const datas = [
  {n:"青眼の白龍",h:"ぶるーあいず・ほわいと・どらごん",e:"Blue-Eyes White Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:3000,de:2500,tx:"高い攻撃力を誇る伝説のドラゴン。\nどんな相手でも粉砕する、その破壊力は計り知れない。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリー・エルフ",h:"ほーりー・えるふ",e:"Mystical Elf",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"かよわいエルフだが、聖なる力で身を守りとても守備が高い。",li:"",lk:[],ta:[],al:[]},
  {n:"サイクロプス",h:"さいくろぷす",e:"Hitotsu-Me Giant",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"一つ目の巨人。\n太い腕で殴りかかってくる。\n要注意。",li:"",lk:[],ta:[],al:[]},
  {n:"ベビードラゴン",h:"べびーどらごん",e:"Baby Dragon",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1200,de:700,tx:"こどもドラゴンとあなどってはいけない。\nうちに秘める力は計り知れない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーゴイル",h:"がーごいる",e:"Ryu-Kishin",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"石像と思わせ、闇の中から攻撃をする。\n逃げ足も素早い。",li:"",lk:[],ta:[],al:[]},
  {n:"グレムリン",h:"ぐれむりん",e:"Feral Imp",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"いたずら好きの小さな悪魔。\n暗闇から襲ってくる。\n気をつけろ！",li:"",lk:[],ta:[],al:[]},
  {n:"砦を守る翼竜",h:"とりでをまもるよくりゅう",e:"Winged Dragon, Guardian of the Fortress #1",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"山の砦を守る竜。\n天空から急降下して敵を攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"きのこマン",h:"きのこまん",e:"Mushroom Man",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"ジメジメした所で力を発揮！かさから菌糸を振りまき攻撃！",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルバウンド",h:"へるばうんど",e:"Shadow Specter",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:500,de:200,tx:"荒野に現れるけものの亡霊。\n数が集まるとやっかいなカード。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の竜王",h:"あんこくのどらごん",e:"Blackland Fire Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"暗闇の奥深くに生息するドラゴン。\n目はあまり良くない。",li:"",lk:[],ta:[],al:[]},
  {n:"剣竜",h:"そーど・どらごん",e:"Sword Arm of Dragon",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1750,de:2030,tx:"全身にカタナのトゲがついた恐竜。\n突進攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"バーバリアン２号",h:"ばーばりあん２ごう",e:"Swamp Battleguard",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：自分フィールドの「バーバリアン１号」１体につき、このカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"タイホーン",h:"たいほーん",e:"Tyhone",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"口から砲弾を撃ちだし遠くを攻撃。\n山での砲撃は強い。",li:"",lk:[],ta:[],al:[]},
  {n:"牛魔人",h:"ぎゅうまじん",e:"Battle Steer",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1300,tx:"森に住む牛の魔人。\nツノを突き出し突進して攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の剣士",h:"ほのおのけんし",e:"Flame Swordsman",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1800,de:1600,tx:"「炎を操る者」＋「伝説の剣豪 MASAKI」",li:"",lk:[],ta:[],al:[]},
  {n:"時の魔術師",h:"ときのまじゅつし",e:"Time Wizard",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nコイントスを１回行い、裏表を当てる。\n当たった場合、相手フィールドのモンスターを全て破壊する。\nハズレの場合、自分フィールドのモンスターを全て破壊し、自分は表側表示で破壊されたモンスターの攻撃力を合計した数値の半分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"封印されし者の右足",h:"ふういんされしもののみぎあし",e:"Right Leg of the Forbidden One",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:200,de:300,tx:"封印された右足。\n封印を解くと、無限の力を得られる。",li:1,lk:[],ta:[],al:[]},
  {n:"封印されし者の左足",h:"ふういんされしもののひだりあし",e:"Left Leg of the Forbidden One",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:200,de:300,tx:"封印された左足。\n封印を解くと、無限の力を得られる。",li:1,lk:[],ta:[],al:[]},
  {n:"封印されし者の右腕",h:"ふういんされしもののみぎうで",e:"Right Arm of the Forbidden One",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:200,de:300,tx:"封印された右腕。\n封印を解くと、無限の力を得られる。",li:1,lk:[],ta:[],al:[]},
  {n:"封印されし者の左腕",h:"ふういんされしもののひだりうで",e:"Left Arm of the Forbidden One",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:200,de:300,tx:"封印された左腕。\n封印を解くと、無限の力を得られる。",li:1,lk:[],ta:[],al:[]},
  {n:"封印されしエクゾディア",h:"ふういんされしえくぞでぃあ",e:"Exodia the Forbidden One",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードと「封印されし者の右腕」「封印されし者の左腕」「封印されし者の右足」「封印されし者の左足」が手札に全て揃った時、自分はデュエルに勝利する。",li:1,lk:[],ta:[],al:[]},
  {n:"デーモンの召喚",h:"でーもんのしょうかん",e:"Summoned Skull",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2500,de:1200,tx:"闇の力を使い、人の心を惑わすデーモン。\n悪魔族ではかなり強力な力を誇る。",li:"",lk:[],ta:[],al:[]},
  {n:"邪悪なるワーム・ビースト",h:"じゃあくなるわーむ・びーすと",e:"The Wicked Worm Beast",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"自分ターンのエンドフェイズ時、表側表示でフィールド上に存在するこのカードは持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイト",h:"わいと",e:"Skull Servant",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:300,de:200,tx:"どこにでも出てくるガイコツのおばけ。\n攻撃は弱いが集まると大変。",li:"",lk:[],ta:[],al:[]},
  {n:"インプ",h:"いんぷ",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"闇に住む小さなオニ。\n攻撃は意外に強い。\nツノには注意。",li:"",lk:[],ta:[],al:[]},
  {n:"ミノタウルス",h:"みのたうるす",e:"Battle Ox",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"すごい力を持つウシの怪物。\nオノひと振りで何でもなぎ倒す。",li:"",lk:[],ta:[],al:[]},
  {n:"ルイーズ",h:"るいーず",e:"Beaver Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"体は小さいが、草原での守備力はかなり強い。",li:"",lk:[],ta:[],al:[]},
  {n:"岩窟魔人オーガ・ロック",h:"がんくつまじんおーが・ろっく",e:"Rock Ogre Grotto #1",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"体が岩のため守備は高い。\n太い腕のひと振りに注意。",li:"",lk:[],ta:[],al:[]},
  {n:"マウンテン・ウォーリアー",h:"まうんてん・うぉーりあー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:600,de:1000,tx:"足場の悪い所でもガンガン動きまわる頑丈な戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"アンデット・ウォーリアー",h:"あんでっと・うぉーりあー",e:"Zombie Warrior",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["融合"],ma:"",tp:"",at:1200,de:900,tx:"「ワイト」＋「格闘戦士アルティメーター」",li:"",lk:[],ta:[],al:[]},
  {n:"デビル・ドラゴン",h:"でびる・どらごん",e:"Koumori Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"凶悪なドラゴン。\n邪悪な炎をはき、心を邪悪にする。",li:"",lk:[],ta:[],al:[]},
  {n:"二頭を持つキング・レックス",h:"にとうをもつきんぐ・れっくす",e:"Two-Headed King Rex",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"恐竜族の中では強力なカード。\n２つの頭で同時攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャッジ・マン",h:"じゃっじ・まん",e:"Judge Man",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2200,de:1500,tx:"勝ち負けのない勝負が嫌いな戦士。\nこん棒の攻撃は強いぞ！",li:"",lk:[],ta:[],al:[]},
  {n:"闇・道化師のサギー",h:"やみ・どうけしのさぎー",e:"Saggi the Dark Clown",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:1500,tx:"どこからともなく現れる道化師。\n不思議な動きで攻撃をかわす。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・マジシャン",h:"ぶらっく・まじしゃん",e:"Dark Magician",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:2500,de:2100,tx:"魔法使いとしては、攻撃力・守備力ともに最高クラス。",li:"",lk:[],ta:[],al:[]},
  {n:"メデューサの亡霊",h:"めでゅーさのぼうれい",e:"The Snake Hair",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"毒ヘビの頭を持つモンスター。\n目をあわせると、石にされてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"竜騎士ガイア",h:"りゅうきしがいあ",e:"Gaia the Dragon Champion",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:2600,de:2100,tx:"「暗黒騎士ガイア」＋「カース・オブ・ドラゴン」",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒騎士ガイア",h:"あんこくきしがいあ",e:"Gaia The Fierce Knight",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2300,de:2100,tx:"風よりも速く走る馬に乗った騎士。\n突進攻撃に注意。",li:"",lk:[],ta:[],al:[]},
  {n:"カース・オブ・ドラゴン",h:"かーす・おぶ・どらごん",e:"Curse of Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2000,de:1500,tx:"邪悪なドラゴン。\n闇の力を使った攻撃は強力だ。",li:"",lk:[],ta:[],al:[]},
  {n:"壺魔人",h:"つぼまじん",e:"Dragon Piper",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:1800,tx:"リバース：フィールド上に表側表示で存在する「ドラゴン族・封印の壺」を破壊する。\n破壊した場合、フィールド上に表側表示で存在するドラゴン族モンスターは全て攻撃表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エルフの剣士",h:"えるふのけんし",e:"Celtic Guardian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"剣術を学んだエルフ。\n素早い攻撃で敵を翻弄する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻想師・ノー・フェイス",h:"いりゅーじょにすと・のー・ふぇいす",e:"Illusionist Faceless Mage",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:2200,tx:"幻影を見せ、ひらりと攻撃をかわす。",li:"",lk:[],ta:[],al:[]},
  {n:"カルボナーラ戦士",h:"かるぼなーらせんし",e:"Karbonala Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1500,de:1200,tx:"「マグネッツ１号」＋「マグネッツ２号」",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリー・ドール",h:"ほーりー・どーる",e:"Rogue Doll",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1600,de:1000,tx:"聖なる力を操る人形。\n闇での攻撃は強力だ。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキッズ",h:"えれきっず",e:"Wattkid",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"雷攻撃は意外と強い。\n甘く見ると感電するぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"グリフォール",h:"ぐりふぉーる",e:"Griffore",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"かたい体で守ることが得意。\n半端な攻撃は、はじき返す。",li:"",lk:[],ta:[],al:[]},
  {n:"サイガー",h:"さいがー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:600,tx:"守備は見た目ほど高くないが、ツノによる攻撃は強力だ。",li:"",lk:[],ta:[],al:[]},
  {n:"クリッター",h:"くりったー",e:"Sangan",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから攻撃力１５００以下のモンスター１体を手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ビック・アント",h:"びっく・あんと",e:"Big Insect",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"密林に住む巨大アリ。\n攻撃・守備ともに意外と強い。",li:"",lk:[],ta:[],al:[]},
  {n:"昆虫人間",h:"べーしっく・いんせくと",e:"Basic Insect",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:500,de:700,tx:"群をなして暮らす昆虫。\n森の中は彼らの楽園だ。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧蜥蜴",h:"あーまー・りざーど",e:"Armored Lizard",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"かたい体のトカゲ。\n大きな口で噛みつかれたら、ひとたまりもないぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘラクレス・ビートル",h:"へらくれす・びーとる",e:"Hercules Beetle",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1500,de:2000,tx:"巨大カブトムシ。\nつの攻撃とかたい体の守りは強力。",li:"",lk:[],ta:[],al:[]},
  {n:"キラー・ビー",h:"きらー・びー",e:"Killer Needle",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"大きなハチ。\n意外に強い攻撃をする。\n群で襲われると大変。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴキボール",h:"ごきぼーる",e:"Gokibore",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"丸いゴキブリ。\nゴロゴロ転がって攻撃。\n守備が意外と高いぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"吸血ノミ",h:"きゅうけつのみ",e:"Giant Flea",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"血を吸う巨大ノミ。\n攻撃はかなり強い。\nノミとあなどると危険。",li:"",lk:[],ta:[],al:[]},
  {n:"ラーバモス",h:"らーばもす",e:"Larvae Moth",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは通常召喚できない。\n「進化の繭」が装備され、自分のターンで数えて２ターン以上が経過した「プチモス」１体をリリースした場合に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"グレート・モス",h:"ぐれーと・もす",e:"Great Moth",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2500,tx:"このカードは通常召喚できない。\n「進化の繭」が装備され、自分のターンで数えて４ターン以上が経過した「プチモス」１体をリリースした場合に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリボー",h:"くりぼー",e:"Kuriboh",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：相手モンスターの攻撃で自分が戦闘ダメージを受けるダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"マンモスの墓場",h:"まんもすのはかば",e:"Mammoth Graveyard",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"仲間のお墓を守るマンモス。\n墓荒らしを容赦なく攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"グレート・ホワイト",h:"ぐれーと・ほわいと",e:"Great White",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1600,de:800,tx:"巨大な白いサメ。\n大きな口で噛みつかれたら逃れられない。",li:"",lk:[],ta:[],al:[]},
  {n:"オオカミ",h:"おおかみ",e:"Wolf",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"今ではあまり見かけないオオカミ。\nよくきく鼻で獲物をさがす。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ",h:"はーぴぃ・れでぃ",e:"Harpie Lady",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"人に羽のはえたけもの。\n美しく華麗に舞い、鋭く攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ三姉妹",h:"はーぴぃ・れでぃさんしまい",e:"Harpie Lady Sisters",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1950,de:2100,tx:"このカードは通常召喚できない。\n「万華鏡－華麗なる分身－」の効果で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"タイガー・アックス",h:"たいがー・あっくす",e:"Tiger Axe",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"オノを手にした獣戦士。\n素早い動きからくり出す攻撃は強い。",li:"",lk:[],ta:[],al:[]},
  {n:"シルバー・フォング",h:"しるばー・ふぉんぐ",e:"Silver Fang",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"白銀に輝くオオカミ。\n見た目は美しいが、性格は凶暴。",li:"",lk:[],ta:[],al:[]},
  {n:"魔物の狩人",h:"まもののかりうど",e:"Kojikocy",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"人を狩る凶悪な狩人。\n岩をも砕く強い力を持つ。",li:"",lk:[],ta:[],al:[]},
  {n:"究極完全態・グレート・モス",h:"きゅうきょくかんぜんたい・ぐれーと・もす",e:"Perfectly Ultimate Great Moth",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3000,tx:"このカードは通常召喚できない。\n「進化の繭」を装備した状態で、自分ターンで数えて６ターン以上が経過した自分フィールドの「プチモス」１体をリリースした場合に特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガルーザス",h:"がるーざす",e:"Garoozis",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"竜の頭を持つ獣戦士。\nオノの攻撃はかなり強力。",li:"",lk:[],ta:[],al:[]},
  {n:"千年竜",h:"さうざんど・どらごん",e:"Thousand Dragon",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:2400,de:2000,tx:"「時の魔術師」＋「ベビードラゴン」",li:"",lk:[],ta:[],al:[]},
  {n:"デビル・クラーケン",h:"でびる・くらーけん",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"海に潜む巨大イカ。\n海中から突然あらわれ攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"海月－ジェリーフィッシュ－",h:"くらげ－じぇりーふぃっしゅ－",e:"Jellyfish",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"海を漂うクラゲ。\n半透明の身体で姿を確認しにくい。",li:"",lk:[],ta:[],al:[]},
  {n:"進化の繭",h:"しんかのまゆ",e:"Cocoon of Evolution",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは手札から装備カード扱いとしてフィールド上に表側表示で存在する「プチモス」に装備する事ができる。\nこの効果によってこのカードを装備した「プチモス」の攻撃力・守備力は「進化の繭」の数値を適用する。",li:"",lk:[],ta:[],al:[]},
  {n:"海竜神",h:"りばいあさん",e:"Kairyu-Shin",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"海の主と呼ばれる海のドラゴン。\n津波をおこして全てを飲み込む。",li:"",lk:[],ta:[],al:[]},
  {n:"岩石の巨兵",h:"がんせきのきょへい",e:"Giant Soldier of Stone",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1300,de:2000,tx:"岩石の巨人兵。\n太い腕の攻撃は大地をゆるがす。",li:"",lk:[],ta:[],al:[]},
  {n:"人喰い植物",h:"ひとくいしょくぶつ",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"きれいな花と思わせ、近づく人をパクリと食べる、肉食の花。",li:"",lk:[],ta:[],al:[]},
  {n:"クロコダイラス",h:"くろこだいらす",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1100,de:1200,tx:"知恵を持ちさらに狂暴化したワニ。\nかたいうろこで攻撃をはじく。",li:"",lk:[],ta:[],al:[]},
  {n:"グラップラー",h:"ぐらっぷらー",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1300,de:1200,tx:"ずるがしこいヘビ。\n太くて長い身体で締め付ける攻撃に注意！",li:"",lk:[],ta:[],al:[]},
  {n:"アックス・レイダー",h:"あっくす・れいだー",e:"Axe Raider",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1150,tx:"オノを持つ戦士。\n片手でオノを振り回す攻撃はかなり強い。",li:"",lk:[],ta:[],al:[]},
  {n:"メガザウラー",h:"めがざうらー",e:"Megazowler",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"全身にツノの生えた恐竜。\n突撃攻撃は強烈だ！",li:"",lk:[],ta:[],al:[]},
  {n:"ワイルド・ラプター",h:"わいるど・らぷたー",e:"Uraby",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"走ることが得意な恐竜。\n鋭いかぎづめで攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"屍を貪る竜",h:"しかばねをむさぼるりゅう",e:"Crawling Dragon #2",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"何でも噛み砕く口を持つ恐竜。\nその攻撃は強い。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の黒竜",h:"れっどあいず・ぶらっくどらごん",e:"Red-Eyes Black Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2400,de:2000,tx:"真紅の眼を持つ黒竜。\n怒りの黒き炎はその眼に映る者全てを焼き尽くす。",li:"",lk:[],ta:[],al:[]},
  {n:"闇晦ましの城",h:"やみくらましのしろ",e:"Castle of Dark Illusions",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:920,de:1930,tx:"リバース：フィールド上に表側表示で存在する全てのアンデット族モンスターの攻撃力と守備力は２００ポイントアップする。\nまた、このカードがフィールド上に表側表示で存在する限り、自分のスタンバイフェイズ毎にさらに２００ポイントずつアップする。\nこの効果は自分の４回目のスタンバイフェイズまで続く。",li:"",lk:[],ta:[],al:[]},
  {n:"カードを狩る死神",h:"かーどをかるしにがみ",e:"Reaper of the Cards",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1380,de:1930,tx:"リバース：フィールド上に存在する罠カード１枚を選択して破壊する。\n選択したカードがセットされている場合、そのカードをめくって確認し、罠カードなら破壊する。\n魔法カードの場合は元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"闇魔界の覇王",h:"やみまかいのはおう",e:"King of Yamimakai",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2000,de:1530,tx:"強大な闇の力を使い、まわりのものを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"バロックス",h:"ばろっくす",e:"Barox",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["融合"],ma:"",tp:"",at:1380,de:1530,tx:"キラーパンダ＋ガーゴイル",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・キメラ",h:"だーく・きめら",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1610,de:1460,tx:"魔界に生息するモンスター。\n闇の炎をはき攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル・ガーディアン",h:"めたる・がーでぃあん",e:"Metal Guardian",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1150,de:2150,tx:"魔界の宝を守護する悪魔。\n暗闇での守備は相当かたい。",li:"",lk:[],ta:[],al:[]},
  {n:"カタパルト・タートル",h:"かたぱると・たーとる",e:"Catapult Turtle",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"１ターンに１度、自分フィールド上のモンスター１体をリリースして発動できる。\nリリースしたモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"逆転の女神",h:"ぎゃくてんのめがみ",e:"Gyakutenno Megami",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"聖なる力で弱き者を守り、逆転の力を与える女神。",li:"",lk:[],ta:[],al:[]},
  {n:"ケンタウロス",h:"けんたうろす",e:"Mystic Horseman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1550,tx:"人とウマがひとつになった化け物。\n走るのが速く、誰も追いつけない。",li:"",lk:[],ta:[],al:[]},
  {n:"ミノケンタウロス",h:"みのけんたうろす",e:"Rabid Horseman",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["融合"],ma:"",tp:"",at:2000,de:1700,tx:"「ミノタウルス」＋「ケンタウロス」",li:"",lk:[],ta:[],al:[]},
  {n:"鎧武者斬鬼",h:"よろいむしゃざんき",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1500,de:1700,tx:"一騎打ちを好む。\n一瞬のスキをついて、居合い抜きで攻撃！",li:"",lk:[],ta:[],al:[]},
  {n:"地を這うドラゴン",h:"ちをはうどらごん",e:"Crawling Dragon",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"力が弱り、空を飛べなくなったドラゴン。\nしかしまだ攻撃は強い。",li:"",lk:[],ta:[],al:[]},
  {n:"マーダーサーカス",h:"まーだーさーかす",e:"Crass Clown",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1350,de:1400,tx:"フィールド上に守備表示で存在するこのカードが表側攻撃表示になった時、相手フィールド上に存在するモンスター１体を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧武者ゾンビ",h:"よろいむしゃぞんび",e:"Armored Zombie",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1500,de:0,tx:"怨念により蘇った武者。\n闇雲にふりまわすカタナに注意。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・ゾンビ",h:"どらごん・ぞんび",e:"Dragon Zombie",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1600,de:0,tx:"魔力により蘇ったドラゴン。\nはく息は触れるものを腐食させる。",li:"",lk:[],ta:[],al:[]},
  {n:"マーダーサーカス・ゾンビ",h:"まーだーさーかす・ぞんび",e:"Clown Zombie",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1350,de:0,tx:"闇の力で生き返ったピエロ。\nフラフラとした踊りで死へといざなう。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴースト王－パンプキング－",h:"ごーすとおう－ぱんぷきんぐ－",e:"Pumpking the King of Ghosts",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:2000,tx:"「闇晦ましの城」がフィールド上に表側表示で存在する限り、このカードの攻撃力と守備力は１００ポイントアップする。\nまた、自分のスタンバイフェイズ毎にさらに１００ポイントずつアップする。\nこの効果は自分の４回目のスタンバイフェイズまで続く。",li:"",lk:[],ta:[],al:[]},
  {n:"格闘戦士アルティメーター",h:"かくとうせんしあるてぃめーたー",e:"Battle Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:700,de:1000,tx:"武器をいっさい使わず、素手で戦いぬく格闘戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"邪炎の翼",h:"じゃえんのつばさ",e:"Wings of Wicked Flame",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"赤黒く燃える翼。\n全身から炎を吹き出し攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の仮面",h:"やみのかめん",e:"Mask of Darkness",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:400,tx:"リバース：自分の墓地の罠カード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"転職の魔鏡",h:"てんしょくのまきょう",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:1300,tx:"悪魔の鏡。\n攻撃を受けても割れずに、ダメージを防いでくれる。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔族のカーテン",h:"くろまぞくのかーてん",e:"Curtain of the Dark Ones",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"魔術師がつくりだしたカーテン。\n魔法使いの力が上がるという。",li:"",lk:[],ta:[],al:[]},
  {n:"トモザウルス",h:"ともざうるす",e:"Tomozaurus",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:500,de:400,tx:"小さいが性格は凶暴。\n仲間同士で争いだす。",li:"",lk:[],ta:[],al:[]},
  {n:"風の精霊",h:"かぜのせいれい",e:"",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"気ままに飛び回る風の精霊。\n機嫌が悪いと嵐になる。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドウ・ファイター",h:"しゃどう・ふぁいたー",e:"Kageningen",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"実体と影に分かれて襲ってくる。\n油断すると挟まれるぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"手招きする墓場",h:"てまねきするはかば",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:700,de:900,tx:"死者にさらなる力をあたえ、生ける者を死へとさそう墓場。",li:"",lk:[],ta:[],al:[]},
  {n:"心眼の女神",h:"しんがんのめがみ",e:"Goddess with the Third Eye",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"東方の英雄",h:"とうほうのえいゆう",e:"Hero of the East",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"遥か東の国から来たと言われているサムライ。\n手にするカタナは良く切れる。",li:"",lk:[],ta:[],al:[]},
  {n:"死の沈黙の天使 ドマ",h:"しのちんもくのてんし　どま",e:"Doma The Angel of Silence",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"死を司る天使。\nこいつに睨まれたら、死から逃れられない。",li:"",lk:[],ta:[],al:[]},
  {n:"命を食する者",h:"いのちをしょくするもの",e:"That Which Feeds on Life",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"あらゆる生き物の魂を喰い、己のエネルギーとする悪魔。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・グレイ",h:"だーく・ぐれい",e:"Dark Gray",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"からだが灰色のけもの。\nあまり見かけない貴重ないきもの。",li:"",lk:[],ta:[],al:[]},
  {n:"白い泥棒",h:"ほわいと・しーふ",e:"White Magical Hat",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ウィザード",h:"かおす・うぃざーど",e:"Kamionwizard",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:1300,de:1100,tx:"「ホーリー・エルフ」＋「黒魔族のカーテン」",li:"",lk:[],ta:[],al:[]},
  {n:"ナイトメア・スコーピオン",h:"ないとめあ・すこーぴおん",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"悪夢を見せ、うなされている間に四本もある毒のしっぽを刺す。",li:"",lk:[],ta:[],al:[]},
  {n:"本の精霊 ホーク・ビショップ",h:"ほんのせいれい　ほーく・びしょっぷ",e:"Spirit of the Books",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"本の精霊。\nとても高い知恵を持ち、多彩な攻撃をしかけてくる。",li:"",lk:[],ta:[],al:[]},
  {n:"物陰の協力者",h:"ものかげのきょうりょくしゃ",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"物陰からこっそりと協力してくれる、かわいらしい小人。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄の裁判",h:"じごくのさいばん",e:"Trial of Nightmare",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:900,tx:"敵を棺桶に閉じこめ、地獄の使いがグサリと判決を下す。",li:"",lk:[],ta:[],al:[]},
  {n:"ドリーム・ピエロ",h:"どりーむ・ぴえろ",e:"Dream Clown",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"フィールド上に攻撃表示で存在するこのカードが表側守備表示になった時、相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"眠れる獅子",h:"ねむれるしし",e:"Sleeping Lion",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:700,de:1700,tx:"普段眠っている猛獣。\n目をさますと手がつけられない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヤマタノ竜絵巻",h:"やまたのどらごんえまき",e:"",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:900,de:300,tx:"絵巻のドラゴンが実体化して攻撃する。\n守備はかなり低い。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・プラント",h:"だーく・ぷらんと",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:300,de:400,tx:"汚染された土と闇の力で育てられた花。\nとても凶暴。",li:"",lk:[],ta:[],al:[]},
  {n:"アイアン・ハート",h:"あいあん・はーと",e:"Ancient Tool",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"古代文明の遺跡で見つかった、破壊だけを目的とした機械。",li:"",lk:[],ta:[],al:[]},
  {n:"セイント・バード",h:"せいんと・ばーど",e:"Faith Bird",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1100,tx:"非常に尾の長い鳥。\n全身から聖なる光を発する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦いの神 オリオン",h:"たたかいのかみ　おりおん",e:"",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"戦いの神と言われている天使。\nその戦いを見た者は誰もいない。",li:"",lk:[],ta:[],al:[]},
  {n:"アサシン",h:"あさしん",e:"Ansatsu",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1200,tx:"闇の中を音もたてず相手に忍び寄る、暗殺専門の戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"ラムーン",h:"らむーん",e:"",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:1700,tx:"月に住む魔法使い。\n月の持つ魔力で相手を魅了する。",li:"",lk:[],ta:[],al:[]},
  {n:"眠り子",h:"ねむりこ",e:"Nemuriko",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:700,tx:"子供だが睡魔をあやつり二度と覚めることのない眠りを誘う。",li:"",lk:[],ta:[],al:[]},
  {n:"ウェザー・コントロール",h:"うぇざー・こんとろーる",e:"",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:600,de:400,tx:"天気を自由にあやつれる。\n山の天気が変わりやすいのはコイツのせい。",li:"",lk:[],ta:[],al:[]},
  {n:"オクトバーサー",h:"おくとばーさー",e:"Octoberser",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"サカナの頭、タコの足。\nとっても不思議な生き物。\nヤリで攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"１３人目の埋葬者",h:"１３にんめのまいそうしゃ",e:"The 13th Grave",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"誰もいないはずの１３番目の墓から突然現れたゾンビ。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の騎士 キラー",h:"ほのおのきし　きらー",e:"Charubin the Fire Knight",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["融合"],ma:"",tp:"",at:1100,de:800,tx:"「モンスター・エッグ」＋「スティング」",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる鎖",h:"せいなるくさり",e:"",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:700,de:700,tx:"聖なる力で、動きを封じることができると言われている鎖。",li:"",lk:[],ta:[],al:[]},
  {n:"死者の腕",h:"ししゃのうで",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"混沌の沼から腕をのばし、生ける者を中へと引きずり込む。",li:"",lk:[],ta:[],al:[]},
  {n:"魔人デスサタン",h:"まじんですさたん",e:"Witty Phantom",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1400,de:1300,tx:"闇にとけ込む黒のタキシードに身をつつんだ、死を司る悪魔。",li:"",lk:[],ta:[],al:[]},
  {n:"なぞの手",h:"なぞのて",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"空間をゆがませ、次元のはざまから腕をのばし攻撃をしかける。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魂の石像",h:"どらごん・そうる・すたちゅー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:900,tx:"ドラゴンの魂を持つ石像の戦士。\n自慢の剣で、敵を切り裂く。",li:"",lk:[],ta:[],al:[]},
  {n:"青眼の銀ゾンビ",h:"ぶるーあいど・しるばーぞんび",e:"Blue-Eyed Silver Zombie",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"目から出す怪光線で、相手をゾンビに変えてしまうと言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"トードマスター",h:"とーどますたー",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"何千年も生きているカエルの仙人。\nおたまじゃくしで攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"デビルツムリ",h:"でびるつむり",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:700,de:1300,tx:"闇の力で進化したカタツムリ。\n手や足があり、速く動ける。",li:"",lk:[],ta:[],al:[]},
  {n:"炎を操る者",h:"ほのおをあやつるもの",e:"Flame Manipulator",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:900,de:1000,tx:"炎の海や炎の壁を自在につくり出し攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"時の魔人 ネクロランサ",h:"ときのまじん　ねくろらんさ",e:"Necrolancer the Time-lord",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"好きな所へ行けるという時空リングから出てくる、一つ目の魔人。",li:"",lk:[],ta:[],al:[]},
  {n:"風の番人 ジン",h:"かぜのばんにん　じん",e:"",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:900,tx:"風をあやつり、竜巻や突風を起こし周囲のものを吹き飛ばす。",li:"",lk:[],ta:[],al:[]},
  {n:"魅惑の怪盗",h:"みわくのかいとう",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:700,tx:"黒いマントをはおるキザな怪盗。\n杖を振って、相手を魅了する。",li:"",lk:[],ta:[],al:[]},
  {n:"髑髏の寺院",h:"どくろのじいん",e:"Temple of Skulls",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:1300,tx:"ドクロと骨ばかりの、気味の悪いお寺。\n近づく者を吸い込む。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスター・エッグ",h:"もんすたー・えっぐ",e:"Monster Egg",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:600,de:900,tx:"卵のカラに身を包んだ謎の戦士。\nカラを飛ばして攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇を司る影",h:"やみをつかさどるかげ",e:"The Shadow Who Controls the Dark",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:700,tx:"暗闇の中にとけこむ影。\n金縛りで敵の動きを封じる。",li:"",lk:[],ta:[],al:[]},
  {n:"ランプの魔人",h:"らんぷのまじん",e:"Lord of the Lamp",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"魔法のランプから現れる魔人。\n呼び出した者に服従する。",li:"",lk:[],ta:[],al:[]},
  {n:"カクタス",h:"かくたす",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"水中に潜んでいる、得体の知れない格好をしたばけもの。",li:"",lk:[],ta:[],al:[]},
  {n:"赤き剣のライムンドス",h:"あかきけんのらいむんどす",e:"Rhaimundos of the Red Sword",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"赤き炎の剣を持った戦士。\n炎の束縛で動きを封じる。",li:"",lk:[],ta:[],al:[]},
  {n:"とろける赤き影",h:"とろけるあかきかげ",e:"",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:500,de:700,tx:"体を溶かして足もとの影にもぐり、敵の真下から攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"死神のドクロイゾ",h:"しにがみのどくろいぞ",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:1200,tx:"地獄の一撃で魂を奪おうとする死神。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・デビル",h:"ふぁいやー・でびる",e:"Fire Reaper",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:700,de:500,tx:"炎の矢を手にする死神。\nその矢にあたると火だるまになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラーバス",h:"らーばす",e:"Larvas",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"素早く動く鳥のばけもの。\n細く長い腕をからませ絞めあげる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハードアーマー",h:"はーどあーまー",e:"Hard Armor",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:300,de:1200,tx:"心のある鎧。\n堅い体でソルジャータックルをしかけてくる。",li:"",lk:[],ta:[],al:[]},
  {n:"火炎草",h:"かえんそう",e:"Firegrass",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"火山の近くに生息する草。\n花から火炎を吹き攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"マンイーター",h:"まんいーたー",e:"Man Eater",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"人喰い人面花。\n毒のある触手で攻撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"ディッグ・ビーク",h:"でぃっぐ・びーく",e:"Dig Beak",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:500,de:800,tx:"ヘビのように長い体をまるめ、回転しながらくちばしで攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"マグネッツ１号",h:"まぐねっつ１ごう",e:"M-Warrior #1",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"コンビプレーが得意な戦士。\n強い磁力を発し、誰にも逃げられない。",li:"",lk:[],ta:[],al:[]},
  {n:"マグネッツ２号",h:"まぐねっつ２ごう",e:"M-Warrior #2",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:500,de:1000,tx:"コンビプレーが得意な戦士。\n電磁コーティングされた鎧は頑丈。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔の知恵",h:"あくまのちえ",e:"Tainted Wisdom",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1250,de:800,tx:"このカードの表示形式が攻撃表示から表側守備表示に変わった時、自分のデッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"サファイヤ・リサーク",h:"さふぁいや・りさーく",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1300,tx:"サファイヤの眼を持つけもの。\n幻影を見せ、混乱したところを攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼミアの神",h:"ぜみあのかみ",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"相手をだまして、破滅の道へと誘うことを得意とする邪神。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャジメント・ザ・ハンド",h:"じゃじめんと・ざ・はんど",e:"The Judgement Hand",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:700,tx:"神が宿った手で最後の審判を下し、激しい攻撃を加える。",li:"",lk:[],ta:[],al:[]},
  {n:"謎の傀儡師",h:"なぞのくぐつし",e:"Mysterious Puppeteer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"モンスターが召喚・反転召喚に成功した時、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"太古の壺",h:"たいこのつぼ",e:"",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:400,de:200,tx:"とても壊れやすい大昔の壺。\n中に何かが潜んでいるらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒火炎龍",h:"あんこくかえんりゅう",e:"Darkfire Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:1500,de:1250,tx:"「火炎草」＋「プチリュウ」",li:"",lk:[],ta:[],al:[]},
  {n:"深淵の冥王",h:"しんえんのめいおう",e:"Dark King of the Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"冥界の王。\nかつて闇を全て支配するほどの力があったという。",li:"",lk:[],ta:[],al:[]},
  {n:"ハープの精",h:"はーぷのせい",e:"Spirit of the Harp",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"天界でハープをかなでる精霊。\nその音色はまわりの心をなごます。",li:"",lk:[],ta:[],al:[]},
  {n:"大王目玉",h:"だいおうめだま",e:"Big Eye",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:1000,tx:"リバース：自分のデッキの上からカードを５枚まで確認し、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"アーメイル",h:"あーめいる",e:"Armaill",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:700,de:1300,tx:"剣状の尾を持つ変わった戦士。\n両手と尾で３連攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・プリズナー",h:"だーく・ぷりずなー",e:"Dark Prisoner",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:1000,tx:"光の反射を巧みに操り、自分の姿を隠すことができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハリケル",h:"はりける",e:"",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:900,de:200,tx:"荒野で荒れ狂う竜巻。\n風の刃で相手を切り刻む。",li:"",lk:[],ta:[],al:[]},
  {n:"魔天老",h:"まてんろう",e:"Ancient Brain",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:700,tx:"天界から追放された堕天使。\n闇での闘いに優れている。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・アイ",h:"ふぁいやー・あい",e:"",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"炎につつまれた目玉。\n羽をはばたかせ、炎の風をおこす。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスタートル",h:"もんすたーとる",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"トゲのついたこうらを身につけたカメ。\nとても凶暴で人になつかない。",li:"",lk:[],ta:[],al:[]},
  {n:"キラー・ザ・クロー",h:"きらー・ざ・くろー",e:"Claw Reacher",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:800,tx:"腕を自由に伸ばし、鋭い爪で相手を串刺しにすることができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サターナ",h:"さたーな",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"敵を呪い、動きを止めることができる魔法使い。",li:"",lk:[],ta:[],al:[]},
  {n:"アルラウネ",h:"あるらうね",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"花の中の女性が毒花粉をまき散らす。\n近づいてはいけない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークシェイド",h:"だーくしぇいど",e:"",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"クリスタルから強烈な光を発して攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"仮面道化",h:"かめんどうけ",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:500,de:700,tx:"死のおどりを踊りながら、手にするカマで敵を切りきざむ戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリー・パワー",h:"ほーりー・ぱわー",e:"",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"ひょろひょろとしているが、聖なる力に守られている。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャグラー",h:"じゃぐらー",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:900,tx:"手品のような魔法で敵を倒す。\nハトを出して攻撃もする。",li:"",lk:[],ta:[],al:[]},
  {n:"コピックス",h:"こぴっくす",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"いろんな奴に変身して、相手をだましながら戦う戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラージュ",h:"みらーじゅ",e:"Fiend Reflection #2",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1100,de:1400,tx:"手にする鏡から仲間を呼び出すことのできる鳥のけもの。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルゲート・ディーグ",h:"へるげーと・でぃーぐ",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:700,de:800,tx:"おなかに地獄へ通じる扉があり、召還もできる不気味なモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"ファランクス",h:"ふぁらんくす",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"上にも下にも頭がある、気持ち悪いヤツ。\n口からはレーザーを吐く。",li:"",lk:[],ta:[],al:[]},
  {n:"フュージョニスト",h:"ふゅーじょにすと",e:"Fusionist",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["融合"],ma:"",tp:"",at:900,de:700,tx:"「プチテンシ」＋「スリーピィ」",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・エース",h:"れっど・えーす",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1000,de:800,tx:"死の呪いをかけてくる魔法使い。\n呪文を聞くと、気が遠くなる。",li:"",lk:[],ta:[],al:[]},
  {n:"ララ・ライウーン",h:"らら・らいうーん",e:"",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"電気を帯びた雲形のモンスター。\n何でも溶かす危険な雨を降らせる。",li:"",lk:[],ta:[],al:[]},
  {n:"キーメイス",h:"きーめいす",e:"Key Mace",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"とても小さな天使。\nかわいらしさに負け、誰でも心を開いてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"タートル・タイガー",h:"たーとる・たいがー",e:"Turtle Tiger",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1000,de:1500,tx:"甲羅を持ったトラ。\n堅い甲羅で身を守り、鋭いキバで攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔人 テラ",h:"まじん　てら",e:"Terra the Terrible",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"沼地に住む悪魔の手先。\n見た目ほど強くないが油断は禁物。",li:"",lk:[],ta:[],al:[]},
  {n:"ドローン",h:"どろーん",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:900,de:500,tx:"ドロローンと分身して、はさみ撃ち攻撃をしかけてくる。\n油断するな！",li:"",lk:[],ta:[],al:[]},
  {n:"アンモ・ナイト",h:"あんも・ないと",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"大昔から海を外敵から守っている、アンモナイトの戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾンビランプ",h:"ぞんびらんぷ",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:500,de:400,tx:"腕をロケットのように飛ばして攻撃する、アンデットモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"ハッピー・ラヴァー",h:"はっぴー・らう゛ぁー",e:"Happy Lover",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:800,de:500,tx:"頭からハートビームを出し敵をしあわせにする、小さな天使。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン・ナイト",h:"ぺんぎん・ないと",e:"Penguin Knight",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:800,tx:"このカードが相手のカードの効果によってデッキから墓地へ送られた時、自分の墓地に存在するカードを全てデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"プチリュウ",h:"ぷちりゅう",e:"Petit Dragon",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:600,de:700,tx:"とても小さなドラゴン。\n小さなからだをいっぱいに使い攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"キラーパンダ",h:"きらーぱんだ",e:"Frenzied Panda",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"常に太い竹を一本持っており、性格は非常に凶暴である。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモン・ビーバー",h:"でーもん・びーばー",e:"Archfiend Marmot of Nefariousness",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:400,de:600,tx:"悪魔のツノと翼を持つビーバー。\nどんぐりを投げつけて攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴースト",h:"ごーすと",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"この世の成仏できない霊が集まってできた怨霊。",li:"",lk:[],ta:[],al:[]},
  {n:"マキャノン",h:"まきゃのん",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"大砲のような悪魔。\n目に見えない早さで目玉の弾を発射する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドローバ",h:"どろーば",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"ドロドロした、気持ち悪いモンスター。\n猛毒ガスを吐き、攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"グロス",h:"ぐろす",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"ムチのように長い腕で、少し離れたところでも攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スピック",h:"すぴっく",e:"Droll Bird",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"くちばしがとても大きく、大声で鳴き気の弱い相手を驚かせる。",li:"",lk:[],ta:[],al:[]},
  {n:"プチテンシ",h:"ぷちてんし",e:"Petit Angel",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:600,de:900,tx:"ちょこまか動き攻撃がなかなか当たらない、とても小さな天使。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークキラー",h:"だーくきらー",e:"Winged Cleaver",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:700,de:700,tx:"カマのように発達した腕を振り回し攻撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"スティング",h:"すてぃんぐ",e:"Hinotama Soul",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"ものすごく熱い炎のかたまり。\nその体で体当たりしてくる。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・キッズ",h:"さんだー・きっず",e:"Thunder Kid",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"雷をからだの中に蓄電させている。\n泣かせたときは危険。",li:"",lk:[],ta:[],al:[]},
  {n:"バビロン",h:"ばびろん",e:"Meotoko",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"一つ目の巨大な怪物。\n目玉からビームを発射して攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"アクア・マドール",h:"あくあ・まどーる",e:"Aqua Madoor",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:2000,tx:"水をあやつる魔法使い。\n分厚い水の壁をつくり敵を押しつぶす。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の影武者",h:"しえんのかげむしゃ",e:"Kagemusha of the Blue Flame",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:400,tx:"シエンに仕える影武者。\n鋭い切れ味の名刀を持つ。",li:"",lk:[],ta:[],al:[]},
  {n:"フレイム・ゴースト",h:"ふれいむ・ごーすと",e:"Flame Ghost",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["融合"],ma:"",tp:"",at:1000,de:800,tx:"「ワイト」＋「マグマン」",li:"",lk:[],ta:[],al:[]},
  {n:"ドリアード",h:"どりあーど",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"森の精霊。\n草木の力を借りて相手の動きを封じる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・デーモンズ・ドラゴン",h:"ぶらっく・でーもんず・どらごん",e:"Black Skull Dragon",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:3200,de:2500,tx:"「デーモンの召喚」＋「真紅眼の黒竜」",li:"",lk:[],ta:[],al:[]},
  {n:"二つの口を持つ闇の支配者",h:"つーまうす・だーくるーらー",e:"Two-Mouth Darkruler",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"口が二つある恐竜。\nツノに蓄電し、背中の口から放電する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソリテュード",h:"そりてゅーど",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1050,de:1000,tx:"下半身がシカで、魂を吸うという大カマを持った獣戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"仮面魔道士",h:"かめんまどうし",e:"Masked Sorcerer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"蜘蛛男",h:"くもおとこ",e:"Kumootoko",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:700,de:1400,tx:"巨大クモが知恵をつけた姿。\n糸を吐き動きを封じ込める。",li:"",lk:[],ta:[],al:[]},
  {n:"ミッドナイト・デビル",h:"みっどないと・でびる",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"深夜に現れる鳥のばけもの。\n呼び出すにはいけにえが必要という。",li:"",lk:[],ta:[],al:[]},
  {n:"轟きの大海蛇",h:"とどろきのおおうみへび",e:"Roaring Ocean Snake",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["融合"],ma:"",tp:"",at:2100,de:1800,tx:"「魔法のランプ」＋「ひょうすべ」",li:"",lk:[],ta:[],al:[]},
  {n:"トラップ・マスター",h:"とらっぷ・ますたー",e:"Trap Master",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1100,tx:"リバース：フィールド上の罠カードを１枚破壊する。\n（対象カードが裏の場合、表にしてそれが罠カードなら破壊する。\n違う場合は元に戻す。\nそのカードの効果は発動しない）",li:"",lk:[],ta:[],al:[]},
  {n:"呪われし魔剣",h:"のろわれしまけん",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:800,tx:"身につけ、呪いに打ち勝つことができた者は力を得られると言う。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・ストーカー",h:"です・すとーかー",e:"Skull Stalker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"素早く動き、相手をはさみで捕らえ毒針を刺すサソリ戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒトデンチャク",h:"ひとでんちゃく",e:"",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:600,de:700,tx:"汚染された水で狂暴化したヒトデ。\n口から溶解液をはく。",li:"",lk:[],ta:[],al:[]},
  {n:"森の屍",h:"もりのしかばね",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1000,de:900,tx:"森のぬしが倒れたあと、悪しき者の手により蘇った屍。",li:"",lk:[],ta:[],al:[]},
  {n:"命の砂時計",h:"いのちのすなどけい",e:"",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"命を司る天使。\n命を短くするかわりに力を与える。",li:"",lk:[],ta:[],al:[]},
  {n:"レア・フィッシュ",h:"れあ・ふぃっしゅ",e:"Rare Fish",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:1500,de:1200,tx:"「フュージョニスト」＋「恍惚の人魚」",li:"",lk:[],ta:[],al:[]},
  {n:"ウッド・ジョーカー",h:"うっど・じょーかー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"嫌な笑みを浮かべた悪魔。\n手にするカマで、器用に攻撃をかわす。",li:"",lk:[],ta:[],al:[]},
  {n:"魔人銃",h:"まじんがん",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"口から弾を発射して攻撃する生物兵器。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒魔神 ナイトメア",h:"あんこくまじん　ないとめあ",e:"Dark Titan of Terror",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"夢の中に潜むと言われている悪魔。\n寝ている間に命を奪う。",li:"",lk:[],ta:[],al:[]},
  {n:"斬首の美女",h:"ざんしゅのびじょ",e:"Beautiful Headhuntress",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:800,tx:"その美貌とはうらはらに、カタナで数多くの首をはねてきた女。",li:"",lk:[],ta:[],al:[]},
  {n:"森の住人 ウダン",h:"もりのじゅうにん　うだん",e:"Wodan the Resident of the Forest",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1200,tx:"フィールド上の表側表示の植物族モンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界の番人",h:"めいかいのばんにん",e:"Guardian of the Labyrinth",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"冥界への入り口を守る戦士。\n許可のない者は容赦なく斬る。",li:"",lk:[],ta:[],al:[]},
  {n:"はにわ",h:"はにわ",e:"Haniwa",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"古代王の墓の中にある宝物を守る土人形。",li:"",lk:[],ta:[],al:[]},
  {n:"ヤシの木",h:"やしのき",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"意志をもつヤシの木。\n実を落として攻撃。\n実の中のミルクはおいしい。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴィシュワ・ランディー",h:"う゛ぃしゅわ・らんでぃー",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"闇に仕える女戦士。\n相手を血祭りにあげることが生きがい。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・フット",h:"です・ふっと",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:700,de:800,tx:"目玉に足の生えた化け物。\n高くジャンプしてかぎづめで攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の暗殺者",h:"やみのあんさつしゃ",e:"Dark Assailant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1200,de:1200,tx:"サイコソードと呼ばれる剣を持ち、魔界に君臨する暗殺者。",li:"",lk:[],ta:[],al:[]},
  {n:"運命のろうそく",h:"うんめいのろうそく",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"指先の炎が消えたとき、相手の運命が決定する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーター・エレメント",h:"うぉーたー・えれめんと",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"水に住んでいる精霊。\nまわりを霧でつつみこみ視界を奪う。",li:"",lk:[],ta:[],al:[]},
  {n:"マグマン",h:"まぐまん",e:"Dissolverock",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:900,de:1000,tx:"マグマの中から生まれたモンスター。\nものすごい熱で近づくものは溶ける。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ナポレオン",h:"だーく・なぽれおん",e:"Meda Bat",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:400,tx:"心の悪しき者がつくった目玉の悪魔。\nダークボムで爆破攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"魂を狩る者",h:"たましいをかるもの",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"剣で斬りつけられた者は、魂をぬかれてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"ルート・ウォーター",h:"るーと・うぉーたー",e:"Root Water",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"海にひそむ半魚人。\n暗黒の大津波を起こして攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"マスター・アン・エキスパート",h:"ますたー・あん・えきすぱーと",e:"Master & Expert",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"けもの使いの達人と、主人に忠実なけもの。\nコンビは完璧。",li:"",lk:[],ta:[],al:[]},
  {n:"水の踊り子",h:"みずのおどりこ",e:"Water Omotics",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"かめから次々とあふれでる水を、竜に変えて攻撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷",h:"ひょう",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"全身が氷でできている戦士。\n触れるものを何でも凍らせてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"恍惚の人魚",h:"こうこつのまーめいど",e:"Enchanting Mermaid",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"海を航海する者を誘惑しておぼれさせる、美しい人魚。",li:"",lk:[],ta:[],al:[]},
  {n:"キャッツ・フェアリー",h:"きゃっつ・ふぇありー",e:"Nekogal #1",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1100,de:900,tx:"ネコの妖精。\n愛らしい姿とはうらはらに、素早く敵をひっかく。",li:"",lk:[],ta:[],al:[]},
  {n:"エンジェル・魔女",h:"えんじぇる・まじょ",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"天使になる運命を背負っていたが、あこがれの魔女になった天使。",li:"",lk:[],ta:[],al:[]},
  {n:"未熟な悪魔",h:"みじゅくなあくま",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:500,de:750,tx:"完全体になれなかった醜い悪魔。\nはらの穴は何でも吸い込む。",li:"",lk:[],ta:[],al:[]},
  {n:"プリヴェント・ラット",h:"ぷりう゛ぇんと・らっと",e:"Prevent Rat",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:500,de:2000,tx:"毛が集まり、かたい皮のようになっている。\n守備はかなり高い。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の戦士",h:"いじげんのせんし",e:"D.D. Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードがモンスターと戦闘を行ったダメージ計算後に発動する。\nその戦闘を行ったそれぞれのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ストーン・アルマジラー",h:"すとーん・あるまじらー",e:"Stone Armadiller",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"体が石のように堅い毛で覆われており、守りがかたい。",li:"",lk:[],ta:[],al:[]},
  {n:"沼地の魔獣王",h:"ぬまちのまじゅうおう",e:"Beastking of the Swamps",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1100,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"古代魔導士",h:"こだいまどうし",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1000,de:1300,tx:"数多くの杖を持ち、それぞれを使い分け多彩な攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"月の女神 エルザェム",h:"つきのめがみ　えるざぇむ",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:750,de:1100,tx:"月を守護するきれいな女神。\n月あかりのカーテンで攻撃を防ぐ。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔の鏡",h:"でーもんず・みらー",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"鏡に映るものに催眠術をかけ攻撃をよける悪魔の鏡。",li:"",lk:[],ta:[],al:[]},
  {n:"アイルの小剣士",h:"あいるのしょうけんし",e:"The Little Swordsman of Aile",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"自分のフィールド上に存在するこのカードを除くモンスター１体を生け贄に捧げる度に、このカードの攻撃力はターン終了時まで７００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォー・アース",h:"うぉー・あーす",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:700,de:1400,tx:"岩石が集まってできたゴーレム。\n相手を石化して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィング・エッグ・エルフ",h:"うぃんぐ・えっぐ・えるふ",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:500,de:1300,tx:"たまごのカラに身を包む天使。\n大きな羽で攻撃を防ぐ。",li:"",lk:[],ta:[],al:[]},
  {n:"怒りの海王",h:"いかりのかいおう",e:"The Furious Sea King",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:800,de:700,tx:"偉大な海の王。\n終わることのない大津波を呼び、敵をのみこむ。",li:"",lk:[],ta:[],al:[]},
  {n:"剣の女王",h:"つるぎのじょおう",e:"Princess of Tsurugi",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:700,tx:"リバース：相手フィールド上に存在する魔法・罠カードの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"悪の無名戦士",h:"あくのむめいせんし",e:"Unknown Warrior of Fiend",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"素早い動きで真空を作り出し、相手を切り刻む戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"闇にしたがう者",h:"やみにしたがうもの",e:"Sectarian of Secrets",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:500,tx:"闇を崇拝する魔法使い。\n魔の手を呼び出し暗闇へ引きずり込む。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊神 ヴァサーゴ",h:"はかいしん　う゛ぁさーご",e:"Versago the Destroyer",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:900,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ウェザ",h:"うぇざ",e:"Wetha",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1000,de:900,tx:"雨を操る精霊。\n台風を呼び出し、様々なものを吹き飛ばす。",li:"",lk:[],ta:[],al:[]},
  {n:"メギラス・ライト",h:"めぎらす・らいと",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:900,de:600,tx:"ブキミな目から悪しき光を放ち、相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マブラス",h:"まぶらす",e:"Mavelus",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:1300,de:900,tx:"「タイホーン」＋「邪炎の翼」",li:"",lk:[],ta:[],al:[]},
  {n:"悟りの老樹",h:"さとりのろうじゅ",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:600,de:1500,tx:"ありとあらゆる知識を駆使して、様々な攻撃を防ぐ。",li:"",lk:[],ta:[],al:[]},
  {n:"緑樹の霊王",h:"りょくじゅのれいおう",e:"Green Phantom King",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:500,de:1600,tx:"青々と生い茂る木に囲まれて暮らす、森を治める若き王。",li:"",lk:[],ta:[],al:[]},
  {n:"陸戦型 バグロス",h:"りくせんがた　ばぐろす",e:"Ground Attacker Bugroth",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"陸上戦闘ロボット。\n今はダメだが、海でも使えたらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"北風と太陽",h:"きたかぜとたいよう",e:"Ray & Temperature",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"仲の良い北風と太陽。\nかまいたちと熱光線で攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーゴン・エッグ",h:"ごーごん・えっぐ",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:300,de:1300,tx:"ゴーゴンが産んだ卵。\n大きな目に映ったものが産まれると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"プチモス",h:"ぷちもす",e:"Petit Moth",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:300,de:200,tx:"成長したらどんなムシになるか分からない、小さな幼虫。",li:"",lk:[],ta:[],al:[]},
  {n:"キング・スモーク",h:"きんぐ・すもーく",e:"King Fog",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:900,tx:"煙の中にひそむ悪魔。\nまわりを煙でおおい、見えなくしてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"王座の守護者",h:"おうざのしゅごしゃ",e:"Protector of the Throne",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:1500,tx:"王が留守の間、王座を外敵から守る王妃。\n守備は高い。",li:"",lk:[],ta:[],al:[]},
  {n:"バーサーカー",h:"ばーさーかー",e:"Mystic Clown",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"狂った力を使い攻撃する。\nその暴走は誰にも止められない。",li:"",lk:[],ta:[],al:[]},
  {n:"スリーピィ",h:"すりーぴぃ",e:"Mystical Sheep #2",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"しっぽの長いひつじ。\nしっぽを使い催眠術をかけ、睡魔を誘う。",li:"",lk:[],ta:[],al:[]},
  {n:"ホログラー",h:"ほろぐらー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"様々な幻想を見せ、そのスキをついて攻撃してくる機械。",li:"",lk:[],ta:[],al:[]},
  {n:"陰陽師 タオ",h:"おんみょうし　たお",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"陰と陽の力を浸食させ、歪んだ力を生み出す魔導士。",li:"",lk:[],ta:[],al:[]},
  {n:"デビル・スネーク",h:"でびる・すねーく",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"目が一つしかないヘビ。\n冷気をはき出し、相手を氷づけにする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲート・キーパー",h:"げーと・きーぱー",e:"Gatekeeper",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"入り口を守るために造られた機械。\n壊すのは大変だ！",li:"",lk:[],ta:[],al:[]},
  {n:"黒い影の鬼王",h:"くろいかげのおーが",e:"Ogre of the Black Shadow",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"黒い影にとりつかれたオーガ。\nすごいスピードで突撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の芸術家",h:"だーく・あーてぃすと",e:"Dark Artist",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:1400,tx:"光属性モンスターの攻撃を受けた時、このカードの守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"チェンジ・スライム",h:"ちぇんじ・すらいむ",e:"Change Slime",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"形を自由に変え、様々なものに変身するスライム。",li:"",lk:[],ta:[],al:[]},
  {n:"月の使者",h:"つきのししゃ",e:"Moon Envoy",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"月の女神に仕える戦士。\n三日月のような矛で攻撃！",li:"",lk:[],ta:[],al:[]},
  {n:"炎の魔神",h:"ほのおのまじん",e:"Fireyarou",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"炎につつまれた魔人。\nまわりの炎を自在に操り攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・カッパー",h:"さいこ・かっぱー",e:"Psychic Kappa",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:400,de:1000,tx:"いろいろな超能力を使い、攻撃のダメージを防ぐカッパ。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の剣豪 MASAKI",h:"でんせつのけんごう　まさき",e:"Masaki the Legendary Swordsman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1100,tx:"百人斬りを成しとげたといわれる、伝説の剣豪。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装騎士ドラゴネス",h:"まそうきしどらごねす",e:"Dragoness the Wicked Knight",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1200,de:900,tx:"「アーメイル」＋「一眼の盾竜」",li:"",lk:[],ta:[],al:[]},
  {n:"B・プラント",h:"ばいお・ぷらんと",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:1300,tx:"地下研究所での実験で大失敗して生まれたばけもの。",li:"",lk:[],ta:[],al:[]},
  {n:"一眼の盾竜",h:"わんあいど・しーるどどらごん",e:"One-Eyed Shield Dragon",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:700,de:1300,tx:"身につけた盾は身を守るだけでなく、突撃にも使える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界の機械兵",h:"まかいのきかいへい",e:"Cyber Soldier of Darkworld",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"闇の力でつくられた機械兵。\n狂ったように敵を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔頭を持つ邪竜",h:"まとうをもつじゃりゅう",e:"",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:900,de:900,tx:"もう一つの頭を持つドラゴン。\n二つの口で敵を噛み砕く。",li:"",lk:[],ta:[],al:[]},
  {n:"音女",h:"おとめ",e:"Sonic Maid",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"音を扱うのが得意なオトメ。\n音符のカマを使い攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレイク",h:"どれいく",e:"Kurama",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:800,de:800,tx:"しっぽが長い鳥。\nそのしっぽで空中から攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の剣",h:"でんせつのけん",e:"Legendary Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の破神剣",h:"やみのはしんけん",e:"Sword of Dark Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"闇属性モンスター１体の攻撃力４００ポイントアップ！守備力２００ポイントダウン！",li:"",lk:[],ta:[],al:[]},
  {n:"闇・エネルギー",h:"やみ・えねるぎー",e:"Dark Energy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"悪魔族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの斧",h:"でーもんのおの",e:"Axe of Despair",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は１０００アップする。\n②：このカードがフィールドから墓地へ送られた時、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"レーザー砲機甲鎧",h:"れーざーきゃのんあーまー",e:"Laser Cannon Armor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"昆虫族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"火器付機甲鎧",h:"かきつきいんせくとあーまー",e:"Insect Armor with Laser Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"昆虫族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エルフの光",h:"えるふのひかり",e:"Elf's Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"光属性モンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"猛獣の歯",h:"もうじゅうのは",e:"Beast Fangs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"獣族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"はがねの甲羅",h:"はがねのこうら",e:"Steel Shell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"水属性モンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔菌",h:"まきん",e:"Vile Germs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"植物族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"黒いペンダント",h:"ぶらっく・ぺんだんと",e:"Black Pendant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は５００アップする。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"銀の弓矢",h:"ぎんのゆみや",e:"Silver Bow and Arrow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"天使族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"光の角",h:"ひかりのつの",e:"Horn of Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備したモンスターの守備力は８００ポイントアップする。\nこのカードがフィールドから墓地に送られた時、５００ライフポイント払えばデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"一角獣のホーン",h:"いっかくじゅうのほーん",e:"Horn of the Unicorn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力・守備力は７００アップする。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\nこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンの秘宝",h:"どらごんのひほう",e:"Dragon Treasure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"ドラゴン族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"電撃鞭",h:"でんげきむち",e:"Electro-Whip",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"雷族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ボンテージ",h:"さいばー・ぼんてーじ",e:"Cyber Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ハーピィ・レディ」または「ハーピィ・レディ三姉妹」にのみ装備可能。\n①：装備モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"万華鏡－華麗なる分身－",h:"まんげきょう－かれいなるぶんしん－",e:"Elegant Egotist",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドに「ハーピィ・レディ」が存在する場合に発動できる。\n手札・デッキから「ハーピィ・レディ」または「ハーピィ・レディ三姉妹」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔性の月",h:"ましょうのつき",e:"Mystical Moon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"獣戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"『守備』封じ",h:"『しゅび』ふうじ",e:"Stop Defense",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの守備表示モンスター１体を対象として発動できる。\nその相手モンスターを表側攻撃表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔のくちづけ",h:"あくまのくちづけ",e:"Malevolent Nuzzler",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は７００アップする。\n②：このカードがフィールドから墓地へ送られた時、５００LPを払って発動できる。\nこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"紫水晶",h:"むらさきすいしょう",e:"Violet Crystal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"アンデット族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"秘術の書",h:"ひじゅつのしょ",e:"Book of Secret Arts",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"魔法使い族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"覚醒",h:"かくせい",e:"Invigoration",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"地属性モンスター１体の攻撃力４００ポイントアップ！守備力２００ポイントダウン！",li:"",lk:[],ta:[],al:[]},
  {n:"機械改造工場",h:"きかいかいぞうこうじょう",e:"Machine Conversion Factory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"体温の上昇",h:"たいおんのじょうしょう",e:"Raise Body Heat",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"恐竜族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"フォロー・ウィンド",h:"ふぉろー・うぃんど",e:"Follow Wind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"鳥獣族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ポセイドンの力",h:"ぽせいどんのちから",e:"Power of Kaishin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"水族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン族・封印の壺",h:"どらごんぞく・ふういんのつぼ",e:"Dragon Capture Jar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上に表側表示で存在する全てのドラゴン族モンスターは守備表示になり、表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"森",h:"もり",e:"Forest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての昆虫・獣・植物・獣戦士族モンスターの攻撃力と守備力は、２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"荒野",h:"こうや",e:"Wasteland",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての恐竜・アンデット・岩石族モンスターの攻撃力と守備力は、２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"山",h:"やま",e:"Mountain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するドラゴン族・鳥獣族・雷族モンスターの攻撃力・守備力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"草原",h:"そうげん",e:"Sogen",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての戦士・獣戦士族モンスターの攻撃力と守備力は、２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"海",h:"うみ",e:"Umi",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する魚族・海竜族・雷族・水族モンスターの攻撃力・守備力は２００ポイントアップする。\nフィールド上に表側表示で存在する機械族・炎族モンスターの攻撃力・守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"闇",h:"やみ",e:"Yami",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する悪魔族・魔法使い族モンスターの攻撃力・守備力は２００ポイントアップする。\nフィールド上に表側表示で存在する天使族モンスターの攻撃力・守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・ホール",h:"ぶらっく・ほーる",e:"Dark Hole",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ボルト",h:"さんだー・ぼると",e:"Raigeki",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドのモンスターを全て破壊する。",li:1,lk:[],ta:[],al:[]},
  {n:"モウヤンのカレー",h:"もうやんのかれー",e:"Mooyan Curry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ライフポイントを２００ポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ポーション",h:"れっど・ぽーしょん",e:"Red Medicine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリンの秘薬",h:"ごぶりんのひやく",e:"Goblin's Secret Remedy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分は６００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"天使の生き血",h:"てんしのいきち",e:"Soul of the Pure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分は８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"治療の神 ディアン・ケト",h:"ちりょうのかみ　でぃあん・けと",e:"Dian Keto the Cure Master",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分は１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"火の粉",h:"ひのこ",e:"Sparks",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに２００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・ボール",h:"ふぁいやー・ぼーる",e:"Hinotama",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"火あぶりの刑",h:"ひあぶりのけい",e:"Final Flame",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"昼夜の大火事",h:"ちゅうやのおおかじ",e:"Ookazi",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"火炎地獄",h:"かえんじごく",e:"Tremendous Fire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに１０００ポイントダメージを与え、自分は５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"光の護封剣",h:"ひかりのごふうけん",e:"Swords of Revealing Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードは発動後、フィールドに残り続け、相手ターンで数えて３ターン後の相手エンドフェイズに破壊される。\n①：このカードの発動時の効果処理として、相手フィールドに裏側表示モンスターが存在する場合、そのモンスターを全て表側表示にする。\n②：このカードが魔法＆罠ゾーンに存在する限り、相手モンスターは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"六芒星の呪縛",h:"ろくぼうせいのじゅばく",e:"Spellbinding Circle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターは攻撃できず、表示形式の変更もできない。\n選択したモンスターが破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇をかき消す光",h:"やみをかきけすひかり",e:"Dark-Piercing Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に裏側表示で存在するモンスターを全て表側表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヤランゾ",h:"やらんぞ",e:"Yaranzo",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1300,de:1500,tx:"宝箱のフタを開けようとする盗賊を、箱から飛び出し襲う。",li:"",lk:[],ta:[],al:[]},
  {n:"女剣士カナン",h:"おんなけんしかなん",e:"Kanan the Swordmistress",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:1400,tx:"チョウのように舞いハチのように刺す、剣と盾を手にした女戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"タクリミノス",h:"たくりみのす",e:"Takriminos",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"体にヒレを持ち、水中でも自由に動ける海竜の仲間。",li:"",lk:[],ta:[],al:[]},
  {n:"くいぐるみ",h:"くいぐるみ",e:"Stuffed Animal",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"かわいらしいぬいぐるみと思わせ、チャックの口でガブリとかみつく。",li:"",lk:[],ta:[],al:[]},
  {n:"メガソニック・アイ",h:"めがそにっく・あい",e:"Megasonic Eye",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"宇宙の果てからやってきた殺人マシン。\n謎の金属でできている。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパー・ウォー・ライオン",h:"すーぱー・うぉー・らいおん",e:"Super War-Lion",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["儀式"],ma:"",tp:"",at:2300,de:2100,tx:"「ライオンの儀式」により降臨。",li:"",lk:[],ta:[],al:[]},
  {n:"ヤマドラン",h:"やまどらん",e:"Yamadron",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"三つの頭でつぎつぎ炎をはき、あたり一面を炎の海にする！",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリー・ナイト・ドラゴン",h:"ほーりー・ないと・どらごん",e:"Seiyaryu",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2500,de:2300,tx:"聖なる炎で悪しき者を焼きはらう、神聖な力を持つドラゴン。",li:"",lk:[],ta:[],al:[]},
  {n:"２人３脚ゾンビ",h:"２にん３きゃくぞんび",e:"Three-Legged Zombies",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"ほそっちょとでぶっちょの、仲良しガイコツ二人組。\n歩きにくそう。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼラ",h:"ぜら",e:"Zera the Mant",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:2800,de:2300,tx:"「ゼラの儀式」により降臨。",li:"",lk:[],ta:[],al:[]},
  {n:"トビペンギン",h:"とびぺんぎん",e:"Flying Penguin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"耳のようにも見える頭についた羽で空を飛ぶ、珍しいペンギン。",li:"",lk:[],ta:[],al:[]},
  {n:"千年の盾",h:"せんねんのたて",e:"Millennium Shield",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:0,de:3000,tx:"古代エジプト王家より伝わるといわれている伝説の盾。\nどんなに強い攻撃でも防げるという。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精の贈りもの",h:"ようせいのおくりもの",e:"Fairy's Gift",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1400,de:1000,tx:"誰もが幸せになれるという魔法をふりまきながら飛びまわる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ソルジャー",h:"かおす・そるじゃー",e:"Black Luster Soldier",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:3000,de:2500,tx:"「カオスの儀式」により降臨。",li:"",lk:[],ta:[],al:[]},
  {n:"デビルズ・ミラー",h:"でびるず・みらー",e:"Fiend's Mirror",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:2100,de:1800,tx:"「悪魔鏡の儀式」により降臨。",li:"",lk:[],ta:[],al:[]},
  {n:"迷宮壁－ラビリンス・ウォール－",h:"めいきゅうへき－らびりんす・うぉーる－",e:"Labyrinth Wall",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:0,de:3000,tx:"フィールドに壁を出現させ、出口のない迷宮をつくる。",li:"",lk:[],ta:[],al:[]},
  {n:"地雷蜘蛛",h:"じらいぐも",e:"Jirai Gumo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2200,de:100,tx:"このカードの攻撃宣言時、コイントスで裏表を当てる。\n当たりの場合はそのまま攻撃する。\nハズレの場合は自分のライフポイントを半分失い攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドウ・グール",h:"しゃどう・ぐーる",e:"Shadow Ghoul",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"自分の墓地に存在するモンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォール・シャドウ",h:"うぉーる・しゃどう",e:"Wall Shadow",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:3000,tx:"「迷宮変化」の効果でしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"迷宮の魔戦車",h:"めいきゅうのませんしゃ",e:"Labyrinth Tank",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:2400,de:2400,tx:"「ギガテック・ウルフ」＋「キャノン・ソルジャー」",li:"",lk:[],ta:[],al:[]},
  {n:"雷魔神－サンガ",h:"らいましん－さんが",e:"Sanga of the Thunder",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"このカードが相手のターンで攻撃された場合、そのダメージ計算時に発動する事ができる。\nその攻撃モンスター１体の攻撃力を０にする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"風魔神－ヒューガ",h:"ふうましん－ひゅーが",e:"Kazejin",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:2200,tx:"このカードが相手のターンで攻撃された場合、そのダメージ計算時に発動する事ができる。\nその攻撃モンスター１体の攻撃力を０にする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水魔神－スーガ",h:"すいましん－すーが",e:"Suijin",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2500,de:2400,tx:"このカードが相手のターンで攻撃された場合、そのダメージ計算時に発動する事ができる。\nその攻撃モンスター１体の攻撃力を０にする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲート・ガーディアン",h:"げーと・がーでぃあん",e:"Gate Guardian",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3750,de:3400,tx:"このカードは通常召喚できない。\n自分フィールドの「雷魔神－サンガ」「風魔神－ヒューガ」「水魔神－スーガ」をそれぞれ１体ずつリリースした場合に特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダンジョン・ワーム",h:"だんじょん・わーむ",e:"Dungeon Worm",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"迷路の地下に潜み、上を通る者を大きな口で丸飲みにする。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄の魔物使い",h:"もんすたー・ていまー",e:"Monster Tamer",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1600,tx:"モンスターを自在に操り攻撃してくる、モンスター使い。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーゴイル・パワード",h:"がーごいる・ぱわーど",e:"Ryu-Kishin Powered",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"闇の力を得て強化されたガーゴイル。\nかぎづめに注意！",li:"",lk:[],ta:[],al:[]},
  {n:"復讐のソード・ストーカー",h:"ふくしゅうのそーど・すとーかー",e:"Swordstalker",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2000,de:1600,tx:"やられていった味方の怨念が宿っているモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"ランプの魔精・ラ・ジーン",h:"らんぷのませい・ら・じーん",e:"La Jinn the Mystical Genie of the Lamp",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1000,tx:"呼び出した主人の言うことを、何でも聞いてくれるランプの精。",li:"",lk:[],ta:[],al:[]},
  {n:"青眼の究極竜",h:"ぶるーあいず・あるてぃめっとどらごん",e:"Blue-Eyes Ultimate Dragon",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:4500,de:3800,tx:"「青眼の白龍」＋「青眼の白龍」＋「青眼の白龍」",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・アリゲーター",h:"とぅーん・ありげーたー",e:"Toon Alligator",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:800,de:1600,tx:"アメリカンコミックの世界から現れた、ワニのモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"ルード・カイザー",h:"るーど・かいざー",e:"Rude Kaiser",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1600,tx:"両手に持つ魔人のオノの破壊力は、かなり強力だ！",li:"",lk:[],ta:[],al:[]},
  {n:"パロット・ドラゴン",h:"ぱろっと・どらごん",e:"Parrot Dragon",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2000,de:1300,tx:"アメリカンコミックの世界のドラゴン。\nかわいらしい風貌にだまされるな。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ラビット",h:"だーく・らびっと",e:"Dark Rabbit",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1100,de:1500,tx:"アメリカンコミックの世界のウサギ。\nとても素早くちょこまかと動く。",li:"",lk:[],ta:[],al:[]},
  {n:"デビル・ボックス",h:"でびる・ぼっくす",e:"Bickuribox",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["融合"],ma:"",tp:"",at:2300,de:2000,tx:"マーダーサーカス＋ドリーム・ピエロ",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィズペット竜",h:"はーぴぃずぺっとどらごん",e:"Harpie's Pet Dragon",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2500,tx:"①：このカードの攻撃力・守備力は、フィールドの「ハーピィ・レディ」の数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法のランプ",h:"まほうのらんぷ",e:"Mystic Lamp",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"振り子刃の拷問機械",h:"ふりこやいばのごうもんきかい",e:"Pendulum Machine",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1750,de:2000,tx:"大きな振り子の刃で相手をまっぷたつ！恐ろしい拷問機械。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導騎士ギルティア",h:"まどうきしぎるてぃあ",e:"Giltia the D. Knight",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1850,de:1500,tx:"「冥界の番人」＋「王座の守護者」",li:"",lk:[],ta:[],al:[]},
  {n:"TM－１ランチャースパイダー",h:"ＴＭ－１らんちゃーすぱいだー",e:"Launcher Spider",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2200,de:2500,tx:"ロケットランチャーを乱射して、相手を爆殺する機械グモ。",li:"",lk:[],ta:[],al:[]},
  {n:"デビルゾア",h:"でびるぞあ",e:"Zoa",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2600,de:1900,tx:"真の力をメタル化によって発揮すると言われているモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル・デビルゾア",h:"めたる・でびるぞあ",e:"Metalzoa",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2300,tx:"このカードは通常召喚できない。\n自分フィールドの、「メタル化・魔法反射装甲」を装備した「デビルゾア」１体をリリースした場合にデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾーン・イーター",h:"ぞーん・いーたー",e:"Zone Eater",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:250,de:200,tx:"「ゾーン・イーター」の攻撃を受けたモンスターは、５ターン後に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄のサソリ",h:"てつのさそり",e:"Steel Scorpion",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:250,de:300,tx:"機械族以外のモンスターがこのカードを攻撃した場合、そのモンスターは（相手ターンで数えて）３ターン目のエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダンシング・エルフ",h:"だんしんぐ・えるふ",e:"Dancing Elf",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:300,de:200,tx:"音楽に合わせ天を舞うエルフ。\n体の羽は、鋭利な刃物。",li:"",lk:[],ta:[],al:[]},
  {n:"エンゼル・イヤーズ",h:"えんぜる・いやーず",e:"Ocubeam",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1550,de:1650,tx:"大きな耳と目で周囲を監視する、恐ろしい風貌の天使。",li:"",lk:[],ta:[],al:[]},
  {n:"レッグル",h:"れっぐる",e:"Leghul",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:350,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラージマウス",h:"らーじまうす",e:"Ooguchi",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:250,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"異国の剣士",h:"いこくのけんし",e:"Swordsman from a Distant Land",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:250,de:250,tx:"「異国の剣士」の攻撃を受けたモンスターは、５ターン後に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"水陸の帝王",h:"すいりくのていおう",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"大きな口から四方八方に炎をはく、爬虫類のばけもの。",li:"",lk:[],ta:[],al:[]},
  {n:"牛鬼",h:"うしおに",e:"Ushi Oni",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2150,de:1950,tx:"黒魔術で蘇ったウシの悪魔。\n壺の中から現れる。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスター・アイ",h:"もんすたー・あい",e:"Monster Eye",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:250,de:350,tx:"１０００ライフポイントを払って発動する。\n自分の墓地に存在する「融合」魔法カード１枚を手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"レオグン",h:"れおぐん",e:"Leogun",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1750,de:1550,tx:"百獣の王のような立派なたてがみを持つシシ。\n体も大きい。",li:"",lk:[],ta:[],al:[]},
  {n:"シーホース",h:"しーほーす",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1350,de:1600,tx:"ウマとサカナの体を持つモンスター。\n海中を風のように駆け抜ける。",li:"",lk:[],ta:[],al:[]},
  {n:"キラー・マシーン",h:"きらー・ましーん",e:"Sword Slasher",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1450,de:1500,tx:"大きく反った剣を振りかざし暴れ回る、殺人マシン。",li:"",lk:[],ta:[],al:[]},
  {n:"カッター・ロボ",h:"かったー・ろぼ",e:"Yaiba Robo",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1000,de:1300,tx:"四角い体に刃物を隠し持ち、なんでもかんでも切り刻む機械。",li:"",lk:[],ta:[],al:[]},
  {n:"機械王",h:"きかいおう",e:"Machine King",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"フィールド上に表側表示で存在する機械族モンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"機械の巨兵",h:"きかいのきょへい",e:"Giant Mech-Soldier",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1750,de:1900,tx:"巨大なオノの攻撃は、大地が割れるほど強力な一撃だ。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル・ドラゴン",h:"めたる・どらごん",e:"Metal Dragon",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:1850,de:1700,tx:"「鋼鉄の巨神像」＋「レッサー・ドラゴン」",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ蜘蛛",h:"からくりぐも",e:"Karakuri Spider",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:500,tx:"このカードの攻撃を受けたモンスターが闇属性だった場合、そのモンスターを破壊する。\nダメージ計算は適用する。",li:"",lk:[],ta:[],al:[]},
  {n:"バット",h:"ばっと",e:"",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:300,de:350,tx:"左右のハネに搭載された爆弾を落としてくるメカコウモリ。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガテック・ウルフ",h:"ぎがてっく・うるふ",e:"Giga-Tech Wolf",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"全身が鋼鉄でできたオオカミ。\n鋭くとがったキバでかみついてくる。",li:"",lk:[],ta:[],al:[]},
  {n:"機械の兵隊",h:"きかいのへいたい",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1700,tx:"機械王を警護する兵隊。\n丸い身体でゴロゴロ転がってくる。",li:"",lk:[],ta:[],al:[]},
  {n:"シャベル・クラッシャー",h:"しゃべる・くらっしゃー",e:"Shovel Crusher",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:1200,tx:"何でも破壊してしまう、両手の大きなシャベルには要注意。",li:"",lk:[],ta:[],al:[]},
  {n:"メカ・ハンター",h:"めか・はんたー",e:"Mechanicalchaser",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1850,de:800,tx:"機械王の命令で、ターゲットを捕まえるまで追いつづけるハンター。",li:"",lk:[],ta:[],al:[]},
  {n:"ブロッカー",h:"ぶろっかー",e:"Blocker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:850,de:1800,tx:"体のパーツがそれぞれ武器になっており、分裂して襲ってくる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスター・ボンバー",h:"みすたー・ぼんばー",e:"Blast Juggler",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"自分のスタンバイフェイズ時にのみ発動する事ができる。\nこのカードをリリースする事で、フィールド上に表側表示で存在する攻撃力１０００以下のモンスター２体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴルゴイル",h:"ごるごいる",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:1600,tx:"異次元に通じる穴から出てくる、大きな鋼鉄の魔人。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガント",h:"ぎがんと",e:"Giganto",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1700,de:1800,tx:"スパイクのついた大きな鉄球を振り回し襲いかかってくる。",li:"",lk:[],ta:[],al:[]},
  {n:"デビル・フランケン",h:"でびる・ふらんけん",e:"Cyber-Stein",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"①：５０００LPを払って発動できる。\nエクストラデッキから融合モンスター１体を攻撃表示で特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"コマンダー",h:"こまんだー",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:750,de:700,tx:"ロケットランチャーとバズーカ砲を装備した実戦部隊。",li:"",lk:[],ta:[],al:[]},
  {n:"人造人間７号",h:"じんぞうにんげん７ごう",e:"Jinzo #7",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイス・アルマジロ",h:"だいす・あるまじろ",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1650,de:1800,tx:"体を丸めると、サイコロのような形になるアルマジロ。",li:"",lk:[],ta:[],al:[]},
  {n:"天空竜",h:"すかい・どらごん",e:"Sky Dragon",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1900,de:1800,tx:"４枚の羽を持つ、鳥の姿をしたドラゴン。\n刃の羽で攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ドラゴン",h:"さんだー・どらごん",e:"Thunder Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：このカードを手札から捨てて発動できる。\nデッキから「サンダー・ドラゴン」を２体まで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ストーン・ドラゴン",h:"すとーん・どらごん",e:"Stone Dragon",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:2000,de:2300,tx:"全身が岩でできているドラゴン。\n岩石の攻撃は強力だ。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザー・ドラゴン",h:"かいざー・どらごん",e:"Kaiser Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:2300,de:2000,tx:"砦を守る翼竜＋フェアリー・ドラゴン",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる魔術師",h:"せいんと・まじしゃん",e:"Magician of Faith",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:400,tx:"①：このカードがリバースした場合、自分の墓地の魔法カード１枚を対象として発動する。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"きまぐれの女神",h:"きまぐれのめがみ",e:"Goddess of Whim",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:950,de:700,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nコイントスを１回行い、裏表を当てる。\n当たった場合、このカードの攻撃力はターン終了時まで倍になる。\nハズレの場合、このカードの攻撃力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"水の魔導師",h:"みずのまどうし",e:"Water Magician",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1400,de:1000,tx:"水で相手の周りを囲んで、包むように攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水",h:"こおりみず",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1150,de:900,tx:"攻撃的なマーメイド。\n体に生えているトゲを使って攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウンディーネ",h:"うんでぃーね",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"水の中をユラユラ漂う妖精。\n水龍を召喚できるらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・エルフ",h:"えんしぇんと・えるふ",e:"Ancient Elf",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1450,de:1200,tx:"何千年も生きているエルフ。\n精霊をあやつり攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"美しき魔物使い",h:"うつくしきまものつかい",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1750,de:1500,tx:"珍しい女性の魔物使い。\nムチを手にすると、性格が変わる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーター・ガール",h:"うぉーたー・がーる",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1250,de:1000,tx:"水を氷の矢のようにして攻撃してくるきれいなお姉さん。",li:"",lk:[],ta:[],al:[]},
  {n:"ホワイト・ドルフィン",h:"ほわいと・どるふぃん",e:"",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:500,de:400,tx:"頭にツノを持つまっしろなイルカ。\n大波をおこして攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"深海に潜むサメ",h:"しんかいにひそむさめ",e:"Deepsea Shark",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:1900,de:1600,tx:"神魚＋舌魚",li:"",lk:[],ta:[],al:[]},
  {n:"メタル・フィッシュ",h:"めたる・ふぃっしゅ",e:"Metal Fish",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1900,tx:"金属の魚。\n鋭いカッターになっている尾ビレで相手を切り刻む。",li:"",lk:[],ta:[],al:[]},
  {n:"神魚",h:"しんぎょ",e:"Bottom Dweller",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1650,de:1700,tx:"水中を優雅に泳ぐ魚の神様。\n怒らせると危険。",li:"",lk:[],ta:[],al:[]},
  {n:"レインボー・フィッシュ",h:"れいんぼー・ふぃっしゅ",e:"7 Colored Fish",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1800,de:800,tx:"世にも珍しい七色の魚。\n捕まえるのはかなり難しい。",li:"",lk:[],ta:[],al:[]},
  {n:"サイボーグ・バス",h:"さいぼーぐ・ばす",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"背中に付いている砲台から、閃光のプラズマキャノンを打ち出す。",li:"",lk:[],ta:[],al:[]},
  {n:"アクア・ドラゴン",h:"あくあ・どらごん",e:"Aqua Dragon",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"海竜族",mo:["融合"],ma:"",tp:"",at:2250,de:1900,tx:"「フェアリー・ドラゴン」＋「海原の女戦士」＋「ゾーン・イーター」",li:"",lk:[],ta:[],al:[]},
  {n:"海の竜王",h:"しー・きんぐ・どらごん",e:"",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:2000,de:1700,tx:"海の王様。\nかたい甲羅を持ち、口からアワをはいて攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ツルプルン",h:"つるぷるん",e:"Turu-Purun",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:450,de:500,tx:"一つ目の奇妙なモンスター。\n手にするもりで、相手をひと突き。",li:"",lk:[],ta:[],al:[]},
  {n:"海を守る戦士",h:"うみをまもるせんし",e:"Sentinel of the Seas",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"海を汚す奴等を徹底的に攻撃する、マーマン戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"アクア・スネーク",h:"あくあ・すねーく",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1050,de:900,tx:"尾の先についている玉で催眠術をかけ、相手をおぼれさせる。",li:"",lk:[],ta:[],al:[]},
  {n:"シーザリオン",h:"しーざりおん",e:"Giant Red Seasnake",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1800,de:800,tx:"海中に住むヘビのモンスター。\n近づくものに噛みついてくる。",li:"",lk:[],ta:[],al:[]},
  {n:"スパイクシードラ",h:"すぱいくしーどら",e:"Spike Seadra",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1600,de:1300,tx:"体のトゲを相手に突き刺し、電流を流して攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"３万年の白亀",h:"３まんねんのしろかめ",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1250,de:2100,tx:"３万年も生きつづけている巨大カメ。\n守備力が高い。",li:"",lk:[],ta:[],al:[]},
  {n:"復讐のカッパ",h:"ふくしゅうのかっぱ",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"仲間を殺され、復讐のために心を悪に売ってしまったカッパ。",li:"",lk:[],ta:[],al:[]},
  {n:"カニカブト",h:"かにかぶと",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:650,de:900,tx:"カニのモンスター。\n両手の大きなハサミで相手を切り刻む。",li:"",lk:[],ta:[],al:[]},
  {n:"ザリガン",h:"ざりがん",e:"",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:600,de:700,tx:"ザリガニが進化したモンスター。\n大きなハサミで相手の首を狙う。",li:"",lk:[],ta:[],al:[]},
  {n:"千年ゴーレム",h:"せんねんごーれむ",e:"Millennium Golem",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:2000,de:2200,tx:"千年もの間、財宝を守りつづけてきたゴーレム。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊のゴーレム",h:"はかいのごーれむ",e:"Destroyer Golem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"大きな右手が特徴のゴーレム。\n右手で押しつぶして攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガンロック",h:"がんろっく",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1000,de:1300,tx:"両肩についているマシンガンを乱射しながら体当たりをする。",li:"",lk:[],ta:[],al:[]},
  {n:"岩の戦士",h:"いわのせんし",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1300,de:1200,tx:"非常に重たい岩石の剣を振り回す、岩石の戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"ストーン・ゴースト",h:"すとーん・ごーすと",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"怒らせると、おつむが大噴火。\n岩がゴロゴロ降ってくるぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"雷神の怒り",h:"らいじんのいかり",e:"Kaminari Attack",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"雷族",mo:["融合"],ma:"",tp:"",at:1900,de:1400,tx:"「エンゼル・イヤーズ」＋「メガ・サンダーボール」",li:"",lk:[],ta:[],al:[]},
  {n:"地雷獣",h:"じらいじゅう",e:"Tripwire Beast",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"強力な電磁波をバリバリとまわりに放出して攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ボルト・エスカルゴ",h:"ぼると・えすかるご",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"ネバネバの液をはきかけ、動けなくなったところに電撃アタック！",li:"",lk:[],ta:[],al:[]},
  {n:"ボルト・ペンギン",h:"ぼると・ぺんぎん",e:"Bolt Penguin",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"両腕の電撃ムチで相手をマヒさせ、首を絞めて攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"雷仙人",h:"かみなりせんにん",e:"The Immortal of Thunder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1300,tx:"リバース：３０００ライフポイント回復する。\nこのカードがフィールド上から墓地に送られた時、５０００ライフポイントを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"エレクトリック・スネーク",h:"えれくとりっく・すねーく",e:"Electric Snake",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"このカードが相手のカードの効果によって手札から墓地へ捨てられた時、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウイング・イーグル",h:"ういんぐ・いーぐる",e:"",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"はるか上空から獲物をさがし、狙った獲物は逃さない。",li:"",lk:[],ta:[],al:[]},
  {n:"裁きの鷹",h:"さばきのたか",e:"Punished Eagle",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:2100,de:1800,tx:"冠を戴く蒼き翼＋コケ",li:"",lk:[],ta:[],al:[]},
  {n:"スカイ・ハンター",h:"すかい・はんたー",e:"Skull Red Bird",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1550,de:1200,tx:"羽に隠し持っているナイフを空から降らせて攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"紅陽鳥",h:"こうようちょう",e:"Crimson Sunbird",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:2300,de:1800,tx:"セイント・バード＋スカイ・ハンター",li:"",lk:[],ta:[],al:[]},
  {n:"クイーン・バード",h:"くいーん・ばーど",e:"Queen Bird",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1200,de:2000,tx:"大きなくちばしで相手をつついて攻撃する。\n守備力が高い。",li:"",lk:[],ta:[],al:[]},
  {n:"青い忍者",h:"ぶるーにんじゃ",e:"Armed Ninja",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードがリバースした場合、フィールドの表側表示の魔法カード１枚またはフィールドにセットされた魔法・罠カード１枚を対象として発動する。\nその魔法カードを破壊する（そのカードがフィールドにセットされている場合、めくって確認する）。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・ゴースト",h:"まじかる・ごーすと",e:"Magical Ghost",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"相手に魔法をかけて、恐怖と混乱におとしいれ攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソウル・ハンター",h:"そうる・はんたー",e:"Soul Hunter",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合"],ma:"",tp:"",at:2200,de:1800,tx:"ランプの魔人＋異次元からの侵略者",li:"",lk:[],ta:[],al:[]},
  {n:"エア・イーター",h:"えあ・いーたー",e:"",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2100,de:1600,tx:"周囲の空気を食べてしまい、相手を窒息させるモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"朱雀",h:"すざく",e:"Vermillion Sparrow",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["融合"],ma:"",tp:"",at:1900,de:1500,tx:"「赤き剣のライムンドス」＋「炎の魔神」",li:"",lk:[],ta:[],al:[]},
  {n:"シーカーメン",h:"しーかーめん",e:"Sea Kamen",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1100,de:1300,tx:"鋭いかぎづめで相手を切り裂く、残忍なモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"キラー・スネーク",h:"きらー・すねーく",e:"Sinister Serpent",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:300,de:250,tx:"「キラー・スネーク」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分スタンバイフェイズに発動できる。\nこのカードを手札に戻す。\n次の相手エンドフェイズに自分の墓地の「キラー・スネーク」１体を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガニグモ",h:"がにぐも",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"はさみを持つクモ。\n相手を糸で動きを封じ、カニばさみでしとめる。",li:"",lk:[],ta:[],al:[]},
  {n:"インセクション",h:"いんせくしょん",e:"Alinsection",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:950,de:700,tx:"頭のノコギリの他に、腕もノコギリになっているクワガタ。",li:"",lk:[],ta:[],al:[]},
  {n:"空の昆虫兵",h:"そらのこんちゅうへい",e:"Insect Soldiers of the Sky",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"風属性のモンスターを攻撃する場合、ダメージステップの間このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コカローチ・ナイト",h:"こかろーち・ないと",e:"Cockroach Knight",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"このカードが墓地へ送られた時、このカードはデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"クワガタ・アルファ",h:"くわがた・あるふぁ",e:"Kuwagata α",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1250,de:1000,tx:"凶暴なクワガタ。\n相手の首を狙うギロチンカッターに注意。",li:"",lk:[],ta:[],al:[]},
  {n:"バーグラー",h:"ばーぐらー",e:"Burglar",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:850,de:800,tx:"ずるがしこいネズミ。\n左手の大きなかぎづめで攻撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"プラグティカル",h:"ぷらぐてぃかる",e:"Pragtical",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["融合"],ma:"",tp:"",at:1900,de:1500,tx:"トラコドン＋フレイム・ヴァイパー",li:"",lk:[],ta:[],al:[]},
  {n:"ガルヴァス",h:"がるう゛ぁす",e:"",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:2000,de:1700,tx:"ライオンに羽の生えた、獣のような姿をした悪の化身。",li:"",lk:[],ta:[],al:[]},
  {n:"アメーバ",h:"あめーば",e:"Ameba",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:350,tx:"フィールド上に表側表示で存在するこのカードのコントロールが相手に移った時、相手は２０００ポイントダメージを受ける。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"コロガーシ",h:"ころがーし",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:550,de:400,tx:"自分の体の何倍も大きなフンを転がし、相手を押しつぶす。",li:"",lk:[],ta:[],al:[]},
  {n:"ブークー",h:"ぶーくー",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:650,de:500,tx:"本の姿をした魔法使い。\nありとあらゆる魔法が書かれている。",li:"",lk:[],ta:[],al:[]},
  {n:"フラワー・ウルフ",h:"ふらわー・うるふ",e:"Flower Wolf",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["融合"],ma:"",tp:"",at:1800,de:1400,tx:"シルバー・フォング＋魔界のイバラ",li:"",lk:[],ta:[],al:[]},
  {n:"レインボー・フラワー",h:"れいんぼー・ふらわー",e:"Rainbow Flower",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:500,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マグナム・リリィ",h:"まぐなむ・りりぃ",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1100,de:600,tx:"いわゆるてっぽうユリ。\n花粉の弾を飛ばし相手を攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ニードル・ボール",h:"にーどる・ぼーる",e:"Needle Ball",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:750,de:700,tx:"リバース：２０００ライフポイントを払えば、相手ライフに１０００ポイントダメージを与える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クジャック",h:"くじゃっく",e:"Peacock",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1700,de:1500,tx:"美しい羽根を広げる大きなクジャク。\nその羽根を飛ばして攻撃！",li:"",lk:[],ta:[],al:[]},
  {n:"スーパースター",h:"すーぱーすたー",e:"Hoshiningen",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:700,tx:"①：フィールドの光属性モンスターの攻撃力は５００アップし、闇属性モンスターの攻撃力は４００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"マハー・ヴァイロ",h:"まはー・う゛ぁいろ",e:"Maha Vailo",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1550,de:1400,tx:"①：このカードの攻撃力は、このカードに装備された装備カードの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レインボー・マリン・マーメイド",h:"れいんぼー・まりん・まーめいど",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1550,de:1700,tx:"空に大きな虹の橋がかかると現れる、珍しいマーメイド。",li:"",lk:[],ta:[],al:[]},
  {n:"音楽家の帝王",h:"みゅーじしゃん・きんぐ",e:"Musician King",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:1750,de:1500,tx:"「黒き森のウィッチ」＋「ハイ・プリーステス」",li:"",lk:[],ta:[],al:[]},
  {n:"ウィルミー",h:"うぃるみー",e:"Wilmee",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"かなり凶暴なウサギ。\n鋭いかぎづめで、相手を血祭りにあげる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヤドカリュー",h:"やどかりゅー",e:"Yado Karu",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:1700,tx:"このカードの表示形式が攻撃表示から表側守備表示に変わった時、自分の手札からカードを任意の枚数デッキの一番下に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"モリンフェン",h:"もりんふぇん",e:"Morinphen",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1550,de:1300,tx:"長い腕とかぎづめが特徴の奇妙な姿をした悪魔。",li:"",lk:[],ta:[],al:[]},
  {n:"青虫",h:"きゃたぴらー",e:"",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:250,de:300,tx:"糸をはき攻撃する。\nどんなムシに成長するか分からない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜殺者",h:"どらごん・きらー",e:"Dragon Seeker",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:2100,tx:"このカードがフィールド上に召喚・反転召喚された時、表側表示のドラゴン族モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"人喰い虫",h:"ひとくいむし",e:"Man-Eater Bug",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:450,de:600,tx:"①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴヒューマン",h:"どらごひゅーまん",e:"D. Human",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"ドラゴンのキバで作った剣を振るう、ドラゴンの力を持つ戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"タートル・狸",h:"たーとる・たぬき",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:700,de:900,tx:"カメの甲羅を背負ったタヌキ。\n相手を化かして攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界植物",h:"まかいしょくぶつ",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"表面から強力な酸を出して、近づく者を溶かしてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"プリズマン",h:"ぷりずまん",e:"Prisman",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"透き通った水晶の塊。\n光を集めてレーザーを放つ。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲール・ドグラ",h:"げーる・どぐら",e:"Gale Dogra",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:650,de:600,tx:"①：３０００LPを払って発動できる。\n自分のEXデッキからモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"クレイジー・フィッシュ",h:"くれいじー・ふぃっしゅ",e:"Crazy Fish",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"鋭くとがっている頭の先を突き出して、攻撃してくるトビウオ。",li:"",lk:[],ta:[],al:[]},
  {n:"メカ・ザウルス",h:"めか・ざうるす",e:"Cyber Saurus",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:1800,de:1400,tx:"「ミスター・ボンバー」＋「二頭を持つキング・レックス」",li:"",lk:[],ta:[],al:[]},
  {n:"ブラキオレイドス",h:"ぶらきおれいどす",e:"Bracchio-raidus",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"恐竜族",mo:["融合"],ma:"",tp:"",at:2200,de:2000,tx:"二頭を持つキング・レックス＋屍を貪る竜",li:"",lk:[],ta:[],al:[]},
  {n:"笑う花",h:"わらうはな",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:900,de:500,tx:"いつも笑っている花。\n笑い声を聞きつづけると、頭が混乱する。",li:"",lk:[],ta:[],al:[]},
  {n:"ビーン・ソルジャー",h:"びーん・そるじゃー",e:"Bean Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1400,de:1300,tx:"剣やマメを使って攻撃してくる植物戦士。\n意外と強いぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"キャノン・ソルジャー",h:"きゃのん・そるじゃー",e:"Cannon Soldier",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"自分フィールド上に存在するモンスター１体をリリースする事で、相手ライフに５００ポイントダメージを与える。",li:0,lk:[],ta:[],al:[]},
  {n:"王室前のガーディアン",h:"おうしつまえのがーでぃあん",e:"Guardian of the Throne Room",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1650,de:1600,tx:"王室をガードする衛兵ロボ。\n当たるまで追い続けるミサイルを撃つ。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイブ・シザー",h:"ぶれいぶ・しざー",e:"Brave Scizzar",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"何本も持っているハサミを器用に動かし、相手を切り刻む。",li:"",lk:[],ta:[],al:[]},
  {n:"イースター島のモアイ",h:"いーすたーとうのもあい",e:"The Statue of Easter Island",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1100,de:1400,tx:"イースター島に存在する石像。\n口から丸いレーザーをはく。",li:"",lk:[],ta:[],al:[]},
  {n:"ムカムカ",h:"むかむか",e:"Muka Muka",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:300,tx:"このカードの攻撃力・守備力は、自分の手札の枚数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サンド・ストーン",h:"さんど・すとーん",e:"Sand Stone",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1300,de:1600,tx:"地下から突然目の前に現れ、触手で攻撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"岩石カメッター",h:"がんせきかめったー",e:"",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1450,de:2200,tx:"全身が岩石でできているカメ。\n非常に高い守備が特徴。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・クラーケン",h:"ふぁいやー・くらーけん",e:"Fire Kraken",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"水の中でもボウボウと燃え盛る炎に包まれたイカ。",li:"",lk:[],ta:[],al:[]},
  {n:"タートル・バード",h:"たーとる・ばーど",e:"Turtle Bird",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1900,de:1700,tx:"主に水中に生息しているが、空を飛ぶこともできる珍しいカメ。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・バード",h:"です・ばーど",e:"Skullbird",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:1900,de:1700,tx:"タクヒ＋髑髏の寺院",li:"",lk:[],ta:[],al:[]},
  {n:"巨大な怪鳥",h:"きょだいなかいちょう",e:"",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:2000,de:1900,tx:"あまり見かけない、とても大きなトリ。\n空から急降下して襲いかかる。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔の調理師",h:"でびる・こっく",e:"The Bistro Butcher",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"スター・ボーイ",h:"すたー・ぼーい",e:"Star Boy",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:550,de:500,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する水属性モンスターの攻撃力は５００ポイントアップし、炎属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"山の精霊",h:"やまのせいれい",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1300,de:1800,tx:"手にする笛の音を聞いた者は、力が抜けてしまうと言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"首狩り魔人",h:"ねっく・はんたー",e:"Neck Hunter",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1750,de:1900,tx:"大きなカマを振り回しクビを狩る悪魔。\n大きな目からビームも出す。",li:"",lk:[],ta:[],al:[]},
  {n:"ミリス・レディエント",h:"みりす・れでぃえんと",e:"Milus Radiant",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:300,de:250,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する地属性モンスターの攻撃力は５００ポイントアップし、風属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"クラッシュマン",h:"くらっしゅまん",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"意外に素早く動き回り、丸まって体当たりをしてくる。",li:"",lk:[],ta:[],al:[]},
  {n:"フレイム・ケルベロス",h:"ふれいむ・けるべろす",e:"Flame Cerebrus",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:2100,de:1800,tx:"全身が炎に包まれた魔獣。\n相手を地獄の炎で処刑する。",li:"",lk:[],ta:[],al:[]},
  {n:"エルディーン",h:"えるでぃーん",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:950,de:1000,tx:"手にする杖を使い、様々な魔法で攻撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"砂の魔女",h:"さんど・うぃっち",e:"Mystical Sand",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["融合"],ma:"",tp:"",at:2100,de:1700,tx:"岩石の巨兵＋エンシェント・エルフ",li:"",lk:[],ta:[],al:[]},
  {n:"ヂェミナイ・エルフ",h:"ぢぇみない・えるふ",e:"Gemini Elf",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1900,de:900,tx:"交互に攻撃を仕掛けてくる、エルフの双子姉妹。",li:"",lk:[],ta:[],al:[]},
  {n:"クワガー・ヘラクレス",h:"くわがー・へらくれす",e:"Kwagar Hercules",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["融合"],ma:"",tp:"",at:1900,de:1700,tx:"「クワガタ・アルファ」＋「ヘラクレス・ビートル」",li:"",lk:[],ta:[],al:[]},
  {n:"イビー",h:"いびー",e:"Minar",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:850,de:750,tx:"このカードが相手のカードの効果によって手札から墓地へ捨てられた時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"カマキラー",h:"かまきらー",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1150,de:1400,tx:"二本のカマで相手に襲いかかる、人型のカマキリモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"メカレオン",h:"めかれおん",e:"",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"身体の色を変化させ、どんな場所にでも隠れることができる。",li:"",lk:[],ta:[],al:[]},
  {n:"メガ・サンダーボール",h:"めが・さんだーぼーる",e:"Mega Thunderball",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:750,de:600,tx:"地面を転がり回り、周囲に電撃を放つボール。",li:"",lk:[],ta:[],al:[]},
  {n:"コケ",h:"こけ",e:"Niwatori",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"相手を丸飲みにして、自分のエネルギーとして取り込んでしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"デッド・シャーク",h:"でっど・しゃーく",e:"Corroding Shark",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"海をさまようアンデットのサメ。\n出会った者に死の呪いをかける。",li:"",lk:[],ta:[],al:[]},
  {n:"スケルエンジェル",h:"すけるえんじぇる",e:"Skelengel",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:400,tx:"①：このカードがリバースした場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハネハネ",h:"はねはね",e:"Hane-Hane",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:450,de:500,tx:"①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイル",h:"ないる",e:"Misairuzame",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1400,de:1600,tx:"全身にハリが生えている魚。\n腹の下からミサイルを発射。",li:"",lk:[],ta:[],al:[]},
  {n:"舌魚",h:"たんぎょ",e:"Tongyo",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1350,de:800,tx:"他の魚を長い舌で捕まえ、エネルギーを吸収する。",li:"",lk:[],ta:[],al:[]},
  {n:"大砲だるま",h:"たいほうだるま",e:"Dharma Cannon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:500,tx:"大砲で埋め尽くされているメカだるま。\nねらいは外さない。",li:"",lk:[],ta:[],al:[]},
  {n:"スケルゴン",h:"すけるごん",e:"Skelgon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["融合"],ma:"",tp:"",at:1700,de:1900,tx:"メデューサの亡霊＋暗黒の竜王",li:"",lk:[],ta:[],al:[]},
  {n:"魚ギョ戦士",h:"ぎょぎょせんし",e:"Wow Warrior",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1250,de:900,tx:"魚に手足が生えた魚人獣。\n鋭い歯でかみついてくる。",li:"",lk:[],ta:[],al:[]},
  {n:"グリグル",h:"ぐりぐる",e:"Griggle",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:350,de:300,tx:"フィールド上に表側表示で存在するこのカードのコントロールが相手に移った時、自分は３０００ライフポイント回復する。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"骨ネズミ",h:"ほねねずみ",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"ネコにやられた恨みをはらすため、アンデットとして蘇ったネズミ。",li:"",lk:[],ta:[],al:[]},
  {n:"カエルスライム",h:"かえるすらいむ",e:"Slime Toad",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:700,de:500,tx:"カエルの頭の形をしたスライム。\nゲコゲコひどい歌を聴かせて攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"ベヒゴン",h:"べひごん",e:"Behegon",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1350,de:1000,tx:"かなり変わった海ヘビ。\n大きな口と大きなキバが特徴。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・エルフ",h:"だーく・えるふ",e:"Dark Elf",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカードは１０００ライフポイント払わなければ攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイバーン",h:"わいばーん",e:"Winged Dragon, Guardian of the Fortress #2",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"羽をはばたかせて強力な竜巻をおこす。",li:"",lk:[],ta:[],al:[]},
  {n:"マタンゴ",h:"またんご",e:"Mushroom Man #2",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1250,de:800,tx:"自分のスタンバイフェイズ毎に、コントローラーに３００ポイントダメージを与える。\n自分のエンドフェイズに５００ライフポイントを払えば、このカードのコントロールは相手に移る。",li:"",lk:[],ta:[],al:[]},
  {n:"バーバリアン１号",h:"ばーばりあん１ごう",e:"Lava Battleguard",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1550,de:1800,tx:"①：自分フィールドの「バーバリアン２号」１体につき、このカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ドラゴン",h:"れっど・どらごん",e:"Tyhone #2",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1700,de:1900,tx:"ファイヤーボールをはき辺りを火の海にする、真っ赤なドラゴン。",li:"",lk:[],ta:[],al:[]},
  {n:"さまよえる亡者",h:"さまよえるもうじゃ",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"成仏できず、行くあてもなくフラフラとしているモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼鉄の巨神像",h:"こうてつのきょしんぞう",e:"Steel Ogre Grotto #1",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1800,tx:"機械の国にまつられているという、鋼鉄の巨神像。",li:"",lk:[],ta:[],al:[]},
  {n:"ポット・ザ・トリック",h:"ぽっと・ざ・とりっく",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:400,de:400,tx:"魔術師の命令通りに動く使い魔。\nあまり強くない。",li:"",lk:[],ta:[],al:[]},
  {n:"オシロ・ヒーロー",h:"おしろ・ひーろー",e:"Oscillo Hero",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1250,de:700,tx:"異次元の世界からやってきた、なんだかよく分からない戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元からの侵略者",h:"いじげんからのしんりゃくしゃ",e:"Invader from Another Dimension",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:950,de:1400,tx:"銀河系の外から地球にやってきた宇宙人。",li:"",lk:[],ta:[],al:[]},
  {n:"レッサー・ドラゴン",h:"れっさー・どらごん",e:"Lesser Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"あまり強くなく、ブレス攻撃もやらない低級のドラゴン。",li:"",lk:[],ta:[],al:[]},
  {n:"ニードルワーム",h:"にーどるわーむ",e:"Needle Worm",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:750,de:600,tx:"リバース：相手のデッキの上からカードを５枚墓地へ捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"屋根裏の物の怪",h:"やねうらのもののけ",e:"Wretched Ghost of the Attic",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:550,de:400,tx:"どの家の屋根裏にも潜んでいるもののけ。\n特にわるさはしない。",li:"",lk:[],ta:[],al:[]},
  {n:"金色の魔象",h:"きんいろのまぞう",e:"Great Mammoth of Goldfine",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["融合"],ma:"",tp:"",at:2200,de:1800,tx:"メデューサの亡霊＋ドラゴン・ゾンビ",li:"",lk:[],ta:[],al:[]},
  {n:"黒き人食い鮫",h:"くろきひとくいざめ",e:"Man-eating Black Shark",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:2100,de:1300,tx:"「シーカーメン」＋「キラー・ブロッブ」＋「海原の女戦士」",li:"",lk:[],ta:[],al:[]},
  {n:"ヨルムンガルド",h:"よるむんがるど",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"神話の世界に出てくるヘビ。\n非常に長い。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界のイバラ",h:"まかいのいばら",e:"Darkworld Thorns",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"魔界に生息するイバラ。\n無理に通ろうとする者にからみつく。",li:"",lk:[],ta:[],al:[]},
  {n:"恐竜人",h:"きょうりゅうじん",e:"Anthrosaurus",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1000,de:850,tx:"人型の恐竜。\n高い知能を持つが、あまり強くはない。",li:"",lk:[],ta:[],al:[]},
  {n:"生き血をすするもの",h:"いきちをすするもの",e:"Drooling Lizard",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"暗闇の中、道行く人々を襲う人型の吸血ヘビ。",li:"",lk:[],ta:[],al:[]},
  {n:"トラコドン",h:"とらこどん",e:"Trakodon",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1300,de:800,tx:"トラ柄の恐竜。\n荒野を駆け抜けるスピードはかなり速い。",li:"",lk:[],ta:[],al:[]},
  {n:"密林の黒竜王",h:"みつりんのこくりゅうおう",e:"",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2100,de:1800,tx:"密林に生息する、漆黒のドラゴン。\nバリバリと木を食べる。",li:"",lk:[],ta:[],al:[]},
  {n:"裁きを下す女帝",h:"さばきをくだすじょてい",e:"Empress Judge",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:2100,de:1700,tx:"女王の影武者＋響女",li:"",lk:[],ta:[],al:[]},
  {n:"ベビー・ティーレックス",h:"べびー・てぃーれっくす",e:"Little D",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"ティラノサウルスの子供。\n非常に凶暴な性格をしている。",li:"",lk:[],ta:[],al:[]},
  {n:"黒き森のウィッチ",h:"くろきもりのうぃっち",e:"Witch of the Black Forest",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから守備力１５００以下のモンスター１体を手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"深き森の長老",h:"ふかきもりのちょうろう",e:"Ancient One of the Deep Forest",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1800,de:1900,tx:"昔から森に住んでいる白ヤギ。\n真の姿は森の長老。",li:"",lk:[],ta:[],al:[]},
  {n:"ツンドラの大蠍",h:"つんどらのおおさそり",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"砂漠ではなく、ツンドラに分布する珍しい真っ青なサソリ。",li:"",lk:[],ta:[],al:[]},
  {n:"カラス天狗",h:"からすてんぐ",e:"",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1850,de:1600,tx:"様々なことを知っている天狗。\n神通力が使えるという。",li:"",lk:[],ta:[],al:[]},
  {n:"レオ・ウィザード",h:"れお・うぃざーど",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1350,de:1200,tx:"黒いマントをはおった魔術師。\n正体は言葉を話すシシ。",li:"",lk:[],ta:[],al:[]},
  {n:"深淵に咲く花",h:"しんえんにさくはな",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:750,de:400,tx:"光の届かない深淵にひっそりと咲く、あまり見かけない花。",li:"",lk:[],ta:[],al:[]},
  {n:"パトロール・ロボ",h:"ぱとろーる・ろぼ",e:"Patrol Robo",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:900,tx:"このカードがフィールド上に表側表示で存在する限り、自分のスタンバイフェイズ時に相手フィールド上にセットされたカード１枚を確認する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"タクヒ",h:"たくひ",e:"Takuhee",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1450,de:1000,tx:"このトリが現れた時は、何か不吉な事が起こる前ぶれ。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァルキリー",h:"う゛ぁるきりー",e:"Dark Witch",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"神話に出てくる闘いの天使。\n手にする槍で天罰を下す。",li:"",lk:[],ta:[],al:[]},
  {n:"ウェザー・レポート",h:"うぇざー・れぽーと",e:"Weather Report",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["リバース","効果"],ma:"",tp:"",at:950,de:1500,tx:"リバース：相手フィールド上に表側表示で存在する「光の護封剣」を全て破壊する。\n破壊に成功した場合、次の自分のバトルフェイズを２回行う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"封印の鎖",h:"ふういんのくさり",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1000,de:1100,tx:"相手をギリギリと締め上げて、封印を施す力を持つ。",li:"",lk:[],ta:[],al:[]},
  {n:"メカニカルスネイル",h:"めかにかるすねいる",e:"Mechanical Snail",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"機械に改造されたカタツムリ。\nしかし、スピードはあまり変わらない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドッペルゲンガー",h:"どっぺるげんがー",e:"Greenkappa",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:650,de:900,tx:"リバース：フィールド上にセットされた魔法・罠カード２枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"モン・ラーバス",h:"もん・らーばす",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"ラーバスがより進化したけもの。\n力がパワーアップしている。",li:"",lk:[],ta:[],al:[]},
  {n:"命ある花瓶",h:"いのちあるかびん",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:900,de:1100,tx:"生けてある花から、花粉を飛ばし噛みついてくる生きている花瓶。",li:"",lk:[],ta:[],al:[]},
  {n:"テンタクル・プラント",h:"てんたくる・ぷらんと",e:"",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:500,de:600,tx:"近くに動く者がいると、青いつるを伸ばして攻撃してくる。",li:"",lk:[],ta:[],al:[]},
  {n:"くちばしヘビ",h:"くちばしへび",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"相手を長い体で締め上げ、大きなくちばしでつついて攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"メタモルポット",h:"めたもるぽっと",e:"Morphing Jar",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:700,de:600,tx:"①：このカードがリバースした場合に発動する。\nお互いの手札を全て捨てる。\nその後、お互いはデッキから５枚ドローする。",li:1,lk:[],ta:[],al:[]},
  {n:"ミューズの天使",h:"みゅーずのてんし",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:850,de:900,tx:"芸術家の天使。\n特にハープの演奏は、右に出る者はいない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎を食らう大亀",h:"ほのおをくらうおおがめ",e:"Giant Turtle Who Feeds on Flames",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1400,de:1800,tx:"真っ赤な甲羅が特徴のカメ。\n炎を食べると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"バラに棲む悪霊",h:"ばらにすむあくりょう",e:"Rose Spectre of Dunn",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"植物族",mo:["融合"],ma:"",tp:"",at:2000,de:1800,tx:"グレムリン＋スネーク・パーム",li:"",lk:[],ta:[],al:[]},
  {n:"アブソリューター",h:"あぶそりゅーたー",e:"",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"相手を鏡の中の世界に吸い込むことができる。",li:"",lk:[],ta:[],al:[]},
  {n:"大食いグール",h:"おおぐいぐーる",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"どんなに食べても、おなかがいっぱいになることは無いというモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"ペイルビースト",h:"ぺいるびーすと",e:"Pale Beast",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"青白い肌をした、気味の悪い正体不明のモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"リトル・キメラ",h:"りとる・きめら",e:"Little Chimera",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:550,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上の炎属性モンスターの攻撃力は５００ポイントアップし、水属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"スコール",h:"すこーる",e:"Violent Rain",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1550,de:800,tx:"バケツをひっくり返したような大雨を、突然降らすモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"ロックメイス",h:"ろっくめいす",e:"Key Mace #2",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1050,de:1200,tx:"心の良い部分を封印して、相手を悪魔の手先にしてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"テンダネス",h:"てんだねす",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:700,de:1400,tx:"恋人たちの永遠を祝福する、かわいらしい天使。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン・ソルジャー",h:"ぺんぎん・そるじゃー",e:"Penguin Soldier",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["リバース","効果"],ma:"",tp:"",at:750,de:500,tx:"①：このカードがリバースした場合、フィールドのモンスターを２体まで対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フェアリー・ドラゴン",h:"ふぇありー・どらごん",e:"Fairy Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1100,de:1200,tx:"妖精の中では意外と強い、とてもきれいなドラゴンの妖精。",li:"",lk:[],ta:[],al:[]},
  {n:"イビル・ラット",h:"いびる・らっと",e:"Obese Marmot of Nefariousness",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:750,de:800,tx:"どんな物にでもかじりつく、行儀の悪い野ネズミ。",li:"",lk:[],ta:[],al:[]},
  {n:"リクイド・ビースト",h:"りくいど・びーすと",e:"Liquid Beast",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:950,de:800,tx:"ドロドロ溶けて、水の中を自在に移動できる液体生命体。",li:"",lk:[],ta:[],al:[]},
  {n:"ツインテール",h:"ついんてーる",e:"Twin Long Rods #2",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:850,de:700,tx:"ムチのようなしっぽ２本を振り回し攻撃する、水中生物。",li:"",lk:[],ta:[],al:[]},
  {n:"グレード・ビル",h:"ぐれーど・びる",e:"Great Bill",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1250,de:1300,tx:"どんなものでも丸飲みできる大きな口を持っている。",li:"",lk:[],ta:[],al:[]},
  {n:"フレンドシップ",h:"ふれんどしっぷ",e:"Shining Friendship",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"デュエル中ケンカをしても、友情を伝え仲直りをさせる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレードフライ",h:"ぶれーどふらい",e:"Bladefly",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:600,de:700,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する風属性モンスターの攻撃力は５００ポイントアップし、地属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"でんきトカゲ",h:"でんきとかげ",e:"Electric Lizard",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:850,de:800,tx:"アンデット族以外のモンスターがこのカードを攻撃した場合、そのモンスターは次のターン攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔の偵察者",h:"あくまのていさつしゃ",e:"Hiro's Shadow Scout",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:650,de:500,tx:"リバース：相手はデッキからカードを３枚ドローする。\nこの効果でドローしたカードをお互いに確認し、その中から魔法カードを全て墓地へ捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイ・プリーステス",h:"はい・ぷりーすてす",e:"Lady of Faith",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"聞いたことのない呪文を唱え、あらぶる心をしずめてくれる。",li:"",lk:[],ta:[],al:[]},
  {n:"双頭の雷龍",h:"そうとうのさんだー・どらごん",e:"Twin-Headed Thunder Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["融合"],ma:"",tp:"",at:2800,de:2100,tx:"「サンダー・ドラゴン」＋「サンダー・ドラゴン」",li:"",lk:[],ta:[],al:[]},
  {n:"ハンター・スパイダー",h:"はんたー・すぱいだー",e:"Hunter Spider",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"クモの巣の罠を仕掛け狩りをする。\n罠にかかったものは食べてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマード・スターフィッシュ",h:"あーまーど・すたーふぃっしゅ",e:"Armored Starfish",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:850,de:1400,tx:"表面がかたく守備力が比較的高い、青っぽいヒトデ。",li:"",lk:[],ta:[],al:[]},
  {n:"勇気の砂時計",h:"ゆうきのすなどけい",e:"Hourglass of Courage",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカードが召喚・反転召喚に成功した時、このカードの元々の攻撃力・守備力は次の自分のターンのエンドフェイズ時まで半分になる。\nその後、このカードの元々の攻撃力・守備力は倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"マリン・ビースト",h:"まりん・びーすと",e:"Marine Beast",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:1700,de:1600,tx:"水の魔導師＋ベヒゴン",li:"",lk:[],ta:[],al:[]},
  {n:"戦場の死装束",h:"せんじょうのしにしょうぞく",e:"Warrior of Tradition",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1900,de:1700,tx:"音女＋斬首の美女",li:"",lk:[],ta:[],al:[]},
  {n:"岩石の精霊",h:"がんせきのせいれい",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1650,de:1900,tx:"はにわみたいだが岩石の精霊。\n攻撃・守備ともにかなり強い。",li:"",lk:[],ta:[],al:[]},
  {n:"スネーク・パーム",h:"すねーく・ぱーむ",e:"Snakeyashi",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"多くのヘビが集まり擬態している。\n近づくとバラバラになり襲いかかる。",li:"",lk:[],ta:[],al:[]},
  {n:"サキュバス・ナイト",h:"さきゅばす・ないと",e:"Succubus Knight",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1650,de:1300,tx:"魔法を唱え、相手を血祭りにあげる悪魔の魔法戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"セイレーン",h:"せいれーん",e:"",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"風を操り突風をまきおこし、ありとあらゆる物を吹き飛ばす。",li:"",lk:[],ta:[],al:[]},
  {n:"泥に潜み棲むもの",h:"どろにひそみすむもの",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"足下がドロドロと溶けだしたら、こいつが現れる前兆だ。",li:"",lk:[],ta:[],al:[]},
  {n:"満ち潮のマーマン",h:"みちしおのまーまん",e:"High Tide Gyojin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1650,de:1300,tx:"水の中を自在に泳ぐ、半魚人の戦士。\n攻撃は強い。",li:"",lk:[],ta:[],al:[]},
  {n:"泉の妖精",h:"いずみのようせい",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1600,de:1100,tx:"泉を守る妖精。\n泉を汚す者を容赦なく攻撃。",li:"",lk:[],ta:[],al:[]},
  {n:"海原の女戦士",h:"うなばらのおんなせんし",e:"Amazon of the Seas",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"海の神に仕えるマーメイド。\n神聖な領域を守っている。",li:"",lk:[],ta:[],al:[]},
  {n:"キャット・レディ",h:"きゃっと・れでぃ",e:"Nekogal #2",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:2000,tx:"素早い動きで攻撃をかわし、鋭いかぎづめで襲いかかる。",li:"",lk:[],ta:[],al:[]},
  {n:"見習い魔女",h:"みならいまじょ",e:"Witch's Apprentice",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:550,de:500,tx:"①：フィールドの闇属性モンスターの攻撃力は５００アップし、光属性モンスターの攻撃力は４００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧ネズミ",h:"よろいねずみ",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:950,de:1100,tx:"鎧のようにかたい毛で体を守ることができるネズミ。",li:"",lk:[],ta:[],al:[]},
  {n:"古代のトカゲ戦士",h:"こだいのとかげせんし",e:"Ancient Lizard Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1400,de:1100,tx:"太古の、昔の姿そのままのトカゲの戦士。\n意外に強いぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"月明かりの乙女",h:"つきあかりのおとめ",e:"Maiden of the Moonlight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1500,de:1300,tx:"月に守護されている月の魔導士。\n神秘的な魔法で幻想を見せる。",li:"",lk:[],ta:[],al:[]},
  {n:"ストーンジャイアント",h:"すとーんじゃいあんと",e:"Stone Ogre Grotto",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"巨大な岩が積み重なってできている、岩石の巨人。",li:"",lk:[],ta:[],al:[]},
  {n:"誕生の天使",h:"たんじょうのてんし",e:"",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1400,de:1700,tx:"女性のおなかに命が宿った事を知らせてくれると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイト・リザード",h:"ないと・りざーど",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1150,de:1300,tx:"海を守る、緑のウロコに身を包むリザードマンの戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"女王の影武者",h:"じょおうのかげむしゃ",e:"Queen's Double",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:350,de:300,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"冠を戴く蒼き翼",h:"かんむりをいただくあおきつばさ",e:"Blue-Winged Crown",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"頭の毛が冠のように見える、青白く燃えるトリ。",li:"",lk:[],ta:[],al:[]},
  {n:"トレント",h:"とれんと",e:"Trent",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"まだまだ成長し続けている森の大木。\n森の守り神。",li:"",lk:[],ta:[],al:[]},
  {n:"紅葉の女王",h:"こうようのじょおう",e:"Queen of Autumn Leaves",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"鮮やかな紅葉に囲まれて暮らす、緑樹の霊王のお妃。",li:"",lk:[],ta:[],al:[]},
  {n:"水陸両用バグロス",h:"すいりくりょうようばぐろす",e:"Amphibious Bugroth",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["融合"],ma:"",tp:"",at:1850,de:1300,tx:"「陸戦型 バグロス」＋「海を守る戦士」",li:"",lk:[],ta:[],al:[]},
  {n:"アシッドクロウラー",h:"あしっどくろうらー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"巨大ないもむし。\n強力な酸をはき、何でも溶かしてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"王座の侵略者",h:"おうざのしんりゃくしゃ",e:"Invader of the Throne",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1350,de:1700,tx:"リバース：相手フィールド上に存在するモンスター１体を選択し、そのモンスターとこのカードのコントロールを入れ替える。\nこの効果はバトルフェイズに発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"イリュージョン・シープ",h:"いりゅーじょん・しーぷ",e:"Mystical Sheep #1",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1150,de:900,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ディスク・マジシャン",h:"でぃすく・まじしゃん",e:"Disk Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1350,de:1000,tx:"自らを円盤の中に封印し、攻撃をするとき実体を出す。",li:"",lk:[],ta:[],al:[]},
  {n:"フレイム・ヴァイパー",h:"ふれいむ・う゛ぁいぱー",e:"Flame Viper",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:400,de:450,tx:"シュルシュルと素早く動き、口から火炎をはくマムシ。",li:"",lk:[],ta:[],al:[]},
  {n:"ロイヤルガード",h:"ろいやるがーど",e:"",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1900,de:2200,tx:"王族を守るために開発された、意志を持つ機械の兵隊。",li:"",lk:[],ta:[],al:[]},
  {n:"キラー・ブロッブ",h:"きらー・ぶろっぶ",e:"Gruesome Goo",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1300,de:700,tx:"スライムの親分。\n見た目は同じだが、なめてかかると痛い目にあうぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"ひょうすべ",h:"ひょうすべ",e:"Hyosube",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1500,de:900,tx:"カッパの親分。\n攻撃力は意外と高い。\n守備力は低め。",li:"",lk:[],ta:[],al:[]},
  {n:"マシン・アタッカー",h:"ましん・あたっかー",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1300,tx:"特攻用に作り出した機械。\n突撃で敵をなぎ倒していく。",li:"",lk:[],ta:[],al:[]},
  {n:"響女",h:"ひびきめ",e:"Hibikime",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1450,de:1000,tx:"耳障りな音をガンガン響かせる。\n相手を行動不能にしてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィップテイル・ガーゴイル",h:"うぃっぷている・がーごいる",e:"Whiptail Crow",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1650,de:1600,tx:"ムチのように長いしっぽを使い、空中から襲いかかってくる。",li:"",lk:[],ta:[],al:[]},
  {n:"鎖付きブーメラン",h:"くさりつきぶーめらん",e:"Kunai with Chain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：以下の効果から１つ、または両方を選択してこのカードを発動できる。\n\n●相手モンスターの攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターを守備表示にする。\n\n●自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、その自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"迷宮変化",h:"めいきゅうへんげ",e:"Magical Labyrinth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「迷宮壁－ラビリンス・ウォール－」に装備する。\n「迷宮壁－ラビリンス・ウォール－」と装備したこのカードを生け贄に捧げる事で、デッキから「ウォール・シャドウ」を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦士抹殺",h:"せんしまっさつ",e:"Warrior Elimination",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する戦士族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サラマンドラ",h:"さらまんどら",e:"Salamandra",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"炎属性モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"成仏",h:"じょうぶつ",e:"Eternal Rest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"装備カードを装備したモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大化",h:"きょだいか",e:"Megamorph",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：自分のLPが相手より少ない場合、装備モンスターの攻撃力は元々の攻撃力の倍になる。\n自分のLPが相手より多い場合、装備モンスターの攻撃力は元々の攻撃力の半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル化・魔法反射装甲",h:"めたるか・まほうはんしゃそうこう",e:"Metalmorph",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力・守備力３００アップの装備カード扱いとして、そのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターの攻撃力は、モンスターに攻撃するダメージ計算時のみ、その攻撃対象モンスターの攻撃力の半分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"酸の嵐",h:"さんのあらし",e:"Acid Rain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する機械族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"死のデッキ破壊ウイルス",h:"しのでっきはかいういるす",e:"Crush Card Virus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力１０００以下の闇属性モンスター１体をリリースして発動できる。\n相手フィールドのモンスター及び相手の手札を全て確認し、その内の攻撃力１５００以上のモンスターを全て破壊する。\nその後、相手はデッキから攻撃力１５００以上のモンスターを３体まで選んで破壊できる。\nこのカードの発動後、次のターンの終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"トゲトゲ神の殺虫剤",h:"とげとげしんのさっちゅうざい",e:"Eradicating Aerosol",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の昆虫族モンスターは、すべて破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"神の息吹",h:"かみのいぶき",e:"Breath of Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する岩石族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"永遠の渇水",h:"えいえんのかっすい",e:"Eternal Drought",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の魚族モンスターは、すべて破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"シャイン・キャッスル",h:"しゃいん・きゃっする",e:"Shine Palace",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"光属性モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の呪縛",h:"やみのじゅばく",e:"Shadow Spell",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\n①：対象のモンスターの攻撃力は７００ダウンし、攻撃できず、表示形式の変更もできない。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"カオスの儀式",h:"かおすのぎしき",e:"Black Luster Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス・ソルジャー」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「カオス・ソルジャー」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼラの儀式",h:"ぜらのぎしき",e:"Zera Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ゼラ」の降臨に必要。\n場か手札から、星の数が合計８個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィの羽根帚",h:"はーぴぃのはねぼうき",e:"Harpie's Feather Duster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの魔法・罠カードを全て破壊する。",li:1,lk:[],ta:[],al:[]},
  {n:"ライオンの儀式",h:"らいおんのぎしき",e:"War-Lion Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「スーパー・ウォー・ライオン」の降臨に必要。\n手札・自分フィールド上から、レベルの合計が７以上になるようにモンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔鏡の儀式",h:"あくまかがみのぎしき",e:"Beastly Mirror Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「デビルズ・ミラー」の降臨に必要。\n場か手札から、星の数が合計６個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"踊りによる誘発",h:"おどりによるゆうはつ",e:"Commencement Dance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ダンシング・ソルジャー」の降臨に必要。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければ、「ダンシング・ソルジャー」は降臨できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンバーガーのレシピ",h:"はんばーがーのれしぴ",e:"Hamburger Recipe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ハングリーバーガー」の降臨に必要。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければ、「ハングリーバーガー」は降臨できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ローの祈り",h:"ろーのいのり",e:"Novox's Prayer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ローガーディアン」の降臨に必要。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"粘着テープの家",h:"ねんちゃくてーぷのいえ",e:"House of Adhesive Tape",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が守備力５００以下のモンスターを召喚・反転召喚した時に発動する事ができる。\nそのモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ねずみ取り",h:"ねずみとり",e:"Eatgaboon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が召喚・反転召喚したモンスターの攻撃力が５００以下だった場合、そのモンスター１体を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"硫酸のたまった落とし穴",h:"りゅうさんのたまったおとしあな",e:"Acid Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの裏側守備表示モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にし、守備力が２０００以下の場合は破壊する。\n守備力が２０００より高い場合は裏側守備表示に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"万能地雷グレイモヤ",h:"ばんのうじらいぐれいもや",e:"Widespread Ruin",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの表側攻撃表示モンスターの内、攻撃力が一番高いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天狗のうちわ",h:"てんぐのうちわ",e:"Goblin Fan",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、レベル２以下のモンスターは反転召喚に成功した時に破壊される。\nこの時、そのモンスターはフィールドで発動する効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シモッチによる副作用",h:"しもっちによるふくさよう",e:"Bad Reaction to Simochi",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手ライフを回復する効果は、相手ライフにダメージを与える効果になる。",li:"",lk:[],ta:[],al:[]},
  {n:"あまのじゃくの呪い",h:"あまのじゃくののろい",e:"Reverse Trap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動ターンのエンドフェイズ時まで、攻撃力・守備力のアップ・ダウンの効果は逆になる。",li:"",lk:[],ta:[],al:[]},
  {n:"偽物のわな",h:"にせもののわな",e:"Fake Trap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する罠カードを破壊する魔法・罠・効果モンスターの効果を相手が発動した時に発動する事ができる。\nこのカードを代わりに破壊し、他の自分の罠カードは破壊されない。\nセットされたカードが破壊される場合、そのカードを全てめくって確認する。",li:"",lk:[],ta:[],al:[]},
  {n:"亀の誓い",h:"かめのちかい",e:"Turtle Oath",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「クラブ・タートル」の降臨に必要。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければ、「クラブ・タートル」は降臨できない。",li:"",lk:[],ta:[],al:[]},
  {n:"チャクラの復活",h:"ちゃくらのふっかつ",e:"Resurrection of Chakra",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「チャクラ」の降臨に必要。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャベリンビートルの契約",h:"じゃべりんびーとるのけいやく",e:"Javelin Beetle Pact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ジャベリンビートル」の降臨に必要。\n場か手札から、星の数が合計８個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガルマソードの誓い",h:"がるまそーどのちかい",e:"Garma Sword Oath",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ガルマソード」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が７以上になるようにモンスターをリリースし、手札から「ガルマソード」１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スカルライダーの復活",h:"すかるらいだーのふっかつ",e:"Revival of Dokurorider",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「スカルライダー」の降臨に必要。\n場か手札から、星の数が合計６個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"要塞クジラの誓い",h:"ようさいくじらのちかい",e:"Fortress Whale's Oath",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「要塞クジラ」の降臨に必要。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダンシング・ソルジャー",h:"だんしんぐ・そるじゃー",e:"Performance of Sword",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:1950,de:1850,tx:"「踊りによる誘発」により降臨。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハングリーバーガー",h:"はんぐりーばーがー",e:"Hungry Burger",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:2000,de:1850,tx:"「ハンバーガーのレシピ」により降臨。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"千年原人",h:"せんねんげんじん",e:"Sengenjin",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:2750,de:2500,tx:"どんな時でも力で押し通す、千年アイテムを持つ原始人。",li:"",lk:[],ta:[],al:[]},
  {n:"ローガーディアン",h:"ろーがーでぃあん",e:"Skull Guardian",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:2050,de:2500,tx:"「ローの祈り」により降臨。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"トライホーン・ドラゴン",h:"とらいほーん・どらごん",e:"Tri-Horned Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2850,de:2350,tx:"頭に生えている３本のツノが特徴的な悪魔竜。",li:"",lk:[],ta:[],al:[]},
  {n:"エビルナイト・ドラゴン",h:"えびるないと・どらごん",e:"Serpent Night Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2350,de:2400,tx:"邪悪な騎士の心に宿るドラゴンが実体化したもの。",li:"",lk:[],ta:[],al:[]},
  {n:"スカルビショップ",h:"すかるびしょっぷ",e:"Skull Knight",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:2650,de:2250,tx:"悪魔の知恵＋魔天老",li:"",lk:[],ta:[],al:[]},
  {n:"コスモクイーン",h:"こすもくいーん",e:"Cosmo Queen",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:2900,de:2450,tx:"宇宙に存在する、全ての星を統治しているという女王。",li:"",lk:[],ta:[],al:[]},
  {n:"チャクラ",h:"ちゃくら",e:"Chakra",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:2450,de:2000,tx:"「チャクラの復活」により降臨。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"クラブ・タートル",h:"くらぶ・たーとる",e:"Crab Turtle",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["儀式"],ma:"",tp:"",at:2550,de:2500,tx:"「亀の誓い」により降臨。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"クレセント・ドラゴン",h:"くれせんと・どらごん",e:"Mikazukinoyaiba",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2200,de:2350,tx:"月から来たといわれている、三日月状の刀を持つドラゴン戦士。",li:"",lk:[],ta:[],al:[]},
  {n:"メテオ・ドラゴン",h:"めてお・どらごん",e:"Meteor Dragon",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"宇宙の果てから地球におちてきた、流星のドラゴン。",li:"",lk:[],ta:[],al:[]},
  {n:"メテオ・ブラック・ドラゴン",h:"めてお・ぶらっく・どらごん",e:"Meteor Black Dragon",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:3500,de:2000,tx:"「真紅眼の黒竜」＋「メテオ・ドラゴン」",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・ウイング・ペガサス",h:"ふぁいやー・ういんぐ・ぺがさす",e:"Firewing Pegasus",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:2250,de:1800,tx:"色鮮やかな真紅の翼をはばたかせ、天を駆け抜ける天馬。",li:"",lk:[],ta:[],al:[]},
  {n:"ガルマソード",h:"がるまそーど",e:"Garma Sword",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:2550,de:2150,tx:"「ガルマソードの誓い」により降臨。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャベリンビートル",h:"じゃべりんびーとる",e:"Javelin Beetle",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["儀式"],ma:"",tp:"",at:2450,de:2550,tx:"「ジャベリンビートルの契約」により降臨。\n場か手札から、星の数が合計８個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"要塞クジラ",h:"ようさいくじら",e:"Fortress Whale",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["儀式"],ma:"",tp:"",at:2350,de:2150,tx:"「要塞クジラの誓い」により降臨。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"スカルライダー",h:"すかるらいだー",e:"Dokurorider",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["儀式"],ma:"",tp:"",at:1900,de:1850,tx:"「スカルライダーの復活」により降臨。\n場か手札から、星の数が合計６個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス－黒魔術の儀式",h:"かおす－くろまじゅつのぎしき",e:"Black Magic Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「マジシャン・オブ・ブラックカオス」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が８以上になるようにモンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャン・オブ・ブラックカオス",h:"まじしゃん・おぶ・ぶらっくかおす",e:"Magician of Black Chaos",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["儀式"],ma:"",tp:"",at:2800,de:2600,tx:"「カオス－黒魔術の儀式」により降臨。",li:"",lk:[],ta:[],al:[]},
  {n:"スロットマシーンAM－７",h:"すろっとましーんえーえむ－７",e:"Slot Machine",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2000,de:2300,tx:"スロットに揃う数で能力を変化させる事ができるという機械。",li:"",lk:[],ta:[],al:[]},
  {n:"弓を引くマーメイド",h:"ゆみをひくまーめいど",e:"Red Archery Girl",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"普段は貝殻の中に身を隠し、近づく者に矢を放つマーメイド。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・エッガー",h:"どらごん・えっがー",e:"Ryu-Ran",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2200,de:2600,tx:"卵のカラをかぶっているドラゴン。\n子供と間違えると痛い目にあうぞ！",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ドラゴン・エッガー",h:"とぅーん・どらごん・えっがー",e:"Manga Ryu-Ran",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:2200,de:2600,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在し、自分フィールドのモンスター２体をリリースした場合に特殊召喚できる。\n①：このカードは特殊召喚したターンには攻撃できない。\n②：このカードの攻撃宣言の際に、自分は５００LPを払わなければならない。\n③：相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n存在する場合、トゥーンモンスターを攻撃対象にしなければならない。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・マーメイド",h:"とぅーん・まーめいど",e:"Toon Mermaid",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:1400,de:1500,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在する場合に特殊召喚できる。\n①：このカードは特殊召喚したターンには攻撃できない。\n②：このカードは５００LPを払わなければ攻撃宣言できない。\n③：相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n存在する場合、トゥーンモンスターを攻撃対象にしなければならない。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・デーモン",h:"とぅーん・でーもん",e:"Toon Summoned Skull",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードは通常召喚できない。\n自分フィールド上に「トゥーン・ワールド」が存在する場合のみ特殊召喚できる（レベル５以上はリリースが必要）。\nこのカードは特殊召喚したターンには攻撃できない。\nこのカードは５００ライフポイントを払わなければ攻撃宣言できない。\n相手フィールド上にトゥーンモンスターが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\n存在する場合、トゥーンモンスターを攻撃対象に選択しなければならない。\nフィールド上の「トゥーン・ワールド」が破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・アイズ・イリュージョニスト",h:"だーく・あいず・いりゅーじょにすと",e:"Dark-Eyes Illusionist",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:1400,tx:"リバース：このカードがフィールド上に存在している間、指定した対象モンスター１体は永続的に攻撃できなくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"サクリファイス",h:"さくりふぁいす",e:"Relinquished",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:0,de:0,tx:"「イリュージョンの儀式」により降臨。\n①：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n②：このカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値になり、このカードが戦闘で破壊される場合、代わりに装備したそのモンスターを破壊する。\n③：このカードの効果でモンスターを装備したこのカードの戦闘で自分が戦闘ダメージを受けた時、相手も同じ数値分の効果ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"タイム・ボマー",h:"たいむ・ぼまー",e:"Jigen Bakudan",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:1000,tx:"リバース：自分のスタンバイフェイズでこのカードを生け贄に捧げる。\n全ての自分のモンスターを破壊し、その総攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"千眼の邪教神",h:"せんがんのじゃきょうしん",e:"Thousand-Eyes Idol",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:0,de:0,tx:"人の心を操る邪神。\n千の邪眼は、人の負の心を見透かし増大させる。",li:"",lk:[],ta:[],al:[]},
  {n:"サウザンド・アイズ・サクリファイス",h:"さうざんど・あいず・さくりふぁいす",e:"Thousand-Eyes Restrict",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「サクリファイス」＋「千眼の邪教神」\n①：このカードがモンスターゾーンに存在する限り、このカード以外のフィールドのモンスターは表示形式を変更できず、攻撃できない。\n②：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n ③：このカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値になり、このカードが戦闘で破壊される場合、代わりに装備したそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄腕ゴーレム",h:"てつわんごーれむ",e:"Steel Ogre Grotto #2",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1900,de:2200,tx:"鋼鉄でできた機械人形。\n恐るべき怪力を誇る。",li:"",lk:[],ta:[],al:[]},
  {n:"スフィア・ボム 球体時限爆弾",h:"すふぃあ・ぼむ　きゅうたいじげんばくだん",e:"Blast Sphere",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：裏側守備表示のこのカードが相手モンスターに攻撃されたダメージ計算前に発動する。\nこのカードを装備カード扱いとしてその攻撃モンスターに装備する。\n②：このカードの効果でこのカードを装備した次の相手スタンバイフェイズに発動する。\n装備モンスターを破壊し、その攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイヤモンド・ドラゴン",h:"だいやもんど・どらごん",e:"Hyozanryu",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2100,de:2800,tx:"全身がダイヤモンドでできたドラゴン。\nまばゆい光で敵の目をくらませる。",li:"",lk:[],ta:[],al:[]},
  {n:"磁石の戦士α",h:"まぐねっと・うぉりあー・あるふぁ",e:"Alpha The Magnet Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1400,de:1700,tx:"α、β、γで変形合体する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔道化リジョン",h:"まどうけりじょん",e:"Legion the Fiend Jester",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに魔法使い族モンスター１体を表側攻撃表示でアドバンス召喚できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から魔法使い族の通常モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の眠りを誘うルシファー",h:"あんこくのねむりをさそうるしふぁー",e:"Invitation to a Dark Sleep",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"召喚・反転召喚した時、相手のフィールド上モンスター１体を選択する。\n選択したモンスターはこのカードがフィールド上に表側表示で存在する限り、攻撃をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ロード・オブ・ドラゴン－ドラゴンの支配者－",h:"ろーど・おぶ・どらごん－どらごんのしはいしゃ－",e:"Lord of D.",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"①：このカードがモンスターゾーンに存在する限り、お互いのプレイヤーはフィールドのドラゴン族モンスターを効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドアイズ・ブラックメタルドラゴン",h:"れっどあいず・ぶらっくめたるどらごん",e:"Red-Eyes Black Metal Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカードは通常召喚できない。\n自分フィールドの、「メタル化・魔法反射装甲」を装備した「真紅眼の黒竜」１体をリリースした場合にデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"リボルバー・ドラゴン",h:"りぼるばー・どらごん",e:"Barrel Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"①：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nコイントスを３回行い、その内２回以上が表だった場合、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンニバル・ネクロマンサー",h:"はんにばる・ねくろまんさー",e:"Hannibal Necromancer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く（最大１つまで）。\n②：このカードの魔力カウンターを１つ取り除き、フィールドの表側表示の罠カード１枚を対象として発動できる。\nその表側表示の罠カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の豹戦士パンサーウォリアー",h:"しっこくのひょうせんしぱんさーうぉりあー",e:"Panther Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、このカードの攻撃宣言の際に、自分はこのカード以外の自分フィールドのモンスター１体をリリースしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"三ツ首のギドー",h:"みつくびのぎどー",e:"Three-Headed Geedo",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"三ツ首の怪物。\n夜行性でとても凶暴な性格を持つ。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣王ガゼル",h:"げんじゅうおうがぜる",e:"Gazelle the King of Mythical Beasts",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"走るスピードが速すぎて、姿が幻のように見える獣。",li:"",lk:[],ta:[],al:[]},
  {n:"アステカの石像",h:"あすてかのせきぞう",e:"Stone Statue of the Aztecs",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:300,de:2000,tx:"①：このカードが攻撃された場合、その戦闘で発生する相手への戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"バフォメット",h:"ばふぉめっと",e:"Berfomet",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\nデッキから「幻獣王ガゼル」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"有翼幻獣キマイラ",h:"ゆうよくげんじゅうきまいら",e:"Chimera the Flying Mythical Beast",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1800,tx:"「幻獣王ガゼル」＋「バフォメット」\n①：このカードが破壊された時、自分の墓地の、「バフォメット」または「幻獣王ガゼル」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"機動砦のギア・ゴーレム",h:"きどうとりでのぎあ・ごーれむ",e:"Gear Golem the Moving Fortress",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2200,tx:"メインフェイズ１でのみ発動する事ができる。\n８００ライフポイントを払う。\nこのターンこのカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"人造人間－サイコ・ショッカー",h:"じんぞうにんげん－さいこ・しょっかー",e:"Jinzo",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"①：このカードがモンスターゾーンに存在する限り、お互いにフィールドの罠カードの効果を発動できず、フィールドの罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ランドスターの剣士",h:"らんどすたーのけんし",e:"Swordsman of Landstar",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:500,de:1200,tx:"剣の腕は未熟だが、不思議な能力を持つ妖精剣士。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・レイダー",h:"さいばー・れいだー",e:"Cyber Raider",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、次の効果から１つを選択して発動する。\n\n●フィールド上のモンスター１体に装備されている装備カードを１枚選択し破壊する。\n\n●フィールド上のモンスター１体に装備されている装備カードを１枚選択しこのカードに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導ギガサイバー",h:"まどうぎがさいばー",e:"The Fiend Megacyber",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"①：自分フィールドのモンスターの数が相手フィールドのモンスターより２体以上少ない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鏡導士リフレクト・バウンダー",h:"まきょうどうしりふれくと・ばうんだー",e:"Reflect Bounder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"フィールド上に表側攻撃表示で存在するこのカードが相手モンスターに攻撃された場合、そのダメージ計算前に攻撃モンスターの攻撃力分のダメージを相手ライフに与え、そのダメージ計算後にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"磁石の戦士β",h:"まぐねっと・うぉりあー・べーた",e:"Beta The Magnet Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1700,de:1600,tx:"α、β、γで変形合体する。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグ・シールド・ガードナー",h:"びっぐ・しーるど・がーどなー",e:"Big Shield Gardna",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:2600,tx:"①：裏側表示のこのモンスター１体のみを対象とする魔法カードが発動した時に発動する。\nこのカードを表側守備表示にし、その発動を無効にする。\n②：このカードは攻撃された場合、ダメージステップ終了時に攻撃表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・マジシャン・ガール",h:"ぶらっく・まじしゃん・がーる",e:"Dark Magician Girl",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1700,tx:"①：このカードの攻撃力は、お互いの墓地の「ブラック・マジシャン」「マジシャン・オブ・ブラックカオス」の数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイバーンの戦士",h:"わいばーんのせんし",e:"Alligator's Sword",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"剣技にすぐれたトカゲ人間。\n音の速さで剣をふるう。",li:"",lk:[],ta:[],al:[]},
  {n:"インセクト女王",h:"いんせくとくいーん",e:"Insect Queen",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2200,de:2400,tx:"①：このカードの攻撃力は、フィールドの昆虫族モンスターの数×２００アップする。\n②：このカードの攻撃宣言の際に、自分は自分フィールドの他のモンスター１体をリリースしなければならない。\n③：このカードが戦闘で相手モンスターを破壊したターンのエンドフェイズに発動する。\n自分フィールドに「インセクトモンスタートークン」（昆虫族・地・星１・攻／守１００）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"寄生虫パラサイド",h:"きせいちゅうぱらさいど",e:"Parasite Paracide",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:300,tx:"リバース：このカードを相手のデッキに表向きで混ぜてシャッフルする。\n相手がこのカードをドローした時、このカードは相手フィールド上に表側守備表示で特殊召喚され、相手ライフに１０００ポイントダメージを与える。\nその後、このカードがフィールド上に表側表示で存在する限り、相手フィールド上に表側表示で存在するモンスターは全て昆虫族となる。",li:"",lk:[],ta:[],al:[]},
  {n:"髑髏顔 天道虫",h:"どくろがん　れでぃばぐ",e:"Skull-Mark Ladybug",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードが墓地に送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"リトル・ウィンガード",h:"りとる・うぃんがーど",e:"Little-Winguard",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"このカードは自分のエンドフェイズに１度だけ表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"代打バッター",h:"だいだばったー",e:"Pinch Hopper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"①：このカードが自分フィールドから墓地へ送られた時に発動できる。\n手札から昆虫族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーアイズ・トゥーン・ドラゴン",h:"ぶるーあいず・とぅーん・どらごん",e:"Blue-Eyes Toon Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在し、自分フィールドのモンスター２体をリリースした場合に特殊召喚できる。\n①：このカードは特殊召喚したターンには攻撃できない。\n②：このカードは５００LPを払わなければ攻撃宣言できない。\n③：このカードは、相手フィールドにトゥーンモンスターが存在しない場合、直接攻撃できる。\n存在する場合、トゥーンモンスターしか攻撃対象に選択できない。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ソードハンター",h:"そーどはんたー",e:"Sword Hunter",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2450,de:1700,tx:"このカードが戦闘によってモンスターを破壊したバトルフェイズ終了時、墓地に存在するそのモンスターを攻撃力２００ポイントアップの装備カード扱いとしてこのカードに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"穿孔虫",h:"せんこうちゅう",e:"Drill Bug",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1100,de:200,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分のデッキに存在する自分の「寄生虫パラサイド」１枚をデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"深海の戦士",h:"しんかいのせんし",e:"Deepsea Warrior",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"「海」がフィールド上に存在する限り、このカードは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"朱い靴",h:"あかいくつ",e:"Bite Shoes",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:300,tx:"リバース：表側表示モンスター１体の表示形式を変更する（裏側表示にはできない）。",li:"",lk:[],ta:[],al:[]},
  {n:"スパイク・ヘッド",h:"すぱいく・へっど",e:"Spikebot",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"地獄の魔術師が生み出した機械兵。\n両腕の鉄球で敵味方関係なしに攻撃を繰り返す。",li:"",lk:[],ta:[],al:[]},
  {n:"サテライト・キャノン",h:"さてらいと・きゃのん",e:"Satellite Cannon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはレベル７以下のモンスターとの戦闘では破壊されない。\nまた、自分のエンドフェイズ毎にこのカードの攻撃力は１０００ポイントアップする。\nこの効果でアップした攻撃力は、このカードが攻撃したダメージ計算後に０に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"異星の最終戦士",h:"いせいのさいしゅうせんし",e:"The Last Warrior from Another Planet",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2350,de:2300,tx:"「ダーク・ヒーロー ゾンバイア」＋「魔力吸収球体」\nこのカードが特殊召喚に成功した時、このカード以外の自分フィールド上に存在するモンスターを全て破壊する。\nこのカードがフィールド上に表側表示で存在する限り、お互いにモンスターを召喚・反転召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"デュナミス・ヴァルキリア",h:"でゅなみす・う゛ぁるきりあ",e:"Dunames Dark Witch",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:1050,tx:"勇敢なる光の天使。\nその強い正義感ゆえ、負けるとわかっている悪との戦いでも決して逃げない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーネシア・エレファンティス",h:"がーねしあ・えれふぁんてぃす",e:"Garnecia Elefantis",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:2400,de:2000,tx:"恐るべきパワーの持ち主。\nあまりの体重の重さに、歩くたびに地割れが起きてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"絶対防御将軍",h:"ぜったいぼうぎょしょうぐん",e:"Total Defense Shogun",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1550,de:2500,tx:"このカードは召喚・反転召喚に成功した時、守備表示になる。\nフィールド上に表側守備表示で存在するこのカードは、守備表示の状態で攻撃する事ができる。\nその場合、攻撃力の数値を適用してダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"タルワール・デーモン",h:"たるわーる・でーもん",e:"Beast of Talwar",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2400,de:2150,tx:"そのタルワールは、悪魔族でも剣術の達人しか持つ事を許されていない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバティック・ワイバーン",h:"さいばてぃっく・わいばーん",e:"Cyber-Tech Alligator",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2500,de:1600,tx:"メカで強化された翼竜。\nドラゴンにやられ死にかけた所を、飼い主にサイボーグ化された。",li:"",lk:[],ta:[],al:[]},
  {n:"磁石の戦士γ",h:"まぐねっと・うぉりあー・がんま",e:"Gamma The Magnet Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"α、β、γで変形合体する。",li:"",lk:[],ta:[],al:[]},
  {n:"時の機械－タイム・マシーン",h:"ときのきかい－たいむ・ましーん",e:"Time Machine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手のモンスター１体が戦闘で破壊され墓地へ送られた時に発動できる。\nそのモンスターが破壊された時のコントローラーのフィールドに同じ表示形式でそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ものマネ幻想師",h:"ものまねげんそうし",e:"Copycat",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのカードの攻撃力・守備力は、選択したモンスターの元々の攻撃力・守備力と同じ数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ワールド",h:"とぅーん・わーるど",e:"Toon World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１０００LPを払ってこのカードを発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーゴンの眼",h:"ごーごんのまなこ",e:"Gorgon's Eye",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エンドフェイズ終了時まで、フィールド上に存在する守備表示モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"コピーキャット",h:"こぴーきゃっと",e:"Mimicat",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「コピーキャット」は１ターンに１枚しか発動できない。\n①：自分フィールドに「トゥーン・ワールド」及びトゥーンモンスターが存在する場合、相手の墓地のカード１枚を対象として発動できる。\nそのカードがモンスターだった場合、そのモンスターを自分フィールドに特殊召喚する。\nそのカードが魔法・罠カードだった場合、そのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"イリュージョンの儀式",h:"いりゅーじょんのぎしき",e:"Black Illusion Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「サクリファイス」の降臨に必要。\n①：レベルの合計が１以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「サクリファイス」１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"洗脳－ブレインコントロール",h:"せんのう－ぶれいんこんとろーる",e:"Brain Control",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、相手フィールドの通常召喚可能な表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"攻撃の無力化",h:"こうげきのむりょくか",e:"Negate Attack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\nその攻撃を無効にする。\nその後、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"増殖",h:"ぞうしょく",e:"Multiply",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「クリボー」１体をリリースして発動できる。\n自分フィールド上に「クリボートークン」（悪魔族・闇・星１・攻３００／守２００）を可能な限り守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"７カード",h:"せぶんかーど",e:"7 Completed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターのみ装備可能。\n装備モンスターの攻撃力または守備力は７００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"光の封札剣",h:"ひかりのふうさつけん",e:"Lightforce Sword",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の手札をランダムに１枚選んで裏側表示で除外する。\nこのカードの発動後、相手ターンで数えて４ターン目の相手スタンバイフェイズに、そのカードを相手の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンを呼ぶ笛",h:"どらごんをよぶふえ",e:"The Flute of Summoning Dragon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札からドラゴン族モンスターを２体まで特殊召喚する。\nこの効果はフィールドに「ロード・オブ・ドラゴン－ドラゴンの支配者－」が存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"右手に盾を左手に剣を",h:"みぎてにたてをひだりてにけんを",e:"Shield & Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動時にフィールド上に表側表示で存在する全てのモンスターの元々の攻撃力と元々の守備力を、エンドフェイズ時まで入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"天使の施し",h:"てんしのほどこし",e:"Graceful Charity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキからカードを３枚ドローし、その後手札を２枚選択して捨てる。",li:0,lk:[],ta:[],al:[]},
  {n:"連鎖破壊",h:"ちぇーん・ですとらくしょん",e:"Chain Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：攻撃力２０００以下のモンスターが召喚・反転召喚・特殊召喚に成功した時、その表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントローラーの手札・デッキから対象のモンスターの同名カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"催眠術",h:"さいみんじゅつ",e:"Mesmeric Control",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：次の相手ターン、相手はモンスターの表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"天使のサイコロ",h:"てんしのさいころ",e:"Graceful Dice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：サイコロを１回振る。\n自分フィールドのモンスターの攻撃力・守備力は、ターン終了時まで出た目の数×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔のサイコロ",h:"あくまのさいころ",e:"Skull Dice",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：サイコロを１回振る。\n相手フィールドのモンスターの攻撃力・守備力は、ターン終了時まで出た目の数×１００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"精神操作",h:"せいしんそうさ",e:"Mind Control",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターは攻撃宣言できず、リリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"スケープ・ゴート",h:"すけーぷ・ごーと",e:"Scapegoat",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・反転召喚・特殊召喚できない。\n①：自分フィールドに「羊トークン」（獣族・地・星１・攻／守０）４体を守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳増幅器",h:"でんのうぞうふくき",e:"Amplifier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「人造人間－サイコ・ショッカー」にのみ装備可能。\nこのカードの発動と効果は無効化されない。\n①：装備モンスターの持つ、「お互いにフィールドの罠カードの効果を発動できず、フィールドの罠カードの効果は無効化される」効果は、「相手はフィールドの罠カードの効果を発動できず、相手フィールドの罠カードの効果は無効化される」として適用される。\n②：このカードがフィールドから離れた時に装備モンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ルーレット・スパイダー",h:"るーれっと・すぱいだー",e:"Roulette Spider",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\nサイコロを１回振り、出た目の効果を適用する。\n\n１・自分のLPを半分にする。\n\n２・その攻撃を自分への直接攻撃にする。\n\n３・自分フィールドのモンスター１体を選び、そのモンスターに攻撃対象を移し替えてダメージ計算を行う。\n\n４・攻撃モンスター以外の相手フィールドのモンスター１体を選び、そのモンスターに攻撃対象を移し替えてダメージ計算を行う。\n\n５・その攻撃を無効にし、そのモンスターの攻撃力分のダメージを相手に与える。\n\n６・その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"手札抹殺",h:"てふだまっさつ",e:"Card Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札があるプレイヤーは、その手札を全て捨てる。\nその後、それぞれ自身が捨てた枚数分デッキからドローする。",li:1,lk:[],ta:[],al:[]},
  {n:"黒魔族復活の棺",h:"くろまぞくふっかつのひつぎ",e:"Dark Renewal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターの召喚・特殊召喚に成功した時、そのモンスター１体と自分フィールドの魔法使い族モンスター１体を対象として発動できる。\nそのモンスター２体を墓地へ送る。\nその後、自分のデッキ・墓地から魔法使い族・闇属性モンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"断頭台の惨劇",h:"だんとうだいのさんげき",e:"Tragedy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在するモンスターの表示形式が表側守備表示に変更された時に発動できる。\n相手フィールド上に守備表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊の鏡",h:"せいれいのかがみ",e:"Mystical Refpanel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"プレイヤー１人を対象とする魔法の効果を別のプレイヤーに移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"エクトプラズマー",h:"えくとぷらずまー",e:"Ectoplasmer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは、それぞれ自分のエンドフェイズ時に１度だけ、自分フィールド上に表側表示で存在するモンスター１体を選び、そのモンスターをリリースし、リリースしたそのモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔術のカーテン",h:"くろまじゅつのかーてん",e:"Dark Magic Curtain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・反転召喚・特殊召喚できない。\n①：LPを半分払って発動できる。\nデッキから「ブラック・マジシャン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"封魔の矢",h:"ふうまのや",e:"Anti-Magic Arrows",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動に対して魔法・罠・モンスターの効果は発動できない。\n①：自分または相手のバトルフェイズ開始時に発動できる。\nこのカードの発動後、ターン終了時までお互いに魔法・罠カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"虫除けバリアー",h:"むしよけばりあー",e:"Insect Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在する昆虫族モンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジックアーム・シールド",h:"まじっくあーむ・しーるど",e:"Magical Arm Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドに表側表示モンスターが２体以上存在し、自分フィールドにモンスターが存在する場合、相手モンスターの攻撃宣言時に攻撃モンスター以外の相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールをバトルフェイズ終了時まで得て、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"地割れ",h:"じわれ",e:"Fissure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの攻撃力が一番低いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"落とし穴",h:"おとしあな",e:"Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が攻撃力１０００以上のモンスターの召喚・反転召喚に成功した時、そのモンスター１体を対象として発動できる。\nその攻撃力１０００以上のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"融合",h:"ゆうごう",e:"Polymerization",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"罠はずし",h:"わなはずし",e:"Remove Trap",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"表側表示でフィールド上に存在する罠カードを１枚選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"はさみ撃ち",h:"はさみうち",e:"Two-Pronged Attack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター２体と相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルー・ポーション",h:"ぶるー・ぽーしょん",e:"Blue Medicine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分は４００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"雷鳴",h:"らいめい",e:"Raimei",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"死者蘇生",h:"ししゃそせい",e:"Monster Reborn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"魔法除去",h:"まほうじょきょ",e:"De-Spell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示の魔法カード１枚またはフィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nその魔法カードを破壊する（そのカードがフィールドにセットされている場合、めくって確認する）。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲な壺",h:"ごうよくなつぼ",e:"Pot of Greed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分はデッキから２枚ドローする。",li:0,lk:[],ta:[],al:[]},
  {n:"墓掘りグール",h:"はかほりぐーる",e:"Gravedigger Ghoul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の墓地から１枚または２枚のモンスターカードを選択する。\n選択したカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"灼熱の槍",h:"しゃくねつのやり",e:"Burning Spear",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"炎属性モンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"突風の扇",h:"とっぷうのおうぎ",e:"Gust Fan",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"風属性モンスターのみ装備可能。\n装備モンスター１体の攻撃力は４００ポイントアップする。\n守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"未熟な密偵",h:"みじゅくなみってい",e:"The Inexperienced Spy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の手札を１枚指定して、見ることができる。",li:"",lk:[],ta:[],al:[]},
  {n:"援軍",h:"えんぐん",e:"Reinforcements",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"城壁",h:"じょうへき",e:"Castle Walls",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの守備力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"血の代償",h:"ちのだいしょう",e:"Ultimate Offering",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"５００ライフポイントを払う事で、モンスター１体を通常召喚する。\nこの効果は自分のメインフェイズ時及び相手のバトルフェイズ時にのみ発動できる。",li:0,lk:[],ta:[],al:[]},
  {n:"古代の遠眼鏡",h:"こだいのとおめがね",e:"Ancient Telescope",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手のデッキのカードを、上から５枚まで見ることができる。\nその後カードを元通りにする。",li:"",lk:[],ta:[],al:[]},
  {n:"ホワイト・ホール",h:"ほわいと・ほーる",e:"White Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が「ブラック・ホール」を発動した時に発動する事ができる。\n自分フィールド上に存在するモンスターは、その「ブラック・ホール」の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓場からの呼び声",h:"はかばからのよびごえ",e:"Call of the Grave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が「死者蘇生」を発動した時に発動する事ができる。\nその「死者蘇生」の効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"避雷針",h:"ひらいしん",e:"Anti Raigeki",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が「サンダー・ボルト」を使用した時、自分モンスターの代わりに相手モンスターを全て破壊する。\n発動後このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"死者への手向け",h:"ししゃへのたむけ",e:"Tribute to The Doomed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の解放",h:"たましいのかいほう",e:"Soul Release",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いの墓地のカードを合計５枚まで選択し、そのカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"陽気な葬儀屋",h:"ようきなそうぎや",e:"The Cheerful Coffin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から３枚までのモンスターカードを墓地へ捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"闇からの呼び声",h:"やみからのよびごえ",e:"Call of Darkness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「死者蘇生」の効果によって特殊召喚したモンスターを全て墓地へ送る。\nこのカードがフィールド上に存在する限り、お互いに「死者蘇生」を使用する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"心変わり",h:"こころがわり",e:"Change of Heart",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に存在するモンスター１体を選択して発動する。\nこのターンのエンドフェイズ時まで、選択したモンスターのコントロールを得る。",li:0,lk:[],ta:[],al:[]},
  {n:"神の宣告",h:"かみのせんこく",e:"Solemn Judgment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：LPを半分払って以下の効果を発動できる。\n\n●魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ジャマー",h:"まじっく・じゃまー",e:"Magic Jammer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：魔法カードが発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"盗賊の七つ道具",h:"とうぞくのななつどうぐ",e:"Seven Tools of the Bandit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：罠カードが発動した時、１０００LPを払って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"昇天の角笛",h:"しょうてんのつのぶえ",e:"Horn of Heaven",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体をリリースして発動する。\nモンスターの召喚・反転召喚・特殊召喚を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"自業自得",h:"じごうじとく",e:"Just Desserts",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドのモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮のお触れ",h:"おうきゅうのおふれ",e:"Royal Decree",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、このカード以外のフィールドの全ての罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔女狩り",h:"まじょがり",e:"Last Day of Witch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する魔法使い族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔払い",h:"あくまばらい",e:"Exile of the Wicked",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の悪魔族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力の棘",h:"まりょくのとげ",e:"Magical Thorn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手の手札が墓地へ捨てられた時、捨てたカードの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"革命",h:"かくめい",e:"Restructer Revolution",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の手札の枚数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"融合賢者",h:"ゆうごうけんじゃ",e:"Fusion Sage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキから「融合」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"執念の剣",h:"しゅうねんのつるぎ",e:"Sword of Deep-Seated",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力・守備力は５００ポイントアップする。\nこのカードが墓地へ送られた時、このカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"『攻撃』封じ",h:"『こうげき』ふうじ",e:"Block Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"薄幸の美少女",h:"はっこうのびしょうじょ",e:"The Unhappy Maiden",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、このターンのバトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"追い剥ぎゴブリン",h:"おいはぎごぶりん",e:"Robbin' Goblin",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"細菌感染",h:"さいきんかんせん",e:"Germ Infection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族以外のモンスターのみ装備可能。\n装備モンスターの攻撃力は自分のスタンバイフェイズ毎に３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"しびれ薬",h:"しびれぐすり",e:"Paralyzing Potion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族以外のモンスターのみ装備可能。\n装備モンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"厳格な老魔術師",h:"げんかくなろうまじゅつし",e:"The Stern Mystic",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：このカードがリバースした場合に発動する。\nフィールドにセットされたカードを全て確認する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影の壁",h:"げんえいのかべ",e:"Wall of Illusion",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1850,tx:"このカードを攻撃したモンスターは持ち主の手札に戻る。\nダメージ計算は適用する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法剣士ネオ",h:"まほうけんしねお",e:"Neo the Magic Swordsman",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"武術と剣に優れた風変わりな魔法使い。\n異空間を旅している。",li:"",lk:[],ta:[],al:[]},
  {n:"邪剣男爵",h:"じゃけんだんしゃく",e:"Baron of the Fiend Sword",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1550,de:800,tx:"怨念のこもった剣をあやつる貴族。\n執念深く獲物を追いつめる。",li:"",lk:[],ta:[],al:[]},
  {n:"人喰い宝石箱",h:"ひとくいほうせきばこ",e:"Man-Eating Treasure Chest",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1600,de:1000,tx:"宝石箱の形をしたモンスター。\n冒険者をだまして襲いかかる。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・ソーサラー",h:"です・そーさらー",e:"Sorcerer of the Doomed",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1450,de:1200,tx:"死の魔法が得意な魔法使い。\n闇と契約している。",li:"",lk:[],ta:[],al:[]},
  {n:"遺言状",h:"ゆいごんじょう",e:"Last Will",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このターンに自分フィールド上のモンスターが自分の墓地へ送られた時、デッキから攻撃力１５００以下のモンスター１体を特殊召喚する事ができる。",li:0,lk:[],ta:[],al:[]},
  {n:"和睦の使者",h:"わぼくのししゃ",e:"Waboku",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、自分のモンスターは戦闘では破壊されず、自分が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なるバリア －ミラーフォース－",h:"せいなるばりあ　－みらーふぉーす－",e:"Mirror Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの攻撃表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"磁力の指輪",h:"じりょくのゆびわ",e:"Ring of Magnetism",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は５００ポイントダウンする。\n相手はこのカードの装備モンスターしか攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"痛み分け",h:"いたみわけ",e:"Share the Pain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体をリリースして発動する。\n相手はモンスター１体をリリースしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドーピング",h:"どーぴんぐ",e:"Stim-Pack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は７００ポイントアップする。\nまた、自分のスタンバイフェイズ毎に、装備モンスターの攻撃力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"大嵐",h:"おおあらし",e:"Heavy Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの魔法・罠カードを全て破壊する。",li:0,lk:[],ta:[],al:[]},
  {n:"グリフォンの翼",h:"ぐりふぉんのつばさ",e:"Gryphon Wing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が「ハーピィの羽根帚」を発動した時に発動できる。\nその効果を無効にし、相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の使い魔",h:"はかもりのつかいま",e:"Gravekeeper's Servant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手はデッキの一番上のカード１枚を墓地へ送らなければ、攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"邪悪な儀式",h:"じゃあくなぎしき",e:"Curse of Fiend",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の全てのモンスターの表示形式を入れ替える。\n発動ターン、モンスターの表示形式は変更できない。\nこのカードはスタンバイフェイズにしか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"成金ゴブリン",h:"なりきんごぶりん",e:"Upstart Goblin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分はデッキから１枚ドローする。\nその後、相手は１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"通行税",h:"つうこうぜい",e:"Toll",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは５００ライフポイントを払わなければ、攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"最終戦争",h:"さいしゅうせんそう",e:"Final Destiny",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を５枚捨てて発動する。\nフィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"強奪",h:"ごうだつ",e:"Snatch Steal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードを装備した相手モンスター１体のコントロールを得る。\n相手のスタンバイフェイズ毎に相手は１０００ライフポイント回復する。",li:0,lk:[],ta:[],al:[]},
  {n:"聖域の歌声",h:"せいいきのうたごえ",e:"Chorus of Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側守備表示で存在する全てのモンスターの守備力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"押収",h:"おうしゅう",e:"Confiscation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払って発動する。\n相手の手札を確認し、その中からカードを１枚捨てる。",li:0,lk:[],ta:[],al:[]},
  {n:"いたずら好きな双子悪魔",h:"いたずらずきなふたごあくま",e:"Delinquent Duo",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払って発動する。\n相手は手札をランダムに１枚捨て、さらにもう１枚選択して捨てる。",li:0,lk:[],ta:[],al:[]},
  {n:"闇の訪れ",h:"やみのおとずれ",e:"Darkness Approaches",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を２枚捨てる。\n表側表示のモンスター１体を選択し、表示形式はそのままで裏側表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"天使の手鏡",h:"てんしのてかがみ",e:"Fairy's Hand Mirror",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上のモンスター１体を対象に発動した相手の魔法を、別の正しい対象に移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"移り気な仕立屋",h:"うつりぎなしたてや",e:"Tailor of the Fickle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"モンスターに装備された装備カード１枚を、別の正しい対象となるモンスター１体に移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"突進",h:"とっしん",e:"Rush Recklessly",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"頼もしき守護者",h:"たのもしきしゅごしゃ",e:"The Reliable Guardian",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体の守備力はエンドフェイズ時まで７００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"強引な番兵",h:"ごういんなばんぺい",e:"The Forceful Sentry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の手札を確認し、その中からカードを１枚デッキに戻す。",li:0,lk:[],ta:[],al:[]},
  {n:"魔力の枷",h:"まりょくのかせ",e:"Chain Energy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは５００ライフポイントを払わなければ、手札からカードを召喚・特殊召喚・発動・セットする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイクロン",h:"さいくろん",e:"Mystical Space Typhoon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハリケーン",h:"はりけーん",e:"Giant Trunade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の魔法・罠カードを全て持ち主の手札に戻す。",li:0,lk:[],ta:[],al:[]},
  {n:"苦渋の選択",h:"くじゅうのせんたく",e:"Painful Choice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキからカードを５枚選択して相手に見せる。\n相手はその中から１枚を選択する。\n相手が選択したカード１枚を自分の手札に加え、残りのカードを墓地へ捨てる。",li:0,lk:[],ta:[],al:[]},
  {n:"毒蛇の牙",h:"どくへびのきば",e:"Snake Fang",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの守備力はエンドフェイズ時まで５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバーポッド",h:"さいばーぽっど",e:"Cyber Jar",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:900,tx:"リバース：フィールド上に存在するモンスターを全て破壊する。\nその後、お互いにデッキの上からカードを５枚めくり、その中のレベル４以下のモンスターを全て表側攻撃表示または裏側守備表示で特殊召喚する。\nそれ以外のカードは全て手札に加える。",li:0,lk:[],ta:[],al:[]},
  {n:"光の追放者",h:"ひかりのついほうしゃ",e:"Banisher of the Light",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、墓地へ送られるカードは墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大ネズミ",h:"きょだいねずみ",e:"Giant Rat",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1450,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の地属性モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"センジュ・ゴッド",h:"せんじゅ・ごっど",e:"Senju of the Thousand Hands",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\nデッキから儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"UFOタートル",h:"ＵＦＯたーとる",e:"UFO Turtle",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の炎属性モンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"秒殺の暗殺者",h:"びょうさつのあんさつしゃ",e:"Flash Assailant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードの攻撃力・守備力は、自分の手札の枚数×４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"カラテマン",h:"からてまん",e:"Karate Man",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\nこのカードの攻撃力はエンドフェイズ時まで、元々の攻撃力を倍にした数値になる。\nこの効果を使用した場合、このカードはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークゼブラ",h:"だーくぜぶら",e:"Dark Zebra",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"自分のスタンバイフェイズ時、自分がコントロールするモンスターがこのカードのみの場合、このカードは守備表示になる。\nそのターン表示形式は変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャイアントウィルス",h:"じゃいあんとうぃるす",e:"Giant Germ",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。\nさらに自分のデッキから「ジャイアントウィルス」を任意の数だけ表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"素早いモモンガ",h:"すばやいももんが",e:"Nimble Momonga",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分は１０００ライフポイント回復する。\nさらにデッキから「素早いモモンガ」を任意の数だけ裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークファミリア",h:"だーくふぁみりあ",e:"Spear Cretin",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"リバース：このカードが墓地へ送られた時、お互いのプレイヤーはそれぞれの墓地に存在するモンスター１体を選択し、表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シャインエンジェル",h:"しゃいんえんじぇる",e:"Shining Angel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の光属性モンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ボアソルジャー",h:"ぼあそるじゃー",e:"Boar Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:500,tx:"召喚された場合、このカードを破壊する。\n相手が１体でもモンスターをコントロールしていた場合、攻撃力は１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"グリズリーマザー",h:"ぐりずりーまざー",e:"Mother Grizzly",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の水属性モンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンフライ",h:"どらごんふらい",e:"Flying Kamakiri #1",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1400,de:900,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力１５００以下の風属性モンスター１体を自分フィールド上に表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"セレモニーベル",h:"せれもにーべる",e:"Ceremonial Bell",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:1850,tx:"このカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーは手札を全て公開し続ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ソニックバード",h:"そにっくばーど",e:"Sonic Bird",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\nデッキから儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"キラー・トマト",h:"きらー・とまと",e:"Mystic Tomato",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力１５００以下の闇属性モンスター１体を自分フィールド上に表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"コトダマ",h:"ことだま",e:"Kotodama",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、同名モンスターはフィールド上に表側表示で存在する事ができず破壊される。\n後から召喚・特殊召喚・リバースした同名モンスターは破壊される（同時の場合、全ての同名モンスターを破壊する）。",li:"",lk:[],ta:[],al:[]},
  {n:"ガイアパワー",h:"がいあぱわー",e:"Gaia Power",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する地属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーターワールド",h:"うぉーたーわーるど",e:"Umiiruka",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する水属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"バーニングブラッド",h:"ばーにんぐぶらっど",e:"Molten Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する炎属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"デザートストーム",h:"でざーとすとーむ",e:"Rising Air Current",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する風属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"シャインスパーク",h:"しゃいんすぱーく",e:"Luminous Spark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する光属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークゾーン",h:"だーくぞーん",e:"Mystic Plasma Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する闇属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"平和の使者",h:"へいわのししゃ",e:"Messenger of Peace",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する攻撃力１５００以上のモンスターは攻撃宣言をする事ができない。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に１００ライフポイントを払う。\nまたは、１００ライフポイント払わずにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"道連れ",h:"みちづれ",e:"Michizure",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：モンスターが戦闘で破壊され自分の墓地へ送られた時、またはフィールドのモンスターが自分の墓地へ送られた時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"突風",h:"とっぷう",e:"Gust",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のカードの効果によって自分フィールド上の魔法カードが破壊され墓地へ送られた時に発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"猛吹雪",h:"もうふぶき",e:"Driving Snow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のコントロールするカードの効果によって自分の罠カードが破壊され、フィールドから墓地に送られた時に発動する事ができる。\nフィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガラスの鎧",h:"がらすのよろい",e:"Armored Glass",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"装備カードがモンスターに装備された時に発動する事ができる。\nターン終了時までフィールド上の全ての装備カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"世界の平定",h:"せかいのへいてい",e:"World Suppression",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールドカードが発動された時に発動する事ができる。\nターン終了時までフィールドカードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法探査の石版",h:"まほうたんさのせきばん",e:"Mystic Probe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"永続魔法カードが発動された時に発動する事ができる。\nターン終了時までフィールド上の全ての永続魔法カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"金属探知器",h:"きんぞくたんちき",e:"Metal Detector",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"永続罠カードが発動された時に発動する事ができる。\nターン終了時までフィールド上の全ての永続罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"白衣の天使",h:"はくいのてんし",e:"Numinous Healer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘またはカードの効果によってダメージを受けた時に発動する事ができる。\n自分は１０００ライフポイント回復する。\n自分の墓地に「白衣の天使」が存在する場合、さらにその枚数分だけ５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"便乗",h:"びんじょう",e:"Appropriate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手がドローフェイズ以外でカードをドローした時に発動する事ができる。\nその後、相手がドローフェイズ以外でカードをドローする度に、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"強制接収",h:"きょうせいせっしゅう",e:"Forced Requisition",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分が手札を捨てた時に発動する事ができる。\nその後自分が手札を捨てる度に、相手は同じ枚数分手札を選択して捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリンの小役人",h:"ごぶりんのこやくにん",e:"Minor Goblin Official",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のライフポイントが３０００ポイント以下の場合に発動する事ができる。\n相手のスタンバイフェイズ毎に相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャンブル",h:"ぎゃんぶる",e:"Gamble",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の手札が６枚以上、自分の手札が２枚以下の場合に発動する事ができる。\nコイントスを１回行い裏表を当てる。\n当たった場合、自分の手札が５枚になるようにデッキからカードをドローする。\nハズレの場合、次の自分のターンをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"DNA改造手術",h:"ＤＮＡかいぞうしゅじゅつ",e:"DNA Surgery",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"種族を１つ宣言して発動する。\nこのカードがフィールド上に存在する限り、フィールド上に表側表示で存在する全てのモンスターは宣言した種族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"一族の掟",h:"いちぞくのおきて",e:"The Regulation of Tribe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"発動時に１種類の種族を宣言する。\nその種族のモンスターは攻撃宣言ができない。\n自分のスタンバイフェイズ毎にモンスター１体を生け贄に捧げなければこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"補充要員",h:"ほじゅうよういん",e:"Backup Soldier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地にモンスターが５体以上存在する場合に発動する事ができる。\n自分の墓地に存在する効果モンスター以外の攻撃力１５００以下のモンスターを３体まで選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"白兵戦",h:"はくへいせん",e:"Attack and Receive",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘またはカードの効果によってダメージを受けた時に発動する事ができる。\n相手ライフに７００ポイントダメージを与える。\n自分の墓地に「白兵戦」が存在する場合、さらにその枚数分だけ相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"大騒動",h:"おおそうどう",e:"Major Riot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のコントロールするカードの効果によって、自分のモンスターがフィールドから手札に戻された時に発動する事ができる。\nフィールド上の全モンスターを持ち主の手札に戻す。\nその後、お互いに戻された数のモンスターを裏側守備表示で手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"停戦協定",h:"ていせんきょうてい",e:"Ceasefire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドに効果モンスターまたは裏側守備表示モンスターが存在する場合に発動できる。\nフィールドの裏側守備表示モンスターを全て表側守備表示にする。\nこの時、リバースモンスターの効果は発動しない。\nフィールドの効果モンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる輝き",h:"せいなるかがやき",e:"Light of Intervention",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、モンスターをセットする事はできない。\nまた、モンスターをセットする場合は表側守備表示にしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"正々堂々",h:"せいせいどうどう",e:"Respect Play",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのプレイヤーは、それぞれ自分のターンには手札を全て公開し続けなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮の勅命",h:"おうきゅうのちょくめい",e:"Imperial Order",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーはお互いのスタンバイフェイズ毎に７００LPを払う。\n７００LP払えない場合このカードを破壊する。\n①：このカードが魔法＆罠ゾーンに存在する限り、フィールドの全ての魔法カードの効果は無効化される。",li:1,lk:[],ta:[],al:[]},
  {n:"死霊の誘い",h:"しりょうのいざない",e:"Skull Invitation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"カードが墓地へ送られる度に、そのカードの持ち主は１枚につき３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカルシルクハット",h:"まじかるしるくはっと",e:"Magical Hats",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手バトルフェイズに発動できる。\nデッキから魔法・罠カード２枚を選び、そのカード２枚を通常モンスターカード扱い（攻／守０）として、自分のメインモンスターゾーンのモンスター１体と合わせてシャッフルして裏側守備表示でセットする。\nこの効果でデッキから特殊召喚したカードはバトルフェイズの間しか存在できず、バトルフェイズ終了時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"抹殺の使徒",h:"まっさつのしと",e:"Nobleman of Crossout",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に裏側表示で存在するモンスター１体を選択して破壊し、ゲームから除外する。\nそれがリバース効果モンスターだった場合、お互いのデッキを確認し、同名カードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"撲滅の使徒",h:"ぼくめつのしと",e:"Nobleman of Extermination",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上にセットされた魔法・罠カード１枚を選択して破壊し、ゲームから除外する。\nそれが罠カードだった場合、お互いのデッキを確認し、同名カードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"浅すぎた墓穴",h:"あさすぎたはかあな",e:"The Shallow Grave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーはそれぞれの墓地のモンスター１体を選択し、それぞれのフィールド上に裏側守備表示でセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"早すぎた埋葬",h:"はやすぎたまいそう",e:"Premature Burial",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、自分の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚し、このカードを装備する。\nこのカードが破壊された時にそのモンスターは破壊される。",li:0,lk:[],ta:[],al:[]},
  {n:"検閲",h:"けんえつ",e:"Inspection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手のスタンバイフェイズ毎に５００ライフポイントを払う事で、ランダムに１枚相手の手札を見る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"禁止令",h:"きんしれい",e:"Prohibition",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"カード名を１つ宣言してこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、宣言されたカード名を元々のカード名とするお互いのカードに以下を適用する（この効果の適用前からフィールドに存在するカードには適用されない）。\n\n●フィールドに出す事ができない。\n\n●カードの発動及び効果の発動と適用ができない。\n\n●通常召喚・反転召喚・特殊召喚できない。\n\n●攻撃及び表示形式の変更ができない。\n\n●素材を必要とする特殊召喚のための素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"カオスポッド",h:"かおすぽっど",e:"Morphing Jar #2",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:700,tx:"リバース：フィールド上のモンスターを全て持ち主のデッキに加えてシャッフルする。\nその後、お互いのプレイヤーはそれぞれのデッキに加えた数と同じ数のモンスターが出るまでデッキをめくり、その中からレベル４以下のモンスターを全て裏側守備表示で特殊召喚する。\nそれ以外のめくったカードは全て墓地へ捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"デスハムスター",h:"ですはむすたー",e:"Bubonic Vermin",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:600,tx:"①：このカードがリバースした場合に発動できる。\nデッキから「デスハムスター」１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フレイムキラー",h:"ふれいむきらー",e:"Flame Champion",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1900,de:1300,tx:"炎の盾を使う剣士。\nその盾はどんな攻撃でも無効化してしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグバンドラゴン",h:"びっぐばんどらごん",e:"Twin-Headed Fire Dragon",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:2200,de:1700,tx:"宇宙ができた時に生まれた竜。\nその衝撃で双子の竜が合体して１つの体になってしまった。",li:"",lk:[],ta:[],al:[]},
  {n:"バーニングソルジャー",h:"ばーにんぐそるじゃー",e:"Darkfire Soldier #1",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1700,de:1150,tx:"熱く燃える特殊部隊工作員。\n火薬のエキスパート。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスターボルケーノ",h:"みすたーぼるけーの",e:"Mr. Volcano",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:2100,de:1300,tx:"炎をあやつる紳士。\nふだんは温厚だが怒ると怖い。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の剣豪",h:"ほのおのけんごう",e:"Darkfire Soldier #2",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1700,de:1100,tx:"火山に落ちて、炎を身にまとう能力を身につけた武士。",li:"",lk:[],ta:[],al:[]},
  {n:"精神寄生体",h:"せいしんきせいたい",e:"Kiseitai",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:800,tx:"フィールド上に裏側守備表示で存在するこのカードが相手モンスターに攻撃された場合、そのダメージ計算前にこのカードは攻撃モンスターの装備カードになる。\n相手のスタンバイフェイズ毎に、このカードの装備モンスターの攻撃力の半分の数値分、自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"メカファルコン",h:"めかふぁるこん",e:"Cyber Falcon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"ジェットエンジンを装備した鷹。\n音の速度で飛ぶ事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークバット",h:"だーくばっと",e:"Dark Bat",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"念波で敵を探し出す闇世界のコウモリ。",li:"",lk:[],ta:[],al:[]},
  {n:"フライングマンティス",h:"ふらいんぐまんてぃす",e:"Flying Kamakiri #2",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"飛行能力を持ったカマキリ。\n昆虫が大好物。",li:"",lk:[],ta:[],al:[]},
  {n:"バードマン",h:"ばーどまん",e:"Sky Scout",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1800,de:600,tx:"マッハ５で飛行する鳥人。\nその眼光は鷹より鋭い。",li:"",lk:[],ta:[],al:[]},
  {n:"鬼タンクT－３４",h:"おにたんくＴ－３４",e:"Oni Tank T-34",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1700,tx:"鬼の魂が乗り移った戦車。\n意志を持ち敵をどこまでも追いつめる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガトリングバギー",h:"がとりんぐばぎー",e:"Overdrive",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"重機関銃装備の装甲車。\nどんな荒れ地も平気で走る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・ブレイダー",h:"ばすたー・ぶれいだー",e:"Buster Blader",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2300,tx:"①：このカードの攻撃力は、相手のフィールド・墓地のドラゴン族モンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"刻の封印",h:"ときのふういん",e:"Time Seal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"次の相手ターンのドローフェイズをスキップする。",li:0,lk:[],ta:[],al:[]},
  {n:"墓荒らし",h:"はかあらし",e:"Graverobber",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地にある魔法カード１枚を選択し、ターン終了時まで自分の手札として使用する事ができる。\nその魔法カードを使用した場合、２０００ポイントのダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリー・エルフの祝福",h:"ほーりー・えるふのしゅくふく",e:"Gift of The Mystical Elf",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分はフィールド上に存在するモンスターの数×３００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"真実の眼",h:"しんじつのめ",e:"The Eye of Truth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手は手札を公開し続ける。\n相手のスタンバイフェイズ時に相手の手札に魔法カードがある場合、相手は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"砂塵の大竜巻",h:"さじんのおおたつまき",e:"Dust Tornado",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを破壊する。\nその後、手札から魔法・罠カード１枚をセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"リビングデッドの呼び声",h:"りびんぐでっどのよびごえ",e:"Call of the Haunted",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ソロモンの律法書",h:"そろもんのりっぽうしょ",e:"Solomon's Lawbook",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"次の自分のスタンバイフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"地殻変動",h:"ちかくへんどう",e:"Earthshaker",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分は属性を２つ選択する。\n相手はその中から１つを選択する。\nフィールド上に表側表示で存在する選択された属性のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリージャベリン",h:"ほーりーじゃべりん",e:"Enchanted Javelin",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\nその攻撃モンスター１体の攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"銀幕の鏡壁",h:"ぎんまくのみらーうぉーる",e:"Mirror Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは自分スタンバイフェイズ毎に２０００LPを払う。\nまたはLPを払わずにこのカードを破壊する。\n①：このカードが魔法＆罠ゾーンに存在する限り、相手の攻撃モンスターの攻撃力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"オベリスクの巨神兵",h:"おべりすくのきょしんへい",e:"Obelisk the Tormentor",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードを通常召喚する場合、３体をリリースして召喚しなければならない。\n①：このカードの召喚は無効化されない。\n②：このカードの召喚成功時には、魔法・罠・モンスターの効果は発動できない。\n③：このカードは効果の対象にならない。\n④：自分フィールドのモンスター２体をリリースして発動できる。\n相手フィールドのモンスターを全て破壊する。\nこの効果を発動するターン、このカードは攻撃宣言できない。\n⑤：このカードが特殊召喚されている場合、エンドフェイズに発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"オシリスの天空竜",h:"おしりすのてんくうりゅう",e:"Slifer the Sky Dragon",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードを通常召喚する場合、３体をリリースして召喚しなければならない。\n①：このカードの召喚は無効化されない。\n②：このカードの召喚成功時には、魔法・罠・モンスターの効果は発動できない。\n③：このカードの攻撃力・守備力は自分の手札の数×１０００アップする。\n④：相手モンスターが攻撃表示で召喚・特殊召喚に成功した場合に発動する。\nそのモンスターの攻撃力を２０００ダウンさせ、０になった場合そのモンスターを破壊する。\n⑤：このカードが特殊召喚されている場合、エンドフェイズに発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ラーの翼神竜",h:"らーのよくしんりゅう",e:"The Winged Dragon of Ra",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは特殊召喚できない。\nこのカードを通常召喚する場合、３体をリリースして召喚しなければならない。\n①：このカードの召喚は無効化されない。\n②：このカードの召喚成功時には、このカード以外の魔法・罠・モンスターの効果は発動できない。\n③：このカードが召喚に成功した時、１００LPになるようにLPを払って発動できる。\nこのカードの攻撃力・守備力は払った数値分アップする。\n④：１０００LPを払い、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イタクァの暴風",h:"いたくぁのぼうふう",e:"Windstorm of Etaqua",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの全ての表側表示モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"磁石の戦士マグネット・バルキリオン",h:"じしゃくのせんしまぐねっと・ばるきりおん",e:"Valkyrion the Magna Warrior",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3850,tx:"このカードは通常召喚できない。\n自分の手札・フィールドから、「磁石の戦士α」「磁石の戦士β」「磁石の戦士γ」を１体ずつリリースした場合に特殊召喚できる。\n①：このカードをリリースし、自分の墓地の「磁石の戦士α」「磁石の戦士β」「磁石の戦士γ」を１体ずつ対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンに乗るワイバーン",h:"どらごんにのるわいばーん",e:"Alligator's Sword Dragon",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:1700,de:1500,tx:"「ベビードラゴン」＋「ワイバーンの戦士」\n①：相手フィールドの表側表示モンスターが地・水・炎属性モンスターのみの場合、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラッド・ヴォルス",h:"ぶらっど・う゛ぉるす",e:"Vorse Raider",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:1200,tx:"悪行の限りを尽くし、それを喜びとしている魔獣人。\n手にした斧は常に血塗られている。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊輪",h:"はかいりん",e:"Ring of Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手ターンに、相手LPの数値以下の攻撃力を持つ相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを破壊し、自分はそのモンスターの元々の攻撃力分のダメージを受ける。\nその後、自分が受けたダメージと同じ数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アクアの合唱",h:"あくあのがっしょう",e:"Aqua Chorus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：フィールドに同名モンスターが存在する、フィールドのモンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"セベクの祝福",h:"せべくのしゅくふく",e:"Sebek's Blessing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のモンスターが直接攻撃によって相手に戦闘ダメージを与えた時に発動できる。\nその数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔封じの芳香",h:"まふうじのほうこう",e:"Anti-Spell Fragrance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いに魔法カードはセットしなければ発動できず、セットしたプレイヤーから見て次の自分ターンが来るまで発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォース",h:"ふぉーす",e:"Riryoku",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター２体を対象として発動できる。\nターン終了時まで、対象のモンスター１体の攻撃力を半分にし、その数値分もう１体のモンスターの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜殺しの剣",h:"りゅうごろしのけん",e:"Sword of Dragon's Soul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。\n②：装備モンスターがドラゴン族モンスターと戦闘を行ったダメージ計算後に発動する。\n装備モンスターと戦闘を行ったそのモンスターはそのバトルフェイズ終了時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"旧神の印",h:"きゅうしんのいん",e:"Seal of the Ancients",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：１０００LP払って発動できる。\n相手フィールドにセットされたカードを全て確認する。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚神エクゾディア",h:"しょうかんしんえくぞでぃあ",e:"The Legendary Exodia Incarnate",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「封印されし」モンスター１体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地の「封印されし」モンスターの数×１０００アップする。\n②：このカードは他のカードの効果を受けない。\n③：自分エンドフェイズに発動する。\n自分の墓地から「封印されし」モンスター１体を選んで手札に加える。\n④：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\n手札の「封印されし」モンスターを任意の数だけ相手に見せ、見せた数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽の戦士",h:"たいようのせんし",e:"Luminous Soldier",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"このカードが闇属性モンスターと戦闘を行う場合、ダメージステップの間このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"王虎ワンフー",h:"おうこわんふー",e:"King Tiger Wanghu",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、攻撃力１４００以下のモンスターが召喚・特殊召喚に成功した時、その攻撃力１４００以下のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コマンド・ナイト",h:"こまんど・ないと",e:"Command Knight",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1900,tx:"①：このカードがモンスターゾーンに存在する限り、自分フィールドの戦士族モンスターの攻撃力は４００アップする。\n②：自分フィールドに他のモンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ベイオウルフ",h:"べいおうるふ",e:"Wolf Axwielder",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1650,de:1000,tx:"一度闘いを始めると、決着がつくまで闘うことをやめないバーサーカー。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導紳士－J",h:"まどうしんし－Ｊ",e:"The Illusory Gentleman",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1500,de:1600,tx:"奇抜な変装に身を包んで、気まぐれに悪事を働いたり人助けをしたりする。\n「美」にはうるさい。",li:"",lk:[],ta:[],al:[]},
  {n:"ノーブル・ド・ノワール",h:"のーぶる・ど・のわーる",e:"Patrician of Darkness",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカードがフィールド上に表側表示で存在する限り、相手モンスターの攻撃対象はこのカードのコントローラーが選択する。",li:"",lk:[],ta:[],al:[]},
  {n:"バード・フェイス",h:"ばーど・ふぇいす",e:"Birdface",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「ハーピィ・レディ」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クルーエル",h:"くるーえる",e:"Kryuel",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1700,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手フィールド上に存在するモンスター１体を選択して発動する。\nコイントスを１回行い、裏表を当てる。\n当たった場合、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天空騎士パーシアス",h:"えんじぇるないとぱーしあす",e:"Airknight Parshath",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードが相手に戦闘ダメージを与えた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精王オベロン",h:"ようせいおうおべろん",e:"Fairy King Truesdale",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"①：このカードがモンスターゾーンに守備表示で存在する限り、自分フィールドの植物族モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナーガ",h:"なーが",e:"Serpentine Princess",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1400,de:2000,tx:"フィールド上に表側表示で存在するこのカードがデッキに戻った場合、自分のデッキからレベル３以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"海神の巫女",h:"うながみのみこ",e:"Maiden of the Aqua",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、フィールドは「海」になる。\nフィールド魔法カードが表側表示で存在する場合、この効果は適用されない。",li:"",lk:[],ta:[],al:[]},
  {n:"機械軍曹",h:"きかいぐんそう",e:"Robotic Knight",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"機械王に仕える機械族の司令塔。\n機械の兵隊を統率する攻撃的な用兵で有名。",li:"",lk:[],ta:[],al:[]},
  {n:"雷電娘々",h:"らいでんにゃんにゃん",e:"Thunder Nyan Nyan",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1900,de:800,tx:"自分フィールド上に光属性以外の表側表示モンスターが存在する場合、表側表示のこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"溶岩大巨人",h:"まぐま・ぎがんと",e:"Molten Behemoth",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1000,de:2200,tx:"マグマの大地から生まれた巨人。\nマグマパンチで攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドル・ドラ",h:"どる・どら",e:"Twin-Headed Behemoth",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力・守備力は１０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"お注射天使リリー",h:"おちゅうしゃてんしりりー",e:"Injection Fairy Lily",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:1500,tx:"①：このカードが戦闘を行うそのダメージ計算時に１度、２０００LPを払って発動できる。\nこのカードの攻撃力はダメージ計算時のみ３０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"リーフ・フェアリー",h:"りーふ・ふぇありー",e:"Woodland Sprite",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"このカードに装備された装備カード１枚を墓地へ送って発動する。\n相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマード・フライ",h:"あーまーど・ふらい",e:"Arsenal Bug",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"自分フィールド上にこのカード以外の昆虫族モンスターが存在しなければ、このカードの攻撃力・守備力はそれぞれ１０００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイファー・スカウター",h:"さいふぁー・すかうたー",e:"Cipher Soldier",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1350,de:1800,tx:"このカードが戦士族モンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力・守備力は、そのダメージ計算時のみ２０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"X・E・N・O",h:"ぜの",e:"Jowls of Dark Demise",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:100,tx:"リバース：相手フィールド上に存在するモンスター１体を選択し、エンドフェイズ時までコントロールを得る。\nこの効果でコントロールを得たモンスターは、このターン相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魂喰らい",h:"たまぐらい",e:"Souleater",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1200,de:0,tx:"全てが謎に包まれている超生命体。",li:"",lk:[],ta:[],al:[]},
  {n:"ニュート",h:"にゅーと",e:"Slate Warrior",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1900,de:400,tx:"①：このカードがリバースした場合に発動する。\nこのカードの攻撃力・守備力は５００アップする。\n②：このカードがモンスターとの戦闘で破壊された場合に発動する。\nそのモンスターの攻撃力・守備力を５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"シェイプ・スナッチ",h:"しぇいぷ・すなっち",e:"Shapesnatch",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1200,de:1700,tx:"恐ろしい力を持つ蝶ネクタイ。\n何者かの身体を支配して襲いかかる。",li:"",lk:[],ta:[],al:[]},
  {n:"電磁ミノ虫",h:"ざっくとれーがー・まぐね",e:"Electromagnetic Bagworm",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:1400,tx:"①：このカードがリバースした場合、相手フィールドの機械族モンスター１体を対象として発動する。\n次の自分エンドフェイズまで、その機械族モンスターのコントロールを得る。\n②：このカードがモンスターとの戦闘で破壊された場合に発動する。\nそのモンスターの攻撃力・守備力を５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"タイム・イーター",h:"たいむ・いーたー",e:"Timeater",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1700,tx:"このカードが戦闘によって相手モンスターを破壊した場合、次の相手ターンのメインフェイズ１をスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"グラッジ",h:"ぐらっじ",e:"Mucus Yolk",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカードは相手プレイヤーに直接攻撃をする事ができる。\nこのカードが相手プレイヤーに戦闘ダメージを与えた場合、次の自分のスタンバイフェイズ時にこのカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"異形の従者",h:"いぎょうのじゅうしゃ",e:"Servant of Catabolism",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"リグラス・リーパー",h:"りぐらす・りーぱー",e:"Rigorous Reaver",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"植物族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:100,tx:"①：このカードがリバースした場合に発動する。\nお互いのプレイヤーは、それぞれ自分の手札を１枚選んで捨てる。\n②：このカードがモンスターとの戦闘で破壊された場合に発動する。\nそのモンスターの攻撃力・守備力を５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"モイスチャー星人",h:"もいすちゃーせいじん",e:"Moisture Creature",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2900,tx:"このカードはモンスター３体をリリースして召喚する事ができる。\nこの方法で召喚に成功した時、相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ボーンハイマー",h:"ぼーんはいまー",e:"Boneheimer",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:850,de:400,tx:"海の中をさまよい、獲物を見つけたらあらゆる水分を吸い尽くす。",li:"",lk:[],ta:[],al:[]},
  {n:"フレイムダンサー",h:"ふれいむだんさー",e:"Flame Dancer",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:550,de:450,tx:"燃えたぎるロープを振り回し近づいてくる。\n目を合わせたら駄目だ。",li:"",lk:[],ta:[],al:[]},
  {n:"ソニックジャマー",h:"そにっくじゃまー",e:"Sonic Jammer",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:350,de:650,tx:"リバース：次のターンのエンドフェイズ終了時まで、相手は魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄の騎士 ギア・フリード",h:"てつのきし　ぎあ・ふりーど",e:"Gearfried the Iron Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：このカードに装備カードが装備された場合に発動する。\nその装備カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒューマノイド・スライム",h:"ひゅーまのいど・すらいむ",e:"Humanoid Slime",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"人間の形をしたスライム。\n人間の遺伝子が組み込まれている。",li:"",lk:[],ta:[],al:[]},
  {n:"ワームドレイク",h:"わーむどれいく",e:"Worm Drake",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"その長い体に巻き込まれたら最後、二度と逃げる事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒューマノイド・ドレイク",h:"ひゅーまのいど・どれいく",e:"Humanoid Worm Drake",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["融合"],ma:"",tp:"",at:2200,de:2000,tx:"「ワームドレイク」＋「ヒューマノイド・スライム」",li:"",lk:[],ta:[],al:[]},
  {n:"リバイバルスライム",h:"りばいばるすらいむ",e:"Revival Jam",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードが戦闘によって墓地に送られた時１０００ライフポイントを払う事で、次の自分のスタンバイフェイズに自分フィールド上に表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"フライング・フィッシュ",h:"ふらいんぐ・ふぃっしゅ",e:"Flying Fish",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:800,de:500,tx:"空を飛ぶ姿を見ると、３つの願い事がかなうと言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"半魚獣・フィッシャービースト",h:"はんぎょじゅう・ふぃっしゃーびーすと",e:"Amphibian Beast",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:2400,de:2000,tx:"陸では獣のように、海では魚のように素早く攻撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"ロケット戦士",h:"ろけっとせんし",e:"Rocket Warrior",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：自分バトルフェイズ中、このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードがモンスターに攻撃したダメージ計算後に発動する。\n攻撃対象モンスターの攻撃力はターン終了時まで５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説のフィッシャーマン",h:"でんせつのふぃっしゃーまん",e:"The Legendary Fisherman",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1850,de:1600,tx:"①：フィールドに「海」が存在する限り、フィールドのこのカードは魔法カードの効果を受けない。\n②：フィールドに「海」が存在する限り、このカードは攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。",li:"",lk:[],ta:[],al:[]},
  {n:"レアメタル・レディ",h:"れあめたる・れでぃ",e:"Robolady",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:450,de:900,tx:"全身がメタルの装甲で覆われている女戦士。\n「レアメタル・ソルジャー」と融合し、パワーアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レアメタル・ソルジャー",h:"れあめたる・そるじゃー",e:"Roboyarou",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:450,tx:"全身がメタルの装甲で覆われている戦士。\n「レアメタル・レディ」と融合し、パワーアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"雷ウナギ",h:"かみなりうなぎ",e:"Lightning Conger",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:350,de:750,tx:"電気ウナギが進化すると雷ウナギになると言い伝えられている。",li:"",lk:[],ta:[],al:[]},
  {n:"スフィラスレディ",h:"すふぃらすれでぃ",e:"Spherous Lady",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:400,de:1400,tx:"美女と思い近づくと、首筋を噛まれ全身の血を吸われてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"シャイン・アビス",h:"しゃいん・あびす",e:"Shining Abyss",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"光の力と闇の力を兼ね備えているモンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒魔族ギルファー・デーモン",h:"あんこくまぞくぎるふぁー・でーもん",e:"Archfiend of Gilfer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:2500,tx:"①：このカードが墓地へ送られた時、フィールドの表側表示モンスター１体を対象として発動できる。\n墓地のこのカードを攻撃力５００ダウンの装備カード扱いとしてそのモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガジェット・ソルジャー",h:"がじぇっと・そるじゃー",e:"Gadget Soldier",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"戦うために造られた機械の戦士。\nさびない金属でできている。",li:"",lk:[],ta:[],al:[]},
  {n:"仮面呪術師カースド・ギュラ",h:"かめんじゅじゅつしかーすど・ぎゅら",e:"Grand Tiki Elder",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"呪いの呪文で相手を念殺する、仮面モンスター。",li:"",lk:[],ta:[],al:[]},
  {n:"仮面魔獣マスクド・ヘルレイザー",h:"かめんまじゅうますくど・へるれいざー",e:"The Masked Beast",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:3200,de:1800,tx:"「仮面魔獣の儀式」により降臨。",li:"",lk:[],ta:[],al:[]},
  {n:"メルキド四面獣",h:"めるきどしめんじゅう",e:"Melchid the Four-Face Beast",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"４つの仮面を切り替えながら、４種類の攻撃をしてくる化け物。",li:"",lk:[],ta:[],al:[]},
  {n:"女邪神ヌヴィア",h:"めじゃしんぬう゛ぃあ",e:"Nuvia the Wicked",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"召喚された場合、このカードを破壊する。\n相手が１体でもモンスターをコントロールしていた場合、攻撃力は相手フィールド上のモンスター１体につき２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"仮面魔獣デス・ガーディウス",h:"かめんまじゅうです・がーでぃうす",e:"Masked Beast Des Gardius",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3300,de:2500,tx:"このカードは通常召喚できない。\n「仮面呪術師カースド・ギュラ」「メルキド四面獣」の内いずれかを含む自分フィールドのモンスター２体をリリースした場合に特殊召喚できる。\n①：このカードがフィールドから墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動する。\nデッキから「遺言の仮面」１枚を装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"遺言の仮面",h:"ゆいごんのかめん",e:"The Mask of Remnants",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このカードをデッキに戻してシャッフルする。\n②：このカードが「仮面魔獣デス・ガーディウス」の効果で装備されている場合、装備モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"クロス・ソウル",h:"くろす・そうる",e:"Soul Exchange",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：相手フィールドのモンスター１体を対象として発動できる。\nこのターン自分がモンスターをリリースする場合、自分のモンスター１体の代わりに対象の相手モンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"選ばれし者",h:"えらばれしもの",e:"Chosen One",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から、モンスターカード１枚とモンスター以外のカード２枚を選択し、相手はその中からランダムに１枚を選択する。\n相手が選択したカードがモンスターだった場合、そのモンスターを自分フィールド上に特殊召喚し、残りのカードは墓地へ送る。\n違った場合、選択した３枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"弱体化の仮面",h:"じゃくたいかのかめん",e:"Mask of Weakness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターの攻撃力はターン終了時まで７００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"仮面魔獣の儀式",h:"かめんまじゅうのぎしき",e:"Curse of the Masked Beast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「仮面魔獣マスクド・ヘルレイザー」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「仮面魔獣マスクド・ヘルレイザー」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力無力化の仮面",h:"まりょくむりょくかのかめん",e:"Mask of Dispel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する魔法カード１枚を選択して発動する。\n自分のスタンバイフェイズ毎に、選択した魔法カードのコントローラーに５００ポイントダメージを与える。\n選択したカードがフィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"生贄封じの仮面",h:"いけにえふうじのかめん",e:"Mask of Restrict",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いにカードをリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"呪魂の仮面",h:"じゅこんのかめん",e:"Mask of the Accursed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは攻撃する事ができない。\nまた、自分のスタンバイフェイズ毎に、装備モンスターのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"凶暴化の仮面",h:"きょうぼうかのかめん",e:"Mask of Brutality",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は１０００ポイントアップし、守備力は１０００ポイントダウンする。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に１０００ライフポイントを払う。\nまたは、１０００ライフポイント払わずにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"死者の生還",h:"ししゃのせいかん",e:"Return of the Doomed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札からモンスターカード１枚を墓地に捨てる。\nこのターン戦闘によって破壊され自分の墓地へ送られたモンスター１体を、ターン終了時に手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"稲妻の剣",h:"いなずまのけん",e:"Lightning Blade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップし、フィールド上に表側表示で存在する全ての水属性モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜巻海流壁",h:"とるねーどうぉーる",e:"Tornado Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドに「海」が存在する場合にこのカードを発動できる。\n①：フィールドに「海」が存在する限り、自分が受ける戦闘ダメージは０になる。\n②：フィールドに「海」が存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"メサイアの蟻地獄",h:"めさいあのありじごく",e:"Infinite Dismissal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側表示で存在する全てのレベル３以下のモンスターは、召喚・反転召喚したターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスターBOX",h:"もんすたーぼっくす",e:"Fairy Box",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは自分スタンバイフェイズ毎に５００LPを払う。\nまたはLPを払わずにこのカードを破壊する。\n①：相手モンスターの攻撃宣言時に発動する。\nコイントスを１回行い、裏表を当てる。\n当たった場合、その攻撃モンスターの攻撃力は、このカードが魔法＆罠ゾーンに存在する限り、バトルフェイズ終了時まで０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"激流葬",h:"げきりゅうそう",e:"Torrential Tribute",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：モンスターが召喚・反転召喚・特殊召喚された時に発動できる。\nフィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アリの増殖",h:"ありのぞうしょく",e:"Multiplication of Ants",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の昆虫族モンスター１体を生け贄に捧げて発動する。\n自分フィールド上に「兵隊アリトークン」（昆虫族・地・星４・攻５００／守１２００）を２体特殊召喚する。\n（生け贄召喚のための生け贄にはできない）",li:"",lk:[],ta:[],al:[]},
  {n:"融合解除",h:"ゆうごうかいじょ",e:"De-Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの融合モンスター１体を対象として発動できる。\nその融合モンスターを持ち主のEXデッキに戻す。\nその後、EXデッキに戻したそのモンスターの融合召喚に使用した融合素材モンスター一組が自分の墓地に揃っていれば、その一組を自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スライム増殖炉",h:"すらいむぞうしょくろ",e:"Jam Breeding Machine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ毎に、自分フィールド上に「スライムモンスタートークン」（水族・水・星１・攻／守５００）１体を攻撃表示で特殊召喚する。\nこのカードがフィールド上に存在する限り、自分は「スライムモンスタートークン」以外のモンスターを召喚・反転召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"悪夢の鉄檻",h:"あくむのてつおり",e:"Nightmare's Steelcage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"全てのモンスターは（相手ターンで数えて）２ターンの間攻撃できない。\n２ターン後このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"無限の手札",h:"むげんのてふだ",e:"Infinite Cards",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーは手札枚数制限が無くなる。",li:"",lk:[],ta:[],al:[]},
  {n:"ディフェンド・スライム",h:"でぃふぇんど・すらいむ",e:"Jam Defender",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが自分のモンスターに攻撃した時、自分のフィールド上に「リバイバルスライム」が表側表示で存在している場合、攻撃対象を「リバイバルスライム」に移し替える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"生還の宝札",h:"せいかんのほうさつ",e:"Card of Safe Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するモンスターが特殊召喚に成功した時、自分のデッキからカードを１枚ドローする事ができる。",li:0,lk:[],ta:[],al:[]},
  {n:"魔法の筒",h:"まじっく・しりんだー",e:"Magic Cylinder",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時、攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターの攻撃を無効にし、その攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"神の恵み",h:"かみのめぐみ",e:"Solemn Wishes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分がカードをドローする度に、自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"燃えさかる大地",h:"もえさかるだいち",e:"Burning Land",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、フィールド上のフィールド魔法カードを全て破壊する。\nまた、お互いのスタンバイフェイズ時、ターンプレイヤーは５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"大寒波",h:"だいかんぱ",e:"Cold Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１の開始時に発動する事ができる。\n次の自分のドローフェイズ時まで、お互いに魔法・罠カードの効果の使用及び発動・セットはできない。",li:0,lk:[],ta:[],al:[]},
  {n:"メテオ・ストライク",h:"めてお・すとらいく",e:"Fairy Meteor Crush",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"リミッター解除",h:"りみったーかいじょ",e:"Limiter Removal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの全ての機械族モンスターの攻撃力は、ターン終了時まで倍になる。\nこの効果が適用されているモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"シフトチェンジ",h:"しふとちぇんじ",e:"Shift",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスター１体が相手の魔法・罠カードの効果の対象になった時、または相手モンスターの攻撃対象になった時に発動できる。\nその対象を、自分フィールド上の正しい対象となる他のモンスター１体に移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"孵化",h:"ふか",e:"Insect Imitation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターよりレベルが１つ高い昆虫族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ホール",h:"わーむ・ほーる",e:"Dimensionhole",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択した自分のモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nゲームから除外している間、そのモンスターカードゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ドレイン",h:"まじっく・どれいん",e:"Magic Drain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が魔法カードを発動した時に発動する事ができる。\n相手は手札から魔法カード１枚を捨ててこのカードの効果を無効にする事ができる。\n捨てなかった場合、相手の魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"グラヴィティ・バインド－超重力の網－",h:"ぐらう゛ぃてぃ・ばいんど－ちょうじゅうりょくのあみ－",e:"Gravity Bind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上のレベル４以上のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"誘惑のシャドウ",h:"ゆうわくのしゃどう",e:"Shadow of Eyes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターがセットされた時に発動する事ができる。\nそのセットされたモンスター１体を表側攻撃表示にする。\n（この時、リバース効果モンスターの効果は発動しない。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ギロチン・クワガタ",h:"ぎろちん・くわがた",e:"Girochin Kuwagata",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"ハイエルフの森に生息するクワガタ。\n人の親指ほどの大きさしかないが、鋼鉄をも切り裂く強力な顎をもつ。",li:"",lk:[],ta:[],al:[]},
  {n:"隼の騎士",h:"はやぶさのきし",e:"Hayabusa Knight",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"①：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"爆弾かめ虫",h:"ばくだんかめむし",e:"Bombardment Beetle",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:900,tx:"リバース：相手フィールド上の裏側守備表示モンスターカード１枚を確認する。\n効果モンスターの場合は破壊し（リバース効果は発動しない）、違う場合は元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"死の４つ星てんとう虫",h:"しの４つほしてんとうむし",e:"4-Starred Ladybug of Doom",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1200,tx:"リバース：相手フィールド上に表側表示で存在するレベル４モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"超時空戦闘機ビック・バイパー",h:"ちょうじくうせんとうきびっく・ばいぱー",e:"Gradius",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"パワーカプセルにより、様々な能力を発揮する超高性能戦闘機。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・ベビー",h:"う゛ぁんぱいあ・べびー",e:"Vampire Baby",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:700,de:1000,tx:"このカードが戦闘によってモンスターを破壊したバトルフェイズ終了時、墓地に存在するそのモンスターを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"猛進する剣角獣",h:"もうしんするけんかくじゅう",e:"Mad Sword Beast",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"海賊船スカルブラッド号",h:"かいぞくせんすかるぶらっどごう",e:"Skull Mariner",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:900,tx:"船首に赤い骸骨をかたどった海賊船。\nあらゆる海域に神出鬼没に現れ、旅客船や貨物船を襲撃する。",li:"",lk:[],ta:[],al:[]},
  {n:"隻眼のホワイトタイガー",h:"せきがんのほわいとたいがー",e:"The All-Seeing White Tiger",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1300,de:500,tx:"ある者には恐怖、ある者には尊敬の対象とされている、気高き密林の王者。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリン突撃部隊",h:"ごぶりんとつげきぶたい",e:"Goblin Attack Force",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"①：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分ターンの終了時まで表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"島亀",h:"しまがめ",e:"Island Turtle",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1100,de:2000,tx:"小島ほどの大きさがある巨大ガメ。\n海中に潜ることはなく、甲羅の上には木や生物が住みついている。",li:"",lk:[],ta:[],al:[]},
  {n:"翼を織りなす者",h:"つばさをおりなすもの",e:"Wingweaver",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:2750,de:2400,tx:"６枚の翼をもつハイエンジェル。\n人々の自由と希望を司っている。",li:"",lk:[],ta:[],al:[]},
  {n:"科学特殊兵",h:"かがくとくしゅへい",e:"Science Soldier",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:800,tx:"未知の生物に対抗するため、最新の科学兵器を装備した兵士。\n背中のコンテナにはさまざまな兵器が収納されている。",li:"",lk:[],ta:[],al:[]},
  {n:"怨念集合体",h:"おんねんしゅうごうたい",e:"Souls of the Forgotten",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:900,de:200,tx:"恨みを持って死んでいった人の意識が集まってできた悪霊。\n人を襲いその意識をとりこんで巨大化していく。",li:"",lk:[],ta:[],al:[]},
  {n:"死神ブーメラン",h:"しにがみぶーめらん",e:"Dokuroyaiba",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:400,tx:"ねらいを付けた標的をめがけてどこまでも飛んでゆく意志を持ったブーメラン。",li:"",lk:[],ta:[],al:[]},
  {n:"恵みの雨",h:"めぐみのあめ",e:"Rain of Mercy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスター回収",h:"もんすたーかいしゅう",e:"Monster Recovery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"元々の持ち主が自分となる、自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターと自分の手札を全てデッキに加えてシャッフルする。\nその後、元の手札の枚数分だけデッキからカードをドローする。\n元々の持ち主が相手となるカードが自分の手札にある場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"零式魔導粉砕機",h:"ぜろしきまどうふんさいき",e:"Type Zero Magic Crusher",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"手札の魔法カード１枚を捨てる事で、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・シールド",h:"かおす・しーるど",e:"Yellow Luster Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全てのモンスターの守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"強制転移",h:"きょうせいてんい",e:"Creature Swap",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、それぞれ自身のフィールドのモンスター１体を選ぶ。\nそのモンスター２体のコントロールを入れ替える。\nこのターン、そのモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントの泉",h:"えれめんとのいずみ",e:"Spring of Rebirth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するモンスターが持ち主の手札に戻った時、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"千本ナイフ",h:"さうざんどないふ",e:"Thousand Knives",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン」が存在する場合、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"死のマジック・ボックス",h:"しのまじっく・ぼっくす",e:"Mystic Box",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分及び相手フィールドのモンスターを１体ずつ対象として発動できる。\nその相手のモンスターを破壊する。\nその後、その自分のモンスターのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"地盤沈下",h:"じばんちんか",e:"Ground Collapse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"使用していないメインモンスターゾーンを２ヵ所指定してこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、指定したゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"女豹の傭兵",h:"れでぃぱんさー",e:"Lady Panther",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"表側表示のこのカードを生け贄に捧げる。\nこのターンに戦闘によって破壊され自分の墓地へ送られたモンスター１体をデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"味方殺しの女騎士",h:"みかたごろしのおんなきし",e:"The Unfriendly Amazon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"自分のスタンバイフェイズ毎に、このカードを除く自分のフィールド上モンスター１体を生け贄に捧げなければ、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの射手",h:"あまぞねすあーちゃー",e:"Amazoness Archer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：自分フィールドのモンスター２体をリリースして発動できる。\n相手に１２００ダメージを与える。",li:0,lk:[],ta:[],al:[]},
  {n:"紅蓮の女守護兵",h:"ぐれんのおんなしゅごへい",e:"Crimson Sentry",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"表側表示のこのカードを生け贄に捧げる。\nこのターンに戦闘によって破壊され自分の墓地へ送られたモンスター１体をデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグバンガール",h:"びっぐばんがーる",e:"Fire Princess",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカードがフィールド上に表側表示で存在する限り、自分のライフポイントが回復する度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の女暗殺者",h:"ほのおのおんなあんさつしゃ",e:"Lady Assailant of Flames",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1000,tx:"リバース：自分のデッキの上からカードを３枚ゲームから除外する。\n相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤーソーサラー",h:"ふぁいやーそーさらー",e:"Fire Sorcerer",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1500,tx:"リバース：自分の手札を２枚ランダムに選択しゲームから除外する。\n相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"そよ風の精霊",h:"そよかぜのせいれい",e:"Spirit of the Breeze",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、自分のスタンバイフェイズ毎に自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"踊る妖精",h:"だんしんぐふぇありー",e:"Dancing Fairy",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが自分フィールド上に表側守備表示で存在する限り、自分のスタンバイフェイズ毎に自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ピクシーガーディアン",h:"ぴくしーがーでぃあん",e:"Fairy Guardian",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"表側表示のこのカードを生け贄に捧げる。\nこのターン相手によって墓地に送られた自分の魔法カード１枚をデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"女帝カマキリ",h:"じょていかまきり",e:"Empress Mantis",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:2200,de:1400,tx:"集団性の高い巨大カマキリの女王。\n狩りや移動など、カマキリの大群は全て女王の指令により行動する。",li:"",lk:[],ta:[],al:[]},
  {n:"プリンセス人魚",h:"ぷりんせすにんぎょ",e:"Cure Mermaid",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分のスタンバイフェイズ毎に自分は８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒステリック天使",h:"ひすてりっくえんじぇる",e:"Hysteric Fairy",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"自分フィールド上に存在するモンスター２体をリリースして発動する。\n自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"バイオ僧侶",h:"ばいおそうりょ",e:"Bio-Mage",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1150,de:1000,tx:"最新のバイオテクノロジーによって生み出された僧侶。\n数々の謎を秘めている。",li:"",lk:[],ta:[],al:[]},
  {n:"慈悲深き修道女",h:"じひぶかきしゅうどうじょ",e:"The Forgiving Maiden",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:850,de:2000,tx:"このカードをリリースして発動する。\nこのターン戦闘によって破壊され自分の墓地へ送られたモンスター１体を手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"聖女ジャンヌ",h:"せいじょじゃんぬ",e:"St. Joan",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["融合"],ma:"",tp:"",at:2800,de:2000,tx:"「慈悲深き修道女」＋「堕天使マリー」",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使マリー",h:"だてんしまりー",e:"Darklord Marie",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが墓地に存在する場合、自分のスタンバイフェイズ時に１度だけ、自分は２００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲な瓶",h:"ごうよくなかめ",e:"Jar of Greed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻惑の巻物",h:"げんわくのまきもの",e:"Scroll of Bewitchment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"属性を１つ宣言して発動する。\n装備モンスターの属性は宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"団結の力",h:"だんけつのちから",e:"United We Stand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力・守備力は、自分フィールドの表側表示モンスターの数×８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導師の力",h:"まどうしのちから",e:"Mage Power",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力・守備力は、自分フィールドの魔法・罠カードの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"死者への供物",h:"ししゃへのくもつ",e:"Offerings to the Doomed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを破壊する。\n次の自分ドローフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・メテオ",h:"です・めてお",e:"Meteor of Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに１０００ポイントダメージを与える。\n相手ライフが３０００ポイント以下の場合このカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトニング・ボルテックス",h:"らいとにんぐ・ぼるてっくす",e:"Lightning Vortex",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクスチェンジ",h:"えくすちぇんじ",e:"Exchange",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは手札を公開し、それぞれ相手のカード１枚を選んで自分の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"絵画に潜む者",h:"かいがにひそむもの",e:"The Portrait's Secret",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"描いた者の呪いの込められた絵。\nこの絵を所持した者は全て不幸に陥ると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔の亡霊",h:"むまのぼうれい",e:"The Gross Ghost of Fled Dreams",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1800,tx:"寝ている者の夢に取り憑き、生気を吸い取る悪魔。\n取り憑かれてしまった者は、決して自力で目覚めることはない。",li:"",lk:[],ta:[],al:[]},
  {n:"首なし騎士",h:"くびなしきし",e:"Headless Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1450,de:1700,tx:"反逆者に仕立て上げられ処刑された騎士の亡霊。\n失ったものを求め、出会った者に襲いかかる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ネクロフィア",h:"だーく・ねくろふぃあ",e:"Dark Necrofear",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:2800,tx:"このカードは通常召喚できない。\n自分の墓地から悪魔族モンスター３体を除外した場合に特殊召喚できる。\n①：モンスターゾーンのこのカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに、相手フィールドの表側表示モンスター１体を対象として発動する。\n墓地のこのカードを装備カード扱いとしてその相手モンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、装備モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔術の呪文書",h:"まじゅつのじゅもんしょ",e:"Magic Formula",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ブラック・マジシャン」「ブラック・マジシャン・ガール」にのみ装備可能。\n装備モンスターの攻撃力は７００ポイントアップする。\nこのカードがフィールド上から墓地へ送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィジャ盤",h:"うぃじゃばん",e:"Destiny Board",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードと「死のメッセージ」カード４種類が自分フィールドに揃った時、自分はデュエルに勝利する。\n①：相手エンドフェイズにこの効果を発動する。\n手札・デッキから、「死のメッセージ」カード１枚を「E」「A」「T」「H」の順番で自分の魔法＆罠ゾーンに出す。\n②：自分フィールドの「ウィジャ盤」または「死のメッセージ」カードがフィールドから離れた時に自分フィールドの「ウィジャ盤」及び「死のメッセージ」カードは全て墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の扉",h:"あんこくのとびら",e:"The Dark Door",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは、バトルフェイズにモンスター１体でしか攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛霊",h:"あーす・ばうんど・すぴりっと",e:"Earthbound Spirit",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:500,de:2000,tx:"闘いに敗れた兵士たちの魂が一つになった怨霊。\nこの地に足を踏み入れた者を地中に引きずり込もうとする。",li:"",lk:[],ta:[],al:[]},
  {n:"沈黙の邪悪霊",h:"ちんもくのだーく・すぴりっと",e:"Dark Spirit of the Silent",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードは相手のバトルステップ時にのみ発動する事ができる。\n攻撃モンスター１体の攻撃を無効にし、相手フィールド上に表側表示で存在する他のモンスター１体を選択して代わりに攻撃させる。\n（選択したモンスターが守備表示の場合は攻撃表示にする。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"死霊伯爵",h:"しりょうはくしゃく",e:"The Earl of Demise",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2000,de:700,tx:"魔界の伯爵。\n紳士を装ってはいるが、本性は邪悪そのもの。\n人間だけでなく、低級悪魔たちにも恐れられている。",li:"",lk:[],ta:[],al:[]},
  {n:"黒衣の大賢者",h:"こくいのだいけんじゃ",e:"Dark Sage",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:3200,tx:"このカードは通常召喚できない。\n自分の「時の魔術師」のコイントスを当てた時、自分フィールド上に存在する「ブラック・マジシャン」１体をリリースして手札またはデッキから特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、自分のデッキから魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"王家の神殿",h:"おうけのしんでん",e:"Temple of the Kings",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分は罠カード１枚をセットしたターンに発動できる。\n②：自分フィールドの表側表示の「聖獣セルケト」１体とこのカードを墓地へ送って発動できる。\n手札・デッキのモンスター１体またはEXデッキの融合モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アヌビスの裁き",h:"あぬびすのさばき",e:"Judgment of Anubis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札を１枚捨てる。\n相手がコントロールする「フィールド上の魔法・罠カードを破壊する」効果を持つ魔法カードの発動と効果を無効にし破壊する。\nその後、相手フィールド上の表側表示モンスター１体を破壊し、そのモンスターの攻撃力分のダメージを相手プレイヤーに与える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アポピスの化神",h:"あぽぴすのけしん",e:"Embodiment of Apophis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分・相手のメインフェイズに発動できる。\nこのカードは発動後、通常モンスター（爬虫類族・地・星４・攻１６００／守１８００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"おろかな埋葬",h:"おろかなまいそう",e:"Foolish Burial",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからモンスター１体を墓地へ送る。",li:1,lk:[],ta:[],al:[]},
  {n:"魔霧雨",h:"まきう",e:"Makiu, the Magical Mist",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：自分のモンスターゾーンの、「デーモンの召喚」または雷族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力以下の守備力を持つ、相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ランプ",h:"まじっく・らんぷ",e:"Ancient Lamp",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"①：自分メインフェイズに発動できる。\nこのカードがフィールドに表側表示で存在する場合、手札から「ランプの魔精・ラ・ジーン」１体を特殊召喚する。\n②：裏側守備表示のこのカードが相手モンスターに攻撃されたダメージ計算前に、攻撃モンスター以外の相手モンスター１体を対象として発動できる。\n攻撃対象をその相手モンスターに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ・SB",h:"はーぴぃ・れでぃ・さいばー・ぼんてーじ",e:"Cyber Harpie Lady",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"このカードのカード名はルール上「ハーピィ・レディ」として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"聖獣セルケト",h:"せいじゅうせるけと",e:"Mystical Beast of Serket",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：自分フィールドに「王家の神殿」が存在しない場合にこのカードは破壊される。\n②：このカードがモンスターゾーンに存在する限り、このカードが戦闘で破壊したモンスターは除外される。\n③：このカードが戦闘でモンスターを破壊した場合に発動する。\nこのカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"疾風の暗黒騎士ガイア",h:"しっぷうのあんこくきしがいあ",e:"Swift Gaia the Fierce Knight",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：手札がこのカード１枚のみの場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"翻弄するエルフの剣士",h:"ほんろうするえるふのけんし",e:"Obnoxious Celtic Guard",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"①：このカードは攻撃力１９００以上のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ヒーロー ゾンバイア",h:"だーく・ひーろー　ぞんばいあ",e:"Zombyra the Dark",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:500,tx:"①：このカードは直接攻撃できない。\n②：このカードが戦闘でモンスターを破壊した場合に発動する。\nこのカードの攻撃力は２００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ポルターガイスト",h:"ぽるたーがいすと",e:"Spiritualism",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを持ち主の手札に戻す。\nこのカードの発動と効果は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"昇霊術師 ジョウゲン",h:"しょうれいじゅつし　じょうげん",e:"Jowgen the Spiritualist",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:200,de:1300,tx:"手札をランダムに１枚墓地へ捨てて発動できる。\nフィールド上の特殊召喚されたモンスターを全て破壊する。\nまた、このカードがフィールド上に表側表示で存在する限り、お互いにモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霊滅術師 カイクウ",h:"れいめつじゅつし　かいくう",e:"Kycoo the Ghost Destroyer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"①：このカードがモンスターゾーンに存在する限り、相手はお互いの墓地のカードを除外できない。\n②：このカードが相手に戦闘ダメージを与えた時、相手の墓地のモンスターを２体まで対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻想召喚師",h:"げんそうしょうかんし",e:"Summoner of Illusions",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:900,tx:"①：このカードがリバースした場合に発動する。\nこのカード以外の自分フィールドのモンスター１体をリリースし、エクストラデッキから融合モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"魂を喰らう者 バズー",h:"たましいをくらうもの　ばずー",e:"Bazoo the Soul-Eater",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"１ターンに１度、自分の墓地に存在するモンスターを３体までゲームから除外して発動する事ができる。\nこのカードの攻撃力は相手のエンドフェイズ時まで、除外したモンスター１体につき３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"神聖なる魂",h:"ほーりーしゃいん・そうる",e:"Soul of Purity and Light",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:1800,tx:"このカードは通常召喚できない。\n自分の墓地から光属性モンスター２体を除外した場合に特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力は、相手バトルフェイズの間３００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の精霊 イフリート",h:"ほのおのせいれい いふりーと",e:"Spirit of Flames",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地から炎属性モンスター１体を除外した場合に特殊召喚できる。\n①：このカードの攻撃力は自分バトルフェイズの間３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"水の精霊 アクエリア",h:"みずのせいれい　あくえりあ",e:"Aqua Spirit",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地から水属性モンスター１体を除外した場合に特殊召喚できる。\n①：相手スタンバイフェイズに、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。\nこのターン、そのモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"岩の精霊 タイタン",h:"いわのせいれい　たいたん",e:"The Rock Spirit",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する地属性モンスター１体をゲームから除外した場合に特殊召喚する事ができる。\nこのカードの攻撃力は相手のバトルフェイズ中のみ３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"風の精霊 ガルーダ",h:"かぜのせいれい　がるーだ",e:"Garuda the Wind Spirit",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地から風属性モンスター１体を除外した場合に特殊召喚できる。\n①：相手エンドフェイズに、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"俊足のギラザウルス",h:"しゅんそくのぎらざうるす",e:"Gilasaurus",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"①：このカードは手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合に発動する。\n相手は自身の墓地のモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トルネード・バード",h:"とるねーど・ばーど",e:"Tornado Bird",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:1000,tx:"リバース：フィールド上に存在する魔法・罠カード２枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影の妖精",h:"げんえいのようせい",e:"Dreamsprite",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードが相手モンスターから攻撃を受けた場合、他の自分のフィールド上モンスター１体が攻撃を受けた事にし、ダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"物資調達員",h:"ぶっしちょうたついん",e:"Supply",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1300,de:800,tx:"リバース：「融合」によって自分の墓地へ送られた融合素材モンスター２体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力吸収球体",h:"まりょくきゅうしゅうきゅうたい",e:"Maryokutai",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"相手が魔法カードを発動した時、自分フィールド上に表側表示で存在するこのカードをリリースする事で、その発動を無効にし破壊する。\nこの効果は相手ターンにのみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"力の集約",h:"ちからのしゅうやく",e:"Collected Power",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nフィールド上に存在する全ての装備カードを選択したモンスターに装備する。\n対象が正しくない装備カードは破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮の号令",h:"おうきゅうのごうれい",e:"Royal Command",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、全てのリバース効果モンスターの発動及び効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"フォースフィールド",h:"ふぉーすふぃーるど",e:"Riryoku Field",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上のモンスター１体を対象にした魔法カードの発動を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"死霊の巣",h:"しりょうのす",e:"Skull Lair",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地に存在するモンスターを任意の枚数ゲームから除外する事で、その枚数と同じレベルを持つフィールド上に表側表示で存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓荒らしの報い",h:"はかあらしのむくい",e:"Graverobber's Retribution",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎に、ゲームから除外されている相手モンスターの数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"守護霊のお守り",h:"しゅごれいのおまもり",e:"Deal of Phantom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択する。\n選択したモンスター１体の攻撃力は、ターン終了時まで自分の墓地に存在するモンスター１体につき攻撃力が１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カウンターパンチ",h:"かうんたーぱんち",e:"Destruction Punch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に守備表示で存在するモンスターが相手モンスターに攻撃され、その守備力が攻撃モンスターの攻撃力を超えていた場合、ダメージ計算時に発動する事ができる。\nその攻撃モンスターをダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"無差別破壊",h:"むさべつはかい",e:"Blind Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎にサイコロを１回振る。\n１～５の目が出た場合、フィールド上に表側表示で存在する出た目と同じレベルのモンスターを全て破壊する。\n６の目が出た場合、フィールド上に表側表示で存在するレベル６以上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"裸の王様",h:"はだかのおうさま",e:"The Emperor's Holiday",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上の全ての装備カードの効果は無効になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイクロンレーザー",h:"さいくろんれーざー",e:"Cyclon Laser",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「超時空戦闘機ビック・バイパー」のみ装備可能。\n攻撃力は３００ポイントアップする。\nさらにこのカードが守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"死のメッセージ「E」",h:"しのめっせーじ「いー」",e:"Spirit Message \"I\"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは「ウィジャ盤」の効果でしかフィールドに出す事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"死のメッセージ「A」",h:"しのめっせーじ「えー」",e:"Spirit Message \"N\"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは「ウィジャ盤」の効果でしかフィールドに出す事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"死のメッセージ「T」",h:"しのめっせーじ「てぃー」",e:"Spirit Message \"A\"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは「ウィジャ盤」の効果でしかフィールドに出す事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"死のメッセージ「H」",h:"しのめっせーじ「えいち」",e:"Spirit Message \"L\"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは「ウィジャ盤」の効果でしかフィールドに出す事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"おとり人形",h:"おとりにんぎょう",e:"Bait Doll",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"魔法＆罠カードゾーンにセットされたカード１枚を選択して発動する。\n選択したカードをめくって確認し、そのカードが罠カードだった場合、強制発動させる。\n発動タイミングが正しくない場合、その効果を無効にし破壊する。\nそのカードが罠カード以外だった場合、元に戻す。\nこのカードは発動後、墓地へ送らずにデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フュージョン・ゲート",h:"ふゅーじょん・げーと",e:"Fusion Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、自分メインフェイズに発動できる。\n自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"疫病ウィルス ブラックダスト",h:"えきびょううぃるす　ぶらっくだすと",e:"Ekibyo Drakmord",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードの装備モンスターは攻撃をする事ができない。\nその装備モンスターのコントローラーの２回目のエンドフェイズ時、その装備モンスターを破壊する。\nまた、この効果で装備モンスターを破壊した場合、このカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の発掘",h:"きせきのはっくつ",e:"Miracle Dig",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：除外されている自分のモンスターが５体以上存在する場合、その内３体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魂の力",h:"どらごにっく・ふぉーす",e:"Dragonic Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族のみ装備可能。\n装備モンスターはドラゴン族となり、攻撃力・守備力がそれぞれ５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂消滅",h:"れいこんしょうめつ",e:"Spirit Elimination",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"墓地のモンスターをゲームから除外する場合、その代わりとして自分のフィールド上からモンスターを除外する。\nこの効果は発動ターンのみ有効。",li:"",lk:[],ta:[],al:[]},
  {n:"怨霊の湿地帯",h:"おんりょうのしっちたい",e:"Vengeful Bog Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"召喚・反転召喚・特殊召喚されたモンスターは、そのターンに攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの剣士",h:"あまぞねすのけんし",e:"Amazoness Swords Woman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードの戦闘で発生する自分への戦闘ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"処刑人－マキュラ",h:"しょけいにん－まきゅら",e:"Makyura the Destructor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンから墓地へ送られた場合に発動できる。\nこのターンに１度だけ、自分は罠カードを手札から発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの弩弓隊",h:"あまぞねすのどきゅうたい",e:"Amazoness Archers",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の攻撃宣言時に、自分フィールド上に「アマゾネス」という名のついたモンスターが存在する場合のみ発動する事ができる。\n相手フィールド上の全てのモンスターは表側攻撃表示になり（リバース効果は発動しない）、攻撃力は５００ポイントダウンする。\n相手は全てのモンスターで攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"命の綱",h:"いのちのつな",e:"Rope of Life",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分モンスターが戦闘によって墓地に送られた時に、手札を全て捨てて発動する。\nそのモンスターの攻撃力を８００ポイントアップさせて、フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"飛行エレファント",h:"ひこうえれふぁんと",e:"Flying Elephant",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1850,de:1300,tx:"①：このカードは相手ターンに１度だけ、相手の効果では破壊されない。\n②：このカードの①の効果を適用した相手ターンのエンドフェイズに発動する。\n次の自分ターン中、以下を適用する。\n●このカードが直接攻撃で相手に戦闘ダメージを与えた時、自分はデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法効果の矢",h:"まほうこうかのや",e:"Spell Shattering Arrow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの表側表示の魔法カードを全て破壊し、破壊した数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの鎖使い",h:"あまぞねすのくさりつかい",e:"Amazoness Chain Master",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：このカードが戦闘で破壊され墓地へ送られた時、１５００LPを払って発動できる。\n相手の手札を確認し、その中からモンスター１体を選んで自分の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"万力魔神バイサー・デス",h:"まんりきまじんばいさー・です",e:"Viser Des",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"①：このカードの召喚に成功した場合、相手フィールドのモンスター１体を対象として発動する。\n発動後、３回目の自分スタンバイフェイズに対象のモンスターを破壊する。\n②：このカードの①の効果の対象のモンスターがフィールドに存在している限り、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの格闘戦士",h:"あまぞねすのかくとうせんし",e:"Amazoness Fighter",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが戦闘を行う事によって受けるコントローラーの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの呪詛師",h:"あまぞねすのじゅそし",e:"Amazoness Spellcaster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ターン終了時まで、自分フィールド上の「アマゾネス」という名のついた表側表示モンスター１体と、相手フィールド上表側表示モンスター１体の元々の攻撃力を入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"遺言の札",h:"ゆいごんのふだ",e:"Card of Last Will",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターの攻撃力が０になった場合に発動できる。\n自分は手札が５枚になるようにデッキからドローする。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"拷問車輪",h:"ごうもんしゃりん",e:"Nightmare Wheel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールドのモンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、対象のモンスターは攻撃できず、表示形式の変更もできない。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。\n②：自分スタンバイフェイズに発動する。\n相手に５００ダメージを与える。\nこの効果は対象のモンスターがモンスターゾーンに存在する場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"救出劇",h:"きゅうしゅつげき",e:"Dramatic Rescue",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の「アマゾネス」という名のついたモンスターを対象にしたカードが発動した時に発動する事ができる。\n対象になったモンスターカードを手札に戻し、別のモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バイサー・ショック",h:"ばいさー・しょっく",e:"Byser Shock",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、フィールド上の全てのセットされたカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界の魔王 ハ・デス",h:"めいかいのまおう　は・です",e:"Dark Ruler Ha Des",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2450,de:1600,tx:"このカードは墓地からの特殊召喚はできない。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上に存在する悪魔族モンスターが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔人 ダーク・バルター",h:"まじん　だーく・ばるたー",e:"Dark Balter the Terrible",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1200,tx:"「憑依するブラッド・ソウル」＋「辺境の大賢者」\nこのカードの融合召喚は上記のカードでしか行えない。\n通常魔法カードが発動した時、１０００ライフポイントを払う事でその効果を無効にする。\nまた、このカードが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"レッサー・デーモン",h:"れっさー・でーもん",e:"Lesser Fiend",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、このカードが戦闘によって破壊したモンスターは墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依するブラッド・ソウル",h:"ひょういするぶらっど・そうる",e:"Possessed Dark Soul",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードをリリースして発動する。\n相手フィールド上に表側表示で存在する全てのレベル３以下のモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ハ・デスの使い魔",h:"は・ですのつかいま",e:"Winged Minion",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:700,de:700,tx:"このカードを生け贄に捧げる。\nフィールド上に表側表示で存在する悪魔族モンスター１体を選択する。\nそのモンスターは表側表示でフィールド上に存在する限り、攻撃力と守備力が７００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"スカル・ナイト",h:"すかる・ないと",e:"Skull Knight #2",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードを生け贄にして悪魔族モンスターを生け贄召喚した場合、デッキから「スカル・ナイト」１体を特殊召喚する。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーゴイルの道化師",h:"がーごいるのどうけし",e:"Ryu-Kishin Clown",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"このカードが召喚・反転召喚・特殊召喚された時、対象の表側表示モンスター１体の表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"ツインヘッド・ケルベロス",h:"ついんへっど・けるべろす",e:"Twin-Headed Wolf",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上にこのカード以外の悪魔族モンスターが表側表示で存在する場合、このカードが戦闘で破壊したリバース効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・サイクロプス",h:"れっど・さいくろぷす",e:"Opticlops",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"「冥界の魔王 ハ・デス」に仕える一つ目の巨人。\nツノの攻撃で敵を粉砕する。",li:"",lk:[],ta:[],al:[]},
  {n:"冥王の咆哮",h:"めいおうのほうこう",e:"Bark of Dark Ruler",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する悪魔族モンスターが戦闘を行う場合、そのダメージステップ時に１００の倍数のライフポイントを払って発動する。\nこのターンのエンドフェイズ時まで、戦闘を行う相手モンスター１体の攻撃力・守備力は払った数値分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"死の演算盤",h:"です・かりきゅれーたー",e:"Fatal Abacus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に存在するモンスターが墓地へ送られる度に、そのモンスターの持ち主は１枚につき５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"生命吸収装置",h:"せいめいきゅうしゅうそうち",e:"Life Absorbing Machine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎に、直前の自分のターンに払ったライフポイントの半分が回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界流傀儡術",h:"めいかいりゅうくぐつじゅつ",e:"The Puppet Magic of Dark Ruler",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する悪魔族モンスター１体を選択して発動する。\nレベルの合計が選択したモンスターのレベルと同じになるように、自分フィールド上に存在するモンスターをゲームから除外する。\nその後、選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魂粉砕",h:"そうる・くらっしゅ",e:"Soul Demolition",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、５００ライフポイントを払う事で、お互いに相手の墓地に存在するモンスター１体を選択してゲームから除外する。\nこの効果は自分フィールド上に悪魔族モンスターが表側表示で存在する場合のみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブルトラップ",h:"だぶるとらっぷ",e:"Double Snare",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する、「罠の効果を無効にする効果」を持つカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"無敗将軍 フリード",h:"じぇねらる　ふりーど",e:"Freed the Matchless General",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードがフィールド上に表側表示で存在する限り、このカードを対象にする魔法カードの効果を無効にし破壊する。\nこのカードがフィールド上に表側表示で存在する限り、自分のドローフェイズ時に通常のドローを行う代わりに、自分のデッキからレベル４以下の戦士族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"投石部隊",h:"とうせきぶたい",e:"Throwstone Unit",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:2000,tx:"自分フィールド上の戦士族モンスター１体を生け贄に捧げる。\nこのカードの攻撃力以下の守備力を持つ表側表示のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"切り込み隊長",h:"きりこみたいちょう",e:"Marauding Captain",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下のモンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手は他の戦士族モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・ウォリアー",h:"どらごん・うぉりあー",e:"Ryu Senshi",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1200,tx:"「戦士ダイ・グレファー」＋「スピリット・ドラゴン」\nこのカードの融合召喚は上記のカードでしか行えない。\nこのカードがフィールド上に表側表示で存在する限り、通常罠カードが発動した時、１０００ライフポイントを払う事でその効果を無効にする。\nまた、このカードがフィールド上に表側表示で存在する限り、このカードを対象にする魔法カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦士ダイ・グレファー",h:"せんしだい・ぐれふぁー",e:"Warrior Dai Grepher",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1600,tx:"ドラゴン族を操る才能を秘めた戦士。\n過去は謎に包まれている。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる守り手",h:"せいなるまもりて",e:"Mysterious Guard",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1200,tx:"①：このカードがリバースした場合、以下の効果から１つを選択して発動する。\n\n●フィールドの表側表示モンスター１体を対象として発動する。\nその表側表示モンスターを持ち主のデッキの一番上に戻す。\n\n●自分フィールドに戦士族モンスターが存在する場合、フィールドの表側表示モンスター２体を対象として発動する。\nその表側表示モンスター１体を持ち主のデッキの一番上に戻す。\nその後、自分フィールドに戦士族モンスターが存在する場合、もう１体の表側表示モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"辺境の大賢者",h:"へんきょうのだいけんじゃ",e:"Frontier Wiseman",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する戦士族モンスターを対象にする魔法カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ならず者傭兵部隊",h:"ならずものようへいぶたい",e:"Exiled Force",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードをリリースし、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"７つの武器を持つハンター",h:"ななつのぶきをもつはんたー",e:"The Hunter with 7 Weapons",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードが召喚に成功した時、種族を１つ宣言して発動する。\nこのカードが、宣言した種族のモンスターと戦闘を行う場合、このカードの攻撃力はダメージ計算時のみ１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモン・テイマー",h:"でーもん・ていまー",e:"Shadow Tamer",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:700,tx:"リバース：相手フィールド上に表側表示で存在する悪魔族モンスター１体のコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・ライダー",h:"どらごん・らいだー",e:"Dragon Manipulator",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:700,de:800,tx:"リバース：相手フィールド上に表側表示で存在するドラゴン族モンスター１体のコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"連合軍",h:"れんごうぐん",e:"The A. Forces",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の戦士族モンスターの攻撃力は、自分フィールド上の戦士族・魔法使い族モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"増援",h:"ぞうえん",e:"Reinforcement of the Army",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからレベル４以下の戦士族モンスター１体を手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"光の護封陣",h:"ひかりのごふうじん",e:"Array of Revealing Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"種族を１つ宣言して発動する。\n宣言した種族のモンスターは召喚・反転召喚・特殊召喚されたターンに攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"戦士の生還",h:"せんしのせいかん",e:"The Warrior Returning Alive",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の戦士族モンスター１体を対象として発動できる。\nその戦士族モンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"迎撃準備",h:"げいげきじゅんび",e:"Ready for Intercepting",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する戦士族か魔法使い族モンスター１体を裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"陽動作戦",h:"ようどうさくせん",e:"A Feint Plan",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン、お互いは裏側表示モンスターを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"タイラント・ドラゴン",h:"たいらんと・どらごん",e:"Tyrant Dragon",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2900,de:2500,tx:"相手フィールド上にモンスターが存在する場合、このカードはバトルフェイズ中にもう１度だけ攻撃する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、このカードを対象にする罠カードの効果を無効にし破壊する。\nこのカードを他のカードの効果によって墓地から特殊召喚する場合、そのプレイヤーは自分フィールド上に存在するドラゴン族モンスター１体をリリースしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"スピア・ドラゴン",h:"すぴあ・どらごん",e:"Spear Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリット・ドラゴン",h:"すぴりっと・どらごん",e:"Spirit Ryu",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘を行う自分のバトルステップ時、手札からドラゴン族モンスター１体を墓地へ捨てて発動する事ができる。\nこのカードの攻撃力・守備力は、バトルフェイズ終了時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"洞窟に潜む竜",h:"どうくつにひそむりゅう",e:"The Dragon Dwelling in the Cave",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1300,de:2000,tx:"洞窟に潜む巨大なドラゴン。\n普段はおとなしいが、怒ると恐ろしい。\n財宝を守っていると伝えられている。",li:"",lk:[],ta:[],al:[]},
  {n:"リザード兵",h:"りざーどへい",e:"Lizard Soldier",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"ドラゴンから派生した獣人種。\nドラゴン族の中では小型で敏捷性に優れ、戦略性に富んでいる。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・デーモン・ドラゴン",h:"です・でーもん・どらごん",e:"Fiend Skull Dragon",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1200,tx:"「グランド・ドラゴン」＋「レッサー・デーモン」\nこのカードの融合召喚は上記のカードでしか行えない。\nこのカードがフィールド上に表側表示で存在する限り、リバース効果モンスターの効果は無効化される。\nまた、このカードがフィールド上に表側表示で存在する限り、このカードを対象にする罠カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"グランド・ドラゴン",h:"ぐらんど・どらごん",e:"Cave Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:100,tx:"自分フィールド上にモンスターが存在する場合、このカードを召喚する事はできない。\nこのカードは自分フィールド上にこのカード以外のドラゴン族モンスターが表側表示で存在する場合のみ攻撃宣言する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイ・ウイング",h:"ぐれい・ういんぐ",e:"Gray Wing",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1300,de:700,tx:"メインフェイズ１で手札からカードを１枚捨てる。\nこのカードはそのターンのバトルフェイズ中に２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"軍隊竜",h:"あーみー・どらごん",e:"Troop Dragon",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:700,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「軍隊竜」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンの宝珠",h:"どらごんのほうじゅ",e:"The Dragon's Bead",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"手札を１枚捨てる。\n表側表示のドラゴン族モンスター１体を対象とする罠カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"巨竜の羽ばたき",h:"きょりゅうのはばたき",e:"A Wingbeat of Giant Dragon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル５以上のドラゴン族モンスター１体を選んで持ち主の手札に戻し、フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"火竜の火炎弾",h:"かりゅうのかえんだん",e:"Dragon's Gunfire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にドラゴン族モンスターが表側表示で存在する場合、次の効果から１つを選択して発動する。\n\n●相手ライフに８００ポイントダメージを与える。\n\n●フィールド上に表側表示で存在する守備力８００以下のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スタンピング・クラッシュ",h:"すたんぴんぐ・くらっしゅ",e:"Stamping Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にドラゴン族モンスターが表側表示で存在する場合のみ発動する事ができる。\nフィールド上に存在する魔法・罠カード１枚を選択して破壊し、そのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"超再生能力",h:"ちょうさいせいのうりょく",e:"Super Rejuvenation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このカードを発動したターンのエンドフェイズに、このターン自分の手札から捨てられたドラゴン族モンスター、及びこのターン自分の手札・フィールドからリリースされたドラゴン族モンスターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の逆鱗",h:"りゅうのげきりん",e:"Dragon's Rage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドのドラゴン族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"バーストブレス",h:"ばーすとぶれす",e:"Burst Breath",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのドラゴン族モンスター１体をリリースして発動できる。\nリリースしたモンスターの攻撃力以下の守備力を持つフィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エメラルド・ドラゴン",h:"えめらるど・どらごん",e:"Luster Dragon #2",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2400,de:1400,tx:"エメラルドを喰らうドラゴン。\nその美しい姿にひかれて命を落とす者は後を絶たない。",li:"",lk:[],ta:[],al:[]},
  {n:"非常食",h:"ひじょうしょく",e:"Emergency Provisions",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このカード以外の自分フィールドの魔法・罠カードを任意の数だけ墓地へ送って発動できる。\n自分はこのカードを発動するために墓地へ送ったカードの数×１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ケルドウ",h:"けるどう",e:"Keldo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが戦闘によって破壊され墓地に送られた時、相手の墓地からカードを２枚選択して相手のデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"墓穴の道連れ",h:"はかあなのみちづれ",e:"Dragged Down into the Grave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、それぞれ相手の手札を確認し、その中からカードを１枚選んで捨てる。\nその後、お互いのプレイヤーは、それぞれデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザー・グライダー",h:"かいざー・ぐらいだー",e:"Kaiser Glider",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2200,tx:"①：このカードは同じ攻撃力のモンスターとの戦闘では破壊されない。\n②：このカードが破壊され墓地へ送られた場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法再生",h:"まほうさいせい",e:"Spell Reproduction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の魔法カードを２枚墓地に送る。\n自分の墓地から魔法カードを１枚選択し、手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"収縮",h:"しゅうしゅく",e:"Shrink",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ムドラ",h:"むどら",e:"Mudora",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"自分の墓地に存在する天使族モンスター１体につき、このカードの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダグラの剣",h:"だぐらのつるぎ",e:"Cestus of Dagla",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"天使族のみ装備可能。\n装備モンスター１体の攻撃力は５００ポイントアップする。\n装備モンスターが戦闘によって相手プレイヤーにダメージを与えた時、その数値分、自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法除去細菌兵器",h:"まほうじょきょさいきんへいき",e:"Virus Cannon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：トークン以外の自分フィールドのモンスターを任意の数だけリリースして発動できる。\nリリースしたモンスターの数だけ、相手はデッキから魔法カードを選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"デスグレムリン",h:"ですぐれむりん",e:"Des Feral Imp",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカードがリバースした場合、自分の墓地のカード１枚を対象として発動する。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"現世と冥界の逆転",h:"げんせとめいかいのぎゃくてん",e:"Exchange of the Spirit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードはデュエル中に１枚しか発動できない。\n①：お互いの墓地のカードがそれぞれ１５枚以上の場合に１０００LPを払って発動できる。\nお互いのプレイヤーは、それぞれ自分のデッキと墓地のカードを全て入れ替え、その後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"ケルベク",h:"けるべく",e:"Kelbek",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"このカードを攻撃したモンスターは持ち主の手札に戻る。\nダメージ計算は適用する。",li:"",lk:[],ta:[],al:[]},
  {n:"無効",h:"むこう",e:"Muko",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"カードをドローする効果を発動した時に発動する事ができる。\nそのカードの効果によってドローするカードをお互いに確認し、全て墓地に捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾルガ",h:"ぞるが",e:"Zolga",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードがアドバンス召喚のためにリリースされた場合に発動する。\n自分は２０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"生贄の抱く爆弾",h:"いけにえのだくばくだん",e:"Blast Held by a Tribute",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：アドバンス召喚された相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの表側攻撃表示モンスターを全て破壊し、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アギド",h:"あぎど",e:"Agido",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが戦闘によって破壊され墓地に送られた時、サイコロを１回振る。\n自分の墓地から、サイコロの出た目と同じレベルの天使族モンスター１体を特殊召喚する事ができる。\n（６の目が出た場合は、レベル６以上のモンスターを含む）",li:"",lk:[],ta:[],al:[]},
  {n:"黙する死者",h:"もくするししゃ",e:"Silent Doom",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイバーポッド",h:"ふぁいばーぽっど",e:"Fiber Jar",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"リバース：お互いの手札・フィールド上・墓地のカードを全て持ち主のデッキに加えてシャッフルする。\nその後、お互いにデッキからカードを５枚ドローする。",li:0,lk:[],ta:[],al:[]},
  {n:"オプション",h:"おぷしょん",e:"Gradius' Option",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分フィールド上に「超時空戦闘機ビック・バイパー」が表側表示で存在する場合に特殊召喚する事ができる。\nこのカードを特殊召喚する場合、自分フィールド上に表側表示で存在する「超時空戦闘機ビック・バイパー」１体を選択しなければならない。\nこのカードの攻撃力・守備力は常に選択したモンスターと同じになる。\n選択したモンスターがフィールド上に表側表示で存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"磨破羅魏",h:"まはらぎ",e:"Maharaghi",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["スピリット","効果"],ma:"",tp:"",at:1200,de:1700,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nまた、このカードが召喚・リバースした時に発動する。\n次の自分のドローフェイズのドロー前に自分のデッキの一番上のカードを確認してデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"因幡之白兎",h:"いなばのしろうさぎ",e:"Inaba White Rabbit",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["スピリット","効果"],ma:"",tp:"",at:700,de:500,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\n相手フィールド上にモンスターが存在する場合、このカードの攻撃は相手プレイヤーへの直接攻撃としなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"八汰烏",h:"やたがらす",e:"Yata-Garasu",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"悪魔族",mo:["スピリット","効果"],ma:"",tp:"",at:200,de:100,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに戦闘ダメージを与えた場合、次の相手ターンのドローフェイズをスキップする。",li:0,lk:[],ta:[],al:[]},
  {n:"雷帝神",h:"すさのお",e:"Susa Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["スピリット","効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"八俣大蛇",h:"やまたのどらごん",e:"Yamata Dragon",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["スピリット","効果"],ma:"",tp:"",at:2600,de:3100,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに戦闘ダメージを与えた時、自分の手札が５枚になるまでデッキからカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"偉大天狗",h:"ぐれーと・てんぐ",e:"Great Long Nose",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["スピリット","効果"],ma:"",tp:"",at:1900,de:1700,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに戦闘ダメージを与えた場合、次の相手ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜宮之姫",h:"おとひめ",e:"Otohime",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:0,de:100,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、相手フィールド上に表側表示で存在するモンスター１体を選択し、表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"火之迦具土",h:"ひのかぐつち",e:"Hino-Kagu-Tsuchi",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["スピリット","効果"],ma:"",tp:"",at:2800,de:2900,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nまた、このカードが相手ライフに戦闘ダメージを与えた時に発動する。\n次のターンのドローフェイズのドロー前に相手は手札を全て捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"阿修羅",h:"あすら",e:"Asura Priest",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードは相手フィールド上に存在する全てのモンスターに１回ずつ攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"不死之炎鳥",h:"ふしのとり",e:"Fushi No Tori",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:1200,de:0,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに戦闘ダメージを与えた時、与えた戦闘ダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"レアメタル・ヴァルキリー",h:"れあめたる・う゛ぁるきりー",e:"Super Robolady",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1200,de:500,tx:"「レアメタル・レディ」＋「レアメタル・ソルジャー」\n相手プレイヤーへの直接攻撃のダメージステップ時、攻撃力１０００アップ。\nフィールド上のこのカードと融合デッキの「レアメタル・ナイト」を交換できる。\n（このカードが特殊召喚されたターンは不可）",li:"",lk:[],ta:[],al:[]},
  {n:"レアメタル・ナイト",h:"れあめたる・ないと",e:"Super Roboyarou",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1200,de:500,tx:"「レアメタル・ソルジャー」＋「レアメタル・レディ」\nモンスターとの戦闘のダメージステップ時、攻撃力１０００アップ。\nフィールド上のこのカードと融合デッキの「レアメタル・ヴァルキリー」を交換できる。\n（このカードが特殊召喚されたターンは不可）",li:"",lk:[],ta:[],al:[]},
  {n:"封神鏡",h:"ほうしんきょう",e:"Fengsheng Mirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の手札を見る。\nスピリットモンスターが手札に存在する場合、スピリットモンスター１体を選択して墓地に捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"明鏡止水の心",h:"めいきょうしすいのこころ",e:"Heart of Clear Water",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは、戦闘及び装備モンスターを対象とするカードの効果では破壊されない。\n装備モンスターの攻撃力が１３００以上の場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の都 アトランティス",h:"でんせつのみやこ　あとらんてぃす",e:"A Legendary Ocean",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「海」として扱う。\n①：フィールドの水属性モンスターの攻撃力・守備力は２００アップする。\n②：このカードがフィールドゾーンに存在する限り、お互いの手札・フィールドの水属性モンスターのレベルは１つ下がる。",li:"",lk:[],ta:[],al:[]},
  {n:"融合武器ムラサメブレード",h:"ゆうごうぶきむらさめぶれーど",e:"Fusion Sword Murasame Blade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は８００アップする。\n②：モンスターに装備されているこのカードは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"盗人の煙玉",h:"ぬすっとのけむりだま",e:"Smoke Grenade of the Thief",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"モンスターに装備されているこのカードがカードの効果によって破壊された時、相手の手札を確認して１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"霊子エネルギー固定装置",h:"れいしえねるぎーこていそうち",e:"Spiritual Energy Settle Machine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、スピリットモンスターはフィールド上に残り続ける。\n自分のエンドフェイズ時に手札を１枚捨てる。\n捨てなければ、このカードを破壊する。\nまた、このカードがフィールド上から離れた時、フィールド上に存在する表側表示のスピリットモンスターは全て持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"セカンド・チャンス",h:"せかんど・ちゃんす",e:"Second Coin Toss",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか適用できない。\n①：自分がコイントスを行う効果を適用する際に、コイントスを最初からやり直す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"天変地異",h:"てんぺんちい",e:"Convulsion of Nature",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーはデッキを裏返しにしてデュエルを進行する。",li:"",lk:[],ta:[],al:[]},
  {n:"盗賊の極意",h:"とうぞくのごくい",e:"The Secret of the Bandit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１でのみ発動する事ができる。\nフィールド上に表側表示で存在するモンスターを１体選択する。\nこのターン、選択したモンスターが相手プレイヤーに戦闘ダメージを与える度に、相手はランダムにカードを１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェノサイド・ウォー",h:"じぇのさいど・うぉー",e:"After the Struggle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１でのみ発動する事ができる。\nこのターン戦闘によってダメージ計算をした自分・相手モンスターは、エンドステップ時に全て破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ガードナー",h:"まじっく・がーどなー",e:"Magic Reflector",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する魔法カード１枚を選択して発動する。\n選択したカードにカウンターを１つ置く。\n選択したカードが破壊される場合、代わりにそのカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"鎖付き爆弾",h:"くさりつきだいなまいと",e:"Blast with Chain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、その自分のモンスターに装備する。\n②：装備カード扱いのこのカードが効果で破壊された場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ロスト",h:"ろすと",e:"Disappear",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地のカード１枚をゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"バブル・クラッシュ",h:"ばぶる・くらっしゅ",e:"Bubble Crash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札・フィールド上のカードの合計が６枚以上あるプレイヤーは、その合計が５枚になるようにカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮の弾圧",h:"おうきゅうのだんあつ",e:"Royal Oppression",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"８００ライフポイントを払う事で、モンスターの特殊召喚及び、モンスターの特殊召喚を含む効果を無効にし破壊する。\nこの効果は相手プレイヤーも使用する事ができる。",li:0,lk:[],ta:[],al:[]},
  {n:"奈落の落とし穴",h:"ならくのおとしあな",e:"Bottomless Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が攻撃力１５００以上のモンスターを召喚・反転召喚・特殊召喚した時に発動できる。\nその攻撃力１５００以上のモンスターを破壊し除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"不吉な占い",h:"ふきつなうらない",e:"Ominous Fortunetelling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ時、相手の手札からカードを１枚ランダムに選択する。\nそのカードの種類（モンスター・魔法・罠）を当てた場合、相手プレイヤーに７００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリットの誘い",h:"すぴりっとのいざない",e:"Spirit's Invitation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。\nフィールド上に表側表示で存在するスピリットモンスターが自分の手札に戻った時、相手フィールド上に存在するモンスター１体を相手が選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"体力増強剤スーパーZ",h:"たいりょくぞうきょうざいすーぱーＺ",e:"Nutrient Z",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分が２０００以上の戦闘ダメージを受ける場合、そのダメージ計算時に発動できる。\n自分は４０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"はたき落とし",h:"はたきおとし",e:"Drop Off",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のドローフェイズ時に、相手が通常のドローをした時に発動する事ができる。\n相手はドローしたカード１枚をそのまま墓地へ捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"デビル・コメディアン",h:"でびる・こめでぃあん",e:"Fiend Comedian",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：コイントスを１回行い、コインの裏表を当てる。\n当たった場合、相手の墓地のカードを全て除外する。\nハズレの場合、相手の墓地のカードの枚数分、自分のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ラストバトル！",h:"らすとばとる！",e:"Last Turn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが１０００以下の場合、相手ターンにのみ発動する事ができる。\n発動後、自分フィールド上に存在するモンスター１体を選択し、そのモンスター以外のお互いの手札・フィールド上のカードを全て墓地へ送る。\nその後、相手はデッキからモンスター１体を表側攻撃表示で特殊召喚し、自分が選択したモンスターと戦闘を行う。\nこの戦闘によって発生するお互いのプレイヤーへの戦闘ダメージは０になる。\nこのターンのエンドフェイズ時、どちらかのプレイヤーのみがモンスターをコントロールしていた場合、そのコントローラーはデュエルに勝利する。\nそれ以外の場合は引き分けになる。",li:0,lk:[],ta:[],al:[]},
  {n:"デス・ヴォルストガルフ",h:"です・う゛ぉるすとがるふ",e:"Des Volstgalph",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1700,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に５００ダメージを与える。\n②：このカードがモンスターゾーンに存在する限り、自分または相手が通常・速攻魔法カードを発動する度に、ターン終了時までこのカードの攻撃力は２００ずつアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザー・シーホース",h:"かいざー・しーほーす",e:"Kaiser Sea Horse",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1650,tx:"①：光属性モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・ロード",h:"う゛ぁんぱいあ・ろーど",e:"Vampire Lord",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが相手ライフに戦闘ダメージを与えた時、カードの種類（モンスター・魔法・罠）を宣言する。\n相手は宣言された種類のカード１枚をデッキから墓地へ送る。\nまた、このカードが相手のカードの効果によって破壊され墓地へ送られた場合、次の自分のスタンバイフェイズ時にこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴラ・タートル",h:"ごら・たーとる",e:"Gora Turtle",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"このカードがフィールド上に表側表示で存在する限り、攻撃力１９００以上のモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"一刀両断侍",h:"いっとうりょうだんざむらい",e:"Sasuke Samurai",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ポイズンマミー",h:"ぽいずんまみー",e:"Poison Mummy",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1800,tx:"リバース：相手プレイヤーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"砂塵の悪霊",h:"さじんのあくりょう",e:"Dark Dust Spirit",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"アンデット族",mo:["スピリット","効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、このカード以外のフィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王族親衛隊",h:"おうぞくしんえいたい",e:"Royal Keeper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、このカードの攻撃力・守備力はエンドフェイズ時まで３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"さまようミイラ",h:"さまようみいら",e:"Wandering Mummy",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこの効果を使用した時、自分フィールド上に裏側守備表示で存在する全てのモンスターをシャッフルし、再び裏側守備表示で並べ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"大神官デ・ザード",h:"だいしんかんで・ざーど",e:"Great Dezard",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1900,de:2300,tx:"このカードが戦闘によってモンスターを破壊する度に以下の効果を得る。\n\n●１回目：フィールド上に表側表示で存在するこのカードを対象とする魔法・罠カードが発動した時、その発動を無効にし破壊する。\n\n●２回目：このカードをリリースする事で、自分の手札・デッキから「不死王リッチー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スカラベの大群",h:"すからべのたいぐん",e:"Swarm of Scarabs",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イナゴの軍勢",h:"いなごのぐんぜい",e:"Swarm of Locusts",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上に存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャイアントマミー",h:"じゃいあんとまみー",e:"Giant Axe Mummy",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:2000,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\n裏側守備表示のこのカードを攻撃したモンスターの攻撃力がこのカードの守備力より低い場合、攻撃モンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"八つ手サソリ",h:"やつでさそり",e:"8-Claws Scorpion",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\n裏側守備表示の相手モンスターを攻撃対象にした場合、このカードの攻撃力はダメージ計算時のみ２４００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"守護者スフィンクス",h:"がーでぃあんすふぃんくす",e:"Guardian Sphinx",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:2400,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上に存在するモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ピラミッド・タートル",h:"ぴらみっど・たーとる",e:"Pyramid Turtle",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから守備力２０００以下のアンデット族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイス・ポット",h:"だいす・ぽっと",e:"Dice Jar",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:300,tx:"リバース：お互いにサイコロを１回ずつ振る。\n相手より小さい目が出たプレイヤーは、相手の出た目によって以下のダメージを受ける。\n相手の出た目が２～５だった場合、相手の出た目×５００ポイントダメージを受ける。\n相手の出た目が６だった場合、６０００ポイントダメージを受ける。\nお互いの出た目が同じだった場合はサイコロを振り直す。",li:"",lk:[],ta:[],al:[]},
  {n:"黒蠍盗掘団",h:"くろさそりとうくつだん",e:"Dark Scorpion Burglars",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手はデッキから魔法カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"首領・ザルーグ",h:"どん・ざるーぐ",e:"Don Zaloog",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"このカードが相手ライフに戦闘ダメージを与えた時、以下の効果から１つを選択して発動する事ができる。\n\n●相手の手札をランダムに１枚捨てる。\n\n●相手のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・ラクーダ",h:"です・らくーだ",e:"Des Lacooda",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:600,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードが反転召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"不死王リッチー",h:"のすふぇらとぅりっちー",e:"Fushioh Richie",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2900,tx:"このカードは通常召喚できない。\n「大神官デ・ザード」の効果で特殊召喚する事ができる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、自分の墓地に存在するアンデット族モンスター１体を選択して特殊召喚する事ができる。\nまた、フィールド上に表側表示で存在するこのカードを対象とする魔法・罠カードが発動した時、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"半蛇人サクズィー",h:"はんじゃじんさくずぃー",e:"Cobraman Sakuzy",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、相手フィールド上にセットされている魔法・罠カードを全てめくり、確認した後元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"生者の書－禁断の呪術－",h:"せいじゃのしょ－きんだんのじゅじゅつ－",e:"Book of Life",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のアンデット族モンスター１体と相手の墓地のモンスター１体を対象として発動できる。\nその自分のアンデット族モンスターを特殊召喚する。\nその相手のモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽の書",h:"たいようのしょ",e:"Book of Taiyou",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に裏側表示で存在するモンスター１体を選択し、表側攻撃表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"月の書",h:"つきのしょ",e:"Book of Moon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"悪夢の蜃気楼",h:"あくむのしんきろう",e:"Mirage of Nightmare",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手のスタンバイフェイズ時に１度、自分の手札が４枚になるまでデッキからカードをドローする。\nこの効果でドローした場合、次の自分のスタンバイフェイズ時に１度、ドローした枚数分だけ自分の手札をランダムに捨てる。",li:0,lk:[],ta:[],al:[]},
  {n:"財宝への隠し通路",h:"ざいほうへのかくしつうろ",e:"Secret Pass to the Treasures",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"表側表示で自分フィールド上に存在する攻撃力１０００以下のモンスター１体を選択する。\nこのターン、選択したモンスターは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミイラの呼び声",h:"みいらのよびごえ",e:"Call of the Mummy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札からアンデット族モンスター１体を特殊召喚する。\nこの効果は自分フィールドにモンスターが存在しない場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"おくびょうかぜ",h:"おくびょうかぜ",e:"Timidity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"次の相手ターンのエンドフェイズまで、フィールド上にセットされた魔法・罠カードを破壊する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ピラミッドパワー",h:"ぴらみっどぱわー",e:"Pyramid Energy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"次の効果から１つを選択して発動する事ができる。\n\n●自分フィールド上に表側表示で存在する全てのモンスターの攻撃力は、エンドフェイズ時まで２００ポイントアップする。\n\n●自分フィールド上に表側表示で存在する全てのモンスターの守備力は、エンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ツタン仮面",h:"つたんかめん",e:"Tutan Mask",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上に表側表示で存在するアンデット族モンスター１体を対象にする魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"旅人の試練",h:"たびびとのしれん",e:"Ordeal of a Traveler",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n自分の手札から相手はランダムにカードを１枚選択し、そのカードの種類（モンスター・魔法・罠）を当てる。\nハズレの場合、その攻撃モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"底なし流砂",h:"そこなしりゅうさ",e:"Bottomless Shifting Sand",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のエンドフェイズ時に１度だけ、フィールド上に表側表示で存在する攻撃力が一番高いモンスターを破壊する。\n 自分のスタンバイフェイズ時に自分の手札が４枚以下の場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王家の呪い",h:"おうけののろい",e:"Curse of Royal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドの魔法・罠カード１枚のみを対象として、そのカードを破壊する魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"つり天井",h:"つりてんじょう",e:"Needle Ceiling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上にモンスターが４体以上存在する場合に発動する事ができる。\nフィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金の邪神像",h:"おうごんのじゃしんぞう",e:"Statue of the Wicked",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが破壊され墓地へ送られた時、自分フィールド上に「邪神トークン」（悪魔族・闇・星４・攻／守１０００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"呪われた棺",h:"のろわれたひつぎ",e:"Dark Coffin",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが破壊され墓地へ送られた時、相手は以下の効果から１つを選択して適用する。\n\n●自分の手札をランダムに１枚捨てる。\n\n●自分フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ニードル・ウォール",h:"にーどる・うぉーる",e:"Needle Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ時に１度だけ、サイコロを１回振る。\n相手のモンスターカードゾーンを、このカードのコントローラーから見て右から１～５とし、出た目のモンスターカードゾーンに存在するモンスターを破壊する。\n６の目が出た場合はサイコロを振り直す。",li:"",lk:[],ta:[],al:[]},
  {n:"ダスト・シュート",h:"だすと・しゅーと",e:"Trap Dustshoot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の手札が４枚以上の場合に発動する事ができる。\n相手の手札を確認してモンスターカード１枚を選択し、そのカードを持ち主のデッキに戻す。",li:0,lk:[],ta:[],al:[]},
  {n:"運命の火時計",h:"うんめいのひどけい",e:"Pyro Clock of Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"カード１枚のターンカウントを１ターン進める。",li:"",lk:[],ta:[],al:[]},
  {n:"無謀な欲張り",h:"むぼうなよくばり",e:"Reckless Greed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分はデッキから２枚ドローし、その後の自分ドローフェイズは２回スキップされる。",li:"",lk:[],ta:[],al:[]},
  {n:"王家の財宝",h:"おうけのざいほう",e:"Pharaoh's Treasure",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードは発動後、墓地へ送らずに表向きで自分のデッキに加えてシャッフルする。\nこの効果でデッキに加えたこのカードをドローした時、このカードを墓地へ送り、このカード以外の自分の墓地に存在するカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ブラック・マジシャン・ガール",h:"とぅーん・ぶらっく・まじしゃん・がーる",e:"Toon Dark Magician Girl",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:2000,de:1700,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在し、自分フィールドのモンスター１体をリリースした場合に特殊召喚できる。\n①：このカードの攻撃力は、お互いの墓地の「ブラック・マジシャン」「マジシャン・オブ・ブラックカオス」の数×３００アップする。\n②：このカードは、相手フィールドにトゥーンモンスターが存在しない場合、直接攻撃できる。\n存在する場合、トゥーンモンスターしか攻撃対象に選択できない。\n③：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ギルフォード・ザ・ライトニング",h:"ぎるふぉーど・ざ・らいとにんぐ",e:"Gilford the Lightning",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:1400,tx:"このカードはモンスター３体をリリースして召喚する事もできる。\n①：モンスター３体をリリースしてこのカードのアドバンス召喚に成功した場合に発動する。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イグザリオン・ユニバース",h:"いぐざりおん・ゆにばーす",e:"Exarion Universe",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1900,tx:"①：このカードが守備表示モンスターに攻撃するバトルステップに発動できる。\nターン終了時まで、このカードの攻撃力は４００ダウンし、このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"レジェンド・デビル",h:"れじぇんど・でびる",e:"Legendary Fiend",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"自分のターンのスタンバイフェイズ毎に、このカードの攻撃力は７００ポイントずつアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ディフェンス",h:"とぅーん・でぃふぇんす",e:"Toon Defense",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手モンスターが自分のレベル４以下のトゥーンモンスターに攻撃宣言した時にこの効果を発動できる。\nその相手モンスターの攻撃を自分への直接攻撃にする。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーンのもくじ",h:"とぅーんのもくじ",e:"Toon Table of Contents",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「トゥーン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・仮面魔道士",h:"とぅーん・かめんまどうし",e:"Toon Masked Sorcerer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["トゥーン","効果"],ma:"",tp:"",at:900,de:1400,tx:"このカードは召喚・反転召喚・特殊召喚したターンには攻撃する事ができない。\n自分フィールド上に「トゥーン・ワールド」が存在し、相手フィールド上にトゥーンモンスターが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\nフィールド上の「トゥーン・ワールド」が破壊された時、このカードを破壊する。\nこのカードが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ヂェミナイ・エルフ",h:"とぅーん・ぢぇみない・えるふ",e:"Toon Gemini Elf",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["トゥーン","効果"],ma:"",tp:"",at:1900,de:900,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：このカードが相手に戦闘ダメージを与えた時に発動できる。\n相手の手札をランダムに１枚選んで捨てる。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・キャノン・ソルジャー",h:"とぅーん・きゃのん・そるじゃー",e:"Toon Cannon Soldier",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["トゥーン","効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカードは召喚・反転召喚・特殊召喚したターンには攻撃する事ができない。\n自分フィールド上に「トゥーン・ワールド」が存在し、相手フィールド上にトゥーンモンスターが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\nフィールド上の「トゥーン・ワールド」が破壊された時、このカードを破壊する。\nまた、自分フィールド上に存在するモンスター１体をリリースする事で、相手ライフに５００ポイントダメージを与える。",li:0,lk:[],ta:[],al:[]},
  {n:"トゥーン・ゴブリン突撃部隊",h:"とぅーん・ごぶりんとつげきぶたい",e:"Toon Goblin Attack Force",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["トゥーン","効果"],ma:"",tp:"",at:2300,de:0,tx:"このカードは召喚・反転召喚・特殊召喚したターンには攻撃する事ができない。\n自分フィールド上に「トゥーン・ワールド」が存在し、相手フィールド上にトゥーンモンスターが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\nフィールド上の「トゥーン・ワールド」が破壊された時、このカードを破壊する。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分のターンのエンドフェイズ時まで表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"天よりの宝札",h:"てんよりのほうさつ",e:"Card of Sanctity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドのカードを全て除外して発動できる。\n自分は手札が２枚になるようにデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の指名者",h:"やみのしめいしゃ",e:"Dark Designator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"モンスターカード名を１つ宣言する。\n宣言したカードが相手のデッキにある場合、そのカード１枚を相手の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"死なばもろとも",h:"しなばもろとも",e:"Multiple Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：お互いの手札がそれぞれ３枚以上の場合に発動できる。\nお互いのプレイヤーは、それぞれ手札を全て好きな順番でデッキの下に戻し、自分はこの効果でお互いがデッキに戻したカードの数×３００LPを失う。\nその後、お互いのプレイヤーは、それぞれデッキから５枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"死霊操りしパペットマスター",h:"しりょうあやつりしぱぺっとますたー",e:"Puppet Master",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードがアドバンス召喚に成功した時、２０００LPを払い、自分の墓地の悪魔族モンスター２体を対象として発動できる。\nその悪魔族モンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲルニア",h:"げるにあ",e:"Gernia",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"①：フィールドの表側表示のこのカードが相手の効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ニュードリュア",h:"にゅーどりゅあ",e:"Newdoria",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ロードポイズン",h:"ろーどぽいずん",e:"Lord Poison",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードが戦闘によって破壊され墓地に送られた時、自分の墓地に存在する「ロードポイズン」以外の植物族モンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"痛魂の呪術",h:"つうこんのじゅじゅつ",e:"Spell of Pain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分にダメージを与える効果を相手が発動した時に発動できる。\nその効果で発生する自分への効果ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイドナイト",h:"ぶれいどないと",e:"Blade Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：自分の手札が１枚以下の場合、このカードの攻撃力は４００アップする。\n②：自分フィールドにこのカード以外のモンスターが存在しない場合、このカードが戦闘で破壊したリバースモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄詩人ヘルポエマー",h:"じごくしじんへるぽえまー",e:"Helpoemer",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカードは墓地からの特殊召喚はできない。\n①：戦闘で破壊されたこのカードが墓地に存在する場合、相手バトルフェイズ終了時に発動する。\nこのカードが墓地に存在する場合、相手の手札をランダムに１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"隠れ兵",h:"かくれへい",e:"Hidden Soldiers",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターを召喚・反転召喚した時に発動できる。\n手札からレベル４以下の闇属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギル・ガース",h:"ぎる・がーす",e:"Gil Garth",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1200,tx:"鋼鉄の鎧を身にまとった殺戮マシーン。\n巨大なカタナで容赦なく攻撃をする。",li:"",lk:[],ta:[],al:[]},
  {n:"棺桶売り",h:"かんおけうり",e:"Coffin Seller",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"モンスターカードが相手の墓地に送られる度に、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"邪神の大災害",h:"じゃしんのだいさいがい",e:"Malevolent Catastrophe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\nフィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"クイズ",h:"くいず",e:"Question",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"発動中、相手は墓地のカードを確認する事ができない。\n相手プレイヤーは「クイズ」発動プレイヤーの墓地の一番下にあるモンスター名を当てる。\n当てた場合、そのカードをゲームから除外する。\nハズレの場合、そのカードは持ち主のフィールド上に特殊召喚される。",li:"",lk:[],ta:[],al:[]},
  {n:"溶岩魔神ラヴァ・ゴーレム",h:"ようがんまじんらう゛ぁ・ごーれむ",e:"Lava Golem",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n相手フィールドのモンスター２体をリリースした場合に相手フィールドに特殊召喚できる。\nこのカードを特殊召喚するターン、自分は通常召喚できない。\n①：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスターレリーフ",h:"もんすたーれりーふ",e:"Relieve Monster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n自分フィールド上に存在するモンスター１体を手札に戻し、その後手札からレベル４モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"機械複製術",h:"きかいふくせいじゅつ",e:"Machine Duplication",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの攻撃力５００以下の機械族モンスター１体を対象として発動できる。\nデッキからその表側表示モンスターの同名モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークジェロイド",h:"だーくじぇろいど",e:"Dark Jeroid",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、フィールド上の表側表示モンスター１体を選択する。\nそのモンスターはフィールド上に表側表示で存在する限り、攻撃力が８００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"究極竜騎士",h:"ますたー・おぶ・どらごんないと",e:"Dragon Master Knight",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:5000,de:5000,tx:"「カオス・ソルジャー」＋「青眼の究極竜」 \nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、このカード以外の自分フィールドのドラゴン族モンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"F・G・D",h:"ふぁいぶ・ごっど・どらごん",e:"Five-Headed Dragon",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:5000,de:5000,tx:"ドラゴン族モンスター×５\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードは闇・地・水・炎・風属性モンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"クィーンズ・ナイト",h:"くぃーんず・ないと",e:"Queen's Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1500,de:1600,tx:"しなやかな動きで敵を翻弄し、相手のスキを突いて素早い攻撃を繰り出す。",li:"",lk:[],ta:[],al:[]},
  {n:"X－ヘッド・キャノン",h:"えっくす－へっど・きゃのん",e:"X-Head Cannon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"強力なキャノン砲を装備した、合体能力を持つモンスター。\n合体と分離を駆使して様々な攻撃を繰り出す。",li:"",lk:[],ta:[],al:[]},
  {n:"エネミーコントローラー",h:"えねみーこんとろーらー",e:"Enemy Controller",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。\n\n●自分フィールドのモンスター１体をリリースし、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"達人キョンシー",h:"たつじんきょんしー",e:"Master Kyonshee",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1750,de:1000,tx:"強い相手を求めさまよっているキョンシー。\nかつてはあらゆる武術の達人として知られていたらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"大くしゃみのカバザウルス",h:"おおくしゃみのかばざうるす",e:"Kabazauls",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1700,de:1500,tx:"巨大な体を持つカバの化け物。\nその巨体からくり出されるクシャミは、ハリケーンに匹敵する。",li:"",lk:[],ta:[],al:[]},
  {n:"大木人１８",h:"だいもくじんいんぱち",e:"Inpachi",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1900,tx:"切り倒された大木に邪悪な魂が宿った姿。\n森に迷い込んだ者に襲いかかる。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の偵察者",h:"はかもりのていさつしゃ",e:"Gravekeeper's Spy",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:2000,tx:"リバース：自分のデッキから攻撃力１５００以下の「墓守の」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の呪術師",h:"はかもりのじゅじゅつし",e:"Gravekeeper's Curse",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが召喚・反転召喚・特殊召喚された時、相手プレイヤーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の番兵",h:"はかもりのばんぺい",e:"Gravekeeper's Guard",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1900,tx:"リバース：フィールド上の相手モンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の長槍兵",h:"はかもりのながやりへい",e:"Gravekeeper's Spear Soldier",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"守備表示モンスターを攻撃した時、このカードの攻撃力が守備表示モンスターの守備力を越えていれば、その数値だけ相手ライフポイントに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の従者",h:"はかもりのじゅうしゃ",e:"Gravekeeper's Vassal",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"このカードが相手プレイヤーに与える戦闘ダメージは効果によるダメージとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の監視者",h:"はかもりのかんししゃ",e:"Gravekeeper's Watcher",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"相手プレイヤーが自らの手札を捨てる効果を含むカードを発動した時、このカードを手札から墓地に送る事で、その発動と効果を無効にしてそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の長",h:"はかもりのおさ",e:"Gravekeeper's Chief",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードは自分のフィールド上に１枚しか存在できない。\nこのカードがフィールド上に存在する限り、自分の墓地は「王家の眠る谷－ネクロバレー」の効果を受けない。\nこのカードが生け贄召喚に成功した場合、自分の墓地に存在する「墓守の」という名のついたモンスターカード１枚をフィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の大筒持ち",h:"はかもりのおおづつもち",e:"Gravekeeper's Cannonholder",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"自分フィールド上の「墓守の」という名のついたモンスターカード１体を生け贄に捧げる度に、相手に７００ポイントダメージを与える。\nこの場合「墓守の大筒持ち」を生け贄に捧げる事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の暗殺者",h:"はかもりのあさしん",e:"Gravekeeper's Assailant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「王家の眠る谷－ネクロバレー」がフィールド上に存在しなければ発動できない。\nこのカードの攻撃宣言時、相手表側表示モンスターの表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウジャト眼を持つ男",h:"うじゃとがんをもつおとこ",e:"A Man with Wdjat",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードを通常召喚した時と自分のスタンバイフェイズ時、相手フィールド上にセットされているカード１枚を選択してめくり、確認した後元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャッカルの聖戦士",h:"じゃっかるのせいせんし",e:"Mystical Knight of Jackal",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:1200,tx:"このカードが相手フィールド上モンスター１体を戦闘によって破壊し相手の墓地に送った時、そのカードを相手のデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"不幸を告げる黒猫",h:"ふこうをつげるくろねこ",e:"A Cat of Ill Omen",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:300,tx:"①：このカードがリバースした場合に発動する。\nデッキから罠カード１枚を選んでデッキの一番上に置く。\n「王家の眠る谷－ネクロバレー」がフィールドに存在する場合、その罠カードをデッキの一番上に置く代わりに、手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"黄泉へ渡る船",h:"よみへわたるふね",e:"Yomi Ship",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nこのカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"有翼賢者ファルコス",h:"ゆうよくけんじゃふぁるこす",e:"Winged Sage Falcos",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが戦闘によって破壊し墓地に送った表側攻撃表示の相手モンスターを、相手のデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"幸運を告げるフクロウ",h:"こううんをつげるふくろう",e:"An Owl of Luck",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:500,tx:"リバース：デッキからフィールド魔法カードを１枚選択し、デッキの一番上に置く。\n「王家の眠る谷－ネクロバレー」がフィールド上に存在する場合、選択したフィールド魔法カードを手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"シャブティのお守り",h:"しゃぶてぃのおまもり",e:"Charm of Shabti",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"手札からこのカードを捨てる。\nエンドフェイズまで、自分フィールド上の「墓守の」という名のついたモンスターカードが受ける戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"スネークポット",h:"すねーくぽっと",e:"Cobra Jar",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:600,de:300,tx:"リバース：自分フィールド上に「毒蛇トークン」（爬虫類族・地・星３・攻／守１２００）を１体特殊召喚する。\n「毒蛇トークン」が戦闘によって破壊された場合、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魂を削る死霊",h:"たましいをけずるしりょう",e:"Spirit Reaper",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードは戦闘では破壊されない。\nこのカードがカードの効果の対象になった時、このカードを破壊する。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイトメア・ホース",h:"ないとめあ・ほーす",e:"Nightmare Horse",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは相手フィールド上にモンスターが存在しても、相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイトメアを駆る死霊",h:"ないとめあをかるしりょう",e:"Reaper on the Nightmare",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["融合","効果"],ma:"",tp:"",at:800,de:600,tx:"「魂を削る死霊」＋「ナイトメア・ホース」\nこのカードは戦闘によっては破壊されない。\n魔法・罠・効果モンスターの効果の対象になった時、このカードを破壊する。\nこのカードは相手フィールド上にモンスターが存在しても相手プレイヤーに直接攻撃をする事ができる。\n直接攻撃に成功した場合、相手はランダムに手札を１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ショット・ガン・シャッフル",h:"しょっと・がん・しゃっふる",e:"Card Shuffle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"３００ライフポイントを払う。\n自分、または相手のデッキをシャッフルする。\nこの効果は１ターンに１度しか使えない。",li:"",lk:[],ta:[],al:[]},
  {n:"名推理",h:"めいすいり",e:"Reasoning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手は１～１２までの任意のレベルを宣言する。\n通常召喚可能なモンスターが出るまで自分のデッキの上からカードをめくり、そのモンスターのレベルが宣言されたレベルと同じ場合、めくったカードを全て墓地へ送る。\n違った場合、そのモンスターを特殊召喚し、残りのめくったカードは全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"悪夢の拷問部屋",h:"あくむのごうもんべや",e:"Dark Room of Nightmare",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手ライフに戦闘ダメージ以外のダメージを与える度に、相手ライフに３００ポイントダメージを与える。\n「悪夢の拷問部屋」の効果では、このカードの効果は適用されない。",li:"",lk:[],ta:[],al:[]},
  {n:"タイムカプセル",h:"たいむかぷせる",e:"Different Dimension Capsule",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキからカードを１枚選択し、裏側表示でゲームから除外する。\n発動後２回目の自分のスタンバイフェイズ時にこのカードを破壊し、そのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"王家の眠る谷－ネクロバレー",h:"おうけのねむるたに－ねくろばれー",e:"Necrovalley",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「墓守」モンスターの攻撃力・守備力は５００アップする。\n②：このカードがフィールドゾーンに存在する限り、お互いに墓地のカードを除外できず、墓地のカードへ及ぶ効果は無効化され、適用されない。",li:"",lk:[],ta:[],al:[]},
  {n:"バスターランチャー",h:"ばすたーらんちゃー",e:"Buster Rancher",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"攻撃力１０００以下のモンスターのみ装備可能。\nダメージ計算時、相手モンスターが攻撃表示なら攻撃力、守備表示なら守備力が２５００ポイント以上の場合、このカードを装備したモンスターの攻撃力は２５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒエログリフの石版",h:"ひえろぐりふのせきばん",e:"Hieroglyph Lithograph",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払う。\nそのデュエル中、自分の手札制限枚数が７枚になる。",li:"",lk:[],ta:[],al:[]},
  {n:"黒蛇病",h:"こくじゃびょう",e:"Dark Snake Syndrome",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ毎にお互いのライフに２００ポイントダメージを与える。\n２ターン目以降自分のスタンバイフェイズ毎にダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"テラ・フォーミング",h:"てら・ふぉーみんぐ",e:"Terraforming",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからフィールド魔法カード１枚を手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"勇気の旗印",h:"ゆうきのはたじるし",e:"Banner of Courage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドのモンスターの攻撃力は、自分バトルフェイズの間２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"突然変異",h:"めたもるふぉーぜ",e:"Metamorphosis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上モンスター１体を生け贄に捧げる。\n生け贄に捧げたモンスターのレベルと同じレベルの融合モンスター１体を融合デッキから特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"王家の生け贄",h:"おうけのいけにえ",e:"Royal Tribute",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「王家の眠る谷－ネクロバレー」が自分フィールド上に存在している時に発動する事ができる。\nお互いの手札にあるモンスターカードを全て墓地に捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"大逆転クイズ",h:"だいぎゃくてんくいず",e:"Reversal Quiz",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札とフィールド上のカードを全て墓地に送る。\n自分のデッキの一番上にあるカードの種類（魔法・罠・モンスター）を当てる。\n当てた場合、相手と自分のライフポイントを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"老化の呪い",h:"ろうかののろい",e:"Curse of Aging",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札からカードを１枚捨てる。\nターン終了時まで相手フィールド上の全モンスターの攻撃力・守備力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄の扉越し銃",h:"じごくのとびらごしじゅう",e:"Barrel Behind the Door",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\n自分が受けるその効果ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ブレイク",h:"さんだー・ぶれいく",e:"Raigeki Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"狭き通路",h:"せまきつうろ",e:"Narrow Pass",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのフィールド上にそれぞれモンスターが２体以下の時に発動する事ができる。\nお互いのプレイヤーは自分フィールド上に召喚できるモンスターは２体までになる。",li:"",lk:[],ta:[],al:[]},
  {n:"攪乱作戦",h:"かくらんさくせん",e:"Disturbance Strategy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手は手札をデッキに加えてシャッフルした後、元の手札の数だけデッキからカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"黒板消しの罠",h:"こくばんけしのわな",e:"Trap of Board Eraser",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\n自分が受けるその効果ダメージを無効にし、相手は手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"降霊の儀式",h:"こうれいのぎしき",e:"Rite of Spirit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"指定した自分の墓地の「墓守の」という名のついたモンスターカード１枚を特殊召喚する。\nこのカードの発動は「王家の眠る谷－ネクロバレー」によっては制限されない。",li:"",lk:[],ta:[],al:[]},
  {n:"絶対不可侵領域",h:"ぜったいふかしんりょういき",e:"Non Aggression Area",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のスタンバイフェイズに発動する事ができる。\n手札からカードを１枚捨てる。\n相手は次の相手ターンに通常召喚・特殊召喚ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の血族",h:"りゅうのけつぞく",e:"D. Tribe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の全てのモンスターは、エンドフェイズ時までドラゴン族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"天声の服従",h:"てんせいのふくじゅう",e:"Lullaby of Obedience",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：２０００LPを払い、モンスターカード名を１つ宣言して発動できる。\n相手は自身のデッキを確認し、宣言されたモンスターがあった場合、その内の１体をお互いに確認し以下の効果から１つを選択して適用する。\n●確認したカードを、このカードを発動したプレイヤーの手札に加える。\n●確認したカードを、このカードを発動したプレイヤーのフィールドに召喚条件を無視して攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Y－ドラゴン・ヘッド",h:"わい－どらごん・へっど",e:"Y-Dragon Head",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの「X－ヘッド・キャノン」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は４００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"XY－ドラゴン・キャノン",h:"えっくすわい－どらごん・きゃのん",e:"XY-Dragon Cannon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1900,tx:"「X－ヘッド・キャノン」＋「Y－ドラゴン・ヘッド」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその相手の表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"Z－メタル・キャタピラー",h:"ぜっと－めたる・きゃたぴらー",e:"Z-Metal Tank",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの「X－ヘッド・キャノン」または「Y－ドラゴン・ヘッド」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"XYZ－ドラゴン・キャノン",h:"えっくすわいぜっと－どらごん・きゃのん",e:"XYZ-Dragon Cannon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2600,tx:"「X－ヘッド・キャノン」＋「Y－ドラゴン・ヘッド」＋「Z－メタル・キャタピラー」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の綱",h:"たましいのつな",e:"Soul Rope",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが効果で破壊され墓地へ送られた時、１０００LPを払って発動できる。\nデッキからレベル４モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"キングス・ナイト",h:"きんぐす・ないと",e:"King's Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"①：自分フィールドに「クィーンズ・ナイト」が存在し、このカードが召喚に成功した時に発動できる。\nデッキから「ジャックス・ナイト」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャックス・ナイト",h:"じゃっくす・ないと",e:"Jack's Knight",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:1000,tx:"あらゆる剣術に精通した戦士。\nとても正義感が強く、弱き者を守るために闘っている。",li:"",lk:[],ta:[],al:[]},
  {n:"亜空間物質転送装置",h:"あくうかんぶっしつてんそうそうち",e:"Interdimensional Matter Transporter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分の表側表示モンスターをエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリンゾンビ",h:"ごぶりんぞんび",e:"Goblin Zombie",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1100,de:1050,tx:"①：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手のデッキの一番上のカードを墓地へ送る。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから守備力１２００以下のアンデット族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドリラゴ",h:"どりらご",e:"Drillago",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1100,tx:"相手フィールド上に攻撃力１６００以上の表側表示モンスターしか存在しない場合、このモンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レクンガ",h:"れくんが",e:"Lekunga",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"自分の墓地の水属性モンスター２体をゲームから除外する度に、自分フィールド上に「レクンガトークン」（植物族・水・星２・攻／守７００）を１体攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クローン複製",h:"くろーんふくせい",e:"Cloning",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターの召喚・反転召喚に成功した時、そのモンスター１体を対象として発動できる。\nその表側表示モンスターの元々の種族・属性・レベル・攻撃力・守備力を持つ「クローントークン」１体を自分フィールドに特殊召喚する。\nその表側表示モンスターが破壊され墓地へ送られた時にこのトークンは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"コストダウン",h:"こすとだうん",e:"Cost Down",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\nこのターン、自分の手札のモンスターのレベルを２つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"命削りの宝札",h:"いのちけずりのほうさつ",e:"Card of Demise",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はモンスターを特殊召喚できない。\n①：自分は手札が３枚になるようにデッキからドローする。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。\nこのターンのエンドフェイズに、自分の手札を全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"逃げまどう民",h:"にげまどうたみ",e:"People Running About",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"いつも苦しみに耐えているが、いつか必ず革命を起こすと心に誓っている。",li:"",lk:[],ta:[],al:[]},
  {n:"弾圧される民",h:"だんあつされるたみ",e:"Oppressed People",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:400,de:2000,tx:"いつの日か自由を手にする事ができると信じて日々の生活に耐えている。",li:"",lk:[],ta:[],al:[]},
  {n:"団結するレジスタンス",h:"だんけつするれじすたんす",e:"United Resistance",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1000,de:400,tx:"強大な力に立ち向かう誓いを交わすために集結した人々。\n革命の日は近い。",li:"",lk:[],ta:[],al:[]},
  {n:"闇魔界の戦士 ダークソード",h:"やみまかいのせんし だーくそーど",e:"Dark Blade",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"ドラゴンを操ると言われている闇魔界の戦士。\n邪悪なパワーで斬りかかる攻撃はすさまじい。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の闘龍",h:"しっこくのどらごん",e:"Pitch-Dark Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:900,de:600,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「闇魔界の戦士 ダークソード」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力・守備力は４００ポイントアップする。\n守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"騎竜",h:"きりゅう",e:"Kiryu",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:2000,de:1500,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「闇魔界の戦士 ダークソード」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力・守備力は９００ポイントアップする。\n装備状態のこのカードを生け贄に捧げる事で、装備モンスターはこのターン相手プレイヤーに直接攻撃ができる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"朽ち果てた武将",h:"くちはてたぶしょう",e:"Decayed Commander",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードが召喚に成功した時、手札から「ゾンビタイガー」１体を特殊召喚する事ができる。\nこのカードが相手プレイヤーへの直接攻撃に成功した場合、相手は手札からランダムにカードを１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾンビタイガー",h:"ぞんびたいがー",e:"Zombie Tiger",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["ユニオン","効果"],ma:"",tp:"",at:1400,de:1600,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「朽ち果てた武将」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力・守備力は５００ポイントアップする。\n装備モンスターが戦闘によって相手モンスターを破壊する度に、相手は手札からランダムにカードを１枚捨てる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ジャイアント・オーク",h:"じゃいあんと・おーく",e:"Giant Orc",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分ターン終了時までこのカードの表示形式は変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"セコンド・ゴブリン",h:"せこんど・ごぶりん",e:"Second Goblin",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["ユニオン","効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「ジャイアント・オーク」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの表示形式を１ターンに１度だけ変更する事ができる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ブラッド・オーキス",h:"ぶらっど・おーきす",e:"Vampiric Orchis",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが召喚に成功した時、手札から「デス・デンドル」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・デンドル",h:"です・でんどる",e:"Des Dendle",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["ユニオン","効果"],ma:"",tp:"",at:300,de:2000,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「ブラッド・オーキス」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターが戦闘によってモンスターを破壊する度に「魔草トークン」（植物族・地・星１・攻／守８００）を１体特殊召喚する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"灼岩魔獣",h:"しゃくがんまじゅう",e:"Burning Beast",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1000,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「氷岩魔獣」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターが相手プレイヤーに戦闘ダメージを与えた場合、フィールド上で表側表示の魔法または罠カード１枚を破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"氷岩魔獣",h:"ひょうがんまじゅう",e:"Freezing Beast",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1000,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「灼岩魔獣」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターが相手プレイヤーに戦闘ダメージを与えた場合、フィールド上で裏側表示の魔法または罠カード１枚を破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ユニオン・ライダー",h:"ゆにおん・らいだー",e:"Union Rider",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"相手フィールド上でモンスター状態のユニオンモンスターのコントロールを得て、このカードに１枚まで装備する。\nこのカードに装備されたユニオンは自身の効果を使ってモンスター状態に戻る事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の狂獣",h:"いじげんのきょうじゅう",e:"D.D. Crazy Beast",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが戦闘によって破壊したモンスターはゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・キャンセラー",h:"まじっく・きゃんせらー",e:"Spell Canceller",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り魔法カードは発動できず、全てのフィールド上魔法カードの効果は無効になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネコマネキング",h:"ねこまねきんぐ",e:"Neko Mane King",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"相手ターン中にこのカードが相手の魔法・罠・モンスターの効果によって墓地に送られた時、相手ターンを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"白兵戦型お手伝いロボ",h:"はくへいせんがたおてつだいろぼ",e:"Helping Robo for Combat",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカードが戦闘によって相手モンスターを破壊する度に、自分はカードを１枚ドローした後手札からカードを１枚選択してデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・ポッド",h:"でぃめんしょん・ぽっど",e:"Dimension Jar",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:200,tx:"リバース：お互いのプレイヤーは、相手の墓地のカードを３枚までゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"世紀の大泥棒",h:"せいきのおおどろぼう",e:"Great Phantom Thief",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"相手プレイヤーに戦闘ダメージを与える度にカード名を１つ宣言する。\n相手の手札を確認して宣言したカードがあった場合それを全て墓地に捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ルーレットボマー",h:"るーれっとぼまー",e:"Roulette Barrel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分のメインフェイズに２回サイコロを振る事ができる。\n出た目を１つ選択し、その数と同じレベルのフィールド上表側表示モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"白竜の聖騎士",h:"ないと・おぶ・ほわいとどらごん",e:"Paladin of White Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:1200,tx:"「白竜降臨」により降臨。\n①：このカードが裏側守備表示モンスターに攻撃するダメージステップ開始時に発動する。\nその裏側守備表示モンスターを破壊する。\n②：このカードをリリースして発動できる。\n手札・デッキから「青眼の白龍」１体を特殊召喚する。\nこのターン、自分の「青眼の白龍」は攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"白竜降臨",h:"はくりゅうこうりん",e:"White Dragon Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「白竜の聖騎士」の降臨に必要。\n①：レベルの合計が４以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「白竜の聖騎士」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"前線基地",h:"ぜんせんきち",e:"Frontline Base",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分のメインフェイズ時に手札からレベル４以下のユニオンモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"降格処分",h:"こうかくしょぶん",e:"Demotion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターのレベルを２つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"コンビネーション・アタック",h:"こんびねーしょん・あたっく",e:"Combination Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"ユニオンモンスターを装備したモンスターが戦闘を行ったバトルフェイズ時にのみ発動する事ができる。\nユニオンモンスターを装備して戦闘を行ったモンスター１体を選択し、装備されたユニオンを解除する。\n選択したモンスターはこのターンもう１度攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザーコロシアム",h:"かいざーころしあむ",e:"Kaiser Colosseum",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在する限り、相手はその数より多くなるように自身のフィールドにモンスターを出す事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"自律行動ユニット",h:"じりつこうどうゆにっと",e:"Autonomous Action Unit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：１５００LPを払い、相手の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを自分フィールドに攻撃表示で特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ご隠居の猛毒薬",h:"ごいんきょのもうどくやく",e:"Poison of the Old Man",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●自分は１２００LP回復する。\n\n●相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アンティ勝負",h:"あんてぃしょうぶ",e:"Ante",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"それぞれ手札からカードを１枚選択し、お互いにレベルを確認する。\nレベルの高いモンスターを選択したプレイヤーのカードは手札に戻り、レベルの低いモンスターを選択したプレイヤーは１０００ポイントダメージを受け、そのカードを墓地へ送る。\nモンスター以外のカードを選択した場合はレベル０とする。\n同レベルの場合はお互いにカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・コア",h:"ぶらっく・こあ",e:"Dark Core",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札を１枚捨てる。\nフィールド上の表側表示のモンスター１体をゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"レアゴールド・アーマー",h:"れあごーるど・あーまー",e:"Raregold Armor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードを装備したモンスターをコントロールしている限り、相手は装備モンスター以外のモンスターには攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルシルバー・アーマー",h:"めたるしるばー・あーまー",e:"Metalsilver Armor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターが自分フィールドに存在する限り、相手は装備モンスター以外のお互いのフィールド・墓地のモンスター及び除外されているモンスターを効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"騎士道精神",h:"きしどうせいしん",e:"Kishido Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上モンスターは、攻撃力の同じモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"生け贄人形",h:"いけにえどーる",e:"Tribute Doll",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上モンスター１体を生け贄に捧げて発動する。\n手札から通常召喚可能なレベル７のモンスター１体を特殊召喚する。\nそのモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"波動キャノン",h:"はどうきゃのん",e:"Wave-Motion Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のメインフェイズ時、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、このカードの発動後に経過した自分のスタンバイフェイズの数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"大革命",h:"だいかくめい",e:"Huge Revolution",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のメインフェイズで自分フィールド上に「逃げまどう民」「弾圧される民」「団結するレジスタンス」が表側表示で存在する時のみ発動する事ができる。\n相手の手札を全て墓地に送り、フィールド上の相手がコントロールするカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"覇者の一括",h:"はしゃのいっかつ",e:"Thunder of Ruler",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手スタンバイフェイズで発動する事ができる。\n発動ターン相手はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"八式対魔法多重結界",h:"はちしきたいまほうたじゅうけっかい",e:"Spell Shield Type-8",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"次の効果から１つを選択して発動する。\n\n●フィールド上のモンスター１体を対象にした魔法の発動と効果を無効にし、そのカードを破壊する。\n\n●手札から魔法カード１枚を墓地に送る事で魔法の発動と効果を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"メテオ・レイン",h:"めてお・れいん",e:"Meteorain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン自分のモンスターが守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"パイナップル爆弾",h:"ぱいなっぷるぼむ",e:"Pineapple Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分がモンスターの召喚に成功した時に発動する事ができる。\n相手フィールド上のモンスターの数が自分のコントロールするモンスターの数より多い場合、同じ数になるように相手のカードを破壊する。\n破壊するカードは相手が選択する。",li:"",lk:[],ta:[],al:[]},
  {n:"仕込みマシンガン",h:"しこみましんがん",e:"Secret Barrel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の手札・フィールドのカードの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"物理分身",h:"ぶつりぶんしん",e:"Physical Double",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のターンでのみ発動する事ができる。\n指定した相手モンスターと同じレベル・種族・属性・攻撃力・守備力を持つ「ミラージュトークン」を１体特殊召喚する。\nターン終了時にこのトークンを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"群雄割拠",h:"ぐんゆうかっきょ",e:"Rivalry of Warlords",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いのフィールドにそれぞれ１種類の種族のモンスターしか表側表示で存在できない。\nお互いのプレイヤーは自身のフィールドの表側表示モンスターの種族が２種類以上の場合には１種類になるように墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーメーション・ユニオン",h:"ふぉーめーしょん・ゆにおん",e:"Formation Union",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"次の効果から１つを選択して発動する。\n●自分フィールド上に表側表示で存在するユニオンモンスター１体を選択し、自分フィールド上に表側表示で存在する装備可能なモンスターに装備する。\n●自分フィールド上に存在する装備カード扱いのユニオンモンスター１体の装備を解除して、自分フィールド上に表側攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"粘着落とし穴",h:"ねんちゃくおとしあな",e:"Adhesion Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターの召喚・反転召喚・特殊召喚に成功した時に発動する事ができる。\nそのモンスターはフィールド上に表側表示で存在する限り、元々の攻撃力が半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"XZ－キャタピラー・キャノン",h:"えっくすぜっと－きゃたぴらー・きゃのん",e:"XZ-Tank Cannon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2100,tx:"「X－ヘッド・キャノン」＋「Z－メタル・キャタピラー」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドの裏側表示の魔法・罠カード１枚を対象として発動できる。\nその相手の裏側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"YZ－キャタピラー・ドラゴン",h:"わいぜっと－きゃたぴらー・どらごん",e:"YZ-Tank Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:2200,tx:"「Y－ドラゴン・ヘッド」＋「Z－メタル・キャタピラー」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドの裏側表示モンスター１体を対象として発動できる。\nその相手の裏側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"最終突撃命令",h:"さいしゅうとつげきめいれい",e:"Final Attack Orders",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、フィールドの表側表示モンスターは、攻撃表示になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超魔導剣士－ブラック・パラディン",h:"ちょうまどうけんし－ぶらっく・ぱらでぃん",e:"Dark Paladin",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2400,tx:"「ブラック・マジシャン」＋「バスター・ブレイダー」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、お互いのフィールド・墓地のドラゴン族モンスターの数×５００アップする。\n②：魔法カードが発動した時、手札を１枚捨てて発動できる。\nこのカードがフィールドに表側表示で存在する場合、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"二重魔法",h:"だぶるまじっく",e:"Double Spell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から魔法カード１枚を捨て、相手の墓地の魔法カード１枚を選択して発動できる。\n選択した魔法カードを自分フィールド上の正しいカードゾーンに置き、使用する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法吸収",h:"まほうきゅうしゅう",e:"Spell Absorption",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"魔法カードが発動する度に、このカードのコントローラーは５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"拡散する波動",h:"かくさんするはどう",e:"Diffusion Wave-Motion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにモンスターが存在する場合、１０００LPを払い、自分フィールドのレベル７以上の魔法使い族モンスター１体を対象として発動できる。\nこのターン、そのモンスター以外のモンスターは攻撃できず、対象のモンスターは可能な限り相手モンスター全てに１回ずつ攻撃しなければならない。\nその攻撃で破壊されたモンスターの効果は発動できず、無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"デビルズ・サンクチュアリ",h:"でびるず・さんくちゅあり",e:"Fiend's Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「メタルデビル・トークン」（悪魔族・闇・星１・攻／守０）１体を特殊召喚する。\nこのトークンは攻撃できず、このトークンの戦闘で発生するコントローラーへの戦闘ダメージは代わりに相手が受ける。\nこのトークンのコントローラーは自分スタンバイフェイズ毎に１０００LPを払う。\nまたは、LPを払わずにこのトークンを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラゲド",h:"じゅらげど",e:"Juragedo",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のバトルステップに発動できる。\nこのカードを手札から特殊召喚し、自分は１０００LP回復する。\n②：このカードをリリースし、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を次のターンの終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"左腕の代償",h:"ひだりうでのだいしょう",e:"Left Arm Offering",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分は魔法・罠カードをセットできない。\n①：このカード以外の自分の手札が２枚以上の場合、その手札を全て除外して発動できる。\nデッキから魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の魔再生",h:"あんこくのまさいせい",e:"Dark Spell Regeneration",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に、相手の墓地の魔法カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\n②：墓地のこのカードを除外し、手札及び自分フィールドにセットされたカードの中から、「死者蘇生」１枚を墓地へ送って発動できる。\n自分の墓地から「ラーの翼神竜」１体を選んで召喚条件を無視して特殊召喚する。\nその後、相手フィールドのモンスター１体を選んで墓地へ送る事ができる。\nこの効果で特殊召喚したモンスターはエンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"グレート・アンガス",h:"ぐれーと・あんがす",e:"Great Angus",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1800,de:600,tx:"狂ったように暴れ続けている、非常に凶暴な獣。\nおとなしい姿を見た者はいないと言う。",li:"",lk:[],ta:[],al:[]},
  {n:"アイツ",h:"あいつ",e:"Aitsu",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:100,de:100,tx:"非常に頼りない姿をしているが、実はとてつもない潜在能力を隠し持っているらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"音速ダック",h:"そにっくだっく",e:"Sonic Duck",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1700,de:700,tx:"音速で歩く事ができるダック。\nそのすさまじいスピードに対応できず、コントロールを失う事が多い。",li:"",lk:[],ta:[],al:[]},
  {n:"サファイアドラゴン",h:"さふぁいあどらごん",e:"Luster Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1900,de:1600,tx:"全身がサファイアに覆われた、非常に美しい姿をしたドラゴン。\n争いは好まないが、とても高い攻撃力を備えている。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの聖戦士",h:"あまぞねすのせいせんし",e:"Amazoness Paladin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"自分フィールド上の「アマゾネス」という名のついたモンスターカード１枚につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの吹き矢兵",h:"あまぞねすのふきやへい",e:"Amazoness Blowpiper",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"自分のスタンバイフェイズ毎に相手フィールド上のモンスターを１体選択する。\n選択したモンスターの攻撃力はターン終了時まで５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスペット虎",h:"あまぞねすぺっとたいがー",e:"Amazoness Tiger",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:1500,tx:"このカードは自分フィールド上に１枚しか表側表示で存在できない。\n自分フィールド上の「アマゾネス」という名のついたモンスターカード１枚につき、このカードの攻撃力は４００ポイントアップする。\n相手はこのカードを破壊しない限り、他の「アマゾネス」という名のついたモンスターを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"熟練の白魔導師",h:"じゅくれんのしろまどうし",e:"Skilled White Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\nまた、魔力カウンターが３つ乗っているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「バスター・ブレイダー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"熟練の黒魔術師",h:"じゅくれんのくろまじゅつし",e:"Skilled Dark Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1900,de:1700,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャン」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"見習い魔術師",h:"みならいまじゅつし",e:"Apprentice Magician",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した場合、フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動する。\nそのカードに魔力カウンターを１つ置く。\n②：このカードが戦闘で破壊された時に発動できる。\nデッキからレベル２以下の魔法使い族モンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"執念深き老魔術師",h:"しゅうねんぶかきろうまじゅつし",e:"Old Vindictive Magician",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:450,de:600,tx:"①：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・マジシャン",h:"かおす・まじしゃん",e:"Chaos Command Magician",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:1900,tx:"①：このカードがモンスターゾーンに存在する限り、このカード１枚のみを対象とするモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法の操り人形",h:"まじかる・まりおねっと",e:"Magical Marionette",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに乗っている魔力カウンター１つにつき、このカードの攻撃力は２００ポイントアップする。\nまた、このカードに乗っている魔力カウンターを２つ取り除く事で、フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ピクシーナイト",h:"ぴくしーないと",e:"Pixie Knight",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:200,tx:"このカードが戦闘によって墓地に送られた時、自分の墓地の魔法カード１枚を相手が選択し、そのカードを自分のデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導戦士 ブレイカー",h:"まどうせんし　ぶれいかー",e:"Breaker the Magical Warrior",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く（最大１つまで）。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×３００アップする。\n③：このカードの魔力カウンターを１つ取り除き、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔草 マンドラゴラ",h:"まそう　まんどらごら",e:"Magical Plant Mandragola",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:200,tx:"リバース：フィールド上に表側表示で存在する魔力カウンターを置く事ができるカード全てに魔力カウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導サイエンティスト",h:"まどうさいえんてぃすと",e:"Magical Scientist",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"１０００ライフポイントを払う事で、融合デッキからレベル６以下の融合モンスター１体を特殊召喚する。\nこの融合モンスターは相手プレイヤーに直接攻撃する事はできず、ターン終了時に融合デッキに戻る。",li:0,lk:[],ta:[],al:[]},
  {n:"王立魔法図書館",h:"おうりつまほうとしょかん",e:"Royal Magical Library",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：このカードの魔力カウンターを３つ取り除いて発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導アーマー エグゼ",h:"まどうあーまー　えぐぜ",e:"Armor Exe",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n自分と相手のスタンバイフェイズ毎に、自分フィールド上の魔力カウンターを１個取り除く。\n取り除かない場合はこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"同族感染ウィルス",h:"どうぞくかんせんうぃるす",e:"Tribe-Infecting Virus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：手札を１枚捨て、種族を１つ宣言して発動できる。\nフィールドの宣言した種族のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・コアラ",h:"です・こあら",e:"Des Koala",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:1800,tx:"リバース：相手の手札１枚につき４００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒蠍－罠はずしのクリフ",h:"くろさそり－わなはずしのくりふ",e:"Dark Scorpion - Cliff the Trap Remover",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●フィールド上の魔法または罠カード１枚を破壊する。\n\n●相手のデッキの上から２枚を墓地に送る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導雑貨商人",h:"まどうざっかしょうにん",e:"Magical Merchant",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:700,tx:"①：このカードがリバースした場合に発動する。\n魔法・罠カードが出るまで自分のデッキの上からカードをめくり、その魔法・罠カードを手札に加える。\n残りのめくったカードは全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"コイツ",h:"こいつ",e:"Koitsu",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:200,de:100,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして、フィールド上のこのカードを「アイツ」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\n装備モンスターの攻撃力は３０００ポイントアップする。\n守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n（１体のモンスターが装備できるユニオンは１枚まで、装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ネコ耳族",h:"ねこみみぞく",e:"Cat's Ear Tribe",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"相手ターン時のみ、このカードと戦闘する相手モンスターの元々の攻撃力は、ダメージステップ時に２００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"絶対服従魔人",h:"ぜったいふくじゅうまじん",e:"Ultimate Obedient Fiend",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3500,de:3000,tx:"自分フィールド上にこのカードだけしかなく、手札が０枚でなければこのカードは攻撃できない。\nこのカードが破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"尾も白い黒猫",h:"おもしろいくろねこ",e:"Dark Cat with White Tail",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:500,tx:"リバース：相手フィールド上モンスター２体と自分フィールド上モンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒のパワーストーン",h:"しっこくのぱわーすとーん",e:"Pitch-Black Power Stone",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードを発動する場合、このカードに魔力カウンターを３つ置いて発動する。\n①：自分ターンに１度、このカード以外のフィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nこのカードの魔力カウンターを１つ取り除き、そのカードに魔力カウンターを１つ置く。\n②：このカードの魔力カウンターが全て取り除かれた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグバン・シュート",h:"びっぐばん・しゅーと",e:"Big Bang Shot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は４００ポイントアップする。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードがフィールド上から離れた時、装備モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"精神統一",h:"せいしんとういつ",e:"Gather Your Mind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキから「精神統一」を１枚手札に加える。\nこのカードは１ターンに１枚しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マスドライバー",h:"ますどらいばー",e:"Mass Driver",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体を生け贄に捧げる度に、相手ライフに４００ポイントダメージを与える。",li:0,lk:[],ta:[],al:[]},
  {n:"千里眼",h:"せんりがん",e:"Senri Eye",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度だけ自分のスタンバイフェイズ時に１００ライフポイントを払う事で、相手デッキの一番上のカードを確認して元に戻す事ができる。\n相手はそのカードを確認できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜破壊の証",h:"りゅうはかいのあかし",e:"Emblem of Dragon Destroyer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキ・墓地から「バスター・ブレイダー」１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"壺盗み",h:"つぼぬすみ",e:"Jar Robber",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「強欲な壺」発動時に発動する事ができる。\n「強欲な壺」の効果を無効にし、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"我が身を盾に",h:"わがみをたてに",e:"My Body as a Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"１５００ライフポイントを払って発動する。\n相手が発動した「フィールド上のモンスターを破壊する効果」を持つカードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書整理",h:"まどうしょせいり",e:"Spellbook Organization",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分のデッキの上から３枚カードをめくり好きな順番でデッキの上に戻す。\n相手はそのカードを確認できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メガトン魔導キャノン",h:"めがとんまどうきゃのん",e:"Mega Ton Magical Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する魔力カウンターを１０個取り除いて発動する。\n相手フィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カウンターマシンガンパンチ",h:"かうんたーましんがんぱんち",e:"Continuous Destruction Punch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"攻撃された守備表示モンスターの守備力が相手攻撃モンスターの攻撃力を越えていた場合、その攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力枯渇",h:"まりょくこかつ",e:"Exhausting Spell",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分と相手フィールド上に存在する魔力カウンターを全て取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"隠された魔導書",h:"かくされたまどうしょ",e:"Hidden Spellbook",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードは自分のターンのみ発動する事ができる。\n自分の墓地に存在する魔法カード２枚を選択し、デッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の復活",h:"きせきのふっかつ",e:"Miracle Restoring",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の魔力カウンターを２つ取り除いて発動できる。\n自分の墓地から「ブラック・マジシャン」または「バスター・ブレイダー」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"洗脳解除",h:"せんのうかいじょ",e:"Remove Brainwashing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分と相手のフィールド上に存在する全てのモンスターのコントロールは、元々の持ち主に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"武装解除",h:"ぶそうかいじょ",e:"Disarmament",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の装備カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"対抗魔術",h:"たいこうまじゅつ",e:"Anti-Spell",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に存在する魔力カウンターを２つ取り除いて発動する。\n魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"生命力吸収魔術",h:"せいめいりょくきゅうしゅうまじゅつ",e:"The Spell Absorbing Life",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の全ての裏側守備表示モンスターを表側表示にする。\nこの時、リバース効果モンスターの効果は発動しない。\n自分はフィールド上の効果モンスター１体につき４００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル・リフレクト・スライム",h:"めたる・りふれくと・すらいむ",e:"Metal Reflect Slime",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（水族・水・星１０・攻０／守３０００）となり、モンスターゾーンに守備表示で特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚されたこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ボーガニアン",h:"ぼーがにあん",e:"Bowganian",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"自分のスタンバイフェイズ毎に相手ライフに６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法石の採掘",h:"まほうせきのさいくつ",e:"Magical Stone Excavation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を２枚捨て、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"グラナドラ",h:"ぐらなどら",e:"Granadora",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1900,de:700,tx:"このモンスターが召喚・反転召喚・特殊召喚に成功した時、自分は１０００ライフポイント回復する。\nこのカードが破壊され墓地へ送られた時、自分は２０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元竜 トワイライトゾーンドラゴン",h:"いじげんりゅう　とわいらいとぞーんどらごん",e:"Different Dimension Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードは対象を指定しない魔法・罠カードの効果では破壊されない。\nまた、攻撃力１９００以下のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"天界王 シナト",h:"てんかいおう　しなと",e:"Shinato, King of a Higher Plane",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:3300,de:3000,tx:"「奇跡の方舟」により降臨。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。\nこのカードが相手の守備表示モンスターを戦闘によって破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒炎の騎士－ブラック・フレア・ナイト－",h:"こくえんのきし－ぶらっく・ふれあ・ないと－",e:"Dark Flare Knight",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:800,tx:"「ブラック・マジシャン」＋「炎の剣士」\n戦闘によって発生したこのカードのコントローラーへのダメージは０になる。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキまたは手札から「幻影の騎士－ミラージュ・ナイト－」を１体特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影の騎士－ミラージュ・ナイト－",h:"げんえいのきし－みらーじゅ・ないと－",e:"Mirage Knight",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2000,tx:"このカードは「黒炎の騎士－ブラック・フレア・ナイト－」の効果でのみ特殊召喚が可能。\nダメージ計算時、このカードの攻撃力に相手モンスターの元々の攻撃力を加える。\n戦闘を行ったターンのエンドフェイズ時に、このカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"バーサーク・デッド・ドラゴン",h:"ばーさーく・でっど・どらごん",e:"Berserk Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:0,tx:"このカードは「デーモンとの駆け引き」の効果でのみ特殊召喚が可能。\n相手フィールド上の全てのモンスターに１回ずつ攻撃が可能。\n自分のターンのエンドフェイズ毎にこのカードの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクゾディア・ネクロス",h:"えくぞでぃあ・ねくろす",e:"Exodia Necross",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードは通常召喚できない。\n「エクゾディアとの契約」の効果でのみ特殊召喚できる。\n①：このカードは戦闘及び魔法・罠カードの効果では破壊されない。\n②：自分スタンバイフェイズに発動する。\nこのカードの攻撃力は５００アップする。\n③：自分の墓地に「封印されしエクゾディア」「封印されし者の右腕」「封印されし者の左腕」「封印されし者の右足」「封印されし者の左足」のいずれかが存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"バトルフットボーラー",h:"ばとるふっとぼーらー",e:"Battle Footballer",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1000,de:2100,tx:"高い守備能力を誇るサイボーグ。\n元々はフットボールマシンとして開発されたという。",li:"",lk:[],ta:[],al:[]},
  {n:"ウェポンサモナー",h:"うぇぽんさもなー",e:"Arsenal Summoner",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：このカードがリバースした場合に発動する。\nデッキから「ガーディアン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"忍犬ワンダードッグ",h:"にんけんわんだーどっぐ",e:"Nin-Ken Dog",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1000,tx:"忍術を極めた犬忍者。\n厳しい修行により、擬人化の忍術を使う事が可能となった。",li:"",lk:[],ta:[],al:[]},
  {n:"アクロバットモンキー",h:"あくろばっともんきー",e:"Acrobat Monkey",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1000,de:1800,tx:"超最先端技術により開発されたモンキータイプの自律型ロボット。\n非常にアクロバティックな動きをする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・エルマ",h:"がーでぃあん・えるま",e:"Guardian Elma",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"「蝶の短剣－エルマ」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\nこのカードが召喚・特殊召喚に成功した時、自分の墓地の装備魔法カード１枚をこのカードに装備する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・シール",h:"がーでぃあん・しーる",e:"Guardian Ceal",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"「流星の弓－シール」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\nこのカードに装備された自分の装備カード１枚を墓地へ送る事で、相手フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・グラール",h:"がーでぃあん・ぐらーる",e:"Guardian Grarl",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"「重力の斧－グラール」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\n手札にこのカード１枚しかない場合、手札からこのカードを特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・バオウ",h:"がーでぃあん・ばおう",e:"Guardian Baou",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"「破邪の大剣－バオウ」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\n戦闘で相手モンスターを破壊して墓地へ送る度に、このカードの攻撃力は１０００ポイントアップする。\nまた、戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・ケースト",h:"がーでぃあん・けーすと",e:"Guardian Kay'est",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"「静寂のロッド－ケースト」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\nこのカードは魔法の効果を受けない。\nまた相手モンスターから攻撃対象にされない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・トライス",h:"がーでぃあん・とらいす",e:"Guardian Tryce",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1900,de:1700,tx:"「閃光の双剣－トライス」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\nこのカードが破壊され墓地へ送られた時、墓地に存在するこのカードの生け贄召喚に使用したモンスターを自分のフィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"逆ギレパンダ",h:"ぎゃくぎれぱんだ",e:"Gyaku-Gire Panda",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"相手フィールド上のモンスター１体につきこのカードの攻撃力は５００ポイントアップする。\n守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"盲信するゴブリン",h:"もうしんするごぶりん",e:"Blindly Loyal Goblin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカードは表側表示でフィールド上に存在する限り、コントロールを変更する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"闇より出でし絶望",h:"やみよりいでしぜつぼう",e:"Despair from the Dark",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:3000,tx:"①：このカードが相手の効果で手札・デッキから墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"合成魔獣 ガーゼット",h:"ごうせいまじゅう　がーぜっと",e:"Maju Garzett",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードの攻撃力は、生け贄召喚時に生け贄に捧げた２体のモンスターの元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"闇よりの恐怖",h:"やみよりのきょうふ",e:"Fear from the Dark",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"このカードが相手のカードの効果によって手札またはデッキから墓地に送られた時、このカードを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒蠍－逃げ足のチック",h:"くろさそり－にげあしのちっく",e:"Dark Scorpion - Chick the Yellow",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●フィールド上のカード１枚を持ち主の手札に戻す。\n\n●相手のデッキの一番上のカードを１枚めくる（相手は確認する事はできない）。\nそのカードをデッキの一番上か一番下かを選択して戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の女戦士",h:"いじげんのおんなせんし",e:"D.D. Warrior Lady",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその戦闘を行ったそれぞれのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"針千本",h:"さうざんど・にーどる",e:"Thousand Needles",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"守備表示のこのカードが攻撃を受けた時、このカードの守備力が相手攻撃モンスターの攻撃力を越えていれば、ダメージステップ終了時にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の方舟",h:"きせきのはこぶね",e:"Shinato's Ark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「天界王 シナト」の降臨に必要。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンとの駆け引き",h:"でーもんとのかけひき",e:"A Deal with Dark Ruler",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"レベル８以上の自分フィールド上のモンスターが墓地へ送られたターンに発動する事ができる。\n自分の手札またはデッキから「バーサーク・デッド・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクゾディアとの契約",h:"えくぞでぃあとのけいやく",e:"Contract with Exodia",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地に「封印されしエクゾディア」「封印されし者の右腕」「封印されし者の左腕」「封印されし者の右足」「封印されし者の左足」が存在する場合に発動できる。\n手札から「エクゾディア・ネクロス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"蝶の短剣－エルマ",h:"ちょうのたんけん－えるま",e:"Butterfly Dagger - Elma",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は３００ポイントアップする。\nモンスターに装備されているこのカードが破壊されて墓地に送られた時、このカードを持ち主の手札に戻す事ができる。",li:0,lk:[],ta:[],al:[]},
  {n:"流星の弓－シール",h:"りゅうせいのゆみ－しーる",e:"Shooting Star Bow - Ceal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は１０００ポイントダウンする。\n装備モンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"重力の斧－グラール",h:"じゅうりょくのおの－ぐらーる",e:"Gravity Axe - Grarl",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は５００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上モンスターは表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"破邪の大剣－バオウ",h:"はじゃのたいけん－ばおう",e:"Wicked-Breaking Flamberge - Baou",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"手札のカード１枚を墓地に送って装備する。\n装備モンスターの攻撃力は５００ポイントアップする。\nこのカードを装備したモンスターが戦闘で相手モンスターを破壊した場合、そのモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"静寂のロッド－ケースト",h:"せいじゃくのろっど－けーすと",e:"Rod of Silence - Kay'est",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの守備力は５００ポイントアップする。\n装備モンスターを対象にするこのカード以外の魔法カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光の双剣－トライス",h:"せんこうのそうけん－とらいす",e:"Twin Swords of Flashing Light - Tryce",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"手札のカード１枚を墓地に送って装備する。\n装備モンスターの攻撃力は５００ポイントダウンする。\n装備モンスターはバトルフェイズ中に２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界の宝札",h:"めいかいのほうさつ",e:"Precious Cards from Beyond",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分がモンスター２体以上をリリースしたアドバンス召喚に成功した場合に発動する。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"心眼の鉾",h:"しんがんのほこ",e:"Rod of the Mind's Eye",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが相手プレイヤーに戦闘ダメージを与える場合、そのダメージ数値は１０００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"泉の精霊",h:"いずみのせいれい",e:"Fairy of the Spring",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地から装備魔法カード１枚を手札に加える。\nその装備魔法カードはこのターン発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トークン収穫祭",h:"とーくんしゅうかくさい",e:"Token Thanksgiving",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上のトークンを全て破壊する。\n破壊したトークンの数×８００ライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"士気高揚",h:"しきこうよう",e:"Morale Boost",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"装備魔法カードをモンスターに装備させる度に、装備魔法カードのコントローラーは１０００ライフポイント回復する。\nまた、装備魔法カードがフィールド上から離れる度に、装備魔法カードのコントローラーは１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"絶対魔法禁止区域",h:"ぜったいまほうきんしくいき",e:"Non-Spellcasting Area",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する全ての効果モンスター以外のモンスターは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元隔離マシーン",h:"いじげんかくりましーん",e:"Different Dimension Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分と相手のフィールド上からモンスターを１体ずつ選択し、ゲームから除外する。\nこのカードが破壊され墓地へ送られた時、除外したモンスターを同じ表示形式で元のフィールド上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"立ちはだかる強敵",h:"たちはだかるきょうてき",e:"Staunch Defender",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の攻撃宣言時に発動する事ができる。\n自分フィールド上の表側表示モンスター１体を選択する。\n発動ターン相手は選択したモンスターしか攻撃対象にできず、全ての表側攻撃表示モンスターで選択したモンスターを攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマトリオ",h:"おじゃまとりお",e:"Ojama Trio",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドに「おジャマトークン」（獣族・光・星２・攻０／守１０００）３体を守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。\n「おジャマトークン」が破壊された時にそのコントローラーは１体につき３００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"武器庫荒らし",h:"ぶきこあらし",e:"Arsenal Robber",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手はデッキから装備魔法カードを１枚選択して墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"スキルドレイン",h:"すきるどれいん",e:"Skill Drain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１０００LPを払ってこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、フィールドの全ての表側表示モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"大成仏",h:"だいじょうぶつ",e:"Really Eternal Rest",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"装備カードが装備されているモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソウルテイカー",h:"そうるていかー",e:"Soul Taker",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。\nその後、相手は１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・マジック",h:"でぃめんしょん・まじっく",e:"Magical Dimension",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに魔法使い族モンスターが存在する場合、自分フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターをリリースし、手札から魔法使い族モンスター１体を特殊召喚する。\nその後、フィールドのモンスター１体を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ファラオの審判",h:"ふぁらおのしんぱん",e:"Judgment of Pharaoh",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ライフポイントを半分払う。\n以下の条件が当てはまり、適用できる効果を選択して発動する。\n\n●自分の墓地に「友情 YU－JYO」が存在する場合、相手プレイヤーはターン終了時までモンスターのセット・召喚・反転召喚・特殊召喚・効果モンスターの効果発動ができなくなる。\n\n●自分の墓地に「結束 UNITY」が存在する場合、ターン終了時まで相手フィールド上の魔法・罠の効果を無効にし、さらに相手プレイヤーは魔法・罠の発動・セットができなくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"友情 YU－JYO",h:"ゆうじょう　ＹＵ－ＪＹＯ",e:"Yu-Jo Friendship",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手プレイヤーに握手を申し込む。\n相手が握手に応じた場合、お互いのLPは、お互いのLPを合計した数値の半分になる。\n自分の手札に「結束 UNITY」がある場合、そのカードを相手に見せる事ができる。\nその場合、お互いは握手をして、このカードの効果を適用する。",li:"",lk:[],ta:[],al:[]},
  {n:"結束 UNITY",h:"けっそく　ＵＮＩＴＹ",e:"Unity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの守備力はターン終了時まで、自分フィールドの全ての表側表示モンスターの元々の守備力を合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・マジシャンズ・ナイト",h:"ぶらっく・まじしゃんず・ないと",e:"Dark Magician Knight",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2100,tx:"このカードは通常召喚できない。\n「騎士の称号」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"騎士の称号",h:"きしのしょうごう",e:"Knight's Title",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「ブラック・マジシャン」１体をリリースして発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャンズ・ナイト」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"賢者の宝石",h:"けんじゃのほうせき",e:"Sage's Stone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン・ガール」が存在する場合に発動できる。\n手札・デッキから「ブラック・マジシャン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガギゴ",h:"ががぎご",e:"Gagagigo",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1850,de:1000,tx:"かつては邪悪な心を持っていたが、ある人物に出会う事で正義の心に目覚めた悪魔の若者。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元トレーナー",h:"いじげんとれーなー",e:"D.D. Trainer",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:100,de:2000,tx:"異次元に吸い込まれてしまった哀れなゴブリン。\nしかし、今新たな目標に向かって日々努力している。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・グリーン",h:"おじゃま・ぐりーん",e:"Ojama Green",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"あらゆる手段を使ってジャマをすると言われているおジャマトリオの一員。\n三人揃うと何かが起こると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモン・ソルジャー",h:"でーもん・そるじゃー",e:"Archfiend Soldier",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1900,de:1500,tx:"デーモンの中でも精鋭だけを集めた部隊に所属する戦闘のエキスパート。\n与えられた任務を確実にこなす事で有名。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄の番熊",h:"じごくのばんぐま",e:"Pandemonium Watchbear",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"このカードが表側表示で自分フィールド上に存在する限り、自分フィールド上の「万魔殿－悪魔の巣窟－」は、相手がコントロールするカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"言語道断侍",h:"ごんごどうだんざむらい",e:"Sasuke Samurai #2",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"８００ライフポイントを払う。\nエンドフェイズまで、全ての魔法・罠カードは発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"黒蠍－強力のゴーグ",h:"くろさそり－ごうりきのごーぐ",e:"Dark Scorpion - Gorg the Strong",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●相手フィールド上のモンスターカード１枚を持ち主のデッキの一番上に戻す。\n\n●相手のデッキの一番上のカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"黒蠍－棘のミーネ",h:"くろさそり－いばらのみーね",e:"Dark Scorpion - Meanae the Thorn",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●「黒蠍」という名のついたカードを自分のデッキから１枚手札に加える。\n\n●「黒蠍」という名のついたカードを自分の墓地から１枚手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"迷犬マロン",h:"めいけんまろん",e:"Outstanding Dog Marron",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが墓地へ送られた時、このカードをデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"偉大魔獣 ガーゼット",h:"ぐれーとまじゅう　がーぜっと",e:"Great Maju Garzett",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃力は、生け贄召喚時に生け贄に捧げたモンスター１体の元々の攻撃力を倍にした数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"名工 虎鉄",h:"めいこう　こてつ",e:"Iron Blacksmith Kotetsu",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードがリバースした場合に発動する。\nデッキから装備魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲ゴブリン",h:"ごうよくごぶりん",e:"Goblin of Greed",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードが表側表示で自分フィールド上に存在する限り、お互いは「手札のカードを捨てる事によって発動する効果」を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄将軍・メフィスト",h:"へるじぇねらる・めふぃすと",e:"Mefist the Infernal General",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1700,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。\n相手に戦闘ダメージを与えた時、相手の手札からカードを１枚ランダムに捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルポーンデーモン",h:"へるぽーんでーもん",e:"Vilepawn Archfiend",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n３が出た場合、その効果を無効にし破壊する。\nこのカードがフィールド上に存在する限り、相手は自分フィールド上に存在する同名カード以外の「デーモン」という名のついたモンスターカードを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドウナイトデーモン",h:"しゃどうないとでーもん",e:"Shadowknight Archfiend",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に９００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n３が出た場合、その効果を無効にし破壊する。\nこのカードが相手プレイヤーに与えるダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークビショップデーモン",h:"だーくびしょっぷでーもん",e:"Darkbishop Archfiend",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:1400,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\n自分フィールド上に存在する「デーモン」という名のついたモンスターカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n１・３・６が出た場合、その効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デスルークデーモン",h:"でするーくでーもん",e:"Desrook Archfiend",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:1800,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n３が出た場合、その効果を無効にし破壊する。\n自分フィールド上の「ジェノサイドキングデーモン」が破壊され墓地に送られた時、このカードを手札から墓地に送る事で、その「ジェノサイドキングデーモン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノクインデーモン",h:"いんふぇるのくいんでーもん",e:"Infernalqueen Archfiend",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:900,de:1500,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n２・５が出た場合、その効果を無効にし破壊する。\nこのカードがフィールド上に存在する限り、スタンバイフェイズ毎に「デーモン」という名のついたモンスターカード１体の攻撃力をエンドフェイズまで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェノサイドキングデーモン",h:"じぇのさいどきんぐでーもん",e:"Terrorking Archfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"自分フィールド上に「デーモン」という名のついたモンスターカードが存在しなければこのカードは召喚・反転召喚できない。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に８００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n２・５が出た場合、その効果を無効にし破壊する。\nこのカードが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"迅雷の魔王－スカル・デーモン",h:"じんらいのまおう－すかる・でーもん",e:"Skull Archfiend of Lightning",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n１・３・６が出た場合、その効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル化寄生生物－ルナタイト",h:"めたるかきせいせいぶつ－るなたいと",e:"Metallizing Parasite - Lunatite",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["ユニオン","効果"],ma:"",tp:"",at:1000,de:500,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして、フィールド上のこのカードを自分フィールド上表側表示のモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\n装備モンスターは相手がコントロールする魔法の効果を受けなくなる。\n（１体のモンスターが装備できるユニオンは１枚まで、装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"月読命",h:"つくよみ",e:"Tsukuyomi",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:1100,de:1400,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースした場合、フィールドの表側表示モンスター１体を対象として発動する。\nそのモンスターを裏側守備表示にする。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の爆炎使い",h:"でんせつのふれいむ・ろーど",e:"Legendary Flame Lord",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「灼熱の試練」により降臨。\nフィールドか手札から、レベルが７以上になるようカードを生け贄に捧げなければならない。\n自分または相手が魔法を発動する度に、このカードに魔力カウンターを１個乗せる。\nこのカードの魔力カウンターを３個取り除く事で、このカードを除くフィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の支配者－ゾーク",h:"だーく・ますたー－ぞーく",e:"Dark Master - Zorc",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1500,tx:"「闇の支配者との契約」により降臨。\n①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の効果を適用する。\n\n●１・２：相手フィールドのモンスターを全て破壊する。\n\n●３・４・５：相手フィールドのモンスター１体を選んで破壊する。\n\n●６：自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"灼熱の試練",h:"しゃくねつのしれん",e:"Incandescent Ordeal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「伝説の爆炎使い」の降臨に必要。\nフィールドか手札から、レベルが７以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"奈落との契約",h:"ならくとのけいやく",e:"Contract with the Abyss",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"闇属性の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、手札から闇属性の儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の支配者との契約",h:"やみのしはいしゃとのけいやく",e:"Contract with the Dark Master",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「闇の支配者－ゾーク」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「闇の支配者－ゾーク」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"堕落",h:"ふぉーりん・だうん",e:"Falling Down",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"相手フィールドのモンスターに装備できる。\n①：装備モンスターのコントロールを得る。\n②：相手スタンバイフェイズに発動する。\n自分は８００ダメージを受ける。\n③：自分フィールドに「デーモン」カードが存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ディスカバード・アタック",h:"でぃすかばーど・あたっく",e:"Checkmate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「デーモン」という名のついたモンスター１体を生け贄に捧げる。\nこのターン自分フィールド上の「ジェノサイドキングデーモン」１体は相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉のカウントダウン",h:"しゅうえんのかうんとだうん",e:"Final Countdown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"２０００ライフポイントを払う。\n発動ターンより２０ターン後、自分はデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの宣告",h:"でーもんのせんこく",e:"Archfiend's Oath",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、５００LPを払い、カード名を１つ宣言して発動できる。\n自分のデッキの一番上のカードをめくり、宣言したカードだった場合、そのカードを手札に加える。\n違った場合、めくったカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"黒蠍団召集",h:"くろさそりだんしょうしゅう",e:"Mustering of the Dark Scorpions",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「首領・ザルーグ」が表側表示で存在する時に発動する事ができる。\n自分の手札から「黒蠍」という名のついたモンスターカードを全て特殊召喚する事ができる。\n（同名カードは１枚のみ）",li:"",lk:[],ta:[],al:[]},
  {n:"万魔殿－悪魔の巣窟－",h:"ぱんでぃもにうむ－あくまのそうくつ－",e:"Pandemonium",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「デーモン」という名のついたモンスターはスタンバイフェイズにライフを払わなくてよい。\n戦闘以外で「デーモン」という名のついたモンスターカードが破壊されて墓地に送られた時、そのカードのレベル未満の「デーモン」という名のついたモンスターカードをデッキから１枚選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"生贄の祭壇",h:"いけにえのさいだん",e:"Altar for Tribute",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターを１体選択して墓地に送る。\nこのモンスターの元々の攻撃力分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の氷結",h:"たましいのひょうけつ",e:"Frozen Soul",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが相手のライフポイントより２０００以上少ない時に発動する事ができる。\n相手の次のバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"血の刻印",h:"ちのこくいん",e:"Battle-Scarred",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在する「デーモン」という名のついたモンスター１体を選択して発動する。\n選択したモンスターがスタンバイフェイズに払うライフポイントは相手プレイヤーも払う。\nこのカードがフィールド上から離れた時、選択したモンスターを破壊する。\n選択したモンスターがフィールド上から離れた場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"必殺！黒蠍コンビネーション",h:"ひっさつ！くろさそりこんびねーしょん",e:"Dark Scorpion Combination",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「首領・ザルーグ」「黒蠍－罠はずしのクリフ」「黒蠍－逃げ足のチック」「黒蠍－強力のゴーグ」「黒蠍－棘のミーネ」が表側表示で存在する時に発動する事ができる。\nこれらのカードは発動ターンのみ相手プレイヤーに直接攻撃をする事ができる。\nその場合、相手プレイヤーに与える戦闘ダメージはそれぞれ４００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの雄叫び",h:"でーもんのおたけび",e:"Archfiend's Roar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払い発動する。\n自分の墓地から「デーモン」という名のついたモンスターカード１枚を自分のフィールド上に特殊召喚する。\nこのモンスターは、いかなる場合にも生け贄にする事はできず、このターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"リバースダイス",h:"りばーすだいす",e:"Dice Re-Roll",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、サイコロを振る効果を適用する際に１度だけ、サイコロを振り直す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"呪術抹消",h:"じゅじゅつまっしょう",e:"Spell Vanishing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札からカードを２枚捨てる。\n魔法カードの発動を無効にし、それを破壊する。\nさらに相手の手札とデッキを確認し、破壊した魔法カードと同名のカードがあった場合全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"炸裂装甲",h:"りあくてぃぶあーまー",e:"Sakuretsu Armor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時、攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"希望の光",h:"きぼうのひかり",e:"Ray of Hope",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の光属性モンスターカード２枚を自分のデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン・ナイトメア",h:"ぺんぎん・ないとめあ",e:"Nightmare Penguin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:1800,tx:"このカードがリバースした時、相手フィールド上のカード１枚を選択して持ち主の手札に戻す。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の水属性モンスターの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"パーフェクト機械王",h:"ぱーふぇくときかいおう",e:"Perfect Machine King",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:1500,tx:"フィールド上に存在するこのカード以外の機械族モンスター１体につき、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャイアント・レックス",h:"じゃいあんと・れっくす",e:"Giant Rex",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは直接攻撃できない。\n②：このカードが除外された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は、除外されている自分の恐竜族モンスターの数×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン魚雷",h:"ぺんぎんぎょらい",e:"Penguin Torpedo",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:550,de:300,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは直接攻撃できる。\n②：このカードが相手に戦闘ダメージを与えた時、相手フィールドのレベル６以下のモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターの効果は無効化され、攻撃宣言できない。\n③：このカードが攻撃したダメージステップ終了時に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"賢者ケイローン",h:"けんじゃけいろーん",e:"Chiron the Mage",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"１ターンに１度、手札から魔法カード１枚を捨てて発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・イエロー",h:"おじゃま・いえろー",e:"Ojama Yellow",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"あらゆる手段を使ってジャマをすると言われているおジャマトリオの一員。\n三人揃うと何かが起こると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・ブラック",h:"おじゃま・ぶらっく",e:"Ojama Black",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"あらゆる手段を使ってジャマをすると言われているおジャマトリオの一員。\n三人揃うと何かが起こると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"魂虎",h:"そうる・たいがー",e:"Soul Tiger",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"人の魂をむさぼると言われている恐ろしい虎の魂。\nできれば出会いたくない魂として有名。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグ・コアラ",h:"びっぐ・こあら",e:"Big Koala",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:2700,de:2000,tx:"とても巨大なデス・コアラの一種。\nおとなしい性格だが、非常に強力なパワーを持っているため恐れられている。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・カンガルー",h:"です・かんがるー",e:"Des Kangaroo",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1700,tx:"守備表示のこのカードを攻撃したモンスターの攻撃力がこのカードの守備力より低い場合、その攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"赤い忍者",h:"れっどにんじゃ",e:"Crimson Ninja",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードがリバースした場合、フィールドの表側表示の罠カード１枚またはフィールドにセットされた魔法・罠カード１枚を対象として発動する。\nその罠カードを破壊する（そのカードがフィールドにセットされている場合、めくって確認する）。",li:"",lk:[],ta:[],al:[]},
  {n:"速攻の黒い忍者",h:"そっこうのぶらっくにんじゃ",e:"Strike Ninja",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分の墓地から闇属性モンスター２体を除外して発動できる。\n表側表示のこのカードをエンドフェイズまで除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"爆風トカゲ",h:"ばくふうとかげ",e:"Gale Lizard",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:700,tx:"①：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲な壺の精霊",h:"ごうよくなつぼのせいれい",e:"Spirit of the Pot of Greed",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードがフィールド上に表側攻撃表示で存在する時に「強欲な壺」を発動した場合、「強欲な壺」を発動したプレイヤーはカードをもう１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"凶悪犯－チョップマン",h:"きょうあくはん－ちょっぷまん",e:"Chopman the Desperate Outlaw",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1100,de:500,tx:"このカードが反転召喚に成功した時、自分の墓地の装備魔法カード１枚をこのカードに装備する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"大盤振舞侍",h:"おおばんぶるまいざむらい",e:"Sasuke Samurai #3",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、相手プレイヤーは手札が７枚になるようにカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の偵察機",h:"いじげんのていさつき",e:"D.D. Scout Plane",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"①：このカードが除外されたターンのエンドフェイズに発動する。\n除外されているこのカードを攻撃表示で特殊召喚する（１ターンに１度のみ）。",li:"",lk:[],ta:[],al:[]},
  {n:"怒れる類人猿",h:"ばーさーくごりら",e:"Berserk Gorilla",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードのコントローラーは、このカードが攻撃可能な状態であれば必ず攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"放浪の勇者 フリード",h:"ほうろうのゆうしゃ　ふりーど",e:"Freed the Brave Wanderer",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"１ターンに１度、自分の墓地の光属性モンスター２体をゲームから除外して発動できる。\nこのカードの攻撃力より高い攻撃力を持つ、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鬼ゴブリン",h:"おにごぶりん",e:"Coach Goblin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分のエンドフェイズ時に手札から通常モンスターカード１枚をデッキの一番下に戻す事で、デッキからカードを１枚ドローする事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の呪術師",h:"こんとんのじゅじゅつし",e:"Witch Doctor of Chaos",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"リバース：自分または相手の墓地からモンスターカードを１枚選択する。\n選択したカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ネクロマンサー",h:"かおす・ねくろまんさー",e:"Chaos Necromancer",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃力は、自分の墓地に存在するモンスターカードの数×３００ポイントの数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオスライダー グスタフ",h:"かおすらいだー　ぐすたふ",e:"Chaosrider Gustaph",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"自分の墓地の魔法カードを２枚までゲームから除外する事ができる。\nこの効果によって除外したカード１枚につき、相手ターン終了時までこのカードの攻撃力は３００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノ",h:"いんふぇるの",e:"Inferno",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1100,de:1900,tx:"このカードは通常召喚できない。\n自分の墓地から炎属性モンスター１体を除外した場合に特殊召喚できる。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に１５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"フェンリル",h:"ふぇんりる",e:"Fenrir",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地の水属性モンスター２体をゲームから除外して特殊召喚する。\nこのカードが戦闘によって相手モンスターを破壊した場合、次の相手ターンのドローフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガンテス",h:"ぎがんてす",e:"Gigantes",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:1300,tx:"このカードは通常召喚できない。\n自分の墓地から地属性モンスター１体を除外した場合に特殊召喚できる。\n①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nフィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"シルフィード",h:"しるふぃーど",e:"Silpheed",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:700,tx:"このカードは通常召喚できない。\n自分の墓地の風属性モンスター１体をゲームから除外して特殊召喚する。\nこのカードが戦闘によって破壊され墓地に送られた時、相手はランダムに手札を１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ソーサラー",h:"かおす・そーさらー",e:"Chaos Sorcerer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\n①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを除外する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮魔獣 ダ・イーザ",h:"ぐれんまじゅう　だ・いーざ",e:"Gren Maju Da Eiza",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの攻撃力・守備力は、除外されている自分のカードの数×４００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ソルジャー －開闢の使者－",h:"かおす・そるじゃー　－かいびゃくのししゃ－",e:"Black Luster Soldier - Envoy of the Beginning",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\nこのカードの①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果を発動するターン、このカードは攻撃できない。\n②：このカードの攻撃で相手モンスターを破壊した時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"風魔手裏剣",h:"ふうましゅりけん",e:"Fuhma Shuriken",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「忍者」という名のついたモンスターのみ装備可能。\n装備モンスターは攻撃力が７００ポイントアップする。\nこのカードがフィールド上から墓地に送られた時、相手ライフに７００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"凡骨の意地",h:"ぼんこつのいじ",e:"Heart of the Underdog",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"ドローフェイズにドローしたカードが通常モンスターだった場合、そのカードを相手に見せる事で、自分はカードをもう１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"野性解放",h:"やせいかいほう",e:"Wild Nature's Release",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の獣族・獣戦士族モンスター１体を選択して発動できる。\n選択した獣族・獣戦士族モンスターの攻撃力は、そのモンスターの守備力分アップする。\nこの効果を受けたモンスターはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・デルタハリケーン！！",h:"おじゃま・でるたはりけーん！！",e:"Ojama Delta Hurricane!!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「おジャマ・グリーン」「おジャマ・イエロー」「おジャマ・ブラック」が表側表示で存在する場合に発動する事ができる。\n相手フィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"つまずき",h:"つまずき",e:"Stumbling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"召喚・反転召喚・特殊召喚に成功したモンスターは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・エンド",h:"かおす・えんど",e:"Chaos End",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のカードが７枚以上ゲームから除外されている場合に発動する事ができる。\nフィールド上に存在する全てのモンスターカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・グリード",h:"かおす・ぐりーど",e:"Chaos Greed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のカードが４枚以上ゲームから除外されており、自分の墓地にカードが存在しない場合に発動する事ができる。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の指名者",h:"いじげんのしめいしゃ",e:"D.D. Designator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"カード名を１つ宣言する。\n相手の手札を確認し、宣言したカードが相手の手札に存在する場合、そのカード１枚をゲームから除外する。\n宣言したカードが相手の手札に存在しなかった場合、自分の手札をランダムに１枚ゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の境界線",h:"いじげんのきょうかいせん",e:"D.D. Borderline",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地に魔法カードが存在しない場合、お互いのプレイヤーはバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"リサイクル",h:"りさいくる",e:"Recycle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分ターンのスタンバイフェイズ時に３００ライフポイントを払う事で、自分の墓地に存在するモンスター以外のカードを１枚選択してデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"原初の種",h:"げんしょのたね",e:"Primal Seed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「カオス・ソルジャー －開闢の使者－」または「混沌帝龍 －終焉の使者－」がフィールド上に存在する場合に発動する事ができる。\nゲームから除外された自分のカード２枚を自分の手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"サンダー・クラッシュ",h:"さんだー・くらっしゅ",e:"Thunder Crash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全てのモンスターを破壊する。\n相手ライフに破壊したモンスターの数×３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"次元の歪み",h:"じげんのひずみ",e:"Dimension Distortion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地にカードが存在しない場合に発動する事ができる。\n除外された自分のモンスター１体を選択し、自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リロード",h:"りろーど",e:"Reload",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の手札を全てデッキに加えてシャッフルする。\nその後、デッキに加えた枚数分のカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魂吸収",h:"たましいきゅうしゅう",e:"Soul Absorption",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーはカードがゲームから除外される度に、１枚につき５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"大火葬",h:"だいかそう",e:"Big Burn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が墓地のモンスターを対象にするカードを発動した時に発動する事ができる。\nお互いの墓地のモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"残骸爆破",h:"ざんがいばくは",e:"Blasting the Ruins",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地のカードが３０枚以上存在する場合に発動する事ができる。\n相手ライフに３０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"封魔の呪印",h:"ふうまのじゅいん",e:"Cursed Seal of the Forbidden Spell",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から魔法カードを１枚捨てる。\n魔法カードの発動と効果を無効にし、それを破壊する。\n相手はこのデュエル中、この効果で破壊された魔法カード及び同名カードを発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"バベル・タワー",h:"ばべる・たわー",e:"Tower of Babel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに４つ目の魔力カウンターが乗った時にこのカードを破壊し、その時に魔法カードを発動したプレイヤーは３０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"宇宙の収縮",h:"うちゅうのしゅうしゅく",e:"Spatial Collapse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"それぞれのフィールド上に存在しているカードが５枚以下の場合に発動する事ができる。\nお互いにフィールド上に出せるカードの合計枚数は５枚までになる。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖除外",h:"ちぇーん・ろすと",e:"Chain Disappearance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"攻撃力１０００以下のモンスターが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nその攻撃力１０００以下のモンスターをゲームから除外し、さらに除外したカードと同名カードを相手の手札・デッキから全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"重力解除",h:"じゅうりょくかいじょ",e:"Zero Gravity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分と相手フィールド上に表側表示で存在する全てのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"邪悪なるバリア －ダーク・フォース－",h:"じゃあくなるばりあ　－だーく・ふぉーす－",e:"Dark Mirror Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手フィールド上に守備表示で存在するモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"エナジー・ドレイン",h:"えなじー・どれいん",e:"Energy Drain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択する。\nそのモンスターの攻撃力・守備力は、エンドフェイズ時まで相手の手札の数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌帝龍 －終焉の使者－",h:"かおす・えんぺらー・どらごん　－しゅうえんのししゃ－",e:"Chaos Emperor Dragon - Envoy of the End",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合のみ特殊召喚できる。\nこのカードの効果を発動するターン、自分は他の効果を発動できない。\n①：１ターンに１度、１０００LPを払って発動できる。\nお互いの手札・フィールドのカードを全て墓地へ送る。\nその後、この効果で相手の墓地へ送ったカードの数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴィクトリー・ドラゴン",h:"う゛ぃくとりー・どらごん",e:"Victory Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:3000,tx:"このカードは特殊召喚できない。\n自分フィールド上のドラゴン族モンスター３体を生け贄にして生け贄召喚しなければならない。\nこのカードの直接攻撃によって相手ライフを０にした場合、このカードのコントローラーはマッチに勝利する。",li:0,lk:[],ta:[],al:[]},
  {n:"マジシャンズ・ヴァルキリア",h:"まじしゃんず・う゛ぁるきりあ",e:"Magician's Valkyria",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、相手は他の魔法使い族モンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガ・ガガギゴ",h:"ぎが・ががぎご",e:"Giga Gagagigo",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:2450,de:1500,tx:"強大な悪に立ち向かうため、様々な肉体改造をほどこした結果恐るべきパワーを手に入れたが、その代償として正義の心を失ってしまった。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の狂犬",h:"あんこくのまっどどっぐ",e:"Mad Dog of Darkness",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1900,de:1400,tx:"かつては公園で遊ぶ普通の犬だったが、暗黒の力により凶暴化してしまった。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオバグ",h:"ねおばぐ",e:"Neo Bug",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"異星から来たと言われる巨大な昆虫タイプのモンスター。\n集団で行動してターゲットをとらえる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の海竜兵",h:"あんこくのかいりゅうへい",e:"Sea Serpent Warrior of Darkness",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"暗黒海の世界を守る戦士。\n水中はもちろん、陸上でも高い戦闘能力を誇る。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェノサイドキングサーモン",h:"じぇのさいどきんぐさーもん",e:"Terrorking Salmon",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:2400,de:1000,tx:"暗黒海の主として恐れられている巨大なシャケ。\nその卵は暗黒界一の美味として知られている。",li:"",lk:[],ta:[],al:[]},
  {n:"火炎木人１８",h:"かえんもくじんいんぱち",e:"Blazing Inpachi",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1850,de:0,tx:"全身が灼熱の炎に包まれた巨木の化身。\n炎の攻撃は強力だが、自身が燃えているため先は長くない。",li:"",lk:[],ta:[],al:[]},
  {n:"燃える藻",h:"もえるも",e:"Burning Algae",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードが墓地に送られた時、相手は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"火口に潜む者",h:"かこうにひそむもの",e:"The Thing in the Crater",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードが破壊されフィールド上から墓地に送られた時、手札から炎族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"灼熱ゾンビ",h:"しゃくねつぞんび",e:"Molten Zombie",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが墓地からの特殊召喚に成功した時、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の黒魔術師",h:"こんとんのくろまじゅつし",e:"Dark Magician of Chaos",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功したターンのエンドフェイズに、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが戦闘で相手モンスターを破壊したダメージ計算後に発動する。\nその相手モンスターを除外する。\n③：表側表示のこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒のマンティコア",h:"あんこくのまんてぃこあ",e:"Manticore of Darkness",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"このカードが墓地へ送られたターンのエンドフェイズ時、自分の手札・フィールド上から獣族・獣戦士族・鳥獣族モンスター１体を墓地へ送って発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ステルスバード",h:"すてるすばーど",e:"Stealth Bird",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:700,de:1700,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖鳥クレイン",h:"せいちょうくれいん",e:"Sacred Crane",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"激昂のミノタウルス",h:"げきこうのみのたうるす",e:"Enraged Battle Ox",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、自分の獣族・獣戦士族・鳥獣族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"首領亀",h:"どんがめ",e:"Don Turtle",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカードが召喚・反転召喚に成功した時、自分の手札から「首領亀」を任意の枚数特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バルーン・リザード",h:"ばるーん・りざーど",e:"Balloon Lizard",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:500,de:1900,tx:"自分のスタンバイフェイズ毎にこのカードにカウンターを１個乗せる。\nこのカードを破壊したカードのコントローラーにカウンターの数×４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒ドリケラトプス",h:"だーくどりけらとぷす",e:"Dark Driceratops",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードが守備表示モンスターを攻撃した時、このカードの攻撃力が守備表示モンスターの守備力を越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイパーハンマーヘッド",h:"はいぱーはんまーへっど",e:"Hyper Hammerhead",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターとの戦闘で破壊されなかった相手モンスターは、ダメージステップ終了時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒恐獣",h:"ぶらっく・てぃらの",e:"Black Tyranno",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"相手フィールド上に守備表示モンスターしか存在しない場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"対空放花",h:"たいくうほうか",e:"Anti-Aircraft Flower",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"自分フィールド上に存在する昆虫族モンスター１体を生け贄に捧げる度に、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"棘の妖精",h:"いばらのようせい",e:"Prickle Fairy",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:2000,tx:"相手はこのカードを破壊しない限り、昆虫族モンスターを攻撃できない。\nこのカードと戦闘を行ったモンスターは、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"インセクト・プリンセス",h:"いんせくと・ぷりんせす",e:"Insect Princess",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、相手フィールド上に表側表示で存在する昆虫族モンスターは全て攻撃表示になる。\nこのカードが戦闘によって昆虫族モンスターを破壊する度に、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"水陸両用バグロス Mk－３",h:"すいりくりょうようばぐろす　Ｍｋ－３",e:"Amphibious Bugroth MK-3",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"「海」がフィールド上に存在する限り、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魚雷魚",h:"ぎょらいぎょ",e:"Torpedo Fish",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「海」がフィールド上に存在する限り、このカードは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"海竜－ダイダロス",h:"りばいあどらごん－だいだろす",e:"Levia-Dragon - Daedalus",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1500,tx:"自分フィールド上に存在する「海」を墓地に送る事で、このカード以外のフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒大要塞鯱",h:"あんこくだいようさいじゃち",e:"Orca Mega-Fortress of Darkness",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"自分フィールド上の「魚雷魚」１体を生け贄に捧げる事で、フィールド上のモンスター１体を破壊する。\n自分フィールド上の「砲弾ヤリ貝」１体を生け贄に捧げる事で、フィールド上の魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"砲弾ヤリ貝",h:"ほうだんやりがい",e:"Cannonball Spear Shellfish",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「海」がフィールド上に存在する限り、このカードは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"不意打ち又佐",h:"ふいうちまたざ",e:"Mataza the Zapper",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードは表側表示でフィールド上に存在する限り、コントロールを変更する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"守護天使 ジャンヌ",h:"がーでぃあんえんじぇる　じゃんぬ",e:"Guardian Angel Joan",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"マンジュ・ゴッド",h:"まんじゅ・ごっど",e:"Manju of the Ten Thousand Hands",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\nデッキから儀式モンスター１体または儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"月風魔",h:"げつふうま",e:"Getsu Fuhma",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードと戦闘を行ったモンスターが悪魔族・アンデット族の場合、ダメージステップ終了時にそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"龍骨鬼",h:"りゅうこつき",e:"Ryu Kokki",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードと戦闘を行ったモンスターが戦士族・魔法使い族の場合、ダメージステップ終了時にそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"グリフォンの羽根帚",h:"ぐりふぉんのはねぼうき",e:"Gryphon's Feather Duster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全ての魔法・罠カードを破壊する。\n自分は破壊したカードの数×５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"迷える仔羊",h:"まよえるこひつじ",e:"Stray Lambs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・反転召喚・特殊召喚できない。\n①：自分フィールドに「仔羊トークン」（獣族・地・星１・攻／守０）２体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"地砕き",h:"じくだき",e:"Smashing Ground",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの守備力が一番高いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"次元融合",h:"じげんゆうごう",e:"Dimension Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"２０００ライフポイントを払う。\nお互いに除外されたモンスターをそれぞれのフィールド上に可能な限り特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"光と闇の洗礼",h:"ひかりとやみのせんれい",e:"Dedication through Light and Darkness",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「ブラック・マジシャン」１体をリリースして発動できる。\n自分の手札・デッキ・墓地から「混沌の黒魔術師」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サルベージ",h:"さるべーじ",e:"Salvage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の攻撃力１５００以下の水属性モンスター２体を対象として発動できる。\nその水属性モンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超進化薬",h:"ちょうしんかやく",e:"Ultra Evolution Pill",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の爬虫類族モンスター１体を生け贄に捧げる。\n手札から恐竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"大地讃頌",h:"だいちさんしょう",e:"Earth Chant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"地属性の儀式モンスターの降臨に使用する事ができる。\nフィールドか手札から、儀式召喚する地属性モンスターと同じレベルになるように生け贄を捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"翡翠の蟲笛",h:"ひすいのむしぶえ",e:"Jade Insect Whistle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手プレイヤーはデッキから昆虫族モンスター１体を選択し、デッキをシャッフルした後そのカードをデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊指輪",h:"はかいりんぐ",e:"Destruction Ring",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の表側表示モンスター１体を破壊し、お互いに１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔の手鏡",h:"あくまのてかがみ",e:"Fiend's Hand Mirror",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の魔法・罠カード１枚を対象に発動した相手の魔法を、別の正しい対象に移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"強制脱出装置",h:"きょうせいだっしゅつそうち",e:"Compulsory Evacuation Device",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー見参",h:"ひーろーけんざん",e:"A Hero Emerges",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n自分の手札１枚を相手がランダムに選ぶ。\nそれがモンスターだった場合、自分フィールドに特殊召喚し、違った場合は墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"自爆スイッチ",h:"じばくすいっち",e:"Self-Destruct Button",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが相手より７０００以上少ない時に発動する事ができる。\nお互いのライフポイントは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の呪縛",h:"あんこくのじゅばく",e:"Curse of Darkness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分または相手が魔法カードを発動する度にその発動したプレイヤーに１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"門前払い",h:"もんぜんばらい",e:"Begone, Knave!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが既に魔法＆罠ゾーンに存在する状態で、モンスターがプレイヤーに戦闘ダメージを与えた場合に発動する。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"DNA移植手術",h:"ＤＮＡいしょくしゅじゅつ",e:"DNA Transplant",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"発動時に１種類の属性を宣言する。\nこのカードがフィールド上に存在する限り、フィールド上の全ての表側表示モンスターは自分が宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"追い剥ぎゾンビ",h:"おいはぎぞんび",e:"Robbin' Zombie",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上のモンスターが相手プレイヤーに戦闘ダメージを与える度に、相手はデッキの一番上のカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"トラップ・ジャマー",h:"とらっぷ・じゃまー",e:"Trap Jammer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n相手が発動した罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の侵略者",h:"あんこくのしんりゃくしゃ",e:"Invader of Darkness",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2900,de:2500,tx:"このカードがフィールド上に表側表示で存在する限り、相手は速攻魔法カードを発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ツインヘデッド・ビースト",h:"ついんへでっど・びーすと",e:"Twinheaded Beast",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1900,tx:"①：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィングド・ライノ",h:"うぃんぐど・らいの",e:"Winged Rhynos",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"罠カードが発動した時に発動する事ができる。\nフィールド上に表側表示で存在するこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"Ulevo",h:"Ulevo",e:"Ulevo",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by offering 3 Winged Beast-Type monsters on your side of the field as Tributes. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイン・クラッシャー",h:"ぶれいん・くらっしゃー",e:"Brain Crusher",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った場合、破壊したモンスター１体をそのターンのエンドフェイズ時に墓地から特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アビス・ソルジャー",h:"あびす・そるじゃー",e:"Abyss Soldier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"１ターンに１度、手札から水属性モンスター１体を墓地へ捨て、フィールド上のカード１枚を選択して発動できる。\n選択したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"バイトロン",h:"ばいとろん",e:"Bitelon",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"Meteo the Matchless",h:"Meteo the Matchless",e:"Meteo the Matchless",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by offering 3 Beast-Warrior-Type monsters on your side of the field as Tributes. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノ・ハンマー",h:"いんふぇるの・はんまー",e:"Inferno Hammer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"エメス・ザ・インフィニティ",h:"えめす・ざ・いんふぃにてぃ",e:"Emes the Infinity",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\nこのカードの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"D.D.アサイラント",h:"でぃー．でぃー．あさいらんと",e:"D.D. Assailant",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"①：このカードが相手モンスターとの戦闘で破壊されたダメージ計算後に発動する。\nその戦闘を行ったそれぞれのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"テーヴァ",h:"てーう゛ぁ",e:"Teva",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"①：このカードがアドバンス召喚に成功した場合に発動する。\n次の相手ターン、相手は攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影のゴラ亀",h:"げんえいのごらがめ",e:"Gora Turtle of Illusion",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"フィールド上に表側表示で存在するこのカードを対象とした、相手がコントロールする魔法・罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"死霊ゾーマ",h:"しりょうぞーま",e:"Zoma the Spirit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（アンデット族・闇・星４・攻１８００／守５００）となり、モンスターゾーンに守備表示で特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚したこのカードが戦闘で破壊された場合に発動する。\nこのカードを破壊したモンスターの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マキシマム・シックス",h:"まきしまむ・しっくす",e:"Maximum Six",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードが生け贄召喚に成功した時、サイコロを１回振る。\nこのカードの攻撃力は、フィールド上に表側表示で存在する限り、出た目×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デンジャラスマシン TYPE－６",h:"でんじゃらすましん　たいぷ－しっくす",e:"Dangerous Machine Type-6",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ毎にサイコロを１回振る。\n出た目の効果を適用する。\n１・自分は手札を１枚捨てる。\n２・相手は手札を１枚捨てる。\n３・自分はカードを１枚ドローする。\n４・相手はカードを１枚ドローする。\n５・相手フィールド上モンスター１体を破壊する。\n６・このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"第六感",h:"だいろっかん",e:"Sixth Sense",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分は１から６までの数字の内２つを宣言する。\n相手がサイコロを１回振り、宣言した数字の内どちらか１つが出た場合、その枚数自分はカードをドローする。\nハズレの場合、出た目の枚数デッキの上からカードを墓地へ送る。",li:0,lk:[],ta:[],al:[]},
  {n:"ゴギガ・ガガギゴ",h:"ごぎが・ががぎご",e:"Gogiga Gagagigo",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:2950,de:2800,tx:"既に精神は崩壊し、肉体は更なるパワーを求めて暴走する。\nその姿にかつての面影はない・・・。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼラの戦士",h:"ぜらのせんし",e:"Warrior of Zera",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:1600,tx:"大天使の力を手に入れる事ができるという聖域を探し求める戦士。\n邪悪な魔族からの誘惑から逃れるため、孤独な闘いの日々を送る。",li:"",lk:[],ta:[],al:[]},
  {n:"封印師 メイセイ",h:"ふういんし　めいせい",e:"Sealmaster Meisei",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1100,de:900,tx:"封印の呪符を使いこなす事ができる数少ない人物。\nその経歴は未だ謎に包まれている。",li:"",lk:[],ta:[],al:[]},
  {n:"神聖なる球体",h:"ほーりーしゃいん・ぼーる",e:"Mystical Shine Ball",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"聖なる輝きに包まれた天使の魂。\nその美しい姿を見た者は、願い事がかなうと言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄鋼装甲虫",h:"めたるあーまーどばぐ",e:"Metal Armored Bug",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:2800,de:1500,tx:"全身が分厚い装甲で覆われている巨大な昆虫型生物。\n行く手を妨げるものは容赦なく破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"裁きの代行者 サターン",h:"さばきのだいこうしゃ　さたーん",e:"The Agent of Judgment - Saturn",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"①：このカードをリリースして発動できる。\n自分のLPが相手より多い場合、その差の数値分のダメージを相手に与える。\nこの効果は自分フィールドに「天空の聖域」が存在する場合に発動と処理ができる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"英知の代行者 マーキュリー",h:"えいちのだいこうしゃ　まーきゅりー",e:"The Agent of Wisdom - Mercury",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1700,tx:"①：相手ターン終了時に、このカードが自分のモンスターゾーンに表側表示で存在し、自分の手札が０枚の場合、次の自分スタンバイフェイズに発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"創造の代行者 ヴィーナス",h:"そうぞうのだいこうしゃ　う゛ぃーなす",e:"The Agent of Creation - Venus",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：５００LPを払って発動できる。\n手札・デッキから「神聖なる球体」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"力の代行者 マーズ",h:"ちからのだいこうしゃ　まーず",e:"The Agent of Force - Mars",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：フィールドのこのカードは魔法カードの効果を受けない。\n②：自分フィールドに「天空の聖域」が存在し、自分のLPが相手より多い場合、その差の数値分このカードの攻撃力・守備力はアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"薄幸の乙女",h:"はっこうのおとめ",e:"The Unhappy Girl",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードは表側攻撃表示で存在する限り戦闘によっては破壊されない。\nこのカードが表側攻撃表示でフィールド上に存在する限り、このカードと戦闘を行ったモンスターは表示形式の変更と攻撃ができなくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"精気を吸う骨の塔",h:"せいきをすうぼーんたわー",e:"Soul-Absorbing Bone Tower",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:400,de:1500,tx:"自分フィールド上にこのカード以外のアンデット族モンスターが存在する場合、このカードを攻撃する事はできない。\nアンデット族モンスターが特殊召喚に成功する度に、相手のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ザ・キックマン",h:"ざ・きっくまん",e:"The Kick Man",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1300,de:300,tx:"このカードが特殊召喚に成功した時、自分の墓地の装備魔法カード１枚をこのカードに装備する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・レディ",h:"う゛ぁんぱいあ・れでぃ",e:"Vampire Lady",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1550,de:1550,tx:"このカードが相手プレイヤーに戦闘ダメージを与える度に、カードの種類（モンスター、魔法、罠）を宣言する。\n相手はデッキからその種類のカード１枚を選択して墓地に送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ロケット・ジャンパー",h:"ろけっと・じゃんぱー",e:"Rocket Jumper",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"相手フィールド上に守備表示モンスターしか存在しない場合、このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"壺魔神",h:"つぼましん",e:"Avatar of The Pot",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:1300,tx:"手札から「強欲な壺」１枚を墓地へ送る。\n自分のデッキからカードを３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の柔術家",h:"でんせつのじゅうじゅつか",e:"Legendary Jujitsu Master",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"フィールド上に守備表示で存在するこのカードと戦闘を行ったモンスターは、ダメージステップ終了時に持ち主のデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"KA－２ デス・シザース",h:"ＫＡ－２　です・しざーす",e:"KA-2 Des Scissors",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターのレベル×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ニードルバンカー",h:"にーどるばんかー",e:"Needle Burrower",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1700,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターのレベル×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブローバック・ドラゴン",h:"ぶろーばっく・どらごん",e:"Blowback Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1200,tx:"①：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\nコイントスを３回行い、その内２回以上が表だった場合、その相手のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"雷帝ザボルグ",h:"らいていざぼるぐ",e:"Zaborg the Thunder Monarch",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"原子ホタル",h:"げんしほたる",e:"Atomic Firefly",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"フィールド上に表側表示で存在するこのカードを戦闘で破壊したプレイヤーは、１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"マーメイド・ナイト",h:"まーめいど・ないと",e:"Mermaid Knight",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"「海」がフィールド上に存在する限り、このカードは一度のバトルフェイズ中に２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"軍隊ピラニア",h:"ぐんたいぴらにあ",e:"Piranha Army",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:200,tx:"このカードが相手プレイヤーへの直接攻撃で与える戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"針二千本",h:"つーさうざんど・にーどる",e:"Two Thousand Needles",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"守備表示のこのカードが攻撃を受けた時、このカードの守備力が相手攻撃モンスターの攻撃力を越えていれば、ダメージ計算後にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"円盤闘士",h:"でぃすくふぁいたー",e:"Disc Fighter",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが守備力２０００以上の守備表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"深緑の魔弓使い",h:"しんりょくのまきゅうつかい",e:"Arcane Archer of the Forest",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"自分フィールド上に植物族モンスターが存在する場合、このカードを攻撃する事はできない。\n自分フィールド上の植物族モンスター１体を生け贄に捧げる度に、フィールド上に存在する魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"女忍者ヤエ",h:"おんなにんじゃやえ",e:"Lady Ninja Yae",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:200,tx:"手札から風属性モンスター１枚を墓地に捨てる。\n相手フィールド上に存在する魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"キングゴブリン",h:"きんぐごぶりん",e:"Goblin King",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上にこのカード以外の悪魔族モンスターが存在する場合、このカードを攻撃する事はできない。\nこのカードの攻撃力・守備力は、フィールド上のこのカード以外の悪魔族モンスターの数×１０００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"プロミネンス・ドラゴン",h:"ぷろみねんす・どらごん",e:"Solar Flare Dragon",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上にこのカード以外の炎族モンスターが存在する場合、このカードを攻撃する事はできない。\n自分のターンのエンドフェイズ時、このカードは相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"白魔導士ピケル",h:"しろまどうしぴける",e:"White Magician Pikeru",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"自分のスタンバイフェイズ時、自分フィールド上に存在するモンスターの数×４００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"大天使ゼラート",h:"だいてんしぜらーと",e:"Archlord Zerato",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2300,tx:"このカードは通常召喚できない。\nこのカードは「天空の聖域」がフィールド上に存在し、自分フィールド上に表側表示で存在する「ゼラの戦士」１体を生け贄に捧げた場合のみ特殊召喚できる。\n光属性のモンスターカード１枚を手札から墓地に捨てる事で、相手フィールド上に存在する全てのモンスターを破壊する。\nこの効果は自分フィールド上に「天空の聖域」が存在しなければ適用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"光学迷彩アーマー",h:"こうがくめいさいあーまー",e:"Opti-Camouflage Armor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル１のモンスターのみ装備可能。\n装備モンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"神秘の中華なべ",h:"しんぴのちゅうかなべ",e:"Mystik Wok",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体を生け贄に捧げる。\n生け贄に捧げたモンスターの攻撃力か守備力を選択し、その数値だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"滅びの爆裂疾風弾",h:"ほろびのばーすとすとりーむ",e:"Burst Stream of Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分の「青眼の白龍」は攻撃できない。\n①：自分フィールドに「青眼の白龍」が存在する場合に発動できる。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスターゲート",h:"もんすたーげーと",e:"Monster Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースして発動できる。\n通常召喚可能なモンスターが出るまで自分のデッキの上からカードをめくり、そのモンスターを特殊召喚する。\n残りのめくったカードは全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ウェポンチェンジ",h:"うぇぽんちぇんじ",e:"Weapon Change",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度だけ自分のスタンバイフェイズに７００ライフポイントを払って発動する事ができる。\n自分フィールド上の戦士族か機械族モンスター１体の攻撃力と守備力を次の相手のエンドフェイズ終了時まで入れ替える。\nこのカードが破壊された時、その効果は無効になる。",li:"",lk:[],ta:[],al:[]},
  {n:"天空の聖域",h:"てんくうのせいいき",e:"The Sanctuary in the Sky",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、天使族モンスターの戦闘で発生するそのコントローラーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"アースクエイク",h:"あーすくえいく",e:"Earthquake",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスターを全て守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"罠封印の呪符",h:"わなふういんのじゅふ",e:"Talisman of Trap Sealing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「封印師 メイセイ」が自分フィールド上に存在している時のみ発動する事ができる。\nこのカードがフィールド上に存在する限り罠カードは発動できず、全てのフィールド上罠カードの効果は無効になる。\n「封印師 メイセイ」が自分フィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"盗人ゴブリン",h:"ぬすっとごぶりん",e:"Goblin Thief",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手に５００ダメージを与える。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"バックファイア",h:"ばっくふぁいあ",e:"Backfire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの表側表示の炎属性モンスターが破壊され墓地へ送られる度に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミクロ光線",h:"みくろこうせん",e:"Micro Ray",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体の守備力は、エンドフェイズ終了時まで０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"裁きの光",h:"さばきのひかり",e:"Light of Judgment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドに「天空の聖域」が存在する場合に手札から光属性モンスター１体を墓地へ捨てて発動できる。\n以下の効果から１つを選んで適用する。\n\n●相手の手札を確認し、その中からカード１枚を選んで墓地へ送る。\n\n●相手フィールドのカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法封印の呪符",h:"まほうふういんのじゅふ",e:"Talisman of Spell Sealing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「封印師 メイセイ」が自分フィールド上に存在している時のみ発動する事ができる。\nこのカードがフィールド上に存在する限り魔法カードは発動できず、全てのフィールド上魔法カードの効果は無効になる。\n「封印師 メイセイ」が自分フィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"光の護封壁",h:"ひかりのごふうへき",e:"Wall of Revealing Light",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１０００の倍数のライフポイントを払って発動する。\nこのカードがフィールド上に存在する限り、払った数値以下の攻撃力を持つ相手モンスターは攻撃をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ソーラーレイ",h:"そーらーれい",e:"Solar Ray",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する光属性モンスターの数×６００ポイントダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"忍法 変化の術",h:"にんぽう　へんげのじゅつ",e:"Ninjitsu Art of Transformation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの表側表示の「忍者」モンスター１体をリリースしてこのカードを発動できる。\nリリースしたモンスターのレベル＋３以下のレベルを持つ獣族・鳥獣族・昆虫族モンスター１体を、手札・デッキから特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"光の召集",h:"ひかりのしょうしゅう",e:"Beckoning Light",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札を全て捨てる。\nその後、この効果で墓地へ捨てられたカードの数だけ、自分の墓地から光属性モンスターを選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレインシールド",h:"どれいんしーるど",e:"Draining Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時、攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターの攻撃を無効にし、そのモンスターの攻撃力分だけ自分はLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマーブレイク",h:"あーまーぶれいく",e:"Armor Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"装備魔法カードの発動を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デビルマゼラ",h:"でびるまぜら",e:"Mazera DeVille",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2300,tx:"このカードは通常召喚できない。\nこのカードは「万魔殿－悪魔の巣窟－」がフィールド上に存在し、自分フィールド上に表側表示で存在する「ゼラの戦士」１体を生け贄に捧げた場合のみ特殊召喚できる。\nこのカードが特殊召喚に成功した場合、相手はランダムに手札を３枚捨てる。\nこの効果は自分フィールド上に「万魔殿－悪魔の巣窟－」が存在しなければ適用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マシュマロン",h:"ましゅまろん",e:"Marshmallon",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"①：このカードは戦闘では破壊されない。\n②：裏側表示のこのカードが攻撃されたダメージ計算後に発動する。\nこのカードを攻撃したモンスターのコントローラーは１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"死霊騎士デスカリバー・ナイト",h:"しりょうきしですかりばー・ないと",e:"Doomcaliber Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1900,de:1800,tx:"このカードは特殊召喚できない。\n①：モンスターの効果が発動した時、このカードをリリースして発動する。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"シールドクラッシュ",h:"しーるどくらっしゅ",e:"Shield Crush",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの守備表示モンスター１体を対象として発動できる。\nその守備表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヂェミナイ・デビル",h:"ぢぇみない・でびる",e:"Gemini Imps",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分の手札を捨てる効果を含むカードを相手が発動した時、このカードを手札から墓地に送る事でその発動と効果を無効にし、そのカードを破壊した後自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"リターン・ゾンビ",h:"りたーん・ぞんび",e:"Return Zombie",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"自分のスタンバイフェイズ時、このカードが墓地に存在し自分の手札が０枚だった場合、５００ライフポイントを払う事でこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"八汰烏の骸",h:"やたがらすのむくろ",e:"Legacy of Yata-Garasu",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"次の効果から１つを選択して発動する。\n\n●自分のデッキからカードを１枚ドローする。\n\n●相手フィールド上にスピリットモンスターが表側表示で存在する場合に発動する事ができる。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"マシュマロンのメガネ",h:"ましゅまろんのめがね",e:"Marshmallon Glasses",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のモンスターカードゾーン上に「マシュマロン」が存在する限り、相手は「マシュマロン」以外を攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛霊の誘い",h:"じばくれいのいざない",e:"Call of the Earthbound",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\nその攻撃対象を自分が選択し直す。",li:"",lk:[],ta:[],al:[]},
  {n:"もけもけ",h:"もけもけ",e:"Mokey Mokey",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:300,de:100,tx:"何を考えているのかさっぱりわからない天使のはみだし者。\nたまに怒ると怖い。",li:"",lk:[],ta:[],al:[]},
  {n:"ギゴバイト",h:"ぎごばいと",e:"Gigobyte",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:350,de:300,tx:"今はまだおだやかな心を持っているが、邪悪な心に染まる運命を背負っている・・・。",li:"",lk:[],ta:[],al:[]},
  {n:"コザッキー",h:"こざっきー",e:"Kozaky",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:400,de:400,tx:"魔界言語の研究に全てを捧げているモーレツ悪魔。\n働きすぎで精神が崩壊している。",li:"",lk:[],ta:[],al:[]},
  {n:"デビルスコーピオン",h:"でびるすこーぴおん",e:"Fiend Scorpion",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:900,de:200,tx:"悪魔の魂が宿る巨大なサソリ。\n普段は力を温存しているが、潜在能力は高い。",li:"",lk:[],ta:[],al:[]},
  {n:"ファラオのしもべ",h:"ふぁらおのしもべ",e:"Pharaoh's Servant",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:0,tx:"かつてファラオに仕えたといわれている者たちの亡霊。\n揺らぐ事のない忠誠心を持っている。",li:"",lk:[],ta:[],al:[]},
  {n:"王家の守護者",h:"おうけのしゅごしゃ",e:"Pharaonic Protector",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:0,tx:"何千年もの間王家を守り続けている兵士のミイラ。\nその魂は今も侵入者を許す事はない。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリッツ・オブ・ファラオ",h:"すぴりっつ・おぶ・ふぁらお",e:"Spirit of the Pharaoh",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは通常召喚できない。\n「第一の棺」の効果でのみ特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、自分の墓地からレベル２以下のアンデット族通常モンスターを４体まで特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイトメアテーベ",h:"ないとめあてーべ",e:"Theban Nightmare",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"①：自分の手札と自分の魔法＆罠ゾーンにカードが存在しない場合、このカードの攻撃力は１５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アスワンの亡霊",h:"あすわんのぼうれい",e:"Aswan Apparition",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた場合、自分の墓地の罠カード１枚をデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"神殿を守る者",h:"しんでんをまもるもの",e:"Protector of the Sanctuary",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、相手プレイヤーはドローフェイズ以外ではカードをドローする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヌビアガード",h:"ぬびあがーど",e:"Nubian Guard",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた場合、自分の墓地の永続魔法カード１枚をデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レガシーハンター",h:"れがしーはんたー",e:"Legacy Hunter",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードが戦闘によって裏側守備表示モンスターを破壊し墓地へ送った時、相手はランダムに手札を１枚デッキに戻す。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"砂バク",h:"さばく",e:"Desertapir",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:300,tx:"①：このカードがリバースした場合、「砂バク」以外のフィールドの表側表示モンスター１体を対象として発動する。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"サンド・ギャンブラー",h:"さんど・ぎゃんぶらー",e:"Sand Gambler",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:1600,tx:"コイントスを３回行う。\n３回とも表だった場合、相手フィールド上モンスターを全て破壊する。\n３回とも裏だった場合、自分フィールド上モンスターを全て破壊する。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"みつこぶラクーダ",h:"みつこぶらくーだ",e:"3-Hump Lacooda",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"「みつこぶラクーダ」が自分フィールド上に表側表示で３体存在する場合、その内２体を生け贄に捧げる事でカードを３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャッカルの霊騎士",h:"じゃっかるのれいきし",e:"Ghost Knight of Jackal",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"このカードが戦闘によって破壊し墓地に送った相手モンスター１体を、自分フィールド上に表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"吸収天児",h:"きゅうしゅうてんじ",e:"Absorbing Kid from the Sky",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、自分は破壊したモンスターのレベル×３００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"恵みの像",h:"めぐみのぞう",e:"Elephant Statue of Blessing",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手がコントロールするカードの効果によって、このカードが手札から墓地に送られた時、自分は２０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"災いの像",h:"わざわいのぞう",e:"Elephant Statue of Disaster",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手がコントロールするカードの効果によって、このカードが手札から墓地に送られた時、相手ライフに２０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魂を呼ぶ者",h:"たましいをよぶもの",e:"Spirit Caller",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1000,tx:"リバース：自分の墓地からレベル３以下の通常モンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界の使者",h:"めいかいのししゃ",e:"Emissary of the Afterlife",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"このカードがフィールド上から墓地に送られた時、お互いに自分のデッキからレベル３以下の通常モンスター１体を選択し、お互いに確認して手札に加える。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイヴ・キーパー",h:"ぐれいう゛・きーぱー",e:"Grave Protector",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、戦闘によって破壊されたモンスターは墓地へは行かず、持ち主のデッキに戻る。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブルコストン",h:"だぶるこすとん",e:"Double Coston",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:1650,tx:"①：闇属性モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[]},
  {n:"再生ミイラ",h:"さいせいみいら",e:"Regenerating Mummy",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"相手のカードの効果によって、このカードが手札から墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"深淵の暗殺者",h:"ないと・あさしん",e:"Night Assailant",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:500,tx:"リバース：相手フィールド上に存在するモンスター１体を選択して破壊する。\nまた、このカードが手札から墓地へ送られた時、自分の墓地に存在するリバース効果モンスター１体を手札に戻す。",li:1,lk:[],ta:[],al:[]},
  {n:"人投げトロール",h:"ひとなげとろーる",e:"Man-Thro' Tro'",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分フィールド上に存在する通常モンスター（トークンを除く）１体を生け贄に捧げる度に、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"沼地の魔神王",h:"ぬまちのましんおう",e:"King of the Swamp",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:1100,tx:"①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：自分メインフェイズにこのカードを手札から墓地へ捨てて発動できる。\nデッキから「融合」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オアシスの使者",h:"おあしすのししゃ",e:"Emissary of the Oasis",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:400,tx:"自分フィールド上にレベル３以下の通常モンスターが表側表示で存在する限り、相手はこのカードを攻撃対象に選択できない。\nこのカードが自分フィールド上に存在する限り、レベル３以下の通常モンスターの戦闘によって発生するこのカードのコントローラーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スペシャルハリケーン",h:"すぺしゃるはりけーん",e:"Special Hurricane",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札を１枚捨てる。\nフィールド上に存在する、特殊召喚されたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"突撃指令",h:"とつげきしれい",e:"Order to Charge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：トークン以外の自分フィールドの通常モンスター１体を対象として発動できる。\nその通常モンスターをリリースし、相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魂喰らいの魔刀",h:"たまぐらいのまとう",e:"Sword of the Soul-Eater",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するレベル３以下の通常モンスターに装備する事ができる。\nこのカードの発動時、装備モンスター以外の自分フィールド上に存在する通常モンスター（トークンを除く）を全て生け贄に捧げる。\n生け贄に捧げた通常モンスター１体につき、装備モンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"砂塵の結界",h:"さじんのけっかい",e:"Dust Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する通常モンスターは相手の魔法カードの効果を受けない。\nこのカードは発動後、２回目の自分のスタンバイフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"リバースソウル",h:"りばーすそうる",e:"Soul Reversal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するリバース効果モンスター１体をデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力倹約術",h:"まりょくけんやくじゅつ",e:"Spell Economics",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"魔法カードを発動するために払うライフポイントが必要なくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイルの恵み",h:"ないるのめぐみ",e:"Blessings of the Nile",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手がコントロールするカードの効果によって、カードが自分の手札から墓地へ捨てられる度に、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"7",h:"せぶん",e:"7",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「７」が自分フィールド上に表側表示で３枚揃った時、自分はデッキからカードを３枚ドローする。\nその後全ての「７」を破壊する。\nこのカードがフィールドから墓地へ送られた時、自分は７００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル制限B地区",h:"れべるせいげんＢちく",e:"Level Limit - Area B",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上のレベル４以上のモンスターは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔の試着部屋",h:"まのしちゃくべや",e:"Enchanting Fitting Room",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"８００ライフポイントを払う。\n自分のデッキの上からカードを４枚めくり、その中のレベル３以下の通常モンスターを自分フィールド上に特殊召喚する。\nそれ以外のカードはデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"弱肉一色",h:"じゃくにくいっしょく",e:"The Law of the Normal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にレベル２以下の通常モンスターが表側表示で５体存在する時に発動する事ができる。\nお互いのプレイヤーは手札を全て捨て、レベル２以下の通常モンスターを除くフィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒・魔・導",h:"ぶらっく・まじっく",e:"Dark Magic Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン」が存在する場合に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デルタ・アタッカー",h:"でるた・あたっかー",e:"Delta Attacker",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に同名通常モンスター（トークンを除く）が３体存在する時に発動する事ができる。\n発動ターンのみ、３体の同名通常モンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サウザンドエナジー",h:"さうざんどえなじー",e:"Thousand Energy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、全てのレベル２通常モンスター（トークンを除く）の元々の攻撃力と守備力は１０００ポイントアップする。\nエンドフェイズ時に自分フィールド上に存在するレベル２通常モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トライアングルパワー",h:"とらいあんぐるぱわー",e:"Triangle Power",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、全てのレベル１通常モンスター（トークンを除く）の元々の攻撃力と守備力は２０００ポイントアップする。\nエンドフェイズ時に自分フィールド上に存在するレベル１通常モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"第三の棺",h:"だいさんのひつぎ",e:"The Third Sarcophagus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは「第一の棺」の効果でしかフィールドに出す事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"第二の棺",h:"だいにのひつぎ",e:"The Second Sarcophagus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは「第一の棺」の効果でしかフィールドに出す事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"第一の棺",h:"だいいちのひつぎ",e:"The First Sarcophagus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手ターンのエンドフェイズ毎に「第二の棺」「第三の棺」の順にカードを１枚手札またはデッキから自分フィールド上に表側表示で出す。\n１枚でもフィールド上から離れると、これらのカードは全て墓地に送られる。\n全てのカードが自分フィールド上に揃った時、これらのカードを全て墓地へ送り、手札またはデッキから「スピリッツ・オブ・ファラオ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"運命のドラ",h:"うんめいのどら",e:"Dora of Fate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのみ発動する事ができる。\n相手フィールド上に表側表示で存在するモンスター１体を選択する。\n次の自分ターン、選択したモンスターより１つレベルが低いモンスターの召喚に成功した時、相手ライフに選択したモンスターのレベル×５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"砂漠の裁き",h:"さばくのさばき",e:"Judgment of the Desert",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"表側表示になったモンスターの表示形式は変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"人海戦術",h:"じんかいせんじゅつ",e:"Human-Wave Tactics",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"各ターンのエンドフェイズ時、そのターンで戦闘によって破壊された自分のレベル２以下の通常モンスターの数だけ、デッキからレベル２以下の通常モンスターを選択して自分フィールド上に特殊召喚する。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"アヌビスの呪い",h:"あぬびすののろい",e:"Curse of Anubis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する効果モンスターは全て守備表示になる。\n発動ターン、それらの効果モンスターの元々の守備力は０になり、表示形式の変更ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"砂漠の光",h:"さばくのひかり",e:"Desert Sunlight",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターを全て表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"デスカウンター",h:"ですかうんたー",e:"Des Counterblow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"直接攻撃によってプレイヤーに戦闘ダメージを与えたモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"悪夢の迷宮",h:"あくむのめいきゅう",e:"Labyrinth of Nightmare",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"各ターンのエンドフェイズ時、ターンプレイヤーのフィールド上に表側表示で存在する全てのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"蘇りし魂",h:"よみがえりしたましい",e:"Soul Resurrection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"玉砕指令",h:"ぎょくさいしれい",e:"Order to Smash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するレベル２以下の通常モンスター（トークンを除く）１体を選択して発動する。\n発動後、選択した通常モンスターを生け贄に捧げ、相手フィールド上の魔法・罠カードを２枚まで破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エンド・オブ・アヌビス",h:"えんど・おぶ・あぬびす",e:"The End of Anubis",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、墓地のカードを対象にする、または墓地で効果が発動する魔法・罠・モンスターの効果は全て無効になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロフェイス",h:"ねくろふぇいす",e:"Necroface",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"①：このカードが召喚に成功した場合に発動する。\n除外されているお互いのカードを全て持ち主のデッキに戻す。\nこのカードの攻撃力は、この効果でデッキに戻った数×１００アップする。\n②：このカードが除外された場合に発動する。\nお互いのプレイヤーは、それぞれ自身のデッキの上からカードを５枚除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊竜ガンドラ",h:"はかいりゅうがんどら",e:"Gandora the Dragon of Destruction",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは特殊召喚できない。\n①：LPを半分払って発動できる。\nこのカード以外のフィールドのカードを全て破壊し除外する。\nこのカードの攻撃力は、この効果で破壊したカードの数×３００アップする。\n②：このカードを召喚・反転召喚したターンのエンドフェイズに発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元からの帰還",h:"いじげんからのきかん",e:"Return from the Different Dimension",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ライフポイントを半分払って発動できる。\nゲームから除外されている自分のモンスターを可能な限り自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時にゲームから除外される。",li:0,lk:[],ta:[],al:[]},
  {n:"光のピラミッド",h:"ひかりのぴらみっど",e:"Pyramid of Light",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するこのカードがフィールド上から離れた場合、自分フィールド上に存在する「アンドロ・スフィンクス」、「スフィンクス・テーレイア」を破壊しゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"青眼の光龍",h:"ぶるーあいず・しゃいにんぐどらごん",e:"Blue-Eyes Shining Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分フィールドの「青眼の究極竜」１体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地のドラゴン族モンスターの数×３００アップする。\n②：このカードを対象とする魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ファミリア・ナイト",h:"ふぁみりあ・ないと",e:"Familiar Knight",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nお互いのプレイヤーは、手札からレベル４モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"レアメタル・ドラゴン",h:"れあめたる・どらごん",e:"Rare Metal Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードは通常召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"闇・道化師のペーテン",h:"やみ・どうけしのぺーてん",e:"Peten the Dark Clown",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"①：このカードが墓地へ送られた時、墓地のこのカードを除外して発動できる。\n手札・デッキから「闇・道化師のペーテン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒の魔法神官",h:"まじっく・はいえろふぁんと・おぶ・ぶらっく",e:"Sorcerer of Dark Magic",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3200,de:2800,tx:"このカードは通常召喚できない。\n自分フィールドのレベル６以上の魔法使い族モンスター２体をリリースした場合のみ特殊召喚できる。\n①：罠カードが発動した時に発動できる。\nこのカードがフィールドに表側表示で存在する場合、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アンドロ・スフィンクス",h:"あんどろ・すふぃんくす",e:"Andro Sphinx",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"フィールド上に「光のピラミッド」が存在する場合、５００ライフポイントを払う事でこのカードを手札から特殊召喚する事ができる。\nこのカードは召喚・特殊召喚したターンに攻撃をする事ができない。\nこのカードは墓地からの特殊召喚はできない。\nこのカードが戦闘によって守備表示モンスターを破壊した場合、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"スフィンクス・テーレイア",h:"すふぃんくす・てーれいあ",e:"Sphinx Teleia",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2500,de:3000,tx:"フィールド上に「光のピラミッド」が存在する場合、５００ライフポイントを払う事でこのカードを手札から特殊召喚する事ができる。\nこのカードは召喚・特殊召喚したターンに攻撃をする事ができない。\nこのカードは墓地からの特殊召喚はできない。\nこのカードが戦闘によって守備表示モンスターを破壊した場合、破壊したモンスターの守備力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"スフィンクス・アンドロジュネス",h:"すふぃんくす・あんどろじゅねす",e:"Theinen the Great Sphinx",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3000,tx:"このカードは通常召喚できない。\n自分フィールド上の「アンドロ・スフィンクス」と「スフィンクス・テーレイア」が同時に破壊された時、５００ライフポイントを払う事でのみ手札またはデッキから特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、５００ライフポイントを払う事で、エンドフェイズ終了時までこのカードの攻撃力は３０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・テンペスト",h:"へる・てんぺすと",e:"Inferno Tempest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"３０００ポイント以上の戦闘ダメージを受けた時に発動する事ができる。\nお互いのデッキと墓地のモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワタポン",h:"わたぽん",e:"Watapon",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカードがカードの効果によって自分のデッキから手札に加わった場合、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"大木炭１８",h:"だいもくたんいんぱち",e:"Charcoal Inpachi",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:100,de:2100,tx:"完全に燃え尽きてしまった巨木の化身。\nこの炭で焼く肉は絶品と言われ、重宝されている。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオアクア・マドール",h:"ねおあくあ・まどーる",e:"Neo Aqua Madoor",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:3000,tx:"水を支配する魔法使いの真の姿。\n絶対に破る事のできないと言われる巨大な氷の壁をつくり攻撃を防ぐ。",li:"",lk:[],ta:[],al:[]},
  {n:"骨犬マロン",h:"すかるどっぐまろん",e:"Skull Dog Marron",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1350,de:2000,tx:"１０００年前に飼い主とはぐれてしまった迷犬。\n未だにご主人の迎えを待ち続けている。",li:"",lk:[],ta:[],al:[]},
  {n:"スカゴブリン",h:"すかごぶりん",e:"Goblin Calligrapher",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:400,de:400,tx:"完璧な「スカ」の文字を極めるため、日々精進するゴブリン。\nその全てを一筆に注ぐ。",li:"",lk:[],ta:[],al:[]},
  {n:"アルティメット・インセクト LV１",h:"あるてぃめっと・いんせくと　れべる１",e:"Ultimate Insect LV1",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に表側表示で存在するこのカードは魔法の効果を受けない。\n自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「アルティメット・インセクト LV３」１体を手札またはデッキから特殊召喚する（召喚・特殊召喚・リバースしたターンを除く）。",li:"",lk:[],ta:[],al:[]},
  {n:"ホルスの黒炎竜 LV４",h:"ほるすのこくえんりゅう　れべる４",e:"Horus the Black Flame Dragon LV4",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードは自分フィールド上に表側表示で存在する限り、コントロールを変更する事はできない。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ホルスの黒炎竜 LV６」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホルスの黒炎竜 LV６",h:"ほるすのこくえんりゅう　れべる６",e:"Horus the Black Flame Dragon LV6",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"このカードは自分フィールド上に表側表示で存在する限り、魔法の効果を受けない。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ホルスの黒炎竜 LV８」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホルスの黒炎竜 LV８",h:"ほるすのこくえんりゅう　れべる８",e:"Horus the Black Flame Dragon LV8",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカードは通常召喚できない。\n「ホルスの黒炎竜 LV６」の効果でのみ特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、魔法カードの発動を無効にし破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒のミミック LV１",h:"あんこくのみみっく　れべる１",e:"Dark Mimic LV1",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:1000,tx:"リバース：デッキからカードを１枚ドローする。\nまた、自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「暗黒のミミック LV３」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒のミミック LV３",h:"あんこくのみみっく　れべる３",e:"Dark Mimic LV3",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘によって墓地に送られた場合、このカードのコントローラーはデッキからカードを１枚ドローする。\nこのカードが「暗黒のミミック LV１」の効果によって特殊召喚されている場合はカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスティック・ソードマン LV２",h:"みすてぃっく・そーどまん　れべる２",e:"Mystic Swordsman LV2",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:0,tx:"裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ミスティック・ソードマン LV４」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスティック・ソードマン LV４",h:"みすてぃっく・そーどまん　れべる４",e:"Mystic Swordsman LV4",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードを通常召喚する場合、裏側守備表示でしか出せない。\n裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ミスティック・ソードマン LV６」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン LV３",h:"あーむど・どらごん　れべる３",e:"Armed Dragon LV3",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"①：自分スタンバイフェイズにフィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「アームド・ドラゴン LV５」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン LV５",h:"あーむど・どらごん　れべる５",e:"Armed Dragon LV5",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1700,tx:"①：手札からモンスター１体を墓地へ送り、そのモンスターの攻撃力以下の攻撃力を持つ、相手フィールドのモンスター１体を対象として発動できる。\nこの効果を発動するために墓地へ送ったモンスターの攻撃力以下の攻撃力を持つ、その相手モンスターを破壊する。\n②：このカードが戦闘でモンスターを破壊したターンのエンドフェイズに、フィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「アームド・ドラゴン LV７」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン LV７",h:"あーむど・どらごん　れべる７",e:"Armed Dragon LV7",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは通常召喚できない。\n「アームド・ドラゴン LV５」の効果でのみ特殊召喚できる。\n①：手札からモンスター１体を墓地へ送って発動できる。\n墓地へ送ったそのモンスターの攻撃力以下の攻撃力を持つ、相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホルスのしもべ",h:"ほるすのしもべ",e:"Horus' Servant",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は「ホルスの黒炎竜（全てのレベルを含む）」を魔法・罠・モンスターの効果の対象にする事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"黒竜の雛",h:"こくりゅうのひな",e:"Black Dragon's Chick",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"自分フィールド上に表側表示で存在するこのカードを墓地へ送って発動できる。\n手札から「真紅眼の黒竜」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"怨念のキラードール",h:"おんねんのきらーどーる",e:"Malice Doll of Demise",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードが永続魔法の効果によってフィールド上から墓地に送られた場合、自分のターンのスタンバイフェイズ時に墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"忍者マスター SASUKE",h:"にんじゃますたー　さすけ",e:"Ninja Grandmaster Sasuke",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが表側守備表示のモンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻惑のラフレシア",h:"げんわくのらふれしあ",e:"Rafflesia Seduction",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:900,tx:"リバース：ターン終了時まで相手フィールド上表側表示モンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"超熱血球児",h:"ちょうねっけつきゅうじ",e:"Ultimate Baseball Kid",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"フィールド上にこのカード以外の炎属性モンスターが存在する場合、このカードの攻撃力は１体につき１０００ポイントアップする。\nこのカード以外の炎属性モンスターを墓地に送る度に、相手プレイヤーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷帝メビウス",h:"ひょうていめびうす",e:"Mobius the Frost Monarch",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した時、フィールドの魔法・罠カードを２枚まで対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメント・ドラゴン",h:"えれめんと・どらごん",e:"Element Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：フィールドのモンスターの属性によって、このカードは以下の効果を得る。\n\n●炎属性：このカードの攻撃力は５００アップする。\n\n●風属性：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメント・ソルジャー",h:"えれめんと・そるじゃー",e:"Element Soldier",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●水属性：このカードのコントロールを変更する事ができない。\n\n●地属性：このカードが戦闘によって破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"共鳴虫",h:"はうりんぐ・いんせくと",e:"Howling Insect",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:1300,tx:"このカードが戦闘によって破壊され墓地に送られた時、デッキから攻撃力１５００以下の昆虫族モンスター１体を自分フィールド上に特殊召喚する事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"仮面竜",h:"ますくど・どらごん",e:"Masked Dragon",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下のドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マインド・オン・エア",h:"まいんど・おん・えあ",e:"Mind on Air",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、相手は手札を公開し続けなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒゲアンコウ",h:"ひげあんこう",e:"Unshaven Angler",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"水属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"トロイホース",h:"とろいほーす",e:"The Trojan Horse",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"地属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"使徒喰い虫",h:"しとくいむし",e:"Nobleman-Eater Bug",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:1200,tx:"リバース：フィールド上のモンスター２体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"激昂のムカムカ",h:"げっこうのむかむか",e:"Enraged Muka Muka",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"自分の手札１枚につき、このカードの攻撃力・守備力はそれぞれ４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"派手ハネ",h:"はではね",e:"Hade-Hane",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:1000,tx:"リバース：フィールド上のモンスターを３体まで持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"月の女戦士",h:"つきのおんなせんし",e:"Penumbral Soldier Lady",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"光属性モンスターと戦闘をする場合、ダメージステップの間このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・キング",h:"おじゃま・きんぐ",e:"Ojama King",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:0,de:3000,tx:"「おジャマ・グリーン」＋「おジャマ・イエロー」＋「おジャマ・ブラック」\nこのカードがフィールド上に表側表示で存在する限り、相手のモンスターカードゾーンを３カ所まで使用不可能にする。",li:"",lk:[],ta:[],al:[]},
  {n:"マスター・オブ・OZ",h:"ますたー・おぶ・おーじー",e:"Master of Oz",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"獣族",mo:["融合"],ma:"",tp:"",at:4200,de:3700,tx:"「ビッグ・コアラ」＋「デス・カンガルー」",li:"",lk:[],ta:[],al:[]},
  {n:"クリッチー",h:"くりっちー",e:"Sanwitch",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:2100,de:1800,tx:"「クリッター」＋「黒き森のウィッチ」",li:"",lk:[],ta:[],al:[]},
  {n:"闇の量産工場",h:"やみのりょうさんこうじょう",e:"Dark Factory of Mass Production",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の通常モンスター２体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンマーシュート",h:"はんまーしゅーと",e:"Hammer Shot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの攻撃表示モンスターの内、攻撃力が一番高いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"記憶抹消",h:"きおくまっしょう",e:"Mind Wipe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手の手札が３枚以下の場合に発動する事ができる。\n相手は手札をデッキに加えてシャッフルする。\nその後、相手はデッキに加えた枚数分のカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"深淵の指名者",h:"しんえんのしめいしゃ",e:"Abyssal Designator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払う。\n種族と属性を１つずつ宣言する。\n相手は宣言された種族・属性を両方とも満たすモンスターを手札またはデッキから１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"レベルアップ！",h:"れべるあっぷ！",e:"Level Up!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「LV」を持つモンスター１体を墓地へ送り発動する。\nそのカードに記されているモンスターを、召喚条件を無視して手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒炎弾",h:"こくえんだん",e:"Inferno Fire Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、「真紅眼の黒竜」は攻撃できない。\n①：自分のモンスターゾーンの「真紅眼の黒竜」１体を対象として発動できる。\nその「真紅眼の黒竜」の元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"四次元の墓",h:"よじげんのはか",e:"The Graveyard in the Fourth Dimension",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「LV」を持つモンスター２体を、自分のデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"ツーマンセルバトル",h:"つーまんせるばとる",e:"Two-Man Cell Battle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"各プレイヤーは自分のターンのエンドフェイズ時に１度だけ、レベル４通常モンスター１体を手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"大波小波",h:"おおなみこなみ",e:"Big Wave Small Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する水属性モンスターを全て破壊する。\nその後、破壊した数と同じ数まで手札から水属性モンスターを特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"フュージョン・ウェポン",h:"ふゅーじょん・うぇぽん",e:"Fusion Weapon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル６以下の融合モンスターのみ装備可能。\n装備モンスターの攻撃力と守備力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュアル・ウェポン",h:"りちゅある・うぇぽん",e:"Ritual Weapon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル６以下の儀式モンスターのみ装備可能。\n装備モンスターの攻撃力と守備力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"挑発",h:"ちょうはつ",e:"Taunt",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのメインフェイズ１に自分フィールド上モンスター１体を選択して発動する。\n選択したモンスターがフィールド上に存在する限り、このターン相手がモンスターで攻撃を行う場合、選択したモンスターを攻撃対象に選択しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"絶体絶命",h:"ぜったいぜつめい",e:"Absolute End",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのみ発動する事ができる。\nこのターン相手モンスターの攻撃は、全てプレイヤーへの直接攻撃になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリットバリア",h:"すぴりっとばりあ",e:"Spirit Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上にモンスターが存在する限り、このカードのコントローラーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"忍法 空蝉の術",h:"にんぽう　うつせみのじゅつ",e:"Ninjitsu Art of Decoy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「忍者」という名のついたモンスター１体を選択して発動する。\nこのカードがフィールド上に存在する限り、選択したモンスターは戦闘によっては破壊されない。\n（ダメージ計算は適用する）",li:"",lk:[],ta:[],al:[]},
  {n:"衰弱の霧",h:"すいじゃくのきり",e:"Enervating Mist",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手の手札制限枚数は５枚までになる。",li:"",lk:[],ta:[],al:[]},
  {n:"大暴落",h:"だいぼうらく",e:"Heavy Slump",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の手札が８枚以上ある時に発動する事ができる。\n相手は手札を全てデッキに加えてシャッフルした後、カードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"グリード",h:"ぐりーど",e:"Greed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"カードの効果でドローを行ったプレイヤーは、そのターンのエンドフェイズ終了時にカードの効果でドローしたカードの枚数×５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"マインドクラッシュ",h:"まいんどくらっしゅ",e:"Mind Crush",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：カード名を１つ宣言して発動できる。\n宣言したカードが相手の手札にある場合、相手は手札のそのカードを全て捨てる。\n宣言したカードが相手の手札に無い場合、自分は手札をランダムに１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"セメタリー・ボム",h:"せめたりー・ぼむ",e:"Cemetary Bomb",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地のカードの数×１００ポイントダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリーライフバリアー",h:"ほーりーらいふばりあー",e:"Hallowed Life Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nこのカードを発動したターン、相手から受ける全てのダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"THE トリッキー",h:"ざ　とりっきー",e:"The Tricky",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：このカードは手札を１枚捨てて、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"グリーン・ガジェット",h:"ぐりーん・がじぇっと",e:"Green Gadget",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「レッド・ガジェット」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"造反劇",h:"ぞうはんげき",e:"Rebellion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のバトルフェイズに相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをバトルフェイズ終了時まで得る。\nこのターン、対象のモンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機動砦 ストロング・ホールド",h:"きどうとりで　すとろんぐ・ほーるど",e:"Stronghold the Moving Fortress",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（機械族・地・星４・攻０／守２０００）となり、モンスターゾーンに守備表示で特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：自分フィールドに「グリーン・ガジェット」「レッド・ガジェット」「イエロー・ガジェット」が存在する場合、このカードの効果で特殊召喚されたこのカードの攻撃力は３０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"同胞の絆",h:"どうほうのきずな",e:"Ties of the Brethren",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：２０００LPを払い、自分フィールドのレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターと同じ種族・属性・レベルでカード名が異なるモンスター２体をデッキから特殊召喚する（同名カードは１枚まで）。\nこのカードの発動後、ターン終了時まで自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ガジェット",h:"れっど・がじぇっと",e:"Red Gadget",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「イエロー・ガジェット」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"イエロー・ガジェット",h:"いえろー・がじぇっと",e:"Yellow Gadget",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「グリーン・ガジェット」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トリッキーズ・マジック４",h:"とりっきーず・まじっくふぉー",e:"Tricky Spell 4",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「THE トリッキー」１体を墓地へ送って発動できる。\n相手フィールドのモンスターの数だけ、自分フィールドに「トリッキートークン」（魔法使い族・風・星５・攻２０００／守１２００）を守備表示で特殊召喚する。\nこのトークンは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"罅割れゆく斧",h:"ひびわれゆくおの",e:"Shattered Axe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、対象のモンスターの攻撃力は自分スタンバイフェイズ毎に５００ダウンする。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ブロックマン",h:"ぶろっくまん",e:"Blockman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：このカードをリリースして発動できる。\nこのカードが自分フィールドに表側表示で存在した自分ターンの数と同じ数だけ、自分フィールドに「ブロックトークン」（岩石族・地・星４・攻１０００／守１５００）を守備表示で特殊召喚する。\nこのトークンは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"封印の黄金櫃",h:"ふういんのおうごんひつ",e:"Gold Sarcophagus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからカード１枚を選んで除外する。\nこのカードの発動後２回目の自分スタンバイフェイズに、この効果で除外したカードを手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"サイレント・ソードマン LV３",h:"さいれんと・そーどまん　れべる３",e:"Silent Swordsman LV3",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、このカードを対象とする相手の魔法カードの効果は無効化される。\n②：自分スタンバイフェイズにフィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「サイレント・ソードマン LV５」１体を特殊召喚する。\nこの効果はこのカードが召喚・特殊召喚・リバースしたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレント・ソードマン LV５",h:"さいれんと・そーどまん　れべる５",e:"Silent Swordsman LV5",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"①：このカードは相手の魔法カードの効果を受けない。\n②：このカードが直接攻撃で相手に戦闘ダメージを与えた場合、次の自分ターンのスタンバイフェイズにフィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「サイレント・ソードマン LV７」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレント・ソードマン LV７",h:"さいれんと・そーどまん　れべる７",e:"Silent Swordsman LV7",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは通常召喚できない。\n「サイレント・ソードマン LV５」の効果でのみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、フィールドの魔法カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレント・マジシャン LV４",h:"さいれんと・まじしゃん　れべる４",e:"Silent Magician LV4",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、相手がカードをドローする度に、このカードに魔力カウンターを１つ置く（最大５つまで）。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×５００アップする。\n③：このカードに５つ目の魔力カウンターが置かれた次の自分ターンのスタンバイフェイズに、魔力カウンターが５つ置かれているこのカードを墓地へ送って発動できる。\n手札・デッキから「サイレント・マジシャン LV８」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・サークル",h:"まじしゃんず・さーくる",e:"Magician's Circle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手の魔法使い族モンスターの攻撃宣言時に発動できる。\nお互いのプレイヤーは、それぞれ自分のデッキから攻撃力２０００以下の魔法使い族モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・クロス",h:"まじしゃんず・くろす",e:"Magicians Unite",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに攻撃表示の魔法使い族モンスターが２体以上存在する場合、その内の１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで３０００になる。\nこのカードの発動後、ターン終了時までそのモンスター以外の魔法使い族モンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレント・マジシャン LV８",h:"さいれんと・まじしゃん　れべる８",e:"Silent Magician LV8",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:1000,tx:"このカードは通常召喚できない。\n「サイレント・マジシャン LV４」の効果でのみ特殊召喚できる。\n①：このカードは相手の魔法カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"人造木人１８",h:"じんぞうもくじんいんぱち",e:"Woodborg Inpachi",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:500,de:2500,tx:"謎の大木１８が魔界の先端技術により改造された姿。\n頑丈な装甲に重点を置きすぎた結果、機動性が犠牲になった。",li:"",lk:[],ta:[],al:[]},
  {n:"マイティガード",h:"まいてぃがーど",e:"Mighty Guard",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:500,de:1200,tx:"警備用として開発された機械の戦士。\n錆びない金属でできている。",li:"",lk:[],ta:[],al:[]},
  {n:"魔貨物車両 ボコイチ",h:"まかもつしゃりょう　ぼこいち",e:"Bokoichi the Freightening Car",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"デコイチ専用の貨物車両。\nどんな物でも運ぶ事ができるが、大抵は到着時に壊れている。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・ガール",h:"はーぴぃ・がーる",e:"Harpie Girl",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"美しく華麗に舞い、鋭く攻撃する事ができるようになりたいと願っているハーピィの女の子。",li:"",lk:[],ta:[],al:[]},
  {n:"創世神",h:"ざ・くりえいたー",e:"The Creator",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2300,de:3000,tx:"自分の墓地からモンスターを１体選択する。\n手札を１枚墓地に送り、選択したモンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこのカードは墓地からの特殊召喚はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"創世者の化身",h:"そうせいしゃのけしん",e:"The Creator Incarnate",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカードを生け贄に捧げる事で、手札の「創世神」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アルティメット・インセクト LV３",h:"あるてぃめっと・いんせくと　れべる３",e:"Ultimate Insect LV3",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1400,de:900,tx:"「アルティメット・インセクト LV１」の効果で特殊召喚した場合、このカードがフィールド上に存在する限り、全ての相手モンスターの攻撃力は３００ポイントダウンする。\n自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「アルティメット・インセクト LV５」１体を手札またはデッキから特殊召喚する（召喚・特殊召喚・リバースしたターンを除く）。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスティック・ソードマン LV６",h:"みすてぃっく・そーどまん　れべる６",e:"Mystic Swordsman LV6",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードを通常召喚する場合、裏側守備表示でしか出せない。\n裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nこの効果で破壊したモンスターを墓地へ送らず、相手のデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"強化支援メカ・ヘビーウェポン",h:"きょうかしえんめか・へびーうぇぽん",e:"Heavy Mech Support Platform",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:500,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメント・マジシャン",h:"えれめんと・まじしゃん",e:"Element Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●水属性：このカードのコントロールを変更する事ができない。\n\n●風属性：このカードが戦闘によって相手モンスターを破壊した場合、もう一度だけ続けて攻撃を行う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメント・ザウルス",h:"えれめんと・ざうるす",e:"Element Saurus",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●炎属性：このカードの攻撃力は５００ポイントアップする。\n\n●地属性：このカードが戦闘によって破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の大怪鳥",h:"かすみのたにのだいかいちょう",e:"Roc from the Valley of Haze",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"このカードが手札から墓地に送られた時、このカードをデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"一撃必殺侍",h:"いちげきひっさつざむらい",e:"Sasuke Samurai #4",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動する。\nコイントスを１回行い、裏表を当てる。\n当たった場合、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ１",h:"はーぴぃ・れでぃわん",e:"Harpie Lady 1",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカードのカード名は「ハーピィ・レディ」として扱う。\nこのカードがフィールド上に表側表示で存在する限り、風属性モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ２",h:"はーぴぃ・れでぃつー",e:"Harpie Lady 2",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカードのカード名は「ハーピィ・レディ」として扱う。\nこのモンスターが戦闘によって破壊したリバース効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ３",h:"はーぴぃ・れでぃすりー",e:"Harpie Lady 3",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカードのカード名は「ハーピィ・レディ」として扱う。\nこのカードと戦闘を行った相手モンスターは、相手ターンで数えて２ターンの間攻撃宣言ができなくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"逆巻く炎の精霊",h:"さかまくほのおのせいれい",e:"Raging Flame Sprite",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎帝テスタロス",h:"えんていてすたろす",e:"Thestalos the Firestorm Monarch",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合に発動する。\n相手の手札をランダムに１枚選んで捨てる。\n捨てたカードがモンスターカードだった場合、そのモンスターのレベル×１００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"イーグル・アイ",h:"いーぐる・あい",e:"Eagle Eye",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"華麗なる潜入工作員",h:"かれいなるせんにゅうこうさくいん",e:"Tactical Espionage Expert",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の炎",h:"しんりゃくのほのお",e:"Invasion of Flames",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"忍び寄るデビルマンタ",h:"しのびよるでびるまんた",e:"Creeping Doom Manta",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の戦士 ワーウルフ",h:"しっこくのせんし　わーうるふ",e:"Pitch-Black Warwolf",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズに罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラージュ・ドラゴン",h:"みらーじゅ・どらごん",e:"Mirage Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズに罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"爆炎集合体 ガイヤ・ソウル",h:"ばくえんしゅうごうたい　がいや・そうる",e:"Gaia Soul the Combustible Collective",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"自分フィールド上の炎族モンスターを２体まで生け贄に捧げる事ができる。\nこの効果で生け贄を捧げた場合、このモンスターの攻撃力は生け贄の数×１０００ポイントアップする。\nこのカードが守備表示モンスターを攻撃した時、このカードの攻撃力が守備表示モンスターの守備力を越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nエンドフェイズ時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"きつね火",h:"きつねび",e:"Fox Fire",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"フィールド上に表側表示で存在するこのカードが戦闘によって破壊され墓地へ送られたターンのエンドフェイズ時、このカードを墓地から特殊召喚する。\nこのカードはフィールド上に表側表示で存在する限り、アドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大戦艦 ビッグ・コア",h:"きょだいせんかん　びっぐ・こあ",e:"B.E.S. Big Core",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1100,tx:"このカードの召喚時にカウンターを３つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にこのカードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"可変機獣 ガンナードラゴン",h:"かへんきじゅう　がんなーどらごん",e:"Fusilier Dragon, the Dual-Mode Beast",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードはリリースなしで通常召喚できる。\n②：このカードの①の方法で通常召喚したこのカードの元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装機関車 デコイチ",h:"まそうきかんしゃ　でこいち",e:"Dekoichi the Battlechanted Locomotive",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:1000,tx:"リバース：カードを１枚ドローする。\n自分フィールド上に「魔貨物車両 ボコイチ」が表側表示で存在する場合、さらにその枚数分カードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トラップ処理班 Aチーム",h:"とらっぷしょりはん　Ａちーむ",e:"A-Team: Trap Disposal Unit",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:400,tx:"この効果は相手ターンでも使用する事ができる。\n相手が罠カードを発動した時、表側表示のこのカードを生け贄に捧げる事で罠カードの発動を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"錬金生物 ホムンクルス",h:"れんきんせいぶつ　ほむんくるす",e:"Homunculus the Alchemic Being",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このモンスターの属性を変更する事ができる。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"闇魔界の竜騎士 ダークソード",h:"やみまかいのりゅうきし　だーくそーど",e:"Dark Blade the Dragon Knight",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1500,tx:"「闇魔界の戦士 ダークソード」＋「漆黒の闘龍」\nこのカードが相手に戦闘ダメージを与える度に、相手の墓地から３枚までモンスターカードを選択し、ゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"キング・もけもけ",h:"きんぐ・もけもけ",e:"Mokey Mokey King",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:300,de:100,tx:"「もけもけ」＋「もけもけ」＋「もけもけ」\nこのカードがフィールド上から離れた時、自分の墓地に存在する「もけもけ」を可能な限り特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"連続魔法",h:"れんぞくまほう",e:"Serial Spell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の通常魔法発動時に発動する事ができる。\n手札を全て墓地に捨てる。\nこのカードの効果は、その通常魔法の効果と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィの狩場",h:"はーぴぃのかりば",e:"Harpies' Hunting Ground",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの鳥獣族モンスターの攻撃力・守備力は２００アップする。\n②：自分または相手が、「ハーピィ・レディ」または「ハーピィ・レディ三姉妹」の召喚・特殊召喚に成功した場合、そのプレイヤーはフィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トライアングル・X・スパーク",h:"とらいあんぐる・えくすたしー・すぱーく",e:"Triangle Ecstasy Spark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの全ての「ハーピィ・レディ三姉妹」の攻撃力はターン終了時まで２７００になる。\nこのターン、相手は罠カードを発動できず、相手フィールドの罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"与奪の首飾り",h:"よだつのくびかざり",e:"Necklace of Command",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の装備モンスターが戦闘によって破壊されこのカードが墓地に送られた時、次の効果から１つを選択して発動する。\n\n●デッキからカードを１枚ドローする。\n\n●相手の手札をランダムに１枚墓地に捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"フリント",h:"ふりんと",e:"Flint",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードの装備モンスターは、表示形式の変更と攻撃宣言ができなくなり、攻撃力が３００ポイントダウンする。\n装備モンスターが破壊された場合、フィールド上のモンスターを１体選択し、そのモンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"怒れるもけもけ",h:"いかれるもけもけ",e:"Mokey Mokey Smackdown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「もけもけ」が自分フィールド上に表側表示で存在している時、自分フィールド上の天使族モンスターが破壊された場合、このターンのエンドフェイズまで自分フィールド上の「もけもけ」の攻撃力は３０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"振り出し",h:"ふりだし",e:"Back to Square One",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚捨てる。\nフィールド上のモンスター１体を持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"死者転生",h:"ししゃてんせい",e:"Monster Reincarnation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"城壁壊しの大槍",h:"じょうへきこわしのおおやり",e:"Ballista of Rampart Smashing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが裏側守備表示モンスターを攻撃する場合、装備モンスターの攻撃力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"軽量化",h:"けいりょうか",e:"Lighten the Load",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"手札からレベル７以上のモンスター１体をデッキに加えてシャッフルした後、カードを１枚ドローする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"邪気退散",h:"じゃきたいさん",e:"Malice Dispersion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"手札を１枚捨てる。\nフィールド上に表側表示で存在する永続罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天罰",h:"てんばつ",e:"Divine Wrath",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札を１枚捨てて発動する。\n効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"心鎮壷",h:"しん・つぇん・ふー",e:"Xing Zhen Hu",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上にセットされた魔法・罠カードを２枚選択して発動する。\nこのカードがフィールド上に存在する限り、選択された魔法・罠カードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レアメタル化・魔法反射装甲",h:"れあめたるか・まほうはんしゃそうこう",e:"Rare Metalmorph",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上の機械族モンスター１体を選択して発動できる。\nこのカードがフィールド上に存在する限り、その機械族モンスターの攻撃力は５００ポイントアップし、そのモンスターを対象にする魔法カードの効果を１度だけ無効にする。\n選択したモンスターがフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コザッキーの研究成果",h:"こざっきーのけんきゅうせいか",e:"Fruits of Kozaky's Studies",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキのカードを上から３枚確認し、そのカードを好きな順番に入れ替えてデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"マインド・ハック",h:"まいんど・はっく",e:"Mind Haxorz",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払う。\n相手の手札と相手フィールド上のセットされたカードを全て確認する。",li:"",lk:[],ta:[],al:[]},
  {n:"風林火山",h:"ふうりんかざん",e:"Fuh-Rin-Ka-Zan",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：風・水・炎・地属性モンスターがフィールドに全て存在する場合に発動できる。\n以下の効果から１つを選んで適用する。\n\n●相手フィールドのモンスターを全て破壊する。\n\n●相手フィールドの魔法・罠カードを全て破壊する。\n\n●相手の手札をランダムに２枚選んで捨てる。\n\n●自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖炸薬",h:"ちぇーん・ばーすと",e:"Chain Burst",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"罠カードを発動したプレイヤーに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ピケルの魔法陣",h:"ぴけるのまほうじん",e:"Pikeru's Circle of Enchantment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターンのエンドフェイズまで、このカードのコントローラーへのカードの効果によるダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力浄化",h:"まりょくじょうか",e:"Spell Purification",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nフィールド上に表側表示で存在する永続魔法カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アストラルバリア",h:"あすとらるばりあ",e:"Astral Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが自分フィールド上モンスターを攻撃する場合、その攻撃を自分ライフへの直接攻撃にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"援護射撃",h:"えんごしゃげき",e:"Covering Fire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターが自分フィールド上モンスターを攻撃する場合、ダメージステップ時に発動する事ができる。\n攻撃を受けた自分モンスターの攻撃力は、自分フィールド上に表側表示で存在する他のモンスター１体の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"キャッスル・ゲート",h:"きゃっする・げーと",e:"Castle Gate",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2400,tx:"①：このカードは戦闘では破壊されない。\n②：１ターンに１度、このカードがフィールドに攻撃表示で存在する場合、自分フィールドのレベル５以下のモンスター１体をリリースして発動できる。\nリリースしたモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"所有者の刻印",h:"しょゆうしゃのこくいん",e:"Owner's Seal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの全てのモンスターのコントロールは元々の持ち主に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"スペースマンボウ",h:"すぺーすまんぼう",e:"Space Mambo",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"広大な銀河を漂う宇宙マンボウ。\nアルファード４の超文明遺跡から発見されたという生きた化石。",li:"",lk:[],ta:[],al:[]},
  {n:"神竜 ラグナロク",h:"しんりゅう　らぐなろく",e:"Divine Dragon Ragnarok",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"神の使いと言い伝えられている伝説の竜。\nその怒りに触れた時、世界は海に沈むと言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"格闘ねずみ チュー助",h:"かくとうねずみ　ちゅーすけ",e:"Chu-Ske the Mouse Fighter",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:0,tx:"ねずみ界最強の格闘家を目指して世界を放浪している熱血ねずみ。\nうかつに触ると火傷するぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装甲騎士",h:"いんせくとないと",e:"Insect Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1900,de:1500,tx:"昆虫戦士の中でも、エリート中のエリートのみが所属できるという「無死虫団」の精鋭騎士。\n彼らの高い戦闘能力は無視できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの鳳凰神",h:"ねふてぃすのほうおうしん",e:"Sacred Phoenix of Nephthys",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"①：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動する。\nこのカードを墓地から特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した場合に発動する。\nフィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの導き手",h:"ねふてぃすのみちびきて",e:"Hand of Nephthys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：自分フィールドのモンスター１体とこのカードをリリースして発動できる。\n手札・デッキから「ネフティスの鳳凰神」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アルティメット・インセクト LV５",h:"あるてぃめっと・いんせくと　れべる５",e:"Ultimate Insect LV5",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2300,de:900,tx:"「アルティメット・インセクト LV３」の効果で特殊召喚した場合、このカードがフィールド上に存在する限り、全ての相手モンスターの攻撃力は５００ポイントダウンする。\n自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「アルティメット・インセクト LV７」１体を手札またはデッキから特殊召喚する（召喚・特殊召喚・リバースしたターンを除く）。",li:"",lk:[],ta:[],al:[]},
  {n:"地帝グランマーグ",h:"ちていぐらんまーぐ",e:"Granmarg the Rock Monarch",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドにセットされたカード１枚を対象として発動する。\nセットされたそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメント・ヴァルキリー",h:"えれめんと・う゛ぁるきりー",e:"Element Valkyrie",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●炎属性：このカードの攻撃力は５００ポイントアップする。\n\n●水属性：このカードのコントロールを変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメント・デビル",h:"えれめんと・でびる",e:"Element Doom",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●地属性：このカードが戦闘によって破壊した効果モンスターの効果は無効化される。\n\n●風属性：このカードが戦闘によって相手モンスターを破壊した場合、もう一度だけ続けて攻撃を行う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"本気ギレパンダ",h:"まじぎれぱんだ",e:"Maji-Gire Panda",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上の獣族モンスターが破壊される度にこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"またたびキャット",h:"またたびきゃっと",e:"Catnipped Kitty",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"自分フィールド上にこのカード以外の獣族モンスターが存在する場合、このカードを攻撃する事はできない。\n相手フィールド上に存在するモンスター１体の守備力をエンドフェイズ時まで０にする。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"百獣王 ベヒーモス",h:"あにまる・きんぐ　べひーもす",e:"Behemoth the King of All Animals",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1500,tx:"このカードは生け贄１体で通常召喚する事ができる。\nその場合、このカードの元々の攻撃力は２０００になる。\n生け贄召喚に成功した時、生け贄に捧げた数だけ自分の墓地の獣族モンスターを持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグホーン・マンモス",h:"びっぐほーん・まんもす",e:"Big-Tusked Mammoth",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、相手フィールド上モンスターは召喚・反転召喚・特殊召喚したターンには攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"カンガルー・チャンプ",h:"かんがるー・ちゃんぷ",e:"Kangaroo Champ",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:700,tx:"このカードと戦闘を行ったモンスターは、ダメージ計算後に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイエナ",h:"はいえな",e:"Hyena",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:300,tx:"このカードが戦闘によって墓地へ送られた時、デッキから「ハイエナ」をフィールド上に特殊召喚する事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレードラビット",h:"ぶれーどらびっと",e:"Blade Rabbit",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードの表示形式が攻撃表示から表側守備表示に変わった時、相手フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機械犬マロン",h:"めかどっぐまろん",e:"Mecha-Dog Marron",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"フィールド上のこのカードが戦闘によって破壊され墓地へ送られた時、お互いのライフに１０００ポイントダメージを与える。\nフィールド上のこのカードが戦闘以外によって破壊され墓地へ送られた時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラッド・マジシャン－煉獄の魔術師－",h:"ぶらっど・まじしゃん－れんごくのまじゅつし－",e:"Blast Magician",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1700,tx:"このカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに乗っている魔力カウンターを任意の個数取り除く事で、取り除いた数×７００ポイント以下の攻撃力を持つフィールド上に表側表示で存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣聖－ネイキッド・ギア・フリード",h:"けんせい－ねいきっど・ぎあ・ふりーど",e:"Gearfried the Swordmaster",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2200,tx:"このカードは通常召喚できない。\n「拘束解除」の効果でのみ特殊召喚できる。\n①：このカードに装備カードが装備された場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"重装武者－ベン・ケイ",h:"じゅうそうむしゃ－べん・けい",e:"Armed Samurai - Ben Kei",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードは通常の攻撃に加えて、このカードに装備された装備カードの数だけ、１度のバトルフェイズ中に攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の住人 シャドウキラー",h:"やみのじゅうにん　しゃどうきらー",e:"Shadowslayer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"相手のモンスターカードゾーンに守備表示モンスターしか存在しない場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"番兵ゴーレム",h:"ばんぺいごーれむ",e:"Golem Sentry",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上のモンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"暴れ牛鬼",h:"あばれうしおに",e:"Abare Ushioni",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"コイントスで裏表を当てる。\n当たった場合、相手ライフに１０００ポイントダメージを与える。\nハズレの場合、自分は１０００ポイントダメージを受ける。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"融合呪印生物－光",h:"ゆうごうじゅいんせいぶつ－ひかり",e:"The Light - Hex-Sealed Fusion",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。\nまた、必要となる融合素材モンスター（このカードを含む）をリリースして発動できる。\nそのリリースしたモンスターを融合素材とする光属性の融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"融合呪印生物－闇",h:"ゆうごうじゅいんせいぶつ－やみ",e:"The Dark - Hex-Sealed Fusion",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：闇属性の融合モンスターカードによって決められた融合素材モンスター一組（このカードをその内の１体とする）を自分フィールドからリリースして発動できる。\nそのリリースしたモンスターを融合素材とする闇属性の融合モンスター１体をEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"融合呪印生物－地",h:"ゆうごうじゅいんせいぶつ－ち",e:"The Earth - Hex-Sealed Fusion",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：地属性の融合モンスターカードによって決められた融合素材モンスター一組（このカードをその内の１体とする）を自分フィールドからリリースして発動できる。\nそのリリースしたモンスターを融合素材とする地属性の融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暴風小僧",h:"ぼうふうこぞう",e:"Whirlwind Prodigy",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"風属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎を支配する者",h:"ふれいむ・るーらー",e:"Flame Ruler",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"炎属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"火炎鳥",h:"ばーにんぐばーど",e:"Firebird",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の鳥獣族モンスターが破壊される度にこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキューキャット",h:"れすきゅーきゃっと",e:"Rescue Cat",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"「レスキューキャット」の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを墓地へ送って発動できる。\nデッキからレベル３以下の獣族モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイン・ジャッカー",h:"ぶれいん・じゃっかー",e:"Brain Jacker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:900,tx:"リバース：このカードは装備カード扱いとなり、相手フィールド上モンスターに装備する。\nこのカードを装備したモンスターのコントロールを得る。\n相手のスタンバイフェイズ毎に、相手は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガトリング・ドラゴン",h:"がとりんぐ・どらごん",e:"Gatling Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1200,tx:"「リボルバー・ドラゴン」＋「ブローバック・ドラゴン」\nコイントスを３回行う。\n表が出た数だけ、フィールド上のモンスターを破壊する。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魔人 キングドラグーン",h:"りゅうまじん　きんぐどらぐーん",e:"King Dragun",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1100,tx:"「ロード・オブ・ドラゴン－ドラゴンの支配者－」＋「神竜 ラグナロク」\nこのカードがフィールド上に表側表示で存在する限り、相手はドラゴン族モンスターを魔法・罠・モンスターの効果の対象にする事はできない。\n１ターンに１度だけ、手札からドラゴン族モンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"鳳凰神の羽根",h:"ほうおうしんのはね",e:"A Feather of the Phoenix",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚捨て、自分の墓地のカード１枚を選択して発動できる。\n選択したカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ポイズン・ファング",h:"ぽいずん・ふぁんぐ",e:"Poison Fangs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"獣族モンスターが相手プレイヤーに戦闘ダメージを与える度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の護封剣",h:"やみのごふうけん",e:"Swords of Concealing Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは発動後、２回目の自分スタンバイフェイズに破壊される。\n①：このカードの発動時の効果処理として、相手フィールドに表側表示モンスターが存在する場合、そのモンスターを全て裏側守備表示にする。\n②：このカードが魔法＆罠ゾーンに存在する限り、相手フィールドのモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"螺旋槍殺",h:"すぱいらる・しぇいばー",e:"Spiral Spear Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分の「暗黒騎士ガイア」「疾風の暗黒騎士ガイア」「竜騎士ガイア」が守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：このカードの①の効果の適用によって「竜騎士ガイア」が相手に戦闘ダメージを与えた場合に発動する。\n自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"拘束解除",h:"こうそくかいじょ",e:"Release Restraint",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「鉄の騎士 ギア・フリード」１体をリリースして発動できる。\n手札・デッキから「剣聖－ネイキッド・ギア・フリード」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"遠心分離フィールド",h:"えんしんぶんりふぃーるど",e:"Centrifugal Field",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"融合モンスターがカードの効果で破壊され墓地へ送られた時、その融合モンスターに記されている融合素材モンスター１体を自分の墓地から選択し、自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"契約の履行",h:"けいやくのりこう",e:"Fulfillment of the Contract",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"８００ライフポイントを払う。\n自分の墓地から儀式モンスター１体を選択して自分フィールド上に特殊召喚し、このカードを装備する。\nこのカードが破壊された時、装備モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"再融合",h:"さいゆうごう",e:"Re-Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、自分の墓地の融合モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードが破壊された時にそのモンスターは除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"百獣大行進",h:"ひゃくじゅうだいこうしん",e:"The Big March of Animals",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する獣族モンスターの攻撃力は、エンドフェイズ時まで自分フィールド上の獣族モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"クロスカウンター",h:"くろすかうんたー",e:"Cross Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"攻撃された守備表示モンスターの守備力が、相手攻撃モンスターの攻撃力を越えていた場合、相手に与える戦闘ダメージは倍になる。\nダメージ計算後にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ポールポジション",h:"ぽーるぽじしょん",e:"Pole Position",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側表示で存在する、攻撃力が一番高いモンスターは魔法の効果を受けない。\n「ポールポジション」がフィールド上に存在しなくなった時、フィールド上に表側表示で存在する攻撃力が一番高いモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"罰ゲーム！",h:"ばつげーむ！",e:"Penalty Game!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の手札が４枚の時、次の効果から１つを選択して発動する。\n\n●相手は次のドローフェイズにカードをドローできない。\n\n●このターン相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"威嚇する咆哮",h:"いかくするほうこう",e:"Threatening Roar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン相手は攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"鳳翼の爆風",h:"ほうよくのばくふう",e:"Phoenix Wing Wind Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚捨て、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリンのやりくり上手",h:"ごぶりんのやりくりじょうず",e:"Good Goblin Housekeeping",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「ゴブリンのやりくり上手」の枚数＋１枚を自分のデッキからドローし、自分の手札を１枚選択してデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"キャトルミューティレーション",h:"きゃとるみゅーてぃれーしょん",e:"Beast Soul Swap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する獣族モンスター１体を手札に戻し、手札から戻したモンスターと同じレベルの獣族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"本陣強襲",h:"ほんじんきょうしゅう",e:"Assault on GHQ",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択したカードを破壊した後、相手のデッキのカードを上から２枚墓地に送る。",li:"",lk:[],ta:[],al:[]},
  {n:"D.D.ダイナマイト",h:"でぃー．でぃー．だいなまいと",e:"D.D. Dynamite",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が除外しているカードの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔のデッキ破壊ウイルス",h:"まのでっきはかいういるす",e:"Deck Devastation Virus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力２０００以上の闇属性モンスター１体をリリースして発動できる。\n相手フィールドのモンスター、相手の手札、相手ターンで数えて３ターンの間に相手がドローしたカードを全て確認し、その内の攻撃力１５００以下のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメンタルバースト",h:"えれめんたるばーすと",e:"Elemental Burst",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する風・水・炎・地属性モンスターを１体ずつ生け贄に捧げて発動する。\n相手フィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"強引な安全協定",h:"ごういんなあんぜんきょうてい",e:"Forced Ceasefire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nこのターンのエンドフェイズ時まで、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"カース・オブ・ヴァンパイア",h:"かーす・おぶ・う゛ぁんぱいあ",e:"Vampire's Curse",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、５００ライフポイントを払って発動できる。\n次のターンのスタンバイフェイズ時に、このカードを墓地から特殊召喚する。\nまた、この効果によって特殊召喚に成功した時に発動する。\nこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニオン・アタック",h:"ゆにおん・あたっく",e:"Union Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターンのバトルフェイズ開始時、そのモンスターの攻撃力は、他の自分フィールドの攻撃表示モンスターの攻撃力の合計分、バトルフェイズ終了時までアップする。\nこのターン、対象のモンスターが相手に与える戦闘ダメージは０になり、他の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラッド・サッカー",h:"ぶらっど・さっかー",e:"Blood Sucker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"威圧する魔眼",h:"いあつするまがん",e:"Overpowering Eye",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの攻撃力２０００以下のアンデット族モンスター１体を対象として発動できる。\nこのターン、そのモンスターは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の闇竜",h:"れっどあいず・だーくねすどらごん",e:"Red-Eyes Darkness Dragon",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「真紅眼の黒竜」１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードの攻撃力は、自分の墓地に存在するドラゴン族モンスター１体につき３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイアジェネシス",h:"う゛ぁんぱいあじぇねしす",e:"Vampire Genesis",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2100,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「ヴァンパイア・ロード」１体をゲームから除外した場合のみ特殊召喚する事ができる。\n１ターンに１度、手札からアンデット族モンスター１体を墓地に捨てる事で、捨てたアンデット族モンスターよりレベルの低いアンデット族モンスター１体を自分の墓地から選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"正義の味方 カイバーマン",h:"せいぎのみかた　かいばーまん",e:"Kaibaman",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:700,tx:"このカードをリリースして発動できる。\n手札から「青眼の白龍」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・ソルジャー",h:"ましんなーず・そるじゃー",e:"Machina Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：自分フィールドに他のモンスターが存在せず、このカードが召喚に成功した時に発動できる。\n手札から「マシンナーズ・ソルジャー」以外の「マシンナーズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・ディフェンダー",h:"ましんなーず・でぃふぇんだー",e:"Machina Defender",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:1800,tx:"①：このカードがリバースした場合に発動する。\nデッキから「督戦官コヴィントン」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・スナイパー",h:"ましんなーず・すないぱー",e:"Machina Sniper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：このカードがモンスターゾーンに存在する限り、お互いに「マシンナーズ・スナイパー」以外の「マシンナーズ」モンスターを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"敵襲警報－イエローアラート－",h:"てきしゅうけいほう－いえろーあらーと－",e:"Intruder Alarm - Yellow Alert",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手モンスターの攻撃宣言時に発動できる。\n手札からモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、相手は他のモンスターを攻撃対象に選択できない。\nこの効果で特殊召喚したモンスターは、バトルフェイズ終了時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"督戦官コヴィントン",h:"とくせんかんこう゛ぃんとん",e:"Commander Covington",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"①：自分フィールドの表側表示の「マシンナーズ・ソルジャー」「マシンナーズ・スナイパー」「マシンナーズ・ディフェンダー」を１体ずつ墓地へ送って発動できる。\n手札・デッキから「マシンナーズ・フォース」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・フォース",h:"ましんなーず・ふぉーす",e:"Machina Force",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4600,de:4100,tx:"このカードは通常召喚できない。\n「督戦官コヴィントン」の効果でのみ特殊召喚できる。\n①：このカードは１０００LP払わなければ攻撃宣言できない。\n②：フィールドのこのカードを墓地へ送り、自分の墓地の「マシンナーズ・ソルジャー」「マシンナーズ・スナイパー」「マシンナーズ・ディフェンダー」を１体ずつ対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO フェザーマン",h:"えれめんたるひーろー　ふぇざーまん",e:"Elemental HERO Avian",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"風を操り空を舞う翼を持ったE・HERO。\n天空からの一撃、フェザーブレイクで悪を裁く。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO バーストレディ",h:"えれめんたるひーろー　ばーすとれでぃ",e:"Elemental HERO Burstinatrix",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"炎を操るE・HEROの紅一点。\n紅蓮の炎、バーストファイヤーが悪を焼き尽くす。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO クレイマン",h:"えれめんたるひーろー　くれいまん",e:"Elemental HERO Clayman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"粘土でできた頑丈な体を持つE・HERO。\n体をはって、仲間のE・HEROを守り抜く。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO スパークマン",h:"えれめんたるひーろー　すぱーくまん",e:"Elemental HERO Sparkman",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"様々な武器を使いこなす、光の戦士のE・HERO。\n聖なる輝きスパークフラッシュが悪の退路を断つ。",li:"",lk:[],ta:[],al:[]},
  {n:"ハネクリボー",h:"はねくりぼー",e:"Winged Kuriboh",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：フィールドのこのカードが破壊され墓地へ送られた場合に発動する。\nこのターン、自分が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械巨人",h:"あんてぃーく・ぎあごーれむ",e:"Ancient Gear Golem",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは特殊召喚できない。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械獣",h:"あんてぃーく・ぎあびーすと",e:"Ancient Gear Beast",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは特殊召喚できない。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが戦闘で破壊した相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械兵士",h:"あんてぃーく・ぎあそるじゃー",e:"Ancient Gear Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ミレニアム・スコーピオン",h:"みれにあむ・すこーぴおん",e:"Millennium Scorpion",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"このカードが相手フィールド上モンスター１体を戦闘によって破壊し墓地へ送る度に、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アルティメット・インセクト LV７",h:"あるてぃめっと・いんせくと　れべる７",e:"Ultimate Insect LV7",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2600,de:1200,tx:"「アルティメット・インセクト LV５」の効果で特殊召喚した場合、このカードが自分フィールド上に存在する限り、全ての相手モンスターの攻撃力・守備力は７００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ロストガーディアン",h:"ろすとがーでぃあん",e:"Lost Guardian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:"?",tx:"このカードの元々の守備力は、自分が除外している岩石族モンスターの数×７００ポイントの数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒエラコスフィンクス",h:"ひえらこすふぃんくす",e:"Hieracosphinx",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は裏側守備表示モンスターを攻撃対象に選択する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"クリオスフィンクス",h:"くりおすふぃんくす",e:"Criosphinx",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"このカードが自分フィールド上に表側表示で存在する限り、フィールド上のモンスターが持ち主の手札に戻った時、そのモンスターの持ち主は手札からカードを１枚選択して墓地に送る。",li:"",lk:[],ta:[],al:[]},
  {n:"モアイ迎撃砲",h:"もあいげいげきほう",e:"Moai Interceptor Cannons",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"メガロック・ドラゴン",h:"めがろっく・どらごん",e:"Megarock Dragon",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分の墓地に存在する岩石族モンスターを除外する事でのみ特殊召喚できる。\nこのカードの元々の攻撃力と守備力は、特殊召喚時に除外した岩石族モンスターの数×７００ポイントの数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダミー・ゴーレム",h:"だみー・ごーれむ",e:"Dummy Golem",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:800,tx:"リバース：相手はコントロールしているモンスター１体を選択する。\n選択したモンスターとこのカードのコントロールを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイヴ・オージャ",h:"ぐれいう゛・おーじゃ",e:"Grave Ohja",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"自分フィールド上に裏側守備表示モンスターが存在する限り、このカードを攻撃対象に選択する事はできない。\n自分フィールド上のモンスターが反転召喚する度に、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マイン・ゴーレム",h:"まいん・ごーれむ",e:"Mine Golem",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"モンク・ファイター",h:"もんく・ふぁいたー",e:"Monk Fighter",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"このカードが戦闘を行う事によって受けるコントローラーの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"マスターモンク",h:"ますたーもんく",e:"Master Monk",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「モンク・ファイター」１体を生け贄に捧げた場合のみ特殊召喚する事ができる。\nこのカードは１ターンに２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・スタチュー",h:"がーでぃあん・すたちゅー",e:"Guardian Statue",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上モンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"メデューサ・ワーム",h:"めでゅーさ・わーむ",e:"Medusa Worm",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:600,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の生還者",h:"いじげんのせいかんしゃ",e:"D.D. Survivor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分フィールド上に表側表示で存在するこのカードがゲームから除外された場合、このカードはエンドフェイズ時にフィールド上に特殊召喚される。",li:"",lk:[],ta:[],al:[]},
  {n:"ミドル・シールド・ガードナー",h:"みどる・しーるど・がーどなー",e:"Mid Shield Gardna",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:1800,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\n裏側表示のこのモンスター１体を対象とする魔法カードの発動を無効にする。\nその時、このカードは表側守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"白い忍者",h:"ほわいとにんじゃ",e:"White Ninja",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:800,tx:"リバース：フィールド上の守備表示モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"地霊使いアウス",h:"ちれいつかいあうす",e:"Aussa the Earth Charmer",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの地属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"水霊使いエリア",h:"すいれいつかいえりあ",e:"Eria the Water Charmer",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの水属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"火霊使いヒータ",h:"かれいつかいひーた",e:"Hiita the Fire Charmer",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの炎属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"風霊使いウィン",h:"ふうれいつかいうぃん",e:"Wynn the Wind Charmer",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの風属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"電池メン－単三型",h:"でんちめん－たんさんがた",e:"Batteryman AA",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上の「電池メン－単三型」が全て攻撃表示だった場合、「電池メン－単三型」１体につきこのカードの攻撃力は１０００ポイントアップする。\n自分フィールド上の「電池メン－単三型」が全て守備表示だった場合、「電池メン－単三型」１体につきこのカードの守備力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・ウォンバット",h:"です・うぉんばっと",e:"Des Wombat",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"このカードがフィールド上に表側表示で存在する限り、コントローラーへのカードの効果によるダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイトキング",h:"わいときんぐ",e:"King of the Skull Servants",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードの元々の攻撃力は、自分の墓地に存在する「ワイトキング」「ワイト」の数×１０００ポイントの数値になる。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分の墓地の「ワイトキング」または「ワイト」１体をゲームから除外する事で、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"大邪神 レシェフ",h:"だいじゃしん　れしぇふ",e:"Reshef the Dark Being",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1500,tx:"「大邪神の儀式」により降臨。\n手札の魔法カードを１枚捨てる。\n相手フィールド上モンスター１体のコントロールをエンドフェイズ時まで得る。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊術師 ドリアード",h:"えれめんたるますたー　どりあーど",e:"Elemental Mistress Doriado",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:1200,de:1400,tx:"「ドリアードの祈り」により降臨。\nこのカードの属性は「風」「水」「炎」「地」としても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO フレイム・ウィングマン",h:"えれめんたるひーろー　ふれいむ・うぃんぐまん",e:"Elemental HERO Flame Wingman",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1200,tx:"「E・HERO フェザーマン」＋「E・HERO バーストレディ」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO サンダー・ジャイアント",h:"えれめんたるひーろー　さんだー・じゃいあんと",e:"Elemental HERO Thunder Giant",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「E・HERO スパークマン」＋「E・HERO クレイマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n自分の手札を１枚捨てる事で、フィールド上に表側表示で存在する元々の攻撃力がこのカードの攻撃力よりも低いモンスター１体を選択して破壊する。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"受け継がれる力",h:"うけつがれるちから",e:"Gift of the Martyr",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体を墓地に送る。\n自分フィールド上のモンスター１体を選択する。\n選択したモンスター１体の攻撃力は、発動ターンのエンドフェイズまで墓地に送ったモンスターカードの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブルアタック",h:"だぶるあたっく",e:"Double Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札からモンスターカード１枚を墓地に捨てる。\n捨てたモンスターよりもレベルが低いモンスター１体を自分フィールド上から選択する。\n選択したモンスター１体はこのターン２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"充電器",h:"ばってりーちゃーじゃー",e:"Battery Charger",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"５００ライフポイントを払う。\n自分の墓地から「電池メン」という名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴッドハンド・スマッシュ",h:"ごっどはんど・すまっしゅ",e:"Kaminote Blow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「格闘ねずみ チュー助」「モンク・ファイター」「マスターモンク」の内１体以上が存在している場合のみ発動する事ができる。\nこのターンこれらのモンスターと戦闘を行ったモンスターをダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドリアードの祈り",h:"どりあーどのいのり",e:"Doriado's Blessing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「精霊術師 ドリアード」の降臨に必要。\nフィールドか手札から、レベルが３以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"大邪神の儀式",h:"だいじゃしんのぎしき",e:"Final Ritual of the Ancients",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「大邪神 レシェフ」の降臨に必要。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の黒帯",h:"でんせつのくろおび",e:"Legendary Black Belt",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「格闘ねずみ チュー助」「モンク・ファイター」「マスターモンク」のみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターの守備力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ニトロユニット",h:"にとろゆにっと",e:"Nitro Unit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"相手フィールド上モンスターにのみ装備可能。\n装備モンスターを戦闘によって破壊し墓地へ送った時、装備モンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"うごめく影",h:"うごめくかげ",e:"Shifting Shadows",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"３００ライフポイントを払う事で、自分フィールド上の裏側守備表示モンスターをシャッフルして、再び裏側守備表示で並べ替える。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄壁の布陣",h:"てっぺきのふじん",e:"Impenetrable Formation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体を選択して発動する。\n自分フィールド上にモンスターが２体以上存在し全て守備表示の場合、選択したモンスターの守備力は７００ポイントアップする。\n選択したモンスターがフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー・シグナル",h:"ひーろー・しぐなる",e:"Hero Signal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが戦闘で破壊され墓地へ送られた時に発動できる。\n手札・デッキからレベル４以下の「E・HERO」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ピケルの読心術",h:"ぴけるのどくしんじゅつ",e:"Pikeru's Second Sight",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の２回目のエンドフェイズまで、相手はドローしたカードを公開してから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"岩盤爆破",h:"がんばんばくは",e:"Minefield Eruption",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「マイン・ゴーレム」１体につき、相手ライフに１０００ポイントダメージを与える。\nその後自分フィールド上に表側表示で存在する「マイン・ゴーレム」を全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コザッキーの自爆装置",h:"こざっきーのじばくそうち",e:"Kozaky's Self-Destruct Button",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードを破壊したプレイヤーに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"融合失敗",h:"ゆうごうしっぱい",e:"Mispolymerization",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"融合モンスターが特殊召喚された時に発動する事ができる。\nフィールド上に存在する全ての融合モンスターを融合デッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル変換実験室",h:"れべるへんかんじっけんしつ",e:"Level Conversion Lab",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札のモンスターカードを１枚選択して相手に見せ、サイコロを１回振る。\n１の目が出た場合、選択したモンスターを墓地へ送る。\n２～６の目が出た場合、このターンのエンドフェイズ時までこのモンスターは出た目のレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"岩投げアタック",h:"いわなげあたっく",e:"Rock Bombardment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキから岩石族モンスター１体を選択して墓地へ送る。\n相手ライフに５００ポイントダメージを与える。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"墓場からの誘い",h:"はかばからのいざない",e:"Grave Lure",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のデッキの一番上のカードを表向きにしてシャッフルする。\n相手がそのカードをドローした時、そのカードをそのまま墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"トークン謝肉祭",h:"とーくんしゃにくさい",e:"Token Feastevil",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"トークンが特殊召喚された時に発動する事ができる。\nフィールド上に存在する全てのトークンを破壊し、相手ライフに破壊したトークンの数×３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力終了宣告",h:"まりょくしゅうりょうせんこく",e:"Spell-Stopping Statute",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が永続魔法カードを発動した時に発動する事ができる。\nそのカードの発動と効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮の陥落",h:"おうきゅうのかんらく",e:"Royal Surrender",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が永続罠カードを発動した時に発動する事ができる。\nそのカードの発動と効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"孤高の格闘家",h:"ここうのかくとうか",e:"Lone Wolf",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「格闘ねずみ チュー助」「モンク・ファイター」「マスターモンク」の内１体のみが存在している場合に発動する事ができる。\nこのモンスター１体は戦闘によっては破壊されず、相手モンスターの効果も受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルフレイムエンペラー",h:"へるふれいむえんぺらー",e:"Infernal Flame Emperor",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2700,de:1600,tx:"このカードは特殊召喚できない。\nこのカードがアドバンス召喚に成功した時、自分の墓地に存在する炎属性モンスターを５体までゲームから除外する事ができる。\nこの効果で除外したモンスターの数だけ、フィールド上に存在する魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュータント・ハイブレイン",h:"みゅーたんと・はいぶれいん",e:"Mutant Mindmaster",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2500,tx:"①：相手フィールドにモンスターが２体以上存在する場合、このカードの攻撃宣言時に、相手フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nその相手の表側攻撃表示モンスター１体のコントロールをバトルフェイズ終了時まで得る。\n対象のモンスターはこのターン直接攻撃できず、攻撃可能な場合、相手モンスター１体を攻撃対象として選び、ダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"聖導騎士イシュザーク",h:"せいんとないといしゅざーく",e:"Divine Knight Ishzark",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1800,tx:"このカードが戦闘によってモンスターを破壊した時、そのモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"海竜神－ネオダイダロス",h:"かいりゅうしん－ねおだいだろす",e:"Ocean Dragon Lord - Neo-Daedalus",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:1600,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「海竜－ダイダロス」１体をリリースした場合のみ特殊召喚する事ができる。\n自分フィールド上に存在する「海」を墓地へ送る事で、このカード以外のお互いの手札・フィールド上のカードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"森の番人グリーン・バブーン",h:"もりのばんにんぐりーん・ばぶーん",e:"Green Baboon, Defender of the Forest",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：このカードが手札・墓地に存在し、自分フィールドの表側表示の獣族モンスターが効果で破壊され墓地へ送られた時、１０００LPを払って発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイクロイド",h:"さいくろいど",e:"Cycroid",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"数あるビークロイドの中で、最も親しみ深く愛されるビークロイド。\n補助輪を装備する事もできるぞ！",li:"",lk:[],ta:[],al:[]},
  {n:"パトロイド",h:"ぱとろいど",e:"Patroid",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"相手フィールド上にセットされているカードを１枚めくり、確認した後元に戻す。\nこの効果は１ターンに１度だけ自分のメインフェイズに発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャイロイド",h:"じゃいろいど",e:"Gyroid",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは１ターンに１度だけ、戦闘によっては破壊されない。\n（ダメージ計算は適用する）",li:"",lk:[],ta:[],al:[]},
  {n:"スチームロイド",h:"すちーむろいど",e:"Steamroid",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が５００ポイントアップする。\nこのカードは相手モンスターに攻撃される場合、ダメージステップの間攻撃力が５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドリルロイド",h:"どりるろいど",e:"Drillroid",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが守備表示モンスターを攻撃した場合、ダメージ計算前にそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ユーフォロイド",h:"ゆーふぉろいど",e:"UFOroid",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の機械族モンスター１体を表側攻撃表示で特殊召喚する事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェット・ロイド",h:"じぇっと・ろいど",e:"Jetroid",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードが相手モンスターの攻撃対象に選択された時、このカードのコントローラーは手札から罠カードを発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン",h:"さいばー・どらごん",e:"Cyber Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フレンドッグ",h:"ふれんどっぐ",e:"Wroughtweiler",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地から「E・HERO」と名のついたカード１枚と「融合」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・カタパルター",h:"だーく・かたぱるたー",e:"Dark Catapulter",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"自分のスタンバイフェイズ時にこのカードが守備表示だった場合、このカードにカウンターを１つ置く。\nカウンターと同じ数のカードを自分の墓地から除外する事で、その枚数と同じ枚数のフィールド上の魔法・罠カードを破壊する。\nその後このカードのカウンターを全て取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO バブルマン",h:"えれめんたるひーろー　ばぶるまん",e:"Elemental HERO Bubbleman",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"①：手札がこのカード１枚のみの場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分はデッキから２枚ドローする。\nこの効果は自分の手札・フィールドに他のカードが無い場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スチームジャイロイド",h:"すちーむじゃいろいど",e:"Steam Gyroid",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:2200,de:1600,tx:"「ジャイロイド」＋「スチームロイド」",li:"",lk:[],ta:[],al:[]},
  {n:"ユーフォロイド・ファイター",h:"ゆーふぉろいど・ふぁいたー",e:"UFOroid Fighter",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:"?",tx:"「ユーフォロイド」＋戦士族モンスター\nこのモンスターの融合召喚は、上記のカードでしか行えない。\nこのカードの元々の攻撃力・守備力は、融合素材にしたモンスター２体の元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ツイン・ドラゴン",h:"さいばー・ついん・どらごん",e:"Cyber Twin Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2100,tx:"「サイバー・ドラゴン」＋「サイバー・ドラゴン」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エンド・ドラゴン",h:"さいばー・えんど・どらごん",e:"Cyber End Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:2800,tx:"「サイバー・ドラゴン」＋「サイバー・ドラゴン」＋「サイバー・ドラゴン」 \nこのカードの融合召喚は上記のカードでしか行えない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・ボンド",h:"ぱわー・ぼんど",e:"Power Bond",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、機械族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は、その元々の攻撃力分アップする。\nこのカードを発動したターンのエンドフェイズに自分はこの効果でアップした数値分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"摩天楼 －スカイスクレイパー－",h:"まてんろう　－すかいすくれいぱー－",e:"Skyscraper",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：「E・HERO」モンスターの攻撃力は、その攻撃力より高い攻撃力を持つモンスターに攻撃するダメージ計算時のみ１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚僧サモンプリースト",h:"しょうかんそうさもんぷりーすと",e:"Summoner Monk",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"①：このカードが召喚・反転召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、このカードはリリースできない。\n③：１ターンに１度、手札から魔法カード１枚を捨てて発動できる。\nデッキからレベル４モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"邪神ドレッド・ルート",h:"じゃしんどれっど・るーと",e:"The Wicked Dreadroot",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは特殊召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ通常召喚できる。\n①：このカードがモンスターゾーンに存在する限り、このカード以外のフィールドのモンスターの攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハネクリボー LV１０",h:"はねくりぼー　れべる１０",e:"Winged Kuriboh LV10",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:300,de:200,tx:"このカードは通常召喚できない。\nこのカードは「進化する翼」の効果でのみ特殊召喚する事ができる。\n自分フィールド上に表側表示で存在するこのカードを生け贄に捧げる事で、相手フィールド上の攻撃表示モンスターを全て破壊し、破壊したモンスターの元々の攻撃力の合計分のダメージを相手ライフに与える。\nこの効果は相手バトルフェイズ中のみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"進化する翼",h:"しんかするつばさ",e:"Transcendent Wings",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「ハネクリボー」１体と手札２枚を墓地に送る。\n「ハネクリボー LV１０」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バブル・シャッフル",h:"ばぶる・しゃっふる",e:"Bubble Shuffle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「E・HERO バブルマン」がフィールド上に表側表示で存在する時のみ発動する事ができる。\n自分フィールド上に表側攻撃表示で存在する「E・HERO バブルマン」１体と相手フィールド上に表側攻撃表示で存在するモンスター１体を守備表示にする。\n守備表示にした「E・HERO バブルマン」１体を生け贄に捧げ、「E・HERO」と名のつくモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スパークガン",h:"すぱーくがん",e:"Spark Blaster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO スパークマン」にのみ装備可能。\n自分のターンのメインフェイズ時に表側表示モンスター１体の表示形式を変更する事ができる。\nこの効果を３回使用した後、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"冥王竜ヴァンダルギオン",h:"めいおうりゅうう゛ぁんだるぎおん",e:"Van'Dalgyon the Dark Dragon Lord",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2500,tx:"相手がコントロールするカードの発動をカウンター罠で無効にした場合、このカードを手札から特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、無効にしたカードの種類により以下の効果を発動する。\n\n●魔法：相手ライフに１５００ポイントダメージを与える。\n\n●罠：相手フィールド上のカード１枚を選択して破壊する。\n\n●効果モンスター：自分の墓地からモンスター１体を選択して自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソイツ",h:"そいつ",e:"Soitsu",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:0,de:0,tx:"かなり頼りない姿をしているが、実はとてつもない潜在能力を隠し持っていると思っているらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"マッド・ロブスター",h:"まっど・ろぶすたー",e:"Mad Lobster",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"世界中のグルメモンスター達に愛されている高級食材として有名。\n凶悪な味が刺激的という。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェリービーンズマン",h:"じぇりーびーんずまん",e:"Jerry Beans Man",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1750,de:0,tx:"ジェリーという名の豆戦士。\n自分が世界最強の戦士だと信じ込んでいるが、その実力は定かではない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・マジシャン",h:"さいばねてぃっく・まじしゃん",e:"Cybernetic Magician",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"手札を１枚捨てる。\nこのターンのエンドフェイズ時まで、フィールド上に表側表示で存在するモンスター１体の攻撃力は２０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・サイクロプス",h:"さいばねてぃっく・さいくろぷす",e:"Cybernetic Cyclopean",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"自分の手札が０枚である限り、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"メカニカル・ハウンド",h:"めかにかる・はうんど",e:"Mechanical Hound",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:1500,tx:"自分の手札が０枚である限り、相手は魔法カードを発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバーデーモン",h:"さいばーでーもん",e:"Cyber Archfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分のドローフェイズ開始時に自分の手札が０枚だった場合、通常のドローに加えてもう１枚ドローする。\n自分のエンドフェイズ時に自分の手札が１枚以上存在していた場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリンエリート部隊",h:"ごぶりんえりーとぶたい",e:"Goblin Elite Attack Force",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"①：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分ターンの終了時まで表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大戦艦 クリスタル・コア",h:"きょだいせんかん　くりすたる・こあ",e:"B.E.S. Crystal Core",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカードの召喚時にカウンターを３つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にこのカードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。\n１ターンに１度だけ自分のメインフェイズに、相手フィールド上の表側攻撃表示モンスター１体を表側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"G・コザッキー",h:"じゃいあんと・こざっきー",e:"Giant Kozaky",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:2400,tx:"フィールド上に「コザッキー」が表側表示で存在していない場合、このカードを破壊する。\nフィールド上に表側表示で存在するこのカードが破壊された場合、その時のコントローラーにこのカードの元々の攻撃力分のダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"不屈闘士レイレイ",h:"ふくつとうしれいれい",e:"Indomitable Fighter Lei Lei",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"守護霊アイリン",h:"しゅごれいあいりん",e:"Protective Soul Ailin",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「不屈闘士レイレイ」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの表示形式を１ターンに１度だけ変更する事ができる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ドイツ",h:"どいつ",e:"Doitsu",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:100,de:200,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「ソイツ」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力は２５００ポイントアップする。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"デスガエル",h:"ですがえる",e:"Des Frog",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードの生け贄召喚に成功した時、自分の墓地に存在する「悪魂邪苦止」の枚数分まで、「デスガエル」を手札またはデッキから特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魂邪苦止",h:"おたまじゃくし",e:"T.A.D.P.O.L.E.",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に存在するこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「悪魂邪苦止」を手札に加える事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"引きガエル",h:"ひきがえる",e:"Poison Draw Frog",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"フィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ディノインフィニティ",h:"でぃのいんふぃにてぃ",e:"Tyranno Infinity",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"①：このカードの元々の攻撃力は、除外されている自分の恐竜族モンスターの数×１０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"電池メン－単二型",h:"でんちめん－たんにがた",e:"Batteryman C",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上の「電池メン－単二型」が全て攻撃表示だった場合、「電池メン－単二型」１体につき自分フィールド上の全ての機械族モンスターの攻撃力は５００ポイントアップする。\n自分フィールド上の「電池メン－単二型」が全て守備表示だった場合、「電池メン－単二型」１体につき自分フィールド上の全ての機械族モンスターの守備力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔導師クラン",h:"くろまどうしくらん",e:"Ebon Magician Curran",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"自分のスタンバイフェイズ時、相手フィールド上に存在するモンスターの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"D・D・M",h:"でぃふぁれんと・でぃめんしょん・ますたー",e:"D.D.M. - Different Dimension Master",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"手札の魔法カードを１枚捨てる。\nゲームから除外された自分が持ち主のモンスター１体を特殊召喚する。\nこの効果は１ターンに１度だけ自分のメインフェイズに発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"融合回収",h:"ふゅーじょん・りかばりー",e:"Fusion Recovery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の、「融合」１枚と融合召喚に使用した融合素材モンスター１体を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラクル・フュージョン",h:"みらくる・ふゅーじょん",e:"Miracle Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、「E・HERO」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"龍の鏡",h:"どらごんず・みらー",e:"Dragon's Mirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"システム・ダウン",h:"しすてむ・だうん",e:"System Down",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払う。\n相手フィールド上と相手の墓地の機械族モンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"死の合唱",h:"ですこーらす",e:"Des Croaking",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「デスガエル」３体が表側表示で存在する時に発動する事ができる。\n相手フィールド上に存在する全てのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"謙虚な壺",h:"けんきょなつぼ",e:"Pot of Generosity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札からカードを２枚デッキに戻す。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"シエンの間者",h:"しえんのかんじゃ",e:"Shien's Spy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターンのエンドフェイズ時まで、選択したカードのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイアーダーツ",h:"ふぁいあーだーつ",e:"Fire Darts",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札が０枚の時に発動する事ができる。\nサイコロを３回振り、その合計の数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"地霊術－「鉄」",h:"ちれいじゅつ－「くろがね」",e:"Spiritual Earth Art - Kurogane",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの地属性モンスター１体をリリースし、そのモンスター以外の自分の墓地のレベル４以下の地属性モンスター１体を対象として発動できる。\nその地属性モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"水霊術－「葵」",h:"すいれいじゅつ－「あおい」",e:"Spiritual Water Art - Aoi",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの水属性モンスター１体をリリースして発動できる。\n相手の手札を確認し、その中からカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"火霊術－「紅」",h:"かれいじゅつ－「くれない」",e:"Spiritual Fire Art - Kurenai",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの炎属性モンスター１体をリリースして発動できる。\nリリースしたモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"風霊術－「雅」",h:"ふうれいじゅつ－「みやび」",e:"Spiritual Wind Art - Miyabi",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの風属性モンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ライバル登場！",h:"らいばるとうじょう！",e:"A Rival Appears!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択する。\n選択したモンスターと同じレベルのモンスター１体を自分の手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・エクスプロージョン",h:"まじかる・えくすぷろーじょん",e:"Magical Explosion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札が０枚の時に発動する事ができる。\n自分の墓地に存在する魔法カードの枚数×２００ポイントダメージを相手ライフに与える。",li:0,lk:[],ta:[],al:[]},
  {n:"ライジング・エナジー",h:"らいじんぐ・えなじー",e:"Rising Energy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の落とし穴",h:"いじげんのおとしあな",e:"D.D. Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスター１体を守備表示でセットした時に発動する事ができる。\nセットしたそのモンスター１体と自分フィールド上のモンスター１体を破壊してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"徴兵令",h:"ちょうへいれい",e:"Conscription",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のデッキの一番上のカードを１枚めくる。\nめくったカードが通常召喚可能なモンスターだった場合、自分フィールド上に特殊召喚する。\nそれ以外のカードだった場合、そのカードを相手の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・ウォール",h:"でぃめんしょん・うぉーる",e:"Dimension Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\nこの戦闘によって自分が受ける戦闘ダメージは、かわりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"反撃準備",h:"はんげきじゅんび",e:"Prepare to Strike Back",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手プレイヤーが表側守備表示モンスターに対して攻撃宣言をする度に、コイントスで裏表を当てる。\n当たった場合、攻撃対象となった表側守備表示モンスターを攻撃表示にする。\nハズレの場合、攻撃モンスターの攻撃力が攻撃対象のモンスターの守備力を越えた数値分だけこのカードのコントローラーはダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"方舟の選別",h:"はこぶねのせんべつ",e:"The Selection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に１０００LPを払って発動できる。\nフィールドに同じ種族のモンスターが存在するモンスターの召喚・反転召喚・特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神の進化",h:"かみのしんか",e:"Divine Evolution",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動と効果は無効化されない。\n①：自分フィールドの、元々の種族が幻神獣族のモンスターまたは元々のカード名が「邪神アバター」「邪神ドレッド・ルート」「邪神イレイザー」となるモンスター１体を選ぶ（「神の進化」の効果を既に適用したモンスターは選べない）。\nそのモンスターは、攻撃力・守備力が１０００アップし、自身の効果の発動及びその発動した効果は無効化されず、以下の効果を得る。\n\n●このカードの攻撃宣言時に発動できる。\n相手は自身のフィールドのモンスター１体を墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"闇よりの罠",h:"やみよりのわな",e:"Trap of Darkness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分LPが３０００以下の時、１０００LPを払い、「闇よりの罠」以外の自分の墓地の通常罠カード１枚を対象として発動できる。\nこのカードの効果は、その墓地の通常罠カード発動時の効果と同じになる。\nその後、その墓地の通常罠カードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナ ナイトジョーカー",h:"あるかな　ないとじょーかー",e:"Arcana Knight Joker",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3800,de:2500,tx:"「クィーンズ・ナイト」＋「ジャックス・ナイト」＋「キングス・ナイト」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：１ターンに１度、フィールドのこのカードを対象とする、モンスターの効果・魔法・罠カードが発動した時、そのカードと同じ種類（モンスター・魔法・罠）の手札を１枚捨てて発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ギルフォード・ザ・レジェンド",h:"ぎるふぉーど・ざ・れじぇんど",e:"Gilford the Legend",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"このカードは特殊召喚できない。\nこのカードが召喚に成功した時、自分の墓地に存在する装備魔法カードを可能な限り自分フィールド上に表側表示で存在する戦士族モンスターに装備する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"荒野の女戦士",h:"こうやのおんなせんし",e:"Warrior Lady of the Wasteland",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の戦士族・地属性モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神剣－フェニックスブレード",h:"しんけん－ふぇにっくすぶれーど",e:"Divine Sword - Phoenix Blade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力は３００ポイントアップする。\n自分のメインフェイズ時、自分の墓地に存在する戦士族モンスター２体をゲームから除外する事で、このカードを自分の墓地から手札に加える。",li:0,lk:[],ta:[],al:[]},
  {n:"デスサイズ・キラー",h:"ですさいず・きらー",e:"Dreadscythe Harvester",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"①：このカード以外の自分フィールドの昆虫族モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO シャイニング・フレア・ウィングマン",h:"えれめんたるひーろー　しゃいにんぐ・ふれあ・うぃんぐまん",e:"Elemental HERO Shining Flare Wingman",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「E・HERO フレイム・ウィングマン」＋「E・HERO スパークマン」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、自分の墓地の「E・HERO」カードの数×３００アップする。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル調整",h:"れべるちょうせい",e:"Level Modulation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手はカードを２枚ドローする。\n自分の墓地に存在する「LV」を持つモンスター１体を、召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン攻撃できず効果を発動及び適用する事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマッスル",h:"おじゃまっする",e:"Ojamuscle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「おジャマ・キング」１体を選択する。\n選択した「おジャマ・キング」以外の「おジャマ」と名のついたモンスターを全て破壊する。\n破壊したモンスター１体につき、選択した「おジャマ・キング」１体の攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマジック",h:"おじゃまじっく",e:"Ojamagic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このカードが手札・フィールドから墓地へ送られた場合に発動する。\nデッキから「おジャマ・グリーン」「おジャマ・イエロー」「おジャマ・ブラック」を１体ずつ手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"V－タイガー・ジェット",h:"う゛ぃ－たいがー・じぇっと",e:"V-Tiger Jet",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"空中戦を得意とする、合体能力を持つモンスター。\n合体と分離を駆使して立体的な攻撃を繰り出す。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレード・スケーター",h:"ぶれーど・すけーたー",e:"Blade Skater",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"氷上の舞姫は、華麗なる戦士。\n必殺アクセル・スライサーで華麗に敵モンスターを切り裂く。",li:"",lk:[],ta:[],al:[]},
  {n:"リボーン・ゾンビ",h:"りぼーん・ぞんび",e:"Reborn Zombie",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"自分の手札が０枚の場合、フィールド上に攻撃表示で存在するこのカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄戦士",h:"へるそるじゃー",e:"Chthonian Soldier",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、この戦闘によって自分が受けた戦闘ダメージを相手ライフにも与える。",li:"",lk:[],ta:[],al:[]},
  {n:"W－ウィング・カタパルト",h:"だぶる－うぃんぐ・かたぱると",e:"W-Wing Catapult",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1300,de:1500,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの「V－タイガー・ジェット」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は４００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎獄魔人ヘル・バーナー",h:"えんごくまじんへる・ばーなー",e:"Infernal Incinerator",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードを除く自分の手札を全て墓地に捨て、さらに自分フィールド上の攻撃力２０００以上のモンスター１体を生け贄に捧げなければ通常召喚できない。\n相手フィールド上モンスター１体につきこのカードの攻撃力は２００ポイントアップする。\nこのカード以外の自分フィールド上のモンスター１体につきこのカードの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO エッジマン",h:"えれめんたるひーろー　えっじまん",e:"Elemental HERO Bladedge",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ワイルドマン",h:"えれめんたるひーろー　わいるどまん",e:"Elemental HERO Wildheart",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードはモンスターゾーンに存在する限り、罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイドロゲドン",h:"はいどろげどん",e:"Hydrogeddon",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「ハイドロゲドン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オキシゲドン",h:"おきしげどん",e:"Oxygeddon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：このカードが炎族モンスターとの戦闘で破壊され墓地へ送られた場合に発動する。\nお互いのプレイヤーは８００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーター・ドラゴン",h:"うぉーたー・どらごん",e:"Water Dragon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカードは通常召喚できない。\n「ボンディング－H２O」の効果でのみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、フィールドの炎属性モンスター及び炎族モンスターの攻撃力は０になる。\n②：このカードが破壊され墓地へ送られた時、自分の墓地の「ハイドロゲドン」２体と「オキシゲドン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エトワール・サイバー",h:"えとわーる・さいばー",e:"Etoile Cyber",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードは相手プレイヤーを直接攻撃する場合、ダメージステップの間攻撃力が５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"VW－タイガー・カタパルト",h:"う゛ぃだぶる－たいがー・かたぱると",e:"VW-Tiger Catapult",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2100,tx:"「V－タイガー・ジェット」＋「W－ウィング・カタパルト」\n自分フィールドの上記カードを除外した場合のみ、エクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：手札を１枚捨て、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターの表示形式を変更する。\nこの時、リバースモンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[]},
  {n:"VWXYZ－ドラゴン・カタパルトキャノン",h:"う゛ぃとぅずぃ－どらごん・かたぱるときゃのん",e:"VWXYZ-Dragon Catapult Cannon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2800,tx:"「VW－タイガー・カタパルト」＋「XYZ－ドラゴン・キャノン」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを除外する。\n②：このカードが相手モンスターに攻撃宣言した時、その攻撃対象モンスター１体を対象として発動できる。\nその攻撃対象モンスターの表示形式を変更する。\nこの時、リバースモンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ブレイダー",h:"さいばー・ぶれいだー",e:"Cyber Blader",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:800,tx:"「エトワール・サイバー」＋「ブレード・スケーター」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：相手フィールドのモンスターの数によって、このカードは以下の効果を得る。\n●１体：このカードは戦闘では破壊されない。\n●２体：このカードの攻撃力は倍になる。\n●３体：相手が発動したカードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ランパートガンナー",h:"えれめんたるひーろー　らんぱーとがんなー",e:"Elemental HERO Rampart Blaster",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2500,tx:"「E・HERO クレイマン」＋「E・HERO バーストレディ」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカードが表側守備表示の場合、守備表示の状態で相手プレイヤーを直接攻撃する事ができる。\nその場合、このカードの攻撃力はダメージ計算時のみ半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO テンペスター",h:"えれめんたるひーろー　てんぺすたー",e:"Elemental HERO Tempest",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2800,tx:"「E・HERO フェザーマン」＋「E・HERO スパークマン」＋「E・HERO バブルマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカード以外の自分フィールド上のカード１枚を墓地に送り、自分フィールド上のモンスター１体を選択する。\nこのカードがフィールド上に表側表示で存在する限り、選択したモンスターは戦闘によっては破壊されない。\n（ダメージ計算は適用する）",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ワイルドジャギーマン",h:"えれめんたるひーろー　わいるどじゃぎーまん",e:"Elemental HERO Wildedge",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2300,tx:"「E・HERO ワイルドマン」＋「E・HERO エッジマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n相手フィールド上の全てのモンスターに１回ずつ攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・アライアンス",h:"へる・あらいあんす",e:"Chthonian Alliance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する装備モンスターと同名のモンスター１体につき、装備モンスターの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"フェザー・ショット",h:"ふぇざー・しょっと",e:"Feather Shot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「E・HERO フェザーマン」１体を選択して発動する。\nこのターン、選択したカードは自分フィールド上のモンスターの数だけ攻撃する事ができる。\nその場合、相手プレイヤーに直接攻撃をする事はできず、他の自分のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ボンディング－H２O",h:"ぼんでぃんぐ－えいちつーおー",e:"Bonding - H2O",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「ハイドロゲドン」２体と「オキシゲドン」１体を生け贄に捧げる。\n自分の手札・デッキ・墓地から「ウォーター・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・ポリマー",h:"へる・ぽりまー",e:"Chthonian Polymer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が融合モンスターを融合召喚した時に発動する事ができる。\n自分フィールド上のモンスター１体を生け贄に捧げる事で、その融合モンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・ブラスト",h:"へる・ぶらすと",e:"Chthonian Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが破壊され墓地へ送られた時に発動する事ができる。\nフィールド上の攻撃力が一番低い表側表示モンスター１体を破壊し、お互いにその攻撃力の半分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーローバリア",h:"ひーろーばりあ",e:"Hero Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「E・HERO」と名のついたモンスターが表側表示で存在する場合、相手モンスターの攻撃を１度だけ無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"フェザー・ウィンド",h:"ふぇざー・うぃんど",e:"Feather Wind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「E・HERO フェザーマン」が表側表示で存在する場合に発動する事ができる。\n魔法・罠の発動を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の騎士 ズール",h:"あんこくかいのきし ずーる",e:"Zure, Knight of Dark World",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"暗黒界でその名を知らぬ者はいない、誇り高き騎士。\n決して弱き者に手を下す事はない。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大戦艦 テトラン",h:"きょだいせんかん　てとらん",e:"B.E.S. Tetran",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:2300,tx:"このカードの召喚時にカウンターを３つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にこのカードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。\nまた、このカードのカウンターを１つ取り除く事で、フィールド上の魔法・罠カード１枚を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ナノブレイカー",h:"なのぶれいかー",e:"Nanobreaker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカードがレベル３以下の表側表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"連弾の魔術師",h:"れんだんのまじゅつし",e:"Rapid-Fire Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、自分が通常魔法を発動する度に、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の尖兵 ベージ",h:"あんこくかいのせんぺい　べーじ",e:"Beiige, Vanguard of Dark World",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の狩人 ブラウ",h:"あんこくかいのかりうど　ぶらう",e:"Broww, Huntsman of Dark World",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、自分のデッキからカードを１枚ドローする。\n相手のカードの効果によって捨てられた場合、さらにもう１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の狂王 ブロン",h:"あんこくかいのきょうおう　ぶろん",e:"Brron, Mad King of Dark World",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分は手札を１枚選択して捨てる事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の軍神 シルバ",h:"あんこくかいのぐんしん　しるば",e:"Sillva, Warlord of Dark World",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:1400,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\n相手のカードの効果によって捨てられた場合、さらに相手は手札を２枚選択して好きな順番でデッキの下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の武神 ゴルド",h:"あんこくかいのぶしん　ごるど",e:"Goldd, Wu-Lord of Dark World",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:1400,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\n相手のカードの効果によって捨てられた場合、さらに相手フィールド上に存在するカードを２枚まで選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の斥候 スカー",h:"あんこくかいのせっこう　すかー",e:"Scarr, Scout of Dark World",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからレベル４以下の「暗黒界」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依装着－アウス",h:"ひょういそうちゃく－あうす",e:"Familiar-Possessed - Aussa",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「地霊使いアウス」１体と地属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依装着－エリア",h:"ひょういそうちゃく－えりあ",e:"Familiar-Possessed - Eria",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「水霊使いエリア」１体と水属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依装着－ヒータ",h:"ひょういそうちゃく－ひーた",e:"Familiar-Possessed - Hiita",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「火霊使いヒータ」１体と炎属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依装着－ウィン",h:"ひょういそうちゃく－うぃん",e:"Familiar-Possessed - Wynn",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「風霊使いウィン」１体と風属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"貪欲な壺",h:"どんよくなつぼ",e:"Pot of Avarice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のモンスター５体を対象として発動できる。\nそのモンスター５体をデッキに加えてシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の雷",h:"あんこくかいのいかずち",e:"Dark World Lightning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に裏側表示で存在するカード１枚を選択して破壊する。\nその後、自分の手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブランチ",h:"ぶらんち",e:"Branch!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"融合モンスターがフィールド上で破壊され墓地に送られた時、自分の墓地に存在するその融合モンスターの融合に使用した融合素材モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ボスラッシュ",h:"ぼすらっしゅ",e:"Boss Rush",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分が通常召喚していないターンにこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分は通常召喚できない。\n②：自分フィールドの表側表示の「巨大戦艦」モンスターが破壊され墓地へ送られた場合、そのターンのエンドフェイズに発動できる。\nデッキから「巨大戦艦」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界に続く結界通路",h:"あんこくかいにつづくけっかいつうろ",e:"Gateway to Dark World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「暗黒界」と名のついたモンスター１体を選択して特殊召喚する。\nこのカードを発動するターン、自分は召喚・反転召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒よりの軍勢",h:"あんこくよりのぐんぜい",e:"The Forces of Darkness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「暗黒界」と名のついたモンスター２体を選択して手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の取引",h:"やみのとりひき",e:"Dark Deal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の通常魔法発動時に１０００ライフポイントを払って発動する事ができる。\nその時相手が発動した通常魔法の効果は、「相手はランダムに手札を１枚捨てる」となる。",li:"",lk:[],ta:[],al:[]},
  {n:"二者一両損",h:"にしゃいちりょうぞん",e:"Simultaneous Loss",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いに自分のデッキの一番上のカード１枚を墓地に送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ふるい落とし",h:"ふるいおとし",e:"Weed Out",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払う。\nフィールド上に表側攻撃表示で存在する、全てのレベル３モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"同姓同名同盟",h:"どうせいどうめいどうめい",e:"The League of Uniform Nomenclature",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、レベル２以下の通常モンスター１体を選択して発動する。\n自分のデッキから、選択したカードと同名のカードを可能な限り自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲットライド！",h:"げっとらいど！",e:"Roll Out!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地のユニオンモンスター１体を選択し、自分フィールド上の装備可能なモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"融合禁止エリア",h:"ゆうごうきんしえりあ",e:"Non-Fusion Area",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのプレイヤーは融合召喚をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル制限A地区",h:"れべるせいげんＡちく",e:"Level Limit - Area A",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側表示で存在するレベル３以下のモンスターは全て攻撃表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・チェンジャー",h:"あーむど・ちぇんじゃー",e:"Armed Changer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分の手札から装備魔法カード１枚を墓地に送って発動する。\n装備モンスターが戦闘によってモンスターを破壊した場合、装備カードのコントローラーは自分の墓地から装備モンスターの攻撃力以下のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"テュアラティン",h:"てゅあらてぃん",e:"Tualatin",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2500,tx:"相手のバトルフェイズ時に発動する事ができる。\nバトルフェイズ開始時に自分フィールド上にモンスターが２体以上存在し、それらのモンスターが１度のバトルフェイズ中に戦闘によって全て破壊され墓地へ送られた時、このカードを手札から特殊召喚する事ができる。\nこの効果で特殊召喚に成功した時、属性を１つ宣言し、フィールド上に表側表示で存在する宣言した属性のモンスターを全て破壊する。\nその後、このカードがフィールド上に表側表示で存在する限り、相手は宣言された属性のモンスターを召喚・特殊召喚する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ネクロダークマン",h:"えれめんたるひーろー　ねくろだーくまん",e:"Elemental HERO Necroshade",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカードが墓地に存在する限り１度だけ、自分はレベル５以上の「E・HERO」モンスター１体をリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー・ヘイロー",h:"ひーろー・へいろー",e:"Hero Ring",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、攻撃力１５００以下の戦士族モンスター１体に装備する。\n相手の攻撃力１９００以上のモンスターは、装備モンスターを攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO マッドボールマン",h:"えれめんたるひーろー　まっどぼーるまん",e:"Elemental HERO Mudballman",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:3000,tx:"「E・HERO バブルマン」＋「E・HERO クレイマン」\nこのモンスターは融合召喚でしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔導の執行官",h:"ぶらっく・えくすきゅーしょなー",e:"Dark Eradicator Warlock",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2100,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「ブラック・マジシャン」１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分または相手が通常魔法カードを発動する度に、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣 ケルベロス",h:"まじっくびーすと　けるべろす",e:"Mythical Beast Cerberus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×５００アップする。\n③：このカードが戦闘を行ったバトルフェイズ終了時にこのカードの魔力カウンターは全て取り除かれる。",li:"",lk:[],ta:[],al:[]},
  {n:"マジックブラスト",h:"まじっくぶらすと",e:"Magical Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法使い族モンスターの数×２００ダメージを相手に与える。\n②：このカードが墓地に存在する場合、自分ドローフェイズのドロー前に発動できる。\nこのターン通常のドローを行う代わりに、このカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"King of Destruction - Xexex",h:"King of Destruction - Xexex",e:"King of Destruction - Xexex",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by Tributing 3 Fiend-Type monsters on your side of the field. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"Queen of Fate - Eternia",h:"Queen of Fate - Eternia",e:"Queen of Fate - Eternia",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by Tributing 3 Fairy-Type monsters on your side of the field. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO スチーム・ヒーラー",h:"えれめんたるひーろー　すちーむ・ひーらー",e:"Elemental HERO Steam Healer",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1800,de:1000,tx:"「E・HERO バーストレディ」＋「E・HERO バブルマン」\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"バースト・リターン",h:"ばーすと・りたーん",e:"Burst Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「E・HERO バーストレディ」が自分フィールド上に表側表示で存在する時のみ発動する事ができる。\nフィールド上の「E・HERO バーストレディ」以外の「E・HERO」と名のついたモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"バブル・ショット",h:"ばぶる・しょっと",e:"Bubble Blaster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO バブルマン」にのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\n装備モンスターが戦闘で破壊される場合、代わりにこのカードを破壊し、装備モンスターのコントローラーへの戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"バブルイリュージョン",h:"ばぶるいりゅーじょん",e:"Bubble Illusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「E・HERO バブルマン」が自分フィールド上に表側表示で存在する時のみ発動する事ができる。\nこのターン、自分は手札から罠カード１枚を発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クレイ・チャージ",h:"くれい・ちゃーじ",e:"Clay Charge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「E・HERO クレイマン」が攻撃対象に選択された時に発動する事ができる（選択されたカードがセットされている場合、そのカードをめくって確認する）。\n攻撃モンスターと選択された「E・HERO クレイマン」を破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン LV１０",h:"あーむど・どらごん　れべる１０",e:"Armed Dragon LV10",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2000,tx:"このカードは通常召喚できない。\n自分フィールドの「アームド・ドラゴン LV７」１体をリリースした場合のみ特殊召喚できる。\n①：手札を１枚墓地へ送って発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"打ち出の小槌",h:"うちでのこづち",e:"Magical Mallet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札を任意の数だけデッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄の暴走召喚",h:"じごくのぼうそうしょうかん",e:"Inferno Reckless Summon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：相手フィールドに表側表示モンスターが存在し、自分フィールドに攻撃力１５００以下のモンスター１体のみが特殊召喚された時に発動できる。\nその特殊召喚したモンスターの同名モンスターを自分の手札・デッキ・墓地から可能な限り攻撃表示で特殊召喚し、相手は自身のフィールドの表側表示モンスター１体を選び、そのモンスターの同名モンスターを自身の手札・デッキ・墓地から可能な限り特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"防御輪",h:"ぼうぎょりん",e:"Ring of Defense",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"罠カードの効果によるダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"復活の墓穴",h:"ふっかつのはかあな",e:"The Grave of Enkindling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n自分と相手はそれぞれの墓地からモンスター１体を選択し、守備表示でフィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールド上に表側表示で存在する限り表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ランサー・ドラゴニュート",h:"らんさー・どらごにゅーと",e:"Lancer Dragonute",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスト・ボディ",h:"みすと・ぼでぃ",e:"Mist Body",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"アックス・ドラゴニュート",h:"あっくす・どらごにゅーと",e:"Axe Dragonute",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ホワイト・ホーンズ・ドラゴン",h:"ほわいと・ほーんず・どらごん",e:"White-Horned Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1400,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手の墓地の魔法カードを５枚まで対象として発動する。\nそのカードを除外し、このカードの攻撃力はその除外したカードの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"小天使テルス",h:"しょうてんしてるす",e:"Tellus the Little Angel",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンから墓地へ送られた場合に発動できる。\n自分フィールドに「テルスの羽トークン」（天使族・光・星１・攻／守０）１体を特殊召喚する。\n②：自分フィールドに「テルスの羽トークン」が存在する場合、墓地のこのカードと手札の魔法カード１枚を除外して発動できる。\n自分フィールドに「テルスの羽トークン」２体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は手札からしかモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"闇紅の魔導師",h:"だーくれっど・えんちゃんたー",e:"Dark Red Enchanter",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:2200,tx:"このカードが召喚に成功した時、このカードに魔力カウンターを２つ置く。\nこのカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに乗っている魔力カウンター１つにつき、このカードの攻撃力は３００ポイントアップする。\n１ターンに１度、このカードに乗っている魔力カウンターを２つ取り除く事で、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"冥府の使者ゴーズ",h:"めいふのししゃごーず",e:"Gorz the Emissary of Darkness",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2700,de:2500,tx:"自分フィールド上にカードが存在しない場合、相手がコントロールするカードによってダメージを受けた時、このカードを手札から特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、受けたダメージの種類により以下の効果を発動する。\n●戦闘ダメージの場合、自分フィールド上に「冥府の使者カイエントークン」（天使族・光・星７・攻／守？）を１体特殊召喚する。\nこのトークンの攻撃力・守備力は、この時受けた戦闘ダメージと同じ数値になる。\n●カードの効果によるダメージの場合、受けたダメージと同じダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"神炎皇ウリア",h:"しんえんおううりあ",e:"Uria, Lord of Searing Flames",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの表側表示の罠カード３枚を墓地へ送った場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地の永続罠カードの数×１０００アップする。\n②：１ターンに１度、相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nセットされたそのカードを破壊する。\nこの効果の発動に対して魔法・罠カードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"降雷皇ハモン",h:"こうらいおうはもん",e:"Hamon, Lord of Striking Thunder",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールドの表側表示の永続魔法カード３枚を墓地へ送った場合のみ特殊召喚できる。\n①：このカードがモンスターゾーンに守備表示で存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻魔皇ラビエル",h:"げんまおうらびえる",e:"Raviel, Lord of Phantasms",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールドの悪魔族モンスター３体をリリースした場合のみ特殊召喚できる。\n①：１ターンに１度、このカード以外の自分フィールドのモンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分アップする。\n②：相手がモンスターの召喚に成功した場合に発動する。\n自分フィールドに「幻魔トークン」（悪魔族・闇・星１・攻／守１０００）１体を特殊召喚する。\nこのトークンは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO バブルマン・ネオ",h:"えれめんたるひーろー　ばぶるまん・ねお",e:"Elemental HERO Neo Bubbleman",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:800,de:1200,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「E・HERO バブルマン」と手札の「突然変異」を墓地へ送った場合のみ特殊召喚する事ができる。\nこのカードはフィールド上に表側表示で存在する限り、カード名を「E・HERO バブルマン」として扱う。\nこのカードと戦闘を行った相手モンスターをダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー・キッズ",h:"ひーろー・きっず",e:"Hero Kid",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"このカードが特殊召喚に成功した時、デッキから「ヒーロー・キッズ」を任意の枚数特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・バリア・ドラゴン",h:"さいばー・ばりあ・どらごん",e:"Cyber Barrier Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:800,de:2800,tx:"このカードは通常召喚できない。\nこのカードは「アタック・リフレクター・ユニット」の効果でのみ特殊召喚する事ができる。\nこのカードが攻撃表示の場合、１ターンに１度だけ相手モンスター１体の攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・レーザー・ドラゴン",h:"さいばー・れーざー・どらごん",e:"Cyber Laser Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカードは通常召喚できない。\nこのカードは「フォトン・ジェネレーター・ユニット」の効果でのみ特殊召喚する事ができる。\nこのカードの攻撃力以上の攻撃力か守備力を持つモンスター１体を破壊する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の歯車",h:"あんてぃーく・ぎあ",e:"Ancient Gear",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"自分フィールド上に「古代の歯車」が表側表示で存在する時、手札からこのカードを攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーローハート",h:"ひーろーはーと",e:"Hero Heart",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「E・HERO」と名のついたモンスター１体を選択して発動する。\nこのターン選択したモンスターの攻撃力は半分になり、１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"磁力の召喚円 LV２",h:"まぐねっとさーくる　れべる２",e:"Magnet Circle LV2",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札からレベル２以下の機械族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械工場",h:"あんてぃーく・ぎあふぁくとりー",e:"Ancient Gear Factory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「アンティーク・ギア」と名のついたモンスターカード１枚を選択する。\n選択したカードの倍のレベルになるように墓地の「アンティーク・ギア」と名のつくカードをゲームから除外する。\n選択したカードはこのターンに限り召喚時に生け贄は必要なくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の採掘機",h:"あんてぃーく・ぎあどりる",e:"Ancient Gear Drill",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「アンティーク・ギア」モンスターが存在する場合、手札を１枚捨てて発動できる。\nデッキから魔法カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはこのターン発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻魔の殉教者",h:"げんまのじゅんきょうしゃ",e:"Phantasmal Martyrs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このカード以外の自分の手札が２枚以上存在し、自分フィールドに「神炎皇ウリア」または「降雷皇ハモン」が存在する場合、手札を全て墓地へ送って発動できる。\n自分フィールドに「幻魔の殉教者トークン」（悪魔族・闇・星１・攻／守０）３体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイクロン・ブーメラン",h:"さいくろん・ぶーめらん",e:"Cyclone Boomerang",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO ワイルドマン」にのみ装備可能。\n装備モンスターの攻撃力は５００ポイントアップする。\n装備モンスターが他のカードの効果によって破壊され墓地へ送られた時、フィールド上の魔法・罠カードを全て破壊する。\n破壊した魔法・罠カードの枚数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・ジェネレーター・ユニット",h:"ふぉとん・じぇねれーたー・ゆにっと",e:"Photon Generator Unit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「サイバー・ドラゴン」２体を生け贄に捧げて発動する。\n自分の手札・デッキ・墓地から「サイバー・レーザー・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械城",h:"あんてぃーく・ぎあきゃっする",e:"Ancient Gear Castle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：フィールドの「アンティーク・ギア」モンスターの攻撃力は３００アップする。\n②：このカードが魔法＆罠ゾーンに存在する限り、モンスターが通常召喚される度に、このカードにカウンターを１つ置く。\n③：自分が「アンティーク・ギア」モンスターを表側表示でアドバンス召喚する場合、必要なリリースの数以上のカウンターが置かれたこのカードを代わりにリリースしてアドバンス召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパージュニア対決！",h:"すーぱーじゅにあたいけつ！",e:"Super Junior Confrontation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\nその戦闘を無効にし、相手フィールド上の一番攻撃力の低い表側攻撃表示モンスター１体と、自分フィールド上の一番守備力の低い表側守備表示モンスター１体で戦闘を行う。\nその戦闘終了後、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラクル・キッズ",h:"みらくる・きっず",e:"Miracle Kids",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エンドフェイズ時まで相手モンスター１体の攻撃力は、自分の墓地に存在する「ヒーロー・キッズ」の枚数×４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"アタック・リフレクター・ユニット",h:"あたっく・りふれくたー・ゆにっと",e:"Attack Reflector Unit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「サイバー・ドラゴン」１体を生け贄に捧げて発動する。\n自分の手札・デッキから「サイバー・バリア・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・コンデンサー",h:"だめーじ・こんでんさー",e:"Damage Condenser",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘ダメージを受けた時、手札を１枚捨てて発動できる。\n受けたそのダメージの数値以下の攻撃力を持つモンスター１体をデッキから表側攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"埋蔵金の地図",h:"まいぞうきんのちず",e:"Treasure Map",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードを手札に戻す効果が発動した時に発動する事ができる。\n自分のデッキからカードを２枚ドローし、その後手札を１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロースピリッツ",h:"ひーろーすぴりっつ",e:"Hero Spirit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「E・HERO」と名のついたモンスターが戦闘によって破壊された場合、そのターンのバトルフェイズ中に発動する事ができる。\n相手モンスター１体からの戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械砲台",h:"あんてぃーく・ぎあきゃのん",e:"Ancient Gear Cannon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードを生け贄に捧げる。\n相手ライフに５００ポイントダメージを与え、このターンのバトルフェイズ中はお互いに罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"プロト・サイバー・ドラゴン",h:"ぷろと・さいばー・どらごん",e:"Proto-Cyber Dragon",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:600,tx:"このカードのカード名は、フィールド上に表側表示で存在する限り「サイバー・ドラゴン」として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"瞬着ボマー",h:"しゅんちゃくぼまー",e:"Adhesive Explosive",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"裏側守備表示のこのカードを相手モンスターが攻撃した場合、ダメージ計算を行わずこのカードは攻撃モンスターの装備カードになる。\n次の相手ターンのスタンバイフェイズ時、その装備モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機械王－プロトタイプ",h:"きかいおう－ぷろとたいぷ",e:"Machine King Prototype",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"フィールド上に存在するこのカード以外の機械族モンスター１体につき、このカードの攻撃力・守備力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大戦艦 カバード・コア",h:"きょだいせんかん　かばーど・こあ",e:"B.E.S. Covered Core",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:800,tx:"このカードの召喚時にカウンターを２つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にコイントスで裏表を当てる。\nハズレの場合、カードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元への案内人",h:"いじげんへのあんないにん",e:"D.D. Guide",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードの召喚に成功した時、このカードのコントロールは相手に移る。\nエンドフェイズ毎に、相手はこのカードのコントローラーの墓地からカード１枚を選択しゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"チェイン・スラッシャー",h:"ちぇいん・すらっしゃー",e:"Chain Thrasher",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードは通常の攻撃に加えて、自分の墓地に存在する「チェイン・スラッシャー」の数だけ１度のバトルフェイズ中に攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"封魔の伝承者",h:"ふうまのでんしょうしゃ",e:"Disciple of the Forbidden Spell",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分の墓地に存在する「封魔の伝承者」の数だけ属性を宣言する。\nこのカードが宣言した属性のモンスターに攻撃を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天下人 紫炎",h:"てんかびと　しえん",e:"Tenkabito Shien",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは罠の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"寄生体ダニー",h:"きせいたいだにー",e:"Parasitic Ticky",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、フィールド上のトークンの数×５００ポイントの数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴキポン",h:"ごきぽん",e:"Gokipon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の昆虫族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"静寂虫",h:"さいれんと・いんせくと",e:"Silent Insect",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカードは召喚・反転召喚に成功した場合守備表示になる。\nこのカードがフィールド上に表側表示で存在する限り、永続魔法・永続罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"電動刃虫",h:"ちぇーんそー・いんせくと",e:"Chainsaw Insect",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカードが戦闘を行った場合、ダメージステップ終了時に相手プレイヤーはカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"オオアリクイクイアリ",h:"おおありくいくいあり",e:"Anteatereatingant",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:500,tx:"このカードは通常召喚できない。\n自分フィールド上の魔法・罠カード２枚を墓地へ送った場合のみ特殊召喚する事ができる。\nこのカードは攻撃をするかわりに相手フィールド上の魔法・罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイバー・ビートル",h:"せいばー・びーとる",e:"Saber Beetle",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:600,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デビルドーザー",h:"でびるどーざー",e:"Doom Dozer",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカードは通常召喚できない。\n自分の墓地の昆虫族モンスター２体をゲームから除外した場合のみ特殊召喚する事ができる。\nこのカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"黄泉ガエル",h:"よみがえる",e:"Treeborn Frog",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが墓地に存在し、自分フィールドに「黄泉ガエル」が存在しない場合、自分スタンバイフェイズに発動できる。\nこのカードを特殊召喚する。\nこの効果は自分フィールドに魔法・罠カードが存在しない場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔ガエル",h:"あまがえる",e:"Beelze Frog",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードの攻撃力は、自分の墓地に存在する「悪魂邪苦止」の枚数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法の国の王女－ピケル",h:"ぷりんせす－ぴける",e:"Princess Pikeru",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できない。\nこのカードは「王女の試練」の効果でのみ特殊召喚する事ができる。\n自分のスタンバイフェイズ時、自分フィールド上に存在するモンスターの数×８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法の国の王女－クラン",h:"ぷりんせす－くらん",e:"Princess Curran",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できない。\nこのカードは「王女の試練」の効果でのみ特殊召喚する事ができる。\n自分のスタンバイフェイズ時、相手フィールド上に存在するモンスターの数×６００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"記憶破壊者",h:"めもりー・くらっしゃー",e:"Memory Crusher",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードが相手プレイヤーへの直接攻撃に成功した場合、相手の融合デッキの枚数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"増幅する悪意",h:"ぞうふくするまりす",e:"Malice Ascendant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:700,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、相手ターンのスタンバイフェイズ時に自分の墓地に存在する「増幅する悪意」の枚数だけ、相手のデッキの上からカードを墓地に送る。",li:"",lk:[],ta:[],al:[]},
  {n:"グラスファントム",h:"ぐらすふぁんとむ",e:"Grass Phantom",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードの攻撃力は、自分の墓地に存在する「グラスファントム」の枚数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サンドモス",h:"さんどもす",e:"Sand Moth",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"裏側守備表示のこのカードが戦闘以外によって破壊され墓地へ送られた時、元々の攻撃力と守備力を入れ替えて自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神竜－エクセリオン",h:"しんりゅう－えくせりおん",e:"Divine Dragon - Excelion",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"このカードの召喚時に自分の墓地に存在する「神竜－エクセリオン」１体につき、以下の効果を１つ得る。\nただし同じ効果を重複して得る事はできない。\n\n●このカードの攻撃力は１０００ポイントアップする。\n\n●このカードが戦闘によって相手モンスターを破壊した場合、もう一度だけ続けて攻撃を行う事ができる。\n\n●このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅の女神ルイン",h:"はめつのめがみるいん",e:"Ruin, Queen of Oblivion",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:2000,tx:"「エンド・オブ・ザ・ワールド」により降臨。\n①：このカードの攻撃で相手モンスターを破壊した時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉の王デミス",h:"しゅうえんのおうでみす",e:"Demise, King of Armageddon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「エンド・オブ・ザ・ワールド」により降臨。\n①：２０００LPを払って発動できる。\nフィールドの他のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガエル・サンデス",h:"がえる・さんです",e:"D.3.S. Frog",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「デスガエル」＋「デスガエル」＋「デスガエル」\nこのモンスターの融合召喚は、上記のカードでしか行えない。\nこのカードの攻撃力は、自分の墓地に存在する「黄泉ガエル」の枚数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"継承の印",h:"けいしょうのしるし",e:"Symbol of Heritage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分の墓地に同名モンスターカードが３枚存在する時に発動する事ができる。\nそのモンスター１体を選択して自分フィールド上に特殊召喚し、このカードを装備する。\nこのカードが破壊された時、装備モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王女の試練",h:"おうじょのしれん",e:"Trial of the Princesses",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「白魔導士ピケル」「黒魔導師クラン」にのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\n装備モンスターがレベル５以上のモンスターを戦闘によって破壊したターン、装備モンスターとこのカードを生け贄に捧げる事で、「白魔導士ピケル」は「魔法の国の王女－ピケル」を、「黒魔導師クラン」は「魔法の国の王女－クラン」を手札またはデッキから１体特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エンド・オブ・ザ・ワールド",h:"えんど・おぶ・ざ・わーるど",e:"End of the World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「破滅の女神ルイン」「終焉の王デミス」の降臨に使用する事ができる。\nフィールドか手札から、儀式召喚するモンスターと同じレベルになるように生け贄を捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"輪廻転生",h:"りんねてんしょう",e:"Samsara",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"儀式召喚の生け贄にしたモンスターカードは墓地へは行かず、持ち主のデッキに戻る。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"因果切断",h:"いんがせつだん",e:"Karma Cut",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てて発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択してゲームから除外する。\nこの効果によって除外したモンスターと同名のカードが相手の墓地に存在する場合、さらにその同名カードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ロスト・ネクスト",h:"ろすと・ねくすと",e:"Next to be Lost",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択する。\n自分のデッキから選択したカードと同名カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネレーション・チェンジ",h:"じぇねれーしょん・ちぇんじ",e:"Generation Shift",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して破壊する。\nその後、デッキから破壊したカードと同名のカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"全弾発射",h:"ふるばーすと",e:"Full Salvo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードの発動後、手札を全て墓地へ送る。\n墓地に送ったカードの枚数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"成功確率０％",h:"せいこうかくりつ０％",e:"Success Probability 0%",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の融合デッキから融合モンスター２体をランダムに墓地に送る。",li:"",lk:[],ta:[],al:[]},
  {n:"オプションハンター",h:"おぷしょんはんたー",e:"Option Hunter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n自分は破壊されたモンスターの元々の攻撃力分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリンのその場しのぎ",h:"ごぶりんのそのばしのぎ",e:"Goblin Out of the Frying Pan",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"５００ライフポイントを払う。\n魔法カードの発動を無効にし、そのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"誤作動",h:"まるふぁんくしょん",e:"Malfunction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"５００ライフポイントを払う。\n罠カードの発動を無効にし、そのカードを元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"クリボーを呼ぶ笛",h:"くりぼーをよぶふえ",e:"The Flute of Summoning Kuriboh",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分のデッキから「クリボー」または「ハネクリボー」１体を選択し、手札に加えるか自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO エリクシーラー",h:"えれめんたるひーろー　えりくしーらー",e:"Elemental HERO Electrum",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2600,tx:"「E・HERO フェザーマン」＋「E・HERO バーストレディ」＋「E・HERO クレイマン」＋「E・HERO バブルマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカードの属性は「風」「水」「炎」「地」としても扱う。\nこのカードが融合召喚に成功した時、ゲームから除外された全てのカードを持ち主のデッキに戻し、デッキをシャッフルする。\n相手フィールド上に存在するこのカードと同じ属性のモンスター１体につき、このカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"守護神エクゾード",h:"しゅごしんえくぞーど",e:"Exxod, Master of The Guard",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:4000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「スフィンクス」と名のついたモンスター１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、地属性モンスターが反転召喚に成功した時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"グレート・スピリット",h:"ぐれーと・すぴりっと",e:"Great Spirit",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、フィールド上の地属性モンスター１体を選択して発動できる。\n選択した地属性モンスターの元々の攻撃力と元々の守備力を、エンドフェイズ時まで入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"断層地帯",h:"だんそうちたい",e:"Canyon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"守備表示の岩石族モンスターが攻撃され、攻撃モンスターのコントローラーが戦闘ダメージを受ける場合、その戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ワイルド・ウィングマン",h:"えれめんたるひーろー　わいるど・うぃんぐまん",e:"Elemental HERO Wild Wingman",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:2300,tx:"「E・HERO ワイルドマン」＋「E・HERO フェザーマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n手札を１枚捨てる事で、フィールド上の魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ネクロイド・シャーマン",h:"えれめんたるひーろー　ねくろいど・しゃーまん",e:"Elemental HERO Necroid Shaman",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:1800,tx:"「E・HERO ワイルドマン」＋「E・HERO ネクロダークマン」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを破壊する。\nその後、相手の墓地からモンスター１体を選び、相手フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金のホムンクルス",h:"おうごんのほむんくるす",e:"Golden Homunculus",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードの攻撃力・守備力は、ゲームから除外されている自分のカードの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"原始太陽ヘリオス",h:"げんしたいようへりおす",e:"Helios - The Primordial Sun",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、ゲームから除外されているモンスターの数×１００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘリオス・デュオ・メギストス",h:"へりおす・でゅお・めぎすとす",e:"Helios Duo Megistus",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは自分フィールド上の「原始太陽ヘリオス」１体を生け贄に捧げる事で特殊召喚する事ができる。\nこのカードの攻撃力と守備力は、ゲームから除外されているモンスターカードの数×２００ポイントになる。\nこのカードが戦闘によって破壊され墓地へ送られた場合、エンドフェイズ時に攻撃力・守備力を３００ポイントアップさせて特殊召喚される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘリオス・トリス・メギストス",h:"へりおす・とりす・めぎすとす",e:"Helios Trice Megistus",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは自分フィールド上の「ヘリオス・デュオ・メギストス」１体を生け贄に捧げる事で特殊召喚する事ができる。\nこのカードの攻撃力と守備力は、ゲームから除外されているモンスターカードの数×３００ポイントになる。\nこのカードが戦闘によって破壊され墓地へ送られた場合、エンドフェイズ時に攻撃力・守備力を５００ポイントアップさせて特殊召喚される。\n相手フィールド上にモンスターが存在する場合、もう１度だけ続けて攻撃を行う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ネオス",h:"えれめんたるひーろー　ねおす",e:"Elemental HERO Neos",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2500,de:2000,tx:"ネオスペースからやってきた新たなるE・HERO。\nネオスペーシアンとコンタクト融合する事で、未知なる力を発揮する！",li:"",lk:[],ta:[],al:[]},
  {n:"ダンディライオン",h:"だんでぃらいおん",e:"Dandylion",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードが墓地へ送られた場合に発動する。\n自分フィールドに「綿毛トークン」（植物族・風・星１・攻／守０）２体を守備表示で特殊召喚する。\nこのトークンは特殊召喚されたターン、アドバンス召喚のためにはリリースできない。",li:0,lk:[],ta:[],al:[]},
  {n:"E・HERO フェニックスガイ",h:"えれめんたるひーろー　ふぇにっくすがい",e:"Elemental HERO Phoenix Enforcer",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1200,tx:"「E・HERO フェザーマン」＋「E・HERO バーストレディ」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカードは戦闘によっては破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO シャイニング・フェニックスガイ",h:"えれめんたるひーろー　しゃいにんぐ・ふぇにっくすがい",e:"Elemental HERO Shining Phoenix Enforcer",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「E・HERO フェニックスガイ」＋「E・HERO スパークマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカードの攻撃力は、自分の墓地の「E・HERO」と名のついたカード１枚につき３００ポイントアップする。\nこのカードは戦闘によっては破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO セイラーマン",h:"えれめんたるひーろー　せいらーまん",e:"Elemental HERO Mariner",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:1000,tx:"「E・HERO バブルマン」＋「E・HERO フェザーマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n自分の魔法＆罠カードゾーンにカードがセットされている場合、このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO デビルガイ",h:"ですてにーひーろー　でびるがい",e:"Destiny HERO - Doom Lord",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する場合、１ターンに１度だけ相手モンスター１体をゲームから除外する事ができる。\nこの効果を使用したプレイヤーはこのターン戦闘を行えない。\nこの効果によって除外したモンスターは、２回目の自分のスタンバイフェイズ時に同じ表示形式で相手フィールド上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダイハードガイ",h:"ですてにーひーろー　だいはーどがい",e:"Destiny HERO - Captain Tenacious",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが自分フィールド上に表側表示で存在する時、このカードを除く自分フィールド上の「D-HERO」と名のついたモンスターが戦闘によって破壊され墓地へ送られた場合、そのモンスター１体を次の自分のスタンバイフェイズ時に自分フィールド上に特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダイヤモンドガイ",h:"ですてにーひーろー　だいやもんどがい",e:"Destiny HERO - Diamond Dude",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分のデッキの一番上のカードをめくり、それが通常魔法カードだった場合、そのカードを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nこの効果で通常魔法カードを墓地へ送った場合、次の自分ターンのメインフェイズに墓地のその通常魔法カードの発動時の効果を発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドレッドガイ",h:"ですてにーひーろー　どれっどがい",e:"Destiny HERO - Dreadmaster",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"「幽獄の時計塔」の効果で特殊召喚した場合、自分フィールド上の「D-HERO」と名のついたモンスター以外の自分のモンスターを全て破壊する。\nその後、自分の墓地から「D-HERO」と名のついたモンスターを２体まで特殊召喚する事ができる。\nこのカードが特殊召喚されたターン、自分フィールド上の「D-HERO」と名のついたモンスターは破壊されず、コントローラーへの戦闘ダメージは０になる。\nこのカードの攻撃力・守備力は、自分フィールド上のこのカードを除く「D-HERO」と名のついたモンスターの元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ジラフ",h:"さいばー・じらふ",e:"Cyber Kirin",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:800,tx:"このカードを生け贄に捧げる。\nこのターンのエンドフェイズまで、このカードのコントローラーへの効果によるダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・フェニックス",h:"さいばー・ふぇにっくす",e:"Cyber Phoenix",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：このカードがモンスターゾーンに攻撃表示で存在する限り、自分フィールドの機械族モンスター１体のみを対象とする魔法・罠カードの効果は無効化される。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・チュチュ",h:"さいばー・ちゅちゅ",e:"Cyber Tutu",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"相手フィールド上に存在する全てのモンスターの攻撃力がこのカードの攻撃力よりも高い場合、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ジムナティクス",h:"さいばー・じむなてぃくす",e:"Cyber Gymnast",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"①：１ターンに１度、手札を１枚捨て、相手フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nその相手の表側攻撃表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・プリマ",h:"さいばー・ぷりま",e:"Cyber Prima",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"①：このカードがアドバンス召喚に成功した場合に発動する。\nフィールドの表側表示の魔法カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガードペナルティ",h:"がーどぺなるてぃ",e:"Guard Penalty",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上のモンスター１体を選択する。\nこのターン選択したモンスターが守備表示になった場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"幽獄の時計塔",h:"ゆうごくのとけいとう",e:"Clock Tower Prison",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"相手ターンのスタンバイフェイズ時に、このカードに時計カウンターを１個乗せる。\n時計カウンターの合計が４個以上になった場合、このカードのコントローラーは戦闘ダメージを受けない。\n時計カウンターが４個以上乗ったこのカードが破壊され墓地へ送られた時、手札またはデッキから「D-HERO ドレッドガイ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスフォーチュン",h:"みすふぉーちゅん",e:"Misfortune",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。\nこのターン自分のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"グランドクロス",h:"ぐらんどくろす",e:"Grand Convergence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「マクロコスモス」が存在する時に発動する事ができる。\n相手ライフに３００ポイントダメージを与え、フィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"H－ヒートハート",h:"えいち－ひーとはーと",e:"H - Heated Heart",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップし、このターンそのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"E－エマージェンシーコール",h:"いー－えまーじぇんしーこーる",e:"E - Emergency Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「E・HERO」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"R－ライトジャスティス",h:"あーる－らいとじゃすてぃす",e:"R - Righteous Justice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「E・HERO」カードの数だけ、フィールドの魔法・罠カードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"O－オーバーソウル",h:"おー－おーばーそうる",e:"O - Oversoul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「E・HERO」通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーローフラッシュ！！",h:"ひーろーふらっしゅ！！",e:"Hero Flash!!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「H－ヒートハート」「E－エマージェンシーコール」「R－ライトジャスティス」「O－オーバーソウル」をゲームから除外して発動する。\n自分のデッキから「E・HERO」と名のついた通常モンスター１体を特殊召喚する。\nこのターン自分フィールド上の「E・HERO」と名のついた通常モンスターは、相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメンタル・チャージ",h:"えれめんたる・ちゃーじ",e:"Elemental Recharge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「E・HERO」と名のついたモンスター１体につき、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"デステニー・デストロイ",h:"ですてにー・ですとろい",e:"Destruction of Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキのカードを上から３枚墓地へ送る。\nこの効果で墓地へ送った魔法・罠カード１枚につき、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"D－タイム",h:"でぃー－たいむ",e:"D - Time",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「E・HERO」と名のついたモンスターがフィールド上から離れた時に発動する事ができる。\nそのモンスターのレベルと同じレベル以下の「D-HERO」と名のついたモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デステニー・シグナル",h:"ですてにー・しぐなる",e:"Destiny Signal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n自分の手札またはデッキから「D-HERO」と名のついたレベル４以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"D－シールド",h:"でぃー－しーるど",e:"D - Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に攻撃表示で存在する「D-HERO」と名のついたモンスターが攻撃対象になった時に発動する事ができる。\nこのカードは装備カードとなり、攻撃対象になったモンスターを守備表示にしてこのカードを装備する。\n装備モンスターは戦闘によっては破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメンタル・アブソーバー",h:"えれめんたる・あぶそーばー",e:"Elemental Absorber",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"手札のモンスターカード１枚をゲームから除外する。\nこの効果によって除外したモンスターと同じ属性を持つ相手モンスターは、このカードがフィールド上に存在する限り攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"マクロコスモス",h:"まくろこすもす",e:"Macro Cosmos",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードの発動時の効果処理として、手札・デッキから「原始太陽ヘリオス」１体を特殊召喚できる。\n②：このカードが魔法＆罠ゾーンに存在する限り、墓地へ送られるカードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"サーチライトメン",h:"さーちらいとめん",e:"Searchlightman",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1000,tx:"リバース：このターン相手プレイヤーはフィールド上にカードをセットする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ビクトリー・バイパー XX０３",h:"びくとりー・ばいぱー　だぶるえっくすぜろすりー",e:"Victory Viper XX03",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊した場合、以下の効果から１つを選択して発動する。\n●このカードの攻撃力は４００アップする。\n●フィールドの表側表示の魔法・罠カード１枚を対象として発動する。\nその表側表示のカードを破壊する。\n●常にこのカードと同じ種族・属性・レベル・攻撃力・守備力を持つ「オプショントークン」１体を自分フィールドに特殊召喚する。\nこのカードがフィールドに表側表示で存在しなくなった時にこのトークンは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"超電磁稼動ボルテック・ドラゴン",h:"ちょうでんじかどうぼるてっく・どらごん",e:"Super-Electromagnetic Voltech Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"以下のモンスターを生け贄にして生け贄召喚した場合、このカードはそれぞれの効果を得る。\n\n●電池メン－単一型：このカード１枚を対象にする魔法・罠カードの効果を無効にする。\n\n●電池メン－単二型：このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n\n●電池メン－単三型：このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィズペット仔竜",h:"はーぴぃずぺっとべびーどらごん",e:"Harpie's Pet Baby Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードは自分フィールド上に存在する「ハーピィズペット仔竜」を除く「ハーピィ」と名のついたモンスターの数により効果を追加する。\n１体：このカードがフィールド上に表側表示で存在する限り、相手は自分フィールド上に存在する「ハーピィズペット仔竜」を除く「ハーピィ」と名のついたモンスターを攻撃対象に選択できない。\n２体：このカードの元々の攻撃力・守備力は倍になる。\n３体：１ターンに１度、相手フィールド上のカード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"光神機－閃空",h:"らいとにんぐぎあ－せんくう",e:"Majestic Mech - Senku",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。\nこのカードが召喚・反転召喚・特殊召喚したターンのエンドフェイズ時、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"光神機－桜火",h:"らいとにんぐぎあ－おうか",e:"Majestic Mech - Ohka",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"このカードは生け贄なしで召喚する事ができる。\nこの方法で召喚した場合、このカードはエンドフェイズ時に墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"光神機－轟龍",h:"らいとにんぐぎあ－ごうりゅう",e:"Majestic Mech - Goryu",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2900,de:1800,tx:"このカードは生け贄１体で召喚する事ができる。\nこの方法で召喚した場合、このカードはエンドフェイズ時に墓地へ送られる。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"豊穣のアルテミス",h:"ほうじょうのあるてみす",e:"Bountiful Artemis",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ロイヤルナイツ",h:"ろいやるないつ",e:"Royal Knight",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの守備力分だけ自分のライフポイントが回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"紫光の宣告者",h:"ばいおれっと・でくれあらー",e:"Herald of Purple Light",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"①：相手が罠カードを発動した時、手札からこのカードと天使族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"緑光の宣告者",h:"ぐりーん・でくれあらー",e:"Herald of Green Light",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"①：相手が魔法カードを発動した時、手札からこのカードと天使族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"救済のレイヤード",h:"きゅうさいのれいやーど",e:"Layard the Liberator",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、除外されている自分の天使族モンスター２体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光の追放者",h:"せんこうのついほうしゃ",e:"Banisher of the Radiance",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、墓地へ送られるカードは墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"裁きを下す者－ボルテニス",h:"さばきをくだすもの－ぼるてにす",e:"Voltanis the Adjudicator",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:1400,tx:"自分のカウンター罠が発動に成功した場合、自分フィールド上のモンスターを全て生け贄に捧げる事で特殊召喚する事ができる。\nこの方法で特殊召喚に成功した場合、生け贄に捧げた天使族モンスターの数まで相手フィールド上のカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガード・ドッグ",h:"がーど・どっぐ",e:"Guard Dog",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:500,tx:"リバース：このターン相手プレイヤーは特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"真空イタチ",h:"しんくういたち",e:"Whirlwind Weasel",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"リバース：このターン相手プレイヤーは魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"荒ぶるアウス",h:"あらぶるあうす",e:"Avalanching Aussa",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の地属性モンスター１体を生け贄に捧げる事で、手札から地属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「荒ぶるアウス」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"逆巻くエリア",h:"さかまくえりあ",e:"Raging Eria",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の水属性モンスター１体を生け贄に捧げる事で、手札から水属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「逆巻くエリア」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"燃え盛るヒータ",h:"もえさかるひーた",e:"Blazing Hiita",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の炎属性モンスター１体を生け贄に捧げる事で、手札から炎属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「燃え盛るヒータ」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"吹き荒れるウィン",h:"ふきあれるうぃん",e:"Storming Wynn",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の風属性モンスター１体を生け贄に捧げる事で、手札から風属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「吹き荒れるウィン」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"電池メン－単一型",h:"でんちめん－たんいちがた",e:"Batteryman D",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は自分フィールド上に存在する「電池メン－単一型」以外の雷族モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"疾風鳥人ジョー",h:"しっぷうちょうじんじょー",e:"Swift Birdman Joe",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2300,de:1400,tx:"風属性モンスターを生け贄にして生け贄召喚に成功した場合、フィールド上の魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"光神化",h:"こうしんか",e:"Celestial Transformation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札から天使族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は半分になり、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"パワーカプセル",h:"ぱわーかぷせる",e:"Power Capsule",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ビクトリー・バイパー XX０３」１体を選択して発動する。\n「ビクトリー・バイパー XX０３」の効果から１つを選択し、このカードの効果として適用する。",li:"",lk:[],ta:[],al:[]},
  {n:"次元の裂け目",h:"じげんのさけめ",e:"Dimensional Fissure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いの墓地へ送られるモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ライフチェンジャー",h:"らいふちぇんじゃー",e:"Life Equalizer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いのライフポイントに８０００ポイント以上の差があった場合に発動する事ができる。\nお互いのライフポイントは３０００になる。",li:0,lk:[],ta:[],al:[]},
  {n:"ゴッドバードアタック",h:"ごっどばーどあたっく",e:"Icarus Attack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの鳥獣族モンスター１体をリリースし、フィールドのカード２枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の光臨",h:"きせきのこうりん",e:"Miraculous Descent",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：除外されている自分の天使族モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"人造天使",h:"しんせてぃっく・えんじぇる",e:"Synthetic Seraphim",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、カウンター罠カードが発動する度に、自分フィールドに「人造天使トークン」（天使族・光・星１・攻／守３００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"キックバック",h:"きっくばっく",e:"Forced Back",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"モンスターの召喚・反転召喚を無効にし、そのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"パペット・クィーン",h:"ぱぺっと・くぃーん",e:"",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:2500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手がドロー以外の方法でデッキからモンスターを手札に加えた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・墓地から戦士族・地属性・レベル７モンスター１体を選んで特殊召喚する。\nこの効果で「パペット・キング」を特殊召喚した場合、自分フィールドの全ての戦士族・地属性モンスターの攻撃力は、次のターンの終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"神鳥シムルグ",h:"しんちょうしむるぐ",e:"Simorgh, Bird of Divinity",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1000,tx:"このカードは特殊召喚できない。\nこのカードをアドバンス召喚する場合、リリースするモンスターは風属性モンスターでなければならない。\nこのカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーはお互いのエンドフェイズ毎に１０００ポイントダメージを受ける。\nこの時、それぞれのプレイヤーが受けるダメージは魔法・罠カードをコントロールしている数×５００ポイント少なくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"ソニック・シューター",h:"そにっく・しゅーたー",e:"Sonic Shooter",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:600,tx:"相手の魔法＆罠カードゾーンにカードが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\nこの時、相手プレイヤーに与える戦闘ダメージはこのカードの元々の攻撃力となる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒステリック・パーティー",h:"ひすてりっく・ぱーてぃー",e:"Hysteric Party",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：手札を１枚捨ててこのカードを発動できる。\n自分の墓地から「ハーピィ・レディ」を可能な限り特殊召喚する。\nこのカードがフィールドから離れた時にこの効果で特殊召喚したモンスターは全て破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"パペット・キング",h:"ぱぺっと・きんぐ",e:"Puppet King",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:2600,tx:"①：相手がドロー以外の方法でデッキからモンスターを手札に加えた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがこのカードの①の効果で特殊召喚に成功した場合、次の自分ターンのエンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼鉄の魔導騎士－ギルティギア・フリード",h:"こうてつのまどうきし－ぎるてぃぎあ・ふりーど",e:"Gilti-Gearfried the Magical Steel Knight",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:1600,tx:"属性が異なる戦士族モンスター×２\n①：１ターンに１度、このカードを対象とする魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にし、フィールドのカード１枚を選んで破壊する。\n②：フィールドのモンスターのみを素材として融合召喚したこのカードは１度のバトルフェイズ中に２回攻撃できる。\n③：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージ計算時に、自分の墓地から魔法カード１枚を除外して発動できる。\nこのカードの攻撃力はターン終了時まで、このカードの守備力の半分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ダイナソー",h:"さいばー・だいなそー",e:"Cyber Dinosaur",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:1900,tx:"相手が手札からモンスターを特殊召喚した時、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO フォレストマン",h:"えれめんたるひーろー　ふぉれすとまん",e:"Elemental HERO Woodsman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：１ターンに１度、自分スタンバイフェイズに発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ジ・アース",h:"えれめんたるひーろー　じ・あーす",e:"Elemental HERO Terra Firma",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO オーシャン」＋「E・HERO フォレストマン」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカード以外の自分フィールドの表側表示の「E・HERO」モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで、リリースしたモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO オーシャン",h:"えれめんたるひーろー　おーしゃん",e:"Elemental HERO Ocean",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：１ターンに１度、自分スタンバイフェイズに自分のフィールド・墓地の「HERO」モンスター１体を対象として発動できる。\nその自分の「HERO」モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"キメラテック・オーバー・ドラゴン",h:"きめらてっく・おーばー・どらごん",e:"Chimeratech Overdragon",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:"?",tx:"「サイバー・ドラゴン」＋機械族モンスター１体以上\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが融合召喚に成功した場合に発動する。\nこのカード以外の自分フィールドのカードを全て墓地へ送る。\n②：このカードの元々の攻撃力・守備力は、このカードの融合素材としたモンスターの数×８００になる。\n③：このカードは１度のバトルフェイズ中に、このカードの融合素材としたモンスターの数まで相手モンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクスプレスロイド",h:"えくすぷれすろいど",e:"Expressroid",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:1600,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時、「エクスプレスロイド」以外の自分の墓地の「ロイド」モンスター２体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"キューキューロイド",h:"きゅーきゅーろいど",e:"Ambulanceroid",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"「ロイド」と名のついたモンスターが自分の墓地から手札に加わった時、そのモンスターを特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・サモン・ブラスター",h:"さいばー・さもん・ぶらすたー",e:"Cyber Summon Blaster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが既に魔法＆罠ゾーンに存在する状態で、機械族モンスターが特殊召喚に成功する度に発動する。\n相手に３００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"サブマリンロイド",h:"さぶまりんろいど",e:"Submarineroid",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこの時、相手ライフに与える戦闘ダメージはこのカードの元々の攻撃力と同じ数値になる。\nまた、このカードが戦闘を行ったダメージステップ終了時、このカードの表示形式を守備表示にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパービークロイド－ジャンボドリル",h:"すーぱーびーくろいど－じゃんぼどりる",e:"Super Vehicroid Jumbo Drill",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「スチームロイド」＋「ドリルロイド」＋「サブマリンロイド」\nこのカードの融合召喚は上記のカードでしか行えない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ステルスロイド",h:"すてるすろいど",e:"Stealthroid",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"自分フィールド上にこのカード以外の「ロイド」と名のついたモンスターが存在する場合、このカードが戦闘を行った自分ターンのバトルフェイズ終了時に、フィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デコイロイド",h:"でこいろいど",e:"Decoyroid",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は「デコイロイド」以外の表側表示のモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トラックロイド",h:"とらっくろいど",e:"Truckroid",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターを装備カード扱いとしてこのカードに装備する。\nこのカードの攻撃力は、装備したモンスターカードの攻撃力分だけアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキューキューロイド",h:"れすきゅーきゅーろいど",e:"Ambulance Rescueroid",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1800,tx:"「レスキューロイド」＋「キューキューロイド」\nこのモンスターは融合召喚でしか特殊召喚できない。\n自分フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時、そのモンスターを守備表示で特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキューロイド",h:"れすきゅーろいど",e:"Rescueroid",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在する「ロイド」と名のついたモンスターが戦闘によって破壊され墓地に送られた時、そのモンスターを手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"進入禁止！No Entry！！",h:"しんにゅうきんし！のーえんとりー！！",e:"No Entry!!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に攻撃表示で存在するモンスターを全て守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパーチャージ",h:"すーぱーちゃーじ",e:"Supercharge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが機械族の「ロイド」モンスターのみの場合、相手モンスターの攻撃宣言時に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ビークロイド・コネクション・ゾーン",h:"びーくろいど・こねくしょん・ぞーん",e:"Vehicroid Connection Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「ビークロイド」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターは効果では破壊されず、そのモンスターの効果は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンダーガレージ",h:"わんだーがれーじ",e:"Wonder Garage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが破壊され墓地へ送られた時、手札からレベル４以下の「ロイド」と名のついた機械族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダガーガイ",h:"ですてにーひーろー　だがーがい",e:"Destiny HERO - Blade Master",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"相手のバトルフェイズ時、手札からこのカードを捨てて発動できる。\n自分フィールド上の全ての「D-HERO」と名のついたモンスターの攻撃力は、エンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダッシュガイ",h:"ですてにーひーろー　だっしゅがい",e:"Destiny HERO - Dasher",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"１ターンに１度、自分フィールド上のモンスター１体をリリースして発動できる。\nこのカードの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\nまた、このカードが墓地に存在する限り１度だけ、自分のドローフェイズ時にカードをドローした時、そのカードがモンスターだった場合、その１体をお互いに確認して自分フィールド上に特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダブルガイ",h:"ですてにーひーろー　だぶるがい",e:"Destiny HERO - Double Dude",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは特殊召喚できない。\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードが破壊され墓地へ送られた場合、次の自分のターンのスタンバイフェイズ時、自分フィールド上に「ダブルガイ・トークン」（戦士族・闇・星４・攻／守１０００）２体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディフェンドガイ",h:"ですてにーひーろー　でぃふぇんどがい",e:"Destiny HERO - Defender",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:2700,tx:"相手のスタンバイフェイズ時、このカードがフィールド上に表側守備表示で存在する場合、相手はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドゥームガイ",h:"ですてにーひーろー　どぅーむがい",e:"Destiny HERO - Fear Monger",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた場合、次の自分のスタンバイフェイズ時に発動する。\n自分の墓地から「D-HERO ドゥームガイ」以外の「D-HERO」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドグマガイ",h:"ですてにーひーろー　どぐまがい",e:"Destiny HERO - Dogma",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3400,de:2400,tx:"このカードは通常召喚できない。\n「D-HERO」モンスターを含む自分フィールドのモンスター３体をリリースした場合のみ特殊召喚できる。\n①：この方法でこのカードが特殊召喚に成功した場合、次の相手スタンバイフェイズに発動する。\n相手のLPを半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・シティ",h:"だーく・してぃ",e:"Dark City",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：「D-HERO」モンスターがその攻撃力より高い攻撃力を持つモンスターを攻撃した場合、攻撃モンスターの攻撃力はダメージ計算時のみ１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"死皇帝の陵墓",h:"しこうていのりょうぼ",e:"Mausoleum of the Emperor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、自分メインフェイズに以下の効果から１つを選択して発動できる。\n\n●１０００LPを払って発動できる。\n１体のリリースを必要とする手札のモンスター１体の通常召喚を、リリースなしで行う。\n\n●２０００LPを払って発動できる。\n２体のリリースを必要とする手札のモンスター１体の通常召喚を、リリースなしで行う。",li:"",lk:[],ta:[],al:[]},
  {n:"D－チェーン",h:"でぃー－ちぇーん",e:"D - Chain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「D-HERO」モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、その自分のモンスターに装備する。\n②：装備モンスターが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"D－スピリッツ",h:"でぃー－すぴりっつ",e:"D - Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「D-HERO」と名のついたモンスターが表側表示で存在しない場合、手札からレベル４以下の「D-HERO」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デステニー・ミラージュ",h:"ですてにー・みらーじゅ",e:"Destiny Mirage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「D-HERO」と名のついたモンスターが相手のカードの効果によって破壊され墓地へ送られた時に発動する事ができる。\nこのターンに破壊され墓地へ送られた「D-HERO」と名のついたモンスターを、全て自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"C・ドルフィーナ",h:"こくーん・どるふぃーな",e:"Chrysalis Dolphin",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:400,de:600,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・アクア・ドルフィン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ブラック・ネオス",h:"えれめんたるひーろー　ぶらっく・ねおす",e:"Elemental HERO Dark Neos",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・ブラック・パンサー」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nフィールド上の効果モンスター１体を選択して発動できる。\nこのカードがフィールド上に表側表示で存在する限り、選択したモンスターの効果は無効化される（この効果で選択できるモンスターは１体まで）。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO アクア・ネオス",h:"えれめんたるひーろー　あくあ・ねおす",e:"Elemental HERO Aqua Neos",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・アクア・ドルフィン」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\n１ターンに１度、手札を１枚捨てる事で、相手の手札をランダムに１枚選んで破壊する。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO フレア・ネオス",h:"えれめんたるひーろー　ふれあ・ねおす",e:"Elemental HERO Flare Neos",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・フレア・スカラベ」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nこのカードの攻撃力は、フィールド上の魔法・罠カードの数×４００ポイントアップする。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"N・アクア・ドルフィン",h:"ねおすぺーしあん・あくあ・どるふぃん",e:"Neo-Spacian Aqua Dolphin",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:800,tx:"①：１ターンに１度、手札を１枚捨てて発動できる。\n相手の手札を確認し、その中からモンスター１体を選ぶ。\n選んだモンスターの攻撃力以上の攻撃力を持つモンスターが自分フィールドに存在する場合、選んだモンスターを破壊し、相手に５００ダメージを与える。\n存在しない場合、自分は５００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"N・エア・ハミングバード",h:"ねおすぺーしあん・えあ・はみんぐばーど",e:"Neo-Spacian Air Hummingbird",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分は相手の手札の数×５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"N・グラン・モール",h:"ねおすぺーしあん・ぐらん・もーる",e:"Neo-Spacian Grand Mole",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:900,de:300,tx:"①：このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターとこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"N・グロー・モス",h:"ねおすぺーしあん・ぐろー・もす",e:"Neo-Spacian Glow Moss",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:900,tx:"このカードが戦闘を行う場合、相手はカードを１枚ドローする。\nこの効果でドローしたカードをお互いに確認し、そのカードの種類によりこのカードは以下の効果を得る。\n●モンスターカード：このターンのバトルフェイズを終了させる。\n●魔法カード：このカードは相手プレイヤーに直接攻撃する事ができる。\n●罠カード：このカードは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"N・ブラック・パンサー",h:"ねおすぺーしあん・ぶらっく・ぱんさー",e:"Neo-Spacian Dark Panther",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nエンドフェイズまで、このカードは、そのモンスターの元々のカード名・効果と同じカード名・効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"N・フレア・スカラベ",h:"ねおすぺーしあん・ふれあ・すからべ",e:"Neo-Spacian Flare Scarab",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードの攻撃力は、相手フィールド上の魔法・罠カードの数×４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コンタクト",h:"こんたくと",e:"Contact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「C」と名のついたモンスター全てを墓地に送り、そのカードに記されているモンスター１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオスペース",h:"ねおすぺーす",e:"Neo Space",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、「E・HERO ネオス」及び「E・HERO ネオス」を融合素材とする融合モンスターの攻撃力は５００ポイントアップする。\nまた、「E・HERO ネオス」を融合素材とする融合モンスターは、エンドフェイズ時にエクストラデッキに戻る効果を発動しなくてもよい。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒ステゴ",h:"ぶらっくすてご",e:"Black Stego",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"フィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"究極恐獣",h:"あるてぃめっとてぃらの",e:"Ultimate Tyranno",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3000,de:2200,tx:"①：自分バトルフェイズに攻撃可能な「究極恐獣」が存在する場合、「究極恐獣」以外のモンスターは攻撃できない。\n②：このカードは相手モンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"星見鳥ラリス",h:"ほしみどりらりす",e:"Rallis the Star Bird",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが戦闘を行う場合、ダメージステップの間このカードの攻撃力は戦闘を行う相手モンスターのレベル×２００ポイントアップする。\nまた、このカードが攻撃したダメージステップ終了時、このカードをゲームから除外し、次の自分のターンのバトルフェイズ開始時に表側攻撃表示で自分フィールド上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フェイク・ヒーロー",h:"ふぇいく・ひーろー",e:"Fake Hero",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「E・HERO」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、このターンのエンドフェイズ時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の共有－コモンソウル",h:"たましいのきょうゆう－こもんそうる",e:"Common Soul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n自分の手札の「N」と名のついたモンスター１体を、選択したモンスターのコントローラーのフィールド上へ特殊召喚する。\n選択したモンスターの攻撃力は、この効果で特殊召喚した「N」と名のついたモンスターの攻撃力分アップする。\nこのカードがフィールド上から離れた時、このカードの効果で特殊召喚した「N」と名のついたモンスター１体を手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"コーリング・マジック",h:"こーりんぐ・まじっく",e:"Spell Calling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手がコントロールする魔法・罠カードの効果によってセットされたこのカードが破壊され墓地へ送られた時、デッキから速攻魔法カード１枚を選択してお互いに確認し自分フィールド上にセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"未来融合－フューチャー・フュージョン",h:"みらいゆうごう－ふゅーちゃー・ふゅーじょん",e:"Future Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動後１回目の自分スタンバイフェイズに発動する。\n自分のEXデッキの融合モンスター１体をお互いに確認し、そのモンスターによって決められた融合素材モンスターを自分のデッキから墓地へ送る。\n②：このカードの発動後２回目の自分スタンバイフェイズに発動する。\nこのカードの①の効果で確認したモンスターと同名の融合モンスター１体をEXデッキから融合召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーロード・フュージョン",h:"おーばーろーど・ふゅーじょん",e:"Overload Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、機械族・闇属性の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO エアーマン",h:"えれめんたるひーろー　えあーまん",e:"Elemental HERO Stratos",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"①：このカードが召喚・特殊召喚に成功した時、以下の効果から１つを選択して発動できる。\n●このカード以外の自分フィールドの「HERO」モンスターの数まで、フィールドの魔法・罠カードを選んで破壊する。\n●デッキから「HERO」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"HERO’S ボンド",h:"ひーろーず　ぼんど",e:"HERO's Bond",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に「HERO」と名のついたモンスターが存在している時に発動する事ができる。\n手札からレベル４以下の「E・HERO」と名のついたモンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧竜－アームド・ドラゴン－",h:"よろいりゅう－あーむど・どらごん－",e:"",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n手札・デッキから「鎧竜－アームド・ドラゴン－」以外のレベル５以下のドラゴン族・風属性モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の騎士",h:"どらごん・ないと",e:"Dragonic Knight",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"自分フィールド上のカードを破壊する効果を相手モンスターが発動した時、対象となった自分のカードを全て墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼータ・レティキュラント",h:"ぜーた・れてぃきゅらんと",e:"Zeta Reticulant",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"①：このカードが墓地に存在し、相手フィールドのモンスターが除外される度に発動する。\n自分フィールドに「イーバトークン」（悪魔族・闇・星２・攻／守５００）１体を特殊召喚する。\n②：このカードは自分フィールドの「イーバトークン」１体をリリースし、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイバーザウルス",h:"せいばーざうるす",e:"Sabersaurus",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1900,de:500,tx:"おとなしい性格で有名な恐竜。\n大草原の小さな巣でのんびりと過ごすのが好きという。\n怒ると恐い。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡のジュラシック・エッグ",h:"きせきのじゅらしっく・えっぐ",e:"Miracle Jurassic Egg",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：フィールドの表側表示のこのカードは除外できない。\n②：このカードがモンスターゾーンに存在する限り、恐竜族モンスターが自分の墓地へ送られる度に、このカードにカウンターを２つ置く。\n③：このカードをリリースして発動できる。\nこのカードに置かれていたカウンターの数以下のレベルを持つ恐竜族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒プテラ",h:"ぶらっくぷてら",e:"Black Ptera",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードが戦闘によって破壊される以外の方法でフィールド上から墓地に送られた時、このカードは持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"ベビケラサウルス",h:"べびけらさうるす",e:"Babycerasaurus",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが効果で破壊され墓地へ送られた場合に発動する。\nデッキからレベル４以下の恐竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・グレイ",h:"えーりあん・ぐれい",e:"Alien Grey",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:800,tx:"リバース：相手フィールド上に表側表示で存在するモンスター１体を選択し、Aカウンターを１つ置く。\nまた、リバースしたこのカードが戦闘によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・マーズ",h:"えーりあん・まーず",e:"Alien Mars",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、「エーリアン・マーズ」以外のフィールド上のAカウンターが乗った効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・スカル",h:"えーりあん・すかる",e:"Alien Skull",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"相手フィールド上のレベル３以下のモンスター１体をリリースし、このカードを手札から相手フィールド上に特殊召喚できる。\nこの方法で特殊召喚する場合、このターン自分は通常召喚できず、特殊召喚時にこのカードにAカウンターを１つ置く。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・ハンター",h:"えーりあん・はんたー",e:"Alien Hunter",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが戦闘によってAカウンターが乗ったモンスターを破壊した場合、もう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・ウォリアー",h:"えーりあん・うぉりあー",e:"Alien Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、このカードを破壊したモンスターにAカウンターを２つ置く。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・マザー",h:"えーりあん・まざー",e:"Alien Mother",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"このカードが戦闘によってAカウンターが乗ったモンスターを破壊し墓地へ送った場合、そのバトルフェイズ終了時に発動する。\n破壊したそのモンスターを自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターは、このカードがフィールド上から離れた場合、全て破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"宇宙獣ガンギル",h:"そらけものがんぎる",e:"Cosmic Horror Gangi'el",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"自分フィールド上に存在する元々の持ち主が相手のモンスターを生け贄に捧げる場合、このカードは生け贄１体で召喚する事ができる。\n１ターンに１度だけ、相手フィールド上モンスター１体にAカウンターを１つ置く事ができる。\nAカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘する場合、Aカウンター１つにつき攻撃力と守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"円盤ムスキー",h:"えんばんむすきー",e:"Flying Saucer Muusik'i",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、自分のドローフェイズに通常のドローを行う代わりに、デッキから「エーリアン」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミステリーサークル",h:"みすてりーさーくる",e:"Crop Circles",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターを任意の数だけ墓地へ送って発動できる。\n墓地へ送ったモンスターのレベルの合計と同じレベルの「エーリアン」と名のついたモンスター１体をデッキから特殊召喚する。\n特殊召喚できなかった場合、自分は２０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"運命の分かれ道",h:"うんめいのわかれみち",e:"The Paths of Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いのプレイヤーはそれぞれコイントスを１回行い、表が出た場合は２０００ライフポイント回復し、裏が出た場合は２０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"大気圏外射撃",h:"たいきけんがいしゃげき",e:"Orbital Bombardment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「エーリアン」と名のついたモンスター１体を墓地に送って発動する。\nフィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"化石発掘",h:"かせきはっくつ",e:"Fossil Excavation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：手札を１枚捨て、自分の墓地の恐竜族モンスター１体を対象としてこのカードを発動できる。\nその恐竜族モンスターを特殊召喚する。\n②：このカードの①の効果で特殊召喚されたモンスターは、このカードが魔法＆罠ゾーンに存在する限り効果が無効化され、このカードがフィールドから離れた時に破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"洗脳光線",h:"せんのうこうせん",e:"Brainwashing Beam",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上のAカウンターが乗ったモンスター１体を選択してコントロールを得る。\n自分のエンドフェイズ毎に、選択したモンスターのAカウンターを１つ取り除く。\nそのモンスターのAカウンターが全て取り除かれるかそのモンスターが破壊された場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮の重税",h:"おうきゅうのじゅうぜい",e:"Royal Writ of Taxation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のスタンバイフェイズ時に発動できる。\n相手の手札をランダムに１枚確認する。\n確認したカードまたは同名カードが次の自分のエンドフェイズ終了時までに召喚または発動されなかった場合、相手ライフに１０００ポイントダメージを与える。\n召喚または発動された場合、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"旋風剣",h:"さいくろんぶれーど",e:"Cyclone Blade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D-HERO」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃を行ったダメージステップ終了時、フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トイ・マジシャン",h:"とい・まじしゃん",e:"Toy Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットする事ができる。\n自分の魔法＆罠カードゾーンにセットされたこのカードが相手のカードの効果によって破壊され墓地へ送られた場合、そのターンのエンドフェイズ時にこのカードを墓地から特殊召喚する。\nまた、このカードが反転召喚に成功した時、フィールド上に表側表示で存在する「トイ・マジシャン」の数だけ、フィールド上に存在する魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"超伝導恐獣",h:"すーぱーこんだくたーてぃらの",e:"Super Conductor Tyranno",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3300,de:1400,tx:"①：１ターンに１度、自分フィールドのモンスター１体をリリースして発動できる。\n相手に１０００ダメージを与える。\nこの効果を発動するターン、このカードは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"狩猟本能",h:"しゅりょうほんのう",e:"Hunting Instinct",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターが特殊召喚された時に発動する事ができる。\n手札から恐竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"生存本能",h:"せいぞんほんのう",e:"Survival Instinct",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する恐竜族モンスターを任意の枚数選択しゲームから除外する。\n除外した恐竜族モンスター１体につき、自分は４００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"大進化薬",h:"だいしんかやく",e:"Big Evolution Pill",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールドの恐竜族モンスター１体をリリースしてこのカードを発動できる。\nこのカードは発動後、フィールドに残り続け、相手ターンで数えて３ターン目の相手エンドフェイズに破壊される。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分はレベル５以上の恐竜族モンスターを召喚する場合に必要なリリースをなくす事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"光と闇の竜",h:"らいとあんどだーくねす・どらごん",e:"Light and Darkness Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカードは特殊召喚できない。\nこのカードの属性は「闇」としても扱う。\nこのカードがフィールド上に表側表示で存在する限り、効果モンスターの効果・魔法・罠カードの発動を無効にする。\nこの効果でカードの発動を無効にする度に、このカードの攻撃力と守備力は５００ポイントダウンする。\nこのカードが破壊され墓地へ送られた時、自分の墓地に存在するモンスター１体を選択して発動する。\n自分フィールド上のカードを全て破壊する。\n選択したモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"テールスイング",h:"てーるすいんぐ",e:"Tail Swipe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するレベル５以上の恐竜族モンスター１体を選択して発動する。\n相手フィールド上に存在する裏側表示モンスターまたは選択した恐竜族モンスターのレベル未満のモンスターを合計２体まで選択し、持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラシックワールド",h:"じゅらしっくわーるど",e:"Jurassic World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する恐竜族モンスターは攻撃力と守備力が３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"大噴火",h:"だいふんか",e:"Volcanic Eruption",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「ジュラシックワールド」が存在している場合、自分のエンドフェイズ時に発動する事ができる。\nフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"大地震",h:"だいじしん",e:"Seismic Shockwave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在する恐竜族モンスターが破壊され墓地へ送られた時に発動する事ができる。\n相手フィールド上の魔法＆罠カードゾーンから３カ所を指定する。\n指定した魔法＆罠カードゾーンは使用できない。\nこの時カードが存在している場所は指定する事はできない。\nこのカードは発動後３回目の自分のスタンバイフェイズ時に破壊される。\nこの効果で破壊された場合、自分の墓地の恐竜族モンスター１体を手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ダーク・ホーン",h:"さいばー・だーく・ほーん",e:"Cyberdark Horn",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した場合、自分の墓地のレベル３以下のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n④：このカードが戦闘で破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ダーク・エッジ",h:"さいばー・だーく・えっじ",e:"Cyberdark Edge",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した場合、自分の墓地のレベル３以下のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：このカードは直接攻撃できる。\nその場合、このカードの攻撃力はそのダメージ計算時のみ半分になる。\n④：このカードが戦闘で破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ダーク・キール",h:"さいばー・だーく・きーる",e:"Cyberdark Keel",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した場合、自分の墓地のレベル３以下のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手に３００ダメージを与える。\n④：このカードが戦闘で破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧黒竜－サイバー・ダーク・ドラゴン",h:"がいこくりゅう－さいばー・だーく・どらごん",e:"Cyberdark Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:1000,tx:"「サイバー・ダーク・ホーン」＋「サイバー・ダーク・エッジ」＋「サイバー・ダーク・キール」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分、及び自分の墓地のモンスターの数×１００アップする。\n③：このカードが戦闘で破壊される場合、代わりに装備したそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・ウォール",h:"ぱわー・うぉーる",e:"Power Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃によって自分が戦闘ダメージを受けるダメージ計算時に発動できる。\nその戦闘で発生する自分への戦闘ダメージが０になるように、受けるダメージの代わりに５００ダメージにつき１枚、自分のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパービークロイド－ステルス・ユニオン",h:"すーぱーびーくろいど－すてるす・ゆにおん",e:"Super Vehicroid - Stealth Union",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3600,de:3000,tx:"「トラックロイド」＋「エクスプレスロイド」＋「ドリルロイド」＋「ステルスロイド」\n１ターンに１度、自分のメインフェイズ時にフィールド上に存在する機械族以外のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる。\nこの効果によってモンスターを装備している場合、相手フィールド上の全てのモンスターに１回ずつ攻撃をする事ができる。\nこのカードが攻撃をする場合、このカードの元々の攻撃力は半分になる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"究極進化薬",h:"きゅうきょくしんかやく",e:"Double Evolution Pill",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地から、恐竜族モンスターと恐竜族以外のモンスターを１体ずつ除外して発動できる。\n手札・デッキからレベル７以上の恐竜族モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械工兵",h:"あんてぃーく・ぎあえんじにあ",e:"Ancient Gear Engineer",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードがモンスターゾーンに存在する限り、このカードを対象とする罠カードの効果は無効化され破壊される。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n③：このカードが攻撃したダメージステップ終了時、相手フィールドの魔法・罠カード１枚を対象として発動する。\nその相手の魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械戦車",h:"あんてぃーく・ぎあたんく",e:"Ancient Gear Tank",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「アンティーク・ギア」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は６００ポイントアップする。\nこのカードが破壊され墓地へ送られた時、相手ライフに６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械爆弾",h:"あんてぃーく・ぎあぼむ",e:"Ancient Gear Explosive",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アンティーク・ギア」と名のついたモンスター１体を対象に発動する事ができる。\n対象のモンスターを破壊し、そのモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械掌",h:"あんてぃーく・ぎあはんど",e:"Ancient Gear Fist",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「アンティーク・ギア」と名のついたモンスターにのみ装備可能。\n装備モンスターと戦闘を行ったモンスターを、そのダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エンジェル－弁天－",h:"さいばー・えんじぇる－べんてん－",e:"Cyber Angel Benten",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1800,de:1500,tx:"「機械天使の儀式」により降臨。\n①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の守備力分のダメージを相手に与える。\n②：このカードがリリースされた場合に発動できる。\nデッキから天使族・光属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エンジェル－韋駄天－",h:"さいばー・えんじぇる－いだてん－",e:"Cyber Angel Idaten",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1600,de:2000,tx:"「機械天使の儀式」により降臨。\n①：このカードが儀式召喚に成功した場合に発動できる。\n自分のデッキ・墓地から儀式魔法カード１枚を選んで手札に加える。\n②：このカードがリリースされた場合に発動できる。\n自分フィールドの全ての儀式モンスターの攻撃力・守備力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エンジェル－荼吉尼－",h:"さいばー・えんじぇる－だきに－",e:"Cyber Angel Dakini",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:2400,tx:"「機械天使の儀式」により降臨。\n①：このカードが儀式召喚に成功した場合に発動できる。\n相手は自身のフィールドのモンスター１体を墓地へ送らなければならない。\n②：このカードがモンスターゾーンに存在する限り、自分の儀式モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：自分エンドフェイズに自分の墓地の、儀式モンスター１体または「機械天使の儀式」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・プチ・エンジェル",h:"さいばー・ぷち・えんじぇる",e:"Cyber Petit Angel",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「サイバー・エンジェル」モンスター１体または「機械天使の儀式」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機械天使の儀式",h:"きかいてんしのぎしき",e:"Machine Angel Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「サイバー・エンジェル」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「サイバー・エンジェル」儀式モンスター１体を儀式召喚する。\n②：自分フィールドの光属性モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・オーガ",h:"さいばー・おーが",e:"Cyber Ogre",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードを手札から墓地に捨てる。\n自分フィールド上に存在する「サイバー・オーガ」１体が行う戦闘を１度だけ無効にし、さらに次の戦闘終了時まで攻撃力は２０００ポイントアップする。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エスパー",h:"さいばー・えすぱー",e:"Cyber Esper",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、相手がドローしたカードを確認する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・オーガ・２",h:"さいばー・おーが・２",e:"Cyber Ogre 2",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1900,tx:"「サイバー・オーガ」＋「サイバー・オーガ」\nこのモンスターの融合召喚は、上記のカードでしか行えない。\nこのカードが攻撃を行う時、攻撃対象モンスターの攻撃力の半分の数値だけこのカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・シャドー・ガードナー",h:"さいばー・しゃどー・がーどなー",e:"Cyber Shadow Gardna",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のメインフェイズ時に発動できる。\nこのカードは発動後モンスターカード（機械族・地・星４・攻／守？）となり、自分のモンスターカードゾーンに特殊召喚する。\nこのカードを攻撃対象とした相手モンスターの攻撃宣言時、このカードの攻撃力・守備力は相手攻撃モンスターと同じ数値になる。\nこのカードは相手のエンドフェイズ時に魔法＆罠カードゾーンにセットされる。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"バイロード・サクリファイス",h:"ばいろーど・さくりふぁいす",e:"Byroad Sacrifice",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊された場合に発動する事ができる。\n手札から「サイバー・オーガ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トロイボム",h:"とろいぼむ",e:"Trojan Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターのコントロールが相手のカードの効果によって相手に移った時に発動できる。\nそのモンスター１体を破壊し、破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷騎士",h:"あいすないと",e:"Ice Knight",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"①：このカードの攻撃力は、自分フィールドの水族モンスターの数×４００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに水属性モンスター１体を召喚できる。\nこの効果の発動後、ターン終了時まで自分は水属性モンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO クノスペ",h:"えれめんたるひーろー　くのすぺ",e:"Elemental HERO Knospe",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:600,de:1000,tx:"相手プレイヤーに戦闘ダメージを与える度に、このカードの攻撃力は１００ポイントアップし、守備力は１００ポイントダウンする。\nこのカードを除く「E・HERO」と名のついたモンスターが自分フィールド上に表側表示で存在する限り、相手はこのカードを攻撃対象に選択できず、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魂のさまよう墓場",h:"たましいのさまようはかば",e:"Graveyard of Wandering Souls",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが既に魔法＆罠ゾーンに存在する状態で、自分フィールドのモンスターが戦闘で破壊され墓地へ送られた場合に発動する。\n自分フィールドに「火の玉トークン」（炎族・炎・星１・攻／守１００）１体を特殊召喚する。\n②：このカードが既に魔法＆罠ゾーンに存在する状態で、自分の手札・フィールドのモンスターが相手の効果で墓地へ送られた場合に発動する。\nそのモンスターの数まで可能な限り、自分フィールドに「火の玉トークン」を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーメンブラット",h:"ぶるーめんぶらっと",e:"Rose Bud",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「E・HERO クノスペ」１体を生け贄に捧げて発動する。\n自分の手札またはデッキから「E・HERO ブルーメ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ブルーメ",h:"えれめんたるひーろー　ぶるーめ",e:"Elemental HERO Poison Rose",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:2000,tx:"このカードは通常召喚できない。\nこのカードは「ブルーメンブラット」の効果でのみ特殊召喚する事ができる。\n相手は「E・HERO ブルーメ」しか攻撃対象に選択できない。\n相手プレイヤーに戦闘ダメージを与える度に、このカードの攻撃力は２００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"グリード・クエーサー",h:"ぐりーど・くえーさー",e:"Greed Quasar",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの元々の攻撃力・守備力は、このカードのレベル×３００になる。\n②：このカードがモンスターゾーンに存在する限り、このカードのレベルはこのカードが戦闘で破壊したモンスターの元々のレベル分だけ上がる。",li:"",lk:[],ta:[],al:[]},
  {n:"魅惑の女王 LV３",h:"ありゅーる・くぃーん　れべる３",e:"Allure Queen LV3",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"１ターンに１度だけ自分のメインフェイズ時に相手フィールド上のレベル３以下のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる（この効果で装備できる装備カードは１枚まで）。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。\n自分ターンのスタンバイフェイズ時、この効果で装備カードを装備したこのカードを墓地に送る事で、「魅惑の女王 LV５」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魅惑の女王 LV５",h:"ありゅーる・くぃーん　れべる５",e:"Allure Queen LV5",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「魅惑の女王 LV３」の効果で特殊召喚した場合、１ターンに１度だけ自分のメインフェイズ時に相手フィールド上のレベル５以下のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる（この効果で装備できる装備カードは１枚まで）。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。\n自分ターンのスタンバイフェイズ時、この効果で装備カードを装備したこのカードを墓地に送る事で、「魅惑の女王 LV７」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魅惑の女王 LV７",h:"ありゅーる・くぃーん　れべる７",e:"Allure Queen LV7",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「魅惑の女王 LV５」の効果で特殊召喚した場合、１ターンに１度だけ自分のメインフェイズ時に相手フィールド上のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる（この効果で装備できる装備カードは１枚まで）。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の魔王 LV４",h:"だーく・るしあす　れべる４",e:"Dark Lucius LV4",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:300,tx:"このカードが戦闘によって破壊した相手モンスターの効果は無効化される。\nこのカードがモンスターを戦闘によって破壊した次の自分ターンのスタンバイフェイズ時、このカードを墓地に送る事で「漆黒の魔王 LV６」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の魔王 LV６",h:"だーく・るしあす　れべる６",e:"Dark Lucius LV6",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"「漆黒の魔王 LV４」の効果で特殊召喚した場合、このカードが戦闘によって破壊した相手モンスターの効果は無効化される。\nこの効果で相手モンスターの効果を無効化した次の自分ターンのスタンバイフェイズ時、このカードを墓地に送る事で「漆黒の魔王 LV８」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の魔王 LV８",h:"だーく・るしあす　れべる８",e:"Dark Lucius LV8",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:900,tx:"「漆黒の魔王 LV６」の効果で特殊召喚した場合、このカードが戦闘によって破壊した相手モンスターの効果を無効にし、ゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"スナイプストーカー",h:"すないぷすとーかー",e:"Snipe Hunter",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"手札を１枚捨て、フィールド上のカード１枚を選択して発動できる。\nサイコロを１回振り、１・６以外が出た場合、選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"連爆魔人",h:"ぶらすと・でびる",e:"Blast Asmodian",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:300,tx:"魔法・罠カードがチェーンする場合、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"虚無魔人",h:"う゛ぁにてぃー・でびる",e:"Vanity's Fiend",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードは特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、お互いにモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"深淵の結界像",h:"しんえんのけっかいぞう",e:"Barrier Statue of the Abyss",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、お互いに闇属性以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"豪雨の結界像",h:"ごううのけっかいぞう",e:"Barrier Statue of the Torrent",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、お互いに水属性以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"業火の結界像",h:"ごうかのけっかいぞう",e:"Barrier Statue of the Inferno",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いは炎属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"烈風の結界像",h:"れっぷうのけっかいぞう",e:"Barrier Statue of the Stormwinds",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いは風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"干ばつの結界像",h:"かんばつのけっかいぞう",e:"Barrier Statue of the Drought",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いは地属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光の結界像",h:"せんこうのけっかいぞう",e:"Barrier Statue of the Heavens",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いは光属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"虚無の統括者",h:"う゛ぁにてぃー・るーらー",e:"Vanity's Ruler",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:1600,tx:"このカードは特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、相手はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地母神アイリス",h:"あーすまざーあいりす",e:"Iris, the Earth Mother",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"チェーンが３つ積まれた場合、自分のデッキからカードを１枚ドローする。\n同一チェーン上に複数回同名カードの効果が発動されている場合、この効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトニングパニッシャー",h:"らいとにんぐぱにっしゃー",e:"Lightning Punisher",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2600,de:1600,tx:"チェーンが３つ以上積まれた場合に発動する。\n相手フィールド上のカード１枚を選択して破壊する。\n同一チェーン上に複数回同名カードの効果が発動している場合、この効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"女王親衛隊",h:"じょおうしんえいたい",e:"Queen's Bodyguard",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在する「魅惑の女王」と名のついたモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"コンボファイター",h:"こんぼふぁいたー",e:"Combo Fighter",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードがフィールド上に表側表示で存在する時に自分ターンのメインフェイズ１でチェーンが発生した場合、このターンのみこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"コンボマスター",h:"こんぼますたー",e:"Combo Master",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"このカードがフィールド上に表側表示で存在する時に自分ターンのメインフェイズ１でチェーンが発生した場合、このターンのみこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"獣人アレス",h:"じゅうじんあれす",e:"Man Beast of Ares",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードがフィールド上に表側表示で存在する限り、チェーンが発生する度にこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"突撃ライノス",h:"とつげきらいのす",e:"Rampaging Rhynos",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"１ターンに１度、使用していない隣のモンスターカードゾーンを指定して発動できる。\n自分フィールド上のこのカードをそのモンスターカードゾーンに移動する。\nこのカードの正面の相手モンスターを攻撃する場合、ダメージステップの間このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ストーム・シューター",h:"すとーむ・しゅーたー",e:"Storm Shooter",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2300,de:500,tx:"１ターンに１度だけ、次の効果から１つを選択して発動する事ができる。\n\n●使用していない隣のモンスターカードゾーンに移動する。\n\n●このカードの正面に存在する相手のモンスター・魔法・罠カード１枚を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・ベーダー",h:"えーりあん・べーだー",e:"Alien Infiltrator",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"このカードは１ターンに１度だけ、使用していない隣のモンスターカードゾーンに移動する事ができる。\nこのカードの正面に相手のモンスター・魔法・罠カードが存在しない場合、このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"侵食細胞「A」",h:"しんしょくさいぼう「えー」",e:"Corruption Cell \"A\"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択した相手モンスターにAカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"封魔一閃",h:"ふうまいっせん",e:"Flash of the Forbidden Spell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上のモンスターカードゾーン全てにモンスターが存在している時に発動する事ができる。\n相手フィールド上に存在する全てのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"限定解除",h:"げんていかいじょ",e:"Ritual Foregone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払って発動できる。\n手札から儀式モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、エンドフェイズ時に破壊される。\n「限定解除」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"簡易融合",h:"いんすたんとふゅーじょん",e:"Instant Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１０００LPを払って発動できる。\nレベル５以下の融合モンスター１体を融合召喚扱いとしてEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、エンドフェイズに破壊される。",li:1,lk:[],ta:[],al:[]},
  {n:"カウンタークリーナー",h:"かうんたーくりーなー",e:"Counter Cleaner",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"５００ライフポイントを払う。\nフィールド上に存在する全てのカウンターを取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"リニアキャノン",h:"りにあきゃのん",e:"Linear Accelerator Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を生け贄に捧げ、そのモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。\nこのカードを発動する場合、このターン中に他の魔法カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖爆撃",h:"ちぇーん・すとらいく",e:"Chain Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"チェーン２以降に発動できる。\nこのカードの発動時に積まれているチェーンの数×４００ポイントダメージを相手ライフに与える。\n同一チェーン上に複数回同名カードの効果が発動している場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の蘇生",h:"みらくる・りぼーん",e:"Miraculous Rebirth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"チェーン４以降に発動できる。\n自分または相手の墓地のモンスター１体を選択し、自分フィールド上に特殊召喚する。\n同一チェーン上に複数回同名カードの効果が発動している場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"一陣の風",h:"いちじんのかぜ",e:"Mystical Wind Typhoon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"チェーン３以降に発動する事ができる。\nフィールド上の魔法または罠カード１枚を破壊する。\n同一チェーン上に複数回同名カードの効果が発動されている場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レベルダウン！？",h:"れべるだうん！？",e:"Level Down!?",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上の「LV」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを持ち主のデッキに戻し、持ち主の墓地からそのモンスターのカード名が含まれる「LV」の低いモンスター１体を選び、特殊召喚条件を無視して持ち主のフィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"縮退回路",h:"しゅくたいかいろ",e:"Degenerate Circuit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上から手札に戻るモンスターカードは手札に戻らずゲームから除外される。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこの時に５００ライフポイント払えない場合はこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ポジションチェンジ",h:"ぽじしょんちぇんじ",e:"Senet Switch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上モンスター１体の位置を、使用していない隣のモンスターカードゾーンに移動する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"爆導索",h:"ばくどうさく",e:"Blasting Fuse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このカードと同じ縦列全てにカードが存在する場合、セットされたこのカードを発動できる。\nその縦列のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ストレートフラッシュ",h:"すとれーとふらっしゅ",e:"Straight Flush",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の魔法＆罠カードゾーン全てにカードが存在する場合に発動できる。\n相手の魔法＆罠カードゾーンのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャスティブレイク",h:"じゃすてぃぶれいく",e:"Justi-Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の通常モンスターを攻撃対象とした相手モンスターの攻撃宣言時に発動できる。\n表側攻撃表示で存在する通常モンスター以外のフィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"リバースディメンション",h:"りばーすでぃめんしょん",e:"Dimensional Inversion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"元々の持ち主が自分であるモンスターが相手の効果によってゲームから除外された時に発動する事ができる。\nそのモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"チェーン・ヒーリング",h:"ちぇーん・ひーりんぐ",e:"Chain Healing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分は５００ライフポイント回復する。\nこのカードがチェーン２またはチェーン３で発動した場合、このカードをデッキに加えてシャッフルする。\nこのカードがチェーン４以降に発動した場合、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"チェーン・ブラスト",h:"ちぇーん・ぶらすと",e:"Chain Detonation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ライフに５００ポイントダメージを与える。\nこのカードをチェーン２またはチェーン３で発動した場合、発動後このカードは墓地へ送らず、持ち主のデッキに戻す。\nチェーン４以降に発動した場合、持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"積み上げる幸福",h:"つみあげるこうふく",e:"Accumulated Fortune",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"チェーン４以降に発動できる。\nデッキからカードを２枚ドローする。\n同一チェーン上に複数回同名カードの効果が発動している場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"虚無を呼ぶ呪文",h:"う゛ぁにてぃー・こーる",e:"Vanity's Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"チェーン４以降にライフポイントを半分払って発動できる。\nこのカードの発動時に積まれていたチェーン上の全てのカードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"昇天の黒角笛",h:"しょうてんのぶらっくほーん",e:"Black Horn of Heaven",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手モンスター１体の特殊召喚を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"怨念の魂 業火",h:"おんねんのたましい　ごうか",e:"Goka, the Pyre of Malice",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2200,de:1900,tx:"①：自分フィールドに炎属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合、自分フィールドの炎属性モンスター１体を対象として発動する。\nその自分の炎属性モンスターを破壊する。\n③：このカード以外の自分フィールドの炎属性モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで５００アップする。\n④：自分スタンバイフェイズに発動する。\n自分フィールドに「火の玉トークン」（炎族・炎・星１・攻／守１００）１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アース・グラビティ",h:"あーす・ぐらびてぃ",e:"Terra Firma Gravity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードは相手ターンのバトルフェイズにしか発動できない。\n攻撃可能なレベル４以下のモンスターは、自分フィールド上に表側表示で存在する「E・HERO ジ・アース」を攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ザ・ヒート",h:"えれめんたるひーろー　ざ・ひーと",e:"Elemental HERO Heat",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードの攻撃力は、自分フィールドの「E・HERO」モンスターの数×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コールド・エンチャンター",h:"こーるど・えんちゃんたー",e:"Cold Enchanter",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"手札を１枚捨てて発動できる。\nフィールド上に表側表示で存在するモンスター１体を選択し、アイスカウンターを１つ置く。\nこのカードがフィールド上に表側表示で存在する限り、このカードの攻撃力は、フィールド上のアイスカウンターの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械巨竜",h:"あんてぃーく・ぎあがじぇるどらごん",e:"Ancient Gear Gadjiltron Dragon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：このカードの召喚のためにリリースしたモンスターによって以下の効果を得る。\n●グリーン・ガジェット：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n●レッド・ガジェット：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手に４００ダメージを与える。\n●イエロー・ガジェット：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手に６００ダメージを与える。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械合成獣",h:"あんてぃーく・ぎあがじぇるきめら",e:"Ancient Gear Gadjiltron Chimera",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1300,tx:"①：このカードの召喚のためにリリースしたモンスターによって以下の効果を得る。\n●グリーン・ガジェット：このカードの攻撃力は３００アップする。\n●レッド・ガジェット：このカードが直接攻撃で相手に戦闘ダメージを与えた場合に発動する。\n相手に５００ダメージを与える。\n●イエロー・ガジェット：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手に７００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"起動兵士デッドリボルバー",h:"きどうへいしでっどりぼるばー",e:"Boot-Up Soldier - Dread Dynamo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分フィールド上に「ガジェット」と名のついたモンスターが表側表示で存在する限り、このカードの攻撃力は２０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の整備場",h:"あんてぃーく・ぎあがれーじ",e:"Ancient Gear Workshop",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「アンティーク・ギア」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"迷走悪魔",h:"すとれい・でびる",e:"Stray Asmodian",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:1700,tx:"このカードが戦闘によって破壊され墓地へ送られた時、お互いは８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"暴鬼",h:"あばき",e:"Abaki",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、お互いは５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"フレイム・オーガ",h:"ふれいむ・おーが",e:"Flame Ogre",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1700,tx:"このカードは特殊召喚できない。\nこのカードの召喚に成功した時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"カードガンナー",h:"かーどがんなー",e:"Card Trooper",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"①：１ターンに１度、自分のデッキの上からカードを３枚まで墓地へ送って発動できる。\nこのカードの攻撃力はターン終了時まで、この効果を発動するために墓地へ送ったカードの数×５００アップする。\n②：自分フィールドのこのカードが破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトイレイザー",h:"らいといれいざー",e:"Light Laser",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"光属性・戦士族モンスターにのみ装備可能。\n装備モンスターと戦闘を行ったモンスターを、そのダメージステップ終了時にゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元からの埋葬",h:"いじげんからのまいそう",e:"Burial from a Different Dimension",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：除外されている自分及び相手のモンスターの中から合計３体まで対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジ・ハンマー",h:"えっじ・はんまー",e:"Edge Hammer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「E・HERO エッジマン」１体を生け贄に捧げる。\n相手フィールド上に存在するモンスター１体を破壊し、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"キッズ・ガード",h:"きっず・がーど",e:"Kid Guard",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「ヒーロー・キッズ」１体を生け贄に捧げる。\n相手モンスターの攻撃を無効にし、自分のデッキから「E・HERO」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・ドラゴン",h:"へる・どらごん",e:"Infernal Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードが攻撃したターンのエンドフェイズに発動する。\nこのカードを破壊する。\n②：フィールドのこのカードが破壊され墓地へ送られた時、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"無情の抹殺",h:"むじょうのまっさつ",e:"Ruthless Denial",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択した自分のモンスターを墓地へ送り、相手の手札をランダムに１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"リターンソウル",h:"りたーんそうる",e:"Return Soul",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エンドフェイズ時に発動する事ができる。\nこのターンに破壊され墓地に存在するモンスターを３体まで持ち主のデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・ポラリライザー",h:"だめーじ・ぽらりらいざー",e:"Damage Polarizer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\nその発動と効果を無効にし、お互いのプレイヤーはカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"フュージョン・ガード",h:"ふゅーじょん・がーど",e:"Fusion Guard",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\nその発動と効果を無効にし、自分の融合デッキからランダムに融合モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディアボリックガイ",h:"ですてにーひーろー　でぃあぼりっくがい",e:"Destiny HERO - Malicious",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：墓地のこのカードを除外して発動できる。\nデッキから「D-HERO ディアボリックガイ」１体を特殊召喚する。",li:2,lk:[],ta:[],al:[]},
  {n:"デステニー・ドロー",h:"ですてにー・どろー",e:"Destiny Draw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札から「D-HERO」カード１枚を捨てて発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバー・デステニー",h:"おーばー・ですてにー",e:"Over Destiny",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターのレベルの半分以下のレベルを持つ「D-HERO」モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"D－カウンター",h:"でぃー－かうんたー",e:"D - Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D-HERO」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エターナル・ドレッド",h:"えたーなる・どれっど",e:"Eternal Dread",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「幽獄の時計塔」に時計カウンターを２個乗せる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣クロスウィング",h:"げんじゅうくろすうぃんぐ",e:"Phantom Beast Cross-Wing",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"このカードが墓地に存在する限り、フィールド上の「幻獣」と名のついたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣ワイルドホーン",h:"げんじゅうわいるどほーん",e:"Phantom Beast Wild-Horn",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣サンダーペガス",h:"げんじゅうさんだーぺがす",e:"Phantom Beast Thunder-Pegasus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"相手モンスターの攻撃宣言時、墓地のこのカードをゲームから除外し、自分フィールド上の「幻獣」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択した自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣ロックリザード",h:"げんじゅうろっくりざーど",e:"Phantom Beast Rock-Lizard",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"「幻獣」と名のついたモンスターを生け贄に捧げる場合、このカードは生け贄１体で召喚する事ができる。\nこのカードが戦闘で破壊したモンスター１体につき、相手ライフに５００ポイントダメージを与える。\n相手がコントロールするカードの効果によってこのカードが破壊され墓地へ送られた時、相手ライフに２０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"Testament of the Arcane Lords",h:"Testament of the Arcane Lords",e:"Testament of the Arcane Lords",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by Tributing 3 Spellcaster-Type monsters on your side of the field. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"Armament of the Lethal Lords",h:"Armament of the Lethal Lords",e:"Armament of the Lethal Lords",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by Tributing 3 Warrior-Type monsters on your side of the field. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネティック・ワーウルフ",h:"じぇねてぃっく・わーうるふ",e:"Gene-Warped Warwolf",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:2000,de:100,tx:"遺伝子操作により強化された人狼。\n本来の優しき心は完全に破壊され、闘う事でしか生きる事ができない体になってしまった。\nその破壊力は計り知れない。",li:"",lk:[],ta:[],al:[]},
  {n:"フロストザウルス",h:"ふろすとざうるす",e:"Frostosaurus",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:2600,de:1700,tx:"鈍い神経と感性のお陰で、氷づけになりつつも氷河期を乗り越える脅威の生命力を持つ。\n寒さには滅法強いぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"スパイラルドラゴン",h:"すぱいらるどらごん",e:"Spiral Serpent",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:2900,de:2900,tx:"海流の渦をつくり出し人々を襲うと伝えられる海竜。\n巨大なヒレから放たれるスパイラルウェーブは全てを飲み込む。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆－ヤリザ",h:"ろくぶしゅう－やりざ",e:"The Six Samurai - Yariza",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"自分フィールド上に「六武衆－ヤリザ」以外の「六武衆」と名のついたモンスターが存在する場合、このカードは相手プレイヤーに直接攻撃できる。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆－ザンジ",h:"ろくぶしゅう－ざんじ",e:"The Six Samurai - Zanji",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"自分フィールド上に「六武衆－ザンジ」以外の「六武衆」と名のついたモンスターが存在する場合、このカードが攻撃したモンスターをダメージステップ終了時に破壊する。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆－ニサシ",h:"ろくぶしゅう－にさし",e:"The Six Samurai - Nisashi",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"自分フィールド上に「六武衆－ニサシ」以外の「六武衆」と名のついたモンスターが存在する場合、このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆－ヤイチ",h:"ろくぶしゅう－やいち",e:"The Six Samurai - Yaichi",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"自分フィールド上に「六武衆－ヤイチ」以外の「六武衆」と名のついたモンスターが存在する場合、１ターンに１度、フィールド上にセットされた魔法・罠カード１枚を選択して破壊できる。\nこの効果を発動するターン、このカードは攻撃宣言できない。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆－カモン",h:"ろくぶしゅう－かもん",e:"The Six Samurai - Kamon",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上に「六武衆－カモン」以外の「六武衆」と名のついたモンスターが存在する場合、１ターンに１度、フィールド上に表側表示で存在する魔法・罠カード１枚を選択して破壊できる。\nこの効果を発動するターン、このカードは攻撃宣言できない。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆－イロウ",h:"ろくぶしゅう－いろう",e:"The Six Samurai - Irou",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"自分フィールド上に「六武衆－イロウ」以外の「六武衆」と名のついたモンスターが存在し、このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"大将軍 紫炎",h:"だいしょうぐん　しえん",e:"Great Shogun Shien",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2400,tx:"自分フィールド上に「六武衆」と名のついたモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、相手は１ターンに１度しか魔法・罠カードを発動できない。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の足軽",h:"しえんのあしがる",e:"Shien's Footsoldier",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:700,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル３以下の「六武衆」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"静寂の聖者",h:"せいじゃくのせいじゃ",e:"Sage of Silence",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手プレイヤーは次のターン魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"平穏の賢者",h:"へいおんのけんじゃ",e:"Sage of Stillness",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手プレイヤーは次のターン罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の魔神 レイン",h:"あんこくかいのましん　れいん",e:"Reign-Beaux, Overlord of Dark World",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1800,tx:"このカードが相手のカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、相手フィールド上の全てのモンスターまたは全ての魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の刺客 カーキ",h:"あんこくかいのしかく　かーき",e:"Kahkki, Guerilla of Dark World",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の策士 グリン",h:"あんこくかいのさくし　ぐりん",e:"Gren, Tactician of Dark World",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フュージョニストキラー",h:"ふゅーじょにすときらー",e:"Fusion Devourer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードと戦闘を行う融合モンスターの攻撃力は、ダメージステップの間０となる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレクトリック・ワーム",h:"えれくとりっく・わーむ",e:"Electric Virus",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードを手札から墓地へ捨て、相手フィールドのドラゴン族・機械族モンスター１体を対象として発動できる。\nそのドラゴン族・機械族モンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"パペット・プラント",h:"ぱぺっと・ぷらんと",e:"Puppet Plant",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードを手札から墓地へ捨てて発動できる。\n相手フィールド上の戦士族・魔法使い族モンスター１体を選択し、エンドフェイズ時までコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"傀儡虫",h:"くぐつちゅう",e:"Marionette Mite",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードを手札から墓地へ捨て、相手フィールドの悪魔族・アンデット族モンスター１体を対象として発動できる。\nその悪魔族・アンデット族モンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"D.D.クロウ",h:"でぃー．でぃー．くろう",e:"D.D. Crow",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードを手札から墓地へ捨て、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"レイジアース",h:"れいじあーす",e:"Raging Earth",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する地属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレントアビス",h:"さいれんとあびす",e:"Silent Abyss",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する水属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルプロミネンス",h:"へるぷろみねんす",e:"Firestorm Prominence",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する炎属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デスサイクロン",h:"ですさいくろん",e:"Destruction Cyclone",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する風属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークネスソウル",h:"だーくねすそうる",e:"Umbral Soul",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する闇属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"シャインスピリッツ",h:"しゃいんすぴりっつ",e:"Radiant Spirit",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する光属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・サイコ",h:"えーりあん・さいこ",e:"Alien Psychic",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"このカードは召喚・反転召喚に成功した場合守備表示になる。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO グロー・ネオス",h:"えれめんたるひーろー　ぐろー・ねおす",e:"Elemental HERO Glow Neos",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・グロー・モス」\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\nエンドフェイズ時にこのカードは融合デッキに戻る。\n相手フィールド上に表側表示で存在するカード１枚を破壊し、そのカードの種類によりこのカードは以下の効果を得る。\nこの効果は１ターンに１度だけ自分のメインフェイズ１に使用する事ができる。\n●モンスターカード：このターン、このカードは戦闘を行えない。\n●魔法カード：このカードは相手プレイヤーに直接攻撃する事ができる。\n●罠カード：このカードは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO エアー・ネオス",h:"えれめんたるひーろー　えあー・ねおす",e:"Elemental HERO Air Neos",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・エア・ハミングバード」\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\n自分のライフポイントが相手のライフポイントよりも少ない場合、その数値だけこのカードの攻撃力がアップする。\nエンドフェイズ時にこのカードは融合デッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO グラン・ネオス",h:"えれめんたるひーろー　ぐらん・ねおす",e:"Elemental HERO Grand Neos",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・グラン・モール」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\n１ターンに１度、相手フィールド上のモンスター１体を選択して持ち主の手札に戻す事ができる。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライカン・スロープ",h:"らいかん・すろーぷ",e:"Lycanthrope",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:1800,tx:"「合成魔術」により降臨。\nこのカードが相手ライフに戦闘ダメージを与えた時、自分の墓地に存在する通常モンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"覚醒戦士 クーフーリン",h:"かくせいせんし　くーふーりん",e:"CÚ Chulainn the Awakened",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:500,de:1000,tx:"「覚醒の証」により降臨。\n１ターンに１度、自分の墓地に存在する通常モンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外し、除外したその通常モンスターの攻撃力分だけ、次の自分のターンのスタンバイフェイズ時までこのカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオス・フォース",h:"ねおす・ふぉーす",e:"Neos Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO ネオス」にのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\n装備モンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。\nまた、エンドフェイズ時、フィールド上のこのカードはデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"「A」細胞散布爆弾",h:"「えー」さいぼうさんぷばくだん",e:"A Cell Scatter Burst",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「エーリアン」と名のついたモンスター１体を選択して発動できる。\n選択した表側表示モンスターを破壊し、そのモンスターのレベルの数だけAカウンターを相手フィールド上に表側表示で存在するモンスターに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"古のルール",h:"いにしえのるーる",e:"Ancient Rules",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札からレベル５以上の通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"高等儀式術",h:"こうとうぎしきじゅつ",e:"Advanced Ritual Art",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、デッキから通常モンスターを墓地へ送り、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"合成魔術",h:"ごうせいまじゅつ",e:"Synthesis Spell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ライカン・スロープ」の降臨に必要。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"覚醒の証",h:"かくせいのあかし",e:"Emblem of the Awakening",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「覚醒戦士 クーフーリン」の降臨に必要。\n手札・自分フィールド上から、レベルの合計が４以上になるようにモンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ツイスター",h:"ついすたー",e:"Twister",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"５００ライフポイントを払って発動できる。\nフィールド上に表側表示で存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の名馬",h:"しっこくのめいば",e:"Legendary Ebon Steed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「六武衆」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力と守備力は２００ポイントアップする。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カードトレーダー",h:"かーどとれーだー",e:"Card Trader",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ時に手札を１枚デッキに戻す事で、デッキからカードを１枚ドローする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の取引",h:"あんこくかいのとりひき",e:"Dark World Dealings",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーはデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の霞城",h:"しえんのかすみじょう",e:"Shien's Castle of Mist",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「六武衆」と名のついたモンスターを攻撃する時、攻撃モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"摩天楼２－ヒーローシティ",h:"まてんろう２－ひーろーしてぃ",e:"Skyscraper 2 - Hero City",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分のメインフェイズ時に、戦闘によって破壊され自分の墓地へ送られた「E・HERO」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"英雄変化－リフレクター・レイ",h:"ちぇんじ・おぶ・ひーろー－りふれくたー・れい",e:"Change of Hero - Reflector Ray",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「E・HERO」と名のついた融合モンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n破壊された融合モンスターのレベル×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー・メダル",h:"ひーろー・めだる",e:"Hero Medal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がコントロールするカードの効果によってセットされたこのカードが破壊され墓地へ送られた時、このカードをデッキに加えてシャッフルする。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆推参！",h:"ろくぶしゅうすいさん！",e:"Return of the Six Samurai",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「六武衆」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"お家おとりつぶし",h:"おいえおとりつぶし",e:"Eliminating the League",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から魔法カード１枚を捨て、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。\nその後、相手の手札がある場合、相手の手札を確認し、破壊したカードの同名カードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光弾",h:"せんこうだん",e:"Flashbang",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃によって自分が戦闘ダメージを受けた時に発動できる。\nこのターンのエンドフェイズになる。",li:"",lk:[],ta:[],al:[]},
  {n:"転生の予言",h:"てんせいのよげん",e:"The Transmigration Prophecy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いの墓地のカードを合計２枚対象として発動できる。\nそのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"融合体駆除装置",h:"ゆうごうたいくじょそうち",e:"Anti-Fusion Device",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する融合モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式降臨封印の書",h:"ぎしきこうりんふういんのしょ",e:"Ritual Sealing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する儀式モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"正統なる血統",h:"せいとうなるけっとう",e:"Birthright",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"疾風！凶殺陣",h:"しっぷう！きょうさつじん",e:"Swift Samurai Storm!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「六武衆」と名のついたモンスターが戦闘を行った場合、このターンのエンドフェイズ時まで自分フィールド上の「六武衆」と名のついたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"夜霧のスナイパー",h:"よぎりのすないぱー",e:"Cloak and Dagger",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"モンスターカード名を１つ宣言する。\n宣言したモンスターを相手が召喚・特殊召喚・リバースした場合、宣言したモンスターとこのカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"畳返し",h:"たたみがえし",e:"Pulling the Rug",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"召喚成功時に発動する効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリザード・ドラゴン",h:"ぶりざーど・どらごん",e:"Blizzard Dragon",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"相手フィールド上に存在するモンスター１体を選択して発動する。\n選択したモンスターは次の相手のエンドフェイズ時まで攻撃宣言をする事ができず、表示形式を変更する事もできない。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル・シューター",h:"めたる・しゅーたー",e:"Metal Shooter",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが召喚に成功した時、このカードにカウンターを２つ置く。\nこの効果によってカードに乗ったカウンター１つにつき、このカードの攻撃力は８００ポイントアップする。\nこのカードが他のカードの効果によって破壊される場合、代わりにこのカードのカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・モスキート",h:"です・もすきーと",e:"Des Mosquito",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが召喚・特殊召喚に成功した時、このカードにカウンターを２つ置く。\nこの効果によってカードに乗ったカウンター１つにつき、このカードの攻撃力は５００ポイントアップする。\nこのカードが戦闘によって破壊される場合、代わりにこのカードのカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO レディ・オブ・ファイア",h:"えれめんたるひーろー　れでぃ・おぶ・ふぁいあ",e:"Elemental HERO Lady Heat",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"自分のターンのエンドフェイズ時、自分フィールド上に表側表示で存在する「E・HERO」と名のついたモンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アイス・ブリザード・マスター",h:"あいす・ぶりざーど・ますたー",e:"Ice Master",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは自分フィールドの水属性モンスター２体をリリースし、手札から特殊召喚できる。\n１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択し、アイスカウンターを１つ置く事ができる。\nまた、このカードをリリースする事で、アイスカウンターが乗ったモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO フレイム・ブラスト",h:"えれめんたるひーろー　ふれいむ・ぶらすと",e:"Elemental HERO Inferno",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1600,tx:"「E・HERO ザ・ヒート」＋「E・HERO レディ・オブ・ファイア」\nこのモンスターは融合召喚でしか特殊召喚できない。\n水属性モンスターと戦闘を行う場合、ダメージステップの間このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エンジェルO７",h:"えんじぇるおーせぶん",e:"Angel O7",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:1500,tx:"このカードの生け贄召喚に成功した時、このカードは以下の効果を得る。\n●このカードがフィールド上に表側表示で存在する限り、効果モンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンター・アウル",h:"はんたー・あうる",e:"Hunter Owl",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:900,tx:"自分フィールド上に表側表示で存在する風属性モンスター１体につき、このカードの攻撃力は５００ポイントアップする。\nまた、自分フィールド上に他の風属性モンスターが表側表示で存在する限り、相手はこのカードを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"マスクド・チョッパー",h:"ますくど・ちょっぱー",e:"Masked Chopper",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手ライフに２０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"天空勇士ネオパーシアス",h:"えんじぇるぶれいぶねおぱーしあす",e:"Neo-Parshath, the Sky Paladin",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"①：このカードは自分フィールドの「天空騎士パーシアス」１体をリリースして手札から特殊召喚できる。\n②：フィールドに「天空の聖域」が存在し、自分のLPが相手より多い場合、このカードの攻撃力・守備力はその差の数値分アップする。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n④：このカードが相手に戦闘ダメージを与えた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"天空聖者メルティウス",h:"えんじぇるせいんとめるてぃうす",e:"Meltiel, Sage of the Sky",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、自分は１０００LP回復し、フィールドに「天空の聖域」が存在する場合、さらに相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"智天使ハーヴェスト",h:"ちてんしはーう゛ぇすと",e:"Harvest Angel of Wisdom",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが戦闘で破壊され墓地へ送られた時、自分の墓地のカウンター罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"勝利の導き手フレイヤ",h:"しょうりのみちびきてふれいや",e:"Freya, Spirit of Victory",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールド上に「勝利の導き手フレイヤ」以外の天使族モンスターが表側表示で存在する場合、このカードを攻撃対象に選択する事はできない。\nこのカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する天使族モンスターの攻撃力・守備力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コーリング・ノヴァ",h:"こーりんぐ・のう゛ぁ",e:"Nova Summoner",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の天使族・光属性モンスター１体を特殊召喚する。\nフィールドに「天空の聖域」が存在する場合、代わりに「天空騎士パーシアス」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリー・ジェラル",h:"ほーりー・じぇらる",e:"Radiant Jeral",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"フィールド上に「天空の聖域」が存在する場合、このカードが戦闘によって破壊される以外の方法で墓地に送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェルエンデュオ",h:"じぇるえんでゅお",e:"Gellenduo",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"①：天使族・光属性モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n②：このカードは戦闘では破壊されない。\n③：自分が戦闘・効果でダメージを受けた場合に発動する。\nフィールドの表側表示のこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"女神の加護",h:"めがみのかご",e:"Aegis of Gaia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分は３０００ライフポイント回復する。\n自分フィールド上に表側表示で存在するこのカードがフィールド上から離れた時、自分は３０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"シャトルロイド",h:"しゃとるろいど",e:"",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードが攻撃対象に選択された時、このカードをゲームから除外する事ができる。\nこの効果によってゲームから除外された場合、このカードは次の自分のスタンバイフェイズ時に自分フィールド上に特殊召喚される。\nその時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネイビィロイド",h:"ねいびぃろいど",e:"Carrierroid",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"相手が自分フィールド上の魔法・罠カードを対象に「フィールド上の魔法・罠カードを破壊する」効果を持つカードを発動した時、手札を１枚捨てる事でそのカードの発動と効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマロイドガイデンゴー",h:"あーまろいどがいでんごー",e:"Armoroid",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:2000,tx:"①：「ロイド」モンスターをリリースしてこのカードがアドバンス召喚に成功した場合に発動する。\nフィールドの魔法・罠カードを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ボルテック",h:"えれめんたるひーろー　ぼるてっく",e:"Elemental HERO Voltic",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：このカードが相手に戦闘ダメージを与えた時、除外されている自分の「E・HERO」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"モザイク・マンティコア",h:"もざいく・まんてぃこあ",e:"Mosaic Manticore",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2800,de:2500,tx:"①：このカードがアドバンス召喚に成功した場合、次の自分ターンのスタンバイフェイズに発動する。\nこのカードのアドバンス召喚のためにリリースしたモンスターを墓地から可能な限り自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃宣言できず、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の侯爵ベリアル",h:"やみのこうしゃくべりある",e:"Belial - Marquis of Darkness",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカードがフィールド上に表側表示で存在する限り、相手は「闇の侯爵ベリアル」以外の自分フィールド上に表側表示で存在するモンスターを攻撃対象に選択できず、魔法・罠カードの効果の対象にする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉獣 アメジスト・キャット",h:"ほうぎょくじゅう　あめじすと・きゃっと",e:"Crystal Beast Amethyst Cat",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこの時、このカードが相手ライフに与える戦闘ダメージは半分になる。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉獣 アンバー・マンモス",h:"ほうぎょくじゅう　あんばー・まんもす",e:"Crystal Beast Amber Mammoth",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"自分フィールド上の「宝玉獣」と名のついたモンスターが攻撃対象に選択された時、このカードに攻撃対象を変更できる。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の導き",h:"ほうぎょくのみちびき",e:"Crystal Beacon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の魔法＆罠カードゾーンに「宝玉獣」と名のついたカードが２枚以上存在する場合、デッキから「宝玉獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉獣 ルビー・カーバンクル",h:"ほうぎょくじゅう　るびー・かーばんくる",e:"Crystal Beast Ruby Carbuncle",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードが特殊召喚に成功した時に発動できる。\n自分の魔法＆罠ゾーンの「宝玉獣」モンスターカードを可能な限り特殊召喚する。\n②：表側表示のこのカードがモンスターゾーンで破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉獣 エメラルド・タートル",h:"ほうぎょくじゅう　えめらるど・たーとる",e:"Crystal Beast Emerald Tortoise",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:600,de:2000,tx:"１ターンに１度、このターン攻撃を行った自分フィールド上のモンスター１体を選択して発動できる。\n選択した自分のモンスターを守備表示にする。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉獣 トパーズ・タイガー",h:"ほうぎょくじゅう　とぱーず・たいがー",e:"Crystal Beast Topaz Tiger",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間、攻撃力が４００ポイントアップする。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉獣 コバルト・イーグル",h:"ほうぎょくじゅう　こばると・いーぐる",e:"Crystal Beast Cobalt Eagle",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の「宝玉獣」と名のついたカード１枚を選択して持ち主のデッキの一番上に戻す。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉獣 サファイア・ペガサス",h:"ほうぎょくじゅう　さふぁいあ・ぺがさす",e:"Crystal Beast Sapphire Pegasus",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分の手札・デッキ・墓地から「宝玉獣」モンスター１体を選び、永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。\n②：表側表示のこのカードがモンスターゾーンで破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"虹の古代都市－レインボー・ルイン",h:"にじのこだいとし－れいんぼー・るいん",e:"Ancient City - Rainbow Ruins",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンの「宝玉獣」カードの数によって以下の効果を得る。\n●１枚以上：このカードは効果では破壊されない。\n●２枚以上：１ターンに１度、自分への戦闘ダメージを半分にできる。\n●３枚以上：魔法・罠カードの発動時、自分フィールドの表側表示の「宝玉獣」モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\n●４枚以上：１ターンに１度、発動できる。\n自分は１枚ドローする。\n●５枚：１ターンに１度、自分の魔法＆罠ゾーンの「宝玉獣」カード１枚を対象として発動できる。\nそのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レア・ヴァリュー",h:"れあ・う゛ぁりゅー",e:"Rare Value",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンの「宝玉獣」カードが２枚以上存在する場合に発動できる。\n自分の魔法＆罠ゾーンの「宝玉獣」カード１枚を相手が選ぶ。\n相手が選んだカードを墓地へ送り、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラスト・リゾート",h:"らすと・りぞーと",e:"Last Resort",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n自分のデッキから「虹の古代都市－レインボー・ルイン」１枚を選択して発動する。\nこの時、相手のフィールド魔法が発動している場合、相手プレイヤーはカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・バレット",h:"う゛ぉるかにっく・ばれっと",e:"Volcanic Shell",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:100,de:0,tx:"①：このカードが墓地に存在する場合、１ターンに１度、５００LPを払って発動できる。\nこのカードが墓地に存在する場合、デッキから「ヴォルカニック・バレット」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・バックショット",h:"う゛ぉるかにっく・ばっくしょっと",e:"Volcanic Scattershot",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカードが墓地へ送られた時、相手ライフに５００ポイントダメージを与える。\nこのカードが「ブレイズ・キャノン」と名のついたカードの効果によって墓地へ送られた場合、手札・デッキから「ヴォルカニック・バックショット」２体を墓地へ送る事で、相手フィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"誘発召喚",h:"ゆうはつしょうかん",e:"Triggered Summon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターが特殊召喚された時に発動する事ができる。\nお互いは手札からレベル４以下のモンスター１体をフィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・デビル",h:"う゛ぉるかにっく・でびる",e:"Volcanic Doomfire",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカードは通常召喚できない。\n自分フィールド上に表側表示で存在する「ブレイズ・キャノン－トライデント」１枚を墓地へ送った場合に特殊召喚できる。\n相手のバトルフェイズ中、相手フィールド上にモンスターが攻撃表示で存在する場合、相手はこのカードに攻撃をしなければならない。\nこのカードが戦闘によってモンスターを破壊し墓地へ送った時、相手フィールド上のモンスターを全て破壊し、破壊したモンスターの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイズ・キャノン",h:"ぶれいず・きゃのん",e:"Blaze Accelerator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のメインフェイズ時に相手フィールド上のモンスター１体を選択して発動できる。\n手札から攻撃力５００以下の炎族モンスター１体を墓地へ送り、選択した相手モンスターを破壊する。\nこの効果を発動するターン、自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・ソウル",h:"ふぁいやー・そうる",e:"Soul of Fire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手はデッキからカードを１枚ドローする。\n自分のデッキから炎族モンスター１体を選んでゲームから除外し、除外したモンスターの攻撃力の半分のダメージを相手ライフに与える。\nこのカードを発動するターン、自分は攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイズ・キャノン－トライデント",h:"ぶれいず・きゃのん－とらいでんと",e:"Tri-Blaze Accelerator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは自分フィールド上に表側表示で存在する「ブレイズ・キャノン」１枚を墓地へ送って発動できる。\nまた、自分のメインフェイズ時、相手フィールド上のモンスター１体を選択して発動できる。\n手札から炎族モンスター１体を墓地へ送り、選択した相手モンスターを破壊し相手ライフに５００ポイントダメージを与える。\nこの効果を発動するターン、自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"クレイジー・ファイヤー",h:"くれいじー・ふぁいやー",e:"Wild Fire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"５００ライフポイントを払う。\n自分フィールド上に表側表示で存在する「ブレイズ・キャノン」と名のついたカードを破壊し、フィールド上のモンスターを全て破壊する。\nその後、自分フィールド上に「クレイジー・ファイヤー・トークン」（炎族・炎・星３・攻／守１０００）を１体攻撃表示で特殊召喚する。\nこのターン自分のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・ウォール",h:"ふぁいやー・うぉーる",e:"Firewall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時、自分の墓地に存在する炎族モンスター１体をゲームから除外する事で、そのモンスターの攻撃を無効にする。\n自分のスタンバイフェイズ毎に５００ライフポイントを払う。\n払わなければ、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"邪神アバター",h:"じゃしんあばたー",e:"The Wicked Avatar",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは特殊召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ通常召喚できる。\n①：このカードが召喚に成功した場合に発動する。\n相手ターンで数えて２ターンの間、相手は魔法・罠カードを発動できない。\n②：このカードの攻撃力・守備力は、「邪神アバター」以外のフィールドの攻撃力が一番高いモンスターの攻撃力＋１００の数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"邪神イレイザー",h:"じゃしんいれいざー",e:"The Wicked Eraser",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは特殊召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ通常召喚できる。\n①：このカードの攻撃力・守備力は、相手フィールドのカードの数×１０００になる。\n②：自分メインフェイズに発動できる。\nこのカードを破壊する。\n③：このカードが破壊され墓地へ送られた場合に発動する。\nフィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ストライカー",h:"まじっく・すとらいかー",e:"Spell Striker",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"①：このカードは自分の墓地の魔法カード１枚を除外し、手札から特殊召喚できる。\n②：このカードは直接攻撃できる。\n③：このカードの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ボマー・ドラゴン",h:"ぼまー・どらごん",e:"Exploder Dragon",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"①：このカードの攻撃で発生するお互いの戦闘ダメージは０になる。\n②：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nこのカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディスクガイ",h:"ですてにーひーろー　でぃすくがい",e:"Destiny HERO - Disk Commander",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"このカード名の効果はデュエル中に１度しか使用できない。\nこのカードは墓地へ送られたターンには墓地からの特殊召喚はできない。\n①：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO Bloo-D",h:"ですてにーひーろー　ぶるーでぃー",e:"Destiny HERO - Plasma",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:600,tx:"このカードは通常召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、相手フィールドの表側表示モンスターの効果は無効化される。\n②：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n③：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力の半分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エンジェルO１",h:"えんじぇるおーわん",e:"Angel O1",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは手札のレベル７以上のモンスター１体を相手に見せ、手札から特殊召喚できる。\n②：特殊召喚したこのカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズにレベル７以上のモンスター１体を表側攻撃表示でアドバンス召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・リボルバー",h:"う゛ぉるかにっく・りぼるばー",e:"Volcanic Blaster",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「ヴォルカニック」と名のついたモンスター１体を選んでデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・エッジ",h:"う゛ぉるかにっく・えっじ",e:"Volcanic Slicer",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n相手ライフに５００ポイントダメージを与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・ハンマー",h:"う゛ぉるかにっく・はんまー",e:"Volcanic Hammerer",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分の墓地の「ヴォルカニック」と名のついたモンスターの数×２００ポイントダメージを相手ライフに与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO キャプテン・ゴールド",h:"えれめんたるひーろー　きゃぷてん・ごーるど",e:"Elemental HERO Captain Gold",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「摩天楼 －スカイスクレイパー－」１枚を手札に加える。\nまた、フィールド上に「摩天楼 －スカイスクレイパー－」が存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の司令官",h:"はかもりのしれいかん",e:"Gravekeeper's Commandant",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「王家の眠る谷－ネクロバレー」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アトランティスの戦士",h:"あとらんてぃすのせんし",e:"Warrior of Atlantis",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「伝説の都 アトランティス」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"キラーザウルス",h:"きらーざうるす",e:"Destroyersaurus",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1100,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「ジュラシックワールド」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天空の使者 ゼラディアス",h:"てんくうのししゃ　ぜらでぃあす",e:"Zeradias, Herald of Heaven",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"①：このカードを手札から墓地へ捨てて発動できる。\nデッキから「天空の聖域」１枚を手札に加える。\n②：フィールドに「天空の聖域」が存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネラルデーモン",h:"じぇねらるでーもん",e:"Archfiend General",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「万魔殿－悪魔の巣窟－」１枚を手札に加える。\nまた、フィールド上に「万魔殿－悪魔の巣窟－」が存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・クィーン",h:"はーぴぃ・くぃーん",e:"Harpie Queen",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：このカードを手札から墓地へ捨てて発動できる。\nデッキから「ハーピィの狩場」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天魔神 エンライズ",h:"てんましん　えんらいず",e:"Sky Scourge Enrise",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードは通常召喚できない。\n自分の墓地の光属性・天使族モンスター３体と闇属性・悪魔族モンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\nフィールド上に表側表示で存在するモンスター１体をゲームから除外する事ができる。\nこの効果を発動する場合、このターンこのカードは攻撃する事ができない。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"天魔神 ノーレラス",h:"てんましん　のーれらす",e:"Sky Scourge Norleras",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードは通常召喚できない。\n自分の墓地から天使族・光属性モンスター１体と悪魔族・闇属性モンスター３体を除外した場合のみ特殊召喚できる。\n①：１０００LPを払って発動できる。\nお互いの手札・フィールドのカードを全て墓地へ送り、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"天魔神 インヴィシル",h:"てんましん　いんう゛ぃしる",e:"Sky Scourge Invicil",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"このカードは特殊召喚できない。\nこのカードのアドバンス召喚のためにリリースしたモンスターの種族と属性によって、このカードは以下の効果を得る。\n\n●天使族・光属性：このカードがフィールド上に表側表示で存在する限り、フィールド上の魔法カードの効果を無効にする。\n\n●悪魔族・闇属性：このカードがフィールド上に表側表示で存在する限り、フィールド上の罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"忍者義賊ゴエゴエ",h:"にんじゃぎぞくごえごえ",e:"Goe Goe the Gallant Ninja",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"相手の手札が５枚以上の場合、このカードが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに２枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"結界術師 メイコウ",h:"けっかいじゅつし　めいこう",e:"Mei-Kou, Master of Barriers",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"このカードをリリースして発動できる。\nフィールド上に表側表示で存在する永続魔法・永続罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"風帝ライザー",h:"ふうていらいざー",e:"Raiza the Storm Monarch",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドのカード１枚を対象として発動する。\nそのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"プレートクラッシャー",h:"ぷれーとくらっしゃー",e:"Seismic Crasher",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1400,de:300,tx:"自分フィールド上に存在する表側表示の永続魔法または永続罠カード１枚を墓地に送る。\n相手ライフに５００ポイントダメージを与える。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"龍脈に棲む者",h:"りゅうみゃくにすむもの",e:"Dweller in the Depths",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"①：このカードの攻撃力は、自分の魔法＆罠ゾーンの永続魔法カードの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"マグナ・スラッシュドラゴン",h:"まぐな・すらっしゅどらごん",e:"Magna-Slash Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"自分フィールド上に表側表示で存在する永続魔法カード１枚を墓地へ送って発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"グラビ・クラッシュドラゴン",h:"ぐらび・くらっしゅどらごん",e:"Gravi-Crush Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"①：自分フィールドの表側表示の永続魔法カード１枚を墓地へ送り、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の恵み",h:"ほうぎょくのめぐみ",e:"Crystal Blessing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「宝玉獣」と名のついたモンスターを２体まで選択して発動できる。\n選択したモンスターを永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の氾濫",h:"ほうぎょくのはんらん",e:"Crystal Abundance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の魔法＆罠カードゾーンの「宝玉獣」と名のついたカード４枚を墓地へ送って発動できる。\nフィールド上のカードを全て墓地へ送る。\nさらにこの効果によって墓地へ送った相手フィールド上のカードの数まで、自分の墓地の「宝玉獣」と名のついたモンスターを可能な限り特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の契約",h:"ほうぎょくのけいやく",e:"Crystal Promise",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の魔法＆罠カードゾーンの「宝玉獣」と名のついたカード１枚を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幸運の鉄斧",h:"こううんのてつおの",e:"Lucky Iron Axe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は５００アップする。\n②：フィールドに表側表示で存在するこのカードが相手の効果で破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トルネード",h:"とるねーど",e:"Tornado",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手の魔法＆罠カードゾーンにカードが３枚以上存在する場合に発動できる。\n相手の魔法＆罠カードゾーンのカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フィールドバリア",h:"ふぃーるどばりあ",e:"Field Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いにフィールド魔法カードを破壊できず、フィールド魔法カードの発動もできない。\n「フィールドバリア」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"「A」細胞増殖装置",h:"「えー」さいぼうぞうしょくそうち",e:"A Cell Breeding Device",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ時、相手フィールド上に表側表示で存在するモンスター１体を選択し、Aカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"異界空間－Aゾーン",h:"いかいくうかん－えーぞーん",e:"Otherworld - The \"A\" Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"相手モンスターが自分フィールド上に存在する「エーリアン」と名のついたモンスターと戦闘する場合、相手モンスターの攻撃力と守備力はダメージ計算時のみ３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の祈り",h:"ほうぎょくのいのり",e:"Crystal Raigeki",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の魔法＆罠カードゾーンの「宝玉獣」と名のついたカード１枚を墓地へ送って発動できる。\n相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・チャージ",h:"う゛ぉるかにっく・ちゃーじ",e:"Volcanic Recharge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「ヴォルカニック」と名のついたモンスターカードを３枚までデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔の取引",h:"まのとりひき",e:"Terrible Deal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が魔法カードを発動した時、１０００ライフポイントを払って発動できる。\n相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"中央突破",h:"ちゅうおうとっぱ",e:"Breakthrough!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を選択して発動する。\nこのターンのバトルフェイズ中、選択したモンスターが相手モンスターを戦闘によって破壊した場合、自分フィールド上に存在する「大将軍 紫炎」または「六武衆」と名のついたモンスターは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"究極・背水の陣",h:"きゅうきょく・はいすいのじん",e:"Backs to the Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ライフポイントを１００ポイントになるように払って発動できる。\n自分の墓地の「六武衆」と名のついたモンスターを可能な限り特殊召喚する。\n（同名カードは１枚まで。\nただし、フィールド上に存在する同名カードは特殊召喚できない。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"義賊の入門書",h:"ぎぞくのにゅうもんしょ",e:"Introduction to Gallantry",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の手札が５枚以上の場合に発動できる。\n相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"義賊の極意書",h:"ぎぞくのごくいしょ",e:"Secrets of the Gallant",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の通常モンスター１体を選択して発動できる。\nこのターン選択したモンスターが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに２枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光のバリア －シャイニング・フォース－",h:"せんこうのばりあ　－しゃいにんぐ・ふぉーす－",e:"Radiant Mirror Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に攻撃表示モンスターが３体以上存在する場合、相手の攻撃宣言時に発動する事ができる。\n相手フィールド上の攻撃表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"押し売りゴブリン",h:"おしうりごぶりん",e:"Hard-sellin' Goblin",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上のモンスターが相手ライフに戦闘ダメージを与えた時、相手の魔法＆罠カードゾーンのカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"押し売りゾンビ",h:"おしうりぞんび",e:"Hard-sellin' Zombie",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上のモンスターが相手ライフに戦闘ダメージを与えた時、相手の墓地のカード１枚を選択して持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"集団催眠",h:"しゅうだんさいみん",e:"Mass Hypnosis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「エーリアン」と名のついたモンスターが存在する時に発動する事ができる。\n相手フィールド上に存在するAカウンターが乗ったモンスターを３体まで選択しコントロールを得る。\nこのカードは発動ターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムフラッシュエナジー",h:"じぇむふらっしゅえなじー",e:"Gem Flash Energy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎にフィールド上に表側表示で存在する永続魔法カードの枚数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ボルテック・コング",h:"ぼるてっく・こんぐ",e:"Voltic Kong",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分フィールド上に表側表示で存在する光属性モンスターの数だけ相手のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ボタニカル・ライオ",h:"ぼたにかる・らいお",e:"Botanical Lion",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"自分フィールド上に表側表示で存在する植物族モンスター１体につき、このカードの攻撃力は３００ポイントアップする。\nこのカードはフィールド上に表側表示で存在する限り、コントロールを変更する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"グラヴィティ・ベヒモス",h:"ぐらう゛ぃてぃ・べひもす",e:"Gravity Behemoth",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"フィールド上にカードが存在しない場合、このカードはリリースなしで召喚する事ができる。\nこの効果は先攻１ターン目には使用する事ができない。\nこのカードは攻撃する代わりにフィールド上に存在するフィールド魔法カードを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の師範",h:"ろくぶしゅうのしはん",e:"Grandmaster of the Six Samurai",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"①：「六武衆の師範」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに「六武衆」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：このカードが相手の効果で破壊された場合、自分の墓地の「六武衆」モンスター１体を対象として発動する。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔王ディアボロス",h:"まおうでぃあぼろす",e:"Diabolos, King of the Abyss",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは特殊召喚できない。\nこのカードをアドバンス召喚する場合、リリースするモンスターは闇属性モンスターでなければならない。\n①：このカードはモンスターゾーンに存在する限り、効果ではリリースできない。\n②：相手ドローフェイズのドロー前に発動する。\n相手のデッキの一番上のカードを確認し、デッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"死王リッチーロード",h:"しおうりっちーろーど",e:"Lich Lord, King of the Underworld",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードをアドバンス召喚する場合、リリースするモンスターは闇属性モンスターでなければならない。\n①：このカードが効果でリリースされ墓地へ送られた場合に発動する。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"闇王プロメティス",h:"やみおうぷろめてぃす",e:"Prometheus, King of the Shadows",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが召喚に成功した場合に発動する。\n自分の墓地の闇属性モンスターを任意の数だけ選んで除外する。\nこのカードの攻撃力はターン終了時まで、除外した数×４００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミストデーモン",h:"みすとでーもん",e:"Mist Archfiend",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"①：このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚されている場合、エンドフェイズに発動する。\nこのカードを破壊し、自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"疫病狼",h:"えきびょうおおかみ",e:"Plague Wolf",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力はターン終了時まで元々の攻撃力の倍になる。\n②：このカードの①の効果を発動した場合、エンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"悪夢再び",h:"あくむふたたび",e:"Recurring Nightmare",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の守備力０の闇属性モンスター２体を対象として発動できる。\nその闇属性モンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サクリファイス・ソード",h:"さくりふぁいす・そーど",e:"Sword of Dark Rites",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"闇属性モンスターのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップする。\n装備モンスターが生け贄に捧げられる事によってこのカードが墓地に送られた場合、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"闇のデッキ破壊ウイルス",h:"やみのでっきはかいういるす",e:"Eradicator Epidemic Virus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力２５００以上の闇属性モンスター１体をリリースし、カードの種類（魔法・罠）を宣言して発動できる。\n相手フィールドの魔法・罠カード、相手の手札、相手ターンで数えて３ターンの間に相手がドローしたカードを全て確認し、その内の宣言した種類のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コクーン・パーティ",h:"こくーん・ぱーてぃ",e:"Cocoon Party",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「N」と名のついたモンスター１種類につき、「C」と名のついたモンスター１体を自分のデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"C・チッキー",h:"こくーん・ちっきー",e:"Chrysalis Chicky",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:600,de:400,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・エア・ハミングバード」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"C・パンテール",h:"こくーん・ぱんてーる",e:"Chrysalis Pantail",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:300,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・ブラック・パンサー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"C・ピニー",h:"こくーん・ぴにー",e:"Chrysalis Pinny",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:700,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・グロー・モス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コクーン・ヴェール",h:"こくーん・う゛ぇーる",e:"Cocoon Veil",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「C」と名のついたモンスター１体を生け贄に捧げて発動する事ができる。\nこのターン効果によるプレイヤーへのダメージは０になる。\nその後、生け贄に捧げた「C」と名のついたモンスターに記されている「N」と名のついたモンスター１体を手札・デッキ・墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コンバート・コンタクト",h:"こんばーと・こんたくと",e:"Convert Contact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\n手札及びデッキから「N」カードを１枚ずつ墓地へ送る。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー逆襲",h:"ひーろーぎゃくしゅう",e:"Hero Counterattack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「E・HERO」と名のついたモンスターが戦闘によって破壊された時に発動する事ができる。\n自分の手札から相手はカード１枚をランダムに選択する。\nそれが「E・HERO」と名のついたモンスターカードだった場合、相手フィールド上のモンスター１体を破壊し、選択したカードを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホープ・オブ・フィフス",h:"ほーぷ・おぶ・ふぃふす",e:"Fifth Hope",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「E・HERO」と名のついたカード５枚を選択し、デッキに加えてシャッフルする。\nその後、デッキからカードを２枚ドローする。\nこのカードの発動時に自分の手札・フィールド上に他のカードが存在しない場合はカードを３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"NEX",h:"ねおすぺーしあんえくすてんと",e:"NEX",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「N」と名のついたモンスター１体を墓地へ送り、墓地へ送ったカードと同名カード扱いのレベル４モンスター１体を融合デッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リバース・オブ・ネオス",h:"りばーす・おぶ・ねおす",e:"Reverse of Neos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ネオス」と名のついた融合モンスターが破壊された時に発動する事ができる。\n自分のデッキから「E・HERO ネオス」１体を攻撃表示で特殊召喚する。\nこの効果で特殊召喚された「E・HERO ネオス」の攻撃力はフィールド上に表側表示で存在する限り１０００ポイントアップし、このターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェノム・スワンプ",h:"う゛ぇのむ・すわんぷ",e:"Venom Swamp",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"お互いのターンのエンドフェイズ毎に、フィールド上に表側表示で存在する「ヴェノム」と名のついたモンスター以外の表側表示で存在する全てのモンスターにヴェノムカウンターを１つ置く。\nヴェノムカウンター１つにつき、攻撃力は５００ポイントダウンする。\nこの効果で攻撃力が０になったモンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"スネーク・ホイッスル",h:"すねーく・ほいっする",e:"Snake Whistle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する爬虫類族モンスターが破壊された時に発動する事ができる。\n自分のデッキからレベル４以下の爬虫類族モンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スネーク・レイン",h:"すねーく・れいん",e:"Snake Rain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\nデッキから爬虫類族モンスター４体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ＝レプトル",h:"だめーじいこーるれぷとる",e:"Damage = Reptile",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、爬虫類族モンスターの戦闘によって自分が戦闘ダメージを受けた時に発動できる。\nその時に受けたダメージの数値以下の攻撃力を持つ爬虫類族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"蛇神の勅命",h:"じゃしんのちょくめい",e:"Snake Deity's Command",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札の「ヴェノム」と名のついたモンスターカード１枚を相手に見せて発動する。\n相手の魔法カードの発動と効果を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"蛇神降臨",h:"じゃしんこうりん",e:"Rise of the Snake Deity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「毒蛇王ヴェノミノン」が戦闘以外で破壊された時に発動できる。\n手札・デッキから「毒蛇神ヴェノミナーガ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元トンネル－ミラーゲート－",h:"いじげんとんねる－みらーげーと－",e:"Mirror Gate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「E・HERO」と名のついたモンスターを攻撃対象にした相手モンスターの攻撃宣言時に発動する事ができる。\n相手の攻撃モンスターと攻撃対象となった自分モンスターのコントロールを入れ替えてダメージ計算を行う。\nこのターンのエンドフェイズ時までコントロールを入れ替えたモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"究極宝玉神 レインボー・ドラゴン",h:"きゅうきょくほうぎょくしん　れいんぼー・どらごん",e:"Rainbow Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n自分のフィールド・墓地に「宝玉獣」カードが７種類存在する場合のみ特殊召喚できる。\nこのカードが特殊召喚したターン、このカードの①②の効果は発動できない。\n①：自分フィールドの表側表示の「宝玉獣」モンスターを全て墓地へ送って発動できる。\nこのカードの攻撃力は墓地へ送ったモンスターの数×１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：自分の墓地から「宝玉獣」モンスターを全て除外して発動できる。\nフィールドのカードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"C・ラーバ",h:"こくーん・らーば",e:"Chrysalis Larva",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・フレア・スカラベ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"C・モーグ",h:"こくーん・もーぐ",e:"Chrysalis Mole",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:700,de:100,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・グラン・モール」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"N・マリン・ドルフィン",h:"ねおすぺーしあん・まりん・どるふぃん",e:"Neo-Spacian Marine Dolphin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:900,de:1100,tx:"このカード名はルール上「N・アクア・ドルフィン」としても扱う。\nこのカードは「NEX」の効果でのみ特殊召喚できる。\n手札を１枚捨てる。\n相手の手札を確認してモンスターカード１枚を選択する。\n選択したモンスターの攻撃力以上のモンスターが自分フィールド上に存在する場合、選択したモンスターカードを破壊して相手ライフに５００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO マリン・ネオス",h:"えれめんたるひーろー　まりん・ねおす",e:"Elemental HERO Marine Neos",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2300,tx:"「E・HERO ネオス」＋「N・マリン・ドルフィン」\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\n相手の手札１枚をランダムに選択し破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ダーク・ブライトマン",h:"えれめんたるひーろー　だーく・ぶらいとまん",e:"Elemental HERO Darkbright",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1000,tx:"「E・HERO スパークマン」＋「E・HERO ネクロダークマン」\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。\nこのカードが破壊された時、相手フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO マグマ・ネオス",h:"えれめんたるひーろー　まぐま・ねおす",e:"Elemental HERO Magma Neos",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「N・フレア・スカラベ」＋「N・グラン・モール」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nこのカードの攻撃力は、フィールド上のカードの数×４００ポイントアップする。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。\nこの効果によってこのカードがエクストラデッキに戻った時、フィールド上のカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロ・ガードナー",h:"ねくろ・がーどなー",e:"Necro Gardna",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:1300,tx:"①：相手ターンに墓地のこのカードを除外して発動できる。\nこのターン、相手モンスターの攻撃を１度だけ無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・ナイト",h:"おじゃま・ないと",e:"Ojama Knight",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:0,de:2500,tx:"「おジャマ」モンスター×２\n①：このカードがモンスターゾーンに表側表示で存在する間、使用していない相手のモンスターゾーンを２ヵ所まで指定し、そのゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェノム・スネーク",h:"う゛ぇのむ・すねーく",e:"Venom Snake",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"１ターンに１度だけ、相手フィールド上モンスター１体にヴェノムカウンターを１つ置く事ができる。\nこの効果を使用したターンこのモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェノム・ボア",h:"う゛ぇのむ・ぼあ",e:"Venom Boa",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度だけ、相手フィールド上モンスター１体にヴェノムカウンターを２つ置く事ができる。\nこの効果を使用したターンこのモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェノム・サーペント",h:"う゛ぇのむ・さーぺんと",e:"Venom Serpent",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"１ターンに１度だけ、相手フィールド上モンスター１体にヴェノムカウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"毒蛇王ヴェノミノン",h:"どくじゃおうう゛ぇのみのん",e:"Vennominon the King of Poisonous Snakes",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはこのカード以外の効果モンスターの効果では特殊召喚できない。\nこのカードの攻撃力は、自分の墓地の爬虫類族モンスターの数×５００ポイントアップする。\nこのカードはフィールド上に表側表示で存在する限り、「ヴェノム・スワンプ」の効果を受けない。\nこのカードが戦闘によって破壊され墓地へ送られた時、このカード以外の自分の墓地の爬虫類族モンスター１体をゲームから除外する事で、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"毒蛇神ヴェノミナーガ",h:"どくじゃしんう゛ぇのみなーが",e:"Vennominaga the Deity of Poisonous Snakes",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n「蛇神降臨」の効果及びこのカードの効果でのみ特殊召喚できる。\nこのカードの攻撃力は、自分の墓地の爬虫類族モンスターの数×５００ポイントアップする。\nこのカードはフィールド上に表側表示で存在する限り、このカード以外のカードの効果の対象にならず、効果も受けない。\nこのカードが戦闘によって破壊され墓地へ送られた時、このカード以外の自分の墓地の爬虫類族モンスター１体をゲームから除外する事で、このカードを特殊召喚する。\nこのカードが相手ライフに戦闘ダメージを与えた時、このカードにハイパーヴェノムカウンターを１つ置く。\nこのカードにハイパーヴェノムカウンターが３つ乗った時、このカードのコントローラーはデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーローアライブ",h:"ひーろーあらいぶ",e:"A Hero Lives",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに表側表示モンスターが存在しない場合、LPを半分払って発動できる。\nデッキからレベル４以下の「E・HERO」モンスター１体を特殊召喚する。",li:2,lk:[],ta:[],al:[]},
  {n:"儀式の供物",h:"ぎしきのくもつ",e:"Ritual Raven",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"闇属性の儀式モンスターを特殊召喚する場合、このカード１枚で儀式召喚のための生け贄として使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェノム・ショット",h:"う゛ぇのむ・しょっと",e:"Venom Shot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「毒蛇王ヴェノミノン」、「毒蛇神ヴェノミナーガ」または「ヴェノム」と名のついたモンスターが表側表示で存在する時に発動する事ができる。\n自分のデッキから爬虫類族モンスター１体を墓地に送り、相手フィールド上に表側表示で存在するモンスター１体にヴェノムカウンターを２つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・ソルジャー",h:"えーりあん・そるじゃー",e:"Alien Shocktrooper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1900,de:800,tx:"謎の生命体、エーリアンの上級戦士。\n比較的高い攻撃力を持つが、反面特殊な能力は身につけていない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・ラット",h:"う゛ぉるかにっく・らっと",e:"Volcanic Rat",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"灼熱の火山地帯に生息するネズミの変種。\nどんな暑さにも耐えられる体を持っている。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の番兵 レンジ",h:"あんこくかいのばんぺい れんじ",e:"Renge, Gatekeeper of Dark World",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:100,de:2100,tx:"暗黒界随一の強靱な身体を誇り、「鉄壁レンジ」として暗黒界の人々から親しまれている。\n彼の守りを破れる者は少ない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハウンド・ドラゴン",h:"はうんど・どらごん",e:"Hunter Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1700,de:100,tx:"鋭い牙で獲物を仕留めるドラゴン。\n鋭く素早い動きで攻撃を繰り出すが、守備能力は持ち合わせていない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェノム・コブラ",h:"う゛ぇのむ・こぶら",e:"Venom Cobra",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:100,de:2000,tx:"堅いウロコに覆われた巨大なコブラ。\n大量の毒液を射出して攻撃するが、その巨大さ故毒液は大味である。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO アナザー・ネオス",h:"えれめんたるひーろー　あなざー・ねおす",e:"Elemental HERO Neos Alius",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1900,de:1300,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードはモンスターゾーンに存在する限り、カード名を「E・HERO ネオス」として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルカイザー・ドラゴン",h:"へるかいざー・どらごん",e:"Chthonian Emperor Dragon",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"水面のアレサ",h:"みなものあれさ",e:"Aquarian Alessa",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"幸運の笛吹き",h:"こううんのふえふき",e:"Lucky Pied Piper",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァリュアブル・アーマー",h:"う゛ぁりゅあぶる・あーまー",e:"Grasschopper",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["デュアル","効果"],ma:"",tp:"",at:2350,de:1000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードは相手フィールド上の全てのモンスターに１回ずつ攻撃をする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"巨人ゴーグル",h:"きょじんごーぐる",e:"Goggle Golem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの元々の攻撃力は２１００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サンライズ・ガードナー",h:"さんらいず・がーどなー",e:"Dawnbreak Gardna",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの元々の守備力は２３００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔族召喚師",h:"でびるず・さもなー",e:"Doom Shaman",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["デュアル","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分のメインフェイズ時に発動できる。\n手札または自分・相手の墓地から悪魔族モンスター１体を選んで特殊召喚する。\nこのカードがフィールド上から離れた時、この効果で特殊召喚した悪魔族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"灼熱王パイロン",h:"しゃくねつおうぱいろん",e:"King Pyron",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分のメインフェイズ時に発動できる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドウ・ダイバー",h:"しゃどう・だいばー",e:"Shadow Delver",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分フィールド上に表側表示で存在する闇属性・レベル４以下モンスター１体を選択して発動する。\n選択したモンスターはこのターン相手プレイヤーに直接攻撃する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フリントロック",h:"ふりんとろっく",e:"Flint Lock",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"フィールド上に表側表示の「フリント」が存在する場合、このカードに装備させる事ができる。\nこの効果は１ターンに１度しか使用できない。\nまた、このカードが装備している「フリント」１枚を、フィールド上に存在する表側表示モンスター１体に装備させる事ができる。\nこのカードが装備できる「フリント」は１枚まで。\nこのカードが「フリント」を装備している限り、このカードは「フリント」の効果を受けず、戦闘によっては破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"グラビティ・ボール",h:"ぐらびてぃ・ぼーる",e:"Gravitic Orb",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:700,tx:"リバース：相手フィールド上に存在する表側表示モンスター全ての表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影コオロギ",h:"げんえいこおろぎ",e:"Phantom Cricket",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1000,tx:"リバース：フィールド上に裏側表示で存在するモンスター１体を持ち主のデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶の占い師",h:"すいしょうのうらないし",e:"Crystal Seer",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:100,tx:"リバース：自分のデッキの上からカードを２枚めくり、その中から１枚を選んで手札に加える。\nその後、残りのカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオスペース・コンダクター",h:"ねおすぺーす・こんだくたー",e:"Neo Space Pathfinder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードを手札から墓地へ捨てて発動できる。\n自分のデッキ・墓地から「ネオスペース」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷炎の双竜",h:"ふろすとあんどふれいむ・ついんどらごん",e:"Frost and Flame Dragon",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地の水属性モンスター２体と炎属性モンスター１体をゲームから除外した場合のみ特殊召喚できる。\n１ターンに１度、手札を１枚捨てて発動できる。\nフィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デザート・ツイスター",h:"でざーと・ついすたー",e:"Desert Twister",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地の風属性モンスター２体と地属性モンスター１体をゲームから除外する事でのみ特殊召喚する事ができる。\n手札を１枚捨てる事でフィールド上の魔法・罠カード１枚を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"カミソーリトカゲ",h:"かみそーりとかげ",e:"Razor Lizard",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"自分フィールド上にこのカード以外の爬虫類族モンスターが存在する限り、このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリーフレーム",h:"ほーりーふれーむ",e:"Light Effigy",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"光属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークフレーム",h:"だーくふれーむ",e:"Dark Effigy",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"闇属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾンビ・マスター",h:"ぞんび・ますたー",e:"Zombie Master",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：１ターンに１度、手札からモンスター１体を墓地へ送り、自分または相手の墓地のレベル４以下のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを自分フィールドに特殊召喚する。\nこの効果はこのカードがモンスターゾーンに表側表示で存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"コクーン・リボーン",h:"こくーん・りぼーん",e:"Cocoon Rebirth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「C」と名のついたモンスター１体を生け贄に捧げる事で、そのカードに記されている「N」と名のついたモンスター１体を墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバーダーク・インパクト！",h:"さいばーだーく・いんぱくと！",e:"Cyberdark Impact!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールド・墓地から、「サイバー・ダーク・ホーン」「サイバー・ダーク・エッジ」「サイバー・ダーク・キール」を１枚ずつ持ち主のデッキに戻し、「鎧黒竜－サイバー・ダーク・ドラゴン」１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フリント・アタック",h:"ふりんと・あたっく",e:"Flint Missile",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「フリント」を装備したモンスター１体を破壊する。\n発動後このカードが墓地へ送られた時、このカードをデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"二重召喚",h:"でゅあるさもん",e:"Double Summon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このターン自分は通常召喚を２回まで行う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚師のスキル",h:"しょうかんしのすきる",e:"Summoner's Art",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからレベル５以上の通常モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"鹵獲装置",h:"ろかくそうち",e:"Creature Seizure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いが自分フィールド上モンスターを１体ずつ選択し、そのモンスターのコントロールを入れ替える。\nただしこのカードのコントローラーは自分フィールド上に表側表示で存在する通常モンスターを選択しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"守護神の矛",h:"しゅごしんのほこ",e:"Phalanx Pike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は、お互いの墓地に存在する装備モンスターと同名のカードの数×９００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"戦線復活の代償",h:"せんせんふっかつのだいしょう",e:"Symbols of Duty",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の通常モンスター１体を墓地へ送り、自分または相手の墓地のモンスター１体を選択して発動できる。\n選択したモンスターを自分フィールド上に特殊召喚し、このカードを装備する。\nこのカードがフィールド上から離れた時、装備モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"下克上の首飾り",h:"げこくじょうのくびかざり",e:"Amulet of Ambition",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"通常モンスターにのみ装備可能。\n装備モンスターよりレベルの高いモンスターと戦闘を行う場合、装備モンスターの攻撃力はダメージ計算時のみレベルの差×５００ポイントアップする。\nこのカードが墓地へ送られた時、このカードをデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"折れ竹光",h:"おれたけみつ",e:"Broken Bamboo Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は０ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"反撃の毒牙",h:"はんげきのどくが",e:"Ambush Fangs",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ヴェノム」と名のついたモンスターが攻撃宣言を受けた時に発動する事ができる。\n相手モンスター１体の攻撃を無効にし、バトルフェイズを終了する。\nその後、攻撃モンスターにヴェノムカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェノム・スプラッシュ",h:"う゛ぇのむ・すぷらっしゅ",e:"Venom Burn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ヴェノムカウンターが乗ったモンスター１体を選択して発動する。\nそのカードのヴェノムカウンターを取り除き、取り除いたヴェノムカウンターの数×７００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"凡人の施し",h:"ぼんじんのほどこし",e:"Common Charity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"デッキからカードを２枚ドローし、その後手札の通常モンスター１体をゲームから除外する。\n手札に通常モンスターが無い場合、手札を全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅へのクイック・ドロー",h:"はめつへのくいっく・どろー",e:"Destructive Draw",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのプレイヤーはドローフェイズ開始時に手札が０枚だった場合、通常のドローに加えてもう１枚ドローする事ができる。\nこのカードのコントローラーは自分のターンのエンドフェイズ毎に７００ライフポイントを払う。\nこの時にライフポイントが７００未満だった場合、ライフポイントは０になる。\n自分フィールド上に表側表示で存在するこのカードがフィールド上から離れた時、自分は３０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"シールドスピア",h:"しーるどすぴあ",e:"Shield Spear",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで４００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ストライク・ショット",h:"すとらいく・しょっと",e:"Strike Slash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターの攻撃宣言時に発動する事ができる。\nそのモンスターの攻撃力はエンドフェイズ時まで７００ポイントアップする。\nそのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・キャプチャー",h:"まじっく・きゃぷちゃー",e:"Spell Reclamation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が魔法カードを発動した時、手札を１枚捨ててチェーン発動する。\nチェーン発動した魔法カードが墓地へ送られた時、そのカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"トラップ・キャプチャー",h:"とらっぷ・きゃぷちゃー",e:"Trap Reclamation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が罠カードを発動した時、手札を１枚捨ててチェーン発動する。\nチェーン発動した罠カードが墓地へ送られた時、そのカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ギフトカード",h:"ぎふとかーど",e:"Gift Card",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手は３０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲な贈り物",h:"ごうよくなおくりもの",e:"The Gift of Greed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"カウンター・カウンター",h:"かうんたー・かうんたー",e:"Counter Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"カウンター罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"モーターシェル",h:"もーたーしぇる",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分フィールドに「モータートークン」（機械族・地・星１・攻／守２００）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・ロケット",h:"う゛ぉるかにっく・ろけっと",e:"Volcanic Rocket",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分のデッキ・墓地から「ブレイズ・キャノン」と名のついたカード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"フェルグラントドラゴン",h:"ふぇるぐらんとどらごん",e:"Felgrand Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2800,tx:"このカードは墓地からしか特殊召喚できず、フィールドから墓地へ送られていなければ墓地からの特殊召喚もできない。\n①：このカードが墓地からの特殊召喚に成功した場合、自分の墓地のモンスター１体を対象として発動する。\nこのカードの攻撃力は、対象のモンスターのレベル×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークブレイズドラゴン",h:"だーくぶれいずどらごん",e:"Darkblaze Dragon",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードが墓地からの特殊召喚に成功した場合に発動する。\nこのカードの攻撃力・守備力は元々の数値の倍になる。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"創世の預言者",h:"そうせいのよげんしゃ",e:"Herald of Creation",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：１ターンに１度、手札を１枚捨て、自分の墓地のレベル７以上のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デコイドラゴン",h:"でこいどらごん",e:"Decoy Dragon",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：このカードが攻撃対象に選択された場合、自分の墓地のレベル７以上のドラゴン族モンスター１体を対象として発動する。\nそのモンスターを特殊召喚し、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"トレード・イン",h:"とれーど・いん",e:"Trade-In",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札からレベル８モンスター１体を捨てて発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"The big SATURN",h:"ざ・びっぐ・さたーん",e:"The Big Saturn",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは手札またはデッキからの特殊召喚はできない。\n手札を１枚捨てて１０００ライフポイントを払う。\nエンドフェイズ時までこのカードの攻撃力は１０００ポイントアップする。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。\n相手がコントロールするカードの効果によってこのカードが破壊され墓地へ送られた時、お互いにその攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO プラズマヴァイスマン",h:"えれめんたるひーろー　ぷらずまう゛ぁいすまん",e:"Elemental HERO Plasma Vice",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2300,tx:"「E・HERO スパークマン」＋「E・HERO エッジマン」\nこのカードは融合召喚でしか特殊召喚できない。\n自分のメインフェイズ時に、手札を１枚捨てて発動できる。\n相手フィールド上に攻撃表示で存在するモンスター１体を選択して破壊する。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古生代化石騎士 スカルキング",h:"こせいだいかせききし　すかるきんぐ",e:"Fossil Warrior Skull King",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:1300,tx:"岩石族モンスター＋レベル７以上のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：相手ターンに、相手の墓地のモンスター１体を対象として発動できる。\n自分の手札を１枚選んで捨て、対象のモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーリミット",h:"おーばーりみっと",e:"Over Limit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払う。\nこのターン戦闘で破壊された攻撃力１０００以下の通常モンスターを、可能な限り自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コンタクト・アウト",h:"こんたくと・あうと",e:"Contact Out",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ネオス」と名のついた融合モンスター１体を融合デッキに戻す。\nさらに、融合デッキに戻したモンスターに記された融合素材モンスター一組が自分のデッキに揃っていれば、この一組を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"思い出のブランコ",h:"おもいでのぶらんこ",e:"Swing of Memories",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO インフェルノ・ウィング",h:"いーびるひーろー　いんふぇるの・うぃんぐ",e:"Evil HERO Inferno Wing",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1200,tx:"「E・HERO フェザーマン」＋「E・HERO バーストレディ」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力と元々の守備力の内、高い方の数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO マリシャス・エッジ",h:"いーびるひーろー　まりしゃす・えっじ",e:"Evil HERO Malicious Edge",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"相手フィールドにモンスターが存在する場合、このカードはモンスター１体をリリースして表側表示でアドバンス召喚できる。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO ヘル・ゲイナー",h:"いーびるひーろー　へる・げいなー",e:"Evil HERO Infernal Gainer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：自分メインフェイズ１にフィールドのこのカードを除外し、自分フィールドの悪魔族モンスター１体を対象として発動できる。\nその自分の悪魔族モンスターは、フィールドに表側表示で存在する限り、１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが①の効果を発動するために除外された場合、２回目の自分スタンバイフェイズに発動する。\nこのカードを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO ライトニング・ゴーレム",h:"いーびるひーろー　らいとにんぐ・ごーれむ",e:"Evil HERO Lightning Golem",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「E・HERO スパークマン」＋「E・HERO クレイマン」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：１ターンに１度、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO ダーク・ガイア",h:"いーびるひーろー　だーく・がいあ",e:"Evil HERO Dark Gaia",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:0,tx:"悪魔族モンスター＋岩石族モンスター\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードの元々の攻撃力は、このカードの融合素材としたモンスターの元々の攻撃力を合計した数値になる。\n②：このカードの攻撃宣言時に発動できる。\n相手フィールドの守備表示モンスターを全て表側攻撃表示にする。\nこの時、リバースモンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・フュージョン",h:"だーく・ふゅーじょん",e:"Dark Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、悪魔族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターは、このターン相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"古生代化石竜 スカルギオス",h:"こせいだいかせきりゅう　すかるぎおす",e:"Fossil Dragon Skullgios",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:0,tx:"岩石族モンスター＋相手の墓地のレベル７以上のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\n①：このカードが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその相手モンスターの攻撃力と守備力をそのダメージステップ終了時まで入れ替える。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：融合召喚したこのカードが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－アイ・オブ・ザ・タイフーン",h:"くらうでぃあん－あい・おぶ・ざ・たいふーん",e:"Cloudian - Eye of the Typhoon",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:3000,de:1000,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの攻撃宣言時、「雲魔物」と名のついたカード以外の全ての表側表示モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－ゴースト・フォッグ",h:"くらうでぃあん－ごーすと・ふぉっぐ",e:"Cloudian - Ghost Fog",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは特殊召喚できない。\nこのカードの戦闘によって発生するお互いのプレイヤーへの戦闘ダメージは０になる。\nこのカードが戦闘によって破壊された場合、このカードを破壊したモンスターのレベルの数だけフォッグカウンターをフィールド上に表側表示で存在するモンスターに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－ニンバスマン",h:"くらうでぃあん－にんばすまん",e:"Cloudian - Nimbusman",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードを生け贄召喚する場合、自分フィールド上の水属性モンスターを任意の数だけ生け贄にできる。\nこのカードの生け贄召喚に成功した時、生け贄に捧げた水属性モンスターの数だけフォッグカウンターをこのカードに置く。\nこのカードの攻撃力はフォッグカウンター１つにつき５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－羊雲",h:"くらうでぃあん－しーぷくらうど",e:"Cloudian - Sheep Cloud",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上に「雲魔物トークン」（天使族・水・星１・攻／守０）を２体守備表示で特殊召喚する。\nこのトークンは「雲魔物」と名のついたカード以外の生け贄召喚のための生け贄にはできない。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－ポイズン・クラウド",h:"くらうでぃあん－ぽいずん・くらうど",e:"Cloudian - Poison Cloud",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"フィールド上に表側表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、このカードを破壊したモンスターを破壊し相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイヤモンドダスト・サイクロン",h:"だいやもんどだすと・さいくろん",e:"Diamond-Dust Cyclone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フォッグカウンターが４つ以上乗っているモンスター１体を選択して発動する。\n選択したモンスターを破壊し、破壊したモンスターに乗っているフォッグカウンター４つにつき、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚雲",h:"さもんくらうど",e:"Summon Cloud",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合、自分の手札または墓地からレベル４以下の「雲魔物」と名のついたモンスター１体を特殊召喚する事ができる。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。\n墓地から特殊召喚した場合はこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"宝札雲",h:"らっきーくらうど",e:"Lucky Cloud",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"発動ターン中に「雲魔物」と名のついた同名モンスターを２体以上召喚・反転召喚・特殊召喚した場合、エンドフェイズ時に自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュラル・ディザスター",h:"なちゅらる・でぃざすたー",e:"Natural Disaster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「雲魔物」と名のついたモンスターの効果によって、相手のコントロールするカードが破壊され墓地へ送られる度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"暴風雨",h:"れいんすとーむ",e:"Rain Storm",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「雲魔物」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力を下げる事で、以下の効果を適用する。\n\n●１０００ポイント下げる事で、相手フィールド上の魔法または罠カード１枚を破壊する。\n\n●２０００ポイント下げる事で、相手フィールド上のカード２枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO カオス・ネオス",h:"えれめんたるひーろー　かおす・ねおす",e:"Elemental HERO Chaos Neos",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「N・ブラック・パンサー」＋「N・グロー・モス」\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\nエンドフェイズ時にこのカードを融合デッキに戻し、フィールド上に存在する全ての表側表示モンスターをセットした状態にする。\nコイントスを３回行い、表が出た回数によって以下の処理を行う。\nこの効果は１ターンに１度だけ自分のメインフェイズ１に使用する事ができる。\n●３回：相手フィールド上に存在する全てのモンスターを破壊する。\n●２回：このターン相手フィールド上に表側表示で存在する効果モンスターは全て効果が無効化される。\n●１回：自分フィールド上に存在する全てのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の侍従",h:"ろくぶしゅうのじじゅう",e:"Chamberlain of the Six Samurai",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:200,de:2000,tx:"六武衆を陰で支える謎多き人物。\n今はもう闘う事はないが、体に刻まれた無数の傷跡が何かを語る。\nその過去を知る者はいない。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－スモークボール",h:"くらうでぃあん－すもーくぼーる",e:"Cloudian - Smoke Ball",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:200,de:600,tx:"小さな小さな雲魔物の子供雲。\nひとりぼっちが大嫌いで、仲間達とそよ風に乗ってゆらゆらと散歩をするのが大好き。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－アシッド・クラウド",h:"くらうでぃあん－あしっど・くらうど",e:"Cloudian - Acid Cloud",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの召喚に成功した時、フィールド上に存在する「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nこのカードに乗っているフォッグカウンターを２つ取り除く事で、フィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－キロスタス",h:"くらうでぃあん－きろすたす",e:"Cloudian - Cirrostratus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:900,de:0,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの召喚に成功した時、フィールド上に存在する「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nこのカードに乗っているフォッグカウンターを２つ取り除く事で、フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－アルトス",h:"くらうでぃあん－あるとす",e:"Cloudian - Altus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの召喚に成功した時、フィールド上に存在する「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nフィールド上に存在するフォッグカウンターを３つ取り除く事で、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－タービュランス",h:"くらうでぃあん－たーびゅらんす",e:"Cloudian - Turbulence",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカードは戦闘では破壊されない。\nこのカードがフィールド上に表側守備表示で存在する場合、このカードを破壊する。\nこのカードが召喚に成功した時、フィールド上の「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nまた、このカードに乗っているフォッグカウンターを１つ取り除く事で、自分のデッキまたはお互いの墓地から「雲魔物－スモークボール」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣アレクサンデル",h:"ぐらでぃあるびーすとあれくさんでる",e:"Gladiator Beast Alexander",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2400,de:600,tx:"「剣闘獣ディカエリィ」以外の効果によって、このカードを特殊召喚する事はできない。\n特殊召喚されたこのカードは自分フィールド上に表側表示で存在する限り、魔法の効果を受けない。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣アレクサンデル」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣スパルティクス",h:"ぐらでぃあるびーすとすぱるてぃくす",e:"Gladiator Beast Spartacus",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"「剣闘獣ホプロムス」以外の効果によって、このカードを特殊召喚する事はできない。\nこのカードが特殊召喚に成功した時、デッキから「闘器」と名のついた装備魔法カード１枚を手札に加える。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣スパルティクス」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ムルミロ",h:"ぐらでぃあるびーすとむるみろ",e:"Gladiator Beast Murmillo",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、フィールド上に表側表示で存在するモンスター１体を選択して破壊する。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ムルミロ」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ベストロウリィ",h:"ぐらでぃあるびーすとべすとろうりぃ",e:"Gladiator Beast Bestiari",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した場合、フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣ベストロウリィ」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ラクエル",h:"ぐらでぃあるびーすとらくえる",e:"Gladiator Beast Laquari",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードの元々の攻撃力は２１００になる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ラクエル」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ホプロムス",h:"ぐらでぃあるびーすとほぷろむす",e:"Gladiator Beast Hoplomus",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:700,de:2100,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードの元々の守備力は２４００になる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ホプロムス」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ディカエリィ",h:"ぐらでぃあるびーすとでぃかえりぃ",e:"Gladiator Beast Dimacari",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードは１度のバトルフェイズ中に２回攻撃をする事ができる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ディカエリィ」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣セクトル",h:"ぐらでぃあるびーすとせくとる",e:"Gladiator Beast Secutor",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"「剣闘獣」と名のついたモンスターの効果によって特殊召喚したこのカードが戦闘を行ったバトルフェイズ終了時、デッキから「剣闘獣セクトル」以外の「剣闘獣」と名のついたモンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スレイブ・エイプ",h:"すれいぶ・えいぷ",e:"Test Ape",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「剣闘獣」と名のついたレベル４以下のモンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スパルタクァの呪術師",h:"すぱるたくぁのじゅじゅつし",e:"Witch Doctor of Sparta",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"このカードがフィールド上に表側表示で存在する場合、モンスターがデッキから特殊召喚される度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフィニティ・ダーク",h:"いんふぃにてぃ・だーく",e:"Infinity Dark",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの攻撃宣言時、相手フィールド上に存在する表側表示モンスター１体の表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・スライム",h:"まじっく・すらいむ",e:"Magical Reflect Slime",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:700,de:1200,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘を行う事によって受けるコントローラーへの戦闘ダメージは相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械騎士",h:"あんてぃーく・ぎあないと",e:"Ancient Gear Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:500,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリン暗殺部隊",h:"ごぶりんあんさつぶたい",e:"Goblin Black Ops",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の賭博師",h:"れじぇんど・ぎゃんぶらー",e:"Gambler of Legend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:1400,tx:"コイントスを３回行う。\n３回とも表だった場合、相手フィールド上モンスターを全て破壊する。\n２回表だった場合、相手の手札をランダムに１枚捨てる。\n１回表だった場合、自分フィールド上に存在するカード１枚を破壊する。\n３回とも裏だった場合、自分の手札を全て捨てる。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の老中 エニシ",h:"しえんのろうじゅう　えにし",e:"Enishi, Shien's Chancellor",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地の「六武衆」と名のついたモンスター２体をゲームから除外した場合のみ特殊召喚できる。\n１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択して破壊できる。\nこの効果を発動するターン、このカードは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の御霊代",h:"ろくぶしゅうのみたましろ",e:"Spirit of the Six Samurai",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:500,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の「六武衆」と名のついたモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターの攻撃力・守備力は５００ポイントアップする。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、自分はデッキからカードを１枚ドローする。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・テレパス",h:"えーりあん・てれぱす",e:"Alien Telepath",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、相手フィールド上のモンスターに乗っているAカウンターを１つ取り除いて発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・ヒュプノ",h:"えーりあん・ひゅぷの",e:"Alien Hypno",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["デュアル","効果"],ma:"",tp:"",at:1600,de:700,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●相手フィールド上のAカウンターが乗ったモンスター１体を選択してコントロールを得る。\n自分のエンドフェイズ毎に、コントロールを得たモンスターのAカウンターを１つ取り除く。\nコントロールを得たモンスターのAカウンターが全て取り除かれた場合、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"超合魔獣ラプテノス",h:"ちょうごうまじゅうらぷてのす",e:"Superalloy Beast Raptinus",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:2200,tx:"デュアルモンスター×２\n①：このカードがモンスターゾーンに存在する限り、フィールドのデュアルモンスターはもう１度召喚された状態として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ゲオルディアス",h:"ぐらでぃあるびーすとげおるでぃあす",e:"Gladiator Beast Gaiodiaz",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"恐竜族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1500,tx:"「剣闘獣スパルティクス」＋「剣闘獣」と名のついたモンスター\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\nこのカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの守備力分のダメージを相手ライフに与える。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードを融合デッキに戻す事で、デッキから「剣闘獣スパルティクス」以外の「剣闘獣」と名のついたモンスター２体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ヘラクレイノス",h:"ぐらでぃあるびーすとへらくれいのす",e:"Gladiator Beast Heraklinos",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2800,tx:"「剣闘獣ラクエル」＋「剣闘獣」と名のついたモンスター×２\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nこのカードがフィールド上に表側表示で存在する限り、手札を１枚捨てる事で、魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォッグ・コントロール",h:"ふぉっぐ・こんとろーる",e:"Fog Control",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「雲魔物」と名のついたモンスター１体を生け贄に捧げる事で、フィールド上に表側表示で存在するモンスター１体にフォッグカウンターを３つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物のスコール",h:"くらうでぃあん・すこーる",e:"Cloudian Squall",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ毎にフィールド上に表側表示で存在する全てのモンスターに、フォッグカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"スペシャル・デュアル・サモン",h:"すぺしゃる・でゅある・さもん",e:"Super Double Summon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するデュアルモンスター１体を選択し、再度召喚した状態にする。\nこのターンのエンドフェイズ時、選択したデュアルモンスターを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣の檻－コロッセウム",h:"ぐらでぃあるびーすとのおり－ころっせうむ",e:"Colosseum - Cage of the Gladiator Beasts",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"モンスターがデッキからフィールド上に特殊召喚される度に、このカードにカウンターを１つ置く。\nフィールド上に表側表示で存在する「剣闘獣」と名のついたモンスターは、このカードに乗っているカウンター１つにつき、攻撃力と守備力が１００ポイントアップする。\nこのカードがカードの効果によって破壊される時、手札から「剣闘獣の檻－コロッセウム」１枚を捨てる事でこのカードは破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣の闘器ハルバード",h:"ぐらでぃあるびーすとのとうきはるばーど",e:"Gladiator Beast's Battle Halberd",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「剣闘獣」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃を行ったダメージステップ終了時、フィールド上の魔法または罠カード１枚を破壊する。\n装備モンスターが自分フィールド上からデッキに戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣の闘器グラディウス",h:"ぐらでぃあるびーすとのとうきぐらでぃうす",e:"Gladiator Beast's Battle Gladius",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「剣闘獣」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は３００ポイントアップする。\n装備モンスターが自分フィールド上からデッキに戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣の闘器マニカ",h:"ぐらでぃあるびーすとのとうきまにか",e:"Gladiator Beast's Battle Manica",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「剣闘獣」と名のついたモンスターにのみ装備可能。\n装備モンスターは戦闘では破壊されない。\n装備モンスターが自分フィールド上からデッキに戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"休息する剣闘獣",h:"きゅうそくするぐらでぃあるびーすと",e:"Gladiator Beast's Respite",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から「剣闘獣」と名のついたカード２枚をデッキに戻す。\nその後、自分のデッキからカードを３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"グラディアル・リターン",h:"ぐらでぃある・りたーん",e:"Gladiator's Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"墓地に存在する「剣闘獣」と名のついたカード３枚をデッキに戻す。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魂を吸う竹光",h:"たましいをすうたけみつ",e:"Soul Devouring Bamboo Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「竹光」と名のついた装備魔法カードを装備したモンスター１体を選択して発動できる。\n選択したモンスターが相手ライフに戦闘ダメージを与えた場合、次の相手のドローフェイズをスキップする。\nこのカードは発動後２回目の自分のスタンバイフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の理",h:"ろくぶしゅうのことわり",e:"Cunning of the Six Samurai",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を墓地へ送って発動できる。\n自分または相手の墓地の「六武衆」と名のついたモンスター１体を選択して自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"「A」細胞培養装置",h:"「えー」さいぼうばいようそうち",e:"A Cell Incubator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上のAカウンターが取り除かれる度に、このカードにAカウンターを１つ置く。\nフィールド上のこのカードが破壊された時、このカードに乗っていた全てのAカウンターを、フィールド上に表側表示で存在するモンスターに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"上昇気流",h:"じょうしょうきりゅう",e:"Updraft",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に存在するフォッグカウンターを全て取り除いて発動する。\n取り除いたフォッグカウンターの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"化石岩の解放",h:"かせきがんのかいほう",e:"Release from Stone",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"ゲームから除外されている自分の岩石族モンスター１体を選択して特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターが破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光を吸い込むマジック・ミラー",h:"せんこうをすいこむまじっく・みらー",e:"Light-Imprisoning Mirror",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上・墓地で発動する光属性モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"暗闇を吸い込むマジック・ミラー",h:"くらやみをすいこむまじっく・みらー",e:"Shadow-Imprisoning Mirror",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上・墓地で発動する闇属性モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ディザーム",h:"でぃざーむ",e:"Disarm",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から「剣闘獣」と名のついたカード１枚をデッキに戻す。\n魔法カードの発動を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"パリィ",h:"ぱりぃ",e:"Parry",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から「剣闘獣」と名のついたカード１枚をデッキに戻す。\n罠カードの発動を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神速の具足",h:"しんそくのぐそく",e:"Swiftstrike Armor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のドローフェイズにドローしたカードが「六武衆」と名のついたモンスターカードだった場合、そのカードを相手に見せる事で自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"諸刃の活人剣術",h:"もろはのかつじんけんじゅつ",e:"Double-Edged Sword Technique",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「六武衆」モンスター２体を対象として発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊され、自分は破壊されたモンスターの攻撃力の合計分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"エネルギー吸収板",h:"えねるぎーきゅうしゅうばん",e:"Energy-Absorbing Monolith",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分にダメージを与える魔法・罠・効果モンスターの効果を相手が発動した時に発動できる。\n自分はダメージを受ける代わりに、その数値分だけライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"細胞爆破ウイルス",h:"さいぼうばくはういるす",e:"Cell Explosion Virus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"Aカウンターが乗った相手モンスターの攻撃宣言時に発動できる。\n相手フィールド上に攻撃表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デストラクト・サークル－A",h:"ですとらくと・さーくる－えー",e:"Detonator Circle \"A\"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するAカウンターが乗ったモンスター１体を破壊し、お互いに１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"亜空間ジャンプ装置",h:"あくうかんじゃんぷそうち",e:"Interdimensional Warp",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上モンスター１体と、相手フィールド上のAカウンターが乗ったモンスター１体のコントロールを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミス・リバイブ",h:"みす・りばいぶ",e:"Foolish Revival",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地のモンスター１体を選択して発動できる。\n選択したモンスターを相手フィールド上に表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"不運なリポート",h:"ふうんなりぽーと",e:"An Unfortunate Report",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手は次のバトルフェイズを２回行う。",li:"",lk:[],ta:[],al:[]},
  {n:"デモニック・モーター・Ω",h:"でもにっく・もーたー・おめが",e:"Fiendish Engine Ω",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力はターン終了時まで１０００アップする。\n②：このカードの①の効果を発動した場合、エンドフェイズに発動する。\nこのカードを破壊する。\n③：自分エンドフェイズに発動する。\n自分フィールドに「モータートークン」（機械族・地・星１・攻／守２００）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアル・サモナー",h:"でゅある・さもなー",e:"Gemini Summoner",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手のエンドフェイズ時に１度だけ５００ライフポイントを払って発動する事ができる。\n手札または自分フィールド上に表側表示で存在するデュアルモンスター１体を通常召喚する。\nまた、このカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"オーシャンズ・オーパー",h:"おーしゃんず・おーぱー",e:"Ocean's Keeper",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードが戦闘によって破壊された場合、自分のデッキから「サウザンド・アイズ・フィッシュ」または「マザー・ブレイン」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サウザンド・アイズ・フィッシュ",h:"さうざんど・あいず・ふぃっしゅ",e:"Thousand-Eyes Jellyfish",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["特殊召喚","効果"],ma:"",tp:"",at:300,de:2100,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「オーシャンズ・オーパー」１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、相手は手札を公開し続けなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"マザー・ブレイン",h:"まざー・ぶれいん",e:"Cranium Fish",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードは自分フィールド上に存在する「オーシャンズ・オーパー」１体をリリースし、手札から特殊召喚する事ができる。\n手札から水属性モンスター１体を墓地に捨てる事で、フィールド上にセットされたカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"深海王デビルシャーク",h:"しんかいおうでびるしゃーく",e:"Abyssal Kingshark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"このカードは１ターンに１度だけ、対象を指定しないカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"モーム",h:"もーむ",e:"Mormolith",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:900,tx:"自分フィールド上に表側表示で存在する地属性モンスター１体をリリースして発動する。\nフィールド上に表側表示で存在し、リリースしたモンスターの攻撃力以下の守備力を持つモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マンモ・フォッシル",h:"まんも・ふぉっしる",e:"Fossil Tusker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜影魚レイ・ブロント",h:"りゅうえいぎょれい・ぶろんと",e:"Phantom Dragonray Bronto",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの元々の攻撃力は２３００になる。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄の門番イル・ブラッド",h:"じごくのもんばんいる・ぶらっど",e:"Il Blud",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["デュアル","効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、手札・自分または相手の墓地に存在するアンデット族モンスター１体を特殊召喚する事ができる。\nこのカードがフィールド上から離れた時、この効果で特殊召喚したアンデット族モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎妖蝶ウィルプス",h:"えんようちょううぃるぷす",e:"Blazewing Butterfly",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"昆虫族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードをリリースし、「炎妖蝶ウィルプス」以外の自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはもう１度召喚された状態として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌幻魔アーミタイル",h:"こんとんげんまあーみたいる",e:"Armityle the Chaos Phantasm",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「神炎皇ウリア」＋「降雷皇ハモン」＋「幻魔皇ラビエル」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードの攻撃力は自分ターンの間１００００アップする。\n②：このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"青氷の白夜龍",h:"ぶるーあいす・ほわいとないつ・どらごん",e:"White Night Dragon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"①：このカードを対象とする魔法・罠カードが発動した時に発動する。\nその発動を無効にし破壊する。\n②：このカード以外の自分の表側表示モンスターが攻撃対象に選択された時、自分フィールドの魔法・罠カード１枚を墓地へ送って発動できる。\n攻撃対象をこのカードに移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースEX－THE LIGHT RULER",h:"あるかなふぉーすえくすとら－ざ・らいと・るーらー",e:"Arcana Force EX - The Light Ruler",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスター３体を墓地へ送った場合のみ特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：相手モンスターを戦闘によって破壊し墓地へ送った時、自分の墓地からカード１枚を選択して手札に加える事ができる。\n●裏：このカードを対象にする効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。\nこの効果でカードの発動を無効にする度に、このカードの攻撃力は１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"カードブロッカー",h:"かーどぶろっかー",e:"Card Blocker",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"このカードは召喚・反転召喚・特殊召喚に成功した時、守備表示になる。\n自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時、このカードに攻撃対象を変更する事ができる。\nこのカードが攻撃対象になった時、自分のデッキのカードを上から３枚まで墓地へ送る事ができる。\n墓地へ送ったカード１枚につき、このカードの守備力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"神獣王バルバロス",h:"しんじゅうおうばるばろす",e:"Beast King Barbaros",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:1200,tx:"①：このカードはリリースなしで通常召喚できる。\n②：このカードの①の方法で通常召喚したこのカードの元々の攻撃力は１９００になる。\n③：このカードはモンスター３体をリリースして召喚する事もできる。\n④：このカードがこのカードの③の方法で召喚に成功した場合に発動する。\n相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"The splendid VENUS",h:"ざ・すぷれんでぃっど・う゛ぃーなす",e:"Splendid Venus",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"①：このカードがモンスターゾーンに存在する限り、天使族以外のフィールドのモンスターの攻撃力・守備力は５００ダウンし、自分フィールドの魔法・罠カードの効果の発動及びその発動した効果は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マッド・リローダー",h:"まっど・りろーだー",e:"Mad Reloader",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\n手札を２枚選んで墓地へ送り、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔宮の賄賂",h:"まきゅうのわいろ",e:"Dark Bribe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n相手はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・バースト",h:"かおす・ばーすと",e:"Chaos Burst",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に自分フィールドのモンスター１体をリリースし、その攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターを破壊する。\nその後、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファントム・オブ・カオス",h:"ふぁんとむ・おぶ・かおす",e:"Phantom of Chaos",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、自分の墓地の効果モンスター１体を対象として発動できる。\nそのモンスターを除外し、このカードはエンドフェイズまで、そのモンスターと同名カードとして扱い、同じ元々の攻撃力と効果を得る。\n②：このカードの戦闘で発生する相手への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使ナース－レフィキュル",h:"だてんしなーす－れふぃきゅる",e:"Darklord Nurse Reficule",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"このカードがフィールド上に表側表示で存在する限り、相手のライフポイントが回復する効果は、相手のライフポイントにダメージを与える効果になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・キュア",h:"だーく・きゅあ",e:"Dark Cure",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手がモンスターの召喚・反転召喚・特殊召喚に成功した時、そのモンスターの攻撃力の半分の数値分だけ相手のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"サディスティック・ポーション",h:"さでぃすてぃっく・ぽーしょん",e:"Brutal Potion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に存在するモンスター１体に装備する。\nこのカードのコントローラーがカードの効果によって相手プレイヤーにダメージを与えた場合、そのターンのエンドフェイズ時まで装備モンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"もののけの巣くう祠",h:"もののけのすくうほこら",e:"Haunted Shrine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドにモンスターが存在しない場合、自分の墓地のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドにモンスターが存在しない場合、墓地のこのカードを除外し、自分の墓地のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"酒呑童子",h:"しゅてんどうじ",e:"Shutendoji",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n\n●自分の墓地からアンデット族モンスター２体を除外して発動できる。\n自分はデッキから１枚ドローする。\n\n●除外されている自分のアンデット族モンスター１体を対象として発動できる。\nそのモンスターをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"馬頭鬼",h:"めずき",e:"Mezuki",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"①：自分メインフェイズに墓地のこのカードを除外し、自分の墓地のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"九尾の狐",h:"きゅうびのきつね",e:"Nine-Tailed Fox",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドのモンスター２体をリリースして発動できる。\nこのカードを特殊召喚する。\n②：墓地から特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n自分フィールドに「狐トークン」（アンデット族・炎・星２・攻／守５００）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣アンダル",h:"ぐらでぃあるびーすとあんだる",e:"Gladiator Beast Andal",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:1500,tx:"高い攻撃力で敵を追いつめる、隻眼の戦闘グマ。\n恐るべきスピードと重さを誇る自慢のパンチを受けて倒れぬ者はいない。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇の長槍兵",h:"かいおうのながやりへい",e:"Atlantean Pikeman",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1400,de:0,tx:"海底を支配していると言われる、海皇に仕える長槍兵。\n深く暗い海の底から襲いかかる長槍の連続攻撃は、深海魚たちに恐れられている。",li:"",lk:[],ta:[],al:[]},
  {n:"ガイアフレーム",h:"がいあふれーむ",e:"Earth Effigy",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"地属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィンドフレーム",h:"うぃんどふれーむ",e:"Wind Effigy",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"風属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアル・ランサー",h:"でゅある・らんさー",e:"Gemini Lancer",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"プリズンクインデーモン",h:"ぷりずんくいんでーもん",e:"Imprisoned Queen Archfiend",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:1700,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に１０００ライフポイントを払う。\nフィールド上に「万魔殿－悪魔の巣窟－」が存在し、このカードが墓地に存在する場合、自分のスタンバイフェイズ毎にフィールド上に存在するレベル４以下の悪魔族モンスター１体の攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークエンド・ドラゴン",h:"だーくえんど・どらごん",e:"Dark End Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"チューナー＋チューナー以外の闇属性モンスター１体以上\n１ターンに１度、このカードの攻撃力・守備力を５００ポイントダウンし、相手フィールド上に存在するモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトエンド・ドラゴン",h:"らいとえんど・どらごん",e:"Light End Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\nこのカードが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nこのカードの攻撃力・守備力を５００ポイントダウンし、戦闘を行う相手モンスターの攻撃力・守備力はエンドフェイズ時まで１５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"Chimaera, the Master of Beasts",h:"Chimaera, the Master of Beasts",e:"Chimaera, the Master of Beasts",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by Tributing 3 Beast-Type monsters on your side of the field. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"Emperor of Lightning",h:"Emperor of Lightning",e:"Emperor of Lightning",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2600,de:2600,tx:"This card cannot be Special Summoned. This card must be Tribute Summoned by Tributing 3 Thunder-Type monsters on your side of the field. If this card attacks your opponent's Life Points directly and makes them 0, the controller of this card wins the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO ヘル・ブラット",h:"いーびるひーろー　へる・ぶらっと",e:"Evil HERO Infernal Prodigy",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から攻撃表示で特殊召喚できる。\n②：このカードをリリースして「HERO」モンスターがアドバンス召喚に成功したターンのエンドフェイズに発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマー・ブレイカー",h:"あーまー・ぶれいかー",e:"Armor Breaker",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["ユニオン","効果"],ma:"",tp:"",at:800,de:800,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分フィールド上の戦士族モンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが相手ライフに戦闘ダメージを与えた時、フィールド上に存在するカード１枚を破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO ワイルド・サイクロン",h:"いーびるひーろー　わいるど・さいくろん",e:"Evil HERO Wild Cyclone",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:2300,tx:"「E・HERO ワイルドマン」＋「E・HERO フェザーマン」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手フィールドにセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO ヘル・スナイパー",h:"いーびるひーろー　へる・すないぱー",e:"Evil HERO Infernal Sniper",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2500,tx:"「E・HERO クレイマン」＋「E・HERO バーストレディ」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードはモンスターゾーンに存在する限り、魔法カードの効果では破壊されない。\n②：自分スタンバイフェイズに発動する。\n相手に１０００ダメージを与える。\nこの効果はこのカードがモンスターゾーンに表側守備表示で存在する場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO マリシャス・デビル",h:"いーびるひーろー　まりしゃす・でびる",e:"Evil HERO Malicious Fiend",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:2100,tx:"「E-HERO マリシャス・エッジ」＋レベル６以上の悪魔族モンスター\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、相手バトルフェイズの間、相手フィールドの全てのモンスターは、表側攻撃表示になり、攻撃可能な場合はこのカードを攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・コーリング",h:"だーく・こーりんぐ",e:"Dark Calling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・墓地から、融合モンスターカードによって決められた融合素材モンスターを除外し、「ダーク・フュージョン」の効果でのみ特殊召喚できるその融合モンスター１体を「ダーク・フュージョン」による融合召喚扱いとしてEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"融合破棄",h:"ゆうごうはき",e:"Revoke Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「融合」魔法カード１枚を手札から墓地へ捨てて発動する。\n融合デッキに存在する融合モンスター１体を墓地に送り、その融合モンスターに記されている融合素材モンスター１体を手札から特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンのエンドフェイズ時に墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーローズルール２",h:"ひーろーずるーる２",e:"Hero's Rule 2",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"墓地のカードを対象とする効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻銃士",h:"げんじゅうし",e:"Phantom Skyblaster",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:800,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\n自分フィールドのモンスターの数まで、自分フィールドに「銃士トークン」（悪魔族・闇・星４・攻／守５００）を特殊召喚する。\n②：自分スタンバイフェイズに発動できる。\n自分フィールドの「銃士」モンスターの数×３００ダメージを相手に与える。\nこの効果を発動するターン、自分の「銃士」モンスターは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイブ・スクワーマー",h:"ぐれいぶ・すくわーまー",e:"Grave Squirmer",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トーチ・ゴーレム",h:"とーち・ごーれむ",e:"Grinder Golem",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:300,tx:"このカードは通常召喚できない。\n自分フィールドに「トーチトークン」（悪魔族・闇・星１・攻／守０）２体を攻撃表示で特殊召喚する事によって相手フィールドに特殊召喚できる。\nこのカードを特殊召喚するターン、自分は通常召喚できない。",li:0,lk:[],ta:[],al:[]},
  {n:"カウンター・ジェム",h:"かうんたー・じぇむ",e:"Counter Gem",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の魔法＆罠カードゾーンに存在するカードを全て墓地に送る。\n自分の墓地に存在する「宝玉獣」と名のついたモンスターを可能な限り永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く。\nこのターンのエンドフェイズ時に自分フィールド上に表側表示で存在する「宝玉獣」と名のついたカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の双璧",h:"ほうぎょくのそうへき",e:"Crystal Pair",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「宝玉獣」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\nデッキから「宝玉獣」と名のついたモンスター１体を永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く。\nこのターン、自分が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"手札断殺",h:"てふだだんさつ",e:"Hand Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは手札を２枚墓地へ送る。\nその後、それぞれデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の解放",h:"ほうぎょくのかいほう",e:"Crystal Release",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「宝玉獣」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は８００アップする。\n②：このカードがフィールドから墓地へ送られた時に発動できる。\nデッキから「宝玉獣」モンスター１体を永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の樹",h:"ほうぎょくのき",e:"Crystal Tree",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、「宝玉獣」モンスターが魔法＆罠ゾーンに置かれる度に、このカードにジェムカウンターを１つ置く。\n②：ジェムカウンターが置かれているこのカードを墓地へ送って発動できる。\nこのカードに置かれていたジェムカウンターの数だけ、デッキから「宝玉獣」モンスターを永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"究極封印神エクゾディオス",h:"きゅうきょくふういんしんえくぞでぃおす",e:"Exodius the Ultimate Forbidden Lord",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\n自分の墓地のモンスターを全てデッキに戻した場合のみ特殊召喚できる。\nこのカードの②の効果で「封印されし」モンスター５種類が自分の墓地へ送られ全て揃った時、自分はデュエルに勝利する。\n①：このカードの攻撃力は、自分の墓地の通常モンスターの数×１０００アップする。\n②：このカードの攻撃宣言時に発動する。\n手札・デッキからモンスター１体を墓地へ送る。\n③：表側表示のこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ライオウ",h:"らいおう",e:"Thunder King Rai-Oh",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1900,de:800,tx:"このカードが自分フィールド上に表側表示で存在する限り、お互いにドロー以外の方法でデッキからカードを手札に加える事はできない。\nまた、自分フィールド上に表側表示で存在するこのカードを墓地へ送る事で、相手モンスター１体の特殊召喚を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ディープ・ダイバー",h:"でぃーぷ・だいばー",e:"Deep Diver",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られたバトルフェイズ終了時、デッキからモンスター１体を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"強者の苦痛",h:"きょうしゃのくつう",e:"Burden of the Mighty",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：相手フィールドのモンスターの攻撃力は、そのモンスターのレベル×１００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"次元幽閉",h:"じげんゆうへい",e:"Dimensional Prison",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時、攻撃モンスター１体を選択して発動できる。\n選択した攻撃モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"エア・サーキュレーター",h:"えあ・さーきゅれーたー",e:"Vortex Trooper",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:600,tx:"①：このカードが召喚に成功した時に発動できる。\n手札を２枚デッキに戻してシャッフルする。\nその後、自分はデッキから２枚ドローする。\n②：このカードが破壊された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"キメラテック・フォートレス・ドラゴン",h:"きめらてっく・ふぉーとれす・どらごん",e:"Chimeratech Fortress Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「サイバー・ドラゴン」＋機械族モンスター１体以上\n自分・相手フィールドの上記カードを墓地へ送った場合のみ、エクストラデッキから特殊召喚できる（「融合」は必要としない）。\nこのカードは融合素材にできない。\n①：このカードの元々の攻撃力は、このカードの融合素材としたモンスターの数×１０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"霧の王",h:"きんぐみすと",e:"Fog King",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードを召喚する場合、生け贄１体または生け贄なしで召喚する事ができる。\nこのカードの攻撃力は、生け贄召喚時に生け贄に捧げたモンスターの元々の攻撃力を合計した数値になる。\nこのカードがフィールド上に表側表示で存在する限り、いかなる場合による生け贄も行う事ができなくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォッシル・ダイナ パキケファロ",h:"ふぉっしる・だいな　ぱきけふぁろ",e:"Fossil Dyna Pachycephalo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:1300,tx:"①：このカードがリバースした場合に発動する。\nフィールドの特殊召喚されたモンスターを全て破壊する。\n②：このカードがモンスターゾーンに存在する限り、お互いにモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"究極宝玉神 レインボー・ダーク・ドラゴン",h:"きゅうきょくほうぎょくしん　れいんぼー・だーく・どらごん",e:"Rainbow Dark Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n自分の墓地から闇属性モンスター７種類を１体ずつ除外した場合のみ特殊召喚できる。\n①：自分の墓地及び自分フィールドの表側表示モンスターの中から、このカード以外の闇属性モンスターを全て除外して発動できる。\nこのカードの攻撃力は除外した数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サクリファイス・ロータス",h:"さくりふぁいす・ろーたす",e:"Samsara Lotus",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分のエンドフェイズ時にこのカードが墓地に存在し、自分フィールド上に魔法・罠カードが存在しない場合、自分フィールド上に表側攻撃表示で特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する場合、自分のスタンバイフェイズ毎にこのカードのコントローラーは１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリット・D・ローズ",h:"すぷりっと・でもん・ろーず",e:"Regenerating Rose",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上に「D・ローズトークン」（植物族・闇・星３・攻／守１２００）を２体特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ユベル",h:"ゆべる",e:"Yubel",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは戦闘では破壊されず、このカードの戦闘で自分は戦闘ダメージを受けない。\n②：攻撃表示のこのカードが攻撃対象に選択された場合、そのダメージ計算前に発動する。\nその攻撃モンスターの攻撃力分のダメージを相手に与える。\n③：自分エンドフェイズに発動する。\n自分フィールドの他のモンスター１体をリリースするか、このカードを破壊する。\n④：この③の効果以外でこのカードが破壊された時に発動できる。\n自分の手札・デッキ・墓地から「ユベル－Das Abscheulich Ritter」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ユベル－Das Abscheulich Ritter",h:"ゆべる－だす・あぷしぇりっひ・りったー",e:"Yubel - Terror Incarnate",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n「ユベル」の効果でのみ特殊召喚できる。\nこのカードは戦闘では破壊されず、このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nフィールド上に表側攻撃表示で存在するこのカードが相手モンスターに攻撃された場合、そのダメージ計算前に攻撃モンスターの攻撃力分のダメージを相手ライフに与える。\nまた、自分のエンドフェイズ時、このカード以外のフィールド上のモンスターを全て破壊する。\nこのカードがフィールド上から離れた時、自分の手札・デッキ・墓地から「ユベル－Das Extremer Traurig Drachen」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ユベル－Das Extremer Traurig Drachen",h:"ゆべる－だす・えくすとれーむ・とらうりひ・どらっへ",e:"Yubel - The Ultimate Nightmare",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n「ユベル－Das Abscheulich Ritter」の効果でのみ特殊召喚できる。\nこのカードは戦闘では破壊されず、このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nフィールド上に表側攻撃表示で存在するこのカードが相手モンスターと戦闘を行ったダメージステップ終了時、相手モンスターの攻撃力分のダメージを相手ライフに与え、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマード・サイバーン",h:"あーまーど・さいばーん",e:"Armored Cybern",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの、「サイバー・ドラゴン」を素材とする融合モンスターまたは「サイバー・ドラゴン」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\n装備モンスターの攻撃力を１０００ダウンし、対象の表側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ヴァリー",h:"さいばー・う゛ぁりー",e:"Cyber Valley",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：以下の効果から１つを選択して発動できる。\n\n●このカードが攻撃対象に選択された時、このカードを除外して発動できる。\n自分はデッキから１枚ドローし、その後バトルフェイズを終了する。\n\n●自分フィールドの表側表示モンスター１体とこのカードを対象として発動できる。\nその自分の表側表示モンスターとこのカードを除外し、その後自分はデッキから２枚ドローする。\n\n●自分の墓地のカード１枚を対象として発動できる。\nフィールドのこのカードと手札１枚を除外し、その後対象のカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ウロボロス",h:"さいばー・うろぼろす",e:"Cyber Ouroboros",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"このカードがゲームから除外された時、手札のカード１枚を墓地に送る事で、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・カウンター",h:"う゛ぉるかにっく・かうんたー",e:"Volcanic Counter",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:300,de:1300,tx:"このカードが墓地に存在し、自分が戦闘ダメージを受けた時に発動する。\n墓地のこのカードをゲームから除外し、自分の墓地に「ヴォルカニック・カウンター」以外の炎属性モンスターが存在する場合、自分が受けた戦闘ダメージと同じ数値のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダンクガイ",h:"ですてにーひーろー　だんくがい",e:"Destiny HERO - Dunker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1700,tx:"手札から「D-HERO」と名のついたカード１枚を墓地に送る事で、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディパーテッドガイ",h:"ですてにーひーろー　でぃぱーてっどがい",e:"Destiny HERO - Departed",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"自分のスタンバイフェイズ時にこのカードが墓地に存在する場合、相手フィールド上に表側攻撃表示で特殊召喚される。\nこのカードが戦闘で破壊された場合、墓地へは行かずゲームから除外される。\nこのカードが手札またはデッキからカードの効果によって墓地へ送られる場合、墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ホルス・ドラゴン",h:"だーく・ほるす・どらごん",e:"Dark Horus",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、相手のメインフェイズ時に魔法カードが発動した場合、自分の墓地のレベル４の闇属性モンスター１体を選択して特殊召喚できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・クリエイター",h:"だーく・くりえいたー",e:"The Dark Creator",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:3000,tx:"このカードは通常召喚できない。\n自分の墓地に闇属性モンスターが５体以上存在し、自分フィールド上にモンスターが存在しない場合に特殊召喚できる。\n１ターンに１度、自分の墓地の闇属性モンスター１体をゲームから除外する事で、自分の墓地の闇属性モンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ネフティス",h:"だーく・ねふてぃす",e:"Dark Nephthys",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"自分のメインフェイズ時に自分の墓地の闇属性モンスターが３体以上の場合、その内２体をゲームから除外する事で、このカードを手札から墓地へ送る。\n次の自分のスタンバイフェイズ時、この効果で墓地へ送られたこのカードを墓地から特殊召喚する。\nまた、このカードが特殊召喚に成功した時、フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・アームド・ドラゴン",h:"だーく・あーむど・どらごん",e:"Dark Armed Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地の闇属性モンスターが３体の場合のみ特殊召喚できる。\n①：自分の墓地から闇属性モンスター１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・クルセイダー",h:"だーく・くるせいだー",e:"Dark Crusader",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"手札から闇属性モンスター１体を墓地へ送って発動できる。\nこのカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"終末の騎士",h:"しゅうまつのきし",e:"Armageddon Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nデッキから闇属性モンスター１体を墓地へ送る。",li:1,lk:[],ta:[],al:[]},
  {n:"終焉の精霊",h:"じ・えんど・すぴりっつ",e:"Doomsday Horror",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、ゲームから除外されている闇属性モンスターの数×３００ポイントになる。\nこのカードが破壊され墓地へ送られた時、ゲームから除外されている闇属性モンスターを全て墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"黒曜岩竜",h:"こくようがんりゅう",e:"Obsidian Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:2100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の闇属性モンスター１体を対象にする魔法・罠カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"プリーステス・オーム",h:"ぷりーすてす・おーむ",e:"Shadowpriestess of Ohm",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"自分フィールド上に表側表示で存在する闇属性モンスター１体を生け贄に捧げる事で、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガプラント",h:"ぎがぷらんと",e:"Gigaplant",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["デュアル","効果"],ma:"",tp:"",at:2400,de:1200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から昆虫族または植物族のモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"未来サムライ",h:"みらいさむらい",e:"Future Samurai",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分の墓地に存在するモンスター１体をゲームから除外する事で、フィールド上に表側表示で存在するモンスター１体を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"悪シノビ",h:"おしのび",e:"Vengeful Shinobi",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"フィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"不死武士",h:"ふしぶし",e:"The Immortal Bushi",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"自分のスタンバイフェイズ時にこのカードが墓地に存在し、自分フィールド上にモンスターが存在しない場合、このカードを墓地から特殊召喚できる。\nこの効果は自分の墓地に戦士族以外のモンスターが存在する場合には発動できない。\nこのカードは戦士族モンスターのアドバンス召喚以外のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"戦士ラーズ",h:"せんしらーず",e:"Field-Commander Rahz",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが召喚・特殊召喚に成功した時、自分のデッキから「戦士ラーズ」以外のレベル４以下の戦士族モンスター１体を選択し、デッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ダリウス",h:"ぐらでぃあるびーすとだりうす",e:"Gladiator Beast Darius",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した時、自分の墓地の「剣闘獣」モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは持ち主のデッキに戻る。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣ダリウス」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒ヴェロキ",h:"ぶらっくう゛ぇろき",e:"Black Veloci",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が４００ポイントアップする。\nこのカードは相手モンスターに攻撃された場合、ダメージステップの間攻撃力が４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"超古深海王シーラカンス",h:"ちょうこしんかいおうしーらかんす",e:"Superancient Deepsea King Coelacanth",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"①：１ターンに１度、手札を１枚捨てて発動できる。\nデッキからレベル４以下の魚族モンスターを可能な限り特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃宣言できず、効果は無効化される。\n②：フィールドのこのカードを対象とする魔法・罠・モンスターの効果が発動した時、このカード以外の自分フィールドの魚族モンスター１体をリリースして発動できる。\nその効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガキャノン・ソルジャー",h:"めがきゃのん・そるじゃー",e:"Cannon Soldier MK-2",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"自分フィールド上に存在するモンスター２体を生け贄に捧げる度に、相手ライフに１５００ポイントダメージを与える。",li:0,lk:[],ta:[],al:[]},
  {n:"ザ・カリキュレーター",h:"ざ・かりきゅれーたー",e:"The Calculator",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"①：このカードの攻撃力は、自分フィールドのモンスターのレベルの合計×３００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"コアラッコ",h:"こあらっこ",e:"Sea Koala",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:1600,tx:"このカード以外の獣族モンスターが自分フィールド上に存在する場合、相手フィールド上のモンスター１体を選択し、エンドフェイズ時まで攻撃力を０にできる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーサンダーT４５",h:"ぶるーさんだーＴ４５",e:"Blue Thunder T-45",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、自分フィールド上に「サンダーオプショントークン」（機械族・光・星４・攻／守１５００）１体を特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"電磁蚊",h:"もすきーと・まぐね",e:"Magnetic Mosquito",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1000,tx:"リバース：フィールド上に表側表示で存在する機械族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"N・ティンクル・モス",h:"ねおすぺーしあん・てぃんくる・もす",e:"Neo-Spacian Twinkle Moss",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"植物族",mo:["融合","効果"],ma:"",tp:"",at:500,de:1100,tx:"このカード名はルール上「N・グロー・モス」としても扱う。\nこのカードは「NEX」の効果でのみ特殊召喚できる。\nこのカードが戦闘を行う場合、自分はカードを１枚ドローする。\nこの効果でドローしたカードをお互いに確認し、そのカードの種類によりこのカードは以下の効果を得る。\n●モンスターカード：このターンのバトルフェイズを終了させる。\n●魔法カード：このカードは相手プレイヤーに直接攻撃する事ができる。\n●罠カード：このカードは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ストーム・ネオス",h:"えれめんたるひーろー　すとーむ・ねおす",e:"Elemental HERO Storm Neos",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「N・エア・ハミングバード」＋「N・アクア・ドルフィン」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\n１ターンに１度、自分のメインフェイズ時にフィールド上の魔法・罠カードを全て破壊できる。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。\nこの効果によってこのカードがエクストラデッキに戻った時、フィールド上のカードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"レインボー・ネオス",h:"れいんぼー・ねおす",e:"Rainbow Neos",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:3000,tx:"「E・HERO ネオス」＋「究極宝玉神」モンスター\nこのカードは上記のカードを融合素材にした融合召喚でのみ特殊召喚できる。\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドのモンスター１体を墓地へ送って発動できる。\n相手フィールドのモンスターを全て持ち主のデッキに戻す。\n●自分フィールドの魔法・罠カード１枚を墓地へ送って発動できる。\n相手フィールドの魔法・罠カードを全て持ち主のデッキに戻す。\n●デッキの一番上のカードを墓地へ送って発動できる。\n相手の墓地のカードを全てデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"レインボー・ヴェール",h:"れいんぼー・う゛ぇーる",e:"Rainbow Veil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"超融合",h:"ちょうゆうごう",e:"Super Polymerization",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動に対して魔法・罠・モンスターの効果は発動できない。\n①：手札を１枚捨てて発動できる。\n自分・相手フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:2,lk:[],ta:[],al:[]},
  {n:"ヴィシャス・クロー",h:"う゛ぃしゃす・くろー",e:"Vicious Claw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は３００ポイントアップする。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを手札に戻す。\nさらに戦闘を行った相手モンスター以外のモンスター１体を破壊し、相手ライフに６００ポイントダメージを与える。\nその後、相手フィールド上に「イービル・トークン」（悪魔族・闇・星７・攻／守２５００）を１体特殊召喚する。\nこのカードが手札に戻ったターンは「ヴィシャス・クロー」を手札から使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"インスタント・ネオスペース",h:"いんすたんと・ねおすぺーす",e:"Instant Neo Space",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO ネオス」を融合素材とする融合モンスターにのみ装備可能。\n装備モンスターはエンドフェイズ時にエクストラデッキに戻る効果を発動しなくてもよい。\n装備モンスターがフィールド上から離れた場合、自分の手札・デッキ・墓地から「E・HERO ネオス」１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"蜃気楼の筒",h:"みらーじゅしりんだー",e:"Mirage Tube",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードは手札から発動する事はできない。\n自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時に発動する事ができる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・クロニクル",h:"まじっく・くろにくる",e:"Spell Chronicle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：手札を全て墓地へ送ってこのカードを発動できる。\nデッキから魔法・罠カード５枚を除外する。\n②：相手が魔法カードを発動する度に、このカードにクロニクルカウンターを１つ置く。\n③：このカードのクロニクルカウンターを２つ取り除いて発動できる。\n相手はこのカードの効果で除外したカードの中から１枚を選ぶ。\n自分は相手が選んだカードを手札に加える。\n④：魔法＆罠ゾーンの表側表示のこのカードがフィールドから離れた時、自分はこのカードの効果で除外されているカードの数×５００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"次元誘爆",h:"じげんゆうばく",e:"Dimension Explosion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する融合モンスター１体を融合デッキに戻す事で発動する事ができる。\nお互いにゲームから除外されているモンスターを２体まで選択し、それぞれのフィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・ゾーン",h:"さいばねてぃっく・ぞーん",e:"Cybernetic Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する機械族の融合モンスター１体を選択し、発動ターンのエンドフェイズ時までゲームから除外する。\nゲームから除外されたモンスターがフィールド上に戻った時、そのモンスターの攻撃力は倍になる。\n次の自分のターンのスタンバイフェイズ時に、このカードの対象となった機械族の融合モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"終わりの始まり",h:"おわりのはじまり",e:"The Beginning of the End",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地に闇属性モンスターが７体以上存在する場合、その内の５体を除外して発動できる。\n自分はデッキから３枚ドローする。",li:1,lk:[],ta:[],al:[]},
  {n:"ダーク・バースト",h:"だーく・ばーすと",e:"Dark Eruption",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の攻撃力１５００以下の闇属性モンスター１体を対象として発動できる。\nその闇属性モンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉の焔",h:"しゅうえんのほのお",e:"Fires of Doomsday",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・反転召喚・特殊召喚できない。\n①：自分フィールドに「黒焔トークン」（悪魔族・闇・星１・攻／守０）２体を守備表示で特殊召喚する。\nこのトークンは闇属性以外のモンスターのアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォース・リリース",h:"ふぉーす・りりーす",e:"Unleash Your Power!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動時に自分フィールド上に表側表示で存在する全てのデュアルモンスターは再度召喚した状態になる。\nこの効果を適用したモンスターはエンドフェイズ時に裏側守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サモンチェーン",h:"さもんちぇーん",e:"Chain Summoning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：同一チェーン上で複数回同名カードの効果が発動していない場合、そのチェーン３以降に発動できる。\nこのターン自分は通常召喚を３回まで行う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アシッドレイン",h:"あしっどれいん",e:"Acidic Downpour",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての地属性モンスターの攻撃力は５００ポイントダウンし、守備力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の結束",h:"ろくぶしゅうのけっそく",e:"Six Samurai United",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「六武衆」モンスターが召喚・特殊召喚される度にこのカードに武士道カウンターを１つ置く（最大２つまで）。\n②：武士道カウンターが置かれているこのカードを墓地へ送って発動できる。\nこのカードに置かれていた武士道カウンターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣の闘器デーモンズシールド",h:"ぐらでぃあるびーすとのとうきでーもんずしーるど",e:"Gladiator Beast's Battle Archfiend Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「剣闘獣」と名のついたモンスターにのみ装備可能。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n装備モンスターが自分フィールド上からデッキに戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘訓練所",h:"ぐらでぃあるとれーなー",e:"Gladiator Proving Ground",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキからレベル４以下の「剣闘獣」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の書物",h:"あんこくかいのしょもつ",e:"Dark World Grimoire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のエンドフェイズ時に手札枚数制限によって手札を墓地に捨てた場合、その中にモンスターカードが含まれていれば１体のみ自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"虹の行方",h:"にじのゆくえ",e:"Rainbow Path",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に、自分の魔法＆罠カードゾーンに存在する「宝玉獣」と名のついたカード１枚を選択して墓地へ送り発動する。\n相手モンスター１体の攻撃を無効にし、自分のデッキから「究極宝玉神」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レインボー・ライフ",h:"れいんぼー・らいふ",e:"Rainbow Life",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てて発動できる。\nこのターンのエンドフェイズ時まで、自分は戦闘及びカードの効果によってダメージを受ける代わりに、その数値分だけライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"デモンバルサム・シード",h:"でもんばるさむ・しーど",e:"Sinister Seeds",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスターが戦闘によって破壊された時に発動する事ができる。\nその戦闘によって自分が受けた戦闘ダメージ５００ポイントにつき、自分フィールド上に「デモンバルサムトークン」（植物族・闇・星１・攻／守１００）を１体特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘイト・バスター",h:"へいと・ばすたー",e:"Hate Buster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の悪魔族モンスターが攻撃対象に選択された時、攻撃モンスター１体とその攻撃対象モンスター１体を対象として発動できる。\nその攻撃モンスターとその攻撃対象モンスターの２体を破壊し、破壊した攻撃モンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"チェーン・マテリアル",h:"ちぇーん・まてりある",e:"Chain Material",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードの発動ターンに自分が融合召喚をする場合、融合モンスターカードによって決められた融合素材モンスターを自分の手札・デッキ・フィールド上・墓地から選んでゲームから除外し、これらを融合素材にできる。\nこのカードを発動するターン、自分は攻撃する事ができず、この効果で融合召喚したモンスターはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"アルケミー・サイクル",h:"あるけみー・さいくる",e:"Alchemy Cycle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動ターンのエンドフェイズ時まで、自分フィールド上に表側表示で存在するモンスター全ての元々の攻撃力を０にする。\nこの効果によって元々の攻撃力が０になっているモンスターが戦闘によって破壊され墓地へ送られる度に、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・ヒドゥン・テクノロジー",h:"さいばねてぃっく・ひどぅん・てくのろじー",e:"Cybernetic Hidden Technology",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが攻撃宣言を行う度に、自分フィールド上の「サイバー・ドラゴン」または融合素材として「サイバー・ドラゴン」が記されている融合モンスター１体を墓地へ送って発動できる。\nその攻撃モンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇霊術－「欲」",h:"やみれいじゅつ－「よく」",e:"Dark Spirit Art - Greed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の闇属性モンスター１体をリリースして発動できる。\n相手は手札から魔法カード１枚を見せてこのカードの効果を無効にできる。\n見せなかった場合、自分はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の幻影",h:"やみのげんえい",e:"Dark Illusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上に表側表示で存在する闇属性モンスターを対象にする効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇次元の解放",h:"やみじげんのかいほう",e:"Escape from the Dark Dimension",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：除外されている自分の闇属性モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊され除外される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"二重の落とし穴",h:"でゅある・ほーる",e:"Gemini Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：もう１度召喚された状態のデュアルモンスターが戦闘で破壊された時に発動できる。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"強烈なはたき落とし",h:"きょうれつなはたきおとし",e:"Drastic Drop Off",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手がデッキからカードを手札に加えた時に発動できる。\n相手は手札に加えたそのカード１枚を捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚制限－猛突するモンスター",h:"しょうかんせいげん－もうとつするもんすたー",e:"All-Out Attacks",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する場合にモンスターが特殊召喚に成功した時、そのモンスターを表側攻撃表示にする。\nそのターンそのモンスターが攻撃可能な場合には攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンディキャップマッチ！",h:"はんでぃきゃっぷまっち！",e:"Double Tag Team",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が「剣闘獣」と名のついたモンスターの特殊召喚に成功した時に発動する事ができる。\n自分の手札またはデッキから「剣闘獣」と名のついたレベル４以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"毒蛇の供物",h:"どくじゃのくもつ",e:"Offering to the Snake Deity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの爬虫類族モンスター１体と、相手フィールドのカード２枚を対象として発動できる。\nその自分の爬虫類族モンスター１体と、その相手のカード２枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"決戦の火蓋",h:"けっせんのひぶた",e:"Cry Havoc!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地のモンスターカード１枚をゲームから除外する事で、手札から通常モンスター１体を通常召喚する事ができる。\nこの効果は自分ターンのメインフェイズ時にのみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"転生断絶",h:"てんせいだんぜつ",e:"Transmigration Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、墓地からデッキに戻るカードはデッキに戻らずゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"罰則金",h:"ばっそくきん",e:"Fine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分は手札を２枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣オクタビウス",h:"ぐらでぃあるびーすとおくたびうす",e:"Gladiator Beast Octavius",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、魔法＆罠カードゾーンにセットされたカード１枚を破壊する。\nこのカードが戦闘を行った自分のバトルフェイズ終了時に、手札を１枚捨てるかこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣トラケス",h:"ぐらでぃあるびーすととらけす",e:"Gladiator Beast Torax",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"スレイブタイガー",h:"すれいぶたいがー",e:"Test Tiger",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:300,tx:"①：自分フィールドに「剣闘獣」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードをリリースし、自分フィールドの「剣闘獣」モンスター１体を対象として発動できる。\nその自分の「剣闘獣」モンスターを持ち主のデッキに戻し、デッキから「剣闘獣」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、「剣闘獣」モンスターの効果で特殊召喚した扱いとなる。",li:"",lk:[],ta:[],al:[]},
  {n:"ディフェンシブ・タクティクス",h:"でぃふぇんしぶ・たくてぃくす",e:"Defensive Tactics",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「剣闘獣」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\nこのターン自分フィールド上に存在するモンスターは戦闘では破壊されず、自分への戦闘ダメージは０になる。\n発動後、このカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・アイス",h:"どらごん・あいす",e:"Dragon Ice",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:2200,tx:"相手がモンスターの特殊召喚に成功した時、自分の手札を１枚捨てる事で、このカードを手札または墓地から特殊召喚する。\n「ドラゴン・アイス」はフィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"タン・ツイスター",h:"たん・ついすたー",e:"Tongue Twister",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"アドバンス召喚したこのカードがフィールド上から墓地へ送られた時、自分のデッキからカードを２枚ドローする。\nこの効果を発動した場合、このカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクリーチ",h:"すくりーち",e:"Skreech",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:400,tx:"このカードが戦闘によって破壊された場合、デッキから水属性モンスター２体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"炎帝近衛兵",h:"えんていこのえへい",e:"Royal Firestorm Guards",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが召喚に成功した時、自分の墓地に存在する炎族モンスター４体を選択して発動する。\n選択したモンスターをデッキに戻し、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒のトバリ",h:"しっこくのとばり",e:"Veil of Darkness",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のドローフェイズにドローしたカードが闇属性モンスターだった場合、そのカードを相手に見せる事で、そのカードを墓地へ送る。\nその後、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"セキュリティー・ボール",h:"せきゅりてぃー・ぼーる",e:"Security Orb",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\nその攻撃モンスター１体の表示形式を変更する。\n相手の魔法・罠カードの効果によって、セットされたこのカードが破壊され墓地へ送られた時、フィールド上に存在するモンスター１体を選択し破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・トルーパー",h:"ふぁいやー・とるーぱー",e:"Fire Trooper",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、このカードを墓地へ送って発動できる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO プリズマー",h:"えれめんたるひーろー　ぷりずまー",e:"Elemental HERO Prisma",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"①：１ターンに１度、エクストラデッキの融合モンスター１体を相手に見せ、そのモンスターにカード名が記されている融合素材モンスター１体をデッキから墓地へ送って発動できる。\nエンドフェイズまで、このカードはこの効果を発動するために墓地へ送ったモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"カードエクスクルーダー",h:"かーどえくすくるーだー",e:"Card Ejector",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"相手の墓地に存在するカード１枚を選択しゲームから除外する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アームズ・ホール",h:"あーむず・ほーる",e:"Hidden Armory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分は通常召喚できない。\n①：デッキの一番上のカードを墓地へ送って発動できる。\n自分のデッキ・墓地から装備魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アサルト・アーマー",h:"あさると・あーまー",e:"Assault Armor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターが戦士族モンスター１体のみの場合、そのモンスターに装備できる。\n①：装備モンスターの攻撃力は３００アップする。\n②：装備されているこのカードを墓地へ送って発動できる。\nこのターン、このカードを装備していたモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"イービル・ブラスト",h:"いーびる・ぶらすと",e:"Evil Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターが特殊召喚された時に発動する事ができる。\n発動後このカードは攻撃力５００ポイントアップの装備カードとなり、そのモンスターに装備する。\n相手のスタンバイフェイズ毎に５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷の女王",h:"こおりのじょおう",e:"Ice Queen",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2900,de:2100,tx:"このカードは墓地からの特殊召喚はできない。\n自分フィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた時、自分の墓地の魔法使い族モンスターが３体以上の場合、自分の墓地から魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル・レジストウォール",h:"れべる・れじすとうぉーる",e:"Level Resist Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターが戦闘または相手の効果で破壊された場合、そのモンスター１体を対象として発動できる。\nレベルの合計がそのモンスターと同じになるように、デッキからモンスターを任意の数だけ選んで守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"弓神レライエ",h:"きゅうしんれらいえ",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nこのカードの攻撃力はターン終了時まで、自分の墓地のモンスターの種族の種類×１００アップする。\n②：このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの守備力はこのカードの攻撃力分ダウンする。\nこの効果でそのモンスターの守備力が０になった場合、さらにそのモンスターを破壊する。\nこの効果の発動後、ターン終了時までこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・目覚めの旋律",h:"どらごん・めざめのせんりつ",e:"The Melody of Awakening Dragon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n攻撃力３０００以上で守備力２５００以下のドラゴン族モンスターを２体までデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"軍神ガープ",h:"ぐんしんがーぷ",e:"Gaap the Divine Soldier",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に存在するモンスターは全て表側攻撃表示となり、表示形式を変更する事ができない。\nこの時、リバース効果モンスターの効果は発動しない。\nまた、１ターンに１度、手札の悪魔族モンスターを任意の枚数見せる事で、このカードの攻撃力はエンドフェイズ時まで、見せた枚数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"邪帝ガイウス",h:"じゃていがいうす",e:"Caius the Shadow Monarch",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドのカード１枚を対象として発動する。\nそのカードを除外し、除外したカードが闇属性モンスターカードだった場合、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"次元合成師",h:"でぃめんしょん・けみすとりー",e:"Dimensional Alchemist",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分のデッキの一番上のカードを除外し、このカードの攻撃力をターン終了時まで５００アップする。\n②：自分フィールドのこのカードが破壊され墓地へ送られた時、除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザー・サクリファイス",h:"かいざー・さくりふぁいす",e:"Samsara Kaiser",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"このカードを生け贄にした生け贄召喚に成功した時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"D・D・R",h:"でぃふぁれんと・でぃめんしょん・りばいばる",e:"D.D.R. - Different Dimension Reincarnation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、除外されている自分のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"エンペラー・オーダー",h:"えんぺらー・おーだー",e:"By Order of the Emperor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：モンスターが召喚に成功した時に発動するモンスターの効果が発動した時、この効果を発動できる。\nその発動を無効にする。\nその後、発動を無効にされたプレイヤーはデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"邪龍アナンタ",h:"じゃりゅうあなんた",e:"Evil Dragon Ananta",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分のフィールド上及び墓地に存在する爬虫類族モンスターを全てゲームから除外する事でのみ特殊召喚する事ができる。\nこのカードの攻撃力・守備力は、特殊召喚時にゲームから除外した爬虫類族モンスターの数×６００ポイントになる。\nこのカードが自分フィールド上に存在する限り、自分ターンのエンドフェイズ時にフィールド上のカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハネクリボー LV９",h:"はねくりぼー　れべる９",e:"Winged Kuriboh LV9",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"チェーンが発生した時、自分の手札からこのカードを特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、お互いに発動した魔法カードは墓地へ送られずゲームから除外する。\nこのカードの攻撃力・守備力は、相手の墓地に存在する魔法カードの数×５００ポイントになる。\n「ハネクリボー LV９」は、自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の番人 ブリズド",h:"ひょうけっかいのばんにん　ぶりずど",e:"Blizzed, Defender of the Ice Barrier",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリザード・ウォリアー",h:"ぶりざーど・うぉりあー",e:"Blizzard Warrior",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手のデッキの一番上のカードを確認してデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の術者",h:"ひょうけっかいのじゅつしゃ",e:"Cryomancer of the Ice Barrier",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:0,tx:"①：自分フィールドに他の「氷結界」モンスターが存在する限り、レベル４以上のモンスターは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の雷鳥",h:"みすと・ばれーのらいちょう",e:"Mist Valley Thunderbird",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1100,de:700,tx:"フィールド上に表側表示で存在するこのカードが手札に戻った時、このカードを自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の祈祷師",h:"みすと・ばれーのきとうし",e:"Mist Valley Shaman",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:1200,tx:"１ターンに１度、このカード以外の自分フィールド上のモンスター１体を手札に戻して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の戦士",h:"みすと・ばれーのせんし",e:"Mist Valley Soldier",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:300,tx:"このカードが自分フィールド上に表側表示で存在する限り、このカードとの戦闘で破壊されなかった相手モンスターをダメージステップ終了時に持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・ドラグノフ",h:"ふれむべる・どらぐのふ",e:"Flamvell Dragnov",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1100,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。\nまた、自分の墓地のこのカードと自分フィールド上に表側表示で存在する炎属性モンスター１体をゲームから除外して発動できる。\nデッキから「フレムベル・ドラグノフ」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・マジカル",h:"ふれむべる・まじかる",e:"Flamvell Magician",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:200,tx:"自分フィールド上に「A・O・J」と名のついたモンスターが存在する限り、このカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガード・オブ・フレムベル",h:"がーど・おぶ・ふれむべる",e:"Flamvell Guard",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:100,de:2000,tx:"炎を自在に操る事ができる、フレムベルの護衛戦士。\n灼熱のバリアを作り出して敵の攻撃を跳ね返す。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー アクセル",h:"えっくす－せいばー　あくせる",e:"X-Saber Axel",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:400,de:100,tx:"このカードが自分フィールド上に表側表示で存在する限り、「セイバー」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー エアベルン",h:"えっくす－せいばー　えあべるん",e:"X-Saber Airbellum",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:200,tx:"①：このカードが直接攻撃で相手に戦闘ダメージを与えた場合に発動する。\n相手の手札をランダムに１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー ウルズ",h:"えっくす－せいばー　うるず",e:"X-Saber Uruz",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードをリリースして発動できる。\n破壊したそのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"総剣司令 ガトムズ",h:"そうけんしれい　がとむず",e:"Commander Gottoms, Swordmaster",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「セイバー」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J クラウソラス",h:"あーりー・おぶ・じゃすてぃす　くらうそらす",e:"Ally of Justice Clausolas",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2300,de:1200,tx:"霞の谷に生息するモンスター、クラウソラスをモチーフに開発された対外敵用戦闘兵器。\n上空からの奇襲で敵を翻弄する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J ガラドホルグ",h:"あーりー・おぶ・じゃすてぃす　がらどほるぐ",e:"Ally of Justice Garadholg",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードは光属性モンスターと戦闘を行う場合、ダメージステップの間、攻撃力が２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J ルドラ",h:"あーりー・おぶ・じゃすてぃす　るどら",e:"Ally of Justice Rudra",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードは光属性モンスターと戦闘を行う場合、ダメージステップの間、攻撃力が７００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・アポカリプス",h:"わーむ・あぽかりぷす",e:"Worm Apocalypse",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:200,tx:"リバース：フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・バルサス",h:"わーむ・ばるさす",e:"Worm Barses",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"このカードが召喚に成功した時、フィールド上に守備表示で存在するモンスター１体を選択して表側攻撃表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・カルタロス",h:"わーむ・かるたろす",e:"Worm Cartaros",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:500,tx:"リバース：デッキからレベル４以下の「ワーム」と名のついた爬虫類族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ディミクレス",h:"わーむ・でぃみくれす",e:"Worm Dimikles",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1700,de:1400,tx:"リバース：このカードの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・イーロキン",h:"わーむ・いーろきん",e:"Worm Erokin",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードは特殊召喚できない。\n１ターンに１度、フィールド上の「ワーム」と名のついた爬虫類族モンスター１体を選択して裏側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の龍 ブリューナク",h:"ひょうけっかいのりゅう　ぶりゅーなく",e:"Brionac, Dragon of the Ice Barrier",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"海竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の効果は１ターンに１度しか使用できない。\n①：手札を任意の枚数墓地へ捨て、捨てた数だけ相手フィールドのカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスト・ウォーム",h:"みすと・うぉーむ",e:"Mist Wurm",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"チューナー＋チューナー以外のモンスター２体以上\n①：このカードがS召喚に成功した場合、相手フィールドのカードを３枚まで対象として発動する。\nその相手のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・ウルキサス",h:"ふれむべる・うるきさす",e:"Flamvell Uruquizas",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードが相手ライフに戦闘ダメージを与えた時、このカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー ウルベルム",h:"えっくす－せいばー　うるべるむ",e:"X-Saber Urbellum",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n相手の手札が４枚以上の場合、このカードが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚選んで持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J カタストル",h:"あーりー・おぶ・じゃすてぃす　かたすとる",e:"Ally of Justice Catastor",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが闇属性以外のフィールド上に表側表示で存在するモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラス・オブ・ネオス",h:"らす・おぶ・ねおす",e:"Wrath of Neos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のモンスターゾーンの「E・HERO ネオス」１体を対象として発動できる。\nその「E・HERO ネオス」を持ち主のデッキに戻し、フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機雷化",h:"きらいか",e:"Detonate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「クリボー」及び「クリボートークン」を全て破壊する。\nその後、破壊した数と同じ数まで相手フィールド上のカードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"バーサーカークラッシュ",h:"ばーさーかーくらっしゅ",e:"Berserker Crush",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するモンスター１体をゲームから除外して発動する。\nこのターンのエンドフェイズ時まで、自分フィールド上に表側表示で存在する「ハネクリボー」１体の攻撃力・守備力は、除外したモンスターと同じ数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォリューション・バースト",h:"えう゛ぉりゅーしょん・ばーすと",e:"Evolution Burst",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「サイバー・ドラゴン」が存在する場合に発動できる。\n相手フィールド上のカード１枚を選択して破壊する。\nこのカードを発動するターン、「サイバー・ドラゴン」は攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドアイズ・ダークネスメタルドラゴン",h:"れっどあいず・だーくねすめたるどらごん",e:"Red-Eyes Darkness Metal Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは自分フィールドの表側表示のドラゴン族モンスター１体を除外し、手札から特殊召喚できる。\n②：自分メインフェイズに発動できる。\n自分の手札・墓地から「レッドアイズ・ダークネスメタルドラゴン」以外のドラゴン族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"地球巨人 ガイア・プレート",h:"ちきゅうきょじん　がいあ・ぷれーと",e:"Gaia Plate the Earth Giant",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分の墓地から岩石族モンスター１体を除外する。\nまたは除外せずにこのカードを墓地へ送る。\n①：このカードは自分の墓地の岩石族モンスター２体を除外し、手札から特殊召喚できる。\n②：このカードと戦闘を行う相手モンスターの攻撃力・守備力はダメージ計算時のみ半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"竜脚獣ブラキオン",h:"りゅうきゃくじゅうぶらきおん",e:"Sauropod Brachion",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1500,de:3000,tx:"このカードはデッキから特殊召喚できない。\nこのカードは恐竜族モンスター１体をリリースして表側表示でアドバンス召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードが反転召喚に成功した場合に発動する。\nこのカード以外のフィールドのモンスターを全て裏側守備表示にする。\n③：このカードが攻撃された場合、その戦闘で発生する相手への戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"光神テテュス",h:"こうしんててゅす",e:"Tethys, Goddess of Light",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"①：自分がカードをドローした時、そのカードが天使族モンスターだった場合、そのカードを相手に見せて発動できる。\nこのカードがフィールドに表側表示で存在する場合、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・グレファー",h:"だーく・ぐれふぁー",e:"Dark Grepher",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"①：このカードは手札からレベル５以上の闇属性モンスター１体を捨てて、手札から特殊召喚できる。\n②：１ターンに１度、手札から闇属性モンスター１体を捨てて発動できる。\nデッキから闇属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使ゼラート",h:"だてんしぜらーと",e:"Darklord Zerato",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"自分の墓地に闇属性モンスターが４種類以上存在する場合、このカードは闇属性モンスター１体をリリースしてアドバンス召喚できる。\n①：手札から闇属性モンスター１体を墓地へ送って発動できる。\n相手フィールドのモンスターを全て破壊する。\n②：このカードの①の効果を発動したターンのエンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・パーシアス",h:"だーく・ぱーしあす",e:"Darknight Parshath",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、自分の墓地に存在する闇属性モンスター１体をゲームから除外する事で、自分のデッキからカードを１枚ドローする。\nこのカードの攻撃力は、自分の墓地に存在する闇属性モンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"深海の大ウナギ",h:"しんかいのおおうなぎ",e:"Deepsea Macrotrema",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"このカードがフィールド上から墓地へ送られた時、自分フィールド上に表側表示で存在する全ての水属性モンスターの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"メタボ・サッカー",h:"めたぼ・さっかー",e:"Metabo Globster",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:300,tx:"このカードをリリースして闇属性モンスターのアドバンス召喚に成功した時、自分フィールド上に「メタボトークン」（悪魔族・闇・星１・攻／守０）３体を守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"光鱗のトビウオ",h:"こうりんのとびうお",e:"Golden Flying Fish",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分フィールド上に存在するこのカード以外の魚族モンスター１体をリリースして発動する。\nフィールド上のカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マテリアルドラゴン",h:"まてりあるどらごん",e:"Prime Material Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、ライフポイントにダメージを与える効果は、ライフポイントを回復する効果になる。\nまた、「フィールド上のモンスターを破壊する効果」を持つ魔法・罠・効果モンスターの効果が発動した時、手札を１枚墓地へ送る事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ローンファイア・ブロッサム",h:"ろーんふぁいあ・ぶろっさむ",e:"Lonefire Blossom",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:500,de:1400,tx:"①：１ターンに１度、自分フィールドの表側表示の植物族モンスター１体をリリースして発動できる。\nデッキから植物族モンスター１体を特殊召喚する。",li:2,lk:[],ta:[],al:[]},
  {n:"ジャイアントワーム",h:"じゃいあんとわーむ",e:"Aztekipede, the Worm Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:400,tx:"このカードは通常召喚できない。\n自分の墓地に存在する昆虫族モンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\nこのカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の誘惑",h:"やみのゆうわく",e:"Allure of Darkness",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分はデッキから２枚ドローし、その後手札の闇属性モンスター１体を除外する。\n手札に闇属性モンスターが無い場合、手札を全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"アテナ",h:"あてな",e:"Athena",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:800,tx:"①：１ターンに１度、「アテナ」以外の自分フィールドの表側表示の天使族モンスター１体を墓地へ送り、「アテナ」以外の自分の墓地の天使族モンスター１体を対象として発動できる。\nその天使族モンスターを特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、このカード以外の天使族モンスターが召喚・反転召喚・特殊召喚された場合に発動する。\n相手に６００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘカテリス",h:"へかてりす",e:"Hecatrice",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1100,tx:"①：自分メインフェイズにこのカードを手札から墓地へ捨てて発動できる。\nデッキから「神の居城－ヴァルハラ」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"神の居城－ヴァルハラ",h:"かみのきょじょう－う゛ぁるはら",e:"Valhalla, Hall of the Fallen",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から天使族モンスター１体を特殊召喚する。\nこの効果は自分フィールドにモンスターが存在しない場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"オネスト",h:"おねすと",e:"Honest",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"①：自分メインフェイズに発動できる。\nフィールドの表側表示のこのカードを持ち主の手札に戻す。\n②：自分の光属性モンスターが戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nそのモンスターの攻撃力はターン終了時まで、戦闘を行う相手モンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"クロス・ポーター",h:"くろす・ぽーたー",e:"Cross Porter",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"①：自分フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターを墓地へ送り、手札から「N」モンスター１体を特殊召喚する。\n②：このカードが墓地へ送られた時に発動できる。\nデッキから「N」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラクル・フリッパー",h:"みらくる・ふりっぱー",e:"Miracle Flipper",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"「ミラクル・フリッパー」が自分フィールド上に存在する場合、このカードは召喚・反転召喚・特殊召喚できない。\nこのカードがフィールド上に表側表示で存在する限り、相手は他の表側表示のモンスターを攻撃対象に選択できない。\nこのカードが戦闘によって破壊された場合、このカードを相手フィールド上に特殊召喚する。\nこのカードが魔法・罠の効果によって破壊された場合、相手フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドレッドサーヴァント",h:"ですてにーひーろー　どれっどさーう゛ぁんと",e:"Destiny HERO - Dread Servant",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:700,tx:"このカードが召喚に成功した時、「幽獄の時計塔」に時計カウンターを１つ置く。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上の魔法・罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴォルカニック・クイーン",h:"う゛ぉるかにっく・くいーん",e:"Volcanic Queen",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードは通常召喚できない。\n相手フィールド上のモンスター１体をリリースし、手札から相手フィールド上に特殊召喚できる。\n１ターンに１度、このカード以外の自分フィールド上のカード１枚を墓地へ送る事で、相手ライフに１０００ポイントダメージを与える。\nまた、自分のエンドフェイズ時にこのカード以外の自分フィールド上のモンスター１体をリリースするか、自分は１０００ポイントダメージを受ける。\nこのカードを特殊召喚するターン、自分は通常召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"人造人間－サイコ・リターナー",h:"じんぞうにんげん－さいこ・りたーなー",e:"Jinzo - Returner",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:1400,tx:"①：このカードは直接攻撃できる。\n②：このカードが墓地へ送られた時、自分の墓地の「人造人間－サイコ・ショッカー」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは自分エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"人造人間－サイコ・ロード",h:"じんぞうにんげん－さいこ・ろーど",e:"Jinzo - Lord",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:1600,tx:"このカードは通常召喚できない。\n自分フィールドの表側表示の「人造人間－サイコ・ショッカー」１体を墓地へ送った場合のみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、お互いにフィールドの罠カードの効果を発動できず、フィールドの罠カードの効果は無効化される。\n②：１ターンに１度、自分メインフェイズに発動できる。\nフィールドの表側表示の罠カードを全て破壊し、破壊した数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォース０－THE FOOL",h:"あるかなふぉーすぜろ－ざ・ふーる",e:"Arcana Force 0 - The Fool",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは戦闘では破壊されず、表示形式を守備表示に変更できない。\nこのカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い、その裏表によって以下の効果を得る。\n●表：このカードを対象にする自分のカードの効果を無効にし破壊する。\n●裏：このカードを対象にする相手のカードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅠ－THE MAGICIAN",h:"あるかなふぉーすわん－ざ・まじしゃん",e:"Arcana Force I - The Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：魔法カードが発動された時、そのターンのエンドフェイズ時までこのカードの元々の攻撃力は倍になる。\n●裏：魔法カードが発動する度に相手は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅢ－THE EMPRESS",h:"あるかなふぉーすすりー－じ・えんぷれす",e:"Arcana Force III - The Empress",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：相手がモンスターの通常召喚に成功する度に手札から「アルカナフォース」と名のついたモンスター１体を自分フィールド上に特殊召喚する事ができる。\n●裏：相手がモンスターの通常召喚に成功する度に自分は手札のカード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅣ－THE EMPEROR",h:"あるかなふぉーすふぉー－じ・えんぺらー",e:"Arcana Force IV - The Emperor",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスターの攻撃力は５００ポイントアップする。\n●裏：自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅥ－THE LOVERS",h:"あるかなふぉーすしっくす－ざ・らばーず",e:"Arcana Force VI - The Lovers",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：「アルカナフォース」と名のついたモンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。\n●裏：「アルカナフォース」と名のついたモンスターを生け贄召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅦ－THE CHARIOT",h:"あるかなふぉーすせぶん－ざ・ちゃりおっと",e:"Arcana Force VII - The Chariot",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1700,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：このカードが戦闘によって相手モンスターを破壊した場合、そのモンスターを自分フィールド上に特殊召喚する事ができる。\n●裏：このカードのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅩⅣ－TEMPERANCE",h:"あるかなふぉーすふぉーてぃーん－てんぱらんす",e:"Arcana Force XIV - Temperance",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:2400,tx:"戦闘ダメージ計算時、このカードを手札から捨てて発動できる。\nその戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い、その裏表によって以下の効果を得る。\n●表：このカードがフィールド上に表側表示で存在する限り、自分が受ける戦闘ダメージは半分になる。\n●裏：このカードがフィールド上に表側表示で存在する限り、相手が受ける戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅩⅧ－THE MOON",h:"あるかなふぉーすえいてぃーん－ざ・むーん",e:"Arcana Force XVIII - The Moon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2800,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：自分のスタンバイフェイズ時に自分フィールド上に「ムーントークン」（天使族・光・星１・攻／守０）を１体特殊召喚する事ができる。\n●裏：自分のエンドフェイズ時に１度だけ、自分フィールド上のモンスター１体を選択し、そのモンスターのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースⅩⅩⅠ－THE WORLD",h:"あるかなふぉーすとぅえんてぃーわん－ざ・わーるど",e:"Arcana Force XXI - The World",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3100,de:3100,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い、その裏表によって以下の効果を得る。\n●表：自分のエンドフェイズ時に自分フィールド上のモンスター２体を墓地へ送って発動できる。\n次の相手ターンをスキップする。\n●裏：相手のドローフェイズ毎に、相手の墓地の一番上のカードを相手の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナフォースEX－THE DARK RULER",h:"あるかなふぉーすえくすとら－ざ・だーく・るーらー",e:"Arcana Force EX - The Dark Ruler",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスター３体を墓地へ送った場合のみ特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：このカードはバトルフェイズ中２回攻撃する事ができる。\nこの効果が適用された２回目の戦闘を行った場合、このカードはバトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。\n●裏：このカードが破壊される場合、フィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・パラディン ジェイン",h:"らいとろーど・ぱらでぃん　じぇいん",e:"Jain, Lightsworn Paladin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが相手モンスターに攻撃するダメージステップの間、このカードの攻撃力は３００アップする。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・マジシャン ライラ",h:"らいとろーど・まじしゃん　らいら",e:"Lyla, Lightsworn Sorceress",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"①：自分メインフェイズに相手フィールドの魔法・罠カード１枚を対象として発動できる。\n自分フィールドの表側攻撃表示のこのカードを守備表示にし、対象の相手のカードを破壊する。\nこの効果の発動後、次の自分ターンの終了時までこのカードは表示形式を変更できない。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・ウォリアー ガロス",h:"らいとろーど・うぉりあー　がろす",e:"Garoth, Lightsworn Warrior",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1850,de:1300,tx:"「ライトロード・ウォリアー ガロス」以外の自分フィールド上の「ライトロード」と名のついたモンスターの効果によって自分のデッキからカードが墓地へ送られた場合、自分のデッキの上からカードを２枚墓地へ送る。\nその後、この効果で墓地へ送られた「ライトロード」と名のついたモンスターの数だけデッキからカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・サモナー ルミナス",h:"らいとろーど・さもなー　るみなす",e:"Lumina, Lightsworn Summoner",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、手札を１枚捨て、自分の墓地のレベル４以下の「ライトロード」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・ハンター ライコウ",h:"らいとろーど・はんたー　らいこう",e:"Ryko, Lightsworn Hunter",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:100,tx:"①：このカードがリバースした場合に発動する。\nフィールドのカード１枚を選んで破壊できる。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・ビースト ウォルフ",h:"らいとろーど・びーすと　うぉるふ",e:"Wulf, Lightsworn Beast",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2100,de:300,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\nこのカードがデッキから墓地へ送られた時に発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・エンジェル ケルビム",h:"らいとろーど・えんじぇる　けるびむ",e:"Celestia, Lightsworn Angel",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:200,tx:"「ライトロード」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、自分のデッキの上からカードを４枚墓地へ送って発動できる。\n相手フィールド上のカードを２枚まで選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・ドラゴン グラゴニス",h:"らいとろーど・どらごん　ぐらごにす",e:"Gragonith, Lightsworn Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードの攻撃力・守備力は、自分の墓地の「ライトロード」と名のついたモンスターの種類×３００ポイントアップする。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、自分のエンドフェイズ毎に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"裁きの龍",h:"じゃっじめんと・どらぐーん",e:"Judgment Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2600,tx:"このカードは通常召喚できない。\n自分の墓地の「ライトロード」モンスターが４種類以上の場合のみ特殊召喚できる。\n①：１０００LPを払って発動できる。\nこのカード以外のフィールドのカードを全て破壊する。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを４枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ヴァルキリア",h:"だーく・う゛ぁるきりあ",e:"Dark Valkyria",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:1050,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが表側表示で存在する限り１度だけ、このカードに魔力カウンターを１つ置く事ができる。\nこのカードの攻撃力は、このカードに乗っている魔力カウンターの数×３００ポイントアップする。\nまた、このカードに乗っている魔力カウンターを１つ取り除く事で、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イレカエル",h:"いれかえる",e:"Substitoad",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"自分フィールド上に存在するモンスター１体を生け贄に捧げる。\n自分のデッキから「ガエル」と名のついたモンスター１体を選択し、自分フィールド上に特殊召喚する。\nこのカードがフィールド上に存在する限り、「ガエル」と名のついたモンスターは戦闘によっては破壊されない。",li:0,lk:[],ta:[],al:[]},
  {n:"貫ガエル",h:"かんがえる",e:"Unifrog",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分フィールド上に「貫ガエル」以外の「ガエル」と名のついたモンスターが存在する場合、相手フィールド上の魔法・罠カード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"充電池メン",h:"じゅうでんちめん",e:"Batteryman Charger",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカードが召喚に成功した時、手札・デッキから「充電池メン」以外の「電池メン」と名のついたモンスター１体を特殊召喚できる。\nこのカードの攻撃力・守備力は、自分フィールド上の雷族モンスターの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"電池メン－業務用",h:"でんちめん－ぎょうむよう",e:"Batteryman Industrial Strength",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:0,tx:"このカードは通常召喚できない。\n自分の墓地の「電池メン」と名のついたモンスター２体をゲームから除外した場合のみ特殊召喚できる。\n１ターンに１度、自分の墓地の雷族モンスター１体をゲームから除外して発動できる。\nフィールド上のモンスター１体と魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"電池メン－ボタン型",h:"でんちめん－ぼたんがた",e:"Batteryman Micro-Cell",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:100,tx:"リバース：デッキから「電池メン－ボタン型」以外のレベル４以下の「電池メン」と名のついたモンスター１体を特殊召喚する。\nまた、リバースしたこのカードが戦闘によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリン偵察部隊",h:"ごぶりんていさつぶたい",e:"Goblin Recon Squad",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが相手プレイヤーへの直接攻撃に成功した場合、相手の手札をランダムに１枚確認する事ができる。\n確認したカードが魔法カードだった場合、そのカードを墓地に送る。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"惑星からの物体A",h:"わくせいからのぶったいえー",e:"Interplanetary Invader \"A\"",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"フィールド上に表側攻撃表示で存在するこのカードを攻撃したモンスターのコントロールをバトルフェイズ終了時に得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ディスクライダー",h:"でぃすくらいだー",e:"Diskblade Rider",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"１ターンに１度、自分の墓地の通常罠カード１枚を選択して発動できる。\n選択したカードをゲームから除外し、このカードの攻撃力を相手のエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金の天道虫",h:"ごーるでん・れでぃばぐ",e:"Golden Ladybug",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分のスタンバイフェイズ時に、手札のこのカードを相手に見せて発動できる。\n自分は５００ライフポイント回復する。\nこの効果を使用した場合、エンドフェイズ時まで手札のこのカードを公開する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダッカー",h:"だっかー",e:"DUCKER Mobile Cannon",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードがリバースした場合、自分の墓地のレベル４モンスター１体を対象として発動する。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイト夫人",h:"わいとふじん",e:"The Lady in Wight",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードのカード名は、墓地に存在する限り「ワイト」として扱う。\nまた、このカードがフィールド上に表側表示で存在する限り、「ワイト夫人」以外のフィールド上のレベル３以下のアンデット族モンスターは戦闘では破壊されず、魔法・罠カードの効果も受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"始祖神鳥シムルグ",h:"しそしんちょうしむるぐ",e:"Simorgh, Bird of Ancestry",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2900,de:2000,tx:"このカードは手札にある場合通常モンスターとして扱う。\nこのカードがフィールド上に表側表示で存在する限り、風属性モンスターの生け贄召喚に必要な生け贄は１体少なくなる。\n風属性モンスターのみを生け贄にしてこのカードの生け贄召喚に成功した場合、相手フィールド上のカードを２枚まで持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物－ストーム・ドラゴン",h:"くらうでぃあん－すとーむ・どらごん",e:"Cloudian - Storm Dragon",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:0,tx:"このカードは通常召喚できない。\n自分の墓地の「雲魔物」と名のついたモンスター１体をゲームから除外して特殊召喚する。\nこのカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\n１ターンに１度だけ、フィールド上モンスター１体にフォッグカウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ファントム・ドラゴン",h:"ふぁんとむ・どらごん",e:"Phantom Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:2200,tx:"相手がモンスターの特殊召喚に成功した時、手札からこのカードを特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分のモンスターカードゾーンは２ヵ所が使用不可能になる。",li:"",lk:[],ta:[],al:[]},
  {n:"Dragoon D-END",h:"どらぐーん　でぃーえんど",e:"Destiny End Dragoon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"「D-HERO Bloo-D」＋「D-HERO ドグマガイ」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊し、表側表示モンスターを破壊した場合、その攻撃力分のダメージを相手に与える。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\n②：このカードが墓地に存在する場合、自分スタンバイフェイズに自分の墓地の「D-HERO」カード１枚を除外して発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械究極巨人",h:"あんてぃーく・ぎあ・あるてぃめっと・ごーれむ",e:"Ultimate Ancient Gear Golem",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4400,de:3400,tx:"「古代の機械巨人」＋「アンティーク・ギア」モンスター×２\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが破壊された場合、自分の墓地の「古代の機械巨人」１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ガイザレス",h:"ぐらでぃあるびーすとがいざれす",e:"Gladiator Beast Gyzarus",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「剣闘獣ベストロウリィ」＋「剣闘獣」モンスター\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードが特殊召喚に成功した時、フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のEXデッキに戻して発動できる。\nデッキから「剣闘獣ベストロウリィ」以外の「剣闘獣」モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー・マスク",h:"ひーろー・ますく",e:"Hero Mask",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nデッキから「E・HERO」モンスター１体を墓地へ送り、対象の自分の表側表示モンスターはエンドフェイズまで、この効果で墓地へ送ったモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"スペーシア・ギフト",h:"すぺーしあ・ぎふと",e:"Space Gift",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「N」と名のついたモンスター１種類につき、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉の地",h:"しゅうえんのち",e:"Demise of the Land",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：相手がモンスターの特殊召喚に成功した時に発動できる。\nデッキからフィールド魔法カード１枚を選んで発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"D－フォーメーション",h:"でぃー－ふぉーめーしょん",e:"D - Formation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D-HERO」と名のついたモンスターが破壊される度に、１体につき１つDカウンターをこのカードに置く。\n自分のメインフェイズ時にモンスターの召喚・特殊召喚に成功した時、Dカウンターが２つ以上乗ったこのカードを墓地に送る事で、召喚・特殊召喚したモンスターと同名カードを２枚まで自分のデッキ・墓地から手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法の歯車",h:"まじっく・ぎあ",e:"Spell Gear",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「アンティーク・ギア」カード３枚を墓地へ送って発動できる。\n手札及びデッキからそれぞれ１体まで、「古代の機械巨人」を召喚条件を無視して特殊召喚する。\nその後、自分フィールドに「古代の機械巨人」以外のモンスターが存在する場合、そのモンスターを全て破壊する。\nこの効果の発動後、自分のターンで数えて２ターンの間、自分は通常召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"カップ・オブ・エース",h:"かっぷ・おぶ・えーす",e:"Cup of Ace",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"コイントスを１回行う。\n表が出た場合、自分はデッキからカードを２枚ドローする。\n裏が出た場合、相手はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"光の結界",h:"ひかりのけっかい",e:"Light Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分スタンバイフェイズに発動する。\nコイントスを１回行い、裏が出た場合、このカードの②③の効果を次の自分スタンバイフェイズまで無効にする。\n②：自分の「アルカナフォース」モンスターの召喚・反転召喚・特殊召喚時に発動する効果は、コイントスを行わず裏表のどちらかを選んで適用する。\n③：自分の「アルカナフォース」モンスターが戦闘で相手モンスターを破壊した場合に発動する。\nその破壊されたモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソーラー・エクスチェンジ",h:"そーらー・えくすちぇんじ",e:"Solar Recharge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札から「ライトロード」モンスター１体を捨てて発動できる。\n自分はデッキから２枚ドローする。\nその後、自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャスティス・ワールド",h:"じゃすてぃす・わーるど",e:"Realm of Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分のデッキからカードが墓地へ送られる度に、このカードにシャインカウンターを１つ置く。\nフィールド上の「ライトロード」と名のついたモンスターの攻撃力は、このカードに乗っているシャインカウンターの数×１００ポイントアップする。\nまた、このカードがカードの効果によって破壊される場合、代わりにこのカードに乗っているシャインカウンターを２つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"湿地草原",h:"しっちそうげん",e:"Wetlands",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上の水族・水属性・レベル２以下のモンスターの攻撃力は１２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"急速充電器",h:"くぃっくちゃーじゃー",e:"Quick Charger",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地のレベル４以下の「電池メン」と名のついたモンスター２体を選択して発動できる。\n選択したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"漏電",h:"しょーとさーきっと",e:"Short Circuit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「電池メン」と名のついたモンスターが３体以上存在する場合に発動できる。\n相手フィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"救援光",h:"きゅうえんこう",e:"Light of Redemption",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、除外されている自分の光属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光の宝札",h:"せんこうのほうさつ",e:"Mystical Cards of Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分の魔法＆罠カードゾーンを１ヵ所だけ使用不可能にする。\nこのカード以外の「閃光の宝札」が自分フィールド上に表側表示で存在する場合、自分のドローフェイズ時の通常ドローでカードを２枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"能力調整",h:"ぱわー・ちゅーん",e:"Level Tuning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する全てのモンスターのレベルをエンドフェイズ時まで１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"デッキロック",h:"でっきろっく",e:"Deck Lockdown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いにドロー以外の方法でデッキからカードを手札に加える事はできず、デッキからの特殊召喚もできない。\n発動後２回目の自分のスタンバイフェイズ時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"リボーンリボン",h:"りぼーんりぼん",e:"Ribbon of Rebirth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが戦闘によって破壊され墓地へ送られた場合、そのターンのエンドフェイズ時にそのモンスターを墓地から自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金色の竹光",h:"こがねいろのたけみつ",e:"Golden Bamboo Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「竹光」装備魔法カードが存在する場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"リミット・リバース",h:"りみっと・りばーす",e:"Limit Reverse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地の攻撃力１０００以下のモンスター１体を選択し、表側攻撃表示で特殊召喚する。\nそのモンスターが守備表示になった時、そのモンスターとこのカードを破壊する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターが破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒーロー・ブラスト",h:"ひーろー・ぶらすと",e:"Hero Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「E・HERO」通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札に加えたモンスターの攻撃力以下の攻撃力を持つ相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"虹の引力",h:"にじのいんりょく",e:"Rainbow Gravity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のフィールド上及び墓地に「宝玉獣」と名のついたカードが合計７種類存在する場合のみ発動する事ができる。\n自分のデッキまたは墓地に存在する「究極宝玉神」と名のついたモンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"D－フォーチュン",h:"でぃー－ふぉーちゅん",e:"D - Fortune",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時に発動する事ができる。\n自分の墓地に存在する「D-HERO」と名のついたモンスター１体をゲームから除外する事で、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"逆転する運命",h:"ぎゃくてんするうんめい",e:"Reversal of Fate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスター１体を選択して発動する。\n選択したモンスターがコイントスの裏表によって得た効果は逆になる。",li:"",lk:[],ta:[],al:[]},
  {n:"死神の巡遊",h:"しにがみのじゅんゆう",e:"Tour of Doom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手ターンのスタンバイフェイズ時にコイントスを１回行い以下の効果を適用する。\n\n●表：相手はエンドフェイズ時まで召喚・反転召喚する事ができない。\n\n●裏：自分は次の自分のターン召喚・反転召喚する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナコール",h:"あるかなこーる",e:"Arcana Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスター１体を選択して発動する。\n墓地に存在する「アルカナフォース」と名のついたモンスター１体をゲームから除外する。\nエンドフェイズ時まで、選択したモンスターがコイントスによって得た効果は、ゲームから除外したモンスターがコイントスによって得る効果と同じ効果になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライト・リサイレンス",h:"らいと・りさいれんす",e:"Light Spiral",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「ライトロード」と名のついたモンスターの効果で自分のデッキからカードが墓地に送られる度に、相手のデッキの上からカードを１枚ゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光のイリュージョン",h:"せんこうのいりゅーじょん",e:"Glorious Illusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地から「ライトロード」と名のついたモンスター１体を選択して表側攻撃表示で特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターがフィールド上から離れた時、このカードを破壊する。\nまた、自分のエンドフェイズ毎に発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"デストラクション・ジャマー",h:"ですとらくしょん・じゃまー",e:"Destruction Jammer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札を１枚捨てて発動できる。\n「フィールド上のモンスターを破壊する効果」を持つ効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フロッグ・バリア",h:"ふろっぐ・ばりあ",e:"Froggy Forcefield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「ガエル」と名のついたモンスターが攻撃対象に選択された時に発動できる。\n相手フィールド上に攻撃表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"携帯型バッテリー",h:"けいたいがたばってりー",e:"Portable Battery Pack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地の「電池メン」と名のついたモンスター２体を選択し、表側攻撃表示で特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを全て破壊する。\nそのモンスターが全てフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"グラディアル・チェンジ",h:"ぐらでぃある・ちぇんじ",e:"Gladiator Lash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「剣闘獣」と名のついたモンスターが特殊召喚された時に発動する事ができる。\n相手は手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"アグレッシブ・クラウディアン",h:"あぐれっしぶ・くらうでぃあん",e:"Raging Cloudian",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「雲魔物」と名のついたモンスターが自身の効果により破壊され墓地に送られた時に発動する事ができる。\n自分の墓地からそのモンスター１体を攻撃表示で特殊召喚し、そのモンスターにフォッグカウンターを１つ置く。\nこの効果で特殊召喚されたモンスターはカードの効果によっては守備表示にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"血の沼地",h:"ちのぬまち",e:"Sanguine Swamp",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上にこのカード以外の魔法・罠カードが存在している場合、このカードを破壊する。\nこのカードがフィールド上に存在する限り、魔法＆罠カードゾーンにセットされたカードは発動できない。\n２回目の自分のスタンバイフェイズ時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラッキー・チャンス！",h:"らっきー・ちゃんす！",e:"Lucky Chance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"コイントスを１回行う効果モンスターの効果が発動した時、コインの裏表を当てる。\n当たった場合、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サモンリミッター",h:"さもんりみったー",e:"Summon Limit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーは１ターンに合計２回までしかモンスターを召喚・反転召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイスインパクト",h:"だいすいんぱくと",e:"Dice Try!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が発動したサイコロを振る効果の発動を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"陰魔羅鬼",h:"おんもらき",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが、相手の効果で破壊され墓地へ送られた場合、または墓地からの特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"エンペラー・ストゥム",h:"えんぺらー・すとぅむ",e:"Emperor Sem",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:2200,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分がモンスターのアドバンス召喚に成功した時、お互いのプレイヤーはそれぞれの墓地に存在するカード１枚を選択し、デッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"光帝クライス",h:"こうていくらいす",e:"Kuraz the Light Monarch",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードが召喚・特殊召喚に成功した時、フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊し、破壊されたカードのコントローラーは破壊された枚数分だけデッキからドローできる。\n②：このカードは召喚・特殊召喚したターンには攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲイシャドウ",h:"げいしゃどう",e:"Maiden of Macabre",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが戦闘によって相手モンスターを破壊した場合、このカードに魔力カウンターを１つ置く。\nこのカードの攻撃力は、このカードに乗っている魔力カウンターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハネワタ",h:"はねわた",e:"Hanewata",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:300,tx:"①：このカードを手札から捨てて発動できる。\nこのターン、自分が受ける効果ダメージは０になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"素早いムササビ",h:"すばやいむささび",e:"Nimble Musasabi",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。\nさらに自分のデッキから「素早いムササビ」を任意の数だけ相手フィールド上に表側攻撃表示で特殊召喚する事ができる。\nこのカードはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"オイスターマイスター",h:"おいすたーまいすたー",e:"Oyster Meister",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"このカードが戦闘で破壊される以外の方法でフィールド上から墓地へ送られた時、自分フィールド上に「オイスタートークン」（魚族・水・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇霊使いダルク",h:"やみれいつかいだるく",e:"Dharc the Dark Charmer",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの闇属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガント・セファロタス",h:"ぎがんと・せふぁろたす",e:"Gigantic Cephalotus",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1850,de:700,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に存在する植物族モンスターが墓地へ送られる度に、このカードの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィード",h:"うぃーど",e:"Nettles",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:400,tx:"フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する植物族モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・ブランブル",h:"へる・ぶらんぶる",e:"Queen of Thorns",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"チューナー＋チューナー以外の植物族モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、お互いに手札から植物族以外のモンスターを召喚・特殊召喚するためには、１体につき１０００ライフポイントを払わなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ツクシー",h:"つくしー",e:"Horseytail",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手フィールド上に「ツクシトークン」（植物族・風・星１・攻／守０）１体を守備表示で特殊召喚する。\nこのトークンが植物族モンスターとの戦闘によって破壊された場合、このトークンのコントローラーは手札を１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"カースド・フィグ",h:"かーすど・ふぃぐ",e:"Cursed Fig",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上にセットされた魔法・罠カードを２枚選択して発動する。\nこのカードが墓地に存在する限り、選択したカードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"増草剤",h:"ぞうそうざい",e:"Miracle Fertilizer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分の墓地の植物族モンスター１体を対象として発動できる。\nその植物族モンスターを特殊召喚する。\nこの効果でモンスターを特殊召喚するターン、自分は通常召喚できない。\nこの効果で特殊召喚したモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバースペック",h:"おーばーすぺっく",e:"Overworked",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"元々の攻撃力よりも高い攻撃力を持つフィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"朱光の宣告者",h:"ばーみりおん・でくれあらー",e:"Herald of Orange Light",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:500,tx:"①：相手がモンスターの効果を発動した時、手札からこのカードと天使族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神光の宣告者",h:"ぱーふぇくと・でくれあらー",e:"Herald of Perfection",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1800,de:2800,tx:"「宣告者の預言」により降臨。\n①：相手がモンスターの効果・魔法・罠カードを発動した時、手札から天使族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ツインバレル・ドラゴン",h:"ついんばれる・どらごん",e:"Twin-Barrel Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、相手フィールド上に存在するカード１枚を選択して発動する。\nコイントスを２回行い、２回とも表だった場合、選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"伊弉凪",h:"いざなぎ",e:"Izanagi",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"このカードは手札のスピリットモンスター１体をゲームから除外し、手札から特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在するスピリットモンスターはエンドフェイズ時に手札に戻る効果を発動しなくてもよい。",li:"",lk:[],ta:[],al:[]},
  {n:"伊弉波",h:"いざなみ",e:"Izanami",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1100,de:1800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、手札を１枚捨てる事で自分の墓地に存在するスピリットモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大戦艦 ビッグ・コアMk－Ⅱ",h:"きょだいせんかん　びっぐ・こあＭｋ－Ⅱ",e:"B.E.S. Big Core MK-2",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1100,tx:"このカードが特殊召喚に成功した時、このカードにカウンターを３つ置く。\nこのカードは戦闘では破壊されない。\nこのカードが戦闘を行った場合、ダメージステップ終了時にこのカードに乗っているカウンターを１つ取り除く。\nこのカードがカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。\n自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ロード・ウォリアー",h:"ろーど・うぉりあー",e:"Road Warrior",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:1500,tx:"「ロード・シンクロン」＋チューナー以外のモンスター２体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキからレベル２以下の戦士族・機械族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"草薙剣",h:"くさなぎのつるぎ",e:"Sword of Kusanagi",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"スピリットモンスターにのみ装備可能。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n装備モンスターが自分フィールド上から手札に戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"八尺勾玉",h:"やさかのまがたま",e:"Orb of Yasaka",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"スピリットモンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分だけ自分のライフポイントを回復する。\n装備モンスターが自分フィールド上から手札に戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"八汰鏡",h:"やたのかがみ",e:"Mirror of Yata",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"スピリットモンスターにのみ装備可能。\n装備モンスターはエンドフェイズ時に手札に戻る効果を発動しなくてもよい。\n装備モンスターが戦闘によって破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"歯車街",h:"ぎあ・たうん",e:"Geartown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、お互いのプレイヤーは「アンティーク・ギア」モンスターを召喚する場合に必要なリリースを１体少なくできる。\n②：このカードが破壊され墓地へ送られた時に発動できる。\n自分の手札・デッキ・墓地から「アンティーク・ギア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"チューン・ウォリアー",h:"ちゅーん・うぉりあー",e:"Tune Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","通常"],ma:"",tp:"",at:1600,de:200,tx:"あらゆるものをチューニングしてしまう電波系戦士。\n常にアンテナを張ってはいるものの、感度はそう高くない。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーター・スピリット",h:"うぉーたー・すぴりっと",e:"Water Spirit",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["チューナー","通常"],ma:"",tp:"",at:400,de:1200,tx:"古代南極大陸の永久凍土にて生命が宿ったと言われる氷水の精霊。\n様々な物質に浸透する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ロードランナー",h:"ろーどらんなー",e:"Sonic Chick",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードは攻撃力１９００以上のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・シンクロン",h:"じゃんく・しんくろん",e:"Junk Synchron",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:500,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル２以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"スピード・ウォリアー",h:"すぴーど・うぉりあー",e:"Speed Warrior",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"①：このカードの召喚に成功したターンのバトルステップに発動できる。\nこのカードの攻撃力はバトルフェイズ終了時まで元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎龍",h:"まぐな・どらご",e:"Magna Drago",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:600,tx:"①：このカードが相手に戦闘ダメージを与えた場合に発動する。\nこのカードの攻撃力は２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカルフィシアリスト",h:"まじかるふぃしありすと",e:"Frequency Magician",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:400,tx:"このカードが召喚に成功した時、このカードに魔力カウンターを１つ置く（最大１つまで）。\nまた、自分のメインフェイズ時にこのカードに乗っている魔力カウンターを１つ取り除く事で、自分フィールド上のモンスター１体を選択してその攻撃力をエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・ヒーロー",h:"しんくろ・ひーろー",e:"Synchro Boost",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターのレベルは１つ上がり、攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"精神同調波",h:"せいしんどうちょうは",e:"Synchro Blast Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にシンクロモンスターが表側表示で存在する場合のみ発動する事ができる。\n相手フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コモンメンタルワールド",h:"こもんめんたるわーるど",e:"Synchronized Realm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分がシンクロモンスターのシンクロ召喚に成功する度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"くず鉄のかかし",h:"くずてつのかかし",e:"Scrap-Iron Scarecrow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\nその攻撃を無効にする。\n発動後このカードは墓地へ送らず、そのままセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミニチュアライズ",h:"みにちゅあらいず",e:"Miniaturize",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側表示で存在する元々の攻撃力が１０００より上のモンスター１体を選択して発動する。\n選択したモンスターのレベルを１つ下げ、攻撃力は１０００ポイントダウンする。\nそのモンスターがフィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・ウォリアー",h:"じゃんく・うぉりあー",e:"Junk Warrior",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1300,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\nこのカードの攻撃力は、自分フィールドのレベル２以下のモンスターの攻撃力の合計分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"大地の騎士ガイアナイト",h:"だいちのきしがいあないと",e:"Gaia Knight, the Force of Earth",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ"],ma:"",tp:"",at:2600,de:800,tx:"チューナー＋チューナー以外のモンスター１体以上",li:"",lk:[],ta:[],al:[]},
  {n:"ギガンテック・ファイター",h:"ぎがんてっく・ふぁいたー",e:"Colossal Fighter",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードの攻撃力は、お互いの墓地の戦士族モンスターの数×１００ポイントアップする。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分または相手の墓地の戦士族モンスター１体を選択し、自分フィールド上に特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンショット・ブースター",h:"わんしょっと・ぶーすたー",e:"Turbo Booster",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分がモンスターの召喚に成功したターン、このカードは手札から特殊召喚できる。\nまた、このカードをリリースして発動できる。\nこのターン自分のモンスターと戦闘を行った相手モンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ニトロ・シンクロン",h:"にとろ・しんくろん",e:"Nitro Synchron",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:100,tx:"このカードが「ニトロ」と名のついたシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ボルト・ヘッジホッグ",h:"ぼると・へっじほっぐ",e:"Quillbolt Hedgehog",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：自分メインフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果は自分フィールドにチューナーが存在する場合に発動と処理ができる。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"デッド・ガードナー",h:"でっど・がーどなー",e:"Ghost Gardna",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時、このカードに攻撃対象を変更する事ができる。\nこのカードが破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体の攻撃力はエンドフェイズ時まで１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"シールド・ウォリアー",h:"しーるど・うぉりあー",e:"Shield Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"戦闘ダメージ計算時、自分の墓地に存在するこのカードをゲームから除外して発動する事ができる。\n自分フィールド上に存在するモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"スモール・ピース・ゴーレム",h:"すもーる・ぴーす・ごーれむ",e:"Small Piece Golem",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"自分フィールド上に「ビッグ・ピース・ゴーレム」が表側表示で存在する場合にこのカードが召喚・反転召喚・特殊召喚に成功した時、自分のデッキから「ミッド・ピース・ゴーレム」１体を特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ミッド・ピース・ゴーレム",h:"みっど・ぴーす・ごーれむ",e:"Medium Piece Golem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"自分フィールド上に「ビッグ・ピース・ゴーレム」が表側表示で存在する場合にこのカードが召喚・反転召喚・特殊召喚に成功した時、自分のデッキから「スモール・ピース・ゴーレム」１体を特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグ・ピース・ゴーレム",h:"びっぐ・ぴーす・ごーれむ",e:"Big Piece Golem",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2100,de:0,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・スプロケッター",h:"だーく・すぷろけったー",e:"Sinister Sprocket",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:0,tx:"このカードが闇属性のシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、フィールド上に表側表示で存在する魔法または罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・リゾネーター",h:"だーく・りぞねーたー",e:"Dark Resonator",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:300,tx:"①：このカードは１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブル・プロテクター",h:"だぶる・ぷろてくたー",e:"Twin-Shield Defender",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:700,de:1600,tx:"自分フィールド上に存在するこのカードが戦闘によって破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体の攻撃力をエンドフェイズ時まで半分にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュッテ・ナイト",h:"じゅって・ないと",e:"Jutte Fighter",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:900,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"手錠龍",h:"わっぱー・どらごん",e:"Handcuffs Dragon",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、このカードを装備カード扱いとしてそのモンスターに装備する事ができる。\n装備モンスターの攻撃力は１８００ポイントダウンする。\n装備モンスターが破壊される事によってこのカードが墓地へ送られた時、このカードを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"モンタージュ・ドラゴン",h:"もんたーじゅ・どらごん",e:"Montage Dragon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\n手札からモンスター３体を墓地へ送った場合のみ特殊召喚できる。\nこのカードの攻撃力は、墓地へ送ったそのモンスターのレベルの合計×３００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴロゴル",h:"ごろごる",e:"Gonogo",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1350,de:1600,tx:"このカードと戦闘を行った相手モンスターは、ダメージステップ終了時に裏側守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"メンタルマスター",h:"めんたるますたー",e:"Mind Master",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:200,tx:"８００ライフポイントを払い、「メンタルマスター」以外の自分フィールド上のサイキック族モンスター１体をリリースして発動できる。\nデッキからレベル４以下のサイキック族モンスター１体を表側攻撃表示で特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"カバリスト",h:"かばりすと",e:"Doctor Cranium",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、８００ライフポイントを払う事で自分のデッキからサイキック族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"クレボンス",h:"くれぼんす",e:"Krebons",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードが攻撃対象に選択された時、８００ライフポイントを払って発動できる。\nその攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"メンタルプロテクター",h:"めんたるぷろてくたー",e:"Mind Protector",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこの時に５００ライフポイント払えない場合はこのカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、サイキック族モンスター以外の攻撃力２０００以下のモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・コマンダー",h:"さいこ・こまんだー",e:"Psychic Commander",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:800,tx:"自分フィールド上のサイキック族モンスターが相手モンスターと戦闘を行うダメージステップ時に１度だけ、１００の倍数のライフポイントを払って発動できる（最大５００まで）。\nこのターンのエンドフェイズ時まで、戦闘を行う相手モンスター１体の攻撃力・守備力は払った数値分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ウォールド",h:"さいこ・うぉーるど",e:"Psychic Snail",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"８００ライフポイントを払って発動する。\n自分フィールド上に表側表示で存在するサイキック族モンスター１体は、１度のバトルフェイズ中に２回攻撃する事ができる。\nこの効果を発動するターンこのカードは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"テレキアタッカー",h:"てれきあたっかー",e:"Telekinetic Shocker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"サイキック族モンスターが破壊される場合、５００ライフポイントを払い代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ディストラクター",h:"でぃすとらくたー",e:"Destructotron",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"１０００ライフポイントを払って発動できる。\n相手フィールド上にセットされた魔法・罠カード１枚を選択して破壊する。\nまた、お互いのエンドフェイズ時、自分フィールド上にこのカード以外のサイキック族モンスターが存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣エクイテ",h:"ぐらでぃあるびーすとえくいて",e:"Gladiator Beast Equeste",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地の「剣闘獣」と名のついたカード１枚を選択して手札に加える。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣エクイテ」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・プリースト ジェニス",h:"らいとろーど・ぷりーすと　じぇにす",e:"Jenis, Lightsworn Mender",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:2100,tx:"「ライトロード」と名のついたカードの効果によって自分のデッキからカードが墓地へ送られたターンのエンドフェイズ時、相手ライフに５００ポイントダメージを与え、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"メカウサー",h:"めかうさー",e:"Mecha Bunny",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「メカウサー」１体を自分フィールド上に裏側守備表示で特殊召喚する事ができる。\nこのカードがリバースした時、フィールド上に存在するカード１枚を選択し、そのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"くノ一ウォリアー",h:"くのいちうぉりあー",e:"Kunoichi",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"フィールド上に表側表示で存在するこのカードのコントロールが移った時、コントローラーは手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ファラオの化身",h:"ふぁらおのけしん",e:"Beast of the Pharaoh",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:400,de:600,tx:"①：このカードがS素材として墓地へ送られた場合、自分の墓地のレベル４以下のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・アサシン",h:"だーく・あさしん",e:"Dark Hunter",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"自分の墓地に存在する闇属性モンスターの数によって、このカードは以下の効果を得る。\n●１枚以下：このカードの攻撃力は４００ポイントダウンする。\n●２～４枚：このカードの攻撃力は４００ポイントアップする。\n●５枚以上：このカードを墓地に送る事で、相手フィールド上に裏側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"金華猫",h:"きんかびょう",e:"Kinka-byo",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"獣族",mo:["スピリット","効果"],ma:"",tp:"",at:400,de:200,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースした時、自分の墓地のレベル１モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"大和神",h:"やまとのかみ",e:"Yamato-no-Kami",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","スピリット","効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードは通常召喚する事ができない。\n自分の墓地に存在するスピリットモンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\n特殊召喚したターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが戦闘によって相手モンスターを破壊した場合、相手フィールド上に存在する魔法または罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"寂々虫",h:"じゃくじゃくむし",e:"Silent Strider",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:700,tx:"このカードを手札から墓地へ送って発動する。\nフィールド上に存在するモンスター１体のレベルをエンドフェイズ時まで１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"騒々虫",h:"そうぞうむし",e:"Noisy Gnat",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:700,de:300,tx:"このカードを手札から墓地へ送って発動する。\nフィールド上に存在するモンスター１体のレベルをエンドフェイズ時まで１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"マルチ・ピース・ゴーレム",h:"まるち・ぴーす・ごーれむ",e:"Multiple Piece Golem",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1300,tx:"「ビッグ・ピース・ゴーレム」＋「ミッド・ピース・ゴーレム」\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをエクストラデッキに戻す事ができる。\nさらに、エクストラデッキに戻したこのカードの融合召喚に使用した融合素材モンスター一組が自分の墓地に揃っていれば、この一組を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ニトロ・ウォリアー",h:"にとろ・うぉりあー",e:"Nitro Warrior",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1800,tx:"「ニトロ・シンクロン」＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分のターンに自分が魔法カードを発動した場合、このカードの攻撃力はそのターンのダメージ計算時のみ１度だけ１０００ポイントアップする。\nまた、このカードの攻撃によって相手モンスターを破壊したダメージ計算後に発動できる。\n相手フィールド上に表側守備表示で存在するモンスター１体を選択して攻撃表示にし、そのモンスターにもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・ドラゴン",h:"すたーだすと・どらごん",e:"Stardust Dragon",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：フィールドのカードを破壊する魔法・罠・モンスターの効果が発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。\n②：このカードの①の効果を適用したターンのエンドフェイズに発動できる。\nその効果を発動するためにリリースしたこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・デーモンズ・ドラゴン",h:"れっど・でーもんず・どらごん",e:"Red Dragon Archfiend",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが相手の守備表示モンスターを攻撃したダメージ計算後に発動する。\n相手フィールドの守備表示モンスターを全て破壊する。\n②：自分エンドフェイズに発動する。\nこのカードがフィールドに表側表示で存在する場合、このカード以外のこのターン攻撃宣言をしていない自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴヨウ・ガーディアン",h:"ごよう・がーでぃあん",e:"Goyo Guardian",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"地属性チューナー＋チューナー以外のモンスター１体以上\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・アンドロイド",h:"まじかる・あんどろいど",e:"Magical Android",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1700,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のエンドフェイズ時、自分フィールド上のサイキック族モンスター１体につき、自分は６００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"メンタルスフィア・デーモン",h:"めんたるすふぃあ・でーもん",e:"Thought Ruler Archfiend",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分だけ自分のLPを回復する。\n②：サイキック族モンスター１体のみを対象とする魔法・罠カードが発動した時、１０００LPを払って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイティング・スピリッツ",h:"ふぁいてぃんぐ・すぴりっつ",e:"Fighting Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は相手フィールド上に存在するモンスター１体につき３００ポイントアップする。\n装備モンスターが戦闘によって破壊される場合、代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドミノ",h:"どみの",e:"Domino Effect",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時、自分フィールド上に存在するモンスター１体を墓地へ送る事で、相手フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・アタック",h:"じゃんく・あたっく",e:"Junk Barrage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"イージーチューニング",h:"いーじーちゅーにんぐ",e:"Battle Tuned",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地からチューナー１体を除外し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターの攻撃力は、このカードを発動するために除外したチューナーの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロキャンセル",h:"しんくろきゃんせる",e:"De-Synchro",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドのSモンスター１体を対象として発動できる。\nそのSモンスターを持ち主のEXデッキに戻す。\nその後、EXデッキに戻したそのモンスターのS召喚に使用したS素材モンスター一組が自分の墓地に揃っていれば、その一組を自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトニング・チューン",h:"らいとにんぐ・ちゅーん",e:"Lightwave Tuning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するレベル４の光属性モンスター１体を選択して発動する。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"サイキックブレイク",h:"さいきっくぶれいく",e:"Psi-Station",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"サイキック族モンスターが召喚に成功した時、５００ライフポイントを払う事でそのモンスター１体のレベルを１つ上げ、攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイキック・インパルス",h:"さいきっく・いんぱるす",e:"Psi-Impulse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するサイキック族モンスター１体をリリースして発動する。\n相手の手札を全てデッキに加えてシャッフルする。\nその後、相手はデッキからカードを３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急テレポート",h:"きんきゅうてれぽーと",e:"Emergency Teleport",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札・デッキからレベル３以下のサイキック族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンのエンドフェイズに除外される。",li:2,lk:[],ta:[],al:[]},
  {n:"ライトロード・レイピア",h:"らいとろーど・れいぴあ",e:"Lightsworn Sabre",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ライトロード」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は７００ポイントアップする。\nこのカードがデッキから墓地に送られた時、このカードを自分フィールド上に存在する「ライトロード」と名のついたモンスター１体に装備する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"進化する人類",h:"しんかするじんるい",e:"Unstable Evolution",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分のライフポイントが相手より少ない場合、装備モンスターの元々の攻撃力は２４００になる。\n自分のライフポイントが相手より多い場合、装備モンスターの元々の攻撃力は１０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"バッテリーリサイクル",h:"ばってりーりさいくる",e:"Recycling Batteries",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の攻撃力１５００以下の雷族モンスター２体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"皆既日蝕の書",h:"かいきにっしょくのしょ",e:"Book of Eclipse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスターを全て裏側守備表示にする。\nこのターンのエンドフェイズに、相手フィールドの裏側守備表示モンスターを全て表側守備表示にし、その後、この効果で表側守備表示にしたモンスターの数だけ相手はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"イクイップ・シュート",h:"いくいっぷ・しゅーと",e:"Equip Shot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n自分フィールド上に表側攻撃表示で存在するモンスターに装備された装備カード１枚と相手フィールド上に存在する表側攻撃表示のモンスター１体を選択し、選択した装備カードを選択した相手モンスターに装備する。\nその後、選択した装備カードを装備していた自分のモンスターと、選択した相手モンスターで戦闘を行いダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"エンジェル・リフト",h:"えんじぇる・りふと",e:"Graceful Revival",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地のレベル２以下のモンスター１体を選択し、表側攻撃表示で特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターがフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガード・ブロック",h:"がーど・ぶろっく",e:"Defense Draw",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンの戦闘ダメージ計算時に発動する事ができる。\nその戦闘によって発生する自分への戦闘ダメージは０になり、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"悲劇の引き金",h:"ひげきのひきがね",e:"Remote Revenge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが、「フィールド上のモンスター１体を破壊する効果」を持つ魔法・罠・効果モンスターの効果の対象になった時に発動する事ができる。\nその効果の対象を相手フィールド上に存在する正しい対象となるモンスター１体に移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"スターゲート",h:"すたーげーと",e:"Spacegate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが戦闘を行った場合、そのダメージステップ終了時にこのカードにゲートカウンターを１つ置く。\n自分のターンのメインフェイズ時にこのカードを墓地に送る事で、このカードに乗っているゲートカウンターの数以下のレベルのモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロン・リフレクト",h:"しんくろん・りふれくと",e:"Synchro Deflector",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスターが攻撃対象になった時に発動する事ができる。\nその攻撃を無効にし、相手フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブロークン・ブロッカー",h:"ぶろーくん・ぶろっかー",e:"Broken Blocker",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する攻撃力よりも守備力の高い守備表示モンスターが、戦闘によって破壊された場合に発動する事ができる。\nそのモンスターと同名モンスターを２体まで自分のデッキから表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神機王ウル",h:"しんきおううる",e:"Machine Lord Ür",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：このカードは相手モンスター全てに１回ずつ攻撃できる。\n②：このカードが戦闘を行う場合、相手が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"念導力",h:"ねんどうりょく",e:"Telepathic Power",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するサイキック族モンスターが、相手モンスターの攻撃によって破壊された場合に発動する事ができる。\nその時に攻撃を行った相手モンスター１体を破壊し、その攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブローニング・パワー",h:"ぶろーにんぐ・ぱわー",e:"Mind Over Matter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上のサイキック族モンスター１体をリリースして発動できる。\n魔法・罠カードの発動、モンスターの召喚・特殊召喚のどれか１つを無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣の戦車",h:"ぐらでぃあるびーすと・ちゃりおっと",e:"Gladiator Beast War Chariot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「剣闘獣」モンスターが存在し、モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・バリア",h:"らいとろーど・ばりあ",e:"Lightsworn Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「ライトロード」と名のついたモンスターが攻撃対象に選択された時、自分のデッキの上からカードを２枚墓地へ送る事で、攻撃モンスター１体を選択し、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"インターセプト",h:"いんたーせぷと",e:"Intercept",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"モンスター１体のリリースを必要とするモンスターの召喚に成功した時に発動する事ができる。\nそのモンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"雷の裁き",h:"いかずちのさばき",e:"Judgment of Thunder",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに雷族モンスターが召喚・反転召喚・特殊召喚された時、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ヒーリング",h:"さいこ・ひーりんぐ",e:"Psychic Rejuvenation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するサイキック族モンスター１体につき、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシャーチャージ",h:"ふぃっしゃーちゃーじ",e:"Fish Depth Charge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の魚族モンスター１体をリリースし、フィールド上のカード１枚を選択して発動できる。\n選択したカードを破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"針虫の巣窟",h:"はりむしのそうくつ",e:"Needlebug Nest",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のデッキの上からカードを５枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・シムルグ",h:"だーく・しむるぐ",e:"Dark Simorgh",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1000,tx:"①：このカードが手札に存在する場合、自分の墓地から闇属性と風属性のモンスターを１体ずつ除外して発動できる。\nこのカードを特殊召喚する。\n②：このカードが墓地に存在する場合、手札から闇属性と風属性のモンスターを１体ずつ除外して発動できる。\nこのカードを特殊召喚する。\n③：このカードがモンスターゾーンに存在する限り、このカードの属性は「風」としても扱う。\n④：このカードがモンスターゾーンに存在する限り、相手はカードをセットできない。",li:"",lk:[],ta:[],al:[]},
  {n:"神禽王アレクトール",h:"しんきんおうあれくとーる",e:"Alector, Sovereign of Birds",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"相手フィールド上に同じ属性のモンスターが表側表示で２体以上存在する場合、このカードは手札から特殊召喚する事ができる。\n１ターンに１度、フィールド上に表側表示で存在するカード１枚を選択する。\n選択されたカードの効果はそのターン中無効になる。\n「神禽王アレクトール」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚制限－パワーフィルター",h:"しょうかんせいげん－ぱわーふぃるたー",e:"Power Filter",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーは攻撃力１０００以下のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔知ガエル",h:"まちがえる",e:"Dupe Frog",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"①：このカードはモンスターゾーンに存在する限り、カード名を「デスガエル」として扱う。\n②：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n③：このカードがフィールドから墓地へ送られた時に発動できる。\n自分のデッキ・墓地から「魔知ガエル」以外の「ガエル」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・チャージ",h:"さいこ・ちゃーじ",e:"Psychic Overload",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在するサイキック族モンスター３体を選択し、デッキに加えてシャッフルする。\nその後、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"獣神機王バルバロスUr",h:"じゅうしんきおうばるばろすうる",e:"Beast Machine King Barbaros Ür",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3800,de:1200,tx:"①：このカードは、自分の手札・フィールド・墓地から獣戦士族モンスターと機械族モンスターを１体ずつ除外して手札から特殊召喚できる。\n②：このカードの戦闘で相手が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・オブ・オーダー",h:"がーでぃあん・おぶ・おーだー",e:"Guardian of Order",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"自分フィールド上に光属性モンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n「ガーディアン・オブ・オーダー」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・ドルイド オルクス",h:"らいとろーど・どるいど　おるくす",e:"Aurkus, Lightsworn Druid",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーは「ライトロード」と名のついたモンスターを魔法・罠・効果モンスターの効果の対象にできない。\nまた、自分のエンドフェイズ毎に発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・モンク エイリン",h:"らいとろーど・もんく　えいりん",e:"Ehren, Lightsworn Monk",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが守備表示モンスターを攻撃したダメージ計算前に発動する。\nそのモンスターを持ち主のデッキに戻す。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ジェネラル フリード",h:"だーく・じぇねらる　ふりーど",e:"Dark General Freed",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードは特殊召喚できない。\n自分フィールド上に表側表示で存在する闇属性モンスターを対象にする魔法カードの効果を無効にし破壊する。\nこのカードがフィールド上に表側表示で存在する限り、自分のドローフェイズ時に通常のドローを行う代わりに、自分のデッキからレベル４の闇属性モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・コンダクター",h:"まじかる・こんだくたー",e:"Magical Exemplar",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを２つ置く。\n②：１ターンに１度、このカードの魔力カウンターを任意の数だけ取り除いて発動できる。\n取り除いた数と同じレベルを持つ魔法使い族モンスター１体を、自分の手札・墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"邪狂神の使い",h:"じゃきょうしんのつかい",e:"Maniacal Servant",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:1000,tx:"相手のスタンバイフェイズ時に１度だけ、自分の墓地に存在する闇属性モンスターを任意の枚数ゲームから除外する事ができる。\nエンドフェイズ時まで、このカードの守備力はこの効果で除外したモンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の魔精イグニス",h:"ほのおのませいいぐにす",e:"Flame Spirit Ignis",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"自分フィールド上に表側表示で存在する炎属性モンスター１体をリリースして発動する。\n自分の墓地に存在する炎属性モンスターの数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"超古代恐獣",h:"えんしぇんと・だいの",e:"Super-Ancient Dinobeast",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2700,de:1400,tx:"このカードは恐竜族モンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\n①：このカードがモンスターゾーンに存在し、自分の墓地から恐竜族モンスターが特殊召喚された時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ライト・バニッシュ",h:"らいと・ばにっしゅ",e:"Vanquishing Light",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上の「ライトロード」と名のついたモンスター１体をリリースして発動できる。\nモンスターの召喚・反転召喚・特殊召喚を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"赤鬼",h:"あかおに",e:"Red Ogre",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"このカードが召喚に成功した時、自分の手札を任意の枚数墓地に送る事で、その枚数分だけフィールド上のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオス・ワイズマン",h:"ねおす・わいずまん",e:"Neos Wiseman",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは通常召喚できない。\n自分のモンスターゾーンの表側表示の、「E・HERO ネオス」と「ユベル」を１体ずつ墓地へ送った場合のみ特殊召喚できる。\n①：フィールドのこのカードは効果では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に発動する。\nその相手モンスターの攻撃力分のダメージを相手に与える。\nその相手モンスターの守備力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ゴッド・ネオス",h:"えれめんたるひーろー　ごっど・ねおす",e:"Elemental HERO Divine Neos",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは融合召喚でしか特殊召喚できない。\n「ネオス」・「N」・「HERO」と名のついたモンスターをそれぞれ１体以上、合計５体のモンスターを融合素材として融合召喚する。\n１ターンに１度、自分の墓地に存在する「ネオス」・「N」・「HERO」と名のついたモンスター１体をゲームから除外する事で、このカードの攻撃力は５００ポイントアップする。\nさらに、エンドフェイズ時までそのモンスターと同じ効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・コントローラー",h:"じぇねくす・こんとろーらー",e:"Genex Controller",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","通常"],ma:"",tp:"",at:1400,de:1200,tx:"仲間達と心を通わせる事ができる、数少ないジェネクスのひとり。\n様々なエレメントの力をコントロールできるぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・ウンディーネ",h:"じぇねくす・うんでぃーね",e:"Genex Undine",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"①：このカードが召喚に成功した時、デッキから水属性モンスター１体を墓地へ送って発動できる。\nデッキから「ジェネクス・コントローラー」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・パワー・プランナー",h:"じぇねくす・ぱわー・ぷらんなー",e:"Genex Power Planner",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードが召喚に成功した時、デッキからレベル３の「ジェネクス」と名のついた効果モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・サーチャー",h:"じぇねくす・さーちゃー",e:"Genex Searcher",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の「ジェネクス」と名のついたモンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・ワーカー",h:"じぇねくす・わーかー",e:"Genex Worker",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードをリリースして発動できる。\n手札から「ジェネクス」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷弾使いレイス",h:"ひょうだんつかいれいす",e:"Reese the Ice Mistress",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:800,tx:"このカードはレベル４以上のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界のロイヤル・ナイト",h:"ひょうけっかいのろいやる・ないと",e:"Royal Knight of the Ice Barrier",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードがアドバンス召喚に成功した時、相手フィールド上に「アイスコフュントークン」（水族・水・星１・攻１０００／守０）１体を攻撃表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界に住む魔酔虫",h:"ひょうけっかいにすむますいちゅう",e:"Numbing Grub in the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"このカードが召喚に成功した時、使用していないモンスターカードゾーンを１ヵ所指定して発動する。\nこのカードがフィールド上に表側表示で存在する限り、指定したモンスターカードゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の見張り番",h:"みすと・ばれーのみはりばん",e:"Mist Valley Watcher",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1500,de:1900,tx:"霞の谷を代々見張り続ける、見張り番一族の末裔。\n谷で起こる出来事は、どんな些細な事も見逃さない。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスト・コンドル",h:"みすと・こんどる",e:"Mist Condor",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"このカードは自分フィールド上に表側表示で存在する「ミスト・バレー」と名のついたモンスター１体を持ち主の手札に戻し、手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの攻撃力は１７００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の風使い",h:"みすと・ばれーのかぜつかい",e:"Mist Valley Windmaster",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"１ターンに１度、お互いの手札が５枚以上の場合に発動できる。\nお互いのプレイヤーは手札が４枚になるように手札を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・パウン",h:"ふれむべる・ぱうん",e:"Flamvell Poun",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから守備力２００のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・アーチャー",h:"ふれむべる・あーちゃー",e:"Flamvell Archer",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:200,tx:"１ターンに１度、自分フィールド上に表側表示で存在する炎族モンスター１体をリリースして発動できる。\nフィールド上の全ての「フレムベル」と名のついたモンスターの攻撃力は、エンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・デビル",h:"ふれむべる・でびる",e:"Flamvell Fiend",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分の墓地の炎族モンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー アナペレラ",h:"えっくす－せいばー　あなぺれら",e:"X-Saber Anu Piranha",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1100,tx:"華麗な攻撃と冷静な判断で戦場を舞う、X－セイバーの女戦士。\n時に冷酷なその攻撃は敵に恐れられている。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー ガラハド",h:"えっくす－せいばー　がらはど",e:"X-Saber Galahad",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間、攻撃力が３００ポイントアップする。\nこのカードは相手モンスターに攻撃される場合、ダメージステップの間、攻撃力が５００ポイントダウンする。\nまた、このカードが攻撃対象に選択された時、このカード以外の自分フィールド上の「セイバー」と名のついたモンスター１体をリリースする事で、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー パロムロ",h:"えっくす－せいばー　ぱろむろ",e:"X-Saber Palomuro",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:300,tx:"このカードが墓地に存在し、自分フィールド上の「セイバー」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時、５００ライフポイントを払う事で、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー パシウル",h:"えっくす－せいばー　ぱしうる",e:"X-Saber Pashuul",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:0,tx:"①：このカードは戦闘では破壊されない。\n②：相手スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。\nこの効果はこのカードがモンスターゾーンに表側守備表示で存在する場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ボム",h:"あーりー・ぼむ",e:"Ally Salvo",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードが光属性モンスターとの戦闘によって破壊され墓地へ送られた時、フィールド上のカード２枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J サウザンド・アームズ",h:"あーりー・おぶ・じゃすてぃす　さうざんど・あーむず",e:"Ally of Justice Thousand Arms",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードは相手フィールド上の全ての光属性モンスターに１回ずつ攻撃できる。\nこのカードが光属性以外のモンスターと戦闘を行う場合、そのダメージ計算前にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J アンノウン・クラッシャー",h:"あーりー・おぶ・じゃすてぃす　あんのうん・くらっしゃー",e:"Ally of Justice Unknown Crusher",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが光属性モンスターと戦闘を行った時、そのモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ファルコ",h:"わーむ・ふぁるこ",e:"Worm Falco",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:800,tx:"リバース：このカード以外の自分フィールド上の「ワーム」と名のついた爬虫類族モンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・グルス",h:"わーむ・ぐるす",e:"Worm Gulse",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"フィールド上に裏側守備表示で存在するモンスターがリバースする度に、このカードにワームカウンターを１つ置く。\nこのカードの攻撃力は、このカードに乗っているワームカウンターの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ホープ",h:"わーむ・ほーぷ",e:"Worm Hope",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1500,tx:"リバース：このカードが相手モンスターの攻撃によってリバースした場合、デッキからカードを１枚ドローする。\nまた、このカードがフィールド上から墓地へ送られた時、自分は手札を１枚選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・イリダン",h:"わーむ・いりだん",e:"Worm Illidan",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"自分フィールド上にカードがセットされる度に、このカードにワームカウンターを１つ置く。\nこのカードに乗っているワームカウンターを２つ取り除く事で、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ジェートリクプス",h:"わーむ・じぇーとりくぷす",e:"Worm Jetelikpse",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:0,tx:"リバース：このターン、このカードが破壊され墓地へ送られた時、このカードを墓地から表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・キング",h:"わーむ・きんぐ",e:"Worm King",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2700,de:1100,tx:"このカードは「ワーム」と名のついた爬虫類族モンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\nまた、自分フィールド上の「ワーム」と名のついた爬虫類族モンスター１体をリリースする事で、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイドロ・ジェネクス",h:"はいどろ・じぇねくす",e:"Hydro Genex",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"「ジェネクス・コントローラー」＋チューナー以外の水属性モンスター１体以上\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー ウェイン",h:"えっくす－せいばー　うぇいん",e:"X-Saber Wayne",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:400,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\n手札からレベル４以下の戦士族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J ライト・ゲイザー",h:"あーりー・おぶ・じゃすてぃす　らいと・げいざー",e:"Ally of Justice Light Gazer",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードの攻撃力は、相手の墓地の光属性モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カオスエンドマスター",h:"かおすえんどますたー",e:"Chaos-End Master",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキからレベル５以上で攻撃力１６００以下のモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌球体",h:"すふぃあ・おぶ・かおす",e:"Sphere of Chaos",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカードはフィールド上に表側表示で存在する限り、光属性としても扱う。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。\nこのカードがアドバンス召喚に成功した時、デッキからレベル３モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・ニュートロン",h:"じぇねくす・にゅーとろん",e:"Genex Neutron",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードを召喚したターンのエンドフェイズに発動できる。\nデッキから機械族チューナー１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J サイクロン・クリエイター",h:"あーりー・おぶ・じゃすてぃす　さいくろん・くりえいたー",e:"Ally of Justice Cyclone Creator",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1200,tx:"１ターンに１度、手札を１枚捨てて発動できる。\nフィールド上のチューナーの数だけ、フィールド上の魔法・罠カードを選んで持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ロード・シンクロン",h:"ろーど・しんくろん",e:"Road Synchron",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:800,tx:"①：このカードを「ロード・ウォリアー」以外のSモンスターの素材とする場合、このカードのレベルを２つ下げたレベルとして扱う。\n②：このカードが攻撃した場合、そのダメージステップ終了時に発動する。\nこのカードのレベルをターン終了時まで１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリン陽動部隊",h:"ごぶりんようどうぶたい",e:"Goblin Decoy Squad",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする事ができる。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分のターンのエンドフェイズ時まで表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェイドナイト",h:"じぇいどないと",e:"Jade Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードがフィールド上に表側攻撃表示で存在する限り、自分フィールド上の攻撃力１２００以下の機械族モンスターは罠カードの効果では破壊されない。\nフィールド上に表側表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、デッキから機械族・光属性・レベル４のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラッコアラ",h:"らっこあら",e:"Tree Otter",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:100,tx:"このカード以外の獣族モンスターが自分フィールド上に表側表示で存在する場合、自分フィールド上に表側表示で存在するモンスター１体の攻撃力をエンドフェイズ時まで１０００ポイントアップする事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"コアラッコアラ",h:"こあらっこあら",e:"Koalo-Koala",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:200,tx:"「コアラッコ」＋「ラッコアラ」\n手札から獣族モンスター１体を墓地へ送り、相手フィールド上に存在するモンスター１体を選択して発動する。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マンモス・ゾンビ",h:"まんもす・ぞんび",e:"Zombie Mammoth",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"自分の墓地にアンデット族モンスターが存在しない場合、このカードを破壊する。\nフィールド上に表側表示で存在するこのカードが破壊された場合、その時のコントローラーにこのカードの元々の攻撃力分のダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デスカイザー・ドラゴン",h:"ですかいざー・どらごん",e:"Doomkaiser Dragon",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「ゾンビキャリア」＋チューナー以外のアンデット族モンスター１体以上\n①：このカードが特殊召喚に成功した時、相手の墓地のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを自分フィールドに攻撃表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾンビキャリア",h:"ぞんびきゃりあ",e:"Plaguespreader Zombie",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"①：このカードが墓地に存在する場合、手札を１枚デッキの一番上に戻して発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の白石",h:"ほわいと・おぶ・れじぇんど",e:"The White Stone of Legend",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:250,tx:"①：このカードが墓地へ送られた場合に発動する。\nデッキから「青眼の白龍」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"蘇りし魔王 ハ・デス",h:"よみがえりしまおう　は・です",e:"Revived King Ha Des",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2450,de:0,tx:"「ゾンビキャリア」＋チューナー以外のアンデット族モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のアンデット族モンスターが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・シャーク",h:"さいばー・しゃーく",e:"Cyber Shark",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2100,de:2000,tx:"自分フィールド上に水属性モンスターが表側表示で存在する場合、このカードはリリースなしで召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の露払い",h:"ろくぶしゅうのつゆはらい",e:"Hand of the Six Samurai",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"自分フィールド上にこのカード以外の「六武衆」と名のついたモンスターが表側表示で存在する場合に発動する事ができる。\n自分フィールド上に存在する「六武衆」と名のついたモンスター１体をリリースする事で、フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の不死竜",h:"れっどあいず・あんでっとどらごん",e:"Red-Eyes Zombie Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードはアンデット族モンスター１体をリリースして攻撃表示でアドバンス召喚できる。\n①：このカードが戦闘でアンデット族モンスターを破壊し墓地へ送った時に発動できる。\nそのアンデット族モンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"邪神機－獄炎",h:"だーくねすぎあ－ごくえん",e:"Malevolent Mech - Goku En",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"①：このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚されている場合、エンドフェイズに発動する。\nこのカードを墓地へ送る。\nその後、自分はこのカードの元々の攻撃力分のダメージを受ける。\nこの効果はフィールドにこのカード以外のアンデット族モンスターが存在しない場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"闇竜の黒騎士",h:"ぶらっくないと・おぶ・だーくどらごん",e:"Paladin of the Cursed Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：１ターンに１度、戦闘で破壊された相手の墓地のレベル４以下のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アンデットワールド",h:"あんでっとわーるど",e:"Zombie World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター及び墓地のモンスターは全てアンデット族になる。\n②：お互いはアンデット族モンスターしかアドバンス召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮の鉄壁",h:"おうきゅうのてっぺき",e:"Imperial Iron Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いにカードを除外できない。",li:"",lk:[],ta:[],al:[]},
  {n:"閻魔の裁き",h:"えんまのさばき",e:"Enma's Judgment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターの特殊召喚に成功した時に発動できる。\nそのモンスターを破壊する。\nその後、以下の効果を適用できる。\n\n●自分の墓地からアンデット族モンスター５体を除外し、手札・デッキからレベル７以上のアンデット族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神聖騎士パーシアス",h:"ほーりーないとぱーしあす",e:"Avenging Knight Parshath",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\n①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"メンタル・カウンセラー リリー",h:"めんたる・かうんせらー　りりー",e:"Counselor Lily",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1500,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、５００ライフポイントを払う事でこのカードをシンクロ素材としたシンクロモンスターの攻撃力は、このターンのエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォールクリエイター",h:"うぉーるくりえいたー",e:"Grapple Blocker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカードが召喚に成功した時、相手フィールド上に存在するモンスター１体を選択する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、選択されたモンスターは攻撃する事はできずリリースする事もできない。\nこのカードのコントローラーは自分のエンドフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"念動増幅装置",h:"ねんどうぞうふくそうち",e:"Telekinetic Charging Cell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"サイキック族モンスターにのみ装備可能。\n装備モンスターの効果を発動するために払うライフポイントが必要なくなる。\n装備モンスターが破壊される事によってこのカードが墓地へ送られた時、１０００ライフポイントを払う事でこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"光の援軍",h:"ひかりのえんぐん",e:"Charge of the Light Brigade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキの上からカードを３枚墓地へ送って発動できる。\nデッキからレベル４以下の「ライトロード」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒール・ウェーバー",h:"ひーる・うぇーばー",e:"Healing Wave Generator",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"このカード以外の自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターのレベル×１００ライフポイント回復する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ターボ・シンクロン",h:"たーぼ・しんくろん",e:"Turbo Synchron",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:500,tx:"①：このカードが攻撃表示モンスターに攻撃宣言した時に発動できる。\n攻撃対象モンスターを守備表示にする。\n②：このカードの攻撃で自分が戦闘ダメージを受けた時に発動できる。\n受けた戦闘ダメージの数値以下の攻撃力のモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マッド・デーモン",h:"まっど・でーもん",e:"Mad Archfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：攻撃表示のこのカードが攻撃対象に選択された場合に発動する。\nこのカードを守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"アイヴィ・ウォール",h:"あいう゛ぃ・うぉーる",e:"Wall of Ivy",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1200,tx:"リバース：相手フィールド上に「アイヴィトークン」（植物族・地・星１・攻／守０）１体を守備表示で特殊召喚する。\n「アイヴィトークン」が破壊された時、このトークンのコントローラーは３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"コピー・プラント",h:"こぴー・ぷらんと",e:"Copy Plant",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、このカード以外のフィールド上の植物族モンスター１体を選択して発動できる。\nこのカードのレベルはエンドフェイズ時まで、選択したモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"D・モバホン",h:"でぃふぉーまー・もばほん",e:"Morphtronic Celfon",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：サイコロを１回振り、出た目の数だけ自分のデッキの上からカードをめくる。\nその中からレベル４以下の「D」と名のついたモンスター１体を選び、召喚条件を無視して特殊召喚し、残りのカードはデッキに戻してシャッフルする。\nこの効果は１ターンに１度しか使用できない。\n●守備表示：サイコロを１回振り、出た目の数だけ自分のデッキの上からカードを確認して元の順番でデッキの上に戻す。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"D・マグネンU",h:"でぃふぉーまー・まぐねんゆー",e:"Morphtronic Magnen",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：相手フィールド上に表側表示のモンスターが存在する場合、このカードは攻撃力が一番高いモンスターしか攻撃対象に選択できない。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、相手は他のモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"D・チャッカン",h:"でぃふぉーまー・ちゃっかん",e:"Morphtronic Datatron",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：自分フィールド上に存在するモンスター１体をリリースする事で相手ライフに６００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。\n●守備表示：１ターンに１度、相手ライフに３００ポイントダメージを与える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ラジカッセン",h:"でぃふぉーまー・らじかっせん",e:"Morphtronic Boomboxen",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\n●守備表示：１ターンに１度、自分フィールド上の「D」と名のついたモンスターが攻撃対象に選択された時、その攻撃を無効にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"D・キャメラン",h:"でぃふぉーまー・きゃめらん",e:"Morphtronic Cameran",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードが戦闘によって破壊された時、自分の手札・墓地から「D・キャメラン」以外のレベル４以下の「D」と名のついたモンスター１体を選んで特殊召喚できる。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、お互いにフィールド上の「D」と名のついたモンスターをカードの効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ラジオン",h:"でぃふぉーまー・らじおん",e:"Morphtronic Radion",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:900,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「D」と名のついたモンスターの攻撃力は８００ポイントアップする。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「D」と名のついたモンスターの守備力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"D・クロックン",h:"でぃふぉーまー・くろっくん",e:"Morphtronic Clocken",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:1100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードに乗っているディフォーマーカウンター１つにつき、このカードの攻撃力は５００ポイントアップする。\n●守備表示：１ターンに１度、このカードにディフォーマーカウンターを１つ置く事ができる。\nこのカードをリリースする事で、このカードに乗っているディフォーマーカウンターの数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガジェット・トレーラー",h:"がじぇっと・とれーらー",e:"Gadget Hauler",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"１ターンに１度、手札から「D」と名のついたモンスターを任意の枚数墓地へ送る事ができる。\nこの効果で墓地に送ったモンスター１体につき、このカードの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガジェット・ドライバー",h:"がじぇっと・どらいばー",e:"Gadget Driver",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードを手札から墓地へ送って発動する。\n自分フィールド上に表側表示で存在する「D」と名のついたモンスターを任意の数だけ選択して表示形式を変更する。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サーチ・ストライカー",h:"さーち・すとらいかー",e:"Search Striker",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する事ができる。\nこの効果を使用した場合、このカードはバトルフェイズ終了時に守備表示になり、次の自分のターンのエンドフェイズ時まで表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"チェイス・スカッド",h:"ちぇいす・すかっど",e:"Pursuit Chaser",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"フィールド上に守備表示で存在するモンスターが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"C・リペアラー",h:"ちぇーん・りぺあらー",e:"Iron Chain Repairman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、相手ライフに３００ポイントダメージを与える。\n１ターンに１度、自分の墓地に存在する「C・リペアラー」以外の「C」と名のついたレベル４以下のモンスター１体を特殊召喚する事ができる。\nこの効果を発動するターンこのカードは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"C・スネーク",h:"ちぇーん・すねーく",e:"Iron Chain Snake",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"自分のメインフェイズ時に装備カード扱いとして相手フィールド上に存在する表側表示モンスター１体に装備する事ができる。\nこのカードを装備したモンスターの攻撃力・守備力は８００ポイントダウンする。\n装備モンスターが戦闘によって破壊され墓地へ送られた時、装備モンスターのコントローラーのデッキの上からそのモンスターのレベルと同じ枚数のカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"C・シューター",h:"ちぇーん・しゅーたー",e:"Iron Chain Blaster",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"自分フィールド上に存在する「C」と名のついたモンスター１体を墓地へ送って発動する。\n相手ライフに８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"C・コイル",h:"ちぇーん・こいる",e:"Iron Chain Coil",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:1600,tx:"自分フィールド上に存在する「C」と名のついたモンスター１体を選択して発動する。\nそのモンスターの攻撃力・守備力は３００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・インジェクター",h:"ぱわー・いんじぇくたー",e:"Power Injector",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"６００ライフポイントを払って発動する。\nそのターン中フィールド上に表側表示で存在する全てのサイキック族モンスターの攻撃力は５００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ストーム・サモナー",h:"すとーむ・さもなー",e:"Storm Caller",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードが自分フィールド上に表側表示で存在する限り、このカード以外のサイキック族モンスターが戦闘によって破壊した相手モンスターは墓地へ送らず、相手のデッキの一番上に置く事ができる。\nこのカードがカードの効果によって破壊された時、このカードのコントローラーはこのカードの攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコジャンパー",h:"さいこじゃんぱー",e:"Psychic Jumper",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1500,tx:"１０００ライフポイントを払って相手フィールド上に表側表示で存在するモンスター１体を選択し、そのモンスターと「サイコジャンパー」以外の自分フィールド上に表側表示で存在するサイキック族モンスター１体のコントロールを入れ替える。\n選択されたモンスターは、このターン表示形式の変更はできない。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ボタニティ・ガール",h:"ぼたにてぃ・がーる",e:"Botanical Girl",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1300,de:1100,tx:"このカードがフィールド上から墓地へ送られた時、デッキから守備力１０００以下の植物族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"椿姫ティタニアル",h:"つばきてぃたにある",e:"Tytannial, Princess of Camellias",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2800,de:2600,tx:"①：フィールドのカードを対象とする魔法・罠・モンスターの効果が発動した時、自分フィールドの表側表示の植物族モンスター１体をリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神レイヴン",h:"まごうしんれいう゛ん",e:"Fabled Raven",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分の手札を任意の枚数捨て、その枚数分このカードのレベルをエンドフェイズ時まで上げる。\nこのカードの攻撃力はエンドフェイズ時まで、この効果によって捨てた手札の枚数×４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"共闘するランドスターの剣士",h:"きょうとうするらんどすたーのけんし",e:"Comrade Swordsman of Landstar",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する戦士族モンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"タイガードラゴン",h:"たいがーどらごん",e:"Tiger Dragon",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"ドラゴン族モンスターをリリースしてこのカードのアドバンス召喚に成功した時、相手の魔法＆罠カードゾーンにセットされたカードを２枚まで破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ターボ・ウォリアー",h:"たーぼ・うぉりあー",e:"Turbo Warrior",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"「ターボ・シンクロン」＋チューナー以外のモンスター１体以上\nレベル６以上のシンクロモンスターを攻撃対象としたこのカードの攻撃宣言時、攻撃対象モンスターの攻撃力をダメージステップ終了時まで半分にする。\nフィールド上のこのカードはレベル６以下の効果モンスターの効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・ローズ・ドラゴン",h:"ぶらっく・ろーず・どらごん",e:"Black Rose Dragon",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\nフィールドのカードを全て破壊する。\n②：１ターンに１度、自分の墓地から植物族モンスター１体を除外し、相手フィールドの守備表示モンスター１体を対象として発動できる。\nその相手の守備表示モンスターを表側攻撃表示にし、その攻撃力はターン終了時まで０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"C・ドラゴン",h:"ちぇーん・どらごん",e:"Iron Chain Dragon",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のメインフェイズ時に発動できる。\n自分の墓地の「C」と名のついたモンスターを全てゲームから除外する。\nこのカードの攻撃力はエンドフェイズ時まで、この効果で除外したモンスターの数×２００ポイントアップする。\nまた、このカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ヘルストランサー",h:"さいこ・へるすとらんさー",e:"Psychic Lifetrancer",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分の墓地のサイキック族モンスター１体をゲームから除外して発動できる。\n自分は１２００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"カード・フリッパー",h:"かーど・ふりっぱー",e:"Card Rotator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚墓地へ送って発動する。\n相手フィールド上に存在する全てのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"偽りの種",h:"いつわりのたね",e:"Seed of Deception",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"手札からレベル２以下の植物族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"薔薇の刻印",h:"ばらのこくいん",e:"Mark of the Rose",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分の墓地から植物族モンスター１体を除外し、相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\n①：装備モンスターのコントロールを得る。\n②：自分エンドフェイズに発動する。\nこのカードの①の効果は次の自分スタンバイフェイズまで無効になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・ガーデン",h:"ぶらっく・がーでん",e:"Black Garden",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：「ブラック・ガーデン」の効果以外でモンスターが表側表示で召喚・特殊召喚される度に発動する。\nそのモンスターの攻撃力を半分にする。\nその後、そのコントローラーは、相手のフィールドに「ローズ・トークン」（植物族・闇・星２・攻／守８００）１体を攻撃表示で特殊召喚する。\n②：フィールドの全ての植物族モンスターの攻撃力の合計と同じ攻撃力を持つ、自分の墓地のモンスター１体を対象として発動できる。\nこのカード及びフィールドの植物族モンスターを全て破壊する。\n全て破壊した場合、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"百機夜工",h:"ひゃっきやこう",e:"Factory of 100 Machines",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「D」と名のついたモンスターを全てゲームから除外する。\n自分フィールド上に表側表示で存在する機械族モンスター１体の攻撃力はこのターンのエンドフェイズ時まで、この効果で除外したモンスター１体につき２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"D・スピードユニット",h:"でぃふぉーまー・すぴーどゆにっと",e:"Morphtronic Accelerator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の「D」と名のついたモンスター１体をデッキに戻してシャッフルする。\nその後、フィールド上のカード１枚を選択して破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"D・コード",h:"でぃふぉーまー・こーど",e:"Morphtronic Cord",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D」と名のついたモンスターにのみ装備可能。\n装備モンスターの表示形式が変更される度に、フィールド上に存在する魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"D・パワーユニット",h:"でぃふぉーまー・ぱわーゆにっと",e:"Morphtronic Engine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D」と名のついたレベル３のモンスターにのみ装備可能。\n装備モンスターの元々の攻撃力を倍にする。\n発動後２回目の自分のスタンバイフェイズ時にこのカードを破壊し、自分は装備モンスターの元々の攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ポイズン・チェーン",h:"ぽいずん・ちぇーん",e:"Poison Chain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のターンに戦闘を行っていない場合、エンドフェイズ時に自分フィールド上に表側表示で存在する「C」と名のついたモンスターの数だけ、相手のデッキの上からカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"パラライズ・チェーン",h:"ぱららいず・ちぇーん",e:"Paralyzing Chain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"カードの効果によって相手のデッキからカードが墓地へ送られる度に、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アポート",h:"あぽーと",e:"Teleport",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、相手フィールドにのみモンスターが存在する場合、８００LPを払って発動できる。\n手札からサイキック族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"最古式念導",h:"さいこしきねんどう",e:"Psychokinesis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にサイキック族モンスターが存在する場合、フィールド上のカード１枚を選択して発動できる。\n選択したカードを破壊し、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"フレグランス・ストーム",h:"ふれぐらんす・すとーむ",e:"Fragrance Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの植物族モンスター１体を対象として発動できる。\nその植物族モンスターを破壊し、自分はデッキから１枚ドローする。\nそのドローしたカードが植物族モンスターだった場合、さらにそのカードをお互いに確認して自分はデッキから１枚ドローできる。",li:"",lk:[],ta:[],al:[]},
  {n:"世界樹",h:"せかいじゅ",e:"The World Tree",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上の植物族モンスターが破壊される度に、このカードにフラワーカウンターを１つ置く。\nまた、このカードに乗っているフラワーカウンターを任意の個数取り除いて以下の効果を発動できる。\n●１つ：フィールド上の植物族モンスター１体を選択し、その攻撃力・守備力をエンドフェイズ時まで４００ポイントアップする。\n●２つ：フィールド上のカード１枚を選択して破壊する。\n●３つ：自分の墓地の植物族モンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"不死式冥界砲",h:"ふししきめいかいほう",e:"Everliving Underworld Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上にアンデット族モンスターが特殊召喚された時、相手ライフに８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法族の里",h:"まほうぞくのさと",e:"Secret Village of the Spellcasters",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにのみ魔法使い族モンスターが存在する場合、相手は魔法カードを発動できない。\n②：自分フィールドに魔法使い族モンスターが存在しない場合、自分は魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"Ωメガネ",h:"おめがめがね",e:"Omega Goggles",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードは自分フィールド上のモンスターのみ装備可能。\n１ターンに１度、相手の手札をランダムに１枚選択して確認する。\nこの効果を発動するターン、装備モンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"バトルマニア",h:"ばとるまにあ",e:"Battle Mania",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのスタンバイフェイズ時に発動する事ができる。\n相手フィールド上に表側表示で存在するモンスターは全て攻撃表示になり、このターン表示形式を変更する事はできない。\nまた、このターン攻撃可能な相手モンスターは攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"コンフュージョン・チャフ",h:"こんふゅーじょん・ちゃふ",e:"Confusion Chaff",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"１度のバトルフェイズ中に２回目の直接攻撃が宣言された時に発動する事ができる。\nその相手モンスターは、直接攻撃した１体目の相手モンスターと戦闘しダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急同調",h:"きんきゅうどうちょう",e:"Urgent Tuning",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分・相手のバトルフェイズに発動できる。\nSモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・ストライク",h:"しんくろ・すとらいく",e:"Synchro Strike",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"シンクロ召喚したモンスター１体の攻撃力はエンドフェイズ時まで、シンクロ素材にしたモンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"プライドの咆哮",h:"ぷらいどのほうこう",e:"Prideful Roar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"戦闘ダメージ計算時、自分のモンスターの攻撃力が相手モンスターより低い場合、その攻撃力の差分のライフポイントを払って発動する。\nダメージ計算時のみ、自分のモンスターの攻撃力は相手モンスターとの攻撃力の差の数値＋３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"リバイバル・ギフト",h:"りばいばる・ぎふと",e:"Revival Gift",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在するチューナー１体を選択し特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n相手フィールド上に「ギフト・デモン・トークン」（悪魔族・闇・星３・攻／守１５００）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊神の系譜",h:"はかいしんのけいふ",e:"Lineage of Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に守備表示で存在するモンスターを破壊したターン、自分フィールド上に表側表示で存在するレベル８のモンスター１体を選択して発動する。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドッペル・ゲイナー",h:"どっぺる・げいなー",e:"Doppelganger",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上に存在するモンスターの効果によってダメージを受けた時、受けたダメージと同じダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディフォーム",h:"でぃふぉーむ",e:"Morphtransition",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃モンスター１体の攻撃を無効にし、攻撃対象に選択された「D」と名のついたモンスター１体の表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ゲイザー",h:"でぃふぉーまー・げいざー",e:"Morphtronic Monitron",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「D」と名のついたモンスターが召喚・反転召喚・特殊召喚に成功した時、そのモンスターを表側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・トリガー",h:"さいこ・とりがー",e:"Psychic Trigger",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが相手より下の場合に発動する事ができる。\n自分の墓地に存在するサイキック族モンスター２体をゲームから除外し、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ポリノシス",h:"ぽりのしす",e:"Pollinosis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上の植物族モンスター１体をリリースして発動できる。\n魔法・罠カードの発動、モンスターの召喚・特殊召喚のどれか１つを無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"竹頭木屑",h:"ちくとうぼくせつ",e:"Bamboo Scrap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する植物族モンスター１体をリリースして発動する。\n相手フィールド上に「プラントークン」（植物族・地・星１・攻８００／守５００）２体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"植物連鎖",h:"しょくぶつれんさ",e:"Plant Food Chain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力５００ポイントアップの装備カードとなり、自分フィールド上の植物族モンスター１体に装備する。\n装備カードとなったこのカードがカードの効果によって破壊された場合、自分の墓地の植物族モンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"王墓の罠",h:"おうぼのわな",e:"Trap of the Imperial Tomb",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の墓地から自分フィールドにアンデット族モンスターが特殊召喚された時、フィールドのカード２枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"DNA定期健診",h:"ＤＮＡていきけんしん",e:"DNA Checkup",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に裏側表示で存在するモンスター１体を選択して発動する。\n相手はモンスターの属性を２つ宣言する。\n選択したモンスターをめくって確認し宣言された属性だった場合、相手はデッキからカードを２枚ドローする。\n違った場合、自分はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"御前試合",h:"ごぜんじあい",e:"Gozen Match",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いのフィールドにそれぞれ１種類の属性のモンスターしか表側表示で存在できない。\nお互いのプレイヤーは自身のフィールドの表側表示モンスターの属性が２種類以上の場合には１種類になるように墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"大落とし穴",h:"おおおとしあな",e:"Giant Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"同時に２体以上のモンスターが特殊召喚に成功した時に発動できる。\nフィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"火車",h:"かしゃ",e:"Kasha",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:1000,tx:"このカードは通常召喚できない。\n自分フィールドのアンデット族モンスターが２体以上の場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\nこのカード以外のフィールドのモンスターを全て持ち主のデッキに戻す。\n元々の種族がアンデット族の表側表示モンスターがデッキに戻った場合、このカードの攻撃力はそのモンスターの数×１０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"平行世界融合",h:"ぱられる・わーるど・ふゅーじょん",e:"Parallel World Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを特殊召喚できない。\n①：除外されている、「E・HERO」融合モンスターカードによって決められた自分の融合素材モンスターをデッキに戻し、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"裏ガエル",h:"うらがえる",e:"Flip Flop Frog",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、自分フィールド上の「ガエル」と名のついたモンスターの数まで、相手フィールド上のモンスターを選んで持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ビートル",h:"なちゅる・びーとる",e:"Naturia Beetle",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:400,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、魔法カードが発動する度に、このカードの元々の攻撃力と元々の守備力を入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ロック",h:"なちゅる・ろっく",e:"Naturia Rock",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"罠カードが発動した時、デッキの上からカードを１枚墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ガーディアン",h:"なちゅる・がーでぃあん",e:"Naturia Guardian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"相手がモンスターの召喚に成功した時、このカードの攻撃力はエンドフェイズ時まで３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ナーブ",h:"なちゅる・なーぶ",e:"Naturia Vein",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:300,tx:"相手が魔法・罠カードを発動した時、自分フィールド上の「ナチュル」と名のついたモンスター１体とこのカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・ヒート",h:"じぇねくす・ひーと",e:"Genex Furnace",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2000,de:1300,tx:"自分フィールド上に「ジェネクス・コントローラー」が存在する場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・ガイア",h:"じぇねくす・がいあ",e:"Genex Gaia",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに自分フィールド上に表側表示で存在する「ジェネクス・コントローラー」１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スペア・ジェネクス",h:"すぺあ・じぇねくす",e:"Genex Spare",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1200,tx:"１ターンに１度、自分フィールド上にこのカード以外の「ジェネクス」と名のついたモンスターが存在する場合に発動できる。\nこのカードのカード名はエンドフェイズ時まで「ジェネクス・コントローラー」として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"タービン・ジェネクス",h:"たーびん・じぇねくす",e:"Genex Turbine",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「ジェネクス」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・ドクター",h:"じぇねくす・どくたー",e:"Genex Doctor",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上に表側表示で存在する「ジェネクス・コントローラー」１体をリリースして発動できる。\nフィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソーラー・ジェネクス",h:"そーらー・じぇねくす",e:"Genex Solar",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:1500,tx:"このカードは「ジェネクス」と名のついたモンスター１体をリリースして召喚できる。\nまた、自分フィールド上に表側表示で存在する「ジェネクス」と名のついたモンスターが墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の大僧正",h:"ひょうけっかいのだいそうじょう",e:"Dai-sojo of the Ice Barrier",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:2200,tx:"①：このカードが召喚・反転召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「氷結界」モンスターは魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の交霊師",h:"ひょうけっかいのこうれいし",e:"Medium of the Ice Barrier",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"①：相手フィールドのカードの数が自分フィールドのカードより４枚以上多い場合、このカードは手札から特殊召喚できる。\n②：このカードがモンスターゾーンに存在する限り、相手は１ターンに１度しか魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の幼怪鳥",h:"みすと・ばれーのようかいちょう",e:"Mist Valley Baby Roc",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:600,tx:"①：このカードが手札から墓地へ送られた時に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の執行者",h:"みすと・ばれーのしっこうしゃ",e:"Mist Valley Executor",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカードが召喚に成功した時、フィールド上に表側表示で存在する魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・グルニカ",h:"ふれむべる・ぐるにか",e:"Flamvell Grunika",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターのレベル×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・ベビー",h:"ふれむべる・べびー",e:"Flamvell Baby",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"自分のメインフェイズ時にこのカードを手札から墓地へ送って発動できる。\n自分フィールド上の炎属性モンスター１体を選択して攻撃力を４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"A・マインド",h:"あーりー・まいんど",e:"Ally Mind",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["チューナー","通常"],ma:"",tp:"",at:1800,de:1400,tx:"A・O・Jの思考回路を強化するために開発された高性能ユニット。\nワーム星雲より飛来した隕石から採取された物質が埋め込まれており、高いチューニング能力を誇る。\nその未知なるパワーの謎は未だ解明されていない。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J ブラインド・サッカー",h:"あーりー・おぶ・じゃすてぃす　ぶらいんど・さっかー",e:"Ally of Justice Nullfier",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードと戦闘を行った光属性モンスターの効果をダメージ計算後に無効化する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J リサーチャー",h:"あーりー・おぶ・じゃすてぃす　りさーちゃー",e:"Ally of Justice Searcher",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:100,tx:"１ターンに１度、手札を１枚捨てて発動できる。\n相手フィールド上に裏側守備表示で存在するモンスター１体を選択して表側攻撃表示にする。\nこの時、リバース効果モンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J エネミー・キャッチャー",h:"あーりー・おぶ・じゃすてぃす　えねみー・きゃっちゃー",e:"Ally of Justice Enemy Catcher",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカードが召喚に成功した時、相手フィールド上に裏側守備表示で存在するモンスター１体を選択してエンドフェイズ時までコントロールを得る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J サンダー・アーマー",h:"あーりー・おぶ・じゃすてぃす　さんだー・あーまー",e:"Ally of Justice Thunder Armor",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:2200,tx:"このカードは特殊召喚できない。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上の「A・O・J」と名のついたモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J コズミック・クローザー",h:"あーりー・おぶ・じゃすてぃす　こずみっく・くろーざー",e:"Ally of Justice Cosmic Gateway",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"相手フィールド上に、光属性モンスターを含む２体以上のモンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・リンクス",h:"わーむ・りんくす",e:"Worm Linx",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1000,tx:"リバース：このカードがフィールド上に表側表示で存在する場合、お互いのエンドフェイズ毎に自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ミリディス",h:"わーむ・みりでぃす",e:"Worm Millidith",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:1600,tx:"リバース：このカードを装備カード扱いとして相手モンスター１体に装備できる。\nお互いのスタンバイフェイズ毎に、装備モンスターのコントローラーに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ノーブル",h:"わーむ・のーぶる",e:"Worm Noble",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:2400,tx:"リバース：このカードが相手モンスターの攻撃によってリバースした場合、その相手モンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ビースト",h:"なちゅる・びーすと",e:"Naturia Beast",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1700,tx:"地属性チューナー＋チューナー以外の地属性モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、デッキの上からカードを２枚墓地へ送る事で、魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の虎王 ドゥローレン",h:"ひょうけっかいのこおう　どぅろーれん",e:"Dewloren, Tiger King of the Ice Barrier",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1400,tx:"チューナー＋チューナー以外の水属性モンスター１体以上\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドの表側表示のカードを任意の数だけ対象として発動できる。\nその自分の表側表示のカードを持ち主の手札に戻す。\nこのカードの攻撃力はターン終了時まで、この効果で手札に戻ったカードの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サーマル・ジェネクス",h:"さーまる・じぇねくす",e:"Thermal Genex",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"「ジェネクス・コントローラー」＋チューナー以外の炎属性モンスター１体以上\nこのカードの攻撃力は自分の墓地の炎属性モンスターの数×２００ポイントアップする。\nこのカードが戦闘によって相手モンスターを破壊した場合、自分の墓地の「ジェネクス」と名のついたモンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジオ・ジェネクス",h:"じお・じぇねくす",e:"Geo Genex",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1800,de:2800,tx:"「ジェネクス・コントローラー」＋チューナー以外の地属性モンスター１体以上\n１ターンに１度、自分フィールド上にレベル４以下の「ジェネクス」と名のついたモンスターが存在する限り、このカードの元々の攻撃力と元々の守備力をエンドフェイズ時まで入れ替える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J フィールド・マーシャル",h:"あーりー・おぶ・じゃすてぃす　ふぃーるど・まーしゃる",e:"Ally of Justice Field Marshal",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:2600,tx:"チューナー＋チューナー以外のモンスター２体以上\nこのカードはシンクロ召喚でしか特殊召喚できない。\nこのカードの攻撃によって裏側守備表示のモンスターを破壊し墓地へ送った時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"創世竜",h:"じぇねしっくどらごん",e:"Genesis Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"１ターンに１度、手札からドラゴン族モンスター１体を墓地へ送る事で、自分の墓地のドラゴン族モンスター１体を選択して手札に加える。\nこのカードがフィールド上から墓地へ送られた時、自分の墓地のドラゴン族モンスターを全てデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"星見獣ガリス",h:"ほしみじゅうがりす",e:"Gallis the Star Beast",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"手札にあるこのカードを相手に見せて発動する。\n自分のデッキの一番上のカードを墓地へ送り、そのカードがモンスターだった場合、そのモンスターのレベル×２００ポイントダメージを相手ライフに与えこのカードを特殊召喚する。\nそのカードがモンスター以外だった場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の飛竜",h:"れっどあいず・わいばーん",e:"Red-Eyes Wyvern",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカードの効果を発動するターン、自分は通常召喚できない。\n①：自分エンドフェイズに墓地のこのカードを除外し、自分の墓地の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークネス・デストロイヤー",h:"だーくねす・ですとろいやー",e:"Darkness Destroyer",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:1800,tx:"このカードは特殊召喚できない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"白夜の女王",h:"ほわいとないつ・くぃーん",e:"White Night Queen",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードは特殊召喚できない。\n１ターンに１度、フィールド上にセットされたカード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マッシブ・ウォリアー",h:"まっしぶ・うぉりあー",e:"Fortress Warrior",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"チューニング・サポーター",h:"ちゅーにんぐ・さぽーたー",e:"Tuningware",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"①：フィールドのこのカードをS素材とする場合、このカードはレベル２モンスターとして扱う事ができる。\n②：このカードがS素材として墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"アームズ・エイド",h:"あーむず・えいど",e:"Armory Arm",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1800,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に装備カード扱いとしてモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターの攻撃力は１０００ポイントアップする。\nまた、装備モンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ギブ＆テイク",h:"ぎぶあんどていく",e:"Give and Take",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体と、自分フィールドの表側表示モンスター１体を対象として発動できる。\n対象の墓地のモンスターを相手フィールドに守備表示で特殊召喚し、対象のフィールドのモンスターのレベルはターン終了時まで、その特殊召喚したモンスターのレベル分だけ上がる。",li:"",lk:[],ta:[],al:[]},
  {n:"リミッター・ブレイク",h:"りみったー・ぶれいく",e:"Limiter Overload",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このカードが墓地へ送られた場合に発動する。\n自分の手札・デッキ・墓地から「スピード・ウォリアー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シールド・ウィング",h:"しーるど・うぃんぐ",e:"Shield Wing",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:900,tx:"このカードは１ターンに２度まで、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"バイス・ドラゴン",h:"ばいす・どらごん",e:"Vice Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2400,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"Tyr, the Vanquishing Warlord",h:"Tyr, the Vanquishing Warlord",e:"Tyr, the Vanquishing Warlord",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"This card cannot be Special Summoned. To Tribute Summon this card, you must Tribute 3 Fairy-Type monsters. If this card attacks your opponent directly and reduces their Life Points to 0, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"Lorelei, the Symphonic Arsenal",h:"Lorelei, the Symphonic Arsenal",e:"Lorelei, the Symphonic Arsenal",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"This card cannot be Special Summoned. To Tribute Summon this card, you must Tribute 3 Machine-Type monsters. If this card attacks your opponent directly and reduces their Life Points to 0, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"サモン・リアクター・AI",h:"さもん・りあくたー・えーあい",e:"Summon Reactor ・SK",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手フィールド上にモンスターが召喚・反転召喚・特殊召喚された時、相手ライフに８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。\nこの効果を使用したターンのバトルフェイズ時、相手モンスター１体の攻撃を無効にできる。\nまた、自分フィールド上に表側表示で存在する、このカードと「トラップ・リアクター・RR」「マジック・リアクター・AID」をそれぞれ１体ずつ墓地へ送る事で、自分の手札・デッキ・墓地から「ジャイアント・ボマー・エアレイド」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャイアント・ボマー・エアレイド",h:"じゃいあんと・ぼまー・えあれいど",e:"Flying Fortress SKY FIRE",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n「サモン・リアクター・AI」の効果でのみ特殊召喚できる。\n１ターンに１度、手札を１枚墓地へ送る事で、相手フィールド上のカード１枚を選択して破壊する。\nまた、相手のターンに１度、以下の効果から１つを選択して発動できる。\n●相手がモンスターの召喚・特殊召喚に成功した時に発動できる。\nそのモンスターを破壊し、相手ライフに８００ポイントダメージを与える。\n●相手がカードをセットした時に発動できる。\nそのカードを破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"トラップ・リアクター・RR",h:"とらっぷ・りあくたー・だぶるあーる",e:"Trap Reactor ・Y FI",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"１ターンに１度、相手が罠カードを発動した時に発動できる。\nその罠カードを破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・ボンバー",h:"ぶらっく・ぼんばー",e:"Black Salvo",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1100,tx:"このカードが召喚に成功した時、自分の墓地から機械族・闇属性・レベル４のモンスター１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・リアクター・AID",h:"まじっく・りあくたー・えいど",e:"Spell Reactor ・RE",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"１ターンに１度、相手が魔法カードを発動した時に発動できる。\nその魔法カードを破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ローズ・テンタクルス",h:"ろーず・てんたくるす",e:"Rose Tentacles",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードは特殊召喚できない。\n自分のバトルフェイズ開始時に相手フィールド上に表側表示で植物族モンスターが存在する場合、このターンこのカードは通常の攻撃に加えて、その植物族モンスターの数だけ攻撃する事ができる。\nこのカードが戦闘によって植物族モンスターを破壊した場合、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ターレット・ウォリアー",h:"たーれっと・うぉりあー",e:"Turret Warrior",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカードは自分フィールド上の戦士族モンスター１体をリリースして手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの攻撃力は、リリースしたモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デブリ・ドラゴン",h:"でぶり・どらごん",e:"Debris Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードをS素材とする場合、ドラゴン族モンスターのS召喚にしか使用できず、他のS素材モンスターは全てレベル４以外のモンスターでなければならない。\n①：このカードが召喚に成功した時、自分の墓地の攻撃力５００以下のモンスター１体を対象として発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイパー・シンクロン",h:"はいぱー・しんくろん",e:"Hyper Synchron",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードがドラゴン族モンスターのシンクロ召喚に使用され墓地へ送られた場合、このカードをシンクロ素材としたシンクロモンスターは攻撃力が８００ポイントアップし、エンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・デーモンズ・ドラゴン／バスター",h:"れっど・でーもんず・どらごんすらっしゅばすたー",e:"Red Dragon Archfiend/Assault Mode",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:2500,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが攻撃した場合、ダメージ計算後にこのカード以外のフィールド上のモンスターを全て破壊する。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「レッド・デーモンズ・ドラゴン」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トラップ・イーター",h:"とらっぷ・いーたー",e:"Trap Eater",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードは通常召喚できない。\n相手フィールド上に表側表示で存在する罠カード１枚を墓地へ送った場合のみ特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ツイン・ブレイカー",h:"ついん・ぶれいかー",e:"Twin-Sword Marauder",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが守備表示モンスターを攻撃した場合、もう１度だけ続けて攻撃する事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・リペアラー",h:"だーく・りぺあらー",e:"Dark Tinker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1300,tx:"このカードが自分フィールド上から墓地へ送られた時、自分のデッキの一番上のカードを確認してデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－疾風のゲイル",h:"ぶらっくふぇざー－しっぷうのげいる",e:"Blackwing - Gale the Whirlwind",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:400,tx:"①：自分フィールドに「BF－疾風のゲイル」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力を半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－黒槍のブラスト",h:"ぶらっくふぇざー－くろやりのぶらすと",e:"Blackwing - Bora the Spear",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"①：自分フィールドに「BF－黒槍のブラスト」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－暁のシロッコ",h:"ぶらっくふぇざー－あかつきのしろっこ",e:"Blackwing - Sirocco the Dawn",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2000,de:900,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードはリリースなしで通常召喚できる。\n②：１ターンに１度、自分メインフェイズ１に自分フィールドの「BF」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのモンスター以外のフィールドの「BF」モンスターの攻撃力の合計分アップする。\nこの効果を発動するターン、対象のモンスターしか攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"夜薔薇の騎士",h:"ないとろーずないと",e:"Twilight Rose Knight",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の植物族モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手は植物族モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ボードン",h:"でぃふぉーまー・ぼーどん",e:"Morphtronic Boarden",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「D」と名のついたモンスターは相手プレイヤーに直接攻撃できる。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、このカード以外の自分フィールド上の「D」と名のついたモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"D・パッチン",h:"でぃふぉーまー・ぱっちん",e:"Morphtronic Slingen",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、「D・パッチン」以外の「D」と名のついたモンスター１体をリリースする事でフィールド上のカード１枚を破壊する。\n●守備表示：このカードが破壊される場合、代わりにこのカード以外の「D」と名のついたモンスター１体を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"デスカイザー・ドラゴン／バスター",h:"ですかいざー・どらごんすらっしゅばすたー",e:"Doomkaiser Dragon/Assault Mode",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:2000,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが特殊召喚に成功した時、自分・相手の墓地からアンデット族モンスターを任意の数だけ選択して自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターンのエンドフェイズ時に破壊される。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「デスカイザー・ドラゴン」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイパーサイコガンナー／バスター",h:"はいぱーさいこがんなーすらっしゅばすたー",e:"Hyper Psychic Blaster/Assault Mode",t:"monster",a:"地",l:11,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3000,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが相手モンスターと戦闘を行ったダメージステップ終了時、その相手モンスターの守備力分のダメージを相手ライフに与え、その攻撃力分だけ自分のライフポイントを回復する。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「ハイパーサイコガンナー」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アーカナイト・マジシャン／バスター",h:"あーかないと・まじしゃんすらっしゅばすたー",e:"Arcanite Magician/Assault Mode",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:900,de:2300,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが特殊召喚に成功した時、このカードに魔力カウンターを２つ置く。\nこのカードの攻撃力は、このカードに乗っている魔力カウンターの数×１０００ポイントアップする。\nこのカードに乗っている魔力カウンターを２つ取り除く事で、相手フィールド上のカードを全て破壊する。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「アーカナイト・マジシャン」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アーケイン・ファイロ",h:"あーけいん・ふぁいろ",e:"Arcane Apprentice",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:400,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分のデッキから「バスター・モード」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・マーセナリ",h:"ばすたー・まーせなり",e:"Assault Mercenary",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"自分の手札または墓地に存在する「バスター・モード」１枚をデッキに戻す事で、相手フィールド上に存在する魔法・罠カード１枚を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・ビースト",h:"ばすたー・びーすと",e:"Assault Beast",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードを手札から墓地へ捨てて発動できる。\nデッキから「バスター・モード」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒翼の魔術師",h:"こくよくのまじゅつし",e:"Night Wing Sorceress",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、このカードのコントローラーは「バスター・モード」をセットしたターンに発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライフ・コーディネイター",h:"らいふ・こーでぃねいたー",e:"Lifeforce Harmonizer",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"相手が「ライフポイントにダメージを与える効果」を持つカードを発動した時、手札からこのカードを捨てる事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣サムニテ",h:"ぐらでぃあるびーすとさむにて",e:"Gladiator Beast Samnite",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキから「剣闘獣」と名のついたカード１枚を手札に加える事ができる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣サムニテ」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トマボー",h:"とまぼー",e:"Inmato",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカード以外のフィールド上の植物族モンスター１体が相手の魔法・罠カードの効果の対象になった時、このカードをリリースして発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"スキヤナー",h:"すきやなー",e:"Scanner",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"ゲームから除外されている相手のモンスター１体を選択して発動する。\nこのカードはエンドフェイズ時まで選択したモンスターと同名カードとして扱い、選択したモンスターと同じ属性・レベル・攻撃力・守備力になる。\nこの効果は１ターンに１度しか使用できない。\nこの効果が適用されたこのカードはフィールド上から離れた場合、ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"次元要塞兵器",h:"じげんようさいへいき",e:"Dimension Fortress Weapon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"このカードがフィールド上に表側表示で存在する限り、お互いにデッキからカードを墓地へ送る事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"砂漠の守護者",h:"でざーと・がーどなー",e:"Desert Protector",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカードの守備力は、フィールド上に存在する魔法・罠カードの枚数×３００ポイントアップする。\n自分フィールド上に存在する昆虫族モンスターが破壊される場合、代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クロスソード・ハンター",h:"くろすそーど・はんたー",e:"Cross-Sword Beetle",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上にこのカード以外の昆虫族モンスターが存在する場合、自分フィールド上に存在する昆虫族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ハチビー",h:"はちびー",e:"Bee List Soldier",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードと自分フィールド上に表側表示で存在する「ハチビー」以外の昆虫族モンスター１体をリリースして発動する。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トリプル・ヴァイパー",h:"とりぷる・う゛ぁいぱー",e:"Hydra Viper",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードは１度のバトルフェイズ中に３回攻撃する事ができる。\nこのカードは自分フィールド上に存在する水族モンスター１体をリリースしなければ攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・リベンジャー",h:"えーりあん・りべんじゃー",e:"Alien Overlord",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"このカードはフィールド上のAカウンターを２つ取り除き、手札から特殊召喚できる。\n１ターンに１度、相手フィールド上に表側表示で存在する全てのモンスターにAカウンターを１つ置く事ができる。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。\n「エーリアン・リベンジャー」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアンモナイト",h:"えーりあんもないと",e:"Alien Ammonite",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル４以下の「エーリアン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ダイブ・ボンバー",h:"だーく・だいぶ・ぼんばー",e:"Dark Strike Fighter",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n「ダーク・ダイブ・ボンバー」の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズ１に自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターのレベル×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－アーマード・ウィング",h:"ぶらっくふぇざー－あーまーど・うぃんぐ",e:"Blackwing Armor Master",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"「BF」チューナー＋チューナー以外のモンスター１体以上\n①：このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードがモンスターを攻撃したダメージステップ終了時に発動できる。\nそのモンスターに楔カウンターを１つ置く（最大１つまで）。\n③：相手フィールドの楔カウンターを全て取り除いて発動できる。\n楔カウンターが置かれていた全てのモンスターの攻撃力・守備力をターン終了時まで０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイパーサイコガンナー",h:"はいぱーさいこがんなー",e:"Hyper Psychic Blaster",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のサイキック族モンスター１体以上\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードが守備表示モンスターを攻撃したダメージステップ終了時、その守備力を攻撃力が超えていれば、その数値だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーカナイト・マジシャン",h:"あーかないと・まじしゃん",e:"Arcanite Magician",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:400,de:1800,tx:"チューナー＋チューナー以外の魔法使い族モンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\nこのカードに魔力カウンターを２つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×１０００アップする。\n③：自分フィールドの魔力カウンターを１つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"宇宙砦ゴルガー",h:"そらとりでごるがー",e:"Cosmic Fortress Gol'gar",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1800,tx:"「エーリアンモナイト」＋チューナー以外の「エーリアン」モンスター１体以上\n①：１ターンに１度、フィールドの表側表示の魔法・罠カードを任意の数だけ対象として発動できる。\nその表側表示のカードを持ち主の手札に戻す。\nその後、手札に戻った数だけフィールドの表側表示モンスターにAカウンターを置く。\n②：１ターンに１度、フィールドのAカウンターを２つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"プリベント・スター",h:"ぷりべんと・すたー",e:"Prevention Star",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスターの表示形式が表側守備表示に変更されたターン、そのモンスターにのみ装備可能。\n相手フィールド上に存在するモンスター１体を選択する。\nそのモンスターは攻撃と表示形式の変更ができない。\n装備モンスターが破壊される事によってこのカードが墓地へ送られた時、選択した相手モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"反目の従者",h:"はんもくのじゅうしゃ",e:"Vengeful Servant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターのコントロールが移った時、装備モンスターのコントローラーに装備モンスターの元々の攻撃力分のダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"スター・ブラスト",h:"すたー・ぶらすと",e:"Star Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"５００の倍数のライフポイントを払って発動できる。\n自分の手札または自分フィールド上に表側表示で存在するモンスター１体を選び、そのモンスターのレベルをエンドフェイズ時まで、払ったライフポイント５００ポイントにつき１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"フェザー・ウィンド・アタック",h:"ふぇざー・うぃんど・あたっく",e:"Raptor Wing Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「BF」と名のついたモンスター１体をデッキに戻して発動する。\n自分のデッキから「BF」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"D・レトロエンジン",h:"でぃふぉーまー・れとろえんじん",e:"Morphtronic Rusty Engine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D」と名のついたモンスターにのみ装備可能。\n装備モンスターが破壊された場合、お互いにそのモンスターの元々の攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"D・フィールド",h:"でぃふぉーまー・ふぃーるど",e:"Morphtronic Map",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上のモンスターの表示形式が変更される度に、このカードにディフォーマーカウンターを１つ置く。\nフィールド上の「D」と名のついたモンスターの攻撃力は、このカードに乗っているディフォーマーカウンターの数×３００ポイントアップする。\nフィールド上のこのカードが破壊され墓地へ送られた時、自分の墓地の「D」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・バースト",h:"ばすたー・ばーすと",e:"Assault Overload",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「／バスター」と名のついたモンスター１体をリリースして発動する。\nお互いにそのモンスターのレベル×２００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・テレポート",h:"ばすたー・てれぽーと",e:"Assault Teleport",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「／バスター」と名のついたモンスター１体をデッキに戻して発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"Re－BUSTER",h:"りばすたー",e:"Assault Revival",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「バスター・モード」１枚をゲームから除外して発動する。\n自分フィールド上に存在するモンスターを全て破壊し、自分の墓地に存在する「／バスター」と名のついたモンスター１体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、リリースする事もできず、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ソード",h:"さいこ・そーど",e:"Psychic Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"サイキック族モンスターにのみ装備可能。\n自分のライフポイントが相手より下の場合、その数値だけ装備モンスターの攻撃力がアップする（最大２０００まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"念動収集機",h:"ねんどうしゅうしゅうき",e:"Telekinetic Power Well",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地からレベル２以下のサイキック族モンスターを任意の数だけ選択して特殊召喚する。\nその後、自分はこの効果で特殊召喚したモンスターのレベルの合計×３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣の底力",h:"ぐらでぃあるびーすとのそこぢから",e:"Indomitable Gladiator Beast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「剣闘獣」と名のついたモンスター１体を選択し、その攻撃力をエンドフェイズ時まで５００ポイントアップする。\nまた、このカードが墓地に存在する場合、自分のメインフェイズ時にこのカード以外の自分の墓地の「剣闘獣」と名のついたカード２枚をデッキに戻す事で、墓地のこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"種子弾丸",h:"しゅしだんがん",e:"Seed Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"植物族モンスターが召喚・反転召喚・特殊召喚される度に、このカードにプラントカウンターを１つ置く（最大５つまで）。\nフィールド上に存在するこのカードを墓地へ送る事で、このカードに乗っているプラントカウンターの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"超栄養太陽",h:"ちょうえいようたいよう",e:"Super Solar Nutrient",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル２以下の植物族モンスター１体をリリースしてこのカードを発動できる。\nリリースしたモンスターのレベル＋３以下のレベルを持つ植物族モンスター１体を、手札・デッキから特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"六武ノ書",h:"ろくぶのしょ",e:"Six Scrolls of the Samurai",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター２体をリリースして発動する。\n自分のデッキから「大将軍 紫炎」１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"大樹海",h:"だいじゅかい",e:"Verdant Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する昆虫族モンスターが戦闘またはカードの効果によって破壊され墓地へ送られた時、そのモンスターのコントローラーは破壊されたモンスターと同じレベルの昆虫族モンスター１体をデッキから手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法族の結界",h:"まほうぞくのけっかい",e:"Arcane Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示の魔法使い族モンスターが破壊される度にこのカードに魔力カウンターを１つ置く（最大４つまで）。\n②：魔力カウンターが置かれているこのカードと自分フィールドの表側表示の魔法使い族モンスター１体を墓地へ送って発動できる。\nこのカードに置かれていた魔力カウンターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トライアングル・エリア",h:"とらいあんぐる・えりあ",e:"Mysterious Triangle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上のAカウンターが乗ったモンスター１体を選択して破壊する。\nその後、自分のデッキから「エーリアン」と名のついたレベル４モンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・モード",h:"ばすたー・もーど",e:"Assault Mode Activate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのSモンスター１体をリリースして発動できる。\nそのモンスターのカード名が含まれる「／バスター」モンスター１体をデッキから攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリット・フォース",h:"すぴりっと・ふぉーす",e:"Spirit Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンの戦闘ダメージ計算時に発動する事ができる。\nその戦闘によって発生する自分への戦闘ダメージは０になる。\nその後、自分の墓地に存在する守備力１５００以下の戦士族チューナー１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ロスト・スター・ディセント",h:"ろすと・すたー・でぃせんと",e:"Descending Lost Star",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のSモンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、守備力を０にして守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"白銀のバリア－シルバーフォース－",h:"はくぎんのばりあ－しるばーふぉーす－",e:"Shining Silver Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手がダメージを与える罠カードを発動した時に発動する事ができる。\nその発動を無効にし、そのカードと相手フィールド上に表側表示で存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーフorストップ",h:"はーふおあすとっぷ",e:"Half or Nothing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのバトルフェイズ時のみ発動する事ができる。\n相手は以下の効果から１つを選択して適用する。\n\n●バトルフェイズ終了時まで、自分フィールド上に存在する全てのモンスターの攻撃力は半分になる。\n\n●バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイトメア・デーモンズ",h:"ないとめあ・でーもんず",e:"Nightmare Archfiends",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースして発動できる。\n相手フィールドに「ナイトメア・デーモン・トークン」（悪魔族・闇・星６・攻／守２０００）３体を攻撃表示で特殊召喚する。\n「ナイトメア・デーモン・トークン」が破壊された時にそのコントローラーは１体につき８００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・アロー",h:"ぶらっく・あろー",e:"Ebon Arrow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nエンドフェイズ時まで、そのモンスターの攻撃力は５００ポイントダウンし、守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n選択したモンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の守備力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アイヴィ・シャックル",h:"あいう゛ぃ・しゃっくる",e:"Ivy Shackles",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手フィールド上に表側表示で存在する全てのモンスターは自分のターンのみ植物族になる。\nフィールド上に表側表示で存在するこのカードが相手の効果によって破壊され墓地へ送られた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"フェイク・エクスプロージョン・ペンタ",h:"ふぇいく・えくすぷろーじょん・ぺんた",e:"Fake Explosion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\nモンスターはその戦闘では破壊されず、ダメージ計算後、自分の手札・墓地から「サモン・リアクター・AI」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"D・バリア",h:"でぃふぉーまー・ばりあ",e:"Morphtronic Forcefield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D」と名のついたモンスターを破壊する魔法・罠カードの発動を無効にし破壊する。\nさらに自分のデッキから「D」と名のついたカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレンD",h:"ぶれんでぃふぉーまー",e:"Morphtronic Mix-up",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「D」と名のついたモンスターが２体以上表側表示で存在する場合、相手フィールド上に存在するカードを２枚選択して発動する。\n相手はその中から１枚を選択し、相手が選択したカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・スラッシュ",h:"ばすたー・すらっしゅ",e:"Assault Slash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「／バスター」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\nフィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・カウンター",h:"ばすたー・かうんたー",e:"Assault Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「／バスター」と名のついたモンスターが存在する場合に発動できる。\n効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・チューン",h:"さいこ・ちゅーん",e:"Psychic Tuning",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地に存在するサイキック族モンスター１体を選択し、攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはチューナーとして扱う。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターがフィールド上から離れた時このカードを破壊する。\nこのカードが墓地へ送られた時、自分はこのカードの効果で特殊召喚したモンスターのレベル×４００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"超能力治療",h:"すーぱーなちゅらるひーりんぐ",e:"Supernatural Regeneration",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードはエンドフェイズ時にのみ発動する事ができる。\n自分はこのターン墓地へ送られたサイキック族モンスターの数×１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"トロイの剣闘獣",h:"とろいのぐらでぃあるびーすと",e:"Trojan Gladiator Beast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札から「剣闘獣」と名のついたモンスター１体を相手フィールド上に特殊召喚する。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"棘の壁",h:"そーん・うぉーる",e:"Wall of Thorns",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の植物族モンスターが攻撃対象に選択された時に発動できる。\n相手フィールド上に攻撃表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"惑星汚染ウイルス",h:"わくせいおせんういるす",e:"Planet Pollutant Virus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「エーリアン」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上に表側表示で存在する、Aカウンターが乗っていないモンスターを全て破壊する。\n相手のターンで数えて３ターンの間に相手が召喚・反転召喚・特殊召喚したモンスター全てにAカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィクトーリア",h:"うぃくとーりあ",e:"Victoria",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"１ターンに１度、相手の墓地に存在するドラゴン族モンスター１体を選択して自分フィールド上に特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、相手は表側表示で存在する他の天使族モンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"復讐の女戦士ローズ",h:"ふくしゅうのおんなせんしろーず",e:"Rose, Warrior of Revenge",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手に３００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"シード・オブ・フレイム",h:"しーど・おぶ・ふれいむ",e:"Seed of Flame",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分フィールド上に存在するこのカードがカードの効果によって破壊され墓地へ送られた時に発動する事ができる。\n自分の墓地に存在する「シード・オブ・フレイム」以外のレベル４以下の植物族モンスター１体を自分フィールド上に特殊召喚し、相手フィールド上に「シードトークン」（植物族・地・星１・攻／守０）１体を守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"サボウ・ファイター",h:"さぼう・ふぁいたー",e:"Cactus Fighter",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手フィールド上に「ニードルトークン」（植物族・地・星１・攻／守５００）１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マックス・テレポーター",h:"まっくす・てれぽーたー",e:"Overdrive Teleporter",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"このカードは特殊召喚できない。\n２０００ライフポイントを払う事で、自分のデッキからレベル３のサイキック族モンスター２体を自分フィールド上に特殊召喚する。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RAI－JIN",h:"らい－じん",e:"Rai-Jin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:"?",de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の光属性モンスターの攻撃力は、自分の墓地の光属性モンスターの数×１００ポイントアップする。\nまた、自分のエンドフェイズ毎に発動する。\n自分フィールド上の光属性モンスター１体を選択して破壊する。\n「RAI－JIN」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RAI－MEI",h:"らい－めい",e:"Rai-Mei",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル２以下の光属性モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣レティアリィ",h:"ぐらでぃあるびーすとれてぃありぃ",e:"Gladiator Beast Retiari",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、相手の墓地のカード１枚を選択して除外できる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣レティアリィ」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイトエンド・ソーサラー",h:"ないとえんど・そーさらー",e:"Night's End Sorcerer",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:400,tx:"①：このカードが特殊召喚に成功した時、相手の墓地のカードを２枚まで対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジックテンペスター",h:"まじっくてんぺすたー",e:"Tempest Magician",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1400,tx:"チューナー＋チューナー以外の魔法使い族モンスター１体以上\nこのカードがシンクロ召喚に成功した時、このカードに魔力カウンターを１つ置く。\n１ターンに１度、自分の手札を任意の枚数墓地へ送る事で、その枚数分だけ魔力カウンターを自分フィールド上に表側表示で存在するモンスターに置く。\nまた、フィールド上に存在する魔力カウンターを全て取り除く事で、その個数×５００ポイントダメージを相手ライフに与える。",li:0,lk:[],ta:[],al:[]},
  {n:"狡猾な落とし穴",h:"こうかつなおとしあな",e:"Treacherous Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地に罠カードが存在しない場合、フィールドのモンスター２体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"超魔神イド",h:"ちょうまじんいど",e:"Ido the Supreme Magical Force",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"このカードがカードの効果によって破壊され墓地へ送られた場合、次のターンのスタンバイフェイズ時にこのカードを墓地から特殊召喚し、このカード以外の自分フィールド上に存在するモンスターを全て破壊する。\nこのカードがフィールド上に表側表示で存在する限り、自分はモンスターを通常召喚・反転召喚・特殊召喚する事ができない。\n「超魔神イド」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"バイオレット・ウィッチ",h:"ばいおれっと・うぃっち",e:"Violet Witch",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから守備力１５００以下の植物族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トラゴエディア",h:"とらごえでぃあ",e:"Tragoedia",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力・守備力は自分の手札の数×６００アップする。\n③：１ターンに１度、手札からモンスター１体を墓地へ送り、そのモンスターと同じレベルの相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールを得る。\n④：１ターンに１度、自分の墓地のモンスター１体を対象として発動できる。\nこのカードのレベルはターン終了時までそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・フェアリー・ドラゴン",h:"えんしぇんと・ふぇありー・どらごん",e:"Ancient Fairy Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、手札からレベル４以下のモンスター１体を特殊召喚できる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\nフィールド上のフィールド魔法カードを全て破壊し、自分は１０００ライフポイント回復する。\nその後、デッキからフィールド魔法カード１枚を手札に加える事ができる。",li:0,lk:[],ta:[],al:[]},
  {n:"スターダスト・ドラゴン／バスター",h:"すたーだすと・どらごんすらっしゅばすたー",e:"Stardust Dragon/Assault Mode",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果及びこのカードの効果でのみ特殊召喚する事ができる。\n魔法・罠・効果モンスターの効果が発動した時、このカードをリリースする事でその発動を無効にし破壊する。\nこの効果を適用したターンのエンドフェイズ時、この効果を発動するためにリリースされ墓地に存在するこのカードを、自分フィールド上に特殊召喚する事ができる。\nまた、フィールド上に存在するこのカードが破壊された時、自分の墓地に存在する「スターダスト・ドラゴン」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ルリー",h:"まごうしんるりー",e:"Fabled Lurrie",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"このカードが手札から墓地へ捨てられた時、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神グリムロ",h:"まごうしんぐりむろ",e:"Fabled Grimro",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：自分フィールドに「魔轟神」モンスターが存在する場合、このカードを手札から墓地へ送って発動できる。\nデッキから「魔轟神グリムロ」以外の「魔轟神」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ガルバス",h:"まごうしんがるばす",e:"Fabled Gallabas",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"手札を１枚墓地へ捨てて発動できる。\nこのカードの攻撃力以下の守備力を持つ、相手フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神クシャノ",h:"まごうしんくしゃの",e:"Fabled Kushano",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:800,tx:"このカードが墓地に存在する場合、手札から「魔轟神クシャノ」以外の「魔轟神」と名のついたモンスター１体を墓地へ捨てて発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・プロトプス",h:"じゅらっく・ぷろとぷす",e:"Jurrac Protops",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードの攻撃力は相手フィールド上のモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・ヴェロー",h:"じゅらっく・う゛ぇろー",e:"Jurrac Velo",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分フィールド上に表側攻撃表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１７００以下の「ジュラック」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・モノロフ",h:"じゅらっく・ものろふ",e:"Jurrac Monoloph",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードは相手フィールド上の全てのモンスターに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・ティラヌス",h:"じゅらっく・てぃらぬす",e:"Jurrac Tyrannus",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2500,de:1400,tx:"自分のメインフェイズ時に、このカード以外の自分フィールド上の恐竜族モンスター１体をリリースして発動できる。\nこのカードの攻撃力は５００ポイントアップする。\nまた、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・アントジョー",h:"なちゅる・あんとじょー",e:"Naturia Antjaw",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"相手がモンスターの特殊召喚に成功した時、自分のデッキからレベル３以下の「ナチュル」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・スパイダーファング",h:"なちゅる・すぱいだーふぁんぐ",e:"Naturia Spiderfang",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2100,de:400,tx:"このカードは、相手が魔法・罠・効果モンスターの効果を発動したターンでなければ攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ローズウィップ",h:"なちゅる・ろーずうぃっぷ",e:"Naturia Rosewhip",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1700,tx:"このカードがフィールド上に表側表示で存在する限り、相手は１ターンに１度しか魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・コスモスビート",h:"なちゅる・こすもすびーと",e:"Naturia Cosmobeet",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:700,tx:"相手がモンスターの通常召喚に成功した時、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネクス・ブラスト",h:"じぇねくす・ぶらすと",e:"Genex Blastfan",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"このカードが特殊召喚に成功した時、デッキから「ジェネクス」と名のついた闇属性モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"リサイクル・ジェネクス",h:"りさいくる・じぇねくす",e:"Genex Recycled",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:400,tx:"１ターンに１度、自分の墓地の「ジェネクス」と名のついたモンスター１体を選択して発動できる。\nエンドフェイズ時まで、このカードは選択したモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"アーミー・ジェネクス",h:"あーみー・じぇねくす",e:"Genex Army",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1300,tx:"「ジェネクス」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、相手フィールド上のカード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の修験者",h:"ひょうけっかいのしゅげんじゃ",e:"Pilgrim of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは攻撃力１９００以上のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の風水師",h:"ひょうけっかいのふうすいし",e:"Geomancer of the Ice Barrier",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1200,tx:"手札を１枚捨て、属性を１つ宣言して発動できる。\n宣言した属性のモンスターはこのカードを攻撃対象に選択できない。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷のファルコン",h:"みすと・ばれーのふぁるこん",e:"Mist Valley Falcon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"このカードは、このカード以外の自分フィールド上のカード１枚を手札に戻さなければ攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の巨神鳥",h:"みすと・ばれーのきょしんちょう",e:"Mist Valley Apex Avian",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2700,de:2000,tx:"このカードの効果は同一チェーン上では１度しか発動できない。\n①：魔法・罠・モンスターの効果が発動した時、自分フィールドの「ミスト・バレー」カード１枚を対象として発動できる。\nその自分の「ミスト・バレー」カードを持ち主の手札に戻し、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J リバース・ブレイク",h:"あーりー・おぶ・じゃすてぃす　りばーす・ぶれいく",e:"Ally of Justice Reverse Break",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"フィールド上に光属性モンスターが存在する場合、このカードを破壊する。\nこのカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J アンリミッター",h:"あーりー・おぶ・じゃすてぃす　あんりみったー",e:"Ally of Justice Unlimiter",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"このカードをリリースして発動できる。\n自分フィールド上の「A・O・J」と名のついたモンスター１体を選択し、その攻撃力をエンドフェイズ時まで元々の攻撃力の倍にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・オペラ",h:"わーむ・おぺら",e:"Worm Opera",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:800,tx:"リバース：「ワーム」と名のついた爬虫類族モンスター以外の、フィールド上の全てのモンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・プリンス",h:"わーむ・ぷりんす",e:"Worm Prince",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、デッキから「ワーム」と名のついた爬虫類族モンスター１体を手札に加える事ができる。\nエンドフェイズ時、自分フィールド上にこのカード以外の「ワーム」と名のついた爬虫類族モンスターが存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・クィーン",h:"わーむ・くぃーん",e:"Worm Queen",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2700,de:1100,tx:"このカードは「ワーム」と名のついた爬虫類族モンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\nまた、１ターンに１度、自分フィールド上の「ワーム」と名のついた爬虫類族モンスター１体をリリースする事で、リリースしたモンスターのレベル以下の「ワーム」と名のついた爬虫類族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ルクイエ",h:"わーむ・るくいえ",e:"Worm Rakuyeh",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"このカードは、このカードがリバースしたターンでなければ攻撃宣言できない。\nこのカードは攻撃した場合、バトルフェイズ終了時に裏側守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ヴァルキュルス",h:"まごうしんう゛ぁるきゅるす",e:"Fabled Valkyrus",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:1700,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、手札から悪魔族モンスター１体を捨てて発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・ギガノト",h:"じゅらっく・ぎがのと",e:"Jurrac Giganoto",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"恐竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"チューナー＋チューナー以外の恐竜族モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上の「ジュラック」と名のついたモンスターの攻撃力は、自分の墓地の「ジュラック」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ガオドレイク",h:"なちゅる・がおどれいく",e:"Naturia Leodrake",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"獣族",mo:["シンクロ"],ma:"",tp:"",at:3000,de:1800,tx:"地属性チューナー＋チューナー以外の地属性モンスター１体以上",li:"",lk:[],ta:[],al:[]},
  {n:"ウィンドファーム・ジェネクス",h:"うぃんどふぁーむ・じぇねくす",e:"Windmill Genex",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1600,tx:"「ジェネクス・コントローラー」＋チューナー以外の風属性モンスター１体以上\nこのカードの攻撃力はフィールド上にセットされた魔法・罠カードの数×３００ポイントアップする。\nまた、手札を１枚墓地へ送って発動できる。\nフィールド上にセットされた魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の雷神鬼",h:"みすと・ばれーのらいじんき",e:"Mist Valley Thunder Lord",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2400,tx:"チューナー＋チューナー以外の「ミスト・バレー」と名のついたモンスター１体以上\n１ターンに１度、このカード以外の自分フィールド上のカード１枚を選択して発動できる。\n選択した自分のカードを持ち主の手札に戻し、このカードの攻撃力をエンドフェイズ時まで５００ポイントアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ガイア",h:"えれめんたるひーろー　がいあ",e:"Elemental HERO Gaia",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:2600,tx:"「E・HERO」モンスター＋地属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが融合召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動する。\nターン終了時まで、そのモンスターの攻撃力を半分にし、このカードの攻撃力はその数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO アイスエッジ",h:"えれめんたるひーろー　あいすえっじ",e:"Elemental HERO Ice Edge",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"１ターンに１度、自分のメインフェイズ１で手札を１枚捨てて発動する。\nこのターンこのカードは相手プレイヤーに直接攻撃する事ができる。\nまた、このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手の魔法＆罠カードゾーンにセットされたカード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO アブソルートZero",h:"えれめんたるひーろー　あぶそるーとぜろ",e:"Elemental HERO Absolute Zero",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「HERO」と名のついたモンスター＋水属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードの攻撃力は、フィールド上に表側表示で存在する「E・HERO アブソルートZero」以外の水属性モンスターの数×５００ポイントアップする。\nこのカードがフィールド上から離れた時、相手フィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神聖魔導王 エンディミオン",h:"しんせいまどうおう　えんでぃみおん",e:"Endymion, the Master Magician",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"①：このカードは自分フィールドの「魔法都市エンディミオン」に置かれている魔力カウンターを６つ取り除き、手札・墓地から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合、自分の墓地の魔法カード１枚を対象として発動する。\nそのカードを手札に加える。\n③：１ターンに１度、手札から魔法カード１枚を捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる解呪師",h:"せいんと・でぃすえんちゃんたー",e:"Disenchanter",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:2300,tx:"①：１ターンに１度、フィールドの表側表示の魔法カード１枚を対象として発動できる。\nフィールドの魔力カウンターを１つ取り除き、対象の表側表示のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導騎士 ディフェンダー",h:"まどうきし　でぃふぇんだー",e:"Defender, the Magical Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く（最大１つまで）。\n②：１ターンに１度、フィールドの魔法使い族モンスターが破壊される場合、代わりにその数だけ自分フィールドの魔力カウンターを取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法都市エンディミオン",h:"まほうとしえんでぃみおん",e:"Magical Citadel of Endymion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：魔力カウンターが置かれているカードが破壊された場合にそのカードに置かれていた魔力カウンターの数だけ、このカードに魔力カウンターを置く。\n③：１ターンに１度、自分がカードの効果を発動するために自分フィールドの魔力カウンターを取り除く場合、代わりにこのカードから取り除く事ができる。\n④：このカードが破壊される場合、代わりにこのカードの魔力カウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力掌握",h:"まりょくしょうあく",e:"Spell Power Grasp",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置く。\nその後、デッキから「魔力掌握」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・ツール・ドラゴン",h:"ぱわー・つーる・どらごん",e:"Power Tool Dragon",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に発動できる。\nデッキから装備魔法カードを３枚選んで相手に見せ、相手はその中からランダムに１枚選ぶ。\n相手が選んだカード１枚を自分の手札に加え、残りのカードをデッキに戻す。\nまた、装備魔法カードを装備したこのカードが破壊される場合、代わりにこのカードに装備された装備魔法カード１枚を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガンテック・ファイター／バスター",h:"ぎがんてっく・ふぁいたーすらっしゅばすたー",e:"Colossal Fighter/Assault Mode",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3300,de:1500,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、全ての相手モンスターの攻撃力は、自分の墓地に存在する戦士族モンスターの数×１００ポイントダウンする。\nこのカードが特殊召喚に成功した時、自分のデッキから戦士族モンスターを２体まで選択し墓地へ送る事ができる。\nまた、フィールド上に存在するこのカードが破壊された時、自分の墓地に存在する「ギガンテック・ファイター」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ボルテニス",h:"だーく・ぼるてにす",e:"Dark Voltanis",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:1400,tx:"自分がカウンター罠の発動に成功した場合、自分フィールド上に存在する闇属性モンスター１体をリリースする事で、手札からこのカードを特殊召喚する。\nこの効果で特殊召喚に成功した時、フィールド上に存在するカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マテリアルファルコ",h:"まてりあるふぁるこ",e:"Prime Material Falcon",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"「フィールド上の魔法・罠カードを破壊する効果」を持つ効果モンスターの効果が発動した時、手札を１枚墓地へ送る事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ボーンクラッシャー",h:"ぼーんくらっしゃー",e:"Bone Crusher",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"このカードがアンデット族モンスターの効果によって自分の墓地から特殊召喚された時、相手フィールド上に存在する魔法・罠カード１枚を破壊する事ができる。\nこのカードは特殊召喚されたターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・キッズ",h:"えーりあん・きっず",e:"Alien Kid",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:700,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手フィールド上にモンスターが特殊召喚される度に、その時に特殊召喚されたモンスターにAカウンターを１つ置く。\nAカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘する場合、Aカウンター１つにつき攻撃力と守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミンゲイドラゴン",h:"みんげいどらごん",e:"Totem Dragon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"ドラゴン族モンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。\n自分のスタンバイフェイズ時にこのカードが墓地に存在し、自分フィールド上にモンスターが存在しない場合、このカードを表側攻撃表示で特殊召喚する事ができる。\nこの効果は自分の墓地にドラゴン族以外のモンスターが存在する場合には発動できない。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"竜宮の白タウナギ",h:"りゅうぐうのしろたうなぎ",e:"Royal Swamp Eel",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードをS素材とする場合、他のS素材モンスターは全て魚族モンスターでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"未知ガエル",h:"みちがえる",e:"Submarine Frog",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古代遺跡コードA",h:"こだいいせきこーどえー",e:"Code A Ancient Ruins",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「エーリアン」と名のついたモンスターが破壊される度に、このカードにAカウンターを１つ置く。\n１ターンに１度、フィールド上に存在するAカウンターを２つ取り除く事で、自分の墓地に存在する「エーリアン」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・チェンジ",h:"しんくろ・ちぇんじ",e:"Synchro Change",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体をゲームから除外して発動する。\nそのモンスターと同じレベルのシンクロモンスター１体をエクストラデッキから特殊召喚する。\nこの効果で特殊召喚した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"HEROの遺産",h:"ひーろーのいさん",e:"Legacy of a HERO",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「HEROの遺産」は１ターンに１枚しか発動できない。\n①：「HERO」モンスターを融合素材とする融合モンスター２体を自分の墓地からエクストラデッキに戻して発動できる。\n自分はデッキから３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ロックストーン・ウォリアー",h:"ろっくすとーん・うぉりあー",e:"Rockstone Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードの攻撃によってこのカードが戦闘で破壊され墓地へ送られた時、自分フィールド上に「ロックストーン・トークン」（岩石族・地・星１・攻／守０）２体を特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル・ウォリアー",h:"れべる・うぉりあー",e:"Level Warrior",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"お互いのフィールド上にモンスターが存在しない場合、このカードはレベル２モンスターとして手札から召喚できる。\n相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードはレベル４モンスターとして手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ストロング・ウィンド・ドラゴン",h:"すとろんぐ・うぃんど・どらごん",e:"Strong Wind Dragon",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：ドラゴン族モンスター１体をリリースしてこのカードのアドバンス召喚に成功した場合に発動する。\nこのカードの攻撃力は、リリースしたそのモンスターの元々の攻撃力の半分だけアップする。\n②：このカードは同じ攻撃力のモンスターとの戦闘では破壊されない。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ヴァージャー",h:"だーく・う゛ぁーじゃー",e:"Dark Verger",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"自分フィールド上に植物族のチューナーが召喚された時、このカードを墓地から攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"フェニキシアン・シード",h:"ふぇにきしあん・しーど",e:"Phoenixian Seed",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"自分フィールド上に表側表示で存在するこのカードを墓地へ送って発動できる。\n手札から「フェニキシアン・クラスター・アマリリス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フェニキシアン・クラスター・アマリリス",h:"ふぇにきしあん・くらすたー・あまりりす",e:"Phoenixian Cluster Amaryllis",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは「フェニキシアン・シード」またはこのカードの効果でしか特殊召喚できない。\nこのカードは攻撃した場合、そのダメージ計算後に破壊される。\n自分フィールド上のこのカードが破壊され墓地へ送られた時、相手ライフに８００ポイントダメージを与える。\nまた、自分のエンドフェイズ時、このカード以外の自分の墓地の植物族モンスター１体をゲームから除外する事で、このカードを墓地から表側守備表示で特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"ガード・ヘッジ",h:"がーど・へっじ",e:"Hedge Guard",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"自分フィールド上のモンスターが戦闘を行うダメージ計算時、このカードを手札から墓地へ送って発動できる。\nその自分のモンスターはその戦闘では破壊されず、エンドフェイズ時まで攻撃力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"イービル・ソーン",h:"いーびる・そーん",e:"Evil Thorn",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"①：このカードをリリースして発動できる。\n相手に３００ダメージを与え、デッキから「イービル・ソーン」を２体まで攻撃表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－極北のブリザード",h:"ぶらっくふぇざー－きょくほくのぶりざーど",e:"Blackwing - Blizzard the Far North",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードは特殊召喚できない。\n①：このカードが召喚に成功した時、自分の墓地のレベル４以下の「BF」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－蒼炎のシュラ",h:"ぶらっくふぇざー－そうえんのしゅら",e:"Blackwing - Shura the Blue Flame",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから攻撃力１５００以下の「BF」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－月影のカルート",h:"ぶらっくふぇざー－げつえいのかるーと",e:"Blackwing - Kalut the Moon Shadow",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：自分の「BF」モンスターが戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nそのモンスターの攻撃力はターン終了時まで１４００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－漆黒のエルフェン",h:"ぶらっくふぇざー－しっこくのえるふぇん",e:"Blackwing - Elphin the Raven",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"自分フィールド上に「BF」と名のついたモンスターが表側表示で存在する場合、このカードはリリースなしで召喚する事ができる。\nこのカードが召喚に成功した時、相手フィールド上に存在するモンスター１体の表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"D・リモコン",h:"でぃふぉーまー・りもこん",e:"Morphtronic Remoten",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:1200,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、自分の墓地の「D」と名のついたモンスター１体を選択してゲームから除外し、デッキからそのモンスターと同じレベルを持つ「D」と名のついたモンスター１体を手札に加える。\n●守備表示：１ターンに１度、手札の「D」と名のついたモンスター１体を墓地へ送り、自分の墓地からそのモンスターと同じレベルを持つ他の「D」と名のついたモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ビデオン",h:"でぃふぉーまー・びでおん",e:"Morphtronic Videon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードの攻撃力はこのカードに装備された装備カードの数×８００ポイントアップする。\n●守備表示：このカードの守備力はこのカードに装備された装備カードの数×８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"D・スコープン",h:"でぃふぉーまー・すこーぷん",e:"Morphtronic Scopen",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1400,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、手札から「D」と名のついたレベル４モンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に破壊される。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、このカードのレベルは４になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガジェット・アームズ",h:"がじぇっと・あーむず",e:"Gadget Arms",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:400,tx:"リバース：自分の墓地に存在する「D」と名のついた魔法または罠カード１枚を選択し、手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トラパート",h:"とらぱーと",e:"Torapart",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:600,tx:"このカードをシンクロ素材としたシンクロモンスターが攻撃する場合、相手はダメージステップ終了時まで罠カードを発動できない。\nこのカードをシンクロ素材とする場合、戦士族モンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神 Aslla piscu",h:"じばくしん　あすら　ぴすく",e:"Earthbound Immortal Aslla piscu",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、フィールド上に表側表示で存在するこのカードが、このカードの効果以外の方法でフィールド上から離れた時、相手フィールド上に表側表示で存在するモンスターを全て破壊し、破壊したモンスターの数×８００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神 Ccapac Apu",h:"じばくしん　こかぱく　あぷ",e:"Earthbound Immortal Ccapac Apu",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、このカードが戦闘によって相手モンスターを破壊した場合、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ヴァラファール",h:"こあきめいる・う゛ぁらふぁーる",e:"Koa'ki Meiru Valafar",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:2100,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送る。\nまたは、墓地へ送らずにこのカードを破壊する。\nこのカードは「コアキメイル」と名のついたモンスター１体をリリースしてアドバンス召喚する事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードは罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・パワーハンド",h:"こあきめいる・ぱわーはんど",e:"Koa'ki Meiru Powerhand",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の通常罠カード１枚を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードが光属性または闇属性のモンスターと戦闘を行う場合、バトルフェイズの間だけそのモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ガーディアン",h:"こあきめいる・がーでぃあん",e:"Koa'ki Meiru Guardian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の岩石族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nまた、効果モンスターの効果が発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ドラゴ",h:"こあきめいる・どらご",e:"Koa'ki Meiru Drago",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードのコントローラーは自分エンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札のドラゴン族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n①：このカードがモンスターゾーンに存在する限り、お互いに光・闇属性モンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・アイス",h:"こあきめいる・あいす",e:"Koa'ki Meiru Ice",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の永続魔法カード１枚を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nまた、自分のメインフェイズ時に手札を１枚墓地へ送って発動できる。\nフィールド上の特殊召喚されたモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・デビル",h:"こあきめいる・でびる",e:"Koa'ki Meiru Doom",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の悪魔族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、メインフェイズ時に発動する光属性及び闇属性モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーレム",h:"ごーれむ",e:"Brain Golem",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する光属性モンスターの効果は無効化される。\nこのカードが戦闘によって光属性モンスターを破壊した場合、もう１度だけ続けて攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミノケンサテュロス",h:"みのけんさてゅろす",e:"Minoan Centaur",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードは特殊召喚できない。\nこのカードをリリースする事で、自分のデッキから獣戦士族・レベル４の通常モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"強化人類サイコ",h:"きょうかじんるいさいこ",e:"Reinforced Human Psychic Borg",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"自分の墓地に存在するサイキック族モンスター１体をゲームから除外して発動する。\nこのカードの攻撃力は５００ポイントアップする。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マスター・ジーグ",h:"ますたー・じーぐ",e:"Master Gig",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2600,de:1400,tx:"１０００ライフポイントを払って発動する。\n自分フィールド上に表側表示で存在するサイキック族モンスターの数だけ、相手フィールド上に存在するモンスターを破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄からの使い",h:"じごくからのつかい",e:"Emissary from Pandemonium",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:2600,de:1800,tx:"このカードは特殊召喚できない。\nこのカードはモンスター１体をリリースして召喚できる。\nこの方法で召喚したこのカードのレベルは５になり、元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギガストーン・オメガ",h:"ぎがすとーん・おめが",e:"Gigastone Omega",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:2300,tx:"このカードは通常召喚できない。\n自分の墓地に存在する地属性モンスター２体をゲームから除外した場合に特殊召喚する事ができる。\n自分フィールド上に表側表示で存在するこのカードが、カードの効果によって墓地へ送られた時、相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・ドッグ",h:"えーりあん・どっぐ",e:"Alien Dog",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分が「エーリアン」と名のついたモンスターの召喚に成功した時、このカードを手札から特殊召喚できる。\nこの効果で特殊召喚に成功した時、相手フィールド上に表側表示で存在するモンスターにAカウンターを２つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ニードル・ギルマン",h:"にーどる・ぎるまん",e:"Spined Gillman",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の魚族・海竜族・水族モンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"深海のディーヴァ",h:"しんかいのでぃーう゛ぁ",e:"Deep Sea Diva",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキからレベル３以下の海竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シー・アーチャー",h:"しー・あーちゃー",e:"Mermaid Archer",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"１ターンに１度、自分フィールド上のレベル３以下のモンスター１体を選択し、装備カード扱いとしてこのカードに１体のみ装備できる。\nこの効果でモンスターを装備している場合、このカードの攻撃力は８００ポイントアップする。\nまた、フィールド上のこのカードが破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァ・ドラゴン",h:"らう゛ぁ・どらごん",e:"Lava Dragon",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分フィールド上に表側守備表示で存在するこのカードをリリースして発動する。\n自分の手札及び墓地からレベル３以下のドラゴン族モンスターをそれぞれ１体ずつ特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の尖兵",h:"りゅうのせんぺい",e:"Vanguard of the Dragon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"①：手札からドラゴン族モンスター１体を墓地へ送って発動できる。\nこのカードの攻撃力は３００アップする。\n②：フィールドのこのカードが相手の効果で墓地へ送られた時、自分または相手の墓地のドラゴン族の通常モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"G・B・ハンター",h:"じーびー・はんたー",e:"G.B. Hunter",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーはフィールド上のカードをデッキに戻す事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクスプロード・ウィング・ドラゴン",h:"えくすぷろーど・うぃんぐ・どらごん",e:"Exploder Dragonwing",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"チューナー＋チューナー以外のドラゴン族モンスター１体以上\nこのカードの攻撃力以下の攻撃力を持つ、フィールド上に表側表示で存在するモンスターとこのカードが戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊し、その攻撃力分のダメージを相手ライフに与える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－アームズ・ウィング",h:"ぶらっくふぇざー－あーむず・うぃんぐ",e:"Blackwing Armed Wing",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"「BF」チューナー＋チューナー以外のモンスター１体以上\n①：このカードが守備表示モンスターを攻撃するダメージステップの間、このカードの攻撃力は５００アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"トライデント・ドラギオン",h:"とらいでんと・どらぎおん",e:"Trident Dragion",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2800,tx:"ドラゴン族チューナー＋チューナー以外のドラゴン族モンスター１体以上\nこのカードはシンクロ召喚でしか特殊召喚できない。\nこのカードがシンクロ召喚に成功した時、このカード以外の自分フィールド上のカードを２枚まで選択して破壊できる。\nこのターン、このカードは通常の攻撃に加えて、この効果で破壊したカードの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"神海竜ギシルノドン",h:"しんかいりゅうぎしるのどん",e:"Sea Dragon Lord Gishilnodon",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"チューナー＋チューナー以外のレベル３モンスター１体\nフィールド上に表側表示で存在するレベル３以下のモンスターが墓地へ送られた時、このカードの攻撃力はエンドフェイズ時まで３０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワン・フォー・ワン",h:"わん・ふぉー・わん",e:"One for One",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札からモンスター１体を墓地へ送って発動できる。\n手札・デッキからレベル１モンスター１体を特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"トラスト・マインド",h:"とらすと・まいんど",e:"Mind Trust",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するレベル２以上のモンスター１体をリリースして発動する。\n自分の墓地から、リリースしたモンスターの半分以下のレベルを持つチューナー１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"憎悪の棘",h:"ぞうおのとげ",e:"Thorn of Malice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ブラック・ローズ・ドラゴン」または植物族モンスターにのみ装備可能。\n装備モンスターの攻撃力は６００ポイントアップする。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n装備モンスターがモンスターを攻撃した場合、ダメージ計算後に攻撃対象モンスターの攻撃力・守備力は６００ポイントダウンする。\n装備モンスターと戦闘を行った相手モンスターは、その戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・プランター",h:"まじっく・ぷらんたー",e:"Magic Planter",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の永続罠カード１枚を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンダー・クローバー",h:"わんだー・くろーばー",e:"Wonder Clover",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択し、手札からレベル４の植物族モンスター１体を墓地へ送って発動する。\n選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードを発動するターン、自分フィールド上に存在する他のモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"アゲインスト・ウィンド",h:"あげいんすと・うぃんど",e:"Against the Wind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「BF」と名のついたモンスター１体を選択して発動する。\n自分はそのモンスターの攻撃力分のダメージを受け、そのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒い旋風",h:"くろいせんぷう",e:"Black Whirlwind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「BF」モンスターが召喚された時にこの効果を発動できる。\nそのモンスターより低い攻撃力を持つ「BF」モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンクBOX",h:"じゃんくぼっくす",e:"Junk Box",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地からレベル４以下の「D」と名のついたモンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブルツールD＆C",h:"だぶるつーるでぃーあんどしー",e:"Double Tool C&D",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「パワー・ツール・ドラゴン」または「D」と名のついたレベル４以上の機械族モンスターにのみ装備可能。\nそれぞれのターンで以下の効果を適用する。\n●自分のターン：装備モンスターの攻撃力は１０００ポイントアップする。\nまた、装備モンスターが攻撃する場合、バトルフェイズの間だけ攻撃対象モンスターの効果は無効化される。\n●相手のターン：相手は装備モンスター以外のモンスターを攻撃対象に選択できない。\nまた、装備モンスターが相手モンスターと戦闘を行ったダメージステップ終了時、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"D・リペアユニット",h:"でぃふぉーまー・りぺあゆにっと",e:"Morphtronic Repair Unit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"手札から「D」と名のついたモンスター１体を墓地へ送り、自分の墓地に存在する「D」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを特殊召喚し、このカードを装備する。\n装備モンスターは表示形式を変更する事ができない。\nこのカードがフィールド上に存在しなくなった時、装備モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイルの鋼核",h:"こあきめいるのこうかく",e:"Iron Core of Koa'ki Meiru",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のドローフェイズ時に通常のドローを行う代わりに、墓地のこのカードを手札に加える事ができる。\nまた、自分のドローフェイズ時に手札から「コアキメイル」と名のついたモンスター１体を墓地へ送る事で、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急鋼核処分",h:"きんきゅうこうかくしょぶん",e:"Iron Core Immediate Disposal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキから「コアキメイルの鋼核」１枚を選択して墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急合成",h:"きんきゅうごうせい",e:"Urgent Synthesis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「コアキメイルの鋼核」１枚をデッキに戻して発動する。\n自分の手札・墓地からレベル４以下の「コアキメイル」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコパス",h:"さいこぱす",e:"Psychic Path",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、除外されている自分のサイキック族モンスターを２体まで対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュラル・チューン",h:"なちゅらる・ちゅーん",e:"Natural Tune",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のレベル４以下の通常モンスター１体を選択して発動できる。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"至高の木の実",h:"すぷれましー・べりー",e:"Supremacy Berry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分のライフポイントが相手より下の場合、自分は２０００ライフポイント回復する。\n自分のライフポイントが相手より上の場合、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"禁じられた聖杯",h:"きんじられたせいはい",e:"Forbidden Chalice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力が４００アップし、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"カーム・マジック",h:"かーむ・まじっく",e:"Calming Magic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１の開始時に発動できる。\nお互いのプレイヤーは、このターンのメインフェイズ時及びバトルフェイズ時にモンスターを通常召喚・反転召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の軌跡",h:"みらくるるーかす",e:"Miracle Locus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動する。\n相手はデッキからカードを１枚ドローする。\nこのターンのエンドフェイズ時まで、選択したモンスターの攻撃力は１０００ポイントアップし、１度のバトルフェイズ中に２回までモンスターに攻撃する事ができる。\nそのモンスターが戦闘を行う場合、相手プレイヤーが受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリムゾン・ヘルフレア",h:"くりむぞん・へるふれあ",e:"Crimson Fire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「レッド・デーモンズ・ドラゴン」が存在し、自分にダメージを与える魔法・罠カードを相手が発動した時に発動できる。\n自分が受けるその効果ダメージの代わりに、相手はその数値の倍のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"チューナー・キャプチャー",h:"ちゅーなー・きゃぷちゃー",e:"Tuner Capture",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がシンクロモンスターのシンクロ召喚に成功した時に発動する事ができる。\nそのシンクロ素材としたチューナー１体を相手の墓地から自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバー・デッド・ライン",h:"おーばー・でっど・らいん",e:"Overdoom Line",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、墓地から自分フィールド上に特殊召喚した植物族モンスターの攻撃力は１０００ポイントアップする。\nこのカードは発動後２回目の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィキッド・リボーン",h:"うぃきっど・りぼーん",e:"Wicked Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"８００ライフポイントを払い、自分の墓地に存在するシンクロモンスター１体を選択して発動する。\n選択したモンスターを表側攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターン攻撃宣言をする事ができない。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターが破壊された時このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デルタ・クロウ－アンチ・リバース",h:"でるた・くろう－あんち・りばーす",e:"Delta Crow - Anti Reverse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドの「BF」モンスターが３体のみの場合、このカードの発動は手札からもできる。\n①：自分フィールドに「BF」モンスターが存在する場合に発動できる。\n相手フィールドにセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル・リチューナー",h:"れべる・りちゅーなー",e:"Level Retuner",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体のレベルを２つまで下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"フェイク・フェザー",h:"ふぇいく・ふぇざー",e:"Fake Feather",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札から「BF」と名のついたモンスター１体を墓地へ送り、相手の墓地の通常罠カード１枚を選択して発動できる。\nこのカードの効果は、選択した通常罠カードの効果と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"トラップ・スタン",h:"とらっぷ・すたん",e:"Trap Stun",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、このカード以外のフィールドの罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"D・バインド",h:"でぃふぉーまー・ばいんど",e:"Morphtronic Bind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「D」と名のついたモンスターが存在する限り、相手フィールド上のレベル４以上のモンスターは攻撃宣言できず、表示形式の変更もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"レクリスパワー",h:"れくりすぱわー",e:"Reckoned Power",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札にある「コアキメイルの鋼核」１枚を相手に見せて発動する。\n相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"オートマチック・レーザー",h:"おーとまちっく・れーざー",e:"Automatic Laser",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が攻撃力１０００以上のモンスターの召喚・特殊召喚に成功した時、手札にある「コアキメイルの鋼核」１枚を相手に見せて発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"窮鼠の進撃",h:"きゅうそのしんげき",e:"Attack of the Cornered Rat",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するレベル３以下の通常モンスターが戦闘を行う場合、そのダメージステップ時に１００の倍数のライフポイントを払って発動する事ができる。\nこのターンのエンドフェイズ時まで、戦闘を行う相手モンスター１体の攻撃力は払った数値分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"無力の証明",h:"むりょくのしょうめい",e:"Proof of Powerlessness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にレベル７以上のモンスターが存在する場合に発動できる。\n相手フィールド上のレベル５以下のモンスターを全て破壊する。\nこのカードを発動するターン、自分フィールド上のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ボーン・テンプル・ブロック",h:"ぼーん・てんぷる・ぶろっく",e:"Bone Temple Block",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nお互いに相手の墓地からレベル４以下のモンスター１体を選択し自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"超古代生物の墓場",h:"ちょうこだいせいぶつのはかば",e:"Grave of the Super Ancient Organism",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上のレベル６以上の特殊召喚されたモンスターは攻撃宣言できず、効果を発動する事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ツバメ返し",h:"つばめがえし",e:"Swallow Flip",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"特殊召喚成功時に発動される効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"呪言の鏡",h:"じゅごんのかがみ",e:"Mirror of Oaths",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がデッキからモンスターを特殊召喚した時に発動できる。\nそのモンスターを破壊し、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ウルストス",h:"まごうしんうるすとす",e:"Fabled Urustos",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカードがフィールド上に表側表示で存在し、自分の手札が２枚以下の場合、自分フィールド上の全ての「魔轟神」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神クルス",h:"まごうしんくるす",e:"Fabled Krus",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードが手札から墓地へ捨てられた時、自分の墓地からこのカード以外のレベル４以下の「魔轟神」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神トピー",h:"まごうしんとぴー",e:"Fabled Topi",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"自分の手札が相手の手札より２枚以上少ない場合、手札の「魔轟神」と名のついたモンスター１体を相手に見せ、フィールド上のこのカードをリリースして発動できる。\n相手フィールド上の魔法・罠カード２枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ソルキウス",h:"まごうしんそるきうす",e:"Fabled Soulkius",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:2100,tx:"このカードが墓地に存在する場合、手札から「魔轟神ソルキウス」以外のカード２枚を墓地へ送って発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ミーズトージ",h:"まごうしんみーずとーじ",e:"Fabled Miztoji",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"このカードを手札から墓地へ送り、チューナー以外の自分フィールド上の「魔轟神」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・プティラ",h:"じゅらっく・ぷてぃら",e:"Jurrac Ptera",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードが攻撃されたダメージ計算後、攻撃モンスターを持ち主の手札に戻す。\nこのカードの守備力は、この効果で手札に戻したモンスターのレベル×１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・イグアノン",h:"じゅらっく・いぐあのん",e:"Jurrac Iguanon",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手フィールド上にセットされたカード１枚を選択して持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・ブラキス",h:"じゅらっく・ぶらきす",e:"Jurrac Brachis",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"フィールド上にこのカード以外の「ジュラック」と名のついたモンスターが存在する場合、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・スピノス",h:"じゅらっく・すぴのす",e:"Jurrac Spinos",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1700,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手フィールド上に「スピノストークン」（恐竜族・炎・星１・攻３００／守０）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ドラゴンフライ",h:"なちゅる・どらごんふらい",e:"Naturia Dragonfly",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードは攻撃力２０００以上のモンスターとの戦闘では破壊されない。\nこのカードの攻撃力は自分の墓地の「ナチュル」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・サンフラワー",h:"なちゅる・さんふらわー",e:"Naturia Sunflower",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"相手の効果モンスターの効果が発動した時、自分フィールド上の「ナチュル」と名のついたモンスター１体とこのカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・クリフ",h:"なちゅる・くりふ",e:"Naturia Cliff",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードがフィールド上から墓地へ送られた時、デッキからレベル４以下の「ナチュル」と名のついたモンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・トライアンフ",h:"なちゅる・とらいあんふ",e:"Naturia Tulip",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1500,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手が魔法・罠カードを発動する度に、自分フィールド上の「ナチュル」と名のついたモンスターの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・ターボ",h:"れある・じぇねくす・たーぼ",e:"R-Genex Turbo",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが召喚に成功した時、デッキからレベル１の「ジェネクス」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・コーディネイター",h:"れある・じぇねくす・こーでぃねいたー",e:"R-Genex Overseer",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:100,tx:"このカードが召喚・特殊召喚に成功した時、手札からレベル３以下の「ジェネクス」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・クラッシャー",h:"れある・じぇねくす・くらっしゃー",e:"R-Genex Crusher",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが召喚に成功した時、デッキからレベル４の「レアル・ジェネクス」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・マグナ",h:"れある・じぇねくす・まぐな",e:"R-Genex Magma",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"このカードが召喚に成功した時、デッキからレベル２の「レアル・ジェネクス」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の決起隊",h:"ひょうけっかいのけっきたい",e:"Shock Troops of the Ice Barrier",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカードをリリースして発動できる。\nフィールド上の水属性モンスター１体を選択して破壊し、デッキから「氷結界」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の武士",h:"ひょうけっかいのもののふ",e:"Samurai of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"フィールド上に表側攻撃表示で存在するこのカードが表側守備表示になった時、このカードを破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の水影",h:"ひょうけっかいのみずかげ",e:"Dewdark of the Ice Barrier",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:800,tx:"自分フィールド上に表側表示で存在するモンスターがレベル２以下のモンスターのみの場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の輸送部隊",h:"ひょうけっかいのゆそうぶたい",e:"Caravan of the Ice Barrier",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"１ターンに１度、自分の墓地の「氷結界」と名のついたモンスター２体を選択して発動できる。\n選択したモンスター２体をデッキに戻してシャッフルする。\nその後、お互いにデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ソリッド",h:"わーむ・そりっど",e:"Worm Solid",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードの守備力は、自分の墓地の「ワーム」と名のついた爬虫類族モンスターの数×１００ポイントアップする。\nまた、このカードが攻撃され、相手プレイヤーが戦闘ダメージを受けた場合、そのダメージステップ終了時に相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・テンタクルス",h:"わーむ・てんたくるす",e:"Worm Tentacles",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"１ターンに１度、自分の墓地の「ワーム」と名のついた爬虫類族モンスター１体をゲームから除外して発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・アグリィ",h:"わーむ・あぐりぃ",e:"Worm Ugly",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードをリリースして「ワーム」と名のついた爬虫類族モンスターのアドバンス召喚に成功した時、自分の墓地のこのカードを相手フィールド上に表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ヴィクトリー",h:"わーむ・う゛ぃくとりー",e:"Worm Victory",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:2500,tx:"リバース：「ワーム」と名のついた爬虫類族モンスター以外の、フィールド上に表側表示で存在するモンスターを全て破壊する。\nまた、このカードがフィールド上に表側表示で存在する限り、このカードの攻撃力は、自分の墓地の「ワーム」と名のついた爬虫類族モンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神レヴュアタン",h:"まごうしんれう゛ゅあたん",e:"Fabled Leviathan",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\nフィールド上のこのカードが破壊され墓地へ送られた時、自分の墓地の「魔轟神」と名のついたモンスターを３体まで選択して発動できる。\n選択したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・ヴェルヒプト",h:"じゅらっく・う゛ぇるひぷと",e:"Jurrac Velphito",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"恐竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"チューナー＋チューナー以外の恐竜族モンスター１体以上\nこのカードの攻撃力・守備力は、このカードのシンクロ素材としたモンスターの元々の攻撃力を合計した数値になる。\nまた、このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・パルキオン",h:"なちゅる・ぱるきおん",e:"Naturia Barkion",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"地属性チューナー＋チューナー以外の地属性モンスター１体以上\n①：罠カードが発動した時、自分の墓地のカード２枚を除外して発動できる。\nこのカードがフィールドに表側表示で存在する場合、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・クロキシアン",h:"れある・じぇねくす・くろきしあん",e:"Locomotion R-Genex",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「ジェネクス」と名のついたチューナー＋チューナー以外の闇属性モンスター１体以上\nこのカードがシンクロ召喚に成功した時、相手フィールド上のレベルが一番高いモンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の龍 グングニール",h:"ひょうけっかいのりゅう　ぐんぐにーる",e:"Gungnir, Dragon of the Ice Barrier",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1700,tx:"チューナー＋チューナー以外の水属性モンスター１体以上\n①：１ターンに１度、手札を２枚まで墓地へ捨て、捨てた数だけ相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スノーマンイーター",h:"すのーまんいーたー",e:"Snowman Eater",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードがリバースした時、フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・レッド",h:"おじゃま・れっど",e:"Ojama Red",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードが召喚に成功した時、手札から「おジャマ」と名のついたモンスターを４体まで自分フィールド上に攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・ブルー",h:"おじゃま・ぶるー",e:"Ojama Blue",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「おジャマ」カード２枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・カントリー",h:"おじゃま・かんとりー",e:"Ojama Country",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、手札から「おジャマ」と名のついたカード１枚を墓地へ送る事で、自分の墓地に存在する「おジャマ」と名のついたモンスター１体を特殊召喚する。\n自分フィールド上に「おジャマ」と名のついたモンスターが表側表示で存在する限り、フィールド上に表側表示で存在する全てのモンスターの元々の攻撃力・守備力を入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"柴戦士タロ",h:"しばせんしたろ",e:"Shiba-Warrior Taro",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"このカードは戦闘では破壊されない。\nフィールド上に存在するカードが戦闘またはカードの効果によって破壊された時、自分フィールド上に表側表示で存在するこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"スピード・キング☆スカル・フレイム",h:"すぴーど・きんぐ☆すかる・ふれいむ",e:"Supersonic Skull Flame",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する「スカル・フレイム」１体をゲームから除外した場合に特殊召喚する事ができる。\n１ターンに１度、自分の墓地に存在する「バーニング・スカルヘッド」の数×４００ポイントダメージを相手ライフに与える事ができる。\nまた、このカードがフィールド上から墓地へ送られた時、自分の墓地に存在する「スカル・フレイム」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スカル・フレイム",h:"すかる・ふれいむ",e:"Skull Flame",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"１ターンに１度、手札から「バーニング・スカルヘッド」１体を特殊召喚する事ができる。\nこの効果を発動するターン、自分はバトルフェイズを行う事ができない。\nまた、自分のドローフェイズ時に通常のドローを行う代わりに、自分の墓地に存在する「バーニング・スカルヘッド」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バーニング・スカルヘッド",h:"ばーにんぐ・すかるへっど",e:"Burning Skull Head",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードが手札から特殊召喚に成功した時、相手ライフに１０００ポイントダメージを与える。\nまた、自分フィールド上に表側表示で存在するこのカードをゲームから除外する事で、ゲームから除外されている「スカル・フレイム」１体を墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"スカル・コンダクター",h:"すかる・こんだくたー",e:"Skull Conductor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードを手札から墓地へ送って発動できる。\n攻撃力の合計が２０００になるように手札のアンデット族モンスターを２体まで選び、そのモンスターを特殊召喚する。\n②：自分・相手のバトルフェイズ終了時に発動する。\nフィールドの表側表示のこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・デーモン",h:"いんふぇるにてぃ・でーもん",e:"Infernity Archfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：手札が０枚の場合にこのカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した時に発動できる。\nデッキから「インフェルニティ」カード１枚を手札に加える。\nこの効果は自分の手札が０枚の場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ドワーフ",h:"いんふぇるにてぃ・どわーふ",e:"Infernity Dwarf",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"自分の手札が０枚の場合、このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在するモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ガーディアン",h:"いんふぇるにてぃ・がーでぃあん",e:"Infernity Guardian",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1700,tx:"自分の手札が０枚の場合、フィールド上に表側表示で存在するこのカードは戦闘及びカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・デストロイヤー",h:"いんふぇるにてぃ・ですとろいやー",e:"Infernity Destroyer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"自分の手札が０枚の場合、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手ライフに１６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"コート・オブ・ジャスティス",h:"こーと・おぶ・じゃすてぃす",e:"Court of Justice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から天使族モンスター１体を特殊召喚する。\nこの効果は自分フィールドにレベル１の天使族モンスターが存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"パワード・チューナー",h:"ぱわーど・ちゅーなー",e:"Powered Tuner",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードの攻撃力は、フィールド上に表側表示で存在するチューナーの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"弱者の意地",h:"じゃくしゃのいじ",e:"Pride of the Weak",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の手札が０枚の場合、自分フィールド上に存在するレベル２以下のモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガトムズの緊急指令",h:"がとむずのきんきゅうしれい",e:"Gottoms' Emergency Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドに「X－セイバー」モンスターが存在する場合、自分・相手の墓地の「X－セイバー」モンスターを合計２体対象として発動できる。\nそのモンスター２体を自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"大天使クリスティア",h:"だいてんしくりすてぃあ",e:"Archlord Kristya",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"①：自分の墓地の天使族モンスターが４体のみの場合、このカードは手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合、自分の墓地の天使族モンスター１体を対象として発動する。\nその天使族モンスターを手札に加える。\n③：このカードがモンスターゾーンに存在する限り、お互いにモンスターを特殊召喚できない。\n④：フィールドの表側表示のこのカードが墓地へ送られる場合、墓地へは行かず持ち主のデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使降臨",h:"だてんしこうりん",e:"Darklord Descent",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：LPを半分払って発動できる。\n相手フィールドの表側表示モンスター１体を選び、そのモンスターと同じレベルの「堕天使」モンスターを２体まで自分の墓地から選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使アスモディウス",h:"だてんしあすもでぃうす",e:"Darklord Asmodeus",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードはデッキ・墓地からの特殊召喚はできない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから天使族モンスター１体を墓地へ送る。\n②：自分フィールドのこのカードが破壊され墓地へ送られた場合に発動する。\n自分フィールドに「アスモトークン」（天使族・闇・星５・攻１８００／守１３００）１体と、「ディウストークン」（天使族・闇・星３・攻／守１２００）１体を特殊召喚する。\n「アスモトークン」は効果では破壊されない。\n「ディウストークン」は戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使スペルビア",h:"だてんしすぺるびあ",e:"Darklord Superbia",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2900,de:2400,tx:"①：このカードが墓地からの特殊召喚に成功した時、「堕天使スペルビア」以外の自分の墓地の天使族モンスター１体を対象として発動できる。\nその天使族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使エデ・アーラエ",h:"だてんしえで・あーらえ",e:"Darklord Edeh Arae",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"①：墓地から特殊召喚したこのカードは以下の効果を得る。\n\n●このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"クリボン",h:"くりぼん",e:"Kuribon",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードが相手モンスターの攻撃対象になったダメージ計算時に発動できる。\nその戦闘によって発生する自分への戦闘ダメージは０になり、攻撃モンスターの攻撃力分だけ相手のライフポイントを回復し、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"サニー・ピクシー",h:"さにー・ぴくしー",e:"Sunny Pixie",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:400,tx:"このカードが光属性のシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"サンライト・ユニコーン",h:"さんらいと・ゆにこーん",e:"Sunlight Unicorn",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\n自分のデッキの一番上のカードをめくり、それが装備魔法カードだった場合手札に加える。\n違った場合、自分のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－銀盾のミストラル",h:"ぶらっくふぇざー－ぎんたてのみすとらる",e:"Blackwing - Mistral the Silver Shield",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1800,tx:"フィールド上に存在するこのカードが破壊され墓地へ送られた場合、このターン自分が受ける戦闘ダメージを１度だけ０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－大旆のヴァーユ",h:"ぶらっくふぇざー－たいはいのう゛ぁーゆ",e:"Blackwing - Vayu the Emblem of Honor",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"①：このカードはモンスターゾーンに存在する限り、S素材にできない。\n②：このカードが墓地に存在する場合、チューナー以外の自分の墓地の「BF」モンスター１体を対象として発動できる。\nそのモンスターとこのカードを墓地から除外し、その２体のレベルの合計と同じレベルを持つ「BF」Sモンスター１体をエクストラデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－鉄鎖のフェーン",h:"ぶらっくふぇざー－てっさのふぇーん",e:"Blackwing - Fane the Steel Chain",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手フィールド上に攻撃表示で存在するモンスター１体を守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"D・マグネンI",h:"でぃふぉーまー・まぐねんあい",e:"Morphtronic Magnen Bar",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：自分フィールド上にこのカード以外のモンスターが表側攻撃表示で２体存在する場合、１ターンに１度、このカードの攻撃力はこのターンのエンドフェイズ時までそのモンスターの攻撃力を合計した数値分アップする事ができる。\nこの効果を発動するターン、他のモンスターは攻撃できない。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上に存在するモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェスター・ロード",h:"じぇすたー・ろーど",e:"Jester Lord",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"フィールド上にこのカード以外のモンスターが存在しない場合、お互いの魔法＆罠カードゾーンに存在するカード１枚につき、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェスター・コンフィ",h:"じぇすたー・こんふぃ",e:"Jester Confit",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：「ジェスター・コンフィ」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードは手札から攻撃表示で特殊召喚できる。\n③：このカードの②の方法で特殊召喚した場合、次の相手エンドフェイズに相手フィールドの表側表示モンスター１体を対象として発動する。\nその相手の表側表示モンスターと表側表示のこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・ライティー",h:"ふぉーちゅんれでぃ・らいてぃー",e:"Fortune Lady Light",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの攻撃力・守備力はこのカードのレベル×２００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\n③：表側表示のこのカードが効果でフィールドから離れた時に発動できる。\nデッキから「フォーチュンレディ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・ファイリー",h:"ふぉーちゅんれでぃ・ふぁいりー",e:"Fortune Lady Fire",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、このカードのレベル×２００ポイントになる。\nまた、自分のスタンバイフェイズ時、このカードのレベルを１つ上げる（最大レベル１２まで）。\nこのカードが「フォーチュンレディ」と名のついたカードの効果によって表側攻撃表示で特殊召喚に成功した時、相手フィールド上に表側表示で存在するモンスター１体を選択して破壊し、破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ビースト",h:"いんふぇるにてぃ・びーすと",e:"Infernity Beast",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分の手札が０枚の場合、このカードは以下の効果を得る。\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークシー・レスキュー",h:"だーくしー・れすきゅー",e:"Darksea Rescue",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークシー・フロート",h:"だーくしー・ふろーと",e:"Darksea Float",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:300,tx:"フィールド上に存在するこのカードがカードの効果によって破壊され墓地へ送られた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンショット・ロケット",h:"わんしょっと・ろけっと",e:"Turbo Rocket",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが攻撃する場合、このカードはその戦闘では破壊されない。\nまた、このカードが攻撃を行ったダメージ計算後、攻撃対象モンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神 Cusillu",h:"じばくしん　くしる",e:"Earthbound Immortal Cusillu",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、フィールド上に表側表示で存在するこのカードが戦闘によって破壊される場合、代わりにこのカード以外の自分フィールド上のモンスター１体をリリースして相手のライフポイントを半分にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神 Chacu Challhua",h:"じばくしん　ちゃく　ちゃるあ",e:"Earthbound Immortal Chacu Challhua",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2900,de:2400,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、１ターンに１度、このカードの守備力の半分のダメージを相手ライフに与える事ができる。\nこの効果を発動するターン、このカードは攻撃できない。\nこのカードがフィールド上に表側守備表示で存在する限り、相手はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ロック",h:"こあきめいる・ろっく",e:"Koa'ki Meiru Boulder",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「コアキメイルの鋼核」１枚またはレベル４以下の「コアキメイル」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・クルセイダー",h:"こあきめいる・くるせいだー",e:"Koa'ki Meiru Crusader",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1300,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の獣戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードが戦闘によって相手モンスターを破壊した場合、自分の墓地の「コアキメイル」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・スピード",h:"こあきめいる・すぴーど",e:"Koa'ki Meiru Speeder",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:2200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の機械族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、自分のドローフェイズ時にドローしたカードが「コアキメイルの鋼核」だった場合、そのカードを相手に見せる事で自分はカードをもう１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・トルネード",h:"こあきめいる・とるねーど",e:"Koa'ki Meiru Tornado",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"手札から「コアキメイルの鋼核」１枚をデッキの一番上に戻して発動する。\n相手フィールド上に存在する特殊召喚されたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・フルバリア",h:"こあきめいる・ふるばりあ",e:"Koa'ki Meiru Hydro Barrier",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"手札から「コアキメイルの鋼核」１枚をデッキの一番上に戻して発動する。\n次の自分のターンのスタンバイフェイズ時まで、「コアキメイル」と名のついたモンスター以外のフィールド上に表側表示で存在する効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"スケイルモース",h:"すけいるもーす",e:"Scary Moth",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:2300,tx:"このカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーは１ターンに１度しかモンスターを特殊召喚する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"黒光りするG",h:"くろびかりするじー",e:"Shiny Black \"C\"",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:500,tx:"相手フィールド上にシンクロモンスター１体のみが特殊召喚された時、墓地のこのカードをゲームから除外して発動できる。\nそのシンクロモンスターを選択して破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アームズ・シーハンター",h:"あーむず・しーはんたー",e:"Armed Sea Hunter",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"自分フィールド上にこのカード以外の水属性モンスターが存在する場合、このカードと戦闘を行った効果モンスターの効果をダメージ計算後に無効化する。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに自分フィールド上に表側表示で存在するレベル３以下の水属性モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"神竜アクアバザル",h:"しんりゅうあくあばざる",e:"Divine Dragon Aquabizarre",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2100,de:1500,tx:"このカード以外の自分フィールド上に表側表示で存在する水属性モンスター１体をリリースし、自分の墓地に存在する永続魔法またはフィールド魔法カード１枚を選択して発動する。\n選択したカードを自分の墓地からデッキの一番上に戻す。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュボーグ－ガンナー",h:"ふぃっしゅぼーぐ－がんなー",e:"Fishborg Blaster",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:200,tx:"自分フィールド上にレベル３以下の水属性モンスターが存在する場合、手札を１枚捨てて発動できる。\nこのカードを墓地から特殊召喚する。\nこのカードをシンクロ素材とする場合、他のシンクロ素材モンスターは全て水属性モンスターでなければならない。",li:0,lk:[],ta:[],al:[]},
  {n:"シャーク・ザ・クルー",h:"しゃーく・ざ・くるー",e:"Shark Cruiser",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1800,de:2200,tx:"自分フィールド上に表側表示で存在するこのカードが相手のカードの効果によって破壊された場合、自分のデッキからレベル４以下の水属性モンスターを２体まで自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマード・サイキッカー",h:"あーまーど・さいきっかー",e:"Armored Axon Kicker",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"自分フィールド上にサイキック族モンスターが表側表示で存在する場合、このカードはリリースなしで召喚する事ができる。\nこのカードが戦闘によって相手モンスターを破壊した場合、自分は破壊したモンスターの攻撃力の半分のダメージを受ける。\nさらに受けたダメージの数値以下の攻撃力を持つモンスター１体を自分の墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネティック・ウーマン",h:"じぇねてぃっく・うーまん",e:"Genetic Woman",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"１ターンに１度、１０００ライフポイントを払い、ゲームから除外されている自分のサイキック族モンスター１体を選択して発動できる。\n除外されているそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マジキャット",h:"まじきゃっと",e:"Magicat",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:500,tx:"このカードが魔法使い族モンスターのシンクロ召喚に使用され墓地へ送られた場合、自分の墓地に存在する魔法カード１枚をデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイボーグドクター",h:"さいぼーぐどくたー",e:"Cyborg Doctor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1700,tx:"自分フィールド上に存在するチューナー１体をリリースして発動する。\nリリースしたモンスターと同じ属性・レベルのモンスター１体を自分の墓地から特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ホワイトポータン",h:"ほわいとぽーたん",e:"White Potan",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"自分フィールド上にチューナーが表側表示で存在する場合、このカードは戦闘では破壊されない。\n自分フィールド上に表側表示で存在するチューナーが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マインフィールド",h:"まいんふぃーるど",e:"Minefieldriller",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：表側表示のこのカードが自分フィールドから離れた時、自分の墓地のフィールド魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー フォルトロール",h:"だぶるえっくす－せいばー　ふぉるとろーる",e:"XX-Saber Faultroll",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカードは通常召喚できない。\n自分フィールドに「X－セイバー」モンスターが２体以上存在する場合のみ特殊召喚できる。\n①：１ターンに１度、自分の墓地のレベル４以下の「X－セイバー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー レイジグラ",h:"だぶるえっくす－せいばー　れいじぐら",e:"XX-Saber Ragigura",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:1000,tx:"①：このカードが召喚・特殊召喚に成功した時、自分の墓地の「X－セイバー」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・ヘルドッグ",h:"ふれむべる・へるどっぐ",e:"Flamvell Firedog",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1900,de:200,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「フレムベル・ヘルドッグ」以外の守備力２００以下の炎属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・クリムゾン・エイプ",h:"えんしぇんと・くりむぞん・えいぷ",e:"Ancient Crimson Ape",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"自分フィールド上に存在するモンスターが破壊され墓地へ送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファルシオンβ",h:"ふぁるしおんべーた",e:"Falchionβ",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが戦闘によって相手モンスターを破壊した場合、以下の効果から１つを選択して発動する。\n\n●デッキから機械族・光属性・攻撃力１２００以下のモンスター１体を墓地へ送る。\n\n●自分の墓地から機械族・光属性・攻撃力１２００以下のモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンショット・キャノン",h:"わんしょっと・きゃのん",e:"Turbo Cannon",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:0,tx:"「ワンショット・ロケット」＋チューナー以外のモンスター１体\n１ターンに１度、自分のメインフェイズ時に発動できる。\nフィールド上に表側表示で存在するモンスター１体を選択して破壊し、そのコントローラーに破壊したモンスターの攻撃力の半分のダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アンデット・スカル・デーモン",h:"あんでっと・すかる・でーもん",e:"Archfiend Zombie-Skull",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"「ゾンビキャリア」＋チューナー以外のアンデット族モンスター２体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のアンデット族モンスターは、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・ホーリー・ワイバーン",h:"えんしぇんと・ほーりー・わいばーん",e:"Ancient Sacred Wyvern",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:2000,tx:"光属性チューナー＋チューナー以外のモンスター１体以上\n自分のライフポイントが相手より多い場合、このカードの攻撃力はその差の数値分アップする。\n自分のライフポイントが相手より少ない場合、このカードの攻撃力はその差の数値分ダウンする。\nまた、このカードが戦闘によって破壊され墓地へ送られた時、１０００ライフポイントを払う事でこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー ガトムズ",h:"だぶるえっくす－せいばー　がとむず",e:"XX-Saber Gottoms",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3100,de:2600,tx:"チューナー＋地属性モンスター１体以上\n①：自分フィールドの「X－セイバー」モンスター１体をリリースして発動できる。\n相手の手札をランダムに１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"拘束解放波",h:"こうそくかいほうは",e:"Release Restraint Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する装備魔法カード１枚を選択して発動できる。\n選択した装備魔法カードと相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"白銀の翼",h:"はくぎんのつばさ",e:"Silver Wing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル８以上のドラゴン族シンクロモンスターにのみ装備可能。\n装備モンスターは１ターンに２度まで、戦闘では破壊されない。\n装備モンスターがカードの効果によって破壊される場合、代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アドバンスドロー",h:"あどばんすどろー",e:"Advance Draw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するレベル８以上のモンスター１体をリリースして発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"古の森",h:"いにしえのもり",e:"Ancient Forest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、フィールド上に守備表示モンスターが存在する場合、そのモンスターを全て表側攻撃表示にする。\nこの時、リバース効果モンスターの効果は発動しない。\nまた、このカードがフィールド上に存在する限り、攻撃を行ったモンスターをバトルフェイズ終了時に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"救急救命",h:"きゅうきゅうきゅうめい",e:"Emergency Assistance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ２でのみ発動する事ができる。\nこのターンにカードの効果によって破壊され墓地へ送られたレベル４のモンスター１体を、自分の墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリット・バーナー",h:"すぴりっと・ばーなー",e:"Spirit Burner",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"１ターンに１度、装備モンスターを守備表示にする事ができる。\n装備モンスターがフィールド上から手札に戻る事によってこのカードが墓地へ送られた時、相手ライフに６００ポイントダメージを与える。\nこのカードが墓地に存在する場合、自分のドローフェイズ時に通常のドローを行う代わりに、このカードを手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"フューチャー・ヴィジョン",h:"ふゅーちゃー・う゛ぃじょん",e:"Future Visions",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分または相手がモンスターの召喚に成功した時、そのモンスター１体を選択してゲームから除外する。\n召喚したモンスターのコントローラーから見て次の自分のスタンバイフェイズ時、この効果で除外したモンスターを表側攻撃表示でフィールド上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"コア濃度圧縮",h:"こあのうどあっしゅく",e:"Core Compression",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の「コアキメイルの鋼核」１枚を相手に見せ、手札から「コアキメイル」と名のついたモンスター１体を捨てて発動する。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"コアバスター",h:"こあばすたー",e:"Core Blaster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「コアキメイル」と名のついたモンスターにのみ装備可能。\n装備モンスターが光属性または闇属性モンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。\n装備モンスターがフィールド上から離れる事によってこのカードが墓地へ送られた時、このカードを手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"一族の結束",h:"いちぞくのけっそく",e:"Solidarity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の全てのモンスターの元々の種族が同じ場合、自分フィールドのその種族のモンスターの攻撃力は８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイドロプレッシャーカノン",h:"はいどろぷれっしゃーかのん",e:"Hydro Pressure Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル３以下の水属性モンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、相手の手札をランダムに１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーターハザード",h:"うぉーたーはざーど",e:"Water Hazard",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合、手札からレベル４以下の水属性モンスター１体を特殊召喚できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"脳開発研究所",h:"のうかいはつけんきゅうじょ",e:"Brain Research Lab",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、お互いのプレイヤーは通常召喚に加えて１度だけ、自身のメインフェイズにサイキック族モンスター１体を召喚できる。\nこの方法でサイキック族モンスターが召喚される度にこのカードにサイコカウンターを１つ置く。\n②：自分フィールドのサイキック族モンスターが効果を発動するためにLPを払う場合、代わりにこのカードにサイコカウンターを１つ置く事ができる。\n③：このカードがフィールドから離れた時に自分はこのカードに置かれていたサイコカウンターの数×１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"セイバー・スラッシュ",h:"せいばー・すらっしゅ",e:"Saber Slash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在する「X－セイバー」と名のついたモンスターの数だけ、フィールド上に表側表示で存在するカードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣の煌き",h:"つるぎのきらめき",e:"Sword of Sparkles",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「X－セイバー」と名のついたモンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、相手フィールド上に存在するカード１枚を破壊する事ができる。\nまた、自分フィールド上に存在するモンスター１体をリリースする事で、自分の墓地に存在するこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"真炎の爆発",h:"しんえんのばくはつ",e:"Rekindling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地から守備力２００の炎属性モンスターを可能な限り特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・リーフ",h:"えんしぇんと・りーふ",e:"Ancient Leaf",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のLPが９０００以上の場合、２０００LPを払って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"化石調査",h:"かせきちょうさ",e:"Fossil Dig",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからレベル６以下の恐竜族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スキル・サクセサー",h:"すきる・さくせさー",e:"Skill Successor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで４００ポイントアップする。\nまた、墓地のこのカードをゲームから除外し、自分フィールド上のモンスター１体を選択して発動できる。\n選択した自分のモンスターの攻撃力はエンドフェイズ時まで８００ポイントアップする。\nこの効果はこのカードが墓地へ送られたターンには発動できず、自分のターンにのみ発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥルース・リインフォース",h:"とぅるーす・りいんふぉーす",e:"Reinforce Truth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：デッキからレベル２以下の戦士族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガリトラップ－ピクシーの輪－",h:"がりとらっぷ－ぴくしーのわ－",e:"Pixie Ring",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上にモンスターが表側攻撃表示で２体以上存在する場合、相手は攻撃力の一番低いモンスターを攻撃対象に選択する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精の風",h:"ようせいのかぜ",e:"Fairy Wind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する魔法・罠カードを全て破壊し、お互いに破壊したカードの枚数×３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"宮廷のしきたり",h:"きゅうていのしきたり",e:"Imperial Custom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「宮廷のしきたり」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードが魔法＆罠ゾーンに存在する限り、「宮廷のしきたり」以外のお互いのフィールドの表側表示の永続罠カードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"不協和音",h:"ふきょうわおん",e:"Discord",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーはシンクロ召喚できない。\n発動後３回目の自分のエンドフェイズ時にこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュン・スリップ",h:"ふぉーちゅん・すりっぷ",e:"Slip of Fortune",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にし、攻撃対象モンスター１体を次のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"デプス・アミュレット",h:"でぷす・あみゅれっと",e:"Depth Amulet",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n手札を１枚墓地へ捨て、相手モンスター１体の攻撃を無効にする。\nこのカードは発動後３回目の相手のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・トランスレーション",h:"だめーじ・とらんすれーしょん",e:"Damage Translation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン自分が受ける効果ダメージは半分になる。\nこのターンのエンドフェイズ時、受けた効果ダメージの回数と同じ数だけ自分フィールド上に「ゴースト・トークン」（悪魔族・闇・星１・攻／守０）を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バトル・テレポーテーション",h:"ばとる・てれぽーてーしょん",e:"Battle Teleportation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するサイキック族モンスターが１体のみの場合、そのモンスター１体を選択して発動する。\nこのターン選択したモンスターは相手プレイヤーに直接攻撃する事ができる。\nこのターンのバトルフェイズ終了時、選択したモンスターのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"コアの再練成",h:"こあのさいれんせい",e:"Core Reinforcement",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地に存在する「コアキメイル」と名のついたモンスター１体を選択し、攻撃表示で特殊召喚する。\n自分のエンドフェイズ時にそのモンスターが破壊された時、このカードのコントローラーはそのモンスターの攻撃力分のダメージを受ける。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターが破壊された時このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼核の輝き",h:"こうかくのかがやき",e:"Iron Core Luster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札にある「コアキメイルの鋼核」１枚を相手に見せて発動する。\n相手の魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"異種闘争",h:"いしゅとうそう",e:"Battle of the Elements",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いのフィールド上のモンスターが全て表側表示の場合に発動できる。\nお互いのプレイヤーはそれぞれ属性が１種類になるように、フィールド上の自分のモンスターを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"海竜神の加護",h:"かいりゅうしんのかご",e:"Aegis of the Ocean Dragon Lord",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターンのエンドフェイズ時まで、自分フィールド上の全てのレベル３以下の水属性モンスターは、戦闘及びカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ソウル",h:"さいこ・そうる",e:"Psychic Soul",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するサイキック族モンスター１体をリリースして発動する。\n自分はリリースしたモンスターのレベル×３００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベルカウンター",h:"ふれむべるかうんたー",e:"Flamvell Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地の守備力２００の炎属性モンスター１体をゲームから除外して発動できる。\n魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"身剣一体",h:"しんけんいったい",e:"At One With the Sword",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが、「X－セイバー」と名のついたモンスター１体のみの場合に発動できる。\n発動後このカードは攻撃力８００ポイントアップの装備カードとなり、そのモンスター１体に装備する。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"大番狂わせ",h:"おおばんくるわせ",e:"A Major Upset",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するレベル２以下のモンスター１体をリリースして発動する。\nフィールド上に表側表示で存在するレベル７以上の特殊召喚したモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラッドストーム",h:"ぶらっどすとーむ",e:"Battlestorm",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードの攻撃力は自分フィールド上に表側表示で存在する鳥獣族モンスターの数×１００ポイントアップする。\n自分フィールド上に鳥獣族モンスターが表側表示で３体以上存在する場合、相手フィールド上に存在する魔法・罠カード１枚を破壊する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ウォーアームズ",h:"こあきめいる・うぉーあーむず",e:"Koa'ki Meiru War Arms",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、自分の墓地に存在するレベル３以下の戦士族モンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる。\nこのカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力の半分の数値分アップする。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イモータル・ルーラー",h:"いもーたる・るーらー",e:"Immortal Ruler",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカードは特殊召喚できない。\n①：このカードをリリースし、自分の墓地の「アンデットワールド」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ハードアームドラゴン",h:"はーどあーむどらごん",e:"Hardened Armed Dragon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：このカードは手札のレベル８以上のモンスター１体を墓地へ送り、手札から特殊召喚できる。\n②：このカードをリリースして召喚したレベル７以上のモンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"モジャ",h:"もじゃ",e:"Moja",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在するレベル４の獣族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ビーストライカー",h:"びーすとらいかー",e:"Beast Striker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1850,de:400,tx:"手札を１枚捨てて発動する。\n自分のデッキから「モジャ」１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"キング・オブ・ビースト",h:"きんぐ・おぶ・びーすと",e:"King of the Beasts",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2500,de:800,tx:"自分フィールド上に表側表示で存在する「モジャ」１体をリリースして発動する。\nこのカードを手札または墓地から特殊召喚する。\n「キング・オブ・ビースト」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スワローズ・ネスト",h:"すわろーず・ねすと",e:"Swallow's Nest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の鳥獣族モンスター１体をリリースして発動できる。\nリリースしたモンスターと同じレベルの鳥獣族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーウェルム",h:"おーばーうぇるむ",e:"Overwhelm",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上にレベル７以上のアドバンス召喚したモンスターが表側表示で存在する場合に発動する事ができる。\n罠カードまたは効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"バーサーキング",h:"ばーさーきんぐ",e:"Berserking",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、フィールド上に表側表示で存在する獣族モンスター２体を選択して発動する事ができる。\nそのターンのエンドフェイズ時まで、選択したモンスター１体の攻撃力を半分にし、その数値分もう１体のモンスターの攻撃力をアップする。\nこの効果は自分のメインフェイズ時及び相手のバトルフェイズ時にのみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使ユコバック",h:"だてんしゆこばっく",e:"Darklord Ukoback",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:700,de:1000,tx:"「堕天使ユコバック」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「堕天使」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使ディザイア",h:"だてんしでぃざいあ",e:"Darklord Desire",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3000,de:2800,tx:"このカードは特殊召喚できない。\nこのカードは天使族モンスター１体をリリースしてアドバンス召喚する事ができる。\n１ターンに１度、自分のメインフェイズ時にこのカードの攻撃力を１０００ポイントダウンし、相手フィールド上に存在するモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ドゥクス",h:"どらぐにてぃ－どぅくす",e:"Dragunity Dux",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：このカードが召喚に成功した時、自分の墓地のドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、自分フィールドの「ドラグニティ」カードの数×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－レギオン",h:"どらぐにてぃ－れぎおん",e:"Dragunity Legionnaire",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが召喚に成功した時、自分の墓地のドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：自分の魔法＆罠ゾーンの表側表示の「ドラグニティ」カード１枚を墓地へ送り、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－トリブル",h:"どらぐにてぃ－とりぶる",e:"Dragunity Tribus",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"このカードが召喚・特殊召喚に成功した時、デッキからレベル３以下のドラゴン族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ブラックスピア",h:"どらぐにてぃ－ぶらっくすぴあ",e:"Dragunity Darkspear",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、自分フィールドのドラゴン族の「ドラグニティ」モンスター１体をリリースし、自分の墓地のレベル４以下の鳥獣族モンスター１体を対象として発動できる。\nその鳥獣族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ファランクス",h:"どらぐにてぃ－ふぁらんくす",e:"Dragunity Phalanx",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1100,tx:"①：１ターンに１度、このカードが装備カード扱いとして装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ディフ",h:"まごうしんでぃふ",e:"Fabled Dyf",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1700,tx:"「魔轟神」と名のついたモンスターが手札から自分の墓地へ送られた時、フィールド上のこのカードをリリースし、そのモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神アシェンヴェイル",h:"まごうしんあしぇんう゛ぇいる",e:"Fabled Ashenveil",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードが戦闘を行うそのダメージ計算時に１度、手札を１枚墓地へ送って発動できる。\nこのカードの攻撃力は、そのダメージ計算時のみ６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神オルトロ",h:"まごうしんおるとろ",e:"Fabled Oltro",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:500,tx:"１ターンに１度、手札を１枚墓地へ送って発動できる。\n手札からレベル３の「魔轟神」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・タイタン",h:"じゅらっく・たいたん",e:"Jurrac Titano",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3000,de:2800,tx:"このカードは特殊召喚できない。\nこのカードはフィールド上に表側表示で存在する限り、罠・効果モンスターの効果の対象にならない。\nまた、１ターンに１度、自分の墓地の攻撃力１７００以下の「ジュラック」と名のついたモンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・グアイバ",h:"じゅらっく・ぐあいば",e:"Jurrac Guaiba",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、デッキから攻撃力１７００以下の「ジュラック」と名のついたモンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターは、このターン攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・スタウリコ",h:"じゅらっく・すたうりこ",e:"Jurrac Stauriko",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードが戦闘によって破壊された場合、自分フィールド上に「ジュラックトークン」（恐竜族・炎・星１・攻／守０）２体を守備表示で特殊召喚する。\nこのトークンは「ジュラック」と名のついたモンスター以外のアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ホーストニードル",h:"なちゅる・ほーすとにーどる",e:"Naturia Horneedle",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"相手がモンスターの特殊召喚に成功した時、このカード以外の自分フィールド上に表側表示で存在する「ナチュル」と名のついたモンスター１体をリリースして発動できる。\nそのモンスターを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・フライトフライ",h:"なちゅる・ふらいとふらい",e:"Naturia Fruitfly",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードがフィールド上に表側表示で存在する限り、相手フィールド上のモンスターの攻撃力・守備力は、自分フィールド上の「ナチュル」と名のついたモンスターの数×３００ポイントダウンする。\nまた、１ターンに１度、相手フィールド上の守備力が０のモンスター１体を選択し、エンドフェイズ時までコントロールを得る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ハイドランジー",h:"なちゅる・はいどらんじー",e:"Naturia Hydrangea",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:2000,tx:"自分フィールド上の「ナチュル」と名のついたモンスターの効果が発動した自分のターン、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・アクセラレーター",h:"れある・じぇねくす・あくせられーたー",e:"R-Genex Accelerator",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1900,tx:"このカードがフィールド上に表側表示で存在し、「ジェネクス」と名のついたモンスターが自分のデッキから手札に加わった時、そのモンスター１体を相手に見せて発動できる。\nそのモンスターを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・オラクル",h:"れある・じぇねくす・おらくる",e:"R-Genex Oracle",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"このカードが「ジェネクス」と名のついたモンスターの効果によって自分のデッキから手札に加わった場合、このカードを手札から特殊召喚できる。\nこのカードをシンクロ素材とする場合、「ジェネクス」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・ウルティマム",h:"れある・じぇねくす・うるてぃまむ",e:"R-Genex Ultimum",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"フィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた時、自分の墓地の「ジェネクス」と名のついたモンスター２体を選択してデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の封魔団",h:"ひょうけっかいのふうまだん",e:"Spellbreaker of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：１ターンに１度、手札から「氷結界」モンスター１体を墓地へ送って発動できる。\nこのモンスターが表側表示で存在する間、次の自分ターンの終了時までお互いに魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の虎将 グルナード",h:"ひょうけっかいのこしょう　ぐるなーど",e:"General Grunard of the Ice Barrier",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「氷結界」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J リーサル・ウェポン",h:"あーりー・おぶ・じゃすてぃす　りーさる・うぇぽん",e:"Ally of Justice Omni-Weapon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"このカードが戦闘によって光属性モンスターを破壊し墓地へ送った時、デッキからカードを１枚ドローする。\nさらに、この効果でドローしたカードがレベル４以下の闇属性モンスターだった場合、そのカードを相手に見せて特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J D.D.チェッカー",h:"あーりー・おぶ・じゃすてぃす　でぃーでぃーちぇっかー",e:"Ally of Justice Quarantine",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、お互いに光属性モンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J サイクルリーダー",h:"あーりー・おぶ・じゃすてぃす　さいくるりーだー",e:"Ally of Justice Cycle Reader",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードを手札から墓地へ捨てて発動できる。\n相手の墓地の光属性モンスターを２体まで選択し、ゲームから除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ウォーロード",h:"わーむ・うぉーろーど",e:"Worm Warlord",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2350,de:1800,tx:"このカードは特殊召喚できない。\nこのカードが戦闘で破壊した効果モンスターの効果は無効化される。\nこのカードが戦闘によって相手モンスターを破壊した場合、もう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ゼクス",h:"わーむ・ぜくす",e:"Worm Xex",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが召喚に成功した時、デッキから「ワーム」と名のついた爬虫類族モンスター１体を墓地へ送る事ができる。\n自分フィールド上に「ワーム・ヤガン」が存在する場合、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ヤガン",h:"わーむ・やがん",e:"Worm Yagan",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"自分フィールド上のモンスターが「ワーム・ゼクス」１体のみの場合、このカードを墓地から裏側守備表示で特殊召喚できる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。\nこのカードがリバースした時、相手フィールド上に表側表示で存在するモンスター１体を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ゼロ",h:"わーむ・ぜろ",e:"Worm Zero",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"爬虫類族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:0,tx:"「ワーム」と名のついた爬虫類族モンスター×２体以上\nこのカードの攻撃力は、このカードの融合素材としたモンスターの種類×５００ポイントになる。\nまた、このカードは融合素材としたモンスターの種類によって以下の効果を得る。\n●２種類以上：１ターンに１度、自分の墓地の爬虫類族モンスター１体を選択し、裏側守備表示で特殊召喚できる。\n●４種類以上：自分の墓地の爬虫類族モンスター１体をゲームから除外して発動できる。\nフィールド上のモンスター１体を選択して墓地へ送る。\n●６種類以上：１ターンに１度、デッキからカードを１枚ドローできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－ゲイボルグ",h:"どらぐにてぃないと－げいぼるぐ",e:"Dragunity Knight - Gae Bulg",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1100,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上\n①：このカードが戦闘を行うダメージステップ開始時からダメージ計算前までに１度、自分の墓地から鳥獣族モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで、除外したそのモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神レイジオン",h:"まごうしんれいじおん",e:"Fabled Ragin",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"「魔轟神」チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\n自分は手札が２枚になるようにデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・ジェネクス・ヴィンディカイト",h:"れある・じぇねくす・う゛ぃんでぃかいと",e:"Vindikite R-Genex",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1000,tx:"「ジェネクス」と名のついたチューナー＋チューナー以外の風属性モンスター１体以上\n相手はこのカードを攻撃対象に選択できない。\nこのカードが戦闘によって相手モンスターを破壊した場合、デッキから「ジェネクス」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J ディサイシブ・アームズ",h:"あーりー・おぶ・じゃすてぃす　でぃさいしぶ・あーむず",e:"Ally of Justice Decisive Armor",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3300,tx:"チューナー＋チューナー以外のモンスター２体以上\n相手フィールド上に光属性モンスターが存在する場合、１ターンに１度、以下の効果から１つを選択して発動できる。\n●相手フィールド上にセットされたカード１枚を選択して破壊する。\n●手札を１枚墓地へ送る事で、相手フィールド上の魔法・罠カードを全て破壊する。\n●自分の手札を全て墓地へ送る事で、相手の手札を確認してその中から光属性モンスターを全て墓地へ送る。\nその後、この効果で墓地へ送ったモンスターの攻撃力の合計分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ランス・リンドブルム",h:"らんす・りんどぶるむ",e:"Lancer Lindwurm",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーレム・ドラゴン",h:"ごーれむ・どらごん",e:"Golem Dragon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:200,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、相手は表側表示で存在する他のドラゴン族モンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ラーバァ",h:"さいばー・らーばぁ",e:"Cyber Larva",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:600,tx:"①：このカードが攻撃対象に選択された場合に発動する。\nこのターン、自分が受ける全ての戦闘ダメージは０になる。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「サイバー・ラーバァ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴニック・タクティクス",h:"どらごにっく・たくてぃくす",e:"Dragonic Tactics",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するドラゴン族モンスター２体をリリースして発動する。\n自分のデッキからレベル８のドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－ガジャルグ",h:"どらぐにてぃないと－がじゃるぐ",e:"Dragunity Knight - Gae Dearg",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:800,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上 \n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキからレベル４以下の、ドラゴン族または鳥獣族のモンスター１体を手札に加える。\nその後、手札からドラゴン族または鳥獣族のモンスター１体を選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"エマージェンシー・サイバー",h:"えまーじぇんしー・さいばー",e:"Cyber Emergency",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「サイバー・ドラゴン」モンスターまたは通常召喚できない機械族・光属性モンスター１体を手札に加える。\n②：相手によってこのカードの発動が無効になり、墓地へ送られた場合、手札を１枚捨てて発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ボーン・フロム・ドラコニス",h:"ぼーん・ふろむ・どらこにす",e:"Born from Draconis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを特殊召喚できない。\n①：自分の墓地及び自分フィールドの表側表示モンスターの中から、機械族・光属性モンスターを全て除外して発動できる。\n手札からレベル６以上の機械族・光属性モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力はこのカードを発動するために除外したモンスターの数×５００になり、自身以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エルタニン",h:"さいばー・えるたにん",e:"Cyber Eltanin",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分の墓地及び自分フィールドの表側表示モンスターの中から、機械族・光属性モンスターを全て除外した場合のみ特殊召喚できる。\n①：このカードの攻撃力・守備力は、このカードを特殊召喚するために除外したモンスターの数×５００になる。\n②：このカードが特殊召喚に成功した場合に発動する。\nこのカード以外のフィールドの表側表示モンスターを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"フェニックス・ギア・フリード",h:"ふぇにっくす・ぎあ・ふりーど",e:"Phoenix Gearfried",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:2800,de:2200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●相手が魔法カードを発動した場合、自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n\n●フィールドのモンスターを対象とする魔法・罠カードが発動した時、自分フィールドの表側表示の装備カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルテクター シュバリエ",h:"えう゛ぉるてくたー　しゅばりえ",e:"Evocator Chevalier",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1900,de:900,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分フィールドの表側表示の装備カード１枚を墓地へ送り、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フェデライザー",h:"ふぇでらいざー",e:"Featherizer",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:700,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからデュアルモンスター１体を墓地へ送り、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアル・ソルジャー",h:"でゅある・そるじゃー",e:"Gemini Soldier",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:500,de:300,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードは１ターンに１度だけ、戦闘では破壊されない。\nこのカードが戦闘を行う場合、そのダメージ計算後に自分のデッキから「デュアル・ソルジャー」以外のレベル４以下のデュアルモンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スーペルヴィス",h:"すーぺるう゛ぃす",e:"Supervise",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"デュアルモンスターにのみ装備可能。\n①：装備モンスターはもう１度召喚された状態として扱う。\n②：表側表示のこのカードがフィールドから墓地へ送られた場合、自分の墓地の通常モンスター１体を対象として発動する。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"救世竜 セイヴァー・ドラゴン",h:"きゅうせいりゅう　せいう゛ぁー・どらごん",e:"Majestic Dragon",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをS素材とする場合、「セイヴァー」モンスターのS召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・シャオロン",h:"すたーだすと・しゃおろん",e:"Stardust Xiaolong",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分が「スターダスト・ドラゴン」のシンクロ召喚に成功した時、墓地のこのカードを表側攻撃表示で特殊召喚できる。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マックス・ウォリアー",h:"まっくす・うぉりあー",e:"Max Warrior",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が４００ポイントアップする。\nこのカードが戦闘によって相手モンスターを破壊した場合、次の自分のスタンバイフェイズ時までこのカードのレベルは２になり、元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"クイック・シンクロン",h:"くいっく・しんくろん",e:"Quickdraw Synchron",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:1400,tx:"このカードは「シンクロン」チューナーの代わりとしてS素材にできる。\nこのカードをS素材とする場合、「シンクロン」チューナーを素材とするSモンスターのS召喚にしか使用できない。\n①：このカードは手札のモンスター１体を墓地へ送り、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル・スティーラー",h:"れべる・すてぃーらー",e:"Level Eater",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"①：このカードはモンスターゾーンに存在する限り、アドバンス召喚以外のためにはリリースできない。\n②：このカードが墓地に存在する場合、自分フィールドのレベル５以上のモンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、このカードを墓地から特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"ゼロ・ガードナー",h:"ぜろ・がーどなー",e:"Zero Gardna",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをリリースして発動する。\nこのターン自分のモンスターは戦闘では破壊されず、相手モンスターとの戦闘によって発生する自分への戦闘ダメージは０になる。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レグルス",h:"れぐるす",e:"Regulus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分の墓地に存在するフィールド魔法カード１枚を選択して発動する。\n選択したカードをデッキに戻す。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ネクロマンサー",h:"いんふぇるにてぃ・ねくろまんさー",e:"Infernity Necromancer",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは召喚に成功した時、守備表示になる。\nまた、自分の手札が０枚の場合、このカードは以下の効果を得る。\n１ターンに１度、自分の墓地から「インフェルニティ・ネクロマンサー」以外の「インフェルニティ」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・ウインディー",h:"ふぉーちゅんれでぃ・ういんでぃー",e:"Fortune Lady Wind",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、このカードのレベル×３００ポイントになる。\nまた、自分のスタンバイフェイズ時、このカードのレベルを１つ上げる（最大レベル１２まで）。\nこのカードが召喚に成功した時、自分フィールド上の「フォーチュンレディ」と名のついたモンスターの数だけ、相手フィールド上の魔法・罠カードを選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・ウォーテリー",h:"ふぉーちゅんれでぃ・うぉーてりー",e:"Fortune Lady Water",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの攻撃力・守備力はこのカードのレベル×３００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\n③：自分フィールドに「フォーチュンレディ・ウォーテリー」以外の「フォーチュンレディ」モンスターが存在し、このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・ダルキー",h:"ふぉーちゅんれでぃ・だるきー",e:"Fortune Lady Dark",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、このカードのレベル×４００ポイントになる。\nまた、自分のスタンバイフェイズ時、このカードのレベルを１つ上げる（最大レベル１２まで）。\nこのカードが自分フィールド上に表側表示で存在する限り、自分フィールド上の「フォーチュンレディ」と名のついたモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の墓地の「フォーチュンレディ」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・アーシー",h:"ふぉーちゅんれでぃ・あーしー",e:"Fortune Lady Earth",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、このカードのレベル×４００ポイントになる。\nまた、自分のスタンバイフェイズ時、このカードのレベルを１つ上げる（最大レベル１２まで）。\nこのカードのレベルが上がった時、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ソリテア・マジカル",h:"そりてあ・まじかる",e:"Solitaire Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"自分フィールド上に表側表示で存在する「フォーチュンレディ」と名のついたモンスター１体と、フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した「フォーチュンレディ」と名のついたモンスターのレベルを３つ下げ、選択したもう１体のモンスターを破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"カトブレパスと運命の魔女",h:"かとぶれぱすとうんめいのまじょ",e:"Catoblepas and the Witch of Fate",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"相手がモンスターの特殊召喚に成功した時、自分の墓地に存在する攻撃力が？のモンスター１体をゲームから除外する事で、その特殊召喚したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・スパイダー",h:"だーく・すぱいだー",e:"Dark Spider",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分フィールド上に表側表示で存在する昆虫族モンスター１体のレベルをエンドフェイズ時まで２つ上げる事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"グランド・スパイダー",h:"ぐらんど・すぱいだー",e:"Ground Spider",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"このカードが自分フィールド上に表側守備表示で存在する場合に相手がモンスターの召喚・特殊召喚に成功した時、そのモンスターを守備表示にする事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サクリファイス・スパイダー",h:"さくりふぁいす・すぱいだー",e:"Relinquished Spider",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"自分の墓地に昆虫族モンスターが４体以上存在する場合、このカードをリリースして発動する。\n相手フィールド上に表側守備表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スパイダー・スパイダー",h:"すぱいだー・すぱいだー",e:"Spyder Spider",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードが戦闘によって相手フィールド上に守備表示で存在するモンスターを破壊した場合、自分の墓地に存在するレベル４以下の昆虫族モンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マザー・スパイダー",h:"まざー・すぱいだー",e:"Mother Spider",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2300,de:1200,tx:"自分の墓地に存在するモンスターが昆虫族のみの場合、このカードは相手フィールド上に表側守備表示で存在するモンスター２体を墓地へ送り、手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ゴルゴーン",h:"れぷてぃれす・ごるごーん",e:"Reptilianne Gorgon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが攻撃を行ったダメージ計算後、このカードと戦闘を行ったモンスターは攻撃力が０になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・メデューサ",h:"れぷてぃれす・めでゅーさ",e:"Reptilianne Medusa",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"手札を１枚墓地へ送り、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターは攻撃力が０になり、表示形式を変更する事ができなくなる。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・スキュラ",h:"れぷてぃれす・すきゅら",e:"Reptilianne Scylla",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカードが戦闘によって攻撃力０のモンスターを破壊した場合、そのモンスターを墓地から自分フィールド上に表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・バイパー",h:"れぷてぃれす・ばいぱー",e:"Reptilianne Viper",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが召喚に成功した時、相手フィールドの攻撃力０のモンスター１体を対象として発動できる。\nその攻撃力０のモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神 Ccarayhua",h:"じばくしん　こからいあ",e:"Earthbound Immortal Ccarayhua",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2800,de:1800,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、このカードがこのカード以外の効果によって破壊された時、フィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神 Uru",h:"じばくしん　うる",e:"Earthbound Immortal Uru",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、１ターンに１度、このカード以外の自分フィールド上のモンスター１体をリリースする事で、相手フィールド上に表側表示で存在するモンスター１体を選択し、エンドフェイズ時までコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神 Wiraqocha Rasca",h:"じばくしん　うぃらこちゃ　らすか",e:"Earthbound Immortal Wiraqocha Rasca",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：「地縛神」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードが召喚に成功した場合、相手の手札の数までこのカード以外の自分フィールドのカードを対象として発動する（最大３枚まで）。\nそのカードを持ち主のデッキに戻す。\nその後、戻した数だけ相手の手札をランダムに選んで捨て、このカードの攻撃力は捨てた数×１０００アップする。\n③：このカードは直接攻撃できる。\n④：相手はこのカードを攻撃対象に選択できない。\n⑤：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・シーパンサー",h:"こあきめいる・しーぱんさー",e:"Koa'ki Meiru Sea Panther",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の水属性モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、手札から「コアキメイルの鋼核」１枚を墓地へ送る事で、自分の墓地に存在する魔法カード１枚を選択しデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ルークロード",h:"こあきめいる・るーくろーど",e:"Koa'ki Meiru Rooklord",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードは「コアキメイル」と名のついたモンスター１体をリリースしてアドバンス召喚できる。\nこのカードが召喚に成功した時、自分の墓地の「コアキメイル」と名のついたカード１枚をゲームから除外する事で、相手フィールド上のカードを２枚まで選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"チューンド・マジシャン",h:"ちゅーんど・まじしゃん",e:"Tuned Magician",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードがフィールド上に表側表示で存在する限り、このカードをチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"クルセイダー・オブ・エンディミオン",h:"くるせいだー・おぶ・えんでぃみおん",e:"Crusader of Endymion",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["デュアル","効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置き、このカードの攻撃力はターン終了時まで６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"樹海の射手",h:"うっどらんど・あーちゃー",e:"Woodland Archer",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカードは通常召喚できない。\n自分の墓地に通常モンスターが２体以上存在する場合のみ特殊召喚する事ができる。\nこのカードをリリースする事で、自分のデッキからデュアルモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮魔闘士",h:"ぐれんまとうし",e:"Knight of the Red Lotus",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1800,tx:"①：このカードは、自分の墓地の通常モンスターが３体の場合、その内の２体を除外し、手札から特殊召喚できる。\n②：１ターンに１度、自分の墓地のレベル４以下の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エナジー・ブレイブ",h:"えなじー・ぶれいぶ",e:"Energy Bravery",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在する再度召喚された状態のデュアルモンスターは、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"鬼ガエル",h:"きがえる",e:"Swap Frog",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"①：このカードは手札からこのカード以外の水属性モンスター１体を捨てて、手札から特殊召喚できる。\n②：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nデッキ及び自分フィールドの表側表示モンスターの中から、レベル２以下の水族・水属性モンスター１体を選んで墓地へ送る。\n③：１ターンに１度、自分フィールドのモンスター１体を持ち主の手札に戻して発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「鬼ガエル」以外の「ガエル」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ロードブリティッシュ",h:"ろーどぶりてぃっしゅ",e:"Lord British Space Fighter",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが戦闘によって相手モンスターを破壊した場合、以下の効果から１つを選択して発動する。\n\n●このカードはもう１度だけ続けて攻撃できる。\n\n●フィールド上にセットされたカード１枚を選択して破壊する。\n\n●自分フィールド上に「マルチプルトークン」（機械族・光・星４・攻／守１２００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オシャレオン",h:"おしゃれおん",e:"Oshaleon",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、相手は「オシャレオン」以外のモンスターを攻撃対象に選択する事はできない。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力５００以下の爬虫類族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式魔人リリーサー",h:"ぎしきまじんりりーさー",e:"Djinn Releaser of Rituals",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、墓地のこのカードを除外できる。\n②：このカードを使用して儀式召喚したプレイヤーから見て相手は、儀式召喚したそのモンスターがモンスターゾーンに表側表示で存在する限り、モンスターを特殊召喚できない。",li:0,lk:[],ta:[],al:[]},
  {n:"儀式魔人プレサイダー",h:"ぎしきまじんぷれさいだー",e:"Djinn Presider of Rituals",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、自分の墓地のこのカードをゲームから除外できる。\nまた、このカードを儀式召喚に使用した儀式モンスターが戦闘によってモンスターを破壊した場合、その儀式モンスターのコントローラーはデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"救世の美神ノースウェムコ",h:"きゅうせいのびしんのーすうぇむこ",e:"Divine Grace - Northwemko",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1200,tx:"「救世の儀式」により降臨。\nこのカードが儀式召喚に成功した時、このカードの儀式召喚に使用したモンスターの数まで、このカード以外のフィールド上に表側表示で存在するカードを選択して発動する。\n選択したカードがフィールド上に表側表示で存在する限り、このカードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"セイヴァー・スター・ドラゴン",h:"せいう゛ぁー・すたー・どらごん",e:"Majestic Star Dragon",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3800,de:3000,tx:"「救世竜 セイヴァー・ドラゴン」＋「スターダスト・ドラゴン」＋チューナー以外のモンスター１体\n相手が魔法・罠・効果モンスターの効果を発動した時、このカードをリリースする事でその発動を無効にし、相手フィールド上のカードを全て破壊する。\n１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択し、その効果をエンドフェイズ時まで無効にできる。\nまた、この効果で無効にしたモンスターに記された効果を、このターンこのカードの効果として１度だけ発動できる。\nエンドフェイズ時、このカードをエクストラデッキに戻し、自分の墓地の「スターダスト・ドラゴン」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－孤高のシルバー・ウィンド",h:"ぶらっくふぇざー－ここうのしるばー・うぃんど",e:"Blackwing - Silverwind the Ascendant",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「BF」と名のついたチューナー＋チューナー以外のモンスター２体以上\nこのカードがシンクロ召喚に成功した時、このカードの攻撃力よりも低い守備力を持つ、フィールド上のモンスターを２体まで選択して破壊できる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\nまた、相手のターンに１度だけ、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「BF」と名のついたモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ラミア",h:"れぷてぃれす・らみあ",e:"Reptilianne Hydra",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1500,tx:"「レプティレス」チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\n相手フィールドの攻撃力０のモンスターを全て破壊し、破壊した数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・ブルドラゴ",h:"ぶらっく・ぶるどらご",e:"Black Brutdrago",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2600,tx:"チューナー＋チューナー以外のデュアルモンスター１体以上\n１ターンに１度、手札からデュアルモンスター１体を墓地へ送って発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。\nまた、このカードが破壊され墓地へ送られた時、自分の墓地のデュアルモンスター１体を選択して特殊召喚できる。\nこの効果で特殊召喚したデュアルモンスターは再度召喚された状態になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクスプローシブ・マジシャン",h:"えくすぷろーしぶ・まじしゃん",e:"Explosive Magician",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"チューナー＋チューナー以外の魔法使い族モンスター１体以上\n自分フィールド上の魔力カウンターを２つ取り除いて発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スパイダー・ウェブ",h:"すぱいだー・うぇぶ",e:"Spider Web",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"モンスターが攻撃宣言をした場合、そのモンスターはダメージステップ終了時に守備表示になり、そのモンスターのコントローラーから見て次の自分のターンのエンドフェイズ時まで表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛旋風",h:"じばくせんぷう",e:"Earthbound Whirlwind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「地縛神」と名のついたモンスターが存在する場合に発動できる。\n相手フィールド上の魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サベージ・コロシアム",h:"さべーじ・ころしあむ",e:"Savage Colosseum",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するモンスターが攻撃を行った場合、そのモンスターのコントローラーはダメージステップ終了時に３００ライフポイント回復する。\nこのカードがフィールド上に存在する限り、攻撃可能なモンスターは攻撃しなければならない。\nエンドフェイズ時、ターンプレイヤーのフィールド上に表側攻撃表示で存在する攻撃宣言をしていないモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アタック・フェロモン",h:"あたっく・ふぇろもん",e:"Attack Pheromones",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する爬虫類族モンスターが守備表示モンスターを攻撃した場合、そのモンスターをダメージステップ終了時に表側攻撃表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"モルティング・エスケープ",h:"もるてぃんぐ・えすけーぷ",e:"Molting Escape",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"爬虫類族モンスターにのみ装備可能。\n装備モンスターは１ターンに１度だけ、戦闘では破壊されない。\nこの効果を適用したダメージステップ終了時、装備モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・スポーン",h:"れぷてぃれす・すぽーん",e:"Reptilianne Spawn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「レプティレス」と名のついたモンスター１体をゲームから除外して発動する。\n自分フィールド上に「レプティレストークン」（爬虫類族・地・星１・攻／守０）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンフューチャー",h:"ふぉーちゅんふゅーちゃー",e:"Fortune's Future",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：除外されている自分の「フォーチュンレディ」モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"タイムパッセージ",h:"たいむぱっせーじ",e:"Time Passage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「フォーチュンレディ」と名のついたモンスター１体を選択し、そのレベルをエンドフェイズ時まで３つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼核収納",h:"こうかくしゅうのう",e:"Iron Core Armor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「コアキメイル」と名のついたモンスターにのみ装備可能。\n装備モンスターと戦闘を行う相手モンスターの攻撃力は、そのダメージ計算時のみ装備モンスターのレベル×１００ポイントダウンする。\n装備モンスターがエンドフェイズ時に破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"金剛真力",h:"こんごうしんりき",e:"Herculean Power",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、手札からレベル４以下のデュアルモンスター１体を特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアルスパーク",h:"でゅあるすぱーく",e:"Gemini Spark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示のレベル４のデュアルモンスター１体をリリースし、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"救世の儀式",h:"きゅうせいのぎしき",e:"Ritual of Grace",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「救世の美神ノースウェムコ」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が７以上になるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外し、自分フィールド上の儀式モンスター１体を選択して発動できる。\nこのターン選択した自分の儀式モンスターはカードの効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式の準備",h:"ぎしきのじゅんび",e:"Preparation of Rites",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからレベル７以下の儀式モンスター１体を手札に加える。\nその後、自分の墓地の儀式魔法カード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲なウツボ",h:"ごうよくなうつぼ",e:"Moray of Greed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札の水属性モンスター２体をデッキに戻してシャッフルする。\nその後、自分はデッキから３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"神聖なる森",h:"すぴりちゅある・ふぉれすと",e:"Spiritual Forest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の植物族・獣族・獣戦士族モンスターは、１ターンに１度だけ戦闘では破壊されない。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"狂植物の氾濫",h:"きょうしょくぶつのはんらん",e:"Raging Mad Plants",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の全ての植物族モンスターの攻撃力は、エンドフェイズ時まで自分の墓地の植物族モンスターの数×３００ポイントアップする。\nこのターンのエンドフェイズ時、自分フィールド上の植物族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"無視加護",h:"むしかご",e:"Insect Neglect",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手モンスターの攻撃宣言時、自分の墓地の昆虫族モンスター１体をゲームから除外する事で、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔への貢物",h:"あくまへのみつぎもの",e:"Faustian Bargain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の特殊召喚されたモンスター１体を選択して墓地へ送り、手札からレベル４以下の通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スリップ・サモン",h:"すりっぷ・さもん",e:"Slip Summon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターの召喚・反転召喚・特殊召喚に成功した時に発動する事ができる。\n手札からレベル４以下のモンスター１体を表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・バリアー",h:"しんくろ・ばりあー",e:"Synchro Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するシンクロモンスター１体をリリースして発動する。\n次のターンのエンドフェイズ時まで、自分が受ける全てのダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"未来王の予言",h:"みらいおうのよげん",e:"Enlightenment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する魔法使い族モンスターの攻撃によって相手モンスターを破壊した場合、そのダメージステップ終了時に発動する事ができる。\nそのモンスターはもう１度だけ続けて攻撃する事ができる。\nこのカードを発動するターン、自分は召喚・反転召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"運命湾曲",h:"うんめいわんきょく",e:"Bending Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが「フォーチュンレディ」と名のついたモンスターのみの場合に発動できる。\n魔法・罠カードの発動、モンスターの召喚のどれか１つを無効にし、そのカードをゲームから除外する。\nこのターンのエンドフェイズ時に、この効果でゲームから除外したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュン・インハーリット",h:"ふぉーちゅん・いんはーりっと",e:"Inherited Fortune",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「フォーチュンレディ」と名のついたモンスターが破壊されたターンに発動する事ができる。\n次の自分のスタンバイフェイズ時に手札から「フォーチュンレディ」と名のついたモンスターを２体まで特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スパイダー・エッグ",h:"すぱいだー・えっぐ",e:"Spider Egg",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時、自分の墓地に昆虫族モンスターが３体以上存在する場合に発動する事ができる。\nそのモンスターの攻撃を無効にし、自分フィールド上に「スパイダー・トークン」（昆虫族・地・星１・攻／守１００）３体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"無抵抗の真相",h:"むていこうのしんそう",e:"Wolf in Sheep's Clothing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃によって自分が戦闘ダメージを受けた時、手札のレベル１モンスター１体を相手に見せて発動する。\n相手に見せたモンスター１体と、自分のデッキに存在する同名モンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛波",h:"ぐらんど・うぇーぶ",e:"Earthbound Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「地縛神」と名のついたモンスターが存在する場合に発動できる。\n相手が発動した魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神の咆哮",h:"じばくしんのほうこう",e:"Roar of the Earthbound Immortal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時、攻撃モンスターの攻撃力が自分フィールド上に表側表示で存在する「地縛神」と名のついたモンスターの攻撃力より低い場合、その攻撃モンスター１体を破壊し、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"極限への衝動",h:"きょくげんへのしょうどう",e:"Limit Impulse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を２枚墓地へ送って発動する。\n自分フィールド上に「ソウルトークン」（悪魔族・闇・星１・攻／守０）２体を特殊召喚する。\nこのトークンはアドバンス召喚以外のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・フォース",h:"いんふぇるにてぃ・ふぉーす",e:"Infernity Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札が０枚の場合、「インフェルニティ」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃モンスター１体を破壊し、自分の墓地に存在する「インフェルニティ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"能力吸収コア",h:"のうりょくきゅうしゅうこあ",e:"Nega-Ton Corepanel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「コアキメイル」と名のついたモンスターが表側表示で存在し、自分の墓地に「コアキメイルの鋼核」が存在する場合に発動する事ができる。\n効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴィクティム・カウンター",h:"う゛ぃくてぃむ・かうんたー",e:"Gemini Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するデュアルモンスター１体を裏側守備表示にし、相手の魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアル・ブースター",h:"でゅある・ぶーすたー",e:"Gemini Booster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのデュアルモンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力７００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：装備カード扱いのこのカードが破壊され墓地へ送られた場合、フィールドのデュアルモンスター１体を対象として発動する。\nそのデュアルモンスターはもう１度召喚された状態になる。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュアルバスター",h:"りちゅあるばすたー",e:"Ritual Buster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手が儀式召喚に成功した時に発動できる。\n次の自分ターンのスタンバイフェイズまで、相手は魔法・罠カードの発動及びその効果の発動ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"デビリアン・ソング",h:"でびりあん・そんぐ",e:"Stygian Dirge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手フィールド上の全てのモンスターのレベルは１つ下がる。",li:"",lk:[],ta:[],al:[]},
  {n:"破邪の刻印",h:"はじゃのこくいん",e:"Seal of Wickedness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のスタンバイフェイズ時に１度だけ、フィールド上に表側表示で存在するカード１枚を選択する。\n選択されたカードの効果はそのターン中無効になる。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮の指名者",h:"ぐれんのしめいしゃ",e:"Appointer of the Red Lotus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：２０００LPを払い、手札を全て相手に見せて発動できる。\n相手の手札を確認し、その中から１枚を選んで、次の相手エンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ベルグザーク",h:"こあきめいる・べるぐざーく",e:"Koa'ki Meiru Bergzak",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードが戦闘によって相手モンスターを破壊した場合、もう１度だけ続けて攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーム・ワーム",h:"うぉーむ・わーむ",e:"Warm Worm",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:600,de:1400,tx:"①：このカードが破壊された場合に発動する。\n相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・ベイト",h:"わーむ・べいと",e:"Worm Bait",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はレベル３・４のモンスターを召喚・特殊召喚できない。\n①：自分フィールドに昆虫族モンスターが存在する場合に発動できる。\n自分フィールドに「ワームトークン」（昆虫族・地・星１・攻／守０）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リグレット・リボーン",h:"りぐれっと・りぼーん",e:"Regretful Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時に発動できる。\nそのモンスター１体を選択して自分フィールド上に表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"シールド・ワーム",h:"しーるど・わーむ",e:"Shield Worm",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードは召喚・反転召喚・特殊召喚に成功した時、守備表示になる。\nさらに、自分フィールド上に表側表示で存在する昆虫族モンスターの数だけ、相手のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー ガルドストライク",h:"だぶるえっくす－せいばー　がるどすとらいく",e:"XX-Saber Gardestrike",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"自分の墓地に「X－セイバー」と名のついたモンスターが２体以上存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー フラムナイト",h:"だぶるえっくす－せいばー　ふらむないと",e:"XX-Saber Fulhelmknight",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:1000,tx:"①：このカードがフィールドに表側表示で存在する限り１度だけ、相手モンスターの攻撃宣言時にそのモンスター１体を対象として発動できる。\nその攻撃を無効にする。\n②：このカードが戦闘で相手の守備表示モンスターを破壊した場合、自分の墓地のレベル４以下の「X－セイバー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・グールズスレイブ",h:"こあきめいる・ぐーるずすれいぶ",e:"Koa'ki Meiru Ghoulungulate",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2500,de:1700,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札のアンデット族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n自分フィールド上に存在する「コアキメイル」と名のついたモンスター１体が戦闘またはカードの効果によって破壊される場合、代わりに自分の墓地に存在する「コアキメイル」と名のついたモンスター１体をゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・グラヴィローズ",h:"こあきめいる・ぐらう゛ぃろーず",e:"Koa'ki Meiru Gravirose",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:1300,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の植物族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n自分のスタンバイフェイズ時に１度だけ、自分のデッキからレベル３以下のモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・エンペラー",h:"さいこ・えんぺらー",e:"Psychic Emperor",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードが召喚・特殊召喚に成功した時、自分の墓地に存在するサイキック族モンスター１体につき、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"カードガード",h:"かーどがーど",e:"Card Guard",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカードが召喚・特殊召喚に成功した時、このカードにガードカウンターを１つ置く。\nこのカードに乗っているガードカウンター１つにつき、このカードの攻撃力は３００ポイントアップする。\nまた、１ターンに１度、このカードに乗っているガードカウンターを１つ取り除き、このカード以外の自分フィールド上に表側表示で存在するカード１枚にガードカウンターを１つ置く事ができる。\n選択したカードが破壊される場合、代わりにガードカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"フレムベル・デスガンナー",h:"ふれむべる・ですがんなー",e:"Flamvell Commando",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2200,de:200,tx:"このカードは特殊召喚できない。\n自分フィールド上に存在する「フレムベル」と名のついたモンスター１体をリリースした場合のみ召喚する事ができる。\n１ターンに１度、自分の墓地に存在する守備力２００のモンスター１体をゲームから除外する事で、除外したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"擬似空間",h:"ぎじくうかん",e:"Pseudo Space",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分の墓地からフィールド魔法カード１枚を除外して発動できる。\nエンドフェイズまで、このカードは除外したカードと同名カードとして扱い、同じ効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"グリード・グラード",h:"ぐりーど・ぐらーど",e:"Greed Grado",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分が相手フィールド上に表側表示で存在するシンクロモンスターを戦闘またはカードの効果によって破壊したターンに発動する事ができる。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"早すぎた復活",h:"はやすぎたふっかつ",e:"Revival of the Immortals",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「地縛神」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターは、そのターン攻撃宣言をする事はできない。\nまた、この効果で特殊召喚したモンスターが戦闘を行う場合、相手プレイヤーが受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"オレイカルコス・シュノロス",h:"おれいかるこす・しゅのろす",e:"Orichalcos Shunoros",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\nこのカードの①の効果で特殊召喚できる。\n①：自分の通常モンスターが戦闘で破壊された場合、ダメージステップ終了時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力は相手フィールドのモンスターの数×１０００アップする。\n③：このカードがモンスターゾーンに存在する限り、フィールドのレベル４通常モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・エアトス",h:"がーでぃあん・えあとす",e:"Guardian Eatos",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：自分の墓地にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードに装備された自分フィールドの装備魔法カード１枚を墓地へ送り、相手の墓地のモンスターを３体まで対象として発動できる。\nそのモンスターを除外する。\nこのカードの攻撃力はターン終了時まで、この効果で除外したモンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークネス・ネオスフィア",h:"だーくねす・ねおすふぃあ",e:"Darkness Neosphere",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n相手モンスターの攻撃宣言時、自分の手札・フィールド上から悪魔族モンスターをそれぞれ１体ずつ墓地へ送る事でのみ、このカードを手札から特殊召喚する事ができる。\nこのカードは戦闘では破壊されない。\n１ターンに１度、自分フィールド上に表側表示で存在する罠カードを全て手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリアー・バイス・ドラゴン",h:"くりあー・ばいす・どらごん",e:"Clear Vice Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードがフィールド上に表側表示で存在する限り、このカードのコントローラーに「クリアー・ワールド」の効果は適用されない。\nこのカードが相手モンスターに攻撃する場合、ダメージ計算時のみこのカードの攻撃力は攻撃対象モンスターの攻撃力の倍になる。\nこのカードが相手のカードの効果によって破壊される場合、代わりに自分の手札を１枚捨てる事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリアー・ワールド",h:"くりあー・わーるど",e:"Clear World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは自分のエンドフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。\nお互いは、コントロールしている属性によって以下を適用する。\n●光：自分は手札を全て公開し続ける。\n●闇：自分フィールド上のモンスターが２体以上の場合、自分は攻撃宣言できない。\n●地：自分のスタンバイフェイズ時、自分の表側守備表示モンスター１体を破壊する。\n●水：自分のエンドフェイズ時、自分の手札を１枚捨てる。\n●炎：自分のエンドフェイズ時、自分は１０００ポイントダメージを受ける。\n●風：自分は５００ライフポイント払わなければ魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO フラッシュ",h:"えれめんたるひーろー　ふらっしゅ",e:"Elemental HERO Flash",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地からこのカードと「E・HERO」と名のついたモンスターカード３種類をゲームから除外する事で、自分の墓地に存在する通常魔法カード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO The シャイニング",h:"えれめんたるひーろー　ざ　しゃいにんぐ",e:"Elemental HERO The Shining",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「E・HERO」と名のついたモンスター＋光属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードの攻撃力は、ゲームから除外されている自分の「E・HERO」と名のついたモンスターの数×３００ポイントアップする。\nこのカードがフィールド上から墓地へ送られた時、ゲームから除外されている自分の「E・HERO」と名のついたモンスターを２体まで選択し、手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・ドゥルダーク",h:"あーりー・じぇねくす・どぅるだーく",e:"Genex Ally Duradark",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\nこのカードと同じ属性を持つ、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して破壊する。\nこの効果を発動するターンこのカードは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣ルビィラーダ",h:"まごうしんじゅうるびぃらーだ",e:"The Fabled Rubyruda",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:800,tx:"自分フィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、手札から「魔轟神」と名のついたモンスター１体を捨てる事でその攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－ヴァジュランダ",h:"どらぐにてぃないと－う゛ぁじゅらんだ",e:"Dragunity Knight - Vajrayana",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:1200,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上 \n①：このカードがS召喚に成功した時、自分の墓地のドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：１ターンに１度、このカードに装備された自分フィールドの装備カード１枚を墓地へ送って発動できる。\nこのカードの攻撃力はターン終了時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・リモート",h:"あーりー・じぇねくす・りもーと",e:"Genex Ally Remote",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1800,tx:"１ターンに１度、フィールド上のチューナー１体を選択して発動できる。\n選択したモンスターのカード名はエンドフェイズ時まで「ジェネクス・コントローラー」として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・パワーコール",h:"あーりー・じぇねくす・ぱわーこーる",e:"Genex Ally Powercell",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、このカードと同じ属性を持つ、このカード以外の自分フィールド上のモンスターの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・チェンジャー",h:"あーりー・じぇねくす・ちぇんじゃー",e:"Genex Ally Changer",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択し、属性を１つ宣言して発動できる。\n選択したモンスターの属性はエンドフェイズ時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・ボルキャノン",h:"あーりー・じぇねくす・ぼるきゃのん",e:"Genex Ally Volcannon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"１ターンに１度、自分フィールド上に表側表示で存在する「ジェネクス」と名のついた炎属性モンスター１体を墓地へ送って発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択して破壊し、破壊したモンスターのレベル×４００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・ソリッド",h:"あーりー・じぇねくす・そりっど",e:"Genex Ally Solid",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"１ターンに１度、自分フィールド上に表側表示で存在する「ジェネクス」と名のついた水属性モンスター１体を墓地へ送って発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣チャワ",h:"まごうしんじゅうちゃわ",e:"The Fabled Chawa",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:100,tx:"自分のメインフェイズ時に発動できる。\n手札から「魔轟神」と名のついたモンスター１体を捨て、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣キャシー",h:"まごうしんじゅうきゃしー",e:"The Fabled Catsith",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"このカードが手札から墓地へ捨てられた時、フィールド上に表側表示で存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣ケルベラル",h:"まごうしんじゅうけるべらる",e:"The Fabled Cerburrel",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:400,tx:"①：このカードが手札から墓地へ捨てられた場合に発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣ガナシア",h:"まごうしんじゅうがなしあ",e:"The Fabled Ganashia",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが手札から墓地へ捨てられた時、このカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は２００ポイントアップし、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣ノズチ",h:"まごうしんじゅうのずち",e:"The Fabled Nozoochee",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"自分のメインフェイズ時に発動できる。\n手札から「魔轟神」と名のついたモンスター１体を捨て、このカードを手札から特殊召喚する。\nこの効果で特殊召喚に成功した時、手札からレベル２以下の「魔轟神」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ミリトゥム",h:"どらぐにてぃ－みりとぅむ",e:"Dragunity Militum",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"１ターンに１度、自分の魔法＆罠カードゾーンの「ドラグニティ」と名のついたカード１枚を選択して発動できる。\n選択したカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－プリムス・ピルス",h:"どらぐにてぃ－ぷりむす・ぴるす",e:"Dragunity Primus Pilus",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した時、自分フィールドの鳥獣族の「ドラグニティ」モンスター１体を対象として発動できる。\nデッキからドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ブランディストック",h:"どらぐにてぃ－ぶらんでぃすとっく",e:"Dragunity Brandistock",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:400,tx:"①：このカードを装備カード扱いとして装備しているモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ジャベリン",h:"どらぐにてぃ－じゃべりん",e:"Dragunity Javelin",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに装備カード扱いとして自分フィールド上の「ドラグニティ」と名のついた鳥獣族モンスター１体に装備できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・デイノ",h:"じゅらっく・でいの",e:"Jurrac Dino",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードが戦闘によって相手モンスターを破壊したターンのエンドフェイズ時に１度だけ、自分フィールド上の「ジュラック」と名のついたモンスター１体をリリースして発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・ガリム",h:"じゅらっく・がりむ",e:"Jurrac Gallim",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:0,tx:"このカードが戦闘によって破壊され墓地へ送られた時に発動する。\n相手は手札を１枚捨ててこのカードの効果を無効にできる。\n捨てなかった場合、このカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・アウロ",h:"じゅらっく・あうろ",e:"Jurrac Aeolo",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"①：このカードをリリースし、「ジュラック・アウロ」以外の自分の墓地のレベル４以下の「ジュラック」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・ヘレラ",h:"じゅらっく・へれら",e:"Jurrac Herra",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"「ジュラック・ヘレラ」以外の自分フィールド上に守備表示で存在する「ジュラック」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時、このカードを手札または墓地から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・バタフライ",h:"なちゅる・ばたふらい",e:"Naturia Butterfly",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1200,tx:"１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\n自分のデッキの一番上のカード１枚を墓地へ送り、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・レディバグ",h:"なちゅる・れでぃばぐ",e:"Naturia Ladybug",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分が「ナチュル」と名のついたモンスターのシンクロ召喚に成功した時、このカードを墓地から特殊召喚できる。\nまた、自分のメインフェイズ時にこのカードをリリースし、自分フィールド上の「ナチュル」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ストロベリー",h:"なちゅる・すとろべりー",e:"Naturia Strawberry",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"相手がモンスターの召喚・特殊召喚に成功した時、そのモンスター１体を選択して発動する。\nこのカードの攻撃力はエンドフェイズ時まで、選択したモンスター１体のレベル×１００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の守護陣",h:"ひょうけっかいのしゅごじん",e:"Defender of the Ice Barrier",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:1600,tx:"①：自分フィールドに他の「氷結界」モンスターが存在する限り、このカードの守備力以上の攻撃力を持つ相手モンスターは攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の破術師",h:"ひょうけっかいのはじゅつし",e:"Warlock of the Ice Barrier",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:1000,tx:"①：自分フィールドに他の「氷結界」モンスターが存在する限り、お互いに魔法カードはセットしなければ発動できず、セットしたプレイヤーから見て次の自分ターンが来るまで発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の神精霊",h:"ひょうけっかいのしんしょうれい",e:"Sacred Spirit of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"アンデット族",mo:["スピリット","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\n自分フィールド上にこのカード以外の「氷結界」と名のついたモンスターが存在する場合、エンドフェイズ時に発動するこのカードの効果は「相手フィールド上のモンスター１体を選択して持ち主の手札に戻す」となる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の虎将 ライホウ",h:"ひょうけっかいのこしょう　らいほう",e:"General Raiho of the Ice Barrier",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:2300,tx:"①：このカードがモンスターゾーンに存在する限り、フィールドで発動した相手モンスターの効果処理時に、相手は手札を１枚捨てる事ができる。\n捨てなかった場合、その効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・トライアーム",h:"あーりー・じぇねくす・とらいあーむ",e:"Genex Ally Triarm",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"「ジェネクス・コントローラー」＋チューナー以外のモンスター１体以上\nこのカードのシンクロ素材としたチューナー以外のモンスターの属性によって、以下の効果を１ターンに１度、手札を１枚捨てて発動できる。\n●風属性：相手の手札をランダムに１枚墓地へ送る。\n●水属性：フィールド上の魔法・罠カード１枚を選択して破壊する。\n●闇属性：フィールド上の光属性モンスター１体を選択して破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣ユニコール",h:"まごうしんじゅうゆにこーる",e:"The Fabled Unicore",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在し、お互いの手札が同じ枚数である限り、相手が発動した魔法・罠・効果モンスターの効果は無効化され破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－トライデント",h:"どらぐにてぃないと－とらいでんと",e:"Dragunity Knight - Trident",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1700,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上\n①：１ターンに１度、自分フィールドのカードを３枚まで墓地へ送って発動できる。\n相手のEXデッキを確認し、この効果を発動するために墓地へ送った数だけカードを選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・メテオ",h:"じゅらっく・めてお",e:"Jurrac Meteor",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"恐竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「ジュラック」と名のついたチューナー＋チューナー以外の恐竜族モンスター２体以上\nこのカードがシンクロ召喚に成功した時、フィールド上のカードを全て破壊する。\nその後、自分の墓地からチューナー１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ランドオルス",h:"なちゅる・らんどおるす",e:"Naturia Landoise",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["シンクロ","効果"],ma:"",tp:"",at:2350,de:1600,tx:"地属性チューナー＋チューナー以外の地属性モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、手札の魔法カード１枚を墓地へ送る事で、効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガントレット・ウォリアー",h:"がんとれっと・うぉりあー",e:"Gauntlet Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:1600,tx:"このカードをリリースして発動する。\n自分フィールド上に表側表示で存在する全ての戦士族モンスターの攻撃力・守備力は５００ポイントアップし、そのモンスターが戦闘を行ったダメージステップ終了時まで適用される。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エキセントリック・ボーイ",h:"えきせんとりっく・ぼーい",e:"Eccentric Boy",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"このカードをシンクロ素材とする場合、他のシンクロ素材モンスターは手札のモンスター１体でなければならない。\nこのカードをシンクロ素材としたシンクロモンスターは効果を発動する事ができず無効化され、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・アーチャー",h:"じゃんく・あーちゃー",e:"Junk Archer",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2000,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n１ターンに１度、相手フィールド上に存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外する。\nこの効果で除外したモンスターは、このターンのエンドフェイズ時に同じ表示形式で相手フィールド上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"強制終了",h:"きょうせいしゅうりょう",e:"Scrubbed Raid",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するこのカード以外のカード１枚を墓地へ送る事で、このターンのバトルフェイズを終了する。\nこの効果はバトルフェイズ時にのみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"チューナーズ・バリア",h:"ちゅーなーず・ばりあ",e:"Tuner's Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するチューナー１体を選択して発動する。\n次のターンのエンドフェイズ時まで、選択したチューナー１体は戦闘またはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイターズ・エイプ",h:"ふぁいたーず・えいぷ",e:"Ape Fighter",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードが戦闘によって相手モンスターを破壊した場合、このカードの攻撃力は３００ポイントアップする。\nこのカードが自分のターンで攻撃を行っていない場合、この効果でアップした数値はそのターンのエンドフェイズ時に０に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"クローザー・フォレスト",h:"くろーざー・ふぉれすと",e:"Closed Forest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するモンスター１体につき、自分フィールド上に表側表示で存在する獣族モンスターの攻撃力は１００ポイントアップする。\nこのカードがフィールド上に存在する限り、フィールド魔法カードを発動する事はできない。\nまた、このカードが破壊されたターン、フィールド魔法カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"吠え猛る大地",h:"ほえたけるだいち",e:"Roaring Earth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在する獣族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこの効果が適用された事によって相手ライフに戦闘ダメージを与えた時、相手フィールド上に表側表示で存在するモンスター１体の攻撃力・守備力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・エイプ",h:"まじしゃんず・えいぷ",e:"Ape Magician",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカードは特殊召喚できない。\nこのカードがフィールド上に表側攻撃表示で存在する場合、１ターンに１度、手札のモンスター１体を墓地へ送り、相手フィールド上に表側守備表示で存在するモンスター１体を選択して発動する事ができる。\nこのターンのエンドフェイズ時まで、選択したモンスターのコントロールを得る。\nこの効果でコントロールを得たモンスターは、このターン表示形式を変更する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO Great TORNADO",h:"えれめんたるひーろー　ぐれいと　とるねーど",e:"Elemental HERO Great Tornado",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2200,tx:"「E・HERO」モンスター＋風属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが融合召喚に成功した場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ビートル",h:"こあきめいる・びーとる",e:"Koa'ki Meiru Beetle",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1900,de:1500,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の昆虫族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n光属性または闇属性モンスターが表側攻撃表示で特殊召喚に成功した時、そのモンスターを守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・マキシマム",h:"こあきめいる・まきしまむ",e:"Koa'ki Meiru Maximus",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の手札から「コアキメイルの鋼核」１枚をゲームから除外した場合に特殊召喚する事ができる。\nこのカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚か「コアキメイル」と名のついたモンスター１体を墓地へ送る。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、自分のメインフェイズ時に相手フィールド上に存在するカード１枚を選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・スピリット シャイア",h:"らいとろーど・すぴりっと　しゃいあ",e:"Shire, Lightsworn Spirit",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:400,de:1400,tx:"このカードの攻撃力は、自分の墓地に存在する「ライトロード」と名のついたモンスターの種類×３００ポイントアップする。\nこのカードが自分フィールド上に表側表示で存在する限り、自分のエンドフェイズ毎に、自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・シーフ ライニャン",h:"らいとろーど・しーふ　らいにゃん",e:"Rinyan, Lightsworn Rogue",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:100,tx:"リバース：自分の墓地に存在する「ライトロード」と名のついたモンスター１体を選択してデッキに戻し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"森の狩人イエロー・バブーン",h:"もりのかりうどいえろー・ばぶーん",e:"Yellow Baboon, Archer of the Forest",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"自分フィールド上に存在する獣族モンスターが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在する獣族モンスター２体をゲームから除外する事で、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアル・スコーピオン",h:"でゅある・すこーぴおん",e:"Gemini Scorpion",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが召喚・特殊召喚に成功した時、手札からレベル４以下のデュアルモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"メタボ・シャーク",h:"めたぼ・しゃーく",e:"Metabo-Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"このカードが召喚に成功した時、自分の墓地に存在する魚族モンスター２体を選択してデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛神の復活",h:"じばくしんのふっかつ",e:"Earthbound Immortal Revival",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚捨てて発動する。\n自分の墓地に存在する「地縛神」と名のついたモンスター１体とフィールド魔法カード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ポイズン",h:"れぷてぃれす・ぽいずん",e:"Reptilianne Poison",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「レプティレス」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\n相手フィールド上に守備表示で存在するモンスター１体を表側攻撃表示に変更し、そのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"六武の門",h:"ろくぶのもん",e:"Gateway of the Six",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「六武衆」モンスターが召喚・特殊召喚される度にこのカードに武士道カウンターを２つ置く。\n②：自分フィールドの武士道カウンターを以下の数だけ取り除き、その効果を発動できる。\n●２つ：フィールドの「六武衆」効果モンスターまたは「紫炎」効果モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。\n●４つ：自分のデッキ・墓地から「六武衆」モンスター１体を選んで手札に加える。\n●６つ：自分の墓地の「紫炎」効果モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"地底のアラクネー",h:"ちていのあらくねー",e:"Underground Arachnid",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"闇属性チューナー＋チューナー以外の昆虫族モンスター１体\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\n１ターンに１度、相手フィールド上に表側表示で存在するモンスターを装備カード扱いとしてこのカードに１体のみ装備する事ができる。\nこのカードが戦闘によって破壊される場合、代わりにこの効果で装備したモンスターを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンハンドレッド・アイ・ドラゴン",h:"わんはんどれっど・あい・どらごん",e:"Hundred Eyes Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"闇属性チューナー＋チューナー以外の悪魔族モンスター１体以上\n①：１ターンに１度、自分の墓地からレベル６以下の闇属性の効果モンスター１体を除外して発動できる。\nエンドフェイズまで、このカードはそのモンスターと同じ、元々のカード名・効果を得る。\n②：このカードが破壊され墓地へ送られた場合に発動する。\nデッキから「地縛神」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"猿魔王ゼーマン",h:"えんまおうぜーまん",e:"Zeman the Ape King",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"闇属性チューナー＋チューナー以外の獣族モンスター１体\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\n相手モンスターの攻撃宣言時、自分の手札またはフィールド上のモンスター１体を墓地へ送る事で、相手モンスター１体の攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・コレクター",h:"じゃんく・これくたー",e:"Junk Collector",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2200,tx:"フィールド上に表側表示で存在するこのカードと自分の墓地に存在する通常罠カード１枚をゲームから除外して発動する。\nこのカードの効果は、この効果を発動するためにゲームから除外した通常罠カードの効果と同じになる。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バックアップ・ウォリアー",h:"ばっくあっぷ・うぉりあー",e:"Backup Warrior",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2100,de:0,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスターが守備表示モンスター２体のみの場合に特殊召喚する事ができる。\nこのカードを特殊召喚するターン、自分はシンクロ召喚をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の残照",h:"きせきのざんしょう",e:"Miracle's Wake",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン戦闘で破壊され自分の墓地へ送られたモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターライト・ロード",h:"すたーらいと・ろーど",e:"Starlight Road",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのカードを２枚以上破壊する魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にし破壊する。\nその後、「スターダスト・ドラゴン」１体をEXデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Aggiba, the Malevolent Sh'nn S'yo",h:"Aggiba, the Malevolent Sh'nn S'yo",e:"Aggiba, the Malevolent Sh'nn S'yo",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"This card cannot be Special Summoned. To Tribute Summon this card, you must Tribute 3 Fiend-Type monsters. If this card attacks your opponent directly and reduces their Life Points to 0, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"Skuna, the Leonine Rakan",h:"Skuna, the Leonine Rakan",e:"Skuna, the Leonine Rakan",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"This card cannot be Special Summoned. To Tribute Summon this card, you must Tribute 3 Beast-Warrior-Type monsters. If this card attacks your opponent directly and reduces their Life Points to 0, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"アンサイクラー",h:"あんさいくらー",e:"Unicycular",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:100,de:100,tx:"トライクラー、ヴィークラーを兄に持つ三男坊のアンサイクラー。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴィークラー",h:"う゛ぃーくらー",e:"Bicular",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の手札またはデッキから「アンサイクラー」１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"トライクラー",h:"とらいくらー",e:"Tricular",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の手札またはデッキから「ヴィークラー」１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドリル・シンクロン",h:"どりる・しんくろん",e:"Drill Synchron",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:300,tx:"①：このカードがモンスターゾーンに存在する限り、自分の戦士族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：１ターンに１度、このカードの①の効果を適用して相手に戦闘ダメージを与えた時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"血涙のオーガ",h:"けつるいのおーが",e:"Ogre of the Scarlet Sorrow",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"相手ターンの１度のバトルフェイズ中に２回目の直接攻撃が宣言された時、このカードを手札から特殊召喚する事ができる。\nこの効果で特殊召喚に成功した時、このカードの攻撃力・守備力はこのターンに１回目の直接攻撃を行った、フィールド上に表側表示で存在するモンスターと同じ数値になる。\nこのターン、この効果で特殊召喚したこのカードがフィールド上に表側表示で存在する限り、相手はこのカード以外のモンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"バトルフェーダー",h:"ばとるふぇーだー",e:"Battle Fader",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚し、その後バトルフェイズを終了する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・サプライヤー",h:"ぱわー・さぷらいやー",e:"Power Supplier",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"１ターンに１度、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、選択したモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ホール・ゴーレム",h:"まじっく・ほーる・ごーれむ",e:"Magic Hole Golem",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"１ターンに１度、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターはエンドフェイズ時まで攻撃力が半分になり、このターン相手プレイヤーに直接攻撃する事ができる。\nこの効果を発動するターン、選択したモンスター以外のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・インベーダー",h:"ぱわー・いんべーだー",e:"Power Invader",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"相手フィールド上のモンスターが２体以上の場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・バグ",h:"だーく・ばぐ",e:"Dark Bug",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが召喚に成功した時、自分の墓地のレベル３のチューナー１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ソード・マスター",h:"そーど・ますたー",e:"Sword Master",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:0,tx:"自分フィールド上に存在する戦士族モンスターの攻撃によって相手モンスターが破壊されなかったダメージステップ終了時、このカードを手札から特殊召喚する事ができる。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒薔薇の魔女",h:"ぶらっくろーずうぃっち",e:"Witch of the Black Rose",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードは特殊召喚できない。\n①：自分フィールドに他のカードが存在せず、このカードが召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれがモンスター以外だった場合、そのカードは墓地へ送られ、このカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"薔薇の妖精",h:"ばらのようせい",e:"Rose Fairy",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"このカードが魔法・罠・効果モンスターの効果によって自分のデッキから手札に加わった場合、このカードを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バッド・エンド・クイーン・ドラゴン",h:"ばっど・えんど・くいーん・どらごん",e:"Dragon Queen of Tragic Endings",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:2600,tx:"このカードは通常召喚できない。\n自分フィールド上の永続魔法カードが３枚以上の場合に特殊召喚できる。\nこのカードの攻撃によって相手ライフに戦闘ダメージを与えた時、相手は手札を１枚選んで墓地へ送り、自分はデッキからカードを１枚ドローする。\nまた、このカードがフィールド上から墓地へ送られていた場合、自分のスタンバイフェイズ時に、自分フィールド上に表側表示で存在する永続魔法カード１枚を墓地へ送る事で、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・サーヴァント",h:"れぷてぃれす・さーう゛ぁんと",e:"Reptilianne Servant",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"フィールド上にこのカード以外のモンスターが表側表示で存在する場合、このカードを破壊する。\nこのカードが魔法・罠カードの効果の対象になった時、このカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、お互いにモンスターを召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ガードナー",h:"れぷてぃれす・がーどなー",e:"Reptilianne Gardna",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分フィールド上のこのカードが破壊され墓地へ送られた時、デッキから「レプティレス」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ナージャ",h:"れぷてぃれす・なーじゃ",e:"Reptilianne Naga",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは戦闘では破壊されない。\n②：このカードがモンスターと戦闘を行ったバトルフェイズ終了時に発動する。\nそのモンスターの攻撃力を０にする。\n③：このカードが守備表示で存在する場合、自分エンドフェイズに発動する。\n表側守備表示のこのカードを表側攻撃表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ヴァースキ",h:"れぷてぃれす・う゛ぁーすき",e:"Reptilianne Vaskii",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:0,tx:"このカードは通常召喚できない。\n自分・相手フィールドの攻撃力０のモンスター２体をリリースした場合のみ特殊召喚できる。\n①：「レプティレス・ヴァースキ」はフィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽の神官",h:"たいようのしんかん",e:"Oracle of the Sun",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードが破壊され墓地へ送られた時に発動できる。\nデッキから「赤蟻アスカトル」または「スーパイ」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"赤蟻アスカトル",h:"あかありあすかとる",e:"Fire Ant Ascator",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:1300,tx:"①：このカードが戦闘で破壊され墓地へ送られた時、自分の墓地のレベル５モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターンのエンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"泣き神の石像",h:"なきがみのせきぞう",e:"Weeping Idol",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"自分の墓地に存在するチューナー１体をゲームから除外して発動する。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アポカテクイル",h:"あぽかてくいる",e:"Apocatequil",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上にチューナーが存在する場合、フィールド上のこのカードのレベルは５として扱う。\nフィールド上のこのカードが破壊され墓地へ送られた時、自分の墓地の「太陽の神官」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパイ",h:"すーぱい",e:"Supay",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:100,tx:"①：このカードが効果でフィールドから墓地へ送られた時に発動できる。\nデッキから「太陽の神官」１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃力が倍になり、このターンのエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"インフォーマー・スパイダー",h:"いんふぉーまー・すぱいだー",e:"Informer Spider",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"フィールド上に存在するこのカードがカードの効果によって墓地へ送られた時、相手フィールド上に守備表示で存在するモンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ウルナイト",h:"こあきめいる・うるないと",e:"Koa'ki Meiru Urnight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の獣戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、手札の「コアキメイルの鋼核」１枚を相手に見せる事で、デッキから「コアキメイル・ウルナイト」以外のレベル４以下の「コアキメイル」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー ガルセム",h:"だぶるえっくす－せいばー　がるせむ",e:"XX-Saber Garsem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"フィールド上に存在するこのカードがカードの効果によって破壊され墓地へ送られた時、自分のデッキから「X－セイバー」と名のついたモンスター１体を手札に加える。\nこのカードの攻撃力は、自分フィールド上に表側表示で存在する「X－セイバー」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の大神官",h:"はかもりのだいしんかん",e:"Gravekeeper's Visionary",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"このカードは「墓守の」と名のついたモンスター１体をリリースして召喚できる。\nこのカードの攻撃力は、自分の墓地の「墓守の」と名のついたモンスターの数×２００ポイントアップする。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに手札から「墓守の」と名のついたモンスター１体を捨てる事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の末裔",h:"はかもりのまつえい",e:"Gravekeeper's Descendant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカード以外の自分フィールド上に表側表示で存在する「墓守の」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラックポータン",h:"ぶらっくぽーたん",e:"Black Potan",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"自分フィールド上にチューナーが表側表示で存在する場合、このカードは戦闘では破壊されない。\n自分フィールド上に表側表示で存在するチューナーがフィールド上から離れた時、自分は８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"シュレツダー",h:"しゅれつだー",e:"Shreddder",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"手札から機械族モンスター１体を墓地へ送って発動する。\n墓地へ送ったモンスターのレベル以下の、相手フィールド上に表側表示で存在するモンスター１体を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"パンダボーグ",h:"ぱんだぼーぐ",e:"Pandaborg",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、８００ライフポイントを払って発動できる。\nデッキからレベル４のサイキック族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コダロス",h:"こだろす",e:"Codarus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"自分フィールド上に表側表示で存在する「海」を墓地へ送って発動できる。\n相手フィールド上のカードを２枚まで選択して墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なるあかり",h:"せいなるあかり",e:"Consecrated Light",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは闇属性モンスターとの戦闘では破壊されず、その戦闘によって発生する自分への戦闘ダメージは０になる。\nまた、このカードがフィールド上に表側表示で存在する限り、闇属性モンスターは攻撃宣言できず、お互いに闇属性モンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"軍荼利",h:"ぐんだり",e:"Gundari",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["スピリット","効果"],ma:"",tp:"",at:1000,de:200,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードがシンクロモンスターと戦闘を行う場合、ダメージ計算を行わず、そのモンスターとこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・ツヴァイ",h:"さいばー・どらごん・つう゛ぁい",e:"Cyber Dragon Zwei",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：このカードのカード名は、墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：１ターンに１度、手札の魔法カード１枚を相手に見せて発動できる。\nこのカードのカード名はエンドフェイズまで「サイバー・ドラゴン」として扱う。\n③：このカードが相手モンスターに攻撃するダメージステップの間、このカードの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"オイルメン",h:"おいるめん",e:"Oilman",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:400,de:400,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の機械族モンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、デッキからカードを１枚ドローする。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"儀式魔人カースエンチャンター",h:"ぎしきまじんかーすえんちゃんたー",e:"Djinn Cursenchanter of Rituals",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、自分の墓地のこのカードをゲームから除外できる。\nまた、このカードを儀式召喚に使用した儀式モンスターがフィールド上に表側表示で存在する限り、シンクロモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式魔人プレコグスター",h:"ぎしきまじんぷれこぐすたー",e:"Djinn Prognosticator of Rituals",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、自分の墓地のこのカードをゲームから除外できる。\nまた、このカードを儀式召喚に使用した儀式モンスターが相手ライフに戦闘ダメージを与えた時、相手は手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅の魔王ガーランドルフ",h:"はめつのまおうがーらんどるふ",e:"Garlandolf, King of Destruction",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1400,tx:"「破滅の儀式」により降臨。\nこのカードが儀式召喚に成功した時、このカードの攻撃力以下の守備力を持つ、このカード以外のフィールド上のモンスターを全て破壊し、破壊したモンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"セイヴァー・デモン・ドラゴン",h:"せいう゛ぁー・でもん・どらごん",e:"Majestic Red Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:3000,tx:"「救世竜 セイヴァー・ドラゴン」＋「レッド・デーモンズ・ドラゴン」＋チューナー以外のモンスター１体\nこのカードはカードの効果では破壊されない。\nこのカードが攻撃した場合、ダメージ計算後にフィールド上に守備表示で存在するモンスターを全て破壊する。\n１ターンに１度、エンドフェイズ時まで、相手フィールド上に表側表示で存在するモンスター１体を選択してその効果を無効にし、そのモンスターの攻撃力分このカードの攻撃力をアップできる。\nエンドフェイズ時、このカードをエクストラデッキに戻し、自分の墓地の「レッド・デーモンズ・ドラゴン」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドリル・ウォリアー",h:"どりる・うぉりあー",e:"Drill Warrior",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「ドリル・シンクロン」＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードの攻撃力を半分にし、このターンこのカードは相手プレイヤーに直接攻撃できる。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\n手札を１枚捨ててこのカードをゲームから除外する。\n次の自分のスタンバイフェイズ時、この効果で除外したこのカードを自分フィールド上に特殊召喚する。\nその後、自分の墓地のモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽龍インティ",h:"たいようりゅういんてぃ",e:"Sun Dragon Inti",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2800,tx:"「赤蟻アスカトル」＋チューナー以外のモンスター１体以上\n①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nこのカードを破壊したモンスターを破壊し、その攻撃力の半分のダメージを相手に与える。\n②：フィールドのこのカードが破壊された次のターンのスタンバイフェイズに、自分の墓地の「月影龍クイラ」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"月影龍クイラ",h:"つきかげりゅうくいら",e:"Moon Dragon Quilla",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「スーパイ」＋チューナー以外のモンスター１体以上\n①：このカードが攻撃対象に選択された場合に発動する。\n攻撃モンスターの攻撃力の半分だけ自分のLPを回復する。\n②：フィールドのこのカードが破壊された場合、自分の墓地の「太陽龍インティ」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー ヒュンレイ",h:"だぶるえっくす－せいばー　ひゅんれい",e:"XX-Saber Hyunlei",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1300,tx:"チューナー＋チューナー以外の「X－セイバー」と名のついたモンスター１体以上\nこのカードがシンクロ召喚に成功した時、フィールド上の魔法・罠カードを３枚まで選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"調和の宝札",h:"ちょうわのほうさつ",e:"Cards of Consonance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札から攻撃力１０００以下のドラゴン族チューナー１体を捨てて発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"バラエティ・アウト",h:"ばらえてぃ・あうと",e:"Variety Comes Out",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体をエクストラデッキに戻して発動する。\nレベルの合計がそのシンクロモンスターのレベルと同じになるように、自分の墓地に存在するチューナーを選択して自分フィールド上に特殊召喚する。\nこのカードを発動するターン、自分はシンクロ召喚をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・アンガー",h:"れぷてぃれす・あんがー",e:"Reptilianne Rage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは爬虫類族になり、攻撃力は８００ポイントアップする。\nまた、このカードが破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択する。\nそのモンスターの攻撃力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"アドバンス・フォース",h:"あどばんす・ふぉーす",e:"Advance Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、レベル７以上のモンスターはレベル５以上のモンスター１体をリリースしてアドバンス召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイパー・リボーン",h:"う゛ぁいぱー・りぼーん",e:"Viper's Rebirth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のモンスターが爬虫類族モンスターのみの場合、チューナー以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽の祭壇",h:"たいようのさいだん",e:"Temple of the Sun",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する墓地から特殊召喚されたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ロケット・パイルダー",h:"ろけっと・ぱいるだー",e:"Rocket Pilder",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが攻撃する場合、装備モンスターは戦闘では破壊されない。\n装備モンスターが攻撃を行ったダメージステップ終了時、装備モンスターの攻撃を受けたモンスターの攻撃力は、エンドフェイズ時まで装備モンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイク・ドロー",h:"ぶれいく・どろー",e:"Break! Draw!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを１枚ドローする。\nこのカードは発動後３回目の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・ピカクス",h:"ぱわー・ぴかくす",e:"Power Pickaxe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"１ターンに１度、装備モンスターのレベル以下のレベルを持つ、相手の墓地に存在するモンスター１体を選択してゲームから除外し、エンドフェイズ時まで装備モンスターの攻撃力を５００ポイントアップする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"蜘蛛の領域",h:"くものりょういき",e:"Spiders' Lair",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分フィールド上に表側表示で存在する昆虫族モンスター１体を選択して発動する。\n選択したモンスターと戦闘を行った相手モンスターはバトルフェイズ終了時に守備表示になり、このカードがフィールド上に存在する限り表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼核合成獣研究所",h:"こうかくごうせいじゅうけんきゅうじょ",e:"Iron Core Specimen Lab",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を相手に見せる。\nまたは、見せずにこのカードを破壊する。\nこのカードがフィールド上に存在する限り、フィールド上の「コアキメイル」と名のついたモンスターがエンドフェイズ時に破壊される度に、そのモンスターの元々の持ち主はデッキから「コアキメイル」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の石版",h:"はかもりのせきばん",e:"Gravekeeper's Stele",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「墓守の」と名のついたモンスター２体を選択して手札に加える。\nこの効果は「王家の眠る谷－ネクロバレー」の効果では無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マシン・デベロッパー",h:"ましん・でべろっぱー",e:"Machine Assembly Line",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上の機械族モンスターの攻撃力は２００ポイントアップする。\nフィールド上の機械族モンスターが戦闘またはカードの効果によって破壊される度に、このカードにジャンクカウンターを２つ置く。\nまた、ジャンクカウンターが乗っているこのカードを墓地へ送って発動できる。\nこのカードに乗っていたジャンクカウンターの数以下のレベルを持つ機械族モンスター１体を自分の墓地から選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅の儀式",h:"はめつのぎしき",e:"Ritual of Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「破滅の魔王ガーランドルフ」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が７以上になるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外する事で、このターン自分フィールド上の儀式モンスターが戦闘によって破壊したモンスターは墓地へ送らず持ち主のデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"昇華する魂",h:"しょうかするたましい",e:"Ascending Soul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"儀式モンスターが儀式召喚に成功した時、その儀式召喚でリリースした自分の墓地に存在するモンスター１体を選択して手札に加える事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式の檻",h:"ぎしきのおり",e:"Ritual Cage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分の儀式モンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの儀式モンスターはモンスターの効果の対象にならず、モンスターの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"無欲な壺",h:"むよくなつぼ",e:"Pot of Benevolence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分または相手の墓地に存在するカードを合計２枚選択し、持ち主のデッキに戻す。\nこのカードは発動後、墓地へ送らずにゲームから除外する。\n「無欲な壺」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・コントロール",h:"しんくろ・こんとろーる",e:"Synchro Control",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードは自分フィールド上及び自分の墓地にシンクロモンスターが存在しない場合のみ発動する事ができる。\n１０００ライフポイントを払い、相手フィールド上に表側表示で存在するシンクロモンスター１体を選択して発動する。\n自分のエンドフェイズ時まで、選択したモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"チェンジ・デステニー",h:"ちぇんじ・ですてにー",e:"Changing Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にし、そのモンスターを守備表示にする。\nそのモンスターはフィールド上に表側表示で存在する限り、表示形式を変更する事ができなくなる。\nその後、相手は以下の効果から１つを選択して適用する。\n\n●このカードの効果で攻撃を無効にされたモンスターの攻撃力の半分だけ自分のライフポイントを回復する。\n\n●このカードの効果で攻撃を無効にされたモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デモンズ・チェーン",h:"でもんず・ちぇーん",e:"Fiendish Chain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドの効果モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、その表側表示モンスターは攻撃できず、効果は無効化される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"リフレクト・ネイチャー",h:"りふれくと・ねいちゃー",e:"Nature's Reflection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン、相手が発動したライフポイントにダメージを与える効果は、相手ライフにダメージを与える効果になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スネーク・チョーク",h:"すねーく・ちょーく",e:"Serpent Suppression",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在する攻撃力が０のモンスターは、「レプティレス」と名のついたモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"メテオ・プロミネンス",h:"めてお・ぷろみねんす",e:"Meteor Flare",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ライフが３０００ポイントよりも多い場合、手札を２枚墓地へ送って発動する事ができる。\n相手ライフに２０００ポイントダメージを与える。\nこのカードが墓地に存在する場合、自分のドローフェイズ時に通常のドローを行う代わりに、このカードを手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"栄誉の贄",h:"えいよのにえ",e:"Offering to the Immortals",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが３０００以下の場合、相手が直接攻撃を宣言した時に発動する事ができる。\nそのモンスターの攻撃を無効にし、自分フィールド上に「贄の石碑トークン」（岩石族・地・星１・攻／守０）２体を特殊召喚し、自分のデッキから「地縛神」と名のついたカード１枚を手札に加える。\n「贄の石碑トークン」は、「地縛神」と名のついたモンスターのアドバンス召喚以外のためにはリリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"デストラクト・ポーション",h:"ですとらくと・ぽーしょん",e:"Destruct Potion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択したモンスターを破壊し、破壊したモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"死神の呼び声",h:"しにがみのよびごえ",e:"Call of the Reaper",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地からモンスターが特殊召喚された時に発動できる。\n自分の墓地に存在する「スーパイ」または「赤蟻アスカトル」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライヤー・ワイヤー",h:"らいやー・わいやー",e:"Lair Wire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の昆虫族モンスター１体をゲームから除外し、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"コア・ブラスト",h:"こあ・ぶらすと",e:"Core Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ時に１度だけ、相手フィールド上に存在するモンスターの数が自分より多い場合、自分フィールド上に存在するモンスターの数と同じ数になるように相手フィールド上に存在するカードを破壊する事ができる。\nこの効果は自分フィールド上に「コアキメイル」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイバー・ホール",h:"せいばー・ほーる",e:"Saber Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「X－セイバー」モンスターが存在し、自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機械王－B.C.３０００",h:"きかいおう－Ｂ．Ｃ．３０００",e:"Machine King - 3000 B.C.",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後モンスターカード（機械族・地・星４・攻／守１０００）となり、自分のモンスターカードゾーンに特殊召喚する。\nこの効果で特殊召喚されている場合のみ、１ターンに１度、自分フィールド上に存在する機械族モンスター１体をリリースする事で、このカードの攻撃力はエンドフェイズ時までリリースしたモンスターの攻撃力分アップする。\nこのカードを発動するターン、自分はモンスターを召喚・特殊召喚する事はできない。\n（このカードは罠カードとしても扱う）",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・ブレイン",h:"えーりあん・ぶれいん",e:"Alien Brain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する爬虫類族モンスターが相手モンスターの攻撃によって破壊され墓地に送られた時に発動する事ができる。\nその時に攻撃を行った相手モンスターのコントロールを得て、そのモンスターを爬虫類族として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"忘却の海底神殿",h:"ぼうきゃくのかいていしんでん",e:"Forgotten Temple of the Deep",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、このカードのカード名は「海」として扱う。\n１ターンに１度、自分フィールド上のレベル４以下の魚族・海竜族・水族モンスター１体を選択してゲームから除外できる。\n自分のエンドフェイズ時、この効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"調律師の陰謀",h:"ちょうりつしのいんぼう",e:"Tuner's Scheme",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上にシンクロモンスターが特殊召喚された時に発動する事ができる。\nそのシンクロモンスター１体のコントロールを得る。\nこの効果でコントロールを得たモンスターは破壊された場合ゲームから除外される。\nそのモンスターがフィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カース・サイキック",h:"かーす・さいきっく",e:"Psi-Curse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するサイキック族モンスターが相手モンスターの攻撃によって破壊され墓地へ送られた時に発動する事ができる。\nその時に攻撃を行った相手モンスター１体を破壊し、相手ライフに破壊された自分のサイキック族モンスターのレベル×３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイモヤ不発弾",h:"ぐれいもやふはつだん",e:"Widespread Dud",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側攻撃表示で存在するモンスター２体を選択して発動できる。\n選択したモンスターがフィールド上から離れた時、このカードを破壊する。\nこのカードが破壊された時、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"反転世界",h:"りばーさる・わーるど",e:"Inverse Universe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の全ての効果モンスターの攻撃力・守備力を入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・フォートレス",h:"ましんなーず・ふぉーとれす",e:"Machina Fortress",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:1600,tx:"①：このカードはレベルの合計が８以上になるように手札の機械族モンスターを捨てて、手札・墓地から特殊召喚できる（自身を捨てた場合、墓地から特殊召喚する）。\n②：このカードがモンスターゾーンに存在する限り、このカードを対象として発動した相手モンスターの効果が適用される際に、相手の手札を確認し、その中からカード１枚を選んで捨てる。\n③：このカードが戦闘で破壊され墓地へ送られた場合、相手フィールドのカード１枚を対象として発動する。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・ギアフレーム",h:"ましんなーず・ぎあふれーむ",e:"Machina Gearframe",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「マシンナーズ・ギアフレーム」以外の「マシンナーズ」モンスター１体を手札に加える。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・ピースキーパー",h:"ましんなーず・ぴーすきーぱー",e:"Machina Peacekeeper",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:400,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：フィールドのこのカードが破壊され墓地へ送られた時に発動できる。\nデッキからユニオンモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・リサイクラー",h:"すくらっぷ・りさいくらー",e:"Scrap Recycler",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:900,de:1200,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから機械族モンスター１体を墓地へ送る。\n②：１ターンに１度、自分の墓地の機械族・地属性・レベル４モンスター２体をデッキに戻して発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲部隊の最前線",h:"ましんなーず・ふろんとらいん",e:"Machina Armored Unit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、機械族モンスターが戦闘で破壊され自分の墓地へ送られた時に発動できる。\n墓地のそのモンスターより攻撃力が低い、同じ属性の機械族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライオ・アリゲーター",h:"らいお・ありげーたー",e:"Lion Alligator",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1900,de:200,tx:"自分フィールド上にこのカード以外の爬虫類族モンスターが存在する場合、自分フィールド上に存在する爬虫類族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"トランスフォーム・スフィア",h:"とらんすふぉーむ・すふぃあ",e:"Transforming Sphere",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度、相手フィールド上に表側守備表示で存在するモンスター１体を選択して発動する事ができる。\n手札を１枚捨て、選択した相手モンスターを装備カード扱いとしてこのカードに１体のみ装備する。\nこのカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\nエンドフェイズ時、このカードの効果で装備したモンスターを相手フィールド上に表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スパウン・アリゲーター",h:"すぱうん・ありげーたー",e:"Spawn Alligator",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"爬虫類族モンスターをリリースしてこのカードのアドバンス召喚に成功した場合、このカードのアドバンス召喚のためにリリースしたモンスター１体を、そのターンのエンドフェイズ時に墓地から自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオフレムベル・オリジン",h:"ねおふれむべる・おりじん",e:"Neo Flamvell Origin",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"自分フィールド上に「ネオフレムベル・オリジン」以外の「フレムベル」と名のついたモンスターが存在し、相手の墓地のカードが３枚以下の場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオフレムベル・ヘッジホッグ",h:"ねおふれむべる・へっじほっぐ",e:"Neo Flamvell Hedgehog",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"このカードが戦闘によって破壊された場合、相手の墓地のカード１枚を選択してゲームから除外する。\nフィールド上のこのカードがカードの効果によって破壊された場合、自分の墓地から「ネオフレムベル・ヘッジホッグ」以外の守備力２００以下の炎属性モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオフレムベル・シャーマン",h:"ねおふれむべる・しゃーまん",e:"Neo Flamvell Shaman",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"自分の墓地に「フレムベル」と名のついたモンスターが３体以上存在し、このカードが戦闘によって相手モンスターを破壊した場合、相手の墓地のカード１枚を選択してゲームから除外する。\nこの効果の発動時に相手の墓地に魔法カードが存在しない場合、さらに相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオフレムベル・ガルーダ",h:"ねおふれむべる・がるーだ",e:"Neo Flamvell Garuda",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"自分のエンドフェイズ時、自分フィールド上にこのカード以外の「フレムベル」と名のついたモンスターが存在する場合、相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオフレムベル・サーベル",h:"ねおふれむべる・さーべる",e:"Neo Flamvell Sabre",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"相手の墓地のカードが４枚以下の場合、このカードの攻撃力は６００ポイントアップする。\n相手の墓地のカードが８枚以上の場合、このカードの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・ケミストリ",h:"あーりー・じぇねくす・けみすとり",e:"Genex Ally Chemistrer",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:500,tx:"属性を１つ宣言し、このカードを手札から捨てて発動できる。\n自分フィールド上の「ジェネクス」と名のついたモンスター１体を選択し、その属性を宣言した属性にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・バードマン",h:"あーりー・じぇねくす・ばーどまん",e:"Genex Ally Birdman",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:400,tx:"①：自分フィールドの表側表示モンスター１体を持ち主の手札に戻して発動できる。\nこのカードを手札から特殊召喚する。\nこの効果を発動するために風属性モンスターを手札に戻した場合、このカードの攻撃力は５００アップする。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:1,lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・ベルフレイム",h:"あーりー・じぇねくす・べるふれいむ",e:"Genex Ally Bellflame",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分フィールド上のモンスターが墓地へ送られる度に、このカードにジェネクスカウンターを１つ置く。\nまた、相手の墓地のカードがゲームから除外される度に、このカードにジェネクスカウンターを２つ置く。\nこのカードの攻撃力はフィールド上のジェネクスカウンターの数×１００ポイントアップする。\nこのカードが戦闘によって破壊され墓地へ送られた時、このカードに乗っていたジェネクスカウンターの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・クラッシャー",h:"あーりー・じぇねくす・くらっしゃー",e:"Genex Ally Crusher",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分フィールド上のこのカードと同じ属性のモンスターが自分フィールド上に召喚された時、相手フィールド上のカード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・リバイバー",h:"あーりー・じぇねくす・りばいばー",e:"Genex Ally Reliever",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"相手がコントロールするカードの発動を無効にした場合、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣ペガラサス",h:"まごうしんじゅうぺがらさす",e:"The Fabled Peggulsus",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1600,tx:"このカードが手札から墓地へ捨てられた時、このカードを墓地から裏側守備表示で特殊召喚できる。\nまた、このカードがリバースした時、手札の「魔轟神」と名のついたモンスター１体を見せて発動できる。\nデッキから「魔轟神」と名のついたモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣コカトル",h:"まごうしんじゅうこかとる",e:"The Fabled Kokkator",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、手札から「魔轟神」と名のついたモンスター１体を捨てて発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神ディアネイラ",h:"まごうしんでぃあねいら",e:"Fabled Dianaira",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:100,tx:"このカードは「魔轟神」と名のついたモンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\nこのカードが自分フィールド上に表側表示で存在する限り、相手が発動した通常魔法カードの効果は「相手は手札を１枚選んで捨てる」となる。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－コルセスカ",h:"どらぐにてぃ－こるせすか",e:"Dragunity Corsesca",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:700,tx:"①：このカードを装備カード扱いとして装備しているモンスターが戦闘で相手モンスターを破壊した時に発動できる。\n種族・属性が装備モンスターと同じとなるレベル４以下のモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－パルチザン",h:"どらぐにてぃ－ぱるちざん",e:"Dragunity Partisan",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが召喚に成功した時に発動できる。\n手札から「ドラグニティ」と名のついた鳥獣族モンスター１体を特殊召喚し、さらにこのカードを装備カード扱いとして装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターをチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ピルム",h:"どらぐにてぃ－ぴるむ",e:"Dragunity Pilum",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが召喚に成功した時に発動できる。\n手札から「ドラグニティ」と名のついた鳥獣族モンスター１体を特殊召喚し、さらにこのカードを装備カード扱いとして装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターは相手プレイヤーに直接攻撃できる。\nこの時、装備モンスターが相手ライフに与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－アングス",h:"どらぐにてぃ－あんぐす",e:"Dragunity Angusticlavii",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカードが「ドラグニティ」と名のついたドラゴン族モンスターを装備している場合、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・スティンクバグ",h:"なちゅる・すてぃんくばぐ",e:"Naturia Stinkbug",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:500,tx:"自分フィールド上の「ナチュル」と名のついたモンスターが攻撃対象になった時、フィールド上のこのカードを墓地へ送って発動できる。\nその攻撃を無効にし、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・マンティス",h:"なちゅる・まんてぃす",e:"Naturia Mantis",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"相手がモンスターの召喚に成功した時、手札から「ナチュル」と名のついたモンスター１体を墓地へ送って発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ラグウィード",h:"なちゅる・らぐうぃーど",e:"Naturia Ragweed",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"相手がドローフェイズ以外でカードをドローした時、フィールド上のこのカードを墓地へ送って発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ホワイトオーク",h:"なちゅる・ほわいとおーく",e:"Naturia White Oak",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカードが相手のカードの効果の対象になった時、フィールド上のこのカードを墓地へ送って発動できる。\nデッキからレベル４以下の「ナチュル」と名のついたモンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃宣言できず、自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の軍師",h:"ひょうけっかいのぐんし",e:"Strategist of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：１ターンに１度、手札から「氷結界」モンスター１体を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の御庭番",h:"ひょうけっかいのおにわばん",e:"Secret Guards of the Ice Barrier",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「氷結界」と名のついたモンスターは相手の効果モンスターの効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の虎将 ガンターラ",h:"ひょうけっかいのこしょう　がんたーら",e:"General Gantala of the Ice Barrier",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:2000,tx:"①：自分エンドフェイズに、「氷結界の虎将 ガンターラ」以外の自分の墓地の「氷結界」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・エクストリオ",h:"なちゅる・えくすとりお",e:"Naturia Exterio",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2400,tx:"「ナチュル・ビースト」＋「ナチュル・パルキオン」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：魔法・罠カードが発動した時、自分の墓地のカード１枚を除外し、デッキの一番上のカードを墓地へ送って発動できる。\nこのカードがフィールドに表側表示で存在する場合、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・ゴッド・フレムベル",h:"えんしぇんと・ごっど・ふれむべる",e:"Ancient Flamvell Deity",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:200,tx:"炎属性チューナー＋チューナー以外の炎族モンスター１体以上\nこのカードがシンクロ召喚に成功した時、相手の手札の枚数分まで相手の墓地のカードを選択してゲームから除外する。\nこのカードの攻撃力は、この効果で除外したカードの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・トライフォース",h:"あーりー・じぇねくす・とらいふぉーす",e:"Genex Ally Triforce",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「ジェネクス」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードのシンクロ素材としたチューナー以外のモンスターの属性によって、このカードは以下の効果を得る。\n●地属性：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n●炎属性：このカードが戦闘によってモンスターを破壊した場合、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。\n●光属性：１ターンに１度、自分の墓地の光属性モンスター１体を選択して、裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣クダベ",h:"まごうしんじゅうくだべ",e:"The Fabled Kudabbi",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1100,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードは自分の手札の枚数によって以下の効果を得る。\n\n●０枚：このカードは戦闘及びカードの効果では破壊されない。\n\n●３枚以上：エンドフェイズ時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－バルーチャ",h:"どらぐにてぃないと－ばるーちゃ",e:"Dragunity Knight - Barcha",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1200,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上\n①：このカードがS召喚に成功した時、自分の墓地のドラゴン族の「ドラグニティ」モンスターを任意の数だけ対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードに装備された「ドラグニティ」カードの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の龍 トリシューラ",h:"ひょうけっかいのりゅう　とりしゅーら",e:"Trishula, Dragon of the Ice Barrier",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"チューナー＋チューナー以外のモンスター２体以上\n①：このカードがS召喚に成功した時に発動できる。\n相手の手札・フィールド・墓地のカードをそれぞれ１枚まで選んで除外できる（手札からはランダムに選ぶ）。",li:1,lk:[],ta:[],al:[]},
  {n:"The tyrant NEPTUNE",h:"ざ・たいらんと・ねぷちゅーん",e:"The Tyrant Neptune",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは特殊召喚できない。\nこのカードはモンスター１体をリリースしてアドバンス召喚する事ができる。\nこのカードの攻撃力・守備力は、アドバンス召喚時にリリースしたモンスターの元々の攻撃力・守備力をそれぞれ合計した数値分アップする。\nこのカードがアドバンス召喚に成功した時、墓地に存在するリリースした効果モンスター１体を選択し、そのモンスターと同名カードとして扱い、同じ効果を得る。",li:0,lk:[],ta:[],al:[]},
  {n:"The アトモスフィア",h:"じ　あともすふぃあ",e:"The Atmosphere",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスター２体と自分の墓地に存在するモンスター１体をゲームから除外した場合に特殊召喚する事ができる。\n１ターンに１度、相手フィールド上に表側表示で存在するモンスターを装備カード扱いとしてこのカードに１体のみ装備する事ができる。\nこのカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・ブレーダー",h:"じゃんく・ぶれーだー",e:"Junk Blader",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"自分の墓地に存在する「ジャンク」と名のついたモンスター１体をゲームから除外する事で、このカードの攻撃力はエンドフェイズ時まで４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトニング・ウォリアー",h:"らいとにんぐ・うぉりあー",e:"Lightning Warrior",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手の手札の枚数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"サムライソード・バロン",h:"さむらいそーど・ばろん",e:"Samurai Sword Baron",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度、相手フィールド上に守備表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを表側攻撃表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・セキュリティ",h:"へる・せきゅりてぃ",e:"Stygian Security",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:600,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nデッキから悪魔族・レベル１モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・ツイン・コップ",h:"へる・ついん・こっぷ",e:"Stygian Sergeants",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"悪魔族チューナー＋チューナー以外のモンスター１体以上\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードは、バトルフェイズ終了時まで攻撃力が８００アップし、もう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルウェイ・パトロール",h:"へるうぇい・ぱとろーる",e:"Stygian Street Patrol",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々のレベル×１００ダメージを相手に与える。\n②：墓地のこのカードを除外して発動できる。\n手札から攻撃力２０００以下の悪魔族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマード・ビー",h:"あーまーど・びー",e:"Armored Bee",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力をエンドフェイズ時まで半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ポセイドン・オオカブト",h:"ぽせいどん・おおかぶと",e:"Great Poseidon Beetle",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2500,de:2300,tx:"このカードが相手フィールド上に表側攻撃表示で存在するモンスターを攻撃し、そのモンスターが戦闘によって破壊されなかった場合、同じモンスターに続けて攻撃する事ができる。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"クイック・スパナイト",h:"くいっく・すぱないと",e:"Quick-Span Knight",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、相手フィールド上に表側表示で存在するモンスター１体の攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー エマーズブレイド",h:"だぶるえっくす－せいばー　えまーずぶれいど",e:"XX-Saber Emmersblade",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキからレベル４以下の「X－セイバー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔力の精製者",h:"くろまりょくのせいせいしゃ",e:"Alchemist of Black Spells",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、自分フィールド上に表側攻撃表示で存在するこのカードを表側守備表示に変更し、自分フィールド上に表側表示で存在する魔力カウンターを置く事ができるカード１枚に魔力カウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"素早いビッグハムスター",h:"すばやいびっぐはむすたー",e:"Super-Nimble Mega Hamster",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:1800,tx:"リバース：自分のデッキからレベル３以下の獣族モンスター１体を裏側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サボウ・クローザー",h:"さぼう・くろーざー",e:"Cactus Bouncer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"このカードは特殊召喚できない。\nこのカード以外の植物族モンスターがフィールド上に表側表示で存在する限り、お互いにモンスターを特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴニック・ガード",h:"どらごにっく・がーど",e:"Dragonic Guard",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"モンスターが通常召喚される度に、このカードにドラゴニックカウンターを１つ置く。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、このカードに乗っているドラゴニックカウンターの数以下のレベルを持つドラゴン族モンスター１体を自分のデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"海底に潜む深海竜",h:"かいていにひそむしんかいりゅう",e:"The Dragon Dwelling in the Deep",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"お互いのスタンバイフェイズ毎にこのカードにオーシャンカウンターを１つ置く。\nこのカードがフィールド上から離れた時、このカードに乗っているオーシャンカウンター１つにつき、自分フィールド上に存在する魚族・海竜族モンスターの攻撃力はエンドフェイズ時まで２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式魔人ディザーズ",h:"ぎしきまじんでぃざーず",e:"Djinn Disserere of Rituals",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"儀式モンスターの儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、墓地に存在するこのカードをゲームから除外する事ができる。\nこのカードを儀式召喚に使用した儀式モンスターは罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛大神官",h:"じばくだいしんかん",e:"Earthbound Linewalker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、「地縛神」と名のついたモンスターは自身の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の巫女",h:"はかもりのみこ",e:"Gravekeeper's Priestess",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードがフィールド上に表側表示で存在する限り、フィールドは「王家の眠る谷－ネクロバレー」になる。\nフィールド魔法カードが表側表示で存在する場合、この効果は適用されない。\nまた、このカードがフィールド上に表側表示で存在する限り、フィールド上の「墓守」と名のついたモンスターの攻撃力・守備力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コア転送ユニット",h:"こあてんそうゆにっと",e:"Core Transport Unit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、手札を１枚捨てる事で、自分のデッキから「コアキメイルの鋼核」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"A・ジェネクス・アクセル",h:"あーりー・じぇねくす・あくせる",e:"Genex Ally Axel",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"「ジェネクス」と名のついたチューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、手札を１枚捨てる事で、自分の墓地に存在するレベル４以下の機械族モンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力はエンドフェイズ時まで倍になり、相手プレイヤーに直接攻撃する事はできず、自分のエンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタル・ガール",h:"くりすたる・がーる",e:"Crystal Girl",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのターンのエンドフェイズに、デッキからレベル５以上の水属性モンスター１体を手札に加える。\n②：このカードが墓地に存在し、自分フィールドにレベル５以上の水属性モンスターが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"マイティ・ウォリアー",h:"まいてぃ・うぉりあー",e:"Mighty Warrior",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが戦闘によって相手モンスターを破壊した場合、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－熱風のギブリ",h:"ぶらっくふぇざー－ねっぷうのぎぶり",e:"Blackwing - Ghibli the Searing Wind",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの元々の攻撃力・守備力をターン終了時まで入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－逆風のガスト",h:"ぶらっくふぇざー－ぎゃくふうのがすと",e:"Blackwing - Gust the Backblast",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"自分フィールド上にカードが存在しない場合、このカードは手札から特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、相手モンスターが自分フィールド上に存在する「BF」と名のついたモンスターを攻撃する場合、その攻撃モンスターはダメージステップの間攻撃力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－そよ風のブリーズ",h:"ぶらっくふぇざー－そよかぜのぶりーず",e:"Blackwing - Breeze the Zephyr",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:300,tx:"このカードがカードの効果によって自分のデッキから手札に加わった場合、このカードを手札から特殊召喚できる。\nこのカードをシンクロ素材とする場合、「BF」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"チェンジ・シンクロン",h:"ちぇんじ・しんくろん",e:"Changer Synchron",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、相手フィールド上に存在するモンスター１体を選択して表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"カード・ブレイカー",h:"かーど・ぶれいかー",e:"Card Breaker",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:100,de:900,tx:"このカードは通常召喚できない。\n自分の魔法＆罠カードゾーンに存在するカード１枚を墓地へ送った場合に表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"セカンド・ブースター",h:"せかんど・ぶーすたー",e:"Second Booster",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードをリリースし、自分フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は、エンドフェイズ時まで１５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"インターセプト・デーモン",h:"いんたーせぷと・でーもん",e:"Archfiend Interceptor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、相手モンスターの攻撃宣言時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレッド・ドラゴン",h:"どれっど・どらごん",e:"Dread Dragon",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:400,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキからレベル３以下のドラゴン族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トラスト・ガーディアン",h:"とらすと・がーでぃあん",e:"Trust Guardian",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:800,tx:"このカードをシンクロ素材とする場合、レベル７以上のシンクロモンスターのシンクロ召喚にしか使用できない。\nこのカードをシンクロ素材としたシンクロモンスターは、１ターンに１度だけ戦闘では破壊されない。\nこの効果を適用したダメージステップ終了時、そのシンクロモンスターの攻撃力・守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"フレア・リゾネーター",h:"ふれあ・りぞねーたー",e:"Flare Resonator",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:1300,tx:"このカードをシンクロ素材としたシンクロモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・マグネーター",h:"しんくろ・まぐねーたー",e:"Synchro Magnet",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードは通常召喚できない。\n自分がシンクロモンスターのシンクロ召喚に成功した時、このカードを手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ミラージュ",h:"いんふぇるにてぃ・みらーじゅ",e:"Infernity Mirage",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは墓地からの特殊召喚はできない。\n自分の手札が０枚の場合、このカードをリリースし、自分の墓地の「インフェルニティ」と名のついたモンスター２体を選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・リローダー",h:"いんふぇるにてぃ・りろーだー",e:"Infernity Randomizer",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:0,tx:"自分の手札が０枚の場合、１ターンに１度、自分のデッキからカードを１枚ドローする事ができる。\nこの効果でドローしたカードをお互いに確認し、モンスターカードだった場合、そのモンスターのレベル×２００ポイントダメージを相手ライフに与える。\n魔法・罠カードだった場合、自分は５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ビートル",h:"いんふぇるにてぃ・びーとる",e:"Infernity Beetle",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:0,tx:"自分の手札が０枚の場合、このカードをリリースする事で、デッキから「インフェルニティ・ビートル」を２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・リベンジャー",h:"いんふぇるにてぃ・りべんじゃー",e:"Infernity Avenger",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが墓地に存在し、自分の手札が０枚の場合、「インフェルニティ・リベンジャー」以外の自分フィールド上のモンスターが相手モンスターとの戦闘によって破壊され自分の墓地へ送られた時、このカードを墓地から特殊召喚できる。\nこの効果で特殊召喚したこのカードは、その戦闘によって破壊された自分のモンスターの元々のレベルと同じレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"返り咲く薔薇の大輪",h:"りばいばる・ろーず",e:"Revival Rose",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"自分フィールド上に存在するレベル５以上の植物族モンスターが破壊された場合、墓地に存在するこのカードを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"D・クリーナン",h:"でぃふぉーまー・くりーなん",e:"Morphtronic Vacuumen",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、このカードに装備された装備カード１枚を墓地へ送る事で、相手ライフに５００ポイントダメージを与える。\n●守備表示：１ターンに１度、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択し、装備カード扱いとしてこのカードに１体のみ装備できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ローズ・バード",h:"ろーず・ばーど",e:"Bird of Roses",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"自分フィールド上に表側攻撃表示で存在するこのカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、デッキから植物族チューナー２体を表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スポーア",h:"すぽーあ",e:"Spore",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地からこのカード以外の植物族モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードのレベルは除外したモンスターのレベル分だけ上がる。",li:"",lk:[],ta:[],al:[]},
  {n:"フェアリー・アーチャー",h:"ふぇありー・あーちゃー",e:"Fairy Archer",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"自分のメインフェイズ時に発動できる。\n自分フィールド上の光属性モンスターの数×４００ポイントダメージを相手ライフに与える。\n「フェアリー・アーチャー」の効果は１ターンに１度しか使用できず、この効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"バイオファルコン",h:"ばいおふぁるこん",e:"Biofalcon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードがフィールド上に表側表示で存在する場合に自分フィールド上の機械族モンスターが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１０００以下の機械族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"プチトマボー",h:"ぷちとまぼー",e:"Cherry Inmato",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「トマボー」と名のついたモンスターを２体まで特殊召喚できる。\nこのターン、この効果で特殊召喚したモンスターはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジドッグ",h:"まじどっぐ",e:"Magidog",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが魔法使い族モンスターのシンクロ召喚に使用され墓地へ送られた場合、自分の墓地に存在するフィールド魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"光霊使いライナ",h:"こうれいつかいらいな",e:"Lyna the Light Charmer",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの光属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキリン",h:"えれきりん",e:"Wattgiraffe",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1200,de:100,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手はこのターンのエンドフェイズ時まで魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキツネ",h:"えれきつね",e:"Wattfox",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:100,tx:"このカードが相手によって破壊された場合、そのターン相手はモンスターの特殊召喚及び魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキツツキ",h:"えれきつつき",e:"Wattwoodpecker",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:100,tx:"このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードと戦闘を行ったモンスターは表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・サンドマン",h:"こあきめいる・さんどまん",e:"Koa'ki Meiru Sandman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の岩石族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nまた、相手の罠カードが発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"記憶破壊王",h:"めもりー・くらっしゅ・きんぐ",e:"Memory Crush King",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手の墓地に存在するシンクロモンスターを全てゲームから除外し、その数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デルタトライ",h:"でるたとらい",e:"Delta Tri",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、次の効果から１つを選択して発動する。\n●自分の墓地に存在する装備可能なユニオンモンスター１体を選択してこのカードに装備する。\n●自分フィールド上に表側表示で存在する機械族・光属性モンスター１体を選択してデッキに戻し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トライゴン",h:"とらいごん",e:"Trigon",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:1700,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の機械族モンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、自分の墓地から機械族・光属性・レベル４以下のモンスター１体を選択して特殊召喚する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"かつて神と呼ばれた亀",h:"かつてかみとよばれたかめ",e:"Testudo erat Numen",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、お互いに攻撃力１８００以上のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"粋カエル",h:"いきかえる",e:"Ronintoadin",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカードはS素材にできない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「デスガエル」として扱う。\n②：このカードが墓地に存在する場合、自分の墓地から「ガエル」モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"電池メン－単四型",h:"でんちめん－たんよんがた",e:"Batteryman AAA",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・リバースした時、自分の手札・墓地から「電池メン－単四型」１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"燃料電池メン",h:"ねんりょうでんちめん",e:"Batteryman Fuel Cell",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2100,de:0,tx:"自分フィールド上に「電池メン」と名のついたモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n１ターンに１度、このカード以外の自分フィールド上の「電池メン」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"キーマウス",h:"きーまうす",e:"Key Mouse",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル３以下の獣族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"A・O・J コアデストロイ",h:"あーりー・おぶ・じゃすてぃす　こあですとろい",e:"Ally of Justice Core Destroyer",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"このカードが光属性モンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒羽を狩る者",h:"だーくねす・はんたー",e:"Hunter of Black Feathers",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"相手フィールド上にモンスターが表側表示で２体以上存在し、そのモンスターの種族が全て同じ場合、手札を１枚墓地へ送る事で、相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラックフェザー・ドラゴン",h:"ぶらっくふぇざー・どらごん",e:"Black-Winged Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：自分が効果ダメージを受ける場合、代わりにこのカードに黒羽カウンターを１つ置く。\n②：このカードの攻撃力は、このカードの黒羽カウンターの数×７００ダウンする。\n③：１ターンに１度、このカードの黒羽カウンターを全て取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力は取り除いた黒羽カウンターの数×７００ダウンし、ダウンした数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモン・カオス・キング",h:"でーもん・かおす・きんぐ",e:"Chaos King Archfiend",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2600,tx:"悪魔族チューナー＋チューナー以外のモンスター１体以上\nこのカードの攻撃宣言時、相手フィールド上の全てのモンスターの攻撃力・守備力をバトルフェイズ終了時まで入れ替える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・デス・ドラゴン",h:"いんふぇるにてぃ・です・どらごん",e:"Infernity Doom Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2400,tx:"闇属性チューナー＋チューナー以外のモンスター１体以上\n自分の手札が０枚の場合、１ターンに１度、相手フィールド上のモンスター１体を選択して発動できる。\n選択した相手モンスターを破壊し、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スプレンディッド・ローズ",h:"すぷれんでぃっど・ろーず",e:"Splendid Rose",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分の墓地の植物族モンスター１体をゲームから除外して発動できる。\n相手フィールド上のモンスター１体を選択し、その攻撃力をエンドフェイズ時まで半分にする。\nまた、このカードが攻撃したバトルフェイズ中に、自分の墓地の植物族モンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力をエンドフェイズ時まで半分にし、そのバトルフェイズ中、このカードはもう１度だけ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ゴッデス－混沌の女神－",h:"かおす・ごっです－こんとんのめがみ－",e:"Chaos Goddess",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"光属性チューナー＋チューナー以外の闇属性モンスター２体以上\n１ターンに１度、手札から光属性モンスター１体を墓地へ送り、自分の墓地のレベル５以上の闇属性モンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラックフェザー・シュート",h:"ぶらっくふぇざー・しゅーと",e:"Black-Winged Strafe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「BF」と名のついたモンスター１体を墓地へ送り、相手フィールド上に守備表示で存在するモンスター１体を選択して発動する。\n選択したモンスターを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"黒羽の宝札",h:"くろはねのほうさつ",e:"Cards for Black Feathers",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の「BF」と名のついたモンスター１体をゲームから除外して発動できる。\nデッキからカードを２枚ドローする。\n「黒羽の宝札」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ZERO－MAX",h:"ぜろ－まっくす",e:"ZERO-MAX",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札が０枚の場合、自分の墓地に存在する「インフェルニティ」と名のついたモンスター１体を選択して発動する事ができる。\n選択したモンスターを特殊召喚し、特殊召喚したモンスターの攻撃力より低い攻撃力を持つ、フィールド上に表側表示で存在するモンスターを全て破壊する。\nこのカードを発動するターン、自分はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティガン",h:"いんふぇるにてぃがん",e:"Infernity Launcher",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「インフェルニティ」モンスター１体を墓地へ送る。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分の墓地の「インフェルニティ」モンスターを２体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果は自分の手札が０枚の場合に発動と処理ができる。",li:1,lk:[],ta:[],al:[]},
  {n:"無の煉獄",h:"むのれんごく",e:"Into the Void",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札が３枚以上の場合に発動できる。\n自分のデッキからカードを１枚ドローし、このターンのエンドフェイズ時に自分の手札を全て捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"妨害電波",h:"ぼうがいでんぱ",e:"Intercept Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上にシンクロモンスターが表側攻撃表示で存在する場合に発動できる。\nフィールド上のシンクロモンスターを全て表側守備表示にする。\nこのターンのエンドフェイズ時にフィールド上のシンクロモンスターを全てエクストラデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡のピラミッド",h:"きせきのぴらみっど",e:"Pyramid of Wonders",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するアンデット族モンスターの攻撃力は、相手フィールド上に存在するモンスターの数×２００ポイントアップする。\n自分フィールド上に表側表示で存在するアンデット族モンスター１体が破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"天空の泉",h:"てんくうのいずみ",e:"The Fountain in the Sky",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"光属性モンスターが戦闘によって破壊され自分の墓地へ送られた時、そのモンスター１体をゲームから除外する事で、そのモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンレーザー",h:"どらごんれーざー",e:"Dragon Laser",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「デルタトライ」に装備された「トライゴン」１体を墓地へ送り、相手フィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキューブ",h:"えれきゅーぶ",e:"Wattcube",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"雷族モンスターにのみ装備可能。\n装備モンスターの攻撃力は、自分の墓地に存在する雷族モンスターの数×１００ポイントアップする。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、自分フィールド上に表側表示で存在する雷族モンスター１体の攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"電磁シールド",h:"でんじしーるど",e:"Electromagnetic Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側守備表示で存在するレベル３以下の雷族モンスターは戦闘では破壊されない。\n自分フィールド上にモンスターが表側攻撃表示で存在する場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーム・コール",h:"わーむ・こーる",e:"Worm Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、手札から「ワーム」と名のついた爬虫類族モンスター１体を裏側守備表示で特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の三方陣",h:"ひょうけっかいのさんほうじん",e:"Magic Triangle of the Ice Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：カード名が異なる手札の「氷結界」モンスター３体を相手に見せ、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊し、手札から「氷結界」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼核初期化",h:"こあきめいる・いにしゃらいず",e:"Koa'ki Meiru Initialize!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「コアキメイル」と名のついたモンスター１体をリリースして発動する。\n自分のデッキ・墓地から「コアキメイルの鋼核」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"宣告者の預言",h:"でくれあらー・ぷろふぇしー",e:"Dawn of the Herald",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「神光の宣告者」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が６になるようにモンスターをリリースしなければならない。\nこのカードの効果によって「神光の宣告者」が儀式召喚に成功した時、自分の墓地のこのカードをゲームから除外する事で、その儀式召喚のためにリリースしたモンスター１体を選択し、自分の墓地から手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"墓地封印",h:"ぼちふういん",e:"Silent Graveyard",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\nこのターン、墓地で発動するカードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"シャイニング・アブソーブ",h:"しゃいにんぐ・あぶそーぶ",e:"Leeching the Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在する光属性モンスター１体を選択して発動する。\n自分フィールド上に表側攻撃表示で存在する全てのモンスターの攻撃力はエンドフェイズ時まで、選択したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"苦痛の回廊",h:"くつうのかいろう",e:"Corridor of Agony",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、デッキから特殊召喚されたモンスターは、フィールド上に表側表示で存在する限り効果を発動できず無効化され、攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・フレーム",h:"ぱわー・ふれーむ",e:"Power Frame",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが、その攻撃力よりも高い攻撃力を持つモンスターの攻撃対象に選択された時に発動する事ができる。\nその攻撃を無効にし、このカードを攻撃対象モンスター１体に装備する。\n装備モンスターの攻撃力は、その時の攻撃モンスターと攻撃対象モンスターの攻撃力の差の数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－バックフラッシュ",h:"ぶらっくふぇざー－ばっくふらっしゅ",e:"Blackwing - Backlash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に「BF」と名のついたモンスターが５体以上存在する場合、相手モンスターの直接攻撃宣言時に発動する事ができる。\n相手フィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－アンカー",h:"ぶらっくふぇざー－あんかー",e:"Blackwing - Bombardment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「BF」と名のついたモンスター１体をリリースし、自分フィールド上に表側表示で存在するシンクロモンスター１体を選択して発動する。\n選択したモンスターの攻撃力はエンドフェイズ時まで、このカードを発動するためにリリースしたモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・サンダー",h:"ぶらっく・さんだー",e:"Black Thunder",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「BF」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n相手フィールド上に存在するカード１枚につき、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ボム・ガード",h:"ぼむ・がーど",e:"Guard Mines",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「自分フィールド上に存在するモンスター１体を破壊する効果」を持つカードが発動した時に発動する事ができる。\nその発動を無効にし破壊する。\nさらに相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・リフレクター",h:"いんふぇるにてぃ・りふれくたー",e:"Infernity Reflector",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「インフェルニティ」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時、手札を全て捨てて発動する事ができる。\nそのモンスター１体を自分の墓地から特殊召喚し、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ブレイク",h:"いんふぇるにてぃ・ぶれいく",e:"Infernity Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札が０枚の場合に発動できる。\n自分の墓地の「インフェルニティ」と名のついたカード１枚を選択してゲームから除外し、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・ゲート",h:"だめーじ・げーと",e:"Damage Gate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘ダメージを受けた時に発動できる。\nその時に受けたダメージの数値以下の攻撃力を持つ、自分の墓地のモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・インフェルノ",h:"いんふぇるにてぃ・いんふぇるの",e:"Infernity Inferno",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札を２枚まで捨てる。\nその後、この効果で捨てた枚数分だけデッキから「インフェルニティ」と名のついたカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンドレス・フェイク",h:"はんどれす・ふぇいく",e:"Phantom Hand",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「インフェルニティ」と名のついたモンスターが表側表示で存在する場合、１ターンに１度だけ、次の自分のスタンバイフェイズ時まで自分の手札を全て裏側表示でゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アサルト・スピリッツ",h:"あさると・すぴりっつ",e:"Assault Spirits",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に存在するモンスター１体に装備する。\n装備モンスターが攻撃する場合、そのダメージステップ時に手札から攻撃力１０００以下のモンスター１体を墓地へ送る事で、装備モンスターの攻撃力はエンドフェイズ時まで、墓地へ送ったモンスターの攻撃力分アップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブロッサム・ボンバー",h:"ぶろっさむ・ぼんばー",e:"Blossom Bombardment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する植物族モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時に発動する事ができる。\nその戦闘で破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"D・スクランブル",h:"でぃふぉーまー・すくらんぶる",e:"Morphtronics, Scramble!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時、自分フィールド上にモンスターが存在しない場合に発動する事ができる。\nその攻撃を無効にし、手札から「D」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・ブレイク",h:"ぱわー・ぶれいく",e:"Power Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「パワー・ツール・ドラゴン」が表側表示で存在する場合に発動する事ができる。\n自分のフィールド上・墓地に存在する装備カードを３枚まで選択してデッキに戻し、戻した数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイルの障壁",h:"こあきめいるのしょうへき",e:"Koa'ki Meiru Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に「コアキメイルの鋼核」が２枚以上存在する場合、相手モンスターの攻撃宣言時に発動する事ができる。\n相手フィールド上に表側攻撃表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元への隙間",h:"いじげんへのすきま",e:"Crevice Into the Different Dimension",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"属性を１つ宣言し、お互いの墓地に存在する宣言した属性のモンスターを合計２体選択して発動する。\n選択したモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・イジェクション",h:"しんくろ・いじぇくしょん",e:"Synchro Ejection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側表示で存在するシンクロモンスター１体を選択してゲームから除外し、相手はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の落とし穴",h:"かおす・ほーる",e:"Chaos Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"２０００ライフポイントを払って発動できる。\n光属性または闇属性モンスターの召喚・反転召喚・特殊召喚を無効にしゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダニポン",h:"だにぽん",e:"Danipon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから守備力１０００以下の昆虫族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"モロコシーナ",h:"もろこしーな",e:"Sweet Corn",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分のメインフェイズ２で５００ライフポイントを払って発動する事ができる。\n自分フィールド上に「つぶコーントークン」（植物族・地・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"吸血コアラ",h:"きゅうけつこあら",e:"Vampiric Koala",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカードがモンスターとの戦闘によって相手ライフに戦闘ダメージを与えた時、与えた戦闘ダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"荒野の大竜巻",h:"こうやのおおたつまき",e:"Wild Tornado",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：魔法＆罠ゾーンの表側表示のカード１枚を対象として発動できる。\nその表側表示のカードを破壊する。\nその後、破壊されたカードのコントローラーは手札から魔法・罠カード１枚をセットできる。\n②：セットされたこのカードが破壊され墓地へ送られた場合、フィールドの表側表示のカード１枚を対象として発動する。\nその表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・デストロイヤー",h:"じゃんく・ですとろいやー",e:"Junk Destroyer",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2500,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時、そのS素材としたモンスターの内、チューナー以外のモンスターの数までフィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラピッド・ウォリアー",h:"らぴっど・うぉりあー",e:"Rapid Warrior",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"メインフェイズ１でのみ発動する事ができる。\nこのターンこのカードは相手プレイヤーに直接攻撃する事ができる。\nこの効果を発動するターン、このカード以外のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロン・エクスプローラー",h:"しんくろん・えくすぷろーらー",e:"Synchron Explorer",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:700,tx:"①：このカードが召喚に成功した時、自分の墓地の「シンクロン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーフ・シャット",h:"はーふ・しゃっと",e:"Half Shut",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力が半分になり、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin 青眼の白龍",h:"しん ぶるーあいず・ほわいと・どらごん",e:"Malefic Blue-Eyes White Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\nデッキから「青眼の白龍」１体を除外した場合に特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin スターダスト・ドラゴン",h:"しん すたーだすと・どらごん",e:"Malefic Stardust Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは通常召喚できない。\nEXデッキから「スターダスト・ドラゴン」１体を除外した場合のみ特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、フィールドゾーンの表側表示のカードは効果では破壊されない。\n③：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n④：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ネオス・ナイト",h:"えれめんたるひーろー ねおす・ないと",e:"Elemental HERO Neos Knight",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1000,tx:"「E・HERO ネオス」＋戦士族モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、このカードの融合素材とした「E・HERO ネオス」以外のモンスターの元々の攻撃力の半分の数値分アップする。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：このカードの戦闘で発生する相手への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin パラドクス・ドラゴン",h:"しん ぱらどくす・どらごん",e:"Malefic Paradox Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「Sin パラレルギア」＋チューナー以外の「Sin」モンスター１体\n①：「Sin パラドクス・ドラゴン」はフィールドに１体しか表側表示で存在できない。\n②：このカードがS召喚に成功した時、自分または相手の墓地のSモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\n③：フィールドに「Sin World」が存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界龍 ドラゴネクロ",h:"めいかいりゅう　どらごねくろ",e:"Dragonecro Nethersoul Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:0,tx:"アンデット族モンスター×２\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\nこのカードと戦闘を行うモンスターはその戦闘では破壊されない。\nまた、このカードがモンスターと戦闘を行ったダメージステップ終了時、そのモンスターの攻撃力は０になり、そのモンスターの元々のレベル・攻撃力を持つ「ダークソウルトークン」（アンデット族・闇・星？・攻？／守０）１体を自分フィールド上に特殊召喚する。\n「冥界龍 ドラゴネクロ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライティ・ドライバー",h:"らいてぃ・どらいばー",e:"Righty Driver",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:300,tx:"このカードは「シンクロン」チューナーの代わりとしてS素材にできる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動できる。\n自分の手札・デッキ・墓地から「レフティ・ドライバー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神の氷結",h:"かみのひょうけつ",e:"The Ice-Bound God",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドに水属性モンスターが２体以上存在する場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターは攻撃できず、効果は無効化される。\n②：このカードが墓地に存在する状態で、自分フィールドにレベル５以上の水属性モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界",h:"ひょうけっかい",e:"Ice Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手モンスターが戦闘を行う攻撃宣言時に発動できる。\nその相手モンスターは、攻撃力が０になり、表示形式を変更できず、効果は無効化される。\n②：墓地のこのカードを除外して発動できる。\nデッキからレベル５以上の水属性モンスター１体を墓地へ送る。\nその後、自分の墓地から水属性モンスター１体を選んで手札に加える事ができる。\nこの効果の発動後、次の自分ターンの終了時まで自分は水属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリザード・プリンセス",h:"ぶりざーど・ぷりんせす",e:"Blizzard Princess",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"このカードは魔法使い族モンスター１体をリリースして表側攻撃表示でアドバンス召喚する事ができる。\nこのカードが召喚に成功したターン、相手は魔法・罠カードを発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin 真紅眼の黒竜",h:"しん れっどあいず・ぶらっくどらごん",e:"Malefic Red-Eyes Black Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは通常召喚できない。\nデッキから「真紅眼の黒竜」１体を除外した場合に特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・ガネット",h:"じぇむないと・がねっと",e:"Gem-Knight Garnet",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1900,de:0,tx:"ガーネットの力を宿すジェムナイトの戦士。\n炎の鉄拳はあらゆる敵を粉砕するぞ。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・サフィア",h:"じぇむないと・さふぃあ",e:"Gem-Knight Sapphire",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"サファイアのパワーで水を自在に操り、敵からの攻撃をやさしく包み込んでしまう。\nその静かなる守りは仲間から信頼されているらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・ルマリン",h:"じぇむないと・るまりん",e:"Gem-Knight Tourmaline",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"イエロートルマリンの力で不思議なエナジーを創りだし、戦力に変えて闘うぞ。\n彼の刺激的な生き方に共感するジェムは多い。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・アレキサンド",h:"じぇむないと・あれきさんど",e:"Gem-Knight Alexandrite",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードをリリースして発動できる。\nデッキから「ジェムナイト」通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムレシス",h:"じぇむれしす",e:"Gem-Armadillo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ジェムナイト」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェム・マーチャント",h:"じぇむ・まーちゃんと",e:"Gem-Merchant",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分フィールド上の地属性の通常モンスターが戦闘を行うダメージステップ時、このカードを手札から墓地へ送って発動できる。\nそのモンスターの攻撃力・守備力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルの炎車回し",h:"らう゛ぁるのえんしゃまわし",e:"Laval Miller",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:300,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「ラヴァル」と名のついたモンスター２体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮地帯を飛ぶ鷹",h:"ぐれんちたいをとぶたか",e:"Soaring Eagle Above the Searing Land",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:0,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合に自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、このカードを墓地から特殊召喚できる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・ウォリアー",h:"らう゛ぁる・うぉりあー",e:"Laval Warrior",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"このカードが戦闘によって相手モンスターを破壊した場合に自分の墓地の「ラヴァル」と名のついたモンスターが４種類以上の場合、その戦闘で破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"炎熱刀プロミネンス",h:"えんねつとうぷろみねんす",e:"Prominence, Molten Swordsman",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"１ターンに１度、自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで３００ポイントアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル炎樹海の妖女",h:"らう゛ぁるえんじゅかいのようじょ",e:"Laval Forest Sprite",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:200,tx:"このカードがフィールド上から墓地へ送られた時、自分フィールド上の全ての「ラヴァル」と名のついたモンスターの攻撃力はエンドフェイズ時まで、自分の墓地の「ラヴァル」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"灼熱工の巨匠カエン",h:"しゃくねつこうのきょしょうかえん",e:"Kayenn, the Master Magma Blacksmith",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"自分のメインフェイズ時、墓地のこのカードをゲームから除外して発動できる。\n自分フィールド上の全ての「ラヴァル」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルバーナー",h:"らう゛ぁるばーなー",e:"Laval Burner",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルロード・ジャッジメント",h:"らう゛ぁるろーど・じゃっじめんと",e:"Laval Judgment Lord",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:1800,tx:"１ターンに１度、自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外して発動できる。\n相手ライフに１０００ポイントダメージを与える。\nこの効果を発動するターン、「ラヴァルロード・ジャッジメント」は攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・キューブ",h:"う゛ぁいろん・きゅーぶ",e:"Vylon Cube",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが光属性モンスターのシンクロ召喚に使用され墓地へ送られた場合、デッキから装備魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・ヴァンガード",h:"う゛ぁいろん・う゛ぁんがーど",e:"Vylon Vanguard",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードがカードの効果によって破壊され墓地へ送られた時、このカードに装備されていた装備カードの数だけデッキからカードをドローできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・チャージャー",h:"う゛ぁいろん・ちゃーじゃー",e:"Vylon Charger",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の光属性モンスターの攻撃力は、このカードに装備された装備カードの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・ソルジャー",h:"う゛ぁいろん・そるじゃー",e:"Vylon Soldier",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードの攻撃宣言時、このカードに装備された装備カードの数まで相手フィールド上のモンスターを選択し、表示形式を変更できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・ルビーズ",h:"じぇむないと・るびーず",e:"Gem-Knight Ruby",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1300,tx:"「ジェムナイト・ガネット」＋「ジェムナイト」モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n①：１ターンに１度、このカード以外の自分フィールドの表側表示の「ジェム」モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで、リリースしたモンスターの攻撃力分アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・アクアマリナ",h:"じぇむないと・あくあまりな",e:"Gem-Knight Aquamarine",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:2600,tx:"「ジェムナイト・サフィア」＋「ジェムナイト」モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n②：このカードがフィールドから墓地へ送られた場合、相手フィールドのカード１枚を対象として発動する。\nその相手のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・パーズ",h:"じぇむないと・ぱーず",e:"Gem-Knight Topaz",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:1800,de:1800,tx:"「ジェムナイト・ルマリン」＋「ジェムナイト」モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルバル・ドラゴン",h:"らう゛ぁるばる・どらごん",e:"Lavalval Dragon",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1100,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\n①：自分の墓地の「ラヴァル」モンスター２体をデッキに戻し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・グレイター",h:"らう゛ぁる・ぐれいたー",e:"Laval the Greater",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:800,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分は手札を１枚墓地へ送る。\nこのカードがカードの効果によって破壊される場合、代わりに自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・シグマ",h:"う゛ぁいろん・しぐま",e:"Vylon Sigma",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:1800,de:1000,tx:"光属性チューナー＋チューナー以外の光属性モンスター１体以上\n自分フィールド上にこのカード以外のモンスターが存在しない場合、このカードの攻撃宣言時に発動できる。\nデッキから装備魔法カード１枚を選んでこのカードに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・エプシロン",h:"う゛ぁいろん・えぷしろん",e:"Vylon Epsilon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1200,tx:"光属性チューナー＋チューナー以外のモンスター１体以上\nこのカードに装備された装備カードは、カードの効果の対象にならない。\nまた、１ターンに１度、このカードに装備された装備カード１枚を墓地へ送って発動できる。\n相手フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・フュージョン",h:"じぇむないと・ふゅーじょん",e:"Gem-Knight Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「ジェムナイト」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードが墓地に存在する場合、自分の墓地の「ジェムナイト」モンスター１体を除外して発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮の炎壁",h:"ぐれんのえんぺき",e:"Searing Fire Wall",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地の「ラヴァル」と名のついたモンスターを任意の枚数ゲームから除外して発動できる。\nこのカードを発動するために除外したモンスターの数だけ自分フィールド上に「ラヴァルトークン」（炎族・炎・星１・攻／守０）を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・マテリアル",h:"う゛ぁいろん・まてりある",e:"Vylon Material",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は６００ポイントアップする。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、デッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェム・エンハンス",h:"じぇむ・えんはんす",e:"Gem-Enhancement",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「ジェムナイト」と名のついたモンスター１体をリリースし、自分の墓地の「ジェムナイト」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎熱旋風壁",h:"えんねつせんぷうへき",e:"Molten Whirlwind Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「ラヴァル」と名のついたモンスターの攻撃力は、自分の墓地の「ラヴァル」と名のついたモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・アリゲーター",h:"だーく・ありげーたー",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2500,de:2300,tx:"このカードは爬虫類族モンスター１体をリリースしてアドバンス召喚できる。\n①：このカードがアドバンス召喚に成功した時に発動できる。\nこのカードのアドバンス召喚のためにリリースした爬虫類族モンスターの数まで、自分フィールドに「アリゲーター・トークン」（爬虫類族・闇・星１・攻２０００／守０）を特殊召喚する。\n②：アドバンス召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「ダーク・アリゲーター」以外の爬虫類族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アースクエイク・ジャイアント",h:"あーすくえいく・じゃいあんと",e:"Earthquake Giant",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:2100,tx:"このカードの表示形式が変更された時、相手フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エフェクト・ヴェーラー",h:"えふぇくと・う゛ぇーらー",e:"Effect Veiler",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：相手メインフェイズに、このカードを手札から墓地へ送り、相手フィールドの効果モンスター１体を対象として発動できる。\nその相手モンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダッシュ・ウォリアー",h:"だっしゅ・うぉりあー",e:"Dash Warrior",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"このカードが攻撃する場合、ダメージステップの間このカードの攻撃力は１２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・イーター",h:"だめーじ・いーたー",e:"Damage Eater",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"相手がダメージを与える魔法・罠・効果モンスターの効果を発動した時、墓地に存在するこのカードをゲームから除外して発動する事ができる。\nその効果は、ライフポイントを回復する効果になる。\nこの効果は相手ターンにのみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ADチェンジャー",h:"えーでぃーちぇんじゃー",e:"A/D Changer",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分のメインフェイズ時に、墓地のこのカードをゲームから除外し、フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"牙城のガーディアン",h:"がじょうのがーでぃあん",e:"Stronghold Guardian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"自分フィールド上に守備表示で存在するモンスターが攻撃された場合、そのダメージステップ時にこのカードを手札から墓地へ送る事で、その戦闘を行う自分のモンスターの守備力はエンドフェイズ時まで１５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"おとぼけオポッサム",h:"おとぼけおぽっさむ",e:"Playful Possum",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"自分のメインフェイズ時、このカードの攻撃力よりも高い攻撃力を持つモンスターが相手フィールド上に表側表示で存在する場合、フィールド上に存在するこのカードを破壊する事ができる。\nまた、自分のスタンバイフェイズ時、このカードの効果で破壊されたこのカードを墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"虚栄の大猿",h:"きょえいのおおざる",e:"Egotistical Ape",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードは通常召喚できない。\n手札から獣族モンスター１体を墓地へ送った場合に特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、墓地へ送ったその獣族モンスターのレベルを確認し、次の効果から１つを選択して発動する事ができる。\n●そのレベルの数だけこのカードのレベルを上げる。\n●そのレベルの数だけこのカードのレベルを下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"一角獣の使い魔",h:"いっかくじゅうのつかいま",e:"Uni-Horned Familiar",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1000,tx:"表側守備表示で存在するこのカードが攻撃対象に選択された時、このカード以外の自分フィールド上に存在するモンスター１体をゲームから除外する事で、このカードをゲームから除外する事ができる。\nその時の攻撃モンスターは攻撃しなければならない。\nこの効果で除外したこのカードは、次の自分のスタンバイフェイズ時にフィールド上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"モノケロース",h:"ものけろーす",e:"Monoceros",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n手札の魔法カード１枚をゲームから除外した場合に特殊召喚する事ができる。\nこのカードと獣族チューナーを素材としたシンクロ召喚に成功した時、そのシンクロ召喚の素材とした獣族チューナー１体を墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の一角戦士",h:"いじげんのゆにこーんないと",e:"D.D. Unicorn Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードは通常召喚できず、このカードを特殊召喚するターン自分は通常召喚できない。\nこのカードは相手フィールド上にモンスターが存在し、自分フィールド上にチューナーが表側表示で存在する場合に特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、ゲームから除外されているチューナー以外のレベル３以下の自分のモンスター１体を選択して特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニバード",h:"ゆにばーど",e:"Unibird",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"自分フィールド上に表側表示で存在するモンスター１体とこのカードをゲームから除外し、その元々のレベルを合計した数以下のレベルを持つシンクロモンスター１体を自分の墓地から選択して発動する。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"二角獣レーム",h:"にかくじゅうれーむ",e:"Bicorn Re'em",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、相手のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"マイン・モール",h:"まいん・もーる",e:"Mine Mole",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードは１ターンに１度だけ、戦闘では破壊されない。\nこのカードが獣族モンスターのシンクロ召喚の素材として墓地へ送られた場合、自分のデッキからカードを１枚ドローする。\nこのカードは相手のカードの効果によってフィールド上から離れた場合、ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"トライデント・ウォリアー",h:"とらいでんと・うぉりあー",e:"Trident Warrior",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル３モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デルタフライ",h:"でるたふらい",e:"Delta Flyer",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:900,tx:"①：１ターンに１度、このカード以外の自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライノタウルス",h:"らいのたうるす",e:"Rhinotaurus",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"１度のバトルフェイズ中に、自分フィールド上に存在するモンスターの戦闘によって相手モンスターを２体以上破壊した場合、このカードはそのバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻角獣フュプノコーン",h:"げんかくじゅうふゅぷのこーん",e:"Hypnocorn",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合にこのカードが召喚に成功した時、フィールド上にセットされた魔法・罠カード１枚を選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・キマイラ",h:"すくらっぷ・きまいら",e:"Scrap Chimera",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"このカードをS素材とする場合、「スクラップ」モンスターのS召喚にしか使用できず、他のS素材モンスターは全て「スクラップ」モンスターでなければならない。\n①：このカードが召喚に成功した時、自分の墓地の「スクラップ」チューナー１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ゴブリン",h:"すくらっぷ・ごぶりん",e:"Scrap Goblin",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:500,tx:"フィールド上に表側守備表示で存在するこのカードが攻撃対象に選択された場合、バトルフェイズ終了時にこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ゴブリン」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。\nまた、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ビースト",h:"すくらっぷ・びーすと",e:"Scrap Beast",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1300,tx:"フィールド上に表側守備表示で存在するこのカードが攻撃対象に選択された場合、バトルフェイズ終了時にこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ビースト」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ハンター",h:"すくらっぷ・はんたー",e:"Scrap Hunter",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"１ターンに１度、このカード以外の自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して破壊し、自分のデッキからチューナー１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ゴーレム",h:"すくらっぷ・ごーれむ",e:"Scrap Golem",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2300,de:1400,tx:"１ターンに１度、自分の墓地に存在するレベル４以下の「スクラップ」と名のついたモンスター１体を選択し、自分または相手フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキンギョ",h:"えれきんぎょ",e:"Wattbetta",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:0,tx:"このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手は手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキツネザル",h:"えれきつねざる",e:"Wattlemur",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"このカードが相手によって破壊された場合、次の相手ターン、相手はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキジ",h:"えれきじ",e:"Wattpheasant",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、フィールド上に表側表示で存在するモンスター１体を選択し、このターンのエンドフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・モスキート",h:"なちゅる・もすきーと",e:"Naturia Mosquito",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"自分フィールド上にこのカード以外の「ナチュル」と名のついたモンスターが表側表示で存在する限り、相手はこのカードを攻撃対象に選択する事はできない。\nこのカード以外の自分フィールド上に表側表示で存在する「ナチュル」と名のついたモンスターの戦闘によって発生する自分への戦闘ダメージは、代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ビーンズ",h:"なちゅる・びーんず",e:"Naturia Beans",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:1200,tx:"このカードは１ターンに１度だけ、戦闘では破壊されない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・バンブーシュート",h:"なちゅる・ばんぶーしゅーと",e:"Naturia Bamboo Shoot",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"「ナチュル」と名のついたモンスターをリリースしてアドバンス召喚に成功したこのカードがフィールド上に表側表示で存在する限り、相手は魔法・罠カードを発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの賢者",h:"あまぞねすのけんじゃ",e:"Amazoness Sage",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"このカードが攻撃した場合、そのダメージステップ終了時に相手フィールド上に存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネス訓練生",h:"あまぞねすくんれんせい",e:"Amazoness Trainee",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが戦闘によって破壊したモンスターは墓地へ送らず持ち主のデッキの一番下に戻す。\nこのカードが戦闘によって相手モンスターを破壊した場合、このカードの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネス女王",h:"あまぞねすくぃーん",e:"Amazoness Queen",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、自分の「アマゾネス」モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ロックキャット",h:"ろっくきゃっと",e:"Lock Cat",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが召喚に成功した時、自分の墓地に存在するレベル１の獣族モンスター１体を選択して表側守備表示で特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"エレファン",h:"えれふぁん",e:"Elephun",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、ゲームから除外されている自分のレベル３以下の獣族・獣戦士族・鳥獣族モンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・フュージョニスト",h:"しんくろ・ふゅーじょにすと",e:"Synchro Fusionist",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"①：このカードがS素材として墓地へ送られた場合に発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"野望のゴーファー",h:"やぼうのごーふぁー",e:"Ambitious Gofer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:100,tx:"１ターンに１度、相手フィールド上に存在するモンスターを２体まで選択して発動する事ができる。\n相手は手札のモンスター１体を見せてこのカードの効果を無効にする事ができる。\n見せなかった場合、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイナルサイコオーガ",h:"ふぁいなるさいこおーが",e:"Final Psychic Ogre",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2200,de:1700,tx:"このカードが戦闘によって相手モンスターを破壊した場合、８００ライフポイントを払う事で自分の墓地に存在するサイキック族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"波動竜騎士 ドラゴエクィテス",h:"はどうりゅうきし　どらごえくぃてす",e:"Dragon Knight Draco-Equiste",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:2000,tx:"ドラゴン族シンクロモンスター＋戦士族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚する事ができる。\n１ターンに１度、墓地に存在するドラゴン族のシンクロモンスター１体をゲームから除外し、エンドフェイズ時までそのモンスターと同名カードとして扱い、同じ効果を得る事ができる。\nまた、このカードがフィールド上に表側攻撃表示で存在する限り、相手のカードの効果によって発生する自分への効果ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"アルティメットサイキッカー",h:"あるてぃめっとさいきっかー",e:"Ultimate Axon Kicker",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:1700,tx:"サイキック族シンクロモンスター＋サイキック族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚する事ができる。\nこのカードはカードの効果では破壊されない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ユニコーン",h:"さんだー・ゆにこーん",e:"Thunder Unicorn",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"獣族チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に相手フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターの攻撃力はエンドフェイズ時まで、自分フィールド上に存在するモンスターの数×５００ポイントダウンする。\nこの効果を発動するターン、このカード以外のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ボルテック・バイコーン",h:"ぼるてっく・ばいこーん",e:"Voltic Bicorn",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"獣族チューナー＋チューナー以外のモンスター１体以上\nこのカードが相手によって破壊された場合、お互いのプレイヤーはデッキの上からカードを７枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトニング・トライコーン",h:"らいとにんぐ・とらいこーん",e:"Lightning Tricorn",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"チューナー＋チューナー以外の獣族モンスター１体以上\nこのカードが相手によって破壊された場合、自分の墓地に存在する「サンダー・ユニコーン」または「ボルテック・バイコーン」１体を選択して自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ドラゴン",h:"すくらっぷ・どらごん",e:"Scrap Dragon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分及び相手フィールドのカードを１枚ずつ対象として発動できる。\nそのカードを破壊する。\n②：このカードが相手によって破壊され墓地へ送られた場合、Sモンスター以外の自分の墓地の「スクラップ」モンスター１体を対象として発動する。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキマイラ",h:"えれきまいら",e:"Wattchimera",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:1400,de:1200,tx:"「エレキ」と名のついたチューナー＋チューナー以外の雷族モンスター１体以上\nこのカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚デッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"死角からの一撃",h:"しかくからのいちげき",e:"Blind Spot Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側守備表示で存在するモンスター１体と、自分フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動する。\n選択した自分のモンスターの攻撃力はエンドフェイズ時まで、選択した相手モンスターの守備力の数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブル・サイクロン",h:"だぶる・さいくろん",e:"Double Cyclone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法・罠カード１枚と、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・エリア",h:"すくらっぷ・えりあ",e:"Scrapyard",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキから「スクラップ」と名のついたチューナー１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・スコール",h:"すくらっぷ・すこーる",e:"Scrapstorm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して発動する。\n自分のデッキから「スクラップ」と名のついたモンスター１体を墓地へ送り、カードを１枚ドローする。\nその後、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ポリッシュ",h:"すくらっぷ・ぽりっしゅ",e:"Scrap Sheen",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して破壊する。\n自分フィールド上に表側表示で存在する全ての「スクラップ」と名のついたモンスターの攻撃力は、このターンのエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキュア",h:"えれきゅあ",e:"Wattcine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する雷族モンスターが相手ライフに戦闘ダメージを与えた時、与えた戦闘ダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュルの森",h:"なちゅるのもり",e:"Naturia Forest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"相手がコントロールするカードの発動を無効にした場合、自分のデッキからレベル３以下の「ナチュル」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ランドオルスのヒカリゴケ",h:"らんどおるすのひかりごけ",e:"Landoise's Luminous Moss",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ナチュル」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\nこのターン、相手の効果モンスターは効果を発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの里",h:"あまぞねすのさと",e:"Amazoness Village",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「アマゾネス」モンスターの攻撃力は２００アップする。\n②：１ターンに１度、「アマゾネス」モンスターが戦闘・効果で破壊され墓地へ送られた時に発動できる。\n自分はその「アマゾネス」モンスターの元々のレベル以下のレベルを持つ「アマゾネス」モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの闘志",h:"あまぞねすのとうし",e:"Amazoness Fighting Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「アマゾネス」と名のついたモンスターが、その攻撃力よりも高い攻撃力を持つモンスターを攻撃する場合、ダメージ計算時のみ攻撃モンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニコーンの導き",h:"ゆにこーんのみちびき",e:"Unicorn Beacon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ゲームから除外されているレベル５以下の獣族または鳥獣族モンスター１体を選択して発動する。\n手札を１枚ゲームから除外し、選択したモンスターを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ビーストレイジ",h:"びーすとれいじ",e:"Beast Rage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全てのモンスターの攻撃力は、ゲームから除外されている自分の獣族及び鳥獣族モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラクルシンクロフュージョン",h:"みらくるしんくろふゅーじょん",e:"Miracle Synchro Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、融合モンスターカードによって決められた融合素材モンスターを除外し、Sモンスターを融合素材とするその融合モンスター１体をエクストラデッキから融合召喚する。\n②：セットされたこのカードが相手の効果で破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"疫病",h:"えきびょう",e:"Pestilence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族・獣戦士族・魔法使い族モンスターにのみ装備可能。\n装備モンスターの攻撃力は０になる。\nまた、自分のスタンバイフェイズ毎に、装備モンスターのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"災いの装備品",h:"わざわいのそうびひん",e:"Cursed Armaments",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は、自分フィールド上に存在するモンスターの数×６００ポイントダウンする。\nこのカードがフィールド上から墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択してこのカードを装備する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"賢者の聖杯",h:"けんじゃのせいはい",e:"Wiseman's Chalice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合、相手の墓地に存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを自分フィールド上に特殊召喚する。\nこのターンのエンドフェイズ時、この効果で特殊召喚したモンスターのコントロールを相手に移す。\nまた、この効果で特殊召喚したモンスターはリリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚の呪詛",h:"しょうかんのじゅそ",e:"Summoning Curse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは自分のエンドフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。\nこのカードがフィールド上に存在する場合にモンスターが特殊召喚に成功した時、そのモンスターのコントローラーは手札を１枚選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲で謙虚な壺",h:"ごうよくでけんきょなつぼ",e:"Pot of Duality",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はモンスターを特殊召喚できない。\n①：自分のデッキの上からカードを３枚めくり、その中から１枚を選んで手札に加え、その後残りのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"死力のタッグ・チェンジ",h:"しりょくのたっぐ・ちぇんじ",e:"Desperate Tag",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスターが戦闘によって破壊されるダメージ計算時、その戦闘によって発生する自分への戦闘ダメージを０にし、そのダメージステップ終了時に手札からレベル４以下の戦士族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"闘争本能",h:"とうそうほんのう",e:"Battle Instinct",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時、自分フィールド上にモンスターが存在しない場合に発動する事ができる。\n手札からレベル４以下の獣族モンスター１体を表側攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"野生の咆哮",h:"やせいのほうこう",e:"Howl of the Wild",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分フィールド上に表側表示で存在する獣族モンスター１体につき相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"パラレル・セレクト",h:"ぱられる・せれくと",e:"Parallel Selection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するシンクロモンスターが相手によって破壊され墓地へ送られた時、ゲームから除外されている自分の魔法カード１枚を選択して発動する。\n選択した魔法カードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"波動再生",h:"はどうさいせい",e:"Reanimation Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃宣言時、その攻撃モンスターのレベル以下のレベルを持つシンクロモンスター１体を自分の墓地から選択して発動する。\nその時の攻撃によって発生する自分への戦闘ダメージは半分になる。\nそのダメージステップ終了時、選択したシンクロモンスターを自分の墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"波動障壁",h:"はどうしょうへき",e:"Barrier Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時、自分フィールド上に存在するシンクロモンスター１体をリリースして発動する。\n相手フィールド上に攻撃表示で存在するモンスターを全て守備表示にする。\nその後、攻撃宣言をしたモンスターの守備力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖旋風",h:"れんさせんぷう",e:"Chain Whirlwind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"魔法・罠・効果モンスターの効果によってフィールド上に存在するカードが破壊された時、フィールド上に存在する魔法・罠カード２枚を選択して発動する事ができる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・カウンター",h:"すくらっぷ・かうんたー",e:"Scrap Rage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に守備表示で存在する「スクラップ」と名のついたモンスターが攻撃された場合、そのダメージ計算時に発動する事ができる。\n攻撃された「スクラップ」と名のついたモンスターの守備力は２０００ポイントアップし、バトルフェイズ終了時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキャノン",h:"えれきゃのん",e:"Wattcannon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上にレベル４以下の雷族モンスターが召喚・特殊召喚された時、相手ライフに６００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの意地",h:"あまぞねすのいじ",e:"Amazoness Willpower",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地から「アマゾネス」と名のついたモンスター１体を選択し、攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは表示形式を変更する事ができず、攻撃可能な場合には攻撃しなければならない。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターが破壊された時このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"女王の選択",h:"じょおうのせんたく",e:"Queen's Pawn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「アマゾネス」と名のついたモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時に発動する事ができる。\n自分のデッキからレベル４以下の「アマゾネス」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ビーストライザー",h:"びーすとらいざー",e:"Beast Rising",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの表側表示の獣族・獣戦士族モンスター１体を除外し、自分フィールドの獣族・獣戦士族モンスター１体を対象として発動できる。\nその自分の獣族・獣戦士族モンスターの攻撃力は、この効果を発動するために除外したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣の角",h:"げんじゅうのつの",e:"Horn of the Phantom Beast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力８００ポイントアップの装備カードとなり、自分フィールド上の獣族・獣戦士族モンスター１体に装備する。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"パラドックス・フュージョン",h:"ぱらどっくす・ふゅーじょん",e:"Paradox Fusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドの表側表示の融合モンスター１体を除外して以下の効果を発動できる。\n発動後２回目の自分エンドフェイズに、除外したそのモンスターは表側攻撃表示で自分フィールドに戻る。\n\n●魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n \n●自分または相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神の警告",h:"かみのけいこく",e:"Solemn Warning",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：２０００LPを払って以下の効果を発動できる。\n\n●モンスターを特殊召喚する効果を含む、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"反魔鏡",h:"はんまきょう",e:"Anti-Magic Prism",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が速攻魔法カードを発動した時に発動する事ができる。\nフィールド上に存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"威風堂々",h:"いふうどうどう",e:"Chivalry",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n相手が発動した効果モンスターの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライト・オブ・デストラクション",h:"らいと・おぶ・ですとらくしょん",e:"Light of Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手の効果によって相手のデッキからカードが墓地へ送られた時、相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"灰塵王 アッシュ・ガッシュ",h:"かいじんおう　あっしゅ・がっしゅ",e:"Gash the Dust Lord",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードが相手ライフに戦闘ダメージを与えた時、このカードのレベルを１つ上げる（最大レベル１２まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・フォアード",h:"じゃんく・ふぉあーど",e:"Junk Forward",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1500,tx:"自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スカー・ウォリアー",h:"すかー・うぉりあー",e:"Scarred Warrior",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、相手は表側表示で存在する他の戦士族モンスターを攻撃対象に選択する事はできない。\nまた、このカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティア・エッグ",h:"れぷてぃあ・えっぐ",e:"",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは戦闘では破壊されない。\n②：このカードが召喚に成功した場合、次の自分ターンのスタンバイフェイズに、このカードをリリースして発動できる。\n手札・デッキからレベル４以下の爬虫類族・岩石族モンスターを３体まで特殊召喚する（２体以上を特殊召喚する場合は全て同名モンスターでなければならない）。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"エレクトロ軍曹",h:"えれくとろぐんそう",e:"Sergeant Electro",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"①：１ターンに１度、相手の魔法＆罠ゾーンにセットされたカード１枚を対象として発動できる。\nこのカードは以下の効果を得る。\n\n●このカードがモンスターゾーンに存在する限り、対象のセットされたカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー ボガーナイト",h:"だぶるえっくす－せいばー　ぼがーないと",e:"XX-Saber Boggart Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードをS素材とする場合、「X－セイバー」モンスターのS召喚にしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「X－セイバー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"XX－セイバー ダークソウル",h:"だぶるえっくす－せいばー　だーくそうる",e:"XX-Saber Darksoul",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが自分フィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「X－セイバー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"セイバー・ヴォールト",h:"せいばー・う゛ぉーると",e:"Saber Vault",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「X－セイバー」と名のついたモンスターは、攻撃力がそのレベル×１００ポイントアップし、守備力がそのレベル×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・テストベッド",h:"こあきめいる・てすとべっど",e:"Koa'ki Meiru Prototype",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"フィールド上に表側表示で存在する「コアキメイル」と名のついたモンスターがエンドフェイズ時に破壊される場合、代わりにこのカードを破壊する事ができる。\nまた、フィールド上に表側表示で存在する「コアキメイル」と名のついたモンスターがエンドフェイズ時に破壊された時、自分フィールド上に「コアキメイルトークン」（岩石族・地・星４・攻／守１８００）１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバー・コアリミット",h:"おーばー・こありみっと",e:"Core Overclock",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する「コアキメイル」と名のついた全てのモンスターの攻撃力は５００ポイントアップする。\nまた、１ターンに１度、自分のメインフェイズ時に手札から「コアキメイルの鋼核」１枚を捨てる事で、自分フィールド上に表側表示で存在する「コアキメイル」と名のついた全てのモンスターの攻撃力は、エンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"虚無の波動",h:"きょむのはどう",e:"Wave-Motion Inferno",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の手札が０枚の場合、自分フィールド上に表側表示で存在する「インフェルニティ」と名のついた全てのモンスターの攻撃力・守備力は４００ポイントアップする。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、自分の手札を全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・バリア",h:"いんふぇるにてぃ・ばりあ",e:"Infernity Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「インフェルニティ」と名のついたモンスターが表側攻撃表示で存在し、自分の手札が０枚の場合に発動する事ができる。\n相手が発動した効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"除草獣",h:"じょそうじゅう",e:"Snyffus",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度、自分フィールド上に存在する植物族モンスター１体をリリースする事で、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。\nまた、このカードが墓地に存在し、フィールド上に存在する植物族モンスターが破壊された時、このカードを墓地から特殊召喚する事ができる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"素早いマンボウ",h:"すばやいまんぼう",e:"Nimble Sunfish",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから魚族モンスター１体を墓地へ送る。\nその後、自分のデッキから「素早いマンボウ」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ピューマン",h:"ぴゅーまん",e:"Akz, the Pumer",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する獣戦士族モンスター２体をゲームから除外した場合に特殊召喚する事ができる。\n１ターンに１度、手札から獣戦士族モンスター１体を捨て、以下の効果から１つを選択して発動する事ができる。\n●このカードの攻撃力はエンドフェイズ時まで倍になる。\n●このターンこのカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"天刑王 ブラック・ハイランダー",h:"てんけいおう　ぶらっく・はいらんだー",e:"Dark Highlander",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2300,tx:"悪魔族チューナー＋チューナー以外の悪魔族モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、お互いにシンクロ召喚をする事ができない。\n１ターンに１度、装備カードを装備した相手モンスター１体を選択して発動する事ができる。\n選択したモンスターに装備された装備カードを全て破壊し、破壊した数×４００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin サイバー・エンド・ドラゴン",h:"しん さいばー・えんど・どらごん",e:"Malefic Cyber End Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:2800,tx:"このカードは通常召喚できない。\nEXデッキから「サイバー・エンド・ドラゴン」１体を除外した場合のみ特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin トゥルース・ドラゴン",h:"しん とぅるーす・どらごん",e:"Malefic Truth Dragon",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:5000,de:5000,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：「Sin トゥルース・ドラゴン」以外の自分フィールドの表側表示の「Sin」モンスターが戦闘・効果で破壊された場合、LPを半分払って発動できる。\nこのカードを手札・墓地から特殊召喚する。\n②：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。\n④：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin パラレルギア",h:"しん ぱられるぎあ",e:"Malefic Parallel Gear",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをS素材とする場合、他のS素材モンスターは手札の「Sin」モンスター１体でなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin レインボー・ドラゴン",h:"しん れいんぼー・どらごん",e:"Malefic Rainbow Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n手札・デッキから「究極宝玉神 レインボー・ドラゴン」１体を除外した場合のみ特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・ガードナー",h:"じゃんく・がーどなー",e:"Junk Gardna",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1400,de:2600,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n１ターンに１度、相手フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。\nこの効果は相手ターンでも発動する事ができる。\nまた、このカードがフィールド上から墓地へ送られた場合、フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティアームズ－レヴァテイン",h:"どらぐにてぃあーむず－れう゛ぁていん",e:"Dragunity Arma Leyvaten",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:1200,tx:"①：このカードは「ドラグニティ」カードを装備した自分フィールドのモンスター１体を除外し、手札・墓地から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した時、「ドラグニティアームズ－レヴァテイン」以外の自分の墓地のドラゴン族モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n③：このカードが相手の効果で墓地へ送られた時、このカードに装備されていた自分・相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティアームズ－ミスティル",h:"どらぐにてぃあーむず－みすてぃる",e:"Dragunity Arma Mystletainn",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2100,de:1500,tx:"①：このカードは自分フィールドの表側表示の「ドラグニティ」モンスター１体を墓地へ送り、手札から特殊召喚できる。\n②：このカードが手札からの召喚・特殊召喚に成功した時、自分の墓地のドラゴン族の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－アキュリス",h:"どらぐにてぃ－あきゅりす",e:"Dragunity Aklys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:800,tx:"①：このカードが召喚に成功した時に発動できる。\n手札から「ドラグニティ」モンスター１体を特殊召喚し、その後、自分フィールドの表側表示のこのカードを装備カード扱いとしてその特殊召喚したモンスターに装備する。\n②：モンスターに装備されているこのカードが墓地へ送られた場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の渓谷",h:"りゅうのけいこく",e:"Dragon Ravine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札を１枚捨て、以下の効果から１つを選択して発動できる。\n\n●デッキからレベル４以下の「ドラグニティ」モンスター１体を手札に加える。\n\n●デッキからドラゴン族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"竜操術",h:"りゅうそうじゅつ",e:"Dragon Mastery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「ドラグニティ」モンスターカードを装備した、自分フィールドのモンスターの攻撃力は５００アップする。\n②：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\n手札からドラゴン族の「ドラグニティ」モンスター１体を装備カード扱いとして対象の自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"奇術王 ムーン・スター",h:"きじゅつおう　むーん・すたー",e:"Magical King Moonstar",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:900,de:600,tx:"このカードをS素材とする場合、闇属性モンスターのS召喚にしか使用できない。\n①：自分フィールドにチューナーが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合、自分のフィールド・墓地のモンスター１体を対象として発動できる。\nこのカードのレベルはターン終了時までそのモンスターと同じになる。\nこの効果の発動後、ターン終了時まで自分はS召喚以外の特殊召喚ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO ミニマム・レイ",h:"う゛ぃじょんひーろー　みにまむ・れい",e:"Vision HERO Minimum Ray",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\n相手フィールドのレベル４以下のモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影の魔術士",h:"げんえいのまじゅつし",e:"Phantom Magician",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:700,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力１０００以下の「HERO」と名のついたモンスター１体を表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO マルティプリ・ガイ",h:"う゛ぃじょんひーろー　まるてぃぷり・がい",e:"Vision HERO Multiply Guy",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:700,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\nフィールドのモンスター１体を選び、その攻撃力を８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ローズ・ウィッチ",h:"ろーず・うぃっち",e:"Rose Witch",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"植物族モンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"凛天使クイーン・オブ・ローズ",h:"りんてんしくいーん・おぶ・ろーず",e:"Queen Angel of Roses",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1300,tx:"このカードは植物族モンスター１体をリリースして表側攻撃表示でアドバンス召喚する事ができる。\n自分のスタンバイフェイズ時に１度だけ、フィールド上に表側表示で存在する攻撃力が一番低いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO インクリース",h:"う゛ぃじょんひーろー　いんくりーす",e:"Vision HERO Increase",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1100,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\nデッキからレベル４以下の「V・HERO」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マスク・チェンジ",h:"ますく・ちぇんじ",e:"Mask Change",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「HERO」モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、そのモンスターと同じ属性の「M・HERO」モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO 剛火",h:"ますくどひーろー　ごうか",e:"Masked HERO Goka",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\nこのカードの攻撃力は、自分の墓地の「HERO」と名のついたモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーム・チェンジ",h:"ふぉーむ・ちぇんじ",e:"Form Change",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「HERO」融合モンスター１体を対象として発動できる。\nそのモンスターをエクストラデッキへ戻し、そのモンスターの元々のレベルと同じレベルでカード名が異なる「M・HERO」モンスター１体を、「マスク・チェンジ」による特殊召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO ヴェイパー",h:"ますくどひーろー　う゛ぇいぱー",e:"Masked HERO Vapor",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\nこのカードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影融合",h:"う゛ぃじょんゆうごう",e:"Vision Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、「HERO」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n自分の魔法＆罠ゾーンに融合素材モンスターが永続罠カード扱いで存在する場合、そのモンスターカードを除外して融合素材とする事もできる（最大２枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO ポイズナー",h:"う゛ぃじょんひーろー　ぽいずなー",e:"Vision HERO Poisoner",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:700,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\nフィールドのモンスター１体を選び、その攻撃力を半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO ウィッチ・レイド",h:"う゛ぃじょんひーろー　うぃっち・れいど",e:"Vision HERO Witch Raider",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:1900,tx:"このカードを表側表示でアドバンス召喚する場合、モンスターの代わりに自分フィールドの罠カードをリリースできる。\n①：このカードが召喚に成功した時に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。\nこの効果を発動するターン、自分は「HERO」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影解放",h:"う゛ぃじょんかいほう",e:"Vision Release",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンの「V・HERO」モンスターカード１枚を対象として発動できる。\nそのカードを特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「V・HERO」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO アドレイション",h:"う゛ぃじょんひーろー　あどれいしょん",e:"Vision HERO Adoration",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2100,tx:"「HERO」モンスター×２\n①：１ターンに１度、相手フィールドの表側表示モンスター１体と、このカード以外の自分フィールドの「HERO」モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力はターン終了時まで、その自分のモンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"速攻のかかし",h:"そっこうのかかし",e:"Swift Scarecrow",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：相手モンスターの直接攻撃宣言時にこのカードを手札から捨てて発動できる。\nその攻撃を無効にし、その後バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラー・レディバグ",h:"みらー・れでぃばぐ",e:"Mirror Ladybug",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールド上に表側表示でモンスターが１体以上存在し、自分の墓地にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。\nこの効果で特殊召喚に成功した時、このカードのレベルはこのカード以外の自分フィールド上に存在するモンスターのレベルを合計したレベルになる。\nまた、フィールド上に表側表示で存在するこのカードのレベルが１２を超えた場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"リード・バタフライ",h:"りーど・ばたふらい",e:"Reed Butterfly",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"相手フィールド上にシンクロモンスターが表側表示で存在し、自分フィールド上にシンクロモンスターが表側表示で存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ニードル・ガンナー",h:"にーどる・がんなー",e:"Needle Soldier",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードをシンクロ素材としたシンクロモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロ・リンカー",h:"ねくろ・りんかー",e:"Necro Linker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"このカードをリリースし、自分の墓地に存在する「シンクロン」と名のついたチューナー１体を選択して発動する。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンシンクロ素材とする事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキュー・ウォリアー",h:"れすきゅー・うぉりあー",e:"Rescue Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードが戦闘によって破壊された場合、相手フィールド上に表側表示で存在する元々の持ち主が自分となるモンスター１体を選択してコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・ジャイアント",h:"ぱわー・じゃいあんと",e:"Power Giant",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは手札のレベル４以下のモンスター１体を墓地へ送り、手札から特殊召喚する事ができる。\nこの方法で特殊召喚した場合、手札から墓地へ送ったモンスターのレベルの数だけこのカードのレベルを下げる。\nまた、このカードが戦闘を行う場合、そのダメージステップ終了時まで自分が受ける効果ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"バイス・バーサーカー",h:"ばいす・ばーさーかー",e:"Vice Berserker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、そのプレイヤーに２０００ポイントダメージを与える。\nまた、このカードをシンクロ素材としたシンクロモンスターの攻撃力は、このターンのエンドフェイズ時まで２０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ランサー・デーモン",h:"らんさー・でーもん",e:"Lancer Archfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"相手フィールド上に守備表示で存在するモンスターを攻撃対象とした自分のモンスターの攻撃宣言時に発動する事ができる。\nそのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"パワー・ブレイカー",h:"ぱわー・ぶれいかー",e:"Power Breaker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードが相手モンスターの攻撃、または相手の効果によって破壊され墓地へ送られた時、相手フィールド上に表側表示で存在する魔法・罠カード１枚を選択して破壊する。\nこのカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクストラ・ヴェーラー",h:"えくすとら・う゛ぇーらー",e:"Extra Veiler",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"相手がモンスターを特殊召喚した時、手札からこのカードを特殊召喚する事ができる。\nこの効果で特殊召喚したターン、相手のカードの効果によって発生する自分への効果ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・ガンナー",h:"しんくろ・がんなー",e:"Synchro Soldier",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分のメインフェイズ１でのみ発動する事ができる。\n自分フィールド上に表側表示で存在するシンクロモンスター１体をゲームから除外して相手ライフに６００ポイントダメージを与える。\nこの効果で除外したモンスターは、次の自分のスタンバイフェイズ時にフィールド上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"クリエイト・リゾネーター",h:"くりえいと・りぞねーたー",e:"Creation Resonator",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"自分フィールド上にレベル８以上のシンクロモンスターが表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アタック・ゲイナー",h:"あたっく・げいなー",e:"Attack Gainer",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、相手フィールド上に表側表示で存在するモンスター１体の攻撃力はエンドフェイズ時まで１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－二の太刀のエテジア",h:"ぶらっくふぇざー－にのたちのえてじあ",e:"Blackwing - Etesian of Two Swords",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:400,de:1600,tx:"自分フィールド上に存在する「BF」と名のついたモンスターが相手モンスターとの戦闘を行ったダメージステップ終了時にその相手モンスターがフィールド上に存在する場合、このカードを手札から墓地へ送って発動する事ができる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－極光のアウロラ",h:"ぶらっくふぇざー－きょっこうのあうろら",e:"Blackwing - Aurora the Northern Lights",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\n自分フィールド上に表側表示で存在する「BF」と名のついたチューナー１体と、チューナー以外のモンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\n１ターンに１度、自分のエクストラデッキから「BF」と名のついたシンクロモンスター１体をゲームから除外し、エンドフェイズ時までそのモンスターと同名カードとして扱い、同じ攻撃力と効果を得る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－激震のアブロオロス",h:"ぶらっくふぇざー－げきしんのあぶろおろす",e:"Blackwing - Abrolhos the Megaquake",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"このカードは特殊召喚できない。\n１ターンに１度、このカードの攻撃力を１０００ポイントダウンし、相手の魔法＆罠カードゾーンに存在するカードを全て持ち主の手札に戻す事ができる。\nこの効果はメインフェイズ１にしか使用できない。\nこのカードと戦闘を行ったモンスターは、その戦闘で破壊されずダメージ計算後に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"グローアップ・バルブ",h:"ぐろーあっぷ・ばるぶ",e:"Glow-Up Bulb",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが墓地に存在する場合に発動できる。\n自分のデッキの一番上のカードを墓地へ送り、このカードを特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"カラクリ兵 弐参六",h:"からくりへい　にさむ",e:"Karakuri Soldier mdl 236 \"Nisamu\"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからレベル４以下の「カラクリ」と名のついたモンスター１体を表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ商人 壱七七",h:"からくりしょうにん　いなしち",e:"Karakuri Merchant mdl 177 \"Inashichi\"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードが召喚に成功した時、自分のデッキから「カラクリ」と名のついたカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ参謀 弐四八",h:"からくりさんぼう　にしぱち",e:"Karakuri Strategist mdl 248 \"Nishipachi\"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1600,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードが召喚・特殊召喚に成功した時、フィールド上に存在するモンスター１体の表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ忍者 参参九",h:"からくりにんじゃ　さざんく",e:"Karakuri Ninja mdl 339 \"Sazank\"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードがリバースした時、フィールド上に表側表示で存在するモンスター１体を選択して墓地へ送る。\nまた、このカードがリバースしたターン、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ武者 六参壱八",h:"からくりむしゃ　むざんいちは",e:"Karakuri Bushi mdl 6318 \"Muzanichiha\"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nフィールド上に存在する「カラクリ」と名のついたモンスターが破壊された場合、このカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ソルジャー",h:"すくらっぷ・そるじゃー",e:"Scrap Soldier",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:2100,de:700,tx:"フィールド上に表側守備表示で存在するこのカードが攻撃対象に選択された場合、バトルフェイズ終了時にこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ソルジャー」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。\nこのカードをシンクロ素材とする場合、「スクラップ」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・サーチャー",h:"すくらっぷ・さーちゃー",e:"Scrap Searcher",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードが墓地に存在し、自分フィールド上に存在する「スクラップ・サーチャー」以外の「スクラップ」と名のついたモンスターが破壊され墓地へ送られた時、このカードを墓地から特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、「スクラップ」と名のついたモンスター以外の自分フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキーウィ",h:"えれきーうぃ",e:"Wattkiwi",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に存在する「エレキ」と名のついたモンスターが攻撃する場合、攻撃モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキリギリス",h:"えれきりぎりす",e:"Watthopper",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、相手は表側表示で存在する他の「エレキ」と名のついたモンスターを攻撃対象に選択できず、カードの効果の対象にする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキトンボ",h:"えれきとんぼ",e:"Wattdragonfly",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:100,tx:"このカードが相手によって破壊された場合、自分のデッキから「エレキ」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキタリス",h:"えれきたりす",e:"Wattsquirrel",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:700,de:100,tx:"このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードと戦闘を行った効果モンスターの効果をダメージ計算後に無効化する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・チェリー",h:"なちゅる・ちぇりー",e:"Naturia Cherries",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが相手によってフィールド上から墓地へ送られた場合、自分のデッキから「ナチュル・チェリー」を２体まで裏側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・パンプキン",h:"なちゅる・ぱんぷきん",e:"Naturia Pumpkin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"相手フィールド上にモンスターが存在する場合にこのカードが召喚に成功した時、手札から「ナチュル」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・スタッグ",h:"なちゅる・すたっぐ",e:"Naturia Stag Beetle",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"このカードが攻撃を行うバトルステップ時及びダメージステップ時に相手が魔法・罠・効果モンスターの効果を発動した時、自分の墓地に存在する「ナチュル」と名のついたモンスター１体を選択して発動する事ができる。\n選択したモンスターを墓地から特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の舞姫",h:"ひょうけっかいのまいひめ",e:"Dance Princess of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"①：１ターンに１度、自分フィールドに他の「氷結界」モンスターが存在する場合、手札の「氷結界」モンスターを任意の数だけ相手に見せ、その数だけ相手フィールドにセットされた魔法・罠カードを対象として発動できる。\nセットされたそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"チェーンドッグ",h:"ちぇーんどっぐ",e:"Chain Dog",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1100,tx:"自分フィールド上に獣族モンスターが表側表示で２体存在する場合、このカードを墓地から特殊召喚する事ができる。\nこの効果で特殊召喚したこのカードはフィールド上から離れた場合、ゲームから除外される。\nこのカードをシンクロ素材とする場合、獣族モンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイトメア",h:"わいとめあ",e:"Wightmare",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードのカード名は、墓地に存在する限り「ワイト」として扱う。\nまた、このカードを手札から捨てて以下の効果から１つを選択して発動する事ができる。\n●ゲームから除外されている自分の「ワイト」または「ワイトメア」１体を選択して自分の墓地に戻す。\n●ゲームから除外されている自分の「ワイト夫人」または「ワイトキング」１体を選択してフィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破戒僧 ランシン",h:"はかいそう　らんしん",e:"Anarchist Monk Ranshin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"このカードが相手によってフィールド上から墓地へ送られた時、相手の墓地に存在するモンスター１体を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇帝ディルグ",h:"あんていでぃるぐ",e:"Delg the Dark Monarch",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードが召喚・特殊召喚に成功した時、相手の墓地に存在するカードを２枚までゲームから除外する事ができる。\nさらに、除外した数だけ相手のデッキの上からカードを墓地へ送る。\nこのカードは召喚・特殊召喚したターンには攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"覇魔導士アーカナイト・マジシャン",h:"はまどうしあーかないと・まじしゃん",e:"Supreme Arcanite Magician",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:2800,tx:"魔法使い族Sモンスター＋魔法使い族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードが融合召喚に成功した場合に発動する。\nこのカードに魔力カウンターを２つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×１０００アップする。\n③：１ターンに１度、自分フィールドの魔力カウンターを１つ取り除き、以下の効果から１つを選択して発動できる。\n\n●フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n\n●自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"地天の騎士ガイアドレイク",h:"ちてんのきしがいあどれいく",e:"Gaia Drake, the Universal Force",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:2800,tx:"「大地の騎士ガイアナイト」＋効果モンスター以外のシンクロモンスター\nこのカードは効果モンスターの効果の対象にならず、効果モンスターの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"シューティング・スター・ドラゴン",h:"しゅーてぃんぐ・すたー・どらごん",e:"Shooting Star Dragon",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:2500,tx:"Sモンスターのチューナー＋「スターダスト・ドラゴン」\n①：１ターンに１度、発動できる。\n自分のデッキの上から５枚めくってデッキに戻す。\nこのターンこのカードはめくった中のチューナーの数まで攻撃できる。\n②：１ターンに１度、フィールドのカードを破壊する効果の発動時に発動できる。\nその効果を無効にし破壊する。\n③：１ターンに１度、相手の攻撃宣言時に攻撃モンスターを対象として発動できる。\nフィールドのこのカードを除外し、その攻撃を無効にする。\n④：この③の効果で除外されたターンのエンドフェイズに発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーミュラ・シンクロン",h:"ふぉーみゅら・しんくろん",e:"Formula Synchron",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:200,de:1500,tx:"チューナー＋チューナー以外のモンスター１体\n①：このカードがS召喚に成功した時に発動できる。\n自分はデッキから１枚ドローする。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スカーレッド・ノヴァ・ドラゴン",h:"すかーれっど・のう゛ぁ・どらごん",e:"Red Nova Dragon",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:3000,tx:"チューナー２体＋「レッド・デーモンズ・ドラゴン」\n①：このカードの攻撃力は自分の墓地のチューナーの数×５００アップする。\n②：フィールドのこのカードは相手の効果では破壊されない。\n③：相手モンスターの攻撃宣言時にその攻撃モンスター１体を対象として発動できる。\nフィールドのこのカードを除外し、その攻撃を無効にする。\n④：このカードの③の効果で除外されたターンのエンドフェイズに発動する。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ将軍 無零",h:"からくりしょうぐん　ぶれい",e:"Karakuri Shogun mdl 00 \"Burei\"",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1900,tx:"チューナー＋チューナー以外の機械族モンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分のデッキから「カラクリ」と名のついたモンスター１体を特殊召喚する事ができる。\n１ターンに１度、フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ツイン・ドラゴン",h:"すくらっぷ・ついん・どらごん",e:"Scrap Twin Dragon",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2200,tx:"「スクラップ」と名のついたチューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分フィールド上に存在するカード１枚と、相手フィールド上に存在するカード２枚を選択して発動する事ができる。\n選択した自分のカードを破壊し、選択した相手のカードを手札に戻す。\nこのカードが相手によって破壊され墓地へ送られた時、シンクロモンスター以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"調律",h:"ちょうりつ",e:"Tuning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「シンクロン」チューナー１体を手札に加えてデッキをシャッフルする。\nその後、自分のデッキの一番上のカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"風雲カラクリ城",h:"ふううんからくりじょう",e:"Karakuri Showdown Castle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「カラクリ」と名のついたモンスターが、相手フィールド上に表側表示で存在するモンスターを攻撃対象に選択した時、その相手モンスター１体の表示形式を変更する事ができる。\nまた、フィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた時、自分の墓地に存在するレベル４以上の「カラクリ」と名のついたモンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金の歯車装置箱",h:"おうごんのはぐるまそうちばこ",e:"Golden Gearbox",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「カラクリ」と名のついたモンスター１体を選択して発動する。\nエンドフェイズ時まで、選択したモンスターの攻撃力は５００ポイントアップし、守備力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ解体新書",h:"からくりかいたいしんしょ",e:"Karakuri Anatomy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「カラクリ」と名のついたモンスターの表示形式が変更される度に、このカードにカラクリカウンターを１つ置く（最大２つまで）。\nまた、フィールド上に存在するこのカードを墓地へ送る事で、このカードに乗っているカラクリカウンターの数だけ自分のデッキからカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・オイルゾーン",h:"すくらっぷ・おいるぞーん",e:"Scrap Lube",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターがフィールド上から離れた時このカードを破壊する。\nこのカードを発動するターン、自分はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキャッスル",h:"えれきゃっする",e:"Wattcastle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、「エレキ」と名のついたモンスターを攻撃したモンスターの攻撃力は、そのダメージ計算後に１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキャッシュ",h:"えれきゃっしゅ",e:"Wattjustment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル３以下の雷族モンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップし、効果は無効化される。\n装備モンスターが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"パルキオンのうろこ",h:"ぱるきおんのうろこ",e:"Barkion's Bark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ナチュル」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\nこのターン、相手は罠カードを発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガオドレイクのタテガミ",h:"がおどれいくのたてがみ",e:"Leodrake's Mane",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ナチュル」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力はエンドフェイズ時まで３０００になり、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の紋章",h:"ひょうけっかいのもんしょう",e:"Medallion of the Ice Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「氷結界」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の鏡",h:"ひょうけっかいのかがみ",e:"Mirror of the Ice Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターン相手が発動したモンスターの効果で、自分の手札・フィールド・墓地のカードが除外される度に、以下の効果を適用する。\n\n●手札：相手の手札をランダムに２枚まで選んで除外する。\n\n●フィールド：相手フィールドのカードを２枚まで選んで除外する。\n\n●墓地：相手の墓地のカードを２枚まで選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキリング",h:"こあきりんぐ",e:"Koa'ki Ring",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の「コアキメイルの鋼核」１枚を相手に見せて発動する。\nフィールド上に表側表示で存在するモンスター１体を破壊し、お互いに１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界の足枷",h:"まかいのあしかせ",e:"Darkworld Shackles",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは攻撃する事ができず、攻撃力・守備力は１００になる。\nまた、自分のスタンバイフェイズ毎に、装備モンスターのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"愚鈍の斧",h:"ぐどんのおの",e:"Axe of Fools",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は１０００アップし、効果は無効化される。\n②：自分スタンバイフェイズに発動する。\n装備モンスターのコントローラーに５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"呪いのお札",h:"のろいのおふだ",e:"Cursed Bill",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが破壊される事によってこのカードが墓地へ送られた時、墓地へ送られた装備モンスターの元々の守備力分のダメージを装備モンスターのコントローラーのライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"霊滅独鈷杵",h:"れいめつどっこしょ",e:"Tokkosho of Ghost Destroying",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが相手ライフに戦闘ダメージを与えた時、相手の墓地に存在するモンスターを２体まで選択してゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"馬の骨の対価",h:"うまのほねのたいか",e:"White Elephant's Gift",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：効果モンスター以外の自分フィールドの表側表示モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"大熱波",h:"だいねっぱ",e:"Heat Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分メインフェイズ１開始時に発動できる。\n次の自分ドローフェイズまで、お互いに効果モンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"D２シールド",h:"でぃーつーしーるど",e:"D2 Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側守備表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの守備力は、元々の守備力を倍にした数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクリーン・オブ・レッド",h:"すくりーん・おぶ・れっど",e:"Red Screen",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは自分エンドフェイズ毎に１０００LPを払う。\n１０００LP払えない場合このカードを破壊する。\n①：このカードが魔法＆罠ゾーンに存在する限り、相手モンスターは攻撃宣言できない。\n②：自分の墓地のレベル１チューナー１体を対象として発動できる。\nこのカードを破壊し、そのモンスターを特殊召喚する。\nこの効果はフィールドに「レッド・デーモンズ・ドラゴン」が存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・バック",h:"ぶらっく・ばっく",e:"Blackback",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のターンのみ発動する事ができる。\n自分の墓地に存在する攻撃力２０００以下の「BF」と名のついたモンスター１体を選択して特殊召喚する。\nこのカードを発動するターン、自分はモンスターを通常召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ディフェンダーズ・クロス",h:"でぃふぇんだーず・くろす",e:"Defenders Intersect",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n相手フィールド上に守備表示で存在するモンスターは表側攻撃表示になり、そのモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"重力崩壊",h:"じゅうりょくほうかい",e:"Gravity Collapse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手がモンスターを召喚・反転召喚・特殊召喚する際に、自分フィールドの表側表示のSモンスター１体を墓地へ送って発動できる。\nそれを無効にし、そのモンスターを破壊する。\nこのターン、相手はモンスターを召喚・反転召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－マイン",h:"ぶらっくふぇざー－まいん",e:"Blackwing - Boobytrap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが相手の効果によって破壊された時、自分フィールド上に「BF」と名のついたモンスターが表側表示で存在する場合、相手ライフに１０００ポイントダメージを与え、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"星蝕－レベル・クライム－",h:"せいしょく－れべる・くらいむ－",e:"Star Siphon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"シンクロモンスターが特殊召喚された時、そのモンスター１体を選択して発動する。\n自分フィールド上に「星蝕トークン」（魔法使い族・闇・星１・攻／守０）１体を特殊召喚する。\nこのトークンのレベルは、選択したモンスターと同じレベルになり、選択したモンスターのレベルは１になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーフ・カウンター",h:"はーふ・かうんたー",e:"Half Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが攻撃された場合、そのダメージ計算時に発動する事ができる。\n攻撃対象となった自分のモンスター１体の攻撃力は、エンドフェイズ時まで攻撃モンスターの元々の攻撃力の半分の数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ屋敷",h:"からくりやしき",e:"Karakuri Trick House",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「カラクリ」と名のついたモンスターの表示形式が変更された時に発動する事ができる。\nフィールド上に存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"時限カラクリ爆弾",h:"じげんからくりばくだん",e:"Karakuri Klock",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"表側守備表示で存在する「カラクリ」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n相手フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・クラッシュ",h:"すくらっぷ・くらっしゅ",e:"Scrap Crash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「スクラップ」と名のついたモンスターが破壊され墓地へ送られた時に発動する事ができる。\nフィールド上に表側表示で存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキーパー",h:"えれきーぱー",e:"Wattkeeper",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在するレベル４以下の「エレキ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"エクストリオの牙",h:"えくすとりおのきば",e:"Exterio's Fang",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「ナチュル」と名のついたモンスターが表側表示で存在する場合に発動する事ができる。\n相手が発動した魔法・罠カードの発動を無効にし破壊する。\nその後、自分の手札を１枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"虚無空間",h:"う゛ぁにてぃー・すぺーす",e:"Vanity's Emptiness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いにモンスターを特殊召喚できない。\n②：デッキまたはフィールドから自分の墓地へカードが送られた場合に発動する。\nこのカードを破壊する。",li:1,lk:[],ta:[],al:[]},
  {n:"異次元グランド",h:"いじげんぐらんど",e:"Different Dimension Ground",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、墓地へ送られるモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"能力吸収石",h:"のうりょくきゅうしゅうせき",e:"Powersink Stone",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"モンスターの効果が発動する度に、このカードに魔石カウンターを１つ置く（最大２つまで）。\nこのカードに魔石カウンターが２つ乗っている場合、フィールド上に表側表示で存在するモンスターは、効果を発動する事ができず無効化される。\nエンドフェイズ時、このカードに乗っている魔石カウンターを全て取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"暴君の威圧",h:"ぼうくんのいあつ",e:"Tyrant's Temper",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体をリリースして発動する。\nこのカードがフィールド上に存在する限り、フィールド上に表側表示で存在する元々の持ち主が自分となるモンスターは、このカード以外の罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"落とし大穴",h:"おとしおおあな",e:"Darkfall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が表側表示で２体以上のモンスターの特殊召喚に成功した時に発動する事ができる。\nそのモンスターを全て墓地へ送る。\nさらにそのモンスターと同名モンスターを相手の手札・デッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・アビス",h:"りちゅあ・あびす",e:"Gishki Abyss",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、デッキから「リチュア・アビス」以外の守備力１０００以下の「リチュア」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・ヴァニティ",h:"りちゅあ・う゛ぁにてぃ",e:"Gishki Vanity",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"自分のメインフェイズ時に、このカードを手札から捨てて発動できる。\nこのターン、相手は「リチュア」と名のついた儀式魔法カードの発動に対して魔法・罠・効果モンスターの効果を発動できず、「リチュア」と名のついた儀式モンスターが儀式召喚に成功した時、相手は魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・マーカー",h:"りちゅあ・まーかー",e:"Gishki Marker",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、「リチュア」と名のついた自分の墓地の儀式モンスターまたは儀式魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・チェイン",h:"りちゅあ・ちぇいん",e:"Gishki Chain",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが召喚に成功した時、デッキの上からカードを３枚確認する。\n確認したカードの中に儀式モンスターまたは儀式魔法カードがあった場合、その１枚を相手に見せて手札に加える事ができる。\nその後、確認したカードを好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・エリアル",h:"りちゅあ・えりある",e:"Gishki Ariel",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1800,tx:"リバース：デッキから「リチュア」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドウ・リチュア",h:"しゃどう・りちゅあ",e:"Gishki Shadow",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"水属性の儀式モンスターを特殊召喚する場合、このカード１枚で儀式召喚のためのリリースとして使用できる。\nまた、手札からこのカードを捨てて発動できる。\nデッキから「リチュア」と名のついた儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・ガルド",h:"がすた・がるど",e:"Gusto Gulldo",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"このカードがフィールド上から墓地へ送られた時、デッキからレベル２以下の「ガスタ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・イグル",h:"がすた・いぐる",e:"Gusto Egul",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからチューナー以外のレベル４以下の「ガスタ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・サンボルト",h:"がすた・さんぼると",e:"Gusto Thunbolt",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた場合、そのバトルフェイズ終了時に自分の墓地の「ガスタ」と名のついたモンスター１体をゲームから除外して発動できる。\nデッキから守備力１５００以下のサイキック族・風属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの巫女 ウィンダ",h:"がすたのみこ　うぃんだ",e:"Winda, Priestess of Gusto",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:400,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、デッキから「ガスタ」と名のついたチューナー１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの静寂 カーム",h:"がすたのせいじゃく　かーむ",e:"Caam, Serenity of Gusto",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"１ターンに１度、自分の墓地の「ガスタ」と名のついたモンスター２体をデッキに戻して発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの賢者 ウィンダール",h:"がすたのけんじゃ　うぃんだーる",e:"Windaar, Sage of Gusto",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、自分の墓地からレベル３以下の「ガスタ」と名のついたモンスター１体を選択し、表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズの魔細胞",h:"いんう゛ぇるずのまさいぼう",e:"Steelswarm Cell",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこのカードは「インヴェルズ」と名のついたモンスターのアドバンス召喚以外のためにはリリースできず、シンクロ素材にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズの斥候",h:"いんう゛ぇるずのせっこう",e:"Steelswarm Scout",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:0,tx:"自分フィールド上に魔法・罠カードが存在しない場合、自分のメインフェイズ１の開始時にのみ発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果を発動するターン、自分はモンスターを特殊召喚できない。\nこのカードは「インヴェルズ」と名のついたモンスターのアドバンス召喚以外のためにはリリースできず、シンクロ素材にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズの門番",h:"いんう゛ぇるずのもんばん",e:"Steelswarm Gatekeeper",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、「インヴェルズ」と名のついたモンスターが表側表示でアドバンス召喚に成功したターン、自分は通常召喚に加えて１度だけモンスターを通常召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズを呼ぶ者",h:"いんう゛ぇるずをよぶもの",e:"Steelswarm Caller",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードをリリースして「インヴェルズ」と名のついたモンスターのアドバンス召喚に成功した時、デッキからレベル４以下の「インヴェルズ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ・マディス",h:"いんう゛ぇるず・までぃす",e:"Steelswarm Mantis",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"「インヴェルズ」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、１０００ライフポイントを払う事で、自分の墓地の「インヴェルズ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ・モース",h:"いんう゛ぇるず・もーす",e:"Steelswarm Moth",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"「インヴェルズ」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、１０００ライフポイントを払う事で、相手フィールド上のカードを２枚まで選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ・ギラファ",h:"いんう゛ぇるず・ぎらふぁ",e:"Steelswarm Girastag",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:0,tx:"このカードは「インヴェルズ」と名のついたモンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\n「インヴェルズ」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、相手フィールド上のカード１枚を選択して発動できる。\n選択した相手のカードを墓地へ送り、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ・ガザス",h:"いんう゛ぇるず・がざす",e:"Steelswarm Caucastag",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:0,tx:"「インヴェルズ」と名のついたモンスター２体をリリースしてこのカードのアドバンス召喚に成功した時、以下の効果から１つを選択して発動する。\n●このカード以外のフィールド上のモンスターを全て破壊する。\n●フィールド上の魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・マインドオーガス",h:"いびりちゅあ・まいんどおーがす",e:"Evigishki Mind Augus",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\nこのカードが儀式召喚に成功した時、お互いの墓地のカードを合計５枚まで選択して持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・ソウルオーガ",h:"いびりちゅあ・そうるおーが",e:"Evigishki Soul Ogre",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2800,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、手札から「リチュア」と名のついたモンスター１体を捨てて発動できる。\n相手フィールド上に表側表示で存在するカード１枚を選択して持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイガスタ・ガルドス",h:"だいがすた・がるどす",e:"Daigusto Gulldos",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:800,tx:"チューナー＋チューナー以外の「ガスタ」と名のついたモンスター１体以上\n１ターンに１度、自分の墓地の「ガスタ」と名のついたモンスター２体をデッキに戻して発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイガスタ・イグルス",h:"だいがすた・いぐるす",e:"Daigusto Eguls",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1800,tx:"チューナー＋チューナー以外の「ガスタ」と名のついたモンスター１体以上\n１ターンに１度、自分のエンドフェイズ時に自分の墓地の風属性モンスター１体をゲームから除外して発動できる。\n相手フィールド上にセットされたカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュアの儀水鏡",h:"りちゅあのぎすいきょう",e:"Gishki Aquamirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「リチュア」と名のついた儀式モンスターの降臨に必要。\n自分の手札・フィールド上から、儀式召喚するモンスターと同じレベルになるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをデッキに戻す事で、自分の墓地の「リチュア」と名のついた儀式モンスター１体を選択して手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの交信",h:"がすたのこうしん",e:"Contact with Gusto",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「ガスタ」と名のついたモンスター２体と相手フィールド上のカード１枚を選択して発動できる。\n選択した墓地のモンスター２体をデッキに加えてシャッフルする。\nその後、選択した相手のカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の一手",h:"しんりゃくのいって",e:"First Step Towards Infestation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上のアドバンス召喚した「インヴェルズ」と名のついたモンスター１体を持ち主の手札に戻して発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"儀水鏡の瞑想術",h:"ぎすいきょうのめいそうじゅつ",e:"Aquamirror Meditation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札の儀式魔法カード１枚を相手に見せ、自分の墓地の「リチュア」と名のついたモンスター２体を選択して発動できる。\n選択した墓地のモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタへの祈り",h:"がすたへのいのり",e:"Blessings for Gusto",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「ガスタ」と名のついたモンスター２体を選択し、その２体をデッキに加えてシャッフルする。\nその後、自分の墓地の「ガスタ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の波動",h:"しんりゃくのはどう",e:"Infestation Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のアドバンス召喚した「インヴェルズ」と名のついたモンスター１体を持ち主の手札に戻して発動できる。\n相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・デルタ",h:"う゛ぁいろん・でるた",e:"Vylon Delta",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:1700,de:2800,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\nこのカードが表側守備表示で存在する場合、自分のエンドフェイズ時に自分のデッキから装備魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO ファリス",h:"う゛ぃじょんひーろー　ふぁりす",e:"Vision HERO Faris",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカード以外の「HERO」モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「V・HERO ファリス」以外の「V・HERO」モンスター１体を選び、永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\nこの効果の発動後、ターン終了時まで自分は「HERO」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"セブン・ソード・ウォリアー",h:"せぶん・そーど・うぉりあー",e:"Seven Swords Warrior",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、このカードに装備カードが装備された時、相手ライフに８００ポイントダメージを与える。\nまた、１ターンに１度、このカードに装備された装備カード１枚を墓地へ送る事ができる。\nこのカードに装備された装備カードが墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"起爆獣ヴァルカノン",h:"きばくじゅうう゛ぁるかのん",e:"Ignition Beast Volcannon",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1600,tx:"機械族モンスター＋炎族モンスター\nこのカードが融合召喚に成功した時、相手フィールド上に存在するモンスター１体を選択して発動する事ができる。\n選択した相手モンスターとこのカードを破壊して墓地へ送る。\nその後、墓地へ送られた相手モンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO トリニティー",h:"う゛ぃじょんひーろー　とりにてぃー",e:"Vision HERO Trinity",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「HERO」モンスター×３\n①：このカードは直接攻撃できない。\n②：このカードが融合召喚に成功したターン、このカードの攻撃力は元々の攻撃力の倍になる。\n③：融合召喚したこのカードは１度のバトルフェイズ中に３回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トラファスフィア",h:"とらふぁすふぃあ",e:"Troposphere",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードをアドバンス召喚する場合、リリースするモンスターは鳥獣族モンスターでなければならない。\nこのカードはフィールド上に表側表示で存在する限り罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"フルール・シンクロン",h:"ふるーる・しんくろん",e:"Fleur Synchron",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"①：このカードがS素材として墓地へ送られた場合に発動できる。\n手札からレベル２以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フルール・ド・シュヴァリエ",h:"ふるーる・ど・しゅう゛ぁりえ",e:"Chevalier de Fleur",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2300,tx:"「フルール・シンクロン」＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分ターンに相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"自由解放",h:"じゆうかいほう",e:"Liberty at Last!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のモンスターが戦闘で破壊され墓地へ送られた時、フィールドの表側表示モンスター２体を対象として発動できる。\nその表側表示モンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士ジャンヌ",h:"せいきしじゃんぬ",e:"Noble Knight Joan",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1300,tx:"①：このカードが攻撃するダメージステップの間、このカードの攻撃力は３００ダウンする。\n②：このカードが相手によって破壊され墓地へ送られた場合、手札を１枚墓地へ送り、自分の墓地のレベル４以下の戦士族モンスター１体を対象として発動できる。\nその戦士族モンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・デスデーモン",h:"すくらっぷ・ですでーもん",e:"Scrap Archfiend",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ"],ma:"",tp:"",at:2700,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネス・スカウト",h:"あまぞねす・すかうと",e:"Amazoness Scouts",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:1100,tx:"このカードをリリースして発動する。\n自分フィールド上に表側表示で存在する「アマゾネス」と名のついたモンスターは、このターン効果モンスターの効果の対象にならず、魔法・罠・効果モンスターの効果では破壊されない。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・パイナポー",h:"なちゅる・ぱいなぽー",e:"Naturia Pineapple",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する全てのモンスターは植物族になる。\n自分のスタンバイフェイズ時にこのカードが墓地に存在し、自分フィールド上に魔法・罠カードが存在しない場合、このカードを墓地から特殊召喚する事ができる。\nこの効果は自分フィールド上に「ナチュル・パイナポー」が表側表示で存在する場合、または自分の墓地に植物族・獣族以外のモンスターが存在する場合には発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"D.D.デストロイヤー",h:"でぃー．でぃー．ですとろいやー",e:"D.D. Destroyer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"フィールド上に存在するこのカードがゲームから除外された時、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク砂バク",h:"だーくさばく",e:"Dark Desertapir",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:300,tx:"このカードがゲームから除外された時、自分の墓地に存在するレベル４以下の獣族モンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・デビル",h:"さいこ・でびる",e:"Psychic Nightmare",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のサイキック族モンスター１体以上 \n①：１ターンに１度、自分メインフェイズに発動できる。\n相手の手札をランダムに１枚選び、そのカードの種類（モンスター・魔法・罠）を当てる。\n当たった場合、このカードの攻撃力は次の相手ターンの終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ポンコツの意地",h:"ぽんこつのいじ",e:"Guts of Steel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「スクラップ」と名のついたモンスター３体を選択して発動する。\n相手はその中から１体を選択する。\nその後、相手が選択したモンスターを自分または相手フィールド上に特殊召喚し、残りのカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの秘宝",h:"あまぞねすのひほう",e:"Amazoness Heirloom",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「アマゾネス」と名のついたモンスターにのみ装備可能。\n装備モンスターは１ターンに１度だけ、戦闘では破壊されない。\n装備モンスターが攻撃した場合、その攻撃を受けたモンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネス転生術",h:"あまぞねすてんせいじゅつ",e:"Amazoness Shamanism",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アマゾネス」と名のついたモンスターを全て破壊する。\nその後、破壊した数と同じ数まで自分の墓地に存在するレベル４以下の「アマゾネス」と名のついたモンスターを表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"猛突進",h:"もうとっしん",e:"Super Rush Recklessly",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する獣族モンスター１体を選択して破壊し、相手フィールド上に存在するモンスター１体を選択してデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・エメラル",h:"じぇむないと・えめらる",e:"Gem-Knight Emerald",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：自分フィールドの表側表示の通常モンスター１体とこのカードを除外し、自分の墓地の「ジェムナイト」融合モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・オーム",h:"う゛ぁいろん・おーむ",e:"Vylon Ohm",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカードが召喚に成功した時、自分の墓地に存在する装備魔法カード１枚を選択し、ゲームから除外する。\n次の自分のスタンバイフェイズ時にそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・ツインスレイヤー",h:"らう゛ぁる・ついんすれいやー",e:"Laval Dual Slasher",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:200,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\n自分の墓地に存在する「ラヴァル」と名のついたモンスターの数によって、このカードは以下の効果を得る。\n●２体以上：このカードが守備表示モンスターを攻撃した場合、もう１度だけ続けて攻撃する事ができる。\n●３体以上：このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・ディフェンダー",h:"じゃんく・でぃふぇんだー",e:"Junk Defender",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"相手モンスターの直接攻撃宣言時、このカードを手札から特殊召喚する事ができる。\nまた、１ターンに１度、このカードの守備力をエンドフェイズ時まで３００ポイントアップする事ができる。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結のフィッツジェラルド",h:"ひょうけつのふぃっつじぇらるど",e:"Frozen Fitzgerald",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2500,tx:"闇属性チューナー＋チューナー以外の獣族モンスター１体\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上にモンスターが存在しない場合、手札を１枚捨てる事でこのカードを墓地から表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒のズムウォルト",h:"しっこくのずむうぉると",e:"Dark Diviner",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"闇属性チューナー＋チューナー以外の昆虫族モンスター１体\nこのカードは戦闘では破壊されない。\nこのカードの攻撃宣言時、攻撃対象モンスターの攻撃力がこのカードの攻撃力よりも高い場合、攻撃対象モンスターの攻撃力をバトルフェイズ終了時までこのカードと同じ数値にする。\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・フラット・トップ",h:"だーく・ふらっと・とっぷ",e:"Dark Flattop",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:3000,tx:"闇属性チューナー＋チューナー以外の機械族モンスター１体以上\n１ターンに１度、自分の墓地の「リアクター」と名のついたモンスターまたは「ジャイアント・ボマー・エアレイド」１体を選択して発動できる。\n選択したモンスターを、召喚条件を無視して特殊召喚する。\nまた、このカードが破壊され墓地へ送られた場合、手札からレベル５以下の機械族モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"不退の荒武者",h:"ふたいのあらむしゃ",e:"Driven Daredevil",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2100,tx:"戦士族チューナー＋チューナー以外の戦士族モンスター１体以上\nこのカードの攻撃力よりも高い攻撃力を持つモンスターから攻撃を受けた場合、このカードはその戦闘では破壊されず、戦闘を行った相手モンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラッド・メフィスト",h:"ぶらっど・めふぃすと",e:"Blood Mefist",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n相手のスタンバイフェイズ時、相手フィールド上に存在するカード１枚につき相手ライフに３００ポイントダメージを与える事ができる。\nまた、相手が魔法・罠カードをセットした時、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"出幻",h:"しゅつげん",e:"Apparition",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示の「HERO」モンスターが戦闘・効果で破壊された場合に発動できる。\nデッキからレベル４以下の「V・HERO」モンスター１体を特殊召喚する。\nその後、相手フィールドのモンスター１体を選び、その元々の攻撃力・守備力を半分にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO ヴァイオン",h:"う゛ぃじょんひーろー　う゛ぁいおん",e:"Vision HERO Vyon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「HERO」モンスター１体を墓地へ送る。\n②：１ターンに１度、自分の墓地から「HERO」モンスター１体を除外して発動できる。\nデッキから「融合」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"V・HERO グラビート",h:"う゛ぃじょんひーろー　ぐらびーと",e:"Vision HERO Gravito",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、除外されている自分の「HERO」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードをリリースし、自分の魔法＆罠ゾーンの「V・HERO」モンスターカード２枚を対象として発動できる。\nそのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin World",h:"しん わーるど",e:"Malefic World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分ドローフェイズに通常のドローを行う代わりに発動できる。\nデッキから「Sin」カード３枚を相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りのカードはデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin Claw Stream",h:"しん くろう すとりーむ",e:"Malefic Claw Stream",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「Sin」モンスターが存在する場合、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブースト・ウォリアー",h:"ぶーすと・うぉりあー",e:"Boost Warrior",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"自分フィールド上にチューナーが表側表示で存在する場合、このカードは手札から表側守備表示で特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャスティス・ブリンガー",h:"じゃすてぃす・ぶりんがー",e:"Justice Bringer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"相手フィールド上に存在する特殊召喚したモンスターの効果が発動した時に発動する事ができる。\nその発動を無効にする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブライ・シンクロン",h:"ぶらい・しんくろん",e:"Bri Synchron",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1100,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、このターンのエンドフェイズ時まで、このカードをシンクロ素材としたシンクロモンスターの攻撃力は６００ポイントアップし、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグ・ワン・ウォリアー",h:"びっぐ・わん・うぉりあー",e:"Big One Warrior",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"自分のメインフェイズ時、このカード以外の手札のレベル１モンスター１体を墓地へ送って発動する事ができる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"武闘円舞",h:"ばとるわるつ",e:"Battle Waltz",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体を選択して発動する。\nそのモンスターと同じ種族・属性・レベル・攻撃力・守備力を持つ「ワルツトークン」１体を自分フィールド上に特殊召喚する。\nこのトークンの戦闘によって発生するお互いのプレイヤーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・ギフト",h:"しんくろ・ぎふと",e:"Synchro Gift",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体とシンクロモンスター以外のモンスター１体を選択して発動する。\nこのターンのエンドフェイズ時まで、選択したシンクロモンスターの攻撃力を０にし、その元々の攻撃力分もう１体のモンスターの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"捨て身の宝札",h:"すてみのほうさつ",e:"Card of Sacrifice",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスター２体以上の攻撃力の合計が、相手フィールド上に表側表示で存在する攻撃力が一番低いモンスターよりも低い場合、自分のデッキからカードを２枚ドローする。\nこのカードを発動するターン、自分はモンスターを召喚・反転召喚・特殊召喚する事ができず、表示形式を変更する事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・マテリアル",h:"しんくろ・まてりある",e:"Synchro Material",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターン自分がシンクロ召喚をする場合、選択したモンスターをシンクロ素材にする事ができる。\nこのカードを発動するターン、自分はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－東雲のコチ",h:"ぶらっくふぇざー－しののめのこち",e:"Blackwing - Kochi the Daybreak",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:1500,tx:"特殊召喚されたこのカードはS素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－蒼天のジェット",h:"ぶらっくふぇざー－そうてんのじぇっと",e:"Blackwing - Jetstream the Blue Sky",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"戦闘ダメージ計算時、このカードを手札から墓地へ送って発動する。\n自分フィールド上に存在する「BF」と名のついたモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－精鋭のゼピュロス",h:"ぶらっくふぇざー－せいえいのぜぴゅろす",e:"Blackwing - Zephyros the Elite",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドの表側表示のカード１枚を持ち主の手札に戻して発動できる。\nこのカードを墓地から特殊召喚し、自分は４００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"黒羽の導き",h:"くろはねのみちびき",e:"Black Feather Beacon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"カードの効果によって自分がダメージを受けた時に発動する事ができる。\n手札からレベル４以下の「BF」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・リターン",h:"ぶらっく・りたーん",e:"Black Return",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「BF」と名のついたモンスター１体が特殊召喚に成功した時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力分だけ自分のライフを回復し、そのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"Stardust Divinity",h:"Stardust Divinity",e:"Stardust Divinity",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"1 LIGHT Tuner + 2 or more non-Tuner Fairy-Type monsters\nThis card cannot be Special Summoned except by Synchro Summon. If this card attacks your opponent directly and reduces their Life Points to 0, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"Grizzly, the Red Star Beast",h:"Grizzly, the Red Star Beast",e:"Grizzly, the Red Star Beast",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2300,tx:"1 EARTH Tuner + 2 or more non-Tuner Beast-Type monsters\nThis card cannot be Special Summoned except by Synchro Summon. If this card attacks your opponent directly and reduces their Life Points to 0, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"レフティ・ドライバー",h:"れふてぃ・どらいばー",e:"Lefty Driver",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nこのカードのレベルはターン終了時まで３になる。\n②：墓地のこのカードを除外して発動できる。\nデッキから「ライティ・ドライバー」１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"重爆撃禽 ボム・フェネクス",h:"じゅうばくげきん　ぼむ・ふぇねくす",e:"Blaze Fenix, the Burning Bombardment Bird",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2300,tx:"機械族モンスター＋炎族モンスター\n自分のメインフェイズ時、フィールド上に存在するカード１枚につき３００ポイントダメージを相手ライフに与える事ができる。\nこの効果を発動するターンこのカードは攻撃する事ができない。\nこの効果は１ターンに１度しか使用できない。",li:1,lk:[],ta:[],al:[]},
  {n:"機皇帝ワイゼル∞",h:"きこうていわいぜるいんふぃにてぃ",e:"Meklord Emperor Wisel",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できず、自身の効果でのみ特殊召喚できる。\n①：自分フィールドの表側表示モンスターが効果で破壊され墓地へ送られた時に発動できる。\n手札のこのカードを特殊召喚する。\n②：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n③：この攻撃力は自身の効果で装備したモンスターの攻撃力分アップし、他の自分のモンスターは攻撃宣言できない。\n④：１ターンに１度、相手が魔法カードを発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇帝グランエル∞",h:"きこうていぐらんえるいんふぃにてぃ",e:"Meklord Emperor Granel",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できず、自身の効果でのみ特殊召喚できる。\n①：自分フィールドの表側表示モンスターが効果で破壊され墓地へ送られた時に発動できる。\n手札のこのカードを特殊召喚する。\n②：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n③：この攻撃力・守備力は自分LPの半分アップし、攻撃力は自身の効果で装備したモンスターの攻撃力分アップする。\n④：自身の効果で装備したモンスター１体を対象として発動できる。\nそれを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇帝スキエル∞",h:"きこうていすきえるいんふぃにてぃ",e:"Meklord Emperor Skiel",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカードは通常召喚できず、自身の効果でのみ特殊召喚できる。\n①：自分フィールドの表側表示モンスターが効果で破壊され墓地へ送られた時に発動できる。\n手札のこのカードを特殊召喚する。\n②：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n③：この攻撃力は自身の効果で装備したモンスターの攻撃力分アップし、他の自分のモンスターは攻撃宣言できない。\n④：自身が装備している自分のモンスター１体を墓地へ送って発動できる。\nこのターンこのカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇城",h:"きこうじょう",e:"Meklord Fortress",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「機皇帝」モンスターはSモンスターの効果の対象にならない。\n②：フィールドのこのカードが破壊され墓地へ送られた時に発動できる。\nデッキから「機皇帝」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マスク・チェンジ・セカンド",h:"ますく・ちぇんじ・せかんど",e:"Mask Change II",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「マスク・チェンジ・セカンド」は１ターンに１枚しか発動できない。\n①：手札を１枚捨て、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、そのモンスターよりレベルが高く同じ属性の「M・HERO」モンスター１体を、「マスク・チェンジ」による特殊召喚扱いとしてエクストラデッキから特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"M・HERO アシッド",h:"ますくどひーろー　あしっど",e:"Masked HERO Acid",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\nこのカードが特殊召喚に成功した時、相手フィールド上の魔法・罠カードを全て破壊し、相手フィールド上の全てのモンスターの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"The despair URANUS",h:"ざ・でぃすぺあ・うらぬす",e:"The Despair Uranus",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2900,de:2300,tx:"①：自分フィールドに魔法・罠カードが存在せず、このカードがアドバンス召喚に成功した時に発動できる。\n相手はカードの種類（永続魔法・永続罠）を宣言する。\n自分はデッキから宣言した種類のカード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：このカードの攻撃力は、自分フィールドの表側表示の魔法・罠カードの数×３００アップする。\n③：このカードがモンスターゾーンに存在する限り、自分の魔法＆罠ゾーンの表側表示のカードは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘル・エンプレス・デーモン",h:"へる・えんぷれす・でーもん",e:"Archfiend Empress",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2900,de:2100,tx:"このカード以外のフィールド上に表側表示で存在する悪魔族・闇属性モンスター１体が破壊される場合、代わりに自分の墓地に存在する悪魔族・闇属性モンスター１体をゲームから除外する事ができる。\nまた、フィールド上に存在するこのカードが破壊され墓地へ送られた時、「ヘル・エンプレス・デーモン」以外の自分の墓地に存在する悪魔族・闇属性・レベル６以上のモンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スカル・マイスター",h:"すかる・まいすたー",e:"Skull Meister",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:400,tx:"①：相手の墓地で魔法・罠・モンスターの効果が発動した時、このカードを手札から墓地へ送って発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドロール＆ロックバード",h:"どろーるあんどろっくばーど",e:"Droll & Lock Bird",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：相手がドローフェイズ以外でデッキからカードを手札に加えた場合、このカードを手札から墓地へ送って発動できる。\nこのターン、お互いにデッキからカードを手札に加える事はできない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔石術師 クルード",h:"ませきじゅつし　くるーど",e:"Spellstone Sorcerer Karood",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1900,tx:"１ターンに１度、このカード以外のモンスターの効果が発動する度に、このカードに魔石カウンターを１つ置く（最大１つまで）。\nこのカードに乗っている魔石カウンター１つにつき、このカードの守備力は３００ポイントアップする。\nまた、１ターンに１度、自分フィールド上に存在する魔石カウンターを１つ取り除く事で、相手の墓地に存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・マインドリーダー",h:"すくらっぷ・まいんどりーだー",e:"Scrap Mind Reader",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが墓地に存在する場合、自分のメインフェイズ２に１度だけ発動する事ができる。\n「スクラップ・マインドリーダー」以外の自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して破壊し、このカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。\nこのカードをシンクロ素材とする場合、他のシンクロ素材モンスターは全て「スクラップ」と名のついたモンスターでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の召喚師",h:"はかもりのしょうかんし",e:"Gravekeeper's Recruiter",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードが自分フィールド上から墓地へ送られた時、自分のデッキから守備力１５００以下の「墓守の」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイキック・ブロッカー",h:"さいきっく・ぶろっかー",e:"Psi-Blocker",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1200,de:300,tx:"１ターンに１度、カード名を１つ宣言して発動する。\n次の相手ターン終了時まで、お互いに宣言されたカードをプレイする事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・ウォール",h:"こあきめいる・うぉーる",e:"Koa'ki Meiru Wall",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の岩石族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n相手の魔法カードが発動した時、このカードをリリースする事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ樽 真九六",h:"からくりだる　しんくろー",e:"Karakuri Barrel mdl 96 \"Shinkuro\"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:400,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"妖怪のいたずら",h:"ようかいのいたずら",e:"Mischief of the Yokai",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの全てのモンスターのレベルをターン終了時まで２つ下げる。\n②：墓地のこのカードを除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで１つ下げる。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マスター・ヒュペリオン",h:"ますたー・ひゅぺりおん",e:"Master Hyperion",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2700,de:2100,tx:"①：このカードは自分の手札・フィールド・墓地の「代行者」モンスター１体を除外し、手札から特殊召喚できる。\n②：１ターンに１度、自分の墓地から天使族・光属性モンスター１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nフィールドに「天空の聖域」が存在する場合、この効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"神秘の代行者 アース",h:"しんぴのだいこうしゃ　あーす",e:"The Agent of Mystery - Earth",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:800,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「神秘の代行者 アース」以外の「代行者」モンスター１体を手札に加える。\nフィールドに「天空の聖域」が存在する場合、手札に加えるモンスターを「マスター・ヒュペリオン」１体にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の代行者 ジュピター",h:"きせきのだいこうしゃ　じゅぴたー",e:"The Agent of Miracles - Jupiter",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：１ターンに１度、自分の墓地から「代行者」モンスター１体を除外し、自分フィールドの天使族・光属性モンスター１体を対象として発動できる。\nその自分の天使族・光属性モンスターの攻撃力はターン終了時まで８００アップする。\n②：１ターンに１度、手札から天使族モンスター１体を捨て、除外されている自分の天使族・光属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はフィールドに「天空の聖域」が存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"天空の宝札",h:"てんくうのほうさつ",e:"Cards from the Sky",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はモンスターを特殊召喚できず、バトルフェイズを行えない。\n①：手札から天使族・光属性モンスター１体を除外し、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"神罰",h:"しんばつ",e:"Divine Punishment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドに「天空の聖域」が存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"クラスター・ペンデュラム",h:"くらすたー・ぺんでゅらむ",e:"Cosmic Compass",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"①：このカードが召喚に成功した時に発動できる。\n相手フィールドのモンスターの数まで、自分フィールドに「ペンデュラム・トークン」（機械族・地・星１・攻／守０）を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドッペル・ウォリアー",h:"どっぺる・うぉりあー",e:"Doppelwarrior",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：自分の墓地からモンスターが特殊召喚された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがS素材として墓地へ送られた場合に発動できる。\n自分フィールドに「ドッペル・トークン」（戦士族・闇・星１・攻／守４００）２体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・ファントム",h:"すたーだすと・ふぁんとむ",e:"Stardust Phantom",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に存在するこのカードが相手によって破壊され墓地へ送られた時、自分の墓地に存在する「スターダスト・ドラゴン」１体を選択して表側守備表示で特殊召喚する事ができる。\nまた、墓地に存在するこのカードをゲームから除外し、自分フィールド上に表側表示で存在するドラゴン族のシンクロモンスター１体を選択して発動する事ができる。\n選択したモンスターは１ターンに１度だけ戦闘では破壊されず、この効果を適用したダメージステップ終了時に攻撃力・守備力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の精霊",h:"いじげんのせいれい",e:"D.D. Sprite",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:100,tx:"①：このカードは自分フィールドの表側表示モンスター１体を除外し、手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚した場合、次のスタンバイフェイズに発動する。\nこのカードを特殊召喚するために除外したモンスターをフィールドに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"トップ・ランナー",h:"とっぷ・らんなー",e:"Top Runner",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する全てのシンクロモンスターの攻撃力は６００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"バリア・リゾネーター",h:"ばりあ・りぞねーたー",e:"Barrier Resonator",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:800,tx:"このカードを手札から墓地へ送り、自分フィールド上に表側表示で存在するチューナー１体を選択して発動する。\n選択したモンスターはこのターン戦闘では破壊されず、選択したモンスターの戦闘によって発生する自分への戦闘ダメージは０になる。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－尖鋭のボーラ",h:"ぶらっくふぇざー－せんえいのぼーら",e:"Blackwing - Boreas the Sharp",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:900,tx:"墓地に存在するこのカードをゲームから除外し、自分フィールド上に表側表示で存在する「BF」と名のついたモンスター１体を選択して発動する。\nこのターン選択したモンスターが攻撃する場合、その攻撃によって発生する自分への戦闘ダメージは０になり、選択したモンスターは戦闘では破壊されず、戦闘を行った相手モンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－追い風のアリゼ",h:"ぶらっくふぇざー－おいかぜのありぜ",e:"Blackwing - Brisote the Tailwind",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"自分フィールド上に表側表示で存在する「BF」と名のついたモンスターが２体以上破壊されたターン、このカードは手札から特殊召喚する事ができる。\nこのカードがシンクロ召喚の素材として墓地へ送られた場合、自分は６００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－陽炎のカーム",h:"ぶらっくふぇざー－かげろうのかーむ",e:"Blackwing - Calima the Haze",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:600,de:1800,tx:"相手のバトルフェイズ時、自分フィールド上にモンスターが存在しない場合、墓地に存在するこのカードをゲームから除外して発動する事ができる。\n自分の墓地に存在するシンクロモンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターはバトルフェイズ終了時にゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"極星獣タングリスニ",h:"きょくせいじゅうたんぐりすに",e:"Tanngrisnir of the Nordic Beasts",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\n自分フィールドに「極星獣トークン」（獣族・地・星３・攻／守０）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"極星獣グルファクシ",h:"きょくせいじゅうぐるふぁくし",e:"Guldfaxe of the Nordic Beasts",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1000,tx:"相手フィールド上にシンクロモンスターが表側表示で存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極星獣ガルム",h:"きょくせいじゅうがるむ",e:"Garmr of the Nordic Beasts",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1900,tx:"このカードがレベル４以下のモンスターと戦闘を行ったダメージ計算後、そのモンスターを手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極星獣タングニョースト",h:"きょくせいじゅうたんぐにょーすと",e:"Tanngnjostr of the Nordic Beasts",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1100,tx:"自分フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時、このカードを手札から特殊召喚する事ができる。\n１ターンに１度、フィールド上に守備表示で存在するこのカードが表側攻撃表示になった時、自分のデッキから「極星獣タングニョースト」以外の「極星獣」と名のついたモンスター１体を表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極星霊リョースアールヴ",h:"きょくせいれいりょーすあーるう゛",e:"Ljosalf of the Nordic Alfar",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが召喚に成功した時、このカード以外の自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターのレベル以下の「極星」と名のついたモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"極星霊デックアールヴ",h:"きょくせいれいでっくあーるう゛",e:"Svartalf of the Nordic Alfar",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが召喚に成功した時、自分の墓地に存在する「極星」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極星霊ドヴェルグ",h:"きょくせいれいどう゛ぇるぐ",e:"Dverg of the Nordic Alfar",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:1000,tx:"このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ「極星」と名のついたモンスター１体を召喚する事ができる。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分の墓地から「極星宝」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"極星天ヴァルキュリア",h:"きょくせいてんう゛ぁるきゅりあ",e:"Valkyrie of the Nordic Ascendant",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"①：このカードが召喚に成功した時、相手フィールドにモンスターが存在し、自分フィールドにこのカード以外のカードが存在しない場合、手札から「極星」モンスター２体を除外して発動できる。\n自分フィールドに「エインヘリアル・トークン」（戦士族・地・星４・攻／守１０００）２体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"極星天ミーミル",h:"きょくせいてんみーみる",e:"Mimir of the Nordic Ascendant",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"自分フィールド上に「極星」と名のついたモンスターが表側表示で存在する場合、自分のスタンバイフェイズ開始時に１度だけ、手札から魔法カード１枚を墓地へ送って発動する事ができる。\n墓地に存在するこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"極星將テュール",h:"きょくせいしょうてゅーる",e:"Tyr of the Nordic Champions",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"フィールド上にこのカード以外の「極星」と名のついたモンスターが表側表示で存在しない場合、このカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、相手は「極星將テュール」以外の「極星」と名のついたモンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"真六武衆－キザン",h:"しんろくぶしゅう－きざん",e:"Legendary Six Samurai - Kizan",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"①：自分フィールドに「真六武衆－キザン」以外の「六武衆」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：自分フィールドにこのカード以外の「六武衆」モンスターが２体以上存在する場合、このカードの攻撃力・守備力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"真六武衆－エニシ",h:"しんろくぶしゅう－えにし",e:"Legendary Six Samurai - Enishi",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"自分フィールド上に「真六武衆－エニシ」以外の「六武衆」と名のついたモンスターが表側表示で存在する場合、１ターンに１度、自分の墓地に存在する「六武衆」と名のついたモンスター２体をゲームから除外する事で、フィールド上に表側表示で存在するモンスター１体を選択して手札に戻す。\nこの効果は相手ターンでも発動する事ができる。\nまた、自分フィールド上に「真六武衆－エニシ」以外の「六武衆」と名のついたモンスターが表側表示で２体以上存在する場合、このカードの攻撃力・守備力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"真六武衆－カゲキ",h:"しんろくぶしゅう－かげき",e:"Legendary Six Samurai - Kageki",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:2000,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「六武衆」モンスター１体を特殊召喚する。\n②：自分フィールドに「真六武衆－カゲキ」以外の「六武衆」モンスターが存在する場合、このカードの攻撃力は１５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"真六武衆－シナイ",h:"しんろくぶしゅう－しない",e:"Legendary Six Samurai - Shinai",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"自分フィールド上に「真六武衆－ミズホ」が表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。\nフィールド上に存在するこのカードがリリースされた場合、自分の墓地に存在する「真六武衆－シナイ」以外の「六武衆」と名のついたモンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"真六武衆－ミズホ",h:"しんろくぶしゅう－みずほ",e:"Legendary Six Samurai - Mizuho",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"自分フィールド上に「真六武衆－シナイ」が表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。\nまた、１ターンに１度、このカード以外の自分フィールド上に存在する「六武衆」と名のついたモンスター１体をリリースする事で、フィールド上に存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の影武者",h:"ろくぶしゅうのかげむしゃ",e:"Kagemusha of the Six Samurai",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1800,tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体が魔法・罠・効果モンスターの効果の対象になった時、その効果の対象をフィールド上に表側表示で存在するこのカードに移し替える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の寄子",h:"しえんのよりこ",e:"Shien's Squire",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:700,tx:"自分フィールド上に存在する「六武衆」と名のついたモンスターが戦闘を行う場合、そのダメージ計算時にこのカードを手札から墓地へ送って発動する。\nそのモンスターはこのターン戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ守衛 参壱参",h:"からくりしゅえい　さいざん",e:"Karakuri Watchdog mdl 313 \"Saizan\"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1800,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードの戦闘によって自分が戦闘ダメージを受けた時、自分フィールド上に表側表示で存在する「カラクリ」と名のついた全てのモンスターの攻撃力・守備力は、エンドフェイズ時まで８００ポイントアップする。\nまた、このカードはフィールド上に表側攻撃表示で存在する限り戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ忍者 九壱九",h:"からくりにんじゃ　くいっく",e:"Karakuri Ninja mdl 919 \"Kuick\"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の墓地に存在するレベル４以下の「カラクリ」と名のついたモンスター１体を選択して表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ワーム",h:"すくらっぷ・わーむ",e:"Scrap Worm",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:100,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードは攻撃した場合、バトルフェイズ終了時に破壊される。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ワーム」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・シャーク",h:"すくらっぷ・しゃーく",e:"Scrap Shark",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2100,de:0,tx:"効果モンスターの効果・魔法・罠カードが発動した時、フィールド上に表側表示で存在するこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、自分のデッキから「スクラップ」と名のついたモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキンメダイ",h:"えれきんめだい",e:"Wattberyx",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:0,tx:"このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキンモグラ",h:"えれきんもぐら",e:"Wattmole",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"音響戦士ベーシス",h:"さうんどうぉりあーべーしす",e:"Symphonic Warrior Basses",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:400,tx:"①：１ターンに１度、フィールドの「音響戦士」モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターのレベルを手札の枚数分上げる。\n②：墓地のこのカードを除外し、自分フィールドの「音響戦士」モンスター１体を対象として発動できる。\nターン終了時まで、その自分の「音響戦士」モンスターのレベルを手札の枚数分上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"音響戦士ドラムス",h:"さうんどうぉりあーどらむす",e:"Symphonic Warrior Drumss",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:700,tx:"属性を１つ宣言し、フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体を選択して発動する。\n選択したモンスターは宣言した属性になる。\nこの効果は１ターンに１度しか使用できない。\nまた、属性を１つ宣言し、自分の墓地に存在するこのカードをゲームから除外する事で、自分フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体は宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"音響戦士ピアーノ",h:"さうんどうぉりあーぴあーの",e:"Symphonic Warrior Piaano",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:1300,tx:"種族を１つ宣言し、フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体を選択して発動する。\n選択したモンスターは宣言した種族になる。\nこの効果は１ターンに１度しか使用できない。\nまた、種族を１つ宣言し、自分の墓地に存在するこのカードをゲームから除外する事で、自分フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体は宣言した種族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"マジオシャレオン",h:"まじおしゃれおん",e:"Majioshaleon",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"相手フィールド上に魔法・罠カードが存在する限り、相手はこのカードを攻撃対象に選択する事はできない。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"夜叉",h:"やしゃ",e:"Yaksha",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1900,de:1500,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、相手フィールド上に存在する魔法・罠カード１枚を選択して持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極神皇トール",h:"きょくしんおうとーる",e:"Thor, Lord of the Aesir",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:2800,tx:"「極星獣」チューナー＋チューナー以外のモンスター２体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\n相手フィールドの全ての表側表示モンスターの効果はターン終了時まで無効化される。\n②：フィールドの表側表示のこのカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに、自分の墓地から「極星獣」チューナー１体を除外して発動できる。\nこのカードを墓地から特殊召喚する。\n③：このカードの②の効果で特殊召喚に成功した時に発動できる。\n相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"極神皇ロキ",h:"きょくしんおうろき",e:"Loki, Lord of the Aesir",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3000,tx:"「極星霊」と名のついたチューナー＋チューナー以外のモンスター２体以上\n１ターンに１度、自分のバトルフェイズ中に相手が魔法・罠カードを発動した時、その発動を無効にし破壊する事ができる。\nフィールド上に表側表示で存在するこのカードが相手によって破壊され墓地へ送られた場合、そのターンのエンドフェイズ時に自分の墓地に存在する「極星霊」と名のついたチューナー１体をゲームから除外する事で、このカードを墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、自分の墓地に存在する罠カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極神聖帝オーディン",h:"きょくしんせいていおーでぃん",e:"Odin, Father of the Aesir",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:3500,tx:"「極星天」チューナー＋チューナー以外のモンスター２体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードはターン終了時まで魔法・罠カードの効果を受けない。\n②：フィールドの表側表示のこのカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに、自分の墓地から「極星天」チューナー１体を除外して発動できる。\nこのカードを墓地から特殊召喚する。\n③：このカードの②の効果で特殊召喚に成功した時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"真六武衆－シエン",h:"しんろくぶしゅう－しえん",e:"Legendary Six Samurai - Shi En",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1400,tx:"戦士族チューナー＋チューナー以外の「六武衆」モンスター１体以上\n①：１ターンに１度、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりに自分フィールドの「六武衆」モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ大将軍 無零怒",h:"からくりだいしょうぐん　ぶれいど",e:"Karakuri Steel Shogun mdl 00X \"Bureido\"",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1700,tx:"チューナー＋チューナー以外の機械族モンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\nデッキから「カラクリ」モンスター１体を特殊召喚する。\n②：１ターンに１度、自分フィールドの表側表示の「カラクリ」モンスターの表示形式が変更された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"アトミック・スクラップ・ドラゴン",h:"あとみっく・すくらっぷ・どらごん",e:"Atomic Scrap Dragon",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:2400,tx:"「スクラップ」と名のついたチューナー＋チューナー以外のモンスター２体以上\n１ターンに１度、自分フィールド上に存在するカード１枚と、相手の墓地に存在するカードを３枚まで選択して発動する事ができる。\n選択した自分のカードを破壊し、選択した相手のカードをデッキに戻す。\nこのカードが相手によって破壊され墓地へ送られた時、シンクロモンスター以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキリム",h:"えれきりむ",e:"Watthydra",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:1500,de:1500,tx:"「エレキ」と名のついたチューナー＋チューナー以外の雷族モンスター１体以上\nこのカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚選択してゲームから除外する。\n発動後２回目の自分のスタンバイフェイズ時にそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"極星宝ドラウプニル",h:"きょくせいほうどらうぷにる",e:"Nordic Relic Draupnir",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「極神」または「極星」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nフィールド上に表側表示で存在するこのカードがカードの効果によって破壊された場合、自分のデッキから「極星宝」と名のついたカード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"神々の黄昏",h:"かみがみのたそがれ",e:"Gotterdammerung",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\n選択したモンスターのコントロールを相手に移す。\n次の相手のエンドフェイズ時、選択したモンスターを破壊し、相手フィールド上に存在するカードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"最後の進軍",h:"さいごのしんぐん",e:"March Towards Ragnarok",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\nこのターンのエンドフェイズ時まで、選択したモンスターの効果は無効化され、このカード以外の魔法・罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の狼煙",h:"しえんののろし",e:"Shien's Smoke Signal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからレベル３以下の「六武衆」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"六武式三段衝",h:"ろくぶしきさんだんしょう",e:"Six Strike - Triple Impact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「六武衆」と名のついたモンスターが表側表示で３体以上存在する場合、以下の効果から１つを選択して発動する事ができる。\n\n●相手フィールド上に表側表示で存在するモンスターを全て破壊する。\n\n●相手フィールド上に表側表示で存在する魔法・罠カードを全て破壊する。\n\n●相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の荒行",h:"ろくぶしゅうのあらぎょう",e:"Asceticism of the Six Samurai",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「六武衆」モンスター１体を対象として発動できる。\n対象のモンスターとはカード名が異なり、同じ攻撃力を持つ「六武衆」モンスター１体をデッキから特殊召喚する。\nこのターンのエンドフェイズに対象のモンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"六武院",h:"ろくぶいん",e:"Temple of the Six",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「六武衆」と名のついたモンスターが召喚・特殊召喚される度に、このカードに武士道カウンターを１つ置く。\n相手フィールド上に表側表示で存在するモンスターの攻撃力は、このカードに乗っている武士道カウンターの数×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"借カラクリ蔵",h:"かりからくりくら",e:"Karakuri Cash Cache",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「カラクリ」と名のついたモンスター１体を選択して発動する。\n自分のデッキからレベル４以下の「カラクリ」と名のついたモンスター１体を手札に加え、選択したモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ粉",h:"からくりこ",e:"Karakuri Gold Dust",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側攻撃表示で存在する「カラクリ」と名のついたモンスター２体を選択して発動する。\n選択したモンスター１体を守備表示にし、もう１体のモンスターの攻撃力はエンドフェイズ時まで、守備表示にしたモンスターの攻撃力分だけアップする。\nこの効果はバトルフェイズ時にのみ発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキー",h:"えれきー",e:"Wattkey",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「エレキ」と名のついたモンスターは、このターン相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"星屑のきらめき",h:"ほしくずのきらめき",e:"Stardust Shimmer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するドラゴン族のシンクロモンスター１体を選択して発動する。\nそのモンスターのレベルと同じレベルになるように、選択したモンスター以外の自分の墓地に存在するモンスターをゲームから除外し、選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リゾネーター・エンジン",h:"りぞねーたー・えんじん",e:"Resonator Engine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「リゾネーター」と名のついたモンスター２体を選択して発動する。\n自分のデッキからレベル４モンスター１体を手札に加え、選択した墓地のモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"トークン復活祭",h:"とーくんふっかつさい",e:"Token Sundae",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するトークンを全て破壊する。\nこの効果で破壊したトークンの数まで、フィールド上に存在するカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"おろかな転生",h:"おろかなてんせい",e:"Foolish Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手の墓地に存在するカード１枚を選択して発動する。\n選択したカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の神風",h:"みすと・ばれーのしんぷう",e:"Divine Wind of Mist Valley",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する風属性モンスターが手札に戻った場合、自分のデッキからレベル４以下の風属性モンスター１体を特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:1,lk:[],ta:[],al:[]},
  {n:"ヴァイロン・マター",h:"う゛ぁいろん・またー",e:"Vylon Matter",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する装備魔法カード３枚を選択して発動する。\n選択したカードをデッキに加えてシャッフルし、以下の効果から１つを選択して適用する。\n●自分のデッキからカードを１枚ドローする。\n●相手フィールド上に存在するカード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"禁じられた聖槍",h:"きんじられたせいそう",e:"Forbidden Lance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力が８００ダウンし、このカード以外の魔法・罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"端末世界",h:"たーみなるわーるど",e:"Terminal World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分メインフェイズ１にこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、お互いのメインフェイズ２をスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"活路への希望",h:"かつろへのきぼう",e:"Hope for Escape",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のLPが相手より１０００以上少ない場合、１０００LPを払って発動できる。\nお互いのLPの差２０００につき１枚、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼロ・フォース",h:"ぜろ・ふぉーす",e:"Zero Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターがゲームから除外された時に発動する事ができる。\nフィールド上に表側表示で存在する全てのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・ブースト",h:"ぶらっく・ぶーすと",e:"Blackboost",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「BF」と名のついたチューナー２体をゲームから除外して発動する。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミョルニルの魔槌",h:"みょるにるのまづち",e:"Divine Relic Mjollnir",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"神の威光",h:"かみのいこう",e:"Solemn Authority",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\nこのカードがフィールド上に存在する限り、お互いのプレイヤーは選択したモンスターをカードの効果の対象にする事はできない。\n発動後２回目の自分のスタンバイフェイズ時にこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"極星宝ブリージンガ・メン",h:"きょくせいほうぶりーじんが・めん",e:"Nordic Relic Brisingamen",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分及び相手フィールド上に表側表示で存在するモンスターを１体ずつ選択して発動する。\n選択した自分のモンスターの攻撃力はエンドフェイズ時まで、選択した相手モンスターの元々の攻撃力と同じ攻撃力になる。",li:"",lk:[],ta:[],al:[]},
  {n:"極星宝レーヴァテイン",h:"きょくせいほうれーう゛ぁていん",e:"Nordic Relic Laevateinn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン戦闘によってモンスターを破壊した、フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを破壊する。\nこのカードの発動に対して、魔法・罠・効果モンスターの効果を発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"極星宝グングニル",h:"きょくせいほうぐんぐにる",e:"Nordic Relic Gungnir",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」または「極星」と名のついたモンスター１体をゲームから除外し、フィールド上に存在するカード１枚を選択して発動する。\n選択したカードを破壊する。\n発動後２回目の自分のエンドフェイズ時に、この効果を発動するためにゲームから除外したモンスターを表側攻撃表示でフィールド上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フリッグのリンゴ",h:"ふりっぐのりんご",e:"The Golden Apples",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドにモンスターが存在せず、自分が戦闘ダメージを受けた時に発動できる。\n受けたダメージの数値分だけ自分のLPを回復し、自分フィールドに「邪精トークン」（悪魔族・闇・星１・攻／守？）１体を特殊召喚する。\nこのトークンの攻撃力・守備力は、この効果で自分が回復した数値と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"オーディンの眼",h:"おーでぃんのまなこ",e:"Odin's Eye",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、お互いのスタンバイフェイズ時に自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する事ができる。\n選択したモンスターの効果をエンドフェイズ時まで無効にし、相手の手札及び相手フィールド上にセットされたカードを全て確認する。\nこの効果の発動に対して、相手は魔法・罠・効果モンスターの効果を発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"神の桎梏グレイプニル",h:"かみのしっこくぐれいぷにる",e:"Gleipnir, the Fetters of Fenrir",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキから「極星」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"六尺瓊勾玉",h:"むさかにのまがたま",e:"Musakani Magatama",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「六武衆」モンスターが存在し、カードを破壊するモンスターの効果・魔法・罠カードを相手が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の計略",h:"しえんのけいりゃく",e:"Shien's Scheme",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「六武衆」と名のついたモンスターが戦闘によって破壊された場合に発動する事ができる。\n手札から「六武衆」と名のついたモンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暴走闘君",h:"ぼうそうとうくん",e:"Token Stampede",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、攻撃表示のトークンは、攻撃力が１０００アップし、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"心鎮壷のレプリカ",h:"しん・つぇん・ふーのれぷりか",e:"Xing Zhen Hu Replica",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上にセットされた魔法・罠カード１枚を選択して発動する。\nこのカードがフィールド上に存在する限り、選択したカードは発動する事ができない。\nこのカードの発動に対して、魔法・罠・効果モンスターの効果を発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"暴君の暴言",h:"ぼうくんのぼうげん",e:"Tyrant's Tirade",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター２体をリリースして発動する。\nこのカードがフィールド上に存在する限り、お互いに手札及びフィールド上で発動する効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"カース・オブ・スタチュー",h:"かーす・おぶ・すたちゅー",e:"Tiki Curse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後モンスターカード（岩石族・闇・星４・攻１８００／守１０００）となり、自分のモンスターカードゾーンに特殊召喚する。\nこのカードがフィールド上にモンスター扱いとして存在し、このカード以外のモンスター扱いとした罠カードが相手モンスターと戦闘を行った場合、その相手モンスターをダメージ計算後に破壊する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ソウル・オブ・スタチュー",h:"そうる・おぶ・すたちゅー",e:"Tiki Soul",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後モンスターカード（岩石族・光・星４・攻１０００／守１８００）となり、自分のモンスターカードゾーンに特殊召喚する。\nこのカードがフィールド上にモンスター扱いとして存在する限り、このカード以外のモンスター扱いとした罠カードが相手によって破壊され自分の墓地へ送られる場合、墓地へ送らず魔法＆罠カードゾーンにセットする事ができる。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・クリスタ",h:"じぇむないと・くりすた",e:"Gem-Knight Crystal",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:2450,de:1950,tx:"クリスタルパワーを最適化し、戦闘力に変えて戦うジェムナイトの上級戦士。\nその高い攻撃力で敵を圧倒するぞ。\nしかし、その最適化には限界を感じる事も多く、仲間たちとの結束を大切にしている。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル炎火山の侍女",h:"らう゛ぁるえんかざんのじじょ",e:"Laval Volcano Handmaiden",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:200,tx:"①：このカードが墓地へ送られた時、自分の墓地に「ラヴァル炎火山の侍女」以外の「ラヴァル」モンスターが存在する場合に発動できる。\nデッキから「ラヴァル」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・キャノン",h:"らう゛ぁる・きゃのん",e:"Laval Cannon",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"このカードが召喚・反転召喚に成功した時、ゲームから除外されている自分の「ラヴァル」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・スフィア",h:"う゛ぁいろん・すふぃあ",e:"Vylon Sphere",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:400,tx:"①：このカードがモンスターゾーンから墓地へ送られた場合、５００LPを払い、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその自分のモンスターに装備する。\n②：装備カード扱いのこのカードを墓地へ送り、このカードを装備していたモンスターが装備可能な自分の墓地の装備魔法カード１枚を対象として発動できる。\nこのカードを装備していたモンスターに対象のカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・テトラ",h:"う゛ぁいろん・てとら",e:"Vylon Tetra",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:900,tx:"このカードがモンスターカードゾーン上から墓地へ送られた場合、５００ライフポイントを払う事で、このカードを装備カード扱いとして自分フィールド上のモンスター１体に装備する。\nこのカードを装備したモンスターが破壊される場合、代わりにこのカードを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・ステラ",h:"う゛ぁいろん・すてら",e:"Vylon Stella",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:200,tx:"このカードがモンスターカードゾーン上から墓地へ送られた場合、５００ライフポイントを払う事で、このカードを装備カード扱いとして自分フィールド上のモンスター１体に装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターと戦闘を行った相手モンスターを、そのダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・プリズム",h:"う゛ぁいろん・ぷりずむ",e:"Vylon Prism",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードがモンスターカードゾーン上から墓地へ送られた場合、５００ライフポイントを払って発動できる。\nこのカードを装備カード扱いとして自分フィールド上のモンスター１体に装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターが戦闘を行うダメージステップの間、その攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・ハプト",h:"う゛ぁいろん・はぷと",e:"Vylon Hept",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"１ターンに１度、自分フィールド上の装備カード扱いの「ヴァイロン」と名のついたモンスターカード１枚を選択し、表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・キラー",h:"りちゅあ・きらー",e:"Gishki Reliever",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードが召喚・反転召喚に成功した時、自分フィールド上にこのカード以外の「リチュア」と名のついたモンスターが存在する場合、このカード以外の自分フィールド上のモンスター１体を選択して持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・ノエリア",h:"りちゅあ・のえりあ",e:"Gishki Noellia",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが召喚に成功した時、自分のデッキの上からカードを５枚めくる事ができる。\nめくったカードの中に儀式魔法カードまたは「リチュア」と名のついたモンスターカードがあった場合、それらのカードを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・スクイレル",h:"がすた・すくいれる",e:"Gusto Squirro",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードがカードの効果によって破壊され墓地へ送られた時、デッキからレベル５以上の「ガスタ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの疾風 リーズ",h:"がすたのしっぷう　りーず",e:"Reeze, Whirlwind of Gusto",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"１ターンに１度、手札を１枚デッキの一番下に戻し、相手フィールド上のモンスター１体と自分フィールド上の「ガスタ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのコントロールを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ万能態",h:"いんう゛ぇるずばんのうたい",e:"Steelswarm Genome",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"「インヴェルズ」と名のついたモンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズの歩哨",h:"いんう゛ぇるずのほしょう",e:"Steelswarm Sentinel",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"このカードがフィールド上に表側攻撃表示で存在する限り、フィールド上のレベル５以上の特殊召喚されたモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズの先鋭",h:"いんう゛ぇるずのせんえい",e:"Steelswarm Sting",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1850,de:0,tx:"自分フィールド上のこのカードが墓地へ送られた時、フィールド上の儀式・融合・シンクロモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ・ホーン",h:"いんう゛ぇるず・ほーん",e:"Steelswarm Longhorn",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"「インヴェルズ」と名のついたモンスターをリリースしてこのカードが表側表示でアドバンス召喚に成功した場合、このカードは以下の効果を得る。\n１ターンに１度、１０００ライフポイントを払う事で、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ・グレズ",h:"いんう゛ぇるず・ぐれず",e:"Steelswarm Hercules",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3200,de:0,tx:"このカードは特殊召喚できない。\nこのカードを通常召喚する場合、自分フィールド上の「インヴェルズ」と名のついたモンスター３体をリリースして召喚しなければならない。\n１ターンに１度、ライフポイントを半分払う事で、このカード以外のフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・テトラオーグル",h:"いびりちゅあ・てとらおーぐる",e:"Evigishki Tetrogre",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手は手札を１枚捨ててこの効果を無効にできる。\n捨てなかった場合、お互いのプレイヤーは宣言された種類のカード１枚をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・マディラ",h:"じぇむないと・までぃら",e:"Gem-Knight Citrine",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1950,tx:"「ジェムナイト」モンスター＋炎族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・プリズムオーラ",h:"じぇむないと・ぷりずむおーら",e:"Gem-Knight Prismaura",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:2450,de:1400,tx:"「ジェムナイト」モンスター＋雷族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：１ターンに１度、手札から「ジェムナイト」カード１枚を墓地へ送り、フィールドの表側表示のカード１枚を対象として発動できる。\nその表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・ステライド",h:"らう゛ぁる・すてらいど",e:"Laval Stennon",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:1800,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分は手札を１枚墓地へ送る。\nこのカードがカードの効果の対象になった時、自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外する事で、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・アルファ",h:"う゛ぁいろん・あるふぁ",e:"Vylon Alpha",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1100,tx:"「ヴァイロン」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分の墓地の装備魔法カード１枚を選択してこのカードに装備できる。\n装備カードを装備したこのカードは、装備カード以外の魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・オメガ",h:"う゛ぁいろん・おめが",e:"Vylon Omega",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:1900,tx:"チューナー２体＋チューナー以外の「ヴァイロン」と名のついたモンスター１体\nこのカードがシンクロ召喚に成功した時、フィールド上に表側表示で存在する通常召喚されたモンスターを全て破壊する。\n１ターンに１度、自分の墓地の「ヴァイロン」と名のついたモンスター１体を選択し、装備カード扱いとしてこのカードに装備できる。\n効果モンスターの効果が発動した時、このカードに装備された装備カード１枚を墓地へ送る事で、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイガスタ・スフィアード",h:"だいがすた・すふぃあーど",e:"Daigusto Sphreez",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1300,tx:"チューナー＋チューナー以外の「ガスタ」と名のついたモンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分の墓地の「ガスタ」と名のついたカード１枚を選択して手札に加える事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上の「ガスタ」と名のついたモンスターの戦闘によって発生する自分への戦闘ダメージは代わりに相手が受ける。\nまた、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・コンポーネント",h:"う゛ぁいろん・こんぽーねんと",e:"Vylon Component",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、デッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・エレメント",h:"う゛ぁいろん・えれめんと",e:"Vylon Element",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ヴァイロン」と名のついた装備カードが破壊された時、破壊された数までデッキから「ヴァイロン」と名のついたチューナーを特殊召喚できる。\nこの効果で特殊召喚したモンスターをシンクロ素材とする場合、「ヴァイロン」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュアに伝わりし禁断の秘術",h:"りちゅあにつたわりしきんだんのひじゅつ",e:"Forbidden Arts of the Gishki",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「リチュア」と名のついた儀式モンスターの降臨に必要。\n自分フィールド上及び相手フィールド上から、儀式召喚するモンスターと同じレベルになるように表側表示で存在するモンスターをリリースしなければならない。\nこの効果で儀式召喚したモンスターの攻撃力は半分になる。\nこのカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"輝石融合",h:"あっせんぶる・ふゅーじょん",e:"Pyroxene Fusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札・フィールド上から、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「ジェムナイト」と名のついたその融合モンスター１体を融合召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の波紋",h:"しんりゃくのはもん",e:"Infestation Ripples",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払い、自分の墓地のレベル４以下の「インヴェルズ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の手段",h:"しんりゃくのしゅだん",e:"Infestation Tool",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"デッキから「インヴェルズ」と名のついたモンスター１体を墓地へ送り、自分フィールド上の「インヴェルズ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"極戦機王ヴァルバロイド",h:"きょくせんきおうう゛ぁるばろいど",e:"Barbaroid, the Ultimate Battle Machine",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「ロイド」と名のついた機械族モンスター×５\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードが攻撃した相手の効果モンスターの効果をダメージ計算後に無効化する。\nこのカードが戦闘によって相手モンスターを破壊した場合、相手ライフに１０００ポイントダメージを与える。\nこのカードは相手プレイヤーに直接攻撃をする事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"天啓の薔薇の鐘",h:"てんけいのろーず・べる",e:"Rose Bell of Revelation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：デッキから攻撃力２４００以上の植物族モンスター１体を手札に加える。\n②：墓地のこのカードを除外して発動できる。\n手札から攻撃力２４００以上の植物族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"薔薇恋人",h:"ばら・らう゛ぁー",e:"Rose Lover",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\n手札から植物族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、相手の罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔天使ローズ・ソーサラー",h:"まてんしろーず・そーさらー",e:"Fallen Angel of Roses",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1300,tx:"このカードは「魔天使ローズ・ソーサラー」以外の自分フィールド上に表側表示で存在する植物族モンスター１体を手札に戻し、手札から特殊召喚する事ができる。\nこの方法で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"The tripping MERCURY",h:"ざ・とりっぴんぐ・まーきゅりー",e:"The Tripper Mercury",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：このカードがアドバンス召喚に成功した時に発動できる。\nフィールドのモンスターを全て表側攻撃表示にする。\n②：このカードはモンスター３体をリリースして召喚する事もできる。\n③：このカードの②の方法で召喚したこのカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力はそのモンスターの元々の攻撃力分ダウンする。\n④：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・カノン",h:"ましんなーず・かのん",e:"Machina Cannon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードは通常召喚できない。\nこのカード以外の手札の機械族モンスターを任意の枚数墓地へ送った場合に特殊召喚できる。\n①：このカードの攻撃力は、このカードを特殊召喚するために墓地へ送ったモンスターの数×８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"スナップドラゴン",h:"すなっぷどらごん",e:"Snapdragon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカードが墓地へ送られた時、相手の手札をランダムに１枚選択し、エンドフェイズ時まで表側表示でゲームから除外する。\n「スナップドラゴン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ラニスタ",h:"ぐらでぃあるびーすとらにすた",e:"Gladiator Beast Lanista",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地に存在する「剣闘獣」と名のついたモンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外し、エンドフェイズ時までそのモンスターと同名カードとして扱う。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ラニスタ」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－空風のジン",h:"ぶらっくふぇざー－からかぜのじん",e:"Blackwing - Jin the Rain Shadow",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:500,tx:"このカードの攻撃力以下の守備力を持つ、フィールド上に表側表示で存在するモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・オルトロス",h:"すくらっぷ・おるとろす",e:"Scrap Orthros",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードは通常召喚できない。\n自分フィールドに「スクラップ」モンスターが存在する場合に特殊召喚できる。\n①：この方法でこのカードが特殊召喚に成功した場合、自分フィールドの「スクラップ」モンスター１体を対象として発動する。\nその自分の「スクラップ」モンスターを破壊する。\n②：このカードが「スクラップ」カードの効果で破壊され墓地へ送られた場合、「スクラップ・オルトロス」以外の自分の墓地の「スクラップ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ノヴァマスター",h:"えれめんたるひーろー　のう゛ぁますたー",e:"Elemental HERO Nova Master",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「E・HERO」モンスター＋炎属性モンスター \nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・エッグプラント",h:"なちゅる・えっぐぷらんと",e:"Naturia Eggplant",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"このカードがフィールド上から墓地へ送られた時、自分の墓地に存在する「ナチュル・エッグプラント」以外の「ナチュル」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムタートル",h:"じぇむたーとる",e:"Gem-Turtle",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:2000,tx:"リバース：デッキから「ジェムナイト・フュージョン」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・ランスロッド",h:"らう゛ぁる・らんすろっど",e:"Laval Lancelord",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:200,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードは、エンドフェイズ時に墓地へ送られる。\nフィールド上のこのカードが破壊され墓地へ送られた時、ゲームから除外されている自分の炎属性モンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・ビースト",h:"りちゅあ・びーすと",e:"Gishki Beast",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが召喚に成功した時、自分の墓地のレベル４以下の「リチュア」と名のついたモンスター１体を選択して表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"X－セイバー ソウザ",h:"えっくす－せいばー　そうざ",e:"X-Saber Souza",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1600,tx:"チューナー＋チューナー以外の「X－セイバー」モンスター１体以上\n①：自分フィールドの「X－セイバー」モンスター１体をリリースし、以下の効果から１つを選択して発動できる。\nその効果をターン終了時まで得る。\n●このカードがモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。\n●このカードは罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"死の花－ネクロ・フルール",h:"しのはな－ねくろ・ふるーる",e:"Necro Fleur",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「時花の魔女－フルール・ド・ソルシエール」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"時花の魔女－フルール・ド・ソルシエール",h:"ときばなのまじょ－ふるーる・ど・そるしえーる",e:"Sorciere de Fleur",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2900,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手の墓地のモンスター１体を対象として発動する。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは相手に直接攻撃できず、このターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"Z－ONE",h:"ぜっと・わん",e:"Z-ONE",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：セットされたこのカードが破壊され墓地へ送られた場合、自分の墓地の永続魔法カードまたはフィールド魔法カード１枚を対象として発動する。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーローズ・ドラゴン",h:"ぶるーろーず・どらごん",e:"Blue Rose Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：フィールドのこのカードが破壊され墓地へ送られた時、自分の墓地の、「ブラック・ローズ・ドラゴン」または植物族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"旋風のボルテクス",h:"せんぷうのぼるてくす",e:"Vortex the Whirlwind",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:700,tx:"チューナー＋チューナー以外の鳥獣族モンスター１体以上\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからレベル４以下の鳥獣族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極星天ヴァナディース",h:"きょくせいてんう゛ぁなでぃーす",e:"Vanadis of the Nordic Ascendant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードは「極星」チューナーの代わりとしてS素材にできる。\nこのカードをS素材とする場合、他のS素材モンスターは全て「極星」モンスターでなければならない。\n①：１ターンに１度、このカードのレベルと異なるレベルを持つ「極星」モンスター１体をデッキから墓地へ送って発動できる。\nこのカードのレベルはターン終了時まで、墓地へ送ったモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の荒武者",h:"しえんのあらむしゃ",e:"Shien's Daredevil",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが召喚に成功した時、このカードに武士道カウンターを１つ置く（最大１つまで）。\nこのカードに武士道カウンターが乗っている場合、このカードの攻撃力は３００ポイントアップする。\nまた、１ターンに１度、このカードに乗っている武士道カウンターを取り除き、フィールド上に表側表示で存在する武士道カウンターを置く事ができるカード１枚を選択して武士道カウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ無双 八壱八",h:"からくりむそう　はいぱー",e:"Karakuri Muso mdl 818 \"Haipa\"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1100,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ブレイカー",h:"すくらっぷ・ぶれいかー",e:"Scrap Breaker",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:700,tx:"相手フィールド上にモンスターが存在する場合、このカードは手札から特殊召喚する事ができる。\nこの効果で特殊召喚に成功した時、自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カオスハンター",h:"かおすはんたー",e:"Chaos Hunter",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1600,tx:"①：相手がモンスターの特殊召喚に成功した時、このカード以外の手札を１枚捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手はカードを除外できない。",li:"",lk:[],ta:[],al:[]},
  {n:"増殖するG",h:"ぞうしょくするじー",e:"Maxx \"C\"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の効果は１ターンに１度しか使用できず、相手ターンでも発動できる。\n①：このカードを手札から墓地へ送って発動できる。\nこのターン、以下の効果を適用する。\n●相手がモンスターの特殊召喚に成功する度に、自分はデッキから１枚ドローしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"極星の輝き",h:"きょくせいのかがやき",e:"The Nordic Lights",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上に存在する「極星」と名のついたモンスターは戦闘では破壊されない。\nまた、フィールド上に存在するこのカードが破壊された時、フィールド上に表側表示で存在する「極星」と名のついたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"極星宝メギンギョルズ",h:"きょくせいほうめぎんぎょるず",e:"Nordic Relic Megingjord",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」または「極星」と名のついたモンスター１体を選択して発動する。\nエンドフェイズ時まで、選択したモンスターの攻撃力・守備力は元々の数値の倍になる。\nこのターン、選択したモンスターは相手プレイヤーに直接攻撃する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"六武式風雷斬",h:"ろくぶしきふうらいざん",e:"Six Strike - Thunder Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する武士道カウンターを１つ取り除き、以下の効果から１つを選択して発動する。\n\n●相手フィールド上に存在するモンスター１体を選択して破壊する。\n\n●相手フィールド上に存在するカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－上弦のピナーカ",h:"ぶらっくふぇざー－じょうげんのぴなーか",e:"Blackwing - Pinaki the Waxing Moon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:1000,tx:"「BF－上弦のピナーカ」の効果は１ターンに１度しか使用できない。\nこのカードをS素材とする場合、「BF」モンスターのS召喚にしか使用できない。\n①：このカードがフィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「BF－上弦のピナーカ」以外の「BF」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－残夜のクリス",h:"ぶらっくふぇざー－ざんやのくりす",e:"Blackwing - Kris the Crack of Dawn",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1900,de:300,tx:"「BF－残夜のクリス」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「BF－残夜のクリス」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードは１ターンに１度だけ、魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－白夜のグラディウス",h:"ぶらっくふぇざー－びゃくやのぐらでぃうす",e:"Blackwing - Gladius the Midnight Sun",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"①：自分フィールドの表側表示モンスターが「BF－白夜のグラディウス」以外の「BF」モンスター１体のみの場合、このカードは手札から特殊召喚できる。\n②：このカードは１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF T－漆黒のホーク・ジョー",h:"ぶらっくふぇざー　ていまー－しっこくのほーく・じょー",e:"Blackwing Tamer - Obsidian Hawk Joe",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"「BF」チューナー＋チューナー以外の「BF」モンスター１体以上\n「BF T－漆黒のホーク・ジョー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のレベル５以上の鳥獣族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが相手の効果の対象になった時、または相手モンスターの攻撃対象になった時、このカード以外の自分フィールドの「BF」モンスター１体を対象として発動できる。\nその対象を正しい対象となるそのモンスターに移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－煌星のグラム",h:"ぶらっくふぇざー－きらぼしのぐらむ",e:"Blackwing - Gram the Shining Star",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードはシンクロ召喚でのみエクストラデッキから特殊召喚できる。\nこのカードがシンクロ召喚に成功した時、手札からチューナー以外のレベル４以下の「BF」と名のついたモンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"The grand JUPITER",h:"ざ・ぐらんど・じゅぴたー",e:"The Grand Jupiter",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：１ターンに１度、手札を２枚捨て、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力分アップする。\n③：自分・相手のエンドフェイズに、このカードの効果で装備したモンスターカード１枚を対象として発動できる。\nそのカードを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"The blazing MARS",h:"ざ・ぶれいじんぐ・まーず",e:"The Blazing Mars",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"「The blazing MARS」の②の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、このカード以外の自分の墓地のモンスター３体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。\n②：自分メインフェイズ１に、このカード以外の自分フィールドのモンスターを全て墓地へ送って発動できる。\n墓地へ送ったモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・サーバント",h:"じゃんく・さーばんと",e:"Junk Servant",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上に「ジャンク」と名のついたモンスターが表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アンノウン・シンクロン",h:"あんのうん・しんくろん",e:"Unknown Synchron",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「アンノウン・シンクロン」の①の方法による特殊召喚はデュエル中に１度しかできない。\n①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サルベージ・ウォリアー",h:"さるべーじ・うぉりあー",e:"Salvage Warrior",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードがアドバンス召喚に成功した時、手札または自分の墓地からチューナー１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロ・ディフェンダー",h:"ねくろ・でぃふぇんだー",e:"Necro Defender",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:800,tx:"自分のメインフェイズ時に、墓地に存在するこのカードをゲームから除外し、自分フィールド上に存在するモンスター１体を選択して発動する事ができる。\n次の相手のエンドフェイズ時まで、選択したモンスターは戦闘では破壊されず、選択したモンスターの戦闘によって発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミスティック・パイパー",h:"みすてぃっく・ぱいぱー",e:"Mystic Piper",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをリリースして発動する。\n自分のデッキからカードを１枚ドローする。\nこの効果でドローしたカードをお互いに確認し、レベル１モンスターだった場合、自分はカードをもう１枚ドローする。\n「ミスティック・パイパー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォース・リゾネーター",h:"ふぉーす・りぞねーたー",e:"Force Resonator",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"自分フィールド上に表側表示で存在するこのカードを墓地へ送り、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターン、選択したモンスターが攻撃する場合、相手はダメージステップ終了時までモンスターを対象にする魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"クロック・リゾネーター",h:"くろっく・りぞねーたー",e:"Clock Resonator",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードがフィールド上に表側守備表示で存在する限り、このカードは１ターンに１度だけ戦闘またはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－天狗風のヒレン",h:"ぶらっくふぇざー－てんぐかぜのひれん",e:"Blackwing - Hillen the Tengu-wind",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2300,tx:"このカードが墓地に存在し、相手モンスターの直接攻撃によって自分が２０００ポイント以上の戦闘ダメージを受けた時、自分の墓地に存在する「BF」と名のついたレベル３以下のモンスター１体を選択して発動する。\n選択したモンスターとこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「BF－天狗風のヒレン」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－流離いのコガラシ",h:"ぶらっくふぇざー－さすらいのこがらし",e:"Blackwing - Kogarashi the Wanderer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:2300,de:1600,tx:"自分フィールド上に表側表示で存在する「BF」と名のついたモンスターがカードの効果によって破壊され墓地へ送られた時、このカードを手札から特殊召喚する事ができる。\nまた、このカードをシンクロ素材としたシンクロ召喚に成功した時、相手は魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ライトン",h:"でぃふぉーまー・らいとん",e:"Morphtronic Lantron",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードがフィールド上に表側表示で存在する限り、相手のカードの効果によって発生する自分への効果ダメージは代わりに相手が受ける。\n●守備表示：このカードが戦闘によって破壊され墓地へ送られた時、このターン戦闘によって発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"D・ステープラン",h:"でぃふぉーまー・すてーぷらん",e:"Morphtronic Staplen",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードがフィールド上に表側表示で存在する限り、相手は他のモンスターを攻撃対象に選択できない。\nこのカードが戦闘によって破壊された場合、このカードを破壊したモンスターの攻撃力は３００ポイントダウンする。\n●守備表示：このカードは戦闘では破壊されない。\nこのカードが攻撃された場合、そのダメージ計算後に相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して守備表示にし、このカードの表示形式を攻撃表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇兵ワイゼル・アイン",h:"きこうへいわいぜる・あいん",e:"Meklord Army of Wisel",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードの攻撃力は、このカード以外のフィールドの「機皇」モンスターの数×１００アップする。\n②：１ターンに１度、このカード以外の自分の「機皇」モンスターが守備表示モンスターに攻撃宣言した時に発動できる。\nその戦闘でその自分のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇兵スキエル・アイン",h:"きこうへいすきえる・あいん",e:"Meklord Army of Skiel",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードの攻撃力は、このカード以外のフィールド上に表側表示で存在する「機皇」と名のついたモンスターの数×２００ポイントアップする。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「機皇兵」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇兵グランエル・アイン",h:"きこうへいぐらんえる・あいん",e:"Meklord Army of Granel",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードの攻撃力は、このカード以外のフィールド上に表側表示で存在する「機皇」と名のついたモンスターの数×１００ポイントアップする。\nこのカードが召喚に成功した時、相手フィールド上に表側表示で存在するモンスター１体を選択し、そのモンスターの攻撃力をエンドフェイズ時まで半分にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇神龍アステリスク",h:"きこうしんりゅうあすてりすく",e:"Meklord Astro Dragon Asterisk",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「機皇」モンスターが３体以上の場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した時、このカード以外の自分フィールドの「機皇」モンスターを任意の数だけ対象として発動できる。\nその自分の「機皇」モンスターを墓地へ送る。\nこのカードの攻撃力は、この効果で墓地へ送ったモンスターの元々の攻撃力を合計した数値になる。\n②：Sモンスターが特殊召喚される度に発動する。\nそのモンスターを特殊召喚したプレイヤーに１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"TG サイバー・マジシャン",h:"てっくじーなす　さいばー・まじしゃん",e:"T.G. Cyber Magician",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に表側表示で存在するこのカードを「TG」と名のついたシンクロモンスターのシンクロ素材とする場合、手札の「TG」と名のついたモンスターを他のチューナー以外のシンクロ素材とする事ができる。\nフィールド上に存在するこのカードが破壊され墓地へ送られたターンのエンドフェイズ時、自分のデッキから「TG サイバー・マジシャン」以外の「TG」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ストライカー",h:"てっくじーなす　すとらいかー",e:"T.G. Striker",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「TG ストライカー」以外の「TG」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ジェット・ファルコン",h:"てっくじーなす　じぇっと・ふぁるこん",e:"T.G. Jet Falcon",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"TG カタパルト・ドラゴン",h:"てっくじーなす　かたぱると・どらごん",e:"T.G. Catapult Dragon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:900,de:1300,tx:"１ターンに１度、手札からレベル３以下の「TG」と名のついたチューナー１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ワーウルフ",h:"てっくじーなす わーうるふ",e:"T.G. Warwolf",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"①：レベル４以下のモンスターが特殊召喚された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「TG ワーウルフ」以外の「TG」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ラッシュ・ライノ",h:"てっくじーなす　らっしゅ・らいの",e:"T.G. Rush Rhino",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが攻撃する場合、ダメージステップの間このカードの攻撃力は４００ポイントアップする。\nフィールド上に存在するこのカードが破壊され墓地へ送られたターンのエンドフェイズ時、自分のデッキから「TG ラッシュ・ライノ」以外の「TG」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・ショットマン",h:"ばすたー・しょっとまん",e:"Buster Blaster",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上のモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターの攻撃力・守備力は５００ポイントダウンする。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、フィールド上に表側表示で存在する、破壊したモンスターと同じ種族のモンスターを全て破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"サイ・ガール",h:"さい・がーる",e:"Esper Girl",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:300,tx:"ゲームから除外されているこのカードが特殊召喚に成功した時、自分のデッキの一番上のカードを裏側表示でゲームから除外する。\nこのカードがフィールド上から墓地へ送られた時、このカードの効果で除外した自分のカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"メンタルシーカー",h:"めんたるしーかー",e:"Mental Seeker",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"ゲームから除外されているこのカードが特殊召喚に成功した時、相手のデッキの上からカードを３枚めくる。\n自分はその中から１枚を選択してゲームから除外し、残りのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"沈黙のサイコウィザード",h:"ちんもくのさいこうぃざーど",e:"Silent Psychic Wizard",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードが召喚に成功した時、自分の墓地に存在するサイキック族モンスター１体を選択してゲームから除外する事ができる。\nこのカードがフィールド上から墓地へ送られた時、このカードの効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"静寂のサイコウィッチ",h:"せいじゃくのさいこうぃっち",e:"Serene Psychic Witch",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"フィールド上に存在するこのカードが破壊され墓地へ送られた時、自分のデッキから攻撃力２０００以下のサイキック族モンスター１体をゲームから除外する事ができる。\n次のスタンバイフェイズ時、この効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"寡黙なるサイコプリースト",h:"かもくなるさいこぷりーすと",e:"Hushed Psychic Cleric",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカードは召喚・反転召喚に成功した時、守備表示になる。\n１ターンに１度、手札を１枚墓地へ送る事で、自分の墓地に存在するサイキック族モンスター１体を選択してゲームから除外する。\nこのカードがフィールド上から墓地へ送られた時、このカードの効果で除外したモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆のご隠居",h:"ろくぶしゅうのごいんきょ",e:"Elder of the Six Samurai",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:0,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の参謀",h:"しえんのさんぼう",e:"Shien's Advisor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"自分フィールド上に「六武衆」と名のついたモンスターが表側表示で存在する場合にこのカードが召喚に成功した時、種族を１つ宣言して発動する。\nこのカードがフィールド上に表側表示で存在する限り、宣言した種族のモンスターは攻撃宣言をする事ができず、お互いのプレイヤーは宣言した種族のモンスターを特殊召喚する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ小町 弐弐四",h:"からくりこまち　ににし",e:"Karakuri Komachi mdl 224 \"Ninishi\"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードがフィールド上に表側表示で存在する限り、自分のメインフェイズ時に１度だけ、自分は通常召喚に加えて「カラクリ」と名のついたモンスター１体を召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ忍者 七七四九",h:"からくりにんじゃ　ななしっく",e:"Karakuri Ninja mdl 7749 \"Nanashick\"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードが召喚に成功した時、自分フィールド上に表側守備表示で存在する「カラクリ」と名のついたモンスターの数だけ、自分のデッキからカードをドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・コング",h:"すくらっぷ・こんぐ",e:"Scrap Kong",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードが召喚に成功した時、このカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・コング」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サシカエル",h:"さしかえる",e:"Tradetoad",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"自分フィールド上に存在する水族モンスター１体をリリースし、自分の墓地に存在する「ガエル」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを墓地から特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ティゲル",h:"ぐらでぃあるびーすとてぃげる",e:"Gladiator Beast Tygerius",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードは融合素材モンスターとして使用する事はできない。\nこのカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、手札から「剣闘獣」と名のついたカード１枚を捨てる事で、自分のデッキから「剣闘獣」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"瓶亀",h:"かめがめ",e:"Jar Turtle",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:2100,tx:"このカードがフィールド上に表側表示で存在する限り、「強欲な瓶」が発動する度に自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"オーロラ・アンギラス",h:"おーろら・あんぎらす",e:"Aurora Paragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いにモンスターを特殊召喚できない。\n②：このカード以外のモンスターが召喚された場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・バーサーカー",h:"じゃんく・ばーさーかー",e:"Junk Berserker",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:1800,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n自分の墓地に存在する「ジャンク」と名のついたモンスター１体をゲームから除外し、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力は、除外したモンスターの攻撃力分ダウンする。\nまた、このカードが守備表示のモンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライフ・ストリーム・ドラゴン",h:"らいふ・すとりーむ・どらごん",e:"Life Stream Dragon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2900,de:2400,tx:"チューナー＋「パワー・ツール・ドラゴン」\nこのカードがシンクロ召喚に成功した時、自分のライフポイントを４０００にする事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分が受ける効果ダメージは０になる。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに自分の墓地に存在する装備魔法カード１枚をゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"TG レシプロ・ドラゴン・フライ",h:"てっくじーなす　れしぷろ・どらごん・ふらい",e:"T.G. Recipro Dragonfly",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:300,de:300,tx:"チューナー＋チューナー以外のモンスター１体\n①：１ターンに１度、このカード以外の自分フィールドの「TG」Sモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送る。\nその後、墓地へ送ったそのモンスターのS召喚に使用したS素材モンスター一組が、全てSモンスターで自分の墓地に揃っていれば、その一組を自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ワンダー・マジシャン",h:"てっくじーなす　わんだー・まじしゃん",e:"T.G. Wonder Magician",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1900,de:0,tx:"チューナー＋チューナー以外の「TG」モンスター１体以上\n①：このカードがS召喚に成功した場合、フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターを素材としてS召喚する。\n③：フィールドのこのカードが破壊された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"TG パワー・グラディエイター",h:"てっくじーなす　ぱわー・ぐらでぃえいたー",e:"T.G. Power Gladiator",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"チューナー＋チューナー以外の「TG」と名のついたモンスター１体以上\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nフィールド上に存在するこのカードが破壊された時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ブレード・ガンナー",h:"てっくじーなす　ぶれーど・がんなー",e:"T.G. Blade Blaster",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:2200,tx:"シンクロモンスターのチューナー１体＋チューナー以外のシンクロモンスター１体以上\nこのカードを対象とする相手の魔法・罠カードが発動した時、手札を１枚墓地へ送る事で、その効果を無効にする。\nまた、相手ターンに１度、自分の墓地に存在する「TG」と名のついたモンスター１体をゲームから除外する事で、フィールド上に表側表示で存在するこのカードをゲームから除外する。\n次のスタンバイフェイズ時、この効果で除外したこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ハルバード・キャノン",h:"てっくじーなす はるばーど・きゃのん",e:"T.G. Halberd Cannon",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター２体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：１ターンに１度、自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nこのカードがフィールドに表側表示で存在する場合、それを無効にし、そのモンスターを破壊する。\n②：このカードがフィールドから墓地へ送られた時、自分の墓地の「TG」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メンタルオーバー・デーモン",h:"めんたるおーばー・でーもん",e:"Overmind Archfiend",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3000,tx:"サイキック族チューナー＋チューナー以外のサイキック族モンスター２体以上\n１ターンに１度、自分の墓地に存在するサイキック族モンスター１体を選択してゲームから除外する事ができる。\nこのカードがフィールド上から墓地へ送られた時、このカードの効果で除外したモンスターを可能な限り自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリムゾン・ヘル・セキュア",h:"くりむぞん・へる・せきゅあ",e:"Scarlet Security",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「レッド・デーモンズ・ドラゴン」が表側表示で存在する場合のみ発動する事ができる。\n相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮魔竜の壺",h:"ぐれんまりゅうのつぼ",e:"Red Dragon Vase",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「レッド・デーモンズ・ドラゴン」が表側表示で存在する場合のみ発動する事ができる。\n自分のデッキからカードを２枚ドローする。\nこのカードを発動する場合、次の相手ターン終了時まで自分はモンスターを召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"コール・リゾネーター",h:"こーる・りぞねーたー",e:"Resonator Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「リゾネーター」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"共鳴破",h:"きょうめいは",e:"Resonant Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、「リゾネーター」と名のついたモンスターがシンクロ素材として墓地へ送られる度に、相手フィールド上に存在するカード１枚を選択して破壊する。\nこのカードは発動後２回目の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"機動要塞フォルテシモ",h:"きどうようさいふぉるてしも",e:"Fortissimo the Mobile Fortress",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分の手札から「機皇兵」と名のついたモンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇帝の賜与",h:"きこうていのしよ",e:"Boon of the Meklord Emperor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスターが「機皇」と名のついたモンスター２体のみの場合に発動する事ができる。\n自分のデッキからカードを２枚ドローする。\nこのカードを発動するターン、自分はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄壁の機皇兵",h:"てっぺきのきこうへい",e:"The Resolute Meklord Army",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側攻撃表示で存在する「機皇兵」と名のついたモンスターの効果は無効化され、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"再機動",h:"さいきどう",e:"Reboot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から「機皇」と名のついたモンスター１体をデッキに戻して発動する。\n自分の墓地に存在する「機皇」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"TGX1－HL",h:"ＴＧＸ１－ＨＬ",e:"TGX1-HL",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「TG」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力・守備力を半分にし、フィールド上に存在する魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"TGX３００",h:"ＴＧＸ３００",e:"TGX300",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「TG」と名のついたモンスター１体につき、自分フィールド上に表側表示で存在するモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"超能力増幅器",h:"さいきっく・ぶーすたー",e:"ESP Amplifier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分フィールド上に表側表示で存在する全てのサイキック族モンスターの攻撃力は、ゲームから除外されている自分のサイキック族モンスターの数×３００ポイントアップする。\nこの効果を受けたモンスターはエンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・フィール・ゾーン",h:"さいこ・ふぃーる・ぞーん",e:"Psychic Feel Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ゲームから除外されている自分のサイキック族のチューナー１体とチューナー以外のサイキック族モンスター１体を墓地に戻し、そのレベルの合計と同じレベルのサイキック族のシンクロモンスター１体をエクストラデッキから表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"紫炎の道場",h:"しえんのどうじょう",e:"Shien's Dojo",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「六武衆」モンスターが召喚・特殊召喚される度に、このカードに武士道カウンターを１つ置く。\n②：武士道カウンターが置かれているこのカードを墓地へ送って発動できる。\nこのカードに置かれていた武士道カウンターの数以下のレベルを持つ、「六武衆」効果モンスターまたは「紫炎」効果モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ大暴走",h:"からくりだいぼうそう",e:"Runaway Karakuri",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「カラクリ」と名のついたモンスター１体を選択して発動する。\nエンドフェイズ時まで、選択したモンスターの攻撃力は１０００ポイントアップし、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"儀水鏡との交信",h:"ぎすいきょうとのこうしん",e:"Contact with the Aquamirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に水属性モンスターが表側表示で存在する場合、以下の効果から１つを選択して発動する。\n自分フィールド上に水属性の儀式モンスターが表側表示で存在する場合、両方を選択して発動する事ができる。\n\n●相手の魔法＆罠カードゾーンにセットされているカードを全て確認して元に戻す。\n\n●自分または相手のデッキの上からカードを２枚確認し、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"完全防音壁",h:"さいれんと・うぉーる",e:"Soundproofed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にシンクロモンスターが表側表示で存在しない場合、メインフェイズ１の開始時に発動する事ができる。\n次の相手のエンドフェイズ時まで、お互いにシンクロモンスターを特殊召喚する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"急転直下",h:"きゅうてんちょっか",e:"Out of the Blue",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手が墓地に存在するカードを対象とする魔法・罠・効果モンスターの効果を発動した場合このカードを破壊する。\nこのカードの効果によってこのカードが破壊され墓地へ送られた時、相手は墓地に存在するカードを全てデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"即神仏",h:"そくしんぶつ",e:"Self-Mummification",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"スカーレッド・カーペット",h:"すかーれっど・かーぺっと",e:"Red Carpet",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドにドラゴン族Sモンスターが存在する場合、自分の墓地の「リゾネーター」モンスターを２体まで対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"パワーアップ・コネクター",h:"ぱわーあっぷ・こねくたー",e:"Power-Up Adapter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に表側表示で存在する「D」と名のついたモンスター１体に装備する。\n装備モンスターは攻撃をする事ができない。\nこの効果で装備された時、装備モンスター以外のフィールド上に表側表示で存在するモンスター１体を選択する。\n選択したモンスターの攻撃力は装備モンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・インフィニティ",h:"かおす・いんふぃにてぃ",e:"Chaos Infinity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの守備表示モンスターを全て表側攻撃表示にする。\nその後、自分のデッキ・墓地から「機皇」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"機限爆弾",h:"きげんばくだん",e:"Mektimed Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「機皇」と名のついたモンスター１体と、相手フィールド上に存在するカード１枚を選択して発動する。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇廠",h:"きこうしょう",e:"Meklord Factory",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する「機皇」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n自分の墓地に存在する「機皇兵」と名のついたモンスター１体を選択して手札に加える。\nその後、攻撃対象となったモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"TGX3－DX2",h:"ＴＧＸ３－ＤＸ２",e:"TGX3-DX2",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「TG」と名のついたモンスター３体を選択して発動する。\n選択したモンスターをデッキに加えてシャッフルする。\nその後、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"TG－SX1",h:"ＴＧ－ＳＸ１",e:"TG-SX1",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「TG」と名のついたモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時に発動する事ができる。\n自分の墓地に存在する「TG」と名のついたシンクロモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"TG1－EM1",h:"ＴＧ１－ＥＭ１",e:"TG1-EM1",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に存在するモンスター１体と、自分フィールド上に表側表示で存在する「TG」と名のついたモンスター１体を選択して発動する。\n選択したモンスターのコントロールを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・リアクター",h:"さいこ・りあくたー",e:"Psychic Reactor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にサイキック族モンスターが表側表示で存在する場合に発動する事ができる。\n自分フィールド上に表側表示で存在するサイキック族モンスターがこのターン相手モンスターと戦闘を行った時、そのサイキック族モンスターと相手モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレインハザード",h:"ぶれいんはざーど",e:"Brain Hazard",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：除外されている自分のサイキック族モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"六武派二刀流",h:"ろくぶはにとうりゅう",e:"Six Style - Dual Wield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが、表側攻撃表示で存在する「六武衆」と名のついたモンスター１体のみの場合に発動する事ができる。\n相手フィールド上に存在するカード２枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"借カラクリ整備蔵",h:"かりからくりせいびくら",e:"Karakuri Cash Shed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「カラクリ」と名のついたモンスターが表側守備表示で存在する場合に発動する事ができる。\n相手が発動した魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暴君の暴力",h:"ぼうくんのぼうりょく",e:"Tyrant's Tantrum",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター２体をリリースして発動する。\nこのカードがフィールド上に存在する限り、相手はデッキから魔法カード１枚を墓地へ送らなければ魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"透破抜き",h:"すっぱぬき",e:"Debunk",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：手札・墓地でモンスターの効果が発動した時に発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"木遁封印式",h:"もくとんふういんしき",e:"Sealing Ceremony of Mokuton",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分フィールド上に表側表示で存在する地属性モンスター１体をリリースする事で、相手の墓地に存在するカードを２枚まで選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"安全地帯",h:"あんぜんちたい",e:"Safe Zone",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドの表側攻撃表示モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、その表側表示モンスターは、相手の効果の対象にならず、戦闘及び相手の効果では破壊されず、相手に直接攻撃できない。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"局地的大ハリケーン",h:"きょくちてきだいはりけーん",e:"Localized Tornado",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札・墓地に存在するカードを全て持ち主のデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"W星雲隕石",h:"だぶるせいうんいんせき",e:"W Nebula Meteorite",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に裏側表示で存在するモンスターを全て表側守備表示にする。\nこのターンのエンドフェイズ時に自分フィールド上に表側表示で存在する爬虫類族・光属性モンスターを全て裏側守備表示にし、その枚数分だけ自分はデッキからカードをドローする。\nその後、自分のデッキからレベル７以上の爬虫類族・光属性モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カタパルト・ウォリアー",h:"かたぱると・うぉりあー",e:"Catapult Warrior",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1000,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分フィールド上に存在する「ジャンク」と名のついたモンスター１体をリリースして発動する。\nリリースしたモンスターの元々の攻撃力分のダメージを相手ライフに与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇神マシニクル∞",h:"きこうしんましにくるいんふぃにてぃ",e:"Meklord Astro Mekanikle",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n手札から「機皇」モンスター３体を墓地へ送った場合のみ特殊召喚できる。\n①：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n②：この攻撃力は自身の効果で装備したモンスターの攻撃力分アップする。\n③：自分スタンバイフェイズに、自身の効果で装備している自分のモンスター１体を墓地へ送って発動できる。\nそのモンスターの攻撃力分のダメージを相手に与える。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ハイパー・ライブラリアン",h:"てっくじーなす　はいぱー・らいぶらりあん",e:"T.G. Hyper Librarian",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがフィールドに存在し、自分または相手が、このカード以外のSモンスターのS召喚に成功した場合に発動する。\nこのカードがフィールドに表側表示で存在する場合、自分はデッキから１枚ドローする。",li:1,lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・ドラゴン",h:"う゛ぁんぱいあ・どらごん",e:"Vampire Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"アドバンス召喚したこのカードがフィールド上から墓地へ送られた時、デッキからレベル４以下のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO ダイアン",h:"ますくどひーろー　だいあん",e:"Masked HERO Dian",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:3000,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキからレベル４以下の「HERO」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"The suppression PLUTO",h:"ざ・さぷれっしょん・ぷるーと",e:"The Suppression Pluto",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"①：１ターンに１度、カード名を１つ宣言して発動できる。\n相手の手札を全て確認し、その中に宣言したカードがあった場合、以下の効果から１つを選んで適用する。\n●相手フィールドのモンスター１体を選んでコントロールを得る。\n●相手フィールドの魔法・罠カード１枚を選んで破壊する。\nその後、破壊したその魔法・罠カードを自分フィールドにセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"The supremacy SUN",h:"ざ・すぷれましー・さん",e:"The Supremacy Sun",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードはこのカードの効果でしか特殊召喚できない。\nフィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた場合、次のターンのスタンバイフェイズ時に手札を１枚捨てる事で、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ズババナイト",h:"ずばばないと",e:"Zubaba Knight",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"①：このカードが表側守備表示モンスターを攻撃するダメージステップ開始時に発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガンバラナイト",h:"がんばらないと",e:"Ganbara Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"フィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハウリング・ウォリアー",h:"はうりんぐ・うぉりあー",e:"Feedback Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:700,tx:"このカードが召喚・特殊召喚に成功した時、自分フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターのレベルは３になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スピッド・バード",h:"すぴっど・ばーど",e:"Speed Bird",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在するレベル２のモンスター２体を選択して特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"鍵戦士キーマン",h:"かぎせんしきーまん",e:"Key Man the Key Warrior",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\nこのターンのエンドフェイズ時まで、このカードのレベルは３になる。",li:"",lk:[],ta:[],al:[]},
  {n:"転身テンシーン",h:"てんしんてんしーん",e:"Tenshin",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"このカードの攻撃力は、自分フィールド上に表側表示で存在するレベル２モンスターの数×４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"シャインナイト",h:"しゃいんないと",e:"Shine Knight",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:1900,tx:"このカードがフィールド上に表側守備表示で存在する限り、このカードのレベルは４になる。",li:"",lk:[],ta:[],al:[]},
  {n:"破天荒な風",h:"はてんこうなかぜ",e:"Blustering Winds",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力・守備力は、次の自分のスタンバイフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"波動共鳴",h:"はどうきょうめい",e:"Harmonic Waves",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターのレベルはエンドフェイズ時まで４になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズエナジー",h:"えくしーずえなじー",e:"Xyz Energy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"強化空間",h:"きょうかくうかん",e:"Reinforced Space",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する全てのエクシーズモンスターの攻撃力は、エンドフェイズ時までそのモンスターのエクシーズ素材１つにつき３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズエフェクト",h:"えくしーずえふぇくと",e:"Xyz Effect",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分がエクシーズ召喚に成功した時、フィールド上のカード１枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.39 希望皇ホープ",h:"なんばーず３９　きぼうおうほーぷ",e:"Number 39: Utopia",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル４モンスター×２\n①：自分または相手のモンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\nその攻撃を無効にする。\n②：このカードがX素材の無い状態で攻撃対象に選択された場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガチガチガンテツ",h:"がちがちがんてつ",e:"Gachi Gachi Gantetsu",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:1800,tx:"レベル２モンスター×２\nフィールド上のこのカードが破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のモンスターの攻撃力・守備力は、このカードのエクシーズ素材の数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"グレンザウルス",h:"ぐれんざうるす",e:"Grenosaurus",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1900,tx:"レベル３モンスター×２\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブーテン",h:"ぶーてん",e:"Buten",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:300,tx:"自分のメインフェイズ時に、墓地のこのカードをゲームから除外し、自分フィールド上のレベル４以下の天使族・光属性モンスター１体を選択して発動できる。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・ドーナツ",h:"です・どーなつ",e:"Doom Donuts",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:0,tx:"リバース：フィールド上に表側表示で存在する元々の攻撃力または元々の守備力が０のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"素早いマンタ",h:"すばやいまんた",e:"Nimble Manta",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"フィールド上のこのカードがカードの効果によって墓地へ送られた時、デッキから「素早いマンタ」を任意の数だけ特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"深海の怒り",h:"れいじ・おぶ・でぃーぷしー",e:"Rage of the Deep Sea",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃力・守備力は、自分の墓地の魚族・海竜族・水族モンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・オブシディア",h:"じぇむないと・おぶしでぃあ",e:"Gem-Knight Obsidian",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：このカードが手札から墓地へ送られた場合、自分の墓地のレベル４以下の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・アイオーラ",h:"じぇむないと・あいおーら",e:"Gem-Knight Iolite",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:1300,de:2000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分の墓地の「ジェム」と名のついたモンスター１体をゲームから除外して発動できる。\n自分の墓地の「ジェムナイト」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・アンバー",h:"じぇむないと・あんばー",e:"Gem-Knight Amber",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["デュアル","効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、手札から「ジェムナイト」と名のついたカード１枚を墓地へ送って発動できる。\nゲームから除外されている自分のモンスター１体を選択して手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル炎湖畔の淑女",h:"らう゛ぁるえんこはんのしゅくじょ",e:"Laval Lakeside Lady",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、自分の墓地のこのカードと「ラヴァル」と名のついたモンスター１体をゲームから除外して発動できる。\n相手フィールド上にセットされたカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・コアトル",h:"らう゛ぁる・こあとる",e:"Laval Coatl",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:700,tx:"自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・ガンナー",h:"らう゛ぁる・がんなー",e:"Laval Blaster",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが召喚に成功した時、自分の墓地に「ラヴァル・ガンナー」以外の「ラヴァル」と名のついたモンスターが存在する場合、デッキの上からカードを５枚まで墓地へ送って発動できる。\nこのカードの攻撃力は、この効果を発動するために墓地へ送った「ラヴァル」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・ペンタクロ",h:"う゛ぁいろん・ぺんたくろ",e:"Vylon Pentachloro",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:400,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の「ヴァイロン」と名のついたモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、相手フィールド上のカード１枚を選択して破壊できる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・テセラクト",h:"う゛ぁいろん・てせらくと",e:"Vylon Tesseract",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:800,de:600,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の「ヴァイロン」と名のついたモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、自分の墓地からレベル４以下の「ヴァイロン」と名のついたモンスター１体を選択して特殊召喚できる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・スティグマ",h:"う゛ぁいろん・すてぃぐま",e:"Vylon Stigma",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、自分フィールド上の「ヴァイロン」と名のついた装備カード１枚を、別の正しい対象となるモンスター１体に移し替える事ができる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴィジョン・リチュア",h:"う゛ぃじょん・りちゅあ",e:"Gishki Vision",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"水属性の儀式モンスターを特殊召喚する場合、このカード１枚で儀式召喚のためのリリースとして使用できる。\nまた、手札からこのカードを捨てて発動できる。\nデッキから「リチュア」と名のついた儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・エミリア",h:"りちゅあ・えみりあ",e:"Gishki Emilia",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、自分フィールド上にこのカード以外の「リチュア」と名のついたモンスターが存在する場合、エンドフェイズ時までフィールド上の罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・シェルフィッシュ",h:"りちゅあ・しぇるふぃっしゅ",e:"Gishki Mollusk",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"このカードがカードの効果によって墓地へ送られた時、自分のデッキの上からカードを３枚確認し、確認した３枚を好きな順番でデッキの上か下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・ファルコ",h:"がすた・ふぁるこ",e:"Gusto Falco",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1400,tx:"フィールド上に表側表示で存在するこのカードが戦闘以外によって墓地へ送られた時、デッキから「ガスタ」と名のついたモンスター１体を裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの希望 カムイ",h:"がすたのきぼう　かむい",e:"Kamui, Hope of Gusto",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:1000,tx:"リバース：デッキから「ガスタ」と名のついたチューナー１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの神官 ムスト",h:"がすたのしんかん　むすと",e:"Musto, Oracle of Gusto",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分の墓地の「ガスタ」と名のついたモンスター１体を選択してデッキに戻し、フィールド上に表側表示で存在するモンスター１体を選択してその効果をエンドフェイズ時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・ガストクラーケ",h:"いびりちゅあ・がすとくらーけ",e:"Evigishki Gustkraken",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:1000,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\nこのカードが儀式召喚に成功した時、相手の手札をランダムに２枚まで確認し、その中から１枚を選んで持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・アメジス",h:"じぇむないと・あめじす",e:"Gem-Knight Amethyst",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:1950,de:2450,tx:"「ジェムナイト」モンスター＋水族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nフィールドにセットされた魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルバル・ドラグーン",h:"らう゛ぁるばる・どらぐーん",e:"Lavalval Dragun",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「ラヴァル」モンスター１体を手札に加える。\nその後、手札から「ラヴァル」モンスター１体を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイガスタ・ファルコス",h:"だいがすた・ふぁるこす",e:"Daigusto Falcos",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:1400,de:1200,tx:"チューナー＋チューナー以外の「ガスタ」と名のついたモンスター１体以上\nこのカードがシンクロ召喚に成功した時、フィールド上の全ての「ガスタ」と名のついたモンスターの攻撃力は６００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・パール",h:"じぇむないと・ぱーる",e:"Gem-Knight Pearl",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ"],ma:"",tp:"",at:2600,de:1900,tx:"レベル４モンスター×２",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルバル・イグニス",h:"らう゛ぁるばる・いぐにす",e:"Lavalval Ignis",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1400,tx:"レベル３モンスター×２\nこのカードが戦闘を行うダメージステップ時に１度だけ、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・ディシグマ",h:"う゛ぁいろん・でぃしぐま",e:"Vylon Disigma",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上に表側攻撃表示で存在する効果モンスター１体を選択し、装備カード扱いとしてこのカードに装備する。\nこのカードが、この効果で装備したモンスターカードと同じ属性のモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・メロウガイスト",h:"いびりちゅあ・めろうがいすと",e:"Evigishki Merrowgeist",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1600,tx:"レベル４モンスター×２\nこのカードが戦闘によって相手モンスターを破壊した場合、そのダメージ計算後にこのカードのエクシーズ素材を１つ取り除いて発動できる。\n破壊したそのモンスターを墓地へ送らず持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイガスタ・フェニクス",h:"だいがすた・ふぇにくす",e:"Daigusto Phoenix",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"炎族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1100,tx:"レベル２モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上の風属性モンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"パーティカル・フュージョン",h:"ぱーてぃかる・ふゅーじょん",e:"Particle Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上から、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「ジェムナイト」と名のついたその融合モンスター１体を融合召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果で融合召喚に成功した時、墓地のこのカードをゲームから除外し、その融合召喚に使用した「ジェムナイト」と名のついた融合素材モンスター１体を選択して発動する。\nその融合モンスターの攻撃力はエンドフェイズ時まで、選択したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・ポリトープ",h:"う゛ぁいろん・ぽりとーぷ",e:"Vylon Polytope",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の装備カード扱いの「ヴァイロン」と名のついたモンスターを任意の枚数選択して発動できる。\n選択したカードを表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・セグメント",h:"う゛ぁいろん・せぐめんと",e:"Vylon Segment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターは相手の罠・効果モンスターの効果の対象にならない。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、デッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎塵爆発",h:"えんじんばくはつ",e:"Dustflame Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「ラヴァル」と名のついたモンスターを全てゲームから除外して発動できる。\nこのカードを発動するために除外したモンスターの数まで、フィールド上のカードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"儀水鏡の幻影術",h:"ぎすいきょうのげんえいじゅつ",e:"Aquamirror Illusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札から「リチュア」と名のついた儀式モンスター１体を特殊召喚する。\nこの効果で特殊召喚した儀式モンスターは攻撃できず、エンドフェイズ時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタのつむじ風",h:"がすたのつむじかぜ",e:"Whirlwind of Gusto",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合に発動できる。\n自分の墓地の「ガスタ」と名のついたモンスター２体を選択してデッキに戻す。\nその後、デッキから守備力１０００以下の「ガスタ」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴェルズ・ローチ",h:"いんう゛ぇるず・ろーち",e:"Steelswarm Roach",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:0,tx:"レベル４モンスター×２\nこのカードのエクシーズ素材を１つ取り除く事で、レベル５以上のモンスターの特殊召喚を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガマジシャン",h:"がががまじしゃん",e:"Gagaga Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードはS素材にできない。\n①：「ガガガマジシャン」は自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、１～８までの任意のレベルを宣言して発動できる。\nこのカードのレベルはターン終了時まで宣言したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴゴーレム",h:"ごごごごーれむ",e:"Gogogo Golem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：守備表示のこのカードは１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"アチャチャアーチャー",h:"あちゃちゃあーちゃー",e:"Achacha Archer",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが召喚・反転召喚に成功した時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリンドバーグ",h:"ごぶりんどばーぐ",e:"Goblindbergh",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下のモンスター１体を特殊召喚する。\nこの効果を発動した場合、このカードは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグ・ジョーズ",h:"びっぐ・じょーず",e:"Big Jaws",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"このカードは攻撃した場合、バトルフェイズ終了時にゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"スカル・クラーケン",h:"すかる・くらーけん",e:"Skull Kraken",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:600,de:1600,tx:"このカードが召喚に成功した時、相手フィールド上に表側表示で存在する魔法カード１枚を選択して破壊する事ができる。\n１ターンに１度、このカードの表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドリル・バーニカル",h:"どりる・ばーにかる",e:"Drill Barnacle",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:0,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与える度に、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジョーズマン",h:"じょーずまん",e:"Jawsman",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:1600,tx:"このカードは特殊召喚できない。\nこのカードをアドバンス召喚する場合、リリースするモンスターは水属性モンスターでなければならない。\nこのカードの攻撃力は、このカード以外の自分フィールド上に表側表示で存在する水属性モンスター１体につき３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"バグマンX",h:"ばぐまんえっくす",e:"Crashbug X",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードが召喚に成功した時、自分フィールド上に「バグマンY」が表側表示で存在する場合、自分のデッキから「バグマンZ」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バグマンY",h:"ばぐまんわい",e:"Crashbug Y",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが召喚に成功した時、自分フィールド上に「バグマンZ」が表側表示で存在する場合、自分のデッキから「バグマンX」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"バグマンZ",h:"ばぐまんぜっと",e:"Crashbug Z",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"このカードが召喚に成功した時、自分フィールド上に「バグマンX」が表側表示で存在する場合、自分のデッキから「バグマンY」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパーバグマン",h:"すーぱーばぐまん",e:"Super Crashbug",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:3000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する「バグマンX」「バグマンY」「バグマンZ」をゲームから除外した場合に表側守備表示で特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、フィールド上に表側攻撃表示で存在する全てのモンスターの攻撃力・守備力を入れ替える。\n「スーパーバグマン」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイソルジャー",h:"ぜんまいそるじゃー",e:"Wind-Up Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分のメインフェイズ時に発動する事ができる。\nエンドフェイズ時までこのカードのレベルを１つ上げ、攻撃力を４００ポイントアップする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイマジシャン",h:"ぜんまいまじしゃん",e:"Wind-Up Magician",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:1800,tx:"「ゼンマイマジシャン」以外の「ゼンマイ」と名のついたモンスターの効果が発動した場合、自分のデッキから「ゼンマイ」と名のついたレベル４以下のモンスター１体を表側守備表示で特殊召喚する事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイジャグラー",h:"ぜんまいじゃぐらー",e:"Wind-Up Juggler",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが相手モンスターと戦闘を行った場合、その相手モンスターをダメージ計算後に破壊する事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイドッグ",h:"ぜんまいどっぐ",e:"Wind-Up Dog",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"自分のメインフェイズ時に発動する事ができる。\nエンドフェイズ時までこのカードのレベルを２つ上げ、攻撃力を６００ポイントアップする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイマイ",h:"ぜんまいまい",e:"Wind-Up Snail",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"自分のメインフェイズ時、フィールド上にセットされたカード１枚を選択して持ち主の手札に戻す事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スピアフィッシュソルジャー",h:"すぴあふぃっしゅそるじゃー",e:"Spearfish Soldier",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードの攻撃力は、ゲームから除外されている自分の魚族・海竜族・水族モンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"フライファング",h:"ふらいふぁんぐ",e:"Flyfang",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードが相手ライフに戦闘ダメージを与えたバトルフェイズ終了時、このカードを次の自分のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"スカイオニヒトクイエイ",h:"すかいおにひとくいえい",e:"Skystarray",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:600,de:300,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃を行ったバトルフェイズ終了時、このカードを次の自分のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"エアジャチ",h:"えあじゃち",e:"Airorca",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:300,tx:"１ターンに１度、手札から魚族・海竜族・水族モンスター１体をゲームから除外する事で、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。\nその後、このカードを次の自分のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウイングトータス",h:"ういんぐとーたす",e:"Wingtortoise",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:1400,tx:"自分フィールド上に表側表示で存在する魚族・海竜族・水族モンスターがゲームから除外された時、このカードを手札または自分の墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スペースタイムポリス",h:"すぺーすたいむぽりす",e:"Space-Time Police",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"このカードが特殊召喚に成功した時、相手フィールド上に表側表示で存在するカード１枚を選択してゲームから除外する。\nこのカードがフィールド上から離れた時、このカードの効果でゲームから除外したカードを相手フィールド上にセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"タイム・エスケーパー",h:"たいむ・えすけーぱー",e:"Time Escaper",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:500,de:100,tx:"このカードを手札から捨て、自分フィールド上に表側表示で存在するサイキック族モンスター１体を選択して発動する。\n選択したモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムエレファント",h:"じぇむえれふぁんと",e:"Gem-Elephant",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:400,de:1900,tx:"自分のメインフェイズ時に、フィールド上に表側表示で存在するこのカードを手札に戻す事ができる。\nまた、このカードが戦闘を行うダメージ計算時に１度だけ、手札から通常モンスター１体を墓地へ送って発動する事ができる。\nこのカードの守備力は、そのダメージ計算時のみ１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルのマグマ砲兵",h:"らう゛ぁるのまぐまほうへい",e:"Laval Magma Cannoneer",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"手札から炎属性モンスター１体を墓地へ送って発動する。\n相手ライフに５００ポイントダメージを与える。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・ディバイナー",h:"りちゅあ・でぃばいなー",e:"Gishki Diviner",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"１ターンに１度、カード名を１つ宣言して発動する事ができる。\n自分のデッキの一番上のカードをめくり、それが宣言したカードだった場合手札に加える。\n違った場合、自分のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・コドル",h:"がすた・こどる",e:"Gusto Codor",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:400,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキから守備力１５００以下のサイキック族・風属性モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚師セームベル",h:"しょうかんしせーむべる",e:"Saambell the Summoner",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:400,tx:"自分のメインフェイズ時、このカードと同じレベルのモンスター１体を手札から特殊召喚する事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアーノ",h:"ぎあぎあーの",e:"Geargiano",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカードをリリースし、自分の墓地の機械族・レベル４モンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ポケ・ドラ",h:"ぽけ・どら",e:"Poki Draco",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"このカードが召喚に成功した時、自分のデッキから「ポケ・ドラ」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"龍炎剣の使い手",h:"りゅうえんけんのつかいて",e:"Master of the Flaming Dragonswords",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上に「龍炎剣の使い手」以外のモンスターが召喚された時、そのモンスターのレベルを１つ上げ、このカードの攻撃力をエンドフェイズ時まで３００ポイントアップする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄の傀儡魔人",h:"じごくのくぐつまじん",e:"Perditious Puppeteer",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2700,de:500,tx:"１ターンに１度、手札を１枚捨てる事で、相手フィールド上に表側表示で存在する全てのレベル３以下のモンスターのコントロールをエンドフェイズ時まで得る。\nこの効果でコントロールを得たモンスターは効果を発動する事ができず、リリースやシンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"蒼血鬼",h:"そうけつき",e:"Blue-Blooded Oni",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1700,tx:"このカードは召喚・反転召喚に成功した時、守備表示になる。\n１ターンに１度、自分フィールド上に存在するエクシーズ素材を１つ取り除く事で、自分の墓地に存在するレベル４のアンデット族モンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂の護送船",h:"そうる・こんう゛ぉい",e:"Ghost Ship",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する光属性モンスター１体をゲームから除外した場合に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"レアル・クルセイダー",h:"れある・くるせいだー",e:"Absolute Crusader",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"レベル５以上のモンスターが特殊召喚に成功した時、フィールド上に表側表示で存在するこのカードをリリースして発動しなければならない。\nそのレベル５以上のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"大皇帝ペンギン",h:"だいこうていぺんぎん",e:"The Great Emperor Penguin",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：このカードをリリースして発動できる。\nデッキから「大皇帝ペンギン」以外の「ペンギン」モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"久遠の魔術師ミラ",h:"くおんのまじゅつしみら",e:"Milla the Temporal Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが召喚に成功した場合、相手フィールドの裏側表示のカード１枚を対象として発動する。\nその相手のカードを確認する。\nこの効果の発動に対して、相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.17 リバイス・ドラゴン",h:"なんばーず１７　りばいす・どらごん",e:"Number 17: Leviathan Dragon",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:0,tx:"レベル３モンスター×２\n①：X素材が無いこのカードは直接攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"潜航母艦エアロ・シャーク",h:"せんこうぼかんえあろ・しゃーく",e:"Submersible Carrier Aero Shark",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1000,tx:"レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nゲームから除外されている自分のモンスターの数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.34 電算機獣テラ・バイト",h:"なんばーず３４　でんさんきじゅうてら・ばいと",e:"Number 34: Terror-Byte",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2900,tx:"レベル３モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのレベル４以下の攻撃表示モンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"発条機甲ゼンマイスター",h:"ぜんまいきこうぜんまいすたー",e:"Wind-Up Zenmaister",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1500,tx:"レベル４モンスター×２\nこのカードの攻撃力は、このカードのエクシーズ素材の数×３００ポイントアップする。\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを裏側守備表示にする。\nこのターンのエンドフェイズ時、選択したモンスターは表側攻撃表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"虚空海竜リヴァイエール",h:"こくうかいりゅうりう゛ぁいえーる",e:"Leviair the Sea Dragon",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1600,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、除外されている自分または相手のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"始祖の守護者ティラス",h:"しそのしゅごしゃてぃらす",e:"Tiras, Keeper of Genesis",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1700,tx:"レベル５モンスター×２\nこのカードの効果はこのカードのエクシーズ素材がなければ適用されない。\nこのカードはカードの効果では破壊されない。\nこのカードが戦闘を行ったバトルフェイズ終了時、相手フィールド上に存在するカード１枚を選択して破壊する。\n自分のエンドフェイズ毎にこのカードのエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンダー・ワンド",h:"わんだー・わんど",e:"Wonder Wand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"魔法使い族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は５００アップする。\n②：装備モンスターとこのカードを自分フィールドから墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブル・アップ・チャンス",h:"だぶる・あっぷ・ちゃんす",e:"Double or Nothing!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：モンスターの攻撃が無効になった時、そのモンスター１体を対象として発動できる。\nこのバトルフェイズ中、そのモンスターはもう１度だけ攻撃できる。\nこの効果でそのモンスターが攻撃するダメージステップの間、そのモンスターの攻撃力は倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ショート",h:"さんだー・しょーと",e:"Thunder Short",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に存在するモンスター１体につき、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アクア・ジェット",h:"あくあ・じぇっと",e:"Aqua Jet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の魚族・海竜族・水族モンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"浮上",h:"ふじょう",e:"Surface",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のレベル３以下の魚族・海竜族・水族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バグ・ロード",h:"ばぐ・ろーど",e:"Crashbug Road",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"お互いに自分フィールド上に表側表示で存在するレベル４以下のモンスター１体と同じレベルのモンスター１体を手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィルスメール",h:"うぃるすめーる",e:"Infected Mail",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分フィールド上に表側表示で存在するレベル４以下のモンスター１体を選択して発動する事ができる。\nこのターン、選択したモンスターは相手プレイヤーに直接攻撃する事ができる。\nそのモンスターはバトルフェイズ終了時に墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"クラッキング",h:"くらっきんぐ",e:"Cracking",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するモンスターがカードの効果によって墓地へ送られた時、そのモンスターの元々の持ち主に８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説のゼンマイ",h:"でんせつのぜんまい",e:"Legendary Wind-Up Key",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイマニュファクチャ",h:"ぜんまいまにゅふぁくちゃ",e:"Wind-Up Factory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「ゼンマイ」と名のついたモンスターの効果が発動した場合、自分のデッキからレベル４以下の「ゼンマイ」と名のついたモンスター１体を手札に加える事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュアンドキックス",h:"ふぃっしゅあんどきっくす",e:"Fish and Kicks",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ゲームから除外されている自分の魚族・海竜族・水族モンスターが３体以上の場合、フィールド上に存在するカード１枚を選択して発動する。\n選択したカードをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"フューチャー・グロウ",h:"ふゅーちゃー・ぐろう",e:"Future Glow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するサイキック族モンスター１体をゲームから除外して発動する。\nこのカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する全てのサイキック族モンスターの攻撃力は、このカードを発動するために除外したモンスターのレベル×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァイロン・フィラメント",h:"う゛ぁいろん・ふぃらめんと",e:"Vylon Filament",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、自分のデッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガルドスの羽根ペン",h:"がるどすのはねぺん",e:"Quill Pen of Gulldos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する風属性モンスター２体を選択してデッキに戻し、フィールド上に存在するカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"スター・チェンジャー",h:"すたー・ちぇんじゃー",e:"Star Changer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択し、以下の効果から１つを選択して発動できる。\n\n●そのモンスターのレベルを１つ上げる。\n\n●そのモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"鬼神の連撃",h:"きしんのれんげき",e:"Oni-Gami Combo",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するエクシーズモンスター１体を選択し、そのエクシーズ素材を全て取り除いて発動する。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"共振装置",h:"きょうしんそうち",e:"Resonance Device",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する同じ種族・属性のモンスター２体を選択して発動する。\n選択したモンスター１体のレベルはエンドフェイズ時まで、もう１体のモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"盗み見ゴブリン",h:"ぬすみみごぶりん",e:"Peeking Goblin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手はデッキの上からカードを３枚めくる。\n自分はその中から１枚を選択して相手のデッキの一番下に戻し、残りのカードを好きな順番で相手のデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"油断大敵",h:"ゆだんたいてき",e:"Asleep at the Switch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のライフポイントが回復した時に発動する事ができる。\n相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ポセイドン・ウェーブ",h:"ぽせいどん・うぇーぶ",e:"Poseidon Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にする。\n自分フィールド上に魚族・海竜族・水族モンスターが表側表示で存在する場合、その数×８００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"爆弾ウニ－ボム・アーチン－",h:"ばくだんうに－ぼむ・あーちん－",e:"Explosive Urchin",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手が罠カードを発動した時に発動する事ができる。\n自分のスタンバイフェイズ時、相手フィールド上に罠カードが表側表示で存在する場合、相手ライフに１０００ポイントダメージを与える。\n発動後３回目の自分のエンドフェイズ時にこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・ワクチンΩMAX",h:"だめーじ・わくちんおめがまっくす",e:"Damage Vaccine Ω MAX",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘またはカードの効果によってダメージを受けた時に発動する事ができる。\n自分が受けたそのダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバー・レンチ",h:"おーばー・れんち",e:"Overwind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力・守備力は倍になり、このターンのエンドフェイズ時に手札に戻る。\n「オーバー・レンチ」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔製産卵床",h:"ませいさんらんしょう",e:"Underworld Egg Clutch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する魚族・海竜族・水族モンスターがゲームから除外された時に発動する事ができる。\n自分のデッキからレベル４以下の魚族・海竜族・水族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ギョッ！",h:"ぎょっ！",e:"Oh F!sh!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ゲームから除外されている自分の魚族・海竜族・水族モンスター１体をデッキに戻して発動する。\n効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブライト・フューチャー",h:"ぶらいと・ふゅーちゃー",e:"Bright Future",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ゲームから除外されている自分のサイキック族モンスター２体を選択して発動する。\n選択したモンスターを墓地に戻し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"パスト・イメージ",h:"ぱすと・いめーじ",e:"Past Image",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にサイキック族モンスターが表側表示で存在する場合、相手フィールド上に存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外する。\nこの効果で除外したモンスターは、次のスタンバイフェイズ時に同じ表示形式で相手フィールド上に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"炎渦の胎動",h:"えんかのたいどう",e:"Burgeoning Whirlflame",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から「ラヴァル」と名のついたモンスター１体を墓地へ送って発動する。\n罠カードの発動を無効にし破壊する。\nまた、このカードが墓地に存在する場合、自分の墓地に存在する炎属性モンスター２体をゲームから除外する事で、このカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"同姓同名同盟条約",h:"どうせいどうめいどうめいじょうやく",e:"Treaty on Uniform Nomenclature",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にトークン以外の同名モンスターが表側表示で２体以上存在する場合に発動する事ができる。\nその同名モンスターの数によって以下の効果を適用する。\n\n●２体：相手フィールド上に存在する魔法・罠カード１枚を破壊する。\n\n●３体：相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"皇の波動",h:"おうのはどう",e:"Utopian Aura",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズ素材を１つ取り除いて発動できる。\n自分フィールド上の全てのエクシーズモンスターは、エンドフェイズ時までカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"共同戦線",h:"きょうどうせんせん",e:"United Front",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に同じレベルのモンスターが表側表示で２体以上存在する場合に発動する事ができる。\n罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"五稜星の呪縛",h:"ごりょうせいのじゅばく",e:"Curse of the Circle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に存在するモンスター１体を選択して発動する。\n相手は選択したモンスターをリリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"暴君の暴飲暴食",h:"ぼうくんのぼういんぼうしょく",e:"Tyrant's Tummyache",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体をリリースして発動する。\nこのカードがフィールド上に存在する限り、お互いにレベル６以上のモンスターを特殊召喚する事はできない。\n自分の手札が３枚以上の場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"統制訓練",h:"とうせいくんれん",e:"Attention!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側表示で存在するレベル５以下のモンスター１体を選択して発動する。\nフィールド上に表側表示で存在する、それ以外のレベルを持つモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ボトル",h:"さんだー・ぼとる",e:"Raigeki Bottle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが攻撃宣言をする度に、このカードに雷カウンターを１つ置く。\n雷カウンターが４つ以上乗っているこのカードを墓地へ送る事で、相手フィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"砂利ケーン",h:"じゃりけーん",e:"Gravelstorm",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分及び相手フィールド上に存在する魔法・罠カードを１枚ずつ選択して発動する。\n選択したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神メタイオン",h:"じかいしんめたいおん",e:"Metaion, the Timelord",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、攻撃表示のこのカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\nこのカード以外のフィールドのモンスターを全て持ち主の手札に戻し、戻した数×３００ダメージを相手に与える。\n④：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"輪廻天狗",h:"りんねてんぐ",e:"Reborn Tengu",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"①：表側表示のこのカードがフィールドから離れた場合に発動する。\nデッキから「輪廻天狗」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アボイド・ドラゴン",h:"あぼいど・どらごん",e:"Dodger Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードの召喚は無効化されない。\nこのカードが召喚に成功したターン、相手はカウンター罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"極星霊スヴァルトアールヴ",h:"きょくせいれいすう゛ぁるとあーるう゛",e:"Mara of the Nordic Alfar",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードをシンクロ素材とする場合、他のシンクロ素材モンスターは手札の「極星」と名のついたモンスター２体でなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界発現世行きデスガイド",h:"まかいはつげんせゆきですがいど",e:"Tour Guide From the Underworld",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"①：このカードが召喚に成功した時に発動できる。\n手札・デッキから悪魔族・レベル３モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは効果が無効化され、S素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイキック・ビースト",h:"さいきっく・びーすと",e:"Psi-Beast",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"このカードが召喚に成功した時、自分のデッキのサイキック族モンスター１体をゲームから除外できる。\nこのカードはこの効果で除外したモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣エセダリ",h:"ぐらでぃあるびーすとえせだり",e:"Gladiator Beast Essedarii",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1400,tx:"「剣闘獣」と名のついたモンスター×２\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘調教",h:"ぐらでぃあるていむ",e:"Gladiator Taming",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に「剣闘獣」と名のついたモンスターが存在する場合、以下の効果から１つを選択して発動できる。\n\n●相手フィールド上に表側表示で存在するモンスター１体を選択し、表示形式を変更する。\n\n●相手フィールド上の「剣闘獣」と名のついたモンスター１体を選択し、エンドフェイズ時までコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"フルハウス",h:"ふるはうす",e:"Full House",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード以外のフィールド上に表側表示で存在する魔法・罠カード２枚と、セットされた魔法・罠カード３枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ショックウェーブ",h:"さいこ・しょっくうぇーぶ",e:"Psychic Shockwave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が罠カードを発動した時、手札から魔法・罠カード１枚を捨てて発動できる。\nデッキから機械族・闇属性・レベル６モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"究極時械神セフィロン",h:"きゅうきょくじかいしんせふぃろん",e:"Sephylon, the Ultimate Timelord",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分の墓地にモンスターが１０体以上存在する場合のみ特殊召喚する事ができる。\n１ターンに１度、レベル８以上の天使族モンスター１体を自分の手札・墓地から特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化され、攻撃力は４０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"シューティング・クェーサー・ドラゴン",h:"しゅーてぃんぐ・くぇーさー・どらごん",e:"Shooting Quasar Dragon",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター２体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：このカードは、そのS素材としたモンスターの内、チューナー以外のモンスターの数まで１度のバトルフェイズ中に攻撃できる。\n②：１ターンに１度、魔法・罠・モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n③：表側表示のこのカードがフィールドから離れた時に発動できる。\nEXデッキから「シューティング・スター・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ナイト",h:"いんふぇるにてぃ・ないと",e:"Infernity Knight",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"フィールド上に存在するこのカードが破壊され墓地へ送られた時、手札を２枚捨てる事でこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"茫漠の死者",h:"ぼうばくのししゃ",e:"Endless Decay",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"①：自分のLPが２０００以下の場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカードの攻撃力は相手のLPの半分の数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の龍神 グラファ",h:"あんこくかいのりゅうしん　ぐらふぁ",e:"Grapha, Dragon Lord of Dark World",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2700,de:1800,tx:"このカードは「暗黒界の龍神 グラファ」以外の自分フィールド上に表側表示で存在する「暗黒界」と名のついたモンスター１体を手札に戻し、墓地から特殊召喚する事ができる。\nこのカードがカードの効果によって手札から墓地へ捨てられた場合、相手フィールド上に存在するカード１枚を選択して破壊する。\n相手のカードの効果によって捨てられた場合、さらに相手の手札をランダムに１枚確認する。\n確認したカードがモンスターだった場合、そのモンスターを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の術師 スノウ",h:"あんこくかいのじゅつし　すのう",e:"Snoww, Unlight of Dark World",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、自分のデッキから「暗黒界」と名のついたカード１枚を手札に加える。\n相手のカードの効果によって捨てられた場合、さらに相手の墓地に存在するモンスター１体を選択し、自分フィールド上に表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の導師 セルリ",h:"あんこくかいのどうし　せるり",e:"Ceruli, Guru of Dark World",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを相手フィールド上に表側守備表示で特殊召喚する。\nこのカードが「暗黒界」と名のついたカードの効果によって特殊召喚に成功した時、相手は手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の門",h:"あんこくかいのもん",e:"The Gates of Dark World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する悪魔族モンスターの攻撃力・守備力は３００ポイントアップする。\n１ターンに１度、自分の墓地に存在する悪魔族モンスター１体をゲームから除外する事で、手札から悪魔族モンスター１体を選択して捨てる。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の謀略",h:"あんこくのぼうりゃく",e:"Dark Scheme",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いのプレイヤーは手札を２枚選択して捨て、デッキからカードを２枚ドローする。\n相手は手札を１枚捨ててこのカードの効果を無効にする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影王 ハイド・ライド",h:"げんえいおう　はいど・らいど",e:"Phantom King Hydride",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:300,tx:"自分フィールド上に表側表示で存在するこのカードをシンクロ召喚に使用する場合、このカードをチューナー以外のモンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"天狼王 ブルー・セイリオス",h:"てんろうおう　ぶるー・せいりおす",e:"Celestial Wolf Lord, Blue Sirius",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nフィールド上のこのカードが破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は２４００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"バニーラ",h:"ばにーら",e:"Bunilla",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:150,de:2050,tx:"甘いものがとっても大好きな甘党うさぎ。\n世界一甘いと言われる甘糖人参を探し求め、今日も明日もニンジンをかじりたい。",li:"",lk:[],ta:[],al:[]},
  {n:"ラビードラゴン",h:"らびーどらごん",e:"Rabidragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2950,de:2900,tx:"雪原に生息するドラゴンの突然変異種。\n巨大な耳は数キロ離れた物音を聴き分け、驚異的な跳躍力と相俟って狙った獲物は逃さない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライライダー",h:"らいらいだー",e:"Rai Rider",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"このカードが相手モンスターと戦闘を行った場合、そのモンスターはフィールド上に表側表示で存在する限り攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"針剣士",h:"はりけんし",e:"Stinging Swordsman",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手の魔法＆罠カードゾーンに表側表示で存在するカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"カゲトカゲ",h:"かげとかげ",e:"Kagetokage",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1100,de:1500,tx:"このカードは通常召喚できず、このカードの効果で特殊召喚できる。\nこのカードはS素材にできない。\n①：自分がレベル４モンスターの召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コロボックリ",h:"ころぼっくり",e:"Acorno",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"自分のメインフェイズ時に発動する事ができる。\n手札から「マツボックル」１体を墓地へ送り、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マツボックル",h:"まつぼっくる",e:"Pinecono",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"このカードが「コロボックリ」の効果によって墓地へ送られた場合、このカードを墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"キラー・ラブカ",h:"きらー・らぶか",e:"Friller Rabca",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:700,de:1500,tx:"自分フィールド上の魚族・海竜族・水族モンスターが攻撃対象に選択された時、墓地のこのカードをゲームから除外し、攻撃モンスター１体を選択して発動できる。\n選択したモンスターの攻撃を無効にし、その攻撃力を次の自分のエンドフェイズ時まで５００ポイントダウンする。\n「キラー・ラブカ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シャーク・サッカー",h:"しゃーく・さっかー",e:"Shark Stickers",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:200,de:1000,tx:"自分フィールド上に魚族・海竜族・水族モンスターが召喚・特殊召喚された時、このカードを手札から特殊召喚する事ができる。\nこのカードはシンクロ素材とする事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハリマンボウ",h:"はりまんぼう",e:"Needle Sunfish",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:100,tx:"このカードが墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河眼の光子竜",h:"ぎゃらくしーあいず・ふぉとん・どらごん",e:"Galaxy-Eyes Photon Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"①：このカードは自分フィールドの攻撃力２０００以上のモンスター２体をリリースして手札から特殊召喚できる。\n②：このカードが相手モンスターと戦闘を行うバトルステップに、その相手モンスター１体を対象として発動できる。\nその相手モンスターとフィールドのこのカードを除外する。\nこの効果で除外したモンスターはバトルフェイズ終了時にフィールドに戻り、この効果でXモンスターを除外した場合、このカードの攻撃力は、そのXモンスターを除外した時のX素材の数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デイブレーカー",h:"でいぶれーかー",e:"Daybreaker",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが特殊召喚に成功した時、手札から「デイブレーカー」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライト・サーペント",h:"らいと・さーぺんと",e:"Lightserpent",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが手札から墓地へ送られた場合、このカードを墓地から特殊召喚する事ができる。\nこのカードはシンクロ素材とする事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"プラズマ・ボール",h:"ぷらずま・ぼーる",e:"Plasma Ball",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・ケルベロス",h:"ふぉとん・けるべろす",e:"Photon Cerberus",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1300,de:600,tx:"このカードが召喚に成功したターン、このカードがフィールド上に表側表示で存在する限りお互いに罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・ゲフィロス",h:"えう゛ぉるど・げふぃろす",e:"Evoltile Gephyro",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在する「エヴォルダー」と名のついたモンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・ウェストロ",h:"えう゛ぉるど・うぇすとろ",e:"Evoltile Westlo",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:700,de:1900,tx:"リバース：自分のデッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・オドケリス",h:"えう゛ぉるど・おどけりす",e:"Evoltile Odonto",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"このカードが召喚に成功した時、手札から「エヴォルダー」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルダー・ウルカノドン",h:"えう゛ぉるだー・うるかのどん",e:"Evolsaur Vulcano",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地に存在する「エヴォルダー」と名のついたモンスター１体を選択して特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルダー・ケラト",h:"えう゛ぉるだー・けらと",e:"Evolsaur Cerato",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、このカードの攻撃力は２００ポイントアップする。\nその後、このカードが戦闘によって相手モンスターを破壊した場合、自分のデッキから「エヴォルド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルダー・ディプロドクス",h:"えう゛ぉるだー・でぃぷろどくす",e:"Evolsaur Diplo",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、相手フィールド上に存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイウォリアー",h:"ぜんまいうぉりあー",e:"Wind-Up Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスター１体を選択して発動する事ができる。\nエンドフェイズ時まで選択したモンスター１体のレベルを１つ上げ、攻撃力を６００ポイントアップする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイナイト",h:"ぜんまいないと",e:"Wind-Up Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスターが攻撃対象に選択された時、そのモンスターの攻撃を無効にする事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイハンター",h:"ぜんまいはんたー",e:"Wind-Up Hunter",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"自分のメインフェイズ時、「ゼンマイハンター」以外の自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスター１体をリリースして発動する事ができる。\n相手の手札をランダムに１枚墓地へ送る。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:0,lk:[],ta:[],al:[]},
  {n:"ゼンマイバット",h:"ぜんまいばっと",e:"Wind-Up Bat",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:350,tx:"自分のメインフェイズ時に発動する事ができる。\n自分フィールド上に表側攻撃表示で存在するこのカードを表側守備表示に変更し、自分の墓地に存在する「ゼンマイ」と名のついたモンスター１体を選択して手札に加える。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイニャンコ",h:"ぜんまいにゃんこ",e:"Wind-Up Kitten",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"自分のメインフェイズ時に発動する事ができる。\n相手フィールド上に存在するモンスター１体を選択して持ち主の手札に戻す。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"テレポンD.D.",h:"てれぽんでぃー．でぃー．",e:"D.D. Telepon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"自分フィールド上に表側表示で存在するこのカードがゲームから除外された場合、自分のデッキから攻撃力１５００以下のサイキック族モンスター１体をゲームから除外する事ができる。\n次の自分のスタンバイフェイズ時、この効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキングコブラ",h:"えれきんぐこぶら",e:"Wattcobra",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分のデッキから「エレキ」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・マロン",h:"なちゅる・まろん",e:"Naturia Marron",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"このカードが召喚に成功した時、自分のデッキから「ナチュル」と名のついたモンスター１体を墓地へ送る事ができる。\nまた、１ターンに１度、自分の墓地に存在する「ナチュル」と名のついたモンスター２体を選択してデッキに戻し、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の伝道師",h:"ひょうけっかいのでんどうし",e:"Prior of the Ice Barrier",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:400,tx:"①：自分フィールドに「氷結界」モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚するターン、自分はレベル５以上のモンスターを特殊召喚できない。\n②：このカードをリリースし、「氷結界の伝道師」以外の自分の墓地の「氷結界」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"渋い忍者",h:"しるばーにんじゃ",e:"Senior Silver Ninja",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2200,tx:"このカードがリバースした時、自分の手札・墓地から「渋い忍者」以外の「忍者」と名のついたモンスターを任意の数だけ裏側守備表示で特殊召喚する事ができる。\n「渋い忍者」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドングリス",h:"どんぐりす",e:"Rodenut",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"相手がモンスターを特殊召喚する度に、このカードにドングリカウンターを１つ置く。\nこのカードに乗っているドングリカウンターを１つ取り除く事で、相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鳳凰",h:"ほうおう",e:"Fenghuang",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:2100,de:1800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"同族感電ウィルス",h:"どうぞくかんでんうぃるす",e:"Tribe-Shocking Virus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"１ターンに１度、手札のモンスター１体をゲームから除外して発動する事ができる。\nこの効果を発動するためにゲームから除外したモンスターと同じ種族の、フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリン穴埋め部隊",h:"ごぶりんあなうめぶたい",e:"Goblin Pothole Squad",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:400,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。\nまた、このカードがフィールド上に表側表示で存在する限り、モンスターが召喚・反転召喚・特殊召喚に成功した時、「落とし穴」と名のついた罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・ウサギ",h:"です・うさぎ",e:"Creepy Coney",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1900,tx:"リバース：トークン以外の自分フィールド上に表側表示で存在する通常モンスター１体につき、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキューラビット",h:"れすきゅーらびっと",e:"Rescue Rabbit",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"「レスキューラビット」の効果は１ターンに１度しか使用できない。\nこのカードはデッキから特殊召喚できない。\n①：フィールドのこのカードを除外して発動できる。\nデッキからレベル４以下の同名の通常モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ベビー・トラゴン",h:"べびー・とらごん",e:"Baby Tiragon",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:900,de:900,tx:"レベル１モンスター×３\n自分のメインフェイズ１でこのカードのエクシーズ素材を１つ取り除き、自分フィールド上に表側表示で存在するレベル１のモンスター１体を選択して発動する事ができる。\n選択したモンスターは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.83 ギャラクシー・クィーン",h:"なんばーず８３ ぎゃらくしー・くぃーん",e:"Number 83: Galaxy Queen",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:500,tx:"レベル１モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全てのモンスターは相手ターン終了時まで、戦闘では破壊されず、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・レイ・ランサー",h:"ぶらっく・れい・らんさー",e:"Black Ray Lancer",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:600,tx:"水属性レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの効果をエンドフェイズ時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.10 白輝士イルミネーター",h:"なんばーず１０ しろきしいるみねーたー",e:"Number 10: Illumiknight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2400,tx:"レベル４モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札を１枚選んで墓地へ送り、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.20 蟻岩土ブリリアント",h:"なんばーず２０ ぎがんとぶりりあんと",e:"Number 20: Giga-Brilliant",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1800,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全てのモンスターの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルカイザー・ラギア",h:"えう゛ぉるかいざー・らぎあ",e:"Evolzar Laggia",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"恐竜族レベル４モンスター×２\n①：このカードのX素材を２つ取り除き、以下の効果を発動できる。\n\n●魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダーエンド・ドラゴン",h:"さんだーえんど・どらごん",e:"Thunder End Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"レベル８通常モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動する事ができる。\nこのカード以外のフィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"攻通規制",h:"こうつうきせい",e:"Attraffic Control",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが３体以上存在する場合、相手は攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"虚栄巨影",h:"きょえいきょえい",e:"Ego Boost",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：モンスターの攻撃宣言時、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、そのバトルフェイズ終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスター・スロット",h:"もんすたー・すろっと",e:"Monster Slots",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択し、選択したモンスターと同じレベルの自分の墓地に存在するモンスター１体を選択してゲームから除外する。\nその後、自分のデッキからカードを１枚ドローする。\nこの効果でドローしたカードをお互いに確認し、選択したモンスターと同じレベルのモンスターだった場合、そのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クロス・アタック",h:"くろす・あたっく",e:"Cross Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在する、同じ攻撃力を持つモンスター２体を選択して発動する。\nこのターン、選択したモンスター１体は相手プレイヤーに直接攻撃する事ができる。\nもう１体のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ギフト",h:"えくしーず・ぎふと",e:"Xyz Gift",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにXモンスターが２体以上存在する場合に発動できる。\n自分フィールドのX素材を２つ取り除き、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・ベール",h:"ふぉとん・べーる",e:"Photon Veil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から光属性モンスター３体をデッキに戻し、自分のデッキから光属性・レベル４以下のモンスターを３体まで手札に加える事ができる。\n２体以上手札に加える場合は、全て同名モンスターでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・リード",h:"ふぉとん・りーど",e:"Photon Lead",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"手札からレベル４以下の光属性モンスター１体を表側攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・ブースター",h:"ふぉとん・ぶーすたー",e:"Photon Booster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"トークン以外のフィールド上に表側表示で存在するレベル４以下の光属性モンスター１体を選択して発動する。\n選択したモンスター及びフィールド上に表側表示で存在する同名のモンスターの攻撃力は、エンドフェイズ時まで２０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"進化の宿命",h:"しんかのしゅくめい",e:"Evo-Karma",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の「エヴォルド」と名のついたモンスターの効果によってモンスターが特殊召喚に成功した時、相手は魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"進化の奇跡",h:"しんかのきせき",e:"Evo-Miracle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「エヴォルド」と名のついたモンスターの効果によって特殊召喚されたモンスター１体を選択して発動する。\nこのターン、選択したモンスターは戦闘及びカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"巻きすぎた発条",h:"まきすぎたぜんまい",e:"Zenmailfunction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「ゼンマイ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、リリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクストラゲート",h:"えくすとらげーと",e:"Extra Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"１から１２までの任意のレベルを宣言して発動する。\n相手はエクストラデッキに存在する宣言されたレベルを持つモンスター１体をゲームから除外する。\n宣言したレベルを持つモンスターが相手のエクストラデッキに無かった場合、自分の手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲なカケラ",h:"ごうよくなかけら",e:"Shard of Greed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分ドローフェイズに通常のドローをする度に、このカードに強欲カウンターを１つ置く。\n②：強欲カウンターが２つ以上置かれているこのカードを墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"森のざわめき",h:"もりのざわめき",e:"Murmur of the Forest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して裏側守備表示にする。\nその後、フィールド上に存在するフィールド魔法カードを持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"トライワイトゾーン",h:"とらいわいとぞーん",e:"Tri-Wight",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のレベル２以下の通常モンスター３体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"一時休戦",h:"いちじきゅうせん",e:"One Day of Peace",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、それぞれデッキから１枚ドローする。\n次の相手ターン終了時まで、お互いが受ける全てのダメージは０になる。",li:1,lk:[],ta:[],al:[]},
  {n:"スペース・サイクロン",h:"すぺーす・さいくろん",e:"Space Cyclone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"猛毒の風",h:"もうどくのかぜ",e:"Poisonous Winds",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いに風属性モンスターを特殊召喚する事はできない。\nまた、フィールド上に表側表示で存在する全ての風属性モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"痛恨の訴え",h:"つうこんのうったえ",e:"Heartfelt Appeal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃によって自分が戦闘ダメージを受けた時に発動できる。\n相手フィールド上に表側表示で存在する守備力が一番高いモンスター１体のコントロールを次の自分のエンドフェイズ時まで得る。\nこの効果でコントロールを得たモンスターの効果は無効化され、攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"燃える闘志",h:"もえるとうし",e:"Fiery Fervor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に表側表示で存在するモンスター１体に装備する。\n元々の攻撃力よりも攻撃力が高いモンスターが相手フィールド上に存在する場合、装備モンスターの攻撃力はダメージステップの間、元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・ダイエット",h:"だめーじ・だいえっと",e:"Damage Diet",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン自分が受ける全てのダメージは半分になる。\nまた、墓地に存在するこのカードをゲームから除外する事で、そのターン自分が受ける効果ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"コピー・ナイト",h:"こぴー・ないと",e:"Copy Knight",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上にレベル４以下の戦士族モンスターが召喚された時に発動できる。\nこのカードは発動後、その召喚されたモンスターと同じレベルの同名モンスターカード（戦士族・光・攻／守０）となり、モンスターカードゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる鎧 －ミラーメール－",h:"せいなるよろい　－みらーめーる－",e:"Mirror Mail",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃対象モンスターの攻撃力は、攻撃モンスターの攻撃力と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュ・レイン",h:"ふぃっしゅ・れいん",e:"Fish Rain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する魚族・海竜族・水族モンスターがゲームから除外された時に発動する事ができる。\n自分の手札からレベル３以下の魚族・海竜族・水族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘイト・クレバス",h:"へいと・くればす",e:"Icy Crevasse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体が相手のカードの効果によって破壊され墓地へ送られた時、相手フィールド上に存在するモンスター１体を選択して墓地へ送り、その元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"光子化",h:"ふぉとないず",e:"Lumenize",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にし、その相手モンスターの攻撃力分だけ、自分フィールド上に表側表示で存在する光属性モンスター１体の攻撃力を、次の自分のエンドフェイズ時までアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"進化への懸け橋",h:"しんかへのかけはし",e:"Evolutionary Bridge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが攻撃対象に選択された時に発動する事ができる。\n自分の墓地に存在する「エヴォルド」と名のついたモンスター１体を選択して特殊召喚し、そのモンスターに攻撃対象を移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"発条の巻き戻し",h:"ぜんまいのまきもどし",e:"Zenmairch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するレベル４以下の「ゼンマイ」と名のついたモンスター１体を選択して手札に戻し、戻したモンスターと同じレベルの「ゼンマイ」と名のついたモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキャンセル",h:"えれきゃんせる",e:"Wattcancel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から「エレキ」と名のついたモンスター１体を捨てて発動する。\n相手モンスターの召喚・特殊召喚を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王者の看破",h:"おうじゃのかんぱ",e:"Champion's Vigilance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドにレベル７以上の通常モンスターが存在する場合、以下の効果を発動できる。\n\n●魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の閃光",h:"やみのせんこう",e:"Darklight",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力１５００以上の闇属性モンスター１体をリリースして発動できる。\nこのターンに特殊召喚されたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暴君の自暴自棄",h:"ぼうくんのじぼうじき",e:"Tyrant's Throes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するトークン以外の通常モンスター２体をリリースして発動する。\nこのカードがフィールド上に存在する限り、お互いに効果モンスターを召喚・特殊召喚する事ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"撤収命令",h:"てっしゅうめいれい",e:"Sound the Retreat!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"深黒の落とし穴",h:"しんこくのおとしあな",e:"Deep Dark Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"レベル５以上の効果モンスターが特殊召喚に成功した時に発動する事ができる。\nそのレベル５以上の効果モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"アイスバーン",h:"あいすばーん",e:"Eisbahn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に水属性モンスターが表側表示で存在し、水属性以外のモンスターが召喚・特殊召喚に成功した時、そのモンスターは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"水遁封印式",h:"すいとんふういんしき",e:"Sealing Ceremony of Suiton",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、手札の水属性モンスター１体を墓地へ送る事で、相手の墓地に存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソニック・ウォリアー",h:"そにっく・うぉりあー",e:"Sonic Warrior",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"①：このカードが墓地へ送られた場合に発動する。\n自分フィールドの全てのレベル２以下のモンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"インフルーエンス・ドラゴン",h:"いんふるーえんす・どらごん",e:"Influence Dragon",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:900,tx:"１ターンに１度、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターはエンドフェイズ時までドラゴン族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリムゾン・ブレーダー",h:"くりむぞん・ぶれーだー",e:"Crimson Blader",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n次の相手ターン、相手はレベル５以上のモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スター・ブライト・ドラゴン",h:"すたー・ぶらいと・どらごん",e:"Bright Star Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードが召喚に成功した時、このカード以外のフィールド上に表側表示で存在するモンスター１体を選択し、エンドフェイズ時までレベルを２つ上げる事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・サニクス",h:"じぇむないと・さにくす",e:"Gem-Knight Sardonyx",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"炎族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキから「ジェムナイト」と名のついたカード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・フロギス",h:"らう゛ぁる・ふろぎす",e:"Laval Phlogis",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードが墓地へ送られた時、自分フィールド上の全ての「ラヴァル」と名のついたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・アバンス",h:"りちゅあ・あばんす",e:"Gishki Avance",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nデッキから「リチュア」と名のついたモンスター１体を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・グリフ",h:"がすた・ぐりふ",e:"Gusto Griffin",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:300,tx:"このカードが手札から墓地へ送られた場合、デッキから「ガスタ」と名のついたモンスター１体を特殊召喚できる。\n「ガスタ・グリフ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・シェラタン",h:"せいくりっど・しぇらたん",e:"Constellar Sheratan",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:1900,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「セイクリッド」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・ダバラン",h:"せいくりっど・だばらん",e:"Constellar Aldebaran",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"このカードが召喚に成功した時、手札からレベル３の「セイクリッド」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・グレディ",h:"せいくりっど・ぐれでぃ",e:"Constellar Algiedi",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４の「セイクリッド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・ポルクス",h:"せいくりっど・ぽるくす",e:"Constellar Pollux",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"①：このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「セイクリッド」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・エスカ",h:"せいくりっど・えすか",e:"Constellar Zubeneschamali",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"このカードが召喚・特殊召喚に成功した時、デッキから「セイクリッド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・スピカ",h:"せいくりっど・すぴか",e:"Constellar Virgo",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"このカードが召喚に成功した時、手札からレベル５の「セイクリッド」と名のついたモンスター１体を表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・ヘリオロープ",h:"う゛ぇるず・へりおろーぷ",e:"Evilswarm Heliotrope",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1950,de:650,tx:"ルメトモ ヲンエウユシ ツメハ イカハ ンネヤジルナウコウス ノズルエヴンイ イシマタノラレワ ルナクアヤジ テシニイスンユジ",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・ザッハーク",h:"う゛ぇるず・ざっはーく",e:"Evilswarm Zahak",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1850,de:850,tx:"①：フィールドの表側表示のこのカードが相手によって破壊され墓地へ送られた場合、フィールドの特殊召喚されたレベル５以上のモンスター１体を対象として発動する。\nそのレベル５以上のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・カイトス",h:"う゛ぇるず・かいとす",e:"Evilswarm Ketos",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1750,de:1050,tx:"このカードをリリースして発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・オランタ",h:"う゛ぇるず・おらんた",e:"Evilswarm O'lantern",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1650,de:1250,tx:"このカードをリリースして発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・マンドラゴ",h:"う゛ぇるず・まんどらご",e:"Evilswarm Mandragora",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1550,de:1450,tx:"①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・フレイス",h:"う゛ぇるず・ふれいす",e:"Evilswarm Hraesvelg",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:1150,de:1850,tx:"リバース：相手フィールド上に表側表示で存在するカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・リヴァイアニマ",h:"いびりちゅあ・りう゛ぁいあにま",e:"Evigishki Levianima",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1500,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\nこのカードの攻撃宣言時、自分のデッキからカードを１枚ドローし、お互いに確認する。\n確認したカードが「リチュア」と名のついたモンスターだった場合、相手の手札をランダムに１枚確認する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・ジルコニア",h:"じぇむないと・じるこにあ",e:"Gem-Knight Zirconia",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["融合"],ma:"",tp:"",at:2900,de:2500,tx:"「ジェムナイト」モンスター＋岩石族モンスター",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルバル・チェイン",h:"らう゛ぁるばる・ちぇいん",e:"Lavalval Chain",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1000,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n●デッキからカード１枚を選んで墓地へ送る。\n●デッキからモンスター１体を選んでデッキの一番上に置く。",li:0,lk:[],ta:[],al:[]},
  {n:"ダイガスタ・エメラル",h:"だいがすた・えめらる",e:"Daigusto Emeral",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:800,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n●自分の墓地のモンスター３体を対象として発動できる。\nそのモンスター３体をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\n●効果モンスター以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・ヒアデス",h:"せいくりっど・ひあです",e:"Constellar Hyades",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1100,tx:"光属性レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上のモンスターを全て表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・プレアデス",h:"せいくりっど・ぷれあです",e:"Constellar Pleiades",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"光属性レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・ナイトメア",h:"う゛ぇるず・ないとめあ",e:"Evilswarm Nightmare",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:950,de:1950,tx:"闇属性レベル４モンスター×２\n相手がモンスターの特殊召喚に成功した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその特殊召喚したモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・バハムート",h:"う゛ぇるず・ばはむーと",e:"Evilswarm Bahamut",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2350,de:1350,tx:"「ヴェルズ」と名のついたレベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n手札から「ヴェルズ」と名のついたモンスター１体を捨て、選択した相手モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"炎熱伝導場",h:"えんねつでんどうば",e:"Molten Conduction Field",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「ラヴァル」モンスター２体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュアの写魂鏡",h:"りちゅあのしゃこんきょう",e:"Gishki Photomirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「リチュア」と名のついた儀式モンスターの降臨に必要。\n儀式召喚するモンスターのレベル×５００ライフポイントを払わなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッドの星痕",h:"せいくりっどのせいこん",e:"Constellar Star Chart",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「セイクリッド」と名のついたエクシーズモンスターが特殊召喚された時、デッキからカードを１枚ドローできる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"廃石融合",h:"だぶれっと・ふゅーじょん",e:"Fragment Fusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地から、「ジェムナイト」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの風塵",h:"がすたのふうじん",e:"Dust Storm of Gusto",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動したターン、自分フィールド上の「ガスタ」と名のついたモンスターの攻撃宣言時に相手は魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の侵喰感染",h:"しんりゃくのしんしょくかんせん",e:"Infestation Infection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分の手札または自分フィールド上に表側表示で存在する、「ヴェルズ」と名のついたモンスター１体をデッキに戻して発動できる。\nデッキから「ヴェルズ」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"フルエルフ",h:"ふるえるふ",e:"Flelf",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"１ターンに１度、手札のモンスター１体を相手に見せて発動する事ができる。\nエンドフェイズ時まで、見せたモンスターのレベル分だけこのカードのレベルを上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"チュウボーン",h:"ちゅうぼーん",e:"Chewbone",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードがリバースした場合に発動する。\n相手フィールドに「チュウボーンJr.トークン」（アンデット族・地・星１・攻１００／守３００）３体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"三連星のトリオン",h:"さんれんせいのとりおん",e:"Triple Star Trion",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードがアドバンス召喚のリリースとして墓地へ送られたターンのエンドフェイズ時、このカードを墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"巨星のミラ",h:"きょせいのみら",e:"Mira the Star-Bearer",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1100,de:1000,tx:"レベル１モンスター×３\nフィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在するレベル４以下のモンスターは、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"シャインエルフ",h:"しゃいんえるふ",e:"Shining Elf",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:1000,tx:"レベル２モンスター×２\n相手がモンスターの召喚・特殊召喚に成功した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nそのモンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO エスクリダオ",h:"えれめんたるひーろー　えすくりだお",e:"Elemental HERO Escuridao",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO」と名のついたモンスター＋闇属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードの攻撃力は、自分の墓地に存在する「E・HERO」と名のついたモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カチコチドラゴン",h:"かちこちどらごん",e:"Kachi Kochi Dragon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1300,tx:"レベル４モンスター×２\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードのエクシーズ素材を１つ取り除く事で、もう１度だけ続けて攻撃する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ヴェール",h:"えくしーず・う゛ぇーる",e:"Xyz Veil",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーはフィールド上に表側表示で存在する、エクシーズ素材を持ったエクシーズモンスターをカードの効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"シー・ランサー",h:"しー・らんさー",e:"Sea Lancer",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"ゲームから除外されている自分の魚族・海竜族・水族モンスターを任意の数だけ選択し、装備カード扱いとしてこのカードに装備できる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。\nこの効果でモンスターを装備している場合、このカードの攻撃力は１０００ポイントアップする。\nまた、フィールド上のこのカードが破壊される場合、代わりにこのカードの効果で装備したモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"カンツウツボ",h:"かんつうつぼ",e:"Piercing Moray",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"１ターンに１度、自分のメインフェイズ時にこのカード以外の自分フィールド上の魚族・海竜族・水族モンスター１体をリリースして発動できる。\nこのカードの攻撃力は６００ポイントアップする。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ロスト・ブルー・ブレイカー",h:"ろすと・ぶるー・ぶれいかー",e:"Lost Blue Breaker",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"フィールド上にこのカード以外の魚族・海竜族・水族モンスターが存在する場合にこのカードをリリースして発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ペインペインター",h:"ぺいんぺいんたー",e:"Pain Painter",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"このカードのカード名は、フィールド上に表側表示で存在する限り「ゾンビキャリア」として扱う。\nまた、１ターンに１度、このカード以外の自分フィールド上のアンデット族モンスターを２体まで選択して発動できる。\n選択したモンスターのレベルはエンドフェイズ時まで２になる。\nこの効果が適用されたモンスターをシンクロ素材とする場合、アンデット族モンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オリエント・ドラゴン",h:"おりえんと・どらごん",e:"Orient Dragon",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、相手フィールド上のシンクロモンスター１体を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉の守護者アドレウス",h:"しゅうえんのしゅごしゃあどれうす",e:"Adreus, Keeper of Armageddon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1700,tx:"レベル５モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側表示で存在するカード１枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュアンドバックス",h:"ふぃっしゅあんどばっくす",e:"Fish and Swaps",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚捨て、ゲームから除外されている自分の魚族・海竜族・水族モンスター２体を選択して発動できる。\n選択したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"苦渋の転生",h:"くじゅうのてんせい",e:"Painful Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地から、相手はモンスター１体を選ぶ。\nこのターンのエンドフェイズ時、そのモンスターを墓地から自分の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"角笛砕き",h:"つのぶえくだき",e:"Smashing Horn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"モンスターの召喚・特殊召喚を無効にするモンスターの効果・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"King Landia the Goldfang",h:"King Landia the Goldfang",e:"King Landia the Goldfang",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"3 Level 8 monsters\nMust be Xyz Summoned and cannot be Special Summoned by other ways. If this card attacks your opponent directly and reduces their Life Points to 0, while it has an Xyz Material attached that was originally Beast-Warrior-Type, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"Queen Nereia the Silvercrown",h:"Queen Nereia the Silvercrown",e:"Queen Nereia the Silvercrown",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2400,tx:"3 Level 6 monsters\nMust be Xyz Summoned and cannot be Special Summoned by other ways. If this card attacks your opponent directly and reduces their Life Points to 0, while it has an Xyz Material attached that was originally Plant-Type, you win the Match.\nThis card cannot be used in a Duel.",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドウォリアー",h:"どどどうぉりあー",e:"Dododo Warrior",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:900,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１８００になる。\nまた、このカードが攻撃する場合、ダメージステップ終了時まで相手の墓地で発動する効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"No.61 ヴォルカザウルス",h:"なんばーず６１ う゛ぉるかざうるす",e:"Number 61: Volcasaurus",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"恐竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1000,tx:"レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターを破壊し、そのモンスターの元々の攻撃力分のダメージを相手に与える。\nこの効果を発動するターン、このカードは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.19 フリーザードン",h:"なんばーず１９ ふりーざーどん",e:"Number 19: Freezadon",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"恐竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル５モンスター×２\n①：１ターンに１度、自分のXモンスターがX素材を取り除いて効果を発動する場合、取り除くX素材１つをこのカードから取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガバック",h:"がががばっく",e:"Gagagaback",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「ガガガ」と名のついたモンスターが戦闘で破壊され墓地へ送られたターンに発動できる。\nこのターン戦闘で破壊されたモンスターを可能な限り自分の墓地から表側守備表示で特殊召喚する。\nその後、この効果で特殊召喚したモンスター１体につき、自分は６００ポイントのダメージを受ける。\n「ガガガバック」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガシールド",h:"がががしーるど",e:"Gagagashield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの魔法使い族モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてその自分の魔法使い族モンスターに装備する。\n装備モンスターは１ターンに２度まで、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.56 ゴールドラット",h:"なんばーず５６ ごーるどらっと",e:"Number 56: Gold Rat",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:600,tx:"レベル１モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"No.30 破滅のアシッド・ゴーレム",h:"なんばーず３０ はめつのあしっど・ごーれむ",e:"Number 30: Acid Golem of Destruction",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル３モンスター×２\n①：X素材が無いこのカードは攻撃できない。\n②：このカードがモンスターゾーンに存在する限り、自分はモンスターを特殊召喚できない。\n③：自分スタンバイフェイズに発動する。\nこのカードのX素材を１つ取り除くか、自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・アーチャー",h:"いんふぇるにてぃ・あーちゃー",e:"Infernity Archer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"自分の手札が０枚の場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.16 色の支配者ショック・ルーラー",h:"なんばーず１６ いろのしはいしゃしょっく・るーらー",e:"Number 16: Shock Master",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1600,tx:"レベル４モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手ターン終了時まで、宣言した種類のカードをお互いに発動できない。",li:0,lk:[],ta:[],al:[]},
  {n:"No.11 ビッグ・アイ",h:"なんばーず１１ びっぐ・あい",e:"Number 11: Big Eye",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル７モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールを得る。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ジェネラル",h:"いんふぇるにてぃ・じぇねらる",e:"Infernity General",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2700,de:1500,tx:"自分の手札が０枚の場合、自分の墓地に存在するこのカードをゲームから除外し、自分の墓地に存在するレベル３以下の「インフェルニティ」と名のついたモンスター２体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"アレキサンドライドラゴン",h:"あれきさんどらいどらごん",e:"Alexandrite Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2000,de:100,tx:"アレキサンドライトのウロコを持った、非常に珍しいドラゴン。\nその美しいウロコは古の王の名を冠し、神秘の象徴とされる。\n\n――それを手にした者は大いなる幸運を既につかんでいる事に気づいていない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・サーベルタイガー",h:"ふぉとん・さーべるたいがー",e:"Photon Sabre Tiger",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:300,tx:"このカードが召喚・反転召喚に成功した時、デッキから「フォトン・サーベルタイガー」１体を手札に加える事ができる。\n自分フィールド上にこのカード以外の「フォトン・サーベルタイガー」が存在しない場合、このカードの攻撃力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルダー・ペルタ",h:"えう゛ぉるだー・ぺるた",e:"Evolsaur Pelta",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、このカードの守備力は５００ポイントアップする。\nその後、このカードが戦闘によって破壊された場合、デッキから「エヴォルド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイラビット",h:"ぜんまいらびっと",e:"Wind-Up Rabbit",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"自分フィールド上の「ゼンマイ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nこの効果は相手ターンでも発動できる。\nまた、この効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"Dボーイズ",h:"でぃーぼーいず",e:"D-Boyz",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:1000,tx:"リバース：デッキから「Dボーイズ」を任意の数だけ表側攻撃表示で特殊召喚できる。\nその後、自分はこの効果で特殊召喚したモンスターの数×１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の闘神 ラチナ",h:"あんこくかいのとうしん　らちな",e:"Latinum, Exarch of Dark World",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:2400,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\n相手のカードの効果によって捨てられた場合、さらにフィールド上の悪魔族モンスター１体を選択して、攻撃力を５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルカイザー・ドルカ",h:"えう゛ぉるかいざー・どるか",e:"Evolzar Dolkka",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1700,tx:"恐竜族レベル４モンスター×２\n①：このカード以外のモンスターの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"発条機雷ゼンマイン",h:"ぜんまいきらいぜんまいん",e:"Wind-Up Zenmaines",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2100,tx:"レベル３モンスター×２\nフィールド上のこのカードが破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nこの効果を適用したターンのエンドフェイズ時に１度、フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・テリトリー",h:"えくしーず・てりとりー",e:"Xyz Territory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"エクシーズモンスターがモンスターと戦闘を行うダメージ計算時のみ、そのエクシーズモンスターの攻撃力・守備力はそのランク×２００ポイントアップする。\nフィールド上のこのカードがカードの効果によって破壊される場合、代わりに自分フィールド上のエクシーズ素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の瘴気",h:"あんこくのしょうき",e:"Dark Smog",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、相手の墓地のモンスター１体を選択して発動できる。\n手札から悪魔族モンスター１体を捨て、選択したモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"発条装攻ゼンマイオー",h:"ぜんまいそうこうぜんまいおー",e:"Wind-Up Arsenal Zenmaioh",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1900,tx:"レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、フィールドにセットされたカード２枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"M.X－セイバー インヴォーカー",h:"みっしんぐえっくす－せいばー　いんう゛ぉーかー",e:"M-X-Saber Invoker",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:500,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから、戦士族または獣戦士族の、地属性・レベル４モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:0,lk:[],ta:[],al:[]},
  {n:"クリボルト",h:"くりぼると",e:"Kurivolt",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"自分のメインフェイズ時、エクシーズ素材を持っているエクシーズモンスター１体を選択して発動できる。\n選択したモンスターのエクシーズ素材を１つ取り除き、自分のデッキから「クリボルト」１体を特殊召喚する。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークロン",h:"だーくろん",e:"Darklon",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが召喚に成功した時に発動できる。\n自分フィールド上に表側表示で存在するモンスターはエンドフェイズ時までレベルが１つ上がり闇属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガガール",h:"ががががーる",e:"Gagaga Girl",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"①：自分フィールドの「ガガガマジシャン」１体を対象として発動できる。\nこのカードのレベルはそのモンスターと同じになる。\n②：このカードを含む「ガガガ」モンスターのみを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した時、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴジャイアント",h:"ごごごじゃいあんと",e:"Gogogo Giant",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードが召喚に成功した時、自分の墓地の「ゴゴゴ」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nその後、このカードは守備表示になる。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－一角獣皇槍",h:"ぜある・うぇぽん－ゆにこーん・きんぐ・すぴあ",e:"ZW - Unicorn Spear",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"自分のメインフェイズ時、手札のこのカードを攻撃力１９００ポイントアップの装備カード扱いとして自分フィールド上の「CNo.39 希望皇ホープレイ」に装備できる。\n装備モンスターが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。\n「ZW－一角獣皇槍」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シャクトパス",h:"しゃくとぱす",e:"Shocktopus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが相手モンスターとの戦闘によって破壊され墓地へ送られた時、このカードを装備カード扱いとしてその相手モンスターに装備できる。\nこの効果によってこのカードを装備したモンスターは攻撃力が０になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・リザード",h:"ふぉとん・りざーど",e:"Photon Lizard",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:900,de:1200,tx:"このカードをリリースして発動できる。\nデッキからレベル４以下の「フォトン」と名のついたモンスター１体を手札に加える。\n「フォトン・リザード」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・スラッシャー",h:"ふぉとん・すらっしゃー",e:"Photon Thrasher",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2100,de:0,tx:"このカードは通常召喚できない。\n自分フィールドにモンスターが存在しない場合に特殊召喚できる。\n①：自分フィールドにこのカード以外のモンスターが存在する場合、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・クラッシャー",h:"ふぉとん・くらっしゃー",e:"Photon Crusher",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・レオ",h:"ふぉとん・れお",e:"Photon Leo",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2100,de:1100,tx:"このカードが召喚に成功した時に発動できる。\n相手の手札を全てデッキに加えてシャッフルする。\nその後、相手はデッキに加えた枚数分のカードをドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・サークラー",h:"ふぉとん・さーくらー",e:"Photon Circle",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"リバース・バスター",h:"りばーす・ばすたー",e:"Reverse Buster",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"このカードは裏側守備表示のモンスターにのみ攻撃できる。\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\nこのカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍者フレイム",h:"きこうにんじゃふれいむ",e:"Flame Armor Ninja",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分フィールド上の「忍者」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍者エアー",h:"きこうにんじゃえあー",e:"Air Armor Ninja",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分フィールド上の「忍者」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍者アクア",h:"きこうにんじゃあくあ",e:"Aqua Armor Ninja",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"相手モンスターの直接攻撃宣言時、自分の墓地にこのカード以外の「忍者」と名のついたモンスターが存在する場合、墓地のこのカードをゲームから除外して発動できる。\n攻撃モンスター１体の攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍者アース",h:"きこうにんじゃあーす",e:"Earth Armor Ninja",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 ホーネット",h:"いんぜくたー　ほーねっと",e:"Inzektor Hornet",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「甲虫装機」モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードを装備カード扱いとして装備しているモンスターのレベルは３つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。\n③：モンスターに装備されているこのカードを墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 アーマイゼ",h:"いんぜくたー　あーまいぜ",e:"Inzektor Ant",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:600,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは３つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。\n装備モンスターが破壊される場合、代わりにこのカードを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 センチピード",h:"いんぜくたー　せんちぴーど",e:"Inzektor Centipede",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「甲虫装機」モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードが自分フィールドに存在し、このカードに装備されたカードが自分の墓地へ送られた場合に発動できる。\nデッキから「甲虫装機」カード１枚を手札に加える。\n③：このカードを装備カード扱いとして装備しているモンスターのレベルは３つ上がる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 ダンセル",h:"いんぜくたー　だんせる",e:"Inzektor Dragonfly",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「甲虫装機」モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードが自分フィールドに存在し、このカードに装備されたカードが自分の墓地へ送られた場合に発動できる。\nデッキから「甲虫装機 ダンセル」以外の「甲虫装機」モンスター１体を特殊召喚する。\n③：このカードを装備カード扱いとして装備しているモンスターのレベルは３つ上がる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 ギガマンティス",h:"いんぜくたー　ぎがまんてぃす",e:"Inzektor Giga-Mantis",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「甲虫装機」モンスター１体を対象として発動できる。\nこのカードを手札から装備カード扱いとしてその自分の「甲虫装機」モンスターに装備する。\n②：このカードを装備カード扱いとして装備しているモンスターの元々の攻撃力は２４００になる。\n③：モンスターに装備されているこのカードが墓地へ送られた場合、「甲虫装機 ギガマンティス」以外の自分の墓地の「甲虫装機」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 ギガウィービル",h:"いんぜくたー　ぎがうぃーびる",e:"Inzektor Giga-Weevil",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:2600,tx:"このカードは手札から装備カード扱いとして自分フィールド上の「甲虫装機」と名のついたモンスターに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターの元々の守備力は２６００になる。\nまた、モンスターに装備されているこのカードが墓地へ送られた場合、自分の墓地から「甲虫装機」と名のついたモンスター１体を選択して特殊召喚できる。\n「甲虫装機 ギガウィービル」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイネズミ",h:"ぜんまいねずみ",e:"Wind-Up Rat",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"自分のメインフェイズ時に発動できる。\n自分フィールド上に表側攻撃表示で存在するこのカードを表側守備表示に変更し、自分の墓地の「ゼンマイ」と名のついたモンスター１体を選択して表側守備表示で特殊召喚する。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイハニー",h:"ぜんまいはにー",e:"Wind-Up Honeybee",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル４以下の「ゼンマイ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・プレウロス",h:"えう゛ぉるど・ぷれうろす",e:"Evoltile Pleuro",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが自分フィールド上で破壊され墓地へ送られた場合、手札から「エヴォルダー」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・カシネリア",h:"えう゛ぉるど・かしねりあ",e:"Evoltile Casinerio",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが戦闘によって相手モンスターを破壊したバトルフェイズ終了時、このカードをリリースして発動できる。\nデッキから恐竜族・炎属性・レベル６以下の同名モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルダー・エリアス",h:"えう゛ぉるだー・えりあす",e:"Evolsaur Elias",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:100,de:2400,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、手札から恐竜族・炎属性・レベル６以下のモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルダー・テリアス",h:"えう゛ぉるだー・てりあす",e:"Evolsaur Terias",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2400,de:600,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、このカードの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"忍者マスター HANZO",h:"にんじゃますたー　はんぞー",e:"Ninja Grandmaster Hanzo",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「忍法」カード１枚を手札に加える。\n②：このカードが反転召喚・特殊召喚に成功した時に発動できる。\nデッキから「忍者マスター HANZO」以外の「忍者」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"覆面忍者ヱビス",h:"ふくめんにんじゃゑびす",e:"Masked Ninja Ebisu",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、自分フィールド上に「覆面忍者ヱビス」以外の「忍者」と名のついたモンスターが存在する場合に発動できる。\n自分フィールド上の「忍者」と名のついたモンスターの数だけ、相手の魔法・罠カードを持ち主の手札に戻す。\nこの効果を適用したターン、自分フィールド上の「忍者義賊ゴエゴエ」は相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"成金忍者",h:"ごーるどにんじゃ",e:"Upstart Golden Ninja",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"１ターンに１度、手札から罠カード１枚を墓地へ送って発動できる。\nデッキからレベル４以下の「忍者」と名のついたモンスター１体を表側守備表示、または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"予言僧 チョウレン",h:"よげんそう　ちょうれん",e:"Chow Len the Prophet",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：１ターンに１度、カードの種類（魔法・罠）を宣言し、相手の魔法＆罠ゾーンにセットされているカード１枚を対象として発動できる。\nそのセットされたカードをお互いに確認し、宣言した種類だった場合、このターンそのカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依装着－ダルク",h:"ひょういそうちゃく－だるく",e:"Familiar-Possessed - Dharc",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"このカードは自分フィールド上の「闇霊使いダルク」１体と闇属性モンスター１体を墓地へ送り、手札またはデッキから特殊召喚できる。\nこの方法で特殊召喚に成功した時、デッキからレベル３またはレベル４の魔法使い族・光属性モンスター１体を手札に加える事ができる。\nまた、この方法で特殊召喚したこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"闇魔界の戦士長 ダークソード",h:"やみまかいのせんしちょう　だーくそーど",e:"Dark Blade the Captain of the Evil World",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"１ターンに１度、自分の墓地の闇属性モンスター１体をゲームから除外し、相手フィールド上の光属性・レベル４以下のモンスター１体を選択して発動できる。\n選択したモンスターを装備カード扱いとしてこのカードに１体のみ装備する。\nまた、フィールド上のこのカードが破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トランス・デーモン",h:"とらんす・でーもん",e:"Trance Archfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から悪魔族モンスター１体を捨て、このカードの攻撃力をターン終了時まで５００アップする。\n②：自分フィールドのこのカードが破壊され墓地へ送られた時、除外されている自分の闇属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"神竜 アポカリプス",h:"しんりゅう　あぽかりぷす",e:"Divine Dragon Apocralyph",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"１ターンに１度、手札を１枚捨てて発動できる。\n自分の墓地のドラゴン族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークストーム・ドラゴン",h:"だーくすとーむ・どらごん",e:"Darkstorm Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:2700,de:2500,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分フィールドの表側表示の魔法・罠カード１枚を墓地へ送って発動できる。\nフィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"後に亀と呼ばれる神",h:"のちにかめとよばれるかみ",e:"Numen erat Testudo",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、お互いに攻撃力１８００以下のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ツイン・フォトン・リザード",h:"ついん・ふぉとん・りざーど",e:"Twin Photon Lizard",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1000,tx:"「フォトン」と名のついたモンスター×２\nこのカードをリリースして発動できる。\nリリースしたこのカードの融合召喚に使用した融合素材モンスター一組を自分の墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.39 希望皇ホープレイ",h:"かおすなんばーず３９ きぼうおうほーぷれい",e:"Number C39: Utopia Ray",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"光属性レベル４モンスター×３\nこのカードは自分フィールドの「No.39 希望皇ホープ」の上に重ねてX召喚する事もできる。\n①：このカードのX素材を１つ取り除いて発動できる。\nターン終了時までこのカードの攻撃力を５００アップし、相手フィールドのモンスター１体を選んでその攻撃力をターン終了時まで１０００ダウンする。\nこの効果は自分のLPが１０００以下の場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍者ブレード・ハート",h:"きこうにんじゃぶれーど・はーと",e:"Blade Armor Ninja",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1000,tx:"戦士族レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上の「忍者」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.12 機甲忍者クリムゾン・シャドー",h:"なんばーず１２ きこうにんじゃくりむぞん・しゃどー",e:"Number 12: Crimson Shadow Armor Ninja",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1700,tx:"レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン、自分フィールドの「忍者」モンスターは戦闘・効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.96 ブラック・ミスト",h:"なんばーず９６ ぶらっく・みすと",e:"Number 96: Dark Mist",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:100,de:1000,tx:"レベル２モンスター×３\n①：このカードが相手の表側表示モンスターと戦闘を行う攻撃宣言時に１度、このカードのX素材を１つ取り除いて発動できる。\nその相手モンスターの攻撃力を半分にし、その数値分このカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"発条空母ゼンマイティ",h:"ぜんまいくうぼぜんまいてぃ",e:"Wind-Up Carrier Zenmaity",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1500,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札・デッキから「ゼンマイ」モンスター１体を特殊召喚する。\n②：フィールドの表側表示の「ゼンマイ」モンスターが戦闘以外で破壊され自分の墓地へ送られた時、このカードのX素材を１つ取り除き、その「ゼンマイ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"エヴォルカイザー・ソルデ",h:"えう゛ぉるかいざー・そるで",e:"Evolzar Solda",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1000,tx:"恐竜族レベル６モンスター×２\nエクシーズ素材を持っているこのカードはカードの効果では破壊されない。\n相手がモンスターの特殊召喚に成功した時、このカードのエクシーズ素材を１つ取り除く事で、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 エクサビートル",h:"いんぜくたー　えくさびーとる",e:"Inzektor Exa-Beetle",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"レベル６モンスター×２\nこのカードがエクシーズ召喚に成功した時、自分または相手の墓地のモンスター１体を選択し、装備カード扱いとしてこのカードに装備できる。\nこのカードの攻撃力・守備力は、この効果で装備したモンスターのそれぞれの半分の数値分アップする。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除く事で、自分及び相手フィールド上に表側表示で存在するカードを１枚ずつ選択して墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"渾身の一撃",h:"こんしんのいちげき",e:"Full-Force Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターン、選択したモンスターは戦闘では破壊されず、その攻撃によって発生するお互いへの戦闘ダメージは０になる。\nまた、このターン、選択したモンスターが相手モンスターを攻撃した場合、ダメージ計算後にその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガボルト",h:"がががぼると",e:"Gagagabolt",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ガガガ」と名のついたモンスターが存在する場合に発動できる。\nフィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブル・ディフェンダー",h:"だぶる・でぃふぇんだー",e:"Double Defender",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側守備表示でモンスターが２体以上存在する場合、相手モンスターの攻撃宣言時に発動できる。\nその相手モンスター１体の攻撃を無効にする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシー・ストーム",h:"ぎゃらくしー・すとーむ",e:"Galaxy Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に存在する、エクシーズ素材が無いエクシーズモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍法ゴールド・コンバージョン",h:"きこうにんぽうごーるど・こんばーじょん",e:"Armor Ninjitsu Art of Alchemy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「忍法」と名のついたカードが存在する場合に発動できる。\n自分フィールド上の「忍法」と名のついたカードを全て破壊する。\nその後、デッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"星に願いを",h:"ほしにねがいを",e:"Star Light, Star Bright",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターと同じ攻撃力または同じ守備力を持つ自分フィールド上のモンスターのレベルは、エンドフェイズ時まで選択したモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマーブラスト",h:"あーまーぶらすと",e:"Armor Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「甲虫装機」と名のついたカード１枚と相手フィールド上に表側表示で存在するカード２枚を選択して発動する。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機の魔剣 ゼクトキャリバー",h:"いんぜくたーのまけん　ぜくときゃりばー",e:"Inzektor Sword - Zektkaliber",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「甲虫装機」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は８００ポイントアップする。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分の墓地の「甲虫装機」と名のついたモンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"揺れる発条秤",h:"ゆれるぜんまいばかり",e:"Weights & Zenmaisures",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、レベルが異なる「ゼンマイ」と名のついたモンスター２体を選択して発動する。\nその内１体を相手が選び、もう１体のモンスターのレベルはエンドフェイズ時まで、相手が選んだモンスターと同じになる。\n相手がレベルの低いモンスターを選んだ場合、その後自分はデッキからカードを１枚ドローできる。",li:"",lk:[],ta:[],al:[]},
  {n:"原初のスープ",h:"げんしょのすーぷ",e:"Primordial Soup",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n手札の「エヴォルダー」と名のついたモンスターを２体までデッキに戻してシャッフルし、戻した数だけデッキからカードをドローする。\n「原初のスープ」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"強制進化",h:"きょうせいしんか",e:"Evo-Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「エヴォルド」と名のついたモンスター１体をリリースして発動する。\nデッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、「エヴォルド」と名のついたモンスターの効果で特殊召喚した扱いとなる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ジェノサイド・カッター",h:"だーく・じぇのさいど・かったー",e:"Dark Mambele",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に闇属性モンスターが３体以上存在する場合に発動できる。\nフィールド上に表側表示で存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"忍び寄る闇",h:"しのびよるやみ",e:"Creeping Darkness",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の闇属性モンスター２体をゲームから除外して発動する。\nデッキから闇属性・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"霞の谷の祭壇",h:"みすと・ばれーのさいだん",e:"Shrine of Mist Valley",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"風属性モンスターがカードの効果によって破壊され自分の墓地へ送られた時、自分の手札・デッキから風属性・レベル３以下のモンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・バースト",h:"えくしーず・ばーすと",e:"Xyz Burst",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にランク６以上のエクシーズモンスターが存在する場合に発動できる。\n相手フィールド上にセットされたカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシー・ウェーブ",h:"ぎゃらくしー・うぇーぶ",e:"Galaxy Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分がエクシーズ召喚に成功する度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコロン",h:"さいころん",e:"Dicephoon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：サイコロを１回振り、出た目の効果を適用する。\n\n●２・３・４：フィールドの魔法・罠カード１枚を選んで破壊する。\n\n●５：フィールドの魔法・罠カード２枚を選んで破壊する。\n\n●１・６：自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"反発力",h:"はんぱつりょく",e:"Counterforce",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"表側攻撃表示で存在するモンスターを攻撃対象としたモンスターの攻撃が無効になった時に発動できる。\nその２体のモンスターの攻撃力の差分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガガード",h:"ががががーど",e:"Gagagaguard",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「ガガガ」と名のついたモンスターが２体以上存在する場合に発動できる。\nこのターン、自分フィールド上のモンスターは戦闘及びカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・リフレクト",h:"えくしーず・りふれくと",e:"Xyz Reflect",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上のエクシーズモンスターを対象にする効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。\nその後、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"スプラッシュ・キャプチャー",h:"すぷらっしゅ・きゃぷちゃー",e:"Splash Capture",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がエクシーズ召喚に成功した時、自分の墓地の魚族モンスター２体をゲームから除外して発動できる。\nそのエクシーズモンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍法フリーズ・ロック",h:"きこうにんぽうふりーず・ろっく",e:"Armor Ninjitsu Art of Freezing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「忍者」と名のついたモンスターが存在する場合、セットされたこのカードを相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にし、バトルフェイズを終了する。\nまた、自分フィールド上にこのカードと「忍者」と名のついたモンスターが存在する限り、相手フィールド上の全てのモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲忍法ラスト・ミスト",h:"きこうにんぽうらすと・みすと",e:"Armor Ninjitsu Art of Rust Mist",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「忍者」と名のついたモンスターが存在し、相手フィールド上にモンスターが特殊召喚された時、その特殊召喚したモンスターの攻撃力を半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機の宝珠",h:"いんぜくたーのおーぶ",e:"Inzektor Orb",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力・守備力５００ポイントアップの装備カードとなり、自分フィールド上の「甲虫装機」と名のついたモンスター１体に装備する。\n自分フィールド上の「甲虫装機」と名のついたモンスター１体がカードの効果の対象になった時、装備カードとなったこのカードを墓地へ送る事で、その効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァリュアブル・フォーム",h:"う゛ぁりゅあぶる・ふぉーむ",e:"Variable Form",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールド上の「甲虫装機」と名のついたモンスター２体を選択し、選択したモンスター１体をもう１体のモンスターに装備する。\n●自分フィールド上の装備カード扱いの「甲虫装機」と名のついたモンスター１体を選択して自分フィールド上に表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"螺旋式発条",h:"らせんしきぜんまい",e:"Zenmailstrom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の攻撃力１５００以上の「ゼンマイ」と名のついたモンスター１体をリリースして発動する。\n手札から「ゼンマイ」と名のついたモンスター１体を特殊召喚する。\nその後、この効果で特殊召喚したモンスターと同じ攻撃力を持つ「ゼンマイ」と名のついたモンスター１体をデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"強制退化",h:"きょうせいたいか",e:"Degen-Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のレベル４以上の恐竜族モンスター１体をリリースして発動する。\n自分の手札・墓地からレベル３以下の爬虫類族モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"進化の分岐点",h:"しんかのぶんきてん",e:"Evo-Branch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の爬虫類族モンスター１体を選択して発動する。\n選択したモンスターを破壊し、デッキから「エヴォルド」と名のついたモンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"忍法 超変化の術",h:"にんぽう　ちょうへんげのじゅつ",e:"Ninjitsu Art of Super-Transformation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「忍者」と名のついたモンスター１体と相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターを墓地へ送り、そのレベルの合計以下のドラゴン族・恐竜族・海竜族モンスター１体を自分のデッキから自分フィールド上に特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・リボーン",h:"えくしーず・りぼーん",e:"Xyz Reborn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のXモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、このカードを下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"重量オーバー",h:"じゅうりょうおーばー",e:"Over Capacity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターの特殊召喚に成功した時に発動できる。\nフィールド上のレベル２以下のモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"大革命返し",h:"だいかくめいがえし",e:"The Huge Revolution is Over",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドのカードを２枚以上破壊するモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"王宮の牢獄",h:"おうきゅうのろうごく",e:"Royal Prison",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーは墓地のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"火遁封印式",h:"かとんふういんしき",e:"Sealing Ceremony of Katon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分の墓地の炎属性モンスター１体をゲームから除外する事で、相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトパルサー・ドラゴン",h:"らいとぱるさー・どらごん",e:"Lightpulsar Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:1500,tx:"このカードは自分の墓地の光属性と闇属性のモンスターを１体ずつゲームから除外し、手札から特殊召喚できる。\nまた、手札の光属性と闇属性のモンスターを１体ずつ墓地へ送り、このカードを自分の墓地から特殊召喚できる。\nこのカードがフィールド上から墓地へ送られた時、自分の墓地のドラゴン族・闇属性・レベル５以上のモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークフレア・ドラゴン",h:"だーくふれあ・どらごん",e:"Darkflare Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードは自分の墓地の光属性と闇属性のモンスターを１体ずつゲームから除外し、手札から特殊召喚できる。\n１ターンに１度、手札とデッキからドラゴン族モンスターを１体ずつ墓地へ送る事で、自分または相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクリプス・ワイバーン",h:"えくりぷす・わいばーん",e:"Eclipse Wyvern",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが墓地へ送られた場合に発動する。\nデッキから光属性または闇属性のドラゴン族・レベル７以上のモンスター１体を除外する。\n②：墓地のこのカードが除外された場合に発動できる。\nこのカードの①の効果で除外されているモンスターを手札に加える。",li:0,lk:[],ta:[],al:[]},
  {n:"混沌空間",h:"かおす・ぞーん",e:"Chaos Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：モンスターが表側表示で除外される度に、１体につき１つこのカードにカオスカウンターを置く。\n②：１ターンに１度、自分フィールドのカオスカウンターを４つ以上取り除き、取り除いた数と同じレベルを持つ、除外されている自分または相手のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\n③：フィールドのこのカードが相手の効果で墓地へ送られた時に発動できる。\nこのカードに置かれていたカオスカウンターの数以下のレベルを持つ、光・闇属性のモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の転生",h:"りゅうのてんせい",e:"Dragon's Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nその自分のドラゴン族モンスターを除外し、自分の手札・墓地からドラゴン族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ズバババスター",h:"ずばばばすたー",e:"Zubaba Buster",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカードが相手ライフに戦闘ダメージを与えたダメージステップ終了時、フィールド上に表側表示で存在する攻撃力が一番低いモンスター１体を破壊し、このカードの攻撃力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"チャッチャカアーチャー",h:"ちゃっちゃかあーちゃー",e:"Chachaka Archer",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、フィールド上の魔法・罠カード１枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"重機王ドボク・ザーク",h:"じゅうきおうどぼく・ざーく",e:"Digvorzhak, King of Heavy Industry",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:2000,tx:"レベル５モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手のデッキの上からカードを３枚墓地へ送る。\nこの効果で墓地へ送ったカードの中にモンスターカードがあった場合、その数まで相手フィールド上のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"超弩級砲塔列車グスタフ・マックス",h:"ちょうどきゅうほうとうれっしゃぐすたふ・まっくす",e:"Superdreadnought Rail Cannon Gustav Max",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル１０モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手に２０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.7 ラッキー・ストライプ",h:"なんばーず７ らっきー・すとらいぷ",e:"Number 7: Lucky Straight",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:700,de:700,tx:"レベル７モンスター×３\n①：このカードのX素材を１つ取り除いて発動できる。\nサイコロを２回振る。\nこのカードの攻撃力は、相手ターン終了時まで、大きい方の出た目×７００になる。\n出た目の合計が７だった場合、さらに以下の効果から１つを選んで適用する。\n\n●このカード以外のフィールドのカードを全て墓地へ送る。\n\n●手札または自分・相手の墓地からモンスター１体を選んで自分フィールドに特殊召喚する。\n\n●自分はデッキから３枚ドローし、その後手札を２枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"影無茶ナイト",h:"かげむちゃないと",e:"Kagemucha Knight",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分がレベル３モンスターの召喚に成功した時、このカードを手札から特殊召喚できる。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・ワイバーン",h:"ふぉとん・わいばーん",e:"Photon Wyvern",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが召喚・反転召喚に成功した場合に発動する。\n相手フィールドにセットされたカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"大凛魔天使ローザリアン",h:"だいりんまてんしろーざりあん",e:"Rosaria, the Stately Fallen Angel",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"植物族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:2400,tx:"このカードは通常召喚できない。\n自分の手札と墓地からレベル７以上の植物族モンスターを１体ずつゲームから除外した場合に特殊召喚できる。\n１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカード以外のフィールド上に表側表示で存在するカードの効果をエンドフェイズ時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"マアト",h:"まあと",e:"Ma'at",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分フィールド上に表側表示で存在するドラゴン族・光属性モンスター１体と、天使族・光属性モンスター１体を墓地へ送った場合のみ特殊召喚できる。\n１ターンに１度、カード名を３つ宣言して発動できる。\n自分のデッキの上からカードを３枚めくり、宣言したカードは手札に加える。\nそれ以外のめくったカードは全て墓地へ送る。\nこのカードの攻撃力・守備力は、この効果で手札に加えたカードの数×１０００ポイントになる。",li:"",lk:[],ta:[],al:[]},
  {n:"グラヴィティ・ウォリアー",h:"ぐらう゛ぃてぃ・うぉりあー",e:"Gravity Warrior",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、このカードの攻撃力は相手フィールド上に表側表示で存在するモンスターの数×３００ポイントアップする。\n１ターンに１度、相手のバトルフェイズ時に相手フィールド上に守備表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側攻撃表示にする。\nこのターンそのモンスターが攻撃可能な場合には攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄龍 オーガ・ドラグーン",h:"れんごくりゅう　おーが・どらぐーん",e:"Void Ogre Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"闇属性チューナー＋チューナー以外のモンスター１体以上\n自分の手札が０枚の場合、１ターンに１度、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・サンクチュアリ",h:"ふぉとん・さんくちゅあり",e:"Photon Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分は光属性モンスターしか召喚・反転召喚・特殊召喚できない。\n①：自分フィールドに「フォトントークン」（雷族・光・星４・攻２０００／守０）２体を守備表示で特殊召喚する。\nこのトークンは攻撃できず、S素材にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"反射光子流",h:"ふぉとん・らいじんぐ・すとりーむ",e:"Photon Current",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のドラゴン族・光属性モンスターが攻撃対象に選択された時に発動できる。\nその攻撃対象モンスターの攻撃力は、ダメージステップ終了時まで攻撃モンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元エスパー・スター・ロビン",h:"いじげんえすぱー・すたー・ろびん",e:"D.D. Esper Star Sparrow",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:1500,tx:"このカードがフィールド上に表側表示で存在する限り、他の自分のモンスターを相手はカードの効果の対象にできず、攻撃対象にもできない。\nまた、このカードが墓地に存在する場合、相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを墓地から表側守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。\n「異次元エスパー・スター・ロビン」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"野獣戦士ピューマン",h:"やじゅうせんしぴゅーまん",e:"Beast-Warrior Puma",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードをリリースして発動できる。\n自分のデッキ・墓地から「異次元エスパー・スター・ロビン」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"鳳王獣ガイルーダ",h:"ほうおうじゅうがいるーだ",e:"Phoenix Beast Gairuda",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄巨人アイアンハンマー",h:"てつきょじんあいあんはんまー",e:"Ironhammer the Giant",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:900,de:3500,tx:"自分フィールド上に「異次元エスパー・スター・ロビン」「野獣戦士ピューマン」「鳳王獣ガイルーダ」が存在する場合、このカードは手札から特殊召喚できる。\nこのカードはフィールド上に表側表示で存在する限り、表示形式を変更できない。\nまた、１ターンに１度、自分フィールド上のモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元ジェット・アイアン号",h:"いじげんじぇっと・あいあんごう",e:"D.D. Jet Iron",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n自分の手札・フィールド上から、「異次元エスパー・スター・ロビン」「野獣戦士ピューマン」「鳳王獣ガイルーダ」「鉄巨人アイアンハンマー」をそれぞれ１体ずつ墓地へ送った場合に特殊召喚できる。\nまた、自分フィールド上のこのカードをリリースする事で、自分の墓地の「異次元エスパー・スター・ロビン」「野獣戦士ピューマン」「鳳王獣ガイルーダ」「鉄巨人アイアンハンマー」をそれぞれ１体ずつ選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アイアイアン",h:"あいあいあん",e:"Aye-Iron",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"１ターンに１度、自分のメインフェイズ時にこのカードの攻撃力を４００ポイントアップできる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリキンギョ",h:"ぶりきんぎょ",e:"Tin Goldfish",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネジマキシキガミ",h:"ねじまきしきがみ",e:"Gearspring Spirit",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:100,de:100,tx:"このカードは通常召喚できない。\n自分の墓地のモンスターが機械族モンスターのみの場合に特殊召喚できる。\n①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・ラズリー",h:"じぇむないと・らずりー",e:"Gem-Knight Lazuli",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"①：このカードが効果で墓地へ送られた場合、自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"リチュア・ナタリア",h:"りちゅあ・なたりあ",e:"Gishki Natalia",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、自分の墓地の「リチュア」と名のついたモンスター１体を選択してデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・シェアト",h:"せいくりっど・しぇあと",e:"Constellar Siat",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:1600,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nまた、１ターンに１度、このカード以外の自分のフィールド上・墓地の「セイクリッド」と名のついたモンスター１体を選択して発動できる。\nこのカードは選択したモンスターと同じレベルになる。\nフィールド上のこのカードをエクシーズ素材とする場合、「セイクリッド」と名のついたモンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・ハワー",h:"せいくりっど・はわー",e:"Constellar Rasalhague",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:100,tx:"このカードをリリースして発動できる。\n自分の手札・墓地から「セイクリッド・ハワー」以外の「セイクリッド」と名のついたモンスター１体を選んで表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・レオニス",h:"せいくりっど・れおにす",e:"Constellar Leonis",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、自分のメインフェイズ時に１度だけ、自分は通常召喚に加えて「セイクリッド」と名のついたモンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・アクベス",h:"せいくりっど・あくべす",e:"Constellar Acubens",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードが召喚・特殊召喚に成功した時、自分フィールド上の全ての「セイクリッド」と名のついたモンスターの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・カウスト",h:"せいくりっど・かうすと",e:"Constellar Kaus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"このカードはS素材にできない。\n①：フィールドの「セイクリッド」モンスター１体を対象として以下の効果から１つを選択して発動できる。\nこの効果は１ターンに２度まで使用できる。\n\n●対象のモンスターのレベルを１つ上げる。\n\n●対象のモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・レスカ",h:"せいくりっど・れすか",e:"Constellar Alrescha",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードが召喚に成功した時、手札から「セイクリッド」と名のついたモンスター１体を表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・アンタレス",h:"せいくりっど・あんたれす",e:"Constellar Antares",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:900,tx:"このカードが召喚・特殊召喚に成功した時、自分の墓地の「セイクリッド」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・カストル",h:"う゛ぇるず・かすとる",e:"Evilswarm Castor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1750,de:550,tx:"このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ「ヴェルズ」と名のついたモンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・オ・ウィスプ",h:"う゛ぇるず・お・うぃすぷ",e:"Evilswarm Obliviwisp",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:450,de:2050,tx:"このカードと戦闘を行った効果モンスターの効果をダメージ計算後に無効化する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・アザトホース",h:"う゛ぇるず・あざとほーす",e:"Evilswarm Azzathoth",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:750,de:1950,tx:"リバース：フィールド上の特殊召喚されたモンスター１体を選択して持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・サンダーバード",h:"う゛ぇるず・さんだーばーど",e:"Evilswarm Thunderbird",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1650,de:1050,tx:"魔法・罠・効果モンスターの効果が発動した時、自分フィールド上のこのカードをゲームから除外できる。\nこの効果は相手ターンでも発動できる。\nこの効果で除外したこのカードは次のスタンバイフェイズ時にフィールド上に戻り、攻撃力は３００ポイントアップする。\n「ヴェルズ・サンダーバード」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・サラマンドラ",h:"う゛ぇるず・さらまんどら",e:"Evilswarm Salamandra",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1850,de:950,tx:"①：自分の墓地からモンスター１体を除外して発動できる。\nこのカードの攻撃力は相手ターン終了時まで３００アップする。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・ゴーレム",h:"う゛ぇるず・ごーれむ",e:"Evilswarm Golem",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2150,de:1250,tx:"１ターンに１度、フィールド上に表側表示で存在する、闇属性以外のレベル５以上のモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・コッペリアル",h:"う゛ぇるず・こっぺりある",e:"Evilswarm Coppelia",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2450,de:2050,tx:"このカードは特殊召喚できない。\nこのカードが相手によってフィールド上から離れた時、相手フィールド上に表側表示で存在するモンスター１体を選択して次の自分のエンドフェイズ時までコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"創星神 sophia",h:"そうせいしん　そぴあ",e:"Sophia, Goddess of Rebirth",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3600,de:3400,tx:"このカードは通常召喚できない。\n自分・相手フィールド上に表側表示で存在する、儀式・融合・シンクロ・エクシーズモンスターをそれぞれ１体ずつゲームから除外した場合のみ特殊召喚できる。\nこのカードの特殊召喚は無効化されない。\nこのカードが特殊召喚に成功した時、このカード以外のお互いの手札・フィールド上・墓地のカードを全てゲームから除外する。\nこの効果の発動に対して魔法・罠・効果モンスターの効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・プシュケローネ",h:"いびりちゅあ・ぷしゅけろーね",e:"Gishki Psychelone",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2150,de:1650,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、モンスターの種族・属性を宣言して発動できる。\n相手の手札をランダムに１枚確認し、宣言した種族・属性のモンスターだった場合、そのカードを持ち主のデッキに戻す。\n違った場合は元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"イビリチュア・ジールギガス",h:"いびりちゅあ・じーるぎがす",e:"Gishki Zielgigas",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:3200,de:0,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、１０００ライフポイントを払って発動できる。\nデッキからカードを１枚ドローし、お互いに確認する。\n確認したカードが「リチュア」と名のついたモンスターだった場合、フィールド上のカード１枚を選んで持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・セラフィ",h:"じぇむないと・せらふぃ",e:"Gem-Knight Seraphinite",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1400,tx:"「ジェムナイト」モンスター＋光属性モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみEXデッキから特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズにモンスター１体を通常召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイトマスター・ダイヤ",h:"じぇむないとますたー・だいや",e:"Gem-Knight Master Diamond",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2500,tx:"「ジェムナイト」モンスター×３\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地の「ジェム」モンスターの数×１００アップする。\n②：１ターンに１度、自分の墓地のレベル７以下の「ジェムナイト」融合モンスター１体を除外して発動できる。\nエンドフェイズまで、このカードは除外したモンスターと同名カードとして扱い、同じ効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリキの大公",h:"ぶりきのたいこう",e:"Tin Archduke",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1200,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの表示形式を変更する。\nこの時、リバース効果モンスターの効果は発動しない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・ビーハイブ",h:"せいくりっど・びーはいぶ",e:"Constellar Praesepe",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:800,tx:"レベル４「セイクリッド」モンスター×２\n①：１ターンに１度、自分の「セイクリッド」モンスターが戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードのX素材を１つ取り除いて発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・トレミスM７",h:"せいくりっど・とれみすめしえせぶん",e:"Constellar Ptolemy M7",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"レベル６モンスター×２\nこのカードは「セイクリッド・トレミスM７」以外の自分フィールドの「セイクリッド」Xモンスターの上にこのカードを重ねてX召喚する事もできる。\nこの方法で特殊召喚した場合、このターンこのカードの効果は発動できない。\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分または相手の、フィールド・墓地のモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・タナトス",h:"う゛ぇるず・たなとす",e:"Evilswarm Thanatos",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2350,de:1350,tx:"闇属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのターン、このカードはこのカード以外のモンスターの効果を受けない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・オピオン",h:"う゛ぇるず・おぴおん",e:"Evilswarm Ophion",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2550,de:1650,tx:"レベル４「ヴェルズ」モンスター×２\n①：X素材を持っているこのカードがモンスターゾーンに存在する限り、お互いにレベル５以上のモンスターを特殊召喚できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから「侵略の」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・ウロボロス",h:"う゛ぇるず・うろぼろす",e:"Evilswarm Ouroboros",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2750,de:1950,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n以下の効果はこのカードがフィールド上に表側表示で存在する限りそれぞれ１度しか選択できない。\n\n●相手フィールド上に存在するカード１枚を選択して持ち主の手札に戻す。\n\n●相手の手札をランダムに１枚選んで墓地へ送る。\n\n●相手の墓地に存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"アイアンコール",h:"あいあんこーる",e:"Iron Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに機械族モンスターが存在する場合、自分の墓地のレベル４以下の機械族モンスター１体を対象として発動できる。\nその機械族モンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッドの超新生",h:"せいくりっどのちょうしんせい",e:"Constellar Star Cradle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「セイクリッド」と名のついたモンスター２体を選択して手札に加える。\nこのカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の汎発感染",h:"しんりゃくのはんぱつかんせん",e:"Infestation Pandemic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの全ての「ヴェルズ」モンスターは、ターン終了時までこのカード以外の魔法・罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッドの流星",h:"せいくりっどのりゅうせい",e:"Constellar Meteor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動したターン、「セイクリッド」と名のついたモンスターとの戦闘で破壊されなかった相手モンスターは、ダメージステップ終了時に持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"侵略の侵喰崩壊",h:"しんりゃくのしんしょくほうかい",e:"Infestation Terminus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ヴェルズ」と名のついたモンスター１体を選択してゲームから除外し、相手フィールド上のカード２枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・オメガ",h:"せいくりっど・おめが",e:"Constellar Omega",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:500,tx:"光属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分フィールド上の全ての「セイクリッド」と名のついたモンスターは、このターン魔法・罠カードの効果を受けない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界発現世行きバス",h:"まかいはつげんせゆきばす",e:"Tour Bus From the Underworld",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが墓地へ送られた時、「魔界発現世行きバス」以外の自分または相手の墓地のモンスター１体を選択し、持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"白竜の忍者",h:"はくりゅうのにんじゃ",e:"White Dragon Ninja",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2700,de:1200,tx:"このカードを特殊召喚する場合、「忍法」と名のついたカードの効果でのみ特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上の魔法・罠カードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"異界の棘紫竜",h:"いかいのきょくしりゅう",e:"Interplanetarypurplythorny Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1100,tx:"自分フィールド上のモンスターが戦闘またはカードの効果によって破壊され墓地へ送られた場合、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 ホッパー",h:"いんぜくたー　ほっぱー",e:"Inzektor Hopper",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは４つ上がる。\nまた、装備カード扱いとして装備されているこのカードを墓地へ送る事で、このターン装備モンスターは相手プレイヤーに直接攻撃できる。\nこの効果を発動するターン、装備モンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・ナハシュ",h:"えう゛ぉるど・なはしゅ",e:"Evoltile Najasho",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"フィールド上のこのカードがリリースされた場合、デッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼンマイシャーク",h:"ぜんまいしゃーく",e:"Wind-Up Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：自分フィールドに「ゼンマイ」モンスターが召喚・特殊召喚された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●このカードのレベルをターン終了時まで１つ上げる。\n●このカードのレベルをターン終了時まで１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・トライデント",h:"ふぉとん・とらいでんと",e:"Photon Trident",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「フォトン」と名のついたモンスター１体を選択して発動できる。\nエンドフェイズ時まで、選択したモンスターの攻撃力は７００ポイントアップし、守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、選択したモンスターが相手ライフに戦闘ダメージを与えた時、フィールド上の魔法・罠カード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機の魔斧 ゼクトホーク",h:"いんぜくたーのまふ　ぜくとほーく",e:"Inzektor Axe - Zektahawk",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「甲虫装機」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は１０００ポイントアップする。\n装備モンスターの攻撃宣言時、相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"突然進化",h:"とつぜんしんか",e:"Evo-Instant",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の爬虫類族モンスター１体をリリースして発動できる。\nデッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"忍法 分身の術",h:"にんぽう　ぶんしんのじゅつ",e:"Ninjitsu Art of Duplication",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの「忍者」モンスター１体をリリースしてこのカードを発動できる。\nレベルの合計がリリースしたモンスターのレベル以下になるように、デッキから「忍者」モンスターを任意の数だけ表側攻撃表示または裏側守備表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは全て破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキテルドラゴン",h:"えれきてるどらごん",e:"Wattaildragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2500,de:1000,tx:"常に電気を纏い空中を浮遊するドラゴン。\n古代より存在し、その生態には未だ謎が多いものの、古のルールにより捕獲は禁止されている。",li:"",lk:[],ta:[],al:[]},
  {n:"神龍の聖刻印",h:"しんりゅうのせいこくいん",e:"Hieratic Seal of the Sun Dragon Overlord",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:0,de:0,tx:"謎の刻印が刻まれた聖なる遺物。\n神の如く力を振るった龍の力を封じた物と伝承は語る。\n黄金の太陽の下、悠久の刻を経て、それはやがて神々しさと共に太陽石と呼ばれるようになった。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーレイ・オウル",h:"おーばーれい・おうる",e:"Overlay Owl",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"１ターンに１度、６００ライフポイントを払って発動できる。\nフィールド上のエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"タスケナイト",h:"たすけないと",e:"Tasuke Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:100,tx:"このカードが墓地に存在し、自分の手札が０枚の場合、相手モンスターの攻撃宣言時に発動できる。\nこのカードを墓地から特殊召喚し、バトルフェイズを終了する。\n「タスケナイト」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガガードナー",h:"ががががーどなー",e:"Gagaga Gardna",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:2000,tx:"相手モンスターの直接攻撃宣言時、このカードを手札から特殊召喚できる。\nまた、このカードが攻撃対象に選択された時、手札を１枚捨てる事で、このカードはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"カードカー・D",h:"かーどかー・でぃー",e:"Cardcar D",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカードは特殊召喚できない。\nこのカードの効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードが召喚に成功した自分メインフェイズ１にこのカードをリリースして発動できる。\n自分はデッキから２枚ドローする。\nその後、このターンのエンドフェイズになる。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーレイ・イーター",h:"おーばーれい・いーたー",e:"Overlay Eater",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:300,de:800,tx:"自分のメインフェイズ時、墓地のこのカードをゲームから除外して発動できる。\n相手フィールド上のエクシーズ素材１つを、自分フィールド上のエクシーズモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンマー・シャーク",h:"はんまー・しゃーく",e:"Hammer Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードのレベルを１つ下げ、手札から水属性・レベル３以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンマーラッシュ・バウンサー",h:"はんまーらっしゅ・ばうんさー",e:"Hammer Bounzer",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"相手フィールド上にカードが存在し、自分フィールド上にカードが存在しない場合、このカードはリリースなしで召喚できる。\n自分フィールド上に魔法・罠カードが存在せず、このカードが相手モンスターに攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレード・バウンサー",h:"ぶれーど・ばうんさー",e:"Blade Bounzer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカードが攻撃を行ったダメージステップ終了時、相手フィールド上にモンスターが存在する場合、手札を１枚捨てて発動できる。\nこのバトルフェイズ中、このカードは相手モンスターにもう１度だけ攻撃できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ファントム・バウンサー",h:"ふぁんとむ・ばうんさー",e:"Phantom Bounzer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"フィールド上のこのカードが破壊され墓地へ送られた場合、デッキから「バウンサー」と名のついたカード２枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻蝶の刺客モルフォ",h:"げんちょうのしきゃくもるふぉ",e:"Morpho Butterspy",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"相手フィールド上のモンスターの表示形式が変更された時、そのモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力・守備力を１０００ポイントダウンする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻蝶の刺客アゲハ",h:"げんちょうのしきゃくあげは",e:"Swallowtail Butterspy",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード以外の自分フィールド上のモンスターが相手ライフに戦闘ダメージを与えた時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は、その時に与えたダメージの数値分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"月光蝶",h:"むーんりっと・ぱぴよん",e:"Moonlit Papillon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードがフィールド上から墓地へ送られた時、デッキからレベル４以下の「幻蝶の刺客」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"穿孔重機ドリルジャンボ",h:"せんこうじゅうきどりるじゃんぼ",e:"Jumbo Drill",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"このカードが召喚に成功した時、自分フィールド上の全ての機械族モンスターのレベルを１つ上げる事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"爆走特急ロケット・アロー",h:"ばくそうとっきゅうろけっと・あろー",e:"Rocket Arrow Express",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:5000,de:0,tx:"このカードは通常召喚できない。\n自分フィールド上にカードが存在しない場合のみ特殊召喚できる。\nこのカードを特殊召喚するターン、自分はバトルフェイズを行えない。\nこのカードがフィールド上に表側表示で存在する限り、自分は魔法・罠・効果モンスターの効果を発動できず、カードをセットする事もできない。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に手札を全て墓地へ送る。\nまたはこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクープ・シューター",h:"すくーぷ・しゅーたー",e:"Cameraclops",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが、このカードの攻撃力よりも高い守備力を持つフィールド上に表側表示で存在するモンスターに攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－ドラゴンヌート",h:"せいこくりゅう－どらごんぬーと",e:"Hieratic Dragon of Nuit",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"フィールド上に表側表示で存在するこのカードが魔法・罠・効果モンスターの効果の対象になった時に発動する。\n自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－ドラゴンゲイヴ",h:"せいこくりゅう－どらごんげいう゛",e:"Hieratic Dragon of Gebeb",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。\nまた、このカードがリリースされた時、自分の手札・デッキ・墓地から「聖刻」と名のついた通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－アセトドラゴン",h:"せいこくりゅう－あせとどらごん",e:"Hieratic Dragon of Eset",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１０００になる。\n１ターンに１度、フィールド上のドラゴン族の通常モンスター１体を選択して発動できる。\nフィールド上の全ての「聖刻」と名のついたモンスターのレベルはエンドフェイズ時まで選択したモンスターと同じレベルになる。\nまた、このカードがリリースされた時、自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－ネフテドラゴン",h:"せいこくりゅう－ねふてどらごん",e:"Hieratic Dragon of Nebthet",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードは自分フィールド上の「聖刻」と名のついたモンスター１体をリリースして手札から特殊召喚できる。\n１ターンに１度、このカード以外の自分の手札・フィールド上の「聖刻」と名のついたモンスター１体をリリースする事で、相手フィールド上のモンスター１体を選択して破壊する。\nまた、このカードがリリースされた時、自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－トフェニドラゴン",h:"せいこくりゅう－とふぇにどらごん",e:"Hieratic Dragon of Tefnuit",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したターン、このカードは攻撃できない。\n②：このカードがリリースされた場合に発動する。\n自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－シユウドラゴン",h:"せいこくりゅう－しゆうどらごん",e:"Hieratic Dragon of Su",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"①：このカードは自分フィールドの「聖刻」モンスター１体をリリースして手札から特殊召喚できる。\n②：１ターンに１度、このカード以外の自分の手札・フィールドの「聖刻」モンスター１体をリリースし、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを破壊する。\n③：このカードがリリースされた場合に発動する。\n自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－ウシルドラゴン",h:"せいこくりゅう－うしるどらごん",e:"Hieratic Dragon of Asar",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:700,tx:"このカードは自分の墓地のドラゴン族・光属性モンスターとドラゴン族の通常モンスターを１体ずつゲームから除外し、手札から特殊召喚できる。\nフィールド上のこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「聖刻」と名のついたモンスター１体をリリースできる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍－セテクドラゴン",h:"せいこくりゅう－せてくどらごん",e:"Hieratic Dragon of Sutekh",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地のドラゴン族の通常モンスター３体をゲームから除外した場合に特殊召喚できる。\n１ターンに１度、自分の墓地のドラゴン族モンスター１体をゲームから除外する事で、フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・ラゴスクス",h:"えう゛ぉるど・らごすくす",e:"Evoltile Lagosucho",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1200,de:500,tx:"このカードが召喚に成功した時、デッキから「エヴォルダー」と名のついたモンスター１体を墓地へ送る事ができる。\nまた、このカードがリバースした時、デッキから「エヴォルド」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルダー・ダルウィノス",h:"えう゛ぉるだー・だるうぃのす",e:"Evolsaur Darwino",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2200,de:700,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、フィールド上に表側表示で存在するモンスター１体を選択してレベルを２つまで上げる事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 リュシオル",h:"いんぜくたー　りゅしおる",e:"Inzektor Firefly",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードに「甲虫装機」と名のついたカードが装備された場合、相手フィールド上にセットされたカードを全て確認できる。\nまた、このカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは１つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 グルフ",h:"いんぜくたー　ぐるふ",e:"Inzektor Ladybug",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:100,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは２つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。\nまた、装備カード扱いとして装備されているこのカードを墓地へ送る事で、自分フィールド上のモンスター１体を選択し、レベルを２つまで上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 ウィーグ",h:"いんぜくたー　うぃーぐ",e:"Inzektor Earwig",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターの攻撃力・守備力はこのカードのそれぞれの数値分アップする。\nまた、モンスターに装備されているこのカードが墓地へ送られた時、装備していたモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 ギガグリオル",h:"いんぜくたー　ぎがぐりおる",e:"Inzektor Giga-Cricket",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2000,de:1300,tx:"自分の墓地の昆虫族モンスター１体をゲームから除外する事で、墓地のこのカードを装備カード扱いとして自分フィールド上の「甲虫装機」と名のついたモンスター１体に装備する。\n「甲虫装機 ギガグリオル」のこの効果は１ターンに１度しか使用できない。\nまた、このカードが装備カード扱いとして装備されている場合、装備モンスターの元々の攻撃力は２０００になり、装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトレイ ソーサラー",h:"らいとれい　そーさらー",e:"Lightray Sorcerer",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\nゲームから除外されている自分の光属性モンスターが３体以上の場合のみ特殊召喚できる。\n１ターンに１度、ゲームから除外されている自分の光属性モンスター１体を選択してデッキに戻し、フィールド上に表側表示で存在するモンスター１体を選択してゲームから除外できる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトレイ ダイダロス",h:"らいとれい　だいだろす",e:"Lightray Daedalus",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:1500,tx:"このカードは通常召喚できない。\n自分の墓地の光属性モンスターが４体以上の場合のみ特殊召喚できる。\n１ターンに１度、フィールド上のカード２枚とフィールド魔法カード１枚の合計３枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトレイ ギア・フリード",h:"らいとれい　ぎあ・ふりーど",e:"Lightray Gearfried",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の光属性モンスターが５種類以上の場合のみ特殊召喚できる。\n自分フィールド上に表側表示で存在するモンスターが戦士族のみの場合、自分の墓地の戦士族モンスター１体をゲームから除外する事で、魔法・罠カードの発動を無効にし破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトレイ ディアボロス",h:"らいとれい　でぃあぼろす",e:"Lightray Diabolos",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地の光属性モンスターが５種類以上の場合に特殊召喚できる。\n１ターンに１度、自分の墓地の光属性モンスター１体をゲームから除外して発動できる。\n相手フィールド上にセットされたカード１枚を選択して確認し、持ち主のデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・ウィッチ－ドラゴンの守護者－",h:"どらごん・うぃっち－どらごんのしゅごしゃ－",e:"Lady of D.",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上のドラゴン族モンスターを相手は攻撃対象に選択できない。\nフィールド上のこのカードが戦闘またはカードの効果によって破壊される場合、代わりに手札からドラゴン族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アブソーブポッド",h:"あぶそーぶぽっど",e:"Absorbing Jar",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:600,de:500,tx:"リバース：フィールド上にセットされた魔法・罠カードを全て破壊する。\n破壊されたカードのコントローラーは、破壊された数だけデッキからカードをドローする。\nこのターン、自分はカードをセットできない。",li:"",lk:[],ta:[],al:[]},
  {n:"紅血鬼",h:"こうけつき",e:"Red-Headed Oni",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードがアンデット族モンスターの効果によって墓地からの特殊召喚に成功した時、自分フィールド上のアンデット族モンスター１体を選択して発動できる。\nフィールド上のエクシーズ素材を１つ取り除き、選択したモンスターのレベルを１つ上げ、攻撃力を３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"焔虎",h:"ふれいむ・たいがー",e:"Flame Tiger",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードが墓地に存在し、自分フィールド上にモンスターが存在しない場合、自分のドローフェイズ時に通常のドローを行う代わりに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ながれ者傭兵部隊",h:"ながれものようへいぶたい",e:"Nomadic Force",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードをリリースして発動できる。\n相手フィールド上に裏側守備表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"超銀河眼の光子龍",h:"ねお・ぎゃらくしーあいず・ふぉとん・どらごん",e:"Neo Galaxy-Eyes Photon Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4500,de:3000,tx:"レベル８モンスター×３\n「銀河眼の光子竜」を素材としてこのカードがエクシーズ召喚に成功した時、このカード以外のフィールド上に表側表示で存在するカードの効果を無効にする。\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上のエクシーズ素材を全て取り除き、このターンこのカードの攻撃力は取り除いた数×５００ポイントアップする。\nさらに、このターンこのカードは取り除いた数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.32 海咬龍シャーク・ドレイク",h:"なんばーず３２　かいこうりゅうしゃーく・どれいく",e:"Number 32: Shark Drake",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2100,tx:"レベル４モンスター×３\n①：１ターンに１度、このカードの攻撃で相手モンスターを破壊し墓地へ送った時、このカードのX素材を１つ取り除いて発動できる。\nそのモンスターを相手フィールドに攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は１０００ダウンする。\nこの効果で特殊召喚した場合、このバトルフェイズ中、このカードはもう１度だけ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・ストリーク・バウンサー",h:"ふぉとん・すとりーく・ばうんさー",e:"Photon Strike Bounzer",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"レベル６モンスター×２\n相手フィールド上で効果モンスターの効果が発動した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその効果を無効にし、相手ライフに１０００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・バタフライ・アサシン",h:"ふぉとん・ばたふらい・あさしん",e:"Photon Papilloperative",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、フィールド上に守備表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側攻撃表示にし、その攻撃力を６００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.25 重装光学撮影機フォーカス・フォース",h:"なんばーず２５ ふるめたる・ふぉとぐらいどふぉーかす・ふぉーす",e:"Number 25: Force Focus",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2400,tx:"レベル６モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのレベル５以上の効果モンスター１体を対象として発動できる。\nその相手モンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"迅雷の騎士ガイアドラグーン",h:"じんらいのきしがいあどらぐーん",e:"Gaia Dragon, the Thunder Charger",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"レベル７モンスター×２\nこのカードは自分フィールド上のランク５・６のエクシーズモンスターの上にこのカードを重ねてエクシーズ召喚する事もできる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻龍王－アトゥムス",h:"せいこくりゅうおう－あとぅむす",e:"Hieratic Dragon King of Atum",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2100,tx:"ドラゴン族レベル６モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキからドラゴン族モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻神龍－エネアード",h:"せいこくしんりゅう－えねあーど",e:"Hieratic Sun Dragon Overlord of Heliopolis",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2400,tx:"レベル８モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分の手札・フィールドのモンスターを任意の数だけリリースし、その数だけフィールドのカードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魔人 クィーンドラグーン",h:"りゅうまじん　くぃーんどらぐーん",e:"Queen Dragun Djinn",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1200,tx:"レベル４モンスター×２\nこのカードがフィールド上に表側表示で存在する限り、「竜魔人 クィーンドラグーン」以外の自分のドラゴン族モンスターは戦闘では破壊されない。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除く事で、自分の墓地のレベル５以上のドラゴン族モンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターンそのモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機 エクサスタッグ",h:"いんぜくたー　えくさすたっぐ",e:"Inzektor Exa-Stag",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:800,de:800,tx:"昆虫族レベル５モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除く事で、相手のフィールド上・墓地のモンスター１体を選択して装備カード扱いとしてこのカードに装備する。\nこのカードの攻撃力・守備力は、この効果で装備したモンスターのそれぞれの半分の数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"バウンド・ワンド",h:"ばうんど・わんど",e:"Bound Wand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"魔法使い族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は、装備モンスターのレベル×１００アップする。\n②：装備モンスターが相手によって破壊され、このカードが墓地へ送られた場合に発動できる。\nそのモンスターを墓地から自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミニマム・ガッツ",h:"みにまむ・がっつ",e:"Mini-Guts",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体をリリースし、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで０になる。\nこのターン、選択したモンスターが戦闘によって破壊され相手の墓地へ送られた時、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"下降潮流",h:"かこうちょうりゅう",e:"Falling Current",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択し、１から３までの任意のレベルを宣言して発動できる。\n選択したモンスターのレベルは宣言したレベルとなる。",li:"",lk:[],ta:[],al:[]},
  {n:"蛮勇鱗粉",h:"ばーさーく・すけーるす",e:"Berserk Scales",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は１０００ポイントアップし、このターン相手プレイヤーに直接攻撃できない。\nこのターンのエンドフェイズ時、選択したモンスターの攻撃力は２０００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイト・ショット",h:"ないと・しょっと",e:"Night Beam",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nセットされたそのカードを破壊する。\nこのカードの発動に対して相手は対象のカードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"召集の聖刻印",h:"しょうしゅうのせいこくいん",e:"Hieratic Seal of Convocation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「聖刻」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超力の聖刻印",h:"ちょうりょくのせいこくいん",e:"Hieratic Seal of Supremacy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「聖刻」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"多様進化",h:"たようしんか",e:"Evo-Diversity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキから「エヴォルド」または「エヴォルダー」と名のついたモンスター１体を手札に加える。\n「多様進化」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"進化の代償",h:"しんかのだいしょう",e:"Evo-Price",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「エヴォルド」と名のついたモンスターの効果によってモンスターが特殊召喚された場合、フィールド上のカード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイナル・インゼクション",h:"ふぁいなる・いんぜくしょん",e:"Final Inzektion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「甲虫装機」と名のついたカード５枚を墓地へ送って発動できる。\n相手フィールド上のカードを全て破壊する。\n相手はこのターンのバトルフェイズ中に手札・墓地で発動する効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機の魔弓 ゼクトアロー",h:"いんぜくたーのまきゅう　ぜくとあろー",e:"Inzektor Crossbow - Zektarrow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「甲虫装機」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は５００ポイントアップする。\n装備モンスターの効果の発動に対して相手は魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ユニット",h:"えくしーず・ゆにっと",e:"Xyz Unit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"エクシーズモンスターにのみ装備可能。\n装備モンスターの攻撃力は、装備モンスターのランク×２００ポイントアップする。\nまた、自分フィールド上の装備モンスターがエクシーズ素材を取り除いて効果を発動する場合、このカードは取り除くエクシーズ素材の１つとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暴走する魔力",h:"ぼうそうするまりょく",e:"That Wacky Magic!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の魔法カードを全てゲームから除外して発動できる。\n除外した魔法カードの数×３００ポイント以下の守備力を持つ、相手フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神星なる領域",h:"せいくりっど・べると",e:"Constellar Belt",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、光属性モンスターの効果の発動は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"嵐",h:"あらし",e:"Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の魔法・罠カードを全て破壊する。\nその後、破壊したカードの数だけ相手フィールド上の魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"奇策",h:"きさく",e:"Nitwit Outwit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札からモンスター１体を捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、この効果を発動するために捨てたモンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガムシャラ",h:"がむしゃら",e:"Gamushara",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に守備表示で存在するモンスターが攻撃対象になった時に発動できる。\nその守備表示モンスターの表示形式を表側攻撃表示に変更する。\nさらに、その戦闘によって攻撃モンスターを破壊し墓地へ送った時、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"剣の采配",h:"つるぎのさいはい",e:"Commander of Swords",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のドローフェイズ時に、相手が通常のドローをした時に発動できる。\nドローしたカードをお互いに確認し、確認したカードが魔法・罠カードだった場合、以下の効果から１つを選択して適用する。\n\n●ドローしたカードを捨てる。\n\n●相手フィールド上の魔法・罠カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"バウンサー・ガード",h:"ばうんさー・がーど",e:"Bounzer Guard",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「バウンサー」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターはカードの効果の対象にならず、戦闘では破壊されない。\nこのターン、相手モンスターが攻撃する場合、選択したモンスターを攻撃対象にしなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"隷属の鱗粉",h:"れいぞくのりんぷん",e:"Butterflyoke",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\n攻撃モンスターの表示形式を守備表示にし、そのモンスター１体にこのカードを装備する。\nまた、１ターンに１度、メインフェイズ及びバトルフェイズ中に発動できる。\n装備モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"抹殺の聖刻印",h:"まっさつのせいこくいん",e:"Hieratic Seal of Banishment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「聖刻」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"反射の聖刻印",h:"はんしゃのせいこくいん",e:"Hieratic Seal of Reflection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上の「聖刻」と名のついたモンスター１体をリリースして発動できる。\n効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼクト・コンバージョン",h:"ぜくと・こんばーじょん",e:"Zekt Conversion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「甲虫装機」と名のついたモンスターが攻撃対象に選択された時に発動できる。\n攻撃対象モンスターを装備カード扱いとして攻撃モンスター１体に装備する。\nこの効果で「甲虫装機」と名のついたモンスターが装備されている限り、自分はその装備モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"甲虫装機の手甲",h:"いんぜくたーのがんとれっと",e:"Inzektor Gauntlet",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは守備力１０００ポイントアップの装備カードとなり、自分フィールド上の「甲虫装機」と名のついたモンスター１体に装備する。\n装備モンスターは相手のカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"差し戻し",h:"さしもどし",e:"Return",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が墓地のカードを手札に加えた時に発動できる。\n相手は手札に加えたそのカード１枚をデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・スライド",h:"でぃめんしょん・すらいど",e:"Dimension Slice",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にモンスターが特殊召喚された時に発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択してゲームから除外する。\nその特殊召喚がエクシーズ召喚だった場合、このカードはセットしたターンに発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"光霊術－「聖」",h:"こうれいじゅつ－「ひじり」",e:"Spiritual Light Art - Hijiri",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの光属性モンスター１体をリリースし、除外されている自分または相手のモンスター１体を対象として発動できる。\n相手は手札から罠カード１枚を見せてこの効果を無効にできる。\n見せなかった場合、対象のモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"雷遁封印式",h:"らいとんふういんしき",e:"Sealing Ceremony of Raiton",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分フィールド上に表側表示で存在する風属性モンスター１体をデッキの一番下に戻す事で、相手の墓地のカードを２枚まで選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"儀水鏡の反魂術",h:"ぎすいきょうのはんごんじゅつ",e:"Aquamirror Cycle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の水属性モンスター１体を選択してデッキに戻し、自分の墓地に存在する水属性モンスター２体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"倍返し",h:"ばいがえし",e:"Double Payback",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のカードの効果によって自分が１０００ポイント以上のダメージを受けた時に発動できる。\nその時に受けたダメージ１０００ポイントにつき、このカードに倍々カウンターを１つ置く。\n次の相手ターンのエンドフェイズ時、このカードを破壊してこのカードに乗っていた倍々カウンターの数×２０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 ヒッポグリフォ",h:"へいずびーすと　ひっぽぐりふぉ",e:"Hazy Flame Hyppogrif",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2100,de:200,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、１ターンに１度、このカード以外の自分フィールド上の炎属性モンスター１体をリリースして発動できる。\nこのカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"森の聖獣 アルパカリブ",h:"もりのせいじゅう　あるぱかりぶ",e:"Alpacaribou, Mystical Beast of the Forest",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2700,de:2100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：自分フィールド上に表側攻撃表示で存在する鳥獣族・昆虫族・植物族モンスターは戦闘では破壊されない。\n●守備表示：自分フィールド上に表側守備表示で存在する鳥獣族・昆虫族・植物族モンスターはカードの効果の対象にならず、カードの効果では破壊されない。\n\n",li:"",lk:[],ta:[],al:[]},
  {n:"森の聖霊 エーコ",h:"もりのせいれい　えーこ",e:"Eco, Mystical Spirit of the Forest",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"相手のカードの効果によって自分がダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージと同じダメージを相手ライフに与える。\nさらに、このターンお互いが受ける効果ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"カブトロン",h:"かぶとろん",e:"Beetron",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の魔法・罠カード１枚を墓地へ送り、自分の墓地のレベル４以下の昆虫族モンスター１体を対象として発動できる。\nその昆虫族モンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スノーダスト・ドラゴン",h:"すのーだすと・どらごん",e:"Snowdust Dragon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードはフィールド上のアイスカウンターを４つ取り除き、手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、このカード以外のアイスカウンターが乗ったモンスターは攻撃できず、表示形式の変更もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"スノー・ドラゴン",h:"すのー・どらごん",e:"Snow Dragon",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:900,tx:"このカードが戦闘またはカードの効果によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する全てのモンスターにアイスカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ウミノタウルス",h:"うみのたうるす",e:"Uminotaurus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の魚族・海竜族・水族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュボーグ－ランチャー",h:"ふぃっしゅぼーぐ－らんちゃー",e:"Fishborg Launcher",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:100,tx:"このカード名の効果は１ターンに１度しか使用できず、このカードをS素材とする場合、水属性モンスターのS召喚にしか使用できない。\n①：このカードが墓地に存在し、「フィッシュボーグ－ランチャー」以外の自分の墓地のモンスターが水属性モンスターのみの場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"光の創造神 ホルアクティ",h:"ひかりのそうぞうしん　ほるあくてぃ",e:"",t:"monster",a:"神",l:12,ps:"",pe:"",tr:"創造神族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分フィールド上の、元々のカード名が「オシリスの天空竜」「オベリスクの巨神兵」「ラーの翼神竜」となるモンスターをそれぞれ１体ずつリリースした場合のみ特殊召喚できる。\nこのカードの特殊召喚は無効化されない。\nこのカードを特殊召喚したプレイヤーはデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジマジ☆マジシャンギャル",h:"まじまじ☆まじしゃんぎゃる",e:"",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"魔法使い族レベル６モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、手札を１枚ゲームから除外して以下の効果から１つを選択して発動できる。\n\n●相手フィールド上のモンスター１体を選択し、このターンのエンドフェイズ時までコントロールを得る。\n\n●相手の墓地のモンスター１体を選択し、自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴラド",h:"どらごらど",e:"Dragard",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1300,de:1900,tx:"①：このカードが召喚に成功した時、自分の墓地の攻撃力１０００以下の通常モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：１ターンに１度、自分フィールドのドラゴン族モンスター１体をリリースし、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、レベルが８になり、攻撃力が８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士アルトリウス",h:"せいきしあるとりうす",e:"Noble Knight Artorigus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1800,tx:"聖騎士団に所属する聡明な青年騎士。\n導かれるかの如く分け入った森の中、ついに運命にたどり着く。\nそして青年は大きな一歩を踏み出すのだ。\n\n――これは全ての始まりであり、大いなる叙事詩である。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・ドラゴン",h:"えんしぇんと・どらごん",e:"Ancient Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"①：このカードが直接攻撃で相手に戦闘ダメージを与えた時に発動できる。\nこのカードのレベルを１つ上げ、攻撃力を５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"龍王の聖刻印",h:"りゅうおうのせいこくいん",e:"Hieratic Seal of the Dragon King",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「龍王の聖刻印」以外の「聖刻」と名のついたモンスター１体を選んで表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・エルギネル",h:"えう゛ぉるど・えるぎねる",e:"Evoltile Elginero",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"フィールド上のこのカードがリリースされ墓地へ送られた時、デッキからカードを１枚ドローする。\nその後、手札の恐竜族モンスター１体をデッキに戻し、デッキから「エヴォルド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトレイ グレファー",h:"らいとれい　ぐれふぁー",e:"Lightray Grepher",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"このカードは手札からレベル５以上の光属性モンスター１体を捨てて、手札から特殊召喚できる。\n１ターンに１度、手札から光属性モンスター１体を捨てて発動できる。\nデッキから光属性モンスター１体を選び、ゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"遅すぎたオーク",h:"おそすぎたおーく",e:"Tardy Orc",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは召喚したターンには攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の交感",h:"りゅうのこうかん",e:"Draconnection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札のドラゴン族モンスター１体を相手に見せ、見せたモンスターと同じレベルのドラゴン族モンスター１体をデッキから手札に加える。\nその後、見せたモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"招来の対価",h:"しょうらいのたいか",e:"Trial and Tribulation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動したターンのエンドフェイズ時、このターン自分が手札・フィールド上からリリースしたトークン以外のモンスターの数によって以下の効果を適用する。\n「招来の対価」は１ターンに１枚しか発動できない。\n\n●１体：デッキからカードを１枚ドローする。\n\n●２体：自分の墓地のモンスター２体を選んで手札に加える。\n\n●３体以上：フィールド上に表側表示で存在するカードを３枚まで選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"復活の聖刻印",h:"ふっかつのせいこくいん",e:"Hieratic Seal From the Ashes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のターンに１度、デッキから「聖刻」と名のついたモンスター１体を墓地へ送る事ができる。\nまた、自分のターンに１度、ゲームから除外されている自分の「聖刻」と名のついたモンスター１体を選択して墓地に戻す事ができる。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分の墓地の「聖刻」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・パニッシュ",h:"えくしーず・ぱにっしゅ",e:"Xyz Wrath",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分フィールド上にエクシーズモンスターが存在し、レベル５以上の効果モンスターの効果が発動した時、手札を１枚捨てる事で、その効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"弦魔人ムズムズリズム",h:"げんまじんむずむずりずむ",e:"Muzurhythm the String Djinn",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1000,tx:"レベル３モンスター×２\n自分フィールド上の「魔人」と名のついたエクシーズモンスターが相手モンスターを攻撃するダメージステップ時に、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその攻撃モンスターの攻撃力はエンドフェイズ時まで倍になる。\n「弦魔人ムズムズリズム」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"太鼓魔人テンテンテンポ",h:"たいこまじんてんてんてんぽ",e:"Temtempo the Percussion Djinn",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1700,de:1000,tx:"レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターのエクシーズ素材を１つ取り除き、自分フィールド上の全ての「魔人」と名のついたエクシーズモンスターの攻撃力は５００ポイントアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"管魔人メロメロメロディ",h:"かんまじんめろめろめろでぃ",e:"Melomelody the Brass Djinn",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1400,de:1600,tx:"レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上の「魔人」と名のついたエクシーズモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"交響魔人マエストローク",h:"こうきょうまじんまえすとろーく",e:"Maestroke the Symphony Djinn",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2300,tx:"レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを裏側守備表示にする。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「魔人」と名のついたエクシーズモンスターが破壊される場合、代わりにそのモンスターのエクシーズ素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.50 ブラック・コーン号",h:"なんばーず５０ ぶらっく・こーんごう",e:"Number 50: Blackship of Corn",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1500,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、このカードの攻撃力以下の攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、相手に１０００ダメージを与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.15 ギミック・パペット－ジャイアントキラー",h:"なんばーず１５ ぎみっく・ぱぺっと－じゃいあんときらー",e:"Number 15: Gimmick Puppet Giant Grinder",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2500,tx:"レベル８モンスター×２\n①：自分メインフェイズ１に、このカードのX素材を１つ取り除き、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n破壊したモンスターがXモンスターだった場合、さらにそのモンスターの元々の攻撃力分のダメージを相手に与える。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法剣士トランス",h:"まほうけんしとらんす",e:"Trance the Magic Swordsman",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:2600,de:200,tx:"かなりの実力を持った風変わりな魔法使い。\n異空間の旅から帰還したらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"ダメージ・メイジ",h:"だめーじ・めいじ",e:"Damage Mage",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"カードの効果によって自分がダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－不死鳥弩弓",h:"ぜある・うぇぽん－ふぇにっくす・ぼう",e:"ZW - Phoenix Bow",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"このカードは手札から装備カード扱いとして自分フィールド上の「CNo.39 希望皇ホープレイ」に装備できる。\nこの効果によってこのカードを装備したモンスターの攻撃力は１１００ポイントアップする。\nまた、装備モンスターが戦闘によって相手モンスターを破壊した時、相手ライフに１０００ポイントダメージを与える。\n「ZW－不死鳥弩弓」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・カイザー",h:"ふぉとん・かいざー",e:"Photon Caesar",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2800,tx:"このカードが召喚・反転召喚に成功した時、自分の手札・デッキから「フォトン・カイザー」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C スパルタス",h:"ひろいっく・ちゃれんじゃー　すぱるたす",e:"Heroic Challenger - Spartan",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、相手モンスターの攻撃宣言時にこのカード以外の自分フィールド上の「ヒロイック」と名のついたモンスター１体を選択して発動できる。\nこのカードの攻撃力はバトルフェイズ終了時まで、選択したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C ウォー・ハンマー",h:"ひろいっく・ちゃれんじゃー　うぉー・はんまー",e:"Heroic Challenger - War Hammer",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1300,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターを装備カード扱いとしてこのカードに１体のみ装備できる。\nこのカードの攻撃力は、この効果で装備したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C ソード・シールド",h:"ひろいっく・ちゃれんじゃー　そーど・しーるど",e:"Heroic Challenger - Swordshield",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分フィールド上に「ヒロイック」と名のついたモンスターが存在する場合、このカードを手札から墓地へ送って発動できる。\nこのターン、戦闘によって発生する自分への戦闘ダメージは０になり、自分フィールド上の「ヒロイック」と名のついたモンスターは戦闘では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C ダブル・ランス",h:"ひろいっく・ちゃれんじゃー　だぶる・らんす",e:"Heroic Challenger - Double Lance",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"このカードが召喚に成功した時、自分の手札・墓地から「H・C ダブル・ランス」１体を選んで表側守備表示で特殊召喚できる。\nこのカードはシンクロ素材にできない。\nまた、このカードをエクシーズ素材とする場合、戦士族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産マヤン・マシーン",h:"おーぱーつまやん・ましーん",e:"Chronomaly Mayan Machine",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"機械族モンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産コロッサル・ヘッド",h:"おーぱーつころっさる・へっど",e:"Chronomaly Colossal Head",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"墓地のこのカードをゲームから除外し、フィールド上に攻撃表示で存在するレベル３以上のモンスター１体を選択して発動できる。\n選択したモンスターを表側守備表示または裏側守備表示にする。\n「先史遺産コロッサル・ヘッド」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産ゴールデン・シャトル",h:"おーぱーつごーるでん・しゃとる",e:"Chronomaly Golden Jet",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の全ての「先史遺産」と名のついたモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産クリスタル・ボーン",h:"おーぱーつくりすたる・ぼーん",e:"Chronomaly Crystal Bones",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:400,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、自分の手札・墓地から「先史遺産クリスタル・ボーン」以外の「先史遺産」と名のついたモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産クリスタル・スカル",h:"おーぱーつくりすたる・すかる",e:"Chronomaly Crystal Skull",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:900,de:600,tx:"自分フィールド上に「先史遺産」と名のついたモンスターが存在する場合、自分のメインフェイズ時にこのカードを手札から墓地へ捨てて発動できる。\n自分のデッキ・墓地から「先史遺産クリスタル・スカル」以外の「先史遺産」と名のついたモンスター１体を選んで手札に加える。\n「先史遺産クリスタル・スカル」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産モアイ",h:"おーぱーつもあい",e:"Chronomaly Moai",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"自分フィールド上に「先史遺産」と名のついたモンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書士 バテル",h:"まどうしょし　ばてる",e:"Spellbook Magician of Prophecy",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"①：このカードが召喚・リバースした場合に発動する。\nデッキから「魔導書」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導弓士 ラムール",h:"まどうきゅうし　らむーる",e:"Amores of Prophecy",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:2000,tx:"１ターンに１度、手札の「魔導書」と名のついた魔法カード１枚を相手に見せて発動できる。\n手札からレベル４以下の魔法使い族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導召喚士 テンペル",h:"まどうしょうかんし　てんぺる",e:"Temperance of Prophecy",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分が「魔導書」と名のついた魔法カードを発動した自分のターンのメインフェイズ時、このカードをリリースして発動できる。\nデッキから光属性または闇属性の魔法使い族・レベル５以上のモンスター１体を特殊召喚する。\nこの効果を発動するターン、自分は他のレベル５以上のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導戦士 フォルス",h:"まどうせんし　ふぉるす",e:"Strength of Prophecy",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1400,tx:"１ターンに１度、自分の墓地の「魔導書」と名のついた魔法カード１枚をデッキに戻し、フィールド上の魔法使い族モンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げ、攻撃力を５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導剣士 シャリオ",h:"まどうけんし　しゃりお",e:"Charioteer of Prophecy",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"１ターンに１度、手札から「魔導書」と名のついた魔法カードを１枚捨てて発動できる。\n自分の墓地の魔法使い族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導法士 ジュノン",h:"まどうほうし　じゅのん",e:"High Priestess of Prophecy",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2100,tx:"①：手札の「魔導書」魔法カード３枚を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分の手札・墓地から「魔導書」魔法カード１枚を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・ミィルフィーヤ",h:"まどるちぇ・みぃるふぃーや",e:"Madolche Mewfeuille",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードが召喚に成功した時、手札から「マドルチェ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・メェプル",h:"まどるちぇ・めぇぷる",e:"Madolche Baaple",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\n１ターンに１度、自分フィールド上に表側攻撃表示で存在する「マドルチェ」と名のついたモンスター１体と、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択した２体のモンスターを表側守備表示にし、次の相手ターン終了時まで、選択したモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・シューバリエ",h:"まどるちぇ・しゅーばりえ",e:"Madolche Chouxvalier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nまた、このカードがフィールド上に表側表示で存在する限り、「マドルチェ・シューバリエ」以外の「マドルチェ」と名のついたモンスターを相手は攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・マジョレーヌ",h:"まどるちぇ・まじょれーぬ",e:"Madolche Magileine",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードが召喚・反転召喚に成功した時、デッキから「マドルチェ」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・バトラスク",h:"まどるちぇ・ばとらすく",e:"Madolche Butlerusk",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードが召喚に成功した時、フィールド上にこのカード以外の「マドルチェ」と名のついたモンスターが存在する場合、デッキからフィールド魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・プディンセス",h:"まどるちぇ・ぷでぃんせす",e:"Madolche Puddingcess",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：自分の墓地にモンスターが存在しない場合、このカードの攻撃力・守備力は８００アップする。\n②：このカードが相手モンスターと戦闘を行ったダメージ計算後に、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。\n③：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアーノ Mk－Ⅱ",h:"ぎあぎあーの　Ｍｋ－Ⅱ",e:"Geargiano Mk-II",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードが召喚・反転召喚に成功した時、自分の手札・墓地から「ギアギア」と名のついたモンスター１体を選んで表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアクセル",h:"ぎあぎあくせる",e:"Geargiaccelerator",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"自分フィールド上に「ギアギア」と名のついたモンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。\nまた、このカードがフィールド上から墓地へ送られた時、自分の墓地から「ギアギアクセル」以外の「ギアギア」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアーセナル",h:"ぎあぎあーせなる",e:"Geargiarsenal",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードの攻撃力は、自分フィールド上の「ギアギア」と名のついたモンスターの数×２００ポイントアップする。\nまた、このカードをリリースして発動できる。\nデッキから「ギアギアーセナル」以外の「ギアギア」と名のついたモンスター１体を表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアーマー",h:"ぎあぎあーまー",e:"Geargiarmor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、デッキから「ギアギアーマー」以外の「ギアギア」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"森の聖獣 ユニフォリア",h:"もりのせいじゅう　ゆにふぉりあ",e:"Uniflora, Mystical Beast of the Forest",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"自分の墓地のモンスターが獣族のみの場合、このカードをリリースして発動できる。\n自分の手札・墓地から「森の聖獣 ユニフォリア」以外の獣族モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"リトルトルーパー",h:"りとるとるーぱー",e:"Little Trooper",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:500,tx:"このカードが戦闘によって破壊された場合、デッキからレベル２以下の戦士族モンスター１体を裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"白銀のスナイパー",h:"はくぎんのすないぱー",e:"Silver Sentinel",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手のカードの効果によって破壊され墓地へ送られたターンのエンドフェイズ時、このカードを墓地から特殊召喚し、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"砂塵の騎士",h:"さじんのきし",e:"Dust Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:1200,tx:"リバース：デッキから地属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ブロック・ゴーレム",h:"ぶろっく・ごーれむ",e:"Block Golem",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：自分の墓地のモンスターが地属性のみの場合、このカードをリリースし、「ブロック・ゴーレム」以外の自分の墓地のレベル４以下の岩石族モンスター２体を対象として発動できる。\nその岩石族モンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、フィールドで発動する効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇の突撃兵",h:"かいおうのとつげきへい",e:"Atlantean Attack Squad",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"自分フィールド上にこのカード以外の魚族・海竜族・水族モンスターが存在する場合、このカードの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"イリュージョン・スナッチ",h:"いりゅーじょん・すなっち",e:"Illusory Snatcher",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：自分がモンスターのアドバンス召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの種族・属性・レベルは、アドバンス召喚したそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"地霊神グランソイル",h:"ちれいしんぐらんそいる",e:"Grandsoil the Elemental Lord",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の地属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"針三千本",h:"すりーさうざんど・にーどる",e:"Three Thousand Needles",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:3000,de:1800,tx:"守備表示のこのカードが攻撃を受けた場合、このカードの守備力が相手攻撃モンスターの攻撃力を超えていれば、ダメージステップ終了時にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴブリン切り込み部隊",h:"ごぶりんきりこみぶたい",e:"Goblin Marauding Squad",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードの攻撃宣言時、相手は魔法・罠・効果モンスターの効果を発動できない。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"H－C エクスカリバー",h:"ひろいっく－ちゃんぴおん　えくすかりばー",e:"Heroic Champion - Excalibur",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"戦士族レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を２つ取り除いて発動できる。\nこのカードの攻撃力は、次の相手のエンドフェイズ時まで元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産クリスタル・エイリアン",h:"おーぱーつくりすたる・えいりあん",e:"Chronomaly Crystal Chrononaut",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1000,tx:"レベル３モンスター×２\n１ターンに１度、このカードが攻撃対象に選択された時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのターン、このカードは戦闘及びカードの効果では破壊されず、このカードの戦闘によって発生する自分への戦闘ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"No.33 先史遺産－超兵器マシュ＝マック",h:"なんばーず３３ おーぱーつ－ちょうへいきましゅ＝まっく",e:"Number 33: Chronomaly Machu Mech",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1500,tx:"レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、元々の攻撃力と異なる攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力とその元々の攻撃力の差分のダメージを相手に与え、このカードの攻撃力はこの効果で与えたダメージの数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"超次元ロボ ギャラクシー・デストロイヤー",h:"ちょうじげんろぼ　ぎゃらくしー・ですとろいやー",e:"Superdimensional Robot Galaxy Destroyer",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:5000,de:2000,tx:"レベル１０モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上の魔法・罠カードを全て破壊する。\nこの効果の発動に対して、相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導法皇 ハイロン",h:"まどうほうおう　はいろん",e:"Hierophant of Prophecy",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2600,tx:"魔法使い族レベル７モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分の墓地の「魔導書」と名のついた魔法カードの数まで、相手フィールド上の魔法・罠カードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギガント X",h:"ぎあぎがんと　くろす",e:"Gear Gigant X",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1500,tx:"機械族レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分のデッキ・墓地からレベル４以下の機械族モンスター１体を選んで手札に加える。\n②：表側表示のこのカードがフィールドから離れた時、自分の墓地のレベル３以下の「ギアギア」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アルケミック・マジシャン",h:"あるけみっく・まじしゃん",e:"Alchemic Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1500,tx:"魔法使い族レベル４モンスター×３\nこのカードの攻撃力は自分の墓地の魔法カードの数×２００ポイントアップする。\nまた、自分のエンドフェイズ時に１度、このカードのエクシーズ素材を１つ取り除き、手札を１枚墓地へ送って発動できる。\nデッキから魔法カードを１枚選び、自分の魔法＆罠カードゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"銀嶺の巨神",h:"ぎんれいのきょしん",e:"Soul of Silvermountain",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2200,tx:"地属性レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手の魔法＆罠カードゾーンにセットされているカード１枚を選択して発動できる。\n選択したカードは、このカードがフィールド上に表側表示で存在する限り発動できない。\nまた、エクシーズ素材を持っているこのカードが戦闘によって相手モンスターを破壊した場合、自分の墓地の地属性モンスター１体を選択し、表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精王 アルヴェルド",h:"ようせいおう　あるう゛ぇるど",e:"Fairy King Albverdich",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1400,tx:"地属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n地属性以外のフィールド上の全てのモンスターの攻撃力・守備力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ソードブレイカー",h:"そーどぶれいかー",e:"Sword Breaker",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:1000,tx:"レベル６モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、種族を１つ宣言して発動できる。\nこのカードが、宣言した種族のモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガリベンジ",h:"がががりべんじ",e:"Gagagarevenge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「ガガガ」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：装備モンスターがX素材になった事でこのカードが墓地へ送られた場合に発動する。\n自分フィールドの全てのXモンスターの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーレイ・リジェネレート",h:"おーばーれい・りじぇねれーと",e:"Overlay Regen",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するエクシーズモンスター１体を選択して発動できる。\nこのカードを選択したモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒロイック・チャンス",h:"ひろいっく・ちゃんす",e:"Heroic Chance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「ヒロイック」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは攻撃力が倍になり、相手プレイヤーに直接攻撃できない。\n「ヒロイック・チャンス」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産技術",h:"おーぱーつてくのろじー",e:"Chronomaly Technology",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「先史遺産」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターをゲームから除外する。\nその後、自分のデッキの上からカードを２枚確認し、その中から１枚を選んで手札に加え、残りのカードを墓地へ送る。\n「先史遺産技術」は１ターンに１枚しか発動できず、このカードを発動するターン自分は「先史遺産」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産－ピラミッド・アイ・タブレット",h:"おーぱーつ－ぴらみっど・あい・たぶれっと",e:"Chronomaly Pyramid Eye Tablet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「先史遺産」と名のついたモンスターの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシー・クィーンズ・ライト",h:"ぎゃらくしー・くぃーんず・らいと",e:"Galaxy Queen's Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル７以上のモンスター１体を対象として発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで対象のモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"グリモの魔導書",h:"ぐりものまどうしょ",e:"Spellbook of Secrets",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「グリモの魔導書」以外の「魔導書」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒュグロの魔導書",h:"ひゅぐろのまどうしょ",e:"Spellbook of Power",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの魔法使い族モンスター１体を対象として発動できる。\nこのターン、そのモンスターの攻撃力は１０００アップし、以下の効果を適用する。\n\n●そのモンスターが戦闘で相手モンスターを破壊した時に発動できる。\nデッキから「魔導書」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロの魔導書",h:"ねくろのまどうしょ",e:"Spellbook of Life",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分の墓地の魔法使い族モンスター１体をゲームから除外し、このカード以外の手札の「魔導書」と名のついた魔法カード１枚を相手に見せて発動できる。\n自分の墓地の魔法使い族モンスター１体を選択して表側攻撃表示で特殊召喚し、このカードを装備する。\nまた、装備モンスターのレベルは、このカードを発動するために除外した魔法使い族モンスターのレベル分だけ上がる。\n「ネクロの魔導書」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トーラの魔導書",h:"とーらのまどうしょ",e:"Spellbook of Wisdom",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの魔法使い族モンスター１体を対象として、以下の効果から１つを選択して発動できる。\n\n●このターン、その表側表示モンスターはこのカード以外の魔法カードの効果を受けない。\n\n●このターン、その表側表示モンスターは罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・シャトー",h:"まどるちぇ・しゃとー",e:"Madolche Chateau",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分の墓地に「マドルチェ」と名のついたモンスターが存在する場合、そのモンスターを全てデッキに戻す。\nこのカードがフィールド上に存在する限り、フィールド上の「マドルチェ」と名のついたモンスターの攻撃力・守備力は５００ポイントアップする。\nまた、「マドルチェ」と名のついたモンスターの効果によって、自分の墓地のモンスターがデッキに戻る場合、デッキに戻さず手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンチャン！？",h:"わんちゃん！？",e:"Where Arf Thou?",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにレベル１モンスターが存在する場合に発動できる。\nデッキからレベル１モンスター１体を手札に加える。\n発動後、このターン中に自分がこの効果で手札に加えたモンスターまたはその同名カードの召喚に成功しなかった場合、エンドフェイズに自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネレーション・フォース",h:"じぇねれーしょん・ふぉーす",e:"Generation Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにXモンスターが存在する場合に発動できる。\nデッキから「エクシーズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"岩投げエリア",h:"いわなげえりあ",e:"Catapult Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分のモンスターが戦闘で破壊される場合、代わりに自分のデッキから岩石族モンスター１体を墓地へ送る事ができる。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"大寒気",h:"だいかんき",e:"Cold Feet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このターン、自分は魔法・罠カードの効果の使用及び発動・セットはできない。",li:"",lk:[],ta:[],al:[]},
  {n:"攻撃の無敵化",h:"こうげきのむてきか",e:"Impenetrable Attack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"バトルフェイズ時にのみ、以下の効果から１つを選択して発動できる。\n\n●フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターはこのバトルフェイズ中、戦闘及びカードの効果では破壊されない。\n\n●このバトルフェイズ中、自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガラッシュ",h:"がががらっしゅ",e:"Gagagarush",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「ガガガ」と名のついたモンスターが、相手モンスターの効果の対象になった時に発動できる。\nその相手モンスターの効果を無効にし破壊する。\nその後、破壊したモンスターの攻撃力と守備力の内、高い方の数値分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒロイック・リベンジ・ソード",h:"ひろいっく・りべんじ・そーど",e:"Heroic Retribution Sword",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上の「ヒロイック」と名のついたモンスター１体に装備する。\n装備モンスターの戦闘によって発生する自分への戦闘ダメージは相手も受ける。\nまた、装備モンスターと戦闘を行った相手モンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ストーンヘンジ・メソッド",h:"すとーんへんじ・めそっど",e:"Stonehenge Methods",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「先史遺産」と名のついたモンスターが戦闘またはカードの効果によって破壊され墓地へ送られた時に発動できる。\nデッキからレベル４以下の「先史遺産」と名のついたモンスター１体を表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・マナー",h:"まどるちぇ・まなー",e:"Madolche Lesson",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「マドルチェ」と名のついたモンスター１体を選択してデッキに戻し、自分フィールド上に存在する全ての「マドルチェ」と名のついたモンスターの攻撃力・守備力は８００ポイントアップする。\nその後、自分の墓地のモンスター１体を選んでデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・ワルツ",h:"まどるちぇ・わるつ",e:"Madolche Waltz",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「マドルチェ」と名のついたモンスターが戦闘を行ったダメージ計算後、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・ティーブレイク",h:"まどるちぇ・てぃーぶれいく",e:"Madolche Tea Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地にモンスターが存在しない場合に発動できる。\n魔法・罠カードの発動を無効にし、そのカードを持ち主の手札に戻す。\n自分フィールド上に「マドルチェ・プディンセス」が存在する場合、さらに相手フィールド上のカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ソウル",h:"えくしーず・そうる",e:"Xyz Soul",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分または相手の墓地のエクシーズモンスター１体を選択して発動できる。\n自分フィールド上に存在する全てのモンスターの攻撃力は、エンドフェイズ時まで選択したモンスターのランク×２００ポイントアップする。\nその後、選択したモンスターをエクストラデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"強制退出装置",h:"きょうせいたいしゅつそうち",e:"Compulsory Escape Device",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いはそれぞれ自分フィールド上のモンスター１体を選び、そのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"手のひら返し",h:"てのひらがえし",e:"Turnabout",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"元々のレベルと異なるレベルのモンスターが、フィールド上に表側表示で存在する場合に発動できる。\nフィールド上のモンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の落とし穴",h:"れんごくのおとしあな",e:"Void Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が攻撃力２０００以上のモンスターを特殊召喚した時に発動できる。\nその攻撃力２０００以上のモンスター１体を選び、効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スリーカード",h:"すりーかーど",e:"Three of a Kind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にトークン以外の同名モンスターが３体以上存在する場合に発動できる。\n相手フィールド上のカード３枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ソウルドレイン",h:"そうるどれいん",e:"Soul Drain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１０００ライフポイントを払って発動できる。\nこのカードがフィールド上に存在する限り、ゲームから除外されているモンスターの効果及び墓地に存在するモンスターの効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"リ・バウンド",h:"り・ばうんど",e:"Rebound",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上のカードを手札に戻す効果を相手が発動した時に発動できる。\nその効果を無効にし、相手の手札・フィールド上からカードを１枚選んで墓地へ送る。\nまた、セットされたこのカードが相手によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラッキーパンチ",h:"らっきーぱんち",e:"Lucky Punch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\nコイントスを３回行い、３回とも表だった場合、自分はデッキからカードを３枚ドローする。\n３回とも裏だった場合、このカードを破壊する。\nまた、フィールド上に表側表示で存在するこのカードが破壊された場合、自分は６０００ライフポイントを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"オヤコーン",h:"おやこーん",e:"Papa-Corn",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"フィールド魔法カードが表側表示で存在する場合、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.6 先史遺産アトランタル",h:"なんばーず６ おーぱーつあとらんたる",e:"Number 6: Chronomaly Atlandis",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:3000,tx:"レベル６モンスター×２\n①：このカードがX召喚に成功した時、自分の墓地の「No.」モンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力の半分アップする。\n③：１ターンに１度、このカードのX素材を１つ取り除き、このカードの効果で装備している自分の魔法＆罠ゾーンのカード１枚を墓地へ送って発動できる。\n相手のLPを半分にする。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラクル・コンタクト",h:"みらくる・こんたくと",e:"Miracle Contact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールド・墓地から、融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、「E・HERO ネオス」を融合素材とするその「E・HERO」融合モンスター１体を召喚条件を無視してEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アドバンスド・ダーク",h:"あどばんすど・だーく",e:"Advanced Dark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「宝玉獣」モンスター及び墓地の「宝玉獣」モンスターは全て闇属性になる。\n②：このカードがフィールドゾーンに存在する限り、「究極宝玉神」モンスターの攻撃対象になったモンスターの効果はそのバトルフェイズの間だけ無効化される。\n③：自分の「宝玉獣」モンスターが戦闘を行うダメージ計算時に、デッキから「宝玉獣」モンスター１体を墓地へ送って発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・シーホース",h:"さんだー・しーほーす",e:"Thunder Sea Horse",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「サンダー・シーホース」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードを手札から捨てて発動できる。\nデッキから攻撃力１６００以下の雷族・光属性・レベル４の同名モンスター２体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.40 ギミック・パペット－ヘブンズ・ストリングス",h:"なんばーず４０ ぎみっく・ぱぺっと－へぶんず・すとりんぐす",e:"Number 40: Gimmick Puppet of Strings",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"レベル８モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカード以外のフィールドの表側表示モンスター全てにストリングカウンターを１つずつ置く。\n②：このカードの①の効果でストリングカウンターを置いた場合、次の相手エンドフェイズに発動する。\nストリングカウンターが置かれているモンスターを全て破壊し、破壊した数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の影－紫炎",h:"ろくぶしゅうのかげ－しえん",e:"Shadow of the Six Samurai - Shien",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:400,tx:"レベル４「六武衆」モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの攻撃力２０００未満の「六武衆」モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力はターン終了時まで２０００になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇龍 ポセイドラ",h:"かいおうりゅう　ぽせいどら",e:"Poseidra, the Atlantean Dragon",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2800,de:1600,tx:"自分フィールド上のレベル３以下の水属性モンスター３体をリリースして発動できる。\nこのカードを手札または墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、フィールド上の魔法・罠カードを全て持ち主の手札に戻す。\nこの効果でカードを３枚以上手札に戻した場合、相手フィールド上の全てのモンスターの攻撃力は手札に戻したカードの数×３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇の竜騎隊",h:"かいおうのりゅうきたい",e:"Atlantean Dragoons",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードがモンスターゾーンに存在する限り、自分のレベル３以下の海竜族モンスターは直接攻撃できる。\n②：このカードが水属性モンスターの効果を発動するために墓地へ送られた場合に発動する。\nデッキから「海皇の竜騎隊」以外の海竜族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇の狙撃兵",h:"かいおうのそげきへい",e:"Atlantean Marksman",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"このカードが相手ライフに戦闘ダメージを与えた時、デッキから「海皇の狙撃兵」以外のレベル４以下の「海皇」と名のついた海竜族モンスター１体を特殊召喚できる。\nまた、このカードが水属性モンスターの効果を発動するために墓地へ送られた時、相手フィールド上にセットされたカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇の重装兵",h:"かいおうのじゅうそうへい",e:"Atlantean Heavy Infantry",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズにレベル４以下の海竜族モンスター１体を召喚できる。\n②：このカードが水属性モンスターの効果を発動するために墓地へ送られた場合、相手フィールドの表側表示のカード１枚を対象として発動する。\nその相手の表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇の咆哮",h:"かいおうのほうこう",e:"Call of the Atlanteans",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地のレベル３以下の海竜族モンスター３体を選択して発動できる。\n選択したモンスター３体を墓地から特殊召喚する。\nこのカードを発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガカイザー",h:"がががかいざー",e:"Gagaga Caesar",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカードは自分フィールド上にこのカード以外の「ガガガ」と名のついたモンスターが存在する場合のみ攻撃できる。\nまた、１ターンに１度、自分の墓地のモンスター１体をゲームから除外して発動できる。\n自分フィールド上の全ての「ガガガ」と名のついたモンスターのレベルは、この効果を発動するためにゲームから除外したモンスターと同じレベルになる。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルブレーダー",h:"ぶるぶれーだー",e:"Bull Blader",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその戦闘によって発生するお互いのプレイヤーへの戦闘ダメージは０になり、ダメージ計算後にその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アチャチャチャンバラー",h:"あちゃちゃちゃんばらー",e:"Achacha Chanbara",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"ライフポイントにダメージを与える魔法・罠・効果モンスターの効果が発動した時に発動できる。\nこのカードを手札から特殊召喚し、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"モグモール",h:"もぐもーる",e:"Mogmole",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"フィールド上のこのカードが破壊され墓地へ送られた時、このカードを墓地から表側守備表示で特殊召喚できる。\n「モグモール」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"グランドラン",h:"ぐらんどらん",e:"Grandram",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:1400,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トライポッド・フィッシュ",h:"とらいぽっど・ふぃっしゅ",e:"Tripod Fish",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:300,de:1300,tx:"このカードが墓地からの特殊召喚に成功した時、フィールド上の魚族・海竜族・水族モンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"ディープ・スィーパー",h:"でぃーぷ・すぃーぱー",e:"Deep Sweeper",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"このカードをリリースして発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C エクストラ・ソード",h:"ひろいっく・ちゃれんじゃー　えくすとら・そーど",e:"Heroic Challenger - Extra Sword",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードを素材としたエクシーズモンスターは以下の効果を得る。\n●このエクシーズ召喚に成功した時、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C 夜襲のカンテラ",h:"ひろいっく・ちゃれんじゃー　やしゅうのかんてら",e:"Heroic Challenger - Night Watchman",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:300,tx:"このカードが相手フィールド上に守備表示で存在するモンスターを攻撃した場合、ダメージ計算前にそのモンスターを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"惑星探査車",h:"ぷらねっと・ぱすふぁいんだー",e:"Planet Pathfinder",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードをリリースして発動できる。\nデッキからフィールド魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽風帆船",h:"そーらー・うぃんどじゃまー",e:"Solar Wind Jammer",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2400,tx:"自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの元々の攻撃力・守備力は半分になる。\nまた、自分のスタンバイフェイズ毎にこのカードのレベルを１つ上げる。\n「太陽風帆船」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣アバコーンウェイ",h:"もんしょうじゅうあばこーんうぇい",e:"Heraldic Beast Aberconway",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードが墓地に存在する場合、このカード以外の自分の墓地の「紋章獣アバコーンウェイ」１体をゲームから除外して発動できる。\n自分の墓地の「紋章獣」と名のついたモンスター１体を選択して手札に加える。\n「紋章獣アバコーンウェイ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣ベルナーズ・ファルコン",h:"もんしょうじゅうべるなーず・ふぁるこん",e:"Heraldic Beast Berners Falcon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分フィールド上の全てのレベル５以上のモンスターのレベルを４にする。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスリンデ",h:"まーめいる－あびすりんで",e:"Mermail Abysslinde",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"フィールド上のこのカードが破壊され墓地へ送られた場合、デッキから「水精鱗－アビスリンデ」以外の「水精鱗」と名のついたモンスター１体を特殊召喚できる。\n「水精鱗－アビスリンデ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスグンデ",h:"まーめいる－あびすぐんで",e:"Mermail Abyssgunde",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードが手札から墓地へ捨てられた場合、自分の墓地から「水精鱗－アビスグンデ」以外の「水精鱗」と名のついたモンスター１体を選択して特殊召喚できる。\n「水精鱗－アビスグンデ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスヒルデ",h:"まーめいる－あびすひるで",e:"Mermail Abysshilde",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1300,de:400,tx:"このカードが墓地へ送られた場合、手札から「水精鱗－アビスヒルデ」以外の「水精鱗」と名のついたモンスター１体を特殊召喚できる。\n「水精鱗－アビスヒルデ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスタージ",h:"まーめいる－あびすたーじ",e:"Mermail Abyssturge",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードが召喚・特殊召喚に成功した時、手札の水属性モンスター１体を墓地へ捨てて発動できる。\n自分の墓地からレベル３以下の水属性モンスター１体を選択して手札に加える。\n「水精鱗－アビスタージ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスパイク",h:"まーめいる－あびすぱいく",e:"Mermail Abysspike",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが召喚・特殊召喚に成功した時、手札の水属性モンスター１体を墓地へ捨てて発動できる。\nデッキからレベル３の水属性モンスター１体を手札に加える。\n「水精鱗－アビスパイク」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスラング",h:"まーめいる－あびすらんぐ",e:"Mermail Abysslung",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、相手は他の水属性モンスターを攻撃対象に選択できない。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の水属性モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－メガロアビス",h:"まーめいる－めがろあびす",e:"Mermail Abyssmegalo",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1900,tx:"①：手札からこのカード以外の水属性モンスター２体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した時に発動できる。\nデッキから「アビス」魔法・罠カード１枚を手札に加える。\n③：このカード以外の自分フィールドの表側攻撃表示の水属性モンスター１体をリリースして発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導術士 ラパンデ",h:"まどうじゅつし　らぱんで",e:"Stoic of Prophecy",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードが墓地へ送られた時、デッキからレベル３の「魔導」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導老士 エアミット",h:"まどうろうし　えあみっと",e:"Hermit of Prophecy",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"このカードがフィールド上に表側表示で存在する限り、「魔導書」と名のついた魔法カードが発動する度に、このカードのレベルは２つ上がり、攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導教士 システィ",h:"まどうきょうし　しすてぃ",e:"Justice of Prophecy",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"自分が「魔導書」と名のついた魔法カードを発動した自分のターンのエンドフェイズ時、フィールド上のこのカードをゲームから除外して発動できる。\nデッキから光属性または闇属性の魔法使い族・レベル５以上のモンスター１体と、「魔導書」と名のついた魔法カード１枚を手札に加える。\n「魔導教士 システィ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導皇士 アンプール",h:"まどうこうし　あんぷーる",e:"Emperor of Prophecy",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカード以外の自分フィールド上に表側表示で存在する魔法使い族モンスター１体と、自分の墓地の「魔導書」と名のついたカード１枚をゲームから除外して発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択し、エンドフェイズ時までコントロールを得る。\n「魔導皇士 アンプール」の効果は１ターンに１度しか使用できず、この効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・クロワンサン",h:"まどるちぇ・くろわんさん",e:"Madolche Cruffssant",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nまた、１ターンに１度、このカード以外の自分フィールド上の「マドルチェ」と名のついたカード１枚を選択して発動できる。\n選択したカードを手札に戻し、このカードのレベルを１つ上げ、攻撃力を３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・マーマメイド",h:"まどるちぇ・まーまめいど",e:"Madolche Marmalmaide",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードがリバースした時、自分の墓地の「マドルチェ」と名のついた魔法・罠カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・メッセンジェラート",h:"まどるちぇ・めっせんじぇらーと",e:"Madolche Messengelato",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが特殊召喚に成功した時に発動できる。\nデッキから「マドルチェ」魔法・罠カード１枚を手札に加える。\nこの効果は自分フィールドに獣族の「マドルチェ」モンスターが存在する場合に発動と処理ができる。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"アビス・ウォリアー",h:"あびす・うぉりあー",e:"Abyss Warrior",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"１ターンに１度、手札から水属性モンスター１体を墓地へ捨て、自分または相手の墓地のモンスター１体を選択して発動できる。\n選択したモンスターを持ち主のデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"スノーマン・クリエイター",h:"すのーまん・くりえいたー",e:"Snowman Creator",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分フィールドの水属性モンスターの数だけ、相手フィールドの表側表示モンスターにアイスカウンターを置く。\nこの効果でアイスカウンターを３つ以上置いた場合、さらに相手フィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュボーグ－プランター",h:"ふぃっしゅぼーぐ－ぷらんたー",e:"Fishborg Planter",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが墓地に存在する限り１度だけ発動できる。\n自分のデッキの一番上のカードを墓地へ送る。\n墓地へ送ったカードが水属性モンスターだった場合、さらにこのカードを墓地から特殊召喚する。\n「フィッシュボーグ－プランター」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"素早いアンコウ",h:"すばやいあんこう",e:"Nimble Angler",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"このカードが手札・デッキから墓地へ送られた場合、デッキから「素早いアンコウ」以外のレベル３以下の「素早い」と名のついたモンスターを２体まで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"絶海の騎士",h:"ぜっかいのきし",e:"Shore Knight",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"フィールド上に表側表示で存在するこのカードの表示形式が変更された時、デッキから水属性モンスター１体を墓地へ送る。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機海竜プレシオン",h:"きかいりゅうぷれしおん",e:"Mecha Sea Dragon Plesion",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1800,tx:"自分フィールド上に海竜族モンスターが存在する場合、このカードはリリースなしで召喚できる。\n１ターンに１度、自分フィールド上の水属性モンスター１体をリリースする事で、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタル化寄生生物－ソルタイト",h:"めたるかきせいせいぶつ－そるたいと",e:"Metallizing Parasite - Soltite",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["ユニオン","効果"],ma:"",tp:"",at:1000,de:500,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上のモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターは相手の効果モンスターの効果の対象にならず、相手の効果モンスターの効果では破壊されない。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘で破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"氷霊神ムーラングレイス",h:"ひょうれいしんむーらんぐれいす",e:"Moulinglacia the Elemental Lord",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の水属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\n相手の手札をランダムに２枚選んで捨てる。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ハウスダストン",h:"はうすだすとん",e:"House Duston",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：フィールドの表側表示のこのカードが相手の効果で破壊され墓地へ送られた時、または、ダメージステップ開始時に表側表示だったこのカードが相手モンスターとの戦闘で破壊され墓地へ送られた時に発動できる。\n手札・デッキから「ダストン」モンスターを任意の数だけ選び、お互いのフィールドに同じ数ずつ特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"子型ペンギン",h:"こがたぺんぎん",e:"Puny Penguin",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"リバースしたこのカードが墓地へ送られた時、自分の墓地から「子型ペンギン」以外の「ペンギン」と名のついたモンスター１体を選択し、表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"はぐれ者傭兵部隊",h:"はぐれものようへいぶたい",e:"Missing Force",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上にこのカード以外のモンスターが存在しない場合、このカードをリリースして発動できる。\n相手フィールド上のモンスター１体を選択し、エンドフェイズ時までコントロールを得る。\nこの効果を発動するターン、自分はモンスターを特殊召喚できず、バトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.32 海咬龍シャーク・ドレイク・バイス",h:"かおすなんばーず３２ かいこうりゅうしゃーく・どれいく・ばいす",e:"Number C32: Shark Drake Veiss",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2100,tx:"水属性レベル４モンスター×４\nこのカードは自分フィールドの「No.32 海咬龍シャーク・ドレイク」の上に重ねてX召喚する事もできる。\n①：自分のLPが１０００以下の場合、自分の墓地からモンスター１体を除外し、このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力を自分ターン終了時まで０にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"隻眼のスキル・ゲイナー",h:"せきがんのすきる・げいなー",e:"One-Eyed Skill Gainer",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2600,tx:"レベル４モンスター×３\nこのカードのエクシーズ素材を１つ取り除き、相手フィールド上のエクシーズモンスター１体を選択して発動できる。\nこのカードは選択したモンスターと同名カードとして扱い、同じ効果を得る。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガガンマン",h:"ががががんまん",e:"Gagaga Cowboy",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2400,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの表示形式によって以下の効果を適用する。\n\n●攻撃表示：このターン、このカードが相手モンスターに攻撃するダメージステップの間、このカードの攻撃力は１０００アップし、その相手モンスターの攻撃力は５００ダウンする。\n\n●守備表示：相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"H－C ガーンデーヴァ",h:"ひろいっく－ちゃんぴおん　がーんでーう゛ぁ",e:"Heroic Champion - Gandiva",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"戦士族レベル４モンスター×２\n相手フィールド上にレベル４以下のモンスターが特殊召喚された時、このカードのエクシーズ素材を１つ取り除く事で、その特殊召喚されたモンスターを破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"H－C クサナギ",h:"ひろいっく－ちゃんぴおん　くさなぎ",e:"Heroic Champion - Kusanagi",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2400,tx:"戦士族レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n罠カードの発動を無効にし破壊する。\nその後、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.9 天蓋星ダイソン・スフィア",h:"なんばーず９ てんがいせいだいそん・すふぃあ",e:"Number 9: Dyson Sphere",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:3000,tx:"レベル９モンスター×２\n①：このカードより高い攻撃力を持つモンスターが相手フィールドに存在する場合、自分メインフェイズ１にこのカードのX素材を１つ取り除いて発動できる。\nこのターン、このカードは直接攻撃できる。\n②：X素材を持っているこのカードが攻撃されたバトルステップに１度、発動できる。\nその攻撃を無効にする。\n③：このカードがX素材の無い状態で攻撃対象に選択された時、自分の墓地のモンスター２体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.8 紋章王ゲノム・ヘリター",h:"なんばーず８ もんしょうおうげのむ・へりたー",e:"Number 8: Heraldic King Genom-Heritage",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"レベル４「紋章獣」モンスター×２\n①：１ターンに１度、相手フィールドのXモンスター１体を対象として発動できる。\nこのカードは、元々の攻撃力がそのモンスターの攻撃力と同じになり、そのモンスターの元々のカード名・効果と同じカード名・効果を得る。\nその後、対象のモンスターの攻撃力は０になり、効果は無効化される。\nこの効果はエンドフェイズまで適用される。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－ガイオアビス",h:"まーめいる－がいおあびす",e:"Mermail Abyssgaios",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:1600,tx:"水属性レベル７モンスター×２\nエクシーズ素材を持っているこのカードがフィールド上に表側表示で存在する限り、レベル５以上のモンスターは攻撃できない。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力よりも低い攻撃力を持つ相手フィールド上のモンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導皇聖 トリス",h:"まどうこうじょう　とりす",e:"Empress of Prophecy",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1700,tx:"魔法使い族レベル５モンスター×２\nこのカードの攻撃力は自分フィールド上のエクシーズ素材の数×３００ポイントアップする。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分のデッキをシャッフルする。\nその後、デッキの上からカードを５枚めくり、その中の「魔導書」と名のついたカードの数までフィールド上のモンスターを選んで破壊する。\nその後、めくったカードを好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"クイーンマドルチェ・ティアラミス",h:"くいーんまどるちぇ・てぃあらみす",e:"Madolche Queen Tiaramisu",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2100,tx:"レベル４「マドルチェ」モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の「マドルチェ」カードを２枚まで対象として発動できる。\nそのカードをデッキに戻し、戻した数まで相手フィールドのカードを選んで持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"スノーダスト・ジャイアント",h:"すのーだすと・じゃいあんと",e:"Snowdust Giant",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:800,tx:"水属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n手札の水属性モンスターを任意の数だけ相手に見せ、見せた数だけフィールド上に表側表示で存在するモンスターにアイスカウンターを置く。\nこのカードがフィールド上に表側表示で存在する限り、水属性以外のフィールド上のモンスターの攻撃力は、フィールド上のアイスカウンターの数×２００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"覚醒の勇士 ガガギゴ",h:"かくせいのゆうし　ががぎご",e:"Gagagigo the Risen",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["エクシーズ"],ma:"",tp:"",at:2950,de:2800,tx:"レベル４モンスター×３",li:"",lk:[],ta:[],al:[]},
  {n:"ワンショット・ワンド",h:"わんしょっと・わんど",e:"One-Shot Wand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"魔法使い族モンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nまた、装備モンスターが戦闘を行ったダメージ計算後、このカードを破壊してデッキからカードを１枚ドローできる。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元海溝",h:"いじげんかいこう",e:"Different Dimension Deepsea Trench",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分の手札・フィールド上・墓地の水属性モンスター１体を選んでゲームから除外する。\nその後、フィールド上に表側表示で存在するこのカードが破壊された時、このカードの効果で除外したモンスターを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"タンホイザーゲート",h:"たんほいざーげーと",e:"Tannhauser Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の攻撃力１０００以下で同じ種族のモンスター２体を選択して発動できる。\n選択した２体のモンスターは、その２体のレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"重力砲",h:"ぐらう゛ぃてぃ・ぶらすたー",e:"Gravity Blaster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターにのみ装備可能。\n１ターンに１度、装備モンスターの攻撃力を４００ポイントアップできる。\nまた、装備モンスターが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"高等紋章術",h:"はい・めだりおん・あーつ",e:"Advanced Heraldry Art",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「紋章獣」と名のついたモンスター２体を選択して発動できる。\n選択したモンスター２体を特殊召喚し、その２体のみを素材としてエクシーズモンスター１体をエクシーズ召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アビスケイル－クラーケン",h:"あびすけいる－くらーけん",e:"Abyss-scale of the Kraken",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「水精鱗」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上で発動した効果モンスターの効果を無効にする。\nその後、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"忘却の都 レミューリア",h:"ぼうきゃくのみやこ　れみゅーりあ",e:"Lemuria, the Forgotten City",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのカード名は「海」として扱う。\nこのカードがフィールド上に存在する限り、フィールド上の水属性モンスターの攻撃力・守備力は２００ポイントアップする。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードがフィールド上に存在する限り、自分フィールド上の水属性モンスターの数と同じ数だけ、自分フィールド上の水属性モンスターのレベルをエンドフェイズ時まで上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"アルマの魔導書",h:"あるまのまどうしょ",e:"Spellbook of Eternity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「アルマの魔導書」以外のゲームから除外されている自分の「魔導書」と名のついた魔法カード１枚を選択して手札に加える。\n「アルマの魔導書」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲーテの魔導書",h:"げーてのまどうしょ",e:"Spellbook of Fate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に魔法使い族モンスターが存在する場合、自分の墓地の「魔導書」と名のついた魔法カードを３枚までゲームから除外して発動できる。\nこのカードを発動するために除外した魔法カードの数によって以下の効果を適用する。\n「ゲーテの魔導書」は１ターンに１枚しか発動できない。\n\n●１枚：フィールド上にセットされた魔法・罠カード１枚を選んで持ち主の手札に戻す。\n\n●２枚：フィールド上のモンスター１体を選んで裏側守備表示または表側攻撃表示にする。\n\n●３枚：相手フィールド上のカード１枚を選んでゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書院ラメイソン",h:"まどうしょいんらめいそん",e:"The Grand Spellbook Tower",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドまたは自分の墓地に魔法使い族モンスターが存在する場合、自分スタンバイフェイズに発動できる。\n自分の墓地から「魔導書院ラメイソン」以外の「魔導書」魔法カード１枚を選んでデッキの一番下に戻し、自分はデッキから１枚ドローする。\n②：このカードが相手によって破壊され墓地へ送られた時に発動できる。\n自分の墓地の「魔導書」魔法カードの数以下のレベルを持つ魔法使い族モンスター１体を手札・デッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・チケット",h:"まどるちぇ・ちけっと",e:"Madolche Ticket",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分のフィールド・墓地の「マドルチェ」カードが効果で自分の手札・デッキに戻った場合に発動する。\nデッキから「マドルチェ」モンスター１体を手札に加える。\n自分フィールドに天使族の「マドルチェ」モンスターが存在する場合、手札に加えず攻撃表示で特殊召喚する事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"禁じられた聖衣",h:"きんじられたせいい",e:"Forbidden Dress",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時までそのモンスターは、攻撃力が６００ダウンし、効果の対象にならず、効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"最期の同調",h:"さいごのどうちょう",e:"Final Gesture",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のレベル３モンスター１体を選択して発動できる。\n選択したモンスターと同名のモンスター１体を自分の手札・墓地から選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこのターンのエンドフェイズ時、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"精神汚染",h:"せいしんおせん",e:"Mind Pollutant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札からモンスター１体を捨てて発動できる。\nそのモンスターと同じレベルを持つ相手フィールド上のモンスター１体を選択し、エンドフェイズ時までコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"謙虚な番兵",h:"けんきょなばんぺい",e:"The Humble Sentry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札を公開し、その中からカードを１枚選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"バトル・ブレイク",h:"ばとる・ぶれいく",e:"Battle Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\n相手は手札からモンスター１体を見せてこのカードの効果を無効にできる。\n見せなかった場合、その攻撃モンスターを破壊し、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"バブル・ブリンガー",h:"ばぶる・ぶりんがー",e:"Bubble Bringer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、レベル４以上のモンスターは直接攻撃できない。\n自分のターンにフィールド上に表側表示で存在するこのカードを墓地へ送る事で、自分の墓地の水属性・レベル３以下の同名モンスター２体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒロイック・ギフト",h:"ひろいっく・ぎふと",e:"Heroic Gift",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のライフポイントが２０００以下の場合に発動できる。\n相手のライフポイントを８０００にして自分のデッキからカードを２枚ドローする。\n「ヒロイック・ギフト」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒロイック・アドバンス",h:"ひろいっく・あどばんす",e:"Heroic Advance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「ヒロイック」と名のついたモンスターが攻撃対象に選択された時、自分フィールド上の他のレベル４以下の「ヒロイック」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はバトルフェイズ終了時まで倍になり、選択したモンスターに攻撃対象を移し替えてダメージ計算を行う。\nお互いのモンスターはこの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ熱戦！！",h:"えくしーずねっせん！！",e:"Xyz Xtreme !!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズモンスターが戦闘によって破壊された時、１０００ライフポイントを払って発動できる。\nお互いのプレイヤーは、破壊されたモンスターのランク以下のランクを持つエクシーズモンスター１体をそれぞれのエクストラデッキから選んで相手に見せる。\n攻撃力の低いモンスターを見せたプレイヤーは、相手が見せたモンスターの攻撃力と、自分が見せたモンスターの攻撃力の差分のダメージを受ける。\n相手がモンスターを見せなかった場合、自分が見せたモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アビスコール",h:"あびすこーる",e:"Abyss-squall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「水精鱗」と名のついたモンスター３体を選択して表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、攻撃宣言できず、エンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"アビスフィアー",h:"あびすふぃあー",e:"Abyss-sphere",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"デッキから「水精鱗」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nまた、このカードがフィールド上に存在する限り、自分は魔法カードを発動できない。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nこのカードは発動後、次の相手のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"アビストローム",h:"あびすとろーむ",e:"Abyss-strom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「海」を墓地へ送って発動できる。\nフィールド上の魔法・罠カードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・ハッピーフェスタ",h:"まどるちぇ・はっぴーふぇすた",e:"Madolchepalooza",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札から「マドルチェ」と名のついたモンスターを任意の数だけ特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"好敵手の記憶",h:"とものきおく",e:"Memory of an Adversary",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\n自分は攻撃モンスターの攻撃力分のダメージを受け、そのモンスターをゲームから除外する。\n次の相手ターンのエンドフェイズ時、この効果で除外したモンスターを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ディフレクター",h:"まじっく・でぃふれくたー",e:"Magic Deflector",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン、フィールド上の装備・フィールド・永続・速攻魔法カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力誘爆",h:"まりょくゆうばく",e:"That Wacky Alchemy!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の魔法＆罠カードゾーンに表側表示で存在する魔法カードが墓地へ送られた場合に発動できる。\nフィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"キャッシュバック",h:"きゃっしゅばっく",e:"Cash Back",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手がライフポイントを払って発動した効果モンスターの効果・魔法・罠カードの発動を無効にし、そのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"天下統一",h:"てんかとういつ",e:"Unification",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのスタンバイフェイズ時、ターンプレイヤーは自分フィールド上のモンスターのレベルの内から１つを選び、そのレベル以外のレベルを持つ自分フィールド上に表側表示で存在するモンスターを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"しっぺ返し",h:"しっぺがえし",e:"Retort",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地に存在する魔法・罠カードと同名のカードを相手が発動した時に発動できる。\nその発動を無効にし破壊する。\nその後、この効果で発動を無効にしたカードと同名のカードを自分の墓地から１枚選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－極夜のダマスカス",h:"ぶらっくふぇざー－きょくやのだますかす",e:"Blackwing - Damascus the Polar Night",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:700,tx:"①：このカードを手札から捨て、自分フィールドの「BF」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"OToサンダー",h:"おとさんだー",e:"Pahunder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1300,de:600,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n手札から「OToサンダー」以外の雷族・光属性・レベル４のモンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"OKaサンダー",h:"おかさんだー",e:"Mahunder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n手札から「OKaサンダー」以外の雷族・光属性・レベル４のモンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ONeサンダー",h:"おねさんだー",e:"Sishunder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"このカードが召喚に成功した時、「ONeサンダー」以外の自分の墓地の雷族・光属性・レベル４・攻撃力１６００以下のモンスター１体を選択してゲームから除外できる。\nこのターンのエンドフェイズ時にそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.91 サンダー・スパーク・ドラゴン",h:"なんばーず９１ さんだー・すぱーく・どらごん",e:"Number 91: Thunder Spark Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"レベル４モンスター×３\n①：１ターンに１度、このカードのX素材を以下の数だけ取り除き、その効果を発動できる。\n\n●３つ：このカード以外のフィールドの表側表示モンスターを全て破壊する。\n\n●５つ：相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリット変換装置",h:"すぴりっとへんかんそうち",e:"Spirit Converter",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n自分フィールド上の光属性・レベル４モンスター１体を、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。\nまた、このカードがフィールド上から墓地へ送られた時、自分の墓地の雷族・光属性・レベル４の同名モンスター２体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"モノ・シンクロン",h:"もの・しんくろん",e:"Mono Synchron",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをシンクロ素材とする場合、他のシンクロ素材モンスターはレベル４以下の戦士族・機械族モンスターでなければならず、そのレベルは１として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"スチーム・シンクロン",h:"すちーむ・しんくろん",e:"Steam Synchron",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:800,tx:"①：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士ガウェイン",h:"せいきしがうぇいん",e:"Noble Knight Gawayn",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:500,tx:"自分フィールド上に光属性の通常モンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導鬼士 ディアール",h:"まどうきし　でぃあーる",e:"Prophecy Destroyer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードが墓地に存在する場合、自分の墓地の「魔導書」と名のついた魔法カード３枚をゲームから除外して発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトレイ マドール",h:"らいとれい　まどーる",e:"Lightray Madoor",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:3000,tx:"ゲームから除外されている自分の光属性モンスターが３体以上の場合、このカードは手札から特殊召喚できる。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"青竜の忍者",h:"せいりゅうのにんじゃ",e:"Blue Dragon Ninja",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"１ターンに１度、手札から「忍者」と名のついたモンスター１体と「忍法」と名のついたカード１枚を捨て、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは攻撃できず、効果は無効化される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"イマイルカ",h:"いまいるか",e:"Imairuka",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"フィールド上のこのカードが相手によって破壊され墓地へ送られた時、自分のデッキの一番上のカードを墓地へ送る。\n墓地へ送ったカードが水属性モンスターだった場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"リバイバルゴーレム",h:"りばいばるごーれむ",e:"Revival Golem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"このカードがデッキから墓地へ送られた時、以下の効果から１つを選択して発動する。\n「リバイバルゴーレム」の効果は１ターンに１度しか使用できない。\n\n●このカードを墓地から特殊召喚する。\n\n●このカードを墓地から手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖剣ガラティーン",h:"せいけんがらてぃーん",e:"Noble Arms - Gallatin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「聖剣ガラティーン」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターの攻撃力は１０００アップし、自分スタンバイフェイズ毎に２００ダウンする。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合、自分フィールドの戦士族の「聖騎士」モンスター１体を対象として発動できる。\nその自分の戦士族の「聖騎士」モンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書庫クレッセン",h:"まどうしょこくれっせん",e:"Spellbook Library of the Crescent",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に「魔導書」と名のついた魔法カードが存在しない場合に発動できる。\nデッキから「魔導書」と名のついた魔法カードを３種類選んで相手に見せ、相手はその中からランダムに１枚選ぶ。\n相手が選んだカード１枚を自分の手札に加え、残りのカードをデッキに戻す。\n「魔導書庫クレッセン」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「魔導書」と名のついたカード以外の魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アドバンス・ゾーン",h:"あどばんす・ぞーん",e:"Advance Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分がモンスターのアドバンス召喚に成功したターンのエンドフェイズ時に発動できる。\nこのターン自分がアドバンス召喚のためにリリースしたモンスターの数によって以下の効果を適用する。\n\n●１体以上：相手フィールド上にセットされたカード１枚を選んで破壊する。\n\n●２体以上：デッキからカードを１枚ドローする。\n\n●３体以上：自分の墓地のモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"忍法 影縫いの術",h:"にんぽう　かげぬいのじゅつ",e:"Ninjitsu Art of Shadow Sealing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「忍者」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のモンスター１体を選択してゲームから除外する。\nそのモンスターがゲームから除外されている限り、そのモンスターカードゾーンは使用できない。\nこのカードがフィールド上から離れた時、この効果で除外したモンスターを同じ表示形式で元のモンスターカードゾーンに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の白き龍",h:"れじぇんだりー・どらごん・おぶ・ほわいと",e:"Legendary Dragon of White",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル８モンスター×３\nこのカードはエクシーズ召喚でしか特殊召喚できない。\nドラゴン族モンスターをエクシーズ素材として持っているこのカードが直接攻撃によって相手ライフを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の闇の魔導師",h:"れじぇんだりー・まじしゃん・おぶ・だーく",e:"Legendary Magician of Dark",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル７モンスター×３\nこのカードはエクシーズ召喚でしか特殊召喚できない。\n魔法使い族モンスターをエクシーズ素材として持っているこのカードが直接攻撃によって相手ライフを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"No.69 紋章神コート・オブ・アームズ",h:"なんばーず６９ ごっど・めだりおんこーと・おぶ・あーむず",e:"Number 69: Heraldry Crest",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1400,tx:"レベル４モンスター×３\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\nこのカード以外のフィールドの全てのXモンスターの効果は無効化される。\n②：このカード以外のフィールドのXモンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはそのモンスターの元々のカード名・効果と同じカード名・効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"No.88 ギミック・パペット－デステニー・レオ",h:"なんばーず８８ ぎみっく・ぱぺっと－ですてにー・れお",e:"Number 88: Gimmick Puppet of Leo",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:2300,tx:"レベル８モンスター×３\nこのカードにデステニーカウンターが３つ置かれた時、自分はデュエルに勝利する。\n①：１ターンに１度、自分の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードのX素材を１つ取り除き、このカードにデステニーカウンターを１つ置く。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"呪符竜",h:"あみゅれっと・どらごん",e:"Amulet Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2500,tx:"「ブラック・マジシャン」＋ドラゴン族モンスター\nこのカードは上記カードを融合素材にした融合召喚または「ティマイオスの眼」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、自分・相手の墓地の魔法カードを任意の数だけ対象として発動する。\nそのカードを除外し、このカードの攻撃力はその除外したカードの数×１００アップする。\n②：このカードが破壊された場合、自分の墓地の魔法使い族モンスター１体を対象として発動できる。\nその魔法使い族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ティマイオスの眼",h:"てぃまいおすのまなこ",e:"The Eye of Timaeus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「伝説の竜 ティマイオス」としても扱う。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ブラック・マジシャン」モンスター１体を対象として発動できる。\nそのモンスターを融合素材として墓地へ送り、そのカード名が融合素材として記されている融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オレイカルコスの結界",h:"おれいかるこすのけっかい",e:"The Seal of Orichalcos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードはデュエル中に１枚しか発動できない。\n①：このカードの発動時の効果処理として、自分フィールドに特殊召喚されたモンスターが存在する場合、そのモンスターを全て破壊する。\n②：自分はEXデッキからモンスターを特殊召喚できない。\n③：自分フィールドのモンスターの攻撃力は５００アップする。\n④：自分フィールドに表側攻撃表示モンスターが２体以上存在する場合、自分フィールドの攻撃力が一番低いモンスターを相手は攻撃対象に選択できない。\n⑤：このカードは１ターンに１度だけ効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎星侯－ホウシン",h:"えんせいこう－ほうしん",e:"Brotherhood of the Fire Fist - Horse Prince",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"炎属性チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\nデッキから炎属性・レベル３モンスター１体を特殊召喚する。\n②：このカードがS召喚に成功したターン、自分はレベル５以上のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎星師－チョウテン",h:"えんせいし－ちょうてん",e:"Brotherhood of the Fire Fist - Spirit",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\nこのカードをS素材とする場合、獣戦士族モンスターのS召喚にしか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の守備力２００以下の炎属性・レベル３モンスター１体を対象として発動できる。\nその炎属性モンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚に成功したターン、獣戦士族以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎星皇－チョウライオ",h:"えんせいおう－ちょうらいお",e:"Brotherhood of the Fire Fist - Lion Emperor",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:200,tx:"炎属性レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の炎属性モンスター１体を対象として発動できる。\nその炎属性モンスターを手札に加える。\nこの効果の発動後、ターン終了時まで自分は対象のモンスター及びその同名モンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.22 不乱健",h:"なんばーず２２ ふらんけん",e:"Number 22: Zombiestein",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4500,de:1000,tx:"闇属性レベル８モンスター×２\nこのカードはX召喚でしか特殊召喚できない。\n①：１ターンに１度、このカードがフィールドに攻撃表示で存在する場合、このカードのX素材を１つ取り除き、手札を１枚墓地へ送り、相手フィールドの表側表示のカード１枚を対象として発動できる。\nこのカードを守備表示にし、対象のカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"バハムート・シャーク",h:"ばはむーと・しゃーく",e:"Bahamut Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"水属性レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nEXデッキからランク３以下の水属性Xモンスター１体を特殊召喚する。\nこのターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・パイレーツ",h:"ふぉとん・ぱいれーつ",e:"Photon Pirate",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分のメインフェイズ時に自分の墓地の「フォトン」と名のついたモンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\n「フォトン・パイレーツ」の効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・サテライト",h:"ふぉとん・さてらいと",e:"Photon Satellite",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、このカード以外の自分フィールド上の「フォトン」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターとこのカードは、それぞれのレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・スレイヤー",h:"ふぉとん・すれいやー",e:"Photon Slasher",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"フィールド上にエクシーズモンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォトン",h:"くりふぉとん",e:"Kuriphoton",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードを手札から墓地へ送り、２０００LPを払って発動できる。\nこのターン、自分が受ける全てのダメージは０になる。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する場合、手札から「クリフォトン」以外の「フォトン」モンスター１体を墓地へ送って発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・ワンダラー",h:"でぃめんしょん・わんだらー",e:"Dimension Wanderer",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「銀河眼の光子竜」の効果によってモンスターがゲームから除外された時、このカードを手札から墓地へ送って発動できる。\n相手ライフに３０００ポイントダメージを与える。\n「ディメンション・ワンダラー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河の魔導師",h:"ぎゃらくしー・うぃざーど",e:"Galaxy Wizard",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのレベルをターン終了時まで４つ上げる。\n②：このカードをリリースして発動できる。\nデッキから「銀河の魔導師」以外の「ギャラクシー」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河騎士",h:"ぎゃらくしー・ないと",e:"Galaxy Knight",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:2600,tx:"①：自分フィールドに「フォトン」モンスターまたは「ギャラクシー」モンスターが存在する場合、このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚に成功した場合、自分の墓地の「銀河眼の光子竜」１体を対象として発動する。\nこのカードの攻撃力はターン終了時まで１０００ダウンし、対象のモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"輝光子パラディオス",h:"きこうしぱらでぃおす",e:"Starliege Paladynamo",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"光属性レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を２つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、その効果を無効にする。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"未来への思い",h:"みらいへのおもい",e:"Message in a Bottle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地のレベルが異なるモンスター３体を選択して発動できる。\n選択したモンスター３体を特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は０になり、効果は無効化される。\nその後、自分がエクシーズ召喚を行っていない場合、このターンのエンドフェイズ時に自分は４０００ライフポイントを失う。\nまた、このカードを発動するターン、自分はエクシーズ召喚以外の特殊召喚ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"アクセル・ライト",h:"あくせる・らいと",e:"Accellight",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は通常召喚できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\nデッキからレベル４以下の、「フォトン」モンスターまたは「ギャラクシー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河遠征",h:"ぎゃらくしー・えくすぺでぃしょん",e:"Galaxy Expedition",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「銀河遠征」は１ターンに１枚しか発動できない。\n①：自分フィールドにレベル５以上の、「フォトン」モンスターまたは「ギャラクシー」モンスターが存在する場合に発動できる。\nデッキからレベル５以上の、「フォトン」モンスターまたは「ギャラクシー」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河零式",h:"ぎゃらくしー・ぜろ",e:"Galaxy Zero",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「銀河零式」は１ターンに１枚しか発動できない。\n①：自分の墓地の「フォトン」モンスターまたは「ギャラクシー」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚し、このカードを装備する。\n装備モンスターは攻撃できず、効果も発動できない。\n②：装備モンスターがバトルフェイズに戦闘・効果で破壊される場合、代わりにこのカードを破壊できる。\n③：表側表示のこのカードがフィールドから離れた場合に発動する。\nこのカードを装備していたモンスターの攻撃力は０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王神獣 ガルドニクス",h:"えんおうしんじゅう　がるどにくす",e:"Fire King High Avatar Garunix",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"このカードがカードの効果によって破壊され墓地へ送られた場合、次のスタンバイフェイズ時にこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、このカード以外のフィールド上のモンスターを全て破壊する。\nまた、このカードが戦闘によって破壊され墓地へ送られた時、デッキから「炎王神獣 ガルドニクス」以外の「炎王」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王獣 バロン",h:"えんおうじゅう　ばろん",e:"Fire King Avatar Barong",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードがカードの効果によって破壊され墓地へ送られた場合、次のスタンバイフェイズ時に発動する。\nデッキから「炎王獣 バロン」以外の「炎王」と名のついたカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王獣 キリン",h:"えんおうじゅう　きりん",e:"Fire King Avatar Kirin",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードが破壊され墓地へ送られた場合、デッキから炎属性モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王の急襲",h:"えんおうのきゅうしゅう",e:"Onslaught of the Fire Kings",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合に発動できる。\nデッキから炎属性の獣族・獣戦士族・鳥獣族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズ時に破壊される。\n「炎王の急襲」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王炎環",h:"えんおうえんかん",e:"Circle of the Fire Kings",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの炎属性モンスター１体と自分の墓地の炎属性モンスター１体を対象として発動できる。\n対象の自分フィールドのモンスターを破壊し、対象の墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔聖騎士ランスロット",h:"ませいきしらんすろっと",e:"Ignoble Knight of Black Laundsallyn",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"自分フィールド上に表側表示で存在する光属性の通常モンスター１体を墓地へ送って発動できる。\nこのカードを手札または墓地から特殊召喚する。\nまた、自分フィールド上の「聖騎士」と名のついたモンスター１体をリリースして発動できる。\nデッキから「聖剣」と名のついたカード１枚を手札に加える。\n「魔聖騎士ランスロット」のこの効果は１ターンに１度しか使用できない。\nまた、「魔聖騎士ランスロット」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスマンダー",h:"まーめいる－あびすまんだー",e:"Mermail Abyssmander",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"墓地のこのカードをゲームから除外し、以下の効果から１つを選択して発動できる。\n●自分フィールド上の全ての「水精鱗」と名のついたモンスターのレベルを１つ上げる。\n●自分フィールド上の全ての「水精鱗」と名のついたモンスターのレベルを２つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"赤竜の忍者",h:"せきりゅうのにんじゃ",e:"Red Dragon Ninja",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分の墓地の「忍者」または「忍法」と名のついたカード１枚をゲームから除外し、相手フィールド上にセットされたカード１枚を選択して発動できる。\n選択したカードを確認し、持ち主のデッキの一番上または一番下に戻す。\nこの効果の発動に対して相手は選択されたカードを発動できない。\n「赤竜の忍者」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フラッピィ",h:"ふらっぴぃ",e:"Slushy",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚に成功した時、デッキから「フラッピィ」１体を墓地へ送る事ができる。\nまた、自分の墓地の「フラッピィ」及びゲームから除外されている自分の「フラッピィ」の合計が３体の場合、墓地のこのカードをゲームから除外して発動できる。\n自分の墓地の海竜族・レベル５以上のモンスター１体を選択して特殊召喚する。\n「フラッピィ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"深淵に潜む者",h:"しんえんにひそむもの",e:"Abyss Dweller",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1700,de:1400,tx:"レベル４モンスター×２\n①：このカードが水属性モンスターをX素材としている場合、自分フィールドの水属性モンスターの攻撃力は５００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン、相手は墓地のカードの効果を発動できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼鉄の巨兵",h:"こうてつのきょへい",e:"Giant Soldier of Steel",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1300,de:2000,tx:"岩石族レベル３モンスター×２\nこのカードがフィールド上に表側表示で存在する限り、このカードはこのカード以外のモンスターの効果を受けない。\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの守備力はエンドフェイズ時まで１０００ポイントアップし、このターン、相手のカードの効果によって発生する自分への効果ダメージは０になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖剣アロンダイト",h:"せいけんあろんだいと",e:"Noble Arms - Arfeudutyr",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「聖剣アロンダイト」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、相手フィールドにセットされたカード１枚を対象として発動できる。\n装備モンスターの攻撃力を５００ダウンし、そのセットされた相手のカードを破壊する。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合、自分フィールドの戦士族の「聖騎士」モンスター１体を対象として発動できる。\nその自分のモンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書庫ソレイン",h:"まどうしょこそれいん",e:"Spellbook Library of the Heliosphere",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「魔導書」と名のついた魔法カードが５枚以上の場合に発動できる。\n自分のデッキの上からカードを２枚めくる。\nその中の「魔導書」と名のついた魔法カードを全て手札に加え、残りのカードをデッキに戻す。\n「魔導書庫ソレイン」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「魔導書」と名のついたカード以外の魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書廊エトワール",h:"まどうしょろうえとわーる",e:"Spellbook Star Hall",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分または相手が「魔導書」と名のついた魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n自分フィールド上の魔法使い族モンスターの攻撃力は、このカードに乗っている魔力カウンターの数×１００ポイントアップする。\nまた、魔力カウンターが乗っているこのカードが破壊され墓地へ送られた時、このカードに乗っていた魔力カウンターの数以下のレベルを持つ魔法使い族モンスター１体をデッキから手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ムーン・スクレイパー",h:"むーん・すくれいぱー",e:"Attack the Moon!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の岩石族モンスターの表示形式が変更された時、相手フィールド上の魔法・罠カード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドボット",h:"どどどぼっと",e:"Dododo Bot",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1900,tx:"このカードを通常召喚する場合、裏側守備表示でセットしなければならない。\nこのカードが攻撃する場合、このカードはダメージステップ終了時まで、このカード以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴゴースト",h:"ごごごごーすと",e:"Gogogo Ghost",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードが特殊召喚に成功した場合、自分の墓地の「ゴゴゴゴーレム」１体を選択して表側守備表示で特殊召喚できる。\nその後、このカードは守備表示になる。\n「ゴゴゴゴースト」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"タスケルトン",h:"たすけるとん",e:"Bacon Saver",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:700,de:600,tx:"モンスターが戦闘を行うバトルステップ時、墓地のこのカードをゲームから除外して発動できる。\nそのモンスターの攻撃を無効にする。\nこの効果は相手ターンでも発動できる。\n「タスケルトン」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アマリリース",h:"あまりりーす",e:"Amarylease",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"自分のメインフェイズ時、墓地のこのカードをゲームから除外して発動できる。\nこのターンに１度だけ、自分がモンスターを召喚する場合に必要なリリースを１体少なくする事ができる。\n「アマリリース」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－雷神猛虎剣",h:"ぜある・うぇぽん－らいとにんぐ・ぶれーど",e:"ZW - Lightning Blade",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：「ZW－雷神猛虎剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１２００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：このカードが装備されている場合、自分フィールドの「ZW」カードは相手の効果では破壊されない。\n④：装備モンスターが効果で破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－風神雲龍剣",h:"ぜある・うぇぽん－とるねーど・ぶりんがー",e:"ZW - Tornado Bringer",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"①：「ZW－風神雲龍剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１３００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：このカードが装備されている場合、相手は装備モンスターを効果の対象にできない。\n④：装備モンスターが戦闘で破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－玄武絶対聖盾",h:"ぜある・うぇぽん－あるてぃめっと・しーるど",e:"ZW - Ultimate Shield",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードが召喚・特殊召喚に成功した時、ゲームから除外されている自分のエクシーズモンスター１体を選択して表側守備表示で特殊召喚できる。\nまた、自分のメインフェイズ時、フィールド上のこのモンスターを、守備力２０００ポイントアップの装備カード扱いとして自分フィールド上の「希望皇ホープ」と名のついたモンスターに装備できる。\n「ZW－玄武絶対聖盾」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガクラーク",h:"がががくらーく",e:"Gagaga Clerk",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"①：自分フィールドに「ガガガクラーク」以外の「ガガガ」モンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スピア・シャーク",h:"すぴあ・しゃーく",e:"Spear Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカードが召喚に成功した時、自分フィールド上の全ての魚族・レベル３モンスターのレベルを１つ上げる事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ツーヘッド・シャーク",h:"つーへっど・しゃーく",e:"Double Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが召喚に成功した時、自分フィールド上の全ての魚族・レベル４モンスターのレベルを１つ下げる事ができる。\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・リモーラ",h:"えくしーず・りもーら",e:"Xyz Remora",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードは自分フィールドのX素材を２つ取り除き、手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した時、自分の墓地の魚族・レベル４モンスター２体を対象として発動できる。\nその魚族モンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、効果は無効化され、表示形式の変更もできない。\nこの効果で特殊召喚したモンスターをX召喚の素材とする場合、水属性モンスターのX召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エンシェント・シャーク ハイパー・メガロドン",h:"えんしぇんと・しゃーく　はいぱー・めがろどん",e:"Hyper-Ancient Shark Megalodon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2900,de:1300,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手フィールド上のモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣バシリスク",h:"もんしょうじゅうばしりすく",e:"Heraldic Beast Basilisk",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1400,tx:"このカードが相手モンスターと戦闘を行ったダメージ計算後、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣エアレー",h:"もんしょうじゅうえあれー",e:"Heraldic Beast Eale",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"自分フィールド上に「紋章獣」と名のついたモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣ツインヘッド・イーグル",h:"もんしょうじゅうついんへっど・いーぐる",e:"Heraldic Beast Twin-Headed Eagle",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"墓地のこのカードをゲームから除外し、自分フィールド上のエクシーズ素材の無いエクシーズモンスター１体と、自分の墓地の「紋章獣」と名のついたモンスター２体を選択して発動できる。\n選択した墓地のモンスターを、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。\n「紋章獣ツインヘッド・イーグル」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣ユニコーン",h:"もんしょうじゅうゆにこーん",e:"Heraldic Beast Unicorn",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"墓地のこのカードをゲームから除外し、自分の墓地のサイキック族エクシーズモンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「紋章獣ユニコーン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣レオ",h:"もんしょうじゅうれお",e:"Heraldic Beast Leo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードを召喚したターンのエンドフェイズ時、このカードを破壊する。\nまた、このカードが墓地へ送られた時、デッキから「紋章獣レオ」以外の「紋章獣」と名のついたモンスター１体を手札に加える。\n「紋章獣レオ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーベージ・オーガ",h:"がーべーじ・おーが",e:"Garbage Ogre",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"自分のメインフェイズ時に、このカードを手札から墓地へ送って発動できる。\nデッキから「ガーベージ・ロード」１体を手札に加える。\n「ガーベージ・オーガ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーベージ・ロード",h:"がーべーじ・ろーど",e:"Garbage Lord",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2400,tx:"このカードは２０００ライフポイントを払い、手札から特殊召喚できる。\nこのカードをエクシーズ素材とする場合、闇属性モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オービタル ７",h:"おーびたる　せぶん",e:"Orbital 7",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"①：このカードがリバースした場合に発動する。\nこのカードにカシコマリカウンターを１つ置く。\n②：自分メインフェイズに、このカードのカシコマリカウンターを全て取り除いて発動できる。\nこのカードの攻撃力は２０００になり、このターン直接攻撃できず、エンドフェイズに墓地へ送られる。\n③：このカードをリリースし、自分の墓地の、「フォトン」モンスターまたは「ギャラクシー」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"英炎星－ホークエイ",h:"えいえんせい－ほーくえい",e:"Brotherhood of the Fire Fist - Hawk",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:1500,tx:"このカードが相手によって破壊された場合、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、自分フィールド上に「炎舞」と名のついた魔法・罠カードが存在する場合、自分フィールド上の全ての「炎星」と名のついたモンスターの攻撃力・守備力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"捷炎星－セイヴン",h:"しょうえんせい－せいう゛ん",e:"Brotherhood of the Fire Fist - Raven",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:1800,tx:"このカードがフィールド上から墓地へ送られた場合、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「炎舞」と名のついた魔法・罠カードは相手のカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"勇炎星－エンショウ",h:"ようえんせい－えんしょう",e:"Brotherhood of the Fire Fist - Gorilla",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード１枚を墓地へ送って発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗炎星－ユウシ",h:"あんえんせい－ゆうし",e:"Brotherhood of the Fire Fist - Bear",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度、このカードが相手ライフに戦闘ダメージを与えた時、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード１枚を墓地へ送って発動できる。\nフィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"微炎星－リュウシシン",h:"びえんせい－りゅうししん",e:"Brotherhood of the Fire Fist - Dragon",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"１ターンに１度、自分が「炎舞」と名のついた魔法・罠カードを発動した場合、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\nまた、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード２枚を墓地へ送って発動できる。\n自分の墓地から「微炎星－リュウシシン」以外の「炎星」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"雄炎星－スネイリン",h:"ゆうえんせい－すねいりん",e:"Brotherhood of the Fire Fist - Snake",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"１ターンに１度、「炎舞」と名のついた魔法・罠カードが自分の墓地へ送られた場合、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\nまた、自分フィールド上にこのカード以外のモンスターが存在しない場合、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード２枚を墓地へ送って発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"威炎星－ヒエンシャク",h:"いえんせい－ひえんしゃく",e:"Brotherhood of the Fire Fist - Swallow",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカードは自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード３枚を墓地へ送り、手札から特殊召喚できる。\nこのカードが召喚・特殊召喚に成功した時、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\n「威炎星－ヒエンシャク」のこの効果は１ターンに１度しか使用できない。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の獣戦士族モンスターは相手のカードの効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 サーベラス",h:"へいずびーすと　さーべらす",e:"Hazy Flame Cerbereus",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１０００になる。\nこのカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、このカードが破壊され墓地へ送られた時、デッキから「ヘイズ」と名のついたカード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 グリプス",h:"へいずびーすと　ぐりぷす",e:"Hazy Flame Griffin",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:200,de:2100,tx:"相手フィールド上にモンスターが存在し、自分のフィールド上・墓地に炎属性以外のモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 スピンクス",h:"へいずびーすと　すぴんくす",e:"Hazy Flame Sphynx",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、自分のメインフェイズ時、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n自分のデッキの一番上のカードを墓地へ送り、宣言した種類のカードだった場合、さらに自分の手札・墓地から炎属性モンスター１体を選んで特殊召喚できる。\n「陽炎獣 スピンクス」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 ペリュトン",h:"へいずびーすと　ぺりゅとん",e:"Hazy Flame Peryton",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードは「陽炎獣」と名のついたモンスターの効果以外では特殊召喚できない。\nこのカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、手札の炎属性モンスター１体を墓地へ送り、このカードをリリースして発動できる。\nデッキから「陽炎獣」と名のついたモンスター２体を特殊召喚する。\n「陽炎獣 ペリュトン」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスディーネ",h:"まーめいる－あびすでぃーね",e:"Mermail Abyssdine",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"自分フィールド上に「水精鱗」と名のついたモンスターが存在する場合、このカードがカードの効果によってデッキまたは墓地から手札に加わった時、このカードを手札から特殊召喚できる。\nまた、このカードが「水精鱗」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地からレベル３以下の「水精鱗」と名のついたモンスター１体を選択して特殊召喚できる。\n「水精鱗－アビスディーネ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビスノーズ",h:"まーめいる－あびすのーず",e:"Mermail Abyssnose",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、手札の水属性モンスター１体を墓地へ捨てて発動できる。\nデッキから「水精鱗」と名のついたモンスター１体を表側守備表示で特殊召喚する。\n「水精鱗－アビスノーズ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－リードアビス",h:"まーめいる－りーどあびす",e:"Mermail Abyssleed",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2700,de:1000,tx:"自分のメインフェイズ時、手札からこのカード以外の水属性モンスター３体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚に成功した時、自分の墓地から「アビス」と名のついた魔法・罠カード１枚を選択して手札に加える事ができる。\nまた、このカード以外の自分フィールド上に表側攻撃表示で存在する「水精鱗」と名のついたモンスター１体をリリースする事で、相手の手札をランダムに１枚墓地へ送る。\n「水精鱗－リードアビス」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導化士 マット",h:"まどうけし　まっと",e:"Fool of Prophecy",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"１ターンに１度、デッキから「魔導書」と名のついた魔法カード１枚を墓地へ送る事ができる。\nこの効果を発動したターンのエンドフェイズ時、自分の墓地の「魔導書」と名のついた魔法カードが５種類以上の場合、このカードをリリースする事で、デッキから魔法使い族・闇属性・レベル５以上のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導冥士 ラモール",h:"まどうめいし　らもーる",e:"Reaper of Prophecy",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードが召喚・特殊召喚に成功した時に発動できる。\n自分の墓地の「魔導書」と名のついた魔法カードの種類によって以下の効果を適用する。\n「魔導冥士 ラモール」の効果は１ターンに１度しか使用できない。\n\n●３種類以上：このカードの攻撃力は６００ポイントアップする。\n\n●４種類以上：デッキから「魔導書」と名のついた魔法カード１枚を手札に加える。\n\n●５種類以上：デッキから魔法使い族・闇属性・レベル５以上のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"紅炎の騎士",h:"こうえんのきし",e:"Brushfire Knight",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、このカード以外の炎属性モンスターが破壊され自分の墓地へ送られた場合に発動する。\nデッキから炎属性モンスター１体を墓地へ送る。\n②：このカードが破壊され墓地へ送られた場合に発動する。\nデッキから炎属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"稲荷火",h:"いなりび",e:"Inari Fire",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：「稲荷火」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに魔法使い族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：フィールドの表側表示のこのカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バルキリー・ナイト",h:"ばるきりー・ないと",e:"Valkyrian Knight",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、相手は「バルキリー・ナイト」以外の戦士族モンスターを攻撃対象に選択できない。\n②：このカードが戦闘で破壊され墓地へ送られた時、自分の墓地から戦士族モンスター１体とこのカードを除外し、自分の墓地のレベル５以上の戦士族モンスター１体を対象として発動できる。\nその戦士族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎霊神パイロレクス",h:"えんれいしんぱいろれくす",e:"Pyrorex the Elemental Lord",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"恐竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の炎属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、お互いのプレイヤーはそのモンスターの元々の攻撃力の半分のダメージを受ける。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎神機－紫龍",h:"ふれいむぎあ－しりゅう",e:"Pyrotech Mech - Shiryu",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2900,de:1800,tx:"このカードはモンスター１体をリリースして召喚できる。\nこの方法で召喚している場合、自分はエンドフェイズ毎に１０００ポイントダメージを受ける。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"レオンタウロス",h:"れおんたうろす",e:"Leotaur",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードが通常モンスター以外のモンスターと戦闘を行うダメージステップの間、このカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アステル・ドローン",h:"あすてる・どろーん",e:"Star Drawing",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードをX召喚に使用する場合、このカードのレベルを５として扱う事ができる。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ダストン",h:"れっど・だすとん",e:"Red Duston",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーは５００ポイントダメージを受ける。\n「レッド・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.92 偽骸神龍 Heart－eartH Dragon",h:"なんばーず９２ ぎがいしんりゅう はーとあーす・どらごん",e:"Number 92: Heart-eartH Dragon",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル９モンスター×３\n①：このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは代わりに相手が受ける。\n②：相手エンドフェイズにこのカードのX素材を１つ取り除いて発動できる。\nこのターンに召喚・特殊召喚・セットされた相手フィールドのカードを全て除外する。\n③：X素材を持っているこのカードが破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\n④：このカードが自身の効果で特殊召喚した場合に発動する。\nこのカードの攻撃力は除外されているカードの数×１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.53 偽骸神 Heart－eartH",h:"なんばーず５３ ぎがいしん はーと・あーす",e:"Number 53: Heart-eartH",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:100,de:100,tx:"レベル５モンスター×３\n①：１ターンに１度、このカードが攻撃対象に選択された場合に発動する。\nこのカードの攻撃力はターン終了時までその攻撃モンスターの元々の攻撃力分アップする。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。\n③：X素材の無いこのカードが効果で破壊され墓地へ送られた場合に発動する。\nこのカードをX素材として、EXデッキから「No.92 偽骸神龍 Heart－eartH Dragon」１体をX召喚扱いとして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－獣王獅子武装",h:"ぜある・うぇぽん－らいお・あーむず",e:"ZW - Leo Arms",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:1200,tx:"レベル５モンスター×２\nこのカードは直接攻撃できない。\n１ターンに１度、このカードのエクシーズ素材を１つ取り除く事で、デッキから「ZW」と名のついたモンスター１体を手札に加える。\nまた、フィールド上のこのモンスターを、攻撃力３０００ポイントアップの装備カード扱いとして自分フィールド上の「希望皇ホープ」と名のついたモンスターに装備できる。\n装備モンスターが攻撃したバトルフェイズ中に、装備されているこのカードを墓地へ送る事で、そのバトルフェイズ中、装備モンスターは相手モンスターにもう１度だけ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魁炎星王－ソウコ",h:"かいえんせいおう－そうこ",e:"Brotherhood of the Fire Fist - Tiger King",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"獣戦士族レベル４モンスター×２\n①：このカードがX召喚に成功した時に発動できる。\nデッキから「炎舞」魔法・罠カード１枚をセットする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n獣戦士族以外のフィールドの全ての効果モンスターの効果は相手ターン終了時まで無効化される。\n③：このカードがフィールドから墓地へ送られた時、自分フィールドの表側表示の「炎舞」魔法・罠カード３枚を墓地へ送って発動できる。\nレベル４以下で同じ攻撃力の獣戦士族モンスター２体をデッキから守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 バジリコック",h:"へいずびーすと　ばじりこっく",e:"Hazy Flame Basiltrice",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"炎属性レベル６モンスター×２体以上（最大５体まで）\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手のフィールド上・墓地のモンスター１体を選択してゲームから除外する。\nまた、このカードが持っているエクシーズ素材の数によって、このカードは以下の効果を得る。\n\n●３つ以上：このカードの攻撃力・守備力は、このカードのエクシーズ素材の数×２００ポイントアップする。\n\n●４つ以上：このカードは相手のカードの効果の対象にならない。\n\n●５つ：このカードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－アビストリーテ",h:"まーめいる－あびすとりーて",e:"Mermail Abysstrite",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:2800,tx:"レベル３モンスター×３\n自分フィールド上の「水精鱗」と名のついたモンスター１体が相手の魔法・罠カードの効果の対象になった時、または相手モンスターの攻撃対象になった時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその対象を自分フィールド上の正しい対象となるこのカードに移し替える。\nこのカードが破壊され墓地へ送られた時、自分の墓地から「水精鱗－アビストリーテ」以外の「水精鱗」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"恐牙狼 ダイヤウルフ",h:"きょうがろう　だいやうるふ",e:"Diamond Dire Wolf",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1200,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの獣族・獣戦士族・鳥獣族モンスター１体とフィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"電光千鳥",h:"でんこうちどり",e:"Lightning Chidori",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"雷族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1600,tx:"風属性レベル４モンスター×２\nこのカードがエクシーズ召喚に成功した時、相手フィールド上にセットされたカード１枚を選択して持ち主のデッキの一番下に戻す。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上に表側表示で存在するカード１枚を選択して持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"シャイニート・マジシャン",h:"しゃいにーと・まじしゃん",e:"Slacker Magician",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:200,de:2100,tx:"レベル１モンスター×２\nこのカードは１ターンに１度だけ戦闘では破壊されない。\nまた、このカードを対象とする魔法・罠・効果モンスターの効果が発動した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼロゼロック",h:"ぜろぜろっく",e:"Zerozerock",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手は表側攻撃表示で存在する攻撃力０のモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガドロー",h:"がががどろー",e:"Gagagadraw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「ガガガ」と名のついたモンスター３体をゲームから除外して発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ダブル・バック",h:"えくしーず・だぶる・ばっく",e:"Xyz Double Back",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上のエクシーズモンスターが破壊されたターン、自分フィールド上にモンスターが存在しない場合に発動できる。\n自分の墓地から、そのターンに破壊されたエクシーズモンスター１体と、そのモンスターの攻撃力以下のモンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"蘇生紋章",h:"りぼーん・めだりおん",e:"Heraldry Reborn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「紋章獣」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「天枢」",h:"えんぶ－「てんすう」",e:"Fire Formation - Tensu",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに獣戦士族モンスター１体を召喚できる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの獣戦士族モンスターの攻撃力は１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「天璣」",h:"えんぶ－「てんき」",e:"Fire Formation - Tenki",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキからレベル４以下の獣戦士族モンスター１体を手札に加える事ができる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの獣戦士族モンスターの攻撃力は１００アップする。",li:2,lk:[],ta:[],al:[]},
  {n:"陽炎柱",h:"へいずぴらー",e:"Hazy Pillar",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分は「陽炎獣」と名のついたモンスターを召喚する場合に必要なリリースを１体少なくする事ができる。\nまた、１ターンに１度、自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n自分の手札・フィールド上の「陽炎獣」と名のついたモンスター１体を、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"アビスケイル－ケートス",h:"あびすけいる－けーとす",e:"Abyss-scale of Cetus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「水精鱗」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上で発動した罠カードの効果を無効にする。\nその後、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"セフェルの魔導書",h:"せふぇるのまどうしょ",e:"Spellbook of the Master",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに魔法使い族モンスターが存在する場合、このカード以外の手札の「魔導書」カード１枚を相手に見せ、「セフェルの魔導書」以外の自分の墓地の「魔導書」通常魔法カード１枚を対象として発動できる。\nこのカードの効果は、その通常魔法カード発動時の効果と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"烏合の行進",h:"うごうのこうしん",e:"The Big Cattle Drive",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に獣族・獣戦士族・鳥獣族のいずれかのモンスターが存在する場合、その種族１種類につき１枚デッキからカードをドローする。\nこのカードを発動するターン、自分は他の魔法・罠カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"進撃の帝王",h:"しんげきのていおう",e:"March of the Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドのアドバンス召喚したモンスターは効果の対象にならず、効果では破壊されない。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分はエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"速攻魔力増幅器",h:"くいっく・ぶーすたー",e:"Quick Booster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地から「速攻魔力増幅器」以外の速攻魔法カード１枚を選択してデッキに戻す。\nまた、フィールド上のこのカードが相手によって破壊され墓地へ送られた場合、デッキから「速攻魔力増幅器」以外の速攻魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"戦火の残滓",h:"せんかのざんし",e:"After the Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのフィールド上に、他のカードが存在しない場合に発動できる。\n自分の墓地から水属性または風属性のモンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"見世物ゴブリン",h:"みせものごぶりん",e:"Goblin Circus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のエンドフェイズ毎に、自分のデッキの一番下のカードをお互いに確認し、そのカードを自分のデッキの一番上に置くか、裏側表示でゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・ゲート",h:"でぃめんしょん・げーと",e:"Dimension Gate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの発動時に、自分フィールド上のモンスター１体を選択し、表側表示でゲームから除外する。\nまた、相手モンスターの直接攻撃宣言時、フィールド上に表側表示で存在するこのカードを墓地へ送る事ができる。\nこのカードが墓地へ送られた場合、このカードの効果で除外したモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ディメンション・スプラッシュ",h:"えくしーず・でぃめんしょん・すぷらっしゅ",e:"Xyz Dimension Splash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードがゲームから除外された場合、デッキから水属性・レベル８モンスター２体を特殊召喚できる。\nこの効果で特殊召喚したモンスターは攻撃宣言できず、効果は無効化され、リリースする事もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章変換",h:"ちぇんじ・めだりおん",e:"Heraldry Change",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\n手札から「紋章獣」と名のついたモンスター１体を特殊召喚し、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「天璇」",h:"えんぶ－「てんせん」",e:"Fire Formation - Tensen",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの発動時に、自分フィールド上の獣戦士族モンスター１体を選択する。\n選択したモンスターの攻撃力はエンドフェイズ時まで７００ポイントアップする。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「天権」",h:"えんぶ－「てんけん」",e:"Fire Formation - Tenken",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードはメインフェイズ１でのみ発動できる。\nこのカードの発動時に、自分フィールド上の獣戦士族モンスター１体を選択する。\nこのカードを発動したメインフェイズ１の間だけ、選択したモンスターの効果は無効になり、このカード以外のカードの効果を受けない。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"極炎舞－「星斗」",h:"ごくえんぶ－「せいと」",e:"Ultimate Fire Formation - Seito",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「炎舞」と名のついた魔法・罠カード７枚をゲームから除外して発動できる。\n自分の墓地から「炎星」と名のついたモンスターを可能な限り特殊召喚する。\nその後、この効果で特殊召喚したモンスターの数まで、デッキから「極炎舞－「星斗」」以外の「炎舞」と名のついた魔法・罠カードを選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎光輪",h:"へいずぐろーりー",e:"Hazy Glory",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分は「陽炎獣」と名のついたモンスターを召喚する場合に必要なリリースを１体少なくする事ができる。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、自分の墓地から「陽炎光輪」以外の「ヘイズ」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アビスコーン",h:"あびすこーん",e:"Abyss-scorn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「水精鱗」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\nまた、セットされたこのカードが墓地へ送られた時、相手フィールド上のモンスター１体を選択して墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"鎖付き尖盾",h:"くさりつきすぱいくしーるど",e:"Spikeshield with Chain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力５００ポイントアップの装備カードとなり、フィールド上のモンスター１体に装備する。\n装備モンスターが守備表示で戦闘を行う場合、装備モンスターの守備力はダメージ計算時のみ、その攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・トライバル",h:"えくしーず・とらいばる",e:"Xyz Tribalrivals",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上のエクシーズ素材を２つ以上持ったエクシーズモンスターは、カードの効果では破壊されない。\nまた、自分フィールド上のエクシーズ素材を２つ以上持ったエクシーズモンスターと戦闘を行った相手モンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイクスルー・スキル",h:"ぶれいくするー・すきる",e:"Breakthrough Skill",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの効果モンスター１体を対象として発動できる。\nその相手モンスターの効果をターン終了時まで無効にする。\n②：自分ターンに墓地のこのカードを除外し、相手フィールドの効果モンスター１体を対象として発動できる。\nその相手の効果モンスターの効果をターン終了時まで無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラック・インパクト",h:"じゅらっく・いんぱくと",e:"Jurrac Impact",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に攻撃力２５００以上の恐竜族モンスターが存在する場合に発動できる。\nフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"出目出し",h:"だめだし",e:"Dice-nied",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手がモンスターの特殊召喚に成功した時、サイコロを１回振る。\n出た目がその特殊召喚されたモンスターのレベルと同じだった場合、そのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・ソンブレス",h:"せいくりっど・そんぶれす",e:"Constellar Sombre",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1550,de:1600,tx:"「セイクリッド・ソンブレス」の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「セイクリッド」モンスター１体を除外し、自分の墓地の「セイクリッド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードの①の効果を適用したターンのメインフェイズに発動できる。\n「セイクリッド」モンスター１体を召喚する。\n③：このカードが墓地へ送られたターン、「セイクリッド」モンスターを召喚する場合に必要なリリースを１体少なくできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェルズ・ケルキオン",h:"う゛ぇるず・けるきおん",e:"Evilswarm Kerykeion",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1550,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「ヴェルズ」モンスター１体を除外し、自分の墓地の「ヴェルズ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードの①の効果を適用したターンのメインフェイズに発動できる。\n「ヴェルズ」モンスター１体を召喚する。\n③：このカードが墓地へ送られたターン、「ヴェルズ」モンスターを召喚する場合に必要なリリースを１体少なくできる。",li:"",lk:[],ta:[],al:[]},
  {n:"熱血指導王ジャイアントレーナー",h:"ねっけつしどうおうじゃいあんとれーなー",e:"Coach King Giantrainer",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"レベル８モンスター×３\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\nデッキからカードを１枚ドローし、お互いに確認する。\n確認したカードがモンスターだった場合、さらに相手ライフに８００ポイントダメージを与える。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\n「熱血指導王ジャイアントレーナー」の効果は１ターンに３度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"熱血獣王ベアーマン",h:"ねっけつじゅうおうべあーまん",e:"Coach Captain Bearman",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2700,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１３００になる。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の全ての獣戦士族・レベル４モンスターのレベルをエンドフェイズ時まで８にする。\nこのカードをエクシーズ召喚の素材とする場合、戦士族・炎属性モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"熱血獣士ウルフバーク",h:"ねっけつじゅうしうるふばーく",e:"Coach Soldier Wolfbark",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「熱血獣士ウルフバーク」の効果は１ターンに１度しか使用できない。\n①：自分の墓地の獣戦士族・炎属性・レベル４モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"立炎星－トウケイ",h:"りつえんせい－とうけい",e:"Brotherhood of the Fire Fist - Rooster",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが「炎星」モンスターの効果で特殊召喚に成功した時に発動できる。\nデッキから「炎星」モンスター１体を手札に加える。\n②：１ターンに１度、自分フィールドの表側表示の「炎舞」魔法・罠カード１枚を墓地へ送って発動できる。\nデッキから「炎舞」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王獣 ヤクシャ",h:"えんおうじゅう　やくしゃ",e:"Fire King Avatar Yaksha",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードが破壊され墓地へ送られた場合、自分の手札・フィールド上のカード１枚を選んで破壊できる。\n「炎王獣 ヤクシャ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ネクロ・ドール",h:"ぎみっく・ぱぺっと－ねくろ・どーる",e:"Gimmick Puppet Dreary Doll",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できず、このカードをX召喚の素材とする場合、「ギミック・パペット」モンスターのX召喚にしか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地からこのカード以外の「ギミック・パペット」モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－マグネ・ドール",h:"ぎみっく・ぱぺっと－まぐね・どーる",e:"Gimmick Puppet Magnet Doll",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：相手フィールドにモンスターが存在し、自分フィールドのモンスターが「ギミック・パペット」モンスターのみの場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産トゥーラ・ガーディアン",h:"おーぱーつとぅーら・がーでぃあん",e:"Chronomaly Tula Guardian",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"フィールド魔法カードが表側表示で存在する場合、このカードは手札から特殊召喚できる。\n「先史遺産トゥーラ・ガーディアン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"三段腹ナイト",h:"さんだんばらないと",e:"Big Belly Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"エクシーズ素材のこのカードがエクシーズモンスターの効果を発動するために取り除かれ墓地へ送られた場合、手札からレベル３以下のモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"機械竜 パワー・ツール",h:"きかいりゅう　ぱわー・つーる",e:"Power Tool Mecha Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のターンにこのカードが装備魔法カードを装備した時、デッキからカードを１枚ドローできる。\n「機械竜 パワー・ツール」のこの効果は１ターンに１度しか使用できない。\nまた、１ターンに１度、フィールド上に表側表示で存在する装備カード１枚を選択し、正しい対象となるこのカードに移し替える事ができる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精竜 エンシェント",h:"ようせいりゅう　えんしぇんと",e:"Ancient Pixie Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のターンにフィールド魔法カードが発動した場合、デッキからカードを１枚ドローする。\n「妖精竜 エンシェント」のこの効果は１ターンに１度しか使用できない。\nまた、１ターンに１度、フィールド魔法カードが表側表示で存在する場合、フィールド上に表側攻撃表示で存在するモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ズババジェネラル",h:"ずばばじぇねらる",e:"Zubaba General",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n手札から戦士族モンスター１体を装備カード扱いとしてこのカードに装備する。\nこのカードの攻撃力は、この効果で装備したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・パペット",h:"じゃんく・ぱぺっと",e:"Junk Puppet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産都市バビロン",h:"おーぱーつしてぃばびろん",e:"Chronomaly City Babylon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分の墓地の「先史遺産」と名のついたモンスター１体をゲームから除外し、除外したモンスターと同じレベルを持つ「先史遺産」と名のついたモンスター１体を自分の墓地から選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホープ・バスター",h:"ほーぷ・ばすたー",e:"Utopia Buster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「希望皇ホープ」と名のついたモンスターが存在する場合に発動できる。\n相手フィールド上の攻撃力が一番低いモンスター１体を破壊し、破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.106 巨岩掌ジャイアント・ハンド",h:"なんばーず１０６ きょがんしょうじゃいあんと・はんど",e:"Number 106: Giant Hand",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル４モンスター×２\n①：相手フィールドのモンスターの効果が発動した時、このカードのX素材を２つ取り除き、相手フィールドの効果モンスター１体を対象として発動できる。\nこのモンスターが表側表示で存在する間、対象の効果モンスターの効果は無効化され、表示形式の変更もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"輝白竜 ワイバースター",h:"きびゃくりゅう　わいばーすたー",e:"White Dragon Wyverburster",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:1800,tx:"このカードは通常召喚できない。\n自分の墓地から闇属性モンスター１体を除外した場合のみ特殊召喚できる。\nこの方法による「輝白竜 ワイバースター」の特殊召喚は１ターンに１度しかできない。\n①：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「暗黒竜 コラプサーペント」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"コドモドラゴン",h:"こどもどらごん",e:"Kidmodo Dragon",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"このカード名の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はドラゴン族モンスターしか特殊召喚できず、バトルフェイズを行えない。\n①：このカードが墓地へ送られた場合に発動できる。\n手札からドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュボーグ－アーチャー",h:"ふぃっしゅぼーぐ－あーちゃー",e:"Fishborg Archer",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドにモンスターが存在しない場合、手札から水属性モンスター１体を捨てて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したターンのバトルフェイズ開始時に、水属性モンスター以外の自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"獣神ヴァルカン",h:"じゅうしんう゛ぁるかん",e:"Vulcan the Divine",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n「獣神ヴァルカン」の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、自分及び相手フィールドの表側表示のカードを１枚ずつ対象として発動する。\nその自分及び相手の表側表示のカードを手札に戻す。\nこのターン、自分はこの効果で手札に戻したカード及びそれらの同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法族の聖域",h:"まほうぞくのせいいき",e:"Secret Sanctuary of the Spellcasters",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード以外の魔法カードが自分フィールド上にのみ表側表示で存在し、魔法使い族以外のモンスターが相手フィールド上に召喚・特殊召喚された時、そのターンそのモンスターは攻撃できず、効果の発動もできない。\nまた、自分フィールド上に魔法使い族モンスターが存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒竜 コラプサーペント",h:"あんこくりゅう　こらぷさーぺんと",e:"Black Dragon Collapserpent",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1800,de:1700,tx:"このカードは通常召喚できない。\n自分の墓地から光属性モンスター１体を除外した場合のみ特殊召喚できる。\nこの方法による「暗黒竜 コラプサーペント」の特殊召喚は１ターンに１度しかできない。\n①：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「輝白竜 ワイバースター」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"火舞太刀",h:"かまいたち",e:"Fencing Fire Ferret",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"①：このカードが破壊され墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動する。\nその相手の表側表示モンスターを破壊し、相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"九蛇孔雀",h:"くじゃくじゃく",e:"Kujakujaku",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"フィールド上のこのカードがリリースされ墓地へ送られた場合、自分のデッキ・墓地から「九蛇孔雀」以外のレベル４以下の風属性モンスター１体を選んで手札に加える事ができる。\n「九蛇孔雀」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマー・カッパー",h:"あーまー・かっぱー",e:"Armored Kappa",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:400,de:1000,tx:"レベル２モンスター×２\n「アーマー・カッパー」の②の効果はデュエル中に１度しか使用できない。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力または守備力を１０００アップする。\n②：自分のモンスターが戦闘を行うバトルステップに、手札を１枚捨てて発動できる。\nこのターン、自分フィールドのモンスターは戦闘では破壊されず、自分が受ける戦闘ダメージは全て０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"トークン生誕祭",h:"とーくんせいたんさい",e:"Oh Tokenbaum!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の同じレベルのトークンを２体以上リリースして発動できる。\nこのカードを発動するためにリリースしたトークンと同じレベルのモンスターを、リリースしたトークンの数まで自分の墓地から選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"聖剣カリバーン",h:"せいけんかりばーん",e:"Noble Arms - Caliburn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力は５００ポイントアップする。\nまた、１ターンに１度、自分は５００ライフポイント回復できる。\nフィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた場合、自分フィールド上の「聖騎士」と名のついた戦士族モンスター１体を選択してこのカードを装備できる。\n「聖剣カリバーン」のこの効果は１ターンに１度しか使用できない。\nまた、「聖剣カリバーン」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士モルドレッド",h:"せいきしもるどれっど",e:"Noble Knight Medraut",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードはモンスターゾーンに存在する限り、通常モンスターとして扱う。\n②：このカードが「聖剣」装備魔法カードを装備している限り、このカードは効果モンスター扱いとなり以下の効果を得る。\n●このカードはレベルが１つ上がり闇属性になる。\n●１ターンに１度、自分フィールドにこのカード以外のモンスターが存在しない場合に発動できる。\nデッキから「聖騎士モルドレッド」以外の「聖騎士」モンスター１体を守備表示で特殊召喚し、自分フィールドの装備魔法カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 メコレオス",h:"へいずびーすと　めこれおす",e:"Hazy Flame Mantikor",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:300,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、１ターンに１度、手札または自分フィールド上に表側表示で存在する、炎属性モンスター１体を墓地へ送って発動できる。\nこのターン、このカードはカードの効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－ディニクアビス",h:"まーめいる－でぃにくあびす",e:"Mermail Abyssteus",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1700,de:2400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外の水属性モンスター１体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した時に発動できる。\nデッキからレベル４以下の「水精鱗」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"嚇灼の魔神",h:"かくしゃくのまじん",e:"Bonfire Colossus",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"自分フィールド上に炎属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこのカードが特殊召喚に成功した時、自分フィールド上の炎属性モンスター２体を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神秘の妖精 エルフィリア",h:"しんぴのようせい　えるふぃりあ",e:"Mystical Fairy Elfuria",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"１ターンに１度、手札の風属性モンスター１体を相手に見せて発動できる。\n次の相手のエンドフェイズ時まで、お互いのプレイヤーは、見せたモンスターのレベル以外のレベルを持つモンスターを素材としてエクシーズ召喚する事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士王アルトリウス",h:"せいきしおうあるとりうす",e:"Artorigus, King of the Noble Knights",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル４「聖騎士」モンスター×２\n①：このカードがX召喚に成功した時、自分の墓地の「聖剣」装備魔法カードを３枚まで対象として発動できる（同名カードは１枚まで）。\nそのカードをこのカードに装備する。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの「聖剣」装備魔法カードの数まで、フィールドの魔法・罠カードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルフレイムゴースト",h:"へるふれいむごーすと",e:"Infernal Flame Vixen",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"炎族レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力は相手のエンドフェイズ時まで５００ポイントアップする。\nまた、攻撃力２５００以上のこのカードが破壊された時、お互いの墓地のモンスターを合計３体選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力隔壁",h:"まじっく・うぉーる",e:"Spell Wall",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。\n\n●このターン、魔法使い族モンスターの召喚・反転召喚・特殊召喚は無効化されず、魔法使い族モンスターの召喚・反転召喚・特殊召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。\n\n●自分フィールドの魔法使い族モンスター１体を対象として発動できる。\nこのターンそのモンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"キックファイア",h:"きっくふぁいあ",e:"Kickfire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する炎属性モンスターがカードの効果によって破壊される度に、その破壊されたモンスターの数だけこのカードにカウンターを置く。\nこの効果は１ターンに１度しか適用できない。\nまた、自分または相手のスタンバイフェイズ時にこのカードを墓地へ送って発動できる。\nこのカードの効果によってこのカードに乗っていたカウンターの数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドバスター",h:"どどどばすたー",e:"Dododo Buster",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:800,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードのレベルは４になる。\n②：このカードがアドバンス召喚に成功した時、自分の墓地の「ドドド」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃珖竜 スターダスト",h:"せんこうりゅう　すたーだすと",e:"Stardust Spark Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分フィールド上に表側表示で存在するカード１枚を選択して発動できる。\n選択したカードは、このターンに１度だけ戦闘及びカードの効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.42 スターシップ・ギャラクシー・トマホーク",h:"なんばーず４２ すたーしっぷ・ぎゃらくしー・とまほーく",e:"Number 42: Galaxy Tomahawk",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:3000,tx:"レベル７モンスター×２\n①：１ターンに１度、このカードのX素材を２つ取り除いて発動できる。\n自分フィールドに「バトル・イーグル・トークン」（機械族・風・星６・攻２０００／守０）を可能な限り特殊召喚する。\nこの効果で特殊召喚したトークンはこのターンのエンドフェイズに破壊される。\nこの効果の発動後、ターン終了時まで相手が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"バチバチバチ",h:"ばちばちばち",e:"Bachibachibachi",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴギガース",h:"ごごごぎがーす",e:"Gogogo Gigas",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードが墓地に存在し、自分が「ゴゴゴ」と名のついたモンスターの特殊召喚に成功した場合、このカードを墓地から表側守備表示で特殊召喚できる。\n「ゴゴゴギガース」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"ミミミック",h:"みみみっく",e:"Mimimic",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にレベル３のモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法による「ミミミック」の特殊召喚は１ターンに１度しかできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドテドテング",h:"どてどてんぐ",e:"Dotedotengu",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:800,tx:"このカードが相手のカードの効果によって墓地へ送られた場合、相手フィールド上のカード１枚を選択して発動できる。\n選択したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"タタカワナイト",h:"たたかわないと",e:"Tatakawa Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手のカードの効果によって自分の魔法・罠カードの発動が無効になった場合、このカードを手札から墓地へ送って発動できる。\n相手ライフに１５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"リトル・フェアリー",h:"りとる・ふぇありー",e:"Little Fairy",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"自分のメインフェイズ時に手札を１枚墓地へ送って発動できる。\nこのカードのレベルを１つ上げる。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"シャークラーケン",h:"しゃーくらーけん",e:"Sharkraken",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"このカードは自分フィールド上の水属性モンスター１体をリリースし、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ビッグ・ホエール",h:"びっぐ・ほえーる",e:"Big Whale",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:3000,tx:"このカードがアドバンス召喚に成功した時、このカードをリリースして発動できる。\nデッキからレベル３の水属性モンスター３体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"スターフィッシュ",h:"すたーふぃっしゅ",e:"Starfish",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の全ての「スターフィッシュ」のレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"パンサー・シャーク",h:"ぱんさー・しゃーく",e:"Panther Shark",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"相手フィールド上のモンスターが２体以上の場合、このカードはリリースなしで召喚できる。\nまた、自分フィールド上に「イーグル・シャーク」が存在する場合、このカードは手札から特殊召喚できる。\n「パンサー・シャーク」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"イーグル・シャーク",h:"いーぐる・しゃーく",e:"Eagle Shark",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"相手フィールド上のモンスターが２体以上の場合、このカードはリリースなしで召喚できる。\nまた、自分フィールド上に「パンサー・シャーク」が存在する場合、このカードは手札から特殊召喚できる。\n「イーグル・シャーク」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリザード・ファルコン",h:"ぶりざーど・ふぁるこん",e:"Blizzard Falcon",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードの攻撃力が元々の攻撃力よりも高い場合に発動できる。\n相手ライフに１５００ポイントダメージを与える。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できず、「ブリザード・ファルコン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オーロラ・ウィング",h:"おーろら・うぃんぐ",e:"Aurora Wing",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、このカードを表側攻撃表示で特殊召喚できる。\n「オーロラ・ウィング」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"半月竜ラディウス",h:"はんげつりゅうらでぃうす",e:"Radius, the Half-Moon Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードのレベルは８になる。",li:"",lk:[],ta:[],al:[]},
  {n:"星間竜パーセク",h:"せいかんりゅうぱーせく",e:"Parsec, the Interstellar Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"自分フィールド上にレベル８のモンスターが存在する場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"BK ヘッドギア",h:"ばーにんぐなっくらー　へっどぎあ",e:"Battlin' Boxer Headgeared",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードが召喚に成功した時、デッキから「BK」と名のついたモンスター１体を墓地へ送る事ができる。\nフィールド上に表側攻撃表示で存在するこのカードは、１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"BK グラスジョー",h:"ばーにんぐなっくらー　ぐらすじょー",e:"Battlin' Boxer Glassjaw",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードが攻撃対象に選択された時、このカードを破壊する。\nこのカードがカードの効果によって墓地へ送られた時、自分の墓地から「BK グラスジョー」以外の「BK」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"BK スパー",h:"ばーにんぐなっくらー　すぱー",e:"Battlin' Boxer Sparrer",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"自分フィールド上に「BK」と名のついたモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚した場合、このターン自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"BK スイッチヒッター",h:"ばーにんぐなっくらー　すいっちひったー",e:"Battlin' Boxer Switchitter",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1400,tx:"このカードが召喚に成功した時、自分の墓地の「BK」と名のついたモンスター１体を選択して特殊召喚できる。\nこの効果を発動するターン、自分は「BK」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BK カウンターブロー",h:"ばーにんぐなっくらー　かうんたーぶろー",e:"Battlin' Boxer Counterpunch",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1100,tx:"自分フィールド上の「BK」と名のついたモンスターが戦闘を行うダメージステップ時に手札または墓地のこのカードをゲームから除外して発動できる。\nそのモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\n「BK カウンターブロー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機メガラプター",h:"げんじゅうきめがらぷたー",e:"Mecha Phantom Beast Megaraptor",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"自分フィールド上にトークンが特殊召喚された時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n「幻獣機メガラプター」のこの効果は１ターンに１度しか使用できない。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\nデッキから「幻獣機」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機テザーウルフ",h:"げんじゅうきてざーうるふ",e:"Mecha Phantom Beast Tetherwolf",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードが召喚に成功した場合に発動する。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n②：このカードのレベルは自分フィールドの「幻獣機トークン」のレベルの合計分だけ上がる。\n③：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。\n④：このカードが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに１度、自分フィールドのトークン１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ブラックファルコン",h:"げんじゅうきぶらっくふぁるこん",e:"Mecha Phantom Beast Blackfalcon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1700,tx:"このカードが攻撃宣言した時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\n相手フィールド上のモンスター１体を選択して表側守備表示にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機レイステイルス",h:"げんじゅうきれいすているす",e:"Mecha Phantom Beast Stealthray",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"このカードが相手ライフに戦闘ダメージを与えた時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ハムストラット",h:"げんじゅうきはむすとらっと",e:"Mecha Phantom Beast Hamstrat",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"このカードがリバースした時、「幻獣機トークン」（機械族・風・星３・攻／守０）２体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、トークン１体をリリースして発動できる。\n自分の墓地の「幻獣機」と名のついたモンスター１体を選択して特殊召喚する。\n「幻獣機ハムストラット」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"傷炎星－ウルブショウ",h:"しょうえんせい－うるぶしょう",e:"Brotherhood of the Fire Fist - Wolf",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードがリバースした場合、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\n反転召喚によってリバースした場合、さらにデッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"速炎星－タイヒョウ",h:"そくえんせい－たいひょう",e:"Brotherhood of the Fire Fist - Leopard",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:200,tx:"このカードが召喚・特殊召喚に成功したターンのメインフェイズ時に、自分フィールド上の「炎星」と名のついたモンスター１体をリリースして発動できる。\nデッキから「炎舞」と名のついた魔法・罠カード１枚を選んで自分フィールド上にセットする。\n「速炎星－タイヒョウ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"空炎星－サイチョウ",h:"くうえんせい－さいちょう",e:"Brotherhood of the Fire Fist - Rhino",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"自分フィールド上の「炎星」と名のついたモンスターが戦闘を行うダメージ計算時に１度だけ、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード１枚と手札の「炎星」と名のついたモンスター１体を墓地へ送って発動できる。\n戦闘を行う自分のモンスターの攻撃力は、そのダメージ計算時のみ墓地へ送ったモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"殺炎星－ブルキ",h:"さつえんせい－ぶるき",e:"Brotherhood of the Fire Fist - Buffalo",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが墓地に存在する場合、手札または自分フィールド上に表側表示で存在する、「炎星」または「炎舞」と名のついたカードを合計２枚墓地へ送って発動できる。\nこのカードを墓地から特殊召喚する。\n「殺炎星－ブルキ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－オーケアビス",h:"まーめいる－おーけあびす",e:"Mermail Abyssocea",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"自分のメインフェイズ時、自分フィールド上の「水精鱗」と名のついたモンスター１体を選択して発動できる。\nレベルの合計が選択したモンスターのレベル以下となるように、デッキからレベル４以下の「水精鱗」と名のついたモンスターを任意の数だけ特殊召喚する。\nその後、選択したモンスターを墓地へ送る。\n「水精鱗－オーケアビス」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣士 ルード",h:"まどうじゅうし　るーど",e:"Wheel of Prophecy",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"このカードが魔法使い族モンスターの効果によって特殊召喚に成功した時、ゲームから除外されている自分の「魔導書」と名のついた魔法カードを任意の数だけ選択して発動できる。\n選択したカードをデッキに戻し、残りの「魔導書」と名のついた魔法カードを墓地に戻す。\n「魔導獣士 ルード」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・ホーットケーキ",h:"まどるちぇ・ほーっとけーき",e:"Madolche Hootcake",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外し、デッキから「マドルチェ・ホーットケーキ」以外の「マドルチェ」モンスター１体を特殊召喚する。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"真海皇 トライドン",h:"しんかいおう　とらいどん",e:"Legendary Atlantean Tridon",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードと自分フィールド上の海竜族モンスター１体をリリースして発動できる。\n手札・デッキから「海皇龍 ポセイドラ」１体を特殊召喚する。\nその後、相手フィールド上の全てのモンスターの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王獣 ガルドニクス",h:"えんおうじゅう　がるどにくす",e:"Fire King Avatar Garunix",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:700,de:1700,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードが相手によって破壊され墓地へ送られた場合、デッキから「炎王獣」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・チャネラー",h:"はーぴぃ・ちゃねらー",e:"Harpie Channeler",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：自分フィールドにドラゴン族モンスターが存在する場合、このカードのレベルは７になる。\n③：手札から「ハーピィ」カード１枚を捨てて発動できる。\nデッキから「ハーピィ・チャネラー」以外の「ハーピィ」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"虚空の騎士",h:"こくうのきし",e:"Altitude Knight",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードがフィールド上に表側表示で存在する場合にフィールド上のモンスターが手札・デッキに戻った時、自分のデッキから風属性モンスター１体を墓地へ送る。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"風霊神ウィンドローズ",h:"ふうれいしんうぃんどろーず",e:"Windrose the Elemental Lord",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の風属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\n相手フィールドの魔法・罠カードを全て破壊する。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"巌征竜－レドックス",h:"がんせいりゅう－れどっくす",e:"Redox, Dragon Ruler of Boulders",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:3000,tx:"自分の手札・墓地からこのカード以外のドラゴン族または地属性のモンスターを合計２体除外して発動できる。\nこのカードを手札・墓地から特殊召喚する。\n特殊召喚したこのカードは相手のエンドフェイズ時に持ち主の手札に戻る。\nまた、このカードと地属性モンスター１体を手札から墓地へ捨てる事で、自分の墓地のモンスター１体を選択して特殊召喚する。\nこのカードが除外された場合、デッキからドラゴン族・地属性モンスター１体を手札に加える事ができる。\n「巌征竜－レドックス」の効果は１ターンに１度しか使用できない。",li:0,lk:[],ta:[],al:[]},
  {n:"瀑征竜－タイダル",h:"ばくせいりゅう－たいだる",e:"Tidal, Dragon Ruler of Waterfalls",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"自分の手札・墓地からこのカード以外のドラゴン族または水属性のモンスターを合計２体除外して発動できる。\nこのカードを手札・墓地から特殊召喚する。\n特殊召喚したこのカードは相手のエンドフェイズ時に持ち主の手札に戻る。\nまた、このカードと水属性モンスター１体を手札から墓地へ捨てる事で、デッキからモンスター１体を墓地へ送る。\nこのカードが除外された場合、デッキからドラゴン族・水属性モンスター１体を手札に加える事ができる。\n「瀑征竜－タイダル」の効果は１ターンに１度しか使用できない。",li:0,lk:[],ta:[],al:[]},
  {n:"焔征竜－ブラスター",h:"えんせいりゅう－ぶらすたー",e:"Blaster, Dragon Ruler of Infernos",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1800,tx:"自分の手札・墓地からこのカード以外のドラゴン族または炎属性のモンスターを合計２体除外して発動できる。\nこのカードを手札・墓地から特殊召喚する。\n特殊召喚したこのカードは相手のエンドフェイズ時に持ち主の手札に戻る。\nまた、このカードと炎属性モンスター１体を手札から墓地へ捨てる事で、フィールド上のカード１枚を選択して破壊する。\nこのカードが除外された場合、デッキからドラゴン族・炎属性モンスター１体を手札に加える事ができる。\n「焔征竜－ブラスター」の効果は１ターンに１度しか使用できない。",li:0,lk:[],ta:[],al:[]},
  {n:"嵐征竜－テンペスト",h:"らんせいりゅう－てんぺすと",e:"Tempest, Dragon Ruler of Storms",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2200,tx:"このカード名の①～④の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札からこのカードと風属性モンスター１体を墓地へ捨てて発動できる。\nデッキからドラゴン族モンスター１体を手札に加える。\n②：ドラゴン族か風属性のモンスターを自分の手札・墓地から２体除外して発動できる。\nこのカードを手札・墓地から特殊召喚する。\n③：このカードが特殊召喚されている場合、相手エンドフェイズに発動する。\nこのカードを手札に戻す。\n④：このカードが除外された場合に発動できる。\nデッキからドラゴン族・風属性モンスター１体を手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"調星師ライズベルト",h:"ちょうせいしらいずべると",e:"Risebell the Star Adjuster",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが特殊召喚に成功した時、フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターのレベルを３つまで上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"グリーン・ダストン",h:"ぐりーん・だすとん",e:"Green Duston",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーは自分フィールド上の魔法・罠カード１枚を選択して持ち主の手札に戻す。\n「グリーン・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.107 銀河眼の時空竜",h:"なんばーず１０７ ぎゃらくしーあいず・たきおん・どらごん",e:"Number 107: Galaxy-Eyes Tachyon Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル８モンスター×２\n①：自分バトルフェイズ開始時に、このカードのX素材を１つ取り除いて発動できる。\nこのカード以外のフィールドの全ての表側表示モンスターの効果は無効化され、その攻撃力・守備力は元々の数値になる。\nこの効果を発動したターンのバトルフェイズ中に相手が魔法・罠・モンスターの効果を発動する度に、このカードの攻撃力はバトルフェイズ終了時まで１０００アップし、このターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガントレット・シューター",h:"がんとれっと・しゅーたー",e:"Gauntlet Launcher",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2800,tx:"レベル６モンスター×２\n自分のメインフェイズ時、このカードのエクシーズ素材を１つ取り除き、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フェアリー・チア・ガール",h:"ふぇありー・ちあ・がーる",e:"Fairy Cheer Girl",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1500,tx:"天使族レベル４モンスター×２\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\nデッキからカードを１枚ドローする。\n「フェアリー・チア・ガール」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"CX ダーク・フェアリー・チア・ガール",h:"かおすえくしーず　だーく・ふぇありー・ちあ・がーる",e:"CXyz Dark Fairy Cheer Girl",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1900,tx:"レベル５モンスター×３\nこのカードがフィールド上から墓地へ送られた時、デッキからカードを１枚ドローする。\nまた、このカードが「フェアリー・チア・ガール」をエクシーズ素材としている場合、以下の効果を得る。\n●このカードが戦闘によって相手モンスターを破壊した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分の手札の数×４００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"シャーク・フォートレス",h:"しゃーく・ふぉーとれす",e:"Shark Fortress",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魚族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"レベル５モンスター×２\n①：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"零鳥獣シルフィーネ",h:"れいちょうじゅうしるふぃーね",e:"Ice Beast Zerofyne",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2200,tx:"鳥獣族レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上に表側表示で存在する全てのカードの効果を無効にし、このカードの攻撃力はこのカード以外のフィールド上に表側表示で存在するカードの数×３００ポイントアップする。\nこのカードの効果は次の自分のスタンバイフェイズ時まで適用される。",li:"",lk:[],ta:[],al:[]},
  {n:"BK 拘束蛮兵リードブロー",h:"ばーにんぐなっくらー　こうそくばんぺいりーどぶろー",e:"Battlin' Boxer Lead Yoke",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"「BK」と名のついたレベル４モンスター×２\n自分フィールド上の「BK」と名のついたモンスターが戦闘またはカードの効果によって破壊される場合、その破壊されるモンスター１体の代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nまた、このカードのエクシーズ素材が取り除かれた時、このカードの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.105 BK 流星のセスタス",h:"なんばーず１０５ ばーにんぐなっくらー りゅうせいのせすたす",e:"Number 105: Battlin' Boxer Star Cestus",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1600,tx:"レベル４モンスター×３\n①：自分の「BK」モンスターが相手モンスターと戦闘を行うバトルステップに、このカードのX素材を１つ取り除いて発動できる。\nターン終了時までその相手モンスターの効果は表側表示の間だけ無効化され、その自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは代わりに相手が受ける。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.105 BK 彗星のカエストス",h:"かおすなんばーず１０５ ばーにんぐなっくらー すいせいのかえすとす",e:"Number C105: Battlin' Boxer Comet Cestus",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"レベル５モンスター×４\n①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力の半分のダメージを相手に与える。\n②：このカードが「No.105 BK 流星のセスタス」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、表側表示モンスターを破壊した場合、その攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ドラゴサック",h:"げんじゅうきどらごさっく",e:"Mecha Phantom Beast Dracossack",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2200,tx:"レベル７モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）２体を特殊召喚する。\n②：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。\n③：１ターンに１度、自分フィールドの「幻獣機」モンスター１体をリリースし、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"間炎星－コウカンショウ",h:"かんえんせい－こうかんしょう",e:"Brotherhood of the Fire Fist - Cardinal",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2200,tx:"「炎星」と名のついたレベル４モンスター×２\nこのカードのエクシーズ素材を２つ取り除いて発動できる。\n自分のフィールド上・墓地の「炎星」または「炎舞」と名のついたカード合計２枚と、相手の墓地または相手フィールド上に表側表示で存在するカード合計２枚を選択して持ち主のデッキに戻す。\n「間炎星－コウカンショウ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィズペット幻竜",h:"はーぴぃずぺっとみらーじゅどらごん",e:"Harpie's Pet Phantasmal Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"風属性レベル４モンスター×３\nこのカードの効果はこのカードのエクシーズ素材がなければ適用されない。\nこのカードは相手プレイヤーに直接攻撃できる。\nこのカードがフィールド上に表側表示で存在する限り、相手は「ハーピィ」と名のついたモンスターを攻撃対象にできず、カードの効果の対象にもできない。\n自分のエンドフェイズ毎にこのカードのエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"キングレムリン",h:"きんぐれむりん",e:"King of the Feral Imps",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:2000,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから爬虫類族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガウィンド",h:"がががうぃんど",e:"Gagagawind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「ガガガ」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは４になる。",li:"",lk:[],ta:[],al:[]},
  {n:"最強の盾",h:"さいきょうのたて",e:"Magnum Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n①：装備モンスターの表示形式によって以下の効果を適用する。\n●攻撃表示：装備モンスターの攻撃力は、その元々の守備力分アップする。\n●守備表示：装備モンスターの守備力は、その元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・リベンジ",h:"えくしーず・りべんじ",e:"Xyz Revenge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上にエクシーズ素材を持ったエクシーズモンスターが存在する場合、自分の墓地のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚し、相手フィールド上のエクシーズ素材１つを、選択したモンスターの下に重ねてエクシーズ素材とする。\n「エクシーズ・リベンジ」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－バリアンズ・フォース",h:"らんくあっぷまじっく－ばりあんず・ふぉーす",e:"Rank-Up-Magic Barian's Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターと同じ種族でランクが１つ高い「CNo.」または「CX」と名のついたモンスター１体を、選択したモンスターの上に重ねてエクシーズ召喚扱いとしてエクストラデッキから特殊召喚する。\nその後、相手フィールド上にエクシーズ素材が存在する場合、相手フィールド上のエクシーズ素材１つを、この効果で特殊召喚したエクシーズモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急発進",h:"すくらんぶる",e:"Scramble!! Scramble!!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手フィールド上のモンスターの数が、トークン以外の自分フィールド上のモンスターの数より多い場合、自分フィールド上の「幻獣機トークン」を任意の数だけリリースして発動できる。\n「幻獣機」と名のついたモンスターを、リリースしたトークンの数だけデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に持ち主のデッキに戻る。\n「緊急発進」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「玉衝」",h:"えんぶ－「ぎょっこう」",e:"Fire Formation - Gyokkou",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、相手フィールド上にセットされた魔法・罠カード１枚を選択する。\nこのカードの発動に対して相手は選択されたカードを発動できない。\nこのカードがフィールド上に存在する限り、選択されたカードは発動できない。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書の神判",h:"まどうしょのしんぱん",e:"Spellbook of Judgment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードを発動したターンのエンドフェイズ時、このカードの発動後に自分または相手が発動した魔法カードの枚数分まで、自分のデッキから「魔導書の神判」以外の「魔導書」と名のついた魔法カードを手札に加える。\nその後、この効果で手札に加えたカードの数以下のレベルを持つ魔法使い族モンスター１体をデッキから特殊召喚できる。\n「魔導書の神判」は１ターンに１枚しか発動できない。",li:0,lk:[],ta:[],al:[]},
  {n:"アビスケイル－ミヅチ",h:"あびすけいる－みづち",e:"Abyss-scale of the Mizuchi",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「水精鱗」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上で発動した魔法カードの効果を無効にする。\nその後、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒステリック・サイン",h:"ひすてりっく・さいん",e:"Hysteric Sign",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードの発動時の効果処理として、自分のデッキ・墓地から「万華鏡－華麗なる分身－」１枚を選んで手札に加える。\n②：このカードが手札・フィールドから墓地へ送られたターンのエンドフェイズに発動する。\nデッキから「ハーピィ」カードを３枚まで手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"七星の宝刀",h:"しちせいのほうとう",e:"Sacred Sword of Seven Stars",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「七星の宝刀」は１ターンに１枚しか発動できない。\n①：手札または自分フィールドの表側表示モンスターの中から、レベル７モンスター１体を除外して発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"星邪の神喰",h:"せいじゃのしんしょく",e:"Jewels of the Valiant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地のモンスター１体のみがゲームから除外された場合、除外されたそのモンスターと異なる属性のモンスター１体をデッキから墓地へ送る事ができる。\n「星邪の神喰」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サモンブレーカー",h:"さもんぶれーかー",e:"Summon Breaker",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、ターンプレイヤーがそのターン３回目の召喚・反転召喚・特殊召喚に成功した時、そのターンのエンドフェイズになる。\nこの効果はメインフェイズ１でのみ発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"ピンポイント・ガード",h:"ぴんぽいんと・がーど",e:"Pinpoint Guard",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時、自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"もの忘れ",h:"ものわすれ",e:"Memory Loss",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在するモンスターの効果が発動した時に発動できる。\nその発動した効果を無効にし、そのモンスターを表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"激流蘇生",h:"げきりゅうそせい",e:"Torrential Reborn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の水属性モンスターが戦闘またはカードの効果によって破壊され墓地へ送られた時に発動できる。\nその時に破壊され、フィールド上から自分の墓地へ送られたモンスターを全て特殊召喚し、特殊召喚したモンスターの数×５００ポイントダメージを相手ライフに与える。\n「激流蘇生」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ブロック",h:"えくしーず・ぶろっく",e:"Xyz Block",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上のエクシーズ素材を１つ取り除いて発動できる。\n相手が発動した効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"空中補給",h:"えありある・ちゃーじ",e:"Aerial Recharge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは、お互いのエンドフェイズ毎に自分フィールドのトークンまたは「幻獣機」モンスター１体をリリースする。\nまたは、どちらもリリースせずにこのカードを墓地へ送る。\n①：１ターンに１度、発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"弾幕回避",h:"ばれる・ろーる",e:"Do a Barrel Roll",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上の「幻獣機トークン」を全てリリースして発動できる。\n効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「開陽」",h:"えんぶ－「かいよう」",e:"Fire Formation - Kaiyo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードを発動したターン、自分フィールド上の獣戦士族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導人形の夜",h:"まどるちぇ・ないつ",e:"Madolche Nights",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地にモンスターが存在しない場合に発動できる。\n効果モンスターの効果の発動を無効にする。\n自分フィールド上に「マドルチェ・プディンセス」が存在する場合、さらに相手の手札をランダムに１枚デッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアギア",h:"ぎあぎあぎあ",e:"Geargiagear",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"デッキから「ギアギアーノ」と名のついたモンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは１つ上がる。\n「ギアギアギア」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"燃え上がる大海",h:"もえあがるたいかい",e:"High Tide on Fire Island",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にレベル７以上の水属性または炎属性のモンスターが存在する場合に発動できる。\n自分フィールド上のモンスターの属性によって、以下の効果を適用する。\n\n●水属性：このターン効果モンスターの効果を発動するために自分の墓地へ送られた水属性モンスターを可能な限り特殊召喚する。\nその後、自分フィールド上のモンスター１体を選んで破壊する。\n\n●炎属性：フィールド上のモンスター１体を選んで破壊する。\nその後、自分の手札が１枚以上ある場合、１枚を選んで墓地へ捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"メンタルドレイン",h:"めんたるどれいん",e:"Mind Drain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１０００LPを払ってこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、お互いに手札のモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"牙竜転生",h:"がりょうてんせい",e:"Dragoncarnation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ゲームから除外されている自分のドラゴン族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.47 ナイトメア・シャーク",h:"なんばーず４７ ないとめあ・しゃーく",e:"Number 47: Nightmare Shark",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル３モンスター×２\n①：このカードが特殊召喚に成功した時に発動できる。\n手札及び自分フィールドの表側表示モンスターの中から、水属性・レベル３モンスター１体を選び、このカードの下に重ねてX素材とする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの水属性モンスター１体を対象として発動できる。\nこのターン、そのモンスター以外のモンスターは攻撃できず、そのモンスターは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・ダンサー",h:"はーぴぃ・だんさー",e:"Harpie Dancer",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：自分フィールドの風属性モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\nその後、風属性モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガキッド",h:"がががきっど",e:"Gagaga Child",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"自分フィールド上に「ガガガキッド」以外の「ガガガ」と名のついたモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、自分フィールド上の「ガガガ」と名のついたモンスター１体を選択し、このカードのレベルを選択したモンスターと同じレベルにする事ができる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・アンダーテイカー",h:"まじかる・あんだーていかー",e:"Magical Undertaker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:400,tx:"①：このカードがリバースした場合、自分の墓地のレベル４以下の魔法使い族モンスター１体を対象として発動できる。\nその魔法使い族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クロクロークロウ",h:"くろくろーくろう",e:"Caligo Claw Crow",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:900,de:600,tx:"自分フィールド上に闇属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法による「クロクロークロウ」の特殊召喚は１ターンに１度しかできない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の護封剣",h:"ほのおのごふうけん",e:"Swords of Burning Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合、相手フィールド上のモンスターは攻撃宣言できない。\n自分フィールド上にモンスターが存在する場合、または相手の手札が５枚以上の場合、このカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"リボーン・パズル",h:"りぼーん・ぱずる",e:"Puzzle Reborn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスター１体のみがカードの効果によって破壊された場合、その１体を選択して発動できる。\n選択したモンスターを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.39 希望皇ホープレイV",h:"かおすなんばーず３９ きぼうおうほーぷれいぶい",e:"Number C39: Utopia Ray V",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル５モンスター×３\n①：このカードが相手によって破壊された時、自分の墓地のXモンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。\n②：このカードが「希望皇ホープ」モンスターをX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、表側表示モンスターを破壊した場合、その攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－リミテッド・バリアンズ・フォース",h:"らんくあっぷまじっく－りみてっど・ばりあんず・ふぉーす",e:"Rank-Up-Magic Limited Barian's Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のランク４のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターよりランクが１つ高い「CNo.」と名のついたモンスター１体を、選択した自分のモンスターの上に重ねてエクシーズ召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－荒鷲激神爪",h:"ぜある・うぇぽん－いーぐる・くろー",e:"ZW - Eagle Claw",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：「ZW－荒鷲激神爪」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分のLPが相手より２０００以上少ない場合、このカードは手札から特殊召喚できる。\n③：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分フィールドのこのカードを攻撃力２０００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n④：このカードが装備されている場合、１ターンに１度、相手フィールドで発動した罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガンバランサー",h:"がんばらんさー",e:"Ganbara Lancer",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードが召喚に成功した時、自分の手札・墓地から「ガンバランサー」１体を選んで表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"パクバグ",h:"ぱくばぐ",e:"Bite Bug",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:1000,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、相手フィールド上のモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"クレーンクレーン",h:"くれーんくれーん",e:"Crane Crane",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:300,de:900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地のレベル３モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェントルーパー",h:"じぇんとるーぱー",e:"Gentlemander",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"相手モンスターの攻撃宣言時、このカードを手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、相手は他のモンスターを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地征竜－リアクタン",h:"ちせいりゅう－りあくたん",e:"Reactan, Dragon Ruler of Pebbles",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"ドラゴン族または地属性のモンスター１体とこのカードを手札から捨てて発動できる。\nデッキから「巌征竜－レドックス」１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n「地征竜－リアクタン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水征竜－ストリーム",h:"すいせいりゅう－すとりーむ",e:"Stream, Dragon Ruler of Droplets",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"ドラゴン族または水属性のモンスター１体とこのカードを手札から捨てて発動できる。\nデッキから「瀑征竜－タイダル」１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n「水征竜－ストリーム」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダックファイター",h:"だっくふぁいたー",e:"Duck Fighter",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"自分フィールド上のトークンを、そのレベルの合計が３以上になるようにリリースして発動できる。\nこのカードを手札または墓地から特殊召喚する。\n「ダックファイター」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"異界の棘紫獣",h:"いかいのきょくしじゅう",e:"Interplanetarypurplythorny Beast",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2200,tx:"このカードが墓地に存在し、自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時、このカードを墓地から特殊召喚できる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。\n「異界の棘紫獣」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎征竜－バーナー",h:"えんせいりゅう－ばーなー",e:"Burner, Dragon Ruler of Sparks",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"ドラゴン族または炎属性のモンスター１体とこのカードを手札から捨てて発動できる。\nデッキから「焔征竜－ブラスター」１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n「炎征竜－バーナー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"風征竜－ライトニング",h:"ふうせいりゅう－らいとにんぐ",e:"Lightning, Dragon Ruler of Drafts",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"ドラゴン族または風属性のモンスター１体とこのカードを手札から捨てて発動できる。\nデッキから「嵐征竜－テンペスト」１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n「風征竜－ライトニング」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・ピョコレート",h:"まどるちぇ・ぴょこれーと",e:"Madolche Chickolates",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nまた、１ターンに１度、このカードがフィールド上に表側表示で存在し、自分フィールド上の「マドルチェ」と名のついたモンスターの表示形式が変更された時に発動できる。\nフィールド上のモンスター１体を選択して表側守備表示にし、そのモンスターが「マドルチェ」と名のついたモンスター以外だった場合、そのモンスターは攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ビビット騎士",h:"びびっとないと",e:"Vivid Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"自分フィールド上の獣戦士族・光属性モンスター１体が、相手のカードの効果の対象になった時、または相手モンスターの攻撃対象になった時に発動できる。\n対象となった自分のモンスターを次の自分のスタンバイフェイズ時までゲームから除外し、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターシップ・スパイ・プレーン",h:"すたーしっぷ・すぱい・ぷれーん",e:"Starship Spy Plane",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:500,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から特殊召喚できる。\nまた、このカードが手札からの特殊召喚に成功した時、相手フィールド上の魔法・罠カード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"薔薇の聖弓手",h:"ろーず・あーちゃー",e:"Rose Archer",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:100,tx:"自分フィールド上に植物族モンスターが存在し、相手が罠カードを発動した時、このカードを手札から墓地へ送って発動できる。\nその発動を無効にし破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"青き眼の乙女",h:"あおきめのおとめ",e:"Maiden with Eyes of Blue",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドの表側表示のこのカードが効果の対象になった時に発動できる。\n自分の手札・デッキ・墓地から「青眼の白龍」１体を選んで特殊召喚する。\n②：このカードが攻撃対象に選択された時に発動できる。\nその攻撃を無効にし、このカードの表示形式を変更する。\nその後、自分の手札・デッキ・墓地から「青眼の白龍」１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"暴風竜の防人",h:"ぼうふうりゅうのさきもり",e:"Rider of the Storm Winds",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"自分のメインフェイズ時、手札または自分フィールド上のこのモンスターを、装備カード扱いとして自分フィールド上のドラゴン族の通常モンスター１体に装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードの装備モンスターが破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の霊廟",h:"りゅうのれいびょう",e:"Dragon Shrine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからドラゴン族モンスター１体を墓地へ送る。\nこの効果で墓地へ送られたモンスターがドラゴン族の通常モンスターだった場合、さらにデッキからドラゴン族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"銀龍の轟咆",h:"ぎんりゅうのごうほう",e:"Silver's Cry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のドラゴン族の通常モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魂の城",h:"りゅうこんのしろ",e:"Castle of Dragon Souls",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「竜魂の城」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、自分の墓地からドラゴン族モンスター１体を除外し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで７００アップする。\n③：表側表示のこのカードがフィールドから墓地へ送られた時、除外されている自分のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"蒼眼の銀龍",h:"そうがんのぎんりゅう",e:"Azure-Eyes Silver Dragon",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:3000,tx:"チューナー＋チューナー以外の通常モンスター１体以上\n①：このカードが特殊召喚に成功した場合に発動する。\n自分フィールドのドラゴン族モンスターは次のターンの終了時まで、効果の対象にならず、効果では破壊されない。\n②：自分スタンバイフェイズ毎に自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機タートレーサー",h:"げんじゅうきたーとれーさー",e:"Mecha Phantom Beast Turtletracer",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、このカードがフィールド上に表側表示で存在する限り、１ターンに１度、自分フィールド上の「幻獣機トークン」は戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士ガラハド",h:"せいきしがらはど",e:"Noble Knight Gwalchavad",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"このカードはフィールド上に表側表示で存在する限り、通常モンスターとして扱う。\nこのカードが「聖剣」と名のついた装備魔法カードを装備している限り、このカードは効果モンスター扱いとなり以下の効果を得る。\n●自分の墓地の「聖騎士」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを手札に加え、自分フィールド上の「聖剣」と名のついた装備魔法カード１枚を選んで破壊する。\n「聖騎士ガラハド」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機炎星－ゴヨウテ",h:"きえんせい－ごようて",e:"Brotherhood of the Fire Fist - Coyote",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:500,tx:"自分フィールド上に「炎舞」と名のついた魔法・罠カードが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－サルフアビス",h:"まーめいる－さるふあびす",e:"Mermail Abyssbalaen",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"自分のメインフェイズ時、手札から「水精鱗」と名のついたモンスター４体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚した時、このカードの攻撃力を５００ポイントアップし、自分の墓地の「水精鱗」と名のついたモンスターの数まで、相手フィールド上のカードを選択して破壊する。\nまた、このカード以外の表側攻撃表示で存在する「水精鱗」と名のついたモンスター１体をリリースする事で、このターンこのカードが守備表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機動要犀 トリケライナー",h:"きどうようさい　とりけらいなー",e:"Trifortressops",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:2800,tx:"①：相手が３体以上のモンスターの召喚・反転召喚・特殊召喚に成功したターンに発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードは、他のカードの効果を受けず、お互いのスタンバイフェイズ毎に守備力が５００ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"異怪の妖精 エルフォビア",h:"いかいのようせい　えるふぉびあ",e:"Ghost Fairy Elfobia",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:900,de:500,tx:"１ターンに１度、手札の風属性モンスター１体を相手に見せて発動できる。\n次の相手のメインフェイズ１終了時まで、お互いのプレイヤーは、見せたモンスターよりレベルの高いモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トーテムバード",h:"とーてむばーど",e:"Totem Bird",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1400,tx:"風属性レベル３モンスター×２\nこのカードのエクシーズ素材を２つ取り除いて発動できる。\n魔法・罠カードの発動を無効にし破壊する。\nこのカードのエクシーズ素材が無い場合、このカードの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"天命の聖剣",h:"てんめいのせいけん",e:"Noble Arms of Destiny",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「天命の聖剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターは１ターンに１度だけ戦闘・効果では破壊されない。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合、自分フィールドの戦士族の「聖騎士」モンスター１体を対象として発動できる。\nその自分の戦士族の「聖騎士」モンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導書の奇跡",h:"まどうしょのきせき",e:"Spellbook of Miracles",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の魔法使い族エクシーズモンスター１体と、ゲームから除外されている自分の「魔導書」と名のついた魔法カードを２枚まで選択して発動できる。\n選択したモンスターを特殊召喚し、選択した「魔導書」と名のついた魔法カードをそのモンスターの下に重ねてエクシーズ素材とする。\n「魔導書の奇跡」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎虎梁山爆",h:"えんこりょうざんばく",e:"Five Brothers Explosion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの発動時に、自分は自分フィールド上の永続魔法・永続罠カードの数×５００ライフポイント回復する。\nまた、フィールド上に表側表示で存在するこのカードが相手の効果によって墓地へ送られた場合、自分の墓地の永続魔法・永続罠カードの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"DZW－魔装鵺妖衣",h:"だーく・ぜある・うぇぽん－きめら・くろす",e:"DZW - Chimera Clad",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分のメインフェイズ時、手札または自分フィールド上のこのモンスターを、装備カード扱いとして自分フィールド上の「CNo.39」と名のついたモンスターに装備できる。\nまた、このカードが装備カード扱いとして装備されている場合、装備モンスターは戦闘では破壊されない。\n装備モンスターの攻撃によって相手モンスターが破壊されなかったダメージステップ終了時、その相手モンスターの攻撃力を０にしてもう１度だけ同じモンスターに続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Vサラマンダー",h:"ぶいさらまんだー",e:"V Salamander",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが召喚に成功した時、自分の墓地の「希望皇ホープ」と名のついたモンスター１体を選択して特殊召喚できる。\n自分のメインフェイズ時、フィールド上のこのモンスターを自分の「CNo.39 希望皇ホープレイV」に装備できる。\nこのカードが装備されている場合、１ターンに１度、装備モンスターのエクシーズ素材を１つ取り除いて発動できる。\n装備モンスターの効果を無効にし、相手フィールド上のモンスターを全て破壊し、その数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"プリベントマト",h:"ぷりべんとまと",e:"Interceptomato",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"墓地のこのカードをゲームから除外して発動できる。\nこのターン、自分が受ける効果ダメージは０になる。\nこの効果は相手ターンにのみ発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・リサイクラー",h:"まじっく・りさいくらー",e:"Spell Recycler",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"相手モンスターの攻撃宣言時に墓地のこのカードをゲームから除外し、自分の墓地の魔法カード１枚を選択して発動できる。\n自分のデッキの一番上のカードを墓地へ送り、選択したカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・エージェント",h:"えくしーず・えーじぇんと",e:"Xyz Agent",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:300,tx:"このカードが墓地に存在する場合、自分のメインフェイズ時に自分フィールド上の「希望皇ホープ」と名のついたエクシーズモンスター１体を選択して発動できる。\n墓地のこのカードを選択したモンスターの下に重ねてエクシーズ素材とする。\n「エクシーズ・エージェント」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"SDロボ・ライオ",h:"すーぱーでぃふぇんすろぼ・らいお",e:"Super Defense Robot Lio",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが召喚に成功した時、手札から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を特殊召喚できる。\nまた、１ターンに１度、自分の墓地から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体が自分の手札に加わった時、そのモンスターを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"SDロボ・エレファン",h:"すーぱーでぃふぇんすろぼ・えれふぁん",e:"Super Defense Robot Elephan",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:2500,tx:"このカードが召喚に成功した時、手札から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を特殊召喚できる。\n１ターンに１度、自分フィールド上の「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を選択して発動できる。\n選択したモンスターのレベルをエンドフェイズ時まで８にする。\nまた、このカードをエクシーズ召喚の素材とする場合、機械族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"SDロボ・モンキ",h:"すーぱーでぃふぇんすろぼ・もんき",e:"Super Defense Robot Monki",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカードが召喚に成功した時、手札から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を特殊召喚できる。\nまた、１ターンに１度、自分の墓地の機械族モンスター１体をゲームから除外して発動できる。\n自分の墓地から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"光天使ウィングス",h:"ほーりー・らいとにんぐうぃんぐす",e:"Star Seraph Scout",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードが召喚に成功した時、手札から「光天使」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"光天使ブックス",h:"ほーりー・らいとにんぐぶっくす",e:"Star Seraph Sage",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"１ターンに１度、手札の魔法カード１枚を墓地へ送って発動できる。\n手札から「光天使」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"光天使ソード",h:"ほーりー・らいとにんぐそーど",e:"Star Seraph Sword",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"１ターンに１度、手札の「光天使」と名のついたモンスター１体を墓地へ送って発動できる。\nこのカードの攻撃力はエンドフェイズ時まで、墓地へ送ったモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アンブラル・グール",h:"あんぶらる・ぐーる",e:"Umbral Horror Ghoul",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードの攻撃力を０にし、手札から攻撃力０の「アンブラル」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アンブラル・アンフォーム",h:"あんぶらる・あんふぉーむ",e:"Umbral Horror Unform",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃によってこのカードが戦闘で破壊され墓地へ送られた時、デッキから「アンブラル」と名のついたモンスター２体を特殊召喚できる。\n「アンブラル・アンフォーム」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アンブラル・ウィル・オ・ザ・ウィスプ",h:"あんぶらる・うぃる・お・ざ・うぃすぷ",e:"Umbral Horror Will o' the Wisp",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・特殊召喚に成功した時、このカード以外の自分のフィールド上・墓地の「アンブラル」と名のついたモンスター１体を選択して発動できる。\nこのカードのレベルは選択したモンスターのレベルと同じになる。\nまた、フィールド上に表側攻撃表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、このカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"限界竜シュヴァルツシルト",h:"げんかいりゅうしゅう゛ぁるつしると",e:"Schwarzschild Limit Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：相手フィールドに攻撃力２０００以上のモンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神－ヤマト",h:"ぶじん－やまと",e:"Bujin Yamato",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：「武神－ヤマト」は自分フィールドに１体しか表側表示で存在できない。\n②：自分エンドフェイズに発動できる。\nデッキから「武神」モンスター１体を手札に加え、その後手札を１枚選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ムラクモ",h:"ぶじんぎ－むらくも",e:"Bujingi Quilin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"自分のメインフェイズ時、自分フィールド上に「武神」と名のついた獣戦士族モンスターが存在する場合、墓地のこのカードをゲームから除外して発動できる。\n相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。\n「武神器－ムラクモ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ヘツカ",h:"ぶじんぎ－へつか",e:"Bujingi Turtle",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：自分フィールドの「武神」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－タルタ",h:"ぶじんぎ－たるた",e:"Bujingi Wolf",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、このカード以外の自分フィールド上の獣族・獣戦士族・鳥獣族モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ハバキリ",h:"ぶじんぎ－はばきり",e:"Bujingi Crane",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"①：自分の獣戦士族の「武神」モンスターが相手モンスターと戦闘を行うダメージ計算時にこのカードを手札から墓地へ送って発動できる。\nその戦闘を行う自分のモンスターの攻撃力は、そのダメージ計算時のみ元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－オロチ",h:"ぶじんぎ－おろち",e:"Bujingi Ophidian",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"自分のメインフェイズ１で、このカードを手札から墓地へ送り、自分フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ウォーブラン",h:"げんじゅうきうぉーぶらん",e:"Mecha Phantom Beast Warbluran",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"このカードが機械族モンスターのシンクロ召喚の素材として墓地へ送られた場合、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこの効果を適用したターン、自分は風属性以外のモンスターを特殊召喚できない。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、自分フィールド上の「幻獣機」と名のついたモンスター１体をリリースして発動できる。\nこのカードのレベルを１つ上げる。\n「幻獣機ウォーブラン」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ブルーインパラス",h:"げんじゅうきぶるーいんぱらす",e:"Mecha Phantom Beast Blue Impala",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1100,tx:"このカードをシンクロ素材とする場合、機械族モンスターのシンクロ召喚にしか使用できず、他のシンクロ素材モンスターは自分の手札・フィールド上の「幻獣機」と名のついたモンスターでなければならない。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、墓地のこのカードをゲームから除外して発動できる。\n「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機コルトウィング",h:"げんじゅうきこるとうぃんぐ",e:"Mecha Phantom Beast Coltwing",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：自分フィールドに他の「幻獣機」モンスターが存在し、このカードが特殊召喚に成功した場合に発動する。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）２体を特殊召喚する。\n②：このカードのレベルは自分フィールドの「幻獣機トークン」のレベルの合計分だけ上がる。\n③：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。\n④：１ターンに１度、自分フィールドのトークン２体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊し除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ハリアード",h:"げんじゅうきはりあーど",e:"Mecha Phantom Beast Harrliard",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"１ターンに１度、このカード以外のカードの効果を発動するために自分フィールド上のモンスターがリリースされた時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\n手札から「幻獣機」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"孤炎星－ロシシン",h:"こえんせい－ろししん",e:"Brotherhood of the Fire Fist - Boar",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:1400,tx:"このカードをシンクロ素材とする場合、炎属性モンスターのシンクロ召喚にしか使用できない。\nこのカードが戦闘によって破壊され墓地へ送られた時、デッキから「孤炎星－ロシシン」以外の「炎星」と名のついたレベル４モンスター１体を特殊召喚できる。\n１ターンに１度、このカードがフィールド上に存在する場合に「炎星」と名のついたモンスターが自分のエクストラデッキから特殊召喚された時、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"猛炎星－テンレイ",h:"もうえんせい－てんれい",e:"Brotherhood of the Fire Fist - Caribou",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードがカードの効果によって破壊され墓地へ送られたターンのエンドフェイズ時、デッキから「猛炎星－テンレイ」以外の「炎星」と名のついたレベル４モンスター１体を特殊召喚できる。\nこのカードが「炎星」と名のついたシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導天士 トールモンド",h:"まどうてんし　とーるもんど",e:"World of Prophecy",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2900,de:2400,tx:"このカードが魔法使い族モンスターまたは「魔導書」と名のついた魔法カードの効果によって特殊召喚に成功した時、自分の墓地の「魔導書」と名のついた魔法カード２枚を選択して手札に加える事ができる。\nこの効果を発動するターン、自分は他のモンスターを特殊召喚できない。\nこの効果でカードを手札に加えた時、手札の「魔導書」と名のついた魔法カード４種類を相手に見せて発動できる。\nこのカード以外のフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリック・デーモン",h:"とりっく・でーもん",e:"Archfiend Heiress",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合に発動できる。\nデッキから「トリック・デーモン」以外の「デーモン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの騎兵",h:"でーもんのきへい",e:"Archfiend Cavalry",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"①：フィールドのこのカードが効果で破壊され墓地へ送られた場合、「デーモンの騎兵」以外の自分の墓地の「デーモン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"戦慄の凶皇－ジェネシス・デーモン",h:"せんりつのきょうこう－じぇねしす・でーもん",e:"Archfiend Emperor, the First Lord of Horror",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚したこのカードの元々の攻撃力・守備力は半分になり、エンドフェイズに破壊される。\n③：このカードがモンスターゾーンに存在する限り、自分は悪魔族モンスターしか特殊召喚できない。\n④：１ターンに１度、自分の手札・墓地の「デーモン」カード１枚を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アトラの蟲惑魔",h:"あとらのこわくま",e:"Traptrix Atrax",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードはモンスターゾーンに存在する限り、「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。\n②：このカードがモンスターゾーンに存在する限り、自分は「ホール」通常罠カード及び「落とし穴」通常罠カードを手札から発動できる。\n③：このカードがモンスターゾーンに存在する限り、自分の通常罠カードの発動及びその発動した効果は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"トリオンの蟲惑魔",h:"とりおんのこわくま",e:"Traptrix Myrmeleo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ホール」通常罠カードまたは「落とし穴」通常罠カード１枚を手札に加える。\n②：このカードが特殊召喚に成功した場合、相手フィールドの魔法・罠カード１枚を対象として発動する。\nその相手のカードを破壊する。\n③：このカードはモンスターゾーンに存在する限り、「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"カズーラの蟲惑魔",h:"かずーらのこわくま",e:"Traptrix Nepenthes",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。\n②：自分が「ホール」通常罠カードまたは「落とし穴」通常罠カードを発動した場合に発動できる。\nデッキから「カズーラの蟲惑魔」以外の「蟲惑魔」モンスター１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ザ・キャリブレーター",h:"ざ・きゃりぶれーたー",e:"The Calibrator",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードの攻撃力は、相手フィールド上のエクシーズモンスターのランクの合計×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"桜姫タレイア",h:"おうひたれいあ",e:"Talaya, Princess of Cherry Blossoms",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2800,de:1200,tx:"①：このカードの攻撃力は、自分フィールドの植物族モンスターの数×１００アップする。\n②：このカードがモンスターゾーンに存在する限り、このカード以外のフィールドの植物族モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ぴよコッコ",h:"ぴよこっこ",e:"Cheepcheepcheep",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:200,tx:"リバース：デッキからレベル５以上のチューナー１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"カメンレオン",h:"かめんれおん",e:"Masked Chameleon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1100,tx:"このカードは自分フィールドにレベル５以上のモンスターが存在しない場合のみ召喚できる。\nこのカードの効果を発動するターン、自分はエクストラデッキからの特殊召喚及びこのカードの効果でしか特殊召喚できない。\n①：このカードが召喚に成功した時、自分の墓地の守備力０のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"飛翔するG",h:"ひしょうするじー",e:"Flying \"C\"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:700,de:700,tx:"相手がモンスターの召喚・特殊召喚に成功した時、このカードを手札から相手フィールド上に表側守備表示で特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、このカードのコントローラーはエクシーズ召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"イエロー・ダストン",h:"いえろー・だすとん",e:"Yellow Duston",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーは自分の墓地のモンスター１体を選択してデッキに戻す。\n「イエロー・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機コンコルーダ",h:"げんじゅうきこんこるーだ",e:"Mecha Phantom Beast Concoruda",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のトークンは戦闘及び効果では破壊されない。\nフィールド上のこのカードが相手によって破壊され墓地へ送られた場合、自分フィールド上のトークンを全てリリースして発動できる。\n自分の墓地からレベル４以下の「幻獣機」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"罡炎星－リシュンキ",h:"こうえんせい－りしゅんき",e:"Brotherhood of the Fire Fist - Kirin",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:2800,tx:"炎属性チューナー＋チューナー以外の「炎星」と名のついたモンスター１体以上\nこのカードがシンクロ召喚に成功した時、デッキから「炎舞」と名のついた魔法・罠カード１枚を選んで自分フィールド上にセットできる。\nこのカードがフィールド上に表側表示で存在する限り、相手フィールド上のモンスターの攻撃力は、自分フィールド上に表側表示で存在する魔法・罠カードの数×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"霞鳥クラウソラス",h:"かちょうくらうそらす",e:"Mist Bird Clausolas",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\nターン終了時まで選択したモンスターの攻撃力を０にし、その効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界闘士 バルムンク",h:"まかいとうし　ばるむんく",e:"Underworld Fighter Balmung",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがカードの効果によって破壊され墓地へ送られた時、自分の墓地からこのカード以外のレベル４以下のモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻層の守護者アルマデス",h:"げんそうのしゅごしゃあるまです",e:"Armades, Keeper of Boundaries",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超念導体ビヒーマス",h:"さいこんだくたーびひーます",e:"HTS Psyhemuth",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが相手モンスターと戦闘を行った時、そのモンスターとこのカードをゲームから除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"星態龍",h:"せいたいりゅう",e:"Star Eater",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：このカードのS召喚は無効化されない。\n②：このカードのS召喚成功時にお互いは魔法・罠・モンスターの効果を発動できない。\n③：このカードは攻撃する場合、ダメージステップ終了時まで他のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.39 希望皇ホープレイ・ヴィクトリー",h:"かおすなんばーず３９ きぼうおうほーぷれい・う゛ぃくとりー",e:"Number C39: Utopia Ray Victory",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2500,tx:"レベル５モンスター×３\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが「希望皇ホープ」モンスターをX素材としている場合、以下の効果を得る。\n●このカードが相手の表側表示モンスターに攻撃宣言した時、このカードのX素材を１つ取り除いて発動できる。\nターン終了時まで、その相手モンスターの効果は無効化され、このカードの攻撃力はその相手モンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"牙鮫帝シャーク・カイゼル",h:"がこうていしゃーく・かいぜる",e:"Shark Caesar",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2000,tx:"レベル３モンスター×３体以上（最大５体まで）\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードにシャークカウンターを１つ置く。\nまた、このカードが戦闘を行うダメージステップの間、このカードの攻撃力はこのカードに乗っているシャークカウンターの数×１０００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"輝光帝ギャラクシオン",h:"きこうていぎゃらくしおん",e:"Starliege Lord Galaxion",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2100,tx:"「フォトン」と名のついたレベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を２つまで取り除いて発動できる。\nこの効果を発動するために取り除いたエクシーズ素材の数によって以下の効果を適用する。\n●１つ：手札から「銀河眼の光子竜」１体を特殊召喚する。\n●２つ：デッキから「銀河眼の光子竜」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"廃品眼の太鼓竜",h:"がらくたーあいず・ふぁっと・どらごん",e:"Googly-Eyes Drum Dragon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"機械族レベル８モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力は次の相手のエンドフェイズ時まで１０００ポイントアップする。\nエクシーズ素材を持っているこのカードが破壊された場合、自分の墓地の「SDロボ」と名のついたモンスター１体をゲームから除外する事で、このカードを墓地から特殊召喚する。\nその後、自分の墓地の「SDロボ」と名のついたモンスター１体を選んで、このカードの下に重ねてエクシーズ素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"零鳥姫リオート・ハルピュイア",h:"れいちょうきりおーと・はるぴゅいあ",e:"Ice Princess Zereort",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"鳥獣族レベル５モンスター×２\nこのカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.102 光天使グローリアス・ヘイロー",h:"なんばーず１０２ ほーりー・らいとにんぐぐろーりあす・へいろー",e:"Number 102: Star Seraph Sentry",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"光属性レベル４モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は半分になり、効果は無効化される。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を全て取り除く事ができる。\nこの効果を適用したターン、自分が受ける戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.66 覇鍵甲虫マスター・キー・ビートル",h:"なんばーず６６ はけんこうちゅうますたー・きー・びーとる",e:"Number 66: Master Key Beetle",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:800,tx:"闇属性レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、このカード以外の自分フィールドのカード１枚を対象として発動できる。\nこのカードは以下の効果を得る。\n●このカードがモンスターゾーンに存在する限り、対象のカードは効果では破壊されない。\n\n●このカードが戦闘・効果で破壊される場合、代わりに対象の自分のカード１枚を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.104 仮面魔踏士シャイニング",h:"なんばーず１０４ ますかれーど・まじしゃんしゃいにんぐ",e:"Number 104: Masquerade",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:1200,tx:"レベル４モンスター×３\n①：１ターンに１度、自分メインフェイズに発動できる。\n相手のデッキの一番上のカードを墓地へ送る。\n②：自分・相手のバトルフェイズに相手がモンスターの効果を発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし、相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.104 仮面魔踏士アンブラル",h:"かおすなんばーず１０４ ますかれーど・まじしゃんあんぶらる",e:"Number C104: Umbral Horror Masquerade",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:1500,tx:"レベル５モンスター×４\n①：このカードが特殊召喚に成功した時、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが「No.104 仮面魔踏士シャイニング」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、相手フィールドのモンスターの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にする。\nその後、相手の手札をランダムに１枚選んで墓地へ送り、相手のLPを半分にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神帝－スサノヲ",h:"ぶじんてい－すさのを",e:"Bujintei Susanowo",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"レベル４「武神」モンスター×２\n①：「武神帝－スサノヲ」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードは相手モンスター全てに１回ずつ攻撃できる。\n③：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから「武神」モンスター１体を選び、手札に加えるか墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"聖光の宣告者",h:"せいんと・でくれあらー",e:"Herald of Pure Light",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:600,de:1000,tx:"レベル２モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札を１枚選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－ヌメロン・フォース",h:"らんくあっぷまじっく－ぬめろん・ふぉーす",e:"Rank-Up-Magic Numeron Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのXモンスター１体を対象として発動できる。\nその自分のモンスターと同じ種族でランクが１つ高い「CNo.」モンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nその後、この効果で特殊召喚したモンスター以外のフィールドの全ての表側表示のカードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・レセプション",h:"えくしーず・れせぷしょん",e:"Xyz Reception",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターと同じレベルのモンスター１体を手札から特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になり、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の古戦場－サルガッソ",h:"いじげんのこせんじょう－さるがっそ",e:"Sargasso the D.D. Battlefield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"エクシーズ召喚に成功する度に、そのプレイヤーは５００ポイントダメージを受ける。\nまた、エクシーズモンスターをコントロールしているプレイヤーは、それぞれ自分のエンドフェイズ毎に５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"サルガッソの灯台",h:"さるがっそのとうだい",e:"Sargasso Lighthouse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分にダメージを与える魔法カードの効果が発動した時に発動できる。\nその効果によって自分が受ける効果ダメージは０になる。\nまた、このカードが墓地に存在する限り、「異次元の古戦場－サルガッソ」の効果によって自分が受ける効果ダメージは０になる。\nセットされたこのカードが墓地へ送られた時、デッキから「異次元の古戦場－サルガッソ」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神降臨",h:"ぶじんこうりん",e:"Bujincarnation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、自分の墓地の「武神」と名のついたモンスター１体と、ゲームから除外されている自分の「武神」と名のついたモンスター１体を選択して発動できる。\n選択したモンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターをエクシーズ召喚の素材とする場合、獣族・獣戦士族・鳥獣族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"垂直着陸",h:"う゛ぁーてぃかる・らんでぃんぐ",e:"Vertical Landing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"トークン以外の自分フィールド上の風属性モンスターを任意の数だけリリースして発動できる。\nリリースしたモンスターの数だけ、自分フィールド上に「幻獣機トークン」（機械族・風・星３・攻／守０）を特殊召喚する。\n「垂直着陸」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「揺光」",h:"えんぶ－「ようこう」",e:"Fire Formation - Yoko",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に相手フィールド上に表側表示で存在するカード１枚を選択する事ができる。\nその場合、自分の手札から獣戦士族モンスター１体を捨て、選択したカードを破壊する。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"伏魔殿－悪魔の迷宮－",h:"でーもんぱれす－あくまのめいきゅう－",e:"Archfiend Palabyrinth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上の悪魔族モンスターの攻撃力は５００ポイントアップする。\nまた、自分フィールド上の「デーモン」と名のついたモンスター１体を選択して発動できる。\n選択したモンスター以外の自分フィールド上の悪魔族モンスター１体を選んでゲームから除外し、自分の手札・デッキ・墓地から選択したモンスターと同じレベルの「デーモン」と名のついたモンスター１体を選んで特殊召喚する。\n「伏魔殿－悪魔の迷宮－」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トランスターン",h:"とらんすたーん",e:"Transmodify",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体を墓地へ送って発動できる。\n種族・属性が墓地のそのモンスターと同じでレベルが１つ高いモンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒白の波動",h:"こくびゃくのはどう",e:"Black and White Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"シンクロモンスターをエクシーズ素材としているエクシーズモンスターがフィールド上に存在する場合に発動できる。\nフィールド上のカード１枚を選択してゲームから除外し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"一点買い",h:"いってんがい",e:"Single Purchase",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード以外の手札が３枚以上存在し、その中にモンスターカードが存在しない場合に、手札を全て表側表示でゲームから除外して発動できる。\nデッキからモンスター１体を手札に加える。\nこのターン、自分は手札に加えた同名モンスター以外のモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"逆さ眼鏡",h:"さかさめがね",e:"Reverse Glasses",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する全てのモンスターの攻撃力をエンドフェイズ時まで半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・リベンジ・シャッフル",h:"えくしーず・りべんじ・しゃっふる",e:"Xyz Revenge Shuffle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズモンスターが攻撃対象に選択された時、自分の墓地のエクシーズモンスター１体を選択して発動できる。\n攻撃対象となったエクシーズモンスターを持ち主のエクストラデッキに戻す。\nその後、選択したモンスターを墓地から特殊召喚し、このカードを下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"罪鍵の法－シン・キー・ロウ",h:"つみかぎのほう－しん・きー・ろう",e:"Corrupted Keys",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n「アンブラル・ミラージュ・トークン」（悪魔族・闇・星１・攻？／守０）３体を特殊召喚する。\nこのトークンの攻撃力は選択したモンスターの攻撃力と同じになる。\nこのトークンは直接攻撃できず、アドバンス召喚以外のためにはリリースできない。\n選択したモンスターがフィールド上から離れた時、この効果で特殊召喚したトークンを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"Vain－裏切りの嘲笑",h:"う゛ぇいん－うらぎりのちょうしょう",e:"Vain Betrayer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上のエクシーズモンスターが攻撃宣言した時、そのモンスター１体を選択して発動できる。\n選択したモンスターは攻撃できず、効果は無効化される。\n選択したモンスターがフィールド上に存在する限り、相手のエンドフェイズ毎に相手のデッキの上からカードを３枚墓地へ送る。\n選択したモンスターがフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣現する武神",h:"けんげんするぶじん",e:"Bujin Regalia - The Sword",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"以下の効果から１つを選択して発動できる。\n\n●自分の墓地の「武神」と名のついたモンスター１体を選択して手札に加える。\n\n●ゲームから除外されている自分の「武神」と名のついたモンスター１体を選択して墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"武神逐",h:"ぶじんやらい",e:"Bujinfidel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「武神」と名のついた獣戦士族モンスター１体をリリースして発動できる。\nそのモンスターのカード名と異なる「武神」と名のついたモンスター１体を自分の墓地から選択して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超音速波",h:"そにっく・ぶーむ",e:"Sonic Boom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のターンにフィールド上の「幻獣機」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターはこのターン、攻撃力が元々の攻撃力の倍になり、このカード以外の魔法・罠カードの効果を受けず、守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nその後、このターンのエンドフェイズ時に自分フィールド上の機械族モンスターを全て破壊する。\nこのカードを発動するターン、選択したモンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"蟲惑の落とし穴",h:"こわくのおとしあな",e:"Traptrix Trap Hole Nightmare",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターンに特殊召喚された相手フィールドのモンスターが効果を発動した時に発動できる。\nその効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・リバーサル",h:"えくしーず・りばーさる",e:"Xyz Reversal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上のエクシーズモンスター１体と自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターのコントロールを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"シェイプシスター",h:"しぇいぷしすたー",e:"Shapesister",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードは発動後、通常モンスター（悪魔族・チューナー・地・星２・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉の指名者",h:"しゅうえんのしめいしゃ",e:"Armageddon Designator",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚ゲームから除外して発動できる。\nお互いのプレイヤーはこのデュエル中、このカードを発動するために除外したカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.106 溶岩掌ジャイアント・ハンド・レッド",h:"かおすなんばーず１０６ ようがんしょうじゃいあんと・はんど・れっど",e:"Number C106: Giant Red Hand",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル５モンスター×３\n①：このカードが「No.」モンスターをX素材としている場合、以下の効果を得る。\n●１ターンに１度、魔法・罠・モンスターの効果がフィールドで発動した時に発動する。\nこのカードのX素材を１つ取り除き、このカード以外のフィールドの全ての表側表示のカードの効果はターン終了時まで無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"希望の創造者",h:"きぼうのそうぞうしゃ",e:"",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:900,tx:"①：このカードが相手によって破壊され墓地へ送られた次の自分ドローフェイズ開始時に、自分のLPが相手より少ない場合、「かっとビングだ！オレ！」と宣言して発動できる。\nデッキからカード１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの将星",h:"でーもんのしょうせい",e:"Archfiend Commander",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「デーモン」カードが存在する場合、このカードは手札から特殊召喚できる。\nこのターン、このカードは攻撃できない。\n②：このカードの①の方法で特殊召喚に成功した場合、自分フィールドの「デーモン」カード１枚を対象として発動する。\nその自分の「デーモン」カードを破壊する。\n③：このカードがアドバンス召喚に成功した時、自分の墓地のレベル６の「デーモン」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産アステカ・マスク・ゴーレム",h:"おーぱーつあすてか・ますく・ごーれむ",e:"Chronomaly Aztec Mask Golem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分のターンに自分が「オーパーツ」と名のついた魔法カードを発動している場合、このカードは手札から特殊召喚できる。\n「先史遺産アステカ・マスク・ゴーレム」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産カブレラの投石機",h:"おーぱーつかぶれらのとうせきき",e:"Chronomaly Cabrera Trebuchet",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"１ターンに１度、このカード以外の自分フィールド上の「先史遺産」と名のついたモンスター１体をリリースし、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力をエンドフェイズ時まで０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産マッドゴーレム・シャコウキ",h:"おーぱーつまっどごーれむ・しゃこうき",e:"Chronomaly Mud Golem",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「先史遺産」と名のついたモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産ソル・モノリス",h:"おーぱーつそる・ものりす",e:"Chronomaly Sol Monolith",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"１ターンに１度、自分フィールド上の「先史遺産」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのレベルは６になる。\nこの効果を発動するターン、自分は「先史遺産」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ボム・エッグ",h:"ぎみっく・ぱぺっと－ぼむ・えっぐ",e:"Gimmick Puppet Egg Head",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分のメインフェイズ時に手札から「ギミック・パペット」と名のついたモンスター１体を捨て、以下の効果から１つを選択して発動できる。\n「ギミック・パペット－ボム・エッグ」の効果は１ターンに１度しか使用できない。\n\n●相手ライフに８００ポイントダメージを与える。\n\n●このカードのレベルはエンドフェイズ時まで８になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ギア・チェンジャー",h:"ぎみっく・ぱぺっと－ぎあ・ちぇんじゃー",e:"Gimmick Puppet Gear Changer",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードはデッキから特殊召喚できない。\n１ターンに１度、このカード以外の自分フィールド上の「ギミック・パペット」と名のついたモンスター１体を選択して発動できる。\nこのカードのレベルは選択したモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ナイト・ジョーカー",h:"ぎみっく・ぱぺっと－ないと・じょーかー",e:"Gimmick Puppet Twilight Joker",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"自分フィールド上の「ギミック・パペット」と名のついたモンスターが戦闘によって破壊され自分の墓地へ送られた時、そのモンスターをゲームから除外して発動できる。\nこのカードを手札から特殊召喚する。\n「ギミック・パペット－ナイト・ジョーカー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－シザー・アーム",h:"ぎみっく・ぱぺっと－しざー・あーむ",e:"Gimmick Puppet Scissor Arms",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが召喚に成功した時、デッキから「ギミック・パペット」と名のついたモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ナイトメア",h:"ぎみっく・ぱぺっと－ないとめあ",e:"Gimmick Puppet Nightmare",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードは自分フィールド上に表側表示で存在するエクシーズモンスター１体をリリースして手札から特殊召喚できる。\nこの方法による「ギミック・パペット－ナイトメア」の特殊召喚は１ターンに１度しかできない。\nこの方法で特殊召喚に成功した時、自分の手札・墓地から「ギミック・パペット－ナイトメア」１体を選んで特殊召喚できる。\nまた、このカードが特殊召喚に成功したターン、自分は「ギミック・パペット」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C アンブッシュ・ソルジャー",h:"ひろいっく・ちゃれんじゃー　あんぶっしゅ・そるじゃー",e:"Heroic Challenger - Ambush Soldier",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分のスタンバイフェイズ時、フィールド上のこのカードをリリースして発動できる。\n自分の手札・墓地から「H・C アンブッシュ・ソルジャー」以外の「H・C」と名のついたモンスターを２体まで選んで特殊召喚できる。\n「H・C アンブッシュ・ソルジャー」のこの効果は１ターンに１度しか使用できない。\nこの効果で特殊召喚に成功した時、墓地のこのカードをゲームから除外する事で、自分フィールド上の全ての「H・C」と名のついたモンスターのレベルを１にする。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C クラスプ・ナイフ",h:"ひろいっく・ちゃれんじゃー　くらすぷ・ないふ",e:"Heroic Challenger - Clasp Sword",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカードが「H・C」と名のついたモンスターの効果によって特殊召喚に成功した時、デッキから「H・C」と名のついたモンスター１体を手札に加える事ができる。\n「H・C クラスプ・ナイフ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻蝶の刺客オオルリ",h:"げんちょうのしきゃくおおるり",e:"Blue Mountain Butterspy",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:1700,tx:"このカードは通常召喚できない。\n自分が戦士族モンスターの召喚に成功した時、このカードを手札から特殊召喚できる。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"おもちゃ箱",h:"おもちゃばこ",e:"Box of Friends",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが破壊され墓地へ送られた場合に発動できる。\nデッキから攻撃力か守備力が０の通常モンスター２体を守備表示で特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターはS素材にできず、次の自分エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"腐乱犬",h:"ふらんけん",e:"Zombowwow",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードの攻撃力は、このカードが攻撃宣言する度に５００ポイントアップする。\nまた、フィールド上のこのカードが破壊され墓地へ送られた場合、デッキから攻撃力と守備力が０のレベル１モンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化され、次の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"No.54 反骨の闘士ライオンハート",h:"なんばーず５４ はんこつのとうしらいおんはーと",e:"Number 54: Lion Heart",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:100,de:100,tx:"レベル１モンスター×３\n①：攻撃表示のこのカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行うダメージ計算時に１度、このカードのX素材を１つ取り除いて発動できる。\nその戦闘で発生する自分への戦闘ダメージは代わりに相手が受ける。\n③：このカードの戦闘で自分が戦闘ダメージを受けた場合に発動する。\n受けたダメージの数値分だけ相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.44 白天馬スカイ・ペガサス",h:"なんばーず４４ はくてんますかい・ぺがさす",e:"Number 44: Sky Pegasus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1600,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\n相手は１０００LPを払ってこの効果を無効にできる。\n払わなかった場合、対象のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.49 秘鳥フォーチュンチュン",h:"なんばーず４９ ひちょうふぉーちゅんちゅん",e:"Number 49: Fortune Tune",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:400,de:900,tx:"レベル３モンスター×２\nこのカード名の④の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードは効果の対象にならない。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。\n③：自分スタンバイフェイズに発動する。\n自分は５００LP回復する。\n④：このカードがフィールドから墓地へ送られた場合、自分の墓地のレベル３モンスター２体を対象として発動する。\nそのモンスター２体をデッキに戻し、このカードをEXデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"No.57 奮迅竜トレスラグーン",h:"なんばーず５７ ふんじんりゅうとれすらぐーん",e:"Number 57: Tri-Head Dust Dragon",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:100,de:2600,tx:"レベル４モンスター×３\n①：このカードが特殊召喚に成功した時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードの攻撃力は、そのモンスターの攻撃力分アップする。\n②：相手フィールドのカードの数が自分フィールドのカードより多い場合、このカードのX素材を１つ取り除き、使用していないモンスターゾーンまたは使用していない魔法＆罠ゾーンを１ヵ所指定して発動できる。\nこのカードは以下の効果を得る。\n\n●このカードがモンスターゾーンに存在する限り、指定したゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.63 おしゃもじソルジャー",h:"なんばーず６３ おしゃもじそるじゃー",e:"Number 63: Shamoji Soldier",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2000,tx:"レベル１モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n\n●次の相手スタンバイフェイズ開始時に、お互いのプレイヤーはデッキから１枚ドローする。\n\n●お互いのプレイヤーは１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.74 マジカル・クラウン－ミッシング・ソード",h:"なんばーず７４ まじかる・くらうん－みっしんぐ・そーど",e:"Number 74: Master of Blades",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2300,tx:"レベル７モンスター×２\n①：このカードを対象とする魔法・罠・モンスターの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。\nその後、フィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.85 クレイジー・ボックス",h:"なんばーず８５ くれいじー・ぼっくす",e:"Number 85: Crazy Box",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:300,tx:"レベル４モンスター×２\n①：このカードは攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nサイコロを１回振り、出た目によって以下の効果を適用する。\n\n●１：自分のLPを半分にする。\n\n●２：自分はデッキから１枚ドローする。\n\n●３：相手は手札を１枚選んで捨てる。\n\n●４：フィールドの表側表示のカード１枚を選び、その効果をターン終了時まで無効にする。\n\n●５：フィールドのカード１枚を選んで破壊する。\n\n●６：このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.87 雪月花美神クイーン・オブ・ナイツ",h:"なんばーず８７ せつげっかびしんくいーん・おぶ・ないつ",e:"Number 87: Queen of the Night",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:2800,tx:"レベル８モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\nこの効果は相手ターンでも発動できる。\n\n●相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nこのカードがモンスターゾーンに存在する限り、そのカードは発動できない。\n\n●フィールドの植物族モンスター１体を対象として発動できる。\nその植物族モンスターを裏側守備表示にする。\n\n●フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"機装天使エンジネル",h:"きそうてんしえんじねる",e:"Mechquipped Angineer",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1000,tx:"レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側守備表示にし、このターンそのモンスターは戦闘及びカードの効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"CX 機装魔人エンジェネラル",h:"かおすえくしーず　きそうまじんえんじぇねらる",e:"CXyz Mechquipped Djinn Angeneral",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1600,tx:"レベル４モンスター×３\nこのカードがエクシーズ召喚に成功した時、フィールド上に守備表示で存在するモンスター１体を選択して表側攻撃表示にする事ができる。\nまた、このカードが「機装天使エンジネル」をエクシーズ素材としている場合、以下の効果を得る。\n●このカードが相手ライフに戦闘ダメージを与えた時、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CX 熱血指導神アルティメットレーナー",h:"かおすえくしーず　ねっけつしどうしんあるてぃめっとれーなー",e:"CXyz Coach Lord Ultimatrainer",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3800,de:2300,tx:"レベル９モンスター×４\nフィールド上のこのカードはカードの効果の対象にならない。\nまた、このカードがエクシーズモンスターをエクシーズ素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nデッキからカードを１枚ドローし、お互いに確認する。\n確認したカードがモンスターだった場合、さらに相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"風紀宮司ノリト",h:"ふうきぐうじのりと",e:"Norito the Moral Leader",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"魔法使い族レベル６モンスター×２\n１ターンに１度、相手が魔法・罠カードを発動した時にこのカードのエクシーズ素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"CX 風紀大宮司サイモン",h:"かおすえくしーず　ふうきだいぐうじさいもん",e:"CXyz Simon the Great Moral Leader",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル７モンスター×３\nこのカードはこのカード以外のモンスターの効果を受けない。\nまた、このカードが「風紀宮司ノリト」をエクシーズ素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの表示形式を変更し、そのモンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"CHキング・アーサー",h:"こみっくひーろーきんぐ・あーさー",e:"Comics Hero King Arthur",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"戦士族レベル４モンスター×２\nこのカードが戦闘によって破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nこの効果でエクシーズ素材が取り除かれた時、このカードの攻撃力は５００ポイントアップし、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CX－CHレジェンド・アーサー",h:"かおすえくしーず－こみっくひーろーれじぇんど・あーさー",e:"CXyz Comics Hero Legend Arthur",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2400,tx:"レベル５モンスター×３\nこのカードは１ターンに１度だけ戦闘では破壊されない。\nまた、このカードが「CHキング・アーサー」をエクシーズ素材としている場合、以下の効果を得る。\n●このカードが戦闘によってモンスターを破壊し墓地へ送った時、このカードのエクシーズ素材を１つ取り除いて発動できる。\n破壊したモンスターをゲームから除外し、その元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"烈華砲艦ナデシコ",h:"れっかほうかんなでしこ",e:"Battlecruiser Dianthus",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"レベル３モンスター×３\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\n相手の手札の数×２００ポイントダメージを相手ライフに与える。\n「烈華砲艦ナデシコ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"CX 激烈華戦艦タオヤメ",h:"かおすえくしーず　げきれっかせんかんたおやめ",e:"CXyz Battleship Cherry Blossom",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"レベル４モンスター×４\n相手のエンドフェイズ時に１度だけ相手の手札が自分の手札より多い場合に発動する。\n相手は手札を１枚選んで捨てる。\nまた、このカードが「烈華砲艦ナデシコ」をエクシーズ素材としている場合、以下の効果を得る。\n●このカードのエクシーズ素材を１つ取り除いて発動できる。\nフィールド上のカードの数×３００ポイントダメージを相手ライフに与える。\n「CX 激烈華戦艦タオヤメ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超巨大空中宮殿ガンガリディア",h:"ちょうきょだいくうちゅうきゅうでんがんがりでぃあ",e:"Skypalace Gangaridai",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3400,de:3000,tx:"レベル１０モンスター×２\nこのカード名の効果は１ターンに１度しか使用できず、この効果を発動するターン、このカードは攻撃できない。\n①：このカードのX素材を１つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊し、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CX 超巨大空中要塞バビロン",h:"かおすえくしーず　ちょうきょだいくうちゅうようさいばびろん",e:"CXyz Skypalace Babylon",t:"monster",a:"風",l:11,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3800,de:4000,tx:"レベル１１モンスター×３\nこのカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。\nまた、このカードが「超巨大空中宮殿ガンガリディア」をエクシーズ素材としている場合、以下の効果を得る。\n●このカードが戦闘によってモンスターを破壊した場合、このカードのエクシーズ素材を１つ取り除く事で、もう１度だけ続けて攻撃できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・アレキサンドラ・クィーン",h:"ふぉとん・あれきさんどら・くぃーん",e:"Photon Alexandra Queen",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"「幻蝶の刺客」と名のついたレベル４モンスター×２\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\nフィールド上のモンスターを全て持ち主の手札に戻す。\nその後、この効果でカードが手札に加わったプレイヤーは、その数×３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイト・バタフライ・アサシン",h:"ないと・ばたふらい・あさしん",e:"Night Papilloperative",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力はフィールド上のエクシーズ素材の数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"虚構王アンフォームド・ボイド",h:"きょこうおうあんふぉーむど・ぼいど",e:"Unformed Void",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル４モンスター×３\n相手のメインフェイズ時に１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力・守備力は、相手フィールド上のエクシーズモンスターの攻撃力を合計した数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"プリンセス・コロン",h:"ぷりんせす・ころん",e:"Princess Cologne",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:2200,tx:"レベル４モンスター×２\n①：このカードがX召喚に成功した時、自分の墓地の「おもちゃ箱」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドに他のモンスターが存在する限り、相手はこのカードを攻撃対象に選択できず、効果の対象にもできない。\n③：自分フィールドの表側表示の通常モンスターが戦闘・効果で破壊され墓地へ送られた場合、このカードのX素材を１つ取り除いて発動できる。\n自分のデッキ・墓地から通常モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"傀儡儀式－パペット・リチューアル",h:"くぐつぎしき－ぱぺっと・りちゅーある",e:"Puppet Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のライフポイントが相手より２０００ポイント以上少ない場合に発動できる。\n自分の墓地から「ギミック・パペット」と名のついたレベル８モンスター２体を選択して特殊召喚する。\n「傀儡儀式－パペット・リチューアル」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"ストイック・チャレンジ",h:"すといっく・ちゃれんじ",e:"Stoic Challenge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"エクシーズ素材を持っているエクシーズモンスターにのみ装備可能。\n装備モンスターの攻撃力は自分フィールド上のエクシーズ素材の数×６００ポイントアップし、相手モンスターとの戦闘によって相手ライフに与える戦闘ダメージは倍になる。\nまた、装備モンスターは効果を発動できない。\nこのカードは相手のエンドフェイズ時に墓地へ送られる。\nこのカードがフィールド上から離れた時、装備モンスターを破壊する。\n「ストイック・チャレンジ」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーレイ・キャプチャー",h:"おーばーれい・きゃぷちゃー",e:"Overlay Capture",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上のエクシーズ素材を持ったエクシーズモンスター１体と、自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択した相手モンスターのエクシーズ素材を全て取り除き、このカードを選択した自分のモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナンバーズ・ウォール",h:"なんばーず・うぉーる",e:"Number Wall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「No.」と名のついたモンスターが存在する場合に発動できる。\nこのカードがフィールド上に存在する限り、お互いのフィールド上の「No.」と名のついたモンスターは、カードの効果では破壊されず、「No.」と名のついたモンスター以外との戦闘では破壊されない。\n自分フィールド上の「No.」と名のついたモンスターが破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章の記録",h:"めだりおん・れこーど",e:"Heraldry Record",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手フィールド上のエクシーズモンスターがそのエクシーズ素材を取り除いて効果を発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻蝶の護り",h:"げんちょうのまもり",e:"Butterspy Protection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側守備表示にする。\nこのカードを発動したターン、自分が受ける全てのダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシー・ドラグーン",h:"ぎゃらくしー・どらぐーん",e:"Galaxy Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：このカードは直接攻撃できず、ドラゴン族モンスターにのみ攻撃できる。\n②：このカードがドラゴン族モンスターと戦闘を行う場合、バトルフェイズの間だけそのモンスターの効果は無効化され、ダメージステップの間だけこのカードの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄大百足",h:"へる・せんちぴーど",e:"Hundred-Footed Horror",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2600,de:1300,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１３００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラブラドライドラゴン",h:"らぶらどらいどらごん",e:"Labradorite Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:0,de:2400,tx:"ラブラドレッセンスと呼ばれる特有の美しい輝きを放つウロコを持ったドラゴン。\n\nそのウロコから生まれる眩い輝きは、見た者の魂を導き、感情を解放させる力を持つ。\n\n――その光は前世の記憶を辿り、人々を巡り合わせると伝えられる。",li:"",lk:[],ta:[],al:[]},
  {n:"チャウチャウちゃん",h:"ちゃうちゃうちゃん",e:"Chow Chow Chan",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"相手モンスターと戦闘を行う自分のモンスターの攻撃宣言時に相手が通常罠カードを発動した時、このカードを手札から捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マリスボラス・スプーン",h:"まりすぼらす・すぷーん",e:"Malicevorous Spoon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:500,tx:"このカードがフィールド上に表側表示で存在する場合に「マリスボラス・スプーン」以外の「マリスボラス」と名のついたモンスターが自分フィールド上に召喚・特殊召喚された時、自分の墓地から悪魔族・レベル２モンスター１体を選択して特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「マリスボラス・スプーン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マリスボラス・フォーク",h:"まりすぼらす・ふぉーく",e:"Malicevorous Fork",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"自分のメインフェイズ時、手札からこのカード以外の悪魔族モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マリスボラス・ナイフ",h:"まりすぼらす・ないふ",e:"Malicevorous Knife",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"このカードが召喚に成功した時、自分の墓地から「マリスボラス・ナイフ」以外の「マリスボラス」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"BK リベージ・ガードナー",h:"ばーにんぐなっくらー　りべーじ・がーどなー",e:"Battlin' Boxer Rib Gardna",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:1400,tx:"手札または墓地のこのカードをゲームから除外し、自分フィールド上の「BK」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"BK ラビット・パンチャー",h:"ばーにんぐなっくらー　らびっと・ぱんちゃー",e:"Battlin' Boxer Rabbit Puncher",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカードが守備表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗躍のドルイド・ウィド",h:"あんやくのどるいど・うぃど",e:"Secret Sect Druid Wid",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードがフィールド上から墓地へ送られた場合、自分の墓地の永続魔法カード１枚を選択して自分フィールド上にセットできる。\nこの効果でセットしたカードはこのターン発動できない。\n「暗躍のドルイド・ウィド」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"暗躍のドルイド・ドリュース",h:"あんやくのどるいど・どりゅーす",e:"Secret Sect Druid Dru",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードが召喚に成功した時、自分の墓地から「暗躍のドルイド・ドリュース」以外の攻撃力または守備力が０の闇属性・レベル４モンスター１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「暗躍のドルイド・ドリュース」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻木龍",h:"げんぼくりゅう",e:"Mythic Tree Dragon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:100,de:1400,tx:"１ターンに１度、自分フィールド上のドラゴン族・水属性モンスター１体を選択して発動できる。\nこのカードのレベルは選択したモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻水龍",h:"げんすいりゅう",e:"Mythic Water Dragon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分フィールド上に地属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法による「幻水龍」の特殊召喚は１ターンに１度しかできない。",li:"",lk:[],ta:[],al:[]},
  {n:"武装神竜プロテクト・ドラゴン",h:"ぶそうしんりゅうぷろてくと・どらごん",e:"Armed Protector Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2800,tx:"①：このカードの攻撃力は、このカードに装備された装備カードの数×５００アップする。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの表側表示の装備カードは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"魂食神龍ドレイン・ドラゴン",h:"こんじきしんりゅうどれいん・どらごん",e:"Soul Drain Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n自分のドラゴン族エクシーズモンスターの効果でのみ特殊召喚できる。\nこのカードが特殊召喚に成功した時、自分のライフポイントが相手より少ない場合、このカードの攻撃力はその差の数値分アップし、このターン相手プレイヤーが受ける全てのダメージは０になる。\nまた、このカードは相手プレイヤーに直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"子狸ぽんぽこ",h:"こだぬきぽんぽこ",e:"Baby Raccoon Ponpoko",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカードが召喚に成功した時、デッキから「子狸ぽんぽこ」以外の獣族・レベル２モンスター１体を裏側守備表示で特殊召喚できる。\nこの効果を発動するターン、自分は獣族以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"子狸たんたん",h:"こだぬきたんたん",e:"Baby Raccoon Tantan",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:800,tx:"リバース：デッキから「子狸たんたん」以外の獣族・レベル２モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ランタン",h:"ごーすとりっく・らんたん",e:"Ghostrick Lantern",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、相手モンスターの直接攻撃宣言時、または自分フィールド上の「ゴーストリック」と名のついたモンスターが攻撃対象に選択された時に発動できる。\nその攻撃を無効にし、このカードを手札から裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・スペクター",h:"ごーすとりっく・すぺくたー",e:"Ghostrick Specter",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、「ゴーストリック」と名のついたモンスターが、相手のカードの効果または相手モンスターの攻撃によって破壊され自分の墓地へ送られた時に発動できる。\nこのカードを手札から裏側守備表示で特殊召喚し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリックの魔女",h:"ごーすとりっくのまじょ",e:"Ghostrick Witch",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリックの雪女",h:"ごーすとりっくのゆきおんな",e:"Ghostrick Yuki-onna",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードが戦闘によって破壊され墓地へ送られた時に発動できる。\nこのカードを破壊したモンスターは裏側守備表示になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・キョンシー",h:"ごーすとりっく・きょんしー",e:"Ghostrick Jiangshi",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:400,de:1800,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがリバースした時、自分フィールド上の「ゴーストリック」と名のついたモンスターの数以下のレベルを持つ、「ゴーストリック」と名のついたモンスター１体をデッキから手札に加える事ができる。\n「ゴーストリック・キョンシー」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・シュタイン",h:"ごーすとりっく・しゅたいん",e:"Ghostrick Stein",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードが相手ライフに戦闘ダメージを与えた時、デッキから「ゴーストリック」と名のついた魔法・罠カード１枚を手札に加える事ができる。\n「ゴーストリック・シュタイン」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神－ミカヅチ",h:"ぶじん－みかづち",e:"Bujin Mikazuchi",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1500,tx:"自分フィールド上の「武神」と名のついた獣戦士族モンスターが戦闘またはカードの効果によって破壊され墓地へ送られた時、このカードを手札から特殊召喚できる。\nまた、このカードがフィールド上に表側表示で存在し、自分の手札から「武神」と名のついたモンスターが自分の墓地へ送られた場合、そのターンのエンドフェイズ時に１度、デッキから「武神」と名のついた魔法・罠カード１枚を手札に加える事ができる。\n「武神－ミカヅチ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ヤタ",h:"ぶじんぎ－やた",e:"Bujingi Crow",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"自分フィールド上の「武神」と名のついた獣戦士族モンスターが相手モンスターの攻撃対象に選択された時、このカードを手札から墓地へ送って発動できる。\n攻撃モンスターの攻撃を無効にし、そのモンスターの攻撃力の半分のダメージを相手ライフに与える。\n「武神器－ヤタ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－オハバリ",h:"ぶじんぎ－おはばり",e:"Bujingi Ibis",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"自分のメインフェイズ１で、このカードを手札から墓地へ送り、自分フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－イクタ",h:"ぶじんぎ－いくた",e:"Bujingi Boar",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"自分のメインフェイズ時、自分フィールド上に「武神」と名のついたモンスターが存在する場合、墓地のこのカードをゲームから除外し、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側守備表示にし、その守備力をエンドフェイズ時まで０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ハチ",h:"ぶじんぎ－はち",e:"Bujingi Centipede",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"自分のメインフェイズ時、自分フィールド上に「武神」と名のついた獣戦士族モンスターが存在する場合、墓地のこのカードをゲームから除外して発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。\n「武神器－ハチ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機サーバルホーク",h:"げんじゅうきさーばるほーく",e:"Mecha Phantom Beast Sabre Hawk",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:100,tx:"このカードは直接攻撃できない。\n自分の墓地に「幻獣機」と名のついたモンスター以外のモンスターが存在する場合、このカードは攻撃できない。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\n自分または相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機グリーフィン",h:"げんじゅうきぐりーふぃん",e:"Mecha Phantom Beast Kalgriffin",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2500,tx:"自分のメインフェイズ時、自分フィールド上の「幻獣機」と名のついたモンスター２体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n「幻獣機グリーフィン」のこの効果は１ターンに１度しか使用できない。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、手札の「幻獣機」と名のついたモンスター１体を捨てて発動できる。\n「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・ソーサラー",h:"う゛ぁんぱいあ・そーさらー",e:"Vampire Sorcerer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードが相手によって墓地へ送られた場合に発動できる。\nデッキから闇属性の「ヴァンパイア」モンスター１体または「ヴァンパイア」魔法・罠カード１枚を手札に加える。\n②：墓地のこのカードを除外して発動できる。\nこのターンに１度だけ、自分はレベル５以上の闇属性の「ヴァンパイア」モンスターを召喚する場合に必要なリリースをなくす事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドウ・ヴァンパイア",h:"しゃどう・う゛ぁんぱいあ",e:"Shadow Vampire",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードをX召喚の素材とする場合、闇属性モンスターのX召喚にしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札・デッキから「シャドウ・ヴァンパイア」以外の闇属性の「ヴァンパイア」モンスター１体を特殊召喚する。\nこの効果で特殊召喚に成功したターン、そのモンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・グレイス",h:"う゛ぁんぱいあ・ぐれいす",e:"Vampire Grace",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"このカードが墓地に存在し、アンデット族モンスターの効果によって自分フィールド上にレベル５以上のアンデット族モンスターが特殊召喚された時、２０００ライフポイントを払って発動できる。\nこのカードを墓地から特殊召喚する。\n「ヴァンパイア・グレイス」のこの効果は１ターンに１度しか使用できない。\nまた、１ターンに１度、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手は宣言された種類のカード１枚をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴースト姫－パンプリンセス－",h:"ごーすとひめ－ぱんぷりんせす－",e:"Pumprincess the Princess of Ghosts",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:900,de:1000,tx:"このカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。\nこの効果で永続魔法カード扱いになっている場合、お互いのスタンバイフェイズ毎にこのカードにパンプキンカウンターを１つ置く。\n相手フィールド上の全てのモンスターの攻撃力・守備力は、永続魔法カード扱いのこのカードに乗っているパンプキンカウンターの数×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"黄血鬼",h:"おうけつき",e:"Yellow-Bellied Oni",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分がエクシーズ召喚に成功した時、このカードを手札から特殊召喚できる。\nまた、１ターンに１度、自分フィールド上のエクシーズ素材を１つ取り除き、フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターのランクを１つ下げ、攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・キラー",h:"う゛ぁんぱいあ・きらー",e:"Vampire Hunter",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが闇属性モンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"荒魂",h:"あらたま",e:"Aratama",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["スピリット","効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースした時に発動できる。\nデッキから「荒魂」以外のスピリットモンスター１体を手札に加える。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"羅刹",h:"らせつ",e:"Rasetsu",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1500,de:1900,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、「羅刹」以外の手札のスピリットモンスター１体を相手に見せて発動できる。\n相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して持ち主の手札に戻す。\nこの効果を発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュラル・ボーン・サウルス",h:"なちゅらる・ぼーん・さうるす",e:"Skelesaurus",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["デュアル","効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの種族・属性は恐竜族・地属性になる。\n\n●このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの種族はアンデット族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"騎士デイ・グレファー",h:"ないとでい・ぐれふぁー",e:"Knight Day Grepher",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1700,de:1600,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分エンドフェイズに、自分の墓地の装備魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェノミックス・ファイター",h:"じぇのみっくす・ふぁいたー",e:"Genomix Fighter",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:2200,de:1100,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードのレベルは３になり、元々の攻撃力は半分になる。\nまた、１ターンに１度、種族を１つ宣言して発動できる。\nこのターンこのカードをシンクロ素材とする場合、このカードを含むそのシンクロ素材モンスター一組を宣言した種族として扱う。\nこの効果を発動したターン、自分は宣言した種族以外のモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"姫葵マリーナ",h:"ひまりまりーな",e:"MariÑa, Princess of Sunflowers",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2800,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、このカード以外の自分フィールド上の植物族モンスター１体が戦闘またはカードの効果によって破壊され墓地へ送られた場合、相手フィールド上のカード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"剛地帝グランマーグ",h:"ごうちていぐらんまーぐ",e:"Granmarg the Mega Monarch",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\nこのカードがアドバンス召喚に成功した時、フィールド上にセットされたカードを２枚まで選択して破壊する。\nこのカードが地属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"カラスの巨群",h:"からすのきょぐん",e:"Swarm of Crows",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードはデッキから特殊召喚できない。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"土地鋸",h:"つちのこ",e:"Terrene Toothed Tsuchinoko",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカードが特殊召喚に成功した時、このカード以外のフィールド上の特殊召喚されたモンスターを全て裏側守備表示にする。\n「土地鋸」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"覚星師ライズベルト",h:"かくせいしらいずべると",e:"Risebell the Star Psycher",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルー・ダストン",h:"ぶるー・だすとん",e:"Blue Duston",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーの手札をランダムに１枚選び、次のターンのスタンバイフェイズ時まで裏側表示でゲームから除外する。\n「ブルー・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.96 ブラック・ストーム",h:"かおすなんばーず９６ ぶらっく・すとーむ",e:"Number C96: Dark Storm",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"闇属性レベル３モンスター×４\n①：このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは相手も受ける。\n②：このカードが「No.96 ブラック・ミスト」をX素材としている場合、以下の効果を得る。\n●このカードが相手モンスターと戦闘を行う攻撃宣言時に１度、このカードのX素材を１つ取り除いて発動できる。\nその相手モンスターの攻撃力を０にし、このカードの攻撃力はその相手モンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.65 裁断魔人ジャッジ・バスター",h:"なんばーず６５ さいだんまじんじゃっじ・ばすたー",e:"Number 65: Djinn Buster",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1300,de:0,tx:"闇属性レベル２モンスター×２\n①：相手がモンスターの効果を発動した時、このカードのX素材を２つ取り除いて発動できる。\nその発動を無効にし、相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.65 裁断魔王ジャッジ・デビル",h:"かおすなんばーず６５ さいだんまおうじゃっじ・でびる",e:"Number C65: King Overfiend",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:0,tx:"闇属性レベル３モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は１０００ダウンする。\n②：このカードが「No.65 裁断魔人ジャッジ・バスター」をX素材としている場合、以下の効果を得る。\n●このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BK チート・コミッショナー",h:"ばーにんぐなっくらー　ちーと・こみっしょなー",e:"Battlin' Boxer Cheat Commissioner",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:1300,tx:"レベル３モンスター×２\nこのカードがフィールド上に表側表示で存在する限り、攻撃可能な相手モンスターは攻撃しなければならない。\nまた、自分フィールド上にこのカード以外の「BK」と名のついたモンスターが存在する場合、相手はこのカードを攻撃対象にできない。\nこのカード以外の自分の「BK」と名のついたモンスターが戦闘を行う攻撃宣言時、このカードのエクシーズ素材を２つ取り除いて発動できる。\n相手の手札を確認し、その中から魔法カード１枚を選んで自分フィールド上にセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.46 神影龍ドラッグルーオン",h:"なんばーず４６ しんえいりゅうどらっぐるーおん",e:"Number 46: Dragluon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"ドラゴン族レベル８モンスター×２\n①：１ターンに１度、自分フィールドに他のモンスターが存在しない場合、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n●手札からドラゴン族モンスター１体を特殊召喚する。\n●相手フィールドのドラゴン族モンスター１体を対象として発動できる。\nそのドラゴン族モンスターのコントロールを得る。\n●相手ターン終了時まで、相手フィールドのドラゴン族モンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.64 古狸三太夫",h:"なんばーず６４ ふるだぬきさんだゆう",e:"Number 64: Ronin Raccoon Sandayu",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"獣族レベル２モンスター×２\n①：自分フィールドに他の獣族モンスターが存在する限り、このカードは戦闘・効果では破壊されない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドに「影武者狸トークン」（獣族・地・星１・攻？／守０）１体を特殊召喚する。\nこのトークンの攻撃力は、フィールドのモンスターの一番高い攻撃力と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・アルカード",h:"ごーすとりっく・あるかーど",e:"Ghostrick Alucard",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1600,tx:"レベル３モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手は他の「ゴーストリック」モンスター及び裏側守備表示モンスターを攻撃対象に選択できない。\n②：このカードのX素材を１つ取り除き、相手フィールドにセットされたカード１枚を対象として発動できる。\nそのセットされた相手のカードを破壊する。\n③：このカードが墓地へ送られた場合、このカード以外の自分の墓地の「ゴーストリック」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"武神帝－カグツチ",h:"ぶじんてい－かぐつち",e:"Bujintei Kagutsuchi",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"獣戦士族レベル４モンスター×２\nこのカードがエクシーズ召喚に成功した時、自分のデッキの上からカードを５枚墓地へ送る。\nこのカードの攻撃力は、この効果で墓地へ送った「武神」と名のついたカードの数×１００ポイントアップする。\nまた、自分フィールド上の「武神」と名のついた獣戦士族モンスターが戦闘またはカードの効果によって破壊される場合、その破壊されるモンスター１体の代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\n「武神帝－カグツチ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"紅貴士－ヴァンパイア・ブラム",h:"えーでるりったー－う゛ぁんぱいあ・ぶらむ",e:"Crimson Knight Vampire Bram",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:0,tx:"アンデット族レベル５モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚に成功したターン、そのモンスター以外の自分のモンスターは攻撃できない。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた次のターンのスタンバイフェイズに発動する。\nこのカードを墓地から守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メリアスの木霊",h:"めりあすのこだま",e:"Meliae of the Trees",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1700,de:900,tx:"地属性レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n\n●デッキから植物族モンスター１体を墓地へ送る。\n\n●自分の墓地から植物族モンスター１体を選択して表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神竜騎士フェルグラント",h:"しんりゅうきしふぇるぐらんと",e:"Divine Dragon Knight Felgrand",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:1800,tx:"レベル８モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、対象のモンスターは効果が無効になり、このカード以外の効果を受けない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"焔紫竜ピュラリス",h:"ほむらりゅうぴゅらりす",e:"Puralis, the Purple Pyrotile",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:800,de:1400,tx:"チューナー＋チューナー以外のモンスター１体\n①：このカードがフィールドから墓地へ送られた場合に発動する。\n相手フィールドの全てのモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"鬼岩城",h:"きがんじょう",e:"Giganticastle",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"岩石族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードの攻撃力・守備力は、このカードのシンクロ素材としたチューナー以外のモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガタッグ",h:"がががたっぐ",e:"Gagagatag",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の全ての「ガガガ」と名のついたモンスターの攻撃力は、次の自分のスタンバイフェイズ時まで、自分フィールド上の「ガガガ」と名のついたモンスターの数×５００ポイントアップする。\n「ガガガタッグ」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"バーニングナックル・スピリッツ",h:"ばーにんぐなっくる・すぴりっつ",e:"Battlin' Boxing Spirits",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキの一番上のカードを墓地へ送って発動できる。\n自分の墓地の「BK」と名のついたモンスター１体を選択して表側守備表示で特殊召喚する。\n「バーニングナックル・スピリッツ」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・シールド",h:"どらごん・しーるど",e:"Dragon Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"ドラゴン族モンスターにのみ装備可能。\n①：装備モンスターは戦闘・効果では破壊されない。\n装備モンスターの戦闘で発生するお互いのプレイヤーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ハウス",h:"ごーすとりっく・はうす",e:"Ghostrick Mansion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのフィールド上のモンスターは、裏側守備表示のモンスターに攻撃できず、相手フィールド上のモンスターが裏側守備表示のモンスターのみの場合、相手プレイヤーに直接攻撃できる。\nまた、このカードがフィールド上に存在する限り、お互いのプレイヤーが受ける効果ダメージ及び、「ゴーストリック」と名のついたモンスター以外のモンスターがプレイヤーに与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"鏡鳴する武神",h:"きょうめいするぶじん",e:"Bujin Regalia - The Mirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「武神」と名のついた獣戦士族モンスターが存在し、相手フィールド上のモンスターの数が自分フィールド上のモンスターの数より多い場合、メインフェイズ１の開始時に発動できる。\n次の相手ターン終了時まで、お互いに魔法・罠カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア帝国",h:"う゛ぁんぱいあえんぱいあ",e:"Vampire Kingdom",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上のアンデット族モンスターの攻撃力はダメージ計算時のみ５００ポイントアップする。\nまた、１ターンに１度、相手のデッキからカードが墓地へ送られた時、自分の手札・デッキから「ヴァンパイア」と名のついた闇属性モンスター１体を墓地へ送り、フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"貪欲で無欲な壺",h:"どんよくでむよくなつぼ",e:"Pot of Dichotomy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１の開始時に自分の墓地から異なる種族のモンスター３体を選択して発動できる。\n選択したモンスター３体をデッキに加えてシャッフルする。\nその後、デッキからカードを２枚ドローする。\nこのカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"旗鼓堂々",h:"きこどうどう",e:"Swords at Dawn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地の装備魔法カード１枚と、その正しい対象となるフィールド上のモンスター１体を選択して発動できる。\n選択した装備魔法カードを選択したモンスターに装備する。\nこの効果で装備した装備魔法カードはエンドフェイズ時に破壊される。\nこのカードを発動したターン、自分はモンスターを特殊召喚できない。\n「旗鼓堂々」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"帝王の開岩",h:"ていおうのかいがん",e:"Return of the Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「帝王の開岩」の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：自分が表側表示でモンスターのアドバンス召喚に成功した時、以下の効果から１つを選択して発動できる。\n\n●そのモンスターとカード名が異なる攻撃力２４００／守備力１０００のモンスター１体をデッキから手札に加える。\n\n●そのモンスターとカード名が異なる攻撃力２８００／守備力１０００のモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖蛇の息吹",h:"せいじゃのいぶき",e:"Sacred Serpent's Wake",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に儀式・融合・シンクロ・エクシーズモンスターの内２種類以上が存在する場合、そのモンスターの種類によって、以下の効果から１つ以上選んで発動できる。\n「聖蛇の息吹」は１ターンに１枚しか発動できない。\n\n●２種類以上：自分の墓地のモンスター１体またはゲームから除外されている自分のモンスター１体を選択して手札に加える。\n\n●３種類以上：自分の墓地の罠カード１枚を選択して手札に加える。\n\n●４種類：「聖蛇の息吹」以外の自分の墓地の魔法カード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダストンのモップ",h:"だすとんのもっぷ",e:"Magicalized Duston Mop",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが相手のカードの効果によって破壊され墓地へ送られた時、デッキから「ダストン」と名のついたモンスター１体を手札に加える事ができる。\n「ダストンのモップ」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"バースト・リバース",h:"ばーすと・りばーす",e:"Burst Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：２０００LPを払い、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナンバーズ・オーバーレイ・ブースト",h:"なんばーず・おーばーれい・ぶーすと",e:"Numbers Overlay Boost",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズ素材の無い「No.」と名のついたエクシーズモンスター１体を選択して発動できる。\n自分の手札のモンスター２体を、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"陰謀の盾",h:"いんぼうのたて",e:"Intrigue Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上のモンスター１体に装備する。\n装備モンスターは表側攻撃表示で存在する限り、１ターンに１度だけ戦闘では破壊されない。\nまた、装備モンスターの戦闘によって発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・アウト",h:"ごーすとりっく・あうと",e:"Ghostrick Vanish",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札の「ゴーストリック」と名のついたモンスター１体を相手に見せて発動できる。\nこのターン、自分フィールド上の「ゴーストリック」と名のついたカード及び裏側守備表示で存在するモンスターはカードの効果の対象にならず、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・パニック",h:"ごーすとりっく・ぱにっく",e:"Ghostrick Scare",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に裏側守備表示で存在するモンスターを任意の数だけ選択して発動できる。\n選択したモンスターを表側守備表示にし、その中の「ゴーストリック」と名のついたモンスターの数まで、相手フィールド上に表側表示で存在するモンスターを選んで裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・シフト",h:"う゛ぁんぱいあ・しふと",e:"Vampire Takeover",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のフィールドカードゾーンにカードが存在せず、自分フィールド上に表側表示で存在するモンスターがアンデット族モンスターのみの場合に発動できる。\nデッキから「ヴァンパイア帝国」１枚を選んで発動する。\nその後、自分の墓地から「ヴァンパイア」と名のついた闇属性モンスター１体を選んで表側守備表示で特殊召喚できる。\n「ヴァンパイア・シフト」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"手違い",h:"みすていく",e:"Mistake",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いにドロー以外の方法でデッキからカードを手札に加える事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖誘爆",h:"ちぇーん・いぐにっしょん",e:"Chain Ignition",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在し、相手フィールド上のモンスターが相手のカードの効果によって破壊された場合、フィールド上のカード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"グリザイユの牢獄",h:"ぐりざいゆのろうごく",e:"Grisaille Prison",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にアドバンス召喚・儀式召喚・融合召喚したモンスターの内、いずれかが表側表示で存在する場合に発動できる。\n次の相手ターンの終了時まで、お互いにシンクロ・エクシーズ召喚は行えず、フィールド上のシンクロ・エクシーズモンスターは効果が無効化され、攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"生存競争",h:"せいぞんきょうそう",e:"Survival of the Fittest",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの恐竜族モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力１０００アップの装備カード扱いとして、その自分の恐竜族モンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが攻撃で相手モンスターを破壊し墓地へ送った時に発動できる。\n装備モンスターは相手モンスターにもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"大金星！？",h:"だいきんぼし！？",e:"BIG Win!?",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"１から１２までの任意のレベルを宣言して発動できる。\nお互いのプレイヤーはそれぞれコイントスを１回行い、どちらも表が出た場合、自分フィールド上に表側表示で存在する全てのモンスターのレベルは宣言したレベルになる。\nどちらも裏が出た場合、自分は宣言したレベルの数×５００ライフポイントを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシーサーペント",h:"ぎゃらくしーさーぺんと",e:"Galaxy Serpent",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:1000,de:0,tx:"宵闇に紛れて姿を現わすと言われるドラゴン。\n星の海を泳ぐように飛ぶ神秘的な姿からその名が付けられた。\nその姿を見た者は数えるほどしかないと伝えられるが、見た者は新たな力を得られるという。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士トリスタン",h:"せいきしとりすたん",e:"Noble Knight Drystan",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"「聖騎士トリスタン」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにこのカード以外の「聖騎士」モンスターが存在する限り、相手はこのカード以外の自分フィールドの攻撃力１８００未満のモンスターを攻撃対象にできず、効果の対象にもできない。\n②：このカードが「聖剣」装備魔法カードを装備した場合、フィールドの表側表示のカード１枚を対象として発動する。\nその表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－チカヘシ",h:"ぶじんぎ－ちかへし",e:"Bujingi Warg",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1900,tx:"①：このカードがモンスターゾーンに守備表示で存在する限り、このカード以外の自分フィールドの「武神」モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機エアロスバード",h:"げんじゅうきえあろすばーど",e:"Mecha Phantom Beast Aerosguin",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"①：１ターンに１度、「幻獣機エアロスバード」以外の自分の墓地の「幻獣機」モンスター１体を除外して発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n②：このカードのレベルは自分フィールドの「幻獣機トークン」のレベルの合計分だけ上がる。\n③：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"こけコッコ",h:"こけこっこ",e:"Cockadoodledoo",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:2000,tx:"①：お互いのフィールドにモンスターが存在しない場合、このカードはレベル３モンスターとして手札から特殊召喚できる。\n②：相手フィールドにモンスターが存在し、自分フィールドにカードが存在しない場合、このカードはレベル４モンスターとして手札から特殊召喚できる。\n③：表側表示のこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界発冥界行きバス",h:"まかいはつめいかいゆきばす",e:"Tour Bus To Forbidden Realms",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:600,de:1000,tx:"①：このカードがリバースした場合に発動する。\nデッキから光・闇属性以外の悪魔族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"対峙するG",h:"たいじするじー",e:"Confronting the \"C\"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1100,de:2500,tx:"①：相手がEXデッキからモンスターを特殊召喚した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードは、このカードを対象とするモンスターの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼラの天使",h:"ぜらのてんし",e:"Angel of Zera",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\n「ゼラの天使」の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は除外されている相手のカードの数×１００アップする。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに発動する。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・オーバーディレイ",h:"えくしーず・おーばーでぃれい",e:"Xyz Encore",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動に対して魔法・罠・モンスターの効果は発動できない。\n①：相手フィールドのX素材を持っているXモンスター１体を対象として発動できる。\nそのモンスターのX素材を全て取り除き、対象のモンスターを持ち主のEXデッキに戻す。\n取り除いたX素材の中にモンスターカードがあった場合、さらにそのモンスターを墓地から可能な限り相手フィールドに守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは１つ下がる。",li:"",lk:[],ta:[],al:[]},
  {n:"ムーンダンスの儀式",h:"むーんだんすのぎしき",e:"Moon Dance Ritual",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドのX素材の無い風属性Xモンスター１体を対象としてこのカードを発動できる。\nそのモンスターがフィールドに存在する限り、フィールドの表側表示モンスターの効果は無効化される。\n対象のモンスターがフィールドから離れた時にこのカードは破壊される。\n②：このカードを発動したターンのエンドフェイズに発動する。\nフィールドのこのカードを、このカードの発動時に対象としたモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラインモンスター Kホース",h:"らいんもんすたー　けいほーす",e:"Shogi Knight",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカードが召喚に成功した時、相手の魔法＆罠カードゾーンにセットされているカード１枚を選択して発動できる。\nセットされているそのカードを確認し、罠カードだった場合、そのカードを破壊する。\n違った場合、元に戻す。\nこの効果で罠カードを破壊した時、以下の効果を発動できる。\n●手札から地属性・レベル３モンスター１体を表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ソルジャー －宵闇の使者－",h:"かおす・そるじゃー　－よいやみのししゃ－",e:"Black Luster Soldier - Envoy of the Evening Twilight",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地の光属性と闇属性のモンスターの数が同じ場合に、その内のどちらかの属性を全て除外した場合のみ特殊召喚できる。\nこのカードの属性は「光」としても扱う。\nこのカードが特殊召喚に成功した時、特殊召喚するために除外したモンスターの属性により、以下の効果を発動できる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\n●光：フィールド上のモンスター１体を選択して除外する。\n●闇：相手の手札をランダムに１枚選び、相手のエンドフェイズ時まで裏側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"極夜の騎士ガイア",h:"きょくやのきしがいあ",e:"Gaia, the Polar Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「極夜の騎士ガイア」の以下の効果はそれぞれ１ターンに１度ずつ使用できる。\n●このカード以外の自分フィールド上の闇属性モンスター１体をリリースして発動できる。\nデッキから戦士族・光属性・レベル４モンスター１体を手札に加え、その後手札を１枚墓地へ送る。\n●自分の墓地の光属性モンスター１体を除外し、自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は、相手のエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"白夜の騎士ガイア",h:"びゃくやのきしがいあ",e:"Gaia, the Mid-Knight Sun",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「白夜の騎士ガイア」の以下の効果はそれぞれ１ターンに１度ずつ使用できる。\n●このカード以外の自分フィールド上の光属性モンスター１体をリリースして発動できる。\nデッキから戦士族・闇属性・レベル４モンスター１体を手札に加え、その後手札を１枚墓地へ送る。\n●自分の墓地の闇属性モンスター１体を除外し、フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は、相手のエンドフェイズ時まで５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の種",h:"こんとんのたね",e:"Chaos Seed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に、光属性及び闇属性モンスターが存在する場合、除外されている自分の光属性または闇属性の戦士族モンスター１体を選択して発動できる。\n選択したモンスターを手札に加える。\n「混沌の種」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"明と宵の逆転",h:"あけとよいのぎゃくてん",e:"Exchange of Night and Day",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"以下の効果から１つを選択して発動できる。\n「明と宵の逆転」の効果は１ターンに１度しか使用できない。\n\n●手札から戦士族・光属性モンスター１体を墓地へ送る。\nその後、そのモンスターと同じレベルの戦士族・闇属性モンスター１体をデッキから手札に加える。\n\n●手札から戦士族・闇属性モンスター１体を墓地へ送る。\nその後、そのモンスターと同じレベルの戦士族・光属性モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"勝利の方程式",h:"しょうりのほうていしき",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにのみモンスターが存在する場合、「勝利の方程式は全て揃った！」と宣言して発動できる。\nEXデッキから「CNo.」モンスター以外の「No.」Xモンスター１体を特殊召喚する。\nその後、このカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－死の木馬",h:"ぎみっく・ぱぺっと－です・とろい",e:"Gimmick Puppet Des Troy",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：このカードがフィールドに表側表示で存在する限り１度だけ、フィールドの「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：このカードがフィールドから墓地へ送られた時に発動できる。\n手札から「ギミック・パペット」モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴゴーレム－GF",h:"ごごごごーれむ－ごーるでんふぉーむ",e:"Gogogo Golem - Golden Form",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:1500,tx:"このカードは通常召喚できない。\n自分フィールド上の「ゴゴゴ」と名のついたモンスター１体をリリースした場合のみ特殊召喚できる。\nこのカードの攻撃力は、リリースしたそのモンスターの元々の攻撃力を倍にした数値になる。\nこのカードの戦闘によって発生する相手への戦闘ダメージは半分になる。\nまた、１ターンに１度、相手フィールド上で効果モンスターの効果が発動した時に発動する。\nこのカードの攻撃力を１５００ポイントダウンし、その効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドウィッチ",h:"どどどうぃっち",e:"Dododo Witch",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から「ドドドウィッチ」以外の「ドドド」モンスター１体を表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドガッサー",h:"どどどがっさー",e:"Dododo Swordsman",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:3000,tx:"反転召喚したこのカードがフィールド上に表側表示で存在する限り、このカードの攻撃力は３５００ポイントアップする。\nまた、このカードがリバースした時、フィールド上に表側表示で存在するモンスターを２体まで選択して発動できる。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トイナイト",h:"といないと",e:"Toy Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:1200,tx:"このカードはデッキから特殊召喚できない。\n相手フィールド上のモンスターの数が自分フィールド上のモンスターの数より多い場合、このカードは手札から特殊召喚できる。\nこのカードが召喚・特殊召喚に成功した時、手札から「トイナイト」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"バク団",h:"ばくだん",e:"Explossum",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分のメインフェイズ時、手札または自分フィールド上のこのモンスターを、装備カード扱いとして相手フィールド上のエクシーズモンスター１体に装備できる。\nこの効果でこのカードを装備したモンスターにエクシーズ素材が無い場合、そのモンスターを破壊する。\nまた、このカードが装備カード扱いとして装備されている場合、相手のスタンバイフェイズ毎に装備モンスターのエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"護封剣の剣士",h:"ごふうけんのけんし",e:"Swordsman of Revealing Light",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:2400,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、特殊召喚したこのカードの守備力がその攻撃モンスターの攻撃力より高い場合、その攻撃モンスターを破壊する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n\n●このカードは１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"トラブル・ダイバー",h:"とらぶる・だいばー",e:"Doggy Diver",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"相手フィールド上にモンスターが存在し、自分フィールド上に表側表示で存在するモンスターがレベル４モンスターのみの場合、このカードは手札から特殊召喚できる。\nこの方法による「トラブル・ダイバー」の特殊召喚は１ターンに１度しかできない。\nこのカードをエクシーズ召喚の素材とする場合、戦士族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レベル・マイスター",h:"れべる・まいすたー",e:"Level Lifter",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札のモンスター１体を墓地へ送り、自分フィールド上に表側表示で存在するモンスターを２体まで選択して発動できる。\n選択したモンスターのレベルはエンドフェイズ時まで、このカードを発動するために墓地へ送ったモンスターの元々のレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴ護符",h:"ごごごごふ",e:"Gogogo Talisman",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ゴゴゴ」と名のついたモンスターが２体以上存在する場合、自分が受ける効果ダメージは０になる。\nまた、１ターンに１度、自分フィールド上の「ゴゴゴ」と名のついたモンスターが戦闘を行う攻撃宣言時に発動できる。\nその自分のモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーフ・アンブレイク",h:"はーふ・あんぶれいく",e:"Half Unbreak",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上のモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは戦闘では破壊されず、そのモンスターの戦闘によって発生する自分への戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の一撃",h:"たましいのいちげき",e:"Soul Strike",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが４０００以下の場合、自分フィールド上のモンスターが相手モンスターと戦闘を行う攻撃宣言時にライフポイントを半分払い、自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は相手のエンドフェイズ時まで、自分のライフポイントが４０００より下回っている数値分アップする。\n「魂の一撃」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"琰魔竜 レッド・デーモン",h:"えんまりゅう　れっど・でーもん",e:"Hot Red Dragon Archfiend",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ１でのみ発動できる。\nこのカード以外のフィールド上に表側攻撃表示で存在するモンスターを全て破壊する。\nこの効果を発動するターン、このカード以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.72 ラインモンスター チャリオッツ・飛車",h:"なんばーず７２ らいんもんすたー ちゃりおっつ・ひしゃ",e:"Number 72: Shogi Rook",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"レベル６モンスター×２\n①：１ターンに１度、このカードのX素材を２つ取り除き、相手フィールドの表側表示モンスター１体と相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果の発動後、ターン終了時まで相手が受ける戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－極星神馬聖鎧",h:"ぜある・うぇぽん－すれいぷにーる・めいる",e:"ZW - Sleipnir Mail",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：「ZW－極星神馬聖鎧」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１０００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：装備モンスターが相手によって破壊された事でこのカードが墓地へ送られた時、自分の墓地の「希望皇ホープ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"悠久の黄金都市 グランポリス",h:"ゆうきゅうのおうごんとし　ぐらんぽりす",e:"Grandopolis, The Eternal Golden City",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:3500,tx:"レベル８モンスター×３\nこのカードはX召喚でしか特殊召喚できない。\n機械族モンスターをX素材として持っているこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"E☆HERO 盤面の執行者",h:"えんたーていんめんと・ひーろー　ぴっと・ぼす",e:"E☆HERO Pit Boss",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル６モンスター×３\nこのカードはX召喚でしか特殊召喚できない。\n魔法使い族モンスターをX素材として持っているこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"No.48 シャドー・リッチ",h:"なんばーず４８ しゃどー・りっち",e:"Number 48: Shadow Lich",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:0,tx:"レベル３モンスター×２\n①：相手ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドに「幻影トークン」（悪魔族・闇・星１・攻／守５００）１体を特殊召喚する。\n②：このカードの攻撃力は、自分フィールドの「幻影トークン」の数×５００アップする。\n③：自分フィールドに「幻影トークン」が存在する限り、相手はこのカードを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"天穹覇龍ドラゴアセンション",h:"てんきゅうはりゅうどらごあせんしょん",e:"Ascension Sky Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:"?",de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、このカードの攻撃力は自分の手札の数×８００ポイントアップする。\nフィールド上のこのカードが相手によって破壊され墓地へ送られた時、このカードのシンクロ召喚に使用したシンクロ素材モンスター一組が自分の墓地に揃っていれば、その一組を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「天穹覇龍ドラゴアセンション」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブルフィン・シャーク",h:"だぶるふぃん・しゃーく",e:"Double Fin Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードが召喚に成功した時、自分の墓地からレベル３またはレベル４の魚族・水属性モンスター１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果を発動するターン、自分は水属性以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレント・アングラー",h:"さいれんと・あんぐらー",e:"Silent Angler",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"①：自分フィールドに水属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したターン、自分は手札からモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"デプス・シャーク",h:"でぷす・しゃーく",e:"Depth Shark",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n相手のスタンバイフェイズ時に１度、このカードの攻撃力はエンドフェイズ時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイバー・シャーク",h:"せいばー・しゃーく",e:"Saber Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードはシンクロ素材にできない。\n自分のメインフェイズ時に、フィールド上の魚族モンスター１体を選択し、以下の効果から１つを選択して発動できる。\nこの効果は１ターンに２度まで使用できる。\nこの効果を発動するターン、自分は水属性以外のモンスターを特殊召喚できない。\n\n●選択したモンスターのレベルを１つ上げる。\n\n●選択したモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガード・ペンギン",h:"がーど・ぺんぎん",e:"Guard Penguin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:1200,tx:"カードの効果によって自分がダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.73 激瀧神アビス・スプラッシュ",h:"なんばーず７３ げきろうしんあびす・すぷらっしゅ",e:"Number 73: Abyss Splash",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1400,tx:"水属性レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力は相手ターン終了時まで倍になる。\nこの効果の発動後、ターン終了時までこのカードが相手に与える戦闘ダメージは半分になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.94 極氷姫クリスタル・ゼロ",h:"なんばーず９４ きょくひょうきくりすたる・ぜろ",e:"Number 94: Crystalzero",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1600,tx:"水属性レベル５モンスター×２\n①：このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を自分ターンの終了時まで半分にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"FA－クリスタル・ゼロ・ランサー",h:"ふるあーまーど－くりすたる・ぜろ・らんさー",e:"Full Armored Crystalzero Lancer",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1600,tx:"水属性レベル６モンスター×３\nこのカードは自分フィールド上の水属性・ランク５のエクシーズモンスターの上にこのカードを重ねてエクシーズ召喚する事もできる。\nこのカードの攻撃力は、このカードのエクシーズ素材の数×５００ポイントアップする。\nフィールド上のこのカードが破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上の全てのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"FA－ブラック・レイ・ランサー",h:"ふるあーまーど－ぶらっく・れい・らんさー",e:"Full Armored Black Ray Lancer",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:600,tx:"水属性レベル４モンスター×３\nこのカードは自分フィールドのX素材の無いランク３の水属性Xモンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力は、このカードのX素材の数×２００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを破壊する。\n③：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を全て取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"水神の護符",h:"すいじんのごふ",e:"Sea Lord's Amulet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上の水属性モンスターは相手のカードの効果では破壊されない。\n発動後３回目の相手のエンドフェイズ時にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイヤモンド・ダスト",h:"だいやもんど・だすと",e:"Diamond Dust",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の水属性モンスターを全て破壊する。\nその後、この効果で破壊され墓地へ送られた水属性モンスターの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[]},
  {n:"除雪機関車ハッスル・ラッセル",h:"じょせつきかんしゃはっする・らっせる",e:"Snow Plow Hustle Rustle",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:3000,tx:"①：自分の魔法＆罠ゾーンにカードが存在する場合、相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分の魔法＆罠ゾーンのカードを全て破壊し、破壊した数×２００ダメージを相手に与える。\n②：このカードがモンスターゾーンに存在する限り、自分は機械族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"深夜急行騎士ナイト・エクスプレス・ナイト",h:"しんやきゅうこうきしないと・えくすぷれす・ないと",e:"Night Express Knight",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードはデッキから特殊召喚できない。\n①：このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚したこのカードの元々の攻撃力は０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"臨時ダイヤ",h:"りんじだいや",e:"Special Schedule",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の攻撃力３０００以上の機械族モンスター１体を対象として発動できる。\nその機械族モンスターを守備表示で特殊召喚する。\n②：セットされたこのカードが墓地へ送られた場合、自分の墓地の機械族・レベル１０モンスター１体を対象として発動できる。\nその機械族モンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"無頼特急バトレイン",h:"ぶらいとっきゅうばとれいん",e:"Ruffian Railcar",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n相手に５００ダメージを与える。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\n②：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから機械族・地属性・レベル１０モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"蒼炎の剣士",h:"そうえんのけんし",e:"Blue Flame Swordsman",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：１ターンに１度、自分・相手のバトルフェイズに、このカード以外の自分フィールドの戦士族モンスター１体を対象として発動できる。\nこのカードの攻撃力を６００ダウンし、対象のモンスターの攻撃力を６００アップする。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた時、墓地のこのカードを除外し、自分の墓地の戦士族・炎属性モンスター１体を対象として発動できる。\nその戦士族・炎属性モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ －鳳凰の陣－",h:"はーぴぃ・れでぃ　－ほうおうのじん－",e:"Harpie Lady Phoenix Formation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はデッキ・EXデッキからモンスターを特殊召喚できず、バトルフェイズを行えない。\n①：自分フィールドに「ハーピィ・レディ」「ハーピィ・レディ三姉妹」が合計３体以上存在する場合、その数まで可能な限り相手フィールドのモンスターを対象として発動できる。\n対象のモンスターを破壊し、破壊したモンスターの内、元々の攻撃力が一番高いモンスターのその数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・コア",h:"さいばー・どらごん・こあ",e:"Cyber Dragon Core",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:1500,tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：このカードが召喚に成功した場合に発動する。\nデッキから「サイバー」魔法・罠カードまたは「サイバネティック」魔法・罠カード１枚を手札に加える。\n③：相手フィールドにのみモンスターが存在する場合、墓地のこのカードを除外して発動できる。\nデッキから「サイバー・ドラゴン」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・ドライ",h:"さいばー・どらごん・どらい",e:"Cyber Dragon Drei",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：このカードが召喚に成功した時に発動できる。\n自分フィールドの全ての「サイバー・ドラゴン」のレベルを５にする。\nこの効果を発動するターン、自分は機械族モンスターしか特殊召喚できない。\n③：このカードが除外された場合、自分フィールドの「サイバー・ドラゴン」１体を対象として発動できる。\nこのターン、そのモンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・リペア・プラント",h:"さいばー・りぺあ・ぷらんと",e:"Cyber Repair Plant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地に「サイバー・ドラゴン」が存在する場合、以下の効果から１つを選択して発動できる。\nこのカードの発動時に自分の墓地に「サイバー・ドラゴン」が３体以上存在する場合、両方を選択できる。\n\n●デッキから機械族・光属性モンスター１体を手札に加える。\n\n●自分の墓地の機械族・光属性モンスター１体を対象として発動できる。\nその機械族・光属性モンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ネットワーク",h:"さいばー・ねっとわーく",e:"Cyber Network",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後、３回目の自分スタンバイフェイズに破壊される。\n①：１ターンに１度、フィールドに「サイバー・ドラゴン」が存在する場合に発動できる。\nデッキから機械族・光属性モンスター１体を除外する。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\n除外されている自分の機械族・光属性モンスターを可能な限り特殊召喚し、自分フィールドの魔法・罠カードを全て破壊する。\nこの効果で特殊召喚したモンスターは効果を発動できない。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・ノヴァ",h:"さいばー・どらごん・のう゛ぁ",e:"Cyber Dragon Nova",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1600,tx:"機械族レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の「サイバー・ドラゴン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、手札及び自分フィールドの表側表示モンスターの中から、「サイバー・ドラゴン」１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで２１００アップする。\nこの効果は相手ターンでも発動できる。\n③：このカードが相手の効果で墓地へ送られた場合に発動できる。\nEXデッキから機械族の融合モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・グール",h:"ごーすとりっく・ぐーる",e:"Ghostrick Ghoul",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"自分フィールドに「ゴーストリック」モンスターが存在する場合のみ、このカードは表側表示で召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：自分メインフェイズ１に１度、自分フィールドの「ゴーストリック」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は次の相手ターンの終了時まで、フィールドの「ゴーストリック」モンスターの元々の攻撃力を合計した数値になり、このターン対象のモンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－マフツ",h:"ぶじんぎ－まふつ",e:"Bujingi Raven",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"①：自分フィールドの「武神」モンスターが相手モンスターとの戦闘で破壊され自分の墓地へ送られた時、このカードを手札から墓地へ送って発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・デューク",h:"う゛ぁんぱいあ・でゅーく",e:"Vampire Duke",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"「ヴァンパイア・デューク」の②の効果は１ターンに１度しか使用できない。\nこのカードをX召喚の素材とする場合、闇属性モンスターのX召喚にしか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の闇属性の「ヴァンパイア」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが特殊召喚に成功した時、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手は宣言された種類のカード１枚をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの巨神",h:"でーもんのきょしん",e:"Archfiend Giant",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"①：モンスターゾーンのこのカードが効果で破壊される場合、代わりに５００LPを払う事ができる。\nこの効果はこのカードがフィールドに表側表示で存在する限り１度しか使用できない。\n②：このカードが効果で破壊され墓地へ送られた場合に発動できる。\n手札から「デーモン」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"湖の乙女ヴィヴィアン",h:"みずうみのおとめう゛ぃう゛ぃあん",e:"Lady of the Lake",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:1800,tx:"このカードをS素材とする場合、戦士族モンスターのS召喚にしか使用できず、S召喚に使用されたこのカードは除外される。\n①：このカードが召喚に成功した時、自分の墓地の「聖騎士」通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドのレベル５の「聖騎士」モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士ボールス",h:"せいきしぼーるす",e:"Noble Knight Borz",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"①：このカードはモンスターゾーンに存在する限り、通常モンスターとして扱う。\n②：このカードが「聖剣」装備魔法カードを装備している限り、このカードは効果モンスター扱いとなり以下の効果を得る。\n●このカードはレベルが１つ上がり闇属性になる。\n●自分メインフェイズに発動できる。\nデッキから「聖剣」カード３枚を相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りを墓地へ送る。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔聖騎士皇ランスロット",h:"ませいきしおうらんすろっと",e:"Ignoble Knight of High Laundsallyn",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:900,tx:"チューナー＋チューナー以外の「聖騎士」モンスター１体以上\n①：「魔聖騎士皇ランスロット」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードがS召喚に成功した時に発動できる。\nデッキから「聖剣」装備魔法カード１枚を選んでこのカードに装備する。\n③：このカードが戦闘でモンスターを破壊し墓地へ送ったバトルフェイズ終了時に発動できる。\nデッキから「聖騎士」カードまたは「聖剣」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"神聖騎士王アルトリウス",h:"しんせいきしおうあるとりうす",e:"Sacred Noble Knight of King Artorigus",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"レベル５「聖騎士」モンスター×２\n①：このカードがX召喚に成功した時、自分の墓地の「聖剣」装備魔法カードを３種類まで対象として発動できる。\n対象のカードをこのカードに装備する。\n②：１ターンに１度、このカードのX素材を１つ取り除き、このカード以外のフィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：このカードがフィールドから墓地へ送られた場合、自分の墓地のレベル４以上の「聖騎士」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖剣 EX－カリバーン",h:"せいけん　えくす－かりばーん",e:"Noble Arms - Excaliburn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「聖騎士」モンスターにのみ装備可能。\n「聖剣 EX－カリバーン」の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターは相手の効果の対象にならない。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの「聖騎士」Xモンスター１体を対象として発動できる。\nその自分のモンスターとカード名が異なる「聖騎士」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔の憑代",h:"あくまのよりしろ",e:"Sinister Yorishiro",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分はレベル５以上の悪魔族モンスターを召喚する場合に必要なリリースをなくす事ができる。\nこの効果は１ターンに１度しか適用できない。\n②：通常召喚したレベル５以上の悪魔族モンスター１体のみが破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアギア XG",h:"ぎあぎあぎあ　くろすぎがんと",e:"Geargiagear Gigant XG",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1300,tx:"レベル３モンスター×３\n自分フィールド上の機械族モンスターが戦闘を行うバトルステップ時、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上に表側表示で存在するカードの効果はそのダメージステップ終了時まで無効になり、そのダメージステップ終了時まで相手は魔法・罠・効果モンスターの効果を発動できない。\nまた、このカードがフィールド上から離れた時、自分の墓地からこのカード以外の「ギアギア」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアーノ Mk－Ⅲ",h:"ぎあぎあーの　Ｍｋ－Ⅲ",e:"Geargiano Mk-III",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが「ギアギア」と名のついたカードの効果によって特殊召喚に成功した時、自分の手札・墓地から「ギアギアーノ Mk－Ⅲ」以外の「ギアギア」と名のついたモンスター１体を選んで表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「ギアギアーノ Mk－Ⅲ」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分は「ギアギア」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアタッカー",h:"ぎあぎあたっかー",e:"Geargiattacker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:100,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、このカード以外の自分フィールド上の「ギアギア」と名のついたモンスターの数まで、フィールド上の魔法・罠カードを選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・アーク ミカエル",h:"らいとろーど・あーく　みかえる",e:"Michael, the Arch-Lightsworn",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\n①：１ターンに１度、１０００LPを払い、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\n②：このカードが破壊された時、このカード以外の自分の墓地の「ライトロード」モンスターを任意の数だけ対象として発動できる。\nそのモンスターをデッキに戻し、自分は戻した数×３００LP回復する。\n③：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・メイデン ミネルバ",h:"らいとろーど・めいでん　みねるば",e:"Minerva, Lightsworn Maiden",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の墓地の「ライトロード」モンスターの種類の数以下のレベルを持つドラゴン族・光属性モンスター１体をデッキから手札に加える。\n②：このカードが手札・デッキから墓地へ送られた場合に発動する。\n自分のデッキの上からカードを１枚墓地へ送る。\n③：自分エンドフェイズに発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・アサシン ライデン",h:"らいとろーど・あさしん　らいでん",e:"Raiden, Hand of the Lightsworn",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のデッキの上からカードを２枚墓地へ送る。\nこの効果で墓地へ送ったカードの中に「ライトロード」モンスターがあった場合、さらにこのカードの攻撃力は相手ターン終了時まで２００アップする。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ホワイト・ダストン",h:"ほわいと・だすとん",e:"White Duston",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"ちっちゃな悪魔、ダストンズの白いヤツ。\n自身でも驚きの白さである事をホコリに思っているらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－阿修羅副腕",h:"ぜある・うぇぽん－あしゅら・ぶろー",e:"ZW - Asura Strike",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：「ZW－阿修羅副腕」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１０００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：このカードが装備されている場合、装備モンスターは相手フィールドの全てのモンスターに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギラギランサー",h:"ぎらぎらんさー",e:"Gillagillancer",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、自分はエンドフェイズ毎に５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"虹クリボー",h:"にじくりぼー",e:"Rainbow Kuriboh",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\nこのカードを手札から装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは攻撃できない。\n②：このカードが墓地に存在する場合、相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーレイ・スナイパー",h:"おーばーれい・すないぱー",e:"Overlay Sentinel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは特殊召喚できない。\nこのカードは召喚に成功した時、守備表示になる。\nまた、自分フィールド上にエクシーズ素材を持ったエクシーズモンスターが存在する場合、墓地のこのカードをゲームから除外し、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は、自分フィールド上のエクシーズ素材の数×５００ポイントダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーレイ・ブースター",h:"おーばーれい・ぶーすたー",e:"Overlay Booster",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"自分フィールド上に攻撃力２０００以上のモンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。\nこの方法による「オーバーレイ・ブースター」の特殊召喚は１ターンに１度しかできない。\nまた、墓地のこのカードをゲームから除外し、自分フィールド上のエクシーズ素材を持ったエクシーズモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は、そのモンスターのエクシーズ素材の数×５００ポイントアップする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・チャージマン",h:"ふぉとん・ちゃーじまん",e:"Photon Chargeman",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードの攻撃力は、次の自分のスタンバイフェイズ時まで元々の攻撃力の倍になる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産モアイキャリア",h:"おーぱーつもあいきゃりあ",e:"Chronomaly Moai Carrier",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:900,de:1800,tx:"相手フィールド上にカードが存在し、自分フィールド上にカードが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産ウィングス・スフィンクス",h:"おーぱーつうぃんぐす・すふぃんくす",e:"Chronomaly Winged Sphinx",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:1900,tx:"このカードが召喚に成功した時、自分の墓地から「先史遺産」と名のついたレベル５モンスター１体を選択して特殊召喚できる。\nこの効果を発動するターン、自分は「先史遺産」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ディープ・スペース・クルーザー・ナイン",h:"でぃーぷ・すぺーす・くるーざー・ないん",e:"Deep-Space Cruiser IX",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"このカードは手札からこのカード以外の機械族モンスター１体を墓地へ送り、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴルゴニック・ゴーレム",h:"ごるごにっく・ごーれむ",e:"Gorgonic Golem",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、このカードを破壊したモンスターの攻撃力は０になる。\nまた、自分のメインフェイズ時、墓地のこのカードをゲームから除外し、相手フィールド上にセットされた魔法・罠カード１枚を選択して発動できる。\nこのターン、選択されたカードは発動できない。\nこの効果の発動に対して相手は選択されたカードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴルゴニック・ガーゴイル",h:"ごるごにっく・がーごいる",e:"Gorgonic Gargoyle",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"自分が岩石族モンスターの召喚に成功した時、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴルゴニック・グール",h:"ごるごにっく・ぐーる",e:"Gorgonic Ghoul",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールド上に「ゴルゴニック・グール」が存在する場合、３００ライフポイントを払って発動できる。\nこのカードを手札から特殊召喚する。\n「ゴルゴニック・グール」の効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴルゴニック・ケルベロス",h:"ごるごにっく・けるべろす",e:"Gorgonic Cerberus",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:300,tx:"このカードが召喚に成功した時、自分フィールド上の全ての岩石族モンスターのレベルを３にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の実張り ピース",h:"しんらのみはり　ぴーす",e:"Sylvan Peaskeeper",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:100,tx:"このカードが召喚・特殊召喚に成功した時、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分の墓地からレベル４以下の植物族モンスター１体を選択して特殊召喚できる。\n「森羅の実張り ピース」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の影胞子 ストール",h:"しんらのかげぼうし　すとーる",e:"Sylvan Komushroomo",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカードがリバースした時、自分のデッキの上からカードを５枚までめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、フィールド上の魔法・罠カード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の水先 リーフ",h:"しんらのみずさき　りーふ",e:"Sylvan Marshalleaf",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが召喚に成功した時、自分のデッキの上からカードを２枚までめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、フィールド上のモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の花卉士 ナルサス",h:"しんらのはなきし　なるさす",e:"Sylvan Flowerknight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが召喚に成功した時、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、デッキから「森羅」と名のついたカード１枚を選んでデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の番人 オーク",h:"しんらのばんにん　おーく",e:"Sylvan Guardioak",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分のデッキの上からカードを３枚までめくる。\nめくったカードの中に植物族モンスターがあった場合、それらのカードを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分の墓地からこのカード以外の植物族モンスター１体を選択してデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の仙樹 レギア",h:"しんらのせんじゅ　れぎあ",e:"Sylvan Hermitree",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2700,de:1800,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送り、デッキからカードを１枚ドローする。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分のデッキの上からカードを３枚まで確認し、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・フロスト",h:"ごーすとりっく・ふろすと",e:"Ghostrick Jackfrost",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、相手モンスターの直接攻撃宣言時に発動できる。\nその相手モンスターを裏側守備表示にし、このカードを手札から裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・マリー",h:"ごーすとりっく・まりー",e:"Ghostrick Mary",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:1600,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、戦闘またはカードの効果によって自分がダメージを受けた時、このカードを手札から捨てて発動できる。\nデッキから「ゴーストリック」と名のついたモンスター１体を裏側守備表示で特殊召喚する。\n「ゴーストリック・マリー」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリックの猫娘",h:"ごーすとりっくのねこむすめ",e:"Ghostrick Nekomusume",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:900,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、フィールド上にこのカード以外の「ゴーストリック」と名のついたモンスターが存在する限り、レベル４以上のモンスターが召喚・特殊召喚に成功した時、そのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・スケルトン",h:"ごーすとりっく・すけるとん",e:"Ghostrick Skeleton",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがリバースした時、自分フィールド上の「ゴーストリック」と名のついたモンスターの数まで、相手のデッキの上からカードを裏側表示で除外する。\n「ゴーストリック・スケルトン」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・マミー",h:"ごーすとりっく・まみー",e:"Ghostrick Mummy",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがフィールド上に表側表示で存在する限り、自分は通常召喚に加えて１度だけ「ゴーストリック」と名のついたモンスター１体を召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、自分は闇属性以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神－アラスダ",h:"ぶじん－あらすだ",e:"Bujin Arasuda",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1900,tx:"自分のフィールド上・墓地の「武神」と名のついたモンスターがゲームから除外された場合、このカードを手札から表側守備表示で特殊召喚できる。\nまた、このカードがフィールド上に表側表示で存在し、「武神」と名のついたカードがドロー以外の方法で自分のデッキから手札に加わった場合、そのターンのエンドフェイズ時に１度だけ発動できる。\nデッキからカードを１枚ドローし、その後手札を１枚選んで捨てる。\n「武神－アラスダ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ヤサカニ",h:"ぶじんぎ－やさかに",e:"Bujingi Peacock",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"自分のメインフェイズ２で、このカードを手札から墓地へ送って発動できる。\nデッキから「武神」と名のついたモンスター１体を手札に加える。\nこの効果を発動するターン、自分は「武神」と名のついたカード以外の魔法・罠・効果モンスターの効果を発動できない。\n「武神器－ヤサカニ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ヤツカ",h:"ぶじんぎ－やつか",e:"Bujingi Swallow",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"自分のメインフェイズ１で、このカードを手札から墓地へ送り、自分フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。\nこの効果を発動するターン、選択したモンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－オキツ",h:"ぶじんぎ－おきつ",e:"Bujingi Fox",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:200,de:2100,tx:"墓地のこのカードをゲームから除外し、手札から「武神」と名のついたモンスター１体を墓地へ送って発動できる。\nこのターン、自分が受ける全てのダメージを０にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－サグサ",h:"ぶじんぎ－さぐさ",e:"Bujingi Hare",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"墓地のこのカードをゲームから除外し、自分フィールド上の「武神」と名のついた獣戦士族モンスター１体を選択して発動できる。\n選択したモンスターはこのターンに１度だけ戦闘及びカードの効果では破壊されない。\nこの効果は相手ターンでも発動できる。\n「武神器－サグサ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の使徒",h:"はかもりのしと",e:"Gravekeeper's Nobleman",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、デッキから「墓守の使徒」以外の「墓守」と名のついたモンスター１体を裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の伏兵",h:"はかもりのふくへい",e:"Gravekeeper's Ambusher",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードがリバースした時、相手の墓地のカード１枚を選択してデッキの一番下に戻す事ができる。\nまた、リバースしたこのカードが墓地へ送られた場合、自分の墓地の「ネクロバレー」と名のついたカード１枚を選択して手札に加える事ができる。\nこのカードの効果は「王家の眠る谷－ネクロバレー」の効果では無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の祈祷師",h:"はかもりのきとうし",e:"Gravekeeper's Shaman",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードの守備力は、自分の墓地の「墓守」と名のついたモンスターの数×２００ポイントアップする。\nこのカードがフィールド上に表側表示で存在する限り、「墓守」と名のついたモンスター以外の、墓地で発動する効果モンスターの効果は無効化される。\nまた、「王家の眠る谷－ネクロバレー」がフィールド上に存在する場合、相手はフィールド魔法カードを発動できず、フィールド魔法カードは相手の効果によっては破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の審神者",h:"はかもりのさにわ",e:"Gravekeeper's Oracle",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードはモンスター３体または「墓守」モンスター１体をリリースして表側表示でアドバンス召喚する事もできる。\n①：このカードがアドバンス召喚に成功した時、このカードのアドバンス召喚のためにリリースした「墓守」モンスターの数まで、以下の効果から選択して発動できる。\n●このカードの攻撃力は、このカードのアドバンス召喚のためにリリースしたモンスターのレベルの合計×１００アップする。\n●相手フィールドの裏側表示モンスターを全て破壊する。\n●相手フィールドの全てのモンスターの攻撃力・守備力は２０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻妖種ミトラ",h:"げんようしゅみとら",e:"Mystic Macrocarpa Seed",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1000,tx:"このカードをシンクロ素材とする場合、地属性モンスターのシンクロ召喚にしか使用できない。\n自分のメインフェイズ時、フィールド上の地属性モンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ下げる。\n「幻妖種ミトラ」の効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"森の聖獣 カラントーサ",h:"もりのせいじゅう　からんとーさ",e:"Kalantosa, Mystical Beast of the Forest",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:200,de:1400,tx:"①：このカードが獣族モンスターの効果で特殊召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"和魂",h:"にぎたま",e:"Nikitama",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースしたターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにスピリットモンスター１体を召喚できる。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。\n③：このカードが墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。\nこの効果は自分フィールドにスピリットモンスターが存在する場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒ブラキ",h:"ぶらっくぶらき",e:"Black Brachios",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1100,tx:"①：このカードが召喚に成功した時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"紅姫チルビメ",h:"あきちるびめ",e:"Chirubimé, Princess of Autumn Leaves",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:2800,tx:"このカードがフィールド上に表側表示で存在する限り、相手は他の植物族モンスターを攻撃対象に選択できない。\nまた、このカードが相手によって墓地へ送られた場合、デッキから「紅姫チルビメ」以外の植物族モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"凍氷帝メビウス",h:"とうひょうていめびうす",e:"Mobius the Mega Monarch",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\nこのカードがアドバンス召喚に成功した時、フィールド上の魔法・罠カードを３枚まで選択して破壊できる。\nこのカードが水属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●この効果の発動に対して相手は選択されたカードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霊水鳥シレーヌ・オルカ",h:"れいすいちょうしれーぬ・おるか",e:"Sirenorca",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"自分フィールド上に魚族及び鳥獣族モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、３から５までの任意のレベルを宣言して発動できる。\n自分フィールド上の全てのモンスターのレベルは宣言したレベルになる。\nこの効果を発動したターン、水属性以外の自分のモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・アヴェンジャー",h:"えくしーず・あう゛ぇんじゃー",e:"Xyz Avenger",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"このカードはエクシーズモンスターの効果を受けない。\nまた、このカードがエクシーズモンスターとの戦闘によって破壊され墓地へ送られた時、このカードを破壊したエクシーズモンスターのランクによって、以下の効果を発動する。\n\n●ランク３以下：相手はエクストラデッキのカード１枚を選んで墓地へ送る。\n\n●ランク４：自分は相手のエクストラデッキのカード１枚を選んで墓地へ送る。\n\n●ランク５以上：相手はそのエクシーズモンスターのランクの数だけ、エクストラデッキのカードを選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"タックルセイダー",h:"たっくるせいだー",e:"Tackle Crusader",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"このカードが墓地へ送られた場合、以下の効果から１つを選択して発動できる。\n\n●相手フィールド上に表側表示で存在するモンスター１体を選択して裏側守備表示にする。\n\n●相手フィールド上に表側表示で存在する魔法・罠カード１枚を選択して持ち主の手札に戻す。\nこのターン、相手はこの効果で手札に戻したカード及びその同名カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アドバンス・ディボーター",h:"あどばんす・でぃぼーたー",e:"Majiosheldon",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードはこのカードの効果でしか特殊召喚できない。\nこのカードをリリースしてアドバンス召喚に成功した場合、次の自分のスタンバイフェイズ時に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果を発動するターン、自分はエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"光子竜の聖騎士",h:"ないと・おぶ・ふぉとんどらごん",e:"Paladin of Photon Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:800,tx:"「光子竜降臨」により降臨。\nこのカードをリリースして発動できる。\n手札・デッキから「銀河眼の光子竜」１体を特殊召喚する。\nまた、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.101 S・H・Dark Knight",h:"かおすなんばーず１０１　さいれんと・おなーず・だーく・ないと",e:"Number C101: Silent Honor DARK",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:1500,tx:"レベル５モンスター×３\n①：１ターンに１度、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n②：X素材を持っているこのカードが破壊され墓地へ送られた時に発動できる。\nこのカードを特殊召喚する。\nその後、このカードの元々の攻撃力分だけ自分のLPを回復する。\nこの効果で特殊召喚したこのカードはこのターン攻撃できない。\nこの効果は自分の墓地に「No.101 S・H・Ark Knight」が存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.101 S・H・Ark Knight",h:"なんばーず１０１　さいれんと・おなーず・あーく・ないと",e:"Number 101: Silent Honor ARK",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1000,tx:"レベル４モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を２つ取り除き、相手フィールドの特殊召喚された表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.39 希望皇ホープ・ルーツ",h:"なんばーず３９ きぼうおうほーぷ・るーつ",e:"Number 39: Utopia Roots",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:100,tx:"レベル１モンスター×２\n①：自分または相手モンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\nその攻撃を無効にし、そのモンスターがXモンスターだった場合、このカードの攻撃力はそのモンスターのランク×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.69 紋章死神カオス・オブ・アームズ",h:"かおすなんばーず６９ です・めだりおんかおす・おぶ・あーむず",e:"Number C69: Heraldry Crest of Horror",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:1800,tx:"レベル５モンスター×４\n①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドのカードを全て破壊する。\n②：このカードが「No.69 紋章神コート・オブ・アームズ」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのXモンスター１体を対象として発動できる。\nエンドフェイズまで、このカードの攻撃力はそのモンスターの元々の攻撃力分アップし、このカードはそのモンスターの元々のカード名・効果と同じカード名・効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.92 偽骸虚龍 Heart－eartH Chaos Dragon",h:"かおすなんばーず９２ ぎがいきょりゅう はーとあーす・かおす・どらごん",e:"Number C92: Heart-eartH Chaos Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:0,tx:"レベル１０モンスター×４\n①：このカードは戦闘では破壊されない。\n②：自分のモンスターが相手に戦闘ダメージを与えた場合に発動する。\nその数値分だけ自分のLPを回復する。\n③：このカードが「No.92 偽骸神龍 Heart－eartH Dragon」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの全ての表側表示のカードの効果はターン終了時まで無効化される。\nこの効果の発動と効果は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴルゴニック・ガーディアン",h:"ごるごにっく・がーでぃあん",e:"Gorgonic Guardian",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:1200,tx:"岩石族レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\nターン終了時まで、選択したモンスターの攻撃力を０にし、その効果を無効にする。\nこの効果は相手ターンでも発動できる。\nまた、１ターンに１度、フィールド上の攻撃力が０のモンスター１体を選択して発動できる。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の守神 アルセイ",h:"しんらのもりがみ　あるせい",e:"Alsei, the Sylvan High Protector",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:3200,tx:"レベル８モンスター×２\n１ターンに１度、カード名を１つ宣言して発動できる。\n自分のデッキの一番上のカードをめくり、宣言したカードだった場合、手札に加える。\n違った場合、めくったカードを墓地へ送る。\nまた、カードの効果によって自分のデッキからカードが墓地へ送られた場合、このカードのエクシーズ素材を１つ取り除いて発動できる。\nフィールド上のカード１枚を選択して持ち主のデッキの一番上または一番下に戻す。\n「森羅の守神 アルセイ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・デュラハン",h:"ごーすとりっく・でゅらはん",e:"Ghostrick Dullahan",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:0,tx:"レベル１モンスター×２\n①：このカードの攻撃力は、自分フィールドの「ゴーストリック」カードの数×２００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力をターン終了時まで半分にする。\nこの効果は相手ターンでも発動できる。\n③：このカードが墓地へ送られた場合、このカード以外の自分の墓地の「ゴーストリック」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"武神帝－ツクヨミ",h:"ぶじんてい－つくよみ",e:"Bujintei Tsukuyomi",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2300,tx:"光属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n手札を全て墓地へ送り、デッキからカードを２枚ドローする。\nまた、このカードが相手のカードの効果によってフィールド上から離れた時、その時にこのカードが持っていたエクシーズ素材の数まで、自分の墓地からレベル４の「武神」と名のついた獣戦士族モンスターを選択して特殊召喚できる。\n「武神帝－ツクヨミ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精騎士イングナル",h:"ようせいきしいんぐなる",e:"Fairy Knight Ingunar",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:3000,tx:"レベル６モンスター×３\n①：１ターンに１度、このカードのX素材を２つ取り除いて発動できる。\nこのカード以外のフィールドのカードを全て持ち主の手札に戻す。\nこの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"励輝士 ヴェルズビュート",h:"れいきし　う゛ぇるずびゅーと",e:"Evilswarm Exciton Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:0,tx:"レベル４モンスター×２\n①：自分メインフェイズまたは相手バトルフェイズに、相手の手札・フィールドのカードを合計した数が自分の手札・フィールドのカードを合計した数より多い場合、このカードのX素材を１つ取り除いて発動できる。\nこのカード以外のフィールドのカードを全て破壊する。\nこの効果の発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダウナード・マジシャン",h:"だうなーど・まじしゃん",e:"Downerd Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:200,tx:"魔法使い族レベル４モンスター×２\nこのカードは自分メインフェイズ２に、自分フィールドのランク３以下のXモンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力は、このカードのX素材の数×２００アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが戦闘を行ったダメージ計算後に発動する。\nこのカードのX素材を１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"神樹の守護獣－牙王",h:"しんじゅのしゅごじゅう－がおう",e:"Leo, the Keeper of the Sacred Tree",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:3100,de:1900,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードは、自分のメインフェイズ２以外では相手のカードの効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－アストラル・フォース",h:"らんくあっぷまじっく－あすとらる・ふぉーす",e:"Rank-Up-Magic Astral Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのランクが一番高いXモンスター１体を対象として発動できる。\nその自分のモンスターと同じ種族・属性でランクが２つ高いモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\n②：このカードが墓地に存在する場合、自分ドローフェイズのドロー前に発動できる。\nこのカードを手札に加える。\nこの効果を発動するターン、自分は通常のドローを行えず、「RUM－アストラル・フォース」の効果以外ではモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RDM－ヌメロン・フォール",h:"らんくだうんまじっく－ぬめろん・ふぉーる",e:"Rank-Down-Magic Numeron Fall",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「希望皇ホープ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターよりランクが低い「希望皇ホープ」と名のついたモンスター１体を、選択した自分のモンスターの上に重ねてエクシーズ召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果でエクシーズ召喚したモンスターは以下の効果を得る。\n●このカードが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・シフト",h:"えくしーず・しふと",e:"Xyz Shift",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のエクシーズモンスター１体をリリースして発動できる。\nリリースしたモンスターと同じ種族・属性・ランクでカード名が異なるモンスター１体をエクストラデッキから特殊召喚し、このカードを下に重ねてエクシーズ素材とする。\nこの効果で特殊召喚したモンスターは、エンドフェイズ時に墓地へ送られる。\n「エクシーズ・シフト」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"光子竜降臨",h:"こうしりゅうこうりん",e:"Luminous Dragon Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「光子竜の聖騎士」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が４になるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外して発動できる。\nレベルの合計が４になるように自分の墓地のモンスターをゲームから除外し、手札から「光子竜の聖騎士」１体を儀式召喚扱いとして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の霊峰",h:"しんらのれいほう",e:"Mount Sylvania",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分のメインフェイズ時に手札または自分フィールド上に表側表示で存在する、植物族モンスター１体を墓地へ送って発動できる。\nデッキから「森羅」と名のついたカード１枚を選んでデッキの一番上に置く。\n「森羅の霊峰」のこの効果は１ターンに１度しか使用できない。\nまた、相手のエンドフェイズ時に１度だけ、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのカードを墓地へ送る。\n違った場合、そのカードをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ミュージアム",h:"ごーすとりっく・みゅーじあむ",e:"Ghostrick Museum",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、「ゴーストリック」と名のついたモンスター以外の自分フィールド上のモンスターは攻撃できず、お互いのフィールド上のモンスターは、裏側守備表示のモンスターに攻撃できず、相手フィールド上のモンスターが裏側守備表示のモンスターのみの場合、相手プレイヤーに直接攻撃できる。\nまた、プレイヤーに戦闘ダメージを与えたモンスターは、ダメージステップ終了時に裏側守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神集結",h:"ぶじんしゅうけつ",e:"Bujinunity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にこのカード以外のカードが存在しない場合に発動できる。\n自分の墓地の「武神」と名のついた獣戦士族モンスターを全てデッキに戻し、自分の手札を全て墓地へ送る。\nその後、デッキからカード名の異なる「武神」と名のついた獣戦士族モンスターを３体まで手札に加える事ができる。\n「武神集結」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロバレーの祭殿",h:"ねくろばれーのさいでん",e:"Hidden Temples of Necrovalley",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に「墓守」と名のついたモンスター及び「王家の眠る谷－ネクロバレー」が存在する場合に発動できる。\nこのカードがフィールド上に存在する限り、お互いに「墓守」と名のついたモンスター以外のモンスターを特殊召喚できない。\n「墓守」と名のついたモンスター及び「王家の眠る谷－ネクロバレー」がフィールド上に存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"オノマト連携",h:"おのまとぺあ",e:"Onomatopaira",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚墓地へ送って発動できる。\nデッキから以下のモンスターの内１体ずつ、合計２体までを手札に加える。\n\n●「ズババ」モンスター\n●「ガガガ」モンスター\n●「ゴゴゴ」モンスター\n●「ドドド」モンスター",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・オーバーライド",h:"えくしーず・おーばーらいど",e:"Xyz Override",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上のエクシーズモンスターがそのエクシーズ素材を取り除いて効果を発動する場合、取り除くエクシーズ素材１つの代わりに手札１枚を裏側表示でゲームから除外できる。\nこの効果はお互いに１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"実力伯仲",h:"じつりょくはくちゅう",e:"Stand-Off",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分及び相手フィールド上に表側攻撃表示で存在する効果モンスターを１体ずつ選択して発動できる。\n選択した２体のモンスターの効果を無効にする。\nその後、選択した２体のモンスターがフィールド上に表側攻撃表示で存在する限り、選択したモンスターは戦闘では破壊されず、このカード以外のカードの効果を受けず、攻撃と表示形式の変更もできない。",li:"",lk:[],ta:[],al:[]},
  {n:"相乗り",h:"あいのり",e:"Shared Ride",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このターン、相手がドロー以外の方法でデッキ・墓地からカードを手札に加える度に、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"リリース・リバース・バースト",h:"りりーす・りばーす・ばーすと",e:"Release, Reverse, Burst",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「希望皇ホープ」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"パージ・レイ",h:"ぱーじ・れい",e:"Purge Ray",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズモンスター１体をリリースして発動できる。\nこのターンのエンドフェイズ時、リリースしたモンスターと同じ種族でランクが１つ低い「No.」と名のついたモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の恵み",h:"しんらのめぐみ",e:"Sylvan Blessing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚選んで持ち主のデッキの一番上または一番下に戻し、自分の手札・墓地から「森羅」と名のついたモンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、このカード以外のカードの効果を受けず、エンドフェイズ時に持ち主のデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ロールシフト",h:"ごーすとりっく・ろーるしふと",e:"Ghostrick-Go-Round",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、バトルフェイズ中に以下の効果から１つを選択して発動できる。\n\n●自分フィールド上の「ゴーストリック」と名のついたモンスター１体を選択して裏側守備表示にし、相手フィールド上に裏側守備表示で存在するモンスター１体を選んで表側攻撃表示にする。\n\n●自分フィールド上に裏側守備表示で存在するモンスター１体を選択して表側攻撃表示にし、それが「ゴーストリック」と名のついたモンスターだった場合、相手フィールド上に表側表示で存在するモンスター１体を選んで裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"璽律する武神",h:"じりつするぶじん",e:"Bujin Regalia - The Jewel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上のランク４のエクシーズモンスター１体に装備する。\n装備モンスターの攻撃力は、そのモンスターのエクシーズ素材の数×３００ポイントアップする。\nまた、この効果によってこのカードが装備されている場合、１ターンに１度、手札の「武神」と名のついたモンスター１体を装備モンスターの下に重ねてエクシーズ素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロバレーの王墓",h:"ねくろばれーのおうぼ",e:"Imperial Tombs of Necrovalley",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上に「墓守」と名のついたモンスター及び「王家の眠る谷－ネクロバレー」が存在する場合に発動できる。\n効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。\n「ネクロバレーの王墓」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"帝王の凍志",h:"ていおうのとうし",e:"The Monarchs Awaken",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のエクストラデッキにカードが存在しない場合、自分フィールドのアドバンス召喚した表側表示モンスター１体を対象として発動できる。\nそのモンスターは効果が無効になり、このカード以外の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"スキル・プリズナー",h:"すきる・ぷりずなー",e:"Skill Prisoner",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のカード１枚を選択して発動できる。\nこのターン、選択したカードを対象として発動したモンスター効果を無効にする。\nまた、墓地のこのカードをゲームから除外し、自分フィールド上のカード１枚を選択して発動できる。\nこのターン、選択したカードを対象として発動したモンスター効果を無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"戦友の誓い",h:"とものちかい",e:"Oath of Companionship",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エクストラデッキから特殊召喚されたモンスターが自分フィールド上に存在しない場合、相手フィールド上に表側表示で存在する、エクストラデッキから特殊召喚されたモンスター１体を選択して発動できる。\n選択したモンスターのコントロールをエンドフェイズ時まで得る。\nこのカードを発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダストンローラー",h:"だすとんろーらー",e:"Duston Roller",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上のモンスター１体を選択して発動できる。\nこのターン、選択したモンスターはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nまた、セットされたこのカードが相手のカードの効果によって破壊され墓地へ送られた場合、デッキから「ダストン」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産ネブラ・ディスク",h:"おーぱーつねぶら・でぃすく",e:"Chronomaly Nebra Disk",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「先史遺産ネブラ・ディスク」以外の「オーパーツ」カード１枚を手札に加える。\n②：このカードが墓地に存在し、自分フィールドのモンスターが「先史遺産」モンスターのみの場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果を発動するターン、自分は「オーパーツ」カード以外のカードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.36 先史遺産－超機関フォーク＝ヒューク",h:"なんばーず３６ おーぱーつ－ちょうきかんふぉーく＝ひゅーく",e:"Number 36: Chronomaly Chateau Huyuk",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル４「先史遺産」モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力をターン終了時まで０にする。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドの「先史遺産」モンスター１体をリリースし、元々の攻撃力と異なる攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nその元々の攻撃力と異なる攻撃力を持つ相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紋章獣アンフィスバエナ",h:"もんしょうじゅうあんふぃすばえな",e:"Heraldic Beast Amphisbaena",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"自分のメインフェイズ時、手札からこのカード以外の「紋章獣」と名のついたモンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\nまた、１ターンに１度、手札から「紋章獣」と名のついたモンスター１体を捨てて発動できる。\nこのカードの攻撃力はエンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.18 紋章祖プレイン・コート",h:"なんばーず１８ もんしょうそぷれいん・こーと",e:"Number 18: Heraldry Patriarch",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"レベル４モンスター×２\nこのカードの①の効果は同一チェーン上では１度しか発動できない。\n①：フィールドに同名モンスターが２体以上存在する場合、このカードのX素材を１つ取り除いて発動できる。\nその内の１体を選び、そのモンスター以外のそのモンスターの同名モンスターを全て破壊する。\nこの効果は相手ターンでも発動できる。\n②：相手はこのカードの効果で選んだモンスターの同名モンスターを召喚・反転召喚・特殊召喚できない。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「紋章獣」モンスター２体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"昇華する紋章",h:"おーぐめんと・めだりおん",e:"Augmented Heraldry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上のサイキック族エクシーズモンスターは魔法・罠カードの効果の対象にならない。\nまた、１ターンに１度、自分のメインフェイズ時に手札から「紋章獣」と名のついたモンスター１体を捨てて発動できる。\nデッキから「昇華する紋章」以外の「メダリオン」と名のついた魔法・罠カード１枚を手札に加える。\nこの効果を発動するターン、自分はサイキック族エクシーズモンスター及び「紋章獣」と名のついたモンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"SNo.39 希望皇ホープONE",h:"しゃいにんぐなんばーず３９ きぼうおうほーぷわん",e:"Number S39: Utopia Prime",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2510,de:2000,tx:"光属性レベル４モンスター×３\nこのカードは自分フィールドの「No.39 希望皇ホープ」の上に重ねてX召喚する事もできる。\n①：自分のLPが相手より３０００以上少ない場合、このカードのX素材を３つ取り除き、１０LPになるようにLPを払って発動できる。\n相手フィールドの特殊召喚されたモンスターを全て破壊し除外する。\nその後、この効果で除外したモンスターの数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"SZW－天聖輝狼剣",h:"しゃいにんぐ・ぜある・うぇぽん－ふぇんりる・そーど",e:"SZW - Fenrir Sword",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1100,tx:"このカードが召喚に成功した時、自分フィールド上の装備カード扱いの「ZW」と名のついたモンスター１体を選択して表側守備表示で特殊召喚できる。\nまた、自分のメインフェイズ時、手札のこのカードを装備カード扱いとして自分フィールド上の「希望皇ホープ」と名のついたモンスターに装備できる。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の墓地の「ZW」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴゴラム",h:"ごごごごらむ",e:"Gogogo Goram",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、このカードの表示形式を変更する。\nフィールド上のこのカードが破壊され墓地へ送られた時、デッキから「ゴゴゴ」と名のついたモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドドライバー",h:"どどどどらいばー",e:"Dododo Driver",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカードが「ドドド」と名のついたモンスターの効果によって特殊召喚されたターン、自分フィールド上の「ドドド」と名のついたモンスター１体を選択し、以下の効果から１つを選択して発動できる。\nこの効果は１ターンに２度まで使用できる。\n\n●選択したモンスターのレベルを１つ上げる。\n\n●選択したモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・チェンジ・タクティクス",h:"えくしーず・ちぇんじ・たくてぃくす",e:"Xyz Change Tactics",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「エクシーズ・チェンジ・タクティクス」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドに「希望皇ホープ」モンスターがX召喚された時、５００LPを払って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・フュージョン・サポート",h:"さいばねてぃっく・ふゅーじょん・さぽーと",e:"Cybernetic Fusion Support",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"ライフポイントを半分払って発動できる。\nこのターン、自分が機械族の融合モンスターを融合召喚する場合に１度だけ、その融合モンスターカードによって決められた融合素材モンスターを自分の手札・フィールド上・墓地から選んでゲームから除外し、これらを融合素材にできる。\n「サイバネティック・フュージョン・サポート」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"強化蘇生",h:"きょうかそせい",e:"Powerful Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地のレベル４以下のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nそのモンスターのレベルは１つ上がり、攻撃力・守備力は１００アップする。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の葉心棒 ブレイド",h:"しんらのようじんぼう　ぶれいど",e:"Sylvan Bladefender",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:700,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\n自分のデッキの一番上のカードをめくり、そのカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\n②：デッキのこのカードが効果でめくられて墓地へ送られた場合に発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の蜜柑子 シトラ",h:"しんらのみかんこ　しとら",e:"Sylvan Mikorange",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:1100,tx:"①：フィールドのこのカードが相手によって破壊され墓地へ送られた時に発動できる。\n自分のデッキの一番上のカードをめくり、そのカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\n②：デッキのこのカードが効果でめくられて墓地へ送られた場合に発動する。\n自分フィールドの全ての植物族モンスターの攻撃力・守備力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・イエティ",h:"ごーすとりっく・いえてぃ",e:"Ghostrick Yeti",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:300,de:2000,tx:"自分フィールドに「ゴーストリック」モンスターが存在する場合のみ、このカードは表側表示で召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした時、フィールドの「ゴーストリック」モンスター１体を対象として発動できる。\nそのモンスターはこのターン戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－イオツミ",h:"ぶじんぎ－いおつみ",e:"Bujingi Pavo",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"「武神器－イオツミ」の効果は１ターンに１度しか使用できない。\n①：自分フィールドの獣戦士族の「武神」モンスターが戦闘で破壊され墓地へ送られた時、このカードを手札から墓地へ送って発動できる。\nデッキから「武神」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の異端者",h:"はかもりのいたんしゃ",e:"Gravekeeper's Heretic",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：このカードがモンスターゾーンに存在し、フィールドに「王家の眠る谷－ネクロバレー」が存在する限り、このカードはこのカード以外の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士パーシヴァル",h:"せいきしぱーしう゛ぁる",e:"Noble Knight Peredur",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:300,tx:"①：このカードが「聖剣」装備魔法カードを装備している限り、このカードはレベルが１つ上がり闇属性になる。\n②：「聖剣」装備魔法カードを装備したこのカードが墓地へ送られた場合、自分の墓地の「聖剣」カード１枚を対象として発動する。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖剣を抱く王妃ギネヴィア",h:"せいけんをいだくおうひぎねう゛ぃあ",e:"Gwenhwyfar, Queen of Noble Arms",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"「聖剣を抱く王妃ギネヴィア」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「聖騎士」モンスター１体を対象として発動できる。\n手札・墓地のこのカードを攻撃力３００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：装備モンスターの属性によって以下の効果を得る。\n\n●光：装備モンスターが効果で破壊される場合、代わりにこのカードを破壊できる。\n\n●闇：装備モンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターを破壊する。\nその後このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"甲化鎧骨格",h:"いんぜくとろん・ぱわーど",e:"Powered Inzektron",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\nこのターン、このカードは戦闘・効果では破壊されず、自分が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔獣の懐柔",h:"まじゅうのかいじゅう",e:"Obedience Schooled",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\nカード名が異なるレベル２以下の獣族の効果モンスター３体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。\nこのカードの発動後、ターン終了時まで自分は獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"始源の帝王",h:"しげんのていおう",e:"The First Monarch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（悪魔族・闇・星６・攻１０００／守２４００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果でこのカードが特殊召喚した場合、手札を１枚捨て、属性を１つ宣言して発動できる。\nこのカードは宣言した属性として扱い、このカードと同じ属性のモンスターをアドバンス召喚する場合、２体分のリリースにできる。\n③：このカードの効果で特殊召喚されたこのカードが存在する限り、自分はこのカードと同じ属性のモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フラボット",h:"ふらぼっと",e:"Flowerbot",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：このカードが墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。\nその後、手札を１枚持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンプティ・ダンディ",h:"はんぷてぃ・だんでぃ",e:"Humpty Grumpty",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードが反転召喚に成功した場合に発動する。\nこのカードの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"外神ナイアルラ",h:"がいしんないあるら",e:"Outer Entity Nyarla",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2600,tx:"レベル４モンスター×２\n①：このカードがX召喚に成功した時、手札を任意の枚数捨てて発動できる。\nこのカードのランクは、捨てた枚数分だけ上がる。\n②：１ターンに１度、このカードがX素材を持っている場合に自分の墓地のモンスター１体を対象として発動できる。\nこのカードのX素材を全て取り除き、対象のモンスターをこのカードの下に重ねてX素材とする。\nこのカードの種族・属性は、この効果でX素材としたモンスターの元々の種族・属性と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"牙竜咆哮",h:"がりょうほうこう",e:"Dragoroar",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の地・水・炎・風属性モンスターをそれぞれ１体ずつ除外して発動できる。\nフィールドのカード１枚を選んで持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"プランクスケール",h:"ぷらんくすけーる",e:"Planckton",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン中、以下の効果を適用する。\n\n●お互いのフィールドの全てのランク３以下のXモンスターの攻撃力・守備力は５００アップする。\n\n●お互いのフィールドの全てのランク４以上のXモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアングラー",h:"ぎあぎあんぐらー",e:"Geargiauger",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ギアギアングラー」以外の機械族・地属性・レベル４モンスター１体を手札に加える。\nこのターン、自分は攻撃宣言できず、機械族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・アーチャー フェリス",h:"らいとろーど・あーちゃー　ふぇりす",e:"Felis, Lightsworn Archer",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：このカードがモンスターの効果でデッキから墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n②：このカードをリリースし、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。\nその後、自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"フィッシュボーグ－ドクター",h:"ふぃっしゅぼーぐ－どくたー",e:"Fishborg Doctor",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"「フィッシュボーグ－ドクター」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「フィッシュボーグ」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n②：このカードが墓地に存在し、自分フィールドのモンスターが「フィッシュボーグ」モンスターのみの場合、自分メインフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"サタンクロース",h:"さたんくろーす",e:"Santa Claws",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:2500,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに守備表示で特殊召喚できる。\n②：このカードがこのカードの①の方法で特殊召喚に成功したターンのエンドフェイズに発動できる。\nこの効果を発動したプレイヤーはデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"重装機甲 パンツァードラゴン",h:"じゅうそうきこう　ぱんつぁーどらごん",e:"Panzer Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:2600,tx:"機械族モンスター＋ドラゴン族モンスター\n①：このカードが破壊され墓地へ送られた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"浮鵺城",h:"ふやじょう",e:"Cloudcastle",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時、自分の墓地のレベル９モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、召喚・特殊召喚されたレベル８以下のモンスターは、そのターンには攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"巡死神リーパー",h:"ぴるぐりむりーぱー",e:"Pilgrim Reaper",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル６モンスター×２\n「巡死神リーパー」の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力は、お互いの墓地の闇属性モンスターの数×２００アップする。\n②：このカードのX素材を１つ取り除いて発動できる。\nお互いのデッキの上からカードを５枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産ゴルディアス・ユナイト",h:"おーぱーつごるでぃあす・ゆないと",e:"Chronomaly Gordian Knot",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:300,de:900,tx:"このカードが召喚に成功した時、手札から「先史遺産」と名のついたモンスター１体を特殊召喚できる。\nこのカードのレベルはこの効果で特殊召喚したモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ハンプティ・ダンプティ",h:"ぎみっく・ぱぺっと－はんぷてぃ・だんぷてぃ",e:"Gimmick Puppet Humpty Dumpty",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から「ギミック・パペット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－シャドーフィーラー",h:"ぎみっく・ぱぺっと－しゃどーふぃーらー",e:"Gimmick Puppet Shadow Feeler",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは戦闘では破壊されない。\nまた、このカードが墓地に存在し、相手モンスターの直接攻撃によって自分が戦闘ダメージを受けた時に発動できる。\nこのカードを墓地から表側攻撃表示で特殊召喚し、自分は１０００ポイントダメージを受ける。\n「ギミック・パペット－シャドーフィーラー」のこの効果は１ターンに１度しか使用できない。\nエクシーズ素材となったこのカードが墓地へ送られる場合、墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレント・ウォビー",h:"さいれんと・うぉびー",e:"Silent Wobby",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分のメインフェイズ時に発動できる。\nこのカードを手札から相手フィールド上に特殊召喚する。\nこの効果で特殊召喚に成功した時、このカードのコントローラーはデッキからカードを１枚ドローし、このカードのコントローラーから見て相手プレイヤーは２０００ライフポイント回復する。\n「サイレント・ウォビー」のこの効果は１ターンに１度しか使用できない。\nまた、このカードがフィールド上に表側表示で存在する限り、このカードのコントローラーの手札制限枚数は３枚になる。",li:"",lk:[],ta:[],al:[]},
  {n:"俊足なカバ バリキテリウム",h:"しゅんそくなかば　ばりきてりうむ",e:"Dynatherium",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合に発動する。\n相手は、自分または相手の墓地からレベル４モンスター１体を選び、自身のフィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔王龍 ベエルゼ",h:"まおうりゅう　べえるぜ",e:"Beelze of the Diabolic Dragons",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"闇属性チューナー＋チューナー以外のモンスター１体以上\nこのカードは戦闘及びカードの効果では破壊されない。\nまた、このカードの戦闘または相手のカードの効果によって自分がダメージを受けた時に発動する。\nこのカードの攻撃力は、そのダメージの数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"玄翼竜 ブラック・フェザー",h:"げんよくりゅう　ぶらっく・ふぇざー",e:"Blackfeather Darkrage Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、戦闘またはカードの効果によって自分がダメージを受けた時に発動できる。\n自分のデッキの上からカードを５枚まで墓地へ送る。\nこの効果で墓地へ送ったカードの中にモンスターカードがあった場合、このカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.6 先史遺産カオス・アトランタル",h:"かおすなんばーず６ おーぱーつかおす・あとらんたる",e:"Number C6: Chronomaly Chaos Atlandis",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3300,de:3300,tx:"レベル７モンスター×３\n①：このカードの効果を発動したターン、相手が受ける全てのダメージは０になる。\n②：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを攻撃力１０００アップの装備カード扱いとしてこのカードに装備する。\n③：このカードが「No.6 先史遺産アトランタル」をX素材としている場合、以下の効果を得る。\n●このカードのX素材を３つ取り除き、このカードの効果で装備している「No.」モンスターカードを全て墓地へ送って発動できる。\n相手のLPを１００にする。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.15 ギミック・パペット－シリアルキラー",h:"かおすなんばーず１５ ぎみっく・ぱぺっと－しりあるきらー",e:"Number C15: Gimmick Puppet Giant Hunter",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"レベル９モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果でモンスターを破壊した場合、さらにそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.40 ギミック・パペット－デビルズ・ストリングス",h:"かおすなんばーず４０ ぎみっく・ぱぺっと－でびるず・すとりんぐす",e:"Number C40: Gimmick Puppet of Dark Strings",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3300,de:2000,tx:"レベル９モンスター×３\n①：このカードが特殊召喚に成功した場合に発動する。\nストリングカウンターが置かれているモンスターを全て破壊し、自分はデッキから１枚ドローする。\nその後、この効果で破壊され墓地へ送られたモンスターの内、元々の攻撃力が一番高いモンスターのその数値分のダメージを相手に与える。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの表側表示モンスター全てにストリングカウンターを１つずつ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.88 ギミック・パペット－ディザスター・レオ",h:"かおすなんばーず８８ ぎみっく・ぱぺっと－でぃざすたー・れお",e:"Number C88: Gimmick Puppet Disaster Leo",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3500,de:2500,tx:"レベル９モンスター×４\nこのカードは「No.88 ギミック・パペット－デステニー・レオ」を対象とする「RUM」魔法カードの効果でのみ特殊召喚できる。\n自分エンドフェイズに、相手のLPが２０００以下でこのカードにX素材が無い場合、自分はデュエルに勝利する。\n①：フィールドのこのカードは効果の対象にならない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.9 天蓋妖星カオス・ダイソン・スフィア",h:"かおすなんばーず９ てんがいようせいかおす・だいそん・すふぃあ",e:"Number C9: Chaos Dyson Sphere",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3600,de:3200,tx:"レベル１０モンスター×３\n①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのX素材の数×３００ダメージを相手に与える。\n②：このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n③：このカードが「No.9 天蓋星ダイソン・スフィア」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を任意の数だけ取り除いて発動できる。\n取り除いた数×８００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.13 ケインズ・デビル",h:"なんばーず１３ けいんず・でびる",e:"Number 13: Embodiment of Crime",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:500,tx:"レベル１モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの全てのモンスターは、表側攻撃表示になり、このターン攻撃可能な場合にはこのカードを攻撃しなければならない。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドに「No.31 アベルズ・デビル」が存在する限り、このカードは以下の効果を得る。\n●X素材を持っているこのカードは戦闘・効果では破壊されない。\n●このカードへの攻撃で発生する自分への戦闘ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"No.31 アベルズ・デビル",h:"なんばーず３１ あべるず・でびる",e:"Number 31: Embodiment of Punishment",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:500,tx:"レベル１モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの全てのモンスターは、表側攻撃表示になり、このターン攻撃可能な場合にはこのカードを攻撃しなければならない。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドに「No.13 ケインズ・デビル」が存在する限り、このカードは以下の効果を得る。\n●X素材を持っているこのカードは戦闘・効果では破壊されない。\n●このカードへの攻撃で発生する自分への戦闘ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"No.82 ハートランドラコ",h:"なんばーず８２ はーとらんどらこ",e:"Number 82: Heartlandraco",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1500,tx:"レベル４モンスター×２\n①：自分フィールドに魔法カードが表側表示で存在する限り、相手はこのカードを攻撃対象に選択できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン、他の自分のモンスターは攻撃できず、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トライエッジ・リヴァイア",h:"とらいえっじ・りう゛ぁいあ",e:"Tri-Edge Levia",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1500,tx:"レベル３モンスター×３\nこのカードが戦闘によって破壊したモンスターは墓地へは行かずゲームから除外される。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除き、フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\nターン終了時まで、選択したモンスターの攻撃力は８００ポイントダウンし、効果は無効化される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－アージェント・カオス・フォース",h:"らんくあっぷまじっく－あーじぇんと・かおす・ふぉーす",e:"Rank-Up-Magic Argent Chaos Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果はデュエル中に１度しか使用できない。\n①：自分フィールドのランク５以上のXモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが１つ高い、「CNo.」Xモンスターまたは「CX」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドにランク５以上のXモンスターが特殊召喚された時に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガ学園の緊急連絡網",h:"ががががくえんのきんきゅうれんらくもう",e:"Gagaga Academy Emergency Network",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はX召喚以外の特殊召喚ができない。\n①：相手フィールドにのみモンスターが存在する場合に発動できる。\nデッキから「ガガガ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓地墓地の恨み",h:"ぼちぼちのうらみ",e:"Ghost of a Grudge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の墓地のカードが８枚以上の場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.58 炎圧鬼バーナー・バイサー",h:"なんばーず５８ えんあっきばーなー・ばいさー",e:"Number 58: Burner Visor",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"レベル４モンスター×２\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドのXモンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n●装備されているこのカードを攻撃表示で特殊召喚する。\n②：装備モンスターは相手に直接攻撃できる。\n③：装備モンスターが相手に戦闘ダメージを与えた時、手札を１枚捨てて発動できる。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ZS－幻影賢者",h:"ぜある・さーばす－ばにっしゅ・せーじ",e:"ZS - Vanish Sage",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:100,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに「希望皇ホープ」モンスターが存在する場合に発動できる。\n自分はデッキから１枚ドローする。\n②：自分フィールドのモンスターがバトルフェイズ中に除外された場合、その内の１体を対象とし、このカードをフィールドから除外して発動できる。\n対象のモンスターを自分フィールドに特殊召喚し、相手フィールドに攻撃力３０００以下のモンスターが存在する場合、その内の１体を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河魔鏡士",h:"ぎゃらくしー・みらー・せいじ",e:"Galaxy Mirror Sage",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:800,tx:"リバース：自分は自分の墓地の「ギャラクシー」と名のついたモンスターの数×５００ライフポイント回復する。\nまた、リバースしたこのカードが破壊され墓地へ送られた時、自分のデッキ・墓地からレベル４以下の「ギャラクシー」と名のついたモンスター１体を選んで裏側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河暴竜",h:"ぎゃらくしー・てぃらの",e:"Galaxy Tyranno",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"自分フィールド上の「ギャラクシー」と名のついたモンスターが攻撃対象に選択された時に発動できる。\nこのカードを手札から表側守備表示で特殊召喚する。\nこの効果で特殊召喚に成功した時、自分フィールド上の「ギャラクシー」と名のついたモンスターのみを素材として、「ギャラクシー」と名のついたエクシーズモンスター１体をエクシーズ召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"防覇龍ヘリオスフィア",h:"ぼうはりゅうへりおすふぃあ",e:"Heliosphere Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"相手の手札が４枚以下で自分フィールド上のモンスターがこのカードのみの場合、相手は攻撃宣言をする事ができない。\nまた、１ターンに１度、自分フィールド上にドラゴン族・レベル８モンスターが存在する場合に発動できる。\nこのカードのレベルをエンドフェイズ時まで８にする。",li:"",lk:[],ta:[],al:[]},
  {n:"マーメイド・シャーク",h:"まーめいど・しゃーく",e:"Mermaid Shark",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードが召喚に成功した時、デッキからレベル３～５の魚族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲイザー・シャーク",h:"げいざー・しゃーく",e:"Gazer Shark",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"墓地のこのカードをゲームから除外し、「ゲイザー・シャーク」以外の自分の墓地の水属性・レベル５モンスター２体を選択して発動できる。\n選択したモンスター２体の効果を無効にして特殊召喚し、その２体のみを素材として水属性のエクシーズモンスター１体をエクシーズ召喚する。\n「ゲイザー・シャーク」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリザード・サンダーバード",h:"ぶりざーど・さんだーばーど",e:"Blizzard Thunderbird",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"手札を１枚捨てて発動できる。\n「ブリザード・サンダーバード」以外の鳥獣族・水属性モンスターを自分の手札・墓地からそれぞれ１体ずつ選んで特殊召喚する。\nその後、フィールド上のこのカードを持ち主の手札に戻す。\n「ブリザード・サンダーバード」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BK ビッグバンテージ",h:"ばーにんぐなっくらー　びっぐばんてーじ",e:"Battlin' Boxer Big Bandage",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:1400,tx:"このカードは１ターンに１度だけ戦闘では破壊されない。\nまた、１ターンに１度、自分の墓地の「BK」と名のついたモンスター１体または除外されている自分の「BK」と名のついたモンスター１体を選択して発動できる。\n自分フィールド上の全ての「BK」と名のついたモンスターは選択したモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"BK ベイル",h:"ばーにんぐなっくらー　べいる",e:"Battlin' Boxer Veil",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"アンブラル・ゴースト",h:"あんぶらる・ごーすと",e:"Umbral Horror Ghost",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"自分のメインフェイズ時に発動できる。\nこのカードとレベル４以下の悪魔族モンスター１体を手札から特殊召喚する。\nこの効果を発動するターン、自分は通常召喚できない。\n「アンブラル・ゴースト」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－モラルタ",h:"あーてぃふぁくと－もらるた",e:"Artifact Moralltach",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、このカードが特殊召喚に成功した場合に発動できる。\n相手フィールドの表側表示のカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－ベガルタ",h:"あーてぃふぁくと－べがるた",e:"Artifact Beagalltach",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:2100,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、自分フィールド上にセットされたカードを２枚まで選んで破壊する。\n「アーティファクト－ベガルタ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－フェイルノート",h:"あーてぃふぁくと－ふぇいるのーと",e:"Artifact Failnaught",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、自分の墓地から「アーティファクト」と名のついたモンスター１体を選択して自分の魔法＆罠カードゾーンにセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－アイギス",h:"あーてぃふぁくと－あいぎす",e:"Artifact Aegis",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:2500,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、ターン終了時まで、自分フィールド上の「アーティファクト」と名のついたモンスターは相手のカードの効果の対象にならず、相手のカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－アキレウス",h:"あーてぃふぁくと－あきれうす",e:"Artifact Achilleshield",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:2200,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、このターン相手は自分フィールド上の「アーティファクト」と名のついたモンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－ラブリュス",h:"あーてぃふぁくと－らぶりゅす",e:"Artifact Labrys",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\nまた、「アーティファクト」と名のついたカードが破壊され、自分の墓地へ送られた時に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－カドケウス",h:"あーてぃふぁくと－かどけうす",e:"Artifact Caduceus",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:2400,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\nまた、このカードが自分フィールド上に表側表示で存在する限り、相手ターン中に「アーティファクト」と名のついたモンスターが特殊召喚された時、デッキからカードを１枚ドローする。\n「アーティファクト－カドケウス」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の神芽 スプラウト",h:"しんらのしんめ　すぷらうと",e:"Sylvan Cherubsprout",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが特殊召喚に成功した時、自分のデッキの上からカードを２枚までめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、デッキから植物族・レベル１モンスター１体を特殊召喚できる。\n「森羅の神芽 スプラウト」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の隠蜜 スナッフ",h:"しんらのおんみつ　すなっふ",e:"Sylvan Snapdrassinagon",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"手札・フィールド上のこのカードが墓地へ送られた場合、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の渡し守 ロータス",h:"しんらのわたしもり　ろーたす",e:"Sylvan Lotuswain",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2300,de:1100,tx:"１ターンに１度、相手フィールド上のカードの数だけ、自分のデッキの上からカードをめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、「森羅の渡し守 ロータス」以外の自分の墓地の「森羅」と名のついたカードを５枚まで選択し、好きな順番でデッキの下に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の賢樹 シャーマン",h:"しんらのけんじゅ　しゃーまん",e:"Sylvan Sagequoia",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2600,de:2100,tx:"「森羅」と名のついたモンスターが墓地へ送られた時、このカードを手札から特殊召喚できる。\n１ターンに１度、自分のメインフェイズ時に発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分の墓地の「森羅」と名のついた魔法・罠カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリックの人形",h:"ごーすとりっくのひとがた",e:"Ghostrick Doll",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"自分フィールドに「ゴーストリック」モンスターが存在する場合のみ、このカードは表側表示で召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした場合に発動する。\nこのターンのエンドフェイズに、フィールドの表側表示モンスターは全て裏側守備表示になる。\nその後、この効果で裏側守備表示になったモンスターの数以下のレベルを持つ「ゴーストリック」モンスター１体をデッキから裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ワーウルフ",h:"ごーすとりっく・わーうるふ",e:"Ghostrick Warwolf",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがリバースした時、フィールド上にセットされたカードの数×１００ポイントダメージを相手ライフに与える。\n「ゴーストリック・ワーウルフ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神－ヒルメ",h:"ぶじん－ひるめ",e:"Bujin Hirume",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードは通常召喚できない。\n「武神－ヒルメ」以外の自分の墓地の「武神」と名のついたモンスター１体をゲームから除外した場合に特殊召喚できる。\nまた、この方法で特殊召喚したこのカードが相手によって破壊され墓地へ送られた場合に発動できる。\n自分は手札を１枚捨てる。\nその後、相手は手札を１枚選んで捨てる。\n「武神－ヒルメ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ティオの蟲惑魔",h:"てぃおのこわくま",e:"Traptrix Dionaea",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の「蟲惑魔」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが特殊召喚に成功した時、自分の墓地の「ホール」通常罠カードまたは「落とし穴」通常罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nセットしたカードは次の自分ターンのエンドフェイズに除外される。\n③：このカードは「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機オライオン",h:"げんじゅうきおらいおん",e:"Mecha Phantom Beast O-Lion",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。\n②：このカードが墓地へ送られた場合に発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n③：墓地のこのカードを除外して発動できる。\n手札から「幻獣機」モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"陽炎獣 ヒュドラー",h:"へいずびーすと　ひゅどらー",e:"Hazy Flame Hydra",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2300,de:200,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできず、自分は炎属性以外のモンスターを特殊召喚できない。\nまた、このカードを素材としたエクシーズモンスターは以下の効果を得る。\n●このエクシーズ召喚に成功した時、自分の墓地から「陽炎獣」と名のついたモンスター１体を選択し、このカードの下に重ねてエクシーズ素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・エンジェリー",h:"まどるちぇ・えんじぇりー",e:"Madolche Anjelly",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nデッキから「マドルチェ」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは戦闘では破壊されず、次の自分ターンのエンドフェイズに持ち主のデッキに戻る。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタの神裔 ピリカ",h:"がすたのしんえい　ぴりか",e:"Pilica, Descendant of Gusto",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードが召喚・特殊召喚に成功した時、自分の墓地から風属性のチューナー１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果を発動したターン、自分は風属性以外のモンスターを特殊召喚できない。\n「ガスタの神裔 ピリカ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣アウグストル",h:"ぐらでぃあるびーすとあうぐすとる",e:"Gladiator Beast Augustus",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1000,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した時に発動できる。\n手札から「剣闘獣」モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主のデッキに戻る。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣アウグストル」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の鬼神 ケルト",h:"あんこくかいのきしん　けると",e:"Lucent, Netherlord of Dark World",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\n相手のカードの効果によって捨てられた場合、さらに自分のデッキから悪魔族モンスター１体を自分または相手フィールド上に特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械箱",h:"あんてぃーく・ぎあぼっくす",e:"Ancient Gear Box",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがドロー以外の方法でデッキ・墓地から手札に加わった場合に発動できる。\n「古代の機械箱」以外の攻撃力または守備力が５００の機械族・地属性モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"曙光の騎士",h:"しょこうのきし",e:"Dawn Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"フィールド上のこのカードが墓地へ送られた場合、デッキから光属性モンスター１体を墓地へ送る事ができる。\nまた、このカードがデッキから墓地へ送られた場合、自分の墓地の光属性モンスター１体を選択してデッキの一番上に置く。\n「曙光の騎士」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"威光魔人",h:"まじぇすてぃー・でびる",e:"Majesty's Fiend",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードは特殊召喚できない。\nこのカードがフィールド上に表側表示で存在する限り、お互いに効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"爆炎帝テスタロス",h:"ばくえんていてすたろす",e:"Thestalos the Mega Monarch",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\nこのカードがアドバンス召喚に成功した時、相手の手札を確認して１枚捨てる。\n捨てたカードがモンスターカードだった場合、そのモンスターのレベル×２００ポイントダメージを相手ライフに与える。\nこのカードが炎属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"鰤っ子姫",h:"ぶりんせす",e:"Beautunaful Princess",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・特殊召喚に成功した時、このカードをゲームから除外して発動できる。\nデッキから「鰤っ子姫」以外のレベル４以下の魚族モンスター１体を特殊召喚する。\n「鰤っ子姫」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"否定ペンギン",h:"ひていぺんぎん",e:"Nopenguin",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、「ペンギン」カードの効果でフィールドから手札に戻るカードは手札に戻らず除外される。\n②：このカードが墓地に存在し、「ペンギン」モンスターの効果が発動した時に発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"失楽の聖女",h:"しつらくのせいじょ",e:"Condemned Maiden",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分は相手ターンに速攻魔法カード１枚を手札から発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダストン",h:"すたーだすとん",e:"Starduston",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分フィールド上に表側表示で存在する「ダストン」と名のついたモンスターを任意の数だけ墓地へ送った場合のみ特殊召喚できる。\nこのカードの攻撃力・守備力は、墓地へ送ったそのモンスターの数×１０００ポイントになる。\nこのカードがフィールド上に表側表示で存在する限り、相手はフィールド上にセットされた魔法・罠カードを発動できず、相手はモンスターを反転召喚・特殊召喚できない。\n相手フィールド上のモンスターの数より、自分フィールド上のモンスターの数が多い場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.62 銀河眼の光子竜皇",h:"なんばーず６２　ぎゃらくしーあいず・ぷらいむ・ふぉとん・どらごん",e:"Number 62: Galaxy-Eyes Prime Photon Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:3000,tx:"レベル８モンスター×２\n①：このカードが戦闘を行うダメージ計算時に１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力はそのダメージ計算時のみ、フィールドのXモンスターのランクの合計×２００アップする。\n②：「銀河眼の光子竜」をX素材として持っていないこのカードが相手に与える戦闘ダメージは半分になる。\n③：「銀河眼の光子竜」をX素材として持っているこのカードが相手の効果で破壊された場合に発動できる。\n発動後２回目の自分スタンバイフェイズにこのカードの攻撃力を倍にして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.107 超銀河眼の時空龍",h:"かおすなんばーず１０７ ねお・ぎゃらくしーあいず・たきおん・どらごん",e:"Number C107: Neo Galaxy-Eyes Tachyon Dragon",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4500,de:3000,tx:"レベル９モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン相手はフィールドで発動する効果を発動できず、このカード以外のフィールドの全ての表側表示のカードの効果はターン終了時まで無効化される。\n②：このカードが「No.107 銀河眼の時空竜」をX素材としている場合、以下の効果を得る。\n●このカード以外の自分フィールドのモンスター２体をリリースして発動できる。\nこのターン、このカードは１度のバトルフェイズ中に３回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.103 神葬零嬢ラグナ・ゼロ",h:"なんばーず１０３ しんそうれいじょうらぐな・ぜろ",e:"Number 103: Ragnazero",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、元々の攻撃力と異なる攻撃力を持つ相手フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、自分はデッキから１枚ドローする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.103 神葬零嬢ラグナ・インフィニティ",h:"かおすなんばーず１０３ しんそうれいじょうらぐな・いんふぃにてぃ",e:"Number C103: Ragnafinity",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2400,tx:"レベル５モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、元々の攻撃力と異なる攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力とその元々の攻撃力の差分のダメージを相手に与え、そのモンスターを除外する。\nこの効果は相手ターンでも発動できる。\n②：X素材を持っているこのカードが破壊され墓地へ送られた時に発動できる。\nこのカードを特殊召喚する。\nこの効果は自分の墓地に「No.103 神葬零嬢ラグナ・ゼロ」が存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.102 光堕天使ノーブル・デーモン",h:"かおすなんばーず１０２ あんほーりー・らいとにんぐのーぶる・でーもん",e:"Number C102: Archfiend Seraph",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2900,de:2400,tx:"光属性レベル５モンスター×４\n①：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を２つ取り除く事ができる。\n②：このカードのX素材が全て取り除かれた場合に発動する。\n相手に１５００ダメージを与える。\n③：このカードが「No.102 光天使グローリアス・ヘイロー」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力を０にし、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"No.80 狂装覇王ラプソディ・イン・バーサーク",h:"なんばーず８０ きょうそうはおうらぷそでぃ・いん・ばーさーく",e:"Number 80: Rhapsody in Berserk",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:1200,tx:"レベル４モンスター×２\nこのカード名の①の効果は１ターンに２度まで使用できる。\n①：このカードのX素材を１つ取り除き、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\n②：自分フィールドのXモンスター１体を対象として発動できる。\n自分フィールドのこのカードを攻撃力１２００アップの装備カード扱いとしてその自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.80 葬装覇王レクイエム・イン・バーサーク",h:"かおすなんばーず８０ そうそうはおうれくいえむ・いん・ばーさーく",e:"Number C80: Requiem in Berserk",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2000,tx:"レベル５モンスター×３\n①：自分フィールドのXモンスター１体を対象として発動できる。\n自分フィールドのこのカードを攻撃力２０００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n③：このカードが「No.80 狂装覇王ラプソディ・イン・バーサーク」をX素材としている場合、以下の効果を得る。\n●このカードのX素材を１つ取り除き、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.43 魂魄傀儡鬼ソウル・マリオネッター",h:"なんばーず４３ こんぱくくぐつきそうる・まりおねったー",e:"Number 43: Manipulator of Souls",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"闇属性レベル２モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の「No.」モンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n②：「No.」カードを装備しているこのカードは戦闘・効果では破壊されない。\n③：１ターンに１度、自分のLPが回復した時に発動できる。\nその数値分だけこのカードの攻撃力をアップし、その数値分だけ相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.43 魂魄傀儡鬼神カオス・マリオネッター",h:"かおすなんばーず４３ こんぱくくぐつきしんかおす・まりおねったー",e:"Number C43: High Manipulator of Chaos",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"闇属性レベル３モンスター×４\n①：自分のトークンは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが「No.43 魂魄傀儡鬼ソウル・マリオネッター」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドに「魂魄トークン」（悪魔族・闇・星１・攻／守？）１体を特殊召喚する。\nこのトークンの攻撃力・守備力は、相手のLPの半分の数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－デュランダル",h:"あーてぃふぁくと－でゅらんだる",e:"Artifact Durendal",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2100,tx:"レベル５モンスター×２\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\nこの効果は相手ターンでも発動できる。\n\n●フィールドのモンスターの効果が発動した時、または通常魔法・通常罠カードが発動した時、このカードのX素材を１つ取り除いて発動できる。\nその効果は「相手フィールドの魔法・罠カード１枚を選んで破壊する」となる。\n\n●このカードのX素材を１つ取り除いて発動できる。\nお互いは手札を全てデッキに戻してシャッフルする。\nその後、お互いはそれぞれ自身がデッキに戻した数だけデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の鎮神 オレイア",h:"しんらのしずがみ　おれいあ",e:"Orea, the Sylvan High Arbiter",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2500,tx:"レベル７モンスター×２\n１ターンに１度、自分の手札・フィールド上の植物族モンスター１体を墓地へ送って発動できる。\nそのレベル分だけデッキの上からカードを確認し、好きな順番でデッキの上に戻す。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分のデッキの上からカードを３枚までめくる。\nその中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送り、その数までこのカード以外のフィールド上のカードを選んで手札に戻す。\n残りのカードは好きな順番でデッキの下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・サキュバス",h:"ごーすとりっく・さきゅばす",e:"Ghostrick Socuteboss",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1400,de:1200,tx:"レベル２モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、フィールド上の「ゴーストリック」と名のついたモンスターの攻撃力の合計以下の攻撃力を持つ、フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターを破壊し、自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する限り、そのモンスターカードゾーンは使用できない。\nまた、自分フィールド上にこのカード以外の「ゴーストリック」と名のついたモンスターが存在する場合、相手はこのカードを攻撃対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神姫－アマテラス",h:"ぶじんき－あまてらす",e:"Bujinki Amaterasu",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2500,tx:"レベル４モンスター×３\n①：「武神姫－アマテラス」は自分フィールドに１体しか表側表示で存在できない。\n②：自分・相手ターンに１度、このカードのX素材を１つ取り除き、除外されている自分のレベル４以下のモンスター１体を対象として発動できる。\n発動ターンによって以下の効果を適用する。\n\n●自分ターン：対象のモンスターを特殊召喚する。\n\n●相手ターン：対象のモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻子力空母エンタープラズニル",h:"みらーじゅふぉーとれすえんたーぷらずにる",e:"Phantom Fortress Enterblathnir",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2900,de:2500,tx:"レベル９モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n\n●相手フィールドのカード１枚を選んで除外する。\n\n●相手の手札をランダムに１枚選んで除外する。\n\n●相手の墓地のカード１枚を選んで除外する。\n\n●相手のデッキの一番上のカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗遷士 カンゴルゴーム",h:"あんせんし　かんごるごーむ",e:"Cairngorgon, Antiluminescent Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2450,de:1950,tx:"レベル４モンスター×２\nフィールド上のカード１枚を対象にする魔法・罠・効果モンスターの効果が発動した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその対象を自分・相手フィールド上の正しい対象となる別のカードに移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"波動竜フォノン・ドラゴン",h:"はどうりゅうふぉのん・どらごん",e:"Phonon Pulse Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1900,de:800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、１～３までのレベルを宣言して発動できる。\nこのカードのレベルは宣言したレベルになる。\nこの効果を発動したターン、自分はこのカードをシンクロ素材としたシンクロ召喚以外の特殊召喚ができない。\n自分は「波動竜フォノン・ドラゴン」を１ターンに１度しか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"リバース・ブレイカー",h:"りばーす・ぶれいかー",e:"Reverse Breaker",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「希望皇ホープ」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃宣言した時、相手フィールド上の魔法・罠カード１枚を選択して破壊する。\nこの効果の発動に対して相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河の施し",h:"ぎんがのほどこし",e:"Galactic Charity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ギャラクシー」と名のついたエクシーズモンスターが存在する場合に、手札を１枚捨てて発動できる。\nデッキからカードを２枚ドローする。\nこのカードを発動したターン、相手が受ける全てのダメージは半分になる。\n「銀河の施し」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－七皇の剣",h:"らんくあっぷまじっく－ざ・せぶんす・わん",e:"Rank-Up-Magic - The Seventh One",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の効果はデュエル中に１度しか適用できない。\n①：自分のドローフェイズに通常のドローをしたこのカードを公開し続ける事で、そのターンのメインフェイズ１開始時に発動できる。\n「CNo.」モンスター以外の「No.101」～「No.107」のいずれかの「No.」モンスター１体を、自分のEXデッキ・墓地から選んで特殊召喚し、そのモンスターと同じ「No.」の数字を持つ「CNo.」モンスター１体を、そのモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドン・サウザンドの玉座",h:"どん・さうざんどのぎょくざ",e:"Don Thousand's Throne",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手のエンドフェイズ時、自分はそのターンに戦闘ダメージを受けた回数×５００ライフポイント回復する。\nまた、自分フィールド上の「CNo.」以外の「No.」と名のついたモンスターが攻撃対象になった時、このカードを墓地へ送る事でその攻撃を無効にする。\nその後、その自分のモンスターと同じ「No.」の数字を持つ「CNo.」と名のついたモンスターを、そのモンスターの上に重ねてエクシーズ召喚扱いとしてエクストラデッキから特殊召喚する。\n「ドン・サウザンドの玉座」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト・ムーブメント",h:"あーてぃふぁくと・むーぶめんと",e:"Artifact Ignition",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「アーティファクト」モンスター１体を選んで魔法カード扱いとして自分の魔法＆罠ゾーンにセットする。\n②：このカードが相手によって破壊された場合に発動する。\n次の相手バトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクトの解放",h:"あーてぃふぁくとのかいほう",e:"Artifacts Unleashed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「アーティファクト」と名のついたモンスター２体を選択して発動できる。\n選択したモンスター２体のみを素材としてエクシーズモンスター１体をエクシーズ召喚する。\nこのカードを発動したターン、「アーティファクト」と名のついたモンスター以外の自分フィールド上のモンスターは攻撃できない。\nまた、このカードが相手によって破壊された場合、手札の光属性・レベル５モンスター１体を相手に見せて発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の施し",h:"しんらのほどこし",e:"Sylvan Charity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキからカードを３枚ドローする。\nその後、「森羅」と名のついたカード１枚を含む手札のカード２枚を相手に見せ、好きな順番でデッキの上に戻す。\n手札に「森羅」と名のついたカードが無い場合、手札を全て相手に見せ、好きな順番でデッキの上に戻す。\n「森羅の施し」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・パレード",h:"ごーすとりっく・ぱれーど",e:"Ghostrick Parade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのフィールド上のモンスターは、裏側守備表示のモンスターに攻撃できず、相手フィールド上のモンスターが裏側守備表示のモンスターのみの場合、相手プレイヤーに直接攻撃できる。\nまた、相手モンスターの直接攻撃宣言時、自分のデッキから「ゴーストリック」と名のついたカード１枚を手札に加える事ができる。\nこのカードがフィールド上に存在する限り、相手プレイヤーが受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神決戦",h:"ぶじんけっせん",e:"Bujintervention",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン選択したモンスターが、そのモンスターの元々の攻撃力以上の攻撃力を持つモンスターを戦闘によって破壊した場合、破壊したそのモンスターを除外し、さらにその同名モンスターを相手の手札・デッキ・エクストラデッキ・墓地から全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイルの金剛核",h:"こあきめいるのこんごうかく",e:"Diamond Core of Koa'ki Meiru",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキから「コアキメイルの金剛核」以外の「コアキメイル」と名のついたカード１枚を手札に加える。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外して発動できる。\nこのターン、自分フィールド上の「コアキメイル」と名のついたモンスターは破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ファクトリー",h:"すくらっぷ・ふぁくとりー",e:"Scrap Factory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの「スクラップ」モンスターの攻撃力・守備力は２００アップする。\n②：フィールドの表側表示の「スクラップ」モンスターが効果で破壊され墓地へ送られた時に発動できる。\n自分はデッキから「スクラップ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"禁じられた聖典",h:"きんじられたせいてん",e:"Forbidden Scripture",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：お互いのモンスターが戦闘を行うダメージ計算時に発動できる。\nダメージステップ終了時まで、このカード以外のフィールドのカードの効果は無効化され、その戦闘のダメージ計算は元々の攻撃力・守備力で行う。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャックポット７",h:"じゃっくぽっとせぶん",e:"Jackpot 7",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードをデッキに戻してシャッフルする。\nまた、このカードが相手のカードの効果によって墓地へ送られた時、このカードをゲームから除外する。\nこの効果によってゲームから除外された自分の「ジャックポット７」が３枚揃った時、自分はデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"双龍降臨",h:"だぶる・どらごん・でぃせんと",e:"Double Dragon Descent",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のエクシーズモンスターの直接攻撃宣言時に発動できる。\nエクストラデッキからドラゴン族・光属性のエクシーズモンスター１体を表側攻撃表示で特殊召喚し、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。\nこの効果で特殊召喚したモンスターの攻撃力は攻撃モンスターの攻撃力と同じ数値になり、効果は無効化される。\n「双龍降臨」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"時空混沌渦",h:"たきおん・かおす・ほーる",e:"Tachyon Chaos Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ギャラクシー」Xモンスターが、相手モンスターの攻撃または相手の効果で破壊され墓地へ送られた時に発動できる。\n相手フィールドの表側表示のカードを全て破壊し除外する。\n②：このカードが墓地に存在する場合、自分ドローフェイズに通常のドローを行う代わりに、墓地のこのカードを除外し、自分の墓地の「ギャラクシー」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラスト・カウンター",h:"らすと・かうんたー",e:"Last Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上の「BK」と名のついたモンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその攻撃を無効にし、その自分のモンスターを墓地へ送る。\nその相手モンスターの元々の攻撃力分、自分フィールド上の「BK」と名のついたモンスター１体の攻撃力をアップし、その相手モンスターとダメージ計算を行う。\nその後、自分はこのカードの効果でアップした攻撃力の数値分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクトの神智",h:"あーてぃふぁくとのしんち",e:"Artifact Sanctum",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はバトルフェイズを行えない。\n①：デッキから「アーティファクト」モンスター１体を特殊召喚する。\n②：このカードが相手によって破壊された場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の滝滑り",h:"しんらのたきすべり",e:"Sylvan Waterslide",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが直接攻撃宣言する度に、このカードの効果を発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、このカードがフィールド上に存在する限り、自分のドローフェイズ時に通常のドローを行う代わりに、自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ナイト",h:"ごーすとりっく・ないと",e:"Ghostrick Night",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に「ゴーストリック」と名のついたモンスターが存在する限り、相手フィールド上のモンスターは反転召喚できない。\nまた、このカードが相手によって破壊され墓地へ送られた時に発動する。\nこのターン相手は攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武神隠",h:"ぶじんがくれ",e:"Bujincident",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「武神」と名のついたエクシーズモンスター１体を選択して発動できる。\n選択したモンスターを除外し、フィールド上のモンスターを全て手札に戻す。\n発動後２回目の自分のエンドフェイズ時まで、お互いに召喚・反転召喚・特殊召喚できず、お互いが受ける全てのダメージは０になる。\nまた、発動後２回目の自分のエンドフェイズ時に発動する。\nこのカードの効果で除外したモンスターを特殊召喚し、自分の墓地の「武神」と名のついたモンスター１体を選択して、その特殊召喚したモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"帝王の溶撃",h:"ていおうのようげき",e:"The Monarchs Erupt",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のエクストラデッキにカードが存在せず、自分フィールドにアドバンス召喚したモンスターが存在する場合にこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、アドバンス召喚したモンスター以外のフィールドの表側表示モンスターの効果は無効化される。\n②：自分エンドフェイズに、アドバンス召喚したモンスターが自分フィールドに存在しない場合にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"進化の特異点",h:"しんかのとくいてん",e:"Evo-Singularity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地から、「エヴォルド」と名のついたモンスター１体と「エヴォルダー」と名のついたモンスター１体を選択して発動できる。\nエクストラデッキから「エヴォルカイザー」と名のついたモンスター１体を特殊召喚し、選択したモンスターをその下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・ユニバース",h:"えくしーず・ゆにばーす",e:"Xyz Universe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのXモンスター２体を対象として発動できる。\nそのモンスター２体を墓地へ送る。\nその後、そのXモンスター２体のランクの合計と同じか１つ低いランクを持つ、「No.」モンスター以外のXモンスター１体をEXデッキから特殊召喚し、このカードを下に重ねてX素材とする。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚制限－ディスコードセクター",h:"しょうかんせいげん－でぃすこーどせくたー",e:"And the Band Played On",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーは、自身のフィールド上のモンスターと同じレベルを持つモンスターを特殊召喚できない。\nまた、お互いのプレイヤーは、自身のフィールド上のモンスターと同じランクを持つモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"三位一択",h:"さんみいったく",e:"Tri-and-Guess",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エクストラデッキのモンスターカードの種類（融合・シンクロ・エクシーズ）を宣言して発動できる。\nお互いのエクストラデッキを全て確認し、宣言した種類のカードの枚数が多いプレイヤーは、３０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガシスター",h:"がががしすたー",e:"Gagaga Sister",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:200,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「ガガガ」魔法・罠カード１枚を手札に加える。\n②：このカード以外の自分フィールドの「ガガガ」モンスター１体を対象として発動できる。\nそのモンスターとこのカードは、ターン終了時までその２体のレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.55 ゴゴゴゴライアス",h:"なんばーず５５ ごごごごらいあす",e:"Number 55: Gogogo Goliath",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"レベル４モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの守備力は８００アップする。\n②：このカードのX素材を１つ取り除き、自分の墓地の岩石族・地属性・レベル４モンスター１体を対象として発動できる。\nその岩石族・地属性モンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドドロー",h:"どどどどろー",e:"Dodododraw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札または自分フィールド上に表側表示で存在する、「ドドド」と名のついたモンスター１体を墓地へ送って発動できる。\nデッキからカードを２枚ドローする。\n「ドドドドロー」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河眼の雲篭",h:"ぎゃらくしーあいず・くらうどらごん",e:"Galaxy-Eyes Cloudragon",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:300,de:250,tx:"このカードをリリースして発動できる。\n自分の手札・墓地から「銀河眼の雲篭」以外の「ギャラクシーアイズ」と名のついたモンスター１体を選んで特殊召喚する。\n「銀河眼の雲篭」のこの効果は１ターンに１度しか使用できない。\nまた、このカードが墓地に存在する場合、自分のメインフェイズ時に自分フィールド上の「ギャラクシーアイズ」と名のついたエクシーズモンスター１体を選択して発動できる。\n墓地のこのカードを選択したモンスターの下に重ねてエクシーズ素材とする。\n「銀河眼の雲篭」のこの効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河戦士",h:"ぎゃらくしー・そるじゃー",e:"Galaxy Soldier",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"「銀河戦士」の②の効果は１ターンに１度しか使用できない。\n①：このカード以外の手札の光属性モンスター１体を墓地へ送って発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードが特殊召喚に成功した時に発動できる。\nデッキから「ギャラクシー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅のフォトン・ストリーム",h:"はめつのふぉとん・すとりーむ",e:"Photon Stream of Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ギャラクシーアイズ」と名のついたモンスターが存在する場合に発動できる。\nフィールド上のカード１枚を選択してゲームから除外する。\n自分フィールド上に「銀河眼の光子竜」が存在しない場合、このカードは自分のターンにしか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"タキオン・トランスミグレイション",h:"たきおん・とらんすみぐれいしょん",e:"Tachyon Transmigration",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールドに「ギャラクシーアイズ・タキオン・ドラゴン」モンスターが存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドに「ギャラクシーアイズ」モンスターが存在する場合、チェーン２以降に発動できる。\nこのカードの発動時に積まれていたチェーン上の全ての、相手のモンスターの効果・魔法・罠カードの発動を無効にし、この効果で発動を無効にしたフィールドのモンスター及び魔法・罠カードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"BK シャドー",h:"ばーにんぐなっくらー　しゃどー",e:"Battlin' Boxer Shadow",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"自分のメインフェイズ時に発動できる。\n自分フィールド上の「BK」と名のついたエクシーズモンスターのエクシーズ素材を１つ取り除き、このカードを手札から特殊召喚する。\n「BK シャドー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.79 BK 新星のカイザー",h:"なんばーず７９ ばーにんぐなっくらー しんせいのかいざー",e:"Number 79: Battlin' Boxer Nova Kaiser",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1600,tx:"レベル４モンスター×２\n①：このカードの攻撃力は、このカードのX素材の数×１００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「BK」モンスター１体を選び、このカードの下に重ねてX素材とする。\n③：X素材を持っているこのカードが相手によって破壊され墓地へ送られた時、このカードが持っていたX素材の数まで、自分の墓地のレベル４以下の「BK」モンスターを対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジョルト・カウンター",h:"じょると・かうんたー",e:"Jolt Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「BK」と名のついたモンスターが存在する場合に発動できる。\nバトルフェイズ中に発動した効果モンスターの効果・魔法・罠カードの発動を無効にして破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"超電磁タートル",h:"ちょうでんじたーとる",e:"Electromagnetic Turtle",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：相手バトルフェイズに墓地のこのカードを除外して発動できる。\nそのバトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"師弟の絆",h:"していのきずな",e:"Bond Between Teacher and Student",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「ブラック・マジシャン」が存在する場合に発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャン・ガール」１体を選んで特殊召喚する。\nその後、デッキから「黒・魔・導」「黒・魔・導・爆・裂・破」「黒・爆・裂・破・魔・導」「黒・魔・導・連・弾」のいずれか１枚を選んで自分の魔法＆罠ゾーンにセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.52 ダイヤモンド・クラブ・キング",h:"なんばーず５２ だいやもんど・くらぶ・きんぐ",e:"Number 52: Diamond Crab King",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:3000,tx:"レベル４モンスター×２\n①：「No.52 ダイヤモンド・クラブ・キング」は自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nターン終了時まで、このカードの守備力を０にし、攻撃力を３０００にする。\n③：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n④：X素材が無いこのカードは、攻撃されたダメージステップ終了時に攻撃表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"月華竜 ブラック・ローズ",h:"げっかりゅう　ぶらっく・ろーず",e:"Black Rose Moonlight Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが特殊召喚に成功した時、または相手フィールド上にレベル５以上のモンスターが特殊召喚された時に発動する。\n相手フィールド上の特殊召喚されたモンスター１体を選択して持ち主の手札に戻す。\n「月華竜 ブラック・ローズ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の創造者",h:"きせきのそうぞうしゃ",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"①：「ゼアルフィールド！」と宣言し、自分フィールドの「CNo.39」Xモンスター１体を対象として発動できる。\nこのターン、そのモンスターは直接攻撃できる。\nこのデュエル中に自分が「希望の創造者」と「勝利の方程式」の効果を発動している場合、さらにこのターン中、以下を適用する。\n●対象のモンスターが相手に戦闘ダメージを与えた時、自分はデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻想の黒魔導師",h:"げんそうのくろまどうし",e:"Ebon Illusion Magician",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル７モンスター×２\nこのカードは自分フィールドのランク６の魔法使い族Xモンスターの上に重ねてX召喚する事もできる。\n「幻想の黒魔導師」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札・デッキから魔法使い族の通常モンスター１体を特殊召喚する。\n②：魔法使い族の通常モンスターの攻撃宣言時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラインモンスター スピア・ホイール",h:"らいんもんすたー　すぴあ・ほいーる",e:"Shogi Lance",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"１ターンに１度、このカード以外の自分フィールド上の獣戦士族・レベル３モンスター１体を選択して発動できる。\n選択したモンスターとこのカードは、それぞれのレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"天輪の葬送士",h:"てんりんのそうそうし",e:"Guiding Light",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが召喚に成功した時、自分の墓地の光属性・レベル１モンスター１体を対象として発動できる。\nその光属性モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・イリュージョン",h:"ぶらっく・いりゅーじょん",e:"Black Illusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力２０００以上の魔法使い族・闇属性モンスターは、ターン終了時まで、戦闘では破壊されず、効果は無効化され、相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.39 希望皇ビヨンド・ザ・ホープ",h:"なんばーず３９ きぼうおうびよんど・ざ・ほーぷ",e:"Number 39: Utopia Beyond",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル６モンスター×２\nこのカード名はルール上「希望皇ホープ」カードとしても扱う。\n①：このカードがX召喚に成功した場合に発動する。\n相手フィールドの全てのモンスターの攻撃力は０になる。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドのXモンスター１体と自分の墓地の「希望皇ホープ」モンスター１体を対象として発動できる。\nその自分フィールドのXモンスターを除外し、その墓地のモンスターを特殊召喚する。\nその後、自分は１２５０LP回復する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"CX 冀望皇バリアン",h:"かおすえくしーず　きぼうおうばりあん",e:"CXyz Barian Hope",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル７モンスター×３体以上\nこのカードは、「CNo.101」～「CNo.107」のいずれかをカード名に含む自分フィールド上のモンスターの上に重ねてエクシーズ召喚する事もできる。\nこのカードの攻撃力は、このカードのエクシーズ素材の数×１０００ポイントアップする。\n自分の墓地の「No.」と名のついたモンスター１体を選択して発動できる。\n次の相手のエンドフェイズ時まで、このカードは選択したモンスターと同名カードとして扱い、同じ効果を得る。\n「CX 冀望皇バリアン」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星読みの魔術師",h:"ほしよみのまじゅつし",e:"Stargazer Magician",t:"monster",a:"闇",l:5,ps:1,pe:"①：自分のPモンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法カードを発動できない。\n②：もう片方の自分のPゾーンに「魔術師」カードまたは「オッドアイズ」カードが存在しない場合、このカードのPスケールは４になる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:2400,tx:"①：１ターンに１度、自分フィールドのPモンスター１体のみが相手の効果で自分の手札に戻った時に発動できる。\nその同名モンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"時読みの魔術師",h:"ときよみのまじゅつし",e:"Timegazer Magician",t:"monster",a:"闇",l:3,ps:8,pe:"自分フィールドにモンスターが存在しない場合にこのカードを発動できる。\n①：自分のPモンスターが戦闘を行う場合、相手はダメージステップ終了時まで罠カードを発動できない。\n②：もう片方の自分のPゾーンに「魔術師」カードまたは「オッドアイズ」カードが存在しない場合、このカードのPスケールは４になる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:600,tx:"①：このカードがモンスターゾーンに存在する限り、１ターンに１度、自分のPゾーンのカードは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装聖龍 イーサルウェポン",h:"まそうせいりゅう　いーさるうぇぽん",e:"Aether, the Empowering Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"「魔装聖龍 イーサルウェポン」の効果は１ターンに１度しか使用できない。\n①：このカードがP召喚に成功した時、フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装戦士 ヴァンドラ",h:"まそうせんし　う゛ぁんどら",e:"Ventdra, the Empowered Warrior",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"①：このカードは直接攻撃できる。\n②：このカードがフィールドから墓地へ送られた場合、自分の墓地のドラゴン族・戦士族・魔法使い族の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装戦士 アルニス",h:"まそうせんし　あるにす",e:"Arnis, the Empowered Warrior",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードが相手モンスターの攻撃で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の魔法使い族モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装戦士 テライガー",h:"まそうせんし　てらいがー",e:"Terratiger, the Empowered Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の通常モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装戦士 ハイドロータス",h:"まそうせんし　はいどろーたす",e:"Hydrotortoise, the Empowered Warrior",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:900,tx:"①：このカードがリバースした場合、相手フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄龍の召喚士",h:"こうりゅうのしょうかんし",e:"Golden Dragon Summoner",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「黄龍の召喚士」の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースし、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"青竜の召喚士",h:"せいりゅうのしょうかんし",e:"Blue Dragon Summoner",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"①：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキからドラゴン族・戦士族・魔法使い族の通常モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"朱雀の召喚士",h:"すざくのしょうかんし",e:"Red Sparrow Summoner",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"①：このカードが相手モンスターの攻撃で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の戦士族モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"白虎の召喚士",h:"びゃっこのしょうかんし",e:"White Tiger Summoner",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1700,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の通常モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの攻撃力・守備力は１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"玄武の召喚士",h:"げんぶのしょうかんし",e:"Green Turtle Summoner",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:1500,tx:"①：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"破邪の魔法壁",h:"はじゃのまほうへき",e:"Sorcerous Spell Wall",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターは、自分ターンの間は攻撃力が３００アップし、相手ターンの間は守備力が３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"補給部隊",h:"ほきゅうぶたい",e:"Supply Squad",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドのモンスターが戦闘・効果で破壊された場合にこの効果を発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ドラゴン",h:"おっどあいず・どらごん",e:"Odd-Eyes Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の騎士 ティマイオス",h:"でんせつのきし　てぃまいおす",e:"Legendary Knight Timaeus",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードは通常召喚できない。\n「レジェンド・オブ・ハート」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した時、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその表側表示の魔法・罠カードを除外する。\n②：このカードが攻撃対象に選択された時、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"クリバンデット",h:"くりばんでっと",e:"Kuribandit",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"①：このカードが召喚に成功したターンのエンドフェイズにこのカードをリリースして発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から魔法・罠カード１枚を選んで手札に加える事ができる。\n残りのカードは全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"竜騎士ブラック・マジシャン・ガール",h:"りゅうきしぶらっく・まじしゃん・がーる",e:"Dark Magician Girl the Dragon Knight",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1700,tx:"「ブラック・マジシャン・ガール」＋ドラゴン族モンスター\nこのカードは上記カードを融合素材にした融合召喚または「ティマイオスの眼」の効果でのみ特殊召喚できる。\n①：１ターンに１度、手札を１枚墓地へ送り、フィールドの表側表示のカード１枚を対象として発動できる。\nその表側表示のカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"レジェンド・オブ・ハート",h:"れじぇんど・おぶ・はーと",e:"Legend of Heart",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「レジェンド・オブ・ハート」は１ターンに１枚しか発動できない。\n①：２０００LPを払い、自分フィールドの戦士族モンスター１体をリリースして発動できる。\n自分の手札・墓地の「伝説の竜」魔法カードを３種類まで除外し、除外した種類の数だけ、「伝説の騎士」モンスターを自分の手札・デッキ・墓地から選んで特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"狂戦士の魂",h:"ばーさーかー・そうる",e:"Berserker Soul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「狂戦士の魂」は１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターが直接攻撃で相手に１５００以下のダメージを与えた時、手札を全て捨てて発動できる。\n自分のデッキの一番上のカードをめくり、それがモンスターだった場合、そのモンスターを墓地へ送り、相手に５００ダメージを与える。\nその後、モンスター以外がめくられるまでこの効果を（最大で７回）繰り返す。\nめくったカードがモンスター以外だった場合、そのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魂のリレー",h:"たましいのりれー",e:"Relay Soul",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札からモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、自分が受ける全てのダメージは０になる。\nそのモンスターがフィールドから離れた時に相手はデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・デスサイス",h:"がーでぃあん・ですさいす",e:"Guardian Dreadscythe",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：「ガーディアン・エアトス」が戦闘・効果で破壊され自分の墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した時に発動できる。\nデッキから「死神の大鎌－デスサイス」１枚をこのカードに装備する。\n③：このカードがモンスターゾーンに存在する限り、自分は召喚・特殊召喚できない。\n④：このカードがフィールドから墓地へ送られた場合に発動する。\n手札を１枚墓地へ送り、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"女神の聖剣－エアトス",h:"めがみのせいけん－えあとす",e:"Celestial Sword - Eatos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は５００アップする。\n②：このカードがフィールドから墓地へ送られた時、自分フィールドの「ガーディアン・エアトス」１体を対象として発動できる。\nそのモンスターの攻撃力は、除外されているモンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"死神の大鎌－デスサイス",h:"しにがみのおおがま－ですさいす",e:"Reaper Scythe - Dreadscythe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ガーディアン・デスサイス」にのみ装備可能。\n①：装備モンスターの攻撃力は、お互いの墓地のモンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"守護神の宝札",h:"しゅごしんのほうさつ",e:"Guarded Treasure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：手札を５枚捨ててこのカードを発動できる。\n自分はデッキから２枚ドローする。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分ドローフェイズの通常のドローは２枚になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ソウル・チャージ",h:"そうる・ちゃーじ",e:"Soul Charge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はバトルフェイズを行えない。\n①：自分の墓地のモンスターを任意の数だけ対象として発動できる。\nそのモンスターを特殊召喚し、自分はこの効果で特殊召喚したモンスターの数×１０００LPを失う。",li:0,lk:[],ta:[],al:[]},
  {n:"賢者の石－サバティエル",h:"けんじゃのいし－さばてぃえる",e:"Sabatiel - The Philosopher's Stone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地に「ハネクリボー」モンスターが存在する場合、LPを半分払って発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。\n②：このカードが墓地に存在する場合、自分の墓地の「賢者の石－サバティエル」３枚を除外し、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、フィールドの攻撃力が一番高いモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"瞬間融合",h:"しゅんかんゆうごう",e:"Flash Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドから融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で融合召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"決闘融合－バトル・フュージョン",h:"けっとうゆうごう－ばとる・ふゅーじょん",e:"Battle Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「決闘融合－バトル・フュージョン」は１ターンに１枚しか発動できない。\n①：自分フィールドの融合モンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその自分のモンスターの攻撃力はダメージステップ終了時まで、戦闘を行う相手モンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"決戦融合－ファイナル・フュージョン",h:"けっせんゆうごう－ふぁいなる・ふゅーじょん",e:"Final Fusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの融合モンスターが相手フィールドの融合モンスターと戦闘を行うバトルステップに、その融合モンスター２体を対象として発動できる。\nその攻撃を無効にし、お互いのプレイヤーはその融合モンスター２体の攻撃力の合計分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ペアサイクロイド",h:"ぺあさいくろいど",e:"Pair Cycroid",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1600,de:1200,tx:"同名の機械族モンスター×２\n①：このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エアーズロック・サンライズ",h:"えあーずろっく・さんらいず",e:"Ayers Rock Sunrise",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の獣族モンスター１体を対象として発動できる。\nその獣族モンスターを特殊召喚し、相手フィールドに表側表示モンスターが存在する場合、それらのモンスターの攻撃力はターン終了時まで、自分の墓地の獣族・鳥獣族・植物族モンスターの数×２００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドゥーブルパッセ",h:"どぅーぶるぱっせ",e:"Doble Passe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターが自分フィールドの表側攻撃表示モンスターに攻撃宣言した時に発動できる。\n攻撃対象モンスターの攻撃力分のダメージを相手に与え、その相手モンスターの攻撃を自分への直接攻撃にする。\nその自分のモンスターは、次の自分ターンに直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"カーボネドン",h:"かーぼねどん",e:"Carboneddon",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"「カーボネドン」の②の効果は１ターンに１度しか使用できない。\n①：このカードが炎属性モンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力は、そのダメージ計算時のみ１０００アップする。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\n手札・デッキからレベル７以下のドラゴン族の通常モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マスマティシャン",h:"ますまてぃしゃん",e:"Mathematician",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキからレベル４以下のモンスター１体を墓地へ送る。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラーの使徒",h:"らーのしと",e:"Ra's Disciple",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:600,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n手札・デッキから「ラーの使徒」を２体まで特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分は「ラーの使徒」の効果でしかモンスターを特殊召喚できず、このカードは「オシリスの天空竜」「オベリスクの巨神兵」「ラーの翼神竜」のアドバンス召喚以外のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"神縛りの塚",h:"かみしばりのつか",e:"Mound of the Bound Creator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドのレベル１０以上のモンスターは効果の対象にならず、効果では破壊されない。\n②：フィールドのレベル１０以上のモンスターが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\n破壊されたモンスターのコントローラーは１０００ダメージを受ける。\n③：フィールドのこのカードが効果で破壊され墓地へ送られた時に発動できる。\nデッキから神属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"シューティング・スター",h:"しゅーてぃんぐ・すたー",e:"Shooting Star",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドに「スターダスト」モンスターが存在する場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－突風のオロシ",h:"ぶらっくふぇざー－とっぷうのおろし",e:"Blackwing - Oroshi the Squall",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「BF－突風のオロシ」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがS素材として墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－隠れ蓑のスチーム",h:"ぶらっくふぇざー－かくれみののすちーむ",e:"Blackwing - Steam the Cloak",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1200,tx:"「BF－隠れ蓑のスチーム」の②の効果はデュエル中に１度しか使用できない。\n①：表側表示のこのカードがフィールドから離れた場合に発動する。\n自分フィールドに「スチーム・トークン」（水族・風・星１・攻／守１００）１体を特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードをS素材とする場合、他のS素材モンスターは全て「BF」モンスターでなければならない。",li:0,lk:[],ta:[],al:[]},
  {n:"BF－竜巻のハリケーン",h:"ぶらっくふぇざー－たつまきのはりけーん",e:"Blackwing - Hurricane the Tornado",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、フィールドのSモンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、対象のモンスターの攻撃力と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・ソニック",h:"ぶらっく・そにっく",e:"Black Sonic",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドのモンスターが「BF」モンスター３体のみの場合、このカードの発動は手札からもできる。\n①：相手モンスターが自分フィールドの「BF」モンスターに攻撃宣言した時に発動できる。\n相手フィールドの表側攻撃表示モンスターを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・リベンジ",h:"ぶらっく・りべんじ",e:"Black Wing Revenge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの鳥獣族モンスターが戦闘で破壊され墓地へ送られた時に発動できる。\n自分フィールドに「BF－ブラック・クレスト・トークン」（鳥獣族・闇・星２・攻０／守８００）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドー・インパルス",h:"しゃどー・いんぱるす",e:"Shadow Impulse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「シャドー・インパルス」は１ターンに１枚しか発動できない。\n①：自分フィールドのSモンスターが戦闘・効果で破壊され墓地へ送られた時、そのモンスター１体を対象として発動できる。\nそのモンスターと同じレベル・種族でカード名が異なるSモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アサルト・ガンドッグ",h:"あさると・がんどっぐ",e:"Assault Dog",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「アサルト・ガンドッグ」を任意の数だけ特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲート・ブロッカー",h:"げーと・ぶろっかー",e:"Gate Blocker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"①：このカードがモンスターゾーンに存在する限り、相手は他の自分フィールドのモンスターを効果の対象にできず、フィールドのカードにカウンターを置く事はできない。\nまた、相手のフィールド魔法カードの効果は無効になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャクタン",h:"ぎゃくたん",e:"Wiretap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：罠カードが発動した時に発動できる。\nその発動を無効にし、そのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"勇気機関車ブレイブポッポ",h:"ゆうききかんしゃぶれいぶぽっぽ",e:"Lionhearted Locomotive",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"①：このカードの攻撃宣言時に発動する。\nこのカードの攻撃力はダメージステップ終了時まで元々の攻撃力の半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"豪腕特急トロッコロッコ",h:"ごうわんとっきゅうとろっころっこ",e:"Express Train Trolley Olley",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\nこのカードの攻撃力は８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"工作列車シグナル・レッド",h:"こうさくれっしゃしぐなる・れっど",e:"Construction Train Signal Red",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1300,tx:"①：相手モンスターの攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚し、その相手モンスターの攻撃対象をこのカードに移し替えてダメージ計算を行う。\nこのカードはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"機関連結",h:"きかんれんけつ",e:"Train Connection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分の墓地のレベル１０以上の機械族モンスター２体を除外して発動した場合のみ、機械族・地属性モンスターに装備可能。\n①：装備モンスターの攻撃力は元々の攻撃力の倍になり、装備モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：このカードが魔法＆罠ゾーンに存在する限り、装備モンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.73 激瀧瀑神アビス・スープラ",h:"かおすなんばーず７３ げきろうばくしんあびす・すーぷら",e:"Number C73: Abyss Supra Splash",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"レベル６モンスター×３\n①：自分のモンスターが相手モンスターと戦闘を行うダメージ計算時に１度、このカードのX素材を１つ取り除いて発動できる。\nその自分のモンスターの攻撃力はそのダメージ計算時のみ、その相手モンスターの攻撃力分アップする。\n②：このカードが「No.73 激瀧神アビス・スプラッシュ」をX素材としている場合、以下の効果を得る。\n●このカードは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－クイック・カオス",h:"らんくあっぷまじっく－くいっく・かおす",e:"Rank-Up-Magic Quick Chaos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：「CNo.」モンスター以外の自分フィールドの「No.」Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが１つ高く、同じ「No.」の数字を持つ「CNo.」モンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.5 亡朧龍 カオス・キマイラ・ドラゴン",h:"かおすなんばーず５ もうろうりゅう かおす・きまいら・どらごん",e:"Number C5: Chaos Chimera Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル６モンスター×３体以上\n①：このカードの攻撃力は、このカードのX素材の数×１０００アップする。\n②：このカードが攻撃を行ったダメージステップ終了時に、このカードのX素材を１つ取り除いて発動できる。\nこのカードは相手モンスターに続けて攻撃できる。\n③：バトルフェイズ終了時に、LPを半分払い、自分・相手の墓地のカードを合計２枚対象として発動できる。\nその内の１枚を持ち主のデッキの一番上に置き、もう１枚をこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－千死蛮巧",h:"らんくあっぷまじっく－あどまいやー・です・さうざんど",e:"Rank-Up-Magic Admiration of the Thousands",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「RUM－千死蛮巧」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はこのカードの効果以外ではモンスターを特殊召喚できない。\n①：自分及び相手の墓地の同じランクのXモンスターをそれぞれ１体以上対象として発動できる。\nそのモンスターよりランクが１つ高い、「CNo.」モンスターまたは「CX」モンスター１体をエクストラデッキから特殊召喚し、対象のモンスターを下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ハンド",h:"まじっく・はんど",e:"Magic Hand",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"①：このカードがフィールドに表側表示で存在する限り１度だけ、相手がドロー以外の方法でデッキからカードを手札に加えた時に発動できる。\nそのカードを墓地へ送り、相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤー・ハンド",h:"ふぁいやー・はんど",e:"Fire Hand",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが相手によって破壊され墓地へ送られた時、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。\nその後、自分のデッキから「アイス・ハンド」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アイス・ハンド",h:"あいす・はんど",e:"Ice Hand",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"①：このカードが相手によって破壊され墓地へ送られた時、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを破壊する。\nその後、自分のデッキから「ファイヤー・ハンド」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"プロミネンス・ハンド",h:"ぷろみねんす・はんど",e:"Prominence Hand",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:600,de:2000,tx:"①：自分フィールドに「マジック・ハンド」「ファイヤー・ハンド」「アイス・ハンド」のいずれかが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オボミ",h:"おぼみ",e:"Lillybot",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"①：このカードが召喚・リバースした時、自分の墓地の「オービタル ７」１体を対象として発動できる。\nそのモンスターを表側攻撃表示または裏側守備表示で特殊召喚する。\n②：１ターンに１度、自分フィールドの機械族モンスターを任意の数だけリリースして発動できる。\nリリースした数だけ、手札から「フォトン」モンスターまたは「ギャラクシー」モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホープ剣スラッシュ",h:"ほーぷけんすらっしゅ",e:"Rising Sun Slash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「希望皇ホープ」モンスターにのみ装備可能。\n①：装備モンスターは効果では破壊されない。\n②：このカードが魔法＆罠ゾーンに存在する限り、モンスターの攻撃が無効になる度に、このカードにホープ剣カウンターを１つ置く。\n装備モンスターの攻撃力は、このカードのホープ剣カウンターの数×５００アップする。\n③：自分フィールドの装備モンスターがX素材を取り除いて効果を発動する場合、このカードは取り除くX素材の１つとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－デスサイズ",h:"あーてぃふぁくと－ですさいず",e:"Artifact Scythe",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:900,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、このカードが特殊召喚に成功した場合に発動する。\nこのターン、相手はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士の三兄弟",h:"せいきしのさんきょうだい",e:"Noble Knight Brothers",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札から「聖騎士」モンスターを２体まで特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「聖騎士」モンスターしか特殊召喚できない。\n②：このカードは、自分フィールドのモンスターが「聖騎士」モンスター３体の場合にのみ攻撃できる。\n③：１ターンに１度、自分の墓地の「聖騎士」カード及び「聖剣」カードを合計３枚対象として発動できる。\nそのカード３枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士エクター・ド・マリス",h:"せいきしえくたー・ど・まりす",e:"Noble Knight Eachtar",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"「聖騎士エクター・ド・マリス」の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の「聖騎士」モンスター２体を除外して発動できる。\nこのカードを手札・墓地から特殊召喚する。\n②：このカードを素材とした「聖騎士」モンスターのS召喚・X召喚は無効化されず、その特殊召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の姫芽君 スプラウト",h:"しんらのひめぎみ　すぷらうと",e:"Sylvan Princessprout",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「森羅の姫芽君 スプラウト」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n自分のデッキの一番上のカードをめくって墓地へ送る。\nその後、自分の墓地の「スプラウト」モンスター１体を選んで自分のデッキの一番上に置く事ができる。\n②：デッキのこのカードが効果でめくられて墓地へ送られた場合、１～８までの任意のレベルを宣言して発動できる。\nこのカードを墓地から特殊召喚し、このカードのレベルは宣言したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神器－ツムガリ",h:"ぶじんぎ－つむがり",e:"Bujingi Sinyou",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「武神器－ツムガリ」の効果は１ターンに１度しか使用できない。\n①：自分の獣戦士族の「武神」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、墓地のこのカードを除外して発動できる。\nその戦闘を行う自分のモンスターの攻撃力はダメージステップ終了時まで、戦闘を行う相手モンスターの攻撃力分アップし、その戦闘によって相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンプ・オブ・ヴァンパイア",h:"う゛ぁんぷ・おぶ・う゛ぁんぱいあ",e:"Vampire Vamp",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：このカードが召喚に成功した時、または自分フィールドに「ヴァンパイア」モンスターが召喚された時に、このカードより攻撃力が高い相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\nこの効果は１ターンに１度しか使用できない。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力分アップする。\n③：このカードの効果で装備カードを装備したこのカードが墓地へ送られた場合に発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ネロキウス",h:"ぐらでぃあるびーすとねろきうす",e:"Gladiator Beast Nerokius",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:1900,tx:"「剣闘獣」モンスター×３\n自分フィールドの上記カードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードは戦闘では破壊されず、このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードをエクストラデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"円卓の聖騎士",h:"えんたくのせいきし",e:"Noble Knights of the Round Table",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分エンドフェイズに、自分のフィールド・墓地の「聖騎士」カードの種類によって以下の効果をそれぞれ１度ずつ発動できる。\n\n●３種類以上：デッキから「聖騎士」カード１枚を墓地へ送る。\n\n●６種類以上：手札から「聖騎士」モンスター１体を特殊召喚する。\nその後、手札から「聖剣」装備魔法カード１枚をそのモンスターに装備できる。\n\n●９種類以上：自分の墓地の「聖騎士」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n\n●１２種類：自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"約束の地 －アヴァロン－",h:"やくそくのち　－あう゛ぁろん－",e:"Avalon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：「アルトリウス」モンスター及び「ランスロット」モンスター１体ずつを含む自分の墓地の「聖騎士」モンスター５体を対象として発動できる。\nそのモンスターを除外し、フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"連撃の帝王",h:"れんげきのていおう",e:"Escalation of the Monarchs",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、相手のメインフェイズ及びバトルフェイズにこの効果を発動できる。\nモンスター１体をアドバンス召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲリラカイト",h:"げりらかいと",e:"Guerilla Kite",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:200,tx:"「ゲリラカイト」の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキック・ファイター",h:"えれきっく・ふぁいたー",e:"Wattsychic Fighter",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"「エレキック・ファイター」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、相手の墓地のカード１枚を対象として発動できる。\nそのカードを相手のデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"旧神ノーデン",h:"きゅうしんのーでん",e:"Elder Entity Norden",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2200,tx:"SモンスターまたはXモンスター＋SモンスターまたはXモンスター\n①：このカードが特殊召喚に成功した時、自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。",li:0,lk:[],ta:[],al:[]},
  {n:"驚天動地",h:"きょうてんどうち",e:"Earthshattering Event",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分または相手のデッキからカードが墓地へ送られた場合、自分または相手の墓地のカード１枚を対象としてこの効果を発動する。\nそのカードを持ち主のデッキに戻す。\nこの効果の発動後、ターン終了時までお互いにデッキからカードを墓地へ送る事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ブレイク",h:"ごーすとりっく・ぶれいく",e:"Ghostrick Break",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ゴーストリック」モンスター１体が戦闘または相手の効果で破壊され自分の墓地へ送られた時、破壊されたそのモンスターとカード名が異なる、自分の墓地の「ゴーストリック」モンスター２体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃光の騎士",h:"せんこうのきし",e:"Flash Knight",t:"monster",a:"光",l:4,ps:7,pe:"",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1800,de:600,tx:"神の振り子により新たな力を会得した騎士。\n今こそ覚醒し、その力を解放せよ！",li:"",lk:[],ta:[],al:[]},
  {n:"フーコーの魔砲石",h:"ふーこーのまほうせき",e:"Foucault's Cannon",t:"monster",a:"闇",l:5,ps:2,pe:"①：このカードを発動したターンのエンドフェイズに、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2200,de:1200,tx:"夢幻の空間を彷徨う機械仕掛けの生命体、だったはずである。\n\n一番の謎は、過去の記録が殆ど残ってい・・事だ。\n\nその理由・・・なのか、・・・・・干渉・・・て拒・・・ている・・？\n・・・消去・・・",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・アームド・ドラゴン",h:"めたふぁいず・あーむど・どらごん",e:"Metaphys Armed Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"幻竜族",mo:["通常"],ma:"",tp:"",at:2800,de:1000,tx:"崇高なる存在は幻の如く。\n其の竜は頂きに佇む。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ペンデュラム・ドラゴン",h:"おっどあいず・ぺんでゅらむ・どらごん",e:"Odd-Eyes Pendulum Dragon",t:"monster",a:"闇",l:7,ps:4,pe:"このカード名の①②のP効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のPモンスターの戦闘で発生する自分への戦闘ダメージを０にできる。\n②：自分エンドフェイズに発動できる。\nこのカードを破壊し、デッキから攻撃力１５００以下のPモンスター１体を手札に加える。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMアメンボート",h:"えんためいとあめんぼーと",e:"Performapal Skeeter Skimmer",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1600,tx:"①：攻撃表示のこのカードが攻撃対象に選択された時に発動できる。\nこのカードを表側守備表示にし、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMウィップ・バイパー",h:"えんためいとうぃっぷ・ばいぱー",e:"Performapal Whip Snake",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"①：１ターンに１度、フィールドの表側表示のモンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力をターン終了時まで入れ替える。\nこの効果はお互いのメインフェイズにのみ発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMソード・フィッシュ",h:"えんためいとそーど・ふぃっしゅ",e:"Performapal Sword Fish",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は６００ダウンする。\n②：このカードがモンスターゾーンに存在し、自分がモンスターの特殊召喚に成功した場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は６００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMディスカバー・ヒッポ",h:"えんためいとでぃすかばー・ひっぽ",e:"Performapal Hip Hippo",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに、レベル７以上のモンスター１体を表側攻撃表示でアドバンス召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMカレイドスコーピオン",h:"えんためいとかれいどすこーぴおん",e:"Performapal Kaleidoscorp",t:"monster",a:"光",l:6,ps:4,pe:"①：自分フィールドの光属性モンスターの攻撃力は３００アップする。",tr:"昆虫族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:2300,tx:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターは相手フィールドの特殊召喚されたモンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMヒックリカエル",h:"えんためいとひっくりかえる",e:"Performapal Turn Toad",t:"monster",a:"水",l:2,ps:3,pe:"①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力をターン終了時まで入れ替える。",tr:"水族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:800,tx:"①：自分バトルフェイズに１度、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更し、その攻撃力・守備力をターン終了時まで入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ワカ－O２",h:"ちょうじゅうむしゃわか－おに",e:"Superheavy Samurai Blue Brawler",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ソード－９９９",h:"ちょうじゅうむしゃそーど－きゅーきゅーきゅー",e:"Superheavy Samurai Swordsman",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：自分フィールドの「超重武者」モンスターが相手モンスターと戦闘を行ったダメージ計算後に発動する。\nその相手モンスターの攻撃力・守備力は０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ビッグベン－K",h:"ちょうじゅうむしゃびっぐべん－けー",e:"Superheavy Samurai Big Benkei",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:3500,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「超重武者」モンスターは、表側守備表示のままで攻撃できる。\nその場合、そのモンスターは守備力を攻撃力として扱いダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女アリア",h:"げんそうのおとめありあ",e:"Aria the Melodious Diva",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：特殊召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの「幻奏」モンスターは効果の対象にならず、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女ソナタ",h:"げんそうのおとめそなた",e:"Sonata the Melodious Diva",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：自分フィールドに「幻奏」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：特殊召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの天使族モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音姫プロディジー・モーツァルト",h:"げんそうのおとひめぷろでぃじー・もーつぁると",e:"Mozarta the Melodious Maestra",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"このカードの効果を発動するターン、自分は光属性以外のモンスターを特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n手札から天使族・光属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バーバリアン・キング",h:"ばーばりあん・きんぐ",e:"Battleguard King",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:1100,tx:"①：１ターンに１度、このカード以外の自分フィールドの戦士族モンスターを任意の数だけリリースして発動できる。\nこのターン、このカードは通常の攻撃に加えて、この効果を発動するためにリリースしたモンスターの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 デネブ",h:"さてらないと　でねぶ",e:"Satellarknight Deneb",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「星因士 デネブ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星因士 デネブ」以外の「テラナイト」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 アルタイル",h:"さてらないと　あるたいる",e:"Satellarknight Altair",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"「星因士 アルタイル」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、「星因士 アルタイル」以外の自分の墓地の「テラナイト」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで「テラナイト」モンスター以外の自分フィールドのモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 ベガ",h:"さてらないと　べが",e:"Satellarknight Vega",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"「星因士 ベガ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\n手札から「星因士 ベガ」以外の「テラナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 シャム",h:"さてらないと　しゃむ",e:"Satellarknight Alsahm",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"「星因士 シャム」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 ウヌク",h:"さてらないと　うぬく",e:"Satellarknight Unukalhai",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"「星因士 ウヌク」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星因士 ウヌク」以外の「テラナイト」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドール・ファルコン",h:"しゃどーる・ふぁるこん",e:"Shaddoll Falco",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","チューナー","効果"],ma:"",tp:"",at:600,de:1400,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合、「シャドール・ファルコン」以外の自分の墓地の「シャドール」モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nこのカードを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドール・ヘッジホッグ",h:"しゃどーる・へっじほっぐ",e:"Shaddoll Hedgehog",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:200,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「シャドール」魔法・罠カード１枚を手札に加える。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキから「シャドール・ヘッジホッグ」以外の「シャドール」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドール・リザード",h:"しゃどーる・りざーど",e:"Shaddoll Squamata",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキから「シャドール・リザード」以外の「シャドール」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドール・ドラゴン",h:"しゃどーる・どらごん",e:"Shaddoll Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1900,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが効果で墓地へ送られた場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドール・ビースト",h:"しゃどーる・びーすと",e:"Shaddoll Beast",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:2200,de:1700,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合に発動できる。\n自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。\n②：このカードが効果で墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"炎竜星－シュンゲイ",h:"えんりゅうせい－しゅんげい",e:"Suanni, Fire of the Yang Zing",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"「炎竜星－シュンゲイ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「炎竜星－シュンゲイ」以外の「竜星」モンスター１体を守備表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、攻撃力・守備力が５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"地竜星－ヘイカン",h:"ちりゅうせい－へいかん",e:"Bi'an, Earth of the Yang Zing",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"「地竜星－ヘイカン」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「地竜星－ヘイカン」以外の「竜星」モンスター１体を守備表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"水竜星－ビシキ",h:"すいりゅうせい－びしき",e:"Bixi, Water of the Yang Zing",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"「水竜星－ビシキ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「水竜星－ビシキ」以外の「竜星」モンスター１体を攻撃表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"風竜星－ホロウ",h:"ふうりゅうせい－ほろう",e:"Pulao, Wind of the Yang Zing",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"「風竜星－ホロウ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「風竜星－ホロウ」以外の「竜星」モンスター１体を攻撃表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、魔法カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"光竜星－リフン",h:"こうりゅうせい－りふん",e:"Chiwen, Light of the Yang Zing",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「光竜星－リフン」以外の「竜星」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドの「竜星」モンスターが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－チャクラム",h:"あーてぃふぁくと－ちゃくらむ",e:"Artifact Chakram",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1900,de:2000,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：自分フィールドの魔法・罠カードを破壊する効果が発動した時、自分フィールドにセットされた魔法・罠カード１枚を持ち主の手札に戻して発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－ロンギヌス",h:"あーてぃふぁくと－ろんぎぬす",e:"Artifact Lancea",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:2300,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、手札・フィールドのこのカードをリリースして発動できる。\nこのターン、お互いにカードを除外できない。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモン・イーター",h:"でーもん・いーたー",e:"Nefarious Archfiend Eater of Nefariousness",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：「デーモン・イーター」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに魔法使い族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：相手エンドフェイズにこのカードが墓地に存在する場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"死の代行者 ウラヌス",h:"しのだいこうしゃ　うらぬす",e:"The Agent of Entropy - Uranus",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:2200,de:1200,tx:"①：フィールドに「天空の聖域」が存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「代行者」モンスター１体を墓地へ送る。\nこのカードのレベルは、そのモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式魔人デモリッシャー",h:"ぎしきまじんでもりっしゃー",e:"Djinn Demolisher of Rituals",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"①：儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、墓地のこのカードを除外できる。\n②：このカードを使用して儀式召喚したモンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"電池メン－角型",h:"でんちめん－かくがた",e:"Batteryman 9-Volt",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「電池メン－角型」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nデッキから「電池メン」モンスター１体を手札に加え、このカードの攻撃力・守備力を元々の倍にする。\n②：自分エンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"共振虫",h:"れぞなんす・いんせくと",e:"Resonance Insect",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"①：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキからレベル５以上の昆虫族モンスター１体を手札に加える。\n②：このカードが除外された場合に発動できる。\nデッキから「共振虫」以外の昆虫族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔導戦士 ブレイカー",h:"くろまどうせんし　ぶれいかー",e:"Breaker the Dark Magical Warrior",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"「黒魔導戦士 ブレイカー」の④の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを２つ置く。\n②：このカードがP召喚に成功した場合に発動する。\nこのカードに魔力カウンターを３つ置く。\n③：このカードの攻撃力は、このカードの魔力カウンターの数×４００アップする。\n④：このカードの魔力カウンターを１つ取り除き、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"烈風帝ライザー",h:"れっぷうていらいざー",e:"Raiza the Mega Monarch",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\n①：このカードがアドバンス召喚に成功した場合、フィールドのカード１枚と自分または相手の墓地のカード１枚を対象として発動する。\nそのカードを好きな順番で持ち主のデッキの一番上に戻す。\nこのカードが風属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●フィールドのカード１枚を対象として持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"どぐう",h:"どぐう",e:"Dogu",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"「どぐう」の効果は１ターンに１度しか使用できない。\n①：このカードが相手の効果で墓地へ送られたターンのエンドフェイズに発動できる。\n自分のフィールド・墓地に存在しないレベルのモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒュプノシスター",h:"ひゅぷのしすたー",e:"Hypnosister",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"①：お互いのPゾーンのカードの数によって、このカードは以下の効果を得る。\n\n●１枚以上：このカードの攻撃力・守備力は８００アップする。\n\n●２枚以上：このカードがP召喚されたモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。\n\n●３枚以上：相手フィールドのモンスターは攻撃可能な場合、このカードを攻撃しなければならない。\n\n●４枚：このカードが戦闘またはこのカードの効果でモンスターを破壊した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"リ・バイブル",h:"り・ばいぶる",e:"Re-Cover",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:300,tx:"「リ・バイブル」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分のエクストラデッキの枚数が相手よりも５枚以上少ない場合、２０００LPを払って発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００１",h:"ぶんぼーぐぜろぜろわん",e:"Deskbot 001",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードの攻撃力・守備力は、自分フィールドの機械族モンスターの数×５００アップする。\n②：このカードが墓地に存在し、フィールドに機械族モンスターが２体以上同時に特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"華麗なる密偵－C",h:"かれいなるすぱい－しー",e:"Spy-C-Spy",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1300,tx:"①：このカードが召喚に成功した場合に発動する。\n相手のエクストラデッキからランダムに１枚確認する。\n攻撃力２０００以上のモンスターだった場合、このカードの攻撃力は１０００アップする。\n攻撃力２０００未満のモンスターだった場合、その攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイトプリンス",h:"わいとぷりんす",e:"Wightprince",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードのカード名は、墓地に存在する限り「ワイト」として扱う。\n②：このカードが墓地へ送られた場合に発動できる。\n「ワイト」「ワイト夫人」１体ずつを手札・デッキから墓地へ送る。\n③：自分の墓地から、「ワイト」２体とこのカードを除外して発動できる。\nデッキから「ワイトキング」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エルシャドール・ミドラーシュ",h:"えるしゃどーる・みどらーしゅ",e:"El Shaddoll Winda",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:800,tx:"「シャドール」モンスター＋闇属性モンスター\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\n①：フィールドのこのカードは相手の効果では破壊されない。\n②：このカードがモンスターゾーンに存在する限り、その間はお互いに１ターンに１度しかモンスターを特殊召喚できない。\n③：このカードが墓地へ送られた場合、自分の墓地の「シャドール」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エルシャドール・ネフィリム",h:"えるしゃどーる・ねふぃりむ",e:"El Shaddoll Construct",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2500,tx:"「シャドール」モンスター＋光属性モンスター\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「シャドール」カード１枚を墓地へ送る。\n②：このカードが特殊召喚されたモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。\n③：このカードが墓地へ送られた場合、自分の墓地の「シャドール」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜姫神サフィラ",h:"りゅうきしんさふぃら",e:"Saffira, Queen of Dragons",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2400,tx:"「祝祷の聖歌」により降臨。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚したターンのエンドフェイズ及び、このカードがモンスターゾーンに存在し、手札・デッキから光属性モンスターが墓地へ送られたターンのエンドフェイズに、以下の効果から１つを選択して発動できる。\n\n●自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。\n\n●相手の手札をランダムに１枚選んで墓地へ捨てる。\n\n●自分の墓地の光属性モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"輝竜星－ショウフク",h:"きりゅうせい－しょうふく",e:"Baxia, Brightness of the Yang Zing",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2600,tx:"チューナー＋チューナー以外の幻竜族モンスター１体以上\n①：このカードがS召喚に成功した時、このカードのS素材とした幻竜族モンスターの元々の属性の種類の数まで、フィールドのカードを対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n②：１ターンに１度、自分フィールドのカード１枚と自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのフィールドのカードを破壊し、その墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生竜サンサーラ",h:"てんせいりゅうさんさーら",e:"Samsara, Dragon of Rebirth",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:100,de:2600,tx:"チューナー＋チューナー以外のモンスター１体以上\n「転生竜サンサーラ」の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが相手の効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合、「転生竜サンサーラ」以外の自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星輝士 デルタテロス",h:"すてらないと　でるたてろす",e:"Stellarknight Delteros",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル４モンスター×３\n①：X素材を持ったこのカードがモンスターゾーンに存在する限り、自分がモンスターの召喚・特殊召喚に成功した時には、相手は魔法・罠・モンスターの効果を発動できない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札・デッキから「テラナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"鳥銃士カステル",h:"ちょうじゅうしかすてる",e:"Castel, the Skyblaster Musketeer",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1500,tx:"レベル４モンスター×２\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\n②：このカードのX素材を２つ取り除き、このカード以外のフィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"カバーカーニバル",h:"かばーかーにばる",e:"Hippo Carnival",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「カバートークン」（獣族・地・星１・攻／守０）３体を特殊召喚する。\nこのトークンはリリースできない。\n「カバートークン」がモンスターゾーンに存在する限り、自分はエクストラデッキからモンスターを特殊召喚できない。\nこのカードの発動後、ターン終了時まで相手は「カバートークン」以外のモンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"蛮族の狂宴LV５",h:"ばんぞくのきょうえんれべる５",e:"Feast of the Wild LV5",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・墓地から戦士族・レベル５モンスターを２体まで選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは、効果が無効化され、このターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星輝士の因子",h:"すてらないとのいんし",e:"Stellarknight Alpha",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドの「テラナイト」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力・守備力は５００アップする。\n装備モンスターは相手のカードの効果を受けない。\n②：自分フィールドに「テラナイト」モンスター以外のモンスターが表側表示で存在する場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"天架ける星因士",h:"あまかけるさてらないと",e:"Satellarknight Skybridge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「天架ける星因士」は１ターンに１枚しか発動できない。\n①：自分フィールドの「テラナイト」モンスター１体を対象として発動できる。\nそのモンスターとカード名が異なる「テラナイト」モンスター１体をデッキから特殊召喚し、対象のモンスターを持ち主のデッキに戻す。\nこの効果で特殊召喚したモンスターが表側表示で存在する限り、自分は「テラナイト」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"影依融合",h:"しゃどーる・ふゅーじょん",e:"Shaddoll Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、「シャドール」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nEXデッキから特殊召喚されたモンスターが相手フィールドに存在する場合、自分のデッキのモンスターも融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"影牢の呪縛",h:"かげろうのじゅばく",e:"Curse of the Shadow Prison",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：「シャドール」モンスターが効果で墓地へ送られる度に、１体につき１つこのカードに魔石カウンターを置く。\n②：相手フィールドのモンスターの攻撃力は相手ターンの間、このカードの魔石カウンターの数×１００ダウンする。\n③：自分が「シャドール」融合モンスターを融合召喚する度に１度、このカードの魔石カウンターを３つ取り除いて相手フィールドの表側表示モンスター１体を融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"竜星の輝跡",h:"りゅうせいのきせき",e:"Yang Zing Path",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「竜星の輝跡」は１ターンに１枚しか発動できない。\n①：自分の墓地の「竜星」モンスター３体を対象として発動できる。\nそのモンスター３体をデッキに戻してシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜星の気脈",h:"りゅうせいのきみゃく",e:"Yang Zing Prana",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「竜星」モンスターの属性の種類の数によって以下の効果を得る。\n\n●２種類以上：自分フィールドの「竜星」モンスターの攻撃力は５００アップする。\n\n●３種類以上：自分フィールドの「竜星」モンスターが戦闘・効果で破壊される場合、代わりにこのカードを墓地へ送る事ができる。\n\n●４種類以上：相手はモンスターをセットできず、相手フィールドの表側表示モンスターは全て攻撃表示になる。\n\n●５種類以上：このカードを墓地へ送って発動できる。\nフィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"祝祷の聖歌",h:"しゅくとうのせいか",e:"Hymn of Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「竜姫神サフィラ」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が６以上になるようにモンスターをリリースし、手札から「竜姫神サフィラ」を儀式召喚する。\n②：自分フィールドの儀式モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻界突破",h:"げんかいとっぱ",e:"Dracocension",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドのドラゴン族モンスター１体をリリースして発動できる。\nリリースしたモンスターの元々のレベルと同じレベルの幻竜族モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターが戦闘で破壊したモンスターは墓地へ送らず持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力の泉",h:"まりょくのいずみ",e:"Magical Spring",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「魔力の泉」は１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示の魔法・罠カードの数だけ自分はデッキからドローする。\nその後、自分フィールドの表側表示の魔法・罠カードの数だけ自分の手札からカードを選んで捨てる。\nこのカードの発動後、次の相手ターンの終了時まで、相手フィールドの魔法・罠カードは破壊されず、発動と効果を無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"帝王の烈旋",h:"ていおうのれっせん",e:"The Monarchs Stormforth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はEXデッキからモンスターを特殊召喚できない。\n①：このターン、自分がモンスターをアドバンス召喚する場合に１度だけ、自分フィールドのモンスター１体の代わりに相手フィールドのモンスター１体をリリースできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ポップルアップ",h:"ぽっぷるあっぷ",e:"Pop-Up",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「ポップルアップ」は１ターンに１枚しか発動できない。\n①：相手のフィールドゾーンにカードが存在し、自分のフィールドゾーンにカードが存在しない場合に発動できる。\nデッキからフィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"バーバリアン・レイジ",h:"ばーばりあん・れいじ",e:"Battleguard Rage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの戦士族モンスター１体を対象としてこのカードを発動できる。\nそのモンスターの攻撃力は１０００アップし、そのモンスターが戦闘で破壊したモンスターは墓地へ送らず持ち主の手札に戻す。\n対象のモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"バーバリアン・ハウリング",h:"ばーばりあん・はうりんぐ",e:"Battleguard Howling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの戦士族モンスターが相手モンスターの効果の対象になった時、または相手モンスターの攻撃対象に選択された時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与え、そのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"神星なる波動",h:"しんせいなるはどう",e:"Stellarnova Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズ及び相手バトルフェイズにのみ、この効果を発動できる。\n手札から「テラナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神星なる因子",h:"しんせいなるいんし",e:"Stellarnova Alpha",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：モンスターの効果・魔法・罠カードが発動した時、自分フィールドの表側表示の「テラナイト」モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"堕ち影の蠢き",h:"おちかげのうごめき",e:"Sinister Shadow Games",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：デッキから「シャドール」カード１枚を墓地へ送る。\nその後、自分フィールドの裏側守備表示の「シャドール」モンスターを任意の数だけ選んで表側守備表示にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"影依の原核",h:"しゃどーるーつ",e:"Shaddoll Core",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（魔法使い族・闇・星９・攻１４５０／守１９５０）となり、モンスターゾーンに特殊召喚する。\nこの効果で特殊召喚されたこのカードを「シャドール」融合モンスターの融合素材とする場合、そのカードに記された属性の融合素材モンスターの代わりにできる。\nこのカードは罠カードとしても扱う。\n②：このカードが効果で墓地へ送られた場合、「影依の原核」以外の自分の墓地の「シャドール」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜星の具象化",h:"りゅうせいのぐしょうか",e:"Yang Zing Creation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドのモンスターが戦闘・効果で破壊された場合にこの効果を発動できる。\nデッキから「竜星」モンスター１体を特殊召喚する。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分はSモンスター以外のモンスターをエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜星の極み",h:"りゅうせいのきわみ",e:"Yang Zing Unleashed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、攻撃可能な相手モンスターは攻撃しなければならない。\n②：自分または相手のメインフェイズ及びバトルフェイズに魔法＆罠ゾーンに表側表示で存在するこのカードを墓地へ送ってこの効果を発動できる。\n「竜星」モンスター１体以上を含むモンスターを素材としてSモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖解呪",h:"ちぇーん・でぃすぺる",e:"Chain Dispel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：魔法・罠カードが発動した時に発動できる。\n発動したその魔法・罠カードのコントローラーのデッキから同名カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"真剣勝負",h:"しんけんしょうぶ",e:"Face-Off",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：ダメージステップにモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・バック",h:"ぺんでゅらむ・ばっく",e:"Pendulum Back",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のPゾーンにカードが２枚存在する場合、そのPスケールでP召喚可能なレベルを持つ、自分の墓地のモンスター２体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"時空の落とし穴",h:"じくうのおとしあな",e:"Time-Space Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が手札・エクストラデッキからモンスターを特殊召喚した時に発動できる。\n手札・エクストラデッキから特殊召喚されたそのモンスターを持ち主のデッキに戻す。\nその後、自分は戻したモンスターの数×１０００LPを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"出たら目",h:"でたらめ",e:"That Six",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分または相手がサイコロを振った場合、その内１つの目を以下の目として適用できる。\n\n●１・３・５が出た場合：６として扱う。\n\n●２・４・６が出た場合：１として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C 強襲のハルベルト",h:"ひろいっく・ちゃれんじゃー　きょうしゅうのはるべると",e:"Heroic Challenger - Assault Halberd",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが相手に戦闘ダメージを与えた時に発動できる。\nデッキから「ヒロイック」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"H・C サウザンド・ブレード",h:"ひろいっく・ちゃれんじゃー　さうざんど・ぶれーど",e:"Heroic Challenger - Thousand Blades",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1100,tx:"「H・C サウザンド・ブレード」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、手札から「ヒロイック」カード１枚を捨てて発動できる。\nデッキから「ヒロイック」モンスター１体を特殊召喚し、このカードを守備表示にする。\nこの効果の発動後、ターン終了時まで自分は「ヒロイック」モンスターしか特殊召喚できない。\n②：このカードが墓地に存在し、戦闘・効果で自分がダメージを受けた時に発動できる。\nこのカードを墓地から攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"光天使セプター",h:"ほーりー・らいとにんぐせぷたー",e:"Star Seraph Scepter",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「光天使セプター」以外の「光天使」モンスター１体を手札に加える。\n②：フィールドのこのカードを含むモンスター３体以上を素材としてX召喚したモンスターは以下の効果を得る。\n\n●このX召喚に成功した時、このカード以外のフィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローできる。",li:"",lk:[],ta:[],al:[]},
  {n:"光天使スケール",h:"ほーりー・らいとにんぐすけーる",e:"Star Seraph Scale",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"①：このカードが特殊召喚に成功した時に発動できる。\n手札から「光天使」モンスター１体を特殊召喚する。\nその後、自分の墓地の光属性モンスター１体を選んでデッキの一番上に置く事ができる。\n②：フィールドのこのカードを含むモンスター３体以上を素材としてX召喚したモンスターは以下の効果を得る。\n\n●X素材を持っているこのカードがモンスターゾーンに存在する限り、自分または相手が手札からモンスターを特殊召喚する度に、自分はデッキから１枚ドローする。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"光天使スローネ",h:"ほーりー・らいとにんぐすろーね",e:"Star Seraph Sovereignty",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードをX召喚の素材とする場合、モンスター３体以上を素材としたX召喚にしか使用できない。\n①：自分が「光天使」モンスターの召喚・特殊召喚に成功した場合に発動できる。\nこのカードを手札から特殊召喚し、自分はデッキから１枚ドローする。\nそのドローしたカードが「光天使」モンスターだった場合、そのモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナンバーズハンター",h:"なんばーずはんたー",e:"Numeral Hunter",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動する。\nフィールドの「No.」Xモンスターを全て持ち主のエクストラデッキに戻す。\n②：このカードがモンスターゾーンに存在する限り、お互いに「No.」Xモンスターを特殊召喚できない。\nまた、このカードはXモンスターとの戦闘では破壊されず、Xモンスターの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.86 H－C ロンゴミアント",h:"なんばーず８６ ひろいっく－ちゃんぴおん ろんごみあんと",e:"Number 86: Heroic Champion - Rhongomyniad",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1500,tx:"戦士族レベル４モンスター×２体以上（最大５体まで）\n①：相手エンドフェイズに発動する。\nこのカードのX素材を１つ取り除く。\n②：このカードのX素材の数によって、このカードは以下の効果を得る。\n\n●１つ以上：このカードは戦闘では破壊されない。\n\n●２つ以上：このカードの攻撃力・守備力は１５００アップする。\n\n●３つ以上：このカードは他のカードの効果を受けない。\n\n●４つ以上：相手はモンスターを召喚・特殊召喚できない。\n\n●５つ以上：１ターンに１度、発動できる。\n相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鍵魔人ハミハミハミング",h:"けんまじんはみはみはみんぐ",e:"Humhumming the Key Djinn",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1100,de:1800,tx:"レベル３モンスター×２\n①：このカードが特殊召喚に成功した時、自分の墓地の「魔人」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、手札を２枚までそのモンスターの下に重ねてX素材にできる。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの「魔人」Xモンスター１体を対象として発動できる。\nこのターン、そのモンスターは相手に直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"希望郷－オノマトピア－",h:"きぼうきょう－おのまとぴあ－",e:"Onomatopia",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分フィールドに「希望皇ホープ」モンスターが特殊召喚される度に、このカードにかっとビングカウンターを１つ置く。\n②：自分フィールドのモンスターの攻撃力・守備力は、このカードのかっとビングカウンターの数×２００アップする。\n③：１ターンに１度、このカードのかっとビングカウンターを２つ取り除いて発動できる。\nデッキから「ズババ」、「ガガガ」、「ゴゴゴ」、「ドドド」モンスターの内いずれか１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マーシャリング・フィールド",h:"まーしゃりんぐ・ふぃーるど",e:"Marshalling Field",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分は機械族モンスターしか特殊召喚できない。\n②：１ターンに１度、５～９までの任意のレベルを宣言して発動できる。\n自分フィールドの全てのレベル５以上の機械族モンスターは宣言したレベルになる。\n③：自分フィールドの機械族Xモンスターが破壊される場合、代わりにこのカードを墓地へ送る事ができる。\n④：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「RUM－アージェント・カオス・フォース」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMシルバー・クロウ",h:"えんためいとしるばー・くろう",e:"Performapal Silver Claw",t:"monster",a:"闇",l:4,ps:5,pe:"①：自分フィールドの「EM」モンスターの攻撃力は３００アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:700,tx:"①：このカードの攻撃宣言時に発動する。\n自分フィールドの「EM」モンスターの攻撃力はバトルフェイズ終了時まで３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロードの神域",h:"らいとろーどのしんいき",e:"Lightsworn Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札の「ライトロード」モンスター１体を墓地へ送り、そのモンスター以外の自分の墓地の「ライトロード」モンスター１体を対象として発動できる。\n対象のモンスターを手札に加える。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分のデッキからカードが墓地へ送られる度に、このカードにシャインカウンターを１つ置く。\n③：自分フィールドの「ライトロード」カードが効果で破壊される場合、代わりに自分フィールドのシャインカウンターを、破壊される「ライトロード」カード１枚につき２つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"プリミティブ・バタフライ",h:"ぷりみてぃぶ・ばたふらい",e:"Primitive Butterfly",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドの全ての昆虫族モンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・アンカー",h:"じゃんく・あんかー",e:"Junk Anchor",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは「シンクロン」チューナーの代わりとしてS素材にできる。\n①：１ターンに１度、手札を１枚捨て、チューナー以外の自分の墓地の「ジャンク」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、そのモンスターとこのカードのみを素材として、「シンクロン」チューナーを素材とするSモンスター１体をS召喚する。\nその時のS素材モンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"No.99 希望皇龍ホープドラグーン",h:"なんばーず９９　きぼうおうりゅうほーぷどらぐーん",e:"Number 99: Utopic Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:2000,tx:"レベル１０モンスター×３\nこのカードは手札の「RUM」魔法カード１枚を捨て、自分フィールドの「希望皇ホープ」モンスターの上に重ねてX召喚する事もできる。\n①：１ターンに１度、自分の墓地の「No.」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードを対象とするモンスターの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ナナナ",h:"ななな",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル７またはランク７モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"氷帝家臣エッシャー",h:"ひょうていかしんえっしゃー",e:"Escher the Frost Vassal",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"①：相手の魔法＆罠ゾーンにカードが２枚以上存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO シャドー・ミスト",h:"えれめんたるひーろー　しゃどー・みすと",e:"Elemental HERO Shadow Mist",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「チェンジ」速攻魔法カード１枚を手札に加える。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「E・HERO シャドー・ミスト」以外の「HERO」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マスク・チャージ",h:"ますく・ちゃーじ",e:"Mask Charge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の、「HERO」モンスター１体と「チェンジ」速攻魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"C・HERO カオス",h:"こんとらすとひーろー　かおす",e:"Contrast HERO Chaos",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2600,tx:"「M・HERO」モンスター×２\nこのカードはルール上「E・HERO」モンスターとしても扱う。\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、このカードの属性は「光」としても扱う。\n②：１ターンに１度、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO 光牙",h:"ますくどひーろー　こうが",e:"Masked HERO Koga",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1800,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードの攻撃力は相手フィールドのモンスターの数×５００アップする。\n②：１ターンに１度、自分の墓地の「HERO」モンスター１体を除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、この効果を発動するために除外したモンスターの攻撃力分ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO カミカゼ",h:"ますくどひーろー　かみかぜ",e:"Masked HERO Divine Wind",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:1900,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードは戦闘では破壊されない。\n②：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズにモンスター１体でしか攻撃できない。\n③：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO ダーク・ロウ",h:"ますくどひーろー　だーく・ろう",e:"Masked HERO Dark Law",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、相手の墓地へ送られるカードは墓地へは行かず除外される。\n②：１ターンに１度、相手がドローフェイズ以外でデッキからカードを手札に加えた場合に発動できる。\n相手の手札をランダムに１枚選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO Core",h:"えれめんたるひーろー　こあ",e:"Elemental HERO Core",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:2200,tx:"「E・HERO」モンスター×３\nこのカードは融合召喚でしか特殊召喚できない。\n①：１ターンに１度、このカードが攻撃対象になった時に発動できる。\nこのカードの攻撃力はそのダメージステップ終了時まで倍になる。\n②：このカードが戦闘を行ったバトルフェイズ終了時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：このカードが戦闘・効果で破壊された時、自分の墓地のレベル８以下の「E・HERO」融合モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜角の狩猟者",h:"りゅうかくのしゅりょうしゃ",e:"Dragon Horn Hunter",t:"monster",a:"闇",l:6,ps:3,pe:"①：フィールドの通常モンスターの攻撃力は２００アップし、自分の通常モンスターの戦闘で発生する自分への戦闘ダメージは０になる。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2300,de:1000,tx:"疫病に苦しむ故郷の村を救うため、霊薬の原料となるドラゴンの角を乱獲する女戦士。\n\nその村はすでに、棲み処を追われたドラゴンたちによって踏み荒らされ、焼き尽くされてしまった事を、彼女はまだ知らない・・・。",li:"",lk:[],ta:[],al:[]},
  {n:"凶星の魔術師",h:"きょうせいのまじゅつし",e:"Doomstar Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"①：１ターンに１度、手札を１枚捨て、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 スカラマリオン",h:"ひがんのあっき　すからまりおん",e:"Scarm, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「彼岸の悪鬼 スカラマリオン」以外の悪魔族・闇属性・レベル３モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 グラバースニッチ",h:"ひがんのあっき　ぐらばーすにっち",e:"Graff, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「彼岸の悪鬼 グラバースニッチ」以外の「彼岸」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 ガトルホッグ",h:"ひがんのあっき　がとるほっぐ",e:"Cir, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「彼岸の悪鬼 ガトルホッグ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、「彼岸の悪鬼 ガトルホッグ」以外の自分の墓地の「彼岸」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の旅人 ダンテ",h:"ひがんのたびびと　だんて",e:"Dante, Traveler of the Burning Abyss",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:2500,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分のデッキの上からカードを３枚まで墓地へ送って発動できる。\nこのカードの攻撃力はターン終了時まで、この効果を発動するために墓地へ送ったカードの数×５００アップする。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n③：このカードが墓地へ送られた場合、このカード以外の自分の墓地の「彼岸」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"旅人の到彼岸",h:"たびびとのとうひがん",e:"The Traveler and the Burning Abyss",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「旅人の到彼岸」は１ターンに１枚しか発動できない。\n①：自分の墓地の、このターン墓地へ送られた「彼岸」モンスターを任意の数だけ対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.マイティスラッガー",h:"うるとらあすりーとまいてぃすらっがー",e:"U.A. Mighty Slugger",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:700,tx:"「U.A.マイティスラッガー」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.マイティスラッガー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.パーフェクトエース",h:"うるとらあすりーとぱーふぇくとえーす",e:"U.A. Perfect Ace",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:2500,tx:"「U.A.パーフェクトエース」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.パーフェクトエース」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：相手ターンに１度、魔法・罠・モンスターの効果が発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.スタジアム",h:"うるとらあすりーとすたじあむ",e:"U.A. Stadium",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「U.A.」モンスターが召喚された場合に発動できる。\nデッキから「U.A.」モンスター１体を手札に加える。\n②：１ターンに１度、自分フィールドに「U.A.」モンスターが特殊召喚された場合に発動する。\n自分フィールドのモンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD制覇王カイゼル",h:"でぃーでぃーでぃーせいはおうかいぜる",e:"D/D/D Supreme King Kaiser",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"①：このカードがP召喚に成功した場合に発動する。\n相手フィールドの表側表示のカードの効果はターン終了時まで無効になる。\n②：このカードがP召喚に成功したターンのメインフェイズに１度、自分の魔法＆罠ゾーンのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\nこのターン、このカードは通常の攻撃に加えて、この効果で破壊したカードの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"DDプラウド・オーガ",h:"でぃーでぃーぷらうど・おーが",e:"D/D Proud Ogre",t:"monster",a:"闇",l:6,ps:8,pe:"①：１ターンに１度、５００LPを払い、自分フィールドの「DD」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００アップする。\n②：もう片方の自分のPゾーンに「DD」カードが存在しない場合、このカードのPスケールは５になる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2300,de:1500,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のエクストラデッキから、表側表示の闇属性Pモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、この効果の発動後、ターン終了時まで自分は「DD」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"DDプラウド・シュバリエ",h:"でぃーでぃーぷらうど・しゅばりえ",e:"D/D Proud Chevalier",t:"monster",a:"闇",l:5,ps:6,pe:"①：１ターンに１度、５００LPを払い、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００ダウンする。\n②：もう片方の自分のPゾーンに「DD」カードが存在しない場合、このカードのPスケールは５になる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:700,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のエクストラデッキから、表側表示の闇属性Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMヘイタイガー",h:"えんためいとへいたいがー",e:"Performapal Salutiger",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"「EMヘイタイガー」の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「EM」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMジンライノ",h:"えんためいとじんらいの",e:"Performapal Thunderhino",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:200,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、相手は他の「EM」モンスターを攻撃対象に選択できない。\n②：このカードが墓地に存在し、「EMジンライノ」以外の自分フィールドの「EM」カードが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMチアモール",h:"えんためいとちあもーる",e:"Performapal Cheermole",t:"monster",a:"地",l:2,ps:5,pe:"①：自分フィールドのPモンスターの攻撃力は３００アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:600,de:1000,tx:"「EMチアモール」のモンスター効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに元々の攻撃力と異なる攻撃力を持つモンスター１体を対象として発動できる。\nそのモンスターの攻撃力の数値によって以下の効果を適用する。\n●そのモンスターの攻撃力が元々の攻撃力より高い場合、そのモンスターの攻撃力は１０００アップする。\n●そのモンスターの攻撃力が元々の攻撃力より低い場合、そのモンスターの攻撃力は１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMトランポリンクス",h:"えんためいととらんぽりんくす",e:"Performapal Trampolynx",t:"monster",a:"地",l:2,ps:4,pe:"「EMトランポリンクス」のP効果は１ターンに１度しか使用できない。\n①：自分がP召喚に成功した時、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードが召喚に成功した時、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ブロック・スパイダー",h:"ぶろっく・すぱいだー",e:"Block Spider",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"「ブロック・スパイダー」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手は他の昆虫族モンスターを攻撃対象にできない。\n②：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ブロック・スパイダー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女カノン",h:"げんそうのおとめかのん",e:"Canon the Melodious Diva",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:2000,tx:"「幻奏の音女カノン」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「幻奏」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、自分フィールドの「幻奏」モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女セレナ",h:"げんそうのおとめせれな",e:"Serenade the Melodious Diva",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:400,de:1900,tx:"①：天使族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n②：このカードが特殊召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「幻奏」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女エレジー",h:"げんそうのおとめえれじー",e:"Elegy the Melodious Diva",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、自分フィールドの特殊召喚された「幻奏」モンスターは効果では破壊されない。\n②：特殊召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの天使族モンスターの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音姫ローリイット・フランソワ",h:"げんそうのおとひめろーりいっと・ふらんそわ",e:"Shopina the Melodious Maestra",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードの効果を発動するターン、自分は光属性以外のモンスターの効果を発動できない。\n①：１ターンに１度、自分の墓地の天使族・光属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者カブ－１０",h:"ちょうじゅうむしゃかぶ－と",e:"Superheavy Samurai Kabuto",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：相手がモンスターの特殊召喚に成功した場合に発動できる。\n自分フィールドの攻撃表示の「超重武者」モンスターは全て守備表示になり、その守備力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者テンB－N",h:"ちょうじゅうむしゃてんびん",e:"Superheavy Samurai Scales",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"「超重武者テンB－N」の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドにモンスターが２体以上存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合、「超重武者テンB－N」以外の自分の墓地のレベル４以下の「超重武者」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留ファイヤー・アーマー",h:"ちょうじゅうむしゃそうるふぁいやー・あーまー",e:"Superheavy Samurai Soulfire Suit",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターのレベルは５になる。\n②：このカードを手札から捨て、自分フィールドの守備表示の「超重武者」モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの守備力は８００ダウンし、戦闘・効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留グレート・ウォール",h:"ちょうじゅうむしゃそうるぐれーと・うぉーる",e:"Superheavy Samurai Soulshield Wall",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを守備力１２００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが攻撃対象に選択された時、装備されているこのカードを墓地へ送って発動できる。\nその攻撃を無効にし、装備モンスターの守備力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留ブレイク・アーマー",h:"ちょうじゅうむしゃそうるぶれいく・あーまー",e:"Superheavy Samurai Soulbreaker Armor",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドのこのモンスターを守備力１０００ダウンの装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは戦闘では破壊されない。\n②：自分メインフェイズに、自分の墓地からこのカードを含む「超重武者装留ブレイク・アーマー」を全て除外し、元々の守備力よりも守備力が低い「超重武者」モンスター１体を対象として発動できる。\nそのモンスターの守備力と、その元々の守備力の差分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留ビッグバン",h:"ちょうじゅうむしゃそうるびっぐばん",e:"Superheavy Samurai Soulbang Cannon",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドのこのモンスターを守備力１０００アップの装備カード扱いとしてそのモンスターに装備する。\n②：自分フィールドに守備表示の「超重武者」モンスターが存在し、相手がバトルフェイズに魔法・罠・モンスターの効果を発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にし破壊する。\nその後、フィールドのモンスターを全て破壊し、お互いのプレイヤーは１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジインプ・シザー",h:"えっじいんぷ・しざー",e:"Edge Imp Sabres",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"「エッジインプ・シザー」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、手札を１枚デッキの一番上に戻して発動できる。\nこのカードを墓地から守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・ライオ",h:"ふぁーにまる・らいお",e:"Fluffal Leo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードの攻撃宣言時に発動する。\nこのカードの攻撃力はバトルフェイズ終了時まで５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・ベア",h:"ふぁーにまる・べあ",e:"Fluffal Bear",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"「ファーニマル・ベア」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から墓地へ送って発動できる。\nデッキから「トイポット」１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：このカードをリリースし、自分の墓地の「融合」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・ドッグ",h:"ふぁーにまる・どっぐ",e:"Fluffal Dog",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"「ファーニマル・ドッグ」の効果は１ターンに１度しか使用できない。\n①：このカードが手札からの召喚・特殊召喚に成功した時に発動できる。\nデッキから「エッジインプ・シザー」１体または「ファーニマル・ドッグ」以外の「ファーニマル」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・オウル",h:"ふぁーにまる・おうる",e:"Fluffal Owl",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「ファーニマル・オウル」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが手札からの召喚・特殊召喚に成功した時に発動できる。\nデッキから「融合」１枚を手札に加える。\n②：５００LPを払って発動できる。\n自分の手札・フィールドから、「デストーイ」融合モンスターによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・キャット",h:"ふぁーにまる・きゃっと",e:"Fluffal Cat",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:700,de:300,tx:"「ファーニマル・キャット」の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚の素材となって墓地へ送られた場合、自分の墓地の「融合」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・ラビット",h:"ふぁーにまる・らびっと",e:"Fluffal Rabbit",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"「ファーニマル・ラビット」の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚の素材となって墓地へ送られた場合、自分の墓地の、「エッジインプ・シザー」１体または「ファーニマル・ラビット」以外の「ファーニマル」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・ツール",h:"くりふぉーと・つーる",e:"Qliphort Scout",t:"monster",a:"地",l:5,ps:9,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、８００LPを払って発動できる。\nデッキから「クリフォート・ツール」以外の「クリフォート」カード１枚を手札に加える。",tr:"機械族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1000,de:2800,tx:"システムをレプリカモードで起動する準備をしています...\nC：\\sophia\\sefiroth.exe 実行中にエラーが発生しました。\n\n次の不明な発行元からのプログラムを実行しようとしています。\n\nC：\\tierra\\qliphoth.exe の実行を許可しますか？ ＜Y／N＞...［Y］\nシステムを自律モードで起動します。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・アーカイブ",h:"くりふぉーと・あーかいぶ",e:"Qliphort Carrier",t:"monster",a:"地",l:6,ps:1,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：自分フィールドの「クリフォート」モンスターの攻撃力は３００アップする。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードはリリースなしで召喚できる。\n②：特殊召喚またはリリースなしで召喚したこのカードのレベルは４になり、元々の攻撃力は１８００になる。\n③：通常召喚したこのカードは、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果を受けない。\n④：このカードがリリースされた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・ゲノム",h:"くりふぉーと・げのむ",e:"Qliphort Helix",t:"monster",a:"地",l:6,ps:9,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：相手フィールドのモンスターの攻撃力は３００ダウンする。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードはリリースなしで召喚できる。\n②：特殊召喚またはリリースなしで召喚したこのカードのレベルは４になり、元々の攻撃力は１８００になる。\n③：通常召喚したこのカードは、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果を受けない。\n④：このカードがリリースされた場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・ディスク",h:"くりふぉーと・でぃすく",e:"Qliphort Disk",t:"monster",a:"地",l:7,ps:1,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：自分フィールドの「クリフォート」モンスターの攻撃力は３００アップする。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:1000,tx:"①：このカードはリリースなしで召喚できる。\n②：特殊召喚またはリリースなしで召喚したこのカードのレベルは４になり、元々の攻撃力は１８００になる。\n③：通常召喚したこのカードは、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果を受けない。\n④：「クリフォート」モンスターをリリースしてこのカードのアドバンス召喚に成功した時に発動できる。\nデッキから「クリフォート」モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・シェル",h:"くりふぉーと・しぇる",e:"Qliphort Shell",t:"monster",a:"地",l:8,ps:9,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：相手フィールドのモンスターの攻撃力は３００ダウンする。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:1000,tx:"①：このカードはリリースなしで召喚できる。\n②：特殊召喚またはリリースなしで召喚したこのカードのレベルは４になり、元々の攻撃力は１８００になる。\n③：通常召喚したこのカードは、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果を受けない。\n④：「クリフォート」モンスターをリリースして表側表示でアドバンス召喚に成功した場合、このカードは１度のバトルフェイズ中に２回攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アポクリフォート・キラー",h:"あぽくりふぉーと・きらー",e:"Apoqliphort Towers",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:2600,tx:"このカードは特殊召喚できず、自分フィールドの「クリフォート」モンスター３体をリリースした場合のみ通常召喚できる。\n①：通常召喚したこのカードは魔法・罠カードの効果を受けず、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果も受けない。\n②：このカードがモンスターゾーンに存在する限り、特殊召喚されたモンスターの攻撃力・守備力は５００ダウンする。\n③：１ターンに１度、自分メインフェイズに発動できる。\n相手は自身の手札・フィールドのモンスター１体を墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 シリウス",h:"さてらないと　しりうす",e:"Satellarknight Sirius",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"「星因士 シリウス」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、自分の墓地の「テラナイト」モンスター５体を対象として発動できる。\nそのモンスター５体をデッキに戻してシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 プロキオン",h:"さてらないと　ぷろきおん",e:"Satellarknight Procyon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"「星因士 プロキオン」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\n手札の「テラナイト」モンスター１体を墓地へ送り、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 ベテルギウス",h:"さてらないと　べてるぎうす",e:"Satellarknight Betelgeuse",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:700,de:1900,tx:"「星因士 ベテルギウス」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、「星因士 ベテルギウス」以外の自分の墓地の「テラナイト」カード１枚を対象として発動できる。\nこのカードを墓地へ送り、対象のカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドール・ハウンド",h:"しゃどーる・はうんど",e:"Shaddoll Hound",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:900,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合、自分の墓地の「シャドール」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが効果で墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\nこの時、「シャドール」モンスター以外のリバースモンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔竜星－トウテツ",h:"まりゅうせい－とうてつ",e:"Taotie, Shadow of the Yang Zing",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"「魔竜星－トウテツ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「魔竜星－トウテツ」以外の「竜星」モンスター１体を守備表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、コントロールを変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"闇竜星－ジョクト",h:"あんりゅうせい－じょくと",e:"Jiaotu, Darkness of the Yang Zing",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"「闇竜星－ジョクト」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「闇竜星－ジョクト」以外の「竜星」モンスター１体を攻撃表示で特殊召喚する。\n②：自分フィールドにこのカード以外のモンスターが存在しない場合、手札の「竜星」カード２枚を墓地へ送って発動できる。\nデッキから攻撃力０と守備力０の「竜星」モンスターを１体ずつ特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"竜胆ブルーム",h:"りんどぶるーむ",e:"Lindbloom",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"①：このカードがモンスターゾーンに存在し、自分のモンスターと相手のモンスターが戦闘を行うダメージ計算時に発動する。\nダメージステップ終了時まで、戦闘を行うそれぞれのモンスターの攻撃力は、それぞれの守備力と同じ数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナイト・ドラゴリッチ",h:"ないと・どらごりっち",e:"Night Dragolich",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"①：このカードがモンスターゾーンに存在する限り、幻竜族以外のデッキ・エクストラデッキから特殊召喚されている攻撃表示モンスターは守備表示になる。\n②：このカードがモンスターゾーンに存在する限り、幻竜族以外のデッキ・エクストラデッキから特殊召喚されたモンスターの守備力は、その元々の守備力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"破面竜",h:"ねいきっど・どらごん",e:"Unmasked Dragon",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから守備力１５００以下の幻竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・メガフォーム",h:"ましんなーず・めがふぉーむ",e:"Machina Megaform",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2600,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードをリリースして発動できる。\n手札・デッキから「マシンナーズ・メガフォーム」以外の「マシンナーズ」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する状態で、自分フィールドの「マシンナーズ・フォートレス」が自分の墓地へ送られた場合、その「マシンナーズ・フォートレス」１体を墓地から除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"轟雷帝ザボルグ",h:"ごうらいていざぼるぐ",e:"Zaborg the Mega Monarch",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\n①：このカードがアドバンス召喚に成功した場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊する。\n破壊したモンスターが光属性だった場合、その元々のレベルまたはランクの数だけ、お互いはそれぞれ自分のEXデッキからカードを選んで墓地へ送る。\nこのカードが光属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●墓地へ送る相手のカードは自分が選ぶ。",li:"",lk:[],ta:[],al:[]},
  {n:"森の聖獣 ヴァレリフォーン",h:"もりのせいじゅう　う゛ぁれりふぉーん",e:"Valerifawn, Mystical Beast of the Forest",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:900,tx:"「森の聖獣 ヴァレリフォーン」の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨て、「森の聖獣 ヴァレリフォーン」以外の自分の墓地のレベル２以下の獣族モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキューラット",h:"れすきゅーらっと",e:"Rescue Hamster",t:"monster",a:"地",l:4,ps:5,pe:"「レスキューラット」のP効果はデュエル中に１度しか使用できない。\n①：自分のPゾーンのこのカードを除外して発動できる。\n自分のエクストラデッキから表側表示の同名Pモンスター２体を手札に加える。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:100,tx:"①：このカードが召喚に成功したターン、自分のエクストラデッキに表側表示のレベル５以下のPモンスターが存在する場合、このカードをリリースして発動できる。\n自分のエクストラデッキから表側表示のレベル５以下のPモンスター１体を選び、デッキからその同名モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"番犬－ウォッチドッグ",h:"ばんけん－うぉっちどっぐ",e:"Watch Dog",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「番犬－ウォッチドッグ」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードが召喚に成功したターンのメインフェイズ２に手札の魔法カード１枚を墓地へ送って発動できる。\nデッキから永続魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"電光－雪花－",h:"でんこう－せっか－",e:"Denko Sekka",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードは特殊召喚できない。\n①：このカードがモンスターゾーンに存在し、自分フィールドにセットされた魔法・罠カードが存在しない場合、お互いに魔法・罠カードをセットできず、フィールドにセットされた魔法・罠カードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００２",h:"ぶんぼーぐぜろぜろつー",e:"Deskbot 002",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ブンボーグ」カード１枚を手札に加える。\n②：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの機械族モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"Ms.JUDGE",h:"みず・じゃっじ",e:"Ms. Judge",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"「Ms.JUDGE」の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手のカードの効果が発動した場合、その処理を行う時にコイントスを２回行い、２回とも表だった場合、その効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"おねだりゴブリン",h:"おねだりごぶりん",e:"Scrounging Goblin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手は手札を１枚このカードのコントローラーに渡してこの効果を無効にできる。\n渡さなかった場合、自分はデッキから「ゴブリン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"崇光なる宣告者",h:"あるてぃめっと・でくれあらー",e:"Herald of Ultimateness",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2000,de:3000,tx:"「宣告者の神託」により降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n①：手札から天使族モンスター１体を墓地へ送って以下の効果を発動できる。\n●相手がモンスターの効果・魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n●相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・シザー・ベアー",h:"ですとーい・しざー・べあー",e:"Frightfur Bear",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1800,tx:"「エッジインプ・シザー」＋「ファーニマル・ベア」\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターを攻撃力１０００アップの装備カード扱いとしてこのカードに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・シザー・ウルフ",h:"ですとーい・しざー・うるふ",e:"Frightfur Wolf",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1500,tx:"「エッジインプ・シザー」＋「ファーニマル」モンスター１体以上\nこのカードは上記のカードを融合素材にした融合召喚でのみ特殊召喚できる。\n①：このカードは、このカードの融合素材としたモンスターの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エルシャドール・エグリスタ",h:"えるしゃどーる・えぐりすた",e:"El Shaddoll Grysta",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2450,de:1950,tx:"「シャドール」モンスター＋炎属性モンスター\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。\nその後、手札から「シャドール」カード１枚を墓地へ送る。\n②：このカードが墓地へ送られた場合、自分の墓地の「シャドール」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エルシャドール・シェキナーガ",h:"えるしゃどーる・しぇきなーが",e:"El Shaddoll Shekhinaga",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:3000,tx:"「シャドール」モンスター＋地属性モンスター\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：特殊召喚されたモンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\nその後、手札から「シャドール」カード１枚を墓地へ送る。\n②：このカードが墓地へ送られた場合、自分の墓地の「シャドール」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"始祖竜ワイアーム",h:"しそりゅうわいあーむ",e:"First of the Dragons",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:2000,tx:"通常モンスター×２\nこのカードは融合召喚でしか特殊召喚できない。\n①：「始祖竜ワイアーム」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードはモンスターゾーンに存在する限り、通常モンスター以外のモンスターとの戦闘では破壊されず、このカード以外のモンスターの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"邪竜星－ガイザー",h:"じゃりゅうせい－がいざー",e:"Yazi, Evil of the Yang Zing",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードは相手の効果の対象にならない。\n②：自分フィールドの「竜星」モンスター１体と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから幻竜族モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"虹光の宣告者",h:"あーく・でくれあらー",e:"Herald of the Arc Light",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:600,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがモンスターゾーンに存在する限り、お互いの手札・デッキから墓地へ送られるモンスターは墓地へは行かず除外される。\n②：モンスターの効果・魔法・罠カードが発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから儀式モンスター１体または儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・リベリオン・エクシーズ・ドラゴン",h:"だーく・りべりおん・えくしーず・どらごん",e:"Dark Rebellion Xyz Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル４モンスター×２\n①：このカードのX素材を２つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を半分にし、その数値分このカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"星輝士 トライヴェール",h:"すてらないと　とらいう゛ぇーる",e:"Stellarknight Triverr",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:2500,tx:"レベル４「テラナイト」モンスター×３\nこのカードをX召喚するターン、自分は「テラナイト」モンスターしか特殊召喚できない。\n①：このカードがX召喚に成功した場合に発動する。\nこのカード以外のフィールドのカードを全て持ち主の手札に戻す。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手の手札をランダムに１枚選んで墓地へ送る。\n③：X素材を持ったこのカードが墓地へ送られた場合、自分の墓地の「テラナイト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンダー・バルーン",h:"わんだー・ばるーん",e:"Wonder Balloons",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札を任意の枚数墓地へ送って発動できる。\n墓地へ送ったその枚数分だけ、このカードにバルーンカウンターを置く。\n②：このカードが魔法＆罠ゾーンに存在する限り、相手フィールドのモンスターの攻撃力は、このカードのバルーンカウンターの数×３００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"モノマネンド",h:"ものまねんど",e:"Mimiclay",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「モノマネンド」は１ターンに１枚しか発動できない。\n①：相手フィールドにモンスターが存在する場合、自分フィールドのレベル２以下の表側守備表示モンスター１体を対象として発動できる。\nその同名モンスター１体をデッキから守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ドロー・マッスル",h:"どろー・まっする",e:"Draw Muscle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「ドロー・マッスル」は１ターンに１枚しか発動できない。\n①：自分フィールドの守備力１０００以下の表側守備表示モンスター１体を対象として発動できる。\n自分はデッキから１枚ドローする。\nそのモンスターはこのターン戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・スター・イリュージョン",h:"まじかる・すたー・いりゅーじょん",e:"Magical Star Illusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターの数が相手フィールドのモンスターの数以下の場合に発動できる。\n自分及び相手フィールドのモンスターの攻撃力は、ターン終了時までそのモンスターのコントローラーのフィールドのモンスターのレベルの合計×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"独奏の第１楽章",h:"どくそうのだい１がくしょう",e:"1st Movement Solo",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「独奏の第１楽章」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「幻奏」モンスターしか特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\n手札・デッキからレベル４以下の「幻奏」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トイポット",h:"といぽっと",e:"Toy Vendor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札を１枚捨てて発動できる。\n自分はデッキから１枚ドローし、お互いに確認する。\n確認したカードが「ファーニマル」モンスターだった場合、手札からモンスター１体を特殊召喚できる。\n違った場合、そのドローしたカードを捨てる。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「エッジインプ・シザー」１体または「ファーニマル」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機殻の生贄",h:"さくりふぉーと",e:"Saqlifice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「クリフォート」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は３００アップし、戦闘では破壊されない。\n②：「クリフォート」モンスターをアドバンス召喚する場合、装備モンスターは２体分のリリースにできる。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「クリフォート」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機殻の要塞",h:"くりふぉーとれす",e:"Laser Qlip",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「クリフォート」モンスター１体を召喚できる。\n②：このカードがフィールドゾーンに存在する限り、「クリフォート」モンスターの召喚は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"星守る結界",h:"へきさ・てらないと",e:"Hexatellarknight",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「テラナイト」Xモンスターの攻撃力・守備力はそのX素材の数×２００アップする。\n②：自分フィールドの「テラナイト」Xモンスターが攻撃対象に選択された時、手札の「テラナイト」カード１枚を墓地へ送って発動できる。\nその攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"神の写し身との接触",h:"えるしゃどーる・ふゅーじょん",e:"El Shaddoll Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから「シャドール」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"異層空間",h:"いそうくうかん",e:"Celestia",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの幻竜族モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分の墓地の幻竜族モンスター３体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"宣告者の神託",h:"でくれあらー・おらくる",e:"Oracle of the Herald",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「崇光なる宣告者」の降臨に必要。\nこのカードの発動に対して相手は魔法・罠・モンスターの効果を発動できない。\n①：自分の手札・フィールドから、レベルの合計が１２以上になるようにモンスターをリリースし、手札から「崇光なる宣告者」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"帝王の轟毅",h:"ていおうのごうき",e:"Strike of the Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル５以上の通常召喚された表側表示モンスター１体をリリースし、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nその後、自分はデッキから１枚ドローする。\n②：自分メインフェイズに墓地のこのカードを除外し、属性を１つ宣言して発動できる。\nフィールドの全ての表側表示モンスターは、ターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"妖刀竹光",h:"ようとうたけみつ",e:"Cursed Bamboo Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力は０アップする。\n②：このカード以外の自分フィールドの「竹光」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、装備モンスターはこのターン相手に直接攻撃できる。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「妖刀竹光」以外の「竹光」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エンタメ・フラッシュ",h:"えんため・ふらっしゅ",e:"Command Performance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「EM」モンスターが存在する場合に発動できる。\n相手フィールドの表側攻撃表示モンスターは全て守備表示になり、そのモンスターは次のターンの終了時まで表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMリバイバル",h:"えんためいとりばいばる",e:"Performapal Revival",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが戦闘・効果で破壊された場合に発動できる。\n自分の手札・墓地から「EM」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"びっくり箱",h:"びっくりばこ",e:"Punch-in-the-Box",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドにモンスターが２体以上存在する場合、相手モンスターの攻撃宣言時にそのモンスター１体を対象として発動できる。\nその攻撃を無効にし、そのモンスター以外の相手フィールドのモンスター１体を選んで墓地へ送る。\nその後、墓地へ送られたモンスターの攻撃力と守備力の内、高い方の数値分だけ対象のモンスターの攻撃力をダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団シャドーベイル",h:"ふぁんとむ・ないつしゃどーべいる",e:"The Phantom Knights of Shadow Veil",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は３００アップする。\n②：このカードが墓地に存在する場合、相手の直接攻撃宣言時に発動できる。\nこのカードは通常モンスター（戦士族・闇・星４・攻０／守３００）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"隠されし機殻",h:"あぽくりふぉーと",e:"Qlimate Change",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「隠されし機殻」は１ターンに１枚しか発動できない。\n①：自分のエクストラデッキから表側表示の「クリフォート」Pモンスターを３体まで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"起動する機殻",h:"くりふぉーとらん",e:"Qlipper Launch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの通常召喚された「クリフォート」モンスターはターン終了時まで、攻撃力が３００アップし、効果は無効化され、このカード以外の魔法・罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜星の凶暴化",h:"りゅうせいのきょうぼうか",e:"Yang Zing Brutality",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の「竜星」モンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその自分のモンスターの攻撃力・守備力はそのダメージ計算時のみ元々の数値の倍になり、ダメージステップ終了時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュルの神星樹",h:"なちゅるのしんせいじゅ",e:"Naturia Sacred Tree",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「ナチュルの神星樹」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの昆虫族・地属性モンスター１体をリリースして発動できる。\nデッキからレベル４以下の植物族・地属性モンスター１体を特殊召喚する。\n②：自分フィールドの植物族・地属性モンスター１体をリリースして発動できる。\nデッキからレベル４以下の昆虫族・地属性モンスター１体を特殊召喚する。\n③：このカードが墓地へ送られた場合に発動する。\nデッキから「ナチュルの神星樹」以外の「ナチュル」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魂の幻泉",h:"りゅうこんのげんせん",e:"Oasis of Dragon Souls",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこのカードが魔法＆罠ゾーンに存在する限り、特殊召喚したそのモンスターの種族は幻竜族になる。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"融合準備",h:"ふゅーじょん・りざーぶ",e:"Fusion Reserve",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：EXデッキの融合モンスター１体を相手に見せ、そのモンスターにカード名が記されている融合素材モンスター１体をデッキから手札に加える。\nその後、自分の墓地から「融合」１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"神の忠告",h:"かみのちゅうこく",e:"Solemn Scolding",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンにセットされているカードがこのカードのみの場合、３０００LPを払って発動できる。\n\n●モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の邂逅",h:"いじげんのかいこう",e:"Different Dimension Encounter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いに、除外されている自分のモンスターが１体以上存在する場合に発動できる。\nお互いのプレイヤーは、それぞれ除外されている自分のモンスター１体を選んで裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"FNo.0 未来皇ホープ",h:"ふゅーちゃーなんばーず０ みらいおうほーぷ",e:"Number F0: Utopic Future",t:"monster",a:"光",l:"",ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"「No.」モンスター以外の同じランクのXモンスター×２\nルール上、このカードのランクは１として扱う。\n①：このカードは戦闘では破壊されず、このカードの戦闘で発生するお互いの戦闘ダメージは０になる。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に発動できる。\nその相手モンスターのコントロールをバトルフェイズ終了時まで得る。\n③：フィールドのこのカードが効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガザムライ",h:"がががざむらい",e:"Gagaga Samurai",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1600,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの「ガガガ」モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。\n②：このカード以外の自分のモンスターが攻撃対象に選択された時に発動できる。\nこのカードを表側守備表示にし、攻撃対象をこのカードに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガマンサー",h:"がががまんさー",e:"Gagaga Mancer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：１ターンに１度、「ガガガマンサー」以外の自分の墓地の「ガガガ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「ガガガ」モンスターしか特殊召喚できない。\n②：X素材のこのカードがXモンスターの効果を発動するために取り除かれ墓地へ送られた場合、自分フィールドのXモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガードゴー！",h:"がーどごー！",e:"Guard Go!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ガガガ」、「ドドド」、「ゴゴゴ」モンスターのいずれかが戦闘・効果で破壊され墓地へ送られた場合、そのモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、手札から「ガガガ」、「ドドド」、「ゴゴゴ」モンスターを合計２体まで守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"かっとビング・チャレンジ",h:"かっとびんぐ・ちゃれんじ",e:"Hi-Five the Sky",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分バトルフェイズに、このターン攻撃を行ったXモンスター１体を対象として発動できる。\nこのバトルフェイズ中、そのモンスターはもう１度だけ攻撃できる。\nこの効果でそのモンスターが攻撃する場合、ダメージステップ終了時まで相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"運命の扉",h:"うんめいのとびら",e:"The Door of Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時にこのカードを発動できる。\nその攻撃を無効にする。\nその後、このカードは効果モンスター（悪魔族・光・星１・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果でこのカードが特殊召喚されている場合、自分スタンバイフェイズに自分の墓地から「希望皇ホープ」モンスターを任意の数だけ除外して発動できる（同名カードは１枚まで）。\n除外した数×５００ダメージを相手に与え、そのダメージの数値分だけこのカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ブレイズマン",h:"えれめんたるひーろー　ぶれいずまん",e:"Elemental HERO Blazeman",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「融合」１枚を手札に加える。\n②：自分メインフェイズに発動できる。\nデッキから「E・HERO ブレイズマン」以外の「E・HERO」モンスター１体を墓地へ送る。\nこのカードはターン終了時まで、この効果で墓地へ送ったモンスターと同じ属性・攻撃力・守備力になる。\nこの効果の発動後、ターン終了時まで自分は融合モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ナチュル・ガイアストライオ",h:"なちゅる・がいあすとらいお",e:"Naturia Gaiastrio",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:2100,tx:"地属性Sモンスター×２\n①：フィールドのカード１枚のみを対象とする魔法・罠・モンスターの効果が発動した時、手札を１枚墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ヤクルスラーン",h:"げんじゅうきやくるすらーん",e:"Mecha Phantom Beast Jaculuslan",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"「幻獣機」チューナー＋チューナー以外の「幻獣機」モンスター１体以上\n①：このカードがS召喚に成功した時、自分フィールドの「幻獣機トークン」を任意の数だけリリースして発動できる。\nリリースした数だけ、相手の手札をランダムに選んで捨てる。\n②：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「幻獣機」モンスターは戦闘・効果では破壊されない。\n③：このカードが相手によって破壊された場合に発動できる。\nデッキから速攻魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリックの駄天使",h:"ごーすとりっくのだてんし",e:"Ghostrick Angel of Mischief",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル４モンスター×２\nこのカードは「ゴーストリックの駄天使」以外の自分フィールドの「ゴーストリック」Xモンスターの上にこのカードを重ねてX召喚する事もできる。\nまた、このカードが持っているX素材の数が１０になった時、自分はデュエルに勝利する。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから「ゴーストリック」魔法・罠カード１枚を手札に加える。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札の「ゴーストリック」カード１枚をこのカードの下に重ねてX素材にする。",li:"",lk:[],ta:[],al:[]},
  {n:"盾航戦車ステゴサイバー",h:"じゅんこうせんしゃすてごさいばー",e:"Stegocyber",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"「盾航戦車ステゴサイバー」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、相手モンスターが攻撃した場合、そのダメージ計算時に１０００LPを払って発動できる。\nこのカードを墓地から特殊召喚し、その戦闘で発生する自分への戦闘ダメージは０になる。\nこの効果で特殊召喚したこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"名匠 ガミル",h:"めいしょう　がみる",e:"Master Craftsman Gamil",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"①：自分のモンスターが戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nそのモンスターの攻撃力はターン終了時まで３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"カード・アドバンス",h:"かーど・あどばんす",e:"Card Advance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキの上からカードを５枚まで確認し、好きな順番でデッキの上に戻す。\nこのターン自分は通常召喚に加えて１度だけモンスター１体をアドバンス召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"シールドバッシュ",h:"しーるどばっしゅ",e:"Bashing Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"通常召喚されたモンスターにのみ装備可能。\n①：装備モンスターの攻撃力は１０００アップする。\n②：装備モンスターの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"鼓舞",h:"こぶ",e:"Inspiration",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"恐撃",h:"きょうげき",e:"Ghosts From the Past",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター２体を除外し、フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"不屈の闘志",h:"ふくつのとうし",e:"Unbreakable Spirit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスターが１体のみの場合、そのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、相手フィールドの攻撃力が一番低いモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"タイフーン",h:"たいふーん",e:"Typhoon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールドに魔法・罠カードが２枚以上存在し、自分フィールドに魔法・罠カードが存在しない場合、このカードの発動は手札からもできる。\n①：フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鏡像のスワンプマン",h:"きょうぞうのすわんぷまん",e:"Swamp Mirrorer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：種族と属性を１つずつ宣言して発動できる。\nこのカードは発動後、宣言した種族・属性を持つ通常モンスター（星４・攻１８００／守１０００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"量子猫",h:"りょうしねこ",e:"Quantum Cat",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：種族と属性を１つずつ宣言してこのカードを発動できる。\nこのカードは発動後、宣言した種族・属性を持つ通常モンスター（星４・攻０／守２２００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"P・M・キャプチャー",h:"ぴー・えむ・きゃぷちゃー",e:"P.M. Captor",t:"monster",a:"闇",l:4,ps:5,pe:"①：自分がアンデット族モンスターのP召喚に成功した時に発動できる。\nそのモンスターはこのターン戦闘・効果では破壊されない。",tr:"アンデット族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが戦闘でモンスターを破壊した場合、自分の墓地のPモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スピリチューアル・ウィスパー",h:"すぴりちゅーある・うぃすぱー",e:"Spiritual Whisper",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:200,de:2000,tx:"①：このカードは１ターンに１度だけ戦闘では破壊されない。\n②：このカードがP召喚に成功した時に発動できる。\nデッキから儀式モンスター１体または儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"X－レイ・ピアース",h:"えくしーず－れい・ぴあーす",e:"Xyz-Raypierce",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"「X－レイ・ピアース」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地からドラゴン族モンスターと幻竜族モンスターをそれぞれ１体ずつ除外して発動できる。\n手札・デッキから「X－レイ・ピアース」１体を特殊召喚する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古神クトグア",h:"こしんくとぐあ",e:"Old Entity Cthugua",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:200,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動できる。\nフィールドのランク４のXモンスターを全て持ち主のエクストラデッキに戻す。\n②：このカードを素材とした融合召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。\n③：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"禁断のトラペゾヘドロン",h:"きんだんのとらぺぞへどろん",e:"Forbidden Trapezohedron",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「禁断のトラペゾヘドロン」は１ターンに１枚しか発動できない。\n①：自分フィールドの融合・S・Xモンスターが、その内２種類のみの場合、その組み合わせによって以下の効果を適用する。\n\n●融合・Sモンスター：エクストラデッキから「外神」Xモンスター１体を特殊召喚し、このカードを下に重ねてX素材とする。\n\n●S・Xモンスター：エクストラデッキから「旧神」融合モンスター１体を特殊召喚する。\n\n●X・融合モンスター：エクストラデッキから「古神」Sモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDリリス",h:"でぃーでぃーりりす",e:"D/D Lilith",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"「DDリリス」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n\n●自分のエクストラデッキから、表側表示の「DD」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"DDナイト・ハウリング",h:"でぃーでぃーないと・はうりんぐ",e:"D/D Nighthowl",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:600,tx:"①：このカードが召喚に成功した時、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になり、そのモンスターが破壊された場合に自分は１０００ダメージを受ける。\nこの効果の発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"DD魔導賢者ガリレイ",h:"でぃーでぃーまどうけんじゃがりれい",e:"D/D Savant Galilei",t:"monster",a:"闇",l:10,ps:1,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：自分スタンバイフェイズに発動する。\nこのカードのPスケールを２つ上げる（最大１０まで）。\nその後、このカードのPスケール以下のレベルを持つ、「DD」モンスター以外の自分フィールドのモンスターを全て破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"「DD魔導賢者ガリレイ」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分フィールドの、「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"DD魔導賢者ケプラー",h:"でぃーでぃーまどうけんじゃけぷらー",e:"D/D Savant Kepler",t:"monster",a:"闇",l:1,ps:10,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：自分スタンバイフェイズに発動する。\nこのカードのPスケールを２つ下げる（最小１まで）。\nその後、このカードのPスケールの数値以上のレベルを持つ、「DD」モンスター以外の自分フィールドのモンスターを全て破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●このカード以外の自分フィールドの「DD」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n\n●デッキから「契約書」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD死偉王ヘル・アーマゲドン",h:"でぃーでぃーでぃーしいおうへる・あーまげどん",e:"D/D/D Doom King Armageddon",t:"monster",a:"闇",l:8,ps:4,pe:"①：１ターンに１度、自分フィールドの「DD」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで８００アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:1000,tx:"①：１ターンに１度、自分フィールドのモンスターが戦闘・効果で破壊された場合、そのモンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、対象のモンスターの元々の攻撃力分アップする。\nこの効果を発動するターン、このカードは直接攻撃できない。\n②：このカードは、このカードを対象としない魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD烈火王テムジン",h:"でぃーでぃーでぃーれっかおうてむじん",e:"D/D/D Flame King Genghis",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1500,tx:"「DD」モンスター×２\n「DDD烈火王テムジン」の①の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在し、自分フィールドにこのカード以外の「DD」モンスターが特殊召喚された場合、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが戦闘または相手の効果で破壊された場合、自分の墓地の「契約書」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD疾風王アレクサンダー",h:"でぃーでぃーでぃーしっぷうおうあれくさんだー",e:"D/D/D Gust King Alexander",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「DD」チューナー＋チューナー以外のモンスター１体以上\n「DDD疾風王アレクサンダー」の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在し、自分フィールドにこのカード以外の「DD」モンスターが召喚・特殊召喚された場合、自分の墓地のレベル４以下の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"地獄門の契約書",h:"じごくもんのけいやくしょ",e:"Dark Contract with the Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキから「DD」モンスター１体を手札に加える。\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神王の契約書",h:"ましんおうのけいやくしょ",e:"Dark Contract with the Swamp King",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「魔神王の契約書」の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札・フィールドから、悪魔族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n「DD」融合モンスターを融合召喚する場合、自分の墓地のモンスターを除外して融合素材とする事もできる。\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"戦乙女の契約書",h:"う゛ぁるきりーのけいやくしょ",e:"Dark Contract with the Witch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「戦乙女の契約書」の①の効果は１ターンに１度しか使用できない。\n①：手札から「DD」カードまたは「契約書」カードを１枚墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの悪魔族モンスターの攻撃力は、相手ターンの間１０００アップする。\n③：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"契約洗浄",h:"りーす・ろんだりんぐ",e:"Contract Laundering",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンの「契約書」カードを全て破壊する。\n破壊した数だけ自分はデッキからドローする。\nその後、自分はドローした数×１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDDの人事権",h:"でぃーでぃーでぃーのじんじけん",e:"D/D/D Human Resources",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札・フィールド・墓地の「DD」モンスター及び自分のPゾーンの「DD」カードの中から合計３枚選んで持ち主のデッキに戻す。\nその後、デッキから「DD」モンスター２体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"DDリクルート",h:"でぃーでぃーりくるーと",e:"D/D Recruits",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「DDリクルート」は１ターンに１枚しか発動できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、その差の数まで、自分の墓地の「DD」モンスターまたは「契約書」カードを対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"セイバー・リフレクト",h:"せいばー・りふれくと",e:"Saber Reflection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「セイバー・リフレクト」は１ターンに１枚しか発動できない。\n①：自分フィールドに「X－セイバー」モンスターが存在し、自分が戦闘・効果でダメージを受けた時に発動できる。\n受けたダメージの数値分だけ自分のLPを回復し、その数値分だけ相手にダメージを与える。\nその後、デッキから「セイバー」魔法・罠カード１枚または「ガトムズ」カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"吸光融合",h:"あぶそーぷ・ふゅーじょん",e:"Absorb Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「吸光融合」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「ジェムナイト」モンスターしか特殊召喚できない。\n①：デッキから「ジェムナイト」カード１枚を手札に加える。\nその後、以下の効果を適用できる。\n\n●自分の手札・フィールドから、「ジェムナイト」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・テンペスト",h:"せいくりっど・てんぺすと",e:"Constellar Tempest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「セイクリッド・テンペスト」の①の効果はデュエル中に１度しか使用できない。\n①：自分エンドフェイズに自分フィールドのX素材を持った「セイクリッド」Xモンスター２体を対象として発動できる。\nそのXモンスター２体のX素材を全て取り除き、相手LPを半分にする。\n②：自分スタンバイフェイズに自分フィールドの「セイクリッド」Xモンスター１体と自分の墓地の「セイクリッド」モンスター１体を対象として発動できる。\nその墓地のモンスターを、そのXモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"牙狼の双王 ロムルス－レムス",h:"がろうのそうおう　ろむるす－れむす",e:"The Twin Kings, Founders of the Empire",t:"monster",a:"光",l:8,ps:1,pe:"①：１ターンに１度、自分フィールドの表側表示の獣族Pモンスター３体を除外し、自分フィールドのPモンスター１体を対象として発動できる。\nこのターンそのモンスターが直接攻撃で相手のLPを０にした場合、自分はマッチに勝利する。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2200,tx:"このカードを通常召喚する場合、獣族モンスター３体をリリースして召喚しなければならない。\nこのカードはP召喚でしか特殊召喚できない。\n自分フィールドにこのカード以外のモンスターが存在せず、このカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"輝銀の天空船－レオ号",h:"きぎんのすかいしっぷ－れおごう",e:"Leonardo's Silver Skyship",t:"monster",a:"風",l:7,ps:10,pe:"①：１ターンに１度、自分フィールドの表側表示の機械族Pモンスター３体を除外し、自分フィールドのPモンスター１体を対象として発動できる。\nこのターンそのモンスターが直接攻撃で相手のLPを０にした場合、自分はマッチに勝利する。",tr:"機械族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:0,de:3000,tx:"輝銀の翼は栄光の印。\n \nその証は言葉によって語られる。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD怒濤王シーザー",h:"でぃーでぃーでぃーどとうおうしーざー",e:"D/D/D Wave King Caesar",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"悪魔族レベル４モンスター×２\n「DDD怒濤王シーザー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nこのターンに破壊されたモンスターをバトルフェイズ終了時に、自分の墓地から可能な限り特殊召喚する。\n次のスタンバイフェイズに自分はこの効果で特殊召喚したモンスターの数×１０００ダメージを受ける。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「契約書」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"DDケルベロス",h:"でぃーでぃーけるべろす",e:"D/D Cerberus",t:"monster",a:"闇",l:4,ps:6,pe:"①：１ターンに１度、自分フィールドの「DD」モンスター１体を対象として発動できる。\nそのモンスターのレベルを４にし、攻撃力・守備力を４００アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードが手札からのP召喚に成功した時、「DDケルベロス」以外の「DD」モンスターが自分フィールドに存在する場合に自分の墓地の永続魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.23 冥界の霊騎士ランスロット",h:"なんばーず２３ めいかいのれいきしらんすろっと",e:"Number 23: Lancelot, Dark Knight of the Underworld",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1500,tx:"レベル８モンスター×２\n①：X素材を持っているこのカードは直接攻撃できる。\n②：このカードが相手に戦闘ダメージを与えた時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：１ターンに１度、このカード以外のモンスターの効果・魔法・罠カードが発動した時、このカードのX素材を１つ取り除いて発動する。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD反骨王レオニダス",h:"でぃーでぃーでぃーはんこつおうれおにだす",e:"D/D/D Rebel King Leonidas",t:"monster",a:"闇",l:7,ps:3,pe:"①：自分が効果ダメージを受けた時にこの効果を発動できる。\nこのカードを破壊し、さらにこのターン、LPにダメージを与える効果は、LPを回復する効果になる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2600,de:1200,tx:"①：自分が効果ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のLPを回復する。\n②：このカードがモンスターゾーンに存在する限り、自分が受ける効果ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシーアイズ FA・フォトン・ドラゴン",h:"ぎゃらくしーあいず　ふるあーまー・ふぉとん・どらごん",e:"Galaxy-Eyes Full Armor Photon Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:3500,tx:"レベル８モンスター×３\nこのカードは「ギャラクシーアイズ FA・フォトン・ドラゴン」以外の自分フィールドの「ギャラクシーアイズ」Xモンスターの上にこのカードを重ねてX召喚する事もできる。\n①：１ターンに１度、このカードの装備カードを２枚まで対象として発動できる。\nそのカードをこのカードの下に重ねてX素材とする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"極星邪龍ヨルムンガンド",h:"きょくせいじゃりゅうよるむんがんど",e:"Jormungardr the Nordic Serpent",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは通常召喚できない。\nフィールドに「極神」モンスターが存在する場合に手札から相手フィールドに守備表示で特殊召喚できる。\n①：フィールドに「極神」モンスターが存在しない場合にこのカードは破壊される。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、表側守備表示のこのカードが表側攻撃表示になった場合に発動する。\n自分は３０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"極星邪狼フェンリル",h:"きょくせいじゃろうふぇんりる",e:"Fenrir the Nordic Wolf",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分メインフェイズ２に、フィールドに「極神」モンスターが存在する場合に手札から相手フィールドに守備表示で特殊召喚できる。\n①：フィールドに「極神」モンスターが存在しない場合にこのカードは破壊される。\n②：自分バトルフェイズ開始時に発動する。\n自分フィールドの守備表示モンスターを全て表側攻撃表示にする。\n③：このカードの戦闘で発生する戦闘ダメージはお互いのプレイヤーが受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"星屑の残光",h:"すたーだすとふらっしゅ",e:"Stardust Flash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「スターダスト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の預言者マーリン",h:"でんせつのよげんしゃまーりん",e:"Merlin",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:500,tx:"「伝説の預言者マーリン」の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nデッキから「聖騎士」モンスター１体を特殊召喚する。\nこの効果を発動するターン、自分は「聖騎士」モンスターしか特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\n「聖騎士」Sモンスター１体をS召喚する。\nこの効果は相手ターンでも発動できる。\n③：墓地のこのカードを除外して発動できる。\n「聖騎士」Xモンスター１体をX召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士ベディヴィエール",h:"せいきしべでぃう゛ぃえーる",e:"Noble Knight Bedwyr",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「聖剣」装備魔法カード１枚を墓地へ送る。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、フィールドの「聖剣」装備魔法カード１枚と、そのカードを装備可能なモンスター１体を対象として発動できる。\nその装備魔法カードを正しい対象となるそのモンスターに移し替える。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士伝説の終幕",h:"せいきしでんせつのしゅうまく",e:"Last Chapter of the Noble Knights",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「聖騎士伝説の終幕」は１ターンに１枚しか発動できない。\n①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、自分の墓地の、「聖騎士」モンスター１体とそのモンスターが装備可能な「聖剣」装備魔法カード１枚を対象として発動できる。\nそのモンスターを特殊召喚し、その装備魔法カードを正しい対象となるそのモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔妖仙獣 大刃禍是",h:"まようせんじゅう　だいばかぜ",e:"Mayosenju Daibak",t:"monster",a:"風",l:10,ps:7,pe:"①：自分フィールドの「妖仙獣」モンスターの攻撃宣言時に発動できる。\nその攻撃モンスターの攻撃力はバトルフェイズ終了時まで３００アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:300,tx:"このカードはP召喚でしか特殊召喚できない。\n①：このカードのP召喚は無効化されない。\n②：このカードが召喚・特殊召喚に成功した場合、フィールドのカードを２枚まで対象として発動できる。\nそのカードを持ち主の手札に戻す。\n③：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 閻魔巳裂",h:"ようせんじゅう　やまみさき",e:"Yosenju Misak",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2300,de:200,tx:"①：このカードが風属性以外の表側表示モンスターと戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターを破壊する。\n②：このカードがP召喚に成功した時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 鎌壱太刀",h:"ようせんじゅう　かまいたち",e:"Yosenju Kama 1",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"①：このカードが召喚に成功した場合に発動できる。\n手札から「妖仙獣 鎌壱太刀」以外の「妖仙獣」モンスター１体を召喚する。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、自分フィールドにこのカード以外の「妖仙獣」モンスターが存在する場合に相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 鎌弐太刀",h:"ようせんじゅう　かまにたち",e:"Yosenju Kama 2",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：このカードが召喚に成功した場合に発動できる。\n手札から「妖仙獣 鎌弐太刀」以外の「妖仙獣」モンスター１体を召喚する。\n②：このカードは直接攻撃できる。\nその戦闘によって相手に与える戦闘ダメージは半分になる。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 鎌参太刀",h:"ようせんじゅう　かまみたち",e:"Yosenju Kama 3",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"「妖仙獣 鎌参太刀」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動できる。\n手札から「妖仙獣 鎌参太刀」以外の「妖仙獣」モンスター１体を召喚する。\n②：このカード以外の自分の「妖仙獣」モンスターが相手に戦闘ダメージを与えた時に発動できる。\nデッキから「妖仙獣 鎌参太刀」以外の「妖仙獣」カード１枚を手札に加える。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 左鎌神柱",h:"ようせんじゅう　されんしんちゅう",e:"Yosenju Shinchu L",t:"monster",a:"風",l:4,ps:3,pe:"①：自分フィールドの「妖仙獣」モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"岩石族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2100,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、相手はこのカード以外の自分フィールドの「妖仙獣」モンスターを効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 右鎌神柱",h:"ようせんじゅう　うれんしんちゅう",e:"Yosenju Shinchu R",t:"monster",a:"風",l:4,ps:5,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「妖仙獣」カードが存在する場合に発動できる。\nこのカードのPスケールはターン終了時まで１１になる。\nこの効果の発動後、ターン終了時まで自分は「妖仙獣」モンスターしか特殊召喚できない。",tr:"岩石族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2100,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、相手は他の「妖仙獣」モンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"修験の妖社",h:"しゅげんのようしゃ",e:"Yosen Training Grounds",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「修験の妖社」の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、「妖仙獣」モンスターが召喚・特殊召喚される度に、このカードに妖仙カウンターを１つ置く。\n②：このカードの妖仙カウンターを任意の個数取り除いて発動できる。\n取り除いた数によって以下の効果を適用する。\n●１つ：自分フィールドの「妖仙獣」モンスターの攻撃力はターン終了時まで３００アップする。\n●３つ：自分のデッキ・墓地から「妖仙獣」カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣の秘技",h:"ようせんじゅうのひぎ",e:"Yosenjus' Secret Move",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「妖仙獣」カードが存在し、自分のモンスターゾーンに「妖仙獣」モンスター以外の表側表示モンスターが存在しない場合、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の術士 シュリット",h:"ねくろすのじゅつし　しゅりっと",e:"Shurit, Strategist of the Nekroz",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:1800,tx:"「影霊衣の術士 シュリット」の②の効果は１ターンに１度しか使用できない。\n①：「影霊衣」儀式モンスター１体を儀式召喚する場合、このカード１枚で儀式召喚に必要なレベル分のリリースとして使用できる。\n②：このカードが効果でリリースされた場合に発動できる。\nデッキから戦士族の「影霊衣」儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の大魔道士",h:"ねくろすのだいまどうし",e:"Great Sorcerer of the Nekroz",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"「影霊衣の大魔道士」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが効果でリリースされた場合に発動できる。\nデッキから魔法使い族の「影霊衣」儀式モンスター１体を手札に加える。\n②：このカードが除外された場合に発動できる。\nデッキから「影霊衣の大魔道士」以外の「影霊衣」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の戦士 エグザ",h:"ねくろすのせんし　えぐざ",e:"Exa, Enforcer of the Nekroz",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"「影霊衣の戦士 エグザ」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが効果でリリースされた場合に発動できる。\nデッキからドラゴン族の「影霊衣」儀式モンスター１体を手札に加える。\n②：このカードが除外された場合、このカード以外の除外されている自分の「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クラウソラスの影霊衣",h:"くらうそらすのねくろす",e:"Nekroz of Clausolas",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:1200,de:2300,tx:"「影霊衣」儀式魔法カードにより降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n「クラウソラスの影霊衣」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「影霊衣」魔法・罠カード１枚を手札に加える。\n②：エクストラデッキから特殊召喚された、フィールドのモンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力は０になり、効果は無効化される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリューナクの影霊衣",h:"ぶりゅーなくのねくろす",e:"Nekroz of Brionac",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:1400,tx:"「影霊衣」儀式魔法カードにより降臨。\n「ブリューナクの影霊衣」以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「ブリューナクの影霊衣」以外の「影霊衣」モンスター１体を手札に加える。\n②：EXデッキから特殊召喚されたフィールドのモンスターを２体まで対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"トリシューラの影霊衣",h:"とりしゅーらのねくろす",e:"Nekroz of Trishula",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:2000,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル９以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「影霊衣」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、このカードを手札から捨てて発動できる。\nその発動を無効にする。\n②：このカードが儀式召喚に成功した時に発動できる。\n相手の手札・フィールド・墓地のカードをそれぞれ１枚選び（手札からはランダムに選ぶ）、その３枚を除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニコールの影霊衣",h:"ゆにこーるのねくろす",e:"Nekroz of Unicore",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:1000,tx:"「影霊衣」儀式魔法カードにより降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、「ユニコールの影霊衣」以外の自分の墓地の「影霊衣」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードがモンスターゾーンに存在する限り、EXデッキから特殊召喚された表側表示モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァルキュルスの影霊衣",h:"う゛ぁるきゅるすのねくろす",e:"Nekroz of Valkyrus",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2900,de:1700,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル８以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「ヴァルキュルスの影霊衣」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時に自分の墓地の「影霊衣」カード１枚を除外し、このカードを手札から捨てて発動できる。\nその攻撃を無効にし、その後バトルフェイズを終了する。\n②：自分メインフェイズに発動できる。\n自分の手札・フィールドのモンスターを２体までリリースし、リリースした数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"カタストルの影霊衣",h:"かたすとるのねくろす",e:"Nekroz of Catastor",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2200,de:1200,tx:"「影霊衣」儀式魔法カードにより降臨。\n「カタストルの影霊衣」以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「カタストルの影霊衣」の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分の墓地の「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分の「影霊衣」モンスターがエクストラデッキから特殊召喚されたモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ディサイシブの影霊衣",h:"でぃさいしぶのねくろす",e:"Nekroz of Decisive Armor",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:3300,de:2300,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル１０以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「ディサイシブの影霊衣」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分フィールドの「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：相手フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊し除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の降魔鏡",h:"ねくろすのこうまきょう",e:"Nekroz Mirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「影霊衣」儀式モンスターの降臨に必要。\n「影霊衣の降魔鏡」の①の効果は１ターンに１度しか使用できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地の「影霊衣」モンスターを除外し、手札から「影霊衣」儀式モンスター１体を儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと「影霊衣」モンスター１体を除外して発動できる。\nデッキから「影霊衣」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の万華鏡",h:"ねくろすのまんげきょう",e:"Nekroz Kaleidoscope",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「影霊衣」儀式モンスターの降臨に必要。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスター１体をリリース、またはリリースの代わりにEXデッキのモンスター１体を墓地へ送り、手札から「影霊衣」儀式モンスターを任意の数だけ儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと「影霊衣」モンスター１体を除外して発動できる。\nデッキから「影霊衣」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"霊獣使い レラ",h:"れいじゅうつかい　れら",e:"Ritual Beast Tamer Lara",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"自分は「霊獣使い レラ」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚に成功した場合、自分の墓地の「霊獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊獣使いの長老",h:"れいじゅうつかいのちょうろう",e:"Ritual Beast Tamer Elder",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:200,de:1000,tx:"自分は「霊獣使いの長老」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「霊獣」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"霊獣使い ウェン",h:"れいじゅうつかい　うぇん",e:"Ritual Beast Tamer Wen",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分は「霊獣使い ウェン」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚に成功した場合、除外されている自分の「霊獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊獣 アペライオ",h:"せいれいじゅう　あぺらいお",e:"Spiritual Beast Apelio",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分は「精霊獣 アペライオ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分の墓地の「霊獣」カード１枚を除外して発動できる。\nこのターン中、以下の効果を適用する。\nこの効果は相手ターンでも発動できる。\n●自分フィールドの「霊獣」モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊獣 ペトルフィン",h:"せいれいじゅう　ぺとるふぃん",e:"Spiritual Beast Pettlephin",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分は「精霊獣 ペトルフィン」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、手札の「霊獣」カード１枚を除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊獣 カンナホーク",h:"せいれいじゅう　かんなほーく",e:"Spiritual Beast Cannahawk",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"自分は「精霊獣 カンナホーク」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「霊獣」カード１枚を除外する。\n発動後２回目の自分スタンバイフェイズに、この効果で除外したカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖霊獣騎 アペライオ",h:"せいれいじゅうき　あぺらいお",e:"Ritual Beast Ulti-Apelio",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:400,tx:"「霊獣使い」モンスター＋「精霊獣」モンスター\n自分フィールドの上記カードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：このカードは攻撃する場合、ダメージステップ終了時までこのカード以外のカードの効果を受けない。\n②：このカードを持ち主のEXデッキに戻し、除外されている自分の、「霊獣使い」モンスター１体と「精霊獣」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖霊獣騎 ペトルフィン",h:"せいれいじゅうき　ぺとるふぃん",e:"Ritual Beast Ulti-Pettlephin",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:200,de:2800,tx:"「霊獣使い」モンスター＋「精霊獣」モンスター\n自分フィールドの上記カードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：このカードは効果では破壊されない。\n②：このカードをエクストラデッキに戻し、除外されている自分の「霊獣使い」モンスター１体と「精霊獣」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖霊獣騎 カンナホーク",h:"せいれいじゅうき　かんなほーく",e:"Ritual Beast Ulti-Cannahawk",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:1600,tx:"「霊獣使い」モンスター＋「精霊獣」モンスター\n自分フィールドの上記カードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、除外されている自分の「霊獣」カード２枚を対象として発動できる。\nそのカードを墓地へ戻し、デッキから「霊獣」カード１枚を手札に加える。\n②：このカードを持ち主のEXデッキに戻し、除外されている自分の、「霊獣使い」モンスター１体と「精霊獣」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"霊獣の相絆",h:"れいじゅうのしょうばん",e:"Ritual Beast's Bond",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「霊獣」モンスター２体を除外して発動できる。\nエクストラデッキから「霊獣」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊獣の連契",h:"れいじゅうのれんけい",e:"Ritual Beast Steeds",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「霊獣」モンスターが存在する場合に発動できる。\n自分フィールドの「霊獣」モンスターの数まで、フィールドのモンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ランスフォリンクス",h:"らんすふぉりんくす",e:"Lancephorhynchus",t:"monster",a:"風",l:6,ps:7,pe:"①：自分の通常モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"恐竜族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2500,de:800,tx:"太古の絶滅を生き延びた幻の翼竜。\nその姿はより攻撃的に進化し、クチバシは全てを貫く槍と化した。\n・・・それでも主食は魚らしい。",li:"",lk:[],ta:[],al:[]},
  {n:"置換融合",h:"ちかんゆうごう",e:"Fusion Substitute",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードのカード名はルール上「融合」として扱う。\n①：自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外し、自分の墓地の融合モンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 ラビキャント",h:"ひがんのあっき　らびきゃんと",e:"Rubic, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:2100,tx:"「彼岸の悪鬼 ラビキャント」の①の効果は１ターンに１度しか使用できない。\nこのカードをS素材とする場合、「彼岸」モンスターのS召喚にしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 アリキーノ",h:"ひがんのあっき　ありきーの",e:"Alich, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"「彼岸の悪鬼 アリキーノ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 ハックルスパー",h:"ひがんのあっき　はっくるすぱー",e:"Calcab, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"「彼岸の悪鬼 ハックルスパー」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nセットされたそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の詩人 ウェルギリウス",h:"ひがんのしじん　うぇるぎりうす",e:"Virgil, Rock Star of the Burning Abyss",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「彼岸の詩人 ウェルギリウス」の③の効果は１ターンに１度しか使用できない。\n①：「彼岸の詩人 ウェルギリウス」は自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、手札から「彼岸」カード１枚を捨て、相手のフィールド・墓地のカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n③：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の沈溺",h:"ひがんのちんでき",e:"Fire Lake of the Burning Abyss",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示の「彼岸」モンスター２体を墓地へ送り、フィールドのカードを３枚まで対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.ファンタジスタ",h:"うるとらあすりーとふぁんたじすた",e:"U.A. Midfielder",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"「U.A.ファンタジスタ」の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは「U.A.ファンタジスタ」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカード以外の自分フィールドの「U.A.」モンスター１体を対象として発動できる。\nその表側表示モンスターを手札に戻し、その後そのモンスターとカード名が異なる「U.A.」モンスター１体を手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.カストディアン",h:"うるとらあすりーとかすとでぃあん",e:"U.A. Goalkeeper",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2800,tx:"「U.A.カストディアン」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.カストディアン」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：相手ターンに１度、自分フィールドの「U.A.」モンスター１体を対象として発動できる。\nそのモンスターはこのターンに１度だけ戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.パワードギプス",h:"うるとらあすりーとぱわーどぎぷす",e:"U.A. Powered Jersey",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「U.A.」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力・守備力は１０００アップし、装備モンスターが相手モンスターと戦闘を行う場合、相手に与える戦闘ダメージは倍になる。\n②：装備モンスターの攻撃でモンスターを破壊した場合に発動できる。\nこのバトルフェイズ中、装備モンスターはもう１度だけ攻撃できる。\n③：自分スタンバイフェイズに発動する。\n装備モンスターを除外する。\n④：装備モンスターが手札に戻る事でこのカードが墓地へ送られた場合に発動できる。\nこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ビーストアイズ・ペンデュラム・ドラゴン",h:"びーすとあいず・ぺんでゅらむ・どらごん",e:"Beast-Eyes Pendulum Dragon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"ドラゴン族・闇属性モンスター＋獣族モンスター\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●自分フィールドの上記カードをリリースした場合にエクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードが戦闘でモンスターを破壊した場合に発動する。\nこのカードの融合素材とした獣族モンスター１体の元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔王超龍 ベエルゼウス",h:"まおうちょうりゅう　べえるぜうす",e:"Beelzeus of the Diabolic Dragons",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"闇属性チューナー＋チューナー以外のモンスター２体以上\n①：このカードは戦闘・効果では破壊されない。\n②：このカードがモンスターゾーンに存在する限り、このカード以外の自分のモンスターは攻撃できない。\n③：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、その元々の攻撃力分だけ自分はLPを回復する。\nまた、このターンこのカードの戦闘によって発生する相手プレイヤーへの戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"業火の重騎士",h:"ごうかのじゅうきし",e:"Heavy Knight of the Flame",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが、特殊召喚されたモンスターを攻撃するダメージステップ開始時に発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"BOXサー",h:"ぼくさー",e:"BOXer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードにカウンターを１つ置く。\n②：カウンターが２つ以上置かれているこのカードを墓地へ送って発動できる。\nデッキから地属性モンスター１体を特殊召喚する。\n③：このカードが戦闘・効果で破壊される場合、代わりにこのカードのカウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カブキ・ドラゴン",h:"かぶき・どらごん",e:"Kabuki Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：１ターンに１度、自分のモンスターが相手のモンスターに攻撃したダメージ計算前に発動できる。\nその相手のモンスターの表示形式を変更する。\n②：１ターンに１度、相手のモンスターが自分のモンスターに攻撃したダメージ計算前に発動できる。\nその自分のモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"外神アザトート",h:"がいしんあざとーと",e:"Outer Entity Azathot",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:0,tx:"レベル５モンスター×３\nこのカードは自分フィールドの「外神」Xモンスターの上にこのカードを重ねてX召喚する事もできる。\nこのカードはX召喚の素材にできない。\n①：このカードがX召喚に成功したターン、相手はモンスターの効果を発動できない。\n②：このカードが融合・S・Xモンスターを全てX素材としている場合、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドのカードを全て破壊する。",li:0,lk:[],ta:[],al:[]},
  {n:"ペンデュラム・モラトリアム",h:"ぺんでゅらむ・もらとりあむ",e:"Pendulum Impenetrable",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターン、自分及び相手のPゾーンのカードは相手の効果では破壊されず、Pゾーンのカードを対象として相手が発動した効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"EMファイア・マフライオ",h:"えんためいとふぁいあ・まふらいお",e:"Performapal Fire Mufflerlion",t:"monster",a:"炎",l:3,ps:5,pe:"①：自分フィールドのPモンスターが戦闘で破壊された時に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:800,tx:"①：１ターンに１度、自分のPモンスターが相手モンスターを戦闘で破壊したダメージ計算後に発動できる。\nその自分のモンスターは、バトルフェイズ終了時まで攻撃力が２００アップし、もう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMパートナーガ",h:"えんためいとぱーとなーが",e:"Performapal Partnaga",t:"monster",a:"地",l:5,ps:3,pe:"①：１ターンに１度、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、自分フィールドの「EM」カードの数×３００アップする。",tr:"爬虫類族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:2100,tx:"①：このカードが召喚・特殊召喚に成功した場合、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力は自分フィールドの「EM」モンスターの数×３００アップする。\n②：このカードがモンスターゾーンに存在する限り、レベル５以下のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMフレンドンキー",h:"えんためいとふれんどんきー",e:"Performapal Friendonkey",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地からレベル４以下の「EM」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMスパイク・イーグル",h:"えんためいとすぱいく・いーぐる",e:"Performapal Spikeagle",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMプラスタートル",h:"えんためいとぷらすたーとる",e:"Performapal Stamp Turtle",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:1800,tx:"①：１ターンに１度、フィールドの表側表示モンスターを２体まで対象として発動できる。\nそのモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMトランプ・ウィッチ",h:"えんためいととらんぷ・うぃっち",e:"Performapal Trump Witch",t:"monster",a:"闇",l:1,ps:4,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードをリリースして発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者カゲボウ－C",h:"ちょうじゅうむしゃかげぼう－しー",e:"Superheavy Samurai Flutist",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"「超重武者カゲボウ－C」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n手札から「超重武者」モンスター１体を特殊召喚する。\n②：自分フィールドの「超重武者」モンスターが効果の対象になった時、墓地のこのカードを除外して発動できる。\nその発動を無効にし破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ホラガ－E",h:"ちょうじゅうむしゃほらが－いー",e:"Superheavy Samurai Trumpeter",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:600,tx:"①：自分の墓地に魔法・罠カードが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功したターン、自分は「超重武者」モンスターしか特殊召喚できない。\n②：自分の墓地に魔法・罠カードが存在せず、このカードをリリースして「超重武者」モンスターのアドバンス召喚に成功した場合に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留イワトオシ",h:"ちょうじゅうむしゃそうるいわとおし",e:"Superheavy Samurai Soulpiercer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「超重武者装留イワトオシ」以外の「超重武者」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留マカルガエシ",h:"ちょうじゅうむしゃそうるまかるがえし",e:"Superheavy Samurai Soulbeads",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターは、１ターンに１度だけ効果では破壊されない。\n③：守備表示モンスターが戦闘で破壊され自分の墓地へ送られた時、このカードを手札から墓地へ送って発動できる。\nそのモンスターを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－バニシング・レイニアス",h:"れいど・らぷたーず－ばにしんぐ・れいにあす",e:"Raidraptor - Vanishing Lanius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1600,tx:"①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに１度だけ発動できる。\n手札からレベル４以下の「RR」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイト・ラピス",h:"じぇむないと・らぴす",e:"Gem-Knight Lapis",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1200,de:100,tx:"仲間の健康を常に気づかう癒しの戦士。\nラズリーとは大の仲良しだ。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・ベルゼブル",h:"いんふぇるのいど・べるぜぶる",e:"Infernoid Antra",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター１体を除外した場合のみ手札から特殊召喚できる。\n①：１ターンに１度、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：相手ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・ルキフグス",h:"いんふぇるのいど・るきふぐす",e:"Infernoid Harmadik",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター１体を除外した場合のみ手札から特殊召喚できる。\n①：１ターンに１度、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこの効果を発動するターン、このカードは攻撃できない。\n②：相手ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・アスタロス",h:"いんふぇるのいど・あすたろす",e:"Infernoid Patrulea",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター１体を除外した場合のみ手札から特殊召喚できる。\n①：１ターンに１度、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果を発動するターン、このカードは攻撃できない。\n②：相手ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・アシュメダイ",h:"いんふぇるのいど・あしゅめだい",e:"Infernoid Piaty",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター２体を除外した場合のみ手札・墓地から特殊召喚できる。\n①：このカードが相手モンスターへの攻撃で相手に戦闘ダメージを与えた時に発動できる。\n相手の手札をランダムに１枚選んで墓地へ送る。\n②：１ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・ヴァエル",h:"いんふぇるのいど・う゛ぁえる",e:"Infernoid Seitsemas",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター２体を除外した場合のみ手札・墓地から特殊召喚できる。\n①：このカードが相手モンスターを攻撃したバトルフェイズ終了時に発動できる。\nフィールドのカード１枚を選んで除外する。\n②：１ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・アドラメレク",h:"いんふぇるのいど・あどらめれく",e:"Infernoid Attondel",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター２体を除外した場合のみ手札・墓地から特殊召喚できる。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。\n②：１ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・ネヘモス",h:"いんふぇるのいど・ねへもす",e:"Infernoid Onuncu",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター３体を除外した場合のみ手札・墓地から特殊召喚できる。\n①：このカードが特殊召喚に成功した時に発動できる。\nこのカード以外のフィールドのモンスターを全て破壊する。\n②：１ターンに１度、魔法・罠カードの効果が発動した時、自分フィールドのモンスター１体をリリースして発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・アセンブラ",h:"くりふぉーと・あせんぶら",e:"Qliphort Monolith",t:"monster",a:"地",l:5,ps:1,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：自分がアドバンス召喚に成功したターンのエンドフェイズに発動できる。\nこのターン自分がアドバンス召喚のためにリリースした「クリフォート」モンスターの数だけ、自分はデッキからドローする。",tr:"機械族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2400,de:1000,tx:"qliphoth.exe の ０x１i－６６６ でハンドルされていない例外を確認。\n\n場所 ０x００－０００ に書き込み中にアクセス違反が発生しました。\n\nこのエラーを無視し、続行しますか？ ＜Y／N＞...［ ］\n＝＝＝CARNAGE＝＝＝\nたッgなnトiのoモdる知rヲu悪o善yりナnにoウよyノrりgトnひaノれsワiれワdはo人Gヨ見\nイdなoレo知lもfカるeキr生iにf久永gベn食iてrッb取もoラtか木tノn命aべw伸ヲd手nはa彼",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・アクセス",h:"くりふぉーと・あくせす",e:"Qliphort Cephalopod",t:"monster",a:"地",l:7,ps:9,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：相手フィールドのモンスターの攻撃力は３００ダウンする。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:1000,tx:"①：このカードはリリースなしで召喚できる。\n②：特殊召喚またはリリースなしで召喚したこのカードのレベルは４になり、元々の攻撃力は１８００になる。\n③：通常召喚したこのカードは、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果を受けない。\n④：「クリフォート」モンスターをリリースしてこのカードのアドバンス召喚に成功した時に発動できる。\n相手の墓地のモンスターの数が自分の墓地のモンスターより多い場合、自分はその差×３００LP回復し、その数値分だけ相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"クリフォート・エイリアス",h:"くりふぉーと・えいりあす",e:"Qliphort Stealth",t:"monster",a:"地",l:8,ps:1,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：自分フィールドの「クリフォート」モンスターの攻撃力は３００アップする。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:1000,tx:"①：このカードはリリースなしで召喚できる。\n②：特殊召喚またはリリースなしで召喚したこのカードのレベルは４になり、元々の攻撃力は１８００になる。\n③：通常召喚したこのカードは、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果を受けない。\n④：「クリフォート」モンスターをリリースしてこのカードのアドバンス召喚に成功した時、フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アポクリフォート・カーネル",h:"あぽくりふぉーと・かーねる",e:"Apoqliphort Skybase",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2900,de:2500,tx:"このカードは特殊召喚できず、自分フィールドの「クリフォート」モンスター３体をリリースした場合のみ通常召喚できる。\n①：通常召喚したこのカードは魔法・罠カードの効果を受けず、このカードのレベルよりも元々のレベルまたはランクが低いモンスターが発動した効果も受けない。\n②：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 カペラ",h:"さてらないと　かぺら",e:"Satellarknight Capella",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"「星因士 カペラ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nこのターン自分は、モンスター３体以上を素材としたX召喚をする場合、自分フィールドのレベル４以下の「テラナイト」モンスターをレベル５の素材として扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"星因士 リゲル",h:"さてらないと　りげる",e:"Satellarknight Rigel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:700,tx:"「星因士 リゲル」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、フィールドの「テラナイト」モンスター１体を対象として発動できる。\nそのモンスターは、攻撃力が５００アップし、エンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 凶旋嵐",h:"ようせんじゅう　まがつせんらん",e:"Yosenju Magat",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:100,tx:"①：このカードが召喚に成功した場合に発動できる。\nデッキから「妖仙獣 凶旋嵐」以外の「妖仙獣」モンスター１体を特殊召喚する。\n②：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 辻斬風",h:"ようせんじゅう　つじきりかぜ",e:"Yosenju Tsujik",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"「妖仙獣 辻斬風」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「妖仙獣」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から捨てて発動できる。\nその自分のモンスターの攻撃力はターン終了時まで１０００アップする。\n②：フィールドの「妖仙獣」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の舞姫",h:"ねくろすのまいひめ",e:"Dance Princess of the Nekroz",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"「影霊衣の舞姫」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、「影霊衣」儀式魔法カードの発動に対して相手は魔法・罠・モンスターの効果を発動できず、自分フィールドの「影霊衣」儀式モンスターは相手の効果の対象にならない。\n②：このカードが効果でリリースされた場合、「影霊衣の舞姫」以外の除外されている自分の「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊獣 ラムペンタ",h:"せいれいじゅう　らむぺんた",e:"Spiritual Beast Rampengu",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"自分は「精霊獣 ラムペンタ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nエクストラデッキから「霊獣」モンスター１体を除外し、そのモンスターと同じ種族の「霊獣」モンスター１体をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"D・スマホン",h:"でぃふぉーまー・すまほん",e:"Morphtronic Smartfon",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカードは通常召喚できない。\n自分の墓地の「D」モンスター１体を除外した場合に特殊召喚できる。\n①：このカードは表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の数だけデッキの上からカードをめくる。\nその中から「D」カード１枚を手札に加え、残りはデッキに戻してシャッフルする。\n●守備表示：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の数だけデッキの上からカードを確認し、デッキの上か下に好きな順番で戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"人造人間－サイコ・ジャッカー",h:"じんぞうにんげん－さいこ・じゃっかー",e:"Jinzo - Jector",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「人造人間－サイコ・ショッカー」として扱う。\n②：このカードをリリースして発動できる。\nデッキから「人造人間－サイコ・ジャッカー」以外の「人造人間」モンスター１体を手札に加える。\nその後、相手の魔法＆罠ゾーンにセットされたカードがある場合、それらを全て確認する。\nその中の罠カードの数まで手札から「人造人間」モンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"熟練の青魔道士",h:"じゅくれんのあおまどうし",e:"Skilled Blue Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「暗黒騎士ガイア」モンスター１体を選んで特殊召喚する。\n③：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・オーバードーズ",h:"こあきめいる・おーばーどーず",e:"Koa'ki Meiru Overload",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分エンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の岩石族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n①：相手がモンスターを召喚・反転召喚・特殊召喚する際に、このカードをリリースして発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジゴバイト",h:"じごばいと",e:"Jigabyte",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：「ジゴバイト」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに魔法使い族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：このカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「ジゴバイト」以外の攻撃力１５００／守備力２００のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"怨邪帝ガイウス",h:"おんじゃていがいうす",e:"Caius the Mega Monarch",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\n①：このカードがアドバンス召喚に成功した場合、フィールドのカード１枚を対象として発動する。\nそのカードを除外し、相手に１０００ダメージを与える。\n除外したカードが闇属性モンスターカードだった場合、そのコントローラーの手札・デッキ・EXデッキ・墓地から同名カードを全て除外する。\nこのカードが闇属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●この効果の対象を２枚にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"大狼雷鳴",h:"おおかみなり",e:"Thunderclap Skywolf",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードの効果を発動するターン、自分はバトルフェイズを行えない。\n①：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"避雷神",h:"ひらいじん",e:"Lightning Rod Lord",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"①：このカードがモンスターゾーンに存在する限り、お互いにメインフェイズ１の間は魔法カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンダウザー",h:"どらごんだうざー",e:"Dragon Dowser",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「ドラゴンダウザー」の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが戦闘または相手の効果で破壊され墓地へ送られた時に発動できる。\nデッキから地属性Pモンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"フロント・オブザーバー",h:"ふろんと・おぶざーばー",e:"Frontline Observer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：このカードが召喚に成功したターンのエンドフェイズに発動できる。\nデッキから地属性Pモンスター１体を手札に加える。\n②：このカードが召喚に成功した場合、次の自分ターンのエンドフェイズに、このカードをリリースして発動できる。\nデッキから地属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニゾンビ",h:"ゆにぞんび",e:"Uni-Zombie",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\n手札を１枚捨て、対象のモンスターのレベルを１つ上げる。\n②：フィールドの表側表示モンスター１体を対象として発動できる。\nデッキからアンデット族モンスター１体を墓地へ送り、対象のモンスターのレベルを１つ上げる。\nこの効果の発動後、ターン終了時までアンデット族以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００３",h:"ぶんぼーぐぜろぜろすりー",e:"Deskbot 003",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ブンボーグ００３」以外の「ブンボーグ」モンスター１体を特殊召喚する。\n②：１ターンに１度、自分フィールドの「ブンボーグ」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、自分フィールドの「ブンボーグ」カードの数×５００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"真魔獣 ガーゼット",h:"しんまじゅう　がーぜっと",e:"Legendary Maju Garzett",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドのモンスターを全てリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、このカードを特殊召喚するためにリリースしたモンスターの元々の攻撃力を合計した数値になる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"切り盛り隊長",h:"きりもりたいちょう",e:"Marmiting Captain",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札を１枚デッキに戻してシャッフルする。\nその後、自分はデッキから１枚ドローする。\nそのドローしたカードがモンスターだった場合、そのモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"グングニールの影霊衣",h:"ぐんぐにーるのねくろす",e:"Nekroz of Gungnir",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1700,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル７以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「グングニールの影霊衣」の①②の効果はそれぞれ１ターンに１度しか使用できず、相手ターンでも発動できる。\n①：このカードを手札から捨て、自分フィールドの「影霊衣」モンスター１体を対象として発動できる。\nこのターンそのモンスターは戦闘・効果では破壊されない。\n②：手札の「影霊衣」カード１枚を捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ルーンアイズ・ペンデュラム・ドラゴン",h:"るーんあいず・ぺんでゅらむ・どらごん",e:"Rune-Eyes Pendulum Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「オッドアイズ・ペンデュラム・ドラゴン」＋魔法使い族モンスター\n①：このカードは、「オッドアイズ・ペンデュラム・ドラゴン」以外の融合素材としたモンスターの元々のレベルによって以下の効果を得る。\n●レベル４以下：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n●レベル５以上：このカードは１度のバトルフェイズ中に３回までモンスターに攻撃できる。\n②：フィールドのP召喚されたモンスターを素材としてこのカードが融合召喚に成功したターン、このカードは相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイトレディ・ラピスラズリ",h:"じぇむないとれでぃ・らぴすらずり",e:"Gem-Knight Lady Lapis Lazuli",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1000,tx:"「ジェムナイト・ラピス」＋「ジェムナイト」モンスター\nこのカードは上記のカードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n自分は「ジェムナイトレディ・ラピスラズリ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキ・エクストラデッキから「ジェムナイト」モンスター１体を墓地へ送り、フィールドの特殊召喚されたモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"エルシャドール・ウェンディゴ",h:"えるしゃどーる・うぇんでぃご",e:"El Shaddoll Wendigo",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:200,de:2800,tx:"「シャドール」モンスター＋風属性モンスター\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは特殊召喚された相手モンスターとの戦闘では破壊されない。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地へ送られた場合、自分の墓地の「シャドール」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超重荒神スサノ－O",h:"ちょうじゅうこうじんすさの－おー",e:"Superheavy Samurai Warlord Susanowo",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:3800,tx:"機械族チューナー＋チューナー以外の「超重武者」モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：１ターンに１度、自分の墓地に魔法・罠カードが存在しない場合、相手の墓地の魔法・罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nこの効果でセットしたカードはフィールドから離れた場合に除外される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・ホルス・ドラゴン",h:"めたふぁいず・ほるす・どらごん",e:"Metaphys Horus",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合、そのS素材としたチューナー以外のモンスターの種類によって、以下の効果をそれぞれ発動できる。\n\n●通常モンスター：このターンこのカードは自身以外のカードの効果を受けない。\n\n●効果モンスター：このカード以外のフィールドの表側表示のカード１枚を対象として発動できる。\nその効果を無効にする。\n\n●Pモンスター：相手フィールドのモンスター１体を相手が選び、自分はそのコントロールを得る。\nこのターンそのモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ライズ・ファルコン",h:"れいど・らぷたーず－らいず・ふぁるこん",e:"Raidraptor - Rise Falcon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:100,de:2000,tx:"鳥獣族レベル４モンスター×３\n①：このカードは特殊召喚された相手モンスター全てに１回ずつ攻撃できる。\n②：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nこのカードの攻撃力は、対象のモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"星輝士 セイクリッド・ダイヤ",h:"すてらないと　せいくりっど・だいや",e:"Stellarknight Constellar Diamond",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"光属性レベル５モンスター×３体以上\nこのカードは自分メインフェイズ２に、「星輝士 セイクリッド・ダイヤ」以外の自分フィールドの「テラナイト」Xモンスターの上にこのカードを重ねてX召喚する事もできる。\n①：X素材を持ったこのカードがモンスターゾーンに存在する限り、お互いにデッキからカードを墓地へ送る事はできず、墓地から手札に戻るカードは手札に戻らず除外される。\n②：相手の闇属性モンスターの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神騎セイントレア",h:"しんきせいんとれあ",e:"Sky Cavalry Centaurea",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:0,tx:"レベル２モンスター×２\n①：X素材を持っているこのカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に、このカードのX素材を１つ取り除いて発動できる。\nその相手モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"イリュージョン・バルーン",h:"いりゅーじょん・ばるーん",e:"Illusion Balloons",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターが破壊されたターンに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から「EM」モンスター１体を選んで特殊召喚できる。\n残りのカードはデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ネスト",h:"れいど・らぷたーず－ねすと",e:"Raidraptor - Nest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「RR－ネスト」の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「RR」モンスターが２体以上存在する場合にこの効果を発動できる。\n自分のデッキ・墓地の「RR」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンクル・セイクリッド",h:"てぃんくる・せいくりっど",e:"Constellar Twinkle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ティンクル・セイクリッド」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「セイクリッド」モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つまたは２つ上げる。\n②：このカードが墓地に存在する場合、自分の墓地の「セイクリッド」モンスター１体を除外して発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガトムズの非常召集",h:"がとむずのひじょうしょうしゅう",e:"Gottoms' Second Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ガトムズの非常召集」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はバトルフェイズを行えない。\n①：自分フィールドに「X－セイバー」Sモンスターが存在する場合に自分の墓地の「X－セイバー」モンスター２体を対象として発動できる。\nそのモンスター２体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃力が０になり、このターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の死徒",h:"れんごくのしと",e:"Void Seer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「インフェルノイド」モンスター１体を対象として発動できる。\nこのターン、その自分のモンスターは相手の効果を受けない。\n②：自分フィールドの「インフェルノイド」モンスターが効果で破壊される場合、その破壊されるモンスター１体の代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の氾爛",h:"れんごくのはんらん",e:"Void Expansion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分スタンバイフェイズにこの効果を発動できる。\n自分フィールドに「インフェルノイドトークン」（悪魔族・炎・星１・攻／守０）１体を特殊召喚する。\n②：「インフェルノイド」モンスターを自身の方法で特殊召喚する場合、自分フィールドからも「インフェルノイド」モンスターを除外できる。\n③：自分フィールドのレベルが一番高い「インフェルノイド」モンスター以外の自分の「インフェルノイド」モンスターを、相手は攻撃対象にできず、効果の対象にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"魂写しの同化",h:"ねふぇしゃどーる・ふゅーじょん",e:"Nephe Shaddoll Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「シャドール」モンスターにのみ装備可能。\n属性を１つ宣言してこのカードを発動できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターは宣言した属性になる。\n②：自分メインフェイズに発動できる。\n「シャドール」融合モンスターカードによって決められた、このカードの装備モンスターを含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の反魂術",h:"ねくろすのはんごんじゅつ",e:"Nekroz Cycle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「影霊衣」儀式モンスターの降臨に必要。\n「影霊衣の反魂術」の①の効果は１ターンに１度しか使用できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、自分の手札・墓地から「影霊衣」儀式モンスター１体を儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと「影霊衣」モンスター１体を除外して発動できる。\nデッキから「影霊衣」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"帝王の深怨",h:"ていおうのしんえん",e:"Tenacity of the Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「帝王の深怨」は１ターンに１枚しか発動できない。\n①：手札の攻撃力２４００／守備力１０００のモンスター１体または攻撃力２８００／守備力１０００のモンスター１体を相手に見せて発動できる。\nデッキから「帝王の深怨」以外の「帝王」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティの神槍",h:"どらぐにてぃのしんそう",e:"Dragunity Divine Lance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ドラグニティ」モンスターにのみ装備可能。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターは、攻撃力が装備モンスターのレベル×１００アップし、罠カードの効果を受けない。\n②：自分メインフェイズに発動できる。\nデッキからドラゴン族の「ドラグニティ」チューナー１体を選び、このカードの装備モンスターに装備カード扱いとして装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"金満な壺",h:"きんまんなつぼ",e:"Pot of Riches",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「金満な壺」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はP召喚以外の特殊召喚ができない。\n①：自分のエクストラデッキの表側表示のPモンスター及び自分の墓地のPモンスターを合計３体選び、デッキに加えてシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"青天の霹靂",h:"せいてんのへきれき",e:"A Wild Monster Appears!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合に発動できる。\n元々のレベルが１０以下の通常召喚できないモンスター１体を、召喚条件を無視して手札から特殊召喚する。\nこの効果で特殊召喚したモンスターは、そのモンスター以外の自分のカードの効果を受けず、次の相手エンドフェイズに持ち主のデッキに戻る。\nこのターン、自分はモンスターを通常召喚・特殊召喚できず、相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・ターン",h:"ぺんでゅらむ・たーん",e:"Pendulum Shift",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分または相手のPゾーンのカード１枚を対象とし、１～１０までのPスケールを宣言して発動できる。\nこのターン、そのカードは宣言したPスケールになる。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚制限－エクストラネット",h:"しょうかんせいげん－えくすとらねっと",e:"Extra Net",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分または相手がエクストラデッキからモンスターを特殊召喚した場合にこの効果を発動する。\nそのモンスターを特殊召喚したプレイヤーから見て相手は以下の効果を適用できる。\n\n●デッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMコール",h:"えんためいとこーる",e:"Performapal Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\nその攻撃を無効にし、守備力の合計が対象のモンスターの攻撃力以下となるように、デッキから「EM」モンスターを２体まで手札に加える。\nこのカードの発動後、次の自分ターンの終了時まで自分はエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"分断の壁",h:"ぶんだんのかべ",e:"Wall of Disruption",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの全ての攻撃表示モンスターの攻撃力は、相手フィールドのモンスターの数×８００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドタキャン",h:"どたきゃん",e:"Last Minute Cancel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n自分フィールドのモンスターを全て守備表示にする。\nこのカードの発動後、ターン終了時まで戦闘・効果で破壊された自分フィールドの表側表示の「EM」モンスターは墓地へは行かず持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－レディネス",h:"れいど・らぷたーず－れでぃねす",e:"Raidraptor - Readiness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、自分フィールドの「RR」モンスターは戦闘では破壊されない。\n②：自分の墓地に「RR」モンスターが存在する場合に墓地のこのカードを除外して発動できる。\nこのターン、自分が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"蒼焔の煉獄",h:"そうえんのれんごく",e:"Eye of the Void",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から「インフェルノイド」モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果はターン終了時まで無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"殻醒する煉獄",h:"かくせいするれんごく",e:"Void Launch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分スタンバイフェイズ毎にこの効果を発動できる。\nデッキから「インフェルノイド」モンスターを２体まで墓地へ送る。\n②：自分フィールドに「インフェルノイド」モンスター以外のモンスターが存在する場合にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"機殻の再星",h:"りくりふぉーと",e:"Re-qliate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：モンスターが召喚・反転召喚された時、そのモンスターがレベル４以下の場合にこの効果を発動する。\nそのモンスターの効果をターン終了時まで無効にする。\n②：モンスターが特殊召喚された時、そのモンスターがレベル５以上の場合にこの効果を発動する。\nそのモンスターの効果をターン終了時まで無効にする。\nそのモンスターはフィールドから離れた場合に除外される。\n③：フィールドに「機殻の再星」以外の「クリフォート」カードが存在しない場合にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"霊獣の騎襲",h:"れいじゅうのきしゅう",e:"Ritual Beast Ambush",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「霊獣の騎襲」は１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、「霊獣使い」モンスターと「精霊獣」モンスターをそれぞれ１体ずつ対象として発動できる。\n対象のモンスターを守備表示で特殊召喚する。\nこのカードの発動後、ターン終了時まで自分は「霊獣」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"発条の巻き上げ",h:"ぜんまいのまきあげ",e:"Zenmaiday",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの①②の効果は同一チェーン上では発動できない。\n①：１ターンに１度、自分フィールドの機械族Xモンスター１体を対象として発動できる。\n自分の手札・フィールドの「ゼンマイ」モンスター１体を選び、対象のモンスターの下に重ねてX素材とする。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分フィールドの「ゼンマイ」Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが１つ高い「ゼンマイ」Xモンスター１体を、その上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依解放",h:"ひょういかいほう",e:"Unpossessed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分の「霊使い」モンスターは戦闘では破壊されない。\n②：自分の「憑依装着」モンスターの攻撃力は、相手モンスターに攻撃するダメージ計算時のみ８００アップする。\n③：このカードが既に魔法＆罠ゾーンに存在する状態で、自分フィールドのモンスターが戦闘・効果で破壊された場合に発動できる。\nそのモンスター１体とは元々の属性が異なる守備力１５００の魔法使い族モンスター１体を、デッキから表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイズ・キャノン・マガジン",h:"ぶれいず・きゃのん・まがじん",e:"Blaze Accelerator Reload",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「ブレイズ・キャノン・マガジン」の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、魔法＆罠ゾーンに存在する限り「ブレイズ・キャノン－トライデント」として扱う。\n②：自分及び相手メインフェイズにこの効果を発動できる。\n手札の「ヴォルカニック」カード１枚を墓地へ送り、自分はデッキから１枚ドローする。\n③：自分及び相手メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「ヴォルカニック」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の転身",h:"たましいのてんしん",e:"Soul Transition",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「魂の転身」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はモンスターを特殊召喚できない。\n①：自分フィールドに特殊召喚されたモンスターが存在しない場合、自分フィールドの通常召喚された表側表示のレベル４モンスター１体をリリースして発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"連成する振動",h:"れんせいするしんどう",e:"Echo Oscillation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「連成する振動」の効果は１ターンに１度しか使用できない。\n①：自分のPゾーンのカード１枚を対象としてこの効果を発動できる。\nそのカードを破壊し、その後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"姑息な落とし穴",h:"こそくなおとしあな",e:"Double Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターを守備表示で特殊召喚した時に発動できる。\nその守備表示モンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の先導者",h:"ほうぎょくのせんどうしゃ",e:"Crystal Master",t:"monster",a:"闇",l:3,ps:5,pe:"①：このカードがPゾーンに存在する限り、自分フィールドの「究極宝玉神」モンスター及び「宝玉獣」カードは、相手の効果の対象にならない。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1300,de:1000,tx:"①：このカードをリリースして発動できる。\nデッキから、「究極宝玉神」モンスター、「宝玉獣」モンスター、「宝玉」魔法・罠カードの内、いずれか１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の守護者",h:"ほうぎょくのしゅごしゃ",e:"Crystal Keeper",t:"monster",a:"炎",l:4,ps:2,pe:"①：このカードがPゾーンに存在する限り、１ターンに１度、自分フィールドの「究極宝玉神」モンスター及び「宝玉獣」カードは効果では破壊されない。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：自分の「宝玉獣」モンスターが相手モンスターと戦闘を行うダメージ計算時、自分の手札・フィールドのこのカードをリリースして発動できる。\nその戦闘を行う自分のモンスターは、攻撃力・守備力がダメージ計算時のみ元々の数値の倍になり、ダメージステップ終了時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバー・ザ・レインボー",h:"おーばー・ざ・れいんぼー",e:"Rainbow Refraction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの、元々のカード名が「究極宝玉神 レインボー・ドラゴン」または「究極宝玉神 レインボー・ダーク・ドラゴン」となるモンスターが効果を発動したターンに発動できる。\nデッキから「宝玉獣」モンスターを任意の数だけ特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の集結",h:"ほうぎょくのしゅうけつ",e:"Crystal Conclave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの①②の効果は同一チェーン上では発動できない。\n①：１ターンに１度、自分フィールドの表側表示の「宝玉獣」モンスターが戦闘・効果で破壊された場合にこの効果を発動できる。\nデッキから「宝玉獣」モンスター１体を特殊召喚する。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分フィールドの「宝玉獣」カード１枚とフィールドのカード１枚を対象としてこの効果を発動できる。\n対象のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"重機貨列車デリックレーン",h:"じゅうきかれっしゃでりっくれーん",e:"Heavy Freight Train Derricrane",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに機械族・地属性モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの元々の攻撃力・守備力は半分になる。\n②：X素材のこのカードがXモンスターの効果を発動するために取り除かれ墓地へ送られた場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.81 超弩級砲塔列車スペリオル・ドーラ",h:"なんばーず８１ ちょうどきゅうほうとうれっしゃすぺりおる・どーら",e:"Number 81: Superdreadnought Rail Cannon Super Dora",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:4000,tx:"レベル１０モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターはターン終了時まで、自身以外のカードの効果を受けない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"転回操車",h:"てんかいそうしゃ",e:"Revolving Switchyard",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに機械族・地属性・レベル１０モンスターが召喚・特殊召喚された場合に発動できる。\nデッキから攻撃力１８００以上の機械族・地属性・レベル４モンスター１体を特殊召喚する。\nこの効果で特殊召喚されたモンスターのレベルは１０になる。\nこの効果の発動後、ターン終了時まで相手が受ける戦闘ダメージは０になる。\n②：自分の手札を１枚墓地へ送って発動できる。\nデッキから機械族・地属性・レベル１０モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"音響戦士ギータス",h:"さうんどうぉりあーぎーたす",e:"Symphonic Warrior Guitaar",t:"monster",a:"風",l:3,ps:7,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\nデッキから「音響戦士ギータス」以外の「音響戦士」モンスター１体を特殊召喚する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:100,tx:"①：このカードが召喚に成功した時、自分の墓地の「音響戦士」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"音響戦士サイザス",h:"さうんどうぉりあーさいざす",e:"Symphonic Warrior Synthess",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:1900,tx:"①：このカードがリバースした場合に発動できる。\nデッキから「音響戦士サイザス」以外の「音響戦士」モンスター１体を手札に加える。\n②：１ターンに１度、「音響戦士サイザス」以外の自分のフィールド・墓地の「音響戦士」モンスター１体を対象として発動できる。\nこのカードはエンドフェイズまで、そのモンスターと同名カードとして扱い、同じ効果を得る。\n③：墓地のこのカードを除外し、「音響戦士サイザス」以外の除外されている自分の「音響戦士」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アンプリファイヤー",h:"あんぷりふぁいやー",e:"Symph Amplifire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、「音響戦士」カードの効果が発動する度にこのカードに音響カウンターを１つ置く。\n②：フィールドの「音響戦士」モンスターの攻撃力は、このカードの音響カウンターの数×１００アップする。\n③：１ターンに１度、自分フィールドの音響カウンターを５つまたは７つ取り除いて以下の効果を発動できる。\n\n●５つ：フィールドの「音響戦士」カードの数×３００ダメージを相手に与える。\n\n●７つ：フィールドの「音響戦士」カードの数まで、相手のフィールド・墓地のカードを選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"アルティマヤ・ツィオルキン",h:"あるてぃまや・つぃおるきん",e:"Ultimaya Tzolkin",t:"monster",a:"闇",l:"",ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:0,tx:"ルール上、このカードのレベルは１２として扱う。\nこのカードはS召喚できず、自分フィールドの表側表示のレベル５以上で同じレベルの、チューナーとチューナー以外のモンスターを１体ずつ墓地へ送った場合のみ特殊召喚できる。\n①：１ターンに１度、自分フィールドに魔法・罠カードがセットされた時に発動できる。\n「パワー・ツール」Sモンスターまたはレベル７・８のドラゴン族Sモンスター１体をEXデッキから特殊召喚する。\n②：フィールドのこのカードは、他の自分のSモンスターが存在する限り、攻撃対象及び、効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMペンデュラム・マジシャン",h:"えんためいとぺんでゅらむ・まじしゃん",e:"Performapal Pendulum Sorcerer",t:"monster",a:"地",l:4,ps:2,pe:"①：自分フィールドに「EM」モンスターがP召喚された場合に発動する。\n自分フィールドの「EM」モンスターの攻撃力はターン終了時まで１０００アップする。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:800,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、自分フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊し、破壊した数だけデッキから「EMペンデュラム・マジシャン」以外の「EM」モンスターを手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD壊薙王アビス・ラグナロク",h:"でぃーでぃーでぃーかいちおうあびす・らぐなろく",e:"D/D/D Oblivion King Abyss Ragnarok",t:"monster",a:"闇",l:8,ps:5,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分が「DD」モンスターの特殊召喚に成功した場合、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、自分は１０００ダメージを受ける。\nこのターン、相手が受ける戦闘ダメージは半分になる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2200,de:3000,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地の「DDD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、このカード以外の自分フィールドの「DD」モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・シザー・タイガー",h:"ですとーい・しざー・たいがー",e:"Frightfur Tiger",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:1200,tx:"「エッジインプ・シザー」＋「ファーニマル」モンスター１体以上\n①：「デストーイ・シザー・タイガー」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが融合召喚に成功した時、このカードの融合素材としたモンスターの数まで、フィールドのカードを対象として発動できる。\nそのカードを破壊する。\n③：このカードがモンスターゾーンに存在する限り、自分フィールドの「デストーイ」モンスターの攻撃力は、自分フィールドの「ファーニマル」モンスター及び「デストーイ」モンスターの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"超重神鬼シュテンドウ－G",h:"ちょうじゅうしんきしゅてんどう－じー",e:"Superheavy Samurai Ogre Shutendoji",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:500,de:2500,tx:"機械族チューナー１体＋チューナー以外の「超重武者」モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：自分の墓地に魔法・罠カードが存在せず、このカードがS召喚に成功した時に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－フォース・ストリクス",h:"れいど・らぷたーず－ふぉーす・すとりくす",e:"Raidraptor - Force Strix",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:100,de:2000,tx:"レベル４モンスター×２\n①：このカードの攻撃力・守備力は、このカード以外の自分フィールドの鳥獣族モンスターの数×５００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから鳥獣族・闇属性・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェット・シンクロン",h:"じぇっと・しんくろん",e:"Jet Synchron",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがS素材として墓地へ送られた場合に発動できる。\nデッキから「ジャンク」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、手札を１枚墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ラッシュ・ウォリアー",h:"らっしゅ・うぉりあー",e:"Rush Warrior",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"「ラッシュ・ウォリアー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「ウォリアー」Sモンスターが相手モンスターと戦闘を行うダメージ計算時、このカードを手札から墓地へ送って発動できる。\nその戦闘を行う自分のモンスターの攻撃力は、そのダメージ計算時のみ倍になる。\n②：墓地のこのカードを除外し、自分の墓地の「シンクロン」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロン・キャリアー",h:"しんくろん・きゃりあー",e:"Synchron Carrier",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"「シンクロン・キャリアー」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「シンクロン」モンスター１体を召喚できる。\n②：このカードがモンスターゾーンに存在し、このカード以外の「シンクロン」モンスターが戦士族または機械族SモンスターのS素材として自分の墓地へ送られた場合に発動できる。\n自分フィールドに「シンクロントークン」（機械族・地・星２・攻１０００／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・フィスト",h:"すくらっぷ・ふぃすと",e:"Scrap Fist",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「ジャンク・ウォリアー」１体を対象として発動できる。\nこのターン、その自分のモンスターが相手モンスターと戦闘を行う場合、以下の効果を適用する。\n\n●相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n\n●対象のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n\n●相手が受ける戦闘ダメージは倍になる。\n\n●対象のモンスターは戦闘では破壊されない。\n\n●戦闘を行った相手モンスターはダメージステップ終了時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"リミットオーバー・ドライブ",h:"りみっとおーばー・どらいぶ",e:"Limit Overdrive",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「リミットオーバー・ドライブ」は１ターンに１枚しか発動できない。\n①：自分フィールドのSモンスターのチューナー１体とチューナー以外のSモンスター１体をエクストラデッキに戻して発動できる。\nそのモンスター２体のレベルの合計と同じレベルのSモンスター１体を、召喚条件を無視してエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターライト・ジャンクション",h:"すたーらいと・じゃんくしょん",e:"Starlight Junktion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「スターライト・ジャンクション」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのチューナー１体をリリースしてこの効果を発動できる。\nリリースしたモンスターとレベルが異なる「シンクロン」モンスター１体をデッキから特殊召喚する。\n②：相手ターンに自分がエクストラデッキからSモンスターを特殊召喚した場合、フィールドのカード１枚を対象として発動する。\nそのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"くず鉄の像",h:"くずてつのぞう",e:"Scrap-Iron Statue",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「くず鉄の像」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの既に表側表示で存在している魔法・罠カードがその効果を発動した時に発動できる。\nそのカードを破壊する。\n発動後このカードは墓地へ送らず、そのままセットする。\n②：このカードが墓地へ送られた場合、自分の墓地の「ジャンク」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・ウォリアー",h:"すたーだすと・うぉりあー",e:"Stardust Warrior",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター１体以上\n①：相手がモンスターを特殊召喚する際に、このカードをリリースして発動できる。\nそれを無効にし、そのモンスターを破壊する。\n②：このカードの①の効果を適用したターンのエンドフェイズに発動できる。\nその効果を発動するためにリリースしたこのカードを墓地から特殊召喚する。\n③：戦闘または相手の効果で表側表示のこのカードがフィールドから離れた場合に発動できる。\nエクストラデッキからレベル８以下の「ウォリアー」Sモンスター１体をS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェット・ウォリアー",h:"じぇっと・うぉりあー",e:"Jet Warrior",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1200,tx:"「ジェット・シンクロン」＋チューナー以外のモンスター１体以上\n「ジェット・ウォリアー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが墓地に存在する場合、自分フィールドのレベル２以下のモンスター１体をリリースして発動できる。\nこのカードを墓地から守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"アクセル・シンクロン",h:"あくせる・しんくろん",e:"Accel Synchron",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:500,de:2100,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「アクセル・シンクロン」を１ターンに１度しかS召喚できない。\n①：１ターンに１度、デッキから「シンクロン」モンスター１体を墓地へ送り、以下の効果から１つを選択して発動できる。\n●墓地へ送ったそのモンスターのレベル分だけ、このカードのレベルを上げる。\n●墓地へ送ったそのモンスターのレベル分だけ、このカードのレベルを下げる。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラコニアの獣竜騎兵",h:"どらこにあのじゅうりゅうきへい",e:"Dragoons of Draconia",t:"monster",a:"炎",l:4,ps:2,pe:"①：１ターンに１度、自分の通常モンスターが戦闘で相手モンスターを破壊したダメージ計算後に発動できる。\nデッキからレベル４以上の通常モンスター１体を手札に加える。",tr:"獣戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1800,de:200,tx:"龍人族の国、ドラコニア帝国が有する竜騎士団の陸兵部隊。\n鳥銃と鉄槍によるコンビネーション攻撃には隙が無く、レプティア皇国などの周辺国から恐れられている。",li:"",lk:[],ta:[],al:[]},
  {n:"小人のいたずら",h:"こびとのいたずら",e:"Mischief of the Gnomes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、お互いの手札のモンスターのレベルを１つ下げる。\n②：墓地のこのカードを除外して発動できる。\nこのターン、お互いの手札のモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 ファーファレル",h:"ひがんのあっき　ふぁーふぁれる",e:"Farfa, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"このカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターをエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 リビオッコ",h:"ひがんのあっき　りびおっこ",e:"Libic, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:700,tx:"「彼岸の悪鬼 リビオッコ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合に発動できる。\n手札から悪魔族・闇属性・レベル３モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 ハロウハウンド",h:"ひがんのあっき　はろうはうんど",e:"Cagna, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"「彼岸の悪鬼 ハロウハウンド」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「彼岸」魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の鬼神 ヘルレイカー",h:"ひがんのきしん　へるれいかー",e:"Malacoda, Netherlord of the Burning Abyss",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:2200,tx:"「善悪の彼岸」により降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n①：１ターンに１度、手札から「彼岸」モンスター１体を墓地へ送り、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力はターン終了時まで、この効果を発動するために墓地へ送ったモンスターのそれぞれの数値分ダウンする。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"善悪の彼岸",h:"ぜんあくのひがん",e:"Good & Evil in the Burning Abyss",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「彼岸の鬼神 ヘルレイカー」の降臨に必要。\n「善悪の彼岸」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、レベルの合計が６以上になるようにモンスターをリリースし、手札から「彼岸の鬼神 ヘルレイカー」を儀式召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、手札から「彼岸」モンスター１体を墓地へ送って発動できる。\nデッキから「彼岸」カード１枚を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.フィールドゼネラル",h:"うるとらあすりーとふぃーるどぜねらる",e:"U.A. Playmaker",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"「U.A.フィールドゼネラル」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.フィールドゼネラル」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカード以外の自分の「U.A.」モンスターの攻撃宣言時に発動できる。\nこのカードの攻撃力を８００ダウンし、自分の攻撃モンスターの攻撃力を８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.ストロングブロッカー",h:"うるとらあすりーとすとろんぐぶろっかー",e:"U.A. Blockbacker",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:2700,tx:"「U.A.ストロングブロッカー」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.ストロングブロッカー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：１ターンに１度、相手がモンスターの特殊召喚に成功した時に発動できる。\nそのモンスターの表示形式を変更し、その効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.ターンオーバー・タクティクス",h:"うるとらあすりーとたーんおーばー・たくてぃくす",e:"U.A. Turnover Tactics",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「U.A.ターンオーバー・タクティクス」は１ターンに１枚しか発動できない。\n①：自分フィールドに「U.A.」モンスターが２種類以上存在する場合に発動できる。\nフィールドのモンスターを全て持ち主のデッキに戻す。\nその後、自分はこの効果で自分のデッキに戻ったカードの数まで、デッキから「U.A.」モンスターを特殊召喚する（同名カードは１枚まで）。\nこの効果で自分が特殊召喚したモンスターはこのターン攻撃できない。\nその後、相手はこの効果で相手のデッキに戻ったカードの数まで、デッキからモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"HSR魔剣ダーマ",h:"はいすぴーどろいどまけんだーま",e:"Hi-Speedroid Kendama",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n「HSR魔剣ダーマ」の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：自分の墓地の機械族モンスター１体を除外して発動できる。\n相手に５００ダメージを与える。\n③：このカードが墓地に存在し、自分フィールドにカードが存在しない場合、自分メインフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果を発動するターン、自分は通常召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・ジャイアント",h:"じゃんく・じゃいあんと",e:"Junk Giant",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2400,tx:"①：相手フィールドにレベル５以上のモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードを素材としたS召喚は無効化されず、そのS召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"絶対王 バック・ジャック",h:"ぜったいおう　ばっく・じゃっく",e:"Absolute King Back Jack",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手ターンに墓地のこのカードを除外して発動できる。\n自分のデッキの一番上のカードをめくり、そのカードが通常罠カードだった場合、自分フィールドにセットする。\n違った場合、そのカードを墓地へ送る。\nこの効果でセットしたカードはセットしたターンでも発動できる。\n②：このカードが墓地へ送られた場合に発動できる。\n自分のデッキの上からカードを３枚確認し、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"薔薇の聖騎士",h:"ろーず・ぱらでぃん",e:"Rose Paladin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"「薔薇の聖騎士」の②の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時、このカードをリリースして発動できる。\n手札・デッキから植物族モンスター１体を守備表示で特殊召喚する。\n②：このカードを手札から墓地へ送って発動できる。\nデッキからレベル７以上の植物族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"劫火の舟守 ゴースト・カロン",h:"ごうかのふなもり　ごーすと・かろん",e:"Ghost Charon, the Underworld Boatman",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:0,tx:"「劫火の舟守 ゴースト・カロン」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はドラゴン族モンスターしか特殊召喚できない。\n①：相手フィールドにモンスターが存在し、自分フィールドにこのカード以外のモンスターが存在しない場合、自分の墓地の融合モンスター１体を対象として発動できる。\n墓地のそのモンスターとフィールドのこのカードを除外し、その２体のレベルの合計と同じレベルを持つドラゴン族Sモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ペロペロケルペロス",h:"ぺろぺろけるぺろす",e:"Peropero Cerperus",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"①：このカードが墓地に存在し、戦闘または相手の効果で自分がダメージを受けた場合、墓地のこのカードを除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界騎士トリスタン",h:"めいかいきしとりすたん",e:"Tristan, Knight of the Underworld",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが召喚に成功した時、自分の墓地の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのカードを手札に加える。\n②：自分フィールドにこのカード以外のアンデット族モンスターが存在する場合、このカードの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界の麗人イゾルデ",h:"めいかいのれいじんいぞるで",e:"Isolde, Belle of the Underworld",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカードはこのカードの①の方法でしか特殊召喚できない。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「冥界騎士トリスタン」が存在する場合、このカードは手札から特殊召喚できる。\n②：自分フィールドのアンデット族モンスターを２体まで対象とし、５～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO 闇鬼",h:"ますくどひーろー　あんき",e:"Masked HERO Anki",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:1200,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「チェンジ」速攻魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－星影のノートゥング",h:"ぶらっくふぇざー－ほしかげののーとぅんぐ",e:"Blackwing - Nothung the Starlight",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n「BF－星影のノートゥング」の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\n相手に８００ダメージを与える。\nその後、相手の表側表示モンスター１体を選び、その攻撃力・守備力を８００ダウンする。\n②：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「BF」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"冥界濁龍 ドラゴキュートス",h:"めいかいだくりゅう　どらごきゅーとす",e:"Dragocytos Corrupted Nethersoul Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:2000,tx:"闇属性チューナー＋チューナー以外のドラゴン族モンスター１体\n①：このカードは戦闘では破壊されない。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードはもう１度だけ続けて相手モンスターに攻撃できる。\n③：自分スタンバイフェイズに相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を半分にし、その数値分だけ相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.95 ギャラクシーアイズ・ダークマター・ドラゴン",h:"なんばーず９５ ぎゃらくしーあいず・だーくまたー・どらごん",e:"Number 95: Galaxy-Eyes Dark Matter Dragon",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:0,tx:"レベル９モンスター×３\nこのカードは自分フィールドの「ギャラクシーアイズ」Xモンスターの上に重ねてX召喚する事もできる。\nこのカードはX召喚の素材にできない。\n①：このカードがX召喚に成功した時、デッキからドラゴン族モンスター３体を墓地へ送って発動できる（同名カードは１枚まで）。\n相手は自身のデッキからモンスター３体を除外する。\n②：このカードのX素材を１つ取り除いて発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:0,lk:[],ta:[],al:[]},
  {n:"キャット・シャーク",h:"きゃっと・しゃーく",e:"Cat Shark",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:500,tx:"レベル２モンスター×２\n①：このカードが水属性モンスターをX素材として持っている場合、このカードは戦闘では破壊されない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドのランク４以下のXモンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力をターン終了時まで元々の数値の倍にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.14 強欲のサラメーヤ",h:"なんばーず１４ ごうよくのさらめーや",e:"Number 14: Greedy Sarameya",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"レベル５モンスター×２\n①：このカードがモンスターゾーンに存在する限り、相手の効果で発生する自分への効果ダメージは代わりに相手が受ける。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時、このカードのX素材を１つ取り除いて発動できる。\n破壊したそのモンスターの元々の攻撃力以下の攻撃力を持つフィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.21 氷結のレディ・ジャスティス",h:"なんばーず２１ ひょうけつのれでぃ・じゃすてぃす",e:"Number 21: Frozen Lady Justice",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:500,tx:"レベル６モンスター×２\nこのカードは自分フィールドのランク５のXモンスターからX素材を１つ取り除き、そのXモンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力は、このカードのX素材の数×１０００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの守備表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"パラレル・ツイスター",h:"ぱられる・ついすたー",e:"Parallel Twister",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このカード以外の自分フィールドの魔法・罠カード１枚を墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"星墜つる地に立つ閃珖",h:"すたーだすと・り・すぱーく",e:"Stardust Re-Spark",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「星墜つる地に立つ閃珖」は１ターンに１枚しか発動できない。\n①：特殊召喚された相手モンスターの直接攻撃宣言時、そのモンスターの攻撃力が自分のLP以上の場合に発動できる。\nその攻撃を無効にし、自分はデッキから１枚ドローする。\nその後、自分のエクストラデッキ・墓地から「スターダスト」モンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・ハーピスト",h:"はーぴぃ・はーぴすと",e:"Harpie Harpist",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：このカードが召喚に成功した時、このカード以外の自分フィールドの鳥獣族モンスター１体と相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n③：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから攻撃力１５００以下の鳥獣族・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"銅鑼ドラゴン",h:"どらどらごん",e:"Dragong",t:"monster",a:"地",l:4,ps:7,pe:"",tr:"機械族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:500,de:2100,tx:"最新鋭の技術を駆使し、飛行が可能になった戦闘用の銅鑼。\nマッハ０.７で天空を翔るその存在に驚愕せざるを得ない。",li:"",lk:[],ta:[],al:[]},
  {n:"マンドラゴン",h:"まんどらごん",e:"Mandragon",t:"monster",a:"地",l:5,ps:2,pe:"",tr:"植物族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2500,de:1000,tx:"悲哀に満ちたドラゴンの魂を宿したマンドリン。\n呪いの音色を奏で、聞いたものは恐怖のあまり自我を失ってしまうという。",li:"",lk:[],ta:[],al:[]},
  {n:"たつのこ",h:"たつのこ",e:"Tatsunoko",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1700,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\nS召喚したこのカードを素材としてS召喚をする場合、手札のモンスター１体もS素材にできる。\n①：このカードがモンスターゾーンに存在する限り、このカードは他のモンスターの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"プレゼント交換",h:"ぷれぜんとこうかん",e:"Gift Exchange",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、それぞれ自分のデッキからカード１枚を選んで裏側表示で除外する。\nこのターンのエンドフェイズに、お互いはそれぞれ相手となるプレイヤーが除外したそのカードを自分の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"仕込み爆弾",h:"しこみばくだん",e:"Secret Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドのカードの数×３００ダメージを相手に与える。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔サイの戦士",h:"まさいのせんし",e:"Fiendish Rhino Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、「魔サイの戦士」以外の自分フィールドの悪魔族モンスターは戦闘・効果では破壊されない。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「魔サイの戦士」以外の悪魔族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"キューブン",h:"きゅーぶん",e:"Cuben",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:600,de:1900,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\nこのモンスターがフィールドに表側表示で存在する限り、お互いに出た目と同じレベルのモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"にん人",h:"にんじん",e:"World Carrotweight Champion",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"「にん人」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、手札及び自分フィールドの表側表示モンスターの中から、「にん人」以外の植物族モンスター１体を墓地へ送って発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古神ハストール",h:"こしんはすとーる",e:"Old Entity Hastorr",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがモンスターゾーンから墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは攻撃できず、効果は無効化される。\n②：このカードの効果で相手モンスターに装備されているこのカードがフィールドから離れた場合に発動する。\nこのカードを装備していた相手モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ロケットハンド",h:"ろけっとはんど",e:"Rocket Hand",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの攻撃力８００以上の攻撃表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力８００アップの装備カード扱いとしてそのモンスターに装備する。\n②：装備されているこのカードを墓地へ送り、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、このカードを装備していたモンスターは攻撃力が０になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"黒・魔・導・爆・裂・破",h:"ぶらっく・ばーにんぐ",e:"Dark Burning Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン・ガール」モンスターが存在する場合に発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒・爆・裂・破・魔・導",h:"ぶらっく・ばーにんぐ・まじっく",e:"Dark Burning Magic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに、元々のカード名が「ブラック・マジシャン」と「ブラック・マジシャン・ガール」となるモンスターが存在する場合に発動できる。\n相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ピースの輪",h:"ぴーすのわ",e:"Symbol of Friendship",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにモンスターが３体以上存在し、自分フィールドにカードが存在しない場合、自分ドローフェイズに通常のドローをしたこのカードを公開し続ける事で、そのターンのメインフェイズ１に発動できる。\n自分はデッキからカード１枚を選び、お互いに確認して手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"永遠の魂",h:"えいえんのたましい",e:"Eternal Soul",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\n●自分の手札・墓地から「ブラック・マジシャン」１体を選んで特殊召喚する。\n●デッキから「黒・魔・導」または「千本ナイフ」１枚を手札に加える。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分のモンスターゾーンの「ブラック・マジシャン」は相手の効果を受けない。\n③：表側表示のこのカードがフィールドから離れた場合に発動する。\n自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スクランブル・エッグ",h:"すくらんぶる・えっぐ",e:"Scramble Egg",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「ロードランナー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻のグリフォン",h:"まぼろしのぐりふぉん",e:"Phantom Gryphon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"山岳に隠れ棲む伝説のモンスター。\nその翼はひと羽ばたきで嵐を巻き起こすという。\nハーピィとは仲が悪く、狩場を巡って争いが絶えないらしい。",li:"",lk:[],ta:[],al:[]},
  {n:"EMハンマーマンモ",h:"えんためいとはんまーまんも",e:"Performapal Elephammer",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：自分フィールドに「EM」カードが２枚以上存在する場合、このカードはリリースなしで召喚できる。\n②：自分フィールドに他の「EM」カードが存在しない場合、このカードは攻撃できない。\n③：このカードの攻撃宣言時に発動できる。\n相手フィールドの魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"EMガンバッター",h:"えんためいとがんばったー",e:"Performapal Bowhopper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「EMガンバッター」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「EM」モンスター１体をリリースして発動できる。\nリリースしたモンスターのレベル×１００ダメージを相手に与える。\n②：自分フィールドの「EM」モンスター１体をリリースし、リリースしたモンスター以外の自分の墓地の「EM」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMリザードロー",h:"えんためいとりざーどろー",e:"Performapal Lizardraw",t:"monster",a:"地",l:3,ps:6,pe:"「EMリザードロー」のP効果は１ターンに１度しか使用できない。\n①：自分メインフェイズにもう片方の自分のPゾーンに「EMリザードロー」以外の「EM」カードが存在する場合に発動できる。\nこのカードを破壊し、自分はデッキから１枚ドローする。",tr:"爬虫類族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:600,tx:"「EMリザードロー」のモンスター効果は１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドの表側表示モンスターが相手モンスターの攻撃または相手の効果で破壊された場合に発動できる。\n自分フィールドの「EM」モンスターの数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMスプリングース",h:"えんためいとすぷりんぐーす",e:"Performapal Springoose",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2400,tx:"「EMスプリングース」の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに墓地のこのカードを除外し、自分のPゾーンの「魔術師」カード、「EM」カード及び自分フィールドのPモンスターの中から２枚を対象として発動できる。\nそのカード２枚を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ビッグワラ－G",h:"ちょうじゅうむしゃびっぐわら－じー",e:"Superheavy Samurai Big Waraji",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"①：自分の墓地に魔法・罠カードが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功したターン、自分は「超重武者」モンスターしか特殊召喚できない。\n②：機械族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者グロウ－V",h:"ちょうじゅうむしゃぐろう－ぶ",e:"Superheavy Samurai Gigagloves",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:1000,tx:"①：自分の墓地に魔法・罠カードが存在せず、このカードが墓地へ送られた場合に発動できる。\nデッキの上からカードを５枚確認し、好きな順番でデッキの上に戻す。\n②：相手モンスターの直接攻撃宣言時、墓地のこのカードを除外して発動できる。\n自分のデッキの一番上のカードをめくり、そのカードが「超重武者」モンスターだった場合は手札に加え、その攻撃モンスターの攻撃力を０にする。\n違った場合はめくったカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者タマ－C",h:"ちょうじゅうむしゃたま－しー",e:"Superheavy Samurai Battleball",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:800,tx:"「超重武者タマ－C」の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「超重武者」モンスター以外のモンスターが存在せず、自分の墓地に魔法・罠カードが存在しない場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとこのカードをフィールドから墓地へ送る。\nその後、墓地へ送ったそのモンスター２体の元々のレベルの合計と同じレベルを持つ「超重武者」Sモンスター１体をエクストラデッキからS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留バスター・ガントレット",h:"ちょうじゅうむしゃそうるばすたー・がんとれっと",e:"Superheavy Samurai Soulbuster Gauntlet",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:400,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを守備力４００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：自分の墓地に魔法・罠カードが存在しない場合、自分の守備表示の「超重武者」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nその戦闘を行う自分のモンスターの守備力はターン終了時まで元々の守備力の倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の歌姫ソプラノ",h:"げんそうのうたひめそぷらの",e:"Soprano the Melodious Songstress",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"「幻奏の歌姫ソプラノ」の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、「幻奏の歌姫ソプラノ」以外の自分の墓地の「幻奏」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：１ターンに１度、自分メインフェイズに発動できる。\n「幻奏」融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・シープ",h:"ふぁーにまる・しーぷ",e:"Fluffal Sheep",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"「ファーニマル・シープ」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ファーニマル・シープ」以外の「ファーニマル」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカード以外の自分フィールドの「ファーニマル」モンスター１体を持ち主の手札に戻して発動できる。\n自分の手札・墓地から「エッジインプ」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジインプ・ソウ",h:"えっじいんぷ・そう",e:"Edge Imp Saw",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"「エッジインプ・ソウ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、手札の「ファーニマル」モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローし、その後、手札を１枚選んでデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジインプ・チェーン",h:"えっじいんぷ・ちぇーん",e:"Edge Imp Chain",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"「エッジインプ・チェーン」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードの攻撃宣言時に発動できる。\nデッキから「エッジインプ・チェーン」１体を手札に加える。\n②：このカードが手札・フィールドから墓地へ送られた場合に発動できる。\nデッキから「デストーイ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジインプ・トマホーク",h:"えっじいんぷ・とまほーく",e:"Edge Imp Tomahawk",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"「エッジインプ・トマホーク」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札の「エッジインプ」モンスター１体を墓地へ送って発動できる。\n相手に８００ダメージを与える。\n②：デッキから「エッジインプ・トマホーク」以外の「エッジインプ」モンスター１体を墓地へ送って発動できる。\nエンドフェイズまで、このカードは墓地へ送ったモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジインプ・DTモドキ",h:"えっじいんぷ・でぃーてぃーもどき",e:"Edge Imp Frightfuloid",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"このカードはルール上「デストーイ」カードとしても扱う。\n「エッジインプ・DTモドキ」の効果は１ターンに１度しか使用できない。\n①：自分のフィールド・墓地の「デストーイ」融合モンスター１体を対象として発動できる。\nこのカードの攻撃力・守備力はターン終了時まで、そのモンスターの元々の数値と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－インペイル・レイニアス",h:"れいど・らぷたーず－いんぺいる・れいにあす",e:"Raidraptor - Sharp Lanius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"「RR－インペイル・レイニアス」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに１度だけ、フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にする。\n②：このカードが攻撃したターンの自分メインフェイズ２に、自分の墓地の「RR」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ミミクリー・レイニアス",h:"れいど・らぷたーず－みみくりー・れいにあす",e:"Raidraptor - Mimicry Lanius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"「RR－ミミクリー・レイニアス」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに１度だけ発動できる。\n自分フィールドの全ての「RR」モンスターのレベルを１つ上げる。\n②：このカードが墓地へ送られたターンの自分メインフェイズに、墓地のこのカードを除外して発動できる。\nデッキから「RR－ミミクリー・レイニアス」以外の「RR」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 木魅",h:"ようせんじゅう　こだま",e:"Yosenju Kodam",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「妖仙獣 木魅」の①の効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、自分フィールドの「修験の妖社」１枚を対象として発動できる。\nそのカードに妖仙カウンターを３つ置く。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「妖仙獣」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 大幽谷響",h:"ようせんじゅう　おおやまびこ",e:"Yosenju Oyam",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"「妖仙獣 大幽谷響」の①の効果は１ターンに１度しか使用できない。\n①：相手モンスターの直接攻撃宣言時に手札から「妖仙獣 大幽谷響」以外の「妖仙獣」モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードの攻撃力・守備力はターン終了時まで、戦闘を行う相手モンスターの元々の攻撃力と同じになる。\n③：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「妖仙獣」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜星因士－セフィラツバーン",h:"いーさてらないと－せふぃらつばーん",e:"Satellarknight Zefrathuban",t:"monster",a:"光",l:4,ps:1,pe:"①：自分は「テラナイト」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2100,tx:"「竜星因士－セフィラツバーン」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・P召喚に成功した場合、このカード以外の自分のモンスターゾーン・Pゾーンの、「テラナイト」カードまたは「セフィラ」カード１枚と、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"覚星輝士－セフィラビュート",h:"あすてらないと－せふぃらびゅーと",e:"Stellarknight Zefraxciton",t:"monster",a:"光",l:4,ps:7,pe:"①：自分は「テラナイト」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1900,de:0,tx:"「覚星輝士－セフィラビュート」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・P召喚に成功した場合、このカード以外の自分のモンスターゾーン・Pゾーンの、「テラナイト」カードまたは「セフィラ」カード１枚と、相手フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イェシャドール－セフィラナーガ",h:"いぇしゃどーる－せふぃらなーが",e:"Shaddoll Zefranaga",t:"monster",a:"闇",l:2,ps:1,pe:"①：自分は「シャドール」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:900,de:100,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがP召喚に成功した場合、またはこのカードが墓地へ送られた場合、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は自分のPゾーンに「セフィラ」カードが存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"オルシャドール－セフィラルーツ",h:"おるしゃどーる－せふぃらるーつ",e:"Shaddoll Zefracore",t:"monster",a:"闇",l:4,ps:7,pe:"①：自分は「シャドール」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"岩石族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:450,de:1950,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがP召喚に成功した場合、またはこのカードが墓地へ送られた場合、「オルシャドール－セフィラルーツ」以外の自分のPゾーンの「セフィラ」カード１枚を対象として発動できる。\nそのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"宝竜星－セフィラフウシ",h:"ほうりゅうせい－せふぃらふうし",e:"Zefraxi, Treasure of the Yang Zing",t:"monster",a:"地",l:3,ps:1,pe:"①：自分は「竜星」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"幻竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:0,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがP召喚またはデッキからの特殊召喚に成功した場合、「宝竜星－セフィラフウシ」以外の自分フィールドの、「竜星」モンスターまたは「セフィラ」モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターをチューナーとして扱う。\nこの効果を発動したこのカードは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"秘竜星－セフィラシウゴ",h:"ひりゅうせい－せふぃらしうご",e:"Zefraniu, Secret of the Yang Zing",t:"monster",a:"地",l:6,ps:7,pe:"①：自分は「竜星」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"幻竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2600,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがP召喚に成功した時、または自分のモンスターゾーンのこのカードが戦闘・効果で破壊された時に発動できる。\nデッキから「竜星」魔法・罠カードまたは「セフィラ」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"剣聖の影霊衣－セフィラセイバー",h:"けんせいのねくろす－せふぃらせいばー",e:"Zefrasaber, Swordmaster of the Nekroz",t:"monster",a:"水",l:4,ps:1,pe:"①：自分は「影霊衣」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:800,tx:"「剣聖の影霊衣－セフィラセイバー」のモンスター効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドのこのカードをリリースして発動できる。\nレベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、手札から「影霊衣」儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎獣の影霊衣－セフィラエグザ",h:"えんじゅうのねくろす－せふぃらえぐざ",e:"Zefraxa, Flame Beast of the Nekroz",t:"monster",a:"炎",l:5,ps:7,pe:"①：自分は「影霊衣」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:1000,tx:"「炎獣の影霊衣－セフィラエグザ」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、「炎獣の影霊衣－セフィラエグザ」以外の自分のモンスターゾーン・Pゾーンの、「影霊衣」カードまたは「セフィラ」カードが戦闘・効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"英霊獣使い－セフィラムピリカ",h:"えいれいじゅうつかい－せふぃらむぴりか",e:"Ritual Beast Tamer Zeframpilica",t:"monster",a:"風",l:3,ps:1,pe:"①：自分は「霊獣」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"サイキック族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:1500,tx:"自分は「英霊獣使い－セフィラムピリカ」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚・P召喚に成功した時、「英霊獣使い－セフィラムピリカ」以外の自分の墓地の、「霊獣」モンスターまたは「セフィラ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊獣使い－セフィラウェンディ",h:"えいれいじゅうつかい－せふぃらうぇんでぃ",e:"Ritual Beast Tamer Zefrawendi",t:"monster",a:"風",l:3,ps:7,pe:"①：自分は「霊獣」モンスター及び「セフィラ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"サイキック族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分は「影霊獣使い－セフィラウェンディ」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚・P召喚に成功した時に発動できる。\n自分のエクストラデッキから「影霊獣使い－セフィラウェンディ」以外の表側表示の「セフィラ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・シャイターン",h:"いんふぇるのいど・しゃいたーん",e:"Infernoid Pirmais",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター１体を除外した場合のみ手札から特殊召喚できる。\n①：１ターンに１度、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。\nこの効果の発動に対して対象のカードを発動できない。\n②：相手ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・ベルフェゴル",h:"いんふぇるのいど・べるふぇごる",e:"Infernoid Sjette",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター２体を除外した場合のみ手札・墓地から特殊召喚できる。\n①：このカードの攻撃宣言時に発動できる。\n相手はエクストラデッキからモンスター１体を選んで除外する。\n②：１ターンに１度、自分フィールドのモンスター１体をリリースし、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・リリス",h:"いんふぇるのいど・りりす",e:"Infernoid Devyaty",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:2900,tx:"このカードは通常召喚できない。\n自分フィールドの全ての効果モンスターのレベル・ランクの合計が８以下の時、自分の手札・墓地から「インフェルノイド」モンスター３体を除外した場合のみ手札・墓地から特殊召喚できる。\n①：このカードが特殊召喚に成功した時に発動できる。\n「煉獄」カード以外のフィールドの魔法・罠カードを全て破壊する。\n②：１ターンに１度、このカード以外のモンスターの効果が発動した時、自分フィールドのモンスター１体をリリースして発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"幽鬼うさぎ",h:"ゆきうさぎ",e:"Ghost Ogre & Snow Rabbit",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドのモンスターの効果が発動した時、またはフィールドの既に表側表示で存在している魔法・罠カードの効果が発動した時、手札・フィールドのこのカードを墓地へ送って発動できる。\nフィールドのそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マグマ・ドラゴン",h:"まぐま・どらごん",e:"Magma Dragon",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「マグマ・ドラゴン」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分は幻竜族モンスターしか特殊召喚できない。\n①：このカードが特殊召喚に成功した時、「マグマ・ドラゴン」以外の自分の墓地の幻竜族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００４",h:"ぶんぼーぐぜろぜろふぉー",e:"Deskbot 004",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nデッキから「ブンボーグ００４」以外の「ブンボーグ」モンスター１体を墓地へ送り、このカードの攻撃力・守備力はそのダメージ計算時のみ、墓地へ送ったそのモンスターのレベル×５００アップする。\nこの効果の発動後、ターン終了時まで相手が受ける戦闘ダメージは０になる。\n②：このカードが戦闘で相手モンスターを破壊した場合に発動できる。\n自分の手札・墓地からレベルの異なる「ブンボーグ」モンスター２体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔犬オクトロス",h:"まけんおくとろす",e:"Doomdog Octhros",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"「魔犬オクトロス」の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから悪魔族・レベル８モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"プリン隊",h:"ぷりんたい",e:"Putrid Pudding Body Buddies",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"このカードは融合・S・X召喚の素材にできない。\n①：このカードがモンスターゾーンに存在する限り、このカードはリリースできない。\n②：自分エンドフェイズに自分のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、このカードのコントロールを相手に移す。\n③：自分スタンバイフェイズに発動する。\n自分は３００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"sophiaの影霊衣",h:"そぴあのねくろす",e:"Nekroz of Sophia",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:3600,de:3400,tx:"「影霊衣」儀式魔法カードにより降臨。\nそれぞれ種族の異なる自分フィールドのモンスター３体を使用した手札からの儀式召喚でしか特殊召喚できない。\n①：自分・相手のメインフェイズ１に手札からこのカードと「影霊衣」魔法カード１枚を捨てて発動できる。\nそのフェイズの間、相手はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードが儀式召喚に成功した時に発動できる。\nこのカード以外のお互いのフィールド・墓地のカードを全て除外する。\nこの効果を発動するターン、自分は他のモンスターを通常召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音姫マイスタリン・シューベルト",h:"げんそうのおとひめまいすたりん・しゅーべると",e:"Schuberta the Melodious Maestra",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「幻奏」モンスター×２\n①：このカードがフィールドに表側表示で存在する限り１度だけ、お互いの墓地のカードを合計３枚まで対象として発動できる。\nそのカードを除外する。\nこのカードの攻撃力は、この効果で除外したカードの数×２００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の華歌聖ブルーム・ディーヴァ",h:"げんそうのはなかせいぶるーむ・でぃーう゛ぁ",e:"Bloom Diva the Melodious Choir",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:2000,tx:"「幻奏の音姫」モンスター＋「幻奏」モンスター\n①：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードが特殊召喚されたモンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターとこのカードの、元々の攻撃力の差分のダメージを相手に与え、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・ホイールソウ・ライオ",h:"ですとーい・ほいーるそう・らいお",e:"Frightfur Leo",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「エッジインプ・ソウ」＋「ファーニマル」モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n「デストーイ・ホイールソウ・ライオ」の効果は１ターンに１度しか使用できず、この効果を発動するターン、このカードは直接攻撃できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、破壊したモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・チェーン・シープ",h:"ですとーい・ちぇーん・しーぷ",e:"Frightfur Sheep",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「エッジインプ・チェーン」＋「ファーニマル」モンスター\n「デストーイ・チェーン・シープ」の②の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・マッド・キマイラ",h:"ですとーい・まっど・きまいら",e:"Frightfur Chimera",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「デストーイ」モンスター×３\nこのカードは融合召喚でしか特殊召喚できない。\n「デストーイ・マッド・キマイラ」の②の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターの攻撃力を半分にして自分フィールドに特殊召喚する。\n③：このカードの攻撃力は、元々の持ち主が相手となる自分フィールドのモンスターの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エルシャドール・アノマリリス",h:"えるしゃどーる・あのまりりす",e:"El Shaddoll Anoyatyllis",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:2000,tx:"「シャドール」モンスター＋水属性モンスター\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、お互いに魔法・罠カードの効果で手札・墓地からモンスターを特殊召喚できない。\n②：このカードが墓地へ送られた場合、自分の墓地の「シャドール」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖霊獣騎 ガイアペライオ",h:"せいれいじゅうき　がいあぺらいお",e:"Ritual Beast Ulti-Gaiapelio",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:2100,tx:"「聖霊獣騎」モンスター＋「霊獣使い」モンスター＋「精霊獣」モンスター\n自分フィールドの上記カードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：上記の方法で特殊召喚したこのカードは以下の効果を得る。\n\n●モンスターの効果・魔法・罠カードが発動した時、手札から「霊獣」カード１枚を除外して発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリアウィング・シンクロ・ドラゴン",h:"くりあうぃんぐ・しんくろ・どらごん",e:"Clear Wing Synchro Dragon",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、このカード以外のフィールドのレベル５以上のモンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n②：１ターンに１度、フィールドのレベル５以上のモンスター１体のみを対象とするモンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n③：このカードの効果でモンスターを破壊した場合、このカードの攻撃力はターン終了時まで、このカードの効果で破壊したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻竜星－チョウホウ",h:"げんりゅうせい－ちょうほう",e:"Chaofeng, Phantom of the Yang Zing",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2200,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：S召喚したこのカードがモンスターゾーンに存在する限り、相手はこのカードのS素材とした「竜星」モンスターと元々の属性が同じモンスターの効果を発動できない。\n②：S召喚したこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキからチューナー１体を手札に加える。\n③：１ターンに１度、相手フィールドのモンスターが戦闘・効果で破壊された時に発動できる。\nそのモンスター１体と元々の属性が同じ幻竜族モンスター１体を自分のデッキから守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ブレイズ・ファルコン",h:"れいど・らぷたーず－ぶれいず・ふぁるこん",e:"Raidraptor - Blaze Falcon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:2000,tx:"鳥獣族レベル５モンスター×３\n①：X素材を持っているこのカードは直接攻撃できる。\n②：このカードが相手に戦闘ダメージを与えた時、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの特殊召喚されたモンスターを全て破壊し、破壊したモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－レヴォリューション・ファルコン",h:"れいど・らぷたーず－れう゛ぉりゅーしょん・ふぁるこん",e:"Raidraptor - Revolution Falcon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:3000,tx:"鳥獣族レベル６モンスター×３\n①：このカードのX素材を１つ取り除いて発動できる。\nこのターン、このカードは相手モンスター全てに１回ずつ攻撃できる。\n②：このカードが特殊召喚された表側表示モンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターの攻撃力・守備力を０にする。\n③：このカードが「RR」XモンスターをX素材としている場合、以下の効果を得る。\n●１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、その攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"星守の騎士 プトレマイオス",h:"てらないとぷとれまいおす",e:"Tellarknight Ptolemaeus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:550,de:2600,tx:"レベル４モンスター×２体以上\n①：このカードのX素材を３つまたは７つ取り除いて発動できる。\n●３つ：「No.」モンスター以外の、このカードよりランクが１つ高いXモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果は相手ターンでも発動できる。\n●７つ：次の相手ターンをスキップする。\n②：お互いのエンドフェイズ毎に発動できる。\n自分のエクストラデッキの「ステラナイト」カード１枚を選び、このカードの下に重ねてX素材とする。",li:0,lk:[],ta:[],al:[]},
  {n:"マドルチェ・プディンセス・ショコ・ア・ラ・モード",h:"まどるちぇ・ぷでぃんせす・しょこ・あ・ら・もーど",e:"Madolche Puddingcess Chocolat-a-la-Mode",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2200,tx:"地属性レベル５モンスター×２\nこのカードは自分フィールドのランク４以下の「マドルチェ」Xモンスターの上にこのカードを重ねてX召喚する事もできる。\n①：１ターンに１度、自分の墓地の「マドルチェ」カード１枚を対象として発動できる。\nそのカードをデッキに戻す。\n②：このカードが「マドルチェ・プディンセス」をX素材としている場合に自分の墓地の「マドルチェ」カードがデッキに戻った時、このカードのX素材を１つ取り除いて発動できる。\nデッキから「マドルチェ」モンスター１体を表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMキャスト・チェンジ",h:"えんためいときゃすと・ちぇんじ",e:"Performapal Recasting",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「EMキャスト・チェンジ」は１ターンに１枚しか発動できない。\n①：手札の「EM」モンスターを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数＋１枚をデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"融合徴兵",h:"ゆうごうちょうへい",e:"Fusion Conscription",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：EXデッキの融合モンスター１体を相手に見せ、そのモンスターにカード名が記されている融合素材モンスター１体を自分のデッキ・墓地から選んで手札に加える。\nこのカードの発動後、ターン終了時まで自分はこの効果で手札に加えたモンスター及びその同名モンスターを通常召喚・特殊召喚できず、そのモンスター効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・ファクトリー",h:"ですとーい・ふぁくとりー",e:"Frightfur Factory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「デストーイ・ファクトリー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「融合」魔法カードまたは「フュージョン」魔法カード１枚を除外してこの効果を発動できる。\n自分の手札・フィールドから、「デストーイ」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n②：このカードが墓地へ送られた場合、除外されている自分の「魔玩具融合」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"縫合蘇生",h:"ほうごうそせい",e:"Suture Rebirth",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「縫合蘇生」は１ターンに１枚しか発動できない。\n①：自分の墓地の、「ファーニマル」モンスターまたは「デストーイ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔玩具融合",h:"ですとーい・ふゅーじょん",e:"Frightfur Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「魔玩具融合」は１ターンに１枚しか発動できない。\n①：自分のフィールド・墓地から、「デストーイ」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－レヴォリューション・フォース",h:"らんくあっぷまじっく－れう゛ぉりゅーしょん・ふぉーす",e:"Rank-Up-Magic Revolution Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：発動ターンによって以下の効果を発動できる。\n●自分ターン：自分フィールドの「RR」Xモンスター１体を対象として発動できる。\nランクが１つ高い「RR」モンスター１体を、対象の自分のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\n●相手ターン：相手フィールドのX素材の無いXモンスター１体を対象として発動できる。\nそのXモンスターのコントロールを得る。\nその後、ランクが１つ高い「RR」モンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙大旋風",h:"ようせんだいせんぷう",e:"Yosen Whirlwind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"８００LPを払ってこのカードを発動できる。\n①：１ターンに１度、自分フィールドの表側表示の「妖仙獣」モンスターが手札に戻った場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：自分ターンにこのカードの①の効果を適用していない場合、そのターンのエンドフェイズにこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"セフィラの輝跡",h:"せふぃらのきせき",e:"Zefra Path",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分のPゾーンに「セフィラ」カードが２枚存在し、１と７のPスケールが適用されている場合にこのカードを発動できる。\n自分フィールドに「セフィラ」モンスター以外のモンスターが存在する場合、そのモンスターを全て持ち主のデッキに戻す。\n②：このカードが魔法＆罠ゾーンに存在する限り、お互いに手札・エクストラデッキからしかモンスターを特殊召喚できない。\n③：自分のPゾーンにカードが存在する限り、このカードは効果の対象にならず、自分のPゾーンのカードが破壊された場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"セフィラの神託",h:"せふぃらのしんたく",e:"Oracle of Zefra",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時にデッキから「セフィラ」モンスター１体を手札に加える。\n②：以下のモンスターを、「セフィラ」モンスターを使用して儀式召喚、または「セフィラ」モンスターを素材として特殊召喚した時、自分はそれぞれの効果を１ターンに１度ずつ発動できる。\n\n●儀式：フィールドのモンスター１体をデッキに戻す。\n\n●融合：手札のモンスター１体を特殊召喚する。\n\n●S：デッキのモンスター１体をデッキの一番上に置く。\n\n●X：デッキから１枚ドローし、その後手札を１枚捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の消華",h:"れんごくのしょうか",e:"Void Vanishment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「煉獄の消華」の①の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨ててこの効果を発動できる。\nデッキから「煉獄の消華」以外の「煉獄」魔法・罠カード１枚を手札に加える。\nこのターン、自分は「インフェルノイド」モンスター以外のモンスターを召喚・特殊召喚できない。\n②：自分の「インフェルノイド」モンスターが相手モンスターと戦闘を行ったダメージ計算後に、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\nその戦闘を行ったお互いのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシー・サイクロン",h:"ぎゃらくしー・さいくろん",e:"Galaxy Cyclone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：墓地のこのカードを除外し、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"共鳴する振動",h:"きょうめいするしんどう",e:"Harmonic Oscillation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手のPゾーンにカードが２枚存在する場合、そのカード２枚を対象として発動できる。\nそのカード２枚が相手のPゾーンに存在し、このターン自分がP召喚する場合、相手のPスケール一組でP召喚できる。\nその場合、エクストラデッキからしかモンスターをP召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・アライズ",h:"ぺんでゅらむ・あらいず",e:"Pendulum Rising",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ペンデュラム・アライズ」は１ターンに１枚しか発動できない。\n①：自分フィールドのモンスター１体を墓地へ送って発動できる。\nそのモンスターの元々のレベルと同じレベルのPモンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"予想GUY",h:"よそうがい",e:"Unexpected Dai",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\nデッキからレベル４以下の通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMピンチヘルパー",h:"えんためいとぴんちへるぱー",e:"Performapal Pinch Helper",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、相手モンスターの直接攻撃宣言時にこの効果を発動できる。\nその攻撃を無効にし、デッキから「EM」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\nその戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏のイリュージョン",h:"げんそうのいりゅーじょん",e:"Melodious Illusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「幻奏」モンスター１体を対象として発動できる。\nこのターン、その自分のモンスターは相手の魔法・罠カードの効果を受けず、１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・クレーン",h:"ふぁーにまる・くれーん",e:"Fluffal Crane",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示の「ファーニマル」モンスターが相手モンスターの攻撃または相手の効果で破壊され自分の墓地へ送られた時、破壊されたその自分のモンスター１体を対象として発動できる。\nそのモンスターを手札に加え、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・カスタム",h:"ですとーい・かすたむ",e:"Designer Frightfur",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の、「エッジインプ」モンスターまたは「ファーニマル」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターを融合素材とする場合、「デストーイ」モンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙郷の眩暈風",h:"ようせんきょうのめまいかぜ",e:"Dizzying Winds of Yosen Village",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールドにレベル６以上の「妖仙獣」モンスターが存在する場合にこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在し、自分のPゾーンに「妖仙獣」カードが存在する限り、フィールドにセットされたモンスター及び「妖仙獣」モンスター以外のフィールドの表側表示モンスターが効果で手札に戻る場合、手札に戻らず持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"セフィラの聖選士",h:"せふぃらのせいせんし",e:"Chosen of Zefra",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分のエクストラデッキの表側表示の「セフィラ」モンスターの種類によって、以下の効果を得る。\n\n●３種類以上：自分フィールドのモンスターの攻撃力は、お互いのエクストラデッキの表側表示のカードの数×１００アップする。\n\n●５種類以上：自分フィールドのモンスターは相手の効果では破壊されない。\n\n●８種類以上：自分フィールドのモンスターは相手の効果の対象にならない。\n\n●１０種類：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n相手の手札・フィールド・墓地のカードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"セフィラの神撃",h:"せふぃらのしんげき",e:"Zefra Divine Strike",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：モンスターの効果・魔法・罠カードが発動した時、自分のエクストラデッキから表側表示の「セフィラ」モンスター１体を除外して発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"遡洸する煉獄",h:"そこうするれんごく",e:"Void Purification",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「遡洸する煉獄」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手スタンバイフェイズ毎に、自分の墓地の「インフェルノイド」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを手札に加える。\n②：自分スタンバイフェイズ毎に、除外されている自分の「インフェルノイド」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを墓地に戻す。\n③：自分フィールドに「インフェルノイド」モンスター以外のモンスターが存在する場合にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"貪欲な瓶",h:"どんよくなかめ",e:"Jar of Avarice",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「貪欲な瓶」は１ターンに１枚しか発動できない。\n①：「貪欲な瓶」以外の自分の墓地のカード５枚を対象として発動できる。\nそのカード５枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"一回休み",h:"いっかいやすみ",e:"Lose 1 Turn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"特殊召喚されたモンスターが自分フィールドに存在しない場合にこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、特殊召喚されたモンスターは、そのターン終了時まで効果が無効化される。\n②：効果モンスターが攻撃表示で特殊召喚された場合にこの効果を発動する。\nそのモンスターを守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔の嘆き",h:"あくまのなげき",e:"Fiend Griefing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを相手のデッキに戻す。\nその後、自分のデッキから悪魔族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"深淵のスタングレイ",h:"しんえんのすたんぐれい",e:"Abyss Stungray",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（雷族・光・星５・攻１９００／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚されたこのカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"苦紋様の土像",h:"くもんようのどぞう",e:"Statue of Anguish Pattern",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（岩石族・地・星７・攻０／守２５００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカード以外のモンスター扱いの罠カードがモンスターゾーンに存在する限り、このカードの効果で特殊召喚されたこのカードは相手の効果の対象にならない。\n③：このカードの効果で特殊召喚されたこのカードが存在し、自分の魔法＆罠ゾーンのカードがモンスターゾーンに特殊召喚された場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"横取りボーン",h:"よこどりぼーん",e:"Monster Rebone",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手がモンスターを特殊召喚したターン、相手の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"再転",h:"さいころ",e:"Diceversity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、フィールドの元々のレベルと異なるレベルを持つモンスター１体を対象としてこの効果を発動できる。\nサイコロを１回振る。\n対象のモンスターのレベルは出た目と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"雷帝家臣ミスラ",h:"らいていかしんみすら",e:"Mithra the Thunder Vassal",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「雷帝家臣ミスラ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを手札から特殊召喚し、相手フィールドに「家臣トークン」（雷族・光・星１・攻８００／守１０００）１体を守備表示で特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにアドバンス召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎帝家臣ベルリネス",h:"えんていかしんべるりねす",e:"Berlineth the Firestorm Vassal",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「炎帝家臣ベルリネス」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\nこのカードを手札から特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\n相手の手札を確認し、その中からカード１枚を選んでエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"地帝家臣ランドローブ",h:"ちていかしんらんどろーぶ",e:"Landrobe the Rock Vassal",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「地帝家臣ランドローブ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターを裏側守備表示にする。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合、「地帝家臣ランドローブ」以外の自分の墓地の攻撃力８００／守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"風帝家臣ガルーム",h:"ふうていかしんがるーむ",e:"Garum the Storm Vassal",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「風帝家臣ガルーム」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのアドバンス召喚されたモンスター１体を持ち主の手札に戻して発動できる。\nこのカードを手札から特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\nデッキから「風帝家臣ガルーム」以外の攻撃力８００／守備力１０００のモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"邪帝家臣ルキウス",h:"じゃていかしんるきうす",e:"Lucius the Shadow Vassal",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「邪帝家臣ルキウス」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のレベル５以上のモンスター１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\n相手フィールドにセットされたカードを全て確認する。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"真帝王領域",h:"しんていおうりょういき",e:"Domain of the True Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分のエクストラデッキにカードが存在せず、自分フィールドにのみアドバンス召喚したモンスターが存在する場合、相手はエクストラデッキからモンスターを特殊召喚できない。\n②：自分のアドバンス召喚したモンスターの攻撃力は、相手モンスターに攻撃するダメージ計算時のみ８００アップする。\n③：１ターンに１度、自分メインフェイズにこの効果を発動できる。\n自分の手札の攻撃力２８００／守備力１０００のモンスター１体を選び、そのモンスターのレベルをターン終了時まで２つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"海皇子 ネプトアビス",h:"かいおうじ　ねぷとあびす",e:"Neptabyss, the Atlantean Prince",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"「海皇子 ネプトアビス」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「海皇子 ネプトアビス」以外の「海皇」モンスター１体を墓地へ送って発動できる。\nデッキから「海皇子 ネプトアビス」以外の「海皇」カード１枚を手札に加える。\n②：このカードが水属性モンスターの効果を発動するために墓地へ送られた場合、「海皇子 ネプトアビス」以外の自分の墓地の「海皇」モンスター１体を対象として発動する。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王の孤島",h:"えんおうのことう",e:"Fire King Island",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「炎王の孤島」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分メインフェイズにこの効果を発動できる。\n自分の手札・フィールドのモンスター１体を選んで破壊し、デッキから「炎王」モンスター１体を手札に加える。\n②：自分フィールドにモンスターが存在しない場合にこの効果を発動できる。\n手札の鳥獣族・炎属性モンスター１体を特殊召喚する。\n③：フィールドゾーンの表側表示のこのカードが、墓地へ送られた場合または除外された場合に発動する。\n自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"キメラテック・ランページ・ドラゴン",h:"きめらてっく・らんぺーじ・どらごん",e:"Chimeratech Rampage Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1600,tx:"「サイバー・ドラゴン」モンスター×２体以上\nこのカードの融合召喚は上記のカードでしか行えない。\n①：このカードが融合召喚に成功した時、このカードの融合素材としたモンスターの数までフィールドの魔法・罠カードを対象として発動できる。\nそのカードを破壊する。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから機械族・光属性モンスターを２体まで墓地へ送る。\nこのターン、このカードは通常の攻撃に加えて、この効果で墓地へ送ったモンスターの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・インフィニティ",h:"さいばー・どらごん・いんふぃにてぃ",e:"Cyber Dragon Infinity",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1600,tx:"機械族・光属性レベル６モンスター×３\n「サイバー・ドラゴン・インフィニティ」は１ターンに１度、自分フィールドの「サイバー・ドラゴン・ノヴァ」の上に重ねてX召喚する事もできる。\n①：このカードの攻撃力は、このカードのX素材の数×２００アップする。\n②：１ターンに１度、フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n③：１ターンに１度、カードの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"SNo.39 希望皇ホープ・ザ・ライトニング",h:"しゃいにんぐなんばーず３９ きぼうおうほーぷ・ざ・らいとにんぐ",e:"Number S39: Utopia the Lightning",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"光属性レベル５モンスター×３\nこのカードは自分フィールドのランク４の「希望皇ホープ」モンスターの上に重ねてX召喚する事もできる。\nこのカードはX召喚の素材にできない。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時までカードの効果を発動できない。\n②：このカードが「希望皇ホープ」モンスターをX素材としている場合、このカードが相手モンスターと戦闘を行うダメージ計算時に１度、このカードのX素材を２つ取り除いて発動できる。\nこのカードの攻撃力はそのダメージ計算時のみ５０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－トリビュート・レイニアス",h:"れいど・らぷたーず－とりびゅーと・れいにあす",e:"Raidraptor - Tribute Lanius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに発動できる。\nデッキから「RR」カード１枚を墓地へ送る。\n②：このカードが戦闘で相手モンスターを破壊したターンの自分メインフェイズ２に発動できる。\nデッキから「RUM」速攻魔法カード１枚を手札に加える。\nこの効果の発動後、ターン終了時まで自分は「RR」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・セイバー・ドラゴン",h:"おっどあいず・せいばー・どらごん",e:"Odd-Eyes Saber Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードが手札にある場合、自分フィールドの光属性モンスター１体をリリースして発動できる。\n手札・デッキ及び自分フィールドの表側表示モンスターの中から、「オッドアイズ・ドラゴン」１体を選んで墓地へ送り、このカードを特殊召喚する。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った時に発動できる。\n相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD覇龍王ペンドラゴン",h:"でぃーでぃーでぃーはりゅうおうぺんどらごん",e:"D/D/D Dragon King Pendragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:2400,tx:"①：このカードが手札にある場合、自分メインフェイズに自分の手札・フィールドからドラゴン族モンスターと悪魔族モンスターを１体ずつリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分メインフェイズに手札を１枚捨てて発動できる。\nこのカードの攻撃力はターン終了時まで５００アップする。\nその後、フィールドの魔法・罠カード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラコニアの海竜騎兵",h:"どらこにあのかいりゅうきへい",e:"Sea Dragoons of Draconia",t:"monster",a:"水",l:3,ps:7,pe:"「ドラコニアの海竜騎兵」のP効果は１ターンに１度しか使用できない。\n①：自分または相手のモンスターが戦闘で破壊された時に発動できる。\n手札から通常モンスター１体を特殊召喚する。",tr:"海竜族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:200,de:2100,tx:"龍人族の国、ドラコニア帝国が有する竜騎士団の海兵部隊。\n深海から音も無く忍び寄る隠密作戦に長けている。\n対岸のディノン公国兵とは、領海を巡り小競り合いが続いている状態である。",li:"",lk:[],ta:[],al:[]},
  {n:"月鏡の盾",h:"つきがみのたて",e:"Moon Mirror Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：このカードの装備モンスターが相手モンスターと戦闘を行うダメージ計算時に発動する。\n装備モンスターの攻撃力・守備力はダメージ計算時のみ、戦闘を行う相手モンスターの攻撃力と守備力の内、高い方の数値＋１００になる。\n②：表側表示のこのカードがフィールドから墓地へ送られた場合、５００LPを払って発動する。\nこのカードをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 ドラゴネル",h:"ひがんのあっき　どらごねる",e:"Draghig, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:900,tx:"「彼岸の悪鬼 ドラゴネル」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「彼岸」カード１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の悪鬼 バルバリッチャ",h:"ひがんのあっき　ばるばりっちゃ",e:"Barbar, Malebranche of the Burning Abyss",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"「彼岸の悪鬼 バルバリッチャ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、「彼岸の悪鬼 バルバリッチャ」以外の自分の墓地の「彼岸」カードを３枚まで対象として発動できる。\nそのカードを除外し、除外した数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"彼岸の巡礼者 ダンテ",h:"ひがんのじゅんれいしゃ　だんて",e:"Dante, Pilgrim of the Burning Abyss",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2500,tx:"カード名が異なる「彼岸」モンスター×３\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードは相手の効果の対象にならない。\n②：１ターンに１度、手札の「彼岸」カード１枚を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。\nこの効果は相手ターンでも発動できる。\n③：フィールドのこのカードが相手の効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合に発動できる。\n相手の手札をランダムに１枚選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"旅人の結彼岸",h:"たびびとのけつひがん",e:"The Terminus of the Burning Abyss",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「彼岸」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、フィールドの「彼岸」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は次の相手ターン終了時まで８００アップする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.ドレッドノートダンカー",h:"うるとらあすりーとどれっどのーとだんかー",e:"U.A. Dreadnought Dunker",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:1800,tx:"「U.A.ドレッドノートダンカー」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.ドレッドノートダンカー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが相手に戦闘ダメージを与えた時、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.コリバルリバウンダー",h:"うるとらあすりーとこりばるりばうんだー",e:"U.A. Rival Rebounder",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:2300,tx:"「U.A.コリバルリバウンダー」の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは「U.A.コリバルリバウンダー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカードが召喚または相手ターン中の特殊召喚に成功した場合に発動できる。\n自分の手札・墓地から「U.A.コリバルリバウンダー」以外の「U.A.」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.フラッグシップ・ディール",h:"うるとらあすりーとふらっぐしっぷ・でぃーる",e:"U.A. Signing Deal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「U.A.」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはS・X召喚の素材にできず、効果は無効化される。\nその後、自分はそのモンスターのレベル×３００LPを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.ペナルティ",h:"うるとらあすりーとぺなるてぃ",e:"U.A. Penalty Box",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「U.A.ペナルティ」の①の効果は１ターンに１度しか使用できない。\n①：自分の「U.A.」モンスターが相手モンスターと戦闘を行うダメージステップ開始時にこの効果を発動できる。\nその相手モンスターを、発動後２回目の相手エンドフェイズまで除外する。\n②：墓地のこのカードを除外して発動できる。\nデッキから「U.A.」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザー・シースネーク",h:"かいざー・しーすねーく",e:"Kaiser Sea Snake",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"「カイザー・シースネーク」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードがこのカードの①の方法で特殊召喚に成功した時に発動できる。\n自分の手札・墓地から海竜族・レベル８モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になる。\n③：特殊召喚したこのカードのレベルは４になり、元々の攻撃力は０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・ブレイカー",h:"じゃんく・ぶれいかー",e:"Junk Breaker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが召喚に成功したターンの自分メインフェイズに、このカードをリリースして発動できる。\nフィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"慧眼の魔術師",h:"けいがんのまじゅつし",e:"Wisdom-Eye Magician",t:"monster",a:"光",l:4,ps:5,pe:"①：もう片方の自分のPゾーンに「魔術師」カードまたは「EM」カードが存在する場合に発動できる。\nこのカードを破壊し、デッキから「慧眼の魔術師」以外の「魔術師」Pモンスター１体を選び、自分のPゾーンに置く。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードを手札から捨て、自分のPゾーンの、Pスケールが元々の数値と異なるカード１枚を対象として発動できる。\nそのカードのPスケールはターン終了時まで元々の数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻殻竜",h:"げんかくりゅう",e:"Mystery Shell Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"幻竜族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"別次元から突如として飛来した謎の生命体。\n高い攻撃力と奇襲能力を併せ持つ。\nその攻撃は対象物の神経を麻痺させ、強烈な幻覚症状を引き起こす。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚師ライズベルト",h:"しょうかんしらいずべると",e:"Risebell the Summoner",t:"monster",a:"風",l:3,ps:2,pe:"①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ上げる。",tr:"サイキック族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:800,de:800,tx:"妹セームベルをとても大事に想っている、心優しき兄ライズベルト。\n昼下がりの午後に妹と一緒に魔術書を読む時間は毎日の日課になっており、そんな二人の仲睦まじい様子に周囲の人々は自然と心が癒されてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"相克の魔術師",h:"そうこくのまじゅつし",e:"Xiangke Magician",t:"monster",a:"闇",l:7,ps:3,pe:"①：１ターンに１度、自分フィールドのXモンスター１体を対象として発動できる。\nこのターンそのXモンスターは、そのランクと同じ数値のレベルのモンスターとしてX召喚の素材にできる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:500,tx:"①：１ターンに１度、フィールドの光属性モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"相生の魔術師",h:"そうじょうのまじゅつし",e:"Xiangsheng Magician",t:"monster",a:"光",l:4,ps:8,pe:"①：１ターンに１度、自分フィールドの、Xモンスター１体とレベル５以上のモンスター１体を対象として発動できる。\nそのXモンスターのランクはターン終了時まで、そのレベル５以上のモンスターのレベルの数値と同じになる。\n②：自分フィールドのカードが相手フィールドより多い場合、このカードのPスケールは４になる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードの戦闘で発生する相手への戦闘ダメージは０になる。\n②：１ターンに１度、このカード以外の自分フィールドのモンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時までそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMラクダウン",h:"えんためいとらくだうん",e:"Performapal Camelump",t:"monster",a:"地",l:4,ps:2,pe:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\n相手フィールドの全てのモンスターの守備力はターン終了時まで８００ダウンし、このターン対象のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:1800,tx:"①：このカードが戦闘で破壊された場合に発動できる。\nこのカードを破壊したモンスターの攻撃力は８００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMドラミング・コング",h:"えんためいとどらみんぐ・こんぐ",e:"Performapal Drummerilla",t:"monster",a:"地",l:5,ps:2,pe:"①：１ターンに１度、自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に、その自分のモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はバトルフェイズ終了時まで６００アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:900,tx:"①：お互いのフィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：リリースなしで召喚したこのカードのレベルは４になる。\n③：１ターンに１度、自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に、その自分のモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はバトルフェイズ終了時まで６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者タイマ－２",h:"ちょうじゅうむしゃたいま－つー",e:"Superheavy Samurai Blowtorch",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"①：相手モンスターの攻撃宣言時に自分の墓地に魔法・罠カードが存在しない場合、このカードを手札から墓地へ送り、自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n攻撃対象をそのモンスターに移し替えてダメージ計算を行う。\n②：１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\n攻撃対象をこのカードに移し替えてダメージ計算を行う。\n③：このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女オペラ",h:"げんそうのおとめおぺら",e:"Opera the Melodious Diva",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"①：このカードは召喚・リバースしたターンには攻撃できない。\n②：このカードが融合召喚の素材となって墓地へ送られた場合に発動できる。\nこのターン、自分フィールドの「幻奏」モンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女タムタム",h:"げんそうのおとめたむたむ",e:"Tamtam the Melodious Diva",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：自分フィールドに「幻奏」モンスターが存在し、このカードが特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。\n②：このカードが融合召喚の素材となって墓地へ送られた場合、自分フィールドの「幻奏」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を５００ダウンし、相手に５００ダメージ与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・マウス",h:"ふぁーにまる・まうす",e:"Fluffal Mouse",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードの効果を発動するターン、自分は「デストーイ」モンスターしかエクストラデッキから特殊召喚できない。\n①：このカードがフィールドに表側表示で存在する限り１度だけ自分メインフェイズに発動できる。\nデッキから「ファーニマル・マウス」を２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDパンドラ",h:"でぃーでぃーぱんどら",e:"D/D Pandora",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:2100,tx:"①：このカードが戦闘または相手の効果で破壊され墓地へ送られた時、自分フィールドにカードが存在しない場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタル・ローズ",h:"くりすたる・ろーず",e:"Crystal Rose",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"「クリスタル・ローズ」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n手札・デッキから「ジェムナイト」モンスターまたは「幻奏」モンスター１体を墓地へ送る。\nエンドフェイズまで、このカードは墓地へ送ったモンスターと同名カードとして扱う。\n②：このカードが墓地に存在する場合、自分の墓地から融合モンスター１体を除外して発動できる。\nこのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ファジー・レイニアス",h:"れいど・らぷたーず－ふぁじー・れいにあす",e:"Raidraptor - Fuzzy Lanius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"「RR－ファジー・レイニアス」の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分は「RR」モンスターしか特殊召喚できない。\n①：自分フィールドに「RR－ファジー・レイニアス」以外の「RR」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「RR－ファジー・レイニアス」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－シンギング・レイニアス",h:"れいど・らぷたーず－しんぎんぐ・れいにあす",e:"Raidraptor - Singing Lanius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「RR－シンギング・レイニアス」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドにXモンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Emダメージ・ジャグラー",h:"えんためいじだめーじ・じゃぐらー",e:"Performage Damage Juggler",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「Emダメージ・ジャグラー」の③の効果は１ターンに１度しか使用できない。\n①：自分にダメージを与える魔法・罠・モンスターの効果が発動した時、このカードを手札から捨てて発動できる。\nその発動を無効にし破壊する。\n②：自分または相手のバトルフェイズにこのカードを手札から捨てて発動できる。\nこのターン自分が受ける戦闘ダメージを１度だけ０にする。\n③：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「Emダメージ・ジャグラー」以外の「Em」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"Emフレイム・イーター",h:"えんためいじふれいむ・いーたー",e:"Performage Flame Eater",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：自分にダメージを与える魔法・罠・モンスターの効果が発動した時に発動できる。\nこのカードを手札から特殊召喚し、その効果で自分が受けるダメージを０にする。\nこのターン、自分は「Em」モンスターしか特殊召喚できない。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが召喚・特殊召喚に成功した場合に発動する。\nお互いのプレイヤーは５００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"Emハットトリッカー",h:"えんためいじはっととりっかー",e:"Performage Hat Tricker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"①：フィールドにモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n②：自分にダメージを与える魔法・罠・モンスターの効果が発動した時に発動できる。\nこのカードにEmカウンターを１つ置く（最大３つまで）。\nその後、その効果で自分が受けるダメージを０にする。\n③：このカードにEmカウンターが３つ置かれた時にこのカードの攻撃力・守備力は３３００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"Emトリック・クラウン",h:"えんためいじとりっく・くらうん",e:"Performage Trick Clown",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、自分の墓地の「Em」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になる。\nその後、自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"Emスティルツ・シューター",h:"えんためいじすてぃるつ・しゅーたー",e:"Performage Stilts Launcher",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"「Emスティルツ・シューター」の②の効果は１ターンに１度しか使用できない。\n①：フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したターン、自分は通常召喚できない。\n②：自分の墓地にこのカード以外の「Em」モンスターが存在し、相手にダメージを与える魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\n相手に２０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の黒炎竜",h:"れっどあいず・ぶらっくふれあどらごん",e:"Red-Eyes Black Flare Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:2400,de:2000,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘を行ったバトルフェイズ終了時に発動できる。\nこのカードの元々の攻撃力分のダメージを相手に与える。\n「真紅眼の黒炎竜」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の黒石",h:"ぶらっく・おぶ・れじぇんど",e:"The Black Stone of Legend",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「伝説の黒石」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードをリリースして発動できる。\nデッキからレベル７以下の「レッドアイズ」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する場合、自分の墓地のレベル７以下の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒鋼竜",h:"ぶらっくめたるどらごん",e:"Black Metal Dragon",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：自分メインフェイズに自分フィールドの「レッドアイズ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを攻撃力６００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「レッドアイズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の凶雷皇－エビル・デーモン",h:"れっどあいず・らいとにんぐ・ろーど－えびる・でーもん",e:"Red-Eyes Archfiend of Lightning",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:2500,de:1200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力より低い守備力を持つ、相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊廟の守護者",h:"れいびょうのしゅごしゃ",e:"Keeper of the Shrine",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：ドラゴン族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n②：このカードが手札・墓地に存在し、「霊廟の守護者」以外のフィールドの表側表示のドラゴン族モンスターが効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\n墓地へ送られたモンスターが通常モンスターだった場合、さらに自分の墓地のドラゴン族の通常モンスター１体を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"竜剣士ラスターP",h:"りゅうけんしらすたーぺんでゅらむ",e:"Luster Pendulum, the Dracoslayer",t:"monster",a:"光",l:4,ps:5,pe:"①：１ターンに１度、もう片方の自分のPゾーンにカードが存在する場合に発動できる。\nそのカードを破壊し、そのカードの同名カード１枚をデッキから手札に加える。",tr:"ドラゴン族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:1850,de:0,tx:"このカードを素材として、「竜剣士」モンスター以外の融合・S・Xモンスターを特殊召喚する事はできない。",li:1,lk:[],ta:[],al:[]},
  {n:"イグナイト・マグナム",h:"いぐないと・まぐなむ",e:"Igknight Squire",t:"monster",a:"炎",l:3,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:0,de:2000,tx:"剣銃を操る炎の戦士。\n冷たい鋼鉄の鎧に身を包んでいるが、その奥には激しく燃え上がるような熱い心が秘められている。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・イーグル",h:"いぐないと・いーぐる",e:"Igknight Crusader",t:"monster",a:"炎",l:3,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1600,de:300,tx:"とても直情的で行動派なイグナイトの戦士。\n仲間たちからは「鉄砲玉のイーグル」と呼ばれ、少し距離を置かれがちである。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・ドラグノフ",h:"いぐないと・どらぐのふ",e:"Igknight Templar",t:"monster",a:"炎",l:4,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1700,de:1300,tx:"一本気で曲がったことが嫌いなイグナイトのベテラン戦士。\nその性格のせいか、近頃は自分の持つ武器に疑問を抱いているようだ。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・マスケット",h:"いぐないと・ますけっと",e:"Igknight Paladin",t:"monster",a:"炎",l:4,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1400,de:1900,tx:"冷静沈着で理知的と評判のイグナイトの参謀。\n実は頭に血が上るのに時間がかかっているだけで、心の中ではいつもキレ気味らしい。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・ライオット",h:"いぐないと・らいおっと",e:"Igknight Margrave",t:"monster",a:"炎",l:5,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1500,de:2500,tx:"イグナイトの上級戦士。\nところ構わず広範囲をなぎ払う得意の二刀剣銃は、敵だけでなく味方からも怖がられてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・キャリバー",h:"いぐないと・きゃりばー",e:"Igknight Gallant",t:"monster",a:"炎",l:6,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2100,de:2200,tx:"威勢の良すぎるイグナイトたちをまとめる特攻隊長。\n血気盛んですぐ頭に点火してしまう部下たちにいつも悩まされている。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・アヴェンジャー",h:"いぐないと・あう゛ぇんじゃー",e:"Igknight Lancer",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：自分フィールドの「イグナイト」カード３枚を対象として発動できる。\nそのカードを破壊し、このカードを手札から特殊召喚する。\n②：１ターンに１度、このカード以外の自分フィールドの「イグナイト」モンスター１体を対象として発動できる。\nそのカードを持ち主の手札に戻し、相手フィールドの魔法・罠カード１枚を選んで持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・スティンガー",h:"いぐないと・すてぃんがー",e:"Igknight Champion",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"①：自分フィールドの「イグナイト」カード３枚を対象として発動できる。\nそのカードを破壊し、このカードを手札から特殊召喚する。\n②：１ターンに１度、このカード以外の自分フィールドの「イグナイト」モンスター１体を対象として発動できる。\nそのカードを持ち主の手札に戻し、相手フィールドのモンスター１体を選んで持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマージ－ジャスミン",h:"あろまーじ－じゃすみん",e:"Aromage Jasmine",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:1900,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「アロマージ－ジャスミン」以外の植物族モンスター１体を召喚できる。\n②：１ターンに１度、自分のLPが回復した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマージ－カナンガ",h:"あろまーじ－かなんが",e:"Aromage Cananga",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力・守備力は５００ダウンする。\n②：１ターンに１度、自分のLPが回復した場合、相手フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマージ－ローズマリー",h:"あろまーじ－ろーずまりー",e:"Aromage Rosemary",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分の植物族モンスターが攻撃する場合、ダメージステップ終了時まで相手はモンスターの効果を発動できない。\n②：１ターンに１度、自分のLPが回復した場合、フィールドの表側表示モンスター１体を対象として発動する。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマージ－ベルガモット",h:"あろまーじ－べるがもっと",e:"Aromage Bergamot",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分の植物族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：１ターンに１度、自分のLPが回復した場合に発動する。\nこのカードの攻撃力・守備力は相手ターンの終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマポット",h:"あろまぽっと",e:"Aroma Jar",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:400,tx:"①：リバースしたこのカードがモンスターゾーンに表側表示で存在する限り、このカードは戦闘では破壊されない。\n②：リバースしたこのカードがモンスターゾーンに表側表示で存在する場合、お互いのエンドフェイズ毎に発動する。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・デカトロン",h:"いんふぇるのいど・でかとろん",e:"Infernoid Decatron",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「インフェルノイド・デカトロン」以外の「インフェルノイド」モンスター１体を墓地へ送る。\nこのカードのレベルをそのモンスターのレベル分だけ上げ、このカードはそのモンスターと同名カードとして扱い、同じ効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"獄落鳥",h:"ごくらくちょう",e:"Bird of Paradise Lost",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:2700,de:1500,tx:"①：このカードの攻撃力・守備力は、自分の墓地のチューナーの数×１００アップする。\n②：１ターンに１度、手札のチューナー１体を墓地へ送り、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・アブダクター",h:"まじかる・あぶだくたー",e:"Magical Abductor",t:"monster",a:"地",l:4,ps:3,pe:"①：このカードがPゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：１ターンに１度、このカードの魔力カウンターを３つ取り除いて発動できる。\nデッキからPモンスター１体を手札に加える。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×１００アップする。\n③：１ターンに１度、このカードの魔力カウンターを３つ取り除いて発動できる。\nデッキから魔法使い族・レベル１モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エキセントリック・デーモン",h:"えきせんとりっく・でーもん",e:"Archfiend Eccentrick",t:"monster",a:"光",l:3,ps:7,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：このカード以外のフィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードとこのカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・サイバー・ドラゴン",h:"とぅーん・さいばー・どらごん",e:"Toon Cyber Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["トゥーン","効果"],ma:"",tp:"",at:2100,de:1600,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n③：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００５",h:"ぶんぼーぐぜろぜろふぁいぶ",e:"Deskbot 005",t:"monster",a:"地",l:5,ps:10,pe:"①：自分は「ブンボーグ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:500,tx:"「ブンボーグ００５」の③のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードの攻撃力は、自分のエクストラデッキの表側表示の「ブンボーグ」モンスターの数×５００アップする。\n③：このカードがPゾーンで破壊された場合、自分の墓地の「ブンボーグ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"応戦するG",h:"おうせんするじー",e:"Retaliating \"C\"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：相手がモンスターを特殊召喚する効果を含む魔法カードを発動した時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの①の効果で特殊召喚されたこのカードがモンスターゾーンに存在する限り、墓地へ送られるカードは墓地へは行かず除外される。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「応戦するG」以外の攻撃力１５００以下の昆虫族・地属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD神託王ダルク",h:"でぃーでぃーでぃーしんたくおうだるく",e:"D/D/D Oracle King d'Arc",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「DD」モンスター×２\n①：このカードがモンスターゾーンに存在する限り、自分にダメージを与える効果は、自分のLPを回復する効果になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェムナイトレディ・ブリリアント・ダイヤ",h:"じぇむないとれでぃ・ぶりりあんと・だいや",e:"Gem-Knight Lady Brilliant Diamond",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:3400,de:2000,tx:"「ジェムナイト」モンスター×３\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n自分は「ジェムナイトレディ・ブリリアント・ダイヤ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドの表側表示の「ジェムナイト」モンスター１体を選んで墓地へ送り、エクストラデッキから「ジェムナイト」融合モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔竜ブラック・デーモンズ・ドラゴン",h:"あくまりゅうぶらっく・でーもんず・どらごん",e:"Archfiend Black Skull Dragon",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:2500,tx:"レベル６「デーモン」通常モンスター＋「レッドアイズ」通常モンスター\n自分は「悪魔竜ブラック・デーモンズ・ドラゴン」を１ターンに１度しか特殊召喚できない。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：融合召喚したこのカードが戦闘を行ったバトルフェイズ終了時、自分の墓地の「レッドアイズ」通常モンスター１体を対象として発動できる。\n墓地のそのモンスターの元々の攻撃力分のダメージを相手に与える。\nその後、そのモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルノイド・ティエラ",h:"いんふぇるのいど・てぃえら",e:"Infernoid Tierra",t:"monster",a:"炎",l:11,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3400,de:3600,tx:"「インフェルノイド・ネヘモス」＋「インフェルノイド・リリス」＋「インフェルノイド」モンスター１体以上\n①：このカードが融合召喚に成功した時に発動できる。\nその融合素材としたモンスターの種類によって以下の効果を適用する。\n\n●３種類以上：お互いはそれぞれ自分のエクストラデッキからカードを３枚選んで墓地へ送る。\n\n●５種類以上：お互いのデッキの上からカードを３枚墓地へ送る。\n\n●８種類以上：お互いはそれぞれ除外されている自分のカードを３枚まで選んで墓地へ戻す。\n\n●１０種類以上：お互いの手札のカードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"爆竜剣士イグニスターP",h:"ばくりゅうけんしいぐにすたーぷろみねんす",e:"Ignister Prominence, the Blasting Dracoslayer",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2850,de:0,tx:"チューナー＋チューナー以外のPモンスター１体以上\n①：１ターンに１度、フィールドのPモンスター１体またはPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、フィールドのカード１枚を選んで持ち主のデッキに戻す。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「竜剣士」モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはS召喚の素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王黒竜オッドアイズ・リベリオン・ドラゴン",h:"はおうこくりゅうおっどあいず・りべりおん・どらごん",e:"Odd-Eyes Rebellion Dragon",t:"monster",a:"闇",l:7,ps:4,pe:"①：１ターンに１度、もう片方の自分のPゾーンにカードが存在しない場合に発動できる。\nデッキからPモンスター１体を選び、自分のPゾーンに置く。",tr:"ドラゴン族",mo:["エクシーズ","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"ドラゴン族レベル７モンスター×２\nレベル７がP召喚可能な場合にEXデッキの表側表示のこのカードはP召喚できる。\n①：このカードがXモンスターを素材としてX召喚に成功した場合に発動する。\n相手フィールドのレベル７以下のモンスターを全て破壊し、破壊した数×１０００ダメージを相手に与える。\nこのターン、このカードは１度のバトルフェイズ中に３回攻撃できる。\n②：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\n自分のPゾーンのカードを全て破壊し、このカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD狙撃王テル",h:"でぃーでぃーでぃーそげきおうてる",e:"D/D/D Marksman King Tell",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:2000,tx:"レベル５モンスター×２\nこのカードは自分フィールドのランク４の「DDD」Xモンスターの上に重ねてX召喚する事もできる。\n①：自分が効果ダメージを受けたターンに１度、このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力を１０００ダウンし、相手に１０００ダメージを与える。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「DD」カードまたは「契約書」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"Emトラピーズ・マジシャン",h:"えんためいじとらぴーず・まじしゃん",e:"Performage Trapeze Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"魔法使い族レベル４モンスター×２\n①：このカードがモンスターゾーンに存在する限り、自分はこのカードの攻撃力以下の戦闘・効果ダメージを受けない。\n②：自分・相手のメインフェイズ１に１度、このカードのX素材を１つ取り除き、このカード以外のフィールドの表側攻撃表示モンスター１体を対象として発動できる。\nこのターンそのモンスターは２回攻撃でき、バトルフェイズ終了時に破壊される。\n③：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「Em」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の鋼炎竜",h:"れっどあいず・ふれあめたるどらごん",e:"Red-Eyes Flare Metal Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2400,tx:"レベル７モンスター×２\n①：X素材を持ったこのカードは効果では破壊されない。\n②：X素材を持ったこのカードがモンスターゾーンに存在する限り、相手が魔法・罠・モンスターの効果を発動する度に相手に５００ダメージを与える。\n③：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の「レッドアイズ」通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ピアニッシモ",h:"ぴあにっしも",e:"Pianissimo",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターの元々の攻撃力は１００になり、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリリアント・フュージョン",h:"ぶりりあんと・ふゅーじょん",e:"Brilliant Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時に、自分のデッキから「ジェムナイト」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体を、攻撃力・守備力を０にしてEXデッキから融合召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：１ターンに１度、手札の魔法カード１枚を捨てて発動できる。\nこのカードの効果で特殊召喚したモンスターの攻撃力・守備力は相手ターン終了時まで元々の数値分アップする。",li:1,lk:[],ta:[],al:[]},
  {n:"RUM－ラプターズ・フォース",h:"らんくあっぷまじっく－らぷたーず・ふぉーす",e:"Rank-Up-Magic Raptor's Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「RR」Xモンスターが破壊され墓地へ送られたターン、自分の墓地の「RR」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、そのモンスターよりランクが１つ高い「RR」モンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バリア・バブル",h:"ばりあ・ばぶる",e:"Bubble Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの全ての「EM」モンスター及び「Em」モンスターは、それぞれ１ターンに１度だけ戦闘・効果では破壊されない。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「EM」モンスター及び「Em」モンスターの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼融合",h:"れっどあいず・ふゅーじょん",e:"Red-Eyes Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・特殊召喚できない。\n①：自分の手札・デッキ・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「レッドアイズ」モンスターを融合素材とするその融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターのカード名は「真紅眼の黒竜」として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"紅玉の宝札",h:"こうぎょくのほうさつ",e:"Cards of the Red Stone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「紅玉の宝札」は１ターンに１枚しか発動できない。\n①：手札からレベル７の「レッドアイズ」モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。\nその後、デッキからレベル７の「レッドアイズ」モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"イグニッションP",h:"いぐにっしょんふぇにっくす",e:"Ignition Phoenix",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「イグナイト」モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分フィールドの「イグナイト」カード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「イグナイト」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマガーデン",h:"あろまがーでん",e:"Aroma Garden",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドに「アロマ」モンスターが存在する場合に発動できる。\n自分は５００LP回復する。\nこの効果の発動後、次の相手ターン終了時まで自分フィールドのモンスターの攻撃力・守備力は５００アップする。\n②：自分フィールドの「アロマ」モンスターが戦闘・効果で破壊され墓地へ送られた場合に発動する。\n自分は１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の虚夢",h:"れんごくのきょむ",e:"Void Imagination",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの元々のレベルが２以上の「インフェルノイド」モンスターは、レベルが１になり、相手に与える戦闘ダメージは半分になる。\n②：表側表示のこのカードを墓地へ送って発動できる。\n自分の手札・フィールドから、「インフェルノイド」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nエクストラデッキから特殊召喚されたモンスターが相手フィールドにのみ存在する場合、自分のデッキのモンスターも６体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"一騎加勢",h:"いっきかせい",e:"Back-Up Rider",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"捕違い",h:"とりちがい",e:"Mistaken Arrest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このカードの発動後、次の自分ターンの終了時まで、お互いにドロー以外の方法でデッキからカードを手札に加える事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"揺れる眼差し",h:"ゆれるまなざし",e:"Wavering Eyes",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：お互いのPゾーンのカードを全て破壊する。\nその後、この効果で破壊したカードの数によって以下の効果を適用する。\n\n●１枚以上：相手に５００ダメージを与える。\n\n●２枚以上：デッキからPモンスター１体を手札に加える事ができる。\n\n●３枚以上：フィールドのカード１枚を選んで除外できる。\n\n●４枚：デッキから「揺れる眼差し」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"チキンレース",h:"ちきんれーす",e:"Chicken Game",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、相手よりLPが少ないプレイヤーが受ける全てのダメージは０になる。\n②：お互いのプレイヤーは１ターンに１度、自分メインフェイズに１０００LPを払って以下の効果から１つを選択して発動できる。\nこの効果の発動に対して、お互いは魔法・罠・モンスターの効果を発動できない。\n\n●デッキから１枚ドローする。\n\n●このカードを破壊する。\n\n●相手は１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリリアント・スパーク",h:"ぶりりあんと・すぱーく",e:"Brilliant Spark",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「ブリリアント・スパーク」は１ターンに１枚しか発動できない。\n①：自分フィールドの「ジェムナイト」モンスターが相手モンスターの攻撃または相手の効果で破壊された場合、破壊されたそのモンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカードが墓地に存在する場合、手札の「ジェムナイト」カード１枚を墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－リターン",h:"れいど・らぷたーず－りたーん",e:"Raidraptor - Return",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「RR－リターン」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「RR」モンスターが戦闘で破壊された場合、自分の墓地の「RR」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：自分フィールドの「RR」モンスターが効果で破壊された場合、墓地のこのカードを除外して発動できる。\nデッキから「RR」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ラプターズ・ガスト",h:"らぷたーず・がすと",e:"Raptor's Gust",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「RR」カードが存在し、魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリック・ボックス",h:"とりっく・ぼっくす",e:"Trick Box",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「Em」モンスターが戦闘・効果で破壊され墓地へ送られた場合、相手フィールドのモンスター１体を対象として発動できる。\n自分はそのモンスターのコントロールをエンドフェイズまで得る。\nその後、自分の墓地の「Em」モンスター１体を選んで相手フィールドに特殊召喚する。\nこのターンのエンドフェイズに、この効果で特殊召喚したモンスターのコントロールは元々の持ち主に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の鎧旋",h:"りたーん・おぶ・れっどあいず",e:"Return of the Red-Eyes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「真紅眼の鎧旋」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「レッドアイズ」モンスターが存在する場合、自分の墓地の通常モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが相手の効果で破壊され墓地へ送られた場合、自分の墓地の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・バースト",h:"いぐないと・ばーすと",e:"Igknight Burst",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズにこの効果を発動できる。\nこのカード以外の自分フィールドの「イグナイト」カードを３枚まで選んで破壊する。\nその後、破壊した数だけ相手フィールドのカードを選んで持ち主の手札に戻す。\n②：このカードが墓地へ送られた場合に発動できる。\n自分のエクストラデッキの表側表示の「イグナイト」Pモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"潤いの風",h:"うるおいのかぜ",e:"Humid Winds",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：１０００LPを払って発動できる。\nデッキから「アロマ」モンスター１体を手札に加える。\n②：自分のLPが相手より少ない場合に発動できる。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"渇きの風",h:"かわきのかぜ",e:"Dried Winds",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「渇きの風」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のLPが回復した場合、相手フィールドの表側表示モンスター１体を対象としてこの効果を発動する。\nそのモンスターを破壊する。\n②：自分フィールドに「アロマ」モンスターが存在し、自分のLPが相手より３０００以上多い場合、その差分のLPを払ってこの効果を発動できる。\n攻撃力の合計が、この効果を発動するために払ったLPの数値以下になるように、相手フィールドの表側表示モンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神風のバリア －エア・フォース－",h:"しんぷうのばりあ　－えあ・ふぉーす－",e:"Storming Mirror Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの攻撃表示モンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"イタチの大暴発",h:"いたちのだいぼうはつ",e:"Ferret Flames",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスターの攻撃力の合計が自分のLPよりも高い場合に発動できる。\n相手フィールドの表側表示モンスターの攻撃力の合計が、自分のLP以下の数値となるように、相手は自身のフィールドの表側表示モンスターを選んで持ち主のデッキに戻さなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"裁きの天秤",h:"さばきのてんびん",e:"Balance of Judgment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドのカードの数が自分の手札・フィールドのカードの合計数より多い場合に発動できる。\n自分はその差の数だけデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"臨時収入",h:"えくすとらばっく",e:"Extra Buck",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分のEXデッキにカードが加わる度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードを墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"副作用？",h:"ふくさよう？",e:"Side Effects?",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手はデッキから１～３枚までの任意の枚数ドローする。\nその後、自分はこの効果で相手がドローした数×２０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"矮星竜 プラネター",h:"わいせいりゅう　ぷらねたー",e:"Dwarf Star Dragon Planeter",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードを召喚したターンのエンドフェイズに発動できる。\nデッキから光属性または闇属性のレベル７モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神アーク・マキナ",h:"ましんあーく・まきな",e:"Mekanikal Arkfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"①：このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分の手札・墓地から通常モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロード・セイント ミネルバ",h:"らいとろーど・せいんと みねるば",e:"Minerva, the Exalted Lightsworn",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:800,tx:"レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\n自分のデッキの上からカードを３枚墓地へ送る。\nその中に「ライトロード」カードがあった場合、その数だけ自分はデッキからドローする。\n②：このカードが戦闘または相手の効果で破壊された場合に発動できる。\n自分のデッキの上からカードを３枚墓地へ送る。\nその中に「ライトロード」カードがあった場合、その数までフィールドのカードを選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトロードの裁き",h:"らいとろーどのさばき",e:"Lightsworn Judgment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このカードをデッキの一番上に戻す。\n②：このカードが「ライトロード」モンスターの効果でデッキから墓地へ送られた場合に発動できる。\nデッキから「裁きの龍」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアンカー",h:"ぎあぎあんかー",e:"Geargianchor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした場合に発動できる。\nこのカード以外の自分フィールドの「ギアギア」モンスターの数まで、フィールドのモンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギアギアチェンジ",h:"ぎあぎあちぇんじ",e:"Geargia Change",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「ギアギアチェンジ」は１ターンに１枚しか発動できない。\n①：自分の墓地の「ギアギアーノ」モンスター２体以上を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚し、そのモンスターのみを素材としてXモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装戦士 ドラゴディウス",h:"まそうせんし　どらごでぃうす",e:"Dragodies, the Empowered Warrior",t:"monster",a:"光",l:4,ps:2,pe:"①：自分のモンスターが相手の表側表示モンスターと戦闘を行うダメージステップ開始時に手札を１枚捨てて発動できる。\nその戦闘を行う相手モンスターの攻撃力・守備力は半分になる。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：自分のモンスターゾーンのこのカードが相手モンスターの攻撃または相手の効果で破壊された場合に発動できる。\nこのターンのエンドフェイズに、デッキから「魔装戦士 ドラゴディウス」以外の攻撃力２０００以下の、戦士族または魔法使い族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装戦士 ドラゴノックス",h:"まそうせんし　どらごのっくす",e:"Dragonox, the Empowered Warrior",t:"monster",a:"闇",l:4,ps:7,pe:"①：相手モンスターの攻撃宣言時に発動できる。\nこのカードを破壊し、そのバトルフェイズを終了する。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：１ターンに１度、手札を１枚捨て、自分の墓地の攻撃力２０００以下の、戦士族または魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔装邪龍 イーサルウェポン",h:"まそうじゃりゅう　いーさるうぇぽん",e:"Aether, the Evil Empowering Dragon",t:"monster",a:"闇",l:6,ps:4,pe:"①：１ターンに１度、自分の墓地の「魔装戦士」モンスター１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2300,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"召魔装着",h:"しょうまそうちゃく",e:"Empowerment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのドラゴン族・戦士族・魔法使い族モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、手札を１枚捨てて発動できる。\nデッキから「魔装戦士」モンスター１体を特殊召喚する。\n③：１ターンに１度、自分の墓地の戦士族・魔法使い族モンスターを合計４体除外して発動できる。\nデッキから「イーサルウェポン」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"犬タウルス",h:"けんたうるす",e:"Caninetaur",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\n手札・デッキから獣族・獣戦士族・鳥獣族モンスター１体を墓地へ送り、このカードの攻撃力はバトルフェイズ終了時まで、墓地へ送ったそのモンスターのレベル×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナソーイング",h:"だいなそーいんぐ",e:"Dino-Sewing",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは戦闘では破壊されない。\n②：このカードが攻撃対象に選択された場合に発動する。\nこのカードの攻撃力・守備力は１０００アップする。\n③：このカードが攻撃したダメージ計算後に発動する。\nこのカードの②の効果でアップした数値は０に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"タツノオトシオヤ",h:"たつのおとしおや",e:"Mare Mare",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:2100,de:1400,tx:"このカードは幻竜族モンスターの効果でしか特殊召喚できない。\nこのカード名の効果は１ターンに３度まで使用できる。\n①：自分メインフェイズに発動できる。\nこのカードのレベルを１つ下げ、自分フィールドに「タツノコトークン」（幻竜族・水・星１・攻３００／守２００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"旧神ヌトス",h:"きゅうしんぬとす",e:"Elder Entity N'tss",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1200,tx:"Sモンスター＋Xモンスター\n自分フィールドの上記カードを墓地へ送った場合のみ特殊召喚できる。\n自分は「旧神ヌトス」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n手札からレベル４モンスター１体を特殊召喚する。\n②：このカードが墓地へ送られた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻夢境",h:"どりーむらんど",e:"Dreamland",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「幻夢境」は１ターンに１枚しか発動できない。\n①：フィールドのモンスターの種類によって、以下の効果を得る。\n\n●融合：１ターンに１度、自分の手札・フィールドのモンスターが効果で墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。\n\n●S：モンスターの召喚・特殊召喚成功時に自分はこの効果を発動できる。\nそのモンスターのレベルを１つ上げる。\n\n●X：自分エンドフェイズに発動する。\nフィールドのレベルが一番高いモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"合神竜ティマイオス",h:"がっしんりゅうてぃまいおす",e:"Timaeus the Knight of Destiny",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:"?",tx:"「伝説の騎士 ティマイオス」＋「伝説の騎士 クリティウス」＋「伝説の騎士 ヘルモス」\n自分フィールドの上記カードを墓地へ送った場合のみ特殊召喚できる（「融合」は必要としない）。\n①：このカードは他のカードの効果を受けない。\n②：このカードが戦闘を行うダメージ計算時に発動できる。\nこのカードの攻撃力・守備力は、フィールドのモンスターの一番高い攻撃力と同じになる。\n③：このカードが戦闘で破壊された時に発動できる。\n自分の手札・デッキ・墓地の「伝説の騎士」モンスター３体を選び、召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の騎士 クリティウス",h:"でんせつのきし　くりてぃうす",e:"Legendary Knight Critias",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードは通常召喚できない。\n「レジェンド・オブ・ハート」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した時、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその表側表示の魔法・罠カードを除外する。\n②：このカードが攻撃対象に選択された時、自分の墓地の罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"デス・ウイルス・ドラゴン",h:"です・ういるす・どらごん",e:"Doom Virus Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:1500,tx:"このカードは「クリティウスの牙」の効果で自分の手札・フィールドの「死のデッキ破壊ウイルス」を墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n相手フィールドのモンスター、相手の手札、相手ターンで数えて３ターンの間に相手がドローしたカードを全て確認し、その内の攻撃力１５００以上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"タイラント・バースト・ドラゴン",h:"たいらんと・ばーすと・どらごん",e:"Tyrant Burst Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2500,tx:"このカードは「クリティウスの牙」の効果で自分の手札・フィールドの「タイラント・ウィング」を墓地へ送った場合のみ特殊召喚できる。\n①：このカードは相手モンスター全てに１回ずつ攻撃できる。\n②：自分フィールドのモンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその自分のモンスターに装備する。\n③：このカードの効果でこのカードを装備したモンスターは、攻撃力・守備力が４００アップし、１度のバトルフェイズ中に３回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラーフォース・ドラゴン",h:"みらーふぉーす・どらごん",e:"Mirror Force Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:1200,tx:"このカードは「クリティウスの牙」の効果で自分の手札・フィールドの「聖なるバリア －ミラーフォース－」を墓地へ送った場合のみ特殊召喚できる。\n①：自分フィールドのモンスターが攻撃対象に選択された時、または相手の効果の対象になった時に発動できる。\n相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリティウスの牙",h:"くりてぃうすのきば",e:"The Fang of Critias",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードのカード名はルール上「伝説の竜 クリティウス」としても扱う。\n「クリティウスの牙」は１ターンに１枚しか発動できない。\n①：「クリティウスの牙」の効果でのみ特殊召喚できる融合モンスターカードに記された罠カード１枚を自分の手札・フィールドから墓地へ送る（そのカードがフィールドにセットされている場合、めくって確認する）。\nその後、その融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"タイラント・ウィング",h:"たいらんと・うぃんぐ",e:"Tyrant Wing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのドラゴン族モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力・守備力４００アップの装備カード扱いとして、そのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターは、１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードの効果でこのカードを装備したモンスターが相手モンスターを攻撃したターンのエンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説の騎士 ヘルモス",h:"でんせつのきし　へるもす",e:"Legendary Knight Hermos",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードは通常召喚できない。\n「レジェンド・オブ・ハート」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した時、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその表側表示の魔法・罠カードを除外する。\n②：１ターンに１度、このカードが攻撃対象に選択された時、自分の墓地の効果モンスター１体を対象として発動できる。\nこのカードは次の自分ターンのエンドフェイズまで、その墓地のモンスターと同名カードとして扱い、同じ効果を得る。",li:"",lk:[],ta:[],al:[]},
  {n:"タイムマジック・ハンマー",h:"たいむまじっく・はんまー",e:"Time Magic Hammer",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドの魔法使い族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nサイコロを１回振る。\nその相手モンスターを、出た目と同じ数のターン後のスタンバイフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ロケット・ヘルモス・キャノン",h:"ろけっと・へるもす・きゃのん",e:"Rocket Hermos Cannon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドの戦士族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターは、１度のバトルフェイズ中に２回攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"女神の聖弓－アルテミス",h:"めがみのせいきゅう－あるてみす",e:"Goddess Bow",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1500,de:1600,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドの戦士族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、自分・相手のバトルフェイズ中に１度だけ、相手が発動した魔法・罠・モンスターの効果を無効にする。\nこの効果を適用したバトルフェイズ中、装備モンスターは２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の黒竜剣",h:"れっどあいず・ぶらっくどらごん・そーど",e:"Red-Eyes Black Dragon Sword",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドのドラゴン族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを攻撃力１０００アップの装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターの攻撃力・守備力は、お互いのフィールド・墓地のドラゴン族モンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘルモスの爪",h:"へるもすのつめ",e:"The Claw of Hermos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードのカード名はルール上「伝説の竜 ヘルモス」としても扱う。\n「ヘルモスの爪」は１ターンに１枚しか発動できない。\n①：「ヘルモスの爪」の効果でのみ特殊召喚できる融合モンスターカードに記された種族のモンスター１体を自分の手札・フィールドから墓地へ送る（そのカードがフィールドにセットされている場合、めくって確認する）。\nその後、その融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブルマジックアームバインド",h:"だぶるまじっくあーむばいんど",e:"Double Magical Arm Bind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスター２体をリリースし、相手フィールドの表側表示モンスター２体を対象として発動できる。\nその表側表示モンスター２体のコントロールを自分エンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"ロード・オブ・ザ・レッド",h:"ろーど・おぶ・ざ・れっど",e:"Lord of the Red",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:2100,tx:"「レッドアイズ・トランスマイグレーション」により降臨。\n①：１ターンに１度、自分または相手が「ロード・オブ・ザ・レッド」以外の魔法・罠・モンスターの効果を発動した時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：１ターンに１度、自分または相手が「ロード・オブ・ザ・レッド」以外の魔法・罠・モンスターの効果を発動した時、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドアイズ・トランスマイグレーション",h:"れっどあいず・とらんすまいぐれーしょん",e:"Red-Eyes Transmigration",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ロード・オブ・ザ・レッド」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリース、またはリリースの代わりに自分の墓地の「レッドアイズ」モンスターを除外し、手札から「ロード・オブ・ザ・レッド」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒竜の聖騎士",h:"ないと・おぶ・ぶらっくどらごん",e:"Paladin of Dark Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:1200,tx:"「黒竜降臨」により降臨。\n「黒竜の聖騎士」の②の効果は１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃したダメージステップ開始時に発動する。\nそのモンスターを破壊する。\n②：このカードをリリースして発動できる。\n手札・デッキから「レッドアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒竜降臨",h:"こくりゅうこうりん",e:"Dark Dragon Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「黒竜の聖騎士」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が４以上になるようにモンスターをリリースし、手札から「黒竜の聖騎士」を儀式召喚する。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「レッドアイズ」魔法・罠カード１枚を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドアイズ・スピリッツ",h:"れっどあいず・すぴりっつ",e:"Red-Eyes Spirit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドアイズ・バーン",h:"れっどあいず・ばーん",e:"Red-Eyes Burn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「レッドアイズ・バーン」は１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示の「レッドアイズ」モンスターが戦闘・効果で破壊された場合、破壊されたそのモンスター１体を対象として発動できる。\nお互いのプレイヤーはそのモンスターの元々の攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・アンティーク・ギアゴーレム",h:"とぅーん・あんてぃーく・ぎあごーれむ",e:"Toon Ancient Gear Golem",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["トゥーン","効果"],ma:"",tp:"",at:3000,de:3000,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n④：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・キングダム",h:"とぅーん・きんぐだむ",e:"Toon Kingdom",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、自分のデッキの上からカード３枚を裏側表示で除外する。\n②：このカードはフィールドゾーンに存在する限り、カード名を「トゥーン・ワールド」として扱う。\n③：このカードがフィールドゾーンに存在する限り、自分フィールドのトゥーンモンスターは相手の効果の対象にならない。\n④：自分フィールドのトゥーンモンスターが戦闘・効果で破壊される場合、代わりに破壊されるモンスター１体につき１枚、自分のデッキの上からカードを裏側表示で除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ロールバック",h:"とぅーん・ろーるばっく",e:"Toon Rollback",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのトゥーンモンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"シャドー・トゥーン",h:"しゃどー・とぅーん",e:"Shadow Toon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「シャドー・トゥーン」は１ターンに１枚しか発動できない。\n①：自分フィールドに「トゥーン・ワールド」が存在する場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"コミックハンド",h:"こみっくはんど",e:"Comic Hand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドに「トゥーン・ワールド」が存在する場合に相手フィールドのモンスターに装備できる。\n①：装備モンスターのコントロールを得る。\n②：装備モンスターはトゥーンモンスターとしても扱い、相手フィールドにトゥーンモンスターが存在しない場合、装備モンスターは直接攻撃できる。\n③：フィールドに「トゥーン・ワールド」が存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・マスク",h:"とぅーん・ますく",e:"Toon Mask",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「トゥーン・ワールド」が存在する場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルまたはランクの数値以下のレベルを持つトゥーンモンスター１体を、召喚条件を無視して手札・デッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーンのかばん",h:"とぅーんのかばん",e:"Toon Briefcase",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドにトゥーンモンスターが存在し、相手がモンスターを召喚・反転召喚・特殊召喚した時に発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"占術姫コインノーマ",h:"せんじゅつきこいんのーま",e:"Prediction Princess Coinorma",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1400,tx:"①：このカードがリバースした場合に発動できる。\n手札・デッキからレベル３以上のリバースモンスター１体を裏側守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「占術姫」モンスター以外のモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"占術姫ペタルエルフ",h:"せんじゅつきぺたるえるふ",e:"Prediction Princess Petalelf",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:700,tx:"①：このカードがリバースした場合に発動できる。\n相手フィールドの表側攻撃表示モンスターを全て守備表示にする。\nこの効果で守備表示になったモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"占術姫ウィジャモリガン",h:"せんじゅつきうぃじゃもりがん",e:"Prediction Princess Astromorrigan",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:1300,de:0,tx:"①：このカードがリバースした場合に発動する。\nそのターンのエンドフェイズに相手フィールドの守備表示モンスターを全て破壊し、破壊したモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"占術姫アローシルフ",h:"せんじゅつきあろーしるふ",e:"Prediction Princess Arrowsylph",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1400,tx:"①：このカードがリバースした場合に発動できる。\n自分のデッキ・墓地から儀式魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"占術姫クリスタルウンディーネ",h:"せんじゅつきくりすたるうんでぃーね",e:"Prediction Princess Crystaldine",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:2200,tx:"①：このカードがリバースした場合に発動できる。\n自分のデッキ・墓地から儀式モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖占術姫タロットレイ",h:"せいせんじゅつきたろっとれい",e:"Prediction Princess Tarotrei",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1200,tx:"「聖占術の儀式」により降臨。\n「聖占術姫タロットレイ」の①②の効果は１ターンに１度、いずれか１つしか使用できず、相手ターンでも発動できる。\n①：フィールドの裏側表示モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示にする。\n②：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\n③：自分エンドフェイズに発動できる。\n自分の手札・墓地のリバースモンスター１体を選んで裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖占術の儀式",h:"せいせんじゅつのぎしき",e:"Prediction Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「聖占術姫タロットレイ」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が９以上になるようにモンスターをリリースし、手札から「聖占術姫タロットレイ」を儀式召喚する。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「占術姫」モンスター１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"黒猫の睨み",h:"くろねこのにらみ",e:"Black Cat-astrophe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに裏側守備表示モンスターが２体以上存在する場合、相手バトルフェイズに発動できる。\nそのバトルフェイズを終了する。\n②：墓地のこのカードを除外し、「占術姫」モンスターを含むフィールドの表側表示モンスター２体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"リバース・リユース",h:"りばーす・りゆーす",e:"Reverse Reuse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のリバースモンスターを２体まで対象として発動できる。\nそのモンスターを表側守備表示または裏側守備表示で相手フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アクアアクトレス・テトラ",h:"あくああくとれす・てとら",e:"Aquaactress Tetra",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「アクアリウム」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アクアアクトレス・グッピー",h:"あくああくとれす・ぐっぴー",e:"Aquaactress Guppy",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「アクアアクトレス」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アクアアクトレス・アロワナ",h:"あくああくとれす・あろわな",e:"Aquaactress Arowana",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「アクアアクトレス」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"水舞台",h:"あくありうむ・すてーじ",e:"Aquarium Stage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの水属性モンスターは、水属性以外のモンスターとの戦闘では破壊されない。\n②：自分フィールドの「アクアアクトレス」モンスターは相手モンスターの効果を受けない。\n③：このカードがフィールドから墓地へ送られた場合、自分の墓地の水族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は水族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水舞台装置",h:"あくありうむ・せっと",e:"Aquarium Set",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの水属性モンスターの攻撃力・守備力は３００アップする。\n②：自分フィールドの「アクアアクトレス」モンスターの攻撃力・守備力は３００アップする。\n③：このカードがフィールドから墓地へ送られた場合、自分の墓地の水族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は水族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水照明",h:"あくありうむ・らいてぃんぐ",e:"Aquarium Lighting",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「水照明」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分の「アクアアクトレス」モンスターが相手モンスターと戦闘を行うダメージ計算時に発動する。\nその自分のモンスターの攻撃力・守備力はダメージ計算時のみ倍になる。\n③：このカードがフィールドから墓地へ送られた場合、自分の墓地の水族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は水族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水物語－ウラシマ",h:"あくあ・すとーりー－うらしま",e:"Aqua Story - Urashima",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地に「アクアアクトレス」モンスターが存在する場合、フィールドのモンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの効果は無効化され、攻撃力・守備力は１００になり、相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ビショップ",h:"いんふぇるにてぃ・びしょっぷ",e:"Infernity Patriarch",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分の手札がこのカード１枚のみの場合、このカードは手札から特殊召喚できる。\n②：自分の手札が０枚である限り、自分フィールドの「インフェルニティ」モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴゴゴアリステラ＆デクシア",h:"ごごごありすてらあんどでくしあ",e:"Gogogo Aristera & Dexia",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"①：このカードとこのカード以外の「ゴゴゴ」モンスターがモンスターゾーンに存在する限り、相手は「ゴゴゴ」モンスターを攻撃対象に選択できず、効果の対象にもできない。\n②：このカードを含む「ゴゴゴ」モンスターのみを素材としたXモンスターは以下の効果を得る。\n●このX召喚に成功した時、相手フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にして守備力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"邪神官チラム・サバク",h:"じゃしんかんちらむ・さばく",e:"Wicked Acolyte Chilam Sabak",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2500,de:0,tx:"「邪神官チラム・サバク」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札が５枚以上の場合、このカードはリリースなしで召喚できる。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nこのカードを墓地から守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードはチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ギャラクシー・ワーム",h:"ぎゃらくしー・わーむ",e:"Galaxy Worm",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードが召喚に成功した時、自分フィールドにこのカード以外のモンスターが存在しない場合に発動できる。\nデッキからレベル３以下の「ギャラクシー」効果モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ラーの翼神竜－球体形",h:"らーのよくしんりゅう－すふぃあ・もーど",e:"The Winged Dragon of Ra - Sphere Mode",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは特殊召喚できない。\nこのカードを通常召喚する場合、自分フィールドのモンスター３体をリリースして自分フィールドに召喚、または相手フィールドのモンスター３体をリリースして相手フィールドに召喚しなければならず、召喚したこのカードのコントロールは次のターンのエンドフェイズに元々の持ち主に戻る。\n①：このカードは攻撃できず、相手の攻撃・効果の対象にならない。\n②：このカードをリリースして発動できる。\n手札・デッキから「ラーの翼神竜」１体を、召喚条件を無視し、攻撃力・守備力を４０００にして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の遡刻竜",h:"れっどあいず・とれーさーどらごん",e:"Red-Eyes Retro Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"①：自分フィールドのレベル７以下の「レッドアイズ」モンスターが相手モンスターの攻撃または相手の効果で破壊され自分の墓地へ送られた場合に発動できる。\nこのカードを手札から守備表示で特殊召喚し、可能な限りその破壊されたモンスターを破壊された時と同じ表示形式で特殊召喚する。\n②：このカードをリリースして発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「レッドアイズ」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"M・HERO ブラスト",h:"ますくどひーろー　ぶらすと",e:"Masked HERO Blast",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を半分にする。\n②：１ターンに１度、５００LPを払い、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖珖神竜 スターダスト・シフル",h:"せいこうしんりゅう　すたーだすと・しふる",e:"Stardust Sifr Divine Dragon",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター２体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：自分フィールドのカードはそれぞれ１ターンに１度だけ戦闘・効果では破壊されない。\n②：１ターンに１度、相手がモンスターの効果を発動した時に発動できる。\nその効果を無効にし、フィールドのカード１枚を選んで破壊する。\n③：墓地のこのカードを除外し、自分の墓地のレベル８以下の「スターダスト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SNo.0 ホープ・ゼアル",h:"しゃいにんぐなんばーず０ ほーぷ・ぜある",e:"Number S0: Utopic ZEXAL",t:"monster",a:"光",l:"",ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"同じランクの「No.」Xモンスター×３\nルール上、このカードのランクは１として扱う。\nこのカードは手札の「RUM」通常魔法カード１枚を捨て、自分フィールドの「希望皇ホープ」モンスターの上に重ねてX召喚する事もできる。\n①：このカードのX召喚は無効化されない。\n②：このカードのX召喚成功時には、相手は効果を発動できない。\n③：このカードの攻撃力・守備力はこのカードのX素材の数×１０００アップする。\n④：相手ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン相手は効果を発動できない。",li:0,lk:[],ta:[],al:[]},
  {n:"法眼の魔術師",h:"ほうがんのまじゅつし",e:"Dharma-Eye Magician",t:"monster",a:"光",l:7,ps:2,pe:"①：１ターンに１度、手札のPモンスター１体を相手に見せ、自分のPゾーンの「魔術師」カード１枚を対象として発動できる。\nその「魔術師」カードのPスケールはターン終了時まで、見せたPモンスターのPスケールと同じになる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2500,tx:"①：このターンにP召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの「魔術師」Pモンスターは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜脈の魔術師",h:"りゅうみゃくのまじゅつし",e:"Dragonpulse Magician",t:"monster",a:"地",l:4,ps:1,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「魔術師」カードが存在する場合、手札のPモンスター１体を捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1800,de:900,tx:"元気だけが取り得の駆け出しの少年魔術師。\n実は無意識のうちに大地に眠る竜の魂を知覚する能力を有しており、まだ半人前ながらその資質の高さには師匠の「竜穴の魔術師」も一目置いている。",li:"",lk:[],ta:[],al:[]},
  {n:"竜穴の魔術師",h:"りゅうけつのまじゅつし",e:"Dragonpit Magician",t:"monster",a:"水",l:7,ps:8,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「魔術師」カードが存在する場合、手札のPモンスター１体を捨て、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:900,de:2700,tx:"若くして竜の魂を呼び覚ます神通力を体得した天才魔術師。\nその寡黙でストイックな魔術への姿勢から人付き合いは苦手だが、弟子の「竜脈の魔術師」にいつも振り回され、調子を狂わされている。",li:"",lk:[],ta:[],al:[]},
  {n:"貴竜の魔術師",h:"きりゅうのまじゅつし",e:"Nobledragon Magician",t:"monster",a:"炎",l:3,ps:5,pe:"①：もう片方の自分のPゾーンに「魔術師」カードが存在しない場合にこのカードは破壊される。",tr:"魔法使い族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:700,de:1400,tx:"このカードをS素材とする場合、ドラゴン族モンスターのS召喚にしか使用できず、他のS素材に「オッドアイズ」モンスター以外のモンスターを使用した場合、このカードを持ち主のデッキの一番下に戻す。\n①：このカードが手札・墓地に存在する場合、自分フィールドのレベル７以上の「オッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターのレベルを３つ下げ、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"賤竜の魔術師",h:"せんりゅうのまじゅつし",e:"Oafdragon Magician",t:"monster",a:"風",l:6,ps:2,pe:"「賤竜の魔術師」のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンに「魔術師」カードが存在する場合に発動できる。\n自分のエクストラデッキの表側表示の、「賤竜の魔術師」以外の「魔術師」Pモンスターまたは「オッドアイズ」Pモンスター１体を選んで手札に加える。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2100,de:1400,tx:"「賤竜の魔術師」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地の、「賤竜の魔術師」以外の「魔術師」Pモンスターまたは「オッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMドクロバット・ジョーカー",h:"えんためいとどくろばっと・じょーかー",e:"Performapal Skullcrobat Joker",t:"monster",a:"闇",l:4,ps:8,pe:"①：自分は「EM」モンスター、「魔術師」Pモンスター、「オッドアイズ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:100,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「EMドクロバット・ジョーカー」以外の「EM」モンスター、「魔術師」Pモンスター、「オッドアイズ」モンスターの内、いずれか１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天空の虹彩",h:"てんくうのこうさい",e:"Sky Iris",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「天空の虹彩」の②の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、自分のPゾーンの、「魔術師」カード、「EM」カード、「オッドアイズ」カードは相手の効果の対象にならない。\n②：このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「オッドアイズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・コール",h:"ぺんでゅらむ・こーる",e:"Pendulum Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ペンデュラム・コール」は１ターンに１枚しか発動できず、「魔術師」PモンスターのP効果を発動したターンには発動できない。\n①：手札を１枚捨てて発動できる。\nカード名が異なる「魔術師」Pモンスター２体をデッキから手札に加える。\nこのカードの発動後、次の相手ターン終了時まで自分のPゾーンの「魔術師」カードは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・メテオバースト・ドラゴン",h:"おっどあいず・めておばーすと・どらごん",e:"Odd-Eyes Meteorburst Dragon",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「オッドアイズ・メテオバースト・ドラゴン」の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、自分のPゾーンのカード１枚を対象として発動できる。\nそのカードを特殊召喚する。\nこのターン、このカードは攻撃できない。\n②：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズ中にモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・アブソリュート・ドラゴン",h:"おっどあいず・あぶそりゅーと・どらごん",e:"Odd-Eyes Absolute Dragon",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2500,tx:"レベル７モンスター×２\n「オッドアイズ・アブソリュート・ドラゴン」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手のモンスターの攻撃宣言時に、このカードのX素材を１つ取り除いて発動できる。\nその攻撃を無効にする。\nその後、自分の手札・墓地から「オッドアイズ」モンスター１体を選んで特殊召喚できる。\n②：X召喚されたこのカードが墓地へ送られた場合に発動できる。\nエクストラデッキから「オッドアイズ・アブソリュート・ドラゴン」以外の「オッドアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"絶望神アンチホープ",h:"ぜつぼうしんあんちほーぷ",e:"Dystopia the Despondent",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:5000,de:5000,tx:"このカードは通常召喚できない。\n自分フィールドの表側表示のレベル１モンスター４体を墓地へ送った場合のみ手札・墓地から特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃できない。\n②：このカードが戦闘を行うバトルステップ中に１度、自分の墓地のレベル１モンスター１体を除外して発動できる。\nこのカードはそのダメージステップ終了時まで、他のカードの効果を受けず、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"真閃珖竜 スターダスト・クロニクル",h:"しんせんこうりゅう　すたーだすと・くろにくる",e:"Stardust Chronicle Spark Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター１体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：１ターンに１度、自分の墓地のSモンスター１体を除外して発動できる。\nこのカードはターン終了時まで、他のカードの効果を受けない。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手によって破壊された場合、除外されている自分のドラゴン族Sモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMセカンドンキー",h:"えんためいとせかんどんきー",e:"Performapal Secondonkey",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「EMセカンドンキー」以外の「EM」モンスター１体を墓地へ送る。\n自分のPゾーンにカードが２枚存在する場合、墓地へ送らず手札に加える事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMマンモスプラッシュ",h:"えんためいとまんもすぷらっしゅ",e:"Performapal Splashmammoth",t:"monster",a:"水",l:6,ps:4,pe:"①：１ターンに１度、自分フィールドに融合モンスターが特殊召喚された時に発動できる。\n自分のエクストラデッキから表側表示の「オッドアイズ」Pモンスター１体を特殊召喚する。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1900,de:2300,tx:"「EMマンモスプラッシュ」のモンスター効果はデュエル中に１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分フィールドから、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMヘルプリンセス",h:"えんためいとへるぷりんせす",e:"Performapal Helpprincess",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：自分が「EMヘルプリンセス」以外の「EM」モンスターの召喚・特殊召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ヌス－１０",h:"ちょうじゅうむしゃぬす－とう",e:"Superheavy Samurai Thief",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：自分の墓地に魔法・罠カードが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功したターン、自分は「超重武者」モンスターしか特殊召喚できない。\n②：このカードをリリースし、以下の効果から１つを選択して発動できる。\n●相手の魔法＆罠ゾーンのカード１枚を選んで破壊する。\nその後、破壊したその魔法・罠カードを自分フィールドにセットできる。\n●相手のPゾーンのカード１枚を選んで破壊する。\nその後、破壊したそのカードを自分のPゾーンに置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ヒキャ－Q",h:"ちょうじゅうむしゃひきゃ－く",e:"Superheavy Samurai Transporter",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"「超重武者ヒキャ－Q」の②の効果は１ターンに１度しか使用できない。\n①：自分の墓地に魔法・罠カードが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功したターン、自分は「超重武者」モンスターしか特殊召喚できない。\n②：自分の墓地に魔法・罠カードが存在しない場合、このカードをリリースして発動できる。\n手札からモンスターを２体まで相手フィールドに守備表示で特殊召喚する。\nその後、この効果で特殊召喚したモンスターの数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ツヅ－３",h:"ちょうじゅうむしゃつづ－み",e:"Superheavy Samurai Drum",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"「超重武者ツヅ－３」の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが破壊され墓地へ送られた場合、「超重武者ツヅ－３」以外の自分の墓地の「超重武者」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留ダブル・ホーン",h:"ちょうじゅうむしゃそうるだぶる・ほーん",e:"Superheavy Samurai Soulhorns",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:300,tx:"「超重武者装留ダブル・ホーン」の②の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは、１度のバトルフェイズ中に２回攻撃できる。\n②：このカードの効果でこのカードが装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留シャイン・クロー",h:"ちょうじゅうむしゃそうるしゃいん・くろー",e:"Superheavy Samurai Soulclaw",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"「超重武者装留シャイン・クロー」の②の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを攻撃力・守備力５００アップの装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは戦闘では破壊されない。\n②：このカードの効果でこのカードが装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・ウィング",h:"ふぁーにまる・うぃんぐ",e:"Fluffal Wings",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「ファーニマル・ウィング」の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「トイポット」が存在する場合、墓地のこのカードを除外し、自分の墓地の「ファーニマル」モンスター１体を対象として発動できる。\nそのモンスターを除外し、自分はデッキから１枚ドローする。\nその後、以下の効果を適用できる。\n●自分フィールドの「トイポット」１枚を選んで墓地へ送り、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"DDバフォメット",h:"でぃーでぃーばふぉめっと",e:"D/D Berfomet",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"①：１ターンに１度、「DDバフォメット」以外の自分フィールドの「DD」モンスター１体を対象とし、１～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。\nこの効果の発動後、ターン終了時まで自分は「DD」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"DDスワラル・スライム",h:"でぃーでぃーすわらる・すらいむ",e:"D/D Swirl Slime",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分メインフェイズに発動できる。\n「DDD」融合モンスターカードによって決められた、このカードを含む融合素材モンスターを手札から墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外して発動できる。\n手札から「DD」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDネクロ・スライム",h:"でぃーでぃーねくろ・すらいむ",e:"D/D Necro Slime",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合に発動できる。\n「DDD」融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分の墓地から除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ワイルド・ヴァルチャー",h:"れいど・らぷたーず－わいるど・う゛ぁるちゃー",e:"Raidraptor - Wild Vulture",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズにこのカードをリリースして発動できる。\nレベルの合計が６になるように、自分の手札・墓地から「RR」モンスター２体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－スカル・イーグル",h:"れいど・らぷたーず－すかる・いーぐる",e:"Raidraptor - Skull Eagle",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"「RR－スカル・イーグル」の①の効果は１ターンに１度しか使用できない。\n①：X素材のこのカードがXモンスターの効果を発動するために取り除かれ墓地へ送られた場合、墓地のこのカードを除外し、自分の墓地の「RR」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\nこのカードの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"Emミラー・コンダクター",h:"えんためいじみらー・こんだくたー",e:"Performage Mirror Conductor",t:"monster",a:"光",l:4,ps:3,pe:"①：１ターンに１度、フィールドの特殊召喚された表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、その攻撃力と守備力の内、低い方の数値と同じになる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:600,de:1400,tx:"①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力をターン終了時まで入れ替える。\nその後、自分は５００ダメージを受ける。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Emヒグルミ",h:"えんためいじひぐるみ",e:"Performage Plushfire",t:"monster",a:"炎",l:4,ps:5,pe:"「Emヒグルミ」のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の「Em」モンスターが戦闘・効果で破壊された場合に発動できる。\nPゾーンのこのカードを特殊召喚する。\nその後、自分は５００ダメージを受ける。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\n手札・デッキから「Emヒグルミ」以外の「Em」モンスター１体を特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"伝説のフィッシャーマン三世",h:"でんせつのふぃっしゃーまんさんせい",e:"The Legendary Fisherman III",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは通常召喚できない。\n自分フィールドの「伝説のフィッシャーマン」１体をリリースした場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した時に発動できる。\n相手フィールドのモンスターを全て除外する。\nこのターン、このカードは攻撃できない。\n②：フィールドのこのカードは戦闘・効果では破壊されず、魔法・罠カードの効果を受けない。\n③：１ターンに１度、自分メインフェイズに発動できる。\n除外されている相手のカードを全て墓地に戻し、このターン、相手が受ける戦闘・効果ダメージは１度だけ倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"A BF－霧雨のクナイ",h:"あさると　ぶらっくふぇざー－きりさめのくない",e:"Assault Blackwing - Kunai the Drizzle",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"①：このカードは自分フィールドの「BF」モンスター１体をリリースして手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードはチューナーとして扱う。\n②：１ターンに１度、自分フィールドのSモンスター１体を対象とし、１～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"疾走の暗黒騎士ガイア",h:"しっそうのあんこくきしがいあ",e:"Charging Gaia the Fierce Knight",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはリリースなしで召喚できる。\n②：リリースなしで召喚したこのカードの元々の攻撃力は１９００になる。\n③：このカードがリリースされた場合に発動できる。\nデッキから「カオス・ソルジャー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"クリボール",h:"くりぼーる",e:"Sphere Kuriboh",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：相手モンスターの攻撃宣言時、このカードを手札から墓地へ送って発動できる。\nその攻撃モンスターを守備表示にする。\n②：儀式召喚を行う場合、必要なレベル分のモンスターの内の１体として、墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超戦士の魂",h:"ちょうせんしのたましい",e:"Super Soldier Soul",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「超戦士の魂」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札の「カオス・ソルジャー」モンスター１体を墓地へ送って発動できる。\nこのカードは次の相手エンドフェイズまで、攻撃力が３０００になり、カード名を「カオス・ソルジャー」として扱う。\n②：墓地のこのカードを除外して発動できる。\nデッキから「開闢の騎士」または「宵闇の騎士」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"開闢の騎士",h:"かいびゃくのきし",e:"Beginning Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"「開闢の騎士」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを使用して儀式召喚した「カオス・ソルジャー」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n●このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。\n②：墓地のこのカードが除外された場合に発動できる。\nデッキから儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"宵闇の騎士",h:"よいやみのきし",e:"Evening Twilight Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"「宵闇の騎士」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを使用して儀式召喚した「カオス・ソルジャー」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n●１ターンに１度、自分メインフェイズに発動できる。\n相手の手札をランダムに１枚選び、次の相手エンドフェイズまで裏側表示で除外する。\n②：墓地のこのカードが除外された場合に発動できる。\nデッキから儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魔王ベクターP",h:"りゅうまおうべくたーぺんでゅらむ",e:"Vector Pendulum, the Dracoverlord",t:"monster",a:"闇",l:4,ps:3,pe:"①：このカードがPゾーンに存在する限り、相手のPゾーンのカードの効果は無効化される。",tr:"ドラゴン族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1850,de:0,tx:"この世界に突如として現れ、瞬く間に世界を蹂躙し尽くした竜魔族の大群を率いる魔王。\n“竜化の秘法”によって万物を悪しきドラゴンの姿に変えてしまうと言われているが、その力の正体はよく分かっていない。\n強大な魔力の源泉はこの次元のものではないとまで噂されている。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・キャット",h:"まじぇすぺくたー・きゃっと",e:"Majespecter Cat - Nekomata",t:"monster",a:"風",l:3,ps:2,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:1800,tx:"「マジェスペクター・キャット」の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのターンのエンドフェイズに、デッキから「マジェスペクター」カード１枚を手札に加える。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・ラクーン",h:"まじぇすぺくたー・らくーん",e:"Majespecter Raccoon - Bunbuku",t:"monster",a:"風",l:3,ps:5,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:900,tx:"「マジェスペクター・ラクーン」の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「マジェスペクター」モンスター１体を手札に加える。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・クロウ",h:"まじぇすぺくたー・くろう",e:"Majespecter Crow - Yata",t:"monster",a:"風",l:4,ps:5,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:1500,tx:"「マジェスペクター・クロウ」の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「マジェスペクター」魔法カード１枚を手札に加える。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・フォックス",h:"まじぇすぺくたー・ふぉっくす",e:"Majespecter Fox - Kyubi",t:"monster",a:"風",l:4,ps:2,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1000,tx:"「マジェスペクター・フォックス」の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「マジェスペクター」罠カード１枚を手札に加える。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・ユニコーン",h:"まじぇすぺくたー・ゆにこーん",e:"Majespecter Unicorn - Kirin",t:"monster",a:"風",l:6,ps:2,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「マジェスペクター・ユニコーン」の①のモンスター効果は１ターンに１度しか使用できない。\n①：自分のモンスターゾーンのPモンスター１体と相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:0,lk:[],ta:[],al:[]},
  {n:"イグナイト・デリンジャー",h:"いぐないと・でりんじゃー",e:"Igknight Cavalier",t:"monster",a:"炎",l:5,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2400,de:1200,tx:"仲間たちからちやほやされているイグナイトの紅一点。\n自慢の武器は敵よりも味方を射止める事の方が多いが、文句を言える者は誰もいない。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・ウージー",h:"いぐないと・うーじー",e:"Igknight Veteran",t:"monster",a:"炎",l:6,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1300,de:2700,tx:"「デリンジャー」のお目付け役であり親衛隊長。\n無鉄砲な彼女に翻弄されてばかりで、唯一の理解者である「キャリバー」にいつもぼやいている。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・スライム",h:"ぐれいどる・すらいむ",e:"Graydle Slime",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"「グレイドル・スライム」の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの「グレイドル」カード２枚を対象として発動できる。\nそのカードを破壊し、このカードを特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した時、自分の墓地の「グレイドル」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・アリゲーター",h:"ぐれいどる・ありげーたー",e:"Graydle Alligator",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"①：自分のモンスターゾーンのこのカードが戦闘または魔法カードの効果で破壊され墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその相手モンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、装備モンスターのコントロールを得る。\nこのカードがフィールドから離れた時に装備モンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・コブラ",h:"ぐれいどる・こぶら",e:"Graydle Cobra",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：自分のモンスターゾーンのこのカードが戦闘または罠カードの効果で破壊され墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその相手モンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、装備モンスターのコントロールを得る。\nこのカードがフィールドから離れた時に装備モンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・イーグル",h:"ぐれいどる・いーぐる",e:"Graydle Eagle",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"①：自分のモンスターゾーンのこのカードが戦闘またはモンスターの効果で破壊され墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその相手モンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、装備モンスターのコントロールを得る。\nこのカードがフィールドから離れた時に装備モンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"熟練の赤魔術士",h:"じゅくれんのあかまじゅつし",e:"Skilled Red Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「デーモン」モンスター１体を選んで特殊召喚する。\n③：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"竜宮のツガイ",h:"りゅうぐうのつがい",e:"Giant Pairfish",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"「竜宮のツガイ」の効果は１ターンに１度しか使用できない。\n①：手札のモンスター１体を捨てて発動できる。\nデッキからレベル４以下の幻竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・リボルバー・ドラゴン",h:"とぅーん・りぼるばー・どらごん",e:"Toon Barrel Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["トゥーン","効果"],ma:"",tp:"",at:2600,de:2200,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：１ターンに１度、フィールドのカード１枚を対象として発動できる。\nコイントスを３回行い、その内２回以上が表だった場合、そのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００６",h:"ぶんぼーぐぜろぜろしっくす",e:"Deskbot 006",t:"monster",a:"地",l:6,ps:1,pe:"①：自分は「ブンボーグ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:500,tx:"「ブンボーグ００６」の③のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\n②：このカードの攻撃力は、自分のエクストラデッキの表側表示の「ブンボーグ」モンスターの数×５００アップする。\n③：このカードがPゾーンで破壊された場合、自分の墓地の「ブンボーグ」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"禁忌の壺",h:"きんきのつぼ",e:"Pot of The Forbidden",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:2000,de:3000,tx:"「禁忌の壺」の効果は１ターンに１度しか使用できない。\n①：このカードがリバースした場合、以下の効果から１つを選択して発動できる。\n\n●自分はデッキから２枚ドローする。\n\n●フィールドの魔法・罠カードを全て持ち主の手札に戻す。\n\n●相手フィールドのモンスターを全て破壊する。\n\n●相手の手札を確認し、その中からカード１枚を選んで持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"Dr.フランゲ",h:"どくたーふらんげ",e:"Dr. Frankenderp",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"「Dr.フランゲ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、５００LPを払って発動できる。\n自分はデッキの一番上のカードを確認する。\nその後、確認したカードをデッキの一番下に戻すか、相手に見せて手札に加える。\n手札に加えた場合、次の自分ドローフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"超戦士カオス・ソルジャー",h:"ちょうせんしかおす・そるじゃー",e:"Black Luster Soldier - Super Soldier",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「超戦士の儀式」により降臨。\n自分は「超戦士カオス・ソルジャー」を１ターンに１度しか特殊召喚できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「暗黒騎士ガイア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・サーベル・タイガー",h:"ですとーい・さーべる・たいがー",e:"Frightfur Sabre-Tooth",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「デストーイ」融合モンスター＋「ファーニマル」モンスターまたは「エッジインプ」モンスター１体以上\n①：このカードが融合召喚に成功した時、自分の墓地の「デストーイ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「デストーイ」モンスターの攻撃力は４００アップする。\n③：モンスター３体以上を素材として融合召喚したこのカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD怒濤壊薙王カエサル・ラグナロク",h:"でぃーでぃーでぃーどとうかいちおうかえさる・らぐなろく",e:"D/D/D Wave Oblivion King Caesar Ragnarok",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:3000,tx:"「DDD」モンスター×２\n①：１ターンに１度、このカードが戦闘を行う攻撃宣言時に、このカード以外の自分フィールドの「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、このカードと戦闘を行うモンスター以外の相手フィールドの表側表示モンスター１体を選んで装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ボルテックス・ドラゴン",h:"おっどあいず・ぼるてっくす・どらごん",e:"Odd-Eyes Vortex Dragon",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:3000,tx:"「オッドアイズ」モンスター＋Pモンスター\n「オッドアイズ・ボルテックス・ドラゴン」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、相手フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n②：このカード以外のモンスターの効果・魔法・罠カードが発動した時に発動できる。\n自分のエクストラデッキから表側表示のPモンスター１体をデッキに戻し、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・デーモンズ・ドラゴン・スカーライト",h:"れっど・でーもんず・どらごん・すかーらいと",e:"Scarlight Red Dragon Archfiend",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードのカード名は、フィールド・墓地に存在する限り「レッド・デーモンズ・ドラゴン」として扱う。\n②：１ターンに１度、自分メインフェイズに発動できる。\nこのカード以外の、このカードの攻撃力以下の攻撃力を持つ特殊召喚された効果モンスターを全て破壊する。\nその後、この効果で破壊したモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"A BF－驟雨のライキリ",h:"あさると　ぶらっくふぇざー－しゅううのらいきり",e:"Assault Blackwing - Raikiri the Rain Shower",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：１ターンに１度、このカード以外の自分フィールドの「BF」モンスターの数まで、相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・ドラゴン",h:"ぐれいどる・どらごん",e:"Graydle Dragon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"水族チューナー＋チューナー以外のモンスター１体以上\n「グレイドル・ドラゴン」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時、そのS素材とした水属性モンスターの数まで相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、このカード以外の自分の墓地の水属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ・ジェット",h:"ぶんぼーぐ・じぇっと",e:"Deskbot Jet",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:500,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\n「ブンボーグ・ジェット」の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードの攻撃力・守備力は、フィールドの「ブンボーグ」カードの数×５００アップする。\n②：自分フィールドの「ブンボーグ」カード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「ブンボーグ」モンスター１体を特殊召喚する。\n③：自分フィールドの「ブンボーグ」カード１枚とフィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD双暁王カリ・ユガ",h:"でぃーでぃーでぃーそうぎょうおうかり・ゆが",e:"D/D/D Duo-Dawn King Kali Yuga",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3500,de:3000,tx:"レベル８「DD」モンスター×２\n①：このカードがX召喚に成功したターン、このカード以外のフィールドのカードの効果は発動できず、無効化される。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nフィールドの魔法・罠カードを全て破壊する。\nこの効果は相手ターンでも発動できる。\n③：このカードのX素材を１つ取り除き、自分の墓地の「契約書」魔法・罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－デビル・イーグル",h:"れいど・らぷたーず－でびる・いーぐる",e:"Raidraptor - Fiend Eagle",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:0,tx:"レベル３「RR」モンスター×２\n「RR－デビル・イーグル」の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、相手フィールドの特殊召喚された表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"昇竜剣士マジェスターP",h:"しょうりゅうけんしまじぇすたーぱらでぃん",e:"Majester Paladin, the Ascending Dracoslayer",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1850,de:2000,tx:"レベル４のPモンスター×２\n①：このカードがX召喚に成功した時に発動できる。\nこのターンのエンドフェイズに、デッキからPモンスター１体を手札に加える。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分のエクストラデッキから表側表示の「竜剣士」Pモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはX召喚の素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"シャッフル・リボーン",h:"しゃっふる・りぼーん",e:"Shuffle Reborn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに除外される。\n②：墓地のこのカードを除外し、自分フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻してシャッフルし、その後自分はデッキから１枚ドローする。\nこのターンのエンドフェイズに、自分の手札を１枚除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－レイド・フォース",h:"らんくあっぷまじっく－れいど・ふぉーす",e:"Rank-Up-Magic Raid Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのXモンスター１体を対象として発動できる。\nそのモンスターよりランクが１つ高い「RR」モンスター１体を、対象の自分のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\n②：墓地のこのカードと手札の「RR」カード１枚を除外し、「RUM－レイド・フォース」以外の自分の墓地の「RUM」魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ラプターズ・アルティメット・メイス",h:"らぷたーず・あるてぃめっと・めいす",e:"Raptor's Ultimate Mace",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「RR」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は１０００アップする。\n②：装備モンスターが、装備モンスターより攻撃力が高いモンスターの攻撃対象に選択された時に発動できる。\nデッキから「RUM」魔法カード１枚を手札に加え、その戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"超戦士の儀式",h:"ちょうせんしのぎしき",e:"Super Soldier Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス・ソルジャー」儀式モンスターの降臨に必要。\n「超戦士の儀式」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、レベルの合計が８になるようにモンスターをリリースし、手札から「カオス・ソルジャー」儀式モンスター１体を儀式召喚する。\n②：自分の墓地からこのカード及び光属性モンスター１体と闇属性モンスター１体を除外して発動できる。\n手札から「カオス・ソルジャー」儀式モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の場",h:"かおす・ふぃーるど",e:"Gateway to Chaos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「カオス・ソルジャー」儀式モンスターまたは「暗黒騎士ガイア」モンスター１体を手札に加える。\n②：このカードがフィールドゾーンに存在する限り、お互いの手札・フィールドからモンスターが墓地へ送られる度に、１体につき１つこのカードに魔力カウンターを置く（最大６つまで）。\n③：１ターンに１度、このカードの魔力カウンターを３つ取り除いて発動できる。\n自分はデッキから儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスティックP",h:"まじぇすてぃっくぺがさす",e:"Majesty's Pegasus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「マジェスティックP」の②の効果は１ターンに１度しか使用できない。\n①：フィールドの「マジェスペクター」モンスターの攻撃力・守備力は３００アップする。\n②：自分フィールドの魔法使い族・風属性モンスター１体をリリースして発動できる。\nデッキからレベル４以下の「マジェスペクター」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・ストーム",h:"まじぇすぺくたー・すとーむ",e:"Majespecter Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法使い族・風属性モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・サイクロン",h:"まじぇすぺくたー・さいくろん",e:"Majespecter Cyclone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法使い族・風属性モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・リロード",h:"いぐないと・りろーど",e:"Igknight Reload",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「イグナイト・リロード」は１ターンに１枚しか発動できない。\n①：手札のPモンスターを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数＋１枚をデッキからドローする。\nこのカードの発動後、ターン終了時まで自分はカードの効果でドローできない。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・インパクト",h:"ぐれいどる・いんぱくと",e:"Graydle Impact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「グレイドル・インパクト」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカード以外の自分フィールドの「グレイドル」カード１枚と相手フィールドのカード１枚を対象としてこの効果を発動できる。\nそのカードを破壊する。\n②：自分エンドフェイズにこの効果を発動できる。\nデッキから「グレイドル」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・フュージョン",h:"おっどあいず・ふゅーじょん",e:"Odd-Eyes Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「オッドアイズ・フュージョン」は１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n相手フィールドにモンスターが２体以上存在し、自分フィールドにモンスターが存在しない場合、自分のエクストラデッキの「オッドアイズ」モンスターも２体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ブレイド",h:"さいこ・ぶれいど",e:"Psychic Blade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「サイコ・ブレイド」は１ターンに１枚しか発動できない。\n１００の倍数のLPを払ってこのカードを発動できる（最大２０００まで）。\n①：装備モンスターの攻撃力・守備力は、このカードを発動するために払ったLPの数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"苦渋の決断",h:"くじゅうのけつだん",e:"Painful Decision",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからレベル４以下の通常モンスター１体を墓地へ送り、その同名モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"猪突猛進",h:"ちょとつもうしん",e:"Super Rush Headlong",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：属性を１つ宣言し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その自分の表側表示モンスターが、宣言した属性を持つ相手モンスターと戦闘を行う場合、そのダメージステップ開始時にその相手モンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・マーチ",h:"ですとーい・まーち",e:"Frightfur March",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドの「デストーイ」モンスターを対象とするモンスターの効果・魔法・罠カードを相手が発動した時に発動できる。\nその発動を無効にし破壊する。\nその後、以下の効果を適用できる。\n●対象となった「デストーイ」モンスター１体を墓地へ送り、レベル８以上の「デストーイ」融合モンスター１体を融合召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは、次の自分エンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"DDDの契約変更",h:"でぃーでぃーでぃーのけいやくへんこう",e:"D/D/D Contract Change",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に以下の効果から１つを選択して発動できる。\n\n●自分の墓地の「DDD」モンスター１体を選んで除外する。\n攻撃モンスターの攻撃力は、この効果で除外したモンスターの攻撃力分ダウンする。\n\n●デッキからレベル４以下の「DD」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"誤封の契約書",h:"ごふうのけいやくしょ",e:"Dark Contract with Errors",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドに「DD」モンスターが存在する場合にこの効果を発動できる。\nターン終了時まで、このカード以外のフィールドの罠カードの効果は無効化される。\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"転生の超戦士",h:"てんせいのちょうせんし",e:"Super Soldier Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「転生の超戦士」は１ターンに１枚しか発動できない。\n①：自分フィールドの「カオス・ソルジャー」モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、そのモンスターとカード名が異なる「カオス・ソルジャー」モンスター１体を、召喚条件を無視して手札から特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の「カオス・ソルジャー」モンスター１体を対象として発動できる。\nそのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超戦士の盾",h:"ちょうせんしのたて",e:"Super Soldier Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「カオス・ソルジャー」モンスターが存在し、フィールドのモンスターを対象とするモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n②：このカードが墓地に存在する場合、自分フィールドの魔力カウンターを１つ取り除いて発動できる。\n墓地のこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・トルネード",h:"まじぇすぺくたー・とるねーど",e:"Majespecter Tornado",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの魔法使い族・風属性モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・テンペスト",h:"まじぇすぺくたー・てんぺすと",e:"Majespecter Tempest",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドの魔法使い族・風属性モンスター１体をリリースして以下の効果を発動できる。\n \n●モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n \n●自分または相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・パラサイト",h:"ぐれいどる・ぱらさいと",e:"Graydle Parasite",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「グレイドル・パラサイト」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの直接攻撃宣言時にこの効果を発動できる。\n自分フィールドにモンスターが存在しない場合、デッキから「グレイドル」モンスター１体を攻撃表示で特殊召喚する。\n②：自分の「グレイドル」モンスターの直接攻撃宣言時に、相手の墓地のモンスター１体を対象として発動できる。\n相手フィールドにモンスターが存在しない場合、そのモンスターを相手フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・スプリット",h:"ぐれいどる・すぷりっと",e:"Graydle Split",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「グレイドル・スプリット」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、そのモンスターに装備する。\n②：自分メインフェイズに、このカードの効果で装備されているこのカードを墓地へ送って発動できる。\nこのカードを装備していたモンスターを破壊し、デッキから「グレイドル」モンスター２体を特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・エリア",h:"ぺんでゅらむ・えりあ",e:"Pendulum Area",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターがPモンスターのみの場合、自分のPゾーンのカード２枚を対象として発動できる。\nそのカード２枚を破壊し、このターンお互いにP召喚以外の特殊召喚ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急儀式術",h:"きんきゅうぎしきじゅつ",e:"Urgent Ritual Art",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに儀式モンスターが存在しない場合、自分の手札・墓地から儀式魔法カード１枚を除外して発動できる。\nこのカードの効果は、その儀式魔法カード発動時の儀式召喚する効果と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"業炎のバリア －ファイヤー・フォース－",h:"ごうえんのばりあ　－ふぁいやー・ふぉーす－",e:"Blazing Mirror Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの攻撃表示モンスターを全て破壊し、自分はこの効果で破壊したモンスターの元々の攻撃力を合計した数値の半分のダメージを受ける。\nその後、自分が受けたダメージと同じ数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"昇天の剛角笛",h:"しょうてんのぐれいとほーん",e:"Grand Horn of Heaven",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手メインフェイズに相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。\nその後、相手はデッキから１枚ドローし、相手メインフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"救護部隊",h:"きゅうごぶたい",e:"First-Aid Squad",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが墓地に存在し、通常モンスターが戦闘で破壊された時に発動できる。\nこのカードは通常モンスター（戦士族・地・星３・攻１２００／守４００）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"武神－ヒルコ",h:"ぶじん－ひるこ",e:"Bujin Hiruko",t:"monster",a:"光",l:4,ps:3,pe:"①：自分のPゾーンのこのカードを除外し、自分フィールドの「武神」Xモンスター１体を対象として発動できる。\nその自分のモンスターとカード名が異なる「武神」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",tr:"獣戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1000,de:2000,tx:"遥か太古の昔に主神の座をかけて「武神－ヒルメ」と戦い、死闘の末に封印されてしまった悪神。\n自らの封印を解くために「ヒルメ」を操り、禍々しき「アマテラス」を生み出して世界に闇を齎したが、その野望は「ヤマト」たち若き武神の活躍によって潰えた。",li:"",lk:[],ta:[],al:[]},
  {n:"フレシアの蟲惑魔",h:"ふれしあのこわくま",e:"Traptrix Rafflesia",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:300,de:2500,tx:"レベル４モンスター×２\n①：X素材を持ったこのカードは罠カードの効果を受けない。\n②：このカードがモンスターゾーンに存在する限り、「フレシアの蟲惑魔」以外の自分フィールドの「蟲惑魔」モンスターは戦闘・効果では破壊されず、相手の効果の対象にならない。\n③：１ターンに１度、このカードのX素材を１つ取り除き、発動条件を満たしている「ホール」通常罠カードまたは「落とし穴」通常罠カード１枚をデッキから墓地へ送って発動できる。\nこの効果は、その罠カード発動時の効果と同じになる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"森羅の姫芽宮",h:"しんらのひめみや",e:"Sylvan Princessprite",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:100,tx:"レベル１モンスター×２\n「森羅の姫芽宮」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが魔法・罠カードだった場合、そのカードを手札に加える。\n違った場合、そのカードを墓地へ送る。\n②：手札及びこのカード以外の自分フィールドの表側表示モンスターの中から、植物族モンスター１体を墓地へ送り、自分の墓地の「森羅」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－ヴァジュラ",h:"あーてぃふぁくと－う゛ぁじゅら",e:"Artifact Vajra",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1900,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、このカードが特殊召喚に成功した場合に発動する。\n自分の魔法＆罠ゾーンのカードを全て破壊する。\n④：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"音響戦士マイクス",h:"さうんどうぉりあーまいくす",e:"Symphonic Warrior Miccs",t:"monster",a:"風",l:5,ps:1,pe:"①：もう片方の自分のPゾーンに「音響戦士」カードが存在しない場合、このカードのPスケールは４になる。\n②：自分エンドフェイズに、除外されている自分の「音響戦士」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2300,de:1100,tx:"①：このカードは自分フィールドの音響カウンターを３つ取り除き、手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにモンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMラ・パンダ",h:"えんためいとら・ぱんだ",e:"Performapal Trumpanda",t:"monster",a:"地",l:3,ps:3,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのPスケールを１つ上げる（最大１２まで）。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:800,tx:"「EMラ・パンダ」のモンスター効果は１ターンに１度しか使用できない。\n①：自分のPモンスターが攻撃対象に選択された時に発動できる。\nその攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラコニアの翼竜騎兵",h:"どらこにあのよくりゅうきへい",e:"Sky Dragoons of Draconia",t:"monster",a:"風",l:5,ps:7,pe:"①：１ターンに１度、自分の通常モンスターが相手に戦闘ダメージを与えた時、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"鳥獣族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2200,de:200,tx:"龍人族の国、ドラコニア帝国が有する竜騎士団の空兵部隊。\n中立国である空中都市国家シュルブへ侵攻するために結成されたとの噂があり、周辺国は警戒を強めている。",li:"",lk:[],ta:[],al:[]},
  {n:"絶滅の定め",h:"ぜつめつのさだめ",e:"Extinction on Schedule",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分・相手のメインフェイズに２０００LPを払って発動できる。\nこのカードの発動後３回目のバトルフェイズ終了時に、お互いのプレイヤーはそれぞれのフィールドのカードを全て墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ドロッセル",h:"こずも－どろっせる",e:"Kozmo Farmgirl",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「Kozmo－ドロッセル」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル４以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手に戦闘ダメージを与えた時、５００LPを払って発動できる。\nデッキから「Kozmo」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－グリンドル",h:"こずも－ぐりんどる",e:"Kozmo Goodwitch",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"「Kozmo－グリンドル」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル５以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、５００LPを払い、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－スリップライダー",h:"こずも－すりっぷらいだー",e:"Kozmo Sliprider",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:800,tx:"①：このカードが召喚・特殊召喚に成功した場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル４以下の「Kozmo」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－フォアランナー",h:"こずも－ふぉあらんなー",e:"Kozmo Forerunner",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:1400,tx:"①：このカードは相手の効果の対象にならない。\n②：自分スタンバイフェイズに発動する。\n自分は１０００LP回復する。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル６以下の「Kozmo」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－エメラルドポリス",h:"こずも－えめらるどぽりす",e:"Kozmotown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、除外されている自分の「Kozmo」モンスター１体を対象として発動できる。\nそのモンスターを手札に戻し、自分はそのモンスターの元々のレベル×１００LPを失う。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札の「Kozmo」モンスターを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけデッキからドローする。\n③：フィールドゾーンのこのカードが効果で破壊された場合に発動できる。\nデッキから「Kozmo」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"怒炎壊獣ドゴラン",h:"どえんかいじゅうどごらん",e:"Dogoran, the Mad Flame Kaiju",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3000,de:1200,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに攻撃表示で特殊召喚できる。\n②：相手フィールドに「壊獣」モンスターが存在する場合、このカードは手札から攻撃表示で特殊召喚できる。\n③：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n④：１ターンに１度、自分・相手フィールドの壊獣カウンターを３つ取り除いて発動できる。\n相手フィールドのモンスターを全て破壊する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"粘糸壊獣クモグス",h:"ねんしかいじゅうくもぐす",e:"Kumongous, the Sticky String Kaiju",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:2500,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに攻撃表示で特殊召喚できる。\n②：相手フィールドに「壊獣」モンスターが存在する場合、このカードは手札から攻撃表示で特殊召喚できる。\n③：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n④：相手がモンスターを召喚・特殊召喚した時、自分・相手フィールドの壊獣カウンターを２つ取り除いて発動できる。\n次のターンの終了時まで、そのモンスターは攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"KYOUTOUウォーターフロント",h:"きょうとううぉーたーふろんと",e:"Kyoutou Waterfront",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドのカードが墓地へ送られる度に、１枚につき１つこのカードに壊獣カウンターを置く（最大５つまで）。\n②：１ターンに１度、このカードの壊獣カウンターが３つ以上の場合に発動できる。\n自分はデッキから「壊獣」モンスター１体を手札に加える。\n③：このカードが効果で破壊される場合、代わりにこのカードの壊獣カウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖戦士カオス・ソルジャー",h:"せいせんしかおす・そるじゃー",e:"Black Luster Soldier - Sacred Soldier",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"「聖戦士カオス・ソルジャー」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、除外されている自分の光属性または闇属性のモンスター１体と相手フィールドのカード１枚を対象として発動できる。\nその自分のカードを墓地に戻し、その相手のカードを除外する。\n②：このカードが戦闘で相手モンスターを破壊した時、自分の墓地のレベル７以下の戦士族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"覚醒の暗黒騎士ガイア",h:"かくせいのあんこくきしがいあ",e:"Arisen Gaia the Fierce Knight",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"「覚醒の暗黒騎士ガイア」の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードはリリースなしで召喚できる。\n②：このカードがリリースされた場合に発動できる。\n自分の手札・墓地から「カオス・ソルジャー」モンスター１体を選んで特殊召喚する。\n③：「カオス・ソルジャー」モンスターの儀式召喚を行う場合、必要なレベル分のモンスターの内の１体として、墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"陰陽神 クズノハ",h:"おんみょうじん　くずのは",e:"Kuzunoha, the Onmyojin",t:"monster",a:"闇",l:8,ps:1,pe:"①：１ターンに１度、自分のモンスターゾーンの魔法使い族Pモンスター３体を除外し、自分のモンスターゾーンのPモンスター１体を対象として発動できる。\nこのターンそのモンスターが直接攻撃で相手のLPを０にした場合、自分はマッチに勝利する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2300,tx:"このカードを通常召喚する場合、魔法使い族モンスター３体をリリースして召喚しなければならない。\nこのカードはP召喚でしか特殊召喚できない。\n自分フィールドにこのカード以外のモンスターが存在せず、このカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"極刀の武者 左京",h:"きょくとうのむしゃ　さきょう",e:"Sakyo, Swordmaster of the Far East",t:"monster",a:"光",l:7,ps:10,pe:"①：１ターンに１度、自分のモンスターゾーンの戦士族Pモンスター３体を除外し、自分のモンスターゾーンのPモンスター１体を対象として発動できる。\nこのターンそのモンスターが直接攻撃で相手のLPを０にした場合、自分はマッチに勝利する。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2900,de:2600,tx:"夢現の闘宴は終に決す。\n\n強者は勝鬨の声を上げよ。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"SRベイゴマックス",h:"すぴーどろいどべいごまっくす",e:"Speedroid Terrortop",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「SRベイゴマックス」以外の「スピードロイド」モンスター１体を手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"SR三つ目のダイス",h:"すぴーどろいどみつめのだいす",e:"Speedroid Tri-Eyed Dice",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:1500,tx:"①：相手ターンに墓地のこのカードを除外して発動できる。\nこのターン、相手モンスターの攻撃を１度だけ無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"SRダブルヨーヨー",h:"すぴーどろいどだぶるよーよー",e:"Speedroid Double Yoyo",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル３以下の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SRシェイブー・メラン",h:"すぴーどろいどしぇいぶー・めらん",e:"Speedroid Razorang",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードは召喚したターンには攻撃できない。\n②：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを守備表示にし、対象のモンスターの攻撃力はターン終了時まで８００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"SRメンコート",h:"すぴーどろいどめんこーと",e:"Speedroid Menko",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から攻撃表示で特殊召喚し、相手フィールドの表側表示モンスターを全て守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"SRタケトンボーグ",h:"すぴーどろいどたけとんぼーぐ",e:"Speedroid Taketomborg",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"自分は「SRタケトンボーグ」を１ターンに１度しか特殊召喚できない。\n①：自分フィールドに風属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードをリリースして発動できる。\nデッキから「スピードロイド」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"SRオハジキッド",h:"すぴーどろいどおはじきっど",e:"Speedroid Ohajikid",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"①：このカードが召喚に成功した時、自分または相手の墓地のチューナー１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚し、そのモンスターとこのカードのみを素材として風属性のSモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SR赤目のダイス",h:"すぴーどろいどあかめのだいす",e:"Speedroid Red-Eyed Dice",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが召喚・特殊召喚に成功した時、「SR赤目のダイス」以外の自分フィールドの「スピードロイド」モンスター１体を対象とし、１～６までの任意のレベルを宣言して発動できる。\nそのモンスターのレベルはターン終了時まで、宣言したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"HSRチャンバライダー",h:"はいすぴーどろいどちゃんばらいだー",e:"Hi-Speedroid Chanbara",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「HSRチャンバライダー」を１ターンに１度しか特殊召喚できない。\n①：このカードは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが戦闘を行うダメージステップ開始時に発動する。\nこのカードの攻撃力は２００アップする。\n③：このカードが墓地へ送られた場合、除外されている自分の「スピードロイド」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スピードリバース",h:"すぴーどりばーす",e:"Speed Recovery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒドゥン・ショット",h:"ひどぅん・しょっと",e:"Shock Surprise",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「スピードロイド」モンスターを２体まで除外し、除外した数だけフィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・クラッカー",h:"しんくろ・くらっかー",e:"Synchro Cracker",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのSモンスター１体を対象として発動できる。\nそのモンスターを持ち主のエクストラデッキに戻し、そのSモンスターの元々の攻撃力以下の攻撃力を持つ相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイスロール・バトル",h:"だいすろーる・ばとる",e:"Dice Roll Battle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターと手札の「スピードロイド」チューナー１体を除外し、その２体の元々のレベルの合計と同じレベルを持つSモンスター１体をエクストラデッキから特殊召喚する。\n②：相手バトルステップに墓地のこのカードを除外し、自分及び相手フィールドの表側攻撃表示のSモンスターを１体ずつ対象として発動できる。\nその相手の表側攻撃表示モンスターはその自分のモンスターへ攻撃し、ダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・スプリンター",h:"れっど・すぷりんたー",e:"Red Sprinter",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"「レッド・スプリンター」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、自分フィールドに他のモンスターが存在しない場合に発動できる。\n自分の手札・墓地からレベル３以下の悪魔族チューナー１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・リゾネーター",h:"れっど・りぞねーたー",e:"Red Resonator",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下のモンスター１体を特殊召喚する。\n②：このカードが特殊召喚に成功した時、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分だけ自分はLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクローン・リゾネーター",h:"しんくろーん・りぞねーたー",e:"Synkron Resonator",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドにSモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがフィールドから墓地へ送られた場合、「シンクローン・リゾネーター」以外の自分の墓地の「リゾネーター」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"チェーン・リゾネーター",h:"ちぇーん・りぞねーたー",e:"Chain Resonator",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"①：フィールドにSモンスターが存在し、このカードが召喚に成功した時に発動できる。\nデッキから「チェーン・リゾネーター」以外の「リゾネーター」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラー・リゾネーター",h:"みらー・りぞねーたー",e:"Mirror Resonator",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「ミラー・リゾネーター」の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、エクストラデッキから特殊召喚されたモンスターが相手フィールドにのみ存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードはこのターンにS素材とする場合、対象のモンスターの元々のレベルと同じレベルとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ワイバーン",h:"れっど・わいばーん",e:"Red Wyvern",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：S召喚したこのカードがフィールドに表側表示で存在する限り１度だけ、このカードより攻撃力が高いモンスターがフィールドに存在する場合に発動できる。\nフィールドの攻撃力が一番高いモンスター１体を破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スカーレッド・コクーン",h:"すかーれっど・こくーん",e:"Red Cocoon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのドラゴン族Sモンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが相手モンスターと戦闘を行う場合、そのダメージステップ終了時まで相手フィールドの全ての表側表示モンスターの効果は無効化される。\n③：このカードが墓地へ送られたターンのエンドフェイズに、自分の墓地の「レッド・デーモンズ・ドラゴン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレーム・ドライバー",h:"さいふれーむ・どらいばー",e:"PSY-Frame Driver",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["通常"],ma:"",tp:"",at:2500,de:0,tx:"電撃を操るサイキック戦士。\n自律型増幅器「PSYフレーム」を駆り、セキュリティ・フォースに強襲を仕掛ける。\nその姿は、正に電光石火の如し。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレームギア・α",h:"さいふれーむぎあ・あるふぁ",e:"PSY-Framegear Alpha",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:500,de:0,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：自分フィールドにモンスターが存在せず、相手がモンスターを召喚・特殊召喚した時に発動できる。\n手札のこのカードと自分の手札・デッキ・墓地の「PSYフレーム・ドライバー」１体を選んで特殊召喚し、デッキから「PSYフレームギア・α」以外の「PSYフレーム」カード１枚を手札に加える。\nこの効果で特殊召喚したモンスターは全てエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレームギア・β",h:"さいふれーむぎあ・べーた",e:"PSY-Framegear Beta",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:700,de:0,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：自分フィールドにモンスターが存在せず、相手モンスターの攻撃宣言時に発動できる。\n手札のこのカードと自分の手札・デッキ・墓地の「PSYフレーム・ドライバー」１体を選んで特殊召喚し、その攻撃モンスターを破壊する。\nその後、バトルフェイズを終了する。\nこの効果で特殊召喚したモンスターは全てエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレームギア・γ",h:"さいふれーむぎあ・がんま",e:"PSY-Framegear Gamma",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1000,de:0,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：自分フィールドにモンスターが存在せず、相手モンスターの効果が発動した時に発動できる。\n手札のこのカードと自分の手札・デッキ・墓地の「PSYフレーム・ドライバー」１体を選んで特殊召喚し、その発動を無効にし破壊する。\nこの効果で特殊召喚したモンスターは全てエンドフェイズに除外される。",li:1,lk:[],ta:[],al:[]},
  {n:"PSYフレームギア・δ",h:"さいふれーむぎあ・でるた",e:"PSY-Framegear Delta",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1200,de:0,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：自分フィールドにモンスターが存在せず、相手の魔法カードが発動した時に発動できる。\n手札のこのカードと自分の手札・デッキ・墓地の「PSYフレーム・ドライバー」１体を選んで特殊召喚し、その発動を無効にし破壊する。\nこの効果で特殊召喚したモンスターは全てエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレームギア・ε",h:"さいふれーむぎあ・いぷしろん",e:"PSY-Framegear Epsilon",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1500,de:0,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：自分フィールドにモンスターが存在せず、相手の罠カードが発動した時に発動できる。\n手札のこのカードと自分の手札・デッキ・墓地の「PSYフレーム・ドライバー」１体を選んで特殊召喚し、その発動を無効にし破壊する。\nこの効果で特殊召喚したモンスターは全てエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレームロード・Ζ",h:"さいふれーむろーど・ぜーた",e:"PSY-Framelord Zeta",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、相手フィールドの特殊召喚された表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターとフィールドのこのカードを次の自分スタンバイフェイズまで除外する。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する場合、このカード以外の自分の墓地の「PSYフレーム」カード１枚を対象として発動できる。\nこのカードをエクストラデッキに戻し、対象のカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレームロード・Ω",h:"さいふれーむろーど・おめが",e:"PSY-Framelord Omega",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2200,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分・相手のメインフェイズに発動できる。\n相手の手札をランダムに１枚選び、そのカードと表側表示のこのカードを次の自分スタンバイフェイズまで表側表示で除外する。\n②：相手スタンバイフェイズに、除外されている自分または相手のカード１枚を対象として発動できる。\nそのカードを墓地に戻す。\n③：このカードが墓地に存在する場合、このカード以外の自分または相手の墓地のカード１枚を対象として発動できる。\nそのカードと墓地のこのカードをデッキに戻す。",li:1,lk:[],ta:[],al:[]},
  {n:"PSYフレーム・サーキット",h:"さいふれーむ・さーきっと",e:"PSY-Frame Circuit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「PSYフレーム」モンスターが特殊召喚された場合に発動できる。\n自分フィールドの「PSYフレーム」モンスターのみをS素材としてS召喚する。\n②：自分の「PSYフレーム」モンスターが相手モンスターと戦闘を行うダメージステップ開始時に、手札の「PSYフレーム」モンスター１体を捨てて発動できる。\nその戦闘を行う自分のモンスターの攻撃力はターン終了時まで、この効果を発動するために捨てたモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレーム・オーバーロード",h:"さいふれーむ・おーばーろーど",e:"PSY-Frame Overload",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分の手札及び自分フィールドの表側表示モンスターの中から、「PSYフレーム」モンスター１体を除外し、フィールドのカード１枚を対象としてこの効果を発動できる。\nそのカードを裏側表示で除外する。\n②：墓地のこのカードを除外して発動できる。\nデッキから「PSYフレーム・オーバーロード」以外の「PSYフレーム」カード１枚を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴヨウ・チェイサー",h:"ごよう・ちぇいさー",e:"Goyo Chaser",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードの攻撃力は、このカード以外のフィールドの戦士族・地属性のSモンスターの数×３００アップする。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターの攻撃力を半分にして自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"琰魔竜 レッド・デーモン・アビス",h:"えんまりゅう　れっど・でーもん・あびす",e:"Hot Red Dragon Archfiend Abyss",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:2500,tx:"チューナー＋チューナー以外のドラゴン族・闇属性Sモンスター１体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手に戦闘ダメージを与えた時、自分の墓地のチューナー１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"琰魔竜 レッド・デーモン・ベリアル",h:"えんまりゅう　れっど・でーもん・べりある",e:"Hot Red Dragon Archfiend Bane",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:3000,tx:"チューナー＋チューナー以外のドラゴン族・闇属性Sモンスター１体\n「琰魔竜 レッド・デーモン・ベリアル」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースし、自分の墓地の「レッド・デーモン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分のデッキ及び墓地からレベルが同じチューナーをそれぞれ１体ずつ選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"琰魔竜王 レッド・デーモン・カラミティ",h:"えんまりゅうおう　れっど・でーもん・からみてぃ",e:"Hot Red Dragon Archfiend King Calamity",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:3500,tx:"チューナー２体＋チューナー以外のドラゴン族・闇属性Sモンスター１体\n①：このカードがS召喚に成功した時に発動できる。\nこのターン相手はフィールドで発動する効果を発動できない。\nこの発動に対して、相手はカードの効果を発動できない。\n②：このカードが戦闘でモンスターを破壊した場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n③：このカードが相手によって破壊された場合、自分の墓地のレベル８以下のドラゴン族・闇属性Sモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴヨウ・プレデター",h:"ごよう・ぷれでたー",e:"Goyo Predator",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\n「ゴヨウ・プレデター」の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターがプレイヤーに与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・オレノイデス",h:"ばーじぇすとま・おれのいです",e:"Paleozoic Olenoides",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・ハルキゲニア",h:"ばーじぇすとま・はるきげにあ",e:"Paleozoic Hallucigenia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで半分になる。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・カナディア",h:"ばーじぇすとま・かなでぃあ",e:"Paleozoic Canadia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・ピカイア",h:"ばーじぇすとま・ぴかいあ",e:"Paleozoic Pikaia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から「バージェストマ」カード１枚を捨てる。\nその後、自分はデッキから２枚ドローする。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・アノマロカリス",h:"ばーじぇすとま・あのまろかりす",e:"Paleozoic Anomalocaris",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:0,tx:"レベル２モンスター×３体以上\n①：このカードは他のモンスターの効果を受けない。\n②：１ターンに１度、罠カードが自分の魔法＆罠ゾーンから墓地へ送られた場合に発動できる。\n自分のデッキの一番上のカードをめくる。\nそのカードが罠カードだった場合、手札に加える。\n違った場合、墓地へ送る。\n③：このカードが罠カードをX素材としている場合、１ターンに１度、このカードのX素材を１つ取り除き、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・アサルト・ウォリアー",h:"すたーだすと・あさると・うぉりあー",e:"Stardust Assault Warrior",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\n「スターダスト・アサルト・ウォリアー」の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時、自分フィールドに他のモンスターが存在しない場合に自分の墓地の「ジャンク」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.93 希望皇ホープ・カイザー",h:"なんばーず９３ きぼうおうほーぷ・かいざー",e:"Number 93: Utopia Kaiser",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"X素材を持っている同じランクの「No.」Xモンスター×２体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのX素材の種類の数まで、EXデッキからランク９以下で攻撃力３０００以下の「No.」モンスターを効果を無効にして特殊召喚する（同じランクは１体まで）。\nその後、このカードのX素材を１つ取り除く。\nこのターン、相手が受ける戦闘ダメージは半分になり、自分はモンスターを特殊召喚できない。\n②：自分フィールドに他の「No.」Xモンスターが存在する限り、このカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サクリボー",h:"さくりぼー",e:"Relinkuriboh",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：このカードがリリースされた場合に発動する。\n自分はデッキから１枚ドローする。\n②：自分のモンスターが戦闘で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"天帝アイテール",h:"てんていあいてーる",e:"Ehther the Heavenly Monarch",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\n手札・デッキから「帝王」魔法・罠カード２種類を墓地へ送り、デッキから攻撃力２４００以上で守備力１０００のモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。\n②：このカードが手札にある場合、相手メインフェイズに自分の墓地の「帝王」魔法・罠カード１枚を除外して発動できる。\nこのカードをアドバンス召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"冥帝エレボス",h:"めいていえれぼす",e:"Erebus the Underworld Monarch",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\n手札・デッキから「帝王」魔法・罠カード２種類を墓地へ送り、相手の手札・フィールド・墓地の中からカード１枚を選んでデッキに戻す。\n②：このカードが墓地にある場合、１ターンに１度、自分・相手のメインフェイズに手札から「帝王」魔法・罠カード１枚を捨て、自分の墓地の攻撃力２４００以上で守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"冥帝従騎エイドス",h:"めいていじゅうきえいどす",e:"Eidos the Underworld Squire",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「冥帝従騎エイドス」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにアドバンス召喚できる。\n②：墓地のこのカードを除外し、「冥帝従騎エイドス」以外の自分の墓地の攻撃力８００／守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"天帝従騎イデア",h:"てんていじゅうきいであ",e:"Edea the Heavenly Squire",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「天帝従騎イデア」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「天帝従騎イデア」以外の攻撃力８００／守備力１０００のモンスター１体を守備表示で特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードが墓地へ送られた場合、除外されている自分の「帝王」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"汎神の帝王",h:"はんしんのていおう",e:"Pantheism of the Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「汎神の帝王」の②の効果は１ターンに１度しか使用できない。\n①：手札の「帝王」魔法・罠カード１枚を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。\n②：墓地のこのカードを除外して発動できる。\nデッキから「帝王」魔法・罠カード３枚を相手に見せ、相手はその中から１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"真源の帝王",h:"しんげんのていおう",e:"The Prime Monarch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「真源の帝王」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分の墓地の「帝王」魔法・罠カード２枚を対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\n②：このカードが墓地に存在する場合、このカード以外の自分の墓地の「帝王」魔法・罠カード１枚を除外して発動できる。\nこのカードは通常モンスター（天使族・光・星５・攻１０００／守２４００）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガヘッド",h:"がががへっど",e:"Gagaga Head",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2100,de:2000,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードはリリースなしでレベル４モンスターとして召喚できる。\n②：このカードが召喚に成功した時、「ガガガヘッド」以外の自分の墓地の「ガガガ」モンスターを２体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nこのターン自分は「ガガガ」モンスターのみを素材としたX召喚以外の特殊召喚ができない。\n③：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティアの武者騎兵",h:"れぷてぃあのむしゃきへい",e:"Samurai Cavalry of Reptier",t:"monster",a:"地",l:4,ps:3,pe:"",tr:"恐竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードがPモンスター以外の相手の表側表示モンスターに攻撃したダメージステップ開始時に発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"苦渋の黙札",h:"くじゅうのもくさつ",e:"Painful Escape",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターと元々のカード名が異なり、元々の種族・属性・レベルが同じモンスター１体を自分のデッキ・墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－フォルミート",h:"こずも－ふぉるみーと",e:"Kozmo Strawman",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"「Kozmo－フォルミート」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル３以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、５００LPを払い、除外されている自分の「Kozmo」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ダーク・ローズ",h:"こずも－だーく・ろーず",e:"Kozmoll Wickedwitch",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1900,de:300,tx:"「Kozmo－ダーク・ローズ」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル５以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、１０００LPを払って発動できる。\nこのターン、このカードは戦闘・効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ドッグファイター",h:"こずも－どっぐふぁいたー",e:"Kozmo DOG Fighter",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2400,tx:"①：自分・相手のスタンバイフェイズに発動できる。\n自分フィールドに「ドッグファイタートークン」（機械族・闇・星６・攻２０００／守２４００）１体を特殊召喚する。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル５以下の「Kozmo」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ダークシミター",h:"こずも－だーくしみたー",e:"Kozmo Dark Destroyer",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:1800,tx:"①：このカードが召喚・特殊召喚に成功した場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：このカードは相手の効果の対象にならない。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル７以下の「Kozmo」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－レイブレード",h:"こずも－れいぶれーど",e:"Kozmo Lightsword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"サイキック族の「Kozmo」モンスターにのみ装備可能。\n「Kozmo－レイブレード」の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターは、攻撃力・守備力が５００アップし、１度のバトルフェイズ中に２回までモンスターに攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：このカードがフィールドから墓地へ送られた場合、８００LPを払って発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"多次元壊獣ラディアン",h:"たじげんかいじゅうらでぃあん",e:"Radian, the Multidimensional Kaiju",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:2500,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに攻撃表示で特殊召喚できる。\n②：相手フィールドに「壊獣」モンスターが存在する場合、このカードは手札から攻撃表示で特殊召喚できる。\n③：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n④：１ターンに１度、自分・相手フィールドの壊獣カウンターを２つ取り除いて発動できる。\n自分フィールドに「ラディアントークン」（悪魔族・闇・星７・攻２８００／守０）１体を特殊召喚する。\nこのトークンはS素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"海亀壊獣ガメシエル",h:"かいきかいじゅうがめしえる",e:"Gameciel, the Sea Turtle Kaiju",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2200,de:3000,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに攻撃表示で特殊召喚できる。\n②：相手フィールドに「壊獣」モンスターが存在する場合、このカードは手札から攻撃表示で特殊召喚できる。\n③：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n④：相手が「海亀壊獣ガメシエル」以外の魔法・罠・モンスターの効果を発動した時、自分・相手フィールドの壊獣カウンターを２つ取り除いて発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"壊獣捕獲大作戦",h:"かいじゅうほかくだいさくせん",e:"Kaiju Capture Mission",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「壊獣捕獲大作戦」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、フィールドの「壊獣」モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\nその後、このカードに壊獣カウンターを１つ置く（最大３つまで）。\n②：このカードが相手の効果で破壊され墓地へ送られた場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"永遠の淑女 ベアトリーチェ",h:"えいえんのしゅくじょ　べあとりーちぇ",e:"Beatrice, Lady of the Eternal",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2800,tx:"レベル６モンスター×２\nこのカードは手札の「彼岸」モンスター１体を墓地へ送り、自分フィールドの「ダンテ」モンスターの上に重ねてX召喚する事もできる。\nこの方法で特殊召喚したターン、このカードの①の効果は発動できない。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキからカード１枚を選んで墓地へ送る。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\nEXデッキから「彼岸」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"調律の魔術師",h:"ちょうりつのまじゅつし",e:"Tuning Magician",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「調律の魔術師」の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分のPゾーンに「魔術師」カードが２枚存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが召喚・特殊召喚に成功した場合に発動する。\n相手は４００LP回復し、その後自分は４００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"刻剣の魔術師",h:"こっけんのまじゅつし",e:"Timebreaker Magician",t:"monster",a:"闇",l:3,ps:2,pe:"①：このカードがPゾーンに存在する限り、１ターンに１度、自分フィールドのPモンスターは相手の効果では破壊されない。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1400,de:0,tx:"①：手札のこのカードのみがP召喚に成功した時に発動できる。\nこのカードの攻撃力は元々の攻撃力の倍になる。\n②：１ターンに１度、フィールドのモンスター１体を対象として発動できる。\nそのモンスターとフィールドのこのカードを次の自分スタンバイフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMモンキーボード",h:"えんためいともんきーぼーど",e:"Performapal Monkeyboard",t:"monster",a:"地",l:6,ps:1,pe:"「EMモンキーボード」の②のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンに「EM」カードが存在しない場合、このカードのPスケールは４になる。\n②：このカードを発動したターンの自分メインフェイズに発動できる。\nデッキからレベル４以下の「EM」モンスター１体を手札に加える。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:2400,tx:"①：このカードを手札から捨てて発動できる。\n手札の「EM」モンスターまたは「オッドアイズ」モンスター１体を相手に見せる。\nこのターン、そのモンスター及び自分の手札の同名モンスターのレベルを１つ下げる。",li:0,lk:[],ta:[],al:[]},
  {n:"EMギタートル",h:"えんためいとぎたーとる",e:"Performapal Guitartle",t:"monster",a:"水",l:1,ps:6,pe:"「EMギタートル」のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンに「EM」カードが発動した場合に発動できる。\n自分はデッキから１枚ドローする。",tr:"水族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:400,tx:"①：１ターンに１度、自分のPゾーンのカード１枚を対象として発動できる。\nそのカードのPスケールをターン終了時まで２つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMビッグバイトタートル",h:"えんためいとびっぐばいとたーとる",e:"Performapal Bit Bite Turtle",t:"monster",a:"水",l:3,ps:3,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札の「EM」モンスターまたは「オッドアイズ」モンスター１体を相手に見せる。\nこのターン、そのモンスター及び自分の手札の同名モンスターのレベルを１つ下げる。",tr:"爬虫類族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:1200,tx:"①：このカードが戦闘で破壊された時に発動できる。\nこのカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMレインゴート",h:"えんためいとれいんごーと",e:"Performapal Rain Goat",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分にダメージを与える魔法・罠・モンスターの効果が発動した時、このカードを手札から捨てて発動できる。\nその効果で自分が受けるダメージを０にする。\n②：自分・相手のメインフェイズにこのカードを手札から捨て、自分フィールドの「EM」カードまたは「オッドアイズ」カード１枚を対象として発動できる。\nこのターン、そのカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMトランプ・ガール",h:"えんためいととらんぷ・がーる",e:"Performapal Trump Girl",t:"monster",a:"闇",l:2,ps:4,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:200,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n②：このカードがPゾーンで破壊された場合、自分の墓地のドラゴン族の融合モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ジシャ－Q",h:"ちょうじゅうむしゃじしゃ－くー",e:"Superheavy Samurai Magnet",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:900,de:1900,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「超重武者」モンスター１体を特殊召喚する。\nその後、このカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ココロガマ－A",h:"ちょうじゅうむしゃこころがま－えー",e:"Superheavy Samurai Prepped Defense",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"自分の墓地に魔法・罠カードが存在する場合、このカードは召喚・反転召喚できない。\n①：自分の墓地に魔法・罠カードが存在せず、自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードは、このターン戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"超重輝将ヒス－E",h:"ちょうじゅうきしょうひす－いー",e:"Superheavy Samurai General Jade",t:"monster",a:"地",l:8,ps:1,pe:"①：自分は「超重武者」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、自分フィールドの「超重武者」モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ上げる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:2800,tx:"このカードはルール上「超重武者」カードとしても扱う。\nこのカードは「超重武者」モンスター１体をリリースしてアドバンス召喚できる。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"超重輝将サン－５",h:"ちょうじゅうきしょうさん－ご",e:"Superheavy Samurai General Coral",t:"monster",a:"地",l:1,ps:8,pe:"①：自分の墓地に魔法・罠カードが存在する場合、このカードのPスケールは４になる。\n②：１ターンに１度、自分の「超重武者」モンスターが戦闘で相手モンスターを破壊した時に発動できる。\nそのモンスターはもう１度だけ続けて攻撃できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:500,tx:"このカードはルール上「超重武者」カードとしても扱う。\n「超重輝将サン－５」のモンスター効果は１ターンに１度しか使用できない。\n①：自分の墓地に魔法・罠カードが存在しない場合、自分フィールドの「超重武者」モンスターを２体までリリースして発動できる。\nリリースした数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の歌姫ソロ",h:"げんそうのうたひめそろ",e:"Solo the Melodious Songstress",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「幻奏の歌姫ソロ」以外の「幻奏」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の音女スコア",h:"げんそうのおとめすこあ",e:"Score the Melodious Diva",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"①：自分の「幻奏」モンスターが相手モンスターと戦闘を行うダメージ計算時、このカードを手札から墓地へ送って発動できる。\nその相手モンスターの攻撃力・守備力はターン終了時まで０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－砂塵のハルマッタン",h:"ぶらっくふぇざー－さじんのはるまったん",e:"Blackwing - Harmattan the Dust",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"「BF－砂塵のハルマッタン」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「BF－砂塵のハルマッタン」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した時、このカード以外の自分フィールドの「BF」モンスター１体を対象として発動できる。\nこのカードのレベルをそのモンスターのレベル分だけ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"黄昏の忍者－シンゲツ",h:"たそがれのにんじゃ－しんげつ",e:"Twilight Ninja Shingetsu",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:100,tx:"「黄昏の忍者－シンゲツ」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手は他の「忍者」モンスターを攻撃対象にできず、効果の対象にもできない。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「黄昏の忍者－シンゲツ」以外の「忍者」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"黄昏の中忍－ニチリン",h:"たそがれのちゅうにん－にちりん",e:"Twilight Ninja Nichirin, the Chunin",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"このカードはルール上「忍者」カードとしても扱う。\n①：１ターンに１度、手札から「忍者」モンスター１体を捨て、以下の効果から１つを選択して発動できる。\nこの効果は相手ターンでも発動できる。\n\n●このターン、自分フィールドの「忍者」モンスター及び「忍法」カードは戦闘・効果では破壊されない。\n\n●自分フィールドの「忍者」モンスター１体を選び、その攻撃力をターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"黄昏の忍者将軍－ゲツガ",h:"たそがれのにんじゃしょうぐん－げつが",e:"Twilight Ninja Getsuga, the Shogun",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカードは「忍者」モンスター１体をリリースしてアドバンス召喚できる。\n「黄昏の忍者将軍－ゲツガ」の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドに攻撃表示で存在する場合、「黄昏の忍者将軍－ゲツガ」以外の自分の墓地の「忍者」モンスター２体を対象として発動できる。\nこのカードを守備表示にし、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣の使い手－バスター・ブレイダー",h:"はかいけんのつかいて－ばすたー・ぶれいだー",e:"Buster Blader, the Destruction Swordmaster",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2300,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「バスター・ブレイダー」として扱う。\n②：相手フィールドのモンスターが戦闘・効果で破壊され墓地へ送られた場合、破壊されたそのモンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n③：１ターンに１度、このカードが装備しているモンスターカード１枚を墓地へ送って発動できる。\n墓地へ送ったそのモンスターカードと同じ種族の相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣士の伴竜",h:"はかいけんしのばんりゅう",e:"Buster Whelp of the Destruction Swordsman",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:300,tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「破壊剣士の伴竜」以外の「破壊剣」カード１枚を手札に加える。\n②：このカードをリリースして発動できる。\n自分の手札・墓地から「バスター・ブレイダー」１体を選んで特殊召喚する。\n③：このカードが墓地に存在し、自分フィールドに「バスター・ブレイダー」が存在する場合、手札から「破壊剣」カード１枚を捨てて発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣－ドラゴンバスターブレード",h:"はかいけん－どらごんばすたーぶれーど",e:"Dragon Buster Destruction Sword",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:300,tx:"「破壊剣－ドラゴンバスターブレード」の③の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「バスター・ブレイダー」１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードが装備されている場合、相手はエクストラデッキからモンスターを特殊召喚できない。\n③：このカードが装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣－ウィザードバスターブレード",h:"はかいけん－うぃざーどばすたーぶれーど",e:"Wizard Buster Destruction Sword",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"①：自分メインフェイズに自分フィールドの「バスター・ブレイダー」１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードが装備されている場合、相手は墓地のモンスターの効果を発動できない。\n③：装備されているこのカードを墓地へ送り、「破壊剣－ウィザードバスターブレード」以外の自分の墓地の「破壊剣」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣－アームズバスターブレード",h:"はかいけん－あーむずばすたーぶれーど",e:"Robot Buster Destruction Sword",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：自分メインフェイズに自分フィールドの「バスター・ブレイダー」１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードが装備されている場合、相手フィールドの既に表側表示で存在している魔法・罠カードは効果を発動できない。\n③：装備されているこのカードを墓地へ送って発動できる。\nこのカードを装備していたモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜剣士マスターP",h:"りゅうけんしますたーぺんでゅらむ",e:"Master Pendulum, the Dracoslayer",t:"monster",a:"光",l:4,ps:3,pe:"①：このカードがPゾーンに存在する限り１度だけ、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"ドラゴン族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1950,de:0,tx:"同志たちの力を得て成長した「竜剣士ラスターP」の姿。\n謎の呪いをかけられて竜魔族に似た竜の力を発現しているが、それ以前の記憶が全て失われており、真相は定かではない。\n“竜化の秘法”がその呪いと記憶を紐解く鍵だと信じて、今日も悪の魔王を討つべく旅を続けている。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・ステゴサウラー",h:"だいなみすと・すてごさうらー",e:"Dinomist Stegosaur",t:"monster",a:"水",l:4,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカード以外の自分のPモンスターが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその戦闘を行ったお互いのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・プレシオス",h:"だいなみすと・ぷれしおす",e:"Dinomist Plesios",t:"monster",a:"水",l:4,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力・守備力は、自分フィールドの「ダイナミスト」カードの数×１００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・プテラン",h:"だいなみすと・ぷてらん",e:"Dinomist Pteran",t:"monster",a:"水",l:4,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nデッキから「ダイナミスト」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・ブラキオン",h:"だいなみすと・ぶらきおん",e:"Dinomist Brachion",t:"monster",a:"水",l:5,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:800,tx:"①：自分のモンスターゾーンに「ダイナミスト・ブラキオン」が存在せず、フィールドの攻撃力が一番高いモンスターが相手フィールドに存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・ケラトプス",h:"だいなみすと・けらとぷす",e:"Dinomist Ceratops",t:"monster",a:"水",l:5,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2100,de:400,tx:"①：自分フィールドのモンスターが「ダイナミスト・ケラトプス」以外の「ダイナミスト」モンスターのみの場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・レックス",h:"だいなみすと・れっくす",e:"Dinomist Rex",t:"monster",a:"水",l:5,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2400,de:2200,tx:"①：このカードが攻撃を行ったダメージステップ終了時、このカード以外の自分フィールドの「ダイナミスト」モンスター１体をリリースし、以下の効果から１つを選択して発動できる。\n\n●このカードは相手モンスターに続けて攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n\n●相手の手札・フィールドのカード１枚を選んで持ち主のデッキに戻す（手札から選ぶ場合はランダムに選ぶ）。\nその後、このカードの攻撃力は１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・フロッグ",h:"まじぇすぺくたー・ふろっぐ",e:"Majespecter Toad - Ogama",t:"monster",a:"風",l:4,ps:5,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1300,de:500,tx:"「マジェスペクター・フロッグ」の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「マジェスペクター」魔法・罠カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはこのターン発動できない。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"妖刀－不知火",h:"ようとう－しらぬい",e:"Shiranui Spectralsword",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、チューナー以外の自分の墓地のアンデット族モンスター１体を対象として発動できる。\nそのモンスターとこのカードを墓地から除外し、その２体のレベルの合計と同じレベルを持つアンデット族Sモンスター１体をEXデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火の鍛師",h:"しらぬいのかなち",e:"Shiranui Smith",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"「不知火の鍛師」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードがS素材として墓地へ送られた場合に発動できる。\nデッキから「不知火の鍛師」以外の「不知火」カード１枚を手札に加える。\n②：このカードが除外された場合に発動できる。\nこのターン、自分のアンデット族モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火の宮司",h:"しらぬいのみやづかさ",e:"Shiranui Spiritmaster",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"「不知火の宮司」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地から「不知火の宮司」以外の「不知火」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。\n②：このカードが除外された場合、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火の武士",h:"しらぬいのもののふ",e:"Shiranui Samurai",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"「不知火の武士」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のアンデット族モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで６００アップし、このターンこのカードがモンスターと戦闘を行った場合、そのモンスターはダメージ計算後に除外される。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合、「不知火の武士」以外の自分の墓地の「不知火」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ドリアード",h:"だーく・どりあーど",e:"Dark Doriado",t:"monster",a:"闇",l:4,ps:5,pe:"①：自分フィールドの地・水・炎・風属性モンスターの攻撃力・守備力は、自分フィールドのモンスターの属性の種類×２００アップする。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1400,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから地・水・炎・風属性モンスターを１体ずつ選び、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"解放のアリアドネ",h:"かいほうのありあどね",e:"Guiding Ariadne",t:"monster",a:"光",l:4,ps:3,pe:"①：このカードがPゾーンに存在する限り、以下の効果を適用する。\n\n●自分はカウンター罠カードを発動するために払うLPが必要なくなる。\n\n●自分はカウンター罠カードを発動するために捨てる手札が必要なくなる。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:800,tx:"①：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキからカウンター罠カード３枚を相手に見せ、相手はその中から１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"イルミラージュ",h:"いるみらーじゅ",e:"Al-Lumi'raj",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、フィールドのモンスターの攻撃力・守備力は、そのモンスターのレベルまたはランク×３００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・バスター・ブレイダー",h:"とぅーん・ばすたー・ぶれいだー",e:"Toon Buster Blader",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["トゥーン","効果"],ma:"",tp:"",at:2600,de:2300,tx:"①：このカードの攻撃力は、相手のフィールド・墓地のドラゴン族モンスターの数×５００アップする。\n②：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n③：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００７",h:"ぶんぼーぐぜろぜろせぶん",e:"Deskbot 007",t:"monster",a:"地",l:7,ps:10,pe:"①：自分は「ブンボーグ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードの攻撃力は、自分の墓地の「ブンボーグ」カードの数×５００アップする。\n②：このカードがモンスターゾーンに存在する限り、相手は他の「ブンボーグ」モンスターを攻撃対象にできない。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００８",h:"ぶんぼーぐぜろぜろえいと",e:"Deskbot 008",t:"monster",a:"地",l:8,ps:1,pe:"①：自分は「ブンボーグ」モンスターしかP召喚できない。\nこの効果は無効化されない。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードの攻撃力は、自分の墓地の「ブンボーグ」カードの数×５００アップする。\n②：このカードがモンスターゾーンに存在する限り、相手は他の「ブンボーグ」カードを効果の対象にできない。\n③：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"刻印の調停者",h:"こくいんのちょうていしゃ",e:"Engraver of the Mark",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：相手がカード名を１つ宣言して発動する効果を発動した時、このカードを手札から墓地へ送って発動できる。\nカード名を１つ宣言する。\n相手が宣言したカード名はこの効果で宣言したカード名になる。\n②：１ターンに１度、フィールドの表側表示のカード１枚を対象として発動できる。\n次のターンのエンドフェイズにそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヨコシマウマ",h:"よこしまうま",e:"Zany Zebra",t:"monster",a:"闇",l:2,ps:7,pe:"①：このカードを発動したターンの自分メインフェイズに１度だけ、使用していないモンスターゾーンまたは魔法＆罠ゾーンを１ヵ所指定して発動できる。\n指定したゾーンはこのカードがPゾーンに存在する間は使用できない。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードが召喚・特殊召喚に成功した場合、使用していないモンスターゾーンまたは魔法＆罠ゾーンを１ヵ所指定して発動できる。\n指定したゾーンはこのモンスターが表側表示で存在する間は使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・グラビティ・ドラゴン",h:"おっどあいず・ぐらびてぃ・どらごん",e:"Odd-Eyes Gravity Dragon",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2500,tx:"「オッドアイズ・アドベント」により降臨。\n「オッドアイズ・グラビティ・ドラゴン」の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時に発動できる。\n相手フィールドの魔法・罠カードを全て持ち主の手札に戻す。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n②：このカードがモンスターゾーンに存在する限り、相手は５００LPを払わなければ、カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴヨウ・エンペラー",h:"ごよう・えんぺらー",e:"Goyo Emperor",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3300,de:2500,tx:"戦士族・地属性のSモンスター×２\n①：このカードまたは元々の持ち主が相手となる自分のモンスターが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\n破壊したそのモンスターを自分フィールドに特殊召喚する。\n②：相手がモンスターを特殊召喚した時、自分フィールドの戦士族・地属性のSモンスター１体をリリースして発動できる。\nそのモンスターのコントロールを得る。\n③：表側表示のこのカードがフィールドから離れた場合に発動する。\n自分フィールドの全てのモンスターのコントロールは、元々の持ち主に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"竜破壊の剣士－バスター・ブレイダー",h:"りゅうはかいのけんし－ばすたー・ぶれいだー",e:"Buster Blader, the Dragon Destroyer Swordsman",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2500,tx:"「バスター・ブレイダー」＋ドラゴン族モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードは直接攻撃できない。\n②：このカードの攻撃力・守備力は、相手のフィールド・墓地のドラゴン族モンスターの数×１０００アップする。\n③：このカードがモンスターゾーンに存在する限り、相手フィールドのドラゴン族モンスターは守備表示になり、相手はドラゴン族モンスターの効果を発動できない。\n④：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"剛竜剣士ダイナスターP",h:"ごうりゅうけんしだいなすたーぱわふる",e:"Dinoster Power, the Mighty Dracoslayer",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2950,tx:"「竜剣士」Pモンスター＋Pモンスター\n自分フィールドの上記カードをリリースした場合のみ、エクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードがモンスターゾーンに存在する限り、自分のモンスターゾーン・PゾーンのPモンスターカードは戦闘及び相手の効果では破壊されない。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「竜剣士」Pモンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは融合素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"覚醒の魔導剣士",h:"えんらいとめんと・ぱらでぃん",e:"Enlightenment Paladin",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「覚醒の魔導剣士」の①の効果は１ターンに１度しか使用できない。\n①：「魔術師」Pモンスターを素材としてこのカードがS召喚に成功した場合、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"超重魔獣キュウ－B",h:"ちょうじゅうまじゅうきゅう－びー",e:"Superheavy Samurai Beast Kyubi",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:2500,tx:"チューナー＋チューナー以外の「超重武者」モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：自分の墓地に魔法・罠カードが存在しない場合、このカードの守備力は、相手フィールドの特殊召喚されたモンスターの数×９００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"HSRマッハゴー・イータ",h:"はいすぴーどろいどまっはごー・いーた",e:"Hi-Speedroid Hagoita",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nフィールドの全ての表側表示モンスターのレベルはターン終了時まで１つ上がる。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在し、自分フィールドに「スピードロイド」チューナーが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴヨウ・ディフェンダー",h:"ごよう・でぃふぇんだー",e:"Goyo Defender",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分フィールドのモンスターが戦士族・地属性のSモンスターのみの場合に発動できる。\nエクストラデッキから「ゴヨウ・ディフェンダー」１体を特殊召喚する。\n②：このカードが攻撃対象に選択された時に発動できる。\nこのカードの攻撃力はそのダメージステップ終了時まで、このカード以外の自分フィールドの戦士族・地属性のSモンスターの数×１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴヨウ・キング",h:"ごよう・きんぐ",e:"Goyo King",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"チューナー＋チューナー以外のSモンスター１体以上\n①：このカードが相手モンスターに攻撃する攻撃宣言時に発動する。\nこのカードの攻撃力はダメージステップ終了時まで、自分フィールドの戦士族・地属性のSモンスターの数×４００アップする。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時、以下の効果から１つを選択して発動できる。\n●破壊したそのモンスターを自分フィールドに特殊召喚する。\n●相手フィールドの表側表示モンスター１体を選んでコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"破戒蛮竜－バスター・ドラゴン",h:"はかいばんりゅう－ばすたー・どらごん",e:"Buster Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:1200,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：相手フィールドのモンスターは、このカードが表側表示で存在する限りドラゴン族になる。\n②：自分フィールドに「バスター・ブレイダー」モンスターが存在しない場合、１ターンに１度、自分の墓地の「バスター・ブレイダー」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n③：相手ターンに１度、自分フィールドの「バスター・ブレイダー」モンスター１体を対象として発動できる。\n自分の墓地の「破壊剣」モンスター１体を装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"刀神－不知火",h:"かたながみ－しらぬい",e:"Shiranui Samuraisaga",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:0,tx:"アンデット族チューナー＋チューナー以外のアンデット族モンスター１体以上\n自分は「刀神－不知火」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、除外されている自分のアンデット族モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、その攻撃力以下の攻撃力を持つ相手フィールドのモンスターを全て守備表示にする。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"戦神－不知火",h:"いくさがみ－しらぬい",e:"Shiranui Shogunsaga",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:0,tx:"アンデット族チューナー＋チューナー以外のアンデット族モンスター１体以上\n自分は「戦神－不知火」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地からアンデット族モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで、除外したモンスターの元々の攻撃力分アップする。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた場合、除外されている自分の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔海城アイガイオン",h:"まかいじょうあいがいおん",e:"Aegaion the Sea Castrum",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:3000,tx:"レベル８モンスター×２\n「魔海城アイガイオン」の①②の効果はそれぞれ１ターンに１度しか使用できず、相手ターンでも発動できる。\n①：相手のエクストラデッキから裏側表示のモンスターをランダムに１体除外する。\nこのカードの攻撃力は除外したモンスターの攻撃力と同じになる。\n②：このカードのX素材を１つ取り除き、除外されている相手の融合・S・Xモンスター１体を対象として発動できる。\nそのモンスターをエクストラデッキに戻し、そのモンスターと同じ種類（融合・S・X）の相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エンタメ・バンド・ハリケーン",h:"えんため・ばんど・はりけーん",e:"Performance Hurricane",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「エンタメ・バンド・ハリケーン」は１ターンに１枚しか発動できない。\n①：自分フィールドの「EM」モンスターの数まで相手フィールドのカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・ストーム",h:"ぺんでゅらむ・すとーむ",e:"Pendulum Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのPゾーンのカードを全て破壊する。\nその後、相手フィールドの魔法・罠カード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイ・スピード・リレベル",h:"はい・すぴーど・りれべる",e:"Hi-Speed Re-Level",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「スピードロイド」モンスター１体を除外し、自分フィールドのSモンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、除外したモンスターと同じレベルになり、攻撃力は除外したモンスターのレベル×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣士融合",h:"はかいけんしゆうごう",e:"Destruction Swordsman Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「破壊剣士融合」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札及び自分・相手フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「バスター・ブレイダー」を融合素材とするその融合モンスター１体をエクストラデッキから融合召喚する。\n②：このカードが墓地に存在する場合、手札を１枚墓地へ送って発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣士の宿命",h:"はかいけんしのしゅくめい",e:"Karma of the Destruction Swordsman",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「破壊剣士の宿命」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の同じ種族のモンスターを３体まで対象として発動できる。\nそのモンスターを除外し、自分フィールドの「バスター・ブレイダー」モンスターまたは「破壊剣」モンスター１体を選び、ターン終了時までその攻撃力・守備力を除外したモンスターの数×５００アップする。\n②：このカードが墓地に存在する場合、手札から「破壊剣」カード１枚を捨てて発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜呼相打つ",h:"りゅうこあいうつ",e:"Draco Face-Off",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「竜呼相打つ」は１ターンに１枚しか発動できない。\n①：デッキから「竜剣士」Pモンスター１体と「竜魔王」Pモンスター１体を選んで相手に見せ、相手はその中からランダムに１体選ぶ。\n相手が選んだPモンスターを自分のPゾーンに置くか特殊召喚する。\n残りのPモンスターは自分のエクストラデッキに表側表示で加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミックP",h:"だいなみっくぱわーろーど",e:"Dinomic Powerload",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「ダイナミスト」モンスターの攻撃力・守備力は３００アップする。\n②：自分の「ダイナミスト」モンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・チャージ",h:"だいなみすと・ちゃーじ",e:"Dinomist Charge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「ダイナミスト・チャージ」は１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「ダイナミスト」モンスター１体を手札に加える。\n②：１ターンに１度、「ダイナミスト」カードがフィールドから自分のエクストラデッキに表側表示で加わった場合に発動する。\nそのカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・ソニック",h:"まじぇすぺくたー・そにっく",e:"Majespecter Sonics",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「マジェスペクター」モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力・守備力が倍になり、相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火流 転生の陣",h:"しらぬいりゅう　てんせいのじん",e:"Shiranui Style Synthesis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「不知火流 転生の陣」は１ターンに１枚しか発動できない。\n①：１ターンに１度、自分フィールドにモンスターが存在しない場合、手札を１枚墓地へ送り、以下の効果から１つを選択して発動できる。\n\n●自分の墓地の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n\n●除外されている自分の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・アドベント",h:"おっどあいず・あどべんと",e:"Odd-Eyes Advent",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"ドラゴン族の儀式モンスターの降臨に必要。\n「オッドアイズ・アドベント」は１ターンに１枚しか発動できない。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのPモンスターをリリースし、自分の手札・墓地からドラゴン族の儀式モンスター１体を儀式召喚する。\n相手フィールドにモンスターが２体以上存在し、自分フィールドにモンスターが存在しない場合、自分のエクストラデッキの「オッドアイズ」モンスターもリリースの代わりに墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ツインツイスター",h:"ついんついすたー",e:"Twin Twisters",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、フィールドの魔法・罠カードを２枚まで対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"濡れ衣",h:"ぬれぎぬ",e:"Mistaken Accusation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「濡れ衣」は１ターンに１枚しか発動できない。\n①：相手の手札・フィールドのカードを合計した数が自分の手札・フィールドのカードを合計した数より多い場合、フィールドの表側表示のカード１枚を対象として発動できる。\nお互いのプレイヤーはこのデュエル中、その表側表示のカード以外の、対象のカードと同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の束縛",h:"どらごんず・ばいんど",e:"Dragon's Bind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールドの攻撃力・守備力が２５００以下のドラゴン族モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、お互いに対象のモンスターの元々の攻撃力以下のモンスターを特殊召喚できない。\n②：対象のモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"追走の翼",h:"ついそうのつばさ",e:"Follow Wing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールドのSモンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、対象のモンスターは戦闘及び相手の効果では破壊されない。\n②：対象のモンスターがレベル５以上の相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターを破壊する。\n対象のモンスターの攻撃力はターン終了時まで、この効果で破壊したモンスターの元々の攻撃力分アップする。\n③：対象のモンスターがフィールドから離れた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"リジェクト・リボーン",h:"りじぇくと・りぼーん",e:"Reject Reborn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nバトルフェイズを終了する。\nその後、自分の墓地からチューナーとSモンスターを１体ずつ選んで特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣一閃",h:"はかいけんいっせん",e:"Destruction Sword Flash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「バスター・ブレイダー」を融合素材とする融合モンスターが存在する場合に発動できる。\n相手フィールドのモンスターを全て除外する。\n②：自分フィールドの「バスター・ブレイダー」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・ラッシュ",h:"だいなみすと・らっしゅ",e:"Dinomist Rush",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「ダイナミスト・ラッシュ」は１ターンに１枚しか発動できない。\n①：デッキから「ダイナミスト」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは他のカードの効果を受けず、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・スーパーセル",h:"まじぇすぺくたー・すーぱーせる",e:"Majespecter Supercell",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分のPゾーンに「マジェスペクター」カードが存在する場合、このカード以外の「マジェスペクター」カードの自分フィールドで発動する効果の発動と効果は無効化されない。\n②：１ターンに１度、自分の墓地の「マジェスペクター」カード５枚を対象として発動できる。\nそのカード５枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火流 燕の太刀",h:"しらぬいりゅう　つばくろのたち",e:"Shiranui Style Swallow's Slash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのアンデット族モンスター１体をリリースし、フィールドのカード２枚を対象として発動できる。\nそのカードを破壊する。\nその後、デッキから「不知火」モンスター１体を除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"砂塵のバリア －ダスト・フォース－",h:"さじんのばりあ　－だすと・ふぉーす－",e:"Quaking Mirror Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの攻撃表示モンスターを全て裏側守備表示にする。\nこの効果で裏側守備表示になったモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・リボーン",h:"ぺんでゅらむ・りぼーん",e:"Pendulum Reborn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のエクストラデッキの表側表示のPモンスターまたは自分の墓地のPモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"禁断の異本",h:"きんだんのいほん",e:"Forbidden Apocrypha",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：モンスターカードの種類（融合・S・X）を１つ宣言して発動できる。\n宣言した種類のモンスターがフィールドに２体以上表側表示で存在する場合、お互いのプレイヤーはフィールドのその種類のモンスターを全て墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"神の通告",h:"かみのつうこく",e:"Solemn Strike",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：１５００LPを払って以下の効果を発動できる。\n\n●モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"不運の爆弾",h:"ふうんのばくだん",e:"Bad Luck Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「不運の爆弾」は１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\n自分はその表側表示モンスターの攻撃力の半分のダメージを受ける。\nその後、自分が受けたダメージと同じ数値分のダメージを相手に与える。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"スマイル・ワールド",h:"すまいる・わーるど",e:"Smile World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの全てのモンスターの攻撃力はターン終了時まで、フィールドのモンスターの数×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"超重剣聖ムサ－C",h:"ちょうじゅうけんせいむさ－しー",e:"Superheavy Samurai Swordmaster Musashi",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:300,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードがS召喚に成功した時、自分の墓地の機械族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n自分の墓地に魔法・罠カードが存在する場合、このターン自分はそのモンスター及びその同名モンスターを召喚・特殊召喚できない。\n②：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・エルドニア",h:"ばーじぇすとま・えるどにあ",e:"Paleozoic Eldonia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで５００アップする。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・ディノミスクス",h:"ばーじぇすとま・でぃのみすくす",e:"Paleozoic Dinomischus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示のカード１枚を対象として発動できる。\n手札を１枚捨て、対象のカードを除外する。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・マーレラ",h:"ばーじぇすとま・まーれら",e:"Paleozoic Marrella",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：デッキから罠カード１枚を墓地へ送る。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・レアンコイリア",h:"ばーじぇすとま・れあんこいりあ",e:"Paleozoic Leanchoilia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：除外されている自分または相手のカード１枚を対象として発動できる。\nそのカードを墓地に戻す。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バージェストマ・オパビニア",h:"ばーじぇすとま・おぱびにあ",e:"Paleozoic Opabinia",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2400,tx:"レベル２モンスター×２\n「バージェストマ・オパビニア」の③の効果は１ターンに１度しか使用できない。\n①：このカードは他のモンスターの効果を受けない。\n②：このカードがモンスターゾーンに存在する限り、自分は「バージェストマ」罠カードを手札から発動できる。\n③：このカードが罠カードをX素材としている場合、このカードのX素材を１つ取り除いて発動できる。\nデッキから「バージェストマ」罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神火炎砲",h:"えぐぞーど・ふれいむ",e:"Obliterate!!!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドのモンスター１体を対象として発動できる。\n手札・デッキから「封印されし」モンスター１体または「エクゾディア」カード１枚を墓地へ送り、対象のカードを持ち主の手札に戻す。\n②：このカードが魔法＆罠ゾーンから墓地へ送られた場合、自分の墓地の「封印されし」モンスター１体または「エクゾディア」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ダスティローブ",h:"ふぁんとむ・ないつだすてぃろーぶ",e:"The Phantom Knights of Ancient Cloak",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドに攻撃表示で存在する場合、フィールドの闇属性モンスター１体を対象として発動できる。\nこのカードを守備表示にし、対象のモンスターの攻撃力・守備力は相手ターン終了時まで８００アップする。\n②：墓地のこのカードを除外して発動できる。\nデッキから「幻影騎士団ダスティローブ」以外の「幻影騎士団」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団サイレントブーツ",h:"ふぁんとむ・ないつさいれんとぶーつ",e:"The Phantom Knights of Silent Boots",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:1200,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「幻影騎士団」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：墓地のこのカードを除外して発動できる。\nデッキから「ファントム」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ラギッドグローブ",h:"ふぁんとむ・ないつらぎっどぐろーぶ",e:"The Phantom Knights of Ragged Gloves",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"「幻影騎士団ラギッドグローブ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを素材としてX召喚した闇属性モンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\nこのカードの攻撃力は１０００アップする。\n②：墓地のこのカードを除外して発動できる。\nデッキから「幻影騎士団」カードまたは「ファントム」魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団クラックヘルム",h:"ふぁんとむ・ないつくらっくへるむ",e:"The Phantom Knights of Cloven Helm",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"「幻影騎士団クラックヘルム」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「幻影騎士団」カードまたは「ファントム」魔法・罠カードが自分の墓地へ送られた場合に発動する。\nこのカードの攻撃力は５００アップする。\n②：墓地のこのカードを除外して発動できる。\nこのターンのエンドフェイズに、自分の墓地から「幻影騎士団」カードまたは「ファントム」魔法・罠カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団フラジャイルアーマー",h:"ふぁんとむ・ないつふらじゃいるあーまー",e:"The Phantom Knights of Fragile Armor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"「幻影騎士団フラジャイルアーマー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の「幻影騎士団」モンスターが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：墓地のこのカードを除外し、手札の「幻影騎士団」カードまたは「ファントム」魔法・罠カード１枚を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ブレイクソード",h:"ふぁんとむ・ないつぶれいくそーど",e:"The Phantom Knights of Break Sword",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分及び相手フィールドのカードを１枚ずつ対象として発動できる。\nそのカードを破壊する。\n②：X召喚されたこのカードが破壊された場合、自分の墓地の同じレベルの「幻影騎士団」モンスター２体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは１つ上がる。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影死槍",h:"ふぁんとむ・ですすぴあ",e:"Phantom Knights' Spear",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「幻影騎士団」モンスターを対象とする相手の効果が発動する度に相手に５００ダメージを与える。\n②：自分フィールドの闇属性モンスターが戦闘または相手の効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影霧剣",h:"ふぁんとむ・ふぉっぐ・ぶれーど",e:"Phantom Knights' Fog Blade",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドの効果モンスター１体を対象としてこのカードを発動できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、対象のモンスターは攻撃できず、攻撃対象にならず、効果は無効化される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。\n②：墓地のこのカードを除外し、自分の墓地の「幻影騎士団」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影剣",h:"ふぁんとむ・そーど",e:"Phantom Knights' Sword",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\n「幻影剣」の②の効果は１ターンに１度しか使用できない。\n①：対象のモンスターの攻撃力は８００アップし、戦闘・効果で破壊される場合、代わりにこのカードを破壊できる。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。\n②：墓地のこのカードを除外し、自分の墓地の「幻影騎士団」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影翼",h:"ふぁんとむ・うぃんぐ",e:"Phantom Knights' Wing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「幻影翼」の②の効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００アップし、このターンに１度だけ戦闘・効果では破壊されない。\n②：墓地のこのカードを除外し、自分の墓地の「幻影騎士団」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ブービートラップE",h:"ぶーびーとらっぷいー",e:"Booby Trap E",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n自分の手札・墓地の永続罠カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ネクロ・ヴァルチャー",h:"れいど・らぷたーず－ねくろ・う゛ぁるちゃー",e:"Raidraptor - Necro Vulture",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"①：１ターンに１度、自分フィールドの「RR」モンスター１体をリリースし、自分の墓地の「RUM」魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\nこの効果の発動後、ターン終了時まで自分は「RUM」魔法カードの効果でしかモンスターをX召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ラスト・ストリクス",h:"れいど・らぷたーず－らすと・すとりくす",e:"Raidraptor - Last Strix",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「RR－ラスト・ストリクス」の②の効果は１ターンに１度しか使用できない。\n①：自分の「RR」モンスターが戦闘を行うダメージ計算時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分は自分のフィールド・墓地の魔法・罠カードの数×１００LP回復する。\n②：このカードをリリースして発動できる。\nエクストラデッキから「RR」Xモンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズにエクストラデッキに戻る。\nこのターン相手が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－サテライト・キャノン・ファルコン",h:"れいど・らぷたーず－さてらいと・きゃのん・ふぁるこん",e:"Raidraptor - Satellite Cannon Falcon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"鳥獣族レベル８モンスター×２\n①：このカードが「RR」モンスターを素材としてX召喚に成功した場合に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n②：このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は自分の墓地の「RR」モンスターの数×８００ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－コール",h:"れいど・らぷたーず－こーる",e:"Raidraptor - Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「RR－コール」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「RR」モンスターしか特殊召喚できない。\n①：自分フィールドの「RR」モンスター１体を対象として発動できる。\nその同名モンスター１体を手札・デッキから守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－デス・ダブル・フォース",h:"らんくあっぷまじっく－です・だぶる・ふぉーす",e:"Rank-Up-Magic Doom Double Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターンに戦闘で破壊され自分の墓地へ送られた「RR」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、そのモンスターの倍のランクのXモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－ソウル・シェイブ・フォース",h:"らんくあっぷまじっく－そうる・しぇいぶ・ふぉーす",e:"Rank-Up-Magic Soul Shave Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：LPを半分払い、自分の墓地の「RR」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、そのモンスターよりランクが２つ高いXモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超量士レッドレイヤー",h:"ちょうりょうしれっどれいやー",e:"Super Quantum Red Layer",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"「超量士レッドレイヤー」の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した時、自分の墓地の「超量」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n③：このカードが墓地へ送られた場合、「超量士レッドレイヤー」以外の自分の墓地の「超量」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超量士グリーンレイヤー",h:"ちょうりょうしぐりーんれいやー",e:"Super Quantum Green Layer",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"「超量士グリーンレイヤー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から「超量」モンスター１体を特殊召喚する。\n②：このカードが墓地へ送られた場合、手札の「超量」カード１枚を捨てて発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"超量士ブルーレイヤー",h:"ちょうりょうしぶるーれいやー",e:"Super Quantum Blue Layer",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"「超量士ブルーレイヤー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「超量士ブルーレイヤー」以外の「超量」カード１枚を手札に加える。\n②：このカードが墓地へ送られた場合、自分の墓地の「超量」カードを３枚まで対象として発動できる。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"超量妖精アルファン",h:"ちょうりょうようせいあるふぁん",e:"Super Quantal Fairy Alphan",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「超量妖精アルファン」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分フィールドの「超量」モンスター１体を対象として発動できる。\n自分フィールドの全てのモンスターのレベルは対象のモンスターと同じレベルになる。\n②：このカードをリリースして発動できる。\nデッキから「超量」モンスター３種類を相手に見せ、相手はその中からランダムに１体選ぶ。\nそのモンスター１体を自分フィールドに特殊召喚し、残りを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"超量機獣グランパルス",h:"ちょうりょうきじゅうぐらんぱるす",e:"Super Quantal Mech Beast Grampulse",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2800,tx:"レベル３モンスター×２\n①：X素材が無いこのカードは攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nこのカードが「超量士ブルーレイヤー」をX素材としている場合、この効果は相手ターンでも発動できる。\n③：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドの「超量士」モンスター１体を選び、このカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"超量機獣エアロボロス",h:"ちょうりょうきじゅうえあろぼろす",e:"Super Quantal Mech Beast Aeroboros",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2400,tx:"レベル４モンスター×２\n①：X素材が無いこのカードは攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\nこのカードが「超量士グリーンレイヤー」をX素材としている場合、この効果は相手ターンでも発動できる。\n③：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドの「超量士」モンスター１体を選び、このカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"超量機獣マグナライガー",h:"ちょうりょうきじゅうまぐならいがー",e:"Super Quantal Mech Beast Magnaliger",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル５モンスター×２\n①：X素材が無いこのカードは攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこのカードが「超量士レッドレイヤー」をX素材としている場合、この効果は相手ターンでも発動できる。\n③：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドの「超量士」モンスター１体を選び、このカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"超量機神王グレート・マグナス",h:"ちょうりょうきしんおうぐれーと・まぐなす",e:"Super Quantal Mech King Great Magnus",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3600,de:3200,tx:"レベル１２モンスター×３\n①：このカードのX素材の種類によって以下の効果を得る。\n\n●２種類以上：１ターンに１度、自分・相手のメインフェイズにこのカードのX素材を１つ取り除いて発動できる。\nフィールドのカード１枚を選んでデッキに戻す。\n\n●４種類以上：このカードは「超量」カード以外のカードの効果を受けない。\n\n●６種類以上：相手はカードの効果でデッキからカードを手札に加える事ができない。\n\n②：このカードが墓地へ送られた場合に発動できる。\n自分の墓地から「超量機獣」Xモンスター３種類を１体ずつ選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超量機艦マグナキャリア",h:"ちょうりょうきかんまぐなきゃりあ",e:"Super Quantal Mech Ship Magnacarrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、自分フィールドの「超量士」モンスター１体を対象としてこの効果を発動できる。\nその自分のモンスターと同じ属性の「超量機獣」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\n②：フィールドゾーンのこのカードを墓地へ送り、自分のフィールド・墓地の「超量機獣」Xモンスター３種類を１体ずつ対象として発動できる。\nエクストラデッキから「超量機神王グレート・マグナス」１体を特殊召喚し、その下に対象のモンスターとそのX素材を全て重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"超量機神剣－マグナスレイヤー",h:"ちょうりょうきしんけん－まぐなすれいやー",e:"Super Quantal Mech Sword - Magnaslayer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「超量」Xモンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードを装備したモンスターは、攻撃力がそのモンスターのランクの数×１００アップし、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：自分バトルフェイズに、装備されているこのカードを墓地へ送って発動できる。\nこのカードを装備していたモンスターはこのターン、１度のバトルフェイズ中に３回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD極智王カオス・アポカリプス",h:"でぃーでぃーでぃーきょくちおうかおす・あぽかりぷす",e:"D/D/D Chaos King Apocalypse",t:"monster",a:"闇",l:7,ps:4,pe:"①：自分の墓地の「DD」モンスター２体を除外して発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2700,de:2000,tx:"「DDD極智王カオス・アポカリプス」のモンスター効果は１ターンに１度しか使用できず、相手ターンにのみ発動できる。\n①：このカードが手札・墓地に存在する場合、自分フィールドの表側表示の魔法・罠カード２枚を対象として発動できる。\nそのカードを破壊し、このカードを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"DD魔導賢者ニュートン",h:"でぃーでぃーまどうけんじゃにゅーとん",e:"D/D Savant Newton",t:"monster",a:"闇",l:7,ps:10,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：このカードがPゾーンに存在する限り１度だけ、自分にダメージを与える罠カードの効果が発動した場合、その効果を無効にできる。\nその後、このカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"「DD魔導賢者ニュートン」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、「DD魔導賢者ニュートン」以外の自分の墓地の、「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"DD魔導賢者コペルニクス",h:"でぃーでぃーまどうけんじゃこぺるにくす",e:"D/D Savant Copernicus",t:"monster",a:"闇",l:4,ps:1,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：このカードがPゾーンに存在する限り１度だけ、自分にダメージを与える魔法カードの効果が発動した場合、その効果を無効にできる。\nその後、このカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"「DD魔導賢者コペルニクス」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「DD魔導賢者コペルニクス」以外の「DD」カードまたは「契約書」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"DDオルトロス",h:"でぃーでぃーおるとろす",e:"D/D Orthros",t:"monster",a:"闇",l:4,ps:3,pe:"①：１ターンに１度、フィールドの魔法・罠カード１枚と、このカード以外の自分フィールドの「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:600,de:1800,tx:"①：自分が戦闘・効果でダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功したターン、自分は悪魔族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"DDラミア",h:"でぃーでぃーらみあ",e:"D/D Lamia",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1900,tx:"「DDラミア」の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、手札及び自分フィールドの表側表示のカードの中から、「DDラミア」以外の「DD」カードまたは「契約書」カード１枚を墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"闇魔界の契約書",h:"やみまかいのけいやくしょ",e:"Dark Contract with the Yamimakai",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「闇魔界の契約書」の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択してこの効果を発動できる。\n\n●自分の墓地の「DD」Pモンスター１体を対象として発動できる。\nそのモンスターを自分のPゾーンに置く。\n\n●自分のエクストラデッキから、表側表示の「DD」Pモンスター１体を自分のPゾーンに置く。\n\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"DDリビルド",h:"でぃーでぃーりびるど",e:"D/D Reroll",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「契約書」カードは相手の効果では破壊されない。\n②：墓地のこのカードを除外し、このカード以外の除外されている自分の「DD」カードを３枚まで対象として発動できる。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD剋竜王ベオウルフ",h:"でぃーでぃーでぃーこくりゅうおうべおうるふ",e:"D/D/D Dragonbane King Beowulf",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「DDD」モンスター＋「DD」モンスター\n①：このカードがモンスターゾーンに存在する限り、自分の「DD」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：自分スタンバイフェイズに発動できる。\nお互いの魔法＆罠ゾーンのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD呪血王サイフリート",h:"でぃーでぃーでぃーじゅけつおうさいふりーと",e:"D/D/D Cursed King Siegfried",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2200,tx:"チューナー＋チューナー以外の「DD」モンスター１体以上\n「DDD呪血王サイフリート」の①の効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードの効果は次のスタンバイフェイズまで無効化される。\nこの効果は相手ターンでも発動できる。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動する。\n自分は自分フィールドの「契約書」カードの数×１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラーの翼神竜－不死鳥",h:"らーのよくしんりゅう－ごっどふぇにっくす",e:"The Winged Dragon of Ra - Immortal Phoenix",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：このカードが墓地に存在し、「ラーの翼神竜」がフィールドから自分の墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\nこの効果の発動に対して効果は発動できない。\n②：このカードは他のカードの効果を受けない。\n③：１０００LPを払って発動できる。\nフィールドのモンスター１体を選んで墓地へ送る。\n④：エンドフェイズに発動する。\nこのカードを墓地へ送り、自分の手札・デッキ・墓地から「ラーの翼神竜－球体形」１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"獄炎のカース・オブ・ドラゴン",h:"ごくえんのかーす・おぶ・どらごん",e:"Curse of Dragonfire",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"①：このカードが召喚・特殊召喚に成功した場合、フィールドのフィールド魔法カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：１ターンに１度、自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホールディング・アームズ",h:"ほーるでぃんぐ・あーむず",e:"Holding Arms",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、対象の表側表示モンスターは攻撃できず、効果は無効化される。\n②：このカードの①の効果の対象のモンスターがフィールドに存在している限り、このカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ホールディング・レッグス",h:"ほーるでぃんぐ・れっぐす",e:"Holding Legs",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動する。\nフィールドにセットされた魔法・罠カードを全て持ち主の手札に戻す。\n②：墓地のこのカードを除外し、相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\n次のターンの終了時までそのカードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"天翔の竜騎士ガイア",h:"てんしょうのりゅうきしがいあ",e:"Sky Galloping Gaia the Dragon Champion",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「暗黒騎士ガイア」モンスター＋ドラゴン族モンスター\n①：このカードはモンスターゾーンに存在する限り、カード名を「竜騎士ガイア」として扱う。\n②：このカードが特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地から「螺旋槍殺」１枚を選んで手札に加える。\n③：このカードが相手モンスターに攻撃宣言した時に発動できる。\nその相手モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"真実の名",h:"しんじつのな",e:"The True Name",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：カード名を１つ宣言して発動できる。\n自分のデッキの一番上のカードをめくり、宣言したカードだった場合、そのカードを手札に加える。\nさらに、デッキから神属性モンスター１体を手札に加えるか特殊召喚できる。\n違った場合、めくったカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"串刺しの落とし穴",h:"くしざしのおとしあな",e:"Trap Hole of Spikes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターンに召喚・特殊召喚された相手モンスターの攻撃宣言時に発動できる。\nその攻撃モンスターを破壊し、そのモンスターの元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディノンの鋼鉄騎兵",h:"でぃのんのこうてつきへい",e:"Steel Cavalry of Dinon",t:"monster",a:"地",l:4,ps:5,pe:"",tr:"恐竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:2600,tx:"①：このカードがPモンスターと戦闘を行うダメージステップ開始時に発動する。\nこのカードの攻撃力・守備力はダメージステップ終了時まで半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"神の摂理",h:"かみのせつり",e:"Ultimate Providence",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：モンスターの効果・魔法・罠カードが発動した時、そのカードと同じ種類（モンスター・魔法・罠）の手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－フェルブラン",h:"こずも－ふぇるぶらん",e:"Kozmo Tincan",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「Kozmo－フェルブラン」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル２以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：自分・相手のエンドフェイズに５００LPを払って発動できる。\nデッキから「Kozmo」カード３種類を相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りは墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－シーミウズ",h:"こずも－しーみうず",e:"Kozmo Soartroopers",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「Kozmo－シーミウズ」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル４以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、１０００LPを払い、自分の墓地のサイキック族の「Kozmo」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－デルタシャトル",h:"こずも－でるたしゃとる",e:"Kozmo Delta Shuttle",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：１ターンに１度、デッキから「Kozmo」モンスター１体を墓地へ送り、フィールドの表側表示モンスター１体を対象として発動できる。\n対象のモンスターの攻撃力・守備力は、この効果を発動するために墓地へ送ったモンスターのレベル×１００ダウンする。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル４以下の「Kozmo」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ダークエクリプサー",h:"こずも－だーくえくりぷさー",e:"Kozmo Dark Eclipser",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:2600,tx:"①：このカードは相手の効果の対象にならない。\n②：罠カードが発動した時、自分の墓地から「Kozmo」モンスター１体を除外して発動できる。\nその発動を無効にし破壊する。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル８以下の「Kozmo」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－エナジーアーツ",h:"こずも－えなじーあーつ",e:"Kozmojo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「Kozmo－エナジーアーツ」は１ターンに１枚しか発動できない。\n①：自分フィールドの「Kozmo」モンスター１体を対象として発動できる。\nそのモンスターを破壊し、相手のフィールド・墓地のカード１枚を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"怪粉壊獣ガダーラ",h:"かいふんかいじゅうがだーら",e:"Gadarla, the Mystery Dust Kaiju",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2700,de:1600,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに攻撃表示で特殊召喚できる。\n②：相手フィールドに「壊獣」モンスターが存在する場合、このカードは手札から攻撃表示で特殊召喚できる。\n③：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n④：１ターンに１度、自分・相手フィールドの壊獣カウンターを３つ取り除いて発動できる。\nこのカード以外のフィールドの全てのモンスターの攻撃力・守備力を半分にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"壊星壊獣ジズキエル",h:"かいせいかいじゅうじずきえる",e:"Jizukiru, the Star Destroying Kaiju",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3300,de:2600,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに攻撃表示で特殊召喚できる。\n②：相手フィールドに「壊獣」モンスターが存在する場合、このカードは手札から攻撃表示で特殊召喚できる。\n③：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n④：カード１枚のみを対象とする魔法・罠・モンスターの効果が発動した時、自分・相手フィールドの壊獣カウンターを３つ取り除いて発動できる。\nその効果を無効にし、フィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"妨げられた壊獣の眠り",h:"さまたげられたかいじゅうのねむり",e:"Interrupted Kaiju Slumber",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：フィールドのモンスターを全て破壊する。\nその後、デッキからカード名が異なる「壊獣」モンスターを自分・相手のフィールドに１体ずつ攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは表示形式を変更できず、攻撃可能な場合は攻撃しなければならない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「壊獣」モンスター１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"青き眼の巫女",h:"あおきめのみこ",e:"Priestess with Eyes of Blue",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「青き眼の巫女」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドの表側表示のこのカードが効果の対象になった時に発動できる。\n自分フィールドの効果モンスター１体を選んで墓地へ送り、デッキから「ブルーアイズ」モンスターを２体まで手札に加える（同名カードは１枚まで）。\n②：このカードが墓地に存在する場合、自分フィールドの「ブルーアイズ」モンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"青眼の双爆裂龍",h:"ぶるーあいず・ついん・ばーすと・どらごん",e:"Blue-Eyes Twin Burst Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「青眼の白龍」＋「青眼の白龍」\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●自分のモンスターゾーンの上記カードを墓地へ送った場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードは戦闘では破壊されない。\n②：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードの攻撃で相手モンスターが破壊されなかったダメージステップ終了時に発動できる。\nその相手モンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"青眼の亜白龍",h:"ぶるーあいず・おるたなてぃぶ・ほわいと・どらごん",e:"Blue-Eyes Alternative White Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n手札の「青眼の白龍」１体を相手に見せた場合に特殊召喚できる。\nこの方法による「青眼の亜白龍」の特殊召喚は１ターンに１度しかできない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「青眼の白龍」として扱う。\n②：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔帝アングマール",h:"まていあんぐまーる",e:"Angmarl the Fiendish Monarch",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した時、自分の墓地の魔法カード１枚を除外して発動できる。\n除外したその魔法カードの同名カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・チェンジャー",h:"じゃんく・ちぇんじゃー",e:"Junk Changer",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:900,tx:"「ジャンク・チェンジャー」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、フィールドの「ジャンク」モンスター１体を対象として以下の効果から１つを選択して発動できる。\n\n●対象のモンスターのレベルを１つ上げる。\n\n●対象のモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンクリボー",h:"じゃんくりぼー",e:"Junkuriboh",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：自分にダメージを与える魔法・罠・モンスターの効果を相手が発動した時、自分の手札・フィールドのこのカードを墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・チャージ・ウォリアー",h:"すたーだすと・ちゃーじ・うぉりあー",e:"Stardust Charge Warrior",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n「スターダスト・チャージ・ウォリアー」の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時に発動できる。\n自分はデッキから１枚ドローする。\n②：このカードは特殊召喚された相手モンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"究極幻神 アルティミトル・ビシバールキン",h:"きゅうきょくげんしん　あるてぃみとる・びしばーるきん",e:"Phantasmal Lord Ultimitl Bishbaalkin",t:"monster",a:"闇",l:"",ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:0,tx:"ルール上、このカードのレベルは１２として扱う。\nこのカードはS召喚できず、自分フィールドのレベル８以上で同じレベルの、チューナーとチューナー以外のモンスターを１体ずつ墓地へ送った場合のみ特殊召喚できる。\n①：このカードは効果では破壊されず、攻撃力はフィールドのモンスターの数×１０００アップする。\n②：１ターンに１度、自分・相手のメインフェイズに発動できる。\nお互いのフィールドに同じ数だけ、「邪眼神トークン」（悪魔族・闇・星１・攻／守０）を可能な限り守備表示で特殊召喚する。\nこのターンこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.37 希望織竜スパイダー・シャーク",h:"なんばーず３７ きぼうしきりゅうすぱいだー・しゃーく",e:"Number 37: Hope Woven Dragon Spider Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"水属性レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手のモンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで１０００ダウンする。\n②：このカードが戦闘・効果で破壊され墓地へ送られた時、このカード以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.38 希望魁竜タイタニック・ギャラクシー",h:"なんばーず３８ きぼうかいりゅうたいたにっく・ぎゃらくしー",e:"Number 38: Hope Harbinger Dragon Titanic Galaxy",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル８モンスター×２\n①：１ターンに１度、魔法カードの効果がフィールドで発動した時に発動できる。\nその効果を無効にし、フィールドのそのカードをこのカードの下に重ねてX素材とする。\n②：相手の攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\n攻撃対象をこのカードに移し替えてダメージ計算を行う。\n③：自分フィールドの他のXモンスターが戦闘・効果で破壊された場合、自分フィールドのXモンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、破壊されたそのモンスター１体の元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.35 ラベノス・タランチュラ",h:"なんばーず３５ らべのす・たらんちゅら",e:"Number 35: Ravenous Tarantula",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１０モンスター×２\n①：このカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの攻撃力・守備力は、自分と相手のLPの差の数値分アップする。\n②：X素材を持っているこのカードがモンスターゾーンに存在する限り、相手がモンスターを特殊召喚する度に相手に６００ダメージを与える。\n③：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力以下の攻撃力を持つ相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.84 ペイン・ゲイナー",h:"なんばーず８４ ぺいん・げいなー",e:"Number 84: Pain Gainer",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１１モンスター×２\nこのカードはX素材を２つ以上持っている自分のランク８～１０の闇属性Xモンスターの上に重ねてX召喚する事もできる。\n①：このカードの守備力は、自分フィールドのXモンスターのランクの合計×２００アップする。\n②：X素材を持っているこのカードがモンスターゾーンに存在する限り、相手が魔法・罠カードを発動する度に相手に６００ダメージを与える。\n③：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの守備力以下の守備力を持つ相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.77 ザ・セブン・シンズ",h:"なんばーず７７ ざ・せぶん・しんず",e:"Number 77: The Seven Sins",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:3000,tx:"レベル１２モンスター×２\nこのカードは自分フィールドのランク１０・１１の闇属性Xモンスターの上に重ねてX召喚する事もできる。\nこの方法で特殊召喚したターン、このカードの①の効果は発動できない。\n①：１ターンに１度、このカードのX素材を２つ取り除いて発動できる。\n相手フィールドの特殊召喚されたモンスターを全て除外し、その後、除外したモンスターの中から１体を選んでこのカードの下に重ねてX素材とする。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"帝王の凍気",h:"ていおうのとうき",e:"Frost Blast of the Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに攻撃力２４００以上で守備力１０００のモンスターが存在する場合、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分の墓地からこのカードと「帝王」魔法・罠カード１枚を除外し、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"九十九スラッシュ",h:"つくもすらっしゅ",e:"Tsukumo Slash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「九十九スラッシュ」は１ターンに１枚しか発動できない。\n①：自分のモンスターが、そのモンスターよりも攻撃力の高いモンスターに攻撃するダメージ計算時に発動できる。\nその戦闘を行う自分のモンスターの攻撃力は、そのダメージ計算時のみ自分と相手のLPの差の数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"輝望道",h:"しゃいにんぐ・ほーぷ・ろーど",e:"Shining Hope Road",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを特殊召喚できない。\n①：自分の墓地のモンスター３体を対象として発動できる。\nそのモンスター３体を効果を無効にして特殊召喚し、その３体のみを素材として「ホープ」Xモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団シェード・ブリガンダイン",h:"ふぁんとむ・ないつしぇーど・ぶりがんだいん",e:"The Phantom Knights of Shade Brigandine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n自分の墓地に罠カードが存在しない場合、このカードはセットしたターンでも発動できる。\n①：このカードは発動後、通常モンスター（戦士族・闇・星４・攻０／守３００）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ダーク・ガントレット",h:"ふぁんとむ・ないつだーく・がんとれっと",e:"The Phantom Knights of Dark Gauntlets",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：デッキから「ファントム」魔法・罠カード１枚を墓地へ送る。\n②：自分フィールドにカードが存在しない場合、相手モンスターの直接攻撃宣言時に墓地で発動できる。\nこのカードは効果モンスター（戦士族・闇・星４・攻３００／守６００）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n③：このカードの効果で特殊召喚したこのカードの守備力は、自分の墓地の「ファントム」魔法・罠カードの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団トゥーム・シールド",h:"ふぁんとむ・ないつとぅーむ・しーるど",e:"The Phantom Knights of Tomb Shield",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このカードは発動後、通常モンスター（戦士族・闇・星３・攻／守０）となり、モンスターゾーンに攻撃表示で特殊召喚する（罠カードとしては扱わない）。\n②：自分ターンに墓地のこのカードを除外し、相手フィールドの表側表示の罠カード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・アドバンス",h:"だーく・あどばんす",e:"Dark Advance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「ダーク・アドバンス」は１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズ及びバトルフェイズに、自分の墓地の攻撃力２４００以上で守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札から攻撃力２４００以上で守備力１０００のモンスター１体を表側攻撃表示でアドバンス召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"王魂調和",h:"おうごんちょうわ",e:"King's Consonance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nその攻撃を無効にする。\nその後、以下の効果を適用できる。\n●レベルの合計が８以下になるように、自分の墓地からチューナー１体とチューナー以外のモンスターを任意の数だけ選んで除外し、除外したモンスターのレベルの合計と同じレベルを持つSモンスター１体を、エクストラデッキからS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・スプレマシー",h:"れっど・すぷれましー",e:"Red Supremacy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「レッド・デーモン」Sモンスター１体を除外し、自分フィールドの「レッド・デーモン」Sモンスター１体を対象として発動できる。\nそのモンスターは、このカードを発動するために除外した「レッド・デーモン」Sモンスターと同名カードとして扱い、同じ効果になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エンジェル・トランペッター",h:"えんじぇる・とらんぺったー",e:"Angel Trumpeter",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["チューナー","通常"],ma:"",tp:"",at:1900,de:1600,tx:"天使の様な美しい花。\n絶えず侵入者を惑わす霧を生み出し、聖なる獣たちが住まう森の最深部へ立ち入ることを許さない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオオヤヤドカリ",h:"えんためいとおおややどかり",e:"Performapal Sellshell Crab",t:"monster",a:"水",l:5,ps:2,pe:"①：１ターンに１度、自分の「EM」モンスターが戦闘で破壊された時、自分のPゾーンの「EM」カードまたは「オッドアイズ」カード１枚を対象として発動できる。\nそのカードを特殊召喚する。",tr:"水族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:2500,tx:"①：１ターンに１度、自分フィールドのPモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、自分フィールドの「EM」モンスターの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・ライトフェニックス",h:"えんためいとおっどあいず・らいとふぇにっくす",e:"Performapal Odd-Eyes Light Phoenix",t:"monster",a:"光",l:5,ps:3,pe:"①：もう片方の自分のPゾーンにカードが存在する場合、相手モンスターの直接攻撃宣言時に発動できる。\nもう片方の自分のPゾーンのカードを破壊し、このカードを特殊召喚する。",tr:"鳥獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:1000,tx:"①：このカードをリリースし、自分フィールドの「EM」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・ユニコーン",h:"えんためいとおっどあいず・ゆにこーん",e:"Performapal Odd-Eyes Unicorn",t:"monster",a:"光",l:1,ps:8,pe:"①：このカードがPゾーンに存在する限り１度だけ、自分の「オッドアイズ」モンスターの攻撃宣言時、そのモンスター以外の自分フィールドの「EM」モンスター１体を対象として発動できる。\nその攻撃モンスターの攻撃力はバトルフェイズ終了時まで、対象のモンスターの元々の攻撃力分アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:600,tx:"①：このカードが召喚・特殊召喚に成功した時、自分の墓地の「EM」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分だけ自分はLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMホタルクス",h:"えんためいとほたるくす",e:"Performapal Fireflux",t:"monster",a:"光",l:4,ps:5,pe:"①：１ターンに１度、相手モンスターの攻撃宣言時に自分フィールドの「EM」モンスター１体をリリースして発動できる。\nその攻撃を無効にし、その後バトルフェイズを終了する。",tr:"昆虫族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、自分フィールドの「EM」モンスターまたは「オッドアイズ」モンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"SR電々大公",h:"すぴーどろいどでんでんだいこう",e:"Speedroid Den-Den Daiko Duke",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\n自分の手札・墓地から「SR電々大公」以外の「スピードロイド」チューナー１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SRパチンゴーカート",h:"すぴーどろいどぱちんごーかーと",e:"Speedroid Pachingo-Kart",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：１ターンに１度、手札から機械族モンスター１体を捨て、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"月光蒼猫",h:"むーんらいと・ぶるー・きゃっと",e:"Lunalight Blue Cat",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、「月光蒼猫」以外の自分フィールドの「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の攻撃力の倍になる。\n②：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「ムーンライト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"月光紫蝶",h:"むーんらいと・ぱーぷる・ばたふらい",e:"Lunalight Purple Butterfly",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「月光紫蝶」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドのこのカードを墓地へ送り、自分フィールドの「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\n②：墓地のこのカードを除外して発動できる。\n手札から「ムーンライト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"月光白兎",h:"むーんらいと・ほわいと・らびっと",e:"Lunalight White Rabbit",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した時、「月光白兎」以外の自分の墓地の「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：１ターンに１度、このカード以外の自分フィールドの「ムーンライト」カードの数まで、相手フィールドの魔法・罠カードを対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"月光黒羊",h:"むーんらいと・ぶらっく・しーぷ",e:"Lunalight Black Sheep",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"①：このカードを手札から捨て、以下の効果から１つを選択して発動できる。\n\n●「月光黒羊」以外の自分の墓地の「ムーンライト」モンスター１体を選んで手札に加える。\n\n●デッキから「融合」１枚を手札に加える。\n\n②：このカードが融合召喚の素材となって墓地へ送られた場合に発動できる。\n「月光黒羊」以外の、自分のエクストラデッキの表側表示の「ムーンライト」Pモンスターまたは自分の墓地の「ムーンライト」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"月光狼",h:"むーんらいと・うるふ",e:"Lunalight Wolf",t:"monster",a:"光",l:6,ps:1,pe:"①：自分は「ムーンライト」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分のフィールド・墓地から、「ムーンライト」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をエクストラデッキから融合召喚する。",tr:"獣戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、自分の「ムーンライト」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"月光虎",h:"むーんらいと・たいがー",e:"Lunalight Tiger",t:"monster",a:"光",l:3,ps:5,pe:"①：１ターンに１度、自分の墓地の「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、効果は無効化され、エンドフェイズに破壊される。",tr:"獣戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:800,tx:"「月光虎」のモンスター効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが戦闘・効果で破壊された場合、自分の墓地の「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－アベンジ・ヴァルチャー",h:"れいど・らぷたーず－あべんじ・う゛ぁるちゃー",e:"Raidraptor - Avenge Vulture",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:100,tx:"①：自分が戦闘・効果でダメージを受けた場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「RR」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ペイン・レイニアス",h:"れいど・らぷたーず－ぺいん・れいにあす",e:"Raidraptor - Pain Lanius",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「RR－ペイン・レイニアス」の効果は１ターンに１度しか使用できず、このカードをX召喚の素材とする場合、鳥獣族モンスターのX召喚にしか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの「RR」モンスター１体を対象として発動できる。\n自分はそのモンスターの攻撃力か守備力の内、低い方の数値分のダメージを受け、このカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードのレベルは、対象のモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ブースター・ストリクス",h:"れいど・らぷたーず－ぶーすたー・すとりくす",e:"Raidraptor - Booster Strix",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:1700,tx:"①：自分の「RR」モンスターが相手モンスターの攻撃対象に選択された時、このカードを手札から除外して発動できる。\nその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－弔風のデス",h:"ぶらっくふぇざー－とむらいかぜのです",e:"Blackwing - Decay the Ill Wind",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"「BF－弔風のデス」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、自分フィールドの「BF」モンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●対象のモンスターのレベルを１つ上げる。\n\n●対象のモンスターのレベルを１つ下げる。\n\n②：このカードが墓地へ送られたターンのエンドフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"白き霊龍",h:"しろきれいりゅう",e:"Dragon Spirit of White",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名はルール上「ブルーアイズ」カードとしても扱う。\n①：このカードは手札・墓地に存在する限り、通常モンスターとして扱う。\n②：このカードが召喚・特殊召喚に成功した時、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。\n③：相手フィールドにモンスターが存在する場合、このカードをリリースして発動できる。\n手札から「青眼の白龍」１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"青き眼の護人",h:"あおきめのもりと",e:"Protector with Eyes of Blue",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1300,tx:"「青き眼の護人」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札から光属性・レベル１チューナー１体を特殊召喚する。\n②：自分フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、手札から「ブルーアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"青き眼の賢士",h:"あおきめのけんし",e:"Sage with Eyes of Blue",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「青き眼の賢士」以外の光属性・レベル１チューナー１体を手札に加える。\n②：このカードを手札から捨て、自分フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、デッキから「ブルーアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"青き眼の祭司",h:"あおきめのさいし",e:"Master with Eyes of Blue",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:1200,tx:"「青き眼の祭司」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の光属性・レベル１チューナー１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：墓地のこのカードをデッキに戻し、自分フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、そのモンスター以外の「ブルーアイズ」モンスター１体を自分の墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"太古の白石",h:"ほわいと・おぶ・えんしぇんと",e:"The White Stone of Ancients",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「ブルーアイズ」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「ブルーアイズ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魔王レクターP",h:"りゅうまおうれくたーぺんでゅらむ",e:"Lector Pendulum, the Dracoverlord",t:"monster",a:"闇",l:4,ps:5,pe:"①：このカードがPゾーンに存在する限り、相手フィールドの表側表示のPモンスターの効果は無効化される。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1950,de:0,tx:"①：このカードがPモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターとこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・ガストル",h:"あもるふぁーじ・がすとる",e:"Amorphage Gluttony",t:"monster",a:"地",l:2,ps:5,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、お互いに「アモルファージ」モンスター以外のモンスターの効果を発動できない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:1850,tx:"①：P召喚・リバースしたこのカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・ルクス",h:"あもるふぁーじ・るくす",e:"Amorphage Lechery",t:"monster",a:"地",l:2,ps:5,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、お互いに「アモルファージ」カード以外の魔法カードの効果を発動できない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1350,de:0,tx:"①：P召喚・リバースしたこのカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・プレスト",h:"あもるふぁーじ・ぷれすと",e:"Amorphage Greed",t:"monster",a:"地",l:4,ps:3,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、お互いに「アモルファージ」カード以外の罠カードの効果を発動できない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:1950,tx:"①：P召喚・リバースしたこのカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・キャヴム",h:"あもるふぁーじ・きゃう゛む",e:"Amorphage Envy",t:"monster",a:"地",l:4,ps:5,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、お互いに魔法・罠・モンスターの効果をチェーンして発動できない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2050,tx:"①：P召喚・リバースしたこのカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・オルガ",h:"あもるふぁーじ・おるが",e:"Amorphage Wrath",t:"monster",a:"地",l:4,ps:3,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、お互いに「アモルファージ」モンスター以外のモンスターをリリースできない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1650,de:0,tx:"①：P召喚・リバースしたこのカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・ヒュペル",h:"あもるふぁーじ・ひゅぺる",e:"Amorphage Pride",t:"monster",a:"地",l:4,ps:3,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、お互いが受ける効果ダメージは０になる。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1750,de:0,tx:"①：P召喚・リバースしたこのカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・ノーテス",h:"あもるふぁーじ・のーてす",e:"Amorphage Sloth",t:"monster",a:"地",l:6,ps:3,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、お互いにドロー以外の方法でデッキからカードを手札に加える事はできない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2250,de:0,tx:"①：このカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・イリテュム",h:"あもるふぁーじ・いりてゅむ",e:"Amorphage Goliath",t:"monster",a:"地",l:8,ps:5,pe:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：自分フィールドに「アモルファージ」モンスターが存在する限り、「アモルファージ」カード以外のお互いの墓地へ送られるカードは墓地へは行かず除外される。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2750,de:0,tx:"①：このカードがモンスターゾーンに存在する限り、お互いに「アモルファージ」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・スピノス",h:"だいなみすと・すぴのす",e:"Dinomist Spinos",t:"monster",a:"水",l:5,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:1800,tx:"①：このカード以外の自分フィールドの「ダイナミスト」モンスター１体をリリースし、以下の効果から１つを選択して発動できる。\n\n●このターン、このカードは直接攻撃できる。\n\n●このターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－コクーンデンサ",h:"でじたる・ばぐ－こくーんでんさ",e:"Digital Bug Cocoondenser",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、このカードがフィールドに攻撃表示で存在する場合、自分の墓地の昆虫族・レベル３モンスター１体を対象として発動できる。\nこのカードを守備表示にし、対象のモンスターを守備表示で特殊召喚する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが守備表示モンスターを攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－センチビット",h:"でじたる・ばぐ－せんちびっと",e:"Digital Bug Centibit",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、表側攻撃表示のこのカードが守備表示になった時に発動できる。\nデッキから昆虫族・レベル３モンスター１体を守備表示で特殊召喚する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードは相手フィールドの全ての守備表示モンスターに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－ウェブソルダー",h:"でじたる・ばぐ－うぇぶそるだー",e:"Digital Bug Websolder",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、自分フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にし、手札から昆虫族・レベル３モンスター１体を守備表示で特殊召喚する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n相手フィールドの全ての表側表示モンスターは、守備力が０になり、守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドアイズ・トゥーン・ドラゴン",h:"れっどあいず・とぅーん・どらごん",e:"Red-Eyes Toon Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["トゥーン","効果"],ma:"",tp:"",at:2400,de:2000,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：１ターンに１度、自分メインフェイズに発動できる。\n手札から「レッドアイズ・トゥーン・ドラゴン」以外のトゥーンモンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"龍大神",h:"りゅうおおかみ",e:"Ryu Okami",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2900,de:1200,tx:"①：相手がモンスターの特殊召喚に成功した場合に発動する。\n相手はエクストラデッキのカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"天魔大帝",h:"てんまたいてい",e:"Tenmataitei",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、通常召喚されたモンスターの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"裏風の精霊",h:"うらかぜのせいれい",e:"Spirit of the Fall Wind",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"①：このカードが召喚に成功した場合に発動できる。\nデッキからリバースモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"浮幽さくら",h:"ふゆさくら",e:"Ghost Reaper & Winter Cherries",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードを手札から捨てて発動できる。\n自分のEXデッキのカード１枚を選んでお互いに確認する。\nその後、相手のEXデッキを確認し、選んだカードの同名カードがある場合、その相手の同名カードを全て除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"修禅僧 ゲンドウ",h:"しゅぜんそう　げんどう",e:"Gendo the Ascetic Monk",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、フィールドの表側攻撃表示モンスターは効果を発動できない。\n②：守備表示のこのカードはカードの効果の対象にならず、効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ００９",h:"ぶんぼーぐぜろぜろないん",e:"Deskbot 009",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：１ターンに１度、自分メインフェイズ１に発動できる。\nこのカードの攻撃力は相手ターン終了時まで、「ブンボーグ００９」以外の自分フィールドの「ブンボーグ」モンスターの攻撃力の合計分アップする。\nこの効果を発動するターン、このカードしか攻撃できない。\n②：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n③：このカードが戦闘・効果で破壊される場合、代わりに自分フィールドの「ブンボーグ」カード１枚を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコロプス",h:"さいころぷす",e:"Dicelops",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の効果を適用する。\n \n●１：相手の手札を確認し、その中からカード１枚を選んで捨てる。\n\n●２～５：自分の手札を１枚選んで捨てる。\n\n●６：自分の手札を全て捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"虚竜魔王アモルファクターP",h:"きょりゅうまおうあもるふぁくたーさいこ",e:"Amorphactor Pain, the Imagination Dracoverlord",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2950,de:2500,tx:"「アモルファスP」により降臨。\n①：このカードが儀式召喚に成功した場合、次の相手ターンのメインフェイズ１をスキップする。\n②：このカードがモンスターゾーンに存在する限り、フィールドの表側表示の融合・S・Xモンスターの効果は無効化される。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「虚竜魔王アモルファクターP」以外の「竜魔王」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻奏の華歌聖ブルーム・プリマ",h:"げんそうのはなかせいぶるーむ・ぷりま",e:"Bloom Prima the Melodious Choir",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:2000,tx:"「幻奏の音姫」モンスター＋「幻奏」モンスター１体以上\n①：このカードの攻撃力は、このカードの融合素材としたモンスターの数×３００アップする。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：融合召喚されたこのカードが墓地へ送られた場合、自分の墓地の「幻奏」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"月光舞猫姫",h:"むーんらいと・きゃっと・だんさー",e:"Lunalight Cat Dancer",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「ムーンライト」モンスター×２\n①：このカードは戦闘では破壊されない。\n②：１ターンに１度、自分メインフェイズ１にこのカード以外の自分フィールドの「ムーンライト」モンスター１体をリリースして発動できる。\nこのターン、相手モンスターはそれぞれ１度だけ戦闘では破壊されず、このカードは全ての相手モンスターに２回ずつ攻撃できる。\n③：このカードの攻撃宣言時に発動する。\n相手に１００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"月光舞豹姫",h:"むーんらいと・ぱんさー・だんさー",e:"Lunalight Panther Dancer",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2500,tx:"「月光舞猫姫」＋「ムーンライト」モンスター\nこのカードは上記のカードを融合素材にした融合召喚でのみEXデッキから特殊召喚できる。\n①：このカードは相手の効果では破壊されない。\n②：１ターンに１度、自分メインフェイズ１に発動できる。\nこのターン、相手モンスターはそれぞれ１度だけ戦闘では破壊されず、このカードは全ての相手モンスターに２回ずつ攻撃できる。\n③：このカードが戦闘で相手モンスターを破壊した時に発動する。\nこのカードの攻撃力はバトルフェイズ終了時まで２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"月光舞獅子姫",h:"むーんらいと・らいお・だんさー",e:"Lunalight Leo Dancer",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:3000,tx:"「月光舞豹姫」＋「ムーンライト」モンスター×２\nこのカードは上記のカードを融合素材にした融合召喚でのみ特殊召喚できる。\n①：このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：１ターンに１度、このカードがモンスターを攻撃したダメージステップ終了時に発動できる。\n相手フィールドの特殊召喚されたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタルウィング・シンクロ・ドラゴン",h:"くりすたるうぃんぐ・しんくろ・どらごん",e:"Crystal Wing Synchro Dragon",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のSモンスター１体以上\n①：１ターンに１度、このカード以外のモンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\nこの効果でモンスターを破壊した場合、このカードの攻撃力はターン終了時まで、この効果で破壊したモンスターの元々の攻撃力分アップする。\n②：このカードがレベル５以上の相手モンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力はそのダメージ計算時のみ、戦闘を行う相手モンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"HSR快刀乱破ズール",h:"はいすぴーどろいどかいとうらっぱずーる",e:"Hi-Speedroid Puzzle",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1300,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが特殊召喚されたモンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードの攻撃力はそのダメージステップ終了時まで倍になる。\n②：S召喚したこのカードが墓地へ送られたターンのエンドフェイズに、「HSR快刀乱破ズール」以外の自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"A BF－涙雨のチドリ",h:"あさると　ぶらっくふぇざー－なみだあめのちどり",e:"Assault Blackwing - Chidori the Rain Sprinkling",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：このカードの攻撃力は自分の墓地の「BF」モンスターの数×３００アップする。\n③：このカードが破壊され墓地へ送られた時、「A BF－涙雨のチドリ」以外の自分の墓地の鳥獣族Sモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"青眼の精霊龍",h:"ぶるーあいず・すぴりっと・どらごん",e:"Blue-Eyes Spirit Dragon",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:3000,tx:"チューナー＋チューナー以外の「ブルーアイズ」モンスター１体以上\n①：このカードがモンスターゾーンに存在する限り、お互いに２体以上のモンスターを同時に特殊召喚できない。\n②：１ターンに１度、墓地のカードの効果が発動した時に発動できる。\nその発動を無効にする。\n③：S召喚したこのカードをリリースして発動できる。\nエクストラデッキから「青眼の精霊龍」以外のドラゴン族・光属性のSモンスター１体を守備表示で特殊召喚する。\nそのモンスターはこのターンのエンドフェイズに破壊される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－アルティメット・ファルコン",h:"れいど・らぷたーず－あるてぃめっと・ふぁるこん",e:"Raidraptor - Ultimate Falcon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3500,de:2000,tx:"鳥獣族レベル１０モンスター×３\n①：このカードは他のカードの効果を受けない。\n②：このカードのX素材を１つ取り除いて発動できる。\nこのターン、相手フィールドのモンスターの攻撃力は１０００ダウンし、相手はカードの効果を発動できない。\n③：このカードが「RR」モンスターをX素材としている場合、以下の効果を得る。\n●お互いのエンドフェイズ毎に発動できる。\n相手フィールドの全てのモンスターの攻撃力は１０００ダウンする。\n相手フィールドに表側表示モンスターが存在しない場合、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－スカラジエータ",h:"でじたる・ばぐ－すからじえーた",e:"Digital Bug Scaradiator",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1400,tx:"昆虫族・光属性レベル３モンスター×２体以上\n①：１ターンに１度、このカードのX素材を２つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更し、その効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\n破壊したそのモンスターをこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－コアベージ",h:"でじたる・ばぐ－こあべーじ",e:"Digital Bug Corebage",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"昆虫族・光属性レベル５モンスター×２体以上\nこのカードは自分フィールドのランク３・４の昆虫族XモンスターからX素材を２つ取り除き、そのXモンスターの上に重ねてX召喚する事もできる。\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの守備表示モンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。\n②：１ターンに１度、フィールドのモンスターの表示形式が変更された場合に発動できる。\n自分の墓地の昆虫族モンスター１体を選び、このカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－ライノセバス",h:"でじたる・ばぐ－らいのせばす",e:"Digital Bug Rhinosebus",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2200,tx:"昆虫族・光属性レベル７モンスター×２体以上\nこのカードは自分フィールドのランク５・６の昆虫族XモンスターからX素材を２つ取り除き、そのXモンスターの上に重ねてX召喚する事もできる。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの守備力が一番高いモンスターを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォルテッシモ",h:"ふぉるてっしも",e:"Fortissimo",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの「幻奏」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターの攻撃力は次の自分スタンバイフェイズまで８００アップする。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n「幻奏」融合モンスターカードによって決められた融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－スキップ・フォース",h:"らんくあっぷまじっく－すきっぷ・ふぉーす",e:"Rank-Up-Magic Skip Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「RR」Xモンスター１体を対象として発動できる。\nそのモンスターよりランクが２つ高い「RR」モンスター１体を、対象の自分のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\n②：自分の墓地からこのカードと「RR」モンスター１体を除外し、自分の墓地の「RR」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"光の霊堂",h:"ひかりのれいどう",e:"Mausoleum of White",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに光属性・レベル１チューナー１体を召喚できる。\n②：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\n手札・デッキから通常モンスター１体を墓地へ送る。\n対象のモンスターの攻撃力・守備力はターン終了時まで、墓地へ送ったモンスターのレベル×１００アップする。\n③：墓地のこのカードを除外して発動できる。\nデッキから「滅びの爆裂疾風弾」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"光の導き",h:"ひかりのみちびき",e:"Beacon of White",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに他の「光の導き」が存在せず、自分の墓地に「ブルーアイズ」モンスターが３体以上存在する場合、その内の１体を対象としてこのカードを発動できる。\nそのモンスターを効果を無効にして特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\n②：装備モンスター以外の自分のモンスターは攻撃できず、自分の墓地に「ブルーアイズ」モンスターが存在する場合、装備モンスターはその数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜の目覚め",h:"しんりゅうのめざめ",e:"Forge of the True Dracos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：「竜魔王」モンスター及びPモンスター以外の「竜剣士」モンスターがフィールドに存在する場合に発動できる。\nお互いのフィールドのカードを全て持ち主のデッキに戻す。\nその後、デッキから「竜剣士」モンスターまたは「竜魔王」モンスター１体を召喚条件を無視して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファスP",h:"あもるふぁすぺるそな",e:"Amorphous Persona",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「アモルファージ」モンスターの攻撃力・守備力は３００アップする。\n②：このカードがフィールドゾーンに存在する限り、自分フィールドの「アモルファージ」モンスターがリリースされる度に自分はデッキから１枚ドローする。\nこの効果は１ターンに２度まで適用できる。\n③：墓地のこのカードを除外して発動できる。\n自分の手札・フィールドから、レベルの合計が８になるようにPモンスターをリリースし、手札から「虚竜魔王アモルファクターP」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・インフェクション",h:"あもるふぁーじ・いんふぇくしょん",e:"Amorphage Infection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「アモルファージ・インフェクション」の②の効果は１ターンに１度しか使用できない。\n①：フィールドの「アモルファージ」モンスターの攻撃力・守備力は、フィールドの「アモルファージ」カードの数×１００アップする。\n②：自分の手札・フィールドのモンスターがリリースされた場合、または戦闘・効果で破壊された場合に発動できる。\nデッキから「アモルファージ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"光虫基盤",h:"ばぐ・まとりっくす",e:"Bug Matrix",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「光虫基盤」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの昆虫族モンスターの攻撃力・守備力は３００アップする。\n②：自分フィールドの昆虫族Xモンスター１体を対象として発動できる。\n手札の昆虫族モンスター１体をそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"儀式の下準備",h:"ぎしきのしたじゅんび",e:"Pre-Preparation of Rites",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから儀式魔法カード１枚を選び、さらにその儀式魔法カードにカード名が記された儀式モンスター１体を自分のデッキ・墓地から選ぶ。\nそのカード２枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"融合識別",h:"ふゅーじょん・たぐ",e:"Fusion Tag",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスター１体を対象として発動できる。\nEXデッキの融合モンスター１体を相手に見せる。\nこのターン、対象のモンスターを融合素材とする場合、その見せたモンスターの同名カードとして融合素材にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"チューナーズ・ハイ",h:"ちゅーなーず・はい",e:"Tuner's High",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札からモンスター１体を捨てて発動できる。\nそのモンスターと同じ種族・属性でレベルが１つ高いチューナー１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブンボーグ・ベース",h:"ぶんぼーぐ・べーす",e:"Deskbot Base",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「ブンボーグ」モンスターの攻撃力・守備力は５００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札の「ブンボーグ」カードを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけデッキからドローする。\n③：「ブンボーグ・ベース」以外の自分のフィールド・墓地の「ブンボーグ」カード９種類を１枚ずつ除外して発動できる。\n相手の手札・フィールド・墓地のカードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"無念の手札",h:"むねんのてふだ",e:"Finite Cards",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いの手札制限枚数は３枚になる。",li:"",lk:[],ta:[],al:[]},
  {n:"リサイコロ",h:"りさいころ",e:"Re-dyce-cle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「スピードロイド」チューナー１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、サイコロを１回振る。\nその特殊召喚したモンスターはターン終了時まで出た目と同じレベルになる。\n②：墓地のこのカードを除外して発動できる。\n自分フィールドの「スピードロイド」チューナーを含むモンスターを素材として、風属性Sモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"月光輪廻舞踊",h:"むーんらいと・りんかねーしょん・だんす",e:"Lunalight Reincarnation Dance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「月光輪廻舞踊」は１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターが戦闘・効果で破壊された場合に発動できる。\nデッキから「ムーンライト」モンスターを２体まで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アモルファージ・ライシス",h:"あもるふぁーじ・らいしす",e:"Amorphage Lysis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「アモルファージ・ライシス」の②の効果は１ターンに１度しか使用できない。\n①：「アモルファージ」モンスター以外のフィールドのモンスターの攻撃力・守備力は、フィールドの「アモルファージ」カードの数×１００ダウンする。\n②：自分のPゾーンのカードが破壊された場合に発動できる。\nデッキから「アモルファージ」Pモンスター１体を選んで自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・エラプション",h:"だいなみすと・えらぷしょん",e:"Dinomist Eruption",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ダイナミスト」モンスターが戦闘・効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"光虫異変",h:"ばぐ・えまーじぇんしー",e:"Bug Emergency",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「光虫異変」は１ターンに１枚しか発動できない。\n①：自分の墓地の昆虫族・レベル３モンスター２体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：自分の墓地からこのカードとXモンスター１体を除外して発動できる。\n自分フィールドの全ての昆虫族・レベル３モンスターのレベルはターン終了時まで、除外したXモンスターのランクと同じ数値のレベルになる。\nこの効果の発動後、ターン終了時まで自分は昆虫族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"波紋のバリア －ウェーブ・フォース－",h:"はもんのばりあ　－うぇーぶ・ふぉーす－",e:"Drowning Mirror Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\n相手フィールドの攻撃表示モンスターを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンダー・エクシーズ",h:"わんだー・えくしーず",e:"Wonder Xyz",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターを素材としてXモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"仁王立ち",h:"におうだち",e:"Rise to Full Height",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの守備力は倍になり、ターン終了時にその守備力は０になる。\n②：墓地のこのカードを除外し、自分フィールドのモンスター１体を対象として発動できる。\nこのターン、相手は対象のモンスターしか攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"フレンドリーファイア",h:"ふれんどりーふぁいあ",e:"Bad Aim",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の魔法・罠・モンスターの効果が発動した時、そのカード以外のフィールドのカード１枚を対象として発動できる。\n対象のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"揺るがぬ絆",h:"ゆるがぬきずな",e:"Unwavering Bond",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：Pモンスターの効果または既にPゾーンに存在しているカードの効果を相手が発動した時に発動できる。\nその発動を無効にし、除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"天使の涙",h:"てんしのなみだ",e:"Graceful Tear",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札を１枚選んで相手の手札に加える。\nその後、自分は２０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"エルフの聖剣士",h:"えるふのせいけんし",e:"Celtic Guard of Noble Arms",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:700,tx:"このカードはルール上「エルフの剣士」カードとしても扱う。\n①：自分の手札がある場合、このカードは攻撃できない。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札から「エルフの剣士」モンスター１体を特殊召喚する。\n③：このカードの攻撃で相手に戦闘ダメージを与えた時に発動できる。\n自分フィールドの「エルフの剣士」モンスターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅竜ガンドラX",h:"はめつりゅうがんどらくろす",e:"Gandora-X the Dragon of Demolition",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが手札からの召喚・特殊召喚に成功した時に発動できる。\nこのカード以外のフィールドのモンスターを全て破壊し、破壊したモンスターの内、攻撃力が一番高いモンスターの攻撃力分のダメージを相手に与える。\nこのカードの攻撃力は、この効果で相手に与えたダメージと同じ数値になる。\n②：自分エンドフェイズに発動する。\n自分のLPを半分にする。",li:0,lk:[],ta:[],al:[]},
  {n:"暗黒騎士ガイアロード",h:"あんこくきしがいあろーど",e:"Lord Gaia the Fierce Knight",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、このカードより攻撃力が高いモンスターが相手フィールドに特殊召喚された場合に発動できる。\nこのカードの攻撃力はターン終了時まで７００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レモン・マジシャン・ガール",h:"れもん・まじしゃん・がーる",e:"Lemon Magician Girl",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"①：１ターンに１度、「レモン・マジシャン・ガール」以外の自分フィールドの「マジシャン・ガール」モンスター１体をリリースして発動できる。\nデッキから魔法使い族モンスター１体を手札に加える。\n②：１ターンに１度、このカードが攻撃対象に選択された場合に発動できる。\n手札から魔法使い族モンスター１体を効果を無効にして特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替え、攻撃モンスターの攻撃力を半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"守護神官マハード",h:"しゅごしんかんまはーど",e:"Palladium Oracle Mahad",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2100,tx:"①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが闇属性モンスターと戦闘を行うダメージステップの間、このカードの攻撃力は倍になる。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャン」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"チョコ・マジシャン・ガール",h:"ちょこ・まじしゃん・がーる",e:"Chocolate Magician Girl",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：１ターンに１度、手札から魔法使い族モンスター１体を捨てて発動できる。\n自分はデッキから１枚ドローする。\n②：１ターンに１度、このカードが攻撃対象に選択された場合、「チョコ・マジシャン・ガール」以外の自分の墓地の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替え、攻撃モンスターの攻撃力を半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"アークブレイブドラゴン",h:"あーくぶれいぶどらごん",e:"Arkbrave Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"①：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n相手フィールドの表側表示の魔法・罠カードを全て除外し、このカードの攻撃力・守備力は、この効果で除外したカードの数×２００アップする。\n②：このカードが墓地へ送られた次のターンのスタンバイフェイズに、「アークブレイブドラゴン」以外の自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"巨神竜フェルグラント",h:"きょしんりゅうふぇるぐらんと",e:"Divine Dragon Lord Felgrand",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2800,tx:"①：このカードが墓地からの特殊召喚に成功した場合、相手のフィールド・墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外し、このカードの攻撃力・守備力は、除外したそのモンスターのレベルまたはランク×１００アップする。\n②：このカードが戦闘で相手モンスターを破壊した場合、「巨神竜フェルグラント」以外の自分または相手の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"巨竜の聖騎士",h:"ぱらでぃん・おぶ・ふぇるぐらんと",e:"Paladin of Felgrand",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・デッキからレベル７・８のドラゴン族モンスター１体を装備カード扱いとしてこのカードに装備する。\n②：装備カードを装備したこのカードは他のモンスターの効果を受けない。\n③：自分フィールドのモンスター１体とこのカードをリリースし、自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"巨竜の守護騎士",h:"がーでぃあん・おぶ・ふぇるぐらんと",e:"Guardian of Felgrand",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・墓地からレベル７・８のドラゴン族モンスター１体を選んで装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値の半分アップする。\n③：自分フィールドのモンスター１体とこのカードをリリースし、自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"巨神竜の遺跡",h:"きょしんりゅうのいせき",e:"Ruins of the Divine Dragon Lords",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにレベル７・８のドラゴン族モンスターが存在し、墓地以外からモンスターが特殊召喚された場合に発動する。\nそのモンスターの効果はターン終了時まで無効化される。\n②：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を墓地へ送って発動できる。\n自分フィールドに「巨竜トークン」（ドラゴン族・光・星１・攻／守０）１体を特殊召喚する。\n③：このカードが墓地に存在する場合、自分の手札・フィールドのレベル７・８のドラゴン族モンスター１体を墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"復活の福音",h:"ふっかつのふくいん",e:"Return of the Dragon Lords",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドのドラゴン族モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"月光紅狐",h:"むーんらいと・くりむぞん・ふぉっくす",e:"Lunalight Crimson Fox",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードが効果で墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力をターン終了時まで０にする。\n②：自分フィールドの「ムーンライト」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にし、お互いのプレイヤーは１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMスライハンド・マジシャン",h:"えんためいとすらいはんど・まじしゃん",e:"Performapal Sleight Hand Magician",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードはPモンスター以外の自分フィールドの「EM」モンスター１体をリリースして手札から特殊召喚できる。\n②：１ターンに１度、手札を１枚捨て、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMキングベアー",h:"えんためいときんぐべあー",e:"Performapal King Bear",t:"monster",a:"地",l:6,ps:7,pe:"①：このカードを発動したターンのエンドフェイズに発動できる。\nこのカードを破壊し、自分のエクストラデッキの表側表示のPモンスター及び自分の墓地のモンスターの中からレベル７以上のモンスター１体を選んで手札に加える。",tr:"獣戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2200,de:1000,tx:"①：攻撃表示のこのカードは魔法・罠カードの効果では破壊されない。\n②：このカードの攻撃力は自分バトルフェイズの間、自分フィールドの「EM」カードの数×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMブランコブラ",h:"えんためいとぶらんこぶら",e:"Performapal Swincobra",t:"monster",a:"地",l:4,ps:2,pe:"①：１ターンに１度、自分のモンスターが相手に戦闘ダメージを与えた時に発動できる。\n相手のデッキの一番上のカードを墓地へ送る。",tr:"爬虫類族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:1800,tx:"①：このカードは直接攻撃できる。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMモモンカーペット",h:"えんためいとももんかーぺっと",e:"Performapal Momoncarpet",t:"monster",a:"地",l:3,ps:7,pe:"①：もう片方の自分のPゾーンにカードが存在しない場合にこのカードは破壊される。\n②：このカードがPゾーンに存在する限り、自分が受ける戦闘ダメージは半分になる。",tr:"獣族",mo:["ペンデュラム","リバース","効果"],ma:"",tp:"",at:1000,de:100,tx:"①：このカードがリバースした場合、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが特殊召喚に成功した場合に発動できる。\nこのカードを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMインコーラス",h:"えんためいといんこーらす",e:"Performapal Parrotrio",t:"monster",a:"風",l:3,ps:2,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「EMインコーラス」以外の「EM」カード、「魔術師」カード、「オッドアイズ」カードの内、いずれかが存在する場合に発動できる。\nこのカードのPスケールはターン終了時まで７になる。",tr:"鳥獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが戦闘で破壊された時に発動できる。\nデッキからPモンスター以外の「EM」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMロングフォーン・ブル",h:"えんためいとろんぐふぉーん・ぶる",e:"Performapal Longphone Bull",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「EMロングフォーン・ブル」の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキからPモンスター以外の「EM」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMギッタンバッタ",h:"えんためいとぎったんばった",e:"Performapal Teeter Totter Hopper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:1200,tx:"「EMギッタンバッタ」の③の効果は１ターンに１度しか使用できない。\n①：特殊召喚されたこのカードは１ターンに１度だけ戦闘では破壊されない。\n②：相手エンドフェイズに自分の墓地のレベル３以下の「EM」モンスター１体を対象として発動できる。\nこのカードを墓地へ送り、そのモンスターを手札に加える。\n③：このカードが墓地に存在する状態で、「EM」モンスターが手札から自分の墓地へ送られた場合に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMドラマチックシアター",h:"えんためいとどらまちっくしあたー",e:"Performapal Dramatic Theater",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターの攻撃力は、自分フィールドのモンスターの種族の種類×２００アップする。\n②：１ターンに１度、自分フィールドの「EM」モンスターの種族が４種類の場合に発動できる。\n自分の手札・デッキ・墓地から「オッドアイズ」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMショーダウン",h:"えんためいとしょーだうん",e:"Performapal Show Down",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示の魔法カードの数まで、相手フィールドの表側表示モンスターを対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"創世の竜騎士",h:"そうせいのりゅうきし",e:"Dragon Knight of Creation",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードのレベルは相手ターンの間４つ上がる。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキからレベル７・８のドラゴン族モンスター１体を墓地へ送る。\n③：手札を１枚墓地へ送り、自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シュルブの魔導騎兵",h:"しゅるぶのまどうきへい",e:"Magical Cavalry of Cxulub",t:"monster",a:"地",l:4,ps:3,pe:"",tr:"恐竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：このカードはモンスターゾーンに存在する限り、Pモンスター以外のモンスターが発動した効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"烏合無象",h:"うごうむぞう",e:"Cattle Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドから元々の種族が獣族・獣戦士族・鳥獣族の表側表示モンスター１体を墓地へ送って発動できる。\n元々の種族が墓地へ送ったそのモンスターと同じモンスター１体をEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－パーヴィッド",h:"こずも－ぱーう゛ぃっど",e:"Kozmo Scaredy Lion",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1200,de:500,tx:"「Kozmo－パーヴィッド」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル３以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、５００LPを払い、除外されている自分の「Kozmo」モンスター３体を対象として発動できる。\nそのモンスターを墓地に戻し、相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ダーク・エルファイバー",h:"こずも－だーく・えるふぁいばー",e:"Kozmoll Dark Lady",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"「Kozmo－ダーク・エルファイバー」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを除外して発動できる。\n手札からレベル６以上の「Kozmo」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、このカード以外のモンスターの効果が発動した時、１０００LPを払って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ランドウォーカー",h:"こずも－らんどうぉーかー",e:"Kozmo Landwalker",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、自分フィールドの「Kozmo」カードが戦闘または相手の効果で破壊される場合、代わりに自分フィールドの「Kozmo」カード１枚を破壊できる。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル５以下の「Kozmo」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－ダークプラネット",h:"こずも－だーくぷらねっと",e:"Kozmo Dark Planet",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\nレベルの合計が１０以上になるように、手札の「Kozmo」モンスターを除外した場合のみ特殊召喚できる。\n①：このカードは相手の効果の対象にならない。\n②：魔法カードが発動した時、自分の墓地から「Kozmo」モンスター１体を除外して発動できる。\nその発動を無効にし破壊する。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル９以下の「Kozmo」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"Kozmo－エピローグ",h:"こずも－えぴろーぐ",e:"Kozmourning",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分の「Kozmo」モンスターが戦闘で破壊したモンスターは墓地へ送らず持ち主のデッキに戻す。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分の「Kozmo」モンスターの戦闘で自分が戦闘ダメージを受ける場合に１度だけ、代わりにその数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"雷撃壊獣サンダー・ザ・キング",h:"らいげきかいじゅうさんだー・ざ・きんぐ",e:"Thunder King, the Lightningstrike Kaiju",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:3300,de:2100,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに攻撃表示で特殊召喚できる。\n②：相手フィールドに「壊獣」モンスターが存在する場合、このカードは手札から攻撃表示で特殊召喚できる。\n③：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n④：１ターンに１度、自分・相手フィールドの壊獣カウンターを３つ取り除いて発動できる。\nこのターン、相手は魔法・罠・モンスターの効果を発動できず、このカードは１度のバトルフェイズ中に３回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"対壊獣用決戦兵器スーパーメカドゴラン",h:"たいかいじゅうようけっせんへいきすーぱーめかどごらん",e:"Super Anti-Kaiju War Machine Mecha-Dogoran",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:2000,tx:"このカードは通常召喚できない。\n相手フィールドに「壊獣」モンスターが存在する場合に特殊召喚できる。\n①：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、自分・相手フィールドの壊獣カウンターを２つ取り除いて発動できる。\n自分の手札・墓地から「壊獣」モンスター１体を選んで装備カード扱いとしてこのカードに装備する。\n③：このカードの攻撃力は、このカードの効果で装備した「壊獣」モンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"壊獣の出現記録",h:"かいじゅうのしゅつげんきろく",e:"The Kaiju Files",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：手札・墓地から「壊獣」モンスターが特殊召喚される度にこのカードに壊獣カウンターを１つ置く（最大５つまで）。\n②：１ターンに１度、フィールドの「壊獣」モンスター１体を対象として発動できる。\nそのモンスターを破壊し、その後、そのコントローラーのフィールドに、破壊したそのモンスターと元々のカード名が異なる「壊獣」モンスター１体を自分のデッキから特殊召喚する。\n③：壊獣カウンターが３つ以上のこのカードを墓地へ送って発動できる。\nデッキから「壊獣の出現記録」以外の「壊獣」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マイルド・ターキー",h:"まいるど・たーきー",e:"Mild Turkey",t:"monster",a:"炎",l:4,ps:7,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\nターン終了時まで、このカードのPスケールを出た目の数だけ下げる（最小１まで）。",tr:"鳥獣族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1000,de:2000,tx:"ボウリングへの情熱に身を焦がすワイルドな七面鳥。\nストライクを取るべく鍛え上げられた体は、常に極上の香りを放つ。\nまだ見ぬターキーを目指し、日々の練習を欠かさない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴースト・ビーフ",h:"ごーすと・びーふ",e:"Ghost Beef",t:"monster",a:"闇",l:7,ps:4,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\nターン終了時まで、このカードのPスケールを出た目の数だけ上げる（最大１０まで）。",tr:"獣族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2000,de:1000,tx:"グルメな牛の幽霊。\n特に大好物のロースト・ビーフには目がなく、今日も新たな味との出会いに心を躍らせながら現世を彷徨っている。",li:"",lk:[],ta:[],al:[]},
  {n:"輝神鳥ヴェーヌ",h:"きしんちょうう゛ぇーぬ",e:"Vennu, Bright Bird of Divinity",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「原初の叫喚」により降臨。\n①：１ターンに１度、手札のモンスター１体を相手に見せ、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、対象のモンスターのレベルは、見せたモンスターと同じになる。\n②：１ターンに１度、このカード以外の自分の手札・フィールドのモンスターがリリースされた場合、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"原初の叫喚",h:"げんしょのきょうかん",e:"Primal Cry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「輝神鳥ヴェーヌ」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「輝神鳥ヴェーヌ」を儀式召喚する。\n②：自分エンドフェイズに墓地のこのカードを除外し、このターンにフィールドから墓地へ送られた、自分の墓地の儀式モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"鬼くじ",h:"おにくじ",e:"Onikuji",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分スタンバイフェイズにこの効果を発動する。\n相手はカードの種類（モンスター・魔法・罠）を宣言する。\n自分のデッキの一番上のカードをめくり、めくったカードが宣言した種類のカードだった場合、相手はデッキから１枚ドローする。\n違った場合、相手の手札をランダムに１枚選んで捨てる。\nめくったカードはデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"クリアクリボー",h:"くりあくりぼー",e:"Clear Kuriboh",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：ダメージを与えるモンスターの効果を相手が発動した時、このカードを手札から捨てて発動できる。\nその発動を無効にする。\n②：相手モンスターの直接攻撃宣言時、墓地のこのカードを除外して発動できる。\n自分はデッキから１枚ドローする。\nそのドローしたカードがモンスターだった場合、さらにそのモンスターを特殊召喚できる。\nその後、攻撃対象をそのモンスターに移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMボットアイズ・リザード",h:"えんためいとぼっとあいず・りざーど",e:"Performapal Bot-Eyes Lizard",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに１度だけ、デッキから「オッドアイズ」モンスター１体を墓地へ送って発動できる。\nエンドフェイズまで、このカードは墓地へ送ったモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"EMドラネコ",h:"えんためいとどらねこ",e:"Performapal Gongato",t:"monster",a:"地",l:1,ps:2,pe:"①：１ターンに１度、相手モンスターの直接攻撃宣言時に発動できる。\nその戦闘で発生する自分への戦闘ダメージは０になる。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:100,tx:"①：１ターンに１度、自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMエクストラ・シューター",h:"えんためいとえくすとら・しゅーたー",e:"Performapal Extra Slinger",t:"monster",a:"闇",l:3,ps:6,pe:"「EMエクストラ・シューター」のP効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はP召喚できない。\n①：自分メインフェイズに発動できる。\n自分のエクストラデッキの表側表示のPモンスターの数×３００ダメージを相手に与える。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:1100,tx:"①：１ターンに１度、自分のエクストラデッキからモンスター１体を除外し、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、相手に３００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMバリアバルーンバク",h:"えんためいとばりあばるーんばく",e:"Performapal Inflater Tapir",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"「EMバリアバルーンバク」の②の効果は１ターンに１度しか使用できない。\n①：自分のモンスターが相手モンスターと戦闘を行うダメージ計算時、このカードを手札から捨てて発動できる。\nその戦闘で発生するお互いの戦闘ダメージは０になる。\n②：このカードが墓地に存在する場合、相手モンスターの直接攻撃宣言時に手札から「EM」モンスター１体を捨てて発動できる。\nこのカードを墓地から守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMゴムゴムートン",h:"えんためいとごむごむーとん",e:"Performapal Gumgumouton",t:"monster",a:"地",l:5,ps:1,pe:"①：１ターンに１度、自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその自分のモンスターはその戦闘では破壊されない。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:900,de:2400,tx:"①：１ターンに１度、自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその自分のモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMバブルドッグ",h:"えんためいとばぶるどっぐ",e:"Performapal Bubblebowwow",t:"monster",a:"地",l:6,ps:5,pe:"①：Pモンスター以外の、エクストラデッキから特殊召喚された自分フィールドの表側表示モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2300,de:1000,tx:"①：このカードがエクストラデッキからの特殊召喚に成功した時に発動できる。\nこのターン、エクストラデッキから特殊召喚された自分フィールドのPモンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMラディッシュ・ホース",h:"えんためいとらでぃっしゅ・ほーす",e:"Performapal Radish Horse",t:"monster",a:"地",l:4,ps:3,pe:"①：１ターンに１度、相手フィールドの表側表示モンスター１体と、自分フィールドの「EM」モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力は、その自分の「EM」モンスターの攻撃力分ダウンする。",tr:"植物族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:2000,tx:"①：特殊召喚されたモンスターが相手フィールドに存在し、相手フィールドのモンスターの数が自分フィールドのモンスターの数以上の場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体と自分フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、その相手モンスターの攻撃力はこのカードの攻撃力分ダウンし、その自分のモンスターの攻撃力はこのカードの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMライフ・ソードマン",h:"えんためいとらいふ・そーどまん",e:"Performapal Life Swordsman",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：相手のLPが回復した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は、この時相手が回復した数値と同じになる。\n②：このカードをリリースし、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"曲芸の魔術師",h:"きょくげいのまじゅつし",e:"Acrobatic Magician",t:"monster",a:"闇",l:5,ps:2,pe:"「曲芸の魔術師」のP効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが効果で破壊された時に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:2300,tx:"①：魔法・罠カードの発動が無効になった場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが戦闘で破壊された時に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"DD魔導賢者トーマス",h:"でぃーでぃーまどうけんじゃとーます",e:"D/D Savant Thomas",t:"monster",a:"闇",l:8,ps:6,pe:"「DD魔導賢者トーマス」のP効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のエクストラデッキから表側表示の「DD」Pモンスター１体を手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:2600,tx:"「DD魔導賢者トーマス」のモンスター効果は１ターンに１度しか使用できない。\n①：自分のPゾーンの「DD」カード１枚を対象として発動できる。\nそのカードを破壊し、デッキからレベル８の「DDD」モンスター１体を守備表示で特殊召喚する。\nこのターン、この効果で特殊召喚したモンスターの効果は無効化され、相手が受ける戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"DD魔導賢者ニコラ",h:"でぃーでぃーまどうけんじゃにこら",e:"D/D Savant Nikola",t:"monster",a:"闇",l:6,ps:8,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、手札から「DDD」モンスター１体を捨て、自分フィールドのレベル６以下の「DD」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで２０００アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「DD魔導賢者ニコラ」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがPゾーンで破壊された場合、自分フィールドの「DDD」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、自分のエクストラデッキから表側表示の「DD」Pモンスターを２体まで選んで自分のPゾーンに置く。\nそのカードのP効果はこのターン発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－逆巻のトルネード",h:"ぶらっくふぇざー－さかまきのとるねーど",e:"Blackwing - Tornado the Reverse Wind",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"①：特殊召喚されたモンスターが相手フィールドに存在し、このカードが召喚に成功した時、自分の墓地の「BF」チューナー１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分は「BF」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－朧影のゴウフウ",h:"ぶらっくふぇざー－おぼろかげのごうふう",e:"Blackwing - Gofu the Vague Shadow",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドにモンスターが存在しない場合に特殊召喚できる。\n①：このカードが手札からの特殊召喚に成功した時に発動できる。\n自分フィールドに「朧影トークン」（鳥獣族・闇・星１・攻／守０）２体を特殊召喚する。\nこのトークンはリリースできず、S素材にできない。\n②：このカードとチューナー以外のモンスター１体以上を自分フィールドから除外し、そのレベルの合計と同じレベルを持つ自分の墓地の「BF」Sモンスター１体を対象として発動できる。\nそのモンスターをチューナー扱いとして特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"レッド・ウルフ",h:"れっど・うるふ",e:"Red Warg",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:2200,tx:"①：自分が「リゾネーター」モンスターの召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ガードナー",h:"れっど・がーどなー",e:"Red Gardna",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：自分フィールドに「レッド・デーモン」モンスターが存在し、相手の魔法・罠・モンスターの効果が発動した時、このカードを手札から墓地へ送って発動できる。\nこのターン、自分フィールドのモンスターは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ミラー",h:"れっど・みらー",e:"Red Mirror",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「レッド・ミラー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時、このカードを手札から墓地へ送り、「レッド・ミラー」以外の自分の墓地の悪魔族・炎属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが墓地に存在し、自分がS召喚に成功した場合に発動できる。\n墓地のこのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャン・オブ・ブラック・イリュージョン",h:"まじしゃん・おぶ・ぶらっく・いりゅーじょん",e:"Magician of Dark Illusion",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2100,de:2500,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が相手ターンに魔法・罠カードの効果を発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードはモンスターゾーンに存在する限り、カード名を「ブラック・マジシャン」として扱う。\n③：このカードがフィールドに表側表示で存在する限り１度だけ、自分が魔法・罠カードの効果を発動した場合に自分の墓地の「ブラック・マジシャン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・ローブ",h:"まじしゃんず・ろーぶ",e:"Magician's Robe",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"「マジシャンズ・ローブ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手ターンに手札から魔法・罠カード１枚を捨てて発動できる。\nデッキから「ブラック・マジシャン」１体を特殊召喚する。\n②：このカードが墓地に存在する状態で、相手ターンに自分が魔法・罠カードの効果を発動した場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・ロッド",h:"まじしゃんず・ろっど",e:"Magician's Rod",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n「ブラック・マジシャン」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。\n②：このカードが墓地に存在する状態で、自分が相手ターンに魔法・罠カードの効果を発動した場合、自分フィールドの魔法使い族モンスター１体をリリースして発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜剣士マスターP",h:"しんりゅうけんしますたーぴーす",e:"Master Peace, the True Dracoslayer",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2950,de:2950,tx:"このカードは通常召喚できない。\n自分フィールドの、「竜剣士」モンスターと「竜魔王」モンスターを１体ずつリリースした場合のみ特殊召喚できる。\n①：１ターンに１度、魔法・罠・モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n②：フィールドのこのカードが相手によって破壊された場合に発動できる。\nデッキから「竜剣士」モンスターと「竜魔王」モンスターを１体ずつ特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・スティエレン",h:"めたるふぉーぜ・すてぃえれん",e:"Metalfoes Steelen",t:"monster",a:"炎",l:2,ps:8,pe:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「メタルフォーゼ」魔法・罠カード１枚を選んで自分フィールドにセットする。",tr:"サイキック族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:0,de:2100,tx:"黒鉄の機体に秘められた魂が覚醒する時、鋼鉄は秘金属へと昇華し、人機一体の勇士となる。\nその身に刻まれし魂鋼を燃焼させろ！――錬装融合！！",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・シルバード",h:"めたるふぉーぜ・しるばーど",e:"Metalfoes Silverd",t:"monster",a:"炎",l:3,ps:1,pe:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「メタルフォーゼ」魔法・罠カード１枚を選んで自分フィールドにセットする。",tr:"サイキック族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1700,de:100,tx:"白銀の亜光速ジェットを操る美しき狙撃手。\n常識を超えたスピードで疾走る彼女を捉える事は不可能に近く、光の速さで繰り出される一撃から逃れる術は無い。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・ゴルドライバー",h:"めたるふぉーぜ・ごるどらいばー",e:"Metalfoes Goldriver",t:"monster",a:"炎",l:4,ps:1,pe:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「メタルフォーゼ」魔法・罠カード１枚を選んで自分フィールドにセットする。",tr:"サイキック族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1900,de:500,tx:"黄金のボディを煌めかせ、豪快なドリフト走法で敵をなぎ倒す。\nしばしば派手にスピンをやらかすが、本人はそれが必殺技だというスタンスを崩さない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・ヴォルフレイム",h:"めたるふぉーぜ・う゛ぉるふれいむ",e:"Metalfoes Volflame",t:"monster",a:"炎",l:7,ps:8,pe:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「メタルフォーゼ」魔法・罠カード１枚を選んで自分フィールドにセットする。",tr:"サイキック族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2400,de:2000,tx:"赤熱の魂鋼を持つ上級戦士。\n世界を終末へと誘う赤き真竜の脅威と対峙した時、呼応するかのように次元を超えて現われた光の意志に導かれ、鍛えし鋼を身にまとう術を開花させた。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜皇アグニマズドV",h:"しんりゅうおうあぐにまずどばにっしゃー",e:"True King Agnimazud, the Vanisher",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2900,de:1900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカード以外の手札及び自分フィールドの表側表示モンスターの中から、炎属性モンスターを含むモンスター２体を選んで破壊し、このカードを手札から特殊召喚し、炎属性モンスター２体を破壊した場合、相手のフィールド・墓地からモンスター１体を選んで除外できる。\n②：このカードが効果で破壊された場合に発動できる。\n自分の墓地から炎属性以外の幻竜族モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・アンキロス",h:"だいなみすと・あんきろす",e:"Dinomist Ankylos",t:"monster",a:"水",l:4,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:2000,tx:"①：このカードがモンスターゾーンに存在する限り、自分の「ダイナミスト」モンスターが戦闘で破壊したモンスターは除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"トラミッド・ダンサー",h:"とらみっど・だんさー",e:"Triamid Dancer",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:1900,tx:"①：１ターンに１度、自分の墓地の「トラミッド」カード１枚を対象として発動できる。\nそのカードをデッキに戻し、自分フィールドの岩石族モンスターの攻撃力・守備力は５００アップする。\n②：相手ターンに１度、自分フィールドの「トラミッド」フィールド魔法カード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキからそのカードとカード名が異なる「トラミッド」フィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"トラミッド・ハンター",h:"とらみっど・はんたー",e:"Triamid Hunter",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"①：フィールド魔法カードが表側表示で存在する場合、自分は通常召喚に加えて１度だけ、自分メインフェイズに岩石族モンスター１体を召喚できる。\n②：相手ターンに１度、自分フィールドの「トラミッド」フィールド魔法カード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキからそのカードとカード名が異なる「トラミッド」フィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"トラミッド・マスター",h:"とらみっど・ますたー",e:"Triamid Master",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"①：１ターンに１度、自分フィールドの表側表示の「トラミッド」カード１枚を墓地へ送り、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：相手ターンに１度、自分フィールドの「トラミッド」フィールド魔法カード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキからそのカードとカード名が異なる「トラミッド」フィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"トラミッド・スフィンクス",h:"とらみっど・すふぃんくす",e:"Triamid Sphinx",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できず、「トラミッド」カードの効果でのみ特殊召喚できる。\n①：「トラミッド・スフィンクス」以外の自分フィールドの表側表示の「トラミッド」カードが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに他の「トラミッド」カードが存在する場合、このカードの攻撃力・守備力は自分の墓地のフィールド魔法カードの種類×５００アップし、相手モンスターは「トラミッド・スフィンクス」にしか攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火の隠者",h:"しらぬいのかげもの",e:"Shiranui Solitaire",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのアンデット族モンスター１体をリリースして発動できる。\nデッキから守備力０のアンデット族チューナー１体を特殊召喚する。\n②：このカードが除外された場合、「不知火の隠者」以外の除外されている自分の「不知火」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動時にフィールドに「不知火流 転生の陣」が存在する場合、この効果の対象を２体にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ブラック・マジシャン",h:"とぅーん・ぶらっく・まじしゃん",e:"Toon Dark Magician",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["トゥーン","効果"],ma:"",tp:"",at:2500,de:2100,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：１ターンに１度、手札から「トゥーン」カード１枚を捨て、以下の効果から１つを選択して発動できる。\n\n●デッキから「トゥーン・ブラック・マジシャン」以外のトゥーンモンスター１体を召喚条件を無視して特殊召喚する。\n\n●デッキから「トゥーン」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スケープ・ゴースト",h:"すけーぷ・ごーすと",e:"Scapeghost",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["リバース","チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードがリバースした場合に発動できる。\n自分フィールドに「黒羊トークン」（アンデット族・闇・星１・攻／守０）を任意の数だけ特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブロックドラゴン",h:"ぶろっくどらごん",e:"Block Dragon",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:3000,tx:"このカードは通常召喚できない。\n自分の手札・墓地の地属性モンスター３体を除外した場合のみ手札・墓地から特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分フィールドの岩石族モンスターは戦闘以外では破壊されない。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nレベルの合計が８になるように、デッキから岩石族モンスターを３体まで選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天照大神",h:"あまてらす",e:"Amaterasu",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは召喚・特殊召喚できない。\n①：裏側表示のこのモンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードを表側守備表示にして発動できる。\n自分はデッキから１枚ドローする。\n②：このカードがリバースした場合に発動する。\nこのカード以外のフィールドのカードを全て除外する。\n③：このカードがリバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"黒竜の忍者",h:"こくりゅうのにんじゃ",e:"Black Dragon Ninja",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2800,de:1600,tx:"このカードは「忍者」モンスターまたは「忍法」カードの効果でしか特殊召喚できない。\n①：１ターンに１度、手札及び自分フィールドの表側表示のカードの中から、「忍者」モンスター１体と「忍法」カード１枚を墓地へ送り、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果は相手ターンでも発動できる。\n②：表側表示のこのカードがフィールドから離れた場合に発動する。\nこのカードの効果で除外されているモンスターを可能な限り元々の持ち主のフィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジック・ストライダー",h:"まじっく・すとらいだー",e:"Spell Strider",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードが手札に存在する場合、自分及び相手フィールドの表側表示の魔法カードを１枚ずつ対象として発動できる。\nそのカードを除外し、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"放電ムスタンガン",h:"ほうでんむすたんがん",e:"Zap Mustung",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカードは通常召喚できず、自分が特殊召喚を行っていない自分メインフェイズ１に、カードの効果でのみ特殊召喚できる。\n①：このカードは１ターンに２度まで戦闘では破壊されない。\n②：このカードがモンスターゾーンに存在する限り、ターンプレイヤーはそのターン攻撃した回数と同じ回数までしかモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トーテム・ファイブ",h:"とーてむ・ふぁいぶ",e:"Totem Five",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"①：このカードを含む５体のモンスターが同時に特殊召喚に成功した場合に発動する。\n相手フィールドのカードを全て破壊し、破壊した数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"チューニングガム",h:"ちゅーにんぐがむ",e:"Tuning Gum",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1200,tx:"「チューニングガム」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターをチューナーとして扱う。\nこの効果を発動するターン、自分はSモンスターでしか攻撃できない。\n②：自分フィールドのSモンスター１体のみを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"レッカーパンダ",h:"れっかーぱんだ",e:"Wrecker Panda",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：自分・相手のスタンバイフェイズに５００LPを払って発動できる。\n自分のデッキの一番上のカードを墓地へ送り、そのカードがモンスターだった場合、このカードの攻撃力・守備力はそのモンスターのレベル×２００アップする。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\n自分の墓地からレベルが一番低いモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精伝姫－シラユキ",h:"ふぇありーている－しらゆき",e:"Fairy Tail - Snow",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1000,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\n②：このカードが墓地に存在する場合、自分の手札・フィールド・墓地からこのカード以外のカード７枚を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・アダマンテ",h:"めたるふぉーぜ・あだまんて",e:"Metalfoes Adamante",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"サイキック族",mo:["融合"],ma:"",tp:"",at:2500,de:2500,tx:"「メタルフォーゼ」モンスター＋攻撃力２５００以下のモンスター",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・オリハルク",h:"めたるふぉーぜ・おりはるく",e:"Metalfoes Orichalc",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2200,tx:"「メタルフォーゼ」モンスター×２\n①：自分の「メタルフォーゼ」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分の倍の数値だけ相手に戦闘ダメージを与える。\n②：このカードがフィールドから墓地へ送られた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・カーディナル",h:"めたるふぉーぜ・かーでぃなる",e:"Metalfoes Crimsonite",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"サイキック族",mo:["融合"],ma:"",tp:"",at:3000,de:3000,tx:"「メタルフォーゼ」モンスター＋攻撃力３０００以下のモンスター×２",li:"",lk:[],ta:[],al:[]},
  {n:"涅槃の超魔導剣士",h:"にるう゛ぁーな・はい・ぱらでぃん",e:"Nirvana High Paladin",t:"monster",a:"闇",l:10,ps:8,pe:"①：自分のPモンスターが攻撃する場合、そのモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。\n②：自分のPモンスターが攻撃したダメージステップ終了時に発動する。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで、攻撃したそのモンスターの攻撃力分ダウンする。",tr:"魔法使い族",mo:["シンクロ","ペンデュラム","効果"],ma:"",tp:"",at:3300,de:2500,tx:"チューナー＋チューナー以外のSモンスター１体以上\nこのカードをS召喚する場合、自分フィールドのP召喚したPモンスター１体をチューナーとして扱う事ができる。\n①：このカードがP召喚したPモンスターをチューナーとしてS召喚に成功した場合、自分の墓地のカード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n相手のLPを半分にする。\n③：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"A BF－雨隠れのサヨ",h:"あさると　ぶらっくふぇざー－あまがくれのさよ",e:"Assault Blackwing - Sayo the Rain Hider",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:800,de:100,tx:"チューナー＋チューナー以外のモンスター１体\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：このカードは１ターンに２度まで戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"A BF－五月雨のソハヤ",h:"あさると　ぶらっくふぇざー－さみだれのそはや",e:"Assault Blackwing - Sohaya the Rain Storm",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:1500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「A BF－五月雨のソハヤ」の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：このカードがS召喚に成功した時、自分の墓地の「A BF」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n③：このカードが墓地へ送られたターンの自分メインフェイズにこのカード以外の自分の墓地の「A BF－五月雨のソハヤ」１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"A BF－神立のオニマル",h:"あさると　ぶらっくふぇざー－がんだちのおにまる",e:"Assault Blackwing - Onimaru the Divine Thunder",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「A BF－神立のオニマル」の③の効果はデュエル中に１度しか使用できない。\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：このカードは効果では破壊されない。\n③：自分の墓地の「BF」モンスター１体を対象として発動できる。\nこのカードのレベルはそのモンスターと同じになる。\n④：Sモンスターのみを素材としてS召喚したこのカードが攻撃する場合、ダメージステップの間このカードの攻撃力は３０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・デーモンズ・ドラゴン・タイラント",h:"れっど・でーもんず・どらごん・たいらんと",e:"Tyrant Red Dragon Archfiend",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:3000,tx:"チューナー２体＋チューナー以外のモンスター１体以上\nこのカードはS召喚でしか特殊召喚できない。\n「レッド・デーモンズ・ドラゴン・タイラント」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズ１に発動できる。\nこのカード以外のフィールドのカードを全て破壊する。\nこのターン、このカード以外の自分のモンスターは攻撃できない。\n②：バトルフェイズに魔法・罠カードが発動した時に発動できる。\nその発動を無効にして破壊し、このカードの攻撃力を５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"瑚之龍",h:"こーらる・どらごん",e:"Coral Dragon",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2400,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、手札を１枚捨て、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：S召喚したこのカードが墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"虚空の黒魔導師",h:"こくうのくろまどうし",e:"Ebon High Magician",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:2800,tx:"魔法使い族レベル７モンスター×２\n①：X素材を持ったこのカードがモンスターゾーンに存在する限り、自分は相手ターンに速攻魔法カード及び罠カードを手札から発動できる。\nその発動の際にこのカードのX素材を１つ取り除く。\n②：X召喚したこのカードが、相手の効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合に発動できる。\n手札・デッキから魔法使い族・闇属性モンスター１体を特殊召喚する。\nその後、フィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超カバーカーニバル",h:"ちょうかばーかーにばる",e:"Super Hippo Carnival",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の手札・デッキ・墓地から「EMディスカバー・ヒッポ」１体を選んで特殊召喚する。\nその後、自分フィールドに「カバートークン」（獣族・地・星１・攻／守０）を可能な限り特殊召喚できる。\nこのトークンはリリースできない。\n「カバートークン」がモンスターゾーンに存在する限り、自分はエクストラデッキからモンスターを特殊召喚できない。\nこの効果で「カバートークン」を特殊召喚した場合、ターン終了時まで相手は「カバートークン」以外のモンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"月光香",h:"げっこうが",e:"Luna Light Perfume",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードを除外し、手札を１枚捨てて発動できる。\nデッキから「ムーンライト」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・サンクチュアリ",h:"ですとーい・さんくちゅあり",e:"Frightfur Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"手札を１枚捨て、自分のエクストラデッキから「デストーイ」モンスター２体を墓地へ送ってこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの全ての融合モンスターは「デストーイ」モンスターとしても扱う。\n②：このカードが墓地へ送られた場合、自分の墓地の「デストーイ」融合モンスター１体を対象として発動できる。\nそのモンスターをエクストラデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神王の禁断契約書",h:"ましんおうのきんだんけいやくしょ",e:"Forbidden Dark Contract with the Swamp King",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「DDD」モンスター１体を効果を無効にして守備表示で特殊召喚する。\n②：１ターンに１度、自分メインフェイズに発動できる。\n悪魔族の融合モンスターカードによって決められた、このカードの①の効果で特殊召喚したモンスターを含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n③：自分スタンバイフェイズに発動する。\n自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"黒の魔導陣",h:"くろのまどうじん",e:"Dark Magical Circle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、自分のデッキの上からカードを３枚確認する。\nその中に、「ブラック・マジシャン」のカード名が記された魔法・罠カードまたは「ブラック・マジシャン」があった場合、その内の１枚を相手に見せて手札に加える事ができる。\n残りのカードは好きな順番でデッキの上に戻す。\n②：自分フィールドに「ブラック・マジシャン」が召喚・特殊召喚された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"イリュージョン・マジック",h:"いりゅーじょん・まじっく",e:"Illusion Magic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの魔法使い族モンスター１体をリリースして発動できる。\n自分のデッキ・墓地から「ブラック・マジシャン」を２体まで選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔導強化",h:"まじっく・えくすぱんど",e:"Dark Magic Expanded",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：お互いのフィールド・墓地の「ブラック・マジシャン」「ブラック・マジシャン・ガール」の数によって以下の効果を適用する。\n●１体以上：フィールドの魔法使い族・闇属性モンスター１体を選び、その攻撃力をターン終了時まで１０００アップする。\n●２体以上：このターン、自分の魔法・罠カードの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できず、自分フィールドの魔法・罠カードは相手の効果では破壊されない。\n●３体以上：自分フィールドの魔法使い族・闇属性モンスターはターン終了時まで相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタモルF",h:"めたもるふぉーめーしょん",e:"Metamorformation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「メタルフォーゼ」モンスターの攻撃力・守備力は３００アップする。\n②：自分のPゾーンに「メタルフォーゼ」カードが存在する限り、効果モンスター以外の自分フィールドの「メタルフォーゼ」モンスターは相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"錬装融合",h:"めたるふぉーぜ・ふゅーじょん",e:"Metalfoes Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、「メタルフォーゼ」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードが墓地に存在する場合に発動できる。\n墓地のこのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トラミッド・フォートレス",h:"とらみっど・ふぉーとれす",e:"Triamid Fortress",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「トラミッド・フォートレス」の③の効果は１ターンに１度しか使用できない。\n①：フィールドの岩石族モンスターの守備力は５００アップする。\n②：フィールドの「トラミッド」モンスターは効果では破壊されない。\n③：フィールドゾーンの表側表示のこのカードが墓地へ送られた場合、自分の墓地の「トラミッド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トラミッド・クルーザー",h:"とらみっど・くるーざー",e:"Triamid Cruiser",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「トラミッド・クルーザー」の③の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、岩石族モンスターが召喚される度に、自分は５００LP回復する。\n②：フィールドに「トラミッド」モンスターが召喚された場合に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。\n③：フィールドゾーンの表側表示のこのカードが墓地へ送られた場合に発動できる。\nデッキから「トラミッド」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トラミッド・キングゴレム",h:"とらみっど・きんぐごれむ",e:"Triamid Kingolem",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「トラミッド・キングゴレム」の③の効果は１ターンに１度しか使用できない。\n①：フィールドの岩石族モンスターの攻撃力は５００アップする。\n②：自分の「トラミッド」モンスターが戦闘を行う場合、ダメージステップ終了時まで相手は魔法・罠・モンスターの効果を発動できない。\n③：フィールドゾーンの表側表示のこのカードが墓地へ送られた場合に発動できる。\n手札から「トラミッド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コズミック・サイクロン",h:"こずみっく・さいくろん",e:"Cosmic Cyclone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：１０００LPを払い、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲で貪欲な壺",h:"ごうよくでどんよくなつぼ",e:"Pot of Desires",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキの上からカード１０枚を裏側表示で除外して発動できる。\n自分はデッキから２枚ドローする。",li:2,lk:[],ta:[],al:[]},
  {n:"半魔導帯域",h:"はんまどうたいいき",e:"Magical Mid-Breaker Field",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分のメインフェイズ１・メインフェイズ２の開始時にこのカードを発動できる。\n①：お互いのメインフェイズ１の間、フィールドのモンスターは、それぞれの相手の効果の対象にならず、それぞれの相手の効果では破壊されない。\n②：このカードがフィールドゾーンに存在する限り、自分はフィールド魔法カードを発動・セットできない。",li:"",lk:[],ta:[],al:[]},
  {n:"魂のカード",h:"たましいのかーど",e:"Card of the Soul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「魂のカード」は１ターンに１枚しか発動できない。\n①：自分のデッキを確認する。\nその中から攻撃力・守備力の合計が自分のLPと同じ数値となるモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"融合死円舞曲",h:"ふゅーじょん・です・わるつ",e:"Fusion Fright Waltz",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「デストーイ」融合モンスター１体と、相手フィールドの融合モンスター１体を対象として発動できる。\n対象のモンスター以外の、フィールドの特殊召喚されたモンスターを全て破壊する。\nその後、この効果でモンスターを破壊されたプレイヤーは、対象のモンスターの攻撃力の合計分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"キング・スカーレット",h:"きんぐ・すかーれっと",e:"King Scarlet",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の「レッド・デーモン」モンスターが戦闘を行うダメージ計算時にこのカードを発動できる。\nその自分のモンスターはその戦闘では破壊されず、このカードは通常モンスター（悪魔族・チューナー・炎・星１・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・ナビゲート",h:"まじしゃんず・なびげーと",e:"Magician Navigation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から「ブラック・マジシャン」１体を特殊召喚する。\nその後、デッキからレベル７以下の魔法使い族・闇属性モンスター１体を特殊召喚する。\n②：自分フィールドに「ブラック・マジシャン」が存在する場合、墓地のこのカードを除外し、相手フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・カウンター",h:"めたるふぉーぜ・かうんたー",e:"Metalfoes Counter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「メタルフォーゼ」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n自分のEXデッキから表側表示の「メタルフォーゼ」Pモンスター１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・コンビネーション",h:"めたるふぉーぜ・こんびねーしょん",e:"Metalfoes Combination",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、融合モンスターが融合召喚された場合、その融合モンスターよりレベルが低い、自分の墓地の「メタルフォーゼ」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを特殊召喚する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「メタルフォーゼ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"古代遺跡の目覚め",h:"とらみっど・ぱるす",e:"Triamid Pulse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分の墓地から、岩石族モンスターまたはフィールド魔法カードを合計２枚除外し、以下の効果から１つを選択して発動できる。\n\n●このカード以外のフィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\n\n●自分の墓地の岩石族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n\n●自分の墓地のフィールド魔法カードを３枚まで対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣の追憶",h:"はかいけんのついおく",e:"Destruction Sword Memories",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から「破壊剣」カード１枚を捨てて発動できる。\nデッキから「バスター・ブレイダー」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n「竜破壊の剣士－バスター・ブレイダー」によって決められた融合素材モンスターを自分の墓地から除外し、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"底なし落とし穴",h:"そこなしおとしあな",e:"Floodgate Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターの召喚・反転召喚・特殊召喚に成功した時に発動できる。\nそのモンスターを裏側守備表示にする。\nこの効果で裏側守備表示になったモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"早すぎた帰還",h:"はやすぎたきかん",e:"Premature Return",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚除外し、除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"共闘",h:"きょうとう",e:"Unified Front",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動するターン、自分のモンスターは直接攻撃できない。\n①：手札からモンスター１体を捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、このカードを発動するために捨てたモンスターのそれぞれの数値と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・ホール",h:"ぺんでゅらむ・ほーる",e:"Pendulum Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分または相手がモンスターをP召喚する際に発動できる。\nそのP召喚を無効にし、そのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"検問",h:"けんもん",e:"The Forceful Checkpoint",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手の手札を全て確認し、その中にモンスターカードがあった場合、その攻撃を無効にする。\nその後、自分は相手の手札からモンスター１体を選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ペルソナ・ドラゴン",h:"おっどあいず・ぺるそな・どらごん",e:"Odd-Eyes Persona Dragon",t:"monster",a:"闇",l:5,ps:1,pe:"①：自分フィールドの「オッドアイズ」Pモンスター１体を対象とした相手の効果が発動した場合、そのターンのエンドフェイズに発動する。\nPゾーンのこのカードを特殊召喚し、自分のエクストラデッキから「オッドアイズ・ペルソナ・ドラゴン」以外の表側表示の「オッドアイズ」Pモンスター１体を選び、自分のPゾーンに置く。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:2400,tx:"①：１ターンに１度、エクストラデッキから特殊召喚された表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ミラージュ・ドラゴン",h:"おっどあいず・みらーじゅ・どらごん",e:"Odd-Eyes Mirage Dragon",t:"monster",a:"闇",l:3,ps:8,pe:"①：１ターンに１度、自分フィールドの表側表示の「オッドアイズ」Pモンスターが戦闘・効果で破壊された場合に発動できる。\n自分のPゾーンのカード１枚を選んで破壊し、自分のエクストラデッキから「オッドアイズ・ミラージュ・ドラゴン」以外の表側表示の「オッドアイズ」Pモンスター１体を選び、自分のPゾーンに置く。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:600,tx:"「オッドアイズ・ミラージュ・ドラゴン」のモンスター効果は１ターンに１度しか使用できない。\n①：自分のPゾーンに「オッドアイズ」カードが存在する場合、自分フィールドの「オッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターはこのターンに１度だけ戦闘・効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ファントム・ドラゴン",h:"おっどあいず・ふぁんとむ・どらごん",e:"Odd-Eyes Phantom Dragon",t:"monster",a:"闇",l:7,ps:4,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「オッドアイズ」カードが存在する場合、自分の表側表示モンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその自分のモンスターの攻撃力はバトルフェイズ終了時まで１２００アップする。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「オッドアイズ・ファントム・ドラゴン」のモンスター効果は１ターンに１度しか使用できない。\n①：P召喚したこのカードの攻撃で相手に戦闘ダメージを与えた時に発動できる。\n自分のPゾーンの「オッドアイズ」カードの数×１２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"流星方界器デューザ",h:"りゅうせいほうかいきでゅーざ",e:"Duza the Meteor Cubic Vessel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「方界」カード１枚を墓地へ送る。\n②：１ターンに１度、このカードが表側表示で存在する状態で、モンスターが自分の墓地へ送られたターンに発動できる。\nこのカードの攻撃力はターン終了時まで、自分の墓地のモンスターの種類×２００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"真青眼の究極竜",h:"ねお・ぶるーあいず・あるてぃめっとどらごん",e:"Neo Blue-Eyes Ultimate Dragon",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:3800,tx:"「青眼の白龍」＋「青眼の白龍」＋「青眼の白龍」\nこのカード名の①の効果は１ターンに２度まで使用できる。\n①：融合召喚したこのカードが攻撃したダメージステップ終了時、自分フィールドの表側表示のカードがこのカードのみの場合、EXデッキから「ブルーアイズ」融合モンスター１体を墓地へ送って発動できる。\nこのカードは続けて攻撃できる。\n②：自分フィールドの「ブルーアイズ」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザー・ブラッド・ヴォルス",h:"かいざー・ぶらっど・う゛ぉるす",e:"Kaiser Vorse Raider",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが戦闘で相手モンスターを破壊した場合に発動する。\nこのカードの攻撃力は５００アップする。\n③：このカードが戦闘で破壊された場合に発動する。\nこのカードを破壊したモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"アサルトワイバーン",h:"あさるとわいばーん",e:"Assault Wyvern",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊した時、このカードをリリースして発動できる。\n自分の手札・墓地から「アサルトワイバーン」以外のドラゴン族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーアイズ・カオス・MAX・ドラゴン",h:"ぶるーあいず・かおす・まっくす・どらごん",e:"Blue-Eyes Chaos MAX Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:4000,de:0,tx:"「カオス・フォーム」により降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n①：このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分の倍の数値だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディープアイズ・ホワイト・ドラゴン",h:"でぃーぷあいず・ほわいと・どらごん",e:"Deep-Eyes White Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分フィールドの表側表示の「ブルーアイズ」モンスターが戦闘または相手の効果で破壊された時に発動できる。\nこのカードを手札から特殊召喚し、自分の墓地のドラゴン族モンスターの種類×６００ダメージを相手に与える。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地のドラゴン族モンスター１体を対象として発動する。\nこのカードの攻撃力はそのモンスターの攻撃力と同じになる。\n③：フィールドのこのカードが効果で破壊された場合に発動する。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"パンデミック・ドラゴン",h:"ぱんでみっく・どらごん",e:"Pandemic Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"①：１ターンに１度、１００の倍数のLPを払って発動できる。\nこのカード以外のフィールドの表側表示モンスターの攻撃力は、この効果を発動するために払ったLPの数値分ダウンする。\n②：１ターンに１度、このカードの攻撃力以下の攻撃力を持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：このカードが戦闘・効果で破壊された場合に発動する。\nフィールドの全ての表側表示モンスターの攻撃力は１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜の闘志",h:"りゅうのとうし",e:"Dragon's Fighting Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターンに特殊召喚された自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nこのターン、そのモンスターは通常の攻撃に加えて、相手フィールドのこのターンに特殊召喚されたモンスターの数まで、１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・フォーム",h:"かおす・ふぉーむ",e:"Chaos Form",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地から「青眼の白龍」または「ブラック・マジシャン」を除外し、手札から「カオス」儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"融爆",h:"ゆうばく",e:"Induced Explosion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのカードが魔法カードの効果で破壊された時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分フィールドのカードが魔法カードの効果で破壊された時、墓地のこのカードを除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"カウンター・ゲート",h:"かうんたー・げーと",e:"Counter Gate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nその攻撃を無効にし、自分はデッキから１枚ドローする。\nそのドローしたカードがモンスターだった場合、そのモンスターを表側攻撃表示で通常召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタル・アバター",h:"くりすたる・あばたー",e:"Krystal Avatar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時、そのモンスターの攻撃力が自分のLP以上の場合に発動できる。\nこのカードは発動後、自分のLPの数値と同じ攻撃力の効果モンスター（戦士族・光・星４・攻？／守０）となり、モンスターゾーンに攻撃表示で特殊召喚する。\nその後、攻撃対象をこのカードに移し替える。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚したこのカードが戦闘で破壊されたダメージ計算後に発動する。\nこのカードの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"岩石の番兵",h:"がんせきのばんぺい",e:"Sentry Soldier of Stone",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドのモンスターが岩石族モンスターのみの場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシュマカロン",h:"ましゅまかろん",e:"Marshmacaron",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘・効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地からこのカード以外の「マシュマカロン」を２体まで選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ベリー・マジシャン・ガール",h:"べりー・まじしゃん・がーる",e:"Berry Magician Girl",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"①：このカードが召喚に成功した場合に発動できる。\nデッキから「マジシャン・ガール」モンスター１体を手札に加える。\n②：１ターンに１度、このカードが相手の効果の対象になった時、または相手モンスターの攻撃対象に選択された時に発動できる。\nこのカードの表示形式を変更し、デッキから「ベリー・マジシャン・ガール」以外の「マジシャン・ガール」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アップル・マジシャン・ガール",h:"あっぷる・まじしゃん・がーる",e:"Apple Magician Girl",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：１ターンに１度、このカードが攻撃対象に選択された場合に発動できる。\n手札からレベル５以下の魔法使い族モンスター１体を特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替え、攻撃モンスターの攻撃力を半分にする。\n②：このカードが戦闘・効果で破壊された場合、このカード以外の自分の墓地の「マジシャン・ガール」モンスターを３体まで対象として発動できる（同名カードは１枚まで）。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"キウイ・マジシャン・ガール",h:"きうい・まじしゃん・がーる",e:"Kiwi Magician Girl",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードを手札から捨てて発動できる。\n自分フィールドの「マジシャン・ガール」モンスターの攻撃力・守備力はターン終了時まで、お互いのフィールド・墓地の「マジシャン・ガール」モンスターの種類×３００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの魔法使い族モンスターは効果では破壊されず、相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"シルバー・ガジェット",h:"しるばー・がじぇっと",e:"Silver Gadget",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から機械族・レベル４モンスター１体を特殊召喚する。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「シルバー・ガジェット」以外のレベル４の「ガジェット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴールド・ガジェット",h:"ごーるど・がじぇっと",e:"Gold Gadget",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から機械族・レベル４モンスター１体を特殊召喚する。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「ゴールド・ガジェット」以外のレベル４の「ガジェット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔術のヴェール",h:"くろまじゅつのう゛ぇーる",e:"Dark Magic Veil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：１０００LPを払って発動できる。\n自分の手札・墓地から魔法使い族・闇属性モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導契約の扉",h:"まどうけいやくのとびら",e:"Magical Contract Door",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札から魔法カード１枚を選んで相手の手札に加える。\nその後、自分のデッキからレベル７・８の闇属性モンスター１体を自分の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・リフレクター",h:"でぃめんしょん・りふれくたー",e:"Dimension Reflector",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドのモンスター２体を除外し、相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードは発動後、対象のモンスターの攻撃力と同じ数値の攻撃力・守備力を持つ効果モンスター（魔法使い族・闇・星４・攻／守？）となり、モンスターゾーンに攻撃表示で特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果でこのカードが特殊召喚に成功した場合に発動する。\nこのカードの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"運命の発掘",h:"うんめいのはっくつ",e:"Dig of Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分が戦闘ダメージを受けた時に発動できる。\n自分はデッキから１枚ドローする。\n②：フィールドのこのカードが相手の効果で破壊された場合に発動できる。\n自分の墓地の「運命の発掘」の枚数分だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・スフィンクス",h:"でぃめんしょん・すふぃんくす",e:"Dimension Sphinx",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールドの表側攻撃表示モンスター１体を対象としてこのカードを発動できる。\n①：対象のモンスターが、そのモンスターより攻撃力が高い相手モンスターに攻撃されたバトルステップ中に１度、この効果を発動できる。\nその攻撃モンスターと対象のモンスターの攻撃力の差分のダメージを相手に与える。\n②：対象のモンスターがフィールドから離れた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・ガーディアン",h:"でぃめんしょん・がーでぃあん",e:"Dimension Guardian",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールドの表側攻撃表示モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、そのモンスターは戦闘・効果では破壊されない。\nそのモンスターがフィールドから離れた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・ミラージュ",h:"でぃめんしょん・みらーじゅ",e:"Dimension Mirage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールドの表側攻撃表示モンスター１体を対象としてこのカードを発動できる。\n①：対象のモンスターの攻撃で攻撃対象モンスターが破壊されなかったダメージステップ終了時、自分の墓地のモンスター１体を除外してこの効果を発動できる。\n対象のモンスターはもう１度攻撃可能になり、続けて攻撃しなければならない。\n②：対象のモンスターがフィールドから離れた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・ホライズン",h:"だーく・ほらいずん",e:"Dark Horizon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分が戦闘・効果でダメージを受けた時に発動できる。\n受けたダメージの数値以下の攻撃力を持つ魔法使い族・闇属性モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタモル・クレイ・フォートレス",h:"めたもる・くれい・ふぉーとれす",e:"Metamorphortress",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドのレベル４以上のモンスター１体を対象として発動できる。\nこのカードは発動後、効果モンスター（岩石族・地・星４・攻／守１０００）となり、モンスターゾーンに特殊召喚する。\nその後、対象の表側表示モンスターを装備カード扱いとしてこのカードに装備する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚されたこのカードの攻撃力・守備力は、このカードの効果で装備したモンスターの攻撃力分アップし、このカードは攻撃したダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・プロテクション",h:"まじしゃんず・ぷろてくしょん",e:"Magicians' Defense",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドに魔法使い族モンスターが存在する限り、自分が受ける全てのダメージは半分になる。\n②：このカードがフィールドから墓地へ送られた場合、自分の墓地の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイナル・ギアス",h:"ふぁいなる・ぎあす",e:"Final Geas",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：元々のレベルが７以上のモンスターが、自分及び相手のフィールドからそれぞれ１体以上、墓地へ送られたターンに発動できる。\nお互いの墓地のモンスターを全て除外する。\nその後、この効果で除外したモンスターの内、レベルが一番高い魔法使い族モンスター１体を自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"機動要塞 メタル・ホールド",h:"きどうようさい　めたる・ほーるど",e:"Metalhold the Moving Blockade",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの機械族・レベル４モンスターを任意の数だけ対象として発動できる。\nこのカードは発動後、効果モンスター（機械族・地・星４・攻／守０）となり、モンスターゾーンに特殊召喚する。\nその後、対象の表側表示モンスターを装備カード扱いとしてこのカードに装備する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚されたこのカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力の合計分アップし、相手は他の自分フィールドのモンスターを攻撃対象にできず、効果の対象にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"光の護封霊剣",h:"ひかりのごふうれいけん",e:"Spiritual Swords of Revealing Light",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に１度、１０００LPを払って発動できる。\nその攻撃を無効にする。\n②：相手ターンに墓地のこのカードを除外して発動できる。\nこのターン、相手モンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"方界胤ヴィジャム",h:"ほうかいいんう゛ぃじゃむ",e:"Vijam the Cubic Seed",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に発動できる。\nモンスターゾーンのこのカードを永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置き、その相手モンスターに方界カウンターを１つ置く。\n方界カウンターが置かれたモンスターは攻撃できず、効果は無効化される。\n③：このカードの効果でこのカードが永続魔法カード扱いになっている場合、自分メインフェイズに発動できる。\n魔法＆罠ゾーンのこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"方界獣ダーク・ガネックス",h:"ほうかいじゅうだーく・がねっくす",e:"Dark Garnex the Cubic Beast",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター１体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は１０００アップする。\n②：このカードが戦闘でモンスターを破壊した時、自分の墓地の「方界胤ヴィジャム」を２体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界獣ブレード・ガルーディア」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"方界獣ブレード・ガルーディア",h:"ほうかいじゅうぶれーど・がるーでぃあ",e:"Blade Garoodia the Cubic Beast",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター２体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は２０００アップする。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：このカードが戦闘でモンスターを破壊した時、自分の墓地の「方界胤ヴィジャム」を３体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界超獣バスター・ガンダイル」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"方界超獣バスター・ガンダイル",h:"ほうかいちょうじゅうばすたー・がんだいる",e:"Buster Gundil the Cubic Behemoth",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター３体を墓地へ送った場合のみ特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は３０００アップする。\n②：このカードは１度のバトルフェイズ中に３回攻撃できる。\n③：このカードが相手によって墓地へ送られた場合、自分の墓地の「方界」モンスターを３体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、自分のデッキ・墓地から「方界」カード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"方界帝ゲイラ・ガイル",h:"ほうかいていげいら・がいる",e:"Geira Guile the Cubic King",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター１体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は８００アップする。\n②：このカードが手札からの特殊召喚に成功した場合に発動する。\n相手に８００ダメージを与える。\n③：このカードが戦闘したダメージステップ終了時、自分の墓地の「方界胤ヴィジャム」を２体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界帝ヴァルカン・ドラグニー」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"方界帝ヴァルカン・ドラグニー",h:"ほうかいていう゛ぁるかん・どらぐにー",e:"Vulcan Dragni the Cubic King",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター２体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は１６００アップする。\n②：このカードが手札からの特殊召喚に成功した場合に発動する。\n相手に８００ダメージを与える。\n③：このカードが戦闘したダメージステップ終了時、自分の墓地の「方界胤ヴィジャム」を３体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界超帝インディオラ・デス・ボルト」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"方界超帝インディオラ・デス・ボルト",h:"ほうかいちょうていいんでぃおら・です・ぼると",e:"Indiora Doom Volt the Cubic Emperor",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター３体を墓地へ送った場合のみ特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は２４００アップする。\n②：このカードが手札からの特殊召喚に成功した場合に発動する。\n相手に８００ダメージを与える。\n③：このカードが相手によって墓地へ送られた場合、自分の墓地の「方界」モンスターを３体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、自分のデッキ・墓地から「方界」カード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒方界神クリムゾン・ノヴァ",h:"あんこくほうかいしんくりむぞん・のう゛ぁ",e:"Crimson Nova the Dark Cubic Lord",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:0,tx:"このカードは通常召喚できない。\nこのカード以外の手札の「方界」カード３種類を相手に見せた場合のみ特殊召喚できる。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは、このカード以外の元々の攻撃力が３０００以下のモンスターが発動した効果を受けない。\n②：このカードの攻撃でモンスターを破壊した時に発動できる。\nこのバトルフェイズ中、このカードはもう１度だけ攻撃できる。\n③：自分エンドフェイズに発動する。\nお互いのプレイヤーは３０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒方界邪神クリムゾン・ノヴァ・トリニティ",h:"あんこくほうかいじゃしんくりむぞん・のう゛ぁ・とりにてぃ",e:"Crimson Nova Trinity the Dark Cubic Lord",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:3000,tx:"「暗黒方界神クリムゾン・ノヴァ」×３\nこのカードは上記のカードを融合素材にした融合召喚でのみ特殊召喚できる。\n①：フィールドのこのカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードの攻撃宣言時に発動する。\n相手のLPを半分にする。\n③：このカードの攻撃でモンスターを破壊した時に発動できる。\nこのバトルフェイズ中、このカードはもう１度だけ攻撃できる。\n④：自分が効果ダメージを受けた場合に発動する。\n受けたダメージの数値分だけ相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"方界業",h:"ほうかいかるま",e:"Cubic Karma",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時に、「方界胤ヴィジャム」以外の自分フィールドの「方界」モンスター１体を対象にできる。\nその場合、手札・デッキから「方界胤ヴィジャム」を任意の枚数墓地へ送る。\nその後、対象のモンスターの攻撃力は、この効果で墓地へ送ったモンスターの数×８００アップする。\n②：相手ターンに「方界」モンスターの効果で「方界胤ヴィジャム」が特殊召喚された場合に発動する。\nこのカードを墓地へ送り、相手のLPを半分にする。\n③：墓地のこのカードを除外して発動できる。\nデッキから「方界」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"方界波動",h:"ほうかいはどう",e:"Cubic Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「方界」モンスター１体と相手フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターの攻撃力を倍にし、その相手のモンスターの攻撃力を半分にする。\n②：自分の墓地から、このカードと「方界」モンスターを任意の数だけ除外し、除外した「方界」モンスターの数だけ相手フィールドの表側表示モンスターを対象として発動できる。\nそのモンスターに方界カウンターを１つずつ置く。\n方界カウンターが置かれたモンスターは攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"方界輪廻",h:"ほうかいりんね",e:"Cubic Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\n相手はその同名モンスターを可能な限り自身の手札・デッキ・墓地から攻撃表示で特殊召喚する。\n対象のモンスター及びこの効果で特殊召喚したモンスターの攻撃力は０になり、それらのモンスターに方界カウンターを１つずつ置く。\n方界カウンターが置かれたモンスターは攻撃できず、効果は無効化される。\nさらに、自分の手札からレベル４以下の「方界」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"方界曼荼羅",h:"ほうかいまんだら",e:"Cubic Mandala",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドに「方界」モンスターが存在する場合、このターン破壊され相手の墓地へ送られたモンスターを任意の数だけ対象としてこのカードを発動できる。\nそのモンスターの攻撃力を０にして相手フィールドに特殊召喚し、そのモンスターに方界カウンターを１つずつ置く。\n方界カウンターが置かれたモンスターは攻撃できず、効果は無効化される。\n②：対象のモンスターが相手フィールドに存在する限り、相手が発動したモンスターの効果は無効化される。\n③：対象のモンスターが全てフィールドから離れた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"方界合神",h:"ほうかいがっしん",e:"Unification of the Cubic Lords",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札・フィールドから、「方界」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：表側表示の「方界」モンスターが、戦闘で破壊された場合、またはフィールドから離れた場合、墓地のこのカードを除外して発動できる。\n手札・デッキからレベル４以下の「方界」モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚されたモンスターは、このターン戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMユニ",h:"えんためいとゆに",e:"Performapal Uni",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに１度だけ発動できる。\n手札からレベル３以下の「EM」モンスター１体を攻撃表示で特殊召喚する。\n②：相手ターンに、自分の墓地からこのカードと「EMユニ」以外の「EM」モンスター１体を除外して発動できる。\nこのターン自分が受ける戦闘ダメージを１度だけ０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMコン",h:"えんためいとこん",e:"Performapal Corn",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:600,de:1000,tx:"①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに１度だけ、このカード以外の自分フィールドの攻撃力１０００以下の「EM」モンスター１体を対象として発動できる。\n自分フィールドの表側攻撃表示の、そのモンスターとこのカードを守備表示にし、デッキから「オッドアイズ」モンスター１体を手札に加える。\n②：相手ターンに、自分の墓地からこのカードと「EMコン」以外の「EM」モンスター１体を除外して発動できる。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ナパーム・ドラゴニアス",h:"れいど・らぷたーず－なぱーむ・どらごにあす",e:"Raidraptor - Napalm Dragonius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n相手に６００ダメージを与える。\nこの効果の発動後、ターン終了時まで自分は「RR」モンスター以外のモンスターの効果を発動できない。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「RR」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ブレード・バーナー・ファルコン",h:"れいど・らぷたーず－ぶれーど・ばーなー・ふぁるこん",e:"Raidraptor - Blade Burner Falcon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"鳥獣族レベル４モンスター×２\n①：自分のLPが相手より３０００以上少なく、このカードがX召喚に成功した場合に発動できる。\nこのカードの攻撃力は３０００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時、このカードのX素材を任意の数だけ取り除いて発動できる。\n取り除いたX素材の数だけ、相手フィールドのモンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"祝福の教会－リチューアル・チャーチ",h:"しゅくふくのきょうかい－りちゅーある・ちゃーち",e:"Ritual Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに手札から魔法カード１枚を捨てて発動できる。\nデッキから光属性の儀式モンスター１体または儀式魔法カード１枚を手札に加える。\n②：自分の墓地の魔法カードを任意の数だけデッキに戻し、デッキに戻した数と同じレベルを持つ、自分の墓地の天使族・光属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ノヴァ",h:"れっど・のう゛ぁ",e:"Red Nova",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「レッド・ノヴァ」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドにレベル８以上のドラゴン族Sモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがチューナー２体以上を素材とするS召喚に使用され墓地へ送られた場合に発動できる。\nデッキから悪魔族・炎属性モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"眠れる巨人ズシン",h:"ねむれるきょじんずしん",e:"Zushin the Sleeping Giant",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\nズシンカウンターが１０個置かれた自分のモンスター１体をリリースした場合のみ特殊召喚できる。\n①：１ターンに１度、手札のこのカードをターン終了時まで相手に見せ、自分フィールドのレベル１通常モンスター１体を対象として発動できる。\nそのモンスターにズシンカウンターを１つ置く。\n②：このカードは他のカードの効果を受けない。\n③：このカードがモンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力・守備力はダメージ計算時のみ、そのモンスターの攻撃力＋１０００の数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"手をつなぐ魔人",h:"てをつなぐまじん",e:"Hand-Holding Genie",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n②：このカードの守備力は、このカード以外の自分フィールドの表側守備表示モンスターの元々の守備力の合計分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラム・フォース",h:"すくらむ・ふぉーす",e:"Scrum Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドに表側守備表示モンスターが２体以上存在する場合、自分フィールドの守備表示モンスターは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードが効果で破壊される場合、代わりに他の自分の魔法＆罠ゾーンのカード１枚を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.100 ヌメロン・ドラゴン",h:"なんばーず１００ ぬめろん・どらごん",e:"Number 100: Numeron Dragon",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"同じランクの同名「No.」Xモンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力は相手ターン終了時まで、フィールドのXモンスターのランクの合計×１０００アップする。\n②：このカードが効果で破壊された時に発動できる。\nフィールドのモンスターを全て破壊する。\nその後、お互いは自身の墓地の魔法・罠カードを１枚選んでフィールドにセットする。\n③：自分の手札・フィールドにカードが無い場合、相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.24 竜血鬼ドラギュラス",h:"なんばーず２４ りゅうけつきどらぎゅらす",e:"Number 24: Dragulas the Vampiric Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2800,tx:"レベル６モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、EXデッキから特殊召喚された表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\nこの効果は相手ターンでも発動できる。\n②：表側表示のこのカードが相手の効果でフィールドから離れ、墓地へ送られた場合または除外された場合に発動できる。\nこのカードを裏側守備表示で特殊召喚する。\n③：このカードがリバースした場合に発動する。\nフィールドのカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"No.45 滅亡の予言者 クランブル・ロゴス",h:"なんばーず４５ めつぼうのよげんしゃ くらんぶる・ろごす",e:"Number 45: Crumble Logos the Prophet of Demolition",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:0,tx:"レベル２モンスター×２体以上\n①：１ターンに１度、このカードのX素材を１つ取り除き、このカード以外のフィールドの表側表示のカード１枚を対象として発動できる。\nこのモンスターが表側表示で存在する間、対象の表側表示のカードの効果は無効化される。\n②：このカードの①の効果の対象としているカードがフィールドに表側表示で存在する限り、お互いに対象のカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.51 怪腕のフィニッシュ・ホールド",h:"なんばーず５１ かいわんのふぃにっしゅ・ほーるど",e:"Number 51: Finisher the Strong Arm",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:0,tx:"レベル３モンスター×３\n①：このカードは戦闘では破壊されない。\n②：このカードが戦闘を行ったダメージステップ終了時に、このカードのX素材を１つ取り除いて発動できる。\nこのカードにカウンターを１つ置く（最大３つまで）。\n③：このカードが戦闘を行ったバトルフェイズ終了時、このカードにカウンターが３つ置かれている場合に発動できる。\n相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.59 背反の料理人",h:"なんばーず５９ ばっく・ざ・こっく",e:"Number 59: Crooked Cook",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:200,tx:"レベル４モンスター×２\n①：自分フィールドのカードがこのカードのみの場合、このカードは他のカードの効果を受けない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカード以外の自分フィールドのカードを全て破壊する。\nその後、このカードの攻撃力はターン終了時まで、この効果で破壊され墓地へ送られたモンスターの数×３００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.78 ナンバーズ・アーカイブ",h:"なんばーず７８ なんばーず・あーかいぶ",e:"Number 78: Number Archive",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分のEXデッキのカードを相手がランダムに１枚選ぶ。\nそれが「No.1」～「No.99」のいずれかの「No.」モンスターだった場合、そのモンスターを自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.98 絶望皇ホープレス",h:"なんばーず９８ ぜつぼうおうほーぷれす",e:"Number 98: Antitopian",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル４モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分または相手のモンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\nそのモンスターを守備表示にする。\n②：このカードが墓地に存在する場合、フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\nこのカードを守備表示で特殊召喚し、対象のモンスターを下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"光波翼機",h:"さいふぁー・うぃんぐ",e:"Cipher Wing",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"①：自分フィールドに「サイファー」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードをリリースして発動できる。\n自分フィールドの全ての「サイファー」モンスターのレベルをターン終了時まで４つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河眼の光波竜",h:"ぎゃらくしーあいず・さいふぁー・どらごん",e:"Galaxy-Eyes Cipher Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル８モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターの効果は無効化され、攻撃力は３０００になり、カード名を「銀河眼の光波竜」として扱う。\nこの効果の発動後、ターン終了時までこのカード以外の自分のモンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河影竜",h:"ぎゃらくしー・すてるす・どらごん",e:"Galaxy Stealth Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1600,tx:"ドラゴン族レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札からドラゴン族モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「ギャラクシー」カードは相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－松－",h:"かーでぃあん－まつ－",e:"Flower Cardian Pine",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「花札衛－松－」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスター以外だった場合、そのカードを墓地へ送る。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－芒－",h:"かーでぃあん－すすき－",e:"Flower Cardian Zebra Grass",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：自分フィールドにレベル７以下の「花札衛」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「花札衛」モンスターしか召喚・特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札の「花札衛」モンスターを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－柳－",h:"かーでぃあん－やなぎ－",e:"Flower Cardian Willow",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：自分フィールドにレベル１０以下の「花札衛」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「花札衛」モンスターしか召喚・特殊召喚できない。\n②：１ターンに１度、自分の墓地の「花札衛」モンスター１体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－桐－",h:"かーでぃあん－きり－",e:"Flower Cardian Paulownia",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：自分フィールドにレベル１１以下の「花札衛」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「花札衛」モンスターしか召喚・特殊召喚できない。\n②：このカードが攻撃対象に選択された時に発動できる。\nその攻撃を無効にし、バトルフェイズを終了する。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－松に鶴－",h:"かーでぃあん－まつにつる－",e:"Flower Cardian Pine with Crane",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは通常召喚できない。\n「花札衛－松に鶴－」以外の自分フィールドのレベル１の「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを特殊召喚できる。\n違った場合、そのカードを墓地へ送る。\n②：このカードが戦闘を行ったバトルフェイズ終了時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－芒に月－",h:"かーでぃあん－すすきにつき－",e:"Flower Cardian Zebra Grass with Moon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは通常召喚できない。\n「花札衛－芒に月－」以外の自分フィールドのレベル８の「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを特殊召喚できる。\n違った場合、そのカードを墓地へ送る。\n②：１ターンに１度、このカードが戦闘で相手モンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－柳に小野道風－",h:"かーでぃあん－やなぎにおののみちかぜ－",e:"Flower Cardian Willow with Calligrapher",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは通常召喚できない。\n「花札衛－柳に小野道風－」以外の自分フィールドのレベル１１の「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを特殊召喚できる。\n違った場合、そのカードを墓地へ送る。\n②：フィールドのこのカードをS素材とする場合、このカードを含む全てのS素材モンスターをレベル２モンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－桐に鳳凰－",h:"かーでぃあん－きりにほうおう－",e:"Flower Cardian Paulownia with Phoenix",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは通常召喚できない。\n「花札衛－桐に鳳凰－」以外の自分フィールドのレベル１２の「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを特殊召喚できる。\n違った場合、そのカードを墓地へ送る。\n②：１ターンに１度、このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－雨四光－",h:"かーでぃあん－あめしこう－",e:"Flower Cardian Lightshower",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"チューナー＋チューナー以外のモンスター３体\n①：このカードがモンスターゾーンに存在する限り、自分フィールドの「花札衛」モンスターは効果では破壊されず、相手の効果の対象にならない。\n②：相手のドローフェイズに相手が通常のドローをした場合に発動する。\n相手に１５００ダメージを与える。\n③：相手エンドフェイズに以下の効果から１つを選択して発動する。\n●次の自分ターンのドローフェイズをスキップする。\n●このカードの効果を次の相手スタンバイフェイズまで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"花合わせ",h:"はなあわせ",e:"Flower Gathering",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「花合わせ」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「花札衛」モンスターしか召喚・特殊召喚できない。\n①：デッキから攻撃力１００の「花札衛」モンスター４体を攻撃表示で特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターの効果は無効化され、アドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"沈黙の剣士－サイレント・ソードマン",h:"ちんもくのけんし－さいれんと・そーどまん",e:"Silent Swordsman",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n自分フィールドの戦士族モンスター１体をリリースした場合のみ特殊召喚できる。\n①：自分・相手のスタンバイフェイズに発動する。\nこのカードの攻撃力は５００アップする。\n②：１ターンに１度、魔法カードが発動した時に発動できる。\nその発動を無効にする。\n③：フィールドのこのカードが戦闘または相手の効果で破壊された場合に発動できる。\n手札・デッキから「沈黙の剣士－サイレント・ソードマン」以外の「サイレント・ソードマン」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"沈黙の魔術師－サイレント・マジシャン",h:"ちんもくのまじゅつし－さいれんと・まじしゃん",e:"Silent Magician",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n自分フィールドの魔法使い族モンスター１体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の手札の数×５００アップする。\n②：１ターンに１度、魔法カードが発動した時に発動できる。\nその発動を無効にする。\n③：フィールドのこのカードが戦闘または相手の効果で破壊された場合に発動できる。\n手札・デッキから「沈黙の魔術師－サイレント・マジシャン」以外の「サイレント・マジシャン」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"沈黙の剣",h:"ちんもくのつるぎ",e:"Silent Sword Slash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「サイレント・ソードマン」モンスター１体を対象として発動できる。\nその自分のモンスターの攻撃力・守備力は１５００アップし、ターン終了時まで相手の効果を受けない。\nこのカードの発動と効果は無効化されない。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「サイレント・ソードマン」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレント・バーニング",h:"さいれんと・ばーにんぐ",e:"Silent Burning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「サイレント・マジシャン」モンスターが存在し、自分の手札が相手より多い場合、自分・相手のバトルフェイズに発動できる。\nお互いのプレイヤーは、それぞれ手札が６枚になるようにデッキからドローする。\nこのカードの発動と効果は無効化されない。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「サイレント・マジシャン」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マグネット・リバース",h:"まぐねっと・りばーす",e:"Magnet Reverse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、機械族・岩石族の通常召喚できないモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マグネット・フォース",h:"まぐねっと・ふぉーす",e:"Magnet Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、元々の種族が機械族または岩石族のフィールドのモンスターは、自身以外の相手モンスターの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"アルティメット・バースト",h:"あるてぃめっと・ばーすと",e:"Neutron Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの融合召喚された「青眼の究極竜」１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に３回攻撃でき、そのモンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"埋葬されし生け贄",h:"まいそうされしいけにえ",e:"Tribute Burial",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このターン、自分がモンスター２体のリリースを必要とするアドバンス召喚をする場合に１度だけ、モンスター２体をリリースせずに自分・相手の墓地からモンスターを１体ずつ除外してアドバンス召喚できる。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・サンクチュアリ",h:"だーく・さんくちゅあり",e:"Dark Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分の「ウィジャ盤」の効果で「死のメッセージ」カードを出す場合、そのカードを通常モンスター（悪魔族・闇・星１・攻／守０）として特殊召喚できる。\nこの効果で特殊召喚したカードは「ウィジャ盤」以外のカードの効果を受けず、攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。\n②：相手モンスターの攻撃宣言時に発動する。\nコイントスを１回行う。\n表だった場合、その攻撃を無効にし、その相手モンスターの攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"電磁石の戦士α",h:"えれくとろまぐねっと・うぉりあー・あるふぁ",e:"Alpha The Electromagnet Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"「電磁石の戦士α」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキからレベル８の「磁石の戦士」モンスター１体を手札に加える。\n②：相手ターンにこのカードをリリースして発動できる。\nデッキからレベル４の「マグネット・ウォリアー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"電磁石の戦士β",h:"えれくとろまぐねっと・うぉりあー・べーた",e:"Beta The Electromagnet Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「電磁石の戦士β」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「電磁石の戦士β」以外のレベル４以下の「マグネット・ウォリアー」モンスター１体を手札に加える。\n②：相手ターンにこのカードをリリースして発動できる。\nデッキからレベル４の「マグネット・ウォリアー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"電磁石の戦士γ",h:"えれくとろまぐねっと・うぉりあー・がんま",e:"Gamma The Electromagnet Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"「電磁石の戦士γ」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「電磁石の戦士γ」以外のレベル４以下の「マグネット・ウォリアー」モンスター１体を特殊召喚する。\n②：相手ターンにこのカードをリリースして発動できる。\nデッキからレベル４の「マグネット・ウォリアー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"電磁石の戦士マグネット・ベルセリオン",h:"でんじしゃくのせんしまぐねっと・べるせりおん",e:"Berserkion the Electromagna Warrior",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2800,tx:"このカードは通常召喚できない。\n自分の手札・フィールド・墓地から、「電磁石の戦士α」「電磁石の戦士β」「電磁石の戦士γ」を１体ずつ除外した場合に特殊召喚できる。\n①：自分の墓地からレベル４以下の「マグネット・ウォリアー」モンスター１体を除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘または相手の効果で破壊された場合、除外されている自分の「電磁石の戦士α」「電磁石の戦士β」「電磁石の戦士γ」を１体ずつ対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリボーン",h:"くりぼーん",e:"Kuribohrn",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：自分・相手のバトルフェイズ終了時にこのカードを手札から捨て、このターンに戦闘で破壊され自分の墓地へ送られたモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：相手モンスターの攻撃宣言時、墓地のこのカードを除外し、自分の墓地の「クリボー」モンスターを任意の数だけ対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マグネット・フィールド",h:"まぐねっと・ふぃーるど",e:"Magnetic Field",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「マグネット・フィールド」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにレベル４以下の岩石族・地属性モンスターが存在する場合、自分の墓地のレベル４以下の「マグネット・ウォリアー」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、自分の岩石族・地属性モンスターとの戦闘で相手モンスターが破壊されなかったダメージステップ終了時に発動できる。\nその相手モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔術の継承",h:"くろまじゅつのけいしょう",e:"Dark Magic Inheritance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地から魔法カード２枚を除外して発動できる。\n「黒魔術の継承」以外の、「ブラック・マジシャン」のカード名または「ブラック・マジシャン・ガール」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マグネット・コンバージョン",h:"まぐねっと・こんばーじょん",e:"Magnet Conversion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のレベル４以下の「マグネット・ウォリアー」モンスターを３体まで対象として発動できる。\nそのモンスターを手札に加える。\n②：墓地のこのカードを除外し、除外されている自分のレベル４以下の「マグネット・ウォリアー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超電導戦機インペリオン・マグナム",h:"ちょうでんどうせんきいんぺりおん・まぐなむ",e:"Imperion Magnum the Superconductive Battlebot",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「磁石の戦士マグネット・バルキリオン」＋「電磁石の戦士マグネット・ベルセリオン」\nこのカードは上記のカードを融合素材にした融合召喚でのみ特殊召喚できる。\n①：１ターンに１度、相手がモンスターの効果・魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「磁石の戦士マグネット・バルキリオン」「電磁石の戦士マグネット・ベルセリオン」１体ずつを手札・デッキから召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"A－アサルト・コア",h:"えー－あさると・こあ",e:"A-Assault Core",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1900,de:200,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族・光属性モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターは他の相手モンスターの効果を受けない。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nこのカード以外の自分の墓地のユニオンモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"B－バスター・ドレイク",h:"びー－ばすたー・どれいく",e:"B-Buster Drake",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族・光属性モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターは他の相手の魔法カードの効果を受けない。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキからユニオンモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"C－クラッシュ・ワイバーン",h:"しー－くらっしゅ・わいばーん",e:"C-Crush Wyvern",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族・光属性モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターは他の相手の罠カードの効果を受けない。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札からユニオンモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"強化支援メカ・ヘビーアーマー",h:"きょうかしえんめか・へびーあーまー",e:"Heavy Mech Support Armor",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚に成功した場合、自分の墓地のユニオンモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n③：装備モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニオン格納庫",h:"ゆにおんかくのうこ",e:"Union Hangar",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから機械族・光属性のユニオンモンスター１体を手札に加える事ができる。\n②：１ターンに１度、自分フィールドに機械族・光属性のユニオンモンスターが召喚・特殊召喚された場合、そのモンスター１体を対象として発動できる。\nそのモンスターに装備可能で、カード名が異なる機械族・光属性のユニオンモンスター１体をデッキから選び、そのモンスターに装備する。\nこの効果で装備したユニオンモンスターは、このターン特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"青き眼の威光",h:"あおきめのいこう",e:"Majesty with Eyes of Blue",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札・デッキから「ブルーアイズ」モンスター１体を墓地へ送り、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはフィールドに表側表示で存在する限り、攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スクランブル・ユニオン",h:"すくらんぶる・ゆにおん",e:"Union Scramble",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「スクランブル・ユニオン」は１ターンに１枚しか発動できない。\n①：除外されている自分の機械族・光属性の、通常モンスターまたはユニオンモンスターを３体まで対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードを除外し、除外されている自分の機械族・光属性の、通常モンスターまたはユニオンモンスター１体を対象として発動できる。\nそのモンスターを手札に戻す。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"AtoZ－ドラゴン・バスターキャノン",h:"えーとぅずぃ－どらごん・ばすたーきゃのん",e:"A-to-Z-Dragon Buster Cannon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「ABC－ドラゴン・バスター」＋「XYZ－ドラゴン・キャノン」\n自分フィールドの、元々のカード名が上記のカードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：相手がモンスターの効果・魔法・罠カードを発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。\n②：フィールドのこのカードを除外し、除外されている自分の「ABC－ドラゴン・バスター」「XYZ－ドラゴン・キャノン」を１体ずつ対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ABC－ドラゴン・バスター",h:"えーびーしー－どらごん・ばすたー",e:"ABC-Dragon Buster",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2800,tx:"「A－アサルト・コア」＋「B－バスター・ドレイク」＋「C－クラッシュ・ワイバーン」\n自分のフィールド・墓地の上記カードを除外した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、手札を１枚捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。\n②：相手ターンにこのカードをリリースし、除外されている自分の機械族・光属性のユニオンモンスター３種類を１体ずつ対象として発動できる。\nそのモンスターを特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"竜核の呪霊者",h:"りゅうかくのじゅりょうしゃ",e:"Dragon Core Hexer",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:2300,de:3000,tx:"永きに渡って狩り続けたドラゴンの返り血により、常人ならざる力を宿した女戦士。\nその魂は斃されたドラゴンの怨嗟に染まり、疫病を撒き散らす邪悪な竜核へと成り果てた。\n\n\nもはや帰る故郷もなく、本能のままに刃を血に染めたその目的は、彼女自身にも思い出せない・・・。",li:"",lk:[],ta:[],al:[]},
  {n:"EMウィム・ウィッチ",h:"えんためいとうぃむ・うぃっち",e:"Performapal Whim Witch",t:"monster",a:"闇",l:3,ps:4,pe:"「EMウィム・ウィッチ」のP効果は１ターンに１度しか使用できない。\n①：エクストラデッキから特殊召喚されたモンスターが相手フィールドにのみ存在する場合に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:800,tx:"①：Pモンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオールカバー・ヒッポ",h:"えんためいとおーるかばー・ひっぽ",e:"Performapal Flip Hippo",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した時に発動できる。\n手札から「EM」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果はターン終了時まで無効化される。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドのモンスターを全て守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMシール・イール",h:"えんためいとしーる・いーる",e:"Performapal Seal Eel",t:"monster",a:"水",l:1,ps:3,pe:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",tr:"魚族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが召喚・特殊召喚に成功した時、相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nこのターン、そのカードは発動できない。\nこの効果の発動に対して、お互いは魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"EMチェーンジラフ",h:"えんためいとちぇーんじらふ",e:"Performapal Changeraffe",t:"monster",a:"地",l:1,ps:5,pe:"①：自分のモンスター１体が戦闘で破壊された時に発動できる。\nこのカードを破壊し、その戦闘で破壊されたモンスターを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン戦闘では破壊されない。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:500,tx:"①：このカードが召喚・特殊召喚に成功した時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのモンスターが表側表示で存在する間、対象の表側表示モンスターは攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物フライ・ヘル",h:"ぷれでたー・ぷらんつふらい・へる",e:"Predaplant Flytrap",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターに捕食カウンターを１つ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。\n②：このカードがこのカードのレベル以下のレベルを持つモンスターと戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターを破壊する。\nその後、破壊したそのモンスターの元々のレベル分だけ、このカードのレベルを上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物モーレイ・ネペンテス",h:"ぷれでたー・ぷらんつもーれい・ねぺんてす",e:"Predaplant Moray Nepenthes",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードの攻撃力は、フィールドの捕食カウンターの数×２００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nその破壊したモンスターを装備カード扱いとしてこのカードに装備する。\n③：１ターンに１度、このカードの効果で装備しているモンスターカード１枚を対象として発動できる。\nそのカードを破壊し、その元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物スキッド・ドロセーラ",h:"ぷれでたー・ぷらんつすきっど・どろせーら",e:"Predaplant Squid Drosera",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"①：このカードを手札から墓地へ送り、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターは捕食カウンターが置かれた相手モンスター全てに１回ずつ攻撃できる。\n②：表側表示のこのカードがフィールドから離れた場合に発動する。\n相手フィールドの特殊召喚されたモンスター全てに捕食カウンターを１つずつ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者装留チュウサイ",h:"ちょうじゅうむしゃそうるちゅうさい",e:"Superheavy Samurai Soulpeacemaker",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「超重武者装留チュウサイ」の③の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、相手は装備モンスター以外の自分のモンスターを攻撃できない。\n③：このカードの効果でこのカードを装備した自分のモンスターをリリースして発動できる。\nデッキから「超重武者」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"光波双顎機",h:"さいふぁー・ついん・らぷとる",e:"Cipher Twin Raptor",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚されたモンスターが相手フィールドに存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：手札を１枚捨てて発動できる。\n手札・デッキから「サイファー」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「サイファー」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"光波鏡騎士",h:"さいふぁー・みらーないと",e:"Cipher Mirror Knight",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「光波鏡騎士」の②の効果は１ターンに１度しか使用できない。\n①：自分の「サイファー」モンスター１体が戦闘で破壊され自分の墓地へ送られた時、このカードを手札から捨てて発動できる。\n自分の手札・フィールドのカード１枚を選んで墓地へ送り、その破壊されたモンスターを特殊召喚する。\n②：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「サイファー」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－萩に猪－",h:"かーでぃあん－はぎにいのしし－",e:"Flower Cardian Clover with Boar",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n「花札衛－萩に猪－」以外の自分フィールドの「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、相手フィールドのモンスター１体を選んで破壊できる。\n違った場合、そのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－紅葉に鹿－",h:"かーでぃあん－もみじにしか－",e:"Flower Cardian Maple with Deer",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n「花札衛－紅葉に鹿－」以外の自分フィールドの「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、相手フィールドの魔法・罠カード１枚を選んで破壊できる。\n違った場合、そのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－牡丹に蝶－",h:"かーでぃあん－ぼたんにちょう－",e:"Flower Cardian Peony with Butterfly",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n「花札衛－牡丹に蝶－」以外の自分フィールドの「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、相手のデッキの上からカードを３枚確認し、好きな順番でデッキの上か下に戻す。\n違った場合、そのカードを墓地へ送る。\n②：フィールドのこのカードをS素材とする場合、このカードを含む全てのS素材モンスターをレベル２モンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－クオン",h:"くりすとろん－くおん",e:"Crystron Quan",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"「水晶機巧－クオン」の効果は１ターンに１度しか使用できない。\n①：相手のメインフェイズ及びバトルフェイズに発動できる。\n手札からチューナー以外のモンスター１体を効果を無効にして特殊召喚し、そのモンスターとこのカードのみを素材として機械族Sモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－シトリィ",h:"くりすとろん－しとりぃ",e:"Crystron Citree",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：相手のメインフェイズ及びバトルフェイズに、チューナー以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、そのモンスターとこのカードのみを素材として機械族Sモンスター１体をS召喚する。\nその時のS素材モンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－プラシレータ",h:"くりすとろん－ぷらしれーた",e:"Crystron Prasiortle",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"「水晶機巧－プラシレータ」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\n手札から「クリストロン」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－スモーガー",h:"くりすとろん－すもーがー",e:"Crystron Smiger",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"「水晶機巧－スモーガー」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「クリストロン」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－シストバーン",h:"くりすとろん－しすとばーん",e:"Crystron Thystvern",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族SモンスターしかEXデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「水晶機巧－シストバーン」以外の「クリストロン」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－ローズニクス",h:"くりすとろん－ろーずにくす",e:"Crystron Rosenix",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"「水晶機巧－ローズニクス」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\n自分フィールドに「水晶機巧トークン」（機械族・水・星１・攻／守０）１体を特殊召喚する。\nこのトークンはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜皇バハルストスF",h:"しんりゅうおうばはるすとすふゅーらー",e:"True King Bahrastos, the Fathomer",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1800,de:3000,tx:"「真竜皇バハルストスF」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカード以外の手札及び自分フィールドの表側表示モンスターの中から、水属性モンスターを含むモンスター２体を破壊し、このカードを手札から特殊召喚し、水属性モンスター２体を破壊した場合、相手のフィールド・墓地から魔法・罠カードを２枚まで選んで除外できる。\n②：このカードが効果で破壊された場合に発動できる。\nデッキから水属性以外の幻竜族モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レアメタルフォーゼ・ビスマギア",h:"れあめたるふぉーぜ・びすまぎあ",e:"Raremetalfoes Bismugear",t:"monster",a:"炎",l:1,ps:8,pe:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「メタルフォーゼ」魔法・罠カード１枚を選んで自分フィールドにセットする。",tr:"サイキック族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのターンのエンドフェイズに、デッキから「メタルフォーゼ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"化合獣ハイドロン・ホーク",h:"かごうじゅうはいどろん・ほーく",e:"Chemicritter Hydron Hawk",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["デュアル","効果"],ma:"",tp:"",at:1400,de:700,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●手札を１枚捨て、自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n「化合獣ハイドロン・ホーク」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"化合獣カーボン・クラブ",h:"かごうじゅうかーぼん・くらぶ",e:"Chemicritter Carbo Crab",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:700,de:1400,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分メインフェイズに発動できる。\nデッキからデュアルモンスター１体を墓地へ送る。\nその後、デッキからデュアルモンスター１体を手札に加える。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"化合獣オキシン・オックス",h:"かごうじゅうおきしん・おっくす",e:"Chemicritter Oxy Ox",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["デュアル","効果"],ma:"",tp:"",at:0,de:2100,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分メインフェイズに発動できる。\n手札からデュアルモンスター１体を特殊召喚し、自分フィールドの全てのデュアルモンスターのレベルはターン終了時まで、この効果で特殊召喚したモンスターの元々のレベルと同じになる。\n「化合獣オキシン・オックス」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"進化合獣ダイオーキシン",h:"しんかごうじゅうだいおーきしん",e:"Poly-Chemicritter Dioxogre",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:2800,de:200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードがモンスターゾーンに存在する限り、デュアルモンスターの召喚は無効化されない。\n\n●１ターンに１度、自分の墓地のデュアルモンスター１体を除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"進化合獣ヒュードラゴン",h:"しんかごうじゅうひゅーどらごん",e:"Poly-Chemicritter Hydragon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:200,de:2800,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカード以外のデュアルモンスターが召喚に成功した時に発動できる。\nそのモンスターの攻撃力・守備力は５００アップする。\n\n●自分フィールドのデュアルモンスターが効果で破壊される場合、代わりに自分フィールドのカード１枚を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の凶星竜－メテオ・ドラゴン",h:"れっどあいずのきょうせいりゅう－めてお・どらごん",e:"Meteor Dragon Red-Eyes Impact",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:2000,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「レッドアイズ」モンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレーム・マルチスレッダー",h:"さいふれーむ・まるちすれっだー",e:"PSY-Frame Multi-Threader",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:2500,tx:"「PSYフレーム・マルチスレッダー」の③の効果は１ターンに１度しか使用できない。\n①：このカードは手札・墓地に存在する限り、「PSYフレーム・ドライバー」として扱う。\n②：自分フィールドの「PSYフレーム」カードが戦闘・効果で破壊される場合、代わりに手札のこのカードを捨てる事ができる。\n③：このカードが墓地に存在し、自分フィールドに「PSYフレーム」チューナーが特殊召喚された場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・スライムJr.",h:"ぐれいどる・すらいむじゅにあ",e:"Graydle Slime Jr.",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードが召喚に成功した時、自分の墓地の「グレイドル」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、この効果で特殊召喚したモンスターと同じレベルの水族モンスター１体を手札から特殊召喚できる。\nこの効果の発動後、ターン終了時まで自分は水属性モンスターしか特殊召喚できない。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「グレイドル」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマセラフィ－アンゼリカ",h:"あろませらふぃ－あんぜりか",e:"Aromaseraphy Angelica",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分の墓地の「アロマ」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分だけ自分のLPを回復する。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在し、自分のLPが相手より多く、自分フィールドに「アロマ」モンスターが存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"怒気土器",h:"どきどき",e:"Doki Doki",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：手札から岩石族モンスター１体を捨てて発動できる。\nそのモンスターと元々の属性・レベルが同じ岩石族モンスター１体を、デッキから表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トルクチューン・ギア",h:"とるくちゅーん・ぎあ",e:"Torque Tune Gear",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["ユニオン","チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は５００アップし、チューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"パンドラの宝具箱",h:"ぱんどらのほうぐばこ",e:"Pandora's Jewelry Box",t:"monster",a:"闇",l:4,ps:4,pe:"①：自分のエクストラデッキにカードが存在しない場合、相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、Pゾーンのこのカードを相手のPゾーンに置く。",tr:"幻竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：自分のエクストラデッキにカードが存在せず、このカードがモンスターゾーンに存在する限り、自分ドローフェイズの通常のドローは２枚になる。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精伝姫－ターリア",h:"ふぇありーている－たーりあ",e:"Fairy Tail - Sleeper",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1850,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\n手札からモンスター１体を特殊召喚する。\n②：相手が通常魔法・通常罠カードを発動した時、このカード以外の自分フィールドのモンスター１体をリリースして発動できる。\nその効果は「相手フィールドの表側表示モンスター１体を選んで裏側守備表示にする」となる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エンジェル－美朱濡－",h:"さいばー・えんじぇる－びしゅぬ－",e:"Cyber Angel Vrash",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「機械天使の儀式」により降臨。\n①：このカードが儀式召喚に成功した場合に発動できる。\nエクストラデッキから特殊召喚された相手フィールドのモンスターを全て破壊し、破壊したモンスターの数×１０００ダメージを相手に与える。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃できる。\n②：１ターンに１度、フィールドのカードを破壊する魔法・罠・モンスターの効果が発動した時、自分の墓地の儀式モンスター１体をデッキに戻して発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"古聖戴サウラヴィス",h:"こせいたいさうらう゛ぃす",e:"Sauravis, the Ancient and Ascended",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2600,de:2800,tx:"「精霊の祝福」により降臨。\n①：自分フィールドのモンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードを手札から捨てて発動できる。\nその発動を無効にする。\n②：相手がモンスターを特殊召喚する際に、フィールドのこのカードを持ち主の手札に戻して発動できる。\nその特殊召喚を無効にし、そのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターヴ・ヴェノム・フュージョン・ドラゴン",h:"すたーう゛・う゛ぇのむ・ふゅーじょん・どらごん",e:"Starving Venom Fusion Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2000,tx:"トークン以外のフィールドの闇属性モンスター×２\n①：このカードが融合召喚に成功した場合に発動できる。\n相手フィールドの特殊召喚されたモンスター１体を選び、その攻撃力分だけこのカードの攻撃力をターン終了時までアップする。\n②：１ターンに１度、相手フィールドのレベル５以上のモンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはそのモンスターと同名カードとして扱い、同じ効果を得る。\n③：融合召喚したこのカードが破壊された場合に発動できる。\n相手フィールドの特殊召喚されたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フルメタルフォーゼ・アルカエスト",h:"ふるめたるふぉーぜ・あるかえすと",e:"Fullmetalfoes Alkahest",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「メタルフォーゼ」モンスター＋通常モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：相手ターンに１度、フィールドの効果モンスター１体を対象として発動できる。\nその効果モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの守備力は、このカードの効果で装備したモンスターの元々の攻撃力分アップする。\n③：このカードが「メタルフォーゼ」融合モンスターカードによって決められた融合素材モンスターを装備している場合、その装備カードをその融合モンスターの融合素材に使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・ミスリエル",h:"めたるふぉーぜ・みすりえる",e:"Metalfoes Mithrilium",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1000,tx:"「メタルフォーゼ」モンスター＋Pモンスター\n「メタルフォーゼ・ミスリエル」の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の「メタルフォーゼ」カード２枚とフィールドのカード１枚を対象として発動できる。\n墓地の対象のカードをデッキに戻し、フィールドの対象のカードを持ち主の手札に戻す。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分のエクストラデッキの表側表示の「メタルフォーゼ」Pモンスターまたは自分の墓地の「メタルフォーゼ」Pモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"流星竜メテオ・ブラック・ドラゴン",h:"りゅうせいりゅうめてお・ぶらっく・どらごん",e:"Meteor Black Comet Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:2000,tx:"レベル７「レッドアイズ」モンスター＋レベル６ドラゴン族モンスター\n①：このカードが融合召喚に成功した場合に発動できる。\n手札・デッキから「レッドアイズ」モンスター１体を墓地へ送り、そのモンスターの元々の攻撃力の半分のダメージを相手に与える。\n②：このカードがモンスターゾーンから墓地へ送られた場合、自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重忍者サルト－B",h:"ちょうじゅうにんじゃさると－びー",e:"Superheavy Samurai Ninja Sarutobi",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:2800,tx:"機械族チューナー＋チューナー以外の「超重武者」モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：１ターンに１度、自分の墓地に魔法・罠カードが存在しない場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊し、相手に５００ダメージを与える。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－猪鹿蝶－",h:"かーでぃあん－いのしかちょう－",e:"Flower Cardian Boardefly",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"チューナー＋チューナー以外のモンスター２体\n①：このカードがモンスターゾーンに存在する限り、自分の「花札衛」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：１ターンに１度、自分の墓地の「花札衛」モンスター１体を除外して発動できる。\n次の相手ターン終了時まで、相手は墓地のカードの効果を発動できず、墓地からモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－クオンダム",h:"くりすとろん－くおんだむ",e:"Crystron Quandax",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1800,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：相手のメインフェイズ及びバトルフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。\n②：S召喚したこのカードが戦闘・効果で破壊された場合、Sモンスター以外の自分の墓地の「クリストロン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－アメトリクス",h:"くりすとろん－あめとりくす",e:"Crystron Ametrix",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動できる。\n相手フィールドの特殊召喚された表側表示モンスターを全て守備表示にする。\n②：S召喚したこのカードが戦闘・効果で破壊された場合、Sモンスター以外の自分の墓地の「クリストロン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－フェニキシオン",h:"くりすとろん－ふぇにきしおん",e:"Crystron Phoenix",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター１体以上\n①：このカードがS召喚に成功した場合に発動できる。\n相手のフィールド・墓地の魔法・罠カードを全て除外する。\n②：S召喚したこのカードが戦闘・効果で破壊された場合、このカード以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマセラフィ－ローズマリー",h:"あろませらふぃ－ろーずまりー",e:"Aromaseraphy Rosemary",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:900,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分フィールドの植物族モンスターの攻撃力・守備力は５００アップする。\n②：１ターンに１度、自分のLPが回復した場合、相手フィールドの表側表示のカード１枚を対象として発動する。\nそのカードの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"源竜星－ボウテンコウ",h:"げんりゅうせい－ぼうてんこう",e:"Denglong, First of the Yang Zing",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:0,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「源竜星－ボウテンコウ」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「竜星」カード１枚を手札に加える。\n②：１ターンに１度、デッキから幻竜族モンスター１体を墓地へ送って発動できる。\nこのカードのレベルは、墓地へ送ったモンスターと同じになる。\n③：表側表示のこのカードがフィールドから離れた場合に発動できる。\nデッキから「竜星」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・レクイエム・エクシーズ・ドラゴン",h:"だーく・れくいえむ・えくしーず・どらごん",e:"Dark Requiem Xyz Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル５モンスター×３\n①：このカードが「ダーク・リベリオン・エクシーズ・ドラゴン」をX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、その元々の攻撃力分このカードの攻撃力をアップする。\n●相手がモンスターの効果を発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。\nその後、自分の墓地のXモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超化合獣メタン・ハイド",h:"ちょうかごうじゅうめたん・はいど",e:"Vola-Chemicritter Methydraco",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル８デュアルモンスター×２\n①：このカードがX召喚に成功した時、自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：X素材を持ったこのカードがモンスターゾーンに存在する限り、相手は自分フィールドのデュアルモンスターを攻撃対象にできず、効果の対象にもできない。\n③：デュアルモンスターが召喚に成功した時、このカードのX素材を１つ取り除いて発動できる。\n相手は自身の手札・フィールドのカード１枚を墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の騎士 ヴァトライムス",h:"てらないと　う゛ぁとらいむす",e:"Darktellarknight Batlamyus",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:550,tx:"レベル４「テラナイト」モンスター×２\n①：このカードがモンスターゾーンに存在する限り、フィールドの全ての表側表示モンスターは闇属性になる。\n②：このカードのX素材を１つ取り除き、手札を１枚捨てて発動できる。\n光属性の「テラナイト」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスターをX召喚できない。\n自分の墓地に「テラナイト」モンスターが７種類以上存在する場合、この効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"餅カエル",h:"もちかえる",e:"Toadally Awesome",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:0,tx:"水族レベル２モンスター×２\n①：お互いのスタンバイフェイズにこのカードのX素材を１つ取り除いて発動できる。\nデッキから「ガエル」モンスター１体を特殊召喚する。\n②：１ターンに１度、相手がモンスターの効果・魔法・罠カードを発動した時、自分の手札・フィールドの水族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\nその後、破壊したカードを自分フィールドにセットできる。\n③：このカードが墓地へ送られた場合、自分の墓地の水属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"アメイジング・ペンデュラム",h:"あめいじんぐ・ぺんでゅらむ",e:"Amazing Pendulum",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「アメイジング・ペンデュラム」は１ターンに１枚しか発動できない。\n①：自分のPゾーンにカードが存在しない場合に発動できる。\n自分のエクストラデッキから、カード名が異なる表側表示の「魔術師」Pモンスター２体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－幻影騎士団ラウンチ",h:"らんくあっぷまじっく－ふぁんとむ・ないつらうんち",e:"The Phantom Knights' Rank-Up-Magic Launch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分・相手のメインフェイズに、自分フィールドのX素材の無い闇属性Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが１つ高い闇属性Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚し、このカードを下に重ねてX素材とする。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの闇属性Xモンスター１体を対象として発動できる。\n手札の「幻影騎士団」モンスター１体を、そのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"花積み",h:"はなづみ",e:"Flower Stacking",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「花積み」の②の効果は１ターンに１度しか使用できない。\n①：デッキから「花札衛」モンスター３種類を選び、好きな順番でデッキの上に戻す。\n②：墓地のこのカードを除外し、自分の墓地の「花札衛」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超こいこい",h:"ちょうこいこい",e:"Super Koi Koi",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「超こいこい」は１ターンに１枚しか発動できない。\n①：自分のデッキの上からカードを３枚めくり、その中の「花札衛」モンスターを可能な限り、召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは２になり、効果は無効化される。\n残りのカードは全て裏側表示で除外し、自分は除外したカードの数×１０００LPを失う。\n②：墓地のこのカードを除外し、自分フィールドのモンスター１体をリリースして発動できる。\n手札から「花札衛」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタルP",h:"くりすたるぽてんしゃる",e:"Crystolic Potential",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「クリストロン」モンスターの攻撃力・守備力は３００アップする。\n②：自分・相手のエンドフェイズに発動できる。\nこのターン自分がS召喚した「クリストロン」Sモンスターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"重錬装融合",h:"ふるめたるふぉーぜ・ふゅーじょん",e:"Fullmetalfoes Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「メタルフォーゼ」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"化合電界",h:"すぱーく・ふぃーるど",e:"Catalyst Field",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分はレベル５以上のデュアルモンスターを召喚する場合に必要なリリースをなくす事ができる。\nこの効果は１ターンに１度しか適用できない。\n②：このカードがフィールドゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズにデュアルモンスター１体を召喚できる。\n③：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\n自分フィールドのもう１度召喚された状態のデュアルモンスター１体を相手エンドフェイズまで除外し、対象のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドアイズ・インサイト",h:"れっどあいず・いんさいと",e:"Red-Eyes Insight",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「レッドアイズ・インサイト」は１ターンに１枚しか発動できない。\n①：手札・デッキから「レッドアイズ」モンスター１体を墓地へ送って発動できる。\nデッキから「レッドアイズ・インサイト」以外の「レッドアイズ」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"イグナイト・ユナイト",h:"いぐないと・ゆないと",e:"Igknights Unite",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「イグナイト・ユナイト」は１ターンに１枚しか発動できない。\n①：自分フィールドの「イグナイト」カード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「イグナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"創星の因子",h:"じぇねしす・てらないと",e:"Tellarknight Genesis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このカード以外の自分フィールドの「テラナイト」カードの数だけ、フィールドの魔法・罠カードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"「A」細胞組み換え装置",h:"「えー」さいぼうくみかえそうち",e:"A Cell Recombination Device",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nデッキから「エーリアン」モンスター１体を墓地へ送り、墓地へ送ったモンスターのレベルの数だけ、対象のモンスターにAカウンターを置く。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「エーリアン」モンスター１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊の祝福",h:"せいれいのしゅくふく",e:"Sprite's Blessing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"光属性の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、手札から光属性の儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"大欲な壺",h:"たいよくなつぼ",e:"Pot of Acquisitiveness",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：除外されている自分及び相手のモンスターの中から合計３体を対象として発動できる。\nそのモンスター３体を持ち主のデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"検疫",h:"けんえき",e:"Quarantine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分・相手のエンドフェイズに相手フィールドにセットされた魔法・罠カード１枚を対象としてこの効果を発動できる。\nそのカードを確認し、元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"王者の調和",h:"きんぐす・しんくろ",e:"King's Synchro",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターが自分のSモンスターに攻撃宣言した時に発動できる。\nその攻撃を無効にする。\nその後、以下の効果を適用できる。\n●その自分のSモンスターと自分の墓地のチューナー１体を除外し、除外したモンスターのレベルの合計と同じレベルを持つSモンスター１体を、エクストラデッキからS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"二重光波",h:"だぶる・さいふぁー",e:"Double Cipher",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの攻撃力が一番高いモンスターが相手フィールドに存在する場合、自分フィールドのX素材を持った、「ギャラクシーアイズ」Xモンスターまたは「サイファー」Xモンスター１体を対象として発動できる。\nそのXモンスターのX素材を全て取り除き、そのXモンスターの同名モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"光波防輪",h:"さいふぁー・びっと",e:"Cipher Bit",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの、「ギャラクシーアイズ」Xモンスターまたは「サイファー」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とし、そのモンスターはこのターンに１度だけ戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"イカサマ御法度",h:"いかさまごはっと",e:"Fraud Freeze",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、相手が手札からモンスターを特殊召喚した時に発動できる。\n手札から特殊召喚された相手フィールドのモンスターを全て持ち主の手札に戻す。\n②：フィールドに「花札衛」Sモンスターが存在しない場合にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリストロン・エントリー",h:"くりすとろん・えんとりー",e:"Crystron Entry",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「クリストロン・エントリー」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・墓地から「クリストロン」チューナーをそれぞれ１体ずつ選んで特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「クリストロン」モンスター１体を対象として発動できる。\nそのモンスターのレベルと異なるレベルを持つ「クリストロン」モンスター１体をデッキから墓地へ送る。\n対象のモンスターのレベルは、墓地へ送ったモンスターのレベルと同じになる。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"クリストロン・インパクト",h:"くりすとろん・いんぱくと",e:"Crystron Impact",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：除外されている自分の「クリストロン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、相手フィールドに表側表示モンスターが存在する場合、その相手モンスターの守備力は０になる。\n②：自分フィールドの「クリストロン」モンスターを対象とした魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその効果を無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"完全燃焼",h:"ばーんあうと",e:"Burnout",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「完全燃焼」は１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示の「化合獣」モンスター１体を除外して発動できる。\nデッキから「化合獣」モンスター２体を特殊召喚する（同名カードは１枚まで）。\n②：相手モンスターの直接攻撃宣言時に、墓地のこのカードを除外し、除外されている自分のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはもう１度召喚された状態として扱う。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"PSYフレーム・アクセラレーター",h:"さいふれーむ・あくせられーたー",e:"PSY-Frame Accelerator",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、５００LPを払い、自分フィールドの「PSYフレーム」モンスター１体を対象として発動できる。\nそのモンスターを次の自分スタンバイフェイズまで除外する。\n②：１ターンに１度、このカード以外の自分フィールドの表側表示の「PSYフレーム」カードが戦闘以外でフィールドから離れた場合に発動できる。\n手札から「PSYフレーム」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"グレイドル・コンバット",h:"ぐれいどる・こんばっと",e:"Graydle Combat",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドの「グレイドル」モンスター１体のみを対象とするモンスターの効果・魔法・罠カードが発動した時、以下の効果から１つを選択して発動できる。\n\n●その効果は「対象のモンスター１体を破壊する」となる。\n\n●その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機殻の凍結",h:"くりふぉーとだうん",e:"Qlife's End",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（機械族・地・星４・攻１８００／守１０００）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果でこのカードを特殊召喚したターン、自分フィールドの「クリフォート」魔法・罠カードは効果では破壊されない。\n②：このカードの効果で特殊召喚したこのカードは、「アポクリフォート」モンスターをアドバンス召喚する場合、３体分のリリースにできる。",li:"",lk:[],ta:[],al:[]},
  {n:"竜星の九支",h:"りゅうせいのきゅうし",e:"Nine Pillars of Yang Zing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「竜星」カードが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし、そのカードを持ち主のデッキに戻す。\nその後、このカード以外の自分フィールドの「竜星」カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"次元障壁",h:"じげんしょうへき",e:"Dimensional Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：モンスターの種類（儀式・融合・S・X・P）を１つ宣言して発動できる。\nこのターン中、以下の効果を適用する。\n\n●お互いに宣言した種類のモンスターを特殊召喚できず、フィールドの宣言した種類のモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"サモン・ゲート",h:"さもん・げーと",e:"Summon Gate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いはそれぞれ１ターンに合計３体までしかエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"プレゼントカード",h:"ぷれぜんとかーど",e:"Present Card",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「プレゼントカード」は１ターンに１枚しか発動できない。\n①：相手は手札を全て捨てる。\nその後、相手はデッキから５枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"蛇神ゲー",h:"じゃしんげー",e:"Divine Serpent Geh",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：自分フィールドのモンスターが相手の攻撃・効果で破壊された場合、LPを半分払って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードは効果の対象にならない。\n③：このカードが相手モンスターを攻撃するダメージステップの間、そのモンスターの効果は無効化され、攻撃力は元々の攻撃力の半分になる。\n④：このカードが戦闘を行うダメージ計算時に発動できる。\nこのカードの攻撃力は、フィールドのモンスターの一番高い元々の攻撃力と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"沈黙の魔導剣士－サイレント・パラディン",h:"ちんもくのまどうけんし－さいれんと・ぱらでぃん",e:"Silent Paladin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「サイレント・ソードマン LV３」または「サイレント・マジシャン LV４」１体を手札に加える。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、自分フィールドのモンスター１体のみを対象とする魔法カードが発動した時に発動できる。\nその発動を無効にする。\n③：フィールドのこのカードが戦闘または相手の効果で破壊された場合、自分の墓地の光属性の「LV」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMハンサムライガー",h:"えんためいとはんさむらいがー",e:"Performapal Handsamuraiger",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"「EMハンサムライガー」の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキからレベル５以上のPモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"パッチワーク・ファーニマル",h:"ぱっちわーく・ふぁーにまる",e:"Patchwork Fluffal",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードはモンスターゾーンに存在する限り、「デストーイ」モンスターとしても扱う。\n②：このカードはモンスターゾーンに存在する限り、「デストーイ」融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の歯車機械",h:"あんてぃーく・ぎあがじぇっと",e:"Ancient Gear Gadget",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"①：このカードが召喚・特殊召喚に成功した場合、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\nこのターン、自分のモンスターが攻撃する場合、相手はダメージステップ終了時まで宣言した種類のカードを発動できない。\n②：１ターンに１度、「ガジェット」モンスターのカード名を１つ宣言して発動できる。\nエンドフェイズまで、このカードは宣言したカードと同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械熱核竜",h:"あんてぃーく・ぎあ・りあくたー・どらごん",e:"Ancient Gear Reactor Dragon",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"①：「アンティーク・ギア」モンスターをリリースしてアドバンス召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：「ガジェット」モンスターをリリースしてアドバンス召喚したこのカードは１度のバトルフェイズ中に２回攻撃できる。\n③：このカードが攻撃する場合、相手はダメージステップ終了時までモンスターの効果・魔法・罠カードを発動できない。\n④：このカードが攻撃したダメージステップ終了時に発動できる。\nフィールドの魔法・罠カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械合成竜",h:"あんてぃーく・ぎあひどら",e:"Ancient Gear Hydra",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"①：「アンティーク・ギア」モンスターをリリースしてアドバンス召喚したこのカードとの戦闘で相手モンスターが破壊されなかったダメージステップ終了時に発動できる。\nその相手モンスターを除外する。\n②：「ガジェット」モンスターをリリースしてアドバンス召喚したこのカードは相手モンスター全てに１回ずつ攻撃できる。\n③：自分の「アンティーク・ギア」モンスターが攻撃する場合、相手はダメージステップ終了時までモンスターの効果・魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械飛竜",h:"あんてぃーく・ぎあわいばーん",e:"Ancient Gear Wyvern",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"「古代の機械飛竜」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「古代の機械飛竜」以外の「アンティーク・ギア」カード１枚を手札に加える。\nこの効果の発動後、ターン終了時まで自分はカードをセットできない。\n②：このカードが攻撃する場合、相手はダメージステップ終了時までモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械射出機",h:"あんてぃーく・ぎあかたぱると",e:"Ancient Gear Catapult",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「古代の機械射出機」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドにモンスターが存在しない場合、自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「アンティーク・ギア」モンスター１体を召喚条件を無視して特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、自分フィールドに「古代の歯車トークン」（機械族・地・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械要塞",h:"あんてぃーく・ぎあふぉーとれす",e:"Ancient Gear Fortress",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「アンティーク・ギア」モンスターは召喚・特殊召喚されたターンには相手の効果の対象にならず、相手の効果では破壊されない。\n②：「アンティーク・ギア」カードの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n③：魔法＆罠ゾーンのこのカードが破壊された場合に発動できる。\n自分の手札・墓地から「アンティーク・ギア」モンスター１体を選んで特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「アンティーク・ギア」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジックアブソーバー",h:"まじっくあぶそーばー",e:"Magical Something",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードのレベルは、このカードの魔力カウンターの数だけ上がる。\n③：このカードの魔力カウンターを３つ取り除き、自分の墓地の速攻魔法カード１枚を対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"隠密忍法帖",h:"おんみつにんぽうちょう",e:"Ninjitsu Art Notebook",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札から「忍者」モンスター１体を墓地へ送って発動できる。\nデッキから「隠密忍法帖」以外の「忍法」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーの戦士",h:"さぶてらーのせんし",e:"Subterror Nemesis Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「サブテラー」モンスター１体を墓地へ送って発動できる。\n元々のレベルの合計がその「サブテラー」モンスターのレベル以上となるように、このカードと自分フィールドのモンスター１体以上をリリースし、その「サブテラー」モンスターを表側守備表示または裏側守備表示で墓地から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドの「サブテラーマリス」モンスターがリバースした場合に発動できる（ダメージステップでも発動可能）。\n墓地のこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・リグリアード",h:"さぶてらーまりす・りぐりあーど",e:"Subterror Behemoth Umastryx",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:2000,de:2700,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・ジブラタール",h:"さぶてらーまりす・じぶらたーる",e:"Subterror Behemoth Stalagmo",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:2800,de:2100,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\n手札から「サブテラー」モンスター１体を捨て、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"地中界シャンバラ",h:"ちちゅうかいしゃんばら",e:"The Hidden City",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「サブテラー」モンスター１体を手札に加える事ができる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分の裏側守備表示の「サブテラー」モンスター１体を選んで表側攻撃表示または表側守備表示にする。\n③：１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\n自分の裏側守備表示の「サブテラー」モンスター１体を選んで表側攻撃表示または表側守備表示にする。\nその後、その攻撃を無効にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL－ダンディ",h:"すぱいらる－だんでぃ",e:"SPYRAL Super Agent",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手のデッキの一番上のカードをお互いに確認し、宣言した種類のカードだった場合、このカードを手札から特殊召喚する。\n②：このカードが「SPYRAL」カードの効果で特殊召喚に成功した場合、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL－ジーニアス",h:"すぱいらる－じーにあす",e:"SPYRAL Quik-Fix",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「SPYRAL GEAR」カード１枚を手札に加える。\n②：このカードが墓地に存在し、自分フィールドに「SPYRAL－ダンディ」が存在する場合に手札を１枚捨てて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:1,lk:[],ta:[],al:[]},
  {n:"SPYRAL GEAR－ドローン",h:"すぱいらる　ぎあ－どろーん",e:"SPYRAL GEAR - Drone",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n相手のデッキの上からカードを３枚確認し、好きな順番でデッキの上に戻す。\n②：このカードをリリースし、自分フィールドの「SPYRAL」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は相手フィールドのカードの数×５００アップする。\nこの効果は相手ターンでも発動できる。\n③：自分の墓地からこのカードと「SPYRAL」カード１枚を除外し、自分の墓地の「SPYRAL－ダンディ」１体を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL GEAR－ビッグ・レッド",h:"すぱいらる　ぎあ－びっぐ・れっど",e:"SPYRAL GEAR - Big Red",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「SPYRAL」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\n②：装備モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタル・ドラゴン",h:"くりすたる・どらごん",e:"Krystal Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"①：１ターンに１度、このカードが戦闘を行った自分ターンのバトルステップに発動できる。\nデッキからドラゴン族・レベル８モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMレ・ベルマン",h:"えんためいとれ・べるまん",e:"Performapal Lebellman",t:"monster",a:"光",l:6,ps:1,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドのP召喚された全てのモンスターのレベルを１つ上げる。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:2600,tx:"①：１ターンに１度、１～５までの任意のレベルを宣言し、このカード以外の自分フィールドの「EM」モンスター１体を対象として発動できる。\nターン終了時まで、このカードのレベルを宣言したレベル分だけ下げ、対象のモンスターのレベルを宣言したレベル分だけ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO デッドリーガイ",h:"ですてにーひーろー　でっどりーがい",e:"Destiny HERO - Dangerous",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2600,tx:"「D-HERO」モンスター＋闇属性の効果モンスター\n「D-HERO デッドリーガイ」の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\n手札・デッキから「D-HERO」モンスター１体を墓地へ送り、自分フィールドの全ての「D-HERO」モンスターの攻撃力はターン終了時まで、自分の墓地の「D-HERO」モンスターの数×２００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ライジング・ドラゴン",h:"れっど・らいじんぐ・どらごん",e:"Red Rising Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1600,tx:"悪魔族チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時、自分の墓地の「リゾネーター」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果を発動するターン、自分はドラゴン族・闇属性Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外し、自分の墓地のレベル１の「リゾネーター」モンスター２体を対象として発動できる。\nそのモンスター２体を特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河眼の光波刃竜",h:"ぎゃらくしーあいず・さいふぁー・ぶれーど・どらごん",e:"Galaxy-Eyes Cipher Blade Dragon",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:2800,tx:"レベル９モンスター×３\nこのカードは自分フィールドのランク８の「ギャラクシーアイズ」Xモンスターの上に重ねてX召喚する事もできる。\nこのカードはX召喚の素材にできない。\n①：１ターンに１度、このカードのX素材を１つ取り除き、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：X召喚したこのカードが相手モンスターの攻撃または相手の効果で破壊され墓地へ送られた場合、自分の墓地の「銀河眼の光波竜」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－ダンディ・バイプレイヤー",h:"まかいげきだん－だんでぃ・ばいぷれいやー",e:"Abyss Actor - Trendy Understudy",t:"monster",a:"闇",l:2,ps:8,pe:"①：自分がP召喚に成功した時に発動できる。\n自分のEXデッキから表側表示のレベル１またはレベル８の「魔界劇団」Pモンスター１体を手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:700,de:700,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：自分のPゾーンに「魔界劇団」カードが２枚存在する場合、このカードをリリースして発動できる。\n手札及び自分のEXデッキの表側表示のモンスターの中から、レベル１またはレベル８の「魔界劇団」Pモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・メタル・クロウ",h:"えんためいとおっどあいず・めたる・くろう",e:"Performapal Odd-Eyes Metal Claw",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「オッドアイズ」モンスター＋「EM」モンスター\nこのカードは融合素材にできない。\n①：「融合」の効果で融合召喚したこのカードは他のカードの効果を受けない。\n②：このカードの攻撃宣言時に発動する。\n自分フィールドの全てのモンスターの攻撃力はバトルフェイズ終了時まで３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMゴールド・ファング",h:"えんためいとごーるど・ふぁんぐ",e:"Performapal Gold Fang",t:"monster",a:"光",l:4,ps:3,pe:"①：１ターンに１度、自分の「EM」モンスターが戦闘で相手モンスターを破壊した場合に発動する。\n相手に１０００ダメージを与える。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:700,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動する。\n自分フィールドの「EM」モンスターの攻撃力はターン終了時まで２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドリルガイ",h:"ですてにーひーろー　どりるがい",e:"Destiny HERO - Drilldark",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「D-HERO ドリルガイ」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのカードの攻撃力以下の攻撃力を持つ「D-HERO」モンスター１体を手札から特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダイナマイトガイ",h:"ですてにーひーろー　だいなまいとがい",e:"Destiny HERO - Dynatag",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：モンスターが戦闘を行うダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージは０になり、お互いのプレイヤーは１０００ダメージを受ける。\n②：墓地のこのカードを除外し、自分フィールドの「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は次の相手ターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディシジョンガイ",h:"ですてにーひーろー　でぃしじょんがい",e:"Destiny HERO - Decider",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"「D-HERO ディシジョンガイ」の①③の効果はそれぞれデュエル中に１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのターンのエンドフェイズに、自分の墓地の「HERO」モンスター１体を選んで手札に加える。\n②：レベル６以上の相手モンスターはこのカードを攻撃対象に選択できない。\n③：このカードが墓地に存在し、自分にダメージを与える魔法・罠・モンスターの効果が発動した時に発動する。\nこのカードを手札に戻し、その効果で自分が受けるダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディストピアガイ",h:"ですてにーひーろー　でぃすとぴあがい",e:"Destiny HERO - Dystopia",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2400,tx:"「D-HERO」モンスター×２\n「D-HERO ディストピアガイ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地のレベル４以下の「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分のダメージを相手に与える。\n②：このカードの攻撃力が元々の攻撃力と異なる場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、このカードの攻撃力は元々の数値になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダークエンジェル",h:"ですてにーひーろー　だーくえんじぇる",e:"Destiny HERO - Dark Angel",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分の墓地の「D-HERO」モンスターが３体以上の場合、このカードを手札から捨て、自分の墓地の「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターを相手フィールドに守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分が発動した魔法カードの効果は無効化され破壊される。\n③：自分スタンバイフェイズに自分の墓地からこのカードと「D-HERO」モンスター１体を除外して発動できる。\nお互いのプレイヤーは、それぞれデッキから通常魔法カード１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディバインガイ",h:"ですてにーひーろー　でぃばいんがい",e:"Destiny HERO - Celestial",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃宣言時に、相手フィールドの表側表示の魔法カード１枚を対象として発動できる。\nそのカードを破壊し、相手に５００ダメージを与える。\n②：自分の手札が０枚の場合、自分の墓地からこのカードと「D-HERO」モンスター１体を除外して発動できる。\n自分はデッキから２枚ドローする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"D３",h:"でぃーきゅーびっく",e:"D Cubed",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「D３」の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動する。\nこのモンスターは表側表示で存在する間、「D-HERO」モンスターとしても扱う。\n②：手札を２枚まで捨てて発動できる。\n捨てた数だけ自分の手札・デッキ・墓地から「D３」を選んで特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「D-HERO」モンスターしか召喚・特殊召喚できない。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「D-HERO」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"D－フュージョン",h:"でぃー－ふゅーじょん",e:"D-Fusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードの効果で融合召喚する場合、「D-HERO」モンスターしか融合素材にできない。\n①：自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはこのターン、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－デビル・ヒール",h:"まかいげきだん－でびる・ひーる",e:"Abyss Actor - Evil Heel",t:"monster",a:"闇",l:8,ps:1,pe:"①：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体をリリースし、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分ダウンする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、自分フィールドの「魔界劇団」モンスターの数×１０００ダウンする。\n②：このカードが戦闘で相手モンスターを破壊した時、自分の墓地の「魔界台本」魔法カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－ファンキー・コメディアン",h:"まかいげきだん－ふぁんきー・こめでぃあん",e:"Abyss Actor - Funky Comedian",t:"monster",a:"闇",l:1,ps:8,pe:"①：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体をリリースし、自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:200,tx:"「魔界劇団－ファンキー・コメディアン」の②のモンスター効果は１ターンに１度しか使用できず、この効果を発動するターン、このカードは攻撃できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのカードの攻撃力はターン終了時まで、自分フィールドの「魔界劇団」モンスターの数×３００アップする。\n②：このカード以外の自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、このカードの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－ビッグ・スター",h:"まかいげきだん－びっぐ・すたー",e:"Abyss Actor - Superstar",t:"monster",a:"闇",l:7,ps:3,pe:"①：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体をリリースし、自分の墓地の「魔界台本」魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:1800,tx:"①：このカードの召喚・特殊召喚成功時に相手は魔法・罠カードの効果を発動できない。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「魔界台本」魔法カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはエンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－サッシー・ルーキー",h:"まかいげきだん－さっしー・るーきー",e:"Abyss Actor - Sassy Rookie",t:"monster",a:"闇",l:4,ps:2,pe:"①：自分フィールドの「魔界劇団」モンスターが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードは１ターンに１度だけ戦闘・効果では破壊されない。\n②：モンスターゾーンのこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「魔界劇団－サッシー・ルーキー」以外のレベル４以下の「魔界劇団」モンスター１体を特殊召喚する。\n③：このカードがPゾーンで破壊された場合、相手フィールドのレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－エキストラ",h:"まかいげきだん－えきすとら",e:"Abyss Actor - Extras",t:"monster",a:"闇",l:1,ps:3,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：相手フィールドにモンスターが存在する場合に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nデッキから「魔界劇団」Pモンスター１体を選び、自分のPゾーンに置く。\nこの効果の発動後、ターン終了時まで自分は「魔界劇団」モンスターしか特殊召喚できず、「魔界劇団－エキストラ」のP効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－プリティ・ヒロイン",h:"まかいげきだん－ぷりてぃ・ひろいん",e:"Abyss Actor - Leading Lady",t:"monster",a:"闇",l:4,ps:2,pe:"①：１ターンに１度、相手モンスターの攻撃で自分が戦闘ダメージを受けた時、以下の効果から１つを選択して発動できる。\n●その相手モンスターの攻撃力は、受けたダメージの数値分ダウンする。\n●自分のEXデッキから、受けたダメージの数値以下の攻撃力を持つ表側表示の「魔界劇団」Pモンスター１体を手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：１ターンに１度、自分または相手が戦闘ダメージを受けた時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、その戦闘ダメージの数値分ダウンする。\n②：モンスターゾーンのこのカードが戦闘または相手の効果で破壊された時に発動できる。\nデッキから「魔界台本」魔法カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－ワイルド・ホープ",h:"まかいげきだん－わいるど・ほーぷ",e:"Abyss Actor - Wild Hope",t:"monster",a:"闇",l:4,ps:2,pe:"①：１ターンに１度、もう片方の自分のPゾーンの「魔界劇団」カード１枚を対象として発動できる。\nそのカードのPスケールはターン終了時まで９になる。\nこの効果の発動後、ターン終了時まで自分は「魔界劇団」モンスターしか特殊召喚できない。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の②のモンスター効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力はターン終了時まで、自分フィールドの「魔界劇団」モンスターの種類×１００アップする。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「魔界劇団－ワイルド・ホープ」以外の「魔界劇団」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界台本「ファンタジー・マジック」",h:"まかいだいほん「ふぁんたじー・まじっく」",e:"Abyss Script - Fantasy Magic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nこのターン、そのモンスターとの戦闘で破壊されなかったモンスターは、ダメージステップ終了時に持ち主の手札に戻る。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界台本「オープニング・セレモニー」",h:"まかいだいほん「おーぷにんぐ・せれもにー」",e:"Abyss Script - Opening Ceremony",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分は自分フィールドの「魔界劇団」モンスターの数×５００LP回復する。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\n自分は手札が５枚になるようにデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界台本「火竜の住処」",h:"まかいだいほん「かりゅうのすみか」",e:"Abyss Script - Fire Dragon's Lair",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「魔界台本「火竜の住処」」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nこのターン、そのモンスターが戦闘で相手モンスターを破壊した場合、相手はエクストラデッキからモンスター３体を選んで除外する。\n②：自分のエクストラデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\n相手のエクストラデッキを確認し、その内の１枚を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界大道具「ニゲ馬車」",h:"まかいおおどうぐ「にげばしゃ」",e:"Abyss Prop - Wild Wagon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分の「魔界劇団」モンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n②：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\n相手ターン終了時まで、相手はそのモンスターを効果の対象にできない。\n③：自分のエクストラデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\n相手フィールドのカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界台本「魔王の降臨」",h:"まかいだいほん「まおうのこうりん」",e:"Abyss Script - Rise of the Abyss King",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの攻撃表示の「魔界劇団」モンスターの種類の数まで、フィールドの表側表示のカードを対象として発動できる。\nそのカードを破壊する。\n自分フィールドにレベル７以上の「魔界劇団」モンスターが存在する場合、このカードの発動に対して相手は効果を発動できない。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「魔界劇団」カードまたは「魔界台本」魔法カードを合計２枚まで手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団の楽屋入り",h:"まかいげきだんのがくやいり",e:"Abyss Actors Back Stage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のPゾーンに「魔界劇団」カードが２枚存在する場合に発動できる。\nデッキから「魔界劇団」Pモンスター２体を選び、自分のEXデッキに表側表示で加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使ルシフェル",h:"だてんしるしふぇる",e:"Darklord Morningstar",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは特殊召喚できない。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\n相手フィールドの効果モンスターの数まで、手札・デッキから「堕天使」モンスターを特殊召喚する。\n②：自分フィールドに他の「堕天使」モンスターが存在する限り、相手はこのカードを効果の対象にできない。\n③：１ターンに１度、自分メインフェイズに発動できる。\nフィールドの「堕天使」モンスターの数だけ、自分のデッキの上からカードを墓地へ送る。\n自分はこの効果で墓地へ送った「堕天使」カードの数×５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使イシュタム",h:"だてんしいしゅたむ",e:"Darklord Ixchel",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:2900,tx:"自分は「堕天使イシュタム」を１ターンに１度しか特殊召喚できず、その①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードと「堕天使」カード１枚を捨てて発動できる。\n自分はデッキから２枚ドローする。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使テスカトリポカ",h:"だてんしてすかとりぽか",e:"Darklord Tezcatlipoca",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"自分は「堕天使テスカトリポカ」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「堕天使」モンスターが戦闘・効果で破壊される場合、代わりに手札のこのカードを捨てる事ができる。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使マスティマ",h:"だてんしますてぃま",e:"Darklord Nasten",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:2600,tx:"自分は「堕天使マスティマ」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外の「堕天使」カード２枚を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使アムドゥシアス",h:"だてんしあむどぅしあす",e:"Darklord Amdusc",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:2800,tx:"自分は「堕天使アムドゥシアス」を１ターンに１度しか特殊召喚できず、その①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードと「堕天使」カード１枚を捨て、自分の墓地の「堕天使」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使の追放",h:"だてんしのついほう",e:"Banishment of the Darklords",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「堕天使の追放」以外の「堕天使」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使の戒壇",h:"だてんしのかいだん",e:"Darklord Contact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「堕天使の戒壇」は１ターンに１枚しか発動できない。\n①：自分の墓地の「堕天使」モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"背徳の堕天使",h:"はいとくのだてんし",e:"Darklord Rebellion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「背徳の堕天使」は１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、「堕天使」モンスター１体を墓地へ送って発動できる。\nフィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魅惑の堕天使",h:"みわくのだてんし",e:"Darklord Enchantment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、「堕天使」モンスター１体を墓地へ送って発動できる。\n相手フィールドの表側表示モンスター１体を選び、エンドフェイズまでコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"天空の女神 ジュノー",h:"てんくうのめがみ　じゅのー",e:"Juno, the Celestial Goddess",t:"monster",a:"光",l:8,ps:1,pe:"①：１ターンに１度、自分フィールドの表側表示の天使族Pモンスター３体を除外し、自分フィールドのPモンスター１体を対象として発動できる。\nこのターンそのモンスターが直接攻撃で相手のLPを０にした場合、自分はマッチに勝利する。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードを通常召喚する場合、天使族モンスター３体をリリースして召喚しなければならない。\nこのカードはP召喚でしか特殊召喚できない。\n自分フィールドにこのカード以外のモンスターが存在せず、このカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"異界より来たるシェルガ",h:"いかいよりきたるしぇるが",e:"Shelga, the Tri-Warlord",t:"monster",a:"水",l:7,ps:10,pe:"①：１ターンに１度、自分フィールドの表側表示のサイキック族Pモンスター３体を除外し、自分フィールドのPモンスター１体を対象として発動できる。\nこのターンそのモンスターが直接攻撃で相手のLPを０にした場合、自分はマッチに勝利する。",tr:"サイキック族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2800,de:2500,tx:"永き波闘の果て、溟海より目覚めしは、誇り高き闘者。\n\n※公式のデュエルでは使用できません。",li:"",lk:[],ta:[],al:[]},
  {n:"磁石の戦士δ",h:"まぐねっと・うぉりあー・でるた",e:"Delta The Magnet Warrior",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキからレベル４以下の「マグネット・ウォリアー」モンスター１体を墓地へ送る。\n②：このカードが墓地へ送られた場合、自分の墓地から「磁石の戦士δ」以外のレベル４以下の「マグネット・ウォリアー」モンスター３体を除外して発動できる。\n手札・デッキから「磁石の戦士マグネット・バルキリオン」１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"降竜の魔術師",h:"こうりゅうのまじゅつし",e:"Dragoncaller Magician",t:"monster",a:"闇",l:7,ps:2,pe:"①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの種族は相手ターン終了時までドラゴン族になる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの種族はターン終了時までドラゴン族になる。\n②：フィールドのこのカードを素材として融合・S・X召喚したモンスターは以下の効果を得る。\n●このカードがドラゴン族モンスターと戦闘を行うダメージステップの間、このカードの攻撃力は元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMリターンタンタン",h:"えんためいとりたーんたんたん",e:"Performapal Handstandaccoon",t:"monster",a:"地",l:2,ps:3,pe:"「EMリターンタンタン」のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの「EM」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこのターン、自分はこの効果で手札に戻したカード及びその同名カードの発動ができない。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:400,de:600,tx:"①：このカードが戦闘で破壊された時、フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"EMダグ・ダガーマン",h:"えんためいとだぐ・だがーまん",e:"Performapal Dag Daggerman",t:"monster",a:"地",l:5,ps:2,pe:"「EMダグ・ダガーマン」のP効果は１ターンに１度しか使用できない。\n①：このカードを発動したターンの自分メインフェイズに自分の墓地の「EM」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:600,tx:"「EMダグ・ダガーマン」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがP召喚に成功したターンの自分メインフェイズに手札から「EM」モンスター１体を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMラフメイカー",h:"えんためいとらふめいかー",e:"Performapal Laugh Maker",t:"monster",a:"光",l:8,ps:5,pe:"①：１ターンに１度、相手フィールドに元々の攻撃力より高い攻撃力を持つモンスターが存在する場合に発動できる。\n自分は１０００LP回復する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「EMラフメイカー」の①②のモンスター効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードの攻撃宣言時に発動できる。\nこのカードの攻撃力はバトルフェイズ終了時まで、このカード及び相手フィールドのモンスターの内、元々の攻撃力より高い攻撃力を持つモンスターの数×１０００アップする。\n②：元々の攻撃力より高い攻撃力を持つこのカードが戦闘・効果で破壊された場合、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SR－OMKガム",h:"すぴーどろいど－おーえむけーがむ",e:"Speedroid Gum Prize",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:800,tx:"①：自分・相手のバトルフェイズに自分が戦闘・効果でダメージを受けた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの効果でこのカードが特殊召喚に成功したバトルフェイズに発動できる。\nこのカードを含む自分フィールドの風属性モンスターのみをS素材としてS召喚する。\n③：このカードがS素材として墓地へ送られた場合に発動できる。\n自分のデッキの一番上のカードを墓地へ送り、そのカードが「スピードロイド」モンスターだった場合、このカードをS素材としたSモンスターの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"SRバンブー・ホース",h:"すぴーどろいどばんぶー・ほーす",e:"Speedroid Horse Stilts",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「スピードロイド」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\nデッキから風属性モンスター１体を墓地へ送る。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－アイス・ベル",h:"うぃんど・うぃっち－あいす・べる",e:"Windwitch - Ice Bell",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、デッキから「WW」モンスター１体を特殊召喚できる。\nこの効果でデッキから特殊召喚したモンスターはリリースできず、この効果を発動するターン、自分はレベル５以上の風属性モンスターしかEXデッキから特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－スノウ・ベル",h:"うぃんど・うぃっち－すのう・べる",e:"Windwitch - Snow Bell",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"①：自分フィールドに風属性モンスターが２体以上存在し、風属性以外のモンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードをS素材として風属性SモンスターをS召喚した場合、そのSモンスターは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"パラサイト・フュージョナー",h:"ぱらさいと・ふゅーじょなー",e:"Fusion Parasite",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは、このカードの①の効果を適用する場合のみ融合素材にできる。\n①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：このカードが特殊召喚に成功した場合に発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・チュチュボン",h:"さいばー・ちゅちゅぼん",e:"Cyber Tutubon",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：このカードは自分の手札・フィールドから戦士族または天使族モンスター１体をリリースして手札から特殊召喚できる。\n②：このカードが儀式召喚のためにリリースされた場合、自分の墓地の儀式魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"光波異邦臣",h:"さいふぁー・えとらんぜ",e:"Cipher Etranger",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの「サイファー」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「サイファー」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－桜に幕－",h:"かーでぃあん－さくらにまく－",e:"Flower Cardian Cherry Blossom with Curtain",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは通常召喚できない。\nこのカードの①の効果で特殊召喚できる。\n①：手札のこのカードを相手に見せて発動できる。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、このカードを特殊召喚する。\n違った場合、そのカードとこのカードを墓地へ送る。\n②：自分の「花札衛」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から捨てて発動できる。\nその戦闘を行う自分のモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械猟犬",h:"あんてぃーく・ぎあはうんどどっぐ",e:"Ancient Gear Hunting Hound",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードが召喚に成功した場合に発動する。\n相手に６００ダメージを与える。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n③：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドから、「アンティーク・ギア」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣モルモラット",h:"じゅうにししもるもらっと",e:"Zoodiac Ratpier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが召喚に成功した場合に発動できる。\nデッキから「十二獣」カード１枚を墓地へ送る。\n②：このカードを素材として持っている、元々の種族が獣戦士族のXモンスターは以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札・デッキから「十二獣モルモラット」１体を特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"十二獣ラビーナ",h:"じゅうにししらびーな",e:"Zoodiac Bunnyblast",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが戦闘・効果で破壊された場合、「十二獣ラビーナ」以外の自分の墓地の「十二獣」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードを素材として持っている、元々の種族が獣戦士族のXモンスターは以下の効果を得る。\n●このカードを対象とする相手の魔法カードの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣ヴァイパー",h:"じゅうにししう゛ぁいぱー",e:"Zoodiac Whiptail",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"①：自分フィールドの獣戦士族Xモンスター１体を対象として発動できる。\n自分の手札・フィールドのこのカードをそのモンスターの下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。\n②：このカードを素材として持っている、元々の種族が獣戦士族のXモンスターは以下の効果を得る。\n●このカードが相手モンスターと戦闘を行ったダメージ計算後に発動する。\nその相手モンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣サラブレード",h:"じゅうにししさらぶれーど",e:"Zoodiac Thoroughblade",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「十二獣」カード１枚を捨て、自分はデッキから１枚ドローする。\n②：このカードを素材として持っている、元々の種族が獣戦士族のXモンスターは以下の効果を得る。\n●このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣ラム",h:"じゅうにししらむ",e:"Zoodiac Ramram",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"①：このカードが戦闘・効果で破壊された場合、「十二獣ラム」以外の自分の墓地の「十二獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードを素材として持っている、元々の種族が獣戦士族のXモンスターは以下の効果を得る。\n●このカードを対象とする相手の罠カードの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜皇リトスアジムD",h:"しんりゅうおうりとすあじむでぃざすたー",e:"True King Lithosagym, the Disaster",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2500,de:2300,tx:"「真竜皇リトスアジムD」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカード以外の手札及び自分フィールドの表側表示モンスターの中から、地属性モンスターを含むモンスター２体を破壊し、このカードを手札から特殊召喚し、地属性モンスター２体を破壊した場合、相手のエクストラデッキを確認してその中からモンスターを３種類まで選んで除外できる。\n②：このカードが効果で破壊された場合に発動できる。\n自分の墓地から地属性以外の幻竜族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－リオン",h:"くりすとろん－りおん",e:"Crystron Rion",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"「水晶機巧－リオン」の効果は１ターンに１度しか使用できない。\n①：相手のメインフェイズ及びバトルフェイズに、チューナー以外の除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、そのモンスターとこのカードのみを素材として機械族Sモンスター１体をS召喚する。\nその時のS素材モンスターは墓地へは行かず持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－サルファフナー",h:"くりすとろん－さるふぁふなー",e:"Crystron Sulfefnir",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、手札から「水晶機巧－サルファフナー」以外の「クリストロン」カード１枚を捨てて発動できる。\nこのカードを守備表示で特殊召喚する。\nその後、自分フィールドのカード１枚を選んで破壊する。\n②：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「クリストロン」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂鳥－忍鴉",h:"えすぷりっと・ばーど－しのびがらす",e:"Shinobird Crow",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは特殊召喚できない。\n①：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、手札からスピリットモンスター１体を捨てて発動できる。\nこのカードの攻撃力・守備力はバトルフェイズ終了時まで、捨てたモンスターの攻撃力・守備力のそれぞれの数値分アップする。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂鳥－巫鶴",h:"えすぷりっと・ばーど－かんなぎづる",e:"Shinobird Crane",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードは特殊召喚できない。\n①：１ターンに１度、このカードがモンスターゾーンに存在し、このカード以外のスピリットモンスターが召喚・特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂鳥－伝鳩",h:"えすぷりっと・ばーど－つたえばと",e:"Shinobird Pigeon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードは特殊召喚できない。\n①：１ターンに１度、このカード以外のフィールドのスピリットモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の使者",h:"こんとんのししゃ",e:"Envoy of Chaos",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"①：自分・相手のバトルフェイズにこのカードを手札から捨て、自分フィールドの「カオス・ソルジャー」モンスターまたは「暗黒騎士ガイア」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１５００アップし、このターンそのモンスターと戦闘を行う相手モンスターの攻撃力はダメージ計算時のみ元々の攻撃力になる。\n②：自分・相手のエンドフェイズにこのカードが墓地に存在する場合、自分の墓地からこのカード以外の光属性と闇属性のモンスターを１体ずつ除外して発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊獣使い ウィンダ",h:"せいれいじゅうつかい　うぃんだ",e:"Spiritual Beast Tamer Winda",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"自分は「精霊獣使い ウィンダ」を１ターンに１度しか特殊召喚できない。\n①：このカードが相手によって破壊された場合に発動できる。\nデッキまたはエクストラデッキから「霊獣」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"創星神 tierra",h:"そうせいしん　てぃえら",e:"Tierra, Source of Destruction",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3400,de:3600,tx:"このカードは通常召喚できない。\nこのカード以外の自分の手札・フィールドのカード１０種類を持ち主のデッキ・エクストラデッキに戻した場合のみ特殊召喚できる。\n①：このカードの特殊召喚は無効化されない。\n②：このカードが特殊召喚に成功した場合に発動する。\nこのカード以外のお互いの手札・フィールド・墓地のカード及びエクストラデッキの表側表示のPモンスターを全て持ち主のデッキに戻す。\nこの効果の発動に対して魔法・罠・モンスターの効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻創のミセラサウルス",h:"げんそうのみせらさうるす",e:"Miscellaneousaurus",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"「幻創のミセラサウルス」の②の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズにこのカードを手札から墓地へ送って発動できる。\nそのメインフェイズの間、自分フィールドの恐竜族モンスターは相手が発動した効果を受けない。\n②：自分の墓地からこのカードを含む恐竜族モンスターを任意の数だけ除外して発動できる。\n除外したモンスターの数と同じレベルの恐竜族モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"見習い魔笛使い",h:"みならいまてきつかい",e:"Apprentice Piper",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:1500,tx:"①：このカードがリバースした場合に発動できる。\n手札からモンスター１体を特殊召喚する。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n手札からモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"河伯",h:"かわのかみ",e:"Hebo, Lord of the River",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["スピリット","効果"],ma:"",tp:"",at:1800,de:600,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースした場合、フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターはスピリットモンスター扱いとなり、エンドフェイズに持ち主の手札に戻る。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"横綱犬",h:"よこちゅな",e:"Yokotuner",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地からチューナー１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"百万喰らいのグラットン",h:"よろずぐらいのぐらっとん",e:"Eater of Millions",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分の手札・フィールド・EXデッキからカード５枚以上を裏側表示で除外した場合のみ特殊召喚できる。\n①：このカードの攻撃力・守備力は、裏側表示で除外されているカードの数×１００アップする。\n②：このカードはモンスターゾーンに存在する限り、リリースできず、融合・S・X召喚の素材にもできない。\n③：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターを裏側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイトプリンセス",h:"わいとぷりんせす",e:"Wightprincess",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：このカードのカード名は、墓地に存在する限り「ワイト」として扱う。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ワイトプリンス」１体を墓地へ送る。\n③：自分の手札・フィールドのこのカードを墓地へ送って発動できる。\nフィールドの全てのモンスターの攻撃力・守備力はターン終了時まで、そのレベルまたはランク×３００ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"メトロンノーム",h:"めとろんのーむ",e:"Metrognome",t:"monster",a:"地",l:4,ps:4,pe:"①：１ターンに１度、このカード以外の自分または相手のPゾーンのカード１枚を対象として発動できる。\nこのカードのPスケールはターン終了時まで、そのカードのPスケールと同じになる。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：自分のPゾーンにカードが２枚存在し、そのPスケールが同じ場合、このカードの攻撃力・守備力はそのPスケール×１００アップし、このカードは直接攻撃できる。\n②：このカードが直接攻撃によって相手に戦闘ダメージを与えた場合に発動する。\nお互いのPゾーンのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精伝姫－シンデレラ",h:"ふぇありーている－しんでれら",e:"Fairy Tail - Rella",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いにこのカード以外のフィールドのモンスターを魔法カードの効果の対象にできない。\n②：１ターンに１度、手札から魔法カード１枚を捨てて発動できる。\n自分の手札・デッキ・墓地から装備魔法カード１枚を選んでこのカードに装備する。\nその装備魔法カードはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エンジェル－那沙帝弥－",h:"さいばー・えんじぇる－なーさていや－",e:"Cyber Angel Natasha",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1000,de:1000,tx:"「機械天使の儀式」により降臨。\n①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力の半分だけ自分のLPを回復する。\n②：自分の儀式モンスターが攻撃対象に選択された時に発動できる。\nその攻撃を無効にする。\n③：このカードが墓地に存在する場合、自分の墓地からこのカード以外の「サイバー・エンジェル」モンスター１体を除外し、相手フィールドのモンスター１体を対象として発動できる。\nこのカードを墓地から特殊召喚し、対象のモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂鳥神－姫孔雀",h:"えすぷりっと・ろーど－ひめくじゃく",e:"Shinobaroness Peacock",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["儀式","スピリット","効果"],ma:"",tp:"",at:2500,de:3000,tx:"「霊魂の降神」により降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\n相手フィールドの魔法・罠カードを３枚まで選んで持ち主のデッキに戻す。\nその後、デッキからレベル４以下のスピリットモンスター１体を召喚条件を無視して特殊召喚できる。\n②：このカードが特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻し、自分フィールドに「霊魂鳥トークン」（鳥獣族・風・星４・攻／守１５００）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂鳥神－彦孔雀",h:"えすぷりっと・ろーど－ひこくじゃく",e:"Shinobaron Peacock",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["儀式","スピリット","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「霊魂の降神」により降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\n相手フィールドのモンスターを３体まで選んで持ち主の手札に戻す。\nその後、手札からレベル４以下のスピリットモンスター１体を召喚条件を無視して特殊召喚できる。\n②：このカードが特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻し、自分フィールドに「霊魂鳥トークン」（鳥獣族・風・星４・攻／守１５００）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイブアイズ・ペンデュラム・ドラゴン",h:"ぶれいぶあいず・ぺんでゅらむ・どらごん",e:"Brave-Eyes Pendulum Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「ペンデュラム・ドラゴン」モンスター＋戦士族モンスター\n①：このカードが融合召喚に成功した時に発動できる。\n相手フィールドの全てのモンスターの攻撃力は０になる。\nこのターン、このカード以外の自分のモンスターは攻撃できない。\n②：このカードがモンスターゾーンに存在する限り、攻撃力０のモンスターが発動した効果は無効化される。\n③：このカードの攻撃によって相手モンスターが破壊されなかったダメージステップ終了時に発動できる。\nその相手モンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－クリスタル・ベル",h:"うぃんど・うぃっち－くりすたる・べる",e:"Windwitch - Crystal Bell",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2400,tx:"「WW－ウィンター・ベル」＋「WW」モンスター\n「WW－クリスタル・ベル」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手の墓地のモンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはそのモンスターと同名カードとして扱い、同じ効果を得る。\n②：このカードが相手によって破壊され墓地へ送られた場合、自分の墓地の、「WW－ウィンター・ベル」１体とレベル４以下の「WW」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械混沌巨人",h:"あんてぃーく・ぎあ・かおす・じゃいあんと",e:"Chaos Ancient Gear Giant",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:3000,tx:"「アンティーク・ギア」モンスター×４\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、このカードは魔法・罠カードの効果を受けず、相手はバトルフェイズ中にモンスターの効果を発動できない。\n②：このカードは相手モンスター全てに１回ずつ攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械魔神",h:"あんてぃーく・ぎあ・でびる",e:"Ancient Gear Howitzer",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:1800,tx:"「アンティーク・ギア」モンスター×２\n「古代の機械魔神」の②の効果は１ターンに１度しか使用できない。\n①：このカードは他のカードの効果を受けない。\n②：自分メインフェイズに発動できる。\n相手に１０００ダメージを与える。\n③：このカードが戦闘で破壊され墓地へ送られた場合に発動できる。\nデッキから「アンティーク・ギア」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－ウィンター・ベル",h:"うぃんど・うぃっち－うぃんたー・べる",e:"Windwitch - Winter Bell",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"チューナー＋チューナー以外の風属性モンスター１体以上\n「WW－ウィンター・ベル」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「WW」モンスター１体を対象として発動できる。\nそのモンスターのレベル×２００ダメージを相手に与える。\n②：自分・相手のバトルフェイズに自分フィールドの「WW」モンスター１体を対象として発動できる。\nそのモンスターのレベル以下のレベルを持つモンスター１体を手札から特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超重忍者シノビ－A・C",h:"ちょうじゅうにんじゃしのび－あ・しー",e:"Superheavy Samurai Stealth Ninja",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1200,de:2800,tx:"機械族チューナー＋チューナー以外の機械族モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：自分の墓地に魔法・罠カードが存在しない場合に発動できる。\nこのカードの元々の守備力はターン終了時まで半分になり、このターンこのカードは直接攻撃できる。\n③：このカードが効果で破壊され墓地へ送られた場合、次のスタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－五光－",h:"かーでぃあん－ごこう－",e:"Flower Cardian Lightflare",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:5000,de:0,tx:"チューナー＋チューナー以外のモンスター４体\n①：１ターンに１度、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n②：自分の「花札衛」モンスターが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。\n③：S召喚したこのカードが戦闘で破壊された場合、または相手の効果でフィールドから離れた場合に発動できる。\nエクストラデッキから「花札衛－五光－」以外の「花札衛」Sモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"水晶機巧－グリオンガンド",h:"くりすとろん－ぐりおんがんど",e:"Crystron Quariongandrax",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"チューナー２体以上＋チューナー以外のモンスター１体\n①：このカードがS召喚に成功した場合、そのS素材としたモンスターの数まで相手のフィールド・墓地のモンスターを対象として発動できる。\nそのモンスターを除外する。\n②：S召喚したこのカードが戦闘・効果で破壊された場合、このカード以外の除外されている自分または相手のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎神－不知火",h:"ほむらがみ－しらぬい",e:"Shiranui Sunsaga",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:0,tx:"アンデット族チューナー＋チューナー以外のアンデット族モンスター１体以上\n自分は「炎神－不知火」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分の墓地のカード及び除外されている自分のカードの中から、アンデット族Sモンスターを任意の数だけ選んでエクストラデッキに戻す。\nその後、戻した数だけ相手フィールドのカードを選んで破壊できる。\n②：自分フィールドのアンデット族モンスターが戦闘・効果で破壊される場合、代わりに自分の墓地の「不知火」モンスター１体を除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王烈竜オッドアイズ・レイジング・ドラゴン",h:"はおうれつりゅうおっどあいず・れいじんぐ・どらごん",e:"Odd-Eyes Raging Dragon",t:"monster",a:"闇",l:7,ps:1,pe:"①：１ターンに１度、もう片方の自分のPゾーンにカードが存在しない場合に発動できる。\nデッキからPモンスター１体を選び、自分のPゾーンに置く。",tr:"ドラゴン族",mo:["エクシーズ","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"ドラゴン族レベル７モンスター×２\nレベル７がP召喚可能な場合にエクストラデッキの表側表示のこのカードはP召喚できる。\n①：Xモンスターを素材としてX召喚したこのカードは以下の効果を得る。\n●このカードは１度のバトルフェイズ中に２回攻撃できる。\n●１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドのカードを全て破壊し、このカードの攻撃力はターン終了時まで、破壊したカードの数×２００アップする。\n②：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"超銀河眼の光波龍",h:"ねお・ぎゃらくしーあいず・さいふぁー・どらごん",e:"Neo Galaxy-Eyes Cipher Dragon",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4500,de:3000,tx:"レベル９モンスター×３\n①：このカードが「サイファー」カードをX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を３つまで取り除いて発動できる。\n取り除いた数だけ相手フィールドの表側表示モンスターを選び、そのコントロールをエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターの効果は無効化され、攻撃力は４５００になり、カード名を「超銀河眼の光波龍」として扱う。\nこの効果の発動後、ターン終了時までこのカード以外の自分のモンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"重装甲列車アイアン・ヴォルフ",h:"じゅうそうこうれっしゃあいあん・う゛ぉるふ",e:"Heavy Armored Train Ironwolf",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"機械族レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの機械族モンスター１体を対象として発動できる。\nこのターン、自分はそのモンスターでしか攻撃できず、そのモンスターは直接攻撃できる。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\nデッキから機械族・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣ブルホーン",h:"じゅうにししぶるほーん",e:"Zoodiac Broadbull",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル４モンスター×２\n「十二獣ブルホーン」は１ターンに１度、同名カード以外の自分フィールドの「十二獣」モンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力・守備力は、このカードがX素材としている「十二獣」モンスターのそれぞれの数値分アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから通常召喚可能な獣戦士族モンスター１体を手札に加える。",li:0,lk:[],ta:[],al:[]},
  {n:"十二獣タイグリス",h:"じゅうにししたいぐりす",e:"Zoodiac Tigermortar",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル４モンスター×３\n「十二獣タイグリス」は１ターンに１度、同名カード以外の自分フィールドの「十二獣」モンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力・守備力は、このカードがX素材としている「十二獣」モンスターのそれぞれの数値分アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドのXモンスター１体と自分の墓地の「十二獣」モンスター１体を対象として発動できる。\nその「十二獣」モンスターをそのXモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣ドランシア",h:"じゅうにししどらんしあ",e:"Zoodiac Drident",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル４モンスター×４\n「十二獣ドランシア」は１ターンに１度、同名カード以外の自分フィールドの「十二獣」モンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力・守備力は、このカードがX素材としている「十二獣」モンスターのそれぞれの数値分アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:0,lk:[],ta:[],al:[]},
  {n:"十二獣ワイルドボウ",h:"じゅうにししわいるどぼう",e:"Zoodiac Boarbow",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル４モンスター×５\n「十二獣ワイルドボウ」は１ターンに１度、同名カード以外の自分フィールドの「十二獣」モンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力・守備力は、このカードがX素材としている「十二獣」モンスターのそれぞれの数値分アップする。\n②：このカードは相手に直接攻撃できる。\n③：持っているX素材の数が１２以上のこのカードが相手に戦闘ダメージを与えた時に発動できる。\n相手の手札・フィールドのカードを全て墓地へ送り、その後、このカードは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"機械天使の絶対儀式",h:"きかいてんしのぜったいぎしき",e:"Machine Angel Absolute Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「サイバー・エンジェル」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地から天使族または戦士族のモンスターをデッキに戻し、手札から「サイバー・エンジェル」儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RUM－光波昇華",h:"らんくあっぷまじっく－さいふぁー・あせんしょん",e:"Rank-Up-Magic Cipher Ascension",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分・相手のメインフェイズに、自分フィールドの「サイファー」Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが１つ高い「サイファー」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは以下の効果を得る。\n●このカードの攻撃力は、自分フィールドのレベル４以上のモンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"札再生",h:"ふださいせい",e:"Recardination",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「花札衛」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札から「花札衛」モンスター１体を召喚条件を無視して特殊召喚できる。\n②：このカードが「花札衛」モンスターの効果で墓地へ送られた場合に発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から魔法・罠カード１枚を選んで手札に加える事ができる。\n残りのカードは好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾディアックS",h:"ぞでぃあっくさいん",e:"Zodiac Sign",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「十二獣」モンスターの攻撃力・守備力は３００アップする。\n②：このカードがフィールドゾーンに存在する限り、自分フィールドの攻撃力が一番高い獣戦士族モンスター以外の自分の獣戦士族モンスターを相手は攻撃対象に選択できない。\n③：１ターンに１度、自分フィールドの「十二獣」モンスターが効果で破壊される場合、その「十二獣」モンスター１体の代わりに自分の手札・フィールドのモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣の会局",h:"じゅうにししのかいきょく",e:"Zoodiac Barrage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「十二獣の会局」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象としてこの効果を発動できる。\nそのカードを破壊し、デッキから「十二獣」モンスター１体を特殊召喚する。\n②：このカードが効果で破壊され墓地へ送られた場合、自分フィールドの「十二獣」Xモンスター１体を対象として発動できる。\n墓地のこのカードをそのXモンスターの下に重ねてX素材とする。",li:1,lk:[],ta:[],al:[]},
  {n:"霊魂の降神",h:"えすぷりっと・こーりんぐ",e:"Shinobird's Calling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「霊魂鳥神－姫孔雀」「霊魂鳥神－彦孔雀」の降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地のスピリットモンスターを除外し、手札から「霊魂鳥神－姫孔雀」または「霊魂鳥神－彦孔雀」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂の拠所",h:"えすぷりっと・ぱわーすぽっと",e:"Shinobird Power Spot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「霊魂の拠所」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのスピリットモンスターの攻撃力・守備力は５００アップする。\n②：自分フィールドの表側表示の風属性モンスターが自分の手札に戻った場合に発動できる。\nデッキからスピリットモンスター１体または儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超戦士の萌芽",h:"ちょうせんしのほうが",e:"Super Soldier Synthesis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス・ソルジャー」儀式モンスターの降臨に必要。\n「超戦士の萌芽」は１ターンに１枚しか発動できない。\n①：レベルの合計が８になるように、以下のどちらかの組み合わせのモンスターを墓地へ送り、自分の手札・墓地から「カオス・ソルジャー」儀式モンスター１体を儀式召喚する。\n\n●手札の光属性モンスター１体とデッキの闇属性モンスター１体\n●手札の闇属性モンスター１体とデッキの光属性モンスター１体",li:"",lk:[],ta:[],al:[]},
  {n:"超量必殺アルファンボール",h:"ちょうりょうひっさつあるふぁんぼーる",e:"Super Quantal Alphan Spike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「超量士」モンスターが３種類以上存在する場合に発動できる。\n相手フィールドのカードを全て持ち主のデッキに戻す。\nその後、相手はエクストラデッキからモンスター１体を召喚条件を無視して特殊召喚する。\n②：自分の墓地からこのカードと「超量妖精アルファン」１体を除外して発動できる。\nデッキから「超量機艦マグナキャリア」１枚を発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊獣の誓還",h:"れいじゅうのせいかん",e:"Ritual Beast Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札から「霊獣」モンスター１体を除外し、自分の墓地のモンスターまたは除外されている自分のモンスターの中から、「霊獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"おろかな副葬",h:"おろかなふくそう",e:"Foolish Burial Goods",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"隣の芝刈り",h:"となりのしばかり",e:"That Grass Looks Greener",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキの枚数が相手よりも多い場合に発動できる。\nデッキの枚数が相手と同じになるように、自分のデッキの上からカードを墓地へ送る。",li:2,lk:[],ta:[],al:[]},
  {n:"端末世界NEXT",h:"たーみなるわーるどねくすと",e:"Terminal World NEXT",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールドに他のカードが存在せず、相手フィールドのモンスターが３体以下で、相手フィールドの魔法・罠カードが３枚以下の場合にこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、お互いのフィールドに出せるモンスターはそれぞれ３体までになり、お互いのフィールドに出せる魔法・罠カードはそれぞれ３枚までになる。",li:"",lk:[],ta:[],al:[]},
  {n:"迷い風",h:"まよいかぜ",e:"Lost Wind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：特殊召喚された表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果は無効化され、元々の攻撃力は半分になる。\n②：このカードが墓地に存在し、相手のEXデッキからモンスターが特殊召喚された場合に発動できる。\nこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"光波分光",h:"さいふぁー・すぺくとらむ",e:"Cipher Spectrum",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのX素材を持った「サイファー」Xモンスターが戦闘または相手の効果で破壊され自分の墓地へ送られた場合、そのモンスター１体を対象として発動できる。\nそのモンスターを墓地から特殊召喚し、そのモンスターと同名のXモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械蘇生",h:"あんてぃーく・ぎありぼーん",e:"Ancient Gear Reborn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「古代の機械蘇生」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、自分フィールドにモンスターが存在しない場合、自分の墓地の「アンティーク・ギア」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣の方合",h:"じゅうにししのほうごう",e:"Zoodiac Combo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「十二獣」Xモンスター１体を対象として発動できる。\nデッキから「十二獣」モンスター１体を選び、そのXモンスターの下に重ねてX素材とする。\n②：墓地のこのカードを除外し、自分の墓地の「十二獣」カード５枚を対象として発動できる（同名カードは１枚まで）。\nそのカード５枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"霊魂の円環",h:"えすぷりっと・ひーりんぐ",e:"Shinobird Salvation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「霊魂の円環」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在し、自分フィールドの表側表示のスピリットモンスターが自分の手札に戻った場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：相手モンスターの攻撃宣言時に自分の墓地のスピリットモンスター１体を除外して発動できる。\nその攻撃を無効にし、その後バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"天地開闢",h:"てんちかいびゃく",e:"Beginning of Heaven and Earth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「カオス・ソルジャー」モンスターまたは「暗黒騎士ガイア」モンスター１体以上を含む戦士族モンスター３体を相手に見せ、相手はその中からランダムに１体選ぶ。\nそれが「カオス・ソルジャー」モンスターまたは「暗黒騎士ガイア」モンスターだった場合、そのモンスターを自分の手札に加え、残りのカードは全て墓地へ送る。\n違った場合、相手に見せたカードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火流 輪廻の陣",h:"しらぬいりゅう　りんねのじん",e:"Shiranui Style Samsara",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは魔法＆罠ゾーンに存在する限り、カード名を「不知火流 転生の陣」として扱う。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示のアンデット族モンスター１体を除外して発動できる。\nこのターン、自分が受ける全てのダメージは０になる。\n●除外されている自分の守備力０のアンデット族モンスター２体を対象として発動できる。\nそのモンスター２体をデッキに戻してシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスペクター・ガスト",h:"まじぇすぺくたー・がすと",e:"Majespecter Gust",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のPゾーンの「マジェスペクター」カード１枚を対象として発動できる。\nそのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の狂宴",h:"れんごくのきょうえん",e:"Void Feast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札及び自分フィールドの表側表示の魔法・罠カードの中から、「煉獄」魔法・罠カード１枚を墓地へ送って発動できる。\nレベルの合計が８になるように、デッキから「インフェルノイド」モンスターを３体まで選んで召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魂源への影劫回帰",h:"ぷるしゃどーる・あいおーん",e:"Purushaddoll Aeon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「シャドール」モンスター１体を対象として発動できる。\n手札から「シャドール」カード１枚を墓地へ送る。\n対象のモンスターは攻撃力・守備力が１０００アップし、エンドフェイズに裏側守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"影のデッキ破壊ウイルス",h:"かげのでっきはかいういるす",e:"Full Force Virus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの守備力２０００以上の闇属性モンスター１体をリリースして発動できる。\n相手フィールドのモンスター、相手の手札、相手ターンで数えて３ターンの間に相手がドローしたカードを全て確認し、その内の守備力１５００以下のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スウィッチヒーロー",h:"すうぃっちひーろー",e:"Switcheroroo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いのフィールドのモンスターの数が同じ場合、そのモンスターのコントロールを全て入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"肥大化",h:"ひだいか",e:"Massivemorph",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は倍になり、直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻想の見習い魔導師",h:"げんそうのみならいまどうし",e:"Apprentice Illusion Magician",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1700,tx:"①：このカードは手札を１枚捨てて、手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ブラック・マジシャン」１体を手札に加える。\n③：このカード以外の自分の魔法使い族・闇属性モンスターが相手モンスターと戦闘を行うダメージ計算時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその自分のモンスターの攻撃力・守備力はそのダメージ計算時のみ２０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"灼銀の機竜",h:"どらっぐ・おん・う゛ぁーみりおん",e:"Vermillion Dragon Mech",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分の手札・墓地及び自分フィールドの表側表示モンスターの中からチューナー１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：S召喚したこのカードが効果で破壊され墓地へ送られた場合、除外されている自分のチューナー１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"調星のドラッグスター",h:"ちょうせいのどらっぐすたー",e:"Space Dragster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（機械族・チューナー・炎・星１・攻０／守１８００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚したこのカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドのチューナーは戦闘及び相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーの射手",h:"さぶてらーのしゃしゅ",e:"Subterror Nemesis Archer",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにこのカード以外の「サブテラー」モンスターが存在し、このカードが相手の裏側守備表示モンスターを攻撃したダメージステップ開始時に発動できる。\nその相手モンスターを持ち主のデッキに戻す。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「サブテラー」モンスター１体を表側守備表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・アクエドリア",h:"さぶてらーまりす・あくえどりあ",e:"Subterror Behemoth Stygokraken",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"海竜族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:2600,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合、自分フィールドの「サブテラーマリス」モンスターの数だけ、フィールドにセットされたカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・バレスアッシュ",h:"さぶてらーまりす・ばれすあっしゅ",e:"Subterror Behemoth Ultramafus",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"炎族",mo:["リバース","効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\nこのカード以外のフィールドの表側表示モンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリスの潜伏",h:"さぶてらーまりすのせんぷく",e:"Subterror Behemoth Burrowing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地から「サブテラー」モンスター１体を除外して発動できる。\nターン終了時まで、自分フィールドの裏側表示モンスターは効果では破壊されず、相手の効果の対象にならない。\n②：フィールドのこのカードが効果で破壊された場合に発動できる。\nデッキから「サブテラー」モンスター１体を手札に加える。\n③：墓地のこのカードを除外し、自分フィールドの「サブテラー」モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"「RESORT」 STAFF－チャーミング",h:"りぞーと　すたっふ－ちゃーみんぐ",e:"Charming Resort Staff",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：自分の「SPYRAL－ダンディ」が相手の表側表示モンスターと戦闘を行う攻撃宣言時に発動できる。\nその相手モンスターの攻撃力を０にする。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「SPYRAL－ダンディ」１体を特殊召喚する。\n③：このカードが墓地に存在し、自分フィールドの表側表示の「SPYRAL－ダンディ」が、戦闘で破壊された場合、または墓地へ送られた場合、墓地のこのカードを除外して発動できる。\n自分の墓地の「SPYRAL－ダンディ」１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL－グレース",h:"すぱいらる－ぐれーす",e:"SPYRAL Master Plan",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:2800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「SPYRAL MISSION」カード１枚を手札に加える。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「SPYRAL－グレース」以外の「SPYRAL」モンスター１体と「SPYRAL RESORT」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL MISSION－強襲",h:"すぱいらる　みっしょん－きょうしゅう",e:"SPYRAL MISSION - Assault",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは発動後、３回目の自分エンドフェイズに破壊される。\n①：１ターンに１度、自分の「SPYRAL」モンスターが戦闘でモンスターを破壊した場合、または自分フィールドの「SPYRAL」モンスターの効果でフィールドのカードを破壊した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：墓地のこのカードを除外して発動できる。\n手札から「SPYRAL」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL RESORT",h:"すぱいらる　りぞーと",e:"SPYRAL Resort",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは、自分エンドフェイズ毎に自分の墓地のモンスター１体をデッキに戻す。\nまたはデッキに戻さずにこのカードを破壊する。\n①：このカードがフィールドゾーンに存在する限り、このカード以外の自分フィールドの「SPYRAL」カードは相手の効果の対象にならない。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「SPYRAL」モンスター１体を手札に加える。",li:2,lk:[],ta:[],al:[]},
  {n:"クリアウィング・ファスト・ドラゴン",h:"くりあうぃんぐ・ふぁすと・どらごん",e:"Clear Wing Fast Dragon",t:"monster",a:"風",l:7,ps:4,pe:"「クリアウィング・ファスト・ドラゴン」のP効果は１ターンに１度しか使用できない。\n①：レベルの合計が７になるように、自分フィールドの表側表示の、「スピードロイド」チューナー１体とチューナー以外のモンスター１体を墓地へ送って発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"ドラゴン族",mo:["シンクロ","ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外の風属性モンスター１体以上\n「クリアウィング・ファスト・ドラゴン」の①のモンスター効果は１ターンに１度しか使用できない。\n①：エクストラデッキから特殊召喚された相手フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力は０になり、効果は無効化される。\nこの効果は相手ターンでも発動できる。\n②：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"SRパッシングライダー",h:"すぴーどろいどぱっしんぐらいだー",e:"Speedroid Passinglider",t:"monster",a:"風",l:5,ps:3,pe:"①：１ターンに１度、手札及び自分フィールドの表側表示モンスターの中から、「スピードロイド」チューナー１体を墓地へ送って発動できる。\nターン終了時まで、墓地へ送ったそのモンスターの元々のレベル分だけこのカードのPスケールを上げる、または下げる（最小１まで）。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2200,de:2000,tx:"「SRパッシングライダー」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：お互いのフィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードがアドバンス召喚に成功した時、自分の墓地のレベル４以下の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n③：このカードがモンスターゾーンに存在する限り、相手は他の「スピードロイド」モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物セラセニアント",h:"ぷれでたー・ぷらんつせらせにあんと",e:"Predaplant Sarraceniant",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"「捕食植物セラセニアント」の③の効果は１ターンに１度しか使用できない。\n①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを破壊する。\n③：フィールドのこのカードが効果で墓地へ送られた場合、または戦闘で破壊された場合に発動できる。\nデッキから「捕食植物セラセニアント」以外の「プレデター」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物ドロソフィルム・ヒドラ",h:"ぷれでたー・ぷらんつどろそふぃるむ・ひどら",e:"Predaplant Drosophyllum Hydra",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:2300,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは自分または相手フィールドの捕食カウンターが置かれたモンスター１体をリリースした場合に手札・墓地から特殊召喚できる。\n②：このカードがフィールド・墓地に存在する場合、このカード以外の自分の墓地の「捕食植物」モンスター１体を除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物プテロペンテス",h:"ぷれでたー・ぷらんつぷてろぺんてす",e:"Predaplant Pterapenthes",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:2100,tx:"①：このカードが相手に戦闘ダメージを与えた時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターに捕食カウンターを１つ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。\n②：１ターンに１度、このカードのレベル以下のレベルを持つ相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物スピノ・ディオネア",h:"ぷれでたー・ぷらんつすぴの・でぃおねあ",e:"Predaplant Spinodionaea",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターに捕食カウンターを１つ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。\n②：このカードがこのカードのレベル以下のレベルを持つモンスターと戦闘を行ったダメージ計算後に発動できる。\nデッキから「捕食植物スピノ・ディオネア」以外の「捕食植物」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物サンデウ・キンジー",h:"ぷれでたー・ぷらんつさんでう・きんじー",e:"Predaplant Chlamydosundew",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分が融合素材とする捕食カウンターが置かれたモンスターの属性は闇属性として扱う。\n②：自分メインフェイズに発動できる。\n闇属性の融合モンスターカードによって決められた、フィールドのこのカードを含む融合素材モンスターを自分の手札・フィールド及び相手フィールドの捕食カウンターが置かれたモンスターの中から選んで墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物キメラフレシア",h:"ぷれでたー・ぷらんつきめらふれしあ",e:"Predaplant Chimerafflesia",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"植物族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「捕食植物」モンスター＋闇属性モンスター\n①：１ターンに１度、このカードのレベル以下のレベルを持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n②：このカードが相手の表側表示モンスターと戦闘を行う攻撃宣言時に発動できる。\nターン終了時まで、その相手モンスターの攻撃力は１０００ダウンし、このカードの攻撃力は１０００アップする。\n③：このカードが墓地へ送られた場合、次のスタンバイフェイズに発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"グリーディー・ヴェノム・フュージョン・ドラゴン",h:"ぐりーでぃー・う゛ぇのむ・ふゅーじょん・どらごん",e:"Greedy Venom Fusion Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3300,de:2500,tx:"「捕食植物」モンスター＋元々のレベルが８以上の闇属性モンスター\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\n①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力は０になり、効果は無効化される。\n②：このカードが破壊され墓地へ送られた場合に発動する。\nフィールドのモンスターを全て破壊する。\nその後、自分の墓地のレベル８以上の闇属性モンスター１体を除外してこのカードを墓地から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"プレデター・プランター",h:"ぷれでたー・ぷらんたー",e:"Predaponics",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは、自分スタンバイフェイズ毎に８００LPを払う。\nまたはLPを払わずにこのカードを破壊する。\n①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地からレベル４以下の「捕食植物」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食接ぎ木",h:"ぷれでたー・ぐらふと",e:"Predapruning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「捕食植物」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食惑星",h:"ぷれでたー・ぷらねっと",e:"Predaplanet",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「捕食惑星」の①の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在し、捕食カウンターが置かれているモンスターがフィールドから離れた場合に発動する。\nデッキから「プレデター」カード１枚を手札に加える。\n②：墓地のこのカードを除外して発動できる。\n自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で融合召喚する場合、「捕食植物」モンスターしか融合素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・オクト",h:"ふぁーにまる・おくと",e:"Fluffal Octopus",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"「ファーニマル・オクト」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、自分の墓地の、「ファーニマル」モンスターまたは「エッジインプ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが「デストーイ」融合モンスターの融合召喚の素材となって墓地へ送られた場合、除外されている自分のモンスターを２体まで対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・ペンギン",h:"ふぁーにまる・ぺんぎん",e:"Fluffal Penguin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1100,tx:"「ファーニマル・ペンギン」の②の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドに表側表示で存在する限り１度だけ、自分メインフェイズに発動できる。\n手札から「ファーニマル・ペンギン」以外の「ファーニマル」モンスター１体を特殊召喚する。\n②：このカードが「デストーイ」融合モンスターの融合召喚の素材となって墓地へ送られた場合に発動できる。\n自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・ハーケン・クラーケン",h:"ですとーい・はーけん・くらーけん",e:"Frightfur Kraken",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:3000,tx:"「エッジインプ」モンスター＋「ファーニマル」モンスター\n①：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送る。\nこの効果を発動するターン、このカードは直接攻撃できない。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動できる。\nこのカードを守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚師アレイスター",h:"しょうかんしあれいすたー",e:"Aleister the Invoker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"①：このカードを手札から墓地へ送り、自分フィールドの融合モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードが召喚・リバースした場合に発動できる。\nデッキから「召喚魔術」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣カリギュラ",h:"しょうかんじゅうかりぎゅら",e:"Invoked Caliga",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:1800,tx:"「召喚師アレイスター」＋闇属性モンスター\n①：このカードがモンスターゾーンに存在する限り、その間はお互いに、それぞれ１ターンに１度しかモンスターの効果を発動できず、それぞれバトルフェイズにモンスター１体でしか攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣ライディーン",h:"しょうかんじゅうらいでぃーん",e:"Invoked Raidjin",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:2400,tx:"「召喚師アレイスター」＋風属性モンスター\n①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣コキュートス",h:"しょうかんじゅうこきゅーとす",e:"Invoked Cocytus",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:1800,de:2900,tx:"「召喚師アレイスター」＋水属性モンスター\n①：このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードは表側守備表示のままで攻撃できる。\nその場合、攻撃力の数値を適用してダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣プルガトリオ",h:"しょうかんじゅうぷるがとりお",e:"Invoked Purgatrio",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:2000,tx:"「召喚師アレイスター」＋炎属性モンスター\n①：このカードの攻撃力は、相手フィールドのカードの数×２００アップする。\n②：このカードは相手モンスター全てに１回ずつ攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣メガラニカ",h:"しょうかんじゅうめがらにか",e:"Invoked Magellanica",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["融合"],ma:"",tp:"",at:3000,de:3300,tx:"「召喚師アレイスター」＋地属性モンスター",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣メルカバー",h:"しょうかんじゅうめるかばー",e:"Invoked Mechaba",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「召喚師アレイスター」＋光属性モンスター\n①：１ターンに１度、モンスターの効果・魔法・罠カードが発動した時、そのカードと同じ種類（モンスター・魔法・罠）の手札を１枚墓地へ送って発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣エリュシオン",h:"しょうかんじゅうえりゅしおん",e:"Invoked Elysium",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:4000,tx:"「召喚獣」モンスター＋エクストラデッキから特殊召喚されたモンスター\nこのカードは上記のカードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、このカードの属性は「闇」「地」「水」「炎」「風」としても扱う。\n②：１ターンに１度、自分のフィールド・墓地の「召喚獣」モンスター１体を対象として発動できる。\nそのモンスター及びそのモンスターと同じ属性を持つ相手フィールドのモンスターを全て除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"暴走魔法陣",h:"ぼうそうまほうじん",e:"Magical Meltdown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「召喚師アレイスター」１体を手札に加える事ができる。\n②：このカードがフィールドゾーンに存在する限り、融合モンスターを融合召喚する効果を含む効果を自分が発動した場合、その発動は無効化されず、その融合召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。",li:2,lk:[],ta:[],al:[]},
  {n:"召喚魔術",h:"しょうかんまじゅつ",e:"Invocation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：融合モンスターカードによって決められた融合素材モンスターを手札から墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n「召喚獣」融合モンスターを融合召喚する場合、自分フィールド及び自分・相手の墓地のモンスターを除外して融合素材とする事もできる。\n②：このカードが墓地に存在する場合、除外されている自分の「召喚師アレイスター」１体を対象として発動できる。\n墓地のこのカードをデッキに戻し、対象のモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"法の聖典",h:"ほうのせいてん",e:"The Book of the Law",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「法の聖典」は１ターンに１枚しか発動できない。\n①：自分フィールドの「召喚獣」モンスター１体をリリースして発動できる。\nリリースしたモンスターと元々の属性が異なる「召喚獣」モンスター１体を融合召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法名－「大いなる獣」",h:"まほうめい－「と・めが・せりおん」",e:"Omega Summon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：除外されている自分の「召喚獣」モンスターを任意の数だけ対象として発動できる（同名カードは１枚まで）。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"調弦の魔術師",h:"ちょうげんのまじゅつし",e:"Harmonizing Magician",t:"monster",a:"闇",l:4,ps:8,pe:"①：このカードがPゾーンに存在する限り、自分フィールドのモンスターの攻撃力・守備力は、自分のEXデッキの表側表示の「魔術師」Pモンスターの種類×１００アップする。",tr:"魔法使い族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはEXデッキからの特殊召喚はできず、このカードを融合・S・X召喚の素材とする場合、他の素材は全て「魔術師」Pモンスターでなければならない。\nこのカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが手札からのP召喚に成功した時に発動できる。\nデッキから「調弦の魔術師」以外の「魔術師」Pモンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、フィールドから離れた場合に除外される。",li:1,lk:[],ta:[],al:[]},
  {n:"幻影騎士団ミストクロウズ",h:"ふぁんとむ・ないつみすとくろうず",e:"The Phantom Knights of Mist Claws",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：除外されている自分の「幻影騎士団」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが墓地に存在する場合、相手の直接攻撃宣言時に自分の墓地のレベル４以下の「幻影騎士団」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、このカードはそのモンスターと同じ元々のレベルを持つ通常モンスター（戦士族・闇・攻／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"スピードリフト",h:"すぴーどりふと",e:"Speedlift",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターがチューナー１体のみの場合に発動できる。\nデッキからレベル４以下の「スピードロイド」モンスター１体を特殊召喚する。\nその特殊召喚成功時にお互いは魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食生成",h:"ぷれでたー・ぷらすと",e:"Predaplast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札の「プレデター」カードを任意の数だけ相手に見せ、見せた数だけ相手フィールドの表側表示モンスターを対象として発動できる。\nそのモンスターに捕食カウンターを１つずつ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。\n②：自分の「捕食植物」モンスターが戦闘で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オスティナート",h:"おすてぃなーと",e:"Ostinato",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\n自分の手札・デッキから、「幻奏」融合モンスターカードによって決められた融合素材モンスター２体を墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこのターンのエンドフェイズに、この効果で融合召喚したモンスターを破壊し、その融合素材モンスター一組が自分の墓地に揃っていれば、その一組を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"月光彩雛",h:"むーんらいと・かれいど・ちっく",e:"Lunalight Kaleido Chick",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、デッキ・EXデッキから「ムーンライト」モンスター１体を墓地へ送って発動できる。\nこのターン、このカードを融合素材とする場合、墓地へ送ったそのモンスターの同名カードとして融合素材にできる。\n②：このカードが効果で墓地へ送られた場合、自分の墓地の「融合」１枚を対象として発動できる。\nそのカードを手札に加える。\n③：このカードが除外された場合に発動できる。\nこのターン、相手はバトルフェイズ中に効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－グラス・ベル",h:"うぃんど・うぃっち－ぐらす・べる",e:"Windwitch - Glass Bell",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1500,tx:"「WW－グラス・ベル」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「WW－グラス・ベル」以外の「WW」モンスター１体を手札に加える。\nこの効果の発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－リサイト・スターリング",h:"りりかる・るすきにあ－りさいと・すたーりんぐ",e:"Lyrilusc - Recital Starling",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１モンスター×２体以上\n①：このカードがX召喚に成功した場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は、このカードのX素材の数×３００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから鳥獣族・レベル１モンスター１体を手札に加える。\n③：X召喚したこのカードの戦闘で発生する自分への戦闘ダメージは相手も受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者ダイ－８",h:"ちょうじゅうむしゃだい－はち",e:"Superheavy Samurai Wagon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"「超重武者ダイ－８」の③の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n③：自分の墓地に魔法・罠カードが存在しない場合に発動できる。\n自分フィールドの表側守備表示のこのカードを攻撃表示にし、デッキから「超重武者装留」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣の神颪",h:"ようせんじゅうのかみおろし",e:"Yosenju Oroshi Channeling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合、以下の効果から１つを選択して発動できる。\nこのカードの発動後、ターン終了時まで自分は「妖仙獣」モンスターしか特殊召喚できない。\n●デッキからレベル５以上の「妖仙獣」モンスター１体を手札に加える。\n●デッキから「妖仙獣 左鎌神柱」「妖仙獣 右鎌神柱」を１枚ずつ選び、自分のPゾーンに置く。\nこの効果でPゾーンに置かれたカードは次の相手エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－ティンクル・リトルスター",h:"まかいげきだん－てぃんくる・りとるすたー",e:"Abyss Actor - Twinkle Little Star",t:"monster",a:"闇",l:4,ps:9,pe:"①：自分は「魔界劇団」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に３回までモンスターに攻撃でき、対象のモンスター以外の自分のモンスターは攻撃できない。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードは自分ターンには戦闘では破壊されず、１度のバトルフェイズ中に３回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔玩具補綴",h:"ですとーい・ぱっちわーく",e:"Frightfur Patchwork",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「融合」１枚と「エッジインプ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"異形神の契約書",h:"いぎょうしんのけいやくしょ",e:"Dark Contract with the Entities",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに以下の種類の「DDD」モンスターがEXデッキから特殊召喚された時、それぞれの効果を１ターンに１度ずつ発動できる。\n\n●融合：自分は１０００LP回復する。\n\n●S：特殊召喚されたそのモンスターは相手の効果の対象にならない。\n\n●X：自分または相手の、フィールド・墓地のカード１枚を選んで除外する。\n\n●P：自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。\n\n②：自分スタンバイフェイズに発動する。\n自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－アーセナル・ファルコン",h:"れいど・らぷたーず－あーせなる・ふぁるこん",e:"Raidraptor - Arsenal Falcon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル７モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから鳥獣族・レベル４モンスター１体を特殊召喚する。\n②：「RR」モンスターをX素材として持っているこのカードは、その数まで１度のバトルフェイズ中に攻撃できる。\n③：「RR」モンスターをX素材として持っているこのカードが墓地へ送られた場合に発動できる。\nエクストラデッキから「RR－アーセナル・ファルコン」以外の「RR」Xモンスター１体を特殊召喚し、墓地のこのカードをそのXモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－月花見－",h:"かーでぃあん－つきはなみ－",e:"Flower Cardian Moonflowerviewing",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2000,de:2000,tx:"チューナー＋チューナー以外のモンスター２体\n①：１ターンに１度、自分メインフェイズに発動できる。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを召喚条件を無視して特殊召喚できる。\nこの効果で特殊召喚したモンスターはこのターン直接攻撃できる。\nこの効果を発動した場合、次の自分ターンのドローフェイズをスキップする。\n②：フィールドのこのカードをS素材とする場合、このカードを含む全てのS素材モンスターをレベル２モンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物ドラゴスタペリア",h:"ぷれでたー・ぷらんつどらごすたぺりあ",e:"Predaplant Dragostapelia",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"植物族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:1900,tx:"融合モンスター＋闇属性モンスター\n①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターに捕食カウンターを１つ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。\nこの効果は相手ターンでも発動できる。\n②：このカードがモンスターゾーンに存在する限り、相手が発動した捕食カウンターが置かれているモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"テセウスの魔棲物",h:"てせうすのませいぶつ",e:"Sea Monster of Theseus",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"アンデット族",mo:["融合","チューナー"],ma:"",tp:"",at:2200,de:1800,tx:"チューナー×２",li:"",lk:[],ta:[],al:[]},
  {n:"融合再生機構",h:"ゆうごうさいせいきこう",e:"Fusion Recycling Plant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札を１枚捨てて発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。\n②：自分・相手のエンドフェイズに、このターン融合召喚に使用した自分の墓地の融合素材モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーの刀匠",h:"さぶてらーのとうしょう",e:"Subterror Nemesis Defender",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"①：自分フィールドの裏側表示モンスター１体のみが相手の効果の対象になった時、または相手モンスターの攻撃対象に選択された時、手札・フィールドのこのカードを墓地へ送り、その裏側表示モンスター以外の自分フィールドのモンスター１体を対象として発動できる。\nその対象を正しい対象となるそのモンスターに移し替える。\n②：自分フィールドに裏側表示モンスターが存在する限り、このカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・アルラボーン",h:"さぶてらーまりす・あるらぼーん",e:"Subterror Behemoth Dragossuary",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"アンデット族",mo:["リバース","効果"],ma:"",tp:"",at:2400,de:1600,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\nこのターン、自分フィールドの「サブテラー」カードは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・ボルティニア",h:"さぶてらーまりす・ぼるてぃにあ",e:"Subterror Behemoth Voltelluric",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"雷族",mo:["リバース","効果"],ma:"",tp:"",at:1900,de:3000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合、相手フィールドの裏側表示モンスター１体を対象として発動できる。\nそのモンスターのコントロールを次の自分エンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーの激闘",h:"さぶてらーのげきとう",e:"Subterror Cave Clash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「サブテラー」モンスターの攻撃力・守備力は、フィールドの裏側表示モンスターの数×５００アップする。\n②：１ターンに１度、自分の「サブテラー」モンスターが相手に戦闘ダメージを与えた時、「サブテラーの激闘」以外の自分の墓地の「サブテラー」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYGAL－ミスティ",h:"すぱいぎゃる－みすてぃ",e:"SPYGAL Misty",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した場合、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手のデッキの一番上のカードをお互いに確認し、宣言した種類のカードだった場合、自分はデッキから１枚ドローする。\n②：１ターンに１度、自分フィールドの「SPYRAL－ダンディ」１体と相手フィールドのモンスター１体を対象として発動できる。\nそのモンスター２体を持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL－タフネス",h:"すぱいらる－たふねす",e:"SPYRAL Tough",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1500,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「SPYRAL－ダンディ」として扱う。\n②：１ターンに１度、カードの種類（モンスター・魔法・罠）を宣言し、相手フィールドのカード１枚を対象として発動できる。\n相手のデッキの一番上のカードをお互いに確認し、宣言した種類のカードだった場合、対象のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL GEAR－マルチワイヤー",h:"すぱいらる　ぎあ－まるちわいやー",e:"SPYRAL GEAR - Utility Wire",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「SPYRAL－ダンディ」が存在する場合、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL MISSION－奪還",h:"すぱいらる　みっしょん－だっかん",e:"SPYRAL MISSION - Recapture",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後、３回目の自分エンドフェイズに破壊される。\n①：１ターンに１度、自分フィールドに「SPYRAL」モンスターが特殊召喚された場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターは、このターン直接攻撃できない。\n②：自分フィールドの「SPYRAL」モンスターが戦闘・効果で破壊される場合、その破壊されるモンスター１体の代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMバラード",h:"えんためいとばらーど",e:"Performapal Ballad",t:"monster",a:"地",l:3,ps:2,pe:"①：１ターンに１度、自分の「EM」モンスターが相手の表側表示モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターの攻撃力は６００ダウンする。",tr:"植物族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:1100,tx:"①：自分の「EM」モンスターが攻撃したダメージ計算後に、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力は、その「EM」モンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMバラクーダ",h:"えんためいとばらくーだ",e:"Performapal Barracuda",t:"monster",a:"地",l:3,ps:5,pe:"①：１ターンに１度、自分の「EM」モンスターが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその相手モンスターの攻撃力は、その元々の攻撃力との差分だけダウンする。",tr:"植物族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:1100,tx:"「EMバラクーダ」のモンスター効果は１ターンに１度しか使用できない。\n①：元々の攻撃力と異なる攻撃力を持つ「EM」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、その元々の攻撃力との差分だけアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"SRドミノバタフライ",h:"すぴーどろいどどみのばたふらい",e:"Speedroid Dominobutterfly",t:"monster",a:"風",l:2,ps:8,pe:"「SRドミノバタフライ」の②のP効果は１ターンに１度しか使用できない。\n①：自分は風属性モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：手札から風属性モンスター１体を捨て、除外されている自分の風属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",tr:"機械族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:100,de:300,tx:"このカードをS素材とする場合、ドラゴン族・機械族の風属性モンスターのS召喚にしか使用できない。\nエクストラデッキから特殊召喚したこのカードは、S召喚に使用された場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・フュージョン",h:"ぺんでゅらむ・ふゅーじょん",e:"Pendulum Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ペンデュラム・フュージョン」は１ターンに１枚しか発動できない。\n①：融合モンスターカードによって決められた融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n自分のPゾーンにカードが２枚存在する場合、自分のPゾーンに存在する融合素材モンスターも融合素材に使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・デアデビル",h:"ですとーい・であでびる",e:"Frightfur Daredevil",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2200,tx:"「エッジインプ」モンスター＋「ファーニマル」モンスター\n「デストーイ・デアデビル」の②の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n相手に１０００ダメージを与える。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\n自分の墓地の「デストーイ」モンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・リニッチ",h:"ですとーい・りにっち",e:"Frightfur Reborn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「デストーイ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードを除外し、除外されている自分の、「ファーニマル」モンスターまたは「デストーイ」モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"R・R・R",h:"れいど・らぷたーず・れぷりか",e:"Raidraptor Replica",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードはルール上「RR」カードとしても扱う。\n①：自分フィールドの「RR」モンスター１体を対象として発動できる。\nその同名モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、相手は対象のモンスターを攻撃対象にできず、効果の対象にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖召喚",h:"れんさしょうかん",e:"Chain Summon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにXモンスターが２体以上存在する場合、その内のランクが一番低いXモンスター１体を対象として発動できる。\nそのモンスターよりランクが低いXモンスター１体をEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは直接攻撃できず、エンドフェイズにEXデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"ONiサンダー",h:"おにさんだー",e:"Brohunder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ONiサンダー」以外の雷族・光属性・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.28 タイタニック・モス",h:"なんばーず２８ たいたにっく・もす",e:"Number 28: Titanic Moth",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2200,tx:"レベル７モンスター×２\n①：自分フィールドに他のモンスターが存在しない場合、このカードは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。\n②：このカードが相手に戦闘ダメージを与えた時、このカードのX素材を１つ取り除いて発動できる。\n相手の手札の数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.70 デッドリー・シン",h:"なんばーず７０ でっどりー・しん",e:"Number 70: Malevolent Sin",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを次の相手スタンバイフェイズまで除外する。\n②：このカードが攻撃したダメージステップ終了時に発動できる。\nこのカードの攻撃力は３００アップし、ランクは３つ上がる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロイド・シンクロ",h:"ねくろいど・しんくろ",e:"Necroid Synchro",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：チューナー１体とチューナー以外のモンスター２体までを自分の墓地から選んで除外し、除外したモンスターのレベルの合計と同じレベルを持つ「スターダスト」Sモンスター１体をエクストラデッキからS召喚扱いで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"風来王 ワイルド・ワインド",h:"ふうらいおう　わいるど・わいんど",e:"Wandering King Wildwind",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"①：自分フィールドに攻撃力１５００以下の悪魔族チューナーが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したターン、自分はSモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから攻撃力１５００以下の悪魔族チューナー１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロコール",h:"しんくろこーる",e:"Synchro Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、そのモンスターを含む自分フィールドのモンスターのみを素材としてドラゴン族・悪魔族の闇属性Sモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"天輪の双星道士",h:"てんりんのそうせいどうし",e:"Celestial Double Star Shaman",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:100,de:800,tx:"チューナー＋チューナー以外のモンスター１体\n「天輪の双星道士」の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時に発動できる。\n自分の手札・墓地からチューナー以外のレベル２モンスターを４体まで選んで守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分はSモンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"牛頭鬼",h:"ごずき",e:"Gozuki",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキからアンデット族モンスター１体を墓地へ送る。\n②：このカードが墓地へ送られた場合、自分の墓地から「牛頭鬼」以外のアンデット族モンスター１体を除外して発動できる。\n手札からアンデット族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"熱き決闘者たち",h:"あつきでゅえりすとたち",e:"Legacy of the Duelist",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分のモンスターの攻撃宣言時に、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその攻撃を無効にし、対象のカードを破壊する。\n②：このカードが魔法＆罠ゾーンに存在する限り、お互いに１ターンに１枚しか魔法・罠カードを手札からセットできず、エクストラデッキから特殊召喚されたモンスターはそのターンには攻撃できない。\n③：自分ドローフェイズのドロー前に発動できる。\nこのターン通常のドローを行う代わりに、自分の墓地のモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"滅びの呪文－デス・アルテマ",h:"ほろびのじゅもん－です・あるてま",e:"Chaos Scepter Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにレベル８以上の魔法使い族モンスターが存在する場合に発動できる。\nフィールドのカード１枚を選んで裏側表示で除外する。\n②：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「マジシャン・オブ・ブラックカオス」または「混沌の黒魔術師」１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ディアバウンド・カーネル",h:"でぃあばうんど・かーねる",e:"Diabound Kernel",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃宣言時に発動する。\nこのカードの攻撃力は６００アップする。\n②：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、このカードの攻撃力分ダウンする。\nその後、このカードを次のターンのスタンバイフェイズまで除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィの羽根吹雪",h:"はーぴぃのはねふぶき",e:"Harpie's Feather Storm",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドに「ハーピィ」モンスターが存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドに鳥獣族・風属性モンスターが存在する場合に発動できる。\nターン終了時まで、相手が発動したモンスターの効果は無効化される。\n②：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\n自分のデッキ・墓地から「ハーピィの羽根帚」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO オネスティ・ネオス",h:"えれめんたるひーろー　おねすてぃ・ねおす",e:"Elemental HERO Honest Neos",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、相手ターンでも発動できる。\n①：このカードを手札から捨て、フィールドの「HERO」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで２５００アップする。\n②：手札から「HERO」モンスター１体を捨てて発動できる。\nこのカードの攻撃力はターン終了時まで、捨てたモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"スカイスクレイパー・シュート",h:"すかいすくれいぱー・しゅーと",e:"Skydive Scorcher",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「E・HERO」融合モンスター１体を対象として発動できる。\nそのモンスターより攻撃力が高い相手フィールドの表側表示モンスターを全て破壊する。\nその後、この効果で破壊され墓地へ送られたモンスターの内、元々の攻撃力が一番高いモンスターのその数値分のダメージを相手に与える。\n自分のフィールドゾーンに「摩天楼」フィールド魔法カードが存在する場合、相手に与えるダメージは、この効果で破壊され墓地へ送られたモンスター全ての元々の攻撃力の合計分となる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の召喚神",h:"あんこくのしょうかんしん",e:"Dark Summoning Beast",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれか１体を手札・デッキから召喚条件を無視して特殊召喚する。\nこのターン、自分のモンスターは攻撃できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれか１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"失楽園",h:"しつらくえん",e:"Fallen Paradise",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、自分のモンスターゾーンの「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」「混沌幻魔アーミタイル」は相手の効果の対象にならず、相手の効果では破壊されない。\n②：自分のモンスターゾーンに「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」「混沌幻魔アーミタイル」のいずれかが存在する場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"白のヴェール",h:"しろのう゛ぇーる",e:"White Veil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：装備モンスターが戦闘を行う攻撃宣言時に発動する。\n相手フィールドの表側表示の魔法・罠カードの効果はダメージステップ終了時まで無効化される。\n③：装備モンスターが戦闘で相手モンスターを破壊した時に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。\n④：魔法＆罠ゾーンの表側表示のこのカードがフィールドから離れた時に自分は３０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"アストログラフ・マジシャン",h:"あすとろぐらふ・まじしゃん",e:"Astrograph Sorcerer",t:"monster",a:"闇",l:7,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを破壊し、手札・デッキから「星読みの魔術師」１体を選び、自分のPゾーンに置くか特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：自分フィールドのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、このターンに破壊された自分または相手のモンスター１体を選び、その同名モンスター１体をデッキから手札に加える事ができる。\n②：自分の手札・フィールド・墓地の、「ペンデュラム・ドラゴン」「エクシーズ・ドラゴン」「シンクロ・ドラゴン」「フュージョン・ドラゴン」モンスター１体ずつと、フィールドのこのカードを除外して発動できる。\n「覇王龍ズァーク」１体を融合召喚扱いとしてEXデッキから特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"クロノグラフ・マジシャン",h:"くろのぐらふ・まじしゃん",e:"Chronograph Sorcerer",t:"monster",a:"闇",l:6,ps:8,pe:"「クロノグラフ・マジシャン」のP効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを破壊し、手札・デッキから「時読みの魔術師」１体を選び、自分のPゾーンに置くか特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:1700,tx:"①：自分フィールドのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、手札からモンスター１体を特殊召喚できる。\n②：フィールドのこのカードを除外し、自分の手札・フィールド・墓地から、「ペンデュラム・ドラゴン」「エクシーズ・ドラゴン」「シンクロ・ドラゴン」「フュージョン・ドラゴン」モンスターを１体ずつ除外して発動できる。\n「覇王龍ズァーク」１体を融合召喚扱いとしてエクストラデッキから特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"虹彩の魔術師",h:"こうさいのまじゅつし",e:"Double Iris Magician",t:"monster",a:"闇",l:4,ps:8,pe:"①：１ターンに１度、自分フィールドの魔法使い族・闇属性モンスター１体を対象として発動できる。\nこのターンそのモンスターが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。\nその後、このカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードはルール上「ペンデュラム・ドラゴン」カードとしても扱う。\n①：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「ペンデュラムグラフ」カード１枚を手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"黒牙の魔術師",h:"こくがのまじゅつし",e:"Black Fang Magician",t:"monster",a:"闇",l:4,ps:8,pe:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力をターン終了時まで半分にする。\nその後、このカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードはルール上「エクシーズ・ドラゴン」カードとしても扱う。\n①：このカードが戦闘・効果で破壊された場合、自分の墓地の魔法使い族・闇属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"白翼の魔術師",h:"はくよくのまじゅつし",e:"White Wing Magician",t:"monster",a:"風",l:4,ps:1,pe:"①：１ターンに１度、自分フィールドの魔法使い族・闇属性モンスターを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカードはルール上「シンクロ・ドラゴン」カードとしても扱う。\nP召喚したこのカードは、S召喚に使用された場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"紫毒の魔術師",h:"しどくのまじゅつし",e:"Purple Poison Magician",t:"monster",a:"闇",l:4,ps:1,pe:"①：１ターンに１度、自分の魔法使い族・闇属性モンスターが戦闘を行うダメージ計算前に発動できる。\nそのモンスターの攻撃力はダメージステップ終了時まで１２００アップする。\nその後、このカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:2100,tx:"このカードはルール上「フュージョン・ドラゴン」カードとしても扱う。\n①：このカードが戦闘・効果で破壊された場合、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"星霜のペンデュラムグラフ",h:"せいそうのぺんでゅらむぐらふ",e:"Star Pendulumgraph",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「星霜のペンデュラムグラフ」の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの魔法使い族モンスターを相手は魔法カードの効果の対象にできない。\n②：表側表示の「魔術師」Pモンスターカードが自分のモンスターゾーン・Pゾーンから離れた場合に発動する。\nデッキから「魔術師」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"時空のペンデュラムグラフ",h:"じくうのぺんでゅらむぐらふ",e:"Time Pendulumgraph",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「時空のペンデュラムグラフ」の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの魔法使い族モンスターを相手は罠カードの効果の対象にできない。\n②：自分のモンスターゾーン・Pゾーンの「魔術師」Pモンスターカード１枚と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果でカードを２枚破壊できなかった場合、フィールドのカード１枚を選んで墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"星刻の魔術師",h:"せいこくのまじゅつし",e:"Timestar Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"レベル４「魔術師」Pモンスター×２\nこのカードは上記のカードをX素材にしたX召喚でのみエクストラデッキから特殊召喚できる。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分のデッキ・墓地のモンスター及び自分のエクストラデッキの表側表示のPモンスターの中から、魔法使い族・闇属性モンスター１体を選んで手札に加える。\n②：１ターンに１度、自分のモンスターゾーン・PゾーンのPモンスターカードが戦闘・効果で破壊される場合、代わりに自分のデッキから魔法使い族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMスカイ・マジシャン",h:"えんためいとすかい・まじしゃん",e:"Performapal Sky Magician",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"「EMスカイ・マジシャン」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分が魔法カードを発動した場合に発動する。\nこのカードの攻撃力は３００アップする。\n②：自分フィールドの永続魔法カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nその後、手札から「魔術師」永続魔法カード１枚を発動できる。\nこの効果は相手ターンでも発動できる。\n③：表側表示のこのカードがフィールドから離れた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMスカイ・ピューピル",h:"えんためいとすかい・ぴゅーぴる",e:"Performapal Sky Pupil",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"「EMスカイ・ピューピル」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル５以上の「EM」モンスター１体を持ち主の手札に戻して発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手モンスターと戦闘を行う場合、ダメージステップ終了時までそのモンスターの効果は無効化される。\n③：自分フィールドに他の「EM」モンスターが存在する場合、このカードが相手モンスターに攻撃するダメージ計算前に発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMレビュー・ダンサー",h:"えんためいとれびゅー・だんさー",e:"Performapal Revue Dancer",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：「EM」モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMユーゴーレム",h:"えんためいとゆーごーれむ",e:"Performapal U Go Golem",t:"monster",a:"闇",l:4,ps:1,pe:"①：１ターンに１度、自分フィールドにモンスターが融合召喚された場合に発動できる。\n自分の墓地のPモンスター及び自分のエクストラデッキの表側表示のPモンスターの中から、「EM」モンスター、「オッドアイズ」モンスター、「魔術師」モンスターの内、いずれか１体を選んで手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードがP召喚に成功したターンの自分メインフェイズに１度だけ発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nその際、他の融合素材モンスターは全てドラゴン族モンスターでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"EM小判竜",h:"えんためいとどらご・りもーら",e:"Performapal Coin Dragon",t:"monster",a:"水",l:4,ps:5,pe:"①：１ターンに１度、エクストラデッキから特殊召喚された自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nこのターン、その自分のモンスターが相手モンスターと戦闘を行った場合、その相手モンスターはダメージ計算後に除外される。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドのドラゴン族モンスターは攻撃力が５００アップし、効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"SRビードロ・ドクロ",h:"すぴーどろいどびーどろ・どくろ",e:"Speedroid Skull Marbles",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:3000,tx:"「SRビードロ・ドクロ」の③の効果は１ターンに１度しか使用できない。\n①：自分・相手のスタンバイフェイズに、エクストラデッキから特殊召喚されたモンスターが相手フィールドに存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードは通常召喚されたモンスターとの戦闘では破壊されない。\n③：このカードの戦闘で発生する自分への戦闘ダメージは代わりに相手が受ける。\n④：自分フィールドに「スピードロイド」モンスター以外の表側表示モンスターが存在する場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"SRアクマグネ",h:"すぴーどろいどあくまぐね",e:"Speedroid Maliciousmagnet",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「SRアクマグネ」の効果は１ターンに１度しか使用できない。\nこのカードはこのカードの効果によるS召喚でしかS素材にできない。\n①：このカードが自分メインフェイズに召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとこのカードのみを素材として風属性Sモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SR５６プレーン",h:"すぴーどろいどふぁいぶしっくすぷれーん",e:"Speedroid Rubberband Plane",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"「SR５６プレーン」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで６００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物オフリス・スコーピオ",h:"ぷれでたー・ぷらんつおふりす・すこーぴお",e:"Predaplant Ophrys Scorpio",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札からモンスター１体を墓地へ送って発動できる。\nデッキから「捕食植物オフリス・スコーピオ」以外の「捕食植物」モンスター１体を特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"捕食植物ダーリング・コブラ",h:"ぷれでたー・ぷらんつだーりんぐ・こぶら",e:"Predaplant Darlingtonia Cobra",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"「捕食植物ダーリング・コブラ」の効果はデュエル中に１度しか使用できない。\n①：このカードが「捕食植物」モンスターの効果で特殊召喚に成功した場合に発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物コーディセップス",h:"ぷれでたー・ぷらんつこーでぃせっぷす",e:"Predaplant Cordyceps",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分スタンバイフェイズに墓地のこのカードを除外し、自分の墓地のレベル４以下の「捕食植物」モンスター２体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は通常召喚できず、融合モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－コバルト・スパロー",h:"りりかる・るすきにあ－こばると・すぱろー",e:"Lyrilusc - Cobalt Sparrow",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから鳥獣族・レベル１モンスター１体を手札に加える。\n②：フィールドのこのカードを素材としてX召喚した風属性モンスターは以下の効果を得る。\n●このカードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－サファイア・スワロー",h:"りりかる・るすきにあ－さふぁいあ・すわろー",e:"Lyrilusc - Sapphire Swallow",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに鳥獣族モンスターが存在する場合に発動できる。\nこのカードと鳥獣族・レベル１モンスター１体を手札から特殊召喚する。\n②：フィールドのこのカードを素材としてX召喚した風属性モンスターは以下の効果を得る。\n●このX召喚に成功した場合、自分の墓地の「LL」モンスター１体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－ターコイズ・ワーブラー",h:"りりかる・るすきにあ－たーこいず・わーぶらー",e:"Lyrilusc - Turquoise Warbler",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが手札からの特殊召喚に成功した場合に発動できる。\n自分の手札・墓地から「LL」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDゴースト",h:"でぃーでぃーごーすと",e:"D/D Ghost",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:300,tx:"①：このカードが墓地へ送られた場合、自分の墓地の、「DDゴースト」以外の「DD」モンスター１体または「契約書」カード１枚を対象として発動できる。\nその同名カード１枚をデッキから墓地へ送る。\n②：このカードが除外された場合、除外されている自分のカードの中から、「DDゴースト」以外の「DD」モンスター１体または「契約書」カード１枚を対象として発動できる。\nそのカードを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブル・リゾネーター",h:"だぶる・りぞねーたー",e:"Double Resonator",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「ダブル・リゾネーター」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターをチューナーとして扱う。\n②：墓地のこのカードを除外し、自分フィールドの悪魔族モンスター１体を対象として発動できる。\nこのターン、その悪魔族モンスターをチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王門零",h:"はおうもんぜろ",e:"Supreme King Gate Zero",t:"monster",a:"闇",l:7,ps:"",pe:"①：自分フィールドに「覇王龍ズァーク」が存在する場合、自分が受ける全てのダメージは０になる。\n②：１ターンに１度、もう片方の自分のPゾーンに「覇王門無限」が存在する場合に発動できる。\n自分のPゾーンのカード２枚を破壊し、デッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードとこのカードを破壊し、ドラゴン族の融合モンスターまたはドラゴン族のSモンスター１体をエクストラデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になり、効果は無効化され、S・X召喚の素材にできない。\n②：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王門無限",h:"はおうもんいんふぃにてぃ",e:"Supreme King Gate Infinity",t:"monster",a:"闇",l:7,ps:13,pe:"①：自分フィールドにモンスターが存在する場合、自分はP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、自分フィールドに「覇王龍ズァーク」が存在する場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分だけ自分のLPを回復する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードとこのカードを破壊し、ドラゴン族のXモンスターまたはドラゴン族のPモンスター１体をエクストラデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になり、効果は無効化され、S・X召喚の素材にできない。\n②：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王眷竜ダークヴルム",h:"はおうけんりゅうだーくう゛るむ",e:"Supreme King Dragon Darkwurm",t:"monster",a:"闇",l:4,ps:5,pe:"①：１ターンに１度、自分フィールドにモンスターが存在しない場合に発動できる。\nデッキから「覇王門」Pモンスター１体を選び、自分のPゾーンに置く。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしかP召喚できない。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード名の①②のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「覇王門」Pモンスター１体を手札に加える。\n②：このカードが墓地に存在し、自分フィールドにモンスターが存在しない場合に発動できる。\nこのカードを特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"真竜導士マジェスティM",h:"しんりゅうどうしまじぇすてぃめいでん",e:"Majesty Maiden, the True Dracocaster",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"このカードを表側表示でアドバンス召喚する場合、モンスターの代わりに自分フィールドの永続魔法・永続罠カードをリリースできる。\n①：１ターンに１度、アドバンス召喚したこのカードが存在し、相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nデッキから「真竜」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜戦士イグニスH",h:"しんりゅうせんしいぐにすひーと",e:"Ignis Heat, the True Dracowarrior",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードを表側表示でアドバンス召喚する場合、モンスターの代わりに自分フィールドの永続魔法・永続罠カードをリリースできる。\n①：１ターンに１度、アドバンス召喚したこのカードが存在し、相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nデッキから「真竜」永続魔法カード１枚を選び、手札に加えるか自分フィールドに発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜拳士ダイナマイトK",h:"しんりゅうけんしだいなまいとなっくる",e:"Dinomight Knight, the True Dracofighter",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードを表側表示でアドバンス召喚する場合、モンスターの代わりに自分フィールドの永続魔法・永続罠カードをリリースできる。\n①：１ターンに１度、アドバンス召喚したこのカードが存在し、相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nデッキから「真竜」永続罠カード１枚を選び、手札に加えるか自分フィールドに発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜騎将ドライアスⅢ世",h:"しんりゅうきしょうどらいあすさんせい",e:"Dreiath III, the True Dracocavalry General",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2100,de:2800,tx:"このカードを表側表示でアドバンス召喚する場合、モンスターの代わりに自分フィールドの永続魔法・永続罠カードをリリースできる。\n①：アドバンス召喚した表側表示のこのカードがフィールドから離れた場合に発動できる。\nデッキから「真竜」モンスター１体を守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、このカード以外のフィールドの「真竜」モンスターは相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜剣皇マスターP",h:"しんりゅうけんおうますたーぴーす",e:"Master Peace, the True Dracoslaying King",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2950,de:2950,tx:"このカードを表側表示でアドバンス召喚する場合、モンスターの代わりに自分フィールドの永続魔法・永続罠カードをリリースできる。\n①：このカードは、このカードのアドバンス召喚のためにリリースしたカードと元々の種類（モンスター・魔法・罠）が同じカードの効果を受けない。\n②：アドバンス召喚したこのカードが存在する場合、１ターンに１度、自分の墓地から永続魔法・永続罠カード１枚を除外し、このカード以外のフィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:0,lk:[],ta:[],al:[]},
  {n:"真竜機兵ダースメタトロン",h:"しんりゅうきへいだーすめたとろん",e:"Metaltron XII, the True Dracombatant",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードを通常召喚する場合、モンスター３体をリリースして召喚しなければならず、モンスターの代わりに自分フィールドの永続魔法・永続罠カードをリリースできる。\n①：このカードは、このカードのアドバンス召喚のためにリリースしたカードと元々の種類（モンスター・魔法・罠）が同じカードの効果を受けない。\n②：アドバンス召喚したこのカードが相手によって破壊された場合に発動できる。\n地・水・炎・風属性のいずれかの融合・S・Xモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜凰マリアムネ",h:"しんりゅうおうまりあむね",e:"Mariamne, the True Dracophoenix",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2700,de:2100,tx:"「真竜凰マリアムネ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカード以外の手札及び自分フィールドの表側表示モンスターの中から、風属性モンスターを含むモンスター２体を破壊し、このカードを手札から特殊召喚し、風属性モンスター２体を破壊した場合、相手のデッキの上からカードを４枚除外できる。\n②：このカードが効果で破壊された場合に発動できる。\nデッキから風属性以外の幻竜族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣クックル",h:"じゅうにししくっくる",e:"Zoodiac Kataroost",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"①：このカードが戦闘・効果で破壊された場合、「十二獣クックル」以外の自分の墓地の「十二獣」カード１枚を対象として発動できる。\nそのカードをデッキに戻す。\n②：このカードを素材として持っている、元々の種族が獣戦士族のXモンスターは以下の効果を得る。\n●このカードを対象とする相手モンスターの効果が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌龍 スパイラル",h:"げんおうりゅう　すぱいらる",e:"Phantasm Spiral Dragon",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"幻竜族",mo:["通常"],ma:"",tp:"",at:2900,de:2900,tx:"熾烈な戦渦を経た猛き龍。\n\n傷付いたその身は古の光に触れ、浸渦を遂げた。\n\nやがて、龍はその翼を広げ、天渦を制する煌となる。\n\nその新たなる煌は、夢か現か幻か。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－LEDバグ",h:"でじたる・ばぐ－れでぃばぐ",e:"Digital Bug LEDybug",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、表側攻撃表示のこのカードが守備表示になった時に発動できる。\nデッキから昆虫族・レベル３モンスター１体を手札に加える。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが戦闘でモンスターを破壊した時に自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"智天の神星龍",h:"せふぃら・とーら・ぐらまとん",e:"Zefraath",t:"monster",a:"地",l:11,ps:5,pe:"「智天の神星龍」のP効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキから「セフィラ」Pモンスター１体を選び、自分のエクストラデッキに表側表示で加え、このカードのPスケールをターン終了時まで、そのPモンスターのPスケールと同じにする。",tr:"岩石族",mo:["特殊召喚","ペンデュラム","効果"],ma:"",tp:"",at:3450,de:2950,tx:"このカードは通常召喚できない。\nこのカードがエクストラデッキに表側表示で存在し、「セフィラ」モンスター３体以上を含む自分フィールドのモンスターを全てリリースした場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功したターン、自分は通常のP召喚に加えて１度だけ、自分メインフェイズに「セフィラ」モンスターをP召喚できる。\n②：１ターンに１度、自分フィールドのモンスター１体をリリースして発動できる。\nデッキから「セフィラ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊衣の巫女 エリアル",h:"ねくろすのみこ　えりある",e:"Ariel, Priestess of the Nekroz",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"「影霊衣の巫女 エリアル」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、手札の「影霊衣」カードを任意の数だけ相手に見せて発動できる。\nターン終了時まで、見せたカードの数だけこのカードのレベルを上げる、または下げる。\n②：このカードが効果でリリースされた場合に発動できる。\nデッキから儀式モンスター以外の「影霊衣」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大戦艦 ビッグ・コアMk－Ⅲ",h:"きょだいせんかん　びっぐ・こあＭｋ－Ⅲ",e:"B.E.S. Big Core MK-3",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:1900,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から守備表示で特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカードにカウンターを３つ置く。\n③：このカードは戦闘では破壊されない。\n④：このカードが戦闘を行ったダメージステップ終了時に発動する。\nこのカードのカウンターを１つ取り除く。\n取り除けない場合このカードを破壊する。\n⑤：墓地のこのカードを除外して発動できる。\n自分の墓地の「巨大戦艦」モンスターを全てデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラムーチョ",h:"ぺんでゅらむーちょ",e:"Pendulumucho",t:"monster",a:"地",l:1,ps:"",pe:"①：このカードを発動したターンの自分メインフェイズに１度だけ、自分の墓地のモンスターまたは除外されている自分のモンスターの中から、「ペンデュラムーチョ」以外のPモンスター１体を対象として発動できる。\nそのPモンスターを自分のエクストラデッキに表側表示で加える。",tr:"鳥獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分のエクストラデッキから、「ペンデュラムーチョ」以外の表側表示のレベル１のPモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バオバブーン",h:"ばおばぶーん",e:"Baobaboon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んでデッキの一番上または一番下に戻す。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「バオバブーン」を任意の数だけ特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤークラッカー",h:"ふぁいやーくらっかー",e:"Fire Cracker",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"「ファイヤークラッカー」の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\n相手に１０００ダメージを与え、次の自分ドローフェイズをスキップする。\nこの効果は相手ターンでも発動できる。\n②：このカードがモンスターゾーンに存在する限り、相手が効果ダメージを受ける度にこのカードにカウンターを１つ置く。\n③：自分・相手のエンドフェイズに発動する。\nこのカードのカウンターを全て取り除き、その数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"灰流うらら",h:"はるうらら",e:"Ash Blossom & Joyous Spring",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：以下のいずれかの効果を含む魔法・罠・モンスターの効果が発動した時、このカードを手札から捨てて発動できる。\nその効果を無効にする。\n\n●デッキからカードを手札に加える効果\n●デッキからモンスターを特殊召喚する効果\n●デッキからカードを墓地へ送る効果",li:"",lk:[],ta:[],al:[]},
  {n:"憑依装着－ライナ",h:"ひょういそうちゃく－らいな",e:"Familiar-Possessed - Lyna",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「光霊使いライナ」１体と光属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した時に発動できる。\nデッキから「憑依装着－ライナ」以外の守備力１５００の魔法使い族モンスター１体を手札に加える。\n③：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精伝姫－カグヤ",h:"ふぇありーている－かぐや",e:"Fairy Tail - Luna",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから攻撃力１８５０の魔法使い族モンスター１体を手札に加える。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\n相手はそのモンスターの同名カード１枚を自身のデッキ・EXデッキから墓地へ送ってこの効果を無効にできる。\n墓地へ送らなかった場合、このカードと対象のモンスターを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王龍ズァーク",h:"はおうりゅうずぁーく",e:"Supreme King Z-ARC",t:"monster",a:"闇",l:12,ps:1,pe:"①：このカードがPゾーンに存在する限り、相手フィールドの融合・S・Xモンスターは効果を発動できない。\n②：１ターンに１度、相手がドローフェイズ以外でデッキからカードを手札に加えた時に発動できる。\nそのカードを破壊する。",tr:"ドラゴン族",mo:["融合","ペンデュラム","効果"],ma:"",tp:"",at:4000,de:4000,tx:"ドラゴン族の融合・S・X・Pモンスター１体ずつ合計４体\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合に発動する。\n相手フィールドのカードを全て破壊する。\n②：このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n③：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nデッキ・EXデッキから「覇王眷竜」モンスター１体を特殊召喚する。\n④：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"EMガトリングール",h:"えんためいとがとりんぐーる",e:"Performapal Gatlinghoul",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:900,tx:"「EM」モンスター＋レベル５以上の闇属性モンスター\n「EMガトリングール」の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合に発動できる。\nフィールドのカードの数×２００ダメージを相手に与える。\nこのカードがPモンスターを素材として融合召喚していた場合、さらに相手フィールドのモンスター１体を選んで破壊し、そのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－インディペンデント・ナイチンゲール",h:"りりかる・るすきにあ－いんでぃぺんでんと・ないちんげーる",e:"Lyrilusc - Independent Nightingale",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:0,tx:"「LL－アセンブリー・ナイチンゲール」＋「LL」モンスター\n①：元々のカード名に「LL」を含むXモンスターを素材としてこのカードが融合召喚に成功した場合に発動できる。\nそのモンスターが持っていたX素材の数だけ、このカードのレベルを上げる。\n②：このカードの攻撃力はこのカードのレベル×５００アップし、このカードは他のカードの効果を受けない。\n③：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのレベル×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団カースド・ジャベリン",h:"ふぁんとむ・ないつかーすど・じゃべりん",e:"The Phantom Knights of Cursed Javelin",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:0,tx:"レベル２モンスター×２\n「幻影騎士団カースド・ジャベリン」の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力は０になり、効果は無効化される。\nこのカードが「幻影騎士団」カードをX素材としている場合、この効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－アセンブリー・ナイチンゲール",h:"りりかる・るすきにあ－あせんぶりー・ないちんげーる",e:"Lyrilusc - Assembled Nightingale",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１モンスター×２体以上\n①：このカードの攻撃力は、このカードのX素材の数×２００アップする。\n②：このカードは直接攻撃でき、X素材を持っているこのカードは、その数まで１度のバトルフェイズ中に攻撃できる。\n③：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nターン終了時まで、自分フィールドの「LL」モンスターは戦闘・効果では破壊されず、自分が受ける戦闘ダメージは０になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－エトランゼ・ファルコン",h:"れいど・らぷたーず－えとらんぜ・ふぁるこん",e:"Raidraptor - Stranger Falcon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル５モンスター×２\n①：このカードがXモンスターをX素材としている場合、以下の効果を得る。\n●１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、その元々の攻撃力分のダメージを相手に与える。\n②：このカードが相手によって破壊され墓地へ送られた場合、「RR－エトランゼ・ファルコン」以外の自分の墓地の「RR」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、このカードをそのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD磐石王ダリウス",h:"でぃーでぃーでぃーばんじゃくおうだりうす",e:"D/D/D Stone King Darius",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1000,tx:"悪魔族レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの「契約書」カード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手モンスターと戦闘を行うダメージ計算時、このカードのX素材を１つ取り除いて発動できる。\nこのカードはその戦闘では破壊されず、ダメージ計算後にその相手モンスターを破壊し、相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜皇V.F.D.",h:"しんりゅうおうざ・びーすと",e:"True King of All Calamities",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル９モンスター×２体以上\n①：１ターンに１度、このカードのX素材を１つ取り除き、属性を１つ宣言して発動できる。\nこのターン、以下の効果を適用する。\nこの効果は相手ターンでも発動できる。\n●フィールドの表側表示モンスターは宣言した属性になり、宣言した属性の相手モンスターは攻撃できず、効果を発動できない。\n②：このカードがモンスターゾーンに存在する限り、自分の手札の「真竜」モンスターの効果で破壊するモンスターを相手フィールドからも選ぶ事ができる。",li:0,lk:[],ta:[],al:[]},
  {n:"十二獣ハマーコング",h:"じゅうにししはまーこんぐ",e:"Zoodiac Hammerkong",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル４モンスター×３体以上\n「十二獣ハマーコング」は１ターンに１度、同名カード以外の自分フィールドの「十二獣」モンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力・守備力は、このカードがX素材としている「十二獣」モンスターのそれぞれの数値分アップする。\n②：X素材を持ったこのカードがモンスターゾーンに存在する限り、相手はこのカード以外のフィールドの「十二獣」モンスターを効果の対象にできない。\n③：自分・相手のエンドフェイズに発動する。\nこのカードのX素材を１つ取り除く。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣ライカ",h:"じゅうにししらいか",e:"Zoodiac Chakanine",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル４モンスター×２体以上\n「十二獣ライカ」は１ターンに１度、同名カード以外の自分フィールドの「十二獣」モンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力・守備力は、このカードがX素材としている「十二獣」モンスターのそれぞれの数値分アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の「十二獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、効果が無効化され、X召喚の素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔術師の右手",h:"まじゅつしのみぎて",e:"Magician's Right Hand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドに魔法使い族モンスターが存在する場合、相手が発動した魔法カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔術師の左手",h:"まじゅつしのひだりて",e:"Magician's Left Hand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドに魔法使い族モンスターが存在する場合、相手が発動した罠カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔術師の再演",h:"まじゅつしのさいえん",e:"Magician's Restage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドに表側表示で存在する限り１度だけ、自分の墓地のレベル３以下の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「魔術師の再演」以外の「魔術師」永続魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超越融合",h:"ちょうえつゆうごう",e:"Ultra Polymerization",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動に対してカードの効果は発動できない。\n①：２０００LPを払って発動できる。\n融合モンスターカードによって決められた融合素材モンスター２体を自分フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外し、このカードの効果で融合召喚したモンスター１体を対象として発動できる。\nそのモンスターの融合召喚に使用した融合素材モンスター一組を自分の墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になり、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴニックD",h:"どらごにっくだいあぐらむ",e:"Dragonic Diagram",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「真竜」モンスターの攻撃力・守備力は３００アップする。\n②：このカードがフィールドゾーンに存在する限り、アドバンス召喚した「真竜」モンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n③：１ターンに１度、自分メインフェイズに発動できる。\nこのカード以外の自分の手札・フィールドのカード１枚を選んで破壊し、デッキから「真竜」カード１枚を手札に加える。",li:1,lk:[],ta:[],al:[]},
  {n:"真竜の継承",h:"しんりゅうのけいしょう",e:"True Draco Heritage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「真竜の継承」の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのターンにフィールドから墓地へ送られた「真竜」カードの種類（モンスター・魔法・罠）の数だけ、自分はデッキからドローする。\n②：自分メインフェイズに発動できる。\n「真竜」モンスター１体を表側表示でアドバンス召喚する。\n③：このカードが魔法＆罠ゾーンから墓地へ送られた場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜凰の使徒",h:"しんりゅうおうのしと",e:"Disciples of the True Dracophoenix",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「真竜凰の使徒」の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「真竜」カード３枚を対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\n②：自分メインフェイズに発動できる。\n「真竜」モンスター１体を表側表示でアドバンス召喚する。\n③：このカードが魔法＆罠ゾーンから墓地へ送られた場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌の都 パシフィス",h:"げんおうのみやこ　ぱしふぃす",e:"Pacifis, the Phantasm City",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのカード名はルール上「海」として扱う。\nこのカードの効果を発動するターン、自分は効果モンスターを召喚・特殊召喚できない。\n①：１ターンに１度、自分が通常モンスター１体の召喚・特殊召喚に成功した場合に発動する。\nデッキから「幻煌龍」カード１枚を手札に加える。\n②：自分フィールドにトークンが存在せず、相手が魔法・罠・モンスターの効果を発動した場合に発動できる。\n自分フィールドに「幻煌龍トークン」（幻竜族・水・星８・攻／守２０００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌龍の螺旋突",h:"げんおうりゅうのすぱいらる・くらっしゅ",e:"Phantasm Spiral Crash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"通常モンスターにのみ装備可能。\n「幻煌龍の螺旋突」の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：装備モンスターが相手に戦闘ダメージを与えた時に発動できる。\n自分の手札・デッキ・墓地から「幻煌龍 スパイラル」１体を選んで特殊召喚し、このカードをそのモンスターに装備する。\nその後、相手フィールドの攻撃表示モンスター１体を選んで守備表示にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌龍の螺旋絞",h:"げんおうりゅうのすぱいらる・ほーるど",e:"Phantasm Spiral Grip",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"通常モンスターにのみ装備可能。\n「幻煌龍の螺旋絞」の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力は５００アップする。\n②：装備モンスターが戦闘で相手モンスターを破壊した時に発動できる。\n自分の手札・デッキ・墓地から「幻煌龍 スパイラル」１体を選んで特殊召喚し、このカードをそのモンスターに装備する。\nその後、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌龍の螺旋波",h:"げんおうりゅうのすぱいらる・うぇーぶ",e:"Phantasm Spiral Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"通常モンスターにのみ装備可能。\n「幻煌龍の螺旋波」の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターは１ターンに１度だけ戦闘では破壊されない。\n②：装備モンスターが戦闘を行ったバトルフェイズ終了時に発動できる。\n自分の手札・デッキ・墓地から「幻煌龍 スパイラル」１体を選んで特殊召喚し、このカードをそのモンスターに装備する。\nその後、相手の手札がある場合、相手は手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"光虫信号",h:"ばぐ・しぐなる",e:"Bug Signal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「光虫信号」は１ターンに１枚しか発動できない。\n①：自分フィールドの昆虫族Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが２つ高い、またはランクが２つ低い昆虫族Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"セフィラの神意",h:"せふぃらのしんい",e:"Zefra Providence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「セフィラの神意」は１ターンに１枚しか発動できない。\n①：デッキから「セフィラの神意」以外の「セフィラ」カード１枚を手札に加える。\n②：自分フィールドの「セフィラ」カードが破壊される場合、代わりに墓地のこのカードを除外できる。\nこの効果はこのカードが墓地へ送られたターンには使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大要塞ゼロス",h:"きょだいようさいぜろす",e:"B.E.F. Zelos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、デッキから「ボスラッシュ」１枚を手札に加える事ができる。\n②：自分フィールドの「巨大戦艦」モンスターの攻撃力・守備力は５００アップし、相手の効果の対象にならず、相手の効果では破壊されない。\n③：１ターンに１度、自分メインフェイズに発動できる。\n手札から「巨大戦艦」モンスター１体を特殊召喚する。\n④：自分フィールドに「巨大戦艦」モンスターが召喚・特殊召喚された場合に発動する。\nそのモンスターに、自身の効果で使用するカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"デュエリスト・アドベント",h:"でゅえりすと・あどべんと",e:"Duelist Alliance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分または相手のPゾーンにカードが存在する場合に発動できる。\nデッキから「ペンデュラム」Pモンスター１体または「ペンデュラム」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"盆回し",h:"ぼんまわし",e:"Set Rotation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のデッキからカード名が異なるフィールド魔法カード２枚を選び、その内の１枚を自分フィールドにセットし、もう１枚を相手フィールドにセットする。\nこの効果でセットしたカードのいずれかがフィールドゾーンにセットされている限り、お互いに他のフィールド魔法カードを発動・セットできない。",li:1,lk:[],ta:[],al:[]},
  {n:"決別",h:"けつべつ",e:"Break Away",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手バトルフェイズに手札から魔法カード１枚を墓地へ送って発動できる。\nそのバトルフェイズを終了する。\nフィールドの表側表示モンスターはターン終了時まで効果が無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ロスト・ヴァンブレイズ",h:"ふぁんとむ・ないつろすと・う゛ぁんぶれいず",e:"The Phantom Knights of Lost Vambrace",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力は６００ダウンし、レベルは２になり、自分の「幻影騎士団」モンスターは戦闘では破壊されない。\nその後、このカードは通常モンスター（戦士族・闇・星２・攻６００／守０）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ウロング・マグネリング",h:"ふぁんとむ・ないつうろんぐ・まぐねりんぐ",e:"The Phantom Knights of Wrong Magnetring",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にする。\nその後、このカードは以下の効果を持つ効果モンスター（戦士族・闇・星２・攻／守０）となり、モンスターゾーンに攻撃表示で特殊召喚される（罠カードとしては扱わない）。\n●このカード及び自分フィールドの表側表示の、「幻影騎士団」モンスター１体または「ファントム」永続魔法・永続罠カード１枚を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"常闇の契約書",h:"とこやみのけいやくしょ",e:"Dark Contract with the Eternal Darkness",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分のPゾーンに「DD」カードが２枚存在する場合、相手はフィールドのモンスターを魔法・罠カードの効果の対象にできず、アドバンス召喚のリリースにできず、融合・S・X召喚の素材にもできない。\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜皇の復活",h:"しんりゅうおうのふっかつ",e:"True King's Return",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「真竜皇の復活」の①②③の効果はそれぞれ１ターンに１度しか使用できず、①②の効果は同一チェーン上では発動できない。\n①：自分の墓地の「真竜」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。\n②：相手メインフェイズに発動できる。\n「真竜」モンスター１体を表側表示でアドバンス召喚する。\n③：このカードが魔法＆罠ゾーンから墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"真竜の黙示録",h:"しんりゅうのもくしろく",e:"True Draco Apocalypse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「真竜の黙示録」の①②③の効果はそれぞれ１ターンに１度しか使用できず、①②の効果は同一チェーン上では発動できない。\n①：このカード以外の自分フィールドの「真竜」カード１枚を対象として発動できる。\nそのカードを破壊し、相手フィールドの全ての表側表示モンスターの攻撃力・守備力を半分にする。\n②：相手メインフェイズに発動できる。\n「真竜」モンスター１体を表側表示でアドバンス召喚する。\n③：このカードが魔法＆罠ゾーンから墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"十二獣の相剋",h:"じゅうにししのそうこく",e:"Zoodiac Gathering",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「十二獣の相剋」の①の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分の「十二獣」XモンスターがX素材を取り除いて効果を発動する場合、取り除くX素材を自分フィールドの他のXモンスターから取り除く事ができる。\n②：墓地のこのカードを除外し、自分フィールドの「十二獣」Xモンスター２体を対象として発動できる。\n対象のモンスターの１体を、もう１体の下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌龍の戦渦",h:"げんおうりゅうのせんか",e:"Phantasm Spiral Battle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールドに「海」が存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドのモンスターが通常モンスターのみの場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：墓地のこのカードを除外し、自分フィールドの通常モンスター１体を対象として発動できる。\nそのモンスターが装備可能な自分フィールドの全ての「幻煌龍」装備魔法カードをその通常モンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌龍の浸渦",h:"げんおうりゅうのしんか",e:"Phantasm Spiral Power",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールドに「海」が存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドのモンスターが通常モンスターのみの場合、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力・守備力が１０００ダウンし、効果が無効化される。\n②：墓地のこのカードを除外し、自分フィールドの通常モンスター１体を対象として発動できる。\n自分の手札・墓地から「幻煌龍」装備魔法カード１枚を選んでその通常モンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻煌龍の天渦",h:"げんおうりゅうのてんか",e:"Phantasm Spiral Assault",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールドに「海」が存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドの「幻煌龍 スパイラル」１体を対象として発動できる。\nそのモンスターが「幻煌龍」装備魔法カード３種類以上を装備した状態で、戦闘で相手の効果モンスター３体を破壊した時、自分はデュエルに勝利する。\n②：自分フィールドの通常モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊剣士の揺籃",h:"はかいけんしのようらん",e:"Prologue of the Destruction Swordsman",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「破壊剣士の揺籃」は１ターンに１枚しか発動できない。\n①：デッキから「破壊剣士の揺籃」以外の「破壊剣」カード１枚と「バスター・ブレイダー」モンスター１体を墓地へ送って発動できる。\n自分のエクストラデッキ・墓地から「破戒蛮竜－バスター・ドラゴン」１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに破壊される。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分フィールドの「破壊剣」カードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナミスト・ハウリング",h:"だいなみすと・はうりんぐ",e:"Dinomists Howling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードの発動時の効果処理として、デッキから「ダイナミスト」Pモンスターを２体まで選んで自分のPゾーンに置く事ができる。\n置いた場合、次のターンの終了時まで、自分は「ダイナミスト」モンスターしかP召喚できない。\n②：このカードが既に魔法＆罠ゾーンに表側表示で存在する場合、１ターンに１度、自分フィールドの「ダイナミスト」モンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"セフィラの星戦",h:"せふぃらのせいせん",e:"Zefra War",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「セフィラの星戦」は１ターンに１枚しか発動できない。\n自分のPゾーンに「セフィラ」カードが２枚存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドの「セフィラ」カード１枚と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幽麗なる幻滝",h:"ゆうれいなるげんろう",e:"Waterfall of Dragon Souls",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●デッキから幻竜族モンスター１体を手札に加える。\n\n●手札及び自分フィールドの表側表示モンスターの中から、幻竜族モンスターを任意の数だけ墓地へ送って発動できる。\n自分は墓地へ送ったモンスターの数＋１枚をデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"醒めない悪夢",h:"さめないあくむ",e:"Unending Nightmare",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の効果は同一チェーン上では１度しか発動できない。\n①：１０００LPを払い、フィールドの表側表示の魔法・罠カード１枚を対象としてこの効果を発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイヤモンドダストン",h:"だいやもんどだすとん",e:"Diamond Duston",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのカードが戦闘・効果で破壊された時に発動できる。\nその破壊されたカードの数だけ、デッキから「ダストン」モンスターを選んで自分・相手フィールドに特殊召喚する。\n②：このカードが墓地に存在する場合に１度だけ、自分の墓地の「ダストン」モンスター１体を除外して発動できる。\nこのカードは通常モンスター（悪魔族・闇・星１・攻０／守１０００）となり、相手のモンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはリリースできず、融合・S・X召喚の素材にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊竜ガンドラ－ギガ・レイズ",h:"はかいりゅうがんどら－ぎが・れいず",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\nこのカード以外の自分の手札・フィールドのモンスター２体を墓地へ送った場合に特殊召喚できる。\n①：このカードの攻撃力は除外されているカードの数×３００アップする。\n②：１ターンに１度、LPを半分払って発動できる。\n自分の墓地の「ガンドラ」モンスターの種類によって以下を適用する。\n●１種類：このカード以外のフィールドのカードを全て破壊する。\n●２種類：このカード以外のフィールドのカードを全て除外する。\n●３種類以上：このカード以外のお互いのフィールド・墓地のカードを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械巨人－アルティメット・パウンド",h:"あんてぃーく・ぎあごーれむ－あるてぃめっと・ぱうんど",e:"Ancient Gear Golem - Ultimate Pound",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは特殊召喚できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードの攻撃でモンスターを破壊した時、手札から機械族モンスター１体を捨てて発動できる。\nこのカードは続けて攻撃できる。\nこの効果は１ターンに２度まで使用できる。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「融合」１枚を手札に加え、自分の墓地からこのカード以外の「アンティーク・ギア」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"コズミック・ブレイザー・ドラゴン",h:"こずみっく・ぶれいざー・どらごん",e:"Cosmic Blazar Dragon",t:"monster",a:"風",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター２体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：フィールドのこのカードをエンドフェイズまで除外して以下の効果から１つを選択して発動できる。\n\n●相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。\n\n●相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にし、その後バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリア・エフェクター",h:"くりあ・えふぇくたー",e:"Clear Effector",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:900,tx:"①：このカードがS素材として墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。\n②：このカードをS素材としたSモンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"シューティング・ソニック",h:"しゅーてぃんぐ・そにっく",e:"Cosmic Flare",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「スターダスト」Sモンスター１体を対象として発動できる。\nこのターン、その自分のSモンスターが相手モンスターと戦闘を行う場合、ダメージステップ開始時にその相手モンスターを持ち主のデッキに戻す。\n②：自分フィールドの「スターダスト」Sモンスターが効果を発動するために自身をリリースする場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"集いし願い",h:"つどいしねがい",e:"Converging Wishes",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地にドラゴン族Sモンスターが５種類以上存在する場合に発動できる。\nエクストラデッキから「スターダスト・ドラゴン」１体をS召喚扱いで特殊召喚し、このカードを装備する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。\n②：装備モンスターの攻撃力は、自分の墓地のドラゴン族Sモンスターの攻撃力の合計分アップする。\n③：装備モンスターが戦闘で相手モンスターを破壊する度に、自分の墓地のドラゴン族Sモンスター１体を除外して発動できる。\n装備モンスターは相手モンスターに続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ぶつかり合う魂",h:"ぶつかりあうたましい",e:"Clashing Souls",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の攻撃表示モンスターが、そのモンスターより攻撃力が高い相手の攻撃表示モンスターと戦闘を行うダメージ計算時に発動できる。\nその戦闘を行うモンスターの内、攻撃力が低いモンスターのコントローラーは、５００LPを払ってそのモンスターの攻撃力をダメージ計算時のみ５００アップする事ができる。\nその後、お互いがLPを払わなくなるまでこの効果を繰り返す。\nその戦闘で発生するお互いの戦闘ダメージは０になり、ダメージ計算後にその戦闘でモンスターが破壊されたプレイヤーのフィールドのカードは全て墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"ムーンバリア",h:"むーんばりあ",e:"Light Wing Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：モンスターの攻撃が無効になった時、以下の効果から１つを選択して発動できる。\n\n●このターンのエンドフェイズになる。\n\n●自分フィールドの「希望皇ホープ」Xモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の攻撃力の倍になる。\n\n②：自分フィールドの「希望皇ホープ」XモンスターがX素材を１つ取り除いて効果を発動する場合、取り除くX素材の代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マスター・ピース",h:"ますたー・ぴーす",e:"Halfway to Forever",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター２体を対象として発動できる。\nそのモンスター２体を効果を無効にして特殊召喚し、その２体のみを素材として光属性の「ホープ」Xモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドン・サウザンドの契約",h:"どん・さうざんどのけいやく",e:"Contract with Don Thousand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「ドン・サウザンドの契約」は１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、お互いのプレイヤーは１０００LPを失い、それぞれデッキから１枚ドローする。\n②：お互いのプレイヤーは、このカードが魔法＆罠ゾーンに存在する間にドローしたカード及び、このカードの①の効果でドローしたカードを公開し続ける。\n③：このカードの効果で手札の魔法カードを公開しているプレイヤーは、モンスターを通常召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"螺旋のストライクバースト",h:"らせんのすとらいくばーすと",e:"Spiral Flame Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●自分フィールドに「オッドアイズ」カードが存在する場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n\n●デッキのモンスター及び自分のEXデッキの表側表示のPモンスターの中から、レベル７の「オッドアイズ」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エンタメデュエル",h:"えんためでゅえる",e:"Dueltaining",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、お互いのプレイヤーは１ターン中に以下の条件をそれぞれ満たす度に、１つの条件につき１ターンに１度ずつデッキから２枚ドローする。\n\n●レベルの異なるモンスター５体を同時に特殊召喚した。\n\n●自身のモンスター１体が５回戦闘を行った。\n\n●チェーン５以上でカードの効果を発動した。\n\n●サイコロを振った回数及びコイントスの回数が合計５回になった。\n\n●自身のLPが５００以下になるダメージを受けた。",li:"",lk:[],ta:[],al:[]},
  {n:"プチラノドン",h:"ぷちらのどん",e:"Petiteranodon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが効果で破壊され墓地へ送られた場合に発動する。\nデッキからレベル４以上の恐竜族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"究極伝導恐獣",h:"あるてぃめっとこんだくたーてぃらの",e:"Ultimate Conductor Tyranno",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"恐竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3200,tx:"このカードは通常召喚できない。\n自分の墓地の恐竜族モンスター２体を除外した場合に特殊召喚できる。\n①：１ターンに１度、自分・相手のメインフェイズに発動できる。\n自分の手札・フィールドのモンスター１体を選んで破壊し、相手フィールドの表側表示モンスターを全て裏側守備表示にする。\n②：このカードは相手モンスター全てに１回ずつ攻撃できる。\n③：このカードが守備表示モンスターを攻撃したダメージステップ開始時に発動できる。\n相手に１０００ダメージを与え、その守備表示モンスターを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"魂喰いオヴィラプター",h:"たまぐいおう゛ぃらぷたー",e:"Souleating Oviraptor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"「魂喰いオヴィラプター」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから恐竜族モンスター１体を選び、手札に加えるか墓地へ送る。\n②：このカード以外のフィールドのレベル４以下の恐竜族モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nその後、自分の墓地から恐竜族モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガロスマッシャーX",h:"めがろすまっしゃーえっくす",e:"Megalosmasher X",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"太古の大海原に突如として現れた恐竜型バイオノイド。\n自慢の消音装甲で獲物の背後に忍び寄り、音もなく喰らいつくが、捕食モードになると体が発光する仕様なのでよく逃げられてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"ロストワールド",h:"ろすとわーるど",e:"Lost World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：恐竜族以外のフィールドのモンスターの攻撃力・守備力は５００ダウンする。\n②：１ターンに１度、恐竜族モンスターが召喚・特殊召喚された場合に発動できる。\n相手フィールドに「ジュラエッグトークン」（恐竜族・地・星１・攻／守０）１体を守備表示で特殊召喚する。\n③：相手フィールドにトークンがある限り、相手はトークン以外のフィールドのモンスターを効果の対象にできない。\n④：１ターンに１度、フィールドの通常モンスターが戦闘・効果で破壊される場合、代わりにその数だけ自分の手札・デッキの恐竜族モンスターを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"生存境界",h:"せいぞんきょうかい",e:"Survival's End",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの通常モンスターを全て破壊し、破壊した数までデッキからレベル４以下の恐竜族モンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。\n②：墓地のこのカードを除外し、自分フィールドの恐竜族モンスター１体と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD烈火大王エグゼクティブ・テムジン",h:"でぃーでぃーでぃーれっかだいおうえぐぜくてぃぶ・てむじん",e:"D/D/D Flame High King Genghis",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2400,tx:"レベル５以上の「DD」モンスター＋「DD」モンスター\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在し、自分フィールドにこのカード以外の「DD」モンスターが召喚・特殊召喚された場合、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、自分ターンに魔法・罠カードの効果が発動した時に発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"FNo.0 未来皇ホープ－フューチャー・スラッシュ",h:"ふゅーちゃーなんばーず０ みらいおうほーぷ－ふゅーちゃー・すらっしゅ",e:"Number F0: Utopic Future Slash",t:"monster",a:"光",l:"",ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"「No.」モンスター以外の同じランクのXモンスター×２\nルール上、このカードのランクは１として扱う。\nこのカードは自分フィールドの、「希望皇ホープ」モンスターまたは「FNo.0 未来皇ホープ」の上に重ねてX召喚する事もできる。\n①：このカードの攻撃力は、お互いの墓地の「No.」Xモンスターの数×５００アップする。\n②：このカードは戦闘では破壊されない。\n③：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－レヴォリューション・ファルコン－エアレイド",h:"れいど・らぷたーず－れう゛ぉりゅーしょん・ふぁるこん－えあれいど",e:"Raidraptor - Revolution Falcon - Air Raid",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:3000,tx:"鳥獣族レベル６モンスター×３\nこのカードは手札の「RUM」魔法カード１枚を捨て、自分フィールドのランク５以下の「RR」Xモンスターの上に重ねてX召喚する事もできる。\n①：このカードがX召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、その攻撃力分のダメージを相手に与える。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\nエクストラデッキから「RR－レヴォリューション・ファルコン」１体を特殊召喚し、このカードを下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜巻竜",h:"とるねーどらごん",e:"Tornado Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:2000,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・スイッチ",h:"ぺんでゅらむ・すいっち",e:"Pendulum Switch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分のPゾーンのカード１枚を対象として発動できる。\nそのカードを特殊召喚する。\n②：自分のモンスターゾーンのPモンスター１体を対象として発動できる。\nそのPモンスターを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーの妖魔",h:"さぶてらーのようま",e:"Subterror Fiendess",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手が魔法・罠・モンスターの効果を発動した時、手札・フィールドのこのカードを墓地へ送り、自分フィールドの「サブテラー」モンスター１体を対象として発動できる。\nその発動を無効にする。\nその後、対象のモンスターを裏側守備表示にする。\n②：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にし、自分の手札・墓地の「サブテラー」モンスター１体を選んで表側守備表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・グライオース",h:"さぶてらーまりす・ぐらいおーす",e:"Subterror Behemoth Phospheroglacier",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"水族",mo:["リバース","効果"],ma:"",tp:"",at:2600,de:2500,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\nデッキからカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリス・エルガウスト",h:"さぶてらーまりす・えるがうすと",e:"Subterror Behemoth Speleogeist",t:"monster",a:"地",l:11,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:3000,de:1400,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターが守備表示の場合、表側攻撃表示にする。\nそのモンスターの攻撃力は０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーの決戦",h:"さぶてらーのけっせん",e:"Subterror Final Battle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n発動後このカードは墓地へ送らず、そのままセットする。\n\n●自分フィールドの裏側表示の「サブテラー」モンスター１体を選んで表側攻撃表示または表側守備表示にする。\n\n●フィールドの表側表示の「サブテラー」モンスター１体を選んで裏側守備表示にする。\n\n●フィールドの表側表示の「サブテラー」モンスター１体を選び、そのモンスターの攻撃力・守備力はターン終了時まで、その元々の攻撃力と守備力を合計した数値になる。\n\n●このターン、「サブテラー」カードの発動する効果は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL－ボルテックス",h:"すぱいらる－ぼるてっくす",e:"SPYRAL Sleeper",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地の「SPYRAL」カード３枚を除外した場合のみ特殊召喚できる。\n①：１ターンに１度、自分フィールドの「SPYRAL」カード１枚と、相手フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた場合に発動する。\n自分フィールドのカードを全て破壊し、自分の手札・デッキ・墓地から「SPYRAL－ダンディ」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL GEAR－ラスト・リゾート",h:"すぱいらる　ぎあ－らすと・りぞーと",e:"SPYRAL GEAR - Last Resort",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：自分メインフェイズに自分フィールドの「SPYRAL」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは戦闘・効果では破壊されず、相手の効果の対象にならない。\n②：１ターンに１度、このカードの効果でこのカードが装備されている場合、このカード以外の自分フィールドのカード１枚を墓地へ送って発動できる。\nこのターン、装備モンスターは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL GEAR－エクストラアームズ",h:"すぱいらる　ぎあ－えくすとらあーむず",e:"SPYRAL GEAR - Fully Armed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「SPYRAL－ダンディ」にのみ装備可能。\n①：装備モンスターの攻撃力は１０００アップする。\n②：装備モンスターが戦闘で相手モンスターを破壊した場合に発動できる。\n相手フィールドのカード１枚を選び、そのカードと破壊したモンスターを除外する。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた時、自分の墓地の「SPYRAL－ダンディ」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SPYRAL MISSION－救出",h:"すぱいらる　みっしょん－きゅうしゅつ",e:"SPYRAL MISSION - Rescue",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後、３回目の自分エンドフェイズに破壊される。\n①：「SPYRAL MISSION－救出」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、自分の墓地の「SPYRAL」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n③：墓地のこのカードを除外し、自分の墓地の「SPYRAL」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ビットロン",h:"びっとろん",e:"Bitron",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:200,de:2000,tx:"電子空間で見つけた新種。\nその情報量は少ない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラコネット",h:"どらこねっと",e:"Draconnet",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"①：このカードが召喚に成功した時に発動できる。\n手札・デッキからレベル２以下の通常モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RAMクラウダー",h:"らむくらうだー",e:"RAM Clouder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースし、自分の墓地のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リンクスレイヤー",h:"りんくすれいやー",e:"Linkslayer",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2000,de:600,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、手札を２枚まで捨て、捨てた数だけフィールドの魔法・罠カードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・ユニバース",h:"さいばねっと・ゆにばーす",e:"Cynet Universe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのリンクモンスターの攻撃力は３００アップする。\n②：１ターンに１度、自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。\n③：フィールドのこのカードが効果で破壊された場合に発動する。\nEXモンスターゾーンのモンスターを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"リンク・スパイダー",h:"りんく・すぱいだー",e:"Link Spider",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"通常モンスター１体\n①：１ターンに１度、自分メインフェイズに発動できる。\n手札からレベル４以下の通常モンスター１体をこのカードのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:["下"],ta:[],al:[]},
  {n:"ハニーボット",h:"はにーぼっと",e:"Honeybot",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"サイバース族モンスター２体\n①：このカードのリンク先のモンスターは効果の対象にならず、戦闘では破壊されない。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"デコード・トーカー",h:"でこーど・とーかー",e:"Decode Talker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"効果モンスター２体以上\n①：このカードの攻撃力は、このカードのリンク先のモンスターの数×５００アップする。\n②：自分フィールドのカードを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードのリンク先の自分のモンスター１体をリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"EMカード・ガードナー",h:"えんためいとかーど・がーどなー",e:"Performapal Card Gardna",t:"monster",a:"地",l:3,ps:8,pe:"①：１ターンに１度、自分フィールドの表側守備表示モンスター１体を対象として発動できる。\nそのモンスターの守備力は、自分フィールドの全ての表側守備表示モンスターの元々の守備力を合計した数値になる。",tr:"岩石族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードの守備力は、このカード以外の自分フィールドの「EM」モンスターの元々の守備力の合計分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジインプ・コットン・イーター",h:"えっじいんぷ・こっとん・いーたー",e:"Edge Imp Cotton Eater",t:"monster",a:"闇",l:7,ps:1,pe:"①：自分フィールドの融合モンスターの攻撃力は３００アップする。\n②：１ターンに１度、自分フィールドに「デストーイ」融合モンスターが融合召喚された場合に発動できる。\n自分はデッキから１枚ドローする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2400,de:1600,tx:"「エッジインプ・コットン・イーター」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分の墓地の「デストーイ」モンスターの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・ガジェット",h:"さいばーす・がじぇっと",e:"Cyberse Gadget",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1400,de:300,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地のレベル２以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分フィールドに「ガジェット・トークン」（サイバース族・光・星２・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・ウィザード",h:"さいばーす・うぃざーど",e:"Cyberse Wizard",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：１ターンに１度、相手フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にする。\nこの効果で守備表示にしたターン、自分のモンスターは対象のモンスターしか攻撃できず、自分のサイバース族モンスターが対象の守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"バックアップ・セクレタリー",h:"ばっくあっぷ・せくれたりー",e:"Backup Secretary",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドにサイバース族モンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スタック・リバイバー",h:"すたっく・りばいばー",e:"Stack Reviver",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードを素材としてリンク召喚した場合、このカード以外の自分の墓地の、そのリンク召喚の素材としたレベル４以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ランチャー・コマンダー",h:"らんちゃー・こまんだー",e:"Launcher Commander",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドのサイバース族モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分フィールドのサイバース族モンスター１体をリリースし、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サルベージェント・ドライバー",h:"さるべーじぇんと・どらいばー",e:"Salvagent Driver",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2200,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドのサイバース族リンクモンスターが相手の効果によって破壊された場合に発動できる。\nこのカードを特殊召喚する。\n②：手札から魔法カード１枚を捨て、自分の墓地のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・リリーベル",h:"とりっくすたー・りりーべる",e:"Trickstar Lilybell",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがドロー以外の方法で手札に加わった場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードは直接攻撃できる。\n③：このカードが相手に戦闘ダメージを与えた時、自分の墓地の「トリックスター」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・マンジュシカ",h:"とりっくすたー・まんじゅしか",e:"Trickstar Lycoris",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：手札のこのカードを相手に見せ、「トリックスター・マンジュシカ」以外の自分フィールドの「トリックスター」モンスター１体を対象として発動できる。\nこのカードを特殊召喚し、対象のモンスターを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。\n②：このカードがモンスターゾーンに存在する限り、相手の手札にカードが加わる度に、加えたカードの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・キャンディナ",h:"とりっくすたー・きゃんでぃな",e:"Trickstar Candina",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「トリックスター」カード１枚を手札に加える。\n②：このカードがモンスターゾーンに存在する限り、相手が魔法・罠カードを発動する度に相手に２００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ツイストコブラ",h:"ごうきついすとこぶら",e:"Gouki Twistcobra",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「剛鬼」モンスター１体をリリースし、自分フィールドの「剛鬼」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ツイストコブラ」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼スープレックス",h:"ごうきすーぷれっくす",e:"Gouki Suprex",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札から「剛鬼」モンスター１体を特殊召喚する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼スープレックス」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ライジングスコーピオ",h:"ごうきらいじんぐすこーぴお",e:"Gouki Riscorpio",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが存在しない場合、または「剛鬼」モンスターのみの場合、このカードはリリースなしで召喚できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ライジングスコーピオ」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ハック・ワーム",h:"はっく・わーむ",e:"Hack Worm",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:0,tx:"①：相手フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャック・ワイバーン",h:"じゃっく・わいばーん",e:"Jack Wyvern",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの機械族モンスター１体とこのカードを除外し、自分の墓地の闇属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クラッキング・ドラゴン",h:"くらっきんぐ・どらごん",e:"Cracking Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"①：このカードは、このカードのレベル以下のレベルを持つモンスターとの戦闘では破壊されない。\n②：このカードがモンスターゾーンに存在し、相手がモンスター１体のみを召喚・特殊召喚した時に発動できる。\nそのモンスターの攻撃力はターン終了時までそのレベル×２００ダウンし、ダウンした数値分だけ相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王眷竜オッドアイズ",h:"はおうけんりゅうおっどあいず",e:"Supreme King Dragon Odd-Eyes",t:"monster",a:"闇",l:8,ps:4,pe:"①：自分フィールドの「覇王眷竜」モンスター１体をリリースして発動できる。\nこのカードを破壊し、デッキから攻撃力１５００以下のPモンスター１体を手札に加える。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：自分フィールドの「覇王眷竜」モンスター２体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：自分のPモンスターが相手モンスターと戦闘を行う場合、相手に与える戦闘ダメージは倍になる。\n③：自分・相手のバトルフェイズにこのカードをリリースして発動できる。\n自分のEXデッキの表側表示のPモンスターの中から、「覇王眷竜オッドアイズ」以外の「覇王眷竜」モンスターまたは「覇王門」モンスターを合計２体まで選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物バンクシアオーガ",h:"ぷれでたー・ぷらんつばんくしあおーが",e:"Predaplant Banksiogre",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:2000,de:100,tx:"①：このカードは相手フィールドの捕食カウンターが置かれたモンスター１体をリリースした場合に手札から特殊召喚できる。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\n相手フィールドの表側表示モンスター全てに捕食カウンターを１つずつ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。",li:"",lk:[],ta:[],al:[]},
  {n:"DDヴァイス・テュポーン",h:"でぃーでぃーう゛ぁいす・てゅぽーん",e:"D/D Vice Typhon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分フィールドの「DD」モンスター１体をリリースして発動できる。\nデッキからレベル７の「DDD」モンスター１体を特殊召喚する。\n②：このカードが墓地へ送られたターンの自分メインフェイズに発動できる。\nレベル８以上の「DDD」融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分の墓地から除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星杯を戴く巫女",h:"せいはいをいただくみこ",e:"Crowned by the World Chalice",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"星神に鎮魂の祈りを捧げる巫女。\n\n手にした杖は代々受け継がれし祭器であり、力を結界に変えて機界騎士による支配から森の民を守護している。\n\n森の守護竜が懐く程の神通力をその身に秘めているが、普段は兄と幼馴染を大切に想う、心優しい少女の顔を見せる。",li:"",lk:[],ta:[],al:[]},
  {n:"星杯に選ばれし者",h:"せいはいにえらばれしもの",e:"Chosen by the World Chalice",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイキック族",mo:["通常"],ma:"",tp:"",at:1600,de:0,tx:"機怪の残骸で武装する、真っ直ぐな心の少年。\n\n星辰の森に古くから伝わる『星の勇者』に憧れており、妖精リースの願いを受けて、光を授かった仲間たちと共に七つの星遺物を解き放つ旅に出る。\n\n\n“星明かりの勇者 掲げし剣に光を束ね 大いなる闇を討ち祓わん”",li:"",lk:[],ta:[],al:[]},
  {n:"星杯に誘われし者",h:"せいはいにいざなわれしもの",e:"Beckoned by the World Chalice",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:0,tx:"機怪との戦いに明け暮れる青年。\n\n森の周辺に生息する機怪蟲が突如凶暴化した際にも、一歩も引かずに結界への侵入を防ぎ続けた。\n\n常に先陣を駆けるその雄姿は森の民を奮い立たせるが、本人はたった一人の妹を守る為だけにその槍を振るっている。",li:"",lk:[],ta:[],al:[]},
  {n:"星杯の守護竜",h:"せいはいのしゅごりゅう",e:"World Chalice Guardragon",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのリンク状態のモンスターを対象とする魔法・罠・モンスターの効果が発動した時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその発動を無効にし破壊する。\n②：墓地のこのカードを除外し、自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターをリンクモンスターのリンク先となる自分フィールドに守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星杯の妖精リース",h:"せいはいのようせいりーす",e:"Lee the World Chalice Fairy",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星杯」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、自分の手札・フィールドのモンスター１体を墓地へ送って発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物－『星杯』",h:"せいいぶつ－『せいはい』",e:"World Legacy - \"World Chalice\"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキからモンスターが特殊召喚された場合、このカードをリリースして発動できる。\nそのモンスターを墓地へ送る。\n②：通常召喚した表側表示のこのカードがフィールドから離れた場合に発動できる。\nデッキから「星遺物－『星杯』」以外の「星杯」モンスター２体を特殊召喚する。\n③：墓地のこのカードを除外して発動できる。\nデッキから「星遺物」カード１枚を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トワイライトロード・ジェネラル ジェイン",h:"とわいらいとろーど・じぇねらる　じぇいん",e:"Jain, Twilightsworn General",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：１ターンに１度、自分の手札・墓地から「ライトロード」モンスター１体を除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、除外したモンスターのレベル×３００ダウンする。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"トワイライトロード・ソーサラー ライラ",h:"とわいらいとろーど・そーさらー　らいら",e:"Lyla, Twilightsworn Enchantress",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"①：１ターンに１度、魔法・罠カードの効果が発動した時、自分の手札・墓地から「ライトロード」モンスター１体を除外し、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"トワイライトロード・シャーマン ルミナス",h:"とわいらいとろーど・しゃーまん　るみなす",e:"Lumina, Twilightsworn Shaman",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、自分の手札・墓地から「ライトロード」モンスター１体を除外し、「トワイライトロード・シャーマン ルミナス」以外の除外されている自分の「ライトロード」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"トワイライトロード・ファイター ライコウ",h:"とわいらいとろーど・ふぁいたー　らいこう",e:"Ryko, Twilightsworn Fighter",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"①：このカードが召喚・リバースした場合、自分の手札・墓地から「ライトロード」モンスター１体を除外して発動できる。\nフィールドのカード１枚を選んで除外する。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"戒めの龍",h:"ぱにっしゅめんと・どらぐーん",e:"Punishment Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2600,tx:"このカードは通常召喚できない。\n除外されている自分の「ライトロード」モンスターが４種類以上の場合のみ特殊召喚できる。\n①：１ターンに１度、１０００LPを払って発動できる。\n「ライトロード」モンスター以外の、お互いの墓地のカード及び表側表示で除外されているカードを全て持ち主のデッキに戻す。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを４枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキューフェレット",h:"れすきゅーふぇれっと",e:"Rescue Ferret",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードを持ち主のデッキに戻して発動できる。\nレベルの合計が６になるようにデッキから「レスキューフェレット」以外のモンスターを任意の数だけ選び、リンクモンスターのリンク先となる自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ランカの蟲惑魔",h:"らんかのこわくま",e:"Traptrix Mantis",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「蟲惑魔」モンスター１体を手札に加える。\n②：このカードはモンスターゾーンに存在する限り、「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。\n③：１ターンに１度、自分フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nそのセットされたカードを持ち主の手札に戻す。\nその後、自分の手札から魔法・罠カード１枚をセットできる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"切れぎみ隊長",h:"きれぎみたいちょう",e:"Motivating Captain",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:1200,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"トレジャー・パンダー",h:"とれじゃー・ぱんだー",e:"Treasure Panda",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"①：自分の墓地から魔法・罠カードを３枚まで裏側表示で除外して発動できる。\n除外したカードの数と同じレベルの通常モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾンビーナ",h:"ぞんびーな",e:"Zombina",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"①：このカードが相手によって破壊された場合、「ゾンビーナ」以外の自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Re：EX",h:"れっくす",e:"Re: EX",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードがEXモンスターゾーンのモンスターと戦闘を行う場合、ダメージステップの間だけこのカードの攻撃力・守備力は８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"影星軌道兵器ハイドランダー",h:"えいせいきどうへいきはいどらんだー",e:"Orbital Hydralander",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:1500,tx:"このカードは通常召喚できない。\n自分の墓地にモンスターが５体以上存在し、それらのモンスターのカード名が全て異なる場合のみ特殊召喚できる。\n①：１ターンに１度、自分のデッキの上からカードを３枚墓地へ送って発動できる。\n自分の墓地のモンスターのカード名が全て異なる場合、フィールドのカード１枚を選んで破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"雷仙神",h:"かみなりせんじん",e:"The Ascended of Thunder",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2700,de:2400,tx:"①：このカードは３０００LPを払って手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが相手によって破壊された場合に発動する。\n自分は５０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"パーリィナイツ",h:"ぱーりぃないつ",e:"Parry Knights",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：相手モンスターの攻撃で自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、受けたダメージの数値以下の攻撃力を持つモンスター１体を手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王眷竜スターヴ・ヴェノム",h:"はおうけんりゅうすたーう゛・う゛ぇのむ",e:"Supreme King Dragon Starving Venom",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2000,tx:"闇属性Pモンスター×２\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●自分フィールドの上記カードをリリースした場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、このカード以外の自分または相手のフィールド・墓地のモンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはそのモンスターと同じ、元々のカード名・効果を得る。\nこのターン、自分のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王眷竜クリアウィング",h:"はおうけんりゅうくりあうぃんぐ",e:"Supreme King Dragon Clear Wing",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外の闇属性Pモンスター１体以上\n①：このカードがS召喚に成功した場合に発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。\n②：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nそのモンスターを破壊し、破壊したモンスターの元々の攻撃力分のダメージを相手に与える。\n③：このカードが墓地に存在する場合、自分フィールドの「覇王眷竜」モンスター２体をリリースして発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD疾風大王エグゼクティブ・アレクサンダー",h:"でぃーでぃーでぃーしっぷうだいおうえぐぜくてぃぶ・あれくさんだー",e:"D/D/D Gust High King Alexander",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドに「DDD」モンスターが３体以上存在する場合、このカードの攻撃力は３０００アップする。\n②：このカードがモンスターゾーンに存在し、自分フィールドにこのカード以外の「DD」モンスターが召喚・特殊召喚された場合、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王眷竜ダーク・リベリオン",h:"はおうけんりゅうだーく・りべりおん",e:"Supreme King Dragon Dark Rebellion",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"闇属性レベル４のPモンスター×２\n①：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージ計算前に、このカードのX素材を１つ取り除いて発動できる。\nターン終了時まで、その相手モンスターの攻撃力を０にし、その元々の攻撃力分このカードの攻撃力をアップする。\n②：自分・相手のバトルフェイズにこのカードをEXデッキに戻して発動できる。\n自分のEXデッキの表側表示のPモンスターの中から、「覇王眷竜」モンスターまたは「覇王門」モンスターを合計２体まで選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD怒涛大王エグゼクティブ・シーザー",h:"でぃーでぃーでぃーどとうだいおうえぐぜくてぃぶ・しーざー",e:"D/D/D Wave High King Caesar",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:1800,tx:"悪魔族レベル６モンスター×２\n①：モンスターを特殊召喚する効果を含む、モンスターの効果・魔法・罠カードが発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。\nその後、このカード以外の自分フィールドの「DD」モンスター１体を選び、そのモンスターとこのカードの攻撃力をターン終了時まで１８００アップできる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「契約書」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイアウォール・ドラゴン",h:"ふぁいあうぉーる・どらごん",e:"Firewall Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドに表側表示で存在する限り１度だけ、このカードの相互リンク先のモンスターの数まで、自分または相手の、フィールド・墓地のモンスターを対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。\n②：このカードのリンク先のモンスターが、戦闘で破壊された場合、または墓地へ送られた場合に発動できる。\n手札からサイバース族モンスター１体を特殊召喚する。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"トリックスター・ホーリーエンジェル",h:"とりっくすたー・ほーりーえんじぇる",e:"Trickstar Holly Angel",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「トリックスター」モンスター２体\n①：このカードがモンスターゾーンに存在する限り、このカードのリンク先に「トリックスター」モンスターが召喚・特殊召喚される度に、相手に２００ダメージを与える。\n②：このカードのリンク先の「トリックスター」モンスターは戦闘・効果では破壊されない。\n③：「トリックスター」モンスターの効果で相手がダメージを受ける度に発動する。\nこのカードの攻撃力はターン終了時まで、そのダメージの数値分アップする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"剛鬼ザ・グレート・オーガ",h:"ごうきざ・ぐれーと・おーが",e:"Gouki The Great Ogre",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2600,de:"-",tx:"「剛鬼」モンスター２体以上\n①：このカードがモンスターゾーンに存在する限り、フィールドのモンスターの攻撃力は、そのモンスターの元々の守備力分ダウンする。\n②：このカードが戦闘・効果で破壊される場合、代わりにこのカードのリンク先の自分のモンスター１体を破壊できる。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"トポロジック・ボマー・ドラゴン",h:"とぽろじっく・ぼまー・どらごん",e:"Topologic Bomber Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター２体以上\n①：このカードが既にモンスターゾーンに存在する状態で、このカード以外のモンスターがリンクモンスターのリンク先に特殊召喚された場合に発動する。\nお互いのメインモンスターゾーンのモンスターを全て破壊する。\nこのターン、このカード以外の自分のモンスターは攻撃できない。\n②：このカードが相手モンスターを攻撃したダメージ計算後に発動する。\nその相手モンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:["上","左下","下","右下"],ta:[],al:[]},
  {n:"星杯竜イムドゥーク",h:"せいはいりゅういむどぅーく",e:"Imduk the World Chalice Dragon",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"トークン以外の通常モンスター１体\n①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「星杯」モンスター１体を召喚できる。\n②：このカードがこのカードのリンク先の相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターを破壊する。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札から「星杯」モンスター１体を特殊召喚する。",li:"",lk:["上"],ta:[],al:[]},
  {n:"星杯神楽イヴ",h:"せいはいかぐらいう゛",e:"Ib the World Chalice Priestess",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"種族と属性が異なるモンスター２体\n①：リンク状態のこのカードは戦闘・効果では破壊されず、相手の効果の対象にならない。\n②：このカードのリンク先のモンスターが効果で破壊される場合、代わりにこのカードを墓地へ送る事ができる。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札から「星杯」モンスター１体を特殊召喚する。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"星杯剣士アウラム",h:"せいはいけんしあうらむ",e:"Auram the World Chalice Blademaster",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「星杯」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、自分の墓地の「星遺物」モンスターの種類×３００アップする。\n②：このカードのリンク先の自分の「星杯」モンスター１体をリリースし、そのモンスター以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札から「星杯」モンスター１体を特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"星杯戦士ニンギルス",h:"せいはいせんしにんぎるす",e:"Ningirsu the World Chalice Warrior",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"リンクモンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動する。\nこのカードのリンク先の「星杯」モンスターの数だけ、自分はデッキからドローする。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分及び相手フィールドのカードを１枚ずつ選んで墓地へ送る。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札から「星杯」モンスター１体を特殊召喚する。",li:"",lk:["上","左","右"],ta:[],al:[]},
  {n:"電影の騎士ガイアセイバー",h:"でんえいのきしがいあせいばー",e:"Gaia Saber, the Lightning Shadow",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク"],ma:"",tp:"",at:2600,de:"-",tx:"モンスター２体以上",li:"",lk:["左","右","下"],ta:[],al:[]},
  {n:"ミセス・レディエント",h:"みせす・れでぃえんと",e:"Missus Radiant",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"地属性モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、フィールドの地属性モンスターの攻撃力・守備力は５００アップし、風属性モンスターの攻撃力・守備力は４００ダウンする。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地の地属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"トリックスター・ライトステージ",h:"とりっくすたー・らいとすてーじ",e:"Trickstar Light Stage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、デッキから「トリックスター」モンスター１体を手札に加える事ができる。\n②：１ターンに１度、相手の魔法＆罠ゾーンにセットされたカード１枚を対象として発動できる。\nこのカードがフィールドゾーンに存在する限り、セットされたそのカードはエンドフェイズまで発動できず、相手はエンドフェイズにそのカードを発動するか、墓地へ送らなければならない。\n③：自分フィールドの「トリックスター」モンスターが戦闘・効果で相手にダメージを与える度に、相手に２００ダメージを与える。",li:1,lk:[],ta:[],al:[]},
  {n:"剛鬼再戦",h:"ごうきさいせん",e:"Gouki Re-Match",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベルの異なる「剛鬼」モンスター２体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エアークラック・ストーム",h:"えあーくらっく・すとーむ",e:"Air Cracking Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターにのみ装備可能。\n①：装備モンスターの攻撃で相手モンスターを破壊した時に発動できる。\nこのバトルフェイズ中、装備モンスターはもう１度だけ攻撃できる。\nこの効果を発動するターン、装備モンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スマイル・ユニバース",h:"すまいる・ゆにばーす",e:"Smile Universe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・特殊召喚できず、自分のモンスターは攻撃できない。\n①：自分のEXデッキから表側表示のPモンスターを可能な限り特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nその後、この効果で特殊召喚したモンスターの元々の攻撃力の合計分だけ相手のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物との邂逅",h:"せいいぶつとのかいこう",e:"World Legacy Discovery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「星杯」モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分フィールドの表側表示の「星杯」モンスターが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、自分の墓地の「星杯」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の加護",h:"せいいぶつのかご",e:"World Legacy's Heart",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のカード名が異なる「星杯」モンスター２体を対象として発動できる。\nそのモンスターを手札に加える。\n②：自分フィールドのリンク状態のリンクモンスターが戦闘で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の進軍",h:"やみのしんぐん",e:"March of the Dark Brigade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「ライトロード」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札に加えたそのモンスターの元々のレベルの数だけ、自分のデッキの上からカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄昏の双龍",h:"とわいらいと・ついん・どらぐーん",e:"Twilight Twin Dragons",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「戒めの龍」が存在する場合、自分の墓地の「裁きの龍」１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、自分のデッキの上からカードを４枚墓地へ送る。\n②：このカードが「ライトロード」モンスターの効果でデッキから墓地へ送られた場合、自分の墓地の「戒めの龍」１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、自分のデッキの上からカードを４枚除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急救急救命レスキュー",h:"きんきゅうきゅうきゅうきゅうめいれすきゅー",e:"Emerging Emergency Rescute Rescue",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のLPが相手より少ない場合に発動できる。\nデッキから攻撃力３００／守備力１００の獣族モンスター３体を相手に見せ、相手はその中から１体選ぶ。\nそのモンスター１体を自分の手札に加え、残りをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ルドラの魔導書",h:"るどらのまどうしょ",e:"Spellbook of Knowledge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：「ルドラの魔導書」以外の自分の手札・フィールドの「魔導書」カード１枚または自分フィールドの魔法使い族モンスター１体を墓地へ送り、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"鈍重",h:"どんじゅう",e:"Gravity Lash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのモンスターの守備力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブーギートラップ",h:"ぶーぎーとらっぷ",e:"Boogie Trap",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を２枚捨て、自分の墓地の罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"キャッスル・リンク",h:"きゃっする・りんく",e:"Castle Link",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズにフィールドのリンクモンスター１体を対象として発動できる。\nそのモンスターの位置を、そのモンスターのリンク先のメインモンスターゾーンに移動する（そのモンスターから見て相手のフィールドには移動できない）。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分のメインモンスターゾーンのリンクモンスター２体または相手のメインモンスターゾーンのリンクモンスター２体を選び、その位置を入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディフェンスゾーン",h:"でぃふぇんすぞーん",e:"Defense Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：以下の効果はお互いのプレイヤーに適用される。\n●自分のメインモンスターゾーンにモンスターが存在する場合、同じ縦列の自分の魔法＆罠ゾーンのカードは相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"スリーストライク・バリア",h:"すりーすとらいく・ばりあ",e:"Three Strikes Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドのカードが３枚のみの場合、以下の効果から１つを選択して発動できる。\n\n●このターン、自分のモンスターは戦闘では破壊されない。\n\n●このターン、自分が受ける戦闘ダメージは０になる。\n\n●このターン、自分のモンスターが相手に戦闘ダメージを与える度に、その数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・リンカーネイション",h:"とりっくすたー・りんかーねいしょん",e:"Trickstar Reincarnation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の手札を全て除外し、その枚数分だけ相手はデッキからドローする。\n②：墓地のこのカードを除外し、自分の墓地の「トリックスター」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"パルス・ボム",h:"ぱるす・ぼむ",e:"Pulse Mines",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに機械族モンスターが存在する場合に発動できる。\n相手フィールドに攻撃表示モンスターが存在する場合、そのモンスターを全て守備表示にする。\nターン終了時まで、相手フィールドにモンスターが召喚・特殊召喚された場合、そのモンスターは守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王の逆鱗",h:"はおうのげきりん",e:"Supreme Rage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「覇王龍ズァーク」が存在する場合に発動できる。\n「覇王龍ズァーク」以外の自分フィールドのモンスターを全て破壊し、自分の手札・デッキ・EXデッキ・墓地からカード名が異なる「覇王眷竜」モンスターを４体まで召喚条件を無視して特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「覇王眷竜」Xモンスター１体を対象として発動できる。\n自分の墓地のモンスター及び自分のEXデッキの表側表示のPモンスターの中から、「覇王眷竜」モンスター２体を選び、対象のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の導き",h:"せいいぶつのみちびき",e:"World Legacy Landmark",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札及び自分フィールドの表側表示モンスターの中から、「星遺物」モンスター１体を除外し、自分の墓地のモンスター２体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トワイライト・イレイザー",h:"とわいらいと・いれいざー",e:"Twilight Eraser",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに同じ種族でカード名が異なる「ライトロード」モンスターが２体以上存在する場合、自分の墓地の「ライトロード」モンスター２体を除外し、フィールドのカード２枚を対象として発動できる。\nそのカードを除外する。\n②：このカードが「ライトロード」モンスターの効果でデッキから墓地へ送られた場合に発動できる。\n手札から「ライトロード」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄昏の交衣",h:"とわいらいと・くろす",e:"Twilight Cloth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\n自分の墓地の「ライトロード」モンスターを任意の数だけ選んで除外し、対象のモンスターの攻撃力・守備力はターン終了時まで、この効果で除外したモンスターの数×２００アップする。\n②：このカードが「ライトロード」モンスターの効果でデッキから墓地へ送られた場合に発動できる。\nこのターン、自分フィールドの「ライトロード」モンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の洗脳",h:"あんこくかいのせんのう",e:"Dark World Brainwashing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の手札が３枚以上存在し、相手モンスターの効果が発動した時、フィールドの「暗黒界」モンスター１体を対象としてこの効果を発動できる。\n対象のモンスターを持ち主の手札に戻し、その相手の効果は「相手の手札をランダムに１枚選んで捨てる」となる。",li:"",lk:[],ta:[],al:[]},
  {n:"絶縁の落とし穴",h:"ぜつえんのおとしあな",e:"Break Off Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：リンクモンスターがリンク召喚に成功した時に発動できる。\nフィールドのリンク状態ではないモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"砂塵の大嵐",h:"さじんのおおあらし",e:"Heavy Storm Duster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：フィールドの魔法・罠カードを２枚まで対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦線復帰",h:"せんせんふっき",e:"Back to the Front",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"無償交換",h:"りこーる",e:"Recall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手がモンスターの効果を発動した時に発動できる。\nその発動を無効にし破壊する。\n相手はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"無差別崩壊",h:"むさべつほうかい",e:"Blind Obliteration",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：サイコロを２回振る。\n出た目の合計よりレベル・ランクが低いフィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"変則ギア",h:"とらんすみっしょんぎあ",e:"Transmission Gear",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のモンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\n相手プレイヤーとジャンケンを行う。\n引き分けの場合はジャンケンをやり直す。\n負けたプレイヤーはその戦闘を行う自身のモンスターを裏側表示で除外しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD運命王ゼロ・ラプラス",h:"でぃーでぃーでぃーうんめいおうぜろ・らぷらす",e:"D/D/D Destiny King Zero Laplace",t:"monster",a:"闇",l:10,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のEXデッキから「DDD運命王ゼロ・ラプラス」以外の表側表示の「DDD」Pモンスター１体を選んで手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:"?",de:0,tx:"①：このカードは自分フィールドの「DDD」モンスター１体をリリースして手札から特殊召喚できる。\n②：このカードが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nこのカードの攻撃力はダメージステップ終了時まで、その相手モンスターの元々の攻撃力の倍になる。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n④：このカードは１ターンに１度だけ戦闘では破壊されない。\nその際、自分が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王白竜オッドアイズ・ウィング・ドラゴン",h:"はおうはくりゅうおっどあいず・うぃんぐ・どらごん",e:"",t:"monster",a:"闇",l:8,ps:10,pe:"①：１ターンに１度、自分のモンスターが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその自分のモンスターの攻撃力はそのダメージステップ終了時まで、その相手モンスターの攻撃力分アップする。",tr:"ドラゴン族",mo:["シンクロ","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"闇属性チューナー＋チューナー以外の「クリアウィング」モンスター１体\nこのカード名の①②のモンスター効果は１ターンに１度、いずれか１つしか使用できない。\n①：相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n②：S召喚したこのカードが存在する場合、お互いのバトルフェイズに発動できる。\n相手フィールドのレベル５以上のモンスターを全て破壊する。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD超死偉王パープリッシュ・ヘル・アーマゲドン",h:"でぃーでぃーでぃーちょうしいおうぱーぷりっしゅ・へる・あーまげどん",e:"D/D/D Super Doom King Purple Armageddon",t:"monster",a:"闇",l:10,ps:1,pe:"①：１ターンに１度、自分の「DDD」融合モンスターが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその相手モンスターの攻撃力はターン終了時まで１０００ダウンする。",tr:"悪魔族",mo:["融合","ペンデュラム","効果"],ma:"",tp:"",at:3500,de:3000,tx:"「DDD」モンスター×２\nこのカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：相手フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、破壊したモンスターの元々の攻撃力の半分のダメージを相手に与える。\n②：相手モンスターが戦闘を行うダメージ計算前に発動できる。\nその相手モンスターの攻撃力はダメージステップ終了時まで元々の数値になる。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ランサー・ドラゴン",h:"おっどあいず・らんさー・どらごん",e:"Odd-Eyes Lancer Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：自分フィールドのPモンスターが戦闘・効果で破壊された場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n③：自分フィールドの「オッドアイズ」カードが戦闘・効果で破壊される場合、代わりに自分の手札・モンスターゾーン・Pゾーンの「オッドアイズ」カード１枚を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・ミノタウロス",h:"えんためいとおっどあいず・みのたうろす",e:"Performapal Odd-Eyes Minitaurus",t:"monster",a:"闇",l:4,ps:6,pe:"①：自分の「EM」モンスターまたは「オッドアイズ」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"獣戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：自分のPモンスターが相手モンスターに攻撃するダメージ計算時に発動できる。\nその相手モンスターの攻撃力はそのダメージ計算時のみ、自分フィールドの「EM」カード及び「オッドアイズ」カードの数×１００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・ディゾルヴァー",h:"えんためいとおっどあいず・でぃぞるう゛ぁー",e:"Performapal Odd-Eyes Dissolver",t:"monster",a:"闇",l:8,ps:4,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドから、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2600,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：自分のPモンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードを手札から特殊召喚し、その自分のモンスターはその戦闘では破壊されない。\n②：自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nその際、自分のPゾーンに存在する融合素材モンスターも融合素材に使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・シンクロン",h:"えんためいとおっどあいず・しんくろん",e:"Performapal Odd-Eyes Synchron",t:"monster",a:"闇",l:2,ps:6,pe:"①：１ターンに１度、自分フィールドの「EM」モンスターまたは「オッドアイズ」モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターをチューナーとして扱い、レベルは１になる。",tr:"魔法使い族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:200,de:600,tx:"EXデッキから特殊召喚したこのカードは、S召喚に使用された場合に除外される。\n①：このカードが召喚に成功した時、自分の墓地のレベル３以下の、「EM」モンスターまたは「オッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：１ターンに１度、自分のPゾーンのカード１枚を対象として発動できる。\nそのカードを効果を無効にして特殊召喚し、そのカードとこのカードのみを素材としてSモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EM五虹の魔術師",h:"えんためいとごこうのまじゅつし",e:"Performapal Five-Rainbow Magician",t:"monster",a:"光",l:1,ps:12,pe:"①：自分はEXデッキからしかP召喚できない。\nこの効果は無効化されない。\n②：お互いは自身の魔法＆罠ゾーンにセットされているカードの数により以下を適用する。\n●０枚：自分フィールドのモンスターは、攻撃できず、効果を発動できない。\n●４枚以上：自分フィールドのモンスターの攻撃力は元々の数値の倍になる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが墓地に存在し、自分フィールドに魔法・罠カードがセットされた場合に発動できる。\n墓地のこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王紫竜オッドアイズ・ヴェノム・ドラゴン",h:"はおうしりゅうおっどあいず・う゛ぇのむ・どらごん",e:"Odd-Eyes Venom Dragon",t:"monster",a:"闇",l:10,ps:1,pe:"①：１ターンに１度、自分フィールドの融合モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、相手フィールドのモンスターの数×１０００アップする。",tr:"ドラゴン族",mo:["融合","ペンデュラム","効果"],ma:"",tp:"",at:3300,de:2500,tx:"「スターヴ・ヴェノム」モンスター＋「オッドアイズ」モンスター\nこのカードは融合召喚及びP召喚でのみ特殊召喚できる。\n①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nエンドフェイズまで、このカードの攻撃力はそのモンスターの攻撃力分アップし、このカードはそのモンスターと同じ、元々のカード名・効果を得る。\n②：モンスターゾーンのこのカードが破壊された場合に発動できる。\n自分のPゾーンのカード１枚を選んで特殊召喚し、このカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD超死偉王ホワイテスト・ヘル・アーマゲドン",h:"でぃーでぃーでぃーちょうしいおうほわいてすと・へる・あーまげどん",e:"D/D/D Super Doom King Bright Armageddon",t:"monster",a:"闇",l:10,ps:1,pe:"①：１ターンに１度、相手モンスターの攻撃宣言時に自分フィールドの「DDD」Sモンスター１体を対象として発動できる。\nそのモンスターの攻撃力以下の守備力を持つ相手フィールドのモンスターを全て破壊し、破壊したモンスターの数×１０００ダメージを相手に与える。",tr:"悪魔族",mo:["シンクロ","ペンデュラム","効果"],ma:"",tp:"",at:3500,de:3000,tx:"「DD」チューナー＋チューナー以外の「DDD」モンスター１体以上\n①：このカードがモンスターゾーンに存在する限り、自分フィールドのモンスターを相手は効果の対象にできない。\n②：このカードが既にモンスターゾーンに存在する状態で、このカード以外のモンスターが召喚・特殊召喚された場合に発動できる。\n相手は自身のフィールドのPモンスター１体を選ぶ。\nそのモンスター以外の相手フィールドのモンスターの効果は無効化される。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD超死偉王ダークネス・ヘル・アーマゲドン",h:"でぃーでぃーでぃーちょうしいおうだーくねす・へる・あーまげどん",e:"D/D/D Super Doom King Dark Armageddon",t:"monster",a:"闇",l:8,ps:1,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「DD」カードが存在する場合に発動できる。\nEXデッキから「DDD超死偉王ダークネス・ヘル・アーマゲドン」以外の「DDD」Xモンスター１体を特殊召喚する。",tr:"悪魔族",mo:["エクシーズ","ペンデュラム","効果"],ma:"",tp:"",at:3500,de:3000,tx:"レベル８「DDD」モンスター×２\n①：このカードがX召喚に成功した時に発動できる。\n自分のEXデッキの表側表示の「DD」Pモンスター１体を選び、このカードの下に重ねてX素材とする。\n②：自分フィールドのPモンスターは効果では破壊されない。\n③：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドのPモンスターの数だけ相手フィールドのモンスターを対象として発動できる。\nそのモンスターを破壊する。\n④：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者オタス－K",h:"ちょうじゅうむしゃおたす－けー",e:"Superheavy Samurai Helper",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分の墓地に魔法・罠カードが存在しない場合、自分の守備表示モンスターが相手モンスターと戦闘を行うダメージ計算時にこのカードを手札から捨て、戦闘を行うモンスター以外の自分フィールドの「超重武者」モンスター１体を対象として発動できる。\nその戦闘を行う自分のモンスターの守備力はそのダメージ計算時のみ、対象のモンスターの守備力分アップする。\n②：相手モンスターの直接攻撃宣言時、墓地のこのカードを除外し、自分の墓地の「超重武者」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超重武者コブ－C",h:"ちょうじゅうむしゃこぶ－しー",e:"Superheavy Samurai Fist",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「超重武者」モンスターが戦闘で相手モンスターを破壊した自分バトルフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。\n②：自分の墓地に魔法・罠カードが存在しない場合、自分フィールドの「超重武者」Sモンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、このカードを墓地から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「超重武者」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超重蒸鬼テツドウ－O",h:"ちょうじゅうじょうきてつどう－おー",e:"Superheavy Samurai Steam Train King",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:4800,tx:"「超重武者」チューナー＋チューナー以外の「超重武者」モンスター２体以上\nこのカード名はルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：１ターンに１度、手札を２枚まで捨て、捨てた数だけ相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。\n③：１ターンに１度、自分メインフェイズに発動できる。\nお互いの墓地の魔法・罠カードを全て除外し、除外した数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団カーテン・ライザー",h:"まかいげきだんかーてん・らいざー",e:"Abyss Actor - Curtain Raiser",t:"monster",a:"闇",l:4,ps:7,pe:"このカード名のP効果はデュエル中に１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1100,de:1000,tx:"①：自分フィールドにこのカード以外のモンスターが存在しない場合、このカードの攻撃力は１１００アップする。\n②：１ターンに１度、デッキから「魔界台本」魔法カード１枚を墓地へ送って発動できる。\n自分のEXデッキから表側表示の「魔界劇団」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界台本「魔界の宴咜女」",h:"まかいだいほん「まかいのえんため」",e:"Abyss Script - Abysstainment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに２度まで使用できる。\n①：自分フィールドの「魔界劇団」モンスター１体をリリースし、自分の墓地の「魔界台本」魔法カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「魔界劇団」Pモンスターを任意の数だけ特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ラダー・ストリクス",h:"れいど・らぷたーず－らだー・すとりくす",e:"Raidraptor - Rudder Strix",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"①：このカードが召喚に成功した場合、または「RR」カードの効果で手札から特殊召喚に成功した場合に発動できる。\n相手に６００ダメージを与える。\n②：このカードを攻撃対象とした相手モンスターの攻撃宣言時に発動できる。\n手札から「RR」モンスターを２体まで特殊召喚する。\nこのターン、相手はこの効果で特殊召喚したモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ファイナル・フォートレス・ファルコン",h:"れいど・らぷたーず－ふぁいなる・ふぉーとれす・ふぁるこん",e:"Raidraptor - Final Fortress Falcon",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3800,de:2800,tx:"レベル１２モンスター×３\n①：「RR」XモンスターをX素材としているこのカードは他のカードの効果を受けない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n除外されている自分の「RR」モンスターを全て墓地に戻す。\n③：このカードの攻撃でモンスターを破壊した時、自分の墓地の「RR」Xモンスター１体を除外して発動できる。\nこのカードは続けて攻撃できる。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"黄昏の忍者－ジョウゲン",h:"たそがれのにんじゃ－じょうげん",e:"Twilight Ninja Jogen",t:"monster",a:"闇",l:7,ps:1,pe:"①：自分の「忍者」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:1000,tx:"①：手札の「忍法」カード１枚を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"黄昏の忍者－カゲン",h:"たそがれのにんじゃ－かげん",e:"Twilight Ninja Kagen",t:"monster",a:"闇",l:1,ps:10,pe:"①：自分は「忍者」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、自分の「忍者」モンスターの攻撃宣言時に発動できる。\nそのモンスターの攻撃力はダメージステップ終了時まで１０００アップする。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードをリリースし、自分フィールドの「忍者」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"白鱓",h:"ほわいと・もーれい",e:"White Moray",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"①：このカードが召喚に成功したターン、このカードは相手に直接攻撃できる。\n②：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nこのターン、このカードをチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"白闘気海豚",h:"ほわいと・おーら・どるふぃん",e:"White Aura Dolphin",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の攻撃力の半分になる。\n②：このカードが相手によって破壊され墓地へ送られた場合、このカード以外の自分の墓地の水属性モンスター１体を除外して発動できる。\nこのカードをチューナー扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"白闘気白鯨",h:"ほわいと・おーら・ほえーる",e:"White Aura Whale",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魚族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"水属性チューナー＋チューナー以外の水属性モンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\n相手フィールドの攻撃表示モンスターを全て破壊する。\n②：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n④：このカードが相手によって破壊され墓地へ送られた場合、このカード以外の自分の墓地の水属性モンスター１体を除外して発動できる。\nこのカードをチューナー扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの斥候",h:"あまぞねすのせっこう",e:"Amazoness Spy",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカード以外の手札の「アマゾネス」モンスター１体を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが戦闘で破壊され墓地へ送られた時、「アマゾネスの斥候」以外の自分の墓地の「アマゾネス」モンスター１体を対象として発動できる。\nそのモンスターを手札またはデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスペット虎獅子",h:"あまぞねすぺっとらいがー",e:"Amazoness Pet Liger",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2400,tx:"「アマゾネスペット虎」＋「アマゾネス」モンスター\n①：このカードが攻撃するダメージ計算時に発動できる。\nこのカードの攻撃力はそのダメージ計算時のみ５００アップする。\n②：自分の「アマゾネス」モンスターが相手モンスターに攻撃したダメージ計算後に相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は８００ダウンする。\n③：このカードがモンスターゾーンに存在する限り、相手は他の「アマゾネス」モンスターを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネス女帝",h:"あまぞねすえんぷれす",e:"Amazoness Empress",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2400,tx:"「アマゾネス女王」＋「アマゾネス」モンスター\n①：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「アマゾネス」カードは戦闘・効果では破壊されない。\n②：自分の「アマゾネス」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：融合召喚した表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「アマゾネス女王」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スローライフ",h:"すろーらいふ",e:"Quiet Life",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールドにモンスターが存在しない場合、自分メインフェイズ１開始時にこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、以下の効果を適用する。\n●モンスターを通常召喚したプレイヤーは、そのターンモンスターを特殊召喚できない。\n●モンスターを特殊召喚したプレイヤーは、そのターンモンスターを通常召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ノクシウス",h:"ぐらでぃあるびーすとのくしうす",e:"Gladiator Beast Noxious",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚し、その相手モンスターの攻撃対象をこのカードに移し替えてダメージ計算を行う。\nこのカードはその戦闘では破壊されない。\n②：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した場合に発動できる。\nデッキから「剣闘獣」モンスター１体を墓地へ送る。\n③：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣ノクシウス」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣アンダバタエ",h:"ぐらでぃあるびーすとあんだばたえ",e:"Gladiator Beast Andabata",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:2800,tx:"「剣闘獣アウグストル」＋「剣闘獣」モンスター×２\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードが上記の方法で特殊召喚に成功した場合に発動できる。\nEXデッキからレベル７以下の「剣闘獣」融合モンスター１体を召喚条件を無視して特殊召喚する。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のEXデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣総監エーディトル",h:"ぐらでぃあるびーすと・ていまーえーでぃとる",e:"Gladiator Beast Tamer Editor",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:3000,tx:"レベル５以上の「剣闘獣」モンスター×２\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\nこのカードは融合素材にできない。\n①：１ターンに１度、発動できる。\nEXデッキから「剣闘獣総監エーディトル」以外の「剣闘獣」融合モンスター１体を召喚条件を無視して特殊召喚する。\n②：自分の「剣闘獣」モンスターが戦闘を行ったバトルフェイズ終了時にそのモンスター１体を持ち主のデッキ・EXデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドリームガイ",h:"ですてにーひーろー　どりーむがい",e:"Destiny HERO - Dreamer",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分の「D-HERO」モンスターが戦闘を行うダメージ計算時に発動できる。\nこのカードを墓地から特殊召喚し、その自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ダスクユートピアガイ",h:"ですてにーひーろー　だすくゆーとぴあがい",e:"Destiny HERO - Dusktopia",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"「D-HERO」融合モンスター＋「D-HERO」モンスター\n①：このカードが融合召喚に成功した場合に発動できる。\n自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：１ターンに１度、フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは戦闘・効果では破壊されず、そのモンスターの戦闘で発生するお互いの戦闘ダメージは０になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"時空超越",h:"じくうちょうえつ",e:"Spacetime Transcendence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地から恐竜族モンスターを２体以上除外して発動できる。\n除外したモンスターのレベルの合計と同じレベルを持つ恐竜族モンスター１体を自分の手札・墓地から選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジュラシック・インパクト",h:"じゅらしっく・いんぱくと",e:"Jurassic Impact",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに恐竜族モンスターが２体以上存在し、自分のLPが相手より少ない場合に発動できる。\nフィールドのモンスターを全て破壊し、自分は破壊したモンスターの数×１０００ダメージを受ける。\nその後、自分が受けたダメージと同じ数値分のダメージを相手に与える。\nこのカードの発動後、次のターンの終了時までお互いはモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神ラツィオン",h:"じかいしんらつぃおん",e:"Lazion, the Timelord",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\n相手の墓地のカードを全てデッキに戻す。\n④：１ターンに１度、相手がドローした場合に発動する。\n相手に１０００ダメージを与える。\n⑤：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神ザフィオン",h:"じかいしんざふぃおん",e:"Zaphion, the Timelord",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\n相手フィールドの魔法・罠カードを全てデッキに戻す。\n④：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。\n⑤：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神サディオン",h:"じかいしんさでぃおん",e:"Sadion, the Timelord",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時、自分のLPが４０００より少ない場合に発動する。\n自分のLPは４０００になる。\n④：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神カミオン",h:"じかいしんかみおん",e:"Kamion, the Timelord",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\n相手フィールドのカード１枚を選んで持ち主のデッキに戻し、相手に５００ダメージを与える。\nこの効果の発動に対してカードの効果は発動できない。\n④：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械巫女",h:"じかいみこ",e:"Time Maiden",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：「時械神」モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n③：このカードをリリースして発動できる。\nデッキから攻撃力０の「時械神」モンスター１体を手札に加える。\n④：墓地のこのカードを除外して発動できる。\nデッキから攻撃力０の「時械神」モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果を発動するターン、自分はこの効果以外ではモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.29 マネキンキャット",h:"なんばーず２９ まねきんきゃっと",e:"Number 29: Mannequin Cat",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:900,tx:"レベル２モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを相手フィールドに特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、相手フィールドにモンスターが特殊召喚された場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\n種族または属性がそのモンスターと同じモンスター１体を自分の手札・デッキ・墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.41 泥睡魔獣バグースカ",h:"なんばーず４１ でいすいまじゅうばぐーすか",e:"Number 41: Bagooska the Terribly Tired Tapir",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:2000,tx:"レベル４モンスター×２\nこのカードのコントローラーは、自分スタンバイフェイズ毎にこのカードのX素材を１つ取り除く。\n取り除けない場合、このカードを破壊する。\n①：このカードはモンスターゾーンに攻撃表示で存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードがモンスターゾーンに守備表示で存在する限り、フィールドの表側表示モンスターは守備表示になり、守備表示モンスターが発動した効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"No.68 魔天牢サンダルフォン",h:"なんばーず６８ まてんろうさんだるふぉん",e:"Number 68: Sanaphond the Sky Prison",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:2700,tx:"レベル８モンスター×２\n①：このカードの攻撃力・守備力は、お互いの墓地のモンスターの数×１００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手ターン終了時まで、このカードは効果では破壊されず、お互いに墓地のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.71 リバリアン・シャーク",h:"なんばーず７１　りばりあん・しゃーく",e:"Number 71: Rebarian Shark",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2000,tx:"レベル３モンスター×２\n①：１ターンに１度、「No.71 リバリアン・シャーク」以外の自分の墓地の「No.」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、このカードのX素材１つをそのモンスターの下に重ねてX素材とする。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「RUM」魔法カード１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"No.89 電脳獣ディアブロシス",h:"なんばーず８９ でんのうじゅうでぃあぶろしす",e:"Number 89: Diablosis the Mind Hacker",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:1200,tx:"レベル７モンスター×２\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手のEXデッキを確認し、その内の１枚を選んで裏側表示で除外する。\n②：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時、相手の墓地のカード１枚を対象として発動できる。\nそのカードを裏側表示で除外する。\n③：相手のカードが裏側表示で除外された場合に発動できる。\n裏側表示で除外されている相手のカードの数だけ、相手のデッキの上からカードを裏側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"プロキシー・ドラゴン",h:"ぷろきしー・どらごん",e:"Proxy Dragon",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"モンスター２体\n①：自分フィールドのカードが戦闘・効果で破壊される場合、代わりにこのカードのリンク先の自分のモンスター１体を破壊できる。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"真紅眼の幼竜",h:"れっどあいず・べびーどらごん",e:"Red-Eyes Baby Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキからレベル７以下の「レッドアイズ」モンスター１体を特殊召喚し、墓地のこのカードを攻撃力３００アップの装備カード扱いとして、そのモンスターに装備する。\n②：モンスターに装備されているこのカードが墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から、ドラゴン族・レベル１モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の鉄騎士－ギア・フリード",h:"れっどあいず・めたるないと－ぎあ・ふりーど",e:"Gearfried the Red-Eyes Iron Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：１ターンに１度、このカードに装備カードが装備された場合に発動できる。\nその装備カードを破壊する。\nその後、相手フィールドの魔法・罠カード１枚を選んで破壊できる。\n②：１ターンに１度、このカードに装備されている自分フィールドの装備カード１枚を墓地へ送り、自分の墓地のレベル７以下の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の黒刃竜",h:"れっどあいず・すらっしゅどらごん",e:"Red-Eyes Slash Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2400,tx:"「真紅眼の黒竜」＋戦士族モンスター\n①：「レッドアイズ」モンスターの攻撃宣言時に自分の墓地の戦士族モンスター１体を対象として発動できる。\nそのモンスターを攻撃力２００アップの装備カード扱いとしてこのカードに装備する。\n②：自分フィールドのカードを対象とするカードの効果が発動した時、自分フィールドの装備カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\nこのカードに装備されていたモンスターを自分の墓地から可能な限り特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"鎖付き真紅眼牙",h:"くさりつきれっどあいずふぁんぐ",e:"Red-Eyes Fang with Chain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「レッドアイズ」モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとして、そのモンスターに装備する。\n装備モンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n②：装備されているこのカードを墓地へ送り、フィールドの効果モンスター１体を対象として発動できる。\nその効果モンスターを装備カード扱いとして、このカードを装備していたモンスターに装備する。\nこの効果でモンスターを装備している限り、装備モンスターはそのモンスターと同じ攻撃力・守備力になる。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネス王女",h:"あまぞねすぷりんせす",e:"Amazoness Princess",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アマゾネス女王」として扱う。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「アマゾネス」魔法・罠カード１枚を手札に加える。\n③：このカードの攻撃宣言時にこのカード以外の自分の手札・フィールドのカード１枚を墓地へ送って発動できる。\nデッキから「アマゾネス王女」以外の「アマゾネス」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスペット仔虎",h:"あまぞねすぺっとべびーたいがー",e:"Amazoness Baby Tiger",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アマゾネスペット虎」として扱う。\n②：このカードが手札・墓地に存在し、自分フィールドに「アマゾネス」モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。\n③：このカードの攻撃力は、自分の墓地の「アマゾネス」カードの数×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの叫声",h:"あまぞねすこーる",e:"Amazoness Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「アマゾネスの叫声」以外の「アマゾネス」カード１枚を手札に加えるか墓地へ送る。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの「アマゾネス」モンスター１体を対象として発動できる。\nこのターン、そのモンスター以外の自分のモンスターは攻撃できず、対象のモンスターは相手モンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アマゾネスの急襲",h:"あまぞねすのきゅうしゅう",e:"Amazoness Onslaught",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分・相手のバトルフェイズに発動できる。\n手札から「アマゾネス」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力はターン終了時まで５００アップする。\n②：自分の「アマゾネス」モンスターが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを除外する。\n③：フィールドのこのカードが破壊され墓地へ送られた場合、自分の墓地の「アマゾネス」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"伝説のフィッシャーマン二世",h:"でんせつのふぃっしゃーまんにせい",e:"The Legendary Fisherman II",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「伝説のフィッシャーマン」として扱う。\n②：フィールドに「海」が存在する限り、フィールドのこのカードは他のモンスターの効果を受けない。\n③：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\nデッキから水属性・レベル７モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"城塞クジラ",h:"じょうさいくじら",e:"Citadel Whale",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2350,de:2150,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの水属性モンスター２体をリリースして発動できる。\nこのカードを特殊召喚する。\n②：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「潜海奇襲」１枚を選んで自分フィールドにセットする。\n③：１ターンに１度、自分フィールドの水属性モンスター１体のみを対象とする魔法・罠・モンスターの効果を相手が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"海竜神の怒り",h:"りばいあさんのいかり",e:"Rage of Kairyu-Shin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：フィールドに「海」が存在する場合、自分フィールドの元々のレベルが５以上の水属性モンスターの数まで、相手フィールドのモンスターを対象として発動できる。\nそのモンスターを破壊する。\n次のターンの終了時まで、そのモンスターが存在していたゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"潜海奇襲",h:"しー・すてるす・あたっく",e:"Sea Stealth Attack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードの発動時の効果処理として、自分の手札・墓地から「海」１枚を選んで発動できる。\n②：フィールドに「海」が存在する場合、表側表示のこのカードは以下の効果を得る。\n\n●１ターンに１度、自分フィールドの表側表示の水属性モンスター１体をエンドフェイズまで除外して発動できる。\nこのターン、自分フィールドの表側表示の魔法・罠カードは相手の効果では破壊されない。\n\n●元々のレベルが５以上の自分の水属性モンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動する。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ダーク・カノン",h:"さいばー・だーく・かのん",e:"Cyberdark Cannon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから機械族の「サイバー・ダーク」モンスター１体を手札に加える。\n②：このカードを装備したモンスターが戦闘を行うダメージ計算時に発動できる。\nデッキからモンスター１体を墓地へ送る。\n③：モンスターに装備されているこのカードが墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ダーク・クロー",h:"さいばー・だーく・くろー",e:"Cyberdark Claw",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「サイバーダーク」魔法・罠カード１枚を手札に加える。\n②：このカードを装備したモンスターが戦闘を行うダメージ計算時に発動できる。\n自分のEXデッキからモンスター１体を墓地へ送る。\n③：モンスターに装備されているこのカードが墓地へ送られた場合、自分の墓地の「サイバー・ダーク」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧獄竜－サイバー・ダークネス・ドラゴン",h:"がいごくりゅう－さいばー・だーくねす・どらごん",e:"Cyberdarkness Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「サイバー・ダーク」効果モンスター×５\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分の墓地からドラゴン族モンスターまたは機械族モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：相手が魔法・罠・モンスターの効果を発動した時、自分フィールドの装備カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバーダーク・インフェルノ",h:"さいばーだーく・いんふぇるの",e:"Cyberdark Inferno",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備カードを装備した自分フィールドの「サイバー・ダーク」効果モンスターは、相手の効果の対象にならず、相手の効果では破壊されない。\n②：自分フィールドの「サイバー・ダーク」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、その後「サイバー・ダーク」モンスター１体を召喚できる。\n③：フィールドのこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミキサーロイド",h:"みきさーろいど",e:"Mixeroid",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの機械族モンスター１体をリリースして発動できる。\nデッキから風属性以外の「ロイド」モンスター１体を特殊召喚する。\n②：LPを半分払い、自分の墓地からこのカードを含む機械族モンスターを任意の数だけ除外して発動できる。\n除外したモンスターの数と同じレベルの「ロイド」融合モンスター１体をEXデッキから召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"スーパービークロイド－モビルベース",h:"すーぱーびーくろいど－もびるべーす",e:"Super Vehicroid - Mobile Base",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:0,de:5000,tx:"「ロイド」融合モンスター＋「ロイド」モンスター\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力以下の攻撃力を持つ「ロイド」モンスター１体をデッキ・EXデッキから特殊召喚する。\n②：自分・相手のエンドフェイズにこのカード以外の自分のメインモンスターゾーンの「ロイド」モンスター１体を対象として発動できる。\nその自分のモンスターを持ち主の手札に戻し、このカードの位置をそのモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガロイド都市",h:"めがろいどしてぃ",e:"Megaroid City",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分は融合モンスターしかEXデッキから特殊召喚できない。\n①：このカード以外の自分フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「ロイド」カード１枚を手札に加える。\n②：自分の「ロイド」モンスターが戦闘を行うダメージ計算時に、デッキから「ロイド」モンスター１体を墓地へ送って発動できる。\nその戦闘を行う自分のモンスターはそのダメージ計算時のみ、元々の攻撃力と元々の守備力が入れ替わる。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・ロイド・コール",h:"れっど・ろいど・こーる",e:"Emergeroid Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ロイド」融合モンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にする。\nその後、発動したそのプレイヤーのデッキ・EXデッキから同名カードを全て墓地へ送る。\n②：墓地のこのカードを除外し、自分の墓地の「ロイド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーター・ドラゴン－クラスター",h:"うぉーたー・どらごん－くらすたー",e:"Water Dragon Cluster",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカードは通常召喚できない。\n「ボンディング」魔法・罠カードの効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動できる。\n相手フィールドの効果モンスターはターン終了時まで、攻撃力が０になり、効果を発動できない。\n②：このカードをリリースして発動できる。\n手札・デッキから「ウォーター・ドラゴン」２体を召喚条件を無視して守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"デューテリオン",h:"でゅーてりおん",e:"Duoterion",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズにこのカードを手札から捨てて発動できる。\nデッキから「ボンディング」魔法・罠カード１枚を手札に加える。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地の「ハイドロゲドン」「オキシゲドン」「デューテリオン」のいずれか１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ボンディング－D２O",h:"ぼんでぃんぐ－でぃーつーおー",e:"Bonding - D2O",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドの「デューテリオン」２体と「オキシゲドン」１体をリリースして発動できる。\n自分の手札・デッキ・墓地から「ウォーター・ドラゴン」または「ウォーター・ドラゴン－クラスター」１体を選んで「ボンディング－H２O」の効果扱いとして特殊召喚する。\n②：このカードが墓地に存在し、「ウォーター・ドラゴン」または「ウォーター・ドラゴン－クラスター」がフィールドから自分の墓地へ送られた場合に発動する。\n墓地のこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ボンディング－DHO",h:"ぼんでぃんぐ－でぃーえいちおー",e:"Bonding - DHO",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札・墓地から「デューテリオン」「ハイドロゲドン」「オキシゲドン」を１体ずつデッキに戻して発動できる。\n自分の手札・墓地から「ウォーター・ドラゴン－クラスター」１体を選んで特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n自分のデッキ・墓地から「ウォーター・ドラゴン」または「ウォーター・ドラゴン－クラスター」１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デジトロン",h:"でじとろん",e:"Digitron",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:1500,de:0,tx:"電子空間で見つけた亜種。\nその情報量は心なしか少し多い。",li:"",lk:[],ta:[],al:[]},
  {n:"ドットスケーパー",h:"どっとすけーぱー",e:"Dotscaper",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できず、それぞれデュエル中に１度しか使用できない。\n①：このカードが墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードが除外された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クラインアント",h:"くらいんあんと",e:"Cliant",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：通常召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドのサイバース族モンスターの攻撃力・守備力は、自分ターンの間５００アップする。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりに自分の手札・フィールドのサイバース族モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"バックリンカー",h:"ばっくりんかー",e:"Backlinker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：EXモンスターゾーンに相手モンスターのみが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードをリリースして発動できる。\nEXモンスターゾーンのモンスターを全て持ち主のデッキに戻す。\nこの効果の発動後、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"バランサーロード",h:"ばらんさーろーど",e:"Balancer Lord",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、１０００LPを払って発動できる。\nこのターン自分は通常召喚に加えて１度だけ、自分メインフェイズにサイバース族モンスター１体を召喚できる。\n②：このカードが除外された場合に発動できる。\n手札からレベル４以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ROMクラウディア",h:"ろむくらうでぃあ",e:"ROM Cloudia",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが召喚に成功した時、「ROMクラウディア」以外の自分の墓地のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「ROMクラウディア」以外のレベル４以下のサイバース族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブート・スタッガード",h:"ぶーと・すたっがーど",e:"Boot Staggered",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2300,de:500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにサイバース族モンスターが召喚された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分フィールドに「スタッグトークン」（サイバース族・地・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアル・アセンブルム",h:"でゅある・あせんぶるむ",e:"Dual Assembwurm",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、手札及び自分フィールドの表側表示モンスターの中から、サイバース族モンスター２体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は半分になる。\n②：１ターンに１度、手札を１枚除外して発動できる。\nこのカードの攻撃力以下の攻撃力を持つフィールドのモンスター１体を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・バックドア",h:"さいばねっと・ばっくどあ",e:"Cynet Backdoor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのサイバース族モンスター１体を対象として発動できる。\nそのモンスターを除外し、そのモンスターの元々の攻撃力より低い攻撃力を持つサイバース族モンスター１体をデッキから手札に加える。\nこの効果で除外したモンスターは次の自分スタンバイフェイズにフィールドに戻り、そのターン直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"リコーデッド・アライブ",h:"りこーでっど・あらいぶ",e:"Recoded Alive",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のフィールド・墓地のリンク３のサイバース族リンクモンスター１体を対象として発動できる。\nそのモンスターを除外し、EXデッキから「コード・トーカー」モンスター１体を特殊召喚する。\n②：EXモンスターゾーンに自分のモンスターが存在しない場合、墓地のこのカードを除外し、除外されている自分の「コード・トーカー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エンコード・トーカー",h:"えんこーど・とーかー",e:"Encode Talker",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"サイバース族モンスター２体以上\n①：１ターンに１度、このカードのリンク先の自分のモンスターが、そのモンスターより攻撃力が高い相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。\nそのダメージ計算後、このカードまたはこのカードのリンク先の自分のモンスター１体を選び、その攻撃力をターン終了時まで、その戦闘を行った相手モンスターの攻撃力分アップする。",li:"",lk:["上","下","右下"],ta:[],al:[]},
  {n:"トライゲート・ウィザード",h:"とらいげーと・うぃざーど",e:"Tri-Gate Wizard",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2200,de:"-",tx:"トークン以外のモンスター２体以上\n①：このカードと相互リンクしているモンスターの数によって以下の効果を得る。\n\n●１体以上：このカードと相互リンクしているモンスターが相手モンスターと戦闘を行う場合、そのモンスターが相手に与える戦闘ダメージは倍になる。\n\n●２体以上：１ターンに１度、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\n\n●３体：１ターンに１度、魔法・罠・モンスターの効果が発動した時に発動できる。\nその発動を無効にし除外する。",li:"",lk:["上","左","右"],ta:[],al:[]},
  {n:"バイナル・ソーサレス",h:"ばいなる・そーされす",e:"Binary Sorceress",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"トークン以外のモンスター２体\n①：このカードと相互リンクしているモンスターの数によって以下の効果を得る。\n\n●１体以上：このカードと相互リンクしているモンスターが相手モンスターとの戦闘で相手に戦闘ダメージを与えた時に発動できる。\nその数値分だけ自分のLPを回復する。\n\n●２体：１ターンに１度、自分フィールドの表側表示モンスター２体を対象として発動できる。\nターン終了時まで、そのモンスター２体の内１体の攻撃力を半分にし、その数値分もう１体の攻撃力をアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"リンク・ディサイプル",h:"りんく・でぃさいぷる",e:"Link Disciple",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:500,de:"-",tx:"レベル４以下のサイバース族モンスター１体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先のモンスター１体をリリースして発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んでデッキの一番下に戻す。",li:"",lk:["下"],ta:[],al:[]},
  {n:"堕ち武者",h:"です・さむらい",e:"Samurai Skull",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキからアンデット族モンスター１体を墓地へ送る。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\nデッキから「堕ち武者」以外のレベル４以下のアンデット族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リヴェンデット・スレイヤー",h:"りう゛ぇんでっと・すれいやー",e:"Revendread Slayer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが相手モンスターと戦闘を行うダメージ計算時に１度、自分の墓地からアンデット族モンスター１体を除外して発動できる。\nこのカードの攻撃力は３００アップする。\n②：儀式召喚したこのカードが墓地へ送られた場合に発動できる。\nデッキから儀式魔法カード１枚を手札に加え、デッキから「ヴェンデット」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・レヴナント",h:"う゛ぇんでっと・れう゛なんと",e:"Vendread Revenants",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・ヘルハウンド",h:"う゛ぇんでっと・へるはうんど",e:"Vendread Houndhorde",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、手札から「ヴェンデット」カード１枚を捨てて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"リヴェンデット・ボーン",h:"りう゛ぇんでっと・ぼーん",e:"Revendread Origin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ヴェンデット」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地のアンデット族モンスターを除外し、自分の手札・墓地から「ヴェンデット」儀式モンスター１体を儀式召喚する。\n②：自分フィールドの「リヴェンデット・スレイヤー」が戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・リボーン",h:"う゛ぇんでっと・りぼーん",e:"Vendread Reorigin",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターをリリースし、その元々のレベルと同じレベルを持つ「ヴェンデットトークン」（アンデット族・闇・攻／守０）１体を自分フィールドに特殊召喚する。\nこの効果で特殊召喚したトークンがモンスターゾーンに存在する限り、自分は「ヴェンデット」モンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ソニックマイスター",h:"ふぉーみゅらあすりーとそにっくまいすたー",e:"F.A. Sonic Meister",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、このカードのレベルよりも元々のレベルまたはランクが低い相手モンスターとの戦闘では破壊されない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上の場合、このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ハングオンマッハ",h:"ふぉーみゅらあすりーとはんぐおんまっは",e:"F.A. Hang On Mach",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、このカードのレベルよりも元々のレベルまたはランクが低い相手モンスターが発動した効果を受けない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上の場合、相手の墓地へ送られるカードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.サーキットGP",h:"ふぉーみゅらあすりーとさーきっとぐらんぷり",e:"F.A. Circuit Grand Prix",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、フィールドの「F.A.」モンスターのレベルはバトルフェイズの間だけ２つ上がる。\n②：１ターンに１度、自分の「F.A.」モンスターが戦闘で相手モンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。\n③：フィールドの表側表示のこのカードが効果で破壊された場合に発動できる。\nデッキから「F.A.サーキットGP」以外の「F.A.」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ダウンフォース",h:"ふぉーみゅらあすりーとだうんふぉーす",e:"F.A. Downforce",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「F.A.」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ上げる。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの「F.A.」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ上げる。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ディフェクト・コンパイラー",h:"でぃふぇくと・こんぱいらー",e:"Defect Compiler",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、相手の効果で自分がダメージを受ける場合、代わりにこのカードにディフェクトカウンターを１つ置く（最大１つまで）。\n②：１ターンに１度、このカードのディフェクトカウンターを１つ取り除き、自分フィールドのサイバース族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで８００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"コンデンサー・デスストーカー",h:"こんでんさー・ですすとーかー",e:"Capacitor Stalker",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、このカード以外の自分フィールドのサイバース族モンスター１体を対象として発動できる。\nこのモンスターが表側表示で存在する間、そのモンスターの攻撃力は８００アップする。\n②：モンスターゾーンのこのカードが効果で破壊され墓地へ送られた場合に発動する。\nお互いのプレイヤーは８００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"リンク・インフライヤー",h:"りんく・いんふらいやー",e:"Link Infra-Flier",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードはフィールドのリンクモンスターのリンク先となる自分フィールドに手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・ナルキッス",h:"とりっくすたー・なるきっす",e:"Trickstar Narkissus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手が効果ダメージを受けた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手が手札・墓地のモンスターの効果を発動する度に、相手に２００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・エンジェル",h:"だーく・えんじぇる",e:"Dark Angel",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分の天使族モンスターが攻撃対象に選択された時、そのモンスターをリリースし、このカードを手札から墓地へ送り、自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターに攻撃対象を移し替え、対象のモンスターの攻撃力はターン終了時まで、リリースした天使族モンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ヘッドバット",h:"ごうきへっどばっと",e:"Gouki Headbatt",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札にある場合、このカード以外の「剛鬼」モンスター１体を手札から墓地へ送り、自分フィールドの「剛鬼」モンスター１体を対象として発動できる。\nこのカードを手札から守備表示で特殊召喚し、対象のモンスターの攻撃力をターン終了時まで８００アップする。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ヘッドバット」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲートウェイ・ドラゴン",h:"げーとうぇい・どらごん",e:"Gateway Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：相手フィールドにリンクモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札からレベル４以下のドラゴン族・闇属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スニッフィング・ドラゴン",h:"すにっふぃんぐ・どらごん",e:"Sniffer Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「スニッフィング・ドラゴン」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アネスヴァレット・ドラゴン",h:"あねすう゛ぁれっと・どらごん",e:"Anesthrokket Dragon",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを対象とするリンクモンスターの効果が発動した時に発動できる。\nこのカードを破壊する。\nその後、フィールドの表側表示モンスター１体を選ぶ。\nそのモンスターは攻撃できず、効果は無効化される。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「アネスヴァレット・ドラゴン」以外の「ヴァレット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オートヴァレット・ドラゴン",h:"おーとう゛ぁれっと・どらごん",e:"Autorokket Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを対象とするリンクモンスターの効果が発動した時に発動できる。\nこのカードを破壊する。\nその後、フィールドの魔法・罠カード１枚を選んで墓地へ送る。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「オートヴァレット・ドラゴン」以外の「ヴァレット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マグナヴァレット・ドラゴン",h:"まぐなう゛ぁれっと・どらごん",e:"Magnarokket Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを対象とするリンクモンスターの効果が発動した時に発動できる。\nこのカードを破壊する。\nその後、フィールドのモンスター１体を選んで墓地へ送る。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「マグナヴァレット・ドラゴン」以外の「ヴァレット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・マリオネッター",h:"おるたーがいすと・まりおねったー",e:"Altergeist Marionetter",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「オルターガイスト」罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：自分フィールドの「オルターガイスト」カード１枚と、自分の墓地の「オルターガイスト」モンスター１体を対象として発動できる。\n対象のフィールドのカードを墓地へ送り、対象の墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・シルキタス",h:"おるたーがいすと・しるきたす",e:"Altergeist Silquitous",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドの「オルターガイスト」カード１枚を持ち主の手札に戻し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合、自分の墓地の「オルターガイスト」罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・メリュシーク",h:"おるたーがいすと・めりゅしーく",e:"Altergeist Meluseek",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは直接攻撃できる。\n②：このカードが相手に戦闘ダメージを与えた時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを墓地へ送る。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「オルターガイスト・メリュシーク」以外の「オルターガイスト」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・クンティエリ",h:"おるたーがいすと・くんてぃえり",e:"Altergeist Kunquery",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2400,tx:"①：自分フィールドに「オルターガイスト」カードが存在する場合、相手モンスターの攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚し、その攻撃を無効にする。\n②：このカードが特殊召喚に成功した場合、相手フィールドの表側表示のカード１枚を対象として発動できる。\nこのモンスターが表側表示で存在する間、そのカードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"クローラー・スパイン",h:"くろーらー・すぱいん",e:"Krawler Spine",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・スパイン」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"クローラー・アクソン",h:"くろーらー・あくそん",e:"Krawler Axon",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・アクソン」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"クローラー・グリア",h:"くろーらー・ぐりあ",e:"Krawler Glial",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:700,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\n自分の手札・墓地から「クローラー・グリア」以外の「クローラー」モンスター１体を選んで表側攻撃表示または裏側守備表示で特殊召喚する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・グリア」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"クローラー・レセプター",h:"くろーらー・れせぷたー",e:"Krawler Receptor",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「クローラー」モンスター１体を手札に加える。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・レセプター」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"クローラー・ランヴィエ",h:"くろーらー・らんう゛ぃえ",e:"Krawler Ranvier",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合、自分の墓地の「クローラー」モンスターを２体まで対象として発動できる。\nそのモンスターを手札に加える。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・ランヴィエ」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"クローラー・デンドライト",h:"くろーらー・でんどらいと",e:"Krawler Dendrite",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:1300,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキからモンスター１体を墓地へ送る。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・デンドライト」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物－『星鎧』",h:"せいいぶつ－『せいがい』",e:"World Legacy - \"World Armor\"",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：モンスターが反転召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星遺物」カード１枚を手札に加える。\n③：通常召喚したこのカードが存在する場合、EXデッキから特殊召喚された相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとこのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・ラグナロク",h:"めたふぁいず・らぐなろく",e:"Metaphys Ragnarok",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分のデッキの上からカードを３枚除外する。\nこのカードの攻撃力は、この効果で除外した「メタファイズ」カードの数×３００アップする。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\nデッキからレベル５以上の「メタファイズ」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・ダイダロス",h:"めたふぁいず・だいだろす",e:"Metaphys Daedalus",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1500,tx:"①：このカードが「メタファイズ」モンスターの効果で特殊召喚に成功した場合に発動できる。\nこのカード以外のフィールドの特殊召喚された表側表示モンスターを全て除外する。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに除外されているこのカードをデッキに戻して発動できる。\nデッキから「メタファイズ・ダイダロス」以外の「メタファイズ」カード１枚を除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・ネフティス",h:"めたふぁいず・ねふてぃす",e:"Metaphys Nephthys",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"①：このカードが「メタファイズ」モンスターの効果で特殊召喚に成功した場合に発動できる。\nフィールドにセットされた魔法・罠カードを全て除外する。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに除外されているこのカードをデッキに戻して発動できる。\nデッキから「メタファイズ・ネフティス」以外の「メタファイズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・タイラント・ドラゴン",h:"めたふぁいず・たいらんと・どらごん",e:"Metaphys Tyrant Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2900,de:2500,tx:"①：「メタファイズ」モンスターの効果で特殊召喚したこのカードは罠カードの効果を受けず、このカードがモンスターを攻撃した場合もう１度だけ続けて攻撃できる。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに除外されているこのカードをデッキに戻して発動できる。\n手札から「メタファイズ」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・エグゼキューター",h:"めたふぁいず・えぐぜきゅーたー",e:"Metaphys Executor",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"幻竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地及び自分フィールドの表側表示のカードの中から、「メタファイズ」カード５種類を１枚ずつ除外した場合のみ特殊召喚できる。\n①：フィールドのこのカードは効果では破壊されず、効果では除外できない。\n②：相手フィールドのカードの数が自分フィールドのカードより多い場合、１ターンに１度、除外されている自分の「メタファイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"水精鱗－ネレイアビス",h:"まーめいる－ねれいあびす",e:"Mermail Abyssnerei",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分フィールドの水属性モンスター１体を対象として発動できる。\nそのモンスター以外の自分の手札・フィールドの水属性モンスター１体を選んで破壊し、対象のモンスターの攻撃力・守備力はターン終了時まで、この効果で破壊したモンスターの元々の数値分アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎王獣 ガネーシャ",h:"えんおうじゅう　がねーしゃ",e:"Fire King Avatar Arvata",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドに存在し、モンスターの効果が発動した時に発動できる。\nその発動を無効にし、このカード以外の自分の手札・フィールドの炎属性モンスター１体を選んで破壊する。\n②：このカードが破壊され墓地へ送られた場合、「炎王獣 ガネーシャ」以外の自分の墓地の炎属性の獣族・獣戦士族・鳥獣族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"幻獣機ライテン",h:"げんじゅうきらいてん",e:"Mecha Phantom Beast Raiten",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：手札を１枚捨てて発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「幻獣機」モンスターしか融合・S・X・リンク召喚の素材にできない。\n②：このカードのレベルは自分フィールドの「幻獣機トークン」のレベルの合計分だけ上がる。\n③：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ザ・アキュムレーター",h:"ざ・あきゅむれーたー",e:"The Accumulator",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードの攻撃力は、フィールドのリンクモンスターのリンクマーカーの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"兵隊竜",h:"そるじゃー・どらごん",e:"Soldier Dragons",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:700,de:800,tx:"①：１ターンに１度、相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nデッキからレベル２以下のドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダックドロッパー",h:"だっくどろっぱー",e:"Duck Dummy",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["デュアル","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードは効果の対象にならず、効果では破壊されない。\n\n●このカードがモンスターゾーンに存在する限り、相手モンスターの攻撃は全て直接攻撃になる。",li:"",lk:[],ta:[],al:[]},
  {n:"比翼レンリン",h:"ひよくれんりん",e:"Leng Ling",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:0,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの元々の攻撃力は１０００になり、１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アリジバク",h:"ありじばく",e:"Self-Destruct Ant",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動する。\nお互いのプレイヤーは１０００ダメージを受ける。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動する。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"天岩戸",h:"あまのいわと",e:"Amano-Iwato",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["スピリット","効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードは特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、お互いにスピリットモンスター以外のモンスターの効果を発動できない。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"旋風機ストリボーグ",h:"せんぷうきすとりぼーぐ",e:"Fantastic Striborg",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカードを表側表示でアドバンス召喚する場合にリリースしたモンスターは墓地へ送らず持ち主の手札に戻す。\n①：１ターンに１度、手札を１枚捨てて発動できる。\nこのカードと同じ縦列の相手フィールドのカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"亡龍の戦慄－デストルドー",h:"ぼうりゅうのせんりつ－ですとるどー",e:"Destrudo the Lost Dragon's Frisson",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:3000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、LPを半分払い、自分フィールドのレベル６以下のモンスター１体を対象として発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、レベルが対象のモンスターのレベル分だけ下がり、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊神后 ドリアード",h:"えれめんたるぐれいす　どりあーど",e:"Elemental Grace Doriado",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分・相手の墓地のモンスターの属性が６種類以上の場合のみ特殊召喚できる。\n①：このカードの攻撃力・守備力は、自分・相手の墓地のモンスターの属性の種類×５００アップする。\n②：相手がモンスターを特殊召喚する際に、自分の墓地のモンスター３体を除外して発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"素早いビーバー",h:"すばやいびーばー",e:"Nimble Beaver",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:400,de:100,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のデッキ・墓地からレベル３以下の「素早い」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"衛生兵マッスラー",h:"えいせいへいまっすらー",e:"Muscle Medic",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:100,tx:"①：このカードの戦闘で自分または相手が戦闘ダメージを受ける場合、代わりにその数値分だけLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレルロード・ドラゴン",h:"う゛ぁれるろーど・どらごん",e:"Borreload Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター３体以上\n①：このカードはモンスターの効果の対象にならない。\n②：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は５００ダウンする。\nこの効果の発動に対して相手はカードの効果を発動できない。\nこの効果は相手ターンでも発動できる。\n③：このカードが相手モンスターに攻撃するダメージステップ開始時に発動できる。\nその相手モンスターをこのカードのリンク先に置いてコントロールを得る。\nそのモンスターは次のターンのエンドフェイズに墓地へ送られる。",li:"",lk:["左","右","左下","右下"],ta:[],al:[]},
  {n:"リンク・バンパー",h:"りんく・ばんぱー",e:"Link Bumper",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"サイバース族モンスター２体\n①：１ターンに１度、このカードのリンク先の自分のモンスターが相手のリンクモンスターに攻撃したダメージステップ終了時に発動できる。\nこのバトルフェイズ中、そのモンスターは通常の攻撃に加えて、このカード以外の自分フィールドのリンクモンスターの数まで、相手のリンクモンスターに攻撃できる。\nこの効果を発動するターン、そのモンスター以外の自分のモンスターは攻撃できない。",li:"",lk:["上","左"],ta:[],al:[]},
  {n:"トリックスター・スイートデビル",h:"とりっくすたー・すいーとでびる",e:"Trickstar Black Catbat",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「トリックスター」モンスター２体\n①：このカードがモンスターゾーンに存在する限り、このカードのリンク先のモンスターが戦闘・効果で破壊され墓地へ送られる度に、相手に２００ダメージを与える。\n②：「トリックスター」モンスターの効果で相手がダメージを受ける度に発動する。\n相手フィールドの全ての表側表示モンスターの攻撃力はターン終了時まで、このカードのリンク先のモンスターの数×２００ダウンする。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"剛鬼サンダー・オーガ",h:"ごうきさんだー・おーが",e:"Gouki Thunder Ogre",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2200,de:"-",tx:"「剛鬼」モンスター２体以上\n①：このカードがモンスターゾーンに存在する限り、ターンプレイヤーは以下の効果を適用する。\n\n●自分メインフェイズに通常召喚に加えて１度だけ、このカードのリンク先となる自分フィールドに手札からモンスター１体を召喚できる。\n\n②：このカードのリンク先のモンスターが戦闘・効果で破壊された場合に発動できる。\nこのカードの攻撃力は４００アップする。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"ツイン・トライアングル・ドラゴン",h:"ついん・とらいあんぐる・どらごん",e:"Twin Triangle Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"トークン以外のレベル４以下のドラゴン族モンスター２体\n①：このカードがリンク召喚に成功した時、５００LPを払い、自分の墓地のレベル５以上のモンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターン攻撃できない。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"オルターガイスト・プライムバンシー",h:"おるたーがいすと・ぷらいむばんしー",e:"Altergeist Primebanshee",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:2100,de:"-",tx:"「オルターガイスト」モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズにこのカード以外の自分フィールドの「オルターガイスト」モンスター１体をリリースして発動できる。\nデッキから「オルターガイスト」モンスター１体をこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：このカードがフィールドから墓地へ送られた場合、自分の墓地の「オルターガイスト」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:["右","下","右下"],ta:[],al:[]},
  {n:"エクスクローラー・シナプシス",h:"えくすくろーらー・しなぷしす",e:"X-Krawler Synaphysis",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"地属性モンスター２体\n①：このカードのリンク先の「クローラー」モンスターは戦闘では破壊されず、攻撃力・守備力が３００アップし、１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、自分の墓地の「クローラー」モンスター２体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"エクスクローラー・ニューロゴス",h:"えくすくろーらー・にゅーろごす",e:"X-Krawler Neurogos",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"昆虫族モンスター２体\n①：このカードのリンク先の「クローラー」モンスターは戦闘では破壊されず、攻撃力・守備力が３００アップし、相手モンスターと戦闘を行う場合、相手に与える戦闘ダメージは倍になる。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、自分の墓地の「クローラー」モンスター２体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"エクスクローラー・クオリアーク",h:"えくすくろーらー・くおりあーく",e:"X-Krawler Qualiark",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「クローラー」モンスター２体\n①：自分フィールドの「クローラー」モンスターの数によって以下を適用する。\n\n●２体以上：自分フィールドのモンスターの攻撃力・守備力は３００アップする。\n\n●４体以上：相手はバトルフェイズ中に効果を発動できない。\n\n●６体以上：自分のモンスターは直接攻撃できる。\n\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、自分の墓地の「クローラー」モンスター２体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"アカシック・マジシャン",h:"あかしっく・まじしゃん",e:"Akashic Magician",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1700,de:"-",tx:"トークン以外の同じ種族のモンスター２体\n自分は「アカシック・マジシャン」を１ターンに１度しかリンク召喚できない。\n①：このカードがリンク召喚に成功した場合に発動する。\nこのカードのリンク先のモンスターを全て持ち主の手札に戻す。\n②：１ターンに１度、カード名を１つ宣言して発動できる。\nこのカードの相互リンク先のモンスターのリンクマーカーの合計分だけ自分のデッキの上からカードをめくり、その中に宣言したカードがあった場合、そのカードを手札に加える。\nそれ以外のめくったカードは全て墓地へ送る。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"マスター・ボーイ",h:"ますたー・ぼーい",e:"Mistar Boy",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"水属性モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの水属性モンスターの攻撃力・守備力は５００アップし、炎属性モンスターの攻撃力・守備力は４００ダウンする。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地の水属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"セキュリティ・ブロック",h:"せきゅりてぃ・ぶろっく",e:"Security Block",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドのサイバース族モンスター１体を対象として発動できる。\nこのターン、そのモンスターは戦闘では破壊されず、お互いが受ける全ての戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴノイド・ジェネレーター",h:"どらごのいど・じぇねれーたー",e:"Dragonoid Generator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１０００LPを払ってこのカードを発動できる。\nこのカード名の①の効果は１ターンに２度まで使用できる。\n①：自分メインフェイズに発動できる。\n「ドラゴノイドトークン」（機械族・地・星１・攻／守３００）１体を自分フィールドに攻撃表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。\nこのターンのエンドフェイズに、相手は「ドラゴノイドトークン」１体を自身のフィールドに攻撃表示で特殊召喚しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"スクイブ・ドロー",h:"すくいぶ・どろー",e:"Squib Draw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ヴァレット」モンスター１体を対象として発動できる。\nそのモンスターを破壊し、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"クイック・リボルブ",h:"くいっく・りぼるぶ",e:"Quick Launch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：デッキから「ヴァレット」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物に差す影",h:"せいいぶつにさすかげ",e:"World Legacy in Shadow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「クローラー」モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札からレベル２以下の昆虫族モンスター１体を表側守備表示または裏側守備表示で特殊召喚する。\n③：自分のリバースモンスターが相手モンスターとの戦闘で破壊された時に発動できる。\nその相手モンスターを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物を巡る戦い",h:"せいいぶつをめぐるたたかい",e:"World Legacy Clash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体をエンドフェイズまで除外し、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力は、このカードを発動するために除外したモンスターのそれぞれの元々の数値分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・ファクター",h:"めたふぁいず・ふぁくたー",e:"Metaphys Factor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分はレベル５以上の「メタファイズ」モンスターを召喚する場合に必要なリリースをなくす事ができる。\nこの効果は１ターンに１度しか適用できない。\nこの効果を適用して召喚したモンスターは次のターンのエンドフェイズに除外される。\n②：このカードがフィールドゾーンに存在する限り、自分の「メタファイズ」モンスターの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アシンメタファイズ",h:"あしんめたふぁいず",e:"Asymmetaphys",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「メタファイズ」カード１枚を除外し、自分はデッキから１枚ドローする。\n②：１ターンに１度、自分の「メタファイズ」カードが除外された場合に発動する。\nそのターンによって以下の効果を適用する。\n●自分ターン：「メタファイズ」モンスター以外のフィールドの全てのモンスターの攻撃力・守備力は５００ダウンする。\n●相手ターン：「メタファイズ」モンスター以外のフィールドの全てのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンタイム・パスコード",h:"わんたいむ・ぱすこーど",e:"One-Time Passcode",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「セキュリティトークン」（サイバース族・光・星４・攻／守２０００）１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライバル・アライバル",h:"らいばる・あらいばる",e:"Arrivalrivals",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のバトルフェイズに発動できる。\nモンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"やりすぎた埋葬",h:"やりすぎたまいそう",e:"Overdone Burial",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札からモンスター１体を捨て、捨てたモンスターより元々のレベルが低い自分の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\n②：装備モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"心眼の祭殿",h:"しんがんのさいでん",e:"Temple of the Mind's Eye",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分または相手が戦闘ダメージを受ける場合、その数値は１０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"補充部隊",h:"ほじゅうぶたい",e:"Backup Squad",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：相手モンスターの攻撃または相手の効果で自分が１０００以上のダメージを受ける度に発動する。\nそのダメージ１０００につき１枚、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"燃え竹光",h:"もえたけみつ",e:"Burning Bamboo Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが既に魔法＆罠ゾーンに存在する状態で、自分が「竹光」カードを発動した場合に発動できる。\n次の相手メインフェイズ１をスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・ビーコン",h:"さいばーす・びーこん",e:"Cyberse Beacon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：戦闘または相手の効果で自分がダメージを受けたターンに発動できる。\nデッキからレベル４以下のサイバース族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"リンク・リスタート",h:"りんく・りすたーと",e:"Link Restart",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分にダメージを与えるモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし、自分の墓地からリンクモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リモート・リボーン",h:"りもーと・りぼーん",e:"Remote Rebirth",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドのリンクモンスターのリンク先となる相手のメインモンスターゾーンに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・カモフラージュ",h:"おるたーがいすと・かもふらーじゅ",e:"Altergeist Camouflage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「オルターガイスト」モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは相手モンスターの攻撃対象にされない。\n（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる。\n）②：装備モンスターを対象として発動した相手モンスターの効果は無効化される。\n③：自分フィールドの「オルターガイスト」カードが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・プロトコル",h:"おるたーがいすと・ぷろとこる",e:"Altergeist Protocol",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「オルターガイスト」カードの効果の発動及びその発動した効果は無効化されない。\n②：相手がモンスターの効果を発動した時、このカード以外の自分フィールドの表側表示の「オルターガイスト」カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"パーソナル・スプーフィング",h:"ぱーそなる・すぷーふぃんぐ",e:"Personal Spoofing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、手札及び自分フィールドの表側表示のカードの中から、「オルターガイスト」カード１枚を持ち主のデッキに戻して発動できる。\nデッキから「オルターガイスト」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の傀儡",h:"せいいぶつのかいらい",e:"World Legacy Pawns",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの裏側表示モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示または表側守備表示にする。\n②：自分の墓地の「クローラー」モンスター１体をデッキに戻し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物に蠢く罠",h:"せいいぶつにうごめくわな",e:"World Legacy Trap Globe",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地のカード、自分フィールドの表側表示のカード、除外されている自分のカードの中から、「星遺物に蠢く罠」以外の「星遺物」カード５種類を１枚ずつ選び、持ち主のデッキに加えてシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・ディメンション",h:"めたふぁいず・でぃめんしょん",e:"Metaphys Dimension",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの特殊召喚に成功した場合、除外されている自分の「メタファイズ」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに除外される。\n②：このカードが既に魔法＆罠ゾーンに存在する状態で、このカード以外の自分の「メタファイズ」カードが除外された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタバース",h:"めたばーす",e:"Metaverse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：デッキからフィールド魔法カード１枚を選び、手札に加えるか自分フィールドに発動する。",li:2,lk:[],ta:[],al:[]},
  {n:"拮抗勝負",h:"きっこうしょうぶ",e:"Evenly Matched",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドにカードが存在しない場合、このカードの発動は手札からもできる。\n①：相手フィールドのカードの数が自分フィールドのカードより多い場合、自分・相手のバトルフェイズ終了時に発動できる。\n自分フィールドのカードの数と同じになるように、相手は自身のフィールドのカードを選んで裏側表示で除外しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"導爆線",h:"どうばくせん",e:"Fuse Line",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このカードと同じ縦列のカード１枚を対象として、セットされたこのカードを発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"直通断線",h:"しゃっとらいん",e:"Broken Line",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：このカードと同じ縦列でモンスターの効果・魔法・罠カードが発動した時、セットされたこのカードを発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマデュオ",h:"おじゃまでゅお",e:"Ojama Duo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドに「おジャマトークン」（獣族・光・星２・攻０／守１０００）２体を守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。\n「おジャマトークン」が破壊された時にそのコントローラーは１体につき３００ダメージを受ける。\n②：墓地のこのカードを除外して発動できる。\nデッキからカード名が異なる「おジャマ」モンスター２体を特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"V－LAN ヒドラ",h:"ぶいらん　ひどら",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"トークン以外のモンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力はこのカードと相互リンクしているモンスターの数×３００アップする。\n②：このカードの相互リンク先のリンク３以下のモンスター１体を対象として発動できる。\nそのモンスターをリリースし、そのリンクマーカーの数だけ自分フィールドに「V－LANトークン」（サイバース族・光・星１・攻／守０）を特殊召喚する。\nこのターン自分は対象としたモンスターとリンクマーカーの数が同じモンスターを特殊召喚できない。",li:"",lk:["上","下","左"],ta:[],al:[]},
  {n:"白棘鱏",h:"ほわいと・すてぃんぐれい",e:"White Stingray",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは手札の水属性モンスター１体を捨てて、手札から特殊召喚できる。\n②：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nこのターン、このカードをチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD超視王ゼロ・マクスウェル",h:"でぃーでぃーでぃーちょうしおうぜろ・まくすうぇる",e:"D/D/D Supersight King Zero Maxwell",t:"monster",a:"闇",l:7,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの守備力をターン終了時まで０にする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:2500,tx:"①：このカードが相手の守備表示モンスターに攻撃するダメージ計算前に発動できる。\nその相手モンスターの守備力はダメージステップ終了時まで０になる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"インタラプト・レジスタンス",h:"いんたらぷと・れじすたんす",e:"Interrupt Resistor",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"①：自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は受けたダメージの数値分アップする。\n②：１ターンに１度、このカード以外の自分の守備表示モンスターが相手モンスターに攻撃されたダメージ計算時に発動できる。\nその自分のモンスターは、そのダメージ計算時のみこのカードと同じ守備力になり、その戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"影六武衆－フウマ",h:"かげろくぶしゅう－ふうま",e:"Secret Six Samurai - Fuma",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:1800,tx:"①：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「影六武衆－フウマ」以外の「六武衆」モンスター１体を特殊召喚する。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"影六武衆－ゲンバ",h:"かげろくぶしゅう－げんば",e:"Secret Six Samurai - Genba",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:2100,tx:"①：このカードが召喚に成功した時、除外されている自分の「六武衆」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"影六武衆－ハツメ",h:"かげろくぶしゅう－はつめ",e:"Secret Six Samurai - Hatsume",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地及び自分フィールドの表側表示モンスターの中から、「六武衆」モンスター２体を除外し、「影六武衆－ハツメ」以外の自分の墓地の「六武衆」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"影六武衆－ドウジ",h:"かげろくぶしゅう－どうじ",e:"Secret Six Samurai - Doji",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「六武衆」モンスターが召喚・特殊召喚された時に発動できる。\nデッキから「六武衆」カード１枚を墓地へ送る。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"影六武衆－キザル",h:"かげろくぶしゅう－きざる",e:"Secret Six Samurai - Kizaru",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"①：このカードが特殊召喚に成功した時に発動できる。\n自分フィールドに存在する属性以外の「六武衆」モンスター１体をデッキから手札に加える。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"影六武衆－リハン",h:"かげろくぶしゅう－りはん",e:"Secret Six Samurai - Rihan",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2400,tx:"属性が異なる「六武衆」モンスター×３\n自分フィールドの上記カードを墓地へ送った場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\nこのカードは融合素材にできない。\n①：１ターンに１度、手札及び自分フィールドの表側表示のカードの中から、「六武衆」カード１枚を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\n②：自分フィールドの「六武衆」モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の影忍術",h:"ろくぶしゅうのかげにんじゅつ",e:"Secret Skills of the Six Samurai",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスター１体を墓地へ送り、除外されている自分の「六武衆」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"忍の六武",h:"しのびのろくぶ",e:"The Six Shinobi",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「六武衆」モンスターが６体存在し、その属性が全て異なる場合に発動できる。\n次の相手ターンをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾の射手 カスパール",h:"まだんのしゃしゅ　かすぱーる",e:"Magical Musketeer Caspar",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。\n②：このカードと同じ縦列で魔法・罠カードが発動した場合に発動できる。\nその発動したカードとカード名が異なる「魔弾」カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾の射手 ドクトル",h:"まだんのしゃしゅ どくとる",e:"Magical Musketeer Doc",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。\n②：このカードと同じ縦列で魔法・罠カードが発動した場合に発動できる。\nその発動したカードとカード名が異なる「魔弾」カード１枚を自分の墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾の射手 ザ・キッド",h:"まだんのしゃしゅ ざ・きっど",e:"Magical Musketeer Kidbrave",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。\n②：このカードと同じ縦列で魔法・罠カードが発動した場合、手札から「魔弾」カード１枚を捨てて発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾の射手 スター",h:"まだんのしゃしゅ すたー",e:"Magical Musketeer Starfire",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:1700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。\n②：このカードと同じ縦列で魔法・罠カードが発動した場合に発動できる。\nデッキから「魔弾の射手 スター」以外のレベル４以下の「魔弾」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾の射手 カラミティ",h:"まだんのしゃしゅ からみてぃ",e:"Magical Musketeer Calamity",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。\n②：このカードと同じ縦列で魔法・罠カードが発動した場合、自分の墓地の「魔弾」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾の射手 ワイルド",h:"まだんのしゃしゅ わいるど",e:"Magical Musketeer Wild",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。\n②：このカードと同じ縦列で魔法・罠カードが発動した場合、自分の墓地の「魔弾」カード３枚を対象として発動できる。\nそのカード３枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾の悪魔 ザミエル",h:"まだんのあくま ざみえる",e:"Magical Musket Mastermind Zakiel",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは「魔弾」モンスター１体をリリースして表側表示でアドバンス召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。\n②：相手エンドフェイズに発動できる。\nこのターン、このカードが表側表示で存在する間に自分が発動した「魔弾」魔法・罠カードの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾－ネバー・エンドルフィン",h:"まだん－ねばー・えんどるふぃん",e:"Magical Musket - Steady Hands",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「魔弾」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで元々の数値の倍になる。\nこのカードを発動するターン、対象のモンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾－クロス・ドミネーター",h:"まだん－くろす・どみねーたー",e:"Magical Musket - Cross-Domination",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「魔弾」モンスターが存在する場合、フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力・守備力は０になり、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾－デスペラード",h:"まだん－ですぺらーど",e:"Magical Musket - Desperado",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「魔弾」モンスターが存在する場合、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾－ダンシング・ニードル",h:"まだん－だんしんぐ・にーどる",e:"Magical Musket - Dancing Needle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「魔弾」モンスターが存在する場合、お互いの墓地のカードを合計３枚まで対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾－デビルズ・ディール",h:"まだん－でびるず・でぃーる",e:"Magical Musket - Fiendish Deal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「魔弾」モンスターは効果では破壊されない。\n②：このカードが相手の効果で墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「魔弾－デビルズ・ディール」以外の「魔弾」カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾－デッドマンズ・バースト",h:"まだん－でっどまんず・ばーすと",e:"Magical Musket - Last Stand",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「魔弾」モンスターが存在する場合、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"雪天気シエル",h:"ゆきてんきしえる",e:"The Weather Painter Snow",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"雨天気ラズラ",h:"あめてんきらずら",e:"The Weather Painter Rain",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n手札から「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"曇天気スレット",h:"どんてんきすれっと",e:"The Weather Painter Cloud",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが自分フィールドに存在し、このカード以外の自分フィールドの表側表示の「天気」カードが墓地へ送られた場合、自分の墓地の「天気」魔法・罠カードを２枚まで対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"晴天気ベンガーラ",h:"せいてんきべんがーら",e:"The Weather Painter Sun",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドの表側表示の永続魔法・永続罠カード１枚を墓地へ送って発動できる。\nこのカードを守備表示で特殊召喚し、手札から「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"雷天気ターメル",h:"らいてんきたーめる",e:"The Weather Painter Thunder",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の永続魔法・永続罠カード１枚を墓地へ送って発動できる。\nデッキから「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"極天気ランブラ",h:"きょくてんきらんぶら",e:"The Weather Painter Aurora",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の手札・デッキ・墓地から「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「天気」魔法・罠カードは相手の効果の対象にならず、相手の効果では破壊されない。\n③：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"虹天気アルシエル",h:"にじてんきあるしえる",e:"The Weather Painter Rainbow",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"「天気」モンスター３体\n①：このカードのリンク先の「天気」効果モンスターは以下の効果を得る。\n●魔法・罠・モンスターの効果が発動した時、このカードを除外して発動できる。\nその発動を無効にし破壊する。\n②：相手がモンスターを特殊召喚する際に、リンク召喚したこのカードを墓地へ送って発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。\n③：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"雪の天気模様",h:"ゆきのてんきもよう",e:"The Weather Snowy Canvas",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「雪の天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードを除外して発動できる。\nデッキから「天気」カード１枚を手札に加える。\nこの効果の発動後、ターン終了時まで自分はドロー以外の方法でデッキからカードを手札に加える事はできない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"雨の天気模様",h:"あめのてんきもよう",e:"The Weather Rainy Canvas",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「雨の天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードを除外し、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"曇りの天気模様",h:"くもりのてんきもよう",e:"The Weather Cloudy Canvas",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「曇りの天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードを除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターの攻撃力は半分になり、直接攻撃できる。\nこの効果はダメージステップでは発動できず、相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"晴れの天気模様",h:"はれのてんきもよう",e:"The Weather Sunny Canvas",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「晴れの天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードを除外し、自分フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターをリリースし、そのモンスターとカード名が異なる「天気」モンスター１体を、自分の手札・墓地から選んで特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"雷の天気模様",h:"かみなりのてんきもよう",e:"The Weather Thundery Canvas",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「雷の天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードが相手モンスターと戦闘を行うダメージステップ開始時に、このカードを除外して発動できる。\nその相手モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"オーロラの天気模様",h:"おーろらのてんきもよう",e:"The Weather Auroral Canvas",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「オーロラの天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●カード１枚のみが自分または相手の手札に加わった時、このカードを除外して発動できる。\n手札に加わったそのカードを除外し、そのプレイヤーはデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・アクセラレーター",h:"さいばーす・あくせられーたー",e:"Cyberse Accelerator",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"トークン以外のモンスター２体以上\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分・相手のバトルフェイズにこのカードのリンク先のサイバース族モンスター１体を対象として以下の効果から１つを選択して発動できる。\nこの効果を発動するターン、このカードは攻撃できない。\n●そのモンスターの攻撃力はターン終了時まで２０００アップする。\n●このターン、そのモンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:["右","下","左"],ta:[],al:[]},
  {n:"リローダー・ドラゴン",h:"りろーだー・どらごん",e:"Overburst Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"「ヴァレット」モンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドのリンクモンスター１体を対象として発動できる。\n手札から「ヴァレット」モンスター１体を、そのカードのリンク先となる自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはリンク素材にできず、エンドフェイズに破壊される。\n②：このカードが戦闘で破壊され墓地へ送られた時、自分の墓地の「ヴァレット」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["右","左"],ta:[],al:[]},
  {n:"トリックスター・ブラッディマリー",h:"とりっくすたー・ぶらっでぃまりー",e:"Trickstar Crimson Heart",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「トリックスター」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードのリンク先に「トリックスター」モンスターが召喚・特殊召喚される度に、自分は２００LP回復する。\n②：手札から「トリックスター」カード１枚を捨てて発動できる。\nお互いのプレイヤーは、それぞれデッキから１枚ドローする。\nこの効果の発動時に自分のLPが相手より２０００以上多い場合、この効果で自分がドローする枚数は２枚になる。",li:"",lk:["右","左下"],ta:[],al:[]},
  {n:"剛鬼デストロイ・オーガ",h:"ごうきですとろい・おーが",e:"Gouki Destroy Ogre",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"「剛鬼」モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先のモンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n②：自分メインフェイズに発動できる。\n相手は自身の墓地からモンスターを２体まで選んで特殊召喚する。\nその後、特殊召喚したモンスターの数まで、自分はリンクモンスター以外の「剛鬼」モンスターを自分の墓地から選んでこのカードのリンク先に特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「剛鬼」モンスターしか特殊召喚できない。",li:"",lk:["右","右下","左下","左"],ta:[],al:[]},
  {n:"サイバース・コンバーター",h:"さいばーす・こんばーたー",e:"Cyberse Converter",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドのモンスターがサイバース族モンスターのみの場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚に成功した時、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの種族はターン終了時までサイバース族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"リンクリボー",h:"りんくりぼー",e:"Linkuriboh",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:300,de:"-",tx:"レベル１モンスター１体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時、このカードをリリースして発動できる。\nその相手モンスターの攻撃力はターン終了時まで０になる。\n②：このカードが墓地に存在する場合、自分フィールドのレベル１モンスター１体をリリースして発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:["下"],ta:[],al:[]},
  {n:"ユニティ・オブ・ドラゴン",h:"ゆにてぃ・おぶ・どらごん",e:"Sanctity of Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"ドラゴン族の効果モンスター３体以上\nこのカードはリンク召喚でしか特殊召喚できない。\nEXリンク状態のこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:["左","右","左下","下","右下"],ta:[],al:[]},
  {n:"革命の鉄騎士",h:"かくめいのてつきし",e:"Iron Knight of Revolution",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"機械族の効果モンスター３体以上\nこのカードはリンク召喚でしか特殊召喚できない。\nEXリンク状態のこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"イーバ",h:"いーば",e:"Eva",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、このカード以外の自分のフィールド・墓地の天使族・光属性モンスターを２体まで除外して発動できる。\n除外した数だけ、デッキから「イーバ」以外のレベル２以下の天使族・光属性モンスターを手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"天空聖騎士アークパーシアス",h:"えんじぇるぱらでぃんあーくぱーしあす",e:"Sacred Arch-Airknight Parshath",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"①：このカードが手札・墓地に存在し、自分がカウンター罠カードを発動した場合、または自分がモンスターの効果・魔法・罠カードの発動を無効にした場合、自分の手札・フィールド・墓地からこのカード以外の天使族モンスター２体を除外して発動できる。\nこのカードを特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが相手に戦闘ダメージを与えた時に発動できる。\nデッキから「パーシアス」カードまたはカウンター罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天空賢者ミネルヴァ",h:"えんじぇるせーじみねるう゛ぁ",e:"Minerva, Scholar of the Sky",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、このカードの攻撃力は５００アップし、フィールドに「天空の聖域」が存在する場合、さらにその発動したカウンター罠カードとカード名が異なるカウンター罠カード１枚を自分の墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"力天使ヴァルキリア",h:"りきてんしう゛ぁるきりあ",e:"Power Angel Valkyria",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1050,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分がモンスターの効果・魔法・罠カードの発動を無効にした場合に発動する。\nデッキから天使族・光属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"パーシアスの神域",h:"ぱーしあすのしんいき",e:"The Sanctum of Parshath",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「天空の聖域」として扱う。\n②：このカードが魔法＆罠ゾーンに存在する限り、フィールドの天使族モンスターの攻撃力・守備力は３００アップし、フィールドにセットされた魔法・罠カードは効果の対象にならず、効果では破壊されない。\n③：１ターンに１度、自分の墓地の天使族モンスター及びカウンター罠カードの中から、合計３枚を対象として発動できる（同名カードは１枚まで）。\nそのカードを好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"輪廻のパーシアス",h:"りんねのぱーしあす",e:"Rebirth of Parshath",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：モンスターの効果・魔法・罠カードが発動した時、手札のカウンター罠カード１枚を相手に見せ、手札を１枚捨て、１０００LPを払って発動できる。\nその発動を無効にし、そのカードを持ち主のデッキに戻す。\nその後、デッキ・EXデッキから「パーシアス」モンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"六武衆の真影",h:"ろくぶしゅうのしんえい",e:"Legendary Secret of the Six Samurai",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分が「影六武衆」モンスターの召喚・特殊召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分の墓地からレベル４以下の「六武衆」モンスター１体を除外して発動できる。\nターン終了時まで、このカードの属性・レベル・攻撃力・守備力は、除外したそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"竜騎士ブラック・マジシャン",h:"りゅうきしぶらっく・まじしゃん",e:"Dark Magician the Dragon Knight",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「ブラック・マジシャン」＋ドラゴン族モンスター\n①：このカードのカード名は、フィールド・墓地に存在する限り「ブラック・マジシャン」として扱う。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの魔法・罠カードは相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"キメラテック・メガフリート・ドラゴン",h:"きめらてっく・めがふりーと・どらごん",e:"Chimeratech Megafleet Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「サイバー・ドラゴン」モンスター＋EXモンスターゾーンのモンスター１体以上\n自分・相手フィールドの上記カードを墓地へ送った場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\nこのカードは融合素材にできない。\n①：このカードの元々の攻撃力は、このカードの融合素材としたモンスターの数×１２００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・アークペンデュラム・ドラゴン",h:"おっどあいず・あーくぺんでゅらむ・どらごん",e:"Odd-Eyes Arc Pendulum Dragon",t:"monster",a:"闇",l:7,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の「オッドアイズ」カードが戦闘・効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「オッドアイズ」モンスター１体を選んで特殊召喚する。",tr:"ドラゴン族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2700,de:2000,tx:"雄々しくも美しき、神秘の眼を持つ奇跡の龍。\n\nその二色に輝く眼は、天空に描かれし軌跡を映す。",li:"",lk:[],ta:[],al:[]},
  {n:"鬼動武者",h:"ですとろい・さむらい",e:"Samurai Destroyer",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1400,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが相手モンスターと戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できず、バトルフェイズの間だけその相手モンスターの効果は無効化される。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合、自分の墓地の機械族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハロハロ",h:"はろはろ",e:"Hallohallo",t:"monster",a:"闇",l:3,ps:2,pe:"①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nサイコロを１回振る。\nそのモンスターのレベルはターン終了時まで、出た目と同じレベルになる。",tr:"悪魔族",mo:["ペンデュラム","チューナー","通常"],ma:"",tp:"",at:800,de:600,tx:"ハロハロはあまいおかしにメロメロ。\n\nおかしをさがしてあっちへウロウロ、こっちをキョロキョロ。\n\nおかしをくれないとイロイロないたずらでヘロヘロにしちゃうぞ。\n\n\nオロオロしたってもうおそいよ。\n\nなにがでるかはおたのしみ。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・キマイラ",h:"う゛ぇんでっと・きまいら",e:"Vendread Chimera",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのカードを破壊する魔法・罠・モンスターの効果が発動した時、自分の墓地からアンデット族モンスター１体を除外して発動できる。\nその発動を無効にし破壊する。\n②：このカードが儀式召喚のために、リリースまたは除外された場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・ストリゲス",h:"う゛ぇんでっと・すとりげす",e:"Vendread Striges",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、手札の「ヴェンデット」カード１枚を相手に見せて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●このカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・ナイト",h:"う゛ぇんでっと・ないと",e:"Vendread Nights",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに手札を１枚捨てて発動できる。\nデッキから「ヴェンデット」モンスター１体を手札に加える。\n②：自分の「ヴェンデット」モンスターの攻撃で相手モンスターを破壊した時、自分の墓地から「ヴェンデット」モンスター１体を除外して発動できる。\nそのモンスターは相手モンスターに続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・リユニオン",h:"う゛ぇんでっと・りゆにおん",e:"Vendread Reunion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：儀式召喚する手札の「ヴェンデット」儀式モンスター１体を相手に見せる。\nレベルの合計が見せた儀式モンスターのレベルと同じになるように、除外されている自分の「ヴェンデット」モンスターを任意の数だけ選び、裏側守備表示で特殊召喚する（同名カードは１枚まで）。\nその後、その裏側守備表示モンスターを全てリリースして手札からその儀式モンスターを儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ウィップクロッサー",h:"ふぉーみゅらあすりーとうぃっぷくろっさー",e:"F.A. Whip Crosser",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、相手は３００LPを払わなければ、このカードのレベルよりも元々のレベルまたはランクが低いモンスターの効果を発動できない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上の場合、相手は手札を捨てて発動する効果及び手札を墓地へ送って発動する効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ターボチャージャー",h:"ふぉーみゅらあすりーとたーぼちゃーじゃー",e:"F.A. Turbo Charger",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、このカードのレベルよりも元々のレベルまたはランクが低い相手モンスターは、このカードしか攻撃対象にできず、このカードしか効果の対象にできない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上で、自分の「F.A.」モンスターが戦闘を行う場合、ダメージステップ終了時まで相手はモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.オフロードGP",h:"ふぉーみゅらあすりーとおふろーどぐらんぷり",e:"F.A. Off-Road Grand Prix",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、フィールドの「F.A.」モンスターのレベルはメインフェイズの間だけ２つ上がる。\n②：自分の「F.A.」モンスターが戦闘で破壊された時に発動できる。\n相手の手札をランダムに１枚選んで捨てる。\n③：フィールドの表側表示のこのカードが効果で破壊された場合に発動できる。\nデッキから「F.A.オフロードGP」以外の「F.A.」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ピットストップ",h:"ふぉーみゅらあすりーとぴっとすとっぷ",e:"F.A. Pit Stop",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「F.A.」モンスター１体を対象として発動できる。\nそのモンスターのレベルを２つ下げ、自分は自分の墓地の「F.A.ピットストップ」の数＋１枚をデッキからドローする。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の「F.A.」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーマリスの妖魔",h:"さぶてらーまりすのようま",e:"Subterror Behemoth Fiendess",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"幻竜族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"リバースモンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク素材とした「サブテラー」モンスターの元々のレベルの合計×１００アップする。\n②：自分メインフェイズに発動できる。\nデッキからリバースモンスター１体を墓地へ送り、手札からモンスター１体をこのカードのリンク先に裏側守備表示で特殊召喚する。\n③：１ターンに１度、このカードのリンク先のモンスターがリバースした場合に発動する。\n自分のデッキ・墓地からリバースモンスター１体を選んで手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"SPYRAL－ザ・ダブルヘリックス",h:"すぱいらる－ざ・だぶるへりっくす",e:"SPYRAL Double Helix",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"「SPYRAL」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「SPYRAL－ダンディ」として扱う。\n②：カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手のデッキの一番上のカードをお互いに確認し、宣言した種類のカードだった場合、自分のデッキ・墓地から「SPYRAL」モンスター１体を選び、手札に加えるかこのカードのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"ロックアウト・ガードナー",h:"ろっくあうと・がーどなー",e:"Lockout Gardna",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から攻撃表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、このターン戦闘では破壊されない。\n②：自分フィールドのサイバース族モンスター１体のみを対象とする相手フィールドのモンスターの効果が発動した時に発動できる。\nその自分のサイバース族モンスターとその相手モンスターの効果はターン終了時まで無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ストライピング・パートナー",h:"すとらいぴんぐ・ぱーとなー",e:"Striping Partner",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードは通常召喚できず、このカードの①の効果でのみ特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドで発動したモンスターの効果の発動が無効になった場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した時、自分の墓地のレベル４以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フリック・クラウン",h:"ふりっく・くらうん",e:"Flick Clown",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドにこのカード以外のサイバース族モンスターが２体以上存在し、自分の手札が０枚の場合、１０００LPを払って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ビットルーパー",h:"びっとるーぱー",e:"Bitrooper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：手札からレベル２以下のモンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"弾帯城壁龍",h:"べるとりんく・うぉーる・どらごん",e:"Linkbelt Wall Dragon",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:2100,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：自分がリンク召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚し、このカードにカウンターを２つ置く。\n②：お互いに、このカードのカウンターの数より多い数のリンクマーカーを持つモンスターをリンク召喚できず、リンクモンスター以外のモンスターは攻撃できない。\n③：モンスターがリンク召喚された場合に発動する。\nこのカードのカウンターを２つ取り除く。\n④：自分・相手のスタンバイフェイズに発動する。\nこのカードにカウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"シェルヴァレット・ドラゴン",h:"しぇるう゛ぁれっと・どらごん",e:"Shelrokket Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを対象とするリンクモンスターの効果が発動した時に発動できる。\nこのカードを破壊する。\nその後、このカードが存在していたゾーンと同じ縦列のモンスター１体を選んで破壊し、その隣のゾーンにモンスターが存在する場合、それらも破壊する。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「シェルヴァレット・ドラゴン」以外の「ヴァレット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルヴァレット・ドラゴン",h:"めたるう゛ぁれっと・どらごん",e:"Metalrokket Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを対象とするリンクモンスターの効果が発動した時に発動できる。\nこのカードを破壊する。\nその後、このカードが存在していたゾーンと同じ縦列の相手のカードを全て破壊する。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「メタルヴァレット・ドラゴン」以外の「ヴァレット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・エンジェル",h:"てぃんだんぐる・えんじぇる",e:"Tindangle Angel",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\n自分の手札・墓地から「ティンダングル・エンジェル」以外のリバースモンスター１体を選んで裏側守備表示で特殊召喚する。\nこの効果が相手ターンのバトルフェイズに発動した場合、さらにそのバトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・ベース・ガードナー",h:"てぃんだんぐる・べーす・がーどなー",e:"Tindangle Base Gardna",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2300,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに裏側守備表示モンスターが存在する場合、このカードは手札から守備表示で特殊召喚できる。\n②：このカードが既にモンスターゾーンに存在する状態で、相手のリンクモンスターのリンク先にモンスターが召喚・特殊召喚された場合、このカードをリリースして発動できる。\n手札・デッキから「ティンダングル」モンスター１体を表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・ハウンド",h:"てぃんだんぐる・はうんど",e:"Tindangle Hound",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:2500,de:0,tx:"①：このカードがリバースした場合、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nこのカードの攻撃力は対象のモンスターの元々の攻撃力分アップする。\nその後、対象のモンスターを裏側守備表示にする。\n②：相手フィールドのモンスターの攻撃力は、そのモンスターとリンク状態になっているモンスターの数×１０００ダウンする。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合、フィールドの裏側表示モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・アポストル",h:"てぃんだんぐる・あぽすとる",e:"Tindangle Protector",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\n自分フィールドの裏側表示モンスターを３体まで選んで表側守備表示にする。\nこの効果で表側守備表示にしたモンスターが全て「ティンダングル」モンスターだった場合、そのモンスターの数までデッキから「ティンダングル」カードを手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・イントルーダー",h:"てぃんだんぐる・いんとるーだー",e:"Tindangle Intruder",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:2200,de:0,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「ティンダングル」カード１枚を手札に加える。\n②：このカードが召喚に成功した時に発動できる。\nデッキから「ティンダングル」カード１枚を墓地へ送る。\n③：このカードが墓地に存在し、自分フィールドにモンスターが裏側守備表示で特殊召喚された場合に発動する。\nこのカードを墓地から裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"蒼穹の機界騎士",h:"そうきゅうのじゃっくないつ",e:"Mekk-Knight Blue Sky",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2000,de:2500,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：このカードが手札からの召喚・特殊召喚に成功した場合に発動できる。\nこのカードと同じ縦列の相手のカードの数だけ、デッキから「蒼穹の機界騎士」以外の「ジャックナイツ」モンスターを手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"翠嵐の機界騎士",h:"すいらんのじゃっくないつ",e:"Mekk-Knight Green Horizon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：このカードが同じ縦列の相手モンスターと戦闘を行う攻撃宣言時に、自分の墓地の「ジャックナイツ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"燈影の機界騎士",h:"とうえいのじゃっくないつ",e:"Mekk-Knight Orange Sunset",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:800,de:3000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：このカードと同じ縦列の相手のカードがフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\n手札から「ジャックナイツ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄華の機界騎士",h:"おうかのじゃっくないつ",e:"Mekk-Knight Yellow Star",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2200,de:2800,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：自分の墓地から「ジャックナイツ」モンスター１体を除外し、このカードと同じ縦列の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮の機界騎士",h:"ぐれんのじゃっくないつ",e:"Mekk-Knight Red Moon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2300,de:2600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：自分の墓地から「ジャックナイツ」モンスター１体を除外し、このカードと同じ縦列の表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"紺碧の機界騎士",h:"こんぺきのじゃっくないつ",e:"Mekk-Knight Indigo Eclipse",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2400,de:2400,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：１ターンに１度、自分フィールドの「ジャックナイツ」モンスター１体を対象として発動できる。\nその自分のモンスターの位置を、他の自分のメインモンスターゾーンに移動する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"紫宵の機界騎士",h:"ししょうのじゃっくないつ",e:"Mekk-Knight Purple Nightfall",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：自分フィールドの「ジャックナイツ」モンスター１体を対象として発動できる。\nそのモンスターを次の自分ターンのスタンバイフェイズまで除外し、デッキから「紫宵の機界騎士」以外の「ジャックナイツ」モンスター１体を手札に加える。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物－『星盾』",h:"せいいぶつ－『せいじゅん』",e:"World Legacy - \"World Shield\"",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:3000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、EXデッキから特殊召喚されたモンスターが発動した効果を受けない。\n②：このカードと同じ縦列の自分の「星遺物」カードは相手の効果の対象にならず、相手の効果では破壊されない。\n③：このカードが墓地に存在する場合、自分・相手のスタンバイフェイズに１０００LPを払って発動できる。\nこのカードを墓地から特殊召喚する。\nその後、相手は自身の手札・墓地からモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣 ジャッカル",h:"まじっくびーすと　じゃっかる",e:"Mythical Beast Jackal",t:"monster",a:"闇",l:1,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにカードが存在しない場合、自分フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nこのカードを破壊し、そのカードに魔力カウンターを１つ置く。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：自分フィールドの魔力カウンターを３つ取り除き、このカードをリリースして発動できる。\nデッキから「魔導獣 ジャッカル」以外の「魔導獣」効果モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣 ガルーダ",h:"まじっくびーすと　がるーだ",e:"Mythical Beast Garuda",t:"monster",a:"光",l:4,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにカードが存在しない場合、このカード以外のフィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードとこのカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した時、自分フィールドの魔力カウンターを３つ取り除いて発動できる。\nこのカードを手札から特殊召喚する。\nその後、相手が召喚・特殊召喚したそのモンスターを持ち主の手札に戻す。\n②：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣 メデューサ",h:"まじっくびーすと　めでゅーさ",e:"Mythical Beast Medusa",t:"monster",a:"光",l:4,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにカードが存在しない場合、自分の墓地の魔力カウンターを置く事ができるモンスター１体を対象として発動できる。\nこのカードを破壊し、そのモンスターを特殊召喚し、そのモンスターに魔力カウンターを１つ置く。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：１ターンに１度、自分・相手のバトルフェイズに、自分フィールドの魔力カウンターを２つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣 バジリスク",h:"まじっくびーすと　ばじりすく",e:"Mythical Beast Bashilisk",t:"monster",a:"光",l:4,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにカードが存在しない場合に発動できる。\nこのカードを破壊し、自分のEXデッキから「魔導獣 バジリスク」以外の表側表示の魔法使い族Pモンスター１体をデッキに戻す。\nその後、自分はデッキから１枚ドローする。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:500,tx:"このカード名の②のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：自分フィールドの魔力カウンターを３つ取り除いて発動できる。\n自分のPゾーンのカード及び自分のEXデッキの表側表示のPモンスターの中から、「魔導獣」カード１枚を選んで持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣 キングジャッカル",h:"まじっくびーすと　きんぐじゃっかる",e:"Mythical Beast Jackal King",t:"monster",a:"闇",l:6,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにカードが存在しない場合に発動できる。\nこのカードを破壊し、自分のEXデッキから「魔導獣 キングジャッカル」以外の表側表示の「魔導獣」Pモンスター１体を特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2400,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを２つ置く。\n②：１ターンに１度、相手モンスターの効果が発動した時、自分フィールドの魔力カウンターを２つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導獣 マスターケルベロス",h:"まじっくびーすと　ますたーけるべろす",e:"Mythical Beast Master Cerberus",t:"monster",a:"光",l:8,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにカードが存在しない場合に発動できる。\nこのカードを破壊し、デッキからレベル７以下の「魔導獣」効果モンスター１体を手札に加える。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:2800,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを２つ置く。\n②：自分フィールドに魔力カウンターが４つ以上存在する場合、このカードは効果では破壊されない。\n③：１ターンに１度、自分フィールドの魔力カウンターを４つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこのカードの攻撃力は相手ターン終了時まで、除外したそのモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アーティファクト－ミョルニル",h:"あーてぃふぁくと－みょるにる",e:"Artifact Mjollnir",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、このカードが特殊召喚に成功した場合、自分の墓地の「アーティファクト」モンスター１体を対象として発動する。\nそのモンスターを守備表示で特殊召喚する。\nこの効果の発動後、次のターンの終了時まで自分は「アーティファクト」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"屈強の釣り師",h:"あんぐらっぷらー",e:"Grappler Angler",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが直接攻撃で相手に戦闘ダメージを与えた時、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"雀姉妹",h:"じゃんしまつ",e:"Mahjong Munia Maidens",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:2000,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした場合に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の探求者",h:"いじげんのたんきゅうしゃ",e:"D.D. Seeker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを次のターンのエンドフェイズまで除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魅幽鳥",h:"みくらとり",e:"Ghost Bird of Bewitchment",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"①：このカードはメインモンスターゾーンに存在する限り、その位置によって以下の効果を得る。\n\n●左端：このカードの攻撃力・守備力は１０００アップする。\n\n●右端：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n\n●中央：このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n\n●それ以外：このカードと同じ縦列のモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"デスマニア・デビル",h:"ですまにあ・でびる",e:"Desmanian Devil",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nデッキからレベル４以下の獣族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"電送擬人エレキネシス",h:"でんそうぎじんえれきねしす",e:"Wattkinetic Puppeteer",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：手札・フィールドのこのカードを墓地へ送り、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターの位置を、他の相手のメインモンスターゾーンに移動する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"インスペクト・ボーダー",h:"いんすぺくと・ぼーだー",e:"Inspector Boarder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"自分フィールドにモンスターが存在する場合、このカードは召喚・特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、その間はお互いにそれぞれ１ターンに発動できるモンスターの効果の回数が、フィールドのモンスターの種類（儀式・融合・S・X・P・リンク）の数までになる。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーテクス・ゴアトルス",h:"おーばーてくす・ごあとるす",e:"Overtex Qoatlus",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"恐竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2700,de:2100,tx:"このカードは通常召喚できない。\n除外されている自分の恐竜族モンスター５体をデッキに戻した場合のみ特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、相手が魔法・罠カードを発動した時に発動できる。\n自分の手札・フィールドの恐竜族モンスター１体を選んで破壊し、その発動を無効にし破壊する。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキから「進化薬」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"接触するG",h:"せっしょくするじー",e:"Contact \"C\"",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:2200,tx:"①：相手がモンスターの召喚・特殊召喚に成功した時に発動できる。\nこのカードを手札から相手フィールドに守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、このカードのコントローラーは融合・S・X・リンク召喚を行う場合、このカードを素材とした融合・S・X・リンク召喚しか行えない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゾンビーノ",h:"ぞんびーの",e:"Zombino",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"ふたりは とってもなかよし\nしんでもいっしょ よみがえってもいっしょ \nはなれることはない\n\nだから ふたりがであうことは もうにどとない",li:"",lk:[],ta:[],al:[]},
  {n:"エクスコード・トーカー",h:"えくすこーど・とーかー",e:"Excode Talker",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"サイバース族モンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した時、EXモンスターゾーンのモンスターの数だけ、使用していないメインモンスターゾーンを指定して発動できる。\n指定したゾーンはこのモンスターが表側表示で存在する間は使用できない。\n②：このカードのリンク先のモンスターは、攻撃力が５００アップし、効果では破壊されない。",li:"",lk:["上","左","右"],ta:[],al:[]},
  {n:"アンダークロックテイカー",h:"あんだーくろっくていかー",e:"Underclock Taker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"効果モンスター２体\n①：１ターンに１度、このカードのリンク先の表側表示モンスター１体と、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力はターン終了時まで、対象としたリンク先のモンスターの攻撃力分だけダウンする。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"ベクター・スケア・デーモン",h:"べくたー・すけあ・でーもん",e:"Vector Scare Archfiend",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"サイバース族モンスター２体以上\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時、このカードのリンク先の自分のモンスター１体をリリースして発動できる。\nこのカードのリンク先となる自分・相手フィールドに、破壊したそのモンスターを特殊召喚する。\nこの効果で相手フィールドにモンスターを特殊召喚した場合、このバトルフェイズ中、このカードはもう１度だけ攻撃できる。",li:"",lk:["上","右","左下"],ta:[],al:[]},
  {n:"フレイム・アドミニスター",h:"ふれいむ・あどみにすたー",e:"Flame Administrator",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"サイバース族モンスター２体\n①：「フレイム・アドミニスター」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドのリンクモンスターの攻撃力は８００アップする。",li:"",lk:["左","右下"],ta:[],al:[]},
  {n:"リカバリー・ソーサラー",h:"りかばりー・そーさらー",e:"Recovery Sorcerer",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"サイバース族モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このターンに破壊された自分の墓地のサイバース族リンクモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。\nこの効果は相手ターンでも発動できる。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"セキュア・ガードナー",h:"せきゅあ・がーどなー",e:"Secure Gardna",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"サイバース族リンクモンスター１体\nこのカードはリンク素材にできない。\n①：「セキュア・ガードナー」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが特殊召喚に成功したターン、自分が受ける効果ダメージは０になる。\n③：自分が戦闘・効果でダメージを受ける場合、１ターンに１度だけそのダメージは０になる。",li:"",lk:["右"],ta:[],al:[]},
  {n:"スリーバーストショット・ドラゴン",h:"すりーばーすとしょっと・どらごん",e:"Triple Burst Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"トークン以外のモンスター２体以上\n①：１ターンに１度、ダメージステップにモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードをリリースし、自分の墓地のリンク２以下のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、手札からレベル４以下のドラゴン族モンスター１体を特殊召喚できる。\nこの効果はこのカードが特殊召喚したターンには発動できない。",li:"",lk:["上","左","下"],ta:[],al:[]},
  {n:"ティンダングル・アキュート・ケルベロス",h:"てぃんだんぐる・あきゅーと・けるべろす",e:"Tindangle Acute Cerberus",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"「ティンダングル」モンスター３体\n①：自分の墓地に「ティンダングル・ベース・ガードナー」を含む「ティンダングル」モンスターが３種類以上存在する場合、このカードの攻撃力は３０００アップする。\n②：このカードの攻撃力は、このカードのリンク先の「ティンダングル」モンスターの数×５００アップする。\n③：このカードが攻撃宣言したバトルフェイズ終了時に発動できる。\n自分フィールドに「ティンダングルトークン」（悪魔族・闇・星１・攻／守０）１体を特殊召喚する。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"オルターガイスト・ヘクスティア",h:"おるたーがいすと・へくすてぃあ",e:"Altergeist Hexstia",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"「オルターガイスト」モンスター２体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク先の「オルターガイスト」モンスターの元々の攻撃力分アップする。\n②：魔法・罠カードの効果が発動した時、このカードのリンク先の「オルターガイスト」モンスター１体をリリースして発動できる。\nその発動を無効にし破壊する。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「オルターガイスト」カード１枚を手札に加える。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"星痕の機界騎士",h:"せいこんのじゃっくないつ",e:"Mekk-Knight Spectrum Supreme",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"「ジャックナイツ」モンスター２体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードと同じ縦列に他のカードが存在しない場合、このカードは直接攻撃できる。\n②：EXモンスターゾーンのこのカードのリンク先にモンスターが存在しない場合、このカードは効果では破壊されず、相手の効果の対象にならない。\n③：このカードと同じ縦列の他の自分のカード１枚を墓地へ送って発動できる。\nデッキから「ジャックナイツ」モンスター１体を守備表示で特殊召喚する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"鎖龍蛇－スカルデット",h:"さりゅうじゃ－すかるでっと",e:"Saryuja Skull Dread",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"カード名が異なるモンスター２体以上\n①：このカードは、このカードのリンク素材としたモンスターの数によって以下の効果を得る。\n\n●２体以上：このカードのリンク先にモンスターが召喚・特殊召喚された場合に発動する。\nそのモンスターの攻撃力・守備力は３００アップする。\n\n●３体以上：１ターンに１度、自分メインフェイズに発動できる。\n手札からモンスター１体を特殊召喚する。\n\n●４体：このカードがリンク召喚に成功した時に発動できる。\n自分はデッキから４枚ドローし、その後手札を３枚選んで好きな順番でデッキの下に戻す。",li:1,lk:["上","左下","下","右下"],ta:[],al:[]},
  {n:"副話術士クララ＆ルーシカ",h:"ふくわじゅつしくららあんどるーしか",e:"Clara & Rushka, the Ventriloduo",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"通常召喚されたモンスター１体\nこのカードのリンク召喚はメインフェイズ２でしか行えない。",li:"",lk:["上"],ta:[],al:[]},
  {n:"ドリトル・キメラ",h:"どりとる・きめら",e:"Duelittle Chimera",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"炎属性モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの炎属性モンスターの攻撃力・守備力は５００アップし、水属性モンスターの攻撃力・守備力は４００ダウンする。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地の炎属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"リンケージ・ホール",h:"りんけーじ・ほーる",e:"Link Hole",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドにリンク４以上のリンクモンスターが存在する場合に発動できる。\n自分フィールドのリンク３以上のリンクモンスターの数まで、相手フィールドのモンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天火の牢獄",h:"てんぴのろうごく",e:"Fire Prison",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドのドラゴン族モンスターの守備力は３００アップする。\n②：お互いに、フィールドのリンクモンスターよりリンクマーカーの数が少ないリンクモンスターをリンク召喚できず、リンクモンスター以外のモンスターは攻撃できない。\n③：フィールドにサイバース族リンクモンスターが２体以上存在する場合、以下の効果を適用する。\n\n●サイバース族モンスターが発動した効果は無効化される。\n\n●フィールドのサイバース族モンスターは攻撃できず、攻撃対象にならず、効果の対象にもならない。",li:"",lk:[],ta:[],al:[]},
  {n:"リボルブート・セクター",h:"りぼるぶーと・せくたー",e:"Boot Sector Launch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの「ヴァレット」モンスターの攻撃力・守備力は３００アップする。\n②：自分メインフェイズに以下の効果から１つを選択して発動できる。\n\n●手札から「ヴァレット」モンスターを２体まで守備表示で特殊召喚する（同名カードは１枚まで）。\n\n●相手フィールドのモンスターの数が自分フィールドのモンスターよりも多い場合、その差の数まで自分の墓地から「ヴァレット」モンスターを選んで守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"ナーゲルの守護天",h:"なーげるのしゅごてん",e:"Nagel's Protection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分のメインモンスターゾーンの「ティンダングル」モンスターは戦闘及び相手の効果では破壊されない。\n②：自分の「ティンダングル」モンスターが相手に戦闘ダメージを与える場合、１ターンに１度だけそのダメージは倍になる。\n③：墓地のこのカードを除外し、手札から「ティンダングル」カード１枚を捨てて発動できる。\nデッキから「ナーゲルの守護天」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オイラーサーキット",h:"おいらーさーきっと",e:"Euler's Circuit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ティンダングル」モンスターが３体以上存在する場合、相手モンスターは攻撃できない。\n②：自分スタンバイフェイズに、自分フィールドの「ティンダングル」モンスター１体を対象として発動できる。\nそのモンスターのコントロールを相手に移す。\n③：墓地のこのカードを除外し、手札から「ティンダングル」カード１枚を捨てて発動できる。\nデッキから「オイラーサーキット」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物が刻む傷痕",h:"せいいぶつがきざむきずあと",e:"World Legacy Scars",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「ジャックナイツ」モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、手札から「ジャックナイツ」モンスター１体または「星遺物」カード１枚を捨てて発動できる。\n自分はデッキから１枚ドローする。\n③：自分の墓地及び自分フィールドの表側表示モンスターの中から、「ジャックナイツ」モンスター８種類を１体ずつ除外して発動できる。\n相手の手札・EXデッキのカードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物へ至る鍵",h:"せいいぶつへいたるかぎ",e:"World Legacy Key",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時に、除外されている自分のカードの中から、「ジャックナイツ」モンスター１体または「星遺物」カード１枚を対象にできる。\nその場合、そのカードを手札に加える。\n②：自分フィールドに「ジャックナイツ」モンスターが存在する限り、そのモンスターと同じ縦列で発動した相手の罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導加速",h:"まじっく・ぶーすと",e:"Mythical Bestiary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキの上からカードを２枚墓地へ送り、フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを２つまで置く。\n②：このカードが相手の効果で破壊された場合に発動できる。\n魔力カウンターを置く事ができるモンスター１体をデッキから特殊召喚し、そのモンスターに魔力カウンターを２つまで置く。",li:"",lk:[],ta:[],al:[]},
  {n:"栄光の聖騎士団",h:"えいこうのせいきしだん",e:"Glory of the Noble Knights",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「聖騎士」モンスター１体を対象として発動できる。\nその自分のモンスターが装備可能な装備魔法カード１枚をデッキから選び、そのモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアンの力",h:"がーでぃあんのちから",e:"Power of the Guardians",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターが戦闘を行う攻撃宣言時に発動する。\nこのカードに魔力カウンターを１つ置く。\n②：装備モンスターの攻撃力・守備力は、このカードの魔力カウンターの数×５００アップする。\n③：装備モンスターが戦闘・効果で破壊される場合、代わりに自分フィールドの魔力カウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・パラドックス",h:"ぺんでゅらむ・ぱらどっくす",e:"Pendulum Paradox",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のEXデッキの表側表示のPモンスターの中から、Pスケールが同じでカード名が異なるモンスター２体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"局所的ハリケーン",h:"きょくしょてきはりけーん",e:"Hey, Trunade!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドにセットされている魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ダウンビート",h:"だうんびーと",e:"Downbeat",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体をリリースして発動できる。\nリリースしたモンスターと元々の種族・属性が同じで元々のレベルが１つ低いモンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アスポート",h:"あすぽーと",e:"Column Switch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンのモンスター１体を対象として発動できる。\nその自分のモンスターの位置を、他の自分のメインモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[]},
  {n:"身分転換",h:"みぶんてんかん",e:"Trading Places",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のLPが相手より多い場合、お互いのLPを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"パラレルポート・アーマー",h:"ぱられるぽーと・あーまー",e:"Parallel Port Armor",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのリンクモンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは相手の効果の対象にならず、戦闘では破壊されない。\n②：自分の墓地からこのカードとリンクモンスター２体を除外し、自分フィールドのリンクモンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・リフレッシュ",h:"さいばねっと・りふれっしゅ",e:"Cynet Refresh",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手のサイバース族モンスターの攻撃宣言時に発動できる。\nお互いのメインモンスターゾーンのモンスターを全て破壊する。\nこのターンのエンドフェイズにこの効果で破壊したサイバース族リンクモンスターを可能な限り、墓地から持ち主のフィールドに特殊召喚する。\n②：相手モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\n自分フィールドのサイバース族リンクモンスターはターン終了時まで、自身以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレル・レフリジェレーション",h:"う゛ぁれる・れふりじぇれーしょん",e:"Borrel Cooling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ヴァレット」モンスター１体をリリースし、自分フィールドの「ヴァレル」リンクモンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは以下の効果を得る。\n●１ターンに１度、自分フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは戦闘・効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・ドロネー",h:"てぃんだんぐる・どろねー",e:"Tindangle Delaunay",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地に「ティンダングル」モンスターが３種類以上存在し、相手モンスターの攻撃で自分が戦闘ダメージを受けた時に発動できる。\nその攻撃モンスターを破壊し、EXデッキから「ティンダングル・アキュート・ケルベロス」１体を特殊召喚する。\n②：EXモンスターゾーンに自分のモンスターが存在しない場合、墓地のこのカードを除外し、自分の墓地の「ティンダングル」モンスター３体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・マテリアリゼーション",h:"おるたーがいすと・まてりありぜーしょん",e:"Altergeist Manifestation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の墓地の「オルターガイスト」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚し、このカードを装備カード扱いとしてそのモンスターに装備する。\nこのカードがフィールドを離れた時にそのモンスターは破壊される。\n②：墓地のこのカードを除外し、自分の墓地の「オルターガイスト」罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の囁き",h:"せいいぶつのささやき",e:"World Legacy Whispers",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードの発動時に、フィールドのレベル５以上のモンスター１体を対象にできる。\nその場合、そのモンスターの攻撃力・守備力はターン終了時まで１０００アップする。\n②：自分フィールドに「ジャックナイツ」モンスターが存在する限り、そのモンスターと同じ縦列で発動した相手の魔法カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物に眠る深層",h:"せいいぶつにねむるしんそう",e:"World Legacy's Secret",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードの発動時に、自分の墓地のレベル５以上のモンスター１体を対象にできる。\nその場合、そのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：自分フィールドに「ジャックナイツ」モンスターが存在する限り、そのモンスターと同じ縦列で発動した相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導変換",h:"まじっく・ばーすと",e:"Mythical Bestiamorph",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、相手モンスターの効果が発動する度に、このカードに魔力カウンターを１つ置く。\n②：魔力カウンターが６つ以上置かれているこのカードを墓地へ送って発動できる。\nデッキから魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・リフォーム",h:"ごーすとりっく・りふぉーむ",e:"Ghostrick Renovation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「ゴーストリック」フィールド魔法カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nその後、手札・デッキからフィールド魔法カード１枚を発動できる。\n②：墓地のこのカードを除外し、自分フィールドの「ゴーストリック」Xモンスター１体を対象として発動できる。\nその自分のモンスターとカード名が異なる「ゴーストリック」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの呼び声",h:"でーもんのよびごえ",e:"Call of the Archfiend",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地のレベル５以上の悪魔族モンスター１体を対象として発動できる。\n手札から悪魔族モンスター１体を捨て、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"センサー万別",h:"せんさーばんべつ",e:"There Can Be Only One",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いのフィールドにそれぞれ１体しか同じ種族のモンスターは表側表示で存在できない。\nお互いのプレイヤーは自身のフィールドに同じ種族のモンスターが２体以上存在する場合には、同じ種族のモンスターが１体になるように墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"GUYダンス",h:"がいだんす",e:"Dai Dance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の効果はデュエル中に１度しか適用できない。\n①：使用していない相手のメインモンスターゾーンを１ヵ所指定して発動できる。\n指定したゾーンが使用可能である限り、相手がメインモンスターゾーンにモンスターを通常召喚・特殊召喚する場合、そのゾーンしか使用できない。\nこの効果は指定したゾーンにモンスターが置かれるまで適用される。",li:"",lk:[],ta:[],al:[]},
  {n:"見切りの極意",h:"みきりのごくい",e:"Showdown of the Secret Sense Scroll Techniques",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手の墓地のカードと同名のモンスターの効果・魔法・罠カードを相手が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"一撃離脱",h:"ぱるてぃあんしょっと",e:"Parthian Shot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分・相手のバトルフェイズ終了時に発動できる。\nこのターンのエンドフェイズになる。",li:"",lk:[],ta:[],al:[]},
  {n:"誤爆",h:"ごばく",e:"Oops!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイヤーオパールヘッド",h:"ふぁいやーおぱーるへっど",e:"",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2500,de:1000,tx:"熱く燃えたぎる石頭の恐竜番長。\nダイナミックな動きと炎で敵を翻弄し、必殺のファイヤーオパールヘッドをお見舞いする。",li:"",lk:[],ta:[],al:[]},
  {n:"沼地のドロゴン",h:"ぬまちのどろごん",e:"Mudragon of the Swamp",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"幻竜族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:1600,tx:"同じ属性で種族が異なるモンスター×２\n①：このカードがモンスターゾーンに存在する限り、相手はこのカード及びこのカードと同じ属性を持つフィールドのモンスターを効果の対象にできない。\n②：１ターンに１度、属性を１つ宣言して発動できる。\nこのカードはターン終了時まで宣言した属性になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"武力の軍奏",h:"ぶりきのぐんそう",e:"Martial Metal Marcher",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:500,de:2200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、自分の墓地のチューナー１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードをS素材としたSモンスターは、フィールドに表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"キキナガシ風鳥",h:"ききながしふうちょう",e:"Kikinagashi Fucho",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１モンスター×２\n①：このカードはモンスターゾーンに存在する限り、他のカードの効果を受けない。\n②：１ターンに１度、このカードのX素材を２つ取り除いて発動できる。\nこのターン、このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"リンクルベル",h:"りんくるべる",e:"Linkerbell",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"モンスター２体\nこのカードのリンク召喚は自分のEXデッキの枚数が相手よりも３枚以上多い場合にしか行えない。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"サブテラーの導師",h:"さぶてらーのどうし",e:"Subterror Guru",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「サブテラーの導師」以外の「サブテラー」カード１枚を手札に加える。\n②：このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとこのカードを裏側守備表示にする。\n自分フィールドにこのカード以外の「サブテラー」カードが存在する場合、この効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"コード・トーカー",h:"こーど・とーかー",e:"Code Talker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1300,de:"-",tx:"効果モンスター２体\n①：このカードの攻撃力は、このカードのリンク先のモンスターの数×５００アップする。\n②：このカードのリンク先にモンスターが存在する限り、このカードは戦闘及び相手の効果では破壊されない。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"白闘気双頭神龍",h:"ほわいと・おーら・ばいふぁむーと",e:"White Aura Bihamut",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"魚族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3000,tx:"Sモンスターのチューナー＋チューナー以外のモンスター１体以上\n①：自分ターンにこのカードがS召喚に成功した時に発動できる。\n自分フィールドに「神龍トークン」（魚族・水・星１０・攻３３００／守３０００）１体を守備表示で特殊召喚する。\n②：相手ターンに１度、自分フィールドにトークンがない場合に発動できる。\n自分フィールドに「神龍トークン」１体を特殊召喚する。\n③：このカードが相手の効果で破壊され墓地へ送られた場合に、自分フィールドに「神龍トークン」が存在していれば発動できる。\nこのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超天新龍オッドアイズ・レボリューション・ドラゴン",h:"ちょうてんしんりゅうおっどあいず・れぼりゅーしょん・どらごん",e:"Odd-Eyes Revolution Dragon",t:"monster",a:"光",l:12,ps:12,pe:"①：自分はドラゴン族モンスターしかＰ召喚できない。\nこの効果は無効化されない。\n②：自分の墓地のドラゴン族の融合・Ｓ・Ｘモンスター１体を対象として発動できる。\nこのカードを破壊し、そのモンスターを特殊召喚する。",tr:"ドラゴン族",mo:["特殊召喚","ペンデュラム","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n手札からのP召喚、または自分フィールドのドラゴン族の融合・S・Xモンスターを１体ずつリリースした場合のみ特殊召喚できる。\n①：このカードを手札から捨て、５００LPを払って発動できる。\nデッキからレベル８以下のドラゴン族Pモンスター１体を手札に加える。\n②：このカードの攻撃力・守備力は相手のLPの半分の数値分アップする。\n③：１ターンに１度、LPを半分払って発動できる。\nこのカード以外のお互いのフィールド・墓地のカードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・マルチフェイカー",h:"おるたーがいすと・まるちふぇいかー",e:"Altergeist Multifaker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が罠カードを発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「オルターガイスト・マルチフェイカー」以外の「オルターガイスト」モンスター１体を守備表示で特殊召喚する。\nこの効果を発動するターン、自分は「オルターガイスト」モンスターしか特殊召喚できない。",li:2,lk:[],ta:[],al:[]},
  {n:"ミレニアム・アイズ・イリュージョニスト",h:"みれにあむ・あいず・いりゅーじょにすと",e:"Millennium-Eyes Illusionist",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、相手フィールドの効果モンスター１体を対象として発動できる。\n自分フィールドの、「アイズ・サクリファイス」融合モンスターまたは「サクリファイス」１体を選び、その効果による装備カード扱いとして対象の相手の効果モンスターを装備する。\nこの効果は相手ターンでも発動できる。\n②：フィールドに「アイズ・サクリファイス」融合モンスターまたは「サクリファイス」が特殊召喚された場合に発動する。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"幻想魔術師・ノー・フェイス",h:"まじかるいりゅーじょにすと・のー・ふぇいす",e:"Illusionist Faceless Magician",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドの表側表示の、「アイズ・サクリファイス」融合モンスターまたは「サクリファイス」が戦闘・効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードがフィールドから墓地へ送られた場合、自分の墓地の、「アイズ・サクリファイス」融合モンスターまたは「サクリファイス」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミレニアム・アイズ・サクリファイス",h:"みれにあむ・あいず・さくりふぁいす",e:"Millennium-Eyes Restrict",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「サクリファイス」＋効果モンスター\n①：１ターンに１度、相手モンスターの効果が発動した時、相手のフィールド・墓地の効果モンスター１体を対象として発動できる。\nその相手の効果モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値分アップする。\n③：このカードの効果で装備したモンスターと同名のモンスターは攻撃できず、その効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"サクリファイス・フュージョン",h:"さくりふぁいす・ふゅーじょん",e:"Relinquished Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「アイズ・サクリファイス」融合モンスターカードの融合素材モンスターを自分の手札・フィールド・墓地から除外し、その融合モンスター１体をEXデッキから融合召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、相手フィールドの効果モンスター１体を対象として発動できる。\n自分フィールドの、「アイズ・サクリファイス」融合モンスターまたは「サクリファイス」１体を選び、その効果による装備カード扱いとして対象の相手の効果モンスターを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"寄生虫パラノイド",h:"きせいちゅうぱらのいど",e:"Parasite Paranoid",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは種族が昆虫族になり、昆虫族モンスターを攻撃できず、昆虫族モンスターを対象として発動した装備モンスターの効果は無効化される。\nこの効果は相手ターンでも発動できる。\n②：装備カード扱いのこのカードが墓地へ送られた場合に発動できる。\n手札からレベル７以上の昆虫族モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"究極変異態・インセクト女王",h:"きゅうきょくへんいたい・いんせくとくいーん",e:"Metamorphosed Insect Queen",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：フィールドに他の昆虫族モンスターが存在する場合、自分フィールドの昆虫族モンスターは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードが攻撃したダメージステップ終了時、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードは相手モンスターに続けて攻撃できる。\n③：自分・相手のエンドフェイズに発動できる。\n自分フィールドに「インセクトモンスタートークン」（昆虫族・地・星１・攻／守１００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超進化の繭",h:"ちょうしんかのまゆ",e:"Cocoon of Ultra Evolution",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備カードを装備した自分・相手フィールドの昆虫族モンスター１体をリリースし、デッキから昆虫族モンスター１体を召喚条件を無視して特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の昆虫族モンスター１体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"蝕みの鱗粉",h:"むしばみのりんぷん",e:"Corrosive Scales",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの昆虫族モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n相手はその自分の装備モンスター以外の昆虫族モンスターを攻撃できない。\n②：このカードが装備されている限り、相手がモンスターを召喚・特殊召喚する度、または相手が魔法・罠・モンスターの効果を発動する度に、相手フィールドの表側表示モンスター全てに鱗粉カウンターを１つずつ置く。\n相手フィールドのモンスターの攻撃力・守備力は、そのモンスターの鱗粉カウンターの数×１００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"BM－４ボムスパイダー",h:"ＢＭ－４ぼむすぱいだー",e:"BM-4 Blast Spider",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:2200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分フィールドの機械族・闇属性モンスター１体と相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分フィールドの元々の種族・属性が機械族・闇属性のモンスターが、戦闘または自身の効果で相手フィールドのモンスターを破壊し墓地へ送った場合に発動できる。\nその破壊され墓地へ送られたモンスター１体の元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"デスペラード・リボルバー・ドラゴン",h:"ですぺらーど・りぼるばー・どらごん",e:"Desperado Barrel Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"①：自分フィールドの機械族・闇属性モンスターが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分・相手のバトルフェイズに発動できる。\nコイントスを３回行う。\n表が出た数までフィールドの表側表示モンスターを選んで破壊する。\n３回とも表だった場合、さらに自分はデッキから１枚ドローする。\nこの効果を発動するターン、このカードは攻撃できない。\n③：このカードが墓地へ送られた場合に発動できる。\nコイントスを行う効果を持つレベル７以下のモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"鋼鉄の襲撃者",h:"へびーめたる・れいだーす",e:"Heavy Metal Raiders",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分の機械族・闇属性モンスターは、それぞれ１ターンに１度だけ戦闘では破壊されず、その戦闘で自分が戦闘ダメージを受けた場合、その数値分だけ攻撃力がアップする。\n②：１ターンに１度、自分フィールドの元々の種族・属性が機械族・闇属性のモンスターが、戦闘または自身の効果でフィールドのカードを破壊した場合に発動できる。\n手札から機械族・闇属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"銃砲撃",h:"がん・きゃのん・しょっと",e:"Proton Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、コイントスを行う効果が発動した場合、その効果で表が出た数によって以下の効果を適用する。\n\n●１回以上：相手に５００ダメージを与える。\n\n●２回以上：相手フィールドのカード１枚を選んで破壊する。\n\n●３回以上：相手の手札を確認し、その中からカード１枚を選んで捨てる。\n\n②：コイントスを２回以上行う効果が発動した時、墓地のこのカードを除外して発動できる。\nそのコイントスの結果を全て表が出たものとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン・カタパルトキャノン",h:"あーむど・どらごん・かたぱるときゃのん",e:"Armed Dragon Catapult Cannon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:3000,tx:"「VWXYZ－ドラゴン・カタパルトキャノン」＋「アームド・ドラゴン LV７」\n自分が上記のカード全ての特殊召喚に成功しているデュエル中に、自分のフィールド・墓地の上記のカードを除外した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードがモンスターゾーンに存在する限り、相手は除外されている自分・相手のカードと同名カードの効果を発動できない。\n②：相手ターンに１度、デッキ・EXデッキからカード１枚を除外して発動できる。\n相手のフィールド・墓地のカードを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ改造",h:"おじゃまかいぞう",e:"Ojamassimilation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：EXデッキの機械族・光属性の融合モンスター１体を相手に見せ、自分の手札・フィールド・墓地の「おジャマ」モンスターを任意の数だけ除外して発動できる。\n見せたモンスターにカード名が記されている融合素材モンスターを、除外したモンスターの数だけ自分の手札・デッキ・墓地から選んで特殊召喚する（同名カードは１枚まで）。\n②：墓地のこのカードを除外し、除外されている自分の「おジャマ」モンスター３体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマッチング",h:"おじゃまっちんぐ",e:"Ojamatch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札及び自分フィールドの表側表示のカードの中から、「おジャマ」カード１枚を墓地へ送って発動できる。\nそのカードとカード名が異なる「おジャマ」モンスター１体と「アームド・ドラゴン」モンスター１体を自分のデッキ・墓地から選んで手札に加える。\nその後、この効果で手札に加えたモンスター１体を召喚できる。\n②：自分メインフェイズに墓地のこのカードを除外し、除外されている自分の「おジャマ」モンスター３体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマパーティ",h:"おじゃまぱーてぃ",e:"Ojama Pajama",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\nデッキから「おジャマ」カード１枚を手札に加え、その後手札を１枚選んで捨てる。\n②：自分フィールドの、「アームド・ドラゴン」モンスターまたは機械族・光属性の融合モンスターが戦闘・効果で破壊される場合、代わりに自分の手札・フィールド・墓地の「おジャマ」カード１枚を除外できる。\n③：このカードが墓地へ送られた場合に発動できる。\n除外されている自分の「おジャマ」モンスターを可能な限り特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械素体",h:"あんてぃーく・ぎあふれーむ",e:"Ancient Gear Frame",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\n「古代の機械巨人」１体または「古代の機械巨人」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n③：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n手札から「古代の機械巨人」「古代の機械巨人－アルティメット・パウンド」を合計３体まで召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械超巨人",h:"あんてぃーく・ぎあ・めがとん・ごーれむ",e:"Ancient Gear Megaton Golem",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3300,de:3300,tx:"「アンティーク・ギア」モンスター×３\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：「古代の機械巨人」「古代の機械巨人－アルティメット・パウンド」の中から合計２体以上を素材として融合召喚したこのカードは、その数まで１度のバトルフェイズ中に攻撃できる。\n③：融合召喚した表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\nEXデッキから「古代の機械究極巨人」１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"古代の機械融合",h:"あんてぃーく・ぎあふゅーじょん",e:"Ancient Gear Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「アンティーク・ギア」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n自分フィールドの、「古代の機械巨人」または「古代の機械巨人－アルティメット・パウンド」を融合素材とする場合、自分のデッキのモンスターも融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"競闘－クロス・ディメンション",h:"きょうとう－くろす・でぃめんしょん",e:"Cross-Dimensional Duel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「アンティーク・ギア」モンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果で除外したモンスターは次のスタンバイフェイズにフィールドに戻り、その攻撃力はそのターンの終了時まで元々の攻撃力の倍になる。\n②：自分フィールドの、「古代の機械巨人」または「古代の機械巨人－アルティメット・パウンド」が戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"究極宝玉神 レインボー・オーバー・ドラゴン",h:"きゅうきょくほうぎょくしん　れいんぼー・おーばー・どらごん",e:"Rainbow Overdragon",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:0,tx:"「宝玉獣」モンスター×７\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●自分フィールドのレベル１０の「究極宝玉神」モンスター１体をリリースした場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、自分の墓地から「宝玉獣」モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで、除外したモンスターの攻撃力分アップする。\n②：融合召喚したこのカードをリリースして発動できる。\nフィールドのカードを全て持ち主のデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"虹の架け橋",h:"にじのかけはし",e:"Rainbow Bridge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「宝玉」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"宝玉の絆",h:"ほうぎょくのきずな",e:"Crystal Bond",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「宝玉獣」モンスター１体を手札に加え、そのモンスターとカード名が異なる「宝玉獣」モンスター１体をデッキから選び、永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"究極宝玉陣",h:"きゅうきょくほうぎょくじん",e:"Ultimate Crystal Magic",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の「宝玉獣」モンスターが戦闘で破壊された時に、手札・デッキ及び自分フィールドの表側表示のカードの中から、「宝玉獣」カード７種類を１枚ずつ墓地へ送って発動できる。\n「究極宝玉神」融合モンスター１体を融合召喚扱いとしてEXデッキから特殊召喚する。\n②：表側表示の「究極宝玉神」モンスターが相手の効果で自分フィールドから離れた場合、墓地のこのカードを除外して発動できる。\n自分の墓地の「宝玉獣」モンスターを任意の数だけ選び、永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"セキュリティ・ドラゴン",h:"せきゅりてぃ・どらごん",e:"Security Dragon",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1100,de:"-",tx:"モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドに表側表示で存在する限り１度だけ、このカードが相互リンク状態の場合に相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"パーペチュアルキングデーモン",h:"ぱーぺちゅあるきんぐでーもん",e:"Masterking Archfiend",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"悪魔族モンスター２体\nこのカードのコントローラーは、自分スタンバイフェイズ毎に５００LPを払う。\nまたは払わずにこのカードを破壊する。\nこのカードの①②の効果はそれぞれ同一チェーン上では１度しか発動できない。\n①：自分がLPを払った場合に発動できる。\nその数値と同じ攻撃力か守備力の悪魔族モンスター１体をデッキから墓地へ送る。\n②：悪魔族モンスターが自分の墓地へ送られた場合に発動できる。\nサイコロを１回振り、その内の１体に出た目の効果を適用する。\n\n●１：手札に加える。\n\n●２～５：デッキに戻す。\n\n●６：特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"剣闘獣ドラガシス",h:"ぐらでぃあるびーすとどらがしす",e:"Gladiator Beast Dragases",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「剣闘獣」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の「剣闘獣」モンスターが攻撃する場合、そのモンスターはその戦闘では破壊されず、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のEXデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター２体を特殊召喚する（同名カードは１枚まで）。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ライトロード・ドミニオン キュリオス",h:"らいとろーど・どみにおん　きゅりおす",e:"Curious, the Lightsworn Dominion",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"同じ属性で種族が異なるモンスター３体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキからカード１枚を選んで墓地へ送る。\n②：自分のデッキのカードが効果で墓地へ送られた場合に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。\n③：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、自分の墓地のカード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"ジェムナイト・ファントムルーツ",h:"じぇむないと・ふぁんとむるーつ",e:"Gem-Knight Phantom Quartz",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["リンク","効果"],ma:"",tp:"",at:1450,de:"-",tx:"「ジェム」モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「ジェムナイト」カード１枚を手札に加える。\n②：１０００LPを払って発動できる。\n自分の墓地のカード及び除外されている自分のカードの中から、「ジェムナイト」融合モンスターカードによって決められた融合素材モンスターをデッキに戻し、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはこのターン直接攻撃できない。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"インヴェルズ・オリジン",h:"いんう゛ぇるず・おりじん",e:"Steelswarm Origin",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「ヴェルズ」モンスター２体\n①：このカードがEXモンスターゾーンに存在する限り、お互いにEXデッキからメインモンスターゾーンにモンスターを特殊召喚する場合、このカードのリンク先にしか出せない。\n②：このカードのリンク先にモンスターが存在する限り、このカードは効果の対象にならず、戦闘・効果では破壊されない。\n③：１ターンに１度、フィールドのモンスターが戦闘・効果で破壊された時に発動できる。\nその破壊されたモンスターの数まで、デッキからレベル４以下の「ヴェルズ」モンスターを守備表示で特殊召喚する。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"甲虫装機 ピコファレーナ",h:"いんぜくたー　ぴこふぁれーな",e:"Inzektor Picofalena",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"昆虫族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨て、このカード以外の自分フィールドの昆虫族モンスター１体を対象として発動できる。\nデッキから昆虫族モンスター１体を選び、攻撃力・守備力５００アップの装備カード扱いとして対象のモンスターに装備する。\n②：自分の墓地の昆虫族モンスター３体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"天球の聖刻印",h:"てんきゅうのせいこくいん",e:"Hieratic Seal of the Heavenly Spheres",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"ドラゴン族モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：相手ターンに１度、このカードがEXモンスターゾーンに存在する場合、自分の手札・フィールドのモンスター１体をリリースして発動できる。\nフィールドの表側表示のカード１枚を選んで持ち主の手札に戻す。\n②：このカードがリリースされた場合に発動する。\n手札・デッキからドラゴン族モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"魔導原典 クロウリー",h:"まどうげんてん　くろうりー",e:"Crowley, the First Propheseer",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"魔法使い族モンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「魔導書」カード３種類を相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りのカードはデッキに戻す。\n②：このカードがモンスターゾーンに存在する限り、自分はレベル５以上の魔法使い族モンスターを召喚する場合に必要なリリースをなくす事ができる。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"フレッシュマドルチェ・シスタルト",h:"ふれっしゅまどるちぇ・しすたると",e:"Madolche Fresh Sistart",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"「マドルチェ」モンスター２体\n①：このカードのリンク先に「マドルチェ」モンスターが存在する限り、自分フィールドの「マドルチェ」魔法・罠カードは効果の対象にならず、効果では破壊されない。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりに自分の墓地の「マドルチェ」モンスター１体をデッキに戻す事ができる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"水精鱗－サラキアビス",h:"まーめいる－さらきあびす",e:"Mermail Abyssalacia",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"魚族・海竜族・水族モンスター２体\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのリンク先のモンスターの攻撃力・守備力は５００アップする。\n②：相手ターンに手札を１枚墓地へ送って発動できる。\nデッキから「水精鱗」モンスター１体を手札に加える。\n③：このカードが相手モンスターの攻撃または相手の効果で破壊された場合、デッキから水属性モンスター１体を墓地へ送り、自分の墓地の水属性モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"聖騎士の追想 イゾルデ",h:"せいきしのついそう　いぞるで",e:"Isolde, Two Tales of the Noble Knights",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"戦士族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから戦士族モンスター１体を手札に加える。\nこのターン自分は、この効果で手札に加えたモンスター及びその同名モンスターを通常召喚・特殊召喚できず、そのモンスター効果も発動できない。\n②：デッキから装備魔法カードを任意の数だけ墓地へ送って発動できる（同名カードは１枚まで）。\n墓地へ送ったカードの数と同じレベルの戦士族モンスター１体をデッキから特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"シャドール・ネフィリム",h:"しゃどーる・ねふぃりむ",e:"Shaddoll Construct",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"リバースモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札・フィールドから、「シャドール」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードが墓地に存在する場合に発動できる。\n手札及び自分フィールドの表側表示のカードの中から、「シャドール」カード１枚を選んで墓地へ送り、このカードを特殊召喚する。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"クリフォート・ゲニウス",h:"くりふぉーと・げにうす",e:"Qliphort Genius",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"機械族モンスター２体\n①：リンク召喚したこのカードは魔法・罠カードの効果を受けず、このカード以外のリンクモンスターが発動した効果も受けない。\n②：１ターンに１度、このカード以外の、自分及び相手フィールドの表側表示のカードを１枚ずつ対象として発動できる。\nそのカード２枚の効果をターン終了時まで無効にする。\n③：このカードのリンク先にモンスター２体が同時に特殊召喚された時に発動できる。\nデッキからレベル５以上の機械族モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"聖霊獣騎 キムンファルコス",h:"せいれいじゅうき　きむんふぁるこす",e:"Ritual Beast Ulti-Kimunfalcos",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"「霊獣」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先の「霊獣」モンスターの攻撃力・守備力は６００アップする。\n②：自分の墓地から「霊獣」カード１枚を除外して発動できる。\n手札から「霊獣」モンスター１体を召喚する。\n③：このカードを持ち主のEXデッキに戻し、除外されている自分の、「霊獣使い」モンスター１体と「精霊獣」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"セフィラ・メタトロン",h:"せふぃら・めたとろん",e:"Zefra Metaltron",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"幻竜族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"EXデッキから特殊召喚されたモンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのリンク先の、EXデッキから特殊召喚されたモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地のモンスター及び自分のEXデッキの表側表示のPモンスターの中から、モンスター１体を選んで手札に加える。\n②：このカード以外の、自分及び相手フィールドのEXデッキから特殊召喚されたモンスターを１体ずつ対象として発動できる。\nそのモンスター２体をエンドフェイズまで除外する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"アロマセラフィ－ジャスミン",h:"あろませらふぃ－じゃすみん",e:"Aromaseraphy Jasmine",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"植物族モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のLPが相手より多い場合、このカード及びこのカードのリンク先の植物族モンスターは戦闘では破壊されない。\n②：このカードのリンク先の自分のモンスター１体をリリースして発動できる。\nデッキから植物族モンスター１体を守備表示で特殊召喚する。\n③：１ターンに１度、自分のLPが回復した場合に発動する。\nデッキから植物族モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"彼岸の黒天使 ケルビーニ",h:"ひがんのくろてんし　けるびーに",e:"Cherubini, Ebon Angel of the Burning Abyss",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:500,de:"-",tx:"レベル３モンスター２体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先のモンスターは効果では破壊されない。\n②：フィールドのこのカードが戦闘または相手の効果で破壊される場合、代わりに自分フィールドのカード１枚を墓地へ送る事ができる。\n③：デッキからレベル３モンスター１体を墓地へ送り、フィールドの「彼岸」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、墓地へ送ったモンスターのそれぞれの数値分アップする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ヘビーメタルフォーゼ・エレクトラム",h:"へびーめたるふぉーぜ・えれくとらむ",e:"Heavymetalfoes Electrumite",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイキック族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"Pモンスター２体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキからPモンスター１体を選び、自分のEXデッキに表側表示で加える。\n②：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、自分のEXデッキから表側表示のPモンスター１体を手札に加える。\n③：自分のPゾーンのカードがフィールドから離れた場合に発動する。\n自分はデッキから１枚ドローする。",li:1,lk:["左下","右下"],ta:[],al:[]},
  {n:"水晶機巧－ハリファイバー",h:"くりすとろん－はりふぁいばー",e:"Crystron Halqifibrax",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"チューナーを含むモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\n手札・デッキからレベル３以下のチューナー１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。\n②：相手のメインフェイズ及びバトルフェイズにフィールドのこのカードを除外して発動できる。\nEXデッキからSモンスターのチューナー１体をS召喚扱いで特殊召喚する。",li:1,lk:["左下","右下"],ta:[],al:[]},
  {n:"暴走召喚師アレイスター",h:"ぼうそうしょうかんしあれいすたー",e:"Aleister the Invoker of Madness",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"種族と属性が異なるモンスター２体\n①：このカードのカード名は、フィールド・墓地に存在する限り「召喚師アレイスター」として扱う。\n②：このカードが既にモンスターゾーンに存在する状態で、融合モンスターが融合召喚された場合に発動できる。\n手札を１枚選んで捨て、デッキから「召喚魔術」または「法の聖典」１枚を手札に加える。\n③：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\nデッキから「魔法名－「大いなる獣」」１枚を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ダブルバイト・ドラゴン",h:"だぶるばいと・どらごん",e:"Doublebyte Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"リンクモンスター２体\n①：このカードの攻撃力は、このカードのリンク素材としたモンスターのリンクマーカーの合計×３００アップする。\n②：このカードはモンスターゾーンに存在する限り、リンクモンスター以外のモンスターの効果を受けず、リンクモンスター以外のモンスターとの戦闘では破壊されない。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"フォーマッド・スキッパー",h:"ふぉーまっど・すきっぱー",e:"Formud Skipper",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nEXデッキのリンクモンスター１体を相手に見せる。\nこのターンにリンク召喚する場合、このカードは見せたモンスターと同じカード名・種族・属性の素材としても扱える。\n②：このカードがリンク素材として墓地へ送られた場合に発動できる。\nデッキからレベル５以上のサイバース族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・フォクシーウィッチ",h:"とりっくすたー・ふぉくしーうぃっち",e:"Trickstar Foxglove Witch",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2200,de:"-",tx:"天使族モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n相手フィールドのカードの数×２００ダメージを相手に与える。\n②：リンク召喚したこのカードが戦闘・効果で破壊された場合に発動できる。\nEXデッキからリンク２以下の「トリックスター」モンスター１体を特殊召喚する。\nその後、相手フィールドのカードの数×２００ダメージを相手に与える。",li:"",lk:["上","左","右"],ta:[],al:[]},
  {n:"トリックスター・マジカローラ",h:"とりっくすたー・まじかろーら",e:"Trickstar Magical Laurel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「トリックスター」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：１ターンに１度、装備モンスターが戦闘・効果で相手にダメージを与えた場合に発動できる。\n手札から「トリックスター」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ジェット・オーガ",h:"ごうきじぇっと・おーが",e:"Gouki Jet Ogre",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「剛鬼」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分フィールドの「剛鬼」カード１枚を対象として発動できる。\nそのカードを破壊し、フィールドのモンスターを全て表側攻撃表示にする。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分フィールドの全ての「剛鬼」モンスターの攻撃力はターン終了時まで５００アップする。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"剛鬼死闘",h:"ごうきですまっち",e:"Gouki Cage Match",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、このカードにカウンターを３つ置く。\n②：自分の「剛鬼」モンスターが戦闘で相手モンスターを破壊した場合に発動する。\nこのカードのカウンターを１つ取り除く。\n③：このカードの効果でこのカードに置かれているカウンターが全て取り除かれたバトルフェイズ終了時に、自分はこの効果を発動できる。\n手札・デッキから「剛鬼」モンスターを可能な限り特殊召喚する（同名カードは１枚まで）。\nその後、このカードにカウンターを３つ置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ブースター・ドラゴン",h:"ぶーすたー・どらごん",e:"Booster Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"「ヴァレット」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は５００アップする。\nこの効果の発動に対して相手はカードの効果を発動できない。\n②：リンク召喚したこのカードが戦闘・効果で破壊され墓地へ送られた場合、このカード以外の自分の墓地のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"タクティカル・エクスチェンバー",h:"たくてぃかる・えくすちぇんばー",e:"Tactical Exchanger",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、そのモンスターと元々のカード名が異なる「ヴァレット」モンスター１体を自分のデッキ・墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドングルドングリ",h:"どんぐるどんぐり",e:"Datacorn",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドに「ドングルトークン」（サイバース族・闇・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガベージコレクター",h:"がべーじこれくたー",e:"Garbage Collector",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:1900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドのサイバース族モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、手札に戻ったモンスターと同じレベルでカード名が異なるサイバース族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シーアーカイバー",h:"しーあーかいばー",e:"Sea Archiver",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:300,de:2100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、フィールドのリンクモンスターのリンク先にモンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"フレイム・バッファロー",h:"ふれいむ・ばっふぁろー",e:"Flame Bufferlo",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：表側表示のこのカードがフィールドから離れた場合に発動できる。\n手札からサイバース族モンスター１体を捨て、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"レディ・デバッガー",h:"れでぃ・でばっがー",e:"Lady Debug",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキからレベル３以下のサイバース族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アンチエイリアン",h:"あんちえいりあん",e:"Antialian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1900,de:600,tx:"①：１ターンに１度、このカードがモンスターと戦闘を行った自分・相手のバトルフェイズに発動できる。\n手札からサイバース族モンスター１体を召喚する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ストームサイファー",h:"すとーむさいふぁー",e:"Storm Cipher",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"①：このカードは直接攻撃できず、EXモンスターゾーンのモンスターには攻撃できない。\n②：このカードはEXモンスターゾーンのモンスターが発動した効果を受けず、EXモンスターゾーンのモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"セグメンタル・ドラゴン",h:"せぐめんたる・どらごん",e:"Segmental Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2600,de:2400,tx:"①：このカードはリリースなしで通常召喚できる。\n②：このカードの①の方法で通常召喚したこのカードの元々の攻撃力・守備力は半分になる。\n③：１ターンに１度、このカードが通常召喚されている場合に発動できる。\n表側表示のこのカードを破壊し、その攻撃力以下の攻撃力を持つメインモンスターゾーンのモンスターを全て破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・ストーム",h:"さいばねっと・すとーむ",e:"Cynet Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドのリンク状態のモンスターの攻撃力・守備力は５００アップする。\n②：このカードがフィールドゾーンに存在する限り、リンクモンスターのリンク召喚は無効化されない。\n③：自分が２０００以上の戦闘・効果ダメージを受けた場合に発動できる。\n自分のEXデッキの裏側表示のカードだけをシャッフルし、その一番上のカードをめくる。\nめくったカードがサイバース族リンクモンスターだった場合、そのモンスターを特殊召喚する。\n違った場合は元に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"パケットリンク",h:"ぱけっとりんく",e:"Packet Link",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズ２に発動できる。\n自分の手札・デッキ・墓地のレベル２以下のモンスターを任意の数だけ選び、フィールドのリンクモンスターのリンク先となる自分フィールドに特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"パワーコード・トーカー",h:"ぱわーこーど・とーかー",e:"Powercode Talker",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"モンスター３体\n①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n②：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージ計算時に、このカードのリンク先の自分のモンスター１体をリリースして発動できる。\nこのカードの攻撃力はそのダメージ計算時のみ、元々の攻撃力の倍になる。",li:"",lk:["左","右","左下"],ta:[],al:[]},
  {n:"トラフィックゴースト",h:"とらふぃっくごーすと",e:"Traffic Ghost",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク"],ma:"",tp:"",at:1800,de:"-",tx:"モンスター３体",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"LANフォリンクス",h:"らんふぉりんくす",e:"LANphorhynchus",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク"],ma:"",tp:"",at:1200,de:"-",tx:"モンスター２体",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"スモウ魂 YOKO－ZUNA",h:"すもうすぴりっと　よこづな",e:"Yoko-Zuna Sumo Spirit",t:"monster",a:"風",l:5,ps:1,pe:"①：フィールドにモンスターがP召喚された場合に発動する。\nPゾーンのこのカードを持ち主の手札に戻す。",tr:"戦士族",mo:["ペンデュラム","スピリット","効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のPゾーンのカードと同じ縦列の相手のモンスターを全て墓地へ送る。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"カラテ魂 KURO－OBI",h:"からてすぴりっと　くろおび",e:"Kuro-Obi Karate Spirit",t:"monster",a:"風",l:5,ps:9,pe:"①：フィールドにモンスターがP召喚された場合に発動する。\nPゾーンのこのカードを持ち主の手札に戻す。",tr:"戦士族",mo:["ペンデュラム","スピリット","効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のPゾーンのカードと同じ縦列の相手の魔法・罠カードを全て墓地へ送る。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・バスタード",h:"う゛ぇんでっと・ばすたーど",e:"Vendread Battlelord",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「ヴェンデット」カード１枚を除外し、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\nこのターン、相手は宣言した種類のカードの効果を発動できない。\n②：儀式召喚したこのカードが墓地へ送られた場合に発動できる。\nデッキから儀式モンスター１体を手札に加え、デッキから「ヴェンデット」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・コア",h:"う゛ぇんでっと・こあ",e:"Vendread Core",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地からこのカード以外のアンデット族モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●このカードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・チャージ",h:"う゛ぇんでっと・ちゃーじ",e:"Vendread Charge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、アンデット族モンスター１体を墓地へ送って発動できる。\nデッキから「ヴェンデット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・リバース",h:"う゛ぇんでっと・りばーす",e:"Vendread Revolution",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨て、自分の墓地の、「ヴェンデット」モンスター１体と儀式魔法カード１枚を対象として発動できる。\nそのモンスターを守備表示で特殊召喚し、その儀式魔法カードを手札に加える。\n②：墓地のこのカードを除外し、除外されている自分のアンデット族モンスター５体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.カーナビゲーター",h:"ふぉーみゅらあすりーとかーなびげーたー",e:"F.A. Auto Navigator",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの元々のレベルより高いレベルを持つ「F.A.」モンスター１体を対象として発動できる。\nこのカードを特殊召喚し、対象のモンスターのレベルをその元々のレベルとの差分だけ下げる。\nこの効果で特殊召喚したこのカードのレベルはその差分の数値と同じになる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「F.A.」フィールド魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ホームトランスポーター",h:"ふぉーみゅらあすりーとほーむとらんすぽーたー",e:"F.A. Motorhome Transport",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードの攻撃力はこのカードのレベル×３００アップする。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードは、このカードのレベルによって以下の効果を得る。\n●レベル１１以上：このカードは戦闘・効果では破壊されない。\n●レベル１３以上：１ターンに１度、自分の墓地の「F.A.」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.シティGP",h:"ふぉーみゅらあすりーとしてぃぐらんぷり",e:"F.A. City Grand Prix",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、フィールドの「F.A.」モンスターのレベルはメインフェイズ及びバトルフェイズの間だけ２つ上がる。\n②：自分フィールドの「F.A.」モンスターは相手の効果の対象にならない。\n③：フィールドの表側表示のこのカードが効果で破壊された場合に発動できる。\nデッキから「F.A.シティGP」以外の「F.A.」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.シェイクダウン",h:"ふぉーみゅらあすりーとしぇいくだうん",e:"F.A. Test Run",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「F.A.」モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\nその後、フィールドのカード１枚を選んで破壊する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「F.A.」モンスター１体を特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナ エクストラジョーカー",h:"あるかな　えくすとらじょーかー",e:"Arcana Extra Joker",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"カード名が異なる戦士族モンスター３体\n①：１ターンに１度、フィールドのこのカードまたはこのカードのリンク先のモンスターを対象とする、モンスターの効果・魔法・罠カードが発動した時、そのカードと同じ種類（モンスター・魔法・罠）の手札を１枚捨てて発動できる。\nその発動を無効にする。\n②：リンク召喚したこのカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから戦士族・レベル４の通常モンスター１体を特殊召喚し、デッキから戦士族・レベル４モンスター１体を手札に加える。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"X・HERO ワンダー・ドライバー",h:"えくすとらひーろー　わんだー・どらいばー",e:"Xtra HERO Wonder Driver",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"「HERO」モンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先となる自分フィールドに「HERO」モンスターが召喚・特殊召喚された場合、自分の墓地の、「融合」魔法カード、「フュージョン」魔法カード、「チェンジ」速攻魔法カードの内、いずれか１枚を対象として発動する。\nそのカードを自分フィールドにセットする。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\n手札から「HERO」モンスター１体を特殊召喚する。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"ジャンク・コネクター",h:"じゃんく・こねくたー",e:"Junk Connector",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1700,de:"-",tx:"チューナー１体以上を含む戦士族・機械族の効果モンスター２体\n①：１ターンに１度、自分・相手のメインフェイズ及びバトルフェイズに発動できる。\nこのカードのリンク先のモンスターのみを素材としてS召喚する。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\nEXデッキから「ジャンク」Sモンスター１体をS召喚扱いで特殊召喚する。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"白闘気一角",h:"ほわいと・おーら・ものけろす",e:"White Aura Monoceros",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"水属性チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時、自分の墓地の魚族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n②：このカードが相手によって破壊され墓地へ送られた場合、このカード以外の自分の墓地の水属性モンスター１体を除外して発動できる。\nこのカードをチューナー扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.5 亡朧竜 デス・キマイラ・ドラゴン",h:"なんばーず５ もうろうりゅう です・きまいら・どらごん",e:"Number 5: Doom Chimera Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル５モンスター×２体以上\n①：このカードの攻撃力は、このカードのX素材の数×１０００アップする。\n②：X素材を持っているこのカードは相手モンスター全てに１回ずつ攻撃できる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に、以下の効果から１つを選択して発動できる。\n●自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n●相手の墓地のカード１枚を対象として発動できる。\nそのカードを相手のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・ホルト",h:"ぺんでゅらむ・ほると",e:"Pendulum Halt",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のEXデッキに表側表示のPモンスターが３種類以上存在する場合に発動できる。\n自分はデッキから２枚ドローする。\nこのカードの発動後、ターン終了時まで自分はデッキからカードを手札に加える事はできない。",li:"",lk:[],ta:[],al:[]},
  {n:"白の救済",h:"ほわいと・さるべーじ",e:"Whitefish Salvage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の魚族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが相手の効果で破壊され墓地へ送られた場合に発動できる。\nデッキから魚族モンスター１体を手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アクションマジック－フルターン",h:"あくしょんまじっく－ふるたーん",e:"Action Magic - Full Turn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターン、モンスター同士の戦闘で発生するお互いの戦闘ダメージは倍になる。\n②：このカードが墓地に存在する場合、自分メインフェイズに手札から魔法カード１枚を捨てて発動できる。\nこのカードを自分の魔法＆罠ゾーンにセットする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アクションマジック－ダブル・バンキング",h:"あくしょんまじっく－だぶる・ばんきんぐ",e:"Action Magic - Double Banking",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n自分フィールドのモンスターは、このターン戦闘で相手モンスターを破壊した場合、もう１度だけ続けて攻撃できる。\n②：このカードが墓地に存在する場合、自分メインフェイズに手札から魔法カード１枚を捨てて発動できる。\nこのカードを自分の魔法＆罠ゾーンにセットする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"白の咆哮",h:"ほわいと・はうりんぐ",e:"White Howling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに水属性モンスターが存在する場合、相手の墓地の魔法カード１枚を対象として発動できる。\nそのカードを除外し、このターン、相手フィールドの魔法カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・アドバンサー",h:"ふぉとん・あどばんさー",e:"Photon Advancer",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドに「フォトン」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドにこのカード以外の「フォトン」モンスターが存在する場合、このカードの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サモン・ソーサレス",h:"さもん・そーされす",e:"Summon Sorceress",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"トークン以外の同じ種族のモンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\n手札からモンスター１体を、このカードのリンク先となる相手フィールドに守備表示で特殊召喚する。\n②：このカードのリンク先の表側表示モンスター１体を対象として発動できる。\nそのモンスターと同じ種族のモンスター１体をデッキから選び、このカードのリンク先となる自分・相手フィールドに守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:0,lk:["上","右下","左下"],ta:[],al:[]},
  {n:"トリックスター・キャロベイン",h:"とりっくすたー・きゃろべいん",e:"Trickstar Corobane",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのモンスターが、存在しない場合または「トリックスター」モンスターのみの場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分の「トリックスター」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nその自分のモンスターの攻撃力はターン終了時まで、その元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"プロトロン",h:"ぷろとろん",e:"Protron",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:100,de:100,tx:"電子空間で見つかる原種。\nその情報量は未知数。",li:"",lk:[],ta:[],al:[]},
  {n:"プロンプトホーン",h:"ぷろんぷとほーん",e:"Prompthorn",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル４以下のサイバース族モンスター１体をリリースして発動できる。\nレベルの合計がリリースしたモンスターのレベルと同じになるように、自分のデッキ・墓地からサイバース族の通常モンスターを任意の数だけ選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バックアップ・オペレーター",h:"ばっくあっぷ・おぺれーたー",e:"Backup Operator",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分のリンクモンスターのリンク先の表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターを持ち主の手札に戻す。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"リンク・ストリーマー",h:"りんく・すとりーまー",e:"Link Streamer",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにサイバース族モンスターが召喚・特殊召喚された時に発動できる。\n自分フィールドに「データトークン」（サイバース族・光・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デグレネード・バスター",h:"でぐれねーど・ばすたー",e:"Degrade Buster",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"サイバース族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地のサイバース族モンスター２体を除外した場合に特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力より高い攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターをエンドフェイズまで除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・ヒヨス",h:"とりっくすたー・ひよす",e:"Trickstar Nightshade",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが「トリックスター」リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・マンドレイク",h:"とりっくすたー・まんどれいく",e:"Trickstar Mandrake",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札から墓地へ送られた場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n ②：このカードが「トリックスター」リンクモンスターのリンク素材として墓地へ送られた場合、相手のリンクモンスターのリンク先のモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・シャクナージュ",h:"とりっくすたー・しゃくなーじゅ",e:"Trickstar Rhodode",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1900,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札から「トリックスター」カード１枚を捨て、自分の墓地の「トリックスター」リンクモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手の墓地からカードが除外される度に、その除外されたカードの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼マンジロック",h:"ごうきまんじろっく",e:"Gouki Octostretch",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：相手モンスターが攻撃した場合、そのダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージは半分になる。\n②：自分にダメージを与える効果を相手が発動した時、このカードを手札から捨てて発動できる（ダメージステップでも発動可能）。\nその効果で自分が受けるダメージは半分になる。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼マンジロック」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ハッグベア",h:"ごうきはっぐべあ",e:"Gouki Bearhug",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚または「剛鬼」カードの効果で特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の攻撃力の半分になる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ハッグベア」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デフラドラグーン",h:"でふらどらぐーん",e:"Defrag Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは手札の他のモンスター１体を墓地へ送り、手札から特殊召喚できる。\n②：このカードが墓地に存在する場合、自分の墓地からこのカード以外の同名モンスター３体を除外して発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バックグランド・ドラゴン",h:"ばっくぐらんど・どらごん",e:"Background Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドにカードが存在しない場合に発動できる。\n墓地のこのカードと手札のレベル４以下のドラゴン族モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・トリニティ",h:"てぃんだんぐる・とりにてぃ",e:"Tindangle Trinity",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:1500,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「ティンダングル・ベース・ガードナー」１体を特殊召喚する。\n②：リバースしたこのカードがモンスターゾーンに存在する限り、自分の「ティンダングル」モンスターは戦闘では破壊されない。\n③：このカードが「ティンダングル」リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\nデッキから「ジェルゴンヌの終焉」１枚を手札に加え、デッキから魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・ピクシール",h:"おるたーがいすと・ぴくしーる",e:"Altergeist Pixiel",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n自分のデッキの上からカードを３枚めくり、その中から「オルターガイスト」カード１枚を選んで手札に加え、残りのカードは墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"機界騎士アヴラム",h:"じゃっくないつあう゛らむ",e:"Mekk-Knight Avram",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"星の光を守護する勇者\n幻界せし闇を討つべく\n選ばれしものに力を託す。\n\n\n星の杯に継がれし意志は新たな鍵となり\n闇を絶ち切る剣とならん。",li:"",lk:[],ta:[],al:[]},
  {n:"夢幻崩界イヴリース",h:"ですとろいめあいう゛りーす",e:"Knightmare Corruptor Iblee",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地のリンクモンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、効果を無効にして、このカードとリンク状態となるように自分フィールドに特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、このカードのコントローラーはリンクモンスターしか特殊召喚できない。\n③：このカードが自分フィールドから墓地へ送られた場合に発動できる。\nこのカードを相手フィールドに守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物－『星槍』",h:"せいいぶつ－『せいそう』",e:"World Legacy - \"World Lance\"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：リンクモンスターを含むモンスター同士が戦闘を行うダメージ計算時に、このカードを手札から捨てて発動できる。\nその戦闘を行う相手モンスターの攻撃力は３０００ダウンする。\n②：このカードがモンスターゾーンに存在する限り、相手は他の「星遺物」モンスターを攻撃できない。\n③：EXデッキからモンスターが特殊召喚された場合に発動する。\nお互いのフィールドに「星遺物トークン」（機械族・闇・星１・攻／守０）を１体ずつ守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントセイバー・アイナ",h:"えれめんとせいばー・あいな",e:"Elementsaber Aina",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1800,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送り、自分の墓地の、「エレメントセイバー・アイナ」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントセイバー・マカニ",h:"えれめんとせいばー・まかに",e:"Elementsaber Makani",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送って発動できる。\nデッキから「エレメントセイバー・マカニ」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントセイバー・ナル",h:"えれめんとせいばー・なる",e:"Elementsaber Nalu",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送り、自分の墓地の、「エレメントセイバー・ナル」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントセイバー・マロー",h:"えれめんとせいばー・まろー",e:"Elementsaber Malo",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送って発動できる。\nデッキから「エレメントセイバー・マロー」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を墓地へ送る。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントセイバー・ラパウィラ",h:"えれめんとせいばー・らぱうぃら",e:"Elementsaber Lapauila",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:2100,tx:"①：１ターンに１度、魔法・罠カードが発動した時、手札から「エレメントセイバー」モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントセイバー・モーレフ",h:"えれめんとせいばー・もーれふ",e:"Elementsaber Molehu",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送り、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"エレメントセイバー・ウィラード",h:"えれめんとせいばー・うぃらーど",e:"Elementsaber Lapauila Mana",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札から他のモンスター２体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、このカードの①の効果を発動するために墓地へ送った「エレメントセイバー」モンスターの元々の属性によって、自分フィールドの「エレメントセイバー」モンスター及び「霊神」モンスターに以下を適用する。\n\n●地または風：戦闘では破壊されない。\n\n●水または炎：効果では破壊されない。\n\n●光または闇：相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"光霊神フォスオラージュ",h:"こうれいしんふぉすおらーじゅ",e:"Phosphorage the Elemental Lord",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の光属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時に発動できる。\n相手フィールドのモンスターを全て破壊する。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽電池メン",h:"たいようでんちめん",e:"Batteryman Solar",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから雷族モンスター１体を墓地へ送る。\n②：このカードが既にモンスターゾーンに存在する状態で、雷族モンスターが召喚・特殊召喚された場合に発動する。\n自分フィールドに「電池メントークン」（雷族・光・星１・攻／守０）１体を特殊召喚する。\n③：自分のフィールド・墓地の「電池メン」効果モンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはその同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"番猫－ウォッチキャット",h:"ばんびょう－うぉっちきゃっと",e:"Watch Cat",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した自分ターンのエンドフェイズにフィールドのこのカードを除外して発動できる。\nデッキから永続魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"トランスファミリア",h:"とらんすふぁみりあ",e:"Trancefamiliar",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、自分フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターの位置を、他の自分のメインモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[]},
  {n:"妖海のアウトロール",h:"ようかいのあうとろーる",e:"Three Trolling Trolls",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分は獣戦士族モンスターしか特殊召喚できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地の獣戦士族モンスター１体を対象として発動できる。\nこのカードの属性・レベルはターン終了時まで、そのモンスターと同じになる。\n②：自分メインフェイズに発動できる。\nこのカードと同じ属性・レベルを持つ獣戦士族モンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヤジロベーダー",h:"やじろべーだー",e:"Yajiro Invader",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"①：このカードは中央以外のメインモンスターゾーンに召喚・特殊召喚した場合、破壊される。\n②：１ターンに１度、自分メインフェイズに発動できる。\nこのカードを隣の使用していないメインモンスターゾーンへ移動する。\n③：相手フィールドにモンスター１体のみが召喚・特殊召喚される度に発動する。\nその相手モンスターの位置がこのカードと異なる縦列の場合、このカードをその相手モンスターに近づくように隣のメインモンスターゾーンへ移動する。\nその後、移動したこのカードと同じ縦列の他のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄騎龍ティアマトン",h:"てっきりゅうてぃあまとん",e:"Iron Dragon Tiamaton",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できず、このカードの①の効果でのみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：３枚以上のカードが同じ縦列に存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが特殊召喚に成功した場合に発動する。\nこのカードと同じ縦列の他のカードを全て破壊する。\n③：このカードがモンスターゾーンに存在する限り、このカードと同じ縦列の使用していないゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"屋敷わらし",h:"やしきわらし",e:"Ghost Belle & Haunted Mansion",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：以下のいずれかの効果を含む魔法・罠・モンスターの効果が発動した時、このカードを手札から捨てて発動できる。\nその発動を無効にする。\n\n●墓地からカードを手札・デッキ・EXデッキに加える効果\n●墓地からモンスターを特殊召喚する効果\n●墓地からカードを除外する効果",li:"",lk:[],ta:[],al:[]},
  {n:"急き兎馬",h:"せきとば",e:"Red Hared Hasty Horse",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：カードが存在しない縦列がある場合、このカードはその縦列の自分フィールドに手札から攻撃表示で特殊召喚できる。\n②：このカードと同じ縦列に他のカードが置かれた場合に発動する。\nこのカードを破壊する。\n③：１ターンに１度、自分メインフェイズに発動できる。\nこのターン、このカードの元々の攻撃力は半分になり、直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ボイコットン",h:"ぼいこっとん",e:"Boycotton",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:2500,tx:"①：このカードの戦闘で発生する相手への戦闘ダメージは代わりに自分が受ける。\n②：このカードの戦闘でこのカードが破壊されず、自分が戦闘ダメージを受けた場合に発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"トポロジック・トゥリスバエナ",h:"とぽろじっく・とぅりすばえな",e:"Topologic Trisbaena",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"効果モンスター２体以上\n①：このカードのリンク先にモンスターが特殊召喚された場合に発動する。\nそのモンスター及びフィールドの魔法・罠カードを全て除外し、この効果で除外した相手のカードの数×５００ダメージを相手に与える。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"スペース・インシュレイター",h:"すぺーす・いんしゅれいたー",e:"Space Insulator",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"モンスター２体\n①：このカードのリンク先のモンスターの攻撃力・守備力は８００ダウンする。\n②：このカードが墓地に存在し、自分フィールドにサイバース族リンクモンスターがリンク召喚された時に発動できる。\nそのモンスターのリンク先となる自分フィールドにこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードはリンク素材にできず、フィールドから離れた場合に除外される。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"トリックスター・ベラマドンナ",h:"とりっくすたー・べらまどんな",e:"Trickstar Bella Madonna",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"「トリックスター」モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：リンク召喚したこのカードのリンク先にモンスターが存在しない場合、このカードは他のカードが発動した効果を受けない。\n②：このカードのリンク先にモンスターが存在しない場合に発動できる。\n自分の墓地の「トリックスター」モンスターの種類×２００ダメージを相手に与える。",li:"",lk:["上","右","左下","下"],ta:[],al:[]},
  {n:"トリックスター・ブルム",h:"とりっくすたー・ぶるむ",e:"Trickstar Bloom",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:100,de:"-",tx:"レベル２以下の「トリックスター」モンスター１体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\n相手はデッキから１枚ドローする。\n②：このカードのリンク先の「トリックスター」モンスターが戦闘・効果で破壊された場合に発動できる。\n相手の手札の数×２００ダメージを相手に与える。",li:"",lk:["下"],ta:[],al:[]},
  {n:"トリックスター・デビルフィニウム",h:"とりっくすたー・でびるふぃにうむ",e:"Trickstar Delfiendium",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2200,de:"-",tx:"「トリックスター」モンスター２体以上\n①：このカードのリンク先に「トリックスター」モンスターが存在する場合、このカードの攻撃宣言時に相手フィールドのリンクモンスターの数まで、除外されている自分の「トリックスター」カードを対象として発動できる。\nそのカードを手札に加える。\nこのカードの攻撃力はターン終了時まで、この効果で手札に加えたカードの数×１０００アップする。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"剛鬼ザ・マスター・オーガ",h:"ごうきざ・ますたー・おーが",e:"Gouki The Master Ogre",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"「剛鬼」モンスター２体以上\n①：１ターンに１度、このカードのリンク先の自分の「剛鬼」モンスターを任意の数だけ持ち主の手札に戻し、手札に戻した数だけ相手フィールドの表側表示のカードを対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。\n②：このカードは相手モンスター全てに１回ずつ攻撃でき、相手フィールドに表側表示モンスターが存在する場合、その内の攻撃力が一番高いモンスターしか攻撃対象に選択できない。",li:"",lk:["上","左下","下","右下"],ta:[],al:[]},
  {n:"オルターガイスト・キードゥルガー",h:"おるたーがいすと・きーどぅるがー",e:"Altergeist Kidolga",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「オルターガイスト」モンスター２体\n①：このカード以外の自分の「オルターガイスト」モンスターが相手に戦闘ダメージを与えた時、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは、このカードが攻撃宣言したターンしか攻撃できない。\n②：このカードが戦闘で破壊された場合、自分の墓地の「オルターガイスト」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"トロイメア・マーメイド",h:"とろいめあ・まーめいど",e:"Knightmare Mermaid",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「トロイメア・マーメイド」以外の「トロイメア」モンスター１体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨てて発動できる。\nデッキから「トロイメア」モンスター１体を特殊召喚する。\nこの効果の発動時にこのカードが相互リンク状態だった場合、さらに自分はデッキから１枚ドローできる。\n②：このカードがモンスターゾーンに存在する限り、フィールドの相互リンク状態ではないモンスターの攻撃力・守備力は１０００ダウンする。",li:0,lk:["下"],ta:[],al:[]},
  {n:"トロイメア・ゴブリン",h:"とろいめあ・ごぶりん",e:"Knightmare Goblin",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1300,de:"-",tx:"カード名が異なるモンスター２体\n①：このカードがリンク召喚に成功した場合、手札を１枚捨てて発動できる。\nこの効果の発動時にこのカードが相互リンク状態だった場合、自分はデッキから１枚ドローできる。\n自分は通常召喚に加えて１度だけ、このターンのメインフェイズにこのカードのリンク先となる自分フィールドに手札からモンスター１体を召喚できる。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの相互リンク状態のモンスターは効果の対象にならない。",li:0,lk:["左","右"],ta:[],al:[]},
  {n:"トロイメア・ケルベロス",h:"とろいめあ・けるべろす",e:"Knightmare Cerberus",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"カード名が異なるモンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨て、相手のメインモンスターゾーンの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこの効果の発動時にこのカードが相互リンク状態だった場合、さらに自分はデッキから１枚ドローできる。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの相互リンク状態のモンスターは効果では破壊されない。",li:"",lk:["上","左"],ta:[],al:[]},
  {n:"トロイメア・フェニックス",h:"とろいめあ・ふぇにっくす",e:"Knightmare Phoenix",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"カード名が異なるモンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨て、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果の発動時にこのカードが相互リンク状態だった場合、さらに自分はデッキから１枚ドローできる。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの相互リンク状態のモンスターは戦闘では破壊されない。",li:"",lk:["上","右"],ta:[],al:[]},
  {n:"トロイメア・ユニコーン",h:"とろいめあ・ゆにこーん",e:"Knightmare Unicorn",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:2200,de:"-",tx:"カード名が異なるモンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。\nこの効果の発動時にこのカードが相互リンク状態だった場合、さらに自分はデッキから１枚ドローできる。\n②：自分ドローフェイズの通常のドローの枚数は、フィールドに相互リンク状態の「トロイメア」モンスターが存在する限り、その「トロイメア」モンスターの種類の数になる。",li:"",lk:["左","右","下"],ta:[],al:[]},
  {n:"トロイメア・グリフォン",h:"とろいめあ・ぐりふぉん",e:"Knightmare Gryphon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"カード名が異なるモンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨て、自分の墓地の魔法・罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nそのカードはこのターン発動できない。\nこの効果の発動時にこのカードが相互リンク状態だった場合、さらに自分はデッキから１枚ドローできる。\n②：このカードがモンスターゾーンに存在する限り、フィールドの特殊召喚されたモンスターはリンク状態でなければ効果を発動できない。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"発条補修ゼンマイコン",h:"ぜんまいほしゅうぜんまいこん",e:"Wind-Up Zenmaintenance",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"「ゼンマイ」モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「ゼンマイ」カード１枚を手札に加える。\n②：自分フィールドの表側表示の「ゼンマイ」モンスター１体を裏側表示で除外して発動できる。\nそれと同名のモンスター１体をデッキから特殊召喚する。\n③：このカードが破壊され墓地へ送られた場合、自分フィールドの「ゼンマイ」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ヴァンパイア・サッカー",h:"う゛ぁんぱいあ・さっかー",e:"Vampire Sucker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"アンデット族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを相手フィールドに守備表示で特殊召喚する。\n特殊召喚したそのモンスターはアンデット族になる。\n②：自分・相手の墓地からアンデット族モンスターが特殊召喚された場合に発動する。\n自分はデッキから１枚ドローする。\n③：自分がモンスターをアドバンス召喚する場合、自分フィールドのモンスターの代わりに相手フィールドのアンデット族モンスターをリリースできる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"小法師ヒダルマー",h:"しょうぼうしひだるまー",e:"Fire Fighting Daruma Doll",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"獣族・獣戦士族・鳥獣族モンスター２体\n①：このカードの攻撃力はフィールドの獣族・獣戦士族・鳥獣族モンスターの数×１００アップする。\n②：１ターンに１度、自分及び相手フィールドの魔法・罠カードを１枚ずつ対象として発動できる。\nそのカードを破壊する。\n③：このカードが戦闘で相手モンスターを破壊した時、自分の墓地のモンスター及び除外されている自分のモンスターの中から、獣族・獣戦士族・鳥獣族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"グレートフライ",h:"ぐれーとふらい",e:"Greatfly",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"風属性モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの風属性モンスターの攻撃力・守備力は５００アップし、地属性モンスターの攻撃力・守備力は４００ダウンする。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地の風属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"サイバーサル・サイクロン",h:"さいばーさる・さいくろん",e:"Cybersal Cyclone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：相手フィールドのリンクモンスター１体を対象として発動できる。\nそのモンスターとリンクマーカーの数が同じモンスター１体を自分の墓地から選んで除外し、対象のモンスターを破壊する。\nこの効果で除外した自分のモンスターの元々の種族がサイバース族の場合、さらに相手の魔法＆罠ゾーンの表側表示のカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・ライトアリーナ",h:"とりっくすたー・らいとありーな",e:"Trickstar Light Arena",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分が「トリックスター」モンスターをリンク召喚した場合、その素材とした自分の墓地の「トリックスター」モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして守備表示で特殊召喚する。\n②：１ターンに１度、相手の魔法＆罠ゾーンにセットされたカード１枚を対象として発動できる。\nこのカードが存在する限り、セットされたそのカードはエンドフェイズまで発動できず、相手はエンドフェイズにそのカードを発動するか、手札に戻さなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・ブーケ",h:"とりっくすたー・ぶーけ",e:"Trickstar Bouquet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「トリックスター」モンスター１体とフィールドの表側表示モンスター１体を対象として発動できる。\nその「トリックスター」モンスターを持ち主の手札に戻し、もう１体の対象のモンスターの攻撃力はターン終了時まで、手札に戻したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼フェイスターン",h:"ごうきふぇいすたーん",e:"Gouki Face Turn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「剛鬼」カード１枚と自分の墓地の「剛鬼」モンスター１体を対象として発動できる。\n対象のフィールドのカードを破壊し、対象の墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物が導く果て",h:"せいいぶつがみちびくはて",e:"World Legacy's Corruption",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「星遺物が導く果て」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、自分フィールドの表側表示のリンクモンスターが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\n手札・デッキから「星遺物」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物を継ぐもの",h:"せいいぶつをつぐもの",e:"World Legacy Succession",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターをフィールドのリンクモンスターのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物へ誘う悪夢",h:"せいいぶつへいざなうあくむ",e:"World Legacy's Nightmare",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分の相互リンク状態のモンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n②：１ターンに１度、自分メインフェイズに以下の効果から１つを選択して発動できる。\n\n●自分フィールドの「トロイメア」モンスター１体を選び、その位置を他の自分のメインモンスターゾーンに移動する。\n\n●自分のメインモンスターゾーンの「トロイメア」モンスター２体を選び、その位置を入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"霊神の聖殿",h:"れいしんのせいでん",e:"Palace of the Elemental Lords",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターの攻撃力・守備力は、自分の墓地のモンスターの属性の種類×２００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「エレメントセイバー」モンスター１体を手札に加える。\nその後、次の自分ターンのバトルフェイズをスキップする。\n③：１ターンに１度、自分の手札・フィールドの「エレメントセイバー」モンスターが効果を発動するために手札を墓地へ送る場合、代わりにデッキの「エレメントセイバー」モンスターを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"再臨の帝王",h:"さいりんのていおう",e:"Restoration of the Monarchs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の、攻撃力２４００／守備力１０００のモンスターまたは攻撃力２８００／守備力１０００のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを効果を無効にして守備表示で特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：モンスターをアドバンス召喚する場合、装備モンスターは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[]},
  {n:"雪花の光",h:"せっかのひかり",e:"Sekka's Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地に魔法・罠カードが存在しない場合に発動できる。\n自分はデッキから２枚ドローする。\nこのカードの発動後、このデュエル中に自分は「雪花の光」以外の魔法・罠カードの効果を発動できない。\n②：墓地のこのカードを除外して発動できる。\n手札のモンスター１体を相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"バウンドリンク",h:"ばうんどりんく",e:"Link Bound",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のフィールド・墓地のリンクモンスター１体を対象として発動できる。\nそのモンスターを持ち主のEXデッキに戻し、そのリンクマーカーの数だけ、自分はデッキからドローする。\nその後、ドローした数だけ手札を選んで好きな順番でデッキの下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"にらみ合い",h:"にらみあい",e:"Staring Contest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分がEXモンスターゾーンにモンスターを特殊召喚した時、相手のメインモンスターゾーンのモンスター１体を対象として発動できる。\nその相手モンスターを、その自分のEXモンスターゾーンのモンスターと同じ縦列の相手のメインモンスターゾーンに移動する。\n②：相手がEXモンスターゾーンにモンスターを特殊召喚した時、自分のメインモンスターゾーンのモンスター１体を対象として発動できる。\nその自分のモンスターを、その相手のEXモンスターゾーンのモンスターと同じ縦列の自分のメインモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[]},
  {n:"墓穴の指名者",h:"はかあなのしめいしゃ",e:"Called by the Grave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n次のターンの終了時まで、この効果で除外したモンスター及びそのモンスターと元々のカード名が同じモンスターの効果は無効化される。",li:2,lk:[],ta:[],al:[]},
  {n:"取捨蘇生",h:"しゅしゃそせい",e:"Monster Reborn Reborn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター３体を対象として発動できる。\n相手は対象のモンスターの中から１体を選ぶ。\nそのモンスター１体を自分フィールドに特殊召喚し、残りのモンスターは全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"リミット・コード",h:"りみっと・こーど",e:"Limit Code",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードはデュエル中に１枚しか発動できない。\n①：自分の墓地にサイバース族リンクモンスターが存在する場合にこのカードを発動できる。\nそのモンスターの数だけこのカードにカウンターを置き、EXデッキから「コード・トーカー」モンスター１体を特殊召喚し、このカードを装備カード扱いとしてそのモンスターに装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：自分エンドフェイズに発動する。\nこのカードのカウンターを１つ取り除く。\n取り除けない場合このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"レッド・リブート",h:"れっど・りぶーと",e:"Red Reboot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカードはLPを半分払って手札から発動する事もできる。\n①：相手が罠カードを発動した時に発動できる。\nその発動を無効にし、そのカードをそのままセットする。\nその後、相手はデッキから罠カード１枚を選んで自身の魔法＆罠ゾーンにセットできる。\nこのカードの発動後、ターン終了時まで相手は罠カードを発動できない。",li:1,lk:[],ta:[],al:[]},
  {n:"ジェルゴンヌの終焉",h:"じぇるごんぬのしゅうえん",e:"Gergonne's End",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ティンダングル」リンクモンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは戦闘・効果では破壊されず、相手の効果の対象にならない。\n②：１ターンに１度、装備モンスターの全てのリンクマーカーの先にモンスターが存在する場合に発動できる。\nそれらのモンスターとこのカードを全て破壊する。\n全て破壊した場合、このカードを装備していたモンスターの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・エミュレルフ",h:"おるたーがいすと・えみゅれるふ",e:"Altergeist Emulatelf",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（魔法使い族・光・星４・攻１４００／守１８００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚したこのカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「オルターガイスト」罠カードは効果の対象にならず、効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物からの目醒め",h:"せいいぶつからのめざめ",e:"World Legacy Awakens",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターを素材としてリンクモンスター１体をリンク召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物への抵抗",h:"せいいぶつへのていこう",e:"World Legacy Struggle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの相互リンク状態のモンスターの数まで、フィールドの魔法・罠カードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物に響く残叫",h:"せいいぶつにひびくざんきょう",e:"World Legacy's Sorrow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに相互リンク状態のモンスターが存在し、相手がモンスターの効果・魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊神統一",h:"えれめんたる・とれーにんぐ",e:"Elemental Training",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「霊神の聖殿」は効果では破壊されず、相手の効果の対象にならない。\n②：１ターンに１度、自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターと元々の属性が異なる「エレメントセイバー」モンスター１体をデッキから特殊召喚する。\n③：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n手札を全て捨てる。\nその後、この効果で捨てたカードの数だけ、自分の墓地から「霊神」モンスターを選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"神属の堕天使",h:"しんぞくのだてんし",e:"The Sanctified Darklord",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、「堕天使」モンスター１体を墓地へ送って発動できる。\nフィールドの効果モンスター１体を選び、その効果をターン終了時まで無効にし、そのモンスターの攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"電網の落とし穴",h:"でんもうのおとしあな",e:"Network Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がデッキ・墓地からモンスターを特殊召喚した時に発動できる。\nそのモンスターを裏側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"無限泡影",h:"むげんほうよう",e:"Infinite Impermanence",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドにカードが存在しない場合、このカードの発動は手札からもできる。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nセットされていたこのカードを発動した場合、さらにこのターン、このカードと同じ縦列の他の魔法・罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"無情なはたき落とし",h:"むじょうなはたきおとし",e:"Heartless Drop Off",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドの表側表示のカードまたは墓地のカードが、効果で相手の手札に加わった時に発動できる。\n相手の手札を確認し、その中から手札に加えたカード及びその同名カードを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"豆まき",h:"まめまき",e:"Mamemaki",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルの数だけ自分の手札を選んで捨て、捨てた数だけ自分はデッキからドローする。\nその後、対象のモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"やぶ蛇",h:"やぶへび",e:"Waking the Dragon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：セットされているこのカードが相手の効果でフィールドから離れ、墓地へ送られた場合または除外された場合に発動できる。\nデッキ・EXデッキからモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ソリッドマン",h:"えれめんたるひーろー　そりっどまん",e:"Elemental HERO Solid Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「HERO」モンスター１体を特殊召喚する。\n②：このカードが魔法カードの効果でモンスターゾーンから墓地へ送られた場合、「E・HERO ソリッドマン」以外の自分の墓地の「HERO」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"煌々たる逆転の女神",h:"こうこうたるぎゃくてんのめがみ",e:"Goddess of Sweet Revenge",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:2000,tx:"①：自分フィールドにカードが存在せず、自分の手札がこのカード１枚のみの場合、相手モンスターの攻撃宣言時にこのカードを手札から捨てて発動できる。\n相手フィールドのカードを全て破壊する。\nその後、自分はデッキからモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ロード・オブ・ドラゴン－ドラゴンの統制者－",h:"ろーど・おぶ・どらごん－どらごんのとうせいしゃ－",e:"The King of D.",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"①：このカードはモンスターゾーンに存在する限り、カード名を「ロード・オブ・ドラゴン－ドラゴンの支配者－」として扱う。\n②：このカードが召喚に成功した時、手札から魔法・罠カード１枚を捨てて発動できる。\nデッキから「ドラゴンを呼ぶ笛」「ドラゴン・目覚めの旋律」「ドラゴン・復活の狂奏」のいずれか１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デストロイ・ドラゴン",h:"ですとろい・どらごん",e:"Destruction Dragon",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカードは「クリティウスの牙」の効果で自分の手札・フィールドの「破壊輪」を墓地へ送った場合のみ特殊召喚できる。\n①：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、破壊したカードがモンスターカードだった場合、その元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴン・復活の狂奏",h:"どらごん・ふっかつのきょうそう",e:"Dragon Revival Rhapsody",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに魔法使い族モンスターが存在する場合、ドラゴン族の通常モンスターを含む、自分の墓地のドラゴン族モンスターを２体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊輪廻",h:"はかいりんね",e:"Loop of Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは魔法＆罠ゾーンに存在する限り、カード名を「破壊輪」として扱う。\n②：１ターンに１度、フィールドのモンスターが効果で破壊された場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、お互いに５００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイアの使い魔",h:"う゛ぁんぱいあのつかいま",e:"Vampire Familiar",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、５００LPを払って発動できる。\nデッキから「ヴァンパイアの使い魔」以外の「ヴァンパイア」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、手札及び自分フィールドの表側表示のカードの中から、「ヴァンパイア」カード１枚を墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイアの眷属",h:"う゛ぁんぱいあのけんぞく",e:"Vampire Retainer",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、５００LPを払って発動できる。\nデッキから「ヴァンパイア」魔法・罠カード１枚を手札に加える。\n②：このカードが墓地に存在する場合、手札及び自分フィールドの表側表示のカードの中から、「ヴァンパイア」カード１枚を墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・フロイライン",h:"う゛ぁんぱいあ・ふろいらいん",e:"Vampire Fraulein",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:600,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：モンスターの攻撃宣言時に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分のアンデット族モンスターが相手モンスターと戦闘を行うダメージ計算時に１度、１００の倍数のLPを払って発動できる（最大３０００まで）。\nその自分のモンスターの攻撃力・守備力はそのダメージ計算時のみ払った数値分アップする。\n③：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時に発動できる。\nそのモンスターを墓地から可能な限り自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・グリムゾン",h:"う゛ぁんぱいあ・ぐりむぞん",e:"Vampire Grimson",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"①：自分フィールドのモンスターが戦闘または相手の効果で破壊される場合、代わりにその破壊されるモンスターの数×１０００LPを払う事ができる。\n②：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時に発動できる。\nそのモンスターを墓地から可能な限り自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・スカージレット",h:"う゛ぁんぱいあ・すかーじれっと",e:"Vampire Scarlet Scourge",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、１０００LPを払い、「ヴァンパイア・スカージレット」以外の自分の墓地の「ヴァンパイア」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n②：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時に発動できる。\nそのモンスターを墓地から可能な限り自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・レッドバロン",h:"う゛ぁんぱいあ・れっどばろん",e:"Vampire Red Baron",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：１ターンに１度、１０００LPを払い、相手フィールドのモンスター１体とこのカード以外の自分フィールドの「ヴァンパイア」モンスター１体を対象として発動できる。\nそのモンスター２体のコントロールを入れ替える。\n②：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時に発動できる。\nそのモンスターを墓地から可能な限り自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"交血鬼－ヴァンパイア・シェリダン",h:"あるだんぴーる－う゛ぁんぱいあ・しぇりだん",e:"Dhampir Vampire Sheridan",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1000,tx:"レベル６モンスター×２体以上\n元々の持ち主が相手となるモンスターをこのカードのX召喚の素材とする場合、そのレベルを６として扱う。\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nそのカードを墓地へ送る。\n②：１ターンに１度、フィールドのモンスターカードが、効果で相手の墓地へ送られた場合、または戦闘で破壊され相手の墓地へ送られた場合、このカードのX素材を１つ取り除いて発動できる。\nそのモンスター１体を自分フィールドに守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・デザイア",h:"う゛ぁんぱいあ・でざいあ",e:"Vampire's Desire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルと異なるレベルを持つ「ヴァンパイア」モンスター１体をデッキから墓地へ送る。\n対象のモンスターのレベルは、ターン終了時まで墓地へ送ったモンスターと同じになる。\n\n●自分の墓地の「ヴァンパイア」モンスター１体を対象として発動できる。\n自分フィールドのモンスター１体を選んで墓地へ送り、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイアの領域",h:"う゛ぁんぱいあのりょういき",e:"Vampire's Domain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、５００LPを払って発動できる。\nこのターン自分は通常召喚に加えて１度だけ、自分メインフェイズに「ヴァンパイア」モンスター１体を召喚できる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分の「ヴァンパイア」モンスターが相手に戦闘ダメージを与えた場合に発動する。\nその数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイア・アウェイク",h:"う゛ぁんぱいあ・あうぇいく",e:"Vampire Awakening",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「ヴァンパイア」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイアの支配",h:"う゛ぁんぱいあのしはい",e:"Vampire Domination",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「ヴァンパイア」モンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\nその後、破壊したカードがモンスターカードだった場合、自分はその元々の攻撃力分だけLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の撃手 ドンパ",h:"くうがだんのげきしゅ　どんぱ",e:"Donpa, Marksman Fur Hire",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の撃手 ドンパ」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の飛哨 リコン",h:"くうがだんのひしょう　りこん",e:"Recon, Scout Fur Hire",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の飛哨 リコン」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の舵手 ヘルマー",h:"くうがだんのだしゅ　へるまー",e:"Helmer, Helmsman Fur Hire",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の舵手 ヘルマー」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、手札から「空牙団」カード１枚を捨てて発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の剣士 ビート",h:"くうがだんのけんし　びーと",e:"Beat, Bladesman Fur Hire",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の剣士 ビート」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合に発動できる。\nデッキから「空牙団の剣士 ビート」以外の「空牙団」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の参謀 シール",h:"くうがだんのさんぼう　しーる",e:"Seal, Strategist Fur Hire",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の参謀 シール」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、自分の墓地の「空牙団」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の闘士 ブラーヴォ",h:"くうがだんのとうし　ぶらーう゛ぉ",e:"Bravo, Fighter Fur Hire",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1900,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の闘士 ブラーヴォ」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合に発動できる。\nフィールドの全ての「空牙団」モンスターの攻撃力・守備力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の孤高 サジータ",h:"くうがだんのここう　さじーた",e:"Sagitta, Maverick Fur Hire",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n「空牙団の孤高 サジータ」以外の自分フィールドの「空牙団」モンスターの種類×５００ダメージを相手に与える。\n②：このカードがモンスターゾーンに存在する限り、相手はこのカード以外の自分フィールドの「空牙団」モンスターを効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の豪傑 ダイナ",h:"くうがだんのごうけつ　だいな",e:"Dyna, Hero Fur Hire",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:1400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドの「空牙団」モンスターの種類の数まで相手の墓地のカードを選んで除外する。\n②：このカードがモンスターゾーンに存在する限り、相手は他の「空牙団」モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の叡智 ウィズ",h:"くうがだんのえいち　うぃず",e:"Wiz, Sage Fur Hire",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分は「空牙団の叡智 ウィズ」以外の自分フィールドの「空牙団」モンスターの種類×５００LP回復する。\n②：相手が魔法・罠カードの効果を発動した時、手札から「空牙団」カード１枚を捨てて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の英雄 ラファール",h:"くうがだんのえいゆう　らふぁーる",e:"Rafale, Champion Fur Hire",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n「空牙団の英雄 ラファール」以外の自分フィールドの「空牙団」モンスターの種類の数だけ自分のデッキの上からカードをめくる。\nその中のカード１枚を選んで手札に加え、残りのカードはデッキに戻す。\n②：相手がモンスターの効果を発動した時、手札から「空牙団」カード１枚を捨てて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"飛竜艇－ファンドラ",h:"ひりゅうてい－ふぁんどら",e:"Fandora, the Flying Furtress",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分ドローフェイズのドロー前に発動できる。\nこのターン通常のドローを行う代わりに、デッキから「空牙団」モンスター１体を手札に加える。\n②：自分フィールドに「空牙団」モンスターが５種類以上存在する場合、フィールドゾーンのこのカードを墓地へ送って発動できる。\n相手フィールドのカードを全て破壊する。\nこの効果の発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"烈風の空牙団",h:"れっぷうのくうがだん",e:"Mayhem Fur Hire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「空牙団」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の修練",h:"くうがだんのしゅうれん",e:"Training Fur Hire, Fur All Your Training Needs",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「空牙団」モンスターが、戦闘または相手の効果で破壊された場合、そのモンスター１体を対象として発動できる。\nデッキからそのモンスターの元々のレベルより低いレベルを持つ「空牙団」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀姫－カガリ",h:"せんとうき－かがり",e:"Sky Striker Ace - Kagari",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"炎属性以外の「閃刀姫」モンスター１体\n自分は「閃刀姫－カガリ」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地の「閃刀」魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードの攻撃力は自分の墓地の魔法カードの数×１００アップする。",li:1,lk:["左上"],ta:[],al:[]},
  {n:"閃刀姫－シズク",h:"せんとうき－しずく",e:"Sky Striker Ace - Shizuku",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"水属性以外の「閃刀姫」モンスター１体\n自分は「閃刀姫－シズク」を１ターンに１度しか特殊召喚できない。\n①：相手フィールドのモンスターの攻撃力・守備力は、自分の墓地の魔法カードの数×１００ダウンする。\n②：このカードを特殊召喚したターンのエンドフェイズに発動できる。\nデッキから、同名カードが自分の墓地に存在しない「閃刀」魔法カード１枚を手札に加える。",li:"",lk:["右上"],ta:[],al:[]},
  {n:"閃刀姫－レイ",h:"せんとうき－れい",e:"Sky Striker Ace - Raye",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nEXデッキから「閃刀姫」モンスター１体をEXモンスターゾーンに特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の「閃刀姫」リンクモンスターが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀起動－エンゲージ",h:"せんとうきどう－えんげーじ",e:"Sky Striker Mobilize - Engage!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合に発動できる。\nデッキから「閃刀起動－エンゲージ」以外の「閃刀」カード１枚を手札に加える。\nその後、自分の墓地に魔法カードが３枚以上存在する場合、自分はデッキから１枚ドローできる。",li:2,lk:[],ta:[],al:[]},
  {n:"閃刀術式－アフターバーナー",h:"せんとうじゅつしき－あふたーばーなー",e:"Sky Striker Maneuver - Afterburners!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nその後、自分の墓地に魔法カードが３枚以上存在する場合、フィールドの魔法・罠カード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀術式－ジャミングウェーブ",h:"せんとうじゅつしき－じゃみんぐうぇーぶ",e:"Sky Striker Maneuver - Jamming Waves!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、自分の墓地に魔法カードが３枚以上存在する場合、フィールドのモンスター１体を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀機－ホーネットビット",h:"せんとうき－ほーねっとびっと",e:"Sky Striker Mecha - Hornet Drones",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合に発動できる。\n自分フィールドに「閃刀姫トークン」（戦士族・闇・星１・攻／守０）１体を守備表示で特殊召喚する。\nこのトークンはリリースできない。\n自分の墓地に魔法カードが３枚以上存在する場合、そのトークンの攻撃力・守備力は１５００になる。",li:1,lk:[],ta:[],al:[]},
  {n:"閃刀機－ウィドウアンカー",h:"せんとうき－うぃどうあんかー",e:"Sky Striker Mecha - Widow Anchor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nその後、自分の墓地に魔法カードが３枚以上存在する場合、そのモンスターのコントロールをエンドフェイズまで得る事ができる。",li:2,lk:[],ta:[],al:[]},
  {n:"閃刀機－イーグルブースター",h:"せんとうき－いーぐるぶーすたー",e:"Sky Striker Mecha - Eagle Booster",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターは自身以外のカードの効果を受けない。\n自分の墓地に魔法カードが３枚以上存在する場合、さらにこのターン、そのモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀機－シャークキャノン",h:"せんとうき－しゃーくきゃのん",e:"Sky Striker Mecha - Shark Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n自分の墓地に魔法カードが３枚以上存在する場合、除外せずにそのモンスターを自分フィールドに特殊召喚できる。\nこの効果で特殊召喚したモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀機構－ハーキュリーベース",h:"せんとうきこう－はーきゅりーべーす",e:"Sky Striker Mecharmory - Hercules Base",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分のメインモンスターゾーンにモンスターが存在しない場合にこのカードを発動できる。\n①：装備モンスターは直接攻撃できず、１度のバトルフェイズ中にモンスターに２回攻撃できる。\n②：自分の墓地に魔法カードが３枚以上存在し、装備モンスターが攻撃でモンスターを破壊した場合に発動する。\n自分はデッキから１枚ドローする。\n③：このカードが効果でフィールドから墓地へ送られた場合、「閃刀機構－ハーキュリーベース」以外の自分の墓地の「閃刀」カードを３枚まで対象として発動できる。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀機関－マルチロール",h:"せんとうきかん－まるちろーる",e:"Sky Striker Mecha Modules - Multirole",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、このカード以外の自分フィールドのカード１枚を対象として発動できる。\nこのターン、自分の魔法カードの発動に対して相手は魔法・罠・モンスターの効果を発動できない。\nさらに、対象のカードは墓地へ送られる。\n②：自分・相手のエンドフェイズに発動できる。\nこのターン、このカードが表側表示で存在する間に自分が発動した「閃刀」魔法カードの数まで自分の墓地の「閃刀」魔法カードを選び、自分フィールドにセットする（同名カードは１枚まで）。\nこの効果でセットしたカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀空域－エリアゼロ",h:"せんとうくういき－えりあぜろ",e:"Sky Striker Airspace - Area Zero",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドのカード１枚を対象として発動できる。\n自分のデッキの上からカードを３枚めくる。\nその中から「閃刀」カード１枚を選んで手札に加える事ができる。\n残りのカードはデッキに戻す。\n「閃刀」カードがめくられた場合、さらに対象のカードを墓地へ送る。\n②：このカードが効果でフィールドゾーンから墓地へ送られた場合に発動できる。\nデッキから「閃刀姫」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔嬢リリス",h:"あくまじょうりりす",e:"Lilith, Lady of Lament",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：召喚したこのカードの元々の攻撃力は１０００になる。\n②：自分フィールドの闇属性モンスター１体をリリースして発動できる。\nデッキから通常罠カード３枚を相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分フィールドにセットし、残りのカードはデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"闇黒の魔王ディアボロス",h:"あんこくのまおうでぃあぼろす",e:"Darkest Diabolos, Lord of the Lair",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドの闇属性モンスターがリリースされた場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手はこのカードをリリースできず、効果の対象にもできない。\n③：自分フィールドの闇属性モンスター１体をリリースして発動できる。\n相手は手札を１枚選んでデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"悪王アフリマ",h:"あくおうあふりま",e:"Ahrima, the Wicked Warden",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「闇黒世界－シャドウ・ディストピア－」１枚を手札に加える。\n②：自分フィールドの闇属性モンスター１体をリリースして発動できる。\n自分はデッキから１枚ドローする。\nこの効果を発動するためにこのカード以外の闇属性モンスターをリリースした場合、ドローする代わりにデッキから守備力２０００以上の闇属性モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"影王デュークシェード",h:"かげおうでゅーくしぇーど",e:"Duke Shade, the Sinister Shadow Lord",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分は闇属性モンスターしか特殊召喚できない。\n①：自分フィールドの闇属性モンスターを任意の数だけリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力はリリースしたモンスターの数×５００アップする。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地のレベル５以上の闇属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"闇黒世界－シャドウ・ディストピア－",h:"あんこくせかい－しゃどう・でぃすとぴあ－",e:"Lair of Darkness",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスターは闇属性になる。\n②：１ターンに１度、自分がカードの効果を発動するために自分フィールドのモンスターをリリースする場合、自分フィールドのモンスター１体の代わりに相手フィールドの闇属性モンスター１体をリリースできる。\n③：自分・相手のエンドフェイズに発動する。\nこのターンにこのカードが表側表示で存在する状態でリリースされたモンスターの数まで、ターンプレイヤーのフィールドに「シャドウトークン」（悪魔族・闇・星３・攻／守１０００）を可能な限り守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"悪のデッキ破壊ウイルス",h:"あくのでっきはかいういるす",e:"Grinning Grave Virus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力３０００以下の闇属性モンスター１体をリリースして発動できる。\nそのモンスターの攻撃力５００につき１枚、相手は自身の手札・デッキからカードを選んで破壊する。\nこのカードを発動するために攻撃力２０００以上のモンスターをリリースした場合、相手ターンで数えて３ターンの間に相手がドローしたカードを全て確認し、その内のモンスターを全て破壊する。\nまた、このカードの効果で破壊され墓地へ送られているカードは、破壊されたターンには効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魔導の守護者",h:"りゅうまどうのしゅごしゃ",e:"Keeper of Dragon Magic",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分は融合モンスターしかEXデッキから特殊召喚できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札を１枚捨てて発動できる。\nデッキから「融合」通常魔法カードまたは「フュージョン」通常魔法カード１枚を手札に加える。\n②：EXデッキの融合モンスター１体を相手に見せて発動できる。\nそのモンスターにカード名が記された融合素材モンスター１体を自分の墓地から選んで裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クインテット・マジシャン",h:"くいんてっと・まじしゃん",e:"Quintet Magician",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:4500,tx:"魔法使い族モンスター×５\nこのカードは融合召喚でしか特殊召喚できない。\n①：魔法使い族モンスター５種類を素材としてこのカードが融合召喚に成功した場合に発動できる。\n相手フィールドのカードを全て破壊する。\n②：このカードはモンスターゾーンに存在する限り、リリースできず、融合素材にできず、効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"円融魔術",h:"まじからいず・ふゅーじょん",e:"Magicalized Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のフィールド・墓地から、魔法使い族の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライドロン",h:"らいどろん",e:"Leotron",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"高い適合能力を持った電子獣。\n縄張り意識が強い。",li:"",lk:[],ta:[],al:[]},
  {n:"テクスチェンジャー",h:"てくすちぇんじゃー",e:"Texchanger",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：１ターンに１度、自分のモンスターが攻撃対象に選択された時に発動できる。\nその攻撃を無効にする。\nその後、自分の手札・デッキ・墓地からサイバース族の通常モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィジェット・キッド",h:"うぃじぇっと・きっど",e:"Widget Kid",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札からサイバース族モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・ホワイトハット",h:"さいばーす・ほわいとはっと",e:"Cyberse White Hat",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:2400,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに同じ種族のモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがリンク素材として墓地へ送られた場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・リカバー",h:"さいばねっと・りかばー",e:"Cynet Recovery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのリンクモンスターが戦闘または相手の効果で破壊された場合、リンクモンスター以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・リグレッション",h:"さいばねっと・りぐれっしょん",e:"Cynet Regression",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分がリンクモンスターの特殊召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トランスコード・トーカー",h:"とらんすこーど・とーかー",e:"Transcode Talker",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"効果モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが相互リンク状態の場合、このカード及びこのカードの相互リンク先のモンスターの攻撃力は５００アップし、相手の効果の対象にならない。\n②：「トランスコード・トーカー」以外の自分の墓地のリンク３以下のサイバース族リンクモンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\nこの効果を発動するターン、自分はサイバース族モンスターしか特殊召喚できない。",li:"",lk:["上","右","下"],ta:[],al:[]},
  {n:"ペンテスタッグ",h:"ぺんてすたっぐ",e:"Pentestag",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"効果モンスター２体\n①：このカードがモンスターゾーンに存在する限り、リンク状態の自分のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"EMクレイブレイカー",h:"えんためいとくれいぶれいかー",e:"Performapal Clay Breaker",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：アドバンス召喚したこのカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその相手モンスターの攻撃力はターン終了時まで、自分のEXデッキの表側表示のPモンスターの数×５００ダウンする。\n②：このカードが墓地に存在し、自分が２体以上のモンスターを同時にP召喚した時に発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・エンジェル",h:"ふぁーにまる・えんじぇる",e:"Fluffal Angel",t:"monster",a:"光",l:2,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分の墓地の、「ファーニマル」モンスターまたは「エッジインプ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は融合モンスターしかEXデッキから特殊召喚できない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:600,de:1200,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、自分の墓地の「デストーイ」融合モンスター１体を対象として発動できる。\n手札を１枚選んで捨て、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ケンドウ魂 KAI－DEN",h:"けんどうすぴりっと　かいでん",e:"Kai-Den Kendo Spirit",t:"monster",a:"風",l:5,ps:9,pe:"①：フィールドにモンスターがP召喚された場合に発動する。\nPゾーンのこのカードを持ち主の手札に戻す。",tr:"戦士族",mo:["ペンデュラム","スピリット","効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のPゾーンのカード１枚を選び、そのカードと同じ縦列の相手のカードを全て墓地へ送る。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"一族の結集",h:"いちぞくのけっしゅう",e:"Super Team Buddy Force Unite!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターと元々のカード名が異なり、元々の種族が同じモンスター１体を自分の手札・墓地から選んで特殊召喚する。\n②：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「一族の結集」１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"リヴェンデット・エグゼクター",h:"りう゛ぇんでっと・えぐぜくたー",e:"Revendread Executor",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「リヴェンデット・スレイヤー」として扱う。\n②：儀式召喚したこのカードがモンスターゾーンに存在する限り、相手は他の自分フィールドのカードを効果の対象にできない。\n③：儀式召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「ヴェンデット」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・アニマ",h:"う゛ぇんでっと・あにま",e:"Vendread Anima",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：墓地のこのカードを除外し、「ヴェンデット・アニマ」以外の除外されている自分の「ヴェンデット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●このカードが戦闘で破壊したモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"リヴェンデット・バース",h:"りう゛ぇんでっと・ばーす",e:"Revendread Evolution",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ヴェンデット」儀式モンスターの降臨に必要。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりにデッキから「ヴェンデット」モンスター（１体まで）を墓地へ送り、自分の手札・墓地から「ヴェンデット」儀式モンスター１体を儀式召喚する。\nこの効果で儀式召喚したモンスターは、次のターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・ナイトメア",h:"う゛ぇんでっと・ないとめあ",e:"Vendread Nightmare",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分の手札・フィールドから「ヴェンデット」モンスターを任意の数だけリリースし、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルはターン終了時までリリースしたモンスターの数だけ上がる。\n②：自分の「ヴェンデット」儀式モンスターの攻撃で相手モンスターを破壊した時に発動できる。\nその自分のモンスターの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴェンデット・デイブレイク",h:"う゛ぇんでっと・でいぶれいく",e:"Vendread Daybreak",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドのカードの数が自分フィールドのカードより多い場合に発動できる。\n自分フィールドの儀式召喚した「ヴェンデット」モンスター１体を選び、そのモンスター以外のフィールドのカードを全て破壊する。\nその「ヴェンデット」モンスターはフィールドに表側表示で存在する限り、直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ダークネスマスター",h:"ふぉーみゅらあすりーとだーくねすますたー",e:"F.A. Dark Dragster",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは通常召喚できない。\nフィールドにレベル７以上の「F.A.」モンスターが存在し、自分のモンスターゾーンに「F.A.ダークネスマスター」が存在しない場合に特殊召喚できる。\n①：このカードの攻撃力はこのカードのレベル×３００アップする。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：１ターンに１度、フィールドのカード１枚を対象として発動できる。\nこのカードのレベルを３つ下げ、そのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ライトニングマスター",h:"ふぉーみゅらあすりーとらいとにんぐますたー",e:"F.A. Dawn Dragster",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードの攻撃力はこのカードのレベル×３００アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n④：１ターンに１度、相手が魔法・罠カードの効果を発動した時に発動できる。\nこのカードのレベルを２つ下げ、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.ウィナーズ",h:"ふぉーみゅらあすりーとうぃなーず",e:"F.A. Winners",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「F.A.」モンスターが存在する場合、このカードは相手の効果では破壊されない。\n②：元々のレベルより５以上高いレベルを持つ自分の「F.A.」モンスターが相手モンスターとの戦闘で相手に戦闘ダメージを与えた時に発動できる。\n自分の手札・フィールド・墓地のカード１枚を選んで除外する。\n「F.A.ウィナーズ」の効果で除外された自分の「F.A.」フィールド魔法が３種類揃った時、自分はデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.デッド・ヒート",h:"ふぉーみゅらあすりーとでっど・ひーと",e:"F.A. Dead Heat",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手モンスターの直接攻撃宣言時に発動できる。\nデッキから「F.A.」モンスター１体を特殊召喚する。\n②：自分の「F.A.」モンスターが相手モンスターと戦闘を行うダメージ計算前に１度、発動できる。\nお互いにサイコロを１回ずつ振る。\n自分の出た目が相手よりも大きい場合、その戦闘を行う自分のモンスターのレベルをターン終了時まで４つ上げる。\n自分の出た目が相手より小さい場合、その自分のモンスターを破壊する。\n出た目が同じ場合、サイコロを振り直す。",li:"",lk:[],ta:[],al:[]},
  {n:"F.A.オーバー・ヒート",h:"ふぉーみゅらあすりーとおーばー・ひーと",e:"F.A. Overheat",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドにのみモンスターが存在する場合に発動できる。\n手札から「F.A.」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルはターン終了時まで３つ上がる。\n②：自分のフィールドゾーンにカードが存在しない場合、墓地のこのカードを除外して発動できる。\n自分の手札・墓地から「F.A.」フィールド魔法カード１枚を選んで発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"シューティング・ライザー・ドラゴン",h:"しゅーてぃんぐ・らいざー・どらごん",e:"Shooting Riser Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2100,de:1700,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nフィールドのこのカードより低いレベルを持つモンスター１体をデッキから墓地へ送り、そのモンスターのレベル分だけこのカードのレベルを下げる。\nこのターン、自分は墓地へ送ったそのモンスター及びその同名モンスターのモンスター効果を発動できない。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの降臨",h:"でーもんのこうりん",e:"Archfiend's Awakening",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1200,tx:"「奈落との契約」により降臨。\n①：このカードはモンスターゾーンに存在する限り、カード名を「デーモンの召喚」として扱う。\n②：フィールドのこのカードは、儀式モンスター以外のモンスターとの戦闘では破壊されず、儀式モンスター以外のモンスターの効果では破壊されない。\n③：儀式召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「デーモンの召喚」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SIMMタブラス",h:"しむたぶらす",e:"SIMM Tablir",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せ、自分の墓地のサイバース族・レベル４モンスター１体を対象として発動できる。\nリンク状態の自分のリンクモンスターのリンク先となる自分フィールドにこのカードを手札から特殊召喚し、対象のモンスターを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"クラスター・コンジェスター",h:"くらすたー・こんじぇすたー",e:"Cluster Congester",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:2200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにリンクモンスターが存在せず、このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分フィールドに「コンジェスタートークン」（サイバース族・闇・星１・攻／守０）１体を特殊召喚する。\n②：自分のリンクモンスターが攻撃されたバトルステップに１度、そのリンクモンスターと墓地のこのカードを除外して発動できる。\n相手フィールドのリンクモンスターの数まで、自分フィールドに「コンジェスタートークン」を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ムーンサルト",h:"ごうきむーんさると",e:"Gouki Moonsault",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せ、「剛鬼ムーンサルト」以外の自分フィールドの「剛鬼」モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターを持ち主の手札に戻す。\n②：自分の墓地の「剛鬼」リンクモンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。\nその後、自分の墓地から「剛鬼」モンスター１体を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ツープラトン",h:"ごうきつーぷらとん",e:"Gouki Tagpartner",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"①：このカードが「剛鬼」リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\nその「剛鬼」リンクモンスターの攻撃力は、ターン終了時まで１０００アップする。\n②：墓地のこのカードを除外し、自分の墓地の「剛鬼」魔法カード１枚を対象として発動できる。\nそのカードをデッキに戻す。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ヘルトレーナー",h:"ごうきへるとれーなー",e:"Gouki Ringtrainer",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、手札のこのカードを相手に見せ、自分の墓地の「剛鬼」リンクモンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚する。\nその後、対象のモンスターを特殊召喚する。\nこの効果で特殊召喚したリンクモンスターは攻撃力が５００ダウンする。\n②：自分フィールドに「剛鬼」リンクモンスターが存在する限り、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・フィーア",h:"さいばー・どらごん・ふぃーあ",e:"Cyber Dragon Vier",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：自分が「サイバー・ドラゴン」の召喚・特殊召喚に成功した場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n③：このカードがモンスターゾーンに存在する限り、自分フィールドの全ての「サイバー・ドラゴン」の攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・ヘルツ",h:"さいばー・どらごん・へるつ",e:"Cyber Dragon Herz",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：このカードが特殊召喚に成功した場合に発動できる。\nこのカードのレベルをターン終了時まで５にする。\nこの効果の発動後、ターン終了時まで自分は機械族モンスターしか特殊召喚できない。\n③：このカードが墓地へ送られた場合に発動できる。\n自分のデッキ・墓地からこのカード以外の「サイバー・ドラゴン」１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－セナート",h:"どらぐにてぃ－せなーと",e:"Dragunity Senatus",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札から「ドラグニティ」カード１枚を捨てて発動できる。\nデッキから「ドラグニティ」チューナー１体を選び、装備カード扱いとしてこのカードに装備する。\nこの効果の発動後、ターン終了時まで自分はドラゴン族モンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドの「ドラグニティ」カードが戦闘・効果で破壊される場合、代わりにこのカードに装備された「ドラグニティ」カード１枚を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－クーゼ",h:"どらぐにてぃ－くーぜ",e:"Dragunity Couse",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:200,tx:"このカードをS素材とする場合、「ドラグニティ」モンスターのS召喚にしか使用できない。\n①：フィールドのこのカードをS素材とする場合、このカードのレベルを４として扱う事ができる。\n②：このカードが装備カード扱いとして装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔境のパラディオン",h:"まきょうのぱらでぃおん",e:"Crusadia Reclusia",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードはリンクモンスターのリンク先となる自分フィールドに手札から守備表示で特殊召喚できる。\n②：このカードがリンクモンスターのリンク先への召喚・特殊召喚に成功した場合、自分フィールドの「パラディオン」カード１枚と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神樹のパラディオン",h:"しんじゅのぱらでぃおん",e:"Crusadia Arboria",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1800,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードはリンクモンスターのリンク先となる自分フィールドに手札から守備表示で特殊召喚できる。\n②：自分フィールドの「パラディオン」モンスターが戦闘・効果で破壊される場合、代わりにフィールド・墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"百獣のパラディオン",h:"ひゃくじゅうのぱらでぃおん",e:"Crusadia Leonis",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードはリンクモンスターのリンク先となる自分フィールドに手札から守備表示で特殊召喚できる。\n②：自分フィールドの「パラディオン」リンクモンスター１体を対象として発動できる。\nこのターン、そのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"星辰のパラディオン",h:"せいしんのぱらでぃおん",e:"Crusadia Draco",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:600,de:2000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードはリンクモンスターのリンク先となる自分フィールドに手札から守備表示で特殊召喚できる。\n②：このカードがリンクモンスターのリンク先への召喚・特殊召喚に成功した場合、「星辰のパラディオン」以外の自分の墓地の「パラディオン」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天穹のパラディオン",h:"てんきゅうのぱらでぃおん",e:"Crusadia Maximus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードはリンクモンスターのリンク先となる自分フィールドに手札から守備表示で特殊召喚できる。\n②：自分フィールドの「パラディオン」リンクモンスター１体を対象として発動できる。\nこのターン、自分はそのモンスターでしか攻撃できず、そのモンスターが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物－『星冠』",h:"せいいぶつ－『せいかん』",e:"World Legacy - \"World Crown\"",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、③の効果は１ターンに１度しか使用できない。\n①：このカードはリンクモンスターのリンク先となる自分フィールドに手札から守備表示で特殊召喚できる。\n②：EXデッキから特殊召喚されたフィールドのモンスターが効果を発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。\n③：通常召喚したこのカードがリリースされた場合に発動できる。\nデッキから「星遺物」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀－キャンドール",h:"でびりちゃる－きゃんどーる",e:"Impcantation Candoll",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札の儀式魔法カード１枚を相手に見せて発動できる。\n「魔神儀－キャンドール」以外のデッキの「魔神儀」モンスター１体と手札のこのカードを特殊召喚する。\n②：このカードがデッキからの特殊召喚に成功した場合に発動できる。\nデッキから儀式魔法カード１枚を手札に加える。\n③：このカードがモンスターゾーンに存在する限り、自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀－タリスマンドラ",h:"でびりちゃる－たりすまんどら",e:"Impcantation Talismandra",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札の儀式モンスター１体を相手に見せて発動できる。\n「魔神儀－タリスマンドラ」以外のデッキの「魔神儀」モンスター１体と手札のこのカードを特殊召喚する。\n②：このカードがデッキからの特殊召喚に成功した場合に発動できる。\nデッキから儀式モンスター１体を手札に加える。\n③：このカードがモンスターゾーンに存在する限り、自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・デコイドラゴン",h:"めたふぁいず・でこいどらごん",e:"Metaphys Decoy Dragon",t:"monster",a:"光",l:2,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分のモンスターが攻撃対象に選択された時、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「メタファイズ」モンスター１体を対象として発動できる。\nこのカードを除外し、対象のモンスターを攻撃表示で特殊召喚する。",tr:"幻竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の①②のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のモンスターが攻撃対象に選択された時、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「メタファイズ」モンスター１体を対象として発動できる。\nフィールドのこのカードを除外し、対象のモンスターを攻撃表示で特殊召喚する。\n②：このカードが除外された次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇霊神オブルミラージュ",h:"やみれいしんおぶるみらーじゅ",e:"Umbramirage the Elemental Lord",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の闇属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\nデッキから攻撃力１５００以下のモンスター１体を手札に加える。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コスモブレイン",h:"こすもぶれいん",e:"Cosmo Brain",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1500,de:2450,tx:"このカードは通常召喚できない。\n手札及び自分フィールドの表側表示モンスターの中から、効果モンスター以外のモンスター１体を墓地へ送った場合に特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードを特殊召喚するために墓地へ送ったモンスターのレベル×２００アップする。\n②：自分フィールドの効果モンスター１体をリリースして発動できる。\n手札・デッキから通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔晶龍ジルドラス",h:"ましょうりゅうじるどらす",e:"Mana Dragon Zirnitron",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドの魔法・罠カードが相手の効果でフィールドから離れ、墓地へ送られた場合または除外された場合に発動できる。\nこのカードを特殊召喚する。\nその後、自分の墓地のカード及び除外されている自分のカードの中から、魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンにセットできる。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神童",h:"まじんどう",e:"Terrifying Toddler of Torment",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・デッキから墓地へ送られた場合に発動できる。\nこのカードを裏側守備表示で特殊召喚する。\n②：このカードがリバースした場合に発動できる。\nデッキから悪魔族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・エース",h:"さいこ・えーす",e:"Psychic Ace",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"①：モンスターゾーンのこのカードがリリースされた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイ・キューピット",h:"はい・きゅーぴっと",e:"Cupid Volley",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のデッキの上からカードを３枚まで墓地へ送って発動できる。\nこのカードのレベルをターン終了時まで、この効果を発動するために墓地へ送ったカードの数だけ上げる。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n自分は１５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"寝ガエル",h:"ねがえる",e:"Centerfrog",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカードは融合・S・X・リンク召喚の素材にできない。\n①：このカードが召喚・反転召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：１ターンに１度、相手のメインモンスターゾーンのモンスター１体を対象として発動できる。\n守備表示のこのカードを対象の相手モンスターの隣のモンスターゾーンへコントロールを移す。\nその後、相手のメインモンスターゾーンの「寝ガエル」が２体のみの場合、その２体の間に存在する全てのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・マジシャン",h:"さいばーす・まじしゃん",e:"Cyberse Magician",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"サイバース族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「サイバネット・リチューアル」により降臨。\n①：このカードがモンスターゾーンに存在する限り、自分が受ける全てのダメージは半分になる。\n②：自分フィールドにリンクモンスターが存在する限り、相手は自分フィールドの他のモンスターを、攻撃対象に選択できず、効果の対象にもできない。\n③：このカードがリンクモンスターと戦闘を行うダメージ計算時のみこのカードの攻撃力は１０００アップする。\n④：このカードが相手の効果で破壊された場合に発動できる。\nデッキからサイバース族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅の天使ルイン",h:"はめつのてんしるいん",e:"Ruin, Angel of Oblivion",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1700,de:1000,tx:"「エンドレス・オブ・ザ・ワールド」により降臨。\n①：このカードのカード名は、手札・フィールドに存在する限り「破滅の女神ルイン」として扱う。\n②：このカードが儀式召喚に成功した場合に発動する。\nこのターン、このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードが墓地へ送られた場合、自分フィールドの儀式モンスター１体を対象として発動できる。\nそのモンスターが自分フィールドに表側表示で存在する限り、自分の儀式モンスターの攻撃宣言時に相手はカードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉の悪魔デミス",h:"しゅうえんのあくまでみす",e:"Demise, Agent of Armageddon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:1800,de:1000,tx:"「エンドレス・オブ・ザ・ワールド」により降臨。\n①：このカードのカード名は、手札・フィールドに存在する限り「終焉の王デミス」として扱う。\n②：このカードが儀式召喚に成功した場合に発動する。\nフィールドの表側表示モンスター１体を選んで破壊する。\n③：このカードが墓地へ送られた場合、自分フィールドの儀式モンスター１体を対象として発動できる。\nそのモンスターが自分フィールドに表側表示で存在する限り、自分の儀式モンスターの効果の発動に対して相手はカードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"破滅の美神ルイン",h:"はめつのびしんるいん",e:"Ruin, Supreme Queen of Oblivion",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2900,de:3000,tx:"「エンドレス・オブ・ザ・ワールド」により降臨。\n①：このカードのカード名は、手札・フィールドに存在する限り「破滅の女神ルイン」として扱う。\n②：儀式召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの儀式モンスターは効果では破壊されない。\n③：儀式モンスターのみを使用して儀式召喚したこのカードは１度のバトルフェイズ中に２回攻撃できる。\n④：このカードが戦闘で相手モンスターを破壊した場合に発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉の覇王デミス",h:"しゅうえんのはおうでみす",e:"Demise, Supreme King of Armageddon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:3000,tx:"「エンドレス・オブ・ザ・ワールド」により降臨。\n①：このカードのカード名は、手札・フィールドに存在する限り「終焉の王デミス」として扱う。\n②：儀式召喚したこのカードがモンスターゾーンに存在する限り、自分の儀式モンスターは戦闘では破壊されない。\n③：儀式モンスターのみを使用して儀式召喚したこのカードの効果を発動するために払うLPは必要なくなる。\n④：１ターンに１度、２０００LPを払って発動できる。\nフィールドの他のカードを全て破壊し、破壊した相手フィールドのカードの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"嵐竜の聖騎士",h:"ないと・おぶ・すとーむどらごん",e:"Paladin of Storm Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:900,tx:"「サイバネット・リチューアル」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがモンスターに攻撃するダメージステップ開始時に発動できる。\nそのモンスターを持ち主の手札に戻す。\n②：このカードをリリースして発動できる。\n手札・デッキからレベル５以上のサイバース族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－ハールーン",h:"どらぐにてぃないと－はーるーん",e:"Dragunity Knight - Luin",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1200,de:1900,tx:"「ドラグニティ」チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地の「ドラグニティ」モンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードが墓地へ送られた場合、自分フィールドの「ドラグニティ」モンスター１体を対象として発動できる。\nこのカードを攻撃力・守備力１０００アップの装備カード扱いとして、対象の自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－アスカロン",h:"どらぐにてぃないと－あすかろん",e:"Dragunity Knight - Ascalon",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3200,tx:"「ドラグニティ」チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の墓地から「ドラグニティ」モンスター１体を除外し、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n②：S召喚したこのカードが相手によって破壊された場合に発動できる。\nEXデッキから攻撃力３０００以下の「ドラグニティ」Sモンスター１体をS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレルソード・ドラゴン",h:"う゛ぁれるそーど・どらごん",e:"Borrelsword Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター３体以上\n①：このカードは戦闘では破壊されない。\n②：１ターンに１度、攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にする。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃できる。\nこの効果の発動に対して相手は効果を発動できない。\nこの効果は相手ターンでも発動できる。\n③：１ターンに１度、このカードが表側表示モンスターに攻撃宣言した時に発動できる。\nターン終了時まで、このカードの攻撃力はそのモンスターの攻撃力の半分アップし、そのモンスターの攻撃力は半分になる。",li:"",lk:["上","左","左下","下"],ta:[],al:[]},
  {n:"サイバース・ウィッチ",h:"さいばーす・うぃっち",e:"Cyberse Witch",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"サイバース族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのリンク先にモンスターが特殊召喚された場合、自分の墓地の魔法カード１枚を除外して発動できる。\nデッキからサイバース族の儀式モンスター１体と「サイバネット・リチューアル」１枚を手札に加える。\n②：このカードの①の効果を発動したターンの自分メインフェイズに、自分の墓地のレベル４以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:["左下","下"],ta:[],al:[]},
  {n:"リンク・ディヴォーティー",h:"りんく・でぃう゛ぉーてぃー",e:"Link Devotee",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:500,de:"-",tx:"レベル４以下のサイバース族モンスター１体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\nこのターン、自分はリンク３以上のリンクモンスターをリンク召喚できない。\n②：相互リンク状態のこのカードがリリースされた場合に発動できる。\n自分フィールドに「リンクトークン」（サイバース族・光・星１・攻／守０）２体を特殊召喚する。",li:"",lk:["上"],ta:[],al:[]},
  {n:"レストレーション・ポイントガード",h:"れすとれーしょん・ぽいんとがーど",e:"Restoration Point Guard",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"サイバース族モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、このカード以外のリンクモンスターがリンク召喚に成功した場合に発動する。\nこのターン、このカードは戦闘・効果では破壊されない。\n②：このカードが墓地に存在し、このカードを素材としてリンク召喚したリンクモンスターが相手の効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:["左","左下"],ta:[],al:[]},
  {n:"剛鬼ジャドウ・オーガ",h:"ごうきじゃどう・おーが",e:"Gouki Heel Ogre",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「剛鬼」モンスター２体\n①：１ターンに１度、このカードのリンク先のモンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\nその後、自分の墓地から「剛鬼ジャドウ・オーガ」以外の「剛鬼」モンスター１体を選んで特殊召喚できる。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"剛鬼ザ・ジャイアント・オーガ",h:"ごうきざ・じゃいあんと・おーが",e:"Gouki The Giant Ogre",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"「剛鬼」モンスター３体以上\n①：フィールドのこのカードは、戦闘では破壊されず、このカードの攻撃力以下の攻撃力を持つ相手モンスターの発動した効果を受けない。\n②：フィールドのこのカードまたはこのカードのリンク先のモンスターを対象とする相手の効果が発動した時に発動できる。\nこのカードの攻撃力を５００ダウンし、その発動を無効にする。\n③：１ターンに１度、このカードの攻撃力が元々の攻撃力と異なる場合に発動できる。\nこのカードの攻撃力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:["左","右","左下","右下"],ta:[],al:[]},
  {n:"ショートヴァレル・ドラゴン",h:"しょーとう゛ぁれる・どらごん",e:"Miniborrel Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"「ヴァレット」モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドに「ヴァレル」リンクモンスターが存在する場合、自分フィールドのリンク３以下のモンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、リリースしたモンスターとリンクマーカーの数が同じモンスターのリンク素材にできない。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"マガジンドラムゴン",h:"まがじんどらむごん",e:"Vorticular Drumgon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"ドラゴン族・闇属性モンスター３体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：このカードの①の効果を適用したターンにこのカードがモンスターゾーンに存在する限り、このカードのリンク先となる使用していないモンスターゾーンは使用できない。",li:"",lk:["左","右","下"],ta:[],al:[]},
  {n:"サイバー・ドラゴン・ズィーガー",h:"さいばー・どらごん・ずぃーがー",e:"Cyber Dragon Sieger",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2100,de:"-",tx:"「サイバー・ドラゴン」を含む機械族モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：このカードが攻撃宣言をしていない自分・相手のバトルフェイズに、自分フィールドの攻撃力２１００以上の機械族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで２１００アップする。\nこの効果の発動後、ターン終了時までこのカードの戦闘によるお互いの戦闘ダメージは０になる。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"マギアス・パラディオン",h:"まぎあす・ぱらでぃおん",e:"Crusadia Magius",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:100,de:"-",tx:"「マギアス・パラディオン」以外の「パラディオン」モンスター１体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク先のモンスターの元々の攻撃力分アップする。\n②：このカードのリンク先のモンスターは攻撃できない。\n③：このカードのリンク先に効果モンスターが特殊召喚された場合に発動できる。\nデッキから「パラディオン」モンスター１体を手札に加える。",li:"",lk:["下"],ta:[],al:[]},
  {n:"レグレクス・パラディオン",h:"れぐれくす・ぱらでぃおん",e:"Crusadia Regulex",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「パラディオン」モンスターを含む効果モンスター２体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク先のモンスターの元々の攻撃力分アップする。\n②：このカードのリンク先のモンスターは攻撃できない。\n③：このカードのリンク先に効果モンスターが特殊召喚された場合に発動できる。\nデッキから「パラディオン」魔法・罠カード１枚を手札に加える。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"アークロード・パラディオン",h:"あーくろーど・ぱらでぃおん",e:"Crusadia Equimax",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"リンクモンスターを含む効果モンスター２体以上\n①：このカードの攻撃力は、このカードのリンク先のモンスターの元々の攻撃力分アップする。\n②：このカードのリンク先のモンスターは攻撃できない。\n③：１ターンに１度、このカードのリンク先の自分の、「パラディオン」モンスターまたは「星遺物」モンスター１体をリリースし、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"明星の機械騎士",h:"みょうじょうのじゃっくないつ",e:"Mekk-Knight of the Morning Star",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「ジャックナイツ」モンスターを含むモンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札から「ジャックナイツ」モンスター１体または「星遺物」カード１枚を捨てて発動できる。\nデッキから「星遺物」カード１枚を手札に加える。\n②：このカードがモンスターゾーンに存在する限り、自分の「ジャックナイツ」モンスターは、その縦列と異なる縦列のモンスターとの戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"閃刀姫－ハヤテ",h:"せんとうき－はやて",e:"Sky Striker Ace - Hayate",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"風属性以外の「閃刀姫」モンスター１体\n自分は「閃刀姫－ハヤテ」を１ターンに１度しか特殊召喚できない。\n①：このカードは直接攻撃できる。\n②：このカードが戦闘を行ったダメージ計算後に発動できる。\nデッキから「閃刀」カード１枚を墓地へ送る。",li:"",lk:["左下"],ta:[],al:[]},
  {n:"リプロドクス",h:"りぷろどくす",e:"Reprodocus",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\n\n●種族を１つ宣言して発動できる。\nこのカードのリンク先の全ての表側表示モンスターの種族はターン終了時まで宣言した種族になる。\n\n●属性を１つ宣言して発動できる。\nこのカードのリンク先の全ての表側表示モンスターの属性はターン終了時まで宣言した属性になる。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"見習い魔嬢",h:"みならいまじょう",e:"Wee Witch's Apprentice",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"闇属性モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの闇属性モンスターの攻撃力・守備力は５００アップし、光属性モンスターの攻撃力・守備力は４００ダウンする。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地の闇属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ハイパースター",h:"はいぱーすたー",e:"Hip Hoshiningen",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"光属性モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの光属性モンスターの攻撃力・守備力は５００アップし、闇属性モンスターの攻撃力・守備力は４００ダウンする。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地の光属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"サイバネット・リチューアル",h:"さいばねっと・りちゅーある",e:"Cynet Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"サイバース族の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札からサイバース族の儀式モンスター１体を儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと儀式モンスター１体を除外して発動できる。\n自分フィールドに「サイバネット・トークン」（サイバース族・光・星４・攻／守０）２体を特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼロ・エクストラリンク",h:"ぜろ・えくすとらりんく",e:"Zero Extra Link",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"EXモンスターゾーンのリンクモンスターの相互リンク先の自分のリンクモンスター１体を対象としてこのカードを発動できる。\n①：対象のモンスターの攻撃力はフィールドのリンクモンスターの数×８００アップする。\n②：対象のモンスターを素材としてリンクモンスターがリンク召喚された場合に発動する。\nそのリンクモンスターの攻撃力は、ターン終了時までこのカードの①の効果でアップしていた数値分アップする。\n③：対象のモンスターが攻撃したダメージ計算後に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレル・リロード",h:"う゛ぁれる・りろーど",e:"Borrel Regenerator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「ヴァレット」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\n②：装備モンスターが破壊された事でこのカードが墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・レヴシステム",h:"さいばー・れう゛しすてむ",e:"Cyber Revsystem",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・墓地から「サイバー・ドラゴン」１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"リユナイト・パラディオン",h:"りゆないと・ぱらでぃおん",e:"Crusadia Revival",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「パラディオン」リンクモンスターの攻撃力は５００アップする。\n②：１ターンに１度、自分フィールドの「パラディオン」リンクモンスター１体を対象として発動できる。\nこのターン、自分はそのモンスターでしか攻撃できず、そのモンスターは相手モンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバード・パラディオン",h:"おーばーど・ぱらでぃおん",e:"Crusadia Power",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「パラディオン」モンスター１体を対象として発動できる。\nこのターン、その「パラディオン」モンスターは自身以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"エンドレス・オブ・ザ・ワールド",h:"えんどれす・おぶ・ざ・わーるど",e:"Cycle of the World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「破滅の女神ルイン」「終焉の王デミス」の降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分フィールドのモンスターをリリースし、手札から「破滅の女神ルイン」または「終焉の王デミス」を儀式召喚する。\n②：墓地のこのカードをデッキに戻して発動できる。\nデッキから「エンド・オブ・ザ・ワールド」１枚を手札に加える。\nその後、自分の墓地から「破滅の女神ルイン」または「終焉の王デミス」１体を選んで手札に加える事ができる。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイク・オブ・ザ・ワールド",h:"ぶれいく・おぶ・ざ・わーるど",e:"Breaking of the World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの儀式モンスター１体を対象として発動できる。\n手札の儀式モンスター１体をターン終了時まで公開する。\nその公開した儀式モンスターのレベルはターン終了時まで、対象のモンスターのレベルと同じになる。\n②：１ターンに１度、自分フィールドに「破滅の女神ルイン」または「終焉の王デミス」が儀式召喚された場合、以下の効果から１つを選択して発動できる。\n\n●自分はデッキから１枚ドローする。\n\n●フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"リバース・オブ・ザ・ワールド",h:"りばーす・おぶ・ざ・わーるど",e:"Turning of the World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、手札の儀式モンスターをリリースし、手札・デッキから「破滅の女神ルイン」または「終焉の王デミス」を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の醒存",h:"せいいぶつのせいぞん",e:"World Legacy Survivor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキの上からカードを５枚めくる。\nその中に「クローラー」モンスターまたは「星遺物」カードがあった場合、その内の１枚を選んで手札に加え、残りのカードは全て墓地へ送る。\n無かった場合、めくったカードを全てデッキに戻す。\nこのカードの発動後、ターン終了時まで自分はリンクモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の機憶",h:"せいいぶつのきおく",e:"World Legacy's Memory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札・デッキから「ジャックナイツ」モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。\nこのカードの発動後、ターン終了時まで自分は「ジャックナイツ」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導研究所",h:"まじっく・らぼ",e:"Mythical Institution",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「魔導獣」Pモンスターカードが戦闘・効果で破壊される度にこのカードに魔力カウンターを２つ置く。\n②：１ターンに１度、自分フィールドの魔力カウンターを任意の数だけ取り除いて発動できる。\n取り除いた数と同じレベルを持つ、魔力カウンターを置く事ができるモンスター１体を、デッキのモンスター及び自分のEXデッキの表側表示のPモンスターの中から選んで手札に加える。\n③：フィールドのこのカードが効果で破壊される場合、代わりにこのカードの魔力カウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"獣・魔・導",h:"びーすと・まじっく",e:"Beast Magic Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの魔力カウンターを以下の数だけ取り除き、その効果を発動できる。\n\n●２つ：自分フィールドの「魔導獣」Pモンスター１体を選んで持ち主の手札に戻す。\n\n●４つ：自分のEXデッキから表側表示の「魔導獣」Pモンスター１体を特殊召喚する。\nその後、そのモンスターに魔力カウンターを２つ置く事ができる。\n\n●６つ：自分のEXデッキから表側表示のPモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星呼びの天儀台",h:"せれすてぃある・せくすたんと",e:"Celestial Observatory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、レベル６モンスター１体を持ち主のデッキの一番下に戻して発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"孤毒の剣",h:"こどくのつるぎ",e:"Solitary Sword of Poison",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターにのみ装備可能。\n①：「孤毒の剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターの元々の攻撃力・守備力は、相手モンスターと戦闘を行うダメージ計算時のみ倍になる。\n③：自分フィールドに装備モンスター以外のモンスターが存在する場合にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"クロス・ブリード",h:"くろす・ぶりーど",e:"Cross Breed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、元々の種族・属性が同じで、カード名が異なるモンスター２体を除外して発動できる。\nそれらのモンスターと元々の種族・属性が同じで、カード名が異なるモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"予見通帳",h:"よけんつうちょう",e:"Ledger of Legerdemain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキの上からカード３枚を裏側表示で除外する。\nこのカードの発動後３回目の自分スタンバイフェイズに、この効果で除外したカード３枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"シールド・ハンドラ",h:"しーるど・はんどら",e:"Shield Handler",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのモンスターを破壊する魔法・罠・モンスターの効果が発動した時、自分及び相手フィールドのリンクモンスターを１体ずつ対象としてこのカードを発動できる。\n対象の相手モンスターの効果を無効にし、このカードを装備カード扱いとして対象の自分モンスターに装備する。\n装備モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ミラーフォース・ランチャー",h:"みらーふぉーす・らんちゃー",e:"Mirror Force Launcher",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに手札からモンスター１体を捨てて発動できる。\n自分のデッキ・墓地から「聖なるバリア －ミラーフォース－」１枚を選んで手札に加える。\n②：セットされたこのカードが相手の効果で破壊され墓地へ送られた場合に発動できる。\n墓地のこのカードと自分の手札・デッキ・墓地の「聖なるバリア －ミラーフォース－」１枚を選び、そのカードとこのカードを自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"リンク・デス・ターレット",h:"りんく・です・たーれっと",e:"Link Turret",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードの発動時の効果処理として、このカードにカウンターを４つ置く。\n②：自分が戦闘ダメージを受ける度にこのカードにカウンターを１つ置く。\n③：自分メインフェイズ２に、このカードのカウンターを１つ取り除き、自分の墓地の「ヴァレット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、フィールドから離れた場合に除外される。\nこの効果を発動するターン、自分は闇属性のリンクモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・オーバーフロー",h:"さいばねてぃっく・おーばーふろー",e:"Cybernetic Overflow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地及び自分フィールドの表側表示モンスターの中から、「サイバー・ドラゴン」を任意の数だけ選んで除外する（同じレベルは１体まで）。\nその後、除外した数だけ相手フィールドのカードを選んで破壊する。\n②：フィールドのこのカードが効果で破壊された場合に発動できる。\nデッキから「サイバー」魔法・罠カードまたは「サイバネティック」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ・ドライブ",h:"どらぐにてぃ・どらいぶ",e:"Dragunity Legion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\nこのターン、自分は「ドラグニティ」モンスターしか特殊召喚できない。\n\n●自分の魔法＆罠ゾーンの「ドラグニティ」モンスターカード１枚を対象として発動できる。\nそのカードを守備表示で特殊召喚する。\n\n●自分フィールドの「ドラグニティ」モンスター１体を対象として発動できる。\n自分の墓地から「ドラグニティ」モンスター１体を選び、装備カード扱いとして対象の自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"クルセイド・パラディオン",h:"くるせいど・ぱらでぃおん",e:"Crusadia Vanguard",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードは自分フィールドの、「パラディオン」モンスターまたは「星遺物」モンスター１体をリリースして発動する事もできる。\nその場合、そのモンスターと元々のカード名が異なる、「パラディオン」モンスターまたは「星遺物」モンスター１体を自分のデッキ・墓地から選んで特殊召喚する。\n②：自分フィールドに「パラディオン」リンクモンスターが存在する限り、相手はリンクモンスターしか攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"リターン・オブ・ザ・ワールド",h:"りたーん・おぶ・ざ・わーるど",e:"Renewal of the World",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードの発動時の効果処理として、デッキから儀式モンスター１体を除外する。\n②：このカードを墓地へ送り、以下の効果から１つを選択して発動できる。\n●儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスター１体をリリース、またはリリースの代わりに自分の墓地の儀式モンスター１体をデッキに戻し、このカードの①の効果で除外したモンスターを儀式召喚する。\n●このカードの効果で除外したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の交心",h:"せいいぶつのこうしん",e:"World Legacy's Mind Meld",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに「クローラー」モンスターが存在し、相手モンスターの効果が発動した時に発動できる。\nその効果は「相手フィールドの表側表示モンスター１体を選んで持ち主の手札に戻す」となる。\n②：墓地のこのカードを除外し、フィールドのリンクモンスター１体を対象として発動できる。\n自分の手札・デッキ・墓地から「クローラー」モンスター１体を選び、対象のモンスターのリンク先となる自分フィールドに裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メタファイズ・アセンション",h:"めたふぁいず・あせんしょん",e:"Metaphys Ascension",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札から「メタファイズ」カード１枚を捨てて発動できる。\n自分はデッキから１枚ドローする。\nその後、デッキから「メタファイズ」モンスター１体を除外できる。\n②：このカードが除外された場合に発動できる。\nデッキから「メタファイズ・アセンション」以外の「メタファイズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"弩弓部隊",h:"どきゅうぶたい",e:"Ballista Squad",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"深すぎた墓穴",h:"ふかすぎたはかあな",e:"The Deep Grave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手の墓地のモンスター１体を対象として発動できる。\n次の自分スタンバイフェイズにそのモンスターを墓地から自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"単一化",h:"たんいつか",e:"Universal Adapter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\n対象のモンスター以外のフィールドの全てのモンスターの攻撃力はターン終了時まで対象のモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ディーラーズ・チョイス",h:"でぃーらーず・ちょいす",e:"Dealer's Choice",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いのプレイヤーは、デッキをシャッフルし、デッキから１枚ドローする。\nその後、お互いのプレイヤーは手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴッドオーガス",h:"ごっどおーがす",e:"Orgoth the Relentless",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2450,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを３回振る。\nこのカードの攻撃力・守備力は相手ターン終了時まで、出た目の合計×１００アップする。\nその後、出た目の２つが同じ場合、その同じ目によって以下の効果を適用する。\n出た目の全てが同じ場合、以下の効果を全て適用する。\n\n●１・２：このカードは相手ターン終了時まで戦闘・効果では破壊されない。\n\n●３・４：自分はデッキから２枚ドローする。\n\n●５・６：このターン、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サモン・ダイス",h:"さもん・だいす",e:"Summon Dice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１０００LPを払って発動できる。\nサイコロを１回振り、出た目の効果を適用する。\n\n●１・２：モンスター１体を召喚できる。\n\n●３・４：自分の墓地からモンスター１体を選んで特殊召喚できる。\n\n●５・６：手札からレベル５以上のモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"シンデレラ",h:"しんでれら",e:"Prinzessin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから「カボチャの馬車」１体を特殊召喚する。\nフィールドゾーンに「シュトロームベルクの金の城」が存在する場合、さらにデッキから「ガラスの靴」１枚をこのカードに装備できる。\n②：このカードが直接攻撃で相手に戦闘ダメージを与えた時、このカードに装備されている「ガラスの靴」１枚と、フィールドの表側表示モンスター１体を対象として発動できる。\nその「ガラスの靴」をそのモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"カボチャの馬車",h:"かぼちゃのばしゃ",e:"Pumpkin Carriage",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:300,tx:"①：このカードがモンスターゾーンに存在する限り、自分の「シンデレラ」は直接攻撃できる。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「シュトロームベルクの金の城」は効果では破壊されず、相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄のハンス",h:"てつのはんす",e:"Iron Hans",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「鉄の騎士」１体を特殊召喚する。\nこの効果の処理時にフィールドゾーンに「シュトロームベルクの金の城」が存在しない場合、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。\n②：フィールドゾーンに「シュトロームベルクの金の城」が存在する場合、このカードの攻撃力は自分フィールドの「鉄の騎士」の数×１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄の騎士",h:"てつのきし",e:"Iron Knight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「鉄のハンス」が存在する場合、このカードの攻撃力は１０００ダウンする。\n②：フィールドのこのカードが効果で墓地へ送られた場合、または戦闘で破壊された場合に発動できる。\nデッキから「鉄のハンス」１体を手札に加える。\nフィールドゾーンに「シュトロームベルクの金の城」が存在する場合、代わりにデッキから戦士族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"怪鳥グライフ",h:"かいちょうぐらいふ",e:"Glife the Phantom Bird",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「シュトロームベルクの金の城」１枚を手札に加える。\n②：このカードが召喚・特殊召喚に成功した場合、相手の魔法＆罠ゾーンのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘクサ・トルーデ",h:"へくさ・とるーで",e:"Hexe Trude",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2600,de:2100,tx:"①：フィールドゾーンに「シュトロームベルクの金の城」が存在する場合、このカードはリリースなしで召喚できる。\n②：１ターンに１度、フィールドゾーンに「シュトロームベルクの金の城」が存在する場合、このカード以外のフィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、このターンこのカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードが戦闘でモンスターを破壊した時、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は４００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"シュトロームベルクの金の城",h:"しゅとろーむべるくのきんのしろ",e:"Golden Castle of Stromberg",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは自分スタンバイフェイズ毎にデッキの上からカード１０枚を裏側表示で除外する。\n除外できない場合このカードを破壊する。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n「シュトロームベルクの金の城」のカード名が記されたモンスター１体をデッキから特殊召喚する。\nこの効果を発動するターン、自分は通常召喚できない。\n②：相手モンスターの攻撃宣言時に発動する。\nその攻撃モンスターを破壊し、その攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ガラスの靴",h:"がらすのくつ",e:"Glass Slippers",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの種族が天使族の場合、装備モンスターの攻撃力は１０００アップする。\n天使族以外の場合、装備モンスターは攻撃できず、攻撃力は１０００ダウンする。\n②：装備モンスターが破壊された事でこのカードが墓地へ送られた場合、自分フィールドの「シンデレラ」１体を対象として発動できる。\nその自分の「シンデレラ」にこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄の檻",h:"てつのおり",e:"Iron Cage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、自分フィールドのモンスター１体を墓地へ送る。\nフィールドゾーンに「シュトロームベルクの金の城」が存在する場合、代わりに相手フィールドのモンスターを墓地へ送る事もできる。\n②：自分スタンバイフェイズに、このカードの①の効果で自分または相手の墓地へ送られたモンスター１体を対象として発動できる。\nこのカードを破壊し、そのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リトマスの死の剣士",h:"りとますのしのけんし",e:"Litmus Doom Swordsman",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:0,de:0,tx:"「リトマスの死儀式」により降臨。\n①：このカードはモンスターゾーンに存在する限り、罠カードの効果を受けず、戦闘では破壊されない。\n②：罠カードがフィールドに表側表示で存在する場合、このカードの攻撃力・守備力は３０００アップする。\n③：儀式召喚したこのカードが相手によって破壊された場合、自分または相手の墓地の罠カード１枚を対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"リトマスの死儀式",h:"りとますのしぎしき",e:"Litmus Doom Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「リトマスの死の剣士」の降臨に必要。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「リトマスの死の剣士」を儀式召喚する。\n②：このカードが墓地に存在する場合、自分の墓地の「リトマスの死の剣士」１体を対象として発動できる。\n墓地のこのカードと対象のモンスターの合計２枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"リビング・フォッシル",h:"りびんぐ・ふぉっしる",e:"Living Fossil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベル４以下のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\n②：装備モンスターの攻撃力・守備力は１０００ダウンし、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"一撃必殺！居合いドロー",h:"いちげきひっさつ！いあいどろー",e:"Slash Draw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚捨てて発動できる。\n相手フィールドのカードの数だけ自分のデッキの上からカードを墓地へ送り、その後自分はデッキから１枚ドローし、お互いに確認する。\nそれが「一撃必殺！居合いドロー」だった場合、それを墓地へ送り、フィールドのカードを全て破壊する。\nその後、この効果で破壊され墓地へ送られたカードの数×２０００ダメージを相手に与える。\n違った場合、自分はこの効果でデッキから墓地へ送ったカードの数だけ、自分の墓地のカードを選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神ミチオン",h:"じかいしんみちおん",e:"Michion, the Timelord",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\n相手のLPを半分にする。\n④：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神ハイロン",h:"じかいしんはいろん",e:"Hailon, the Timelord",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\n自分のLPが相手より少ない場合、その差の数値分だけ相手にダメージを与える。\n④：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神ラフィオン",h:"じかいしんらふぃおん",e:"Raphion, the Timelord",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\nこのターン、このカードと戦闘を行った相手フィールドの表側表示モンスター１体を選び、その攻撃力分のダメージを相手に与える。\n④：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神ガブリオン",h:"じかいしんがぶりおん",e:"Gabrion, the Timelord",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n③：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\n相手フィールドのカードを全て持ち主のデッキに戻す。\nその後、相手は自身のデッキに戻した数だけドローする。\n④：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神サンダイオン",h:"じかいしんさんだいおん",e:"Sandaion, the Timelord",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードはデッキから特殊召喚できない。\n①：「時械神サンダイオン」は自分フィールドに１体しか表側表示で存在できない。\n②：相手フィールドにのみモンスターが存在する場合、このカードはリリースなしで召喚できる。\n③：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生するお互いの戦闘ダメージは０になる。\n④：このカードが戦闘を行ったバトルフェイズ終了時に発動する。\n相手に２０００ダメージを与える。\n⑤：自分スタンバイフェイズに発動する。\nこのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"虚無械アイン",h:"きょむかいあいん",e:"Empty Machine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードはフィールドに表側表示で存在する限り１度だけ、相手の効果では破壊されない。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●手札からレベル１０モンスター１体を捨てて発動できる。\n自分はデッキから１枚ドローする。\n●自分の魔法＆罠ゾーンにこのカード以外のカードが存在しない場合、自分の墓地の「時械神」モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、手札・デッキから「無限械アイン・ソフ」１枚を選んで自分の魔法＆罠ゾーンにセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"無限械アイン・ソフ",h:"むげんかいあいん・そふ",e:"Infinite Machine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の魔法＆罠ゾーンの表側表示の「虚無械アイン」１枚を墓地へ送ってこのカードを発動できる。\n①：このカードは１ターンに１度だけ相手の効果では破壊されない。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分・相手のメインフェイズに発動できる。\n手札から「時械神」モンスター１体を特殊召喚する。\n●自分の墓地の「時械神」モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、手札・デッキから「無限光アイン・ソフ・オウル」１枚を選んで自分の魔法＆罠ゾーンにセットできる。",li:"",lk:[],ta:[],al:[]},
  {n:"無限光アイン・ソフ・オウル",h:"むげんこうあいん・そふ・おうる",e:"Infinite Light",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の魔法＆罠ゾーンの表側表示の「無限械アイン・ソフ」１枚を墓地へ送ってこのカードを発動できる。\n①：このカードは相手の効果では破壊されない。\n②：自分フィールドの「時械神」モンスターは効果の対象にならず、お互いにフィールドの「時械神」モンスターをデッキに戻す事はできない。\n③：１ターンに１度、自分フィールドにモンスターが存在しない場合に発動できる。\n自分の手札・デッキ・墓地からそれぞれ１体まで、カード名の異なる「時械神」モンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"No.27 弩級戦艦－ドレッドノイド",h:"なんばーず２７ どきゅうせんかん－どれっどのいど",e:"Number 27: Dreadnought Dreadnoid",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1000,tx:"レベル４モンスター×２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊したバトルフェイズ終了時に発動できる。\nランク１０以上の機械族Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.67 パラダイスマッシャー",h:"なんばーず６７ ぱらだいすまっしゃー",e:"Number 67: Pair-a-Dice Smasher",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:2100,tx:"レベル５モンスター×２体以上\n①：１ターンに１度、自分メインフェイズ１にこのカードのX素材を２つ取り除いて発動できる。\nお互いにサイコロを２回ずつ振る。\n出た目の合計が大きいプレイヤーは、次のターンの終了時までモンスターの効果を発動できず、攻撃宣言できない。\n②：X素材を持っているこのカードがモンスターゾーンに存在し、自分または相手がサイコロを振った場合、１ターンに１度だけその内１つの目を７として適用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.75 惑乱のゴシップ・シャドー",h:"なんばーず７５ わくらんのごしっぷ・しゃどー",e:"Number 75: Bamboozling Gossip Shadow",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:2600,tx:"レベル３モンスター×２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、相手がモンスターの効果を発動した時、このカードのX素材を２つ取り除いて発動できる。\nその効果は「お互いのプレイヤーは、それぞれデッキから１枚ドローする」となる。\n②：このカード以外の自分フィールドの「No.」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする（このカードがX素材を持っている場合、それらも全て重ねてX素材とする）。",li:"",lk:[],ta:[],al:[]},
  {n:"No.90 銀河眼の光子卿",h:"なんばーず９０ ぎゃらくしーあいず・ふぉとん・ろーど",e:"Number 90: Galaxy-Eyes Photon Lord",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:3000,tx:"レベル８モンスター×２\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「フォトン」カードをX素材としているこのカードは効果では破壊されない。\n②：相手がモンスターの効果を発動した時、このカードのX素材を１つ取り除いて発動できる。\nその効果を無効にする。\n取り除いたX素材が「ギャラクシー」カードの場合、さらにそのモンスターを破壊する。\n③：相手ターンに発動できる。\nデッキから「フォトン」カードまたは「ギャラクシー」カード１枚を選び、手札に加えるかこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"アイアンドロー",h:"あいあんどろー",e:"Iron Draw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターが機械族の効果モンスター２体のみの場合に発動できる。\n自分はデッキから２枚ドローする。\nこのカードの発動後、ターン終了時まで自分は１回しかモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"グローリアス・ナンバーズ",h:"ぐろーりあす・なんばーず",e:"Glorious Numbers",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、自分の墓地の「No.」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、自分はデッキから１枚ドローする。\n②：墓地のこのカードを除外し、自分フィールドの「No.」Xモンスター１体を対象として発動できる。\n手札１枚をそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"地翔星ハヤテ",h:"ちしょうせいはやて",e:"Hayate the Earth Star",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドにのみモンスターが存在する場合、または自分フィールドに光属性モンスターが存在する場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から戦士族・光属性・レベル５モンスター１体を特殊召喚する。\n③：１ターンに１度、自分の戦士族モンスターが攻撃対象に選択された時に発動できる。\nこのカードの攻撃力を５００ダウンし、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"天昇星テンマ",h:"てんしょうせいてんま",e:"Tenma the Sky Star",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドにのみモンスターが存在する場合、または自分フィールドに地属性モンスターが存在する場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から戦士族・地属性・レベル５モンスター１体を特殊召喚する。\n③：１ターンに１度、自分フィールドの戦士族モンスターを対象とする相手の効果が発動した時に発動できる。\nこのカードの攻撃力を５００ダウンし、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天融星カイキ",h:"てんゆうせいかいき",e:"Kaiki the Unity Star",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、５００LPを払って発動できる。\n自分の手札・フィールドから、戦士族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：相手ターンに、元々の攻撃力と異なる攻撃力を持つレベル５以上の戦士族モンスターが自分フィールドに存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"覇勝星イダテン",h:"はしょうせいいだてん",e:"Idaten the Conqueror Star",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2200,tx:"レベル５以上の戦士族モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合に発動できる。\nデッキから戦士族・レベル５モンスター１体を手札に加える。\n②：１ターンに１度、手札を任意の枚数捨てて発動できる。\nこのカードの攻撃力は捨てた数×２００アップする。\n③：このカードがこのカードのレベル以下のレベルを持つ相手モンスターと戦闘を行うダメージ計算時に１度、発動できる。\nその相手モンスターの攻撃力をそのダメージ計算時のみ０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"覇道星シュラ",h:"はどうせいしゅら",e:"Shura the Combat Star",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「覇勝星イダテン」＋レベル５以上の戦士族モンスター\n①：１ターンに１度、自分・相手のバトルフェイズに発動できる。\n相手フィールドの全ての表側表示モンスターの攻撃力は０になる。\n②：モンスター同士が戦闘を行うダメージ計算時に１度、発動できる。\nその戦闘を行うお互いのモンスターの攻撃力はそのダメージ計算時のみ、それぞれのレベル×２００アップする。\n③：融合召喚したこのカードが相手によって破壊され墓地へ送られた場合に発動できる。\n「覇勝星イダテン」１体を融合召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイバネーション・ドラゴン",h:"はいばねーしょん・どらごん",e:"Hibernation Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:600,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地のレベル４以下のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：自分フィールドにリンクモンスターが存在しない場合、墓地のこのカードを除外し、自分の墓地のドラゴン族・闇属性のリンクモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トリガー・ヴルム",h:"とりがー・う゛るむ",e:"Triggering Wurm",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが闇属性リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\n墓地のこのカードを、そのリンクモンスターのリンク先となる自分フィールドに攻撃表示で特殊召喚する。\nこの効果で特殊召喚したこのカードはリンク素材にできない。\n②：このカードがリンクモンスターの発動した効果で、破壊された場合または除外された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"トポロジック・ガンブラー・ドラゴン",h:"とぽろじっく・がんぶらー・どらごん",e:"Topologic Gumblar Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター２体以上\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、このカード以外のモンスターがリンクモンスターのリンク先に特殊召喚された場合に発動する。\n自分は手札を任意の枚数ランダムに捨てる（最大２枚）。\nその後、捨てた数だけ相手は手札を選んで捨てる。\n②：このカードがEXリンク状態の場合に発動できる。\n相手は手札を２枚まで可能な限り選んで捨てる。\nこの効果で相手の手札が０枚になった場合、さらに相手に３０００ダメージを与える。",li:0,lk:["上","左","右","下"],ta:[],al:[]},
  {n:"ヴァレルガード・ドラゴン",h:"う゛ぁれるがーど・どらごん",e:"Borrelguard Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター３体以上\n①：このカードは効果では破壊されない。\n②：１ターンに１度、自分の魔法＆罠ゾーンのカード１枚を墓地へ送って発動できる。\nこのターンに破壊され自分または相手の墓地へ送られたモンスター１体を選び、効果を無効にして自分フィールドに特殊召喚する。\n③：１ターンに１度、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にする。\nこの効果の発動に対して相手はカードの効果を発動できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:["上","右","下","右下"],ta:[],al:[]},
  {n:"マズルフラッシュ・ドラゴン",h:"まずるふらっしゅ・どらごん",e:"Flash Charge Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"ドラゴン族モンスター２体以上\n自分はこのカードのリンク先にモンスターを出せない。\n①：１ターンに１度、このカードのリンク先にモンスターが召喚・特殊召喚された場合に発動できる。\nこのカードのリンク先のモンスター１体を選んで破壊し、相手に５００ダメージを与える。\n②：このカードを対象とする魔法・罠・モンスターの効果が発動した時、自分フィールドのモンスター１体をリリースして発動できる。\nその発動を無効にする。",li:"",lk:["左上","上","右上"],ta:[],al:[]},
  {n:"オッドアイズ・ファンタズマ・ドラゴン",h:"おっどあいず・ふぁんたずま・どらごん",e:"Odd-Eyes Phantasma Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\n自分のEXデッキから表側表示のドラゴン族Pモンスター１体を手札に加える。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカード名の①②のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のPゾーンにカードが２枚存在し、自分のEXデッキに表側表示の「オッドアイズ」Pモンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果を発動するターン、自分はP召喚できない。\n②：このカードが相手モンスターに攻撃するダメージ計算時に発動できる。\nその相手モンスターの攻撃力はそのダメージ計算時のみ、自分のEXデッキの表側表示のPモンスターの数×１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"希望の魔術師",h:"きぼうのまじゅつし",e:"Magician of Hope",t:"monster",a:"光",l:4,ps:8,pe:"①：１ターンに１度、自分または相手のモンスターの攻撃宣言時に発動できる。\nその攻撃を無効にする。\nその後、このカードを破壊する。",tr:"魔法使い族",mo:["エクシーズ","ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル４モンスター×２\nレベル４がP召喚可能な場合にEXデッキの表側表示のこのカードはP召喚できる。\nこのカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\n手札からレベル７以下のPモンスター１体を効果を無効にして守備表示で特殊召喚する。\nその後、表側表示のこのカードを自分のPゾーンに置く事ができる。\n②：このカードがP召喚に成功した場合、自分の墓地のPモンスター１体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"サクリファイス・アニマ",h:"さくりふぁいす・あにま",e:"Relinquished Anima",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"トークン以外のレベル１モンスター１体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先の表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。",li:"",lk:["上"],ta:[],al:[]},
  {n:"青眼の混沌龍",h:"ぶるーあいず・かおす・どらごん",e:"Blue-Eyes Chaos Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:0,tx:"「カオス・フォーム」により降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n①：このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：「青眼の白龍」を使用して儀式召喚したこのカードの攻撃宣言時に発動できる。\n相手フィールドの全てのモンスターの表示形式を変更する。\nこの効果で表示形式を変更したモンスターの攻撃力・守備力は０になる。\nこのターン、このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーアイズ・ソリッド・ドラゴン",h:"ぶるーあいず・そりっど・どらごん",e:"Blue-Eyes Solid Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果を無効にする。\n②：相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nフィールドのこのカードを持ち主のデッキに戻し、デッキから「青眼の白龍」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ビンゴマシーンGO！GO！",h:"びんごましーんごー！ごー！",e:"Bingo Machine, Go!!!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから以下のカードを合計３枚選んで相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りのカードはデッキに戻す。\n\n●「ブルーアイズ」モンスター\n●「ビンゴマシーンGO！GO！」以外の、「青眼の白龍」または「青眼の究極竜」のカード名が記された魔法・罠カード",li:"",lk:[],ta:[],al:[]},
  {n:"青き眼の激臨",h:"あおきめのげきりん",e:"Rage with Eyes of Blue",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分は「青眼の白龍」しか召喚・特殊召喚できない。\n①：このカードを含む、自分の手札・フィールド・墓地のカードを全て裏側表示で除外し、デッキから「青眼の白龍」を３体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"強靭！無敵！最強！",h:"きょうじん！むてき！さいきょう！",e:"The Ultimate Creature of Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「ブルーアイズ」モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターは自身以外のカードの効果を受けず、戦闘では破壊されず、そのモンスターと戦闘を行ったモンスターはダメージステップ終了時に破壊される。\n②：このカードが墓地に存在し、自分が「青眼の白龍」の召喚・特殊召喚に成功した場合に発動できる。\nこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エタニティ・ドラゴン",h:"さいばー・えたにてぃ・どらごん",e:"Cyber Eternity Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:4000,tx:"「サイバー・ドラゴン」モンスター＋機械族モンスター×２\n①：自分の墓地に機械族の融合モンスターが存在する限り、このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：融合召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「サイバー・ドラゴン」１体を選んで特殊召喚する。\n③：墓地のこのカードを除外して発動できる。\nこのターン、自分フィールドの融合モンスターは相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ファロス",h:"さいばー・ふぁろす",e:"Cyber Pharos",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは自分フィールドの機械族モンスター１体をリリースして手札から特殊召喚できる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドから、機械族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n③：自分の融合モンスターが戦闘で破壊された時、墓地のこのカードを除外して発動できる。\nデッキから「パワー・ボンド」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバーロード・フュージョン",h:"さいばーろーど・ふゅーじょん",e:"Cyberload Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールド及び除外されている自分のモンスターの中から、融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、「サイバー・ドラゴン」モンスターを融合素材とするその融合モンスター１体をEXデッキから融合召喚する。\nこのターン、この効果で特殊召喚したモンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エターナル・エヴォリューション・バースト",h:"えたーなる・えう゛ぉりゅーしょん・ばーすと",e:"Super Strident Blaze",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族の融合モンスターにのみ装備可能。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分バトルフェイズ中に相手は魔法・罠・モンスターの効果を発動できない。\n②：装備モンスターが相手モンスターに攻撃したダメージステップ終了時、自分の墓地から「サイバー・ドラゴン」モンスター１体を除外して発動できる。\n装備モンスターは相手モンスターに続けて攻撃できる。\nこの効果を発動するターン、装備モンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・レボリューション",h:"さいばねてぃっく・れぼりゅーしょん",e:"Cybernetic Revolution",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「サイバー・ドラゴン」１体をリリースして発動できる。\n「サイバー・ドラゴン」モンスターを融合素材とする融合モンスター１体をEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは、直接攻撃できず、次のターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－フルアーマード・ウィング",h:"ぶらっくふぇざー－ふるあーまーど・うぃんぐ",e:"Blackwing Full Armor Master",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"「BF」チューナー＋チューナー以外のモンスター１体以上\n①：このカードは他のカードの効果を受けない。\n②：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターが効果を発動する度に、その相手の表側表示モンスターに楔カウンターを１つ置く（最大１つまで）。\n③：１ターンに１度、相手フィールドの楔カウンターが置かれたモンスター１体を対象として発動できる。\nそのモンスターのコントロールを得る。\n④：自分エンドフェイズに発動できる。\nフィールドの楔カウンターが置かれたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－毒風のシムーン",h:"ぶらっくふぇざー－どくかぜのしむーん",e:"Blackwing - Simoon the Poison Wind",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、手札からこのカード以外の「BF」モンスター１体を除外して発動できる。\nデッキから「黒い旋風」１枚を自分の魔法＆罠ゾーンに表側表示で置く。\nその後、手札のこのカードをリリースなしで召喚するか、墓地へ送る。\nこの効果で置いた「黒い旋風」はエンドフェイズに墓地へ送られ、自分は１０００ダメージを受ける。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"BF－南風のアウステル",h:"ぶらっくふぇざー－みなみかぜのあうすてる",e:"Blackwing - Auster the South Wind",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードは特殊召喚できない。\n①：このカードが召喚に成功した時、除外されている自分のレベル４以下の「BF」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：墓地のこのカードを除外し、以下の効果から１つを選択して発動できる。\n\n●自分フィールドの「ブラックフェザー・ドラゴン」１体を選び、相手フィールドのカードの数だけ黒羽カウンターを置く。\n\n●相手フィールドの表側表示モンスター全てに可能な限り楔カウンターを１つずつ置く（最大１つまで）。",li:"",lk:[],ta:[],al:[]},
  {n:"グローウィング・ボウガン",h:"ぐろーうぃんぐ・ぼうがん",e:"Glowing Crossbow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「BF」モンスターにのみ装備可能。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力・守備力は５００アップする。\n②：装備モンスターが戦闘で相手モンスターを破壊した場合に発動できる。\n相手の手札をランダムに１枚選んで捨てる。\n③：装備モンスターがS素材になった事でこのカードが墓地へ送られた場合に発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラック・バード・クローズ",h:"ぶらっく・ばーど・くろーず",e:"Blackbird Close",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールドに「BF」Sモンスターまたは「ブラックフェザー・ドラゴン」が存在する場合、このカードの発動は手札からもできる。\n①：相手フィールドのモンスターが効果を発動した時、自分フィールドの表側表示の「BF」モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\nその後、EXデッキから「ブラックフェザー・ドラゴン」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"輝光竜フォトン・ブラスト・ドラゴン",h:"きこうりゅうふぉとん・ぶらすと・どらごん",e:"Starliege Photon Blast Dragon",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2500,tx:"レベル４モンスター×２\n①：このカードがX召喚に成功した場合に発動できる。\n手札から「フォトン」モンスター１体を特殊召喚する。\n②：X召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの攻撃力２０００以上のモンスターは相手の効果の対象にならず、相手の効果では破壊されない。\n③：相手ターンに１度、このカードのX素材を１つ取り除き、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「銀河眼の光子竜」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・バニッシャー",h:"ふぉとん・ばにっしゃー",e:"Photon Vanisher",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できない。\n自分フィールドに「フォトン」モンスターまたは「ギャラクシー」モンスターが存在する場合に特殊召喚できる。\n自分は「フォトン・バニッシャー」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「銀河眼の光子竜」１体を手札に加える。\n②：このカードは特殊召喚したターンには攻撃できない。\n③：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが戦闘で破壊したモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・オービタル",h:"ふぉとん・おーびたる",e:"Photon Orbital",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「フォトン」モンスターまたは「ギャラクシー」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを攻撃力５００アップの装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは戦闘では破壊されない。\n②：装備されているこのカードを墓地へ送って発動できる。\nデッキから「フォトン・オービタル」以外の「フォトン」モンスターまたは「ギャラクシー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・ハンド",h:"ふぉとん・はんど",e:"Photon Hand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「フォトン」モンスターまたは「ギャラクシー」モンスターが存在する場合、１０００LPを払い、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールを得る。\n発動時に自分フィールドに「銀河眼の光子竜」が存在しない場合には、Xモンスターしか対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"フォトン・チェンジ",h:"ふぉとん・ちぇんじ",e:"Photon Change",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後、２回目の自分スタンバイフェイズに墓地へ送られる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の、「フォトン」モンスターまたは「ギャラクシー」モンスター１体を墓地へ送り、以下の効果から１つを選択して発動できる。\n「銀河眼の光子竜」を墓地へ送って発動した場合、両方を選択できる。\n●そのモンスターと元々のカード名が異なる「フォトン」モンスター１体をデッキから特殊召喚する。\n●デッキから「フォトン・チェンジ」以外の「フォトン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－メロー・マドンナ",h:"まかいげきだん－めろー・まどんな",e:"Abyss Actor - Mellow Madonna",t:"monster",a:"闇",l:7,ps:"",pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：１０００LPを払って発動できる。\nデッキから「魔界劇団－メロー・マドンナ」以外の「魔界劇団」Pモンスター１体を手札に加える。\nこの効果の発動後、ターン終了時まで自分は「魔界劇団」Pモンスターしか特殊召喚できない。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:2500,tx:"このカード名の①③のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のPモンスターが戦闘で破壊された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力は自分の墓地の「魔界台本」魔法カードの数×１００アップする。\n③：「魔界台本」魔法カードの効果が発動した場合に発動できる。\nデッキからレベル４以下の「魔界劇団」Pモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団－コミック・リリーフ",h:"まかいげきだん－こみっく・りりーふ",e:"Abyss Actor - Comic Relief",t:"monster",a:"闇",l:3,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：相手フィールドのモンスター１体と自分フィールドの「魔界劇団」Pモンスター１体を対象として発動できる。\nそのモンスター２体のコントロールを入れ替える。\nその後、このカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのコントロールを相手に移す。\n③：１ターンに１度、このカードのコントロールが移った場合に発動する。\nこのカードの元々の持ち主は自身の魔法＆罠ゾーンにセットされた「魔界台本」魔法カード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界台本「ロマンティック・テラー」",h:"まかいだいほん「ろまんてぃっく・てらー」",e:"Abyss Script - Romantic Terror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「魔界劇団」Pモンスター１体を選んで持ち主の手札に戻し、手札に戻ったモンスターと元々のカード名が異なる表側表示の「魔界劇団」Pモンスター１体を自分のEXデッキから守備表示で特殊召喚する。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「魔界台本」魔法カードを任意の数だけ選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇場「ファンタスティックシアター」",h:"まかいげきじょう「ふぁんたすてぃっくしあたー」",e:"Abyss Playhouse - Fantastic Theater",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札の「魔界劇団」Pモンスター１体と「魔界台本」魔法カード１枚を相手に見せて発動できる。\n見せた魔法カードとカード名が異なる「魔界台本」魔法カード１枚をデッキから手札に加える。\n②：P召喚した「魔界劇団」Pモンスターが自分フィールドに存在する限り、相手が発動したモンスターの効果は「相手フィールドにセットされた魔法・罠カード１枚を選んで破壊する」となる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界劇団のカーテンコール",h:"まかいげきだんのかーてんこーる",e:"Abyss Actors' Curtain Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：「魔界台本」魔法カードの効果が発動したターンに発動できる。\n自分の墓地の「魔界台本」魔法カードの数まで、自分のEXデッキから表側表示の「魔界劇団」Pモンスターを手札に加える。\nその後、この効果で手札に加えたモンスターの数まで手札から「魔界劇団」Pモンスターを特殊召喚できる（同名カードは１枚まで）。\nこのカードの発動後、ターン終了時まで自分は「魔界劇団」Pモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ネビュラ・ネオス",h:"えれめんたるひーろー　ねびゅら・ねおす",e:"Elemental HERO Nebula Neos",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「N・グラン・モール」＋「N・ブラック・パンサー」\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードがEXデッキからの特殊召喚に成功した場合に発動する。\n相手フィールドのカードの数だけ自分はデッキからドローする。\nその後、フィールドの表側表示のカード１枚を選び、その効果をターン終了時まで無効にする。\n②：エンドフェイズに発動する。\nこのカードをEXデッキに戻し、フィールドのカードを全て裏側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・スピーダー",h:"じゃんく・すぴーだー",e:"Junk Speeder",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1800,de:1000,tx:"「シンクロン」チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nデッキから「シンクロン」チューナーを可能な限り守備表示で特殊召喚する（同じレベルは１体まで）。\nこの効果を発動するターン、自分はSモンスターしかEXデッキから特殊召喚できない。\n②：このターンにS召喚したこのカードがモンスターと戦闘を行う攻撃宣言時に発動できる。\nこのカードの攻撃力はターン終了時まで元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"レイテンシ",h:"れいてんし",e:"Latency",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが効果で自分の墓地から手札に加わった場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの①の効果で特殊召喚したこのカードがリンク素材として墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"スワップリースト",h:"すわっぷりーすと",e:"Swap Cleric",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク素材として墓地へ送られた場合に発動できる。\nこのカードをリンク素材としたリンクモンスターの攻撃力は５００ダウンする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"デフコンバード",h:"でふこんばーど",e:"Defcon Bird",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:1700,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外のサイバース族モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分のサイバース族モンスターが攻撃対象に選択された時に発動できる。\nそのモンスターの攻撃力・守備力はそのダメージステップ終了時まで元々の攻撃力の倍になる。\nそのモンスターが攻撃表示の場合、さらにその表示形式を守備表示にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"マイクロ・コーダー",h:"まいくろ・こーだー",e:"Micro Coder",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:300,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族モンスターを「コード・トーカー」モンスターのリンク素材とする場合、手札のこのカードもリンク素材にできる。\n②：このカードが「コード・トーカー」モンスターのリンク素材として手札・フィールドから墓地へ送られた場合に発動できる。\nデッキから「サイバネット」魔法・罠カード１枚を手札に加える。\nフィールドのこのカードを素材とした場合にはその１枚をサイバース族・レベル４モンスター１体にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"プロフィビット・スネーク",h:"ぷろふぃびっと・すねーく",e:"Prohibit Snake",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のサイバース族リンクモンスターが相手モンスターと戦闘を行うダメージステップ開始時に、このカードを手札から墓地へ送って発動できる。\nその相手モンスターを持ち主の手札に戻す。\n②：このカードが墓地に存在し、自分のサイバース族モンスターが戦闘で相手モンスターを破壊し墓地へ送った時、自分の墓地からカード１枚を除外し、自分の墓地のレベル４以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"コード・ラジエーター",h:"こーど・らじえーたー",e:"Code Radiator",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族モンスターを「コード・トーカー」モンスターのリンク素材とする場合、手札のこのカードもリンク素材にできる。\n②：このカードが「コード・トーカー」モンスターのリンク素材として手札・フィールドから墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターは攻撃力が０になり、効果は無効化される。\nフィールドのこのカードを素材とした場合にはこの効果の対象を２体にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"スプール・コード",h:"すぷーる・こーど",e:"Spool Code",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のサイバース族モンスターが３体以上の場合、相手モンスターの直接攻撃宣言時に発動できる。\nその攻撃を無効にする。\nその後、自分フィールドに「スプールトークン」（サイバース族・光・星１・攻／守０）を３体まで守備表示で特殊召喚できる。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・オプティマイズ",h:"さいばねっと・おぷてぃまいず",e:"Cynet Optimization",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nサイバース族モンスター１体を召喚する。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしかEXデッキから特殊召喚できない。\n②：自分の「コード・トーカー」モンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・コーデック",h:"さいばねっと・こーでっく",e:"Cynet Codec",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の効果は同一チェーン上では１度しか発動できない。\n①：「コード・トーカー」モンスターがEXデッキから自分フィールドに特殊召喚された場合、そのモンスター１体を対象として発動できる。\nそのモンスターと同じ属性のサイバース族モンスター１体をデッキから手札に加える。\nこのターン、同じ属性のモンスターを自分の「サイバネット・コーデック」の効果で手札に加える事はできない。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・コンフリクト",h:"さいばねっと・こんふりくと",e:"Cynet Conflict",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「コード・トーカー」モンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし除外する。\n次のターンの終了時まで、相手はこの効果で除外したカードと元々のカード名が同じカードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シューティングコード・トーカー",h:"しゅーてぃんぐこーど・とーかー",e:"Shootingcode Talker",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"サイバース族モンスター２体以上\n①：自分バトルフェイズ開始時に発動できる。\nこのバトルフェイズ中、このカードはこのカードのリンク先のモンスターの数＋１回まで相手モンスターに攻撃できる。\nこのターン、相手フィールドのモンスターが１体のみの場合、そのモンスターと戦闘を行うこのカードの攻撃力はそのダメージ計算時のみ４００ダウンする。\n②：自分・相手のバトルフェイズ終了時に発動できる。\nこのターンにこのカードが戦闘で破壊したモンスターの数だけ自分はデッキからドローする。",li:"",lk:["上","左","下"],ta:[],al:[]},
  {n:"エルフェーズ",h:"えるふぇーず",e:"Elphase",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"レベル３以上のサイバース族モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク先のモンスターの数×３００アップする。\n②：リンク召喚した表側表示のこのカードがフィールドから離れた場合、自分の墓地のレベル４以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、効果が無効化され、リンク素材にできない。",li:"",lk:["上","右"],ta:[],al:[]},
  {n:"トークバック・ランサー",h:"とーくばっく・らんさー",e:"Talkback Lancer",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"レベル２以下のサイバース族モンスター１体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドのサイバース族モンスター１体をリリースし、そのモンスターと元々のカード名が異なる自分の墓地の「コード・トーカー」モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:["下"],ta:[],al:[]},
  {n:"デーモンの顕現",h:"でーもんのけんげん",e:"Archfiend's Manifestation",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1200,tx:"「デーモンの召喚」＋闇属性モンスター\n①：このカードはモンスターゾーンに存在する限り、カード名を「デーモンの召喚」として扱う。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「デーモンの召喚」の攻撃力は５００アップする。\n③：融合召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「デーモンの召喚」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アストラグールズ",h:"あすとらぐーるず",e:"Astra Ghouls",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに除外される。\n②：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\n自分フィールドの全ての表側表示モンスターのレベルはターン終了時まで出た目の数と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"レイン・ボーズ",h:"れいん・ぼーず",e:"Rain Bozu",t:"monster",a:"光",l:7,ps:8,pe:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、お互いのEXデッキの枚数の差×１００アップする。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは、自分ターンの間は攻撃力が、相手ターンの間は守備力が、お互いのEXデッキの枚数の差×２００アップする。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ベルキャットファイター",h:"べるきゃっとふぁいたー",e:"Bellcat Fighter",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"トークンを含むモンスター３体\n①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n自分フィールドに「ベルキャットトークン」（機械族・風・星４・攻／守２０００）１体を守備表示で特殊召喚する。",li:"",lk:["上","右","左"],ta:[],al:[]},
  {n:"リンク・パーティー",h:"りんく・ぱーてぃー",e:"Link Party",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：フィールドのリンクモンスターの元々の属性の種類の数によって以下を適用する。\n\n●１種類：自分フィールドのリンクモンスターの攻撃力は５００アップする。\n\n●２種類：相手フィールドのリンクモンスターの攻撃力は１０００ダウンする。\n\n●３種類：自分は１５００LP回復する。\n\n●４種類：相手に２０００ダメージを与える。\n\n●５種類：自分はデッキから攻撃力２５００以上のモンスター１体を特殊召喚する。\n\n●６種類：相手フィールドの攻撃力３０００以下のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ペア・ルック",h:"ぺあ・るっく",e:"Matching Outfits",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いのデッキの一番上のカードをお互いに確認し、そのカードが同じ種類（モンスター・魔法・罠）だった場合、お互いはそのカードを手札に加える。\n違った場合、お互いはそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"クロック・ワイバーン",h:"くろっく・わいばーん",e:"Clock Wyvern",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのカードの攻撃力を半分にし、自分フィールドに「クロックトークン」（サイバース族・風・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ミーア",h:"さらまんぐれいとみーあ",e:"Salamangreat Meer",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが通常のドロー以外の方法で手札に加わった場合、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：手札からこのカード以外の「サラマングレイト」カード１枚を捨てて発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣フォクシー",h:"さらまんぐれいとふぉくしー",e:"Salamangreat Foxy",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n自分のデッキの上からカードを３枚めくる。\nその中から「サラマングレイト」カード１枚を選んで手札に加える事ができる。\n残りのカードはデッキに戻す。\n②：このカードが墓地に存在し、フィールドに表側表示の魔法・罠カードが存在する場合、手札から「サラマングレイト」カード１枚を捨てて発動できる。\nこのカードを特殊召喚する。\nその後、フィールドの表側表示の魔法・罠カード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ファルコ",h:"さらまんぐれいとふぁるこ",e:"Salamangreat Falco",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが墓地へ送られた場合、自分の墓地の「サラマングレイト」魔法・罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\n②：このカードが墓地に存在する場合、「転生炎獣ファルコ」以外の自分フィールドの「サラマングレイト」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣Jジャガー",h:"さらまんぐれいとじゃっくじゃがー",e:"Salamangreat Jack Jaguar",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードが墓地に存在し、自分フィールドに「サラマングレイト」リンクモンスターが存在する場合、「転生炎獣Jジャガー」以外の自分の墓地の「サラマングレイト」モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、墓地のこのカードを自分の「サラマングレイト」リンクモンスターのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・カパプテラ",h:"だいなれすらー・かぱぷてら",e:"Dinowrestler Capaptera",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送る。\n②：このカードが「ダイナレスラー」リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\nそのリンクモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・カポエラプトル",h:"だいなれすらー・かぽえらぷとる",e:"Dinowrestler Capoeiraptor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：攻撃表示のこのカードは戦闘では破壊されず、相手モンスターに攻撃されたダメージステップ終了時にこのカードを守備表示にする。\n②：このカードがモンスターゾーンに守備表示で存在する場合、自分・相手のスタンバイフェイズに発動できる。\nデッキから「ダイナレスラー・カポエラプトル」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・システゴ",h:"だいなれすらー・しすてご",e:"Dinowrestler Systegosaur",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ダイナレスラー」モンスター１体または「ワールド・ダイナ・レスリング」１枚を手札に加える。\n②：このカードが墓地へ送られたターンのエンドフェイズに、相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、「ダイナレスラー・システゴ」以外の自分の墓地の「ダイナレスラー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・パンクラトプス",h:"だいなれすらー・ぱんくらとぷす",e:"Dinowrestler Pankratops",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2600,de:0,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードは手札から特殊召喚できる。\n②：自分フィールドの「ダイナレスラー」モンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:1,lk:[],ta:[],al:[]},
  {n:"銀河の修道師",h:"ぎゃらくしー・ぷりーすと",e:"Galaxy Cleric",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの、「フォトン」Xモンスターまたは「ギャラクシー」Xモンスター１体を対象として発動できる。\n手札のこのカードをそのモンスターの下に重ねてX素材とする。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地の「フォトン」カード及び「ギャラクシー」カードの中から合計５枚を対象として発動できる（同名カードは１枚まで）。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河剣聖",h:"ぎゃらくしー・ぶれいばー",e:"Galaxy Brave",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札の「フォトン」モンスター１体を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\nこのカードのレベルは見せた「フォトン」モンスターのレベルと同じになる。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地の「ギャラクシー」モンスター１体を対象として発動できる。\nこのカードの攻撃力・守備力はそのモンスターのそれぞれの数値と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の神職",h:"はかもりのしんしょく",e:"Gravekeeper's Headman",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、自分の墓地のレベル４の「墓守」モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示または裏側守備表示で特殊召喚する。\nこの効果は「王家の眠る谷－ネクロバレー」の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の霊術師",h:"はかもりのれいじゅつし",e:"Gravekeeper's Spiritualist",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドに「王家の眠る谷－ネクロバレー」が存在する場合に発動できる。\n魔法使い族の融合モンスターカードによって決められた、フィールドのこのカードを含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・カノーネ",h:"おるふぇごーる・かのーね",e:"Orcust Brass Bombard",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\n手札から「オルフェゴール・カノーネ」以外の「オルフェゴール」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・スケルツォン",h:"おるふぇごーる・すけるつぉん",e:"Orcust Cymbal Skeleton",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外し、「オルフェゴール・スケルツォン」以外の自分の墓地の「オルフェゴール」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・ディヴェル",h:"おるふぇごーる・でぃう゛ぇる",e:"Orcust Harp Horror",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\nデッキから「オルフェゴール・ディヴェル」以外の「オルフェゴール」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:1,lk:[],ta:[],al:[]},
  {n:"星遺物－『星杖』",h:"せいいぶつ－『せいじょう』",e:"World Legacy - \"World Wand\"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2500,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：通常召喚したこのカードはEXデッキから特殊召喚されたモンスターとの戦闘では破壊されない。\n②：このカードが墓地へ送られた場合に発動できる。\n手札から「星遺物」モンスター１体を特殊召喚する。\n③：墓地のこのカードを除外し、除外されている自分の「オルフェゴール」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"雷源龍－サンダー・ドラゴン",h:"らいげんりゅう－さんだー・どらごん",e:"Thunder Dragonmatrix",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨て、自分フィールドの雷族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\nデッキから「雷源龍－サンダー・ドラゴン」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"雷電龍－サンダー・ドラゴン",h:"らいでんりゅう－さんだー・どらごん",e:"Thunder Dragondark",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「雷電龍－サンダー・ドラゴン」１体を手札に加える。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\nデッキから「雷電龍－サンダー・ドラゴン」以外の「サンダー・ドラゴン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"雷鳥龍－サンダー・ドラゴン",h:"らいちょうりゅう－さんだー・どらごん",e:"Thunder Dragonhawk",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1800,de:2200,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨てて発動できる。\n自分の墓地のモンスター及び除外されている自分のモンスターの中から、「雷鳥龍－サンダー・ドラゴン」以外の「サンダー・ドラゴン」モンスター１体を選んで特殊召喚する。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\n自分の手札を任意の数だけデッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけデッキからドローする。",li:1,lk:[],ta:[],al:[]},
  {n:"雷獣龍－サンダー・ドラゴン",h:"らいじゅうりゅう－さんだー・どらごん",e:"Thunder Dragonroar",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨てて発動できる。\n自分の墓地のカード及び除外されている自分のカードの中から、「雷獣龍－サンダー・ドラゴン」以外の「サンダー・ドラゴン」カード１枚を選んで手札に加える。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\nデッキから「サンダー・ドラゴン」モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。",li:2,lk:[],ta:[],al:[]},
  {n:"雷劫龍－サンダー・ドラゴン",h:"らいごうりゅう－さんだー・どらごん",e:"Thunder Dragonduo",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:0,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\n①：１ターンに１度、モンスターの効果が手札で発動した場合に発動する。\nこのカードの攻撃力はターン終了時まで３００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時、自分の墓地からカード１枚を除外して発動できる。\nデッキから雷族モンスター１体を手札に加える。\n③：相手エンドフェイズに、除外されている自分のカード１枚を対象として発動できる。\nそのカードをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀－ペンシルベル",h:"でびりちゃる－ぺんしるべる",e:"Impcantation Penciplume",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札の儀式モンスター１体を相手に見せて発動できる。\n「魔神儀－ペンシルベル」以外のデッキの「魔神儀」モンスター１体と手札のこのカードを特殊召喚する。\n②：このカードがデッキからの特殊召喚に成功した場合、自分の墓地の儀式モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n③：このカードがモンスターゾーンに存在する限り、自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀－ブックストーン",h:"でびりちゃる－ぶっくすとーん",e:"Impcantation Bookstone",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札の儀式魔法カード１枚を相手に見せて発動できる。\n「魔神儀－ブックストーン」以外のデッキの「魔神儀」モンスター１体と手札のこのカードを特殊召喚する。\n②：このカードがデッキからの特殊召喚に成功した場合、自分の墓地の儀式魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\n③：このカードがモンスターゾーンに存在する限り、自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"混源龍レヴィオニア",h:"こんげんりゅうれう゛ぃおにあ",e:"Chaos Dragon Levianeer",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:0,tx:"このカードは通常召喚できない。\n自分の墓地から光・闇属性モンスターを合計３体除外した場合に特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：この方法でこのカードが特殊召喚に成功した時に発動できる。\nその特殊召喚のために除外したモンスターの属性によって以下の効果を適用する。\nこのターン、このカードは攻撃できない。\n\n●光のみ：自分の墓地からモンスター１体を選んで守備表示で特殊召喚する。\n\n●闇のみ：相手の手札をランダムに１枚選んでデッキに戻す。\n\n●光と闇：フィールドのカードを２枚まで選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"幻妖フルドラ",h:"げんようふるどら",e:"Mystrick Hulder",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:2400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札を１枚捨てて発動できる。\n捨てたカードの種類によって以下の効果を適用する。\n\n●モンスター：自分の墓地から罠カード１枚を選んで手札に加える。\n\n●魔法：自分の墓地からモンスター１体を選んで手札に加える。\n\n●罠：自分の墓地から魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"光の精霊 ディアーナ",h:"ひかりのせいれい でぃあーな",e:"Diana the Light Spirit",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地から光属性モンスター１体を除外した場合に特殊召喚できる。\n①：相手エンドフェイズに発動する。\n自分は１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"失楽の魔女",h:"しつらくのまじょ",e:"Condemned Witch",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「禁じられた」速攻魔法カード１枚を手札に加える。\n②：相手メインフェイズに、このカードをリリースして発動できる。\nデッキから「失楽の魔女」以外の天使族・レベル４モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クマモール",h:"くまもーる",e:"Bearblocker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：このカードがモンスターゾーンに存在する限り、相手ターンの間、自分の魔法＆罠ゾーンにセットされたカードは効果では破壊されない。\n②：自分の魔法＆罠ゾーンにセットされたカードが相手の効果で破壊される度に発動する。\nこのカードの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴキポール",h:"ごきぽーる",e:"Gokipole",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合に発動できる。\nデッキからレベル４の昆虫族モンスター１体を手札に加える。\nこの効果で通常モンスターを加えた場合、さらにそのモンスターを手札から特殊召喚できる。\nその後、この効果で特殊召喚したモンスターの攻撃力以上の攻撃力を持つ、フィールドのモンスター１体を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トークンコレクター",h:"とーくんこれくたー",e:"Token Collector",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、トークンが特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードが特殊召喚に成功した場合に発動する。\nフィールドのトークンを全て破壊し、このカードの攻撃力は破壊したトークンの数×４００アップする。\n③：このカードがモンスターゾーンに存在する限り、お互いにトークンを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"通販売員",h:"つーまん・せーるすまん",e:"Two-for-One Team",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nお互いのプレイヤーは、それぞれ手札１枚を相手に見せる。\n見せたカードが同じ種類だった場合、その種類によって以下の効果を適用する。\n\n●モンスター：お互いのプレイヤーは、それぞれ見せたモンスターを特殊召喚できる。\n\n●魔法：お互いのプレイヤーは、それぞれデッキから２枚ドローする。\n\n●罠：お互いのプレイヤーは、それぞれデッキからカード２枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣エメラルド・イーグル",h:"さらまんぐれいとえめらるど・いーぐる",e:"Salamangreat Emerald Eagle",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"サイバース族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「転生炎獣の降臨」により降臨。\n①：このカードが自分フィールドの「転生炎獣エメラルド・イーグル」を使用して儀式召喚に成功した時に発動できる。\n相手フィールドの特殊召喚されたモンスターを全て破壊する。\n②：１ターンに１度、自分フィールドの「サラマングレイト」リンクモンスター１体をリリースして発動できる。\nこのターン、このカードは以下の効果を得る。\n●このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動する。\nその相手モンスターを破壊し、その元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・クロック・ドラゴン",h:"さいばーす・くろっく・どらごん",e:"Cyberse Clock Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"サイバース族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「クロック・ワイバーン」＋リンクモンスター１体以上\n①：このカードの融合召喚成功時に発動できる。\nその素材のリンクマーカーの合計分だけ、自分のデッキの上からカードを墓地へ送る。\n次のターンの終了時まで、他の自分のモンスターは攻撃できず、このカードの攻撃力はこの効果で墓地へ送った数×１０００アップする。\n②：自分フィールドにリンクモンスターが存在する限り、自分フィールドの他のモンスターを、相手は攻撃・効果の対象にできない。\n③：融合召喚したこのカードが相手の効果で墓地へ送られた場合に発動できる。\nデッキから魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"墓守の異能者",h:"はかもりのいのうしゃ",e:"Gravekeeper's Supernaturalist",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「墓守」モンスター×２\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力は、このカードの融合素材としたモンスターの元々のレベルの合計×１００アップする。\n②：フィールドに「王家の眠る谷－ネクロバレー」が存在する限り、このカード及び自分のフィールドゾーンのカードは効果では破壊されない。\n③：自分メインフェイズに発動できる。\nこのターンのエンドフェイズに、デッキから「墓守」モンスター１体または「ネクロバレー」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"超雷龍－サンダー・ドラゴン",h:"ちょうらいりゅう－さんだー・どらごん",e:"Thunder Dragon Colossus",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2400,tx:"「サンダー・ドラゴン」＋雷族モンスター\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●雷族モンスターの効果が手札で発動したターン、融合モンスター以外の自分フィールドの雷族の効果モンスター１体をリリースした場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードがモンスターゾーンに存在する限り、相手はドロー以外の方法でデッキからカードを手札に加える事ができない。\n②：このカードが戦闘・効果で破壊される場合、代わりに自分の墓地の雷族モンスター１体を除外できる。",li:1,lk:[],ta:[],al:[]},
  {n:"雷神龍－サンダー・ドラゴン",h:"らいじんりゅう－さんだー・どらごん",e:"Thunder Dragon Titan",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:3200,tx:"「サンダー・ドラゴン」モンスター×３\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●手札の雷族モンスター１体と、「雷神龍－サンダー・ドラゴン」以外の自分フィールドの雷族の融合モンスター１体を除外した場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：雷族モンスターの効果が手札で発動した時に発動できる（ダメージステップでも発動可能）。\nフィールドのカード１枚を選んで破壊する。\n②：このカードが効果で破壊される場合、代わりに自分の墓地のカード２枚を除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイプレクサ・キマイラ",h:"だいぷれくさ・きまいら",e:"Diplexer Chimera",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイバース族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:800,tx:"サイバース族モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分フィールドのサイバース族モンスター１体をリリースして発動できる。\nこのターンのバトルフェイズ中にお互いは魔法・罠カードの効果を発動できない。\n②：融合召喚したこのカードが墓地へ送られた場合、このカード以外の自分の墓地の、サイバース族モンスター１体と「サイバネット・フュージョン」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"クロック・スパルトイ",h:"くろっく・すぱるとい",e:"Clock Spartoi",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"サイバース族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「サイバネット・フュージョン」１枚を手札に加える。\n②：このカードのリンク先にモンスターが特殊召喚された場合、自分の墓地のレベル４以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:["下","右下"],ta:[],al:[]},
  {n:"転生炎獣ヒートライオ",h:"さらまんぐれいとひーとらいお",e:"Salamangreat Heatleo",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"炎属性の効果モンスター２体以上\n①：このカードがリンク召喚に成功した場合、相手の魔法＆罠ゾーンのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n②：このカードが「転生炎獣ヒートライオ」を素材としてリンク召喚されている場合、１ターンに１度、フィールドの表側表示モンスター１体と、自分の墓地のモンスター１体を対象として発動できる。\n対象のフィールドのモンスターの攻撃力は、ターン終了時まで対象の墓地のモンスターの攻撃力と同じになる。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"ダイナレスラー・キング・Tレッスル",h:"だいなれすらー・きんぐ・てぃーれっする",e:"Dinowrestler King T Wrextle",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"「ダイナレスラー」モンスター２体以上\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n③：相手バトルフェイズ開始時に、相手フィールドの攻撃表示モンスター１体を対象として発動できる。\nこのバトルフェイズ中、相手はそのモンスターで攻撃宣言しなければ、他のモンスターで攻撃宣言できない。\n攻撃宣言しなかった場合、対象のモンスターはそのバトルフェイズ終了時に破壊される。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"銀河眼の煌星竜",h:"ぎゃらくしーあいず・そるふれあ・どらごん",e:"Galaxy-Eyes Solflare Dragon",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"攻撃力２０００以上のモンスターを含む光属性モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、自分の墓地の「フォトン」モンスターまたは「ギャラクシー」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：相手メインフェイズに、「フォトン」カードと「ギャラクシー」カードの２枚、または「銀河眼の光子竜」１体を手札から捨て、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"オルフェゴール・ガラテア",h:"おるふぇごーる・がらてあ",e:"Galatea, the Orcust Automaton",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"「オルフェゴール」モンスターを含む効果モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：リンク状態のこのカードは戦闘では破壊されない。\n②：除外されている自分の機械族モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、デッキから「オルフェゴール」魔法・罠カード１枚を自分フィールドにセットできる。",li:1,lk:["右上","左下"],ta:[],al:[]},
  {n:"オルフェゴール・ロンギルス",h:"おるふぇごーる・ろんぎるす",e:"Longirsu, the Orcust Orchestrator",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"「オルフェゴール」モンスターを含む効果モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：リンク状態のこのカードは効果では破壊されない。\n②：除外されている自分の機械族モンスター２体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、リンク状態の相手モンスター１体を選んで墓地へ送る事ができる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:["左上","上","右下"],ta:[],al:[]},
  {n:"オルフェゴール・オーケストリオン",h:"おるふぇごーる・おーけすとりおん",e:"Orcustrion",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"「オルフェゴール」モンスターを含む効果モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：リンク状態のこのカードは戦闘・効果では破壊されない。\n②：除外されている自分の機械族モンスター３体を対象として発動できる。\nそのモンスターをデッキに戻す。\n相手フィールドにリンク状態の表側表示モンスターが存在する場合、それらのモンスターは、攻撃力・守備力が０になり、効果は無効化される。",li:"",lk:["上","右上","左下","下"],ta:[],al:[]},
  {n:"ヴェルスパーダ・パラディオン",h:"う゛ぇるすぱーだ・ぱらでぃおん",e:"Crusadia Spatha",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:500,de:"-",tx:"「パラディオン」モンスターを含む効果モンスター２体\n①：このカードの攻撃力は、このカードのリンク先のモンスターの元々の攻撃力分アップする。\n②：このカードのリンク先のモンスターは攻撃できない。\n③：１ターンに１度、このカードのリンク先に効果モンスターが特殊召喚された場合、このカード以外の自分または相手のメインモンスターゾーンのモンスター１体を対象として発動できる。\nそのモンスターの位置を、他のメインモンスターゾーンに移動する（そのモンスターから見て相手のフィールドには移動できない）。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"空牙団の大義 フォルゴ",h:"くうがだんのたいぎ ふぉるご",e:"Folgo, Justice Fur Hire",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"種族が異なるモンスター３体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\nこのカードはリンク素材にできない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nそのリンク素材としたモンスター３体とは異なる種族の「空牙団」モンスター１体をデッキから守備表示で特殊召喚する。\n②：相手フィールドのカードが戦闘・効果で破壊された場合に発動できる。\n自分はデッキから１枚ドローする。\nその後、自分フィールドの「空牙団」モンスターが３種類以上の場合、自分はデッキから２枚ドローする。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"竜絶蘭",h:"りゅうぜつらん",e:"Agave Dragon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"幻竜族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"トークン以外のモンスター２体以上\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nお互いの墓地のモンスターの種族とその数によって以下の効果を適用する。\n\n●ドラゴン族：その数×１００ダメージを相手に与える。\n\n●恐竜族：このカードの攻撃力はその数×２００アップする。\n\n●海竜族：相手フィールドの全てのモンスターの攻撃力はその数×３００ダウンする。\n\n●幻竜族：自分はその数×４００LP回復する。",li:"",lk:["左","右","左下","右下"],ta:[],al:[]},
  {n:"常夏のカミナリサマー",h:"とこなつのかみなりさまー",e:"Some Summer Summoner",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"雷族モンスター２体\n①：相手ターンに１度、手札を１枚捨て、リンクモンスター以外の自分の墓地の雷族モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"サイバネット・フュージョン",h:"さいばねっと・ふゅーじょん",e:"Cynet Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、サイバース族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nEXモンスターゾーンに自分のモンスターが存在しない場合、自分の墓地のサイバース族リンクモンスター（１体まで）を除外して融合素材とする事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣の聖域",h:"さらまんぐれいと・さんくちゅあり",e:"Salamangreat Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、自分が「サラマングレイト」リンクモンスターをリンク召喚する場合、自分フィールドの同名の「サラマングレイト」リンクモンスター１体のみを素材としてリンク召喚できる。\n②：自分のモンスターが戦闘を行うダメージ計算時に、１０００LPを払い、自分フィールドのリンクモンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、そのモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣の降臨",h:"さらまんぐれいとのこうりん",e:"Rise of the Salamangreat",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「サラマングレイト」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「サラマングレイト」儀式モンスター１体を儀式召喚する。\n自分フィールドに炎属性リンクモンスターが存在する場合、自分の墓地の「サラマングレイト」モンスターもリリースの代わりにデッキに戻す事ができる。\n②：このカードが相手の効果で破壊された場合に発動できる。\n手札から「転生炎獣エメラルド・イーグル」１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣の意志",h:"さらまんぐれいと・はーと",e:"Will of the Salamangreat",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札・墓地から「サラマングレイト」モンスター１体を選んで特殊召喚する。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自身と同名のモンスターを素材としてリンク召喚した自分フィールドの「サラマングレイト」リンクモンスター１体を対象として発動できる。\nそのモンスターのリンクマーカーの数まで、自分の手札・墓地から「サラマングレイト」モンスターを選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワールド・ダイナ・レスリング",h:"わーるど・だいな・れすりんぐ",e:"World Dino Wrestling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ダイナレスラー」モンスターが存在する場合、お互いのプレイヤーはバトルフェイズにモンスター１体でしか攻撃できない。\n②：自分の「ダイナレスラー」モンスターの攻撃力は、相手モンスターに攻撃するダメージ計算時のみ２００アップする。\n③：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、墓地のこのカードを除外して発動できる。\nデッキから「ダイナレスラー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロバレーの玉座",h:"ねくろばれーのぎょくざ",e:"Necrovalley Throne",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●デッキから「墓守」モンスター１体を手札に加える。\n\n●手札から「墓守」モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河天翔",h:"ぎゃらくしー・とらんさー",e:"Galaxy Trance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「フォトン」モンスター及び「ギャラクシー」モンスターしか召喚・特殊召喚できない。\n①：２０００LPを払い、自分の墓地の「フォトン」モンスター１体を対象として発動できる。\nそのモンスターと同じレベルを持つデッキの「ギャラクシー」モンスター１体と対象の墓地のモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は２０００になり、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・バベル",h:"おるふぇごーる・ばべる",e:"Orcustrated Babel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、元々のカード名に「オルフェゴール」を含む、自分フィールドのリンクモンスター及び自分の墓地のモンスターが発動する効果は、相手ターンでも発動できる効果になる。\n②：このカードが墓地に存在する場合、手札を１枚墓地へ送って発動できる。\n墓地のこのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・プライム",h:"おるふぇごーる・ぷらいむ",e:"Orcustrated Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、「オルフェゴール」モンスターまたは「星遺物」モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・アインザッツ",h:"おるふぇごーる・あいんざっつ",e:"Orcustrated Einsatz",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから「オルフェゴール」モンスターまたは「星遺物」モンスター１体を選び、墓地へ送るか除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"雷龍融合",h:"さんだー・どらごん・ふゅーじょん",e:"Thunder Dragon Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のフィールド・墓地のモンスター及び除外されている自分のモンスターの中から、雷族の融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外して発動できる。\nデッキから雷族モンスター１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀術式－ベクタードブラスト",h:"せんとうじゅつしき－べくたーどぶらすと",e:"Sky Striker Maneuver - Vector Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合に発動できる。\nお互いのデッキの上からカードを２枚墓地へ送る。\nその後、自分の墓地に魔法カードが３枚以上存在する場合、EXモンスターゾーンの相手モンスターを全て持ち主のデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"G・ボールパーク",h:"じゃいあんと・ぼーるぱーく",e:"Giant Ballpark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：ダメージ計算時に発動できる。\nその戦闘で発生するお互いの戦闘ダメージを０にし、自分のデッキからレベル４以下の昆虫族モンスター１体を墓地へ送る。\nこの効果で通常モンスターが墓地へ送られた場合、さらにその同名モンスターを自分の手札・デッキ・墓地から任意の数だけ選んで特殊召喚できる。\n②：自分フィールドのモンスターが相手の効果で墓地へ送られた場合に発動できる。\n自分の墓地から昆虫族の通常モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"深淵の宣告者",h:"しんえんのせんこくしゃ",e:"Herald of the Abyss",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１５００LPを払い、種族と属性を１つずつ宣言して発動できる。\n宣言した種族・属性のモンスターが相手フィールドに存在する場合、相手はそのモンスター１体を墓地へ送らなければならない。\nこのターン、相手はそのモンスター及びその同名モンスターのモンスター効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"コンセントレイト",h:"こんせんとれいと",e:"Concentrating Current",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時までその守備力分アップする。\nこのカードを発動するターン、対象のモンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"おろかな重葬",h:"おろかなじゅうそう",e:"Extra-Foolish Burial",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は魔法・罠カードをセットできない。\n①：LPを半分払って発動できる。\nEXデッキからモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"パラレル・パンツァー",h:"ぱられる・ぱんつぁー",e:"Parallel Panzer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"リンクモンスターにのみ装備可能。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n装備モンスターの位置を、そのモンスターのリンク先のメインモンスターゾーンに移動する（そのモンスターから見て相手のフィールドには移動できない）。\n②：装備されているこのカードを墓地へ送って発動できる。\nこのカードを装備していたフィールドのモンスターと同じ縦列のカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サラマングレイト・ギフト",h:"さらまんぐれいと・ぎふと",e:"Salamangreat Gift",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札から「サラマングレイト」モンスター１体を捨てて発動できる。\nデッキから「サラマングレイト」モンスター１体を墓地へ送る。\nその後、自分はデッキから１枚ドローする。\n②：自身と同名のモンスターを素材としてリンク召喚した「サラマングレイト」リンクモンスターが自分フィールドに存在する場合、手札から「サラマングレイト」モンスター１体を捨てて発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロバレーの神殿",h:"ねくろばれーのしんでん",e:"Necrovalley Temple",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「墓守」モンスター及び「王家の眠る谷－ネクロバレー」がフィールドに存在する限り、相手フィールドのモンスターの攻撃力・守備力は５００ダウンする。\n②：自分のフィールドゾーンにカードが存在しない場合、１ターンに１度、自分・相手のメインフェイズに発動できる。\n自分の手札・墓地から「王家の眠る谷－ネクロバレー」１枚を選んで発動する。\n③：このカードが相手の効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「ネクロバレーの神殿」以外の「ネクロバレー」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"永遠なる銀河",h:"えたーなる・ぎゃらくしー",e:"Eternal Galaxy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「フォトン」モンスターまたは「ギャラクシー」モンスターが存在する場合、自分フィールドのXモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが４つ高い、「フォトン」Xモンスターまたは「ギャラクシー」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・アタック",h:"おるふぇごーる・あたっく",e:"Orcustrated Attack",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手のモンスターの攻撃宣言時に、自分フィールドの、「オルフェゴール」モンスターまたは「星遺物」モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・コア",h:"おるふぇごーる・こあ",e:"Orcustrated Core",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分のフィールド・墓地からモンスター１体を除外し、「オルフェゴール・コア」以外の自分フィールドの、「オルフェゴール」カードまたは「星遺物」カード１枚を対象として発動できる。\nこのターン、そのカードは効果の対象にならない。\n②：このカード以外の自分フィールドの、「オルフェゴール」カードまたは「星遺物」カードが戦闘・効果で破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"百雷のサンダー・ドラゴン",h:"ひゃくらいのさんだー・どらごん",e:"Thunder Dragons' Hundred Thunders",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の雷族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、その同名モンスターを自分の墓地から可能な限り特殊召喚できる。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。\nこの効果で特殊召喚したモンスターがモンスターゾーンに存在する限り、自分は雷族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"雷龍放電",h:"さんだー・どらごん・すとりーま",e:"Thunder Dragon Discharge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分の雷族モンスターの効果の発動は無効化されない。\n②：１ターンに１度、自分フィールドに「サンダー・ドラゴン」モンスターが召喚・特殊召喚された場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nデッキから雷族モンスター１体を除外し、対象のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"クローラー・パラディオン",h:"くろーらー・ぱらでぃおん",e:"Crusadia Krawler",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（昆虫族・地・星２・攻３００／守２１００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果でこのカードがリンクモンスターのリンク先への特殊召喚に成功した場合に発動できる。\nデッキから「星遺物」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"死魂融合",h:"ねくろ・ふゅーじょん",e:"Necro Fusion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地から、融合モンスターカードによって決められた融合素材モンスターを裏側表示で除外し、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"インヴィンシブル・ヘイロー",h:"いんう゛ぃんしぶる・へいろー",e:"Invincibility Barrier",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの表側表示の、儀式・融合・S・X・P・リンクモンスター１体を除外して発動できる。\nこのターン、このカードが魔法＆罠ゾーンに存在する間、除外したモンスターと同じ種類（儀式・融合・S・X・P・リンク）のモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"通行増税",h:"つうこうぞうぜい",e:"Toll Hike",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：お互いのプレイヤーは手札を１枚墓地へ送らなければ攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トラップトリック",h:"とらっぷとりっく",e:"Trap Trick",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「トラップトリック」以外の通常罠カード１枚を除外し、その同名カード１枚をデッキから選んで自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。\nこのカードの発動後、ターン終了時まで自分は罠カードを１枚しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"一色即発",h:"いっしょくそくはつ",e:"The Revenge of the Normal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドのモンスターの数まで、手札からレベル４以下のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"素早きは三文の徳",h:"すばやきはさんもんのとく",e:"Subsurface Stage Divers",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターがトークン以外の同名モンスター３体のみの場合に発動できる。\nデッキから３体の同名モンスターを手札に加える。\nこのカードの発動後、ターン終了時まで自分はこの効果で手札に加えたモンスター及びその同名モンスターを通常召喚・特殊召喚できず、そのモンスター効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"闇鋼龍 ダークネスメタル",h:"くろがねりゅう だーくねすめたる",e:"Darkness Metal, the Dragon of Dark Steel",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"同じ種族・属性の効果モンスター２体以上\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分の墓地のモンスターまたは除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、フィールドから離れた場合に持ち主のデッキの一番下に戻る。\nこの効果の発動後、ターン終了時まで自分はリンクモンスターを特殊召喚できない。",li:"",lk:["上","右下","下","左下"],ta:[],al:[]},
  {n:"氷獄龍 トリシューラ",h:"ひょうごくりゅう　とりしゅーら",e:"Trishula, the Dragon of Icy Imprisonment",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:2000,tx:"カード名が異なるモンスター×３\nこのカードは自分の手札・フィールドのモンスターのみを素材とした融合召喚及び以下の方法でのみEXデッキから特殊召喚できる。\n●自分フィールドの上記カードを除外した場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：元々の種族がドラゴン族のモンスターのみを素材としてこのカードが特殊召喚に成功した場合に発動できる。\n自分のデッキ・相手のデッキの一番上・相手のEXデッキの順に確認してそれぞれ１枚ずつ除外する。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"熾天龍 ジャッジメント",h:"してんりゅう　じゃっじめんと",e:"Judgment, the Dragon of Heaven",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードをS召喚する場合、S素材モンスターは全て同じ属性のモンスターでなければならない。\n①：自分の墓地にチューナーが４種類以上存在し、このカードがS召喚されている場合、１ターンに１度、LPを半分払って発動できる。\nこのカード以外のフィールドのカードを全て破壊する。\nこの効果の発動後、ターン終了時まで自分はドラゴン族モンスターしか特殊召喚できない。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを４枚除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"撃滅龍 ダーク・アームド",h:"げきめつりゅう　だーく・あーむど",e:"Dark Armed, the Dragon of Annihilation",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:1000,tx:"レベル７モンスター×２体以上\n「撃滅龍 ダーク・アームド」は１ターンに１度、自分の墓地の闇属性モンスターが５体のみの場合、自分フィールドのレベル５以上のドラゴン族・闇属性モンスターの上に重ねてX召喚する事もできる。\n①：このカードのX素材を１つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、自分の墓地からカード１枚を選んで除外する。\nこの効果の発動後、ターン終了時までこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"終焉龍 カオス・エンペラー",h:"しゅうえんりゅう　かおす・えんぺらー",e:"Chaos Emperor, the Dragon of Armageddon",t:"monster",a:"闇",l:8,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：１０００LPを払い、除外されている自分のドラゴン族モンスター１体を対象として発動できる。\nこのカードを破壊し、そのモンスターを手札に加える。",tr:"ドラゴン族",mo:["特殊召喚","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n「終焉龍 カオス・エンペラー」は１ターンに１度、自分の墓地の光属性と闇属性のモンスターを１体ずつ除外した場合のみ、手札・EXデッキから特殊召喚できる。\n①：１ターンに１度、LPを半分払って発動できる。\nEXモンスターゾーン以外の自分フィールドのカードを全て墓地へ送り、墓地へ送った数まで相手フィールドのカードを選んで墓地へ送る。\nその後、相手の墓地へ送った数×３００ダメージを相手に与える。\n②：特殊召喚した表側表示のこのカードはフィールドから離れた場合にデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"コンタクト・ゲート",h:"こんたくと・げーと",e:"Contact Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地から「N」モンスター２種類を１体ずつ除外して発動できる。\n自分の手札・デッキ・墓地から「N」モンスター２体を選んで特殊召喚する（同名カードは１枚まで）。\nこの効果を発動したターン、自分は融合モンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドの表側表示の、「E・HERO ネオス」を融合素材とする融合モンスターがEXデッキに戻った場合、墓地のこのカードを除外して発動できる。\n除外されている自分の「N」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"一点着地",h:"いってんちゃくち",e:"Pinpoint Landing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分または相手の手札から自分フィールドに、モンスター１体のみが特殊召喚された場合に発動できる。\n自分はデッキから１枚ドローする。\n②：自分ターンにこのカードの①の効果でドローしていない場合、そのターンのエンドフェイズにこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のビッグフット",h:"みかいいきのびっぐふっと",e:"Danger! Bigfoot!",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のビッグフット」以外だった場合、さらに手札から「未界域のビッグフット」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のネッシー",h:"みかいいきのねっしー",e:"Danger! Nessie!",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:2800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のネッシー」以外だった場合、さらに手札から「未界域のネッシー」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合に発動できる。\nデッキから「未界域のネッシー」以外の「未界域」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のチュパカブラ",h:"みかいいきのちゅぱかぶら",e:"Danger! Chupacabra!",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のチュパカブラ」以外だった場合、さらに手札から「未界域のチュパカブラ」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合、「未界域のチュパカブラ」以外の自分の墓地の「未界域」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のジャッカロープ",h:"みかいいきのじゃっかろーぷ",e:"Danger!? Jackalope?",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のジャッカロープ」以外だった場合、さらに手札から「未界域のジャッカロープ」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合に発動できる。\nデッキから「未界域のジャッカロープ」以外の「未界域」モンスター１体を守備表示で特殊召喚する。",li:2,lk:[],ta:[],al:[]},
  {n:"未界域－ユーマリア大陸",h:"みかいいき－ゆーまりあたいりく",e:"Realm of Danger!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分フィールドの「未界域」モンスターは特殊召喚されたターンには相手の効果の対象にならない。\n②：１ターンに１度、自分フィールドの「未界域」モンスター１体を対象として発動できる。\nそのモンスターとこのカードが自分フィールドに表側表示で存在する限り、そのモンスターは直接攻撃でき、攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域の危険地帯",h:"みかいいきのきけんちたい",e:"Danger! Zone",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分はデッキから３枚ドローする。\nその後、手札から「未界域」カード１枚以上を含むカード２枚を捨てる。\n手札に「未界域」カードが無い場合、手札を全て公開し、デッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士コルネウス",h:"せいきしこるねうす",e:"Noble Knight Custennin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「聖剣」装備魔法カードが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードを素材としてS・X・リンク召喚した「聖騎士」モンスターは以下の効果を得る。\n●この特殊召喚に成功した場合に発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにモンスター１体を召喚できる。\nこの効果の発動後、ターン終了時まで自分は「聖騎士」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"神聖騎士王コルネウス",h:"しんせいきしおうこるねうす",e:"Sacred Noble Knight of King Custennin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2500,tx:"レベル４「聖騎士」モンスター×２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を任意の数だけ取り除き、その数だけ相手フィールドのカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n「神聖騎士王コルネウス」以外の「聖騎士」Xモンスター１体をX召喚扱いとしてEXデッキから特殊召喚し、墓地のこのカードを下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士ペリノア",h:"せいきしぺりのあ",e:"Noble Knight Pellinore",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードに装備されている「聖剣」装備魔法カード１枚と相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのカードを破壊する。\nその後、自分はデッキから１枚ドローする。\nこの効果の発動後、ターン終了時までこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖剣クラレント",h:"せいけんくられんと",e:"Noble Arms - Clarent",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「聖剣クラレント」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、５００LPを払って発動できる。\nこのターン、装備モンスターは直接攻撃できる。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合、自分フィールドの戦士族の「聖騎士」モンスター１体を対象として発動できる。\nその自分の戦士族の「聖騎士」モンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"超装甲兵器ロボ ブラックアイアンG",h:"ちょうそうこうへいきろぼ　ぶらっくあいあんじー",e:"Super Armored Robot Armed Black Iron \"C\"",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地に昆虫族の同名モンスターが３体存在する場合、その内の任意の数だけ対象として発動できる。\nこのカードを手札から特殊召喚する。\nその後、対象のモンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの効果で装備しているモンスターカード１枚を墓地へ送って発動できる。\n墓地へ送ったそのカードの攻撃力以上の攻撃力を持つ、相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"G戦隊 シャインブラック",h:"じーせんたい　しゃいんぶらっく",e:"Shiny Black \"C\" Squadder",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"黒光りするスーツを身にまとい、戦場を駆け回る“黒の閃光”・・・。\n\n影あるところにG戦隊あり！\n隠された飛行能力を駆使して巨大なモンスターにも立ち向かうぞ！！",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物スパイダー・オーキッド",h:"ぷれでたー・ぷらんつすぱいだー・おーきっど",e:"Predaplant Spider Orchid",t:"monster",a:"闇",l:1,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：このカードを発動したターンの自分メインフェイズに、このカード以外の魔法＆罠ゾーンの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"植物族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功したターンのエンドフェイズに、手札から植物族モンスター１体を捨てて発動できる。\nデッキからレベル４以下の植物族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・マイスター",h:"ですとーい・まいすたー",e:"Frightfur Meister",t:"monster",a:"闇",l:8,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル４以下の、「デストーイ」、「ファーニマル」、「エッジインプ」モンスターのいずれか１体をリリースして発動できる。\nリリースしたモンスターと同じレベルでカード名が異なる悪魔族モンスター１体をデッキから特殊召喚する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキからレベル４以下の、「デストーイ」、「ファーニマル」、「エッジインプ」モンスターのいずれか１体を特殊召喚する。\nこのターン、自分は融合モンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドの悪魔族モンスター２体以上をリリースして発動できる。\nその元々のレベルの合計と同じレベルを持つ「デストーイ」融合モンスター１体を、融合召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・ドリット",h:"わるきゅーれ・どりっと",e:"Valkyrie Dritte",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ワルキューレ・ドリット」以外の「ワルキューレ」カード１枚を手札に加える。\n②：このカードの攻撃力は、除外されている相手モンスターの数×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・ツヴァイト",h:"わるきゅーれ・つう゛ぁいと",e:"Valkyrie Zweite",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：このカードが戦闘を行ったダメージ計算後に、自分の墓地の永続魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・アルテスト",h:"わるきゅーれ・あるてすと",e:"Valkyrie Erste",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが魔法カードの効果で手札から特殊召喚に成功した場合、自分の墓地の「時の女神の悪戯」１枚を対象として発動できる。\nそのカードを手札に加える。\n②：自分フィールドに「ワルキューレ・アルテスト」以外の「ワルキューレ」モンスターが存在する場合に発動できる。\n相手の墓地からモンスター１体を選んで除外する。\nその後、このカードの攻撃力はターン終了時まで、除外したモンスターの元々の攻撃力と同じになる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・ヴリュンヒルデ",h:"わるきゅーれ・う゛りゅんひるで",e:"Valkyrie Brunhilde",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:2000,tx:"①：このカードの攻撃力は、相手フィールドのモンスターの数×５００アップする。\n②：このカードは相手の魔法カードの効果を受けない。\n③：相手モンスターの攻撃宣言時に発動できる。\nこのカードの守備力を１０００ダウンし、このターン、自分の「ワルキューレ」モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"運命の戦車",h:"ふぉーちゅん・ちゃりおっと",e:"Fortune Chariot",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名はルール上「ワルキューレ」カードとしても扱う。\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの天使族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"Walkuren Ritt",h:"わるきゅーれん　りっと",e:"Ride of the Valkyries",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札から「ワルキューレ」モンスターを任意の数だけ特殊召喚する（同名カードは１枚まで）。\nこの効果で３体以上のモンスターを特殊召喚した場合、次のターンの終了時まで自分が受ける戦闘ダメージは０になる。\nこのカードを発動したターンのエンドフェイズに、自分フィールドのモンスターは全て持ち主のデッキに戻る。\n②：墓地のこのカードを除外して発動できる。\nデッキから「時の女神の悪戯」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"時の女神の悪戯",h:"ときのめがみのいたずら",e:"Mischief of the Time Goddess",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードはチェーン発動できず、このカードの発動に対して魔法・罠・モンスターの効果は発動できない。\n①：自分フィールドのモンスターが「ワルキューレ」モンスターのみの場合、自分バトルフェイズ終了時に発動できる。\nこのカードを墓地へ送る。\n次の自分ターンのバトルフェイズ開始時までターンをスキップする。\nそのターンの終了時まで自分は「時の女神の悪戯」を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"女神スクルドの託宣",h:"めがみすくるどのたくせん",e:"Goddess Skuld's Oracle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、自分フィールドのモンスターが「ワルキューレ」モンスターのみの場合には、デッキから「女神ヴェルダンディの導き」１枚を手札に加える事ができる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n相手のデッキの上からカードを３枚確認し、好きな順番でデッキの上に戻す。\nこの効果の発動後、ターン終了時まで自分は天使族モンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"女神ヴェルダンディの導き",h:"めがみう゛ぇるだんでぃのみちびき",e:"Goddess Verdande's Guidance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、自分フィールドのモンスターが「ワルキューレ」モンスターのみの場合には、デッキから「女神ウルドの裁断」１枚を手札に加える事ができる。\n②：１ターンに１度、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手のデッキの一番上のカードをお互いに確認し、宣言した種類のカードだった場合、相手はそのカードを自身のフィールドにセットする。\n違った場合、またはセットできない場合、相手はそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"女神ウルドの裁断",h:"めがみうるどのさいだん",e:"Goddess Urd's Verdict",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「ワルキューレ」モンスターは相手の効果の対象にならず、相手の効果では破壊されない。\n②：１ターンに１度、カード名を１つ宣言し、相手フィールドにセットされたカード１枚を対象として発動できる。\nそのセットされたカードをお互いに確認し、宣言したカードだった場合、そのカードを除外する。\n違った場合、自分フィールドのカード１枚を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"忍者マスター SAIZO",h:"にんじゃますたー　さいぞー",e:"Ninja Grandmaster Saizo",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「忍者」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先にモンスターが存在する限り、このカードは攻撃対象にならず、相手の効果の対象にもならない。\n②：自分メインフェイズに発動できる。\nデッキから「忍法」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"黄色い忍者",h:"いえろーにんじゃ",e:"Yellow Ninja",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・リバースした場合に発動できる。\n手札からレベル４以下の「忍者」モンスター１体を表側攻撃表示または裏側守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「忍者」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"黄竜の忍者",h:"こうりゅうのにんじゃ",e:"Yellow Dragon Ninja",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3000,de:1500,tx:"このカードは「忍者」モンスターまたは「忍法」カードの効果でしか特殊召喚できない。\n①：１ターンに１度、手札及び自分フィールドの表側表示のカードの中から、「忍者」モンスター１体と「忍法」カード１枚を墓地へ送り、フィールドの魔法・罠カードを２枚まで対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"隠れ里－忍法修練の地",h:"かくれざと－にんぽうしゅうれんのち",e:"Hidden Village of Ninjitsu Arts",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「忍者」モンスターが召喚・反転召喚・特殊召喚された場合、自分の墓地の、「忍者」モンスター１体または「忍法」カード１枚を対象として発動できる。\nそのカードを手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果の発動ができない。\n②：自分フィールドの、「忍者」モンスターまたは「忍法」カードが戦闘または相手の効果で破壊される場合、代わりに自分の墓地の「忍者」モンスター１体を除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"忍法 妖変化の術",h:"にんぽう　ようへんげのじゅつ",e:"Ninjitsu Art of Mirage-Transformation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「忍者」モンスター１体をリリースし、相手の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはこのカードが表側表示で存在する間、「忍者」モンスターとしても扱う。\nこのカードがフィールドから離れた時にそのモンスターは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔界の警邏課デスポリス",h:"まかいのけいらかですぽりす",e:"Beat Cop from the Underworld",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"モンスター２体\n①：カード名が異なる闇属性モンスター２体を素材としてリンク召喚したこのカードは以下の効果を得る。\n\n●自分フィールドのモンスター１体をリリースし、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードに警邏カウンターを１つ置く。\nこのカード名のこの効果は１ターンに１度しか使用できない。\n警邏カウンターが置かれたカードが戦闘・効果で破壊される場合、代わりにそのカードの警邏カウンターを１つ取り除く。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"ネフティスの悟り手",h:"ねふてぃすのさとりて",e:"Matriarch of Nephthys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネフティスの悟り手」以外の自分の墓地のレベル４以下の「ネフティス」モンスター１体を対象として発動できる。\n手札を１枚選んで破壊し、対象のモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの祈り手",h:"ねふてぃすのいのりて",e:"Disciple of Nephthys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札を１枚選んで破壊し、デッキから「ネフティスの祈り手」以外の「ネフティス」モンスター１体を手札に加える。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nデッキから「ネフティス」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの語り手",h:"ねふてぃすのかたりて",e:"Chronicler of Nephthys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネフティスの語り手」以外の自分の墓地の「ネフティス」カード１枚を対象として発動できる。\n手札を１枚選んで破壊し、対象のカードを手札に加える。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\n自分の墓地から「ネフティスの語り手」以外の「ネフティス」カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの護り手",h:"ねふてぃすのまもりて",e:"Defender of Nephthys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札を１枚選んで破壊し、手札から「ネフティスの護り手」以外のレベル４以下の「ネフティス」モンスター１体を特殊召喚する。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nデッキから「ネフティスの護り手」以外の「ネフティス」モンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの祀り手",h:"ねふてぃすのまつりて",e:"Devotee of Nephthys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:1200,de:1200,tx:"「ネフティスの輪廻」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\nデッキから「ネフティス」モンスター１体を特殊召喚する。\nこの効果を発動したターンのエンドフェイズにこのカードは破壊される。\n②：このカードが墓地に存在する場合に発動できる。\n手札の「ネフティス」カード１枚を選んで破壊し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの蒼凰神",h:"ねふてぃすのそうおうしん",e:"Cerulean Sacred Phoenix of Nephthys",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:1000,tx:"「ネフティスの輪廻」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札及び自分フィールドの表側表示のカードの中から、「ネフティス」カードを任意の数だけ選んで破壊する。\nその後、破壊した数だけ相手フィールドのモンスターを選んで破壊する。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"守護神－ネフティス",h:"しゅごしん－ねふてぃす",e:"Nephthys, the Sacred Preserver",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"「ネフティス」モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚している場合、以下の効果から１つを選択して発動できる。\n\n●デッキから鳥獣族・レベル８モンスター１体を手札に加える。\nその後、自分の墓地から儀式魔法カード１枚を選んで手札に加える事ができる。\n\n●このカードのリンク先の「ネフティス」モンスター１体を選んで破壊し、そのモンスターと元々のカード名が異なる「ネフティス」モンスター１体を自分の墓地から選んで効果を無効にして特殊召喚する。",li:"",lk:["下","右下"],ta:[],al:[]},
  {n:"焔凰神－ネフティス",h:"えんおうしん－ねふてぃす",e:"Nephthys, the Sacred Flame",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"儀式モンスターを含むモンスター２体以上\n①：このカードは、このカードのリンク素材とした儀式モンスターの数によって以下の効果を得る。\n\n●１体以上：このカードは戦闘では破壊されない。\n\n●２体以上：このカードの攻撃力は１２００アップし、効果では破壊されない。\n\n●３体：このカードの攻撃力は１２００アップし、効果の対象にならない。\n\n②：このカードがEXモンスターゾーンに存在する限り、相手はメインモンスターゾーンの「ネフティス」モンスターを攻撃対象に選択できない。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"ネフティスの輪廻",h:"ねふてぃすのりんね",e:"Rebirth of Nephthys",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ネフティス」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「ネフティス」儀式モンスター１体を儀式召喚する。\n「ネフティスの祀り手」または「ネフティスの蒼凰神」をリリースして儀式召喚した場合には、さらにフィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの希望",h:"ねふてぃすのきぼう",e:"Last Hope of Nephthys",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ネフティス」カード１枚と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの覚醒",h:"ねふてぃすのかくせい",e:"Awakening of Nephthys",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「ネフティス」モンスターの攻撃力は３００アップする。\n②：魔法＆罠ゾーンの表側表示のこのカードが効果で破壊され墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「ネフティス」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・ランプ",h:"ぷらんきっず・らんぷ",e:"Prank-Kids Lampsies",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが「プランキッズ」モンスターの、融合素材またはリンク素材として墓地へ送られた場合に発動できる。\n相手に５００ダメージを与える。\nその後、手札・デッキから「プランキッズ・ランプ」以外の「プランキッズ」モンスター１体を守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・ドロップ",h:"ぷらんきっず・どろっぷ",e:"Prank-Kids Dropsies",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが「プランキッズ」モンスターの、融合素材またはリンク素材として墓地へ送られた場合に発動できる。\n自分は１０００LP回復する。\nその後、手札・デッキから「プランキッズ・ドロップ」以外の「プランキッズ」モンスター１体を守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・パルス",h:"ぷらんきっず・ぱるす",e:"Prank-Kids Fansies",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが「プランキッズ」モンスターの、融合素材またはリンク素材として墓地へ送られた場合に発動できる。\nデッキから「プランキッズ・パルス」以外の「プランキッズ」カード１枚を墓地へ送る。\nその後、手札・デッキから「プランキッズ・パルス」以外の「プランキッズ」モンスター１体を守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・ロケット",h:"ぷらんきっず・ろけっと",e:"Prank-Kids Rocket Ride",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:0,tx:"「プランキッズ」モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合に発動できる。\nこのターンこのカードは、攻撃力が１０００ダウンし、さらに直接攻撃もできる。\n②：このカードをリリースし、融合モンスター以外の自分の墓地の「プランキッズ」モンスター２体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・ウェザー",h:"ぷらんきっず・うぇざー",e:"Prank-Kids Weather Washer",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:0,tx:"「プランキッズ」モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の「プランキッズ」モンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：相手ターンにこのカードをリリースし、融合モンスター以外の自分の墓地の「プランキッズ」モンスター２体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・ハウスバトラー",h:"ぷらんきっず・はうすばとらー",e:"Prank-Kids Battle Butler",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"「プランキッズ・ランプ」＋「プランキッズ・ドロップ」＋「プランキッズ・パルス」\nこのカードは融合召喚でしか特殊召喚できない。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n相手フィールドのモンスターを全て破壊する。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手によって墓地へ送られた場合、融合モンスター以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・ドゥードゥル",h:"ぷらんきっず・どぅーどぅる",e:"Prank-Kids Dodo-Doodle-Doo",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「プランキッズ」モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「プランキッズ」魔法・罠カード１枚を手札に加える。\n②：このカードをリリースし、リンクモンスター以外の自分の墓地の「プランキッズ」カード２枚を対象として発動できる（同名カードは１枚まで）。\nそのカードを手札に加える。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"プランキッズ・バウワウ",h:"ぷらんきっず・ばうわう",e:"Prank-Kids Bow-Wow-Bark",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「プランキッズ」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先の「プランキッズ」モンスターの攻撃力は１０００アップする。\n②：相手ターンにこのカードをリリースし、リンクモンスター以外の自分の墓地の「プランキッズ」カード２枚を対象として発動できる（同名カードは１枚まで）。\nそのカードを手札に加える。\nまた、このターン自分フィールドの「プランキッズ」モンスターは相手の効果では破壊されない。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"プランキッズ・ロアゴン",h:"ぷらんきっず・ろあごん",e:"Prank-Kids Rip-Roarin-Roaster",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"「プランキッズ」モンスター２体以上\nこのカードはリンク召喚でしか特殊召喚できない。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手によって墓地へ送られた場合、リンクモンスター以外の自分の墓地のカード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"プランキッズ・ハウス",h:"ぷらんきっず・はうす",e:"Prank-Kids Place",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「プランキッズ」モンスター１体を手札に加える事ができる。\n②：１ターンに１度、自分が「プランキッズ」融合モンスターの融合召喚に成功した場合に発動できる。\n自分フィールドの全てのモンスターの攻撃力は５００アップする。\n③：１ターンに１度、自分が「プランキッズ」リンクモンスターのリンク召喚に成功した場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・プランク",h:"ぷらんきっず・ぷらんく",e:"Prank-Kids Pranks",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から「プランキッズ」カード１枚を捨てて発動できる。\n自分フィールドに「プランキッズトークン」（炎族・炎・星１・攻／守０）１体を特殊召喚する。\nこのトークンはリリースできない。\n②：自分エンドフェイズに「プランキッズ・プランク」以外の自分の墓地の「プランキッズ」カード３枚を対象として発動できる。\nそのカード３枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズの大暴走",h:"ぷらんきっずのだいぼうそう",e:"Prank-Kids Pandemonium",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分・相手のメインフェイズに発動できる。\n自分の手札・フィールドから、「プランキッズ」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこのカードの発動後、ターン終了時まで自分は「プランキッズ」モンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズの大作戦",h:"ぷらんきっずのだいさくせん",e:"Prank-Kids Plan",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\n自分フィールドの「プランキッズ」モンスターを素材として「プランキッズ」リンクモンスター１体をリンク召喚する。\n②：相手モンスターの攻撃宣言時に墓地のこのカードを除外して発動できる。\n自分の墓地の「プランキッズ」カードを任意の数だけ選んでデッキに戻し、その攻撃モンスターの攻撃力はターン終了時まで戻した数×１００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"麗の魔妖－妲姫",h:"うるわしのまやかし－だっき",e:"Dakki, the Graceful Mayakashi",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:0,tx:"①：「麗の魔妖－妲姫」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、「魔妖」モンスターがEXデッキから自分フィールドに特殊召喚された時に発動できる。\nこのカードを特殊召喚する。\nこの効果を発動するターン、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"轍の魔妖－俥夫",h:"わだちのまやかし－しゃふ",e:"Shafu, the Wheeled Mayakashi",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「轍の魔妖－俥夫」以外の自分の墓地の「魔妖」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードがモンスターゾーンに存在する限り、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"毒の魔妖－束脛",h:"どくのまやかし－つかはぎ",e:"Tsukahagi, the Poisonous Mayakashi",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：「毒の魔妖－束脛」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、「毒の魔妖－束脛」以外の自分の「魔妖」モンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果を発動するターン、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"翼の魔妖－波旬",h:"つばさのまやかし－はじゅん",e:"Hajun, the Winged Mayakashi",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:600,de:400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「翼の魔妖－波旬」以外の「魔妖」モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"骸の魔妖－夜叉",h:"むくろのまやかし－やしゃ",e:"Yasha, the Skeletal Mayakashi",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外の「魔妖」モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"翼の魔妖－天狗",h:"つばさのまやかし－てんぐ",e:"Tengu, the Winged Mayakashi",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「翼の魔妖－天狗」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが９の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n相手フィールドの魔法・罠カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"轍の魔妖－朧車",h:"わだちのまやかし－おぼろぐるま",e:"Oboro-Guruma, the Wheeled Mayakashi",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:800,de:2100,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「轍の魔妖－朧車」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが５の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nこのターン、自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"毒の魔妖－土蜘蛛",h:"どくのまやかし－つちぐも",e:"Tsuchigumo, the Poisonous Mayakashi",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「毒の魔妖－土蜘蛛」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが７の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nお互いのデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"麗の魔妖－妖狐",h:"うるわしのまやかし－ようこ",e:"Yoko, the Graceful Mayakashi",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「麗の魔妖－妖狐」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが１１の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"骸の魔妖－餓者髑髏",h:"むくろのまやかし－がしゃどくろ",e:"Gashadokuro, the Skeletal Mayakashi",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:2600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「骸の魔妖－餓者髑髏」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、自分のリンクモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nこのターン、表側表示のこのカードは他のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷の魔妖－雪女",h:"つららのまやかし－ゆきおんな",e:"Yuki-Onna, the Ice Mayakashi",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"アンデット族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"「魔妖」モンスター２体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「氷の魔妖－雪女」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードのリンク先にSモンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。\n③：このカードがモンスターゾーンに存在し、自分のSモンスターが戦闘または相手の効果で破壊された場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで半分になる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"魔妖廻天",h:"まやかしかいてん",e:"Mayakashi Return",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「魔妖廻天」以外の「魔妖」カード１枚を選び、手札に加えるか墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔妖変生",h:"まやかしへんじょう",e:"Mayakashi Metamorphosis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。\n①：手札を１枚捨て、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「魔妖」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ドラゴン・ネクステア",h:"さいばー・どらごん・ねくすてあ",e:"Cyber Dragon Nachster",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：手札からこのカード以外のモンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n③：このカードが召喚・特殊召喚に成功した場合、自分の墓地の、攻撃力か守備力が２１００の機械族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"黒衣之詔刀師",h:"こくいののりとし",e:"Noritoshi in Darkest Rainment",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"魔法使い族の効果モンスター３体以上\nこのカードはリンク召喚でしか特殊召喚できない。\nEXリンク状態のこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:["左","右","左下","下","右下"],ta:[],al:[]},
  {n:"神峰之天津靇",h:"しんぽうのあまつおかみ",e:"Amatsu-Okami of the Divine Peaks",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"ドラゴン族の効果モンスター３体以上\nこのカードはリンク召喚でしか特殊召喚できない。\nEXリンク状態のこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。\n\n※公式のデュエルでは使用できません。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"アドヴェンデット・セイヴァー",h:"あどう゛ぇんでっと・せいう゛ぁー",e:"Avendread Savior",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"アンデット族モンスター２体\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「リヴェンデット・スレイヤー」として扱う。\n②：自分の墓地の「ヴェンデット」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n③：このカードが相手モンスターと戦闘を行うダメージ計算時、デッキからアンデット族モンスター１体を墓地へ送って発動できる。\nその相手モンスターの攻撃力はターン終了時まで、墓地へ送ったモンスターのレベル×２００ダウンする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"F.A.シャイニングスターGT",h:"ふぉーみゅらあすりーとしゃいにんぐすたーじーてぃ",e:"F.A. Shining Star GT",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"機械族モンスター２体\nこのカード名の④の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク先の「F.A.」モンスターのレベルの合計×３００アップする。\n②：このカードの戦闘で発生するお互いの戦闘ダメージは０になる。\n③：「F.A.」魔法・罠カードの効果が発動した場合に発動する。\nこのカードにアスリートカウンターを１つ置く。\n④：相手がモンスターの効果を発動した時、このカードのアスリートカウンターを１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"X・HERO ドレッドバスター",h:"えくすとらひーろー　どれっどばすたー",e:"Xtra HERO Dread Decimator",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"「HERO」モンスター２体以上\n①：このカード及びこのカードのリンク先の「HERO」モンスターの攻撃力は、自分の墓地の「HERO」モンスターの種類×１００アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"極星天グルヴェイグ",h:"きょくせいてんぐるう゛ぇいぐ",e:"Gullveig of the Nordic Ascendant",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"レベル５以下の「極星」モンスター１体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\n自分の手札・フィールドのカードを３枚まで選んで除外し、その数だけデッキから「極星」モンスターを守備表示で特殊召喚する。\nこの効果を発動したターン、自分は通常召喚できず、「極神」モンスターしか特殊召喚できない。\n②：このカードのリンク先に「極神」モンスターが存在する限り、相手はそのモンスターを効果の対象にできず、このカードを攻撃対象に選択できない。",li:"",lk:["左下"],ta:[],al:[]},
  {n:"幻影騎士団ラスティ・バルディッシュ",h:"ふぁんとむ・ないつらすてぃ・ばるでぃっしゅ",e:"The Phantom Knights of Rusty Bardiche",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2100,de:"-",tx:"闇属性モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\nこのカードはリンク素材にできない。\n①：自分メインフェイズに発動できる。\nデッキから「幻影騎士団」モンスター１体を墓地へ送る。\nその後、デッキから「ファントム」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：このカードが既にモンスターゾーンに存在する状態で、このカードのリンク先に闇属性Xモンスターが特殊召喚された場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:["右","左下","右下"],ta:[],al:[]},
  {n:"タツネクロ",h:"たつねくろ",e:"Tatsunecro",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1700,tx:"通常召喚したこのカードを素材としてS召喚する場合、手札のモンスター１体もS素材にできる。\nその時のS素材モンスターは墓地へは行かず除外される。\n①：このカードがモンスターゾーンに存在する限り、自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"死霊王 ドーハスーラ",h:"しりょうおう　どーはすーら",e:"Doomking Balerdroch",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「死霊王 ドーハスーラ」以外のアンデット族モンスターの効果が発動した時に発動できる。\n以下の効果から１つを選んで適用する。\nこのターン、自分の「死霊王 ドーハスーラ」の効果で同じ効果を適用できない。\n\n●その効果を無効にする。\n\n●自分または相手の、フィールド・墓地のモンスター１体を選んで除外する。\n\n②：フィールドゾーンに表側表示でカードが存在する場合、自分・相手のスタンバイフェイズに発動できる。\nこのカードを墓地から守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"屍界のバンシー",h:"しかいのばんしー",e:"Necroworld Banshee",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、フィールドゾーンの「アンデットワールド」は効果の対象にならず、効果では破壊されない。\n②：フィールド・墓地のこのカードを除外して発動できる。\n手札・デッキから「アンデットワールド」１枚を選んで発動する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"グローアップ・ブルーム",h:"ぐろーあっぷ・ぶるーむ",e:"Glow-Up Bloom",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル５以上のアンデット族モンスター１体を手札に加える。\nフィールドゾーンに「アンデットワールド」が存在する場合、手札に加えず特殊召喚する事もできる。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アンデット・ネクロナイズ",h:"あんでっと・ねくろないず",e:"Zombie Necronize",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドにレベル５以上のアンデット族モンスターが存在する場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\n②：このカードが墓地に存在する場合に発動できる。\n除外されている自分のアンデット族モンスター１体を選んでデッキに戻し、このカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"アンデット・ストラグル",h:"あんでっと・すとらぐる",e:"Zombie Power Struggle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドのアンデット族モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力は１０００アップ、または１０００ダウンする。\n②：このカードが墓地に存在する場合、自分メインフェイズに発動できる。\n除外されている自分のアンデット族モンスター１体を選んでデッキに戻し、このカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"リターン・オブ・アンデット",h:"りたーん・おぶ・あんでっと",e:"Return of the Zombies",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのアンデット族モンスター１体を選んで除外する。\nその後、そのコントローラーの墓地からアンデット族モンスター１体を選び、その持ち主のフィールドに守備表示で特殊召喚する。\n②：このカードが墓地に存在する場合に発動できる。\n除外されている自分のアンデット族モンスター１体を選んでデッキに戻し、このカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の不屍竜",h:"れっどあいず・あんでっとねくろどらごん",e:"Red-Eyes Zombie Necro Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力は、お互いのフィールド・墓地のアンデット族モンスターの数×１００アップする。\n②：このカード以外のアンデット族モンスターが戦闘で破壊された時に発動できる。\n自分または相手の墓地のアンデット族モンスター１体を選んで自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの招来",h:"でーもんのしょうらい",e:"Archfiend's Call",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードはモンスターゾーンに存在する限り、カード名を「デーモンの召喚」として扱う。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「デーモンの召喚」を相手は効果の対象にできない。\n③：S召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「デーモンの召喚」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シノビネクロ",h:"しのびねくろ",e:"Shinobi Necro",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、自分の墓地からこのカード以外のアンデット族モンスターが特殊召喚された場合に発動できる。\n自分はデッキから１枚ドローする。\nその後、手札を１枚選んで捨てる。\n②：墓地のこのカードが、効果を発動するために除外された場合、または効果で除外された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"極星霊アルヴィース",h:"きょくせいれいあるう゛ぃーす",e:"Alviss of the Nordic Alfar",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の②の効果はデュエル中に１度しか使用できない。\n①：「極星」リンクモンスターの効果でこのカードのみが除外された場合に発動できる。\nレベルの合計が１０になるように、「極星」モンスターを自分フィールドから１体、デッキから２体墓地へ送る。\nその後、EXデッキから「極神」モンスター１体を特殊召喚する。\n②：自分の「極神」モンスターが戦闘以外の方法で相手によって墓地へ送られた場合、墓地のこのカードを除外して発動できる。\n同名カードが自分の墓地にない「極神」モンスター１体をEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"廃車復活",h:"はいしゃふっかつ",e:"Consolation Prize",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分または相手の手札からモンスターが墓地へ送られた場合、そのモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のサンダーバード",h:"みかいいきのさんだーばーど",e:"Danger! Thunderbird!",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のサンダーバード」以外だった場合、さらに手札から「未界域のサンダーバード」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合、相手フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のワーウルフ",h:"みかいいきのわーうるふ",e:"Danger! Dogman!",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のワーウルフ」以外だった場合、さらに手札から「未界域のワーウルフ」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のモスマン",h:"みかいいきのもすまん",e:"Danger! Mothman!",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のモスマン」以外だった場合、さらに手札から「未界域のモスマン」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合に発動できる。\nお互いのプレイヤーはデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のツチノコ",h:"みかいいきのつちのこ",e:"Danger!? Tsuchinoko?",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のツチノコ」以外だった場合、さらに手札から「未界域のツチノコ」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域調査報告",h:"みかいいきちょうさほうこく",e:"Danger! Response Team",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「未界域」モンスター１体とフィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n②：このカードが墓地に存在する場合、手札から「未界域」モンスター１体を捨てて発動できる。\nこのカードをデッキの一番下に戻す。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"第弐次未界域探険隊",h:"だいにじみかいいきたんけんたい",e:"Second Expedition into Danger!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨て、自分フィールドの「未界域」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで５００アップする。\n②：このカードが墓地に存在する場合、手札から「未界域」モンスター１体を捨てて発動できる。\nこのカードをデッキの一番下に戻す。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士イヴァン",h:"せいきしいう゛ぁん",e:"Noble Knight Iyvanne",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが「聖剣」装備魔法カードを装備した場合に発動できる。\n自分フィールドに「聖騎士トークン」（戦士族・光・星４・攻／守１０００）１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「聖騎士」モンスターしか特殊召喚できない。\n②：このカードが「聖剣」装備魔法カードを装備している限り、このカード以外の自分フィールドの「聖騎士」モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アヴァロンの魔女モルガン",h:"あう゛ぁろんのまじょもるがん",e:"Morgan, the Enchantress of Avalon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:1600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「聖騎士」モンスター及び「聖剣」装備魔法カードが存在し、相手が魔法・罠・モンスターの効果を発動した時、このカードを手札から墓地へ送って発動できる。\n自分フィールドの「聖剣」装備魔法カード１枚を選んで破壊し、その発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖杯の継承",h:"せいはいのけいしょう",e:"Heritage of the Chalice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分のデッキ・墓地から「聖騎士」モンスター１体または「聖剣」カード１枚を選んで手札に加える。\n②：このカードが墓地に存在し、「聖剣」装備魔法カードを装備した自分の「聖騎士」モンスターが戦闘で破壊され墓地へ送られた時に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"聖剣の導く未来",h:"せいけんのみちびくみらい",e:"Until Noble Arms are Needed Once Again",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「聖剣」装備魔法カードの数だけ自分のデッキの上からカードをめくる。\nその中から１枚を選んで手札に加え、残りのカードは好きな順番でデッキの上に戻す。\n②：墓地のこのカードを除外して発動できる。\n同名カードが自分のフィールド・墓地に存在しない「聖騎士」モンスター１体をデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"キャシー・イヴL２",h:"きゃしー・いう゛える２",e:"Catche Eve L2",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドのレベル３以上のモンスター１体を対象として発動できる。\nそのモンスターのレベルを２つ下げ、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・シンクロン",h:"さいばーす・しんくろん",e:"Cyberse Synchron",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分フィールドのレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで、その元々のレベル分だけ上げる。\n②：EXモンスターゾーンの自分のモンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ウルヴィー",h:"さらまんぐれいとうるう゛ぃー",e:"Salamangreat Wolvie",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを素材としてリンク召喚に成功したモンスターは、そのターン戦闘・効果では破壊されない。\n②：このカードが墓地からの特殊召喚に成功した場合、自分の墓地の炎属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n③：このカードが効果で自分の墓地から手札に加わった場合、このカードを相手に見せ、自分の墓地の炎属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣パロー",h:"さらまんぐれいとぱろー",e:"Salamangreat Parro",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時に発動できる。\nこのカードを手札から攻撃表示で特殊召喚する。\n②：このカードが特殊召喚に成功した場合、自分の墓地の「サラマングレイト」モンスター１体を対象として発動できる。\nこのカードの攻撃力はそのモンスターの攻撃力と同じになる。\n③：このカードをリリースして発動できる。\n自分は２０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣フォクサー",h:"さらまんぐれいとふぉくさー",e:"Salamangreat Foxer",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地の「サラマングレイト」モンスターが３体以上の場合、このカードを手札から墓地へ送り、自分の墓地の「サラマングレイト」リンクモンスター１体と相手の魔法＆罠ゾーンのカード１枚を対象として発動できる。\n対象の墓地のモンスターをEXデッキに戻し、対象のフィールドのカードを破壊する。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンのカードが破壊され墓地へ送られた場合に発動できる。\nこのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スピードローダー・ドラゴン",h:"すぴーどろーだー・どらごん",e:"Speedburst Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が効果ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分が受けたダメージと同じ数値分のダメージを相手に与え、与えたダメージの半分だけ自分のLPを回復する。\n②：このカードがアドバンス召喚に成功した時に発動できる。\nデッキから「ヴァレット」モンスター２体を手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレット・シンクロン",h:"う゛ぁれっと・しんくろん",e:"Rokket Synchron",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル５以上のドラゴン族・闇属性モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオスペース・コネクター",h:"ねおすぺーす・こねくたー",e:"Neo Space Connector",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札・デッキから「N」モンスターまたは「E・HERO ネオス」１体を守備表示で特殊召喚する。\n②：このカードをリリースし、自分の墓地の、「N」モンスターまたは「E・HERO ネオス」１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"TG スクリュー・サーペント",h:"てっくじーなす すくりゅー・さーぺんと",e:"T.G. Screw Serpent",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「TG スクリュー・サーペント」以外の自分の墓地のレベル４以下の「TG」モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「TG」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで１つ上げる、または下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ブースター・ラプトル",h:"てっくじーなす ぶーすたー・らぷとる",e:"T.G. Booster Raptor",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「TG」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「TG ブースター・ラプトル」以外の「TG」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"TG タンク・ラーヴァ",h:"てっくじーなす たんく・らーう゛ぁ",e:"T.G. Tank Grub",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードを「TG」SモンスターのS素材とする場合、このカードをチューナー以外のモンスターとして扱う事ができる。\n②：このカードが「TG」SモンスターのS素材として墓地へ送られた場合に発動できる。\n自分フィールドに「TGトークン」（機械族・地・星１・攻／守０）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"守護竜ユスティア",h:"しゅごりゅうゆすてぃあ",e:"Guardragon Justicia",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:0,de:2100,tx:"星鍵は流れぬ涙を流し、天命は果たされる。\n\n神の門は嘶き崩れ、蛇は守人の夢幻を喰らう。\n\n其の魂は始まりの地に、彼の魂は終極の地に。\n\n\n――此処に神獄たる星は闢かれん。",li:"",lk:[],ta:[],al:[]},
  {n:"守護竜ガルミデス",h:"しゅごりゅうがるみです",e:"Guardragon Garmides",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：通常モンスターが自分の墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在する場合、手札からドラゴン族モンスター１体を墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"守護竜プロミネシス",h:"しゅごりゅうぷろみねしす",e:"Guardragon Promineses",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札・フィールドのこのカードを墓地へ送り、自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は相手ターン終了時まで５００アップする。\n②：このカードが墓地に存在し、通常モンスターが自分の墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"守護竜アンドレイク",h:"しゅごりゅうあんどれいく",e:"Guardragon Andrake",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:600,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・デッキからの特殊召喚に成功した場合に発動できる。\nこのカードの元々の攻撃力・守備力は次のターンの終了時まで倍になる。\n②：このカードが墓地からの特殊召喚に成功した場合、または除外されているこのカードが特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物－『星櫃』",h:"せいいぶつ－『せいひつ』",e:"World Legacy - \"World Ark\"",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:500,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのリンクモンスターが相手の効果で破壊され自分の墓地へ送られた場合、このカードを手札から墓地へ送り、そのリンクモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n③：通常召喚したこのカードがモンスターゾーンに存在し、相手がEXデッキからモンスターを特殊召喚した場合に発動できる。\nデッキからモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"逢魔ノ妖刀－不知火",h:"おうまのようとう－しらぬい",e:"Shiranui Spectralsword Shade",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、「逢魔ノ妖刀－不知火」以外の除外されている自分のモンスターの中から「不知火」モンスターを含むアンデット族モンスター２体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火の師範",h:"しらぬいのいくさのり",e:"Shiranui Swordmaster",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドに「不知火」モンスターが２種類以上存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードはフィールドから離れた場合に除外される。\n②：このカードが除外された場合、自分フィールドのアンデット族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火の武部",h:"しらぬいのもののべ",e:"Shiranui Squire",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札・デッキから「妖刀－不知火」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。\n②：このカードが除外された場合に発動できる。\n自分はデッキから１枚ドローする。\nその後、手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻創龍ファンタズメイ",h:"げんそうりゅうふぁんたずめい",e:"Fantastical Dragon Phantazmay",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がリンクモンスターの特殊召喚に成功した場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分は相手フィールドのリンクモンスターの数＋１枚をデッキからドローし、相手フィールドのリンクモンスターの数だけ自分の手札を選んでデッキに戻す。\n②：自分フィールドのモンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・トロイメア",h:"おるふぇごーる・とろいめあ",e:"Orcust Knightmare",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードはリンクモンスターとの戦闘では破壊されない。\n②：墓地のこのカードを除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nデッキから「オルフェゴール・トロイメア」以外の機械族・闇属性モンスター１体を墓地へ送る。\n対象のモンスターの攻撃力はターン終了時まで、墓地へ送ったモンスターのレベル×１００アップする。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"プランキッズ・ロック",h:"ぷらんきっず・ろっく",e:"Prank-Kids Rocksies",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが「プランキッズ」モンスターの、融合素材またはリンク素材として墓地へ送られた場合に発動できる。\n自分の手札を１枚除外し、自分はデッキから１枚ドローする。\nその後、手札・デッキから「プランキッズ・ロック」以外の「プランキッズ」モンスター１体を守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・プティンセスール",h:"まどるちぇ・ぷてぃんせすーる",e:"Madolche Petingcessoeur",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地にモンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した場合に発動できる。\n手札・デッキから「マドルチェ・プティンセスール」以外の「マドルチェ」モンスター１体を特殊召喚する。\nそのモンスターのレベルは１つ下がる。\nこのターン、自分は「マドルチェ」モンスターしか特殊召喚できない。\n③：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコウィールダー",h:"さいこうぃーるだー",e:"Psychic Wheeleder",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「サイコウィールダー」以外のレベル３モンスターが存在する場合、このカードは手札から守備表示で特殊召喚できる。\n②：このカードがS素材として墓地へ送られた場合、このカードをS素材としたSモンスターより低い攻撃力を持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコトラッカー",h:"さいことらっかー",e:"Psychic Tracker",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「サイコトラッカー」以外のレベル３モンスターが存在する場合、このカードは手札から守備表示で特殊召喚できる。\n②：このカードをS素材としたSモンスターは、攻撃力が６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"封狼雷坊",h:"ふうらいぼう",e:"Thunderclap Monk",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1900,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの効果を発動した時、特殊召喚したこのカードをリリースして発動できる。\nその発動を無効にし破壊する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札から雷族モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"彩宝龍",h:"さいほうりゅう",e:"Lappis Dragon",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2600,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがデッキから手札に加わった場合、このカードを相手に見せて発動できる。\nこのカードを特殊召喚する。\n②：このカードが効果で自分の墓地から手札に加わった場合、このカードを相手に見せて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"炎天禍サンバーン",h:"えんてんかさんばーん",e:"Cataclysmic Scorching Sunburner",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の炎属性モンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、その破壊された自分の墓地の炎属性モンスター１体を選び、その攻撃力の半分のダメージを相手に与える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"隅烏賊",h:"すみいか",e:"Squirt Squid",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"①：１ターンに１度、このカードが相手モンスターの攻撃対象に選択された時に発動できる。\n自分フィールドのこのカードの位置を、他の自分のメインモンスターゾーンに移動する。\nその後、自分フィールドに「イカスミトークン」（水族・水・星２・攻？／守０）１体を特殊召喚する。\nこのトークンの攻撃力は、その相手モンスターの攻撃力と同じになる。\n②：このカードの守備力以下の攻撃力を持つ相手モンスターは、メインモンスターゾーンの右端か左端に存在するこのカードを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"孤高除獣",h:"ここうのけもの",e:"Aloof Lupine",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、手札からモンスター１体を除外して発動できる。\nデッキからそのモンスターと同じ種族のモンスター１体を除外する。\n②：このカードが戦闘または相手の効果で破壊された場合、除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エキストラケアトップス",h:"えきすとらけあとっぷす",e:"Extraceratops",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、EXモンスターゾーンのモンスターがメインモンスターゾーンのモンスターとの戦闘で破壊され墓地へ送られた時に発動できる。\nこのカードをその破壊されたEXモンスターゾーンのモンスターの持ち主のフィールドに守備表示で特殊召喚する。\n②：このカードの①の効果で特殊召喚したこのカードが破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀－カリスライム",h:"でびりちゃる－かりすらいむ",e:"Impcantation Chalislime",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1800,tx:"「魔神儀の祝誕」により降臨。\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n手札を１枚選んで捨て、デッキから「魔神儀」モンスター１体を特殊召喚する。\n発動後、このターン中に自分が儀式モンスターの特殊召喚に成功しなかった場合、エンドフェイズに自分は２５００LPを失う。\n②：手札及び自分フィールドの表側表示のカードの中から、「魔神儀」カード１枚を墓地へ送り、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスターバンド・ギタースイート",h:"とりっくすたーばんど・ぎたーすいーと",e:"Trickstar Band Sweet Guitar",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1200,tx:"「トリックスター」リンクモンスター＋「トリックスター」モンスター\n①：このカードとリンク状態の自分の「トリックスター」リンクモンスターの効果で相手に与えるダメージは倍になる。\n②：「トリックスター」モンスターの効果で相手がダメージを受ける度に発動する。\nこのカードの攻撃力はそのダメージの数値分アップする。\n③：このカードが攻撃したターンのエンドフェイズに発動する。\nこのカードの②の効果でアップした数値は０に戻る。\nその後、自分の墓地から「トリックスター」モンスター１体を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ヴァイオレットキマイラ",h:"さらまんぐれいとう゛ぁいおれっときまいら",e:"Salamangreat Violet Chimera",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"サイバース族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「サラマングレイト」モンスター＋リンクモンスター\n①：このカードが融合召喚に成功した場合に発動できる。\nこのカードの攻撃力はターン終了時まで、素材としたモンスターの元々の攻撃力を合計した数値の半分だけアップする。\n②：このカードが元々の攻撃力と異なる攻撃力を持つモンスターと戦闘を行うダメージ計算時に１度、発動できる。\nこのカードの攻撃力はそのダメージ計算時のみ倍になる。\n③：「転生炎獣ヴァイオレットキマイラ」を素材として融合召喚したこのカードと戦闘を行うモンスターの攻撃力はダメージ計算時のみ０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ブレイヴ・ネオス",h:"えれめんたるひーろー ぶれいう゛・ねおす",e:"Elemental HERO Brave Neos",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋レベル４以下の効果モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は自分の墓地の「N」モンスター及び「HERO」モンスターの数×１００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n「E・HERO ネオス」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO コスモ・ネオス",h:"えれめんたるひーろー こすも・ねおす",e:"Elemental HERO Cosmo Neos",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:3000,tx:"「E・HERO ネオス」＋属性が異なる「N」モンスター×３\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードがEXデッキからの特殊召喚に成功した場合に発動できる。\nこのターン相手はフィールドで発動する効果を発動できない。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n②：エンドフェイズに発動する。\nこのカードをEXデッキに戻し、相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレルロード・S・ドラゴン",h:"う゛ぁれるろーど・さべーじ・どらごん",e:"Borreload Savage Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\n自分の墓地からリンクモンスター１体を選び、装備カード扱いとしてこのカードに装備し、そのリンクマーカーの数だけこのカードにヴァレルカウンターを置く。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力の半分アップする。\n③：相手の効果が発動した時、このカードのヴァレルカウンターを１つ取り除いて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・クアンタム・ドラゴン",h:"さいばーす・くあんたむ・どらごん",e:"Cyberse Quantum Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"サイバース族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：自分フィールドにリンクモンスターが存在する限り、相手はこのカード以外の自分フィールドのモンスターを、攻撃対象に選択できず、効果の対象にもできない。\n②：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターを持ち主の手札に戻す。\nこの効果を発動した場合、このカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"TG スター・ガーディアン",h:"てっくじーなす すたー・がーでぃあん",e:"T.G. Star Guardian",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:100,de:2200,tx:"チューナー＋チューナー以外の「TG」モンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地の「TG」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：自分メインフェイズに発動できる。\n手札から「TG」モンスター１体を特殊召喚する。\n③：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"業神－不知火",h:"わざがみ－しらぬい",e:"Shiranui Swordsaga",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「業神－不知火」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\n②：このカードが除外された場合に発動できる。\n自分フィールドに「不知火トークン」（アンデット族・炎・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"妖神－不知火",h:"あやかしがみ－しらぬい",e:"Shiranui Squiresaga",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「妖神－不知火」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n自分の墓地及び自分フィールドの表側表示モンスターの中から、モンスター１体を選んで除外する。\nその後、その種類によって、以下の効果をそれぞれ適用できる。\n\n●アンデット族：自分フィールドの全てのモンスターの攻撃力は３００アップする。\n\n●炎属性：フィールドの魔法・罠カード１枚を選んで破壊する。\n\n●S：フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイパーサイコライザー",h:"はいぱーさいこらいざー",e:"Hyper Psychic Riser",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードの攻撃力より低い攻撃力を持つモンスターは攻撃できず、このカードの攻撃力より高い攻撃力を持つフィールドのモンスターは効果を発動できない。\n②：このカードが相手によって破壊され墓地へ送られた場合、自分の墓地の、種族と属性が同じとなるチューナー１体とチューナー以外のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・インテグレーター",h:"さいばーす・いんてぐれーたー",e:"Cyberse Integrator",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["シンクロ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\n自分の手札・墓地からサイバース族チューナー１体を選んで守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしか特殊召喚できない。\n②：S召喚したこのカードが墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・ウィキッド",h:"さいばーす・うぃきっど",e:"Cyberse Wicckid",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"サイバース族モンスター２体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：リンク召喚したこのカードは戦闘・効果では破壊されない。\n②：このカードのリンク先のサイバース族モンスターは効果では破壊されない。\n③：このカードが既にモンスターゾーンに存在する状態で、このカードのリンク先にモンスターが特殊召喚された場合、自分の墓地からサイバース族モンスター１体を除外して発動できる。\nデッキからサイバース族チューナー１体を手札に加える。",li:"",lk:["下","右下"],ta:[],al:[]},
  {n:"アップデートジャマー",h:"あっぷでーとじゃまー",e:"Update Jammer",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"レベル２以上のサイバース族モンスター２体\n①：自分のサイバース族モンスターが戦闘を行うダメージ計算時に１度、発動できる。\nダメージステップ終了時まで、このカード以外のフィールドのカードの効果は無効化され、その戦闘のダメージ計算は元々の攻撃力・守備力で行う。\nその戦闘で相手モンスターが破壊され墓地へ送られた時、相手に１０００ダメージを与える。\n②：このカードがリンク素材として墓地へ送られた場合に発動できる。\nこのカードをリンク素材としたリンクモンスターはこのターン、１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:["上","左"],ta:[],al:[]},
  {n:"デトネイト・デリーター",h:"でとねいと・でりーたー",e:"Detonate Deleter",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2100,de:"-",tx:"サイバース族モンスター２体以上\n①：１ターンに１度、リンク３以上のリンクモンスターを除く、表側表示モンスターとこのカードが戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターを破壊する。\n②：１ターンに１度、このカードのリンク先の自分のモンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:["左","右","左下"],ta:[],al:[]},
  {n:"クロック・リザード",h:"くろっく・りざーど",e:"Clock Lizard",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"サイバース族モンスター２体\n①：このカードをリリースして発動できる。\n自分の墓地から融合モンスター１体を選んでEXデッキに戻す。\nその後、その融合モンスターカードによって決められた融合素材モンスターを自分の墓地から除外し、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードが除外された場合に発動できる。\n相手フィールドの特殊召喚されたモンスターの攻撃力は、ターン終了時まで自分の墓地のサイバース族モンスターの数×４００ダウンする。",li:"",lk:["左下","下"],ta:[],al:[]},
  {n:"転生炎獣サンライトウルフ",h:"さらまんぐれいとさんらいとうるふ",e:"Salamangreat Sunlight Wolf",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"炎属性の効果モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのリンク先にモンスターが召喚・特殊召喚された場合に発動できる。\n自分の墓地から炎属性モンスター１体を選んで手札に加える。\nこのターン、自分はこの効果で手札に加えたモンスター及びその同名モンスターを通常召喚・特殊召喚できない。\n②：このカードが「転生炎獣サンライトウルフ」を素材としてリンク召喚されている場合に発動できる。\n自分の墓地の「サラマングレイト」魔法・罠カード１枚を選んで手札に加える。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"トリックスター・ディーヴァリディス",h:"とりっくすたー・でぃーう゛ぁりでぃす",e:"Trickstar Divaridis",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"レベル３以下の「トリックスター」モンスター２体\n①：「トリックスター・ディーヴァリディス」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが特殊召喚に成功した場合に発動できる。\n相手に２００ダメージを与える。\n③：相手がモンスターの召喚・特殊召喚に成功する度に発動する。\n相手に２００ダメージを与える。",li:"",lk:["左下","下"],ta:[],al:[]},
  {n:"TG トライデント・ランチャー",h:"てっくじーなす とらいでんと・らんちゃー",e:"T.G. Trident Launcher",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2200,de:"-",tx:"「TG」チューナーを含む効果モンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\n自分の手札・デッキ・墓地から「TG」モンスターをそれぞれ１体ずつ選んで、このカードのリンク先となる自分フィールドに守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「TG」モンスターしか特殊召喚できない。\n②：相手はこのカードのリンク先の「TG」Sモンスターを効果の対象にできない。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"守護竜エルピィ",h:"しゅごりゅうえるぴぃ",e:"Guardragon Elpy",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"レベル４以下のドラゴン族モンスター１体\n自分は「守護竜エルピィ」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分はドラゴン族モンスターしか特殊召喚できない。\n②：自分メインフェイズに発動できる。\n２体以上のリンクモンスターのリンク先となる自分フィールドに、手札・デッキからドラゴン族モンスター１体を特殊召喚する。",li:0,lk:["左"],ta:[],al:[]},
  {n:"守護竜ピスティ",h:"しゅごりゅうぴすてぃ",e:"Guardragon Pisty",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"レベル４以下のドラゴン族モンスター１体\n自分は「守護竜ピスティ」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分はドラゴン族モンスターしか特殊召喚できない。\n②：自分の墓地のモンスターまたは除外されている自分のモンスターの中から、ドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを、２体以上のリンクモンスターのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:["右"],ta:[],al:[]},
  {n:"守護竜アガーペイン",h:"しゅごりゅうあがーぺいん",e:"Guardragon Agarpain",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"ドラゴン族モンスター２体\n自分は「守護竜アガーペイン」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分はドラゴン族モンスターしか特殊召喚できない。\n②：自分メインフェイズに発動できる。\n２体以上のリンクモンスターのリンク先となる、EXモンスターゾーンまたは自分フィールドに、EXデッキからドラゴン族モンスター１体を特殊召喚する。",li:0,lk:["上","下"],ta:[],al:[]},
  {n:"麗神－不知火",h:"うるわしがみ－しらぬい",e:"Shiranui Skillsaga Supremacy",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"アンデット族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"アンデット族モンスター２体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分フィールドのSモンスターは効果では破壊されない。\n②：このカードがモンスターゾーンに存在する限り、自分の炎属性モンスターは戦闘では破壊されない。\n③：相手ターンに、除外されている自分のアンデット族Sモンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:["上","左","下"],ta:[],al:[]},
  {n:"閃刀姫－カイナ",h:"せんとうき－かいな",e:"Sky Striker Ace - Kaina",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"地属性以外の「閃刀姫」モンスター１体\n自分は「閃刀姫－カイナ」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターは相手ターン終了時まで攻撃できない。\n②：このカードがモンスターゾーンに存在する限り、自分が「閃刀」魔法カードの効果を発動する度に、自分は１００LP回復する。",li:"",lk:["右下"],ta:[],al:[]},
  {n:"灼熱の火霊使いヒータ",h:"しゃくねつのかれいつかいひーた",e:"Hiita the Fire Charmer, Ablaze",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1850,de:"-",tx:"炎属性モンスターを含むモンスター２体\nこのカード名はルール上「憑依装着」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の炎属性モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから守備力１５００以下の炎属性モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"フュージョン・オブ・ファイア",h:"ふゅーじょん・おぶ・ふぁいあ",e:"Fusion of Fire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「サラマングレイト」カードとしても扱う。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札及び自分・相手フィールドから、「サラマングレイト」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・ライブステージ",h:"とりっくすたー・らいぶすてーじ",e:"Trickstar Live Stage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できず、発動するターン、自分は「トリックスター」モンスターしか特殊召喚できない。\n①：このカードの発動時に、自分の墓地の「トリックスター」モンスター１体を手札に加える事ができる。\n②：自分フィールドに「トリックスター」リンクモンスターが存在する場合に発動できる。\n「トリックスタートークン」（天使族・光・星１・攻／守０）１体を特殊召喚する。\n③：相手の魔法＆罠ゾーンにカードが存在する場合に発動できる。\n「トリックスタートークン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・フュージョン",h:"とりっくすたー・ふゅーじょん",e:"Trickstar Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「トリックスター」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「トリックスター」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこのターン、自分はこの効果で手札に加えたモンスター及びその同名モンスターを通常召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオス・フュージョン",h:"ねおす・ふゅーじょん",e:"Neos Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・デッキ・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「E・HERO ネオス」を含むモンスター２体のみを素材とするその融合モンスター１体を召喚条件を無視してEXデッキから特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はモンスターを特殊召喚できない。\n②：「E・HERO ネオス」を融合素材とする自分フィールドの融合モンスターが戦闘・効果で破壊される場合、または自身の効果でEXデッキに戻る場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"守護竜の結界",h:"しゅごりゅうのけっかい",e:"Guardragon Shield",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は相手ターン終了時まで、フィールドのリンクモンスターのリンクマーカーの合計×１００アップする。\n②：１ターンに１度、自分フィールドのドラゴン族モンスター１体のみが戦闘・効果で破壊される場合、代わりに手札・デッキから通常モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の守護竜",h:"せいいぶつのしゅごりゅう",e:"World Legacy Guardragon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時に、自分の墓地のレベル４以下のドラゴン族モンスター１体を対象にできる。\nその場合、そのモンスターを手札に加えるか特殊召喚する。\n②：１ターンに１度、自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nその自分のモンスターの位置を、他の自分のメインモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[]},
  {n:"逢華妖麗譚－不知火語",h:"おうかようれいたん－しらぬいがたり",e:"Ghost Meets Girl - A Shiranui's Story",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドにモンスターが存在する場合、手札からアンデット族モンスター１体を捨てて発動できる。\n捨てたモンスターとカード名が異なる「不知火」モンスター１体を自分のデッキ・墓地から選んで特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火流 伝承の陣",h:"しらぬいりゅう でんしょうのじん",e:"Shiranui Style Solemnity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードは魔法＆罠ゾーンに存在する限り、カード名を「不知火流 転生の陣」として扱う。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n\n●自分の墓地からアンデット族モンスター１体を除外して発動できる。\nこのターン、自分のアンデット族モンスターの召喚・特殊召喚は無効化されない。\n\n●自分フィールドのアンデット族モンスター１体を対象として発動できる。\nそのモンスターを除外する。\nその後、デッキから守備力０のアンデット族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀の祝誕",h:"でびりちゃるのしゅくたん",e:"Impcantation Inception",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"儀式モンスターの降臨に必要。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドの「魔神儀」モンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。\n②：このカードが墓地に存在する場合、手札及び自分フィールドの表側表示のカードの中から、「魔神儀の祝誕」以外の「魔神儀」カード１枚を墓地へ送って発動できる。\nデッキから「魔神儀」モンスター１体を特殊召喚する。\nその後、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニゾン・チューン",h:"ゆにぞん・ちゅーん",e:"Uni-Song Tuning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分または相手の墓地のチューナー１体と、フィールドの表側表示モンスター１体を対象として発動できる。\n対象の墓地のモンスターを除外する。\nその後、対象のフィールドのモンスターはターン終了時まで、除外したモンスターと同じレベルになり、チューナーとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"強欲で金満な壺",h:"ごうよくできんまんなつぼ",e:"Pot of Extravagance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分メインフェイズ１開始時に、自分のEXデッキの裏側表示のカード３枚または６枚をランダムに裏側表示で除外して発動できる。\n除外したカード３枚につき１枚、自分はデッキからドローする。\nこのカードの発動後、ターン終了時まで自分はカードの効果でドローできない。",li:"",lk:[],ta:[],al:[]},
  {n:"土俵間際",h:"どひょうまぎわ",e:"Edge of the Ring",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のLPが相手より少ない場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力の半分のダメージを相手に与える。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"大胆無敵",h:"だいたんむてき",e:"Child's Play",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：相手がモンスターを召喚・反転召喚・特殊召喚する度に、自分は３００LP回復する。\n②：自分のLPが１００００以上の場合、自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"サモンオーバー",h:"さもんおーばー",e:"Summon Over",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：モンスターが特殊召喚される度にこのカードにサモンカウンターを１つ置く（最大６つまで）。\n②：サモンカウンターが６つ置かれているこのカードは効果では破壊されない。\n③：このカードにサモンカウンターが６つ置かれている場合に、お互いのプレイヤーは、自分メインフェイズ１開始時に発動できる。\nこのカードを墓地へ送り、相手フィールドの特殊召喚されたモンスターを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"NEXT",h:"ねおすぺーすえくすてんしょん",e:"NEXT",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n自分フィールドにカードが存在しない場合、このカードの発動は手札からもできる。\n①：自分の手札・墓地から、「N」モンスター及び「E・HERO ネオス」を任意の数だけ選んで守備表示で特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"守護竜の核醒",h:"しゅごりゅうのかくせい",e:"Guardragon Corewakening",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札から効果モンスター１体を墓地へ送って発動できる。\n自分の手札・デッキ・墓地からレベル４以下のドラゴン族の通常モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"崩界の守護竜",h:"ほうかいのしゅごりゅう",e:"Guardragon Cataclysm",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのドラゴン族モンスター１体をリリースし、フィールドのカード２枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"不知火流 才華の陣",h:"しらぬいりゅう さいかのじん",e:"Shiranui Style Success",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札からアンデット族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはフィールドから離れた場合に除外される。\n②：墓地のこのカードを除外し、自分フィールドのアンデット族モンスター１体を対象として発動できる。\nこのターン、そのアンデット族モンスターは自身以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"逢魔ノ刻",h:"おうまのとき",e:"Fateful Hour",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手の墓地の通常召喚できないモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・リリース",h:"おるふぇごーる・りりーす",e:"Orcustrated Release",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの機械族モンスター２体をリリースし、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n相手フィールドにリンクモンスターが存在する場合、この効果の対象を２体にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"サブテラーの継承",h:"さぶてらーのけいしょう",e:"Subterror Succession",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n発動後このカードは墓地へ送らず、そのままセットする。\n●手札及び自分フィールドの表側表示モンスターの中から、モンスター１体を選んで墓地へ送り、同じ属性でカード名が異なるリバースモンスター１体をデッキから手札に加える。\n●手札及び自分フィールドの表側表示モンスターの中から、リバースモンスター１体を選んで墓地へ送り、同じ属性で元々のレベルが低いモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の増産工場",h:"やみのぞうさんこうじょう",e:"Dark Factory of More Production",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドのモンスター１体を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔女の一撃",h:"まじょのいちげき",e:"Witch's Strike",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターの召喚・特殊召喚を無効にした場合、または相手が魔法・罠・モンスターの効果の発動を無効にした場合に発動できる。\n相手の手札・フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ロスタイム",h:"ろすたいむ",e:"Loss Time",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手のLPが４０００以上の場合、自分のLPは相手より１０００少ない数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"コード・ジェネレーター",h:"こーど・じぇねれーたー",e:"Code Generator",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1300,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族モンスターを「コード・トーカー」モンスターのリンク素材とする場合、手札のこのカードもリンク素材にできる。\n②：このカードが「コード・トーカー」モンスターのリンク素材として手札・フィールドから墓地へ送られた場合に発動できる。\nデッキから攻撃力１２００以下のサイバース族モンスター１体を墓地へ送る。\nフィールドのこのカードを素材とした場合には墓地へ送らず手札に加える事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"時械神祖ヴルガータ",h:"じかいしんそう゛るがーた",e:"Timelord Progenitor Vorpgate",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：EXデッキから特殊召喚したこのカードが戦闘を行ったダメージステップ終了時に発動できる。\n相手フィールドのモンスターを全て除外する。\nこの効果の発動後、ターン終了時まで相手が受ける戦闘ダメージは半分になる。\n③：このカードの②の効果を発動したターンのエンドフェイズに発動する。\nその効果で自分が除外したモンスターを可能な限り相手フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・パフューマー",h:"はーぴぃ・ぱふゅーまー",e:"Harpie Perfumer",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ハーピィ・レディ三姉妹」のカード名が記された魔法・罠カード１枚を手札に加える。\n自分フィールドにレベル５以上の「ハーピィ」モンスターが存在する状態で発動した場合にはその枚数は２枚までになる（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・オラクル",h:"はーぴぃ・おらくる",e:"Harpie Oracle",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：自分フィールドにレベル５以上の「ハーピィ」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n③：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのターンのエンドフェイズに、自分の墓地から「ハーピィ・レディ三姉妹」のカード名が記された魔法・罠カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魅惑の合わせ鏡",h:"みわくのすぷりっと・みらー",e:"Alluring Mirror Split",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「ハーピィ・レディ」または「ハーピィ・レディ三姉妹」が戦闘で破壊された時に発動できる。\nそのモンスターと元々のカード名が異なる「ハーピィ」モンスター１体をデッキから特殊召喚する。\n②：フィールドのこのカードが相手の効果または自分の「ハーピィ」カードの効果で破壊された場合、自分の墓地の「ハーピィ」モンスター１体を対象として発動する。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィの羽根休め",h:"はーぴぃのはねやすめ",e:"Harpie's Feather Rest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「ハーピィ・レディ」「ハーピィ・レディ三姉妹」の中から合計３体を対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\n自分フィールドにレベル５以上の「ハーピィ」モンスターが存在する状態で発動した場合にはドローする枚数は２枚になる。\nこのカードの発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"華麗なるハーピィ・レディ",h:"かれいなるはーぴぃ・れでぃ",e:"Harpie Lady Elegance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「ハーピィ・レディ三姉妹」１体を選んで持ち主のデッキに戻す。\nその後、元々のカード名が異なる「ハーピィ」モンスター３体を、自分の手札・デッキ・墓地からそれぞれ１体ずつ選んで特殊召喚できる。\nこのカードの発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。\n②：フィールドのこのカードが相手の効果または自分の「ハーピィ」カードの効果で破壊された場合に発動する。\nデッキから「ハーピィ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エンジェル－伊舎那－",h:"さいばー・えんじぇる－いざな－",e:"Cyber Angel Izana",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2600,tx:"「機械天使の儀式」により降臨。\n①：このカードが儀式召喚に成功した場合に発動できる。\n相手は自身のフィールドの魔法・罠カード１枚を墓地へ送らなければならない。\n②：このカードの攻撃で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードはもう１度だけ続けて相手モンスターに攻撃できる。\n③：１ターンに１度、自分フィールドの「サイバー・エンジェル」儀式モンスターを対象とする相手の効果が発動した時に発動できる。\n自分の墓地の儀式モンスター１体を選んでデッキに戻し、相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・エッグ・エンジェル",h:"さいばー・えっぐ・えんじぇる",e:"Cyber Egg Angel",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「機械天使」魔法カードまたは「祝福の教会－リチューアル・チャーチ」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"慈悲深き機械天使",h:"じひぶかききかいてんし",e:"Merciful Machine Angel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドの「サイバー・エンジェル」儀式モンスター１体をリリースして発動できる。\n自分はデッキから２枚ドローし、その後手札を１枚選んでデッキの一番下に戻す。\nこのカードの発動後、ターン終了時まで自分は儀式モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"応身の機械天使",h:"おうじんのきかいてんし",e:"Incarnated Machine Angel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分の「サイバー・エンジェル」儀式モンスターは戦闘では破壊されない。\n②：戦闘または相手の効果で自分がダメージを受けた時、自分の手札・フィールドの「サイバー・エンジェル」儀式モンスター１体をリリースして発動できる。\n手札から「サイバー・エンジェル」儀式モンスター１体を儀式召喚扱いとして特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"荘厳なる機械天使",h:"そうごんなるきかいてんし",e:"Magnificent Machine Angel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドの「サイバー・エンジェル」儀式モンスター１体をリリースし、自分フィールドの天使族・光属性モンスター１体を対象として発動できる。\n対象のモンスターの攻撃力・守備力はターン終了時まで、リリースしたモンスターのレベル×２００アップする。\nこのターン、対象のモンスターが、EXデッキから特殊召喚された相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーデン・ローズ・メイデン",h:"がーでん・ろーず・めいでん",e:"Garden Rose Maiden",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:1600,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地から「ブラック・ガーデン」１枚を選んで手札に加える。\n②：墓地のこのカードを除外し、自分の墓地の、「ローズ・ドラゴン」モンスターまたはドラゴン族Sモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇薔薇の妖精",h:"だーくろーずふぇありー",e:"Dark Rose Fairy",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：チューナーが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在する場合、自分の手札・フィールドのカード１枚を墓地へ送って発動できる。\nこのカードをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドローズ・ドラゴン",h:"れっどろーず・どらごん",e:"Red Rose Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがS素材として墓地へ送られた場合に発動できる。\n手札・デッキから「レッドローズ・ドラゴン」以外の「ローズ・ドラゴン」モンスター１体を特殊召喚する。\nこのカードが「ブラック・ローズ・ドラゴン」または植物族SモンスターのS素材として墓地へ送られた場合には、さらに「冷薔薇の抱香」または「漆黒の薔薇の開華」１枚をデッキから手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"冷薔薇の抱香",h:"ふろーずん・ろあーず",e:"Frozen Rose",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体を墓地へ送って発動できる。\nそのモンスターの種族によって以下の効果を適用する。\n\n●植物族：このターンのエンドフェイズに、自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。\n\n●植物族以外：デッキからレベル４以下の植物族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"漆黒の薔薇の開華",h:"ぶるーみんぐ・ろーず",e:"Blooming of the Darkest Rose",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：お互いのフィールドゾーンのカード及び墓地のフィールド魔法カードの数まで、自分・相手フィールドに「ローズ・トークン」（植物族・闇・星２・攻／守８００）を守備表示で特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドの、「ブラック・ローズ・ドラゴン」または植物族モンスター１体を対象として発動できる。\nそのモンスターを除外し、このカードをデッキの一番下に戻す。\nこの効果で除外したモンスターは次のスタンバイフェイズにフィールドに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"超弩級砲塔列車ジャガーノート・リーベ",h:"ちょうどきゅうほうとうれっしゃじゃがーのーと・りーべ",e:"Superdreadnought Rail Cannon Juggernaut Liebe",t:"monster",a:"地",l:11,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"レベル１１モンスター×３\n「超弩級砲塔列車ジャガーノート・リーベ」は１ターンに１度、自分フィールドのランク１０の機械族Xモンスターの上に重ねてX召喚する事もできる。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力・守備力は２０００アップする。\nこの効果の発動後、ターン終了時まで自分はこのカードでしか攻撃宣言できない。\n②：このカードは１度のバトルフェイズ中にこのカードのX素材の数＋１回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"弾丸特急バレット・ライナー",h:"だんがんとっきゅうばれっと・らいなー",e:"Super Express Bullet Train",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが機械族・地属性モンスターのみの場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃宣言の際に、自分はこのカード以外の自分フィールドのカード２枚を墓地へ送らなければならない。\n③：このカードが墓地へ送られたターンのエンドフェイズに、「弾丸特急バレット・ライナー」以外の自分の墓地の機械族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"爆走軌道フライング・ペガサス",h:"ばくそうきどうふらいんぐ・ぺがさす",e:"Flying Pegasus Railroad Stampede",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「爆走軌道フライング・ペガサス」以外の自分の墓地の機械族・地属性モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして守備表示で特殊召喚する。\n②：このカード以外の自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとこのカードのレベルは、その内の１体のレベルと同じになる。\nこの効果を発動するターン、自分はXモンスターでしか攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"緊急ダイヤ",h:"きんきゅうだいや",e:"Urgent Schedule",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合に発動できる。\n機械族・地属性の、レベル４以下のモンスター１体とレベル５以上のモンスター１体をデッキから効果を無効にして守備表示で特殊召喚する。\nこのカードを発動するターン、自分は機械族モンスターでしか攻撃宣言できない。\n②：フィールドにセットされたこのカードが墓地へ送られた場合に発動できる。\nデッキから機械族・レベル１０モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"掃射特攻",h:"そうしゃとっこう",e:"Barrage Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの機械族XモンスターのX素材を任意の数だけ取り除き、その数だけフィールドのカードを対象として発動できる。\nそのカードを破壊する。\n②：このカードが墓地に存在する状態で、自分フィールドの機械族Xモンスターが戦闘または相手の効果で破壊された場合、自分の墓地からこのカードと機械族Xモンスター１体を除外して発動できる。\n除外したモンスターのランク×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"月光舞剣虎姫",h:"むーんらいと・さーべる・だんさー",e:"Lunalight Sabre Dancer",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2600,tx:"「ムーンライト」モンスター×３\nこのカードは融合召喚でしか特殊召喚できない。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、お互いの墓地の獣戦士族モンスター及び除外されている獣戦士族モンスターの数×２００アップする。\n②：このカードは相手の効果の対象にならない。\n③：墓地のこのカードを除外し、自分フィールドの融合モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで３０００アップする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"月光翠鳥",h:"むーんらいと・えめらるど・ばーど",e:"Lunalight Emerald Bird",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「ムーンライト」カード１枚を墓地へ送り、自分はデッキから１枚ドローする。\n②：このカードが効果で墓地へ送られた場合、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「月光翠鳥」以外のレベル４以下の「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"月光黄鼬",h:"むーんらいと・いえろー・まーてん",e:"Lunalight Yellow Marten",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、「月光黄鼬」以外の自分フィールドの「ムーンライト」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、このカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキから「ムーンライト」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"月光融合",h:"むーんらいと・ふゅーじょん",e:"Lunalight Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、「ムーンライト」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n相手フィールドに、EXデッキから特殊召喚されたモンスターが存在する場合には自分のデッキ・EXデッキの「ムーンライト」モンスターも１体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"月光小夜曲舞踊",h:"むーんらいと・せれなーど・だんす",e:"Lunalight Serenade Dance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに融合モンスターが融合召喚された時、そのモンスター１体を対象として発動できる。\n以下の効果を適用する。\n\n●相手フィールドに「ムーンライト・トークン」（獣戦士族・闇・星４・攻／守２０００）１体を特殊召喚する。\n\n●対象のモンスターの攻撃力は相手フィールドのモンスターの数×５００アップする。\n\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\n手札を１枚選んで墓地へ送り、デッキから「ムーンライト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホワイトローズ・ドラゴン",h:"ほわいとろーず・どらごん",e:"White Rose Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにドラゴン族または植物族のチューナーが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地から「ホワイトローズ・ドラゴン」以外の「ローズ・ドラゴン」モンスター１体を選んで特殊召喚する。\n③：このカードがS素材として墓地へ送られた場合に発動できる。\nデッキからレベル４以上の植物族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の戦士 カオス・ソルジャー",h:"こんとんのせんし　かおす・そるじゃー",e:"Black Luster Soldier - Soldier of Chaos",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"カード名が異なるモンスター３体\n①：レベル７以上のモンスターを素材としてリンク召喚したこのカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードが戦闘で相手モンスターを破壊した時、以下の効果から１つを選択して発動できる。\n●このカードの攻撃力は１５００アップする。\n●このカードは次の自分ターンのバトルフェイズ中に２回攻撃できる。\n●フィールドのカード１枚を選んで除外する。",li:"",lk:["左下","上","右下"],ta:[],al:[]},
  {n:"ハーピィ・コンダクター",h:"はーぴぃ・こんだくたー",e:"Harpie Conductor",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"風属性モンスター２体\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：自分フィールドの「ハーピィ」モンスターが戦闘・効果で破壊される場合、代わりに自分フィールドの魔法・罠カード１枚を破壊できる。\n③：このカード以外の自分フィールドの表側表示の「ハーピィ」モンスターが自分の手札に戻った場合、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"轟雷機龍－サンダー・ドラゴン",h:"ごうらいきりゅう－さんだー・どらごん",e:"Thunder Dragon Thunderstormech",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"雷族モンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚されている場合、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「サンダー・ドラゴン」モンスター１体を対象として発動できる。\nそのモンスターに記された、自身を手札から捨てて発動する効果を適用する。\nその後、そのモンスターをデッキの一番上または一番下に戻す。\n②：自分フィールドの雷族モンスターが戦闘・効果で破壊される場合、代わりに自分の墓地のカード３枚を除外できる。",li:"",lk:["左","右","下","右下"],ta:[],al:[]},
  {n:"天空神騎士ロードパーシアス",h:"せれすてぃあるないとろーどぱーしあす",e:"Celestial Knightlord Parshath",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"天使族モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\n「天空の聖域」またはそのカード名が記されたカード１枚をデッキから手札に加える。\nフィールドに「天空の聖域」が存在する場合、手札に加えるカードを天使族モンスター１体にできる。\n②：自分フィールドの表側表示の天使族モンスターが墓地へ送られた場合、自分の墓地から天使族モンスター１体を除外して発動できる。\n除外したモンスターよりレベルが高い天使族モンスター１体を手札から特殊召喚する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"エーリアン・ソルジャー M／フレーム",h:"えーりあん・そるじゃー　まいてぃ／ふれーむ",e:"Alien Shocktrooper M-Frame",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"爬虫類族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からモンスター１体を捨てて発動できる。\n捨てたモンスターの元々のレベルの数だけ、フィールドの表側表示モンスターにAカウンターを置く。\nこの効果は相手ターンでも発動できる。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\nAカウンターが置かれた相手フィールドのモンスターの数まで、自分の墓地からリンクモンスター以外の爬虫類族モンスターを選んで特殊召喚する（同名カードは１枚まで）。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ドラグニティナイト－ロムルス",h:"どらぐにてぃないと－ろむるす",e:"Dragunity Knight - Romulus",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"トークン以外のドラゴン族・鳥獣族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「ドラグニティ」魔法・罠カードまたは「竜の渓谷」１枚を手札に加える。\n②：ドラゴン族モンスターがEXデッキからこのカードのリンク先に特殊召喚された場合に発動できる。\n手札からドラゴン族・鳥獣族モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、効果が無効化され、リンク素材にできない。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"スクラップ・ワイバーン",h:"すくらっぷ・わいばーん",e:"Scrap Wyvern",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1700,de:"-",tx:"「スクラップ」モンスターを含むモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「スクラップ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、自分フィールドのカード１枚を選んで破壊する。\n②：このカードが既にモンスターゾーンに存在する状態で、フィールドの表側表示の「スクラップ」モンスターが効果で破壊された場合に発動できる。\nデッキから「スクラップ」モンスター１体を特殊召喚する。\nその後、フィールドのカード１枚を選んで破壊する。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"六武衆の軍大将",h:"ろくぶしゅうのぐんだいしょう",e:"Battle Shogun of the Six Samurai",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「六武衆」モンスターを含む戦士族モンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨てて発動できる。\n武士道カウンターを置く効果を持つカード１枚をデッキから手札に加える。\n②：このカードがモンスターゾーンに存在する限り、このカードのリンク先に「六武衆」モンスターが召喚・特殊召喚される度に、このカードに武士道カウンターを１つ置く。\n③：このカードの攻撃力は自分フィールドの武士道カウンターの数×１００アップする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"機関重連アンガー・ナックル",h:"きかんじゅうれんあんがー・なっくる",e:"Double Headed Anger Knuckle",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"機械族モンスター２体\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\nこのカードはリンク素材にできない。\n①：自分・相手のメインフェイズに、自分の手札・フィールドのモンスター１体を墓地へ送り、自分の墓地の機械族・レベル１０モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードが墓地に存在する場合、自分の手札・フィールドのカード１枚を墓地へ送って発動できる。\nこのカードを特殊召喚する。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"炎星仙－ワシンジン",h:"えんせいせん－わしんじん",e:"Brotherhood of the Fire Fist - Eagle",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1700,de:"-",tx:"獣戦士族モンスター２体\n自分は「炎星仙－ワシンジン」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分が「炎星」モンスターの効果を発動する場合、自分の手札・フィールドの「炎星」カード及び「炎舞」カードを墓地へ送らずに発動する事もできる。\n②：自分のフィールド・墓地の「炎舞」魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nその後、デッキから「炎星」モンスター１体を墓地へ送る。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"セラの蟲惑魔",h:"せらのこわくま",e:"Traptrix Sera",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"リンクモンスター以外の「蟲惑魔」モンスター１体\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：リンク召喚したこのカードは罠カードの効果を受けない。\n②：通常罠カードが発動した場合に発動できる。\n同名カードが自分フィールドに存在しない「蟲惑魔」モンスター１体をデッキから特殊召喚する。\n③：このカード以外の自分の「蟲惑魔」モンスターの効果が発動した場合に発動できる。\nデッキから「ホール」通常罠カードまたは「落とし穴」通常罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:["下"],ta:[],al:[]},
  {n:"DDD深淵王ビルガメス",h:"でぃーでぃーでぃーしんえんおうびるがめす",e:"D/D/D Abyss King Gilgamesh",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"「DD」モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキからカード名が異なる「DD」Pモンスター２体を選んで自分のPゾーンに置き、自分は１０００ダメージを受ける。\nこの効果を発動したターン、自分は「DD」モンスターしか特殊召喚できない。\n②：リンク召喚したこのカードが相手モンスターの攻撃または相手の効果で破壊された場合に発動できる。\n自分のEXデッキ・墓地から「DD」モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"RR－ワイズ・ストリクス",h:"れいど・らぷたーず－わいず・すとりくす",e:"Raidraptor - Wise Strix",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"鳥獣族・闇属性モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから鳥獣族・闇属性・レベル４モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはリンク素材にできず、効果は無効化される。\n②：自分の「RR」Xモンスターの効果が発動した場合に発動する。\nデッキから「RUM」魔法カード１枚を自分フィールドにセットする。\n速攻魔法カードをセットした場合、そのカードはセットしたターンでも発動できる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"HSR－GOMガン",h:"はいすぴーどろいど－じーおーえむがん",e:"Hi-Speedroid Rubber Band Shooter",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"風属性モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n風属性モンスター１体を召喚する。\nこの効果を発動したターン、自分はSモンスターしかEXデッキから特殊召喚できない。\n②：EXデッキから風属性Sモンスター１体を除外して発動できる。\nレベルの合計が除外したモンスターと同じになるように、デッキからカード名が異なる「スピードロイド」モンスター２体を相手に見せ、相手はその中からランダムに１枚選ぶ。\nその１枚を自分の手札に加え、残りを墓地へ送る。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"PSYフレームロード・Λ",h:"さいふれーむろーど・らむだ",e:"PSY-Framelord Lambda",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイキック族",mo:["リンク","効果"],ma:"",tp:"",at:1700,de:"-",tx:"トークン以外のモンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分は手札の「PSYフレームギア」モンスターの効果を自分フィールドにモンスターが存在する場合でも発動できる。\n②：このカードが既にモンスターゾーンに存在する状態で、このカード以外の自分フィールドの表側表示のサイキック族モンスターが除外された場合に発動できる。\nこのターンのエンドフェイズに、デッキから「PSYフレーム」カード１枚を手札に加える。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"失楽の堕天使",h:"しつらくのだてんし",e:"Condemned Darklord",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"天使族モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分がモンスター２体のリリースを必要とする天使族モンスターのアドバンス召喚をする場合、モンスター２体をリリースせずに自分の墓地からモンスター２体を除外してアドバンス召喚できる。\n②：手札を１枚捨てて発動できる。\nデッキから「堕天使」モンスター１体を選び、手札に加えるか墓地へ送る。\n③：自分エンドフェイズに発動する。\n自分はフィールドの天使族モンスターの数×５００LP回復する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"魔弾の射手 マックス",h:"まだんのしゃしゅ　まっくす",e:"Magical Musketeer Max",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"レベル８以下の「魔弾」モンスター１体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●相手フィールドのモンスターの数までデッキから「魔弾」魔法・罠カードを手札に加える（同名カードは１枚まで）。\n\n●相手フィールドの魔法・罠カードの数までデッキから「魔弾」モンスターを特殊召喚する（同名カードは１枚まで）。\n\n②：自分・相手ターンに自分は「魔弾」魔法・罠カードを手札から発動できる。",li:"",lk:["下"],ta:[],al:[]},
  {n:"マジシャン・オブ・カオス",h:"まじしゃん・おぶ・かおす",e:"Magician of Chaos",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「カオス・フォーム」により降臨。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ブラック・マジシャン」として扱う。\n②：１ターンに１度、魔法・罠カードの効果が発動した時、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：儀式召喚したこのカードが戦闘・効果で破壊された場合に発動できる。\n手札から「マジシャン・オブ・カオス」以外の「カオス」儀式モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超魔導騎士－ブラック・キャバルリー",h:"ちょうまどうきし－ぶらっく・きゃばるりー",e:"Dark Cavalry",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2300,tx:"「ブラック・マジシャン」＋戦士族モンスター\n①：このカードの攻撃力は、お互いのフィールド・墓地の魔法・罠カードの数×１００アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：フィールドのカードを対象とする魔法・罠・モンスターの効果が発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"運命のドロー",h:"うんめいのどろー",e:"Draw of Fate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のLPが相手より少なく、フィールドの攻撃力が一番高いモンスターが相手フィールドに存在する場合に発動できる。\nデッキからカード名が異なるカード３枚を選んで相手に見せ、その３枚をシャッフルしてデッキの上に戻す。\nその後、自分はデッキから１枚ドローする。\nこのカードの発動後、ターン終了時まで自分は魔法・罠カードをセットできず、魔法・罠・モンスターの効果を１度しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO グランドマン",h:"えれめんたるひーろー　ぐらんどまん",e:"Elemental HERO Grandmerge",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「HERO」通常モンスター×２\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力・守備力は、このカードの融合素材としたモンスターの元々のレベルの合計×３００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時、このカードをリリースして発動できる。\nEXデッキから「E・HERO」融合モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターは自身のレベル以下のレベルを持つモンスターを攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダブルヒーローアタック",h:"だぶるひーろーあたっく",e:"Double Hero Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「E・HERO ネオス」を融合素材とする融合モンスターが存在する場合、自分の墓地の「HERO」融合モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シグナル・ウォリアー",h:"しぐなる・うぉりあー",e:"Signal Warrior",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：お互いのスタンバイフェイズ毎に発動する。\nこのカード及びフィールドゾーンの表側表示のカード全てにシグナルカウンターを１つずつ置く。\n②：シグナルカウンターが置かれているこのカードは戦闘及び相手の効果では破壊されない。\n③：１ターンに１度、自分・相手フィールドのシグナルカウンターを以下の数だけ取り除き、その効果を発動できる。\n\n●４：相手に８００ダメージを与える。\n\n●７：自分はデッキから１枚ドローする。\n\n●１０：フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"星屑の願い",h:"すたーだすとうぃっしゅ",e:"Stardust Wish",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの「スターダスト」Sモンスターが自身の効果を発動するためにリリースされた場合、そのモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n②：このカードの①の効果で特殊召喚したモンスターは攻撃表示の場合、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.39 希望皇ホープ・ダブル",h:"なんばーず３９　きぼうおうほーぷ・だぶる",e:"Number 39: Utopia Double",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2500,tx:"レベル４モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nデッキから「ダブル・アップ・チャンス」１枚を手札に加える。\nその後、「No.39 希望皇ホープ・ダブル」以外の「希望皇ホープ」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は倍になり、直接攻撃できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"希望皇オノマトピア",h:"きぼうおうおのまとぴあ",e:"Utopic Onomatopoeia",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名はルール上「ズババ」、「ガガガ」、「ゴゴゴ」、「ドドド」カードとしても扱う。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「希望皇オノマトピア」以外の以下のモンスターをそれぞれ１体まで守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n\n●「ズババ」モンスター\n●「ガガガ」モンスター\n●「ゴゴゴ」モンスター\n●「ドドド」モンスター",li:"",lk:[],ta:[],al:[]},
  {n:"シャイニング・ドロー",h:"しゃいにんぐ・どろー",e:"Shining Draw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分ドローフェイズに通常のドローをしたこのカードを公開し続ける事で、そのターンのメインフェイズ１に、自分フィールドの「希望皇ホープ」Xモンスター１体を対象として、以下の効果から１つを選択して発動できる。\n\n●デッキ・EXデッキからカード名が異なる「ZW」モンスターを任意の数だけ選び、装備カード扱いとして対象のモンスターに装備する。\n\n●対象の自分のモンスターとはカード名が異なる「希望皇ホープ」Xモンスター１体を、そのモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・アドバンス・ドラゴン",h:"おっどあいず・あどばんす・どらごん",e:"Odd-Eyes Advance Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードはレベル５以上のモンスター１体をリリースしてアドバンス召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\n相手フィールドのモンスター１体を選んで破壊し、そのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカードが戦闘でモンスターを破壊した時に発動できる。\n自分の手札・墓地から「オッドアイズ・アドバンス・ドラゴン」以外のレベル５以上のモンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMスマイル・マジシャン",h:"えんためいとすまいる・まじしゃん",e:"Performapal Smile Sorcerer",t:"monster",a:"光",l:8,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：元々の攻撃力より高い攻撃力を持つ自分フィールドのモンスターが戦闘・効果で破壊された場合に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の①②のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「スマイル」魔法・罠カード１枚を手札に加える。\n②：自分フィールドのモンスターが「EM」モンスター、「魔術師」Pモンスター、「オッドアイズ」モンスターのみで、このカードの攻撃力が元々の攻撃力より高い場合に発動できる。\n元々の攻撃力より高い攻撃力を持つ自分フィールドのモンスターの数だけ自分はデッキからドローする。\nこのターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魂のペンデュラム",h:"たましいのぺんでゅらむ",e:"Soul Pendulum",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分のPゾーンのカード２枚を対象として発動できる。\n対象のカードのPスケールをそれぞれ、１つ上げるか下げる（最小１まで）。\n②：自分のPモンスターがP召喚される度にこのカードにカウンターを１つ置く。\n③：フィールドのPモンスターの攻撃力は、このカードのカウンターの数×３００アップする。\n④：このカードのカウンターを３つ取り除いて発動できる。\nこのターン、自分は通常のP召喚に加えて１度だけ、自分メインフェイズにモンスターをP召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・エンチャンター",h:"さいばーす・えんちゃんたー",e:"Cyberse Enchanter",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「サイバース・ウィザード」を素材としてリンク召喚されている場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更し、その効果はターン終了時まで無効化される。\nこの効果は相手ターンでも発動できる。\n②：このカードが戦闘または相手の効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「サイバース・ウィザード」１体を選んで特殊召喚する。",li:"",lk:["右","下","左"],ta:[],al:[]},
  {n:"バックアップ・スーパーバイザー",h:"ばっくあっぷ・すーぱーばいざー",e:"Backup Supervisor",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「バックアップ・セクレタリー」を素材としてリンク召喚されている場合にこのカードのリンク先の自分のモンスターが、相手モンスターと戦闘を行ったダメージステップ終了時に発動できる。\n手札からサイバース族モンスター１体を特殊召喚する。\n②：このカードが戦闘または相手の効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「バックアップ・セクレタリー」１体を選んで特殊召喚する。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"デコード・エンド",h:"でこーど・えんど",e:"Decode Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「デコード・トーカー」１体を対象として発動できる。\nそのモンスターのリンク先のモンスターの数によって、このターン以下の効果を適用する。\n\n●１体以上：そのモンスターの攻撃力は、そのリンク先のモンスターの数×５００アップする。\n\n●２体以上：そのモンスターが戦闘で破壊したモンスターはダメージ計算後に除外される。\n\n●３体：そのモンスターが戦闘で相手モンスターを破壊したダメージ計算後に発動する。\n相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ポテト＆チップス",h:"ぽてとあんどちっぷす",e:"",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:200,de:200,tx:"いつも仲良しポテトとチップス。\nポテトチップスをかじりながら部屋でごろごろ。\nコンソメパンチも美味しい。\nのりしおも美味しい。\nポテトとチップスの美味しい生活。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の開封",h:"たましいのかいふう",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにモンスターが存在し、自分フィールドに通常モンスターが存在する場合に発動できる。\nデッキから通常モンスター５体を選ぶ。\nその内の１体を手札に加え、残りのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"農園からの配送",h:"のうえんからのはいそう",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに通常モンスターが存在する場合、除外されている自分の通常モンスターを３体まで対象として発動できる。\nそのモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ラクーン",h:"さらまんぐれいとらくーん",e:"Salamangreat Raccoon",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の「サラマングレイト」モンスターが相手モンスターの攻撃対象に選択された時、このカードを手札から墓地へ送り、その戦闘を行うモンスター２体を対象として発動できる。\n対象の相手モンスターの攻撃力分だけ自分のLPを回復する。\nこのターン、対象の自分のモンスターは戦闘では破壊されない。\n②：このカードが墓地に存在し、自分の「サラマングレイト」モンスターが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣モル",h:"さらまんぐれいともる",e:"Salamangreat Mole",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分がリンクモンスターのリンク召喚に成功したターンの自分メインフェイズに発動できる。\n手札のこのカードをリンクモンスターのリンク先となる自分フィールドに特殊召喚する。\n②：自分フィールドにモンスターが存在しない場合、墓地のこのカードを除外し、自分の墓地の「サラマングレイト」カード５枚を対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ガゼル",h:"さらまんぐれいとがぜる",e:"Salamangreat Gazelle",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「転生炎獣ガゼル」以外の「サラマングレイト」モンスターが自分の墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「転生炎獣ガゼル」以外の「サラマングレイト」カード１枚を墓地へ送る。",li:1,lk:[],ta:[],al:[]},
  {n:"転生炎獣スピニー",h:"さらまんぐれいとすぴにー",e:"Salamangreat Spinny",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「サラマングレイト」カードが存在する場合、このカードを手札から捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。\n②：自分フィールドに「転生炎獣スピニー」以外の「サラマングレイト」モンスターが存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣フォウル",h:"さらまんぐれいとふぉうる",e:"Salamangreat Fowl",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「転生炎獣フォウル」以外の「サラマングレイト」モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：手札及び自分フィールドの表側表示のカードの中から、「サラマングレイト」カード１枚を墓地へ送り、相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nこのターン、そのセットされた魔法・罠カードは発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣Bバイソン",h:"さらまんぐれいとびーとばいそん",e:"Salamangreat Beat Bison",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地に「サラマングレイト」モンスターが３体以上存在する場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：相手フィールドの表側表示のカードの数まで、自分の墓地の炎属性リンクモンスターを対象として発動できる。\nそのモンスターをEXデッキに戻す。\nその後、戻したカードの数まで相手フィールドの表側表示のカードを選んで、ターン終了時までその効果を無効にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣の炎陣",h:"さらまんぐれいと・さーくる",e:"Salamangreat Circle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n●デッキから「サラマングレイト」モンスター１体を手札に加える。\n●自身と同名のモンスターを素材としてリンク召喚した自分フィールドの「サラマングレイト」リンクモンスター１体を対象として発動できる。\nこのターン、そのリンクモンスターは自身以外のモンスターの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣の烈爪",h:"さらまんぐれいと・くろー",e:"Salamangreat Claw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「サラマングレイト」モンスターにのみ装備可能。\n①：「転生炎獣の烈爪」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターは、戦闘・効果では破壊されず、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：装備モンスターが自身と同名のモンスターを素材としてリンク召喚した自分の「サラマングレイト」リンクモンスターの場合、装備モンスターは１度のバトルフェイズ中にそのリンクマーカーの数までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サラマングレイト・レイジ",h:"さらまんぐれいと・れいじ",e:"Salamangreat Rage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n●手札及び自分フィールドの表側表示モンスターの中から、「サラマングレイト」モンスター１体を墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n●自身と同名のモンスターを素材としてリンク召喚した自分フィールドの「サラマングレイト」リンクモンスター１体を対象として発動できる。\nそのモンスターのリンクマーカーの数まで、相手フィールドのカードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サラマングレイト・ロアー",h:"さらまんぐれいと・ろあー",e:"Salamangreat Roar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに「サラマングレイト」リンクモンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n②：このカードが墓地に存在する状態で、自身と同名のモンスターを素材として「サラマングレイト」リンクモンスターが自分フィールドにリンク召喚された場合に発動できる。\nこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ミラージュスタリオ",h:"さらまんぐれいとみらーじゅすたりお",e:"Salamangreat Miragestallio",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:900,tx:"レベル３モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nデッキから「サラマングレイト」モンスター１体を守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は炎属性以外のモンスターの効果を発動できない。\n②：X召喚したこのカードが「サラマングレイト」リンクモンスターのリンク素材として墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ベイルリンクス",h:"さらまんぐれいとべいるりんくす",e:"Salamangreat Balelynx",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:500,de:"-",tx:"レベル４以下のサイバース族モンスター１体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「転生炎獣の聖域」１枚を手札に加える。\n②：自分フィールドの「サラマングレイト」カードが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:["下"],ta:[],al:[]},
  {n:"プラチナ・ガジェット",h:"ぷらちな・がじぇっと",e:"Platinum Gadget",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"機械族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：自分メインフェイズに発動できる。\n手札からレベル４以下の機械族モンスター１体をこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキからレベル４の「ガジェット」モンスター１体を特殊召喚する。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"ファイアウォール・ガーディアン",h:"ふぁいあうぉーる・がーでぃあん",e:"Firewall Guardian",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがサイバース族リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：自分と相手のリンクモンスター同士が戦闘を行う攻撃宣言時に墓地のこのカードを除外して発動できる。\nその攻撃を無効にし、その相手モンスターはターン終了時まで、元々の攻撃力が０になり、自身以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"グリッド・スィーパー",h:"ぐりっど・すぃーぱー",e:"Grid Sweeper",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールド魔法カードが表側表示で存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：墓地のこのカード及び自分フィールドのリンクモンスター１体を除外して発動できる。\n相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣フェネック",h:"さらまんぐれいとふぇねっく",e:"Salamangreat Fennec",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、EXデッキから特殊召喚された自分フィールドのサイバース族モンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードがリンク２以上のサイバース族リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\nデッキから「サラマングレイト」通常魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オーバーフロー・ドラゴン",h:"おーばーふろー・どらごん",e:"Overflow Dragon",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドのモンスターが効果で破壊された時に発動できる。\nこのカードを手札から特殊召喚する。\n２体以上のフィールドのモンスターが効果で破壊された時に発動した場合、さらに自分フィールドに「オーバーフロートークン」（ドラゴン族・闇・星１・攻／守０）１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・フィフィネラグ",h:"おるたーがいすと・ふぃふぃねらぐ",e:"Altergeist Fifinellag",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、相手は「オルターガイスト・フィフィネラグ」以外の自分フィールドの「オルターガイスト」モンスターを、攻撃対象に選択できず、効果の対象にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・エスクリマメンチ",h:"だいなれすらー・えすくりまめんち",e:"Dinowrestler Eskrimamenchi",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ダイナレスラー」モンスターが存在する場合、このカードはリリースなしで召喚できる。\n②：このカードが墓地に存在し、自分ターンに相手がモンスターの特殊召喚に成功した場合、自分の墓地のレベル４以下の「ダイナレスラー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・コエロフィシラット",h:"だいなれすらー・こえろふぃしらっと",e:"Dinowrestler Coelasilat",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:800,tx:"このカード名の①の方法による特殊召喚はデュエル中に１度しかできない。\n①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードをリンク召喚の素材とする場合、「ダイナレスラー」モンスターのリンク召喚にしか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・マーシャルアンガ",h:"だいなれすらー・まーしゃるあんが",e:"Dinowrestler Martial Anga",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：自分の「ダイナレスラー」モンスターが、その攻撃力以上の攻撃力を持つモンスターと戦闘を行うダメージ計算時、このカードを手札から墓地へ送って発動できる。\nその自分のモンスターはその戦闘では破壊されず、そのダメージステップ終了後にバトルフェイズを終了する。\n②：このカードがこのカードの効果を発動するために墓地へ送られたターンのエンドフェイズに、相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドローガイ",h:"ですてにーひーろー どろーがい",e:"Destiny HERO - Drawhand",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「HERO」モンスターの効果で特殊召喚に成功した場合に発動できる。\nお互いのプレイヤーは、それぞれデッキから１枚ドローする。\n②：このカードが墓地へ送られた場合、次のスタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"サイキック・リフレクター",h:"さいきっく・りふれくたー",e:"Psi-Reflector",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n「サイキック・リフレクター」以外の「バスター・モード」のカード名が記されたカードまたは「バスター・モード」１枚をデッキから手札に加える。\n②：手札の「バスター・モード」１枚を相手に見せ、「サイキック・リフレクター」以外の「バスター・モード」のカード名が記された自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、そのレベルを４つまで上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・スナイパー",h:"ばすたー・すないぱー",e:"Assault Sentinel",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n手札・デッキから「バスター・スナイパー」以外の「バスター・モード」のカード名が記されたモンスター１体を特殊召喚する。\nこの効果を発動したターン、自分はSモンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドの表側表示モンスター１体を対象として発動できる。\nEXデッキのSモンスター１体を相手に見せ、対象のモンスターの種族・属性はターン終了時まで、見せたモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ハルバード・キャノン／バスター",h:"てっくじーなす はるばーど・きゃのんすらっしゅばすたー",e:"T.G. Halberd Cannon/Assault Mode",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4500,de:4500,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスター及び相手フィールドの特殊召喚されたモンスターを全て除外する。\n②：フィールドのこのカードが破壊された時、自分の墓地の「TG ハルバード・キャノン」１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"超量士ホワイトレイヤー",h:"ちょうりょうしほわいとれいやー",e:"Super Quantum White Layer",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:2400,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、光属性以外の「超量」モンスター１体を墓地へ送って発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「超量」モンスター１体を墓地へ送る。\nこのカードの属性・レベルはそのモンスターと同じになる。\n③：このカードが墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「超量妖精アルファン」１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオフレムベル・レディ",h:"ねおふれむべる・れでぃ",e:"Neo Flamvell Lady",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から炎属性モンスター１体を墓地へ送り、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。\n②：このカードが既にモンスターゾーンに存在する状態で、相手の墓地からカードが除外された場合に発動できる。\nデッキから「ネオフレムベル・レディ」以外の守備力２００以下の炎属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"空牙団の伝令 フィロ",h:"くうがだんのでんれい ふぃろ",e:"Filo, Messenger Fur Hire",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の伝令 フィロ」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、自分の墓地の「空牙団」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"氷の魔妖－雪娘",h:"つららのまやかし－ゆきむすめ",e:"Yuki-Musume, the Ice Mayakashi",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドに「氷の魔妖－雪娘」以外の「魔妖」カードが存在する場合に発動できる。\nこのカードを特殊召喚する。\nその後、デッキからアンデット族モンスター１体を墓地へ送る。\n②：このカードがモンスターゾーンに存在する限り、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"夢幻転星イドリース",h:"あすとろいめあいどりーす",e:"Knightmare Incarnation Idlee",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2100,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのリンクモンスターのリンクマーカーの合計が８以上の場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：相手フィールドのリンクモンスターの数が自分フィールドのリンクモンスターより多い状態で、このカードが特殊召喚に成功した場合に発動できる。\nフィールドのリンクモンスターを全て墓地へ送る。\n③：自分フィールドのレベル９モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の守護竜メロダーク",h:"せいいぶつのしゅごりゅうめろだーく",e:"World Legacy Guardragon Mardark",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:3000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地から通常モンスター２体を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力・守備力は、自分フィールドのドラゴン族モンスターの数×５００ダウンする。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードとは元々の種族・属性が異なるレベル９モンスター１体を自分の墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機怪神エクスクローラー",h:"でうすえくすくろーらー",e:"Deus X-Krawler",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：裏側表示のこのモンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードを表側守備表示にして発動できる。\nその発動を無効にし破壊する。\n②：リバースしたこのカードがモンスターゾーンに存在する限り、相手フィールドのモンスターが発動した効果は無効化される。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードとは元々の種族・属性が異なるレベル９モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"妖醒龍ラルバウール",h:"ようせいりゅうらるばうーる",e:"Omni Dragon Brotaur",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドのモンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが特殊召喚に成功した場合、フィールドの表側表示モンスター１体を対象として発動できる。\n自分の手札を１枚選んで捨て、対象のモンスターと同じ種族・属性でカード名が異なるモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ベトレイヤー",h:"かおす・べとれいやー",e:"Chaos Betrayer",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地から「カオス・ベトレイヤー」以外の光属性と闇属性のモンスターを１体ずつ除外して発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが特殊召喚に成功した場合、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"擾乱騒蛇ラウドクラウド",h:"じょうらんそうじゃらうどくらうど",e:"Loud Cloud the Storm Serpent",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地から炎属性と風属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から炎属性モンスター１体を除外し、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこのカードの攻撃力は破壊したモンスターの元々の攻撃力分アップする。\n②：自分の墓地から風属性モンスター１体を除外し、相手の魔法＆罠ゾーンのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・スライドルフィン",h:"えくしーず・すらいどるふぃん",e:"Xyz Slidolphin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにXモンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドにXモンスターが特殊召喚された場合、そのXモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星向鳥",h:"ほしむくどり",e:"Star Staring Starling",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：このカードはメインモンスターゾーンに存在する限り、その位置によって以下の効果を得る。\n\n●左端：このカードのレベルは４つ上がる。\n\n●右端：このカードのレベルは３つ上がる。\n\n●中央：このカードのレベルは２つ上がる。\n\n●それ以外：このカードのレベルは１つ上がる。",li:"",lk:[],ta:[],al:[]},
  {n:"儚無みずき",h:"はなみずき",e:"Ghost Sister & Spooky Dogwood",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できず、相手ターンでも発動できる。\n①：このカードを手札から捨てて発動できる。\nこのターン、以下の効果を適用する。\n●相手がメインフェイズ及びバトルフェイズに効果モンスターの特殊召喚に成功する度に、自分はそのモンスターの攻撃力分だけLPを回復する。\nこの効果で自分のLPが回復しなかった場合、エンドフェイズに自分のLPは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハンディ・ギャロップ",h:"はんでぃ・ぎゃろっぷ",e:"Handigallop",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは直接攻撃できない。\n②：このカードの攻撃力はお互いのLPの差の数値分アップする。\n③：自分のLPが相手より多い場合、このカードの攻撃で発生する相手への戦闘ダメージは代わりに自分が受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"魔獣皇帝ガーゼット",h:"まじゅうかいざーがーぜっと",e:"Emperor Maju Garzett",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、このカードを特殊召喚するためにリリースしたモンスターの元々の攻撃力を合計した数値になる。\n②：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズ中に魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ロー・キューピット",h:"ろー・きゅーぴっと",e:"Cupid Dunk",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードは、このカードより高いレベルを持つモンスター以外のモンスターとの戦闘では破壊されない。\n②：自分スタンバイフェイズに発動できる。\nこのカードのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀の創造主－クリオルター",h:"でびりちゃるのそうぞうしゅ－くりおるたー",e:"Crealtar, the Impcantation Originator",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:3000,tx:"「魔神儀の祝誕」により降臨。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札のこのカードを相手に見せて発動できる。\n手札を１枚選んで捨て、その後、レベルの合計が１０になるように自分の墓地の「魔神儀」モンスターを選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主のデッキに戻る。\n②：このカードがモンスターゾーンに存在する限り、儀式モンスター以外の自分フィールドの「魔神儀」モンスターは、攻撃力が２０００アップし、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・キメラ・Tレッスル",h:"だいなれすらー・きめら・てぃーれっする",e:"Dinowrestler Chimera T Wrextle",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:0,tx:"「ダイナレスラー」リンクモンスター＋「ダイナレスラー」モンスター\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：相手は他のモンスターを攻撃対象に選択できない。\n④：このカードが戦闘でモンスターを破壊した場合に発動する。\nこのカードの攻撃力は５００アップする。\n⑤：このカードが効果で破壊された場合に発動する。\n相手の攻撃表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ドミネイトガイ",h:"ですてにーひーろー どみねいとがい",e:"Destiny HERO - Dominance",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2600,tx:"「D-HERO」モンスター×３\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分または相手のデッキの上からカードを５枚確認し、好きな順番でデッキの上に戻す。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。\n③：融合召喚したこのカードが戦闘・効果で破壊された場合、自分の墓地のレベル９以下の「D-HERO」モンスター３体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星杯の守護竜アルマドゥーク",h:"せいはいのしゅごりゅうあるまどぅーく",e:"World Chalice Guardragon Almarduke",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2600,tx:"リンクモンスター×３\nこのカードは融合召喚及び以下の方法でのみEXデッキから特殊召喚できる。\n●自分フィールドの上記カードをリリースした場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードは相手モンスター全てに１回ずつ攻撃できる。\n②：このカードが相手のリンクモンスターと戦闘を行う攻撃宣言時、その相手モンスターとリンクマーカーの数が同じリンクモンスターを自分のフィールド・墓地から１体除外して発動できる。\nその相手モンスターを破壊し、その元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・ドラッグウィリオン",h:"おるたーがいすと・どらっぐうぃりおん",e:"Altergeist Dragvirion",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：特殊召喚された相手モンスターの攻撃宣言時に、自分フィールドの「オルターガイスト」モンスター１体を持ち主の手札に戻して発動できる。\nその攻撃を無効にする。\n②：このカードがリリースされ墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・ギガ・スピノサバット",h:"だいなれすらー・ぎが・すぴのさばっと",e:"Dinowrestler Giga Spinosavate",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:0,tx:"恐竜族チューナー＋チューナー以外のモンスター１体以上\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n③：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n④：このカードが戦闘・効果で破壊される場合、代わりに自分フィールドのカード１枚を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"星杯の神子イヴ",h:"せいはいのみこいう゛",e:"Ib the World Chalice Justiciar",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1800,de:2100,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードをS召喚する場合、自分フィールドの「星杯」通常モンスター１体をチューナーとして扱う事ができる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nデッキから「星遺物」カード１枚を手札に加える。\n②：S召喚したこのカードが墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「星杯の神子イヴ」以外の「星杯」モンスター１体を選んで特殊召喚する。",li:0,lk:[],ta:[],al:[]},
  {n:"ファイアウォール・X・ドラゴン",h:"ふぁいあうぉーる・えくしーど・どらごん",e:"Firewall eXceed Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル４モンスター×２体以上\n①：X召喚したこのカードの攻撃力は、このカードとリンク状態になっているリンクモンスターのリンクマーカーの数×５００アップする。\n②：このカードのX素材を２つ取り除き、自分の墓地のリンク４のサイバース族リンクモンスター１体を対象として発動できる。\nそのモンスターをこのカードとリンク状態となるように自分フィールドに特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できず、直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超量機獣ラスターレックス",h:"ちょうりょうきじゅうらすたーれっくす",e:"Super Quantal Mech Beast Lusterrex",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:1900,tx:"レベル７モンスター×２\n①：X素材が無いこのカードは攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nこのカードが「超量士ホワイトレイヤー」をX素材としている場合、この効果は相手ターンでも発動できる。\n③：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドから「超量士」モンスター１体を選び、このカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"宵星の機神ディンギルス",h:"しーおるふぇごーるでぃんぎるす",e:"Dingirsu, the Orcust of the Evening Star",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"レベル８モンスター×２\n自分は「宵星の機神ディンギルス」を１ターンに１度しか特殊召喚できず、自分フィールドの「オルフェゴール」リンクモンスターの上に重ねてX召喚する事もできる。\n①：このカードが特殊召喚に成功した場合、以下の効果から１つを選択して発動できる。\n●相手フィールドのカード１枚を選んで墓地へ送る。\n●除外されている自分の機械族モンスター１体を選び、このカードの下に重ねてX素材とする。\n②：自分フィールドのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ティーチャーマドルチェ・グラスフレ",h:"てぃーちゃーまどるちぇ・ぐらすふれ",e:"Madolche Teacher Glassouffle",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2500,tx:"レベル４「マドルチェ」モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、フィールドの「マドルチェ」モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターは自身以外のモンスターの効果を受けない。\nこの効果は相手ターンでも発動できる。\n②：このカードがモンスターゾーンに存在し、「マドルチェ」カードが自分の墓地へ送られた場合に発動できる。\nお互いの墓地のカードを合計２枚まで選び、持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバース・リマインダー",h:"さいばーす・りまいんだー",e:"Cyberse Reminder",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:800,tx:"レベル３モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分はサイバース族モンスターしかEXデッキから特殊召喚できない。\n①：このカードのX素材を１つ取り除き、自分の墓地の「サイバネット」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：X召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキからサイバース族・レベル４モンスター２体を効果を無効にして特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"デリンジャラス・ドラゴン",h:"でりんじゃらす・どらごん",e:"Dillingerous Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"ドラゴン族・闇属性モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手エンドフェイズに、このターン攻撃宣言をしていない相手フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、その元々の攻撃力分のダメージを相手に与える。\n②：このカードが墓地に存在し、自分フィールドに「ヴァレット」モンスターが特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"ダイナレスラー・テラ・パルクリオ",h:"だいなれすらー・てら・ぱるくりお",e:"Dinowrestler Terra Parkourio",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「ダイナレスラー」モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「ワールド・ダイナ・レスリング」を発動した場合、自分の墓地の「ダイナレスラー」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードがリンク素材として墓地へ送られた場合、自分の墓地の「ダイナレスラー」モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして守備表示で特殊召喚する。\nこのターン、自分は「ダイナレスラー」モンスターしか特殊召喚できない。",li:"",lk:["上","左"],ta:[],al:[]},
  {n:"剛鬼ザ・ブレード・オーガ",h:"ごうきざ・ぶれーど・おーが",e:"Gouki The Blade Ogre",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"「剛鬼」モンスター２体以上\n①：このカードの攻撃力は、このカードのリンク先のモンスターの数×３００アップする。\n②：１ターンに１度、このカードのリンク先の自分または相手のモンスター１体をリリースして発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:["上","左下","下"],ta:[],al:[]},
  {n:"剛鬼ザ・ソリッド・オーガ",h:"ごうきざ・そりっど・おーが",e:"Gouki The Solid Ogre",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"「剛鬼」モンスター２体以上\n①：このカードのリンク先に「剛鬼」モンスターが存在する限り、このカードは戦闘・効果では破壊されない。\n②：１ターンに１度、「剛鬼ザ・ソリッド・オーガ」以外の自分のメインモンスターゾーンの「剛鬼」モンスター１体を対象として発動できる。\nその自分のモンスターの位置を、このカードのリンク先となる自分のメインモンスターゾーンに移動する。\nこの効果は相手ターンでも発動できる。",li:"",lk:["上","左","下"],ta:[],al:[]},
  {n:"X・HERO クロスガイ",h:"えくすとらひーろー くろすがい",e:"Xtra HERO Cross Crusader",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"戦士族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分は「HERO」モンスターしか特殊召喚できない。\n①：このカードがリンク召喚に成功した場合、自分の墓地の「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドの「D-HERO」モンスター１体をリリースして発動できる。\nリリースしたモンスターとカード名が異なる「HERO」モンスター１体をデッキから手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"真超量機神王ブラスター・マグナ",h:"しんちょうりょうきしんおうぶらすたー・まぐな",e:"Neo Super Quantal Mech King Blaster Magna",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"「超量」モンスターを含む効果モンスター２体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：リンク召喚したこのカードは相手の効果では破壊されない。\n②：同名カードが自分フィールドに存在しない「超量」XモンスターがEXデッキからこのカードのリンク先に特殊召喚される度に発動する。\n自分はデッキから１枚ドローする。\n③：このカードのリンク先のXモンスターが戦闘または相手の効果で破壊された場合に発動できる。\nそのXモンスターと元々の属性が同じ「超量」モンスター１体をデッキから特殊召喚する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"双穹の騎士アストラム",h:"じゃっくないつ・ぱらでぃおんあすとらむ",e:"Mekk-Knight Crusadia Avramax",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"EXデッキから特殊召喚されたモンスター２体以上\n①：リンク召喚したこのカードがモンスターゾーンに存在する限り、このカードは相手の効果の対象にならず、相手は他のモンスターを攻撃対象に選択できない。\n②：このカードが特殊召喚されたモンスターと戦闘を行うダメージ計算時に１度、発動できる。\nこのカードの攻撃力はそのダメージ計算時のみ、その相手モンスターの攻撃力分アップする。\n③：リンク召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\nフィールドのカード１枚を選んで持ち主のデッキに戻す。",li:"",lk:["左","右","左下","右下"],ta:[],al:[]},
  {n:"星神器デミウルギア",h:"せいしんきでみうるぎあ",e:"World Gears of Theurlogical Demiurgy",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:3500,de:"-",tx:"レベル５以上のモンスター３体\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\nこのカードはリンク素材にできない。\n①：リンク召喚したこのカードは他のモンスターの効果を受けない。\n②：種族と属性が異なるモンスター３体を素材としてこのカードがリンク召喚されている場合、自分メインフェイズに発動できる。\nこのカード以外のフィールドのカードを全て破壊する。\n③：相手がEXデッキからモンスターを特殊召喚した場合に発動できる。\nデッキから「星遺物」モンスター１体を特殊召喚する。",li:"",lk:["左","右","下"],ta:[],al:[]},
  {n:"落消しのパズロミノ",h:"おちけしのぱずろみの",e:"Puzzlomino, the Drop-n-Deleter",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1300,de:"-",tx:"レベルが異なるモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、このカードのリンク先にモンスターが表側表示で特殊召喚された場合、１～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。\n②：自分と相手のフィールドから、同じレベルのモンスターを１体ずつ対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"揚陸群艦アンブロエール",h:"ようりくぐんかんあんぶろえーる",e:"Amphibious Swarmship Amblowhale",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2600,de:"-",tx:"効果モンスター２体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの攻撃力は、お互いの墓地のリンクモンスターの数×２００アップする。\n②：このカードが破壊された場合、自分または相手の墓地のリンク３以下のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\n③：このカードが墓地に存在し、フィールドのリンク３以下のモンスターが戦闘・効果で破壊された場合、墓地のこのカードを除外して発動できる。\nフィールドのカード１枚を選んで破壊する。",li:"",lk:["左","右","下","右下"],ta:[],al:[]},
  {n:"サイバネット・マイニング",h:"さいばねっと・まいにんぐ",e:"Cynet Mining",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚墓地へ送って発動できる。\nデッキからレベル４以下のサイバース族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣の再起",h:"さらまんぐれいと・りきゅあらんす",e:"Salamangreat Recureance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のカード名が異なる「サラマングレイト」モンスター２体を対象として発動できる。\nそのモンスターを手札に加える。\n②：セットされたこのカードが効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「転生炎獣の再起」以外の「サラマングレイト」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"タイラント・ダイナ・フュージョン",h:"たいらんと・だいな・ふゅーじょん",e:"Tyrant Dino Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：「ダイナレスラー」融合モンスターカードによって決められた融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターは、フィールドに表側表示で存在する限り１度だけ、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"フュージョン・デステニー",h:"ふゅーじょん・ですてにー",e:"Fusion Destiny",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・デッキから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「D-HERO」モンスターを融合素材とするその融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに破壊される。\nこのカードの発動後、ターン終了時まで自分は闇属性の「HERO」モンスターしか特殊召喚できない。",li:0,lk:[],ta:[],al:[]},
  {n:"バスター・モード・ゼロ",h:"ばすたー・もーど・ぜろ",e:"Assault Mode Zero",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのSモンスター１体をリリースして発動できる。\nそのモンスターのカード名が含まれる「／バスター」モンスター１体を、「バスター・モード」による特殊召喚扱いとして手札から特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\n手札・デッキから「バスター・モード」１枚を選んで自分の魔法＆罠ゾーンにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超量要請アルファンコール",h:"ちょうりょうようせいあるふぁんこーる",e:"Super Quantal Alphancall Appeal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の「超量」モンスターが戦闘で破壊された時に発動できる。\nEXデッキから「超量機獣」Xモンスター１体を特殊召喚する。\nその後、そのXモンスターカードにカード名が記された「超量士」モンスター１体を自分の手札・デッキ・墓地から選び、効果を無効にして特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔妖壊劫",h:"まやかしかいごう",e:"Mayakashi Winter",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：相手フィールドのモンスターの攻撃力・守備力は、自分の墓地の「魔妖」モンスターの種類×１００ダウンする。\n②：自分フィールドの表側表示の「魔妖」モンスター１体とこのカードを墓地へ送って発動できる。\n自分はデッキから１枚ドローする。\n③：自分の墓地からこのカードとアンデット族モンスター１体を除外し、自分の墓地の「魔妖」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"雲魔物の雲核",h:"くらうでぃあん・えあろぞる",e:"Cloudian Aerosol",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札から「雲魔物」モンスター１体を捨て、フィールドの表側表示モンスター１体を対象として発動できる。\n対象のモンスターにそのレベルの数だけフォッグカウンターを置く。\n②：自分の墓地からこのカードと「雲魔物」モンスター１体を除外して発動できる。\nデッキから「雲魔物」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の胎導",h:"せいいぶつのたいどう",e:"World Legacy Monstrosity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n●手札からレベル９モンスター１体を特殊召喚する。\n●自分フィールドのレベル９モンスター１体を対象として発動できる。\nそのモンスターとは元々の種族・属性が異なるレベル９モンスター２体をデッキから特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターは攻撃できず、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"転臨の守護竜",h:"てんりんのしゅごりゅう",e:"Guardragon Reincarnation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のフィールド・墓地から、融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。\nその際、融合素材モンスターは全てリンクモンスターでなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"テスタメント・パラディオン",h:"てすためんと・ぱらでぃおん",e:"Crusadia Testament",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n●このターン、自分の「パラディオン」モンスターの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。\n●自分の「パラディオン」リンクモンスターが戦闘で相手モンスターを破壊したダメージ計算後に発動できる。\nその自分のモンスターのリンクマーカーの数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔神儀の隠れ房",h:"でびりちゃるのかくれぼう",e:"Impcantation Thanatosis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、手札の「魔神儀」モンスター１体を相手に見せ、その同名モンスター２体をデッキから特殊召喚できる。\nその後、見せたモンスターをデッキに戻す。\n②：１ターンに１度、自分フィールドに儀式モンスターが特殊召喚された場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"亡龍の旋律",h:"ぼうりゅうのすとれいん",e:"Dirge of the Lost Dragon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"カード名を１つ宣言してこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、宣言されたカードの効果が発動する度に、その効果を発動したカードの元々の持ち主のLPは半分になる。\nこの効果を適用したターンのエンドフェイズにこのカードは墓地へ送られる。\n②：相手フィールドにモンスターが存在する場合、このカードは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍾洞",h:"ましょうどう",e:"Mystic Mine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、相手はモンスターの効果を発動できず、攻撃宣言もできない。\n②：自分フィールドのモンスターの数が相手フィールドのモンスターより多い場合、自分はモンスターの効果を発動できず、攻撃宣言もできない。\n③：自分・相手のエンドフェイズに、お互いのフィールドのモンスターの数が同じ場合に発動する。\nこのカードを破壊する。",li:0,lk:[],ta:[],al:[]},
  {n:"モルトシュラーク",h:"もるとしゅらーく",e:"Mordschlag",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドの通常召喚されたモンスターにのみ装備可能。\n①：装備モンスターは特殊召喚された相手フィールドのモンスターが発動した効果を受けない。\n②：装備モンスターが特殊召喚された相手モンスターと戦闘を行うダメージ計算時に発動する。\nその相手モンスターの攻撃力・守備力はそのダメージ計算時のみ、装備モンスターの元々の攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"スタンド・イン",h:"すたんど・いん",e:"Stand In",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスター１体をリリースし、そのモンスターと元々の種族・属性が同じとなる相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"強制切断",h:"きょうせいせつだん",e:"Packet Swap",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：リンクモンスター以外の自分フィールドの表側表示モンスター１体と、相手フィールドのリンクモンスター１体を対象として発動できる。\nそのモンスター２体のコントロールを入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・ホーンデッドロック",h:"おるたーがいすと・ほーんでっどろっく",e:"Altergeist Haunted Rock",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n「オルターガイスト」カードの効果によってセットされているこのカードはセットしたターンでも発動できる。\n①：このカードの発動時の効果処理として、手札から「オルターガイスト」モンスター１体を墓地へ送る。\n②：相手が罠カードを発動した時、手札から「オルターガイスト」モンスター１体を墓地へ送って発動できる。\nその効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"D－タクティクス",h:"でぃー－たくてぃくす",e:"D - Tactics",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のスタンバイフェイズに発動できる。\n自分フィールドの全ての「HERO」モンスターの攻撃力は４００アップする。\n②：自分フィールドにレベル８以上の「D-HERO」モンスターまたは「Dragoon D-END」が特殊召喚された場合に発動できる。\n相手の手札・フィールド・墓地のカード１枚を選んで除外する。\n③：魔法＆罠ゾーンのこのカードが効果で破壊された場合に発動できる。\nデッキから「D-HERO」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"バスター・リブート",h:"ばすたー・りぶーと",e:"Assault Reboot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「／バスター」モンスター１体をリリースして発動できる。\nそのモンスターとカード名が異なる「／バスター」モンスター１体を召喚条件を無視してデッキから守備表示で特殊召喚する。\n②：墓地のこのカードを除外し、「バスター・リブート」以外の自分の墓地の、「バスター・モード」のカード名が記されたカード及び「バスター・モード」を任意の数だけ対象として発動できる（同名カードは１枚まで）。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"超量合神－マグナフォーメーション",h:"ちょうりょうがっしん－まぐなふぉーめーしょん",e:"Super Quantal Union - Magnaformation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分メインフェイズ１の間、相手はフィールドの「超量」カードを効果の対象にできない。\n②：自分フィールドの「超量」Xモンスター１体を対象として発動できる。\n対象のモンスター以外の自分フィールドの表側表示モンスター１体を選び、対象のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔弾－ブラッディ・クラウン",h:"まだん－ぶらっでぃ・くらうん",e:"Magical Musket - Crooked Crown",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「魔弾－ブラッディ・クラウン」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分・相手のメインフェイズに発動できる。\n手札から「魔弾」モンスター１体を特殊召喚する。\nこの効果でモンスターが特殊召喚されたゾーンと同じ縦列の相手のメインモンスターゾーンが使用されていない場合、そのゾーンはターン終了時まで使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"虹の天気模様",h:"にじのてんきもよう",e:"The Weather Rainbowed Canvas",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「虹の天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●相手フィールドにモンスターが存在する場合、このカードを除外して発動できる。\nデッキから、このカードとカード名が異なる「天気」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はデッキからモンスターを特殊召喚できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"オルフェゴール・クリマクス",h:"おるふぇごーる・くりまくす",e:"Orcust Crescendo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに「オルフェゴール」リンクモンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし除外する。\n②：墓地のこのカードを除外して発動できる。\nデッキのモンスター及び除外されている自分のモンスターの中から、機械族・闇属性モンスター１体を選んで手札に加える。\nこの効果を発動するターン、自分は機械族・闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の齎す崩界",h:"せいいぶつのもたらすほうかい",e:"World Legacy Collapse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地及び自分フィールドの表側表示モンスターの中から、「星遺物」モンスター１体を除外し、フィールドのリンクモンスター１体を対象として発動できる。\nそのモンスターの攻撃力は除外したモンスターの元々の攻撃力分アップする。\n②：このカードが墓地に存在する状態で、自分フィールドのリンクモンスターが戦闘または相手の効果で破壊された場合、このカードを除外して発動できる。\n自分の墓地からサイバース族リンクモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の対焉",h:"せいいぶつのついえん",e:"World Legacy Cliffhanger",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：リンクモンスター同士が戦闘を行う攻撃宣言時に発動できる。\nお互いのフィールド・墓地のモンスターを全て持ち主のデッキに戻す。\nこのカードを発動したターン、お互いにリンク召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"連鎖空穴",h:"ちぇーん・ほーる",e:"Chain Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：魔法・罠・モンスターの効果の発動にチェーンして相手がモンスターの効果を発動した時に発動できる。\nその効果を無効にする。\nその後、相手はこの効果で無効にされたカードと元々のカード名が同じカード１枚を手札・デッキから選んで除外できる。\n除外しなかった場合、自分は相手の手札をランダムに１枚選んで除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"大捕り物",h:"おおとりもの",e:"Crackdown",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nそのモンスターのコントロールを得る。\nそのモンスターは自分フィールドに存在する場合、攻撃できず、効果を発動できない。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"スノーマン・エフェクト",h:"すのーまん・えふぇくと",e:"Snowman Effect",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのモンスター以外の自分フィールドのモンスターの元々の攻撃力の合計分アップする。\nこのカードを発動するターン、対象のモンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイスエット",h:"だいすえっと",e:"Dice It",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：サイコロを１回振る。\n発動ターンによって以下の効果を適用する。\n\n●自分ターン：出た目の数だけ自分の墓地からカードを選んで除外する。\n出た目が１の場合、さらに自分のデッキの上からカードを６枚墓地へ送る。\n\n●相手ターン：出た目の数だけ自分のデッキの上からカードを墓地へ送る。\n出た目が６の場合、さらに自分の墓地のカード１枚を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの超越",h:"でーもんのちょうえつ",e:"Archfiend's Ascent",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"レベル６モンスター×２\n①：このカードはモンスターゾーンに存在する限り、カード名を「デーモンの召喚」として扱う。\n②：自分フィールドの「デーモンの召喚」が戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。\n③：X召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「デーモンの召喚」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剛炎の剣士",h:"ごうえんのけんし",e:"Ferocious Flame Swordsman",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1300,de:"-",tx:"カード名が異なるモンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの戦士族モンスターの攻撃力は５００アップする。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊された場合、リンクモンスター以外の自分の墓地の戦士族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:["左下","左"],ta:[],al:[]},
  {n:"おジャマ・エンペラー",h:"おじゃま・えんぺらー",e:"Ojama Emperor",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"「おジャマ」モンスターを含む獣族モンスター３体\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：フィールドゾーンに「おジャマ・カントリー」が存在する場合、このカードは攻撃力が３０００アップし、効果では破壊されない。\n②：このカードへの攻撃で発生する自分への戦闘ダメージは代わりに相手が受ける。\n③：リンクモンスター以外の自分の墓地の「おジャマ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果を発動したターン、自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:["右下","下","左下"],ta:[],al:[]},
  {n:"決闘竜 デュエル・リンク・ドラゴン",h:"けっとうりゅう　でゅえる・りんく・どらごん",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"Sモンスターを含むモンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、EXデッキから「パワー・ツール」Sモンスターまたはレベル７・８のドラゴン族Sモンスター１体を除外して発動できる。\nそのモンスターと同じ種族・属性・レベル・攻撃力・守備力を持つ「決闘竜トークン」１体をこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：自分フィールドに「決闘竜トークン」が存在する場合、相手はこのカードを攻撃対象に選択できず、効果の対象にもできない。",li:"",lk:["右","右下","左下","左"],ta:[],al:[]},
  {n:"ギミック・パペット－キメラ・ドール",h:"ぎみっく・ぱぺっと－きめら・どーる",e:"Gimmick Puppet Chimera Doll",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"機械族モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚されている場合に発動できる。\nデッキから「ギミック・パペット」モンスター１体を選び、手札に加えるか墓地へ送る。\n自分フィールドのモンスターが「ギミック・パペット」モンスターのみの場合、さらに手札から「ギミック・パペット」モンスター１体を特殊召喚できる。\nこの効果の発動後、ターン終了時まで自分は機械族XモンスターしかEXデッキから特殊召喚できない。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"超重武者カカ－C",h:"ちょうじゅうむしゃかか－しー",e:"Superheavy Samurai Scarecrow",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"「超重武者」モンスター１体\nこのカード名の②の効果は１ターンに１度しか使用できない。\nこのカードはリンク素材にできない。\n①：自分の墓地に魔法・罠カードが存在しない場合、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：自分の墓地に魔法・罠カードが存在しない場合、手札からモンスター１体を捨て、自分の墓地の「超重武者」モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに守備表示で特殊召喚する。",li:"",lk:["左下"],ta:[],al:[]},
  {n:"転生炎獣アルミラージ",h:"さらまんぐれいとあるみらーじ",e:"Salamangreat Almiraj",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"通常召喚された攻撃力１０００以下のモンスター１体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、自分フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは相手の効果では破壊されない。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在し、通常召喚された自分のモンスターが戦闘で破壊された時に発動できる。\nこのカードを特殊召喚する。",li:"",lk:["右下"],ta:[],al:[]},
  {n:"SRビーダマシーン",h:"すぴーどろいどびーだましーん",e:"Speedroid Marble Machine",t:"monster",a:"風",l:2,ps:1,pe:"①：１ターンに１度、自分の守備表示モンスターが攻撃対象に選択された時に発動できる。\nそのモンスターを表側攻撃表示にする。\nこのターン、そのモンスターは戦闘では破壊されない。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:200,de:100,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「スピードロイド」モンスター１体を手札に加える。\nこの効果の発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"SRヘキサソーサー",h:"すぴーどろいどへきさそーさー",e:"Speedroid Hexasaucer",t:"monster",a:"風",l:4,ps:6,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、風属性Sモンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:1600,tx:"①：このカードの戦闘で発生する戦闘ダメージはお互いのプレイヤーが受ける。\n②：このカードの戦闘で発生するお互いの戦闘ダメージは半分になる。\n③：このカードがPゾーンで破壊された場合に発動できる。\n自分のEXデッキから表側表示の「スピードロイド」Pモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMクリボーダー",h:"えんためいとくりぼーだー",e:"Performapal Kuribohble",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚し、その相手モンスターの攻撃対象をこのカードに移し替えてダメージ計算を行う。\nその戦闘で自分が戦闘ダメージを受ける場合、代わりにその数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・アンセリオン・ドラゴン",h:"だーく・あんせりおん・どらごん",e:"Dark Anthelion Dragon",t:"monster",a:"闇",l:7,ps:10,pe:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力はターン終了時まで半分になる。",tr:"ドラゴン族",mo:["エクシーズ","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル７モンスター×２\nレベル７がP召喚可能な場合にEXデッキの表側表示のこのカードはP召喚できる。\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力を半分にし、その数値分このカードの攻撃力をアップする。\nこの効果は相手ターンでも発動できる。\n②：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"スターヴ・ヴェネミー・リーサルドーズ・ドラゴン",h:"すたーう゛・う゛ぇねみー・りーさるどーず・どらごん",e:"Starving Venemy Lethal Dose Dragon",t:"monster",a:"闇",l:8,ps:1,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\n相手フィールドの全ての表側表示モンスターにヴェネミーカウンターを１つずつ置く。\n②：ドラゴン族・闇属性モンスター以外のフィールドのモンスターの攻撃力はフィールドのヴェネミーカウンターの数×２００ダウンする。",tr:"ドラゴン族",mo:["融合","ペンデュラム","効果"],ma:"",tp:"",at:2800,de:2000,tx:"闇属性Pモンスター×３\n①：フィールドのカードが墓地へ送られる度に、１枚につきヴェネミーカウンター１つをこのカードに置く。\n②：ドラゴン族・闇属性モンスター以外のフィールドのモンスターの攻撃力はフィールドのヴェネミーカウンターの数×２００ダウンする。\n③：１ターンに１度、自分メインフェイズに発動できる。\n相手フィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。\n④：モンスターゾーンのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"デンジャラス・デストーイ・ナイトメアリー",h:"でんじゃらす・ですとーい・ないとめありー",e:"Dangerous Frightfur Nightmary",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:3000,tx:"「エッジインプ」モンスター＋「ファーニマル」モンスター×２\n①：このカードの攻撃力は自分ターンの間、自分の墓地の天使族・悪魔族モンスターの数×３００アップする。\n②：融合召喚したこのカードが戦闘でモンスターを破壊した時に発動できる。\nそのモンスターのレベルの数だけ、「デストーイ」、「ファーニマル」、「エッジインプ」モンスターをデッキから墓地へ送る。\n③：フィールドのこのカードを対象とする相手の効果が発動した時、EXデッキから「デストーイ」モンスター１体を除外して発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"幻魔帝トリロジーグ",h:"げんまていとりろじーぐ",e:"Phantasm Emperor Trilojig",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:4000,tx:"レベル１０モンスター×３\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、またはこのカードが既にモンスターゾーンに存在する状態で、このカード以外のモンスターが墓地から自分フィールドに特殊召喚された場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.XX インフィニティ・ダークホープ",h:"なんばーずだぶるえっくす　いんふぃにてぃ・だーくほーぷ",e:"Number XX: Utopic Dark Infinity",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"レベル１０モンスター×２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手のモンスターが戦闘で破壊され墓地へ送られた時、このカードのX素材を１つ取り除き、そのモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。\n②：このカード以外の自分フィールドの特殊召喚された表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・トランセンド",h:"しんくろ・とらんせんど",e:"Synchro Transcend",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドのSモンスター１体を対象として発動できる。\nそのモンスターより１つ高いレベルを持つSモンスター１体をEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"希望の記憶",h:"きぼうのきおく",e:"Memories of Hope",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「No.」Xモンスターの種類の数だけ、自分はデッキからドローする。",li:2,lk:[],ta:[],al:[]},
  {n:"バイバイダメージ",h:"ばいばいだめーじ",e:"Bye Bye Damage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の効果は１ターンに１度しか適用できない。\n①：自分のモンスターが攻撃されたダメージ計算時に発動できる。\nその自分のモンスターはその戦闘では破壊されない。\nその戦闘で自分が戦闘ダメージを受けた時、相手はその数値の倍の効果ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域のオゴポゴ",h:"みかいいきのおごぽご",e:"Danger! Ogopogo!",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:3000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分の全ての手札の中から、相手がランダムに１枚選び、自分はそのカードを捨てる。\nそれが「未界域のオゴポゴ」以外だった場合、さらに手札から「未界域のオゴポゴ」１体を特殊召喚し、自分はデッキから１枚ドローする。\n②：このカードが手札から捨てられた場合に発動できる。\nデッキから「未界域のオゴポゴ」以外の「未界域」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"対壊獣用決戦兵器メカサンダー・キング",h:"たいかいじゅうようけっせんへいきめかさんだー・きんぐ",e:"Super Anti-Kaiju War Machine Mecha-Thunder-King",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:2100,tx:"このカード名の④の効果はデュエル中に１度しか使用できない。\n①：お互いのメインフェイズにこのカードを手札から捨てて発動できる。\n元々の持ち主が相手となる自分フィールドの「壊獣」モンスター１体を選んで除外する。\nその後、自分の墓地からモンスター１体を選んで特殊召喚できる。\n②：「壊獣」モンスターは自分フィールドに１体しか表側表示で存在できない。\n③：フィールドのこのカードは他の「壊獣」カードの効果を受けず、「壊獣」モンスターとの戦闘では破壊されない。\n④：自分エンドフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・リューズ",h:"くろのだいばー・りゅーず",e:"Time Thief Winder",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのX素材を１つ取り除いて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「クロノダイバー・リューズ」以外の「クロノダイバー」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・ベゼルシップ",h:"くろのだいばー・べぜるしっぷ",e:"Time Thief Bezel Ship",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースし、自分フィールドの「クロノダイバー」Xモンスター１体を対象として発動できる。\n相手の墓地からカード１枚を選び、対象のモンスターの下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する場合、自分フィールドのX素材を１つ取り除いて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・レギュレーター",h:"くろのだいばー・れぎゅれーたー",e:"Time Thief Regulator",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにこのカード以外のモンスターが存在しない場合、このカードをリリースして発動できる。\nデッキから「クロノダイバー・レギュレーター」以外の「クロノダイバー」モンスター２体を守備表示で特殊召喚する（同名カードは１枚まで）。\n②：このカードが墓地に存在する状態で、自分のXモンスターが戦闘で破壊された時に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・リダン",h:"くろのだいばー・りだん",e:"Time Thief Redoer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"レベル４モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分・相手のスタンバイフェイズに発動できる。\n相手のデッキの一番上のカードをこのカードの下に重ねてX素材とする。\n②：自分・相手ターンに発動できる。\nこのカードのX素材を３種類（モンスター・魔法・罠）まで取り除く。\nその後、以下を適用する。\n\n●モンスター：このカードをエンドフェイズまで除外する。\n\n●魔法：自分はデッキから１枚ドローする。\n\n●罠：相手フィールドの表側表示のカード１枚を選んで持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・ハック",h:"くろのだいばー・はっく",e:"Time Thief Hack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドのXモンスターは特殊召喚したターンには、相手の効果の対象にならず、相手の効果では破壊されない。\n②：自分フィールドのXモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのX素材の数×３００アップする。\nさらにそのモンスターが、元々の持ち主が相手となるカードをX素材としている場合、このターンそのモンスターは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・フライバック",h:"くろのだいばー・ふらいばっく",e:"Time Thief Flyback",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの「クロノダイバー」Xモンスター１体を対象として発動できる。\n手札・デッキから「クロノダイバー」カード１枚を選び、対象のモンスターの下に重ねてX素材とする。\n②：墓地のこのカードを除外し、自分フィールドの「クロノダイバー」Xモンスター１体を対象として発動できる。\n相手の墓地からカード１枚を選び、対象のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・セクスト",h:"わるきゅーれ・せくすと",e:"Valkyrie Sechste",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ワルキューレ・セクスト」以外の「ワルキューレ」モンスター１体を特殊召喚する。\n②：自分メインフェイズに発動できる。\n相手のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・フィアット",h:"わるきゅーれ・ふぃあっと",e:"Valkyrie Vierte",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカード以外の自分フィールドの「ワルキューレ」モンスターの数だけ自分のデッキの上からカードをめくる。\nその中に通常魔法・通常罠カードがあった場合、その内の１枚を選んで手札に加え、残りのカードは全て墓地へ送る。\n無かった場合、めくったカードを全てデッキに戻す。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「ワルキューレ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"終幕の光",h:"しゅうまくのひかり",e:"Final Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１０００の倍数のLPを払い、払ったLP１０００につき１体、自分の墓地の「ワルキューレ」モンスターを対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚する。\nその後、この効果で特殊召喚したモンスターの数まで、相手は自身の墓地から攻撃力２０００以下のモンスターを選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"フライアのリンゴ",h:"ふらいあのりんご",e:"Apple of Enlightenment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ワルキューレ」モンスターの数まで相手の墓地のカードを対象として発動できる。\nそのカードを除外する。\n②：セットされているこのカードが相手の効果でフィールドから離れ、墓地へ送られた場合または除外された場合に発動できる。\n自分は自分フィールドの「ワルキューレ」モンスターの数＋１枚をデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴッドアイズ・ファントム・ドラゴン",h:"ごっどあいず・ふぁんとむ・どらごん",e:"All-Eyes Phantom Dragon",t:"monster",a:"光",l:10,ps:"",pe:"①：１ターンに１度、自分のドラゴン族Pモンスターが相手モンスターに攻撃したダメージステップ終了時に発動できる。\nその自分のモンスターは続けて攻撃できる。\nこの効果を発動するターン、自分はそのモンスターでしか攻撃宣言できない。",tr:"ドラゴン族",mo:["特殊召喚","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n「ゴッドアイズ・ファントム・ドラゴン」は１ターンに１度、ドラゴン族Pモンスターを含む自分フィールドの２体以上の全てのモンスターをリリースした場合のみ、手札・EXデッキから特殊召喚できる。\n①：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力はターン終了時まで倍になる。\n②：１ターンに１度、相手が魔法・罠カードの効果を発動した時、自分フィールドの魔法・罠カード１枚を墓地へ送って発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"スマイル・ポーション",h:"すまいる・ぽーしょん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドにモンスターが存在せず、元々の攻撃力より高い攻撃力を持つモンスターが相手フィールドに存在する場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"青眼の究極亜竜",h:"ぶるーあいず・おるたなてぃぶ・あるてぃめっとどらごん",e:"Blue-Eyes Alternative Ultimate Dragon",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:3800,tx:"「青眼の白龍」＋「青眼の白龍」＋「青眼の白龍」\n①：フィールドのこのカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n元々のカード名が「青眼の亜白龍」となるモンスターを素材としてこのカードが融合召喚されている場合、この効果の対象を２枚または３枚にできる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャン・オブ・ブラックカオス・MAX",h:"まじしゃん・おぶ・ぶらっくかおす・まっくす",e:"Magician of Black Chaos MAX",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2600,tx:"「カオス・フォーム」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのターン、相手はモンスターの効果を発動できない。\n②：このカードが戦闘で相手モンスターを破壊した時、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"守護神エクゾディア",h:"しゅごしんえくぞでぃあ",e:"Exodia, the Legendary Defender",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは特殊召喚できない。\nこのカードの①の方法で召喚したこのカードが、元々の持ち主が相手となる悪魔族・闇属性モンスターを戦闘で破壊した時、自分はデュエルに勝利する。\n①：このカードはモンスター５体をリリースして召喚する事もできる。\n②：このカードの攻撃力・守備力は、このカードを召喚するためにリリースしたモンスターの元々の攻撃力・守備力をそれぞれ合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"守護神官マナ",h:"しゅごしんかんまな",e:"Palladium Oracle Mana",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1700,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドの魔法使い族モンスター１体のみを対象とする相手の魔法・罠・モンスターの効果が発動した時に発動できる。\nこのカードを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドのレベル７以上の魔法使い族モンスターは効果では破壊されない。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャン・ガール」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"真紅眼の亜黒竜",h:"れっどあいず・おるたなてぃぶ・ぶらっくどらごん",e:"Red-Eyes Alternative Black Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは通常召喚できない。\n自分の手札・フィールドから「レッドアイズ」モンスター１体をリリースした場合に特殊召喚できる。\nこの方法による「真紅眼の亜黒竜」の特殊召喚は１ターンに１度しかできない。\n①：このカードが戦闘または相手の効果で破壊された場合、「真紅眼の亜黒竜」以外の自分の墓地のレベル７以下の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターが「真紅眼の黒竜」の場合、その元々の攻撃力は倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ネオ・カイザー・グライダー",h:"ねお・かいざー・ぐらいだー",e:"Neo Kaiser Glider",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードとモンスター１体を捨て、自分の墓地のドラゴン族の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが墓地へ送られた場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin Cross",h:"しん くろす",e:"Malefic Divide",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「Sin」モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin Selector",h:"しん せれくたー",e:"Malefic Selector",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地から「Sin」カード２枚を除外して発動できる。\n除外したカードとカード名が異なる「Sin Selector」以外の「Sin」カード２枚をデッキから手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin Tune",h:"しん ちゅーん",e:"Malefic Tune",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の「Sin」モンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分はデッキから２枚ドローする。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の「Sin」モンスターが戦闘以外で破壊された場合、墓地のこのカードを除外して発動できる。\nデッキから「Sin」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"黒・魔・導・連・弾",h:"ぶらっくついんばーすと",e:"Dark Magic Twin Burst",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ブラック・マジシャン」１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、お互いのフィールド・墓地の「ブラック・マジシャン・ガール」の攻撃力の合計分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・ミラージュ",h:"すたーだすと・みらーじゅ",e:"Stardust Mirage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドにレベル８以上のドラゴン族Sモンスターが存在する場合に発動できる。\nこのターンに戦闘または相手の効果で破壊され自分の墓地へ送られたモンスターを可能な限り特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡のマジック・ゲート",h:"きせきのまじっく・げーと",e:"Magic Gate of Miracles",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに魔法使い族モンスターが２体以上存在する場合に発動できる。\n相手フィールドの攻撃表示モンスター１体を選んで守備表示にする。\nその後、そのモンスターのコントロールを得る。\nこの効果でコントロールを得たモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"身代わりの闇",h:"みがわりのやみ",e:"Dark Sacrifice",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのカードを破壊する相手の魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にする。\nその後、デッキからレベル３以下の闇属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"方界降世",h:"ほうかいごうぜ",e:"Cubic Ascension",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時に発動できる。\nデッキから「方界胤ヴィジャム」１体を特殊召喚し、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。\n②：自分のLPが相手より２０００以上少ない場合、墓地のこのカードを除外して発動できる。\n自分の手札・デッキ・墓地から「方界胤ヴィジャム」１体を選んで特殊召喚する。\n相手フィールドにのみモンスターが存在する状態で発動した場合、さらに「方界胤ヴィジャム」を２体まで選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin パラドクスギア",h:"しん ぱらどくすぎあ",e:"Malefic Paradox Gear",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールド魔法カードが表側表示で存在する場合、このカードをリリースして発動できる。\nデッキから「Sin パラレルギア」１体を特殊召喚する。\nその後、デッキから「Sin パラドクスギア」以外の「Sin」モンスター１体を手札に加える。\n②：自分の手札の「Sin」モンスターを、自身の方法で特殊召喚するためにモンスターを除外する場合、そのモンスターの代わりにフィールド・墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin Territory",h:"しん てりとりー",e:"Malefic Territory",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、デッキから「Sin World」１枚を発動できる。\nこの効果で発動したカードがフィールドゾーンに存在する限り、お互いにフィールドゾーンのカードを効果の対象にできない。\n②：「Sin」モンスターの持つ、「「Sin」モンスターはフィールドに１体しか表側表示で存在できない」効果は、「「Sin」モンスターは１種類につきフィールドに１体しか表側表示で存在できない」として適用される。\n③：バトルフェイズの間だけフィールドの「Sin」モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"方界法",h:"ほうかいだーま",e:"Cubic Dharma",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「方界」カード１枚を墓地へ送り、自分はデッキから１枚ドローする。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分の「方界」モンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n③：墓地のこのカードを除外し、自分の墓地の「方界」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"方界縁起",h:"ほうかいえんぎ",e:"Cubic Causality",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「方界」モンスターの数まで、相手フィールドの表側表示モンスターに方界カウンターを置く。\n方界カウンターが置かれたモンスターは攻撃できず、効果は無効化される。\n②：墓地のこのカードを除外し、自分フィールドの「方界」モンスター１体を対象として発動できる。\nこのターン、そのモンスターが戦闘で方界カウンターが置かれているモンスターを破壊する度に、その破壊されたモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"運命の一枚",h:"かーど・おぶ・すぴりっと",e:"Card of Fate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：お互いは自身のデッキからカードを１枚選ぶ。\nその後、お互いは相手のデッキのカードをランダムに４枚選ぶ。\nお互いは、それぞれ自身が選んだカード１枚と相手が選んだ４枚を合わせてシャッフルし、その５枚の中からランダムに１枚選び、お互いに確認し、自身の手札に加える。\n残りのカードは自身のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"アンクリボー",h:"あんくりぼー",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時にこのカードを手札から捨て、このカード以外の自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに墓地へ送られる。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\nこのターンのエンドフェイズに、自分のデッキ・墓地から「死者蘇生」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"真エクゾディア",h:"しんえくぞでぃあ",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがモンスターゾーンに存在し、このカード以外のお互いのフィールドのモンスターが「封印されし」通常モンスター４種類のみの場合、このカードのコントローラーから見て相手はデュエルに勝利する。",li:"",lk:[],ta:[],al:[]},
  {n:"相愛のアンブレカム",h:"そうあいのあんぶれかむ",e:"Angraecum Umbrella",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードが召喚に成功した時、手札を１枚捨て、自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"讃美火",h:"さんびか",e:"Firebrand Hymnist",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:800,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n相手フィールドのカードの数だけ、相手のデッキの上からカードを墓地へ送る。\n②：このカードが特殊召喚に成功した場合に発動できる。\n相手フィールドのカードの数だけ、自分のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"星導竜アーミライル",h:"せいどうりゅうあーみらいる",e:"Armillyre, the Starleader Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"効果モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\nこのカードはリンク素材にできない。\n①：このカードのリンク先の表側表示モンスター１体を対象として発動できる。\nそのモンスターと元々のレベルが同じモンスター１体を、このカードのリンク先となる自分フィールドに手札から守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"憑依覚醒",h:"ひょういかくせい",e:"Awakening of the Possessed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターの攻撃力は、自分フィールドのモンスターの属性の種類×３００アップする。\n②：自分フィールドの「霊使い」モンスター及び「憑依装着」モンスターは効果では破壊されない。\n③：自分フィールドに元々の攻撃力が１８５０の魔法使い族モンスターが召喚・特殊召喚された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"金魚救い",h:"きんぎょすくい",e:"Kingyo Sukui",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手の墓地のモンスター１体を対象として発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが対象のモンスターと同じ属性を持つモンスターだった場合、めくったカードを手札に加え、対象のモンスターを相手のデッキに戻す。\n違った場合、めくったカードは墓地へ送られ、このカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動ハーヴェスター",h:"むげんきどうはーう゛ぇすたー",e:"Infinitrack Harvester",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「無限起動ハーヴェスター」以外の「無限起動」モンスター１体を手札に加える。\n②：このカード以外の自分フィールドの機械族モンスター１体を対象として発動できる。\nそのモンスターとこのカードは、ターン終了時までその２体の元々のレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動ロックアンカー",h:"むげんきどうろっくあんかー",e:"Infinitrack Anchor Drill",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「無限起動ロックアンカー」以外の機械族・地属性モンスター１体を守備表示で特殊召喚する。\n②：このカード以外の自分フィールドの機械族モンスター１体を対象として発動できる。\nそのモンスターとこのカードは、ターン終了時までその２体の元々のレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動キャンサークレーン",h:"むげんきどうきゃんさーくれーん",e:"Infinitrack Crab Crane",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの機械族・地属性モンスター１体をリリースして発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分の墓地から機械族モンスター１体を除外して発動できる。\nデッキから「超接地展開」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動ドラグショベル",h:"むげんきどうどらぐしょべる",e:"Infinitrack Drag Shovel",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの機械族・地属性モンスター１体をリリースして発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分の墓地から機械族モンスター１体を除外して発動できる。\nデッキから「超信地旋回」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動トレンチャー",h:"むげんきどうとれんちゃー",e:"Infinitrack Trencher",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの機械族・地属性モンスター１体をリリースして発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：墓地のこのカードを除外し、「無限起動トレンチャー」以外の自分の墓地のレベル５以下の「無限起動」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動スクレイパー",h:"むげんきどうすくれいぱー",e:"Infinitrack Tunneller",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの機械族・地属性モンスター１体をリリースして発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の機械族・地属性モンスター５体を対象として発動できる。\nそのモンスターをデッキに戻してシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動リヴァーストーム",h:"むげんきどうりう゛ぁーすとーむ",e:"Infinitrack River Stormer",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:500,tx:"レベル５モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nデッキから機械族・地属性モンスター１体を選び、手札に加えるか墓地へ送る。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n③：このカードが墓地に存在する場合、自分フィールドの機械族リンクモンスター１体をリリースして発動できる。\nこのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動コロッサルマウンテン",h:"むげんきどうころっさるまうんてん",e:"Infinitrack Mountain Smasher",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:3100,tx:"レベル７モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力は１０００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n③：このカードが墓地に存在する場合、自分フィールドの機械族リンクモンスター１体をリリースして発動できる。\nこのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動アースシェイカー",h:"むげんきどうあーすしぇいかー",e:"Infinitrack Earth Slicer",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3100,de:2100,tx:"レベル９モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を任意の数だけ取り除き、その数だけフィールドのカードを対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。\n③：このカードが墓地に存在する場合、自分フィールドの機械族リンクモンスター１体をリリースして発動できる。\nこのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動ゴライアス",h:"むげんきどうごらいあす",e:"Infinitrack Goliath",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"リンクモンスター以外の「無限起動」モンスター１体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合、自分フィールドのXモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。\n②：このカードを素材として持っている、元々の種族が機械族のXモンスターは以下の効果を得る。\n●このカードは効果では破壊されない。",li:"",lk:["右下"],ta:[],al:[]},
  {n:"無限起動要塞メガトンゲイル",h:"むげんきどうようさいめがとんげいる",e:"Infinitrack Fortress Megaclops",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:4000,de:"-",tx:"Xモンスター３体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、このカード及びXモンスター以外のモンスターの効果を受けず、Xモンスター以外のモンスターとの戦闘では破壊されない。\n②：自分の墓地のXモンスター１体と相手フィールドのカード１枚を対象として発動できる。\nその墓地のXモンスターを特殊召喚し、その相手のカードを下に重ねてX素材とする。\nこの効果の発動後、ターン終了時まで相手が受ける全てのダメージは半分になる。",li:"",lk:["右","左下","右下"],ta:[],al:[]},
  {n:"超接地展開",h:"あうとりがー・えくすぱんど",e:"Outrigger Extension",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの機械族Xモンスターは相手の効果の対象にならない。\n②：１ターンに１度、自分フィールドの「無限起動」Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが２つ高い機械族Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族・地属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"超信地旋回",h:"すぴん・たーん",e:"Spin Turn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●自分フィールドの攻撃表示の機械族・地属性Xモンスター１体と相手フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターの表示形式を変更し、その相手モンスターを破壊する。\n\n●自分フィールドの守備表示の機械族・地属性Xモンスター１体と相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその自分のモンスターの表示形式を変更し、その相手の魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・ポトリー",h:"うぃっちくらふと・ぽとりー",e:"Witchcrafter Potterie",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードをリリースし、手札から魔法カード１枚を捨てて発動できる。\nデッキから「ウィッチクラフト・ポトリー」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\n②：自分の手札が０枚の場合、墓地のこのカードを除外し、自分の墓地の「ウィッチクラフト」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・ピットレ",h:"うぃっちくらふと・ぴっとれ",e:"Witchcrafter Pittore",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードをリリースし、手札から魔法カード１枚を捨てて発動できる。\nデッキから「ウィッチクラフト・ピットレ」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n自分はデッキから１枚ドローし、その後手札から「ウィッチクラフト」カード１枚を選んで墓地へ送る。\n手札に「ウィッチクラフト」カードが無い場合、手札を全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・シュミッタ",h:"うぃっちくらふと・しゅみった",e:"Witchcrafter Schmietta",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードをリリースし、手札から魔法カード１枚を捨てて発動できる。\nデッキから「ウィッチクラフト・シュミッタ」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\nデッキから「ウィッチクラフト・シュミッタ」以外の「ウィッチクラフト」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・エーデル",h:"うぃっちくらふと・えーでる",e:"Witchcrafter Edel",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:2300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から魔法カード１枚を捨てて発動できる。\n手札から「ウィッチクラフト・エーデル」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードをリリースし、自分の墓地の「ウィッチクラフト・エーデル」以外の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・ハイネ",h:"うぃっちくらふと・はいね",e:"Witchcrafter Haine",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分フィールドの他の魔法使い族モンスターは相手の効果の対象にならない。\n②：手札から魔法カード１枚を捨て、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフトマスター・ヴェール",h:"うぃっちくらふとますたー・う゛ぇーる",e:"Witchcrafter Madame Verre",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の魔法使い族モンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\n手札からカード名が異なる魔法カードを任意の数だけ相手に見せ、その自分のモンスターの攻撃力・守備力はターン終了時まで、見せた数×１０００アップする。\n②：手札から魔法カード１枚を捨てて発動できる。\n相手フィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・クリエイション",h:"うぃっちくらふと・くりえいしょん",e:"Witchcrafter Creation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：デッキから「ウィッチクラフト」モンスター１体を手札に加える。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・サボタージュ",h:"うぃっちくらふと・さぼたーじゅ",e:"Witchcrafter Holiday",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地の「ウィッチクラフト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・コラボレーション",h:"うぃっちくらふと・こらぼれーしょん",e:"Witchcrafter Collaboration",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの「ウィッチクラフト」モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃でき、そのモンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・ドレーピング",h:"うぃっちくらふと・どれーぴんぐ",e:"Witchcrafter Draping",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの「ウィッチクラフト」モンスターの数まで相手フィールドの魔法・罠カードを対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・バイストリート",h:"うぃっちくらふと・ばいすとりーと",e:"Witchcrafter Bystreet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの「ウィッチクラフト」モンスターはそれぞれ１ターンに１度だけ戦闘・効果では破壊されない。\n②：自分フィールドの「ウィッチクラフト」モンスターが効果を発動するために手札を捨てる場合、代わりにこのカードを墓地へ送る事ができる。\n③：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・スクロール",h:"うぃっちくらふと・すくろーる",e:"Witchcrafter Scroll",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：１ターンに１度、自分の魔法使い族モンスターが戦闘でモンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。\n②：自分フィールドの「ウィッチクラフト」モンスターが効果を発動するために手札を捨てる場合、代わりにこのカードを墓地へ送る事ができる。\n③：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・マスターピース",h:"うぃっちくらふと・ますたーぴーす",e:"Witchcrafter Masterpiece",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分または相手の墓地の魔法カード１枚を対象として発動できる。\nそのカードの同名カード１枚を自分のデッキから手札に加える。\n②：自分の墓地から、このカードと魔法カードを任意の数だけ除外して発動できる。\n除外した魔法カードの数と同じレベルの「ウィッチクラフト」モンスター１体をデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"呪眼の死徒 サリエル",h:"じゅがんのしと さりえる",e:"Serziel, Watcher of the Evil Eye",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「呪眼の死徒 サリエル」以外の「呪眼」カード１枚を手札に加える。\n②：このカードが「セレンの呪眼」を装備している場合、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこの効果は相手ターンでも発動できる。\n③：このカードの②の効果を発動した場合、次のスタンバイフェイズに発動する。\n自分フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"呪眼の死徒 メドゥサ",h:"じゅがんのしと めどぅさ",e:"Medusa, Watcher of the Evil Eye",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、「呪眼の死徒 メドゥサ」以外の自分の墓地の「呪眼」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが「セレンの呪眼」を装備している場合、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果は相手ターンでも発動できる。\n③：このカードの②の効果を発動した場合、次のスタンバイフェイズに発動する。\n自分の墓地のカード１枚を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"呪眼の眷属 カトブレパス",h:"じゅがんのけんぞく かとぶれぱす",e:"Catoblepas, Familiar of the Evil Eye",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:1900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「呪眼」魔法・罠カード１枚を対象として発動できる。\n次のターンの終了時まで、そのカードは１度だけ相手の効果では破壊されない。\n②：このカードが墓地に存在し、自分フィールドに「呪眼の眷属 カトブレパス」以外の「呪眼」モンスターが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"呪眼の眷属 バジリウス",h:"じゅがんのけんぞく ばじりうす",e:"Basilius, Familiar of the Evil Eye",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「呪眼」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：自分メインフェイズに発動できる。\nデッキから「呪眼」魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"呪眼の王 ザラキエル",h:"じゅがんのおう ざらきえる",e:"Zerrziel, Ruler of the Evil Eyed",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:2600,de:"-",tx:"「呪眼」モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：攻撃力２６００以上のモンスターを素材としてリンク召喚したこのカードは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが「セレンの呪眼」を装備している場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。\n③：このカードの②の効果を発動した場合、次のスタンバイフェイズに発動する。\nこのカードのリンク先の効果モンスター１体を選び、その効果を無効にする。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"セレンの呪眼",h:"せれんのじゅがん",e:"Evil Eye of Selene",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「呪眼」モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：装備モンスターは戦闘及び相手の効果では破壊されず、相手の効果の対象にならない。\n②：自分が装備モンスターの効果またはこのカード以外の「呪眼」魔法・罠カードを発動する度に発動する。\n装備モンスターの攻撃力は５００アップし、自分は５００LPを失う。\n③：このカードが墓地に存在する場合、１０００LPを払い、自分の墓地から「セレンの呪眼」以外の「呪眼」魔法・罠カード１枚を除外して発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"呪眼領閾－パレイドリア－",h:"じゅがんりょういき－ぱれいどりあ－",e:"Evil Eye Domain - Pareidolia",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「呪眼」モンスター１体を手札に加える事ができる。\n②：１ターンに１度、自分の魔法＆罠ゾーンに「セレンの呪眼」が存在し、自分の「呪眼」モンスターが攻撃されたダメージ計算時に発動できる。\nその戦闘で発生する自分への戦闘ダメージは相手も受ける。\n③：フィールドゾーンのこのカードが効果で破壊された場合、自分の墓地の「呪眼」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"喚忌の呪眼",h:"かんきのじゅがん",e:"Evil Eye Awakening",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地から「呪眼」モンスター１体を選んで特殊召喚する。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、代わりにデッキから「呪眼」モンスター１体を特殊召喚する事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"惨禍の呪眼",h:"さんかのじゅがん",e:"Evil Eye Confrontation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「呪眼」モンスターが存在する場合、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、この効果で破壊したカードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"静冠の呪眼",h:"せいかんのじゅがん",e:"Evil Eye Repose",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードは自分の墓地から「呪眼」カード１枚を除外して発動する事もできる。\nその場合、自分はデッキから１枚ドローする。\n②：１ターンに１度、「セレンの呪眼」を装備した自分の「呪眼」モンスターが相手モンスターを攻撃したダメージ計算後に発動できる。\nその相手モンスターを除外する。\n③：魔法＆罠ゾーンのこのカードが効果で破壊された場合、除外されている自分の「呪眼」カードを３枚まで対象として発動できる。\nそのカードを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妬絶の呪眼",h:"とぜつのじゅがん",e:"Evil Eye Defeat",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「呪眼」モンスターが存在する場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、この効果の対象を２体にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"死配の呪眼",h:"しはいのじゅがん",e:"Evil Eye Mesmerism",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手がモンスターを攻撃表示で特殊召喚した時、そのモンスターより高い攻撃力を持つ「呪眼」モンスターが自分フィールドに存在する場合にその相手モンスター１体を対象としてこのカードを発動できる。\nそのモンスターのコントロールを得る。\nそのモンスターは自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する限り、「呪眼」モンスターとしても扱う。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"断罪の呪眼",h:"だんざいのじゅがん",e:"Evil Eye Retribution",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「呪眼」モンスターが存在し、魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、このカードの発動と効果は無効化されない。",li:"",lk:[],ta:[],al:[]},
  {n:"HSRカイドレイク",h:"はいすぴーどろいどかいどれいく",e:"Hi-Speedroid Kitedrake",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2800,tx:"機械族・風属性チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●このカード以外のフィールドのカードを全て破壊する。\n\n●相手フィールドの全ての表側表示のカードの効果を無効にする。\n\n②：このカードが相手によって墓地へ送られた場合に発動できる。\nデッキから「スピードロイド」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"フリップ・フローズン",h:"ふりっぷ・ふろーずん",e:"Flip Frozen",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが墓地へ送られた場合に発動できる。\n相手フィールドの攻撃表示モンスターを全て守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドライブレイブ",h:"どらいぶれいぶ",e:"Bravedrive",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：１ターンに１度、自分のサイバース族モンスターの攻撃宣言時に、手札からモンスター１体を捨てて発動できる。\nその攻撃モンスターの攻撃力はターン終了時まで６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"リビルディア",h:"りびるでぃあ",e:"Rebuildeer",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：このカードが戦闘で相手モンスターを破壊した時、自分の墓地の攻撃力１５００以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スレッショルド・ボーグ",h:"すれっしょるど・ぼーぐ",e:"Threshold Borg",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札のサイバース族モンスター１体を捨てて、手札から特殊召喚できる。\n②：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・クロスワイプ",h:"さいばねっと・くろすわいぷ",e:"Cynet Crosswipe",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのサイバース族モンスター１体をリリースし、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネット・カスケード",h:"さいばねっと・かすけーど",e:"Cynet Cascade",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分がリンクモンスターのリンク召喚に成功した場合、そのリンク素材とした自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デコード・トーカー・エクステンド",h:"でこーど・とーかー・えくすてんど",e:"Decode Talker Extended",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"効果モンスター２体以上\n①：このカードはモンスターゾーンに存在する限り、カード名を「デコード・トーカー」として扱う。\n②：このカードの攻撃力は、このカードのリンク先のモンスターの数×５００アップする。\n③：自分バトルフェイズに、このカードのリンク先のモンスターが、戦闘で破壊された場合、または墓地へ送られた場合に発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"トラックブラック",h:"とらっくぶらっく",e:"",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"効果モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先の自分のモンスター１体を対象として発動できる。\nこのターン、そのモンスターが戦闘で相手モンスターを破壊する度に、自分はデッキから１枚ドローする。",li:"",lk:["左上","右下"],ta:[],al:[]},
  {n:"創聖魔導王 エンディミオン",h:"そうせいまどうおう　えんでぃみおん",e:"Endymion, the Mighty Master of Magic",t:"monster",a:"闇",l:7,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの魔力カウンターを６つ取り除いて発動できる。\nPゾーンのこのカードを特殊召喚する。\nその後、自分フィールドの魔力カウンターを置く事ができるカードの数まで、フィールドのカードを選んで破壊し、破壊した数だけこのカードに魔力カウンターを置く。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:1700,tx:"①：１ターンに１度、魔法・罠カードの効果が発動した時に発動できる。\n自分フィールドの魔力カウンターが置かれているカード１枚を選んで持ち主の手札に戻し、その発動を無効にし破壊する。\nその後、手札に戻したそのカードに置かれていた数だけ魔力カウンターをこのカードに置く事ができる。\n②：魔力カウンターが置かれているこのカードは相手の効果の対象にならず、相手の効果では破壊されない。\n③：魔力カウンターが置かれたこのカードが戦闘で破壊された時に発動できる。\nデッキから通常魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エンプレス・オブ・エンディミオン",h:"えんぷれす・おぶ・えんでぃみおん",e:"Reflection of Endymion",t:"monster",a:"光",l:7,ps:2,pe:"①：自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードの魔力カウンターを３つ取り除いて発動できる。\n手札の魔力カウンターを置く事ができるモンスター１体とPゾーンのこのカードを特殊召喚し、その２体に魔力カウンターを１つずつ置く。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1850,de:2700,tx:"自分は「エンプレス・オブ・エンディミオン」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、自分フィールドの他の魔力カウンターが置かれているカード１枚と相手フィールドのカード１枚を対象として発動できる。\nその自分及び相手のカードを持ち主の手札に戻す。\nその後、自分フィールドから手札に戻したカードに置かれていた数だけ魔力カウンターをこのカードに置く。\n②：魔力カウンターが置かれたこのカードが戦闘で破壊された時に発動できる。\nデッキから「エンディミオン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マギステル・オブ・エンディミオン",h:"まぎすてる・おぶ・えんでぃみおん",e:"Magister of Endymion",t:"monster",a:"地",l:3,ps:8,pe:"①：自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードの魔力カウンターを３つ取り除いて発動できる。\n自分のEXデッキの表側表示の魔力カウンターを置く事ができるモンスター１体とPゾーンのこのカードを特殊召喚し、その２体に魔力カウンターを１つずつ置く。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:900,tx:"自分は「マギステル・オブ・エンディミオン」を１ターンに１度しか特殊召喚できない。\n①：このカードの攻撃宣言時に発動できる。\nこのカードに魔力カウンターを１つ置く。\n②：相手ターンに１度、自分フィールドの魔力カウンターを３つ取り除いて発動できる。\n魔力カウンターを置く事ができるモンスター１体をデッキから特殊召喚する。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。\nその後、このカードに置かれていた数だけ魔力カウンターをこのカードに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"サーヴァント・オブ・エンディミオン",h:"さーう゛ぁんと・おぶ・えんでぃみおん",e:"Servant of Endymion",t:"monster",a:"風",l:3,ps:2,pe:"①：自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードの魔力カウンターを３つ取り除いて発動できる。\nデッキの魔力カウンターを置く事ができる攻撃力１０００以上のモンスター１体とPゾーンのこのカードを特殊召喚し、その２体に魔力カウンターを１つずつ置く。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:900,de:1500,tx:"自分は「サーヴァント・オブ・エンディミオン」を１ターンに１度しか特殊召喚できない。\n①：魔力カウンターが置かれているこのカードは直接攻撃できる。\n②：相手ターンに１度、手札を１枚捨てて発動できる。\nこのカード及び自分フィールドの魔力カウンターを置く事ができるカード全てに魔力カウンターを１つずつ置く。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。\nその後、このカードに置かれていた数だけ魔力カウンターをこのカードに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"魔力統轄",h:"まりょくとうかつ",e:"Spell Power Mastery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「エンディミオン」カード１枚を手札に加える。\nその後、自分フィールドの魔力カウンターを置く事ができるカードに、自分のフィールド・墓地の「魔力統轄」「魔力掌握」の数まで可能な限り魔力カウンターを置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔法都市の実験施設",h:"えんでぃみおん・らぼ",e:"Endymion's Lab",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードは魔法＆罠ゾーンに存在する限り、カード名を「魔法都市エンディミオン」として扱う。\n②：自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n③：１ターンに１度、自分の魔法使い族モンスターが戦闘で破壊されたダメージ計算後に、自分フィールドの魔力カウンターを６つ取り除いて発動できる。\n手札・デッキからレベル７以上の魔法使い族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導耀士 デイブレイカー",h:"まどうきし　でいぶれいかー",e:"Day-Breaker the Shining Magical Warrior",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"魔法使い族モンスター２体\nこのカード名の②④の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く。\n②：このカードのリンク先に魔法使い族モンスターが特殊召喚された場合に発動する。\nこのカードに魔力カウンターを１つ置く。\n③：このカードの攻撃力は、このカードの魔力カウンターの数×３００アップする。\n④：このカードの魔力カウンターを２つ取り除き、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"救魔の標",h:"きゅうまのしるべ",e:"Dwimmered Path",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の魔法使い族の効果モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"EMターントルーパー",h:"えんためいとたーんとるーぱー",e:"Performapal Turn Trooper",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：自分バトルフェイズ開始時に発動できる。\nこのカードにカウンターを１つ置く（最大２つまで）。\n②：このカードは、このカードのカウンターの数によって以下の効果を得る。\n●１つ：１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にする。\n●２つ：このカードをリリースして発動できる。\n発動後２回目の相手エンドフェイズまで、フィールドのモンスターを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDアーク",h:"でぃーでぃーあーく",e:"D/D Ark",t:"monster",a:"闇",l:4,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：相手フィールドのP召喚されたモンスター１体を対象として発動できる。\nそのモンスターとこのカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが効果で破壊された場合に発動できる。\n自分のEXデッキから「DDアーク」以外の表側表示の「DD」Pモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"レスキュー・インターレーサー",h:"れすきゅー・いんたーれーさー",e:"Rescue Interlacer",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分のサイバース族モンスターが攻撃されたダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。\n②：このカードがこのカードの効果を発動するために墓地へ捨てられたターンのエンドフェイズに発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クロス・デバッガー",h:"くろす・でばっがー",e:"Cross Debug",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:900,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにリンクモンスターが２体以上存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分と相手のリンクモンスター同士が戦闘を行うダメージ計算時に、墓地のこのカードを除外し、自分の墓地のリンクモンスター１体を対象として発動できる。\nその戦闘を行う自分のモンスターの攻撃力はターン終了時まで対象のモンスターの攻撃力分アップし、その戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女シーホース",h:"まりんせすしーほーす",e:"Marincess Sea Horse",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは「マリンセス」リンクモンスターのリンク先となる自分フィールドに手札から特殊召喚できる。\n②：墓地のこのカードを除外して発動できる。\n手札から水属性モンスター１体を「マリンセス」リンクモンスターのリンク先となる自分フィールドに特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女シースター",h:"まりんせすしーすたー",e:"Marincess Sea Star",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカード名の効果は１ターンに２度まで使用できる。\n①：このカードを手札から墓地へ送り、自分フィールドの「マリンセス」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"DMZドラゴン",h:"でぃーえむぜっとどらごん",e:"DMZ Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：１ターンに１度、自分の墓地のレベル４以下のドラゴン族モンスター１体と自分フィールドのドラゴン族モンスター１体を対象として発動できる。\n対象の墓地のモンスターを攻撃力５００アップの装備カード扱いとして、対象のフィールドのモンスターに装備する。\n②：装備カードを装備した自分のモンスターが攻撃したダメージステップ終了時に、墓地のこのカードを除外して発動できる。\nその自分のモンスターの装備カードを全て破壊する。\n破壊した場合、そのモンスターはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・マーシャルアンキロ",h:"だいなれすらー・まーしゃるあんきろ",e:"Dinowrestler Martial Ankylo",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分の「ダイナレスラー」モンスターが相手モンスターと戦闘を行うダメージ計算時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその自分のモンスターはその戦闘では破壊されず、その相手モンスターの攻撃力はダメージステップ終了時に半分になる。\n②：このカードがフィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・ラアムブラキオ",h:"だいなれすらー・らあむぶらきお",e:"Dinowrestler Rambrachio",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「ダイナレスラー」モンスターが相手に戦闘ダメージを与えた時に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの他の「ダイナレスラー」モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"天威龍－アーダラ",h:"てんいりゅう－あーだら",e:"Tenyi Spirit - Adhara",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに効果モンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに効果モンスター以外の表側表示モンスターが存在する場合、手札・墓地のこのカードを除外し、このカード以外の除外されている自分の幻竜族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天威龍－シュターナ",h:"てんいりゅう－しゅたーな",e:"Tenyi Spirit - Shthana",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに効果モンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：効果モンスター以外の自分フィールドの表側表示モンスターが戦闘・効果で破壊された場合、手札・墓地のこのカードを除外し、その破壊されたモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、相手フィールドのモンスター１体を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"天威龍－マニラ",h:"てんいりゅう－まにら",e:"Tenyi Spirit - Mapura",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:600,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに効果モンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：効果モンスター以外の自分フィールドの表側表示モンスターを対象とする相手の魔法・罠・モンスターの効果が発動した時、手札・墓地のこのカードを除外して発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天威龍－ナハタ",h:"てんいりゅう－なはた",e:"Tenyi Spirit - Nahata",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに効果モンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：効果モンスター以外の自分の表側表示モンスターが相手の表側表示モンスターと戦闘を行う攻撃宣言時に、手札・墓地のこのカードを除外して発動できる。\nその相手モンスターの攻撃力はターン終了時まで１５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"天威龍－ヴィシュダ",h:"てんいりゅう－う゛ぃしゅだ",e:"Tenyi Spirit - Vishuda",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1500,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに効果モンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに効果モンスター以外の表側表示モンスターが存在する場合、手札・墓地のこのカードを除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"雛神鳥シムルグ",h:"すうしんちょうしむるぐ",e:"Simorgh, Bird of Beginning",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「シムルグ」モンスター１体を召喚できる。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"招神鳥シムルグ",h:"しょうしんちょうしむるぐ",e:"Simorgh, Bird of Bringing",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「招神鳥シムルグ」以外の「シムルグ」カード１枚を手札に加える。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"死神鳥シムルグ",h:"ししんちょうしむるぐ",e:"Simorgh, Bird of Calamity",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「死神鳥シムルグ」以外の「シムルグ」カード１枚を墓地へ送る。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"護神鳥シムルグ",h:"ごしんちょうしむるぐ",e:"Simorgh, Bird of Protection",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、相手の魔法＆罠ゾーンのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"烈風の覇者シムルグ",h:"れっぷうのはしゃしむるぐ",e:"Simorgh, Lord of the Storm",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2900,de:2000,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：アドバンス召喚したこのカードは相手の魔法・罠カードの効果の対象にならない。\n②：魔法・罠カードの効果が発動した時、自分フィールドの鳥獣族・風属性モンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n③：このカードが墓地に存在し、自分の鳥獣族モンスターが戦闘で破壊された時に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークネス・シムルグ",h:"だーくねす・しむるぐ",e:"Simorgh of Darkness",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2900,de:2000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分が闇属性または風属性のモンスターのアドバンス召喚に成功した場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、このカードの属性は「風」としても扱う。\n③：魔法・罠カードの効果が発動した時、自分フィールドの鳥獣族・風属性モンスター１体をリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 侍郎風",h:"ようせんじゅう　さぶろうかぜ",e:"Yosenju Sabu",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに他の「妖仙獣」カードが存在し、このカードが召喚に成功した場合に発動できる。\nデッキから「妖仙獣」Pモンスター１体を手札に加える。\n②：自分フィールドの「妖仙獣」カード１枚を対象として発動できる。\nデッキから「妖仙郷の眩暈風」または「妖仙大旋風」１枚を自分の魔法＆罠ゾーンに表側表示で置き、対象のカードを持ち主のデッキに戻す。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣 飯綱鞭",h:"ようせんじゅう　いずなむち",e:"Yosenju Izna",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nこのターン、「妖仙獣」モンスターの召喚・特殊召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。\n②：自分フィールドに他の「妖仙獣」モンスターが存在する場合に発動できる。\n自分はデッキから１枚ドローする。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔妖仙獣 独眼群主",h:"まようせんじゅう　ひとつめのむらじ",e:"Mayosenju Hitot",t:"monster",a:"風",l:10,ps:3,pe:"このカード名の①②のP効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のPゾーンの「妖仙獣」カード１枚を対象として発動できる。\nそのPスケールはターン終了時まで１１になる。\nこのターン、自分は「妖仙獣」モンスターしか特殊召喚できない。\n②：自分エンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカードはP召喚でしか特殊召喚できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードがモンスターゾーンに存在し、自分のカードの効果によって、このカード以外のフィールドのカードが手札・デッキに戻る度に発動する。\n自分フィールドの全ての「妖仙獣」モンスターの攻撃力は５００アップする。\n③：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・パスティー",h:"ふぉーちゅんれでぃ・ぱすてぃー",e:"Fortune Lady Past",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力はこのカードのレベル×２００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\n③：自分フィールドの「フォーチュンレディ」モンスター１体を対象として発動できる。\nそのモンスター以外の自分の手札・フィールド・墓地の魔法使い族モンスターを任意の数だけ除外し、ターン終了時まで対象のモンスターのレベルを、除外したモンスターの数だけ上げる、または下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"巨大戦艦 ブラスターキャノン・コア",h:"きょだいせんかん　ぶらすたーきゃのん・こあ",e:"B.E.S. Blaster Cannon Core",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:3000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカードにカウンターを３つ置く。\n③：このカードは戦闘では破壊されない。\n④：このカードが戦闘を行ったダメージステップ終了時に発動する。\nこのカードのカウンターを１つ取り除く。\n取り除けない場合このカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ビック・バイパー T３０１",h:"びっく・ばいぱー　Ｔ３０１",e:"Vic Viper T301",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分の表側表示モンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカード以外の自分フィールドの機械族・光属性モンスターの攻撃力は１２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ヒュドラ",h:"れぷてぃれす・ひゅどら",e:"Reptilianne Lamia",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが手札に存在し、自分フィールドのモンスターが爬虫類族モンスターのみの場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、このカードを特殊召喚する。\nその後、自分はそのモンスターの元々の攻撃力分のダメージを受ける。\n②：このカードがS素材として墓地へ送られた場合、フィールドの表側表示モンスターを２体まで対象として発動できる。\nそのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"メギストリーの儀術師",h:"めぎすとりーのぎじゅつし",e:"Megistric Maginician",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：自分・相手のエンドフェイズにこのカードの魔力カウンターを３つ取り除き、除外されている自分の魔法カード１枚を対象として発動できる。\nそのカードの同名カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"満天禍コルドー",h:"まんてんかこるどー",e:"Cataclysmic Cryonic Coldo",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の風属性モンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、フィールドの魔法・罠カード１枚を選んで持ち主のデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"縄張恐竜",h:"びーとらぷとる",e:"Beatraptor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1400,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがメインモンスターゾーンに存在する限り、EXモンスターゾーンのモンスターの効果は無効化される。\n②：このカードが戦闘で破壊された時に発動できる。\nデッキから「縄張恐竜」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"邪王トラカレル",h:"じゃおうとらかれる",e:"Tlakalel, His Malevolent Majesty",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合に発動できる。\n攻撃力の合計が、このカードのアドバンス召喚のためにリリースしたモンスターの元々の攻撃力以下になるように、相手フィールドの表側表示モンスターを任意の数だけ選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魂の造形家",h:"すぴりっと・すかるぷたー",e:"Spirit Sculptor",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:2100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースして発動できる。\n元々の攻撃力と元々の守備力の合計が、リリースしたモンスターと同じとなるモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"リバーシブル・ビートル",h:"りばーしぶる・びーとる",e:"Reversible Beetle",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカード及びこのカードと同じ縦列のモンスターを全て裏側守備表示にする。\n②：このカードがリバースした場合に発動する。\nこのカード及びこのカードと同じ縦列の表側表示モンスターを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧蛇－叢雲遠呂智",h:"きこうじゃ－むらくものおろち",e:"Gizmek Orochi, the Serpentron Sky Slasher",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2450,de:2450,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが手札・墓地に存在する場合、自分のデッキの上からカード８枚を裏側表示で除外して発動できる。\nこのカードを特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：EXデッキからカード３枚を裏側表示で除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ボルテスター",h:"ぼるてすたー",e:"Voltester",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンクモンスターのリンク先に特殊召喚された場合に発動する。\nこのカードとリンク状態になっているリンクモンスターを全て破壊する。\nさらに「破壊したリンクモンスターのリンク先のモンスターも全て破壊する」処理を繰り返す。\n（この効果でこのカードは破壊されない。\n）",li:"",lk:[],ta:[],al:[]},
  {n:"ランリュウ",h:"らんりゅう",e:"Ranryu",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：「ランリュウ」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに魔法使い族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合、「ランリュウ」以外の自分の墓地の攻撃力１５００／守備力２００のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフトゴーレム・アルル",h:"うぃっちくらふとごーれむ・あるる",e:"Witchcrafter Golem Aruru",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2800,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在し、自分フィールドの魔法使い族モンスターが相手の効果の対象になった時、または相手モンスターの攻撃対象に選択された時、相手フィールドのカード１枚または自分の墓地の「ウィッチクラフト」魔法カード１枚を対象として発動できる。\nこのカードを特殊召喚し、そのカードを持ち主の手札に戻す。\n②：相手スタンバイフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"双星神 a－vida",h:"そうせいしん　あう゛ぃだ",e:"Avida, Rebuilder of Worlds",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"サイバース族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3500,tx:"このカードは通常召喚できない。\nお互いのフィールド・墓地にリンクモンスターが８種類以上存在する場合のみ特殊召喚できる。\nこのカードを特殊召喚するターン、自分は他のモンスターを特殊召喚できない。\n①：このカードの特殊召喚は無効化されない。\n②：このカードが特殊召喚に成功した場合に発動する。\nこのカード以外の、お互いのフィールド・墓地のモンスター及び除外されているモンスターを全て持ち主のデッキに戻す。\nこの効果の発動に対して魔法・罠・モンスターの効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライブラの魔法秤",h:"らいぶらのまほうびん",e:"Magicalibra",t:"monster",a:"水",l:4,ps:5,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：１～６までの任意のレベルを宣言し、自分フィールドの表側表示モンスター２体を対象として発動できる。\nターン終了時まで、対象のモンスター１体のレベルを宣言したレベル分だけ下げ、もう１体のモンスターのレベルを宣言したレベル分だけ上げる。",tr:"魔法使い族",mo:["ペンデュラム","チューナー","通常"],ma:"",tp:"",at:1000,de:1000,tx:"意思を持った天秤。\n世の中の均衡を保っているが、しばしば間違った方に錘星を乗せてしまう。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・エヴァリー",h:"ふぉーちゅんれでぃ・えう゛ぁりー",e:"Fortune Lady Every",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"チューナー＋チューナー以外の魔法使い族モンスター１体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力はこのカードのレベル×４００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\nその後、相手フィールドの表側表示モンスター１体を選んで除外できる。\n③：相手エンドフェイズにこのカードが墓地に存在する場合、自分の墓地からこのカード以外の魔法使い族モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレルロード・X・ドラゴン",h:"う゛ぁれるろーど・えくすちゃーじ・どらごん",e:"Borreload eXcharge Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"ドラゴン族・闇属性レベル４モンスター×２\n①：X召喚したこのカードは他のモンスターの効果の対象にならない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は６００ダウンする。\nその後、自分の墓地から「ヴァレル」モンスター１体を選んで特殊召喚できる。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できず、直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女ブルースラッグ",h:"まりんせすぶるーすらっぐ",e:"Marincess Blue Slug",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"レベル４以下の「マリンセス」モンスター１体\n自分は「海晶乙女ブルースラッグ」を１ターンに１度しかリンク召喚できない。\n①：このカードがリンク召喚に成功した場合、「海晶乙女ブルースラッグ」以外の自分の墓地の「マリンセス」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果の発動後、ターン終了時まで自分は水属性モンスターしか特殊召喚できない。",li:"",lk:["下"],ta:[],al:[]},
  {n:"海晶乙女コーラルアネモネ",h:"まりんせすこーらるあねもね",e:"Marincess Coral Anemone",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"水属性モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の攻撃力１５００以下の水属性モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は水属性モンスターしか特殊召喚できない。\n②：このカードがフィールドから墓地へ送られた場合、「海晶乙女コーラルアネモネ」以外の自分の墓地の「マリンセス」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"海晶乙女マーブルド・ロック",h:"まりんせすまーぶるど・ろっく",e:"Marincess Marbled Rock",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"水属性モンスター２体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：「海晶乙女マーブルド・ロック」以外の自分の墓地の「マリンセス」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：相手モンスターの攻撃宣言時に、手札から「マリンセス」モンスター１体を墓地へ送って発動できる。\nモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:["左","右","下"],ta:[],al:[]},
  {n:"天威の拳僧",h:"てんいのけんそう",e:"Monk of the Tenyi",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"幻竜族",mo:["リンク"],ma:"",tp:"",at:1000,de:"-",tx:"リンクモンスター以外の「天威」モンスター１体",li:"",lk:["下"],ta:[],al:[]},
  {n:"天威の龍仙女",h:"てんいのりゅうせんにょ",e:"Shaman of the Tenyi",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"幻竜族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"幻竜族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨て、自分の墓地の幻竜族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「天威」モンスター以外のEXデッキから特殊召喚されたフィールドのモンスターの効果を発動できない。\n②：効果モンスター以外の自分の表側表示モンスターが戦闘を行う攻撃宣言時に、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"天威の鬼神",h:"てんいのきじん",e:"Berserker of the Tenyi",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"幻竜族",mo:["リンク"],ma:"",tp:"",at:3000,de:"-",tx:"リンクモンスターを含むモンスター２体以上",li:"",lk:["上","右上","右下"],ta:[],al:[]},
  {n:"冥宮の番人",h:"めいきゅうのばんにん",e:"Defender of the Labyrinth",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"通常モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：効果モンスター以外の自分フィールドのモンスターの攻撃力は５００アップし、相手フィールドの効果モンスターの攻撃力は５００ダウンする。\n②：このカードが相手によって破壊された場合、効果モンスター以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"リンクメイル・デーモン",h:"りんくめいる・でーもん",e:"Linkmail Archfiend",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"儀式・融合・S・Xモンスターのいずれかを含むモンスター２体以上\n①：このカードが特殊召喚に成功した場合、自分のフィールド・墓地の儀式・融合・S・Xモンスター１体を対象として発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで対象のモンスターの攻撃力分ダウンする。\n②：EXデッキから特殊召喚された自分フィールドのモンスターは相手モンスターの効果の対象にならない。\n③：このカードが戦闘・効果で破壊される場合、代わりに自分の墓地の儀式・融合・S・Xモンスター１体を除外できる。",li:"",lk:["左上","左","左下","右下"],ta:[],al:[]},
  {n:"召命の神弓－アポロウーサ",h:"しょうめいのしんきゅう－あぽろうーさ",e:"Apollousa, Bow of the Goddess",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:"?",de:"-",tx:"トークン以外のカード名が異なるモンスター２体以上\nこのカードの③の効果は同一チェーン上では１度しか発動できない。\n①：「召命の神弓－アポロウーサ」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードの元々の攻撃力は、このカードのリンク素材としたモンスターの数×８００になる。\n③：相手がモンスターの効果を発動した時に発動できる。\nこのカードの攻撃力を８００ダウンし、その発動を無効にする。",li:"",lk:["上","左下","下","右下"],ta:[],al:[]},
  {n:"蒼翠の風霊使いウィン",h:"そうすいのふうれいつかいうぃん",e:"Wynn the Wind Charmer, Verdant",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1850,de:"-",tx:"風属性モンスターを含むモンスター２体\nこのカード名はルール上「憑依装着」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の風属性モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから守備力１５００以下の風属性モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ミス・ケープ・バーバ",h:"みす・けーぷ・ばーば",e:"Baba Barber",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:200,de:"-",tx:"モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分・相手のバトルフェイズ開始時に、このカードまたはこのカードのリンク先のモンスター１体を対象として発動できる。\nそのモンスターをエンドフェイズまで除外する。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"リンク・バック",h:"りんく・ばっく",e:"Link Back",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：EXモンスターゾーンの自分のリンクモンスター１体を対象として発動できる。\nその自分のモンスターの位置を、そのリンク先となる自分のメインモンスターゾーンに移動する。\nその後、そのモンスターのリンクマーカーの数だけ、自分のデッキの上からカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"グリッド・ロッド",h:"ぐりっど・ろっど",e:"Grid Rod",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのサイバース族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は３００アップし、相手の効果を受けず、１ターンに１度だけ戦闘では破壊されない。\n②：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合に発動できる。\n自分フィールドの全てのサイバース族モンスターは、ターン終了時まで戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ライジング・オブ・ファイア",h:"らいじんぐ・おぶ・ふぁいあ",e:"Rising Fire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「サラマングレイト」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、自分の墓地の炎属性モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\n装備モンスターの攻撃力は５００アップする。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：このカードが相手の効果で破壊された場合に発動できる。\nフィールドのモンスター１体を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"フューリー・オブ・ファイア",h:"ふゅーりー・おぶ・ふぁいあ",e:"Fury of Fire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「サラマングレイト」カードとしても扱う。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地から「サラマングレイト」モンスターを２体まで選んで守備表示で特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこのカードの発動後、ターン終了時まで自分は１回しかEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"天威無崩の地",h:"てんいむほうのち",e:"Flawless Perfection of the Tenyi",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、効果モンスター以外のフィールドの表側表示モンスターは、モンスターの効果を受けない。\n②：１ターンに１度、自分フィールドに効果モンスター以外のモンスターが存在し、相手が効果モンスターを特殊召喚した場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"虚ろなる龍輪",h:"うつろなるりゅうりん",e:"Vessel for the Dragon Cycle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから幻竜族モンスター１体を墓地へ送る。\n自分フィールドに効果モンスター以外の表側表示モンスターが存在する場合、さらに墓地へ送ったそのモンスターとはカード名が異なる「天威」モンスター１体をデッキから手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"神鳥の霊峰エルブルズ",h:"しむるぐのれいほうえるぶるず",e:"Elborz, the Sacred Lands of Simorgh",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドの鳥獣族・風属性モンスターの攻撃力・守備力は３００アップする。\n②：手札のレベル５以上の鳥獣族・風属性モンスターを相手に見せて発動できる。\nこのターン、自分は鳥獣族モンスターを召喚する場合に必要なリリースを１体少なくできる。\n③：自分フィールドに鳥獣族・風属性モンスターが存在する場合に発動できる。\n鳥獣族モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神鳥の来寇",h:"しむるぐのらいこう",e:"Simorgh Onslaught",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から鳥獣族モンスター１体を捨てて発動できる。\nデッキから「シムルグ」モンスター２体を手札に加える（同じ属性は１体まで）。\n②：墓地のこのカードを除外して発動できる。\n手札の鳥獣族モンスター１体を相手に見せる。\nこのターン、そのモンスター及び自分の手札の同名モンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"神鳥の排撃",h:"しむるぐのはいげき",e:"Simorgh Repulsion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から鳥獣族モンスター１体を捨てて発動できる。\n相手の魔法＆罠ゾーンのカードを全て持ち主の手札に戻す。\n②：墓地のこのカードを除外して発動できる。\n手札の鳥獣族モンスター１体を相手に見せる。\nこのターン、そのモンスター及び自分の手札の同名モンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュン・ヴィジョン",h:"ふぉーちゅん・う゛ぃじょん",e:"Fortune Vision",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「フォーチュンレディ」カード１枚を手札に加える事ができる。\n②：１ターンに１度、自分フィールドのカードが効果で除外された場合に発動できる。\nこのターン、自分フィールドのモンスターは効果では破壊されない。\n③：１ターンに１度、相手フィールドのカードが効果で除外された場合に発動できる。\nこのターン、自分が受ける戦闘ダメージは１度だけ０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・コーリング",h:"ふぉーちゅんれでぃ・こーりんぐ",e:"Fortune Lady Calling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「フォーチュンレディ」モンスターが存在する場合に発動できる。\n同名カードが自分フィールドに存在しない「フォーチュンレディ」モンスター１体をデッキから特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣の風祀り",h:"ようせんじゅうのかざまつり",e:"Yosenju Wind Worship",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「妖仙獣」モンスターカードが３種類以上存在する場合に発動できる。\n自分フィールドの「妖仙獣」モンスターカードを全て持ち主の手札に戻す。\nその後、自分は手札が５枚になるようにデッキからドローできる。",li:"",lk:[],ta:[],al:[]},
  {n:"極超辰醒",h:"きょくちょうしんせい",e:"Hypernova Burst",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、通常召喚できないモンスター２体を裏側表示で除外して発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"才呼粉身",h:"さいこふんしん",e:"Psychic Fervor",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のバトルフェイズに自分フィールドの表側表示モンスター１体を対象として発動できる。\n自分はそのモンスターの攻撃力分のLPを失い、そのモンスターの攻撃力はターン終了時まで倍になる。\nこのカードを発動するターン、対象のモンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"遮攻カーテン",h:"しゃこうかーてん",e:"Blockout Curtain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのカードが戦闘または相手の効果で破壊される場合、その破壊されるカード１枚の代わりにこのカードを破壊できる。\n②：相手フィールドのカードが戦闘・効果で破壊される場合、その破壊されるカード１枚の代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"セクステット・サモン",h:"せくすてっと・さもん",e:"Sextet Summon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地及び自分フィールドの表側表示モンスターの中から、元々の種族が同じモンスター６種類（儀式・融合・S・X・P・リンク）をそれぞれ１体ずつ選んで除外する。\nその後、除外したモンスターと元々の種族が同じモンスター１体をデッキ・EXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドロー・ディスチャージ",h:"どろー・でぃすちゃーじ",e:"Draw Discharge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手の効果で相手がカードをドローした時に発動できる。\n相手がドローしたそのカードを全て確認する。\nその中にモンスターカードがあった場合、その攻撃力の合計分のダメージを相手に与え、確認したカードを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女波動",h:"まりんせす・うぇーぶ",e:"Marincess Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドにリンク３以上の「マリンセス」モンスターが存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドに「マリンセス」リンクモンスターが存在する場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n自分フィールドにリンク２以上の「マリンセス」モンスターが存在する場合、さらに自分フィールドの全ての表側表示モンスターはターン終了時まで、相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女潮流",h:"まりんせす・かれんと",e:"Marincess Current",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n自分フィールドにリンク３以上の「マリンセス」モンスターが存在する場合、このカードの発動は手札からもできる。\n①：自分の「マリンセス」リンクモンスターが戦闘で相手モンスターを破壊した時に発動できる。\nその自分のモンスターのリンクマーカーの数×４００ダメージを相手に与える。\n自分フィールドにリンク２以上の「マリンセス」モンスターが存在し、相手リンクモンスターを破壊した場合には、破壊されたモンスターのリンクマーカーの数×５００ダメージをさらに相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"天威無双の拳",h:"てんいむそうのけん",e:"Fists of the Unrivaled Tenyi",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに効果モンスター以外の表側表示モンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にする。\n②：セットされたこのカードが相手の効果で破壊された場合に発動できる。\nEXデッキから効果モンスター以外のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"神鳥の烈戦",h:"しむるぐのれっせん",e:"Simorgh Sky Battle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの鳥獣族モンスターの内、攻撃力が一番高い鳥獣族モンスター以外の鳥獣族モンスターは、攻撃対象にならず、相手の効果の対象にもならない。\n②：自分フィールドの、元々のレベルが７以上で元々の属性が異なる「シムルグ」モンスター２体とこのカードを墓地へ送って発動できる。\nフィールドのカードを全て持ち主の手札に戻し、自分は手札に戻した数×５００のダメージを受ける。\nその後、自分が受けたダメージと同じ数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"フォーチュンレディ・リワインド",h:"ふぉーちゅんれでぃ・りわいんど",e:"Fortune Lady Rewind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：除外されている自分の「フォーチュンレディ」モンスターを任意の数だけ対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"妖仙獣の居太刀風",h:"ようせんじゅうのいたちかぜ",e:"Yosenjus' Sword Sting",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドにモンスターが存在しない場合、手札からカード名が異なる「妖仙獣」モンスターを２体まで相手に見せ、見せた数だけ相手フィールドの表側表示のカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"怪蹴一色",h:"かいしゅういっしょく",e:"The Return to the Normal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：モンスター１体のみが召喚・特殊召喚された時に発動できる。\nそのモンスターより低い攻撃力を持つフィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜嵐還帰",h:"りょうらんかんき",e:"Storm Dragon's Return",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：除外されている自分または相手のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"セットアッパー",h:"せっとあっぱー",e:"Setuppercut",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のモンスターが戦闘で破壊された時に発動できる。\nそのモンスターの攻撃力以下の攻撃力を持つモンスター１体を手札・デッキから裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"救魔の奇石",h:"きゅうまのきせき",e:"Dwimmered Glimmer",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体または自分の墓地のモンスター１体を除外してこのカードを発動できる。\nこのカードは発動後、除外したモンスターの元々のレベルと同じレベルを持つ通常モンスター（魔法使い族・光・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ゲット・アウト！",h:"げっと・あうと！",e:"Get Out!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：EXデッキから特殊召喚された相手フィールドのモンスター２体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の選託",h:"せいいぶつのせんたく",e:"World Legacy Bestowal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n●相手フィールドのリンクモンスター１体を選んで墓地へ送る。\n●自分の墓地から「星遺物」カード７枚を除外して発動できる。\nデッキからサイバース族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"泥仕合",h:"どろーげーむ",e:"Fighting Dirty",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分及び相手フィールドのカードが同時に、戦闘・効果で破壊された場合に発動できる。\nお互いのプレイヤーは、それぞれデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"光をもたらす者 ルシファー",h:"ひかりをもたらすもの るしふぁー",e:"",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:2600,de:1800,tx:"我、至高の玉座にて天地を統べる者なり・・・光あれ！",li:"",lk:[],ta:[],al:[]},
  {n:"ノーマテリア",h:"のーまてりあ",e:"Gnomaterial",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドにカードが存在せず、相手がモンスターの特殊召喚に成功した場合、このカードを手札から捨て、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターはリリースできず、融合・S・X・リンク召喚の素材にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドロドロゴン",h:"どろどろごん",e:"Muddy Mudragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:500,de:2200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：このカードがS召喚されている場合、自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"蛇龍の枷鎖",h:"だりゅうのかさ",e:"Saryuja's Shackles",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手のフィールド・墓地のリンクモンスター１体を対象として発動できる。\nそのモンスターのリンクマーカーの数だけ自分はデッキからドローする。\nその後、自分の手札が２枚以上の場合、その内の２枚を選んで好きな順番でデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魅惑の未界域",h:"みわくのみかいいき",e:"Danger! Excitement! Mystery!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札からレベル５以上の「未界域」モンスター１体を捨てて発動できる。\nデッキからレベル４以下の「未界域」モンスター１体を手札に加える。\nこのカードの発動後、ターン終了時まで自分は「未界域」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域捕縛作戦",h:"みかいいきほばくさくせん",e:"Danger! Feets of Strength!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「未界域」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力・守備力は８００アップし、効果では破壊されない。\n②：装備モンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"未界域の歓待",h:"みかいいきのかんたい",e:"You're in Danger!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「未界域」モンスター３種類を相手に見せ、相手はその中からランダムに１体選ぶ。\nそのモンスター１体を自分フィールドに特殊召喚し、残りのモンスターはデッキに戻す。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・フュンフト",h:"わるきゅーれ・ふゅんふと",e:"Valkyrie Funfte",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「ワルキューレ」モンスターの攻撃力は、除外されている相手モンスターの数×２００アップする。\n②：自分フィールドに「ワルキューレ・フュンフト」以外の「ワルキューレ」モンスターが存在する場合に発動できる。\nデッキから魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・エルダ",h:"わるきゅーれ・えるだ",e:"Valkyrie Erda",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2000,de:2200,tx:"①：「ワルキューレ・エルダ」は自分フィールドに１体しか表側表示で存在できない。\n②：「ワルキューレ」カードの効果で特殊召喚したこのカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力は１０００ダウンする。\n③：このカードがモンスターゾーンに存在する限り、戦闘・効果で破壊され相手の墓地へ送られるカードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"戦乙女の戦車",h:"わるきゅーれ・ちゃりおっと",e:"Valkyrie Chariot",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:1000,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの天使族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃宣言時に発動できる。\n装備モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレの抱擁",h:"わるきゅーれのほうよう",e:"Valkyrie's Embrace",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「ワルキューレ」モンスターしか特殊召喚できない。\n①：自分フィールドのモンスターが「ワルキューレ」モンスターのみの場合、自分フィールドの攻撃表示の「ワルキューレ」モンスター１体と相手フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターを守備表示にし、その相手モンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"天馬の翼",h:"てんまのつばさ",e:"Pegasus Wing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地にユニオンモンスターが存在する場合、自分フィールドの「ワルキューレ」モンスターを任意の数だけ対象として発動できる。\nこのターン、そのモンスターは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ローゲの焔",h:"ろーげのほのお",e:"Loge's Flame",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在し、自分フィールドに「ワルキューレ」モンスターが存在する限り、攻撃力２０００以下の相手モンスターは攻撃できない。\n②：このカードが相手の効果で破壊された場合に発動できる。\n手札・デッキからレベル５以上の「ワルキューレ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"コード・トーカー・インヴァート",h:"こーど・とーかー・いんう゛ぁーと",e:"Code Talker Inverted",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1300,de:"-",tx:"サイバース族モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\n手札からサイバース族モンスター１体をこのカードのリンク先となる自分フィールドに特殊召喚する。",li:"",lk:["右","左"],ta:[],al:[]},
  {n:"転生炎獣コヨーテ",h:"さらまんぐれいとこよーて",e:"Salamangreat Coyote",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク素材として墓地へ送られたターンのエンドフェイズに以下の効果から１つを選択して発動できる。\n●「転生炎獣コヨーテ」以外の自分の墓地の「サラマングレイト」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n●「転生炎獣コヨーテ」以外の自分の墓地の「サラマングレイト」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ローグ・オブ・エンディミオン",h:"ろーぐ・おぶ・えんでぃみおん",e:"Rogue of Endymion",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く。\n②：このカードの魔力カウンターを１つ取り除き、手札から魔法使い族モンスター１体を捨てて発動できる。\nデッキから永続魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\nこのターン、自分はこの効果でセットしたカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"イピリア",h:"いぴりあ",e:"Ipiria",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"命の水",h:"いのちのみず",e:"Water of Life",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドにモンスターが存在しない場合、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はこの効果で特殊召喚したフィールドのモンスター以外のモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星の金貨",h:"ほしのきんか",e:"Gold Moon Coin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札を２枚選んで相手の手札に加える。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"おかしの家",h:"おかしのいえ",e:"Gingerbread House",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手スタンバイフェイズに発動できる。\n相手フィールドの全てのモンスターの攻撃力は６００アップする。\nその後、相手フィールドの攻撃力２５００以上のモンスターを全て破壊し、自分は破壊したモンスターの数×５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"占い魔女 ヒカリちゃん",h:"うらないまじょ　ひかりちゃん",e:"Fortune Fairy Hikari",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、デッキから魔法使い族・レベル１モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"占い魔女 エンちゃん",h:"うらないまじょ　えんちゃん",e:"Fortune Fairy En",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、相手フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"占い魔女 フウちゃん",h:"うらないまじょ　ふうちゃん",e:"Fortune Fairy Hu",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、除外されている自分の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"占い魔女 スィーちゃん",h:"うらないまじょ　すぃーちゃん",e:"Fortune Fairy Swee",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを次の自分ターンのスタンバイフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"占い魔女 アンちゃん",h:"うらないまじょ　あんちゃん",e:"Fortune Fairy Ann",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合に発動できる。\nデッキから魔法使い族モンスター１体を除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"占い魔女 チーちゃん",h:"うらないまじょ　ちーちゃん",e:"Fortune Fairy Chee",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合に発動できる。\nお互いのプレイヤーは、それぞれデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"受け入れがたい結果",h:"うけいれがたいけっか",e:"Unacceptable Result",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに魔法使い族モンスターが存在する場合に発動できる。\n手札から「占い魔女」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"開運ミラクルストーン",h:"かいうんみらくるすとーん",e:"Miracle Stone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「開運ミラクルストーン」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの魔法使い族モンスターの攻撃力・守備力は、自分フィールドの「占い魔女」モンスターの種類×５００アップする。\n③：１ターンに１度、自分の「占い魔女」モンスターが戦闘を行う攻撃宣言時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"幸運の前借り",h:"こううんのまえがり",e:"Lucky Loan",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「占い魔女」モンスター１体を対象として発動できる。\nそのモンスターより元々のレベルが１つ低い魔法使い族モンスター１体を手札・デッキから特殊召喚する。\nこのカードの発動後、次の自分ターン中、自分は魔法使い族モンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ギア・ゾンビ",h:"てっくじーなす　ぎあ・ぞんび",e:"T.G. Gear Zombie",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「TG」モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚する。\nその後、対象のモンスターの攻撃力を１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"TG ドリル・フィッシュ",h:"てっくじーなす　どりる・ふぃっしゅ",e:"T.G. Drill Fish",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが「TG」モンスターのみの場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードは直接攻撃できる。\n③：自分の「TG」モンスターが相手に戦闘ダメージを与えた時、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"TG メタル・スケルトン",h:"てっくじーなす　めたる・すけるとん",e:"T.G. Metal Skeleton",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのモンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドの「TG」モンスターが戦闘・効果で破壊される場合、代わりにフィールド・墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"バトル・スタン・ソニック",h:"ばとる・すたん・そにっく",e:"Sonic Stun",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にする。\nその後、手札・デッキから「TG」モンスターまたはレベル４以下のチューナー１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.26 次元孔路オクトバイパス",h:"なんばーず２６ じげんこうろおくとばいぱす",e:"Number 26: Spaceway Octobypass",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:800,de:2400,tx:"レベル３モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のバトルフェイズ開始時に、このカードのX素材を１つ取り除いて発動できる。\nこのバトルフェイズ中、モンスター１体でしか攻撃できず、そのモンスターの攻撃は直接攻撃になる。\n②：モンスターが直接攻撃で戦闘ダメージを与えたダメージステップ終了時に発動する。\nその攻撃したモンスターのコントロールをターンプレイヤーから見て相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"No.60 刻不知のデュガレス",h:"なんばーず６０ ときしらずのでゅがれす",e:"Number 60: Dugares the Timeless",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1200,de:1200,tx:"レベル４モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を２つ取り除き、以下の効果から１つを選択して発動できる。\n\n●自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。\n次の自分ドローフェイズをスキップする。\n\n●自分の墓地からモンスター１体を選んで守備表示で特殊召喚する。\n次の自分メインフェイズ１をスキップする。\n\n●自分フィールドのモンスター１体を選び、その攻撃力をターン終了時まで倍にする。\n次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No.76 諧調光師グラディエール",h:"なんばーず７６ かいちょうこうしぐらでぃえーる",e:"Number 76: Harmonizer Gradielle",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2100,tx:"レベル７モンスター×２\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの属性は、このカードがX素材としているモンスターのそれぞれの属性としても扱う。\n②：このカードは、このカードと同じ属性を持つモンスターとの戦闘では破壊されず、このカードと同じ属性を持つ相手モンスターが発動した効果では破壊されない。\n③：相手の墓地のモンスター１体を対象として発動できる。\nこのカードのX素材を１つ取り除き、対象のモンスターをこのカードの下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.97 龍影神ドラッグラビオン",h:"なんばーず９７ りゅうえいしんどらっぐらびおん",e:"Number 97: Draglubion",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル８モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは相手の効果の対象にならない。\n②：このカードのX素材を１つ取り除いて発動できる。\n自分のEXデッキ・墓地から「No.97 龍影神ドラッグラビオン」以外のドラゴン族の「No.」モンスター２種類を選ぶ。\nその内の１体を特殊召喚し、もう１体をそのモンスターの下に重ねてX素材とする。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できず、この効果で特殊召喚したモンスターでしか攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－必中のピン",h:"びー・ふぉーす－ひっちゅうのぴん",e:"Battlewasp - Pin the Bullseye",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに昆虫族モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分フィールドの「B・F－必中のピン」の数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－追撃のダート",h:"びー・ふぉーす－ついげきのだーと",e:"Battlewasp - Dart the Hunter",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「B・F」モンスターが、元々の持ち主が相手となるモンスターを戦闘で破壊した時、このカードを手札から捨てて発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカード以外の自分フィールドの昆虫族モンスター１体を対象として発動できる。\nこのターン、そのモンスターをチューナーとして扱う。\nこの効果の発動後、ターン終了時まで自分は昆虫族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－毒針のニードル",h:"びー・ふぉーす－どくばりのにーどる",e:"Battlewasp - Sting the Poison",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「B・F－毒針のニードル」以外の「B・F」モンスター１体を手札に加える。\n②：このカード以外の自分フィールドの昆虫族モンスター１体をリリースし、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－連撃のツインボウ",h:"びー・ふぉーす－れんげきのついんぼう",e:"Battlewasp - Twinbow the Attacker",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は昆虫族モンスターしかEXデッキから特殊召喚できない。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－早撃ちのアルバレスト",h:"びー・ふぉーす－はやうちのあるばれすと",e:"Battlewasp - Arbalest the Rapidfire",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地のレベル３以下の昆虫族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが相手によって破壊された場合に発動できる。\n手札・デッキから「B・F」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－霊弓のアズサ",h:"びー・ふぉーす－れいきゅうのあずさ",e:"Battlewasp - Azusa the Ghost Bow",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2200,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカード以外の「B・F」モンスターの効果で相手がダメージを受けた時に発動できる（ダメージステップでも発動可能）。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカードが墓地に存在する状態で、自分の「B・F」モンスターの戦闘でモンスターが破壊された時に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－突撃のヴォウジェ",h:"びー・ふぉーす－とつげきのう゛ぉうじぇ",e:"Battlewasp - Halberd the Charge",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:800,tx:"昆虫族チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがこのカードの攻撃力以上の攻撃力を持つ相手モンスターに攻撃するダメージ計算時に１度、発動できる。\nその相手モンスターの攻撃力はダメージ計算時のみ半分になる。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分フィールドの「B・F」モンスターの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－降魔弓のハマ",h:"びー・ふぉーす－こうまきゅうのはま",e:"Battlewasp - Hama the Conquering Bow",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：Sモンスターを素材としてS召喚したこのカードは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\n相手フィールドの全てのモンスターの攻撃力・守備力は１０００ダウンする。\n③：相手が戦闘ダメージを受けなかった自分バトルフェイズの終了時に発動できる。\n自分の墓地の「B・F」モンスターの数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F－決戦のビッグ・バリスタ",h:"びー・ふぉーす－けっせんのびっぐ・ばりすた",e:"Battlewasp - Ballista the Armageddon",t:"monster",a:"風",l:12,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが特殊召喚に成功した場合、自分の墓地の昆虫族モンスターを全て除外して発動できる。\n相手フィールドの全てのモンスターの攻撃力・守備力は、除外されている自分の昆虫族モンスターの数×５００ダウンする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：S召喚したこのカードが相手によって破壊された場合に発動できる。\n除外されている自分のレベル１１以下の昆虫族モンスター３体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"一斉蜂起",h:"いっせいほうき",e:"Summoning Swarm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は昆虫族モンスターしかEXデッキから特殊召喚できない。\n①：相手フィールドのモンスターの数まで、自分の墓地のレベル４以下の「B・F」モンスターを対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"蘇生の蜂玉",h:"そせいのほうぎょく",e:"Revival Swarm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の墓地の「B・F」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの昆虫族モンスター１体を対象として発動できる。\nそのモンスターは次のターンの終了時まで、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"B・F・N",h:"びー・ふぉーす・ねすと",e:"Battlewasp - Nest",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「B・F」モンスターが攻撃対象に選択された時に発動できる。\n手札・デッキから「B・F」モンスター１体を特殊召喚し、このカードにカウンターを１つ置く。\nその後、バトルフェイズを終了する。\n②：エンドフェイズに、カウンターが２つ以上置かれているこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"プログレオ",h:"ぷろぐれお",e:"Progleo",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"トークン以外のモンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚されている場合、このカードのリンク先の自分のモンスター１体とこのカードを除外し、自分または相手の墓地のリンクモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:["右","左"],ta:[],al:[]},
  {n:"ラスタライガー",h:"らすたらいがー",e:"Rasterliger",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"トークン以外のモンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手の墓地のリンクモンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、そのモンスターの攻撃力分アップする。\n②：このカードのリンク先の自分のモンスターを任意の数だけリリースして発動できる。\nリリースしたモンスターの数だけ、フィールドのカードを選んで破壊する。",li:"",lk:["上","右","下","左"],ta:[],al:[]},
  {n:"カース・ネクロフィア",h:"かーす・ねくろふぃあ",e:"Curse Necrofear",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：除外されている自分の悪魔族モンスター３体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターをデッキに戻す。\n②：モンスターゾーンのこのカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに発動する。\nこのカードを墓地から特殊召喚する。\nその後、自分フィールドの魔法・罠カードのカード名の種類の数まで相手フィールドのカードを選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"抹殺の邪悪霊",h:"まっさつのだーく・すぴりっと",e:"Dark Spirit of Banishment",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターが攻撃するダメージステップ開始時に、自分の手札・フィールドのこのカードを墓地へ送り、自分の墓地の悪魔族・レベル８モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。\n②：このカードが墓地に存在し、悪魔族・レベル８モンスターが自分の墓地へ送られた場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"怨念の邪悪霊",h:"おんねんのだーく・すぴりっと",e:"Dark Spirit of Malice",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手が魔法・罠・モンスターの効果を発動した時、手札・フィールドのこのカードを墓地へ送り、自分の墓地の悪魔族・レベル８モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードが墓地に存在し、悪魔族・レベル８モンスターが自分の墓地へ送られた場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・オカルティズム",h:"だーく・おかるてぃずむ",e:"Dark Spirit's Mastery",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\n自分のデッキ・墓地から「ウィジャ盤」１枚または悪魔族・レベル８モンスター１体を選んで手札に加える。\n②：墓地のこのカードを除外して発動できる。\n自分の手札・墓地の「ウィジャ盤」及び「死のメッセージ」カードの中から、任意の数だけ選び（同名カードは１枚まで）、好きな順番でデッキの一番下に戻す。\nその後、戻した数だけ自分はデッキからドローする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"死の宣告",h:"しのせんこく",e:"Sentence of Doom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、自分フィールドの「ウィジャ盤」及び「死のメッセージ」カードの数まで悪魔族モンスターを対象として発動できる。\nそのモンスターを手札に加える。\n②：魔法＆罠ゾーンのこのカードを墓地へ送って発動できる。\n自分の手札・デッキ・墓地から「死のメッセージ」カード１枚を選び、「ウィジャ盤」の効果扱いとして自分の魔法＆罠ゾーンに出す。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO マリシャス・ベイン",h:"いーびるひーろー　まりしゃす・べいん",e:"Evil HERO Malicious Bane",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"「E-HERO」モンスター＋レベル５以上のモンスター\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードは戦闘・効果では破壊されない。\n②：自分メインフェイズに発動できる。\nこのカードの攻撃力以下の攻撃力を持つ相手フィールドのモンスターを全て破壊し、このカードの攻撃力は破壊したモンスターの数×２００アップする。\nこの効果の発動後、ターン終了時まで自分は「HERO」モンスターでしか攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO アダスター・ゴールド",h:"いーびるひーろー　あだすたー・ごーるど",e:"Evil HERO Adusted Gold",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\n「E-HERO アダスター・ゴールド」以外の「ダーク・フュージョン」のカード名が記されたカードまたは「ダーク・フュージョン」１枚をデッキから手札に加える。\n②：自分フィールドに融合モンスターが存在しない場合、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"E-HERO シニスター・ネクロム",h:"いーびるひーろー　しにすたー・ねくろむ",e:"Evil HERO Sinister Necrom",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\n手札・デッキから「E-HERO シニスター・ネクロム」以外の「E-HERO」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王城",h:"はおうじょう",e:"Supreme King's Castle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分は「ダーク・フュージョン」の効果でのみ特殊召喚できる融合モンスターを「ダーク・フュージョン」の効果以外でも融合召喚できる。\n②：１ターンに１度、自分の悪魔族モンスターが相手モンスターと戦闘を行うダメージ計算時に、デッキ・EXデッキから「E-HERO」モンスター１体を墓地へ送って発動できる。\nその自分のモンスターの攻撃力はターン終了時まで、墓地へ送ったモンスターのレベル×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"イービル・マインド",h:"いーびる・まいんど",e:"Evil Mind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに悪魔族モンスターが存在する場合、相手の墓地のモンスターの数によって以下の効果から１つを選択して発動できる。\n\n●１体以上：自分はデッキから１枚ドローする。\n\n●４体以上：デッキから「HERO」モンスター１体または「ダーク・フュージョン」１枚を手札に加える。\n\n●１０体以上：デッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛超神官",h:"じばくちょうしんかん",e:"Earthbound Greater Linewalker",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2900,de:2900,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：Sモンスターが自分のフィールド・墓地の両方に存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分のデッキ・墓地から「地縛神」モンスター１体を選んで手札に加える。\n③：このカードが既にモンスターゾーンに存在する状態で、「地縛神」モンスターが召喚された場合に発動できる。\n相手のLPは３０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"使神官－アスカトル",h:"ししんかん－あすかとる",e:"Ascator, Dawnwalker",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカード以外の手札を１枚捨てて発動できる。\nこのカードを手札から守備表示で特殊召喚する。\nその後、手札・デッキから「赤蟻アスカトル」１体を特殊召喚できる。\nこの効果を発動するターン、自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"死神官－スーパイ",h:"ししんかん－すーぱい",e:"Supay, Duskwalker",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2200,de:1900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカード以外の手札を１枚捨てて発動できる。\nこのカードを手札から守備表示で特殊召喚する。\nその後、手札・デッキから「スーパイ」１体を特殊召喚できる。\nこの効果を発動するターン、自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"地縛地上絵",h:"じばくちじょうえ",e:"Earthbound Geoglyph",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：フィールドにレベル１０モンスターが存在する限り、このカードは効果の対象にならず、効果では破壊されない。\n②：このカードがフィールドゾーンに存在する限り、自分が「地縛神」モンスターをアドバンス召喚する場合、Sモンスター１体を２体分のリリースにできる。\n③：Sモンスターが特殊召喚された場合に自分はこの効果を発動できる。\nデッキから「地縛神」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"究極地縛神",h:"きゅうきょくじばくしん",e:"Ultimate Earthbound Immortal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：フィールドに通常召喚された「地縛神」モンスターが存在する場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ギガンテス・ドール",h:"ぎみっく・ぱぺっと－ぎがんてす・どーる",e:"Gimmick Puppet Gigantes Doll",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2000,tx:"レベル４「ギミック・パペット」モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を２つ取り除き、相手フィールドのモンスターを２体まで対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果を発動したターン、自分は「ギミック・パペット」モンスターしか特殊召喚できず、Xモンスターでしか攻撃宣言できない。\n②：このカードをリリースして発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで８になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－テラー・ベビー",h:"ぎみっく・ぱぺっと－てらー・べびー",e:"Gimmick Puppet Terror Baby",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"①：このカードが召喚に成功した時、「ギミック・パペット－テラー・ベビー」以外の自分の墓地の「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分の「ギミック・パペット」モンスターの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ギミック・パペット－ビスク・ドール",h:"ぎみっく・ぱぺっと－びすく・どーる",e:"Gimmick Puppet Bisque Doll",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードは手札の「ギミック・パペット」モンスター１体を捨てて、手札から特殊召喚できる。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分フィールドの「ギミック・パペット」モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"パフォーム・パペット",h:"ぱふぉーむ・ぱぺっと",e:"Perform Puppet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「ギミック・パペット」モンスター１体を除外して発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで、除外したモンスターと同じレベルになる。\n②：自分フィールドの表側表示の「ギミック・パペット」モンスターが戦闘または相手の効果で破壊され墓地へ送られた場合、除外されている自分の「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"傀儡葬儀－パペット・パレード",h:"くぐつそうぎ－ぱぺっと・ぱれーど",e:"Puppet Parade",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、その差の数までデッキから「ギミック・パペット」モンスターを特殊召喚する（同名カードは１枚まで）。\n自分のLPが相手より２０００以上少ない場合、さらにデッキから「RUM」通常魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットできる。\nこのカードの発動後、ターン終了時まで自分は「ギミック・パペット」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物トリフィオヴェルトゥム",h:"ぷれでたー・ぷらんつとりふぃおう゛ぇるとぅむ",e:"Predaplant Triphyoverutum",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"植物族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"フィールドの闇属性モンスター×３\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカード以外のフィールドの捕食カウンターが置かれたモンスターの元々の攻撃力の合計分アップする。\n②：このカードが融合召喚されている場合、相手がEXデッキからモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。\n③：相手フィールドのモンスターに捕食カウンターが置かれている場合に発動できる。\nこのカードを墓地から守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食植物ヘリアンフォリンクス",h:"ぷれでたー・ぷらんつへりあんふぉりんくす",e:"Predaplant Heliamphorhynchus",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドのモンスターに捕食カウンターが置かれている場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手によって破壊された場合、「捕食植物ヘリアンフォリンクス」以外の自分の墓地のドラゴン族・植物族の闇属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食活動",h:"ぷれでたー・ぷらくてぃす",e:"Predapractice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から「捕食植物」モンスター１体を特殊召喚する。\nその後、デッキから「捕食活動」以外の「プレデター」カード１枚を手札に加える。\nこのカードの発動後、ターン終了時まで自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"プレデター・プライム・フュージョン",h:"ぷれでたー・ぷらいむ・ふゅーじょん",e:"Predaprime Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：フィールドに「捕食植物」モンスターが存在する場合に発動できる。\n自分・相手フィールドから、闇属性の融合モンスターカードによって決められた、自分フィールドの闇属性モンスター２体以上を含む融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"捕食計画",h:"ぷれでたー・ぷらんにんぐ",e:"Predaplanning",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「捕食植物」モンスター１体を墓地へ送って発動できる。\nフィールドの表側表示モンスター全てに捕食カウンターを１つずつ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。\n②：このカードが墓地に存在する状態で、自分が闇属性モンスターの融合召喚に成功した場合、墓地のこのカードを除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"カクリヨノチザクラ",h:"かくりよのちざくら",e:"Red Blossoms from Underroot",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分及び相手の墓地から１枚ずつ、魔法・罠カードを対象として発動できる。\nそのカードを除外し、このカードを特殊召喚する。\n②：このカードをリリースし、自分または相手の墓地の融合・S・X・リンクモンスター１体を対象として発動できる。\nそのモンスターを除外し、そのモンスターとは種類（融合・S・X・リンク）が異なるモンスター１体を自分の墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リンクアップル",h:"りんくあっぷる",e:"Link Apple",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n自分のEXデッキの裏側表示のカードをランダムに１枚選んで除外する。\n除外したカードがリンクモンスターだった場合、このカードを手札から特殊召喚する。\n違った場合、このカードを手札から捨て、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"飛鯉",h:"ひごい",e:"Flying Red Carp",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から水属性モンスター１体を捨てて発動できる。\nこのカードの攻撃力は５００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードが戦闘で相手モンスターを破壊した時、このカードをリリースして発動できる。\n手札・デッキから魚族・海竜族・水族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"虫忍 ハガクレミノ",h:"ちゅうにん　はがくれみの",e:"Shinobi Insect Hagakuremino",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"カード名が異なるモンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先にモンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。\n②：このカードのリンク先のモンスターが戦闘・効果で破壊された場合に発動できる。\n自分の手札・墓地からレベル４以下の昆虫族モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:["下","左上"],ta:[],al:[]},
  {n:"一惜二跳",h:"いっせきにちょう",e:"Two Toads with One Sting",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを相手フィールドに効果を無効にして特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：装備モンスターは攻撃できず、攻撃対象にならない。\n③：装備モンスターが融合・S・X・リンク召喚の素材になった事でこのカードが墓地へ送られた場合に発動できる。\n融合・S・X・リンク召喚されたそのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"獣湧き肉躍り",h:"ししわきにくおどり",e:"Dances with Beasts",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手モンスターの直接攻撃宣言時、相手フィールドの表側表示モンスターの攻撃力の合計が８０００以上の場合に発動できる。\n元々のカード名が異なるモンスター３体を、自分の手札・デッキ・墓地からそれぞれ１体ずつ選んで攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シルバーヴァレット・ドラゴン",h:"しるばーう゛ぁれっと・どらごん",e:"Silverrokket Dragon",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを対象とするリンクモンスターの効果が発動した時に発動できる。\nこのカードを破壊する。\nその後、相手のEXデッキを確認し、その内の１枚を選んで除外する。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「シルバーヴァレット・ドラゴン」以外の「ヴァレット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレット・トレーサー",h:"う゛ぁれっと・とれーさー",e:"Rokket Tracer",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「ヴァレット・トレーサー」以外の「ヴァレット」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしかEXデッキから特殊召喚できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレット・リチャージャー",h:"う゛ぁれっと・りちゃーじゃー",e:"Rokket Recharger",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚された自分フィールドの闇属性モンスターが戦闘・効果で破壊された場合、手札・フィールドのこのカードを墓地へ送り、破壊されたそのモンスター１体を対象として発動できる。\nそのモンスターとは元々のカード名が異なる闇属性モンスター１体を自分の墓地から選んで特殊召喚する。\n②：EXデッキから特殊召喚された闇属性モンスターが自分フィールドに存在する限り、相手はこのカードを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エクスプロードヴァレット・ドラゴン",h:"えくすぷろーどう゛ぁれっと・どらごん",e:"Exploderokket Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードを対象とするリンクモンスターの効果が発動した時に発動できる。\nこのカードを破壊する。\nその後、お互いに２０００ダメージを受ける。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「エクスプロードヴァレット・ドラゴン」以外の「ヴァレット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アブソルーター・ドラゴン",h:"あぶそるーたー・どらごん",e:"Absorouter Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:2800,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ヴァレット」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「ヴァレット」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"チェックサム・ドラゴン",h:"ちぇっくさむ・どらごん",e:"Checksum Dragon",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:400,de:2400,tx:"①：相手モンスターの攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、このカードの守備力の半分だけ自分のLPを回復する。\n②：攻撃表示のこのカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレル・サプライヤー",h:"う゛ぁれる・さぷらいやー",e:"Borrel Supplier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ヴァレル」リンクモンスターが存在する場合、自分・相手のスタンバイフェイズに自分の墓地の「ヴァレット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"ラピッド・トリガー",h:"らぴっど・とりがー",e:"Rapid Trigger",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：融合モンスターカードによって決められた自分フィールドの融合素材モンスターを破壊し、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはEXデッキから特殊召喚されたモンスターしか攻撃できず、EXデッキから特殊召喚された他のモンスターが発動した効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼロ・デイ・ブラスター",h:"ぜろ・でい・ぶらすたー",e:"Zero-Day Blaster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの闇属性リンクモンスター１体をリリースし、そのリンクマーカーの数だけフィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガンスリンガー・エクスキューション",h:"がんすりんがー・えくすきゅーしょん",e:"Execute Protocols",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のバトルフェイズに、自分の墓地から闇属性リンクモンスター１体を除外し、自分フィールドの「ヴァレル」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、このカードを発動するために除外したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"トポロジック・ゼロヴォロス",h:"とぽろじっく・ぜろう゛ぉろす",e:"Topologic Zeroboros",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター２体以上\n自分はこのカードのリンク先となるEXモンスターゾーンにモンスターを出せない。\n①：このカードの攻撃力は除外されているカードの数×２００アップする。\n②：このカードが既にモンスターゾーンに存在する状態で、このカード以外のモンスターがリンクモンスターのリンク先に特殊召喚された場合に発動する。\nフィールドのカードを全て除外する。\n③：このカードが自身の効果で除外された場合、次のターンのスタンバイフェイズに発動する。\n除外されているこのカードを特殊召喚する。",li:"",lk:["左上","右上","左下","右下"],ta:[],al:[]},
  {n:"ヴァレルロード・F・ドラゴン",h:"う゛ぁれるろーど・ふゅりあす・どらごん",e:"Borreload Furious Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"ドラゴン族・闇属性モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。\n②：墓地のこのカードを除外し、自分の墓地の闇属性リンクモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ソーンヴァレル・ドラゴン",h:"そーんう゛ぁれる・どらごん",e:"Quadborrel Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「ヴァレット」モンスターを含むドラゴン族モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこの効果でリンクモンスターを破壊した場合、さらにそのリンクマーカーの数まで自分の手札・墓地から「ヴァレット」モンスターを選んで特殊召喚できる（同名カードは１枚まで）。\nこの効果の発動後、ターン終了時まで自分はリンク２以下のモンスターをEXデッキから特殊召喚できない。",li:"",lk:["下","左"],ta:[],al:[]},
  {n:"抹殺の指名者",h:"まっさつのしめいしゃ",e:"Crossout Designator",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：カード名を１つ宣言して発動できる。\n宣言したカード１枚をデッキから除外する。\nターン終了時まで、この効果で除外したカード及びそのカードと元々のカード名が同じカードの効果は無効化される。",li:1,lk:[],ta:[],al:[]},
  {n:"サプレス・コライダー",h:"さぷれす・こらいだー",e:"Suppression Collider",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:2800,de:2600,tx:"ネットワーク世界の巨悪と戦う熟練の闘士。\nその鉄鎚に圧縮されたデータは、誰にも復元できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女マンダリン",h:"まりんせすまんだりん",e:"Marincess Mandarin",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドに「マリンセス」モンスターが２体以上存在する場合、自分フィールドの水属性リンクモンスター１体を対象として発動できる。\nそのモンスターのリンク先となる自分フィールドにこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女クラウンテイル",h:"まりんせすくらうんている",e:"Marincess Crown Tail",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:600,de:2300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：モンスター同士が戦闘を行うダメージ計算時に、手札からこのカード以外の「マリンセス」モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚し、その戦闘で発生する自分への戦闘ダメージは半分になる。\n②：相手モンスターが攻撃するダメージステップ開始時に、墓地のこのカードを除外して発動できる。\nこのターン自分は、自分の墓地の「マリンセス」リンクモンスターのリンクマーカーの合計×１０００以下の戦闘ダメージを受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女ブルータン",h:"まりんせすぶるーたん",e:"Marincess Blue Tang",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「海晶乙女ブルータン」以外の「マリンセス」モンスター１体を墓地へ送る。\n②：このカードが水属性リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\n自分のデッキの上からカードを３枚めくる。\nその中から「マリンセス」カード１枚を選んで手札に加える事ができる。\n残りのカードはデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"チョバムアーマー・ドラゴン",h:"ちょばむあーまー・どらごん",e:"Chobham Armor Dragon",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から攻撃表示で特殊召喚する。\nこのターン、この効果で特殊召喚したこのカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは半分になる。\n②：このカードがリンク素材として墓地へ送られた場合、このカード以外の自分の墓地の闇属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n相手は自身の墓地のモンスター１体を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・マーシャルアンペロ",h:"だいなれすらー・まーしゃるあんぺろ",e:"Dinowrestler Martial Ampelo",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の他の「ダイナレスラー」モンスターが、その攻撃力以上の攻撃力を持つ相手モンスターと戦闘を行うダメージ計算時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは半分になる。\n②：相手モンスターの攻撃宣言時に墓地のこのカードを除外して発動できる。\nデッキから「ダイナレスラー・マーシャルアンペロ」以外の「ダイナレスラー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・バーリオニクス",h:"だいなれすらー・ばーりおにくす",e:"Dinowrestler Valeonyx",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：自分フィールドにリンク３以上の「ダイナレスラー」モンスターが存在する限り、自分フィールドの表側表示モンスターはリンク３以下の相手モンスターが発動した効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"破械童子アルハ",h:"はかいどうじあるは",e:"Unchained Twins - Aruha",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、このカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。\n②：フィールドのこのカードが戦闘または「破械童子アルハ」以外のカードの効果で破壊された場合に発動できる。\n手札・デッキから「破械童子アルハ」以外の「破械」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破械童子ラキア",h:"はかいどうじらきあ",e:"Unchained Twins - Rakea",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果の発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。\nこの効果は相手ターンでも発動できる。\n②：フィールドのこのカードが戦闘または「破械童子ラキア」以外のカードの効果で破壊された場合に発動できる。\n手札・デッキから「破械童子ラキア」以外の「破械」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破械神の禍霊",h:"はかいしんのまがたま",e:"Unchained Soul of Disaster",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:3000,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの攻撃力は、自分の墓地の「破械」カードの数×３００アップする。\n②：相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターと自分フィールドのこのカードのみを素材として闇属性リンクモンスター１体をリンク召喚する。\n③：フィールドのこのカードが戦闘・効果で破壊された場合、「破械神の禍霊」以外の自分の墓地の「破械」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣サジタリィ",h:"ぐらでぃあるびーすとさじたりぃ",e:"Gladiator Beast Sagittarii",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した場合、手札から「剣闘獣」カード１枚を捨てて発動できる。\n自分はデッキから２枚ドローする。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣サジタリィ」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣アトリクス",h:"ぐらでぃあるびーすとあとりくす",e:"Gladiator Beast Attorix",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した場合、デッキ・EXデッキから「剣闘獣アトリクス」以外の「剣闘獣」モンスター１体を墓地へ送って発動できる。\nエンドフェイズまで、このカードは墓地へ送ったモンスターと同じレベルになり、同名カードとして扱う。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣アトリクス」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ウェスパシアス",h:"ぐらでぃあるびーすとうぇすぱしあす",e:"Gladiator Beast Vespasius",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「剣闘獣」モンスターがモンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードを手札から特殊召喚する。\n②：「剣闘獣」モンスターの効果で特殊召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの攻撃力は５００アップする。\n③：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣ウェスパシアス」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"輝光竜セイファート",h:"きこうりゅうせいふぁーと",e:"Starliege Seyfert",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、ドラゴン族モンスターを任意の数だけ墓地へ送って発動できる。\n墓地へ送ったモンスターの元々のレベルの合計と同じレベルを持つドラゴン族モンスター１体をデッキから手札に加える。\n②：墓地のこのカードを除外し、自分の墓地の光・闇属性のドラゴン族・レベル８モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"星雲龍ネビュラ",h:"せいうんりゅうねびゅら",e:"Nebula Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札のこのカードと手札のドラゴン族・レベル８モンスター１体を相手に見せて発動できる。\nその２体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分は光・闇属性のドラゴン族モンスターしか召喚・特殊召喚できない。\n②：墓地のこのカードを除外し、自分の墓地の光・闇属性のドラゴン族・レベル４モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"螺旋竜バルジ",h:"らせんりゅうばるじ",e:"Galactic Spiral Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドに光・闇属性のドラゴン族モンスターが２体以上存在する場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：自分メインフェイズに発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで８になる。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマージ－ローリエ",h:"あろまーじ－ろーりえ",e:"Aromage Laurel",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のLPが相手より多い場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分のLPが回復した場合、フィールドの植物族モンスター１体を対象として発動する。\nこのターン、そのモンスターをチューナーとして扱う。\n③：このカードが墓地へ送られた場合に発動できる。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマージ－マジョラム",h:"あろまーじ－まじょらむ",e:"Aromage Marjoram",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の植物族モンスターが戦闘で破壊された時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分は５００LP回復する。\n②：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分の植物族モンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n③：自分のLPが回復した場合、自分フィールドの「アロマ」モンスターの数まで相手の墓地のカードを対象として発動する。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"天威龍－アシュナ",h:"てんいりゅう－あしゅな",e:"Tenyi Spirit - Ashuna",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1600,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに効果モンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに効果モンスター以外の表側表示モンスターが存在する場合、手札・墓地のこのカードを除外して発動できる。\nデッキから「天威龍－アシュナ」以外の「天威」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は幻竜族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルド・メガキレラ",h:"えう゛ぉるど・めがきれら",e:"Evoltile Megachirella",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの爬虫類族モンスター１体をリリースし、手札を１枚捨てて発動できる。\nデッキからレベル６以下の恐竜族・炎属性モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドのX素材の無いドラゴン族Xモンスター１体を対象として発動できる。\n自分の手札・墓地から爬虫類族・恐竜族のモンスターを２体まで選び、対象のモンスターの下に重ねてX素材とする（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物－『星鍵』",h:"せいいぶつ－『せいけん』",e:"World Legacy - \"World Key\"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から「星遺物」カード１枚を捨てて発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにアドバンス召喚できる。\n②：このカードが相手リンクモンスターと戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターを持ち主のEXデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"無限起動ブルータルドーザー",h:"むげんきどうぶるーたるどーざー",e:"Infinitrack Brutal Dozer",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの機械族・地属性モンスター１体をリリースして発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合に発動できる。\nデッキから「無限起動ブルータルドーザー」以外の「無限起動」モンスター１体を効果を無効にして守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族・地属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧嘴－八咫御先",h:"きこうし－やたのみさき",e:"Gizmek Yata, the Gleaming Vanguard",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2050,de:2050,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは自分フィールドの通常召喚されたモンスター１体をリリースして手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに発動できる。\nモンスター１体を召喚する。\n自分はこの効果で召喚したターン、そのモンスターと元々の種族が同じモンスターしか特殊召喚できない。\n③：このカードの①の方法で特殊召喚したこのカードがリリースされた場合に発動する。\n自分は２０５０LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"礫岩の霊長－コングレード",h:"れきがんのれいちょう－こんぐれーど",e:"Primineral Kongreat",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2000,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：相手の手札・デッキからモンスターが墓地へ送られた場合に発動できる。\nこのカードを手札から裏側守備表示で特殊召喚する。\n②：このカードがリバースした場合、フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"燎星のプロメテオロン",h:"りょうせいのぷろめておろん",e:"Prometeor, the Burning Star",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードの攻撃でメインモンスターゾーンの相手モンスターを破壊した時、手札を１枚捨てて発動できる。\nこのカードは続けて攻撃できる。\n次のターンの終了時まで、そのモンスターが存在していたゾーンは使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ブライニグル",h:"ぶらいにぐる",e:"Brinegir",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1000,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地の水属性モンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、対象のモンスターの攻撃力分アップする。\n②：このカードが墓地へ送られた場合、自分フィールドの水属性モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"闇の精霊 ルーナ",h:"やみのせいれい　るーな",e:"Luna the Dark Spirit",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地から闇属性モンスター１体を除外した場合に特殊召喚できる。\n①：自分スタンバイフェイズに発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"異次元の哨戒機",h:"いじげんのしょうかいき",e:"D.D. Patrol Plane",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが除外されたターンのエンドフェイズに発動できる。\n自分の手札・フィールド・墓地のカード１枚を選んで除外し、このカードを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホップ・イヤー飛行隊",h:"ほっぷ・いやーひこうたい",e:"Hop Ear Squadron",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：相手メインフェイズに、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、そのモンスターとこのカードのみを素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔筒覗ベイオネーター",h:"まづつしべいおねーたー",e:"Bayonater, the Baneful Barrel",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は相手フィールドのモンスターの数×１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミミックリル",h:"みみっくりる",e:"Mimikuril",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のデッキの一番上のカードをめくり、それがモンスターだった場合、そのモンスターを特殊召喚し、このカードを持ち主のデッキの一番下に戻す。\n違った場合、または特殊召喚できない場合、そのカードをデッキの一番下に戻す。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"独法師",h:"ひとりぼうし",e:"Bonze Alone",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールドにモンスターが存在する場合、このカードは召喚・特殊召喚できない。\n①：このカードは手札から攻撃表示で特殊召喚できる。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外のモンスターが召喚・反転召喚・特殊召喚された場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘獣ドミティアノス",h:"ぐらでぃあるびーすとどみてぃあのす",e:"Gladiator Beast Domitianus",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"海竜族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:1200,tx:"「剣闘獣ウェスパシアス」＋「剣闘獣」モンスター×２\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、相手がモンスターの効果を発動した時に発動できる。\nその発動を無効にし破壊する。\n②：このカードがモンスターゾーンに存在する限り、相手モンスターの攻撃対象は自分が選択する。\n③：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のEXデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマセラフィ－スイート・マジョラム",h:"あろませらふぃ－すいーと・まじょらむ",e:"Aromaseraphy Sweet Marjoram",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nデッキから「潤いの風」「渇きの風」「恵みの風」のいずれか１枚を手札に加える。\n②：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分フィールドの植物族モンスターは相手の効果の対象にならない。\n③：自分のLPが回復した場合、相手フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"天威の龍鬼神",h:"てんいのりゅうきしん",e:"Draco Berserker of the Tenyi",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの効果を発動した時に発動できる。\nそのモンスターを除外する。\n②：このカードの攻撃で効果モンスターを破壊し墓地へ送った場合に発動する。\nこのカードの攻撃力は破壊したモンスターの元々の攻撃力分アップする。\nこのバトルフェイズ中、このカードはもう１度だけモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"御影志士",h:"みかげしし",e:"Gallant Granite",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"レベル４モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n\n●デッキから岩石族モンスター１体を手札に加える。\n\n●手札から岩石族モンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファイアウォール・ドラゴン・ダークフルード",h:"ふぁいあうぉーる・どらごん・だーくふるーど",e:"Firewall Dragon Darkfluid",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター３体以上\n①：このカードがリンク召喚に成功した場合に発動できる。\n自分の墓地のサイバース族モンスターの種類（儀式・融合・S・X）の数だけこのカードにカウンターを置く。\n②：このカードの攻撃力はバトルフェイズの間、このカードのカウンターの数×２５００アップする。\n③：相手がモンスターの効果を発動した時、このカードのカウンターを１つ取り除いて発動できる。\nその発動を無効にする。\nこの効果をこのカードの攻撃宣言時からダメージステップ終了時までに発動した場合、このカードはもう１度続けて攻撃できる。",li:"",lk:["上","左","右","左下","右下"],ta:[],al:[]},
  {n:"プロトコル・ガードナー",h:"ぷろとこる・がーどなー",e:"Protocol Gardna",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"サイバース族モンスター２体\n①：相手はこのカードのリンク先のモンスターを攻撃対象に選択できない。\n②：このカードは１ターンに１度だけ戦闘では破壊されない。\nその際、自分が受ける戦闘ダメージは０になる。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"転生炎獣パイロ・フェニックス",h:"さらまんぐれいとぱいろ・ふぇにっくす",e:"Salamangreat Pyro Phoenix",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"炎属性の効果モンスター２体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「転生炎獣パイロ・フェニックス」を素材としてリンク召喚に成功した場合に発動できる。\n相手フィールドのカードを全て破壊する。\n②：相手の墓地のリンクモンスター１体を対象として発動できる。\nそのモンスターを相手フィールドに特殊召喚する。\n③：相手フィールドにリンクモンスターが特殊召喚された場合、そのモンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"海晶乙女クリスタルハート",h:"まりんせすくりすたるはーと",e:"Marincess Crystal Heart",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"水属性モンスター２体\n①：このカードはEXモンスターゾーンに存在する限り、相手モンスターの効果を受けない。\n②：このカードが相手モンスターと戦闘を行うダメージステップの間、その相手モンスターは自身以外のカードの効果を受けない。\n③：このカードまたはこのカードのリンク先の自分の「マリンセス」リンクモンスターが攻撃対象に選択された時、手札から「マリンセス」モンスター１体を墓地へ送って発動できる。\nその自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"海晶乙女ワンダーハート",h:"まりんせすわんだーはーと",e:"Marincess Wonder Heart",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"水属性モンスター２体以上\n①：このカードがモンスターと戦闘を行うダメージ計算時に１度、発動できる。\nこのカードに装備された自分の「マリンセス」モンスターカード１枚を選んで特殊召喚する。\nこのカードはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。\nこの効果で特殊召喚したモンスターを、エンドフェイズに装備カード扱いとしてこのカードに装備する。\n②：このカードが相手によって破壊された場合に発動できる。\n自分の墓地からリンク３以下の「マリンセス」モンスター１体を選んで特殊召喚する。",li:"",lk:["左","右","左下","右下"],ta:[],al:[]},
  {n:"海晶乙女シーエンジェル",h:"まりんせすしーえんじぇる",e:"Marincess Sea Angel",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"レベル４以下の「マリンセス」モンスター１体\n自分は「海晶乙女シーエンジェル」を１ターンに１度しかリンク召喚できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「マリンセス」魔法カード１枚を手札に加える。",li:"",lk:["左"],ta:[],al:[]},
  {n:"破械神ラギア",h:"はかいしんらぎあ",e:"Unchained Soul of Rage",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"「破械神」モンスターを含むモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手メインフェイズに、相手フィールドの特殊召喚された表側表示モンスター１体を対象として発動できる。\nその相手モンスターと自分フィールドのこのカードのみを素材として「破械神ラギア」以外の闇属性リンクモンスター１体をリンク召喚する。\n②：フィールドのこのカードが戦闘・効果で破壊された場合、「破械神ラギア」以外の自分の墓地の悪魔族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"破械神アルバ",h:"はかいしんあるば",e:"Unchained Soul of Anguish",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"「破械神」モンスターを含むモンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターと自分フィールドのこのカードのみを素材として「破械神アルバ」以外の闇属性リンクモンスター１体をリンク召喚する。\n②：フィールドのこのカードが戦闘・効果で破壊された場合、「破械神アルバ」以外の自分の墓地の悪魔族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"破械雙王神ライゴウ",h:"はかいそうおうしんらいごう",e:"Unchained Abomination",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"リンクモンスターを含むモンスター２体以上\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「破械雙王神ライゴウ」以外のカードの効果でフィールドのカードが破壊された場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカード以外のモンスターが戦闘で破壊された時、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：自分・相手のエンドフェイズにフィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:["上","左下","下","右下"],ta:[],al:[]},
  {n:"スレイブパンサー",h:"すれいぶぱんさー",e:"Test Panther",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"「剣闘獣」モンスターを含むモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「剣闘獣」カード１枚を手札に加える。\n②：自分フィールドの「剣闘獣」モンスター１体を対象として発動できる。\nその「剣闘獣」モンスターを持ち主のデッキに戻し、そのモンスターとは元々のカード名が異なる「剣闘獣」モンスター１体を、「剣闘獣」モンスターの効果による特殊召喚扱いとしてデッキから特殊召喚する。",li:"",lk:["左下","下"],ta:[],al:[]},
  {n:"銀河衛竜",h:"ぎゃらくしー・さてらいと・どらごん",e:"Galaxy Satellite Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"ドラゴン族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のバトルフェイズに、フィールド・墓地のこのカードを除外し、自分フィールドの元々の種族・属性がドラゴン族・光属性の「No.」Xモンスター１体を対象として発動できる。\nバトルフェイズ終了時まで、相手が受ける戦闘ダメージは半分になり、対象のモンスターの攻撃力は、そのモンスターの持つ「No.」の数値×１００になる。\n②：相手エンドフェイズに発動できる。\nデッキからカード１枚を選んでデッキの一番上に置く。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"呪眼の女王 ゴルゴーネ",h:"じゅがんのじょおう　ごるごーね",e:"Gorgon, Empress of the Evil Eyed",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"「呪眼」モンスターを含むモンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、自分の墓地の「呪眼」カードの種類×１００アップする。\n②：このカードが「セレンの呪眼」を装備している場合、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。\n③：このカードの②の効果を発動した場合、次のスタンバイフェイズに発動する。\nこのカードのリンク先のモンスター１体を選んで破壊する。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"I：Pマスカレーナ",h:"あいぴーますかれーな",e:"I:P Masquerena",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"リンクモンスター以外のモンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをリンク素材としてリンク召喚する。\n②：このカードをリンク素材としたリンクモンスターは相手の効果では破壊されない。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"熾天蝶",h:"せらふぃむ・ぱぴよん",e:"Seraphim Papillion",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リンク","効果"],ma:"",tp:"",at:2100,de:"-",tx:"カード名が異なるモンスター２体以上\nこのカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリンク召喚に成功した場合に発動する。\nこのカードのリンク素材とした昆虫族モンスターの数だけこのカードにカウンターを置く。\n②：このカードの攻撃力は、このカードのカウンターの数×２００アップする。\n③：このカードのカウンターを１つ取り除いて発動できる。\n自分の墓地からレベル４以下の昆虫族モンスター１体を選んで守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"転生炎獣の炎虞",h:"さらまんぐれいと・ばーにんぐしぇる",e:"Salamangreat Burning Shell",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から「サラマングレイト」モンスター１体を効果を無効にして特殊召喚し、そのモンスターを含む自分フィールドのモンスターを素材として「サラマングレイト」リンクモンスター１体をリンク召喚する。\nこのターン、この効果でリンク召喚したモンスターは攻撃できず、効果を発動できない。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の「サラマングレイト」リンクモンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣の超転生",h:"さらまんぐれいと・とらんせんど",e:"Salamangreat Transcendence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「サラマングレイト」リンクモンスター１体を対象として発動できる。\nその自分のモンスター１体のみを素材として同名の「サラマングレイト」リンクモンスター１体をリンク召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女の闘海",h:"まりんせす・ばとるおーしゃん",e:"Marincess Battle Ocean",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「マリンセス」モンスターの攻撃力は２００アップし、さらに装備している「マリンセス」カードの数×６００アップする。\n②：「海晶乙女クリスタルハート」を素材としてリンク召喚したEXモンスターゾーンの自分のモンスターは相手の効果を受けない。\n③：自分がEXモンスターゾーンに「マリンセス」リンクモンスターをリンク召喚した時に発動できる。\n自分の墓地から「マリンセス」リンクモンスターを３体まで選び、そのリンク召喚したモンスターに装備カード扱いとして装備する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"雙王の械",h:"そうおうのかせ",e:"Abomination's Prison",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「破械」カード１枚を手札に加える。\n②：セットされたこのカードが効果で破壊された場合に発動できる。\nデッキから「破械」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破械神の慟哭",h:"はかいしんのどうこく",e:"Wailing of the Unchained Souls",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「破械」リンクモンスターのリンク召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：セットされたこのカードが効果で破壊された場合に発動できる。\nデッキから「破械」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"再起する剣闘獣",h:"さいきするぐらでぃあるびーすと",e:"Gladiator Beast's Comeback",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：同じ種族のモンスターが自分フィールドに存在しない「剣闘獣」モンスター１体を自分の手札・墓地から選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"団結する剣闘獣",h:"だんけつするぐらでぃあるびーすと",e:"Gladiator Beast United",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「剣闘獣」モンスターでしか攻撃宣言できない。\n①：自分・相手のバトルフェイズに発動できる。\n自分の手札・フィールド・墓地から、「剣闘獣」融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、その融合モンスター１体をEXデッキから召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘排斥波",h:"ぐらでぃあるりじぇくしょん",e:"Gladiator Rejection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「剣闘獣」モンスターは、バトルフェイズ以外では相手の効果の対象にならない。\n②：自分のデッキから「剣闘獣」モンスターが特殊召喚された場合に発動できる。\n同じ種族のモンスターが自分フィールドに存在しない「剣闘獣」モンスター１体をデッキから守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アロマガーデニング",h:"あろまがーでにんぐ",e:"Aroma Gardening",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「アロマ」モンスターの召喚・特殊召喚に成功した場合に発動できる。\n自分は１０００LP回復する。\n②：自分のLPが相手より少ない場合、相手モンスターの攻撃宣言時に発動できる。\nデッキから「アロマ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エレキカンシャ",h:"えれきかんしゃ",e:"Wattrain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの雷族モンスターの種類の数まで、デッキから「エレキカンシャ」以外の「エレキ」カードを手札に加える（同名カードは１枚まで）。\n②：墓地のこのカードを除外して発動できる。\n手札から「エレキ」モンスターを可能な限り特殊召喚する（同名カードは１枚まで）。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星遺物の導く先",h:"せいいぶつのみちびくさき",e:"The World Legacy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「星遺物の導く先」は自分フィールドに１枚しか表側表示で存在できない。\n②：フィールドのレベル５以上の表側表示モンスターが墓地へ送られる度に、１体につき１つこのカードにカウンターを置く（最大７つまで）。\n③：カウンターが７つ置かれているこのカードを墓地へ送って発動できる。\nEXデッキからサイバース族リンクモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴルゴネイオの呪眼",h:"ごるごねいおのじゅがん",e:"Evil Eye of Gorgoneio",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「呪眼」モンスターにのみ装備可能。\nこのカード名のカードは１ターンに１枚しか発動できず、このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは魔法＆罠ゾーンに存在する限り、カード名を「セレンの呪眼」として扱う。\n②：自分のLPが相手より少ない場合、装備モンスターの攻撃力はLPの差の数値分アップする。\n③：墓地のこのカードを除外し、手札から「呪眼」カード１枚を捨てて発動できる。\nデッキから「ゴルゴネイオの呪眼」以外の「呪眼」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"犬賞金",h:"けんしょうきん",e:"Bownty",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のモンスターの攻撃で相手モンスターを破壊した時、自分の墓地のカード１枚を対象として発動できる。\nそのカードを手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果の発動ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"ご隠居の大釜",h:"ごいんきょのおおがま",e:"Cauldron of the Old Man",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、このカードにカウンターを１つ置く。\n②：自分スタンバイフェイズに発動する。\nこのカードにカウンターを１つ置く。\n③：１ターンに１度、以下の効果から１つを選択して発動できる。\n\n●自分はこのカードのカウンターの数×５００LP回復する。\n\n●このカードのカウンターの数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"霊子もつれ",h:"りょうしもつれ",e:"Spiritual Entanglement",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターをエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"オールド・マインド",h:"おーるど・まいんど",e:"Old Mind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手の手札をランダムに１枚確認する。\nその後、以下の効果から１つを選んで適用する。\n\n●確認したカードと同じ種類（モンスター・魔法・罠）の自分の手札のカード１枚と確認した相手のカードを捨てる。\nその後、フィールドのこのカードを相手の手札に加え、自分はデッキから１枚ドローする。\n\n●自分は１０００LPを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女雪花",h:"まりんせす・すのー",e:"Marincess Snow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「マリンセス」リンクモンスターが戦闘・効果で破壊された場合、そのモンスター１体を対象として発動できる。\nそのモンスターよりリンクマーカーの数が少ない「マリンセス」リンクモンスター１体をリンク召喚扱いとしてEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは自分フィールドに存在する限り、相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女瀑布",h:"まりんせす・かたらくと",e:"Marincess Cascade",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドにリンク３以上の「マリンセス」モンスターが存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドの「マリンセス」リンクモンスターを任意の数だけ次の自分スタンバイフェイズまで除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、このカードを発動するために除外したリンクモンスターのリンクマーカーの合計×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"破械唱導",h:"はかいしょうどう",e:"Escape of the Unchained",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「破械」モンスター１体とフィールドのカード１枚を対象として発動できる。\nそのカード２枚を破壊する。\n②：セットされたこのカードが効果で破壊された場合に発動できる。\nデッキから「破械」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破械雙極",h:"はかいそうきょく",e:"Abominable Chamber of the Unchained",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地から「破械」モンスター１体を選んで特殊召喚する。\n②：セットされたこのカードが効果で破壊された場合に発動できる。\nデッキから「破械」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"猛進する剣闘獣",h:"もうしんするぐらでぃあるびーすと",e:"Gladiator Beast Charge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「剣闘獣」モンスターの種類の数まで、フィールドの表側表示のカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"剣闘海戦",h:"ぐらでぃあるなうまきあ",e:"Gladiator Naumachia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在し、自分フィールドに「剣闘獣」モンスターが存在する限り、攻撃可能な相手モンスターは攻撃しなければならない。\n②：１ターンに１度、自分の手札・墓地から「剣闘獣」モンスター１体をデッキに戻し、自分フィールドの「剣闘獣」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の守備力分アップする。\n③：魔法＆罠ゾーンのこのカードが効果で破壊された場合に発動できる。\nこのターン、自分の「剣闘獣」モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"タキオン・ギャラクシースパイラル",h:"たきおん・ぎゃらくしーすぱいらる",e:"Tachyon Spiral Galaxy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n自分フィールドに「ギャラクシーアイズ・タキオン・ドラゴン」モンスターが存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドのドラゴン族の「ギャラクシー」モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、戦闘では破壊されず、自身以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"恵みの風",h:"めぐみのかぜ",e:"Blessed Winds",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\n\n●手札及び自分フィールドの表側表示モンスターの中から、植物族モンスター１体を墓地へ送って発動できる。\n自分は５００LP回復する。\n\n●自分の墓地の植物族モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、自分は５００LP回復する。\n\n●１０００LPを払って発動できる。\n自分の墓地から「アロマ」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"創星改帰",h:"そうせいかいき",e:"World Reassembly",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札・デッキから「星遺物」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"超整地破砕",h:"くらっしゃー・らん",e:"Crusher Run",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の機械族・地属性モンスターが戦闘・効果で破壊された場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：墓地のこのカードを除外して発動できる。\n自分のデッキ・墓地から「超接地展開」または「超信地旋回」１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"のどかな埋葬",h:"のどかなまいそう",e:"Peaceful Burial",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからモンスター１体を墓地へ送る。\nこのターン、自分はこの効果で墓地へ送ったカード及びその同名カードの効果の発動ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"心太砲式",h:"ところてんほうしき",e:"Jelly Cannon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手のモンスターの攻撃宣言時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ソウル・レヴィ",h:"そうる・れう゛ぃ",e:"Soul Levy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「ソウル・レヴィ」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードが魔法＆罠ゾーンに存在する限り、相手がモンスターの特殊召喚に成功する度に、相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ドカンポリン",h:"どかんぽりん",e:"Boompoline!!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"使用していないモンスターゾーンを１ヵ所指定してこのカードを発動できる。\n①：指定したゾーンに効果モンスターが特殊召喚された場合に発動する。\nそのゾーンに存在するモンスターとこのカードの２枚を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"GO－DDD神零王ゼロゴッド・レイジ",h:"ごー－でぃーでぃーでぃーしんれいおうぜろごっど・れいじ",e:"Go! - D/D/D Divine Zero King Rage",t:"monster",a:"闇",l:10,ps:"",pe:"このカード名の①②のP効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがPゾーンに存在し、自分が効果ダメージを受ける場合、そのダメージは０になる。\n②：このカードがPゾーンに存在する限り、自分はレベル５以上の「DD」モンスターを召喚する場合に必要なリリースをなくす事ができる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカード以外の自分フィールドのモンスター１体をリリースして発動できる。\n以下の効果から１つを選び、ターン終了時まで適用する。\n●このカードは直接攻撃できる。\n●相手は魔法＆罠ゾーンのカードの効果を発動できない。\n●相手は手札・墓地のカードの効果を発動できない。\n②：相手のLPが４０００以下の場合、このカードの攻撃宣言時に発動できる。\nこのカードの攻撃力はターン終了時まで、相手のLPの数値分アップする。\n③：このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"カプシェル",h:"かぷしぇる",e:"Capshell",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリリースされた場合に発動できる。\n自分はデッキから１枚ドローする。\n②：このカードが融合・S・リンク召喚の素材になり、墓地へ送られた場合または除外された場合に発動できる。\n自分はデッキから１枚ドローする。\n③：X素材のこのカードがXモンスターの効果を発動するために取り除かれた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"警衛バリケイドベルグ",h:"けいえいばりけいどべるぐ",e:"Barricadeborg Blocker",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"カード名が異なるモンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨てて発動できる。\nこのターンのエンドフェイズに、自分の墓地から永続魔法カードまたはフィールド魔法カード１枚を選んで手札に加える。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの表側表示の魔法カードは相手の効果では破壊されない。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"死翼のフレスヴェイス",h:"しよくのふれすう゛ぇいす",e:"Hraesvelgr, the Desperate Doom Eagle",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"風属性モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：相手の墓地にモンスターが存在しない場合、このカードの攻撃力は２４００アップする。\n②：相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"一曲集中",h:"いっきょくしゅうちゅう",e:"Star Power!!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のリンクモンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその自分のモンスターの攻撃力はそのダメージ計算時のみ、そのリンク先のモンスターのレベル・ランクの合計×４００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"風魔の波動",h:"ふうまのはどう",e:"Fuhma Wave",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なり、同じ属性を持つフィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の乙女－イケロス",h:"ゆめまきょうのおとめ－いけろす",e:"Ikelos, the Dream Mirror Sprite",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\nデッキから「夢魔鏡の乙女－イケロス」以外の「夢魔鏡」カード１枚を手札に加える。\n②：フィールドゾーンに「闇黒の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の夢魔－イケロス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の夢魔－イケロス",h:"ゆめまきょうのむま－いけろす",e:"Ikelos, the Dream Mirror Mara",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\n手札から「夢魔鏡の夢魔－イケロス」以外の「夢魔鏡」モンスター１体を特殊召喚する。\n②：フィールドゾーンに「聖光の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の乙女－イケロス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の白騎士－ルペウス",h:"ゆめまきょうのしろきし－るぺうす",e:"Morpheus, the Dream Mirror White Knight",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\nこのターン、このカードは戦闘・効果では破壊されない。\n②：フィールドゾーンに「闇黒の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の黒騎士－ルペウス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の黒騎士－ルペウス",h:"ゆめまきょうのくろきし－るぺうす",e:"Morpheus, the Dream Mirror Black Knight",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：フィールドゾーンに「聖光の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の白騎士－ルペウス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖光の夢魔鏡",h:"せいこうのゆめまきょう",e:"Dream Mirror of Joy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに光属性の「夢魔鏡」モンスターが存在する限り、自分フィールドの「夢魔鏡」モンスターの内、レベルが一番高いモンスター以外の「夢魔鏡」モンスターは、攻撃対象にならず、相手の効果の対象にもならない。\n②：自分・相手のエンドフェイズに、自分のフィールドゾーンのこのカードを除外して発動できる。\n手札・デッキから「闇黒の夢魔鏡」１枚を発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"闇黒の夢魔鏡",h:"あんこくのゆめまきょう",e:"Dream Mirror of Terror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに闇属性の「夢魔鏡」モンスターが存在する限り、相手がモンスターの特殊召喚に成功する度に、相手に３００ダメージを与える。\n②：自分・相手のエンドフェイズに、自分のフィールドゾーンのこのカードを除外して発動できる。\n手札・デッキから「聖光の夢魔鏡」１枚を発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の夢物語",h:"ゆめまきょうのゆめものがたり",e:"Dream Mirror Fantasy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「夢魔鏡」モンスターが存在する場合、除外されている自分の「聖光の夢魔鏡」「闇黒の夢魔鏡」を１枚ずつ対象として発動できる。\nそのカードをデッキに戻し、フィールドのカード１枚を選んで除外する。\n②：自分フィールドの「夢魔鏡」カードが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ストライカー・ドラゴン",h:"すとらいかー・どらごん",e:"Striker Dragon",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"レベル４以下のドラゴン族モンスター１体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「リボルブート・セクター」１枚を手札に加える。\n②：自分フィールドの表側表示モンスター１体と自分の墓地の「ヴァレット」モンスター１体を対象として発動できる。\n対象のフィールドのモンスターを破壊し、対象の墓地のモンスターを手札に加える。",li:"",lk:["左"],ta:[],al:[]},
  {n:"起動兵長コマンドリボルバー",h:"きどうへいちょうこまんどりぼるばー",e:"Boot-Up Corporal - Command Dynamo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分のフィールド・墓地の機械族の「ガジェット」モンスターを２体まで対象として発動できる（同名カードは１枚まで）。\nこのカードを手札から特殊召喚する。\nその後、対象の自分の「ガジェット」モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備しているモンスターの数×１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"起動提督デストロイリボルバー",h:"きどうていとくですとろいりぼるばー",e:"Boot-Up Admiral - Destroyer Dynamo",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できない。\n手札及び自分フィールドの表側表示のカードの中から、「ガジェット」モンスターカード２枚を墓地へ送った場合のみ特殊召喚できる。\n①：自分フィールドに「ガジェット」モンスターまたは装備カード扱いの「ガジェット」モンスターが存在する限り、このカードは戦闘・効果では破壊されない。\n②：１ターンに１度、このカード以外のフィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"起動指令 ギア・チャージ",h:"きどうしれい　ぎあ・ちゃーじ",e:"Boot-Up Order - Gear Charge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時に、自分フィールドの装備カード扱いの「ガジェット」モンスターカードを任意の数だけ対象にできる。\nその場合、そのカードを特殊召喚する。\n②：手札を１枚捨てて発動できる。\nデッキから「起動提督デストロイリボルバー」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"起動指令 ギア・フォース",h:"きどうしれい　ぎあ・ふぉーす",e:"Boot-Up Order - Gear Force",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが機械族モンスターのみの場合、自分または相手のモンスターの攻撃宣言時に発動できる。\n自分フィールドの機械族モンスターの数まで、相手フィールドの攻撃表示モンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機動砲塁 パワー・ホールド",h:"きどうほうるい　ぱわー・ほーるど",e:"Powerhold the Moving Battery",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（機械族・地・星４・攻０／守２０００）となり、モンスターゾーンに特殊召喚する。\nその後、手札・デッキから機械族・レベル４の「ガジェット」モンスター１体を選び、装備カード扱いとしてこのカードに装備できる。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚されたこのカードの攻撃力は、このカードの効果で装備しているモンスターの攻撃力の倍の数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魁炎星－シーブ",h:"かいえんせい－しーぶ",e:"Brotherhood of the Fire Fist - Ram",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、手札を１枚捨て、自分フィールドの「炎舞」魔法・罠カード１枚を対象として発動できる。\nそのカードとはカード名が異なる「炎舞」魔法・罠カード１枚をデッキから選んで自分フィールドにセットする。\n②：このカードが「炎星」モンスターの効果で特殊召喚に成功した場合に発動できる。\n同名カードが自分の墓地に存在しない「炎舞」魔法・罠カード１枚をデッキから選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"俊炎星－ゾウセイ",h:"しゅんえんせい－ぞうせい",e:"Brotherhood of the Fire Fist - Elephant",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分フィールドの表側表示の「炎舞」魔法・罠カード１枚を墓地へ送って発動できる。\n手札から「俊炎星－ゾウセイ」以外の「炎星」モンスター１体を特殊召喚する。\n②：自分の墓地の「炎舞」魔法・罠カード１枚を対象として発動できる。\nそのカードをデッキに戻す。\nその後、デッキからレベル５以上の「炎星」モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"寿炎星－リシュンマオ",h:"じゅえんせい－りしゅんまお",e:"Brotherhood of the Fire Fist - Panda",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「炎舞」魔法・罠カードを発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分の墓地から「寿炎星－リシュンマオ」以外の「炎星」モンスター１体を選んで特殊召喚できる。\nこの効果の発動後、ターン終了時まで自分は「炎星」モンスターしか特殊召喚できない。\n②：自分フィールドの「炎星」モンスターが相手の効果で破壊される場合、代わりに自分フィールドの表側表示の「炎舞」魔法・罠カード１枚を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"巧炎星－エランセイ",h:"こうえんせい－えらんせい",e:"Brotherhood of the Fire Fist - Eland",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「炎舞－「洞明」」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からモンスター１体を捨てて発動できる。\n自分のデッキ・墓地から「炎舞」魔法・罠カード１枚を選んで自分フィールドにセットする。\n②：相手がモンスターの効果を発動した時、「巧炎星－エランセイ」以外の自分フィールドの表側表示の、「炎星」カードまたは「炎舞」カード１枚を墓地へ送って発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"富炎星－ハクテンオウ",h:"ふえんせい－はくてんおう",e:"Brotherhood of the Fire Fist - Swan",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2200,tx:"獣戦士族モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドの「炎舞」魔法・罠カードの数×２００ダメージを相手に与える。\n②：自分・相手のバトルフェイズに、自分フィールドの表側表示の「炎舞」魔法・罠カード１枚を墓地へ送り、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"慧炎星－コサンジャク",h:"けいえんせい－こさんじゃく",e:"Brotherhood of the Fire Fist - Peacock",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「炎星」モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先に「炎星」モンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。\n②：このカードの攻撃宣言時に自分フィールドの表側表示の「炎舞」魔法・罠カード１枚を墓地へ送り、相手フィールドのモンスター１体を対象として発動できる。\nエンドフェイズまで、その相手モンスターをこのカードのリンク先に置いてコントロールを得る。\nこの効果でコントロールを得たモンスターは攻撃宣言できない。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"炎傑の梁山閣",h:"えんけつのりょうざんかく",e:"Fire Fortress atop Liang Peak",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「炎舞」カードとしても扱う。\n①：「炎星」モンスターが召喚・特殊召喚される度にこのカードに炎星カウンターを１つ置く。\n②：１ターンに１度、自分フィールドの炎星カウンターを以下の数だけ取り除き、その効果を発動できる。\n\n●２：このターン、自分の獣戦士族モンスターが攻撃する場合、相手はダメージステップ終了時までカードの効果を発動できない。\n\n●６：デッキから獣戦士族モンスター１体を手札に加える。\n\n●１０：デッキ・EXデッキから獣戦士族モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「洞明」",h:"えんぶ－「どうめい」",e:"Fire Formation - Domei",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、以下の効果を適用できる。\n●レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から獣戦士族の儀式モンスター１体を儀式召喚する。\n②：魔法＆罠ゾーンの表側表示のこのカードが墓地へ送られた場合、自分の墓地の「炎星」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎舞－「隠元」",h:"えんぶ－「いんげん」",e:"Fire Formation - Ingen",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、以下の効果を適用できる。\n●自分の手札・フィールドから、獣戦士族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：魔法＆罠ゾーンの表側表示のこのカードが墓地へ送られた場合、自分の墓地の「炎星」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"極炎舞－「辰斗」",h:"ごくえんぶ－「しんと」",e:"Ultimate Fire Formation - Sinto",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「炎星」モンスター及び「炎舞」魔法・罠カードが存在し、魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMミス・ディレクター",h:"えんためいとみす・でぃれくたー",e:"",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「オッドアイズ」モンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。\n②：このカードがモンスターゾーンに守備表示で存在する限り、自分の「オッドアイズ」モンスターは戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。\n③：自分の墓地のレベル１モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、そのモンスターとこのカードのみを素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スモーク・モスキート",h:"すもーく・もすきーと",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃で自分が戦闘ダメージを受けるダメージ計算時に発動できる。\nこのカードを手札から特殊召喚し、その戦闘で発生する自分への戦闘ダメージは半分になり、そのダメージステップ終了後にバトルフェイズを終了する。\n②：自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードのレベルはターン終了時までそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"DDイービル",h:"でぃーでぃーいーびる",e:"D/D Evil",t:"monster",a:"闇",l:4,ps:8,pe:"①：このカードがPゾーンに存在する限り１度だけ、相手がモンスターのP召喚に成功した時に発動できる。\nこのターン、そのP召喚されたモンスターは攻撃できず、効果は無効化される。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカード名の②のモンスター効果は１ターンに１度しか使用できない。\n①：自分フィールドに他の「DD」モンスターが存在しない場合、このカードは攻撃できない。\n②：相手メインフェイズに、相手フィールドのP召喚されたモンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・アトラクター",h:"でぃめんしょん・あとらくたー",e:"Dimension Shifter",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:2200,tx:"①：自分の墓地にカードが存在しない場合、このカードを手札から墓地へ送って発動できる。\n次のターンの終了時まで、墓地へ送られるカードは墓地へは行かず除外される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"原始生命態ニビル",h:"げんしせいめいたいにびる",e:"Nibiru, the Primal Being",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:3000,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：相手が５体以上のモンスターを召喚・特殊召喚したターンのメインフェイズに発動できる。\n自分・相手フィールドの表側表示モンスターを可能な限りリリースし、このカードを手札から特殊召喚する。\nその後、相手フィールドに「原始生命態トークン」（岩石族・光・星１１・攻／守？）１体を特殊召喚する。\nこのトークンの攻撃力・守備力は、この効果でリリースしたモンスターの元々の攻撃力・守備力をそれぞれ合計した数値になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"冥王結界波",h:"めいおうけっかいは",e:"Dark Ruler No More",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動に対してモンスターの効果は発動できない。\n①：相手フィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"バイナル・ブレーダー",h:"ばいなる・ぶれーだー",e:"Binary Blader",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"通常モンスター２体\n①：このカードと相互リンクしているモンスターの数によって以下の効果を得る。\n\n●１体以上：このカードが相手モンスターに攻撃する攻撃宣言時に発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃でき、このカードが相手モンスターと戦闘を行う場合、その相手モンスターはその戦闘では破壊されない。\n\n●２体：このカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを除外する。",li:"",lk:["右","左"],ta:[],al:[]},
  {n:"斬機シグマ",h:"ざんきしぐま",e:"Mathmech Sigma",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、EXモンスターゾーンに自分のモンスターが存在しない場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドのこのカードを「斬機」SモンスターのS素材とする場合、このカードをチューナー以外のモンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機ナブラ",h:"ざんきなぶら",e:"Mathmech Nabla",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族モンスター１体をリリースして発動できる。\nデッキから「斬機」モンスター１体を特殊召喚する。\n②：このカードが墓地へ送られた場合、EXモンスターゾーンの自分のサイバース族モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機アディオン",h:"ざんきあでぃおん",e:"Mathmech Addition",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターの攻撃力をターン終了時まで１０００アップする。\nこの効果で特殊召喚したターン、このカードは攻撃できない。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機サブトラ",h:"ざんきさぶとら",e:"Mathmech Subtraction",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターの攻撃力をターン終了時まで１０００ダウンする。\nこの効果で特殊召喚したターン、このカードは攻撃できない。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機マルチプライヤー",h:"ざんきまるちぷらいやー",e:"Mathmech Multiplication",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族・レベル４モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで８にする。\n②：このカードが墓地へ送られた場合、EXモンスターゾーンの自分のサイバース族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機ディヴィジョン",h:"ざんきでぃう゛ぃじょん",e:"Mathmech Division",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXモンスターゾーンの自分のサイバース族モンスター１体をリリースして発動できる。\n手札及び自分の墓地からそれぞれ１体まで、サイバース族・レベル４モンスターを選んで特殊召喚する。\n②：このカードが墓地へ送られた場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎斬機マグマ",h:"えんざんきまぐま",e:"Geomathmech Magma",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"サイバース族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2500,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが戦闘でモンスターを破壊した時、相手フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「斬機」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"炎斬機ファイナルシグマ",h:"えんざんきふぁいなるしぐま",e:"Geomathmech Final Sigma",t:"monster",a:"炎",l:12,ps:"",pe:"",tr:"サイバース族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはEXモンスターゾーンに存在する限り、「斬機」カード以外のカードの効果を受けない。\n②：EXモンスターゾーンのこのカードが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。\n③：このカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「斬機」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"塊斬機ラプラシアン",h:"かいざんきらぷらしあん",e:"Primathmech Laplacian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:0,tx:"レベル４モンスター×３\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがX召喚に成功した場合、このカードのX素材を３つまで取り除き、その数だけ以下の効果から選択して発動できる。\n\n●相手の手札をランダムに１枚選んで墓地へ送る。\n\n●相手フィールドのモンスター１体を選んで墓地へ送る。\n\n●相手フィールドの魔法・罠カード１枚を選んで墓地へ送る。\n\n②：自分フィールドの「斬機」カードが効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機方程式",h:"ざんきほうていしき",e:"Mathmech Equation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「斬機」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機刀ナユタ",h:"ざんきとうなゆた",e:"Mathmech Billionblade Nayuta",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"サイバース族モンスターにのみ装備可能。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：装備モンスターが相手モンスターと戦闘を行うダメージ計算時に、デッキから「斬機」モンスター１体を墓地へ送って発動できる。\n装備モンスターの攻撃力はターン終了時まで、墓地へ送ったモンスターの攻撃力分アップする。\n②：このカードが魔法＆罠ゾーンから墓地へ送られた場合、「斬機刀ナユタ」以外の自分の墓地の「斬機」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機超階乗",h:"ざんきちょうかいじょう",e:"Mathmech Superfactorial",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「斬機」モンスターを３体まで対象とし、以下の効果から１つを選択して発動できる（同名カードは１枚まで）。\n\n●そのモンスターを効果を無効にして特殊召喚し、そのモンスターのみを素材として「斬機」Sモンスター１体をS召喚する。\nその時のS素材モンスターは墓地へは行かず持ち主のデッキに戻る。\n\n●そのモンスターを効果を無効にして特殊召喚し、そのモンスターのみを素材として「斬機」Xモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機帰納法",h:"ざんききのうほう",e:"Mathmech Induction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族モンスターの攻撃力は５００アップする。\n②：自分フィールドに「斬機」モンスターが存在する場合、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・ナサリー",h:"どらごんめいど・なさりー",e:"Nurse Dragonmaid",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「ドラゴンメイド・ナサリー」以外の自分の墓地のレベル４以下の「ドラゴンメイド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分・相手のバトルフェイズ開始時に発動できる。\nこのカードを持ち主の手札に戻し、自分の手札・墓地からレベル７の「ドラゴンメイド」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・エルデ",h:"どらごんめいど・えるで",e:"Dragonmaid Ernus",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:1600,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\n手札からレベル４以下の「ドラゴンメイド」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドに融合モンスターが存在する限り、このカードは効果では破壊されない。\n③：自分・相手のバトルフェイズ終了時に発動できる。\nこのカードを持ち主の手札に戻し、手札からレベル２の「ドラゴンメイド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・ラドリー",h:"どらごんめいど・らどりー",e:"Laundry Dragonmaid",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分のデッキの上からカードを３枚墓地へ送る。\n②：自分・相手のバトルフェイズ開始時に発動できる。\nこのカードを持ち主の手札に戻し、自分の手札・墓地からレベル７の「ドラゴンメイド」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・フルス",h:"どらごんめいど・ふるす",e:"Dragonmaid Nudyarl",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:1600,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。\n②：自分フィールドに融合モンスターが存在する限り、このカードは効果では破壊されない。\n③：自分・相手のバトルフェイズ終了時に発動できる。\nこのカードを持ち主の手札に戻し、手札からレベル２の「ドラゴンメイド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・ティルル",h:"どらごんめいど・てぃるる",e:"Kitchen Dragonmaid",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ドラゴンメイド・ティルル」以外の「ドラゴンメイド」モンスター１体を手札に加える。\nその後、手札から「ドラゴンメイド」モンスター１体を選んで墓地へ送る。\n②：自分・相手のバトルフェイズ開始時に発動できる。\nこのカードを持ち主の手札に戻し、自分の手札・墓地からレベル８の「ドラゴンメイド」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・フランメ",h:"どらごんめいど・ふらんめ",e:"Dragonmaid Tinkhec",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分フィールドの「ドラゴンメイド」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで２０００アップする。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドに融合モンスターが存在する限り、このカードは効果では破壊されない。\n③：自分・相手のバトルフェイズ終了時に発動できる。\nこのカードを持ち主の手札に戻し、手札からレベル３の「ドラゴンメイド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・パルラ",h:"どらごんめいど・ぱるら",e:"Parlor Dragonmaid",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ドラゴンメイド・パルラ」以外の「ドラゴンメイド」カード１枚を墓地へ送る。\n②：自分・相手のバトルフェイズ開始時に発動できる。\nこのカードを持ち主の手札に戻し、自分の手札・墓地からレベル８の「ドラゴンメイド」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・ルフト",h:"どらごんめいど・るふと",e:"Dragonmaid Lorpar",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターはフィールドで発動する効果を発動できない。\n②：自分フィールドに融合モンスターが存在する限り、このカードは効果では破壊されない。\n③：自分・相手のバトルフェイズ終了時に発動できる。\nこのカードを持ち主の手札に戻し、手札からレベル３の「ドラゴンメイド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・ハスキー",h:"どらごんめいど・はすきー",e:"House Dragonmaid",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「ドラゴンメイド」モンスター＋ドラゴン族モンスター\n①：自分・相手のスタンバイフェイズに、このカード以外の自分フィールドの「ドラゴンメイド」モンスター１体を対象として発動できる。\nそのモンスターよりレベルが１つ高い、またはレベルが１つ低い「ドラゴンメイド」モンスター１体を自分の手札・墓地から選んで守備表示で特殊召喚する。\n②：このカード以外の自分フィールドの表側表示のドラゴン族モンスターが自分の手札に戻った時、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイドのお心づくし",h:"どらごんめいどのおこころづくし",e:"Dragonmaid Hospitality",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地から「ドラゴンメイド」モンスター１体を選んで守備表示で特殊召喚する。\nその後、特殊召喚したモンスターと同じ属性でレベルが異なる「ドラゴンメイド」モンスター１体をデッキから墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイドのお出迎え",h:"どらごんめいどのおでむかえ",e:"Dragonmaid Welcome",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターの攻撃力・守備力は、自分フィールドの「ドラゴンメイド」モンスターの数×１００アップする。\n②：自分フィールドに「ドラゴンメイド」モンスターが２体以上存在する場合、自分の墓地の「ドラゴンメイドのお出迎え」以外の「ドラゴンメイド」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n③：このカードが墓地へ送られた場合に発動する。\nこのターン、自分フィールドの「ドラゴンメイド」モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイドのお召し替え",h:"どらごんめいどのおめしかえ",e:"Dragonmaid Changeover",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードが墓地に存在する場合、自分フィールドの「ドラゴンメイド」モンスター１体を対象として発動できる。\nこのカードを手札に加え、そのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・リラクゼーション",h:"どらごんめいど・りらくぜーしょん",e:"Dragonmaid Downtime",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「ドラゴンメイド」モンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●対象のモンスターを持ち主の手札に戻し、デッキから「ドラゴンメイド・リラクゼーション」以外の「ドラゴンメイド」カード１枚を手札に加える。\n\n●対象のモンスターを持ち主の手札に戻し、相手フィールドの魔法・罠カード１枚を選んで持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"光の王 マルデル",h:"ひかりのじぇねれいど　まるでる",e:"Mardel, Generaider Boss of Light",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:2400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「光の王 マルデル」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n「光の王 マルデル」以外の、「ジェネレイド」カード１枚または植物族モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"剣の王 フローディ",h:"つるぎのじぇねれいど　ふろーでぃ",e:"Frodi, Generaider Boss of Swords",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「剣の王 フローディ」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドの、「ジェネレイド」モンスターまたは戦士族モンスターを任意の数だけリリースし、その数だけフィールドのモンスターを対象として発動できる。\nそのモンスターを破壊する。\nその後、破壊された相手フィールドのモンスターの数だけ相手はデッキからドローできる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄の王 ドヴェルグス",h:"くろがねのじぇねれいど　どう゛ぇるぐす",e:"Dovelgus, Generaider Boss of Iron",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:2500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「鉄の王 ドヴェルグス」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドの「ジェネレイド」モンスターまたは機械族モンスターを任意の数だけリリースして発動できる。\nリリースした数だけ、リリースしたモンスターとはカード名が異なる、「ジェネレイド」モンスターまたは機械族モンスターを手札から守備表示で特殊召喚する（同名カードは１枚まで）。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"炎の王 ナグルファー",h:"ほのおのじぇねれいど　なぐるふぁー",e:"Naglfar, Generaider Boss of Fire",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3100,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「炎の王 ナグルファー」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドのカードが戦闘・効果で破壊される場合、代わりに自分フィールドの「ジェネレイド」モンスターまたは獣戦士族モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷の王 ニードヘッグ",h:"こおりのじぇねれいど　にーどへっぐ",e:"Nidhogg, Generaider Boss of Ice",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2100,de:2600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「氷の王 ニードヘッグ」は自分フィールドに１体しか表側表示で存在できない。\n②：相手がモンスターを特殊召喚する際に、自分フィールドの「ジェネレイド」モンスターまたは幻竜族モンスター１体をリリースして発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"死の王 ヘル",h:"しのじぇねれいど　へる",e:"Hela, Generaider Boss of Doom",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:800,de:2800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「死の王 ヘル」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドの「ジェネレイド」モンスターまたはアンデット族モンスター１体をリリースし、そのモンスターとはカード名が異なる自分の墓地の、「ジェネレイド」モンスターまたはアンデット族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"永の王 オルムガンド",h:"とこしえのじぇねれいど　おるむがんど",e:"Jormungandr, Generaider Boss of Eternity",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"爬虫類族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル９モンスター×２体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「永の王 オルムガンド」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードの元々の攻撃力・守備力は、このカードのX素材の数×１０００になる。\n③：このカードのX素材を１つ取り除いて発動できる。\nお互いは、それぞれデッキから１枚ドローする。\nその後、ドローしたプレイヤーは自身の手札・フィールドのカードを１枚選び、このカードの下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"王の舞台",h:"じぇねれいど・すてーじ",e:"Generaider Boss Stage",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、相手がデッキからカードを手札に加えた場合に発動できる。\nデッキから「ジェネレイド」モンスター１体を守備表示で特殊召喚する。\n②：相手ターンに、自分が「ジェネレイド」モンスターの特殊召喚に成功した場合に発動できる。\n自分フィールドに「ジェネレイドトークン」（天使族・光・星４・攻／守１５００）を可能な限り攻撃表示で特殊召喚する。\nこのトークンはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"王の試練",h:"じぇねれいど・くえすと",e:"Generaider Boss Quest",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札の「ジェネレイド」モンスター１体を相手に見せ、デッキから「王の試練」以外の「ジェネレイド」魔法・罠カードを２枚まで手札に加える（同名カードは１枚まで）。\nその後、見せたカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"王の報酬",h:"じぇねれいど・りわーど",e:"Generaider Boss Loot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにトークンが存在する限り、相手は「ジェネレイド」効果モンスターを攻撃対象に選択できない。\n②：「ジェネレイド」効果モンスターが戦闘で破壊された場合に発動する。\n相手はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"王の襲来",h:"じぇねれいど・ばとる",e:"Generaider Boss Fight",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキ・墓地から「ジェネレイド」フィールド魔法カード１枚を選んで発動する。\nその後、相手はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"王の支配",h:"じぇねれいど・てりとりー",e:"Generaider Boss Room",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「ジェネレイド」カードの効果の発動にチェーンして相手が魔法・罠・モンスターの効果を発動した時、手札を１枚捨てて発動できる。\nその相手の効果は「お互いのプレイヤーは、それぞれデッキから１枚ドローする」となる。",li:"",lk:[],ta:[],al:[]},
  {n:"王の憤激",h:"じぇねれいど・ばいと",e:"Generaider Boss Bite",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ジェネレイド」モンスターを任意の数だけリリースし、自分フィールドのXモンスター１体を対象として発動できる。\nリリースした数だけ自分の手札・フィールド・墓地から、リリースしたモンスター以外の「ジェネレイド」モンスターを選び、対象のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"コード・エクスポーター",h:"こーど・えくすぽーたー",e:"",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:2300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族モンスターを「コード・トーカー」モンスターのリンク素材とする場合、手札のこのカードもリンク素材にできる。\n②：このカードが「コード・トーカー」モンスターのリンク素材として手札・フィールドから墓地へ送られた場合、自分の墓地のレベル４以下のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nフィールドのこのカードを素材とした場合には手札に加えず効果を無効にして特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルートエンフォーサー",h:"ぶるーとえんふぉーさー",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"効果モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨て、相手フィールドの表側表示のカード１枚を対象として発動できる。\n相手はその表側表示のカードと元々の種類（モンスター・魔法・罠）が同じカード１枚を手札から捨ててこの効果を無効にできる。\n捨てなかった場合、対象の表側表示のカードを破壊する。",li:"",lk:["右下","左下"],ta:[],al:[]},
  {n:"転生炎獣ブレイズ・ドラゴン",h:"さらまんぐれいとぶれいず・どらごん",e:"",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1200,tx:"レベル４モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く。\n②：このカードにX素材が無い場合、自分・相手のバトルフェイズに発動できる。\n「サラマングレイト」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\n③：このカードが「転生炎獣ブレイズ・ドラゴン」を素材としてX召喚に成功した場合に発動できる。\n相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ベイオネット・パニッシャー",h:"べいおねっと・ぱにっしゃー",e:"Bayonet Punisher",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のフィールド・墓地の「ヴァレル」モンスターの種類によって、以下の効果を適用する。\n自分フィールドに攻撃力３０００以上のモンスターが存在する場合、このカードの発動に対して相手は効果を発動できない。\n\n●融合：相手フィールドのモンスター１体を選んで除外する。\n\n●S：相手のEXデッキから裏側表示のカードをランダムに３枚除外する。\n\n●X：相手フィールドの魔法・罠カード１枚を選んで除外する。\n\n●リンク：相手の墓地からカードを３枚まで選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"トリックスター・フェス",h:"とりっくすたー・ふぇす",e:"Trickstar Festival",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「トリックスター」モンスターしか召喚・特殊召喚できない。\n①：自分フィールドに「トリックスタートークン」（天使族・光・星１・攻／守０）２体を特殊召喚する。\n②：EXデッキから特殊召喚された自分フィールドの「トリックスター」モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女パスカルス",h:"まりんせすぱすかるす",e:"Marincess Pascalus",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「海晶乙女パスカルス」以外の「マリンセス」モンスター１体を守備表示で特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「マリンセス」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼フィニッシュホールド",h:"ごうきふぃにっしゅほーるど",e:"Gouki Finishing Move",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「剛鬼」リンクモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのリンクマーカーの数×１０００アップし、このターンそのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\nこのカードの発動後、ターン終了時まで自分は「剛鬼」モンスターでしか攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイナレスラー・イグアノドラッカ",h:"だいなれすらー・いぐあのどらっか",e:"Dinowrestler Iguanodraka",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカード以外の恐竜族モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドの恐竜族モンスター１体をリリースし、そのモンスターとは元々のカード名が異なる自分の墓地の「ダイナレスラー」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・フェイルオーバー",h:"おるたーがいすと・ふぇいるおーばー",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドのカードが相手の効果で破壊された場合に発動できる。\n手札から「オルターガイスト」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「オルターガイスト」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・ジレルス",h:"てぃんだんぐる・じれるす",e:"Tindangle Jhrelth",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札にある場合、このカード以外の手札を１枚捨てて発動できる。\nデッキから「ティンダングル・ジレルス」以外の「ティンダングル」カード１枚を墓地へ送り、このカードを裏側守備表示で特殊召喚する。\n②：このカードがリバースした場合に発動できる。\nデッキから「ティンダングル・ジレルス」以外のリバースモンスター１体を選び、手札に加えるか墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"天威の龍拳聖",h:"てんいのりゅうけんせい",e:"Draco Masters of the Tenyi",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"幻竜族",mo:["リンク","効果"],ma:"",tp:"",at:2600,de:"-",tx:"リンクモンスターを含むモンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは効果モンスターとの戦闘では破壊されない。\n②：自分フィールドに他の効果モンスターが存在しない場合に発動できる。\n自分の墓地及び自分フィールドに表側表示で存在する、効果モンスター以外のモンスターの数まで、相手フィールドの効果モンスターを選んで破壊する。",li:"",lk:["右下","下","左下"],ta:[],al:[]},
  {n:"天元の荒鷲王",h:"てんげんのあらわしおう",e:"Kaiser Eagle, the Heavens' Mandate",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"鳥獣族の効果モンスター３体以上\nこのカードはリンク召喚でしか特殊召喚できない。\nEXリンク状態のこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。",li:"",lk:["左","右","左下","下","右下"],ta:[],al:[]},
  {n:"黒き森の航天閣",h:"くろきもりのこうてんかく",e:"Skyfaring Castle of the Black Forest",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"岩石族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"岩石族の効果モンスター３体以上\nこのカードはリンク召喚でしか特殊召喚できない。\nEXリンク状態のこのカードが直接攻撃で相手のLPを０にした場合、このカードのコントローラーはマッチに勝利する。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"激動の未界域",h:"げきどうのみかいいき",e:"Danger! Disturbance! Disorder!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の「未界域」モンスターが相手モンスターとの戦闘で破壊された時に発動できる。\nその相手モンスターを破壊する。\n②：自分の墓地から「未界域」魔法・罠カード３種類を１枚ずつ除外して発動できる。\nフィールドのカードを全て破壊する。\nこの効果の発動後、ターン終了時まで自分は「未界域」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・パーペチュア",h:"くろのだいばー・ぱーぺちゅあ",e:"Time Thief Perpetua",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:2500,tx:"レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のスタンバイフェイズにこのカードのX素材を１つ取り除き、「クロノダイバー・パーペチュア」以外の自分の墓地の「クロノダイバー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカード以外の自分フィールドのXモンスター１体を対象として発動できる。\nデッキから「クロノダイバー」カード１枚を選び、対象のモンスターの下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"塊斬機ダランベルシアン",h:"かいざんきだらんべるしあん",e:"Primathmech Alembertian",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:0,tx:"レベル４モンスター×２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがX召喚に成功した場合、このカードのX素材を以下の数だけ取り除き、その効果を発動できる。\n\n●２つ：デッキから「斬機」カード１枚を手札に加える。\n\n●３つ：デッキからレベル４モンスター１体を手札に加える。\n\n●４つ：デッキから魔法・罠カード１枚を手札に加える。\n\n②：自分フィールドのモンスター１体をリリースして発動できる。\n自分の手札・墓地からレベル４の「斬機」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴッドフェニックス・ギア・フリード",h:"ごっどふぇにっくす・ぎあ・ふりーど",e:"Immortal Phoenix Gearfried",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:2200,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のフィールド・墓地から装備魔法カード１枚を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが攻撃するダメージステップ開始時に発動できる。\nこのカード以外のフィールドの表側表示モンスター１体を選び、攻撃力５００アップの装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n③：モンスターの効果が発動した時、自分フィールドの表側表示の装備カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"昇華騎士－エクスパラディン",h:"しょうかきし－えくすぱらでぃん",e:"Sublimation Knight",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから戦士族・炎属性モンスターまたはデュアルモンスター１体を選び、攻撃力５００アップの装備カード扱いとしてこのカードに装備する。\n②：デュアルモンスターを装備したこのカードが相手によって破壊された場合に発動できる。\nその装備していたデュアルモンスターを墓地から可能な限り自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはもう１度召喚された状態として扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士－ローラン",h:"えんせいきし－ろーらん",e:"Infernoble Knight - Roland",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から攻撃力５００アップの装備カード扱いとしてその自分のモンスターに装備する。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「焔聖騎士－ローラン」以外の戦士族・炎属性モンスター１体または装備魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"武装鍛錬",h:"ぎあ・ぶりーど",e:"Gearbreed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分ドローフェイズに通常のドローを行う代わりに発動できる。\n自分のデッキ・墓地から装備魔法カード１枚を選んで手札に加える。\n②：自分フィールドに装備魔法カードが存在する場合、自分の墓地から戦士族・炎属性モンスターまたはデュアルモンスター１体をデッキの一番下に戻して発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラプテノスの超魔剣",h:"らぷてのすのちょうまけん",e:"Supermagic Sword of Raptinus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの表示形式によって以下の効果を適用する。\n\n●攻撃表示：装備モンスターは相手の効果の対象にならない。\n\n●守備表示：装備モンスターは戦闘では破壊されない。\n\n②：自分・相手のバトルフェイズ開始時に発動できる。\n装備モンスターの表示形式を変更し、モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアル・アブレーション",h:"でゅある・あぶれーしょん",e:"Gemini Ablation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札を１枚捨て、以下の効果から１つを選択して発動できる。\n\n●デッキからデュアルモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはもう１度召喚された状態として扱う。\n\n●自分フィールドのデュアルモンスター１体をリリースし、手札・デッキから戦士族・炎属性モンスター１体を特殊召喚する。\nもう１度召喚された状態のデュアルモンスターをリリースした場合、さらにフィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エヴォルテクター エヴェック",h:"えう゛ぉるてくたー　えう゛ぇっく",e:"Evocator Eveque",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが召喚・特殊召喚に成功した場合、「エヴォルテクター エヴェック」以外の自分の墓地の、戦士族・炎属性モンスターまたはデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"クロス・オーバー",h:"くろす・おーばー",e:"Cross Over",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示モンスター１体と自分フィールドの戦士族モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを装備カード扱いとしてその自分のモンスターに装備し、ターン終了時までその自分のモンスターの戦闘で発生する相手への戦闘ダメージは０になる。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"万物創世龍",h:"てんさうざんど・どらごん",e:"Ten Thousand Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n攻撃力の合計と守備力の合計が、合わせて１００００以上になるように自分フィールドのモンスターをリリースした場合のみ特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力・守備力は１００００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ピカリ＠イグニスター",h:"ぴかりあっといぐにすたー",e:"Pikari @Ignister",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「Ai」魔法・罠カード１枚を手札に加える。\n②：自分フィールドの「＠イグニスター」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで４にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルル＠イグニスター",h:"ぶるるあっといぐにすたー",e:"Bururu @Ignister",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイバース族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「＠イグニスター」モンスター１体を墓地へ送る。\n②：このカードがサイバース族SモンスターのS素材として墓地へ送られた場合、「ブルル＠イグニスター」以外の自分の墓地の、そのS召喚の素材としたモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドヨン＠イグニスター",h:"どよんあっといぐにすたー",e:"Doyon @Ignister",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:400,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地の「＠イグニスター」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードがサイバース族リンクモンスターのリンク素材として墓地へ送られた場合、自分の墓地の「Ai」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アチチ＠イグニスター",h:"あちちあっといぐにすたー",e:"Achichi @Ignister",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「アチチ＠イグニスター」以外のレベル４以下の「＠イグニスター」モンスター１体を手札に加える。\n②：自分のサイバース族モンスターが戦闘を行うダメージステップ開始時に、墓地のこのカードを除外して発動できる。\nその自分のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヒヤリ＠イグニスター",h:"ひやりあっといぐにすたー",e:"Hiyari @Ignister",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:300,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「＠イグニスター」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカード以外の自分フィールドのサイバース族モンスター１体をリリースして発動できる。\nデッキからレベル５以上の「＠イグニスター」モンスター１体を手札に加え、このカードのレベルをターン終了時まで４にする。\nこの効果を発動するためにリンクモンスターをリリースした場合、さらにデッキから「Aiの儀式」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドシン＠イグニスター",h:"どしんあっといぐにすたー",e:"Doshin @Ignister",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「＠イグニスター」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分の墓地のサイバース族リンクモンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻し、デッキから「Aiラブ融合」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ドンヨリボー＠イグニスター",h:"どんよりぼーあっといぐにすたー",e:"Donyoribo @Ignister",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:300,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「＠イグニスター」モンスターが攻撃されたダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。\n②：「＠イグニスター」モンスターまたは「Ai」魔法・罠カードの、相手にダメージを与える効果が発動した時、墓地のこのカードを除外して発動できる（ダメージステップでも発動可能）。\nその効果で相手に与えるダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の仲－孫謀",h:"せんかのちゅう－そんぼう",e:"Ancient Warriors - Masterful Sun Mou",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに他の「戦華」モンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。\n②：自分の手札・フィールドからカード１枚を墓地へ送って発動できる。\nデッキから「戦華の仲－孫謀」以外の「戦華」モンスター１体を手札に加える。\n③：このカード以外の自分の「戦華」モンスターの効果が発動した場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の美－周公",h:"せんかのび－しゅうこう",e:"Ancient Warriors - Graceful Zhou Gong",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの永続魔法・永続罠カード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキから「戦華」魔法・罠カード１枚を手札に加える。\n②：このカード以外の自分の「戦華」モンスターの効果が発動した場合、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の妙－魯敬",h:"せんかのみょう－ろけい",e:"Ancient Warriors - Eccentric Lu Jing",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの永続魔法・永続罠カード１枚を対象として発動できる。\nそのカードを墓地へ送り、そのカードとはカード名が異なる「戦華」魔法・罠カード１枚を自分の墓地から選んで手札に加える。\n②：このカード以外の自分の「戦華」モンスターの効果が発動した場合、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の徳－劉玄",h:"せんかのとく－りゅうげん",e:"Ancient Warriors - Virtuous Liu Xuan",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに他の「戦華」モンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。\n②：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、自分の手札・フィールドからカード１枚を墓地へ送って発動できる。\nデッキから「戦華の徳－劉玄」以外の「戦華」モンスター１体を特殊召喚する。\n③：このカード以外の自分の「戦華」モンスターが戦闘を行う攻撃宣言時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の義－関雲",h:"せんかのぎ－かんうん",e:"Ancient Warriors - Loyal Guan Yun",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:1800,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：相手フィールドにのみモンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの他の「戦華」モンスターは相手の効果の対象にならない。\n③：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の雄－張徳",h:"せんかのゆう－ちょうとく",e:"Ancient Warriors - Valiant Zhang De",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:900,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「戦華」モンスターが２体以上存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力は自分ターンの間、相手フィールドのモンスターの数×３００アップする。\n③：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合に発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ法師 九七六参",h:"からくりほうし　くなむざん",e:"Karakuri Bonze mdl 9763 \"Kunamzan\"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの「カラクリ」モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更し、このカードをチューナー扱いとして特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族・地属性モンスターしかEXデッキから特殊召喚できない。\n②：このカードは攻撃可能な場合には攻撃しなければならない。\n③：このカードが攻撃対象に選択された場合に発動する。\nこのカードの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ蝦蟇 四六弐四",h:"からくりがま　しろくにし",e:"Karakuri Gama mdl 4624 \"Shirokunishi\"",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:1000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは攻撃可能な場合には攻撃しなければならない。\n②：このカードが攻撃対象に選択された場合に発動する。\nこのカードの表示形式を変更する。\n③：墓地のこのカードを除外し、自分フィールドの「カラクリ」モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産トゥスパ・ロケット",h:"おーぱーつとぅすぱ・ろけっと",e:"Chronomaly Tuspa Rocket",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、デッキ・EXデッキから「先史遺産」モンスター１体を墓地へ送り、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、墓地へ送ったモンスターのレベルまたはランク×２００ダウンする。\n②：フィールドのこのカードを素材としてX召喚した「No.」モンスターは以下の効果を得る。\n●このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アークジェット・ライトクラフター",h:"あーくじぇっと・らいとくらふたー",e:"Arcjet Lightcraft",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地のレベル８以下の機械族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは９になり、効果は無効化される。\n③：このカードがモンスターゾーンに存在する限り、自分は機械族XモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・タイムレコーダー",h:"くろのだいばー・たいむれこーだー",e:"Time Thief Chronocorder",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手バトルフェイズにこのカードをリリースして発動できる。\nこのターンに１度だけ、相手モンスターの攻撃で発生する自分への戦闘ダメージは代わりに相手が受ける。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示のXモンスターが効果でフィールドから離れた場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"雙極の破械神",h:"そうきょくのはかいしん",e:"Abominable Unchained Soul",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:1500,tx:"自分は「雙極の破械神」を１ターンに１度しか特殊召喚できない。\n①：自分フィールドのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した場合、手札を１枚捨てて発動できる。\nフィールドのカード１枚を選んで破壊する。\n③：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀姫－ロゼ",h:"せんとうき－ろぜ",e:"Sky Striker Ace - Roze",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドに「閃刀姫－ロゼ」以外の「閃刀姫」モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在する状態で、EXモンスターゾーンの相手モンスターが、戦闘で破壊された場合、または自分のカードの効果でフィールドから離れた場合に発動できる。\nこのカードを特殊召喚する。\nその後、相手フィールドの表側表示モンスター１体を選び、ターン終了時までその効果を無効にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・ジェニー",h:"うぃっちくらふと・じぇにー",e:"Witchcrafter Genni",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードをリリースし、手札から魔法カード１枚を捨てて発動できる。\nデッキから「ウィッチクラフト・ジェニー」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\n②：自分の墓地からこのカードと「ウィッチクラフト」魔法カード１枚を除外して発動できる。\nこの効果は、その魔法カード発動時の効果と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"虚の王 ウートガルザ",h:"うつろのじぇねれいど　うーとがるざ",e:"Utgarda, Generaider Boss of Delusion",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2200,de:2700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「虚の王 ウートガルザ」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドの「ジェネレイド」モンスターまたは岩石族モンスターを合計２体リリースし、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリックの妖精",h:"ごーすとりっくのようせい",e:"Ghostrick Fairy",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:1000,tx:"自分フィールドに「ゴーストリック」モンスターが存在する場合のみ、このカードは表側表示で召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした時、自分の墓地の「ゴーストリック」カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nそのカードはフィールドから離れた場合に除外される。\nその後、自分フィールドにセットされているカードの数まで相手フィールドの表側表示モンスターを選んで裏側守備表示にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧蹄－天迦久御雷",h:"きこうてい－あめのかくのみかづち",e:"Gizmek Kaku, the Supreme Shining Sky Stag",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2750,de:2750,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXモンスターゾーンにモンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：EXモンスターゾーンの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n③：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nこのカードに装備された自分のモンスターカード１枚を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"楽天禍カルクラグラ",h:"らくてんかかるくらぐら",e:"Cataclysmic Crusted Calcifida",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の地属性モンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、デッキからモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャック・ア・ボーラン",h:"じゃっく・あ・ぼーらん",e:"Jack-o-Bolan",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からアンデット族モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：相手メインフェイズに、自分または相手の墓地のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nその後、表側表示のこのカードをエンドフェイズまで除外する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"イビルセラ・ルテア",h:"いびるせら・るてあ",e:"Ibicella Lutea",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"①：自分フィールドに他のモンスターが存在する限り、このカードは戦闘では破壊されない。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの表側表示の魔法・罠カードは効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"執愛のウヴァループ",h:"しゅうあいのうう゛ぁるーぷ",e:"Obsessive Uvualoop",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分のフィールド・墓地のSモンスター１体を対象として発動できる。\nそのモンスターを除外し、このカードを特殊召喚する。\n②：このカードが墓地に存在する場合、自分のフィールド・墓地のSモンスター１体を対象として発動できる。\nそのモンスターを除外し、このカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"達磨落師",h:"だるまおとし",e:"Daruma Dropper",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:700,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで０になる。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドのXモンスターがX素材を取り除いて効果を発動する場合、取り除くX素材１つの代わりに墓地のこのカードを除外できる。\nこの効果はこのカードが墓地へ送られたターンには使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トランシケーダ",h:"とらんしけーだ",e:"Transcicada",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドに「ヌケガラトークン」（昆虫族・地・星３・攻／守０）１体を特殊召喚する。\nこの効果で特殊召喚したトークンがモンスターゾーンに存在する限り、自分は昆虫族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"チューン・ナイト",h:"ちゅーん・ないと",e:"Squeaknight",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"戦士族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが特殊召喚に成功した場合に発動できる。\nこのターン、このカードをチューナーとして扱う。\nこの効果の発動後、ターン終了時まで自分は１回しかEXデッキからモンスターを特殊召喚できない。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"バトル・サバイバー",h:"ばとる・さばいばー",e:"Battle Survivor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分・相手のバトルフェイズ終了時に発動できる。\nこのバトルフェイズ中、このモンスターが表側表示で存在する間に自分の墓地へ送られたカードの中から、「バトル・サバイバー」以外のカード１枚を自分の墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"テイ・キューピット",h:"てい・きゅーぴっと",e:"Cupid Serve",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカード名の②の効果は１ターンに１度しかできない。\n①：このカードはモンスターゾーンに存在する限り、このカードのレベル以下のレベルを持つモンスター以外の全てのモンスターが発動した効果を受けない。\n②：自分の墓地のカードを３枚まで除外して発動できる。\nターン終了時まで、このカードのレベルを、除外した数だけ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォーターリヴァイアサン＠イグニスター",h:"うぉーたーりう゛ぁいあさんあっといぐにすたー",e:"Water Leviathan @Ignister",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"サイバース族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:2000,tx:"「Aiの儀式」により降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\n相手フィールドの攻撃力２３００以下のモンスターを全て持ち主の手札に戻す。\n②：相手フィールドの表側表示モンスター１体を対象として発動できる。\n自分の墓地のリンクモンスターを全てEXデッキに戻し、対象のモンスターの攻撃力を０にする。\n③：このカードが相手モンスターと戦闘を行うダメージ計算時に１度、発動できる。\nその相手モンスターの攻撃力はそのダメージ計算時のみ半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・オフィエル",h:"めがりす・おふぃえる",e:"Megalith Ophiel",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1600,de:2500,tx:"「メガリス」カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\nデッキから「メガリス・オフィエル」以外の「メガリス」モンスター１体を手札に加える。\n②：自分メインフェイズに発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、フィールドのこのカードを含む自分の手札・フィールドのモンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・ハギト",h:"めがりす・はぎと",e:"Megalith Hagith",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1300,de:2600,tx:"「メガリス」カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\nデッキから「メガリス」魔法・罠カード１枚を手札に加える。\n②：自分メインフェイズに発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、フィールドのこのカードを含む自分の手札・フィールドのモンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・オク",h:"めがりす・おく",e:"Megalith Och",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1000,de:2700,tx:"「メガリス」カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\nその後、手札を１枚選んで捨てる。\n②：自分・相手のメインフェイズに発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、フィールドのこのカードを含む自分の手札・フィールドのモンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・ファレグ",h:"めがりす・ふぁれぐ",e:"Megalith Phaleg",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1200,tx:"「メガリス」カードにより降臨。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「メガリス」儀式モンスター１体を儀式召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの攻撃力・守備力は、自分の墓地の儀式モンスターの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・ベトール",h:"めがりす・べとーる",e:"Megalith Bethor",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1500,de:2600,tx:"「メガリス」カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「メガリス」儀式モンスター１体を儀式召喚する。\n②：このカードが儀式召喚に成功した場合、自分の墓地の儀式モンスターの種類の数まで相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・アラトロン",h:"めがりす・あらとろん",e:"Megalith Aratron",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:2000,de:3000,tx:"「メガリス」カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードを手札から捨てて発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「メガリス」儀式モンスター１体を儀式召喚する。\n②：自分フィールドのカードを対象とする相手の効果が発動した時に発動できる。\n自分の墓地から儀式モンスター１体を選んでデッキの一番下に戻し、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アースゴーレム＠イグニスター",h:"あーすごーれむあっといぐにすたー",e:"Earth Golem @Ignister",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"サイバース族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:2000,tx:"サイバース族モンスター＋リンクモンスター\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功したターン、自分が受ける全てのダメージは０になる。\n②：EXデッキから特殊召喚されたモンスターをこのカードが攻撃するダメージステップの間、このカードの攻撃力は元々の攻撃力分アップする。\n③：このカードが戦闘で破壊された時、「アースゴーレム＠イグニスター」以外の自分の墓地のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィンドペガサス＠イグニスター",h:"うぃんどぺがさすあっといぐにすたー",e:"Wind Pegasus @Ignister",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"サイバース族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分フィールドの「＠イグニスター」モンスターの数まで、相手フィールドの魔法・罠カードを選んで破壊する。\n②：このカードがフィールド・墓地に存在する状態で、このカード以外の自分フィールドのカードが戦闘または相手の効果で破壊された場合、このカードを除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ大権現 無零武",h:"からくりだいごんげん　ぶれいぶ",e:"Karakuri Super Shogun mdl 00N \"Bureibu\"",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:1500,tx:"チューナー＋チューナー以外の機械族モンスター１体以上\n①：このカードがS召喚に成功した場合に発動できる。\nデッキから「カラクリ」モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分の守備表示モンスターは戦闘では破壊されない。\n③：１ターンに１度、自分フィールドの表側表示の「カラクリ」モンスターの表示形式が変更された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトドラゴン＠イグニスター",h:"らいとどらごんあっといぐにすたー",e:"Light Dragon @Ignister",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1500,tx:"レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの「＠イグニスター」モンスターの数まで、相手フィールドの表側表示モンスターを選んで破壊する。\n②：このカード以外の自分のサイバース族モンスターが相手に戦闘ダメージを与えた時に発動できる。\n自分の墓地からリンクモンスター１体を選んで特殊召喚する。\n③：自分フィールドのモンスターが効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークナイト＠イグニスター",h:"だーくないとあっといぐにすたー",e:"Dark Templar @Ignister",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"カード名が異なるモンスター３体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのリンク先にモンスターが特殊召喚された場合に発動できる（ダメージステップでも発動可能）。\n自分の墓地からレベル４以下の「＠イグニスター」モンスターを可能な限りこのカードのリンク先となる自分フィールドに効果を無効にして特殊召喚する。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n自分の墓地からサイバース族モンスター１体を選んで特殊召喚する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"ファイアフェニックス＠イグニスター",h:"ふぁいあふぇにっくすあっといぐにすたー",e:"Fire Phoenix @Ignister",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"サイバース族モンスター２体以上\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが攻撃するダメージ計算時に発動できる。\nこのカードの攻撃力分のダメージを相手に与え、その戦闘で発生する相手への戦闘ダメージは０になる。\n②：このカードが効果で破壊された場合に発動できる。\n相手フィールドのモンスター１体を選んで破壊する。\n③：リンク召喚したこのカードが破壊され墓地へ送られた場合、次のスタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:["左","右","下"],ta:[],al:[]},
  {n:"クロシープ",h:"くろしーぷ",e:"Cross-Sheep",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:700,de:"-",tx:"カード名が異なるモンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのリンク先にモンスターが特殊召喚された場合に発動できる。\nこのカードのリンク先のモンスターの種類によって以下の効果を適用する。\n\n●儀式：自分はデッキから２枚ドローし、その後手札を２枚選んで捨てる。\n\n●融合：自分の墓地からレベル４以下のモンスター１体を選んで特殊召喚する。\n\n●S：自分フィールドの全てのモンスターの攻撃力は７００アップする。\n\n●X：相手フィールドの全てのモンスターの攻撃力は７００ダウンする。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"崔嵬の地霊使いアウス",h:"さいかいのちれいつかいあうす",e:"Aussa the Earth Charmer, Immovable",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1850,de:"-",tx:"地属性モンスターを含むモンスター２体\nこのカード名はルール上「憑依装着」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の地属性モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから守備力１５００以下の地属性モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"グラビティ・コントローラー",h:"ぐらびてぃ・こんとろーらー",e:"Gravity Controller",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイキック族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"リンクモンスター以外のEXモンスターゾーンのモンスター１体\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：EXモンスターゾーンのこのカードは、メインモンスターゾーンのモンスターとの戦闘では破壊されない。\n②：このカードがEXモンスターゾーンの相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターとこのカードを持ち主のデッキに戻す。",li:"",lk:["左下"],ta:[],al:[]},
  {n:"イグニスターAiランド",h:"いぐにすたーあいらんど",e:"Ignister A.I.Land",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のメインモンスターゾーンにモンスターが存在しない場合にこの効果を発動できる。\n手札からレベル４以下の「＠イグニスター」モンスター１体を特殊召喚する。\nこのターン自分は、元々の属性が同じモンスターを「イグニスターAiランド」の効果で特殊召喚できず、サイバース族モンスターしか特殊召喚できない。\n②：このカードが墓地に存在する場合、自分の墓地から「＠イグニスター」モンスター１体を除外して発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"Ai打ち",h:"あいうち",e:"TA.I. Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分と相手のモンスター同士が戦闘を行うダメージ計算時に発動できる。\nその自分のモンスターの攻撃力はそのダメージ計算時のみ、その相手モンスターの攻撃力と同じになり、そのダメージステップ終了時にその戦闘で破壊されたモンスターのコントローラーはその元々の攻撃力分のダメージを受ける。\n②：自分の「＠イグニスター」モンスターが戦闘で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Aiドリング・ボーン",h:"あいどりんぐ・ぼーん",e:"A.I.dle Reborn",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「＠イグニスター」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はサイバース族モンスターしか特殊召喚できない。\n②：モンスター同士が戦闘を行う攻撃宣言時、墓地のこのカードと手札を１枚除外し、自分の墓地のカード及び除外されている自分のカードの中から、「Aiドリング・ボーン」以外の「Ai」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"Aiラブ融合",h:"あいらぶゆうごう",e:"A.I. Love Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、サイバース族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n自分の「＠イグニスター」モンスターを融合素材とする場合、相手フィールドのリンクモンスターも１体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"Aiの儀式",h:"あいのぎしき",e:"A.I.'s Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"サイバース族の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドの「＠イグニスター」モンスターをリリースし、手札からサイバース族の儀式モンスター１体を儀式召喚する。\n発動時に自分フィールドに「＠イグニスター」モンスターが存在する場合、自分の墓地の「＠イグニスター」モンスターもリリースの代わりに除外する事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華史略－三顧礼迎",h:"せんかしりゃく－さんこれいげい",e:"Ancient Warriors Saga - Three Visits",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは発動後、２回目の自分スタンバイフェイズに墓地へ送られる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに、自分が「戦華」モンスターの召喚・特殊召喚に成功した場合、そのモンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なる「戦華」モンスター１体をデッキから手札に加える。\n②：このカードが魔法＆罠ゾーンから墓地へ送られた場合に発動できる。\n手札から「戦華」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華史略－孫劉同盟",h:"せんかしりゃく－そんりゅうどうめい",e:"Ancient Warriors Saga - Sun-Liu Alliance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「戦華」モンスターの属性が２種類以上の場合、属性を１つ宣言して発動できる。\n宣言した属性を持つ相手フィールドの全てのモンスターはターン終了時まで効果を発動できない。\n②：相手がモンスターの特殊召喚に成功した場合、または自分が「戦華」モンスターの効果を発動した場合に発動できる。\n自分フィールドの全ての「戦華」モンスターの攻撃力はターン終了時まで、自分フィールドの「戦華」モンスターの数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・ポータル",h:"めがりす・ぽーたる",e:"Megalith Portal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：儀式召喚したモンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n②：「メガリス」モンスターが特殊召喚された場合、自分の墓地の儀式モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"カラクリ蝦蟇油",h:"からくりがまあぶら",e:"Karakuri Gama Oil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「カラクリ」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\n②：１ターンに１度、自分フィールドの表側表示の「カラクリ」モンスターの表示形式が変更された場合に発動する。\n装備モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コンドーレンス・パペット",h:"こんどーれんす・ぱぺっと",e:"Condolence Puppet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚された相手フィールドのモンスターの数＋１枚まで、デッキから「ギミック・パペット」モンスターを墓地へ送る（同名カードは１枚まで）。\n②：墓地のこのカードを除外し、自分フィールドの機械族Xモンスター１体を対象として発動できる。\nそのモンスターはフィールドに表側表示で存在する限り、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"顕現する紋章",h:"ちゃーじ・めだりおん",e:"Charged-Up Heraldry",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスター１体をリリースして発動できる。\nデッキから「紋章獣」モンスター２体を守備表示で特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はサイキック族モンスター及び機械族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・スタートアップ",h:"くろのだいばー・すたーとあっぷ",e:"Time Thief Startup",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札から「クロノダイバー」モンスター１体を特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの「クロノダイバー」Xモンスター１体を対象として発動できる。\n自分の墓地から、「クロノダイバー」カード３種類（モンスター・魔法・罠）をそれぞれ１枚ずつ選んで、対象のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀術式－シザーズクロス",h:"せんとうじゅつしき－しざーずくろす",e:"Sky Striker Maneuver - Scissors Cross",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、自分の墓地のレベル４の「閃刀姫」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n自分の墓地に魔法カードが３枚以上存在する場合、手札に加えず特殊召喚する事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"逢華妖麗譚－魔妖語",h:"おうかようれいたん－まやかしがたり",e:"Ghost Meets Girl - A Mayakashi's Manuscript",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに、自分フィールドのアンデット族Sモンスター１体を対象として発動できる。\n自分の墓地のモンスター及び除外されている自分のモンスターの中から、対象のモンスターと同じ属性のアンデット族モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。\nこのカードの発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイドのお見送り",h:"どらごんめいどのおみおくり",e:"Dragonmaid Send-Off",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ドラゴンメイド」モンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なる「ドラゴンメイド」モンスター１体を手札から守備表示で特殊召喚し、対象のモンスターを持ち主の手札に戻す。\nこの効果で特殊召喚したモンスターは次のターンの終了時まで、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"使い捨て学習装置",h:"つかいすてらーにんぐましん",e:"Disposable Learner Device",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力は、自分の墓地のモンスターの数×２００アップする。\n②：このカードがフィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"九字切りの呪符",h:"くじきりのじゅふ",e:"Kuji-Kiri Curse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、レベル９モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトニング・ストーム",h:"らいとにんぐ・すとーむ",e:"Lightning Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに表側表示のカードが存在しない場合、以下の効果から１つを選択して発動できる。\n●相手フィールドの攻撃表示モンスターを全て破壊する。\n●相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"脆刃の剣",h:"もろはのつるぎ",e:"Double-Edged Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：「脆刃の剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターの攻撃力は２０００アップし、装備モンスターの戦闘で発生する戦闘ダメージはお互いのプレイヤーが受ける。\n③：自分が２０００以上の戦闘ダメージを受けた場合に発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"Aiシャドー",h:"あいしゃどー",e:"A.I. Shadow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n自分フィールドの「＠イグニスター」モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、対象のモンスターの攻撃力は８００アップし、攻撃可能な相手モンスターは対象のモンスターに攻撃しなければならない。\n②：魔法＆罠ゾーンの表側表示のこのカードが相手の効果でフィールドから離れ、墓地へ送られた場合または除外された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華史略－長坂之雄",h:"せんかしりゃく－ちょうはんのゆう",e:"Ancient Warriors Saga - Defense of Changban",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の「戦華」モンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：相手のバトルフェイズ開始時に、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\nこのターン、相手は「戦華」モンスターを攻撃対象に選択できない。\n③：相手モンスターの攻撃宣言時に、墓地のこのカードを除外して発動できる。\nデッキから「戦華」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・プロモーション",h:"めがりす・ぷろもーしょん",e:"Megalith Promotion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで元々のレベルの倍にする。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・エマージョン",h:"めがりす・えまーじょん",e:"Megalith Emergence",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の「メガリス」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"借カラクリ旅籠蔵",h:"かりからくりはたごくら",e:"Karakuri Cash Inn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「カラクリ」モンスター１体と相手フィールドの効果モンスター１体を対象として発動できる。\nその自分のモンスターの表示形式を変更し、その相手モンスターの効果をターン終了時まで無効にする。\n②：自分フィールドに「カラクリ」モンスターが存在する場合、墓地のこのカードを除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"リサーガム・エクシーズ",h:"りさーがむ・えくしーず",e:"Resurgam Xyz",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドのXモンスターの攻撃力は８００アップする。\n②：１ターンに１度、手札から魔法カード１枚を捨て、自分フィールドのXモンスター１体を対象として発動できる。\nその自分のモンスターと同じ種族でランクが１つ高い「CNo.」モンスターまたは「CX」モンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\n「RUM」魔法カード以外を捨てて発動した場合、この効果で特殊召喚したモンスターはエンドフェイズに持ち主のEXデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・レトログラード",h:"くろのだいばー・れとろぐらーど",e:"Time Thief Retrograde",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「クロノダイバー」Xモンスターが存在し、魔法・罠カードが発動した時に発動できる。\nその発動を無効にし、そのカードを自分フィールドの「クロノダイバー」Xモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"キャッチ・コピー",h:"きゃっち・こぴー",e:"Sales Pitch",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手の効果によって、相手がドロー以外の方法でデッキからカードを手札に加えた場合に発動できる。\n自分はデッキからカード１枚を選び、お互いに確認して手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果の発動ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"アームズ・コール",h:"あーむず・こーる",e:"Armory Call",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから装備魔法カード１枚を手札に加える。\nその後、そのカードを装備可能な自分フィールドのモンスター１体に装備できる。",li:"",lk:[],ta:[],al:[]},
  {n:"相視相殺",h:"そうしそうさい",e:"Mutually Affured Destruction",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、お互いのプレイヤーは手札を公開し続ける。\nこの効果で手札を公開し続けている間、お互いの手札に同名カードが存在する場合、お互いにそのカード及びその同名カードの効果の発動ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻魔の肖像",h:"でびるず・ぽーとれいと",e:"Fiendish Portrait",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの同名モンスター１体を自分のデッキ・EXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに持ち主のデッキに戻る。\nこのカードの発動後、ターン終了時まで自分はデッキ・EXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヘッド・ジャッジング",h:"へっど・じゃっじんぐ",e:"Head Judging",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分または相手がフィールドのモンスターの効果を発動した時に発動できる。\n発動したそのプレイヤーはコイントスを１回行い、裏表を当てる。\n当たった場合、このカードは墓地へ送られる。\nハズレの場合、その発動を無効にし、そのモンスターのコントロールをそのプレイヤーから見て相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスターエクスプレス",h:"もんすたーえくすぷれす",e:"Monster Express",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\n元々の種族がその表側表示モンスターと同じモンスター１体をEXデッキから墓地へ送る。\nこのターン、自分はこの効果で墓地へ送ったモンスター及びそのモンスターと元々の種族が同じモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"泥岩の霊長－マンドストロング",h:"でいがんのれいちょう－まんどすとろんぐ",e:"Primineral Mandstrong",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：セットされているこのカードが相手の効果で破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを特殊召喚する。\nその後、自分の墓地から「泥岩の霊長－マンドストロング」以外のモンスター１体を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"砂漠の飛蝗賊",h:"でざーと・ろーかすと",e:"Desert Locusts",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1500,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動する。\nターンプレイヤーは自身の手札を１枚選んで捨てる。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"タイラント・プランテーション",h:"たいらんと・ぷらんてーしょん",e:"Tyrant Farm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの効果モンスター１体をリリースして発動できる。\n元々の種族・属性がそのモンスターと同じとなる、効果モンスター以外のモンスター１体を自分の墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"獣烈な争い",h:"しれつなあらそい",e:"Brutal Beast Battle",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：お互いのプレイヤーは自身のフィールドに同じ種類（儀式・融合・S・X・リンク）のモンスターが２体以上存在する場合には、その種類のモンスターが１体になるように墓地へ送らなければならない。\nその後、墓地へ送ったプレイヤーは自身のフィールドのモンスターの種類（儀式・融合・S・X・リンク）の数だけデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の聖獣－パンタス",h:"ゆめまきょうのせいじゅう－ぱんたす",e:"Phantasos, the Dream Mirror Friend",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合、「夢魔鏡の聖獣－パンタス」以外の自分の墓地のレベル８以下の「夢魔鏡」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：フィールドゾーンに「闇黒の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の魔獣－パンタス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の魔獣－パンタス",h:"ゆめまきょうのまじゅう－ぱんたす",e:"Phantasos, the Dream Mirror Foe",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\nこのターン、このカードは直接攻撃できる。\n②：フィールドゾーンに「聖光の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の聖獣－パンタス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の天魔－ネイロス",h:"ゆめまきょうのてんま－ねいろす",e:"Oneiros, the Dream Mirror Erlking",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"属性が異なる「夢魔鏡」モンスター×２\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードの属性は「闇」としても扱う。\n②：このカード以外の自分フィールドのモンスターがリリースされた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：このカードが相手によって破壊された場合に発動できる。\n自分の墓地から「夢魔鏡の天魔－ネイロス」以外の「夢魔鏡」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢幻の夢魔鏡",h:"ゆめまぼろしのゆめまきょう",e:"Dream Mirror Phantasms",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「夢魔鏡」モンスター１体を手札に加える事ができる。\n②：フィールドゾーンに「聖光の夢魔鏡」が存在する限り、自分フィールドの全てのモンスターの攻撃力・守備力は５００アップする。\n③：フィールドゾーンに「闇黒の夢魔鏡」が存在する限り、相手フィールドの全てのモンスターの攻撃力・守備力は５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の夢魔鏡",h:"こんとんのゆめまきょう",e:"Dream Mirror of Chaos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：「夢魔鏡」融合モンスターカードによって決められた融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nフィールドゾーンに「聖光の夢魔鏡」が存在する場合、手札のモンスターも融合素材とする事ができる。\nフィールドゾーンに「闇黒の夢魔鏡」が存在する場合、自分の墓地のモンスターを除外して融合素材とする事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"夢現の夢魔鏡",h:"ゆめうつつのゆめまきょう",e:"Dream Mirror Hypnagogia",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：「聖光の夢魔鏡」「闇黒の夢魔鏡」をそれぞれ１枚ずつ手札・デッキから選び、その内の１枚を自分のフィールドゾーンに、もう１枚を相手のフィールドゾーンにそれぞれ表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の夢占い",h:"ゆめまきょうのゆめうらない",e:"Dream Mirror Oneiromancy",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果を発動できる。\n\n●フィールドゾーンに「聖光の夢魔鏡」が存在し、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●フィールドゾーンに「闇黒の夢魔鏡」が存在し、相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワルキューレ・シグルーン",h:"わるきゅーれ・しぐるーん",e:"Valkyrie Sigrun",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを墓地へ送り、このカードを特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・墓地からレベル８以下の「ワルキューレ」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"リングリボー",h:"りんぐりぼー",e:"Linguriboh",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:300,de:"-",tx:"レベル４以下のサイバース族モンスター１体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手が罠カードを発動した時、このカードをリリースして発動できる。\nその効果を無効にし除外する。\n②：このカードが墓地に存在する場合、EXデッキから特殊召喚された自分フィールドの「＠イグニスター」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:["左下"],ta:[],al:[]},
  {n:"超魔導師－ブラック・マジシャンズ",h:"ちょうまどうし－ぶらっく・まじしゃんず",e:"The Dark Magicians",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2300,tx:"「ブラック・マジシャン」または「ブラック・マジシャン・ガール」＋魔法使い族モンスター\n①：１ターンに１度、魔法・罠カードの効果が発動した場合に発動できる。\n自分はデッキから１枚ドローする。\nそのドローしたカードが魔法・罠カードだった場合、自分フィールドにセットできる。\n速攻魔法・罠カードをセットした場合、そのカードはセットしたターンでも発動できる。\n②：このカードが破壊された場合に発動できる。\n「ブラック・マジシャン」「ブラック・マジシャン・ガール」を１体ずつ自分の手札・デッキ・墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・ソウルズ",h:"まじしゃんず・そうるず",e:"Magicians' Souls",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札にある場合、デッキからレベル６以上の魔法使い族モンスター１体を墓地へ送り、以下の効果から１つを選択して発動できる。\n●このカードを特殊召喚する。\n●このカードを墓地へ送る。\nその後、自分の墓地から「ブラック・マジシャン」または「ブラック・マジシャン・ガール」１体を選んで特殊召喚できる。\n②：自分の手札・フィールドから魔法・罠カードを２枚まで墓地へ送って発動できる。\n墓地へ送った数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魂のしもべ",h:"たましいのしもべ",e:"Soul Servant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・デッキ・墓地から、「魂のしもべ」以外の「ブラック・マジシャン」のカード名または「ブラック・マジシャン・ガール」のカード名が記されたカード、「ブラック・マジシャン」の内、いずれか１枚を選んでデッキの一番上に置く。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nお互いのフィールド・墓地の、「守護神官」モンスター、「ブラック・マジシャン」、「ブラック・マジシャン・ガール」の種類の数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"黒魔術の秘儀",h:"くろまじゅつのひぎ",e:"Secrets of Dark Magic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n●融合モンスターカードによって決められた、「ブラック・マジシャン」または「ブラック・マジシャン・ガール」を含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n●レベルの合計が儀式召喚するモンスターのレベル以上になるように、「ブラック・マジシャン」または「ブラック・マジシャン・ガール」を含む自分の手札・フィールドのモンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・コンビネーション",h:"まじしゃんず・こんびねーしょん",e:"Magicians' Combination",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、魔法・罠・モンスターの効果が発動した時、自分フィールドの、「ブラック・マジシャン」または「ブラック・マジシャン・ガール」１体をリリースして発動できる。\nリリースしたモンスターとはカード名が異なる「ブラック・マジシャン」または「ブラック・マジシャン・ガール」１体を、自分の手札・墓地から選んで特殊召喚し、その発動した効果を無効にする。\n②：魔法＆罠ゾーンの表側表示のこのカードが墓地へ送られた場合に発動できる。\nフィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO サンライザー",h:"えれめんたるひーろー　さんらいざー",e:"Elemental HERO Sunrise",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1200,tx:"属性が異なる「HERO」モンスター×２\nこのカードは融合召喚でしか特殊召喚できない。\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ミラクル・フュージョン」１枚を手札に加える。\n②：自分フィールドのモンスターの攻撃力は、自分フィールドのモンスターの属性の種類×２００アップする。\n③：このカード以外の自分の「HERO」モンスターが戦闘を行う攻撃宣言時に、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO リキッドマン",h:"えれめんたるひーろー　りきっどまん",e:"Elemental HERO Liquid Soldier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚に成功した時、「E・HERO リキッドマン」以外の自分の墓地のレベル４以下の「HERO」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが「HERO」融合モンスターの融合召喚の素材になり、墓地へ送られた場合または除外された場合に発動できる。\n自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジェネレーション・ネクスト",h:"じぇねれーしょん・ねくすと",e:"Generation Next",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のLPが相手より少ない場合に発動できる。\nお互いのLPの差の数値以下の攻撃力を持つ、「E・HERO」モンスター、「クリボー」モンスター、「N」モンスターの内、いずれか１体を自分のデッキ・墓地から選び、手札に加えるか特殊召喚する。\nこのターン、自分はそのカード及びその同名カードの効果の発動ができない。",li:"",lk:[],ta:[],al:[]},
  {n:"フェイバリット・ヒーロー",h:"ふぇいばりっと・ひーろー",e:"Favorite Hero",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル５以上の「HERO」モンスターにのみ装備可能。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のフィールドゾーンにカードが存在する場合、装備モンスターは、攻撃力が元々の守備力分アップし、相手の効果の対象にならない。\n②：自分・相手のバトルフェイズ開始時に発動できる。\n自分の手札・デッキからフィールド魔法カード１枚を選んで発動する。\n③：装備モンスターの攻撃で相手モンスターを破壊した時、このカードを墓地へ送って発動できる。\nその攻撃モンスターはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マジスタリー・アルケミスト",h:"まじすたりー・あるけみすと",e:"Magistery Alchemist",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地及び自分フィールドの表側表示モンスターの中から、「HERO」モンスター４体を除外し、自分の墓地の「HERO」モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。\n「地」「水」「炎」「風」の全ての属性を除外して発動した場合には、この効果で特殊召喚したモンスターの元々の攻撃力は倍になり、相手フィールドの全ての表側表示のカードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"サテライト・ウォリアー",h:"さてらいと・うぉりあー",e:"Satellite Warrior",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のSモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、自分の墓地のSモンスターの数まで相手フィールドのカードを対象として発動できる。\nそのカードを破壊し、このカードの攻撃力は破壊した数×１０００アップする。\n②：S召喚したこのカードが破壊された場合に発動できる。\n自分の墓地からレベル８以下の「ウォリアー」、「シンクロン」、「スターダスト」Sモンスターを３体まで選んで特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンク・コンバーター",h:"じゃんく・こんばーたー",e:"Junk Converter",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードとチューナー１体を捨てて発動できる。\nデッキから「シンクロン」モンスター１体を手札に加える。\n②：このカードがS素材として墓地へ送られた場合、自分の墓地のチューナー１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サテライト・シンクロン",h:"さてらいと・しんくろん",e:"Satellite Synchron",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地からモンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：元々のカード名に「ウォリアー」、「シンクロン」、「スターダスト」の内、いずれかを含むSモンスターが自分のフィールド・墓地に存在する場合に発動できる。\nこのカードのレベルはターン終了時まで４になる。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・チェイス",h:"しんくろ・ちぇいす",e:"Synchro Chase",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分が「ウォリアー」、「シンクロン」、「スターダスト」SモンスターのS召喚に成功した場合、そのS召喚の素材とした自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが魔法＆罠ゾーンに存在する限り、元々のカード名に「ウォリアー」、「シンクロン」、「スターダスト」の内、いずれかを含む自分のSモンスターの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"くず鉄のシグナル",h:"くずてつのしぐなる",e:"Scrap-Iron Signal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：Sモンスターを素材として必要とするSモンスターが自分フィールドに存在し、相手がモンスターの効果を発動した時に発動できる。\nその発動を無効にする。\n発動後このカードは墓地へ送らず、そのままセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガガガガマジシャン",h:"ががががまじしゃん",e:"Gagagaga Magician",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル４モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、「ガガガガマジシャン」以外の自分の墓地のXモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\n②：このカードを素材として持っている「未来皇ホープ」Xモンスターは以下の効果を得る。\n●このカードのX素材を２つ取り除き、自分フィールドのXモンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターの攻撃力は４０００になり、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ズバババンチョー－GC",h:"ずばばばんちょー－がががこーと",e:"Zubababancho Gagagacoat",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在し、自分フィールドに「ズバババンチョー－GC」以外の、「ズババ」モンスターまたは「ガガガ」モンスターが存在する場合に発動できる。\nこのカードを特殊召喚する。\n②：自分の墓地の、「ゴゴゴ」モンスターまたは「ドドド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドドドワーフ－GG",h:"どどどどわーふ－ごごごぐろーぶ",e:"Dodododwarf Gogogoglove",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「ズババ」モンスターまたは「ガガガ」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドに「ドドドドワーフ－GG」以外の、「ゴゴゴ」モンスターまたは「ドドド」モンスターが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"オノマト選択",h:"おのまとぴっく",e:"Onomatopickup",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「オノマト選択」以外の「オノマト」カード１枚を手札に加える事ができる。\n②：自分フィールドの「ズババ」、「ガガガ」、「ゴゴゴ」、「ドドド」モンスターの内、いずれか１体を対象として発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで対象のモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"フューチャー・ドライブ",h:"ふゅーちゃー・どらいぶ",e:"Future Drive",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「未来皇ホープ」Xモンスター１体を対象として発動できる。\nこのターン、その自分のモンスターに以下の効果を適用する。\n\n●そのモンスターは相手モンスター全てに１回ずつ攻撃できる。\n\n●そのモンスターが相手モンスターと戦闘を行うダメージステップの間、その相手モンスターの効果は無効化される。\n\n●そのモンスターが戦闘で相手モンスターを破壊する度に、その破壊されたモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"EM天空の魔術師",h:"えんためいとてんくうのまじゅつし",e:"Performapal Celestial Magician",t:"monster",a:"闇",l:4,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚された自分フィールドの融合・S・Xモンスター１体のみが戦闘または相手の効果で破壊された時に発動できる。\nその破壊されたモンスターを特殊召喚する。\nその後、このカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに発動できる。\nこのカード以外の自分フィールドのモンスターの種類によって、このターン、以下の効果を適用する。\n\n●融合：このカードは直接攻撃できる。\n\n●S：相手はモンスターの効果を発動できない。\n\n●X：このカードの攻撃力は元々の攻撃力の倍になる。\n\n●P：エンドフェイズにデッキからPモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オッドアイズ・ウィザード・ドラゴン",h:"おっどあいず・うぃざーど・どらごん",e:"Odd-Eyes Wizard Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"①：このカードが手札にある場合、自分フィールドの闇属性モンスター１体をリリースして発動できる。\n手札・デッキ及び自分フィールドの表側表示モンスターの中から、「オッドアイズ・ドラゴン」１体を選んで墓地へ送り、このカードを特殊召喚する。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「オッドアイズ・ウィザード・ドラゴン」以外の「オッドアイズ」モンスター１体を選んで特殊召喚する。\nその後、デッキから「螺旋のストライクバースト」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMポップアップ",h:"えんためいとぽっぷあっぷ",e:"Performapal Popperup",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を３枚まで墓地へ送って発動できる。\n自分はその数だけデッキからドローする。\nその後、この効果でドローした数まで、自分のPゾーンのカード２枚のPスケールでP召喚可能なレベルを持つ、「EM」モンスター、「魔術師」Pモンスター、「オッドアイズ」モンスターを手札から特殊召喚できる（同名カードは１枚まで）。\nこのカードの効果で特殊召喚しなかった場合、自分は自分の手札の数×１０００LPを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"スマイル・アクション",h:"すまいる・あくしょん",e:"Smile Action",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、お互いのプレイヤーは、それぞれ自分の墓地から魔法カードを５枚まで選んで裏側表示で除外できる。\n②：モンスターの攻撃宣言時に発動する。\n攻撃されたプレイヤーは以下の効果を適用できる。\n\n●このカードの効果で除外した自分のカードの中からランダムに１枚選び、手札に加える。\nその後、そのカードを捨ててその攻撃を無効にできる。\n捨てなかった場合、このターン、自分が受ける戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・ディメンション",h:"ぺんでゅらむ・でぃめんしょん",e:"Pendulum Dimension",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが既に魔法＆罠ゾーンに存在する状態で、自分がPモンスターを素材として以下のモンスターの特殊召喚に成功した場合に発動できる。\nこのターン、自分の「ペンデュラム・ディメンション」の効果で同じ効果を適用できない。\n\n●融合：デッキから元々のレベルがその融合モンスターと同じモンスター１体を効果を無効にして守備表示で特殊召喚する。\n\n●S：デッキから「融合」１枚を手札に加える。\n\n●X：デッキからそのXモンスターのランクの数値以下のレベルを持つチューナー１体を手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"L・G・D",h:"りんく・ごっど・どらごん",e:"Five-Headed Link Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:5000,de:"-",tx:"モンスター５体\nこのカードはリンク召喚でしか特殊召喚できない。\n①：このカードが、闇・地・水・炎・風属性の全てを素材としてリンク召喚に成功した場合に発動できる。\n相手フィールドのカードを全て破壊する。\n②：フィールドのこのカードは他のカードの効果を受けず、闇・地・水・炎・風属性モンスターとの戦闘では破壊されない。\n③：相手エンドフェイズに発動する。\n自分の墓地からカード５枚を選んで裏側表示で除外する。\n５枚除外できない場合、このカードは墓地へ送られる。",li:"",lk:["左","右","左下","下","右下"],ta:[],al:[]},
  {n:"破壊剣士の守護絆竜",h:"はかいけんしのしゅごばんりゅう",e:"Protector Whelp of the Destruction Swordsman",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:400,de:"-",tx:"モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「破壊剣」カード１枚を墓地へ送る。\nその後、手札から「バスター・ブレイダー」モンスター１体を特殊召喚できる。\n②：相手フィールドにモンスターが存在しない自分バトルフェイズ終了時に、このターン攻撃宣言をしていない自分フィールドの「バスター・ブレイダー」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分のダメージを相手に与える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"ユニオン・キャリアー",h:"ゆにおん・きゃりあー",e:"Union Carrier",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"種族または属性が同じモンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\n元々の種族または元々の属性が対象のモンスターと同じモンスター１体を手札・デッキから選び、攻撃力１０００アップの装備カード扱いとして対象のモンスターに装備する。\nこの効果でデッキから装備した場合、ターン終了時まで自分はその装備したモンスターカード及びその同名モンスターを特殊召喚できない。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"古代の機械弩士",h:"あんてぃーく・ぎあ・ばりすてぃっくしゅーたー",e:"Ancient Gear Ballista",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"機械族・地属性モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「アンティーク・ギア」モンスター１体または「歯車街」１枚を手札に加える。\n②：自分フィールドの魔法・罠カード１枚と、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のカードを破壊し、その相手モンスターの攻撃力・守備力をターン終了時まで０にする。",li:"",lk:["右","左下"],ta:[],al:[]},
  {n:"虚光の宣告者",h:"みらーじゅ・でくれあらー",e:"Herald of Mirage Lights",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:600,de:"-",tx:"トークン以外の同じ種族・属性のモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：魔法・罠カードの効果が発動した時、手札から天使族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\n②：このカードが相手によって墓地へ送られた場合に発動できる。\n自分の墓地の儀式モンスター及び儀式魔法カードの中から、合計２枚まで選んで手札に加える（同名カードは１枚まで）。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"王神鳥シムルグ",h:"おうしんちょうしむるぐ",e:"Simorgh, Bird of Sovereignty",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"鳥獣族モンスターを含むモンスター２体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\nこのカードはリンク素材にできない。\n①：このカード及びこのカードのリンク先の鳥獣族モンスターは相手の効果の対象にならない。\n②：このカードが戦闘で破壊される場合、代わりに自分フィールドの「シムルグ」カード１枚を破壊できる。\n③：自分・相手のエンドフェイズに発動できる。\n使用していない自分・相手の魔法＆罠ゾーンの数以下のレベルを持つ、鳥獣族モンスター１体を手札・デッキから特殊召喚する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"X・HERO ヘル・デバイサー",h:"えくすとらひーろー　へる・でばいさー",e:"Xtra HERO Infernal Devicer",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1700,de:"-",tx:"「HERO」モンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nEXデッキの「HERO」融合モンスター１体を相手に見せ、そのモンスターにカード名が記されている融合素材モンスターを２体までデッキから手札に加える（同名カードは１枚まで）。\nこの効果を発動するターン、自分は「HERO」モンスターしか特殊召喚できない。\n②：このカードのリンク先の悪魔族モンスターの攻撃力・守備力はそのレベル×１００アップする。",li:"",lk:["左下","下"],ta:[],al:[]},
  {n:"神聖魔皇后セレーネ",h:"しんせいまこうごうせれーね",e:"Selene, Queen of the Master Magicians",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1850,de:"-",tx:"魔法使い族モンスターを含むモンスター２体以上\n①：このカードがリンク召喚に成功した場合に発動する。\nお互いのフィールド・墓地の魔法カードの数だけこのカードに魔力カウンターを置く。\n②：フィールドに「エンディミオン」カードが存在する限り、相手はこのカードを攻撃対象に選択できない。\n③：１ターンに１度、自分・相手のメインフェイズに、自分フィールドの魔力カウンターを３つ取り除いて発動できる。\n自分の手札・墓地から魔法使い族モンスター１体を選び、このカードのリンク先となる自分フィールドに守備表示で特殊召喚する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"クロスローズ・ドラゴン",h:"くろすろーず・どらごん",e:"Crossrose Dragon",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"種族が異なるモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードと自分フィールドの植物族モンスター１体をリリースして発動できる。\nEXデッキから「ローズ」Sモンスターまたは植物族Sモンスター１体をS召喚扱いで特殊召喚する。\n②：このカードが墓地に存在する状態で、自分フィールドのモンスターが効果で破壊された場合、墓地のこのカードを除外して発動できる。\n自分の墓地から「ローズ・ドラゴン」モンスター１体を選んで特殊召喚する。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"レプティレス・エキドゥーナ",h:"れぷてぃれす・えきどぅーな",e:"Reptilianne Echidna",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リンク","効果"],ma:"",tp:"",at:200,de:"-",tx:"爬虫類族モンスターを含むモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にする。\n②：自分メインフェイズに発動できる。\n相手フィールドの攻撃力０のモンスターの数まで、デッキから爬虫類族モンスターを手札に加える（同名カードは１枚まで）。\nこの効果の発動後、ターン終了時まで自分は爬虫類族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:["左","右下"],ta:[],al:[]},
  {n:"幻獣機アウローラドン",h:"げんじゅうきあうろーらどん",e:"Mecha Phantom Beast Auroradon",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:2100,de:"-",tx:"機械族モンスター２体以上\n①：このカードがリンク召喚に成功した場合に発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）３体を特殊召喚する。\nこのターン、自分はリンク召喚できない。\n②：１ターンに１度、自分フィールドのモンスターを３体までリリースして発動できる。\nリリースしたモンスターの数によって以下の効果を適用する。\n●１体：フィールドのカード１枚を選んで破壊する。\n●２体：デッキから「幻獣機」モンスター１体を特殊召喚する。\n●３体：自分の墓地から罠カード１枚を選んで手札に加える。",li:"",lk:["左","下","右下"],ta:[],al:[]},
  {n:"武神姫－アハシマ",h:"ぶじんき－あはしま",e:"Bujinki Ahashima",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"同じレベルのモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\nこのカードはリンク素材にできない。\n①：このカードがリンク召喚に成功した場合に発動できる。\n同じレベルのモンスターを、手札及び自分の墓地からそれぞれ１体ずつ選んで効果を無効にして特殊召喚し、その２体のみを素材としてXモンスター１体をX召喚する。\n②：このカードのリンク先のXモンスターがX素材を取り除いて効果を発動した場合、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:["上","右下"],ta:[],al:[]},
  {n:"アーティファクト－ダグザ",h:"あーてぃふぁくと－だぐざ",e:"Artifact Dagda",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"カード名が異なるモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外のフィールドのカードの効果が発動した時に発動できる。\n手札・デッキから「アーティファクト」モンスター１体を選んで、魔法カード扱いとして自分の魔法＆罠ゾーンにセットする。\nこの効果でセットしたカードは次の相手エンドフェイズに破壊される。\n②：リンク召喚したこのカードが相手ターンに破壊された場合に発動できる。\n自分の墓地から「アーティファクト」モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"幻奏の華楽聖ブルーム・ハーモニスト",h:"げんそうのはながくせいぶるーむ・はーもにすと",e:"Bloom Harmonist the Melodious Composer",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"天使族モンスター２体\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、手札を１枚捨てて発動できる。\nデッキからレベルが異なる「幻奏」モンスター２体をこのカードのリンク先となる自分フィールドに守備表示で特殊召喚する。\nこの効果を発動するターン、自分は「幻奏」モンスターしか特殊召喚できない。\n②：このカードのリンク先の「幻奏」モンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"捕食植物ヴェルテ・アナコンダ",h:"ぷれでたー・ぷらんつう゛ぇるて・あなこんだ",e:"Predaplant Verte Anaconda",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:500,de:"-",tx:"効果モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで闇属性になる。\n②：２０００LPを払い、「融合」通常・速攻魔法カードまたは「フュージョン」通常・速攻魔法カード１枚をデッキから墓地へ送って発動できる。\nこの効果は、その魔法カード発動時の効果と同じになる。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。",li:1,lk:["左下","右下"],ta:[],al:[]},
  {n:"魔界劇団－ハイパー・ディレクター",h:"まかいげきだん－はいぱー・でぃれくたー",e:"Abyss Actor - Hyper Director",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"「魔界劇団」Pモンスター１体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分のPゾーンのカード１枚を対象として発動できる。\nそのカードを特殊召喚する。\nその後、デッキのモンスター及び自分のEXデッキの表側表示のPモンスターの中から、特殊召喚したモンスターとはカード名が異なる「魔界劇団」Pモンスター１体を選び、自分のPゾーンに置く。\nこの効果の発動後、ターン終了時まで自分は「魔界劇団」モンスターしか召喚・特殊召喚できない。",li:"",lk:["下"],ta:[],al:[]},
  {n:"星鍵士リイヴ",h:"せいけんしりいう゛",e:"Lib the World Key Blademaster",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"モンスター２体\nこのカードは自分の墓地に「星遺物」カードが存在する場合のみリンク召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキから「星遺物」魔法・罠カード１枚を選んで自分フィールドにセットする。\nこのターン、自分の墓地に「星遺物」モンスターが存在しない場合には、そのカードは発動できない。\n②：リンク召喚したこのカードがリンク素材として墓地へ送られた場合に発動できる。\nフィールドのカード１枚を選んで持ち主のデッキに戻す。",li:"",lk:["左","左下"],ta:[],al:[]},
  {n:"閃刀姫－ジーク",h:"せんとうき－じーく",e:"Sky Striker Ace - Zeke",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"「閃刀姫」モンスターを含むモンスター２体\nこのカードはリンク召喚でしか特殊召喚できず、自分は「閃刀姫－ジーク」を１ターンに１度しか特殊召喚できない。\n①：このカードがリンク召喚に成功した場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを次の相手エンドフェイズまで除外する。\n②：１ターンに１度、このカード以外の自分フィールドのカード１枚を対象として発動できる。\nこのカードの攻撃力は１０００アップする。\nさらに、対象のカードは墓地へ送られる。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"零氷の魔妖－雪女",h:"れいひょうのまやかし－ゆきおんな",e:"Yuki-Onna, the Absolute Zero Mayakashi",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"アンデット族",mo:["リンク","効果"],ma:"",tp:"",at:2900,de:"-",tx:"アンデット族モンスター２体以上\nこのカード名の③の効果は１ターンに２度まで使用できる。\n①：「零氷の魔妖－雪女」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、除外されている状態で発動した相手モンスターの効果は無効化される。\n③：墓地からモンスターが特殊召喚された場合、または墓地のモンスターの効果が発動した場合、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、その効果を無効にする。",li:"",lk:["左","右","左下","右下"],ta:[],al:[]},
  {n:"天威龍－サハスラーラ",h:"てんいりゅう－さはすらーら",e:"Tenyi Spirit - Sahasrara",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"幻竜族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"幻竜族モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに効果モンスター以外の表側表示モンスターが存在する場合、相手はフィールドの効果モンスターを攻撃対象にできず、効果の対象にもできない。\n②：相手フィールドの効果モンスター１体を対象として発動できる。\n自分フィールドに「天威龍トークン」（幻竜族・光・星４・攻？／守０）１体を特殊召喚する。\nこのトークンの攻撃力は、対象のモンスターの元々の攻撃力と同じになる。\nこの効果は相手ターンでも発動できる。",li:"",lk:["上","右上","下","右下"],ta:[],al:[]},
  {n:"始原竜プライマル・ドラゴン",h:"しげんりゅうぷらいまる・どらごん",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの戦闘で発生する相手への戦闘ダメージは０になる。\n②：自分の墓地からドラゴン族モンスター１体を除外して発動できる。\nこのカードの攻撃力・守備力は次のターンの終了時まで、除外したモンスターの攻撃力分アップする。\n③：このカードがリリースされた場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超魔導竜騎士－ドラグーン・オブ・レッドアイズ",h:"ちょうまどうりゅうきし－どらぐーん・おぶ・れっどあいず",e:"Red-Eyes Dark Dragoon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「ブラック・マジシャン」＋「真紅眼の黒竜」またはドラゴン族の効果モンスター\n①：このカードは効果の対象にならず、効果では破壊されない。\n②：自分メインフェイズに発動できる。\n相手フィールドのモンスター１体を選んで破壊し、その元々の攻撃力分のダメージを相手に与える。\nこの効果は１ターン中に、このカードの融合素材とした通常モンスターの数まで使用できる。\n③：１ターンに１度、魔法・罠・モンスターの効果が発動した時、手札を１枚捨てて発動できる。\nその発動を無効にして破壊し、このカードの攻撃力を１０００アップする。",li:0,lk:[],ta:[],al:[]},
  {n:"深淵の青眼龍",h:"でぃーぷ・おぶ・ぶるーあいず",e:"Blue-Eyes Abyss Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できず、自分フィールドまたは自分の墓地に「青眼の白龍」が存在する場合にしか発動できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから儀式魔法カードまたは「融合」１枚を手札に加える。\n②：自分エンドフェイズに発動できる。\nデッキからレベル８以上のドラゴン族モンスター１体を手札に加える。\n③：墓地のこのカードを除外して発動できる。\n自分フィールドの全てのレベル８以上のドラゴン族モンスターの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"E・HERO ネオス・クルーガー",h:"えれめんたるひーろー　ねおす・くるーがー",e:"",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「ユベル」\nこのカードは融合召喚でしか特殊召喚できない。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその相手モンスターの攻撃力分のダメージを相手に与える。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\n手札・デッキから「ネオス・ワイズマン」１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"究極宝玉獣 レインボー・ドラゴン",h:"きゅうきょくほうぎょくじゅう　れいんぼー・どらごん",e:"",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「宝玉獣」モンスターが戦闘を行う攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。\n②：表側表示のこのカードがモンスターゾーンで破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く事ができる。\n③：永続魔法カード扱いのこのカードを除外して発動できる。\nデッキからレベル４以下の「宝玉獣」モンスター１体を効果を無効にして特殊召喚し、デッキから「究極宝玉神」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"シューティング・スター・ドラゴン・TG－EX",h:"しゅーてぃんぐ・すたー・どらごん・てっくじーなす－えくすぱんしょん",e:"Shooting Star Dragon T.G. EX",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:2500,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターを対象とするモンスターの効果が発動した時、自分の墓地からチューナー１体を除外して発動できる。\nその発動を無効にし破壊する。\n②：相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にする。\n③：相手ターンに、このカードが墓地に存在する場合、自分フィールドのSモンスター２体をリリースして発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スカーレッド・スーパーノヴァ・ドラゴン",h:"すかーれっど・すーぱーのう゛ぁ・どらごん",e:"Red Supernova Dragon",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:3000,tx:"チューナー３体＋チューナー以外のSモンスター１体以上\nこのカードはS召喚でのみEXデッキから特殊召喚できる。\n①：このカードの攻撃力は自分の墓地のチューナーの数×５００アップする。\n②：フィールドのこのカードは相手の効果では破壊されない。\n③：１ターンに１度、相手モンスターの効果が発動した時、または相手モンスターの攻撃宣言時に発動できる。\nこのカード及び相手フィールドのカードを全て除外する。\n④：このカードの③の効果で除外された場合、次の自分エンドフェイズに発動する。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"FNo.0 未来龍皇ホープ",h:"ふゅーちゃーなんばーず０ みらいりゅうおうほーぷ",e:"Number F0: Utopic Draco Future",t:"monster",a:"光",l:"",ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「No.」モンスター以外の同じランクのXモンスター×３\nルール上、このカードのランクは１として扱い、このカード名は「未来皇ホープ」カードとしても扱う。\nこのカードは自分フィールドの「FNo.0 未来皇ホープ」の上に重ねてX召喚する事もできる。\n①：このカードは戦闘・効果では破壊されない。\n②：１ターンに１度、相手がモンスターの効果を発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にする。\nこの効果でフィールドのモンスターの効果の発動を無効にした場合、さらにそのコントロールを得る。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河眼の残光竜",h:"ぎゃらくしーあいず・あふたーぐろう・どらごん",e:"Galaxy-Eyes Afterglow Dragon",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「ギャラクシーアイズ」モンスターが存在する場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：X素材のこのカードがXモンスターの効果を発動するために取り除かれた場合に発動できる。\n手札・デッキから「銀河眼の光子竜」１体を選び、特殊召喚するか、自分フィールドのXモンスターの下に重ねてX素材とする。\nこの効果をバトルフェイズに発動した場合、さらに自分フィールドの全ての「No.」Xモンスターの攻撃力は倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"覇王黒竜オッドアイズ・リベリオン・ドラゴン－オーバーロード",h:"はおうこくりゅうおっどあいず・りべりおん・どらごん－おーばーろーど",e:"",t:"monster",a:"闇",l:7,ps:4,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\nPゾーンのこのカードを特殊召喚し、「リベリオン」モンスターまたは「幻影騎士団」モンスター１体をこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nその後、自分のPゾーンのカード１枚を選んでそのモンスターの下に重ねてX素材にできる。",tr:"ドラゴン族",mo:["エクシーズ","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル７モンスター×２\n自分は「覇王黒竜オッドアイズ・リベリオン・ドラゴン－オーバーロード」を１ターンに１度しか特殊召喚できない。\nこのカードは自分フィールドの「リベリオン」Xモンスターの上に重ねてX召喚する事もでき、レベル７がP召喚可能な場合にEXデッキの表側表示のこのカードはP召喚できる。\n①：ランク７のXモンスターを素材としてX召喚したこのカードは１度のバトルフェイズ中に３回攻撃できる。\n②：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD死謳王バイス・レクイエム",h:"でぃーでぃーでぃーしおうおうばいす・れくいえむ",e:"",t:"monster",a:"闇",l:8,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの「契約書」カードを任意の数だけ対象として発動できる。\nそのカードを破壊し、Pゾーンのこのカードを特殊召喚する。\nその後、このカードのレベルを、破壊した数だけ上げる、または下げる事ができる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:2000,tx:"このカード名の②のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分は「DDD」モンスターしか特殊召喚できない。\n②：フィールドのこのカードを素材として融合・S・X・リンク召喚した「DDD」モンスターは以下の効果を得る。\n●１ターンに１度、フィールドのカード１枚を対象として発動できる。\n自分のフィールド・墓地から「契約書」カード１枚を選んで持ち主のデッキに戻し、対象のカードを破壊する。\nその後、自分は１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"デコード・トーカー・ヒートソウル",h:"でこーど・とーかー・ひーとそうる",e:"",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"属性が異なるサイバース族モンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク先のモンスターの数×５００アップする。\n②：自分・相手ターンに１０００LPを払って発動できる。\n自分はデッキから１枚ドローする。\nその後、自分のLPが２０００以下の場合、以下の効果を適用できる。\n●フィールドのこのカードを除外し、EXデッキから「デコード・トーカー・ヒートソウル」以外のリンク３以下のサイバース族モンスター１体を特殊召喚する。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"ヴァレルエンド・ドラゴン",h:"う゛ぁれるえんど・どらごん",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:3500,de:"-",tx:"効果モンスター３体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードは戦闘・効果では破壊されず、モンスターの効果の対象にならない。\n②：このカードは相手モンスター全てに１回ずつ攻撃できる。\n③：フィールドの効果モンスター１体と自分の墓地の「ヴァレット」モンスター１体を対象として発動できる。\n対象のフィールドのモンスターの効果を無効にし、対象の墓地のモンスターを特殊召喚する。\nこの効果の発動に対して相手はカードの効果を発動できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:["上","左","右","左下","右下"],ta:[],al:[]},
  {n:"聖なる影 ケイウス",h:"かどしゃどーる　けいうす",e:"Qadshaddoll Keios",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:100,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合に発動できる。\n手札から「シャドール」モンスター１体を表側守備表示または裏側守備表示で特殊召喚する。\n②：このカードが効果で墓地へ送られた場合に発動できる。\n手札から「シャドール」モンスター１体を墓地へ送る。\nこのターン中、以下の効果を適用する。\n●自分フィールドのモンスターの攻撃力・守備力はこの効果で墓地へ送ったモンスターの元々のレベル×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"影霊の翼 ウェンディ",h:"りーしゃどーる　うぇんでぃ",e:"Reeshaddoll Wendi",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「影霊の翼 ウェンディ」以外の「シャドール」モンスター１体を表側守備表示または裏側守備表示で特殊召喚する。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキから「影霊の翼 ウェンディ」以外の「シャドール」モンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"影依の巫女 エリアル",h:"のぇるしゃどーる　えりある",e:"Naelshaddoll Ariel",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイキック族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合、除外されている自分の「シャドール」モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示または裏側守備表示で特殊召喚する。\n②：このカードが効果で墓地へ送られた場合、お互いの墓地のカードを合計３枚まで対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"影光の聖選士",h:"れーしゃどーる・いんかーねーしょん",e:"Resh Shaddoll Incarnation",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地の「シャドール」モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示または裏側守備表示で特殊召喚する。\n②：自分の墓地からこのカードと「シャドール」カード１枚を除外し、以下の効果から１つを選択して発動できる。\n●自分フィールドの裏側表示モンスター１体を選んで表側守備表示にする。\n●自分フィールドの表側表示モンスター１体を選んで裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"エルシャドール・アプカローネ",h:"えるしゃどーる・あぷかろーね",e:"El Shaddoll Apkallone",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"属性が異なる「シャドール」モンスター×２\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果を無効にする。\n②：このカードは戦闘では破壊されない。\n③：このカードが墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「シャドール」カード１枚を選んで手札に加える。\nその後、手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"風霊媒師ウィン",h:"ふうれいばいしうぃん",e:"Wynn the Wind Channeler",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"このカード名はルール上「霊使い」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードと風属性モンスター１体を捨てて発動できる。\nデッキから「風霊媒師ウィン」以外の守備力１５００以下の風属性モンスター１体を手札に加える。\nこの効果の発動後、ターン終了時まで自分は風属性以外のモンスターの効果を発動できない。\n②：このカードが手札に存在する場合、自分の風属性モンスターが戦闘で破壊された時に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・メモリーガント",h:"おるたーがいすと・めもりーがんと",e:"Altergeist Memorygant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"「オルターガイスト」モンスター２体以上\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：お互いのバトルフェイズに、自分フィールドの他のモンスター１体をリリースして発動できる。\nそのモンスターの攻撃力分このカードの攻撃力をアップする。\n②：このカードが戦闘でモンスターを破壊した時に発動できる。\n相手フィールドのモンスター１体を選んで破壊する。\n破壊した場合、このカードはもう１度だけ続けて攻撃できる。\n③：このカードが破壊される場合、代わりに自分の墓地のモンスター１体を除外できる。",li:"",lk:["右","右下","左下","左"],ta:[],al:[]},
  {n:"オルターガイスト・プークエリ",h:"おるたーがいすと・ぷーくえり",e:"Altergeist Pookuery",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカード名の、①の効果は１ターンに１度しか使用できず、②の効果はデュエル中に１度しか使用できない。\n①：自分フィールドの「オルターガイスト」モンスターを「オルターガイスト」モンスターのリンク素材とする場合、手札のこのカードもリンク素材にできる。\n②：このカードが墓地に存在し、「オルターガイスト」リンクモンスターが自分フィールドにリンク召喚された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"オルターガイスト・フィジアラート",h:"おるたーがいすと・ふぃじあらーと",e:"Altergeist Fijialert",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在し、「オルターガイスト」リンクモンスターが自分フィールドにリンク召喚された時、そのモンスター以外のフィールドのリンクモンスター１体を対象として発動できる。\nこのカードを対象のモンスターのリンク先となる自分フィールドに特殊召喚する。\nこのターン、対象のモンスターは「オルターガイスト」モンスターとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンダングル・ドールス",h:"てぃんだんぐる・どーるす",e:"Tindangle Dholes",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・デッキから墓地へ送られた場合、「ティンダングル・ドールス」以外の自分の墓地の「ティンダングル」モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。\n②：このカードがリバースした場合に発動できる。\nデッキから魔法・罠カード１枚を墓地へ送る。\n③：このカードをリンク素材とした「ティンダングル」リンクモンスターは１度のバトルフェイズ中に３回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ローズ・プリンセス",h:"ろーず・ぷりんせす",e:"Rose Princess",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードはチューナーとして扱う。\n②：このカードを手札から捨てて発動できる。\nデッキから「白薔薇の回廊」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"変容王 ヘル・ゲル",h:"へんようおう　へる・げる",e:"Morph King Stygi-Gel",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nこのカードのレベルをそのモンスターと同じにし、自分はそのモンスターのレベル×２００LPを回復する。\nこの効果の発動後、ターン終了時まで自分はSモンスターしかEXデッキから特殊召喚できない。\n②：自分メインフェイズに発動できる。\n手札からこのカードより低いレベルを持つ悪魔族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ライトハンド・シャーク",h:"らいとはんど・しゃーく",e:"Right-Hand Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「レフトハンド・シャーク」１体を手札に加える。\n②：このカードが墓地に存在し、自分フィールドにモンスターが存在しない場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n③：フィールドのこのカードを含む水属性モンスターのみを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"レフトハンド・シャーク",h:"れふとはんど・しゃーく",e:"Left-Hand Shark",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドに「ライトハンド・シャーク」が存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが墓地からの特殊召喚に成功した場合に発動する。\nこのカードのレベルは４になる。\n③：フィールドのこのカードを含む水属性モンスターのみを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"白薔薇の回廊",h:"ほわいと・ろーず・くろいすたー",e:"White Rose Cloister",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\n手札から「ローズ・ドラゴン」モンスターまたは植物族モンスター１体を特殊召喚する。\n②：自分ドローフェイズのドロー前に、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n自分のデッキの一番上のカードをお互いに確認し、宣言した種類のカードだった場合、このターン中、以下の効果を適用する。\n●自分フィールドのレベル７以上のSモンスターの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"バーニング・ソウル",h:"ばーにんぐ・そうる",e:"Burning Soul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドにレベル８以上のSモンスターが存在する場合に発動できる。\n「バーニング・ソウル」以外の自分の墓地のカード１枚を選んで手札に加え、自分は自分フィールドのモンスターをS素材としてS召喚する。\nこのカードの発動後、ターン終了時まで相手はフィールドのSモンスターを効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"報復の隠し歯",h:"ほうふくのかくしば",e:"Hidden Fangs of Revenge",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードの発動に対して魔法・罠・モンスターの効果は発動できない。\n①：自分または相手のモンスターの攻撃宣言時に発動できる。\n自分フィールドにセットされているカード２枚を選んで破壊し、その攻撃を無効にする。\nさらに、この効果で破壊され墓地へ送られたカードの中にモンスターカードがあった場合、その内の１体を選ぶ。\n選んだモンスターの守備力以下の攻撃力を持つ相手フィールドのモンスターを全て破壊し、その後このターンのエンドフェイズになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイパー・ギャラクシー",h:"はいぱー・ぎゃらくしー",e:"Hyper Galaxy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：「銀河眼の光子竜」以外の自分フィールドの攻撃力２０００以上のモンスター１体をリリースし、相手フィールドの攻撃力２０００以上のモンスター１体を対象として発動できる。\nそのモンスターをリリースし、自分の手札・デッキ・墓地から「銀河眼の光子竜」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・シフト",h:"ぺんでゅらむ・しふと",e:"Pendulum Transfer",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのPモンスターを２体まで対象として発動できる。\nその自分のPモンスターを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・エクシーズ",h:"ぺんでゅらむ・えくしーず",e:"Pendulum Xyz",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のPゾーンのカード２枚を対象として発動できる。\nそのカード２枚を効果を無効にして特殊召喚し、そのモンスター２体のみを素材としてXモンスター１体をX召喚する。\nその際、X素材とするモンスター１体のレベルは、もう１体のモンスターと同じレベルとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダウジング・フュージョン",h:"だうじんぐ・ふゅーじょん",e:"Dowsing Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地から、融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で融合召喚する場合、Pモンスターしか融合素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"白の水鏡",h:"ほわいと・みらー",e:"White Mirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベル４以下の魚族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、特殊召喚したモンスターと元々のカード名が同じモンスター１体をデッキから手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"エターナル・ボンド",h:"えたーなる・ぼんど",e:"Eternal Bond",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「フォトン」モンスターを任意の数だけ対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、相手フィールドの「フォトン」モンスター１体を対象として発動できる。\nそのモンスターのコントロールを得る。\nこのターン、自分はそのモンスターでしか攻撃宣言できず、そのモンスターの攻撃力は自分フィールドの「フォトン」モンスターの元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"パラレルエクシード",h:"ぱられるえくしーど",e:"Parallel eXceed",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在し、自分がリンク召喚に成功した場合に発動できる。\nこのカードをそのリンクモンスターのリンク先となる自分フィールドに特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「パラレルエクシード」１体を特殊召喚する。\n③：「パラレルエクシード」の効果で特殊召喚したこのカードのレベルは４になり、元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"コードブレイカー・ゼロデイ",h:"こーどぶれいかー・ぜろでい",e:"Codebreaker Zero Day",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：「コードブレイカー」リンクモンスター以外の、このカードとリンク状態のリンクモンスターの攻撃力は１０００ダウンする。\n②：このカードが既にモンスターゾーンに存在する状態で、フィールドの「コードブレイカー」リンクモンスターが戦闘・効果で破壊された場合に発動する。\nこのカードを破壊する。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「コードブレイカー・ゼロデイ」１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"転生炎獣ゼブロイドX",h:"さらまんぐれいとぜぶろいどえっくす",e:"Salamangreat Zebroid X",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分の「サラマングレイト」リンクモンスターが相手の効果でフィールドから離れた場合に発動できる。\n自分の墓地からこのカードを含むレベル４の「サラマングレイト」モンスター２体を選んで効果を無効にして特殊召喚し、その２体のみを素材として「サラマングレイト」モンスター１体をX召喚する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードの攻撃力はこのカードのX素材の数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼アイアン・クロー",h:"ごうきあいあん・くろー",e:"Gouki Iron Claw",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「剛鬼」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nその自分のモンスターはターン終了時まで、攻撃力が５００アップし、相手の効果を受けない。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼アイアン・クロー」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"剛鬼ガッツ",h:"ごうきがっつ",e:"Gouki Guts",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：守備表示のこのカードは戦闘では破壊されない。\n②：自分メインフェイズに発動できる。\n自分フィールドの全ての「剛鬼」モンスターの攻撃力は２００アップする。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ガッツ」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女バシランリマ",h:"まりんせすばしらんりま",e:"Marincess Basilalima",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:600,de:2100,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「マリンセス」罠カード１枚を除外して発動できる。\nそのカードとはカード名が異なる「マリンセス」罠カード１枚をデッキから手札に加える。\n②：自分フィールドのモンスターが効果で破壊される場合、代わりに墓地のこのカードを除外できる。\n③：このカードが除外された場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ノクトビジョン・ドラゴン",h:"のくとびじょん・どらごん",e:"Noctovision Dragon",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにドラゴン族・闇属性モンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがリンク素材として墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。\n③：自分フィールドの裏側表示のカードを対象とする魔法・罠・モンスターの効果を相手が発動した時、墓地のこのカードを除外して発動できる。\nその効果を無効にする。\nこのターン、相手はその裏側表示のカードを効果の対象にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"アークネメシス・プロートス",h:"あーくねめしす・ぷろーとす",e:"Archnemeses Protos",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"幻竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:3000,tx:"このカードは通常召喚できない。\n自分の墓地及び自分フィールドの表側表示モンスターの中から、属性が異なるモンスター３体を除外した場合に特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードは効果では破壊されない。\n②：フィールドのモンスターの属性を１つ宣言して発動できる。\nフィールドの宣言した属性のモンスターを全て破壊する。\n次のターンの終了時まで、お互いに宣言した属性のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アークネメシス・エスカトス",h:"あーくねめしす・えすかとす",e:"Archnemeses Eschatos",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地及び自分フィールドの表側表示モンスターの中から、種族が異なるモンスター３体を除外した場合に特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードは効果では破壊されない。\n②：フィールドのモンスターの種族を１つ宣言して発動できる。\nフィールドの宣言した種族のモンスターを全て破壊する。\n次のターンの終了時まで、お互いに宣言した種族のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ネメシス・フラッグ",h:"ねめしす・ふらっぐ",e:"Nemeses Flag",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1100,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネメシス・フラッグ」以外の除外されている自分のモンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターをデッキに戻す。\n②：自分メインフェイズに発動できる。\nデッキから「ネメシス・フラッグ」以外の「ネメシス」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネメシス・アンブレラ",h:"ねめしす・あんぶれら",e:"Nemeses Umbrella",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネメシス・アンブレラ」以外の除外されている自分のモンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターをデッキに戻す。\n②：「ネメシス・アンブレラ」以外の自分の墓地の「ネメシス」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ネメシス・コリドー",h:"ねめしす・こりどー",e:"Nemeses Corridor",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1900,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネメシス・コリドー」以外の除外されている自分のモンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターをデッキに戻す。\n②：「ネメシス・コリドー」以外の除外されている自分の「ネメシス」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"深海のアーチザン",h:"しんかいのあーちざん",e:"Deep Sea Artisan",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが水属性モンスターの効果でデッキ・墓地から手札に加わった場合、このカードを相手に見せて発動できる。\n相手の手札を全て確認する。\n②：このカードが特殊召喚に成功した場合、自分のデッキの上からカードを１枚墓地へ送り、「深海のアーチザン」以外の自分の墓地のレベル４以下の水属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"深海のセントリー",h:"しんかいのせんとりー",e:"Deep Sea Sentry",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが水属性モンスターの効果を発動するために墓地へ送られた場合に発動できる。\n相手は手札を１枚選んでエンドフェイズまで表側表示で除外する。\n②：このカードが特殊召喚に成功した場合、自分のデッキの上からカードを２枚墓地へ送り、「深海のセントリー」以外の自分の墓地のレベル４以下の水属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"深海のミンストレル",h:"しんかいのみんすとれる",e:"Deep Sea Minstrel",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードと水属性モンスター１体を捨てて発動できる。\n相手の手札を確認し、その中からカード１枚を選んでエンドフェイズまで表側表示で除外する。\n②：このカードが特殊召喚に成功した場合、自分のデッキの上からカードを３枚墓地へ送り、「深海のミンストレル」以外の自分の墓地のレベル４以下の水属性モンスター１体を対象として発動できる。\nそのモンスターをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"スカーレッド・ファミリア",h:"すかーれっど・ふぁみりあ",e:"Red Familiar",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの悪魔族モンスター１体をリリースし、自分の墓地のドラゴン族・闇属性Sモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：墓地のこのカードを除外し、自分フィールドのドラゴン族・闇属性Sモンスター１体を対象とし、１～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリムゾン・リゾネーター",h:"くりむぞん・りぞねーたー",e:"Crimson Resonator",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分はドラゴン族・闇属性SモンスターしかEXデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカード以外の自分フィールドのモンスターがドラゴン族・闇属性Sモンスター１体のみの場合に発動できる。\n手札・デッキから「クリムゾン・リゾネーター」以外の「リゾネーター」モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ランタン・シャーク",h:"らんたん・しゃーく",e:"Lantern Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「ランタン・シャーク」以外のレベル３～５の水属性モンスター１体を守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n②：このカードを水属性モンスターのX召喚に使用する場合、このカードのレベルを３または５として扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"カッター・シャーク",h:"かったー・しゃーく",e:"Buzzsaw Shark",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの水属性モンスター１体を対象として発動できる。\nそのモンスターと同じレベルでカード名が異なる魚族モンスター１体をデッキから守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n②：このカードを水属性モンスターのX召喚に使用する場合、このカードのレベルを３または５として扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の孟－曹徳",h:"せんかのもう－そうとく",e:"Ancient Warriors - Ambitious Cao De",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドのカードが戦闘・効果で破壊された場合、このカード以外の自分の手札・フィールドのカード１枚を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに他の「戦華」モンスターが存在する限り、このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n③：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の来－張遠",h:"せんかのらい－ちょうえん",e:"Ancient Warriors - Fearsome Zhang Yuan",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「戦華」モンスターが相手の表側表示モンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードを手札から特殊召喚し、その相手モンスターの攻撃力を１０００ダウンする。\n②：このカード以外の自分の「戦華」モンスターは戦闘では破壊されない。\n③：相手フィールドのカードが戦闘・効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の詭－賈文",h:"せんかのき－かぶん",e:"Ancient Warriors - Deceptive Jia Wen",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの永続魔法・永続罠カード１枚を対象として発動できる。\nそのカードを墓地へ送り、フィールドの表側表示モンスター２体を選び、その攻撃力をターン終了時まで半分にする。\n②：相手フィールドのカードが戦闘・効果で破壊された場合、「戦華の詭－賈文」以外の自分の墓地の「戦華」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の智－諸葛孔",h:"せんかのち－しょかつこう",e:"Ancient Warriors - Ingenious Zhuge Kong",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「戦華」カードの効果でデッキから手札に加わった場合に発動できる。\nこのカードを特殊召喚する。\n②：魔法・罠カードが発動した時、自分フィールドの表側表示の「戦華」永続魔法・永続罠カード１枚を墓地へ送って発動できる。\nその発動を無効にする。\n③：自分フィールドに「戦華の徳－劉玄」が存在し、モンスターの効果が発動した時、自分フィールドの表側表示の「戦華」永続魔法・永続罠カード１枚を墓地へ送って発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"宵星の騎士ギルス",h:"じゃっくないつ・おるふぇごーるぎるす",e:"Girsu, the Orcust Mekk-Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「オルフェゴール」カードまたは「星遺物」カード１枚を墓地へ送る。\nこのカードと同じ縦列に他のカードが２枚以上存在する場合、さらにこのターン、このカードをチューナーとして扱う。\n②：自分フィールドに他のモンスターが存在しない場合に発動できる。\nお互いのフィールドに「星遺物トークン」（機械族・闇・星１・攻／守０）を１体ずつ守備表示で特殊召喚する。",li:1,lk:[],ta:[],al:[]},
  {n:"天雷震龍－サンダー・ドラゴン",h:"てんらいしんりゅう－さんだー・どらごん",e:"Thunder Dragonlord",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2000,tx:"このカードは通常召喚できない。\n雷族モンスターの効果が手札で発動したターン、手札及び自分フィールドの表側表示モンスターの中から、レベル８以下の雷族モンスター１体を除外した場合に特殊召喚できる。\n①：相手ターンに１度、自分の墓地から雷族モンスターを含むカード２枚を除外し、自分フィールドの雷族モンスター１体を対象として発動できる。\nこのターン、そのモンスターは相手の効果の対象にならない。\n②：自分エンドフェイズに発動できる。\nデッキから「サンダー・ドラゴン」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・チェイム",h:"どらごんめいど・ちぇいむ",e:"Chamber Dragonmaid",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ドラゴンメイド」魔法・罠カード１枚を手札に加える。\n②：自分・相手のバトルフェイズ開始時に発動できる。\nこのカードを持ち主の手札に戻し、自分の手札・墓地からレベル７以上の「ドラゴンメイド」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"轟の王 ハール",h:"とどろきのじぇねれいど　はーる",e:"Harr, Generaider Boss of Storms",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：「轟の王 ハール」は自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、相手がドローフェイズ以外でデッキからカードを手札に加えた場合に発動できる。\n相手は自身の手札・フィールドのモンスター１体を墓地へ送らなければならない。\n③：魔法・罠・モンスターの効果が発動した時、自分フィールドの「ジェネレイド」モンスターまたは魔法使い族モンスターを合計２体リリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"王の影 ロプトル",h:"じぇねれいど・しゃどう　ろぷとる",e:"Loptr, Shadow of the Generaider Bosses",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：「王の影 ロプトル」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドの「ジェネレイド」モンスターの攻撃力・守備力は相手ターンの間１０００アップする。\n③：自分・相手のメインフェイズに、自分フィールドの「ジェネレイド」モンスター１体をリリースして発動できる。\nそのモンスターとはカード名が異なるレベル９の「ジェネレイド」モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"破械童子サラマ",h:"はかいどうじさらま",e:"Unchained Twins - Sarama",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「破械童子サラマ」以外の自分の墓地の「破械」カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nその後、自分フィールドのカード１枚を選んで破壊する。\n②：フィールドのこのカードが戦闘または「破械童子サラマ」以外のカードの効果で破壊された場合に発動できる。\n手札・デッキから「破械童子サラマ」以外の「破械」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"獣神王バルバロス",h:"じゅうしんおうばるばろす",e:"King Beast Barbaros",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：レベルの合計が８以上になるように、自分フィールドのモンスターをリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：自分の墓地及び自分フィールドの表側表示モンスターの中から、「バルバロス」モンスター１体を除外し、相手フィールドのカード２枚を対象として発動できる。\nそのカードを破壊する。\n③：このカードは相手モンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧狐－宇迦之御魂稲荷",h:"きこうこ－うかのみたまのいなり",e:"Gizmek Uka, the Festive Fox of Fecundity",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2250,de:2250,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキからモンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\n手札・デッキから、攻撃力と守備力の数値が同じで、属性が対象のモンスターと同じとなるモンスター１体を特殊召喚する。\n③：相手がモンスターの召喚・特殊召喚に成功する度に発動する。\n相手に３００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"トリアス・ヒエラルキア",h:"とりあす・ひえらるきあ",e:"Trias Hierarchia",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1900,de:2900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分・相手のメインフェイズに、自分フィールドの天使族モンスターを３体までリリースして発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nさらに、この効果を発動するためにリリースしたモンスターの数によって以下の効果をそれぞれ適用できる。\n\n●２体以上：相手フィールドのカード１枚を選んで破壊する。\n\n●３体：自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"氷天禍チルブレイン",h:"ひょうてんかちるぶれいん",e:"Cataclysmic Circumpolar Chilblainia",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の水属性モンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、相手の手札をランダムに１枚選んで墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ユニオン・ドライバー",h:"ゆにおん・どらいばー",e:"Union Driver",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備されているこのカードを除外して発動できる。\nこのカードを装備していたモンスターに、装備可能なレベル４以下のユニオンモンスター１体をデッキから装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔嬢マリス",h:"あくまじょうまりす",e:"Malice, Lady of Lament",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター２体をリリースし、自分の墓地のカード及び除外されている自分のカードの中から、通常罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nこの効果でセットしたカードはフィールドから離れた場合に持ち主のデッキの一番下に戻る。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"朔夜しぐれ",h:"さよしぐれ",e:"Ghost Mourner & Moonlit Chill",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：相手がモンスターを表側表示で特殊召喚した場合、このカードを手札から捨て、その表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果はターン終了時まで無効化され、このターン中に対象の表側表示モンスターがフィールドから離れた場合、そのコントローラーは対象のモンスターの元々の攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"珠玉獣－アルゴザウルス",h:"しゅぎょくじゅう－あるござうるす",e:"Animadorned Archosaur",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札及び自分フィールドの表側表示モンスターの中から、「珠玉獣－アルゴザウルス」以外の恐竜族モンスター１体を選んで破壊する。\nその後、元々のレベルがその破壊されたモンスターと同じとなる爬虫類族・海竜族・鳥獣族モンスター１体または「進化薬」魔法カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"百景戦都ゴルディロックス",h:"らんどすけーぱーごるでぃろっくす",e:"Goldilocks the Battle Landscaper",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:3000,tx:"①：このカードは中央以外のメインモンスターゾーンに召喚・特殊召喚した場合、破壊される。\n②：このカードがメインモンスターゾーンの中央に存在する限り、このカードの攻撃力は３０００アップする。\n③：１ターンに１度、使用していない自分のメインモンスターゾーンを１ヵ所指定して発動できる。\n自分のメインモンスターゾーンのこのカードの位置を、指定したゾーンに移動する。\nその後、移動する前と移動した後のモンスターゾーン及びその間のモンスターゾーンと同じ縦列に存在する、このカード以外のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジカル・ハウンド",h:"まじかる・はうんど",e:"Magical Hound",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:800,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、相手フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"召喚獣アウゴエイデス",h:"しょうかんじゅうあうごえいです",e:"Invoked Augoeides",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2800,tx:"「召喚師アレイスター」＋融合モンスター\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、または相手フィールドにモンスターが特殊召喚された場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：１ターンに１度、自分の墓地から融合モンスター１体を除外して発動できる。\nこのカードの攻撃力は相手ターン終了時まで、除外したモンスターの攻撃力の数値分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイド・シュトラール",h:"どらごんめいど・しゅとらーる",e:"Dragonmaid Sheou",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:2000,tx:"「ドラゴンメイド」モンスター＋レベル５以上のドラゴン族モンスター\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のスタンバイフェイズに発動できる。\n自分の手札・墓地からレベル９以下の「ドラゴンメイド」モンスター１体を選んで特殊召喚する。\n②：相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nその発動を無効にし破壊する。\nこのカードを持ち主のEXデッキに戻し、EXデッキから「ドラゴンメイド・ハスキー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"深海姫プリマドーナ",h:"しんかいひぷりまどーな",e:"Deep Sea Prima Donna",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1500,de:2700,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：除外されている相手のカード１枚を対象として発動できる。\nデッキからレベル４以下の水属性モンスター１体を手札に加えるか特殊召喚し、対象のカードを相手の手札に加える。\n②：このカードをS素材としたSモンスターは、相手モンスターの効果の対象にならない。\n③：このカードが墓地へ送られた場合、除外されている自分または相手のカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"飢鰐竜アーケティス",h:"きがくりゅうあーけてぃす",e:"Ravenous Crocodragon Archethys",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"魚族",mo:["シンクロ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nそのS素材としたモンスターの内、チューナー以外のモンスターの数だけ、自分はデッキからドローする。\n②：このカードの攻撃力・守備力は自分の手札の数×５００アップする。\n③：手札を２枚捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァリアント・シャーク・ランサー",h:"う゛ぁりあんと・しゃーく・らんさー",e:"Valiant Shark Lancer",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1600,tx:"レベル５モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドのモンスター１体を対象として発動できる。\n自分フィールドのX素材を１つ取り除き、対象のモンスターを破壊する。\n自分フィールドに他の水属性Xモンスターが存在する場合、この効果は相手ターンでも発動できる。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドの他の水属性Xモンスターが戦闘・効果で破壊された場合に発動できる。\nデッキから魔法カード１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"アロメルスの蟲惑魔",h:"あろめるすのこわくま",e:"Traptrix Allomerus",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:600,tx:"レベル４モンスター×２体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：X素材を持ったこのカードは罠カードの効果を受けない。\n②：このカードのX素材を２つ取り除いて発動できる。\n自分の墓地から昆虫族・植物族のレベル４モンスター１体を選んで特殊召喚する。\n③：自分のカードの効果で相手モンスターがフィールドから離れ、墓地へ送られた場合または除外された場合、このカードのX素材を１つ取り除き、そのモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アクセスコード・トーカー",h:"あくせすこーど・とーかー",e:"Accesscode Talker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"効果モンスター２体以上\nこのカードの効果の発動に対して相手は効果を発動できない。\n①：このカードがリンク召喚に成功した場合、そのリンク素材としたリンクモンスター１体を対象として発動できる。\nこのカードの攻撃力は、そのモンスターのリンクマーカーの数×１０００アップする。\n②：自分のフィールド・墓地からリンクモンスター１体を除外して発動できる。\n相手フィールドのカード１枚を選んで破壊する。\nこのターン、自分の「アクセスコード・トーカー」の効果を発動するために同じ属性のモンスターを除外する事はできない。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"プロキシー・F・マジシャン",h:"ぷろきしー・えふ・まじしゃん",e:"Proxy F Magician",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1400,de:"-",tx:"効果モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードのリンク先に融合モンスターが融合召喚された場合に発動できる。\n手札から攻撃力１０００以下のモンスター１体を特殊召喚する。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"スプラッシュ・メイジ",h:"すぷらっしゅ・めいじ",e:"Splash Mage",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1100,de:"-",tx:"サイバース族モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分の墓地のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしか特殊召喚できない。",li:"",lk:["右上","右下"],ta:[],al:[]},
  {n:"リンクロス",h:"りんくろす",e:"Linkross",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:900,de:"-",tx:"リンク２以上のリンクモンスター１体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nそのリンク素材としたリンクモンスターのリンクマーカーの数まで、自分フィールドに「リンクトークン」（サイバース族・光・星１・攻／守０）を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「リンクトークン」をリンク素材にできない。",li:0,lk:["下"],ta:[],al:[]},
  {n:"ジ・アライバル・サイバース＠イグニスター",h:"じ・あらいばる・さいばーすあっといぐにすたー",e:"The Arrival Cyberse @Ignister",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"属性が異なるモンスター３体以上\n①：「ジ・アライバル・サイバース＠イグニスター」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードの元々の攻撃力は、このカードのリンク素材としたモンスターの数×１０００になる。\n③：このカードは他のカードの効果を受けない。\n④：１ターンに１度、このカード以外のフィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、このカードのリンク先となる自分フィールドに「＠イグニスタートークン」（サイバース族・闇・星１・攻／守０）１体を特殊召喚する。",li:"",lk:["上","左","右","左下","下","右下"],ta:[],al:[]},
  {n:"コードブレイカー・ウイルスバーサーカー",h:"こーどぶれいかー・ういるすばーさーかー",e:"Codebreaker Virus Berserker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"「コードブレイカー」モンスターを含むモンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、このカードが相互リンク状態の場合に発動できる。\n自分の手札・墓地から「コードブレイカー」モンスターを２体まで選び、リンクモンスターのリンク先となる自分・相手フィールドに特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分・相手フィールドのリンク状態の「コードブレイカー」モンスターの数まで、相手フィールドの魔法・罠カードを選んで破壊する。",li:"",lk:["上","右","下"],ta:[],al:[]},
  {n:"コードブレイカー・ウイルスソードマン",h:"こーどぶれいかー・ういるすそーどまん",e:"Codebreaker Virus Swordsman",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"効果モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、このカードが相互リンク状態の場合に発動できる。\n自分の手札・デッキ・墓地から「コードブレイカー・ゼロデイ」１体を選び、リンクモンスターのリンク先となる自分・相手フィールドに特殊召喚する。\n②：このカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"剛鬼ザ・パワーロード・オーガ",h:"ごうきざ・ぱわーろーど・おーが",e:"Gouki The Powerload Ogre",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2800,de:"-",tx:"戦士族モンスター２体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカード以外の自分フィールドのリンクモンスターのリンクマーカーの合計×２００アップする。\n②：リンク召喚したこのカードは他のカードの効果を受けない。\n③：自分フィールドの「剛鬼」リンクモンスター１体をリリースし、そのリンクマーカーの数までフィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:["右","左下","下","右下"],ta:[],al:[]},
  {n:"海晶乙女グレート・バブル・リーフ",h:"まりんせすぐれーと・ばぶる・りーふ",e:"Marincess Great Bubble Reef",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2600,de:"-",tx:"水属性モンスター２体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：お互いのスタンバイフェイズに、自分の墓地及び自分フィールドの表側表示モンスターの中から、水属性モンスター１体を除外して発動できる。\n自分はデッキから１枚ドローする。\n②：モンスターが除外される度に発動する。\nこのカードの攻撃力はターン終了時までその除外されたモンスターの数×６００アップする。\n③：手札から水属性モンスター１体を墓地へ送って発動できる。\n除外されている自分の「マリンセス」モンスター１体を選んで特殊召喚する。",li:"",lk:["左","右","左下","下"],ta:[],al:[]},
  {n:"清冽の水霊使いエリア",h:"せいれつのすいれいつかいえりあ",e:"Eria the Water Charmer, Gentle",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1850,de:"-",tx:"水属性モンスターを含むモンスター２体\nこのカード名はルール上「憑依装着」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の水属性モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから守備力１５００以下の水属性モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"Ai－コンタクト",h:"あい－こんたくと",e:"A.I. Contact",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のフィールドゾーンに「イグニスターAiランド」が存在する場合、手札の「イグニスターAiランド」１枚を相手に見せて発動できる。\n見せたカードをデッキの一番下に戻し、自分はデッキから３枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"逆巻く炎の宝札",h:"ばーにんぐ・どろー",e:"Burning Draw",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「サラマングレイト」カードとしても扱う。\nこのカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は炎属性モンスターしか召喚・特殊召喚できない。\n①：相手フィールドのカードの数が自分フィールドのカードより多い場合、相手フィールドのリンクモンスター１体を対象として発動できる。\nそのモンスターのリンクマーカーの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"リンク・バースト",h:"りんく・ばーすと",e:"Link Burst",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのリンクモンスター１体と、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"メールの階段",h:"めーるのかいだん",e:"Stairs of Mail",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分メインフェイズにこの効果を発動できる。\n手札から「ティンダングル」カード１枚を捨て、以下の効果から１つを選んで適用する。\nこのターン、この効果で捨てたカードと同名のカードを自分の「メールの階段」の効果で捨てる事はできない。\n\n●自分フィールドの裏側守備表示モンスター１体を選んで表側攻撃表示にする。\n\n●自分フィールドの表側攻撃表示モンスター１体を選んで裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"星義の執行者",h:"ねめしす・あどらすてあ",e:"Nemeses Adrastea",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、「ネメシス」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚した元々のレベルが１１以上のモンスターは、このターン効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"深海のアリア",h:"しんかいのありあ",e:"Deep Sea Aria",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地から水属性モンスター１体を除外して発動できる。\nデッキからレベル４以下の海竜族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"コマンド・リゾネーター",h:"こまんど・りぞねーたー",e:"Resonator Command",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から「リゾネーター」モンスター１体を捨てて発動できる。\nデッキからレベル４以下の悪魔族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エアー・トルピード",h:"えあー・とるぴーど",e:"Torpedo Takedown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの水属性Xモンスター１体を対象として発動できる。\nそのモンスターのX素材を１つ取り除き、自分の手札の数×４００ダメージを相手に与える。\nそのモンスターの攻撃力はターン終了時まで、この効果で与えたダメージの数値分アップする。\n②：自分の墓地からこのカードと水属性Xモンスター１体を除外して発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・サロン",h:"まどるちぇ・さろん",e:"Madolche Salon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「マドルチェ」モンスター１体を召喚できる。\n②：このカード以外の自分のフィールド・墓地の「マドルチェ」カードが効果で自分の手札・デッキに戻った場合に発動する。\nデッキから「マドルチェ・サロン」以外の「マドルチェ」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の災天",h:"れんごくのさいてん",e:"Void Apocalypse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札を１枚捨てて発動できる。\nデッキから悪魔族モンスター１体を墓地へ送る。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\nEXデッキから特殊召喚された相手フィールドのモンスターの数まで、手札・デッキから「インフェルノイド」モンスターを墓地へ送る（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"超重機回送",h:"へびー・ふぉわーど",e:"Heavy Forward",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「無限起動」モンスター１体を手札に加える事ができる。\n②：１ターンに１度、自分フィールドの機械族Xモンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●そのモンスターの表示形式を変更する。\n\n●このカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・デモンストレーション",h:"うぃっちくらふと・でもんすとれーしょん",e:"Witchcrafter Unveiling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札から「ウィッチクラフト」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したターン、自分の魔法使い族モンスターの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"眷現の呪眼",h:"けんげんのじゅがん",e:"Evil Eye Reemergence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「呪眼の眷属トークン」（悪魔族・闇・星１・攻／守４００）１体を特殊召喚する。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、この効果で特殊召喚する数を２体にできる。\nこのカードの発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分フィールドの「呪眼」魔法・罠カードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華史略－十万之矢",h:"せんかしりゃく－じゅうまんのや",e:"Ancient Warriors Saga - Borrowing of Arrows",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの表側表示モンスター１体と自分フィールドの「戦華」モンスター１体を対象として発動できる。\nターン終了時まで、その相手モンスターの攻撃力を半分にし、その数値分その自分のモンスターの攻撃力をアップする。\n②：自分フィールドの「戦華」モンスターの属性が２種類以上で、このカードが墓地へ送られた場合に発動できる。\n手札・デッキから「戦華史略－十万之矢」以外の「戦華」永続魔法・永続罠カード１枚を自分フィールドに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・アンフォームド",h:"めがりす・あんふぉーむど",e:"Megalith Unformed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●相手フィールドの全てのモンスターの攻撃力はターン終了時まで、フィールドの儀式モンスターの数×５００ダウンする。\n\n●レベルの合計が儀式召喚するモンスターのレベルの倍になるように、自分の手札・フィールドのモンスターをリリースし、デッキから「メガリス」儀式モンスター１体を守備表示で儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"融合派兵",h:"ゆうごうはへい",e:"Fusion Deployment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：EXデッキの融合モンスター１体を相手に見せ、そのモンスターにカード名が記されている融合素材モンスター１体を手札・デッキから特殊召喚する。\nこのカードを発動するターン、自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"繁華の花笑み",h:"はんかのはなえみ",e:"Flourishing Frolic",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「繁華の花笑み」の数＋３枚を自分のデッキの上からめくる。\nその中に３種類（モンスター・魔法・罠）のカードがあった場合、その内の１枚を選んで手札に加え、残りのカードを墓地へ送る。\n無かった場合、めくったカードを全てデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"Ai－Q",h:"あい－きゅー",e:"A.I.Q",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールドに「＠イグニスター」モンスターが存在する場合にこのカードを発動できる。\nこのカードのコントローラーは、自分スタンバイフェイズ毎に自分フィールドのリンクモンスター１体をリリースする。\nまたはリリースせずにこのカードを破壊する。\n①：このカードが魔法＆罠ゾーンに存在する限り、その間はお互いに１ターンに１度しかリンク召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スカーレッド・レイン",h:"すかーれっど・れいん",e:"Red Reign",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにレベル８以上のSモンスターが存在する場合に発動できる。\nフィールドの、レベルが一番高いモンスター以外のモンスターを全て除外する。\nこの効果で除外されなかったフィールドの表側表示モンスターはターン終了時まで、自身以外のカードの効果を受けない。\n②：このカードが墓地に存在する状態で、自分フィールドにドラゴン族・闇属性SモンスターがS召喚された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・リバイブ・スプラッシュ",h:"えくしーず・りばいぶ・すぷらっしゅ",e:"Xyz Revive Splash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地のランク４以下のXモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは水属性になる。\n②：墓地のこのカードを除外し、自分フィールドの水属性Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが１つ高い水属性Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マドルチェ・プロムナード",h:"まどるちぇ・ぷろむなーど",e:"Madolche Promenade",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：相手フィールドの表側表示のカード１枚と自分のフィールド・墓地の「マドルチェ」モンスター１体を対象として発動できる。\nその相手のカードの効果をターン終了時まで無効にし、その自分のモンスターを持ち主の手札に戻す。\n②：墓地のこのカードを除外し、自分フィールドの「マドルチェ」Xモンスター１体を対象として発動できる。\n自分の手札・デッキ・墓地から「マドルチェ」モンスター１体を選び、対象のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・パトローナス",h:"うぃっちくらふと・ぱとろーなす",e:"Witchcrafter Patronus",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、魔法使い族モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、デッキから「ウィッチクラフト」魔法カード１枚を手札に加える。\n②：墓地のこのカードを除外し、除外されている自分の「ウィッチクラフト」魔法カードを任意の数だけ対象として発動できる（同名カードは１枚まで）。\nそのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"墓穴ホール",h:"ぼけつほーる",e:"Gravedigger's Trap Hole",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札・墓地のモンスターまたは除外されているモンスターの効果を相手が発動した時に発動できる。\nその効果を無効にし、相手に２０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"巨神封じの矢",h:"てぃたのさいだー",e:"Titanocider",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚された相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、その効果を無効にする。\n②：このカードが墓地に存在する状態で、相手がEXデッキからモンスターを特殊召喚した場合に発動できる。\nこのカードを自分フィールドにセットする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ピンポイント奪取",h:"ぴんぽいんとだっしゅ",e:"Pinpoint Dash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：お互いはそれぞれ自身のEXデッキの裏側表示のカードを１枚選ぶ。\nそのカードをお互いに確認し、同じ種類（融合・S・X・リンク）だった場合、相手が選んだカードを墓地へ送り、自分が選んだカードを特殊召喚する。\nさらに元々の種族・属性が同じだった場合には、相手は自身が選んだカードの攻撃力分のLPを失う。\n違う種類だった場合、自分が選んだカードを墓地へ送り、相手は自身が選んだカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ディザスター・デーモン",h:"でぃざすたー・でーもん",e:"Annihilator Archfiend",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分及び相手フィールドのカードを１枚ずつ対象として発動できる。\nそのカードを破壊する。\nこの効果で表側表示の悪魔族モンスターを破壊した場合、このカードの攻撃力はターン終了時まで、そのモンスターの元々の攻撃力を合計した数値の半分だけアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"背護衛",h:"はいごえい",e:"Guard Ghost",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地からの特殊召喚に成功した場合、自分フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは戦闘・効果では破壊されない。\n②：このカードが除外されたターンのエンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"いたずら風のフィードラン",h:"いたずらかぜのふぃーどらん",e:"Feedran, the Winds of Mischief",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：相手ターンに、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\nその後、このカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"九魂猫",h:"ないんらいぶず・きゃっと",e:"Nine-Lives Cat",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:900,de:3300,tx:"レベル９モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、墓地から特殊召喚された自分フィールドの他のモンスターは相手の効果の対象にならない。\n②：このカードのX素材を１つ取り除き、自分の墓地のレベル９モンスター１体または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"契約の遂行",h:"けいやくのすいこう",e:"Execution of the Contract",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札の儀式モンスター１体を相手に見せて発動できる。\n装備モンスターのレベルはターン終了時まで、見せたモンスターのレベルと同じになる。\n②：装備モンスターがリリースされた事でこのカードが墓地へ送られた場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－白髭の機関士",h:"ぷらんどろーる－しろひげのきかんし",e:"Whitebeard, the Plunder Patroll Helm",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手ターンに発動できる。\n相手のフィールド・墓地のモンスターのいずれかと同じ属性を持つ「海造賊」モンスター１体をEXデッキから特殊召喚し、自分フィールドのこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードが手札・モンスターゾーンから墓地へ送られた場合に発動できる。\nデッキから「海造賊－白髭の機関士」以外の「海造賊」モンスター１体を特殊召喚する。\nこのターン、自分は「海造賊」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－赤髭の航海士",h:"ぷらんどろーる－あかひげのこうかいし",e:"Redbeard, the Plunder Patroll Matey",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手ターンに発動できる。\n相手のフィールド・墓地のモンスターのいずれかと同じ属性を持つ「海造賊」モンスター１体をEXデッキから特殊召喚し、自分フィールドのこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードが手札・モンスターゾーンから墓地へ送られた場合、「海造賊－赤髭の航海士」以外の自分フィールドの「海造賊」モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－豪速のブレンネ号",h:"ぷらんどろーる－ごうそくのぶれんねごう",e:"Plunder Patrollship Brann",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの他の悪魔族モンスターの攻撃力は５００アップする。\n②：手札から「海造賊」カード１枚を捨て、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。\nその後、デッキから「海造賊」モンスター１体を手札に加える事ができる。\nこのカードが「海造賊」カードを装備している場合、この効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－静寂のメルケ号",h:"ぷらんどろーる－せいじゃくのめるけごう",e:"Plunder Patrollship Moerk",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:2500,tx:"レベル４モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：手札から「海造賊」カード１枚を捨て、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを除外する。\nその後、デッキから「海造賊」魔法・罠カード１枚を手札に加える事ができる。\nこのカードが「海造賊」カードを装備している場合、この効果は相手ターンでも発動できる。\n②：自分フィールドの「海造賊」カードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－キャプテン黒髭",h:"ぷらんどろーる－きゃぷてんくろひげ",e:"Blackbeard, the Plunder Patroll Captain",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"「海造賊」モンスターを含むモンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの効果モンスター１体を対象として発動できる。\n相手のフィールド・墓地のモンスターのいずれかと同じ属性を持つ「海造賊」モンスター１体をEXデッキから特殊召喚し、対象の自分の効果モンスターを装備カード扱いとしてその特殊召喚したモンスターに装備する。\nその後、自分はデッキから１枚ドローする。\nこの効果は相手ターンでも発動できる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"海造賊－拠点",h:"ぷらんどろーる－しっぷやーど",e:"Plunder Patroll Shipyarrrd",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「海造賊」モンスターの攻撃力は、自分の魔法＆罠ゾーンの「海造賊」カードの数×５００アップする。\n②：自分メインフェイズに、手札を１枚捨てて発動できる。\nデッキから「海造賊－拠点」以外の「海造賊」カード１枚を手札に加える。\n③：このカードが墓地に存在する場合、自分の魔法＆罠ゾーンの「海造賊」カード１枚を対象として発動できる。\nこのカードを自分フィールドにセットし、対象のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－大航海",h:"ぷらんどろーる－ぐらんど・う゛ぉやーじゅ",e:"Plunder Patroll Booty",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：属性を１つ宣言し、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターをターン終了時まで宣言した属性にする。\nその後、自分の墓地から「海造賊」モンスター１体を選んでデッキに戻すか特殊召喚できる。\n②：自分・相手のエンドフェイズに、「海造賊」モンスターが自分フィールドに存在しない場合に発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士－リナルド",h:"えんせいきし－りなるど",e:"Infernoble Knight - Renaud",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに戦士族・炎属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードはチューナーとして扱う。\n②：このカードが特殊召喚に成功した場合、自分の墓地のカード及び除外されている自分のカードの中から、「焔聖騎士－リナルド」以外の戦士族・炎属性モンスター１体または装備魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"宣告者の神巫",h:"でくれあらー・でぃう゛ぁいなー",e:"Diviner of the Herald",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキ・EXデッキから天使族モンスター１体を墓地へ送る。\nこのカードのレベルはターン終了時まで、そのモンスターのレベル分だけ上がる。\n②：このカードがリリースされた場合に発動できる。\n手札・デッキから「宣告者の神巫」以外のレベル２以下の天使族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"眩月龍セレグレア",h:"げんげつりゅうせれぐれあ",e:"Seleglare the Luminous Lunar Dragon",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはリリースなしで通常召喚できる。\n②：このカードの①の方法で通常召喚したこのカードの元々の攻撃力は１５００になる。\n③：自分・相手のメインフェイズに、このカードの攻撃力以下の攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nこのカードを持ち主の手札に戻し、対象のモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[]},
  {n:"生きる偲びのシルキィ",h:"いきるしのびのしるきぃ",e:"Ret-time Reviver Emit-ter",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分及び相手フィールドの表側表示モンスターを１体ずつ対象として発動できる。\nそのモンスターを裏側守備表示にし、このカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"驀進装甲ライノセイバー",h:"ばくしんそうこうらいのせいばー",e:"Rampaging Smashtank Rhynosaber",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2100,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を任意の枚数捨てて発動できる。\nこのカードの攻撃力は捨てた数×７００アップする。\n②：このカードが戦闘を行ったバトルフェイズ終了時に、このカードを墓地へ送って発動できる。\nレベルの合計が７になるように、自分の墓地から「驀進装甲ライノセイバー」以外のモンスターを選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"闇味鍋パーティー",h:"やみなべぱーてぃー",e:"Yaminabe Party",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分バトルフェイズ開始時に、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その自分のモンスターの攻撃対象は相手が選択し、その自分のモンスターの攻撃力は相手モンスターと戦闘を行うダメージ計算時のみ、自身の元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"捲怒重来",h:"けんどちょうらい",e:"Revenge Rally",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力・守備力５００アップの装備カード扱いとして、その相手モンスターに装備する。\n②：装備モンスターがフィールドから離れた事でこのカードが墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。\nこのカードをこのターンに発動している場合には代わりに以下の効果を適用する。\n●自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・カーネル",h:"ましんなーず・かーねる",e:"Machina Citadel",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手ターンに、自分フィールドの機械族モンスター１体を対象として発動できる。\nその機械族モンスターと、その攻撃力以下の攻撃力を持つ相手フィールドのモンスターを全て破壊する。\n②：このカードが墓地に存在する状態で、「マシンナーズ・カーネル」以外の自分フィールドの表側表示の機械族・地属性モンスターが戦闘・効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・エアレイダー",h:"ましんなーず・えあれいだー",e:"Machina Air Raider",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカード以外の「マシンナーズ」モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：相手ターンに、自分フィールドの機械族モンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なり、そのモンスターのレベル以下のレベルを持つ「マシンナーズ」モンスター１体をデッキから特殊召喚し、対象のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・ラディエーター",h:"ましんなーず・らでぃえーたー",e:"Machina Irradiator",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカード以外の「マシンナーズ」モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドの機械族モンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なり、そのモンスターのレベル以下のレベルを持つ「マシンナーズ」モンスター１体を自分の墓地から選んで特殊召喚し、対象のモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲部隊の再編制",h:"ましんなーず・りふぉーめーしょん",e:"Machina Redeployment",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●手札を１枚捨てて発動できる。\nデッキから「マシンナーズ」モンスター２体を手札に加える（同名カードは１枚まで）。\n\n●手札から「マシンナーズ」カード１枚を捨てて発動できる。\nデッキから「機甲部隊の再編制」以外の「マシンナーズ」カード２枚を手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲部隊の防衛圏",h:"ましんなーず・でぃふぇんすりじょん",e:"Machina Defense Perimeter",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにレベル７以上の機械族モンスターが存在する限り、相手は自分フィールドのレベル６以下の機械族モンスターを、攻撃対象に選択できず、効果の対象にもできない。\n②：自分フィールドの表側表示の機械族モンスターが戦闘・効果で破壊された場合、自分の墓地の機械族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機甲部隊の超臨界",h:"ましんなーず・おーばーどらいぶ",e:"Machina Overdrive",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの機械族モンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なる「マシンナーズ」モンスター１体を手札・デッキから特殊召喚し、対象のモンスターを破壊する。\n②：墓地のこのカードを除外し、自分の墓地のモンスター及び除外されている自分のモンスターの中から、機械族モンスター３体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・パゼストレージ",h:"ましんなーず・ぱぜすとれーじ",e:"Machina Possesstorage",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「マシンナーズ・パゼストレージ」以外の自分の墓地の「マシンナーズ」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。\n②：このカード以外の自分フィールドの「マシンナーズ」モンスター１体と相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"無許可の再奇動",h:"めいるふぁくたーず・こまんど",e:"Unauthorized Reactivation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの機械族モンスター１体を対象として発動できる。\nそのモンスターに、装備可能な機械族ユニオンモンスター１体を手札・デッキから装備する。\nこの効果で装備したユニオンモンスターは、このターン特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の追求者",h:"あだましあ・しーかー",e:"Adamancipator Seeker",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「魔救の追求者」以外の「アダマシア」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中からチューナー以外のレベル４以下の岩石族モンスター１体を選んで特殊召喚できる。\n残りのカードは好きな順番でデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の探索者",h:"あだましあ・りさーちゃー",e:"Adamancipator Researcher",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「魔救の探索者」以外の岩石族モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中からチューナー以外のレベル４以下の岩石族モンスター１体を選んで特殊召喚できる。\n残りのカードは好きな順番でデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の分析者",h:"あだましあ・あならいざー",e:"Adamancipator Analyzer",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドにのみモンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中からチューナー以外のレベル４以下の岩石族モンスター１体を選んで特殊召喚できる。\n残りのカードは好きな順番でデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の奇石－レオナイト",h:"あだましあ・くりすた－れおないと",e:"Adamancipator Crystal - Leonite",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「アダマシア」カードの効果で特殊召喚に成功した場合に発動できる。\n自分の手札・墓地から「アダマシア」カード１枚を選んでデッキの一番上に戻す。\n②：このカードが墓地に存在する場合、自分のフィールド・墓地の炎属性Sモンスター１体を対象として発動できる。\nそのモンスターを持ち主のEXデッキに戻し、このカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の奇石－ラプタイト",h:"あだましあ・くりすた－らぷたいと",e:"Adamancipator Crystal - Raptite",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「アダマシア」カードの効果で特殊召喚に成功した場合に発動できる。\n自分の手札・墓地から岩石族モンスター１体を選んでデッキの一番上に戻す。\n②：このカードが墓地に存在する場合、自分のフィールド・墓地の風属性Sモンスター１体を対象として発動できる。\nそのモンスターを持ち主のEXデッキに戻し、このカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の奇石－ドラガイト",h:"あだましあ・くりすた－どらがいと",e:"Adamancipator Crystal - Dragite",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「アダマシア」カードの効果で特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：このカードが墓地に存在する場合、自分のフィールド・墓地の水属性Sモンスター１体を対象として発動できる。\nそのモンスターを持ち主のEXデッキに戻し、このカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の奇跡－レオナイト",h:"あだましあ・らいず－れおないと",e:"Adamancipator Risen - Leonite",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"岩石族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から「アダマシア」カード１枚を選んで手札に加える事ができる。\n残りのカードは好きな順番でデッキの一番下に戻す。\n②：相手ターンに、自分の墓地に炎属性モンスターが存在する場合、自分の墓地の岩石族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の奇跡－ラプタイト",h:"あだましあ・らいず－らぷたいと",e:"Adamancipator Risen - Raptite",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"岩石族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から岩石族モンスター１体を選んで守備表示で特殊召喚できる。\n残りのカードは好きな順番でデッキの一番下に戻す。\n②：相手ターンに、自分の墓地に風属性モンスターが存在する場合、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の奇跡－ドラガイト",h:"あだましあ・らいず－どらがいと",e:"Adamancipator Risen - Dragite",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"岩石族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中の岩石族モンスターの数まで相手フィールドのカードを選んで持ち主の手札に戻す事ができる。\nめくったカードは好きな順番でデッキの一番下に戻す。\n②：自分の墓地に水属性モンスターが存在し、相手が魔法・罠カードの効果を発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アダマシア・ラピュタイト",h:"あだましあ・らぴゅたいと",e:"Adamancipator Laputite",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの岩石族モンスターの攻撃力・守備力は５００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「アダマシア」カードを５枚まで選び、好きな順番でデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の息吹",h:"あだましあ・さいん",e:"Adamancipator Signs",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の岩石族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で「アダマシア」モンスターを特殊召喚した場合には、さらにデッキからレベル４以下の岩石族モンスター１体を選んでデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の救砕",h:"あだましあ・りりーぶ",e:"Adamancipator Relief",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「アダマシア」モンスターを任意の数だけリリースし、その数＋１枚だけフィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救共振撃",h:"あだましあ・れぞなんす",e:"Adamancipator Resonance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「アダマシア」Sモンスターが存在し、モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"六花のひとひら",h:"りっかのひとひら",e:"Rikka Petal",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキから「六花のひとひら」以外の「六花」モンスター１体を選び、手札に加えるか墓地へ送る。\nこの効果の発動後、ターン終了時まで自分は植物族モンスターしか特殊召喚できない。\n②：このカードが墓地に存在し、自分フィールドにモンスターが存在しない場合、または自分フィールドのモンスターが植物族モンスターのみの場合、相手エンドフェイズに発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"六花精プリム",h:"りっかせいぷりむ",e:"Primula the Rikka Fairy",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターがリリースされた場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分フィールドの植物族モンスターを２体まで対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ上げる。",li:"",lk:[],ta:[],al:[]},
  {n:"六花精シクラン",h:"りっかせいしくらん",e:"Cyclamen the Rikka Fairy",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札・フィールドのこのカードをリリースし、自分フィールドの植物族モンスターを２体まで対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ下げる。\n②：このカードがリリースされ墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"六花精ボタン",h:"りっかせいぼたん",e:"Mudan the Rikka Fairy",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの植物族モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚または植物族モンスターの効果で特殊召喚に成功した場合に発動できる。\nデッキから「六花」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"六花精エリカ",h:"りっかせいえりか",e:"Erica the Rikka Fairy",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の自分の植物族モンスターが戦闘を行う攻撃宣言時に、手札・フィールドのこのカードをリリースして発動できる。\nその自分のモンスターの攻撃力・守備力はターン終了時まで１０００アップする。\n②：このカードが墓地に存在する状態で、自分フィールドの植物族モンスターがリリースされた場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"六花精スノードロップ",h:"りっかせいすのーどろっぷ",e:"Snowdrop the Rikka Fairy",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの植物族モンスター１体をリリースして発動できる。\nこのカードと植物族モンスター１体を手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は植物族モンスターしか特殊召喚できない。\n②：自分フィールドの植物族モンスター１体を対象として発動できる。\n自分フィールドの全ての植物族モンスターのレベルはターン終了時まで対象のモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"六花精ヘレボラス",h:"りっかせいへれぼらす",e:"Hellebore the Rikka Fairy",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2600,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「六花」モンスターが存在し、自分フィールドのモンスターを対象とするモンスターの効果を相手が発動した時、手札・フィールドのこのカードをリリースして発動できる。\nその効果を無効にする。\n②：このカードが墓地に存在する場合、自分フィールドの植物族モンスター１体をリリースして発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"六花聖カンザシ",h:"りっかせいかんざし",e:"Kanzashi the Rikka Queen",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2400,tx:"レベル６モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：モンスターがリリースされた場合、このカードのX素材を１つ取り除き、自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは効果が無効化され、植物族になる。\n②：自分フィールドの植物族モンスターが効果で破壊される場合、代わりに自分の手札・フィールドの植物族モンスター１体をリリースできる。",li:"",lk:[],ta:[],al:[]},
  {n:"六花聖ティアドロップ",h:"りっかせいてぃあどろっぷ",e:"Teardrop the Rikka Queen",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2800,tx:"レベル８モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、自分・相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターをリリースする。\nこのカードが植物族モンスターをX素材としている場合、この効果は相手ターンでも発動できる。\n②：モンスターがリリースされる度に発動する。\nこのカードの攻撃力はターン終了時まで、リリースされたモンスターの数×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"六花絢爛",h:"りっかけんらん",e:"Rikka Glamour",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\nこのカードは自分フィールドの植物族モンスター１体をリリースして発動する事もできる。\n①：デッキから「六花」モンスター１体を手札に加える。\nモンスターをリリースしてこのカードを発動した場合、さらに手札に加えたモンスターとはカード名が異なり、元々のレベルが同じ植物族モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"六花の風花",h:"りっかのかざはな",e:"Rikka Flurries",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「六花」モンスターがリリースされた場合に発動できる。\n相手は自身のフィールドのモンスター１体をリリースしなければならない。\n②：相手エンドフェイズに、植物族モンスター以外の表側表示モンスターが自分フィールドに存在する場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"六花深々",h:"りっかしんしん",e:"Rikka Tranquility",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\nこのカードは自分フィールドの植物族モンスター１体をリリースして発動する事もできる。\n①：自分の墓地から「六花」モンスター１体を選んで守備表示で特殊召喚する。\nモンスターをリリースしてこのカードを発動した場合、さらに自分の墓地から植物族モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"六花の薄氷",h:"りっかのうすらい",e:"Rikka Sheet",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\nこのカードは自分フィールドの植物族モンスター１体をリリースして発動する事もできる。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターはフィールドで発動する効果を発動できない。\nモンスターをリリースしてこのカードを発動した場合、さらにそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターは植物族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金卿エルドリッチ",h:"おうごんきょうえるどりっち",e:"Eldlich the Golden Lord",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2500,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードと魔法・罠カード１枚を墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを墓地へ送る。\n②：このカードが墓地に存在する場合、自分フィールドの魔法・罠カード１枚を墓地へ送って発動できる。\nこのカードを手札に加える。\nその後、手札からアンデット族モンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターは相手ターン終了時まで、攻撃力・守備力が１０００アップし、効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"呪われしエルドランド",h:"のろわれしえるどらんど",e:"Cursed Eldland",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分はアンデット族モンスターでしか攻撃宣言できない。\n②：８００LPを払って発動できる。\nデッキから「エルドリッチ」モンスター１体または「黄金郷」魔法・罠カード１枚を手札に加える。\n③：このカードが魔法＆罠ゾーンから墓地へ送られた場合に発動できる。\nデッキから「エルドリッチ」モンスター１体または「黄金郷」魔法・罠カード１枚を墓地へ送る。",li:2,lk:[],ta:[],al:[]},
  {n:"黒き覚醒のエルドリクシル",h:"くろきめざめのえるどりくしる",e:"Eldlixir of Black Awakening",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札・デッキからアンデット族モンスター１体を守備表示で特殊召喚する。\n自分フィールドに「エルドリッチ」モンスターが存在しない場合には、この効果で「エルドリッチ」モンスターしか特殊召喚できない。\nこのカードの発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「黄金郷」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"白き宿命のエルドリクシル",h:"しろきさだめのえるどりくしる",e:"Eldlixir of White Destiny",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の手札・墓地からアンデット族モンスター１体を選んで特殊召喚する。\n自分フィールドに「エルドリッチ」モンスターが存在しない場合には、この効果で「エルドリッチ」モンスターしか特殊召喚できない。\nこのカードの発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「黄金郷」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"紅き血染めのエルドリクシル",h:"あかきちぞめのえるどりくしる",e:"Eldlixir of Scarlet Sanguine",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分のデッキ・墓地からアンデット族モンスター１体を選んで特殊召喚する。\n自分フィールドに「エルドリッチ」モンスターが存在しない場合には、この効果で「エルドリッチ」モンスターしか特殊召喚できない。\nこのカードの発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「黄金郷」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金郷のガーディアン",h:"おうごんきょうのがーでぃあん",e:"Guardian of the Golden Land",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードは発動後、通常モンスター（アンデット族・光・星８・攻８００／守２５００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n自分フィールドに「黄金卿エルドリッチ」が存在する場合、さらにフィールドの表側表示モンスター１体を選んで攻撃力を０にできる。\n②：自分・相手のエンドフェイズに墓地のこのカードを除外して発動できる。\nデッキから「エルドリクシル」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金郷のワッケーロ",h:"おうごんきょうのわっけーろ",e:"Huaquero of the Golden Land",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードは発動後、通常モンスター（アンデット族・光・星５・攻１８００／守１５００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n自分フィールドに「黄金卿エルドリッチ」が存在する場合、さらに自分・相手の墓地のカード１枚を選んで除外できる。\n②：自分・相手のエンドフェイズに墓地のこのカードを除外して発動できる。\nデッキから「エルドリクシル」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金郷のコンキスタドール",h:"おうごんきょうのこんきすたどーる",e:"Conquistador of the Golden Land",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードは発動後、通常モンスター（アンデット族・光・星５・攻５００／守１８００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n自分フィールドに「黄金卿エルドリッチ」が存在する場合、さらにフィールドの表側表示のカード１枚を選んで破壊できる。\n②：自分・相手のエンドフェイズに墓地のこのカードを除外して発動できる。\nデッキから「エルドリクシル」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"永久に輝けし黄金郷",h:"とわにかがやけしおうごんきょう",e:"Golden Land Forever!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「エルドリッチ」モンスターが存在し、モンスターの効果・魔法・罠カードが発動した時、自分フィールドのアンデット族モンスター１体をリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金の征服王",h:"える・どらど・あでらんたーど",e:"El Dorado Adelantado",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「エルドリッチ」モンスターが存在する場合、以下の効果から１つを選択して発動できる。\n●除外されている自分の「エルドリクシル」魔法・罠カード３種類を１枚ずつ選んでデッキに戻し、フィールドのカードを全て破壊する。\n●除外されている自分の「黄金郷」魔法・罠カード３種類を１枚ずつ選んでデッキに戻し、相手のLPを半分にする。\nその後、相手のLPの数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマード・ビットロン",h:"あーまーど・びっとろん",e:"Armored Bitron",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nデッキから「アーマード・ビットロン」以外のサイバース族モンスター１体を効果を無効にして特殊召喚する。\nこのターン、自分はサイバース族モンスターしか特殊召喚できない。\n②：このカードが墓地に存在し、自分フィールドのリンク３以上のリンクモンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"グッサリ＠イグニスター",h:"ぐっさりあっといぐにすたー",e:"Gussari @Ignister",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：リンクモンスターが戦闘で破壊された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分のリンクモンスターが戦闘で相手モンスターを破壊した時に発動できる。\nその相手モンスターの元々の攻撃力分のダメージを相手に与える。\n③：自分のリンクモンスターが相手モンスターと戦闘を行う攻撃宣言時に、墓地のこのカードを除外して発動できる。\nそのお互いのモンスターの攻撃力は３０００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガッチリ＠イグニスター",h:"がっちりあっといぐにすたー",e:"Gatchiri @Ignister",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:3000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族の効果モンスター１体を対象として発動できる。\nそのモンスターの効果を無効にし、このカードを手札から特殊召喚する。\n②：自分フィールドのサイバース族モンスターはそれぞれ１ターンに１度だけ効果では破壊されない。\n③：このカードがフィールドから墓地へ送られた場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分の表側表示モンスターは相手ターン終了時まで相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻魔皇ラビエル－天界蹂躙拳",h:"げんまおうらびえる－てんかいじゅうりんけん",e:"Raviel, Lord of Phantasms - Shimmering Scraper",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分フィールドの「幻魔皇ラビエル」１体を対象として発動できる。\nこのターン、そのモンスターの攻撃力は倍になり、相手モンスター全てに１回ずつ攻撃できる。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・コア",h:"かおす・こあ",e:"Chaos Core",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが効果の対象になった時、または相手モンスターの攻撃対象に選択された時に発動できる。\n「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」をそれぞれ１体まで手札・デッキから墓地へ送り、墓地へ送った数だけこのカードに幻魔カウンターを置き、このターン自分が受ける戦闘ダメージは０になる。\n②：このカードが戦闘・効果で破壊される場合、代わりにこのカードの幻魔カウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒の招来神",h:"あんこくのしょうらいしん",e:"Dark Beckoning Beast",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれか１体、またはそのいずれかのカード名が記された、「暗黒の招来神」以外のカード１枚をデッキから手札に加える。\n②：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに攻撃力と守備力が０の悪魔族モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の召喚神",h:"こんとんのしょうかんしん",e:"Chaos Summoning Beast",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれか１体を手札から召喚条件を無視して特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\nデッキから「失楽園」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"失楽の霹靂",h:"しつらくのへきれき",e:"Cerulean Skyfire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「降雷皇ハモン」を自身の方法で特殊召喚する場合、自分フィールドの裏側表示の魔法カードを墓地へ送る事もできる。\n②：１ターンに１度、自分フィールドに「降雷皇ハモン」が攻撃表示で存在する場合、相手が発動した魔法・罠カードの効果を無効にできる。\nその後、自分フィールドの「降雷皇ハモン」１体を選んで守備表示にする。\n③：自分フィールドの表側表示の「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれかがフィールドから離れた場合に発動する。\nこのターン、自分が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"七精の解門",h:"しちせいのかいもん",e:"Opening of the Spirit Gates",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれか１体、またはそのいずれかのカード名が記されたモンスター１体をデッキから手札に加える。\n②：１ターンに１度、手札を１枚捨てて発動できる。\n自分の墓地から攻撃力と守備力が０の悪魔族モンスター１体を選んで特殊召喚する。\n③：１ターンに１度、自分フィールドにレベル１０モンスターが存在する場合に発動できる。\n自分の墓地から永続魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイパーブレイズ",h:"はいぱーぶれいず",e:"Hyper Blaze",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「神炎皇ウリア」を自身の方法で特殊召喚する場合、自分フィールドの裏側表示の罠カードを墓地へ送る事もできる。\n②：自分の「神炎皇ウリア」が戦闘を行う攻撃宣言時に１度、手札・デッキから罠カード１枚を墓地へ送って発動できる。\nこのターン、そのモンスターの攻撃力・守備力はお互いのフィールド・墓地の罠カードの数×１０００になる。\n③：１ターンに１度、手札を１枚捨てて発動できる。\n「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれか１体を自分の墓地から選び、手札に加えるか召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"覚醒の三幻魔",h:"かくせいのさんげんま",e:"Awakening of the Sacred Beasts",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」の種類の数によって以下の効果を得る。\n\n●１種類以上：相手がモンスターの召喚・特殊召喚に成功する度に、自分はそのモンスターの攻撃力分だけLPを回復する。\n\n●２種類以上：相手フィールドのモンスターが発動した効果は無効化される。\n\n●３種類：相手の墓地へ送られるモンスターは墓地へは行かず除外される。\n\n②：自分ターンに１度、自分フィールドにレベル１０モンスターが存在する場合に発動できる。\n自分の墓地から永続罠カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"次元融合殺",h:"じげんゆうごうさつ",e:"Dimension Fusion Destruction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールド・墓地から、「幻魔」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n自分フィールドに「神炎皇ウリア」「降雷皇ハモン」「幻魔皇ラビエル」のいずれかが存在する場合、このカードの発動に対して相手は効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"リセの蟲惑魔",h:"りせのこわくま",e:"Traptrix Genlisea",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。\n②：このカードをリリースして発動できる。\n自分のデッキ及び墓地から１枚ずつ、「ホール」通常罠カードまたは「落とし穴」通常罠カードを選んで自分フィールドにセットする（同名カードは１枚まで）。\nこの効果でセットしたカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"龍馬躓図",h:"りゅうめつまずきのず",e:"Hollow Giants",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"EXデッキから特殊召喚されたモンスターがお互いのフィールドに存在する場合にこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、EXデッキから特殊召喚された表側表示モンスターの効果は無効化される。\n②：EXデッキから特殊召喚されたモンスター同士の戦闘でモンスターが破壊された場合に発動する。\nこのカードは墓地へ送られ、その戦闘で破壊されたモンスターのコントローラーは１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"魔妖遊行",h:"まやかしゆうこう",e:"Mayakashi Mayhem",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は同一チェーン上では１度しか発動できない。\n①：EXデッキ以外からアンデット族Sモンスターが特殊召喚された場合に発動できる（ダメージステップでも発動可能）。\n以下の効果から１つを選んで適用する。\nこのターン、自分の「魔妖遊行」の効果で同じ効果を適用できない。\n\n●自分はデッキから１枚ドローする。\n\n●デッキから「魔妖遊行」以外の「魔妖」魔法・罠カード１枚を選んで自分フィールドにセットする。\n\n●相手フィールドの攻撃力が一番低いモンスター１体を墓地へ送る。\n\n●相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"SRブロックンロール",h:"すぴーどろいどぶろっくんろーる",e:"Speedroid Block-n-Roll",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがS素材として墓地へ送られた場合に発動できる。\nこのカードをS素材としたSモンスターの元々のレベルと同じレベルを持つ「SRトークン」（機械族・風・攻／守０）１体を自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"SRカールターボ",h:"すぴーどろいどかーるたーぼ",e:"Speedroid CarTurbo",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに風属性モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は風属性以外のモンスターの効果を発動できない。\n②：自分の墓地からこのカードと「スピードロイド」モンスター１体を除外して発動できる。\n自分フィールドの全ての風属性モンスターの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"DDドッグ",h:"でぃーでぃーどっぐ",e:"D/D Dog",t:"monster",a:"闇",l:6,ps:3,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：相手フィールドの融合・S・Xモンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nその後、Pゾーンのこのカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：１ターンに１度、相手が融合・S・Xモンスターの特殊召喚に成功した場合、そのモンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターは攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・メタルクランチ",h:"ましんなーず・めたるくらんち",e:"Machina Metalcruncher",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:0,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドに表側表示のカードが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚したこのカードの元々の攻撃力は１８００になる。\n③：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから機械族・地属性モンスター３体を相手に見せ、相手はその中からランダムに１体選ぶ。\nそのモンスター１体を自分の手札に加え、残りのモンスターはデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺獣－鷲々",h:"でんのうかいじゅう－じゅじゅ",e:"Virtual World Beast - Jiujiu",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1700,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：元々の種族・属性が同じでカード名が異なるモンスターが自分の墓地に２体以上存在する限り、フィールドのこのカードは戦闘・効果では破壊されない。\n②：元々の種族・属性が同じでカード名が異なるモンスター２体を自分の墓地から除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺龍－龍々",h:"でんのうかいりゅう－ろんろん",e:"Virtual World Dragon - Longlong",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"同じ種族・属性のレベル３モンスター×２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：X素材を持ったこのカードは相手の効果の対象にならない。\n②：相手フィールドに表側表示モンスターが存在し、相手が自身のフィールドに存在しない属性のモンスターの効果を発動した時、このカードのX素材を２つ取り除いて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺姫－娘々",h:"でんのうかいき－にゃんにゃん",e:"Virtual World Hime - Nyannyan",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドにレベル３モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードはチューナーとして扱い、フィールドから離れた場合に除外される。\nこのターン、自分はレベルまたはランクが３以上のモンスターしか特殊召喚できない。\n②：このカードが除外された場合、このカード以外の除外されている自分のカード１枚を対象として発動できる。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"魔道騎士ガイア",h:"まどうきしがいあ",e:"Gaia the Magical Knight",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"このカード名はルール上「暗黒騎士ガイア」カードとしても扱う。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、または相手フィールドに攻撃力２３００以上のモンスターが存在する場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・墓地からドラゴン族・レベル５モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"呪われし竜－カース・オブ・ドラゴン",h:"のろわれしりゅう－かーす・おぶ・どらごん",e:"Curse of Dragon, the Cursed Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「竜騎士ガイア」のカード名が記された魔法・罠カード１枚を手札に加える。\n②：このカードが墓地へ送られた場合、自分フィールドの「竜騎士ガイア」１体を対象として発動できる。\nそのモンスターの攻撃力以下の攻撃力を持つ、相手フィールドの全ての表側表示モンスターの効果はターン終了時まで無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"砲撃のカタパルト・タートル",h:"ほうげきのかたぱると・たーとる",e:"Artillery Catapult Turtle",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースして発動できる。\n手札・デッキから「暗黒騎士ガイア」モンスターまたはドラゴン族・レベル５モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒騎士ガイアソルジャー",h:"あんこくきしがいあそるじゃー",e:"Soldier Gaia the Fierce Knight",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2100,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのドラゴン族の融合モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが特殊召喚に成功した場合、フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にする。\n③：このカードをリリースして発動できる。\nデッキから「暗黒騎士ガイアソルジャー」以外のレベル７以上の戦士族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"教導の聖女エクレシア",h:"どらぐまのせいじょえくれしあ",e:"Dogmatika Ecclesia, the Virtuous",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚されたモンスターがフィールドに存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「教導の聖女エクレシア」以外の「ドラグマ」カード１枚を手札に加える。\nこの効果の発動後、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。\n③：このカードはEXデッキから特殊召喚されたモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"教導の鉄槌テオ",h:"どらぐまのてっついてお",e:"Dogmatika Theo, the Iron Punch",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚されたモンスターがフィールドに存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードはEXデッキから特殊召喚されたモンスターとの戦闘では破壊されない。\n③：EXデッキから特殊召喚されたフィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、このカードの攻撃力は６００アップし、対象のモンスターの攻撃力は６００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"教導の天啓アディン",h:"どらぐまのてんけいあでぃん",e:"Dogmatika Adin, the Enlightened",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚されたモンスターがフィールドに存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードはEXデッキから特殊召喚されたモンスターとの戦闘では破壊されない。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「教導の天啓アディン」以外の「ドラグマ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"教導の騎士フルルドリス",h:"どらぐまのきしふるるどりす",e:"Dogmatika Fleurdelis, the Knighted",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚されたモンスターがフィールドに存在する場合、自分・相手のメインフェイズに発動できる。\nこのカードを手札から特殊召喚する。\n自分フィールドに他の「ドラグマ」モンスターが存在する場合、さらにフィールドの表側表示モンスター１体を選んでその効果をターン終了時まで無効にできる。\n②：自分の「ドラグマ」モンスターの攻撃宣言時に発動できる。\n自分フィールドの全ての「ドラグマ」モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"教導の大神祇官",h:"まくしむす・どらぐま",e:"Dogmatika Maximus",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:3000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から融合・S・X・リンクモンスター１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分のEXデッキからカード名が異なるモンスター２体を墓地へ送る。\n相手は自身のEXデッキからモンスター２体を墓地へ送る。\nこの効果の発動後、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"教導枢機テトラドラグマ",h:"きょうどうすうきてとらどらぐま",e:"Dogmatika Nexus",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3200,de:3200,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：自分・相手の墓地の融合・S・X・リンクモンスターを合計４体対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターを除外する。\n②：特殊召喚されたモンスターとこのカードが戦闘を行うダメージステップ開始時に発動する。\n相手フィールドの攻撃表示モンスターを全て破壊する。\nその後、この効果で破壊した融合・S・X・リンクモンスターの数×８００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"アルバスの落胤",h:"あるばすのらくいん",e:"Fallen of Albaz",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札を１枚捨てて発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分・相手フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で融合召喚する場合、このカード以外の自分フィールドのモンスターを融合素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士－アストルフォ",h:"えんせいきし－あすとるふぉ",e:"Infernoble Knight Astolfo",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の①②の効果はそれぞれデュエル中に１度しか使用できない。\n①：自分の手札・墓地から戦士族・炎属性モンスター１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\nその後、このカードのレベルを除外したモンスターと同じにできる。\n②：墓地のこのカードを除外して発動できる。\n発動後２回目の自分スタンバイフェイズに、除外されているこのカードを特殊召喚する。\nその後、自分の墓地のモンスター及び除外されている自分のモンスターの中から、戦士族・炎属性モンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士－オジエ",h:"えんせいきし－おじえ",e:"Infernoble Knight Ogier",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「焔聖騎士－オジエ」以外の戦士族・炎属性モンスター１体または「聖剣」カード１枚を墓地へ送る。\n②：このカードが墓地に存在する場合、自分フィールドの戦士族モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその自分のモンスターに装備する。\n③：このカードの装備モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士－オリヴィエ",h:"えんせいきし－おりう゛ぃえ",e:"Infernoble Knight Oliver",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札及び自分フィールドの表側表示のカードの中から、戦士族・炎属性モンスター１体または装備魔法カード１枚を墓地へ送って発動できる。\nこのカードを手札からレベル１モンスターとして特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドの戦士族モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその自分のモンスターに装備する。\n③：このカードの装備モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士－モージ",h:"えんせいきし－もーじ",e:"Infernoble Knight Maugis",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、自分の墓地のカード及び除外されている自分のカードの中から、このカード以外の戦士族・炎属性モンスターまたは「聖剣」カードを合計３枚デッキに戻して発動できる。\n自分はデッキから１枚ドローする。\n②：このカードが墓地に存在する場合、自分フィールドの戦士族モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその自分のモンスターに装備する。\n③：このカードの装備モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィー・ラビィ",h:"めるふぃー・らびぃ",e:"Melffy Rabby",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"ラビィはこの切り株がいちばんのお気に入り。\nいつも登ってまわりをきょろきょろ。\n\n\nメルフィーの森には個性豊かでかわいい動物たちがいっぱい。\n\n切り株を見つけるとついつい座りたくなっちゃうそこのあなた！\nさぁ、ラビィといっしょにメルフィーのお友達を探しに行きましょう♪",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィー・フェニィ",h:"めるふぃー・ふぇにぃ",e:"Melffy Fenny",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合、またはこのカードが相手モンスターの攻撃対象に選択された場合に発動できる。\nこのカードを持ち主の手札に戻す。\nその後、手札から「メルフィー・フェニィ」以外の獣族モンスター１体を特殊召喚できる。\n②：自分エンドフェイズに発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィー・キャシィ",h:"めるふぃー・きゃしぃ",e:"Melffy Catty",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合、またはこのカードが相手モンスターの攻撃対象に選択された場合に発動できる。\nこのカードを持ち主の手札に戻す。\nその後、デッキから「メルフィー・キャシィ」以外の獣族モンスター１体を手札に加える事ができる。\n②：自分エンドフェイズに発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィー・パピィ",h:"めるふぃー・ぱぴぃ",e:"Melffy Puppy",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合、またはこのカードが相手モンスターの攻撃対象に選択された場合に発動できる。\nこのカードを持ち主の手札に戻す。\nその後、デッキから「メルフィー・パピィ」以外のレベル２以下の獣族モンスター１体を特殊召喚できる。\n②：自分エンドフェイズに発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィー・ポニィ",h:"めるふぃー・ぽにぃ",e:"Melffy Pony",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:400,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合、またはこのカードが相手モンスターの攻撃対象に選択された場合に発動できる。\nこのカードを持ち主の手札に戻す。\nその後、自分の墓地から「メルフィー・ポニィ」以外のレベル２以下の獣族モンスター１体を選んで手札に加える事ができる。\n②：自分エンドフェイズに発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ファーニマル・ドルフィン",h:"ふぁーにまる・どるふぃん",e:"Fluffal Dolphin",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「トイポット」１枚を対象として発動できる。\nそのカードを自分フィールドにセットし、デッキから「エッジインプ・シザー」１体または「ファーニマル」モンスター１体を墓地へ送る。\n②：このカードが融合召喚の素材となって墓地へ送られた場合に発動できる。\n自分の墓地のカード及び除外されている自分のカードの中から、「融合」魔法カードまたは「フュージョン」魔法カード１枚を選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"エッジインプ・サイズ",h:"えっじいんぷ・さいず",e:"Edge Imp Scythe",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手メインフェイズに、手札のこのカードを相手に見せて発動できる。\n自分の手札・フィールドから、「デストーイ」融合モンスターカードによって決められた手札のこのカードを含む融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：自分フィールドの「デストーイ」融合モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"享楽の堕天使",h:"きょうらくのだてんし",e:"Capricious Darklord",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\n天使族モンスター１体を表側表示でアドバンス召喚する。\n②：このカードが墓地へ送られた場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力・守備力はターン終了時まで、フィールドの天使族モンスターの数×５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"悦楽の堕天使",h:"えつらくのだてんし",e:"Indulged Darklord",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから「悦楽の堕天使」以外の、レベルが異なる「堕天使」モンスター２体を選び、その内の１体を相手フィールドに守備表示で特殊召喚し、もう１体を自分の手札に加える。\nこの効果の発動後、ターン終了時まで自分は天使族以外のモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"堕天使ネルガル",h:"だてんしねるがる",e:"Darklord Nergal",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2700,de:2500,tx:"自分は「堕天使ネルガル」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：自分の天使族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・リザーブレイク",h:"ましんなーず・りざーぶれいく",e:"Machina Resavenger",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札・フィールドのこのカードを墓地へ送り、自分フィールドの「マシンナーズ」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１２００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在し、自分の「マシンナーズ」モンスターが戦闘で相手モンスターを破壊した時に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"奇動装置メイルファクター",h:"きどうそうちめいるふぁくたー",e:"Unauthorized Bootup Device",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：モンスターに装備されているこのカードが破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"斬機ダイア",h:"ざんきだいあ",e:"Mathmech Diameter",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地のサイバース族・レベル４モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは効果を発動できない。\n②：フィールドのこのカードを素材としてS・X召喚した「斬機」モンスターは以下の効果を得る。\n●このカードが特殊召喚に成功したターンに１度、相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ネメシス・キーストーン",h:"ねめしす・きーすとーん",e:"Nemeses Keystone",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:700,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネメシス・キーストーン」以外の除外されている自分のモンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターをデッキに戻す。\n②：このカードが除外されたターンのエンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"コアキメイル・サプライヤー",h:"こあきめいる・さぷらいやー",e:"Koa'ki Meiru Supplier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の岩石族モンスターが墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「コアキメイル・サプライヤー」以外の「コアキメイルの鋼核」のカード名が記されたカードまたは「コアキメイルの鋼核」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ハンド",h:"さんだー・はんど",e:"Thunder Hand",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、元々の攻撃力または元々の守備力が１６００の自分フィールドの表側表示モンスターが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚し、相手フィールドのカード１枚を選んで破壊する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧辰－高闇御津羽靇",h:"きこうしん－たかくらみつはのおかみ",e:"Gizmek Okami, the Dreaded Deluge Dragon",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2950,de:2950,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚されたモンスターがフィールドに２体以上存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：１５００LPを払って発動できる。\nEXデッキから特殊召喚されたフィールドのモンスターを全て破壊する。\nこのターン、自分はモンスター１体でしか攻撃できない。\n③：このカードが相手によって墓地へ送られた場合に発動できる。\n相手の墓地のモンスター１体を選んで除外する。\nその後、その攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"揺海魚デッドリーフ",h:"ようかいぎょでっどりーふ",e:"Lifeless Leaffish",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nデッキから「揺海魚デッドリーフ」以外の魚族モンスター１体を墓地へ送る。\n②：墓地のこのカードを除外し、自分の墓地の魚族モンスター３体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"レッドポータン",h:"れっどぽーたん",e:"Red Potan",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「ポータン」モンスター１体を対象として発動できる。\nそのモンスターは次のターンの終了時までチューナーとして扱う。\n②：このカードが既にモンスターゾーンに存在する状態で、自分または相手がS召喚に成功した場合に発動できる。\n手札・デッキから「ポータン」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"龍狸燈",h:"どらくーん・らんぷ",e:"Dracoon Lamp",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"①：１ターンに１度、手札から幻竜族モンスター１体を捨てて発動できる。\nこのカードの守備力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：攻撃表示のこのカードが攻撃表示モンスターと戦闘を行うダメージ計算時に１度、発動できる。\nその戦闘ではお互いのモンスターの守備力を攻撃力として扱いダメージ計算を行う。",li:"",lk:[],ta:[],al:[]},
  {n:"メガリス・フール",h:"めがりす・ふーる",e:"Megalith Phul",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:500,de:2000,tx:"「メガリス」カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合、自分の墓地の儀式モンスター１体を対象として発動できる。\nこのカードのレベルをそのモンスターと同じにする。\nその後、対象のモンスターを手札に加える。\n②：自分・相手のメインフェイズに発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札・デッキから「メガリス」儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"竜魔道騎士ガイア",h:"りゅうまどうきしがいあ",e:"Gaia the Magical Knight of Dragons",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「暗黒騎士ガイア」モンスター＋レベル５ドラゴン族モンスター\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「竜騎士ガイア」として扱う。\n②：自分・相手のメインフェイズに、このカード以外のフィールドのカード１枚を対象として発動できる。\nこのカードの攻撃力を２６００ダウンし、対象のカードを破壊する。\n③：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nこのカードの攻撃力は２６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"灰燼竜バスタード",h:"かいじんりゅうばすたーど",e:"Titaniklad the Ash Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「アルバスの落胤」＋攻撃力２５００以上のモンスター\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードの融合素材としたモンスターの元々のレベルの合計×１００アップする。\n②：このカードが融合召喚に成功したターン、このカードはEXデッキから特殊召喚された他のモンスターが発動した効果を受けない。\n③：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「ドラグマ」モンスターまたは「アルバスの落胤」１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・クルーエル・ホエール",h:"ですとーい・くるーえる・ほえーる",e:"Frightfur Cruel Whale",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2400,tx:"「エッジインプ」モンスター＋「ファーニマル」モンスター\n①：このカードが融合召喚に成功した場合に発動できる。\n自分及び相手フィールドのカードを１枚ずつ選んで破壊する。\n②：１ターンに１度、自分フィールドの融合モンスター１体を対象として発動できる。\nデッキ・EXデッキから「デストーイ・クルーエル・ホエール」以外の「デストーイ」カード１枚を墓地へ送り、対象のモンスターの攻撃力はターン終了時まで、自身の元々の攻撃力の半分だけアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"黎明の堕天使ルシフェル",h:"れいめいのだてんしるしふぇる",e:"The First Darklord",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:4000,tx:"天使族・闇属性モンスター×３\nこのカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：「堕天使ルシフェル」を素材としてこのカードが融合召喚に成功した場合に発動できる。\n相手フィールドのカードを全て破壊する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの天使族モンスターは相手の効果の対象にならない。\n③：自分・相手のメインフェイズに１０００LPを払って発動できる。\n自分の手札・墓地から天使族モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士導－ローラン",h:"えんせいきしどう－ろーらん",e:"Infernoble Knight Captain Roland",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2000,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nこのターンのエンドフェイズに、デッキから装備魔法カード１枚を墓地へ送る。\nその後、デッキから戦士族モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、自分・相手のメインフェイズに、自分フィールドの戦士族モンスター１体を対象として発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとしてその自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士帝－シャルル",h:"えんせいきしてい－しゃるる",e:"Infernoble Knight Emperor Charles",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのモンスターに装備カードが装備された場合に発動できる（ダメージステップでも発動可能）。\nフィールドのカード１枚を選んで破壊する。\n②：自分・相手のエンドフェイズに発動できる。\n自分の手札・墓地から装備魔法カード１枚を選んでこのカードに装備する。\nその後、デッキから戦士族・炎属性モンスター１体を選んでこのカードに攻撃力５００アップの装備カード扱いとして装備できる。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌魔龍 カオス・ルーラー",h:"こんとんまりゅう　かおす・るーらー",e:"Chaos Ruler, the Chaotic Magical Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から光・闇属性モンスター１体を選んで手札に加える事ができる。\n残りのカードは墓地へ送る。\n②：このカード以外の光・闇属性モンスターを１体ずつ、自分の手札・墓地から除外して発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"森のメルフィーズ",h:"もりのめるふぃーず",e:"Melffy of the Forest",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:2000,tx:"レベル２モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nデッキから「メルフィー」カード１枚を手札に加える。\n②：このカード以外の自分フィールドの表側表示の「メルフィー」モンスターが自分の手札に戻った場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはフィールドに表側表示で存在する限り、攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィー・マミィ",h:"めるふぃー・まみぃ",e:"Melffy Mommy",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"獣族レベル２モンスター×２体以上\n①：自分・相手ターンに１度、発動できる。\n自分の手札・フィールドから獣族モンスター１体を選び、このカードの下に重ねてX素材とする。\n②：このカードが持っているX素材の数によって、このカードは以下の効果を得る。\n\n●３つ以上：このカードは戦闘では破壊されない。\n\n●４つ以上：このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n\n●５つ以上：このカードが攻撃表示モンスターと戦闘を行う攻撃宣言時に発動できる。\nその攻撃表示モンスターの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"六花聖ストレナエ",h:"りっかせいすとれなえ",e:"Rikka Queen Strenna",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、自分の墓地の、植物族モンスター１体または「六花」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：X素材を持ったこのカードがリリースされた場合に発動できる。\n自分のEXデッキ・墓地からランク５以上の植物族Xモンスター１体を選んで特殊召喚する。\nその後、このカードをそのモンスターの下に重ねてX素材にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"旋壊のヴェスペネイト",h:"せんかいのう゛ぇすぺねいと",e:"Drill Driver Vespenato",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル５モンスター×２\n「旋壊のヴェスペネイト」は１ターンに１度、自分フィールドのランク４のXモンスターの上に重ねてX召喚する事もできる。\nこのカードはX召喚されたターンにはX素材にできない。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：X召喚したこのカードが相手によって破壊された場合、自分の墓地のレベル５以下のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華盟将－双龍",h:"せんかめいしょう－そうりゅう",e:"Ancient Warriors Oath - Double Dragon Lords",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["リンク","効果"],ma:"",tp:"",at:1100,de:"-",tx:"風属性の「戦華」モンスターを含む獣戦士族モンスター２体\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「戦華」カード１枚を手札に加える。\n②：自分フィールドの「戦華」モンスターの攻撃力・守備力は５００アップする。\n③：自分の手札・フィールドからカード１枚を墓地へ送り、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"走破するガイア",h:"そうはするがいあ",e:"Galloping Gaia",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のモンスターゾーンに「竜騎士ガイア」が存在する限り、相手はバトルフェイズ中に効果を発動できない。\n②：以下の効果から１つを選択して発動できる。\n\n●手札の「暗黒騎士ガイア」モンスター１体を相手に見せて発動できる。\nデッキからドラゴン族・レベル５モンスター１体を手札に加える。\n\n●手札のドラゴン族・レベル５モンスター１体を相手に見せて発動できる。\nデッキから「暗黒騎士ガイア」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"螺旋融合",h:"すぱいらる・ふゅーじょん",e:"Spiral Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で「竜騎士ガイア」を特殊召喚した場合、そのモンスターは、攻撃力が２６００アップし、１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"教導国家ドラグマ",h:"きょうどうこっかどらぐま",e:"Dogmatika Nation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、自分フィールドの「ドラグマ」モンスターはEXデッキから特殊召喚されたモンスターの効果の対象にならない。\n②：自分の「ドラグマ」モンスターが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを破壊する。\n③：フィールドゾーンの表側表示のこのカードが相手の効果で破壊された場合に発動できる。\nお互いはそれぞれ自身のEXデッキからモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"天底の使徒",h:"てんていのしと",e:"Nadir Servant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：EXデッキからモンスター１体を墓地へ送る。\nその後、墓地へ送ったモンスターの攻撃力以下の攻撃力を持つ、「ドラグマ」モンスターまたは「アルバスの落胤」１体を自分のデッキ・墓地から選んで手札に加える。\nこのカードの発動後、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。",li:1,lk:[],ta:[],al:[]},
  {n:"『焔聖剣－デュランダル』",h:"『えんせいけん－でゅらんだる』",e:"Infernoble Arms - Durendal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが装備されている場合に発動できる。\nデッキからレベル５以下の戦士族・炎属性モンスター１体を手札に加える。\nその後、このカードを破壊する。\n②：装備モンスターが墓地へ送られた事でこのカードが墓地へ送られた場合、自分の墓地のレベル５以下の戦士族・炎属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は戦士族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"『焔聖剣－オートクレール』",h:"『えんせいけん－おーとくれーる』",e:"Infernoble Arms - Hauteclere",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが装備されている場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、自分はそのモンスターでしか攻撃宣言できず、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。\nその後、このカードを破壊する。\n②：装備モンスターが墓地へ送られた事でこのカードが墓地へ送られた場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"『焔聖剣－ジョワユーズ』",h:"『えんせいけん－じょわゆーず』",e:"Infernoble Arms - Joyeuse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが装備されている場合、自分の墓地の戦士族・炎属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、このカードを破壊する。\n②：装備モンスターが墓地へ送られた事でこのカードが墓地へ送られた場合に発動できる。\n手札から戦士族・炎属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィーのおいかけっこ",h:"めるふぃーのおいかけっこ",e:"Melffy Tag",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「メルフィー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィーのかくれんぼ",h:"めるふぃーのかくれんぼ",e:"Melffy Hide-and-Seek",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの獣族モンスターはそれぞれ１ターンに１度だけ効果では破壊されない。\n②：自分の墓地の獣族モンスター３体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"デストーイ・リペアー",h:"ですとーい・りぺあー",e:"Frightfur Repair",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地の「デストーイ」融合モンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。\nその後、自分の墓地から「ファーニマル」モンスターまたは「エッジインプ」モンスター１体を選んで特殊召喚できる。\n②：墓地のこのカードを除外して発動できる。\n手札から「ファーニマル」モンスターまたは「エッジインプ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"深海の都 マガラニカ",h:"しんかいのみやこ　まがらにか",e:"Magellanica, the Deep Sea City",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「海」として扱う。\n①：このカードの発動時の効果処理として、デッキから水属性モンスター１体を選んでデッキの一番上に置く事ができる。\n②：１ターンに１度、自分フィールドの水属性モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで１つまたは２つ上げる。\n③：１ターンに１度、自分メインフェイズに、自分が水属性Sモンスターの特殊召喚に成功した場合に発動できる。\n相手の手札を確認し、その中からカード１枚を選んでエンドフェイズまで表側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華史略－東南之風",h:"せんかしりゃく－とうなんのかぜ",e:"Ancient Warriors Saga - East-by-South Winds",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは発動後、２回目の自分スタンバイフェイズに墓地へ送られる。\n①：１ターンに１度、自分メインフェイズに発動できる。\nコイントスを１回行う。\n表だった場合、このカードを墓地へ送る。\n②：このカードが魔法＆罠ゾーンから墓地へ送られた場合に発動できる。\nこのターン自分の「戦華」カードの効果の発動に対して相手は効果を発動できず、自分フィールドの全ての「戦華」効果モンスターはターン終了時まで以下の効果を得る。\n●このカードの攻撃宣言時に発動できる。\n相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔救の奇縁",h:"あだましあ・ふれんず",e:"Adamancipator Friends",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの岩石族モンスターの数＋５枚を自分のデッキの上からめくる。\nその中からめくったカードの枚数以下のレベルを持つ岩石族モンスター１体を選んで手札に加える事ができる。\n残りのカードは好きな順番でデッキの一番下に戻す。\nこのカードの発動後、ターン終了時まで自分は岩石族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"三戦の才",h:"さんせんのさい",e:"Triple Tactics Talent",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このターンの自分メインフェイズに相手がモンスターの効果を発動している場合、以下の効果から１つを選択して発動できる。\n\n●自分はデッキから２枚ドローする。\n\n●相手フィールドのモンスター１体を選び、エンドフェイズまでコントロールを得る。\n\n●相手の手札を確認し、その中からカード１枚を選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ブリザード",h:"ぶりざーど",e:"Blizzard",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示の魔法カード１枚を対象として発動できる。\nこのターン、そのカード及びそのカードと元々のカード名が同じ魔法カードがフィールドで発動する効果は無効化される。\nこのターン中に対象のカードが相手の墓地へ送られる場合、墓地へは行かず相手の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"海竜神の激昂",h:"りばいあさんのげきこう",e:"Fury of Kairyu-Shin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「激流葬」１枚を手札に加える。\n②：自分フィールドの水属性モンスターが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"禁じられた一滴",h:"きんじられたひとしずく",e:"Forbidden Droplet",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、このカード以外のカードを任意の数だけ墓地へ送って発動できる。\nその数だけ相手フィールドの効果モンスターを選ぶ。\nそのモンスターはターン終了時まで、攻撃力が半分になり、効果は無効化される。\nこのカードの発動に対して、相手はこのカードを発動するために墓地へ送ったカードと元々の種類（モンスター・魔法・罠）が同じカードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"天幻の龍輪",h:"てんげんのりゅうりん",e:"Heavenly Dragon Circle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの幻竜族モンスター１体をリリースして発動できる。\nデッキから幻竜族モンスター１体を手札に加える。\n効果モンスター以外のモンスターをリリースしてこのカードを発動した場合、手札に加えず効果を無効にして特殊召喚する事もできる。\n②：自分フィールドに効果モンスター以外の表側表示モンスターが存在する場合、自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「天威」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"賽挑戦",h:"さいちょうせん",e:"Diced Dice",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：サイコロを１回振り、１・６が出た場合、サイコロを振る効果を持つカード１枚をデッキから手札に加える。\n１・６以外が出た場合、サイコロをもう１回振り、出た目によって以下の効果を適用する。\n\n●１・６：フィールドのこのカードを持ち主の手札に戻す。\n\n●２～５：フィールドのこのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"螺旋砲撃",h:"すぱいらる・でぃすちゃーじ",e:"Spiral Discharge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のモンスターゾーンに「竜騎士ガイア」が存在する限り、相手は「竜騎士ガイア」しか攻撃対象に選択できない。\n②：自分の「竜騎士ガイア」が戦闘を行う攻撃宣言時に、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"螺旋蘇生",h:"すぱいらる・りぼーん",e:"Spiral Reborn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベル７以下のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で「竜騎士ガイア」を特殊召喚した場合、そのモンスターは相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグマ・パニッシュメント",h:"どらぐま・ぱにっしゅめんと",e:"Dogmatika Punishment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力以上の攻撃力を持つモンスター１体を自分のEXデッキから墓地へ送り、対象のモンスターを破壊する。\nこのカードの発動後、次の自分ターンの終了時まで自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグマ・エンカウンター",h:"どらぐま・えんかうんたー",e:"Dogmatika Encounter",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●手札から「ドラグマ」モンスターまたは「アルバスの落胤」１体を特殊召喚する。\n\n●自分の墓地から「ドラグマ」モンスターまたは「アルバスの落胤」１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"オリファンの角笛",h:"おりふぁんのつのぶえ",e:"Horn of Olifant",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●自分のフィールド・墓地から装備魔法カード１枚を選んで除外する。\nその後、フィールドのカード１枚を選んで破壊できる。\n\n●自分フィールドの「ローラン」モンスター１体を選んで破壊する。\nその後、レベルの合計が９になるようにデッキから戦士族・炎属性モンスターを３体まで選んで効果を無効にして守備表示で特殊召喚する。\nこの効果の発動後、次の自分ターンの終了時まで自分は戦士族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メルフィータイム",h:"めるふぃーたいむ",e:"Melffy Playhouse",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの獣族XモンスターのX素材を任意の数だけ取り除き、その数まで相手フィールドのカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。\n自分フィールドの全ての獣族Xモンスターの攻撃力はターン終了時まで、このカードを発動するために取り除いたX素材の数×５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔玩具厄瓶",h:"ですとーい・ぽっと",e:"Frightfur Jar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「トイポット」として扱う。\n②：１ターンに１度、手札を１枚捨てて発動できる。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「エッジインプ」モンスターだった場合、フィールドのカード１枚を選んで破壊できる。\n違った場合、手札を１枚選んでデッキの一番上または一番下に戻す。\n③：このカードが墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力をターン終了時まで半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"叛逆の堕天使",h:"はんぎゃくのだてんし",e:"Darklord Uprising",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、「堕天使」モンスター１体を墓地へ送って発動できる。\n自分の手札・フィールドから、闇属性の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nその後、このカードを発動するために墓地へ送ったモンスターの攻撃力分だけ自分のLPを回復できる。",li:"",lk:[],ta:[],al:[]},
  {n:"影依の偽典",h:"しゃどーるーく",e:"Shaddoll Schism",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\n自分のフィールド・墓地から、「シャドール」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。\nその後、この効果で特殊召喚したモンスターと同じ属性を持つ相手フィールドのモンスター１体を選んで墓地へ送る事ができる。\nこの効果で特殊召喚したモンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンメイドのお片付け",h:"どらごんめいどのおかたづけ",e:"Dragonmaid Tidying",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのドラゴン族モンスター１体と相手のフィールド・墓地のカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：墓地のこのカードを除外して発動できる。\n自分の手札・墓地から「ドラゴンメイド」モンスター１体を選んで守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"リターナブル瓶",h:"りたーなぶるじゃー",e:"Redeemable Jar",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地から罠カード１枚を除外して発動できる。\nそのカードとは元々のカード名が異なる罠カード１枚を自分の墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天龍雪獄",h:"てんろうせつごく",e:"Ice Dragon's Prison",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして自分フィールドに特殊召喚する。\nその後、自分及び相手フィールドから、種族が同じとなるモンスターを１体ずつ選んで除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャンクスリープ",h:"じゃんくすりーぷ",e:"Junk Sleep",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合に発動できる。\n自分フィールドの裏側守備表示モンスターを全て表側攻撃表示にする。\n②：自分・相手のエンドフェイズに発動できる。\n自分フィールドのモンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"海霊賊",h:"ぱいれいつ",e:"Piwraithe the Ghost Pirate",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する状態で、「海霊賊」以外の自分フィールドの表側表示の水属性モンスターが戦闘・効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は次のターンの終了時まで、自分の墓地の水属性モンスターの数×１００アップする。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"バラガール",h:"ばらがーる",e:"Rose Girl",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示の植物族モンスターが墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在し、フィールドに植物族モンスターが存在する場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スクリプトン",h:"すくりぷとん",e:"Scrypton",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「スクリプトン」以外のサイバース族モンスター１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがサイバース族リンクモンスターのリンク素材として墓地へ送られた場合、除外されている自分または相手のモンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"トウテツドラゴン",h:"とうてつどらごん",e:"Taotie Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"幻竜族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"EXデッキから特殊召喚されたモンスター２体以上\n①：このカードは、このカードのリンク素材としたモンスターの種類によって以下の効果を得る。\n\n●融合：バトルフェイズ中に相手はモンスターの効果を発動できない。\n\n●S：自分メインフェイズ中に相手は魔法・罠カードの効果を発動できない。\n\n●X：自分のメインフェイズ及びバトルフェイズ中に相手は墓地のカードの効果を発動できない。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"優麗なる霊鏡",h:"ねくろいっぷ・ぷりずむ",e:"Necroquip Prism",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターのレベル以下のレベルを持つモンスター１体を手札から特殊召喚する。\nその後、対象のモンスターを装備カード扱いとしてその特殊召喚したモンスターに装備する。\nこの効果でモンスターを装備している限り、装備モンスターの攻撃力はこの効果で装備したモンスターの攻撃力の半分アップする。\nこのターン、自分はその装備カード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－青髭の海技士",h:"ぷらんどろーる－あおひげのかいぎし",e:"Bluebeard, the Plunder Patroll Shipwright",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「海造賊－青髭の海技士」以外の「海造賊」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札・モンスターゾーンから墓地へ送られた場合、手札を１枚捨てて発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－金髪の訓練生",h:"ぷらんどろーる－きんぱつのくんれんせい",e:"Goldenhair, the Newest Plunder Patroll",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の手札及び自分フィールドの表側表示のカードの中から、「海造賊」モンスターカード１枚を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在する場合、手札を１枚捨てて発動できる。\nこのカードを特殊召喚する。\nこのターン、自分は「海造賊」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－双翼のリュース号",h:"ぷらんどろーる－そうよくのりゅーすごう",e:"Plunder Patrollship Lys",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「海造賊」モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\n手札及び自分の魔法＆罠ゾーンの表側表示のカードの中から、「海造賊」モンスターカード１枚を選んで特殊召喚する。\n②：相手がモンスターの効果を発動した時、手札から「海造賊」カード１枚を捨てて発動できる。\nその発動を無効にし破壊する。\nこのカードが「海造賊」カードを装備している場合、さらにデッキから「海造賊」カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－進水式",h:"ぷらんどろーる－せれもにー",e:"Plunder Patroll Shipshape Ships Shipping",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、悪魔族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「海造賊」モンスター１体を対象として発動できる。\nその自分のモンスターに、デッキから「海造賊－象徴」１枚を装備するか、デッキから「海造賊」モンスター１体を装備カード扱いとして装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－象徴",h:"ぷらんどろーる－えんぶれむ",e:"Emblem of the Plunder Patroll",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「海造賊」モンスターにのみ装備可能。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力は５００アップし、相手の効果の対象にならない。\n②：装備されているこのカードを墓地へ送って発動できる。\nお互いのフィールド・墓地のモンスターのいずれかと同じ属性を持つ「海造賊」モンスター１体をEXデッキから特殊召喚する。\nその後、このカードを装備していたモンスターを、その特殊召喚したモンスターに攻撃力５００アップの装備カード扱いとして装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－誇示",h:"ぷらんどろーる－ぷらいど",e:"Pride of the Plunder Patroll",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「海造賊」モンスターが戦闘で相手モンスターを破壊した場合に発動する。\n自分はデッキから１枚ドローする。\n②：自分フィールドに「海造賊」モンスターが存在する場合、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、以下の効果から１つを選択して発動できる。\n\n●相手はデッキから１枚ドローする。\nその後、自分は相手の手札を確認し、その内のモンスター１体を選んで墓地へ送る。\n\n●相手のEXデッキを確認し、その内の１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－祝宴",h:"ぷらんどろーる－ふぃーすと",e:"Plunder Patroll Parrrty",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の、①の効果は１ターンに１度しか使用できず、②の効果はデュエル中に１度しか使用できない。\n①：自分フィールドに「海造賊」モンスターが存在する場合にこのカードを発動できる。\n自分は自分フィールドの装備カードの数＋１枚をデッキからドローし、その後手札を自分フィールドの装備カードの数だけ選んでデッキに戻す。\n②：このカードが墓地に存在し、自分がEXデッキから「海造賊」モンスターを特殊召喚した場合に発動できる。\nこのカードをそのモンスター１体に攻撃力５００アップの装備カード扱いとして装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"リアクター・スライム",h:"りあくたー・すらいむ",e:"Reactor Slime",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分フィールドに「スライムモンスタートークン」（水族・水・星１・攻／守５００）２体を特殊召喚する。\nこのターン、自分は幻神獣族モンスターしか召喚・特殊召喚できない。\n②：自分・相手のバトルフェイズにこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「メタル・リフレクト・スライム」１枚を選んで自分の魔法＆罠ゾーンにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・スライム",h:"がーでぃあん・すらいむ",e:"Guardian Slime",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nこのカードの守備力はそのダメージ計算時のみ、その相手モンスターの攻撃力分アップする。\n③：このカードが手札・フィールドから墓地へ送られた場合に発動できる。\nデッキから「ラーの翼神竜」のカード名が記された魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"神・スライム",h:"ごっど・すらいむ",e:"Egyptian God Slime",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"水族モンスター＋水属性・レベル１０モンスター\nこのカードは融合召喚及び以下の方法でのみEXデッキから特殊召喚できる。\n●自分フィールドの攻撃力０の水族・レベル１０モンスター１体をリリースした場合にEXデッキから特殊召喚できる。\n①：モンスターをアドバンス召喚する場合、このカードは３体分のリリースにできる。\n②：このカードは戦闘では破壊されず、相手は「神・スライム」以外の自分フィールドのモンスターを、攻撃対象に選択できず、効果の対象にもできない。",li:"",lk:[],ta:[],al:[]},
  {n:"古の呪文",h:"いにしえのじゅもん",e:"Ancient Chant",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキ・墓地から「ラーの翼神竜」１体を選んで手札に加え、このターン自分は通常召喚に加えて１度だけ、自分メインフェイズにアドバンス召喚できる。\n②：墓地のこのカードを除外して発動できる。\n発動後、このターン中に自分が「ラーの翼神竜」のアドバンス召喚に成功した場合、その元々の攻撃力・守備力は、そのアドバンス召喚のためにリリースしたモンスターの元々の攻撃力・守備力をそれぞれ合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴッド・ブレイズ・キャノン",h:"ごっど・ぶれいず・きゃのん",e:"Blaze Cannon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「ラーの翼神竜」１体を選ぶ。\nそのモンスターはターン終了時まで以下の効果を得る。\nこのカードの発動と効果は無効化されない。\n\n●このカードは相手の効果を受けない。\n\n●このカードが戦闘を行う攻撃宣言時に、このターン攻撃宣言をしていない自分フィールドの他のモンスターを任意の数だけリリースして発動できる。\nこのカードの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力の合計分アップする。\n\n●このカードが攻撃したダメージ計算後に発動できる。\n相手フィールドのモンスターを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"千年の啓示",h:"せんねんのけいじ",e:"Millennium Revelation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から幻神獣族モンスター１体を墓地へ送って発動できる。\n自分のデッキ・墓地から「死者蘇生」１枚を選んで手札に加える。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\nこのターン、自分の「死者蘇生」によって、自分の墓地の「ラーの翼神竜」を召喚条件を無視して特殊召喚できる。\nこの効果を発動したターンのエンドフェイズに、自分は「死者蘇生」の効果で特殊召喚された「ラーの翼神竜」を墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽神合一",h:"たいようしんごういつ",e:"Sun God Unification",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの①②の効果は同一チェーン上では発動できず、自分フィールドに元々のカード名が「ラーの翼神竜」となるモンスターが存在する場合、このカードはセットしたターンでも発動できる。\n①：自分・相手のメインフェイズに、１００LPになるようにLPを払って発動できる。\n自分フィールドの特殊召喚された「ラーの翼神竜」１体を選び、その攻撃力・守備力を払った数値分アップする。\n②：１ターンに１度、自分フィールドの「ラーの翼神竜」１体をリリースして発動できる。\nその攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇神龍トリスケリア",h:"きこうしんりゅうとりすけりあ",e:"Meklord Astro Dragon Triskelion",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:0,tx:"このカードは通常召喚できない。\n自分の墓地から「機皇」モンスター３種類を１体ずつ除外した場合に特殊召喚できる。\n①：１ターンに１度、このカードの攻撃宣言時に発動できる。\n相手のEXデッキを確認し、その内のモンスター１体を選んで装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：Sモンスターを装備したこのカードは１度のバトルフェイズ中に３回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇帝ワイゼル∞－S・アブソープション",h:"きこうていわいぜるいんふぃにてぃ－しんくろ・あぶそーぷしょん",e:"Meklord Emperor Wisel - Synchro Absorption",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：相手ターンに、自分フィールドの表側表示の「機皇」モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは攻撃できない。\n③：フィールドのカードを破壊する効果が発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇枢インフィニティ・コア",h:"きこうすういんふぃにてぃ・こあ",e:"Meklord Nucleus Infinity Core",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「機皇」魔法・罠カード１枚を手札に加える。\n②：このカードは１ターンに１度だけ戦闘では破壊されない。\n③：このカードが効果で破壊された場合に発動できる。\n同じ属性のモンスターが自分フィールドに存在しない「機皇帝」モンスター１体を手札・デッキから召喚条件を無視して特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスター１体でしか攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇兵廠オブリガード",h:"きこうへいしょうおぶりがーど",e:"Meklord Army Deployer Obbligato",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを破壊し、デッキから「機皇兵廠オブリガード」以外の「機皇兵」モンスター２体を守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族モンスターしか特殊召喚できない。\n②：このカードが墓地へ送られた場合に発動できる。\nこのターンのエンドフェイズに、自分フィールドの「機皇」モンスターの数×１００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇創出",h:"きこうそうしゅつ",e:"Meklord Assembly",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「機皇」モンスター１体を手札に加える事ができる。\n②：手札を１枚捨て、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：自分フィールドの表側表示の「機皇」モンスターが戦闘・効果で破壊された場合、このカード以外のフィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"機皇統制",h:"きこうとうせい",e:"Meklord Deflection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「機皇」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、自分フィールドの「機皇」モンスターの元々の攻撃力を合計した数値になり、ターン終了時までそのモンスターの戦闘で発生する相手への戦闘ダメージは０になる。\n②：自分フィールドの「機皇」モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"根絶の機皇神",h:"こんぜつのきこうしん",e:"Meklord Astro the Eradicator",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「機皇」モンスター３体を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを手札に加えるか召喚条件を無視して特殊召喚する。\nこのカードの発動後、次の自分ターンの終了時まで自分は機械族モンスターしか特殊召喚できない。\n②：自分フィールドに「機皇神」モンスターが存在する場合、墓地のこのカードを除外して発動できる。\n相手フィールドのSモンスター１体を選んで破壊し、その元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"脅威の人造人間－サイコ・ショッカー",h:"きょういのじんぞうにんげん－さいこ・しょっかー",e:"Jinzo the Machine Menace",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手のフィールド・墓地に罠カードが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードのレベルはターン終了時まで６になる。\n②：自分・相手のメインフェイズに、このカードをリリースして発動できる。\n自分の手札・墓地から「人造人間－サイコ・ショッカー」１体を選んで特殊召喚する。\nその後、相手フィールドの罠カードを全て破壊できる（そのカードがセットされている場合、めくって確認する）。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鏡導士サイコ・バウンダー",h:"まきょうどうしさいこ・ばうんだー",e:"Psychic Bounder",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n「人造人間－サイコ・ショッカー」１体またはそのカード名が記された魔法・罠カード１枚をデッキから手札に加える。\n②：このカード以外の自分のモンスターが相手モンスターに攻撃されたダメージ計算前に発動できる。\n攻撃モンスターとこのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・ギガサイバー",h:"さいこ・ぎがさいばー",e:"Psychic Megacyber",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：相手フィールドの魔法・罠カードの数が自分フィールドの魔法・罠カードより多い場合、このカードは手札から特殊召喚できる。\n②：自分フィールドに「人造人間－サイコ・ショッカー」が存在し、このカードが相手の効果モンスターに攻撃宣言した時、このカードをリリースして発動できる。\nその相手モンスターを永続罠カード扱いで相手の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳エナジーショック",h:"さいばーえなじーしょっく",e:"Cyber Energy Shock",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「人造人間－サイコ・ショッカー」が存在する場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果でフィールドの罠カードを破壊した場合、さらに以下の効果から１つを選択して適用できる。\n\n●フィールドの表側表示のカード１枚を選び、その効果をターン終了時まで無効にする。\n\n●自分フィールドの全ての「人造人間－サイコ・ショッカー」の攻撃力は８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"宇宙の法則",h:"うちゅうのほうそく",e:"Law of the Cosmos",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手は自身の手札・デッキから罠カード１枚を選んで自身の魔法＆罠ゾーンにセットできる。\nセットした場合、自分はデッキから「人造人間－サイコ・ショッカー」１体を特殊召喚する。\nセットしなかった場合、自分は「人造人間－サイコ・ショッカー」１体またはそのカード名が記されたモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"宇宙との交信",h:"うちゅうとのこうしん",e:"Cosmos Channelling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：元々の持ち主が相手となる自分フィールドのモンスター１体を墓地へ送って発動できる。\n自分の手札・墓地から機械族モンスター１体を選んで特殊召喚する。\n②：自分フィールドに「人造人間－サイコ・ショッカー」が存在し、相手ドローフェイズに相手が通常のドローをした時、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\nドローしたカードをお互いに確認し、宣言した種類だった場合、このカードを墓地へ送り、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"不朽の特殊合金",h:"ふきゅうのとくしゅごうきん",e:"Everlasting Alloy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「人造人間－サイコ・ショッカー」が存在する場合、以下の効果から１つを選択して発動できる。\n \n●自分フィールドの全ての機械族モンスターはターン終了時まで、相手の効果では破壊されない。\n\n●自分フィールドの機械族モンスターを対象とする魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒騎士ガイアオリジン",h:"あんこくきしがいあおりじん",e:"Gaia the Fierce Knight Origin",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からレベル５以上のモンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：戦士族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n③：自分・相手のバトルフェイズに、墓地のこのカードを除外し、元々の攻撃力と異なる攻撃力を持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力は元々の数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"パワードクロウラー",h:"ぱわーどくろうらー",e:"Powered Crawler",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのカードより低い攻撃力を持つ相手フィールドのモンスター１体を選んで破壊する。\n②：このカードがモンスターゾーンに存在する限り、攻撃可能な相手モンスターは攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"風化戦士",h:"うぇざりんぐそるじゃー",e:"Weathering Soldier",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが効果で墓地へ送られた場合、または戦闘で破壊された場合に発動できる。\n「風化戦士」以外の「化石融合－フォッシル・フュージョン」のカード名が記されたカードまたは「化石融合－フォッシル・フュージョン」１枚をデッキから手札に加える。\n②：自分エンドフェイズに発動する。\nこのカードの攻撃力は６００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"中生代化石騎士 スカルナイト",h:"ちゅうせいだいかせききし　すかるないと",e:"Fossil Warrior Skull Knight",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1100,tx:"岩石族モンスター＋レベル５・６のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードの攻撃でモンスターを破壊した時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。\n③：墓地のこのカードを除外し、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"新生代化石騎士 スカルポーン",h:"しんせいだいかせききし　すかるぽーん",e:"Fossil Warrior Skull Bone",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:800,tx:"岩石族モンスター＋レベル４以下のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n②：墓地のこのカードを除外して発動できる。\nデッキから「タイム・ストリーム」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"新生代化石竜 スカルガー",h:"しんせいだいかせきりゅう　すかるがー",e:"Fossil Dragon Skullgar",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:0,tx:"岩石族モンスター＋相手の墓地のレベル４以下のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：墓地のこのカードを除外して発動できる。\nデッキから「化石融合－フォッシル・フュージョン」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"化石融合－フォッシル・フュージョン",h:"かせきゆうごう－ふぉっしる・ふゅーじょん",e:"Fossil Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分・相手の墓地から、「化石」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果でお互いの墓地からモンスターを除外した場合には、その特殊召喚したモンスターはモンスターの効果の対象にならない。\n②：このカードが墓地に存在し、自分フィールドの表側表示の「化石」融合モンスターが戦闘・効果で破壊された場合に発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"タイム・ストリーム",h:"たいむ・すとりーむ",e:"Time Stream",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「化石」融合モンスター１体を対象として発動できる。\nそのモンスターをリリースし、そのモンスターより元々のレベルが２つ高い「化石」融合モンスター１体を、「化石融合－フォッシル・フュージョン」による融合召喚扱いとしてEXデッキから特殊召喚する。\n②：自分の墓地からこのカードと「化石」融合モンスター１体を除外し、自分の墓地の「化石」融合モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"標本の閲覧",h:"ひょうほんのえつらん",e:"Specimen Inspection",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札の「化石融合－フォッシル・フュージョン」１枚を相手に見せて発動できる。\n手札からモンスター１体を墓地へ送り、種族とレベルを１つずつ宣言する。\n相手は自身の手札・デッキを確認し、宣言された種族・レベルを持つモンスターがあった場合、その内の１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"奇跡の穿孔",h:"きせきのせんこう",e:"Miracle Rupture",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからレベル４以下の岩石族モンスター１体を墓地へ送る。\n自分の墓地に「化石融合－フォッシル・フュージョン」が存在する場合、さらに自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"サイキック・ウェーブ",h:"さいきっく・うぇーぶ",e:"Psychic Wave",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに機械族モンスターが存在する場合、手札・デッキから「人造人間－サイコ・ショッカー」１体を墓地へ送って発動できる。\n相手に６００ダメージを与える。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の機械族モンスター１体を対象として発動できる。\nデッキから「人造人間」モンスター１体を墓地へ送り、対象のモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アーマード・ホワイトベア",h:"あーまーど・ほわいとべあ",e:"Armored White Bear",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドにSモンスターが存在し、このカードが召喚・特殊召喚に成功した場合、自分の墓地のフィールド魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「アーマード・ホワイトベア」以外のレベル４以下の獣族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アフター・グロー",h:"あふたー・ぐろー",e:"Afterglow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードはデュエル中に１枚しか発動できない。\n①：自分の手札・デッキ・墓地及び自分フィールドの表側表示のカードの中から、このカードを含む「アフター・グロー」を全て除外する。\nその後、除外されている自分のカードの中から「アフター・グロー」１枚を選んでデッキに加える。\n次の自分ドローフェイズに、通常のドローをしたカードをお互いに確認する。\nそれが「アフター・グロー」だった場合、相手に４０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ハイレート・ドロー",h:"はいれーと・どろー",e:"High Rate Draw",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターを２体以上任意の数だけ選んで破壊し、破壊したモンスター２体につき１枚、自分はデッキからドローする。\n②：このカードが墓地に存在する場合、相手メインフェイズに、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、このカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"Sin パラダイム・ドラゴン",h:"しん　ぱらだいむ・どらごん",e:"Malefic Paradigm Dragon",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\nフィールドに「Sin パラダイム・ドラゴン」が存在しない場合に、EXデッキから「Sin」モンスター１体を除外した場合のみ特殊召喚できる。\n①：フィールドに「Sin World」が存在しない場合にこのカードは破壊される。\n②：１ターンに１度、デッキから「Sin」カード１枚を墓地へ送って発動できる。\n除外されている自分のレベル８のSモンスター１体をEXデッキに戻す。\nその後、そのモンスターをEXデッキから特殊召喚できる。\nこのターン、自分は「Sin」モンスターでしか攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヌメロン・ウォール",h:"ぬめろん・うぉーる",e:"Numeron Wall",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：「ヌメロン・ウォール」以外のカードが自分フィールドに存在しない場合、手札・フィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「ヌメロン・ネットワーク」１枚を選んで発動する。\nこの効果は相手ターンでも発動できる。\n②：自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、そのダメージステップ終了後にバトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.1 ゲート・オブ・カオス・ヌメロン－シニューニャ",h:"かおすなんばーず１ げーと・おぶ・かおす・ぬめろん－しにゅーにゃ",e:"Number C1: Numeron Chaos Gate Sunya",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"レベル２モンスター×４\nこのカードは自分フィールドの「No.1 ゲート・オブ・ヌメロン－エーカム」の上に重ねてX召喚する事もできる。\n①：このカードがX召喚に成功した場合に発動する。\nフィールドのモンスターを全て除外する。\n②：このカードが除外された場合、次の自分スタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。\n自分のフィールドゾーンに「ヌメロン・ネットワーク」が存在する場合、さらに除外されている自分・相手のXモンスターの攻撃力の合計分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"No.1 ゲート・オブ・ヌメロン－エーカム",h:"なんばーず１ げーと・おぶ・ぬめろん－えーかむ",e:"Number 1: Numeron Gate Ekam",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:100,tx:"レベル１モンスター×３\n①：このカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全ての「ヌメロン」モンスターの攻撃力はターン終了時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.2 ゲート・オブ・ヌメロン－ドゥヴェー",h:"なんばーず２ げーと・おぶ・ぬめろん－どぅう゛ぇー",e:"Number 2: Numeron Gate Dve",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:100,tx:"レベル１モンスター×３\n①：このカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全ての「ヌメロン」モンスターの攻撃力はターン終了時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.3 ゲート・オブ・ヌメロン－トゥリーニ",h:"なんばーず３ げーと・おぶ・ぬめろん－とぅりーに",e:"Number 3: Numeron Gate Trini",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:100,tx:"レベル１モンスター×３\n①：このカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全ての「ヌメロン」モンスターの攻撃力はターン終了時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"No.4 ゲート・オブ・ヌメロン－チャトゥヴァーリ",h:"なんばーず４ げーと・おぶ・ぬめろん－ちゃとぅう゛ぁーり",e:"Number 4: Numeron Gate Catvari",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:100,tx:"レベル１モンスター×３\n①：このカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全ての「ヌメロン」モンスターの攻撃力はターン終了時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヌメロン・ネットワーク",h:"ぬめろん・ねっとわーく",e:"Numeron Network",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに、発動条件を満たしている「ヌメロン」通常魔法カード１枚をデッキから墓地へ送って発動できる。\nこの効果は、その魔法カード発動時の効果と同じになる。\n②：このカードがフィールドゾーンに存在する限り、自分フィールドの「ヌメロン」XモンスターがX素材を取り除いて効果を発動する場合、X素材を取り除かずに発動する事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヌメロン・ダイレクト",h:"ぬめろん・だいれくと",e:"Numeron Calling",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールドゾーンに「ヌメロン・ネットワーク」が存在し、自分フィールドにモンスターが存在しない場合に発動できる。\nEXデッキから「ゲート・オブ・ヌメロン」Xモンスターを４体まで特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。\nこの効果の発動後、ターン終了時まで自分は１回しかモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"No.3 地獄蝉王ローカスト・キング",h:"なんばーず３　じごくぜみおうろーかすと・きんぐ",e:"Number 3: Cicada King",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1200,de:2500,tx:"レベル３モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの表示形式が変更された場合に発動できる。\n自分の手札・墓地から昆虫族モンスター１体を選んで守備表示で特殊召喚する。\n②：フィールドのモンスターの効果が発動した時、このカードのX素材を１つ取り除き、そのモンスター１体を対象として発動できる。\nそのモンスターの効果を無効にする。\nその後、フィールドの昆虫族モンスター１体を選び、守備力を５００アップするか、表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"花札衛－桜－",h:"かーでぃあん－さくら－",e:"Flower Cardian Cherry Blossom",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：自分フィールドにレベル２以下の「花札衛」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「花札衛」モンスターしか召喚・特殊召喚できない。\n②：１ターンに１度、自分フィールドの「花札衛」モンスター１体をリリースして発動できる。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、デッキから「花札衛－桜－」以外の「花札衛」モンスター１体を手札に加えるか特殊召喚できる。\n違った場合、そのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"超勝負！",h:"ちょうしょうぶ！",e:"Super All In!",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのSモンスター１体を選んで持ち主のEXデッキに戻し、自分の墓地から「花札衛」モンスター４体を選んで特殊召喚する。\nその後、自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを召喚条件を無視して特殊召喚できる。\n違った場合、自分フィールドのモンスターを全て破壊し、自分のLPを半分にする。\n②：このカードが「花札衛」モンスターの効果で墓地へ送られたターンのエンドフェイズに発動できる。\n自分の墓地から魔法・罠カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"極氷獣ブリザード・ウルフ",h:"きょくひょうじゅうぶりざーど・うるふ",e:"Glacial Beast Blizzard Wolf",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手のモンスターの攻撃宣言時に発動できる。\nその攻撃を無効にし、手札から「極氷獣ブリザード・ウルフ」以外のレベル４以下の水属性モンスター１体を特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドにモンスターが存在しない場合、相手バトルフェイズ開始時に発動できる。\nこのカードを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"極氷獣ポーラ・ペンギン",h:"きょくひょうじゅうぽーら・ぺんぎん",e:"Glacial Beast Polar Penguin",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n②：フィールドのこのカードが戦闘・効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"極氷獣アイスバーグ・ナーワル",h:"きょくひょうじゅうあいすばーぐ・なーわる",e:"Glacial Beast Iceberg Narwhal",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:1600,tx:"チューナー＋チューナー以外の水属性モンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの他のモンスターが相手モンスターの攻撃または相手の効果で破壊される度に発動する。\n相手に６００ダメージを与える。\n②：自分フィールドに他のモンスターが存在し、自分・相手のバトルフェイズに相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nその効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"キリビ・レディ",h:"きりび・れでぃ",e:"Fire Flint Lady",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに戦士族モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：フィールドのこのカードを墓地へ送って発動できる。\n手札からレベル４以下の戦士族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"電幻機塊コンセントロール",h:"でんげんきかいこんせんとろーる",e:"Appliancer Socketroll",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「機塊」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドに他の「電幻機塊コンセントロール」が特殊召喚された場合に発動できる。\nデッキから「電幻機塊コンセントロール」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"遮断機塊ブレイカーバンクル",h:"しゃだんきかいぶれいかーばんくる",e:"Appliancer Breakerbuncle",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分の「機塊」モンスターが相手モンスターと戦闘を行うダメージ計算時に、このカードを手札から捨てて発動できる。\nその自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。\n②：自分フィールドの「機塊」モンスターが効果で破壊される場合、代わりにフィールド・墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"複写機塊コピーボックル",h:"ふくしゃきかいこぴーぼっくる",e:"Appliancer Copybokkle",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「機塊」モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードはエンドフェイズまで、対象のモンスターと同名カードとして扱う。\n②：墓地のこのカードを除外し、自分フィールドの「機塊」モンスター１体を対象として発動できる。\nそのモンスターの同名モンスター１体を自分の手札・墓地から選んで特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"充電機塊セルトパス",h:"じゅうでんきかいせるとぱす",e:"Appliancer Celtopus",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:"",de:"-",tx:"「機塊」モンスター２体\n①：リンク状態のこのカードは、攻撃対象にならず、相手の効果の対象にもならない。\n②：このカードの相互リンク先の自分の「機塊」リンクモンスターが相手モンスターと戦闘を行うダメージ計算時に発動する。\nその自分のモンスターの攻撃力はそのダメージ計算時のみ、このカードの相互リンク先のモンスターの数×１０００アップする。\n③：１ターンに１度、このカードとは相互リンクしていない自分フィールドの「機塊」リンクモンスターが戦闘・効果で破壊された場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:["左下","下"],ta:[],al:[]},
  {n:"計量機塊カッパスケール",h:"けいりょうきかいかっぱすけーる",e:"Appliancer Kappa Scale",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"「機塊」モンスター１体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：相互リンク状態のこのカードをリリースして発動できる。\n自分の墓地から「計量機塊カッパスケール」以外の「機塊」リンクモンスター１体を選んで特殊召喚する。\n②：相互リンク状態ではないこのカードをリリースして発動できる。\n自分の墓地からレベル４以下の「機塊」モンスター１体を選んで特殊召喚する。",li:"",lk:["上"],ta:[],al:[]},
  {n:"掃除機塊バキューネシア",h:"そうじきかいばきゅーねしあ",e:"Appliancer Vacculephant",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"「機塊」モンスター１体\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：手札を１枚墓地へ送り、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが相互リンク状態の場合、相手のメインモンスターゾーンのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：このカードが相互リンク状態ではない場合、このカードは直接攻撃できる。",li:"",lk:["右上"],ta:[],al:[]},
  {n:"洗濯機塊ランドリードラゴン",h:"せんたくきかいらんどりーどらごん",e:"Appliancer Laundry Dragon",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"「機塊」モンスター１体\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：このカードの戦闘で発生するお互いの戦闘ダメージは０になる。\n②：１ターンに１度、相互リンク状態のこのカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを除外する。\n③：１ターンに１度、相互リンク状態ではないこのカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを破壊し、その元々の攻撃力分のダメージを相手に与える。",li:"",lk:["上"],ta:[],al:[]},
  {n:"乾燥機塊ドライドレイク",h:"かんそうきかいどらいどれいく",e:"Appliancer Dryer Drake",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"「機塊」モンスター１体\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：リンク状態のこのカードの攻撃力は１０００アップする。\n②：自分バトルフェイズに１度、このカードが相互リンク状態の場合に発動できる。\nこのカードを含む自分のメインモンスターゾーンの「機塊」モンスター２体を選び、その位置を入れ替える。\nこのターン、そのもう１体のモンスターは１度のバトルフェイズ中に２回攻撃できる。\n③：１ターンに１度、相互リンク状態ではないこのカードが攻撃対象になった時に発動できる。\nその攻撃を無効にする。",li:"",lk:["右上"],ta:[],al:[]},
  {n:"機塊リユース",h:"きかいりゆーす",e:"Appliancer Reuse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「機塊」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"機塊テスト",h:"きかいてすと",e:"Appliancer Test",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「機塊」リンクモンスター１体を対象として発動できる。\nそのモンスターのリンク先となる自分フィールドに、自分の墓地からリンク１の「機塊」リンクモンスターを可能な限り特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"家電機塊世界エレクトリリカル・ワールド",h:"かでんきかいせかいえれくとりりかる・わーるど",e:"Appliancer Electrilyrical World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキからフィールド魔法カード以外の「機塊」カード１枚を手札に加える事ができる。\n②：１ターンに１度、自分が「機塊」リンクモンスターのリンク召喚に成功した場合に発動できる。\n自分の墓地から「機塊」モンスター１体を選んで手札に加える。\n③：１ターンに１度、自分または相手のモンスターの攻撃宣言時に発動できる。\n自分フィールドの「機塊」モンスター１体を選び、その位置を他の自分のメインモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依覚醒－デーモン・リーパー",h:"ひょういかくせい－でーもん・りーぱー",e:"Awakening of the Possessed - Nefariouser Archfiend",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは自分フィールドの表側表示の、魔法使い族モンスター１体とレベル４以下の地属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した時に発動できる。\n自分の墓地からレベル４以下のモンスター１体を選んで効果を無効にして特殊召喚する。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「地霊術」カードまたは「憑依」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依覚醒－大稲荷火",h:"ひょういかくせい－おおいなりび",e:"Awakening of the Possessed - Greater Inari Fire",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは自分フィールドの表側表示の、魔法使い族モンスター１体とレベル４以下の炎属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した時に発動できる。\n相手フィールドのモンスター１体を選び、その元々の攻撃力分のダメージを相手に与える。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「火霊術」カードまたは「憑依」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"大霊術－「一輪」",h:"だいれいじゅつ－「いちりん」",e:"Grand Spiritual Art - Ichirin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分フィールドに守備力１５００の魔法使い族モンスターが存在する場合、相手が発動したモンスターの効果を無効にする。\n②：自分メインフェイズに発動できる。\n手札の魔法使い族モンスター１体を相手に見せ、そのモンスターと同じ属性で攻撃力１５００／守備力２００のモンスター１体をデッキから手札に加え、見せたカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依連携",h:"ひょういれんけい",e:"Possessed Partnerships",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の手札・墓地から守備力１５００の魔法使い族モンスター１体を選び、表側攻撃表示または裏側守備表示で特殊召喚する。\n自分フィールドのモンスターの属性が２種類以上の場合、さらにフィールドの表側表示のカード１枚を選んで破壊できる。\n②：墓地のこのカードを除外し、自分の墓地の「憑依」永続魔法・永続罠カード１枚を対象として発動できる。\nそのカードを自分フィールドに表側表示で置く。",li:"",lk:[],ta:[],al:[]},
  {n:"精霊術の使い手",h:"せいれいじゅつのつかいて",e:"Spirit Charmers",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚捨てて発動できる。\nデッキから「霊使い」モンスター、「憑依装着」モンスター、「憑依」魔法・罠カードの内、２枚を選ぶ（同名カードは１枚まで）。\nその内の１枚を手札に加え、もう１枚を自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"百年竜",h:"はんどれっど・どらごん",e:"Hundred Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nターン終了時まで、自分フィールドのカードの数だけこのカードのレベルを上げ、上げた数×１００だけこのカードの攻撃力をアップする。\n②：このカードが墓地へ送られた場合、EXデッキから特殊召喚された自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはフィールドに表側表示で存在する限り１度だけ、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"時の魔導士",h:"ときのまどうし",e:"Time Wizard of Tomorrow",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1900,tx:"「時の魔術師」＋効果モンスター\n①：１ターンに１度、このカードが融合召喚されている場合に発動できる。\nコイントスを１回行い、裏表を当てる。\n当たった場合、フィールドのモンスターを全て破壊し、相手は表側表示で破壊されたモンスターの元々の攻撃力を合計した数値の半分のダメージを受ける。\nハズレの場合、フィールドのモンスターを全て破壊し、自分は表側表示で破壊されたモンスターの元々の攻撃力を合計した数値の半分のダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィ・レディ・SC",h:"はーぴぃ・れでぃ・すくらっち・くらっしゅ",e:"Cyber Slash Harpie Lady",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードをS召喚する場合、自分フィールドの「ハーピィ」モンスター１体をチューナーとして扱う事ができる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：魔法・罠カードの効果が発動した時、相手フィールドのモンスター１体または自分フィールドの「ハーピィ」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"人造人間－サイコ・レイヤー",h:"じんぞうにんげん－さいこ・れいやー",e:"Jinzo - Layered",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1500,tx:"レベル６モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターは効果を発動できず、攻撃宣言できない。\n②：フィールドに罠カードが存在する場合に発動できる。\n自分フィールドのモンスター１体をリリースし、フィールドの表側表示のカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・カオス・ソルジャー",h:"とぅーん・かおす・そるじゃー",e:"Toon Black Luster Soldier",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の手札・フィールドから、レベルの合計が８以上になるようにトゥーンモンスターをリリースした場合に特殊召喚できる。\n①：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n②：１ターンに１度、自分フィールドに「トゥーン・ワールド」が存在する場合、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・ハーピィ・レディ",h:"とぅーん・はーぴぃ・れでぃ",e:"Toon Harpie Lady",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["トゥーン","効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「トゥーン・ワールド」が存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n自分フィールドに他のトゥーンモンスターが存在する場合、さらに相手フィールドの魔法・罠カード１枚を選んで破壊できる。\n②：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n③：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーンのしおり",h:"とぅーんのしおり",e:"Toon Bookmark",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：「トゥーンのしおり」以外の「トゥーン・ワールド」のカード名が記されたカードまたは「トゥーン・ワールド」１枚をデッキから手札に加える。\n②：自分フィールドの「トゥーン・ワールド」が効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・フリップ",h:"とぅーん・ふりっぷ",e:"Toon Page-Flip",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「トゥーン・ワールド」が存在する場合に発動できる。\nデッキからカード名が異なるトゥーンモンスター３体を相手に見せ、相手はその中からランダムに１体選ぶ。\nそのモンスター１体を召喚条件を無視して自分フィールドに特殊召喚する。\n残りのモンスターはデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"トゥーン・テラー",h:"とぅーん・てらー",e:"Toon Terror",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「トゥーン・ワールド」及びトゥーンモンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌の創世神",h:"かおす・くりえいたー",e:"The Chaos Creator",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:3000,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：手札から特殊召喚したこのカードが存在する場合、除外されている自分及び相手のモンスターの中から合計３体を対象として発動できる（同名カードは１枚まで）。\nその内の１体を自分フィールドに特殊召喚し、残りを好きな順番で持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ダイダロス",h:"かおす・だいだろす",e:"Chaos Daedalus",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:1500,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールド魔法カードが表側表示で存在する限り、自分フィールドの光・闇属性モンスターは相手の効果の対象にならない。\n②：フィールド魔法カードが表側表示で存在する場合、その数までフィールドのカードを対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌のヴァルキリア",h:"こんとんのう゛ぁるきりあ",e:"Chaos Valkyria",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1800,de:1050,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から光属性または闇属性のモンスター１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが除外された場合に発動できる。\nデッキから光属性または闇属性のモンスター１体を墓地へ送る。\nこのターン、自分はこの効果で墓地へ送ったカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌領域",h:"かおす・てりとりー",e:"Chaos Space",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から光属性または闇属性のモンスター１体を墓地へ送って発動できる。\nそのモンスターとは属性が異なり、レベル４～８の通常召喚できない光・闇属性モンスター１体をデッキから手札に加える。\n②：墓地のこのカードを除外し、除外されている自分の通常召喚できない光・闇属性モンスター１体を対象として発動できる。\nそのモンスターをデッキの一番下に戻す。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"エターナル・カオス",h:"えたーなる・かおす",e:"Eternal Chaos",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\n攻撃力の合計がそのモンスターの攻撃力以下になるように、デッキから光属性と闇属性のモンスターを１体ずつ墓地へ送る。\nこのカードの発動後、ターン終了時まで自分は墓地のモンスターの効果を１度しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"レイダーズ・ウィング",h:"れいだーず・うぃんぐ",e:"Raider's Wing",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名はルール上「幻影騎士団」カード、「RR」カードとしても扱う。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの闇属性XモンスターのX素材を１つ取り除いて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードを素材として持っている、元々の属性が闇属性のXモンスターは以下の効果を得る。\n●このカードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ステンドグリーブ",h:"ふぁんとむ・ないつすてんどぐりーぶ",e:"The Phantom Knights of Stained Greaves",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「幻影騎士団」モンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、このカードのレベルを１つ上げる事ができる。\n②：墓地のこのカードを除外して発動できる。\n手札から「幻影騎士団ステンドグリーブ」以外の「幻影騎士団」モンスター１体を特殊召喚する。\nその後、そのモンスターのレベルを１つ上げる事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"幻影騎士団ティアースケイル",h:"ふぁんとむ・ないつてぃあーすけいる",e:"The Phantom Knights of Torn Scales",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\nデッキから「幻影騎士団ティアースケイル」以外の「幻影騎士団」モンスター１体または「ファントム」魔法・罠カード１枚を墓地へ送る。\n②：このカードが墓地に存在し、自分の墓地からこのカード以外の「幻影騎士団」モンスターまたは「ファントム」魔法・罠カードが除外された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:1,lk:[],ta:[],al:[]},
  {n:"RR－ヒール・イーグル",h:"れいど・らぷたーず－ひーる・いーぐる",e:"Raidraptor - Heel Eagle",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:700,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが「RR」モンスターのみの場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「RR」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ストラングル・レイニアス",h:"れいど・らぷたーず－すとらんぐる・れいにあす",e:"Raidraptor - Strangle Lanius",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに闇属性モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。\n②：自分フィールドに闇属性XモンスターをX素材としているXモンスターが存在する場合、自分の墓地のレベル４以下の「RR」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣戦線 ナーベル",h:"とらいぶりげーど　なーべる",e:"Tri-Brigade Nervall",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から獣族・獣戦士族・鳥獣族モンスターを任意の数だけ除外して発動できる。\n除外した数と同じ数のリンクマーカーを持つ獣族・獣戦士族・鳥獣族リンクモンスター１体をEXデッキから特殊召喚する。\nこのターン、自分は獣族・獣戦士族・鳥獣族モンスターしかリンク素材にできない。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「鉄獣戦線 ナーベル」以外の「トライブリゲード」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣戦線 ケラス",h:"とらいぶりげーど　けらす",e:"Tri-Brigade Kerass",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカード以外の獣族・獣戦士族・鳥獣族モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：自分の墓地から獣族・獣戦士族・鳥獣族モンスターを任意の数だけ除外して発動できる。\n除外した数と同じ数のリンクマーカーを持つ獣族・獣戦士族・鳥獣族リンクモンスター１体をEXデッキから特殊召喚する。\nこのターン、自分は獣族・獣戦士族・鳥獣族モンスターしかリンク素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣戦線 フラクトール",h:"とらいぶりげーど　ふらくとーる",e:"Tri-Brigade Fraktall",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札・フィールドのこのカードを墓地へ送って発動できる。\nデッキからレベル３以下の獣族・獣戦士族・鳥獣族モンスター１体を墓地へ送る。\n②：自分の墓地から獣族・獣戦士族・鳥獣族モンスターを任意の数だけ除外して発動できる。\n除外した数と同じ数のリンクマーカーを持つ獣族・獣戦士族・鳥獣族リンクモンスター１体をEXデッキから特殊召喚する。\nこのターン、自分は獣族・獣戦士族・鳥獣族モンスターしかリンク素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"教導の神徒",h:"はっしゃーしーん・どらぐま",e:"Dogmatika Ashiyan",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：融合・S・X・リンクモンスターが自分または相手の墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、「教導の神徒」以外の自分の墓地の「ドラグマ」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n③：相手モンスターの攻撃宣言時に発動できる。\n自分フィールドの全ての「ドラグマ」モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺媛－瑞々",h:"でんのうかいえん－るぅるぅ",e:"Virtual World Mai-Hime - Lulu",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの「電脳堺」カード１枚を対象として発動できる。\nそのカードとは種類（モンスター・魔法・罠）が異なる「電脳堺」カード１枚をデッキから墓地へ送り、このカードを特殊召喚する。\nその後、対象のカード及び墓地へ送ったカードとは種類が異なる「電脳堺媛－瑞々」以外の「電脳堺」カード１枚をデッキから手札に加える事ができる。\nこのターン、自分はレベルまたはランクが３以上のモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺悟－老々",h:"でんのうかいご－らおらお",e:"Virtual World Roshi - Laolao",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの「電脳堺」カード１枚を対象として発動できる。\nそのカードとは種類（モンスター・魔法・罠）が異なる「電脳堺」カード１枚をデッキから墓地へ送り、このカードを特殊召喚する。\nその後、墓地へ送ったカードとはカード名が異なる「電脳堺」モンスター１体を自分の墓地から選んで効果を無効にして守備表示で特殊召喚できる。\nこのターン、自分はレベルまたはランクが３以上のモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺豸－豸々",h:"でんのうかいち－ぢぃぢぃ",e:"Virtual World Xiezhi - Jiji",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの「電脳堺」カード１枚を対象として発動できる。\nそのカードとは種類（モンスター・魔法・罠）が異なる「電脳堺」カード１枚をデッキから墓地へ送り、このカードを特殊召喚する。\nこのターンのエンドフェイズに、自分の墓地から「電脳堺豸－豸々」以外の「電脳堺」モンスター１体を選んで手札に加える事ができる。\nこのターン、自分はレベルまたはランクが３以上のモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺麟－麟々",h:"でんのうかいりん－りぃりぃ",e:"Virtual World Kirin - Lili",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの「電脳堺」カード１枚を対象として発動できる。\nそのカードとは種類（モンスター・魔法・罠）が異なる「電脳堺」カード１枚をデッキから墓地へ送り、このカードを特殊召喚する。\nその後、対象のカード及び墓地へ送ったカードとは種類が異なる「電脳堺麟－麟々」以外の「電脳堺」カード１枚をデッキから墓地へ送る事ができる。\nこのターン、自分はレベルまたはランクが３以上のモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"双天拳の熊羆",h:"そうてんけんのゆうひ",e:"Dual Avatar Fists - Yuhi",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「双天」モンスター１体を対象として発動できる。\nそのモンスターを破壊し、デッキから「双天」魔法カード１枚を手札に加える。\n②：このカードが墓地に存在する状態で、効果モンスターを素材として融合召喚された自分フィールドの表側表示の「双天」融合モンスターが、戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"双天脚の鴻鵠",h:"そうてんきゃくのこうこく",e:"Dual Avatar Feet - Kokoku",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「双天脚の鴻鵠」以外の自分フィールドの表側表示の「双天」モンスターが相手ターンに戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、以下の効果を適用できる。\n●自分フィールドの「双天」モンスター１体を選んで破壊し、EXデッキから「双天」融合モンスター１体を特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「双天」罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・コンジュラー",h:"いんふぇるにてぃ・こんじゅらー",e:"Infernity Conjurer",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札が０枚である限り、相手フィールドのモンスターの攻撃力は８００ダウンする。\n②：このカードが墓地に存在し、自分の手札が０枚の場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ワイルドキャット",h:"いんふぇるにてぃ・わいるどきゃっと",e:"Infernity Wildcat",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:0,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは手札の他の「インフェルニティ」モンスター１体を墓地へ送り、手札から特殊召喚できる。\n②：自分の手札が０枚の場合、自分の墓地の「インフェルニティ」モンスター１体を除外して発動できる。\nこのカードのレベルをターン終了時まで１つ上げる、または下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.リベロスパイカー",h:"うるとらあすりーとりべろすぱいかー",e:"U.A. Libero Spiker",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは「U.A.リベロスパイカー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：相手メインフェイズに発動できる。\n手札のレベル５以上の「U.A.」モンスター１体をデッキに戻し、そのモンスターとはカード名が異なる「U.A.」モンスター１体をデッキから特殊召喚する。\nその後、フィールドのこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.プレイングマネージャー",h:"うるとらあすりーとぷれいんぐまねーじゃー",e:"U.A. Player Manager",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「U.A.」モンスターの召喚・特殊召喚に成功した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n\n●「U.A.」モンスター以外のフィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依覚醒－ガギゴバイト",h:"ひょういかくせい－がぎごばいと",e:"Awakening of the Possessed - Gagigobyte",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは自分フィールドの表側表示の、魔法使い族モンスター１体とレベル４以下の水属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した時に発動できる。\n相手の手札をランダムに１枚選んで墓地へ送る。\nその後、お互いにデッキから１枚ドローする。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「水霊術」カードまたは「憑依」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"憑依覚醒－ラセンリュウ",h:"ひょういかくせい－らせんりゅう",e:"Awakening of the Possessed - Rasenryu",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは自分フィールドの表側表示の、魔法使い族モンスター１体とレベル４以下の風属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した時に発動できる。\n相手フィールドのカード１枚を選んで持ち主の手札に戻す。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「風霊術」カードまたは「憑依」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧牙－御神尊真神",h:"きこうが－おんかみことのまかみ",e:"Gizmek Makami, the Ferocious Fanged Fortress",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2150,de:2150,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：除外されている自分のカードが６枚以上の場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合、手札からモンスター１体を捨てて発動できる。\nデッキから「機巧牙－御神尊真神」以外の、攻撃力と守備力の数値が同じモンスター１体を手札に加える。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\n除外されている自分のカード６枚を選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"獣王アルファ",h:"ししおうあるふぁ",e:"Alpha, the Master of Beasts",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n相手フィールドのモンスターの攻撃力の合計が、自分フィールドのモンスターの攻撃力の合計より高い場合に特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの獣族・獣戦士族・鳥獣族モンスターを任意の数だけ対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\nその後、手札に戻した数だけ相手フィールドの表側表示モンスターを選んで持ち主の手札に戻す。\nこの効果の発動後、ターン終了時まで自分の「獣王アルファ」は直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"奇采のプルフィネス",h:"きさいのぷるふぃねす",e:"Prufinesse, the Tactical Trapper",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、デッキから罠カード１枚を除外して発動できる。\nこのカードのレベルを１つ上げる。\n②：自分または相手の墓地の罠カード１枚を対象として発動できる。\nそのカードを除外し、このカードのレベルを１つ上げる。\n③：このカードが相手によって破壊された場合に発動できる。\n除外されている自分の通常罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"巌帯の美技－ゼノギタム",h:"ろっくばんど・ぐるーう゛－ぜのぎたむ",e:"Rock Band Xenoguitar",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、「巌帯の美技－ゼノギタム」以外の自分の墓地の岩石族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札を１枚選んでデッキの一番上に置く。\n②：このカードがフィールド・墓地から除外された場合に発動できる。\nデッキから岩石族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導闇商人",h:"まじかる・ぶろーかー",e:"Magical Broker",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに永続魔法・永続罠カードが存在する限り、このカードは戦闘・効果では破壊されない。\n②：相手ターンに、自分フィールドの表側表示の永続魔法・永続罠カード１枚を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"貪食魚グリーディス",h:"どんしょくぎょぐりーでぃす",e:"Gluttonous Reptolphin Greethys",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手の手札の数以下のレベルを持つ自分の墓地の魚族・海竜族・水族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。\n②：このカードがS素材として墓地へ送られた場合に発動できる。\nこのカードをS素材としたSモンスターの攻撃力・守備力は、相手の手札の数×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"コウ・キューピット",h:"こう・きゅーぴっと",e:"Cupid Fore",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが守備力６００のモンスターのみの場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：手札を１枚捨て、自分フィールドの天使族・光属性モンスター１体とフィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターのレベルはターン終了時まで、もう１体のモンスターのレベルと同じになる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"禰須三破鳴比",h:"ねずみはなび",e:"Hinezumi Hanabi",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカードは融合・S・X・リンク召喚の素材にできない。\n①：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカードにカウンターを６つ置く。\n②：自分エンドフェイズに発動する。\nこのカードのコントロールを相手に移す。\n③：１ターンに１度、カウンターが置かれているこのカードのコントロールが移った場合に発動する。\nサイコロを１回振り、出た目の数まで可能な限りこのカードのカウンターを取り除く。\nこの効果でこのカードのカウンターが全て取り除かれた場合、このカードを破壊し、自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"ネフティスの繋ぎ手",h:"ねふてぃすのつなぎて",e:"Conductor of Nephthys",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2000,de:0,tx:"「ネフティスの輪廻」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\n手札・デッキから「ネフティスの繋ぎ手」以外の「ネフティス」儀式モンスター１体を儀式召喚扱いで特殊召喚する。\n②：このカードが「ネフティス」カードの効果で、リリースされた場合、または破壊された場合に発動できる。\n次のスタンバイフェイズに、自分の手札・デッキ・フィールドからそれぞれ１枚まで、儀式モンスター以外の「ネフティス」カードを選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"痕喰竜ブリガンド",h:"こんじきりゅうぶりがんど",e:"Brigrand the Glory Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「アルバスの落胤」＋レベル８以上のモンスター\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは戦闘では破壊されない。\n②：融合召喚したこのカードがモンスターゾーンに存在する限り、相手は自分フィールドの他のモンスターをモンスターの効果の対象にできない。\n③：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「トライブリゲード」モンスターまたは「アルバスの落胤」１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"双天拳 鎧阿",h:"そうてんけん　がいあ",e:"Dual Avatar Fists - Armored Ah-Gyo",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1500,tx:"「双天」モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、相手フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこの効果の発動後、ターン終了時までこのカードは直接攻撃できない。\n②：効果モンスターを素材として融合召喚した「双天」融合モンスターが自分フィールドに存在する限り、自分フィールドの「双天」融合モンスターの攻撃力・守備力は３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"双天脚 鎧吽",h:"そうてんきゃく　がいうん",e:"Dual Avatar Feet - Armored Un-Gyo",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1700,de:2100,tx:"「双天」モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「双天」融合モンスターが効果で破壊される場合、代わりに自分フィールドの「双天」モンスター１体を破壊できる。\n②：効果モンスターを素材として融合召喚した「双天」融合モンスターが自分フィールドに存在する場合、自分・相手のメインフェイズに、EXデッキから特殊召喚された表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"双天将 金剛",h:"そうてんしょう　こんごう",e:"Dual Avatar - Empowered Kon-Gyo",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:1700,tx:"「双天拳の熊羆」＋「双天」モンスター×２\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：このカードが攻撃したダメージ計算後に発動できる。\n相手フィールドのモンスター１体を選んで持ち主の手札に戻す。\n③：自分フィールドに融合モンスターが２体以上存在し、フィールドのこのカードを対象とする相手の魔法・罠カードの効果が発動した時に発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"混沌幻魔アーミタイル－虚無幻影羅生悶",h:"こんとんげんまあーみたいる－きょむげんえいらしょうもん",e:"Armityle the Chaos Phantasm - Phantom of Fury",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「神炎皇ウリア」＋「降雷皇ハモン」＋「幻魔皇ラビエル」\n①：このカードはモンスターゾーンに存在する限り、カード名を「混沌幻魔アーミタイル」として扱う。\n②：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのコントロールを相手に移す。\n③：このカードのコントロールが移ったターンのエンドフェイズに発動する。\n自分フィールドのカードを全て除外する。\nその後、このカードの元々の持ち主は自身のEXデッキから「混沌幻魔アーミタイル」１体を召喚条件を無視して特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺狐－仙々",h:"でんのうかいこ－しぇんしぇん",e:"Virtual World Kyubi - Shenshen",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドから墓地へ送られるカードは墓地へは行かず除外される。\n②：自分のモンスターの攻撃宣言時に発動できる。\n除外されている自分または相手のモンスター１体を選んで墓地に戻す。\n③：このカード以外の、元々の種族・属性が異なるモンスター２体を自分の墓地から除外して発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ヘル・デーモン",h:"いんふぇるにてぃ・へる・でーもん",e:"Infernity Doom Archfiend",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\n自分の手札が０枚の場合、さらにそのカードを破壊できる。\n②：自分の手札が０枚である限り、このカードが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。\n③：このカードをS素材とした闇属性Sモンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"焔聖騎士将－オリヴィエ",h:"えんせいきししょう－おりう゛ぃえ",e:"Infernoble Knight Captain Oliver",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：装備カードを装備したこのカードの攻撃宣言時に、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分フィールドの戦士族モンスター１体を対象として発動できる。\nこのカードをチューナー扱いで墓地から特殊召喚し、対象の自分のモンスターを攻撃力５００アップの装備カード扱いとしてこのカードに装備する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン勇者",h:"ぺんぎんゆうしゃ",e:"Penguin Brave",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nデッキから「ペンギン」モンスター１体を裏側守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの裏側守備表示モンスターは相手の効果の対象にならない。\n③：相手がモンスターの効果を発動した時に発動できる。\n自分フィールドの裏側守備表示の水属性モンスター１体を選んで表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"レイダーズ・ナイト",h:"れいだーず・ないと",e:"Raider's Knight",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:0,tx:"闇属性レベル４モンスター×２\nこのカード名はルール上「幻影騎士団」カード、「RR」カードとしても扱う。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nこのカードよりランクが１つ高い、またはランクが１つ低い「幻影騎士団」、「RR」、「エクシーズ・ドラゴン」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは次の相手エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"アーク・リベリオン・エクシーズ・ドラゴン",h:"あーく・りべりおん・えくしーず・どらごん",e:"Arc Rebellion Xyz Dragon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"レベル５モンスター×３\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：X召喚したこのカードは効果では破壊されない。\n②：このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力は、このカード以外のフィールドのモンスターの元々の攻撃力の合計分アップする。\nこのカードが闇属性XモンスターをX素材としている場合、さらにこのカード以外のフィールドの全ての表側表示モンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分はこのカードでしか攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺甲－甲々",h:"でんのうかいこう－じゃじゃ",e:"Virtual World Shell - Jaja",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:2400,tx:"レベル３モンスター×２体以上\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターは相手ターン終了時まで戦闘では破壊されない。\n②：１ターンに１度、元々の種族・属性が同じモンスターが自分の墓地に２体以上存在し、このカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺凰－凰々",h:"でんのうかいおう－ふぁんふぁん",e:"Virtual World Phoenix - Fanfan",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル６モンスター×２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、このカードのX素材を２つ取り除き、相手フィールドの表側表示のカード１枚と自分または相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\n②：X召喚したこのカードが相手モンスターの攻撃または相手の効果で破壊された場合に発動できる。\n種族・属性が同じ「電脳堺」モンスター２体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"わくわくメルフィーズ",h:"わくわくめるふぃーず",e:"Joyous Melffys",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:500,tx:"獣族レベル２モンスター×２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nこのターン、自分の「メルフィー」モンスターは直接攻撃できる。\n②：相手ターンに、自分フィールドの獣族Xモンスター１体を対象として発動できる。\nそのモンスターを持ち主のEXデッキに戻す。\nその後、そのモンスターが持っていたX素材の数まで、自分の墓地からレベル２以下の獣族モンスターを選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"天霆號アーゼウス",h:"ねがろぎああーぜうす",e:"Divine Arsenal AA-ZEUS - Sky Thunder",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル１２モンスター×２\n「天霆號アーゼウス」は、Xモンスターが戦闘を行ったターンに１度、自分フィールドのXモンスターの上に重ねてX召喚する事もできる。\n①：このカードのX素材を２つ取り除いて発動できる。\nこのカード以外のフィールドのカードを全て墓地へ送る。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、このカード以外の自分フィールドのカードが戦闘または相手の効果で破壊された場合に発動できる。\n手札・デッキ・EXデッキからカード１枚を選び、このカードの下に重ねてX素材とする。",li:1,lk:[],ta:[],al:[]},
  {n:"鉄獣戦線 徒花のフェリジット",h:"とらいぶりげーど　あだばなのふぇりじっと",e:"Tri-Brigade Ferrijit the Barren Blossom",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:1600,de:"-",tx:"獣族・獣戦士族・鳥獣族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札からレベル４以下の獣族・獣戦士族・鳥獣族モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は獣族・獣戦士族・鳥獣族モンスターしかリンク素材にできない。\n②：このカードが墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んでデッキの一番下に戻す。",li:"",lk:["左","左下"],ta:[],al:[]},
  {n:"鉄獣戦線 銀弾のルガル",h:"とらいぶりげーど　ぎんだんのるがる",e:"Tri-Brigade Rugal the Silver Sheller",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"獣族・獣戦士族・鳥獣族モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手メインフェイズに発動できる。\n自分の手札・墓地からレベル４以下の獣族・獣戦士族・鳥獣族モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに持ち主の手札に戻る。\n②：このカードが墓地へ送られた場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで、自分フィールドのモンスターの種族の種類×３００ダウンする。",li:"",lk:["右","下","右下"],ta:[],al:[]},
  {n:"鉄獣戦線 凶鳥のシュライグ",h:"とらいぶりげーど　きょうちょうのしゅらいぐ",e:"Tri-Brigade Shuraig the Ominous Omen",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"獣族・獣戦士族・鳥獣族モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、または自分フィールドにこのカード以外の獣族・獣戦士族・鳥獣族モンスターが特殊召喚された場合に発動できる。\nフィールドのカード１枚を選んで除外する。\n②：このカードが墓地へ送られた場合に発動できる。\n除外されている自分の獣族・獣戦士族・鳥獣族モンスターの数以下のレベルを持つ獣族・獣戦士族・鳥獣族モンスター１体をデッキから手札に加える。",li:"",lk:["左","右","左下","右下"],ta:[],al:[]},
  {n:"プランキッズ・ミュー",h:"ぷらんきっず・みゅー",e:"Prank-Kids Meow-Meow-Mu",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"レベル４以下の「プランキッズ」モンスター１体\n自分は「プランキッズ・ミュー」を１ターンに１度しかリンク召喚できず、その効果は１ターンに１度しか使用できない。\n①：相手ターンに自分フィールドの「プランキッズ」モンスターが効果を発動するために自身をリリースする場合、代わりにフィールド・墓地のこのカードを除外できる。",li:1,lk:["下"],ta:[],al:[]},
  {n:"転晶のコーディネラル",h:"てんしょうのこーでぃねらる",e:"Geonator Transverser",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"効果モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：リンク状態のこのカード及びこのカードのリンク先のモンスターは相手の効果では破壊されない。\n②：このカードのリンク先にモンスターが２体存在する場合に発動できる。\nそのモンスター２体のコントロールを入れ替える。",li:"",lk:["右上","左下"],ta:[],al:[]},
  {n:"RUM－ファントム・フォース",h:"らんくあっぷまじっく－ふぁんとむ・ふぉーす",e:"Phantom Knights' Rank-Up-Magic Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに、自分の墓地から闇属性モンスターを任意の数だけ除外し、自分フィールドの闇属性Xモンスター１体を対象として発動できる。\n除外した数だけ、その自分のモンスターよりランクが高い、「幻影騎士団」、「RR」、「エクシーズ・ドラゴン」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣の戦線",h:"とらいぶりげーど・らいん",e:"Tri-Brigade Stand-Off",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分は獣族・獣戦士族・鳥獣族モンスターしかEXデッキから特殊召喚できない。\n②：自分の手札・フィールドからモンスター１体を墓地へ送って発動できる。\n墓地へ送ったモンスターとは元々の種族が異なる「トライブリゲード」モンスター１体をデッキから手札に加える。\n③：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\nこのターン相手は攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣の凶襲",h:"とらいぶりげーど・えあぼーん",e:"Tri-Brigade Airborne Assault",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの獣族・獣戦士族・鳥獣族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力以下で種族が異なる獣族・獣戦士族・鳥獣族モンスター１体をデッキから守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果はターン終了時まで無効化される。\nこの効果の発動後、ターン終了時まで自分はリンクモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"教導神理",h:"どらぐまてぃずむ",e:"Dogmatikacism",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターが「ドラグマ」モンスターの場合、装備モンスターの攻撃力は自身のレベル×１００アップする。\n「ドラグマ」モンスター以外の場合、装備モンスターの攻撃力は自分フィールドの「ドラグマ」モンスターの数×２００ダウンする。\n②：装備モンスターが破壊された事でこのカードが墓地へ送られた場合に発動できる。\nEXデッキからモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺都－九竜",h:"でんのうかいと－ぢうろん",e:"Virtual World City - Kauwloon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「電脳堺門」カード１枚を自分の魔法＆罠ゾーンに表側表示で置く。\nその後、自分フィールドの「電脳堺門」カードの数によって以下の効果をそれぞれ適用できる。\n\n●２枚以上：このターン、自分フィールドの「電脳堺」モンスターの攻撃力は２００アップする。\n\n●３枚以上：自分のデッキの上からカードを３枚墓地へ送る。\n\n●４枚：EXデッキから「電脳堺」モンスターを４体まで特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺門－青龍",h:"でんのうかいもん－ちんろん",e:"Virtual World Gate - Qinglong",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「電脳堺」カード１枚を除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n②：墓地のこのカードを除外して発動できる。\nデッキから「電脳堺」モンスター１体を手札に加える。\nその後手札を１枚選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"双天招来",h:"そうてんしょうらい",e:"Dual Avatar Invitation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚捨て、自分フィールドに「双天魂トークン」（戦士族・光・星２・攻／守０）を可能な限り特殊召喚する。\nこのターン、自分は融合モンスターしかEXデッキから特殊召喚できず、自分フィールドのトークンはリリースできずエンドフェイズに破壊される。\nその後、以下の効果を２回まで適用できる。\n●自分の手札・フィールドから、「双天」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"阿吽の呼吸",h:"あうんのこきゅう",e:"Perfect Sync - A-Un",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「双天」モンスター１体を手札に加える事ができる。\n②：自分フィールドに「双天」効果モンスターが存在する場合に発動できる。\n自分フィールドに「双天魂トークン」（戦士族・光・星２・攻／守０）１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"双天の調伏",h:"そうてんのちょうぶく",e:"Dual Avatar Defeating Evil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「双天」モンスター１体と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果で自分フィールドの「双天」融合モンスターを破壊した場合、さらに以下の効果から１つを選んで適用できる。\n\n●自分はデッキから１枚ドローする。\n\n●相手の墓地からカード１枚を選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・パラノイア",h:"いんふぇるにてぃ・ぱらのいあ",e:"Infernity Paranoia",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの闇属性モンスター１体をリリースして発動できる。\nそのモンスターとはカード名が異なり、レベルが同じ「インフェルニティ」モンスター１体を自分のデッキ・墓地から選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：墓地のこのカードを除外し、自分の墓地の「インフェルニティ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.ハイパー・スタジアム",h:"うるとらあすりーとはいぱー・すたじあむ",e:"U.A. Hyper Stadium",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「F.A.」カードとしても扱う。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「U.A.」モンスターまたは「F.A.」モンスター１体を手札に加えるか、自分の墓地から「U.A.スタジアム」１枚を選んで手札に加える事ができる。\n②：手札のフィールド魔法カード１枚を相手に見せ、１０００LPを払って発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、「U.A.」モンスターまたは「F.A.」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.ロッカールーム",h:"うるとらあすりーとろっかーるーむ",e:"U.A. Locker Room",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「F.A.」カードとしても扱う。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：自分のフィールド・墓地の、「U.A.」モンスターまたは「F.A.」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、自分は５００LP回復する。\nその後、以下の効果を適用できる。\n\n●手札の、「U.A.」モンスターまたは「F.A.」モンスターを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒界の援軍",h:"あんこくかいのえんぐん",e:"Charge Into a Dark World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベル４以下の悪魔族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、手札から悪魔族モンスター１体を選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナリーディング",h:"あるかなりーでぃんぐ",e:"Arcana Reading",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：コイントスを１回行い、その裏表によって以下の効果を適用する。\n自分のフィールドゾーンに「光の結界」が存在する場合、コイントスを行わず裏表のどちらかを選んで適用する。\n\n●表：デッキから「アルカナリーディング」以外のコイントスを行う効果を持つカード１枚を手札に加える。\n\n●裏：相手は自身のデッキからカード１枚を選んで手札に加える。\n\n②：墓地のこのカードを除外して発動できる。\n手札から「アルカナフォース」モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"新風の空牙団",h:"しんぷうのくうがだん",e:"Rookie Fur Hire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「空牙団」モンスターでしか攻撃宣言できない。\n①：自分フィールドのモンスター１体をリリースして発動できる。\nそのモンスターよりレベルが１つ高い、またはレベルが１つ低い「空牙団」モンスター１体を手札・デッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ・インポート",h:"えくしーず・いんぽーと",e:"Xyz Import",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのXモンスター１体と、その攻撃力以下の攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターをその自分のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャック・イン・ザ・ハンド",h:"じゃっく・いん・ざ・はんど",e:"Jack-In-The-Hand",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからカード名が異なるレベル１モンスター３体を相手に見せ、相手はその中から１体を選んで自身の手札に加える。\n自分は残りのカードの中から１体を選んで手札に加え、残りをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"レイダーズ・アンブレイカブル・マインド",h:"れいだーず・あんぶれいかぶる・まいんど",e:"Raider's Unbreakable Mind",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名はルール上「幻影騎士団」カード、「RR」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が闇属性Xモンスターを素材としたX召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：魔法＆罠ゾーンの表側表示のこのカードが効果で破壊された場合に発動できる。\n自分のデッキ・墓地から「RUM」魔法カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"RR－ファントム・クロー",h:"れいど・らぷたーず－ふぁんとむ・くろー",e:"Raidraptor's Phantom Knights Claw",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：モンスターの効果が発動した時、自分フィールドの闇属性XモンスターのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。\nこのカードを発動するために取り除いたX素材が「幻影騎士団」、「RR」、「エクシーズ・ドラゴン」カードだった場合、さらに自分フィールドの「RR」Xモンスター１体を選び、その攻撃力をこの効果で破壊したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣の抗戦",h:"とらいぶりげーど・りぼると",e:"Tri-Brigade Revolt",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、獣族・獣戦士族・鳥獣族モンスターを任意の数だけ選んで効果を無効にして特殊召喚し、そのモンスターのみを素材として「トライブリゲード」リンクモンスター１体をリンク召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣の血盟",h:"とらいぶりげーど・おーす",e:"Tri-Brigade Oath",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのリンクモンスター１体を対象として発動できる。\nそのモンスターとは種族が異なる獣族・獣戦士族・鳥獣族モンスター１体を自分の手札・墓地から選んで特殊召喚する。\n②：自分フィールドに獣族・獣戦士族・鳥獣族モンスターがそれぞれ１体以上存在する場合、墓地のこのカードを除外し、相手フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺門－朱雀",h:"でんのうかいもん－ちゅちゅえ",e:"Virtual World Gate - Chuche",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドの表側表示のカード１枚を対象として発動できる。\n除外されている自分の「電脳堺」カード２枚を選んでデッキに戻す（同名カードは１枚まで）。\nその後、対象のカードを破壊する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの「電脳堺」モンスター１体を対象として発動できる。\nそのモンスターのレベルまたはランクをターン終了時まで３つ上げる、または下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"双天の再来",h:"そうてんのさいらい",e:"Dual Avatar Return",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「双天」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で元々のレベルが４以下の「双天」モンスターを特殊召喚した場合、さらに自分フィールドに「双天魂トークン」（戦士族・光・星２・攻／守０）１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"双天の使命",h:"そうてんのしめい",e:"Dual Avatar Compact",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに、「双天の使命」以外の自分の墓地の「双天」魔法・罠カード１枚を除外して発動できる。\nこのカードの効果はその魔法・罠カード発動時の効果と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・サプレッション",h:"いんふぇるにてぃ・さぷれっしょん",e:"Infernity Suppression",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n自分の手札が０枚の場合、このカードはセットしたターンでも発動できる。\n①：自分フィールドに「インフェルニティ」モンスターが存在し、相手がモンスターの効果を発動した時に発動できる。\nその効果を無効にする。\nその後、そのモンスターのレベル×１００ダメージを相手に与える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"U.A.マン・オブ・ザ・マッチ",h:"うるとらあすりーとまん・おぶ・ざ・まっち",e:"U.A. Man of the Match",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名はルール上「F.A.」カードとしても扱う。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：自分の「U.A.」モンスターまたは「F.A.」モンスターが、戦闘で相手モンスターを破壊したダメージ計算後、または相手に戦闘ダメージを与えた時に発動できる。\n自分の手札・墓地から、「U.A.」モンスター及び「F.A.」モンスターを任意の数だけ選んで守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"魍魎跋扈",h:"もうりょうばっこ",e:"Free-Range Monsters",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分・相手のメインフェイズに発動できる。\nモンスター１体を通常召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ワーニングポイント",h:"わーにんぐぽいんと",e:"Warning Point",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：モンスターが召喚・反転召喚・特殊召喚された時に発動できる。\nこのターン、その表側表示モンスターは攻撃できず、効果は無効化され、融合・S・X・リンク召喚の素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔獣の大餌",h:"ふぃーど・ぱっく",e:"Banquet of Millions",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のEXデッキのカードを任意の数だけ裏側表示で除外し、その数だけ相手のEXデッキの裏側表示のカードをランダムに選んでエンドフェイズまで表側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"一か八か",h:"いちかばちか",e:"One or Eight",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のLPが相手より少ない場合に発動できる。\n自分のデッキの一番上のカードをお互いに確認し、レベルが１または８のモンスターだった場合、そのモンスターを手札に加えるか特殊召喚する。\n違った場合、相手は以下の効果から１つを選んで適用できる。\n\n●このカードのコントローラーのLPを１０００にする。\n\n●このカードのコントローラーから見て相手のLPを８０００になるまで回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"妖竜マハーマ",h:"ようりゅうまはーま",e:"Mahaama the Fairy Dragon",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2500,tx:"①：相手ターンに、自分または相手が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、以下の効果から１つを選んで適用する。\n\n●その戦闘ダメージの数値分だけ自分のLPを回復する。\n\n●その戦闘ダメージの数値分だけ相手にダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ジャブィアント・パンダ",h:"じゃぶぃあんと・ぱんだ",e:"Jabbing Panda",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：フィールドに獣戦士族モンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがフィールドから墓地へ送られた場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"瓔珞帝華－ペリアリス",h:"ようらくていか－ぺりありす",e:"Periallis, Empress of Blossoms",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:1600,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカード以外の自分フィールドの植物族モンスターの数×４００アップする。\n②：自分メインフェイズに発動できる。\n自分の手札・墓地から「瓔珞帝華－ペリアリス」以外のレベル５以上の植物族モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイコ・イレイザー",h:"さいこ・いれいざー",e:"Psychic Eraser Laser",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：EXデッキから特殊召喚された相手フィールドのモンスター１体を選んで墓地へ送る。\nその後、墓地へ送ったモンスターの元々の攻撃力と元々の守備力の内、高い方の数値分だけ相手のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・トランスミッション",h:"しんくろ・とらんすみっしょん",e:"Synchro Transmission",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\n自分フィールドのモンスターを素材としてS召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地のSモンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。\nその後、自分はデッキから１枚ドローする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"被検体ミュートリアM－０５",h:"ひけんたいみゅーとりあＭ－０５",e:"Myutant M-05",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「被検体ミュートリアM－０５」以外の「ミュートリア」モンスター１体を手札に加える。\n②：このカードをリリースし、手札または自分フィールドの表側表示のカード１枚を除外して発動できる。\n除外したカードの種類により以下のモンスター１体を手札・デッキから特殊召喚する。\n\n●モンスター：「ミュートリアル・ビースト」\n●魔法：「ミュートリアル・ミスト」\n●罠：「ミュートリアル・アームズ」",li:"",lk:[],ta:[],al:[]},
  {n:"被検体ミュートリアST－４６",h:"ひけんたいみゅーとりあＳＴ－４６",e:"Myutant ST-46",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ミュートリア」魔法・罠カード１枚を手札に加える。\n②：このカードをリリースし、手札または自分フィールドの表側表示のカード１枚を除外して発動できる。\n除外したカードの種類により以下のモンスター１体を手札・デッキから特殊召喚する。\n\n●モンスター：「ミュートリアル・ビースト」\n●魔法：「ミュートリアル・ミスト」\n●罠：「ミュートリアル・アームズ」",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリアル・ビースト",h:"みゅーとりある・びーすと",e:"Myutant Beast",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2400,de:2400,tx:"このカードは「ミュートリア」カードの効果でしか特殊召喚できない。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは相手モンスターの効果の対象にならない。\n②：相手が魔法カードの効果を発動した時、自分の手札・フィールドからカード１枚を除外して発動できる。\nその発動を無効にし除外する。\n③：このカードが相手によって破壊された場合、除外されている自分の「ミュートリア」罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリアル・ミスト",h:"みゅーとりある・みすと",e:"Myutant Mist",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2700,de:2900,tx:"このカードは「ミュートリア」カードの効果でしか特殊召喚できない。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは相手の魔法カードの効果の対象にならない。\n②：相手が罠カードの効果を発動した時、自分の手札・フィールドからカード１枚を除外して発動できる。\n自分はデッキから２枚ドローする。\n③：このカードが相手によって破壊された場合、除外されている自分の「ミュートリア」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリアル・アームズ",h:"みゅーとりある・あーむず",e:"Myutant Arsenal",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:1000,tx:"このカードは「ミュートリア」カードの効果でしか特殊召喚できない。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは相手の罠カードの効果の対象にならない。\n②：相手がモンスターの効果を発動した時、自分の手札・フィールドからカード１枚を除外し、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n③：このカードが相手によって破壊された場合、除外されている自分の「ミュートリア」魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリア進化研究所",h:"みゅーとりあしんかけんきゅうじょ",e:"Myutant Evolution Lab",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、手札及び除外されている自分のモンスターの中から、レベル４以下の「ミュートリア」モンスター１体を選んで特殊召喚できる。\n②：自分フィールドの「ミュートリア」モンスターの攻撃力は、除外されている自分の「ミュートリア」カードのカード名の種類×１００アップする。\n③：１ターンに１度、自分メインフェイズに発動できる。\n手札から「ミュートリア」モンスター１体をデッキの一番下に戻し、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリア超個体系",h:"みゅーとりあちょうこたいけい",e:"Myutant Expansion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキからレベル４以下の「ミュートリア」モンスター１体を手札に加えるか特殊召喚できる。\n②：自分フィールドのレベル８以上の「ミュートリア」モンスターが効果で破壊される場合、代わりにフィールドのこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"太陽の魔術師エダ",h:"たいようのまじゅつしえだ",e:"Eda the Sun Magician",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・リバースした場合に発動できる。\n手札・デッキから「太陽の魔術師エダ」以外の守備力１５００の魔法使い族モンスター１体を裏側守備表示で特殊召喚する。\n②：相手メインフェイズに発動できる。\n自分フィールドの裏側表示の魔法使い族モンスター１体を選んで表側攻撃表示または表側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"輪廻竜サンサーラ",h:"りんねりゅうさんさーら",e:"",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：ドラゴン族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n②：墓地のこのカードを除外し、自分の墓地のレベル５以上のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、そのモンスターをアドバンス召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"受け継ぎし魂",h:"うけつぎしたましい",e:"Successor Soul",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はモンスター１体でしか攻撃できない。\n①：自分フィールドの効果モンスター１体をリリースし、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送る。\nその後、手札・デッキからレベル７以上の通常モンスター１体を自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"束ねられし力",h:"たばねられしちから",e:"Strength in Unity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「青眼の白龍」または「ブラック・マジシャン」を使用した儀式・融合召喚に成功した場合、相手のフィールド・墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分の墓地のレベル７以上の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加えるかデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"対峙する宿命",h:"たいじするしゅくめい",e:"Destined Rivals",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「青眼の白龍」または「ブラック・マジシャン」が存在する場合に発動できる。\n相手フィールドの全ての表側表示モンスターの効果はターン終了時まで無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"超重禽属コカトリウム",h:"ちょうじゅうきんぞくこかとりうむ",e:"",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの獣族・獣戦士族・鳥獣族モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：デッキからレベル４以下の獣族・獣戦士族・鳥獣族モンスター１体を除外して発動できる。\nエンドフェイズまで、このカードは除外したモンスターと同名カードとして扱い、同じ種族・属性・レベルになる。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺嫦－兎々",h:"でんのうかいじょう－とぅとぅ",e:"Virtual World Oto-Hime - Toutou",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが、存在しない場合またはサイキック族・幻竜族モンスターのみの場合、このカードはリリースなしで召喚できる。\n②：このカードが墓地に存在する場合、手札からサイキック族・幻竜族モンスター１体を捨てて発動できる。\nこのカードをチューナー扱いで特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこのターン、自分はレベルまたはランクが３以上のモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティアームズ－グラム",h:"どらぐにてぃあーむず－ぐらむ",e:"Dragunity Arma Gram",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2900,de:2200,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地からドラゴン族・鳥獣族モンスター２体を除外して発動できる。\nこのカードを手札・墓地から特殊召喚する。\n②：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果は無効化され、その攻撃力は自分フィールドの装備カードの数×１０００ダウンする。\n③：相手フィールドのモンスターが戦闘で破壊され墓地へ送られた時に発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－レガトゥス",h:"どらぐにてぃ－れがとぅす",e:"Dragunity Legatus",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「ドラグニティ」モンスターまたは「竜の渓谷」が存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分の魔法＆罠ゾーンに「ドラグニティ」モンスターカードが存在する場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－レムス",h:"どらぐにてぃ－れむす",e:"Dragunity Remus",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードをS素材とする場合、「ドラグニティ」モンスターのS召喚にしか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「竜の渓谷」１枚を手札に加える。\n②：自分フィールドに「ドラグニティ」モンスターが存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこのターン、自分はドラゴン族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ・ドラフト",h:"どらぐにてぃ・どらふと",e:"Dragunity Draft",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時に、自分の墓地のレベル４以下の「ドラグニティ」モンスター１体を対象にできる。\nその場合、そのモンスターを手札に加える。\n②：このカードが魔法＆罠ゾーンに存在し、元々のレベルが５以上の自分の「ドラグニティ」モンスターが攻撃する場合、そのモンスターはダメージステップ終了時まで相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"疾風のドラグニティ",h:"しっぷうのどらぐにてぃ",e:"Dragunity Whirlwind",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドにのみモンスターが存在する場合に発動できる。\nデッキから「ドラグニティ」チューナーと鳥獣族の「ドラグニティ」モンスターを１体ずつ効果を無効にして特殊召喚する。\nEXデッキから特殊召喚されたモンスターが相手フィールドに存在する場合、さらに自分フィールドの「ドラグニティ」モンスターのみを素材としてドラゴン族Sモンスター１体をS召喚できる。\nこのカードの発動後、ターン終了時まで自分はドラゴン族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ・グロー",h:"どらぐにてぃ・ぐろー",e:"Dragunity Glow",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のデッキ・墓地からレベル５以上の「ドラグニティ」モンスター１体を選んで手札に加える。\n②：墓地のこのカードを除外し、「ドラグニティ」モンスターが装備している自分の魔法＆罠ゾーンのモンスターカード１枚を対象として発動できる。\nそのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ・ヴォイド",h:"どらぐにてぃ・う゛ぉいど",e:"Dragunity Oubliette",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「ドラグニティ」Sモンスターが存在し、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし除外する。\n自分フィールドにレベル１０の「ドラグニティ」モンスターが存在する場合、さらに自分フィールドの「ドラグニティ」モンスター１体を選び、その攻撃力を表側表示で除外されているカードの数×１００アップできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－アラドヴァル",h:"どらぐにてぃないと－あらどう゛ぁる",e:"Dragunity Knight - Areadbhair",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3200,tx:"「ドラグニティ」チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの効果を発動した時、自分の墓地から「ドラグニティ」モンスター１体を除外して発動できる。\nその発動を無効にし除外する。\n②：このカードが戦闘で相手モンスターを破壊したダメージ計算後に発動できる。\nその相手モンスターを除外する。\n③：S召喚したこのカードが相手によって破壊された場合に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"法典の大賢者クロウリー",h:"まぎすとす・ぐりもわくろうりー",e:"Crowley, the Magistus of Grimoires",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、魔法使い族モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：属性を１つ宣言して発動できる。\nこのカードはターン終了時まで宣言した属性になる。\n③：墓地のこのカードを除外し、自分フィールドの「マギストス」モンスター１体を対象として発動できる。\n自分の墓地からレベル４以外の「マギストス」モンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"絶火の大賢者ゾロア",h:"まぎすとす・ふれいむぞろあ",e:"Zoroa, the Magistus of Flame",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「マギストス」モンスター１体を対象として発動できる。\nEXデッキから「マギストス」モンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。\n②：このカードが「マギストス」モンスターカードを装備した場合に発動できる。\n自分の手札・墓地から「絶火の大賢者ゾロア」以外のレベル４の魔法使い族モンスター１体を選んで守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"結晶の大賢者サンドリヨン",h:"まぎすとす・う゛ぇーるさんどりよん",e:"Rilliona, the Magistus of Verre",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、以下の効果から１つを選択して発動できる。\n●デッキから「マギストス」魔法・罠カード１枚を手札に加える。\n●除外されている自分のレベル４以下の魔法使い族モンスター１体を選んで墓地に戻す。\n②：墓地のこのカードを除外し、自分フィールドの「マギストス」モンスター１体を対象として発動できる。\n自分の墓地からレベル４以外の「マギストス」モンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖魔の大賢者エンディミオン",h:"まぎすとす・ますたーえんでぃみおん",e:"Endymion, the Magistus of Mastery",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「マギストス」モンスター１体を対象として発動できる。\nEXデッキから「マギストス」モンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。\n②：自分フィールドの表側表示の魔法カード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。\nその後、手札を１枚選んでデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"法典の守護者アイワス",h:"まぎすとす・せいんとあいわす",e:"Aiwass, the Magistus Spell Spirit",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2800,tx:"「マギストス」モンスター＋魔法使い族モンスター\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\n自分フィールドのこのカードを装備カード扱いとしてそのモンスターに装備する。\nこの効果でこのカードを相手モンスターに装備した場合には、装備モンスターの効果は発動できず、そのコントロールを得る。\n②：このカードを装備したモンスターの攻撃力・守備力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"絶火の竜神ヴァフラム",h:"まぎすとす・どらごんう゛ぁふらむ",e:"Vahram, the Magistus Divinity Dragon",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2900,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：S召喚したこのカードが破壊された場合に発動できる。\n相手フィールドの表側表示のカードを全て破壊する。\n②：このカードを装備したモンスターは相手の魔法・罠カードの効果では破壊されない。\n③：このカードを装備したモンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"結晶の女神ニンアルル",h:"まぎすとす・ごっですにんあるる",e:"Ninaruru, the Magistus Glass Goddess",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2400,tx:"魔法使い族レベル４モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、自分の墓地のレベル４以上の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードを装備したモンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードが装備されている場合、自分の魔法＆罠ゾーンの「マギストス」カード１枚と相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖魔の乙女アルテミス",h:"まぎすとす・めいでんあるてみす",e:"Artemis, the Magistus Moon Maiden",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"レベル４以下の魔法使い族モンスター１体\n自分は「聖魔の乙女アルテミス」を１ターンに１度しか特殊召喚できず、その①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、他の「マギストス」モンスターが召喚・特殊召喚された場合、そのモンスター１体を対象として発動できる。\n自分フィールドのこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードが装備されている場合に発動できる。\nデッキから「マギストス」モンスター１体を手札に加える。",li:"",lk:["上"],ta:[],al:[]},
  {n:"三賢者の書",h:"とりす・まぎすとす",e:"Trismagistus",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、装備カードを装備した「マギストス」モンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n②：自分メインフェイズに発動できる。\n手札からレベル４の魔法使い族モンスター１体を特殊召喚する。\n③：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n手札から「マギストス」モンスターを任意の数だけ特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"大いなる魔導",h:"てうろぎあ・まぎすとす",e:"Magistus Theurgy",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「マギストス」モンスター１体を対象として発動できる。\n自分のEXデッキ・フィールド・墓地からレベル４以外の「マギストス」モンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。\n自分の墓地に「マギストス」融合・S・X・リンクモンスターがそれぞれ１体以上存在する場合、この効果で装備するモンスターを、「マギストス」モンスター以外のEXデッキの融合・S・X・リンクモンスターから選ぶ事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる法典",h:"まぎすとす・いんう゛ぉけーしょん",e:"Magistus Invocation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、融合モンスターカードによって決められた魔法使い族モンスターを含む融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n「マギストス」融合モンスターを融合召喚する場合、「マギストス」モンスターが装備している自分の魔法＆罠ゾーンの装備カード扱いの融合素材モンスターも融合素材に使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"絶火の祆現",h:"う゛りとら・まぎすとす",e:"Magistus Vritra",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のレベル４以下の「マギストス」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分の魔法＆罠ゾーンの「マギストス」カードが相手の効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Live☆Twin キスキル",h:"らいぶついん　きすきる",e:"Live☆Twin Ki-sikil",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに他のモンスターが存在せず、このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから「リィラ」モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手モンスターが攻撃宣言する度に、自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"Live☆Twin リィラ",h:"らいぶついん　りぃら",e:"Live☆Twin Lil-la",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに他のモンスターが存在せず、このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから「キスキル」モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手は５００LPを払わなければ攻撃宣言できない。",li:"",lk:[],ta:[],al:[]},
  {n:"Evil★Twin キスキル",h:"いびるついん　きすきる",e:"Evil★Twin Ki-sikil",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1100,de:"-",tx:"「キスキル」モンスターを含むモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「リィラ」モンスターが存在し、このカードが特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：自分フィールドに「リィラ」モンスターが存在しない場合、自分・相手のメインフェイズに発動できる。\n自分の墓地から「リィラ」モンスター１体を選んで特殊召喚する。\nこのターン、自分は悪魔族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"Evil★Twin リィラ",h:"いびるついん　りぃら",e:"Evil★Twin Lil-la",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:1100,de:"-",tx:"「リィラ」モンスターを含むモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「キスキル」モンスターが存在し、このカードが特殊召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分フィールドに「キスキル」モンスターが存在しない場合、自分・相手のメインフェイズに発動できる。\n自分の墓地から「キスキル」モンスター１体を選んで特殊召喚する。\nこのターン、自分は悪魔族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:["左","下"],ta:[],al:[]},
  {n:"Evil★Twins キスキル・リィラ",h:"いびるついんず　きすきる・りぃら",e:"Evil★Twins Ki-sikil & Lil-la",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカードは通常召喚できない。\n自分フィールドのリンクモンスター２体をリリースした場合のみ手札・墓地から特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n相手は自身のフィールドのカードが３枚以上の場合には２枚になるように墓地へ送らなければならない。\n②：自分の墓地に「キスキル」モンスター及び「リィラ」モンスターが存在する限り、このカードの攻撃力・守備力は２２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"Live☆Twin エントランス",h:"らいぶついん　えんとらんす",e:"Live☆Twin Home",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚捨てて発動できる。\nデッキから「キスキル」モンスターまたは「リィラ」モンスター１体を特殊召喚する。\nこのカードの発動後、ターン終了時まで自分は「イビルツイン」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"Live☆Twin チャンネル",h:"らいぶついん　ちゃんねる",e:"Live☆Twin Channel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手のモンスターの攻撃宣言時に、自分フィールドの「キスキル」モンスターまたは「リィラ」モンスター１体をリリースして発動できる。\nその攻撃を無効にする。\n②：自分・相手のエンドフェイズに、自分の墓地の「キスキル」モンスターまたは「リィラ」モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\n自分フィールドにモンスターが存在しない場合、デッキに戻さず手札に加える事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"シークレット・パスフレーズ",h:"しーくれっと・ぱすふれーず",e:"Secret Password",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「ライブツイン」魔法・罠カードまたは「イビルツイン」魔法・罠カード１枚を手札に加える。\n自分フィールドに「キスキル」モンスター及び「リィラ」モンスターが存在する場合、代わりに「イビルツイン」モンスター１体を手札に加える事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"Evil★Twin チャレンジ",h:"いびるついん　ちゃれんじ",e:"Evil★Twin Challenge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「キスキル」モンスターまたは「リィラ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、以下の効果を適用できる。\n●自分フィールドのモンスターをリンク素材として「イビルツイン」リンクモンスター１体をリンク召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Evil★Twin イージーゲーム",h:"いびるついん　いーじーげーむ",e:"Evil★Twin GG EZ",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「キスキル」モンスターまたは「リィラ」モンスター１体をリリースし、以下の効果から１つを選択して発動できる。\n\n●自分フィールドの「キスキル」モンスターまたは「リィラ」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分アップする。\n\n●フィールドのカードを破壊する魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"Evil★Twin プレゼント",h:"いびるついん　ぷれぜんと",e:"Evil★Twin Present",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「キスキル」モンスター及び「リィラ」モンスターが存在する場合、以下の効果から１つを選択して発動できる。\n\n●自分フィールドの「キスキル」モンスターまたは「リィラ」モンスター１体と、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスター２体のコントロールを入れ替える。\n\n●相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"竜輝巧－バンα",h:"どらいとろん－ばんあるふぁ",e:"Drytron Alpha Thuban",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できず、「ドライトロン」カードの効果でのみ特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、このカード以外の「ドライトロン」モンスターまたは儀式モンスター１体をリリースして発動できる。\nこのカードを手札・墓地から守備表示で特殊召喚する。\nその後、デッキから儀式モンスター１体を手札に加える事ができる。\nこの効果を発動するターン、自分は通常召喚できないモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜輝巧－ラスβ",h:"どらいとろん－らすべーた",e:"Drytron Beta Rastaban",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できず、「ドライトロン」カードの効果でのみ特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、このカード以外の「ドライトロン」モンスターまたは儀式モンスター１体をリリースして発動できる。\nこのカードを手札・墓地から守備表示で特殊召喚する。\nその後、除外されている自分の「ドライトロン」モンスター１体を選んで墓地に戻す事ができる。\nこの効果を発動するターン、自分は通常召喚できないモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜輝巧－エルγ",h:"どらいとろん－えるがんま",e:"Drytron Gamma Eltanin",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できず、「ドライトロン」カードの効果でのみ特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、このカード以外の「ドライトロン」モンスターまたは儀式モンスター１体をリリースして発動できる。\nこのカードを手札・墓地から守備表示で特殊召喚する。\nその後、自分の墓地から「竜輝巧－エルγ」以外の攻撃力２０００の「ドライトロン」モンスター１体を選んで特殊召喚できる。\nこの効果を発動するターン、自分は通常召喚できないモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜輝巧－アルζ",h:"どらいとろん－あるぜーた",e:"Drytron Zeta Aldhibah",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できず、「ドライトロン」カードの効果でのみ特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、このカード以外の「ドライトロン」モンスターまたは儀式モンスター１体をリリースして発動できる。\nこのカードを手札・墓地から守備表示で特殊召喚する。\nその後、デッキから儀式魔法カード１枚を手札に加える事ができる。\nこの効果を発動するターン、自分は通常召喚できないモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜輝巧－ルタδ",h:"どらいとろん－るたでるた",e:"Drytron Delta Altais",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できず、「ドライトロン」カードの効果でのみ特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、このカード以外の「ドライトロン」モンスターまたは儀式モンスター１体をリリースして発動できる。\nこのカードを手札・墓地から守備表示で特殊召喚する。\nその後、手札の儀式モンスター１体または儀式魔法カード１枚を相手に見せて自分はデッキから１枚ドローできる。\nこの効果を発動するターン、自分は通常召喚できないモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"竜儀巧－メテオニス＝DRA",h:"どらいとろん－めておにす＝DRA",e:"Drytron Meteonis Draconids",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"機械族",mo:["儀式","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「流星輝巧群」により降臨。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードは相手モンスターの効果の対象にならない。\n②：このカードの儀式召喚に使用したモンスターのレベルの合計が２以下の場合、このカードは特殊召喚された相手モンスター全てに１回ずつ攻撃できる。\n③：相手ターンに、攻撃力の合計が２０００または４０００になるように自分の墓地からモンスターを除外し、その合計２０００につき１枚、相手フィールドの表側表示のカードを対象として発動できる。\nそのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"竜儀巧－メテオニス＝QUA",h:"どらいとろん－めておにす＝QUA",e:"Drytron Meteonis Quadrantids",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"機械族",mo:["儀式","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「流星輝巧群」により降臨。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードは相手の魔法・罠カードの効果の対象にならない。\n②：このカードの儀式召喚に使用したモンスターのレベルの合計が２以下の場合に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。\n③：儀式召喚したこのカードが破壊された場合に発動できる。\n自分の墓地から、攻撃力の合計が４０００になるように「竜儀巧－メテオニス＝QUA」以外の「ドライトロン」モンスターを任意の数だけ選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"流星輝巧群",h:"めておにす・どらいとろん",e:"Meteonis Drytron",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"儀式モンスターの降臨に必要。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：攻撃力の合計が儀式召喚するモンスターの攻撃力以上になるように、自分の手札・フィールドの機械族モンスターをリリースし、自分の手札・墓地から儀式モンスター１体を儀式召喚する。\n②：このカードが墓地に存在する場合、自分フィールドの「ドライトロン」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を相手ターン終了時まで１０００ダウンし、このカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜輝巧－ファフニール",h:"どらいとろん－ふぁふにーる",e:"Drytron Fafnir",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「竜輝巧－ファフニール」以外の「ドライトロン」魔法・罠カード１枚を手札に加える事ができる。\n②：儀式魔法カードの効果の発動及びその発動した効果は無効化されない。\n③：１ターンに１度、自分フィールドに「ドライトロン」モンスターが存在する状態で、モンスターが表側表示で召喚・特殊召喚された場合に発動できる。\nこのターン、その表側表示モンスターのレベルは、その攻撃力１０００につき１つ下がる（最小１まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"極超の竜輝巧",h:"どらいとろん・のう゛ぁ",e:"Drytron Nova",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は通常召喚できないモンスターしか特殊召喚できない。\n①：デッキから「ドライトロン」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"喰光の竜輝巧",h:"どらいとろん・えくりぷす",e:"Drytron Eclipse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「ドライトロン」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：墓地のこのカードを除外し、自分フィールドの「ドライトロン」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は相手ターン終了時まで２０００アップする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"星彩の竜輝巧",h:"どらいとろん・あすてりずむ",e:"Drytron Asterism",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに、自分フィールドの「ドライトロン」モンスターまたは儀式モンスター１体と、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターの攻撃力を相手ターン終了時まで１０００ダウンし、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドライトロン流星群",h:"どらいとろんりゅうせいぐん",e:"Drytron Meteor Shower",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに儀式モンスターが存在し、相手がモンスターを召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティ－ギザーム",h:"どらぐにてぃ－ぎざーむ",e:"",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の風属性・レベル４以下の、ドラゴン族または鳥獣族のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードが装備カード扱いとして装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン・サンダー LV１０",h:"あーむど・どらごん・さんだー　れべる１０",e:"Armed Dragon Thunder LV10",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：「アームド・ドラゴン」モンスターの効果で特殊召喚したこのカードは、自身の攻撃力によって以下の効果を得る。\n\n●１以上：カード名を「アームド・ドラゴン LV１０」として扱う。\n\n●１０以上：このコントロールは変更できない。\n\n●１００以上：戦闘では破壊されない。\n\n●１０００以上：相手ターンに１度、手札を１枚墓地へ送り、フィールドの他のカード１枚を対象として発動できる。\nそのカードを破壊し、自身の攻撃力を１０００アップする。\n\n●１００００以上：１ターンに１度、発動できる。\nフィールドの他のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン・サンダー LV７",h:"あーむど・どらごん・さんだー　れべる７",e:"Armed Dragon Thunder LV7",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アームド・ドラゴン LV７」として扱う。\n②：手札からモンスター１体を墓地へ送って発動できる。\nフィールドのこのカードを墓地へ送り、手札・デッキからレベル１０以下の「アームド・ドラゴン」モンスター１体を特殊召喚する。\n③：このカードがドラゴン族モンスターの効果を発動するために墓地へ送られた場合に発動できる。\nデッキから「アームド・ドラゴン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン・サンダー LV５",h:"あーむど・どらごん・さんだー　れべる５",e:"Armed Dragon Thunder LV5",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1700,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アームド・ドラゴン LV５」として扱う。\n②：手札からモンスター１体を墓地へ送って発動できる。\nフィールドのこのカードを墓地へ送り、手札・デッキからレベル７以下の「アームド・ドラゴン」モンスター１体を特殊召喚する。\n③：このカードがドラゴン族モンスターの効果を発動するために墓地へ送られた場合に発動できる。\nデッキからレベル５以上のドラゴン族・風属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン・サンダー LV３",h:"あーむど・どらごん・さんだー　れべる３",e:"Armed Dragon Thunder LV3",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アームド・ドラゴン LV３」として扱う。\n②：手札からモンスター１体を墓地へ送って発動できる。\nフィールドのこのカードを墓地へ送り、手札・デッキからレベル５以下の「アームド・ドラゴン」モンスター１体を特殊召喚する。\n③：このカードがドラゴン族モンスターの効果を発動するために墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"アームド・ドラゴン LV１０－ホワイト",h:"あーむど・どらごん　れべる１０－ほわいと",e:"Armed Dragon LV10 White",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2000,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：レベルの合計が１０になるように自分のフィールド・墓地から「アームド・ドラゴン」モンスターを除外して発動できる。\nこのカードを手札から特殊召喚する。\nその後、デッキから「白のヴェール」１枚を手札に加える事ができる。\n②：このカードのコントローラーが受ける効果ダメージは０になる。\n③：このカードが攻撃するダメージステップ開始時に発動できる。\nフィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・ロッキー",h:"すぷりがんず・ろっきー",e:"Springans Rockey",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「スプリガンズ・ロッキー」以外の自分の墓地の、「スプリガンズ」モンスター１体または「大砂海ゴールド・ゴルゴンダ」１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが手札・フィールド・墓地に存在する場合、自分フィールドの「スプリガンズ」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・ピード",h:"すぷりがんず・ぴーど",e:"Springans Pedor",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースし、「スプリガンズ・ピード」以外の自分の墓地の「スプリガンズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが手札・フィールド・墓地に存在する場合、自分フィールドの「スプリガンズ」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・バンガー",h:"すぷりがんず・ばんがー",e:"Springans Branga",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・フィールド・墓地に存在する場合、自分フィールドの「スプリガンズ」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。\n②：自分の墓地から「スプリガンズ・バンガー」以外の「スプリガンズ」モンスター１体とこのカードを除外して発動できる。\nデッキから「スプリガンズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・キャプテン サルガス",h:"すぷりがんず・きゃぷてん　さるがす",e:"Springans Captain Sargas",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・フィールド・墓地に存在する場合、自分フィールドの「スプリガンズ」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。\n②：相手ターンに、自分フィールドのX素材を１つ取り除き、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：このカードを素材として持っている「スプリガンズ」Xモンスターは以下の効果を得る。\n●このカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣戦線 キット",h:"とらいぶりげーど　きっと",e:"Tri-Brigade Kitt",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から獣族・獣戦士族・鳥獣族モンスターを任意の数だけ除外して発動できる。\n除外した数と同じ数のリンクマーカーを持つ獣族・獣戦士族・鳥獣族リンクモンスター１体をEXデッキから特殊召喚する。\nこのターン、自分は獣族・獣戦士族・鳥獣族モンスターしかリンク素材にできない。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「鉄獣戦線 キット」以外の「トライブリゲード」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force 乱破小夜丸",h:"せきゅりてぃ・ふぉーす　らっぱちよまる",e:"S-Force Rappa Chiyomaru",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分の「S－Force」モンスターの正面の相手モンスターは、自身と同じ縦列のモンスターしか攻撃対象に選択できない。\n②：手札から「S－Force」カード１枚を除外して発動できる。\nこのカードを持ち主の手札に戻し、デッキから「S－Force 乱破小夜丸」以外の「S－Force」モンスター１体を守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force プロフェッサー・Ϝ",h:"せきゅりてぃ・ふぉーす　ぷろふぇっさー・でぃがんま",e:"S-Force Professor DiGamma",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\n②：このカードがモンスターゾーンに存在する限り、自分の「S－Force」モンスターの正面の相手モンスターは効果以外では表示形式を変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force オリフィス",h:"せきゅりてぃ・ふぉーす　おりふぃす",e:"S-Force Orrafist",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分の「S－Force」モンスターの正面の相手モンスターは相手の効果の対象にならない。\n②：相手フィールドのモンスターが効果を発動した時、手札から「S－Force」カード１枚を除外して発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force グラビティーノ",h:"せきゅりてぃ・ふぉーす　ぐらびてぃーの",e:"S-Force Gravitino",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「S－Force グラビティーノ」以外の「S－Force」カード１枚を手札に加える。\n②：このカードがモンスターゾーンに存在する限り、自分の「S－Force」モンスターの正面の相手モンスターはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force プラ＝ティナ",h:"せきゅりてぃ・ふぉーす　ぷら＝てぃな",e:"S-Force Pla-Tina",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「S－Force プラ＝ティナ」以外の除外されている自分の「S－Force」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分の「S－Force」モンスターの正面の相手モンスターの攻撃力は６００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－ブリザード・ベル",h:"うぃんど・うぃっち－ぶりざーど・べる",e:"Windwitch - Blizzard Bell",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが、存在しない場合または「WW」モンスターのみの場合、このカードはリリースなしで召喚できる。\n②：自分フィールドに「WW－ブリザード・ベル」以外の「WW」モンスターが存在する場合、相手メインフェイズに、手札・フィールドのこのカードを墓地へ送って発動できる。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－フリーズ・ベル",h:"うぃんど・うぃっち－ふりーず・べる",e:"Windwitch - Freeze Bell",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが「WW」モンスターのみの場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのレベルを１つ上げる。\n③：このカードをS素材として風属性SモンスターをS召喚した場合、そのSモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神マルコシア",h:"まごうしんまるこしあ",e:"Fabled Marcosia",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合に発動できる。\n「魔轟神」モンスターを含む、「魔轟神マルコシア」以外の手札のモンスターを２体まで捨て、このカードを特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は、この効果で捨てたモンスターの数×２００アップする。\n②：このカードが手札から墓地へ捨てられた場合に発動する。\nデッキから「魔轟神」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神獣アバンク",h:"まごうしんじゅうあばんく",e:"The Fabled Abanc",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合に発動できる。\n手札から「魔轟神」モンスター１体を捨て、このカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"パラメタルフォーゼ・メルキャスター",h:"ぱらめたるふぉーぜ・めるきゃすたー",e:"Parametalfoes Melcaster",t:"monster",a:"炎",l:7,ps:1,pe:"①：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「メタルフォーゼ」魔法・罠カード１枚を選んで自分フィールドにセットする。",tr:"サイキック族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2500,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\n自分のEXデッキから「パラメタルフォーゼ・メルキャスター」以外の表側表示の「メタルフォーゼ」Pモンスター１体を手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードをPゾーンに発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"メタルフォーゼ・バニッシャー",h:"めたるふぉーぜ・ばにっしゃー",e:"Metalfoes Vanisher",t:"monster",a:"炎",l:9,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが効果で破壊された場合に発動できる。\n自分の墓地から「メタルフォーゼ・バニッシャー」以外の「メタルフォーゼ」カード１枚を選んで手札に加える。",tr:"サイキック族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2900,de:1900,tx:"このカード名の①②のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：「メタルフォーゼ」カードを含む、自分フィールドの表側表示のカード２枚を対象として発動できる。\nそのカードを破壊し、このカードを手札から特殊召喚する。\n②：このカードが「メタルフォーゼ」カードの効果で特殊召喚に成功した場合、相手のフィールド・墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"セイクリッド・カドケウス",h:"せいくりっど・かどけうす",e:"Constellar Caduceus",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1550,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「セイクリッド・カドケウス」以外の「セイクリッド」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\nデッキから「セイクリッド」魔法・罠カード１枚を手札に加える。\n③：このカードを素材として持っている「セイクリッド」Xモンスターは以下の効果を得る。\n●このカードが光・闇属性モンスターと戦闘を行うダメージ計算前に発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"電子光虫－レジストライダー",h:"でじたる・ばぐ－れじすとらいだー",e:"Digital Bug Registrider",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：自分が昆虫族・レベル３モンスターの召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、このカードとそのモンスターのレベルを５または７にできる。\n②：このカードが手札からの特殊召喚に成功した場合に発動できる。\n自分フィールドの昆虫族モンスター１体を選んで表示形式を変更する。\n③：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードの攻撃力・守備力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"光天のマハー・ヴァイロ",h:"こうてんのまはー・う゛ぁいろ",e:"Maha Vailo, Light of the Heavens",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1550,de:1400,tx:"①：このカードは、このカードの装備カードの数によって以下の効果を得る。\n\n●１枚以上：このカードの攻撃力は、このカードの装備カードの数×１０００アップする。\n\n●２枚以上：相手はバトルフェイズ中にモンスターの効果を発動できない。\n\n●３枚以上：このカードを対象とする相手の効果が発動した時、自分フィールドの装備カード１枚を墓地へ送って発動できる。\nその効果を無効にする。\n\n●４枚以上：相手はバトルフェイズ中にカードの効果を発動できない。\n\n●５枚以上：このカードが相手に与える戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華の叛－呂奉",h:"せんかのはん－りょほう",e:"Ancient Warriors - Rebellious Lu Feng",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n「戦華の叛－呂奉」は１ターンに１度、フィールドの「戦華」モンスターの内、攻撃力が一番高いモンスターが自分フィールドに存在する場合のみ特殊召喚できる。\n①：お互いのメインフェイズに１度、発動できる。\n相手フィールドの攻撃力が一番高いモンスター１体を破壊する。\nこの効果を発動するターン、自分は「戦華」モンスター以外のモンスター効果を発動できない。\n②：エンドフェイズに、フィールドの攻撃力が一番高いモンスターが相手フィールドに存在する場合に発動する。\nこのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の使徒－ネイロイ",h:"ゆめまきょうのしと－ねいろい",e:"Neiroy, the Dream Mirror Disciple",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドに「夢魔鏡」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、このカードを闇属性にできる。\n②：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動する。\nフィールドに「聖光の夢魔鏡」が存在する場合、相手フィールドの魔法・罠カード１枚を選んで持ち主の手札に戻す事ができる。\nフィールドに「闇黒の夢魔鏡」が存在する場合、自分はデッキから１枚ドローし、その後手札を１枚デッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・アンクラスペア",h:"ましんなーず・あんくらすぺあ",e:"Machina Unclaspare",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがドロー以外の方法で手札に加わった場合に発動できる。\nこのカードを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族モンスターしか特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「マシンナーズ・アンクラスペア」以外の「マシンナーズ」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"Live☆Twin リィラ・トリート",h:"らいぶついん　りぃら・とりーと",e:"Live☆Twin Lil-la Treat",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「キスキル」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：「イビルツイン」モンスターの戦闘で自分または相手が戦闘ダメージを受けた時、墓地のこのカードを除外し、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、その戦闘ダメージの数値分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"風の天翼ミラドーラ",h:"かぜのてんよくみらどーら",e:"Heavenly Zephyr - Miradora",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、その発動と効果は無効化されない。\n①：相手がEXデッキから攻撃力２０００以上のモンスターを特殊召喚した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、EXデッキから特殊召喚された相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのモンスターが表側表示で存在する間、対象のモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精弓士イングナル",h:"ようせいきゅうしいんぐなる",e:"Fairy Archer Ingunar",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが植物族モンスターの効果で特殊召喚に成功した場合、自分の墓地のレベル６以上の植物族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は植物族モンスターしか特殊召喚できない。\n②：このカードは特殊召喚したターンには攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"契珖のヴルーレセンス",h:"けいこうのう゛るーれせんす",e:"Radiant Vouirescence",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"①：相手フィールドにモンスターが存在し、このカードが召喚に成功した時、手札を１枚捨てて発動できる。\nデッキから「契珖のヴルーレセンス」を任意の数だけ墓地へ送る。\n②：１ターンに１度、このカードが墓地に存在する場合に発動できる。\n墓地のこのカードはターン終了時まで闇属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイカミナリ・ジャイクロプス",h:"だいかみなり・じゃいくろぷす",e:"Gigathunder Giclops",t:"monster",a:"風",l:4,ps:5,pe:"①：１ターンに１度、自分フィールドのPモンスター１体と相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",tr:"雷族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:1400,de:1700,tx:"EXデッキから特殊召喚したこのカードは、S召喚に使用された場合に除外される。\nこのカード名の②③のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動できる。\nこのカードの表示形式を変更する。\n②：このカードの表示形式が変更された場合、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n③：このカードがPゾーンで破壊された場合に発動できる。\n自分のPゾーンのカード１枚を選んで持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"天孔邪鬼",h:"あまのくじゃき",e:"Amanokujaki",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、自分はこのカード以外の、このカードと同じ属性の特殊召喚されたモンスターの効果を発動できない。\n②：１ターンに１度、自分・相手のメインフェイズに発動できる。\nこのカードのコントロールを相手に移す。\nその後、このカードの属性を次のターンの終了時まで任意の属性に変更できる。\nこの効果はこのカードの元々の持ち主のターンにしか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"黄紡鮄デュオニギス",h:"きほうぼうでゅおにぎす",e:"Guitar Gurnards Duonigis",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドの水属性モンスターの数だけ相手のデッキの上からカードを除外する。\n②：自分フィールドのレベル４以下の水属性モンスター１体を対象として発動できる。\nそのモンスターのレベルをその元々のレベル分だけ上げる。\n③：墓地のこのカードを除外して発動できる。\nフィールドの水属性モンスター１体を選び、その攻撃力をターン終了時まで、除外されているモンスターの数×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワイトベイキング",h:"わいとべいきんぐ",e:"Wightbaking",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、墓地に存在する限り「ワイト」として扱う。\n②：自分フィールドのレベル３以下のアンデット族モンスターが戦闘・効果で破壊される場合、代わりに手札のこのカードを捨てる事ができる。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから以下のモンスターを合計２体手札に加える（同名カードは１枚まで）。\nその後、手札を１枚選んで捨てる。\n\n●「ワイト」\n●「ワイトベイキング」以外の「ワイト」のカード名が記されたモンスター",li:"",lk:[],ta:[],al:[]},
  {n:"おジャマ・ピンク",h:"おじゃま・ぴんく",e:"Ojama Pink",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・フィールドから墓地へ送られた場合に発動できる。\nお互いのプレイヤーはデッキから１枚ドローし、その後手札を１枚選んで捨てる。\nこの効果で自分が「おジャマ」カードを捨てた場合、さらに使用していない相手のモンスターゾーンを１ヵ所指定できる。\nそのゾーンは相手ターン終了時まで使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧竜の聖騎士",h:"ないと・おぶ・あーまーどらごん",e:"Knight of Armor Dragon",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:1200,tx:"「鎧竜降臨」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚されたモンスターとこのカードが戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターを持ち主のデッキに戻す。\n②：このカードをリリースして発動できる。\n手札・デッキからレベル５以上のドラゴン族・風属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄駆竜スプリンド",h:"てっくりゅうすぷりんど",e:"Sprind the Irondash Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「アルバスの落胤」＋このターンに特殊召喚された効果モンスター\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分フィールドのこのカードの位置を、他の自分のメインモンスターゾーンに移動する。\nその後、移動したこのカードと同じ縦列の他の表側表示のカードを全て破壊できる。\n②：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「スプリガンズ」モンスターまたは「アルバスの落胤」１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"パラメタルフォーゼ・アゾートレス",h:"ぱらめたるふぉーぜ・あぞーとれす",e:"Parametalfoes Azortless",t:"monster",a:"炎",l:7,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の「メタルフォーゼ」カードが効果で破壊された場合、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"サイキック族",mo:["融合","ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「メタルフォーゼ」モンスター＋融合モンスター\nこのカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがEXデッキからの特殊召喚に成功した場合、相手フィールドのカード１枚を対象として発動できる。\n自分のEXデッキから表側表示のPモンスター２体をデッキに戻し、対象のカードを破壊する。\n②：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金狂エルドリッチ",h:"える・れい・こんきすたえるどりっち",e:"Eldlich the Mad Golden Lord",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"アンデット族",mo:["融合","効果"],ma:"",tp:"",at:3800,de:3500,tx:"「エルドリッチ」モンスター＋レベル５以上のアンデット族モンスター\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「黄金卿エルドリッチ」として扱う。\n②：フィールドのこのカードは戦闘・効果では破壊されない。\n③：自分フィールドのアンデット族モンスター１体をリリースし、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのコントロールを得る。\nそのモンスターはターン終了時まで、攻撃できず、効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"双天将 密迹",h:"そうてんしょう　みつじゃく",e:"Dual Avatar - Empowered Mitsu-Jaku",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:3000,tx:"「双天脚の鴻鵠」＋「双天」モンスター×２\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分の「双天」融合モンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n②：１ターンに１度、自分メインフェイズに発動できる。\n相手フィールドの魔法・罠カードを全て持ち主の手札に戻す。\n③：自分フィールドに融合モンスターが２体以上存在し、相手フィールドのモンスターの効果が発動した時に発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の魘魔－ネイロス",h:"ゆめまきょうのえんま－ねいろす",e:"Oneiros, the Dream Mirror Tormentor",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"属性が異なる「夢魔鏡」モンスター×２\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードの属性は「光」としても扱う。\n②：フィールドゾーンに「闇黒の夢魔鏡」が存在し、モンスターの効果が発動した時に発動できる。\nその効果を無効にする。\n③：フィールドゾーンに「聖光の夢魔鏡」が存在する場合、このカードをリリースして発動できる。\nEXデッキから「夢魔鏡の天魔－ネイロス」１体を守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"WW－ダイヤモンド・ベル",h:"うぃんど・うぃっち－だいやもんど・べる",e:"Windwitch - Diamond Bell",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2400,tx:"チューナー＋チューナー以外の風属性モンスター１体以上 \nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、自分の墓地の「WW」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力の半分のダメージを相手に与える。\n②：１ターンに１度、相手が戦闘・効果でダメージを受けた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこのカードが「WW」モンスターのみを素材としてS召喚されている場合、この効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神アンドレイス",h:"まごうしんあんどれいす",e:"Fabled Andwraith",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1900,tx:"「魔轟神」チューナー＋チューナー以外のモンスター１体以上 \nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n相手は手札を１枚捨ててこの効果を無効にできる。\n捨てなかった場合、自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。\n②：モンスターが相手の手札から墓地へ送られた場合、そのモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグニティナイト－ゴルムファバル",h:"どらぐにてぃないと－ごるむふぁばる",e:"Dragunity Knight - Gormfaobhar",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2300,tx:"「ドラグニティ」チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時、自分の墓地の「ドラグニティ」チューナー１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードに装備されている自分フィールドの装備カード１枚を墓地へ送り、相手の墓地のカードを２枚まで対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・シップ エクスブロウラー",h:"すぷりがんず・しっぷ　えくすぶろうらー",e:"Springans Ship - Exblowrer",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:2500,tx:"レベル８モンスター×２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手のモンスターゾーンまたは魔法＆罠ゾーンを１ヵ所指定して発動できる。\nこのカードのX素材を任意の数だけ取り除き、その数だけ、指定したゾーン及びその前・後・隣のゾーン（モンスターゾーン・魔法＆罠ゾーン）に存在するカードの中から、相手のカードを選んで破壊する。\n②：相手のメインフェイズ及びバトルフェイズに発動できる。\nこのカードをエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"神樹獣ハイペリュトン",h:"しんじゅうはいぺりゅとん",e:"Sacred Tree Beast, Hyperyton",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1900,tx:"レベル９モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分ターンに、自分が魔法・罠・モンスターの効果を発動した時、その効果と同じ種類（モンスター・魔法・罠）の自分の墓地のカード１枚を対象として発動できる。\n対象のカードをこのカードの下に重ねてX素材とする。\n②：相手ターンに、魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果と同じ種類（モンスター・魔法・罠）となるX素材をこのカードから１つ取り除き、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force ジャスティファイ",h:"せきゅりてぃ・ふぉーす　じゃすてぃふぁい",e:"S-Force Justify",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2600,de:"-",tx:"「S－Force」モンスターを含む効果モンスター３体\n自分はこのカードのリンク先にモンスターを出せない。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手ターンに、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nその後、その相手モンスターをこのカードのリンク先となる相手のモンスターゾーンに移動できる。\n②：このカードが攻撃するダメージステップ開始時に発動できる。\nこのカードのリンク先のモンスターを全て除外する。",li:"",lk:["左上","上","右上"],ta:[],al:[]},
  {n:"ヘビーメタルフォーゼ・アマルガム",h:"へびーめたるふぉーぜ・あまるがむ",e:"Heavymetalfoes Amalgam",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"サイキック族",mo:["リンク","効果"],ma:"",tp:"",at:2000,de:"-",tx:"「メタルフォーゼ」モンスター２体\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードが効果でモンスターゾーンから墓地へ送られた場合、以下の効果から１つを選択して発動できる。\n\n●自分フィールドの「メタルフォーゼ」モンスター１体を対象として発動できる。\nこのカードを攻撃力１０００アップの装備カード扱いとしてそのモンスターに装備する。\n\n●自分フィールドの「メタルフォーゼ」カード１枚を対象として発動できる。\nそのカードを破壊し、このカードを特殊召喚する。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"閉ザサレシ世界ノ冥神",h:"さろす＝えれす・くるぬぎあす",e:"Underworld Goddess of the Closed World",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:3000,de:"-",tx:"効果モンスター４体以上\nこのカードをリンク召喚する場合、相手フィールドのモンスターも１体までリンク素材にできる。\n①：このカードがリンク召喚に成功した場合に発動できる。\n相手フィールドの全ての表側表示モンスターの効果は無効化される。\n②：リンク召喚したこのカードは、このカードを対象とする効果以外の相手の発動した効果を受けない。\n③：１ターンに１度、墓地からモンスターを特殊召喚する効果を含む、魔法・罠・モンスターの効果を相手が発動した時に発動できる。\nその発動を無効にする。",li:"",lk:["上","右上","右","下","右下"],ta:[],al:[]},
  {n:"武装竜の霹靂",h:"あーむど・どらごん・ふらっしゅ",e:"Armed Dragon Flash",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからレベル３の「アームド・ドラゴン」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"武装竜の襲雷",h:"あーむど・どらごん・ぶりっつ",e:"Armed Dragon Blitz",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はドラゴン族モンスターしか特殊召喚できない。\n①：自分フィールドの「アームド・ドラゴン」モンスター１体を対象として発動できる。\nその同名モンスター１体を自分のデッキ・墓地から選び、手札に加えるか召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"武装竜の震霆",h:"あーむど・どらごん・らいとにんぐ",e:"Armed Dragon Lightning",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「アームド・ドラゴン」モンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●そのモンスターの攻撃力は、そのモンスターのレベル×１００アップする。\n\n●そのモンスターのレベル以下のレベルを持つ「アームド・ドラゴン」モンスター１体を自分の墓地から選んで手札に加える。\n\n②：自分フィールドの「アームド・ドラゴン」モンスターが効果で破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・ウォッチ",h:"すぷりがんず・うぉっち",e:"Springans Watch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「大砂海ゴールド・ゴルゴンダ」１枚を手札に加える。\n自分のフィールドゾーンに「大砂海ゴールド・ゴルゴンダ」が存在する場合、代わりに以下の効果を適用できる。\n\n●デッキから「スプリガンズ」モンスター１体を手札に加え、デッキから「スプリガンズ」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"大砂海ゴールド・ゴルゴンダ",h:"だいさかいごーるど・ごるごんだ",e:"Great Sand Sea - Gold Golgonda",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドの「スプリガンズ」Xモンスターの攻撃力は１０００アップする。\n②：自分フィールドに「スプリガンズ」Xモンスターが存在しない場合、手札から「スプリガンズ」カード１枚を捨てて発動できる。\nEXデッキから「スプリガンズ」Xモンスター１体を特殊召喚する。\n③：自分フィールドの表側表示のXモンスターが効果でフィールドから離れた場合、相手フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣の邂逅",h:"とらいぶりげーど・らんでぶー",e:"Tri-Brigade Rendezvous",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのリンク状態の獣族・獣戦士族・鳥獣族モンスターを任意の数だけ対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで７００アップする。\n②：自分フィールドのリンク状態の獣族・獣戦士族・鳥獣族モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force ブリッジヘッド",h:"せきゅりてぃ・ふぉーす　ぶりっじへっど",e:"S-Force Bridgehead",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「S－Force」モンスター１体を手札に加える事ができる。\n②：相手モンスターが同じ縦列の自分の「S－Force」モンスターに攻撃宣言した時に発動できる。\nその自分のモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force ショウダウン",h:"せきゅりてぃ・ふぉーす　しょうだうん",e:"S-Force Showdown",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●手札から「S－Force」モンスター１体を守備表示で特殊召喚する。\n\n●自分の墓地の「S－Force」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"WWの鈴音",h:"うぃんど・うぃっちのすずおと",e:"Windwitch Chimes",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は風属性モンスターしか特殊召喚できない。\n①：自分フィールドの「WW」モンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なる「WW」モンスター１体をデッキから守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔轟神界の階",h:"まごうしんかいのきざはし",e:"Stairway to a Fabled Realm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「魔轟神」モンスター１体を墓地へ送る事ができる。\n②：自分の墓地の「魔轟神」モンスター１体を対象として発動できる。\n手札を２枚選んで捨て、対象のモンスターを手札に加える。\n③：自分の手札が相手より少ない場合、自分の「魔轟神」モンスターの攻撃力は相手モンスターに攻撃するダメージ計算時のみ、手札の枚数の差×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"混錬装融合",h:"ぱらめたるふぉーぜ・ふゅーじょん",e:"Parametalfoes Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドのモンスター及び自分のEXデッキの表側表示のPモンスターの中から、「メタルフォーゼ」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nその際、手札・フィールド・EXデッキからそれぞれ１体までしか融合素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金郷の七摩天",h:"おうごんきょうのしちまてん",e:"Seven Cities of the Golden Land",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nその際、融合素材モンスターは全てアンデット族モンスターでなければならない。\n②：カードの効果でアンデット族モンスターが特殊召喚された場合、魔法＆罠ゾーンにセットされたカード１枚を対象として発動できる。\nセットされたそのカードはこのターン発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"デーモンの杖",h:"でーもんのつえ",e:"Archfiend's Staff of Despair",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターにのみ装備可能。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで、装備モンスターの攻撃力の半分ダウンする。\n②：モンスターに装備されているこのカードが墓地へ送られた場合、１０００LPを払って発動できる。\nこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧竜降臨",h:"がいりゅうこうりん",e:"Armor Dragon Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「鎧竜の聖騎士」の降臨に必要。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：レベルの合計が４以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「鎧竜の聖騎士」を儀式召喚する。\n②：手札及び自分フィールドの表側表示モンスターの中から、レベルの合計が４以上になるようにモンスターを除外して発動できる。\n墓地のこのカードを除外し、自分の墓地から「鎧竜の聖騎士」１体を選んで特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"金満で謙虚な壺",h:"きんまんでけんきょなつぼ",e:"Pot of Prosperity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はカードの効果でドローできない。\n①：自分のEXデッキのカード３枚または６枚を裏側表示で除外して発動できる。\n除外した数だけ自分のデッキの上からカードをめくり、その中から１枚を選んで手札に加え、残りのカードを好きな順番でデッキの一番下に戻す。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ツッパリーチ",h:"つっぱりーち",e:"Tilted Try",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分ドローフェイズに通常のドローをした時、そのカード１枚を相手に見せて発動できる。\nそのカードをデッキの一番下に戻し、自分はデッキから１枚ドローする。\n②：自分が効果でカードをドローした時、そのカード１枚を相手に見せて発動できる。\nこのカードを墓地へ送り、見せたカードをデッキの一番下に戻し、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"武装竜の万雷",h:"あーむど・どらごん・さんだーぼると",e:"Armed Dragon Thunderbolt",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの「アームド・ドラゴン」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、そのモンスターのレベル以下のレベルを持つ自分の墓地の「アームド・ドラゴン」モンスターの種類×１０００アップする。\nこのターン、そのモンスターが相手に与える戦闘ダメージは０になる。\n②：墓地のこのカードを除外し、自分の墓地の「アームド・ドラゴン」魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・コール！",h:"すぷりがんず・こーる！",e:"Springans Call!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の、「スプリガンズ」モンスターまたは「アルバスの落胤」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分の墓地からこのカードと融合モンスター１体を除外し、自分フィールドの「スプリガンズ」Xモンスター１体を対象として発動できる。\nEXデッキから「アルバスの落胤」を融合素材とする融合モンスター１体を選び、対象のモンスターの下に重ねてX素材とする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・ブラスト！",h:"すぷりがんず・ぶらすと！",e:"Springans Blast!",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「スプリガンズ」モンスターが存在する場合、相手のメインモンスターゾーンを１ヵ所指定して発動できる。\nそのゾーンに表側表示モンスターが存在する場合、このターン、そのモンスターは直接攻撃できず、効果は無効化される。\nそのゾーンにモンスターが存在しない場合、このターン、指定したゾーンは使用できない。\n自分フィールドに「アルバスの落胤」を融合素材とする融合モンスターが存在する場合、この効果で指定するゾーンを２ヵ所にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラグマ・ジェネシス",h:"どらぐま・じぇねしす",e:"Dogmatika Genesis",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：除外されている自分または相手の融合・S・X・リンクモンスター１体と、そのモンスターと同じ種類（融合・S・X・リンク）の相手フィールドの効果モンスター１体を対象として発動できる。\nその除外されているモンスターを持ち主のEXデッキに戻し、その相手フィールドのモンスターの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force スぺシメン",h:"せきゅりてぃ・ふぉーす　すぺしめん",e:"S-Force Specimen",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：相手フィールドにモンスターが存在する場合、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「S－Force」モンスター１体を対象として発動できる。\nそのモンスターを相手モンスターの正面の自分フィールドに特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「S－Force」モンスター１体を対象として発動できる。\nその自分のモンスターの位置を、他の自分のメインモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷風のリフレイン",h:"ひょうふうのりふれいん",e:"Icy Breeze Refrain",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●自分の墓地の「WW」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n\n●自分の「WW」モンスターの効果の発動にチェーンして相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nその相手の効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"弑逆の魔轟神",h:"しぎゃくのまごうしん",e:"Fabled Treason",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「魔轟神」モンスター１体とフィールドの表側表示のカード１枚を対象として発動できる。\n自分の手札を１枚選んで捨て、対象の墓地のモンスターを特殊召喚し、対象のフィールドのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"戦華史略－大丈夫之義",h:"せんかしりゃく－だいじょうふのぎ",e:"Ancient Warriors Saga - Chivalrous Path",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの特殊召喚に成功した場合に発動できる。\n自分フィールドに「戦華の龍兵トークン」（獣戦士族・風・星１・攻／守５００）１体を特殊召喚する。\n②：自分・相手の「戦華」モンスターが戦闘で破壊される場合、代わりにこのカードを墓地へ送る事ができる。\n③：フィールドの「戦華」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"電脳堺門－玄武",h:"でんのうかいもん－しぇんうー",e:"Virtual World Gate - Xuanwu",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに他の「電脳堺門」カードが存在する場合、自分・相手のバトルフェイズに、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の「電脳堺」モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\nその後、手札を１枚選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"双天の転身",h:"そうてんのてんしん",e:"Dual Avatar Ascendance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「双天」モンスター１体を対象として発動できる。\nそのモンスターを破壊し、そのモンスターより元々のレベルが１つ高い、または元々のレベルが１つ低い「双天」モンスター１体をデッキ・EXデッキから特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の「双天」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の夢語らい",h:"ゆめまきょうのゆめかたらい",e:"Dream Mirror Recap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「夢魔鏡」モンスターが自身の効果を発動するためにリリースされる場合、墓地へは行かず持ち主のデッキに戻る。\n②：自分・相手のメインフェイズに、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n自分の墓地のカード及び除外されている自分のカードの中から、「聖光の夢魔鏡」または「闇黒の夢魔鏡」１枚を選んで自分のフィールドゾーンに表側表示で置く。\nその後、置いたカードのカード名が記されたモンスター１体を手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"E.M.R.",h:"えれくとろ・まぐねてぃっく・れーるがん",e:"E.M.R.",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの機械族モンスター１体をリリースし、リリースしたモンスターの元々の攻撃力１０００につき１枚まで、フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"碑像の天使－アズルーン",h:"えんじぇる・すたちゅー－あずるーん",e:"Angel Statue - Azurune",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（天使族・光・星４・攻／守１８００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：１ターンに１度、このカードがモンスターゾーンに存在し、相手がモンスターを特殊召喚する際に、魔法＆罠ゾーンから特殊召喚された自分のモンスターゾーンの永続罠カード１枚を墓地へ送って発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。\n③：モンスターゾーンのこのカードが戦闘で破壊された時に発動できる。\nこのカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"壱時砲固定式",h:"いちじほうこていしき",e:"Linear Equation Cannon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のバトルフェイズに、１・２・３・４・５・６のいずれかの数字を宣言して発動できる。\n相手フィールドの効果モンスター１体を選び、以下の数値を合計した数が自分の墓地のカードの数と同じ場合、宣言した数まで自分のデッキの上からカードを墓地へ送り、墓地へ送った数まで相手フィールドのカードを選んで持ち主のデッキに戻す。\n違った場合、自分は宣言した数×５００LPを失う。\n\n●選んだモンスターのレベル×宣言した数\n●相手フィールドのカードの数",li:"",lk:[],ta:[],al:[]},
  {n:"被検体ミュートリアGB－８８",h:"ひけんたいみゅーとりあＧＢ－８８",e:"Myutant GB-88",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のフィールドゾーンに「ミュートリア進化研究所」が存在する場合、相手メインフェイズに発動できる。\nこのカードを手札から特殊召喚する。\n②：相手ターンに、このカードが特殊召喚に成功した場合、このカードをリリースし、手札または自分フィールドの表側表示のカード１枚を除外して発動できる。\n自分の墓地のモンスター及び除外されている自分のモンスターの中から、レベル８の「ミュートリア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンセシス・ミュートリアス",h:"しんせしす・みゅーとりあす",e:"Myutant Synthesis",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"属性が異なる「ミュートリア」モンスター×２\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：相手が効果を発動した時に発動できる。\nこのターン、表側表示のこのカードはその効果及び、同じ種類（モンスター・魔法・罠）の相手の効果を受けない。\n③：融合召喚したこのカードが相手によって破壊された場合に発動できる。\n除外されている自分の「ミュートリア」カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"究極体ミュートリアス",h:"きゅうきょくたいみゅーとりあす",e:"Myutant Ultimus",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:3000,tx:"レベル８以上の「ミュートリア」モンスター×３\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：カードの効果が発動した時、その効果と同じ種類（モンスター・魔法・罠）の「ミュートリア」カードを自分の手札・墓地及び自分フィールドの表側表示のカードの中から１枚除外して発動できる。\nその発動を無効にし除外する。\n②：融合召喚したこのカードが相手によって破壊された場合に発動できる。\n除外されている自分の「ミュートリア」カード３種類（モンスター・魔法・罠）をそれぞれ１枚まで選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"フュージョン・ミュートリアス",h:"ふゅーじょん・みゅーとりあす",e:"Myutant Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、「ミュートリア」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。\nこのターンに相手がカードの効果を発動している場合、自分のデッキ・墓地のモンスターもそれぞれ１体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリア反射作用",h:"みゅーとりあはんしゃさよう",e:"Myutant Blast",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのレベル８以上の「ミュートリア」モンスターにのみ装備可能。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターが特殊召喚された相手モンスターに攻撃するダメージステップ開始時に発動できる。\nその相手モンスターを除外する。\n②：装備されているこのカードを除外して発動できる。\nこのカードを装備していたモンスターを墓地へ送り、そのモンスターとは元々の属性が異なるレベル８の「ミュートリア」モンスター１体を手札・デッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリア連鎖応動",h:"みゅーとりあれんさおうどう",e:"Myutant Clash",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時に、相手フィールドの効果モンスター１体を対象にできる。\nその場合、自分の墓地から「ミュートリア」モンスター１体を選んで除外し、対象のモンスターの効果をターン終了時まで無効にする。\n②：自分のレベル８以上の「ミュートリア」モンスターが戦闘で相手モンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュートリアスの産声",h:"みゅーとりあすのうぶごえ",e:"Myutant Cry",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに発動できる。\n自分のフィールド・墓地のモンスター及び除外されている自分のモンスターの中から、「ミュートリア」融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・グレファー",h:"かおす・ぐれふぁー",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードの属性は「闇」としても扱う。\n②：手札から光属性または闇属性のモンスター１体を捨てて発動できる。\n捨てたモンスターとは属性が異なる光・闇属性モンスター１体をデッキから墓地へ送る。\nこのターン、自分はこの効果で墓地へ送ったモンスター及びその同名モンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"迅雷の暴君 グローザー",h:"じんらいのぼうくん　ぐろーざー",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2800,tx:"チューナー＋チューナー以外の悪魔族モンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：相手メインフェイズに、フィールドの効果モンスター１体を対象として発動できる。\n手札からモンスター１体を選んで捨て、対象のモンスターの効果をターン終了時まで無効にする。\n②：悪魔族モンスターが手札から自分の墓地へ送られた場合に発動できる。\n以下の効果から１つを選び、ターン終了時までこのカードに適用する。\n●戦闘では破壊されない。\n●相手の効果では破壊されない。\n●相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の虎将 ウェイン",h:"ひょうけっかいのこしょう　うぇいん",e:"General Wayne of the Ice Barrier",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドにモンスターが存在し、自分フィールドに「氷結界」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「氷結界」魔法・罠カード１枚を手札に加える。\n③：このカードがモンスターゾーンに存在する限り、フィールドから相手の墓地へ送られる魔法・罠カードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の照魔師",h:"ひょうけっかいのしょうまし",e:"Revealer of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに他の「氷結界」モンスターが存在する限り、相手はアドバンス召喚できない。\n②：手札を１枚捨てて発動できる。\nデッキから「氷結界」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は水属性モンスターしか特殊召喚できない。\n③：自分が「氷結界」モンスターの効果を発動するために、手札を墓地へ送る場合または捨てる場合、そのカード１枚の代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の依巫",h:"ひょうけっかいのよりまし",e:"Speaker for the Ice Barriers",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「氷結界」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに他の「氷結界」モンスターが存在する限り、相手フィールドの守備表示モンスターは表示形式を変更できない。\n③：自分フィールドに「氷結界」モンスターが存在する場合、墓地のこのカードを除外して発動できる。\n自分フィールドに「氷結界トークン」（水族・水・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の霜精",h:"ひょうけっかいのそうせい",e:"Hexa Spirit of the Ice Barrier",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに他の「氷結界」モンスターが存在する限り、相手フィールドのモンスターの攻撃力・守備力は５００ダウンする。\n②：自分メインフェイズに発動できる。\nデッキからレベル３以下の「氷結界」モンスター１体を墓地へ送る。\nこのカードのレベルはターン終了時まで、そのモンスターと同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界に至る晴嵐",h:"ひょうけっかいにいたるせいらん",e:"Winds Over the Ice Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「氷結界」モンスターを任意の数だけリリースして発動できる。\nリリースした数だけ、デッキからレベル４以下の「氷結界」モンスターを特殊召喚する（同名カードは１枚まで）。\n②：墓地のこのカードを除外し、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「氷結界」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の晶壁",h:"ひょうけっかいのしょうへき",e:"Freezing Chains of the Ice Barrier",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時に、自分の墓地のレベル４以下の「氷結界」モンスター１体を対象にできる。\nその場合、そのモンスターを特殊召喚する。\n②：このカードが魔法＆罠ゾーンに存在し、自分フィールドに「氷結界」モンスターが３体以上存在する限り、自分フィールドの「氷結界」モンスターはEXデッキから特殊召喚された相手モンスターが発動した効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の還零龍 トリシューラ",h:"ひょうけっかいのかんれいりゅう　とりしゅーら",e:"Trishula, Zero Dragon of the Ice Barrier",t:"monster",a:"水",l:11,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"チューナー＋チューナー以外のモンスター２体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時に発動できる。\n相手フィールドのカードを３枚まで選んで除外する。\n②：S召喚したこのカードが相手によって破壊された場合に発動できる。\n自分のEXデッキ・墓地から「氷結界の龍 トリシューラ」１体を選び、攻撃力を３３００にして特殊召喚する。\n相手フィールドに表側表示モンスターが存在する場合、さらにそれらのモンスターは、攻撃力が半分になり、効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"トリシューラの鼓動",h:"とりしゅーらのこどう",e:"Terror of Trishula",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「氷結界」Sモンスターの種類によって以下の効果を適用する。\n\n●１種類以上：相手フィールドのカード１枚を選んで除外する。\n\n●２種類以上：相手の墓地のカード１枚を選んで除外する。\n\n●３種類以上：相手の手札をランダムに１枚選んで除外する。\n\n②：自分フィールドの「氷結界」Sモンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、墓地のこのカードを除外して発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"超電導波サンダーフォース",h:"ちょうでんどうはさんだーふぉーす",e:"Thunderforce Attack",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\nこのカードの発動と効果は無効化されない。\n①：自分フィールドに元々のカード名が「オシリスの天空竜」となるモンスターが存在する場合に発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。\nこのカードを自分メインフェイズに発動した場合、さらに以下の効果を適用できる。\n\n●この効果で破壊され相手の墓地へ送られたモンスターの数だけ、自分はデッキからドローする。\nこのターン、自分はモンスター１体でしか攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴッド・ハンド・クラッシャー",h:"ごっど・はんど・くらっしゃー",e:"Fist of Fate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\nこのカードの発動と効果は無効化されない。\n①：自分フィールドに元々のカード名が「オベリスクの巨神兵」となるモンスターが存在する場合に発動できる。\n相手フィールドの効果モンスター１体を選び、効果を無効にし破壊する。\nこのターン、この効果で破壊したモンスター及びそのモンスターと元々のカード名が同じモンスターの効果は無効化される。\nこのカードを自分メインフェイズに発動した場合、さらに以下の効果を適用できる。\n●相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"交差する魂",h:"こうさするたましい",e:"Soul Crossing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに発動できる。\n幻神獣族モンスター１体をアドバンス召喚する。\nその際、自分フィールドのモンスターの代わりに相手フィールドのモンスターをリリースする事もできる。\n相手フィールドのモンスターをリリースしてアドバンス召喚した場合、以下の効果を適用する。\n\n●このカードの発動後、次のターンの終了時まで自分は幻神獣族モンスター以外の魔法・罠・モンスターの効果を１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"絶対なる幻神獣",h:"ぜったいなるげんしんじゅう",e:"Ultimate Divine-Beast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時に、手札から魔法・罠カード１枚を捨て、自分の墓地の幻神獣族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替える。\n②：自分フィールドに幻神獣族モンスターが存在する場合、エンドフェイズに発動できる。\nこのターンにフィールドで効果を発動した、相手フィールドの表側表示のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"黄金の邪教神",h:"おうごんのじゃきょうしん",e:"Golden-Eyes Idol",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分・相手ターンに１度、発動できる。\n相手の手札を全て確認する。\nこのカードのカード名はエンドフェイズまで「千眼の邪教神」として扱う。\n②：このカードが除外された場合、または効果で墓地へ送られた場合、相手フィールドの効果モンスター１体を対象として発動できる。\nその効果モンスターを自分フィールドの通常召喚できない「サクリファイス」モンスター１体に装備する。\nこの効果でモンスターを装備している限り、その攻撃力分、装備モンスターの攻撃力はアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"千年の血族",h:"みれにあむ・しーかー",e:"Millennium Seeker",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果で１０００以上のダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：相手の墓地の攻撃力が？以外のモンスター１体を対象として発動できる。\n相手はデッキから攻撃力が？以外のモンスター１体を選ぶ事ができる。\n選ばなかった場合、または対象のモンスターの方が攻撃力が高い場合、対象のモンスターを自分フィールドに特殊召喚する。\n選んだモンスターはデッキに戻す。\nそれ以外の場合、相手は選んだモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"予見者ゾルガ",h:"よけんしゃぞるが",e:"Zolga the Prophet",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「予見者ゾルガ」以外の天使族・地属性モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分は自分及び相手のデッキの上からそれぞれ５枚まで確認する。\n②：このカードをリリースして召喚されたモンスターの攻撃宣言時に、墓地のこのカードを除外して発動できる。\nそのモンスターを破壊し、相手に２０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜血公ヴァンパイア",h:"どらくれあう゛ぁんぱいあ",e:"Vampire Voivode",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合、相手の墓地のモンスターを２体まで対象として発動できる。\nそのモンスターを効果を無効にして自分フィールドに守備表示で特殊召喚する。\n②：モンスターの効果が発動した時、その同名モンスターが自分・相手の墓地に存在する場合に発動できる。\nその発動を無効にする。\n③：相手の墓地からモンスターが特殊召喚された場合、自分フィールドのモンスター２体をリリースして発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァル・アーチャー",h:"らう゛ぁる・あーちゃー",e:"Laval Archer",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動する。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「ラヴァル」モンスター１体を召喚できる。\n②：このカードが墓地に存在する場合、自分フィールドの炎属性モンスター１体を対象として発動できる。\nそのモンスターを破壊し、このカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこのターン、自分は炎属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルバル・サラマンダー",h:"らう゛ぁるばる・さらまんだー",e:"Lavalval Salamander",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:200,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\n自分はデッキから２枚ドローし、その後手札から炎属性モンスターを含むカード２枚を墓地へ送る。\n手札に炎属性モンスターが無い場合、手札を全て公開し、デッキに戻す。\n②：１ターンに１度、自分の墓地から炎属性モンスター１体を除外して発動できる。\n自分フィールドの「ラヴァル」モンスターの数まで相手フィールドの表側表示モンスターを選んで裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖刻天龍－エネアード",h:"せいこくてんりゅう－えねあーど",e:"Hieratic Sky Dragon Overlord of Heliopolis",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2400,tx:"レベル８モンスター×２\n①：１ターンに１度、自分のフィールド・墓地のカードまたは除外されている自分のカードを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"創造の聖刻印",h:"そうぞうのせいこくいん",e:"Hieratic Seal of Creation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのドラゴン族Xモンスター１体を対象として発動できる。\nその自分のモンスターとは元々のカード名が異なる「聖刻」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「聖刻」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"星なる影 ゲニウス",h:"ねふしゃどーる　げにうす",e:"Nehshaddoll Genius",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合、自分フィールドの「シャドール」モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターは自身以外のモンスターの効果を受けない。\n②：このカードが効果で墓地へ送られた場合、フィールドの効果モンスター１体を対象として発動できる。\nこのターン、その効果モンスターはフィールドで発動する効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"影依の炎核 ヴォイド",h:"へるしゃどーる　う゛ぉいど",e:"Helshaddoll Hollow",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:2900,de:2900,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがリバースした場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\n属性がそのモンスターと同じ「シャドール」モンスター１体を自分のEXデッキから墓地へ送り、対象のモンスターを除外する。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nフィールドのモンスターの元々の属性の種類の数だけ、自分のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"アラドヴァルの影霊衣",h:"あらどう゛ぁるのねくろす",e:"Nekroz of Areadbhair",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:3300,de:3200,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル１０以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\n自分の手札・フィールドの「影霊衣」モンスターを２体までリリースし、その数だけデッキから「影霊衣」カードを墓地へ送る。\n②：モンスターの効果が発動した時、自分の手札・フィールドのモンスター１体をリリースして発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精伝姫－ラチカ",h:"ふぇありーている－らちか",e:"Fairy Tail - Rochka",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n相手は５００LP回復する。\nその後、相手はこの効果を発動したプレイヤーのデッキの上からカードを３枚確認し、その中から１枚を選ぶ。\n自分は相手が選んだカードを手札に加える。\n残りのカードはデッキに戻してシャッフルする。\n②：このカードが戦闘を行うダメージ計算前に発動できる。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"妖精の伝姫",h:"ふぇありーている",e:"Fairy Tail Tales",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：同名モンスターが自分フィールドに存在しない、手札の攻撃力１８５０の魔法使い族モンスター１体を相手に見せて発動できる。\nそのモンスターを通常召喚する。\n②：自分フィールドに元々の攻撃力が１８５０の魔法使い族モンスターが存在し、自分が戦闘・効果でダメージを受ける場合、１ターンに１度だけそのダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"銀河眼の極光波竜",h:"ぎゃらくしーあいず・さいふぁー・えくす・どらごん",e:"Galaxy-Eyes Cipher X Dragon",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4000,de:3000,tx:"レベル１０モンスター×２\n「銀河眼の極光波竜」は１ターンに１度、自分フィールドの「サイファー・ドラゴン」モンスターの上に重ねてX召喚する事もできる。\n①：このカードのX素材を２つ取り除いて発動できる。\n自分フィールドの光属性モンスターは相手ターン終了時まで相手の効果の対象にならない。\n②：自分スタンバイフェイズに発動できる。\n自分の墓地のランク９以下のドラゴン族Xモンスター１体をEXデッキに戻す。\nその後、そのモンスターを自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・アジャスター",h:"くろのだいばー・あじゃすたー",e:"Time Thief Adjuster",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分が「クロノダイバー・アジャスター」以外の「クロノダイバー」モンスターの召喚・特殊召喚に成功した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「クロノダイバー・アジャスター」以外の「クロノダイバー」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・ダブルバレル",h:"くろのだいばー・だぶるばれる",e:"Time Thief Double Barrel",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"レベル４モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：相手が効果を発動した時に発動できる。\nこのカードのX素材を３種類（モンスター・魔法・罠）まで取り除く。\nその後、以下を適用する。\n\n●モンスター：このカードの攻撃力は４００アップする。\n\n●魔法：相手フィールドの表側表示モンスター１体を選び、エンドフェイズまでコントロールを得る。\nこのターン、そのモンスターは攻撃宣言できず、効果を発動できない。\n\n●罠：フィールドの効果モンスター１体を選び、その効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖種の地霊",h:"さんしーど・げにうす・ろき",e:"Sunseed Genius Loci",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:0,de:600,tx:"千年に１度、聖天樹から獲れる種は、千年の時を経てその土地の守護精霊になると言われている。",li:"",lk:[],ta:[],al:[]},
  {n:"聖蔓の乙女",h:"さんう゛ぁいん・めいでん",e:"Sunvine Maiden",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚された自分フィールドの植物族モンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時に発動できる。\nこのカードを手札から特殊召喚し、その効果を無効にする。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「サンアバロン」魔法・罠カード及び「サンヴァイン」魔法・罠カードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖種の影芽",h:"さんしーど・しゃどう",e:"Sunseed Shadow",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに植物族の通常モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：墓地のこのカードを除外し、リンク状態でリンク２以下の自分の、「サンアバロン」モンスターまたは「サンヴァイン」モンスター１体を対象として発動できる。\nEXデッキから、その同名モンスター１体を効果を無効にして特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は植物族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖種の天双芽",h:"さんしーど・ついん",e:"Sunseed Twin",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「サンアバロン」リンクモンスターが存在し、このカードが召喚・特殊召喚に成功した場合、自分の墓地のレベル４以下の植物族の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードと自分フィールドのリンクモンスター１体を除外して発動できる。\n自分の墓地に同名の植物族リンクモンスターが２体以上存在する場合、その内の１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖天樹の幼精",h:"さんあばろん・どりゅあす",e:"Sunavalon Dryas",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"レベル４以下の植物族モンスター１体\n①：このカードが「聖種の地霊」を素材としてEXモンスターゾーンにリンク召喚された場合に発動できる。\nデッキから「サンヴァイン」魔法・罠カード１枚を手札に加える。\n②：このカードは攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。\n③：１ターンに１度、自分が戦闘・効果でダメージを受けた場合に発動できる。\nその数値分だけ自分のLPを回復し、EXデッキから「サンヴァイン」モンスター１体を特殊召喚する。",li:"",lk:["下"],ta:[],al:[]},
  {n:"聖天樹の精霊",h:"さんあばろん・どりゅあです",e:"Sunavalon Dryades",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"「サンアバロン」リンクモンスターを含む植物族モンスター２体\n①：このカードは攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。\n②：自分が戦闘・効果でダメージを受けた場合に発動できる。\nその数値分だけ自分のLPを回復し、EXデッキから「サンヴァイン」モンスター１体を特殊召喚する。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"聖天樹の大精霊",h:"さんあばろん・どりゅあのーむ",e:"Sunavalon Dryanome",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"植物族モンスター２体以上\n①：このカードは攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。\n②：１ターンに３度まで、自分が戦闘・効果でダメージを受けた場合に発動できる。\nその数値分だけ自分のLPを回復し、EXデッキから「サンヴァイン」モンスター１体を特殊召喚する。\n③：１ターンに１度、このリンク先のモンスターが攻撃対象になった時に発動できる。\n攻撃を無効にし、その自分のモンスターの位置を、他の自分のメインモンスターゾーンに移動する。",li:"",lk:["左下","下","右下"],ta:[],al:[]},
  {n:"聖天樹の大母神",h:"さんあばろん・どりゅあとらんてぃえ",e:"Sunavalon Dryatrentiay",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"リンクモンスター２体以上\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「サンアバロン」魔法・罠カード１枚を手札に加える。\n②：このカードは相手の効果では破壊されず、攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。\n③：１ターンに１度、このカードのリンク先の自分のリンクモンスター１体をリリースして発動できる。\nそのリンクマーカーの数まで、相手フィールドのカードを選んで破壊する。",li:"",lk:["上","左下","下","右下"],ta:[],al:[]},
  {n:"聖蔓の守護者",h:"さんう゛ぁいん・がーどなー",e:"Sunvine Gardna",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:600,de:"-",tx:"植物族の通常モンスター１体\n①：自分フィールドの「サンアバロン」リンクモンスターが効果でフィールドから離れた場合に発動する。\nこのカードを破壊する。\n②：「サンアバロン」リンクモンスターとリンク状態になっているこのカードが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその戦闘で発生する自分への戦闘ダメージは半分になる。\n③：このカードが戦闘で破壊された時に発動できる。\nそのダメージステップ終了後にバトルフェイズを終了する。",li:"",lk:["上"],ta:[],al:[]},
  {n:"聖蔓の癒し手",h:"さんう゛ぁいん・ひーらー",e:"Sunvine Healer",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:600,de:"-",tx:"植物族の通常モンスター１体\n①：自分フィールドの「サンアバロン」リンクモンスターが効果でフィールドから離れた場合に発動する。\nこのカードを破壊する。\n②：このカードが特殊召喚に成功した場合、フィールドの「サンアバロン」リンクモンスター１体を対象として発動できる。\n自分はそのリンクマーカーの数×３００LPを回復する。\n③：自分の植物族リンクモンスターが相手に戦闘ダメージを与えた時に発動できる。\n自分は６００LP回復する。",li:"",lk:["上"],ta:[],al:[]},
  {n:"聖蔓の剣士",h:"さんう゛ぁいん・すらっしゃー",e:"Sunvine Thrasher",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:800,de:"-",tx:"植物族の通常モンスター１体\n①：自分フィールドの「サンアバロン」リンクモンスターが効果でフィールドから離れた場合に発動する。\nこのカードを破壊する。\n②：このカードが特殊召喚に成功した場合、フィールドの「サンアバロン」リンクモンスター１体を対象として発動できる。\nこのカードの攻撃力は、そのリンクマーカーの数×８００アップする。\n③：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターをリンクモンスターのリンク先となる自分フィールドに効果を無効にして特殊召喚する。",li:"",lk:["下"],ta:[],al:[]},
  {n:"聖蔓の社",h:"さんう゛ぁいん・しゅらいん",e:"Sunvine Shrine",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールドに「サンアバロン」リンクモンスターが存在する場合、手札を１枚墓地へ送ってこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分は植物族モンスターしかEXデッキから特殊召喚できない。\n②：１ターンに１度、発動できる。\n自分の墓地からレベル４以下の植物族の通常モンスター１体を選んで特殊召喚する。\n③：相手エンドフェイズに、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分の墓地の永続罠カード１枚を対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖天樹の開花",h:"さんあばろん・ぶるーみんぐ",e:"Sunavalon Bloom",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、自分フィールドにリンク４以上の植物族リンクモンスターが存在する場合、相手フィールドの全ての表側表示モンスターの効果は無効化される。\n②：自分の植物族リンクモンスターが戦闘を行うダメージ計算時に発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのリンク先のモンスターの攻撃力の合計分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖夜に煌めく竜",h:"せいやにきらめくりゅう",e:"Starry Night, Starry Dragon",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2300,tx:"①：このカードが手札からの召喚・特殊召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：フィールドのこのカードは、闇属性モンスターとの戦闘では破壊されず、闇属性モンスターの効果では破壊されない。\n③：１ターンに１度、このカードが相手モンスターに攻撃するダメージステップ開始時に発動できる。\nその相手モンスターをエンドフェイズまで除外する。\nこの効果を発動した場合、このカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリーナイツ・レイエル",h:"ほーりーないつ・れいえる",e:"Starry Knight Rayel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「ホーリーナイツ」魔法・罠カード１枚を手札に加える。\n②：墓地のこのカードを除外し、「ホーリーナイツ・レイエル」以外の自分の墓地の「ホーリーナイツ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリーナイツ・アステル",h:"ほーりーないつ・あすてる",e:"Starry Knight Astel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの光属性モンスター１体を対象として発動できる。\nそのモンスターをリリースし、手札からドラゴン族・光属性・レベル７モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：墓地のこのカードを除外し、自分フィールドのドラゴン族・光属性・レベル７モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は相手ターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリーナイツ・フラムエル",h:"ほーりーないつ・ふらむえる",e:"Starry Knight Flamel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：相手モンスターの攻撃宣言時に、手札・フィールドのこのカードを墓地へ送って発動できる。\n手札からドラゴン族・光属性・レベル７モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外し、自分のフィールド・墓地のドラゴン族・光属性・レベル７モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる篝火",h:"ほーりーないつ・べるふぁいあ",e:"Starry Knight Balefire",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「ホーリーナイツ」モンスターまたはドラゴン族・光属性・レベル７モンスター１体を手札に加える。\n相手フィールドに闇属性モンスターが存在し、自分フィールドにモンスターが存在しない場合、さらに手札からドラゴン族・光属性・レベル７モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"煌めく聖夜",h:"ほーりーないつ・すかい",e:"Starry Knight Sky",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「ホーリーナイツ」モンスターまたはドラゴン族・光属性・レベル７モンスター１体を召喚できる。\n②：自分ターンに、自分フィールドの表側表示のドラゴン族・光属性・レベル７モンスターが手札に戻った場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる降誕",h:"ほーりーないつ・なてぃびてぃ",e:"Starry Knight Ceremony",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分メインフェイズに発動できる。\n手札の天使族・光属性モンスター１体を相手に見せ、デッキからドラゴン族・光属性・レベル７モンスター１体を手札に加える。\nその後、見せたモンスターをデッキの一番下に戻す。\n②：相手が魔法・罠・モンスターの効果を発動した場合に発動できる。\n手札からドラゴン族・光属性・レベル７モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖夜の降臨",h:"ほーりーないつ・あどべんと",e:"Starry Knight Arrival",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、以下の効果から１つを選択して発動できる。\n\n●自分フィールドのドラゴン族・光属性・レベル７モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n\n●手札からドラゴン族・光属性・レベル７モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖なる煌炎",h:"ほーりーないつ・ぶらすと",e:"Starry Knight Blast",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手が魔法・罠・モンスターの効果を発動した時、自分フィールドのドラゴン族・光属性・レベル７モンスター１体を持ち主の手札に戻して発動できる。\nその効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"フリント・クラッガー",h:"ふりんと・くらっがー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分の手札を１枚選んで捨て、EXデッキから「化石」融合モンスター１体を墓地へ送る。\n②：フィールドのこのカードを墓地へ送って発動できる。\n相手に５００ダメージを与える。\n自分の墓地に「化石融合－フォッシル・フュージョン」が存在する場合、さらに除外されている自分の、「化石融合－フォッシル・フュージョン」１枚またはそのカード名が記されたカード１枚を選んで墓地に戻す事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"シェル・ナイト",h:"しぇる・ないと",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nこのカードを守備表示にし、相手に５００ダメージを与える。\n②：このカードが効果で墓地へ送られた場合、または戦闘で破壊された場合に発動できる。\nデッキから岩石族・レベル８モンスター１体を手札に加える。\n自分の墓地に「化石融合－フォッシル・フュージョン」が存在する場合、手札に加えず特殊召喚する事もできる。\nこのターン、自分はそのカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"古生代化石マシン スカルコンボイ",h:"こせいだいかせきましん　すかるこんぼい",e:"",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1800,tx:"自分の墓地の岩石族モンスター＋レベル７以上のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\n①：融合召喚したこのカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力は、そのモンスターの元々の守備力分ダウンする。\n②：このカードは１度のバトルフェイズ中に３回までモンスターに攻撃できる。\n③：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"中生代化石マシン スカルワゴン",h:"ちゅうせいだいかせきましん　すかるわごん",e:"",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:1700,de:1500,tx:"自分の墓地の岩石族モンスター＋レベル５・６のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n相手に８００ダメージを与える。\n③：墓地のこのカードを除外し、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"新生代化石マシン スカルバギー",h:"しんせいだいかせきましん　すかるばぎー",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:1200,tx:"自分の墓地の岩石族モンスター＋レベル４以下のモンスター\nこのカードは「化石融合－フォッシル・フュージョン」の効果でのみEXデッキから特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n相手に６００ダメージを与える。\n②：墓地のこのカードを除外して発動できる。\nデッキから「化石融合－フォッシル・フュージョン」のカード名が記されたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・セイジ",h:"いんふぇるにてぃ・せいじ",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札を全て捨てる。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「インフェルニティ」モンスター１体を墓地へ送る。\nこの効果は自分の手札が０枚の場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"インフェルニティ・ポーン",h:"いんふぇるにてぃ・ぽーん",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分の手札が０枚の場合、自分ドローフェイズに通常のドローを行う代わりに、墓地のこのカードを除外し、以下の効果から１つを選択して発動できる。\n\n●デッキから「インフェルニティ」カード１枚を選んでデッキの一番上に置く。\n\n●デッキから「煉獄」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の契約",h:"れんごくのけいやく",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このカード以外の自分の手札が３枚以上の場合に発動できる。\n自分の手札を全て捨てる。\nその後、自分の墓地から「インフェルニティ」モンスターまたはドラゴン族・闇属性・レベル８のSモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"煉獄の釜",h:"れんごくのかま",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの、「インフェルニティ」モンスターまたはドラゴン族・闇属性・レベル８のSモンスターが相手の効果で破壊される場合、代わりに自分の墓地の「インフェルニティ」カード１枚を除外できる。\n②：自分の手札が０枚ではない場合、このカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドール・モンスター ガールちゃん",h:"どーる・もんすたー　がーるちゃん",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:0,de:0,tx:"わたしたちのお家にようこそ！\nコロンちゃんやデメット爺さん、たくさんのお人形たちと暮らしているの！",li:"",lk:[],ta:[],al:[]},
  {n:"ドール・モンスター 熊っち",h:"どーる・もんすたー　くまっち",e:"",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:0,tx:"ぼくたちが壊れちゃうようなケガをしてもだいじょうぶ。\n\nデメット爺さんが直してくれるんだ！",li:"",lk:[],ta:[],al:[]},
  {n:"デメット爺さん",h:"でめっとじいさん",e:"Grandpa Demetto",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「プリンセス・コロン」のX素材を１つ取り除いて発動できる。\n自分の墓地から攻撃力か守備力が０の通常モンスターを２体まで選び、闇属性・レベル８モンスターとして守備表示で特殊召喚する。\n②：自分のXモンスターが、X素材としている通常モンスターを取り除いて効果を発動した場合、そのXモンスター１体と相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊し、対象のXモンスターのランク×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"人形の幸福",h:"にんぎょうのこうふく",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「デメット爺さん」または「おもちゃ箱」１体を手札に加える。\n②：自分フィールドに「プリンセス・コロン」が存在する限り、相手は攻撃力か守備力が０のモンスターを攻撃対象に選択できない。\n③：１ターンに１度、発動できる。\n自分の手札・フィールドのモンスター１体を選んで破壊し、デッキから「ドール・モンスター」カード１枚を墓地へ送る。\nこのターン、自分はXモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"聖天樹の月桂精",h:"さんあばろん・だふね",e:"Sunavalon Daphne",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"植物族モンスター２体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。\n②：自分フィールドのモンスター１体をリリースし、「聖天樹の月桂精」以外の自分の墓地の植物族リンクモンスター２体を対象として発動できる。\nそのモンスターをEXデッキに戻す。",li:"",lk:["右","左"],ta:[],al:[]},
  {n:"聖天樹の灰樹精",h:"さんあばろん・めりあす",e:"Sunavalon Melias",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"リンクモンスターを含む植物族モンスター２体以上\n①：このカードがリンク召喚に成功した場合に発動できる。\n自分の墓地から「聖種の地霊」１体を選んで特殊召喚する。\n②：このカードは攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。\n③：１ターンに１度、このカードのリンク先の自分の「サンヴァイン」リンクモンスター１体を対象として発動できる。\nこのターン、そのモンスターは自分フィールドの「サンアバロン」リンクモンスターの数まで攻撃できる。",li:"",lk:["上","右","左"],ta:[],al:[]},
  {n:"聖蔓の交配",h:"さんう゛ぁいん・くろすぶりーど",e:"Sunvine Cross Breed",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのリンクモンスター１体をリリースし、そのモンスター以外の自分の墓地の植物族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の随身",h:"ひょうけっかいのずいじん",e:"Zuijin of the Ice Barrier",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n手札からレベル５以上の「氷結界」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドのレベル３以上の水属性モンスター１体を対象として発動できる。\nそのモンスターのレベルを２つ下げ、このカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・フォティア",h:"うぉーくらい・ふぉてぃあ",e:"War Rock Fortia",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の戦士族・地属性モンスターが戦闘を行ったダメージ計算後に発動できる。\nデッキから「ウォークライ・フォティア」以外の「ウォークライ」カード１枚を手札に加える。\nその後、自分フィールドの全ての「ウォークライ」モンスターの攻撃力は相手ターン終了時まで２００アップする。\n②：このカードが相手の効果でモンスターゾーンから墓地へ送られた場合に発動できる。\n手札・デッキからレベル５以上の「ウォークライ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・ガトス",h:"うぉーくらい・がとす",e:"War Rock Gactos",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに戦士族・地属性モンスターが召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手の効果でモンスターゾーンから墓地へ送られた場合に発動できる。\n手札・デッキからレベル５以上の「ウォークライ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・オーピス",h:"うぉーくらい・おーぴす",e:"War Rock Orpis",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:2100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが、存在しない場合または戦士族モンスターのみの場合、このカードはリリースなしで召喚できる。\n②：自分の戦士族・地属性モンスターが戦闘を行ったダメージ計算後に発動できる。\nデッキから「ウォークライ・オーピス」以外の戦士族・地属性モンスター１体を墓地へ送る。\nその後、自分フィールドの全ての「ウォークライ」モンスターの攻撃力は相手ターン終了時まで２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・スキーラ",h:"うぉーくらい・すきーら",e:"War Rock Skyler",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は相手フィールドのモンスターの数×１００アップする。\n②：自分の戦士族・地属性モンスターが戦闘を行った自分・相手のバトルフェイズに、自分の墓地のレベル５以下の戦士族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n自分フィールドの全ての「ウォークライ」モンスターの攻撃力は相手ターン終了時まで２００アップする。\nこのターン、自分はレベル５以下のモンスターでは直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・バシレオス",h:"うぉーくらい・ばしれおす",e:"War Rock Bashileos",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:2700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分の戦士族・地属性モンスターが戦闘で破壊された時に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：自分の戦士族・地属性モンスターが戦闘を行った自分・相手のバトルフェイズに発動できる。\n自分フィールドの全ての「ウォークライ」モンスターの攻撃力は相手ターン終了時まで２００アップする。\nこのターン、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ザ・ロック・オブ・ウォークライ",h:"ざ・ろっく・おぶ・うぉーくらい",e:"War Rock Mountain",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「ウォークライ」モンスター１体を手札に加える事ができる。\n②：自分フィールドのモンスターが、存在しない場合または戦士族モンスターのみの場合、自分・相手のバトルフェイズ開始時に発動できる。\n同名カードが自分フィールドに存在しない「ウォークライ」モンスター１体を手札から特殊召喚する。\n③：自分の戦士族モンスターが戦闘で破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・オーディール",h:"うぉーくらい・おーでぃーる",e:"War Rock Ordeal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「ウォークライ・オーディール」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードの発動時の効果処理として、このカードにカウンターを３つ置く。\n③：自分の「ウォークライ」モンスターが戦闘で相手モンスターを破壊し相手の墓地へ送った時に発動できる。\nこのカードのカウンターを１つ取り除き、自分はデッキから１枚ドローする。\nこの効果でこのカードのカウンターが全て取り除かれた場合、このカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"颶風龍－ビュフォート・ノウェム",h:"ぐふうりゅう－びゅふぉーと・のうぇむ",e:"Raging Storm Dragon - Beaufort IX",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2800,tx:"「褒誉の息吹」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの直接攻撃宣言時にこのカードを手札から捨てて発動できる。\nその攻撃を無効にし、そのモンスターの効果は無効化される。\n②：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとこのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"褒誉の息吹",h:"ほうよのいぶき",e:"Breath of Acclamation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"風属性の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、手札から風属性の儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"三相魔神コーディウス",h:"さんそうまじんこーでぃうす",e:"Coordius the Triphasic Dealmon",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:3000,tx:"Sモンスター＋Xモンスター＋リンクモンスター\nこのカード名の効果は１ターンに１度しか使用できない。\n①：融合召喚したこのカードがフィールドに表側表示で存在する限り１度だけ、２０００の倍数のLPを払い、払ったLP２０００につき１つ、以下の効果から選択して発動できる。\n\n●自分の墓地から魔法・罠カード１枚を選んで手札に加える。\n\n●相手フィールドのカード３枚を選んで破壊する。\n\n●このターン、他の自分のモンスターは攻撃できず、このカードの攻撃力はお互いのLPの差の半分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"大融合",h:"だいゆうごう",e:"Greater Polymerization",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で融合召喚する場合、融合素材モンスターは３体以上でなければならない。\nこの効果で特殊召喚したモンスターは以下の効果を得る。\n\n●このカードは効果では破壊されない。\n\n●このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"マテリアクトル・ギガドラ",h:"まてりあくとる・ぎがどら",e:"Materiactor Gigadra",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分はXモンスターしかEXデッキから特殊召喚できない。\n②：手札を１枚捨てて発動できる。\n手札・デッキからレベル３の通常モンスター１体を特殊召喚する。\n通常モンスターを捨てて発動した場合、特殊召喚するモンスターを「マテリアクトル」モンスター１体にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"マテリアクトル・ギガヴォロス",h:"まてりあくとる・ぎがう゛ぉろす",e:"Materiactor Gigaboros",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:500,tx:"レベル３モンスター×２体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力は自分の手札の数×５００アップする。\n②：このカードのX素材を１つ取り除いて発動できる。\nデッキから「マテリアクトル」モンスター１体を選び、このカードの下に重ねてX素材とする。\n③：相手のメインフェイズ及びバトルフェイズに発動できる。\nこのカードがX素材としているカード１枚を持ち主の手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・アンコール",h:"ぺんでゅらむ・あんこーる",e:"Pendulum Encore",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手メインフェイズに、手札を１枚捨てて発動できる。\nPモンスターをP召喚する。\nこのターン、自分のPゾーンのカードは、効果を発動できず、自分のカードの効果では破壊されず、エンドフェイズに持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"粛星の鋼機",h:"しゅくせいのこうき",e:"Steel Star Regulator",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1000,de:"-",tx:"リンクモンスター以外のモンスター３体\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードのリンク素材としたモンスターの元々のレベル・ランクの合計×１００アップする。\n②：このカードの攻撃力以下の攻撃力を持つ、リンクモンスター以外の相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこのカードがXモンスターを素材としてリンク召喚していた場合、さらに破壊したモンスターの元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:["左上","左下","右下"],ta:[],al:[]},
  {n:"増援部隊",h:"ぞうえんぶたい",e:"Reinforcement of the Army's Troops",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の戦士族モンスターが戦闘を行う攻撃宣言時に発動できる。\n手札からレベル４以下の戦士族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"形勢反転",h:"けいせいはんてん",e:"Underdog",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分ターンの間、相手フィールドの表側表示モンスターの効果は無効化され、相手ターンの間、自分フィールドの表側表示モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－天馬双翼剣",h:"ぜある・うぇぽん－ぺがさす・ついん・せいばー",e:"ZW - Pegasus Twin Saber",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:2100,tx:"①：「ZW－天馬双翼剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分のLPが相手より２０００以上少ない場合、このカードは手札から特殊召喚できる。\n③：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分フィールドのこのカードを攻撃力１０００アップの装備カード扱いとしてそのモンスターに装備する。\n④：このカードが装備されている場合、１ターンに１度、相手フィールドのモンスターが発動した効果を無効にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ZS－武装賢者",h:"ぜある・さーばす－あーむず・せーじ",e:"ZS - Armed Sage",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが「ZS－武装賢者」以外のレベル４モンスター１体のみの場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードを素材としてX召喚した「希望皇ホープ」モンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動できる。\nデッキから「ZW」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ZS－昇華賢者",h:"ぜある・さーばす－あせんど・せーじ",e:"ZS - Ascended Sage",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:300,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにカードが存在しない場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードを素材としてX召喚した「希望皇ホープ」モンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動できる。\nデッキから「RUM」通常魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"覇蛇大公ゴルゴンダ",h:"はじゃたいこうごるごんだ",e:"Supreme Sovereign Serpent of Golgonda",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分のフィールドゾーンに表側表示でカードが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドに「大砂海ゴールド・ゴルゴンダ」が存在する限り、このカードの元々の攻撃力は３０００になる。\n③：フィールドの「大砂海ゴールド・ゴルゴンダ」が効果で破壊される場合、代わりに自分の墓地のモンスター１体を除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・ブラザーズ",h:"すぷりがんず・ぶらざーず",e:"Springans Brothers",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・デッキから墓地へ送られた場合、「スプリガンズ・ブラザーズ」以外の自分の墓地の「スプリガンズ」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが手札・フィールド・墓地に存在する場合、自分フィールドの「スプリガンズ」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"驚楽園の支配人 ＜∀rlechino＞",h:"あめいずめんと・あどみにすとれーたー　＜あるれきーの＞",e:"Amazement Administrator Arlekino",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：罠カードが発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：相手がモンスターの召喚・特殊召喚に成功した場合、その相手モンスター１体を対象として発動できる。\nデッキから「アトラクション」罠カード１枚を選び、その相手モンスターに装備する。\n③：１ターンに１度、自分の墓地から「アトラクション」罠カードを任意の数だけ除外し、その数だけ相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"驚楽園の大使 ＜Bufo＞",h:"あめいずめんと・あんばさだー　＜ぶーふぉ＞",e:"Amazement Ambassador Bufo",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の「アトラクション」罠カード１枚と相手フィールドのモンスター１体を対象として発動できる。\nその墓地のカードをその相手モンスターに装備する。\n②：モンスターに装備されている自分の「アトラクション」罠カード１枚を対象として発動できる。\nそのカードを自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体に装備する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"驚楽園の案内人 ＜Comica＞",h:"あめいずめんと・あてんだんと　＜こみか＞",e:"Amazement Attendant Comica",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「アトラクション」罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：モンスターに装備されている自分の「アトラクション」罠カード１枚を対象として発動できる。\nそのカードを自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体に装備する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ロクスローズ・ドラゴン",h:"ろくすろーず・どらごん",e:"Roxrose Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ロクスローズ・ドラゴン」以外の「ブラック・ローズ・ドラゴン」のカード名が記されたカード１枚を手札に加える。\n②：このカードが墓地に存在し、自分フィールドの表側表示の、「ローズ・ドラゴン」モンスターまたは植物族Sモンスターが戦闘または相手の効果で破壊された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"紅蓮薔薇の魔女",h:"ぶらっどろーずうぃっち",e:"Ruddy Rose Witch",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nデッキから「黒薔薇の魔女」１体を手札に加え、デッキからレベル３以下の植物族モンスター１体を選んでデッキの一番上に置く。\nその後、手札から「黒薔薇の魔女」１体を召喚できる。\n②：墓地のこのカードを除外して発動できる。\n自分の墓地のモンスターまたは除外されている自分のモンスターの中から、「ブラック・ローズ・ドラゴン」または「ブラッド・ローズ・ドラゴン」１体を選んでEXデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ダンマリ＠イグニスター",h:"だんまりあっといぐにすたー",e:"Danmari @Ignister",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「＠イグニスター」モンスターが戦闘を行う攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚し、その攻撃を無効にする。\n②：自分フィールドにリンク６モンスターが存在する場合、フィールド・墓地のこのカードを除外し、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"武神－マヒトツ",h:"ぶじん－まひとつ",e:"Bujin Mahitotsu",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から他の「武神」カード１枚を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：以下の効果から１つを選択して発動できる。\n\n●手札から「武神」モンスター１体を墓地へ送って発動できる。\nそのモンスターとはカード名が異なる「武神」モンスター１体を自分の墓地から選んで手札に加える。\n\n●自分の墓地から「武神」モンスター１体を除外して発動できる。\nそのモンスターとはカード名が異なる「武神」モンスター１体をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"武神－トリフネ",h:"ぶじん－とりふね",e:"Bujin Torifune",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n「武神－トリフネ」を除く、種族が異なる「武神」モンスター２体をデッキから守備表示で特殊召喚する。\n②：このカードが墓地に存在し、自分が「武神」XモンスターのX召喚に成功した時に発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘で破壊したモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force ドッグ・タッグ",h:"せきゅりてぃ・ふぉーす　どっぐ・たっぐ",e:"S-Force Dog Tag",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:2100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「S－Force」モンスターが存在し、相手がモンスターの召喚・特殊召喚に成功した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分メインフェイズ中に、自分の「S－Force」モンスターの正面の相手モンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force エッジ・レイザー",h:"せきゅりてぃ・ふぉーす　えっじ・れいざー",e:"S-Force Edge Razor",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「S－Force エッジ・レイザー」以外の「S－Force」モンスター１体を攻撃表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分の「S－Force」モンスターの正面の相手モンスターは、リンク３以上のモンスターのリンク素材にできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ジーナの蟲惑魔",h:"じーなのこわくま",e:"Traptrix Vesiculo",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドにセットされた罠カード１枚を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードはモンスターゾーンに存在する限り、「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。\n③：自分の魔法＆罠ゾーンにカードが存在しない場合、墓地のこのカードを除外し、自分の墓地の「ホール」通常罠カードまたは「落とし穴」通常罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"Live☆Twin キスキル・フロスト",h:"らいぶついん　きすきる・ふろすと",e:"Live☆Twin Ki-sikil Frost",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「リィラ」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：自分フィールドに「イビルツイン」モンスターが存在し、相手がカードの効果でデッキからカードを手札に加えた場合、墓地のこのカードを除外して発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"海造賊－黒翼の水先人",h:"ぷらんどろーる－こくよくのみずさきにん",e:"Blackeyes, the Plunder Patroll Seaguide",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「海造賊－黒翼の水先人」以外の自分の墓地の「海造賊」モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターを手札に加える。\nこのターン、自分は「海造賊」モンスターしか特殊召喚できない。\n②：このカードが手札・モンスターゾーンから墓地へ送られた場合、自分の魔法＆罠ゾーンの「海造賊」モンスターカード１枚を対象として発動できる。\nそのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリーナイツ・シエル",h:"ほーりーないつ・しえる",e:"Starry Knight Ciel",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、「ホーリーナイツ・シエル」以外の自分フィールドの、「ホーリーナイツ」モンスターまたはドラゴン族・光属性・レベル７モンスター１体を持ち主の手札に戻して発動できる。\nこのカードを特殊召喚する。\n②：自分フィールドにモンスターが存在しない場合、墓地のこのカードを除外して発動できる。\n手札からドラゴン族・光属性・レベル７モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷結界の浄玻璃",h:"ひょうけっかいのじょうはり",e:"Judge of the Ice Barrier",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに他の「氷結界」モンスターが存在する限り、相手がLPを払ってカードの効果を発動する度に相手は５００LPを失う。\n②：自分の墓地の「氷結界」モンスター及び相手の墓地のカードをそれぞれ２枚まで対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n③：自分フィールドに「氷結界」モンスターが存在する場合、墓地のこのカードを除外し、フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"スクラップ・ラプター",h:"すくらっぷ・らぷたー",e:"Scrap Raptor",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「スクラップ」モンスター１体を召喚できる。\n②：このカードが「スクラップ」カードの効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「スクラップ・ファクトリー」１枚またはチューナー以外の「スクラップ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ダーク・オネスト",h:"だーく・おねすと",e:"Dark Honest",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nフィールドの表側表示のこのカードを持ち主の手札に戻す。\n②：自分の闇属性モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nその相手モンスターの攻撃力はターン終了時まで、その攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"光の天穿バハルティヤ",h:"ひかりのてんせんばはるてぃや",e:"Bahalutiya, the Grand Radiance",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2400,tx:"このカードは効果モンスター１体をリリースしてアドバンス召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在し、相手メインフェイズに相手がドロー以外の方法でデッキからカードを手札に加えた場合に発動できる。\nこのカードをアドバンス召喚する。\n②：このカードが手札からの召喚・特殊召喚に成功した場合に発動できる。\n相手は自身の手札の数だけデッキの上からカードを裏側表示で除外する。\nその後、相手は手札を全てデッキに戻し、この効果で除外したカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"王家の守護者スフィンクス",h:"ふぁらおにっくがーでぃあんすふぃんくす",e:"Pharaonic Guardian Sphinx",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2300,de:3000,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードが反転召喚に成功した場合に発動する。\n相手フィールドのモンスターを全て持ち主のデッキに戻す。\n③：フィールドのこのカードが相手によって破壊された場合に発動できる。\n手札・デッキから岩石族・レベル５モンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"天魔神 シドヘルズ",h:"てんましん　しどへるず",e:"Sky Scourge Cidhels",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"このカードは特殊召喚できない。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\nこのカードのアドバンス召喚のためにリリースしたモンスターの種族・属性によって、以下の効果を適用する。\n\n●天使族・光属性：デッキから天使族・光属性または悪魔族・闇属性のモンスター１体を手札に加える。\n\n●悪魔族・闇属性：デッキから天使族・光属性と悪魔族・闇属性のモンスターをそれぞれ１体まで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"アンカモフライト",h:"あんかもふらいと",e:"Anchamoufrite",t:"monster",a:"光",l:5,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分のEXデッキのカードが、存在しない場合または「アンカモフライト」のみの場合に発動できる。\nこのカードを破壊する。\nその後、自分はデッキから１枚ドローする。",tr:"魔法使い族",mo:["特殊召喚","ペンデュラム","効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードは通常召喚できない。\nこのカードがEXデッキに表側表示で存在し、「アンカモフライト」以外のカードが自分のEXデッキに存在しない場合のみ特殊召喚できる。\nこの方法による「アンカモフライト」の特殊召喚は１ターンに１度しかできない。\n①：モンスターゾーンの表側表示のこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"ダークアイ・ナイトメア",h:"だーくあい・ないとめあ",e:"Dark Eye Nightmare",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1700,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の罠カードを３枚まで除外して発動できる。\n除外した数によって以下の効果を適用する。\n\n●１枚：自分はデッキから１枚ドローし、その後手札を１枚選んでデッキの一番上に戻す。\n\n●２枚：自分はデッキから１枚ドローする。\n\n●３枚：自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。\n\n②：１ターンに１度、このカードが戦闘で破壊される場合、代わりに自分の墓地の罠カード１枚を除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"地久神－カルボン",h:"ちきゅうじん－かるぼん",e:"World Soul - Carbon",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：通常召喚したこのカードをリリースして発動できる。\nデッキから「地久神－カルボン」以外の天使族・地属性モンスター１体を手札に加える。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の天使族モンスターが墓地へ送られた場合に発動できる。\nこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ヤモイモリ",h:"やもいもり",e:"Yamorimori",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外し、自分フィールドの爬虫類族モンスター１体と相手フィールドの表側表示モンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●対象のモンスターを裏側守備表示にする。\n\n●対象の自分のモンスターを破壊し、対象の相手モンスターの攻撃力をターン終了時まで０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"変導機咎 クロックアーク",h:"へんどうきこう　くろっくあーく",e:"Clock Arc",t:"monster",a:"闇",l:4,ps:4,pe:"①：自分フィールドのモンスターが、存在しない場合またはPモンスターのみの場合、このカードのPゾーンの位置によって以下の効果を適用する。\n\n●左側：このカードのPスケールは３つ下がる。\n\n●右側：このカードのPスケールは４つ上がる。\n\n②：相手スタンバイフェイズに発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:600,de:1200,tx:"①：このカードは中央以外のメインモンスターゾーンに特殊召喚した場合、破壊される。\n②：このカードは戦闘では破壊されない。\n③：相手エンドフェイズに、自分のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、このカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"落魂",h:"おとしだましい",e:"Otoshidamashi",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにチューナー以外のモンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。\n②：モンスターがフィールドから相手の墓地へ送られた場合に発動する。\nこのカードに落魂カウンターを１つ置く。\nその後、このカードの落魂カウンターの数まで、自分フィールドに「落魂トークン」（獣族・地・星１・攻／守？）を特殊召喚する。\nこのトークンのレベルはこのカードの落魂カウンターの数だけ上がり、攻撃力・守備力は、そのレベル×５００になる。",li:"",lk:[],ta:[],al:[]},
  {n:"凶導の白騎士",h:"どらぐまのあるばす・ないと",e:"White Knight of Dogmatika",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:500,de:2500,tx:"「凶導の福音」により降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分はEXデッキからモンスターを特殊召喚できない。\n②：相手が魔法・罠・モンスターの効果を発動した場合に発動できる。\n自分のEXデッキからモンスター１体を墓地へ送り、相手のEXデッキを確認し、その内のモンスター１体を選んで墓地へ送る。\nこのカードの攻撃力はターン終了時まで、墓地へ送ったモンスターの攻撃力を合計した数値の半分だけアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印竜アルビオン",h:"らくいんりゅうあるびおん",e:"Albion the Branded Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「アルバスの落胤」＋光属性モンスター\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合に発動できる。\n自分の手札・フィールド・墓地から、「烙印竜アルビオン」を除くレベル８以下の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「烙印」魔法・罠カード１枚を選んで手札に加えるか自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"ミュステリオンの竜冠",h:"みゅすてりおんのりゅうかん",e:"Mysterion the Dragon Crown",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:1500,tx:"魔法使い族モンスター＋ドラゴン族モンスター\nこのカード名の②の効果は１ターンに１度しか使用できない。\nこのカードは融合素材にできない。\n①：このカードの攻撃力は除外されている自分のカードの数×１００ダウンする。\n②：発動したモンスターの効果によって、そのモンスターまたはそのモンスターと元々の種族が同じモンスターが特殊召喚された場合、その特殊召喚されたモンスター１体を対象として発動できる。\n対象のモンスター及びそのモンスターと元々の種族が同じフィールドのモンスターを全て除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラッド・ローズ・ドラゴン",h:"ぶらっど・ろーず・どらごん",e:"Ruddy Rose Dragon",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上 \n①：このカードがS召喚に成功した場合に発動できる。\nお互いの墓地のカードを全て除外する。\nこのカードが「ブラック・ローズ・ドラゴン」または植物族Sモンスターを素材としてS召喚していた場合、さらにこのカード以外のお互いのフィールドのカードを全て破壊できる。\n②：カードを破壊する効果を相手が発動した時、このカードをリリースして発動できる。\nその発動を無効にする。\nその後、自分のEXデッキ・墓地から「ブラック・ローズ・ドラゴン」１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーデン・ローズ・フローラ",h:"がーでん・ろーず・ふろーら",e:"Garden Rose Flora",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"植物族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:800,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上 \nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：フィールドのフィールド魔法カード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、そのコントローラーのフィールドに「ローズ・トークン」（植物族・闇・星２・攻／守８００）１体を攻撃表示で特殊召喚する。\nこのターン、自分はSモンスターしかEXデッキから特殊召喚できない。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターを素材としてS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ラヴァルバル・エクスロード",h:"らう゛ぁるばる・えくすろーど",e:"Lavalval Exlord",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:200,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札・フィールドのモンスターの効果を相手が発動した時に発動できる。\nそのモンスターを破壊し、相手に１０００ダメージを与える。\n②：S召喚したこのカードが相手によって破壊された場合に発動できる。\n自分の墓地からSモンスター以外の守備力２００の炎属性モンスターを３体まで選んで守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"スター・マイン",h:"すたー・まいん",e:"Star Mine",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:200,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「スター・マイン」を１ターンに１度しか特殊召喚できない。\n①：このカードが相手モンスターの攻撃または相手の効果で破壊された場合に発動する。\n自分は２０００ダメージを受ける。\nその後、相手に２０００ダメージを与える。\n②：このカードの隣のモンスターゾーンに存在するモンスターが相手モンスターの攻撃または相手の効果で破壊された場合に発動する。\nこのカードを破壊し、自分は２０００ダメージを受ける。\nその後、相手に２０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"竜装合体 ドラゴニック・ホープレイ",h:"りゅうそうがったい　どらごにっく・ほーぷれい",e:"Ultimate Dragonic Utopia Ray",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル５モンスター×３\nこのカード名はルール上「CNo.39 希望皇ホープレイ」として扱う。\n①：１ターンに１度、このカードが効果の対象になった時、または攻撃対象に選択された時に発動できる。\n手札・デッキから「ZW」モンスター１体を選び、その効果による装備カード扱いとしてこのカードに装備する。\n②：１ターンに１度、このカードのX素材を１つ取り除き、このカードが装備している「ZW」モンスターカードの数まで、相手フィールドの表側表示のカードを対象として発動できる。\nそのカードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－弩級兵装竜王戟",h:"ぜある・うぇぽん－どらごにっく・はるばーど",e:"ZW - Dragonic Halberd",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:1200,tx:"レベル５モンスター×２\n①：このカードは直接攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから「ゼアル」魔法・罠カード１枚を手札に加える。\n③：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分フィールドのこのカードを攻撃力３０００アップの装備カード扱いとしてそのモンスターに装備する。\n④：装備モンスターが戦闘でモンスターを破壊した時に発動できる。\n装備モンスターが装備している「ZW」モンスターカードを任意の数だけ選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・メリーメイカー",h:"すぷりがんず・めりーめいかー",e:"Springans Merrymaker",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1100,de:2000,tx:"レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがEXデッキからの特殊召喚に成功した場合に発動できる。\nデッキから「スプリガンズ」モンスター１体を墓地へ送る。\n②：相手のメインフェイズ及びバトルフェイズに発動できる。\nこのカードをエンドフェイズまで除外する。\nX素材を２つ以上持っているこのカードを除外した場合、さらにEXデッキから「アルバスの落胤」を融合素材とする融合モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"結晶の魔女サンドリヨン",h:"まぎすとす・うぃっちさんどりよん",e:"Rilliona, the Wondrous Magistus Witch Wardrobe",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:2800,tx:"属性が異なる魔法使い族レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nデッキから「マギストス」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「マギストス」モンスターしかEXデッキから特殊召喚できない。\n②：このカードが装備されている場合、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"竜輝巧－ファフμβ’",h:"どらいとろん－ふぁふみゅーべーた",e:"Drytron Mu Beta Fafnir",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:0,tx:"レベル１モンスター×２体以上\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがX召喚に成功した場合に発動できる。\nデッキから「ドライトロン」カード１枚を墓地へ送る。\n②：儀式召喚を行う場合、そのリリースするモンスターを、このカードのX素材から取り除く事もできる。\n③：自分フィールドに機械族の儀式モンスターが存在し、相手が魔法・罠カードを発動した時、このカードのX素材を１つ取り除いて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣戦線 塊撃のベアブルム",h:"とらいぶりげーど　かいげきのべあぶるむ",e:"Tri-Brigade Bearbrumm the Rampant Rampager",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣族",mo:["リンク","効果"],ma:"",tp:"",at:1700,de:"-",tx:"「トライブリゲード」モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を２枚捨て、除外されている自分のレベル４以下の獣族・獣戦士族・鳥獣族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが墓地へ送られた場合に発動できる。\nデッキから「トライブリゲード」魔法・罠カード１枚を手札に加える。\nその後、手札を１枚選んでデッキの一番下に戻す。\nこの効果の発動後、ターン終了時まで自分は「トライブリゲード」モンスターしか特殊召喚できない。",li:"",lk:["左","右下"],ta:[],al:[]},
  {n:"ダークインファント＠イグニスター",h:"だーくいんふぁんとあっといぐにすたー",e:"Dark Infant @Ignister",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:500,de:"-",tx:"リンクモンスター以外の「＠イグニスター」モンスター１体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「イグニスターAiランド」１枚を手札に加える。\n②：元々の攻撃力が２３００のサイバース族モンスターが効果を発動した時に発動できる。\nこのカードの位置を、このカードのリンク先となる自分のメインモンスターゾーンに移動する。\nその後、このカードの属性をターン終了時まで任意の属性に変更できる。",li:"",lk:["左"],ta:[],al:[]},
  {n:"クラリアの蟲惑魔",h:"くらりあのこわくま",e:"Traptrix Cularia",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"昆虫族・植物族モンスター２体\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：リンク召喚したこのカードは罠カードの効果を受けない。\n②：このカードがモンスターゾーンに存在する限り、自分が発動した「ホール」通常罠カード及び「落とし穴」通常罠カードは発動後に墓地へ送らず、そのままセットできる。\n③：自分エンドフェイズに、自分の墓地の「蟲惑魔」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:["右","下"],ta:[],al:[]},
  {n:"バージェストマ・カンブロラスター",h:"ばーじぇすとま・かんぶろらすたー",e:"Paleozoic Cambroraster",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"「バージェストマ」モンスター２体\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのこのカードは他のモンスターの効果を受けない。\n②：魔法＆罠ゾーンにセットされたカード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキから「バージェストマ」罠カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。\n③：自分フィールドにセットされたカードが効果で破壊される場合、代わりにフィールド・墓地のこのカードを除外できる。",li:"",lk:["上","下"],ta:[],al:[]},
  {n:"廻生のベンガランゼス",h:"かいせいのべんがらんぜす",e:"Benghalancer the Resurgent",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"植物族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"植物族モンスター２体以上\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：お互いのメインフェイズに、相手フィールドの効果モンスター１体を対象として発動できる。\n自分はそのモンスターの攻撃力分のダメージを受け、そのモンスターを持ち主の手札に戻す。\n②：このカードが墓地に存在する場合、リンクマーカーの合計が４になるように自分の墓地からリンクモンスターを２体以上除外して発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"照耀の光霊使いライナ",h:"しょうようのこうれいつかいらいな",e:"Lyna the Light Charmer, Lustrous",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1850,de:"-",tx:"光属性モンスターを含むモンスター２体\nこのカード名はルール上「憑依装着」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の光属性モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから守備力１５００以下の光属性モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"RUM－ゼアル・フォース",h:"らんくあっぷまじっく－ぜある・ふぉーす",e:"Rank-Up-Magic Zexal Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのXモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが１つ高い、「希望皇ホープ」モンスターまたは「ZW」モンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚し、デッキから「ZW」モンスターまたは「ZS」モンスター１体を選んでデッキの一番上に置く。\n②：自分のLPが相手より２０００以上少ない場合、墓地のこのカードを除外して発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼアル・コンストラクション",h:"ぜある・こんすとらくしょん",e:"Zexal Construction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚相手に見せ、デッキから以下のカードの内いずれか１枚を手札に加える。\nその後、見せたカードをデッキに戻す。\n\n●「ZW」モンスター\n●「ZS」モンスター\n●「ゼアル」魔法・罠カード\n●「RUM」魔法カード\n●「RDM」魔法カード",li:"",lk:[],ta:[],al:[]},
  {n:"ゼアル・エントラスト",h:"ぜある・えんとらすと",e:"Zexal Entrust",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「希望皇ホープ」、「ZW」、「ZS」モンスターの内、いずれか１体を対象として発動できる。\nそのモンスターを手札に加えるか特殊召喚する。\n②：自分のLPが相手より２０００以上少ない場合、墓地のこのカードを除外し、「ゼアル・エントラスト」以外の自分の墓地の「ゼアル」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"凶導の福音",h:"どらぐまーた",e:"Dogmatikalamity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ドラグマ」儀式モンスターの降臨に必要。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように自分の手札・フィールドのモンスターをリリース、または儀式召喚するモンスターと同じレベルのモンスター１体をEXデッキから墓地へ送り、手札から「ドラグマ」儀式モンスター１体を儀式召喚する。\nこのカードの発動後、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・ブーティー",h:"すぷりがんず・ぶーてぃー",e:"Springans Booty",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示のXモンスターが効果でフィールドから離れた場合、相手フィールドの効果モンスター１体を対象として発動できる。\nこのターン、その効果モンスターはフィールドで発動する効果を発動できない。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n自分のデッキ・墓地から「大砂海ゴールド・ゴルゴンダ」１枚を選んで発動する。",li:"",lk:[],ta:[],al:[]},
  {n:"白の烙印",h:"しろのらくいん",e:"Branded in White",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、融合モンスターカードによって決められた、ドラゴン族モンスターを含む融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n自分の「アルバスの落胤」を融合素材とする場合、自分の墓地のモンスターを除外して融合素材とする事もできる。\n②：このカードが「アルバスの落胤」の効果を発動するために墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"アメイジングタイムチケット",h:"あめいじんぐたいむちけっと",e:"Amazing Time Ticket",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：８００LPを払って発動できる。\n発動ターンによって以下の効果を適用する。\n\n●自分ターン：デッキから「アメイズメント」カード１枚を手札に加える。\n\n●相手ターン：デッキから「アトラクション」罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アメイズメント・スペシャルショー",h:"あめいずめんと・すぺしゃるしょー",e:"Amazement Special Show",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「アメイズメント」モンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、その自分の「アメイズメント」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\nその後、手札から「アメイズメント」モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"アメイズメント・プレシャスパーク",h:"あめいずめんと・ぷれしゃすぱーく",e:"Amazement Precious Park",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分は「アトラクション」罠カード１枚を、セットしたターンの自分メインフェイズに発動できる。\n②：自分・相手のエンドフェイズに、モンスターに装備されている自分フィールドの「アトラクション」罠カード１枚を墓地へ送り、自分の墓地のカード及び除外されている自分のカードの中から、墓地へ送ったカードとはカード名が異なる「アトラクション」罠カード１枚を対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"薫り貴き薔薇の芽吹き",h:"べーさる・ろーず・しゅーと",e:"Basal Rose Shoot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地から「ローズ・ドラゴン」モンスター１体を選んで守備表示で特殊召喚する。\n②：セットされたこのカードが破壊された場合、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「ブラック・ローズ・ドラゴン」１体またはそのカード名が記されたモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"めぐり－Ai－",h:"めぐり－あい－",e:"A.I. Meet You",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札・EXデッキの攻撃力２３００のサイバース族モンスター１体を相手に見せ、そのモンスターと同じ属性を持つ「＠イグニスター」モンスター１体をデッキから手札に加える。\n発動後、このターン中に自分がこの効果で見せたモンスターまたはその同名モンスターの特殊召喚に成功しなかった場合、エンドフェイズに自分は２３００ダメージを受ける。\nこのカードの発動後、ターン終了時まで自分はサイバース族以外のモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ユウ－Ai－",h:"ゆう－あい－",e:"You and A.I.",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：元々の攻撃力が２３００のサイバース族モンスターが特殊召喚された場合、その属性によって以下の効果から１つを選択して発動できる。\n「ユウ－Ai－」の以下の効果は１つの属性につき１ターンに１度しか選択できない。\n\n●地・水：フィールドの表側表示モンスター１体を選び、その攻撃力をターン終了時まで半分にする。\n\n●風・光：フィールドの表側表示のカード１枚を選び、その効果をターン終了時まで無効にする。\n\n●炎・闇：自分フィールドに「＠イグニスタートークン」（サイバース族・闇・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"武神籬",h:"ぶじんまがき",e:"Bujincandescence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの獣戦士族の「武神」モンスターはバトルフェイズ以外では相手の効果で破壊されない。\n②：１ターンに１度、手札及び自分フィールドの表側表示モンスターの中から、「武神」モンスター１体を墓地へ送り、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\n③：自分エンドフェイズに、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分の墓地の「武神」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"炎雄爆誕",h:"えんゆうばくたん",e:"Birth of the Prominence Flame",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の守備力２００の炎属性モンスターの中から、チューナー１体とチューナー以外のモンスター１体を対象として発動できる。\nそのモンスター２体を除外し、そのレベルの合計と同じレベルを持つ炎属性Sモンスター１体をEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"皆既月蝕の書",h:"かいきげっしょくのしょ",e:"Book of Lunar Eclipse",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、フィールドの表側表示モンスター２体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ワンクリウェイ",h:"わんくりうぇい",e:"One-Kuri-Way",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分または相手の墓地のリンク１モンスター１体を対象として発動できる。\nそのモンスターを持ち主のEXデッキに戻すか持ち主のフィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"極東秘泉郷",h:"きょくとうひせんきょう",e:"Hidden Springs of the Far East",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：お互いのプレイヤーは１ターンに１度、自分メインフェイズ２に発動できる。\n自分は５００LP回復し、このターン中、以下の効果を適用する。\n\n●自分のモンスターの召喚・特殊召喚は無効化されない。\n\n●モンスターを特殊召喚する効果を含む、モンスターの効果・魔法・罠カードを自分が発動した場合、その発動は無効化されない。\n\n●自分フィールドにセットされた魔法・罠カードは相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼアル・アライアンス",h:"ぜある・あらいあんす",e:"Zexal Alliance",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示のXモンスターが戦闘または相手の効果で破壊された場合、１０LPになるようにLPを払って発動できる。\n自分の墓地から「希望皇ホープ」モンスター１体を選んで特殊召喚し、デッキからカード１枚を選んでデッキの一番上に置く。\nこの効果で特殊召喚したモンスターは、攻撃力が倍になり、効果では破壊されず、「No.」モンスター以外のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印凶鳴",h:"らくいんきょうめい",e:"Screams of the Branded",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：融合モンスターが自分の墓地へ送られたターンに発動できる。\n自分の墓地のモンスター及び除外されている自分のモンスターの中から、融合モンスター１体を選んで特殊召喚する。\n②：このカードが「アルバスの落胤」の効果を発動するために墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印の裁き",h:"らくいんのさばき",e:"Judgment of the Branded",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル８以上の融合モンスター１体を対象として発動できる。\nそのモンスターの攻撃力以上の攻撃力を持つ相手フィールドのモンスターを全て破壊する。\n②：このカードが「アルバスの落胤」の効果を発動するために墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"アメイズメント・ファミリーフェイス",h:"あめいずめんと・ふぁみりーふぇいす",e:"Amazement Family Faces",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の「アトラクション」罠カードを装備した相手フィールドのモンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：装備モンスターのコントロールを得る。\n③：装備モンスターは自分のモンスターゾーンに存在する限り、攻撃力が５００アップし、効果を発動できず、「アメイズメント」モンスターとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"A・∀・CC",h:"あめいず・あとらくしょん・さいくろんこーすたー",e:"Amaze Attraction Cyclo-Coaster",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：装備モンスターのコントローラーによって以下の効果を発動できる。\n\n●自分：相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードとこのカードを墓地へ送る。\n\n●相手：デッキから「アメイズメント」モンスター１体を手札に加え、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"A・∀・WW",h:"あめいず・あとらくしょん・わんだーほいーる",e:"Amaze Attraction Wonder Wheel",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：装備モンスターのコントローラーによって以下の効果を発動できる。\n\n●自分：お互いのメインフェイズに発動できる。\n手札を１枚選んでデッキの一番下に戻す。\nその後、自分はデッキから１枚ドローする。\n\n●相手：装備モンスターの攻撃力・守備力をターン終了時まで入れ替える。",li:"",lk:[],ta:[],al:[]},
  {n:"A・∀・MM",h:"あめいず・あとらくしょん・まじぇすてぃっくまねーじゅ",e:"Amaze Attraction Majestic Merry-Go-Round",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：装備モンスターのコントローラーによって以下の効果を適用する。\n\n●自分：装備モンスターの攻撃力は５００アップする。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを墓地へ送る事ができる。\n\n●相手：装備モンスターの攻撃力は、モンスターに装備されている自分の「アトラクション」罠カードの数×５００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"A・∀・RR",h:"あめいず・あとらくしょん・らぴっどれーしんぐ",e:"Amaze Attraction Rapid Racing",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：装備モンスターのコントローラーによって以下の効果を発動できる。\n\n●自分：相手の墓地のカード１枚を対象として発動できる。\n装備モンスターの表示形式を変更し、対象のカードをデッキに戻す。\n\n●相手：装備モンスターのレベルをターン終了時まで１つ上げ、表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・∀・HH",h:"あめいず・あとらくしょん・ほらーはうす",e:"Amaze Attraction Horror House",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：装備モンスターのコントローラーによって以下の効果を発動できる。\n\n●自分：相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n\n●相手：装備モンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"果たし－Ai－",h:"はたし－あい－",e:"A.I. Challenge You",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの攻撃力は、自分フィールドのカードの数×１００ダウンする。\n②：自分の「＠イグニスター」モンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n③：自分の「＠イグニスター」モンスターが戦闘で破壊された時、そのモンスター以外の自分の墓地の攻撃力２３００のサイバース族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force チェイス",h:"せきゅりてぃ・ふぉーす　ちぇいす",e:"S-Force Chase",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「S－Force」モンスターの種類の数まで相手フィールドの表側表示のカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：自分フィールドの「S－Force」モンスターが効果を発動するために手札を除外する場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ワン・バイ・ワン",h:"わん・ばい・わん",e:"One by One",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドからモンスター１体を墓地へ送り、自分の墓地のモンスター及び除外されている自分のモンスターの中から、墓地へ送ったモンスター以外のレベル１モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブービーゲーム",h:"ぶーびーげーむ",e:"Boo-Boo Game",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：モンスターが戦闘を行うダメージ計算時に発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。\n②：セットされたこのカードが相手の効果で破壊され墓地へ送られた場合、「ブービーゲーム」以外の自分の墓地の通常罠カードを２枚まで対象として発動できる。\nそのカードを自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"福悲喜",h:"ふくびき",e:"Fukubiki",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いのプレイヤーは、デッキをシャッフルし、デッキの一番上のカードをめくってそのカードの攻撃力を確認する（攻撃力？のモンスターまたは魔法・罠カードの場合はその攻撃力を０として扱う）。\n攻撃力が高い方のカードをそのプレイヤーの手札に加え、攻撃力が低い方のカードを墓地へ送る。\n攻撃力が同じだった場合、それらのカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"プロキシー・ホース",h:"ぷろきしー・ほーす",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのこのカードをサイバース族モンスターのリンク素材とする場合、手札のサイバース族モンスターも１体までリンク素材にできる。\n②：自分エンドフェイズに墓地のこのカードを除外し、サイバース族リンクモンスターを含む自分の墓地のリンクモンスター２体を対象として発動できる。\nそのモンスターをEXデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"クロノダイバー・テンプホエーラー",h:"くろのだいばー・てんぷほえーらー",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターをエンドフェイズまで除外する。\n②：このカードが墓地に存在する場合、「クロノダイバー・テンプホエーラー」以外の自分フィールドの「クロノダイバー」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、このカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"CNo.1000 夢幻虚神ヌメロニアス",h:"かおすなんばーず１０００ むげんきょしんぬめろにあす",e:"Number C1000: Numerounius",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル１２モンスター×５\n①：お互いのターンに１度、このカードのX素材を１つ取り除いて発動できる。\nフィールドの他のモンスター１体を選んで破壊する。\n②：バトルフェイズ終了時に発動する。\nフィールドの他のモンスターを全て破壊する。\nその後、相手の墓地からモンスター１体を選んで守備表示で特殊召喚できる。\n③：X素材を持っているこのカードが相手の効果で破壊され墓地へ送られた場合に発動できる。\nEXデッキから「CiNo.1000 夢幻虚光神ヌメロニアス・ヌメロニア」１体を特殊召喚し、このカードをそのX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"CiNo.1000 夢幻虚光神ヌメロニアス・ヌメロニア",h:"かおすいまじなりーなんばーず１０００ むげんきょこうしんぬめろにあす・ぬめろにあ",e:"Number iC1000: Numerounius Numerounia",t:"monster",a:"光",l:13,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"レベル１３モンスター×５\n①：「CNo.1000 夢幻虚神ヌメロニアス」の効果で特殊召喚したこのカードは、攻撃力・守備力が相手ターンの間１０００００アップし、特殊召喚された次のターンより以下を適用する。\n\n●攻撃可能な相手モンスターはこのカードを攻撃しなければならない。\n\n●このカードが戦闘を行っていない相手ターン終了時、自分はデュエルに勝利する。\n\n②：相手モンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\nその攻撃を無効にし、その攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヌメロン・カオス・リチューアル",h:"ぬめろん・かおす・りちゅーある",e:"Numeron Chaos Ritual",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「CNo.1 ゲート・オブ・カオス・ヌメロン－シニューニャ」がモンスターの効果で破壊されたターン、自分の墓地のカード及び除外されている自分のカードの中から、「ヌメロン・ネットワーク」１枚と「No.」Xモンスター４体を対象として発動できる。\nEXデッキから「CNo.1000 夢幻虚神ヌメロニアス」１体を攻撃力１００００／守備力１０００にして特殊召喚し、対象のカード５枚をそのX素材とする。\nこの効果の発動後、ターン終了時まで自分は１回しかモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の滓－ヌル",h:"めいかいのおり－ぬる",e:"Nunu, the Ogdoadic Remnant",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から墓地へ送って発動できる。\nデッキから爬虫類族・闇属性モンスター１体を墓地へ送る。\n②：自分フィールドに、モンスターが存在しない場合、または「溟界」モンスターが存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果で特殊召喚したこのカードがモンスターゾーンに表側表示で存在する限り、自分は爬虫類族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の滓－ナイア",h:"めいかいのおり－ないあ",e:"Nauya, the Ogdoadic Remnant",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から墓地へ送って発動できる。\nデッキから爬虫類族・光属性モンスター１体を墓地へ送る。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「溟界」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の漠－フロギ",h:"めいかいのすなはら－ふろぎ",e:"Flogos, the Ogdoadic Boundless",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが、フィールドから墓地へ送られた場合、または墓地からの特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体と、そのモンスターの攻撃力以上の攻撃力を持つ相手の墓地のモンスター１体を対象として発動できる。\n対象の墓地のモンスターを相手フィールドに特殊召喚し、対象の相手フィールドのモンスターを墓地へ送る。\n②：このカードが墓地に存在する場合、手札を１枚墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の漠－ゾーハ",h:"めいかいのすなはら－ぞーは",e:"Zohah, the Ogdoadic Boundless",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが、フィールドから墓地へ送られた場合、または墓地からの特殊召喚に成功した場合に発動できる。\n相手はデッキから１枚ドローし、自分はデッキから「溟界の漠－ゾーハ」以外の「溟界」モンスター１体を手札に加える。\nその後、お互いのプレイヤーは手札を１枚選んで墓地へ送る。\n②：このカードが墓地に存在する場合、手札を１枚墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の黄昏－カース",h:"めいかいのたそがれ－かーす",e:"Keurse, the Ogdoadic Light",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:400,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\nその後、相手は自身の墓地からモンスター１体を選んで効果を無効にして特殊召喚できる。\n②：このカードが特殊召喚に成功した場合、自分の墓地のレベル４以下の「溟界」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の昏闇－アレート",h:"めいかいのくらやみ－あれーと",e:"Aleirtt, the Ogdoadic Dark",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\nその後、相手は自身の墓地からモンスター１体を選んで手札に加える事ができる。\n②：このカードが特殊召喚に成功した場合、除外されている自分のモンスターの中から、爬虫類族モンスターを含むモンスター２体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界王－アロン",h:"めいかいおう－あろん",e:"Aron, the Ogdoadic King",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2500,de:2800,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドのモンスター２体をリリースして発動できる。\nこのカードを特殊召喚する。\n②：相手がドローフェイズ以外でカードを手札に加えた場合に発動できる。\n相手の手札をランダムに１枚選んで墓地へ送る。\n③：相手フィールドのモンスターが効果で墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「溟界王－アロン」以外の光・闇属性の爬虫類族モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界妃－アミュネシア",h:"めいかいひ－あみゅねしあ",e:"Amunessia, the Ogdoadic Queen",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2700,de:2100,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドのモンスター２体をリリースして発動できる。\nこのカードを特殊召喚する。\n②：相手の墓地からモンスターが特殊召喚された場合に発動できる。\n相手フィールドのカード１枚を選んで墓地へ送る。\n③：相手の手札・デッキからモンスターが墓地へ送られた場合に発動できる。\n自分の墓地から「溟界妃－アミュネシア」以外の光・闇属性の爬虫類族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界神－オグドアビス",h:"めいかいしん－おぐどあびす",e:"Ogdoabyss, the Ogdoadic Overlord",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:3100,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドのモンスター３体をリリースして発動できる。\nこのカードを特殊召喚する。\n②：このカードがフィールドに表側表示で存在する限り１度だけ発動できる。\n墓地から特殊召喚された表側表示モンスター以外の、自分・相手フィールドのモンスターを全て墓地へ送る。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の蛇睡蓮",h:"めいかいのじゃすいれん",e:"Ogdoadic Water Lily",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから爬虫類族モンスター１体を墓地へ送る。\nその後、自分の墓地に爬虫類族モンスターが５種類以上存在する場合、自分の墓地から爬虫類族モンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の淵源",h:"めいかいのえんげん",e:"Ogdoadic Origin",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の爬虫類族モンスターが戦闘または相手の効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを墓地へ送る。\n②：フィールドゾーンのこのカードが相手の効果で破壊された場合に発動できる。\n自分の墓地の爬虫類族モンスターの種類の数だけ、相手のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の虚",h:"めいかいのうつろ",e:"Ogdoadic Hollow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、自分フィールドの爬虫類族の効果モンスター１体をリリースし、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに墓地へ送られる。\n②：魔法＆罠ゾーンの表側表示のこのカードが墓地へ送られた場合に発動する。\n爬虫類族モンスター以外の自分フィールドの表側表示モンスターを全て墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の呼び蛟",h:"めいかいのよびみず",e:"Ogdoadic Calling",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「溟界トークン」（爬虫類族・闇・星２・攻／守０）２体を特殊召喚する。\n自分の墓地に「溟界」モンスターが８種類以上存在する場合、代わりに以下の効果を適用できる。\n●自分の墓地からカード名が異なる爬虫類族モンスター２体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドレミコード・キューティア",h:"どどれみこーど・きゅーてぃあ",e:"DoSolfachord Cutia",t:"monster",a:"地",l:1,ps:8,pe:"①：自分の「ドレミコード」PモンスターのP召喚は無効化されない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:400,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ドドレミコード・キューティア」以外の「ドレミコード」Pモンスター１体を手札に加える。\n②：自分のPゾーンに偶数のPスケールが存在する限り、自分フィールドの「ドレミコード」Pモンスターの攻撃力は自身のPスケール×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"レドレミコード・ドリーミア",h:"れどれみこーど・どりーみあ",e:"ReSolfachord Dreamia",t:"monster",a:"風",l:2,ps:7,pe:"①：自分の「ドレミコード」PモンスターのP召喚は無効化されない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:600,de:400,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：自分のPゾーンに「ドレミコード」カードが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分のPゾーンに奇数のPスケールが存在し、自分フィールドの「ドレミコード」Pモンスターカードが相手の効果で破壊される場合、その破壊されるカード１枚の代わりにこのカードを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ミドレミコード・エリーティア",h:"みどれみこーど・えりーてぃあ",e:"MiSolfachord Eliteia",t:"monster",a:"水",l:3,ps:6,pe:"①：自分の「ドレミコード」PモンスターのP召喚は無効化されない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1100,de:400,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：自分のPゾーンに偶数のPスケールが存在する限り、自分の「ドレミコード」Pモンスターの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ファドレミコード・ファンシア",h:"ふぁどれみこーど・ふぁんしあ",e:"FaSolfachord Fancia",t:"monster",a:"炎",l:4,ps:5,pe:"①：自分の「ドレミコード」PモンスターのP召喚は無効化されない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:400,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキから「ファドレミコード・ファンシア」以外の「ドレミコード」Pモンスター１体を選び、EXデッキに表側表示で加える。\n②：自分のPゾーンに奇数のPスケールが存在し、自分の「ドレミコード」Pモンスターが戦闘で破壊される場合、代わりにこのカードを破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ソドレミコード・グレーシア",h:"そどれみこーど・ぐれーしあ",e:"SolSolfachord Gracia",t:"monster",a:"水",l:5,ps:4,pe:"①：自分の「ドレミコード」PモンスターのP召喚成功時に相手はモンスターの効果・魔法・罠カードを発動できない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2100,de:1400,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ドレミコード」魔法・罠カード１枚を手札に加える。\n②：自分のPゾーンに偶数のPスケールが存在し、自分の「ドレミコード」Pモンスターが攻撃する場合、相手はダメージステップ終了時までモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ラドレミコード・エンジェリア",h:"らどれみこーど・えんじぇりあ",e:"LaSolfachord Angelia",t:"monster",a:"炎",l:6,ps:3,pe:"①：自分の「ドレミコード」PモンスターのP召喚成功時に相手はモンスターの効果・魔法・罠カードを発動できない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2300,de:1400,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分フィールドの「ドレミコード」Pモンスター１体をリリースし、そのモンスターよりPスケールが２つ高いまたは２つ低い、「ラドレミコード・エンジェリア」以外の「ドレミコード」Pモンスター１体をデッキから特殊召喚する。\n②：自分のPゾーンに奇数のPスケールが存在し、自分の「ドレミコード」Pモンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"シドレミコード・ビューティア",h:"しどれみこーど・びゅーてぃあ",e:"TiSolfachord Beautia",t:"monster",a:"地",l:7,ps:2,pe:"①：自分の「ドレミコード」PモンスターのP召喚成功時に相手はモンスターの効果・魔法・罠カードを発動できない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2400,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：自分・相手ターンに、相手フィールドの効果モンスター１体を対象として発動できる。\nこのターン、そのカードはフィールドから離れた場合に除外される。\n自分のPゾーンに偶数のPスケールが存在する場合、相手フィールドの表側表示の魔法・罠カード１枚を対象とする事もできる。\n②：１ターンに１度、このカードが自分のPゾーンの一番低いPスケール×３００以上の攻撃力を持つモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドドレミコード・クーリア",h:"どどれみこーど・くーりあ",e:"DoSolfachord Coolia",t:"monster",a:"風",l:8,ps:1,pe:"①：自分の「ドレミコード」PモンスターのP召喚成功時に相手はモンスターの効果・魔法・罠カードを発動できない。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2700,de:2500,tx:"このカード名の②③のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは自分フィールドのPモンスター２体をリリースして手札から特殊召喚できる。\n②：相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果を相手ターン終了時まで無効にする。\n自分のPゾーンに奇数のPスケールが存在する場合、この効果の対象を２枚にできる。\n③：自分のPゾーンの一番高いPスケール×３００以下の攻撃力を持つフィールドのモンスターの効果が発動した時に発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレミコード・エレガンス",h:"どれみこーど・えれがんす",e:"Solfachord Elegance",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●デッキから「ドレミコード」Pモンスター１体を選び、自分のPゾーンに置く。\n\n●手札から「ドレミコード」Pモンスター１体を選び、EXデッキに表側表示で加える。\nその後、デッキからPスケールが奇数と偶数の「ドレミコード」Pモンスターを１体ずつ選び、自分のPゾーンに置く。\n\n●自分のPゾーンからPスケールが奇数と偶数のカードを１枚ずつ持ち主のEXデッキに表側表示で加え、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレミコード・スケール",h:"どれみこーど・すけーる",e:"Solfachord Scale",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ドレミコード」Pモンスターカードの種類によって以下の効果をそれぞれ適用できる。\n\n●３種類以上：自分のPゾーンのカード１枚を選んで持ち主の手札に戻し、自分のEXデッキから表側表示の「ドレミコード」Pモンスター１体を選んで自分のPゾーンに置く。\n\n●５種類以上：手札から「ドレミコード」Pモンスター１体を特殊召喚する。\n\n●７種類以上：相手フィールドの表側表示のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレミコード・ハルモニア",h:"どれみこーど・はるもにあ",e:"Solfachord Harmonia",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n「ドレミコード・ハルモニア」の以下の効果はそれぞれ１ターンに１度しか選択できない。\n\n●自分のEXデッキから表側表示の「ドレミコード」Pモンスター１体を手札に加える。\n\n●自分のPゾーンの「ドレミコード」カード１枚を選ぶ。\nこのターン、そのPスケールをそのカードのレベル分だけ上げる。\n\n●自分フィールドの「ドレミコード」PモンスターカードのPスケールが奇数３種類以上または偶数３種類以上の場合、相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレミコード・ムジカ",h:"どれみこーど・むじか",e:"Solfachord Musica",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ドレミコード」PモンスターカードのPスケールによって以下の効果から１つを選択して発動できる。\n\n●奇数：自分のEXデッキからPスケールが奇数の表側表示の「ドレミコード」Pモンスター１体を特殊召喚する。\n\n●偶数：自分のEXデッキからPスケールが偶数の表側表示の「ドレミコード」Pモンスター１体を特殊召喚する。\n\n●奇数と偶数：相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ドレミコード・フォーマル",h:"どれみこーど・ふぉーまる",e:"Solfachord Formal",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のPゾーンに「ドレミコード」カードが存在し、相手がモンスターの効果・魔法・罠カードを発動した時に発動できる。\n自分のEXデッキから表側表示のPモンスター１体をデッキに戻す。\nその後、以下の効果を適用する。\n\n●自分フィールドのPモンスターはその相手の効果を受けない。\n\n●自分のPゾーンのカードはその相手の効果では破壊されない。\n\n●自分のPゾーンのカードはその相手の効果では除外できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－ミクポーラ",h:"べあるくてぃ－みくぽーら",e:"Ursarctic Mikpolar",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札からこのカード以外のレベル７以上のモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はレベルを持つモンスターしか特殊召喚できない。\n②：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ベアルクティ－ミクポーラ」以外の「ベアルクティ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－ミクタナス",h:"べあるくてぃ－みくたなす",e:"Ursarctic Miktanus",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札からこのカード以外のレベル７以上のモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はレベルを持つモンスターしか特殊召喚できない。\n②：このカードが特殊召喚に成功した場合、「ベアルクティ－ミクタナス」以外の自分の墓地の「ベアルクティ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－ミクビリス",h:"べあるくてぃ－みくびりす",e:"Ursarctic Mikbilis",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札からこのカード以外のレベル７以上のモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はレベルを持つモンスターしか特殊召喚できない。\n②：このカードが特殊召喚に成功した場合に発動できる。\n手札から「ベアルクティ－ミクビリス」以外の「ベアルクティ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－メガポーラ",h:"べあるくてぃ－めがぽーら",e:"Ursarctic Megapolar",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:2500,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札からこのカード以外のレベル７以上のモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はレベルを持つモンスターしか特殊召喚できない。\n②：自分フィールドに他の「ベアルクティ」モンスターが存在する状態で、このカードが特殊召喚に成功した場合、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－メガタナス",h:"べあるくてぃ－めがたなす",e:"Ursarctic Megatanus",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:2400,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札からこのカード以外のレベル７以上のモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はレベルを持つモンスターしか特殊召喚できない。\n②：自分フィールドに他の「ベアルクティ」モンスターが存在する状態で、このカードが特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－メガビリス",h:"べあるくてぃ－めがびりす",e:"Ursarctic Megabilis",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:2800,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、手札からこのカード以外のレベル７以上のモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はレベルを持つモンスターしか特殊召喚できない。\n②：自分フィールドに他の「ベアルクティ」モンスターが存在する状態で、このカードが特殊召喚に成功した場合、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－ポラリィ",h:"べあるくてぃ－ぽらりぃ",e:"Ursarctic Polari",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:700,de:1000,tx:"このカードはS召喚できず、レベルの差が１となるように自分フィールドからチューナー１体とチューナー以外のモンスター１体を墓地へ送った場合のみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ベアルクティ・ビッグディッパー」１枚を選んで発動する。\n②：自分フィールドのレベル７以上のモンスター１体をリリースして発動できる。\n自分の墓地から「ベアルクティ」モンスター１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－セプテン＝トリオン",h:"べあるくてぃ－せぷてん＝とりおん",e:"Ursarctic Septentrion",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:700,tx:"このカードはS召喚できず、レベルの差が７となるように自分フィールドからレベル８以上のチューナー１体とチューナー以外のSモンスター１体を墓地へ送った場合のみ特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、EXデッキから特殊召喚されたレベルを持たない表側表示モンスターの効果は無効化される。\n②：相手がモンスターの特殊召喚に成功した場合に発動できる。\nデッキから「ベアルクティ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ－グラン＝シャリオ",h:"べあるくてぃ－ぐらん＝しゃりお",e:"Ursarctic Grand Chariot",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:700,tx:"このカードはS召喚できず、レベルの差が７となるように自分フィールドからレベル８以上のチューナー１体とチューナー以外のSモンスター１体を墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\n②：１ターンに１度、自分フィールドの「ベアルクティ」カードを対象とするカードの効果が発動した時、自分の手札・フィールドのモンスター１体をリリースして発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ・ディパーチャー",h:"べあるくてぃ・でぃぱーちゃー",e:"Ursarctic Departure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\nデッキから「ベアルクティ」モンスター２体を手札に加える。\n②：自分が「ベアルクティ」モンスターの効果を発動するためにモンスターをリリースする場合、代わりに墓地のこのカードを除外できる。\nこの効果はこのカードが墓地へ送られたターンには使用できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ・スライダー",h:"べあるくてぃ・すらいだー",e:"Ursarctic Slider",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、「ベアルクティ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、エンドフェイズに破壊される。\nこのカードの発動後、ターン終了時まで自分はレベルを持つモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ・ビッグディッパー",h:"べあるくてぃ・びっぐでぃっぱー",e:"Ursarctic Big Dipper",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分の「ベアルクティ」モンスターが効果を発動するためにモンスターをリリースする場合、代わりに自分の墓地のレベル７以上の「ベアルクティ」モンスター１体を除外できる。\n②：モンスターが特殊召喚される度にこのカードにカウンターを１つ置く。\n③：１ターンに１度、モンスターが特殊召喚された場合、７つ以上のこのカードのカウンターを全て取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのコントロールを得る。\nこの効果はフィールドに「ベアルクティ」Sモンスターが存在する場合に発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ・クィントチャージ",h:"べあるくてぃ・くぃんとちゃーじ",e:"Ursarctic Quint Charge",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、７００LPを払い、以下の効果から１つを選択して発動できる。\n●自分の墓地から「ベアルクティ」モンスター１体を選んで手札に加える。\n●自分フィールドの「ベアルクティ」モンスター２体をリリースし、そのレベルの差と同じレベルを持つ「ベアルクティ」モンスター１体をEXデッキから召喚条件を無視して特殊召喚する。\n②：自分の「ベアルクティ」Sモンスターが相手の攻撃で破壊された時に発動できる。\n相手は自身の手札・フィールド・墓地のカードの合計が７枚になるように持ち主のデッキに戻さなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"ステイセイラ・ロマリン",h:"すていせいら・ろまりん",e:"Staysailor Romarin",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスター以外の自分フィールドの植物族モンスター１体を選んで墓地へ送り、対象のモンスターはこのターンに１度だけ戦闘・効果では破壊されない。\nこの効果は相手ターンでも発動できる。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキ・EXデッキからレベル５以下の植物族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"あまびえさん",h:"あまびえさん",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分メインフェイズ１開始時に、手札のこのカードを相手に見せて発動できる。\nお互いのプレイヤーは３００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"霊塞術師 チョウサイ",h:"れいさいじゅつし　ちょうさい",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1900,de:800,tx:"①：このカードがモンスターゾーンに存在する限り、お互いに墓地の魔法・罠カードの効果を発動できない。\n②：このカードがフィールドから墓地へ送られた場合、相手の墓地の魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"破魔のカラス天狗",h:"はまのからすてんぐ",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが、相手の効果で破壊され墓地へ送られた場合、または墓地からの特殊召喚に成功した場合、相手フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"想い集いし竜",h:"おもいつどいしりゅう",e:"Converging Wills Dragon",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"自分は「想い集いし竜」を１ターンに１度しか特殊召喚できず、このカードをS素材とする場合、「セイヴァー」モンスターのS召喚にしか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「救世竜 セイヴァー・ドラゴン」として扱う。\n②：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n自分フィールドにレベル８以上のドラゴン族Sモンスターが存在する場合、さらにデッキからドラゴン族・レベル１モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・シンクロン",h:"すたーだすと・しんくろん",e:"Stardust Synchron",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分はSモンスターしかEXデッキから特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「スターダスト・ドラゴン」のカード名が記された魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・トレイル",h:"すたーだすと・とれいる",e:"Stardust Trail",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドのモンスターがリリースされた場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードをS素材として「ウォリアー」、「シンクロン」、「スターダスト」SモンスターのS召喚に成功した場合に発動できる。\n自分フィールドに「スターダスト・トークン」（ドラゴン族・光・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"喜劇のデスピアン",h:"きげきのですぴあん",e:"Despian Comedy",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示の「デスピア」カードを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードを手札から捨てて発動できる。\nその効果を無効にする。\n②：このカードが墓地に存在する場合、自分フィールドの融合モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"悲劇のデスピアン",h:"ひげきのですぴあん",e:"Despian Tragedy",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが効果で墓地へ送られた場合、または効果で除外された場合に発動できる。\nデッキから「悲劇のデスピアン」以外の「デスピア」モンスター１体を手札に加える。\n②：墓地のこのカードを除外し、自分の墓地の「烙印」魔法・罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"デスピアの導化アルベル",h:"ですぴあのどうけあるべる",e:"Aluber the Jester of Despia",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「烙印」魔法・罠カード１枚を手札に加える。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の融合モンスターが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、相手フィールドの効果モンスター１体を対象として発動できる。\nこのカードを特殊召喚し、対象のモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"デスピアの大導劇神",h:"ですぴあのどらまとぅるぎあ",e:"Dramaturge of Despia",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：融合・S・X・リンクモンスターが特殊召喚された場合、フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n②：手札・フィールドのこのカードが融合召喚の素材になり、墓地へ送られた場合または除外された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"黒衣竜アルビオン",h:"くろごりゅうあるびおん",e:"Albion the Shrouded Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アルバスの落胤」として扱う。\n②：このカードが手札・墓地に存在する場合、「アルバスの落胤」１体または「烙印」魔法・罠カード１枚を手札・デッキから墓地へ送って発動できる。\nそのカードをどこから墓地へ送ったかによって以下の効果を適用する。\n●手札：このカードを特殊召喚する。\n●デッキ：このカードをデッキの一番下に戻す。\n手札から戻した場合、さらに自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"妖眼の相剣師",h:"あやめのそうけんし",e:"The Iris Swordsoul",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：効果が無効化されているモンスターがフィールドに存在する場合、自分・相手のメインフェイズに発動できる。\nこのカードを手札から特殊召喚する。\n②：相手がモンスターを特殊召喚した場合、そのモンスターをどこから特殊召喚したかによって以下の効果から１つを選択して発動できる。\n●手札：手札からモンスター１体を特殊召喚する。\n●デッキ：自分はデッキから２枚ドローする。\n●EXデッキ：EXデッキから特殊召喚されたそのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵銃士－クラヴィス",h:"まけんじゅうし－くらう゛ぃす",e:"Clavkiys, the Magikey Skyblaster",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:1900,tx:"人は誰しも可能性に満ち満ちる。\n行くも止まるも、施めるも解くも己次第。\n\nめくるめく世界に扉は数多。\nそれを解くは魔法の鍵。\n２つの鍵で１つの扉。\n２つの意思で１つの姿。\n扉を解けば世界が繋がり、巨大な力が顔を出す。",li:"",lk:[],ta:[],al:[]},
  {n:"しゃりの軍貫",h:"しゃりのぐんかん",e:"Gunkan Suship Shari",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"以前から気になっていた軍貫一筋の軍港に。\nここのしゃりは年間二千隻しか製造されておらず、独自開発した粘り気の少ない古米によって他港には無い重厚感が愉しめる事もあり、数多の通を唸らせてきた逸品。\n港内の雰囲気も格式高く胸が高鳴ります。\n\n念願の軍貫は、お酢比率、握り加減、造形が正に職人技で流石の一言。\n店主曰く「円みと芳醇な香りを備えたEDO－FRONT製の赤酢も近日入港予定」との事で調和が大変楽しみです。\n\n周辺海域が若干騒がしかったのが残念でした・・・。\n今後の期待も込めて今回は星４とさせていただきます！",li:"",lk:[],ta:[],al:[]},
  {n:"いくらの軍貫",h:"いくらのぐんかん",e:"Gunkan Suship Ikura",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「しゃりの軍貫」が存在する場合、このカードは手札から特殊召喚できる。\n②：自分メインフェイズに発動できる。\n自分のデッキの上からカードを３枚めくる。\nその中から「しゃりの軍貫」１体を選んで手札に加えるか特殊召喚できる。\n残りのカードはデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産メガラ・グローヴ",h:"おーぱーつめがら・ぐろーう゛",e:"Chronomaly Magella Globe",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nデッキから「先史遺産メガラ・グローヴ」以外の「先史遺産」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する場合、自分の墓地から「先史遺産メガラ・グローヴ」以外の「オーパーツ」カード１枚を除外して発動できる。\nこのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産アカンバロの土偶",h:"おーぱーつあかんばろのどぐう",e:"Chronomaly Acambaro Figures",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「先史遺産」モンスターが戦闘または相手の効果で破壊される場合、代わりに手札のこのカードを捨てる事ができる。\n②：このカードが墓地に存在する場合、１０００LPを払って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードはエンドフェイズに持ち主の手札に戻る。\nこの効果を発動するターン、自分は「先史遺産」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧菟－稻羽之淤岐素",h:"きこうと－いなばのおきのしろ",e:"Gizmek Inaba, the Hopping Hare of Hakuto",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:50,de:50,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n攻撃力と守備力の数値が同じ機械族モンスター１体を手札から守備表示で特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの攻撃力と守備力の数値が同じ機械族モンスター１体を対象として発動できる。\nこのターン、自分はそのモンスターでしか攻撃宣言できず、そのモンスターの攻撃力・守備力は、自分フィールドの攻撃力と守備力の数値が同じ機械族モンスターの元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧鳥－常世宇受賣長鳴",h:"きこうちょう－とこようずめのながなき",e:"Gizmek Naganaki, the Sunrise Signaler",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:950,de:950,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの攻撃力と守備力の数値が同じ機械族モンスター１体をリリースして発動できる。\n攻撃力と守備力の数値が同じで、リリースしたモンスターより低いレベルを持つ機械族モンスター１体をデッキから特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n裏側表示で除外されている自分のカードの中から、攻撃力と守備力の数値が同じ機械族モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧蛙－磐盾多邇具久",h:"きこうわ－いわたてのたにぐく",e:"Gizmek Taniguku, the Immobile Intellect",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1450,de:1450,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから攻撃力と守備力の数値が同じ機械族モンスター１体を選んでデッキの一番上に置く。\n②：墓地のこのカードを除外し、自分の墓地の攻撃力と守備力の数値が同じ機械族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧猪－伊服岐雹荒神",h:"きこうい－いぶきのひょうあらがみ",e:"Gizmek Arakami, the Hailbringer Hog",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1850,de:1850,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに攻撃力と守備力の数値が同じ機械族モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの攻撃力と守備力の数値が同じ機械族モンスター１体を対象として発動できる。\n攻撃力と守備力の数値が同じで、対象のモンスターより低いレベルを持つ機械族モンスター１体をデッキから墓地へ送る。\n対象のモンスターの攻撃力・守備力は墓地へ送ったモンスターのレベル×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタ・ヴェズル",h:"がすた・う゛ぇずる",e:"Gusto Vedir",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在し、フィールドの表側表示の「ガスタ」モンスターが、戦闘で破壊された場合、または自分の墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ガスタ」モンスター１体を墓地へ送る。\nその後、手札から「ガスタ」モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"驚楽園の助手 ＜Delia＞",h:"あめいずめんと・あしすたんと　＜でぃらいあ＞",e:"Amazement Assistant Delia",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札の「アトラクション」罠カード１枚を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：手札及び自分フィールドの表側表示のカードの中から、「アトラクション」罠カード１枚を墓地へ送って発動できる。\nデッキから「アトラクション」罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"エーリアン・バスター",h:"えーりあん・ばすたー",e:"Alien Stealthbuster",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターにAカウンターを２つ置く。\n②：墓地のこのカードを除外し、Aカウンターが置かれているカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"森の聖獣 カルピポニカ",h:"もりのせいじゅう　かるぴぽにか",e:"Carpiponica, Mystical Beast of the Forest",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札から墓地へ送られた場合、このカード以外の自分の墓地の獣族・獣戦士族・鳥獣族・昆虫族・植物族モンスターを合計２体対象として発動できる（同じ種族は１体まで）。\nそのモンスターをデッキに戻し、このカードを特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「森の聖獣 カルピポニカ」以外の「森の聖獣」モンスターまたは「森の聖霊」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷河のアクア・マドール",h:"ひょうがのあくあ・まどーる",e:"Glacier Aqua Madoor",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:3000,tx:"①：自分の通常モンスターが相手モンスターと戦闘を行うダメージステップ開始時に、手札のこのカードを相手に見せて発動できる。\n自分の手札を１枚選んで捨て、その自分のモンスターはその戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行うダメージステップ開始時に、手札の通常モンスター１体を相手に見せて発動できる。\n自分の手札を１枚選んで捨て、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"人攻智能ME－PSY－YA",h:"じんこうちのうめ－さい－や",e:"Antihuman Intelligence ME-PSY-YA",t:"monster",a:"光",l:8,ps:"",pe:"①：このカードがPゾーンに存在する限り、モンスターカード以外のお互いの墓地へ送られるカードは墓地へは行かず除外される。",tr:"サイバース族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:3000,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\nこのカード以外の手札のPモンスター１体または自分のPゾーンのカード１枚を選んで持ち主のEXデッキに表側表示で加え、このカードを特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、他のモンスターが召喚・特殊召喚された場合に発動する。\nそのモンスターはこのターンのエンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[]},
  {n:"守護天霊ロガエス",h:"しゅごてんれいろがえす",e:"Protecting Spirit Loagaeth",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの天使族モンスターの効果が発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：相手フィールドの表側表示のカード１枚と自分フィールドの攻撃表示モンスター１体を対象として発動できる。\nその相手のカードを除外し、その自分のモンスターを守備表示にする。\n③：フィールドのこのカードが戦闘・効果で破壊された場合、フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"修士号ディプロマン",h:"しゅうしごうでぃぷろまん",e:"Master's Diploman",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：このカードがアドバンス召喚に成功した場合、自分の墓地のモンスター１体と相手フィールドの表側表示モンスター１体を対象として発動できる。\nその墓地のモンスターを装備カード扱いとしてその相手モンスターに装備する。\nこの効果でモンスターを装備している限り、その攻撃力分、装備モンスターの攻撃力はダウンする。\n②：このカードがモンスターゾーンに存在する限り、自分の魔法＆罠ゾーンの装備カードを装備した相手モンスターの効果は発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"木花咲弥",h:"このはなさくや",e:"Konohanasakuya",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","スピリット","効果"],ma:"",tp:"",at:1300,de:1300,tx:"このカードは通常召喚できない。\n自分フィールドにスピリットモンスターが存在する場合のみ特殊召喚できる。\nこの方法による「木花咲弥」の特殊召喚は１ターンに１度しかできない。\n①：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。\n②：自分・相手のエンドフェイズに、墓地のこのカードを除外し、自分フィールドのスピリットモンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターはフィールドで発動する効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"死眼の伝霊－プシュコポンポス",h:"しがんのでんれい－ぷしゅこぽんぽす",e:"Doombearer Psychopompos",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\n相手の墓地の以下のカードの内、枚数が多い方の効果を適用する。\n\n●モンスターカード：このカードを除外し、相手は自身の墓地からモンスター１体を選んで除外する。\n\n●魔法・罠カード：このカードを墓地へ送り、相手は自身のフィールドのモンスター１体を選んで墓地へ送る。\n\n②：このカードが除外された場合、次のターンのエンドフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スロワースワロー",h:"すろわーすわろー",e:"Slower Swallow",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドに同じレベルのモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n②：このカードをリリースして発動できる。\n次の自分ドローフェイズの通常のドローは２枚になる。",li:"",lk:[],ta:[],al:[]},
  {n:"絆醒師セームベル",h:"ばんせいしせーむべる",e:"Saambell the Star Bonder",t:"monster",a:"風",l:3,ps:7,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにこのカードと同じレベルのPモンスターカードが存在する場合に発動できる。\nもう片方の自分のPゾーンのカードを破壊し、このカードを特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、自分フィールドに他のモンスターが特殊召喚された場合に発動できる。\nこのカードと同じレベルのモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エアロピΞ",h:"えあろぴくしー",e:"Aeropixthree",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"①：１ターンに１度、このカードと同じ縦列の相手の表側表示モンスター１体を対象として発動できる。\n自分フィールドのこのカードの位置を他の自分のメインモンスターゾーンに移動し、対象の相手モンスターの位置をこのカードの正面の相手のメインモンスターゾーンに移動する。\nその後、その相手モンスターに燃焼カウンターを１つ置く。\nこの効果は相手ターンでも発動できる。\n②：このカードがモンスターゾーンに存在する限り、燃焼カウンターが置かれているモンスターの攻撃力・守備力は、その数×２００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵銃－バトスバスター",h:"まけんじゅう－ばとすばすたー",e:"Magikey Mechmusket - Batosbuster",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["儀式","チューナー","効果"],ma:"",tp:"",at:2000,de:2200,tx:"「魔鍵－マフテア」により降臨。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\nデッキから「魔鍵」カード１枚を手札に加える。\n②：１ターンに１度、自分の墓地の通常モンスターまたは「魔鍵」モンスターのいずれかと同じ属性を持つ、相手モンスターがこのカードと戦闘を行う攻撃宣言時に発動できる。\n自分の手札を任意の数だけデッキの一番下に戻し、その相手モンスターの効果をターン終了時まで無効にする。\nその後、自分はデッキに戻した数だけドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵砲－ガレスヴェート",h:"まけんほう－がれすう゛ぇーと",e:"Magikey Mechmortar - Garesglasser",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["儀式","効果"],ma:"",tp:"",at:2000,de:2800,tx:"「魔鍵－マフテア」により降臨。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの攻撃力は、自分の墓地のモンスターの属性の種類×３００アップする。\n②：このカードの儀式召喚に使用したモンスターの属性が２種類以上だった場合、自分の墓地のいずれかのモンスターと同じ属性を持つモンスターの効果を相手が発動した時に発動できる。\nその発動を無効にし破壊する。\n③：儀式召喚したこのカードが墓地へ送られた場合に発動できる。\nデッキから「魔鍵」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"デスピアン・クエリティス",h:"ですぴあん・くえりてぃす",e:"Despian Quaeritis",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2500,tx:"「デスピア」モンスター＋光・闇属性モンスター\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\nレベル８以上の融合モンスターを除く、フィールドの全てのモンスターの攻撃力はターン終了時まで０になる。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\nデッキから「デスピア」モンスターまたは「アルバスの落胤」１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デスピアン・プロスケニオン",h:"ですぴあん・ぷろすけにおん",e:"Despian Proskenion",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:3200,tx:"「デスピア」モンスター＋光属性モンスター＋闇属性モンスター\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、相手の墓地の融合・S・X・リンクモンスター１体を対象として発動できる。\nそのモンスターを除外するか、自分フィールドに特殊召喚する。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nそのモンスターの元々の攻撃力と元々の守備力の内、高い方の数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵召獣－アンシャラボラス",h:"まけんしょうじゅう－あんしゃらぼらす",e:"Magikey Beast - Ansyalabolas",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["融合","チューナー","効果"],ma:"",tp:"",at:2200,de:2000,tx:"「魔鍵」モンスター＋トークン以外の通常モンスター\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合に発動できる。\n自分の墓地から「魔鍵－マフテア」１枚を選んで手札に加える。\n②：１ターンに１度、自分の墓地の通常モンスターまたは「魔鍵」モンスターのいずれかと同じ属性を持つ、相手の攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にし、その守備力を１０００ダウンする。\n③：このカードが戦闘で破壊したモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵召竜－アンドラビムス",h:"まけんしょうりゅう－あんどらびむす",e:"Magikey Dragon - Andrabime",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「魔鍵」効果モンスター＋トークン以外の通常モンスター\n①：このカードの融合召喚成功時に相手は効果を発動できない。\n②：１ターンに１度、自分の墓地の通常モンスターまたは「魔鍵」モンスター１体を対象として発動できる。\nそのモンスターと同じ属性を持つ相手フィールドのモンスターを全て破壊する。\n③：このカードの融合素材としたモンスターの属性が２種類だった場合、１ターンに１度、自分の墓地のいずれかのモンスターと同じ属性を持つ、相手モンスターが戦闘・効果で破壊された場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"無の畢竟 オールヴェイン",h:"むのひっきょう　おーるう゛ぇいん",e:"Allvain the Essence of Vanity",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["融合","チューナー"],ma:"",tp:"",at:0,de:2100,tx:"通常モンスター×２",li:"",lk:[],ta:[],al:[]},
  {n:"シューティング・セイヴァー・スター・ドラゴン",h:"しゅーてぃんぐ・せいう゛ぁー・すたー・どらごん",e:"Shooting Majestic Star Dragon",t:"monster",a:"風",l:11,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:3300,tx:"「救世竜 セイヴァー・ドラゴン」＋ドラゴン族Sモンスターを含むチューナー以外のモンスター１体以上\nこのカードはS召喚でのみEXデッキから特殊召喚できる。\n①：１ターンに１度、発動できる。\n相手フィールドの効果モンスター１体を選び、その効果を無効にする。\n②：このカードは通常の攻撃に加えて、自分の墓地の「スターダスト・ドラゴン」及びそのカード名が記されたSモンスターの数まで攻撃できる。\n③：１ターンに１度、相手が効果を発動した時に発動できる。\nこのカードをエンドフェイズまで除外し、その発動を無効にし除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイガスタ・ラプラムピリカ",h:"だいがすた・らぷらむぴりか",e:"Daigusto Laplampilica",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:2600,tx:"チューナー＋チューナー以外の「ガスタ」モンスター１体以上\n自分は「ダイガスタ・ラプラムピリカ」を１ターンに１度しか特殊召喚できない。\n①：このカードがS召喚に成功した場合に発動できる。\n「ガスタ」モンスターを手札及びデッキから１体ずつ効果を無効にして特殊召喚し、その２体のみを素材として「ガスタ」Sモンスター１体をS召喚する。\nこのターン、自分は風属性モンスターしか特殊召喚できない。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの他の「ガスタ」Sモンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"星風狼ウォルフライエ",h:"せいふうろううぉるふらいえ",e:"Stellar Wind Wolfrayet",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：「星風狼ウォルフライエ」は自分フィールドに１体しか表側表示で存在できない。\n②：攻撃力４０００未満のこのカードがモンスターゾーンに存在する限り、このカード以外のモンスターの効果が発動する度に、このカードの攻撃力は３００アップする。\n③：１ターンに１度、このカードの攻撃力が４０００以上の場合に発動できる。\nこのカードと相手フィールドのモンスターを全て持ち主のデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"甲纏竜ガイアーム",h:"こうてんりゅうがいあーむ",e:"Gaiarmor Dragonshell",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースし、このカード以外の自分の墓地のSモンスター３体を対象として発動できる。\nそのモンスターをEXデッキに戻す。\n②：EXデッキから特殊召喚された自分フィールドのモンスター１体を対象として発動できる。\n墓地のこのカードを装備カード扱いとしてそのモンスターに装備する。\n③：このカードを装備したモンスターが戦闘を行う攻撃宣言時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"弩級軍貫－いくら型一番艦",h:"どきゅうぐんかん－いくらがたいちばんかん",e:"Gunkan Suship Ikura-class Dreadnought",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:300,tx:"レベル４モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがX召喚に成功した場合に発動できる。\nそのX召喚の素材としたモンスターによって以下の効果を適用する。\n\n●「しゃりの軍貫」：自分はデッキから１枚ドローする。\n\n●「いくらの軍貫」：このカードは１度のバトルフェイズ中に２回攻撃できる。\n\n②：１ターンに１度、EXデッキから特殊召喚された自分の「軍貫」モンスターが相手に戦闘ダメージを与えた時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産ヴィマナ",h:"おーぱーつう゛ぃまな",e:"Chronomaly Vimana",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:2600,tx:"レベル５モンスター×２\n①：１ターンに１度、フィールドの表側表示モンスター１体と、自分の墓地の、「先史遺産」モンスターまたはXモンスター１体を対象として発動できる。\nそのフィールドのモンスターの攻撃力はターン終了時まで、対象の墓地のモンスターの攻撃力の半分アップする。\nその後、対象の墓地のモンスターをこのカードの下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、相手がモンスターの効果を発動した時、このカードのX素材を２つ取り除いて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"黒熔龍騎ヴォルニゲシュ",h:"こくようりゅうきう゛ぉるにげしゅ",e:"Voloferniges, the Darkest Dragon Doomrider",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル７モンスター×２\n①：１ターンに１度、このカードのX素材を２つ取り除き、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果でモンスターを破壊した場合、自分フィールドの表側表示モンスター１体を選び、その攻撃力を次のターンの終了時まで、破壊したモンスターの元々のレベル・ランクの数値×３００アップできる。\nこの効果を発動するターン、このカードは攻撃できない。\nこのカードがドラゴン族モンスターをX素材としている場合、この効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"告天子竜パイレン",h:"こうてんしりゅうぱいれん",e:"Dragonlark Pairen",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"幻竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:1300,tx:"レベル６モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：「告天子竜パイレン」以外の墓地から特殊召喚されたモンスターがフィールドに表側表示で存在する限り、このカードは戦闘・効果では破壊されない。\n②：自分・相手のスタンバイフェイズにこのカードのX素材を１つ取り除き、自分の墓地のレベル５以下のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"宇宙鋏ゼロオル",h:"そらばさみぜろおる",e:"Cosmic Slicer Zer'oll",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["リンク","効果"],ma:"",tp:"",at:2600,de:"-",tx:"爬虫類族モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nAカウンターを置く効果を持つカード１枚をデッキから手札に加える。\n②：自分・相手フィールドのAカウンターを２つ取り除いて発動できる。\n爬虫類族モンスター１体を召喚する。\n③：このカードがモンスターゾーンに存在する限り、Aカウンターが置かれている相手モンスターは、守備表示になり、効果を発動できない。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"グランドレミコード・ミューゼシア",h:"ぐらんどれみこーど・みゅーぜしあ",e:"GranSolfachord Musecia",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1900,de:"-",tx:"Pモンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札からPモンスター１体をEXデッキに表側表示で加え、そのPスケールが奇数なら偶数の、偶数なら奇数のPスケールを持つ表側表示のPモンスター１体を自分のEXデッキから手札に加える。\n②：自分が「ドレミコード」モンスターのP召喚に成功した時、その内の１体を対象として発動できる。\nそのモンスターのPスケールの数値と同じレベルの「ドレミコード」Pモンスター１体をデッキから手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"激撮ディスパラッチ",h:"げきさつでぃすぱらっち",e:"Dispatchparazzi",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:100,de:"-",tx:"効果モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の自分のモンスターが攻撃対象に選択された時に発動できる。\n攻撃対象をこのカードに移し替えてダメージ計算を行う。\n②：このカードが相手モンスターとの戦闘で破壊された時に発動できる。\nその相手モンスターを破壊し、その元々の攻撃力の半分だけ自分のLPを回復する。",li:"",lk:["左","右"],ta:[],al:[]},
  {n:"光来する奇跡",h:"こうらいするきせき",e:"Arrive in Light",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、手札・デッキからドラゴン族・レベル１モンスター１体を選んでデッキの一番上に置く。\n②：お互いにフィールドの「スターダスト・ドラゴン」及びそのカード名が記されたSモンスターをEXデッキに戻す事はできない。\n③：Sモンスターが特殊召喚された場合に発動できる。\n以下の効果から１つを選んで適用する。\nこのターン、自分の「光来する奇跡」の効果で同じ効果を適用できない。\n\n●自分はデッキから１枚ドローする。\n\n●手札からチューナー１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターダスト・イルミネイト",h:"すたーだすと・いるみねいと",e:"Stardust Illumination",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「スターダスト」モンスター１体を墓地へ送る。\n自分フィールドに「スターダスト・ドラゴン」またはそのカード名が記されたSモンスターが存在する場合、墓地へ送らず特殊召喚する事もできる。\n②：墓地のこのカードを除外し、自分フィールドの「スターダスト」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで１つ上げる、または下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"セイヴァー・アブソープション",h:"せいう゛ぁー・あぶそーぷしょん",e:"Majestic Absorption",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの、「スターダスト・ドラゴン」またはそのカード名が記されたSモンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●相手フィールドの表側表示モンスター１体を選び、そのモンスターを装備カード扱いとして対象の自分のモンスターに装備する。\n\n●このターン、対象のモンスターは直接攻撃できる。\n\n●このターン、対象のモンスターが戦闘で相手モンスターを破壊する度に、その破壊されたモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印劇城デスピア",h:"らくいんげきじょうですぴあ",e:"Despia, Theater of the Branded",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、レベル８以上の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：融合モンスター以外の自分フィールドの表側表示の天使族モンスターが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、自分の墓地のレベル８以上の融合モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印開幕",h:"らくいんかいまく",e:"Branded Opening",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札を１枚選んで捨てる。\nその後、デッキから「デスピア」モンスター１体を手札に加えるか守備表示で特殊召喚する。\nこのカードの発動後、ターン終了時まで自分は融合モンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドの融合モンスターが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印の絆",h:"らくいんのきずな",e:"Branded Bond",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地のモンスター及び除外されている自分のモンスターの中から、「アルバスの落胤」１体を選んで特殊召喚する。\n②：このカードが「アルバスの落胤」の効果を発動するために墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵－マフテア",h:"まけん－まふてあ",e:"Magikey Maftea",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：「魔鍵」融合モンスターカードによって決められた融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nまたは、レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「魔鍵」儀式モンスター１体を儀式召喚する。\n自分フィールドに通常モンスターが存在する場合、融合素材モンスターまたは儀式召喚のためにリリースするモンスターとして、デッキの通常モンスター１体を墓地へ送る事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵施解",h:"まけんせかい",e:"Magikey World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「魔鍵」モンスター１体を手札に加える事ができる。\n②：このカードがフィールドゾーンに存在する限り、トークン以外の自分フィールドの通常モンスターはそれぞれ１ターンに１度だけ戦闘・効果では破壊されない。\n③：自分メインフェイズに発動できる。\nデッキから「魔鍵－マフテア」１枚を手札に加える。\nその後、手札を１枚選んでデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"軍貫処 『海せん』",h:"ぐんかんどころ　『かいせん』",e:"Gunkan Sushipyard Seaside Supper Spot",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分が「軍貫」モンスターの召喚・特殊召喚に成功した場合に発動できる（ダメージステップでも発動可能）。\nデッキから「軍貫」カード１枚を選んでデッキの一番上に置く。\n②：１ターンに１度、EXデッキから特殊召喚された自分フィールドの「軍貫」モンスターが相手によって墓地へ送られた場合に発動する。\n相手はその守備力分のLPを払う。\nその後、自分は以下の効果を適用できる。\n●手札の「しゃりの軍貫」１体を特殊召喚し、「軍貫」Xモンスター１体をその上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産驚神殿－トリリトン",h:"おーぱーつぱれす－とりりとん",e:"Chronomaly Temple - Trilithon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが、存在しない場合または「先史遺産」モンスターのみの場合、５００LPを払って発動できる。\n手札から「先史遺産」モンスター１体を召喚する。\n②：自分の「先史遺産」Xモンスターまたは自分の「No.」XモンスターがX素材を取り除いて効果を発動する場合、取り除くX素材１つの代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"機巧伝－神使記紀図",h:"きこうでん－かみつかわしめのききえ",e:"Sacred Scrolls of the Gizmek Legend",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のデッキの上からカードを３枚めくる。\nその中から攻撃力と守備力の数値が同じ機械族モンスター１体を選んで手札に加える事ができる。\n残りのカードは裏側表示で除外する。\n②：攻撃力と守備力の数値が同じ機械族モンスターが召喚・特殊召喚される度にこのカードにカウンターを１つ置く。\n③：このカードのカウンターの数が１０以上の場合、攻撃力と守備力の数値が異なるフィールドのモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ガスタへの追風",h:"がすたへのおいかぜ",e:"Tailwind of Gusto",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ガスタ」モンスターにのみ装備可能。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：装備モンスターは相手の効果では破壊されない。\n②：装備モンスターのレベル・ランクによって以下の効果を発動できる。\n●４以下：装備モンスターとは種族が異なる「ガスタ」モンスター１体をデッキから特殊召喚する。\n●５以上：デッキからレベル１チューナー１体を特殊召喚する。\n③：墓地のこのカードを除外し、手札から風属性モンスター１体を捨てて発動できる。\nデッキから「ガスタ」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"Live☆Twin トラブルサン",h:"らいぶついん　とらぶるさん",e:"Live☆Twin Sunny's Snitch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「ライブツイン」モンスター１体を手札に加える事ができる。\n②：自分フィールドに「イビルツイン」モンスターが存在する限り、相手がモンスターを召喚・特殊召喚する度に、自分は２００LP回復し、相手に２００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"古代遺跡の静粛",h:"とらみっど・ろーでぃんぐ",e:"Triamid Loading",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在し、フィールドゾーンにフィールド魔法カードが表側表示で置かれた場合に発動できる。\nデッキから「古代遺跡の静粛」以外の「トラミッド」魔法・罠カード１枚を手札に加える。\n②：自分フィールドの表側表示の岩石族モンスターが戦闘または相手の効果で破壊された場合に発動できる。\n破壊されたモンスターとはカード名が異なる「トラミッド」モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"二量合成",h:"だいまー・しんせしす",e:"Dimer Synthesis",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\n\n●デッキから「化合電界」１枚を手札に加える。\n\n●デッキから「完全燃焼」１枚と「化合獣」モンスター１体を手札に加える。\n\n②：墓地のこのカードを除外し、もう１度召喚された状態のデュアルモンスターを含む自分フィールドの表側表示モンスター２体を対象として発動できる。\nターン終了時まで、対象のモンスター１体の攻撃力を０にし、その元々の攻撃力分もう１体のモンスターの攻撃力をアップする。",li:"",lk:[],ta:[],al:[]},
  {n:"高尚儀式術",h:"こうしょうぎしきじゅつ",e:"High Ritual Art",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"儀式モンスターの降臨に必要。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、手札の通常モンスターをリリースし、デッキから儀式モンスター１体を儀式召喚する。\nこの効果で特殊召喚したモンスターは相手エンドフェイズに持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"簡素融合",h:"れとるとふゅーじょん",e:"Ready Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１０００LPを払って発動できる。\n効果モンスターを除くレベル６以下の融合モンスター１体を融合召喚扱いとしてEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・オーバーテイク",h:"しんくろ・おーばーていく",e:"Synchro Overtake",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：EXデッキのSモンスター１体を相手に見せ、そのモンスターにカード名が記されているS素材モンスター１体を自分のデッキ・墓地から選び、手札に加えるか特殊召喚する。\nこのカードを発動するターン、自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンデュラム・トレジャー",h:"ぺんでゅらむ・とれじゃー",e:"Pendulum Treasure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからPモンスター１体を選び、EXデッキに表側表示で加える。",li:"",lk:[],ta:[],al:[]},
  {n:"信用取引",h:"まーじんとれーど",e:"Margin Trading",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手は手札を１枚捨ててこの効果を無効にできる。\n捨てなかった場合、お互いのプレイヤーは、それぞれ相手のデッキを確認し、その中からカード１枚を選ぶ。\nその後、お互いのプレイヤーは、それぞれ相手が選んだカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"セイヴァー・ミラージュ",h:"せいう゛ぁー・みらーじゅ",e:"Majestic Mirage",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの表側表示の、「スターダスト・ドラゴン」またはそのカード名が記されたSモンスターが、自分のカードの効果を発動するためにフィールドから離れた場合、または自分のカードの効果でフィールドから離れた場合に発動できる。\n以下の効果から１つを選んで適用する。\nこのターン、自分の「セイヴァー・ミラージュ」の効果で同じ効果を適用できない。\n\n●そのモンスター１体を選んで特殊召喚する。\n\n●相手のフィールド・墓地からモンスター１体を選んで除外する。\n\n●このターン、自分が受ける全てのダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・インタールーダー",h:"すぷりがんず・いんたーるーだー",e:"Springans Interluder",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がカードの効果を発動した時に発動できる。\n自分フィールドの「スプリガンズ」Xモンスター１体を選んで持ち主のEXデッキに戻す。\nその後、以下の効果から１つを選んで適用する。\n●その発動した効果を無効にする。\n●自分の墓地からレベル８モンスター１体を選んで特殊召喚する。\n②：自分フィールドの表側表示のXモンスターが効果でフィールドから離れた場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"繋がれし魔鍵",h:"つながれしまけん",e:"Magikey Duo",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の通常モンスターまたは「魔鍵」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、以下の効果から１つを選んで適用できる。\n●自分の手札・フィールドから、「魔鍵」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから守備表示で融合召喚する。\n●レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「魔鍵」儀式モンスター１体を守備表示で儀式召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵錠－解－",h:"まけんじょう－あんろっく－",e:"Magikey Unlocking",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：「魔鍵」儀式モンスターまたはEXデッキから特殊召喚された「魔鍵」モンスターが自分フィールドに存在し、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\nその後、属性を１つ宣言できる。\nその場合、ターン終了時まで相手フィールドの全ての表側表示モンスターは宣言した属性になる。",li:"",lk:[],ta:[],al:[]},
  {n:"きまぐれ軍貫握り",h:"きまぐれぐんかんにぎり",e:"Gunkan Suship Daily Special",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードは手札の「しゃりの軍貫」１体を相手に見せて発動する事もできる。\n①：デッキから「軍貫」モンスター３体を相手に見せ、相手はその中から１体選ぶ。\nそのモンスター１体を自分の手札に加え、残りをデッキに戻す。\n「しゃりの軍貫」を見せて発動した場合、手札に加えるモンスターは自分が選ぶ。\n②：墓地のこのカードを除外し、自分の墓地の「軍貫」モンスター３体を対象として発動できる。\nそのモンスターをデッキに加える。\nその後、自分はデッキから１枚ドローする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"先史遺産石紋",h:"おーぱーつ・えすぺらんさ・ぐりふ",e:"Chronomaly Esperanza Glyph",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに、自分のフィールド・墓地のXモンスターカード１枚を対象として発動できる。\nそのモンスターカードのランクの数値よりも１つ高いレベルを持つ「先史遺産」モンスター２体を、自分の手札・デッキ・墓地から選んで効果を無効にして特殊召喚し、その２体のみを素材として「先史遺産」Xモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"A・∀・VV",h:"あめいず・あとらくしょん・う゛ぁんきっしゅう゛ぁいきんぐ",e:"Amaze Attraction Viking Vortex",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「アメイズメント」モンスターまたは相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：装備モンスターのコントローラーによって以下の効果を発動できる。\n\n●自分：１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にし、バトルフェイズ終了時まで装備モンスターのコントロールを変更する。\n\n●相手：装備モンスターが効果を発動した時に発動できる。\n装備モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"モンスターアソート",h:"もんすたーあそーと",e:"Monster Assortment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：種族・属性・レベルが同じとなる、通常モンスター１体と効果モンスター１体をデッキから選んで相手に見せ、相手はその中からランダムに１体選ぶ。\nそのモンスター１体を自分の手札に加え、残りをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"獣王無塵",h:"じゅうおうむじん",e:"Beast King Unleashed",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は同一チェーン上では１度しか発動できない。\n①：１ターンに１度、自分のモンスターが自身と同じ縦列の相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその自分のモンスターと同じ縦列のカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ストールターン",h:"すとーるたーん",e:"Stall Turn",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分のデッキの上からカード３枚を裏側表示で除外して以下の効果を発動できる。\n\n●モンスターの召喚・反転召喚・特殊召喚を無効にする、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし、そのカードを持ち主のデッキに戻す。\n\n●魔法・罠カードの発動を無効にする、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし、そのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"謙虚な瓶",h:"けんきょなかめ",e:"Jar of Generosity",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分は手札を１枚選んでデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"プルーフ・プルフラス",h:"ぷるーふ・ぷるふらす",e:"Proof of Pruflas",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：特殊召喚されたモンスターが自分フィールドに存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nモンスター１体をアドバンス召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖座天嗣ストン",h:"せいざてんしすとん",e:"Thron the Disciplined Angel",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：元々の種族・属性が天使族・地属性となるモンスターが自分の手札・フィールドから墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンドラザクション",h:"ぺんどらざくしょん",e:"Pendransaction",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"レベル４モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\n自分のEXデッキの枚数が相手より多い場合、その差の数によって以下の効果をそれぞれ適用する。\n\n●１枚以上：このカードの攻撃力は相手ターン終了時まで１０００アップする。\n\n●５枚以上：このカードは相手ターン終了時まで効果の対象にならない。\n\n●１０枚以上：相手フィールドのカード１枚を選んで除外する。\n\n●１５枚以上：相手のLPを３０００にする。",li:"",lk:[],ta:[],al:[]},
  {n:"刺し違GUY",h:"さしちがい",e:"Expendable Dai",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの戦士族モンスター１体をリリースし、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"常世離レ",h:"とこよばなれ",e:"Terrors of the Underroot",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手の墓地のカードを５枚まで対象とし、その数だけ、除外されている相手のカードを対象として発動できる。\n対象の墓地のカードを除外し、対象の除外されているカードを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・ウェント",h:"うぉーくらい・うぇんと",e:"War Rock Wento",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の戦士族・地属性モンスターが相手モンスターと戦闘を行うダメージ計算時、８００LPを払って発動できる。\nその戦闘を行う自分のモンスターの攻撃力はターン終了時まで８００アップする。\n②：このカードが相手の効果でモンスターゾーンから墓地へ送られた場合に発動できる。\n手札・デッキからレベル５以上の「ウォークライ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・マムード",h:"うぉーくらい・まむーど",e:"War Rock Mammud",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが、存在しない場合または戦士族モンスターのみの場合、このカードはリリースなしで召喚できる。\n②：自分の戦士族・地属性モンスターが戦闘を行ったダメージ計算後に、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、自分フィールドの全ての「ウォークライ」モンスターの攻撃力は相手ターン終了時まで２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・メテオラゴン",h:"うぉーくらい・めておらごん",e:"War Rock Meteoragon",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2600,tx:"①：このカードは相手の効果では破壊されない。\n②：このカードが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nこのターン、その相手モンスター及びその相手モンスターと元々のカード名が同じモンスターの効果は無効化される。\n③：１ターンに１度、自分の戦士族・地属性モンスターが戦闘を行った自分・相手のバトルフェイズに発動できる。\n自分フィールドの全ての「ウォークライ」モンスターの攻撃力は相手ターン終了時まで２００アップする。\nこのターン、このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・ディグニティ",h:"うぉーくらい・でぃぐにてぃ",e:"War Rock Dignity",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「ウォークライ」モンスターが存在する場合、以下の効果から１つを選択して発動できる。\n\n●相手フィールドのモンスターが効果を発動した時に発動できる。\nその効果を無効にする。\n\n●自分・相手のバトルフェイズに、相手がモンスターの効果・魔法・罠カードを発動した時に発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・スピリッツ",h:"うぉーくらい・すぴりっつ",e:"War Rock Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のバトルフェイズに自分の墓地の「ウォークライ」モンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n●対象のモンスターを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、効果が無効化され、直接攻撃できない。\n●対象のモンスターを守備表示で特殊召喚する。\nこのターン、自分の「ウォークライ」モンスターはそれぞれ１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・ジェネレート",h:"うぉーくらい・じぇねれーと",e:"War Rock Generations",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、自分・相手のバトルフェイズに発動できる。\nデッキから「ウォークライ」モンスター１体を特殊召喚する。\nこのカードを相手ターンに発動した場合、このターンこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、相手はそのモンスターにしか攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ウォークライ・ビッグブロウ",h:"うぉーくらい・びっぐぶろう",e:"War Rock Big Blow",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに、自分フィールドの表側表示の「ウォークライ」モンスターが相手の効果でフィールドから離れた場合に発動できる。\n相手フィールドのカードを２枚まで選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔道騎竜カース・オブ・ドラゴン",h:"まどうきりゅうかーす・おぶ・どらごん",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1500,tx:"戦士族モンスター＋レベル５以上のドラゴン族モンスター\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合、「竜騎士ガイア」のカード名が記された自分の墓地の魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードがモンスターゾーンに存在する限り、自分がドラゴン族・レベル７モンスターを融合召喚する場合、自分の墓地のモンスターを除外して融合素材とする事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"パイル・アームド・ドラゴン",h:"ぱいる・あーむど・どらごん",e:"",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：風属性またはレベル７以上の、このカード以外のドラゴン族モンスター１体を手札から墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：手札・デッキから「パイル・アームド・ドラゴン」以外の「アームド・ドラゴン」モンスター１体を墓地へ送り、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、自分はモンスター１体でしか攻撃できず、対象のモンスターの攻撃力は墓地へ送ったモンスターのレベル×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アタッチメント・サイバーン",h:"あたっちめんと・さいばーん",e:"Attachment Cybern",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドのドラゴン族・機械族の「サイバー」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードを装備したモンスターの攻撃力は６００アップする。\n③：モンスターに装備されているこのカードが墓地へ送られた場合、このカード以外の自分の墓地のドラゴン族・機械族の「サイバー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバー・ダーク・キメラ",h:"さいばー・だーく・きめら",e:"Cyberdark Chimera",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から魔法・罠カード１枚を捨てて発動できる。\nデッキから「パワー・ボンド」１枚を手札に加える。\nこのターン、自分はドラゴン族・機械族の「サイバー」モンスターしか融合素材にできず、自分が融合召喚する場合に１度だけ、自分の墓地のモンスターを除外して融合素材とする事もできる。\n②：このカードが墓地へ送られた場合に発動できる。\n同名カードが自分の墓地に存在しない「サイバー・ダーク」モンスター１体をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"エターナル・サイバー",h:"えたーなる・さいばー",e:"Cyber Eternal",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の機械族の「サイバー」融合モンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻すか召喚条件を無視して特殊召喚する。\n②：自分フィールドの機械族の「サイバー」融合モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバーダーク・ワールド",h:"さいばーだーく・わーるど",e:"Cyberdark Realm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、同名カードが自分の墓地に存在しない「サイバー・ダーク」モンスター１体をデッキから手札に加える事ができる。\n②：自分メインフェイズに発動できる。\n「サイバー・ダーク」モンスター１体を召喚する。\n③：「サイバー・ダーク」モンスターの召喚・特殊召喚成功時に発動する自身の効果で、自分が自分の墓地からモンスターを装備する場合、代わりに相手の墓地から装備する事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバーダーク・インヴェイジョン",h:"さいばーだーく・いんう゛ぇいじょん",e:"Cyberdark Invasion",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n「サイバーダーク・インヴェイジョン」の以下の効果はそれぞれ１ターンに１度しか選択できない。\n\n●自分フィールドの「サイバー・ダーク」効果モンスター１体を対象として発動できる。\n自分・相手の墓地からドラゴン族・機械族モンスター１体を選び、攻撃力１０００アップの装備カード扱いとして対象のモンスターに装備する。\n\n●機械族モンスターに装備されている自分フィールドの装備カード１枚を墓地へ送って発動できる。\n相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"鎧皇竜－サイバー・ダーク・エンド・ドラゴン",h:"がいこうりゅう－さいばー・だーく・えんど・どらごん",e:"Cyberdark End Dragon",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:5000,de:3800,tx:"「鎧黒竜－サイバー・ダーク・ドラゴン」＋「サイバー・エンド・ドラゴン」\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●「サイバー・エンド・ドラゴン」を装備した自分のレベル１０以下の「サイバー・ダーク」融合モンスター１体をリリースした場合にEXデッキから特殊召喚できる。\n①：このカードは相手が発動した効果を受けない。\n②：１ターンに１度、発動できる。\n自分・相手の墓地のモンスター１体を選び、このカードに装備する。\n③：このカードは、このカードの装備カードの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"サイバネティック・ホライゾン",h:"さいばねてぃっく・ほらいぞん",e:"Cybernetic Horizon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「サイバーダーク」カードとしても扱う。\nこのカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は機械族モンスターしかEXデッキから特殊召喚できない。\n①：手札及びデッキからそれぞれ１体ずつ、ドラゴン族・機械族の「サイバー」モンスターを墓地へ送って発動できる（同じ属性は１体まで）。\nデッキからドラゴン族・機械族の「サイバー」モンスター１体を手札に加え、EXデッキから機械族の「サイバー」融合モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"華信龍－ノウルーズ・エリーズ",h:"かしんりゅう－のうるーず・えりーず",e:"",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、手札からレベル５以上のモンスター１体を墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが戦闘または相手の効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"SR吹持童子",h:"すぴーどろいどふきもどうじ",e:"Speedroid Fuki-Modoshi Piper",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのカード以外の自分フィールドの風属性モンスターの数だけ自分のデッキの上からカードをめくり、その中から１枚を選んで手札に加え、残りのカードを好きな順番でデッキの一番下に戻す。\n②：墓地のこのカードを除外し、自分フィールドのレベル３以上の風属性モンスター１体を対象として発動できる。\nそのモンスターのレベルを２つ下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"SRマジックハウンド",h:"すぴーどろいどまじっくはうんど",e:"Speedroid Ultra Hound",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「スピードロイド」カード１枚を墓地へ送る。\n②：墓地のこのカードを除外し、自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、そのモンスターとはカード名が異なり、レベルが同じ「スピードロイド」Sモンスター１体を効果を無効にしてEXデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"HSRコルク－１０",h:"はいすぴーどろいどこるくじゅう",e:"Hi-Speedroid Cork Shooter",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:500,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分は風属性モンスターしか特殊召喚できない。\n①：このカードがS召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●デッキから「スピードロイド」魔法・罠カード１枚を手札に加える。\n\n●このカードが「スピードロイド」モンスターのみを素材としてS召喚していた場合、そのS召喚に使用したS素材モンスター一組が全て自分の墓地に揃っていれば、その一組を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタルクリアウィング・シンクロ・ドラゴン",h:"くりすたるくりあうぃんぐ・しんくろ・どらごん",e:"Crystal Clear Wing Synchro Dragon",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"Sモンスターのチューナー＋チューナー以外の「クリアウィング」モンスター１体\n①：１ターンに１度、相手がモンスターの効果を発動した時に発動できる。\nこのカードはターン終了時まで、攻撃力がその相手モンスターの元々の攻撃力分アップし、相手が発動したモンスターの効果を受けない。\n②：１ターンに１度、魔法・罠カードの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n③：S召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\nデッキから風属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"HSR／CWライダー",h:"はいすぴーどろいど／くりあうぃんぐらいだー",e:"Hi-Speedroid Clear Wing Rider",t:"monster",a:"風",l:11,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:2000,tx:"風属性チューナー＋チューナー以外の風属性Sモンスター１体\n①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\n出た目の数まで自分の墓地の風属性モンスターを選んでデッキに戻す。\nその後、戻した数まで相手フィールドのカードを選んで破壊できる。\nこのカードの攻撃力はターン終了時まで、この効果で破壊した数×５００アップする。\n②：相手メインフェイズに、S召喚したこのカードをリリースして発動できる。\nEXデッキからレベル７の風属性Sモンスターを２体まで特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"SRルーレット",h:"すぴーどろいどるーれっと",e:"Speedroid Wheel",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚捨てて発動できる。\nサイコロを１回振る。\nレベルの合計が出た目と同じになるように手札・デッキから「スピードロイド」モンスターを２体まで効果を無効にして特殊召喚する。\n特殊召喚できなかった場合、自分は出た目の数×５００LPを失う。",li:"",lk:[],ta:[],al:[]},
  {n:"SRデュプリゲート",h:"すぴーどろいどでゅぷりげーと",e:"Speedroid Dupligate",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果はデュエル中に１度しか使用できない。\n①：自分の墓地から風属性モンスター１体を除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが墓地に存在する場合、自分メインフェイズに自分フィールドの「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、このカードは通常モンスター（機械族・チューナー・風・星１・攻／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。",li:"",lk:[],ta:[],al:[]},
  {n:"時花の賢者－フルール・ド・サージュ",h:"ときばなのけんじゃ－ふるーる・ど・さーじゅ",e:"Sauge de Fleur",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2900,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体とフィールドのカード１枚を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のカードを破壊する。\n②：このカードがフィールドから墓地へ送られた場合、このカード以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、自分のデッキ・墓地から植物族・レベル１モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"花騎士団の白馬",h:"はなきしだんのはくば",e:"White Steed of the Floral Knights",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにレベル２以下のモンスターが存在する場合、このカードは手札から守備表示で特殊召喚できる。\n②：相手モンスターの攻撃宣言時、墓地のこのカードを除外し、自分フィールドのカード１枚を対象として発動できる。\nその攻撃を無効にし、対象のカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ネクロ・シンクロン",h:"ねくろ・しんくろん",e:"Necro Synchron",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:400,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「フルール・シンクロン」として扱う。\n②：このカード以外の自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ上げる。\n③：このカードが風属性SモンスターのS素材として墓地へ送られた場合に発動できる。\nデッキから植物族・レベル１モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フルール・ド・バロネス",h:"ふるーる・ど・ばろねす",e:"Baronne de Fleur",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、魔法・罠・モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。\n③：お互いのスタンバイフェイズに、自分の墓地のレベル９以下のモンスター１体を対象として発動できる。\nこのカードを持ち主のEXデッキに戻し、そのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"フルール・ド・フルーレ",h:"ふるーる・ど・ふるーれ",e:"Fleuret de Fleur",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分の墓地のレベル２以下のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを効果を無効にして特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：装備モンスターの攻撃力は７００アップする。\n③：このカードが魔法＆罠ゾーンから墓地へ送られた場合に発動できる。\n自分フィールドのSモンスター１体を選び、このカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"シンクロ・ディレンマ",h:"しんくろ・でぃれんま",e:"Synchro Dilemma",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\n\n●手札及び自分フィールドの表側表示モンスターの中から、「シンクロン」モンスター１体を墓地へ送って発動できる。\n手札からモンスター１体を特殊召喚する。\n\n●このカード以外の自分フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、そのカードとは元々のカード名が異なる「シンクロン」モンスター１体を自分の手札・墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"革命の御旗",h:"かくめいのみはた",e:"Pennant of Revolution",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドのカードが戦闘・効果で破壊された場合に発動できる。\n自分のデッキ・墓地から「自由解放」１枚を選んで手札に加える。\n②：Sモンスターを含むモンスター同士が戦闘を行うダメージステップ開始時に発動できる。\nその戦闘を行う相手モンスターを破壊する。\n③：魔法＆罠ゾーンのこのカードが効果で破壊される場合、代わりに自分フィールドのモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－ベリル・カナリー",h:"りりかる・るすきにあ－べりる・かなりー",e:"Lyrilusc - Beryl Canary",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分の墓地の「LL」モンスター１体を対象として発動できる。\nこのカードと対象のモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n②：フィールドのこのカードを素材としてX召喚した風属性モンスターは以下の効果を得る。\n●このカードの攻撃力は２００アップし、コントロールを変更できない。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－セレスト・ワグテイル",h:"りりかる・るすきにあ－せれすと・わぐている",e:"Lyrilusc - Celestine Wagtail",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:200,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「LL」魔法・罠カード１枚を手札に加える。\n②：このカードが墓地に存在する場合、自分フィールドの「LL」Xモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－アンサンブルー・ロビン",h:"りりかる・るすきにあ－あんさんぶるー・ろびん",e:"Lyrilusc - Ensemblue Robin",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１モンスター×２体以上\n①：このカードの攻撃力は、このカードのX素材の数×５００アップする。\n②：相手がモンスターの特殊召喚に成功した場合、このカードのX素材を１つ取り除き、その特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n③：このカードが相手によって墓地へ送られた場合、このカード以外の自分の墓地の「LL」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－プロム・スラッシュ",h:"りりかる・るすきにあ－ぷろむ・すらっしゅ",e:"Lyrilusc - Promenade Thrush",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル１モンスター×２体以上\n①：このカードの攻撃力は、このカードのX素材の数×５００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n③：このカード以外の自分のモンスターが戦闘を行うダメージステップ開始時に、このカードのX素材を任意の数だけ取り除いて発動できる。\nその自分のモンスターの攻撃力はターン終了時まで、取り除いた数×３００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－バード・コール",h:"りりかる・るすきにあ－ばーど・こーる",e:"Lyrilusc - Bird Call",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「LL」モンスター１体を選び、手札に加えるか墓地へ送る。\nその後、そのモンスターとはカード名が異なる「LL」モンスター１体を手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－バード・サンクチュアリ",h:"りりかる・るすきにあ－ばーど・さんくちゅあり",e:"Lyrilusc - Bird Sanctuary",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの鳥獣族Xモンスター２体を対象として発動できる。\n対象のモンスターの内１体を、もう１体のモンスターの下に重ねてX素材とする（X素材を持っているモンスターを重ねる場合はそのX素材も全て重ねる）。\n②：X素材を３つ以上持っているXモンスターが自分フィールドに存在する場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－比翼の麗鳥",h:"りりかる・るすきにあ－ひよくのれいちょう",e:"Lyrilusc - Phantom Feathers",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「LL」モンスター１体を対象として発動できる。\n相手フィールドの全てのモンスターの攻撃力は対象のモンスターの攻撃力と同じになり、相手フィールドの全てのモンスターのレベル・ランクは１になる。\n②：相手モンスターが自分の「LL」モンスターに攻撃宣言した時、墓地のこのカードを除外して発動できる。\nその自分のモンスターの攻撃力はターン終了時まで、その相手モンスターの攻撃力と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリバー",h:"くりばー",e:"Kuribah",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名はルール上「クリボー」カードとしても扱う。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードまたは自分の「クリボー」モンスターが戦闘で破壊された時に発動できる。\nデッキから「クリバー」以外の攻撃力３００／守備力２００のモンスター１体を特殊召喚する。\n②：フィールドのこのカードと自分の手札・フィールドの「クリビー」「クリブー」「クリベー」「クリボー」を１体ずつリリースして発動できる。\n自分の手札・デッキ・墓地から「クリバビロン」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"クリビー",h:"くりびー",e:"Kuribee",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名はルール上「クリボー」カードとしても扱う。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードまたは自分の「クリボー」モンスターが戦闘で破壊された時に発動できる。\nデッキから「クリボー」のカード名が記された魔法・罠カード１枚を手札に加える。\n②：１ターンに１度、自分フィールドに他の「クリボー」モンスターが存在する場合、相手モンスターの攻撃宣言時に発動できる。\nこのカード以外の自分フィールドの全てのモンスターの攻撃力をターン終了時まで０にし、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"クリブー",h:"くりぶー",e:"Kuriboo",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名はルール上「クリボー」カードとしても扱う。\n①：相手モンスターの攻撃宣言時にこのカードを手札から捨てて発動できる。\nデッキから「クリブー」以外の「クリボー」モンスター１体を手札に加える。\n②：１ターンに１度、手札から罠カード１枚を捨て、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１５００ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリベー",h:"くりべー",e:"Kuribeh",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名はルール上「クリボー」カードとしても扱う。\n①：このカードを手札から捨て、自分フィールドの「クリボー」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は１５００アップする。\nこの効果は相手ターンでも発動できる。\n②：フィールドのこのカードと自分の手札・フィールドの「クリバー」「クリビー」「クリブー」「クリボー」を１体ずつリリースして発動できる。\n自分のデッキ・墓地から「クリバンデット」１体を選んで手札に加える。\nその後、手札から悪魔族モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリバビロン",h:"くりばびろん",e:"Kuribabylon",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地のモンスターの数が相手の墓地のモンスターより多い場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力・守備力は、自分のフィールド・墓地の「クリボー」モンスターの数×３００アップする。\n③：自分のメインフェイズ及びバトルフェイズに発動できる。\nこのカードを持ち主の手札に戻し、自分の手札・墓地から「クリバー」「クリビー」「クリブー」「クリベー」「クリボー」を１体ずつ選んで攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ティンクル・ファイブスター",h:"てぃんくる・ふぁいぶすたー",e:"Five Star Twilight",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターがレベル５モンスター１体のみの場合、そのモンスターをリリースして発動できる。\n自分の手札・デッキ・墓地から「クリバー」「クリビー」「クリブー」「クリベー」「クリボー」を１体ずつ選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ヨーウィー",h:"よーうぃー",e:"Yowie",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の効果はデュエル中に１度しか使用できず、この効果を発動するターン、自分は１回しかモンスターを特殊召喚できない。\n①：このカード１体のみが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\n次の相手ドローフェイズをスキップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン・ソード",h:"ぺんぎん・そーど",e:"Penguin Sword",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ペンギン」モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力は８００アップする。\n②：装備モンスターが相手に戦闘ダメージを与えた時に発動できる。\n相手フィールドのカード１枚を選んで持ち主の手札に戻す。\n③：相手フィールドの表側表示のカードが「ペンギン」カードの効果でフィールドから離れ、手札に戻った場合または除外された場合に発動する。\n次のターンの終了時まで、そのカード及びそのカードと元々のカード名が同じカードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"D－フォース",h:"でぃー－ふぉーす",e:"D - Force",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、自分のデッキ・墓地から「D-HERO Bloo-D」１体を選んで手札に加える事ができる。\n②：自分フィールドに「D-HERO Bloo-D」が存在する限り、以下の効果を適用する。\n\n●自分はドローフェイズにドローできない。\n\n●自分フィールドのカードは相手の効果の対象にならない。\n\n●自分フィールドの「D-HERO Bloo-D」は、攻撃力がお互いの墓地のモンスターの数×１００アップし、相手の効果では破壊されず、１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドクターD",h:"どくたーでぃー",e:"Doctor D",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地から「D-HERO」モンスター１体を除外して発動できる。\n自分の墓地から「D-HERO」モンスター１体を選び、手札に加えるか特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「D-HERO」モンスター２体を対象として発動できる。\n対象のモンスター１体の攻撃力は、もう１体のモンスターの攻撃力と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"ドラゴンロイド",h:"どらごんろいど",e:"Dragonroid",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2900,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、以下の効果から１つを選択して発動できる。\n\n●デッキから風属性以外の「ロイド」モンスター１体を手札に加える。\n\n●このターン、融合モンスターを融合召喚する効果を含む効果を自分が発動した場合、その発動は無効化されず、その融合召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。\n\n②：１ターンに１度、このカードが墓地に存在する場合に発動できる。\n墓地のこのカードはターン終了時までドラゴン族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"輪廻独断",h:"りんねどくだん",e:"Rebirth Judgment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、種族を１つ宣言して発動できる。\nこのターン、お互いの墓地のモンスターは宣言した種族になる。",li:"",lk:[],ta:[],al:[]},
  {n:"サモン・ストーム",h:"さもん・すとーむ",e:"Summon Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：８００LPを払って発動できる。\n手札からレベル６以下の風属性モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n手札からレベル４以下の風属性モンスター１体を特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"翼の恩返し",h:"つばさのおんがえし",e:"Wing Requital",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターが、鳥獣族モンスターのみで、元々のカード名が異なるモンスター２体以上の場合、６００LPを払って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士の盾持ち",h:"せいきしのたてもち",e:"Noble Knight's Shield-Bearer",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地から光属性モンスター１体を除外して発動できる。\n自分はデッキから１枚ドローする。\n②：手札・フィールドのこのカードを除外して発動できる。\nデッキからレベル６以下の獣族・風属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"花騎士団の駿馬",h:"はなきしだんのしゅんめ",e:"Horse of the Floral Knights",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから光属性の「聖騎士」モンスター１体を手札に加える。\n②：１ターンに１度、自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、フィールドのこのカードを含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖騎士の槍持ち",h:"せいきしのやりもち",e:"Noble Knight's Spearholder",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分フィールドのレベル８以下のモンスター１体を対象として発動できる。\nそのモンスターはエンドフェイズまで、カード名を「フルール・シンクロン」として扱い、チューナーとして扱う。\n②：このカードをリリースして発動できる。\nデッキから装備魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ケンタウルミナ",h:"けんたうるみな",e:"Centaur Mina",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1600,tx:"戦士族・光属性モンスター＋獣族モンスター\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札・墓地からレベル２以下のモンスター１体を選んで特殊召喚する。\n②：１ターンに１度、自分ターンに相手が罠カードを発動した時に発動できる。\nその発動を無効にし、そのカードをそのままセットする。\n③：このカードが戦士族・風属性SモンスターのS素材として墓地へ送られた場合に発動できる。\nフィールドの表側表示モンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エコール・ド・ゾーン",h:"えこーる・ど・ぞーん",e:"Ecole de Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：ターンプレイヤーがモンスター１体のみの召喚・反転召喚・特殊召喚に成功した場合に発動する。\nそのモンスターを破壊し、そのコントローラーはその自身のフィールドに「マスクトークン」（魔法使い族・闇・星１・攻／守？）１体を特殊召喚する。\nこのトークンは、攻撃力・守備力がこの効果で破壊したモンスターのそれぞれの数値と同じになり、直接攻撃できない。\n②：表側表示のこのカードがフィールドから離れた場合にフィールドの「マスクトークン」は全て破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"魂縛門",h:"こんばくもん",e:"Soul Binding Gate",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分フィールドにセットされた魔法・罠カードが効果で破壊されたターン、自分フィールドにモンスターが存在しない場合にこのカードを発動できる。\n①：自分・相手のメインフェイズに、自分の墓地に「Z－ONE」が存在し、自分のLPの数値より低い攻撃力を持つモンスター１体のみが召喚・反転召喚・特殊召喚された場合に発動する。\nそのモンスターを破壊し、自分は８００ダメージを受ける。\nその後、相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"ピリ・レイスの地図",h:"ぴり・れいすのちず",e:"Piri Reis Map",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分メインフェイズ１開始時に発動できる。\nデッキから攻撃力０のモンスター１体を手札に加え、自分のLPを半分にする。\nこのカードの発動後、次のターンの終了時まで、自分はこの効果で手札に加えたモンスターまたはその同名カードの召喚に成功しない限り、そのモンスター及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アイス・ミラー",h:"あいす・みらー",e:"Ice Mirror",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル３以下の水属性モンスター１体を対象として発動できる。\nその同名モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ZW－天風精霊翼",h:"ぜある・うぇぽん－しるふぃーど・うぃんぐ",e:"ZW - Sylphid Wing",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"①：「ZW－天風精霊翼」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力８００アップの装備カード扱いとしてそのモンスターに装備する。\n③：１ターンに１度、相手が発動した効果でモンスターが特殊召喚された場合に発動できる。\n装備モンスターの攻撃力を１６００アップする。\n④：装備モンスターがX素材を取り除いて効果を発動する場合、取り除くX素材１つの代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ZS－双頭龍賢者",h:"ぜある・さーばす－うろぼろす・せーじ",e:"ZS - Ouroboros Sage",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の墓地から光属性以外の「No.」モンスター１体を選んで効果を無効にして特殊召喚し、自分フィールドからこのカードと「希望皇ホープ」モンスター１体をそれぞれ攻撃力１７００アップの装備カード扱いとしてその特殊召喚したモンスターに装備する。\nこのターン、自分は１度しか攻撃宣言できない。\n②：このカードの効果でこのカードを装備したモンスターが相手モンスターに攻撃宣言した時に発動できる。\nその攻撃モンスターは、攻撃力が倍になりエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"獣装合体 ライオ・ホープレイ",h:"じゅうそうがったい　らいお・ほーぷれい",e:"Ultimate Leo Utopia Ray",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル５モンスター×３\nこのカード名はルール上「CNo.39 希望皇ホープレイ」として扱う。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキ・EXデッキから「ZW」モンスター１体を選び、その効果による装備カード扱いとしてこのカードに装備する。\nこの効果の発動と効果は無効化されない。\n②：お互いのターンに１度、このカードが「ZW」モンスターカードを装備している場合、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果を無効にし、その攻撃力を半分にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ゼアル・カタパルト",h:"ぜある・かたぱると",e:"Zexal Catapult",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から「ZW」モンスターまたは「ZS」モンスター１体を特殊召喚する。\n自分フィールドに「希望皇ホープ」モンスターが存在する場合、さらに自分フィールドの全てのモンスターのレベルを４または５にできる。\n②：「ZW」モンスターまたは「ZS」モンスター１体と、このカードを自分の墓地から除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"サイレンス・シーネットル",h:"さいれんす・しーねっとる",e:"Silent Sea Nettle",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに水属性モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果を発動するターン、自分は水属性モンスターしか特殊召喚できない。\n②：墓地のこのカードを除外し、自分の墓地の水属性モンスターを３体まで対象として発動できる。\nそのモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"No.4 猛毒刺胞ステルス・クラーゲン",h:"なんばーず４　もうどくしほうすてるす・くらーげん",e:"Number 4: Stealth Kragen",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1500,tx:"水属性レベル４モンスター×２\n①：フィールドの表側表示モンスターは水属性になる。\n②：１ターンに１度、自分・相手のメインフェイズに発動できる。\n相手フィールドの水属性モンスター１体を選んで破壊し、その攻撃力の半分のダメージを相手に与える。\n③：X召喚したこのカードが破壊された場合に発動できる。\nこのカードが持っていたX素材の数まで、EXデッキから「ステルス・クラーゲン・エフィラ」を特殊召喚する。\nさらにその特殊召喚したモンスターそれぞれに、自分の墓地から水属性モンスターを１体まで選んでそのX素材にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ステルス・クラーゲン・エフィラ",h:"すてるす・くらーげん・えふぃら",e:"Stealth Kragen Spawn",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1500,tx:"水属性レベル４モンスター×２\n①：１ターンに１度、自分・相手のメインフェイズに発動できる。\n相手フィールドの水属性モンスター１体を選んで破壊する。\n②：「No.」Xモンスターの効果で特殊召喚したこのカードが破壊された場合に発動できる。\nこのカードが持っていたX素材の数まで、自分の墓地からこのカード以外の「ステルス・クラーゲン」モンスターを選んで特殊召喚する。\nさらにその特殊召喚したモンスターそれぞれに、自分の墓地から水属性モンスターを１体まで選んでそのX素材にできる。",li:"",lk:[],ta:[],al:[]},
  {n:"ヌメロン・ストーム",h:"ぬめろん・すとーむ",e:"Numeron Storm",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ヌメロニアス」モンスターが存在する場合に発動できる。\n相手フィールドの魔法・罠カードを全て破壊し、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"人形の家",h:"にんぎょうのいえ",e:"Doll House",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の攻撃力か守備力が０の通常モンスター１体を対象として発動できる。\nその同名モンスター１体をデッキから闇属性・レベル６モンスターとして特殊召喚する。\n自分フィールドに「プリンセス・コロン」が存在する場合、この効果の対象を２体にできる。\n②：相手モンスターの攻撃宣言時に発動できる。\n自分フィールドの「デメット爺さん」１体を選び、自分フィールドの「プリンセス・コロン」のX素材とする。\nその後、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"スターヴ・ヴェネミー・ドラゴン",h:"すたーう゛・う゛ぇねみー・どらごん",e:"Starving Venemy Dragon",t:"monster",a:"闇",l:7,ps:1,pe:"①：フィールドのカードが墓地へ送られる度に、１枚につきヴェネミーカウンター１つをこのカードに置く。\n②：ドラゴン族・闇属性モンスター以外のフィールドのモンスターの攻撃力はフィールドのヴェネミーカウンターの数×２００ダウンする。\n③：１ターンに１度、戦闘で発生する自分への戦闘ダメージを０にできる。",tr:"ドラゴン族",mo:["融合","ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"闇属性モンスター＋Pモンスター\nこのカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはそのモンスターと同名カードとして扱い、同じ効果を得る。\nその後、対象のモンスターの攻撃力・守備力は５００ダウンし、効果は無効化され、相手に５００ダメージを与える。\n②：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"SRスクラッチ",h:"すぴーどろいどすくらっち",e:"Speedroid Scratch",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から「スピードロイド」カード１枚を墓地へ送って発動できる。\nデッキから「スピードロイド」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"LL－バード・ストライク",h:"りりかる・るすきにあ－ばーど・すとらいく",e:"Lyrilusc - Bird Strike",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「LL」モンスターが存在する場合に発動できる。\n相手フィールドの全ての表側表示モンスターの効果はターン終了時まで無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"トイ・パレード",h:"とい・ぱれーど",e:"Toy Parade",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「デストーイ」カードとしても扱う。\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：EXデッキから特殊召喚された自分の闇属性モンスター１体を対象として発動できる。\nこのターン、自分はそのモンスターでしか攻撃宣言できず、そのモンスターは戦闘でモンスターを破壊し墓地へ送る度に続けて攻撃できる。\n②：自分フィールドに天使族モンスターが存在する場合、墓地のこのカードを除外して発動できる。\n自分の墓地からレベル４以下の闇属性モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"光波複葉機",h:"さいふぁー・ばいぷれーん",e:"Cipher Biplane",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「サイファー」モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分フィールドの「サイファー」モンスター２体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで８にする。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキから「サイファー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"光波干渉",h:"さいふぁー・いんたーふぃあー",e:"Cipher Interference",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：フィールドに同名モンスターが存在する、自分の「サイファー」モンスターが戦闘を行うダメージ計算時に発動できる。\nその戦闘を行う自分の「サイファー」モンスターの攻撃力はバトルフェイズ終了時まで倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"二重露光",h:"だぶる・えくすぽーじゃー",e:"Double Exposure",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのレベル６以下の同名モンスター２体を対象として発動できる。\nそのモンスターのレベルは倍になる。\n②：自分・相手のバトルフェイズ開始時に、自分フィールドの「サイファー」モンスター１体を対象として発動できる。\nそのモンスター以外のフィールドの表側表示モンスター１体を選び、エンドフェイズまでそのカード名を対象の「サイファー」モンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"キ－Ai－",h:"き－あい－",e:"FA.I.ghting Spirit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「＠イグニスター」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドの攻撃力２３００以上の「＠イグニスター」モンスターが効果で破壊される場合、代わりに墓地のこのカードを除外できる。\n③：このカードが除外された場合に発動できる。\nこのターン、自分の攻撃力２３００以上の「＠イグニスター」モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"Ai－SHOW",h:"あい－しょう",e:"A.I.'s Show",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：EXモンスターゾーンに自分のリンク３以上の「＠イグニスター」モンスターが存在する場合、相手モンスターの攻撃宣言時に発動できる。\n攻撃力の合計が、その攻撃モンスターの攻撃力以下になるように、リンクモンスター以外の攻撃力２３００のサイバース族モンスターをEXデッキから任意の数だけ選んで特殊召喚する。\nその後、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"扇風機塊プロペライオン",h:"せんぷうきかいぷろぺらいおん",e:"Appliancer Propelion",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["リンク","効果"],ma:"",tp:"",at:1200,de:"-",tx:"「機塊」モンスター１体\nこのカードはリンク召喚されたターンにはリンク素材にできない。\n①：このカードは直接攻撃できる。\n②：１ターンに１度、このカードが相互リンク状態の場合、自分と相手のモンスター同士が戦闘を行うダメージ計算時に発動できる。\nその相手モンスターの攻撃力はそのダメージ計算時のみ０になる。\n③：１ターンに１度、相互リンク状態ではないこのカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその相手モンスターの攻撃力はそのダメージ計算時のみ０になる。",li:"",lk:["上"],ta:[],al:[]},
  {n:"機塊コンバート",h:"きかいこんばーと",e:"Appliancer Conversion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のメインモンスターゾーンの「機塊」リンクモンスターを全て除外する。\nその後、この効果で除外したモンスターの数まで、除外されている自分の「機塊」リンクモンスターを選んで特殊召喚できる。\n②：このカードが墓地に存在する場合、自分メインフェイズに自分の墓地から他の「機塊」魔法・罠カード１枚を除外して発動できる。\nこのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"深海のコレペティ",h:"しんかいのこれぺてぃ",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1800,de:1500,tx:"「深海のディーヴァ」＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分・相手ターンに１度、手札からレベル４以下の水属性モンスター１体を捨てて発動できる。\nこのカードの攻撃力はターン終了時まで８００アップする。\n②：S召喚したこのカードが墓地へ送られた場合、「深海のコレペティ」以外の自分の墓地のレベル５以上の水属性モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこのターン、自分は水属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"希望皇アストラル・ホープ",h:"きぼうおうあすとらる・ほーぷ",e:"Astraltopia",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドにXモンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカード以外の自分の手札・フィールドのカード１枚を墓地へ送って発動できる。\nデッキから以下のカードの内いずれか１枚を手札に加える。\n\n●「エクシーズ」魔法・罠カード\n●「オノマト」魔法・罠カード\n●「ゼアル」魔法・罠カード\n●「ナンバーズ」魔法・罠カード",li:"",lk:[],ta:[],al:[]},
  {n:"ゼアル・フィールド",h:"ぜある・ふぃーるど",e:"Zexal Field",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのXモンスターを対象とする、自分のカードの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n②：自分フィールドにXモンスターが特殊召喚された場合、そのモンスター１体を対象として発動できる。\n自分のEXデッキ・墓地からXモンスター１体を選び、対象のモンスターの下に重ねてX素材とする。\n③：自分ドローフェイズのドロー前に発動できる。\nデッキから「シャイニング・ドロー」１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"運命の契約",h:"うんめいのけいやく",e:"The Deal of Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのカードが戦闘・効果で破壊される度に、このカードに皇の鍵カウンターを１つ置く（最大１つまで）。\n②：相手がEXデッキからモンスターを特殊召喚した場合、このカードの皇の鍵カウンターを１つ取り除いて発動できる。\n手札・デッキ及び自分フィールドの表側表示のカードの中から、「運命の扉」１枚を墓地へ送り、EXデッキから光属性の「ホープ」Xモンスター１体をX召喚扱いとして特殊召喚し、このカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"ナンバーズ・プロテクト",h:"なんばーず・ぷろてくと",e:"Numbers Protection",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに「No.」Xモンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n②：このカードが墓地に存在し、自分フィールドの「No.」Xモンスターが戦闘・効果で破壊された場合に発動できる。\nこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"No.99 希望皇ホープドラグナー",h:"なんばーず９９　きぼうおうほーぷどらぐなー",e:"Number 99: Utopia Dragonar",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル１２モンスター×３体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手ターンに、このカードのX素材を２つ取り除いて発動できる。\n「No.1」～「No.100」のいずれかの「No.」モンスター１体をX召喚扱いとしてEXデッキから特殊召喚する。\nこの効果を発動するターン、自分はXモンスターしかEXデッキから特殊召喚できず、このカード以外の自分のモンスターは直接攻撃できない。\n②：相手モンスターの攻撃宣言時に発動できる。\nその相手モンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ZS－希望賢者",h:"ぜある・さーばす－ほーぷ・せーじ",e:"ZS - Utopic Sage",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル４モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を２つ取り除いて発動できる。\nデッキから「ZW」モンスターまたは「ZS」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できず、「No.」モンスターでしか攻撃できない。\n②：「ZS－希望賢者」を除く自分フィールドの元々の属性が光属性の「ホープ」Xモンスターが戦闘・効果で破壊される場合、代わりにフィールド・墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"HRUM－ユートピア・フォース",h:"はいぱーらんくあっぷまじっく－ゆーとぴあ・ふぉーす",e:"Hyper Rank-Up-Magic Utopiforce",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのランク９以下の「希望皇ホープ」Xモンスター１体を対象として発動できる。\nランク１０以上の「ホープ」Xモンスター１体を、対象の自分のXモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\n②：このカードが墓地に存在し、ランク１０以上の「ホープ」Xモンスターの効果でXモンスターが特殊召喚された場合、その特殊召喚されたモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"アルカナ トライアンフジョーカー",h:"あるかな　とらいあんふじょーかー",e:"Arcana Triumph Joker",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:"?",de:2500,tx:"①：このカードが手札・墓地に存在する場合、手札及び自分フィールドの表側表示モンスターの中から、「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」を１体ずつ墓地へ送って発動できる。\nこのカードを特殊召喚する。\n②：このカードの攻撃力は、お互いの手札の数×５００アップする。\n③：手札を１枚捨てて発動できる。\n捨てたカードと同じ種類（モンスター・魔法・罠）の、相手フィールドの表側表示のカードを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジョーカーズ・ナイト",h:"じょーかーず・ないと",e:"Joker's Knight",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」の内１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードはエンドフェイズまで、墓地へ送ったモンスターと同名カードとして扱う。\n②：自分・相手のエンドフェイズに、このカード以外の自分の墓地の戦士族・光属性モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"インペリアル・バウアー",h:"いんぺりある・ばうあー",e:"Imperial Bower",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドに他のモンスターが存在しない場合、このカードをリリースして発動できる。\nデッキから「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」の内２体を選ぶ（同名カードは１枚まで）。\nそのモンスターをそれぞれ手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ジョーカーズ・ストレート",h:"じょーかーず・すとれーと",e:"Joker's Straight",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札を１枚選んで捨て、デッキから「クィーンズ・ナイト」１体を特殊召喚し、デッキから「キングス・ナイト」「ジャックス・ナイト」の内１体を手札に加える。\nその後、モンスター１体を召喚できる。\nこのターン、自分は戦士族・光属性モンスターしかEXデッキから特殊召喚できない。\n②：自分・相手のエンドフェイズに、自分の墓地の戦士族・光属性モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"絵札融合",h:"えふだゆうごう",e:"Face Card Fusion",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、戦士族・光属性の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n自分フィールドに「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」のいずれかが存在する場合、自分のデッキのモンスターも１体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"神速召喚",h:"しんそくしょうかん",e:"Thunderspeed Summon",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズ及びバトルフェイズに発動できる。\nレベル１０モンスター１体を召喚する。\n自分フィールドに「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」が全て存在する場合、代わりに以下の効果を適用できる。\n●デッキから闇属性以外の攻撃力？のレベル１０モンスター１体を手札に加える。\nその後、レベル１０モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ジョーカーズ・ワイルド",h:"じょーかーず・わいるど",e:"Joker's Wild",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズ及びバトルフェイズに、デッキから「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」のカード名が全て記された魔法カード１枚を墓地へ送って発動できる。\nこの効果は、その魔法カード発動時の効果と同じになる。\n②：自分・相手のエンドフェイズに、自分の墓地の戦士族・光属性モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"絵札の絆",h:"えふだのきずな",e:"Court of Cards",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」以外のモンスターが存在しない場合に発動できる。\n自分の手札・墓地から、「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」の内１体を選んで特殊召喚する。\n②：自分の手札・墓地から、「クィーンズ・ナイト」「ジャックス・ナイト」「キングス・ナイト」をそれぞれ１体まで除外して発動できる。\n除外した数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"マグネット・インダクション",h:"まぐねっと・いんだくしょん",e:"Magnet Induction",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに元々のレベルが４以下の「マグネット・ウォリアー」モンスターが存在する場合に発動できる。\n同名カードが自分フィールドに存在しないレベル４以下の「マグネット・ウォリアー」モンスター１体をデッキから特殊召喚する。\nこのカードの発動後、ターン終了時まで自分フィールドの「マグネット・ウォリアー」モンスター及び「磁石の戦士」モンスターは、戦闘及び相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"X・Y・Zハイパーキャノン",h:"えっくす・わい・ぜっとはいぱーきゃのん",e:"XYZ Hyper Cannon",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：「XYZ－ドラゴン・キャノン」またはそのカード名が融合素材として記されている融合モンスターが自分フィールドに存在する場合、この効果の発動ターンによって以下の効果を発動できる。\n\n●自分ターン：除外されている自分のユニオンモンスター１体を対象として発動できる。\nそのモンスターをデッキの一番下に戻し、自分はデッキから１枚ドローする。\n\n●相手ターン：手札を任意の枚数捨て、その数だけ相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレット・キャリバー",h:"う゛ぁれっと・きゃりばー",e:"Rokket Caliber",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:100,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは闇属性リンクモンスターのリンク先となる自分フィールドに手札から特殊召喚できる。\n②：このカードをリリースして発動できる。\n手札から「ヴァレット・キャリバー」以外のドラゴン族・機械族の闇属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デュアルウィール・ドラゴン",h:"でゅあるうぃーる・どらごん",e:"Double Disruptor Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースし、自分の墓地のカード名が異なる「ヴァレット」モンスター２体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n自分のデッキ・墓地から「ラピッド・トリガー」または「ヘヴィ・トリガー」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣師－莫邪",h:"そうけんし－ばくや",e:"Swordsoul of Mo Ye",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札の「相剣」カード１枚または幻竜族モンスター１体を相手に見せて発動できる。\n自分フィールドに「相剣トークン」（幻竜族・チューナー・水・星４・攻／守０）１体を特殊召喚する。\nこの効果で特殊召喚したトークンが存在する限り、自分はSモンスターしかEXデッキから特殊召喚できない。\n②：このカードがS素材として墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣師－泰阿",h:"そうけんし－たいあ",e:"Swordsoul of Taia",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「相剣」カード１枚または幻竜族モンスター１体を除外して発動できる。\n自分フィールドに「相剣トークン」（幻竜族・チューナー・水・星４・攻／守０）１体を特殊召喚する。\nこの効果で特殊召喚したトークンが存在する限り、自分はSモンスターしかEXデッキから特殊召喚できない。\n②：このカードがS素材として墓地へ送られた場合に発動できる。\nデッキから「相剣」カード１枚または幻竜族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣軍師－龍淵",h:"そうけんぐんし－りゅうえん",e:"Swordsoul Strategist Longyuan",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1200,de:2300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の手札の「相剣」カード１枚または幻竜族モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分フィールドに「相剣トークン」（幻竜族・チューナー・水・星４・攻／守０）１体を特殊召喚できる。\nこの効果で特殊召喚したトークンが存在する限り、自分はSモンスターしかEXデッキから特殊召喚できない。\n②：このカードがS素材として墓地へ送られた場合に発動できる。\n相手に１２００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣瑞獣－純鈞",h:"そうけんずいじゅう－じゅんきん",e:"Swordsoul Auspice Chunjun",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：自分の幻竜族モンスターが、EXデッキから特殊召喚された相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその相手モンスターとこのカードを破壊する。\n③：このカードがS素材として墓地へ送られた場合、自分または相手の、フィールド・墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"白の聖女エクレシア",h:"しろのせいじょえくれしあ",e:"Incredible Ecclesia, the Virtuous",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードは手札から特殊召喚できる。\n②：自分・相手のメインフェイズに、このカードをリリースして発動できる。\n手札・デッキから「相剣」モンスターまたは「アルバスの落胤」１体を特殊召喚する。\n③：このターンに融合モンスターが自分の墓地へ送られている場合、エンドフェイズに発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水のアクティ",h:"ひすいのあくてぃ",e:"Icejade Acti",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札から水属性モンスター１体を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の水属性モンスターが戦闘・効果で破壊された場合、このカードを除外して発動できる。\n自分の手札・墓地から「氷水のアクティ」以外の「氷水」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水のティノーラ",h:"ひすいのてぃのーら",e:"Icejade Tinola",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚墓地へ送り、自分の墓地の水属性モンスター１体を対象として発動できる。\nフィールドのこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の水属性モンスターが戦闘・効果で破壊された場合、このカードを除外して発動できる。\n自分の手札・墓地から「氷水のティノーラ」以外の「氷水」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水のトレモラ",h:"ひすいのとれもら",e:"Icejade Tremora",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から墓地へ送って発動できる。\n手札から水属性モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の水属性モンスターが戦闘・効果で破壊された場合、このカードを除外して発動できる。\n自分の手札・墓地から「氷水のトレモラ」以外の「氷水」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"デスピアの凶劇",h:"ですぴあのあどりびとぅむ",e:"Ad Libitum of Despia",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nフィールドの全てのモンスターの攻撃力は相手ターン終了時まで、自身のレベル×１００アップする。\n②：手札・フィールドのこのカードが融合召喚の素材になり、墓地へ送られた場合または除外された場合、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「デスピアの凶劇」以外の「デスピア」モンスターまたはレベル８以上の融合モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃず×ろびーな",h:"ふわんだりぃず&times;ろびーな",e:"Floowandereeze & Robina",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードが召喚に成功した場合に発動できる。\nデッキからレベル４以下の鳥獣族モンスター１体を手札に加える。\nその後、鳥獣族モンスター１体を召喚できる。\n②：表側表示のこのカードはフィールドから離れた場合に除外される。\n③：このカードが除外されている状態で、自分フィールドに鳥獣族モンスターが召喚された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃず×いぐるん",h:"ふわんだりぃず&times;いぐるん",e:"Floowandereeze & Eglen",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードが召喚に成功した場合に発動できる。\nデッキからレベル７以上の鳥獣族モンスター１体を手札に加える。\nその後、鳥獣族モンスター１体を召喚できる。\n②：表側表示のこのカードはフィールドから離れた場合に除外される。\n③：このカードが除外されている状態で、自分フィールドに鳥獣族モンスターが召喚された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃず×すとりー",h:"ふわんだりぃず&times;すとりー",e:"Floowandereeze & Stri",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:700,de:1100,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードが召喚に成功した場合、自分または相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nその後、鳥獣族モンスター１体を召喚できる。\n②：表側表示のこのカードはフィールドから離れた場合に除外される。\n③：このカードが除外されている状態で、自分フィールドに鳥獣族モンスターが召喚された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃず×とっかん",h:"ふわんだりぃず&times;とっかん",e:"Floowandereeze & Toccan",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:500,de:1300,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードが召喚に成功した場合、除外されている自分の「ふわんだりぃず」カード１枚を対象として発動できる。\nそのカードを手札に加える。\nその後、鳥獣族モンスター１体を召喚できる。\n②：表側表示のこのカードはフィールドから離れた場合に除外される。\n③：このカードが除外されている状態で、自分フィールドに鳥獣族モンスターが召喚された場合に発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃず×すのーる",h:"ふわんだりぃず&times;すのーる",e:"Floowandereeze & Snowl",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2900,de:800,tx:"①：アドバンス召喚したこのカードが存在する場合、１ターンに１度、発動できる。\nこのターン自分は通常召喚を３回まで行う事ができる。\n②：アドバンス召喚したこのカードがモンスターゾーンに存在する限り、自分のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：相手ターンに１度、手札を１枚除外して発動できる。\n相手フィールドの特殊召喚されたモンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃず×えんぺん",h:"ふわんだりぃず&times;えんぺん",e:"Floowandereeze & Empen",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合に発動できる。\nデッキから「ふわんだりぃず」魔法・罠カード１枚を手札に加える。\nその後、モンスター１体を召喚できる。\n②：アドバンス召喚したこのカードがモンスターゾーンに存在する限り、相手フィールドの特殊召喚された攻撃表示モンスターは効果を発動できない。\n③：このカードが相手モンスターと戦闘を行うダメージ計算時に１度、手札を１枚除外して発動できる。\nその相手モンスターの攻撃力・守備力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO ディナイアルガイ",h:"ですてにーひーろー　でぃないあるがい",e:"Destiny HERO - Denier",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:600,tx:"このカード名の、①の効果は１ターンに１度しか使用できず、②の効果はデュエル中に１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地のモンスター及び除外されている自分のモンスターの中から、「D-HERO」モンスター１体を選んでデッキの一番上に置く。\n②：自分のフィールド・墓地に「D-HERO ディナイアルガイ」以外の「D-HERO」モンスターが存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ニャミニ",h:"れぷてぃれす・にゃみに",e:"Reptilianne Nyami",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地に爬虫類族モンスターが存在する場合、自分・相手のメインフェイズに、このカードを手札から墓地へ送り、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にする。\n②：相手フィールドに攻撃力０のモンスターが存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・コアトル",h:"れぷてぃれす・こあとる",e:"Reptilianne Coatl",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに爬虫類族・闇属性モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n相手フィールドに攻撃力０のモンスターが存在する場合、さらにその数まで手札から「レプティレス」モンスターを特殊召喚できる。\n②：自分フィールドのこのカードを爬虫類族SモンスターのS素材とする場合、このカードをチューナー以外のモンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"大魔鍵－マフテアル",h:"だいまけん－まふてある",e:"Maginificent Magikey Mafteal",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できず、このカードをS・X召喚の素材とする場合、「魔鍵」モンスターのS・X召喚にしか使用できない。\n①：自分フィールドに「魔鍵」モンスターが存在する場合、手札のこのカードを相手に見せて発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、「魔鍵」モンスター１体を召喚できる。\n②：このカードが召喚に成功した時、自分の墓地のレベル４以下の、通常モンスターまたは「魔鍵」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"うにの軍貫",h:"うにのぐんかん",e:"Gunkan Suship Uni",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の手札の「軍貫」カード１枚を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\nその後、見せたカードによって以下の効果を適用する。\n●「しゃりの軍貫」：見せたモンスターを特殊召喚できる。\n●それ以外：見せたカードをデッキの一番下に戻す。\n②：自分フィールドの「軍貫」モンスター１体を対象として発動できる。\nそのモンスターのレベルを４または５にする。\nその後、デッキから「しゃりの軍貫」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"しらうおの軍貫",h:"しらうおのぐんかん",e:"Gunkan Suship Shirauo",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:200,de:250,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「しゃりの軍貫」または「しゃりの軍貫」をX素材としているXモンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n手札から「しらうおの軍貫」以外の「軍貫」モンスター１体を特殊召喚する。\nその後、自分のデッキ・墓地から「しゃりの軍貫」を任意の数だけ選び、好きな順番でデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン勇士",h:"ぺんぎんゆうし",e:"Penguin Squire",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードをS素材とする場合、水属性モンスターのS召喚にしか使用できない。\n①：自分フィールドにモンスターがセットされた場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、このカードのレベルを１つまたは２つ下げる事ができる。\n②：自分フィールドの裏側守備表示モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にする。\nこの効果で「ペンギン」モンスター以外のモンスターを表側守備表示にした場合、その効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン忍者",h:"ぺんぎんにんじゃ",e:"Penguin Ninja",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがリバースした場合、相手フィールドの魔法・罠カードを２枚まで対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：自分フィールドの「ペンギン」モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ペンギン僧侶",h:"ぺんぎんそうりょ",e:"Penguin Cleric",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:600,de:1700,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：「ペンギン」モンスターが相手によって自分のモンスターゾーンから自分の墓地へ送られた場合、その内の１体を対象として発動できる（ダメージステップでも発動可能）。\nこのカードを手札から捨て、対象のモンスターを裏側守備表示で特殊召喚する。\n②：１ターンに１度、自分フィールドの「ペンギン」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで６００アップし、自分は６００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリーナイツ・オルビタエル",h:"ほーりーないつ・おるびたえる",e:"Starry Knight Orbitael",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:400,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの光属性モンスター１体を対象として発動できる。\nそのモンスターをリリースし、デッキから「ホーリーナイツ」魔法・罠カード１枚を選んで自分フィールドにセットする。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在し、自分フィールドの表側表示のドラゴン族・光属性・レベル７モンスターが手札に戻った場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"マシンナーズ・ルインフォース",h:"ましんなーず・るいんふぉーす",e:"Machina Ruinforce",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4600,de:4100,tx:"このカードは通常召喚できない。\nレベルの合計が１２以上になるように、自分の墓地の機械族モンスターを除外した場合のみ墓地から特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：バトルフェイズに相手が効果を発動した時、LPを半分払って発動できる。\nその発動を無効にし、相手のLPを半分にする。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nレベルの合計が１２以下になるように、除外されている自分の「マシンナーズ」モンスターを３体まで選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"擬態する人喰い虫",h:"ぎたいするひとくいむし",e:"Mimicking Man-Eater Bug",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:450,de:600,tx:"①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊し、その元々の攻撃力分このカードの攻撃力をアップする。\nその後、このカードの種族を破壊したモンスターの元々の種族と同じにできる。\n②：フィールドのこのカードは、戦闘では破壊されず、このカードと同じ種族のモンスターの効果でも破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"天獄の王",h:"てんごくのおう",e:"Lord of the Heavenly Prison",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分メインフェイズに発動できる。\n手札のこのカードを相手ターン終了時まで公開する。\nこの効果で公開し続けている間、フィールドにセットされたカードは効果では破壊されない。\n②：セットされた魔法・罠カードが発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\n手札で公開されている状態でこの効果を発動した場合、さらにデッキから魔法・罠カード１枚を自分フィールドにセットできる。\nそのカードは次のターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"陽竜果フォンリー",h:"そるどらぽむふぉんりー",e:"Fengli the Soldrapom",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターの効果でデッキから墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\n自分フィールドに他の植物族モンスターが存在する場合、さらにフィールドのモンスター１体を選んでその攻撃力・守備力を半分にできる。\n②：１ターンに１度、フィールドのこのカードが戦闘・効果で破壊される場合、代わりにデッキから植物族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"重起士道－ゴルドナイト",h:"でゅあらいず・ろーど－ごるどないと",e:"Geminize Lord Golknight",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが召喚・特殊召喚に成功した場合に発動できる。\n効果テキストに「デュアルモンスター」と記された魔法・罠カード１枚をデッキから手札に加える。\nこのカード名のこの効果は１ターンに１度しか使用できない。\n\n●このカードは機械族になり、攻撃力が５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"不屈の獣僕",h:"ふくつのじゅうぼく",e:"Undaunted Bumpkin Beast",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:900,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手が３体以上のモンスターを特殊召喚したターンのメインフェイズに発動できる。\nこのカードを手札・墓地から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドに他のモンスターが存在しない場合、攻撃表示のこのカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"ニャータリング",h:"にゃーたりんぐ",e:"Meowseclick",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：フィールド魔法カードが発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\nお互いのフィールドゾーンに表側表示でカードが存在する場合、さらにこのターン、このカードは戦闘・効果では破壊されない。\n②：このカードがモンスターゾーンに存在する限り、自分ターンの間、相手のフィールドゾーンの表側表示のカードの効果は無効化され、相手ターンの間、自分のフィールドゾーンの表側表示のカードの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"迷犬メリー",h:"めいけんめりー",e:"Outstanding Dog Mary",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、以下の効果から１つを選択して発動できる。\n\n●このカードをデッキの一番下に戻す。\n\n●デッキから「迷犬マロン」１体を手札に加え、このカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレルロード・R・ドラゴン",h:"う゛ぁれるろーど・らいおっと・どらごん",e:"Borreload Riot Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「ヘヴィ・トリガー」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。\nその後、このカードまたは自分フィールドの「ヴァレット」モンスター１体を選んで破壊する。\n②：このカードが墓地に存在する場合、自分の墓地の、「ヴァレル」モンスターまたは「ヴァレット」モンスター１体を対象として発動できる。\n自分の手札・フィールドのカード１枚を選んで破壊し、対象のモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"トランソニックバード",h:"とらんそにっくばーど",e:"Transonic Bird",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["儀式","効果"],ma:"",tp:"",at:0,de:2400,tx:"「音速を追う者」により降臨。\n①：１ターンに１度、発動できる。\n手札の儀式魔法カード１枚を相手に見せ、そのカードにカード名が記された儀式モンスター１体をデッキから手札に加え、見せたカードをデッキに戻す。\n②：相手ターンに１度、デッキから儀式魔法カード１枚を墓地へ送って発動できる。\nこの効果は、その儀式魔法カード発動時の儀式召喚する効果と同じになる。\n③：儀式召喚したこのカードがリリースされた場合、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"D-HERO デストロイフェニックスガイ",h:"ですてにーひーろー　ですとろいふぇにっくすがい",e:"Destiny HERO - Destroyer Phoenix Enforcer",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル６以上の「HERO」モンスター＋「D-HERO」モンスター\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの攻撃力は、自分の墓地の「HERO」カードの数×２００ダウンする。\n②：自分・相手ターンに発動できる。\n自分フィールドのカード１枚とフィールドのカード１枚を選んで破壊する。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\n次のターンのスタンバイフェイズに、自分の墓地から「D-HERO」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"赫灼竜マスカレイド",h:"かくしゃくりゅうますかれいど",e:"Masquerade the Blazing Dragon",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「デスピア」モンスター＋光・闇属性モンスター\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：融合召喚したこのカードがモンスターゾーンに存在する限り、相手は６００LPを払わなければ、カードの効果を発動できない。\n②：このカードが墓地に存在し、相手フィールドに儀式・融合・S・X・リンクモンスターのいずれかが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"天極輝艦－熊斗竜巧",h:"てんきょくきかん－べあとろん",e:"Ultimate Flagship Ursatron",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:700,tx:"このカード名はルール上「ベアルクティ」カード、「ドライトロン」カードとしても扱う。\nこのカードは「天斗輝巧極」の効果でのみ特殊召喚できる。\n①：１ターンに１度、自分フィールドに他の効果モンスターが特殊召喚された場合に発動できる。\nデッキから「ベアルクティ」モンスターまたは「ドライトロン」モンスター１体を手札に加える。\n②：１ターンに１度、除外されている自分の、「ベアルクティ」モンスターまたは「ドライトロン」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣大師－赤霄",h:"そうけんたいし－せきしょう",e:"Swordsoul Grandmaster - Chixiao",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1000,tx:"チューナー＋チューナー以外の幻竜族モンスター１体以上\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nデッキから「相剣」カード１枚を手札に加えるか除外する。\n②：自分の手札・墓地から「相剣」カード１枚または幻竜族モンスター１体を除外し、このカード以外のフィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣大公－承影",h:"そうけんたいこう－しょうえい",e:"Swordsoul Supreme Sovereign - Chengying",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：除外されているカードの数×１００だけ、このカードの攻撃力・守備力はアップし、相手フィールドのモンスターの攻撃力・守備力はダウンする。\n②：このカードが効果で破壊される場合、代わりに自分の墓地のカード１枚を除外できる。\n③：カードが除外された場合に発動できる。\n相手のフィールド及び墓地のカードをそれぞれ１枚ずつ選んで除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・メルジーヌ",h:"れぷてぃれす・めるじーぬ",e:"Reptilianne Melusine",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2800,tx:"爬虫類族チューナー＋チューナー以外のモンスター１体以上\nこのカードの②の効果は同一チェーン上では１度しか発動できない。\n①：爬虫類族モンスターのみを素材としてS召喚したこのカードは戦闘・効果では破壊されない。\n②：相手がモンスターの効果を発動した時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にする。\n③：S召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\nデッキから爬虫類族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵変鬼－トランスフルミネ",h:"まけんへんき－とらんすふるみね",e:"Magikey Fiend - Transfurlmine",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2800,tx:"「魔鍵」チューナー＋チューナー以外の通常モンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n②：このカードのS素材としたモンスターの属性が２種類以上だった場合に発動できる。\nデッキから「魔鍵」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n③：自分の墓地のいずれかのモンスターと同じ属性を持つモンスターを相手が召喚・特殊召喚した場合に発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"絶火の魔神ゾロア",h:"まぎすとす・だいもーんぞろあ",e:"Zoroa, the Magistus Conflagrant Calamity",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:1500,tx:"魔法使い族チューナー＋チューナー以外のモンスター１体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動できる。\nEXデッキから「マギストス」モンスター１体を装備カード扱いとしてこのカードに装備する。\n②：自分の魔法＆罠ゾーンの「マギストス」モンスターカードと同じ種類（融合・S・X・リンク）のモンスターの効果を相手は発動できない。\n③：自分フィールドの「マギストス」カード１枚を対象として発動できる。\nそのカードを破壊し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ルイ・キューピット",h:"るい・きゅーぴっと",e:"Cupid Pitch",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:0,de:600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合に発動する。\nそのS素材としたチューナーのレベル分、このカードのレベルを上げる、または下げる。\n②：このカードの攻撃力は自身のレベル×４００アップする。\n③：S召喚したこのカードがS素材として墓地へ送られた場合に発動する。\nそのS召喚したモンスターのレベル×１００ダメージを相手に与え、デッキからレベル８以下で守備力６００のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵憑霊－ウェパルトゥ",h:"まけんひょうれい－うぇぱるとぅ",e:"Magikey Spirit - Vepartu",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル４モンスター×２\n①：このカードがX召喚に成功した場合、このカードのX素材を１つ取り除いて発動できる。\n自分のデッキ・墓地からレベル４以上の通常モンスター１体を選んで手札に加える。\n②：このカードが通常モンスターをX素材としている場合、以下の効果を得る。\n●自分の墓地の通常モンスターまたは「魔鍵」モンスターのいずれかと同じ属性を持つ相手モンスターとこのカードが戦闘を行うダメージステップ開始時に、このカードのX素材を１つ取り除いて発動できる。\n相手はそのモンスターを墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"超弩級軍貫－うに型二番艦",h:"ちょうどきゅうぐんかん－うにがたにばんかん",e:"Gunkan Suship Uni-class Super-Dreadnought",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2900,de:500,tx:"レベル５モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがX召喚に成功した場合に発動できる。\nそのX召喚の素材としたモンスターによって以下の効果を適用する。\n\n●「しゃりの軍貫」：自分はデッキから１枚ドローする。\n\n●「うにの軍貫」：このカードは直接攻撃できる。\n\n②：自分メインフェイズ及び相手バトルフェイズに１度、EXデッキから特殊召喚された自分の「軍貫」モンスターの数まで相手フィールドの表側表示のカードを対象として発動できる。\nそのカードの効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"空母軍貫－しらうお型特務艦",h:"くうぼぐんかん－しらうおがたとくむかん",e:"Gunkan Suship Shirauo-class Carrier",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:250,tx:"レベル４モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがX召喚に成功した場合に発動できる。\nそのX召喚の素材としたモンスターによって以下の効果を適用する。\n\n●「しゃりの軍貫」：自分はデッキから１枚ドローする。\n\n●「しらうおの軍貫」：デッキから「軍貫」魔法・罠カード１枚を手札に加える。\n\n②：フィールドゾーンに表側表示でカードが存在する限り、EXデッキから特殊召喚された自分の「軍貫」モンスターは、相手の効果では破壊されず、攻撃力がその元々の守備力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァレルコード・ドラゴン",h:"う゛ぁれるこーど・どらごん",e:"Borrelcode Dragon",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["リンク","効果"],ma:"",tp:"",at:2500,de:"-",tx:"効果モンスター２体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：リンクモンスターのリンク先のこのカードは効果では破壊されない。\n②：モンスター３体を素材としてリンク召喚したこのカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nフィールドのモンスターを全て破壊する。\n③：墓地のこのカードを除外して発動できる。\nフィールドの攻撃力３０００以上の闇属性モンスター１体を選んで除外し、自分のEXデッキ・墓地から「トポロジック」モンスター１体を選んで特殊召喚する。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"Evil★Twin’s トラブル・サニー",h:"いびるついんず　とらぶる・さにー",e:"Evil★Twin's Trouble Sunny",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リンク","効果"],ma:"",tp:"",at:3300,de:"-",tx:"「イビルツイン」モンスターを含むモンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n自分の墓地から「キスキル」モンスターと「リィラ」モンスターをそれぞれ１体まで選んで特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：墓地のこのカードを除外し、手札・デッキ及び自分フィールドの表側表示モンスターの中から、「イビルツイン」モンスター１体を墓地へ送って発動できる。\nフィールドのカード１枚を選んで墓地へ送る。",li:"",lk:["上","右","下","左"],ta:[],al:[]},
  {n:"ヘヴィ・トリガー",h:"へう゛ぃ・とりがー",e:"Heavy Interlock",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ヴァレルロード・R・ドラゴン」の降臨に必要。\n①：レベルの合計が８以上になるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の手札・フィールドの「ヴァレット」モンスターを破壊し、手札から「ヴァレルロード・R・ドラゴン」を儀式召喚する。\nこの効果で特殊召喚したモンスターはEXデッキから特殊召喚されたモンスターとの戦闘では破壊されず、EXデッキから特殊召喚されたモンスターが発動した効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"龍相剣現",h:"りゅうそうけんげん",e:"Swordsoul Emergence",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「相剣」モンスター１体を手札に加える。\n自分フィールドにSモンスターが存在する場合、代わりに幻竜族モンスター１体を手札に加える事もできる。\n②：このカードが除外された場合、自分フィールドの、「相剣」モンスターまたは幻竜族モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで１つ上げる、または下げる。",li:2,lk:[],ta:[],al:[]},
  {n:"大霊峰相剣門",h:"だいれいほうそうけんもん",e:"Swordsoul Sacred Summit",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「相剣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n自分フィールドにSモンスターが存在する場合、代わりに幻竜族モンスター１体を対象とする事もできる。\n②：このカードが除外された場合、自分フィールドの、「相剣」モンスターまたは幻竜族モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで１つ上げる、または下げる。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印の気炎",h:"らくいんのきえん",e:"Branded in High Spirits",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札のモンスター１体を相手に見せ、そのモンスターと同じ種族で、攻撃力または守備力が２５００のレベル８融合モンスター１体をEXデッキから墓地へ送る。\nその後、以下の効果を適用できる。\n●見せたモンスターを捨て、「アルバスの落胤」１体またはそのカード名が記されたモンスター１体をデッキから手札に加える。\n②：このターンに融合モンスターが自分の墓地へ送られている場合、エンドフェイズに発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水揺籃",h:"ひすいようらん",e:"Icejade Cradle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：同名カードが自分のフィールド・墓地に存在しない「氷水」モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"赫の烙印",h:"あかのらくいん",e:"Branded in Red",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の、「デスピア」モンスターまたは「アルバスの落胤」１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、以下の効果を適用できる。\n●自分の手札・フィールドから、レベル８以上の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはこのターン直接攻撃できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃずと謎の地図",h:"ふわんだりぃずとなぞのちず",e:"Floowandereeze and the Magnificent Map",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札からレベル１の「ふわんだりぃず」モンスター１体を相手に見せ、見せたモンスターとはカード名が異なる「ふわんだりぃず」カード１枚をデッキから除外する。\nその後、見せたモンスターを召喚する。\n②：相手がモンスターの召喚に成功した場合に発動できる。\n自分は「ふわんだりぃず」モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃずと未知の風",h:"ふわんだりぃずとみちのかぜ",e:"Floowandereeze and the Unexplored Winds",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分がモンスター２体のリリースを必要とするアドバンス召喚をする場合、モンスター２体をリリースせずに自分フィールドのモンスター１体と相手フィールドのカード１枚を墓地へ送ってアドバンス召喚できる。\n②：自分メインフェイズに発動できる。\n手札の鳥獣族モンスターを２体まで相手に見せ、好きな順番でデッキの一番下に戻す。\nその後、自分は戻した数だけデッキからドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・ラミフィケーション",h:"れぷてぃれす・らみふぃけーしょん",e:"Reptilianne Ramifications",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を１枚墓地へ送り、以下の効果から２つを選択して発動できる。\n\n●デッキから「レプティレス」モンスター１体を手札に加える。\n\n●デッキから「レプティレス・ラミフィケーション」以外の「レプティレス」魔法・罠カード１枚を手札に加える。\n\n●相手フィールドのモンスター１体を選び、その攻撃力を０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"レプティレス・リコイル",h:"れぷてぃれす・りこいる",e:"Reptilianne Recoil",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの攻撃力０のモンスター１体と、自分の墓地の爬虫類族・闇属性モンスター１体を対象として発動できる。\nそのフィールドのモンスターを破壊し、その墓地のモンスターを特殊召喚する。\n②：相手がモンスターの効果を発動した場合、相手フィールドの攻撃力０のモンスター１体を対象として発動できる。\nそのモンスターのコントロールを得る。\nその後、相手フィールドに「レプティレストークン」（爬虫類族・地・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵闘争",h:"まけんとうそう",e:"Magikey Battle",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の、通常モンスター、「魔鍵」モンスター、「魔鍵－マフテア」の内、１枚を対象として発動できる。\nそのカードをデッキに戻す。\n相手の魔法・罠・モンスターの効果の発動にチェーンしてこのカードを発動した場合、さらにトークン以外の自分フィールドの通常モンスター及び「魔鍵」モンスターはその相手の効果を受けない。",li:"",lk:[],ta:[],al:[]},
  {n:"ロイヤル・ペンギンズ・ガーデン",h:"ろいやる・ぺんぎんず・がーでん",e:"Royal Penguins Garden",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「ロイヤル・ペンギンズ・ガーデン」以外の「ペンギン」カード１枚を手札に加える事ができる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札及び自分フィールドの表側表示モンスターの中から、「ペンギン」モンスター１体を選び、そのモンスターのレベルをターン終了時まで１つ下げる。\nその後、自分の手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"音速を追う者",h:"そにっくとらっかー",e:"Sonic Tracker",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「トランソニックバード」の降臨に必要。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：レベルの合計が４以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「トランソニックバード」を儀式召喚する。\nこの効果で特殊召喚したモンスターのレベルは、その儀式召喚に使用したモンスターのレベルを合計したレベルになる。\n②：墓地のこのカードを除外し、自分フィールドの儀式モンスター１体を対象として発動できる。\n種族または属性がそのモンスターと同じ儀式モンスター１体をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"聖蔓の播種",h:"さんう゛ぁいん・そういんぐ",e:"Sunvine Sowing",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「サンシード」モンスター１体を特殊召喚し、自分は１０００ダメージを受ける。\n自分フィールドに「サンアバロン」リンクモンスターが存在しない場合には、この効果で「聖種の地霊」しか特殊召喚できない。\nこのカードの発動後、ターン終了時まで自分は植物族モンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドの植物族リンクモンスターが戦闘または相手の効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"天斗輝巧極",h:"べあるくてぃ・どらいとろん",e:"Ursarctic Drytron",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、「ベアルクティ・ビッグディッパー」と「竜輝巧－ファフニール」を１枚ずつ除外し、「天極輝艦－熊斗竜巧」１体をEXデッキから特殊召喚する。\nフィールドに「ベアルクティ－ポラリィ」または「竜輝巧－バンα」が存在する場合、除外するカードの内１枚をデッキから除外する事もできる。\n②：自分が「ベアルクティ」、「ドライトロン」モンスターの効果を発動するためにモンスターをリリースする場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"超自然警戒区域",h:"ちょうしぜんけいかいくいき",e:"Supernatural Danger Zone",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：効果モンスター以外のモンスターが表側表示で特殊召喚された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から効果モンスター以外のモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"夜の逃飛行",h:"ないと・ふらいと",e:"Night Flight",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを持ち主の手札に戻す。\nこのターン、お互いにこの効果で手札に加えたカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"スモール・ワールド",h:"すもーる・わーるど",e:"Small World",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札のモンスター１体を相手に見せる。\n見せたモンスターの種族・属性・レベル・攻撃力・守備力の内、１つのみが同じモンスター１体をデッキから選んで確認し、手札から見せたモンスターを裏側表示で除外する。\nさらに確認したカードと種族・属性・レベル・攻撃力・守備力の内、１つのみが同じモンスター１体をデッキから手札に加え、デッキから確認したカードを裏側表示で除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"リローデッド・シリンダー",h:"りろーでっど・しりんだー",e:"Magical Cylinders",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のデッキ・墓地から「魔法の筒」１枚を選んで自分フィールドにセットする。\nデッキからセットした場合、そのカードはセットしたターンでも発動できる。\n②：自分が「魔法の筒」を発動した時、墓地のこのカードを除外して発動できる。\nその効果で相手に与えるダメージは倍になる。",li:"",lk:[],ta:[],al:[]},
  {n:"デトネーション・コード",h:"でとねーしょん・こーど",e:"Detonation Code",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「トポロジック」リンクモンスターが存在する場合、リンクモンスター以外の自分の墓地のドラゴン族・機械族・サイバース族の闇属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが魔法＆罠ゾーンから除外された場合、次のターンのスタンバイフェイズに発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"瑞相剣究",h:"ずいそうけんきゅう",e:"Swordsoul Assessment",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\n自分の墓地から「相剣」カードまたは幻竜族モンスターを５枚まで除外し、対象のモンスターの攻撃力は除外した数×３００アップする。\n②：このカードが除外された場合に発動できる。\n自分フィールドに「相剣トークン」（幻竜族・チューナー・水・星４・攻／守０）１体を特殊召喚する。\nこの効果で特殊召喚したトークンが存在する限り、自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣暗転",h:"そうけんあんてん",e:"Swordsoul Blackout",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの幻竜族モンスター１体と相手フィールドのカード２枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが除外された場合に発動できる。\n自分フィールドに「相剣トークン」（幻竜族・チューナー・水・星４・攻／守０）１体を特殊召喚する。\nこの効果で特殊召喚したトークンが存在する限り、自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃずと夢の町",h:"ふわんだりぃずとゆめのまち",e:"Floowandereeze and the Dreaming Town",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに発動できる。\nレベル４以下の鳥獣族モンスター１体を召喚する。\n②：このカードが墓地に存在する状態で、自分がレベル７以上のモンスターのアドバンス召喚に成功した場合、このカードを除外して発動できる。\n相手フィールドのモンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃずと怖い海",h:"ふわんだりぃずとこわいうみ",e:"Floowandereeze and the Scary Sea",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに、アドバンス召喚された表側表示モンスターが存在し、特殊召喚されたモンスターが存在しない場合、相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを持ち主の手札に戻す。\nこのターン、相手はモンスターを特殊召喚できず、通常召喚を３回まで行う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ブレイク・ザ・デステニー",h:"ぶれいく・ざ・ですてにー",e:"Break the Destiny",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの、「Dragoon D-END」またはレベル８以上の「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターを破壊し、次の相手メインフェイズ１をスキップする。\n②：墓地のこのカードを除外して発動できる。\n「ブレイク・ザ・デステニー」以外の、「Dragoon D-END」のカード名または「D-HERO」モンスターのカード名が記された魔法・罠カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"魔鍵錠－施－",h:"まけんじょう－ろっく－",e:"Magikey Locking",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：トークン以外の自分フィールドの、通常モンスターまたは「魔鍵」モンスター１体をリリースし、レベルの合計が８以下になるように、自分の墓地の通常モンスターまたは「魔鍵」モンスターを２体まで対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nその後、以下の効果から１つを選んで適用できる。\n\n●自分フィールドのモンスターをS素材として「魔鍵」Sモンスター１体をS召喚する。\n\n●自分フィールドのモンスターをX素材として「魔鍵」Xモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"聖邪のステンドグラス",h:"せいじゃのすてんどぐらす",e:"Stained Glass of Light & Dark",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの効果モンスターの種族によって以下の効果をそれぞれ適用できる。\n\n●天使族：自分はデッキから３枚ドローする。\nその後、手札を２枚選んで好きな順番でデッキの一番下に戻す。\n\n●悪魔族：相手はデッキから１枚ドローする。\nその後、手札をランダムに１枚選んで捨てる。\nさらに相手は手札がある場合には１枚選んで捨てる。",li:"",lk:[],ta:[],al:[]},
  {n:"巨星墜とし",h:"きょせいおとし",e:"Giant Starfall",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードの発動に対してレベルを持たないモンスターの効果は発動できない。\n①：レベルを持たないフィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターはターン終了時まで、攻撃力が０になり、効果を発動できず、戦闘では破壊されない。\nこのターン、その表側表示モンスターの戦闘で発生するお互いへの戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ラドリートラップ",h:"らどりーとらっぷ",e:"Laundry Trap",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが召喚・特殊召喚された場合にこの効果を発動できる。\n自分のデッキの上からカードを１枚墓地へ送る。\n②：このカードが効果でデッキから墓地へ送られた場合、「ラドリートラップ」以外の、このターンに自分の墓地へ送られたカード１枚を対象として発動できる。\nそのカードを手札に加える。\nこの効果の発動後、次の自分ターンの終了時まで、自分はこの効果で手札に加えたカード及びその同名カードの効果の発動をできない。",li:"",lk:[],ta:[],al:[]},
  {n:"ホーリー・エルフ－ホーリー・バースト・ストリーム",h:"ほーりー・えるふ－ほーりー・ばーすと・すとりーむ",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにレベル５以上の通常モンスターが存在し、相手フィールドのモンスターが効果を発動した時に発動できる。\nこのカードを手札から特殊召喚し、その効果を無効にする。\n②：相手バトルフェイズに、自分または相手の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこのターン、そのモンスターが自分フィールドに存在する限り、攻撃可能な相手モンスターはそのモンスターを攻撃しなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"カイザー・グライダー－ゴールデン・バースト",h:"かいざー・ぐらいだー－ごーるでん・ばーすと",e:"",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、そのモンスターの攻撃力と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"魔導騎士ギルティア－ソウル・スピア",h:"まどうきしぎるてぃあ－そうる・すぴあ",e:"",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚に成功した時に発動できる。\nこのカードの攻撃力以上の攻撃力を持つ相手フィールドのモンスター１体を選んで除外する。\n③：手札を１枚捨てて発動できる。\nデッキから以下のモンスターの内１体を手札に加える。\n\n●ドラゴン族・闇属性・レベル７モンスター\n●機械族・闇属性・レベル６モンスター\n●戦士族・水属性・レベル５モンスター",li:"",lk:[],ta:[],al:[]},
  {n:"ハーピィズペット竜－セイント・ファイアー・ギガ",h:"はーぴぃずぺっとどらごん－せいんと・ふぁいあー・ぎが",e:"",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2500,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにレベル６以下の風属性モンスターが存在する場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、お互いにレベル６以下の「ハーピィ」モンスターを攻撃対象に選択できない。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから鳥獣族・風属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"アストラル・クリボー",h:"あすとらる・くりぼー",e:"Astral Kuriboh",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：EXデッキの「No.」Xモンスター１体を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\nこのカードのレベルは見せたモンスターのランクの数値と同じになる。\nこの効果で特殊召喚したこのカードがモンスターゾーンに表側表示で存在する限り、自分は「No.」XモンスターしかEXデッキから特殊召喚できない。\n②：フィールドのこのカードを素材としてX召喚した「No.」モンスターは以下の効果を得る。\n●このカードは戦闘及び相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジェスティ・ヒュペリオン",h:"まじぇすてぃ・ひゅぺりおん",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2100,de:2700,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは自分の手札・フィールド・墓地の「代行者」モンスター１体を除外し、手札・墓地から特殊召喚できる。\n②：自分の天使族モンスターの戦闘で発生する自分への戦闘ダメージは相手も受ける。\n③：１ターンに１度、自分の手札・墓地から天使族モンスター１体を除外し、自分または相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nフィールドまたは墓地に「天空の聖域」が存在する場合、この効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[]},
  {n:"命の代行者 ネプチューン",h:"いのちのだいこうしゃ　ねぷちゅーん",e:"",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\n自分の手札・墓地から「命の代行者 ネプチューン」以外の「代行者」モンスター１体を選んで特殊召喚する。\nフィールドまたは墓地に「天空の聖域」が存在する場合、特殊召喚するモンスターを「ヒュペリオン」モンスター１体にできる。\n相手ターン終了時まで、お互いにこの効果で特殊召喚したモンスターをリリースできない。\n②：このカードが除外された場合に発動できる。\nデッキから「天空の聖域」１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天空の歌声",h:"てんくうのうたごえ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１０００LPを払い、自分の墓地の天使族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、フィールドまたは墓地に「天空の聖域」が存在する場合、除外されている自分のカードの中から、「天空の聖域」またはそのカード名が記されたカード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"失われた聖域",h:"うしなわれたせいいき",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「天空の聖域」またはそのカード名が記された魔法・罠カード１枚を選んで自分フィールドにセットする。\n②：このカードのカード名は、フィールド・墓地に存在する限り「天空の聖域」として扱う。\n③：自分の墓地から天使族モンスター１体を除外し、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"冥府の執行者 プルート",h:"めいふのしっこうしゃ　ぷるーと",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2300,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名はルール上「代行者」カードとしても扱う。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分の墓地からモンスター１体を除外し、フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\nフィールドまたは墓地に「天空の聖域」が存在する場合、この効果は相手ターンでも発動できる。\n②：墓地のこのカードを除外して発動できる。\n自分のデッキ・墓地から「天空の聖域」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"マスターフレア・ヒュペリオン",h:"ますたーふれあ・ひゅぺりおん",e:"",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:2600,tx:"チューナー＋チューナー以外の天使族モンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「代行者」モンスター１体または「天空の聖域」のカード名が記されたモンスター１体を手札・デッキ・EXデッキから墓地へ送って発動できる。\nエンドフェイズまで、このカードはそのモンスターと同名カードとして扱い、同じ効果を得る。\n②：相手がカードの効果を発動した時、自分の手札・墓地から天使族モンスター１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"代行者の近衛 ムーン",h:"だいこうしゃのこのえ　むーん",e:"",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リンク","効果"],ma:"",tp:"",at:1800,de:"-",tx:"天使族モンスター２体\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合に発動できる。\nデッキから「天空の聖域」またはそのカード名が記されたカード１枚を墓地へ送る。\nフィールドまたは墓地に「天空の聖域」が存在する場合、代わりに自分のデッキ・墓地から「神秘の代行者 アース」１体を選んで手札に加える事ができる。\n②：自分フィールドの天使族モンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:["右","右下"],ta:[],al:[]},
  {n:"天空の聖水",h:"てんくうのせいすい",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから、「天空の聖域」１枚を発動するか、「天空の聖域」のカード名が記されたモンスター１体を手札に加える。\nその後、フィールドまたは墓地に「天空の聖域」が存在する場合、自分は自分フィールドの「ヒュペリオン」モンスター及び「代行者」モンスターの数×５００LP回復できる。\n②：「天空の聖域」のカード名が記された自分のモンスターが戦闘で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"真の光",h:"しんのひかり",e:"True Light",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\n●自分の手札・墓地から「青眼の白龍」１体を選んで特殊召喚する。\n●同名カードが自分のフィールド・墓地に存在しない、「青眼の白龍」のカード名が記された魔法・罠カード１枚をデッキから選んで自分フィールドにセットする。\n②：自分のモンスターゾーンの「青眼の白龍」は相手の効果の対象にならない。\n③：魔法＆罠ゾーンの表側表示のこのカードが墓地へ送られた場合に発動する。\n自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"マジシャンズ・サルベーション",h:"まじしゃんず・さるべーしょん",e:"Magician's Salvation",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「永遠の魂」１枚を選んで自分フィールドにセットできる。\n②：自分が「ブラック・マジシャン」または「ブラック・マジシャン・ガール」の召喚・特殊召喚に成功した場合、そのモンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なる「ブラック・マジシャン」または「ブラック・マジシャン・ガール」１体を自分の墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"切り裂かれし闇",h:"きりさかれしやみ",e:"Piercing the Darkness",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分がトークン以外の通常モンスターの召喚・特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：以下のいずれかの自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその自分のモンスターの攻撃力はターン終了時まで、その相手モンスターの攻撃力分アップする。\n\n●レベル５以上の通常モンスター\n●通常モンスターを使用して儀式召喚したモンスター\n●通常モンスターを素材として融合・S・X召喚したモンスター",li:"",lk:[],ta:[],al:[]},
  {n:"アウトバースト・ドラゴン",h:"あうとばーすと・どらごん",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2100,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nその後、このカード以外の自分フィールドのモンスターを全て破壊する。\nこの効果で特殊召喚したこのカードがモンスターゾーンに表側表示で存在する限り、自分は闇属性モンスターしか特殊召喚できない。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをリンク素材としてドラゴン族リンクモンスター１体をリンク召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Uk－P.U.N.K.娑楽斎",h:"うきよえ－ぱんくしゃらくさい",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：６００LPを払って発動できる。\n自分の手札・フィールドから、「P.U.N.K.」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：相手ターンに、６００LPを払って発動できる。\n自分フィールドのモンスターをS素材として「P.U.N.K.」Sモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Ga－P.U.N.K.ワゴン",h:"ががく－ぱんくわごん",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：６００LPを払って発動できる。\nデッキから「P.U.N.K.」魔法カード１枚を手札に加える。\n②：自分フィールドの「P.U.N.K.」モンスターが相手の効果の対象になった時、または相手モンスターの攻撃対象に選択された時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"Jo－P.U.N.K.Mme.スパイダー",h:"じょうるり－ぱんくまだむすぱいだー",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：６００LPを払って発動できる。\nデッキから「P.U.N.K.」罠カード１枚を手札に加える。\n②：相手フィールドのカードを対象とする自分の「P.U.N.K.」カードの効果が発動した時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[]},
  {n:"No－P.U.N.K.セアミン",h:"のう－ぱんくせあみん",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：６００LPを払って発動できる。\nデッキから「No－P.U.N.K.セアミン」以外の「P.U.N.K.」モンスター１体を手札に加える。\n②：このカードが墓地へ送られた場合、自分フィールドの「P.U.N.K.」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は６００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"No－P.U.N.K.フォクシー・チューン",h:"のう－ぱんくふぉくしー・ちゅーん",e:"",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2300,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「P.U.N.K.」モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：手札・フィールドのこのカードを墓地へ送って発動できる。\n自分の手札を１枚選んで墓地へ送り、デッキからレベル８以外の「P.U.N.K.」モンスター１体を特殊召喚する。\n③：１ターンに１度、このカードが戦闘で相手モンスターを破壊した時に発動できる。\nそのモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"No－P.U.N.K.オーガ・ナンバー",h:"のう－ぱんくおーが・なんばー",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「P.U.N.K.」モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：手札・フィールドのこのカードを墓地へ送って発動できる。\nデッキからレベル８以外の「P.U.N.K.」モンスター１体を手札に加える。\n③：１ターンに１度、相手がモンスターの効果を発動した時に発動できる。\nこのカードの攻撃力はターン終了時まで、その相手モンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"Uk－P.U.N.K.カープ・ライジング",h:"うきよえ－ぱんくかーぷ・らいじんぐ",e:"",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魚族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:2600,tx:"「P.U.N.K.」モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：融合召喚したこのカードをリリースして発動できる。\n手札・デッキからレベル８以外の「P.U.N.K.」モンスターを２体まで守備表示で特殊召喚する（同名カードは１枚まで）。\n②：このカードをS素材としてS召喚に成功した場合、自分フィールドの「P.U.N.K.」モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[]},
  {n:"Uk－P.U.N.K.アメイジング・ドラゴン",h:"うきよえ－ぱんくあめいじんぐ・どらごん",e:"",t:"monster",a:"風",l:11,ps:"",pe:"",tr:"海竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがS召喚に成功した場合、自分のフィールド・墓地のサイキック族・レベル３モンスターの種類の数まで相手フィールドのカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：「Uk－P.U.N.K.アメイジング・ドラゴン」以外の自分の墓地の「P.U.N.K.」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"Ga－P.U.N.K.ワイルド・ピッキング",h:"ががく－ぱんくわいるど・ぴっきんぐ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「P.U.N.K.」モンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターを破壊する。\n②：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\n自分フィールドの全ての「P.U.N.K.」モンスターは、このターン戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"Ga－P.U.N.K.クラッシュ・ビート",h:"ががく－ぱんくくらっしゅ・びーと",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「P.U.N.K.」モンスターを対象とする効果を相手が発動した場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\n自分フィールドの全ての「P.U.N.K.」モンスターはこのターン、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"Jo－P.U.N.K.デンジャラス・ガブ",h:"じょうるり－ぱんくでんじゃらす・がぶ",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n自分フィールドに「P.U.N.K.」モンスターが存在する場合、さらに対象のモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"Jo－P.U.N.K.ナシワリ・サプライズ",h:"じょうるり－ぱんくなしわり・さぷらいず",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。\n自分フィールドに「P.U.N.K.」モンスターが存在する場合、代わりに相手フィールドの表側表示のカード１枚を対象とする事もできる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・エリス",h:"えくそしすたー・えりす",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「エクソシスター」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n自分フィールドに「エクソシスター・ステラ」が存在する場合、さらに自分は８００LP回復する。\n②：自分・相手の墓地のカードが相手によって墓地から離れた場合に発動できる。\n「エクソシスター」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・ステラ",h:"えくそしすたー・すてら",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「エクソシスター」モンスター１体を特殊召喚する。\nその後、自分フィールドに「エクソシスター・エリス」が存在する場合、自分は８００LP回復する。\n②：自分・相手の墓地のカードが相手によって墓地から離れた場合に発動できる。\n「エクソシスター」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・イレーヌ",h:"えくそしすたー・いれーぬ",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から「エクソシスター」カード１枚をデッキの一番下に戻して発動できる。\n自分はデッキから１枚ドローする。\n自分フィールドに「エクソシスター・ソフィア」が存在する場合、さらに自分は８００LP回復する。\n②：自分・相手の墓地のカードが相手によって墓地から離れた場合に発動できる。\n「エクソシスター」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・ソフィア",h:"えくそしすたー・そふぃあ",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに他の「エクソシスター」モンスターが存在する場合に発動できる。\n自分はデッキから１枚ドローする。\n自分フィールドに「エクソシスター・イレーヌ」が存在する場合、さらに自分は８００LP回復する。\n②：自分・相手の墓地のカードが相手によって墓地から離れた場合に発動できる。\n「エクソシスター」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・ミカエリス",h:"えくそしすたー・みかえりす",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"レベル４モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「エクソシスター」モンスターを素材としてこのカードのX召喚に成功した自分・相手ターンに、相手のフィールド・墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\n②：このカードは墓地から特殊召喚されたモンスターとの戦闘では破壊されない。\n③：このカードのX素材を１つ取り除いて発動できる。\nデッキから「エクソシスター」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・カスピテル",h:"えくそしすたー・かすぴてる",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:800,tx:"レベル４モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「エクソシスター」モンスターを素材としてX召喚に成功した場合に発動できる。\nこのターン、お互いに墓地からモンスターを特殊召喚できない。\n②：このカードは墓地から特殊召喚されたモンスターとの戦闘では破壊されない。\n③：このカードのX素材を１つ取り除いて発動できる。\nデッキから「エクソシスター」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・ジブリーヌ",h:"えくそしすたー・じぶりーぬ",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1400,de:2800,tx:"レベル４モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「エクソシスター」モンスターを素材としてこのカードのX召喚に成功した自分・相手ターンに、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。\n②：このカードは墓地から特殊召喚されたモンスターが発動した効果では破壊されない。\n③：このカードのX素材を１つ取り除いて発動できる。\nこのターン中は自分フィールドのXモンスターの攻撃力が８００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・アソフィール",h:"えくそしすたー・あそふぃーる",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"レベル４モンスター×２\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「エクソシスター」モンスターを素材としてX召喚に成功した場合に発動できる。\nこのターン、お互いに墓地のカードの効果を発動できない。\n②：このカードは墓地から特殊召喚されたモンスターが発動した効果では破壊されない。\n③：このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・パークス",h:"えくそしすたー・ぱーくす",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに８００LPを払って発動できる。\nデッキから「エクソシスター・パークス」以外の「エクソシスター」カード１枚を手札に加える。\nこの効果でモンスターを手札に加え、そのモンスターにカード名が記された「エクソシスター」モンスターが自分のフィールド・墓地に存在している場合、さらに手札に加えたそのモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・アーメント",h:"えくそしすたー・あーめんと",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、墓地から特殊召喚されたモンスターが相手フィールドに存在しない場合、相手ターンにしか発動できない。\n①：８００LPを払い、自分フィールドの「エクソシスター」モンスター１体を対象として発動できる。\n同名カードが自分フィールドに存在しない「エクソシスター」Xモンスター１体を、対象の自分のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・カルペディベル",h:"えくそしすたー・かるぺでぃべる",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「エクソシスター」モンスターは墓地から特殊召喚されたモンスターの効果の対象にならない。\n②：自分が「エクソシスター」モンスターのX召喚に成功した場合、カード名を１つ宣言して発動できる。\nターン終了時まで、宣言したカードと元々のカード名が同じカードの効果は無効化される。\n③：自分の「エクソシスター」モンスターが戦闘を行う攻撃宣言時、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"エクソシスター・バディス",h:"えくそしすたー・ばでぃす",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：８００LPを払って発動できる。\nデッキから「エクソシスター」モンスター１体を選び、さらにそのモンスターにカード名が記された「エクソシスター」モンスター１体をデッキから選ぶ。\nそのモンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主のデッキに戻る。\nこのカードの発動後、ターン終了時まで自分は「エクソシスター」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"アラメシアの儀",h:"あらめしあのぎ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は特殊召喚されたモンスター以外のフィールドのモンスターの効果を発動できない。\n①：自分フィールドに「勇者トークン」が存在しない場合に発動できる。\n自分フィールドに「勇者トークン」（天使族・地・星４・攻／守２０００）１体を特殊召喚する。\n自分フィールドに「運命の旅路」が存在しない場合、さらにデッキから「運命の旅路」１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く事ができる。",li:2,lk:[],ta:[],al:[]},
  {n:"聖殿の水遣い",h:"せいでんのみずつかい",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「勇者トークン」が存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：手札・墓地のこのカードを除外して発動できる。\n自分のデッキ・墓地から「アラメシアの儀」１枚を選んで手札に加える。\n③：自分フィールドに「勇者トークン」が存在する場合に発動できる。\nデッキから「勇者トークン」のトークン名が記されたフィールド魔法カード１枚を選んで自分のフィールドゾーンに表側表示で置く。",li:2,lk:[],ta:[],al:[]},
  {n:"遺跡の魔鉱戦士",h:"いせきのまこうせんし",e:"",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「勇者トークン」が存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「勇者トークン」が存在しない場合、このカードは攻撃できない。\n③：「勇者トークン」のトークン名が記された自分のモンスターが戦闘を行ったバトルフェイズ終了時に発動できる。\nデッキから「勇者トークン」のトークン名が記された罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"流離のグリフォンライダー",h:"さすらいのぐりふぉんらいだー",e:"",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2000,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに、モンスターが存在しない場合、または「勇者トークン」が存在する場合、自分・相手のメインフェイズに発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「勇者トークン」が存在し、魔法・罠・モンスターの効果が発動した時に発動できる。\nこのカードを持ち主のデッキに戻し、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"運命の旅路",h:"うんめいのたびじ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキから「勇者トークン」のトークン名が記されたモンスター１体を手札に加え、その後手札を１枚選んで墓地へ送る。\n②：モンスターが召喚・特殊召喚された場合に発動できる。\nデッキから「勇者トークン」のトークン名が記された装備魔法カード１枚を選び、手札に加えるか、自分フィールドの「勇者トークン」１体に装備する。\n③：１ターンに１度だけ、装備カードを装備している自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"光の聖剣ダンネル",h:"ひかりのせいけんだんねる",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「光の聖剣ダンネル」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターの攻撃力は、自分フィールドの「勇者トークン」のトークン名が記されたモンスターの種類×５００アップする。\n③：このカードが墓地へ送られた場合、自分フィールドの「勇者トークン」１体を対象として発動できる。\nその自分のモンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"星空蝶",h:"すたーりっと・ぱぴよん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「星空蝶」は自分フィールドに１枚しか表側表示で存在できない。\n②：相手フィールドのモンスターの攻撃力は、自分フィールドの「勇者トークン」のトークン名が記されたモンスターの種類×５００ダウンする。\n③：このカードが墓地へ送られた場合、自分フィールドの「勇者トークン」１体を対象として発動できる。\nその自分のモンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"騎竜ドラコバック",h:"きりゅうどらこばっく",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターにのみ装備可能。\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「騎竜ドラコバック」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードが効果モンスター以外のモンスターに装備されている場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n③：このカードが墓地へ送られた場合、自分フィールドの「勇者トークン」１体を対象として発動できる。\nその自分のモンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"暗黒神殿ザララーム",h:"あんこくしんでんざららーむ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「光の聖剣ダンネル」を装備したモンスターが存在する限り、相手はバトルフェイズに効果を発動できない。\n②：１ターンに１度、自分の「勇者トークン」が戦闘で相手モンスターを破壊した時に発動できる。\nその相手モンスターの元々の攻撃力分のダメージを相手に与える。\n③：このカードの②の効果を発動したターンの自分メインフェイズに１度、発動できる。\n自分のデッキ・墓地から「暗黒神殿ザララーム」以外の「勇者トークン」のトークン名が記されたフィールド魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"迷い花の森",h:"まよいばなのもり",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：「星空蝶」を装備した自分のモンスターは相手が発動した効果を受けない。\n②：１ターンに１度、自分の「勇者トークン」が戦闘でモンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。\n③：このカードの②の効果を発動したターンの自分メインフェイズに１度、発動できる。\n自分のデッキ・墓地から「迷い花の森」以外の「勇者トークン」のトークン名が記されたフィールド魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"サンダー・ディスチャージ",h:"さんだー・でぃすちゃーじ",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「勇者トークン」が存在する場合、「勇者トークン」のトークン名が記された装備カードを装備した自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力以下の攻撃力を持つ相手フィールドのモンスターを全て破壊する。\nその後、自分の手札・墓地から「勇者トークン」のトークン名が記された装備魔法カード１枚を選んで自分フィールドの装備可能なモンスター１体に装備できる。",li:"",lk:[],ta:[],al:[]},
  {n:"リザレクション・ブレス",h:"りざれくしょん・ぶれす",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「勇者トークン」が存在する場合に発動できる。\n自分の手札・墓地からモンスターを２体まで選んで特殊召喚する（同名カードは１枚まで）。\nその後、自分の手札・墓地から「勇者トークン」のトークン名が記された装備魔法カード１枚を選んで自分フィールドの装備可能なモンスター１体に装備できる。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[]},
  {n:"勇気の天使ヴィクトリカ",h:"ゆうきのてんしう゛ぃくとりか",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n手札からレベル５以上の光属性モンスター１体を特殊召喚し、自分はそのモンスターの元々の攻撃力分のLPを失う。\nこの効果で特殊召喚したモンスターの攻撃力はターン終了時まで倍になる。\n②：モンスターゾーンのこのカードが破壊された場合、自分の墓地からこのカード以外の天使族モンスター１体を除外して発動できる。\n除外したモンスターとレベルが同じ天使族モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"天翔ける騎士",h:"すたーりんぐ・ないと",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1000,tx:"レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\n手札から光属性モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドの天使族・光属性・レベル４モンスター１体を対象として発動できる。\nこのカードを特殊召喚し、対象のモンスターをこのカードの下に重ねてX素材とする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マジクリボー",h:"まじくりぼー",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：戦闘または相手の効果で自分がダメージを受けたターンのメインフェイズ及びバトルフェイズに、このカードを手札から墓地へ送って発動できる。\n自分のデッキ・墓地から「ブラック・マジシャン」または「ブラック・マジシャン・ガール」１体を選んで特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドの表側表示の魔法使い族モンスターが戦闘または相手の効果で破壊された場合に発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ディメンション・コンジュラー",h:"でぃめんしょん・こんじゅらー",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地から「ディメンション・マジック」１枚を選んで手札に加える。\n②：このカードがモンスターゾーンから墓地へ送られた場合に発動できる。\n自分フィールドの魔法使い族モンスターの数だけ、自分はデッキからドローする。\nその後、ドローした数だけ手札を選んで好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"合体竜ティマイオス",h:"がったいりゅうてぃまいおす",e:"",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札及び自分フィールドの表側表示のカードの中から、魔法使い族モンスター１体または「ブラック・マジシャン」のカード名が記された魔法・罠カード１枚を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分の手札・フィールドから、融合モンスターカードによって決められた、魔法使い族モンスターを含む融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーアイズ・ジェット・ドラゴン",h:"ぶるーあいず・じぇっと・どらごん",e:"",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できず、自分フィールドまたは自分の墓地に「青眼の白龍」が存在する場合にしか発動できない。\n①：このカードが手札・墓地に存在し、フィールドのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードを特殊召喚する。\n②：このカード以外の自分フィールドのカードは相手の効果では破壊されない。\n③：このカードが戦闘を行うダメージステップ開始時に、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ロード・オブ・ドラゴン－ドラゴンの独裁者－",h:"ろーど・おぶ・どらごん－どらごんのどくさいしゃ－",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札・デッキから「青眼の白龍」１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：手札から「青眼の白龍」１体またはそのカード名が記されたカード１枚を捨て、自分の墓地の「ブルーアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n③：自分フィールドに「ブルーアイズ」モンスターが存在する限り、相手モンスターの攻撃対象は自分が選択する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水帝コスモクロア",h:"ひすいていこすもくろあ",e:"",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:3000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドゾーンに表側表示でカードが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドに「氷水底イニオン・クレイドル」が存在する限り、相手フィールドのモンスターは召喚・反転召喚・特殊召喚されたターンにしか効果を発動できない。\n③：自分の「氷水」モンスターが相手モンスターと戦闘を行うダメージ計算時のみ、その相手モンスターの攻撃力は１０００ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水のエジル",h:"ひすいのえじる",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「氷水」魔法・罠カード１枚を手札に加える。\n②：このカードが相手の効果の対象になった時、または相手モンスターの攻撃対象に選択された時に発動できる。\n自分の手札・墓地から「氷水のエジル」以外の水属性モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。\nこのターン、このカードは１度だけ戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水艇キングフィッシャー",h:"ひすいていきんぐふぃっしゃー",e:"",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの水属性モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードを装備した「氷水」モンスターは守備表示のままで攻撃できる。\nその場合、装備モンスターは守備力を攻撃力として扱いダメージ計算を行う。\n③：装備モンスターの守備力以下の攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nこのカードを特殊召喚し、対象のモンスターを手札に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・テリジア",h:"だいのるふぃあ・てりじあ",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ダイノルフィア」罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n自分のLPが２０００以下の場合、さらにこのカードの攻撃力は５００アップする。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地から罠カード１枚を除外して発動できる。\n自分の墓地から「ダイノルフィア・テリジア」以外のレベル４以下の「ダイノルフィア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・ディプロス",h:"だいのるふぃあ・でぃぷろす",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ダイノルフィア」カード１枚を墓地へ送る。\n自分のLPが２０００以下の場合、さらに相手に５００ダメージを与える。\n②：このカードが戦闘・効果で破壊された場合、自分の墓地から罠カード１枚を除外して発動できる。\n自分の墓地から「ダイノルフィア・ディプロス」以外のレベル４以下の「ダイノルフィア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"極星獣グリンブルスティ",h:"きょくせいじゅうぐりんぶるすてぃ",e:"",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:100,tx:"このカードは「極星」チューナーの代わりとしてS素材にできる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「極星」モンスター１体を特殊召喚する。\n②：「極星獣グリンブルスティ」以外の自分の墓地の「極星」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"極星工イーヴァルディ",h:"きょくせいこういーう゛ぁるでぃ",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「極神」モンスターまたは「極星」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「極星宝」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"DDグリフォン",h:"でぃーでぃーぐりふぉん",e:"",t:"monster",a:"闇",l:4,ps:1,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの悪魔族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、自分のフィールド・墓地の「契約書」魔法・罠カードの種類×５００アップする。\nその後、このカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカード名の①②③のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「DD」モンスターが存在する場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードがP召喚に成功した場合、手札から「DD」カードまたは「契約書」カード１枚を捨てて発動できる。\n自分はデッキから１枚ドローする。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nデッキから「DDグリフォン」以外の「DD」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・セイレーン",h:"ごーすとりっく・せいれーん",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"自分フィールドに「ゴーストリック」モンスターが存在する場合のみ、このカードは表側表示で召喚できる。\n①：このカードが召喚・リバースした場合に発動する。\n自分のデッキの上からカードを２枚墓地へ送る。\nその中に「ゴーストリック」カードがあった場合、さらに以下の効果から１つを選んで適用できる。\n●デッキから「ゴーストリック」魔法・罠カード１枚を手札に加える。\n●相手フィールドの効果モンスター１体を選んで裏側守備表示にする。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。",li:"",lk:[],ta:[],al:[]},
  {n:"ヴァンパイアの幽鬼",h:"う゛ぁんぱいあのゆうき",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合、手札及び自分フィールドの表側表示のカードの中から、このカード以外の「ヴァンパイア」カード１枚を墓地へ送って発動できる。\nデッキからレベル４以上の「ヴァンパイア」モンスター１体を手札に加え、デッキからレベル２以下の「ヴァンパイア」モンスター１体を墓地へ送る。\n②：自分・相手のメインフェイズに、墓地のこのカードを除外し、５００LPを払って発動できる。\n「ヴァンパイア」モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force ラプスウェル",h:"せきゅりてぃ・ふぉーす　らぷすうぇる",e:"",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、「S－Force ラプスウェル」以外の自分の墓地の「S－Force」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：手札から「S－Force」カード１枚を除外して発動できる。\n自分の「S－Force」モンスターの正面の相手モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"S－Force レトロアクティヴ",h:"せきゅりてぃ・ふぉーす　れとろあくてぃう゛",e:"",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターを「S－Force」モンスターのリンク素材とする場合、手札のこのカードもリンク素材にできる。\n②：自分・相手のメインフェイズに発動できる。\nこのカードを持ち主の手札に戻し、手札からレベル２以上の「S－Force」モンスター１体を特殊召喚する。\n③：自分フィールドの「S－Force」モンスターが効果を発動するために手札を除外する場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[]},
  {n:"夢魔鏡の逆徒－ネイロイ",h:"ゆめまきょうのぎゃくと－ねいろい",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「夢魔鏡の使徒－ネイロイ」１体を手札に加える。\nその後、このカードを光属性にできる。\n②：自分フィールドの他の「夢魔鏡」モンスター１体をリリースして発動できる。\nそれとはレベルが異なる「夢魔鏡」モンスター１体をデッキから選び、選んだモンスターにカード名が記された「聖光の夢魔鏡」または「闇黒の夢魔鏡」１枚をデッキから手札に加え、選んだモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"変異体ミュートリア",h:"へんいたいみゅーとりあ",e:"",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「ミュートリア」カードが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードをリリースし、手札・デッキから「ミュートリア」カード１枚を除外して発動できる。\n除外したカードの種類により以下のモンスター１体を手札・デッキから特殊召喚し、自分はそのモンスターの元々の攻撃力分のLPを失う。\n\n●モンスター：「ミュートリアル・ビースト」\n●魔法：「ミュートリアル・ミスト」\n●罠：「ミュートリアル・アームズ」",li:"",lk:[],ta:[],al:[]},
  {n:"磁石の戦士ε",h:"まぐねっと・うぉりあー・いぷしろん",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、デッキから「磁石の戦士ε」以外のレベル４以下の「マグネット・ウォリアー」モンスター１体を墓地へ送って発動できる。\nこのカードはエンドフェイズまで、墓地へ送ったモンスターと同名カードとして扱う。\nその後、同名カードが自分フィールドに存在しない、「マグネット・ウォリアー」モンスターまたは「磁石の戦士」モンスター１体を自分の墓地から選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"破壊の代行者 ヴィーナス",h:"はかいのだいこうしゃ　う゛ぃーなす",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1600,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地から「創造の代行者 ヴィーナス」１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：５００の倍数のLPを払い、払ったLP５００につき１体、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「神聖なる球体」を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[]},
  {n:"地葬星カイザ",h:"ちそうせいかいざ",e:"",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2100,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは自分フィールドの光属性または地属性の戦士族モンスター１体をリリースして手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの攻撃力は、リリースしたモンスターの元々の攻撃力分アップする。\n②：自分の墓地から戦士族モンスター１体を除外して発動できる。\nデッキから光属性または地属性の戦士族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"絶神鳥シムルグ",h:"ぜつしんちょうしむるぐ",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに、自分が鳥獣族モンスターの召喚・特殊召喚に成功した場合、手札のこのカードを相手に見せて発動できる。\n「シムルグ」モンスター１体を召喚する。\n②：このカードが召喚に成功した場合、デッキから「シムルグ」モンスター１体を墓地へ送って発動できる。\nデッキから「シムルグ」魔法・罠カード１枚を手札に加える。\n③：このカードがモンスターゾーンに存在する限り、このカードの属性は「風」としても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"熟練の栗魔導士",h:"じゅくれんのくりまどうし",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：このカードの魔力カウンターを１つ取り除き、以下の効果から１つを選択して発動できる。\n\n●このカードのレベルは１つ上がり、攻撃力は１５００アップする。\n\n●自分のデッキ・墓地から「クリボー」モンスター１体または「増殖」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"カオス・ネフティス",h:"かおす・ねふてぃす",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1600,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、フィールドのカードが効果で破壊された場合、自分の墓地から「カオス・ネフティス」以外の光属性と闇属性のモンスターを１体ずつ除外して発動できる。\nこのカードを特殊召喚する。\n②：このカードが特殊召喚に成功した場合、相手フィールドのカード１枚と相手の墓地のカード２枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[]},
  {n:"模拘撮星人 エピゴネン",h:"もくさつせいじん　えぴごねん",e:"",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの効果モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nその後、リリースしたモンスターの元々の種族・属性と同じ種族・属性を持つ「エピゴノストークン」（星１・攻／守０）１体を自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"游覧艇サブマリード",h:"ゆうらんていさぶまりーど",e:"",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドに通常モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度だけ、自分の通常モンスターは効果モンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"悪魔嬢アリス",h:"あくまじょうありす",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地から罠カード１枚を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚に成功した時、自分の墓地の「悪魔嬢」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n③：このカードがリリースされた場合、または相手によって破壊された場合に発動できる。\nデッキから「悪魔嬢アリス」以外の攻撃力・守備力の合計が２０００となる悪魔族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"枯鰈葉リプレース",h:"かれいはりぷれーす",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、相手の墓地のカードの数が自分の墓地のカードより多い場合、自分スタンバイフェイズに発動できる。\nこのカードを特殊召喚する。\n②：このカードの攻撃力・守備力は相手の墓地のカードの数×２００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"マッド・ハッカー",h:"まっど・はっかー",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドにモンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分・相手のエンドフェイズに、フィールドのこのカードを除外して発動できる。\n相手フィールドの攻撃力が一番低いモンスター１体のコントロールを得る。\nそのモンスターが自分フィールドに表側表示で存在する限り、そのモンスターは効果を発動できず、自分はリンクモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"マタタビ仙狸",h:"またたびせんり",e:"",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、「マタタビ仙狸」以外の自分の墓地のレベル２モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、この効果で特殊召喚したモンスターとは属性が異なるレベル２モンスター１体を手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"ピースリア",h:"ぴーすりあ",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"①：このカードは戦闘では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に発動できる。\nこのカードにピースカウンターを１つ置く（最大４つまで）。\nその後、このカードのピースカウンターの数によって以下の効果を適用できる。\n\n●１つ：デッキからモンスター１体を選んでデッキの一番上に置く。\n\n●２つ：自分はデッキから１枚ドローする。\n\n●３つ：デッキからモンスター１体を手札に加える。\n\n●４つ：デッキからカード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"機怪獣ダレトン",h:"きかいじゅうだれとん",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの元々の攻撃力は相手ターン終了時まで、フィールドのモンスターの、攻撃力と元々の攻撃力の差分を合計した数値になる。",li:"",lk:[],ta:[],al:[]},
  {n:"イリュージョン・オブ・カオス",h:"いりゅーじょん・おぶ・かおす",e:"",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2100,de:2500,tx:"「カオス・フォーム」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札のこのカードを相手に見せて発動できる。\n儀式モンスターを除く、「ブラック・マジシャン」またはそのカード名が記されたモンスター１体をデッキから手札に加える。\nその後、手札を１枚選んでデッキの一番上に戻す。\n②：相手がモンスターの効果を発動した時に発動できる。\nこのカードを持ち主の手札に戻し、自分の墓地から「ブラック・マジシャン」１体を選んで特殊召喚し、その発動した効果を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"凶導の白聖骸",h:"どらぐまのあるばす・せいんと",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:500,de:2500,tx:"「凶導の葬列」により降臨。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合、フィールドの表側表示モンスター２体を対象として発動できる。\nその内１体の攻撃力を、もう１体の攻撃力分アップする。\n②：レベル８以上の自分の「ドラグマ」モンスターは戦闘では破壊されない。\n③：相手のEXデッキからモンスターが特殊召喚された場合に発動できる。\n相手のEXデッキを確認し、その内のモンスター１体を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"超魔導戦士－マスター・オブ・カオス",h:"ちょうまどうせんし－ますたー・おぶ・かおす",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「ブラック・マジシャン」＋「カオス」儀式モンスター\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合、自分の墓地の光・闇属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドの光・闇属性モンスターを１体ずつリリースして発動できる。\n相手フィールドのモンスターを全て除外する。\n③：融合召喚したこのカードが戦闘・効果で破壊された場合、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ブルーアイズ・タイラント・ドラゴン",h:"ぶるーあいず・たいらんと・どらごん",e:"",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3400,de:2900,tx:"「青眼の白龍」＋ドラゴン族モンスター\nこのカードは融合召喚及び以下の方法でのみEXデッキから特殊召喚できる。\n●融合モンスターを装備した自分の「青眼の白龍」１体をリリースした場合にEXデッキから特殊召喚できる。\n①：フィールドのこのカードは罠カードの効果を受けない。\n②：このカードは相手モンスター全てに１回ずつ攻撃できる。\n③：１ターンに１度、このカードが戦闘を行ったダメージステップ終了時に、自分の墓地の罠カード１枚を対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・ケントレギナ",h:"だいのるふぃあ・けんとれぎな",e:"",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"恐竜族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:0,tx:"カード名が異なる「ダイノルフィア」モンスター×２\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの攻撃力は自分のLPの数値分ダウンする。\n②：自分・相手のメインフェイズに、LPを半分払い、自分の墓地から「ダイノルフィア」通常罠カード１枚を除外して発動できる。\nこの効果は、その罠カード発動時の効果と同じになる。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\n自分の墓地からレベル４以下の「ダイノルフィア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・ステルスベギア",h:"だいのるふぃあ・すてるすべぎあ",e:"",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"恐竜族",mo:["融合","効果"],ma:"",tp:"",at:0,de:2500,tx:"カード名が異なる「ダイノルフィア」モンスター×２\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のLPが２０００以下である限り、自分は「ダイノルフィア」モンスターの効果及び、罠カードを発動するために払うLPが必要なくなる。\n②：相手がモンスターの効果を発動した時に発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\n自分の墓地からレベル４以下の「ダイノルフィア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"ガーディアン・キマイラ",h:"がーでぃあん・きまいら",e:"",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:3300,de:3300,tx:"カード名が異なるモンスター×３\nこのカードは手札と自分フィールドのモンスターのみをそれぞれ１体以上素材とした融合召喚でのみEXデッキから特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが魔法カードの効果で融合召喚に成功した場合に発動できる。\n手札で融合素材としたカードの数だけ自分はデッキからドローし、フィールドで融合素材としたカードの数だけ相手フィールドのカードを選んで破壊する。\n②：自分の墓地に「融合」が存在する限り、このカードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[]},
  {n:"相剣大邪－七星龍淵",h:"そうけんだいじゃ－しちせいりゅうえん",e:"",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:2300,tx:"チューナー＋チューナー以外の幻竜族モンスター１体以上\nこのカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドに存在し、自分が他の幻竜族SモンスターのS召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：相手がモンスターの特殊召喚に成功した場合に発動できる。\nその内の１体を選んで除外し、相手に１２００ダメージを与える。\n③：相手が魔法・罠カードの効果を発動した時に発動できる。\nそのカードを除外し、相手に１２００ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"いろはもみじ",h:"いろはもみじ",e:"",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:2600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、属性を１つ宣言して発動できる。\nこのカードは以下の効果を得る。\n●フィールドの全ての表側表示モンスターは宣言した属性になる。\n②：相手のメインモンスターゾーンのモンスター１体を対象として発動できる。\n相手はそのモンスターの前・後・隣のゾーン（モンスターゾーン・魔法＆罠ゾーン）に存在するカードの中から１枚を墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[]},
  {n:"闇次元の戦士",h:"やみじげんのせんし",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1200,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚除外し、除外されている自分の闇属性モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示または裏側守備表示で特殊召喚する。\n②：自分・相手のエンドフェイズに発動する。\nフィールドにセットされたカードの数×１００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"DDD赦俿王デス・マキナ",h:"でぃーでぃーでぃーしゃちおうです・まきな",e:"",t:"monster",a:"闇",l:10,ps:10,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：もう片方の自分のPゾーンにカードが存在する場合、自分のフィールド・墓地のPモンスター１体を対象として発動できる。\nもう片方の自分のPゾーンのカードを特殊召喚し、対象のPモンスターを自分のPゾーンに置く。",tr:"悪魔族",mo:["エクシーズ","ペンデュラム","効果"],ma:"",tp:"",at:3000,de:3000,tx:"悪魔族レベル１０モンスター×２\nこのカードは自分フィールドの「DDD」モンスターの上に重ねてX召喚する事もできる。\n①：「DDD赦俿王デス・マキナ」は自分のモンスターゾーンに１体しか表側表示で存在できない。\n②：相手フィールドのモンスターカードが効果を発動した時に発動できる（同一チェーン上では１度まで）。\nこのカードのX素材を２つ取り除くか自分フィールドの「契約書」カード１枚を破壊し、その相手のカードをこのカードのX素材とする。\n③：自分スタンバイフェイズに発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"真血公ヴァンパイア",h:"じ・あんでっとう゛ぁんぱいあ",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2800,tx:"レベル８モンスター×２体以上\n元々の持ち主が相手となるモンスターをこのカードのX召喚の素材とする場合、そのレベルを８として扱う。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは墓地以外から特殊召喚されたモンスターの効果の対象にならない。\n②：このカードのX素材を１つ取り除いて発動できる。\nお互いのデッキの上からカードを４枚墓地へ送る。\nこの効果でモンスターが自分・相手の墓地へ送られた場合、さらに自分は墓地へ送られたそのモンスター１体を選んで自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"神隠し鬼火丸",h:"かみかくしおにびまる",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1600,tx:"レベル２モンスター×２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを相手エンドフェイズまで除外する。\n②：このカードが相手によって破壊された場合、このカードが持っていたX素材の数まで、除外されている自分・相手のモンスターを対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・フェスティバル",h:"ごーすとりっく・ふぇすてぃばる",e:"",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:0,de:"-",tx:"リンクモンスター以外の「ゴーストリック」モンスター１体\nこのカードをリンク召喚する場合、自分フィールドの裏側表示の「ゴーストリック」モンスターもリンク素材にできる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドゾーンに「ゴーストリック」カードが存在する限り、自分の「ゴーストリック」モンスターは直接攻撃できる。\n②：相手モンスターの攻撃宣言時に、このカードをリリースして発動できる。\nデッキから「ゴーストリック」モンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:["下"],ta:[],al:[]},
  {n:"ヴァンパイア・ファシネイター",h:"う゛ぁんぱいあ・ふぁしねいたー",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"アンデット族モンスターを含むモンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリンク召喚に成功した場合、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。\nこのターン、自分はアンデット族モンスターしか特殊召喚できない。\n②：自分フィールドの「ヴァンパイア」モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"暗影の闇霊使いダルク",h:"あんえいのやみれいつかいだるく",e:"",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リンク","効果"],ma:"",tp:"",at:1850,de:"-",tx:"闇属性モンスターを含むモンスター２体\nこのカード名はルール上「憑依装着」カードとしても扱う。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の闇属性モンスター１体を対象として発動できる。\nそのモンスターをこのカードのリンク先となる自分フィールドに特殊召喚する。\n②：リンク召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから守備力１５００以下の闇属性モンスター１体を手札に加える。",li:"",lk:["左下","右下"],ta:[],al:[]},
  {n:"青き眼の幻出",h:"あおきめのげんしゅつ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードは手札の「青眼の白龍」１体を見せて発動する事もできる。\nその場合、手札からモンスター１体を特殊召喚する。\n②：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを持ち主の手札に戻す。\nその後、手札に戻したカードの元々のカード名によって以下の効果を適用できる。\n●「青眼の白龍」：手札からモンスター１体を特殊召喚する。\n●それ以外：手札から「ブルーアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"究極融合",h:"あるてぃめっと・ふゅーじょん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに発動できる。\n自分の手札・フィールド・墓地から、融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、「青眼の白龍」または「青眼の究極竜」を融合素材とするその融合モンスター１体をEXデッキから融合召喚する。\nその後、その融合素材としたフィールドの「青眼の白龍」「青眼の究極竜」の数まで相手フィールドの表側表示のカードを選んで破壊できる。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水底イニオン・クレイドル",h:"ひすいていいにおん・くれいどる",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「氷水」モンスター１体を選んで手札に加える事ができる。\n②：１ターンに１度、モンスターが召喚・特殊召喚された場合、自分フィールドの水属性モンスター１体を対象として発動できる。\n対象のモンスター及び相手フィールドの全ての表側表示モンスターの攻撃力はターン終了時まで、対象のモンスターの元々の攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印の使徒",h:"らくいんのしと",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手が発動したモンスターの効果処理時に、自分フィールドにレベル８以上の悪魔族の融合モンスターが存在し、その相手モンスターの攻撃力または守備力が０の場合、その発動した効果を無効にできる。\n②：攻撃力０のモンスター同士が戦闘を行ったダメージステップ終了時に発動できる。\nその戦闘を行った相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"凶導の葬列",h:"どらぐまかぶる",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ドラグマ」儀式モンスターの降臨に必要。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地の融合・Sモンスターを除外し、自分の手札・墓地から「ドラグマ」儀式モンスター１体を儀式召喚する。\nその後、フィールドに「凶導の白騎士」及び「凶導の白聖骸」が存在する場合、自分または相手のEXデッキを確認し、その内のモンスター１体を選んで墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"極星宝フリドスキャルヴ",h:"きょくせいほうふりどすきゃるう゛",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：デッキから「極星」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターが表側表示で存在する限り、自分は「極神」モンスターしかEXデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「極星」モンスター１体を手札に加える。\nその後、手札を１枚選んでデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"特許権の契約書類",h:"とっきょけんのけいやくしょるい",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「DDD」モンスターと同じ種類（融合・S・X・リンク）のモンスターを相手が特殊召喚した場合に発動できる。\n相手に１０００ダメージを与える。\nこのターン、このカードがフィールドに存在する間、相手はその特殊召喚したモンスターと同じ種類のモンスターを特殊召喚できない。\n②：このカードが墓地へ送られた場合に発動できる。\n自分の墓地のモンスター及び自分のEXデッキの表側表示のPモンスターの中から、「DD」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・ショット",h:"ごーすとりっく・しょっと",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地から「ゴーストリック」モンスター１体を選んで特殊召喚する。\nその後、自分フィールドの裏側表示の「ゴーストリック」モンスター１体を選んで表側攻撃表示にできる。\n②：墓地のこのカードを除外し、自分フィールドの「ゴーストリック」Xモンスター１体を対象として発動できる。\n自分の墓地から「ゴーストリック」カード１枚を選び、対象のモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[]},
  {n:"溟界の大蛟",h:"めいかいのおおみず",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・フィールドからモンスター１体を墓地へ送り、そのモンスターとは元々の属性が異なる自分の墓地の爬虫類族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分・相手のメインフェイズに、モンスターが相手の墓地へ送られた場合に発動できる。\nデッキから爬虫類族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ベアルクティ・ラディエーション",h:"べあるくてぃ・らでぃえーしょん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードを発動する場合、このカードにカウンターを７つ置いて発動する。\n①：「ベアルクティ・ラディエーション」は自分フィールドに１枚しか表側表示で存在できない。\n②：手札・EXデッキから「ベアルクティ」モンスターが特殊召喚される度に、このカードのカウンターを１つ取り除いて発動できる。\n自分はデッキから１枚ドローする。\n③：自分・相手のエンドフェイズに、「ベアルクティ・ラディエーション」以外の自分の墓地の「ベアルクティ」カード１枚を対象として発動できる。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ふわんだりぃずと旅じたく",h:"ふわんだりぃずとたびじたく",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、鳥獣族モンスター１体を除外して発動できる。\nデッキから「ふわんだりぃず」モンスター１体または「ふわんだりぃず」フィールド魔法カード１枚を手札に加える。\nその後、自分は５００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"X・Y・Zコンバイン",h:"えっくす・わい・ぜっとこんばいん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の機械族・光属性のユニオンモンスターカードが除外された場合に発動できる。\nデッキから「X－ヘッド・キャノン」「Y－ドラゴン・ヘッド」「Z－メタル・キャタピラー」の内１体を特殊召喚する。\n②：自分フィールドの融合モンスター１体をEXデッキに戻して発動できる。\n除外されている自分のモンスターの中から、「X－ヘッド・キャノン」「Y－ドラゴン・ヘッド」「Z－メタル・キャタピラー」を２体まで選んで特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[]},
  {n:"新世壊",h:"にゅー・くりあ・わーるど",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、そのモンスターとは元々の種族・属性が異なり、そのモンスターの元々のレベルより低いレベルを持つモンスター１体をデッキから守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[]},
  {n:"発禁令",h:"はっきんれい",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：カード名を１つ宣言して発動できる。\nこのターン、相手は宣言されたカードと元々のカード名が同じカードの効果を発動できない。\nこのデュエル中、自分は宣言したカードと元々のカード名が同じカードの効果を発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"トップ・シェア",h:"とっぷ・しぇあ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからカード１枚を選び、お互いに確認してデッキの一番上に置く。\nその後、相手は自身のデッキからカード１枚を選び、お互いに確認して自身のデッキの一番上に置く。",li:"",lk:[],ta:[],al:[]},
  {n:"運命のウラドラ",h:"うんめいのうらどら",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：１０００LPを払い、自分フィールドの表側表示モンスター１体を対象として発動できる。\n相手ターン終了時まで、そのモンスターの攻撃力は１０００アップし、以下の効果を適用する。\n●そのモンスターが戦闘で相手モンスターを破壊した時に発動できる。\n自分のデッキの一番下のカードをお互いに確認し、デッキの一番上または一番下に戻す。\n確認したカードがドラゴン族・恐竜族・海竜族・幻竜族モンスターだった場合、その攻撃力１０００につき１枚、自分はデッキからドローする。\nその後、自分はドローした数×１０００LP回復する。",li:"",lk:[],ta:[],al:[]},
  {n:"氷水浸蝕",h:"ひすいしんしょく",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの表側表示のカード１枚を対象として発動できる。\n自分フィールドの「氷水」モンスター１体を選んで破壊し、対象のカードの効果をターン終了時まで無効にする。\n②：自分フィールドの表側表示の水属性モンスターが、破壊以外の方法で相手によってフィールドから離れた場合に発動できる。\nデッキから「氷水」モンスター１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"憶念の相剣",h:"おくねんのそうけん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：カードが除外された場合に発動できる。\n自分の手札・デッキ・墓地及び自分・相手フィールドの表側表示のカードの中から、幻竜族Sモンスター１体または「相剣」カード１枚を選んで除外する。\n②：このカードが除外された場合に発動できる。\n自分フィールドに「相剣トークン」（幻竜族・チューナー・水・星４・攻／守０）１体を特殊召喚する。\nこの効果で特殊召喚したトークンが存在する限り、自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・ドメイン",h:"だいのるふぃあ・どめいん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズに、LPを半分払って発動できる。\n自分の手札・デッキ・フィールドから、「ダイノルフィア」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：自分のLPが２０００以下で、相手が魔法・罠・モンスターの効果を発動した時、墓地のこのカードを除外して発動できる。\nこのターン、相手の効果で発生する自分への効果ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・アラート",h:"だいのるふぃあ・あらーと",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：LPを半分払って発動できる。\nレベルの合計が８以下になるように、自分の墓地から「ダイノルフィア」モンスターを２体まで選んで特殊召喚する。\nこのターン、自分は「ダイノルフィア」モンスターしか特殊召喚できず、この効果で特殊召喚したモンスターでは攻撃宣言できない。\n②：自分のLPが２０００以下で、相手が魔法・罠・モンスターの効果を発動した時、墓地のこのカードを除外して発動できる。\nこのターン、相手の効果で発生する自分への効果ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・ブルート",h:"だいのるふぃあ・ぶるーと",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：LPを半分払って発動できる。\n自分フィールドの「ダイノルフィア」モンスター１体と相手フィールドのカード１枚を選んで破壊する。\n②：自分のLPが２０００以下で、相手が魔法・罠・モンスターの効果を発動した時、墓地のこのカードを除外して発動できる。\nこのターン、相手の効果で発生する自分への効果ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・シェル",h:"だいのるふぃあ・しぇる",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手バトルフェイズ開始時に、LPを半分払って発動できる。\n自分フィールドに「ダイノルフィアトークン」（恐竜族・闇・星１０・攻０／守３０００）１体を特殊召喚する。\nこのターン、この効果で特殊召喚したトークンが自分フィールドに存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n②：自分のLPが２０００以下で、自分が戦闘ダメージを受けるダメージ計算時に、墓地のこのカードを除外して発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・ソニック",h:"だいのるふぃあ・そにっく",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「ダイノルフィア」モンスターが存在し、相手が魔法・罠カードを発動した時、LPを半分払って発動できる。\nその発動を無効にし破壊する。\nその後、自分フィールドの「ダイノルフィア」モンスター１体を選んで破壊する。\n②：自分のLPが２０００以下で、自分が戦闘ダメージを受けるダメージ計算時に、墓地のこのカードを除外して発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"ダイノルフィア・リヴァージョン",h:"だいのるふぃあ・りう゛ぁーじょん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「ダイノルフィア」融合モンスターが存在する場合、LPを半分払い、自分の墓地からカウンター罠カード１枚を除外して発動できる。\nこの効果は、そのカウンター罠カード発動時の効果と同じになる。\n②：自分のLPが２０００以下で、自分が戦闘ダメージを受けるダメージ計算時に、墓地のこのカードを除外して発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[]},
  {n:"極星宝スヴァリン",h:"きょくせいほうすう゛ぁりん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに「極神」モンスターが存在する場合に発動できる。\n相手フィールドの全ての表側表示のカードの効果はターン終了時まで無効化される。\n②：自分フィールドの「極星」モンスター１体をリリースし、自分の墓地の「極神」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"DDDヘッドハント",h:"でぃーでぃーでぃーへっどはんと",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「DDD」モンスターが存在する場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのコントロールを次のターンのエンドフェイズまで得る。\nこの効果でコントロールを得たモンスターの効果は無効化され、攻撃宣言できない。\nこの効果でコントロールを得たモンスターがEXデッキから特殊召喚されたモンスターの場合、そのモンスターは「DDD」モンスターとしても扱う。",li:"",lk:[],ta:[],al:[]},
  {n:"ゴーストリック・オア・トリート",h:"ごーすとりっく・おあ・とりーと",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに、「ゴーストリック」フィールド魔法カードまたは「ゴーストリック」リンクモンスターが存在する場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\n相手は２０００LPを払う事ができる。\nその場合、このカードの効果は「発動後このカードは墓地へ送らず、そのままセットする」となる。\n払わなかった場合、このターン、その表側表示モンスターは攻撃できず、効果は無効化され、エンドフェイズに裏側守備表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"死者所生",h:"ししゃしょせい",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：モンスターが戦闘で破壊されたターンに、手札・デッキから「死者蘇生」１枚を墓地へ送り、自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを「死者蘇生」の効果による特殊召喚扱いとして自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"トライブ・ドライブ",h:"とらいぶ・どらいぶ",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターの種族が３種類以上の場合に発動できる。\n自分フィールドのモンスターと同じ種族のモンスターをデッキから３体選ぶ（同じ種族は１体まで）。\n相手はその中からランダムに１体を選び、自分はそのモンスターを手札に加えるか特殊召喚する。\n残りのモンスターは好きな順番でデッキの下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"死償不知",h:"ししょうふち",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のLPが相手より少ない場合に、以下の効果から１つを選択して発動できる。\n\n●その差の数値以下の攻撃力を持つ相手フィールドのモンスター１体を選んで破壊する。\n\n●その差の数値以下の攻撃力を持つモンスター１体を自分の墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"走魔灯",h:"そうまとう",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のLPが１００未満の場合に発動できる。\n自分はデッキから２枚ドローする。\n自分のLPが１０未満の場合、さらに自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[]},
  {n:"新鋭の女戦士",h:"しんえいのおんなせんし",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の他の戦士族モンスターが相手の表側表示モンスターと戦闘を行う攻撃宣言時に、手札・フィールドのこのカードを墓地へ送って発動できる。\nその相手モンスターの攻撃力はターン終了時まで、その元々の攻撃力分ダウンする。\n②：墓地のこのカードを除外し、自分の墓地の戦士族・地属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"クロニクル・マジシャン",h:"くろにくる・まじしゃん",e:"",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに元々の攻撃力または元々の守備力が２５００のモンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードが特殊召喚に成功した場合、自分フィールドの、「ブラック・マジシャン」または「青眼の白龍」１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで２５００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"アビス・シャーク",h:"あびす・しゃーく",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが水属性モンスターのみの場合に発動できる。\n手札のこのカードを特殊召喚し、デッキから「アビス・シャーク」以外のレベル３～５の魚族モンスター１体を手札に加える。\nこのターン、自分は水属性モンスターしか特殊召喚できず、自分の「No.」モンスターがモンスターとの戦闘で相手に与える戦闘ダメージは１度だけ倍になる。\n②：このカードを「No.」モンスターのX召喚に使用する場合、このカードのレベルを３または４として扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"クリスタル・シャーク",h:"くりすたる・しゃーく",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1100,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、フィールドの水属性モンスター１体を対象として発動できる。\nこのカードを特殊召喚し、対象のモンスターの攻撃力を半分にする。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n②：このカードを「No.」モンスターのX召喚に使用する場合、このカードのレベルを３または４として扱う事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"N・As・H Knight",h:"ねふぃる・あさいらむ・へっと・ないと",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1700,de:2700,tx:"レベル５モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドに「No.」モンスターが存在する限り、このカードは戦闘では破壊されない。\n②：自分・相手のメインフェイズに、このカードのX素材を２つ取り除いて発動できる。\nEXデッキから「No.101」～「No.107」のいずれかの「No.」Xモンスター１体を選び、このカードの下に重ねてX素材とする。\nその後、このカード以外のフィールドの表側表示モンスター１体を選んでこのカードの下に重ねてX素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"CX－N・As・Ch Knight",h:"かおすえくしーず－ねふぃる・あさいらむ・かおす・ないと",e:"",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:3000,tx:"レベル６モンスター×３\nこのカードは自分フィールドの「N・As・H Knight」の上に重ねてX召喚する事もできる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードは効果では破壊されない。\n②：このカードのX素材を１つ取り除いて発動できる。\n「No.101」～「No.107」のいずれかの「No.」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは次の相手エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"バリアンズ・カオス・ドロー",h:"ばりあんず・かおす・どろー",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分ドローフェイズに通常のドローをしたこのカードを公開し続ける事で、そのターンのメインフェイズ１に、以下の効果から１つを選択して発動できる。\n\n●デッキから「セブンス」通常魔法カード１枚を墓地へ送って発動できる。\nこの効果は、その魔法カード発動時の効果と同じになる。\n\n●デッキからモンスターを２体まで効果を無効にして特殊召喚し、そのモンスターを全て含む自分フィールドのモンスターを素材として「No.」Xモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"七皇昇格",h:"せぶんす・あせんしょん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから以下のカードの内いずれか１枚を選び、手札に加えるかデッキの一番上に置く。\n\n●「七皇昇格」以外の「セブンス」魔法・罠カード\n●「バリアンズ」魔法・罠カード\n●「RUM」速攻魔法カード\n②：EXデッキから特殊召喚されたモンスターが相手フィールドに存在する場合、墓地のこのカードを除外し、手札から「RUM」魔法カード１枚を墓地へ送って発動できる。\nこの効果は、その魔法カード発動時の効果と同じになる。",li:"",lk:[],ta:[],al:[]},
  {n:"不朽の七皇",h:"えたーなる・せぶんす",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの、「No.101」～「No.107」のいずれかの「No.」XモンスターまたはそのモンスターをX素材としているXモンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●対象のモンスターの攻撃力以下の攻撃力を持つ相手フィールドのモンスター１体を選び、その効果をターン終了時まで無効にする。\n\n●対象のモンスターのX素材を全て取り除く。\nその後、自分の墓地から「No.」Xモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"大要塞クジラ",h:"だいようさいくじら",e:"",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2550,de:2350,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドに「海」が存在する場合に発動できる。\nこのターン、自分の水属性モンスターは直接攻撃できる。\n②：相手バトルフェイズに、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\n自分のデッキ・墓地から戦士族・水属性モンスター１体を選び、手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"海竜神－リバイアサン",h:"かいりゅうしん－りばいあさん",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2000,de:1700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドに「海」が存在する限り、水属性以外の表側表示モンスターはお互いのフィールドにそれぞれ１体しか存在できない（お互いのプレイヤーは自身のフィールドに水属性以外の表側表示モンスターが２体以上存在する場合には、１体になるように墓地へ送らなければならない）。\n②：自分メインフェイズに発動できる。\nデッキから以下のカードの内いずれか１枚を手札に加える。\n\n●「海」\n●「リバイアサン」魔法・罠カード\n●「シー・ステルス」魔法・罠カード",li:"",lk:[],ta:[],al:[]},
  {n:"デス・クラーケン",h:"です・くらーけん",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドに「海」が存在する場合、「デス・クラーケン」以外の自分フィールドの水属性モンスター１体と、相手フィールドのモンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象の自分のモンスターを持ち主の手札に戻し、対象の相手モンスターを破壊する。\nこの効果は相手ターンでも発動できる。\n②：相手モンスターの攻撃宣言時に発動できる。\nこのカードを持ち主の手札に戻し、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[]},
  {n:"電気海月－フィサリア－",h:"でんきくらげ－ふぃさりあ－",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1400,de:1700,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札・デッキ及び自分フィールドの表側表示のカードの中から、「海」１枚を墓地へ送って発動できる。\n手札から水属性モンスター１体を特殊召喚する。\n②：フィールドに「海」が存在し、相手が魔法・モンスターの効果を発動した時に発動できる。\nその効果を無効にする。\nその後、このカードの攻撃力・守備力を６００アップできる。",li:"",lk:[],ta:[],al:[]},
  {n:"魚群探知機",h:"ふぃっしゅ・そなー",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからレベル７以下の、「海」のカード名が記されたモンスターまたは水属性の通常モンスター１体を手札に加える。\nフィールドに「海」が存在する場合、さらにデッキから水属性の通常モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[]},
  {n:"潜海奇襲Ⅱ",h:"しー・すてるすつー",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「海」として扱う。\n②：自分フィールドの水属性モンスターは、水属性以外の相手モンスターの効果の対象にならない。\n③：自分・相手のバトルフェイズ開始時に発動できる。\n自分の手札・墓地から、「海」のカード名が記されたモンスターまたは水属性の通常モンスター１体を選んで守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、そのバトルフェイズ終了時に破壊される。",li:"",lk:[],ta:[],al:[]},
  {n:"暗岩の海竜神",h:"あんがんのりばいあさん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示の「海」１枚を墓地へ送って発動できる。\n自分の手札・デッキから、「海」のカード名が記されたモンスターまたは水属性の通常モンスターを合計２体まで守備表示で特殊召喚する（同名カードは１枚まで）。\n相手フィールドにモンスターが存在する場合、さらに手札・デッキからレベル６以下の水属性の通常モンスターを任意の数だけ特殊召喚できる。\nこのカードの発動後、次の自分ターンの終了時まで自分は水属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女スプリンガール",h:"まりんせすすぷりんがーる",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「マリンセス」モンスター１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが水属性リンクモンスターのリンク素材として墓地へ送られた場合に発動できる。\n自分フィールドの「マリンセス」モンスターの数だけ、自分のデッキの上からカードを墓地へ送る。\nこの効果で「マリンセス」カードが墓地へ送られた場合、さらにその「マリンセス」カードの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女スリーピーメイデン",h:"まりんせすすりーぴーめいでん",e:"",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:500,de:2500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「マリンセス」カード１枚を対象として発動できる。\nこのカードを手札から特殊召喚し、このカードは以下の効果を得る。\n●このカードがモンスターゾーンに存在する限り、対象のカードは相手の効果では破壊されない。\n②：墓地のこのカードを除外し、自分フィールドの「マリンセス」リンクモンスター１体を対象として発動できる。\n自分の墓地から「マリンセス」リンクモンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女コーラルトライアングル",h:"まりんせすこーらるとらいあんぐる",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:1500,de:"-",tx:"「マリンセス」モンスター２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分は水属性モンスターしか特殊召喚できない。\n①：手札から水属性モンスター１体を墓地へ送って発動できる。\nデッキから「マリンセス」罠カード１枚を手札に加える。\n②：相手フィールドにのみモンスターが存在する場合、墓地のこのカードを除外して発動できる。\nリンクマーカーの合計が３になるように、自分の墓地から水属性リンクモンスターを任意の数だけ選んで特殊召喚する。",li:"",lk:["上","左下","右下"],ta:[],al:[]},
  {n:"海晶乙女アクア・アルゴノート",h:"まりんせすあくあ・あるごのーと",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイバース族",mo:["リンク","効果"],ma:"",tp:"",at:2300,de:"-",tx:"水属性モンスター２体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがEXモンスターゾーンに存在する限り、相手は他のモンスターを攻撃できない。\n②：自分フィールドの水属性モンスター１体と相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n③：相手ターンに、魔法・罠カードの効果がフィールドで発動した時に発動できる。\nこのカードに装備された自分の「マリンセス」モンスターカード１枚を選んで特殊召喚し、その発動した効果を無効にする。",li:"",lk:["上","左","右","下"],ta:[],al:[]},
  {n:"海晶乙女の潜逅",h:"まりんせす・だいぶ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\nこのカードの発動後、ターン終了時まで自分は水属性モンスターしか特殊召喚できない。\n\n●リンクモンスター以外の自分の墓地の「マリンセス」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n\n●自分のフィールドゾーンに「海晶乙女の闘海」が存在する場合に発動できる。\nデッキから「マリンセス」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女環流",h:"まりんせす・さーきゅれーしょん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドにリンク３以上の「マリンセス」モンスターが存在する場合、このカードの発動は手札からもできる。\n①：自分フィールドの水属性リンクモンスター１体を対象として発動できる。\nそのモンスターを持ち主のEXデッキに戻し、そのモンスターとはカード名が異なり、リンクマーカーの数が同じ「マリンセス」リンクモンスター１体をリンク召喚扱いとしてEXデッキから特殊召喚する。\nこのターン、この効果で特殊召喚したモンスターは直接攻撃できず、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"海晶乙女渦輪",h:"まりんせす・ばぶるりんぐ",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分または相手のモンスターの攻撃宣言時に発動できる。\nその攻撃を無効にし、自分のEXデッキ・墓地から「海晶乙女クリスタルハート」１体を選んで特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドのリンク２以上の「マリンセス」リンクモンスター１体を対象として発動できる。\nこのターン、そのモンスターは自身のリンクマーカーの数まで１度のバトルフェイズ中に攻撃でき、そのモンスターの戦闘で発生する相手への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[]},
  {n:"鉄獣鳥 メルクーリエ",h:"とらいぶりげーど　めるくーりえ",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「アルバスの落胤」を融合素材とする融合モンスターが存在し、相手がモンスターの効果を発動した時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその効果を無効にする。\n②：このカードが除外された場合に発動できる。\n「鉄獣鳥 メルクーリエ」を除く、「アルバスの落胤」１体またはそのカード名が記されたモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"スプリガンズ・キット",h:"すぷりがんず・きっと",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のフィールド・墓地に「アルバスの落胤」を融合素材とする融合モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地のカード及び除外されている自分のカードの中から、「烙印」魔法・罠カード１枚を選んで手札に加える。\nその後、手札を１枚選んでデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"軒轅の相剣師",h:"けんえんのそうけんし",e:"",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：モンスターの攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚し、その攻撃を無効にする。\n自分フィールドに「アルバスの落胤」を融合素材とする融合モンスターが存在する場合、さらにその攻撃宣言したモンスターを破壊できる。\n②：モンスターが表側表示で除外された場合、フィールド・墓地のこのカードを除外して発動できる。\n攻撃力と守備力の数値が同じ魔法使い族・光属性モンスター１体を自分の手札・墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"失烙印",h:"しつらくいん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、融合モンスターを融合召喚する効果を含む効果を自分が発動した場合、その発動は無効化されず、その融合召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。\n②：自分が融合モンスターの融合召喚に成功した場合に発動できる。\nデッキから「アルバスの落胤」１体またはそのカード名が記されたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印融合",h:"らくいんゆうごう",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は融合モンスターしかEXデッキから特殊召喚できない。\n①：自分の手札・デッキ・フィールドから、融合モンスターカードによって決められた融合素材モンスター２体を墓地へ送り、「アルバスの落胤」を融合素材とするその融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印の剣",h:"らくいんのつるぎ",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地から「烙印」魔法・罠カードを任意の数だけ除外して発動できる。\n除外した数だけ自分フィールドに「氷剣トークン」（ドラゴン族・闇・星８・攻２５００／守２０００）を特殊召喚する。\n②：墓地のこのカードを除外し、除外されている自分の、「アルバスの落胤」１体またはそのカード名が記されたモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"烙印断罪",h:"らくいんだんざい",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：モンスターを特殊召喚する効果を含む、モンスターの効果・魔法・罠カードが発動した時に発動できる。\n「アルバスの落胤」を融合素材とする融合モンスターを、自分フィールドの表側表示モンスターの中から１体または自分の墓地から２体選んで持ち主のEXデッキに戻し、その発動を無効にし破壊する。\n②：墓地のこのカードを除外し、「烙印断罪」以外の自分の墓地の「烙印」魔法・罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"氷剣竜ミラジェイド",h:"ひけんりゅうみらじぇいど",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"幻竜族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「アルバスの落胤」＋融合・S・X・リンクモンスター\n①：「氷剣竜ミラジェイド」は自分フィールドに１体しか表側表示で存在できない。\n②：自分・相手ターンに１度、「アルバスの落胤」を融合素材とする融合モンスター１体をEXデッキから墓地へ送って発動できる。\nフィールドのモンスター１体を選んで除外する。\n次のターン、このカードはこの効果を使用できない。\n③：融合召喚したこのカードが相手によってフィールドから離れた場合に発動できる。\nこのターンのエンドフェイズに相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"神炎竜ルベリオン",h:"しんえんりゅうるべりおん",e:"",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:3000,tx:"闇属性モンスター＋「アルバスの落胤」\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合、手札を１枚捨てて発動できる。\n自分のフィールド・墓地のモンスター及び除外されている自分のモンスターの中から、「神炎竜ルベリオン」を除くレベル８以下の融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、その融合モンスター１体をEXデッキから融合召喚する。\nこのターン、このカードは攻撃できず、自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"海神の依代",h:"うながみのよりしろ",e:"",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の水属性モンスター１体を対象として発動できる。\n以下の効果から１つを選んで適用する。\nこのターン、自分は水属性モンスターしか特殊召喚できない。\n●エンドフェイズまで、このカードは対象のモンスターと同じレベルになり、同名カードとして扱う。\n●フィールドに「海」が存在する場合、対象のモンスターを守備表示で特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「海」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"パペット・ポーン",h:"ぱぺっと・ぽーん",e:"",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは直接攻撃できる。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\nデッキから「プロモーション」１枚を手札に加える。\n③：墓地のこのカードを除外して発動できる。デッキから「パペット・ポーン」以外の戦士族・地属性モンスター１体を手札に加える。\nその後、相手は自身のデッキからモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"パペット・ルーク",h:"ぱぺっと・るーく",e:"",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"①：このカードが召喚に成功した時に発動できる。\nこのカードを守備表示にし、デッキから戦士族・地属性モンスター１体を墓地へ送る。\n②：このカードがモンスターゾーンに存在する限り、攻撃可能な相手モンスターはこのカードを攻撃しなければならない。\n③：１ターンに１度、このカードが攻撃対象に選択された時、自分の墓地のレベル６以上の戦士族・地属性モンスター１体を対象として発動できる。\nフィールドのこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替える。",li:"",lk:[],ta:[],al:[]},
  {n:"プロモーション",h:"ぷろもーしょん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのレベル３以下の戦士族・地属性モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、デッキからレベル４以上の戦士族・地属性モンスター１体を特殊召喚する。\nその後、元々の種族・属性が戦士族・地属性となる「パペット」モンスターが自分フィールドに存在する場合、この効果で特殊召喚したモンスターの攻撃力・守備力は相手の墓地のカードの数×１００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"戦場の惨劇",h:"せんじょうのさんげき",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：モンスター同士が戦闘を行っていないターンの自分メインフェイズ２に、手札を１枚捨てて発動できる。\nデッキから「戦場の惨劇」１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：モンスター同士が戦闘を行ったターンのエンドフェイズに発動する。\nターンプレイヤーは自身のデッキの上からカードを５枚墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"ブラックマンバ",h:"ぶらっくまんば",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに爬虫類族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nデッキから爬虫類族モンスター１体を墓地へ送り、対象のモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"呪念の化身ウルボヌス",h:"じゅねんのけしんうるぼぬす",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの爬虫類族モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力・守備力は３００ダウンする。\n③：自分フィールドのモンスター１体をリリースして発動できる。\n相手フィールドの全てのモンスターの攻撃力・守備力はターン終了時まで、リリースしたモンスターの元々の攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[]},
  {n:"ラミア",h:"らみあ",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから爬虫類族・レベル８モンスター１体を手札に加える。\n②：このカードが除外された場合、自分フィールドの爬虫類族モンスター１体を対象として発動できる。\nそのモンスターは次のターンの終了時まで効果では破壊されない。",li:"",lk:[],ta:[],al:[]},
  {n:"毒蛇の怨念",h:"どくじゃのおんねん",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、爬虫類族以外の自分のモンスターは、攻撃できず、効果を発動できない。\n②：自分フィールドの表側表示の爬虫類族モンスターが、戦闘で破壊された場合、または墓地へ送られた場合に発動できる。\nデッキからレベル４以下の爬虫類族モンスター１体を特殊召喚する。\n③：魔法＆罠ゾーンのこのカードが破壊された場合に発動できる。除外されている自分の爬虫類族モンスターを全て墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"劫火の槍術士",h:"ごうかのそうじゅつし　ごーすと・らんさー",e:"",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードはEXデッキから特殊召喚されたモンスター以外のモンスターとの戦闘では破壊されない。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[]},
  {n:"劫火の眠り姫",h:"ごうかのねむりひめ　ごーすと・すりーぱー",e:"",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合に発動できる。\nデッキから「幽合－ゴースト・フュージョン」１枚を手札に加える。\n②：墓地のこのカードを除外し、手札を１枚捨て、除外されている自分のレベル４以上のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[]},
  {n:"劫火の翼竜",h:"ごうかのよくりゅう　ごーすと・わいばーん",e:"",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「幽合－ゴースト・フュージョン」１枚を手札に加える。\n②：このカードが除外されたターンのエンドフェイズに発動できる。\nデッキからレベル２以下のアンデット族チューナー１体を選び、手札に加えるか墓地へ送る。",li:"",lk:[],ta:[],al:[]},
  {n:"幽合－ゴースト・フュージョン",h:"ゆうごう－ごーすと・ふゅーじょん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nその際、融合素材モンスターは全てアンデット族モンスターでなければならない。\n自分のLPが相手より少ない場合、自分の手札・デッキ・墓地のアンデット族モンスターも１体まで除外して融合素材とする事ができる。",li:"",lk:[],ta:[],al:[]},
  {n:"ナンバーズ・エヴァイユ",h:"なんばーず・えう゛ぁいゆ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：EXデッキから特殊召喚されたモンスターが相手フィールドにのみ存在する場合に発動できる。\nEXデッキから「No.」Xモンスター４体を選ぶ（同じランクは１体まで）。\n「No.」の数値がその４体の合計と同じとなる、「No.」Xモンスター１体をX召喚扱いとしてEXデッキから特殊召喚し、選んだモンスターを全てそのモンスターの下に重ねてX素材とする。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、自分は「No.」Xモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"七皇転生",h:"せぶんす・あらうんど",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の、「No.101」～「No.107」のいずれかの「No.」XモンスターまたはそのモンスターをX素材としているXモンスターが戦闘を行うダメージ計算時に発動できる。\nその自分のモンスターを除外する（X素材を持っているモンスターを除外する場合はそのX素材も全て除外する）。\nこのカードを発動したターンのエンドフェイズに、「No.」モンスターを除くランク３以下のXモンスター１体をEXデッキから特殊召喚し、その元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[]},
  {n:"七皇再生",h:"せぶんす・あらいばる",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのXモンスターを全てリリースし、除外されている自分のXモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、自分の墓地のモンスター及び除外されている自分のモンスターの中から、リリースしたモンスターの数＋１体まで、「No.101」～「No.107」のいずれかの「No.」Xモンスターを選んでその特殊召喚したモンスターの下に重ねてX素材にできる。\nこのカードを発動したターンのエンドフェイズに、お互いはそれぞれ自身の手札の数×３００ダメージを受ける。",li:"",lk:[],ta:[],al:[]},
  {n:"エクシーズ弁当",h:"えくしーずべんとう",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドのモンスターが、戦闘で破壊された場合、または墓地へ送られた場合、自分フィールドのXモンスター１体を対象として発動できる。\n相手の墓地からモンスター１体を選び、対象のモンスターの下に重ねてX素材とする。\n②：墓地のこのカードを除外し、EXデッキから特殊召喚されたフィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・バトラー",h:"えんためいとおっどあいず・ばとらー",e:"",t:"monster",a:"闇",l:5,ps:2,pe:"①：１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\n自分の墓地から「オッドアイズ」Pモンスター１体を選んでEXデッキに表側表示で加え、その攻撃を無効にする。\nその後、そのPモンスターの攻撃力分だけ自分のLPを回復できる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:2100,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、「EMオッドアイズ・バトラー」以外の自分フィールドの、「EM」モンスターカードまたは「オッドアイズ」モンスターカード１枚を対象として発動できる。\nこのカードを特殊召喚する。\nその後、対象のカードを破壊する。\n②：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"EMオッドアイズ・バレット",h:"えんためいとおっどあいず・ばれっと",e:"",t:"monster",a:"光",l:1,ps:8,pe:"①：１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\nその相手モンスターの攻撃力は、自分のEXデッキの表側表示のPモンスターの数×３００ダウンする。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:200,tx:"このカード名の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「EMオッドアイズ・バレット」以外の、「EM」モンスターまたは「オッドアイズ」モンスター１体を墓地へ送る。\nこのカードのレベルはターン終了時まで、墓地へ送ったモンスターのレベルと同じになる。\n②：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[]},
  {n:"EMバロックリボー",h:"えんためいとばろっくりぼー",e:"",t:"monster",a:"闇",l:8,ps:1,pe:"①：１ターンに１度、自分・相手のバトルフェイズ開始時に発動できる。\nデッキから攻撃力３００／守備力２００のモンスター１体を手札に加える。\nその後、このカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:3000,tx:"①：自分のモンスターが戦闘で破壊された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n③：このカードは攻撃された場合、ダメージステップ終了時に攻撃表示になる。",li:"",lk:[],ta:[],al:[]},
  {n:"EMクラシックリボー",h:"えんためいとくらしっくりぼー",e:"",t:"monster",a:"闇",l:1,ps:8,pe:"①：相手モンスターの直接攻撃宣言時にこのカードは破壊される。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の①②のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分のPゾーンのカード１枚を対象として発動できる。\nそのカードのPスケールはターン終了時まで１になる。\n②：このカードが墓地に存在し、自分が戦闘ダメージを受けた時に発動できる。\nこのカードを自分のPゾーンに置く。\n③：このカードが自身のP効果によって破壊された場合に発動する。\nバトルフェイズを終了する。",li:"",lk:[],ta:[],al:[]},
  {n:"八雷天神",h:"やくさいかづちのかみ",e:"",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは自分の墓地のレベルまたはランクが８のモンスター１体を除外し、手札から特殊召喚できる。\n②：自分の墓地のレベルまたはランクが８の儀式・融合・S・Xモンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、戻したモンスターの種類によって以下の効果を適用する。\n●儀式・融合：自分はデッキから１枚ドローする。\n●S・X：このカードの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[]},
  {n:"閃術兵器－H.A.M.P.",h:"せんじゅつへいき－はんぷ",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名はルール上「閃刀」カードとしても扱う。\nこのカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「閃刀姫」モンスターが存在する場合、このカードは自分または相手フィールドのモンスター１体をリリースし、そのコントローラーのフィールドに手札から特殊召喚できる。\n②：このカードが戦闘で破壊された時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[]},
  {n:"閃刀起動－リンケージ",h:"せんとうきどう－りんけーじ",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合に発動できる。\nこのカード以外の自分フィールドのカード１枚を選んで墓地へ送り、EXデッキから「閃刀姫」モンスター１体をEXモンスターゾーンに特殊召喚する。\n自分のフィールド・墓地に、光属性と闇属性の「閃刀姫」モンスターがそれぞれ１体以上存在する場合、この効果で特殊召喚したモンスターの攻撃力は１０００アップする。\nこのカードの発動後、ターン終了時まで自分は「閃刀姫」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[]},
  {n:"垂氷の魔妖－雪女",h:"たるひのまやかし－ゆきおんな",e:"",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"アンデット族",mo:["リンク","効果"],ma:"",tp:"",at:2400,de:"-",tx:"アンデット族モンスター２体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「垂氷の魔妖－雪女」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが特殊召喚に成功した場合、相手フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターの効果を無効にする。\n③：墓地のこのカードを除外して発動できる。\n自分の墓地のモンスター及び除外されている自分のモンスターの中から、アンデット族Sモンスター１体を選んで特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:["右下","下","左下"],ta:[],al:[]},
  {n:"逢華妖麗譚－魔妖不知火語",h:"おうかようれいたん－まやかししらぬいがたり",e:"",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの、「魔妖」S・リンクモンスターまたは「不知火」S・リンクモンスター１体をリリースして発動できる。\nこのターン、お互いに手札・デッキ・EXデッキからモンスターを特殊召喚できない。\n②：墓地のこのカードを除外し、除外されている自分のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・バイスマスター",h:"うぃっちくらふと・ばいすますたー",e:"",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:2800,tx:"「ウィッチクラフト」モンスター＋魔法使い族モンスター\n①：融合モンスター以外の魔法使い族モンスターまたは魔法カードの効果が発動した時、以下の効果から１つを選択して発動できる。\n「ウィッチクラフト・バイスマスター」の以下の効果はそれぞれ１ターンに１度しか選択できない。\n●フィールドのカード１枚を選んで破壊する。\n●手札・デッキからレベル６以下の「ウィッチクラフト」モンスター１体を特殊召喚する。\n●自分の墓地から「ウィッチクラフト」魔法・罠カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[]},
  {n:"ウィッチクラフト・コンフュージョン",h:"うぃっちくらふと・こんふゅーじょん",e:"",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の手札・フィールドから、融合モンスターカードによって決められた、「ウィッチクラフト」モンスターを含む融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[]},
];

export default datas;