import React from 'react';

export default function LinkTriangle(props) {
  const {
    links,
  } = props;

  return (
    <div className="position-relative d-flex flex-column me-3">
      <div className="d-flex align-items-start justify-content-between">
        <div
          className="triangle triangle-top-left"
          style={{ borderColor: `${links.indexOf('左上') !== -1 ? '#ffc107' : '#6c757d'} transparent transparent transparent` }}
        />
        <div
          className="triangle triangle-top"
          style={{ borderColor: `transparent transparent ${links.indexOf('上') !== -1 ? '#ffc107' : '#6c757d'} transparent` }}
        />
        <div
          className="triangle triangle-top-right"
          style={{ borderColor: `transparent ${links.indexOf('右上') !== -1 ? '#ffc107' : '#6c757d'} transparent transparent` }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="triangle triangle-left"
          style={{ borderColor: `transparent ${links.indexOf('左') !== -1 ? '#ffc107' : '#6c757d'} transparent transparent` }}
        />
        <div
          className="flex-grow-1"
        />
        <div
          className="triangle triangle-right"
          style={{ borderColor: `transparent transparent transparent ${links.indexOf('右') !== -1 ? '#ffc107' : '#6c757d'}` }}
        />
      </div>
      <div className="d-flex align-items-end justify-content-between">
        <div
          className="triangle triangle-bottom-left"
          style={{ borderColor: `transparent transparent transparent ${links.indexOf('左下') !== -1 ? '#ffc107' : '#6c757d'}` }}
        />
        <div
          className="triangle triangle-bottom"
          style={{ borderColor: `${links.indexOf('下') !== -1 ? '#ffc107' : '#6c757d'} transparent transparent transparent` }}
        />
        <div
          className="triangle triangle-bottom-right"
          style={{ borderColor: `transparent transparent ${links.indexOf('右下') !== -1 ? '#ffc107' : '#6c757d'} transparent` }}
        />
      </div>
      {/* <div className="position-relative" style={{ top: -1, left: 0 }}>
        <div className="position-absolute triangle triangle-top" style={{ top: 0, left: 0 }}></div>
        <div className="position-absolute triangle triangle-right" style={{ top: 5, left: 10 }}></div>
        <div className="position-absolute triangle triangle-bottom" style={{ top: 15, left: 0 }}></div>
        <div className="position-absolute triangle triangle-left" style={{ top: 5, left: -5 }}></div>
      </div>
      <div className="position-relative" style={{ top: -1, left: 0 }}>
        <div className="position-absolute triangle triangle-top-right" style={{ top: 1, left: 8 }}></div>
        <div className="position-absolute triangle triangle-bottom-right" style={{ top: 12, left: 8 }}></div>
        <div className="position-absolute triangle triangle-bottom-left" style={{ top: 12, left: -4 }}></div>
        <div className="position-absolute triangle triangle-top-left" style={{ top: 1, left: -4 }}></div>
      </div> */}
    </div>
  );
}